import React, { useState } from "react";
import Unitinput from "./input";
import OptionsInput from "./optionsinput";
import SummaryPreview from "./summaryPreview";
import OptionQuantity from "./optionQuantity";

const Unit = ({
  unitTypes,
  handleUnitChange,
  unitStep1List,
  unitOption,
  handleUnitOptionChange,
  unitStep4List,
  updateId,
  unitStep3List,
  handleShowComponent,
}) => {
  const [show, setShow] = useState({
    unitInput: true,
    unitOptionInput: false,
    unitQuanties: false,
    unitSummeryPreview: false,
  });

  const handleShowComponents = (component) => {
    setShow({
      unitInput: component === "unitInput",
      unitQuanties: component === "unitQuanties",
      unitSummeryPreview: component === "unitSummeryPreview",
      unitOptionInput: component === "unitOptionInput",
    });
  };
  return (
    <div className="FolderFormCreateArea Proforma">
      <div className="StepBox Green">
        <ul>
          <li
            className={show.unitInput ? "active" : ""}
            onClick={() => handleShowComponents("unitInput")}
          >
            <span className="Icon">
              <img src={require("../../../assets/images/block_input.png")} />
            </span>
            <span className="Text">
              Units <br /> Input
            </span>
          </li>
          <li
            className={show.unitOptionInput ? "active" : ""}
            onClick={() => handleShowComponents("unitOptionInput")}
          >
            <span className="Icon">
              <img
                src={require("../../../assets/images/block_quantities.png")}
              />
            </span>
            <span className="Text">
              Units <br />
              Options <br /> Input
            </span>
          </li>
          <li
            className={show.unitQuanties ? "active" : ""}
            onClick={() => handleShowComponents("unitQuanties")}
          >
            <span className="Icon">
              <img
                src={require("../../../assets/images/block_quantities.png")}
              />
            </span>
            <span className="Text">
              Units <br /> Options <br /> Quantities{" "}
            </span>
          </li>
          <li
            className={show.unitSummeryPreview ? "active" : ""}
            onClick={() => handleShowComponents("unitSummeryPreview")}
          >
            <span className="Icon">
              <img src={require("../../../assets/images/block_summary.png")} />
            </span>
            <span className="Text">
              Units <br />
              Summary <br />
              Preview
            </span>
          </li>
        </ul>
      </div>
      <div className="FieldsMandatory">
        <h6>
          Fields with <span className="required">*</span> are mandatory
        </h6>
      </div>
      {show.unitInput && (
        <Unitinput
          unitTypes={unitTypes}
          handleUnitChange={handleUnitChange}
          unitStep1List={unitStep1List}
          handleShowComponent={handleShowComponent}
          handleShowComponents={handleShowComponents}
        />
      )}
      {show.unitOptionInput && (
        <OptionsInput
          unitOption={unitOption}
          handleUnitOptionChange={handleUnitOptionChange}
          updateId={updateId}
          handleShowComponents={handleShowComponents}
        />
      )}
      {show.unitQuanties && (
        <OptionQuantity
          unitOption={unitOption}
          unitStep3List={unitStep3List}
          updateId={updateId}
          handleShowComponents={handleShowComponents}
        />
      )}
      {show.unitSummeryPreview && (
        <SummaryPreview
          unitOption={unitOption}
          handleUnitOptionChange={handleUnitOptionChange}
          unitStep4List={unitStep4List}
          handleShowComponent={handleShowComponent}
          handleShowComponents={handleShowComponents}
        />
      )}
    </div>
  );
};

export default Unit;
