import { clientViewAuthAction } from '../actionTypes'

const initialState = {
  clientViewData: {},
  loader: false,
  loginStatus: false,
  warrantyRequestloader: false,
  warrantyRequestList:[],
  warrantyWorkOrderloader: false,
  warrantyWorkOrderList:[],
  warrantyDraftData:[],
  warrantyUrgencyList:[],
  warrantyAllRequestItemList:[],
  changeRequestList:[],
  allAppointmentList:[],
  appointmentLoader:false,
  clientChangeOrdereList:[],
  changeOrderLoader:false,
  folderNFileList:[],
  interiorListData:[],
  exteriorListData:[],
  clientViewConfigData:[],
  clientList:[],
  commentList:[],
  subCommentList:[],
  salePendingList:[],
}

export default function clientViewLogin(state = initialState, {type, payload}) {
  switch(type) {
    case clientViewAuthAction.CLIENT_VIEW_LOGIN_INITIATE: {
      return {
        ...state, loader: true, clientViewData: {}
      }
    }
    case clientViewAuthAction.CLIENT_VIEW_LOGIN_SUCCESS: {
				// 	delete payload.data.password
				// delete payload.data.plainPassword
      // console.log("payload",payload);
       window.localStorage.setItem('clientViewLogin', JSON.stringify(payload))
       
      return {
        ...state, loader: false, loginStatus: true, clientViewData: payload.data
      }
    }
    case clientViewAuthAction.CLIENT_VIEW_LOGIN_FAILURE: {
      return {
        ...state, loader: false,clientViewData: payload
      }
    }

    case clientViewAuthAction.WARRANTY_REQUEST_LIST_INITIATE: {
      return {...state, warrantyRequestloader: true, warrantyRequestList: [] }
    }
    case clientViewAuthAction.WARRANTY_REQUEST_LIST_SUCCESS: {
       return {...state, warrantyRequestloader: false, warrantyRequestList: payload }
    }
    case clientViewAuthAction.WARRANTY_REQUEST_LIST_FAILURE: {
      return { ...state, warrantyRequestloader: false,warrantyRequestList:[] }
    }

    case clientViewAuthAction.WARRANTY_WORK_ORDER_LIST_INITIATE: {
      return {...state, warrantyWorkOrderloader: true, warrantyWorkOrderList: [] }
    }
    case clientViewAuthAction.WARRANTY_WORK_ORDER_LIST_SUCCESS: {
       return {...state, warrantyWorkOrderloader: false, warrantyWorkOrderList: payload }
    }
    case clientViewAuthAction.WARRANTY_WORK_ORDER_LIST_FAILURE: {
      return { ...state, warrantyWorkOrderloader: false,warrantyWorkOrderList:[] }
    }

    case clientViewAuthAction.WARRANTY_DRAFT_DATA_INITIATE: {
      return {...state,warrantyDraftData: [] }
    }
    case clientViewAuthAction.WARRANTY_DRAFT_DATA_SUCCESS: {
       return {...state, warrantyDraftData: payload }
    }
    case clientViewAuthAction.WARRANTY_DRAFT_DATA_FAILURE: {
      return { ...state,warrantyDraftData:[] }
    }

    case clientViewAuthAction.WARRANTY_ALL_SYSTEM_URGENCY_LIST_INITIATE: {
      return {...state,warrantyAllSystemUrgencyList: [] }
    }
    case clientViewAuthAction.WARRANTY_ALL_SYSTEM_URGENCY_LIST_SUCCESS: {
       return {...state, warrantyAllSystemUrgencyList: payload }
    }
    case clientViewAuthAction.WARRANTY_ALL_SYSTEM_URGENCY_LIST_FAILURE: {
      return { ...state,warrantyAllSystemUrgencyList:[] }
    }

    case clientViewAuthAction.WARRANTY_URGENCY_LIST_INITIATE: {
      return {...state,warrantyUrgencyList: [] }
    }
    case clientViewAuthAction.WARRANTY_URGENCY_LIST_SUCCESS: {
       return {...state, warrantyUrgencyList: payload }
    }
    case clientViewAuthAction.WARRANTY_URGENCY_LIST_FAILURE: {
      return { ...state,warrantyUrgencyList:[] }
    }

    case clientViewAuthAction.WARRANTY_ALL_REQUEST_ITEM_LIST_INITIATE: {
      return {...state,warrantyAllRequestItemList: [] }
    }
    case clientViewAuthAction.WARRANTY_ALL_REQUEST_ITEM_LIST_SUCCESS: {
       return {...state, warrantyAllRequestItemList: payload }
    }
    case clientViewAuthAction.WARRANTY_ALL_REQUEST_ITEM_LIST_FAILURE: {
      return { ...state,warrantyAllRequestItemList:[] }
    }

    case clientViewAuthAction.CLIENT_CHANGE_REQUEST_LIST_INITIATE: {
      return {...state,changeRequestList: [] }
    }
    case clientViewAuthAction.CLIENT_CHANGE_REQUEST_LIST_SUCCESS: {
       return {...state, changeRequestList: payload }
    }
    case clientViewAuthAction.CLIENT_CHANGE_REQUEST_LIST_FAILURE: {
      return { ...state,changeRequestList:[] }
    }

    case clientViewAuthAction.CLIENT_ALL_APPOINTMENT_LIST_INITIATE: {
      return {...state,allAppointmentList: [],appointmentLoader:true }
    }
    case clientViewAuthAction.CLIENT_ALL_APPOINTMENT_LIST_SUCCESS: {
       return {...state, allAppointmentList: payload ,appointmentLoader:false}
    }
    case clientViewAuthAction.CLIENT_ALL_APPOINTMENT_LIST_FAILURE: {
      return { ...state,allAppointmentList:[],appointmentLoader:false }
    }

    case clientViewAuthAction.CLIENT_CHANGE_ORDER_LIST_INITIATE: {
      return {...state,clientChangeOrdereList: [],changeOrderLoader:true }
    }
    case clientViewAuthAction.CLIENT_CHANGE_ORDER_LIST_SUCCESS: {
       return {...state, clientChangeOrdereList: payload ,changeOrderLoader:false}
    }
    case clientViewAuthAction.CLIENT_CHANGE_ORDER_LIST_FAILURE: {
      return { ...state,clientChangeOrdereList:[],changeOrderLoader:false }
    }

    case clientViewAuthAction.SALES_FOLDER_N_LIST_INITIATE: {
      return {...state,folderNFileList: []}
    }
    case clientViewAuthAction.SALES_FOLDER_N_LIST_SUCCESS: {
       return {...state, folderNFileList: payload }
    }
    case clientViewAuthAction.SALES_FOLDER_N_LIST_FAILURE: {
      return { ...state,folderNFileList:[]}
    }

    case clientViewAuthAction.GET_INTERIOR_DESIGN_SELECTONS_INITIATE: {
      return {...state,interiorListData: []}
    }
    case clientViewAuthAction.GET_INTERIOR_DESIGN_SELECTONS_SUCCESS: {
       return {...state, interiorListData: payload }
    }
    case clientViewAuthAction.GET_INTERIOR_DESIGN_SELECTONS_FAILURE: {
      return { ...state,interiorListData:[]}
    }

    case clientViewAuthAction.GET_EXTERIOR_DESIGN_SELECTONS_INITIATE: {
      return {...state,exteriorListData: []}
    }
    case clientViewAuthAction.GET_EXTERIOR_DESIGN_SELECTONS_SUCCESS: {
       return {...state, exteriorListData: payload }
    }
    case clientViewAuthAction.GET_EXTERIOR_DESIGN_SELECTONS_FAILURE: {
      return { ...state,exteriorListData:[]}
    }

    case clientViewAuthAction.CLIENT_VIEW_GENERAL_CONFIG_INITIATE: {
      return {...state,clientViewConfigData: []}
    }
    case clientViewAuthAction.CLIENT_VIEW_GENERAL_CONFIG_SUCCESS: {
       return {...state, clientViewConfigData: payload }
    }
    case clientViewAuthAction.CLIENT_VIEW_GENERAL_CONFIG_FAILURE: {
      return { ...state,clientViewConfigData:[]}
    }

    case clientViewAuthAction.GET_CLIENT_VIEW_USER_INITIATE: {
      return {...state,clientList: []}
    }
    case clientViewAuthAction.GET_CLIENT_VIEW_USER_SUCCESS: {
       return {...state, clientList: payload }
    }
    case clientViewAuthAction.GET_CLIENT_VIEW_USER_FAILURE: {
      return { ...state,clientList:[]}
    }

    case clientViewAuthAction.GET_COMMENT_INITIATE: {
      return {...state,commentList: []}
    }
    case clientViewAuthAction.GET_COMMENT_SUCCESS: {
       return {...state, commentList: payload }
    }
    case clientViewAuthAction.GET_COMMENT_FAILURE: {
      return { ...state,commentList:[]}
    }

    case clientViewAuthAction.GET_SUBCOMMENT_INITIATE: {
      return {...state,subCommentList: []}
    }
    case clientViewAuthAction.GET_SUBCOMMENT_SUCCESS: {
       return {...state, subCommentList: payload }
    }
    case clientViewAuthAction.GET_SUBCOMMENT_FAILURE: {
      return { ...state,subCommentList:[]}
    }

    case clientViewAuthAction.SALE_PENDING_TEMPLATES_INITIATE: {
      return {...state,salePendingList: []}
    }
    case clientViewAuthAction.SALE_PENDING_TEMPLATES_SUCCESS: {
       return {...state, salePendingList: payload }
    }
    case clientViewAuthAction.SALE_PENDING_TEMPLATES_FAILURE: {
      return { ...state,salePendingList:[]}
    }
    default: 
    return state
}
}