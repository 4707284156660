
import { changeOrderAction } from "../actionTypes";
import { Url } from "../../Config/Config";
import axios from "axios";
import { isLoggedIn } from "../../utils/tokenCheck";
import { multiPartData, multiPartDatas } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

//===========================getAllCategory================================

export const getAllCategoryInitiate = () => ({
    type: changeOrderAction.GET_ALL_CATEGORY_LIST_INITIATE,
  });
  
  export const getAllCategorySuccess = (data) => ({
    type: changeOrderAction.GET_ALL_CATEGORY_LIST_SUCCESS,
    payload: data,
  });
  
  export const getAllCategoryFailure = (data) => ({
    type: changeOrderAction.GET_ALL_CATEGORY_LIST_FAILURE,
    payload: data,
  });
  
  export const getAllCategoryLists = (projectId,payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(getAllCategoryInitiate(payload));
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${projectId}/change-order/category`, {
          headers: { Authorization: `Bearer ${token}` },
        });
     ;
        if (response.status == 200) {
          dispatch(getAllCategorySuccess(response.data));
        } else {
          dispatch(getAllCategoryFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(getAllCategoryFailure(err));
        return err.response
      }
    };

//====================================getAssembly========================


export const getAssemblyListInitiate = () => ({
    type: changeOrderAction.GET_ASSEMBLY_LIST_INITIATE,
  });
  
  export const getAssemblyListSuccess = (data) => ({
    type: changeOrderAction.GET_ASSEMBLY_LIST_SUCCESS,
    payload: data,
  });
  
  export const getAssemblyListFailure = (data) => ({
    type: changeOrderAction.GET_ASSEMBLY_LIST_FAILURE,
    payload: data,
  });
  
  export const getAssemblyLists = (projectId,catId,payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(getAssemblyListInitiate(payload));
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${projectId}/change-order/assembly?type=company&categoriesId=${catId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
     ;
        if (response.status == 200) {
          dispatch(getAssemblyListSuccess(response.data));
        } else {
          dispatch(getAssemblyListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(getAssemblyListFailure(err));
        return err.response
      }
    };

//=======================================getMeasureList==============================


export const getMeasureListInitiate = () => ({
    type: changeOrderAction.GET_MEASURE_LIST_INITIATE,
  });
  
  export const getMeasureListSuccess = (data) => ({
    type: changeOrderAction.GET_MEASURE_LIST_SUCCESS,
    payload: data,
  });
  
  export const getMeasureListFailure = (data) => ({
    type: changeOrderAction.GET_MEASURE_LIST_FAILURE,
    payload: data,
  });
  
  export const getMeasureLists = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(getMeasureListInitiate(payload));
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/measures`, {
          headers: { Authorization: `Bearer ${token}` },
        });
     ;
        if (response.status == 200) {
          dispatch(getMeasureListSuccess(response.data));
        } else {
          dispatch(getMeasureListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(getMeasureListFailure(err));
        return err.response
      }
    };

//===================================getCategoryList=================================

export const costCategoryListInitiate = () => ({
    type: changeOrderAction.COST_CATEGORY_LIST_INITIATE,
  });
  
  export const costCategoryListSuccess = (data) => ({
    type: changeOrderAction.COST_CATEGORY_LIST_SUCCESS,
    payload: data,
  });
  
  export const costCategoryListFailure = (data) => ({
    type: changeOrderAction.COST_CATEGORY_LIST_FAILURE,
    payload: data,
  });
  
  export const getCostCategoryLists = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(costCategoryListInitiate(payload));
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcategory`, {
          headers: { Authorization: `Bearer ${token}` },
        });
     ;
        if (response.status == 200) {
          dispatch(costCategoryListSuccess(response.data));
        } else {
          dispatch(costCategoryListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(costCategoryListFailure(err));
        return err.response
      }
    };


//==============================costCodeList======================================


export const costCodeListInitiate = () => ({
    type: changeOrderAction.COST_CODE_LIST_INITIATE,
  });
  
  export const costCodeListSuccess = (data) => ({
    type: changeOrderAction.COST_CODE_LIST_SUCCESS,
    payload: data,
  });
  
  export const costCodeListFailure = (data) => ({
    type: changeOrderAction.COST_CODE_LIST_FAILURE,
    payload: data,
  });
  
  export const getCostCodeLists = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(costCodeListInitiate(payload));
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcode/all`, {
          headers: { Authorization: `Bearer ${token}` },
        });
     ;
        if (response.status == 200) {
          dispatch(costCodeListSuccess(response.data));
        } else {
          dispatch(costCodeListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(costCodeListFailure(err));
        return err.response
      }
    };

//=================================addCustomItem===================================


export const addCustomItemInitiate = () => ({
    type: changeOrderAction.ADD_CUSTOM_ITEM_INITIATE,
  });
  
  export const addCustomItemSuccess = (data) => ({
    type: changeOrderAction.ADD_CUSTOM_ITEM_SUCCESS,
    payload: data,
  });
  
  export const addCustomItemFailure = (data) => ({
    type: changeOrderAction.ADD_CUSTOM_ITEM_FAILURE,
    payload: data,
  });
  
  export const createCustomItem = (projectId,payload) => async (dispatch) => {
      try {
        const updatedData = multiPartData({ ...payload });
        const token = isLoggedIn("adminData1");
        dispatch(addCustomItemInitiate(payload));
        const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${projectId}/change-order/customEntries`,updatedData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 201) {
          dispatch(addCustomItemSuccess(response.data));
        } else {
          dispatch(addCustomItemFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(addCustomItemFailure(err));
        return err.response
      }
    };

//=================================addSuppotingDocsFile=====================

export const addSupportingDocsFilesInitiate = () => ({
    type: changeOrderAction.ADD_SUPPORTING_DOCS_FILES_INITIATE,
  });
  
  export const addSupportingDocsFilesSuccess = (data) => ({
    type: changeOrderAction.ADD_SUPPORTING_DOCS_FILES_SUCCESS,
    payload: data,
  });
  
  export const addSupportingDocsFilesFailure = (data) => ({
    type: changeOrderAction.ADD_SUPPORTING_DOCS_FILES_FAILURE,
    payload: data,
  });
  
  export const createSupportingDocsFiles = (payload) => async (dispatch) => {
      try {
        const updatedData = multiPartDatas(payload);
        const token = isLoggedIn("adminData1");
        dispatch(addSupportingDocsFilesInitiate(payload));
        const response = await axios.post(`${Url}/upload/files`,updatedData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(addSupportingDocsFilesSuccess(response.data));
        } else {
          dispatch(addSupportingDocsFilesFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(addSupportingDocsFilesFailure(err));
        return err.response
      }
    };

//==================================change-order-list================================

export const changeOrderListInitiate = () => ({
    type: changeOrderAction.CHANGE_ORDER_LIST_INITIATE,
  });
  
  export const changeOrderListSuccess = (data) => ({
    type: changeOrderAction.CHANGE_ORDER_LIST_SUCCESS,
    payload: data,
  });
  
  export const changeOrderListFailure = (data) => ({
    type: changeOrderAction.CHANGE_ORDER_LIST_FAILURE,
    payload: data,
  });
  
  export const getChangeOrderList= (projectId,jobId,jobType) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(changeOrderListInitiate());
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${projectId}/change-order?jobId=${jobId}&jobType=${jobType}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(changeOrderListSuccess(response.data));
        } else {
          dispatch(changeOrderListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(changeOrderListFailure(err));
        return err.response
      }
    };
//=================================================updateSupportingDocs===============================

export const updateDocsFilesInitiate = () => ({
    type: changeOrderAction.UPDATE_SUPPORTING_DOCS_FILES_INITIATE,
  });
  
  export const updateDocsFilesSuccess = (data) => ({
    type: changeOrderAction.UPDATE_SUPPORTING_DOCS_FILES_SUCCESS,
    payload: data,
  });
  
  export const updateDocsFilesFailure = (data) => ({
    type: changeOrderAction.UPDATE_SUPPORTING_DOCS_FILES_FAILURE,
    payload: data,
  });
  
  export const updateChangeOrderDocsFiles= (projectId,payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(updateDocsFilesInitiate(projectId,payload));
        const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${projectId}/change-order/SupportingDocsByChangeorder`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(updateDocsFilesSuccess(response.data));
        } else {
          dispatch(updateDocsFilesFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(updateDocsFilesFailure(err));
        return err.response
      }
    };

//==========================================================delete-Supporting---docs=============================

export const deleteDocsFilesInitiate = () => ({
    type: changeOrderAction.DELETE_SUPPORTING_DOCS_FILES_INITIATE,
  });
  
  export const deleteDocsFilesSuccess = (data) => ({
    type: changeOrderAction.DELETE_SUPPORTING_DOCS_FILES_SUCCESS,
    payload: data,
  });
  
  export const deleteDocsFilesFailure = (data) => ({
    type: changeOrderAction.DELETE_SUPPORTING_DOCS_FILES_FAILURE,
    payload: data,
  });
  
  export const deleteChangeOrderDocsFiles= (projectId,docId) => async (dispatch) => {
    
      try {
        const token = isLoggedIn("adminData1");
        dispatch(deleteDocsFilesInitiate(projectId,docId));
        const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${projectId}/change-order/SupportingDocsByChangeorder?docId=${docId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(deleteDocsFilesSuccess(response.data));
        } else {
          dispatch(deleteDocsFilesFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(deleteDocsFilesFailure(err));
        return err.response
      }
    };

//=================================ClientChangeRequestList===========================

export const clientChangeRequestListInitiate = () => ({
    type: changeOrderAction.CLIENT_CHANGE_REQUEST_LIST_INITIATE,
  });
  
  export const clientChangeRequestListSuccess = (data) => ({
    type: changeOrderAction.CLIENT_CHANGE_REQUEST_LIST_SUCCESS,
    payload: data,
  });
  
  export const clientChangeRequestListFailure = (data) => ({
    type: changeOrderAction.CLIENT_CHANGE_REQUEST_LIST_FAILURE,
    payload: data,
  });
  
  export const getClientChangeRequestList= (projectId) => async (dispatch) => {
    
      try {
        const token = isLoggedIn("adminData1");
        dispatch(clientChangeRequestListInitiate(projectId));
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${projectId}/client-change-request`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(clientChangeRequestListSuccess(response.data));
        } else {
          dispatch(clientChangeRequestListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(clientChangeRequestListFailure(err));
        return err.response
      }
    };

//=================================cancel-Change-request===============================

export const cancelClientChangeRequestInitiate = () => ({
    type: changeOrderAction.CANCEL_CLIENT_CHANGE_REQUEST_INITIATE,
  });
  
  export const cancelClientChangeRequestSuccess = (data) => ({
    type: changeOrderAction.CANCEL_CLIENT_CHANGE_REQUEST_SUCCESS,
    payload: data,
  });
  
  export const  cancelClientChangeRequestFailure = (data) => ({
    type: changeOrderAction.CANCEL_CLIENT_CHANGE_REQUEST_FAILURE,
    payload: data,
  });
  
  export const cancelClientChangeRequest= (projectId,payload) => async (dispatch) => {
    
      try {
        const token = isLoggedIn("adminData1");
        dispatch(cancelClientChangeRequestInitiate(projectId));
        const response = await axios.put(`${Url}projects/${projectId}/client/client-change-request`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 202) {
          dispatch(cancelClientChangeRequestSuccess(response.data));
        } else {
          dispatch(cancelClientChangeRequestFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(cancelClientChangeRequestFailure(err));
        return err.response
      }
    };

//=========================================create-change-order===========================

export const createChangeOrderInitiate = () => ({
  type: changeOrderAction.CREATE_CHANGE_ORDER_INITIATE,
});

export const createChangeOrderSuccess = (data) => ({
  type: changeOrderAction.CREATE_CHANGE_ORDER_SUCCESS,
  payload: data,
});

export const  createChangeOrderFailure = (data) => ({
  type: changeOrderAction.CREATE_CHANGE_ORDER_FAILURE,
  payload: data,
});

export const createChangeOrder= (projectId,payload) => async (dispatch) => {
  
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createChangeOrderInitiate(projectId));
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${projectId}/change-order`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(createChangeOrderSuccess(response.data));
      } else {
        dispatch(createChangeOrderFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createChangeOrderFailure(err));
      return err.response
    }
  };

//=======================addChangeOrderItem========================

export const addChangeOrderItemInitiate = () => ({
  type: changeOrderAction.ADD_CHANGE_ORDER_ITEM_INITIATE,
});

export const addChangeOrderItemSuccess = (data) => ({
  type: changeOrderAction.ADD_CHANGE_ORDER_ITEM_SUCCESS,
  payload: data,
});

export const  addChangeOrderItemFailure = (data) => ({
  type: changeOrderAction.ADD_CHANGE_ORDER_ITEM_FAILURE,
  payload: data,
});

export const addChangeOrderItem= (projectId,payload) => async (dispatch) => {
  
    try {
      const token = isLoggedIn("adminData1");
      dispatch(addChangeOrderItemInitiate(projectId));
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${projectId}/change-order/add-item`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(addChangeOrderItemSuccess(response.data));
      } else {
        dispatch(addChangeOrderItemFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addChangeOrderItemFailure(err));
      return err.response
    }
  };

//==================getItemByChangeOrderitem============


export const getItemByChangeOrderIdInitiate = () => ({
  type: changeOrderAction.GET_ITEM_BY_CHANGE_ORDER_ID_INITIATE,
});

export const getItemByChangeOrderIdSuccess = (data) => ({
  type: changeOrderAction.GET_ITEM_BY_CHANGE_ORDER_ID_SUCCESS,
  payload: data,
});

export const  getItemByChangeOrderIdFailure = (data) => ({
  type: changeOrderAction.GET_ITEM_BY_CHANGE_ORDER_ID_FAILURE,
  payload: data,
});

export const getItemByChangeOrderId= (projectId,changeOrderId) => async (dispatch) => {
  
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getItemByChangeOrderIdInitiate(projectId,changeOrderId));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${projectId}/change-order/itemByid?changeOrderId=${changeOrderId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getItemByChangeOrderIdSuccess(response.data));
      } else {
        dispatch(getItemByChangeOrderIdFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getItemByChangeOrderIdFailure(err));
      return err.response
    }
  };

//====================item--delete================

export const deleteItemInitiate = () => ({
  type: changeOrderAction.DELETE_ITEM_INITIATE,
});

export const deleteItemSuccess = (data) => ({
  type: changeOrderAction.DELETE_ITEM_SUCCESS,
  payload: data,
});

export const  deleteItemFailure = (data) => ({
  type: changeOrderAction.DELETE_ITEM_FAILURE,
  payload: data,
});

export const getDeleteItem= (changeOrderId) => async (dispatch) => {
  
    try {
      const token = isLoggedIn("adminData1");
      dispatch(deleteItemInitiate(changeOrderId));
      const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/change-order/delete-item?id=${changeOrderId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(deleteItemSuccess(response.data));
      } else {
        dispatch(deleteItemFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(deleteItemFailure(err));
      return err.response
    }
  };

//===================================update-change-Order=========================

export const updateChangeOrderItemInitiate = () => ({
  type: changeOrderAction.UPDATE_CHANGE_ORDER_ITEM_INITIATE,
});

export const updateChangeOrderItemSuccess = (data) => ({
  type: changeOrderAction.UPDATE_CHANGE_ORDER_ITEM_SUCCESS,
  payload: data,
});

export const  updateChangeOrderItemFailure = (data) => ({
  type: changeOrderAction.UPDATE_CHANGE_ORDER_ITEM_FAILURE,
  payload: data,
});

export const updateChangeOrder= (projectId,payload) => async (dispatch) => {
  
    try {
      const token = isLoggedIn("adminData1");
      dispatch(updateChangeOrderItemInitiate(projectId));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${projectId}/change-order`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 202) {
        dispatch(updateChangeOrderItemSuccess(response.data));
      } else {
        dispatch(updateChangeOrderItemFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateChangeOrderItemFailure(err));
      return err.response
    }
  };
//==============================delete-change-order=========================


export const deleteChangeOrderInitiate = () => ({
  type: changeOrderAction.DELETE_CHANGE_ORDER_INITIATE,
});

export const deleteChangeOrderSuccess = (data) => ({
  type: changeOrderAction.DELETE_CHANGE_ORDER_SUCCESS,
  payload: data,
});

export const deleteChangeOrderFailure = (data) => ({
  type: changeOrderAction.DELETE_CHANGE_ORDER_FAILURE,
  payload: data,
});

export const deleteChangeOrder= (projectId,changeOrderId) => async (dispatch) => {
  
    try {
      const token = isLoggedIn("adminData1");
      dispatch(deleteChangeOrderSuccess());
      const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${projectId}/change-order?changeOrderId=${changeOrderId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 202) {
        dispatch(deleteDocsFilesSuccess(response.data));
      } else {
        dispatch(deleteChangeOrderFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(deleteChangeOrderFailure(err));
      return err.response
    }
  };

//========================generate--agrrement========================

export const generateAgreementInitiate = () => ({
  type: changeOrderAction.GENERATE_AGREEMENT_INITIATE,
});

export const generateAgreementSuccess = (data) => ({
  type: changeOrderAction.GENERATE_AGREEMENT_SUCCESS,
  payload: data,
});

export const generateAgreementFailure = (data) => ({
  type: changeOrderAction.GENERATE_AGREEMENT_FAILURE,
  payload: data,
});

export const generateAgreement= (projectId,payload) => async (dispatch) => {
  
    try {
      const token = isLoggedIn("adminData1");
      dispatch(generateAgreementInitiate());
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${projectId}/change-order/agreement/generate`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch(generateAgreementSuccess(response.data));
      } else {
        dispatch(generateAgreementFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(generateAgreementFailure(err));
      return err.response
    }
  };
//===========================SEND-EMAIL===AGRREMENT=================
export const sendEmailAgreementInitiate = () => ({
  type: changeOrderAction.SEND_EMAIL_AGREEMENT_INITIATE,
});

export const sendEmailAgreementSuccess = (data) => ({
  type: changeOrderAction.SEND_EMAIL_AGREEMENT_SUCCESS,
  payload: data,
});

export const sendEmailAgreementFailure = (data) => ({
  type: changeOrderAction.SEND_EMAIL_AGREEMENT_FAILURE,
  payload: data,
});

export const sendEmailAgreement= (projectId,payload) => async (dispatch) => {
  
    try {
      const token = isLoggedIn("adminData1");
      dispatch(sendEmailAgreementInitiate());
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${projectId}/change-order/agreement/sendtoemail`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch(sendEmailAgreementSuccess(response.data));
      } else {
        dispatch(sendEmailAgreementFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(sendEmailAgreementFailure(err));
      return err.response
    }
  };
//=======================ADD-SIGNATURE---IN--AGREEMENT=================

export const addSignatureInAgreementInitiate = () => ({
  type: changeOrderAction.ADD_SIGNATURE_IN_AGREEMENT_INITIATE,
});

export const addSignatureInAgreementSuccess = (data) => ({
  type: changeOrderAction.ADD_SIGNATURE_IN_AGREEMENT_SUCCESS,
  payload: data,
});

export const addSignatureInAgreementFailure = (data) => ({
  type: changeOrderAction.ADD_SIGNATURE_IN_AGREEMENT_FAILURE,
  payload: data,
});

export const addSignatureInAgreements= (CompanyId,projectId,payload,token) => async (dispatch) => {
  
    try {
      dispatch(addSignatureInAgreementInitiate());
      const response = await axios.post(`${Url}companies/${CompanyId}/projects/${projectId}/change-order/agreement/signature/add`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch(addSignatureInAgreementSuccess(response.data));
      } else {
        dispatch(addSignatureInAgreementFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addSignatureInAgreementFailure(err));
      return err.response
    }
  };

//==========================HISTORY-CHANGE-ORDER--AGREEMENT=================

export const historyChangeOrderAgreementInitiate = () => ({
  type: changeOrderAction.HISTORY_CHANGE_ORDER_AGREEMENT_INITIATE,
});

export const historyChangeOrderAgreementSuccess = (data) => ({
  type: changeOrderAction.HISTORY_CHANGE_ORDER_AGREEMENT_SUCCESS,
  payload: data,
});

export const historyChangeOrderAgreementFailure = (data) => ({
  type: changeOrderAction.HISTORY_CHANGE_ORDER_AGREEMENT_FAILURE,
  payload: data,
});

export const historyChangeOrders= (projectId,changeOrderId) => async (dispatch) => {
  
    try {
      const token = isLoggedIn("adminData1");
      dispatch(historyChangeOrderAgreementInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${projectId}/change-order/histories?changeOrderId=${changeOrderId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch(historyChangeOrderAgreementSuccess(response.data));
      } else {
        dispatch(historyChangeOrderAgreementFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(historyChangeOrderAgreementFailure(err));
      return err.response
    }
  };

//=========================CHANGE-ORDER--DETAILS====================
export const changeOrderDetailsInitiate = () => ({
  type: changeOrderAction.CHANGE_ORDER_DETAILS_INITIATE,
});

export const changeOrderDetailsSuccess = (data) => ({
  type: changeOrderAction.CHANGE_ORDER_DETAILS_SUCCESS,
  payload: data,
});

export const changeOrderDetailsFailure = (data) => ({
  type: changeOrderAction.CHANGE_ORDER_DETAILS_FAILURE,
  payload: data,
});

export const changeOrderDetails= (CompanyId,projectId,changeOrderId,token) => async (dispatch) => {
  
    try {
      // const token = isLoggedIn("adminData1");
      dispatch(changeOrderDetailsInitiate());
      const response = await axios.get(`${Url}companies/${CompanyId}/projects/${projectId}/change-order/details?changeOrderId=${changeOrderId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch(changeOrderDetailsSuccess(response.data));
      } else {
        dispatch(changeOrderDetailsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(changeOrderDetailsFailure(err));
      return err.response
    }
  };