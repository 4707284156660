import React, { useEffect, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";

const SubscriptionAgreement = () =>{

    return(
        <>
        <div className="FormArea">
  <div className="ModalTitleBox">
    <h4>Subscription Agreement</h4>
  </div>
  <div className="FolderForm">
    <form>
      <div className="ModalBigheading">
        <h3>
          <span className="fw-500">Status: </span>Pending Company Signature
        </h3>
      </div>
      <div className="AgreementViewArea">
        <div className="AgreementViewTop">
          <a href="javscript:void(0)">
            <img src="images/modal-big-logo.png" />
          </a>
          <div className="AgreementViewTopRight">
            <h3>iBuild</h3>
            <p>312 Weddenburn Rd SE</p>
            <p>Calgary, AB CA, T2J 1J1</p>
            <p>
              4034005933 www.ibuildapplications.com
              linden@ibuildapplications.com
            </p>
            <p>
              “The bitterness of poor quality remains long after the sweetness
              of meeting
              <br />
              the schedule has been gorgotten”
            </p>
          </div>
        </div>
        <div className="StatementAdjustmentArea px-4">
          <h6>Statement of Adjustments</h6>
          <div className="row">
            <div className="Box50" style={{ float: "left" }}>
              <div className="form-group">
                <label>Client Name</label>
                <div style={{ borderBottom: "1px solid #000", width: "35%" }} />
              </div>
              <div className="form-group">
                <label>Address</label>
                <div style={{ borderBottom: "1px solid #000", width: "35%" }} />
              </div>
              <div className="form-group">
                <label>City</label>
                <div style={{ borderBottom: "1px solid #000", width: "35%" }} />
              </div>
              <div className="form-group">
                <label>State/Provice</label>
                <div style={{ borderBottom: "1px solid #000", width: "35%" }} />
              </div>
              <div className="form-group">
                <label>Zip Code</label>
                <div style={{ borderBottom: "1px solid #000", width: "35%" }} />
              </div>
            </div>
            <div className="Box50" style={{ float: "right" }}>
              <div className="form-group">
                <label>Email</label>
                <div style={{ borderBottom: "1px solid #000", width: "35%" }} />
              </div>
              <div className="form-group">
                <label>Phone</label>
                <div style={{ borderBottom: "1px solid #000", width: "35%" }} />
              </div>
              <div className="form-group">
                <label>Sales Consultant</label>
                <div style={{ borderBottom: "1px solid #000", width: "35%" }} />
              </div>
              <div className="form-group">
                <label>Service</label>
                <div style={{ borderBottom: "1px solid #000", width: "35%" }} />
              </div>
              <div className="form-group">
                <label>Contract Date</label>
                <div style={{ borderBottom: "1px solid #000", width: "35%" }} />
              </div>
            </div>
            <div className="col-12">
              <h6 className="Title">PLAN DETAILS</h6>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Name</label>
                <div style={{ borderBottom: "1px solid #000", width: "35%" }} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Description</label>
                <div style={{ borderBottom: "1px solid #000", width: "35%" }} />
              </div>
              <div className="StatementAdjustmentBottom">
                <label>Total Cost Pre-Tax</label>
                <p className="LightOrange">$xx.xx</p>
              </div>
            </div>
            <div className="col-12">
              <h6 className="Title">Project as Entity Options</h6>
              <div className="StatementAdjustmentBottom">
                <label>Unlimited Projects for Fixed Value</label>
                <p className="BgBlue2">$xx.xx</p>
              </div>
              <div>
                <h6 className="Title">Contract Duration</h6>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>1 Year </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Start Date</label>
                    <div style={{ borderBottom: "1px solid #000" }} />
                  </div>
                  <div className="form-group">
                    <label>End Date</label>
                    <div style={{ borderBottom: "1px solid #000" }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <h6 className="Title">Contract Code</h6>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>CO4E10DISC</label>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Benefits</label>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="col-12">
              <h6 className="Title">Payment &amp; Billing</h6>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Payment Option</label>
                  <div
                    style={{ borderBottom: "1px solid #000", width: "35%" }}
                  />
                </div>
                <div className="form-group">
                  <label>Billing Option</label>
                  <div
                    style={{ borderBottom: "1px solid #000", width: "35%" }}
                  />
                </div>
                <div className="form-group">
                  <label>Taxes</label>
                  <div
                    style={{ borderBottom: "1px solid #000", width: "35%" }}
                  />
                </div>
              </div>
              <h6
                className="Title border-0 mb-0 px-0"
                style={{
                  marginBottom: "10px!important",
                  padding: "0px!important"
                }}
              >
                Payment Dates
              </h6>
              <div className="StatementAdjustmentTable">
                <table>
                  <thead>
                    <tr>
                      <th style={{ textAlign: "left" }}>#</th>
                      <th style={{ textAlign: "center" }}>Payment Date</th>
                      <th style={{ textAlign: "center" }}>Payment Amount</th>
                      <th>
                        <div className="text-left ml-5">
                          Payment After Tax Amount
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "left" }}>1</td>
                      <td style={{ textAlign: "center" }}>07/21/2023</td>
                      <td>
                        <div className="LightGreenToo mr-5">$yy.yy</div>
                      </td>
                      <td>
                        <div
                          className="LightGreenToo mx-5"
                          style={{ textAlign: "left" }}
                        >
                          $zz.zz
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td style={{ textAlign: "center" }}>01/21/2024</td>
                      <td>
                        <div className="LightGreenToo mr-5">$yy.yy</div>
                      </td>
                      <td>
                        <div
                          className="LightGreenToo mx-5"
                          style={{ textAlign: "left" }}
                        >
                          $zz.zz
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h6 className="Title mt-5">Summary</h6>
              <div className="StatementAdjustmentBottom">
                <label>Plan 1</label>
                <p className="LightOrange">$yx.xx</p>
              </div>
              <div className="StatementAdjustmentBottom">
                <label>Unlimited Projects for Fixed Value</label>
                <p className="LightBlue2">$yx.xx</p>
              </div>
              <div className="StatementAdjustmentBottom">
                <label>Contract Code Discount(10%)</label>
                <p className="LightGreen">$yx.xx</p>
              </div>
              <div className="StatementAdjustmentBottom">
                <label>Taxes (GST 5%)</label>
                <p className="LightGrey2">$yxz.xx</p>
              </div>
              <div className="StatementAdjustmentBottom">
                <label>Grand Total</label>
                <p className="LightRed2">$zzz,zz.zz</p>
              </div>
            </div>
          </div>
          <div>
            <div style={{ width: "20%", float: "left" }}>
              <div
                className="InputBox"
                style={{
                  borderBottom: "1px solid #000",
                  height: 40,
                  marginBottom: 20
                }}
              ></div>
              <label style={{ fontWeight: 600, fontSize: 18, marginTop: 10 }}>
                Date
              </label>
            </div>
            <div style={{ width: "20%", float: "left", margin: "0 0 0 100px" }}>
              <div
                className="InputBox"
                style={{
                  borderBottom: "1px solid #000",
                  height: 40,
                  marginBottom: 20
                }}
              ></div>
              <label style={{ fontWeight: 600, fontSize: 18, marginTop: 10 }}>
                Joe Doe <br /> Sales Manager
              </label>
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div>
            <div style={{ width: "20%", float: "left" }}>
              <div
                className="InputBox"
                style={{
                  borderBottom: "1px solid #000",
                  height: 40,
                  marginBottom: 20
                }}
              ></div>
              <label style={{ fontWeight: 600, fontSize: 18, marginTop: 10 }}>
                Date
              </label>
            </div>
            <div style={{ width: "20%", float: "left", margin: "0 0 0 100px" }}>
              <div
                className="InputBox"
                style={{
                  borderBottom: "1px solid #000",
                  height: 40,
                  marginBottom: 20
                }}
              ></div>
              <label style={{ fontWeight: 600, fontSize: 18, marginTop: 10 }}>
                Joe Doe <br /> Sales Manager
              </label>
            </div>
            <div style={{ clear: "both" }} />
          </div>
        </div>
      </div>
      <div
        className="Button"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <button button="type" className="Create">
          Print
        </button>
        <button button="type" className="Create">
          Save
        </button>
        <button
          button="type"
          className="Create"
          style={{ alignItems: "center", display: "flex" }}
        >
          {" "}
          <img src="images/history_icon.png" />
          History
        </button>
        <button className="Outline">Close</button>
      </div>
    </form>
  </div>
</div>

        </>
    )

}

export default SubscriptionAgreement