import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import {
  getCreateDocumentation,
  getListDocumentation,
} from "../../../../redux/action/constructionFileManger";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { uploadAggrementFiles } from "../../../../redux/action/designSelectionsAction";
const initialState = {
  folderName: "",
  clientFile: "",
  id: "",
  fileFolderName: "",
  errors: {},
  htmlUrl: "",
};

const CreateFile = ({
  CreateFileModal,
  SetCreateFileModal,
  SetFileMangerModal,
}) => {
  const dispatch = useDispatch();
  const { allFolderList } = useSelector((state) => state.fileManagerData);

  const [iState, updateState] = useState(initialState);
  const { folderName, clientFile, id, fileFolderName, errors, htmlUrl } =
    iState;

  const handleCreateFileModalClose = () => {
    SetCreateFileModal(false);
    SetFileMangerModal(true);
  };


  const handleNewFileSubmit = async () => {

    try {
      let fileUrl="";
      if (htmlUrl) {
        const blobs = new Blob([htmlUrl], { type: "text/html" });
        const formData = new FormData();
        formData.append("files", blobs);
        const uploadResponse = await dispatch(uploadAggrementFiles(formData));
        if (uploadResponse.status === 200) {
          fileUrl = uploadResponse?.data?.data;
         //  const docxBlob =  await htmlDocx.asBlob(fileUrl)
         //  const fileNameData = await `${fileFolderName}`;
         //  var docxFile =  await new File([docxBlob],`${fileNameData}.docx`, {
         //    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
         //  });
         } 
        }
 
       const data = {
         name: fileFolderName,
         htmlUrl:fileUrl||'',
         files:clientFile,
       };
  
      const response = await dispatch(getCreateDocumentation(id, data));
  
      if (response?.status === 201) {
        await dispatch(getListDocumentation(id));
        toast.success("Create File Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setSelectedEditor("BaseTemplate")
        SetCreateFileModal(false);
            SetFileMangerModal(true);
        updateState({ ...iState ,htmlUrl:"",clientFile:"",fileFolderName:"",id:"",clientFile:""});
      } 
    } catch (error) {
      toast.error(error?.data?.message, { position: toast.POSITION.TOP_RIGHT });
      updateState({ ...iState,});
    }
  };

  const handleNewFileClose = () => {
    SetCreateFileModal(false);
    updateState({
      ...iState,
      errors: {},
      fileFolderName: "",
      clientFile: "",
      id: "",
    });
  };

  const handleRemoveClientFile = () => {
    // Reset the 'templateFile' state to null
    updateState({ ...iState, clientFile: null });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#createFileInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleNewFileValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!fileFolderName) {
      error.nameError = "* Name can't be empty";
      formIsValid = false;
    }

    if (!id) {
      error.foldNameError = "* Please select folder name";
      formIsValid = false;
    }

    if (!clientFile) {
      error.cltfileError = "* Please select file";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: error,
    });
    return formIsValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  const handleFileChange = (event, type) => {
    const file = event?.target?.files[0];
    {
      updateState({ ...iState, clientFile: file });
    }
  };

  const [selectedEditor, setSelectedEditor] = useState("BaseTemplate");
  const handleSelectedEditorChange = (e) => {
    const newSelectedEditor = e.target.value;
    if (newSelectedEditor === "CreateNewFile") {
      updateState({ ...iState, clientFile: "" });
    } else if (newSelectedEditor === "BaseTemplate") {
      updateState({ ...iState, htmlUrl: "" });
    }
    setSelectedEditor(newSelectedEditor);
  };

  const editorSelectedRef = useRef(null);
  const handleSelectedEditor = (data) => {
    updateState((prevState) => ({
      ...prevState,
      htmlUrl: data,
      fileFolderName,
    }));
  };
  return (
    <Modal
      show={CreateFileModal}
      className="ModalBox modal fade SmallModal show"
      style={{ display: "block" }}
    >
      <a
        class="CloseModal"
        data-toggle="modal"
        onClick={handleCreateFileModalClose}
      >
        ×
      </a>

      <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Create New File</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="form-group">
                      <h6>Name</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert new entry name"
                        name="fileFolderName"
                        value={fileFolderName}
                        onChange={handleInputChange}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors?.nameError}
                      </span>
                    </div>
                    <div className="form-group">
                      <h6>Folder</h6>
                      <div className="Categories Categories1">
                        <div className="Categories_all Categories_all1">
                          <label>
                            List of folders where user has permission to create
                            file
                          </label>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <div className="">
                            <select
                              className="form-control"
                              name="id"
                              value={id}
                              // onFocus={handleValidation}
                              onChange={handleInputChange}
                            >
                              <option value="">Select</option>
                              {allFolderList?.length > 0 &&
                                allFolderList?.map((item) => {
                                  return (
                                    <option value={item.id}>
                                      {item.folderName}{" "}
                                    </option>
                                  );
                                })}
                            </select>
                            <span style={{ color: "red" }}>
                              {errors?.foldNameError}
                            </span>
                          </div>
                        </ul>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <h6>Editor Option</h6>
                      <div className="mb-4">
                        <label className="Radio">
                          Create file with base template upload
                          <input
                            type="radio"
                            name="editor"
                            value="BaseTemplate"
                            checked={selectedEditor === "BaseTemplate"}
                            onChange={handleSelectedEditorChange}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div>
                        <label className="Radio">
                          Create new file with editor
                          <input
                            type="radio"
                            name="editor"
                            value="CreateNewFile"
                            checked={selectedEditor === "CreateNewFile"}
                            onChange={handleSelectedEditorChange}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                    <div className="file RadioText"></div>
                    <div
                      className="text RadioText"
                      style={{ display: "block" }}
                    >
                      {selectedEditor === "BaseTemplate" &&
                       <>
                        <div className="form-group">
                          <h6>Select Template File (Docx)</h6>
                          <div className="Upload">
                            <span>-Drag and Drop Files or click to select</span>
                            <input
                              type="file"
                              id="createFileInput"
                              accept=".doc,.docx"
                              onChange={(e) =>
                                handleFileChange(e, "createFile")
                              }
                            />
                          </div>
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {errors?.cltfileError}
                          </span>
                        </div>
                    
                        <div className="FilesAdded">
                          <label>{clientFile?.name}</label>
                          <span>
                            <img src="images/fileview.png" />
                          </span>
                          <span onClick={handleRemoveClientFile}>
                            <img src="images/Cross.png" className="cross" />
                          </span>
                        </div>
                        </>
                      }

                      {selectedEditor === "CreateNewFile" && (
                        <div className="mce-pagebreak">
                          <Editor
                            apiKey="rye4mz748ekd8ke0loyu8j7gq7iu1tkxs3nticj32j848g6r" // Use your premium API key
                            onInit={(evt, editor) =>
                              (editorSelectedRef.current = editor)
                            }
                            value={htmlUrl}
                            init={{
                              height: 500,
                              menubar:
                                "file edit view insert format tools table help",
                              plugins: [
                                "advlist",
                                "autolink",
                                "lists",
                                "link",
                                "image",
                                "charmap",
                                "print",
                                "preview",
                                "anchor",
                                "searchreplace",
                                "visualblocks",
                                "code",
                                "fullscreen",
                                "insertdatetime",
                                "media",
                                "table",
                                "paste",
                                "help",
                                "wordcount",
                                "lists",
                                "table",
                                "pagebreak",
                                "spellchecker",
                                "powerpaste",
                                "mediaembed",
                                "advcode",
                                "emoticons",
                                "codesample",
                                "advtable",
                                "visualchars",
                                "visualblocks",
                                "linkchecker",
                                "checklist",
                                "media",
                                "tableofcontents",
                                "a11ychecker",
                                "permanentpen",
                                "casechange",
                                "pageembed",
                                "nonbreaking",
                                "quickbars",
                                "advlist",
                                "autosave",
                                "autolink",
                                "autoresize",
                              ],
                              toolbar:
                                "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | " +
                                "alignleft aligncenter alignright alignjustify | numlist bullist checklist outdent indent | " +
                                "forecolor backcolor casechange permanentpen formatpainter removeformat | " +
                                "pagebreak | charmap emoticons | fullscreen preview save print | insertfile image media pageembed link anchor codesample | " +
                                "a11ycheck ltr rtl | showcomments addcomment",

                              toolbar_sticky: true,

                              autosave_ask_before_unload: true,
                              autosave_interval: "30s",
                              autosave_prefix: "{path}{query}-{id}-",
                              autosave_restore_when_empty: true,
                              autosave_retention: "2m",

                              image_advtab: true,

                              content_css: "default",
                              importcss_append: true,

                              image_title: true,
                              automatic_uploads: true,

                          

                              // Handle drag and drop events
                              setup: (editor) => {
                                editor.on("drop", (e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  // Handle dropped files
                                  if (e.dataTransfer && e.dataTransfer.files) {
                                    const files = e.dataTransfer.files;
                                    for (const file of files) {
                                      const reader = new FileReader();
                                      reader.onload = (event) => {
                                        // Insert image at the cursor position
                                        editor.insertContent(
                                          `<img src="${event.target.result}" alt="${file.name}"/>`
                                        );
                                      };
                                      reader.readAsDataURL(file);
                                    }
                                  }
                                });
                              },

                              link_list: [
                                {
                                  title: "My page 1",
                                  value: "https://www.tinymce.com",
                                },
                                {
                                  title: "My page 2",
                                  value: "http://www.moxiecode.com",
                                },
                              ],
                              image_list: [
                                {
                                  title: "My image 1",
                                  value:
                                    "https://www.tiny.cloud/images/glyph-tinymce.svg",
                                },
                                {
                                  title: "My image 2",
                                  value:
                                    "https://www.tiny.cloud/docs/images/glyph-tinymce.svg",
                                },
                              ],
                              image_class_list: [
                                { title: "None", value: "" },
                                { title: "Some class", value: "some-class" },
                              ],
                              importcss_file_filter: "main.css",
                              templates: [
                                {
                                  title: "New Table",
                                  description: "creates a new table",
                                  content:
                                    "<table><tr><td>Sample Text</td></tr></table>",
                                },
                                {
                                  title: "Starting my story",
                                  description: "A sample document",
                                  content: "Once upon a time...",
                                },
                                {
                                  title: "New list with dates",
                                  description: "New List with Dates",
                                  content:
                                    "<ul><li>12 December</li><li>13 December</li></ul>",
                                },
                              ],
                              template_cdate_format:
                                "[CDATE: %m/%d/%Y : %H:%M:%S]",
                              template_mdate_format:
                                "[MDATE: %m/%d/%Y : %H:%M:%S]",
                              height: 600,
                              image_caption: true,
                              quickbars_selection_toolbar:
                                "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                              noneditable_class: "mceNonEditable",
                              toolbar_mode: "sliding",
                              spellchecker_dialog: true,
                              spellchecker_whitelist: ["Ephox", "Moxiecode"],

                              tinycomments_mode: "embedded",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                              a11y_advanced_options: true,

                              branding: false, // Remove TinyMCE branding
                              powerpaste_word_import: "clean", // Configure PowerPaste
                              powerpaste_html_import: "merge",
                              powerpaste_allow_local_images: true,

                              // Enable live media embeds
                              media_live_embeds: true,

                              // Enable line breaks
                              forced_root_block: "",
                              force_br_newlines: true,
                              force_p_newlines: false,
                            }}
                            onEditorChange={handleSelectedEditor}
                          />
                        </div>
                      )}
                    </div>
                    <div className="Button d-flex justify-content-between mt-5">
                      <button
                        className="Create FolderPermissionId"
                        onClick={handleNewFileSubmit}
                      >
                        Create File
                      </button>
                      <button className="Outline" onClick={handleNewFileClose}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
    </Modal>
  );
};

export default CreateFile;
