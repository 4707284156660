




      // <div class="FormArea">
      //         <div class="ModalTitleBox">
      //           <h4>Upload Files</h4>
      //         </div>
      //         <div class="FolderForm">
      //           <form>
      //             <div class="form-group">
      //               <h6>Folder Name</h6>
      //               <div class="Categories Categories1">
      //                 <div class="Categories_all Categories_all1">
      //                   <span>Select a folder to insert the file</span>
      //                   <img src="images/down.png" />
      //                 </div>
      //                 <ul >
      //                   <li>
      //                     <input type="text" placeholder="-Search" />
      //                     <span class="Search"><img src="images/search.png" /></span>
      //                   </li>
      //                   <li class="CursorPointer" data-dismiss="modal">
      //                     - New Folder
      //                   </li>
      //                   <li>- Full list of folders loaded from the system</li>
      //                 </ul>
      //               </div>
      //             </div>
      //             <div class="form-group">
      //               <h6>Select Files</h6>
      //               <div class="Upload">
      //                 <span>-Drag and Drop Files or click to select</span>
      //                 <input type="file" />
      //               </div>
      //             </div>
      //             <div class="Button mb-4">
      //               <button class="Create">Add</button>
      //             </div>
      //             <div class="form-group mb-2">
      //               <h4>Files</h4>
      //             </div>
      //             <div class="FilesAdded">
      //               <label>File_name.docs</label>
      //               <span><img src="images/fileview.png" /></span>
      //               <span class="cross"><img src="images/Cross.png" /></span>
      //             </div>
      //             <div class="FilesAdded">
      //               <label>File_name.docs</label>
      //               <span><img src="images/fileview.png" /></span>
      //               <span class="cross"><img src="images/Cross.png" /></span>
      //             </div>
                  
      //             <div class="Button d-flex justify-content-between">
      //               <button class="Create" data-toggle="modal" data-target="#ClientDocumentation2" data-dismiss="modal">Save</button>
      //               <button class="Outline" data-toggle="modal" data-target="#ClientDocumentation2" data-dismiss="modal">Save as Draft</button>
      //             </div>
      //           </form>
      //         </div>
      //       </div>
     


import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import {toast} from "react-toastify"
import { getCreateDocumentation, getListDocumentation } from "../../../../redux/action/constructionFileManger";
import { useDispatch, useSelector } from "react-redux";



const initialState={  folderName: "",
clientFile:"",
id:"",
fileFolderName:"",
errors: {},}



const UploadFile = ({ UploadFileModal, SetUploadFileModal,SetFileMangerModal }) => {
  const handleUploadFileModalClose = () => {
    SetUploadFileModal(false);
    SetFileMangerModal(true)
  };  const dispatch = useDispatch()
  const {
    allFolderList,
 
  } = useSelector((state) => state.fileManagerData);

  const [iState, updateState] = useState(initialState)
  const {folderName,clientFile,id, fileFolderName,errors} =iState





  const handleNewFileSubmit = () => {
    let formIsValid = handleNewFileValidation();
    if (formIsValid) {
      const dataa = {
        name: fileFolderName,
        files: clientFile,
        clientView: true,

      };
      dispatch(getCreateDocumentation(id, dataa))
        .then((res) => {
          console.log("message", res);
          if (res.status === 201) {
            dispatch(getListDocumentation(id));
            toast.success("Create File Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });

            SetUploadFileModal(false)
         
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({ ...iState, NewFileModal: true });
          }
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, NewFileModal: true });
        });
    }
  };



  const handleNewFileClose = () => {
    SetUploadFileModal(false)
    updateState({
      ...iState,
      errors: {},
      fileFolderName: "",
      clientFile: "",
      id: "",
    });
  };


  const handleRemoveClientFile = () => {
    // Reset the 'templateFile' state to null
    updateState({ ...iState, clientFile: null });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#createFileInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  
const handleNewFileValidation = () => {
  let formIsValid = true;
  let error = {};

  if (!fileFolderName) {
    error.nameError = "* Name can't be empty";
    formIsValid = false;
  }

  if (!id) {
    error.foldNameError = "* Please select folder name";
    formIsValid = false;
  }

  if (!clientFile) {
    error.cltfileError = "* Please select file";
    formIsValid = false;
  }

  updateState({
    ...iState,
    errors: error,
  });
  return formIsValid;
};


const handleInputChange = (e) => {
  const { name, value } = e.target;
  updateState({
    ...iState,
    [name]: value,
  });
};

const handleFileChange = (event, type) => {
  const file = event?.target?.files[0];{

    updateState({ ...iState, clientFile: file });
  }
};



  return (
    <Modal
      show={UploadFileModal}
      className="ModalBox modal fade SmallModal show"
      style={{ display: "block" }}
    >
      <a
        class="CloseModal"
        data-toggle="modal"
        onClick={handleUploadFileModalClose}
      >
        ×
      </a>

 
              <div class="FolderForm">
                
              <div className="FormArea">
                  <div className="ModalTitleBox">
                          <h4>Upload Files</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="form-group">
                      <h6>Name</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert new entry name"
                        name="fileFolderName"
                        value={fileFolderName}
                        onChange={handleInputChange}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors?.nameError}
                      </span>
                    </div>
                    <div className="form-group">
                      <h6>Folder</h6>
                      <div className="Categories Categories1">
                        <div className="Categories_all Categories_all1">
                          <span>
                            Select folder where file should be created
                          </span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          {/* <li>
                            <input type="text" placeholder="-Search" />
                            <span className="Search">
                              <img src="images/search.png" />
                            </span>
                          </li> */}
                          {/* <li>
                             
                            </li> */}
                          <div className="form-group">
                            <label>
                              list of folders where user has permission to
                              create file
                            </label>
                            <select
                              className="form-control"
                              name="id"
                              value={id}
                              // onFocus={handleValidation}
                              onChange={handleInputChange}
                            >
                              <option value="">Select</option>
                              {allFolderList?.length > 0 &&
                                allFolderList?.map((item) => {
                                  return (
                                    <option value={item.id}>
                                      {item.folderName}{" "}
                                    </option>
                                  );
                                })}
                            </select>
                            <span style={{ color: "red" }}>
                              {errors?.foldNameError}
                            </span>
                          </div>
                        </ul>
                      </div>
                    </div>
                    {/* <div className="form-group mb-4">
                      <h6>Editor Option</h6>
                      <div className="mb-4">
                        <label className="Radio">
                          Create file with base template upload
                          <input
                            type="radio"
                            name="Cuisine"
                            // defaultValue="text"
                            // defaultChecked=""
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div>
                        <label className="Radio">
                          Create new file with editor
                          <input
                            type="radio"
                            name="Cuisine"
                            defaultValue="file"
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div> */}
                    <div className="file RadioText"></div>
                    <div
                      className="text RadioText"
                      style={{ display: "block" }}
                    >
                      <div className="form-group">
                        <h6>Select Template File (Docx)</h6>
                        <div className="Upload">
                          <span>-Drag and Drop Files or click to select</span>
                          <input
                            type="file"
                            id="createFileInput"
                            onChange={(e) => handleFileChange(e, "createFile")}
                          />
                        </div>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errors?.cltfileError}
                        </span>
                      </div>
                      {clientFile && (
                        <div className="FilesAdded">
                          <label>{clientFile?.name}</label>
                          <span>
                            <img src="images/fileview.png" />
                          </span>
                          <span onClick={handleRemoveClientFile}>
                            <img src="images/Cross.png" className="cross" />
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="Button d-flex justify-content-between mt-5">
                   

                                   <button class="Create"  onClick={handleNewFileSubmit} data-dismiss="modal">Save</button>
                    <button class="Outline"   onClick={handleNewFileClose} >Save as Draft</button>

                    </div>
                  </div>
                </div>
              </div>
     
    </Modal>
  );
};



export default UploadFile;
