import { vendorActionTypes } from "../actionTypes";
const initialState = {
  loader: false,
vendorTypesData:[],
vendorListData:[],
vendorDetailsData:[],
contactListData:[],
addressListData:[],
prefferedListData:[]
};

export default function constructionVendor(state = initialState, { type, payload }) {
  switch (type) {
    case vendorActionTypes.VENDOR_TYPES_INITIATE: {
        return {
          ...state,
          vendorTypesData: [],
          loader:true
        };
      }
      case vendorActionTypes.VENDOR_TYPES_SUCCESS: {
        return {
          ...state,
          vendorTypesData: payload,
          loader:false
        };
      }
      case vendorActionTypes.VENDOR_TYPES_FAILURE: {
        return {
          ...state,
          vendorTypesData: payload.data,
          loader:false
        };
      }

      case vendorActionTypes.VENDOR_LIST_INITIATE: {
        return {
          ...state,
          vendorListData: [],
          loader:true
        };
      }
      case vendorActionTypes.VENDOR_LIST_SUCCESS: {
        return {
          ...state,
          vendorListData: payload,
          loader:false
        };
      }
      case vendorActionTypes.VENDOR_LIST_FAILURE: {
        return {
          ...state,
          vendorListData: payload.data,
          loader:false
        };
      }

      case vendorActionTypes.VENDOR_DETAILS_INITIATE: {
        return {
          ...state,
          vendorDetailsData: [],
          loader:true
        };
      }
      case vendorActionTypes.VENDOR_DETAILS_SUCCESS: {
        return {
          ...state,
          vendorDetailsData: payload.details,
          loader:false
        };
      }
      case vendorActionTypes.VENDOR_DETAILS_FAILURE: {
        return {
          ...state,
          vendorDetailsData: payload.data,
          loader:false
        };
      }

      case vendorActionTypes.CONTACT_LIST_INITIATE: {
        return {
          ...state,
          contactListData: [],
          loader:true
        };
      }
      case vendorActionTypes.CONTACT_LIST_SUCCESS: {
        return {
          ...state,
          contactListData: payload,
          loader:false
        };
      }
      case vendorActionTypes.CONTACT_LIST_FAILURE: {
        return {
          ...state,
          contactListData: payload.data,
          loader:false
        };
      }

      case vendorActionTypes.ADDRESS_LIST_INITIATE: {
        return {
          ...state,
          addressListData: [],
          loader:true
        };
      }
      case vendorActionTypes.ADDRESS_LIST_SUCCESS: {
        return {
          ...state,
          addressListData: payload,
          loader:false
        };
      }
      case vendorActionTypes.ADDRESS_LIST_FAILURE: {
        return {
          ...state,
          addressListData: payload.data,
          loader:false
        };
      }
 
      case vendorActionTypes.PREFFERED_COST_LIST_INITIATE: {
        return {
          ...state,
          prefferedListData: [],
        };
      }
      case vendorActionTypes.PREFFERED_COST_LIST_SUCCESS: {
        return {
          ...state,
          //   loader: false,
          prefferedListData: payload,
        };
      }
      case vendorActionTypes.PREFFERED_COST_LIST_FAILURE: {
        return {
          ...state,
          prefferedListData: payload.data,
        };
      }

    default:
      return state;
  }
}
