import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { CreateUnitBlockAction, markCompleteMultiFamilyJobAction } from "../../redux/action/MultifamilyJobActionDk";

import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const CreateUnits = ({
  createUnitsModal,
  setCreateUnitsModal,
  setCreateBlockModal,
  iState,
  units,
  jobId,
  selectedId,
  createdFrom,
  UpdateState,

  jobData
}) => {
  const dispatch = useDispatch();

  const handleJobDetialsModalClose = () => {
    setCreateUnitsModal(false);
   
  };
  const handleJobDetialsModalShow = () => {
    setCreateUnitsModal(true);
  };

  const ProjectId ="";


  const [blocks, setBlocks] = useState([]);

  useEffect(() => {
    let temp;

    if (units) {
      temp = units?.map((item) => {
        return {
          "blockDescription": item?.blockDescription,
          "foundationType": item?.foundationType,
          "numOfUnits": item?.numOfUnits,
          "numOfStories": item?.numOfStories,
          
          units:createdFrom=="home"?item?.MultiUnits?.map((ele)=> {return { unitNumber:ele.unitNumber ,
            unitsSqFt: ele.unitsSqFt,
            numOfBedrooms: ele.numOfBedrooms,
            numOfBathrooms: ele.numOfBathrooms,
            basementDev: false,
          }} ): Array(+item?.numOfUnits).fill({
            unitNumber: "0",
            unitsSqFt: "0",
            numOfBedrooms: "0",
            numOfBathrooms: "0",
            basementDev: false,
          }),
        };
      });

      setBlocks(temp);
    }
  }, [units]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = true;

    let data = {
      "numOfBlocks": blocks?.length,
      blocks
   
      
    };

    if (formIsValid) {
      dispatch(CreateUnitBlockAction(selectedId ,jobId,data))
      .then((res) => {
          if (res.data.message == "Multi Family Job updated successfully") {
            toast.success("Multi Family Job created successfully", {
              position: toast.POSITION.TOP_RIGHT,
            });

            setCreateUnitsModal(false);
          } else {
            setCreateUnitsModal(false);

            toast.error(res.data.message, {
              
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          setCreateUnitsModal(false);

          toast.success(error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  return (
    <Modal show={createUnitsModal} className="ModalBox LargeModal">
      <div class="modal-content BgCreate">
        <a
          class="CloseModal"
          onClick={handleJobDetialsModalClose}
          data-dismiss="modal"
        >
          ×
        </a>
        <form>
        <div class="ModalTitleBox">
              <h4>Create Multi-Family Units</h4>
            </div>
          <div class="FolderFormCreateArea">
            <div class="ModalMediumheading">
            <h3><span>Project:</span> {jobData?.projectName}</h3>
                    <h3><span>Job id:</span> {jobData?.jobNumber}</h3>

            </div>
            <div class="ModalMediumheading">
              <h3>
                <span>{jobData?.jobType}</span>
              </h3>
            </div>
            <div class="EnterProjectTable UnitSetupTable">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Block's Units #</th>
                    <th>Unit's Sq/Ft</th>
                    <th># of Bedrooms</th>
                    <th># of Bathrooms</th>
                    <th>Basement Development</th>
                  </tr>
                </thead>
                <tbody>
                  {blocks?.map((ele, index) => (
                    <>
                      <tr>
                        <td colspan="6">
                          <div class="Action EnterProjectTableAction">
                            <h6 class="mb-0">{ele?.blockDescription}</h6>
                            <img src="images/block_icon.png" class="BlockImg" />
                            <div class="CommonSelectBox">
                              <select>
                                <option selected="">Select Lot Type</option>
                                <option>Lot type 1</option>
                              </select>
                              <span>
                                <img src="images/down.png" />
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      {ele?.units?.map((item, i) => (
                        <tr>
                          <td>Unit {i + 1}</td>
                          <td>
                            <div class="form-group">
                              <input
                                onChange={(e) => {
                                  let temp = blocks[index];
                                  let temp2 = temp["units"];
                                  temp2[i] = {
                                    ...temp2[2],
                                    unitNumber: e.target.value,
                                  };

                                  let value = [...blocks];

                                  setBlocks(value);
                                }}
                                type="number"

                                value={item?.unitNumber}
                                class="form-control"
                                placeholder={""}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input
                                onChange={(e) => {
                                  let temp = blocks[index];
                                  let temp2 = temp["units"];
                                  temp2[i] = {
                                    ...temp2[2],
                                    unitsSqFt: e.target.value,
                                  };

                                  let value = [...blocks];

                                  setBlocks(value);
                                }}
                                type="number"
                                                                value={item?.unitsSqFt}

                                class="form-control"
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input
                                type="number"
                                class="form-control"
                                value={item?.numOfBedrooms}
                                onChange={(e) => {
                                  let temp = blocks[index];
                                  let temp2 = temp["units"];
                                  temp2[i] = {
                                    ...temp2[2],
                                    numOfBedrooms: e.target.value,
                                  };

                                  let value = [...blocks];

                                  setBlocks(value);
                                }}
                                placeholder=""
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input
                                type="number"
                                                                value={item?.numOfBathrooms}

                                onChange={(e) => {
                                  let temp = blocks[index];
                                  let temp2 = temp["units"];
                                  temp2[i] = {
                                    ...temp2[2],
                                    numOfBathrooms: e.target.value,
                                  };

                                  let value = [...blocks];

                                  setBlocks(value);
                                }}
                                class="form-control"
                                placeholder=""
                              />
                            </div>
                          </td>
                          <td>
                            <div class="text-center">
                              <label class="CheckBox">
                                <input type="checkbox" />
                                <span class="checkmark"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  ))}
                </tbody>
              </table>
              <div class="PaginationArea mt-5 px-3">
                <h6>Showing 4 of 4 block entries</h6>
                <h6>
                  <img src="images/leftarrow.png" /> 01{" "}
                  <img src="images/rightarrow.png" /> Out 10
                </h6>
              </div>
            </div>
          </div>

          <div class="Button d-flex justify-content-between align-items-center px-5">
            <button
              onClick={() => {
                setCreateUnitsModal(false);
              }}
              class="Outline"
              data-toggle="modal"
              data-target="#CreateMultiFamilyJobsBlocks2"
              data-dismiss="modal"
            >
              Back
            </button>
            <button onClick={handleSubmit} class="Create">
              Create Blocks &amp; Units
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateUnits;
