import React from "react";
import { Link } from "react-router-dom";

const CreateNewPassword = () => {
  return (
    <div>
      <div className="LoginArea">
        <div className="LoginBoxBig">
          <div className="row">
            <div className="col-md-6">
              <div className="LoginBoxLeft">
                <figure>
                  <img src="images/login_background.png" />
                </figure>
              </div>
            </div>
            <div className="col-md-6">
              <div className="LoginBox">
                <div className="Logo">
                  <img src="images/Logo.png" />
                </div>
                <ul className="nav nav-fill">
                  <li className="nav-item">
                    <a
                      href="#ClientView"
                      className="nav-link active"
                      data-toggle="tab"
                    >
                      Client View
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#TradeView" className="nav-link" data-toggle="tab">
                      Trade View
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane fade show active" id="ClientView">
                    <h3>Create a new Password</h3>
                    <p>
                      Your password must be at least six characters and should
                      include a combination of special characters, numbers,
                      lower case and upper case letters
                    </p>
                    <form>
                      <div className="form-group">
                        <label>New Password</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter text"
                        />
                      </div>
                      <div className="form-group">
                        <label>Confirm New Password</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter text"
                        />
                      </div>
                      <a className="Button" href="new_password_created.html">
                        Reset Password
                      </a>
                    </form>
                  </div>
                  <div className="tab-pane fade" id="TradeView">
                    <h3>Create a new Password</h3>
                    <p>
                      Your password must be at least six characters and should
                      include a combination of special characters, numbers,
                      lower case and upper case letters
                    </p>
                    <form>
                      <div className="form-group">
                        <label>New Password</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter text"
                        />
                      </div>
                      <div className="form-group">
                        <label>Confirm New Password</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter text"
                        />
                      </div>
                      <Link className="Button" to="/new-password-created">
                        Reset Password
                      </Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewPassword;
