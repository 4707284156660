import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";
import moment from "moment";
import {toast} from "react-toastify"
import { updateDefaultPhaseAction, updateDefaultTaskAction } from "../../../redux/action/constructionsAction";
import { useDispatch } from "react-redux";

const EditPhase = ({
  setEditPhaseModal,
  setDefaultSystem,
  EditPhaseModal,
  taskList,
  scheduleState,
}) => {
  const handleEditPhaseModalClose = () => {
    setDefaultSystem(true);
    setEditPhaseModal(false);
  };

  const dispatch = useDispatch()

  let taskTemp = taskList?.Statictasks;
  let obj = taskTemp[0];
  obj["startDate"] = scheduleState.startDate;

  const [taskData, setTaskData] = useState(taskTemp);



  
  const handleEditPhase = (e) => {
    e.preventDefault();

    const date1 = moment(scheduleState.startDate);
const date2 = moment(taskData[taskData.length -1].endDate);
const daysDifference = date2.diff(date1, 'days');


    let data ={
      startDate: scheduleState.startDate,
      endDate : taskData[taskData.length -1].endDate,
      duration : daysDifference,
      id:taskList?.id

    }

    let formIsValid =true


    if (formIsValid) {
      dispatch(updateDefaultPhaseAction(data))
        .then((res) => {
          if (res.status == 201) {
            setEditPhaseModal(false);
            setDefaultSystem(true)
            toast.success(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });


        dispatch(updateDefaultTaskAction({dataArr: taskData}))
        .then((res) => {
          if (res.status == 201) {
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };
  return (
    <Modal
      show={EditPhaseModal}
      onHide={handleEditPhaseModalClose}
      className="ModalBox MediumModal"
    >
      <div class="modal-content">
        <a
          onClick={handleEditPhaseModalClose}
          className="CloseModal"
          data-dismiss="modal"
        >
          ×
        </a>

        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Edit Phase</h4>
          </div>
          <div class="TableHeadingArea">
            <div class="FilesHead">
              <div class="FilesLeft d-flex flex-column align-items-start">
                <h4 class="mb-3">Phase</h4>
                <div class="Search">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Site Preparation"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="TableHeading">
            <h4>Task List</h4>
          </div>
          <div class="TableList CommonHeading TableHeightScroll">
            <table>
              <thead>
                <tr>
                  <th>WBS</th>
                  <th>Task Name</th>
                  <th>Duration</th>
                  <th>Predecessors</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {taskData?.map((task, i) => (
                  <tr>
                    <td>{task?.coast_code}</td>
                    <td>{task?.taskName}</td>
                    <td>
                      <input
                        type="number"
                        onChange={(e) => {
                          let obj = taskData[i];



                          var futureDate = moment(task?.startDate, "YYYY-MM-DD").add(e.target.value, 'days');


                          taskData[i] = {
                            ...obj,
                            duration: e.target.value,
                            endDate: moment(futureDate).format("YYYY-MM-DD")
                          };
                          
                          if(i< taskData?.length-1){
                            let nextObj = taskData[i+1]

                            taskData[i+1] = {
                              ...nextObj,
                              startDate: moment(futureDate).format("YYYY-MM-DD")
                            };
  


                          }


                          let value = [...taskData];

                          setTaskData(value);
                        }}
                        className="form-control"
                      />
                    </td>
                    <td></td>
                    <td>
                      <div class="TableThreeDots">
                        <ul class="">
                          <li class="dropdown">
                            <a
                              class=""
                              id="navbarDropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol class="dropdown-menu dropdown-menuwidth">
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/Action-1.png" />
                                  Edit Task
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/add-task.png" />
                                  Add Task
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/dropdown-delete.png" />
                                  Delete Task
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div class="Button d-flex mt-4">
            <button class="Create" onClick={handleEditPhase}>
              Update Phase
            </button>
            <button class="Outline ml-3" onClick={handleEditPhaseModalClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditPhase;
