
export function multiPartData(data, type = "") {
  console.log("------>", data)
  let multiPart = new FormData();
  for (let key in data) {
    if (key === "file" || key === "images" || key == "files") {
      if (Array.isArray(data[key])) {
        data[key].forEach((file, index) => {
          multiPart.append(`${key}_${index}`, file);
        });
      } else {
        multiPart.append(key, data[key]); // Handle a single file case
      }
    } 
    else if ( key == "purchaseDetails") {
      data[key].forEach((obj, index) => {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            multiPart.append(`purchaseDetails[${index}][${key}]`, obj[key]);
          }
        }
      });
     
    }
    else if (key == "entryAccount") {
      data[key].forEach((obj, index) => {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            multiPart.append(`entryAccount[${index}][${key}]`, obj[key]);
          }
        }
      });
     
    }

    else if (key == "warrantyNotes") {
      data[key].forEach((obj, index) => {      
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            multiPart.append(`warrantyNotes[${index}][${key}]`, obj[key]);
          }
        }
      });
     
    }
  
  
    else if (key == "assignStaffs") {
      console.log("------>", data, data[key], key)
      data[key].forEach((obj, index) => {
        
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            multiPart.append(`assignStaffs[${index}][${key}]`, obj[key]);
          }
        }
      });
     
    }
    else if (key =="supportingDocFiles") {
    
      if(typeof data[key] === "object"){
        multiPart.append(key, data[key]);
      }else{
        data[key].forEach((obj, index) => {      
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              multiPart.append(`supportingDocFiles[${index}][${key}]`, obj[key]);
            }
          }
        });
       
      }
     
    }
   else if (key == "assignTrade" || key === "toDoNotes" ) {
    const assignTradeKeys = Object.keys(data[key]);
      assignTradeKeys.forEach(subKey => {
        multiPart.append(`${key}[${subKey}]`, data[key][subKey]);
      });

     
    }
    else {
      multiPart.append(key, data[key]);
    }
  } 

  return multiPart;
}


export function multiPartDatas(data, type='') {
  const multiPart = new FormData();
  for (const [key, value] of Object.entries(data)) {
    if (type === 'multiple' && key === 'blogImage') {
      value.forEach(file => multiPart.append(key, file));
    } else if (key !== 'blogImage') {
      multiPart.append(key, value);
    }
  }
  return multiPart;
}
export function calculateDateDifference(input) {
  const currentDate = new Date();
  const futureDate = new Date(currentDate.getTime()+ 24 * 60 * 60 * 1000 * Number(input));
  return futureDate.toISOString().slice(0, -1);
}

export function convertToISOFormat(readableDate) {
  const currentDate = new Date();
  const futureDate = new Date(currentDate.getTime(readableDate) )
  //  + 24 * 60 * 60 * 1000 * Number(input));
  return futureDate.toISOString().slice(0, -1);
}

export function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  return `${year}-${month}-${date}`;
}

export function formatDate (dateString){
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based month index
  const day = ('0' + date.getDate()).slice(-2);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  const seconds = ('0' + date.getSeconds()).slice(-2);
  const milliseconds = ('00' + date.getMilliseconds()).slice(-3);

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
};


export const calculateDateDifferenceInDays = (createdAt, updatedAt) => {

  const createdDate = new Date(createdAt);
  const updatedDate = new Date(updatedAt);

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = updatedDate - createdDate;

  // Convert milliseconds to days (1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
  const millisecondsInADay = 24 * 60 * 60 * 1000;
  const differenceInDays = differenceInMilliseconds / millisecondsInADay;

  // Return the difference rounded to the nearest integer (if you need it as an integer)
  return Math.round(differenceInDays);
};


export const calculateAdjustedDeadline = (date, days, type) => {
  if (!date) return "";

  const selectedDate = new Date(date);
  const daysToAddOrSubtract = parseInt(days, 10);

  if (type === "before") {
    selectedDate.setDate(selectedDate.getDate() - daysToAddOrSubtract);
  } else {
    selectedDate.setDate(selectedDate.getDate() + daysToAddOrSubtract);
  }

  // Format the date to "YYYY-MM-DD"
  const year = selectedDate.getFullYear();
  const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
  const day = String(selectedDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};
