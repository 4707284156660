import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import SelectVendor from "./SelectVendor";

const SendClientInfo = ({
  SendClientInfoModal,
  SetSendClientInfoModal,
  SetFileMangerModal,
}) => {
  const [SelectVendorModal, SetSelectVendorModal] = useState(false);
  const handleSendClientInfoModalClose = () => {
    SetSendClientInfoModal(false);
    SetFileMangerModal(true);
  };
  return (
    <>
      <Modal
        show={SendClientInfoModal}
        className="ModalBox modal fade SmallModal show"
        style={{ display: "block" }}
      >
        <a
          class="CloseModal"
          data-toggle="modal"
          onClick={handleSendClientInfoModalClose}
        >
          ×
        </a>
        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Send Client Information</h4>
          </div>
          <div class="FolderForm">
            <form>
              <div class="ModalBigheading2">
                <h3>Send Client Information</h3>
              </div>

              <div class="form-group">
                <h6>Message to Receiver</h6>
                <textarea
                  id="editornew3"
                  class="form-control"
                  rows="5"
                ></textarea>
              </div>

              <div class="SelectAvailableArea">
                <h5>Select Available Documents</h5>
                <div class="SelectAvailableAreaBox">
                  <h6>Sales Documents + Contracts</h6>
                  <div class="form-group">
                    <label class="CheckBox">
                      {" "}
                      CTC Agreement
                      <input type="checkbox" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label class="CheckBox">
                      {" "}
                      Sales Contract
                      <input type="checkbox" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>

                <div class="SelectAvailableAreaBox">
                  <h6>Change Order</h6>
                  <div class="form-group">
                    <label class="CheckBox">
                      {" "}
                      Change Orders
                      <input type="checkbox" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="ModalFilterAreaBox mb-3">
                <h4 class="mb-4">Select Recipient</h4>
                <div class="RadioArea d-flex  flex-column">
                  <div class="form-group">
                    <label class="Radio">
                      Registered Vendor
                      <input
                        type="radio"
                        name="Cuisine"
                        value="text"
                        checked=""
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>

                  <div class="form-group mb-4">
                    <label class="Radio">
                      Other Contact
                      <input type="radio" name="Cuisine" value="file" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="file RadioText">
                <div class="form-group">
                  <h6>Name</h6>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Insert name"
                  />
                </div>
                <div class="form-group">
                  <h6>Email</h6>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Insert Email"
                  />
                </div>
              </div>

              <div class="text RadioText mb-5" style={{ display: "block" }}>
                <div class="CommonModalArea">
                  <div class="form-group">
                    <div class="CommonModalBox">
                      <h5
                        class="CursorPointer"
                        onClick={()=> SetSelectVendorModal(true)}
                      >
                        <figure>
                          <img src="images/register_vendor.png" />
                        </figure>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
 
              <div class="Button d-flex justify-content-between">
                <button
                  class="Create"
                  onClick={handleSendClientInfoModalClose}
                  >
                  Send Information View
                </button>
                <button
                  class="Outline"
                 onClick={handleSendClientInfoModalClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <SelectVendor
        SelectVendorModal={SelectVendorModal}
        SetSelectVendorModal={SetSelectVendorModal}
        SetSendClientInfoModal={SetSendClientInfoModal}
      />
    </>
  );
};

export default SendClientInfo;
