import React, { useEffect, useRef, useState } from "react";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import htmlDocx from "html-docx-js/dist/html-docx";
import "../App.css";
import { convertToPNG, dataURLtoBlob } from "../utils/blog";
import { getOptionByCategoryId, getDesignCategoryById,addSignatureInAggrement, clientDetails, companyDetails,
} from "../redux/action/designSelectionsAction";

import { Button } from "react-bootstrap";
import SignaturePad from "react-signature-canvas";

const initialState = {

  ManageSignatueModal: false,
  signature: "",
  errors: {},
  clientData:[],
  companyData:[],
  aggremnetId:"",
};

const DesignSelectionAggrement = () => {
  const location = useLocation();
  const navigate=useNavigate();
  const urlParams = new URLSearchParams(
    location?.search?.split("?")[1].split("#")[0]
  );
  const CompanyId = urlParams?.get("companyId");
  const categoryId = urlParams?.get("designCategoryId");
  const clientId = urlParams?.get("clientId");
  const token = urlParams?.get("token");
  const [iState, updateState] = useState(initialState);
  const {
  
    ManageSignatueModal,
    signature,
    errors,
    clientData,
    companyData,
    aggremnetId,
  } = iState;
 
  const contentRef = useRef(null);
  const signCanvas = useRef({});
  const dispatch = useDispatch();
  const [agreementSignatures,setAgreementSignatures]=useState([])
  const [interiorAgreementSummary, setInteriorAgreementSummary] = useState({
    totalItem: 0,
    price: 0,
    discount: 0,
    allowance: 0,
    subtotalPrice: 0,
    changeOrder: 0,
    tax: 0,
    grandTotal: 0,
  });
  const [ExteriorAgreementSummary, setExteriorAgreementSummary] = useState({
    totalItem: 0,
    price: 0,
    discount: 0,
    allowance: 0,
    subtotalPrice: 0,
    changeOrder: 0,
    tax: 0,
    grandTotal: 0,
  });
 
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [interiorCreateCategoryList, setInteriorCreateCategoryList] = useState(
    []
  );
  const handleAccordionToggle = (index, id) => {
    // dispatch(getOptionByCategoryId(jobData?.state?.ProjectId, id));
    setOpenAccordionIndex(openAccordionIndex === index ? null : index);
  };
  const handleShowImg = (datas) => {
    const img = datas?.designOptionAttachments?.filter(
      (img) => img?.main == true
    );

    return img?.[0]?.["fileUrl"];
  };

  useEffect(() => {
    dispatch(getDesignCategoryById(CompanyId, 1, categoryId, token))
      .then((data) => {
        if(data.status===200){
          updateState({...iState,aggremnetId:data?.data?.data?.id})
          const agreementSignatureFilterData=data?.data?.data?.agreementSignatures?.filter((item)=>item.designation==="ibuildManager")
          const dummyAgreementSignature=agreementSignatureFilterData?.at(-1)
          setAgreementSignatures(dummyAgreementSignature)
        let totalItem = 0;
        let totalPrice = 0;
        let totalDiscount = 0;
        let totalAllowance =
          data.data.data.DesignCategoriesJunctions[0].allowance;

        const selections =
          data?.data?.data?.DesignCategoriesJunctions?.[0]
            ?.DesingSelectionOptions || [];
        

        totalItem += selections.length;

        selections?.forEach((selection) => {
          totalPrice += selection?.designOptionPriceBookSelections?.reduce(
            (sum, priceBookSelection) =>
              sum + (priceBookSelection?.clientTotal || 0),
            0
          );
          totalDiscount += selection?.designOptionPriceBookSelections?.reduce(
            (sum, priceBookSelection) =>
              sum + (priceBookSelection?.discount || 0),
            0
          );
        });
        totalPrice = isNaN(totalPrice) ? 0 : totalPrice;
        totalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
        totalAllowance = isNaN(totalAllowance) ? 0 : totalAllowance;
        setTotalPrice(totalPrice);
        setDiscount(totalDiscount);
        const subtotalPrice = parseFloat(
          (totalPrice - totalDiscount).toFixed(2)
        );
        const changeOrder = parseFloat(
          (totalAllowance - subtotalPrice).toFixed(2)
        );
        const tax = parseFloat((subtotalPrice * 0.05).toFixed(2));
        const grandTotal = parseFloat((subtotalPrice + tax).toFixed(2));
        setInteriorCreateCategoryList(data?.data?.data);
        setInteriorAgreementSummary({
          totalItem,
          price: isNaN(totalPrice) ? 0 : parseFloat(totalPrice.toFixed(2)),
          discount: isNaN(totalDiscount)
            ? 0
            : parseFloat(totalDiscount.toFixed(2)),
          allowance: isNaN(totalAllowance)
            ? 0
            : parseFloat(totalAllowance.toFixed(2)),
          subtotalPrice: isNaN(subtotalPrice) ? 0 : subtotalPrice,
          changeOrder: isNaN(changeOrder) ? 0 : changeOrder,
          tax: isNaN(tax) ? 0 : tax,
          grandTotal: isNaN(grandTotal) ? 0 : grandTotal,
        });
      }})
      .catch((error) => {

      });
  }, []);

 

  const handleManageSignatueShow = () => {
    updateState({
      ...iState,
      ManageSignatueModal: true,
      signature: "",
      initial: "",
      errors: {},
    });
  };
  const handleManageSignatueClose = () => {
    updateState({
      ...iState,
      ManageSignatueModal: false,
      signature: "",
      initial: "",
      errors: {},
    });
  };
 
  const addSignatureFun = () => {
    if(!signature){
      toast.error("Please upload signture", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else {
   
    const signatureBlob = dataURLtoBlob(signature);
    const convertToValidFormat = (blob, fileName) => {
      const fileExtension = fileName.split(".").pop().toLowerCase();
      if (
        ![
          "jpg",
          "jpeg",
          "png",
          "gif",
          "pdf",
          "bmp",
          "webp",
          "svg+xml",
        ].includes(fileExtension)
      ) {
        const convertedBlob = convertToPNG(blob);
        return convertedBlob;
      }
      return blob;
    };
    const validSignatureBlob = convertToValidFormat(
      signatureBlob,
      "signature.png"
    );
    const formData = new FormData();
    formData.append("files", validSignatureBlob, "signature.png");
    formData.append("agreementId",aggremnetId);
    formData.append("name","Client");
    dispatch(addSignatureInAggrement(CompanyId,1,token,formData))
      .then((res) => {
        if (res.status === 200) {
          navigate("/client-view-login")
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            ManageSignatueModal: false,
            signature: null,
            initial: null,
          });
        } else {
          toast.error("Failed to upload signature", {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, ManageSignatueModal: true });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, ManageSignatueModal: true });
      });

        
    }
  };

  const clearSignature = () => {
    signCanvas.current.clear();
  };


  const handleManageSignatueShows = () => {
    updateState({
      ...iState,
      ManageSignatueModal: false,
      signature,
    });
  };

  useEffect(()=>{
    
    dispatch(clientDetails(CompanyId,clientId,token)).then((res)=>{
      if(res.status===200){
        updateState({...iState,clientData:res?.data})
      }
    })
    dispatch(companyDetails(CompanyId,token)).then((res)=>{
      if(res.status===200){
        updateState({...iState,companyData:res?.data?.company})
      }
    })
  },[])



  const createAboutMarkup = () => {
    return { __html: companyData?.letterHeader };
  };

  return (
    <div>
      <div className="FormArea" ref={contentRef} style={{
        margin:"auto",
        maxWidth:"1100px"
      }}>
        <div className="ModalTitleBox">
          <h4>Design Selection Agreement</h4>
        </div>
        <div className="FolderForm">
            <div className="ModalBigheading">
              <h3>
                <span className="fw-500">Status: </span>
                {/* {jobData?.state?.status} */}
              </h3>
            </div>
            <div className="AgreementViewArea">
              <div className="AgreementViewTop">
                
                <div className="AgreementViewTopRight">
                <p
                    dangerouslySetInnerHTML={createAboutMarkup()}
                    className="editor"
                  ></p>
                </div>
              </div>
              <div className="StatementAdjustmentArea px-4">
                <h6>Design Selections</h6>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Client Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={clientData?.user?.legalName}
                      />
                    </div>
                    <div className="form-group">
                      <label>Address</label>
                      <input
                        type="text"
                        className="form-control"
                        value={clientData?.user?.streetAddress}
                      />
                    </div>
                    <div className="form-group">
                      <label>City</label>
                      <input
                        type="text"
                        className="form-control"
                        value={clientData?.user?.streetAddress?.city}
                      />
                    </div>
                    <div className="form-group">
                      <label>State/Provice</label>
                      <input
                        type="text"
                        className="form-control"
                        value={clientData?.user?.province}
                      />
                    </div>
                    <div className="form-group">
                      <label>Possession Date</label>
                      <input
                        type="text"
                        className="form-control"
                        value={clientData?.user?.createdAt?.slice(
                          0,
                          10
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Date Prepared</label>
                      <input
                        type="text"
                        className="form-control"
                        value={clientData?.user?.createdAt?.slice(
                          0,
                          10
                        )}
                      />
                    </div>
                    <div className="form-group">
                      <label>Job Number</label>
                      <input
                        type="text"
                        className="form-control"
                        value={clientData?.user?.JobId}
                      />
                    </div>
                    <div className="form-group">
                      <label>Sales Consultant</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="form-group">
                      <label>Model</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="form-group">
                      <label>Contract Date</label>
                      <input
                        type="text"
                        className="form-control"
                        value={clientData?.user?.createdAt?.slice(
                          0,
                          10
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <h6 className="Title">JOB DETAILS</h6>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Address</label>
                      <input
                        type="text"
                        className="form-control"
                        value={companyData?.streetAddressLine1}
                      />
                    </div>
                    <div className="form-group">
                      <label>City</label>
                      <input
                        type="text"
                        className="form-control"
                        // value={jobData?.state?.city}
                      />
                    </div>
                    <div className="form-group">
                      <label>State/Provice</label>
                      <input
                        type="text"
                        className="form-control"
                        // value={jobData?.state?.province}
                      />
                    </div>
                    <div className="form-group">
                      <label>Subdivision</label>
                      <input
                        type="text"
                        className="form-control"
                        // value={jobData?.state?.subdividision}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Lot</label>
                      <input
                        type="text"
                        className="form-control"
                        // value={jobData?.state?.lot}
                      />
                    </div>
                    <div className="form-group">
                      <label>Block</label>
                      <input
                        type="text"
                        className="form-control"
                        // value={jobData?.state?.block}
                      />
                    </div>
                    <div className="form-group">
                      <label>Plan</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="form-group">
                      <label>Sale Type</label>
                      <input
                        type="text"
                        className="form-control"
                        // value={jobData?.state?.jobType}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <h6 className="Title">Sale Revenues</h6>
                  </div>
                  <div className="NewAccording mb-4 col-12">
                    {interiorCreateCategoryList?.DesignCategoriesJunctions
                      ?.length > 0 &&
                      interiorCreateCategoryList?.DesignCategoriesJunctions?.map(
                        (data, ind) => {
                          return (
                            <div className="card">
                              <div
                                className={
                                  data.select === true
                                    ? "card-header ChangeColorHeader  HeaderGreen mt-2 mb-2"
                                    : "card-header mt-2 mb-2"
                                }
                              >
                                <h4
                                  data-toggle="collapse"
                                  aria-expanded={
                                    openAccordionIndex === ind
                                      ? "true"
                                      : "false"
                                  }
                                  className=""
                                  onClick={() =>
                                    handleAccordionToggle(ind, data.id)
                                  }
                                >
                                  <span>
                                    <img src="images/down_arrow_white.png" />
                                  </span>

                                  {data?.Category?.name}
                                </h4>
                              </div>
                              <div
                                id="Estimation"
                                className={`collapse ${
                                  openAccordionIndex === ind ? "show" : ""
                                }`}
                                style={{}}
                              >
                                <div className="InteriorSelectionsArea">
                                  <div className="InteriorSelectionsTable mt-4">
                                    <table>
                                      <tbody>
                                        {data?.DesingSelectionOptions?.length >
                                          0 &&
                                          data?.DesingSelectionOptions?.map(
                                            (datas, ind) => {
                                              return (
                                                <tr
                                                  className={
                                                    datas.selected
                                                      ? "ChangeColor"
                                                      : "RowGreen"
                                                  }
                                                  key={ind}
                                                >
                                                  <td>
                                                    <div className="Action">
                                                      <img
                                                        src={handleShowImg(
                                                          datas
                                                        )}
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Name</h6>
                                                      <p>{datas.name}</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Description</h6>
                                                      <p>{datas.description}</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Measure</h6>
                                                      <p>
                                                        {datas
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.measure ?? ""}
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Per Unit</h6>
                                                      <p>
                                                        $
                                                        {datas
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.perUnit ?? ""}
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Quantity</h6>
                                                      <p>
                                                        {datas
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.quantity ?? ""}
                                                      </p>
                                                    </div>
                                                  </td>

                                                  <td>
                                                    <div className="Action">
                                                      <h6>Discount</h6>
                                                      <p>
                                                        {
                                                          datas
                                                            ?.designOptionPriceBookSelections?.[0]
                                                            ?.discount
                                                        }
                                                        %
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Price</h6>
                                                      <p>
                                                        $
                                                        {datas
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.clientTotal ?? ""}
                                                      </p>
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="DoorsInterior DarkRed  my-4">
                                    <h5>Category Summary</h5>
                                  </div>
                                  <div className="col-12">
                                    <div className="StatementAdjustmentBottom">
                                      <label>Allowance</label>
                                      <p className="LightPink">
                                        ${data?.allowance}
                                      </p>
                                    </div>
                                    <div className="StatementAdjustmentBottom">
                                      <label>
                                        Total Regular Price (Pre-Tax)
                                      </label>
                                      <p className="LightPink">${totalPrice}</p>
                                    </div>
                                    <div className="StatementAdjustmentBottom">
                                      <label>Total Discount</label>
                                      <p className="LightPink">${discount}</p>
                                    </div>
                                    <div className="StatementAdjustmentBottom">
                                      <label>Sub Total Price (Pre - Tax)</label>
                                      <p className="LightPink">
                                        ${totalPrice - discount}
                                      </p>
                                    </div>
                                    <div className="StatementAdjustmentBottom">
                                      <label>Remaining Allowance</label>
                                      <p className="LightPink">
                                        $
                                        {data?.allowance -
                                          totalPrice -
                                          discount}
                                      </p>
                                    </div>
                                    <div className="StatementAdjustmentBottom">
                                      <label>Change Order</label>
                                      <p className="LightPink">
                                        $
                                        {(data?.allowance -
                                          totalPrice -
                                          discount) *
                                          -1}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                  <div className="col-12">
                    <h6 className="Title mt-5">Summary</h6>
                    <div className="StatementAdjustmentBottom">
                      <label>Total Items Selected</label>
                      <p className="LightBlue1">
                        {interiorAgreementSummary.totalItem}
                      </p>
                    </div>
                    <div className="StatementAdjustmentBottom">
                      <label>Sub Total Regular Price (Pre-Tax)</label>
                      <p className="MediumBlue">
                        ${interiorAgreementSummary.price}
                      </p>
                    </div>
                    <div className="StatementAdjustmentBottom">
                      <label>Total Discount</label>
                      <p className="LightBlue1">
                        ${interiorAgreementSummary.discount}
                      </p>
                    </div>
                    <div className="StatementAdjustmentBottom">
                      <label>Sub Total Price (Pre-Tax)</label>
                      <p className="MediumBlue">
                        ${interiorAgreementSummary.subtotalPrice}
                      </p>
                    </div>
                    <div className="StatementAdjustmentBottom">
                      <label>Total Change Order</label>
                      <p className="DarkBlue">
                        ${interiorAgreementSummary.changeOrder}
                      </p>
                    </div>
                    <div className="StatementAdjustmentBottom">
                      <label>Tax 5%</label>
                      <p className="LightBlue1">
                        ${interiorAgreementSummary.tax}
                      </p>
                    </div>
                    <div className="StatementAdjustmentBottom">
                      <label>Grand Total</label>
                      <p className="DarkBlue">
                        ${interiorAgreementSummary.grandTotal}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ChangeOrderCreatArea mt-4">
                <div className="row">
                  <div className="col-lg-12"></div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="InputBox">
                      <div className="form-group">
                        <input type="text" />
                        <label>Date</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 offset-2">
                    <div className="InputBox">
                      <div className="form-group">
                      
                      <img  src={agreementSignatures?.imageUrl} style={{
                        border: "1px solid gray", padding: "5px", marginBottom: "10px"
                      }} />
                        <label>{interiorCreateCategoryList?.agreementSignatures?.[0].name??"N/A"}</label>
                        <label>Design Manager</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="InputBox">
                      <div className="form-group">
                        <input type="text" />
                        <label>Date</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 offset-2">
                    <div className="InputBox">
                      <div className="form-group mt-4">
                        <Button className="mb-4" onClick={handleManageSignatueShow}>
                          signature
                        </Button>
                        {
                            signature&&
                            <img src={signature} alt="Signature Image" 
                            style={{border: "1px solid gray",
                              padding: "5px",
                              marginBottom: "10px",
                            width:"220px",
                            height:"202px"
                            }}/>
                        }
                        
                        <label>{clientData?.user?.legalName??""}</label>
                        <label>Client</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Button d-flex mx-5 justify-content-center mb-5" style={{gap:"20px",marginBottom:"20px"}}>
              <button
                className="Create mb-5"
                onClick={addSignatureFun}
              >
               Save
              </button>
              <button className="Create mb-5" onClick={() => window.print()}>
                Print
              </button>
              <button className="Create mb-5" onClick={() => window.print()}>
                Download
              </button>
             
            </div>

        </div>
      </div>

      <Modal
        show={ManageSignatueModal}
        onHide={handleManageSignatueClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a className="CloseModal" onClick={handleManageSignatueClose}>
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Manage Signatures</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <div className="CommonModalArea">
                  <h5>Add new signature</h5>
                </div>
              </div>
              <div className="form-group">
                <div className="CommonModalArea border p-2">
                  <h5>Signatures</h5>
                  <div className="CommonModalBox">
                    <SignaturePad
                      ref={signCanvas}
                      penColor="green"
                      canvasProps={{
                        width: 400,
                        height: 300,
                        className: "sigCanvas",
                      }}
                      onEnd={() =>
                        updateState({
                          ...iState,
                          signature: signCanvas?.current
                            ?.getTrimmedCanvas()
                            .toDataURL("image/png"),
                        })
                      }
                    />
                    <a onClick={clearSignature}>
                      <img src="images/circle_minus.png" />
                    </a>
                    {/* <a onClick={saveSignature} href="javascript:void(0);">
                        Save
                      </a> */}
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors?.signatureError}
                  </span>
                </div>
              </div>

              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={handleManageSignatueShows} >
                  Save
                </button>
                <button className="Outline" onClick={handleManageSignatueClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DesignSelectionAggrement;
