import { proformaActionTypes } from "../actionTypes";
const initialState = {
  loader: false,
  unitList:[],
 proformaTypesListData:[],
 foundationListData:[],
 blockFoundationStep1List:[],
 blockFoundationStep2List:[],
 blockFoundationStep3List:[],
 unitStep1List:[],
 unitStep2List:[],
 unitStep3List:[],
 unitStep4List:[],
 commonAreaStep1List:[],
 commonAreaStep2List:[],
 commonBuildingStep1List:[],
 commonBuildingStep2List:[],
 commonBuildingStep3List:[],

 commonBuildingFoundationStep1List: [],
 commonBuildingFoundationStep2List: [],
 commonBuildingFoundationStep3List: [],
 commonGarageStep1List:[],
 commonGarageStep2List:[],
 commonGarageStep3List:[],
 multiLevelStep1List:[],
 multiLevelStep2List:[],
 multiLevelStep3List:[],

 landDevelopmentStep1List:[],
 landDevelopmentStep2List:[],
 landDevelopmentStep3List:[],
proformaConfirmSummaryList:[],
foundationTypeData:[],

proformaBlockStep2Data:[],
proformaBlockStep3Data:[],
};

export default function ProformaReducer(state = initialState, { type, payload }) {
  switch (type) {


    case proformaActionTypes.GET_UNITS_INITIATE: {
      return { ...state,unitList: []};
    }
    case proformaActionTypes.GET_UNITS_SUCCESS: {
      return {  ...state, unitList: payload};
    }
    case proformaActionTypes.GET_UNITS_FAILURE: {
      return {...state,unitList: payload};
    }

    case proformaActionTypes.PROFORMA_TYPES_INITIATE: {
      return {
        ...state,proformaTypesListData: [],
      };
    }
    case proformaActionTypes.PROFORMA_TYPES_SUCCESS: {
      return {
        ...state, proformaTypesListData: payload
      };
    }
    case proformaActionTypes.PROFORMA_TYPES_FAILURE: {
      return {
        ...state,proformaTypesListData: payload,
      };
    }

    case proformaActionTypes.FOUNDATION_LIST_INITIATE: {
      return {
        ...state,
        foundationListData: [],
        loader: true,
      };
    }
    case proformaActionTypes.FOUNDATION_LIST_SUCCESS: {
      return {
        ...state,
        foundationListData: payload.data,
        loader: false,
      };
    }
    case proformaActionTypes.FOUNDATION_LIST_FAILURE: {
      return {
        ...state,
        foundationListData: payload,
        loader: false,
      };
    }  
    case proformaActionTypes.TODO_DETAILS_INITIATE: {
      return {
        ...state,
        todoDetailsData: [],
        loader: true,
      };
    }
    case proformaActionTypes.TODO_DETAILS_SUCCESS: {
      return {
        ...state,
        todoDetailsData: payload,
        loader: false,
      };
    }
    case proformaActionTypes.TODO_DETAILS_FAILURE: {
      return {
        ...state,
        todoDetailsData: payload.data,
        loader: false,
      };
    }

    case proformaActionTypes.TASK_MEMBER_LIST_INITIATE: {
      return {
        ...state,
        memberList: [],
        loader: true,
      };
    }
    case proformaActionTypes.TASK_MEMBER_LIST_SUCCESS: {
      return {
        ...state,
        memberList: payload,
        loader: false,
      };
    }
    case proformaActionTypes.TASK_MEMBER_LIST_FAILURE: {
      return {
        ...state,
        memberList: payload,
        loader: false,
      };
    }

    case proformaActionTypes.TASK_HISTORY_LIST_INITIATE: {
      return {
        ...state,historyTaskList: [],
      };
    }
    case proformaActionTypes.TASK_HISTORY_LIST_SUCCESS: {
      return {
        ...state, historyTaskList: payload
      };
    }
    case proformaActionTypes.TASK_HISTORY_LIST_FAILURE: {
      return {
        ...state,historyTaskList: payload,
      };
    }


    case proformaActionTypes.GET_BLOCK_FOUNDATION_STEP1_INITIATE: {
        return { ...state,blockFoundationStep1List: []};
      }
      case proformaActionTypes.GET_BLOCK_FOUNDATION_STEP1_SUCCESS: {
        return {  ...state, blockFoundationStep1List: payload};
      }
      case proformaActionTypes.GET_BLOCK_FOUNDATION_STEP1_FAILURE: {
        return {...state,blockFoundationStep1List: payload};
      }

      case proformaActionTypes.GET_BLOCK_FOUNDATION_STEP2_INITIATE: {
        return { ...state,blockFoundationStep2List: []};
      }
      case proformaActionTypes.GET_BLOCK_FOUNDATION_STEP2_SUCCESS: {
        return {  ...state, blockFoundationStep2List: payload};
      }
      case proformaActionTypes.GET_BLOCK_FOUNDATION_STEP2_FAILURE: {
        return {...state,blockFoundationStep2List: payload};
      }

      case proformaActionTypes.GET_BLOCK_FOUNDATION_STEP3_INITIATE: {
        return { ...state,blockFoundationStep3List: []};
      }
      case proformaActionTypes.GET_BLOCK_FOUNDATION_STEP3_SUCCESS: {
        return {  ...state, blockFoundationStep3List: payload};
      }
      case proformaActionTypes.GET_BLOCK_FOUNDATION_STEP3_FAILURE: {
        return {...state,blockFoundationStep3List: []};
      }

      case proformaActionTypes.GET_UNIT_STEP1_INITIATE: {
        return { ...state,unitStep1List: []};
      }
      case proformaActionTypes.GET_UNIT_STEP1_SUCCESS: {
        return {  ...state, unitStep1List: payload};
      }
      case proformaActionTypes.GET_UNIT_STEP1_FAILURE: {
        return {...state,unitStep1List: []};
      }

      case proformaActionTypes.GET_UNIT_STEP2_INITIATE: {
        return { ...state,unitStep2List: []};
      }
      case proformaActionTypes.GET_UNIT_STEP2_SUCCESS: {
        return {  ...state, unitStep2List: payload};
      }
      case proformaActionTypes.GET_UNIT_STEP2_FAILURE: {
        return {...state,unitStep2List: []};
      }

      case proformaActionTypes.GET_UNIT_STEP3_INITIATE: {
        return { ...state,unitStep3List: []};
      }
      case proformaActionTypes.GET_UNIT_STEP3_SUCCESS: {
        return {  ...state, unitStep3List: payload};
      }
      case proformaActionTypes.GET_UNIT_STEP3_FAILURE: {
        return {...state,unitStep3List: []};
      }

      case proformaActionTypes.GET_UNIT_STEP4_INITIATE: {
        return { ...state,unitStep4List: []};
      }
      case proformaActionTypes.GET_UNIT_STEP4_SUCCESS: {
        return {  ...state, unitStep4List: payload};
      }
      case proformaActionTypes.GET_UNIT_STEP4_FAILURE: {
        return {...state,unitStep4List: []};
      }

      case proformaActionTypes.GET_COMMON_AREA_STEP1_INITIATE: {
        return { ...state,commonAreaStep1List: []};
      }
      case proformaActionTypes.GET_COMMON_AREA_STEP1_SUCCESS: {
        return {  ...state, commonAreaStep1List: payload};
      }
      case proformaActionTypes.GET_COMMON_AREA_STEP1_FAILURE: {
        return {...state,commonAreaStep1List: []};
      }

      case proformaActionTypes.GET_COMMON_AREA_STEP2_INITIATE: {
        return { ...state,commonAreaStep2List: []};
      }
      case proformaActionTypes.GET_COMMON_AREA_STEP2_SUCCESS: {
        return {  ...state, commonAreaStep2List: payload};
      }
      case proformaActionTypes.GET_COMMON_AREA_STEP2_FAILURE: {
        return {...state,commonAreaStep2List: []};
      }

      case proformaActionTypes.GET_COMMON_BUILDING_STEP1_INITIATE: {
        return { ...state,commonBuildingStep1List: []};
      }
      case proformaActionTypes.GET_COMMON_BUILDING_STEP1_SUCCESS: {
        return {  ...state, commonBuildingStep1List: payload};
      }
      case proformaActionTypes.GET_COMMON_BUILDING_STEP1_FAILURE: {
        return {...state,commonBuildingStep1List: []};
      }

      case proformaActionTypes.GET_COMMON_BUILDING_STEP2_INITIATE: {
        return { ...state,commonBuildingStep2List: []};
      }
      case proformaActionTypes.GET_COMMON_BUILDING_STEP2_SUCCESS: {
        return {  ...state, commonBuildingStep2List: payload};
      }
      case proformaActionTypes.GET_COMMON_BUILDING_STEP2_FAILURE: {
        return {...state,commonBuildingStep2List: []};
      }

      case proformaActionTypes.GET_COMMON_BUILDING_STEP3_INITIATE: {
        return { ...state,commonBuildingStep3List: []};
      }
      case proformaActionTypes.GET_COMMON_BUILDING_STEP3_SUCCESS: {
        return {  ...state, commonBuildingStep3List: payload};
      }
      case proformaActionTypes.GET_COMMON_BUILDING_STEP3_FAILURE: {
        return {...state,commonBuildingStep3List: []};
      }


      case proformaActionTypes.GET_COMMON_BUILDING_FOUNDATION_STEP1_INITIATE: {
        return { ...state,commonBuildingFoundationStep1List: []};
      }
      case proformaActionTypes.GET_COMMON_BUILDING_FOUNDATION_STEP1_SUCCESS: {
        return {  ...state, commonBuildingFoundationStep1List: payload};
      }
      case proformaActionTypes.GET_COMMON_BUILDING_FOUNDATION_STEP1_FAILURE: {
        return {...state,commonBuildingFoundationStep1List: []};
      }


      case proformaActionTypes.GET_COMMON_BUILDING_FOUNDATION_STEP2_INITIATE: {
        return { ...state,commonBuildingFoundationStep2List: []};
      }
      case proformaActionTypes.GET_COMMON_BUILDING_FOUNDATION_STEP2_SUCCESS: {
        return {  ...state, commonBuildingFoundationStep2List: payload};
      }
      case proformaActionTypes.GET_COMMON_BUILDING_FOUNDATION_STEP2_FAILURE: {
        return {...state,commonBuildingFoundationStep2List: []};
      }


      case proformaActionTypes.GET_COMMON_BUILDING_FOUNDATION_STEP3_INITIATE: {
        return { ...state,commonBuildingFoundationStep3List: []};
      }
      case proformaActionTypes.GET_COMMON_BUILDING_FOUNDATION_STEP3_SUCCESS: {
        return {  ...state, commonBuildingFoundationStep3List: payload};
      }
      case proformaActionTypes.GET_COMMON_BUILDING_FOUNDATION_STEP3_FAILURE: {
        return {...state,commonBuildingFoundationStep3List: []};
      }


      case proformaActionTypes.GET_COMMON_GARAGE_STEP1_INITIATE: {
        return { ...state,commonGarageStep1List: []};
      }
      case proformaActionTypes.GET_COMMON_GARAGE_STEP1_SUCCESS: {
        return {  ...state, commonGarageStep1List: payload};
      }
      case proformaActionTypes.GET_COMMON_GARAGE_STEP1_FAILURE: {
        return {...state,commonGarageStep1List: []};
      }

      case proformaActionTypes.GET_COMMON_GARAGE_STEP2_INITIATE: {
        return { ...state,commonGarageStep2List: []};
      }
      case proformaActionTypes.GET_COMMON_GARAGE_STEP2_SUCCESS: {
        return {  ...state, commonGarageStep2List: payload};
      }
      case proformaActionTypes.GET_COMMON_GARAGE_STEP2_FAILURE: {
        return {...state,commonGarageStep2List: []};
      }

      case proformaActionTypes.GET_COMMON_GARAGE_STEP3_INITIATE: {
        return { ...state,commonGarageStep3List: []};
      }
      case proformaActionTypes.GET_COMMON_GARAGE_STEP3_SUCCESS: {
        return {  ...state, commonGarageStep3List: payload};
      }
      case proformaActionTypes.GET_COMMON_GARAGE_STEP3_FAILURE: {
        return {...state,commonGarageStep3List: []};
      }


      case proformaActionTypes.GET_MULTI_LEVEL_STEP1_INITIATE: {
        return { ...state,multiLevelStep1List: []};
      }
      case proformaActionTypes.GET_MULTI_LEVEL_STEP1_SUCCESS: {
        return {  ...state, multiLevelStep1List: payload};
      }
      case proformaActionTypes.GET_MULTI_LEVEL_STEP1_FAILURE: {
        return {...state,multiLevelStep1List: []};
      }


      case proformaActionTypes.GET_MULTI_LEVEL_STEP2_INITIATE: {
        return { ...state,multiLevelStep2List: []};
      }
      case proformaActionTypes.GET_MULTI_LEVEL_STEP2_SUCCESS: {
        return {  ...state, multiLevelStep2List: payload};
      }
      case proformaActionTypes.GET_MULTI_LEVEL_STEP2_FAILURE: {
        return {...state,multiLevelStep2List: []};
      }

      case proformaActionTypes.GET_MULTI_LEVEL_STEP3_INITIATE: {
        return { ...state,multiLevelStep3List: []};
      }
      case proformaActionTypes.GET_MULTI_LEVEL_STEP3_SUCCESS: {
        return {  ...state, multiLevelStep3List: payload};
      }
      case proformaActionTypes.GET_MULTI_LEVEL_STEP3_FAILURE: {
        return {...state,multiLevelStep3List: []};
      }










      


      case proformaActionTypes.GET_LAND_DEVELOPMENT_STEP1_INITIATE: {
        return { ...state,landDevelopmentStep1List: []};
      }
      case proformaActionTypes.GET_LAND_DEVELOPMENT_STEP1_SUCCESS: {
        return {  ...state, landDevelopmentStep1List: payload};
      }
      case proformaActionTypes.GET_LAND_DEVELOPMENT_STEP1_FAILURE: {
        return {...state,landDevelopmentStep1List: []};
      }


      case proformaActionTypes.GET_LAND_DEVELOPMENT_STEP2_INITIATE: {
        return { ...state,landDevelopmentStep2List: []};
      }
      case proformaActionTypes.GET_LAND_DEVELOPMENT_STEP2_SUCCESS: {
        return {  ...state, landDevelopmentStep2List: payload};
      }
      case proformaActionTypes.GET_LAND_DEVELOPMENT_STEP2_FAILURE: {
        return {...state,landDevelopmentStep2List: []};
      }

      case proformaActionTypes.GET_LAND_DEVELOPMENT_STEP3_INITIATE: {
        return { ...state,landDevelopmentStep3List: []};
      }
      case proformaActionTypes.GET_LAND_DEVELOPMENT_STEP3_SUCCESS: {
        return {  ...state, landDevelopmentStep3List: payload};
      }
      case proformaActionTypes.GET_LAND_DEVELOPMENT_STEP3_FAILURE: {
        return {...state,landDevelopmentStep3List: []};
      }

      case proformaActionTypes.GET_PROFORMA_CONFIRMATION_SUMARRY_INITIATE: {
        return { ...state,proformaConfirmSummaryList: []};
      }
      case proformaActionTypes.GET_PROFORMA_CONFIRMATION_SUMARRY_SUCCESS: {
        return {  ...state, proformaConfirmSummaryList: payload};
      }
      case proformaActionTypes.GET_PROFORMA_CONFIRMATION_SUMARRY_FAILURE: {
        return {...state,proformaConfirmSummaryList: []};
      }

      case proformaActionTypes.GET_FOUNDATION_TYPE_INITIATE: {
        return { ...state,foundationTypeData: []};
      }
      case proformaActionTypes.GET_FOUNDATION_TYPE_SUCCESS: {
        return {  ...state, foundationTypeData: payload};
      }
      case proformaActionTypes.GET_FOUNDATION_TYPE_FAILURE: {
        return {...state,foundationTypeData: []};
      }

      case proformaActionTypes.GET_PROFORMA_BLOCK_STEP2_INITIATE: {
        return { ...state,proformaBlockStep2Data: []};
      }
      case proformaActionTypes.GET_PROFORMA_BLOCK_STEP2_SUCCESS: {
        return {  ...state, proformaBlockStep2Data: payload};
      }
      case proformaActionTypes.GET_PROFORMA_BLOCK_STEP2_FAILURE: {
        return {...state,proformaBlockStep2Data: []};
      }

      case proformaActionTypes.GET_PROFORMA_BLOCK_STEP3_INITIATE: {
        return { ...state,proformaBlockStep3Data: []};
      }
      case proformaActionTypes.GET_PROFORMA_BLOCK_STEP3_SUCCESS: {
        return {  ...state, proformaBlockStep3Data: payload};
      }
      case proformaActionTypes.GET_PROFORMA_BLOCK_STEP3_FAILURE: {
        return {...state,proformaBlockStep3Data: []};
      }
    default:
      return state;
  }
}