import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { createLandDevelopmentAction, getAllFoundationListAction, getAllHomeListAction, getAllLandDevelopmentDetailsAction, getAllProjectListAction, getAllTaxProfileAction, updateLandDevelopmentAction } from '../redux/action/jobAction';
import { getCitiesList, getStateList, getTimeZoneList } from '../redux/action/authAction';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { checkArray } from '../utils/CheckType';
import { update } from 'lodash';

const modalInitState = {
  DetailsModal:false,
  jobAddressModal:false,
  confirmationModal:false,
  projectSharingModal:false,
  modalId:"",
  modalState:""
  
}
const initLeadState = {
ProjectId:"",
timeZone:"",
taxProfile:"",
subDivisionArray:[],
subdividision:"",
buildingPermit: "",
subdividision: "",
specificationDoc: "",
taxProfile: 1,
defaultCurrency: "",
costScheme: "",
timeZone: "",
tractSqFt: 5000,
accountSoftware: false,
numLandPhases: "",
phases: [
    {
        landTractPhaseDes: "",
        numOFLots: "",
        lots: [
            {
                numOfLot:"" ,
                lotDescription: "",
                lotType: ""
            },
           
        ]
    },
  
],
jobAddress: "",
"city": "",
"zipCode": "",
"country": "",
"province": "",
"shareCosts": false,
"residentMulFamPro": "",
"costCodeShareds": {
    "landCosts": true,
    "archConsFees": false,
    "engineersFees": true,
    "permitFees": true,
    "siteLandDevSerCosts": false,
    "sitePerAndTeaOffiCosts": true,
    "siteLandRetaWallRprs": true,
    "newHomeWarrAndInsuFees": false,
    "condMiniPlanCoprCosts": false,
    "markAndSaleCosts": true,
    "managLegalFinFees": true
},
curStep:1
}

function Landdevelopment({ landDevelopmentJobModal, setLandDevelopmentJobModal, setShow }) {
  const[iModalState, updateModalState] = useState(modalInitState);

  const dispatch = useDispatch()
  const {
    
    DetailsModal,
    jobAddressModal,
    confirmationModal,
    projectSharingModal,
    modalId,
    modalState,
    landDevelopmentStaticId
  }    = iModalState
  const[iLeadState, updateLeadState] = useState(initLeadState);

  const [lotsModal , setLotsModal] = useState(false)
  const [landTractModal, setLandTractModal] = useState(false)
  const{
    subDivisionArray,
phases,
buildingPermit ,
      subdividision,
      specificationDoc,
      taxProfile,
      defaultCurrency,
      costScheme,
      timeZone,
      tractSqFt  ,
      accountSoftware,
      numLandPhases,
      jobAddress ,
      city,
      zipCode ,
      country   ,
      province  ,
      shareCosts  ,
      residentMulFamPro,
      ProjectId,
  } = iLeadState;
  const { countryData, cityData, stateData, timeZoneData } = useSelector(
    (state) => state.authReducer
  );
  const { projectListData, taxProfileListData,landDetailsData } = useSelector((state) => state.jobReducer);
  useEffect(() => {


if(landDevelopmentJobModal){    dispatch(getAllTaxProfileAction())
    dispatch(getTimeZoneList())
}
  },[dispatch])
  useEffect(() => {
    dispatch(getAllLandDevelopmentDetailsAction(ProjectId,landDevelopmentStaticId))
  },[landDevelopmentStaticId, iModalState, dispatch, ProjectId])
    
  useEffect(() => {
    console.log("=========>",projectListData?.find((x) => x?.id == ProjectId)?.subdivisions)
    updateLeadState({...iLeadState,  subDivisionArray:projectListData?.find((x) => x?.id == ProjectId)?.subdivisions})
  }, [ProjectId,projectListData,])
    console.log(subDivisionArray)
  useEffect(() => {
    if(country)
    dispatch(
      getStateList(
        countryData?.countries?.find((x) => x?.name == country)?.isoCode
      )
    );
  }, [country,countryData?.countries,dispatch]);
  useEffect(() => {
    dispatch(
      getCitiesList(
        countryData?.countries?.find((x) => x.name == country)?.isoCode,
        stateData?.states?.find((x) => x.name == province)?.isoCode
      )
    );
  }, [province]);

  useEffect(() => {
    updateLeadState({...iLeadState, 
      buildingPermit: landDetailsData?.landDevelopmentJobs?.[0]?.buildingPermit,
      subdividision:landDetailsData?.landDevelopmentJobs?.[0]?.subdividision,
      specificationDoc: landDetailsData?.landDevelopmentJobs?.[0]?.specificationDoc,
      taxProfile:  landDetailsData?.landDevelopmentJobs?.[0]?.taxProfile,
      defaultCurrency:  landDetailsData?.landDevelopmentJobs?.[0]?.defaultCurrency,
      costScheme:  landDetailsData?.landDevelopmentJobs?.[0]?.costScheme,
      timeZone:   landDetailsData?.landDevelopmentJobs?.[0]?.timeZone,
      tractSqFt:   landDetailsData?.landDevelopmentJobs?.[0]?.tractSqFt,
      accountSoftware:  landDetailsData?.landDevelopmentJobs?.[0]?.accountSoftware,
      numLandPhases:   landDetailsData?.landDevelopmentJobs?.[0]?.numLandPhases,
      phases:   landDetailsData?.landDevelopmentJobs?.[0]?.LandPhases,
      jobAddress:   landDetailsData?.landDevelopmentJobs?.[0]?.jobAddress,
      city:   landDetailsData?.landDevelopmentJobs?.[0]?.city,
      zipCode:  landDetailsData?.landDevelopmentJobs?.[0]?.zipCode,
      country:   landDetailsData?.landDevelopmentJobs?.[0]?.country,
      province:   landDetailsData?.landDevelopmentJobs?.[0]?.province,
      shareCosts:   landDetailsData?.landDevelopmentJobs?.[0]?.shareCosts,
      costScheme: landDetailsData?.landDevelopmentJobs?.[0]?.costScheme,
      residentMulFamPro:   landDetailsData?.landDevelopmentJobs?.[0]?.residentMulFamPro,
      landDetailsData: landDetailsData?.landDevelopmentJobs?.[0]?.id,

    })
    },[landDetailsData, modalState])
    
  const hideLandDevelomentModal = () => {
    setLandDevelopmentJobModal(false)
    // setShow(true)
  }

  

  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    if(name === "numLandPhases"){
      let tempArray  = ((new Array(+value)).fill( {
        numOFLots: "",
        landTractPhaseDes: "",
      }))
      // 
     
      updateLeadState({ ...iLeadState, [name]: result, phases:tempArray })
    }else {
     
      updateLeadState({ ...iLeadState, [name]: result })
    }
   
  };
  
  const showLandTractModal = (ProjectId,id) => {
    dispatch(getAllLandDevelopmentDetailsAction(ProjectId,id))
    updateModalState((prev)=> ({...prev, landDevelopmentStaticId:id , landDevelopmentJobModal: false}))
    setLandTractModal(true)
  }

  const hideLandTractModal = () => {
    // updateModalState({...iModalState, landTractModal:false})

    setLandTractModal(false)
    setLandDevelopmentJobModal(true)
  }

  const showLotsModal = (ProjectId,id) => {
    // dispatch(getAllLandDevelopmentDetailsAction(ProjectId,id))
    // updateModalState({...iModalState, lotsModal:true,landTractModal:false, landDevelopmentStaticId:id })
    setLandTractModal(false)
    setLotsModal(true)
    
  }

  
  const hideLotsModal = () => {
    // updateModalState({...iModalState, lotsModal:false, landTractModal:true})
    setLandTractModal(true)
    setLotsModal(false)
    setLandDevelopmentJobModal(true)
  }



  const showJobAddressModal = (ProjectId,id) => {
    dispatch(getAllLandDevelopmentDetailsAction(ProjectId,id))
    updateModalState({...iModalState, jobAddressModal:true,}) 
    setLotsModal(false) 
}


const hideJobAddressModal = () => {
    updateModalState({...iModalState,  jobAddressModal:false})
    setLotsModal(true) 

}


const showProjectSharingModal = () => {
  updateModalState({...iModalState, projectSharingModal:true, jobAddressModal:false})
}

const hideProjectSharingModal = () => {
  updateModalState({...iModalState, projectSharingModal:false, jobAddressModal:true})
}


const showConfirmationModal = (ProjectId,id) => {
  dispatch(getAllLandDevelopmentDetailsAction(ProjectId,id))
  updateModalState({...iModalState, projectSharingModal:false, confirmationModal:true})  
}
const hideConfirmationModal = () => {
  updateModalState({...iModalState, confirmationModal:false})
  setShow(true)
}


const handleInput = (e) => {
  const{name,value} = e.target;
  updateLeadState({...iLeadState, [name]:value})
}
const addLandDevelopmentFunc = () => {
  let data = {
   ProjectId,subdividision, taxProfile,tractSqFt,buildingPermit,defaultCurrency, specificationDoc:1,timeZone : new Date()
  }
   dispatch(createLandDevelopmentAction(ProjectId,data)).then((res) =>{
    // console.log("resssssss",res)
    showLandTractModal(res?.details?.ProjectId,res?.details?.id)
  
   })

}

const updateLandDevelopmentFunc = (e,curStep) => {
   e.preventDefault()
  let data = {   
    phases,
    buildingPermit ,
          subdividision,
          taxProfile,
          defaultCurrency,
          costScheme,
          tractSqFt  ,
          accountSoftware,
          numLandPhases,
          jobAddress ,
          city,
          zipCode ,
          country   ,
          province  ,
          shareCosts  ,
          residentMulFamPro,
          ProjectId, specificationDoc:1,timeZone : new Date(), curStep,
          "costCodeShareds": {
            "landCosts": true,
            "archConsFees": false,
            "engineersFees": true,
            "permitFees": true,
            "siteLandDevSerCosts": false,
            "sitePerAndTeaOffiCosts": true,
            "siteLandRetaWallRprs": true,
            "newHomeWarrAndInsuFees": false,
            "condMiniPlanCoprCosts": false,
            "markAndSaleCosts": true,
            "managLegalFinFees": true
        }
  }
   dispatch(updateLandDevelopmentAction(ProjectId, landDevelopmentStaticId,data)).then((res) =>{
    if(curStep == 2){
    
      showLotsModal(res?.data?.ProjectId,res?.data?.id)
    }
 else  if (curStep == 3){
      showJobAddressModal(res?.data?.ProjectId,res?.data?.id)
    } else if (curStep ==4){
      showProjectSharingModal(res?.data?.ProjectId,res?.data?.id)
    }else if (curStep ==5){
      showConfirmationModal(res?.data?.ProjectId,res?.data?.id)
    }
   
   })
}






const handleBlockFirstStepChange = (e,i) => {
  const {name,value} = e?.target;
  let tempphases = [...phases];
  tempphases[i] = {...phases[i],[name]:value}; 
if(name=="numOFLots"){

  let lot =   ((new Array(+value)).fill( {
      numOFLots: "",
      landTractPhaseDes: "",
    }))
  tempphases[i] = {...phases[i],Lots:lot,[name]:value}


}

  updateLeadState({...iLeadState,phases: tempphases})


}


const handleLotChange = (e,index,indexMain) => {
  const {name,value} = e.target;
  let tempPhases =  phases;
  let tempObject = tempPhases[indexMain].Lots;
  tempObject.forEach((item,i) => {
    
    if(i == index)
    {
      if(name ==="lotDescription")
      {
        item.lotDescription = value
      }
      if(name ==="numOfLot")
      {
        item.numOfLot = value
      }
      if(name ==="lotType")
      {
        item.lotType = value
      }
    }
  });


  tempPhases[indexMain].lots = tempObject;
  updateLeadState({...iLeadState,phases:tempPhases})
  
  }





  return (
 <>
  <Modal
        show={landDevelopmentJobModal}
        onHide={hideLandDevelomentModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            type='button'
            className="CloseModal"
            onClick={hideLandDevelomentModal}
          >
            ×
          </a>

          <div className="FormArea">
  <div className="ModalTitleBox">
    <h4>Create Land Development</h4>
  </div>
  <div className="StepBox">
    <ul>
      <li className="active">
        <span className="Icon">
          <img src="images/step_1.png" />
        </span>
        <span className="Text">Details</span>
      </li>
      <li>
        <span
          className="Icon FolderPermissionId "
          data-toggle="modal"
          data-target="#CreateLandDevelopmentLandTrackSkip"
          data-dismiss="modal"
        >
          <img src="images/land_tract.png" />
        </span>
        <span className="Text">
          Land Tract/ <br /> Phase
        </span>
      </li>
      <li>
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentLotsSkip"
          data-dismiss="modal"
        >
          <img src="images/lots_step.png" />
        </span>
        <span className="Text">Lots</span>
      </li>
      <li>
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentJobAddressSkip"
          data-dismiss="modal"
        >
          <img src="images/step_2.png" />
        </span>
        <span className="Text">
          Job <br /> Address
        </span>
      </li>
      <li>
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentProjectCostSkip"
          data-dismiss="modal"
        >
          <img src="images/project_cost_step.png" />
        </span>
        <span className="Text">
          Project Cost <br /> Sharing
        </span>
      </li>
      <li>
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentConfirmationSmall"
          data-dismiss="modal"
        >
          <img src="images/step_7.png" />
        </span>
        <span className="Text">Confirmation</span>
      </li>
    </ul>
  </div>
  <div className="FolderForm">
  
      <div className="FolderFormCreateArea">
        <div className="FieldsMandatory">
          <h6>
            Fields with <span className="required">*</span> are mandatory
          </h6>
        </div>
        <div className="row">
          <div className="col-md-5">
            <div className="form-group">
              <h6>
                Project <span className="required">*</span>
              </h6>
              <div className="CommonSelectBox">
              <select name='ProjectId' value={ProjectId} onChange={handleInput}>
                  <option selected="">Select Project</option>
                  {checkArray(projectListData) && projectListData?.map((ele,i) => {
                    return (
                      <>
                       <option value={ele?.id}>{ele?.projectName}</option>
                      </>
                    )
                  })}
                 
                </select>
              </div>
            </div>
            <div className="form-group">
              <h6>
                Subdividision <span className="required">*</span>
              </h6>
              <div className="CommonSelectBox">
              <select name ="subdividision"value={subdividision} onChange={handleInput}>
                  <option value="">Select Subdivision</option>
                  {checkArray(projectListData?.find((x) => x?.id == ProjectId)?.subdivisions) && projectListData?.find((x) => x?.id == ProjectId)?.subdivisions?.map((ele,i) => {
                    return(
                      <>
                       <option value={ele}> {ele}</option>
                      </>
                    )
                  })}
                 
                </select>
              </div>
            </div>
            <div className="form-group">
              <h6>
                Tax Profile <span className="required">*</span>
              </h6>
              <div className="CommonSelectBox">
              <select name='taxProfile' value={taxProfile} onChange={handleInput}>
                  <option value="">Select Tax profile</option>
                  {checkArray(taxProfileListData) && taxProfileListData?.map((ele,i) => {
                    return (
                      <>
                         <option value={ele?.id}>{ele?.name}</option>
                      </>
                    )
                  })}
                 
                 
                </select>
              </div>
            </div>
            <div className="form-group">
              <h6>
                Tract Share Cost Scheme <span className="required">*</span>
              </h6>
              <div className="CommonSelectBox">
                <select name='costScheme' value={costScheme} onChange={handleInput}>
                  <option selected="">Select Cost Scheme</option>
                  <option name="By equal share">By equal share</option>
                  <option name="By square footage">By square footage</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              <h6>Tract SqFt</h6>
              <input
                type="text"
                className="form-control"
                placeholder="Insert Sq/Ft"
                name='tractSqFt'
                value={tractSqFt}
                onChange={handleInput}
              />
            </div>
            <div className="form-group">
              <label className="switch">
                <input type="checkbox" /> Integrate Job With Accounting Software{" "}
                <span className="required">*</span>
                <span className="slider" />
              </label>
            </div>
          </div>
          <div className="col-md-5 ml-auto">
            <div className="form-group">
              <h6>Building Permit #</h6>
              <input
                type="text"
                className="form-control"
                placeholder="Insert Building Permit Number"
                name='buildingPermit'
                value={buildingPermit}
                onChange={handleInput}
              />
            </div>
            <div className="form-group">
              <h6>Specification Document</h6>
              <div className="CommonSelectBox">
                <select name='specificationDoc' value={specificationDoc} onChange={handleInput}>
                  <option selected="">Select Specification Document</option>
                  <option value="Document 1">Document 1</option>
                </select>
                <span>
                  <img src="images/down.png" />
                </span>
              </div>
            </div>
            <div className="form-group">
              <h6>
                Default Currency <span className="required">*</span>
              </h6>
              <div className="CommonSelectBox">
                <select name='defaultCurrency' value={defaultCurrency} onChange={handleInput}>
                  <option select="">Select Currency</option>
                  <option value="USD">USD</option>
                  <option value="CSD">CSD</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              <h6>
                Job Site GMT (UTC) Timezone <span className="required">*</span>
              </h6>
              <div className="CommonSelectBox">
              <select
                          name="timeZone"
                          value={timeZone}
                          onChange={handleInput}
                        >
                          <option>Select Timezone</option>
                          {checkArray(timeZoneData?.data) ? (
                            <>
                              {timeZoneData?.data?.map((ele, i) => {
                                return (
                                  <option value={ele?.zoneName}>
                                    {ele?.zoneName}
                                  </option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Button d-flex justify-content-between align-items-center px-5">
        <button className="Outline ClosePermissionId" data-dismiss="modal">
          Cancel
        </button>
        <button
          className="Create FolderPermissionId"
          onClick={addLandDevelopmentFunc}
        >
          Next
        </button>
      </div>
  
  </div>
</div>


        </Modal.Body>
      </Modal>




      
      <Modal
        show={landTractModal}
        onHide={hideLandTractModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideLandTractModal}
          >
            ×
          </a>

          <div className="FormArea">
  <div className="ModalTitleBox">
    <h4>Create Land Development</h4>
  </div>
  <div className="StepBox">
    <ul>
      <li className="active">
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentDetails"
          data-dismiss="modal"
        >
          <img src="images/step_1.png" />
        </span>
        <span className="Text">Details</span>
      </li>
      <li className="active">
        <span className="Icon">
          <img src="images/land_tract.png" />
        </span>
        <span className="Text">
          Land Tract/ <br /> Phase
        </span>
      </li>
      <li>
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentLotsSkip"
          data-dismiss="modal"
        >
          <img src="images/lots_step.png" />
        </span>
        <span className="Text">Lots</span>
      </li>
      <li>
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentJobAddressSkip"
          data-dismiss="modal"
        >
          <img src="images/step_2.png" />
        </span>
        <span className="Text">
          Job <br /> Address
        </span>
      </li>
      <li>
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentProjectCostSkip"
          data-dismiss="modal"
        >
          <img src="images/project_cost_step.png" />
        </span>
        <span className="Text">
          Project Cost <br /> Sharing
        </span>
      </li>
      <li>
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentConfirmationSmall"
          data-dismiss="modal"
        >
          <img src="images/step_7.png" />
        </span>
        <span className="Text">Confirmation</span>
      </li>
    </ul>
  </div>
  <div className="FolderForm">
      <div className="FolderFormCreateArea">
        <div className="FieldsMandatory">
          <h6>
            Fields with <span className="required">*</span> are mandatory
          </h6>
        </div>
        <div className="ModalBigheading2 d-flex align-items-center mb-3">
          <h3 className="mb-0">
            Enter Number of Land Tracts/Phases in the Project
          </h3>
          <input className="ModalBigheadingBox" name='numLandPhases' value={numLandPhases} onChange={handleInputNumber}/>
        </div>
        <div className="EnterProjectTable">
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Land Tract/Phase Description</th>
                <th>
                  <div className="ml-5"># of Lots Per Land Tract/Phase</div>
                </th>
              </tr>
            </thead>
            <tbody>


            { phases?.map((ele,i) => {                           
                              return (
                                <>
       <tr>
                <td>1</td>
                <td>
                  <div className="form-group mr-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert Description"
                      name='landTractPhaseDes'
                      value={ele?.landTractPhaseDes}
                      onChange={(e) => handleBlockFirstStepChange(e,i)}
                    />
                  </div>
                </td>
                <td>
                  <div className="form-group ml-5">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Insert number of lots"
                      name='numOFLots'
                      value={ele?.numOFLots}
                      min={0}
                      onChange={(e) => handleBlockFirstStepChange(e,i)}
                    />
                  </div>
                </td>
              </tr>
                                </>
                              )
                            })
                          }
            
           
            </tbody>
          </table>
          <div className="PaginationArea px-3">
            <h6>Showing 1 to 10 of 27 entries</h6>
            <h6>
              <img src="images/leftarrow.png" /> 01{" "}
              <img src="images/rightarrow.png" /> Out 10
            </h6>
          </div>
        </div>
      </div>
      <div className="Button d-flex justify-content-between align-items-center px-5">
        <button
          className="Outline"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentDetails"
          data-dismiss="modal"
        >
          Back
        </button>
        <button
          className="Create FolderPermissionId"
         onClick={(e) =>{
          e.preventDefault()
          showLotsModal()
         }}
        >
          Skip to Job Address
        </button>
      </div>
    
  </div>
</div>




        </Modal.Body>
      </Modal>



      <Modal
        show={lotsModal}
        onHide={hideLotsModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideLotsModal}
          >
            ×
          </a>

          <div className="FormArea">
  <div className="ModalTitleBox">
    <h4>Create Land Development</h4>
  </div>
  <div className="StepBox">
    <ul>
      <li className="active">
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentDetails"
          data-dismiss="modal"
        >
          <img src="images/step_1.png" />
        </span>
        <span className="Text">Details</span>
      </li>
      <li className="active">
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentLandTrackSkip"
          data-dismiss="modal"
        >
          <img src="images/land_tract.png" />
        </span>
        <span className="Text">
          Land Tract/ <br /> Phase
        </span>
      </li>
      <li className="active">
        <span className="Icon">
          <img src="images/lots_step.png" />
        </span>
        <span className="Text">Lots</span>
      </li>
      <li>
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentJobAddressSkip"
          data-dismiss="modal"
        >
          <img src="images/step_2.png" />
        </span>
        <span className="Text">
          Job <br /> Address
        </span>
      </li>
      <li>
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentProjectCostSkip"
          data-dismiss="modal"
        >
          <img src="images/project_cost_step.png" />
        </span>
        <span className="Text">
          Project Cost <br /> Sharing
        </span>
      </li>
      <li>
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentConfirmationSmall"
          data-dismiss="modal"
        >
          <img src="images/step_7.png" />
        </span>
        <span className="Text">Confirmation</span>
      </li>
    </ul>
  </div>
  <div className="FolderForm">
      <div className="FolderFormCreateArea">
        <div className="FieldsMandatory">
          <h6>
            Fields with <span className="required">*</span> are mandatory
          </h6>
        </div>
        <div className="ModalMediumheading">
          <h3>
            <span>Lots Setup</span>
          </h3>
        </div>
        <div className="EnterProjectTable">
          <table>
            <thead>
              <tr>
                <th />
                <th>Lot#</th>
                <th>Lot Description</th>
                <th>Lot Type</th>
              </tr>
            </thead>
            <tbody>
              {checkArray(phases) && phases?.map((ele,index) => {
                return(
                  <>
                   <tr>
                <td colSpan={4}>Phase {index+1}</td>
              </tr>
              {checkArray(ele?.Lots) && ele?.Lots?.map((item,i) => {
                return (
                  <>
                   <tr>
                <td>Lot {i+1}</td>
                <td>
                  <div className="form-group mr-5">
                    <input
                      type="number"
                      className="form-control width-50"
                      placeholder={"10"}
                      name='numOfLot'
                      min={0}
                      value={ele?.numOfLot}
                      onChange={(e) => handleLotChange(e,i, index)}
                    />
                  </div>
                </td>
                <td>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Residential Lot 10"
                      name='lotDescription'
                      value={ele?.lotDescription}
                      onChange={(e) => handleLotChange(e,i, index)}
                    />
                  </div>
                </td>
                <td>
                  <div className="CommonSelectBox">
                    <select name='lotType'
                      value={ele?.lotType}
                      onChange={(e) => handleLotChange(e,i, index)}
                      >
                      <option selected="">Select Lot Type</option>
                      <option value="Lot 2">Lot type 1</option>
                    </select>
                  </div>
                </td>
              </tr>
                  </>
                )
              })}
             
                  </>
                )
              })}
             
             
             
              
            </tbody>
          </table>
          <div className="PaginationArea mt-5 px-3">
            <h6>Showing 4 of 4 block entries</h6>
            <h6>
              <img src="images/leftarrow.png" /> 01{" "}
              <img src="images/rightarrow.png" /> Out 10
            </h6>
          </div>
        </div>
      </div>
      <div className="Button d-flex justify-content-between align-items-center px-5">
        <button
          className="Outline"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentLandTrackNext"
          data-dismiss="modal"
        >
          Back
        </button>
        <button
          className="Create FolderPermissionId"
          onClick={(e) =>updateLandDevelopmentFunc(e,3)}
        >
          Skip
        </button>
      </div>
    
  </div>
</div>


        </Modal.Body>
      </Modal>



  


      <Modal
        show={jobAddressModal}
        onHide={hideJobAddressModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideJobAddressModal}
          >
            ×
          </a>
          <div className="FormArea">
  <div className="ModalTitleBox">
    <h4>Create Land Development</h4>
  </div>
  <div className="StepBox">
    <ul>
      <li className="active">
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentDetails"
          data-dismiss="modal"
        >
          <img src="images/step_1.png" />
        </span>
        <span className="Text">Details</span>
      </li>
      <li className="active">
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentLandTrackSkip"
          data-dismiss="modal"
        >
          <img src="images/land_tract.png" />
        </span>
        <span className="Text">
          Land Tract/ <br /> Phase
        </span>
      </li>
      <li className="active">
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentLotsSkip"
          data-dismiss="modal"
        >
          <img src="images/lots_step.png" />
        </span>
        <span className="Text">Lots</span>
      </li>
      <li className="active">
        <span className="Icon">
          <img src="images/step_2.png" />
        </span>
        <span className="Text">
          Job <br /> Address
        </span>
      </li>
      <li>
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentProjectCostSkip"
          data-dismiss="modal"
        >
          <img src="images/project_cost_step.png" />
        </span>
        <span className="Text">
          Project Cost <br /> Sharing
        </span>
      </li>
      <li>
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentConfirmationSmall"
          data-dismiss="modal"
        >
          <img src="images/step_7.png" />
        </span>
        <span className="Text">Confirmation</span>
      </li>
    </ul>
  </div>
  <div className="FolderForm">
 
      <div className="FolderFormCreateArea">
        <div className="FieldsMandatory">
          <h6>
            Fields with <span className="required">*</span> are mandatory
          </h6>
        </div>
        <div className="row">
          <div className="col-md-5">
            <div className="form-group">
              <h6>Job Address</h6>
              <input
                type="text"
                className="form-control"
                placeholder="Insert full Address"
                name="jobAddress"
                value={jobAddress}
                onChange={handleInput}
              />
            </div>
           
            <div className="form-group">
              <h6>Zip/ Postal Code</h6>
              <input
                type="text"
                className="form-control"
                placeholder="Insert zip code Zip/ Postal Cod"
                name='zipCode'
                value={zipCode}
                onChange={handleInput}
              />
            </div>
            <div className="form-group">
              <h6>Country</h6>
              <div className="CommonSelectBox">
              <select
                                name="country"
                                value={country}
                                onChange={handleInput}
                              >
                                <option>Select Country</option>
                                {checkArray(countryData?.countries) ? (
                                  <>
                                    {countryData?.countries?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                <span>
                  <img src="images/down.png" />
                </span>
              </div>
            </div>
            <div className="form-group">
              <h6>State/Province</h6>
              <div className="CommonSelectBox">
              <select
                                name="province"
                                value={province}
                                onChange={handleInput}
                              >
                                <option>Select Province</option>
                                {checkArray(stateData?.states) ? (
                                  <>
                                    {stateData?.states?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                <span>
                  <img src="images/down.png" />
                </span>
              </div>
            </div>
            <div className="form-group">
              <h6>City</h6>
              <div className="CommonSelectBox">
              <select
                                name="city"
                                value={city}
                                onChange={handleInput}
                              >
                                <option>Select City</option>
                                {checkArray(cityData?.cities) ? (
                                  <>
                                    {cityData?.cities?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                <span>
                  <img src="images/down.png" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Button d-flex justify-content-between align-items-center px-5">
        <button
          className="Outline ClosePermissionId"
          onClick={() =>hideJobAddressModal()}
        >
          Cancel
        </button>
        <button
          className="Create FolderPermissionId"
           onClick={(e) => updateLandDevelopmentFunc(e,4)}
        >
          Skip
        </button>
      </div>
  
  </div>
</div>


        </Modal.Body>
      </Modal>




      <Modal
        show={projectSharingModal}
        onHide={hideProjectSharingModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideProjectSharingModal}
          >
            ×
          </a>
          <div className="FormArea">
  <div className="ModalTitleBox">
    <h4>Create Land Development</h4>
  </div>
  <div className="StepBox">
    <ul>
      <li className="active">
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentDetails"
          data-dismiss="modal"
        >
          <img src="images/step_1.png" />
        </span>
        <span className="Text">Details</span>
      </li>
      <li className="active">
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentLandTrackSkip"
          data-dismiss="modal"
        >
          <img src="images/land_tract.png" />
        </span>
        <span className="Text">
          Land Tract/ <br /> Phase
        </span>
      </li>
      <li className="active">
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentLotsSkip"
          data-dismiss="modal"
        >
          <img src="images/lots_step.png" />
        </span>
        <span className="Text">Lots</span>
      </li>
      <li className="active">
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentJobAddressSkip"
          data-dismiss="modal"
        >
          <img src="images/step_2.png" />
        </span>
        <span className="Text">
          Job <br /> Address
        </span>
      </li>
      <li className="active">
        <span className="Icon">
          <img src="images/project_cost_step.png" />
        </span>
        <span className="Text">
          Project Cost <br /> Sharing
        </span>
      </li>
      <li>
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentConfirmationSmall"
          data-dismiss="modal"
        >
          <img src="images/step_7.png" />
        </span>
        <span className="Text">Confirmation</span>
      </li>
    </ul>
  </div>
  <div className="FolderForm">
   
      <div className="FolderFormCreateArea">
        <div className="FieldsMandatory">
          <h6>
            Fields with <span className="required">*</span> are mandatory
          </h6>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="switch">
                {" "}
                Share Costs With Residential or Multi-Family Projects
                <input type="checkbox" />
                <span className="slider" />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="Button d-flex justify-content-between align-items-center px-5">
        <button
          className="Outline ClosePermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentJobAddressNext"
          data-dismiss="modal"
        >
          Cancel
        </button>
        <button
          className="Create FolderPermissionId"
           onClick={(e) => updateLandDevelopmentFunc(e,5)}
        >
          Skip
        </button>
      </div>
   
  </div>
</div>


        </Modal.Body>
      </Modal>




      <Modal
        show={confirmationModal}
        onHide={hideConfirmationModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideConfirmationModal}
          >
            ×
          </a>
          <div className="FormArea">
  <div className="ModalTitleBox">
    <h4>Create Land Development</h4>
  </div>
  <div className="StepBox">
    <ul>
      <li className="active">
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentDetails"
          data-dismiss="modal"
        >
          <img src="images/step_1.png" />
        </span>
        <span className="Text">Details</span>
      </li>
      <li className="active">
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentLandTrackSkip"
          data-dismiss="modal"
        >
          <img src="images/land_tract.png" />
        </span>
        <span className="Text">
          Land Tract/ <br /> Phase
        </span>
      </li>
      <li className="active">
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentLotsSkip"
          data-dismiss="modal"
        >
          <img src="images/lots_step.png" />
        </span>
        <span className="Text">Lots</span>
      </li>
      <li className="active">
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentJobAddressSkip"
          data-dismiss="modal"
        >
          <img src="images/step_2.png" />
        </span>
        <span className="Text">
          Job <br /> Address
        </span>
      </li>
      <li className="active">
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateLandDevelopmentProjectCostSkip"
          data-dismiss="modal"
        >
          <img src="images/project_cost_step.png" />
        </span>
        <span className="Text">
          Project Cost <br /> Sharing
        </span>
      </li>
      <li className="active">
        <span className="Icon">
          <img src="images/step_7.png" />
        </span>
        <span className="Text">Confirmation</span>
      </li>
    </ul>
  </div>
  <div className="FolderForm">
    <form>
      <div className="FolderFormCreateArea">
        <div className="FieldsMandatory">
          <h6>
            Fields with <span className="required">*</span> are mandatory
          </h6>
        </div>
        <div className="row">
          <div className="form-group col-md-4">
            <h6>Project</h6>
            <p>{landDetailsData?.landDevelopmentJobs?.[0]?.projectName}</p>
          </div>
          <div className="form-group col-md-4">
            <h6>Tract Share Cost Scheme</h6>
            <p>{landDetailsData?.landDevelopmentJobs?.[0]?.projectName}</p>
          </div>
          <div className="form-group col-md-4">
            <h6>Subdivision</h6>
            <p>{landDetailsData?.landDevelopmentJobs?.[0]?.subdividision}</p>
          </div>
          <div className="form-group col-md-4">
            <h6>Tax Profile</h6>
            <p>{landDetailsData?.landDevelopmentJobs?.[0]?.taxProfile}</p>
          </div>
          <div className="form-group col-md-4">
            <h6>Default Currency</h6>
            <p>{landDetailsData?.landDevelopmentJobs?.[0]?.defaultCurrency}</p>
          </div>
        </div>
        <div className="form-group">
          <h6>Job Site GMT (UTC) Timezone</h6>
          <p>{landDetailsData?.landDevelopmentJobs?.[0]?.timeZone?.split("T")[0]}</p>
        </div>
        <div className="form-group">
          <h6>Integrate Job With Accounting Software</h6>
          <p>{landDetailsData?.landDevelopmentJobs?.[0]?.projectName}</p>
        </div>
        {/* <div className="form-group">
          <h6>Land Tract/Phase Details were skipped</h6>
          <h6>Lots Details were skipped</h6>
          <h6>Job Address Details were skipped</h6>
          <h6>Project Cost Sharing Details were skipped</h6>
        </div> */}
      </div>
      <div className="Button d-flex justify-content-between align-items-center px-5">
        <button
          className="Outline ClosePermissionId"
         onClick={hideConfirmationModal}
        >
          Cancel
        </button>
        <button
          className="Create FolderPermissionId"
          onClick={hideConfirmationModal}
        >
          Create Jobs
        </button>
      </div>
    </form>
  </div>
</div>


        </Modal.Body>
      </Modal>


 </>
  )
}

export default Landdevelopment