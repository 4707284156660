import React, { useState, useEffect, useRef } from "react";
import ClientViewSidenav from "./client-view-sidenav";
import ClientHeader from "./ClientHeader";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";
import SignaturePad from "react-signature-canvas";
import Modal from "react-bootstrap/Modal";
import { convertToPNG, dataURLtoBlob } from "../utils/blog";
import {
  getSalesDocumentHistoryList,
  getSalesFolderNList,
  getSalesPenidngTemplates,
  salesApprovedClientDoc,
  salesCreateManageSignature,
} from "../redux/action/clientViewAuthAction";
import { getConverToString } from "../redux/action/saleAction";
const initialState = {
  signature: "",
  initial: "",
  errors: {},
  ManageSignatueModal: false,
  DocumentHistoryModal: false,
  folderId: "",
  ViewDocumentationModal: false,
  viewDocumentData:"",
};
const SalesDocument = () => {
  const dispatch = useDispatch();
  const signCanvas = useRef({});
  const intialCanvas = useRef({});
  const [iState, updateState] = useState(initialState);
  const {
    signature,
    initial,
    errors,
    ManageSignatueModal,
    DocumentHistoryModal,
    folderId,
    ViewDocumentationModal,
    viewDocumentData,
  } = iState;

  const { folderHistoryList, folderNFileList, salePendingList } = useSelector(
    (state) => state.clientViewLogin
  );
  console.log("salePendingList", salePendingList);

  const clientData = JSON.parse(window.localStorage.getItem("clientViewLogin"));

  useEffect(() => {
    dispatch(
      getSalesFolderNList(clientData?.user?.CompanyId, clientData?.viewId)
    );
    dispatch(getSalesPenidngTemplates(clientData?.user?.CompanyId));
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const handleManageSignatueShow = () => {
    updateState({
      ...iState,
      ManageSignatueModal: true,
      signature: "",
      initial: "",
      errors: {},
    });
  };

  const handleManageSignatueClose = () => {
    updateState({
      ...iState,
      ManageSignatueModal: false,
      signature: "",
      initial: "",
      errors: {},
    });
  };

  const handleSignatureValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!signature) {
      error.signatureError = "* Signature can't be empty";
      formIsValid = false;
    }

    if (!initial) {
      error.initialError = "* Initial can't be empty";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: error,
    });
    return formIsValid;
  };

  const clearSignature = () => {
    signCanvas.current.clear();
  };
  const clearInitials = () => {
    intialCanvas.current.clear();
  };

  const addSignatureFun = () => {
    let formIsValid = handleSignatureValidation();
    if (formIsValid) {
      const signatureBlob = dataURLtoBlob(signature);
      const initialBlob = dataURLtoBlob(initial);
      const convertToValidFormat = (blob, fileName) => {
        const fileExtension = fileName.split(".").pop().toLowerCase();
        if (
          ![
            "jpg",
            "jpeg",
            "png",
            "gif",
            "pdf",
            "bmp",
            "webp",
            "svg+xml",
          ].includes(fileExtension)
        ) {
          const convertedBlob = convertToPNG(blob);
          return convertedBlob;
        }
        return blob;
      };
      const validSignatureBlob = convertToValidFormat(
        signatureBlob,
        "signature.png"
      );
      const validInitialBlob = convertToValidFormat(initialBlob, "initial.png");
      const formData = new FormData();
      formData.append("signature", validSignatureBlob, "signature.png");
      formData.append("initial", validInitialBlob, "initial.png");
      formData.append("imageOwner", "test");
      dispatch(
        salesCreateManageSignature(clientData?.user?.CompanyId, formData)
      )
        .then((res) => {
          if (res.status === 201) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            updateState({
              ...iState,
              ManageSignatueModal: false,
              signature: null,
              initial: null,
            });
          } else {
            toast.error("Failed to upload signature", {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({ ...iState, ManageSignatueModal: true });
          }
        })
        .catch((error) => {
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, ManageSignatueModal: true });
        });
    }
  };
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const mergedData = folderNFileList?.data?.files?.concat(
    folderNFileList?.data?.folders
  );

  // Filter the merged data based on the search term
  const filteredData = mergedData?.filter((item) => {
    return (
      item.name?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
      item.folderName?.toLowerCase()?.includes(searchQuery.toLowerCase())
    );
  });

  const handleDocumentHistoryModalShow = (foldId) => {
    updateState({ ...iState, DocumentHistoryModal: true, folderId: foldId });
  };

  const handleDocumentHistoryModalClose = () => {
    updateState({ ...iState, DocumentHistoryModal: false });
  };

  useEffect(() => {
    if (DocumentHistoryModal) {
      dispatch(
        getSalesDocumentHistoryList(clientData?.user?.CompanyId, folderId)
      );
    }
  }, [DocumentHistoryModal]);

  const handleApprovedTemplate = (id) => {
    dispatch(salesApprovedClientDoc(clientData?.user?.CompanyId, id)).then(
      (res) => {
        if (res.status === 200) {
          dispatch(getSalesPenidngTemplates(clientData?.user?.CompanyId));
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  const handleViewDocumentationShow = async (htmlBlobName) => {
    updateState({
      ...iState,
      ViewDocumentationModal: true,
  
    });
    try {
      let urlWithoutQueryString,htmlData;
        urlWithoutQueryString = htmlBlobName?.split("?")[0];
        htmlData = await dispatch(getConverToString(urlWithoutQueryString, clientData?.user?.token))
  
      if (htmlData) {
        updateState({
          ...iState,
          viewDocumentData: htmlData?.data?.data,
          ViewDocumentationModal: true,
        });
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleViewDocumentationClose = () => {
    updateState({
      ...iState,
      ViewDocumentationModal: false,
      folderId: "",
      id: "",
      viewDocumentData: "",
    });
  };

 

  return (
    <>
      <ClientHeader />
      <ClientViewSidenav />
      <div className="WrapperAreaClient">
        <div className="WrapperBoxClient">
          <div className="TitleBoxClient">
            <h4 className="TitleClient">Sales Documents</h4>
          </div>
          <div className="mb-5 Width60">
            <div className="Tableheader">
              <h6>Action Required</h6>
            </div>
            <div className="TableList NewTableList TableHeightScroll TableHeight500">
              <table>
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>Modified by</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {salePendingList?.data?.length > 0 ? (
                    salePendingList?.data?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {item.ClientDocTemplate?.ClientDoc?.fileName ??
                              "N/A"}
                          </td>
                          <td>
                            {" "}
                            {item.UpdatedBy?.name ||
                              item.UpdatedBy?.firstName ||
                              item.UpdatedBy?.lastName ||
                              "N/A"}
                          </td>
                          <td>
                            {item.ClientDocTemplate?.ClientDoc?.actionStatus}
                          </td>
                          <td>
                            {moment(
                              item.ClientDocTemplate?.ClientDoc?.createdAt
                            ).format("YYYY-MM-DD")}
                          </td>
                          <td>
                            <div className="TableThreeDots">
                              <ul className="">
                                <li className="dropdown">
                                  <a
                                    className=""
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/dots.png" />
                                  </a>
                                  <ol className="dropdown-menu dropdown-menuwidth">
                                    <li>
                                      <a onClick={()=>handleViewDocumentationShow(item.ClientDocTemplate?.htmlBlobName)}>
                                        <img src="images/view-po.png" />
                                        View Document
                                      </a>
                                    </li>
                                    <li>
                                      <a href="javascript:void(0);">
                                        <img src="images/sign_document.png" />
                                        Sign Document
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() =>
                                          handleApprovedTemplate(item.id)
                                        }
                                      >
                                        <img src="images/approve_document.png" />
                                        Approve Document
                                      </a>
                                    </li>
                                    <li>
                                      <a href="javascript:void(0);">
                                        <img src="images/download.png" />
                                        Download Documet
                                      </a>
                                    </li>
                                  </ol>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5">No data found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="FilesArea">
            <div className="FilesHead">
              {/* <div class="TodosButtons mt-0">
        <a href="javascript:void(0)" class="ml-0"><h6><img src="images/white_manage_signatures.png">Manage Signatures</h6>
          <span>3</span>
        </a>
      </div> */}
              <div className="TodosButtons mt-0 d-flex">
                {/* <a href="javascript:void(0);" className="Create mr-2">
            <img src="images/initial_lot.png" />
            Initial Lot Inspection Form<span>3</span>
          </a>
          <a href="javascript:void(0)" className="mr-2">
            <img src="images/final_lot.png" />
            Final Lot Inspection Form <span>!</span>
          </a> */}
                <a onClick={handleManageSignatueShow} className="mr-2">
                  <h6>
                    <img src="images/white_manage_signatures.png" />
                    Manage Signatures
                  </h6>
                  <span>3</span>
                </a>
              </div>
              <div className="FilesLeft FilesLeftSalesdoocument">
                <aside>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                  <span>
                    <img src="images/search.png" />
                  </span>
                </aside>
              </div>
            </div>
          </div>
          <div className="SalesDocumentArea">
            <div className="row">
              {filteredData?.map((item, i) => {
                const isFile = !!item.name; // Check if it's a file (true) or folder (false)
                return (
                  <div className="col-md-3" key={i}>
                    <div className="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>{isFile ? item.name : item.folderName}</h4>
                        </div>
                        <ul>
                          <li className="dropdown">
                            <a
                              className="dropdown-toggle"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" alt="dots" />
                            </a>
                            <ol className="dropdown-menu DropdownMenuSafety">
                              <li>
                                <a
                                // onClick={()=>handleViewDocumentationShow(item.docUrl)}
                                >
                                  <img
                                    src="images/view_jobs_details.png"
                                    alt="view details"
                                  />
                                  View Document
                                </a>
                              </li>
                              {isFile && (
                                <li>
                                  <a href={item.docUrl} target="_self">
                                    <img
                                      src="images/download.png"
                                      alt="download"
                                    />
                                    Download documents
                                  </a>
                                </li>
                              )}
                            </ol>
                          </li>
                        </ul>
                      </figcaption>
                      <div className="FilesBoxCenterSafety">
                        <img src="images/file.png" alt="file" />{" "}
                        <h6>{isFile ? "File" : "Folder"}</h6>{" "}
                        <img src="images/red_esc.png" alt="esc" />
                      </div>
                      <figcaption>
                        <div>
                          <p className="CursorPointer">
                            Last update{" "}
                            {moment(item.updatedAt).format("YYYY-MM-DD")}
                          </p>
                        </div>
                        <ul>
                          {isFile && (
                            <li>
                              <a
                                onClick={() =>
                                  handleDocumentHistoryModalShow(item.FolderId)
                                }
                              >
                                <img src="images/timer.png" alt="timer" />
                              </a>
                            </li>
                          )}
                        </ul>
                      </figcaption>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={ManageSignatueModal}
        onHide={handleManageSignatueClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a className="CloseModal" onClick={handleManageSignatueClose}>
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Manage Signatures</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <div className="CommonModalArea">
                  <h5>Add new signature</h5>
                  <div className="CommonModalBox">
                    <h5>
                      <figure>
                        <img src="images/add_new_signature.png" />
                      </figure>{" "}
                      Add new signature
                    </h5>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="CommonModalArea">
                  <h5>Signatures</h5>
                  <div className="CommonModalBox">
                    {/* <input
                        // type="file"
                        // name="signature"
                        // onChange={handleInputFile}
                      /> */}
                    <SignaturePad
                      ref={signCanvas}
                      penColor="green"
                      canvasProps={{
                        width: 400,
                        height: 300,
                        className: "sigCanvas",
                      }}
                      onEnd={() =>
                        updateState({
                          ...iState,
                          signature: signCanvas?.current
                            ?.getTrimmedCanvas()
                            .toDataURL("image/png"),
                        })
                      }
                    />
                    <a onClick={clearSignature}>
                      <img src="images/circle_minus.png" />
                    </a>
                    {/* <a onClick={saveSignature} href="javascript:void(0);">
                        Save
                      </a> */}
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors?.signatureError}
                  </span>
                </div>
              </div>
              <div className="form-group">
                <div className="CommonModalArea">
                  <h5>Add new Initials</h5>
                  <div className="CommonModalBox">
                    <h5>
                      <figure>
                        <img src="images/add_new_signature.png" />
                      </figure>{" "}
                      Add new Initials
                    </h5>
                    <div></div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="CommonModalArea">
                  <h5>Initials</h5>
                  <div className="CommonModalBox">
                    <SignaturePad
                      ref={intialCanvas}
                      penColor="green"
                      canvasProps={{
                        width: 400,
                        height: 300,
                        className: "sigCanvas",
                      }}
                      onEnd={() =>
                        updateState({
                          ...iState,
                          initial: signCanvas?.current
                            ?.getTrimmedCanvas()
                            .toDataURL("image/png"),
                        })
                      }
                    />
                    <a onClick={clearInitials}>
                      <img src="images/circle_minus.png" />
                    </a>
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors?.initialError}
                  </span>
                </div>
              </div>
              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={addSignatureFun}>
                  Save
                </button>
                <button className="Outline" onClick={handleManageSignatueClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={DocumentHistoryModal}
        onHide={handleDocumentHistoryModalClose}
        className="ModalBox modal fade LargeModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleDocumentHistoryModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Document History</h4>
            </div>
            <div className="JobTitleArea mb-2">
              <div className="JobBox">
                <h6>
                  <span>Document:</span>&nbsp;Statement of Adjusments.pdf
                </h6>
              </div>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Modified By</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {folderHistoryList?.folderHistories?.length > 0 ? (
                    folderHistoryList?.folderHistories?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.modifiedBy ?? "N/A"}</td>
                          <td>{item.createdBy ?? "N/A"}</td>
                          <td>{moment(item.createdAt).format("YYYY-MM-DD")}</td>
                          <td>{item.activity}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="4">No data found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
          show={ViewDocumentationModal}
          onHide={handleViewDocumentationClose}
          className="ModalBox LargeModal GreyModalContent"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleViewDocumentationClose}
                >
                  ×
                </a>
                <div>
                <div className="ModalTitleBox">
                    <h4>View File</h4>
                  </div>
                  {viewDocumentData && (
                    <Editor
                    apiKey="s5lpq1i7ftirx73vaw448qm0lk3phqsvnx3dpqvp542y1fbd"
                    value={viewDocumentData}
                    init={{
                      height: 500,
                      menubar: false,
                      
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                        'lists'
                      ],
                      toolbar: 'undo redo | formatselect | bold italic backcolor | ' +
                               'alignleft aligncenter alignright alignjustify | ' +
                               'bullist numlist outdent indent | removeformat | help',
                               readOnly: true,
                    }}

                   />
                   
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
    </>
  );
};

export default SalesDocument;
