import { costCodesActionTypes,  } from "../actionTypes";
const initialState = {
  loader: false,
  costProfielistData:[],
subCostProfileData :[],
categoryListData:[],
costCodesListData:[],
masterCategoryListData:[],
masterCostCodeListData:[],
masterSubProfileData:[],
proformaBaseCostListData:{},
proformaProfileListData:[],
unitAdminListData:[],
adminEstimationDetailsData:[],
userLimitDetailsData:[],
proformaSettingsData:[],
masterCostCategoryListData:[],
masterCostCodeListData:[]

};

export default function costReducer(state = initialState, { type, payload }) {
  switch (type) {
    case costCodesActionTypes.COST_PROFILE_JOB_LIST_INITIATE: {
        return {
          ...state,
          costProfielistData: [],
          loader:true
        };
      }
      case costCodesActionTypes.COST_PROFILE_JOB_LIST_SUCCESS: {
        return {
          ...state,
          costProfielistData: payload,
          loader:false
        };
      }
      case costCodesActionTypes.COST_PROFILE_JOB_LIST_FAILURE: {
        return {
          ...state,
          costProfielistData: payload.data,
          loader:false
        };
      }


      case costCodesActionTypes.SUB_COST_PROFILE_JOB_LIST_INITIATE: {
        return {
          ...state,
          subCostProfileData: [],
          loader:true
        };
      }
      case costCodesActionTypes.SUB_COST_PROFILE_JOB_LIST_SUCCESS: {
        return {
          ...state,
          subCostProfileData: payload,
          loader:false
        };
      }
      case costCodesActionTypes.SUB_COST_PROFILE_JOB_LIST_FAILURE: {
        return {
          ...state,
          subCostProfileData: payload.data,
          loader:false
        };
      }





      case costCodesActionTypes.CATEGORY_LIST_INITIATE: {
        return {
          ...state,
          categoryListData: [],
          loader:true
        };
      }
      case costCodesActionTypes.CATEGORY_LIST_SUCCESS: {
        return {
          ...state,
          categoryListData: payload,
          loader:false
        };
      }
      case costCodesActionTypes.CATEGORY_LIST_FAILURE: {
        return {
          ...state,
          categoryListData: payload.data,
          loader:false
        };
      }




      
      case costCodesActionTypes.COST_CODE_LIST_INITIATE: {
        return {
          ...state,
          costCodesListData: [],
          loader:true
        };
      }
      case costCodesActionTypes.COST_CODE_LIST_SUCCESS: {
        return {
          ...state,
          costCodesListData: payload,
          loader:false
        };
      }
      case costCodesActionTypes.COST_CODE_LIST_FAILURE: {
        return {
          ...state,
          costCodesListData: payload.data,
          loader:false
        };
      }


      
      
      case costCodesActionTypes.MASTER_CATEGORY_INITIATE: {
        return {
          ...state,
          masterCategoryListData: [],
          loader:true
        };
      }
      case costCodesActionTypes.MASTER_CATEGORY_SUCCESS: {
        return {
          ...state,
          masterCategoryListData: payload,
          loader:false
        };
      }
      case costCodesActionTypes.MASTER_CATEGORY_FAILURE: {
        return {
          ...state,
          masterCategoryListData: payload.data,
          loader:false
        };
      }


      
      case costCodesActionTypes.MASTER_COSTCODE_INITIATE: {
        return {
          ...state,
          masterCostCodeListData: [],
          loader:true
        };
      }
      case costCodesActionTypes.MASTER_COSTCODE_SUCCESS: {
        return {
          ...state,
          masterCostCodeListData: payload,
          loader:false
        };
      }
      case costCodesActionTypes.MASTER_COSTCODE_FAILURE: {
        return {
          ...state,
          masterCostCodeListData: payload.data,
          loader:false
        };
      }


       
      case costCodesActionTypes.MASTER_SUBPROFILE_INITIATE: {
        return {
          ...state,
          masterSubProfileData: [],
          loader:true
        };
      }
      case costCodesActionTypes.MASTER_SUBPROFILE_SUCCESS: {
        return {
          ...state,
          masterSubProfileData: payload,
          loader:false
        };
      }
      case costCodesActionTypes.MASTER_SUBPROFILE_FAILURE: {
        return {
          ...state,
          masterSubProfileData: payload.data,
          loader:false
        };
      }

      case costCodesActionTypes.GET_PROFORMA_PROFILE_INITIATE: {
        return {
          ...state,
          proformaProfileListData: [],
          loader:true
        };
      }
      case costCodesActionTypes.GET_PROFORMA_PROFILE_SUCCESS: {
        return {
          ...state,
          proformaProfileListData: payload,
          loader:false
        };
      }
      case costCodesActionTypes.GET_PROFORMA_PROFILE_FAILURE: {
        return {
          ...state,
          proformaProfileListData: payload.data,
          loader:false
        };
      }


      case costCodesActionTypes.GET_PROFORMA_BASE_COST_INITIATE: {
        return {
          ...state,
          proformaBaseCostListData: [],
          loader:true
        };
      }
      case costCodesActionTypes.GET_PROFORMA_BASE_COST_SUCCESS: {
        return {
          ...state,
          proformaBaseCostListData: payload,
          loader:false
        };
      }
      case costCodesActionTypes.GET_PROFORMA_BASE_COST_FAILURE: {
        return {
          ...state,
          proformaBaseCostListData: payload.data,
          loader:false
        };
      }


      
      case costCodesActionTypes.UNIT_LIST_ADMIN_INITIATE: {
        return {
          ...state,
          unitAdminListData: [],
          loader:true
        };
      }
      case costCodesActionTypes.UNIT_LIST_ADMIN_SUCCESS: {
        return {
          ...state,
          unitAdminListData: payload,
          loader:false
        };
      }
      case costCodesActionTypes.UNIT_LIST_ADMIN_FAILURE: {
        return {
          ...state,
          unitAdminListData: payload.data,
          loader:false
        };
      }


      case costCodesActionTypes.VIEW_ADMIN_ESTIMATION_INITIATE: {
        return {
          ...state,
          adminEstimationDetailsData: [],
          loader:true
        };
      }
      case costCodesActionTypes.VIEW_ADMIN_ESTIMATION_SUCCESS: {
        return {
          ...state,
          adminEstimationDetailsData: payload,
          loader:false
        };
      }
      case costCodesActionTypes.VIEW_ADMIN_ESTIMATION_FAILURE: {
        return {
          ...state,
          adminEstimationDetailsData: payload.data,
          loader:false
        };
      }

      case costCodesActionTypes.VIEW_USER_LIMIT_DETAILS_INITIATE: {
        return {
          ...state,
         userLimitDetailsData : [],
          loader:true
        };
      }
      case costCodesActionTypes.VIEW_USER_LIMIT_DETAILS_SUCCESS: {
        return {
          ...state,
          userLimitDetailsData: payload,
          loader:false
        };
      }
      case costCodesActionTypes.VIEW_USER_LIMIT_DETAILS_FAILURE: {
        return {
          ...state,
          userLimitDetailsData: payload.data,
          loader:false
        };
      }



      
      case costCodesActionTypes.GET_PROFORMA_SETTING_INITIATE: {
        return {
          ...state,
         proformaSettingsData : [],
          loader:true
        };
      }
      case costCodesActionTypes.GET_PROFORMA_SETTING_SUCCESS: {
        return {
          ...state,
          proformaSettingsData: payload,
          loader:false
        };
      }
      case costCodesActionTypes.GET_PROFORMA_SETTING_FAILURE: {
        return {
          ...state,
          proformaSettingsData: payload.data,
          loader:false
        };
      }







      case costCodesActionTypes.GET_MASTER_COSTCATEGORY_INITIATE: {
        return {
          ...state,
         masterCostCategoryListData : [],
          loader:true
        };
      }
      case costCodesActionTypes.GET_MASTER_COSTCATEGORY_SUCCESS: {
        return {
          ...state,
          masterCostCategoryListData: payload,
          loader:false
        };
      }
      case costCodesActionTypes.GET_MASTER_COSTCATEGORY_FAILURE: {
        return {
          ...state,
          masterCostCategoryListData: payload.data,
          loader:false
        };
      }



      case costCodesActionTypes.GET_MASTER_COSTCODE_INITIATE: {
        return {
          ...state,
         masterCostCodeListData : [],
          loader:true
        };
      }
      case costCodesActionTypes.GET_MASTER_COSTCODE_SUCCESS: {
        return {
          ...state,
          masterCostCodeListData: payload,
          loader:false
        };
      }
      case costCodesActionTypes.GET_MASTER_COSTCODE_FAILURE: {
        return {
          ...state,
          masterCostCodeListData: payload.data,
          loader:false
        };
      }



    default:
      return state;
  }
}
