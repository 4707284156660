import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {toast} from "react-toastify"
import { itenaryRequestDenyAction, manageAccessToItineraryListAccessAction } from "../../../redux/action/IbuildAdminItineraryAction";
import moment from "moment/moment";

const ItineraryManageAccessMyModal = ({
  ItineraryManageAccessMyStatus,
  SetItineraryManageAccessMyStatus,
  SetItineraySettingState,
}) => {
  const dispatch = useDispatch();

  const { managerAccessMyList } = useSelector(
    (state) => state.itineraryReducer
  );

  const handleItinerayManageAccessModalClose = () => {
    SetItineraySettingState(true);
    SetItineraryManageAccessMyStatus(false);
  };
  const handleItinerayManageAccessShow = () => {
    SetItineraryManageAccessMyStatus(true);
  };

  useEffect(() => {
    dispatch(manageAccessToItineraryListAccessAction());
  }, []);



  const handleReject = (e,id) => {
    e.preventDefault();

      dispatch(
        itenaryRequestDenyAction(id)
      )
        .then((res) => {
          if (res.status === 200) {

            dispatch(manageAccessToItineraryListAccessAction());

            toast.success("Deny Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });

     
           
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    
  };



  return (
    <Modal
      show={ItineraryManageAccessMyStatus}
      className="ModalBox MediumModal"
    >
      <div class="modal-content BgCreate">
        <a
          onClick={handleItinerayManageAccessModalClose}
          class="CloseModal"
          data-toggle="modal"
        >
          ×
        </a>

        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Manage Access to My Itinerary</h4>
          </div>
          <div class="TableList TableHeightScroll">
            <table>
              <thead>
                <tr>
                  <th>User</th>
                  <th>Grant Access Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {managerAccessMyList?.requests?.map((item) => (
                  <tr>
                         <td>{item?.User?.firstName}</td>
                         <td>Access Granted</td>
                         <td>{moment(item?.createdAt).format('lll')}</td>
                    <td>
                      <div onClick={(e)=>handleReject(e, item?.id)}  class="Action">
                        <i class="fa fa-times-circle" aria-hidden="true"></i>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {managerAccessMyList?.requests?.length > 0 && (
            <div class="PaginationArea">
              <h6>
                Showing {managerAccessMyList?.pagination?.currentPage} to{" "}
                {managerAccessMyList?.pagination?.itemsPerPages} of 1 entries
              </h6>
              <h6>
                <img src="images/leftarrow.png" /> <span>01</span>{" "}
                <img src="images/rightarrow.png" /> Out 10
              </h6>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ItineraryManageAccessMyModal;
