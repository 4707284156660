import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import "../App.css";
import Modal from "react-bootstrap/Modal";
import Loader from "./Loader";
import SignaturePad from "react-signature-canvas";
import { convertToPNG, dataURLtoBlob } from "../utils/blog";
import exportToCalendar from "../assets/images/export-to-calendar.png";
import rightarrow2 from "../assets/images/rightarrow2.png";
import googleCalendar from "../assets/images/google-calendar.png";
import outlookCalendar from "../assets/images/outlook-calendar.png";
import SetReminder from "../assets/images/set-reminder.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import taskHistory from "../assets/images/task-history.png";
import threeDots from "../assets/images/three-dots.png";
import {
  ReleaseFiles,
  ReleaseFolderFiles,
  addSignatureInAggrement,
  appointmentCreate,
  createDesignDocumentation,
  createDesignFolder,
  createInteriorCategory,
  createOption,
  deleteOptionById,
  deletePenidngAppointment,
  deleteSelection,
  designSelectionAggrementSend,
  getAppointmentCategoryList,
  getApprovedAppointmentList,
  getCostCodeDropDown,
  getDesignCategoryById,
  getDesignDocumentHistory,
  getDesignFolderList,
  getDesignFolderRemove,
  getDesignPendingFileEventList,
  getDesignerVendorList,
  getExteriorCreateCategoryList,
  getGenerateAgreement,
  getInteriorCategoryList,
  getInteriorCreateCategoryList,
  getInteriorCreateCategoryRemove,
  getListDesignDocument,
  getMeasureDropDown,
  getOptionByCategoryId,
  getPendingAppointmentList,
  getPriceBookCategoryList,
  getPrimaryClient,
  getSecondaryClient,
  getSelectItemList,
  getSelectionHistory,
  getShareDesignDocument,
  getVendorList,
  resendDateSelection,
  selectedDesignOption,
  selectedDesignOptionById,
  sendForClientSignature,
  updateCategory,
  updateDesignDocss,
  updateOptions,
  updateReminder,
  uploadAggrementFiles,
  uploadmultipleFiles,
} from "../redux/action/designSelectionsAction";
import { Link, useLocation } from "react-router-dom";
import {
  getAllDocumentKeyList,
  getHtmlFile,
} from "../redux/action/fileManagerAction";
import { calculateAdjustedDeadline, calculateDateDifference } from "../utils/uploadFile";
import {
  getEmailCalendar,
  getGoogleCalendar,
  getOutlookCalendar,
} from "../redux/action/taskAction";
import { getChangeOrderUploadFiles } from "../redux/action/tradeViewAuthAction";
import { createSupportingDocsFiles } from "../redux/action/changeOrderAction";
import htmlDocx from "html-docx-js/dist/html-docx";
import { saveAs } from "file-saver";
import { getGeneralInformationData } from "../redux/action/clientViewConfigAction";
import { Button } from "react-bootstrap";
import { getAllClientAction, getBuilderSigneeList, getConverToString } from "../redux/action/saleAction";
import { getPrimaryClientByJobs } from "../redux/action/warrantyAction";
import { getAllProjectList } from "../redux/action/vendorViewConfigAction";

const initialState = {
  AddCategoryModal: false,
  search: "",
  allowance: "",
  // selectedCategories: [],
  DocumentationDeleteModal: false,
  id: "",
  PendingDateSelectionListModal: false,
  CreateAppointmentModal: false,

  title: "",
  type: "",
  catStatus: false,
  category: "",
  location: "",
  streetAddressLine1: "",
  streetAddressLine2: "",
  city: "",
  postalcode: "",
  country: "",
  state: "",
  sentTo: "",
  designer: "",
  vendor: "",
  appointmentDates: [
    {
      date: "",
      fromTime: "",
      toTime: "",
    },
    {
      date: "",
      fromTime: "",
      toTime: "",
    },
    {
      date: "",
      fromTime: "",
      toTime: "",
    },
  ],
  designerSearch: "",
  PendingDeleteModal: false,
  ApprovedAppointmentDetailsModal: false,
  AddOptionModal: false,
  SelectItemBookPriceModal: false,
  categorySearch: "",
  categoryToggle: false,
  multiple: "",
  designCategoryId: "",
  itemSearch: "",
  attachments: [
    {
      fileUrl: "",
      main: false,
    },
  ],
  ViewImagesModal: false,

  DesignSelectionsModal: false,
  folderName: "",
  CreateFolderModal: "",
  folderSearch: "",
  CreateNewFileModal: false,
  fileFolderName: "",
  clientFile: null,
  folderId: "",
  documentFolderSearch: "",
  FolderHistoryModal: false,
  ShareClientDocumentationModal: false,
  emailBody: "",
  emails: [],
  clientName: "",
  clientEmail: "",
  ViewDocumentationModal: false,
  viewDocumentData: "",
  FolderDeleteModal: false,
  selectedDatesOfCalender: [],
  appointmentDeatilsData: [],
  AppointmentsDetailsModal: false,
  UpdateReminderModal: false,
  week: "",
  day: "",
  appointmentId: "",
  categoryType: "",
  UpdateCategoryModal: false,
  designSelectionCategoryId: "",
  ReleasefileModal: false,
  releaseFileData: [],
  VendorId: "",
  ReleaseFolderfileModal: false,
  folderData: [],
  SendForClientModal: false,
  primarySignee: "",
  additionalSignee: "",
  numberOfSignee: 1,
  secondarySignee: "",
  docsId: "",
  EditDocumentationModal: false,
  documentData: [],
  HeaderPreviewModal: false,
  viewDocumentation: [],
  content: "",
  JobId: "",
  DocumentKeyModal: false,
  documentSearch: "",
  name: "",
  productLink: "",
  description: "",
  vendorId: "",
  designCategoryIds: "",
  DesignSelectionsAggrementInteriorModal: false,
  DesignSelectionsAggrementExteriorModal: false,
  Aggrement: "",
  attachmentsArray: [],
  SelectionHistoryModal: false,
  selectionHistoryProjectId: "",
  selectionHistoryId: "",
  jobType: "",
  selectionType: "",
  ManageSignatueModal: false,
  signature: "",
  isGenerateAggremet: false,
  fileNames:"",
  builderSignStatus: "",
  primarySignStatus: "",
  secondarySignStatus: "",
  additionalSignStatus: "",
  primarySignature:"",
  secondarySignature:"",
  additionalSignature:"",
  builderSignature:"",
  ManageSignature: false,
  signTypes:"",
  primarySignatureBlob:"",
  secondarySignatureBlob:"",
  builderSignatureBlob:"",
  additionalSignatureBlob:"",
  ManageSignatures:false,
  builderSignee:"",
  draft:false,
  loading:false,
  htmlUrl:"",
  errors:{}, NavigateModal: false,
};

const DesignSelections = () => {
  const dispatch = useDispatch();
  const signCanvas = useRef({});
  const [iState, updateState] = useState(initialState);
  const {
    AddCategoryModal,
    search,
    allowance,
    DocumentationDeleteModal,
    id,
    PendingDateSelectionListModal,
    CreateAppointmentModal,
    title,
    type,
    catStatus,
    category,
    location,
    streetAddressLine1,
    streetAddressLine2,
    city,
    postalcode,
    country,
    state,
    sentTo,
    designer,
    vendor,
    appointmentDates,
    designerSearch,
    PendingDeleteModal,
    ApprovedAppointmentDetailsModal,
    AddOptionModal,
    SelectItemBookPriceModal,
    categorySearch,
    categoryToggle,

    designCategoryId,
    itemSearch,
    attachments,
    ViewImagesModal,
    multiple,
    DesignSelectionsModal,
    folderName,
    CreateFolderModal,
    folderSearch,
    CreateNewFileModal,
    fileFolderName,
    clientFile,
    folderId,
    documentFolderSearch,
    FolderHistoryModal,
    ShareClientDocumentationModal,
    emailBody,
    emails,
    clientName,
    clientEmail,
    ViewDocumentationModal,
    viewDocumentData,
    FolderDeleteModal,
    selectedDatesOfCalender,
    appointmentDeatilsData,
    AppointmentsDetailsModal,
    UpdateReminderModal,
    week,
    day,
    appointmentId,
    categoryType,
    UpdateCategoryModal,
    designSelectionCategoryId,
    ReleasefileModal,
    releaseFileData,
    VendorId,
    ReleaseFolderfileModal,
    folderData,
    SendForClientModal,
    primarySignee,
    additionalSignee,
    numberOfSignee,
  secondarySignee,
    docsId,
    EditDocumentationModal,
    documentData,
    HeaderPreviewModal,
    viewDocumentation,
    content,
    JobId,
    DocumentKeyModal,
    documentSearch,
    name,
    productLink,
    description,
    vendorId,
    designCategoryIds,
    DesignSelectionsAggrementInteriorModal,
    DesignSelectionsAggrementExteriorModal,
    Aggrement,
    attachmentsArray,
    SelectionHistoryModal,
    selectionHistoryProjectId,
    selectionHistoryId,
    jobType,
    selectionType,
    ManageSignatueModal,
    signature,
    isGenerateAggremet,
    fileNames,
    builderSignStatus,
    primarySignStatus,
    secondarySignStatus ,
    additionalSignStatus,
    primarySignature,
    secondarySignature,
    additionalSignature,
    builderSignature,
    ManageSignature,
    signTypes,
    primarySignatureBlob,
    secondarySignatureBlob,
    builderSignatureBlob,
    additionalSignatureBlob,
    ManageSignatures,
    builderSignee,
    draft,
    loading,
    htmlUrl,
    errors,
    NavigateModal,
  } = iState;

  const {
    interiorCategoryList,
    interiorCreateCategoryList,
    pendingAppointmentLoader,
    pendingAppointmentList,
    appointmentCategoryList,
    appointmentDesignerList,
    approvedAppointmentList,
    priceBookCategoryList,
    assembleList,
    vendorList,
    folderLoader,
    folderList,
    pendingFileLoader,
    pendingFileeEventList,
    documentLoader,
    documentList,
    documentHistoryLoader,
    documentHistoryList,
    primaryList,
    secondaryList,
    exteriorCreateCategoryList,
    getCostCodeList,
    getMeasureList,
    getOptionByCategoryList,
    selectionHistoryList,
  } = useSelector((state) => state.designSelectionsReducers);

  const { allDocumentKey } = useSelector((state) => state.fileManagerData);
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const [primary, setPrimary] = useState([]);
  const [agreementSignatures, setAgreementSignatures] = useState([]);
  const [agreementClient, setAgreementClient] = useState([]);
  const jobData = useLocation();
  console.log("jobData: " , jobData);

  
  const { allProjectList } = useSelector(
    (state) => state.vendorViewConfigReducer
  );

  useEffect(() => {
    dispatch(getAllProjectList(iBuildLocalData?.user?.CompanyId));
  },[])
  const createAboutMarkup = () => {
    return { __html: iBuildLocalData?.companies?.[0]?.letterHeader };
  };
  const [interiorAgreementSummary, setInteriorAgreementSummary] = useState({
    totalItem: 0,
    price: 0,
    discount: 0,
    allowance: 0,
    subtotalPrice: 0,
    changeOrder: 0,
    tax: 0,
    grandTotal: 0,
  });
  const [ExteriorAgreementSummary, setExteriorAgreementSummary] = useState({
    totalItem: 0,
    price: 0,
    discount: 0,
    allowance: 0,
    subtotalPrice: 0,
    changeOrder: 0,
    tax: 0,
    grandTotal: 0,
  });

  useEffect(() => {
    if (jobData?.state?.ProjectId) {
      dispatch(getInteriorCategoryList(jobData?.state?.ProjectId));
      dispatch(
        getInteriorCreateCategoryList(
          jobData?.state?.ProjectId,
          jobData?.state?.jobType,
          jobData?.state?.id
        )
      ).then((res) => {
        if (res.status === 200) {
          updateState((prev) => ({
            ...prev,
            isGenerateAggremet: res?.data?.data?.[0]?.isAgreementGenerated,
          }));
        }
      });

      dispatch(
        getExteriorCreateCategoryList(
          jobData?.state?.ProjectId,
          jobData?.state?.jobType,
          jobData?.state?.id
        )
      );

      dispatch(
        getAppointmentCategoryList(
          jobData?.state?.ProjectId,
          jobData?.state?.jobType,
          jobData?.state?.id
        )
      );
      dispatch(getDesignerVendorList(jobData?.state?.ProjectId));
      dispatch(getApprovedAppointmentList(jobData?.state?.ProjectId)).then(
        (res) => {
          if (res.status == 200 && res?.data?.data?.length > 0) {
            const selectedDatesOfCalender = res?.data?.data
              ?.flatMap((item) => {
                return item?.designAppointmentDates?.map((dateItem) => {
                  if (dateItem?.date) {
                    // Split date string and construct a new Date object with the correct format
                    const [year, month, day] = dateItem.date.split("-");
                    return new Date(year, month - 1, day); // Months are 0-based in JavaScript Date constructor
                  } else {
                    console.error(
                      "dateItem or dateItem.date is null or undefined:",
                      dateItem
                    );
                    return null; // Handle null or undefined dateItem or dateItem.date
                  }
                });
              })
              .filter((date) => date !== null); // Filter out null values

            updateState({
              ...iState,
              selectedDatesOfCalender: selectedDatesOfCalender,
            });
          }
        }
      );
      dispatch(getPriceBookCategoryList(jobData?.state?.ProjectId));
      dispatch(getVendorList(jobData?.state?.ProjectId));
      dispatch(
        getGeneralInformationData(jobData?.state?.id, jobData?.state?.jobType)
      ).then((res) => {
        if (res.status === 200) {
          dispatch(
            getPrimaryClient(
              res?.data?.ViewSetting?.id,
              jobData?.state?.id,
              jobData?.state?.jobType
            )
          ).then((resp) => {
            if (resp.status === 200) {
              const primary = resp?.data?.clientsDetails?.find(
                (item) => item.isPrimary === true
              );
              setPrimary(primary);
            }
          });

          dispatch(
            getSecondaryClient(
              res?.data?.ViewSetting?.id,
              jobData?.state?.id,
              jobData?.state?.jobType
            )
          );
        }
      });

      dispatch(getAllDocumentKeyList());
      dispatch(getCostCodeDropDown(jobData?.state?.ProjectId));
      dispatch(getMeasureDropDown(jobData?.state?.ProjectId));
    }
  }, []);

  useEffect(() => {
    if (designCategoryId != "") {
      dispatch(getSelectItemList(jobData?.state?.ProjectId, designCategoryId));
    }
  }, [designCategoryId]);

 

  const handleAddCategoryShowHide = (type, catType) => {
    if (type === "show")
      updateState({ ...iState, AddCategoryModal: true, categoryType: catType });
    else {
      updateState({ ...iState, AddCategoryModal: false, categoryType: "" });
    }
  };



  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleCheckboxChange = (item) => {
    const isSelected = selectedCategories.some(
      (category) => category.categoryId === item.id
    );

    // Update selectedCategories based on checkbox state
    if (isSelected) {
      setSelectedCategories(
        selectedCategories.filter((category) => category.categoryId !== item.id)
      );
    } else {
      const selectedCategory = {
        categoryId: item.id,
        deadline: null, // You can set an actual value for deadline
        multiple: "", // Assuming type comes from the item
        allowance: "", // You can set an actual value for allowance
        name: item.name, // You can set an actual value for allowance
      };

      setSelectedCategories([...selectedCategories, selectedCategory]);
    }
  };

  const handleCategoryChange = (e, index) => {
    const { name, value } = e.target;
    let updatedCategories = [...selectedCategories];
    updatedCategories[index] = {
      ...updatedCategories[index],
      [name]: name === "multiple" ? e.target.checked : value,
    };
    setSelectedCategories(updatedCategories);
  };

  const handleCreateCategory = () => {
    let dataa = {
      selectionType: categoryType,
      jobType: jobData?.state?.jobType,
      jobId: jobData?.state?.id,
      category: selectedCategories?.map((data) => ({
        categoryId: data.categoryId,
        deadline: null,
        type: data.multiple === true ? "multiple" : "Single",
        allowance: data.allowance,
      })),
    };

    dispatch(createInteriorCategory(jobData?.state?.ProjectId, dataa)).then(
      (res) => {
        if (res.status === 200) {
          if (categoryType === "interior") {
            dispatch(
              getInteriorCreateCategoryList(
                jobData?.state?.ProjectId,
                jobData?.state?.jobType,
                jobData?.state?.id
              )
            );
          } else {
            dispatch(
              getExteriorCreateCategoryList(
                jobData?.state?.ProjectId,
                jobData?.state?.jobType,
                jobData?.state?.id
              )
            );
          }

          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            AddCategoryModal: false,
          });
          setSelectedCategories([]);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, AddCategoryModal: true });
        }
      }
    );
  };

  const handleUpdateCategoryShowHide = (type, item, catType) => {
    const newselectedCategory = {
      categoryId: item.category,
      name: item.name,
      allowance: item.allowance,
      multiple: item.multiple === true ? "multiple" : "Single",
    };

    setUpdateCategories([newselectedCategory]);
    // setSelectedCategories((prev)=>([...prev, newselectedCategory]));

    if (type === "show")
      updateState({
        ...iState,
        UpdateCategoryModal: true,
        designSelectionCategoryId: item.designSelectionCategoryId,
        id: item.id,
        categoryType: catType,
      });
    else {
      updateState({ ...iState, UpdateCategoryModal: false, categoryType: "" });
    }
  };

  const [updateCategories, setUpdateCategories] = useState([]);
  // const handleRadioChange = (selectedItem) => {
  //   console.log({selectedItem});
  //   updateState(prev=>({
  //     ...prev,
  //     allowance:"",
  //     multiple:""
  //     }))
  //   setUpdateCategories(prev=>([

  //     {name:selectedItem.name,categoryId:selectedItem.id}
  //  ] ))
  //   console.log({updateCategories});
  // };
  const handleRadioChange = (selectedItem) => {
    updateState((prev) => ({
      ...prev,
      allowance: "",
      multiple: "",
    }));
    setUpdateCategories([
      {
        name: selectedItem.name,
        categoryId: selectedItem.id,
        multiple: false,
        allowance: "",
      },
    ]);
  };

  const handleUpdateCategoryChange = (e, index) => {
    const { name, value } = e.target;
    let updatedCategoriey = [...updateCategories];
    updatedCategoriey[index] = {
      ...updatedCategoriey[index],
      [name]: name === "multiple" ? e.target.checked : value,
    };
    setUpdateCategories(updatedCategoriey);
  };

  const handleUpdateCategory = () => {
    let dataa = {
      // selectionType: categoryType,
      // jobType: jobData?.state?.jobType,
      // jobId: jobData?.state?.id,
      // category: updateCategories?.map((data) => ({
      category: updateCategories?.[0]?.categoryId,
      //   deadline: null,
      type: updateCategories?.[0]?.multiple === true ? "multiple" : "Single",
      allowance: Number(updateCategories?.[0]?.allowance),
      // })),
    };

    dispatch(
      updateCategory(
        jobData?.state?.ProjectId,
        id,
        designSelectionCategoryId,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        if (categoryType === "interior") {
          dispatch(
            getInteriorCreateCategoryList(
              jobData?.state?.ProjectId,
              jobData?.state?.jobType,
              jobData?.state?.id
            )
          );
        } else {
          dispatch(
            getExteriorCreateCategoryList(
              jobData?.state?.ProjectId,
              jobData?.state?.jobType,
              jobData?.state?.id
            )
          );
        }

        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          UpdateCategoryModal: false,
        });
        setSelectedCategories([]);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, UpdateCategoryModal: true });
      }
    });
  };

  const handleDocumentationDeleteShow = (id) => {
    updateState({
      ...iState,
      DocumentationDeleteModal: true,
      id: id,
    });
  };

  const handleDocumentationDeleteClose = () => {
    updateState({
      ...iState,
      DocumentationDeleteModal: false,
      id: "",
    });
  };

  const handleDocumentationDelete = () => {
    let dataa = { id };
    dispatch(getInteriorCreateCategoryRemove(jobData?.state?.ProjectId, dataa))
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            getInteriorCreateCategoryList(
              jobData?.state?.ProjectId,
              jobData?.state?.jobType,
              jobData?.state?.id
            )
          );
          dispatch(
            getExteriorCreateCategoryList(
              jobData?.state?.ProjectId,
              jobData?.state?.jobType,
              jobData?.state?.id
            )
          );
          toast.success("Delete Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
            theme: "colored",
          });
          updateState(initialState);
        }
      })
      .catch((error) => {
        toast.error(error.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
          theme: "colored",
        });
      });
    updateState({ ...iState, DocumentationDeleteModal: true });
  };

  const handlePendingDateSelectionListModalHideShow = (type) => {
    if (type === "show") {
      updateState({
        ...iState,
        PendingDateSelectionListModal: true,
      });
      dispatch(getPendingAppointmentList(jobData?.state?.ProjectId));
    } else {
      updateState({
        ...iState,
        PendingDateSelectionListModal: false,
      });
    }
  };

  const handleCreateAppointmentModalHideShow = (type) => {
    if (type === "show") {
      updateState({
        ...iState,
        CreateAppointmentModal: true,
      });
    } else {
      updateState({
        ...iState,
        CreateAppointmentModal: false,
      });
    }
  };

  const handleCheck = (e) => {
    updateState({ ...iState, catStatus: e.target.checked });
  };
  const handleChange = (e, field, index) => {
    const updatedDates = [...appointmentDates];
    updatedDates[index][field] = e.target.value;

    updateState((prevState) => ({
      ...prevState,
      appointmentDates: updatedDates,
    }));
  };

  const handleDesignerClick = (item) => {
    updateState({ ...iState, designer: item.id });
  };

  const handleCreateAppointment = () => {
    let dataa = {
      title,
      type,
      catStatus,
      category,
      location,
      streetAddressLine1,
      streetAddressLine2,
      city,
      postalcode,
      country,
      state,
      sentTo,
      designer,
      vendor,
      appointmentDates,
      jobType: jobData?.state?.jobType,
      jobId: jobData?.state?.id,
    };

    dispatch(appointmentCreate(jobData?.state?.ProjectId, dataa)).then(
      (res) => {
        if (res.status === 200) {
          // dispatch(getInteriorCreateCategoryList());
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            CreateAppointmentModal: false,
            title: "",
            type: "",
            catStatus: "",
            category: "",
            location: "",
            streetAddressLine1: "",
            streetAddressLine2: "",
            city: "",
            postalcode: "",
            country: "",
            state: "",
            sentTo: "",
            designer: "",
            vendor: "",
            // appointmentDates:[],
            jobType: "",
            jobId: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, CreateAppointmentModal: true });
        }
      }
    );
  };

  const handlePendingDeleteModalShow = (id) => {
    updateState({
      ...iState,
      PendingDeleteModal: true,
      id: id,
    });
  };

  const handlePendingDeleteModalClose = () => {
    updateState({
      ...iState,
      PendingDeleteModal: false,
      id: "",
    });
  };

  const handlePendingDelete = () => {
    let dataa = { id };
    dispatch(deletePenidngAppointment(jobData?.state?.ProjectId, dataa)).then(
      (res) => {
        if (res.status === 200) {
          dispatch(getPendingAppointmentList(jobData?.state?.ProjectId));
          toast.success("Delete Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
            theme: "colored",
          });
          updateState({ ...iState, PendingDeleteModal: false });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, PendingDeleteModal: true });
        }
      }
    );
  };

  const handleApprovedAppointmentDetailsModalShow = (id) => {
    updateState({
      ...iState,
      ApprovedAppointmentDetailsModal: true,
      id: id,
    });
  };

  const handleApprovedAppointmentDetailsModalClose = () => {
    updateState({
      ...iState,
      ApprovedAppointmentDetailsModal: false,
      id: "",
    });
  };

  const handleCategoryToggle = () => {
    updateState({ ...iState, categoryToggle: !categoryToggle });
  };

  const handleCategoryClick = (id) => {
    updateState({ ...iState, designCategoryId: id });
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const form = new FormData();
    for (let i = 0; i < files.length; i++) {
      form.append("files", files[i]);
    }

    dispatch(uploadmultipleFiles(form)).then((data) => {
      const newAttachments = data?.data?.data?.map((fileData) => {
        return {
          fileUrl: fileData,
          main: false,
        };
      });

      updateState({ ...iState, attachments: newAttachments });
    });
  };

  const attachmentChange = (e, i) => {
    const { checked } = e.target;
    let newList = [...attachments];
    newList = newList?.map((attachment, index) => {
      if (index === i) {
        return { ...attachment, main: checked };
      } else {
        return { ...attachment, main: false };
      }
    });
    updateState({ ...iState, attachments: newList });
  };

  const removeAttachment = (i) => {
    let newAttachments = [...attachments];
    newAttachments.splice(i, 1);
    updateState({
      ...iState,
      attachments: newAttachments,
    });
  };

  const [showImageModal, setImageModal] = useState(false);

  const handleViewImagesShow = () => {
    setImageModal(true);
    updateState({ ...iState, ViewImagesModal: true, AddOptionModal: false });
  };
  const handleViewImagesClose = () => {
    setImageModal(false);
    updateState({
      ...iState,
      ViewImagesModal: !ViewImagesModal,
      AddOptionModal: true,
    });
  };
   
  const [contactData,setContactData]=useState([])
  const handleDesignSelectionsModalShow = () => {
    updateState({ ...iState, DesignSelectionsModal: true });
    dispatch(getPrimaryClientByJobs({id: jobData?.state?.id,jobType: jobData?.state?.jobType,isPrimary: true})).then((res)=>{
      if(res.status ===200){
      console.log("resss",res);
      }
    })
    dispatch(getDesignFolderList(jobData?.state?.id, jobData?.state?.jobType,jobData?.state?.ProjectId));
    
  };

  const handleDesignSelectionsModalClose = () => {
    updateState({ ...iState, DesignSelectionsModal: false });
  };

  const handleCreateFolderShow = () => {
    updateState({
      ...iState,
      CreateFolderModal: true,
      folderName: "",
      DesignSelectionsModal: false,
    });
  };

  const handleCreateFolderClose = () => {
    updateState({
      ...iState,
      CreateFolderModal: false,
      folderName: "",
      DesignSelectionsModal: true,
    });
  };

  const handleCreateFolderSubmit = (e) => {
    e.preventDefault();
    // let formIsValid = handleValidation();
    // if (formIsValid) {
    updateState({ ...iState, loading: true });
    const dataa = {
      folderName,
      subFileManager: "Design",
    };
    dispatch(
      createDesignFolder(
        jobData?.state.id,
        jobData?.state.jobType,
        jobData?.state?.ProjectId,
        dataa
      )
    )
      .then((res) => {
        if(res.status ===201){
        dispatch(
          getDesignFolderList(jobData?.state?.id, jobData?.state?.jobType,jobData?.state?.ProjectId)
        );
        toast.success("Folder created successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          folderName: "",
          CreateFolderModal: false,
          DesignSelectionsModal: true,
        });
  }

 else {
  toast.error(res.data.message, {
    position: toast.POSITION.TOP_RIGHT,
  });
  updateState({ ...iState, loading: false, CreateFolderModal: true });
 }
})
      
      .catch((error) => {
        toast.error(error.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, loading: false, CreateFolderModal: true });
      });
  };
console.log("projectId",jobData);
  const handleFolderDeleteShow = (e,id, foldName) => {
    e.stopPropagation(); 
    updateState({
      ...iState,
      FolderDeleteModal: true,
      id: id,
      folderName: foldName,
      DesignSelectionsModal: false,
    });
  };

  const handleFolderDeleteClose = () => {
    updateState({
      ...iState,
      FolderDeleteModal: false,
      id: "",
      DesignSelectionsModal: true,
    });
  };

  const handleFolderDelete = () => {
    let dataa = { folderName };
    dispatch(
      getDesignFolderRemove(
        id,
        jobData?.state?.id,
        jobData?.state?.jobType,
        dataa
      )
    )
      .then((res) => {
        if (res.status === 200) {
          dispatch(getDesignFolderList(jobData?.state?.id, jobData?.state?.jobType,jobData?.state?.ProjectId));
          toast.success(" Delete Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            FolderDeleteModal: false,
            id: "",
            DesignSelectionsModal: true,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            FolderDeleteModal: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          FolderDeleteModal: true,
        });
      });
  };
  const [folderIDD,setFolderIDD]=useState("")
  const handleFileAndPendingFileData = (e,id) => {
    e.stopPropagation();
    setFolderIDD(id)
    dispatch(getDesignPendingFileEventList(id));
    dispatch(getListDesignDocument(id));
  };


  const handleCreateNewFileModalShow = () => {
    updateState({
      ...iState,
      CreateNewFileModal: true,
      DesignSelectionsModal: false,
    });
  };

  const handleCreateNewFileModalClose = () => {
    updateState({
      ...iState,
      CreateNewFileModal: false,
      DesignSelectionsModal: true,
    });
  };

  const handleTemplateFileChange = (event, type) => {
    const file = event?.target?.files[0];
    if (type === "createFile") {
      updateState({ ...iState, clientFile: file });
    }
  };

  const handleRemoveClientFile = () => {
    // Reset the 'templateFile' state to null
    updateState({ ...iState, clientFile: null });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#createFileInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };




  const handleCreateDocumentation = async () => {
    try {
      let fileUrl = "";
      if (htmlUrl) {
        const blobs = new Blob([htmlUrl], { type: "text/html" });
        const formData = new FormData();
        formData.append("files", blobs);
        const uploadResponse = await dispatch(uploadAggrementFiles(formData));
        if (uploadResponse.status === 200) {
          fileUrl = uploadResponse?.data?.data;
          //  const docxBlob =  await htmlDocx.asBlob(fileUrl)
          //  const fileNameData = await `${fileFolderName}`;
          //  var docxFile =  await new File([docxBlob],`${fileNameData}.docx`, {
          //    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          //  });
        }
      }

      const data = {
        name: fileFolderName,
        htmlUrl: fileUrl || "",
        files: clientFile,
        clientView: true,
      };

      const response = await dispatch(createDesignDocumentation(id, data));

      if (response?.status === 201) {
        await dispatch(getListDesignDocument(id));
        toast.success("Create File Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setSelectedEditor("BaseTemplate");
        updateState({
          ...iState,
         
          htmlUrl: "",
          CreateNewFileModal: false,
          fileFolderName: "",
          clientFile: null,
          id:"",
          DesignSelectionsModal:true
        });
      }
    } catch (error) {
      toast.error(error?.data?.message, { position: toast.POSITION.TOP_RIGHT });
      updateState({ ...iState, CreateNewFileModal: true });
    }
  };

  const handleFolderHistoryModalShow = (folderID, desinDoc,name) => {
    updateState({
      ...iState,
      FolderHistoryModal: true,
      DesignSelectionsModal: false,
      fileNames: name,
    });

    dispatch(getDesignDocumentHistory(folderID, desinDoc));
  };

  const handleFolderHistoryModalClose = () => {
    updateState({
      ...iState,
      FolderHistoryModal: false,
      DesignSelectionsModal: true,
    });
  };

  const handleShareClientDocumentShow = (foldId, Id) => {
    updateState({
      ...iState,
      ShareClientDocumentationModal: true,
      emailBody: "",
      emails: [],
      clientName: "",
      clientEmail: "",
      folderId: foldId,
      id: Id,
    });
  };

  const handleShareClientDocumentClose = () => {
    updateState({
      ...iState,
      ShareClientDocumentationModal: false,
      emailBody: "",
      emails: [],
      clientName: "",
      clientEmail: "",
    });
  };

  const handleAddRecipient = () => {
    const { clientName, clientEmail } = iState;

    // Create a copy of the emails array
    const updatedEmails = [...iState.emails];

    // Push a new recipient object with the clientName and clientEmail values
    updatedEmails.push({ name: clientName, email: clientEmail });

    // Update the state with the modified emails array and reset clientName and clientEmail
    updateState({
      ...iState,
      emails: updatedEmails,
      clientName: "",
      clientEmail: "",
    });
  };

  const handleRemoveRecipient = (indexToRemove) => {
    // Create a copy of the emails array
    const updatedEmails = [...iState.emails];

    // Use the filter method to create a new array without the item at the specified index
    const updatedEmailsFiltered = updatedEmails.filter(
      (_, index) => index !== indexToRemove
    );

    // Update the state with the modified emails array
    updateState({ ...iState, emails: updatedEmailsFiltered });
  };

  const handleShareDocumentSubmit = () => {
    const dataa = {
      emailBody,
      emails,
    };

    dispatch(getShareDesignDocument(folderId, id, dataa))
      .then((res) => {
        if (res.status === 200) {
          // dispatch(getListDocumentation(folderId));
          toast.success("Share Document Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            ShareClientDocumentationModal: false,
            folderId: "",
            id: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            loading: false,
            ShareClientDocumentationModal: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          loading: false,
          ShareClientDocumentationModal: true,
        });
      });
  };

  const handleViewDocumentationShow = async (url) => {
    try {
      updateState((prevState) => ({
        ...prevState,
        DesignSelectionsModal: false,
        ViewDocumentationModal: true,
      }));
  
      const urlWithoutQueryString = url?.split("?")[0];
      const htmlData = await
      dispatch(
        getConverToString(urlWithoutQueryString, iBuildLocalData?.user?.token))
      
      if (htmlData?.data) {
        updateState((prevState) => ({
          ...prevState,
          viewDocumentData: htmlData?.data?.data,
        }));
      }
    } catch (error) {
      console.error("Error fetching documentation:", error);
    }
  };
  

  const handleViewDocumentationClose = () => {
    updateState({
      ...iState,
      ViewDocumentationModal: false,
      DesignSelectionsModal: true,
      viewDocumentData: "",
    });
  };

  const handleResendDateSelection = (appointmentId, resendType) => {
    const dataa = {
      appointmentId,
      resendType: resendType === "client" ? "client" : "vendor",
    };
    dispatch(resendDateSelection(jobData?.state?.ProjectId, dataa)).then(
      (res) => {
        if (res.status === 202) {
          dispatch(getPendingAppointmentList(jobData?.state?.ProjectId));
          toast.success("Resend Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  const handleAppointmentsDetailsModalShow = (data) => {
    updateState({
      ...iState,
      AppointmentsDetailsModal: true,
      appointmentDeatilsData: data,
    });
  };

  const handleAppointmentsDetailsModalClose = () => {
    updateState({ ...iState, AppointmentsDetailsModal: false });
  };

  const handleUpdateReminderShow = (id) => {
    updateState({
      ...iState,
      UpdateReminderModal: true,
      appointmentId: id,
    });
  };

  const handleUpdateReminderClose = () => {
    updateState({
      ...iState,
      UpdateReminderModal: false,
      appointmentId: "",
    });
  };

  const handleReminderUpdate = () => {
    const types = week ? week : day;
    const reminderDates = calculateDateDifference(types);
    const datta = { reminderDate: reminderDates, appointmentId };
    dispatch(updateReminder(jobData?.state?.ProjectId, datta)).then((res) => {
      if (res.status === 200) {
        // dispatch((builderIdd, projectId, jobIdd, jobType));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          UpdateReminderModal: false,
          day: "",
          week: "",
        });
        dispatch(getApprovedAppointmentList(jobData?.state?.ProjectId)).then(
          (res) => {
            if (res.status == 200 && res?.data?.data?.length > 0) {
              const selectedDatesOfCalender = res?.data?.data
                ?.flatMap((item) => {
                  return item?.designAppointmentDates?.map((dateItem) => {
                    if (dateItem?.date) {
                      // Split date string and construct a new Date object with the correct format
                      const [year, month, day] = dateItem.date.split("-");
                      return new Date(year, month - 1, day); // Months are 0-based in JavaScript Date constructor
                    } else {
                      console.error(
                        "dateItem or dateItem.date is null or undefined:",
                        dateItem
                      );
                      return null; // Handle null or undefined dateItem or dateItem.date
                    }
                  });
                })
                .filter((date) => date !== null); // Filter out null values

              updateState({
                ...iState,
                selectedDatesOfCalender: selectedDatesOfCalender,
              });
            }
          }
        );
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, UpdateReminderModal: true });
      }
    });
  };

  const handleReleasefileModalShow = (data) => {
    updateState((prevState) => ({
      ...prevState,
      folderId: "",
      folderData: [],
      ReleasefileModal: true,
      DesignSelectionsModal: false,
      releaseFileData: data,
     
    }));
  };
  


  const handleReleasefileModalClose = () => {
    updateState({
      ...iState,
      ReleasefileModal: false,
      DesignSelectionsModal: true,
    });
  };

  const [selectedOption, setSelectedOption] = useState("");
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    updateState({...iState,VendorId:""})
  };

  const handleReleasefile = () => {
    const datta = {
      projectId: jobData?.state?.ProjectId,
      subFileManager: selectedOption,
      folderId: releaseFileData?.folderId,
      clientDocId: releaseFileData?.id,
      VendorId:VendorId||0,
    };
    dispatch(ReleaseFiles(datta)).then((res) => {
      if (res.status === 200) {
        // dispatch((builderIdd, projectId, jobIdd, jobType));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          ReleasefileModal: false,
          DesignSelectionsModal: true,
          folderId:"",
          folderData:[]
        });
        setSelectedOption("")
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, ReleasefileModal: true });
      }
    });
  };

  const handleReleaseFolderfileModalShow = () => {
    updateState({
      ...iState,
      ReleaseFolderfileModal: true,
      DesignSelectionsModal: false,
    });
  };

  const handleReleaseFolderfileModalClose = () => {
    updateState({
      ...iState,
      ReleaseFolderfileModal: false,
      DesignSelectionsModal: true,
    });
  };

  const [selectedItems, setSelectedItems] = useState([]);

  const handleFolderCheckboxChange = (event, item) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((selectedItem) => selectedItem !== item)
      );
    }
  };

  const handleReleaseFolderfile = () => {
    const datta = {
      projectId: jobData?.state?.ProjectId,
      jobId:jobData?.state?.jobId,
      jobType:jobData?.state?.jobType,
      subFileManager: selectedOption,
      VendorId:VendorId||null,
      data: [
        {
          folderId: selectedItems?.[0]?.FolderId,
          Templates: selectedItems?.map((data) => ({
            id: data.id,
          })),
        },
      ],
    };
    dispatch(ReleaseFolderFiles(datta)).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          ReleaseFolderfileModal: false,
          DesignSelectionsModal: true,
          VendorId: "",
          selectedOption: "",
        });
        setSelectedItems([]);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, ReleaseFolderfileModal: true });
      }
    });
  };


  const [docsUrlData, setDocsUrlData] = useState("");
  const handleSendForClientModalShow = (data) => {
    updateState({
      ...iState,
      SendForClientModal: true,
      DesignSelectionsModal: false,
      folderId: data?.folderId,
      docsId: data?.id,
    });

    setDocsUrlData(data?.docUrl);
  };

  const handleSendForClientModalClose = () => {
    updateState({
      ...iState,
      SendForClientModal: false,
      DesignSelectionsModal: true,
    });
  };


  const handleSendForClient = () => {
    handleSendforClientSignatureModalShow();
  };

  // const handleSendForClient = () => {
  //   const formData = new FormData();
  //   formData.append("primarySignee[0]", primarySignee);
  //   formData.append("additionalSignee[0]", additionalSignee);
  //   formData.append("numberOfSignee", numberOfSignee);
  //   formData.append("builderSignee[0]", iBuildLocalData?.user?.id);
  //   formData.append("draft", "false");
  //   dispatch(sendForClientSignature(folderId, docsId, formData)).then((res) => {
  //     if (res.status === 200) {
  //       toast.success(res.data.message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //         autoClose: 1000,
  //       });
  //       updateState({
  //         ...iState,
  //         SendForClientModal: false,
  //         DesignSelectionsModal: true,
  //         primarySignee: "",
  //         additionalSignee: "",
  //         numberOfSignee: "",
  //       });
  //     } else {
  //       toast.error(res.data.message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       updateState({ ...iState, SendForClientModal: true });
  //     }
  //   });
  // };

  const createAboutHeader = () => {
    return { __html: iBuildLocalData?.companies?.[0]?.letterHeader };
  };
  const [SendforClientSignatureModal, setSendforClientSignatureModal] =
  useState(false);
  const handleSendforClientSignatureModalShow = async () => {
    // Show the client signature modal
    setSendforClientSignatureModal(true);
  
    // Update the modal state
    updateState((prevState) => ({
      ...prevState,
      SendForClientModal: false,
    }));
  
    try {
      // const contId = JSON.parse(window.localStorage.getItem("contId"));
      const payload = {
        // primaryClientId:+primarySignee, 
        // secondaryClientId:+secondarySignee, 
        // additionalClientId:+additionalSignee, 
        // clientDocId :+docsId,
        // contractId: +contId, 
        // folderId: +folderId,
        // builderId:+builderSignee
      };

      const response = await dispatch(sendForClientSignature(payload));
  
      if (response?.data) {
        setDocsUrlData(response.data.data);
      } else {
        console.warn("Response data not found.");
      }
    } catch (error) {
      // Handle the error, e.g., log it or show an error message.
      console.error("Error fetching documentation:", error);
    }
  };
  
  

const handleSendforClientSignatureModalClose = () => {
  setSendforClientSignatureModal(false);
  updateState((prevState) => ({
    ...prevState,
    SendForClientModal: true,
  }));
};

  const handlHeaderPreviewModalShow = () => {
    updateState({
      ...iState,
      HeaderPreviewModal: true,
      EditDocumentationModal: false,
    });
  };
  const handlHeaderPreviewModalClose = () => {
    updateState({
      ...iState,
      HeaderPreviewModal: false,
      EditDocumentationModal: true,
    });
  };

  const handleApply = () => {
    const dataa = iBuildLocalData?.companies?.[0]?.letterHeader;
    const updatedContent = dataa ? dataa + content : content;
    updateState({
      ...iState,
      headerPreviewData: dataa,
      content: updatedContent,
      HeaderPreviewModal: false,
      EditDocumentationModal: true,
    });
  };

  const handleDocumentKeyModalShow = () => {
    updateState({
      ...iState,
      DocumentKeyModal: true,
      JobId: "",
      EditDocumentationModal: false,
    });
  };

  const handleDocumentKeyModalClose = () => {
    updateState({
      ...iState,
      DocumentKeyModal: false,
      JobId: "",
      EditDocumentationModal: true,
    });
  };

  const handleItemClick = (item) => {
    const updatedContent = item ? item + content : content;
    updateState({
      ...iState,
      content: updatedContent,
      DocumentKeyModal: false,
      EditDocumentationModal: true,
    });
  };

  const [contentData, setContentData] = useState([]);
  const handleEditDocumentationShow = async (item) => {
    updateState({
      ...iState,
      EditDocumentationModal: true,
      DesignSelectionsModal:false
    });
  
    setContentData(item);
    const datta = { clientView: true };
    try {
      const urlWithoutQueryString = item?.htmlUrl?.split("?")[0];
      const htmlData = await dispatch(
        getConverToString(urlWithoutQueryString, iBuildLocalData?.user?.token)
      );
      if (htmlData) {
    
        updateState({
          ...iState,
          EditDocumentationModal: true,
          content: htmlData?.data?.data,
          DesignSelectionsModal:false
        });
      }
    } catch (error) {
      console.error("Error fetching documentation:", error);
    }
  };
  

  const handleEditDocumentationClose = () => {
    updateState({
      ...iState,
      EditDocumentationModal: false,
      folderId,
      id,
      DesignSelectionsModal:true,
    });
  };

  const handleAddOptionShow = (id, type) => {
    updateState({
      ...iState,
      AddOptionModal: true,
      designCategoryIds: id,
      name: "",
      designCategoryId: "",
      productLink: "",
      description: "",
      vendorId: "",
      // attachments: "",
    });
    setSelectedAssemblies([]);
  };
  const [typeEdit, setTypeEdit] = useState(false);
  const [selectedAssemblies, setSelectedAssemblies] = useState([]);

  const handleEditOptionShow = async (id, catId) => {
    dispatch(selectedDesignOptionById(jobData?.state?.ProjectId, id)).then(
      (res) => {
        if (res.status === 200) {
          toast.success("Selected Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });

          const categoryDetails = res?.data?.categoryDetails?.[0];
          if (categoryDetails) {
            setSelectedAssemblies([
              ...categoryDetails?.designOptionPriceBookSelections,
            ]);
            updateState({
              ...iState,
              name: res?.data?.categoryDetails?.[0]?.name,
            });
            updateState((prev) => ({
              ...prev,
              designCategoryId: categoryDetails.designCategoryId,
              productLink: categoryDetails.productLink,
              description: categoryDetails.description,
              vendorId: categoryDetails.vendorId,
              attachments: [...categoryDetails.designOptionAttachments],
              AddOptionModal: true,
            }));
          }

          setTypeEdit(true);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );

    // updateState((prev) => {
    //     console.log("Updating state with AddOptionModal and designCategoryIds", prev);
    //     return {
    //         ...prev,
    //         AddOptionModal: true,
    //         designCategoryIds: id,
    //     };
    // });
  };

  
  const handleAddOptionModalClose = () => {
    updateState({
      ...iState,
      AddOptionModal: false,
      designCategoryIds: "",
    });
    setTypeEdit(false);
  };
  const [showSelectItemBookPriceModal, setShowSelectItemBookPriceModal] =
    useState(false);

  const handleSelectItemBookPriceModalShow = () => {
    updateState({
      ...iState,
      SelectItemBookPriceModal: true,
      AddOptionModal: false,
    });
    setShowSelectItemBookPriceModal(true);
  };

  const handleSelectItemBookPriceModalClose = () => {
    updateState((prev) => ({
      ...prev,
      SelectItemBookPriceModal: false,
      AddOptionModal: !prev.AddOptionModal,
    }));
    setShowSelectItemBookPriceModal(false);
  };

  const handleAssemblyCheckboxChange = (item) => {
    const isSelected = selectedAssemblies.some(
      (assembly) => assembly.entryId == item.id
    );
    if (isSelected) {
      setSelectedAssemblies(
        selectedAssemblies.filter((assembly) => assembly.entryId !== item.id)
      );
    } else {
      const selectedAssembly = {
        entryId: item.id,
        entryName: item.name,
        quantity: "",
        measure: item?.measure?.name,
        costcode: "",
        markup: "",
        perUnit: "",
        builderTotal: "",
        discount: "",
        clientTotal: "",
      };

      setSelectedAssemblies([...selectedAssemblies, selectedAssembly]);
    }
    if (typeEdit) {
      if (isSelected) {
        setSelectedAssemblies([]);
      } else {
        const selectedAssembly = {
          entryId: item.id,
          entryName: item.name,
          quantity: "",
          measure: item?.measure?.name,
          costcode: "",
          markup: "",
          perUnit: "",
          builderTotal: "",
          discount: "",
          clientTotal: "",
        };

        setSelectedAssemblies([selectedAssembly]);
      }
    }
  };

  const handleAssemblyChange = (e, i) => {
    const { name, value } = e.target;
    let user = [...selectedAssemblies];
    user[i] = { ...user[i], [name]: value };
    setSelectedAssemblies(user);
  };
  // console.log("selectedAssemblies", selectedAssemblies);

  const handleCreateOption = () => {
    const dataa = {
      name,
      designCategoryId: designCategoryIds,
      productLink,
      description,
      vendorId,
      attachments: attachments,
      priceDetails: selectedAssemblies?.map((data, i) => ({
        entryId: data.entryId,
        entryName: data.entryName,
        costcode: Number(data.costcode),
        markup: Number(data.markup),
        quantity: Number(data.quantity),
        measure: data.measure,
        perUnit: Number(data.perUnit),
        builderTotal: Number(data.perUnit * data.quantity),
        discount: Number(data.discount),
        clientTotal:
          Number(data.perUnit * data.quantity) +
          Number(
            Number(data.perUnit * data.quantity) *
              data.markup *
              (data.discount - 1)
          ),
      })),
    };

    dispatch(createOption(jobData?.state?.ProjectId, dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(
          getOptionByCategoryId(jobData?.state?.ProjectId, designCategoryIds)
        );
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState((prev) => ({
          ...prev,
          AddOptionModal: !prev.AddOptionModal,
        }));
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, AddOptionModal: true });
      }
    });

    setTypeEdit(false);
  };

  const [editId, setEditId] = useState(null);

  const handleUpdateOption = () => {
    const dataa = {
      optionId: selectedAssemblies?.[0]?.optionId,
      name,
      designCategoryId: designCategoryIds,
      productLink,
      description,
      vendorId,
      designOptionAttachments: attachments,
      designOptionPriceBookSelections: selectedAssemblies?.map((data, i) => ({
        optionId: data.optionId,
        id: data.id,
        entryId: data.entryId,
        entryName: data.entryName,
        costcode: Number(data.costcode),
        markup: Number(data.markup),
        quantity: Number(data.quantity),
        measure: data.measure,
        perUnit: Number(data.perUnit),
        builderTotal: Number(data.perUnit * data.quantity),
        discount: Number(data.discount),
        clientTotal:
          Number(data.perUnit * data.quantity) +
          Number(
            Number(data.perUnit * data.quantity) *
              data.markup *
              (data.discount - 1)
          ),
      })),
    };

    dispatch(updateOptions(jobData?.state?.ProjectId, dataa)).then((res) => {
      if (res.status === 202) {
        dispatch(getOptionByCategoryId(jobData?.state?.ProjectId, editId));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState((prev) => ({
          ...prev,
          AddOptionModal: !prev.AddOptionModal,
        }));
        setTypeEdit(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, AddOptionModal: false });
      }
    });
  };

  const handleOptionDelete = (id, catId) => {
    dispatch(deleteOptionById(jobData?.state?.ProjectId, id)).then((res) => {
      if (res.status === 200) {
        dispatch(getOptionByCategoryId(jobData?.state?.ProjectId, catId));
        toast.success(" Delete Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleOptionSelected = (id, catId) => {
    dispatch(selectedDesignOption(jobData?.state?.ProjectId, id)).then(
      (res) => {
        if (res.status === 202) {
          dispatch(getOptionByCategoryId(jobData?.state?.ProjectId, catId));
          dispatch(
            getInteriorCreateCategoryList(
              jobData?.state?.ProjectId,
              jobData?.state?.jobType,
              jobData?.state?.id
            )
          );
          dispatch(
            getExteriorCreateCategoryList(
              jobData?.state?.ProjectId,
              jobData?.state?.jobType,
              jobData?.state?.id
            )
          );
          toast.success("Selected Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  const handleAccordionToggle = (index, id) => {
    dispatch(getOptionByCategoryId(jobData?.state?.ProjectId, id));
    setOpenAccordionIndex(openAccordionIndex === index ? null : index);
  };

  const handleCancelAppointment = (id) => {
    const dataa = { id };
    dispatch(deletePenidngAppointment(jobData?.state?.ProjectId, dataa)).then(
      (res) => {
        if (res?.status === 200) {
          toast.success("Delete successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          dispatch(getApprovedAppointmentList(jobData?.state?.ProjectId)).then(
            (response) => {
              if (response.status == 200 && response?.data?.data?.length > 0) {
                const selectedDatesOfCalender = response?.data?.data
                  ?.flatMap((item) => {
                    return item?.designAppointmentDates?.map((dateItem) => {
                      if (dateItem?.date) {
                        // Split date string and construct a new Date object with the correct format
                        const [year, month, day] = dateItem.date.split("-");
                        return new Date(year, month - 1, day); // Months are 0-based in JavaScript Date constructor
                      } else {
                        console.error(
                          "dateItem or dateItem.date is null or undefined:",
                          dateItem
                        );
                        return null; // Handle null or undefined dateItem or dateItem.date
                      }
                    });
                  })
                  .filter((date) => date !== null); // Filter out null values

                updateState({
                  ...iState,
                  selectedDatesOfCalender: selectedDatesOfCalender,
                });
              }
            }
          );
        }
      }
    );
  };

  const handleGoogleData = (item) => {
    const datta = {
      title: "Sample Event",
      description: item.description,
      assignDate: item?.createdAt,
      reminderDate: item?.createdAt,
      location: item.location,
      // assignDate: `${moment(item.date).format("YYYY-MM-DD")}${item.fromTime.slice(10)}`,
    };
    dispatch(getGoogleCalendar(datta))
      .then((res) => {
        if (res.status === 200) {
          window.open(res.data.google, "_blank");
          // dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Find data Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleOutLookData = (item) => {
    const datta = {
      title: "Sample Event",
      description: item.description,
      assignDate: item?.createdAt,
      reminderDate: item?.createdAt,
      location: item.location,
    };
    dispatch(getOutlookCalendar(datta))
      .then((res) => {
        if (res.status === 200) {
          window.open(res.data.outlook, "_blank");
          // dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Find data Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const handleEmailData = (item) => {
    const datta = {
      title: "Sample Event",
      description: item.description,
      assignDate: item?.createdAt,
      reminderDate: item?.createdAt,
      location: item.location,
    };
    dispatch(getEmailCalendar(datta))
      .then((res) => {
        if (res.status === 200) {
          window.open(res.data.yahoo, "_blank");
          // dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Find data Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const [showAddSelectionOption, setshowAddSelectionOption] = useState("");

  const [showImg, setShowImg] = useState("");
  const updateCategoryName = (categoryId) => {
    return interiorCategoryList?.categoryDetails?.filter(
      (data) => data.id == categoryId
    )?.[0]?.name;
  };
  const updateCategoryid = (categoryId) => {
    return interiorCategoryList?.categoryDetails.some(
      (data) => data.id == categoryId
    );
  };

  const handleShowImg = (datas) => {
    const img = datas?.designOptionAttachments?.filter(
      (img) => img?.main == true
    );

    return img?.[0]?.["fileUrl"];
  };

  const handleInteriorExteriorDeleteSelection = (type, projectId, id) => {
    if (type === "interior") {
      dispatch(deleteSelection(projectId, id)).then((res) => {
        if (res.status === 202) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          dispatch(
            getInteriorCreateCategoryList(
              jobData?.state?.ProjectId,
              jobData?.state?.jobType,
              jobData?.state?.id
            )
          );
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    } else {
      dispatch(deleteSelection(projectId, id)).then((res) => {
        if (res.status === 202) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          dispatch(
            getExteriorCreateCategoryList(
              jobData?.state?.ProjectId,
              jobData?.state?.jobType,
              jobData?.state?.id
            )
          );
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const handleDesignSelectionsAggrementInteriorModalClose = () => {
    updateState((prev) => ({
      ...prev,
      DesignSelectionsAggrementInteriorModal:
        !DesignSelectionsAggrementInteriorModal,
      signature: null,
    }));
  };
  const handleDesignSelectionsAggrementExteriorModalClose = () => {
    updateState((prev) => ({
      ...prev,
      DesignSelectionsAggrementExteriorModal: false,
    }));
  };
  const [discount, setDiscount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const discount =
      getOptionByCategoryList?.categoryDetails?.[0]?.designOptionPriceBookSelections?.reduce(
        (a, b) => {
          return a + b.discount;
        },
        0
      );
    setDiscount(discount);
    const price =
      getOptionByCategoryList?.categoryDetails?.[0]?.designOptionPriceBookSelections?.reduce(
        (a, b) => {
          return a + b.clientTotal;
        },
        0
      );
    setTotalPrice(price);
  }, [getOptionByCategoryList]);

  const contentRef = useRef(null);

  const handleConvertAndSend = async (ProjectId, id) => {
    const htmlContent = contentRef.current.innerHTML;

    const formData = new FormData();
    const docxBlob = htmlDocx.asBlob(htmlContent);
    const filesName = "agreement.docx";
    const docxFile = new File([docxBlob], filesName, {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
    formData.append("files", docxFile);
    formData.append("clientView", false);
    formData.append("name", filesName);

    try {
      dispatch(uploadAggrementFiles(formData)).then((res) => {
        if (res?.status == 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          dispatch(
            designSelectionAggrementSend(
              ProjectId,
              res?.data?.data,
              primary?.id,
              id
            )
          );
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
      // console.log('File sent successfully:', response.data);
    } catch (error) {
      console.error("Error sending file:", error);
    }
  };

  const handleSelectionHistoryModalClose = () => {
    updateState((prev) => ({
      ...prev,
      SelectionHistoryModal: false,
      selectionHistoryProjectId: "",
      selectionHistoryId: "",
      jobType: "",
    }));
  };
  const handleSelectionHistoryModalShow = (item) => {
    const { id, selectionType, projectId, jobType } = item;
    updateState((prev) => ({
      ...prev,
      SelectionHistoryModal: true,
      selectionHistoryId: id,
      selectionType,
      selectionHistoryProjectId: projectId,
      jobType,
    }));
  };

  useEffect(() => {
    if (SelectionHistoryModal) {
      dispatch(
        getSelectionHistory(selectionHistoryProjectId, selectionHistoryId)
      );
    }
  }, [SelectionHistoryModal]);

  const handleManageSignatueShow = () => {
    updateState({
      ...iState,
      ManageSignatueModal: true,
      signature: "",
    });
  };
  const handleManageSignatueClose = () => {
    updateState({
      ...iState,
      ManageSignatueModal: false,
      signature: "",
    });
  };

  const addSignatureFun = () => {
    const signatureBlob = dataURLtoBlob(signature);
    const convertToValidFormat = (blob, fileName) => {
      const fileExtension = fileName.split(".").pop().toLowerCase();
      if (
        ![
          "jpg",
          "jpeg",
          "png",
          "gif",
          "pdf",
          "bmp",
          "webp",
          "svg+xml",
        ].includes(fileExtension)
      ) {
        const convertedBlob = convertToPNG(blob);
        return convertedBlob;
      }
      return blob;
    };
    const validSignatureBlob = convertToValidFormat(
      signatureBlob,
      "signature.png"
    );
    const formData = new FormData();
    formData.append("files", validSignatureBlob, "signature.png");
    formData.append("agreementId", interiorCreateCategoryList?.data?.[0]?.id);
    formData.append("name", "Design Manager");

    dispatch(
      addSignatureInAggrement(
        iBuildLocalData?.user?.CompanyId,
        jobData?.state?.ProjectId,
        iBuildLocalData?.user?.token,
        formData
      )
    )
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            ManageSignatueModal: false,
            signature,
          });
        } else {
          toast.error("Failed to upload signature", {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, ManageSignatueModal: true });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, ManageSignatueModal: true });
      });
  };

  const clearSignature = () => {
    signCanvas.current.clear();
  };

  const handleGenerateAgreement = (type) => {
    if (type === "interior") {
      if (primary) {
        if (!isGenerateAggremet) {
          dispatch(
            getGenerateAgreement(
              interiorCreateCategoryList?.data?.[0]?.projectId,
              interiorCreateCategoryList?.data?.[0]?.id,
              primary?.legalName
            )
          );
        }

        dispatch(
          getDesignCategoryById(
            iBuildLocalData?.user?.CompanyId,
            1,
            interiorCreateCategoryList?.data?.[0]?.id,
            iBuildLocalData?.user?.token
          )
        )
          .then((data) => {
            if (data.status === 200) {
              const agreementSignatureFilterData =
                data?.data?.data?.agreementSignatures?.filter(
                  (item) => item.designation === "Design Manager"
                );
              const dummyAgreementSignature =
                agreementSignatureFilterData?.at(-1);
              setAgreementSignatures(dummyAgreementSignature);
              const agreementClientFilterData =
                data?.data?.data?.agreementSignatures?.filter(
                  (item) => item.designation === "Client"
                );
              const dummyAgreementClient = agreementClientFilterData?.at(-1);
              setAgreementClient(dummyAgreementClient);

              let totalItem = 0;
              let totalPrice = 0;
              let totalDiscount = 0;
              let totalAllowance =
                data?.data?.data?.DesignCategoriesJunctions?.[0]?.allowance;

              const selections =
                data?.data?.data?.DesignCategoriesJunctions?.[0]
                  ?.DesingSelectionOptions || [];

              totalItem += selections.length;

              selections?.forEach((selection) => {
                totalPrice +=
                  selection?.designOptionPriceBookSelections?.reduce(
                    (sum, priceBookSelection) =>
                      sum + (priceBookSelection?.clientTotal || 0),
                    0
                  );
                totalDiscount +=
                  selection?.designOptionPriceBookSelections?.reduce(
                    (sum, priceBookSelection) =>
                      sum + (priceBookSelection?.discount || 0),
                    0
                  );
              });
              totalPrice = isNaN(totalPrice) ? 0 : totalPrice;
              totalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
              totalAllowance = isNaN(totalAllowance) ? 0 : totalAllowance;
              setTotalPrice(totalPrice);
              setDiscount(totalDiscount);
              const subtotalPrice = parseFloat(
                (totalPrice - totalDiscount).toFixed(2)
              );
              const changeOrder = parseFloat(
                (totalAllowance - subtotalPrice).toFixed(2)
              );
              const tax = parseFloat((subtotalPrice * 0.05).toFixed(2));
              const grandTotal = parseFloat((subtotalPrice + tax).toFixed(2));

              setInteriorAgreementSummary({
                totalItem,
                price: isNaN(totalPrice)
                  ? 0
                  : parseFloat(totalPrice.toFixed(2)),
                discount: isNaN(totalDiscount)
                  ? 0
                  : parseFloat(totalDiscount.toFixed(2)),
                allowance: isNaN(totalAllowance)
                  ? 0
                  : parseFloat(totalAllowance.toFixed(2)),
                subtotalPrice: isNaN(subtotalPrice) ? 0 : subtotalPrice,
                changeOrder: isNaN(changeOrder) ? 0 : changeOrder,
                tax: isNaN(tax) ? 0 : tax,
                grandTotal: isNaN(grandTotal) ? 0 : grandTotal,
              });
            }
          })
          .catch((error) => {
            console.error("Error in fetching category list:", error);
          });

        updateState({
          ...iState,
          DesignSelectionsAggrementInteriorModal: true,
        });
      } else {
        toast.error("Please Assign Primary Client for this job", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      if (primary) {
        if (!isGenerateAggremet) {
          dispatch(
            getGenerateAgreement(
              exteriorCreateCategoryList?.data?.[0]?.projectId,
              exteriorCreateCategoryList?.data?.[0]?.id,
              primary?.legalName
            )
          );
        }

        dispatch(
          getDesignCategoryById(
            iBuildLocalData?.user?.CompanyId,
            1,
            exteriorCreateCategoryList?.data?.[0]?.id,
            iBuildLocalData?.user?.token
          )
        )
          .then((data) => {
            if (data.status === 200) {
              const agreementSignatureFilterData =
                data?.data?.data?.agreementSignatures?.filter(
                  (item) => item.designation === "Design Manager"
                );
              const dummyAgreementSignature =
                agreementSignatureFilterData?.at(-1);
              setAgreementSignatures(dummyAgreementSignature);
              const agreementClientFilterData =
                data?.data?.data?.agreementSignatures?.filter(
                  (item) => item.designation === "Client"
                );
              const dummyAgreementClient = agreementClientFilterData?.at(-1);
              setAgreementClient(dummyAgreementClient);

              let totalItem = 0;
              let totalPrice = 0;
              let totalDiscount = 0;
              let totalAllowance =
                data?.data?.data?.DesignCategoriesJunctions?.[0]?.allowance;

              const selections =
                data?.data?.data?.DesignCategoriesJunctions?.[0]
                  ?.DesingSelectionOptions || [];

              totalItem += selections.length;

              selections?.forEach((selection) => {
                totalPrice +=
                  selection?.designOptionPriceBookSelections?.reduce(
                    (sum, priceBookSelection) =>
                      sum + (priceBookSelection?.clientTotal || 0),
                    0
                  );
                totalDiscount +=
                  selection?.designOptionPriceBookSelections?.reduce(
                    (sum, priceBookSelection) =>
                      sum + (priceBookSelection?.discount || 0),
                    0
                  );
              });
              totalPrice = isNaN(totalPrice) ? 0 : totalPrice;
              totalDiscount = isNaN(totalDiscount) ? 0 : totalDiscount;
              totalAllowance = isNaN(totalAllowance) ? 0 : totalAllowance;
              setTotalPrice(totalPrice);
              setDiscount(totalDiscount);
              const subtotalPrice = parseFloat(
                (totalPrice - totalDiscount).toFixed(2)
              );
              const changeOrder = parseFloat(
                (totalAllowance - subtotalPrice).toFixed(2)
              );
              const tax = parseFloat((subtotalPrice * 0.05).toFixed(2));
              const grandTotal = parseFloat((subtotalPrice + tax).toFixed(2));

              setInteriorAgreementSummary({
                totalItem,
                price: isNaN(totalPrice)
                  ? 0
                  : parseFloat(totalPrice.toFixed(2)),
                discount: isNaN(totalDiscount)
                  ? 0
                  : parseFloat(totalDiscount.toFixed(2)),
                allowance: isNaN(totalAllowance)
                  ? 0
                  : parseFloat(totalAllowance.toFixed(2)),
                subtotalPrice: isNaN(subtotalPrice) ? 0 : subtotalPrice,
                changeOrder: isNaN(changeOrder) ? 0 : changeOrder,
                tax: isNaN(tax) ? 0 : tax,
                grandTotal: isNaN(grandTotal) ? 0 : grandTotal,
              });
            }
          })
          .catch((error) => {
            console.error("Error in fetching category list:", error);
          });

        updateState({
          ...iState,
          DesignSelectionsAggrementInteriorModal: true,
        });
      } else {
        toast.error("Please Assign Primary Client for this job", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const [DeadlineModal,setDeadLineModal]=useState(false)
  const [selecetedDate,setSelecetedDate]=useState("text")
  const [deadline,setDeadline]=useState("")
  const [cateType,setCateType]=useState("")
  const [cateData,setCateData]=useState([])
    const handleDeadlineModalShow=(data,type)=>{
      setDeadLineModal(true)
      setDeadline("")
    
      setCateData(data)
    }
    const handleDeadlineModalClose=()=>{
      setDeadLineModal(false)
      setDeadline("")
    
    }

    const handleDateOptionChange = (e) => {
      setDeadline("")
      setSelecetedDate(e.target.value);
    };

    const handleUpdateDeadline = async () => {
      let adjustedDeadline = deadline;
    
      // If the selected date type is "file", adjust the deadline based on the days and type
      if (selecetedDate === "file") {
        adjustedDeadline = calculateAdjustedDeadline(deadline, days, beforeAfterDate);
      }
    
      // Prepare the data object to be sent in the API call
      const datta = { deadline: adjustedDeadline };
    
      try {
        const res = await dispatch(updateCategory(
          jobData?.state?.ProjectId,
          cateData?.id,
          cateData?.designSelectionCategoryId,
          datta
        ));
    
        // Check the response status and handle success or error
        if (res?.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
    
          // Close the modal and reset states
          setDeadLineModal(false);
          setDeadline("");
          setCateType("");
          setDays("")
          // Fetch the updated category list based on cateType
          if (cateType === "interior") {
            dispatch(getInteriorCreateCategoryList(
              jobData?.state?.ProjectId,
              jobData?.state?.jobType,
              jobData?.state?.id
            ));
          } else {
            dispatch(getExteriorCreateCategoryList(
              jobData?.state?.ProjectId,
              jobData?.state?.jobType,
              jobData?.state?.id
            ));
          }
        } else {
          // Handle the error response
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setDeadLineModal(true);
        }
      } catch (error) {
        // Handle any errors that occur during the API call
        console.error("Error sending file:", error);
      }
    };
    

    const [beforeAfterDate,setBeforeAfterDate]=useState("before")
    const [days,setDays]=useState(0)
    const handleBeforeAfterChange = (e) => {
      setBeforeAfterDate(e.target.value);
    };
    const [primayClient, setPrimaryClient] = useState([]);
    const [secondaryClient, setSecondaryClient] = useState([]);
    const [builderData, setBuilder] = useState([]);
  
    useEffect(() => {
      if (SendForClientModal) {
        let contId = JSON.parse(window.localStorage.getItem("contId"));
        dispatch(getBuilderSigneeList()).then((response) => {
          if (response.status === 200) {
            const buillldata = response?.data?.staff;
            setBuilder(buillldata);
          }
        });
        dispatch(getAllClientAction(contId)).then((res) => {
          if (res) {
            const primeData = res?.clientDetails?.filter(
              (data) => data.isPrimary === true
            );
            const secondData = res?.clientDetails?.filter(
              (data) => data.isPrimary === false
            );
            setPrimaryClient(primeData);
            setSecondaryClient(secondData);
          }
        });
      }
    }, [SendForClientModal]);
  
    const handleSendForClientModalsClose = () => {
      updateState({
        ...iState,
        SendForClientModal: false,
        DesignSelectionsModal: true,
      });
    };
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      if (name === "numberOfSignee") {
        updateState({
          ...iState,
          [name]: value,
          secondarySignee: "",
          additionalSignee: "",
          secondarySignStatus: "",
          additionalSignStatus: "",
        });
      } else if (name === "primarySignee") {
        const dummmyPrimaryData = primayClient?.find((item) => item.id == value);
        updateState({
          ...iState,
          [name]: value,
          primarySignStatus: dummmyPrimaryData?.legalName,
        });
      } else if (name === "secondarySignee") {
        const dummmySecondaryData = secondaryClient?.find(
          (item) => item.id == value
        );
        updateState({
          ...iState,
          [name]: value,
          secondarySignStatus: dummmySecondaryData?.legalName,
        });
      } else if (name === "additionalSignee") {
        const dummmyAdditionalData = secondaryClient?.find(
          (item) => item.id == value
        );
        updateState({
          ...iState,
          [name]: value,
          additionalSignStatus: dummmyAdditionalData?.legalName,
        });
      } else if (name === "builderSignee") {
        const dummmyBuilderData = builderData?.find((item) => item.id == value);
        updateState({
          ...iState,
          [name]: value,
          builderSignStatus: dummmyBuilderData?.name,
        });
      } 
      // else if (name === "slectedClient") {
      //   const dummmyallClientsData = allClients?.find((item) => item.id == value);
      //   updateState({
      //     ...iState,
      //     [name]: value,
      //     name: dummmyallClientsData?.legalName,
      //     email: dummmyallClientsData?.email,
      //   });
      // } 
     else  if (name === "folderId") {
        dispatch(getListDesignDocument(value)).then((res) => {
          if (res.status === 200) {
            updateState({
              ...iState,
              folderData: res?.data?.clientDocDetails,
              [name]: value,
  
            });
          }
        });
      }
      else {
        updateState({ ...iState, [name]: value });
      }
    };

    const handleManageSignatuesShow = (type) => {
      if (type == "primarySignature") {
        updateState({
          ...iState,
          ManageSignatures: true,
          primarySignature: "",
          signTypes: type,
        });
      }
      if (type == "secondarySignature") {
        updateState({
          ...iState,
          ManageSignatures: true,
          secondarySignature: "",
          signTypes: type,
        });
      }
      if (type == "builderSignature") {
        updateState({
          ...iState,
          ManageSignatures: true,
          builderSignature: "",
          signTypes: type,
        });
      }
      if (type == "additionalSignature") {
        updateState({
          ...iState,
          ManageSignatures: true,
          additionalSignature: "",
          signTypes: type,
        });
      }
    };
    const handleManageSignatuesClose = () => {
      updateState({
        ...iState,
        ManageSignatures: false,
        signature: "",
        signTypes: "",
      });
    };
  
    const addSignature = () => {
      const signatureBlob = dataURLtoBlob(signature);
      const convertToValidFormat = (blob, fileName) => {
        const fileExtension = fileName.split(".").pop().toLowerCase();
        if (
          ![
            "jpg",
            "jpeg",
            "png",
            "gif",
            "pdf",
            "bmp",
            "webp",
            "svg+xml",
          ].includes(fileExtension)
        ) {
          const convertedBlob = convertToPNG(blob);
          return convertedBlob;
        }
        return blob;
      };
      const validSignatureBlob = convertToValidFormat(
        signatureBlob,
        "signature.png"
      );
      const validSignatureBlobURL = URL.createObjectURL(validSignatureBlob);
  
      if (signTypes === "primarySignature") {
        updateState({
          ...iState,
          primarySignature: validSignatureBlobURL,
          primarySignatureBlob: validSignatureBlob,
          ManageSignatures: false,
        });
      } else if (signTypes === "secondarySignature") {
        updateState({
          ...iState,
          secondarySignature: validSignatureBlobURL,
          secondarySignatureBlob: validSignatureBlob,
          ManageSignatures: false,
        });
      } else if (signTypes === "builderSignature") {
        updateState({
          ...iState,
          builderSignature: validSignatureBlobURL,
          builderSignatureBlob: validSignatureBlob,
          ManageSignatures: false,
        });
      } else if (signTypes === "additionalSignature") {
        updateState({
          ...iState,
          additionalSignature: validSignatureBlobURL,
          additionalSignatureBlob: validSignatureBlob,
          ManageSignatures: false,
        });
      }
    };
  
    const clearSignatureFunc = () => {
      signCanvas.current.clear();
    };
  
    const handleSendToClient = async (type) => {
      let contId = JSON.parse(window.localStorage.getItem("contId"));
      const htmlContent =
        iBuildLocalData?.companies?.[0]?.letterHeader + docsUrlData;
      const blob = new Blob([htmlContent], { type: "text/html" });
      const file = new File([blob], "content.html");
  
      const initialFormData = new FormData();
      initialFormData.append("files", file);
  
      try {
        const uploadResponse = await dispatch(
          uploadAggrementFiles(initialFormData)
        );
        if (uploadResponse.status === 200) {
          const formData = new FormData();
          formData.append("htmlBlobName", uploadResponse?.data?.data);
          formData.append("primarySignee", Number(primarySignee));
          formData.append("primarySign", primarySignatureBlob);
          formData.append("secondarySign", secondarySignatureBlob);
          formData.append("additionalSign", additionalSignatureBlob);
          formData.append("builderSign", builderSignatureBlob);
          formData.append("primarySignStatus", !!primarySignStatus);
          formData.append("secondarySignee", Number(secondarySignee));
          formData.append("secondarySignStatus", !!secondarySignStatus);
          formData.append("builderSignee", Number(builderSignee));
          formData.append("builderSignStatus", !!builderSignStatus);
          formData.append("additionalSignee", Number(additionalSignee));
          formData.append("additionalSignStatus", !!additionalSignStatus);
          formData.append("numberOfSignee", Number(numberOfSignee));
          formData.append("status", type === "save" ? "DRAFT" : "SEND");
  
          const signatureResponse = await dispatch(
            (
              folderId,
              docsId,
              contId,
              formData
            )
          );
  
          if (signatureResponse.status === 200) {
            toast.success(signatureResponse.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            setSendforClientSignatureModal(false);
            updateState((prev) => ({
              ...prev,
              primarySignature: "",
              secondarySignature: "",
              additionalSignature: "",
              builderSignature: "",
              signTypes: "",
              primarySignee: "",
              primarySignStatus: "",
              secondarySignee: "",
              secondarySignStatus: "",
              builderSignee: "",
              builderSignStatus: "",
              additionalSignee: "",
              additionalSignStatus: "",
            }));
          } else {
            throw new Error(signatureResponse.data.message);
          }
        }
      } catch (error) {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setSendforClientSignatureModal(true);
      }
    };

    const handleEditorChange = (newContent) => {
      updateState({ ...iState, content: newContent });
    };
    const editorsRef = useRef(null);
    const handleDraftChange = (e) => {
      updateState({ ...iState, draft: e.target.checked });
    };
    console.log({contentData});
    const handleEditDocumentation = async () => {
      updateState({ ...iState, loading: true });
      const blob = new Blob([content], { type: "text/html" });
      const file = new File([blob], "content.html");
  
      const initialFormData = new FormData();
      initialFormData.append("files", file);
      try {
        const uploadResponse = await dispatch(
          uploadAggrementFiles(initialFormData)
        );
      
  
        if (uploadResponse.status === 200) {
          const formData = new FormData();
          const docxBlob = htmlDocx.asBlob(content);
          const filesName = `${contentData?.name}`;
          const docxFile = new File([docxBlob], filesName, {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          // saveAs(docxBlob, filesName);
          formData.append("files", docxFile);
          formData.append("htmlUrl", uploadResponse?.data?.data);
          formData.append("draft", draft);
          formData.append("name", contentData?.name);
  
          const signatureResponse = await dispatch(
            updateDesignDocss(
              contentData?.folderId,
              contentData?.id,
              formData
            )
          );
  
          if (signatureResponse.status === 200) {
            dispatch(getListDesignDocument(folderId));
            toast.success("Docs Updated Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            updateState({
              ...iState,
              EditDocumentationModal: false,
              loading: false,
              content: "",
              DesignSelectionsModal:true
            });
        
          } else {
            throw new Error(signatureResponse.data.message);
          }
        } else {
          throw new Error(uploadResponse.data.message);
        }
      } catch (error) {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          loading: false,
          EditDocumentationModal: true,
        });
      }
    };

    const [selectedEditor, setSelectedEditor] = useState("BaseTemplate");
    const handleSelectedEditorChange = (e) => {
      const newSelectedEditor = e.target.value;
      if (newSelectedEditor === "CreateNewFile") {
        updateState({ ...iState, clientFile: "" });
      } else if (newSelectedEditor === "BaseTemplate") {
        updateState({ ...iState, htmlUrl: "" });
      }
      setSelectedEditor(newSelectedEditor);
    };
  
    const editorSelectedRef = useRef(null);
    const handleSelectedEditor = (data) => {
      updateState((prevState) => ({
        ...prevState,
        htmlUrl: data,
        fileFolderName,
      }));
    };

    const handleFileChanges = (event) => {
      const file = event?.target?.files[0];
      if(file)
        updateState({ ...iState, clientFile: file ,fileFolderName:file.name });
    };

    const [JobMenuModal, setJobMenuModal] = useState(false);
    const handleJobMenuModalClose = () => setJobMenuModal(false);
    const handleJobMenuModalShow = () => setJobMenuModal(true);

    const handleNavigateModalShow = () => {
      updateState({ ...iState, NavigateModal: true });
    };
    const handleNavigateModalClose = () => {
      updateState({ ...iState, NavigateModal: false });
    };
  return (
    <div>
      <Header />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="MainTitleBox">
            <div className="MainTitle">
              <h2>Design Selections</h2>
            </div>
            <div className="TodosButtons">
              <a onClick={() => handleDesignSelectionsModalShow()}>
                <h6>
                  <img src="images/design_documents.png" />
                  Design File Manager
                </h6>{" "}
                <span>3</span>
              </a>
            </div>
          </div>
          <div className="TitleBox">
            <div className="TodosButtons">
              <ul className="nav nav-tabs">
                <li
                  className="nav-item"
                  onClick={() => setOpenAccordionIndex(null)}
                >
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#InteriorSelectionsTab"
                  >
                    <img src="images/warranty-request.png" />
                    Interior Selections<span>3</span>
                  </a>
                </li>
                <li
                  className="nav-item"
                  onClick={() => setOpenAccordionIndex(null)}
                >
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#ExteriorSelectionsTab"
                  >
                    <img src="images/work-order-red.png" />
                    Exterior Selections<span>3</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#AppointmentsTab"
                  >
                    <img src="images/work-order-red.png" />
                    Appointments<span>3</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="BuilderBox m-0">
              <h4 className="fw-500">
                {jobData?.state?.jobNumber ?? "N/A"}
                {/* <img src="images/refresh.png" /> */}
              </h4>
            </div>
            <div className="TitleBoxNavigate">
              
              <div className="NavigateButton gap-2">
                <button onClick={handleJobMenuModalShow}>Job Menu</button>
                <button className="ml-2" onClick={handleNavigateModalShow}>
                  Navigate
                </button>
              </div>
            </div>
          </div>
          <div className="tab-content DesignSelectionsContent">
            <div className="tab-pane show active" id="InteriorSelectionsTab">
              <div className="InteriorSelections">
                <div className="TodosButtons mt-0 mb-3">
                  <a
                    href="javascript:void(0);"
                    className="py-3 m-0 NewPlusBtn"
                    style={{ display: "none" }}
                  >
                    <h6>New &nbsp; + </h6>
                  </a>
                </div>
                <div
                  className="DesignSelectionHide"
                  style={{ display: "block" }}
                >
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <h3
                          className="CursorPointer"
                          onClick={() =>
                            handleAddCategoryShowHide("show", "interior")
                          }
                        >
                          Categories &nbsp;&nbsp;
                          <span>
                            <img src="images/Plus.png" />
                          </span>
                        </h3>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <h6>Last Update</h6>
                        <p>
                          {interiorCreateCategoryList?.data?.[0]
                            ?.DesignCategoriesJunctions?.length > 0 &&
                            interiorCreateCategoryList?.data?.[0]?.updatedAt?.slice(
                              0,
                              10
                            )}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <h6>Selection Status</h6>
                        <p>{interiorCreateCategoryList?.data?.[0]?.selectionStatus}</p>
                      </div>
                    </div>
                  </div>

                  <div className="NewAccording mb-4">
                    {interiorCreateCategoryList?.data?.[0]
                      ?.DesignCategoriesJunctions?.length > 0 &&
                      interiorCreateCategoryList?.data?.[0]?.DesignCategoriesJunctions?.map(
                        (data, ind) => {
                          return (
                            <div className="card">
                              <div
                                className={
                                  data.select === true
                                    ? "card-header ChangeColorHeader  HeaderGreen mt-2 mb-2"
                                    : "card-header mt-2 mb-2"
                                }
                              >
                                <h4
                                  data-toggle="collapse"
                                  aria-expanded={
                                    openAccordionIndex === ind
                                      ? "true"
                                      : "false"
                                  }
                                  className=""
                                  onClick={() =>
                                    handleAccordionToggle(ind, data.id)
                                  }
                                >
                                  <span>
                                    <img src="images/down_arrow_white.png" />
                                  </span>

                                  {data?.Category?.name}
                                </h4>
                                <a
                                  href="javascript:void(0);"
                                  className="Edit dropdown"
                                >
                                  <div
                                    className=" mt-1"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/white_dots.png" />
                                  </div>
                                  <div
                                    className="dropdown-menu DesignSelectionDropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                    style={{}}
                                  >
                                    <div
                                      className="dropdown-item"
                                      onClick={() =>
                                        handleUpdateCategoryShowHide(
                                          "show",
                                          data,
                                          "interior"
                                        )
                                      }
                                    >
                                      <img src="images/Action-1.png" />
                                      Edit Category Selection&nbsp;&nbsp;
                                    </div>
                                    <div className="dropdown-item AddOptionDropdown mt-5">
                                      <img src="images/add_option.png" />
                                      Add Option
                                    </div>
                                    <div
                                      className="dropdown-item"
                                      onClick={() =>
                                        handleDocumentationDeleteShow(data.id)
                                      }
                                    >
                                      <img src="images/dropdown-delete.png" />
                                      Remove Category
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <div
                                id="Estimation"
                                className={`collapse ${
                                  openAccordionIndex === ind ? "show" : ""
                                }`}
                                style={{}}
                              >
                                <div className="DesignSelectionHeader">
                                  <div className="form-group">
                                    <h6>
                                      Default Cost Code{" "}
                                      <a href="javascript:void(0);">
                                        <img src="images/Action-1.png" />
                                      </a>{" "}
                                    </h6>
                                    <p>{data.category ?? "N/A"}</p>
                                  </div>
                                  <div className="form-group">
                                    <h6>
                                      Deadline{" "}
                                      <a
                                        onClick={()=>handleDeadlineModalShow(data,"interior")}
                                      >
                                        <img src="images/Action-1.png" />
                                      </a>{" "}
                                    </h6>
                                    <p>
                                      {
                                        data.deadline&&
                                      moment(data.deadline).format("YYYY-MM-DD")}
                                      {" "}<br></br>
                                      <span>
                                        <img src="images/set_time.png" />
                                      </span>{" "}
                                      Set Deadline
                                    </p>
                                  </div>
                                  <div className="form-group">
                                    <h6>Allowance </h6>
                                    <p>${data.allowance ?? "N/A"}</p>
                                  </div>
                                  <div className="form-group">
                                    <h6>Type </h6>
                                    <p>{data.type}</p>
                                  </div>
                                  <div className="form-group">
                                    <h6>Status</h6>
                                    <p>New</p>
                                  </div>
                                </div>
                                <div className="InteriorSelectionsArea">
                                  <div className="TodosButtons mt-0 AddOptionBtn">
                                    <a
                                      onClick={() => {
                                        handleAddOptionShow(
                                          data.id,
                                          "interior"
                                        );
                                        setshowAddSelectionOption(
                                          data?.Category?.name
                                        );
                                      }}
                                    >
                                      <h6>
                                        <img src="images/interior_selections.png" />
                                        Add Option{" "}
                                      </h6>
                                    </a>
                                  </div>
                                  <div className="InteriorSelectionsTable mt-4 TableHeight500">
                                    <table>
                                      <tbody>
                                        {getOptionByCategoryList
                                          ?.categoryDetails?.length > 0 &&
                                          getOptionByCategoryList?.categoryDetails?.map(
                                            (datas, ind) => {
                                              return (
                                                <tr
                                                  className={
                                                    datas.selected
                                                      ? "ChangeColor"
                                                      : "RowGreen"
                                                  }
                                                  key={ind}
                                                >
                                                  <td>
                                                    <div className="Action">
                                                      <img
                                                        src={handleShowImg(
                                                          datas
                                                        )}
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Name</h6>
                                                      <p>{datas.name}</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Description</h6>
                                                      <p>{datas.description}</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Product Link</h6>
                                                      <p>{datas.productLink}</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Vendor</h6>
                                                      <p>{datas.Vendor?.vendorName??"N/A"}</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Cost Code</h6>
                                                      <p>
                                                        {datas
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.costcode ?? ""}
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Measure</h6>
                                                      <p>
                                                        {datas
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.measure ?? ""}
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Per Unit</h6>
                                                      <p>
                                                        $
                                                        {datas
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.perUnit ?? ""}
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Quantity</h6>
                                                      <p>
                                                        {datas
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.quantity ?? ""}
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Builder Total</h6>
                                                      <p>
                                                        $
                                                        {datas
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.builderTotal ?? ""}
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Markup</h6>
                                                      <p>
                                                        {datas
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.markup ?? ""}
                                                        %
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Discount</h6>
                                                      <p>
                                                        {
                                                          datas
                                                            ?.designOptionPriceBookSelections?.[0]
                                                            ?.discount
                                                        }
                                                        %
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Client Total</h6>
                                                      <p>
                                                        $
                                                        {datas
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.clientTotal ?? ""}
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Attachments</h6>
                                                      <div className="Actions">
                                                        <a>
                                                          <img src="images/attachment.png" />
                                                        </a>
                                                        <a>
                                                          <span className="Count">
                                                            {datas
                                                              ?.designOptionAttachments
                                                              ?.length ?? 0}
                                                          </span>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Action</h6>
                                                      <div className="TableThreeDots">
                                                        <ul className="">
                                                          <li className="dropdown">
                                                            <a
                                                              className=""
                                                              role="button"
                                                              data-toggle="dropdown"
                                                              aria-haspopup="true"
                                                              aria-expanded="false"
                                                            >
                                                              <img src="images/dots.png" />
                                                            </a>
                                                            <ol className="dropdown-menu dropdown-menuwidth">
                                                              <li
                                                                onClick={() => {
                                                                  handleEditOptionShow(
                                                                    datas.id,
                                                                    datas.designCategoryId
                                                                  );
                                                                  setEditId(
                                                                    data.id
                                                                  );
                                                                }}
                                                              >
                                                                <a
                                                                  href="javascript:void(0);"
                                                                  data-toggle="modal"
                                                                  data-target="#OptionDetails"
                                                                >
                                                                  <img src="images/Action-1.png" />
                                                                  Edit option
                                                                </a>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  onClick={() =>
                                                                    handleOptionDelete(
                                                                      datas.id,
                                                                      datas.designCategoryId
                                                                    )
                                                                  }
                                                                >
                                                                  <img src="images/dropdown-delete.png" />
                                                                  Remove Option
                                                                </a>
                                                              </li>
                                                              <li>
                                                              {
                                                                  (datas?.type==="Single" &&data.selected===false)?
                                                                <a
                                                                onClick={() =>
                                                                  handleOptionSelected(
                                                                    datas.id,
                                                                    datas.designCategoryId
                                                                  )
                                                                }
                                                                >
                                                                  <img src="images/make_selection.png" />
                                                                  Make Selection
                                                                </a>
                                                                : datas?.type==="Multiple"? 
                                                                <a
                                                                onClick={() =>
                                                                  handleOptionSelected(
                                                                    datas.id,
                                                                    datas.designCategoryId
                                                                  )
                                                                }
                                                                >
                                                                  <img src="images/make_selection.png" />
                                                                  Make Selection
                                                                </a>
                                                                :<></>
                                                                }
                                                              </li>
                                                            </ol>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>

                  <div
                    className="Button text-center "
                    style={{ margin: "100px 0", display: "flex", gap: "20px" }}
                  >
                    <button
                      onClick={() => handleGenerateAgreement("interior")}
                      className="Create mr-4 text-center mb-2"
                    >
                      <img src="images/create_new_appointment.png" />
                      {!isGenerateAggremet
                        ? "Generate Agreement"
                        : "View-Agreement"}
                    </button>
                    <button
                      className="Create text-center mb-2 SelectionHistoryBtn"
                      onClick={() =>
                        handleSelectionHistoryModalShow(
                          interiorCreateCategoryList?.data?.[0]
                        )
                      }
                    >
                      Selection History
                    </button>
                    <button
                      className="Create mb-2 text-center "
                      onClick={() =>
                        handleInteriorExteriorDeleteSelection(
                          "interior",
                          interiorCreateCategoryList?.data?.[0]?.projectId,
                          interiorCreateCategoryList?.data?.[0]?.id
                        )
                      }
                    >
                      Delete Selection
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="ExteriorSelectionsTab">
              <div className="InteriorSelections">
                <div className="TodosButtons mt-0 mb-3">
                  <a
                    href="javascript:void(0);"
                    className="py-3 m-0 NewPlusBtn"
                    style={{ display: "none" }}
                  >
                    <h6>New &nbsp; + </h6>
                  </a>
                </div>
                <div
                  className="DesignSelectionHide"
                  style={{ display: "block" }}
                >
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <h3
                          onClick={() =>
                            handleAddCategoryShowHide("show", "exterior")
                          }
                        >
                          Categories &nbsp;&nbsp;
                          <span>
                            <img src="images/Plus.png" />
                          </span>
                        </h3>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <h6>Last Update</h6>
                        <p>
                          {exteriorCreateCategoryList?.data?.[0]
                            ?.DesignCategoriesJunctions?.length > 0 &&
                            exteriorCreateCategoryList?.data?.[0]?.updatedAt?.slice(
                              0,
                              10
                            )}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <h6>Selection Status</h6>
                        <p>{exteriorCreateCategoryList?.data?.[0]?.selectionStatus}</p>
                      </div>
                    </div>
                  </div>

                  <div className="NewAccording mb-4">
                    {exteriorCreateCategoryList?.data?.[0]
                      ?.DesignCategoriesJunctions?.length > 0 &&
                      exteriorCreateCategoryList?.data?.[0]?.DesignCategoriesJunctions?.map(
                        (data, ind) => {
                          return (
                            <div className="card">
                              <div
                                className={
                                  data.select === true
                                    ? "card-header ChangeColorHeader  HeaderGreen mt-2 mb-2"
                                    : "card-header mt-2 mb-2"
                                }
                              >
                                <h4
                                  data-toggle="collapse"
                                  aria-expanded={
                                    openAccordionIndex === ind
                                      ? "true"
                                      : "false"
                                  }
                                  className=""
                                  onClick={() =>
                                    handleAccordionToggle(ind, data.id)
                                  }
                                >
                                  <span>
                                    <img src="images/down_arrow_white.png" />
                                  </span>{" "}
                                  {data?.Category?.name}
                                </h4>
                                <a
                                  href="javascript:void(0);"
                                  className="Edit dropdown"
                                >
                                  <div
                                    className=" mt-1"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/white_dots.png" />
                                  </div>
                                  <div
                                    className="dropdown-menu DesignSelectionDropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                    style={{}}
                                  >
                                    <div
                                      className="dropdown-item"
                                      onClick={() =>
                                        handleUpdateCategoryShowHide(
                                          "show",
                                          data,
                                          "exterior"
                                        )
                                      }
                                    >
                                      <img src="images/Action-1.png" />
                                      Edit Category Selection&nbsp;&nbsp;
                                    </div>
                                    <div className="dropdown-item AddOptionDropdown">
                                      <img src="images/add_option.png" />
                                      Add Option
                                    </div>
                                    <div
                                      className="dropdown-item"
                                      onClick={() =>
                                        handleDocumentationDeleteShow(data.id)
                                      }
                                    >
                                      <img src="images/dropdown-delete.png" />
                                      Remove Category
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <div
                                id="Estimation"
                                className={`collapse ${
                                  openAccordionIndex === ind ? "show" : ""
                                }`}
                                style={{}}
                              >
                                <div className="DesignSelectionHeader">
                                  <div className="form-group">
                                    <h6>
                                      Default Cost Code{" "}
                                      <a href="javascript:void(0);">
                                        <img src="images/Action-1.png" />
                                      </a>{" "}
                                    </h6>
                                    <p>{data.category ?? "N/A"}</p>
                                  </div>
                                  <div className="form-group">
                                    <h6>
                                      Deadline{" "}
                                      <a
                                        onClick={()=>handleDeadlineModalShow(data,"interior")}
                                      >
                                        <img src="images/Action-1.png" />
                                      </a>{" "}
                                    </h6>
                                    <p>
                                      {
                                        data.deadline&&
                                      moment(data.deadline).format("YYYY-MM-DD")}
                                      {" "}<br></br>
                                      <span>
                                        <img src="images/set_time.png" />
                                      </span>{" "}
                                      Set Deadline
                                    </p>
                                  </div>
                                  <div className="form-group">
                                    <h6>Allowance </h6>
                                    <p>${data.allowance ?? "N/A"}</p>
                                  </div>
                                  <div className="form-group">
                                    <h6>Type </h6>
                                    <p>{data.type}</p>
                                  </div>
                                  <div className="form-group">
                                    <h6>Status</h6>
                                    <p>New</p>
                                  </div>
                                </div>
                                <div className="InteriorSelectionsArea">
                                  <div className="TodosButtons mt-0 AddOptionBtn">
                                    <a
                                      onClick={() =>
                                        handleAddOptionShow(data.id, "exterior")
                                      }
                                    >
                                      <h6>
                                        <img src="images/interior_selections.png" />
                                        Add Option{" "}
                                      </h6>
                                    </a>
                                  </div>
                                  <div className="InteriorSelectionsTable mt-4 TableHeight500">
                                    <table>
                                      <tbody>
                                        {getOptionByCategoryList
                                          ?.categoryDetails?.length > 0 &&
                                          getOptionByCategoryList?.categoryDetails?.map(
                                            (datas, ind) => {
                                              return (
                                                <tr
                                                  className={
                                                    datas.selected
                                                      ? "ChangeColor"
                                                      : "RowGreen"
                                                  }
                                                  key={ind}
                                                >
                                                  <td>
                                                    <div className="Action">
                                                      <img
                                                        src={handleShowImg(
                                                          datas
                                                        )}
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Name</h6>
                                                      <p>{datas.name}</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Description</h6>
                                                      <p>{datas.description}</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Product Link</h6>
                                                      <p>{datas.productLink}</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Vendor</h6>
                                                      <p>{datas.Vendor?.vendorName??"N/A"}</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Cost Code</h6>
                                                      <p>
                                                        {datas
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.costcode ?? ""}
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Measure</h6>
                                                      <p>
                                                        {datas
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.measure ?? ""}
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Per Unit</h6>
                                                      <p>
                                                        $
                                                        {datas
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.perUnit ?? ""}
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Quantity</h6>
                                                      <p>
                                                        {datas
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.quantity ?? "0"}
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Builder Total</h6>
                                                      <p>
                                                        $
                                                        {datas
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.builderTotal ?? ""}
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Markup</h6>
                                                      <p>
                                                        {datas
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.markup ?? ""}
                                                        %
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Discount</h6>
                                                      <p>
                                                        {
                                                          datas
                                                            ?.designOptionPriceBookSelections?.[0]
                                                            ?.discount
                                                        }
                                                        %
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Client Total</h6>
                                                      <p>
                                                        $
                                                        {datas
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.clientTotal ?? ""}
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Attachments</h6>
                                                      <div className="Actions">
                                                        <a>
                                                          <img src="images/attachment.png" />
                                                        </a>
                                                        <a>
                                                          <span className="Count">
                                                            {datas
                                                              ?.designOptionAttachments
                                                              ?.length ?? 0}
                                                          </span>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Action</h6>
                                                      <div className="TableThreeDots">
                                                        <ul className="">
                                                          <li className="dropdown">
                                                            <a
                                                              className=""
                                                              role="button"
                                                              data-toggle="dropdown"
                                                              aria-haspopup="true"
                                                              aria-expanded="false"
                                                            >
                                                              <img src="images/dots.png" />
                                                            </a>
                                                            <ol className="dropdown-menu dropdown-menuwidth">
                                                              <li
                                                                onClick={() => {
                                                                  handleEditOptionShow(
                                                                    datas.id,
                                                                    datas.designCategoryId
                                                                  );
                                                                  setEditId(
                                                                    data.id
                                                                  );
                                                                }}
                                                              >
                                                                <a
                                                                  href="javascript:void(0);"
                                                                  data-toggle="modal"
                                                                  data-target="#OptionDetails"
                                                                >
                                                                  <img src="images/Action-1.png" />
                                                                  Edit option
                                                                </a>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  onClick={() =>
                                                                    handleOptionDelete(
                                                                      datas.id,
                                                                      datas.designCategoryId
                                                                    )
                                                                  }
                                                                >
                                                                  <img src="images/dropdown-delete.png" />
                                                                  Remove Option
                                                                </a>
                                                              </li>
                                                              <li>
                                                                {
                                                                  (datas?.type==="Single" &&data.selected===false)?
                                                                <a
                                                                onClick={() =>
                                                                  handleOptionSelected(
                                                                    datas.id,
                                                                    datas.designCategoryId
                                                                  )
                                                                }
                                                                >
                                                                  <img src="images/make_selection.png" />
                                                                  Make Selection
                                                                </a>
                                                                : datas?.type==="Multiple"? 
                                                                <a
                                                                onClick={() =>
                                                                  handleOptionSelected(
                                                                    datas.id,
                                                                    datas.designCategoryId
                                                                  )
                                                                }
                                                                >
                                                                  <img src="images/make_selection.png" />
                                                                  Make Selection
                                                                </a>
                                                                :<></>
                                                                }
                                                              </li>
                                                            </ol>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>

                  <div
                    className="Button text-center"
                    style={{ margin: "100px 0", display: "flex", gap: "20px" }}
                  >
                    <button
                      onClick={() =>
                        updateState((prev) => ({
                          ...prev,
                          DesignSelectionsAggrementExteriorModal: true,
                        }))
                      }
                      className="Create mr-4 mb-2 text-center"
                    >
                      <img src="images/create_new_appointment.png" />
                      Generate Agreement
                    </button>
                    {/* <button className="Create mr-5 mb-2">
                      Send for Client Approval
                    </button> */}
                    <button
                      className="Create mb-2 text-center SelectionHistoryBtn"
                      onClick={() =>
                        handleSelectionHistoryModalShow(
                          exteriorCreateCategoryList?.data?.[0]
                        )
                      }
                    >
                      Selection History
                    </button>
                    <button
                      className="Create mb-2 text-center "
                      onClick={() =>
                        handleInteriorExteriorDeleteSelection(
                          "exterior",
                          exteriorCreateCategoryList?.data?.[0]?.projectId,
                          exteriorCreateCategoryList?.data?.[0]?.id
                        )
                      }
                    >
                      Delete Selection
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="AppointmentsTab">
              <div className="InteriorSelections">
                <div className="TitleBox border-0">
                  <div className="TodosButtons mt-0 d-flex">
                    <a
                      onClick={() =>
                        handlePendingDateSelectionListModalHideShow("show")
                      }
                      className="Create mr-4"
                    >
                      <img src="images/pending_date.png" />
                      Pending Date Selections<span>3</span>
                    </a>
                    <a
                      onClick={() =>
                        handleCreateAppointmentModalHideShow("show")
                      }
                      className="Create mr-4"
                    >
                      <img src="images/create_new_appointment.png" />
                      Create new Appointment<span>3</span>
                    </a>
                  </div>
                  {/* <p className="MountTime">
                    <img src="images/earth.png" />
                    Mountain time - US &amp; Canada (08:08 am){" "}
                    <img src="images/black-down-arrow.png" />
                  </p> */}
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <div className="NextAppointmentsArea">
                      <h1>Next Appointments</h1>
                      {selectedDatesOfCalender?.length > 0 &&
                        approvedAppointmentList?.data?.length > 0 &&
                        approvedAppointmentList?.data?.map((item, i) => {
                          return (
                            <div className="NextAppointBox LightGreenBg">
                              <div className="NextAppointBoxLeft">
                                <h6>{item.title}</h6>
                                <p>
                                  {item?.designAppointmentDates?.length > 0 &&
                                    item?.designAppointmentDates?.map(
                                      (dateItem) => {
                                        if (dateItem?.date) {
                                          const [year, month, day] =
                                            dateItem.date.split("-");
                                          const parsedDate = new Date(
                                            year,
                                            month - 1,
                                            day
                                          ); // Months are 0-based in JavaScript Date constructor
                                          const fromTime = dateItem.fromTime;
                                          const toTime = dateItem.toTime;
                                          const formattedDate =
                                            parsedDate.toLocaleDateString(
                                              "en-US",
                                              {
                                                weekday: "long",
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                              }
                                            );
                                          return (
                                            <span key={dateItem.id}>
                                              {`${fromTime} - ${toTime}. ${formattedDate}`}
                                            </span>
                                          );
                                        } else {
                                          console.error(
                                            "dateItem or dateItem.date is null or undefined:",
                                            dateItem
                                          );
                                          return null; // Handle null or undefined dateItem or dateItem.date
                                        }
                                      }
                                    )}
                                </p>
                              </div>
                              <div className="dropdown">
                                <p data-toggle="dropdown">
                                  <span>
                                    <img src={threeDots} />
                                  </span>
                                </p>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuOffset"
                                >
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        handleAppointmentsDetailsModalShow(item)
                                      }
                                    >
                                      <img src={taskHistory} />
                                      Appointment Details
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        toast.success("Export to itinerary", {
                                          position: toast.POSITION.TOP_RIGHT,
                                          autoClose: 1000,
                                        })
                                      }
                                    >
                                      <img
                                        src="images/export_itinerary.png"
                                        alt="Export to itinerary"
                                      />
                                      Export to itinerary
                                    </a>
                                  </li>

                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src={exportToCalendar} />
                                      Export to Calender
                                      <img src={rightarrow2} />
                                    </a>
                                    <ul className="dropdown-menu submenu">
                                      <li>
                                        <a
                                          onClick={() => handleGoogleData(item)}
                                          className="dropdown-item"
                                        >
                                          <img src={googleCalendar} />
                                          Google Calendar
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleOutLookData(item)
                                          }
                                          className="dropdown-item"
                                        >
                                          <img src={outlookCalendar} />
                                          Outlook Calender
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() => handleEmailData(item)}
                                          className="dropdown-item"
                                        >
                                          <img src="images/yahoo-calendar.png" />
                                          Yahoo Calender
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        handleUpdateReminderShow(item.id)
                                      }
                                    >
                                      <img src={SetReminder} />
                                      Set Reminder
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        handleCancelAppointment(item.id)
                                      }
                                    >
                                      <img src="images/dropdown-delete.png" />
                                      Cancel Appointment
                                    </a>
                                  </li>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="AppointmentCalendar">
                      {selectedDatesOfCalender?.length > 0 && (
                        <DatePicker
                          // selected={new Date()} // Set to null to avoid selecting a default date
                          // onChange={date => handleDateChange(date)}
                          inline
                          selectsRange
                          dateFormat="dd/mm/yyyy"
                          placeholderText="Select dates"
                          highlightDates={selectedDatesOfCalender}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={AddCategoryModal}
        onHide={() => handleAddCategoryShowHide("hide", "")}
        className="ModalBox modal fade MediumModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={() => handleAddCategoryShowHide("hide", "")}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Add Categories</h4>
                </div>
                <div className="FolderForm">
                  {/* <form> */}
                  <div className="row">
                    <div className="col-md-8">
                      <div className="ModalMediumheading">
                        <h3>
                          <span>Job:</span> {jobData?.state?.jobNumber ?? "N/A"}
                        </h3>
                        <h3>
                          <span>Selection Type:</span>
                          {categoryType}
                        </h3>
                      </div>
                      <div className="form-group">
                        <h6>Categories*</h6>
                        <div className="Categories Categories1">
                          <div className="Categories_all Categories_all1">
                            <span>Select a category</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>
                              <input
                                type="text"
                                placeholder="-Search"
                                name="search"
                                value={search}
                                autoComplete="off"
                                onChange={handleInputChange}
                              />
                              <span className="Search">
                                <img src="images/search.png" />
                              </span>
                            </li>
                            {/* <li className="CursorPointer">
                                <span className="text-red2 fw-16">+</span>{" "}
                                &nbsp;&nbsp;&nbsp;&nbsp; New
                              </li> */}
                            {interiorCategoryList?.categoryDetails?.length >
                              0 &&
                              interiorCategoryList?.categoryDetails
                                .filter((data) =>
                                  search.toLowerCase() === ""
                                    ? true
                                    : data.name
                                        .toLowerCase()
                                        .includes(search.toLowerCase())
                                )
                                .map((item, i) => (
                                  <li key={i}>
                                    <label className="CheckBox">
                                      {item.name}
                                      <input
                                        type="checkbox"
                                        checked={selectedCategories.some(
                                          (category) =>
                                            category.categoryId === item.id
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(item)
                                        }
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </li>
                                ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="SelectCategoryTable ">
                    <table>
                      <thead>
                        <tr>
                          <th>Selected Categories</th>
                          <th>Allow Multiple Selections</th>
                          <th>Allowance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedCategories?.length > 0 &&
                          selectedCategories?.map((category, index) => (
                            <tr key={index}>
                              <td>{category.name}</td>
                              <td>
                                <label className="CheckBox">
                                  <input
                                    type="checkbox"
                                    name="multiple"
                                    checked={category.multiple}
                                    onChange={(e) =>
                                      handleCategoryChange(e, index)
                                    }
                                  />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control width-50"
                                  placeholder="$"
                                  name="allowance"
                                  value={category.allowance}
                                  onChange={(e) =>
                                    handleCategoryChange(e, index)
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="Button d-flex justify-content-center align-items-center">
                    <button
                      className="Create mr-5"
                      onClick={() => handleCreateCategory()}
                    >
                      Add Category
                    </button>
                    <button
                      className="Outline ml-5"
                      onClick={() => handleAddCategoryShowHide("hide", "")}
                    >
                      Cancel
                    </button>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={UpdateCategoryModal}
        onHide={() => handleUpdateCategoryShowHide("hide", "")}
        className="ModalBox modal fade MediumModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={() => handleUpdateCategoryShowHide("hide", "")}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Edit Categories</h4>
                </div>
                <div className="FolderForm">
                  {/* <form> */}
                  <div className="row">
                    <div className="col-md-8">
                      <div className="ModalMediumheading">
                        <h3>
                          <span>Job:</span> {jobData?.state?.jobNumber ?? "N/A"}
                        </h3>
                        <h3>
                          <span>Selection Type:</span>
                          {categoryType}
                        </h3>
                      </div>
                      <div className="form-group">
                        <h6>Categories*</h6>
                        <div className="Categories Categories1">
                          <div className="Categories_all Categories_all1">
                            <span>Select a category</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>
                              <input
                                type="text"
                                placeholder="-Search"
                                name="search"
                                value={search}
                                autoComplete="off"
                                onChange={handleInputChange}
                              />
                              <span className="Search">
                                <img src="images/search.png" />
                              </span>
                            </li>
                            {/* <li className="CursorPointer">
                                <span className="text-red2 fw-16">+</span>{" "}
                                &nbsp;&nbsp;&nbsp;&nbsp; New
                              </li> */}
                            {interiorCategoryList?.categoryDetails?.length >
                              0 &&
                              interiorCategoryList?.categoryDetails
                                .filter((data) =>
                                  search.toLowerCase() === ""
                                    ? true
                                    : data.name
                                        .toLowerCase()
                                        .includes(search.toLowerCase())
                                )
                                .map((item, i) => (
                                  <li key={i}>
                                    <label className="CheckBox">
                                      {item.name}
                                      <input
                                        type="radio"
                                        name="selectedItem"
                                        checked={updateCategories.some(
                                          (updateCat) =>
                                            updateCat?.categoryId === item.id
                                        )}
                                        onChange={() => handleRadioChange(item)}
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </li>
                                ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="SelectCategoryTable ">
                    <table>
                      <thead>
                        <tr>
                          <th>Selected Categories</th>
                          <th>Allow Multiple Selections</th>
                          <th>Allowance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {updateCategories?.length > 0 &&
                          updateCategories?.map((category, index) => (
                            <tr key={index}>
                              <td>{updateCategoryName(category.categoryId)}</td>

                              <td>
                                <label className="CheckBox">
                                  <input
                                    type="checkbox"
                                    name="multiple"
                                    checked={category?.multiple}
                                    onChange={(e) =>
                                      handleUpdateCategoryChange(e, index)
                                    }
                                  />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control width-50"
                                  placeholder="$"
                                  name="allowance"
                                  value={category?.allowance}
                                  onChange={(e) =>
                                    handleUpdateCategoryChange(e, index)
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="Button d-flex justify-content-center align-items-center">
                    <button
                      className="Create mr-5"
                      onClick={() => handleUpdateCategory()}
                    >
                      Update Category
                    </button>
                    <button
                      className="Outline ml-5"
                      onClick={() => handleUpdateCategoryShowHide("hide", "")}
                    >
                      Cancel
                    </button>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={DocumentationDeleteModal}
        onHide={handleDocumentationDeleteClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={handleDocumentationDeleteClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Delete</h4>
                </div>
                <div className="FolderForm">
                  <div className="my-5">
                    <h6>Are you sure you want to delete this ?</h6>
                  </div>
                  <div className="Button d-flex justify-content-between">
                    <button
                      className="Create"
                      onClick={handleDocumentationDelete}
                    >
                      Delete
                    </button>
                    <button
                      className="Outline"
                      onClick={handleDocumentationDeleteClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={PendingDateSelectionListModal}
        onHide={() => handlePendingDateSelectionListModalHideShow("hide")}
        className="ModalBox modal fade LargeModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={() =>
                  handlePendingDateSelectionListModalHideShow("hide")
                }
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Pending Date Selection List</h4>
                </div>
                <div className="ModalMediumheading">
                  <h3>
                    <span>Job:</span> {jobData?.state?.jobNumber ?? "N/A"}
                  </h3>
                </div>
                <div className="TableList CommonHeading TableListMaxHeight">
                  <table>
                    <thead>
                      <tr>
                        <th>Desription</th>
                        <th>Type</th>
                        <th>Attendees</th>
                        <th>Client Date Selection Status</th>
                        <th>Designer/Vendor Date Selection Status </th>
                        <th>Designer</th>
                        <th>Vendor</th>
                        <th>Date Created</th>
                        <th>Last Update</th>
                        <th>Appointment Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pendingAppointmentList?.data?.length > 0 ? (
                        pendingAppointmentList?.data?.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{item.title}</td>
                              <td>{item.type}</td>
                              <td>Client &amp; Designer</td>
                              <td>{item.appointmentStatus}</td>
                              <td>{item.clientDateSelectionStatus ?? "N/A"}</td>
                              <td>{item.designerAsoc?.vendorName ?? "N/A"}</td>
                              <td />
                              <td>
                                {moment(item.createdAt).format("YYYY-MM-DD")}
                              </td>
                              <td>
                                {moment(item.updatedAt).format("YYYY-MM-DD")}
                              </td>
                              <td>{item.appointmentStatus}</td>
                              <td>
                                <div className="TableThreeDots text-center">
                                  <ul className="">
                                    <li className="dropdown">
                                      <a
                                        className=""
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <img src="images/dots.png" />
                                      </a>
                                      <ol
                                        className="dropdown-menu ChangeOrderDropdown320"
                                        style={{}}
                                      >
                                        <li>
                                          <a href="javascript:void(0);">
                                            <img src="images/Action-1.png" />
                                            Select Dates
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            onClick={() =>
                                              handleResendDateSelection(
                                                item.id,
                                                "client"
                                              )
                                            }
                                          >
                                            <img src="images/resend_date.png" />
                                            Resend Date Selection to Client
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            onClick={() =>
                                              handleResendDateSelection(
                                                item.id,
                                                "vendor"
                                              )
                                            }
                                          >
                                            <img src="images/Action-2.png" />
                                            Resend Date Selection
                                            Designer/Vendor
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            onClick={() =>
                                              handlePendingDeleteModalShow(
                                                item.id
                                              )
                                            }
                                          >
                                            <img src="images/dropdown-delete.png" />
                                            Cancel Appointment
                                          </a>
                                        </li>
                                      </ol>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="10" className="text-center">
                            <p>No Data found.</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={CreateAppointmentModal}
        onHide={() => handleCreateAppointmentModalHideShow("hide")}
        className="ModalBox modal fade LargeModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={() => handleCreateAppointmentModalHideShow("hide")}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Create New Appointment</h4>
                </div>
                <div className="FolderForm">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <h6>
                          Appointment Title <span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Appointment 1"
                          name="title"
                          value={title}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <h6>
                          Appointment Type <span className="required">*</span>
                        </h6>
                        <div className="CommonSelectBox w-100">
                          <select
                            name="type"
                            value={type}
                            onChange={handleInputChange}
                          >
                            <option value="">
                              Select Design Selections Appointment Type
                            </option>
                            <option value="interior">Interior</option>
                            <option value="exterior">Exterior</option>
                          </select>
                        </div>
                      </div>
                      <div>
                        <label className="CheckBox text-grey-80 fw-500 mb-3">
                          {" "}
                          Include Category Selection information
                          <input
                            type="checkbox"
                            onChange={handleCheck}
                            checked={catStatus}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                      {catStatus &&
                        (type === "interior" ? (
                          <div className="CommonSelectBox w-100">
                            <select
                              name="category"
                              value={category}
                              onChange={handleInputChange}
                            >
                              <option value="">Select a Category</option>
                              {interiorCreateCategoryList?.data?.[0]
                                ?.DesignCategoriesJunctions?.length > 0 &&
                                interiorCreateCategoryList?.data?.[0]?.DesignCategoriesJunctions?.map(
                                  (dataa, index) => {
                                    return (
                                      <option value={dataa.id}>
                                        {dataa.id}
                                      </option>
                                    );
                                  }
                                )}
                            </select>
                          </div>
                        ) : (
                          <div className="CommonSelectBox w-100">
                            <select
                              name="category"
                              value={category}
                              onChange={handleInputChange}
                            >
                              <option value="">Select a Category</option>
                              {exteriorCreateCategoryList?.data?.[0]
                                ?.DesignCategoriesJunctions?.length > 0 &&
                                exteriorCreateCategoryList?.data?.[0]?.DesignCategoriesJunctions?.map(
                                  (dataa, index) => {
                                    return (
                                      <option value={dataa.id}>
                                        {dataa.id}
                                      </option>
                                    );
                                  }
                                )}
                            </select>
                          </div>
                        ))}

                      <div className="form-group mt-4">
                        <h6>
                          Location <span className="required">*</span>
                        </h6>
                        <div className="mb-3">
                          <label className="Radio">
                            Builder's Address
                            <input
                              type="radio"
                              name="location"
                              value="buildersAddress"
                              checked={location === "buildersAddress"}
                              onChange={handleInputChange}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="mb-3">
                          <label className="Radio">
                            Vendor's Address
                            <input
                              type="radio"
                              name="location"
                              value="vendorsAddress"
                              checked={location === "vendorsAddress"}
                              onChange={handleInputChange}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="mb-3">
                          <label className="Radio">
                            Client's Unit
                            <input
                              type="radio"
                              name="location"
                              value="clientsUnit"
                              checked={location === "clientsUnit"}
                              onChange={handleInputChange}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="mb-3">
                          <label className="Radio">
                            Other (Specify)
                            <input
                              type="radio"
                              name="location"
                              value="otherSpecify"
                              checked={location === "otherSpecify"}
                              onChange={handleInputChange}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                      <div className="ModalMediumheading mt-4">
                        <h3>
                          <span>Address</span>
                        </h3>
                      </div>
                      <div className="form-group">
                        <h6>
                          Street Address <span className="required">*</span>{" "}
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input street name"
                          name="streetAddressLine1"
                          value={streetAddressLine1}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <h6>Street Address Line 2 </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input address line two"
                          name="streetAddressLine2"
                          value={streetAddressLine2}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <h6>
                          City <span className="required">*</span>{" "}
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input city name"
                          name="city"
                          value={city}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <h6>
                          Zip/Postal Code <span className="required">*</span>{" "}
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input postal name"
                          name="postalcode"
                          value={postalcode}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <h6>
                          Country <span className="required">*</span>{" "}
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Country"
                          name="country"
                          value={country}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <h6>
                          State/Province <span className="required">*</span>{" "}
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter State or Province"
                          name="state"
                          value={state}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-7 ml-auto">
                      <div className="ModalMediumheading">
                        <h3>
                          <span>Suggested Dates</span>
                        </h3>
                        <h3>
                          <span>Appointment 1</span>
                        </h3>
                      </div>

                      <div className="row">
                        {appointmentDates &&
                          appointmentDates?.map((dateObj, index) => (
                            <React.Fragment key={index}>
                              <div className="form-group col-md-4">
                                <h6>{`Option ${index + 1}`}</h6>
                                <input
                                  type="date"
                                  className="form-control"
                                  value={dateObj.date}
                                  onChange={(e) =>
                                    handleChange(e, "date", index)
                                  }
                                />
                              </div>
                              <div className="form-group col-md-4">
                                <h6>From</h6>
                                <input
                                  type="time"
                                  className="form-control"
                                  value={dateObj.fromTime}
                                  onChange={(e) =>
                                    handleChange(e, "fromTime", index)
                                  }
                                />
                              </div>
                              <div className="form-group col-md-4">
                                <h6>To</h6>
                                <input
                                  type="time"
                                  className="form-control"
                                  value={dateObj.toTime}
                                  onChange={(e) =>
                                    handleChange(e, "toTime", index)
                                  }
                                />
                              </div>
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group mb-4">
                        <h6>Send to</h6>
                        <div className="mb-3">
                          <label className="Radio">
                            {" "}
                            Client &amp; Designer
                            <input
                              type="radio"
                              name="sentTo"
                              value="client to desinger"
                              checked={sentTo === "client to desinger"}
                              onChange={handleInputChange}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="mb-3">
                          <label className="Radio">
                            {" "}
                            Client &amp; Vendor
                            <input
                              type="radio"
                              name="sentTo"
                              value="client to vendor"
                              checked={sentTo === "client to vendor"}
                              onChange={handleInputChange}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                      {sentTo === "client to desinger" ? (
                        <div className="text RadioText">
                          <div className="form-group">
                            <h6>
                              Designer <span className="required">*</span>{" "}
                            </h6>
                            <div className="Categories Categories1">
                              <div className="Categories_all Categories_all1">
                                <span>Designer </span>
                                <img src="images/down.png" />
                              </div>
                              <ul>
                                <li>
                                  <input
                                    type="text"
                                    placeholder="-Search"
                                    name="designerSearch"
                                    value={designerSearch}
                                    onChange={handleInputChange}
                                  />
                                  <span className="Search">
                                    <img src="images/search.png" />
                                  </span>
                                </li>

                                {appointmentDesignerList?.details?.length > 0 &&
                                  appointmentDesignerList?.details
                                    .filter((data) =>
                                      designerSearch.toLowerCase() === ""
                                        ? true
                                        : data.vendorName
                                            .toLowerCase()
                                            .includes(
                                              designerSearch.toLowerCase()
                                            )
                                    )
                                    .map((item, i) => (
                                      <li
                                        value={item.id}
                                        key={i}
                                        onClick={() =>
                                          handleDesignerClick(item)
                                        }
                                        style={{
                                          backgroundColor:
                                            designer == item.id
                                              ? "#e71e09"
                                              : "transparent",
                                        }}
                                      >
                                        {item.vendorName}
                                      </li>
                                    ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="file RadioText">
                          <div className="form-group">
                            <h6>Vendor</h6>
                            <div className="CommonSelectBox w-100">
                              <select
                                name="vendor"
                                value={vendor}
                                onChange={handleInputChange}
                              >
                                <option value="">Select Vendor</option>
                                {vendorList?.details?.length > 0 &&
                                  vendorList?.details?.map((data, i) => {
                                    if (data.vendorName) {
                                      return (
                                        <option key={i} value={data.id}>
                                          {data.vendorName}
                                        </option>
                                      );
                                    }
                                    return null;
                                  })}
                              </select>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-md-7 ml-auto">
                      <div className="CommonModalArea">
                        <div className="form-group">
                          <h5>Set Reminder</h5>
                          <div className="CommonModalBox">
                            <h5>
                              <figure>
                                <img src="images/timer-red.png" />
                              </figure>{" "}
                              Reminder
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Button d-flex justify-content-between mt-5">
                    <button
                      className="Create"
                      onClick={handleCreateAppointment}
                    >
                      Request Confirmation
                    </button>
                    {/* <button
                      className="Create FolderPermissionId"
                      data-toggle="modal"
                      data-target="#CalendarPreview"
                      data-dismiss="modal"
                    >
                      Preview Calendar
                    </button> */}
                    <button
                      className="Outline"
                      onClick={() =>
                        handleCreateAppointmentModalHideShow("hide")
                      }
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={PendingDeleteModal}
        onHide={handlePendingDeleteModalClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handlePendingDeleteModalClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Delete</h4>
                </div>
                <div className="FolderForm">
                  <div className="my-5">
                    <h6>Are you sure you want to delete this ?</h6>
                  </div>
                  <div className="Button d-flex justify-content-between">
                    <button className="Create" onClick={handlePendingDelete}>
                      Delete
                    </button>
                    <button
                      className="Outline"
                      onClick={handlePendingDeleteModalClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ApprovedAppointmentDetailsModal}
        onHide={handleApprovedAppointmentDetailsModalClose}
        className="ModalBox modal fade MediumModal show"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={handleApprovedAppointmentDetailsModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Design Selection Dates Selection</h4>
                </div>
                <div className="FolderForm">
                  <h6>Design Selection Appointment</h6>
                  <form>
                    <div className="DesignAppointmentConfirmationBox">
                      <h3>Design Appointment Confirmation</h3>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="ClientsDetails">
                            <p>Client</p>
                            <h6 className="ClientName">Luke Anderson</h6>
                            <p className="ClientEmail">
                              linden@ibuildapplications.com
                            </p>
                            <p className="ClientNumber">4034005933</p>
                          </div>
                          <div className="ClientLocation">
                            <p>
                              Location <img src="images/location.png" />
                            </p>
                            <p className="ClientAddress">
                              #Unit 2 312 Weddenburn Rd SE <br /> Chinook Gate,
                              Calgary
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="ClientsDetails">
                            <h6 className="ClientName underline-grey">Items</h6>
                            <h6 className="ClientEmail fw-14 fw-400">
                              Door Selection
                            </h6>
                          </div>
                          <div className="ClientsDetails">
                            <h6 className="ClientName underline-grey">
                              Category
                            </h6>
                            <h6 className="ClientEmail fw-14 fw-400">
                              Door Selection
                            </h6>
                          </div>
                          <div className="ClientsDetails">
                            <h6 className="ClientName">Designer</h6>
                            <p className="ClientEmail mb-0">John Doe</p>
                            <p className="ClientNumber">
                              Contact: (403)555-555
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="DatesBoxAreaMain">
                            <h3>Dates</h3>
                            <div className="DatesBoxArea">
                              <h6>Door Selection</h6>
                              <p>02:30pm - 04:30pm. Wednesday, May 17, 2023 </p>
                              <p className="mb-3">
                                Mountain Time - US &amp; Canada
                              </p>
                              <div className="AddToSocialArea">
                                <h6>Add to</h6>
                                <a href="javascript:void(0);">
                                  <img src="images/google-calendar.png" />{" "}
                                  Calendar
                                </a>
                                <a href="javascript:void(0);">
                                  <img src="images/outlook-calendar.png" />
                                  Outlook Calender
                                </a>
                                <a href="javascript:void(0);">
                                  <img src="images/yahoo-calendar.png" />
                                  Yahoo Calender
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="DesignAppointmentConfirmationBoxOverlay">
                        <span>
                          <img src="images/design_icon.png" />
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={AddOptionModal}
        onHide={handleAddOptionModalClose}
        className="ModalBox modal fade ExtraLargeModal show"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleAddOptionModalClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>
                    {typeEdit
                      ? "Edit Selection Option"
                      : "Add Selection Option"}
                  </h4>
                </div>
                <div className="FolderForm">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="ModalMediumheading">
                        <h3>
                          <span>Category:</span> {showAddSelectionOption}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="HomeDepotArea">
                        {/* <h6 className="text-decoration-none">
                          <span>
                            <img src="images/option_details.png" />
                          </span>
                          Home Depot Product Linked
                        </h6> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <h6>Option Name *</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert Option Name"
                          name="name"
                          value={name}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <h6>Product Link</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert link"
                          name="productLink"
                          value={productLink}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <h6>Product Description</h6>
                        <textarea
                          className="form-control"
                          rows={3}
                          placeholder="Insert Description"
                          name="description"
                          value={description}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <h6>Vendor</h6>
                        <div className="CommonSelectBox w-100">
                          <select
                            name="vendorId"
                            value={vendorId}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Vendor</option>
                            {vendorList?.details?.length > 0 &&
                              vendorList?.details?.map((data, i) => {
                                if (data.vendorName) {
                                  return (
                                    <option key={i} value={data.id}>
                                      {data.vendorName}
                                    </option>
                                  );
                                }
                                return null;
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="CommonModalArea">
                        <div className="form-group">
                          <h5>Princing Details</h5>
                          <div className="CommonModalBox">
                            <h5
                              className="CursorPointer FolderPermissionId"
                              onClick={() =>
                                handleSelectItemBookPriceModalShow()
                              }
                            >
                              <figure>
                                <img src="images/select-account.png" />
                              </figure>{" "}
                              Select Item from Pricebook
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <h6>Attachments</h6>
                        <div className="Upload">
                          <span>-Drag and Drop Files or click to select</span>
                          <input
                            type="file"
                            multiple
                            onChange={handleFileChange}
                            accept=".jpg,.jpeg,.png"
                          />
                        </div>
                      </div>
                      {/* <div className="Button">
                        <button className="Create">Add</button>
                      </div> */}
                      <div className="SelectCategoryTable my-4">
                        <table>
                          <thead>
                            <tr>
                              <th>Files</th>
                              <th>Main Display Picture</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(attachments)?.length > 0 &&
                              attachments?.map((data, ind) => {
                                return (
                                  <tr>
                                    <td>
                                      <div
                                        className="FilesAdded"
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <label
                                          className="limited-text"
                                          style={{ width: "85%" }}
                                        >
                                          {data.fileUrl}
                                        </label>
                                        <span>
                                          <img src="images/fileview.png" />
                                        </span>
                                        <span onClick={removeAttachment}>
                                          <img
                                            src="images/Cross.png"
                                            className="cross"
                                          />
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex justify-content-center align-items-center">
                                        <label className="Radio">
                                          <input
                                            type="radio"
                                            name="maindisplaypicture"
                                            checked={data?.main}
                                            onChange={(e) =>
                                              attachmentChange(e, ind)
                                            }
                                          />
                                          <span className="checkmark" />
                                        </label>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                      <div className="Button">
                        <button
                          className="Create"
                          onClick={handleViewImagesShow}
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="ModalGreyTable">
                    <table>
                      <thead>
                        <tr>
                          <th>Cost Code</th>
                          <th>Quantity</th>
                          <th>Measure</th>
                          <th>Per Unit</th>
                          <th>Builder Total</th>
                          <th>Markup (%)</th>
                          <th>Discount (Number)</th>
                          <th>Client Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedAssemblies?.length > 0 &&
                          selectedAssemblies?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <div className="CommonSelectBox">
                                    <select
                                      name="costcode"
                                      value={item.costcode}
                                      onChange={(e) =>
                                        handleAssemblyChange(e, index)
                                      }
                                    >
                                      <option value="">Select Cost Code</option>
                                      {getCostCodeList?.data?.length > 0 &&
                                        getCostCodeList?.data?.map(
                                          (item, i) => {
                                            return (
                                              <option
                                                value={item.CostCode.number}
                                              >
                                                {item.CostCode?.number}
                                              </option>
                                            );
                                          }
                                        )}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="0%"
                                    disabled
                                    value={item.quantity}
                                    onChange={(e) =>
                                      handleAssemblyChange(e, index)
                                    }
                                  />
                                </td>
                                <td>
                                  <div className="CommonSelectBox">
                                    <select>
                                      <option value="">Select Cost Code</option>
                                      {getMeasureList?.measures?.length > 0 &&
                                        getMeasureList?.measures?.map(
                                          (data, i) => {
                                            // if(item.name){}
                                            return (
                                              <option
                                                value={data.name}
                                                disabled
                                                selected={
                                                  data.name == item.measure
                                                }
                                              >
                                                {data.name}
                                              </option>
                                            );
                                          }
                                        )}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="0%"
                                    name="perUnit"
                                    value={item.perUnit}
                                    onChange={(e) =>
                                      handleAssemblyChange(e, index)
                                    }
                                  />
                                </td>
                                <td>${item.perUnit * item.quantity}</td>
                                <td>
                                  <div className="CommonSelectBox">
                                    <td>
                                      <input
                                        type="number"
                                        min={0}
                                        max={100}
                                        className="form-control"
                                        placeholder=""
                                        name="markup"
                                        value={item.markup}
                                        onChange={(e) =>
                                          handleAssemblyChange(e, index)
                                        }
                                      />
                                    </td>
                                  </div>
                                </td>
                                <td>
                                  <div className="CommonSelectBox">
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder=""
                                        name="discount"
                                        value={item.discount}
                                        onChange={(e) =>
                                          handleAssemblyChange(e, index)
                                        }
                                      />
                                    </td>
                                  </div>
                                </td>
                                {/* <td>${= (Builder total + Builder Total * Markup/100) * (1-(Discount)) </td> */}
                                <td>
                                  $
                                  {(Number(item.perUnit * item.quantity) +
                                    Number(item.perUnit *
                                      item.quantity *
                                      Number(item.markup/100)))-
                                      Number(item.discount)}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div className="Button text-center mt-5">
                    <button
                      className="Create InteriorSelectionsBtn mr-5"
                      onClick={() =>
                        typeEdit ? handleUpdateOption() : handleCreateOption()
                      }
                    >
                      {typeEdit ? "Update Option" : "Create Option"}
                    </button>
                    <button
                      className="Outline ml-5s"
                      onClick={handleAddOptionModalClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {showSelectItemBookPriceModal && (
        <Modal
          show={showSelectItemBookPriceModal}
          onHide={() => handleSelectItemBookPriceModalClose()}
          className="ModalBox modal fade LargeModal show"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={() => handleSelectItemBookPriceModalClose()}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Select Items from Price Book</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="form-row">
                      <div className="form-group col-md-5">
                        <h6>Category</h6>
                        <div className="Categories Categories1">
                          <div
                            className="Categories_all Categories_all1"
                            onClick={handleCategoryToggle}
                          >
                            <span>Select a category</span>
                            <img src="images/down.png" />
                          </div>
                          {categoryToggle && (
                            <ul>
                              <li>
                                <input
                                  type="text"
                                  placeholder="-Search"
                                  name="categorySearch"
                                  value={categorySearch}
                                  onChange={handleInputChange}
                                />
                                <span className="Search">
                                  <img src="images/search.png" />
                                </span>
                              </li>

                              {priceBookCategoryList?.categoryDetails?.length >
                                0 &&
                                priceBookCategoryList?.categoryDetails
                                  ?.filter((data) =>
                                    categorySearch.toLowerCase() === ""
                                      ? true
                                      : data.name
                                          .toLowerCase()
                                          .includes(
                                            categorySearch.toLowerCase()
                                          )
                                  )
                                  .map((item, i) => (
                                    <li
                                      value={item.id}
                                      key={i}
                                      onClick={() =>
                                        handleCategoryClick(item.id)
                                      }
                                      style={{
                                        backgroundColor:
                                          designCategoryId == item.id
                                            ? "#e71e09"
                                            : "transparent",
                                      }}
                                    >
                                      {item.name}
                                    </li>
                                  ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-5">
                        <h6>Select Items</h6>
                        <div className="ModalInputSearch">
                          <input
                            type="text"
                            placeholder="Search entry or Assembly name"
                            name="itemSearch"
                            value={itemSearch}
                            onChange={handleInputChange}
                          />
                          <span>
                            <img src="images/search.png" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="BaselineTable BaselineTableModal">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Assembly</th>
                            <th>Measure</th>
                            <th />
                            <th>Markup</th>
                            <th />
                            <th>Select</th>
                          </tr>
                        </thead>
                        <tbody>
                          {assembleList?.categoryDetails?.length > 0
                            ? assembleList?.categoryDetails
                                .filter((data) =>
                                  itemSearch.toLowerCase() === ""
                                    ? true
                                    : data.name
                                        .toLowerCase()
                                        .includes(itemSearch.toLowerCase())
                                )
                                .map((item, index) => {
                                  return (
                                    <>
                                      <tr className="BaselineShowBorder">
                                        <td>
                                          <div className="BigBox BaselineShow">
                                            <img src="images/rightarrow2.png" />
                                            {item.name}
                                          </div>
                                        </td>
                                        <td>
                                          <div className="SmallBox BaselineShow">
                                            {item.measureId}
                                          </div>
                                        </td>
                                        <td>
                                          <div className="SmallBox BaselineShow" />
                                        </td>
                                        <td>
                                          <div className="SmallBox BaselineShow">
                                            {item.defaultMarkup}
                                          </div>
                                        </td>
                                        <td>
                                          <div className="SmallBox BaselineShow" />
                                        </td>
                                        <td>
                                          {/* <div className="SmallBox">
                                          <label className="CheckBox">
                                            <input type="checkbox" 
                                           
                                            
                                            />
                                            <span className="checkmark" />
                                          </label>
                                        </div> */}
                                        </td>
                                      </tr>

                                      <tr className="">
                                        <td
                                          colSpan={6}
                                          className="padding_none"
                                        >
                                          <table>
                                            <tbody>
                                              <tr>
                                                <th>Assembly</th>
                                                <th>Measure</th>
                                                <th>Unit Cost</th>
                                                <th>Markup</th>
                                                <th>Account (s)</th>
                                                <th>Select</th>
                                              </tr>
                                              {item?.Entries?.length > 0 &&
                                                item?.Entries?.map(
                                                  (data, ind) => {
                                                    return (
                                                      <tr>
                                                        <td>
                                                          <div className="BigBox">
                                                            {data?.name}
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className="SmallBox">
                                                            {data.measure?.name}
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className="SmallBox">
                                                            ${data.costcode}
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className="SmallBox">
                                                            {data.markup}%
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className="SmallBox">
                                                            {moment(
                                                              data.createdAt
                                                            ).format(
                                                              "YYYY-MM-DD"
                                                            )}
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className="SmallBox">
                                                            <label className="CheckBox">
                                                              <input
                                                                type="checkbox"
                                                                checked={selectedAssemblies.some(
                                                                  (assembly) =>
                                                                    assembly.entryId ==
                                                                    data.id
                                                                )}
                                                                onChange={() =>
                                                                  handleAssemblyCheckboxChange(
                                                                    data
                                                                  )
                                                                }
                                                              />
                                                              <span className="checkmark" />
                                                            </label>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })
                            : "No Data Found"}
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="Button d-flex mt-5">
                    <button className="Create">Confirm Selection</button>
                  </div> */}
                    <div className="ChooseQualityTable">
                      <h6>Choose Quantity for each selected Item</h6>
                      <table>
                        <thead>
                          <tr>
                            <th>Item</th>
                            <th>Measure</th>
                            <th>Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedAssemblies?.length > 0 &&
                            selectedAssemblies?.map((data, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    {data.entryName}
                                    {/* <img src="images/Cross.png" /> */}
                                  </td>
                                  <td>{data.measure}</td>
                                  <td>
                                    <input
                                      type="number"
                                      min={0}
                                      className="form-control"
                                      name="quantity"
                                      value={data.quantity}
                                      onChange={(e) =>
                                        handleAssemblyChange(e, i)
                                      }
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                    <div className="Button d-flex justify-content-center mt-5">
                      <button
                        className="Create mr-5"
                        onClick={() => handleSelectItemBookPriceModalClose()}
                      >
                        Add Selected Item
                      </button>
                      <button
                        className="Outline ml-5"
                        onClick={() => handleSelectItemBookPriceModalClose()}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showImageModal && (
        <Modal
          show={showImageModal}
          onHide={handleViewImagesClose}
          className="ModalBox modal fade MediumModal show"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleViewImagesClose}>
                  ×
                </a>
                {
                  <div className="FormArea">
                    <div
                      id="carouselExampleCaptions"
                      className="carousel slide"
                      data-ride="carousel"
                    >
                      <div className="carousel-inner">
                        {attachments?.map((imageUrl, index) => (
                          <div
                            key={index}
                            className={`carousel-item ${
                              index === 0 ? "active" : ""
                            }`}
                          >
                            <img
                              src={imageUrl.fileUrl}
                              className="d-block w-100"
                              alt={`Slide ${index + 1}`}
                            />
                            <div className="carousel-caption d-none d-md-block">
                              {/* Add any caption or additional content here if needed */}
                            </div>
                          </div>
                        ))}
                      </div>
                      <ol className="carousel-indicators d-flex justify-content-between">
                        {attachments?.map((imageUrl, index) => (
                          <li
                            key={index}
                            data-target="#carouselExampleCaptions"
                            data-slide-to={index}
                            className={index === 0 ? "active" : ""}
                          >
                            <img
                              src={imageUrl.fileUrl}
                              alt={`Indicator ${index + 1}`}
                              className="d-block w-100 h-100"
                            />
                          </li>
                        ))}
                      </ol>
                      <a
                        className="carousel-control-prev top"
                        href="#carouselExampleCaptions"
                        role="button"
                        data-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon p-2 m-3 bg-danger"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Previous</span>
                      </a>
                      <a
                        className="carousel-control-next top"
                        href="#carouselExampleCaptions"
                        role="button"
                        data-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon p-2 bg-danger"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Next</span>
                      </a>
                    </div>
                  </div>
                }
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <Modal
        show={DesignSelectionsModal}
        onHide={handleDesignSelectionsModalClose}
        className="ModalBox modal fade ExtraLargeModal show"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={handleDesignSelectionsModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Design File Manager</h4>
                </div>
                <div className="FolderForm CommonSelectBoxMain">
                  <>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="DashboardLeft">
                          <div className="ShowList">
                            <div className="ContactInformationArea">
                              <h6>Contact Information</h6>
                              <div className="ContactInformationBox border-0">
                                <span className="circle">JL</span>
                                <div className="ContactInformationName">
                                  <h6>{primary?.legalName ?? "N/A"}</h6>
                                  <p>
                                  {`${primary?.streetAddress ?? ""}  ${
                              primary?.addressLineTwo ?? ""
                            }`}
                                    <img
                                      src="images/location.png"
                                      className="CursorPointer FolderPermissionId ml-2"
                                      
                                    />
                                  </p>
                                  <p>
                                    Phone: <span>{primary?.phoneNumber??"N/A"}</span>
                                  </p>
                                  <p>
                                    Email: <span>{primary?.email??"N/A"}</span>
                                  </p>
                                </div>
                              </div>
                              <div className="AssignUnit">
                                <h4>Assigned Unit</h4>
                                <p>{jobData?.state?.jobType} ({jobData?.state?.jobNumber}))</p>
                              </div>
                            </div>
                            <aside>
                              <h5>Folder</h5>
                              <div>
                                <a
                                  onClick={handleCreateFolderShow}
                                  title="Add Folder"
                                >
                                  <img src="images/Plus.png" />
                                </a>
                              </div>
                            </aside>
                            <div className="Search">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search"
                                name="folderSearch"
                                value={folderSearch}
                                onChange={handleInputChange}
                              />
                              <span>
                                <img src="images/search.png" />
                              </span>
                            </div>
                          </div>
                          <div className="FolderList border-0">
                            <ul>
                              {!folderLoader ? (
                                folderList?.FolderDetails?.length > 0 &&
                                folderList?.FolderDetails?.filter((data) =>
                                  folderSearch.toLowerCase() === ""
                                    ? true
                                    : data.folderName
                                        ?.toLowerCase()
                                        ?.includes(folderSearch.toLowerCase())
                                ).map((item, ind) => {
                                  return (
                                    <li key={ind} style={{
                                      backgroundColor:
                                      folderIDD === item.id
                                          ? "green"
                                          : "transparent",
          
                                    }}
                                    onClick={(e) =>
                                      handleFileAndPendingFileData(e,item.id)
                                    }
                                    >
                                      <span
                                        className="Icon"
                                       
                                      >
                                        <img
                                          src="images/Folder.png"
                                          alt="Folder Icon"
                                        />
                                      </span>
                                      <label>{item.folderName}</label>
                                      <a
                                        onClick={(e) =>
                                          handleFolderDeleteShow(
                                            e,
                                            item.id,
                                            item.folderName
                                          )
                                        }
                                        title="Cancel"
                                      >
                                        <img
                                          src="images/Cross.png"
                                          alt="Timer Icon"
                                        />
                                      </a>
                                      {/* <a
                                        href="javascript:void(0);"
                                        title="Folder History"
                                        onClick={() =>
                                          handleFolderHistoryModalShow(
                                            item.folderId,
                                            item.id,
                                            item.name
                                          )
                                        }
                                      >
                                        <img
                                          src="images/timer.png"
                                          alt="Timer Icon"
                                        />
                                      </a> */}
                                    </li>
                                  );
                                })
                              ) : (
                                <Loader />
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8">
                    
                        <div className="EventArea">
                          <h4>Pending File Events</h4>
                          <div className="TableList">
                            <table>
                              <thead>
                                <tr>
                                  <th>File Id</th>
                                  <th>Template</th>
                                  <th>Modified by</th>
                                  <th>Status</th>
                                  <th>Date</th>
                                  {/* <th>Action</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {!pendingFileLoader ? (
                                  pendingFileeEventList?.pendingFileEvents
                                    ?.length > 0 ? (
                                    pendingFileeEventList?.pendingFileEvents?.map(
                                      (item, i) => {
                                        return (
                                          <tr key={i}>
                                            <td>{item.fileName}</td>
                                            <td>{item.modifiedBy ?? "N/A"}</td>
                                            <td>
                                              {item.actionStatus ?? "N/A"}
                                            </td>
                                            <td>
                                              {moment(item.createdAt).format(
                                                "YYYY-MM-DD"
                                              )}
                                            </td>
                                            <td>
                                              <div className="Actions">
                                                <a
                                                  onClick={() =>
                                                    handleViewDocumentationShow(
                                                      item.htmlUrl
                                                    )
                                                  }
                                                >
                                                  <img src="images/view-po.png" />
                                                </a>
                                                <a
                                                  href="javascript:void(0);"
                                                  data-toggle="modal"
                                                  data-target="#EditFileDesktop"
                                                >
                                                  <img src="images/Action-1.png" />
                                                </a>
                                                <a
                                                // onClick={() =>
                                                //   handleShareClientDocumentShow(
                                                //     item.folderId,
                                                //     item.id
                                                //   )
                                                // }
                                                >
                                                  <img src="images/Action-2.png" />
                                                </a>
                                                {/* <a
                                          onClick={() =>
                                            handleDocumentationDeleteShow(
                                              item.id
                                            )
                                          }
                                        >
                                          <img src="images/icon-30.png" />
                                        </a> */}
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )
                                  ) : (
                                    <tr>
                                      <td colSpan="5" className="text-center">
                                        <p>No Data found.</p>
                                      </td>
                                    </tr>
                                  )
                                ) : (
                                  <Loader />
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="FilesArea">
                          <div className="FilesHead">
                            <div className="FilesLeft">
                              <h4>Files</h4>
                            </div>
                            <div className="TodosButtons mt-3">
                              <a
                                className="ml-0 FolderPermissionId"
                                onClick={handleDocumentKeyModalShow}
                              >
                                <img
                                  src={require("../assets/images/insert_keys.png")}
                                />{" "}
                                Document Keys
                              </a>
                              {/* <a
                          onClick={handleManageSignatueShow}
                          className="ml-0 FolderPermissionId"
                        >
                          <img src="images/white_manage_signatures.png" />{" "}
                          Manage Signatures<span>3</span>
                        </a> */}
                            </div>
                            <div className="FilesRight">
                              <a
                                onClick={() =>
                                  handleReleaseFolderfileModalShow()
                                }
                                title="Release Files"
                                className="FolderPermissionId"
                              >
                                <img
                                  src={require("../assets/images/release-file.png")}
                                />
                              </a>
                            
                              <a
                                onClick={handleCreateNewFileModalShow}
                                title="Create New File"
                                className="FolderPermissionId"
                              >
                                <img src="images/document-support.png" />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {!documentLoader ? (
                            documentList?.clientDocDetails?.length > 0 ? (
                              documentList?.clientDocDetails?.map(
                                (data, ind) => {
                                  return (
                                    <div className="col-md-4">
                                      <div className="FilesBox2 Safety">
                                        <figcaption>
                                          <div>
                                            <h4>{data.name}</h4>
                                          </div>
                                          <ul>
                                            <li className="dropdown">
                                              <a
                                                className="dropdown-toggle"
                                                role="button"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                <img src="images/dots.png" />
                                              </a>
                                              <ol
                                                className="dropdown-menu DropdownMenuSafetyModal"
                                                style={{}}
                                              >
                                                <li>
                                                  <a
                                                    href="javascript:void(0);"
                                                    className="FolderPermissionId"
                                                    onClick={() =>
                                                      handleEditDocumentationShow(
                                                        data
                                                      )
                                                    }
                                                  >
                                                    <img src="images/Action-1.png" />
                                                    Edit Document
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    onClick={() =>
                                                      handleViewDocumentationShow(
                                                        data.htmlUrl,
                                                        
                                                      )
                                                    }
                                                    className="FolderPermissionId"
                                                  >
                                                    <img src="images/view_jobs_details.png" />
                                                    View Document
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    className="FolderPermissionId"
                                                    onClick={() =>
                                                      handleSendForClientModalShow(
                                                        data
                                                      )
                                                    }
                                                  >
                                                    <img src="images/sign_document.png" />
                                                    Sent for Client Signature
                                                  </a>
                                                </li>
                                                {/* <li><a href="javascript:void(0);" class="FolderPermissionId" data-toggle="modal" data-target="#EditFile2" data-dismiss="modal" ><img src="images/sign_document2.png">Sign documents</a></li>
                                                 */}
                                                <li>
                                                  <a
                                                    href={data.docUrl}
                                                    target="_self"
                                                    title="ImageName"
                                                  >
                                                    <img src="images/download.png" />
                                                    Download Documents
                                                  </a>
                                                </li>
                                                <li>
                                                  <a  className="FolderPermissionId"
                                                     onClick={() =>
                                                      handleSendForClientModalShow(
                                                        data
                                                      )
                                                    }
                                                  >
                                                    <img src="images/upload_new.png" />
                                                    Upload Signed documents
                                                  </a>
                                                </li>
                                                {/* <li>
                                                  <a
                                                    onClick={() =>
                                                      handleReleasefileModalShow(
                                                        data
                                                      )
                                                    }
                                                    className="FolderPermissionId"
                                                  >
                                                    <img
                                                      src={require("../assets/images/release-file.png")}
                                                    />
                                                    Release File
                                                  </a>
                                                </li> */}
                                                <li>
                                                  <a
                                                    onClick={() =>
                                                      handleShareClientDocumentShow(
                                                        data.folderId,
                                                        data.id
                                                      )
                                                    }
                                                    className="FolderPermissionId"
                                                  >
                                                    <img src="images/Action-2.png" />
                                                    Share documents
                                                  </a>
                                                </li>
                                              </ol>
                                            </li>
                                          </ul>
                                        </figcaption>
                                        <div className="FilesBoxCenterSafety FilesBoxCenterSafetyModal">
                                          <div
                                            className="FilesBoxCenterSafetyLeft FolderPermissionId CursorPointer"
                                            data-toggle="modal"
                                            data-target="#TemplateActivity"
                                            data-dismiss="modal"
                                          >
                                            <img
                                              src="images/file.png"
                                              title="File"
                                            />
                                            <h6 className="underline-grey">
                                              Template
                                            </h6>
                                            <span className="Count">3</span>
                                          </div>
                                          <div className="FilesBoxCenterSafetyRight">
                                            <div className="form-group">
                                              <h6>Client View</h6>
                                              <label className="switch">
                                                <input type="checkbox" checked={data.clientView} />
                                                <span className="slider" />
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <figcaption>
                                          <div>
                                            <p className="CursorPointer">
                                              Last update {moment(data.updatedAt).format("YYYY-MM-DD")}
                                            </p>
                                          </div>
                                          <ul>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleFolderHistoryModalShow(
                                                    data.folderId,
                                                    data.id,
                                                    data.name
                                                  )
                                                }
                                                title="Document History"
                                                className="FolderPermissionId"
                                              >
                                                <img src="images/timer.png" />
                                              </a>
                                            </li>
                                          </ul>
                                        </figcaption>
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            ) : (
                              <div className="text-center w-100">
                                <h6>No data found</h6>
                              </div>
                            )
                          ) : (
                            <Loader />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={CreateFolderModal}
        onHide={handleCreateFolderClose}
        className="ModalBox modal fade MediumModal show"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleCreateFolderClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Create Folder</h4>
                </div>
                <div className="FolderForm">
                  <div className="form-group">
                    <h6>Folder Name *</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert new folder name"
                      name="folderName"
                      value={folderName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <h6>Folder Permissions</h6>
                    <div className="PermissionsName">
                      <h5
                        className="FolderPermissionId"
                        data-toggle="modal"
                        data-target="#permissionModal2"
                        data-dismiss="modal"
                      >
                        <span>
                          <img src="images/Folder.png" />
                        </span>{" "}
                        Folder Permissions
                      </h5>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>Folder Actions</h6>
                    <div className="PermissionsName">
                      <h5
                        className="FolderPermissionId"
                        data-toggle="modal"
                        data-target="#folderActionModal2"
                        data-dismiss="modal"
                      >
                        <span>
                          <img src="images/Icon-4.png" />
                        </span>{" "}
                        Folder Actions
                      </h5>
                    </div>
                  </div>
                  <div className="Button text-center">
                    <button
                      className="Create"
                      onClick={handleCreateFolderSubmit}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={FolderDeleteModal}
        onHide={handleFolderDeleteClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleFolderDeleteClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Delete</h4>
                </div>
                <div className="FolderForm">
                  <div className="my-5">
                    <h6>Are you sure you want to delete this ?</h6>
                  </div>
                  <div className="Button d-flex justify-content-between">
                    <button className="Create" onClick={handleFolderDelete}>
                      Delete
                    </button>
                    <button
                      className="Outline"
                      onClick={handleFolderDeleteClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <Modal
        show={CreateNewFileModal}
        onHide={handleCreateNewFileModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleCreateNewFileModalClose}>
                ×
              </a>
              <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Create New File</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="form-group">
                      <h6>Name</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert new entry name"
                        name="fileFolderName"
                        value={fileFolderName}
                        onChange={handleInputChange}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors?.nameError}
                      </span>
                    </div>
                    <div className="form-group">
                      <h6>Folder</h6>
                      <div className="Categories Categories1">
                        <div className="Categories_all Categories_all1">
                          <label>
                            List of folders where user has permission to create
                            file
                          </label>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <div className="">
                            <select
                              className="form-control"
                              name="id"
                              value={id}
                              // onFocus={handleValidation}
                              onChange={handleInputChange}
                            >
                              <option value="">Select</option>
                              {folderList?.FolderDetails?.length > 0 &&
                                folderList?.FolderDetails?.map((item) => {
                                  return (
                                    <option value={item.id}>
                                      {item.folderName}{" "}
                                    </option>
                                  );
                                })}
                            </select>
                            <span style={{ color: "red" }}>
                              {errors?.foldNameError}
                            </span>
                          </div>
                        </ul>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <h6>Editor Option</h6>
                      <div className="mb-4">
                        <label className="Radio">
                          Create file with base template upload
                          <input
                            type="radio"
                            name="editor"
                            value="BaseTemplate"
                            checked={selectedEditor === "BaseTemplate"}
                            onChange={handleSelectedEditorChange}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div>
                        <label className="Radio">
                          Create new file with editor
                          <input
                            type="radio"
                            name="editor"
                            value="CreateNewFile"
                            checked={selectedEditor === "CreateNewFile"}
                            onChange={handleSelectedEditorChange}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                    <div className="file RadioText"></div>
                    <div
                      className="text RadioText"
                      style={{ display: "block" }}
                    >
                      {selectedEditor === "BaseTemplate" && (
                        <>
                          <div className="form-group">
                            <h6>Select Template File (Docx)</h6>
                            <div className="Upload">
                              <span>
                                -Drag and Drop Files or click to select
                              </span>
                              <input
                                type="file"
                                id="createFileInput"
                                accept=".doc,.docx"
                                onChange={(e) =>
                                  handleFileChanges(e)
                                }
                              />
                            </div>
                            <span style={{ color: "red", fontSize: "14px" }}>
                              {errors?.cltfileError}
                            </span>
                          </div>

                          <div className="FilesAdded">
                            <label>{clientFile?.name}</label>
                            <span>
                              <img src="images/fileview.png" />
                            </span>
                            <span onClick={handleRemoveClientFile}>
                              <img src="images/Cross.png" className="cross" />
                            </span>
                          </div>
                        </>
                      )}

                      {selectedEditor === "CreateNewFile" && (
                        <div className="mce-pagebreak">
                          <Editor
                            apiKey="rye4mz748ekd8ke0loyu8j7gq7iu1tkxs3nticj32j848g6r" // Use your premium API key
                            onInit={(evt, editor) =>
                              (editorSelectedRef.current = editor)
                            }
                            value={htmlUrl}
                            init={{
                              height: 500,
                              menubar:
                                "file edit view insert format tools table help",
                              plugins: [
                                "advlist",
                                "autolink",
                                "lists",
                                "link",
                                "image",
                                "charmap",
                                "print",
                                "preview",
                                "anchor",
                                "searchreplace",
                                "visualblocks",
                                "code",
                                "fullscreen",
                                "insertdatetime",
                                "media",
                                "table",
                                "paste",
                                "help",
                                "wordcount",
                                "lists",
                                "table",
                                "pagebreak",
                                "spellchecker",
                                "powerpaste",
                                "mediaembed",
                                "advcode",
                                "emoticons",
                                "codesample",
                                "advtable",
                                "visualchars",
                                "visualblocks",
                                "linkchecker",
                                "checklist",
                                "media",
                                "tableofcontents",
                                "a11ychecker",
                                "permanentpen",
                                "casechange",
                                "pageembed",
                                "nonbreaking",
                                "quickbars",
                                "advlist",
                                "autosave",
                                "autolink",
                                "autoresize",
                              ],
                              toolbar:
                                "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | " +
                                "alignleft aligncenter alignright alignjustify | numlist bullist checklist outdent indent | " +
                                "forecolor backcolor casechange permanentpen formatpainter removeformat | " +
                                "pagebreak | charmap emoticons | fullscreen preview save print | insertfile image media pageembed link anchor codesample | " +
                                "a11ycheck ltr rtl | showcomments addcomment",

                              toolbar_sticky: true,

                              autosave_ask_before_unload: true,
                              autosave_interval: "30s",
                              autosave_prefix: "{path}{query}-{id}-",
                              autosave_restore_when_empty: true,
                              autosave_retention: "2m",

                              image_advtab: true,

                              content_css: "default",
                              importcss_append: true,

                              image_title: true,
                              automatic_uploads: true,

                              // Handle drag and drop events
                              setup: (editor) => {
                                editor.on("drop", (e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  // Handle dropped files
                                  if (e.dataTransfer && e.dataTransfer.files) {
                                    const files = e.dataTransfer.files;
                                    for (const file of files) {
                                      const reader = new FileReader();
                                      reader.onload = (event) => {
                                        // Insert image at the cursor position
                                        editor.insertContent(
                                          `<img src="${event.target.result}" alt="${file.name}"/>`
                                        );
                                      };
                                      reader.readAsDataURL(file);
                                    }
                                  }
                                });
                              },

                              link_list: [
                                {
                                  title: "My page 1",
                                  value: "https://www.tinymce.com",
                                },
                                {
                                  title: "My page 2",
                                  value: "http://www.moxiecode.com",
                                },
                              ],
                              image_list: [
                                {
                                  title: "My image 1",
                                  value:
                                    "https://www.tiny.cloud/images/glyph-tinymce.svg",
                                },
                                {
                                  title: "My image 2",
                                  value:
                                    "https://www.tiny.cloud/docs/images/glyph-tinymce.svg",
                                },
                              ],
                              image_class_list: [
                                { title: "None", value: "" },
                                { title: "Some class", value: "some-class" },
                              ],
                              importcss_file_filter: "main.css",
                              templates: [
                                {
                                  title: "New Table",
                                  description: "creates a new table",
                                  content:
                                    "<table><tr><td>Sample Text</td></tr></table>",
                                },
                                {
                                  title: "Starting my story",
                                  description: "A sample document",
                                  content: "Once upon a time...",
                                },
                                {
                                  title: "New list with dates",
                                  description: "New List with Dates",
                                  content:
                                    "<ul><li>12 December</li><li>13 December</li></ul>",
                                },
                              ],
                              template_cdate_format:
                                "[CDATE: %m/%d/%Y : %H:%M:%S]",
                              template_mdate_format:
                                "[MDATE: %m/%d/%Y : %H:%M:%S]",
                              height: 600,
                              image_caption: true,
                              quickbars_selection_toolbar:
                                "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                              noneditable_class: "mceNonEditable",
                              toolbar_mode: "sliding",
                              spellchecker_dialog: true,
                              spellchecker_whitelist: ["Ephox", "Moxiecode"],

                              tinycomments_mode: "embedded",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                              a11y_advanced_options: true,

                              branding: false, // Remove TinyMCE branding
                              powerpaste_word_import: "clean", // Configure PowerPaste
                              powerpaste_html_import: "merge",
                              powerpaste_allow_local_images: true,

                              // Enable live media embeds
                              media_live_embeds: true,

                              // Enable line breaks
                              forced_root_block: "",
                              force_br_newlines: true,
                              force_p_newlines: false,
                            }}
                            onEditorChange={handleSelectedEditor}
                          />
                        </div>
                      )}
                    </div>
                    <div className="Button d-flex justify-content-between mt-5">
                      <button
                        className="Create FolderPermissionId"
                        onClick={handleCreateDocumentation}
                      >
                        Create File
                      </button>
                      <button className="Outline" onClick={handleCreateNewFileModalClose}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={FolderHistoryModal}
        onHide={handleFolderHistoryModalClose}
        className="ModalBox modal fade MediumModal show"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleFolderHistoryModalClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Folder History</h4>
                </div>
                <div className="ModalTitleHeading">
                  <h2>
                    <span>Document :- {fileNames??""} </span>
                  </h2>
                </div>
                <div className="TableList">
                  <table>
                    <thead>
                      <tr>
                        <th>Modified by</th>
                        <th>Created by</th>
                        <th>Date</th>
                        <th>Activity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!documentHistoryLoader ? (
                        documentHistoryList?.clientHistories?.length > 0 ? (
                          documentHistoryList?.clientHistories?.map(
                            (item, i) => {
                              return (
                                <tr key={i}>
                                  <td>{item.modifiedBy ?? "N/A"}</td>
                                  <td>{item.createdBy ?? "N/A"}</td>
                                  <td>
                                    {moment(item.createdAt).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </td>
                                  <td>{item.activity}</td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          "No Data Found"
                        )
                      ) : (
                        <Loader />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ShareClientDocumentationModal}
        onHide={handleShareClientDocumentClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={handleShareClientDocumentClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Share Document</h4>
                </div>
                <div className="FolderForm">
                  <div className="ModalBigheading">
                    <h3>
                      <span>Documents:</span>Statement of Adjustments.pdf
                    </h3>
                  </div>
                  <div className="ModalFilterAreaBox mb-3">
                    <h4 className="mb-4">Select Recipient</h4>
                    <div className="RadioArea d-flex  flex-column">
                      <div className="form-group">
                        <label className="Radio">
                          Select Recipient
                          <input
                            type="radio"
                            name="Cuisine"
                            defaultValue="text"
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div className="form-group mb-4">
                        <label className="Radio">
                          Other Contact
                          <input
                            type="radio"
                            name="Cuisine"
                            defaultValue="file"
                            defaultChecked=""
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="text RadioText">
                    <div className="form-group"></div>
                  </div>
                  <div
                    className="file RadioText mb-5"
                    style={{ display: "block" }}
                  >
                    <div className="form-group">
                      <h6>Name</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert name"
                        name="clientName"
                        value={clientName}
                        onChange={handleInputChange}
                      />
                      {/* <span style={{ color: "red" }}>
                          {errors?.cliNameError}
                        </span> */}
                    </div>
                    <div className="form-group">
                      <h6>Email</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert Email"
                        name="clientEmail"
                        value={clientEmail}
                        onChange={handleInputChange}
                      />
                      {/* <span style={{ color: "red" }}>
                          {errors?.cliEmailError}
                        </span> */}
                    </div>

                    <div className="Button" onClick={handleAddRecipient}>
                      <button
                        className="Create"
                        disabled={clientEmail == "" && clientName == ""}
                      >
                        Add Recipient
                      </button>
                    </div>
                  </div>
                  <h6>Recipients</h6>
                  <div className="FilesAddedTable">
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {emails?.length > 0 &&
                          emails?.map((item, ind) => {
                            return (
                              <tr>
                                <td>
                                  <div className="FilesAddedName">
                                    <p>{item.name}</p>
                                    <span>
                                      <img
                                        src="images/Cross.png"
                                        onClick={() =>
                                          handleRemoveRecipient(ind)
                                        }
                                      />
                                    </span>
                                  </div>
                                </td>
                                <td>{item.email}</td>
                              </tr>
                            );
                          })}
                        {/* <span style={{ color: "red" }}>
                            {errors?.cliNameError}
                          </span> */}
                      </tbody>
                    </table>
                  </div>
                  <div className="form-group">
                    <h6>Message</h6>
                    <textarea
                      type="text"
                      className="form-control"
                      rows={4}
                      placeholder="Include message (optional)"
                      name="emailBody"
                      value={emailBody}
                      onChange={handleInputChange}
                    />
                    {/* <span style={{ color: "red" }}>{errors?.eBodyError}</span> */}
                  </div>
                  <div className="Button d-flex justify-content-between">
                    <button
                      className="Create mr-3"
                      onClick={handleShareDocumentSubmit}
                    >
                      Share Document
                    </button>
                    <button
                      className="Outline ml-3"
                      onClick={handleShareClientDocumentClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ViewDocumentationModal}
        onHide={handleViewDocumentationClose}
        className="ModalBox LargeModal GreyModalContent"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleViewDocumentationClose}>
                ×
              </a>
              <div>
                  <div className="ModalTitleBox">
                    <h4>View File</h4>
                  </div>
                  {viewDocumentData ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: viewDocumentData }}
                    />
                  ) : (
                    <p>Loading document...</p>
                  )}
                </div>
                <div className="FolderForm">
                  <div className="Button d-flex justify-content-between m-3">
                    <button
                      className="Outline"
                      onClick={handleViewDocumentationClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={AppointmentsDetailsModal}
        onHide={handleAppointmentsDetailsModalClose}
        className="ModalBox modal fade MediumModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleAppointmentsDetailsModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBoxBrand">
              <h4>Trades Day Selection</h4>
              <a href="">
                <img src="images/logo-white.png" />
              </a>
            </div>
            <div className="FolderForm">
              <h3>Work Order #{appointmentDeatilsData?.id}</h3>
              <div className="row">
                <div className="col-lg-6">
                  <h3 className="fw-700">Trades Day Confirmation</h3>
                  <div className="ClientsDetails">
                    <p>Client Details</p>
                    <h6 className="ClientName"></h6>
                    <p className="ClientEmail"></p>
                    <p className="ClientNumber"></p>
                  </div>
                  <div className="ClientLocation">
                    <p>
                      Location <img src="images/location.png" />
                    </p>
                    <p className="ClientAddress">
                      {appointmentDeatilsData?.location ?? "N/A"}
                    </p>
                  </div>
                  <div className="ClientsDetails">
                    <p>Vendor Details</p>
                    <h6 className="ClientName"></h6>
                    <p className="ClientEmail"></p>
                    <p className="ClientNumber"></p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h3 className="fw-700">Items to be Fixed</h3>
                  <div className="ItemAreaBox ItemAreaBoxUnderline mb-5">
                    <h6>#1 - Leaking Sink</h6>
                    <h6>#2 Faucet Problem</h6>
                  </div>
                  <div className="ClientsDetails">
                    <h6 className="ClientName">Technician</h6>
                    <p className="ClientEmail"></p>
                    <p className="ClientNumber">Contact:</p>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="DatesBoxAreaMain">
                    <h3>Dates</h3>
                    {appointmentDeatilsData?.designAppointmentDates?.length >
                      0 &&
                      appointmentDeatilsData?.designAppointmentDates?.map(
                        (data, index) => {
                          return (
                            <div className="DatesBoxArea" key={index}>
                              <h6>#{index + 1} - Leaking Sink</h6>
                              <p>
                                {data.fromTime} - {data.toTime}. {data.date}
                              </p>
                              <p className="mb-3"></p>
                              <div className="AddToSocialArea">
                                <h6>Add to</h6>
                                <a href="javascript:void(0);">
                                  <img src="images/google-calendar.png" />{" "}
                                  Calendar
                                </a>
                                <a href="javascript:void(0);">
                                  <img src="images/outlook-calendar.png" />
                                  Outlook Calendar
                                </a>
                                <a href="javascript:void(0);">
                                  <img src="images/yahoo-calendar.png" />
                                  Yahoo Calendar
                                </a>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={UpdateReminderModal}
        onHide={handleUpdateReminderClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleUpdateReminderClose}>
                ×
              </a>
              <div className="FolderForm">
                <div className="form-group">
                  <h6>
                    Weeks <span className="required"></span>
                  </h6>
                  <div className="CommonSelectBox w-100">
                    <select
                      disabled={day}
                      name="week"
                      value={week}
                      onChange={handleInputChange}
                    >
                      <option value="">
                        Select amount of weeks for reminder
                      </option>
                      <option value="7">1</option>
                      <option value="14">2</option>
                      <option value="21">3</option>
                      <option value="28">4</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <h6>
                    Days <span className="required"></span>
                  </h6>
                  <div className="CommonSelectBox w-100">
                    <select
                      disabled={week}
                      name="day"
                      value={day}
                      onChange={handleInputChange}
                    >
                      <option value="">
                        Select amount of days for reminder
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </select>
                  </div>
                </div>
                <div className="Button text-center mt-5">
                  <button className="Create" onClick={handleReminderUpdate}>
                    Set Reminder
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ReleasefileModal}
        onHide={handleReleasefileModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleReleasefileModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Release Files</h4>
            </div>
            <div className="JobTitleArea">
              <div className="form-group">
                <h6>File Manager Folder</h6>
                <p className="ml-0">Folder 1</p>
              </div>
              <div className="form-group">
                <h6>Template</h6>
                <p className="ml-0">File 1</p>
              </div>
              <div className="form-gropu">
                <h6>Sub File Manager</h6>
              </div>
              <div className="FolderForm">
                <div className="form-group mb-4">
                  <label className="Radio">
                    {" "}
                    Sales
                    <input
                      type="radio"
                      name="designFiles"
                      value="Sales"
                      checked={selectedOption === "Sales"}
                      onChange={handleOptionChange}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="form-group">
                  <label className="Radio">
                    {" "}
                    Client View
                    <input
                      type="radio"
                      name="designFiles"
                      value="Client View"
                      checked={selectedOption === "Client View"}
                      onChange={handleOptionChange}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="form-group">
                  <label className="Radio">
                    {" "}
                    Trade View
                    <input
                      type="radio"
                      name="designFiles"
                      value="Trade View"
                      checked={selectedOption === "Trade View"}
                      onChange={handleOptionChange}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="form-group">
                  <label className="Radio">
                    {" "}
                    Estimation
                    <input
                      type="radio"
                      name="designFiles"
                      value="Estimation"
                      checked={selectedOption === "Estimation"}
                      onChange={handleOptionChange}
                    />
                    <span className="checkmark" />
                  </label>
                </div>

                {selectedOption === "Trade View" && (
                  <div
                    className="TradeView RadioText"
                    style={{ display: "block" }}
                  >
                    <div className="form-group">
                      <h6>Vendor</h6>
                      <select
                        className="form-control"
                        name="VendorId"
                        value={VendorId}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Vendor</option>
                        {vendorList?.details?.map((data, ind) => {
                          return (
                            <option key={ind} value={data.id}>
                              {data.vendorName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                )}
                <div className="Button d-flex justify-content-between mt-5">
                  <button className="Create" onClick={handleReleasefile}>
                    Release Files
                  </button>
                  <button
                    className="Outline"
                    onClick={handleReleasefileModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ReleaseFolderfileModal}
        onHide={handleReleaseFolderfileModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleReleaseFolderfileModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Release Files</h4>
            </div>
            <div className="JobTitleArea">
              <h6>Design File Manager</h6>
              <div className="FolderForm">
                <div className="form-group mb-4">
                  <label className="Radio">
                    {" "}
                    Sales
                    <input
                      type="radio"
                      name="designFiles"
                      value="Sales"
                      checked={selectedOption === "Sales"}
                      onChange={handleOptionChange}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="form-group">
                  <label className="Radio">
                    {" "}
                    Client View
                    <input
                      type="radio"
                      name="designFiles"
                      value="Client View"
                      checked={selectedOption === "Client View"}
                      onChange={handleOptionChange}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="form-group">
                  <label className="Radio">
                    {" "}
                    Trade View
                    <input
                      type="radio"
                      name="designFiles"
                      value="Trade View"
                      checked={selectedOption === "Trade View"}
                      onChange={handleOptionChange}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="form-group">
                  <label className="Radio">
                    {" "}
                    Estimation
                    <input
                      type="radio"
                      name="designFiles"
                      value="Estimation"
                      checked={selectedOption === "Estimation"}
                      onChange={handleOptionChange}
                    />
                    <span className="checkmark" />
                  </label>
                </div>

                {selectedOption === "Trade View" && (
                  <div
                    className="TradeView RadioText"
                    style={{ display: "block" }}
                  >
                    <div className="form-group">
                      <h6>Vendor</h6>
                      <select
                        className="form-control"
                        name="VendorId"
                        value={VendorId}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Vendor</option>
                        {vendorList?.details?.map((data, ind) => {
                          return (
                            <option key={ind} value={data.id}>
                              {data.vendorName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <h6>File Manager Folder</h6>
                  <select
                    className="form-control"
                    name="folderId"
                    // value={folderId}
                    onChange={handleInputChange}
                  >
                    <option value="">Select a Folder</option>
                    {folderList?.FolderDetails?.length > 0 &&
                      folderList?.FolderDetails?.map((item, i) => {
                        return (
                          <option
                            value={item.id}
                            key={i}
                            selected={item.id == folderId}
                          >
                            {" "}
                            {item.folderName}
                          </option>
                        );
                      })}
                  </select>
                </div>
                {folderId != null && (
                  <div className="FileCheckBoxTable">
                    <table>
                      <tbody>
                        {folderData?.length > 0 &&
                          folderData?.map((item, i) => {
                            return (
                              <tr>
                                <td>{item.name}</td>
                                <td>
                                  <label className="CheckBox">
                                    <input
                                      type="checkbox"
                                      checked={selectedItems.includes(item)}
                                      onChange={(e) =>
                                        handleFolderCheckboxChange(e, item)
                                      }
                                    />
                                    <span className="checkmark" />
                                  </label>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}

                <div className="Button d-flex justify-content-between mt-5">
                  <button className="Create" onClick={handleReleaseFolderfile}>
                    Release Folder Files
                  </button>
                  <button
                    className="Outline"
                    onClick={handleReleaseFolderfileModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={SendForClientModal}
        onHide={handleSendForClientModalClose}
        className="ModalBox modal fade MediumModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleSendForClientModalClose}
          >
            ×
          </a>

            <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Send Document for Client Signature</h4>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <h6>Template</h6>
                    <p className="ml-0">Design Contract</p>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <h6>File Id</h6>
                    <p className="ml-0">F1</p>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="ModalMediumheading">
                    <h3>
                      <span>Client Signees</span>
                    </h3>
                  </div>
                  <div className="form-group">
                    <h6>Number of Signee</h6>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Insert Number of Signees"
                      name="numberOfSignee"
                      value={numberOfSignee}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <h6>Select Primary Client Signee</h6>
                    <div class="CommonSelectBox w-100">
                      <select
                        name="primarySignee"
                        value={primarySignee}
                        onChange={handleInputChange}
                      >
                        <option value="">Select</option>
                        {primayClient?.length > 0 &&
                          primayClient?.map((item, i) => {
                            return (
                              <option value={item.id} key={i}>
                                {item.legalName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  {numberOfSignee >= 2 && (
                    <div className="form-group">
                      <h6>Select Secondary Client Signee</h6>
                      <div class="CommonSelectBox w-100">
                        <select
                          name="secondarySignee"
                          value={secondarySignee}
                          onChange={handleInputChange}
                        >
                          <option value="">Select</option>
                          {secondaryClient?.length > 0 &&
                            secondaryClient?.map((item, i) => {
                              return (
                                <option value={item.id}>
                                  {item.legalName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  )}
                  {numberOfSignee == 3 && (
                    <div className="form-group">
                      <h6>Select Additional Client Signee</h6>
                      <div class="CommonSelectBox w-100">
                        <select
                          name="additionalSignee"
                          value={additionalSignee}
                          onChange={handleInputChange}
                        >
                          <option value="">Select</option>
                          {secondaryClient?.length > 0 &&
                            secondaryClient?.map((item, i) => {
                              return (
                                <option value={item.id}>
                                  {item.legalName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  )}
                  <div className="form-group">
                    <h6>Select Builder Signee</h6>
                    {/* <p className="ml-0">{iBuildLocalData?.user?.id}</p> */}
                    <div class="CommonSelectBox w-100">
                      <select
                        name="builderSignee"
                        // value={builderSignee}
                        onChange={handleInputChange}
                      >
                        <option value="">Select</option>
                        {builderData?.length > 0 &&
                          builderData?.map((item, i) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                
                </div>
              </div>

              <div className="Button d-flex justify-content-between mt-3">
                <button
                  className="Create FolderPermissionId"
                  onClick={handleSendForClient}
                >
                  Generate Document
                </button>
                <button
                  className="Outline"
                  onClick={handleSendForClientModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
       
        </Modal.Body>
      </Modal>
      <Modal
        show={HeaderPreviewModal}
        onHide={handlHeaderPreviewModalClose}
        className="ModalBox modal fade LargeModal GreyModalContent show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handlHeaderPreviewModalClose}
          >
            ×
          </a>
          <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Header Preview</h4>
              </div>
          <div className="FolderForm">
            <div className="AgreementViewArea">
              <div className="AgreementViewTop">
                <div className="AgreementViewTopRight">
                  <p
                    dangerouslySetInnerHTML={createAboutMarkup()}
                    className="editor"
                  ></p>
                </div>
              </div>
            </div>

            <div className="Button d-flex justify-content-between mt-5">
              <button className="Create" onClick={() => handleApply()}>
                Apply
              </button>
              <button
                className="Outline"
                onClick={handlHeaderPreviewModalClose}
              >
                Cancel
              </button>
            </div>
          </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={EditDocumentationModal}
        onHide={handleEditDocumentationClose}
        className="ModalBox modal fade ExtraLargeModal CkEditorHeight"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleEditDocumentationClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Edit File</h4>
                </div>
                <div className="FolderForm">
                  <div className="EditOption">
                    <div className="EditOptionBox">
                      <h6>Name</h6>
                      <a href="javascript:void(0);">
                        {contentData?.name ?? "N/A"}
                        &nbsp;&nbsp;
                        <span>
                          <img src="images/fileview.png" />
                        </span>
                      </a>
                    </div>
                    <div className="EditOptionBox">
                      <h6>Folder</h6>
                      <a href="javascript:void(0);">
                        {contentData?.fileName ?? "N/A"}
                        &nbsp;&nbsp;
                        <span>{/* <img src="images/refresh.png" /> */}</span>
                      </a>
                    </div>
                    <div className="EditOptionBox">
                      <h6>Draft</h6>
                      <a href="javascript:void(0);">
                        <label className="CheckBox">
                          <input type="checkbox"   checked={draft}
                              onChange={handleDraftChange}/>
                          <span className="checkmark" />
                        </label>
                      </a>
                    </div>
                    <div className="EditOptionBox">
                      <h6>Use Company Header</h6>
                      <a
                        className="FolderPermissionId"
                        onClick={() => handlHeaderPreviewModalShow()}
                      >
                        <span>
                          <img src="images/company_header.png" />
                        </span>
                      </a>
                    </div>
                    <div className="EditOptionBox">
                      <h6>Download</h6>
                      <a href={contentData?.docUrl} target="_blank">
                        <span>
                          <img src="images/download.png" />
                        </span>
                      </a>
                    </div>
                    <div className="EditOptionBox">
                      <h6>Insert Signature</h6>
                      <a
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#InsertSignatureSmall5"
                        data-dismiss="modal"
                      >
                        <span>
                          <img src="images/signature.png" />
                        </span>
                      </a>
                    </div>
                    <div className="EditOptionBox">
                      <h6>Insert Keys</h6>
                      <a
                        className="FolderpermissionId"
                        onClick={() => handleDocumentKeyModalShow()}
                      >
                        <span>
                          <img src="images/insert_keys.png" />
                        </span>
                      </a>
                    </div>
                  </div>

                  {content ? (
                    <div className="mce-pagebreak">
                      <Editor
                        apiKey="rye4mz748ekd8ke0loyu8j7gq7iu1tkxs3nticj32j848g6r" // Use your premium API key
                        onInit={(evt, editor) => (editorsRef.current = editor)}
                        value={content ?? ""}
                        init={{
                          height: 500,
                          menubar:
                            "file edit view insert format tools table help",
                          plugins: [
                            "advlist",
                            "autolink",
                            "lists",
                            "link",
                            "image",
                            "charmap",
                            "print",
                            "preview",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "code",
                            "fullscreen",
                            "insertdatetime",
                            "media",
                            "table",
                            "paste",
                            "help",
                            "wordcount",
                            "lists",
                            "table",
                            "pagebreak",
                            "spellchecker",
                            "powerpaste",
                            "mediaembed",
                            "advcode",
                            "emoticons",
                            "codesample",
                            "advtable",
                            "visualchars",
                            "visualblocks",
                            "linkchecker",
                            "checklist",
                            "media",
                            "tableofcontents",
                            "a11ychecker",
                            "permanentpen",
                            "casechange",
                            "pageembed",
                            "nonbreaking",
                            "quickbars",
                            "advlist",
                            "autosave",
                            "autolink",
                            "autoresize",
                          ],
                          toolbar:
                            "undo redo | styles| bold italic underline strikethrough | fontfamily fontsize blocks | " +
                            "alignleft aligncenter alignright alignjustify | numlist bullist checklist outdent indent | " +
                            "forecolor backcolor casechange permanentpen formatpainter removeformat | " +
                            "pagebreak | charmap emoticons | fullscreen preview save print | insertfile image media pageembed link anchor codesample | " +
                            "a11ycheck ltr rtl | showcomments addcomment",
                          toolbar_sticky: true,
                          autosave_ask_before_unload: true,
                          autosave_interval: "30s",
                          autosave_prefix: "{path}{query}-{id}-",
                          autosave_restore_when_empty: true,
                          autosave_retention: "2m",
                          image_advtab: true,
                          content_css: "default",
                          importcss_append: true,
                          image_title: true,
                          automatic_uploads: true,
                          powerpaste_word_import: "clean", // options: 'clean', 'merge', 'prompt'
                          powerpaste_html_import: "merge", // options: 'merge', 'clean', 'prompt'
                          forced_root_block: "", // allows for line breaks instead of <p>
                          force_br_newlines: true,
                          force_p_newlines: false,
                          content_style: `
          .mce-item-table:not([border]), .mce-item-table:not([border]) caption, 
          .mce-item-table:not([border]) td, .mce-item-table:not([border]) th, 
          .mce-item-table[border="0"], .mce-item-table[border="0"] caption, 
          .mce-item-table[border="0"] td, .mce-item-table[border="0"] th, 
          table[style*="border-width: 0px"], table[style*="border-width: 0px"] caption, 
          table[style*="border-width: 0px"] td, table[style*="border-width: 0px"] th { 
            border: 0;
          }
        `,
                          setup: (editor) => {
                            editor.on("drop", (e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              if (e.dataTransfer && e.dataTransfer.files) {
                                const files = e.dataTransfer.files;
                                for (const file of files) {
                                  const reader = new FileReader();
                                  reader.onload = (event) => {
                                    editor.insertContent(
                                      `<img src="${event.target.result}" alt="${file.name}"/>`
                                    );
                                  };
                                  reader.readAsDataURL(file);
                                }
                              }
                            });
                          },
                          link_list: [
                            {
                              title: "My page 1",
                              value: "https://www.tinymce.com",
                            },
                            {
                              title: "My page 2",
                              value: "http://www.moxiecode.com",
                            },
                          ],
                          image_list: [
                            {
                              title: "My image 1",
                              value:
                                "https://www.tiny.cloud/images/glyph-tinymce.svg",
                            },
                            {
                              title: "My image 2",
                              value:
                                "https://www.tiny.cloud/docs/images/glyph-tinymce.svg",
                            },
                          ],
                          image_class_list: [
                            { title: "None", value: "" },
                            { title: "Some class", value: "some-class" },
                          ],
                          importcss_file_filter: "main.css",
                          templates: [
                            {
                              title: "New Table",
                              description: "creates a new table",
                              content:
                                "<table><tr><td>Sample Text</td></tr></table>",
                            },
                            {
                              title: "Starting my story",
                              description: "A sample document",
                              content: "Once upon a time...",
                            },
                            {
                              title: "New list with dates",
                              description: "New List with Dates",
                              content:
                                "<ul><li>12 December</li><li>13 December</li></ul>",
                            },
                          ],
                          template_cdate_format: "[CDATE: %m/%d/%Y : %H:%M:%S]",
                          template_mdate_format: "[MDATE: %m/%d/%Y : %H:%M:%S]",
                          height: 600,
                          image_caption: true,
                          quickbars_selection_toolbar:
                            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                          noneditable_class: "mceNonEditable",
                          toolbar_mode: "sliding",
                          spellchecker_dialog: true,
                          spellchecker_whitelist: ["Ephox", "Moxiecode"],
                          tinycomments_mode: "embedded",
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          a11y_advanced_options: true,
                          branding: false, // Remove TinyMCE branding
                          powerpaste_word_import: "clean", // Configure PowerPaste
                          powerpaste_html_import: "merge",
                          powerpaste_allow_local_images: true,
                          media_live_embeds: true, // Enable live media embeds
                          forced_root_block: "",
                          force_br_newlines: true,
                          force_p_newlines: false,
                        }}
                        onEditorChange={handleEditorChange}
                      />
                    </div>
                  ) : (
                    <p>Loading document...</p>
                  )}
                  <div className="Button d-flex justify-content-between">
                  <button
                        className="Create mt-5"
                        onClick={handleEditDocumentation}
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            {" "}
                            <span
                              className="spinner-border spinner-border-md"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </>
                        ) : (
                          "Save File"
                        )}
                      </button>
                    <button
                      className="Create mt-5"
                      onClick={handleEditDocumentationClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={DocumentKeyModal}
        onHide={handleDocumentKeyModalClose}
        className="ModalBox modal fade MediumModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleDocumentKeyModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Document Key</h4>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="col-md-7">
                  <div className="form-group">
                    <h6>Keys List</h6>
                    <div className="Categories Categories1">
                      <div className="Categories_all Categories_all1">
                        <span>Block Job</span>
                        <img src="images/down.png" />
                      </div>
                      <ul style={{ display: "block" }}>
                        <li>
                          <input
                            type="text"
                            placeholder="Search"
                            name="documentSearch"
                            value={documentSearch}
                            onChange={handleInputChange}
                          />
                          <span className="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>

                        {allDocumentKey?.length > 0 &&
                          allDocumentKey
                            ?.filter((data) =>
                              documentSearch.toLowerCase() === ""
                                ? true
                                : data.label
                                    .toLowerCase()
                                    .includes(documentSearch.toLowerCase())
                            )
                            .map((item, i) => {
                              return (
                                <li
                                  value={item.label}
                                  key={i}
                                  onClick={() => handleItemClick(item.html)}
                                  style={{
                                    backgroundColor:
                                      JobId === item.label
                                        ? "#e71e09"
                                        : "transparent",
                                  }}
                                >
                                  {item.label}
                                </li>
                              );
                            })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 offset-1">
                  <div className="form-group">
                    <h6>Key</h6>
                    <p className="GreenSignedColor ml-0">
                      <img src="images/insert_keys.png" />
                      &nbsp;&nbsp;{JobId ?? "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div>
        <Modal
          show={DesignSelectionsAggrementInteriorModal}
          onHide={handleDesignSelectionsAggrementInteriorModalClose}
          className="ModalBox modal fade LargeModal show "
        >
          <Modal.Body className="">
            <a
              className="CloseModal ClosePermissionId"
              onClick={handleDesignSelectionsAggrementInteriorModalClose}
            >
              ×
            </a>
            <div className="FormArea" ref={contentRef}>
              <div className="ModalTitleBox">
                <h4>Design Selection Agreement</h4>
              </div>
              <div className="FolderForm">
                <div className="ModalBigheading">
                  <h3>
                    <span className="fw-500">Status: </span>
                    {jobData?.state?.status}
                  </h3>
                </div>
                <div className="AgreementViewArea">
                  <div className="AgreementViewTop">
                    <a href="javscript:void(0)">
                      <img src="images/modal-big-logo.png" />
                    </a>
                    <div className="AgreementViewTopRight">
                      <h3>iBuild</h3>
                      <p>312 Weddenburn Rd SE</p>
                      <p>Calgary, AB CA, T2J 1J1</p>
                      <p>
                        4034005933 www.ibuildapplications.com
                        linden@ibuildapplications.com
                      </p>
                      <p>
                        “The bitterness of poor quality remains long after the
                        sweetness of meeting
                        <br />
                        the schedule has been gorgotten”
                      </p>
                    </div>
                  </div>
                  <div className="StatementAdjustmentArea px-4">
                    <h6>Design Selections</h6>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Client Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={primary?.legalName ?? ""}
                          />
                        </div>
                        <div className="form-group">
                          <label>Address</label>
                          <input
                            type="text"
                            className="form-control"
                            value={`${primary?.streetAddress ?? ""}  ${
                              primary?.addressLineTwo ?? ""
                            }`}
                          />
                        </div>
                        <div className="form-group">
                          <label>City</label>
                          <input
                            type="text"
                            className="form-control"
                            value={primary?.city ?? ""}
                          />
                        </div>
                        <div className="form-group">
                          <label>State/Provice</label>
                          <input
                            type="text"
                            className="form-control"
                            value={primary?.province}
                          />
                        </div>
                        <div className="form-group">
                          <label>Possession Date</label>
                          <input
                            type="text"
                            className="form-control"
                            value={primary?.createdAt?.slice(0, 10)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Date Prepared</label>
                          <input
                            type="text"
                            className="form-control"
                            value={primary?.createdAt?.slice(0, 10)}
                          />
                        </div>
                        <div className="form-group">
                          <label>Job Number</label>
                          <input
                            type="text"
                            className="form-control"
                            value={primary?.JobId}
                          />
                        </div>
                        <div className="form-group">
                          <label>Sales Consultant</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Model</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Contract Date</label>
                          <input
                            type="text"
                            className="form-control"
                            value={primary?.createdAt?.slice(0, 10)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <h6 className="Title">JOB DETAILS</h6>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Address</label>
                          <input
                            type="text"
                            className="form-control"
                            value={jobData?.state?.jobAddress}
                          />
                        </div>
                        <div className="form-group">
                          <label>City</label>
                          <input
                            type="text"
                            className="form-control"
                            value={jobData?.state?.city}
                          />
                        </div>
                        <div className="form-group">
                          <label>State/Provice</label>
                          <input
                            type="text"
                            className="form-control"
                            value={jobData?.state?.province}
                          />
                        </div>
                        <div className="form-group">
                          <label>Subdivision</label>
                          <input
                            type="text"
                            className="form-control"
                            value={jobData?.state?.subdividision}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Lot</label>
                          <input
                            type="text"
                            className="form-control"
                            value={jobData?.state?.lot}
                          />
                        </div>
                        <div className="form-group">
                          <label>Block</label>
                          <input
                            type="text"
                            className="form-control"
                            value={jobData?.state?.block}
                          />
                        </div>
                        <div className="form-group">
                          <label>Plan</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Sale Type</label>
                          <input
                            type="text"
                            className="form-control"
                            value={jobData?.state?.jobType}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <h6 className="Title">Sale Revenues</h6>
                      </div>
                      <div className="NewAccording mb-4 col-12">
                        {interiorCreateCategoryList?.data?.[0]
                          ?.DesignCategoriesJunctions?.length > 0 &&
                          interiorCreateCategoryList?.data?.[0]?.DesignCategoriesJunctions?.map(
                            (data, ind) => {
                              return (
                                <div className="card">
                                  <div
                                    className={
                                      data.select === true
                                        ? "card-header ChangeColorHeader  HeaderGreen mt-2 mb-2"
                                        : "card-header mt-2 mb-2"
                                    }
                                  >
                                    <h4
                                      data-toggle="collapse"
                                      aria-expanded={
                                        openAccordionIndex === ind
                                          ? "true"
                                          : "false"
                                      }
                                      className=""
                                      onClick={() =>
                                        handleAccordionToggle(ind, data.id)
                                      }
                                    >
                                      <span>
                                        <img src="images/down_arrow_white.png" />
                                      </span>

                                      {data?.Category?.name}
                                    </h4>
                                  </div>
                                  <div
                                    id="Estimation"
                                    className={`collapse ${
                                      openAccordionIndex === ind ? "show" : ""
                                    }`}
                                    style={{}}
                                  >
                                    <div className="InteriorSelectionsArea">
                                      <div className="InteriorSelectionsTable mt-4">
                                        <table>
                                          <tbody>
                                            {getOptionByCategoryList
                                              ?.categoryDetails?.length > 0 &&
                                              getOptionByCategoryList?.categoryDetails?.map(
                                                (datas, ind) => {
                                                  return (
                                                    <tr
                                                      className={
                                                        datas.selected
                                                          ? "ChangeColor"
                                                          : "RowGreen"
                                                      }
                                                      key={ind}
                                                    >
                                                      <td>
                                                        <div className="Action">
                                                          <img
                                                            src={handleShowImg(
                                                              datas
                                                            )}
                                                          />
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="Action">
                                                          <h6>Name</h6>
                                                          <p>{datas.name}</p>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="Action">
                                                          <h6>Description</h6>
                                                          <p>
                                                            {datas.description}
                                                          </p>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="Action">
                                                          <h6>Measure</h6>
                                                          <p>
                                                            {datas
                                                              ?.designOptionPriceBookSelections?.[0]
                                                              ?.measure ?? ""}
                                                          </p>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="Action">
                                                          <h6>Per Unit</h6>
                                                          <p>
                                                            $
                                                            {datas
                                                              ?.designOptionPriceBookSelections?.[0]
                                                              ?.perUnit ?? ""}
                                                          </p>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="Action">
                                                          <h6>Quantity</h6>
                                                          <p>
                                                            {datas
                                                              ?.designOptionPriceBookSelections?.[0]
                                                              ?.quantity ?? ""}
                                                          </p>
                                                        </div>
                                                      </td>

                                                      <td>
                                                        <div className="Action">
                                                          <h6>Discount</h6>
                                                          <p>
                                                            {
                                                              datas
                                                                ?.designOptionPriceBookSelections?.[0]
                                                                ?.discount
                                                            }
                                                            %
                                                          </p>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="Action">
                                                          <h6>Price</h6>
                                                          <p>
                                                            $
                                                            {datas
                                                              ?.designOptionPriceBookSelections?.[0]
                                                              ?.clientTotal ??
                                                              ""}
                                                          </p>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="DoorsInterior DarkRed  my-4">
                                        <h5>Category Summary</h5>
                                      </div>
                                      <div className="col-12">
                                        <div className="StatementAdjustmentBottom">
                                          <label>Allowance</label>
                                          <p className="LightPink">
                                            ${data?.allowance}
                                          </p>
                                        </div>
                                        <div className="StatementAdjustmentBottom">
                                          <label>
                                            Total Regular Price (Pre-Tax)
                                          </label>
                                          <p className="LightPink">
                                            ${totalPrice}
                                          </p>
                                        </div>
                                        <div className="StatementAdjustmentBottom">
                                          <label>Total Discount</label>
                                          <p className="LightPink">
                                            ${discount}
                                          </p>
                                        </div>
                                        <div className="StatementAdjustmentBottom">
                                          <label>
                                            Sub Total Price (Pre - Tax)
                                          </label>
                                          <p className="LightPink">
                                            ${totalPrice - discount}
                                          </p>
                                        </div>
                                        <div className="StatementAdjustmentBottom">
                                          <label>Remaining Allowance</label>
                                          <p className="LightPink">
                                            $
                                            {data?.allowance -
                                              totalPrice -
                                              discount}
                                          </p>
                                        </div>
                                        <div className="StatementAdjustmentBottom">
                                          <label>Change Order</label>
                                          <p className="LightPink">
                                            $
                                            {(data?.allowance -
                                              totalPrice -
                                              discount) *
                                              -1}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </div>
                      <div className="col-12">
                        <h6 className="Title mt-5">Summary</h6>
                        <div className="StatementAdjustmentBottom">
                          <label>Total Items Selected</label>
                          <p className="LightBlue1">
                            {interiorAgreementSummary.totalItem}
                          </p>
                        </div>
                        <div className="StatementAdjustmentBottom">
                          <label>Sub Total Regular Price (Pre-Tax)</label>
                          <p className="MediumBlue">
                            ${interiorAgreementSummary.price}
                          </p>
                        </div>
                        <div className="StatementAdjustmentBottom">
                          <label>Total Discount</label>
                          <p className="LightBlue1">
                            ${interiorAgreementSummary.discount}
                          </p>
                        </div>
                        <div className="StatementAdjustmentBottom">
                          <label>Sub Total Price (Pre-Tax)</label>
                          <p className="MediumBlue">
                            ${interiorAgreementSummary.subtotalPrice}
                          </p>
                        </div>
                        <div className="StatementAdjustmentBottom">
                          <label>Total Change Order</label>
                          <p className="DarkBlue">
                            ${interiorAgreementSummary.changeOrder}
                          </p>
                        </div>
                        <div className="StatementAdjustmentBottom">
                          <label>Tax 5%</label>
                          <p className="LightBlue1">
                            ${interiorAgreementSummary.tax}
                          </p>
                        </div>
                        <div className="StatementAdjustmentBottom">
                          <label>Grand Total</label>
                          <p className="DarkBlue">
                            ${interiorAgreementSummary.grandTotal}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ChangeOrderCreatArea mt-4">
                    <div className="row">
                      <div className="col-lg-12"></div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="InputBox">
                          <div className="form-group">
                            <input type="text" />
                            <label>Date</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 offset-2">
                        <div className="InputBox">
                          <div className="form-group">
                            <Button
                              className="mb-4"
                              onClick={handleManageSignatueShow}
                            >
                              signature
                            </Button>
                            {!signature ? (
                              <img
                                src={agreementSignatures?.imageUrl}
                                style={{
                                  border: "1px solid gray",
                                  padding: "5px",
                                  marginBottom: "10px",
                                }}
                              />
                            ) : (
                              <img
                                src={signature}
                                style={{
                                  border: "1px solid gray",
                                  padding: "5px",
                                  marginBottom: "10px",
                                }}
                              />
                            )}

                            {/* <div class="InsertSignature">
                            </div> */}
                            <label>
                              {iBuildLocalData?.user?.name ?? "N/A"}
                            </label>
                            <label>Design Manager</label>
                          </div>
                        </div>
                        <div className="col-md-3 offset-2">
                          <div className="InputBox">
                            <div className="form-group mt-4"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="InputBox">
                          <div className="form-group">
                            <input type="text" />
                            <label>Date</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 offset-2">
                        <div className="InputBox">
                          <div className="form-group">
                            <img
                              src={agreementClient?.imageUrl}
                              style={{
                                border: "1px solid gray",
                                padding: "5px",
                                marginBottom: "10px",
                              }}
                            />
                            <label>{primary?.legalName ?? ""}</label>
                            <label>Client</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button
                    className="Create"
                    onClick={() => {
                      handleConvertAndSend(
                        interiorCreateCategoryList?.data?.[0]?.projectId,
                        interiorCreateCategoryList?.data?.[0]?.id
                      );
                    }}
                  >
                    Send for Client Approval
                  </button>
                  <button className="Create" onClick={() => window.print()}>
                    Print
                  </button>
                  <button className="Create" onClick={() => window.print()}>
                    Download
                  </button>
                  <button
                    className="Outline"
                    onClick={handleDesignSelectionsAggrementInteriorModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <Modal
        show={DesignSelectionsAggrementExteriorModal}
        onHide={handleDesignSelectionsAggrementExteriorModalClose}
        className="ModalBox modal fade LargeModal show "
      >
        <Modal.Body className="">
          <a
            className="CloseModal ClosePermissionId"
            onClick={handleDesignSelectionsAggrementExteriorModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Design Selection Agreement</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="ModalBigheading">
                  <h3>
                    <span className="fw-500">Status: </span>
                    {jobData?.state?.status}
                  </h3>
                </div>
                <div className="AgreementViewArea">
                  <div className="AgreementViewTop">
                    <div className="AgreementViewTopRight">
                      <p
                        dangerouslySetInnerHTML={createAboutMarkup()}
                        className="editor"
                      ></p>
                    </div>
                  </div>
                  <div className="StatementAdjustmentArea px-4">
                    <h6>Design Selections</h6>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Client Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={primaryList?.clientsDetails?.[0]?.legalName}
                          />
                        </div>
                        <div className="form-group">
                          <label>Address</label>
                          <input
                            type="text"
                            className="form-control"
                            value={`${primaryList?.clientsDetails?.[0]?.streetAddress}  ${primaryList?.clientsDetails?.[0]?.addressLineTwo}`}
                          />
                        </div>
                        <div className="form-group">
                          <label>City</label>
                          <input
                            type="text"
                            className="form-control"
                            value={primaryList?.clientsDetails?.[0]?.city}
                          />
                        </div>
                        <div className="form-group">
                          <label>State/Provice</label>
                          <input
                            type="text"
                            className="form-control"
                            value={primaryList?.clientsDetails?.[0]?.province}
                          />
                        </div>
                        <div className="form-group">
                          <label>Possession Date</label>
                          <input
                            type="text"
                            className="form-control"
                            value={primaryList?.clientsDetails?.[0]?.createdAt?.slice(
                              0,
                              10
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Date Prepared</label>
                          <input
                            type="text"
                            className="form-control"
                            value={primaryList?.clientsDetails?.[0]?.createdAt?.slice(
                              0,
                              10
                            )}
                          />
                        </div>
                        <div className="form-group">
                          <label>Job Number</label>
                          <input
                            type="text"
                            className="form-control"
                            value={primaryList?.clientsDetails?.[0]?.JobId}
                          />
                        </div>
                        <div className="form-group">
                          <label>Sales Consultant</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Model</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Contract Date</label>
                          <input
                            type="text"
                            className="form-control"
                            value={primaryList?.clientsDetails?.[0]?.createdAt?.slice(
                              0,
                              10
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <h6 className="Title">JOB DETAILS</h6>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Address</label>
                          <input
                            type="text"
                            className="form-control"
                            value={jobData?.state?.jobAddress}
                          />
                        </div>
                        <div className="form-group">
                          <label>City</label>
                          <input
                            type="text"
                            className="form-control"
                            value={jobData?.state?.city}
                          />
                        </div>
                        <div className="form-group">
                          <label>State/Provice</label>
                          <input
                            type="text"
                            className="form-control"
                            value={jobData?.state?.province}
                          />
                        </div>
                        <div className="form-group">
                          <label>Subdivision</label>
                          <input
                            type="text"
                            className="form-control"
                            value={jobData?.state?.subdividision}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Lot</label>
                          <input
                            type="text"
                            className="form-control"
                            value={jobData?.state?.lot}
                          />
                        </div>
                        <div className="form-group">
                          <label>Block</label>
                          <input
                            type="text"
                            className="form-control"
                            value={jobData?.state?.block}
                          />
                        </div>
                        <div className="form-group">
                          <label>Plan</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Sale Type</label>
                          <input
                            type="text"
                            className="form-control"
                            value={jobData?.state?.jobType}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <h6 className="Title">Sale Revenues</h6>
                      </div>
                      <div className="NewAccording mb-4 col-12">
                        {exteriorCreateCategoryList?.data?.[0]
                          ?.DesignCategoriesJunctions?.length > 0 &&
                          exteriorCreateCategoryList?.data?.[0]?.DesignCategoriesJunctions?.map(
                            (data, ind) => {
                              return (
                                <div className="card">
                                  <div
                                    className={
                                      data.select === true
                                        ? "card-header ChangeColorHeader  HeaderGreen mt-2 mb-2"
                                        : "card-header mt-2 mb-2"
                                    }
                                  >
                                    <h4
                                      data-toggle="collapse"
                                      aria-expanded={
                                        openAccordionIndex === ind
                                          ? "true"
                                          : "false"
                                      }
                                      className=""
                                      onClick={() =>
                                        handleAccordionToggle(ind, data.id)
                                      }
                                    >
                                      <span>
                                        <img src="images/down_arrow_white.png" />
                                      </span>

                                      {data?.Category?.name}
                                    </h4>
                                  </div>
                                  <div
                                    id="Estimation"
                                    className={`collapse ${
                                      openAccordionIndex === ind ? "show" : ""
                                    }`}
                                    style={{}}
                                  >
                                    <div className="InteriorSelectionsArea">
                                      <div className="InteriorSelectionsTable mt-4">
                                        <table>
                                          <tbody>
                                            {getOptionByCategoryList
                                              ?.categoryDetails?.length > 0 &&
                                              getOptionByCategoryList?.categoryDetails?.map(
                                                (datas, ind) => {
                                                  return (
                                                    <tr
                                                      className={
                                                        datas.selected
                                                          ? "ChangeColor"
                                                          : "RowGreen"
                                                      }
                                                      key={ind}
                                                    >
                                                      <td>
                                                        <div className="Action">
                                                          <img
                                                            src={handleShowImg(
                                                              datas
                                                            )}
                                                          />
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="Action">
                                                          <h6>Name</h6>
                                                          <p>{datas.name}</p>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="Action">
                                                          <h6>Description</h6>
                                                          <p>
                                                            {datas.description}
                                                          </p>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="Action">
                                                          <h6>Measure</h6>
                                                          <p>
                                                            {datas
                                                              ?.designOptionPriceBookSelections?.[0]
                                                              ?.measure ?? ""}
                                                          </p>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="Action">
                                                          <h6>Per Unit</h6>
                                                          <p>
                                                            $
                                                            {datas
                                                              ?.designOptionPriceBookSelections?.[0]
                                                              ?.perUnit ?? ""}
                                                          </p>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="Action">
                                                          <h6>Quantity</h6>
                                                          <p>
                                                            {datas
                                                              ?.designOptionPriceBookSelections?.[0]
                                                              ?.quantity ?? ""}
                                                          </p>
                                                        </div>
                                                      </td>

                                                      <td>
                                                        <div className="Action">
                                                          <h6>Discount</h6>
                                                          <p>
                                                            {
                                                              datas
                                                                ?.designOptionPriceBookSelections?.[0]
                                                                ?.discount
                                                            }
                                                            %
                                                          </p>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="Action">
                                                          <h6>Client Total</h6>
                                                          <p>
                                                            $
                                                            {datas
                                                              ?.designOptionPriceBookSelections?.[0]
                                                              ?.clientTotal ??
                                                              ""}
                                                          </p>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="DoorsInterior DarkRed  my-4">
                                        <h5>Category Summary</h5>
                                      </div>
                                      <div className="col-12">
                                        <div className="StatementAdjustmentBottom">
                                          <label>Allowance</label>
                                          <p className="LightPink">
                                            ${data?.allowance}
                                          </p>
                                        </div>
                                        <div className="StatementAdjustmentBottom">
                                          <label>
                                            Total Regular Price (Pre-Tax)
                                          </label>
                                          <p className="LightPink">
                                            ${totalPrice}
                                          </p>
                                        </div>
                                        <div className="StatementAdjustmentBottom">
                                          <label>Total Discount</label>
                                          <p className="LightPink">
                                            ${discount}
                                          </p>
                                        </div>
                                        <div className="StatementAdjustmentBottom">
                                          <label>
                                            Sub Total Price (Pre - Tax)
                                          </label>
                                          <p className="LightPink">
                                            ${totalPrice - discount}
                                          </p>
                                        </div>
                                        <div className="StatementAdjustmentBottom">
                                          <label>Remaining Allowance</label>
                                          <p className="LightPink">
                                            $
                                            {data?.allowance -
                                              totalPrice -
                                              discount}
                                          </p>
                                        </div>
                                        <div className="StatementAdjustmentBottom">
                                          <label>Change Order</label>
                                          <p className="LightPink">
                                            $
                                            {(data?.allowance -
                                              totalPrice -
                                              discount) *
                                              -1}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </div>
                      <div className="col-12">
                        <h6 className="Title mt-5">Summary</h6>
                        <div className="StatementAdjustmentBottom">
                          <label>Total Items Selected</label>
                          <p className="LightBlue1">
                            {ExteriorAgreementSummary.totalItemExterior}
                          </p>
                        </div>
                        <div className="StatementAdjustmentBottom">
                          <label>Sub Total Regular Price (Pre-Tax)</label>
                          <p className="MediumBlue">
                            ${ExteriorAgreementSummary.price}
                          </p>
                        </div>
                        <div className="StatementAdjustmentBottom">
                          <label>Total Discount</label>
                          <p className="LightBlue1">
                            ${ExteriorAgreementSummary.discount}
                          </p>
                        </div>
                        <div className="StatementAdjustmentBottom">
                          <label>Sub Total Price (Pre-Tax)</label>
                          <p className="MediumBlue">
                            ${ExteriorAgreementSummary.subtotalPrice}
                          </p>
                        </div>
                        <div className="StatementAdjustmentBottom">
                          <label>Total Change Order</label>
                          <p className="DarkBlue">
                            ${ExteriorAgreementSummary.changeOrder}
                          </p>
                        </div>
                        <div className="StatementAdjustmentBottom">
                          <label>Tax 5%</label>
                          <p className="LightBlue1">
                            ${ExteriorAgreementSummary.tax}
                          </p>
                        </div>
                        <div className="StatementAdjustmentBottom">
                          <label>Grand Total</label>
                          <p className="DarkBlue">
                            ${ExteriorAgreementSummary.grandTotal}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ChangeOrderCreatArea mt-4">
                    <div className="row">
                      <div className="col-lg-12"></div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="InputBox">
                          <div className="form-group">
                            <input type="text" />
                            <label>Date</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 offset-2">
                        <div className="InputBox">
                          <div className="form-group">
                            <Button
                              className="mb-4"
                              onClick={handleManageSignatueShow}
                            >
                              signature
                            </Button>
                            {!signature ? (
                              <img
                                src={agreementSignatures?.imageUrl}
                                style={{
                                  border: "1px solid gray",
                                  padding: "5px",
                                  marginBottom: "10px",
                                }}
                              />
                            ) : (
                              <img
                                src={signature}
                                style={{
                                  border: "1px solid gray",
                                  padding: "5px",
                                  marginBottom: "10px",
                                }}
                              />
                            )}

                            {/* <div class="InsertSignature">
                            </div> */}
                            <label>
                              {iBuildLocalData?.user?.name ?? "N/A"}
                            </label>
                            <label>Design Manager</label>
                          </div>
                        </div>
                        <div className="col-md-3 offset-2">
                          <div className="InputBox">
                            <div className="form-group mt-4"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="InputBox">
                          <div className="form-group">
                            <input type="text" />
                            <label>Date</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 offset-2">
                        <div className="InputBox">
                          <div className="form-group">
                            <img
                              src={agreementClient?.imageUrl}
                              style={{
                                border: "1px solid gray",
                                padding: "5px",
                                marginBottom: "10px",
                              }}
                            />
                            <label>{primary?.legalName ?? ""}</label>
                            <label>Client</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button
                    className="Create"
                    onClick={() => {
                      handleConvertAndSend(
                        exteriorCreateCategoryList?.data?.[0]?.projectId,
                        exteriorCreateCategoryList?.data?.[0]?.id
                      );
                    }}
                  >
                    Send for Client Approval
                  </button>
                  <button className="Create" onClick={() => window.print()}>
                    Print
                  </button>
                  <button className="Create" onClick={() => window.print()}>
                    Download
                  </button>
                  <button className="Outline">Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={SelectionHistoryModal}
        onHide={handleSelectionHistoryModalClose}
        className="ModalBox modal fade MediumModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleSelectionHistoryModalClose}
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Selection History</h4>
            </div>
            <div class="ModalMediumheading">
              <h3>
                <span>Job:</span>
                {jobType}
              </h3>
              <h3>
                <span>Selection Type:</span>
                {selectionType}
              </h3>
            </div>

            <div class="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Modified By</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {selectionHistoryList?.data?.length > 0 ? (
                    selectionHistoryList?.data?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.modifyBy ?? "N/A"}</td>
                          <td>{item.createdBy ?? "N/A"}</td>
                          <td>{moment(item.createdAt).format("YYYY-MM-DD")}</td>
                          <td>{item.activity ?? "N/A"}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-center">
                        <p>No Data found.</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div class="Button mt-5 text-center">
              <button
                class="Outline"
                onClick={handleSelectionHistoryModalClose}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ManageSignatueModal}
        onHide={handleManageSignatueClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a className="CloseModal" onClick={handleManageSignatueClose}>
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Manage Signatures</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <div className="CommonModalArea">
                  <h5>Add new signature</h5>
                </div>
              </div>
              <div className="form-group">
                <div className="CommonModalArea border p-2">
                  <h5>Signatures</h5>
                  <div className="CommonModalBox">
                    <SignaturePad
                      ref={signCanvas}
                      penColor="green"
                      canvasProps={{
                        width: 400,
                        height: 300,
                        className: "sigCanvas",
                      }}
                      onEnd={() =>
                        updateState({
                          ...iState,
                          signature: signCanvas?.current
                            ?.getTrimmedCanvas()
                            .toDataURL("image/png"),
                        })
                      }
                    />
                    <a onClick={clearSignature}>
                      <img src="images/circle_minus.png" />
                    </a>
                    {/* <a onClick={saveSignature} href="javascript:void(0);">
                        Save
                      </a> */}
                  </div>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                    {errors?.signatureError}
                  </span> */}
                </div>
              </div>

              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={addSignatureFun}>
                  Save
                </button>
                <button className="Outline" onClick={handleManageSignatueClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={DeadlineModal}
        onHide={handleDeadlineModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a className="CloseModal" onClick={handleDeadlineModalClose}>
            ×
          </a>

    <div className="FormArea">
      <div className="ModalTitleBox">
        <h4>Set Deadline</h4>
      </div>
      <div className="FolderForm">
        <div className="ModalMediumheading">
          <h3>
            <span>Category:</span> {cateData?.Category?.name??"N/A"}
          </h3>
        </div>
        <div className="form-group  d-flex justify-content-between">
          <label className="Radio">
            Choose Date
            <input
              type="radio"
              name="Setdeadline"
              value="text"
              checked={selecetedDate === "text"}
              onChange={handleDateOptionChange}
            />
            <span className="checkmark" />
          </label>
          <label className="Radio">
            Link to Schedule Item
            <input
              type="radio"
              name="Setdeadline"
              value="file"
              checked={selecetedDate === "file"}
              onChange={handleDateOptionChange}
            />
            <span className="checkmark" />
          </label>
        </div>

        {selecetedDate === "text" && (
          <div className="text RadioText">
            <div className="form-group">
              <h6>Date</h6>
              <input
                type="date"
                className="form-control"
                placeholder="Select amount of days for reminder"
                value={deadline}
                onChange={(e)=>setDeadline(e.target.value)}
              />
            </div>
          </div>
        )}

        {selecetedDate === "file" && (
          <div className="file RadioText">
            <div className="form-group">
              <h6>Schedule Task*</h6>
              <input
                type="date"
                className="form-control"
                placeholder="Select Schedule Task"
                value={deadline}
                onChange={(e)=>setDeadline(e.target.value)}
              />
            </div>
            <div className="form-group">
              <h6>Due</h6>
              <div className="d-flex align-items-center justify-content-center">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Number of Days"
                  value={days}
                  onChange={(e)=>setDays(e.target.value)}
                  style={{ width: 150 }}
                />
                <p>Days</p>
                <div className="d-flex justify-content-between ml-5">
                  <div className="mr-5">
                    <label className="Radio">
                      Before
                      <input type="radio" 
                      name="beforeafter2" 
                      value="before"
                      checked={beforeAfterDate==="before"}
                      onChange={handleBeforeAfterChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div>
                    <label className="Radio ml-5">
                      After
                      <input type="radio" 
                      name="beforeafter2"
                      value="after"
                      checked={beforeAfterDate==="after"}
                      onChange={handleBeforeAfterChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <h6>Deadline</h6>
              <h6 className="fw-400">
                {calculateAdjustedDeadline(deadline,days,beforeAfterDate)}
                </h6>
            </div>
          </div>
        )}

        <div className="Button d-flex justify-content-between">
          <button className="Create mr-5" onClick={handleUpdateDeadline}>
            Set Deadline
          </button>
          <button className="Outline ml-5" onClick={handleDeadlineModalClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
 


         
        </Modal.Body>
      </Modal>
      <Modal
        show={SendforClientSignatureModal}
        onHide={handleSendforClientSignatureModalClose}
        className="ModalBox modal fade LargeModal ExtraLargeModalGreen show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleSendforClientSignatureModalClose}
          >
            ×
          </a>
          <div class="ModalTitleBox">
              <h4>Send for Client Signature</h4>
            </div>
          <div className="FolderForm">
            <div className="AgreementViewArea mt-5">
              <div className="AgreementViewTop">
                <div className="AgreementViewTopRight">
                  <p
                    dangerouslySetInnerHTML={createAboutHeader()}
                    className="editor"
                  ></p>
                </div>
              </div>
            
              <div className="ChangeOrderCreatArea mt-5">
                {primarySignStatus && (
                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="date" />
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ml-auto">
                      <div className="InputBox">
                        <div className="form-group">
                          <div
                            className="InsertSignature"
                            onClick={() =>
                              handleManageSignatuesShow("primarySignature")
                            }
                          >
                            {primarySignature && 
                            <img src={primarySignature} />
                            }
                            {
                              !primarySignature&&
                              <a>
                              <img src="images/insert_signature.png" />
                              Insert Signature
                            </a>
                            }
                            
                            
                          </div>
                          <label>{primarySignStatus}</label>
                          <label>Primary Signee</label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {secondarySignStatus && (
                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="date" />
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ml-auto">
                      <div className="InputBox">
                        <div className="form-group">
                          <div
                            className="InsertSignature"
                            onClick={() =>
                              handleManageSignatuesShow("secondarySignature")
                            }
                          >
                            {secondarySignature && (
                              <>
                              <img src={secondarySignature} />
                             
                            </>
                            )}
                              {
                              !secondarySignature&&
                              <a>
                              <img src="images/insert_signature.png" />
                              Insert Signature
                            </a>
                            }
                           
                          </div>
                          <label>{secondarySignStatus}</label>
                          <label>Secondary Signee</label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {additionalSignStatus && (
                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="date" />
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ml-auto">
                      <div className="InputBox">
                        <div className="form-group">
                          <div
                            className="InsertSignature"
                            onClick={() =>
                              handleManageSignatuesShow("additionalSignature")
                            }
                          >
                            {additionalSignature && (
                              <>
                              <img src={additionalSignature} />
                             </>
                            )}
                             {
                              additionalSignature==null&&
                              <a>
                              <img src="images/insert_signature.png" />
                              Insert Signature
                            </a>
                            }
                           
                          </div>
                          <label>{additionalSignStatus}</label>
                          <label>Additional Signee</label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {builderSignStatus && (
                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="date" />
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ml-auto">
                      <div className="InputBox">
                        <div className="form-group">
                          <div
                            className="InsertSignature"
                            onClick={() =>
                              handleManageSignatuesShow("builderSignature")
                            }
                          >
                            {builderSignature && 
                            <>
                            <img src={builderSignature} />
                           
                          </>
                            }
                             {
                              !builderSignature&&
                              <a>
                              <img src="images/insert_signature.png" />
                              Insert Signature
                            </a>
                            }
                            
                          </div>
                          <label>{builderSignStatus}</label>
                          <label>Builder Signee</label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="Button d-flex justify-content-between">
              <button
                className="Create"
                onClick={() => handleSendToClient("save")}
              >
                Save Draft
              </button>
              <button
                className="Create"
                onClick={() => handleSendToClient("send")}
              >
                Save &amp; Send to Client
              </button>
              <button
                className="Outline"
                onClick={handleSendforClientSignatureModalClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ManageSignatures}
        onHide={handleManageSignatuesClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a className="CloseModal" onClick={handleManageSignatueClose}>
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Manage Signatures</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <div className="CommonModalArea">
                  <h5>Add new signature</h5>
                </div>
              </div>
              <div className="form-group">
                <div className="CommonModalArea border p-2">
                  <h5>Signatures</h5>
                  <div className="CommonModalBox">
                    <SignaturePad
                      ref={signCanvas}
                      penColor="green"
                      canvasProps={{
                        width: 400,
                        height: 300,
                        className: "sigCanvas",
                      }}
                      onEnd={() =>
                        updateState({
                          ...iState,
                          signature: signCanvas?.current
                            ?.getTrimmedCanvas()
                            .toDataURL("image/png"),
                        })
                      }
                    />
                    <a onClick={clearSignature}>
                      <img src="images/circle_minus.png" />
                    </a>
                    {/* <a onClick={saveSignature} href="javascript:void(0);">
                        Save
                      </a> */}
                  </div>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                    {errors?.signatureError}
                  </span> */}
                </div>
              </div>

              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={addSignature}>
                  Save
                </button>
                <button className="Outline" onClick={handleManageSignatuesClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
          show={JobMenuModal}
          onHide={handleJobMenuModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleJobMenuModalClose}
            >
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Job Menu</h4>
              </div>
              <div className="FolderForm">
                <div className="JobMenuListing">
                  <ul>
                    <li>
                      <a href="javascript:void(0);">
                        <figure>
                          <img src="images/add_build.png" />
                        </figure>
                        Add to/Edit Build
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <figure>
                          <img src="images/details.png" />
                        </figure>
                        Details
                      </a>
                    </li>
                    <li className="JobMenuListing_All">
                      <Link to="/change-order-list" state={state}>
                        <figure>
                          <img src="images/change_order.png" />
                        </figure>
                        Change Order
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                      <ul className="JobMenuListingUnder">
                        <li>
                          <Link to="/change-order-list" state={state}>
                            Change orders List
                          </Link>
                        </li>
                        <li>
                          <Link to="/change-order-list" state={state}>
                            Change to Contract (CTC) master
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/warranty-dashboard" state={state}>
                        <figure>
                          <img src="images/design.png" />
                        </figure>
                        Warranty
                      </Link>
                    </li>
                    <li>
                      <Link to="/sales" state={state}>
                        <figure>
                          <img src="images/sales.png" />
                        </figure>
                        Sales
                      </Link>
                    </li>
                    <li>
                      <Link to="/estimation" state={state}>
                        <figure>
                          <img src="images/estimation.png" />
                        </figure>
                        Estimation
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="/construction" state={state}>
                        <figure>
                          <img src="images/construction_icon.png" />
                        </figure>
                        Construction
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                    </li>
                    {jobData?.pathname !== "/design-selections" && (
                      <li>
                        <Link to="/design-selections" state={state}>
                          <figure>
                            <img src="images/warranty.png" />
                          </figure>
                          Design-Selections
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link to="/reports" state={state}>
                        <figure>
                          <img src="images/reports.png" />
                        </figure>
                        Reports
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                    </li>

                    <li>
                      <Link to="/client-view-config" state={state}>
                        <figure>
                          <img src="images/view_config.png" />
                        </figure>
                        Views Config
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={NavigateModal}
          onHide={handleNavigateModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleNavigateModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Navigate Project</h4>
              </div>
              <div className="FolderForm">
                <div className="NaviagateProjectArea">
                  <ul>
                    {allProjectList?.projects?.length > 0 &&
                      allProjectList?.projects.map((item, index) => {
                        return (
                          <>
                            <li className="DarkBlueList AvalonTownsTab">
                              <a href="javascript:void(0);">
                                {item.projectName}
                              </a>
                            </li>
                            <li className="LightBlueList AdministrationTab">
                              <a href="javascript:void(0);">
                                {item.jobPattern}
                              </a>
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu dropdown-menuwidth DropDownMenuZindex">
                                      <li>
                                        <a>
                                          <img src="images/add_build.png" />
                                          Add to/Edit Build
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <img src="images/details.png" />
                                          Details
                                        </a>
                                      </li>
                                      <li className="border-0">
                                        <Link
                                          to="/change-order-list"
                                          state={state}
                                          className="dropdown-item"
                                          style={{
                                            border:
                                              "1px solid transparent!important",
                                          }}
                                        >
                                          <img src="images/change_order.png" />
                                          Change Order &nbsp;&nbsp;
                                          <img src="images/rightarrow2.png" />
                                        </Link>
                                        <ul className="dropdown-menu submenu">
                                          <li>
                                            <Link
                                              to="/change-order-list"
                                              state={state}
                                              className="dropdown-item"
                                            >
                                              Change orders List
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              to="/change-order-list"
                                              state={state}
                                              className="dropdown-item"
                                            >
                                              Change to Contract (CTC) master
                                            </Link>
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <Link
                                          to="/design-selections"
                                          state={state}
                                        >
                                          <img src="images/design.png" />
                                          Design
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/sales" state={state}>
                                          <img src="images/sales.png" />
                                          Sales
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/estimation" state={state}>
                                          <img src="images/estimation.png" />
                                          Estimation
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/construction" state={state}>
                                          <img src="images/construction_icon.png" />
                                          Construction
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to="/warranty-dashboard"
                                          state={state}
                                        >
                                          <img src="images/warranty.png" />
                                          Warranty
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/reports" state={state}>
                                          <img src="images/reports.png" />
                                          Reports
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to="/client-view-config"
                                          state={state}
                                        >
                                          <img src="images/view_config.png" />
                                          Views Config
                                        </Link>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
    </div>
  );
};

export default DesignSelections;
