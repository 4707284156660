import { constructionConstant } from "../actionTypes";

import axios from "axios";
import { Url } from "../../Config/Config";
import { isLoggedIn } from "../../utils/tokenCheck";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));


export const addPhaseInitiate = () => ({
  type: constructionConstant.ADD_PHASE_INITIATE,
});

export const addPhaseSuccess = (data) => ({
  type: constructionConstant.ADD_PHASE_SUCCESS,
  payload: data,
});

export const addPhaseFailure = (data) => ({
  type: constructionConstant.ADD_PHASE_FAILURE,
  payload: data,
});

export const AddPhaseAction = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(addPhaseInitiate(payload));
    const response = await axios.post(`${Url}createPhase`,payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status == 201) {
      dispatch(addPhaseSuccess(response.data));
    } else {
      dispatch(addPhaseFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(addPhaseFailure(err));
    return err.response;
  }
};

export const getPhaseInitiate = () => ({
  type: constructionConstant.GET_ALL_PHASE_INITIATE,
});

export const getPhaseSuccess = (data) => ({
  type: constructionConstant.GET_ALL_PHASE_SUCCESS,
  payload: data,
});

export const getPhaseFailure = (data) => ({
  type: constructionConstant.GET_ALL_PHASE_FAILURE,
  payload: data,
});

export const GetPhaseAction = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(getPhaseInitiate());
    const response = await axios.post(
      `${Url}construction/getAllPhase`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 201) {

      dispatch(getPhaseSuccess(response.data));
    } else {
      dispatch(getPhaseFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getPhaseFailure(err));
    return err.response;
  }
};



export const deletePhaseInitiate = () => ({
  type: constructionConstant.CREATE_TASK_INITIATE,
});

export const deletePhaseSuccess = (data) => ({
  type: constructionConstant.CREATE_TASK_SUCCESS,
  payload: data,
});

export const deletePhaseFailure = (data) => ({
  type: constructionConstant.CREATE_TASK_FAILURE,
  payload: data,
});

export const DeletePhaseAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(deleteTaskInitiate());
    const response = await axios.patch(
      `${Url}construction/deletePhase`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(deletePhaseSuccess(response.data));
    } else {
      dispatch(deletePhaseFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(deletePhaseFailure(err));
    return err.response;
  }
};


export const getProjectNameInitiate = () => ({
  type: constructionConstant.GET_PROJECT_NAME_INITIATE,
});

export const getProjectNameSuccess = (data) => ({
  type: constructionConstant.GET_PROJECT_NAME_SUCCESS,
  payload: data,
});

export const getProjectNameFailure = (data) => ({
  type: constructionConstant.GET_PROJECT_NAME_FAILURE,
  payload: data,
});

export const GetProjectNameAction = (CompanyId,projectId) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(getProjectNameInitiate());
    const response = await axios.get(`${Url}companies/${CompanyId}/projects/${projectId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status == 200) {
      dispatch(getProjectNameSuccess(response.data));
    } else {
      dispatch(getProjectNameFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getProjectNameFailure(err));
    return err.response;
  }
};

export const getJobDetailsInitiate = () => ({
  type: constructionConstant.GET_JOB_DETAILS_INITIATE,
});

export const getJobDetailsSuccess = (data) => ({
  type: constructionConstant.GET_JOB_DETAILS_SUCCESS,
  payload: data,
});

export const getJobDetailsFailure = (data) => ({
  type: constructionConstant.GET_JOB_DETAILS_FAILURE,
  payload: data,
});

export const GetJobDetailsAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(getJobDetailsInitiate());
    const response = await axios.get(`${Url}construction/getJob`, {
      headers: { Authorization: `Bearer ${token}` },
      params: payload,
    });
    if (response.status == 200) {
      dispatch(getJobDetailsSuccess(response.data));
    } else {
      dispatch(getJobDetailsFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getJobDetailsFailure(err));
    return err.response;
  }
};

export const getAllJobsInitiate = () => ({
  type: constructionConstant.GET_ALL_JOBS_INITIATE,
});

export const getAllJobsSuccess = (data) => ({
  type: constructionConstant.GET_ALL_JOBS_SUCCESS,
  payload: data,
});

export const getAllJobsFailure = (data) => ({
  type: constructionConstant.GET_ALL_JOBS_FAILURE,
  payload: data,
});

export const GetAllJobsAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(getAllJobsInitiate());
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/jobs`, {
      headers: { Authorization: `Bearer ${token}` },
      params: payload,
    });
    if (response.status == 200) {
      dispatch(getAllJobsSuccess(response.data));
    } else {
      dispatch(getAllJobsFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getAllJobsFailure(err));
    return err.response;
  }
};

export const updateJobInitiate = () => ({
  type: constructionConstant.UPDATE_JOB_INITIATE,
});

export const updateJobSuccess = (data) => ({
  type: constructionConstant.UPDATE_JOB_SUCCESS,
  payload: data,
});

export const updateJobFailure = (data) => ({
  type: constructionConstant.UPDATE_JOB_FAILURE,
  payload: data,
});

export const UpdateJobAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(updateJobInitiate());
    const response = await axios.put(
      `${Url}construction/createTask`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(updateJobSuccess(response.data));
    } else {
      dispatch(updateJobFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(updateJobFailure(err));
    return err.response;
  }
};

export const findAllTaskInitiate = () => ({
  type: constructionConstant.FIND_ALL_TASK_INITIATE,
});

export const findAllTaskSuccess = (data) => ({
  type: constructionConstant.FIND_ALL_TASK_SUCCESS,
  payload: data,
});

export const findAllTaskFailure = (data) => ({
  type: constructionConstant.FIND_ALL_TASK_FAILURE,
  payload: data,
});

export const FindAllTaskAction = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(findAllTaskInitiate());
    const response = await axios.get(
      `${Url}construction/getAllTask`,{
      headers: { Authorization: `Bearer ${token}` },
      params: payload}
    );
    if (response.status == 201) {

      dispatch(findAllTaskSuccess(response.data));
    } else {
      dispatch(findAllTaskFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(findAllTaskFailure(err));
    return err.response;
  }
};


export const addTaskInitiate = () => ({
  type: constructionConstant.CREATE_TASK_INITIATE,
});

export const addTaskSuccess = (data) => ({
  type: constructionConstant.CREATE_TASK_SUCCESS,
  payload: data,
});

export const addTaskFailure = (data) => ({
  type: constructionConstant.CREATE_TASK_FAILURE,
  payload: data,
});

export const AddTaskAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(addTaskInitiate());
    const response = await axios.post(
      `${Url}construction/createTask`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(addTaskSuccess(response.data));
    } else {
      dispatch(addTaskFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(addTaskFailure(err));
    return err.response;
  }
};



export const deleteTaskInitiate = () => ({
  type: constructionConstant.CREATE_TASK_INITIATE,
});

export const deleteTaskSuccess = (data) => ({
  type: constructionConstant.CREATE_TASK_SUCCESS,
  payload: data,
});

export const deleteTaskFailure = (data) => ({
  type: constructionConstant.CREATE_TASK_FAILURE,
  payload: data,
});

export const DeleteTaskAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(deleteTaskInitiate());
    const response = await axios.patch(
      `${Url}construction/deleteTask`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(deleteTaskSuccess(response.data));
    } else {
      dispatch(deleteTaskFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(deleteTaskFailure(err));
    return err.response;
  }
};


export const getVendorInitiate = () => ({
  type: constructionConstant.GET_VENDOR_LIST_INITIATE,
});

export const getVendorSuccess = (data) => ({
  type: constructionConstant.GET_VENDOR_LIST_SUCCESS,
  payload: data,
});

export const getVendorFailure = (data) => ({
  type: constructionConstant.GET_VENDOR_LIST_FAILURE,
  payload: data,
});

export const GetVendorAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(getVendorInitiate());
    const response = await axios.get(`${Url}construction/vendorList`, {
      headers: { Authorization: `Bearer ${token}` },
      params: payload,
    });
    if (response.status == 200) {
      dispatch(getVendorSuccess(response.data));
    } else {
      dispatch(getVendorFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getVendorFailure(err));
    return err.response;
  }
};

export const getAccountInitiate = () => ({
  type: constructionConstant.GET_ACCOUNT_LIST_INITIATE,
});

export const getAccountSuccess = (data) => ({
  type: constructionConstant.GET_ACCOUNT_LIST_SUCCESS,
  payload: data,
});

export const getAccountFailure = (data) => ({
  type: constructionConstant.GET_ACCOUNT_LIST_FAILURE,
  payload: data,
});

export const GetAccountAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(getAccountInitiate());
    const response = await axios.get(`${Url}construction/accountList`, {
      headers: { Authorization: `Bearer ${token}` },
      params: payload,
    });
    if (response.status == 200) {
      dispatch(getAccountSuccess(response.data));
    } else {
      dispatch(getAccountFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getAccountFailure(err));
    return err.response;
  }
};

export const getSystemPhaseInitiate = () => ({
  type: constructionConstant.SYSTEM_PHASE_INITIATE,
});

export const getSystemPhaseSuccess = (data) => ({
  type: constructionConstant.SYSTEM_PHASE_SUCCESS,
  payload: data,
});

export const getSystemPhaseFailure = (data) => ({
  type: constructionConstant.SYSTEM_PHASE_FAILURE,
  payload: data,
});

export const GetSystemPhaseAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(getSystemPhaseInitiate());
    const response = await axios.get(`${Url}construction/systemPhase`, {
      headers: { Authorization: `Bearer ${token}` },
      params: payload,
    });
    if (response.status == 200) {
      dispatch(getSystemPhaseSuccess(response.data));
    } else {
      dispatch(getSystemPhaseFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getSystemPhaseFailure(err));
    return err.response;
  }
};

export const defaultSystemInitiate = () => ({
  type: constructionConstant.DEFAULT_SYSTEM_INITIATE,
});

export const defaultSystemSuccess = (data) => ({
  type: constructionConstant.DEFAULT_SYSTEM_SUCCESS,
  payload: data,
});

export const defaultSystemFailure = (data) => ({
  type: constructionConstant.DEFAULT_SYSTEM_FAILURE,
  payload: data,
});

export const DefaultSystemAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(defaultSystemInitiate());
    const response = await axios.post(
      `${Url}construction/uploadDefault`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(defaultSystemSuccess(response.data));
    } else {
      dispatch(defaultSystemFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(defaultSystemFailure(err));
    return err.response;
  }
};

export const calendarMonthlyDataInitiate = () => ({
  type: constructionConstant.CALENDER_DATA_MONTHLY_INITIATE,
});

export const calendarMonthlyDataSuccess = (data) => ({
  type: constructionConstant.CALENDER_DATA_MONTHLY_SUCCESS,
  payload: data,
});

export const calendarMonthlyDataFailure = (data) => ({
  type: constructionConstant.CALENDER_DATA_MONTHLY_FAILURE,
  payload: data,
});

export const CalendarMonthlyDataAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(calendarMonthlyDataInitiate());
    const response = await axios.post(
      `${Url}construction/uploadDefault`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(calendarMonthlyDataSuccess(response.data));
    } else {
      dispatch(calendarMonthlyDataFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(calendarMonthlyDataFailure(err));
    return err.response;
  }
};

export const calendarWeeklyDataInitiate = () => ({
  type: constructionConstant.CALENDER_DATA_MONTHLY_INITIATE,
});

export const calendarWeeklyDataSuccess = (data) => ({
  type: constructionConstant.CALENDER_DATA_MONTHLY_SUCCESS,
  payload: data,
});

export const calendarWeeklyDataFailure = (data) => ({
  type: constructionConstant.CALENDER_DATA_MONTHLY_FAILURE,
  payload: data,
});

export const CalendarWeeklyDataAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(calendarWeeklyDataInitiate());
    const response = await axios.post(
      `${Url}construction/calenderDataWeekly`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(calendarWeeklyDataSuccess(response.data));
    } else {
      dispatch(calendarWeeklyDataFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(calendarWeeklyDataFailure(err));
    return err.response;
  }
};

export const calendardailyDataInitiate = () => ({
  type: constructionConstant.CALENDER_DATA_DAILY_INITIATE,
});

export const calendardailyDataSuccess = (data) => ({
  type: constructionConstant.CALENDER_DATA_DAILY_SUCCESS,
  payload: data,
});

export const calendardailyDataFailure = (data) => ({
  type: constructionConstant.CALENDER_DATA_DAILY_FAILURE,
  payload: data,
});

export const CalendardailyDataAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(calendardailyDataInitiate());
    const response = await axios.post(
      `${Url}construction/calenderDataDay`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(calendardailyDataSuccess(response.data));
    } else {
      dispatch(calendardailyDataFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(calendardailyDataFailure(err));
    return err.response;
  }
};

export const calendarTodayDataInitiate = () => ({
  type: constructionConstant.CALENDER_DATA_TODAY_INITIATE,
});

export const calendarTodayDataSuccess = (data) => ({
  type: constructionConstant.CALENDER_DATA_TODAY_SUCCESS,
  payload: data,
});

export const calendarTodayDataFailure = (data) => ({
  type: constructionConstant.CALENDER_DATA_TODAY_FAILURE,
  payload: data,
});

export const CalendarTodayDataAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(calendarTodayDataInitiate());
    const response = await axios.post(
      `${Url}construction/calenderDataToday`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(calendarTodayDataSuccess(response.data));
    } else {
      dispatch(calendarTodayDataFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(calendarTodayDataFailure(err));
    return err.response;
  }
};

export const addDiscussionInitiate = () => ({
  type: constructionConstant.ADD_DISCUSSION_INITIATE,
});

export const addDiscussionSuccess = (data) => ({
  type: constructionConstant.ADD_DISCUSSION_SUCCESS,
  payload: data,
});

export const addDiscussionFailure = (data) => ({
  type: constructionConstant.ADD_DISCUSSION_FAILURE,
  payload: data,
});

export const AddDiscussionAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(addDiscussionInitiate());
    const response = await axios.post(
      `${Url}construction/createDiscussion`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(addDiscussionSuccess(response.data));
    } else {
      dispatch(addDiscussionFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(addDiscussionFailure(err));
    return err.response;
  }
};

export const updateDiscussionInitiate = () => ({
  type: constructionConstant.UPDATE_DISCUSSION_INITIATE,
});

export const updateDiscussionSuccess = (data) => ({
  type: constructionConstant.UPDATE_DISCUSSION_SUCCESS,
  payload: data,
});

export const updateDiscussionFailure = (data) => ({
  type: constructionConstant.UPDATE_DISCUSSION_FAILURE,
  payload: data,
});

export const UpdateDiscussionAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(updateDiscussionInitiate());
    const response = await axios.post(
      `${Url}construction/createDiscussion`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(updateDiscussionSuccess(response.data));
    } else {
      dispatch(updateDiscussionFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(updateDiscussionFailure(err));
    return err.response;
  }
};

export const DeleteDiscussionInitiate = () => ({
  type: constructionConstant.DELETE_DISCUSSION_INITIATE,
});

export const DeleteDiscussionSuccess = (data) => ({
  type: constructionConstant.DELETE_DISCUSSION_SUCCESS,
  payload: data,
});

export const DeleteDiscussionFailure = (data) => ({
  type: constructionConstant.DELETE_DISCUSSION_FAILURE,
  payload: data,
});

export const DeleteDiscussionAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(DeleteDiscussionInitiate());
    const response = await axios.delete(
      `${Url}construction/deleteDiscussion`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: payload,
      }
    );
    if (response.status == 200) {
      dispatch(DeleteDiscussionSuccess(response.data));
    } else {
      dispatch(DeleteDiscussionFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(DeleteDiscussionFailure(err));
    return err.response;
  }
};

export const discussionListInitiate = () => ({
  type: constructionConstant.DISCUSSION_LIST_INITIATE,
});

export const discussionListSuccess = (data) => ({
  type: constructionConstant.DISCUSSION_LIST_SUCCESS,
  payload: data,
});

export const discussionListFailure = (data) => ({
  type: constructionConstant.DISCUSSION_LIST_FAILURE,
  payload: data,
});

export const discussionListAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(discussionListInitiate());
    const response = await axios.get(`${Url}construction/discussionList`, {
      headers: { Authorization: `Bearer ${token}` },
      params: payload,
    });
    if (response.status == 201) {
      dispatch(discussionListSuccess(response.data));
    } else {
      dispatch(discussionListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(discussionListFailure(err));
    return err.response;
  }
};

export const addReplyInitiate = () => ({
  type: constructionConstant.ADD_REPLY_INITIATE,
});

export const addReplySuccess = (data) => ({
  type: constructionConstant.ADD_REPLY_SUCCESS,
  payload: data,
});

export const addReplyFailure = (data) => ({
  type: constructionConstant.ADD_REPLY_FAILURE,
  payload: data,
});

export const AddReplyAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(addReplyInitiate());
    const response = await axios.post(
      `${Url}construction/replyDiscussion`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(addReplySuccess(response.data));
    } else {
      dispatch(addReplyFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(addReplyFailure(err));
    return err.response;
  }
};

export const updateReplyInitiate = () => ({
  type: constructionConstant.UPDATE_REPLY_INITIATE,
});

export const updateReplySuccess = (data) => ({
  type: constructionConstant.UPDATE_REPLY_SUCCESS,
  payload: data,
});

export const updateReplyFailure = (data) => ({
  type: constructionConstant.UPDATE_REPLY_FAILURE,
  payload: data,
});

export const UpdateReplyAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(updateReplyInitiate());
    const response = await axios.post(
      `${Url}construction/editReplyDiscussion`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(updateReplySuccess(response.data));
    } else {
      dispatch(updateReplyFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(updateReplyFailure(err));
    return err.response;
  }
};

export const DeleteReplyInitiate = () => ({
  type: constructionConstant.SYSTEM_PHASE_INITIATE,
});

export const DeleteReplySuccess = (data) => ({
  type: constructionConstant.SYSTEM_PHASE_SUCCESS,
  payload: data,
});

export const DeleteReplyFailure = (data) => ({
  type: constructionConstant.SYSTEM_PHASE_FAILURE,
  payload: data,
});

export const DeleteReplyAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(DeleteReplyInitiate());
    const response = await axios.delete(
      `${Url}construction/deleteReplyDiscussion`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: payload,
      }
    );
    if (response.status == 200) {
      dispatch(DeleteReplySuccess(response.data));
    } else {
      dispatch(DeleteReplyFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(DeleteReplyFailure(err));
    return err.response;
  }
};


export const replyListInitiate = () => ({
  type: constructionConstant.REPLY_LIST_INITIATE,
});

export const replyListSuccess = (data) => ({
  type: constructionConstant.REPLY_LIST_SUCCESS,
  payload: data,
});

export const replyListFailure = (data) => ({
  type: constructionConstant.REPLY_LIST_FAILURE,
  payload: data,
});

export const replyListAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(replyListInitiate());
    const response = await axios.delete(`${Url}construction/replyDiscussionList`, {
      headers: { Authorization: `Bearer ${token}` },
      params: payload,
    });
    if (response.status == 200) {
      dispatch(replyListSuccess(response.data));
    } else {
      dispatch(replyListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(replyListFailure(err));
    return err.response;
  }
};



export const BaselineInitiate = () => ({
  type: constructionConstant.BASE_LINE_INITIATE,
});

export const BaselineSuccess = (data) => ({
  type: constructionConstant.BASE_LINE_SUCCESS,
  payload: data,
});

export const BaselineFailure = (data) => ({
  type: constructionConstant.BASE_LINE_FAILURE,
  payload: data,
});

export const BaselineAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(BaselineInitiate());
    const response = await axios.get(`${Url}construction/phaseListOfJob/${payload}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status == 201) {
      dispatch(BaselineSuccess(response.data));
    } else {
      dispatch(BaselineFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(BaselineFailure(err));
    return err.response;
  }
};




export const getChangeOrderCheckListInitiate = () => ({
  type: constructionConstant.CHANGE_ORDER_CHECK_LIST_INITIATE,
});

export const getChangeOrderCheckListSuccess = (data) => ({
  type: constructionConstant.CHANGE_ORDER_CHECK_LIST_SUCCESS,
  payload: data,
});

export const getChangeOrderCheckListFailure = (data) => ({
  type: constructionConstant.CHANGE_ORDER_CHECK_LIST_FAILURE,
  payload: data,
});

export const getChangeOrderCheckListAction = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(getChangeOrderCheckListInitiate());
    const response = await axios.get(`${Url}change-order/construction/checklist/${iBuildLocalData?.user?.CompanyId}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: payload,
    });
    if (response.status == 200) {
      dispatch(getChangeOrderCheckListSuccess(response.data));
    } else {
      dispatch(getChangeOrderCheckListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getChangeOrderCheckListFailure(err));
    return err.response;
  }
};





export const addGanttInitiate = () => ({
  type: constructionConstant.ADD_PHASE_INITIATE,
});

export const addGanttSuccess = (data) => ({
  type: constructionConstant.ADD_PHASE_SUCCESS,
  payload: data,
});

export const addGanttFailure = (data) => ({
  type: constructionConstant.ADD_PHASE_FAILURE,
  payload: data,
});

export const AddGanttAction = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(addGanttInitiate(payload));
    const response = await axios.post(`${Url}construction/createPhaseGant`,payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status == 201) {
      dispatch(addGanttSuccess(response.data));
    } else {
      dispatch(addGanttFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(addGanttFailure(err));
    return err.response;
  }
};



export const getGanttDataInitiate = () => ({
  type: constructionConstant.GANTT_DATA_INITIATE,
});

export const getGanttDataSuccess = (data) => ({
  type: constructionConstant.GANTT_DATA_SUCCESS,
  payload: data,
});

export const getGanttDataFailure = (data) => ({
  type: constructionConstant.GANTT_DATA_FAILURE,
  payload: data,
});

export const getGanttDataAction = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(getPhaseInitiate());
    const response = await axios.post(
      `${Url}construction/findAllGant`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 201) {

      dispatch(getGanttDataSuccess(response.data));
    } else {
      dispatch(getGanttDataFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getGanttDataFailure(err));
    return err.response;
  }
};




export const getAllPhaseListInitiate = () => ({
  type: constructionConstant.GET_ALL_PHASE_INITIATE,
});

export const getAllPhaseListSuccess = (data) => ({
  type: constructionConstant.GET_ALL_PHASE_SUCCESS,
  payload: data,
});

export const getAllPhaseListFailure = (data) => ({
  type: constructionConstant.GET_ALL_PHASE_FAILURE,
  payload: data,
});

export const getAllPhaseListAction = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(getAllPhaseListInitiate());
    const response = await axios.get(`${Url}construction/findAllTask`, {
      headers: { Authorization: `Bearer ${token}` },
      params: payload,
    });
 
    if (response.status == 201) {

      dispatch(getAllPhaseListSuccess(response.data));
    } else {
      dispatch(getAllPhaseListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getAllPhaseListFailure(err));
    return err.response;
  }
};



export const createScheduleInitiate = () => ({
  type: constructionConstant.CREATE_SCHEDULE_INITIATE,
});

export const createScheduleSuccess = (data) => ({
  type: constructionConstant.CREATE_SCHEDULE_SUCCESS,
  payload: data,
});

export const createScheduleFailure = (data) => ({
  type: constructionConstant.CREATE_SCHEDULE_FAILURE,
  payload: data,
});

export const createScheduleAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(createScheduleInitiate());
    const response = await axios.post(
      `${Url}createShedule`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(createScheduleSuccess(response.data));
    } else {
      dispatch(createScheduleFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(createScheduleFailure(err));
    return err.response;
  }
};




export const updateDefaultPhaseInitiate = () => ({
  type: constructionConstant.UPDATE_DEFAULT_PHASE_INITIATE,
});

export const updateDefaultPhaseSuccess = (data) => ({
  type: constructionConstant.UPDATE_DEFAULT_PHASE_SUCCESS,
  payload: data,
});

export const updateDefaultPhaseFailure = (data) => ({
  type: constructionConstant.UPDATE_DEFAULT_PHASE_FAILURE,
  payload: data,
});

export const updateDefaultPhaseAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(updateDefaultPhaseInitiate());
    const response = await axios.post(
      `${Url}construction/editDefaultPhase`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(updateDefaultPhaseSuccess(response.data));
    } else {
      dispatch(updateDefaultPhaseFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(updateDefaultPhaseFailure(err));
    return err.response;
  }
};



export const updateDefaultTaskInitiate = () => ({
  type: constructionConstant.UPDATE_DEFAULT_PHASE_INITIATE,
});

export const updateDefaultTaskSuccess = (data) => ({
  type: constructionConstant.UPDATE_DEFAULT_PHASE_SUCCESS,
  payload: data,
});

export const updateDefaultTaskFailure = (data) => ({
  type: constructionConstant.UPDATE_DEFAULT_PHASE_FAILURE,
  payload: data,
});

export const updateDefaultTaskAction = (payload) => async (dispatch) => {
  try {
     const token = isLoggedIn("adminData1");
    dispatch(updateDefaultTaskInitiate());
    const response = await axios.post(
      `${Url}construction/editDefaultTask`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(updateDefaultTaskSuccess(response.data));
    } else {
      dispatch(updateDefaultTaskFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(updateDefaultTaskFailure(err));
    return err.response;
  }
};



export const constructionDashboardInitiate = () => ({
  type: constructionConstant.CONSTRUCTION_DASHBOARD_INITIATE,
});

export const constructionDashboardSuccess = (data) => ({
  type: constructionConstant.CONSTRUCTION_DASHBOARD_SUCCESS,
  payload: data,
});

export const constructionDashboardFailure = (data) => ({
  type: constructionConstant.CONSTRUCTION_DASHBOARD_FAILURE,
  payload: data,
});

export const constructionDashboardAction = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(getProjectNameInitiate());
    const response = await axios.get(`${Url}construction/viewShedule`, {
      headers: { Authorization: `Bearer ${token}` },
      params:payload
    });
    if (response.status == 201) {
      dispatch(constructionDashboardSuccess(response.data));
    } else {
      dispatch(constructionDashboardFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(constructionDashboardFailure(err));
    return err.response;
  }
};













export const getFieldPurcaseListInitiate = () => ({
  type: constructionConstant.FIELD_PURCAHSE_ORDER_LIST_INITIATE,
});

export const getFieldPurcaseListSuccess = (data) => ({
  type: constructionConstant.FIELD_PURCAHSE_ORDER_LIST_SUCCESS,
  payload: data,
});

export const getFieldPurcaseListFailure = (data) => ({
  type: constructionConstant.FIELD_PURCAHSE_ORDER_LIST_FAILURE,
  payload: data,
});

export const  getFieldPurcaseListAction = ( Id) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch( getFieldPurcaseListInitiate());
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/fieldpurchaseorder?id=${Id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status == 200) {
      dispatch( getFieldPurcaseListSuccess(response.data));
    } else {
      dispatch (getFieldPurcaseListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch( getFieldPurcaseListFailure(err));
    return err.response;
  }
};


