import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import SystemDefault from "./SystemDefault";
import CopyFromJob from "./CopyFromJob";
import ImportSchedule from "./ImportSchedule";
import {toast} from "react-toastify"
import { createScheduleAction } from "../../../redux/action/constructionsAction";
import { useDispatch } from "react-redux";

const scheduleInitialState = {
  jobId: "",
  startDate: "2024-0827",
  includeWeekend: false,
  duration: "29",
  sourceType: "System",
  phaseSelect: "All",
  companyType:"SINGLE_FAMILY_RESIDENTIAL"
};

const AddSchedule = ({ AddScheduleModal, setAddSchedule ,jobId,jobNumber,projectName}) => {

  const dispatch = useDispatch()
  const handleAddScheduleModalClose = () => setAddSchedule(false);
  const [DefaultSystemModal, setDefaultSystem] = useState(false);
  const handleDefaultSystemModalShow = () => {
    setAddSchedule(false);
    setDefaultSystem(true);
  };

  const [CopyFromJobModal, setCopyFromJobModal] = useState(false);
  const handleCopyFromJobModalShow = () => {
    setAddSchedule(false);
    setCopyFromJobModal(true);
  };

  const [ImportScheduleModal, setImportScheduleModal] = useState(false);
  const handleImportScheduleModalShow = () => {
    setAddSchedule(false);
    setImportScheduleModal(true);
  };

  const [scheduleState, updateScheduleState] = useState(scheduleInitialState);


  const handleCheck=(e)=>{
    const {checked} = e.target

    updateScheduleState((prev) => ({
      ...prev,
      includeWeekend:checked
    }))
    

  }


  const handleCreateSchedule = (e) => {
    e.preventDefault();

    let formIsValid = true;
    let data =scheduleState

    data['duration'] = 4
    data['startDate'] = "2024-08-27"

    data['jobId'] =jobId

    if (formIsValid) {
      dispatch(createScheduleAction(data))
        .then((res) => {
          if (res.status == 201) {
            setAddSchedule(false);
            toast.success(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };


  return (
    <>
      <Modal
        show={AddScheduleModal}
        onHide={handleAddScheduleModalClose}
        className="ModalBox SmallModal"
      >
        <a
          onClick={handleAddScheduleModalClose}
          className="CloseModal"
          data-dismiss="modal"
        >
          ×
        </a>

        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Schedule Generation</h4>
          </div>
          <div class="FolderForm">
            <form>
              <div class="JobTitleArea mb-2">
                <div class="JobBox">
                  <h6>
                    <span>Job Name:</span>&nbsp;{projectName}
                  </h6>
                </div>
                <div class="JobBox">
                  <h6 class="FolderPermissionId">
                    <span>Job Id:</span>&nbsp;{jobNumber}
                  </h6>
                </div>
              </div>
              <div class="Filter2">
                <div class="row">
                  <div class="col-lg-6">                                                                                                                                                                                                                                                
                    <div class="form-group">
                      <label>Start</label>
                      <input
                        value={scheduleState?.startDate}
                        onChange={(e) =>
                          updateScheduleState((prev) => ({
                            ...prev,
                            startDate: e.target.value,
                          }))
                        }
                        type="date"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Include Weekends</label>
                      <label class="CheckBox">
                        <input type="checkbox" onChange={handleCheck}  />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  {/* <div class="col-lg-6">
                    <div class="form-group">
                      <label>End</label>
                      <input type="date" class="form-control" />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Duration</label>
                      <input type="text" class="form-control" placeholder="Inform project duration" />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="Categories Categories2">
                      <div class="Categories_all Categories_all2">
                        <span>Select duration time unit</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>- Days</li>
                        <li>- Weeks</li>
                        <li>- Months</li>
                        <li>- Years</li>
                      </ul>
                    </div>
                  </div> */}
                  <div class="col-12">
                    <div class="JobTitleArea mt-3 mb-4">
                      <h3 class="mb-0">Schedule Source</h3>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <h6>Import</h6>
                      <div class="PermissionsName">
                        <h5
                          class="FolderPermissionId"
                          onClick={handleImportScheduleModalShow}
                        >
                          <span>
                            <img src="images/import.png" />{" "}
                          </span>{" "}
                          Import
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <h6>System Default Schedule</h6>
                      <div class="PermissionsName">
                        <h5
                          class="FolderPermissionId"
                          onClick={handleDefaultSystemModalShow}
                          data-dismiss="modal"
                        >
                          <span>
                            <img src="images/system-default.png" />
                          </span>
                          System Default Schedule
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <h6>Copy from other Job</h6>
                      <div class="PermissionsName">
                        <h5
                          class="FolderPermissionId"
                          onClick={handleCopyFromJobModalShow}
                          data-dismiss="modal"
                        >
                          <span>
                            <img src="images/copy-from-other.png" />
                          </span>
                          Copy from other Job
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="JobTitleArea mt-3 mb-4">
                      <h3>Schedule Source</h3>
                      <h6>Source : System</h6>
                      <h6
                        class="CursorPointer FolderPermissionId"
                        data-toggle="modal"
                        data-target="#InspectionSelectionTable"
                        data-dismiss="modal"
                      >
                        Total Phases : 21
                      </h6>
                      <h6>Total Tasks : 100</h6>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="JobTitleArea mt-3 mb-4">
                      <h3>Actions</h3>
                      <div class="Actions">
                        <a href="">
                          <img src="images/edit-32.png" />
                        </a>
                        <a href="">
                          <img src="images/Cross.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="Button d-flex justify-content-between">
            <button class="Create" onClick={handleCreateSchedule} data-dismiss="modal">
              Generate Schedule
            </button>
            <button class="Outline" onClick={handleAddScheduleModalClose} data-dismiss="modal">
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      <SystemDefault

scheduleState={scheduleState}
        DefaultSystemModal={DefaultSystemModal}
        setDefaultSystem={setDefaultSystem}
        setAddSchedule={setAddSchedule}
      />

      <CopyFromJob
        CopyFromJobModal={CopyFromJobModal}
        setCopyFromJobModal={setCopyFromJobModal}
        setAddSchedule={setAddSchedule}
      />

      <ImportSchedule
        ImportScheduleModal={ImportScheduleModal}
        setImportScheduleModal={setImportScheduleModal}
        setAddSchedule={setAddSchedule}
      />
    </>
  );
};

export default AddSchedule;
