import { clientViewAuthAction } from "../actionTypes";
import { Url } from  "../../Config/Config";
import axios from "axios";
import { multiPartData, multiPartDatas } from "../../utils/uploadFile";
import { isLoggedIn } from "../../utils/tokenCheck";

//----------------------------------ClientView==login--------------------------------------------------

export const clientViewLoginInitiate = () => ({
  type: clientViewAuthAction.CLIENT_VIEW_LOGIN_INITIATE,
});

export const clientViewLoginSuccess = (data) => ({
  type: clientViewAuthAction.CLIENT_VIEW_LOGIN_SUCCESS,
  payload: data,
});

export const clientViewLoginFailure = (data) => ({
  type: clientViewAuthAction.CLIENT_VIEW_LOGIN_FAILURE,
  payload: data,
});

export const clientViewLogin = (payload) => async (dispatch) => {
    try {
      dispatch(clientViewLoginInitiate(payload));
      const response = await axios.post(`${Url}client-views/login`, payload);
      // console.log("response",response);
      if (response.status === 200) {
        dispatch(clientViewLoginSuccess(response.data));
      } else {
        dispatch(clientViewLoginFailure(response.data));
      }
      return response
    } catch (err) {
      dispatch(clientViewLoginFailure(err));
      return err.response
    }
  };

//=================================clientSendEmailRequest================================

export const clientSendEmailInitiate = () => ({
  type: clientViewAuthAction.CLIENT_SEND_EMAIL_INITIATE,
});

export const clientSendEmailSuccess = (data) => ({
  type: clientViewAuthAction.CLIENT_SEND_EMAIL_SUCCESS,
  payload: data,
});

export const clientSendEmailFailure = (data) => ({
  type: clientViewAuthAction.CLIENT_SEND_EMAIL_FAILURE,
  payload: data,
});

export const sendClientEmail = (payload) => async (dispatch) => {
    try {
      dispatch(clientSendEmailInitiate(payload));
      const response = await axios.put(`${Url}client-user/send-reset`, payload);
      if (response.status === 200) {
        dispatch(clientSendEmailSuccess(response.data));
      } else {
        dispatch(clientSendEmailFailure(response.data));
      }
      return response
    } catch (err) {
      dispatch(clientSendEmailFailure(err));
      return err.response
    }
  };

//===============================upload-main-image==================================

export const uploadMainClientImageInitiate = () => ({
  type: clientViewAuthAction.CLIENT_UPLOAD_MAIN_IMAGE_INITIATE,
});

export const uploadMainClientImageSuccess = (data) => ({
  type: clientViewAuthAction.CLIENT_UPLOAD_MAIN_IMAGE_SUCCESS,
  payload: data,
});

export const uploadMainClientImageFailure = (data) => ({
  type: clientViewAuthAction.CLIENT_UPLOAD_MAIN_IMAGE_FAILURE,
  payload: data,
});

  export const getUploadMainClientImage = (clientId,payload) => async (dispatch) => {
      try {
        // const updatedData = multiPartDatas(payload );
        const token = isLoggedIn();
        dispatch(uploadMainClientImageInitiate(payload));
        const response = await axios.put(`${Url}clients/${clientId}/client-main-image`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(uploadMainClientImageSuccess(response.data));
        } else {
          dispatch(uploadMainClientImageFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(uploadMainClientImageFailure(err));
        return err.response
      }
    };

//=========================upload-icon=image==================================

export const uploadIconClientImageInitiate = () => ({
  type: clientViewAuthAction.CLIENT_UPLOAD_ICON_IMAGE_INITIATE,
});

export const uploadIconClientImageSuccess = (data) => ({
  type: clientViewAuthAction.CLIENT_UPLOAD_ICON_IMAGE_SUCCESS,
  payload: data,
});

export const uploadIconClientImageFailure = (data) => ({
  type: clientViewAuthAction.CLIENT_UPLOAD_ICON_IMAGE_FAILURE,
  payload: data,
});

  export const getUploadIconClientImage = (clientId,payload) => async (dispatch) => {
      try {
        // const updatedData = multiPartDatas(payload );
        const token = isLoggedIn();
        dispatch(uploadIconClientImageInitiate(payload));
        const response = await axios.put(`${Url}clients/${clientId}/client-icon-image`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(uploadIconClientImageSuccess(response.data));
        } else {
          dispatch(uploadIconClientImageFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(uploadIconClientImageFailure(err));
        return err.response
      }
    };

//================================warranty=request==list==========================


export const warrantyRequestListInitiate = () => ({
  type: clientViewAuthAction.WARRANTY_REQUEST_LIST_INITIATE,
});

export const warrantyRequestListSuccess = (data) => ({
  type: clientViewAuthAction.WARRANTY_REQUEST_LIST_SUCCESS,
  payload: data,
});

export const warrantyRequestListFailure = (data) => ({
  type: clientViewAuthAction.WARRANTY_REQUEST_LIST_FAILURE,
  payload: data,
});

  export const getWarrantyRequestList = () => async (dispatch) => {
      try {
        const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
        const token = isLoggedIn();
        dispatch(warrantyRequestListInitiate());
        const response = await axios.get(`${Url}companies/${clientViewData?.user?.CompanyId}/warranties?jobId=${clientViewData?.jobDetails?.id}&jobType=${clientViewData?.jobDetails?.jobType}&viewId=${clientViewData?.viewId}&projectId=${clientViewData?.jobDetails?.ProjectId}&viewType="ClientView"&module="warrantyWorkOrder"`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(warrantyRequestListSuccess(response.data));
        } else {
          dispatch(warrantyRequestListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(warrantyRequestListFailure(err));
        return err.response
      }
    };

//==============================warranty-work-order==============================

export const warrantyWorkOrderListInitiate = () => ({
  type: clientViewAuthAction.WARRANTY_WORK_ORDER_LIST_INITIATE,
});

export const warrantyWorkOrderListSuccess = (data) => ({
  type: clientViewAuthAction.WARRANTY_WORK_ORDER_LIST_SUCCESS,
  payload: data,
});

export const warrantyWorkOrderListFailure = (data) => ({
  type: clientViewAuthAction.WARRANTY_WORK_ORDER_LIST_FAILURE,
  payload: data,
});

  export const getWarrantyWorkOrderList = () => async (dispatch) => {
      try {
        const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
        const token = isLoggedIn();
        dispatch(warrantyWorkOrderListInitiate());
        const response = await axios.get(`${Url}companies/${clientViewData?.user?.CompanyId}/all-work-order?jobId=${clientViewData?.jobDetails?.id}&jobType=${clientViewData?.jobDetails?.jobType}&viewId=${clientViewData?.viewId}&projectId=${clientViewData?.jobDetails?.ProjectId}&viewType="ClientView"&module="warrantyWorkOrder"`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(warrantyWorkOrderListSuccess(response.data));
        } else {
          dispatch(warrantyWorkOrderListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(warrantyWorkOrderListFailure(err));
        return err.response
      }
    };

//====================================DRAFT-data=============================


export const warrantyDraftDataInitiate = () => ({
  type: clientViewAuthAction.WARRANTY_DRAFT_DATA_INITIATE,
});

export const warrantyDraftDataSuccess = (data) => ({
  type: clientViewAuthAction.WARRANTY_DRAFT_DATA_SUCCESS,
  payload: data,
});

export const warrantyDraftDataFailure = (data) => ({
  type: clientViewAuthAction.WARRANTY_DRAFT_DATA_FAILURE,
  payload: data,
});

  export const getDraftData = (payload) => async (dispatch) => {
      try {
        const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
        const token = isLoggedIn();
        dispatch(warrantyDraftDataInitiate());
        const response = await axios.post(`${Url}companies/${clientViewData?.user?.CompanyId}/draft-warranties?projectId=${clientViewData?.jobDetails?.ProjectId}&jobId=${clientViewData?.jobDetails?.id}&jobType=${clientViewData?.jobDetails?.jobType}`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(warrantyDraftDataSuccess(response.data));
        } else {
          dispatch(warrantyDraftDataFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(warrantyDraftDataFailure(err));
        return err.response
      }
    };
//==========================Add-warranty-=notes====================================

export const warrantyAddNotesInitiate = () => ({
  type: clientViewAuthAction.WARRANTY_ADD_NOTES_INITIATE,
});

export const warrantyAddNotesSuccess = (data) => ({
  type: clientViewAuthAction.WARRANTY_ADD_NOTES_SUCCESS,
  payload: data,
});

export const warrantyAddNotesFailure = (data) => ({
  type: clientViewAuthAction.WARRANTY_ADD_NOTES_FAILURE,
  payload: data,
});

  export const warrantyAddNotes = (waranId,payload) => async (dispatch) => {
      try {
        const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
        const token = isLoggedIn();
        dispatch(warrantyAddNotesInitiate());
        const updatedData = multiPartData({ ...payload });
        const response = await axios.put(`${Url}companies/${clientViewData?.user?.CompanyId}/warranties/${waranId}`,updatedData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(warrantyAddNotesSuccess(response.data));
        } else {
          dispatch(warrantyAddNotesFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(warrantyAddNotesFailure(err));
        return err.response
      }
    };

//=============================add-support-docs=============================


export const warrantyAddNewSupportDocsInitiate = () => ({
  type: clientViewAuthAction.WARRANTY_ADD_NEW_SUPPORT_DOCS_INITIATE,
});

export const warrantyAddNewSupportDocsSuccess = (data) => ({
  type: clientViewAuthAction.WARRANTY_ADD_NEW_SUPPORT_DOCS_SUCCESS,
  payload: data,
});

export const  warrantyAddNewSupportDocsFailure = (data) => ({
  type: clientViewAuthAction.WARRANTY_ADD_NEW_SUPPORT_DOCS_FAILURE,
  payload: data,
});

export const getWarrantyAddNewSupportDocs = (waranId,payload) => async (dispatch) => {
    try {
      const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
      const token = isLoggedIn();
      const updatedData = multiPartData({ ...payload });
      dispatch(warrantyAddNewSupportDocsInitiate(payload));
      const response = await axios.put(`${Url}companies/${clientViewData?.user?.CompanyId}/warranties/${waranId}/support-doc`,updatedData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(warrantyAddNewSupportDocsSuccess(response.data));
      } else {
        dispatch(warrantyAddNewSupportDocsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(warrantyAddNewSupportDocsFailure(err));
      return err.response
    }
  };
//===============================add-urgency======================

export const addWarrantyUrgencyInitiate = () => ({
  type: clientViewAuthAction.WARRANTY_ADD_URGENCY_INITIATE,
});

export const addWarrantyUrgencySuccess = (data) => ({
  type: clientViewAuthAction.WARRANTY_ADD_URGENCY_SUCCESS,
  payload: data,
});

export const  addWarrantyUrgencyFailure = (data) => ({
  type: clientViewAuthAction.WARRANTY_ADD_URGENCY_FAILURE,
  payload: data,
});

export const addWarrantyUrgency = (payload) => async (dispatch) => {
    try {
      const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
      const token = isLoggedIn();
      dispatch(addWarrantyUrgencyInitiate(payload));
      const response = await axios.post(`${Url}companies/${clientViewData?.user?.CompanyId}/urgency`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(addWarrantyUrgencySuccess(response.data));
      } else {
        dispatch(addWarrantyUrgencyFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addWarrantyUrgencyFailure(err));
      return err.response
    }
  };

//=========================system-urgency-list=============

export const warrantyAllSystemUrgencyListInitiate = () => ({
  type: clientViewAuthAction.WARRANTY_ALL_SYSTEM_URGENCY_LIST_INITIATE,
});

export const warrantyAllSystemUrgencyListSuccess = (data) => ({
  type: clientViewAuthAction.WARRANTY_ALL_SYSTEM_URGENCY_LIST_SUCCESS,
  payload: data,
});

export const  warrantyAllSystemUrgencyListFailure = (data) => ({
  type: clientViewAuthAction.WARRANTY_ALL_SYSTEM_URGENCY_LIST_FAILURE,
  payload: data,
});

export const getWarrantyAllSystemUrgencyList = (payload) => async (dispatch) => {
    try {
      const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
      const token = isLoggedIn();
      dispatch(warrantyAllSystemUrgencyListInitiate(payload));
      const response = await axios.get(`${Url}companies/${clientViewData?.user?.CompanyId}/urgency?definedType=System`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(warrantyAllSystemUrgencyListSuccess(response.data));
      } else {
        dispatch(warrantyAllSystemUrgencyListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(warrantyAllSystemUrgencyListFailure(err));
      return err.response
    }
  };

//===================================URGENCY-LIST==================


export const warrantyUrgencyListInitiate = () => ({
  type: clientViewAuthAction.WARRANTY_URGENCY_LIST_INITIATE,
});

export const warrantyUrgencyListSuccess = (data) => ({
  type: clientViewAuthAction.WARRANTY_URGENCY_LIST_SUCCESS,
  payload: data,
});

export const  warrantyUrgencyListFailure = (data) => ({
  type: clientViewAuthAction.WARRANTY_URGENCY_LIST_FAILURE,
  payload: data,
});

export const getAllWarrantyUrgencyList = (payload) => async (dispatch) => {
    try {
      const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
      const token = isLoggedIn();
      dispatch(warrantyUrgencyListInitiate(payload));
      const response = await axios.get(`${Url}companies/${clientViewData?.user?.CompanyId}/urgency`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(warrantyUrgencyListSuccess(response.data));
      } else {
        dispatch(warrantyUrgencyListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(warrantyUrgencyListFailure(err));
      return err.response
    }
  };

//=========================all-request--item-list=======================


export const warrantieyAllRequestItemListInitiate = () => ({
  type: clientViewAuthAction.WARRANTY_ALL_REQUEST_ITEM_LIST_INITIATE,
});

export const warrantieyAllRequestItemListSuccess = (data) => ({
  type: clientViewAuthAction.WARRANTY_ALL_REQUEST_ITEM_LIST_SUCCESS,
  payload: data,
});

export const  warrantieyAllRequestItemListFailure = (data) => ({
  type: clientViewAuthAction.WARRANTY_ALL_REQUEST_ITEM_LIST_FAILURE,
  payload: data,
});

export const getWarrantieyAllRequestItemList = (warntId) => async (dispatch) => {
    try {
      const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
      const token = isLoggedIn();
      dispatch(warrantieyAllRequestItemListInitiate(warntId));
      const response = await axios.get(`${Url}companies/${clientViewData?.user?.CompanyId}/warranties/${warntId}/request-items`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(warrantieyAllRequestItemListSuccess(response.data));
      } else {
        dispatch(warrantieyAllRequestItemListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(warrantieyAllRequestItemListFailure(err));
      return err.response
    }
  };

//========================CREATE--client--request===========================

export const createClientChangeRequestInitiate = () => ({
  type: clientViewAuthAction.CREATE_CLIENT_CHANGE_REQUEST_INITIATE,
});

export const createClientChangeRequestSuccess = (data) => ({
  type: clientViewAuthAction.CREATE_CLIENT_CHANGE_REQUEST_SUCCESS,
  payload: data,
});

export const  createClientChangeRequestFailure = (data) => ({
  type: clientViewAuthAction.CREATE_CLIENT_CHANGE_REQUEST_FAILURE,
  payload: data,
});

export const createClientChangeRequest = (projectId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn();
      dispatch(createClientChangeRequestInitiate());
      const response = await axios.post(`${Url}projects/${projectId}/client/client-change-request`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(createClientChangeRequestSuccess(response.data));
      } else {
        dispatch(createClientChangeRequestFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createClientChangeRequestFailure(err));
      return err.response
    }
  }

//=======================client-request---list====================

export const clientChangeRequestListInitiate = () => ({
  type: clientViewAuthAction.CLIENT_CHANGE_REQUEST_LIST_INITIATE,
});

export const clientChangeRequestListSuccess = (data) => ({
  type: clientViewAuthAction.CLIENT_CHANGE_REQUEST_LIST_SUCCESS,
  payload: data,
});

export const  clientChangeRequestListFailure = (data) => ({
  type: clientViewAuthAction.CLIENT_CHANGE_REQUEST_LIST_FAILURE,
  payload: data,
});

export const getClientChangeRequestList = (projectId) => async (dispatch) => {
    try {
      const token = isLoggedIn();
      dispatch(clientChangeRequestListInitiate());
      const response = await axios.get(`${Url}projects/${projectId}/client/client-change-request`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(clientChangeRequestListSuccess(response.data));
      } else {
        dispatch(clientChangeRequestListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(clientChangeRequestListFailure(err));
      return err.response
    }
  }
//================================PENIDNG-APPOINTMENS============================

export const clientAppointmentPendingListInitiate = () => ({
  type: clientViewAuthAction.CLIENT_APPOINTMENT_PENIDNG_LIST_INITIATE,
});

export const clientAppointmentPendingListSuccess = (data) => ({
  type: clientViewAuthAction.CLIENT_APPOINTMENT_PENIDNG_LIST_SUCCESS,
  payload: data,
});

export const  clientAppointmentPendingListFailure = (data) => ({
  type: clientViewAuthAction.CLIENT_APPOINTMENT_PENIDNG_LIST_FAILURE,
  payload: data,
});

export const getAppointmentPendingList = (compId,projectId) => async (dispatch) => {
    try {
      const token = isLoggedIn();
      dispatch(clientAppointmentPendingListInitiate());
      const response = await axios.get(`${Url}companies/${compId}/projects/${projectId}/appointment/pending`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(clientAppointmentPendingListSuccess(response.data));
      } else {
        dispatch(clientAppointmentPendingListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(clientAppointmentPendingListFailure(err));
      return err.response
    }
  }

//================================ALL-APPOINTMENS============================

export const clientAllAppointmentListInitiate = () => ({
  type: clientViewAuthAction.CLIENT_ALL_APPOINTMENT_LIST_INITIATE,
});

export const clientAllAppointmentListSuccess = (data) => ({
  type: clientViewAuthAction.CLIENT_ALL_APPOINTMENT_LIST_SUCCESS,
  payload: data,
});

export const  clientAllAppointmentListFailure = (data) => ({
  type: clientViewAuthAction.CLIENT_ALL_APPOINTMENT_LIST_FAILURE,
  payload: data,
});

export const getClientAllAppointmentList= (compId,projectId,jobId,jobType) => async (dispatch) => {
    try {
      const token = isLoggedIn();
      dispatch(clientAllAppointmentListInitiate());
      const response = await axios.get(`${Url}companies/${compId}/projects/${projectId}/client/appointment?jobId=${jobId}&jobType=${jobType}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(clientAllAppointmentListSuccess(response.data));
      } else {
        dispatch(clientAllAppointmentListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(clientAllAppointmentListFailure(err));
      return err.response
    }
  }
//======================GET-change-order-list================
export const clientChangeOrderListInitiate = () => ({
  type: clientViewAuthAction.CLIENT_CHANGE_ORDER_LIST_INITIATE,
});

export const clientChangeOrderListSuccess = (data) => ({
  type: clientViewAuthAction.CLIENT_CHANGE_ORDER_LIST_SUCCESS,
  payload: data,
});

export const  clientChangeOrderListFailure = (data) => ({
  type: clientViewAuthAction.CLIENT_CHANGE_ORDER_LIST_FAILURE,
  payload: data,
});

export const getChangeOrderList= (projectId,jobId,jobType) => async (dispatch) => {
    try {
      const token = isLoggedIn();
      dispatch(clientChangeOrderListInitiate());
      const response = await axios.get(`${Url}projects/${projectId}/client/change-order?jobId=${jobId}&jobType=${jobType}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(clientChangeOrderListSuccess(response.data));
      } else {
        dispatch(clientChangeOrderListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(clientChangeOrderListFailure(err));
      return err.response
    }
  }

//---------------------------------GOOglecalendar-----------------------------------------------
export const googleCalendarInitiate = () => ({
  type: clientViewAuthAction.GOOGLE_CALENDAR_CLIENT_VIEW_INITIATE,
});

export const googleCalendarSuccess = (data) => ({
  type: clientViewAuthAction.GOOGLE_CALENDAR_CLIENT_VIEW_SUCCESS,
  payload: data,
});

export const googleCalendarFailure = (data) => ({
  type: clientViewAuthAction.GOOGLE_CALENDAR_CLIENT_VIEW_FAILURE,
  payload: data,
});

export const getGoogleCalendarClientView = (compId,payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn();
    dispatch(googleCalendarInitiate());
    const response = await axios.post(`${Url}companies/${compId}/create-google-calender`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(googleCalendarSuccess(response.data));
    } else {
      dispatch(googleCalendarFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(googleCalendarFailure(err));
    return err.response;
  }
};


//---------------------------------OUTLOOKcalendar-----------------------------------------------
export const outlookCalendarInitiate = () => ({
  type: clientViewAuthAction.OUTLOOK_CALENDAR_CLIENT_VIEW_INITIATE,
});

export const outlookCalendarSuccess = (data) => ({
  type: clientViewAuthAction.OUTLOOK_CALENDAR_CLIENT_VIEW_SUCCESS,
  payload: data,
});

export const outlookCalendarFailure = (data) => ({
  type: clientViewAuthAction.OUTLOOK_CALENDAR_CLIENT_VIEW_FAILURE,
  payload: data,
});

export const getOutlookCalendarClientView = (compId,payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn();
    dispatch(outlookCalendarInitiate());
    const response = await axios.post(`${Url}companies/${compId}/create-outlook-calender`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(outlookCalendarSuccess(response.data));
    } else {
      dispatch(outlookCalendarFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(outlookCalendarFailure(err));
    return err.response;
  }
};


//---------------------------------yahoo-calendar-----------------------------------------------
export const emailCalendarInitiate = () => ({
  type: clientViewAuthAction.YAHOO_CALENDAR_CLIENT_VIEW_INITIATE,
});

export const emailCalendarSuccess = (data) => ({
  type: clientViewAuthAction.YAHOO_CALENDAR_CLIENT_VIEW_SUCCESS,
  payload: data,
});

export const emailCalendarFailure = (data) => ({
  type: clientViewAuthAction.YAHOO_CALENDAR_CLIENT_VIEW_FAILURE,
  payload: data,
});

export const getYahooCalendarClientView = (compId,payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn();
    dispatch(emailCalendarInitiate());
    const response = await axios.post(`${Url}companies/${compId}/create-yahoo-calender`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(emailCalendarSuccess(response.data));
    } else {
      dispatch(emailCalendarFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(emailCalendarFailure(err));
    return err.response;
  }
}
//============================UPDATE-reminder==========================

export const clientAppointmentUpdateReminderInitiate = () => ({
  type: clientViewAuthAction.CLIENT_APPOINTMENT_UPDATE_REMINDER_INITIATE,
});

export const clientAppointmentUpdateReminderSuccess = (data) => ({
  type: clientViewAuthAction.CLIENT_APPOINTMENT_UPDATE_REMINDER_SUCCESS,
  payload: data,
});

export const clientAppointmentUpdateReminderFailure = (data) => ({
  type: clientViewAuthAction.CLIENT_APPOINTMENT_UPDATE_REMINDER_FAILURE,
  payload: data,
});

export const clientAppointmentUpdateReminder = (compId,projectId,payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn();
    dispatch(clientAppointmentUpdateReminderInitiate());
    const response = await axios.put(`${Url}companies/${compId}/projects/${projectId}/appointment/reminder/set`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(clientAppointmentUpdateReminderSuccess(response.data));
    } else {
      dispatch(clientAppointmentUpdateReminderFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(clientAppointmentUpdateReminderFailure(err));
    return err.response;
  }
}
//==========================sales---folder----n---list=================

export const salesFolderNListInitiate = () => ({
  type: clientViewAuthAction.SALES_FOLDER_N_LIST_INITIATE,
});

export const salesFolderNListSuccess = (data) => ({
  type: clientViewAuthAction.SALES_FOLDER_N_LIST_SUCCESS,
  payload: data,
});

export const salesFolderNListFailure = (data) => ({
  type: clientViewAuthAction.SALES_FOLDER_N_LIST_FAILURE,
  payload: data,
});

export const getSalesFolderNList = (compId,viewId) => async (dispatch) => {
  
  try {
    const token = isLoggedIn();
    dispatch(salesFolderNListInitiate());
    const response = await axios.get(`${Url}companies/${compId}/client-view/${viewId}/client-file-manager`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(salesFolderNListSuccess(response.data));
    } else {
      dispatch(salesFolderNListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(salesFolderNListFailure(err));
    return err.response;
  }
}
//===============================sales------manage-------signatures=========


export const salesCreateManageSignatureInitiate = () => ({
  type: clientViewAuthAction.SALES_CREATE_MANAGE_SIGNATURES_INITIATE,
});

export const salesCreateManageSignatureSuccess = (data) => ({
  type: clientViewAuthAction.SALES_CREATE_MANAGE_SIGNATURES_SUCCESS,
  payload: data,
});

export const salesCreateManageSignatureFailure = (data) => ({
  type: clientViewAuthAction.SALES_CREATE_MANAGE_SIGNATURES_FAILURE,
  payload: data,
});

export const salesCreateManageSignature = (compId,viewId,payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn();
    dispatch(salesCreateManageSignatureInitiate());
    const response = await axios.post(`${Url}companies/${compId}/client-view/${viewId}/`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 201) {
      dispatch(salesCreateManageSignatureSuccess(response.data));
    } else {
      dispatch(salesCreateManageSignatureFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(salesCreateManageSignatureFailure(err));
    return err.response;
  }
}

//========================SALES-DOCUMENT----HISTORY--LIST===========


export const salesDocumentHistoryListInitiate = () => ({
  type: clientViewAuthAction.SALES_DOCUMENT_HISTORY_LIST_INITIATE,
});

export const salesDocumentHistoryListSuccess = (data) => ({
  type: clientViewAuthAction.SALES_DOCUMENT_HISTORY_LIST_SUCCESS,
  payload: data,
});

export const salesDocumentHistoryListFailure = (data) => ({
  type: clientViewAuthAction.SALES_DOCUMENT_HISTORY_LIST_FAILURE,
  payload: data,
});

export const getSalesDocumentHistoryList = (compId,foldId) => async (dispatch) => {
  
  try {
    const token = isLoggedIn();
    dispatch(salesDocumentHistoryListInitiate());
    const response = await axios.get(`${Url}companies/${compId}/folders/${foldId}/history`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(salesDocumentHistoryListSuccess(response.data));
    } else {
      dispatch(salesDocumentHistoryListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(salesDocumentHistoryListFailure(err));
    return err.response;
  }
}

//==============================WARRANTY--update==========================


export const warrantyUpdateInitiate = () => ({
  type: clientViewAuthAction.CLIENT_WARRANTY_UPDATE_INITIATE,
});

export const warrantyUpdateSuccess = (data) => ({
  type: clientViewAuthAction.CLIENT_WARRANTY_UPDATE_SUCCESS,
  payload: data,
});

export const  warrantyUpdateFailure = (data) => ({
  type: clientViewAuthAction.CLIENT_WARRANTY_UPDATE_FAILURE,
  payload: data,
});

export const clientWarrantyUpdate = (warantId,payload) => async (dispatch) => {
    try {
      const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
      const token = isLoggedIn();
      const updatedData = multiPartData({ ...payload });
      dispatch(warrantyUpdateInitiate(payload));
      const response = await axios.put(`${Url}companies/${clientViewData?.user?.CompanyId}/warranties/${warantId}`,updatedData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(warrantyUpdateSuccess(response.data));
      } else {
        dispatch(warrantyUpdateFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(warrantyUpdateFailure(err));
      return err.response
    }
  };

//===============================get-design--interior---selectins=============

export const getInteriorDesignSelectionsInitiate = () => ({
  type: clientViewAuthAction.GET_INTERIOR_DESIGN_SELECTONS_INITIATE,
});

export const getInteriorDesignSelectionsSuccess = (data) => ({
  type: clientViewAuthAction.GET_INTERIOR_DESIGN_SELECTONS_SUCCESS,
  payload: data,
});

export const  getInteriorDesignSelectionsFailure = (data) => ({
  type: clientViewAuthAction.GET_INTERIOR_DESIGN_SELECTONS_FAILURE,
  payload: data,
});

export const getInteriorDesignSelections = () => async (dispatch) => {
    try {
      const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
      const token = isLoggedIn();
      dispatch(getInteriorDesignSelectionsInitiate());
      const response = await axios.get(`${Url}companies/${clientViewData?.user?.CompanyId}/projects/${clientViewData?.jobDetails?.ProjectId}/client/design-selection?selectionType=interior&jobId=${clientViewData?.jobDetails?.id}&jobType=${clientViewData?.jobDetails?.jobType}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getInteriorDesignSelectionsSuccess(response.data));
      } else {
        dispatch(getInteriorDesignSelectionsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getInteriorDesignSelectionsFailure(err));
      return err.response
    }
  };

//===============================get-design--EXterior---selectins=============

export const getExteriorDesignSelectionsInitiate = () => ({
  type: clientViewAuthAction.GET_EXTERIOR_DESIGN_SELECTONS_INITIATE,
});

export const getExteriorDesignSelectionsSuccess = (data) => ({
  type: clientViewAuthAction.GET_EXTERIOR_DESIGN_SELECTONS_SUCCESS,
  payload: data,
});

export const  getExteriorDesignSelectionsFailure = (data) => ({
  type: clientViewAuthAction.GET_EXTERIOR_DESIGN_SELECTONS_FAILURE,
  payload: data,
});

export const getExteriorDesignSelections = () => async (dispatch) => {
    try {
      const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
    const token = isLoggedIn();
      dispatch(getExteriorDesignSelectionsInitiate());
      const response = await axios.get(`${Url}companies/${clientViewData?.user?.CompanyId}/projects/${clientViewData?.jobDetails?.ProjectId}/client/design-selection?selectionType=exterior&jobId=${clientViewData?.jobDetails?.id}&jobType=${clientViewData?.jobDetails?.jobType}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getExteriorDesignSelectionsSuccess(response.data));
      } else {
        dispatch(getExteriorDesignSelectionsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getExteriorDesignSelectionsFailure(err));
      return err.response
    }
  };

//===========================add-user==============================

export const addClientUserInitiate = () => ({
  type: clientViewAuthAction.ADD_CLIENT_VIEW_USER_INITIATE,
});

export const addClientUserSuccess = (data) => ({
  type: clientViewAuthAction.ADD_CLIENT_VIEW_USER_SUCCESS,
  payload: data,
});

export const  addClientUserFailure = (data) => ({
  type: clientViewAuthAction.ADD_CLIENT_VIEW_USER_FAILURE,
  payload: data,
});

export const addClientUser = (configId,payload) => async (dispatch) => {
    try {
      const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
    const token = isLoggedIn();
      dispatch(addClientUserInitiate());
      const response = await axios.post(`${Url}companies/${clientViewData?.user?.CompanyId}/view-config/${configId}/client-view-user?viewType=ClientView&module=home&viewId=${clientViewData?.viewId}`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(addClientUserSuccess(response.data));
      } else {
        dispatch(addClientUserFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addClientUserFailure(err));
      return err.response
    }
  };

//===============================remove---client======================

export const removeClientUserInitiate = () => ({
  type: clientViewAuthAction.ADD_CLIENT_VIEW_USER_INITIATE,
});

export const removeClientUserSuccess = (data) => ({
  type: clientViewAuthAction.ADD_CLIENT_VIEW_USER_SUCCESS,
  payload: data,
});

export const  removeClientUserFailure = (data) => ({
  type: clientViewAuthAction.ADD_CLIENT_VIEW_USER_FAILURE,
  payload: data,
});

export const removeClientUser = (configId,userId) => async (dispatch) => {
    try {
      const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
    const token = isLoggedIn();
      dispatch(removeClientUserInitiate());
      const response = await axios.delete(`${Url}companies/${clientViewData?.user?.CompanyId}/view-config/${configId}/users/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(removeClientUserSuccess(response.data));
      } else {
        dispatch(removeClientUserFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(removeClientUserFailure(err));
      return err.response
    }
  };

//=============================Client--view-genral--config===================

export const clientViewGeneralConfigInitiate = () => ({
  type: clientViewAuthAction.CLIENT_VIEW_GENERAL_CONFIG_INITIATE,
});

export const clientViewGeneralConfigSuccess = (data) => ({
  type: clientViewAuthAction.CLIENT_VIEW_GENERAL_CONFIG_SUCCESS,
  payload: data,
});

export const  clientViewGeneralConfigFailure = (data) => ({
  type: clientViewAuthAction.CLIENT_VIEW_GENERAL_CONFIG_FAILURE,
  payload: data,
});

export const clientViewGeneralConfig = () => async (dispatch) => {
      try {
        const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
      const token = isLoggedIn();
      dispatch(clientViewGeneralConfigInitiate());
      const response = await axios.get(`${Url}companies/${clientViewData?.user?.CompanyId}/view-config?jobId=${clientViewData?.jobDetails?.id}&jobType=${clientViewData?.jobDetails?.jobType}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(clientViewGeneralConfigSuccess(response.data));
      } else {
        dispatch(clientViewGeneralConfigFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(clientViewGeneralConfigFailure(err));
      return err.response
    }
  };
//===========================update--user=====================

export const updateClientUserInitiate = () => ({
  type: clientViewAuthAction.UPDATE_CLIENT_VIEW_USER_INITIATE,
});

export const updateClientUserSuccess = (data) => ({
  type: clientViewAuthAction.UPDATE_CLIENT_VIEW_USER_SUCCESS,
  payload: data,
});

export const  updateClientUserFailure = (data) => ({
  type: clientViewAuthAction.UPDATE_CLIENT_VIEW_USER_FAILURE,
  payload: data,
});

export const updateClientUser = (configId,userId,payload) => async (dispatch) => {
  debugger
    try {
      const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
    const token = isLoggedIn();
      dispatch(updateClientUserInitiate());
      const response = await axios.put(`${Url}companies/${clientViewData?.user?.CompanyId}/view-config/${configId}/users/${userId}`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateClientUserSuccess(response.data));
      } else {
        dispatch(updateClientUserFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateClientUserFailure(err));
      return err.response
    }
  };

//======================================userList==========================


export const getClientUserInitiate = () => ({
  type: clientViewAuthAction.GET_CLIENT_VIEW_USER_INITIATE,
});

export const getClientUserSuccess = (data) => ({
  type: clientViewAuthAction.GET_CLIENT_VIEW_USER_SUCCESS,
  payload: data,
});

export const  getClientUserFailure = (data) => ({
  type: clientViewAuthAction.GET_CLIENT_VIEW_USER_FAILURE,
  payload: data,
});

export const getClientUserList = (configId) => async (dispatch) => {
    try {
      const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
    const token = isLoggedIn();
      dispatch(getClientUserInitiate());
      const response = await axios.get(`${Url}companies/${clientViewData?.user?.CompanyId}/view-config/${configId}/users-list`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getClientUserSuccess(response.data));
      } else {
        dispatch(getClientUserFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getClientUserFailure(err));
      return err.response
    }
  };
//==============================client-user--password--email---send=================


export const clientUserPasswordEmailSendInitiate = () => ({
  type: clientViewAuthAction.CLIENT_USER_PASSWORD_EMAIL_SEND_INITIATE,
});

export const clientUserPasswordEmailSendSuccess = (data) => ({
  type: clientViewAuthAction.CLIENT_USER_PASSWORD_EMAIL_SEND_SUCCESS,
  payload: data,
});

export const  clientUserPasswordEmailSendFailure = (data) => ({
  type: clientViewAuthAction.CLIENT_USER_PASSWORD_EMAIL_SEND_FAILURE,
  payload: data,
});

export const clientUserPasswordEmailSend = () => async (dispatch) => {
    try {
    const token = isLoggedIn();
      dispatch(clientUserPasswordEmailSendInitiate());
      const response = await axios.get(`${Url}client-user/send-reset`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(clientUserPasswordEmailSendSuccess(response.data));
      } else {
        dispatch(clientUserPasswordEmailSendFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(clientUserPasswordEmailSendFailure(err));
      return err.response
    }
  };


//=======================================updateSupportingDocs===============================

export const updateDocsFilesInitiate = () => ({
  type: clientViewAuthAction.CLIENT_UPDATE_DOCS_INITIATE,
});

export const updateDocsFilesSuccess = (data) => ({
  type: clientViewAuthAction.CLIENT_UPDATE_DOCS_SUCCESS,
  payload: data,
});

export const updateDocsFilesFailure = (data) => ({
  type: clientViewAuthAction.CLIENT_UPDATE_DOCS_FAILURE,
  payload: data,
});

export const updateClientChangeOrderDocsFiles= (projectId,payload) => async (dispatch) => {
    try {
      const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
    const token = isLoggedIn();
      dispatch(updateDocsFilesInitiate(projectId,payload));
      const response = await axios.put(`${Url}companies/${clientViewData?.user?.CompanyId}/projects/${projectId}/change-order/SupportingDocsByChangeorder`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateDocsFilesSuccess(response.data));
      } else {
        dispatch(updateDocsFilesFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateDocsFilesFailure(err));
      return err.response
    }
  };

//================================delete--supportings---docs================

export const clientDeleteSupportingDocsInitiate = () => ({
  type: clientViewAuthAction.CLIENT_DELETE_SUPPORTING_DOCS_INITIATE,
});

export const clientDeleteSupportingDocsSuccess = (data) => ({
  type: clientViewAuthAction.CLIENT_DELETE_SUPPORTING_DOCS_SUCCESS,
  payload: data,
});

export const clientDeleteSupportingDocsFailure = (data) => ({
  type: clientViewAuthAction.CLIENT_DELETE_SUPPORTING_DOCS_FAILURE,
  payload: data,
});

export const clientDeleteSupportingDocs= (projectId,docId) => async (dispatch) => {
    try {
      const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
    const token = isLoggedIn();
      dispatch(clientDeleteSupportingDocsSuccess(projectId,docId));
      const response = await axios.delete(`${Url}companies/${clientViewData?.user?.CompanyId}/projects/${projectId}/change-order/SupportingDocsByChangeorder?docId=${docId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(clientDeleteSupportingDocsSuccess(response.data));
      } else {
        dispatch(clientDeleteSupportingDocsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(clientDeleteSupportingDocsFailure(err));
      return err.response
    }
  };

//================================html-convertor==========================

export const htmlConverterInitiate = () => ({
  type: clientViewAuthAction.CLIENT_VIEW_HTML_CONVERTOR_INITIATE,
});

export const htmlConverterSuccess = (data) => ({
  type: clientViewAuthAction.CLIENT_VIEW_HTML_CONVERTOR_SUCCESS,
  payload: data,
});

export const  htmlConverterFailure = (data) => ({
  type: clientViewAuthAction.CLIENT_VIEW_HTML_CONVERTOR_FAILURE,
  payload: data,
});

export const getHtmlFileClientView = (payload) => async (dispatch) => {
  try {
  const token = isLoggedIn();
    dispatch(htmlConverterInitiate(payload))
    const response = await axios.get(`${Url}/convertToHtml?url=${payload.url}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(htmlConverterSuccess(response.data));
    } else {
      dispatch(htmlConverterFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(htmlConverterFailure(err));
    return err.response;
  }
};
//====================================ADD==adjustment===========================


export const addAdjustmentInitiate = () => ({
  type: clientViewAuthAction.ADD_ADJUSTMENT_INITIATE,
});

export const addAdjustmentSuccess = (data) => ({
  type: clientViewAuthAction.ADD_ADJUSTMENT_SUCCESS,
  payload: data,
});

export const  addAdjustmentFailure = (data) => ({
  type: clientViewAuthAction.ADD_ADJUSTMENT_FAILURE,
  payload: data,
});

export const addAdjustment = (projectId,payload) => async (dispatch) => {
  try {
  const token = isLoggedIn();
    dispatch(addAdjustmentInitiate(payload))
    const response = await axios.post(`${Url}/projects/${projectId}/client/adjustment/request`,payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(addAdjustmentSuccess(response.data));
    } else {
      dispatch(addAdjustmentFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(addAdjustmentFailure(err));
    return err.response;
  }
};

//=================================get--adjustment==================

export const getAdjustmentInitiate = () => ({
  type: clientViewAuthAction.GET_ADJUSTMENT_INITIATE,
});

export const getAdjustmentSuccess = (data) => ({
  type: clientViewAuthAction.GET_ADJUSTMENT_SUCCESS,
  payload: data,
});

export const  getAdjustmentFailure = (data) => ({
  type: clientViewAuthAction.GET_ADJUSTMENT_FAILURE,
  payload: data,
});

export const getAdjustment = (projectId,changeOrderid) => async (dispatch) => {
  try {
  const token = isLoggedIn();
    dispatch(getAdjustmentInitiate())
    const response = await axios.get(`${Url}projects/${projectId}/client/adjustment/request?changeOrderId=${changeOrderid}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getAdjustmentSuccess(response.data));
    } else {
      dispatch(getAdjustmentFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getAdjustmentFailure(err));
    return err.response;
  }
};
//======================delete--adjustment======================


export const removeAdjustmentInitiate = () => ({
  type: clientViewAuthAction.REMOVE_ADJUSTMENT_INITIATE,
});

export const removeAdjustmentSuccess = (data) => ({
  type: clientViewAuthAction.REMOVE_ADJUSTMENT_SUCCESS,
  payload: data,
});

export const  removeAdjustmentFailure = (data) => ({
  type: clientViewAuthAction.REMOVE_ADJUSTMENT_FAILURE,
  payload: data,
});

export const removeAdjustment = (projectId,id) => async (dispatch) => {
  try {
  const token = isLoggedIn();
    dispatch(removeAdjustmentInitiate())
    const response = await axios.delete(`${Url}projects/${projectId}/client/adjustment/request?id=${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(removeAdjustmentSuccess(response.data));
    } else {
      dispatch(removeAdjustmentFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(removeAdjustmentFailure(err));
    return err.response;
  }
};

//==============================GET-notification==================

export const getNotificationsInitiate = () => ({
  type: clientViewAuthAction.GET_NOTIFICATIONS_INITIATE,
});

export const getNotificationsSuccess = (data) => ({
  type: clientViewAuthAction.GET_NOTIFICATIONS_SUCCESS,
  payload: data,
});

export const  getNotificationsFailure = (data) => ({
  type: clientViewAuthAction.GET_NOTIFICATIONS_FAILURE,
  payload: data,
});

export const getNotifications = () => async (dispatch) => {
  try {
    const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
  const token = isLoggedIn();
    dispatch(getNotificationsInitiate())
    const response = await axios.get(`${Url}companies/${clientViewData?.user?.CompanyId}/notifications?timeFrame=Today`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getNotificationsSuccess(response.data));
    } else {
      dispatch(getNotificationsFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getNotificationsFailure(err));
    return err.response;
  }
};

//========================getAskforClient========================

export const getTaskForClientInitiate = () => ({
  type: clientViewAuthAction.GET_TASK_FOR_CLIENT_INITIATE,
});

export const getTaskForClientSuccess = (data) => ({
  type: clientViewAuthAction.GET_TASK_FOR_CLIENT_SUCCESS,
  payload: data,
});

export const  getTaskForClientFailure = (data) => ({
  type: clientViewAuthAction.GET_TASK_FOR_CLIENT_FAILURE,
  payload: data,
});

export const getTaskForClientList = () => async (dispatch) => {
  try {
    const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
    const token = isLoggedIn();
    dispatch(getTaskForClientInitiate())
    const response = await axios.get(`${Url}companies/${clientViewData?.user?.CompanyId}/getTaskForClient`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getTaskForClientSuccess(response.data));
    } else {
      dispatch(getTaskForClientFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getTaskForClientFailure(err));
    return err.response;
  }
};

//====================================craeteComment =======================


export const createClientCommentInitiate = () => ({
  type: clientViewAuthAction.CREATE_COMMENT_INITIATE,
});

export const createClientCommentSuccess = (data) => ({
  type: clientViewAuthAction.CREATE_COMMENT_SUCCESS,
  payload: data,
});

export const createClientCommentFailure = (data) => ({
  type: clientViewAuthAction.CREATE_COMMENT_FAILURE,
  payload: data,
});

  export const createClientComment = (investId,compId,payload) => async (dispatch) => {
      try {
        const token = isLoggedIn();
        dispatch(createClientCommentInitiate(payload));
        const response = await axios.post(`${Url}trade-view/${investId}/discussions?commentableType=Company&commentableId=${compId}`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(createClientCommentSuccess(response.data));
        } else {
          dispatch(createClientCommentFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(createClientCommentFailure(err));
        return err.response
      }
    };
//================================getComment=====================================


export const getCommentInitiate = () => ({
  type: clientViewAuthAction.GET_COMMENT_INITIATE,
});

export const getCommentSuccess = (data) => ({
  type: clientViewAuthAction.GET_COMMENT_SUCCESS,
  payload: data,
});

export const getCommentFailure = (data) => ({
  type: clientViewAuthAction.GET_COMMENT_FAILURE,
  payload: data,
});

  export const getClientCommentList = (investId,compId) => async (dispatch) => {
      try {
        const token = isLoggedIn();
        dispatch(getCommentInitiate());
        const response = await axios.get(`${Url}trade-view/${investId}/discussions?commentableType=Company&commentableId=${compId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(getCommentSuccess(response.data));
        } else {
          dispatch(getCommentFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(getCommentFailure(err));
        return err.response
      }
    };

//====================================craeteSub-Comment =======================


export const createClientSubCommentInitiate = () => ({
  type: clientViewAuthAction.CREATE_SUBCOMMENT_INITIATE,
});

export const createClientSubCommentSuccess = (data) => ({
  type: clientViewAuthAction.CREATE_SUBCOMMENT_SUCCESS,
  payload: data,
});

export const createClientSubCommentFailure = (data) => ({
  type: clientViewAuthAction.CREATE_SUBCOMMENT_FAILURE,
  payload: data,
});

  export const createClientSubComment = (investId,compId,payload) => async (dispatch) => {
      try {
        const token = isLoggedIn();
        dispatch(createClientSubCommentInitiate(payload));
        const response = await axios.post(`${Url}trade-view/${investId}/sub-discussions?commentableType=Company&commentableId=${compId}`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(createClientSubCommentSuccess(response.data));
        } else {
          dispatch(createClientSubCommentFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(createClientSubCommentFailure(err));
        return err.response
      }
    };
//================================getSubComment=====================================


export const getClientSubCommentInitiate = () => ({
  type: clientViewAuthAction.GET_SUBCOMMENT_INITIATE,
});

export const getClientSubCommentSuccess = (data) => ({
  type: clientViewAuthAction.GET_SUBCOMMENT_SUCCESS,
  payload: data,
});

export const getClientSubCommentFailure = (data) => ({
  type: clientViewAuthAction.GET_SUBCOMMENT_FAILURE,
  payload: data,
});

  export const getClientSubCommentList = (investId,disId,compId) => async (dispatch) => {
      try {
        const token = isLoggedIn();
        dispatch(getClientSubCommentInitiate());
        const response = await axios.get(`${Url}trade-view/${investId}/discussions/${disId}/sub-discussions?commentableType=Company&commentableId=${compId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(getClientSubCommentSuccess(response.data));
        } else {
          dispatch(getClientSubCommentFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(getClientSubCommentFailure(err));
        return err.response
      }
    };

//======================SALES-PENDING-TEMPLATE==============

export const salesPenidngTemplatesInitiate = () => ({
  type: clientViewAuthAction.SALE_PENDING_TEMPLATES_INITIATE,
});

export const salesPenidngTemplatesSuccess = (data) => ({
  type: clientViewAuthAction.SALE_PENDING_TEMPLATES_SUCCESS,
  payload: data,
});

export const salesPenidngTemplatesFailure = (data) => ({
  type: clientViewAuthAction.SALE_PENDING_TEMPLATES_FAILURE,
  payload: data,
});

  export const getSalesPenidngTemplates = (compId) => async (dispatch) => {
      try {
        const token = isLoggedIn();
        dispatch(salesPenidngTemplatesInitiate());
        const response = await axios.get(`${Url}companies/${compId}/client/pending-sales-templates`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(salesPenidngTemplatesSuccess(response.data));
        } else {
          dispatch(salesPenidngTemplatesFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(salesPenidngTemplatesFailure(err));
        return err.response
      }
    };
//==================sales-client-approved===================
export const salesApprovedClientDocTemplatesInitiate = () => ({
  type: clientViewAuthAction.SALE_APPROVED_CLIENT_DOC_TEMPLATE_INITIATE,
});

export const salesApprovedClientDocTemplatesSuccess = (data) => ({
  type: clientViewAuthAction.SALE_APPROVED_CLIENT_DOC_TEMPLATE_SUCCESS,
  payload: data,
});

export const salesApprovedClientDocTemplatesFailure = (data) => ({
  type: clientViewAuthAction.SALE_APPROVED_CLIENT_DOC_TEMPLATE_FAILURE,
  payload: data,
});

  export const salesApprovedClientDoc = (compId,clientDoc) => async (dispatch) => {
      try {
        const token = isLoggedIn();
        dispatch(salesApprovedClientDocTemplatesInitiate());
        const response = await axios.patch(`${Url}companies/${compId}/client/approve-sales-templates/${clientDoc}`,{}, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(salesApprovedClientDocTemplatesSuccess(response.data));
        } else {
          dispatch(salesApprovedClientDocTemplatesFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(salesApprovedClientDocTemplatesFailure(err));
        return err.response
      }
    };