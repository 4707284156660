import React, { useState ,useEffect} from "react";
import InvestorViewSidenav from "./investor-view-sidenav";
import InvestorHeader from "./InvestorHeader";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getInvestorFolderNFile,
  getInvestorJobByProject,
  getInvestorView,
  getProjectByBuilder,
} from "../redux/action/investorViewAuthAction";


const initialState = {
  companyId: "",
  projectId: "",
  jobId: "",
  viewId: "",
  searchQuery:"",
};
const FileManagerInvestorView = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const { companyId, projectId, jobId,viewId,searchQuery } = iState;

  const { buliderByProjectList, jobByProjectList ,folderNFileList} = useSelector(
    (state) => state.investorViewLogin
  );
  const compId = JSON.parse(window.localStorage.getItem("investorViewLogin"));


  const builderIdd = JSON.parse(window.localStorage.getItem("builderId"));

  useEffect(() => {
    
    dispatch(getProjectByBuilder(builderIdd));
    dispatch(getInvestorView(compId?.user?.CompanyId)).then((res) => {
      console.log("ress",res);
      updateState({...iState,viewId: res?.data?.ViewSetting?.id})  
  })},[])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "projectId") {
      dispatch(getInvestorJobByProject(builderIdd, value));
      updateState({
        ...iState,
        [name]: value,
        jobId: "",
      });
    } else if (name === "jobId") {
      dispatch(getInvestorFolderNFile(builderIdd, viewId));
      updateState({
        ...iState,
        [name]: value,
      });
    } else {
      updateState({
        ...iState,
        [name]: value,
      });
    }
  };

  const mergedData = folderNFileList?.data?.files?.concat(
    folderNFileList?.data?.folders
  );

  // Filter the merged data based on the search term
  const filteredData = mergedData?.filter((item) => {
    return (
      item.name?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
      item.folderName?.toLowerCase()?.includes(searchQuery.toLowerCase())
    );
  });


  return (
    <>
      <InvestorHeader />
      <InvestorViewSidenav />
      <div className="WrapperAreaClient">
        <div className="WrapperBoxClient">
          <div className="MainTitleBox">
            <div className="MainTitle">
              <h2>System File Manager</h2>
            </div>
          </div>
          <div className="TitleBox">
            <div className="BuilderBox">
              <h4>Project</h4>
              <div className="SelectBuilder">
                <select
                  className="CommonSelect"
                  name="projectId"
                  value={projectId}
                  onChange={handleInputChange}
                >
                  <option value="">Select Project</option>
                  {buliderByProjectList?.data?.length > 0 &&
                    buliderByProjectList?.data?.map((item, i) => {
                      return (
                        <option value={item.id} key={i}>
                          {item.projectName}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>

            <div className="BuilderBox">
              <h4>Job</h4>
              <div className="SelectBuilder">
                <select
                  className="CommonSelect"
                  name="jobId"
                  value={jobId}
                  onChange={handleInputChange}
                >
                  <option value="">Select Jobs</option>
                  {jobByProjectList?.data?.length > 0 &&
                    jobByProjectList?.data?.map((item, i) => {
                      return (
                        <option value={item.id} key={i}>
                          {item.jobType}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>
          <div className="FilesArea mt-4">
            <div className="FilesHead justify-content-end">
              <div className="FilesLeft">
                <aside>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                  <span className="CursorPointer">
                    <img src="images/search.png" />
                  </span>
                </aside>
              </div>
            </div>
          </div>
          <div className="SalesDocumentArea">
          <div className="row">
                {filteredData?.map((item, i) => {
                  const isFile = !!item.name; // Check if it's a file (true) or folder (false)
                  return (
                    <div className="col-md-3" key={i}>
                      <div className="FilesBox2 Safety">
                        <figcaption>
                          <div>
                            <h4>{isFile ? item.name : item.folderName}</h4>
                          </div>
                          <ul>
                            <li className="dropdown">
                              <a
                                className="dropdown-toggle"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" alt="dots" />
                              </a>
                              <ol className="dropdown-menu DropdownMenuSafety">
                                <li>
                                  <a
                                    // onClick={() =>
                                    //   handleViewDocumentationShow(item.docUrl)
                                    // }
                                  >
                                    <img
                                      src="images/view_jobs_details.png"
                                      alt="view details"
                                    />
                                    View Document
                                  </a>
                                </li>
                                {isFile && (
                                  <li>
                                    <a href={item.docUrl} target="_self">
                                      <img
                                        src="images/download.png"
                                        alt="download"
                                      />
                                      Download documents
                                    </a>
                                  </li>
                                )}
                              </ol>
                            </li>
                          </ul>
                        </figcaption>
                        <div className="FilesBoxCenterSafety">
                          <img src="images/file.png" alt="file" />{" "}
                          <h6>{isFile ? "File" : "Folder"}</h6>{" "}
                          <img src="images/red_esc.png" alt="esc" />
                        </div>
                        <figcaption>
                          <div>
                            <p className="CursorPointer">
                              Last update{" "}
                              {moment(item.updatedAt).format("YYYY-MM-DD")}
                            </p>
                          </div>
                          <ul>
                            {isFile && (
                              <li>
                                <a
                                  // onClick={() =>
                                  //   handleDocumentHistoryModalShow(
                                  //     item.FolderId
                                  //   )
                                  // }
                                  
                                >
                                  <img src="images/timer.png" alt="timer" />
                                </a>
                              </li>
                            )}
                          </ul>
                        </figcaption>
                      </div>
                    </div>
                  );
                })}
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileManagerInvestorView;
