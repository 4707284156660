import React, { useEffect, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { getWarrantyAddNewSupportDocs, getWarrantyRequestList, getWarrantyWorkOrderList } from "../redux/action/clientViewAuthAction";
import { Link } from "react-router-dom";
import ClientViewSidenav from "./client-view-sidenav";
import ClientHeader from "./ClientHeader";
import Loader from "./Loader";

const initialState={
  AddNewSupportingDocsModal:false,
  warntId:'',
  supportingDocFiless:null,
}

const HouseWarranty = () => {
  const dispatch = useDispatch();
  const [iState,updateState]=useState(initialState);
  const {AddNewSupportingDocsModal,warntId,supportingDocFiless}=iState;

  const { warrantyRequestloader, warrantyRequestList ,warrantyWorkOrderloader,warrantyWorkOrderList} = useSelector(
    (state) => state.clientViewLogin
  );
  console.log("warrantyRequestList", warrantyRequestList);
  console.log("warrantyWorkOrderList", warrantyWorkOrderList);

  useEffect(() => {
    dispatch(getWarrantyRequestList());
    dispatch(getWarrantyWorkOrderList());
  }, []);

  
  const handleFileChanges = (event, type) => {
    const file = event?.target?.files[0];
    if (type === "docsUpdate") {
      updateState({ ...iState, supportingDocFiless: file });
    }
  };

  const handleRemoveDocsFile = () => {
    // Reset the 'templateFile' state to null
    updateState({ ...iState, supportingDocFiless: null });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#docsInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleAddNewSupportingDocsModalShow = (id) => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: true,
      warntId: id,
    });
  };
  const handleAddNewSupportingDocsModalClose = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: false,
    });
  };

  const handleSupportDocs = () => {
    let dataa = {
      supportingDocFiles: supportingDocFiless,
    };
    dispatch(getWarrantyAddNewSupportDocs(warntId, dataa)).then((res) => {
      if (res.status === 201) {
       
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AddNewSupportingDocsModal: false,
          warntId: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          AddNewSupportingDocsModal: true,
        });
      }
    });
  };

  const clientViewData = JSON.parse(window.localStorage.getItem("clientViewLogin"));
  return (
    <>
       <ClientHeader/>
      <ClientViewSidenav />
      
      <div className="WrapperAreaClient">
        <div className="WrapperBoxClient">
          <div className="MainTitleBox d-flex flex-md-row flex-column align-items-md-center align-items-start">
            <div className="MainTitle">
              <h2>House Warranty</h2>
            </div>
            <div className="TodosButtons">
              <Link to="/warranty-request-client" id="NewWarrantyRequestBtn">
                <h6>
                  <img src="images/new-warranty-request.png" />
                  New Warranty Request
                </h6>
                {/* <span>3</span> */}
              </Link>
            </div>
          </div>
          <div className="TitleBox d-flex flex-sm-row flex-column align-items-sm-center align-items-start">
            <div className="TodosButtons mt-0">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#WarrantyRequest"
                    id="WarrantyRequestTab"
                  >
                    <img src="images/warranty-request.png" />
                    Warranty Request<span>3</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#WorkOrders"
                    id="WorkOrdersTab"
                  >
                    <img src="images/work-order-red.png" />
                    Work Orders<span>3</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="TitleBoxProjectHeading">
              <h6>
                <span>Project:</span>{clientViewData?.projectDetails?.projectName??"N/A"}
              </h6>
              <h6>
                <span>Unit:</span> {clientViewData?.projectDetails?.id??"N/A"}
              </h6>
            </div>
          </div>
          <div className="tab-content">
            <div className="tab-pane active show" id="WarrantyRequest">
              <div className="Tableheader">
                <h6>Warranty Request</h6>
                <div className="NewActions">
                  <a href="javascript:void(0);">
                    <img src="images/icon-64.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/download.png" />
                  </a>
                </div>
              </div>
              <div className="TableList TableListHeader NewTableList TableHeightScroll">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Urgency</th>
                      <th>Description</th>
                      <th>Date Created</th>
                      <th>Type of Warranty</th>
                      <th>Assigned/# of Warranty Items</th>
                      <th>Status</th>
                      <th>Supporting Doc</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      !warrantyRequestloader?
                      warrantyRequestList?.warranties?.length>0?
                      warrantyRequestList?.warranties?.map((item,i)=>{
                        return(
                          <tr key={i}>
                          <td>
                          <a>{item?.id}</a>
                          </td>
                          <td>
                            <a href="javascript:void(0);">
                              <div className="GreenTextTd">
                                {item.urgency?.name??"N/A"}
                              </div>
                            </a>
                          </td>
                          <td>
                            <a href="javascript:void(0);">{item.description}</a>
                          </td>
                          <td>
                            <a href="javascript:void(0);">{item.createdAt?.slice(0,10)}</a>
                          </td>
                          <td>
                                <a>{item?.typeOfWarranty ?? "N/A"}</a>
                              </td>
                              <td>
                                <a>{item?.assignedOfWarrantyItems ?? "N/A"}</a>
                              </td>
                              <td>{item?.progressStatus ?? "N/A"}</td>
                          <td>
                            <div className="Actions">
                              <a
                                onClick={()=>handleAddNewSupportingDocsModalShow(item.id)}
                              >
                                <img src="images/icon-63.png" />
                              </a>
                              <a
                                href="javascript:void(0)"
                                data-toggle="modal"
                                data-target="#SupportingDocumentList"
                              >
                                <span className="Count">3</span>
                              </a>
                            </div>
                          </td>
                          <td>
                            <div className="DetailsDots">
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu ChangeOrderDropdown">
                                      <li>
                                        <Link to="/warranty-request-client-details" state={item}>
                                          <img src="images/view-po.png" />
                                          View Warranty Request Details
                                        </Link>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                        )
                      }):(
                        <tr>
                          <td colSpan="5">No data found</td>
                        </tr>
                      )
                      :<Loader/>
                    }
                  
                    
                  </tbody>
                </table>
              </div>
            </div>
            <div className="tab-pane fade" id="WorkOrders">
              <div className="Tableheader">
                <h6>Work Order</h6>
                <div className="NewActions">
                  <a href="javascript:void(0);">
                    <img src="images/icon-64.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/download.png" />
                  </a>
                </div>
              </div>
              <div className="TableList NewTableList TableListHeader TableHeightScroll">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Description</th>
                      <th>Home Owner Accepted</th>
                      <th>Date Created</th>
                      <th>Vendor/ Technician name</th>
                      <th>Trades Day</th>
                      <th>Completed/# Work Order Items</th>
                      <th>Status</th>
                      <th>Supporting Doc</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  
                        <tr>
                          <td colSpan="5">No data found</td>
                        </tr>
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
          className="ModalBox fade SmallModal"
          show={AddNewSupportingDocsModal}
          onHide={handleAddNewSupportingDocsModalClose}
        >
          <Modal.Body>
            <div className="Category">
              <a
                className="CloseModal"
                onClick={handleAddNewSupportingDocsModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Add New Supporting Docs</h4>
                </div>
                <div className="JobTitleArea">
                  <h3>Supporting Documents #2</h3>
                  <div className="JobBox">
                    <h6>
                      <span>Job:</span> UUnit3-CHI-2B-315
                    </h6>
                  </div>
                  <div className="JobBox">
                    <h6>
                      <span>Origin</span> #185
                    </h6>
                  </div>
                  <div className="JobBox">
                    <h6>
                      <span>Item:</span> #143
                    </h6>
                  </div>
                </div>
                <div className="form-group">
                  <h6>Select Files</h6>
                  <div className="Upload">
                    <span>-Drag and Drop Files or click to select</span>
                    <input
                      type="file"
                      id="docsInput"
                      onChange={(e) => handleFileChanges(e, "docsUpdate")}
                    />
                  </div>
                </div>
                <div className="FilesAddedTitle">
                  <h4>Files</h4>
                </div>
                {supportingDocFiless && (
                  <div className="FilesAdded">
                    <label>{supportingDocFiless?.name}</label>
                    <span>
                      <img src="images/fileview.png" />
                    </span>
                    <span onClick={handleRemoveDocsFile}>
                      <img src="images/Cross.png" className="cross" />
                    </span>
                  </div>
                )}

                <div className="Button mt-3">
                  <button className="Create" onClick={handleSupportDocs}>
                    Add Documents
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
    </>
  );
};

export default HouseWarranty;
