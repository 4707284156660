import React, { useState } from 'react'
import Header from './Header'
import SideNav from './SideNav';
import { Modal } from "react-bootstrap";
import { errorToast, sucessToast } from "../utils/toast";
import CommonModal from './Modal';

const initModal = {
  AddLeadModal: false,
  contactInformationModal: false,
  SOAHistoryModal: false,
  assignSaleModal: false,
  AddContractModal: false,
  ClientDocumentationModal: false,
  sendClientInformationModal: false,
  createClientCTCModal: false,
  manageSignatureModal: false,
  folderHistoryModal: false,
  documentHistoryModal: false,
  discussionModal: false,
  editFileModal: false,
  shareDocumentsModal: false,
  editLeadDetailsModal: false,
  editContactInformationModal: false,
  editSurveyModal: false,
  supportingDocsModal: false,
  SystemVendorModal: false,
  viewDocumentModal: false,
  locationModal: false,
  createNewFileModal: false,
  uploadFilesModal: false,
  leadClientDetailsShow: false,
  editActionLeadDetailsModal: false,
  scheduleNewFollowModal: false,
  convertLeadToContractModal: false,
  editFollowUpActivityModal: false,
  addItineraryModal: false,
  setReminderModal: false,
  modalState: "",
  newEmailModal: false,
  newTextModal: false,
  adjustmentStatementModal: false,
  supportingDocumentListShow: false,
  mainContractEditModal: false,
  contractAssignUnitModal: false,
  contractTransactionsModal: false,
  contractSchedulePaymentsModal: false,
  contractAddSupportingModal: false,
  ContractSupportingDocsModal: false,
  editSupportingModal: false,
  scheduledPaymentModal: false,
  editScheduledPaymentModal: false,
  clientContractDetailsModal:false,
  viewUpdateJobDetailsModal:false,
  postalTransactionModal:false

}
const TransactionManager = () => {
  const [iModalState, updateModalState] = useState(initModal);
  const {
    AddLeadModal,
    contactInformationModal,
    assignSaleModal,
    AddContractModal,
    ClientDocumentationModal,
    sendClientInformationModal,
    createClientCTCModal,
    manageSignatureModal,
    folderHistoryModal,
    documentHistoryModal,
    discussionModal,
    shareDocumentsModal,
    editFileModal,
    editLeadDetailsModal,
    editContactInformationModal,
    editSurveyModal,
    supportingDocsModal,
    SystemVendorModal,
    viewDocumentModal,
    locationModal,
    createNewFileModal,
    uploadFilesModal,
    leadClientDetailsShow,
    editActionLeadDetailsModal,
    scheduleNewFollowModal,
    convertLeadToContractModal,
    editFollowUpActivityModal,
    addItineraryModal,
    setReminderModal,
    newEmailModal,
    newTextModal,
    modalState,
    adjustmentStatementModal,
    supportingDocumentListShow,
    mainContractEditModal,
    updateSatisfiedConditionModal,
    contractAssignUnitModal,
    contractTransactionsModal,
    contractSchedulePaymentsModal,
    contractAddSupportingModal,
    ContractSupportingDocsModal,
    editSupportingModal,
    scheduledPaymentModal,
    SOAHistoryModal,
    editScheduledPaymentModal,
    clientContractDetailsModal,
    viewUpdateJobDetailsModal,
    postalTransactionModal

  } = iModalState;


  const hideDiscussionModal = () => {
    updateModalState({ ...iModalState, discussionModal: false });
  };

  const showDiscussionModal = () => {
    updateModalState({ ...iModalState, discussionModal: true });
  };

  const showScheduledPaymentModal = (modalState) => {
    updateModalState({ ...iModalState, scheduledPaymentModal: true, })
  }

  const hideScheduledPaymentModal = () => {
    updateModalState({ ...iModalState, scheduledPaymentModal: false, })
  }

  const hideContractTransactionsModal = () => {
    if (modalState === "scheduledPayment") {
      updateModalState({ ...iModalState, contractTransactionsModal: false, scheduledPaymentModal: true });
    // } else if (modalState === "scheduledPayment") {
    //   updateModalState({ ...iModalState, contractTransactionsModal: false, scheduledPaymentModal: true });
    // }
     } else {
      updateModalState({ ...iModalState, contractTransactionsModal: false });
    }
  };

  const showContractTransactionsModal = (modalState) => {
    if (modalState === "scheduledPayment") {
      updateModalState({ ...iModalState, contractTransactionsModal: true, scheduledPaymentModal: false , modalState:modalState });
    } else {
      updateModalState({ ...iModalState, contractTransactionsModal: true, modalState:modalState });
    }
  };

  const hideAdjustStatementModal = () => {
    updateModalState({ ...iModalState, adjustmentStatementModal: false });
  };

  const showAdjustStatementModal = (modalState) => {
    updateModalState({ ...iModalState, adjustmentStatementModal: true });
  };

  const hideLeadClientDetailsModal = () => {
    updateModalState({ ...iModalState, leadClientDetailsShow: false });
  };

  const showLeadClientDetailModal = () => {
    updateModalState({ ...iModalState, leadClientDetailsShow: true });
  };

  const showEditFollowUpActivityModal = (modalState) => {
    updateModalState({
      ...iModalState,
      editFollowUpActivityModal: true,
      discussionModal: false,
    });
  };
  const hideEditFollowUpActivityModal = () => {
    updateModalState({
      ...iModalState,
      editFollowUpActivityModal: false,
      // modalState: modalState,
      discussionModal: true,
    });
  };

  const hideSetReminderModal = () => {
    if (modalState == "discussion") {
      updateModalState({
        ...iModalState,
        setReminderModal: false,
        discussionModal: true,
      })
    } else if (modalState === "itinerary") {
      updateModalState({
        ...iModalState,
        setReminderModal: false,
        addItineraryModal: true,
        modalState: modalState
      })
    } else if (modalState === "scheduledPayment") {
      updateModalState({
        ...iModalState,
        setReminderModal: false,
        scheduledPaymentModal: true,
        modalState: modalState
      })
    } else {
      updateModalState({
        ...iModalState,
        setReminderModal: false,
        editFollowUpActivityModal: true,
      })
    }
  };

  const showSetReminderModal = (modalState) => {
    if (modalState === "discussion") {
      updateModalState({
        ...iModalState,
        setReminderModal: true,
        discussionModal: false,
        modalState: modalState
      })
    } else if (modalState === "itinerary") {
      updateModalState({
        ...iModalState,
        setReminderModal: true,
        addItineraryModal: false,
        modalState: modalState
      })
    } else if (modalState === "scheduledPayment") {
      updateModalState({
        ...iModalState,
        setReminderModal: true,
        scheduledPaymentModal: false,
        modalState: modalState
      })
    } else {
      updateModalState({
        ...iModalState,
        setReminderModal: true,
        editFollowUpActivityModal: false,
        modalState: modalState
      })
    }
  };

  const showAssignSaleModal = (state) => {
    if (state === "itinerary") {
      updateModalState({
        ...iModalState,
        assignSaleModal: true,
        addItineraryModal: false,
        modalState: state,
      });
    } else if (state === "discussion") {
      updateModalState({
        ...iModalState,
        assignSaleModal: true,
        discussionModal: false,
        modalState: state
      })
    } else if (state === "editContract") {
      updateModalState({
        ...iModalState,
        assignSaleModal: true,
        editContactInformationModal: false,
        modalState: state
      })
    }  else {
      updateModalState({
        ...iModalState,
        assignSaleModal: true,
        editFollowUpActivityModal: false,
        modalState: state,
      });
    }

  };

  const hideAssignSaleModal = () => {
    if (modalState === "itinerary") {
      updateModalState({
        ...iModalState,
        // AddLeadModal: true,
        assignSaleModal: false,
        addItineraryModal: true
      });
    } else if (modalState === "editContract") {
      updateModalState({
        ...iModalState,
        assignSaleModal: false,
        editContactInformationModal: true,
        modalState: modalState
      })
    }
     else if (modalState === "discussion") {
      updateModalState({
        ...iModalState,
        assignSaleModal: false,
        discussionModal: true,
        modalState: modalState
      })
    } else {
      updateModalState({
        ...iModalState,
        editFollowUpActivityModal: true,
        assignSaleModal: false,
      });
    }

  };

  const hideAddItineraryModal = () => {
    updateModalState({
      ...iModalState,
      addItineraryModal: false,
      discussionModal: true,
    });
  };

  const showAddItineraryModal = (modalState) => {
    updateModalState({
      ...iModalState,
      addItineraryModal: true,
      discussionModal: false,
      // modalState: modalState,
    });

  };

  const hideSOAHistoryModal = () => {
    if(modalState === "SOA"){
      updateModalState({
        ...iModalState,
        SOAHistoryModal: false,
        adjustmentStatementModal: true,
        modalState: "",
      });
    } 
    else {
      updateModalState({
        ...iModalState,
        SOAHistoryModal: false,
        discussionModal: true,
        modalState: "",
      });
  };
}

  const showSOAHistoryModal = (state) => {
    if(state === "SOA"){
      updateModalState({
        ...iModalState,
        SOAHistoryModal: true,
        adjustmentStatementModal: false,
        modalState: state,
      });
    }else {
      updateModalState({
        ...iModalState,
        SOAHistoryModal: true,
        discussionModal: false,
        modalState: state,
      });
    }
    

  };

  const hideEditScheduledPaymentModal = () => {
    updateModalState({
      ...iModalState,
      editScheduledPaymentModal: false,
      scheduledPaymentModal:true
      // discussionModal: true,
    });
  };

  const showEditScheduledPaymentModal = (modalState) => {
    // if(modalState === "scheduledPayment"){

    // }
    updateModalState({
      ...iModalState,
      editScheduledPaymentModal: true,
      // discussionModal: false,
      scheduledPaymentModal:false,
      modalState: modalState,
    });
  };
  const hideSupportingDocsModal = () => {
    if (modalState === "contractSupporticngDocs") {
      updateModalState({ ...iModalState, supportingDocsModal: false, supportingDocumentListShow: true });
    } else if (modalState === "transaction") {
      updateModalState({ ...iModalState, supportingDocsModal: false, contractTransactionsModal: true });
    } else if (modalState === "supportingList") {
      updateModalState({ ...iModalState, supportingDocsModal: false, contractTransactionsModal: true });
    }else if (modalState === "SOA"){
      updateModalState({ ...iModalState, supportingDocsModal: false, adjustmentStatementModal: true, modalState: "" });
    } else if (modalState === "contractSupportingDocs"){
      updateModalState({ ...iModalState, supportingDocsModal: false, ContractSupportingDocsModal: true, modalState: "" });
    } 
    else
    //  if (modalState === "transactionQueue")
     {
      updateModalState({...iModalState, supportingDocsModal:false})
    }
  };

  const showSupportingDocsModal = (state) => {
    if (state === "contractv2SupportingDocs") {
      updateModalState({ ...iModalState, supportingDocsModal: true, supportingDocumentListShow: false, modalState: state });
    } else if (state === "transaction") {
      updateModalState({ ...iModalState, supportingDocsModal: true, contractTransactionsModal: false, modalState: state });
    } else if (state === "supportingList") {
      updateModalState({ ...iModalState, supportingDocsModal: true, contractTransactionsModal: false, modalState: state });
    } else if (state === "SOA"){
      updateModalState({ ...iModalState, supportingDocsModal: true, adjustmentStatementModal: false, modalState: state });
    } else if (state === "contractSupportingDocs"){
      updateModalState({ ...iModalState, supportingDocsModal: true,  ContractSupportingDocsModal: false, modalState: state });
    } 
     else
    //  if (modalState === "transactionQueue")
     {
      updateModalState({...iModalState, supportingDocsModal:true})
    }
  }



  const hideEditSupportingModal = () => {
    updateModalState({ ...iModalState, editSupportingModal: false, ContractSupportingDocsModal: true })
  }

  const showEditSupportingModal = (modalState) => {
    updateModalState({ ...iModalState, editSupportingModal: true, ContractSupportingDocsModal: false })
  }

  const showContractSupportingListModal = (state) => {
    console.log("sateee", state)
    if(state === "SOA"){
      updateModalState({ ...iModalState, ContractSupportingDocsModal: true, adjustmentStatementModal: false,modalState:state });
    } else if (state == "transaction"){
      updateModalState({ ...iModalState, ContractSupportingDocsModal: true, contractTransactionsModal: false,modalState:state });
    }
      else {
      updateModalState({ ...iModalState, ContractSupportingDocsModal: true, modalState:state });
    } 
  };

  const hideContractSupportingListModal = () => {
    console.log("sateee", modalState)
    if(modalState === "SOA"){
      updateModalState({ ...iModalState, ContractSupportingDocsModal: false, adjustmentStatementModal: true });
    } 
    else if (modalState === "transaction"){
      updateModalState({ ...iModalState, ContractSupportingDocsModal: false, contractTransactionsModal: true,modalState:"" });
    }
    else {
      updateModalState({ ...iModalState, ContractSupportingDocsModal: false, });
    }
  };


  const showClientContractDetailsModal = (modalState) => {
    updateModalState({ ...iModalState, clientContractDetailsModal: true, })
  }

  const hideClientContractDetailsModal = () => {
    updateModalState({ ...iModalState, clientContractDetailsModal: false, })
  }
  const hideEditContactInformationModal = () => {
    updateModalState({ ...iModalState, editContactInformationModal: false });
  };

  const showEditContactInformationModal = () => {
    updateModalState({ ...iModalState, editContactInformationModal: true });
  };
  const hideContactInformationModal = () => {
    if(modalState === "contract"){
      updateModalState({
        ...iModalState,
        contactInformationModal: false,
        editContactInformationModal: true,
      });
    } else {
      updateModalState({
        ...iModalState,
        contactInformationModal: false,
        AddLeadModal: true,
      });
    }
  };  const showContactInformationModal = (id) => {
    if(id === "contract"){
      updateModalState({
        ...iModalState,
        contactInformationModal: true,
        editContactInformationModal: false,
        modalState:id
      });
    } else {
      updateModalState({
        ...iModalState,
        contactInformationModal: true,
        AddLeadModal: false,
        modalState:id
      });
    }
    }
const showViewUpdateJobDetailsModal = (state) => {
      if(state === "Edit") {

      } else {
        updateModalState({...iModalState, viewUpdateJobDetailsModal:true})
      }
}
const hideViewUpdateJobDetailsModal = () => {
  if(modalState === "Edit") {

  } else {
    updateModalState({...iModalState, viewUpdateJobDetailsModal:false})
  }
}



const showPostalTransactionModal = (state) => {
  updateModalState({...iModalState, postalTransactionModal:true})
}

const hidePostalTransactionModal = () => {
updateModalState({...iModalState, postalTransactionModal:false})
}


const showClientDocumentatioModal = (id) => {
  if (id == "editLeadDetails") {
    updateModalState({
      ...iModalState,
      ClientDocumentationModal: true,
      editLeadDetailsModal: false,
      modalState: id,
    });
  } else {
    updateModalState({ ...iModalState, ClientDocumentationModal: true, clientContractDetailsModal:false  });
  }
};
const hideClientDocumentationModal = () => {
  if (modalState == "editLeadDetails") {
    updateModalState({
      ...iModalState,
      ClientDocumentationModal: false,
      editLeadDetailsModal: true,
      modalState: "",
    });
  } else {
    updateModalState({ ...iModalState, ClientDocumentationModal: false, clientContractDetailsModal:true });
  }
};

const showLocationModal = (id) => {
  updateModalState({
    ...iModalState,
    locationModal: true,
    ClientDocumentationModal: false,
  });
};
const hideLocationModal = () => {
  updateModalState({
    ...iModalState,
    locationModal: false,
    ClientDocumentationModal: true,
  });
};

const showFolderHistoryModal = (id) => {
  updateModalState({
    ...iModalState,
    folderHistoryModal: true,
    ClientDocumentationModal: false,
  });
};
const hideFolderHistoryModal = () => {
  updateModalState({
    ...iModalState,
    folderHistoryModal: false,
    ClientDocumentationModal: true,
  });
};

const showEditFileModal = (id) => {
  updateModalState({
    ...iModalState,
    editFileModal: true,
    ClientDocumentationModal: false,
  });
};
const hideEditFileModal = () => {
  updateModalState({
    ...iModalState,
    editFileModal: false,
    ClientDocumentationModal: true,
  });
};
const showClientInformationModal = (id) => {
  updateModalState({
    ...iModalState,
    sendClientInformationModal: true,
    ClientDocumentationModal: false,
  });
};
const hideClientInformationModal = () => {
  updateModalState({
    ...iModalState,
    sendClientInformationModal: false,
    ClientDocumentationModal: true,
  });
};
const showCreateClientCTCModal = (id) => {
  updateModalState({
    ...iModalState,
    createClientCTCModal: true,
    ClientDocumentationModal: false,
  });
};
const hideCreateClientCTCModal = () => {
  updateModalState({
    ...iModalState,
    createClientCTCModal: false,
    ClientDocumentationModal: true,
  });
};
const showManageSignatureModal = (id) => {
  updateModalState({
    ...iModalState,
    manageSignatureModal: true,
    ClientDocumentationModal: false,
  });
};
const hideManageSignatureModal = () => {
  updateModalState({
    ...iModalState,
    manageSignatureModal: false,
    ClientDocumentationModal: true,
  });
};

const showUploadFilesModal = (id) => {
  updateModalState({
    ...iModalState,
    uploadFilesModal: true,
    ClientDocumentationModal: false,
  });
};
const hideUploadFilesModal = () => {
  updateModalState({
    ...iModalState,
    uploadFilesModal: false,
    ClientDocumentationModal: true,
  });
};
const showCreateNewFileModal = (id) => {
  updateModalState({
    ...iModalState,
    createNewFileModal: true,
    ClientDocumentationModal: false,
  });
};
const hideCreateNewFileModal = () => {
  updateModalState({
    ...iModalState,
    createNewFileModal: false,
    ClientDocumentationModal: true,
  });
};
const showViewFileModal = (id) => {
  updateModalState({
    ...iModalState,
    viewDocumentModal: true,
    ClientDocumentationModal: false,
  });
};
const hideViewFileModal = () => {
  updateModalState({
    ...iModalState,
    viewDocumentModal: false,
    ClientDocumentationModal: true,
  });
};
const showShareDocumentModal = () => {
  updateModalState({
    ...iModalState,
    shareDocumentsModal: true,
    ClientDocumentationModal: false,
  });
};

const hideShareDocumentModal = () => {
  updateModalState({
    ...iModalState,
    shareDocumentsModal: false,
    ClientDocumentationModal: true,
  });
};
const showDocumentHistoryModal = (id) => {
  updateModalState({
    ...iModalState,
    documentHistoryModal: true,
    ClientDocumentationModal: false,
  });
};
const hideDocumentHistoryModal = () => {
  updateModalState({
    ...iModalState,
    documentHistoryModal: false,
    ClientDocumentationModal: true,
  });
};

const handleSystemVendorModalClose = () => {
  updateModalState({
    ...iModalState,
    SystemVendorModal: false,
    sendClientInformationModal: true,
  });
};

const handleSystemVendorModalShow = (data) => {
  updateModalState({
    ...iModalState,
    SystemVendorModal: true,
    sendClientInformationModal: false,
    vendorName: "",
    vendorType: "",
  });
};

  return (
    <>
      <Header />

      <CommonModal adjustmentStatementModal={adjustmentStatementModal} hideAdjustStatementModal={hideAdjustStatementModal} />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox" >
          <div className="TodosArea">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <div className="TitleBox bg-none border-0 p-0">
                  <h4 className="Title">Transaction Manager</h4>
                </div>
                <div className="CommonTabs mt-3">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="ClientContractsTab"
                        data-toggle="tab"
                        href="#ClientContracts"
                      >
                        <span>
                          <img src="images/client_contracts.png" />
                        </span>
                        Client Contracts
                      </a>
                      <span className="Count">3</span>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="NewTransactionQueueTab"
                        data-toggle="tab"
                        href="#NewTransactionQueue"
                      >
                        <span>
                          <img src="images/new_transaction_queue.png" />
                        </span>
                        New Transaction Queue
                      </a>
                      <span className="Count">3</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="TodosButtons mt-0">
                <a href="javascript:void(0)" id="PajetBtn">
                  <img src="images/create_new_lead.png" />
                  Post All Journal Entry Transactions{" "}
                  <span className="Count">3</span>
                </a>

              </div>
            </div>
          </div>
          <div className="tab-content">
            <div className="tab-pane fade" id="ClientContracts">
              <div className="TableHeadingArea mt-5">
                <div className="FilesHead">
                  <div className="FilesLeft">
                    <h4>Contracts</h4>
                  </div>
                  <div className="NewActions">
                    <a href="Javascript:void(0);">
                      <img src="images/icon-64.png" />
                    </a>
                    <a href="Javascript:void(0);">
                      <img src="images/icon-53.png" />
                    </a>
                    <a href="Javascript:void(0);">
                      <img src="images/download.png" />
                    </a>
                    <a href="Javascript:void(0);">
                      <img src="images/setting.png" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="TableList CommonHeading TableMinimumHeight">
                <table>
                  <thead>
                    <tr>
                      <th>Client Contact</th>
                      <th>Date Created</th>
                      <th>Sale Type</th>
                      <th>Client Assigned Unit</th>
                      <th>Client Purchase Price</th>
                      <th>Status</th>
                      <th>Scheduled Payments</th>
                      <th>Transactions</th>
                      <th>SOA Status</th>
                      <th>View SOA</th>
                      <th>Next Scheduled Transaction</th>
                      <th>Assigned to</th>
                      <th>Discussion</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="TextBlue">John Contract</div>
                      </td>
                      <td>05/24/2023</td>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>
                        <div
                          className="CursorPointer"
                          onClick={showScheduledPaymentModal}
                        >
                          <img src="images/schedule_payment.png" />
                        </div>
                      </td>
                      <td>
                        <div
                          className="CursorPointer"
                          onClick={() => showContractTransactionsModal("clientContractList")}
                        >
                          <img src="images/add_transactions.png" />
                        </div>
                      </td>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>
                        <div className="text-center">
                          <a href="javascript:void(0);">
                            <img src="images/discussion_icon.png" />
                          </a>
                          <span className="Count">3</span>
                        </div>
                      </td>
                      <td>
                        <div className="TableThreeDots">
                          <ul className="">
                            <li className="dropdown">
                              <a className="" role="button" data-toggle="dropdown">
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    onClick={showClientContractDetailsModal}
                                  >
                                    <img src="images/client_contracts_details.png" />{" "}
                                    Client Contract Details
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    onClick={() =>showEditContactInformationModal("clientContractList")}
                                  >
                                    <img src="images/edit_contact.png" />
                                    Edit Contact Information
                                  </a>
                                </li>
                                <li>
                                  <a onClick={() =>showViewUpdateJobDetailsModal("clientContractList")} href="javascript:void(0);">
                                    <img src="images/view_update.png" />
                                    View/Update job details
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                     onClick={() =>showScheduledPaymentModal("clientContractList")}
                                  >
                                    <img src="images/schedule_payment.png" />{" "}
                                    Scheduled Payments
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#TransactionsDesktop"
                                  >
                                    <img src="images/add_transactions.png" />{" "}
                                    Transactions
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#StatementOfAdjustments"
                                  >
                                    <img src="images/visa_icon.png" />
                                    View SOA
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>


                    <tr>
                      <td>
                        <div className="TextBlue">Joice Contracts</div>
                      </td>
                      <td>05/24/2023</td>
                      <td>Multiple</td>
                      <td>
                        <div className="TextBlue">U37-ARB-1B-243 (Unit #37)</div>
                      </td>
                      <td>$754,000.00</td>
                      <td>Fully Satisfied Conditions</td>
                      <td>
                        <div
                          className="CursorPointer"                       
                          onClick={showScheduledPaymentModal}
                        >
                          <img src="images/schedule_payment.png" />
                        </div>
                      </td>
                      <td>
                        <div
                          className="CursorPointer"
                          onClick={() => showContractTransactionsModal("clientContractList")}
                          data-target="#TransactionsDesktop"
                        >
                          <img src="images/add_transactions.png" />
                        </div>
                      </td>
                      <td>
                        <div className="TextRed">
                          Pending Builder's Signing Authority Signature
                        </div>
                      </td>
                      <td>
                        <div className="Actions">
                          <a
                            href="javascript:void(0);"
                            onClick={() => showAdjustStatementModal("clientContractList")}
                          >
                            <img src="images/visa_icon.png" />
                          </a>
                          <a
                            href="javascript:void(0)"
                            onClick={() => showSOAHistoryModal("clientContractList")}
                            data-target="#TransactionsTable"
                          >
                            <span className="Count">3</span>
                          </a>
                        </div>
                      </td>
                      <td>Friday, June 16, 2023</td>
                      <td>
                        <div>
                          <img onClick={showLeadClientDetailModal} src="images/icon-32.png" />
                        </div>
                      </td>
                      <td>
                        <div className="text-center">
                          <a onClick={showDiscussionModal} href="javascript:void(0);">
                            <img src="images/discussion_icon.png" />
                          </a>
                          <span className="Count">3</span>
                        </div>
                      </td>
                      <td>
                        <div className="TableThreeDots">
                          <ul className="">
                            <li className="dropdown">
                              <a className="" role="button" data-toggle="dropdown">
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#CreateNewContract"
                                  >
                                    <img src="images/client_contracts_details.png" />{" "}
                                    Client Contract Details
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#EditContactInformation"
                                  >
                                    <img src="images/edit_contact.png" />
                                    Edit Contact Information
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/view_update.png" />
                                    View/Update job details
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#ScheduledPayments"
                                  >
                                    <img src="images/schedule_payment.png" />{" "}
                                    Scheduled Payments
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#TransactionsDesktop"
                                  >
                                    <img src="images/add_transactions.png" />{" "}
                                    Transactions
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#StatementOfAdjustments"
                                  >
                                    <img src="images/visa_icon.png" />
                                    View SOA
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="tab-pane fade show active" id="NewTransactionQueue">
              <div className="TableHeadingArea mt-5">
                <div className="FilesHead">
                  <div className="FilesLeft">
                    <h4>Transactions</h4>
                  </div>
                  <div className="NewActions">
                    <a href="Javascript:void(0);">
                      <img src="images/icon-64.png" />
                    </a>
                    <a href="Javascript:void(0);">
                      <img src="images/icon-53.png" />
                    </a>
                    <a href="Javascript:void(0);">
                      <img src="images/download.png" />
                    </a>
                    <a href="Javascript:void(0);">
                      <img src="images/setting.png" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="TableList CommonHeading TableMinimumHeight">
                <table>
                  <thead>
                    <tr>
                      <th>Client Contact</th>
                      <th>Job Assigned</th>
                      <th>Transaction ID#</th>
                      <th>Transaction/ Adjustment Date</th>
                      <th>Payment Method</th>
                      <th>Transaction Type</th>
                      <th>Total</th>
                      <th>Notes</th>
                      <th>Debit</th>
                      <th>Credit</th>
                      <th>Status</th>
                      <th>Supporting Documents</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="TextBlue">John Contact</div>
                      </td>
                      <td>U38-ARB-1B-243</td>
                      <td>DP-ARB-1B-243-1</td>
                      <td>06/14/20 23</td>
                      <td>Mastercard</td>
                      <td>DP: Down Payment Deposit</td>
                      <td>$1,000.00</td>
                      <td>Initial deposit</td>
                      <td />
                      <td />
                      <td>
                        <button className="Commited">Commited</button>
                      </td>
                      <td>
                        <div className="Actions">
                          <a
                            href="javascript:void(0);"
                            onClick={() =>showSupportingDocsModal("transactionQueue")}
                          >
                            <img src="images/icon-63.png" />
                          </a>
                          <a
                            href="javascript:void(0)"
                            onClick={() =>showContractSupportingListModal("transactionQueue")}
                          >
                            <span className="Count">3</span>
                          </a>
                        </div>
                      </td>
                      <td>
                        <div className="TableThreeDots">
                          <ul className="">
                            <li className="dropdown">
                              <a className="" role="button" data-toggle="dropdown">
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth">
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    onClick={() => showPostalTransactionModal("transactionQueue")}
                                  > 
                                    <img src="images/Action-1.png" />
                                    Postal Transaction
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/contact-list.png" />
                                    Void Transaction
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="TextBlue">John Contact</div>
                      </td>
                      <td>U38-ARB-1B-243</td>
                      <td>DP-ARB-1B-243-1</td>
                      <td>06/14/20 23</td>
                      <td>Mastercard</td>
                      <td>DP: Down Payment Deposit</td>
                      <td>$1,000.00</td>
                      <td>Initial deposit</td>
                      <td />
                      <td />
                      <td>
                        <button className="Commited">Commited</button>
                      </td>
                      <td>
                        <div className="Actions">
                          <a
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#AddNewSupportingDocs2"
                          >
                            <img src="images/icon-63.png" />
                          </a>
                          <a
                            href="javascript:void(0)"
                            data-toggle="modal"
                            data-target="#SupportingDocumentList"
                          >
                            <span className="Count">3</span>
                          </a>
                        </div>
                      </td>
                      <td>
                        <div className="TableThreeDots">
                          <ul className="">
                            <li className="dropdown">
                              <a className="" role="button" data-toggle="dropdown">
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth">
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#PostTransactionTable"
                                  >
                                    <img src="images/Action-1.png" />
                                    Postal Transaction
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/contact-list.png" />
                                    Void Transaction
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="TextBlue">John Contact</div>
                      </td>
                      <td>U38-ARB-1B-243</td>
                      <td>DP-ARB-1B-243-1</td>
                      <td>06/14/20 23</td>
                      <td>Mastercard</td>
                      <td>DP: Down Payment Deposit</td>
                      <td>$1,000.00</td>
                      <td>Initial deposit</td>
                      <td />
                      <td />
                      <td>
                        <button className="Commited">Commited</button>
                      </td>
                      <td>
                        <div className="Actions">
                          <a
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#AddNewSupportingDocs2"
                          >
                            <img src="images/icon-63.png" />
                          </a>
                          <a
                            href="javascript:void(0)"
                            data-toggle="modal"
                            data-target="#SupportingDocumentList"
                          >
                            <span className="Count">3</span>
                          </a>
                        </div>
                      </td>
                      <td>
                        <div className="TableThreeDots">
                          <ul className="">
                            <li className="dropdown">
                              <a className="" role="button" data-toggle="dropdown">
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth">
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#PostTransactionTable"
                                  >
                                    <img src="images/Action-1.png" />
                                    Postal Transaction
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/contact-list.png" />
                                    Void Transaction
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={scheduledPaymentModal}
        onHide={hideScheduledPaymentModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideScheduledPaymentModal}
          >
            ×
          </a>
          <>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Scheduled Payments</h4>
              </div>
              <div className="ModalBigheading mb-5">
                <h3>
                  <span>Client:</span>Marna Contract
                </h3>
                <h3>
                  <span>Job Assigned:</span>U37-ARB-1B-243
                </h3>
              </div>
              <div className="Tableheader">
                <h6 className="mb-0">Schedule of Payments</h6>
                <div className="Actions">
                  <a href="javascript:void(0);">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/download.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/download.png" />
                  </a>
                </div>
              </div>
              <div className="TableList CommonHeading TableHeightScroll">
                <table>
                  <thead>
                    <tr>
                      <th>Scheduled Payment Date</th>
                      <th>Transaction Type</th>
                      <th>Total</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td>
                        <div className="TableInput">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Insert Amount"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="CommonSelectBox">
                          <select>
                            <option selected="">Select State or Province</option>
                            <option>India</option>
                            <option>USA</option>
                            <option>Australia</option>
                          </select>
                          <span>
                            <img src="images/down.png" />
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="TableInput">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Insert Amount"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="TableThreeDots">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className="javascript:void(0);"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a onClick={() => showEditScheduledPaymentModal("scheduledPayment")} href="javascript:void(0);">
                                    <img src="images/Action-1.png" />
                                    Edit Scheduled Payment
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/dropdown-delete.png" />
                                    Remove Scheduled Payment
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    onClick={() => showContractTransactionsModal("scheduledPayment")}
                                  >
                                    <img src="images/add_transactions.png" />
                                    Create New Transaction Entry
                                  </a>
                                </li>
                                <li>
                                  <a onClick={() => showSetReminderModal("scheduledPayment")} href="javascript:void(0);">
                                    <img src="images/timer.png" />
                                    Set Payment Reminder
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4}>
                        <div className="TableButtons">
                          <button className="BrandBtn">Schedule New Payment</button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="Button mt-5 d-flex justify-content-between">
                <button className="Create" data-dismiss="modal">
                  Save
                </button>
                <button className="Outline" onClick={hideScheduledPaymentModal}>
                  Cancel
                </button>
              </div>
            </div>
            i
          </>


        </Modal.Body>
      </Modal>



      {/* TRANSACTIONMODALSHOW */}

      <Modal
        show={contractTransactionsModal}
        onHide={hideContractTransactionsModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideContractTransactionsModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Transactions</h4>
            </div>
            <div className="ModalBigheading mb-5">
              <h3>
                <span>Client:</span>Marna Contract
              </h3>
              <h3>
                <span>Job Assigned:</span>U37-ARB-1B-243
              </h3>
            </div>
            <div className="Tableheader">
              <h6 className="mb-0">Create new Transaction</h6>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Transaction Date</th>
                    <th>Payment Method</th>
                    <th>Transaction Type</th>
                    <th>Total</th>
                    <th>Notes</th>
                    <th>Supporting Documents</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="TableInput">
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Insert Amount"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="CommonSelectBox">
                        <select>
                          <option selected="">Select Payment Method</option>
                          <option>1</option>
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="CommonSelectBox">
                        <select>
                          <option selected="">Select Transaction Type</option>
                          <option>1</option>
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="TableInput">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert Amount"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="TableInput">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert Notes"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          onClick={() => showSupportingDocsModal("transaction")}
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0)"
                          onClick={() => showContractSupportingListModal("transaction")}
                        >
                          <span className="Count">3</span>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={6}>
                      <div className="TableButtons">
                        <button className="BrandBtn">Create New Transaction</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="TableHeadingArea mt-5">
              <div className="FilesHead">
                <div className="FilesLeft">
                  <h4>Transaction</h4>
                </div>
                <div className="d-flex align-items-center">
                  <div className="Search">
                    <span>
                      <img src="images/search.png" />
                    </span>
                    <input type="text" className="form-control" placeholder="Search" />
                  </div>
                  <div className="FilesRight">
                    <a href="#">
                      <img src="images/pdf.png" />
                    </a>
                    <a href="#">
                      <img src="images/download.png" />
                    </a>
                    <a href="#">
                      <img src="images/setting.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="TableList CommonHeading TableListLarge">
              <table>
                <thead>
                  <tr>
                    <th>Job Assigned</th>
                    <th>Transaction ID#</th>
                    <th>Transaction/Adjustment Date</th>
                    <th>Payment Method</th>
                    <th>Transaction Type</th>
                    <th>Total</th>
                    <th>Notes</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Supporting Documents</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={12}>
                      <h6 className="mb-0">ADRJ: Revenue Adjustments</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="TextBlue">ADRJ: Revenue Adjustments</div>
                    </td>
                    <td>DP-ARB-1B-243-1</td>
                    <td>06/14/20 23</td>
                    <td>Mastercard</td>
                    <td>DP: Down Payment Deposit</td>
                    <td>$1,000.00</td>
                    <td>Initial deposit</td>
                    <td>Account Receivable(A/R)</td>
                    <td>Sales of Product Income</td>
                    <td>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#AddNewSupportingDocs2"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0)"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList"
                        >
                          <span className="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <button className="Posted">Posted</button>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <td colSpan={12}>
                      <h6 className="mb-0">ADRJ: Revenue Adjustments</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="TextBlue">ADRJ: Revenue Adjustments</div>
                    </td>
                    <td>DP-ARB-1B-243-1</td>
                    <td>06/14/20 23</td>
                    <td>Adjustment</td>
                    <td>ADJ: Other Adjustments</td>
                    <td>$5,00.00</td>
                    <td>Price updates</td>
                    <td />
                    <td />
                    <td>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#AddNewSupportingDocs2"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0)"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList"
                        >
                          <span className="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <button className="Commited">Committed</button>
                    </td>
                    <td>
                      <div className="TableThreeDots">
                        <ul className="">
                          <li className="dropdown">
                            <a
                              className="javascript:void(0);"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol className="dropdown-menu">
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#PostTransaction"
                                  data-dismiss="modal"
                                >
                                  <img src="images/post_transaction.png" />
                                  Post Transaction
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/void_transaction.png" />
                                  Void Transaction
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="TextBlue">ADRJ: Revenue Adjustments</div>
                    </td>
                    <td>DP-ARB-1B-243-1</td>
                    <td>06/14/20 23</td>
                    <td>Direct Deposit</td>
                    <td>CO: Change Order Deposit</td>
                    <td>$15,000.00</td>
                    <td>Change Order full deposit</td>
                    <td />
                    <td />
                    <td>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#AddNewSupportingDocs2"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0)"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList"
                        >
                          <span className="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <button className="Void">Void</button>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="Button mt-5 d-flex justify-content-between">
              <button
                className="Create"
                onClick={hideContractTransactionsModal}
              >
                Save
              </button>
              <button
                className="Outline"
                onClick={hideContractTransactionsModal}
              >
                Cancel
              </button>
            </div>
          </div>

        </Modal.Body>
      </Modal>



      <Modal
        show={adjustmentStatementModal}
        onHide={hideAdjustStatementModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAdjustStatementModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Statement of Adjustments</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="ModalBigheading">
                  <h3>
                    <span>Status: Pending Accountant Release</span>
                  </h3>
                </div>
                <div className="AgreementViewArea">
                  <div className="AgreementViewTop">
                    <a href="javscript:void(0)">
                      <img src="images/modal-big-logo.png" />
                    </a>
                    <div className="AgreementViewTopRight">
                      <h3>iBuild</h3>
                      <p>312 Weddenburn Rd SE</p>
                      <p>Calgary, AB CA, T2J 1J1</p>
                      <p>
                        4034005933 www.ibuildapplications.com
                        linden@ibuildapplications.com
                      </p>
                      <p>
                        “The bitterness of poor quality remains long after the sweetness
                        of meeting
                        <br />
                        the schedule has been gorgotten”
                      </p>
                    </div>
                  </div>
                  <div className="StatementAdjustmentArea px-4">
                    <h6>Statement of Adjustments</h6>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Client Name</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Address</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>City</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>State/Provice</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Possession Date</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Date Prepared</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Job Number</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Sales Consultant</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Model</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Contract Date</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-12">
                        <h6 className="Title">JOB DETAILS</h6>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Address</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>City</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>State/Provice</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Subdivision</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Lot</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Block</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Plan</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Sale Type</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-12">
                        <h6 className="Title">Sale Revenues</h6>
                        <div className="StatementAdjustmentBottom">
                          <label>Contract Purchase Agreement Price (Pre-Tax)</label>
                          <p className="LightGreen">$500,000.00</p>
                        </div>
                        <h6 className="Title">ADD: Tax</h6>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>ADD: Tax</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="StatementAdjustmentBottom">
                          <label>Applied Tax</label>
                          <p className="LightGrey">5.00%</p>
                          <p className="LightRed">$25,000.00</p>
                        </div>
                        <h6 className="Title">Less: Deposit</h6>
                        <div className="StatementAdjustmentTable">
                          <table>
                            <thead>
                              <tr>
                                <th>Notes</th>
                                <th>Transaction ID#</th>
                                <th>Date</th>
                                <th>Tx</th>
                                <th>Tx Type</th>
                                <th>
                                  Supporting <br /> Documents
                                </th>
                                <th>Status</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Condition Removal Deposit</td>
                                <td>OP-CHI-2B-243-1</td>
                                <td>03/30/20 21</td>
                                <td>CH</td>
                                <td>OP</td>
                                <td>
                                  <div className="Actions">
                                    <a
                                      href="javascript:void(0);"
                                       onClick={() => showSupportingDocsModal("SOA")}
                                    >
                                      <img src="images/icon-63.png" />
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                     onClick={() => showContractSupportingListModal("SOA")}
                                    >
                                      <span className="Count">3</span>
                                    </a>
                                  </div>
                                </td>
                                <td>Commited</td>
                                <td>
                                  <div className="BgBlue">$35,000.00</div>
                                </td>
                              </tr>
                              <tr>
                                <td>Construction Drawings</td>
                                <td>OP-CHI-2B-243-2</td>
                                <td>06/30/20 21</td>
                                <td>D</td>
                                <td>DP</td>
                                <td>
                                  <div className="Actions">
                                    <a
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddNewSupportingDocsStatement2"
                                    >
                                      <img src="images/icon-63.png" />
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      data-toggle="modal"
                                      data-target="#SupportingDocumentListStatement"
                                    >
                                      <span className="Count">3</span>
                                    </a>
                                  </div>
                                </td>
                                <td>Commited</td>
                                <td>
                                  <div className="BgBlue">$35,000.00</div>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan={6}>Down Payment Amount Required</td>
                                <td colSpan={2}>
                                  <div className="BgBlue">$0.00</div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={6}>Total Deposit</td>
                                <td colSpan={2}>
                                  <div className="BgBlue">$55,000.00</div>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        <h6 className="Title">Summary</h6>
                        <div className="StatementAdjustmentBottom">
                          <label>Contract Purchase Agreement Price (Pre-Tax)</label>
                          <p className="LightGreen">$500,000.00</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ChangeOrderCreatArea mt-5">
                    <div className="row">
                      <div className="col-lg-12"></div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="InputBox">
                          <div className="form-group">
                            <input type="text" />
                            <label>Date</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 ml-auto">
                        <div className="InputBox">
                          <div className="form-group">
                            <input type="text" />
                            {/* <div class="InsertSignature">
                            </div> */}
                            <label>John Doe</label>
                            <label>Company Accountant</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="InputBox">
                          <div className="form-group">
                            <input type="text" />
                            <label>Date</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 ml-auto">
                        <div className="InputBox">
                          <div className="form-group">
                            <input type="text" />
                            <label>John Doe</label>
                            <label>Company Accountant</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button className="Create" data-dismiss="modal">
                    Print
                  </button>
                  <button
                    className="Create FolderPermissionId"
                    data-toggle="modal"
                    data-target="#StatementOfAdjustmentsSaveSignature"
                    data-dismiss="modal"
                  >
                    Save
                  </button>
                  <button
                    className="Create FolderPermissionId"
                    onClick={() => showSOAHistoryModal("SOA")}
                  >
                    <img src="images/history_icon.png" />
                    History
                  </button>
                  <button className="Outline" onClick={hideAdjustStatementModal}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>

        </Modal.Body>
      </Modal>

      < Modal
        show={leadClientDetailsShow}
        onHide={hideLeadClientDetailsModal}
        className="ModalBox ExtraSmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideLeadClientDetailsModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ContactTopBox">
              <div className="SalesPersonarea">
                <span>MB</span>
                <h6>Man Mohan</h6>
              </div>
            </div>
            <div className="FolderForm">
              <form>
                <div className="SendEmailText">
                  <p>
                    <img src="images/send-email.png" />
                    Send E-mail
                  </p>
                  <p className="SendText SendText1">
                    <img src="images/send-text.png" />
                    Send Text
                  </p>
                  <div className="SendTextSearch SendTextSearch1">
                    <input type="text" />
                    <button>Apply</button>
                  </div>
                </div>
                <div className="MolalContactForm mb-4">
                  <h6>Contact</h6>
                  <p>
                    <img src="images/send-email.png" />
                    johndoe@gmail.com
                  </p>
                  <p className="mb-4">
                    <img src="images/send-text.png" />
                    +91 990000000
                  </p>
                  <a href="javascript:void(0);" className="ShowMoreBtn">
                    Show more
                  </a>
                  <a href="javascript:void(0);" className="ShowLessBtn">
                    Show less
                  </a>
                </div>
                <div className="form-group mt-3">
                  <div className="ContactModalAddressArea">
                    <h6>Address</h6>
                    <p>
                      312 Weddenburn Rd SE <br />
                      Calgary, Alberta, T2J 1J
                      <img src="images/location.png" />
                    </p>
                  </div>
                </div>
                <div className="DiscussionHide">
                  <div className="form-group mb-0">
                    <h6>Discussion</h6>
                    <input
                      type="text"
                      placeholder="Write here ....."
                      className="form-control"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal >

      <Modal
        show={discussionModal}
        onHide={hideDiscussionModal}
        className="ModalBox MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideDiscussionModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Follow Up Activity</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="TableList CommonHeading mt-4">
                  <table>
                    <thead>
                      <tr>
                        <th />
                        <th>Type</th>
                        <th>Sent By</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="ActionsWidth">
                            <img src="images/meeting_icon.png" />
                          </div>
                        </td>
                        <td>Meeting</td>
                        <td>John Doe</td>
                        <td>06/12/2023 10:30 AM</td>
                        <td>Scheduled</td>
                        <td>
                          <div className="dropdown CursorPointer">
                            <p data-toggle="dropdown">
                              <span>
                                <img src="images/three-dots.png" />
                              </span>
                            </p>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuOffset"
                            >
                              <li>
                                <a
                                  className="dropdown-item FolderPermissionId"
                                  href="javascript:void(0);"
                                  onClick={() =>
                                    showEditFollowUpActivityModal("discussion")
                                  }
                                >
                                  <img src="images/icon-15.png" />
                                  Edit Activity Details
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/dropdown-delete.png" />
                                  Cancel Activity
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  onClick={() =>
                                    showAddItineraryModal("discussion")
                                  }
                                >
                                  <img src="images/send_to_itinary.png" />
                                  Send to itinery
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/export-to-calendar.png" />
                                  Export to Calender
                                  <img src="images/rightarrow2.png" />
                                </a>
                                <ul className="dropdown-menu submenu submenuLead">
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/google-calendar.png" />
                                      Google Calendar
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/outlook-calendar.png" />
                                      Outlook Calender
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/email-calendar.png" />
                                      Email Calender
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  onClick={() =>
                                    showSetReminderModal("discussion")
                                  }
                                >
                                  <img src="images/set-reminder.png" />
                                  Set Reminder
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/send_invitaiton.png" />
                                  Send Invitation
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  onClick={() =>
                                    showAssignSaleModal("discussion")
                                  }
                                >
                                  <img src="images/assign_icon.png" />
                                  Assign Staff member
                                </a>
                              </li>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <div className="ActionsWidth">
                            <img src="images/meeting_icon.png" />
                          </div>
                        </td>
                        <td>Meeting</td>
                        <td>John Doe</td>
                        <td>06/12/2023 10:30 AM</td>
                        <td>Scheduled</td>
                        <td>
                          <div className="dropdown CursorPointer">
                            <p data-toggle="dropdown">
                              <span>
                                <img src="images/three-dots.png" />
                              </span>
                            </p>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuOffset"
                            >
                              <li>
                                <a
                                  className="dropdown-item FolderPermissionId"
                                  href="javascript:void(0);"
                                  onClick={() =>
                                    showEditFollowUpActivityModal("discussion")
                                  }
                                >
                                  <img src="images/icon-15.png" />
                                  Edit Activity Details
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/dropdown-delete.png" />
                                  Cancel Activity
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  onClick={() =>
                                    showAddItineraryModal("discussion")
                                  }
                                >
                                  <img src="images/send_to_itinary.png" />
                                  Send to itinery
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/export-to-calendar.png" />
                                  Export to Calender
                                  <img src="images/rightarrow2.png" />
                                </a>
                                <ul className="dropdown-menu submenu submenuLead">
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/google-calendar.png" />
                                      Google Calendar
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/outlook-calendar.png" />
                                      Outlook Calender
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/email-calendar.png" />
                                      Email Calender
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  onClick={() =>
                                    showSetReminderModal("discussion")
                                  }
                                >
                                  <img src="images/set-reminder.png" />
                                  Set Reminder
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/send_invitaiton.png" />
                                  Send Invitation
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  onClick={() =>
                                    showAssignSaleModal("discussion")
                                  }
                                >
                                  <img src="images/assign_icon.png" />
                                  Assign Staff member
                                </a>
                              </li>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <div className="ActionsWidth">
                            <img src="images/send-email.png" />
                          </div>
                        </td>
                        <td>Email</td>
                        <td>John Doe</td>
                        <td>06/12/2023 10:30 AM</td>
                        <td>Sent</td>
                        <td>
                          <div className="dropdown CursorPointer">
                            <p data-toggle="dropdown">
                              <span>
                                <img src="images/three-dots.png" />
                              </span>
                            </p>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuOffset"
                            >
                              <li>
                                <a
                                  className="dropdown-item FolderPermissionId"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#EditFollowUpActivity"
                                  data-dismiss="modal"
                                >
                                  <img src="images/icon-15.png" />
                                  Edit Activity Details
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/dropdown-delete.png" />
                                  Cancel Activity
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/send_to_itinary.png" />
                                  Send to itinery
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/export-to-calendar.png" />
                                  Export to Calender
                                  <img src="images/rightarrow2.png" />
                                </a>
                                <ul className="dropdown-menu submenu submenuLead">
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/google-calendar.png" />
                                      Google Calendar
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/outlook-calendar.png" />
                                      Outlook Calender
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/email-calendar.png" />
                                      Email Calender
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/set-reminder.png" />
                                  Set Reminder
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/send_invitaiton.png" />
                                  Send Invitation
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/assign_icon.png" />
                                  Assign Staff member
                                </a>
                              </li>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="PaginationArea">
                  <h6>Showing 1 to 10 of 27 entries</h6>
                  <h6>
                    <img src="images/leftarrow.png" /> 01{" "}
                    <img src="images/rightarrow.png" /> Out 10
                  </h6>
                </div>
                <div className="Button text-center">
                  <button onClick={hideDiscussionModal} className="Outline">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={editFollowUpActivityModal}
        onHide={hideEditFollowUpActivityModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideEditFollowUpActivityModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Edit Follow up activity</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="d-flex justify-content-end">
                  <h6>Itinerary Item: #256</h6>
                </div>
                <div className="row">
                  <div className="form-group col-md-5">
                    <h6>Type</h6>
                    <div className="CommonSelectBox">
                      <select>
                        <option selected="">Select Type</option>
                        <option>-Meeting</option>
                        <option>-Phone call</option>
                      </select>
                      <span>
                        <img src="images/down.png" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group col-md-5 ml-auto">
                    <h6>Location</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert Location"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <h6>Date</h6>
                    <input type="date" className="form-control" />
                  </div>
                  <div className="form-group col-md-4">
                    <h6>From</h6>
                    <input type="time" className="form-control" />
                  </div>
                  <div className="form-group col-md-4">
                    <h6>To</h6>
                    <input type="time" className="form-control" />
                  </div>
                </div>
                <div className="CommonModalAreaMain align-items-start mt-2">
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5>Set Reminder</h5>
                      <div className="CommonModalBox">
                        <h5
                          className="CursorPointer FolderPermissionId"
                          onClick={() =>
                            showSetReminderModal("editFollowUpActivity")
                          }
                        >
                          <figure>
                            <img src="images/timer.png" title="set reminder" />
                          </figure>{" "}
                          Set Reminder
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5>Assign Staff Member</h5>
                      <div className="CommonModalBox">
                        <h5
                          className="CursorPointer FolderPermissionId"
                          onClick={() =>
                            showAssignSaleModal("editFollowUpActivity")
                          }
                        >
                          <figure>
                            <img
                              src="images/assign-resources.png"
                              title="assign staff member"
                            />
                          </figure>{" "}
                          <span className="Count">3</span> Assign
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="StaffMemberArea mt-0">
                    <div className="form-group">
                      <h6>Assign Staff member</h6>
                      <div className="StaffBox">
                        <div className="StaffName">
                          <p>John Doe</p>
                          <img src="images/Cross.png" title="canceling" />
                        </div>
                        <div className="StaffName">
                          <p>John Williams</p>
                          <img src="images/Cross.png" title="canceling" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-center py-5">
                  <div className="Button dropdown ml-3">
                    <button
                      className="Create"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Update <img src="images/down-arrow-white.png" />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a className="dropdown-item" href="#">
                        Update and Send Invitation
                      </a>
                      <a className="dropdown-item" href="#">
                        Update and Send to Itinerary
                      </a>
                    </div>
                  </div>
                  <button
                    className="Outline ml-3"
                    onClick={hideEditFollowUpActivityModal}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      {/* SETREMIANDERMODALSHOW */}

      <Modal
        show={setReminderModal}
        onHide={hideSetReminderModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSetReminderModal}
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Reminder</h4>
            </div>
            <div class="FolderForm">
              <h6>Date</h6>
              <form>
                <div class="Categories Categories1">
                  <div class="Categories_all Categories_all1">
                    <span>Select aumont of time for reminder</span>
                    <img src="images/down.png" />
                  </div>
                  <ul>
                    <li>-0 minutes</li>
                    <li>-5 minutes</li>
                    <li>-15 minutes</li>
                  </ul>
                </div>
                <div class="Button text-center mt-5">
                  <button
                    class="Create mr-4"
                    data-toggle="modal"
                    data-target="#LeadDetails"
                    data-dismiss="modal"
                  >
                    Set Reminder
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ASSIGNSALEMODALSHOW */}
      < Modal
        show={assignSaleModal}
        onHide={hideAssignSaleModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAssignSaleModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Assign Sales Representative</h4>
            </div>
            <div className="ModalTitleHeading">
              <h2>Staff Member Information</h2>
            </div>
            <div className="FolderForm">
              <form>
                <div className="form-group">
                  <h6>Name</h6>
                  <div className="Categories Categories1">
                    <div className="Categories_all Categories_all1">
                      <span>Select a Staff Member</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>List of Staff Members loaded from the system</li>
                    </ul>
                  </div>
                </div>
                <div className="Button text-center mt-3">
                  <button className="Create">Assign</button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal >

      {/* ADDITINERARYMODALSHOW */}

      <Modal
        show={addItineraryModal}
        onHide={hideAddItineraryModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAddItineraryModal}
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Add New Itinerary Item</h4>
            </div>
            <div class="FolderForm">
              <h6>Item #12</h6>

              <div class="form-group">
                <h6>Subject</h6>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Executive Meeting"
                />
              </div>
              <div class="form-group">
                <h6>Description</h6>
                <textarea rows="5"></textarea>
              </div>
              <div class="form-group">
                <h6>Location</h6>
                <input type="text" class="form-control" placeholder="Room 1" />
              </div>
              <h6>Select New Date</h6>
              <div class="Filter row">
                <div class="form-group col-4">
                  <label>Date Option 1</label>
                  <input type="date" class="form-control" />
                </div>
                <div class="form-group col-4">
                  <label>From</label>
                  <input type="time" class="form-control" />
                </div>
                <div class="form-group col-4">
                  <label>To</label>
                  <input type="time" class="form-control" />
                </div>
              </div>

              <div class="Filter row">
                <div class="form-group col-4">
                  <label>Type</label>
                  <select class="form-control">
                    <option value="Days">Meeting</option>
                    <option>Phone Call</option>
                    <option>Text</option>
                    <option>Task</option>
                    <option>Other</option>
                  </select>
                </div>
                <div class="form-group ml-auto col-4">
                  <label class="mb-3">Set Reminder</label>
                  <div
                    class="FolderPermissionId CursorPointer"
                    onClick={() => showSetReminderModal("itinerary")}
                  >
                    <img src="images/timer-red.png" />
                    <a href="javascript:void(0);" class="text-red ml-3">
                      Reminder
                    </a>
                  </div>
                </div>
              </div>
              <h6>Itinerary is related to Job or Schedule's Task?</h6>
              <div class="row">
                <div class="form-group col-6">
                  <div class="d-flex justify-content-between">
                    <label class="CheckBox">
                      Job
                      <input type="checkbox" />
                      <span class="checkmark"></span>
                    </label>
                    <label class="CheckBox">
                      Schedule
                      <input type="checkbox" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="Categories Categories1 mb-3">
                <div class="Categories_all Categories_all1">
                  <span>Select a folder to insert the file</span>
                  <img src="images/down.png" />
                </div>
                <ul>
                  <li>
                    <input type="text" placeholder="-Search" />
                    <span class="Search">
                      <img src="images/search.png" />
                    </span>
                  </li>
                  <li>- List of Jobs already filtered</li>
                </ul>
              </div>

              <div class="Categories Categories2">
                <div class="Categories_all Categories_all2">
                  <span>Select a Schedule Task</span>
                  <img src="images/down.png" />
                </div>
                <ul>
                  <li>
                    <input type="text" placeholder="-Search" />
                    <span class="Search">
                      <img src="images/search.png" />
                    </span>
                  </li>
                  <li>- List of Schedule tasks for job</li>
                </ul>
              </div>

              <div class="StaffMemberArea">
                <div class="row">
                  <div class="col-4">
                    <h6>Assign Staff Member</h6>
                    <div
                      class="StaffName FolderPermissionId"
                      onClick={() => showAssignSaleModal("itinerary")}
                    >
                      <img src="images/member.png" />
                      <div class="Count">3</div>
                      <h6>Assign</h6>
                    </div>
                  </div>
                  <div class="col-4 ml-auto">
                    <h6>Assign Staff member</h6>
                    <div class="StaffBox">
                      <div class="StaffName">
                        <p>John Doe</p>
                        <img src="images/Cross.png" />
                      </div>
                      <div class="StaffName">
                        <p>John Williams</p>
                        <img src="images/Cross.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Button text-center mt-5">
                <button
                  class="Create mr-4"

                >
                  Create new Itinerary Item
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* SOAHISTORYMODALSHOW */}
      < Modal
        show={SOAHistoryModal}
        onHide={hideSOAHistoryModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSOAHistoryModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>SOA History</h4>
            </div>
            <div className="JobTitleArea mb-2">
              <div className="JobBox">
                <h6>
                  <span>Job Id:</span>&nbsp;U231-CHI-2B-243
                </h6>
              </div>
            </div>
            <div className="TableList CommonHeading TableHeightScroll TableLeft">
              <table>
                <thead>
                  <tr>
                    <th>Modified By</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>John Doe</td>
                    <td>Jane Williams</td>
                    <td>04/25/2023</td>
                    <td>Sent to Lawyer LL laywers</td>
                  </tr>
                  <tr>
                    <td>John Doe</td>
                    <td>John Doe</td>
                    <td>04/25/2023</td>
                    <td>Sign by Accountant</td>
                  </tr>
                  <tr>
                    <td>John Doe</td>
                    <td>John Doe</td>
                    <td>04/25/2023</td>
                    <td>SOA Updated</td>
                  </tr>
                  <tr>
                    <td>John Doe</td>
                    <td>John Doe</td>
                    <td>04/25/2023</td>
                    <td>SOA Updated</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </Modal.Body>
      </Modal >

      {/*  EDITSCHEDULEDPAYMENTMODALSHOW*/}

      < Modal
        show={editScheduledPaymentModal}
        onHide={hideEditScheduledPaymentModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideEditScheduledPaymentModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Scheduled Payments</h4>
            </div>
            <div className="ModalBigheading mb-5">
              <h3>
                <span>Client:</span>Marna Contract
              </h3>
              <h3>
                <span>Job Assigned:</span>U37-ARB-1B-243
              </h3>
            </div>
            <div className="Tableheader">
              <h6 className="mb-0">Schedule of Payments</h6>
              <div className="Actions">
                <a href="javascript:void(0);">
                  <img src="images/icon-53.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/download.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/download.png" />
                </a>
              </div>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Scheduled Payment Date</th>
                    <th>Transaction Type</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="TableInput">
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Insert Amount"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="CommonSelectBox">
                        <select>
                          <option selected="">Select Transaction Type</option>
                          <option>trasaction type 1</option>
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="TableInput">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert Amount"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="TableInput">
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Insert Amount"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="CommonSelectBox">
                        <select>
                          <option selected="">Select Transaction Type</option>
                          <option>trasaction type 1</option>
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="TableInput">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert Amount"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="TableInput">
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Insert Amount"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="CommonSelectBox">
                        <select>
                          <option selected="">Select Transaction Type</option>
                          <option>trasaction type 1</option>
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="TableInput">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert Amount"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4}>
                      <div className="TableButtons">
                        <button className="BrandBtn">Add Line</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="Button mt-5 d-flex justify-content-between">
              <button
                className="Create"
                data-toggle="modal"
                data-target="#ScheduledPayments"
                data-dismiss="modal"
              >
                Save
              </button>
              <button
                className="Outline"
                onClick={hideEditScheduledPaymentModal}
              >
                Cancel
              </button>
            </div>
          </div>


        </Modal.Body>
      </Modal >
      <Modal
        show={supportingDocsModal}
        onHide={hideSupportingDocsModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSupportingDocsModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Add New Supporting Docs</h4>
            </div>
            <div className="JobTitleArea">
              <h3>Supporting Documents #2</h3>
              <div className="JobBox">
                <h6>
                  <span>Job:</span> UUnit3-CHI-2B-315
                </h6>
              </div>
              <div className="JobBox">
                <h6>
                  <span>Origin</span> #185
                </h6>
              </div>
              <div className="JobBox">
                <h6>
                  <span>Item:</span> #143
                </h6>
              </div>
            </div>
            <div className="form-group">
              <h6>Select Files</h6>
              <div className="Upload">
                <span>-Drag and Drop Files or click to select</span>
                <input type="file" />
              </div>
            </div>
            <div className="FilesAddedTitle">
              <h4>Files</h4>
            </div>
            <div className="FilesAdded">
              <label>File_name.docs</label>
              <span>
                <img src="images/fileview.png" />
              </span>
              <span>
                <img src="images/Cross.png" className="cross" />
              </span>
            </div>
            <div className="FilesAdded">
              <label>File_name.docs</label>
              <span>
                <img src="images/fileview.png" />
              </span>
              <span>
                <img src="images/Cross.png" className="cross" />
              </span>
            </div>
            <div className="Button mt-3">
              <button className="Create" data-dismiss="modal">
                Add Documents
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* SUPPORTINGDOCSMODALSHOW */}
      <Modal
        show={ContractSupportingDocsModal}
        onHide={hideContractSupportingListModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideContractSupportingListModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Supporting Documents List</h4>
            </div>
            <div className="JobTitleArea">
              <div className="JobBox">
                <h6>
                  <span>Job:</span> UUnit3-CHI-2B-315
                </h6>
                <div>
                  <a
                    href="javascript:void(0);"
                    className="FolderPermissionId"
                    onClick={() => showSupportingDocsModal("contractSupportingDocs")}
                  >
                    <img src="images/notebook.png" />
                  </a>
                </div>
              </div>
              <div className="JobBox">
                <h6>
                  <span className="text-red">Origin</span> #185
                </h6>
              </div>
              <div className="JobBox">
                <h6>
                  <span className="text-red">Item:</span> #143
                </h6>
              </div>
            </div>
            <div className="NotesAreaMain">
              <div className="NotesArea mt-4">
                <h6>Supporting Documents List</h6>
                <div>
                  <a href="javascript:void(0);">
                    <img src="images/pdf.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/download.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
              <div className="TableList">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Sent by</th>
                      <th>Date</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>

                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>John Doe</td>
                      <td>05/01/2023</td>
                      <td className="Inspection">floor_manual.pdf</td>
                      <td>
                        <div className="Actions">
                          <a
                            href="javascript:void(0);"
                            className="FolderPermissionId"
                            onClick={() => showEditSupportingModal("supportingList")}
                          >
                            <img src="images/Action-1.png" />
                          </a>
                          <a href="javascript:void(0);">
                            <img src="images/delete.png" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>John Doe</td>
                      <td>05/01/2023</td>
                      <td className="Inspection">photo1.jpg, photo2.jpg</td>
                      <td>
                        <div className="Actions">
                          <a
                            href="javascript:void(0);"
                            className="FolderPermissionId"
                            onClick={() => showEditSupportingModal("supportingList")}
                          >
                            <img src="images/Action-1.png" />
                          </a>
                          <a href="javascript:void(0);">
                            <img src="images/delete.png" />
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="Button mt-5">
              <button
                className="Create FolderPermissionId"
                onClick={() => showSupportingDocsModal("supportingList")}
              >
                Add New Supporting Docs
              </button>
            </div>
          </div>

        </Modal.Body>
      </Modal>

      {/* EDITSUPPORTINGSHOW */}

      <Modal
        show={editSupportingModal}
        onHide={hideEditSupportingModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideEditSupportingModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Edit New Supporting Docs</h4>
            </div>
            <div className="JobTitleArea">
              <h3>Supporting Documents #2</h3>
              <div className="JobBox">
                <h6>
                  <span>Job:</span> UUnit3-CHI-2B-315
                </h6>
              </div>
              <div className="JobBox">
                <h6>
                  <span>Origin</span> #185
                </h6>
              </div>
              <div className="JobBox">
                <h6>
                  <span>Item:</span> #143
                </h6>
              </div>
            </div>
            <div className="form-group">
              <h6>Select Files</h6>
              <div className="Upload">
                <span>-Drag and Drop Files or click to select</span>
                <input type="file" />
              </div>
            </div>
            <div className="FilesAddedTitle">
              <h4>Files</h4>
            </div>
            <div className="FilesAdded">
              <label>File_name.docs</label>
              <span>
                <img src="images/fileview.png" />
              </span>
              <span>
                <img src="images/Cross.png" className="cross" />
              </span>
            </div>
            <div className="FilesAdded">
              <label>File_name.docs</label>
              <span>
                <img src="images/fileview.png" />
              </span>
              <span>
                <img src="images/Cross.png" className="cross" />
              </span>
            </div>
            <div className="Button mt-3">
              <button className="Create" data-dismiss="modal">
                Update Documents
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>


         {/* CLIENTCONTRACTDETAILSSHOW */}

         <Modal
        show={clientContractDetailsModal}
        onHide={hideClientContractDetailsModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideClientContractDetailsModal}
          >
            ×
          </a>
          <div className="FormArea">
  <div className="ModalTitleBox">
    <h4 className="abcgreen abcgreen11">General Information</h4>
    <h4 className="abcgreen abcgreen12" style={{ display: "block" }}>
      Conditional Details
    </h4>
    <h4 className="abcgreen abcgreen13">Adjustments and Deposit</h4>
  </div>
  <div className="FolderForm">
    <form>
      <div className="row">
        <div className="col-md-4">
          <div className="ContactInformationArea">
            <h6>Contact Information</h6>
            <div className="ContactInformationBox">
              <span className="circle">JL</span>
              <div className="ContactInformationName">
                <h6>John Lead</h6>
                <p>
                  312 Weddenburn Rd SE <br /> Calgary, Alberta{" "}
                  <img
                    src="images/location.png"
                    className="FolderPermissionId CursorPointer"
                    data-toggle="modal"
                    data-target="#MapModal3"
                    data-dismiss="modal"
                  />
                </p>
                <p>
                  Phone: <span>(403)555-555</span>
                </p>
                <p>
                  Email: <span>John.lead@email.com</span>
                  <img src="images/fill_star.png" className="ml-2" />
                </p>
              </div>
              <div className="ContactInformationIcon">
                <a href="javascript:void(0);">
                  <img src="images/pdf.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/download.png" />
                </a>
                <a
                  href="javascript:void(0);"
                  className="FolderPermissionId"
                  title="Client Documentation"
                  onClick={() =>showClientDocumentatioModal("editClientContractDetails")}
                >
                  <img src="images/icon-75.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="TodosButtons mt-5">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <a
              className="nav-link GeneralInformationBtnShowGreen"
              data-toggle="tab"
              href="#GeneralInformationGreenTransaction"
            >
              <img src="images/general_information_red.png" />
              General Information
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link active ConditionalDetailsBtnShowGreen"
              data-toggle="tab"
              href="#ConditionalDetailsGreenTransaction"
            >
              <img src="images/conditional_details.png" />
              Conditional Details<span>3</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link AdjustmentsAndDepositBtnShowGreen"
              data-toggle="tab"
              href="#AdjustmentsAndDepositGreenTransaction"
            >
              <img src="images/adjustment_deposit.png" />
              Adjustments and Deposits<span>3</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="LeadDetailsInput mt-4">
        <div className="tab-content">
          <div
            className="tab-pane fade"
            id="GeneralInformationGreenTransaction"
          >
            <div className="row">
              <div className="col-md-3">
                <div className="CommonModalArea">
                  <div className="form-group">
                    <h5>Assigned to</h5>
                    <div className="CommonModalBox">
                      <h5
                        className="CursorPointer FolderPermissionId"
                        onClick={() => showAssignSaleModal("clientContractDetails")}
                        
                      >
                        <figure>
                          <img src="images/icon-32.png" />
                        </figure>
                        <figure>
                          <img src="images/refresh.png" />
                        </figure>
                        John Doe
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mx-auto">
                <div className="form-group">
                  <h6>Sub Division</h6>
                  <div className="Categories Categories3">
                    <div className="Categories_all Categories_all3">
                      <span>Select SubDivision</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>-List of Subdivisions offered by the builder</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-3 ml-auto">
                <div className="CommonModalArea">
                  <div className="form-group">
                    <h5>Status</h5>
                    <div className="CommonModalBox">
                      <h5 className="">Interested</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <h6 className="mb-4">Realtor/Referrer</h6>
              </div>
              <div className="form-group col-md-5">
                <h6>Name</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Input Name"
                />
              </div>
              <div className="form-group col-md-5 ml-auto">
                <h6>Agency</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Input Agency"
                />
              </div>
              <div className="form-group col-md-5">
                <h6>Email</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Input Email"
                />
              </div>
              <div className="col-12">
                <div className="form-group ">
                  <div className="CommonModalArea">
                    <h5>Configure Client View</h5>
                    <div className="CommonModalBox">
                      <h5>
                        <figure>
                          <img src="images/configure_client_view.png" />
                        </figure>
                        Configure Client View
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Button d-flex justify-content-between mt-4">
              <button className="Create">Save</button>
              <button className="Outline">Cancel</button>
            </div>
          </div>
          <div
            className="tab-pane fade show active"
            id="ConditionalDetailsGreenTransaction"
          >
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <h6>Sale Type</h6>
                  <p className="ml-0">Multi-Family</p>
                </div>
                <div className="form-group d-flex align-items-end">
                  <div>
                    <h6>Client Assigned Unit</h6>
                    <p className="ml-0">U222-CHI-2B-243 (Unit #222)</p>
                  </div>
                  <div className="Button ml-1">
                    <button className="Create px-1">
                      Remove Assigned Unit
                    </button>
                  </div>
                </div>
                <div className="form-group">
                  <h6>Client Purchase Price</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert purchase price"
                  />
                </div>
                <div className="form-group">
                  <h6>Down Payment Percentage</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert deposit amount"
                  />
                </div>
                <div className="form-group">
                  <h6>Down Payment Percentage</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Down Payment Percentage"
                  />
                </div>
                <div className="ParkingSpacesUnitsArea">
                  <h5>Parking Spaces/Units</h5>
                  <div className="ParkingSpacesUnitsTable">
                    <table>
                      <thead>
                        <tr>
                          <th>Description or Stall/Unit #</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="Action">
                              Stall 228
                              <img src="images/Cross.png" className="ml-4" />
                            </div>
                          </td>
                          <td>$1,200.00</td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              type=""
                              className="form-control"
                              placeholder="Insert description or Stall/Unit #"
                            />
                          </td>
                          <td>
                            <input
                              type=""
                              className="form-control"
                              placeholder="Insert quantity"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="Button mt-3">
                  <button className="Create">Add Parking</button>
                </div>
              </div>
              <div className="col-md-8">
                <div className="SatisfiedTab">
                  <table>
                    <thead>
                      <tr>
                        <th>Satisfied Conditions</th>
                        <th />
                        <th>Date</th>
                        <th>Status</th>
                        <th>Action</th>
                        <th>Authorization Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Deposit Paid</td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>06/12/2023 - 02:34 pm</td>
                        <td>
                          <div className="Action">
                            <img src="images/Lock.png" />
                          </div>
                        </td>
                        <td>
                          <div className="Action OpacaityAction">
                            <img src="images/unlock.png" className="mr-2" />
                            Unlock condition
                          </div>
                        </td>
                        <td>
                          <div className="Action TextRed">
                            Pending with John Manager{" "}
                            <img
                              src="images/view-po.png"
                              data-toggle="modal"
                              data-target="#ApproveUnlockConditionTransaction"
                              data-dismiss="modal"
                            />
                          </div>
                          <div className="ApproveRejectBtnArea">
                            <button className="ApproveBtn">Approve</button>
                            <button className="RejectBtn">Reject</button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Deposit Paid</td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>06/12/2023 - 02:34 pm</td>
                        <td>
                          <div className="Action">
                            <img src="images/Lock.png" />
                          </div>
                        </td>
                        <td>
                          <div className="Action OpacaityAction">
                            <img src="images/unlock.png" className="mr-2" />
                            Unlock condition
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Deposit Paid</td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>06/12/2023 - 02:34 pm</td>
                        <td>
                          <div className="Action">
                            <img src="images/Lock.png" />
                          </div>
                        </td>
                        <td>
                          <div className="Action OpacaityAction">
                            <img src="images/unlock.png" className="mr-2" />
                            Unlock condition
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Deposit Paid</td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>06/12/2023 - 02:34 pm</td>
                        <td>
                          <div className="Action">
                            <img src="images/Lock.png" />
                          </div>
                        </td>
                        <td>
                          <div className="Action OpacaityAction">
                            <img src="images/unlock.png" className="mr-2" />
                            Unlock condition
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Deposit Paid</td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>06/12/2023 - 02:34 pm</td>
                        <td>
                          <div className="Action">
                            <img src="images/Lock.png" />
                          </div>
                        </td>
                        <td>
                          <div className="Action OpacaityAction">
                            <img src="images/unlock.png" className="mr-2" />
                            Unlock condition
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={5}>
                          <div className="Button text-right">
                            <button
                              className="Create px-2 py-2 fw-14"
                              data-toggle="modal"
                              data-target="#UnlockConditionTransaction"
                              data-dismiss="modal"
                            >
                              Request to Unlock
                              <br /> Condition
                            </button>
                          </div>
                        </td>
                        <td />
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="text-right mt-5">
                  <button className="LockBtn">
                    <img src="images/lock_white.png" />
                    Lock all Conditions
                  </button>
                  <button className="UnlockBtn">
                    <img src="images/unlock_white.png" />
                    Unlock all Conditions
                  </button>
                </div>
                <div className="row mt-5">
                  <div className="col-md-4 mx-auto">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Authorization Status</h5>
                        <div className="CommonModalBox">
                          <h5> Pending with John Manager</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mx-auto">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Client Documentation</h5>
                        <div className="CommonModalBox">
                          <h5>
                            <figure>
                              <img src="images/icon-63.png" />
                            </figure>{" "}
                            Client Documentation
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="AdjustCostBreakdown AdjustCostBreakdownGreen mt-5">
              <div className="TableHeader">
                <h6 id="GrossMarginBtn" className="CursorPointer">
                  Gross margin <img src="images/black-down-arrow.png" />
                </h6>
              </div>
              <div className="CostBreandownTable CostBreandownTableShow">
                <table>
                  <tbody>
                    <tr className="MiddleGreen">
                      <td>Revenue and Gross Margin Analysis</td>
                      <td>Budget</td>
                      <td>Accounting Actuals</td>
                      <td />
                      <td />
                    </tr>
                    <tr className="LightGreen">
                      <td>
                        Gross Parking Stalls/Pads Margin (% of Gross Margin)
                      </td>
                      <td />
                      <td>$1,200.00</td>
                      <td>0.24%</td>
                      <td />
                    </tr>
                    <tr>
                      <td>Contract Revenue (% Contract Margin)</td>
                      <td>$500,000.00</td>
                      <td>$500,000.00</td>
                      <td>100.00%</td>
                      <td />
                    </tr>
                    <tr>
                      <td className="pl-3">
                        Less: Design, Development, Admin Costs
                      </td>
                      <td>$500,000.00</td>
                      <td>$500,000.00</td>
                      <td />
                      <td />
                    </tr>
                    <tr>
                      <td className="pl-3">Less: Construction Costs</td>
                      <td>$500,000.00</td>
                      <td>$500,000.00</td>
                      <td />
                      <td />
                    </tr>
                    <tr className="LightGreen">
                      <td>Gross Contract Margin (% of Gross Margin)</td>
                      <td>$40,000.00</td>
                      <td>$500,000.00</td>
                      <td>92.00%</td>
                      <td />
                    </tr>
                    <tr className="MiddleGreenBlank">
                      <td colSpan={5}>blank</td>
                    </tr>
                    <tr>
                      <td>Contract Total Income (Projected)</td>
                      <td>$501,700.00</td>
                      <td>$501,700.00</td>
                      <td />
                      <td />
                    </tr>
                    <tr>
                      <td>
                        Less: Total Design, Develop, Admin Construction Costs
                      </td>
                      <td>$460,000.00</td>
                      <td>$0.00</td>
                      <td>($460,000.00)</td>
                      <td>-100.00</td>
                    </tr>
                    <tr className="DarkGreen">
                      <td>
                        Gross Budgeted Margin / Gross Actual Margin (Projected)
                      </td>
                      <td>$41,700.00(8.31%)</td>
                      <td>$501,700.00(-)</td>
                      <td />
                      <td />
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="Button mt-4 d-flex justify-content-between">
              <button
                className="Create"
                data-toggle="modal"
                data-target="#ClientContractDetailsNoUnit"
                data-dismiss="modal"
              >
                Save
              </button>
              <button
                className="Outline"
                data-toggle="modal"
                data-target="#ClientContractDetailsNoUnit"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="AdjustmentsAndDepositGreenTransaction"
          >
            <div className="SchedulePaymentsTableMain">
              <div className="TableHeader">
                <h6>Schedule New Payment</h6>
              </div>
              <div className="SchedulePaymentsTable SchedulePaymentsTableShow TableMinimumHeight2">
                <table>
                  <thead>
                    <tr>
                      <th>Schedule Date</th>
                      <th>Transaction Type</th>
                      <th>Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <input type="date" className="form-control" />
                        </div>
                      </td>
                      <td>
                        <div
                          className="SelectAnOption"
                          style={{ width: "100%" }}
                        >
                          <div className="dropdown">
                            <button
                              className=""
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Select Transaction Type
                              <img src="images/black-down-arrow.png" />
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                <b>Sales</b>
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -ADJ: Other Ajustments
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -ADRJ: Revenue Ajustments
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -CO: Change Order Deposit
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -DP: Down Payment Deposit
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -OP: Offer to Purchase
                              </a>
                              <a
                                className="dropdown-item fw-16"
                                href="javascript:void(0);"
                              >
                                <b>Accounting</b>
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -Balance Due on Closing
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -Closing Transactions
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -Revenue - Change Order
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -Revenue - Contract Price (Pre-Tax)
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -Revenue - Parking
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -Tax - Base
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -Tax - Rebate
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <input
                            type="text"
                            className="form-control width-50 mx-auto"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4}>
                        <div className="TableButtons">
                          <button className="BrandBtn">
                            Schedule New Payment
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="SchedulePaymentsTableMain mt-5">
              <div className="TableHeader">
                <h6 className="SchedulePaymentsBtn">
                  Schedule Payments <img src="images/down-arrow.png" />
                </h6>
                <div className="Actions">
                  <a href="javascript:void(0);" title="pdf">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="javascript:void(0);" title="download">
                    <img src="images/download.png" />
                  </a>
                  <a href="javascript:void(0);" title="settings">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
              <div className="SchedulePaymentsTable SchedulePaymentsTableShow">
                <table>
                  <thead>
                    <tr>
                      <th>Scheduled Payment Date</th>
                      <th>Transaction Type</th>
                      <th>Total</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>06/30/2023</td>
                      <td>DP:Down Payment Deposit</td>
                      <td>$ 30,000.00</td>
                      <td>
                        <div className="TableThreeDots">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/work-order-black.png" />
                                    Edit Scheduled Payment
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/dropdown-delete.png" />
                                    Remove Scheduled Payment
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/dropdown-delete.png" />
                                    Create New Transaction Entry
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/dropdown-delete.png" />
                                    Set Payment Reminder
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="SchedulePaymentsTableMain  mt-5">
              <div className="TableHeader">
                <h6>Add New Transactions</h6>
                <div className="Actions">
                  <a href="javascript:void(0);">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/download.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
              <div className="SchedulePaymentsTable TransactionTableShow TableMinimumHeight2">
                <table>
                  <thead>
                    <tr>
                      <th>Transaction Date</th>
                      <th>Payment Method</th>
                      <th>Transaction Type</th>
                      <th>Total</th>
                      <th>Notes</th>
                      <th>Supporting Documents</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>06/12/2023</td>
                      <td>
                        <div className="SelectAnOption">
                          <div className="dropdown">
                            <button
                              className=""
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Select an option
                              <img src="images/black-down-arrow.png" />
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -Ajustments
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -American Express
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -Bank Draft
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -Cash
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -Cheque
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -Direct Deposit
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -Joural Entry
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -Mastercard
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -Visa
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="SelectAnOption">
                          <div className="dropdown">
                            <button
                              className=""
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Select Transaction Type
                              <img src="images/black-down-arrow.png" />
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                className="dropdown-item fw-16"
                                href="javascript:void(0);"
                              >
                                <b>Sales</b>
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -ADJ: Other Ajustments
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -ADRJ: Revenue Ajustments
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -CO: Change Order Deposit
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -DP: Down Payment Deposit
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -OP: Offer to Purchase
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert Amount"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert notes"
                        />
                      </td>
                      <td>
                        <div className="Actions">
                          <a
                            href="javascript:void(0);"
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#AddNewSupportingDocs11"
                            data-dismiss="modal"
                          >
                            <img src="images/icon-63.png" />
                          </a>
                          <a
                            href="javascript:void(0)"
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#SupportingDocumentList11"
                            data-dismiss="modal"
                          >
                            <span className="Count">3</span>
                          </a>
                        </div>
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td colSpan={6}>
                        <div className="TableButtons">
                          <button className="BrandBtn">
                            Create New Transaction
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="SchedulePaymentsTableMain mt-5">
              <div className="TableHeader">
                <h6 className="TransactionBtn">
                  Transactions
                  <img src="images/down-arrow.png" />
                </h6>
                <div className="Actions">
                  <a href="transaction_manager.html" title="Transaction Module">
                    <img src="images/add_transactions.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/download.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
              <div className="SchedulePaymentsTable TransactionTableShow">
                <table>
                  <thead>
                    <tr>
                      <th>Transaction ID#</th>
                      <th>Transaction Date</th>
                      <th>Payment Method</th>
                      <th>Transaction Type</th>
                      <th>Total</th>
                      <th>Notes</th>
                      <th>Supporting Documents</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>ADJR-ARB-1B-243-1</td>
                      <td>06/12/2023</td>
                      <td>American Express</td>
                      <td>ADRJ: Revenue Adjustments</td>
                      <td>$ 500.00</td>
                      <td>Price Updates</td>
                      <td>
                        <div className="Actions">
                          <a
                            href="javascript:void(0);"
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#AddNewSupportingDocs11"
                            data-dismiss="modal"
                          >
                            <img src="images/icon-63.png" />
                          </a>
                          <a
                            href="javascript:void(0)"
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#SupportingDocumentList11"
                            data-dismiss="modal"
                          >
                            <span className="Count">3</span>
                          </a>
                        </div>
                      </td>
                      <td>
                        <button className="Commited">Commited</button>
                      </td>
                    </tr>
                    <tr>
                      <td>DP-ARB-1B-243-2</td>
                      <td>06/12/2023</td>
                      <td>Cheque</td>
                      <td>DP : Down Payment Depsoit</td>
                      <td>$ 45,000.00</td>
                      <td>Cheque number #123</td>
                      <td>
                        <div className="Actions">
                          <a
                            href="javascript:void(0);"
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#AddNewSupportingDocs11"
                            data-dismiss="modal"
                          >
                            <img src="images/icon-63.png" />
                          </a>
                          <a
                            href="javascript:void(0)"
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#SupportingDocumentList11"
                            data-dismiss="modal"
                          >
                            <span className="Count">3</span>
                          </a>
                        </div>
                      </td>
                      <td>
                        <button className="Commited">Commited</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="AdjustCostBreakdown mt-5">
              <div className="TableHeader">
                <h6 id="CostBreandownBtn" className="CursorPointer">
                  Cost Breakdown <img src="images/black-down-arrow.png" />
                </h6>
              </div>
              <div className="CostBreandownTable CostBreandownTableShow">
                <table>
                  <tbody>
                    <tr className="MiddleBlue">
                      <td>Contract Price Before Tax</td>
                      <td>$500,000.00</td>
                    </tr>
                    <tr className="LightBlue">
                      <td>Change Order Total</td>
                      <td>$10,825.00</td>
                    </tr>
                    <tr className="LightBlue">
                      <td>Parking (Stall 229)</td>
                      <td>$1,200.00</td>
                    </tr>
                    <tr className="LightBlue">
                      <td>Adjustment: Price Updates</td>
                      <td>$500.00</td>
                    </tr>
                    <tr className="MiddleBlue">
                      <td>Sub Total</td>
                      <td>$512,525.00</td>
                    </tr>
                    <tr className="LightBlue">
                      <td>Tax 5%</td>
                      <td>$25,626.25</td>
                    </tr>
                    <tr className="DarkBlue">
                      <td>Total</td>
                      <td>$538,151.25</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="Button mt-4 d-flex justify-content-between">
              <button className="Create" data-dismiss="modal">
                Save
              </button>
              <button className="Outline" data-dismiss="modal">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <hr className="HrLine" />
  <div className="DiscussionArea">
    <div className="DiscussionTitle bg_f7">
      <h4>Discussion</h4>
      <div className="DiscussionChat">
        <img src="images/down.png" className="DiscussionIcon" />
        <a href="#">
          <img src="images/pdf2.png" />
        </a>
      </div>
    </div>
    <div className="DiscussionHideArea" style={{ display: "block" }}>
      <div className="DiscussionBox">
        <span>FM</span>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Add a Comment ....."
          />
          <div className="DiscussionOverlay">
            <a href="" className="CancelChat">
              <img src="images/plus-cancel1.png" />
            </a>
            <a href="" className="SendChat">
              <img src="images/forwardbutton.png" />
            </a>
          </div>
        </div>
      </div>
      <div className="DiscussionBox">
        <span>TH</span>
        <div className="DiscussionRight">
          <h6>Inspections are delayed</h6>
          <small>04/19/2023, at 02:47 pm</small>
        </div>
      </div>
      <div className="ReplyArea">
        <a href="javascript:void(0);">Reply</a>
        <div className="DiscussionBox">
          <span>TH</span>
          <div className="DiscussionRight">
            <h6>Inspections are delayed</h6>
            <small>04/19/2023, at 02:47 pm</small>
          </div>
        </div>
        <div className="DiscussionBox">
          <span>TH</span>
          <div className="DiscussionRight">
            <h6>Inspections are delayed</h6>
            <small>04/19/2023, at 02:47 pm</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        </Modal.Body>
      </Modal>

        {/*EDITCONTRACTSMODALSHOW  */}

        <Modal
        show={editContactInformationModal}
        onHide={hideEditContactInformationModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideEditContactInformationModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox GrayModalTitle">
              <h4>Update Contract</h4>
            </div>
            <div className="FolderForm">
              <div className="ResidentialJobArea ResidentialJobAreaPara">
                <div className="ModalAccordian">
                  <div id="accordion">
                    <div className="card">
                      <div
                        id="GenerationInformation"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <form>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-12">
                                <div className="form-group">
                                  <div className="CommonModalArea">
                                    <h5>Contact Information</h5>
                                    <div className="CommonModalBox">
                                      <h5
                                        className="CursorPointer FolderPermissionId text-black"
                                         onClick={() =>showContactInformationModal("contract")}
                                      >
                                        <figure>
                                          <img src="images/Icon-6.png" />
                                        </figure>{" "}
                                        Import Contact Information
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-5">
                                <div className="form-group">
                                  <div className="ModalBigheading">
                                    <h3>
                                      <span>Personal Information</span>
                                    </h3>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <h6>
                                    Client Full Legal name
                                    <span className="required">*</span>
                                  </h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Input client's full legal name"
                                  />
                                </div>
                                <div className="form-group">
                                  <h6>
                                    Primary Phone Number
                                    <span className="required">*</span>
                                  </h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Input primary phone number"
                                  />
                                </div>
                                <div className="form-group">
                                  <h6>
                                    Email<span className="required">*</span>
                                  </h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Input email"
                                  />
                                </div>
                                <div className="form-group">
                                  <h6>Business Phone Number</h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Input business phone number"
                                  />
                                </div>
                                <div className="form-group">
                                  <h6>Fax Phone Number</h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Input fax phone number"
                                  />
                                </div>
                                <div className="form-group">
                                  <h6>Preferred Communication</h6>
                                  <div className="Categories Categories1">
                                    <div className="Categories_all Categories_all1">
                                      <span>Select preference</span>
                                      <img src="images/down.png" />
                                    </div>
                                    <ul>
                                      <li>-No preference</li>
                                      <li>-Phone</li>
                                      <li>-Email</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-5 ml-auto">
                                <div className="form-group col-md-12">
                                  <div className="ModalBigheading">
                                    <h3>
                                      <span>Address</span>
                                    </h3>
                                  </div>
                                </div>
                                <div className="form-group col-md-12">
                                  <h6>Street Address</h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Input street name"
                                  />
                                </div>
                                <div className="form-group col-md-12">
                                  <h6>Address Line two</h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Input address line two"
                                  />
                                </div>
                                <div className="form-group col-md-12">
                                  <h6>
                                    City<span className="required">*</span>
                                  </h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Input city name"
                                  />
                                </div>
                                <div className="form-group col-md-12">
                                  <h6>
                                    Zip/Postal Code
                                    <span className="required">*</span>
                                  </h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Input postal name"
                                  />
                                </div>
                                <div className="form-group col-md-12">
                                  <h6>
                                    Country<span className="required">*</span>
                                  </h6>
                                  <div className="CommonSelectBox">
                                    <select>
                                      <option selected="">
                                        Select Country
                                      </option>
                                      <option>India</option>
                                      <option>USA</option>
                                      <option>Australia</option>
                                    </select>
                                    <span>
                                      <img src="images/down.png" />
                                    </span>
                                  </div>
                                </div>
                                <div className="form-group col-md-12">
                                  <h6>
                                    State/Province
                                    <span className="required">*</span>
                                  </h6>
                                  <div className="CommonSelectBox">
                                    <select>
                                      <option selected="">
                                        Select State or Province
                                      </option>
                                      <option>Goa</option>
                                    </select>
                                    <span>
                                      <img src="images/down.png" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#GenerationInformation"
                      >
                        <h4>
                          <span>
                            <img src="images/generation_new.png" />
                          </span>
                          Generation Information
                        </h4>
                      </div>
                      <div
                        id="GenerationInformation"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <form>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="CommonModalArea">
                                  <div className="form-group">
                                    <h5>Assigned to</h5>
                                    <div className="CommonModalBox">
                                      <h5
                                        className="CursorPointer FolderPermissionId"
                                        title="assign respresentative"
                                        onClick={() =>showAssignSaleModal("editContract")}
                                      >
                                        <figure>
                                          <img src="images/icon-32.png" />
                                        </figure>

                                        <figure>
                                          <img src="images/refresh.png" />
                                        </figure>
                                        John Doe
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <h6>Sub Division</h6>
                                  <div className="Categories Categories3">
                                    <div className="Categories_all Categories_all3">
                                      <span>Select SubDivision</span>
                                      <img src="images/down.png" />
                                    </div>
                                    <ul>
                                      <li>
                                        -List of Subdivisions offered by the
                                        builder
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <h6 className="mb-4">Realtor/Referrer</h6>
                              </div>
                              <div className="form-group col-md-4">
                                <h6>Name</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Input Name"
                                />
                              </div>
                              <div className="form-group col-md-4">
                                <h6>Agency</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Input Agency"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col-md-4 mr-auto">
                                <h6>Email</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Input Email"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#businessAddress"
                      >
                        <h4>
                          <span>
                            <img src="images/conditonal_details.png" />
                          </span>
                          Conditional Details
                        </h4>
                        <a
                          className="Edit"
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#ResidentialEdit"
                          data-dismiss="modal"
                        >
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div
                        id="businessAddress"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <h6>Sale Type</h6>
                                <div className="Categories Categories3">
                                  <div className="Categories_all Categories_all3">
                                    <span>Sale Type</span>
                                    <img src="images/down.png" />
                                  </div>
                                  <ul>
                                    <li>
                                      -List of sales type for that company
                                      loaded from the systems (for example
                                      Multi-Family, Residential)
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <h6>Client Assigned Unit</h6>
                                <div className="Categories Categories3">
                                  <div className="Categories_all Categories_all3">
                                    <span>Client Assigned Unit</span>
                                    <img src="images/down.png" />
                                  </div>
                                  <ul>
                                    <li>
                                      -List of available units (no client
                                      assined) loaded from the system based on
                                      the sale type selection
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div
                        className="card-header"
                        data-toggle="collapse"
                        href="#Accounting"
                      >
                        <h4>
                          <span>
                            <img src="images/add_transactions.png" />
                          </span>
                          Adjustments and Deposits
                        </h4>
                        <a
                          className="Edit"
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#ResidentialEdit"
                          data-dismiss="modal"
                        >
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div
                        id="Accounting"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="SchedulePaymentsTableMain">
                            <div className="TableHeader">
                              <h6>Schedule New Payment</h6>
                            </div>
                            <div className="SchedulePaymentsTable SchedulePaymentsTableShow TableMinimumHeight2">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Schedule Date</th>
                                    <th>Transaction Type</th>
                                    <th>Notes</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div>
                                        <input
                                          type="date"
                                          className="form-control"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="SelectAnOption"
                                        style={{ width: "100%" }}
                                      >
                                        <div className="dropdown">
                                          <button
                                            className=""
                                            type="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            Select Transaction Type
                                            <img src="images/black-down-arrow.png" />
                                          </button>
                                          <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton"
                                          >
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <b>Sales</b>
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -ADJ: Other Ajustments
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -ADRJ: Revenue Ajustments
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -CO: Change Order Deposit
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -DP: Down Payment Deposit
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -OP: Offer to Purchase
                                            </a>
                                            <a
                                              className="dropdown-item fw-16"
                                              href="javascript:void(0);"
                                            >
                                              <b>Accounting</b>
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -Balance Due on Closing
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -Closing Transactions
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -Revenue - Change Order
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -Revenue - Contract Price
                                              (Pre-Tax)
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -Revenue - Parking
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -Tax - Base
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -Tax - Rebate
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <input
                                          type="text"
                                          className="form-control width-50 mx-auto"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan={4}>
                                      <div className="TableButtons">
                                        <button className="BrandBtn">
                                          Schedule New Payment
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="SchedulePaymentsTableMain mt-5">
                            <div className="TableHeader">
                              <h6>Schedule Payments</h6>
                              <div className="Actions">
                                <a href="javascript:void(0);" title="pdf">
                                  <img src="images/icon-53.png" />
                                </a>
                                <a href="javascript:void(0);" title="download">
                                  <img src="images/download.png" />
                                </a>
                                <a href="javascript:void(0);" title="settings">
                                  <img src="images/setting.png" />
                                </a>
                              </div>
                            </div>
                            <div className="SchedulePaymentsTable SchedulePaymentsTableShow">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Scheduled Payment Date</th>
                                    <th>Transaction Type</th>
                                    <th>Total</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>06/30/2023</td>
                                    <td>DP:Down Payment Deposit</td>
                                    <td>$ 30,000.00</td>
                                    <td>
                                      <div className="TableThreeDots">
                                        <ul className="">
                                          <li className="dropdown">
                                            <a
                                              className=""
                                              role="button"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <img src="images/dots.png" />
                                            </a>
                                            <ol className="dropdown-menu dropdown-menuwidth2">
                                              <li>
                                                <a href="javascript:void(0);">
                                                  <img src="images/work-order-black.png" />
                                                  Edit Scheduled Payment
                                                </a>
                                              </li>
                                              <li>
                                                <a href="javascript:void(0);">
                                                  <img src="images/dropdown-delete.png" />
                                                  Remove Scheduled Payment
                                                </a>
                                              </li>
                                              <li>
                                                <a href="javascript:void(0);">
                                                  <img src="images/dropdown-delete.png" />
                                                  Create New Transaction Entry
                                                </a>
                                              </li>
                                              <li>
                                                <a href="javascript:void(0);">
                                                  <img src="images/dropdown-delete.png" />
                                                  Set Payment Reminder
                                                </a>
                                              </li>
                                            </ol>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>06/30/2023</td>
                                    <td>DP:Down Payment Deposit</td>
                                    <td>$ 30,000.00</td>
                                    <td>
                                      <div className="TableThreeDots">
                                        <ul className="">
                                          <li className="dropdown">
                                            <a
                                              className=""
                                              role="button"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <img src="images/dots.png" />
                                            </a>
                                            <ol className="dropdown-menu dropdown-menuwidth2">
                                              <li>
                                                <a href="javascript:void(0);">
                                                  <img src="images/work-order-black.png" />
                                                  Edit Scheduled Payment
                                                </a>
                                              </li>
                                              <li>
                                                <a href="javascript:void(0);">
                                                  <img src="images/dropdown-delete.png" />
                                                  Remove Scheduled Payment
                                                </a>
                                              </li>
                                              <li>
                                                <a href="javascript:void(0);">
                                                  <img src="images/dropdown-delete.png" />
                                                  Create New Transaction Entry
                                                </a>
                                              </li>
                                              <li>
                                                <a href="javascript:void(0);">
                                                  <img src="images/dropdown-delete.png" />
                                                  Set Payment Reminder
                                                </a>
                                              </li>
                                            </ol>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="SchedulePaymentsTableMain  mt-5">
                            <div className="TableHeader">
                              <h6>Create New Transactions</h6>
                            </div>
                            <div className="SchedulePaymentsTable TransactionTableShow TableMinimumHeight2">
                              <table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <input
                                        type="date"
                                        className="form-control"
                                        placeholder="Insert Amount"
                                      />
                                    </td>
                                    <td>
                                      <div className="SelectAnOption width-250">
                                        <div className="dropdown">
                                          <button
                                            className=""
                                            type="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            Select Payment method
                                            <img src="images/black-down-arrow.png" />
                                          </button>
                                          <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton"
                                          >
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -Ajustments
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -American Express
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -Bank Draft
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -Cash
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -Cheque
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -Direct Deposit
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -Joural Entry
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -Mastercard
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -Visa
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="SelectAnOption width-250">
                                        <div className="dropdown">
                                          <button
                                            className=""
                                            type="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            Select Transaction Type
                                            <img src="images/black-down-arrow.png" />
                                          </button>
                                          <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton"
                                          >
                                            <a
                                              className="dropdown-item fw-16"
                                              href="javascript:void(0);"
                                            >
                                              <b>Sales</b>
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -ADJ: Other Ajustments
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -ADRJ: Revenue Ajustments
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -CO: Change Order Deposit
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -DP: Down Payment Deposit
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              -OP: Offer to Purchase
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control width-50"
                                        placeholder="Insert Amount"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Insert notes"
                                      />
                                    </td>
                                    <td>
                                      <div className="Actions">
                                        <a
                                          href="javascript:void(0);"
                                          data-toggle="modal"
                                          data-target="#AddNewSupportingDocs2"
                                        >
                                          <img src="images/icon-63.png" />
                                        </a>
                                      </div>
                                    </td>
                                    <td />
                                  </tr>
                                  <tr>
                                    <td colSpan={6}>
                                      <div className="TableButtons">
                                        <button className="BrandBtn">
                                          Create New Transaction
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="SchedulePaymentsTableMain mt-5">
                            <div className="TableHeader">
                              <h6 className="TransactionBtn">
                                Transactions
                                <img src="images/down-arrow.png" />
                              </h6>
                              <div className="Actions">
                                <a href="javascript:void(0);">
                                  <img src="images/icon-53.png" />
                                </a>
                                <a href="javascript:void(0);">
                                  <img src="images/download.png" />
                                </a>
                                <a href="javascript:void(0);">
                                  <img src="images/setting.png" />
                                </a>
                              </div>
                            </div>
                            <div className="SchedulePaymentsTable TransactionTableShow">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Transaction ID#</th>
                                    <th>Transaction Date</th>
                                    <th>Payment Method</th>
                                    <th>Transaction Type</th>
                                    <th>Total</th>
                                    <th>Notes</th>
                                    <th>Supporting Documents</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>ADJR-ARB-1B-243-1</td>
                                    <td>06/12/2023</td>
                                    <td>American Express</td>
                                    <td>ADRJ: Revenue Adjustments</td>
                                    <td>$ 500.00</td>
                                    <td>Price Updates</td>
                                    <td>
                                      <div className="Actions">
                                        <a
                                          href="javascript:void(0);"
                                          data-toggle="modal"
                                          data-target="#AddNewSupportingDocs2"
                                        >
                                          <img src="images/icon-63.png" />
                                        </a>
                                        <a
                                          href="javascript:void(0)"
                                          data-toggle="modal"
                                          data-target="#SupportingDocumentList"
                                        >
                                          <span className="Count">3</span>
                                        </a>
                                      </div>
                                    </td>
                                    <td>
                                      <button className="Commited">
                                        Commited
                                      </button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>DP-ARB-1B-243-2</td>
                                    <td>06/12/2023</td>
                                    <td>Cheque</td>
                                    <td>DP : Down Payment Depsoit</td>
                                    <td>$ 45,000.00</td>
                                    <td>Cheque number #123</td>
                                    <td>
                                      <div className="Actions">
                                        <a
                                          href="javascript:void(0);"
                                          data-toggle="modal"
                                          data-target="#AddNewSupportingDocs2"
                                        >
                                          <img src="images/icon-63.png" />
                                        </a>
                                        <a
                                          href="javascript:void(0)"
                                          data-toggle="modal"
                                          data-target="#SupportingDocumentList"
                                        >
                                          <span className="Count">3</span>
                                        </a>
                                      </div>
                                    </td>
                                    <td>
                                      <button className="Commited">
                                        Commited
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button
                        className="Create"
                        onClick={hideEditContactInformationModal}
                      >
                        Create Contract
                      </button>
                      <button
                        className="Outline"
                        onClick={hideEditContactInformationModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

        {/* CONTACTINFORMATIONMODALSHOW */}
        < Modal
        show={contactInformationModal}
        onHide={hideContactInformationModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            onClick={hideContactInformationModal}
            href="javascript:void(0);"
            className="CloseModal"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Import Contact Information</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="form-group">
                  <h6>Select File (Ibuild or xlsx)</h6>
                  <div className="Upload">
                    <span>-Drag and Drop Files or click to select</span>
                    <input type="file" />
                  </div>
                </div>
                <div className="form-group">
                  <div className="FilesAdded">
                    <label>file_name.pdf</label>
                    <span>
                      <img src="images/Cross.png" title="canceling" />
                    </span>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between ">
                  <button
                    className="Create"
                    data-toggle="modal"
                    data-target="#CreateNewLead"
                    data-dismiss="modal"
                  >
                    Import Contact
                  </button>
                  <button
                    className="Outline"
                    onClick={hideContactInformationModal}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal >

      {/* VIEWUPDATEDETAILSMODALSHOW */}

      <Modal
        show={viewUpdateJobDetailsModal}
        onHide={hideViewUpdateJobDetailsModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideViewUpdateJobDetailsModal}
          >
            ×
          </a>
          <div className="ResidentialJobArea">
  <div className="ModalTitleBox GrayModalTitle">
    <h4>Residential Job Details</h4>
  </div>
  <div className="ResidentialJobHead">
    <h3>
      HIL-2B-376 (Basement Development)
      <span className="dropdown">
        <a
          className="dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img src="images/dots.png" />
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
          <a className="dropdown-item" href="#">
            <img src="images/Archive.png" /> Archive Job
          </a>
        </div>
      </span>
    </h3>
    <article>
      <aside>
        <h4>Created By</h4>
        <p>Jonh Doe</p>
      </aside>
      <aside>
        <h4>Creation Date</h4>
        <p>04/15/2023</p>
      </aside>
      <aside>
        <h4>Last Update</h4>
        <p>07/10/2023</p>
      </aside>
    </article>
  </div>
  <div className="ModalAccordian">
    <div id="accordion">
      <div className="card">
        <div className="card-header" data-toggle="collapse" href="#collapseOne">
          <h4>
            <span>
              <img src="images/generation_information.png" />
            </span>{" "}
            Project &amp; Foundation
          </h4>
          <a
            className="Edit"
            href="javascript:void(0);"
            data-toggle="modal"
            data-target="#ResidentialEdit"
            data-dismiss="modal"
          >
            <img src="images/Action-1.png" />
          </a>
        </div>
        <div
          id="collapseOne"
          className="collapse show"
          data-parent="#accordion"
        >
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Project</h6>
                    <p>Project 1</p>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue="Project 1"
                    />
                  </div>
                  <div className="Residential">
                    <h6>Foundation</h6>
                    <p>Basement Development</p>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue="Basement Development"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Add-Ons</h6>
                    <div className="AddonTable">
                      <div className="Addon">
                        <table>
                          <thead>
                            <tr>
                              <th>Job Id</th>
                              <th>Job Id</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>HIL-2B-377</td>
                              <td>
                                Detached Garage or Workshop or Major Storage
                                Shed
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <img src="images/Action-1.png" />
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>HIL-2B-378</td>
                              <td>Secondary Suite - Over Detached G arage</td>
                              <td>
                                <a href="javascript:void(0);">
                                  <img src="images/Action-1.png" />
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header" data-toggle="collapse" href="#collapseTwo">
          <h4>
            <span>
              <img src="images/Residential-2.png" />
            </span>{" "}
            Details
          </h4>
          <a
            className="Edit"
            href="javascript:void(0);"
            data-toggle="modal"
            data-target="#ResidentialEdit"
            data-dismiss="modal"
          >
            <img src="images/Action-1.png" />
          </a>
        </div>
        <div
          id="collapseTwo"
          className="collapse show"
          data-parent="#accordion"
        >
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Home Type</h6>
                    <p>2-Storey</p>
                    <select className="form-control">
                      <option>2-Storey</option>
                    </select>
                  </div>
                  <div className="Residential">
                    <h6>Specification Document</h6>
                    <p>Standard Specifications</p>
                    <select className="form-control">
                      <option>Standard Specifications</option>
                    </select>
                  </div>
                  <div className="Residential">
                    <h6>Tax Profile</h6>
                    <p>Alberta Profile</p>
                    <select className="form-control">
                      <option>Alberta Profile</option>
                    </select>
                  </div>
                  <div className="Residential">
                    <h6>Job Site GMT (UTC) Timezone</h6>
                    <p>Moutain Time GMT - 06</p>
                    <select className="form-control">
                      <option>Moutain Time GMT - 06</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Building Permit #</h6>
                    <p>BP2023-11766</p>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue="BP2023-11766"
                    />
                  </div>
                  <div className="Residential">
                    <h6>Base Model</h6>
                    <p>Residential Job</p>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue="Residential Job"
                    />
                  </div>
                  <div className="Residential">
                    <h6>Default Currency</h6>
                    <p>CAD</p>
                    <select className="form-control">
                      <option>CAD</option>
                    </select>
                  </div>
                  <div className="Residential">
                    <h6>Accounting Status</h6>
                    <p>Integrate with Accounting Software</p>
                    <label className="switch">
                      {" "}
                      Integrate with Accounting Software
                      <input type="checkbox" name="" />
                      <span className="slider" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div
          className="card-header"
          data-toggle="collapse"
          href="#collapseThree"
        >
          <h4>
            <span>
              <img src="images/Residential-3.png" />
            </span>{" "}
            Square Foot
          </h4>
          <a
            className="Edit"
            href="javascript:void(0);"
            data-toggle="modal"
            data-target="#ResidentialEdit"
            data-dismiss="modal"
          >
            <img src="images/Action-1.png" />
          </a>
        </div>
        <div
          id="collapseThree"
          className="collapse show"
          data-parent="#accordion"
        >
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>SqFt Main Floor</h6>
                    <p>1,000.00</p>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue="1,000.00"
                    />
                  </div>
                  <div className="Residential">
                    <h6>SqFt Third Floor</h6>
                    <p>&nbsp;</p>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue=""
                    />
                  </div>
                  <div className="Residential">
                    <h6>SqFt Garage Floor</h6>
                    <p>800.00</p>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={800.0}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>SqFt Second Floor</h6>
                    <p>1,000.00</p>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue="1,000.00"
                    />
                  </div>
                  <div className="Residential">
                    <h6>Total House Square Footage</h6>
                    <p>2000</p>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={2000}
                      disabled=""
                    />
                  </div>
                  <div className="Residential">
                    <h6>SqFt Basement/Lower Level</h6>
                    <p>800.00</p>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={800.0}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div
          className="card-header"
          data-toggle="collapse"
          href="#collapseFour"
        >
          <h4>
            <span>
              <img src="images/Residential-4.png" />
            </span>{" "}
            Business Address
          </h4>
          <a
            className="Edit"
            href="javascript:void(0);"
            data-toggle="modal"
            data-target="#ResidentialEdit"
            data-dismiss="modal"
          >
            <img src="images/Action-1.png" />
          </a>
        </div>
        <div
          id="collapseFour"
          className="collapse show"
          data-parent="#accordion"
        >
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Job Address</h6>
                    <p>Street 1</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert full Address"
                    />
                  </div>
                  <div className="Residential">
                    <h6>Lot</h6>
                    <p>&nbsp;</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert lot number"
                    />
                  </div>
                  <div className="Residential">
                    <h6>Block</h6>
                    <p>&nbsp;</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert block number"
                    />
                  </div>
                  <div className="Residential">
                    <h6>Phase</h6>
                    <p>Insert phase</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert phase"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>City</h6>
                    <p>Calgary</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert Insert City Name"
                    />
                  </div>
                  <div className="Residential">
                    <h6>Zip/ Postal Code</h6>
                    <p>T3A T3A</p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert zip code"
                    />
                  </div>
                  <div className="Residential">
                    <h6>Country</h6>
                    <p>Canada</p>
                    <select className="form-control">
                      <option>Select Country</option>
                    </select>
                  </div>
                  <div className="Residential">
                    <h6>State/Province</h6>
                    <p>AB</p>
                    <select className="form-control">
                      <option>Select State/Province</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div
          className="card-header"
          data-toggle="collapse"
          href="#collapseFive"
        >
          <h4>
            <span>
              <img src="images/Residential-5.png" />
            </span>{" "}
            Sales Information
          </h4>
        </div>
        <div
          id="collapseFive"
          className="collapse show"
          data-parent="#accordion"
        >
          <div className="card-body">
            <div className="row">
              <div className="col-sm-4">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Agreement Date</h6>
                    <p>05/15/2023</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Possession Date</h6>
                    <p>05/15/2024</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Customer Purchase Price</h6>
                    <p>$450,000.00</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Sales Consultant</h6>
                    <p>Jill Sales</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Sales Type</h6>
                    <p>Model Home</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header" data-toggle="collapse" href="#collapseSix">
          <h4>
            <span>
              <img src="images/Residential-6.png" />
            </span>{" "}
            Estimation &amp; Construction Information
          </h4>
        </div>
        <div
          id="collapseSix"
          className="collapse show"
          data-parent="#accordion"
        >
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Primary Estimator/Purchaser</h6>
                    <p>Jack Purchaser</p>
                  </div>
                  <div className="Residential">
                    <h6>Primary Site Super</h6>
                    <p>Joe Site</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div
          className="card-header"
          data-toggle="collapse"
          href="#collapseSeven"
        >
          <h4>
            <span>
              <img src="images/Residential-7.png" />
            </span>{" "}
            Client Information
          </h4>
        </div>
        <div
          id="collapseSeven"
          className="collapse show"
          data-parent="#accordion"
        >
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Personal Information</h6>
                  </div>
                  <div className="Residential">
                    <h6>Client Full Legal name</h6>
                    <p>Joe Full Client</p>
                  </div>
                  <div className="Residential">
                    <h6>Primary Phone Number</h6>
                    <p>(587)555-555</p>
                  </div>
                  <div className="Residential">
                    <h6>Email</h6>
                    <p>joe.fclient@email.com</p>
                  </div>
                  <div className="Residential">
                    <h6>Business Phone Number</h6>
                    <p>&nbsp;</p>
                  </div>
                  <div className="Residential">
                    <h6>Fax Phone Number</h6>
                    <p>&nbsp;</p>
                  </div>
                  <div className="Residential">
                    <h6>Preferred Communication</h6>
                    <p>Email</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="ResidentialBox">
                  <div className="Residential">
                    <h6>Address</h6>
                  </div>
                  <div className="Residential">
                    <h6>Street Address</h6>
                    <p>120 4th Street Sw</p>
                  </div>
                  <div className="Residential">
                    <h6>Address Line two</h6>
                    <p>Insert</p>
                  </div>
                  <div className="Residential">
                    <h6>City</h6>
                    <p>Calgary</p>
                  </div>
                  <div className="Residential">
                    <h6>Zip/Postal Code</h6>
                    <p>T3A T3A</p>
                  </div>
                  <div className="Residential">
                    <h6>Country</h6>
                    <p>Canada</p>
                  </div>
                  <div className="Residential">
                    <h6>State/Province</h6>
                    <p>Select State or Province</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="CloseButton">
      <button className="Close ClosePermissionId" data-dismiss="modal">
        Close
      </button>
    </div>
  </div>
</div>

        </Modal.Body>
      </Modal>
  {/* SETREMIANDERMODALSHOW */}

  <Modal
        show={postalTransactionModal}
        onHide={hidePostalTransactionModal}
        className="ModalBox MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hidePostalTransactionModal}
          >
            ×
          </a>
          <div className="FormArea">
  <div className="ModalTitleBox">
    <h4>Post Transaction</h4>
  </div>
  <div className="ModalBigheading mb-0">
    <h3>
      <span>Transaction ID#</span>DP-ARB-1B-243-2
    </h3>
  </div>
  <div className="TableList CommonHeading TableHeightScroll">
    <table>
      <thead>
        <tr>
          <th>DR/CR</th>
          <th>Account</th>
          <th>Client</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>$ 45,000.00</td>
          <td>DP-ARB-1B-243-2-Cheque number #123</td>
          <td>Marna Contract</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div className="ModalFilterAreaBox mt-5 mb-3">
    <h4 className="mb-4">Operation</h4>
    <div className="RadioArea d-flex  flex-column">
      <div className="form-group mb-3">
        <label className="Radio">
          Payment/Adjustment
          <input type="radio" name="Cuisine" defaultChecked="" />
          <span className="checkmark" />
        </label>
      </div>
      <div className="form-group">
        <label className="Radio">
          Refund
          <input type="radio" name="Cuisine" />
          <span className="checkmark" />
        </label>
      </div>
    </div>
  </div>
  <div className="TableList CommonHeading TableHeightScroll">
    <table>
      <thead>
        <tr>
          <th>TX.Entry</th>
          <th>Account</th>
          <th>Sub-Account Posting</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Debit</td>
          <td>
            <div className="CommonSelectBox">
              <select>
                <option selected="">Select State or Province</option>
                <option>India</option>
                <option>USA</option>
                <option>Australia</option>
              </select>
              <span>
                <img src="images/down.png" />
              </span>
            </div>
          </td>
          <td>
            <label className="CheckBox">
              <input type="checkbox" />
              <span className="checkmark" />
            </label>
          </td>
        </tr>
        <tr>
          <td>Credit</td>
          <td>
            <div className="CommonSelectBox">
              <select>
                <option selected="">Select State or Province</option>
                <option>India</option>
                <option>USA</option>
                <option>Australia</option>
              </select>
              <span>
                <img src="images/down.png" />
              </span>
            </div>
          </td>
          <td>
            <label className="CheckBox">
              <input type="checkbox" />
              <span className="checkmark" />
            </label>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div className="Button mt-5 d-flex justify-content-between">
    <button className="Create">Post Transaction</button>
    <button
      className="Create"
      data-toggle="modal"
      data-target="#TransactionCreateBtn"
      data-dismiss="modal"
    >
      Save Without Posting
    </button>
    <button
      className="Outline"
      data-toggle="modal"
      data-target="#TransactionCreateBtn"
      data-dismiss="modal"
    >
      Cancel
    </button>
  </div>
</div>

        </Modal.Body>
      </Modal>


       {/* CLIENTDOCUMENTATIONMODALSHOW */}
       < Modal
  show={ClientDocumentationModal}
  onHide={hideClientDocumentationModal}
  className="ModalBox ExtraLargeModal"
>
  <Modal.Body>
    <a
      href="javascript:void(0);"
      className="CloseModal"
      onClick={hideClientDocumentationModal}
      title="Close"
    >
      ×
    </a>

    <div className="FormArea">
  <div className="ModalTitleBox">
    <h4>Client Documentation</h4>
  </div>
  <div className="FolderForm">
    <form>
      <div className="row">
      <div className="col-sm-4">
              <div className="DashboardLeft">
                <div className="ShowList">
                  <div className="ContactInformationArea">
                    <h6>Contact Information</h6>
                    <div className="ContactInformationBox border-0">
                      <span className="circle">JL</span>
                      <div className="ContactInformationName">
                        <h6>John Lead</h6>
                        <p>
                          312 Weddenburn Rd SE <br /> Calgary, Alberta{" "}
                          <img
                            src="images/location.png"
                            className="CursorPointer FolderPermissionId ml-2"
                            onClick={showLocationModal}
                          />
                        </p>
                        <p>
                          Phone: <span>(403)555-555</span>
                        </p>
                        <p>
                          Email: <span>John.lead@email.com</span>
                        </p>
                      </div>
                    </div>
                    <div className="AssignUnit">
                      <h4>Assigned Unit</h4>
                      <p>U222-CHI-2B-243 (Unit #222)</p>
                    </div>
                  </div>
                  <aside>
                    <h5>Folder</h5>
                    <div>
                      <a
                        href="javascript:void(0);"
                        data-toggle="tooltip"
                        title="Add Folder"
                      >
                        <img src="images/Plus.png" />
                      </a>
                    </div>
                  </aside>
                  <div className="Search">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                    />
                    <span>
                      <img src="images/search.png" />
                    </span>
                  </div>
                </div>
                <div className="FolderList border-0">
                  <ul>
                    <li>
                      <span className="Icon">
                        <img src="images/Folder.png" />
                      </span>
                      <label>Sales Contracts</label>
                      <a
                        href="javascript:void(0);"
                        title="Folder History"
                        className="FolderPermissionId"
                        onClick={showFolderHistoryModal}
                      >
                        <img src="images/timer.png" />
                      </a>
                    </li>
                    <li>
                      <span className="Icon">
                        <img src="images/Folder.png" />
                      </span>
                      <label>Sales Documents Required By Law</label>
                      <a
                        href="javascript:void(0);"
                        title="Folder History"
                        className="FolderPermissionId"
                        onClick={showFolderHistoryModal}
                      >
                        <img src="images/timer.png" />
                      </a>
                    </li>
                    <li>
                      <span className="Icon">
                        <img src="images/Folder.png" />
                      </span>
                      <label>Sales Supplementary Forms</label>
                      <a
                        href="javascript:void(0);"
                        title="Folder History"
                        className="FolderPermissionId"
                        onClick={showFolderHistoryModal}
                      >
                        <img src="images/timer.png" />
                      </a>
                    </li>
                    <li>
                      <span className="Icon">
                        <img src="images/Folder.png" />
                      </span>
                      <label>Sales Letters</label>
                      <a
                        href="javascript:void(0);"
                        title="Folder History"
                        className="FolderPermissionId"
                        onClick={showFolderHistoryModal}
                      >
                        <img src="images/timer.png" />
                      </a>
                    </li>
                    <li>
                      <span className="Icon">
                        <img src="images/Folder.png" />
                      </span>
                      <label>Custom Layouts</label>
                      <a href="javascript:void(0);" title="Cancel">
                        <img src="images/Cross.png" />
                      </a>
                      <a
                        href="javascript:void(0);"
                        title="Folder History"
                        className="ml-5 FolderPermissionId"
                        onClick={showFolderHistoryModal}
                      >
                        <img src="images/timer.png" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
        <div className="col-md-8">
          <div className="EventArea">
            <h4>Pending File Events</h4>
            <div className="TableList">
              <table>
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>Modified by</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Change_order_summary.pdf</td>
                    <td>John Doe</td>
                    <td>Sent for client approval</td>
                    <td>02/05/2023</td>
                    <td>
                      <div className="Actions">
                      <a
                              href="javascript:void(0);"
                              className="FolderPermissionId"
                              title="Edit"
                              onClick={showEditFileModal}
                            >
                          <img src="images/Action-1.png" />
                        </a>
                        <a>
                          <img src="images/Action-2.png" />
                        </a>
                        <a>
                          <img src="images/Action-3.png" />
                        </a>
                      </div>
                    </td>
                  </tr>             
                  <tr>
                    <td>Change_order_summary.pdf</td>
                    <td>John Doe</td>
                    <td>Sent for client approval</td>
                    <td>02/05/2023</td>
                    <td>
                      <div className="Actions">
                      <a
                              href="javascript:void(0);"
                              className="FolderPermissionId"
                              title="Edit"
                              onClick={showEditFileModal}
                            >
                          <img src="images/Action-1.png" />
                        </a>
                        <a>
                          <img src="images/Action-2.png" />
                        </a>
                        <a>
                          <img src="images/Action-3.png" />
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="TodosButtons">
                <a
                  href="javascript:void(0)"
                  className="Create mb-2 FolderPermissionId"
                  onClick={showClientInformationModal}
                >
                  <img src="images/send_clinet.png" />
                  Send Client Information<span>3</span>
                </a>
                <a
                  href="javascript:void(0)"
                  className="ml-0 FolderPermissionId"
                  onClick={showCreateClientCTCModal}
                >
                  <img src="images/change_contract.png" />
                  Change to Contract (CTC) Agreement <span>!</span>
                </a>
                <a
                  href="javascript:void(0)"
                  className="ml-0 FolderPermissionId"
                  onClick={showManageSignatureModal}
                >
                  <img src="images/white_manage_signatures.png" /> Manage
                  Signatures<span>3</span>
                </a>
                <div className="FilesArea d-inline-block">
                  <div className="FilesHead">
                    <div className="FilesLeft FilesLeftSalesdoocument">
                      <aside>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                        />
                        <span>
                          <img src="images/search.png" />
                        </span>
                      </aside>
                    </div>
                  </div>
                </div>
              </div>
          <div className="FilesArea">
            <div className="FilesHead">
              <div className="FilesLeft">
                <h4>Files</h4>
                <aside>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                  <span>
                    <img src="images/search.png" />
                  </span>
                </aside>
              </div>
           
              <div className="FilesRight">
                    <a
                      href="javascript:void(0);"
                      title="Upload Files"
                      className="FolderPermissionId"
                      onClick={showUploadFilesModal}
                    >
                      <img src="images/Icon-6.png" />
                    </a>
                    <a
                      href="javascript:void(0);"
                      title="Create New File"
                      className="FolderPermissionId"
                      onClick={showCreateNewFileModal}
                    >
                      <img src="images/document-support.png" />
                    </a>
                  </div>
            </div>
          </div>
          <div className="row">
          <div className="col-md-4">
                  <div className="FilesBox2 Safety">
                    <figcaption>
                      <div>
                        <h4>Sales Contract</h4>
                      </div>
                      <ul>
                        <li className="dropdown">
                          <a
                            className="dropdown-toggle"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img src="images/dots.png" />
                          </a>
                          <ol className="dropdown-menu DropdownMenuSafetyModal">
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="FolderPermissionId"
                                onClick={showEditFileModal}
                              >
                                <img src="images/Action-1.png" />
                                Edit Document
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                onClick={showViewFileModal}
                              >
                                <img src="images/view_jobs_details.png" />
                                View Document
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                <img src="images/sign_document.png" />
                                Sent for Client Signature
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                onClick={showEditFileModal}
                              >
                                <img src="images/sign_document2.png" />
                                Sign documents
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                <img src="images/download.png" />
                                Download documents
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="FolderPermissionId"
                                onClick={showShareDocumentModal}
                              >
                                <img src="images/Action-2.png" />
                                Share documents
                              </a>
                            </li>
                          </ol>
                        </li>
                      </ul>
                    </figcaption>
                    <div className="FilesBoxCenterSafety FilesBoxCenterSafetyModal">
                      <div className="FilesBoxCenterSafetyLeft">
                        <img src="images/file.png" title="File" />{" "}
                        <h6>File</h6> <img src="images/red_esc.png" />
                      </div>
                      <div className="FilesBoxCenterSafetyRight">
                        <div className="form-group">
                          <h6>Client View</h6>
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <figcaption>
                      <div>
                        <p className="CursorPointer">
                          Last update 05/16/2023
                        </p>
                      </div>
                      <ul>
                        <li>
                          <a
                            href="javascript:void(0);"
                            title="Document History"
                            className="FolderPermissionId"
                            onClick={showDocumentHistoryModal}
                          >
                            <img src="images/timer.png" />
                          </a>
                        </li>
                      </ul>
                    </figcaption>
                  </div>
                </div>
           
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

  </Modal.Body>
</Modal >

  {/* LOCATIONMODALSHOW */}

  <Modal
        show={locationModal}
        onHide={hideLocationModal}
        className="ModalBox MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideLocationModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Location</h4>
            </div>
            <div className="FolderForm">
              <form>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2512.956156815679!2d-114.06795422412809!3d50.961514850436004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53717131e33f4a8f%3A0xa5e9f67b76d85576!2s312%20Weddenburn%20Rd%20SE%2C%20Calgary%2C%20AB%20T2J%201J1%2C%20Canada!5e0!3m2!1sen!2sin!4v1692684451831!5m2!1sen!2sin"
                  width="100%"
                  height={400}
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* DOCUMENTHISTORYSHOW */}
      <Modal
        show={documentHistoryModal}
        onHide={hideDocumentHistoryModal}
        className="ModalBox MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideDocumentHistoryModal}
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Document History</h4>
            </div>
            <div class="JobTitleArea mb-2">
              <div class="JobBox">
                <h6>
                  <span>Document:</span>&nbsp;Sales Contract.pdf
                </h6>
              </div>
            </div>

            <div class="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Modified By</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>John Doe</td>
                    <td>Jane Williams</td>
                    <td>04/25/2023</td>
                    <td>File Shared by the trade</td>
                  </tr>
                  <tr>
                    <td>John Doe</td>
                    <td>John Doe</td>
                    <td>04/25/2023</td>
                    <td>File E-sign by trade</td>
                  </tr>
                  <tr>
                    <td>John Doe</td>
                    <td>John Doe</td>
                    <td>04/25/2023</td>
                    <td>File sent for trade E-sign</td>
                  </tr>
                  <tr>
                    <td>John Doe</td>
                    <td>John Doe</td>
                    <td>04/25/2023</td>
                    <td>File Created</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* EDITFILEMODALSHOW */}

      <Modal
        show={editFileModal}
        onHide={hideEditFileModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideEditFileModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Edit File</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="EditOption">
                  <div className="EditOptionBox">
                    <h6>Name</h6>
                    <a href="javascript:void(0);">
                      Contract 1&nbsp;&nbsp;
                      <span>
                        <img src="images/fileview.png" />
                      </span>
                    </a>
                  </div>
                  <div className="EditOptionBox">
                    <h6>Folder</h6>
                    <a href="javascript:void(0);">
                      Sales Contract&nbsp;&nbsp;
                      <span>
                        <img src="images/refresh.png" />
                      </span>
                    </a>
                  </div>
                  <div className="EditOptionBox">
                    <h6>Draft</h6>
                    <a href="javascript:void(0);">
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </a>
                  </div>
                  <div className="EditOptionBox">
                    <h6>Use Company Header</h6>
                    <a
                      href="javascript:void(0);"
                      className="FolderPermissionId"
                      data-toggle="modal"
                      data-target="#HeaderPreview"
                      data-dismiss="modal"
                    >
                      <span>
                        <img src="images/company_header.png" />
                      </span>
                    </a>
                  </div>
                  <div className="EditOptionBox">
                    <h6>Download</h6>
                    <a href="javascript:void(0);">
                      <span>
                        <img src="images/download.png" />
                      </span>
                    </a>
                  </div>
                  <div className="EditOptionBox">
                    <h6>Insert Signature</h6>
                    <a href="javascript:void(0);">
                      <span>
                        <img src="images/signature.png" />
                      </span>
                    </a>
                  </div>
                  <div className="EditOptionBox">
                    <h6>Insert Keys</h6>
                    <a
                      href="javascript:void(0);"
                      className="FolderpermissionId"
                      data-toggle="modal"
                      data-target="#InputKeys"
                      data-dismiss="modal"
                    >
                      <span>
                        <img src="images/insert_keys.png" />
                      </span>
                    </a>
                  </div>
                </div>
                <textarea
                  id="editornew"
                  className="form-control"
                  rows={5}
                  defaultValue={""}
                />
                <div className="AgreementViewArea mt-5">
                  <div className="AgreementViewTop">
                    <a href="javscript:void(0)">
                      <img src="images/modal-big-logo.png" />
                    </a>
                    <div className="AgreementViewTopRight">
                      <h3>iBuild</h3>
                      <p>312 Weddenburn Rd SE</p>
                      <p>Calgary, AB CA, T2J 1J1</p>
                      <p>
                        4034005933 www.ibuildapplications.com
                        linden@ibuildapplications.com
                      </p>
                      <p>
                        “The bitterness of poor quality remains long after the
                        sweetness of meeting
                        <br />
                        the schedule has been gorgotten”
                      </p>
                    </div>
                  </div>
                  <div className="StatementAdjustmentArea px-4 pt-4">
                    <h6>Residential Real Estate Purchase Contract</h6>
                    <div className="ContractBetweenArea">
                      <h6>This Contract is Between :</h6>
                      <div className="ContractBetweenBox">
                        <h6>
                          Name{" "}
                          <span className="CompanyNameSpan">
                            <img src="images/companyname.png" />
                            Company Name
                          </span>{" "}
                          THE SELLER
                        </h6>
                        <h6>
                          Name{" "}
                          <span className="CompanyNameSpan">
                            <img src="images/companyname.png" />
                            Client Name
                          </span>{" "}
                          THE BUYER
                        </h6>
                      </div>
                    </div>
                    <div className="PropertyParaArea">
                      <h6>PROPERTY</h6>
                      <ul>
                        <li>
                          <span>1.1</span>The Property is the Land, Buildings,
                          Unattached, and Attached Goods located at (municipal
                          address):
                          <input type="text" className="form-control" />
                        </li>
                        <li>
                          <span>1.2</span>The Legal description of the Property
                          is :
                          <div className="ParaInput">
                            <p>
                              Plan <input type="text" />
                              Block <input type="text" />
                              Lot
                              <input type="text" />
                              Condo Plan <input type="text" />
                              Unit No.
                              <input type="text" />
                              Parking Unit <input type="text" />
                            </p>
                          </div>
                        </li>
                        <li>
                          <span>1.3</span>The Unattached Goods (Challets)
                          Described as follow :
                          <input type="text" className="form-control" /> <br />
                          <input type="text" className="form-control" />
                        </li>
                        <li>
                          <span>1.4</span>All Attached Goods (Fixtures) Except
                          for :
                          <input type="text" className="form-control" /> <br />
                          <input type="text" className="form-control" />
                        </li>
                        <li>
                          <span>1.5</span>Unless Otherwise agreed in writing,
                          title will be free and clear all encumbrances,
                          registrations and obligations except the following:
                        </li>
                      </ul>
                      <ul className="UnlessUl">
                        <li>those implied by law;</li>
                        <li className="d-flex">
                          non-financial obligation now on such title such as
                          easements, utility rights-of-way, covenants and
                          conditions that are normally found registered against
                          property of this nature and which do not affect the
                          stability of property;
                        </li>
                        <li>
                          homeowners association caveats, encumbrance and
                          similar registration; and
                        </li>
                        <li>
                          those item which the buyer agreed to assume in the
                          Contract.
                        </li>
                      </ul>
                    </div>
                    <div className="PropertyParaArea">
                      <h6>TRANSACTION</h6>
                      <ul>
                        <li>
                          <span>2.1</span>The Buyer and the Seller agree to act
                          cooperatively, reasonable, diligently and in good
                          faith.sss
                        </li>
                        <li className="d-flex">
                          <span>2.2</span>Other than the Deposits, the buyer
                          sell pay the purchase price by certified cheque,
                          lawyer’s trust cheque, bank draft or other agreed
                          value as follow:
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="InitialDepositArea px-4">
                    <p>
                      $<input type="text" />
                      <label>Initial Deposit</label>
                    </p>
                    <p>
                      $<input type="text" />
                      <label>Additional Deposit</label>
                    </p>
                    <p>
                      $<input type="text" />
                      <label>
                        Assumption of Mortgage
                        <small>(Approximate principal balance)</small>
                      </label>
                    </p>
                    <p>
                      $<input type="text" />
                      <label>New Financing</label>
                    </p>
                    <p>
                      $<input type="text" />
                      <label>Seller Financing</label>
                    </p>
                    <p>
                      $<input type="text" />
                      <label>Other Value</label>
                    </p>
                    <p>
                      $<input type="text" />
                      <label>
                        Balance Owing<small>(Subject to Adjustments)</small>
                      </label>
                    </p>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button
                    className="Create"
                    data-toggle="modal"
                    data-target="#FilePreview"
                    data-dismiss="modal"
                  >
                    Preview
                  </button>
                  <button
                    className="Create"
                    data-toggle="modal"
                    data-target="#ClientDocumentation"
                    data-dismiss="modal"
                  >
                    Save File
                  </button>
                  <button
                    className="Outline"
                    data-toggle="modal"
                    data-target="#ClientDocumentation"
                    data-dismiss="modal"
                  >
                    Cancel &amp; Delete
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* VIEWFILEMODALSHOW */}

      <Modal
        show={viewDocumentModal}
        onHide={hideViewFileModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideViewFileModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>View Document</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="AgreementViewArea">
                  <div className="AgreementViewTop">
                    <a href="javscript:void(0)">
                      <img src="images/modal-big-logo.png" />
                    </a>
                    <div className="AgreementViewTopRight">
                      <h3>iBuild</h3>
                      <p>312 Weddenburn Rd SE</p>
                      <p>Calgary, AB CA, T2J 1J1</p>
                      <p>
                        4034005933 www.ibuildapplications.com
                        linden@ibuildapplications.com
                      </p>
                      <p>
                        “The bitterness of poor quality remains long after the
                        sweetness of meeting
                        <br />
                        the schedule has been gorgotten”
                      </p>
                    </div>
                  </div>
                  <div className="StatementAdjustmentArea px-4">
                    <h6>Residential Real Estate Purchase Contract</h6>
                    <div className="ContractBetweenArea">
                      <h6>This Contract is Between :</h6>
                      <div className="ContractBetweenBox">
                        <h6>
                          Name{" "}
                          <span className="CompanyNameSpan">
                            Ibuild Applications
                          </span>{" "}
                          THE SELLER
                        </h6>
                        <h6>
                          Name <span className="CompanyNameSpan">John Doe</span>{" "}
                          THE BUYER
                        </h6>
                      </div>
                    </div>
                    <div className="PropertyParaArea">
                      <h6>PROPERTY</h6>
                      <ul>
                        <li>
                          <span>1.1</span>The Property is the Land, Buildings,
                          Unattached, and Attached Goods located at (municipal
                          address):
                          <input type="text" className="form-control" />
                        </li>
                        <li>
                          <span>1.2</span>The Legal description of the Property
                          is :
                          <div className="ParaInput">
                            <p>
                              Plan <input type="text" />
                              Block <input type="text" />
                              Lot
                              <input type="text" />
                              Condo Plan <input type="text" />
                              Unit No.
                              <input type="text" />
                              Parking Unit <input type="text" />
                            </p>
                          </div>
                        </li>
                        <li>
                          <span>1.3</span>The Unattached Goods (Challets)
                          Described as follow :
                          <input type="text" className="form-control" /> <br />
                          <input type="text" className="form-control" />
                        </li>
                        <li>
                          <span>1.4</span>All Attached Goods (Fixtures) Except
                          for :
                          <input type="text" className="form-control" /> <br />
                          <input type="text" className="form-control" />
                        </li>
                        <li>
                          <span>1.5</span>Unless Otherwise agreed in writing,
                          title will be free and clear all encumbrances,
                          registrations and obligations except the following:
                        </li>
                      </ul>
                      <ul className="UnlessUl">
                        <li>those implied by law;</li>
                        <li className="d-flex">
                          non-financial obligation now on such title such as
                          easements, utility rights-of-way, covenants and
                          conditions that are normally found registered against
                          property of this nature and which do not affect the
                          stability of property;
                        </li>
                        <li>
                          homeowners association caveats, encumbrance and
                          similar registration; and
                        </li>
                        <li>
                          those item which the buyer agreed to assume in the
                          Contract.
                        </li>
                      </ul>
                    </div>
                    <div className="PropertyParaArea">
                      <h6>TRANSACTION</h6>
                      <ul>
                        <li>
                          <span>2.1</span>The Buyer and the Seller agree to act
                          cooperatively, reasonable, diligently and in good
                          faith.sss
                        </li>
                        <li className="d-flex">
                          <span>2.2</span>Other than the Deposits, the buyer
                          sell pay the purchase price by certified cheque,
                          lawyer’s trust cheque, bank draft or other agreed
                          value as follow:
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="InitialDepositArea px-4">
                    <p>
                      $<input type="text" />
                      <label>Initial Deposit</label>
                    </p>
                    <p>
                      $<input type="text" />
                      <label>Additional Deposit</label>
                    </p>
                    <p>
                      $<input type="text" />
                      <label>
                        Assumption of Mortgage
                        <small>(Approximate principal balance)</small>
                      </label>
                    </p>
                    <p>
                      $<input type="text" />
                      <label>New Financing</label>
                    </p>
                    <p>
                      $<input type="text" />
                      <label>Seller Financing</label>
                    </p>
                    <p>
                      $<input type="text" />
                      <label>Other Value</label>
                    </p>
                    <p>
                      $<input type="text" />
                      <label>
                        Balance Owing<small>(Subject to Adjustments)</small>
                      </label>
                    </p>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button
                    className="Outline"
                    data-toggle="modal"
                    data-target="#ClientDocumentation"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* SHAREDOCUMENTMODALSHOW */}

      <Modal
        show={shareDocumentsModal}
        onHide={hideShareDocumentModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideShareDocumentModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Share Document</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="ModalBigheading">
                  <h3>
                    <span>Documents:</span>Statement of Adjustments.pdf
                  </h3>
                </div>
                <div className="ModalFilterAreaBox mb-3">
                  <h4 className="mb-4">Select Recipient</h4>
                  <div className="RadioArea d-flex  flex-column">
                    <div className="form-group">
                      <label className="Radio">
                        Select Recipient
                        <input
                          type="radio"
                          name="Cuisine"
                          defaultValue="text"
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="form-group mb-4">
                      <label className="Radio">
                        Other Contact
                        <input
                          type="radio"
                          name="Cuisine"
                          defaultValue="file"
                          defaultChecked=""
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="text RadioText">
                  <div className="form-group"></div>
                </div>
                <div
                  className="file RadioText mb-5"
                  style={{ display: "block" }}
                >
                  <div className="form-group">
                    <h6>Name</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert name"
                    />
                  </div>
                  <div className="form-group">
                    <h6>Email</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert Email"
                    />
                  </div>
                  <div className="Button">
                    <button className="Create">Add Recipient</button>
                  </div>
                </div>
                <h6>Recipients</h6>
                <div className="FilesAddedTable">
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="FilesAddedName">
                            <p>john Doe</p>
                            <span>
                              <img src="images/Cross.png" title="canceling" />
                            </span>
                          </div>
                        </td>
                        <td>johndoe@email.com</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="FilesAddedName">
                            <p>john Doe</p>
                            <span>
                              <img src="images/Cross.png" title="canceling" />
                            </span>
                          </div>
                        </td>
                        <td>johndoe@email.com</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="form-group">
                  <h6>Message</h6>
                  <textarea
                    type="text"
                    className="form-control"
                    rows={4}
                    placeholder="Include message (optional)"
                    defaultValue={""}
                  />
                </div>
                <div className="Button d-flex justify-content-between">
                  <button
                    className="Create mr-3"
                    data-toggle="modal"
                    data-target="#ClientDocumentation"
                    data-dismiss="modal"
                  >
                    Share Document
                  </button>
                  <button
                    className="Outline ml-3"
                    onClick={hideShareDocumentModal}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* FOLDERHISTORYSHOW */}

      < Modal
        show={folderHistoryModal}
        onHide={hideFolderHistoryModal}
        className="ModalBox MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideFolderHistoryModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Folder History</h4>
            </div>
            <div className="JobTitleArea mb-2">
              <div className="JobBox">
                <h6>
                  <span>Folder:</span>&nbsp;Custom Layouts
                </h6>
              </div>
            </div>
            <div className="TableList TableListAlign CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Modified By</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>John Doe</td>
                    <td>Jane Williams</td>
                    <td>04/25/2023</td>
                    <td>All documents download by the Trade</td>
                  </tr>
                  <tr>
                    <td>John Doe</td>
                    <td>John Doe</td>
                    <td>04/25/2023</td>
                    <td>File “layout4.pdf” updated</td>
                  </tr>
                  <tr>
                    <td>John Doe</td>
                    <td>John Doe</td>
                    <td>04/25/2023</td>
                    <td>New file “layout4.pdf” Created</td>
                  </tr>
                  <tr>
                    <td>John Doe</td>
                    <td>John Doe</td>
                    <td>04/25/2023</td>
                    <td>Folder shared with client</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal >

         {/* CLIENTINFORMATIONMODALSHOW */}
         < Modal
        show={sendClientInformationModal}
        onHide={hideClientInformationModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideClientInformationModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Send Client Information</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="ModalBigheading2">
                  <h3>Send Client Information</h3>
                </div>
                <div className="form-group">
                  <h6>Message to Receiver</h6>
                  <textarea
                    id="editornew3"
                    className="form-control"
                    rows={5}
                    defaultValue={""}
                  />
                </div>
                <div className="SelectAvailableArea">
                  <h5>Select Available Documents</h5>
                  <div className="SelectAvailableAreaBox">
                    <h6>Sales Documents + Contracts</h6>
                    <div className="form-group">
                      <label className="CheckBox">
                        {" "}
                        CTC Agreement
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="CheckBox">
                        {" "}
                        Sales Contract
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                  <div className="SelectAvailableAreaBox">
                    <h6>Change Order</h6>
                    <div className="form-group">
                      <label className="CheckBox">
                        {" "}
                        Change Orders
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ModalFilterAreaBox mb-3">
                  <h4 className="mb-4">Select Recipient</h4>
                  <div className="RadioArea d-flex  flex-column">
                    <div className="form-group">
                      <label className="Radio">
                        Registered Vendor
                        <input
                          type="radio"
                          name="Cuisine"
                          defaultValue="text"
                          defaultChecked=""
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="form-group mb-4">
                      <label className="Radio">
                        Other Contact
                        <input
                          type="radio"
                          name="Cuisine"
                          defaultValue="file"
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="file RadioText">
                  <div className="form-group">
                    <h6>Name</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert name"
                    />
                  </div>
                  <div className="form-group">
                    <h6>Email</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert Email"
                    />
                  </div>
                </div>
                <div
                  className="text RadioText mb-5"
                  style={{ display: "block" }}
                >
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <div className="CommonModalBox">
                        <h5
                          className="CursorPointer"
                          onClick={handleSystemVendorModalShow}
                        >
                          <figure>
                            <img src="images/register_vendor.png" />
                          </figure>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button className="Create" data-dismiss="modal">
                    Send Information View
                  </button>
                  <button className="Outline" data-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal >
      {/* SYSTEMVENDORMODALSHOW */}
      < Modal
        show={SystemVendorModal}
        onHide={handleSystemVendorModalClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                href="javascript:void(0);"
                className="CloseModal"
                onClick={handleSystemVendorModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>System Vendor List</h4>
                </div>
                <div className="FolderForm">
                  {/* <form> */}
                  <div className="form-group">
                    <div className="Categories Categories1 mb-4">
                      <h6>Vendor Type</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                        // name="vendorType"
                        // value={vendorType}
                        // onChange={handleInput}
                        >
                          <option>Select a Vendor Type</option>
                          {/* {checkArray(data1) ? (
                            <>
                              {data1?.map((ele, i) => {
                                return <option value={ele}>{ele}</option>;
                              })}
                            </>
                          ) : (
                            <></>
                          )} */}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input
                            type="text"
                            placeholder="-Search"
                          // onChange={(e) => onChangeHandler(e)}
                          />
                          <span className="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>
                        {/* <li className="CursorPointer">
                        - Full list of vendor types loaded from the system
                      </li> */}
                      </ul>
                      {/* <span style={{ color: "red", fontSize: "14px" }}>
                        {systemErrors?.vendorTypeError}
                      </span> */}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>Vendor Name</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="vendorName"
                        // value={vendorName}
                        // onChange={handleInput}
                        >
                          <option>Select a Vendor Name</option>
                          {/* {checkArray(vendorSearchData) ? (
                            <>
                              {vendorSearchData?.map((ele, i) => {
                                return (
                                  <option value={ele?.vendorName}>
                                    {ele.vendorName}
                                  </option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )} */}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input
                            type="text"
                            placeholder="Search"
                          // onChange={handleInputSearchCity}
                          />
                          <span className="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>
                        {/* <li className="CursorPointer">
                        - Full list of names loaded from the system
                      </li> */}
                      </ul>
                      {/* <span style={{ color: "red", fontSize: "14px" }}>
                        {systemErrors?.vendorNameError}
                      </span> */}
                    </div>
                  </div>
                  <div className="Button mt-5 d-flex justify-content-between">
                    <button
                      className="Outline"
                      onClick={handleSystemVendorModalClose}
                    >
                      Cancel
                    </button>
                    <button
                      className="Create"
                    //  onClick={handleSystemVendor}
                    >
                      Confirm Selection
                    </button>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >

       {/* CREATECLIENTCTCMODALSHOW */}
       < Modal
        show={createClientCTCModal}
        onHide={hideCreateClientCTCModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideCreateClientCTCModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Client's CTC</h4>
            </div>
            <div className="FolderForm">
              <div className="ContactInformationArea">
                <h6>Contact Information</h6>
                <div className="ContactInformationBox">
                  <span className="circle">JL</span>
                  <div className="ContactInformationName">
                    <h6>John Lead</h6>
                    <p>
                      312 Weddenburn Rd SE <br /> Calgary, Alberta{" "}
                      <img src="images/location.png" />
                    </p>
                    <p>
                      Phone: <span>(403)555-555</span>
                    </p>
                    <p>
                      Email: <span>John.lead@email.com</span>
                    </p>
                  </div>
                  <div className="AssignUnit">
                    <h4>Assigned Unit</h4>
                    <p>U222-CHI-2B-243 (Unit #222)</p>
                    <a href="javascript:void(0);" className="CommonBtn mt-4">
                      <img src="images/generate_ctc.png" />
                      Generate Client's CTC
                    </a>
                  </div>
                </div>
              </div>
              <div className="AgreementViewAreaGreen mt-3">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <label className="CheckBox">
                          {" "}
                          Client Visible
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </th>
                      <th>Name</th>
                      <th>Pictures</th>
                      <th>Unit Cost</th>
                      <th>Unit Discount</th>
                      <th>Quantity</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="DarkBlueBg">
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td colSpan={6}>Appliances</td>
                    </tr>
                    <tr className="LightBlueBg">
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td colSpan={6}>Appliance Allowances: Includes GST.</td>
                    </tr>
                    <tr>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>Platinum - Appliance Allowances: Includes GST.</td>
                      <td>
                        <div>
                          <img src="images/picture-1.png" />
                        </div>
                      </td>
                      <td>$10,000.00</td>
                      <td>
                        <input type="text" className="form-control" />
                      </td>
                      <td>
                        <input type="text" className="form-control" />
                      </td>
                      <td>$13,500.00</td>
                    </tr>
                    <tr>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>Silver - Appliance Allowances: Includes GST</td>
                      <td>
                        <div>
                          <img src="images/picture-1.png" />
                        </div>
                      </td>
                      <td>$2,5000.00</td>
                      <td>
                        <input type="text" className="form-control" />
                      </td>
                      <td>
                        <input type="text" className="form-control" />
                      </td>
                      <td />
                    </tr>
                    <tr className="DarkBlueBg">
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td colSpan={6}>Cabinets and Vanities</td>
                    </tr>
                    <tr>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        Add Standard Spec Base Cabinets - <br />
                        Laminate Countertop Included
                      </td>
                      <td>
                        <div>
                          <img src="images/picture-1.png" />
                        </div>
                      </td>
                      <td>$4,50.00</td>
                      <td>
                        <input type="text" className="form-control" />
                      </td>
                      <td>
                        <input type="text" className="form-control" />
                      </td>
                      <td>$900.00</td>
                    </tr>
                    <tr className="LightBlueBg">
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td colSpan={6}>Upgrade to granite</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <div className="SelectAnOption ml-0">
                          <div className="dropdown">
                            <button
                              className=""
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Select an option
                              <img src="images/black-down-arrow.png" />
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                Inslands
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <img src="images/picture-1.png" />
                        </div>
                      </td>
                      <td>$225.00</td>
                      <td>
                        <input type="text" className="form-control" />
                      </td>
                      <td>
                        <input type="text" className="form-control" />
                      </td>
                      <td>$13,500.00</td>
                    </tr>
                  </tbody>
                </table>
                <div className="CostBreakDownArea">
                  <h5 className="TableHeading CursorPointer" id="CostBreakDown">
                    Cost Breakdown <img src="images/down-arrow.png" />
                  </h5>
                  <div className="CostBreakDownShow">
                    <div className="Box">
                      <h6>Selections Details</h6>
                    </div>
                    <div className="Box DarkBlueBg">
                      <h6>Options Sub-Total</h6>
                      <h6>$15,075.00</h6>
                    </div>
                    <div className="Box LightBlueBg">
                      <h6>Tax 5%</h6>
                      <h6>$753.75</h6>
                    </div>
                    <div className="Box NavyBlueBg">
                      <h6>Total</h6>
                      <h6>$15,828.75</h6>
                    </div>
                  </div>
                </div>
                <div className="CostBreakDownArea">
                  <h5 className="TableHeading  my-4">
                    Cost Breakdown With Options
                  </h5>
                  <div className="Box DarkBlueBg">
                    <h6>Contract Price Before Tax</h6>
                    <h6>$400,000.00</h6>
                  </div>
                  <div className="Box LightBlueBg">
                    <h6>Change Order Total</h6>
                    <h6>$15,075.00</h6>
                  </div>
                  <div className="Box DarkBlueBg">
                    <h6>Sub Total</h6>
                    <h6>$415,075.00</h6>
                  </div>
                  <div className="Box LightBlueBg">
                    <h6>Tax 5%</h6>
                    <h6>$20,753.75</h6>
                  </div>
                  <div className="Box LightBlueBg">
                    <h6>Adjustment: Applied Tax Rebate</h6>
                    <h6>-$2,843.26</h6>
                  </div>
                  <div className="Box NavyBlueBg">
                    <h6>Total</h6>
                    <h6>$432,985.49</h6>
                  </div>
                </div>
                <div className="ChangeOrderCreatArea mt-5">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="InputBox">
                            <div className="form-group">
                              <input type="text" />
                              <label>Date</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="InputBox">
                            <div className="form-group">
                              <input type="text" />
                              <label>John Joe Client</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="InputBox">
                            <div className="form-group">
                              <input type="text" />
                              <label>Date</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="InputBox">
                            <div className="form-group">
                              <input type="text" />
                              <label>John Joe Builder Company</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button text-center">
                <button
                  className="Create mr-5 FolderPermissionId"
                  data-toggle="modal"
                  data-target="#CreateClientCtcConfirmSignature"
                  data-dismiss="modal"
                >
                  Send for client signature
                </button>
                <button
                  className="Outline ml-5"
                  onClick={hideCreateClientCTCModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >

       {/* MANAGESIGNATUREMODALSHOW */}
       < Modal
        show={manageSignatureModal}
        onHide={hideManageSignatureModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideManageSignatureModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Manage Signatures</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Add new signature</h5>
                    <div className="CommonModalBox">
                      <h5>
                        <figure>
                          <img src="images/add_new_signature.png" />
                        </figure>{" "}
                        Add new signature
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Signatures</h5>
                    <div className="CommonModalBox">
                      <input type="text" />
                      <a href="javascript:void(0);">
                        <img src="images/circle_minus.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Add new Initials</h5>
                    <div className="CommonModalBox">
                      <h5>
                        <figure>
                          <img src="images/add_new_signature.png" />
                        </figure>{" "}
                        Add new Initials
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Initials</h5>
                    <div className="CommonModalBox">
                      <input type="text" />
                      <a href="javascript:void(0);">
                        <img src="images/circle_minus.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button
                    className="Create"
                    data-toggle="modal"
                    data-target="#ClientDocumentation"
                    data-dismiss="modal"
                  >
                    Save
                  </button>
                  <button
                    className="Outline"
                    onClick={hideManageSignatureModal}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>

  )
}

export default TransactionManager
