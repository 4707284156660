import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import "../App.css";
import Modal from "react-bootstrap/Modal";
import Loader from "./Loader";
import moment from "moment";
import { toast } from "react-toastify";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  getTodoWarrantyWorkOrderDetails,
  getTradeView,
  todoWarrantySupportDocs,
  todoWarrantyWorkOrderAddNotes,
  getWorkOrderSelectedItem,
} from "../redux/action/tradeViewAuthAction";
import TradeViewSidenav from "./trade-view-sidenav";
import TradeHeader from "./TradeHeader";
const initialState = {
  AddNewSupportingDocsModal: false,
  id: "",
  supportingDocFiless:null,
  AddNotesModal:false,
  supportingDocFiles:null,
  message:"",
  requestItem:[],
  PurchaseOrderListModal:false,
};

const WarrantyWorkOrderTradeViewDetails = () => {
  const { state } = useLocation();
  console.log("state", state);

  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const { AddNewSupportingDocsModal, id, supportingDocFiless ,AddNotesModal,supportingDocFiles,message,requestItem,PurchaseOrderListModal} = iState;
  const compId = JSON.parse(window.localStorage.getItem("tradeViewLogin"));
  const { warrantyWorkOrderDetails } = useSelector(
    (state) => state.tradeViewLogin
  );

  console.log("warrantyWorkOrderDetails", warrantyWorkOrderDetails);

  useEffect(() => {
    dispatch(getTodoWarrantyWorkOrderDetails(compId?.user?.CompanyId,state?.id))
    dispatch(getWorkOrderSelectedItem( compId?.user?.CompanyId,state?.id)).then((res)=>{
      updateState({...iState,requestItem:res?.data?.data})
    })
  
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const handleFileChange = (event, type) => {
    const file = event?.target?.files[0];
    console.log("file changed", file);
    if (type === "support") {
      updateState({ ...iState, supportingDocFiles: file });
    }
    else {
      updateState({ ...iState, supportingDocFiless: file });
    }
  };

  const handleRemoveDocsFile = () => {
    // Reset the 'templateFile' state to null
    updateState({ ...iState, supportingDocFiless: null });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#docsInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleAddNewSupportingDocsModalShow = (id) => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: true,
      id: id,
      supportingDocFiless: "",
    });
  };
  const handleAddNewSupportingDocsModalClose = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: false,
      supportingDocFiless: "",
    });
  };

  const handleSupportDocs = () => {
    let dataa = {
      supportingDocFiles: supportingDocFiless,
    };
    dispatch(todoWarrantySupportDocs(compId?.user?.CompanyId, id, dataa)).then(
      (res) => {
        if (res.status === 201) {
          // dispatch(getConstructScheduleList(compId?.user?.id));
          // dispatch(getConstructionSupportDocsList(docsId));
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            AddNewSupportingDocsModal: false,
            id: "",
            supportingDocFiless: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            AddNewSupportingDocsModal: true,
          });
        }
      }
    );
  };

  const [selectedOption, setSelectedOption] = useState("Text");
  const [textInput, setTextInput] = useState(true);
  const [fileData, setFileData] = useState(false);

  const onValueChange = (event) => {
    updateState({ ...iState, message: "", supportingDocFiles: "" });
    const value = event.target.value;
    setSelectedOption(value);
    setTextInput(value === "Text");
    setFileData(value === "File");
  };

  const handleRemoveClientFile = () => {
    // Reset the 'templateFile' state to null
    updateState({
      ...iState,
      supportingDocFiles: null,
      supportingDocFiless: null,
      itemDocs: null,
    });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#fileInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleAddNotesShow = () => {
    updateState({
      ...iState,
      AddNotesModal: true,
      message: "",
      supportingDocFiles: "",
    });
  };
  const handleAddNotesClose = () => {
    updateState({
      ...iState,
      AddNotesModal: false,
    });
  };


  const handleAddNotes = () => {
    let dataa;
    if (textInput) {
      dataa = {
        warrantyNotes: [{ noteType: "text", message: message }],
        description: state?.description
          ? state?.description
          : "N/A",
          // jobType:"ResidentialJob",
          // jobId:2,
      };
    } else {
      dataa = {
        warrantyNotes: [{ noteType: "files" }],
        supportingDocFiles,
        description: state?.description
          ? state?.description
          : "N/A",
          // jobType:"ResidentialJob",
          // jobId:2,
      };
    }
    dispatch(todoWarrantyWorkOrderAddNotes(compId?.user?.CompanyId,state?.id, dataa))
    .then((res) => {
      if (res.status === 200) {
        toast.success("Add Notes Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AddNotesModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          AddNotesModal: true,
        });
      }
    }).catch((error)=>{
      toast.error(error.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      updateState({
        ...iState,
        AddNotesModal: true,
      });
    })
  };

  const handlePrint=()=>{
    window.print()
  }

  const handlePurchaseOrderListModalShow=()=>{
    updateState({...iState,PurchaseOrderListModal:true})
  }

  const handlePurchaseOrderListModalClose=()=>{
    updateState({...iState,PurchaseOrderListModal:false})
  }
  return (
    <>
       <TradeHeader/>
      <TradeViewSidenav />
      <div className="WrapperAreaClient">
        <div className="WrapperBoxClient pb-5">
          <div className="MainTitleBox">
            <div className="MainTitle">
              <h2>Work Order</h2>
            </div>
          </div>
          <div className="TitleBox">
            <h4 className="Title">Work Order #158</h4>
            <div className="TitleBox2 TitleBoxRight">
              <div>
                <h6>UUnit3 -CHI -2B -315</h6>
                <span>Multi-Family - Residential Unit</span>
              </div>
            </div>
          </div>
          <div className="WarrantyDashboardForm mb-4">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>
                        Description<span className="Required">*</span>
                      </label>
                      <p>{warrantyWorkOrderDetails?.workOrder?.description??"N/A"}</p>
                    </div>
                    <div className="form-group d-flex align-items-center">
                      <label className="mb-0">Status:</label>
                      <p className="my-0">Item(s) Still O/S</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Home Owner Acceptance ?</label>
                      <p className="TextRed">No</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Home Owner Acceptance ?</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Supporting Document</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer"
                           onClick={()=>handleAddNewSupportingDocsModalShow(state?.id)}
                          >
                            <figure>
                              <img src="images/document-support.png" />
                            </figure>
                            Supporting Document
                          </h5>
                          <span
                            className="Count CursorPointer"
                            data-toggle="modal"
                            data-target="#SupportingDocumentList"
                          >
                            3
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Add Notes</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer"
                            onClick={handleAddNotesShow}
                          >
                            <figure>
                              <img src="images/document-support.png" />
                            </figure>
                            Add Notes
                          </h5>
                          <span
                            className="Count CursorPointer"
                            data-toggle="modal"
                            data-target="#NotesList"
                          >
                            3
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="WarrantyDashboardForm">
            <div className="row">
              <div className="col-lg-6">
                <h4>Client Details</h4>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Possession Date</label>
                      <p>Auto-filled by System</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Request Date</label>
                      <p>Auto-filled by System</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Client Full Legal Name</label>
                      <p>Auto-filled by System</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Phone Number</label>
                      <p>Auto-filled by System</p>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Primary Email</label>
                      <p>Auto-filled by System</p>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        Job Address{" "}
                        <img src="images/location.png" className="ml-2" />
                      </label>
                      <p>Auto-filled by System</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 ml-auto">
                <h3>Vendor</h3>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Company Name</label>
                      <p>Plumber Brothers</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Type</label>
                      <p>Plumbing</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Phone Number</label>
                      <p>(403)4444444</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Warranty Contacts</label>
                      <p>Josef Joe</p>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Email</label>
                      <p>josef@plumbingbrothers.ca</p>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <div className="CommonModalArea2">
                        <div className="ColorHeading">
                          <h5 className="mb-0">Schedule Trades Day</h5>
                          <span className="Count CursorPointer">!</span>
                        </div>
                        <div className="CommonModalBox2">
                          <div className="d-flex align-items-center">
                            <h5
                              className="CursorPointer mb-0 text-black"
                              data-toggle="modal"
                              data-target="#InspectionDatesSelection"
                            >
                              <figure>
                                <img src="images/grey_calendar.png" />
                              </figure>
                              &nbsp; View Scheduled Trades Day
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="Filter FullFormGroup">
                      <div className="form-group">
                        <label>Trades-Day 1</label>
                        <p>
                          <img src="images/grey_calendar.png" />
                          05/17/2023
                        </p>
                      </div>
                      <div className="form-group">
                        <label>From</label>
                        <p>
                          <img src="images/grey_timer.png" />
                          02:30 PM
                        </p>
                      </div>
                      <div className="form-group">
                        <label>To</label>
                        <p>
                          <img src="images/grey_timer.png" />
                          05:30 PM
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="Filter">
                      <div className="form-group">
                        <label>Required Completion Date</label>
                        <p>
                          <img src="images/grey_timer.png" />
                          05/17/2023
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="Filter FullFormGroup">
                      <div className="form-group">
                        <label>Trades- Day 2</label>
                        <p>
                          <img src="images/grey_calendar.png" />
                          05/17/2023
                        </p>
                      </div>
                      <div className="form-group">
                        <label>From</label>
                        <p>
                          <img src="images/grey_timer.png" />
                          02:30 PM
                        </p>
                      </div>
                      <div className="form-group">
                        <label>To</label>
                        <p>
                          <img src="images/grey_timer.png" />
                          05:30 PM
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="Filter">
                      <div className="form-group">
                        <label>Required Completion Date</label>
                        <p>
                          <img src="images/grey_timer.png" />
                          05/17/2023
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Tableheader">
            <h6>Work Order Items</h6>
            <div className="Actions">
              <a href="javascript:void(0);">
                <img src="images/icon-53.png" />
              </a>
              <a href="javascript:void(0);">
                <img src="images/download.png" />
              </a>
              <a href="javascript:void(0);">
                <img src="images/setting.png" />
              </a>
            </div>
          </div>
          <div className="TableList TableListHeader ">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Description</th>
                  <th>Inspector</th>
                  <th>Date Inspected</th>
                  <th>Service Type</th>
                  <th>Supporting Document</th>
                </tr>
              </thead>
              <tbody>
                {
                  requestItem?.length>0&&
                  requestItem?.map((item,i)=>{
                    return(
                  <tr key={i}>
                  <td>{item.id}</td>
                  <td>{item.description}</td>
                  <td>{item.inspectorUser?.name??"N/A"}</td>
                  <td>{moment(item.dateInspected).format("YYYY-MM-DD")}</td>
                  <td>{item.serviceType}</td>
                  <td>
                    <div className="Actions">
                      <a
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#AddNewSupportingDocs2"
                      >
                        <img src="images/icon-63.png" />
                      </a>
                      <a
                        href="javascript:void(0)"
                        data-toggle="modal"
                        data-target="#SupportingDocumentList"
                      >
                        <span className="Count">3</span>
                      </a>
                    </div>
                  </td>
                </tr>
                    )
                  })
                }
                
               
              </tbody>
            </table>
          </div>
          <div className="TodosButtons">
            <a href="javascript:void(0)" className="ml-0">
              <h6 onClick={handlePurchaseOrderListModalShow}>
                <img src="images/view_purchase_order.png" />
                View Purchase Orders<span>3</span>
              </h6>
            </a>
          </div>
          <div className="d-flex justify-content-between mt-5 pb-5">
            {/* <button className="BrandBtn">Cancel</button> */}
            <div className="d-flex ">
              <button className="BrandBtnGreen mr-3" onClick={handlePrint}>Download</button>
              <button className="BrandBtnGreen ml-3" onClick={handlePrint}>Print</button>
            </div>
          </div>
          {/* <div className="DiscussionArea">
            <div className="DiscussionTitle">
              <h4>Discussion</h4>
              <div className="DiscussionChat">
                <img src="images/down.png" className="DiscussionIcon" />
                <a href="#">
                  <img src="images/pdf2.png" />
                </a>
              </div>
            </div>
            <div className="DiscussionHideArea" style={{ display: "block" }}>
              <div className="DiscussionBox">
                <span>FM</span>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Add a Comment ....."
                  />
                  <div className="DiscussionOverlay">
                    <a href="" className="CancelChat">
                      <img src="images/plus-cancel1.png" />
                    </a>
                    <a href="" className="SendChat">
                      <img src="images/forwardbutton.png" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="DiscussionBox">
                <span>TH</span>
                <div className="DiscussionRight">
                  <h6>Inspections are delayed</h6>
                  <small>04/19/2023, at 02:47 pm</small>
                </div>
              </div>
              <div className="ReplyArea">
                <div className="DiscussionBox">
                  <span>TH</span>
                  <div className="DiscussionRight">
                    <h6>Inspections are delayed</h6>
                    <small>04/19/2023, at 02:47 pm</small>
                  </div>
                </div>
                <div className="DiscussionBox">
                  <span>TH</span>
                  <div className="DiscussionRight">
                    <h6>Inspections are delayed</h6>
                    <small>04/19/2023, at 02:47 pm</small>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Modal
        className="ModalBox fade SmallModal"
        show={AddNewSupportingDocsModal}
        onHide={handleAddNewSupportingDocsModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a
              className="CloseModal"
              onClick={handleAddNewSupportingDocsModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4> New Supporting Docs</h4>
              </div>
              <div className="JobTitleArea">
                <h3>Supporting Documents #2</h3>
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Origin</span> #185
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Item:</span> #143
                  </h6>
                </div>
              </div>
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input
                    type="file"
                    id="docsInput"
                    onChange={(e) => handleFileChange(e, "docsUpdate")}
                  />
                </div>
              </div>
              <div className="FilesAddedTitle">
                <h4>Files</h4>
              </div>
              {supportingDocFiless && (
                <div className="FilesAdded">
                  <label>{supportingDocFiless?.name}</label>
                  <span>
                    <img src="images/fileview.png" />
                  </span>
                  <span onClick={handleRemoveDocsFile}>
                    <img src="images/Cross.png" className="cross" />
                  </span>
                </div>
              )}

              <div className="Button mt-3">
                <button className="Create" onClick={handleSupportDocs}>
                  Add Documents
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
          className="ModalBox fade SmallModal"
          show={AddNotesModal}
          onHide={handleAddNotesClose}
        >
          <Modal.Body>
            <div className="Category">
              <a className="CloseModal" onClick={handleAddNotesClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Add New Note</h4>
                </div>
                <h6>Note #1</h6>
                <div className="JobTitleArea">
                  <div className="JobBox">
                    <h6>
                      <span>Job:</span> UUnit3-CHI-2B-315
                    </h6>
                  </div>
                  <div className="JobBox mb-3">
                    <h6>
                      <span>Origin:</span> #185
                    </h6>
                  </div>
                  <h6>Type</h6>

                  <div className="FolderForm">
                    <div className="form-group mb-4">
                      <label className="Radio">
                        Text
                        <input
                          type="radio"
                          value="Text"
                          checked={selectedOption === "Text"}
                          onChange={onValueChange}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="Radio">
                        File
                        <input
                          type="radio"
                          value="File"
                          checked={selectedOption === "File"}
                          onChange={onValueChange}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>

                    {textInput == true && (
                      <div className="form-group">
                        <h6>Message (Max 1000 chars limit)</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Write here ....."
                          name="message"
                          value={message}
                          onChange={handleInputChange}
                        />
                        <small className="form-text text-right">
                          {1000 - Number(message.length)} characters remaining
                        </small>
                      </div>
                    )}

                    {fileData == true && (
                      <>
                        <div className="form-group">
                          <h6>Select Files</h6>
                          <div className="Upload">
                            <span>-Drag and Drop Files or click to select</span>
                            <input
                              type="file"
                              id="fileInput"
                              onChange={(e) => handleFileChange(e, "support")}
                            />
                          </div>
                        </div>
                        {supportingDocFiles && (
                          <div className="FilesAdded">
                            <label>{supportingDocFiles?.name}</label>
                            <span>
                              <img src="images/fileview.png" alt="File view" />
                            </span>
                            <span onClick={handleRemoveClientFile}>
                              <img
                                src="images/Cross.png"
                                className="cross"
                                alt="Cross"
                              />
                            </span>
                          </div>
                        )}
                      </>
                    )}

                    <div className="Button d-flex justify-content-between mt-4">
                      <button className="Create" onClick={handleAddNotes}>
                        Update Note
                      </button>
                      <button className="Outline" onClick={handleAddNotesClose}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          className="ModalBox modal fade LargeModal"
          show={PurchaseOrderListModal}
          onHide={handlePurchaseOrderListModalClose}
        >
          <Modal.Body>
            <div className="Category">
              <a className="CloseModal" onClick={handlePurchaseOrderListModalClose}>
                ×
              </a>
              <div className="FormArea">
  <div className="ModalTitleBox">
    <h4>Work Order's Purchase Order List</h4>
  </div>
  <div className="TableHeadingArea">
    <div className="FilesHead">
      <div className="FilesLeft">
        <h4>Purchase Orders</h4>
      </div>
      <div className="FilesRight">
        <a href="javascript:void(0);">
          <img src="images/pdf.png" />
        </a>
        <a href="javascript:void(0);">
          <img src="images/download.png" />
        </a>
        <a href="javascript:void(0);">
          <img src="images/setting.png" />
        </a>
      </div>
    </div>
  </div>
  <div className="TableList CommonHeading">
    <table>
      <thead>
        <tr>
          <th />
          <th>#</th>
          <th>Vendor</th>
          <th>Pre-Tax Total</th>
          <th>Data Create</th>
          <th>Amount Description</th>
          <th>Accepted</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <label className="CheckBox">
              <input type="checkbox" />
              <span className="checkmark" />
            </label>
          </td>
          <td>8-0304-F1</td>
          <td>8-0304-F1</td>
          <td>$2000.00</td>
          <td>04/20/20 23 3:27:45 PM</td>
          <td>Overhead Garage Door - Labor</td>
          <td>04/20/20 23 3:27:45 PM</td>
          <td>Accepted</td>
          <td>
            <div className="Actions text-center">
              <a href="javascript:void(0);">
                <img src="images/view-po.png" />
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

            </div>
          </Modal.Body>
        </Modal>
    </>
  );
};

export default WarrantyWorkOrderTradeViewDetails;
