import { adminConstructionConstant } from "../actionTypes";
const initialState = {
    constructionProjectProfileList: [],
    constructionCategoryList:[],
    constructionTaskList:[],
    constructionMasterTaskList:[],

    error: {},
  };
  
  export default function adminConstructionReducer(
    state = initialState,
    { type, payload }
    
  ) {
    switch (type) {

      case adminConstructionConstant.PROJECT_PROFILE_LIST_INITIATE:
        return { ...state, loader: true, constructionProjectProfileList: [] };
  
      case adminConstructionConstant.PROJECT_PROFILE_LIST_SUCCESS:
        return { ...state, loader: false, constructionProjectProfileList: payload?.details };
  
      case adminConstructionConstant.PROJECT_PROFILE_LIST_FAILURE:
        return { ...state, loader: false, error: payload };


        case adminConstructionConstant.SEARCH_PROJECT_PROFILE_INITIATE:
            return { ...state, loader: true, constructionProjectProfileList: [] };
      
          case adminConstructionConstant.SEARCH_PROJECT_PROFILE_SUCCESS:
            return { ...state, loader: false, constructionProjectProfileList: payload?.details };
      
          case adminConstructionConstant.SEARCH_PROJECT_PROFILE_FAILURE:
            return { ...state, loader: false, error: payload };
    
            case adminConstructionConstant.CONSTRUCTION_CATEGORY_LIST_INITIATE:
              return { ...state, loader: true, constructionCategoryList: [] };
        
            case adminConstructionConstant.CONSTRUCTION_CATEGORY_LIST_SUCCESS:
              return { ...state, loader: false, constructionCategoryList: payload?.details };
        
            case adminConstructionConstant.CONSTRUCTION_CATEGORY_LIST_FAILURE:
              return { ...state, loader: false, error: payload };
      
              case adminConstructionConstant.SEARCH_CONSTRUCTION_CATEGORY_INITIATE:
                return { ...state, loader: true, constructionCategoryList: [] };
          
              case adminConstructionConstant.SEARCH_CONSTRUCTION_CATEGORY_SUCCESS:
                return { ...state, loader: false, constructionCategoryList: payload?.details };
          
              case adminConstructionConstant.SEARCH_CONSTRUCTION_CATEGORY_FAILURE:
                return { ...state, loader: false, error: payload };


                case adminConstructionConstant.GET_MASTER_CONSTRUCTION_TASK_INITIATE:
                  return { ...state, loader: true, constructionMasterTaskList: [] };
            
                case adminConstructionConstant.GET_MASTER_CONSTRUCTION_TASK_SUCCESS:
                  return { ...state, loader: false, GET_MAS: payload.details };
            
                case adminConstructionConstant.GET_MASTER_CONSTRUCTION_TASK_FAILURE:
                  return { ...state, loader: false, error: payload };



                  case adminConstructionConstant.GET_CONSTRUCTION_TASK_INITIATE:
                    return { ...state, loader: true, constructionTaskList: [] };
              
                  case adminConstructionConstant.GET_CONSTRUCTION_TASK_SUCCESS:
                    return { ...state, loader: false, constructionTaskList: payload?.details };
              
                  case adminConstructionConstant.GET_CONSTRUCTION_TASK_FAILURE:
                    return { ...state, loader: false, error: payload };
  
  
        
    
      default:
        return state;
    }
  }
  