
import Modal from "react-bootstrap/Modal";

const SelectVendor = ({
  SelectVendorModal,
  SetSelectVendorModal,
  SetSendClientInfoModal,
}) => {
  const handleSelectVendorModalClose = () => {
    SetSelectVendorModal(false);
    SetSendClientInfoModal(true);
  };
  return (
    <Modal
      show={SelectVendorModal}
      className="ModalBox modal fade SmallModal show"
      style={{ display: "block" }}
    >
      <a
        class="CloseModal"
        data-toggle="modal"
        onClick={handleSelectVendorModalClose}
      >
        ×
      </a>
      <div class="FormArea">
              <div class="ModalTitleBox">
                <h4>Select Vendor</h4>
              </div>
              <div class="FolderForm">
                <form>
                  <div class="form-group">
                    <h6>Vendor Type</h6>
                    <div class="Categories Categories1">
                      <div class="Categories_all Categories_all1">
                        <span>Select a Vendor Type</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input type="text" placeholder="-Search" />
                          <span class="Search"><img src="images/search.png" /></span>
                        </li>
                        <li>- Full list of vendor types loaded from the system</li>
            
                      </ul>
                    </div>
                  </div>
                  <div class="form-group">
                    <h6>Vendor Name</h6>
                    <div class="Categories Categories2">
                      <div class="Categories_all Categories_all2">
                        <span>Select a Vendor Name</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input type="text" placeholder="-Search" />
                          <span class="Search"><img src="images/search.png" /></span>
                        </li>
                        <li>- Full list of names loaded from the system</li>
            
                      </ul>
                    </div>
                  </div>
                  <div class="Button mt-3 d-flex justify-content-between">
                    <button class="Create" onClick={handleSelectVendorModalClose}>Confirm Selection</button>
                    <button class="Outline" onClick={handleSelectVendorModalClose}>Cancel</button>
                  </div>
                </form>
              </div>
            </div>
    </Modal>
  );
};

export default SelectVendor;
