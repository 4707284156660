import { fileManagerAction } from "../actionTypes";
import { Url } from "../../Config/Config";
import axios from "axios";
import { isLoggedIn } from "../../utils/tokenCheck";
import { multiPartData, multiPartDatas } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

//---------------------------------createFolder----------------------------------------------------
export const createFolderInitiate = () => ({
    type: fileManagerAction.CREATE_FOLDER_INITIATE,
  });
  
  export const createFolderSuccess = (data) => ({
    type: fileManagerAction.CREATE_FOLDER_SUCCESS,
    payload: data,
  });
  
  export const createFolderFailure = (data) => ({
    type: fileManagerAction.CREATE_FOLDER_FAILURE,
    payload: data,
  });
  
  export const getCreateFolder = (payload) => async (dispatch) => {
      try {


        const token = isLoggedIn("adminData1");
        dispatch(createFolderInitiate(payload));
        const response = await axios.post(`${Url}ibuild-admin/folders?foldertableId=${iBuildLocalData?.user?.id}&foldertableType=Ibuild Admins`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = response;
        if (data && data.status == 201) {
          dispatch(createFolderSuccess(response.data));
        } else {
          dispatch(createFolderFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(createFolderFailure(err));
        return err.response
      }
    };


//==========================ALLolderList===========================================

export const getAllFolderListInitiate = () => ({
  type: fileManagerAction.ALL_FOLDER_LIST_INITIATE,
});

export const getAllFolderListSuccess = (data) => ({
  type: fileManagerAction.ALL_FOLDER_LIST_SUCCESS,
  payload: data,
});

export const getAllFolderListFailure = (data) => ({
  type: fileManagerAction.ALL_FOLDER_LIST_FAILURE,
  payload: data,
});

export const getAllFolderList = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getAllFolderListInitiate(payload));
      const response = await axios.get(`${Url}ibuild-admin/folders?foldertableId=${iBuildLocalData?.user?.id}&foldertableType=Ibuild Admins`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = response;
      if (data && data.status == 200) {
        dispatch(getAllFolderListSuccess(data.data.FolderDetails));
      } else {
        dispatch(getAllFolderListFailure(data.data.FolderDetails));
      }
      return response;
    } catch (err) {
      dispatch(getAllFolderListFailure(err));
      return err.response
    }
  };
//==================================searchFolderByname======================================

export const searchFolderByNameInitiate = () => ({
  type: fileManagerAction.SEARCH_FOLDER_BYNAME_INITIATE,
});

export const searchFolderByNameSuccess = (data) => ({
  type: fileManagerAction.SEARCH_FOLDER_BYNAME_SUCCESS,
  payload: data,
});

export const searchFolderByNameFailure = (data) => ({
  type: fileManagerAction.SEARCH_FOLDER_BYNAME_FAILURE,
  payload: data,
});

export const getFolderByName = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(searchFolderByNameInitiate(payload));
      const response = await axios.get(`${Url}ibuild-admin/folders-search?name=${payload?.name}&foldertableId=${iBuildLocalData?.user?.id}&foldertableType=Ibuild Admins`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = response;
      if (data && data.status == 200) {
        dispatch(searchFolderByNameSuccess(data.data.FolderDetails));
      } else {
        dispatch(searchFolderByNameFailure(data.data.FolderDetails));
      }
      return response;
    } catch (err) {
      dispatch(searchFolderByNameFailure(err));
      return err.response
    }
  };
//==========================================manageSignatureUpload================================


export const manageSignatureUploadInitiate = () => ({
  type: fileManagerAction.MANAGE_SIGNATURE_UPLOAD_INITIATE,
});

export const manageSignatureUploadSuccess = (data) => ({
  type: fileManagerAction.MANAGE_SIGNATURE_UPLOAD_SUCCESS,
  payload: data,
});

export const manageSignatureUploadFailure = (data) => ({
  type: fileManagerAction.MANAGE_SIGNATURE_UPLOAD_FAILURE,
  payload: data,
});

export const getManageSignatureUpload = (payload) => async (dispatch) => {
    try {
      const updatedData = multiPartDatas(payload)
      const token = isLoggedIn("adminData1");
      dispatch(manageSignatureUploadInitiate(payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/2/image-uploads`,updatedData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      const data = response;
     
      if (data && data.status == 200 || 201) {
        dispatch(manageSignatureUploadSuccess(data.data));
      } else {
        dispatch(manageSignatureUploadFailure(data.data));
      }
      return response;
    } catch (err) {
      dispatch(manageSignatureUploadFailure(err));
      return err.response
    }
  };
//=============================clientDocumentsList============================

export const getListDocumentationInitiate = () => ({
  type: fileManagerAction.LIST_DOCUMENTATION_INITIATE,
});

export const getListDocumentationSuccess = (data) => ({
  type: fileManagerAction.LIST_DOCUMENTATION_SUCCESS,
  payload: data,
});

export const getListDocumentationFailure = (data) => ({
  type: fileManagerAction.LIST_DOCUMENTATION_FAILURE,
  payload: data,
});

export const getListDocumentation = (id,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getListDocumentationInitiate(payload));
      
      const response = await axios.get(`${Url}ibuild-admin/folders/${id}/client-docs`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = response;
      if (data && data.status == 200) {
        dispatch(getListDocumentationSuccess(data.data.clientDocDetails));
      } else {
        dispatch(getListDocumentationFailure(data.data.clientDocDetails));
      }
      return response;
    } catch (err) {
      dispatch(getListDocumentationFailure(err));
      return err.response
    }
  };

//=====================searchListDocumentation=========================================

export const searchListDocumentationInitiate = () => ({
  type: fileManagerAction.SEARCH_LIST_DOCUMENTATION_INITIATE,
});

export const searchListDocumentationSuccess = (data) => ({
  type: fileManagerAction.SEARCH_LIST_DOCUMENTATION_SUCCESS,
  payload: data,
});

export const searchListDocumentationFailure = (data) => ({
  type: fileManagerAction.SEARCH_LIST_DOCUMENTATION_FAILURE,
  payload: data,
});

export const getSearchListDocumentation = (id,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(searchListDocumentationInitiate(payload));
      
      const response = await axios.get(`${Url}ibuild-admin/folders/10/client-docs-search?name=${payload.name}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = response;
      if (data && data.status == 200) {
        dispatch(searchListDocumentationSuccess(data.data.clientDocDetails));
      } else {
        dispatch(searchListDocumentationFailure(data.data.clientDocDetails));
      }
      return response;
    } catch (err) {
      dispatch(searchListDocumentationFailure(err));
      return err.response
    }
  };

//==============================createDocumentation===============================================


export const createDocumentationInitiate = () => ({
  type: fileManagerAction.CREATE_DOCUMENTATION_INITIATE,
});

export const createDocumentationSuccess = (data) => ({
  type: fileManagerAction.CREATE_DOCUMENTATION_SUCCESS,
  payload: data,
});

export const createDocumentationFailure = (data) => ({
  type: fileManagerAction.CREATE_DOCUMENTATION_FAILURE,
  payload: data,
});

export const getCreateDocumentation = (id,payload) => async (dispatch) => {
    try {
      const updatedData = multiPartData({ ...payload });
      const token = isLoggedIn("adminData1");
      dispatch(createDocumentationInitiate(payload));
      
      const response = await axios.post(`${Url}ibuild-admin/folders/${id}/client-docs`,updatedData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(createDocumentationSuccess(response.data));
      } else {
        dispatch(createDocumentationFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createDocumentationFailure(err));
      return err.response
    }
  };
//===============================removeDocumentation===============================

export const removeDocumentationInitiate = () => ({
  type: fileManagerAction.REMOVE_DOCUMENTATION_INITIATE,
});

export const removeDocumentationSuccess = (data) => ({
  type: fileManagerAction.REMOVE_DOCUMENTATION_SUCCESS,
  payload: data,
});

export const removeDocumentationFailure = (data) => ({
  type: fileManagerAction.REMOVE_DOCUMENTATION_FAILURE,
  payload: data,
});

export const getRemoveDocumentation = (id,docId) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    const { docId: extractedDocId } = docId;
    
    dispatch(removeDocumentationInitiate(extractedDocId));
  
    const response = await axios.delete(`${Url}ibuild-admin/folders/${id}/client-docs/${extractedDocId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (response.status === 200) {
      dispatch(removeDocumentationSuccess(response.data));
    } else {
      dispatch(removeDocumentationFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(removeDocumentationFailure(err));
    return err.response;
  }
};

//===============================shareDocumentation===============================

export const shareDocumentationInitiate = () => ({
  type: fileManagerAction.SHARE_CLIENET_DOCUMENTATION_INITIATE,
});

export const shareDocumentationSuccess = (data) => ({
  type: fileManagerAction.SHARE_CLIENET_DOCUMENTATION_SUCCESS,
  payload: data,
});

export const shareDocumentationFailure = (data) => ({
  type: fileManagerAction.SHARE_CLIENET_DOCUMENTATION_FAILURE,
  payload: data,
});

export const getShareDocumentationAdd = (folderId, id, payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(shareDocumentationInitiate(payload))
    const response = await axios.post(
      `${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/client-docs/${id}/share-doc`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log("response", response);

    if (response.status === 201 || response.status === 200) {
      dispatch(shareDocumentationSuccess(response.data));
    } else {
      dispatch(shareDocumentationFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(shareDocumentationFailure(err));
    return err.response;
  }
};

//===============================sendForApproval==============================

export const sendForApprovalInitiate = () => ({
  type: fileManagerAction.SEND_FOR_APPROVAL_INITIATE,
});

export const sendForApprovalSuccess = (data) => ({
  type: fileManagerAction.SEND_FOR_APPROVAL_SUCCESS,
  payload: data,
});

export const sendForApprovalFailure = (data) => ({
  type: fileManagerAction.SEND_FOR_APPROVAL_FAILURE,
  payload: data,
});

export const sendForApprovalDocument = (folderId, id, payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(sendForApprovalInitiate(payload))
    const response = await axios.post(
      `${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/client-docs/${id}/send-file-approval`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log("responseNewwwa", response);

    if (response.status === 200) {
      dispatch(sendForApprovalSuccess(response.data));
    } else {
      dispatch(sendForApprovalFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(sendForApprovalFailure(err));
    return err.response;
  }
};


//===============================uploadTemplatePic=============================

export const uploadTemplateMainPicInitiate = () => ({
  type: fileManagerAction.UPLOAD_TEMPLATE_MAIN_PICTURE_INITIATE,
});

export const uploadTemplateMainPicSuccess = (data) => ({
  type: fileManagerAction.UPLOAD_TEMPLATE_MAIN_PICTURE_SUCCESS,
  payload: data,
});

export const uploadTemplateMainPicFailure = (data) => ({
  type: fileManagerAction.UPLOAD_TEMPLATE_MAIN_PICTURE_FAILURE,
  payload: data,
});

export const getUploadTemplateMainPic = (folderId, id, payload) => async (dispatch) => {
  try {
    const updatedData = multiPartData({ ...payload });
    const token = isLoggedIn("adminData1");
    dispatch(uploadTemplateMainPicInitiate(payload))
    
    const response = await axios.put(
      `${Url}ibuild-admin/folders/${folderId}/client-docs/${id}/upload-image`,
      updatedData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(uploadTemplateMainPicSuccess(response.data));
    } else {
      dispatch(uploadTemplateMainPicFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(uploadTemplateMainPicFailure(err));
    return err.response;
  }
};

//===============================ViewDocumentation=============================

export const viewDocumentationInitiate = () => ({
  type: fileManagerAction.VIEW_DOCUMENTATION_INITIATE,
});

export const viewDocumentationSuccess = (data) => ({
  type: fileManagerAction.VIEW_DOCUMENTATION_SUCCESS,
  payload: data,
});

export const  viewDocumentationFailure = (data) => ({
  type: fileManagerAction.VIEW_DOCUMENTATION_FAILURE,
  payload: data,
});

export const getViewDocumentation = (folderId, id, payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(viewDocumentationSuccess(payload))

    const response = await axios.put(
      `${Url}ibuild-admin/folders/${folderId}/client-docs/${id}/client-view`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(viewDocumentationSuccess(response.data));
    } else {
      dispatch(viewDocumentationFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(viewDocumentationFailure(err));
    return err.response;
  }
};


//===============================documentationApproval=============================

export const documentationApprovalInitiate = () => ({
  type: fileManagerAction.DOCUMENTATION_APPROVAL_INITIATE,
});

export const documentationApprovalSuccess = (data) => ({
  type: fileManagerAction.DOCUMENTATION_APPROVAL_SUCCESS,
  payload: data,
});

export const  documentationApprovalFailure = (data) => ({
  type: fileManagerAction.DOCUMENTATION_APPROVAL_FAILURE,
  payload: data,
});

export const getDocumentationApproval = (folderId, id, payload) => async (dispatch) => {
  try {
    const updatedData = multiPartData({ ...payload });
    const token = isLoggedIn("adminData1");
    dispatch(documentationApprovalInitiate(payload))
    const response = await axios.put(
      `${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/client-docs/${id}/approval`,
      updatedData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(documentationApprovalSuccess(response.data));
    } else {
      dispatch(documentationApprovalFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(documentationApprovalFailure(err));
    return err.response;
  }
};

//===============================REMOVEfOLDER============================

export const deleteFolderInitiate = () => ({
  type: fileManagerAction.DELETE_FOLDER_INITIATE,
});

export const deleteFolderSuccess = (data) => ({
  type: fileManagerAction.DELETE_FOLDER_SUCCESS,
  payload: data,
});

export const  deleteFolderFailure = (data) => ({
  type: fileManagerAction.DELETE_FOLDER_FAILURE,
  payload: data,
});

export const getFolderRemove = (docId, payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(deleteFolderInitiate(docId));

    const response = await axios.delete(
      `${Url}ibuild-admin/folders/${docId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        data: payload, // Use "data" for the request body
      }
    );
    if (response.status === 200) {
      dispatch(deleteFolderSuccess(response.data));
    } else {
      dispatch(deleteFolderFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(deleteFolderFailure(err));
    return err.response;
  }
};


//=================================pendingFileEventList=======================================


export const pendingFileEventListInitiate = () => ({
  type: fileManagerAction.PENDING_FILE_EVENT_LIST_INITIATE,
});

export const pendingFileEventListSuccess = (data) => ({
  type: fileManagerAction.PENDING_FILE_EVENT_LIST_SUCCESS,
  payload: data,
});

export const  pendingFileEventListFailure = (data) => ({
  type: fileManagerAction.PENDING_FILE_EVENT_LIST_FAILURE,
  payload: data,
});

export const getpendingFileEventList = (folderId) => async (dispatch) => {
  console.log("folderIdenst",folderId)
  try {
    const token = isLoggedIn("adminData1");
    dispatch(pendingFileEventListInitiate(folderId))
    const response = await axios.get(
      `${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/pending-file-events`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(pendingFileEventListSuccess(response.data));
    } else {
      dispatch(pendingFileEventListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(pendingFileEventListFailure(err));
    return err.response;
  }
};

//=================================alltagList=======================================


export const getAllTagListInitiate = () => ({
  type: fileManagerAction.GET_ALL_TAGS_LIST_INITIATE,
});

export const getAllTagListSuccess = (data) => ({
  type: fileManagerAction.GET_ALL_TAGS_LIST_SUCCESS,
  payload: data,
});

export const  getAllTagListFailure = (data) => ({
  type: fileManagerAction.GET_ALL_TAGS_LIST_FAILURE,
  payload: data,
});

export const getAllTagList = (folderId) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(getAllTagListInitiate(folderId))
    const response = await axios.get(
      `${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/tags`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getAllTagListSuccess(response.data));
    } else {
      dispatch(getAllTagListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getAllTagListFailure(err));
    return err.response;
  }
};

//=================================editDocumentation=======================================


export const editDocumentationInitiate = () => ({
  type: fileManagerAction.EDIT_DOCUMENTATION_INITIATE,
});

export const editDocumentationSuccess = (data) => ({
  type: fileManagerAction.EDIT_DOCUMENTATION_SUCCESS,
  payload: data,
});

export const  editDocumentationFailure = (data) => ({
  type: fileManagerAction.EDIT_DOCUMENTATION_FAILURE,
  payload: data,
});

export const updateEditDocumentation = (folderId, id, payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(editDocumentationInitiate(folderId))
    const response = await axios.put(
      
      `${Url}ibuild-admin/folders/${folderId}/client-docs/${id}`,payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(editDocumentationSuccess(response.data));
    } else {
      dispatch(editDocumentationFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(editDocumentationFailure(err));
    return err.response;
  }
};


//=======================FILEHistoryList =============================
export const fileHistoryListInitiate = () => ({
  type: fileManagerAction.FILE_HISTORY_LIST_INITIATE,
});

export const fileHistoryListSuccess = (data) => ({
  type: fileManagerAction.FILE_HISTORY_LIST_SUCCESS,
  payload: data,
});

export const  fileHistoryListFailure = (data) => ({
  type: fileManagerAction.FILE_HISTORY_LIST_FAILURE,
  payload: data,
});

export const getFileHistoryList = ( folderID,docId) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(fileHistoryListInitiate( folderID,docId))
    const response = await axios.get(
      `${Url}ibuild-admin/folders/${folderID}/client-docs/${docId}/history`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(fileHistoryListSuccess(response.data));
    } else {
      dispatch(fileHistoryListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(fileHistoryListFailure(err));
    return err.response;
  }
};
//============================htmlconverter================================

export const htmlConverterInitiate = () => ({
  type: fileManagerAction.HTML_CONVERTER_INITIATE,
});

export const htmlConverterSuccess = (data) => ({
  type: fileManagerAction.HTML_CONVERTER_SUCCESS,
  payload: data,
});

export const  htmlConverterFailure = (data) => ({
  type: fileManagerAction.HTML_CONVERTER_FAILURE,
  payload: data,
});

export const getHtmlFile = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(htmlConverterInitiate(payload))
    const response = await axios.get(`${Url}convertToHtml?url=${payload.url}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(htmlConverterSuccess(response.data));
    } else {
      dispatch(htmlConverterFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(htmlConverterFailure(err));
    return err.response;
  }
};

//==================================get-all-document-key========================

export const getAllDocumentKeyListInitiate = () => ({
  type: fileManagerAction.GET_ALL_DOCUMENT_KEY_LIST_INITIATE,
});

export const getAllDocumentKeyListSuccess = (data) => ({
  type: fileManagerAction.GET_ALL_DOCUMENT_KEY_LIST_SUCCESS,
  payload: data,
});

export const  getAllDocumentKeyListFailure = (data) => ({
  type: fileManagerAction.GET_ALL_DOCUMENT_KEY_LIST_FAILURE,
  payload: data,
});

export const getAllDocumentKeyList = () => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(getAllDocumentKeyListInitiate())
    const response = await axios.get(`${Url}/documentkey`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getAllDocumentKeyListSuccess(response.data));
    } else {
      dispatch(getAllDocumentKeyListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getAllDocumentKeyListFailure(err));
    return err.response;
  }
};



export const lockActionInitiate = () => ({
  type: fileManagerAction.EDIT_DOCUMENTATION_INITIATE,
});

export const lockActionSuccess = (data) => ({
  type: fileManagerAction.EDIT_DOCUMENTATION_SUCCESS,
  payload: data,
});

export const  lockActionFailure = (data) => ({
  type: fileManagerAction.EDIT_DOCUMENTATION_FAILURE,
  payload: data,
});

export const lockAction = (folderId,payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(lockActionInitiate(folderId))
    const response = await axios.put(
      
      `${Url}ibuild-admin/folders/${folderId}/lock-unlock`,payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(lockActionSuccess(response.data));
    } else {
      dispatch(lockActionFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(lockActionFailure(err));
    return err.response;
  }
};
