import React from 'react'
import Modal from "react-bootstrap/Modal";

const ShareReportModal = ({shareReportModal, setShareReportModal}) => {

  const handleAddContactModalClose = () => setShareReportModal(false);
  const handleAddContactModalShow = () => {
    setShareReportModal(true);
  };

  return (
  <Modal show={shareReportModal} className="ModalBox LargeModal">
    <div class="modal-content">
      <a
        onClick={handleAddContactModalClose}
        class="CloseModal"
        data-dismiss="modal"
      >
        ×
      </a>{" "}
      <div class="FormArea">
              <div class="ModalTitleBox">
                <h4>Share Report</h4>
              </div>
              <div class="FolderForm">
                <form>
                  <div class="ModalBigheading">
                    <h3><span>Investor: </span>Investor 1</h3>
                  </div>
                  <div class="form-group">
                    <h6>Email</h6>
                    <input type="text" class="form-control" placeholder="Insert destination email" />
                  </div>
                  <div class="form-group">
                    <h6>CC</h6>
                    <input type="text" class="form-control" placeholder="Insert carbon copy email" />
                  </div>
                  <div class="form-group">
                    <h6>Subject</h6>
                    <input type="text" class="form-control" placeholder="Ibuild Report Sharing: Sales Report 1" />
                  </div>
                  <div class="form-group">
                    <h6>Message</h6>
                    <textarea cols="30" rows="10"></textarea>
                  </div>
                 
                      

                  <div class="Button d-flex justify-content-between  mt-5">
                    <button class="Outline">Cancel</button>
                    <button class="Create">Share Report</button>
                  </div>
                </form>
              </div>
            </div>
    </div>
  </Modal>  )
}

export default ShareReportModal