import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ChangeOrderCheckList from "./ChangeOrderChecklist";
import { useDispatch } from "react-redux";
import { getChangeOrderCheckListAction } from "../../../redux/action/constructionsAction";
import { useSelector } from "react-redux";

const initialState = {
  buildingPermit: "",
  subdividision: "",
  specificationDoc: "",
  taxProfile: "",
  baseModel: "",
  homeType: "",
  defaultCurrency: "",
  costScheme: "",
  timeZone: "",
  accountSoftware: false,
  underParkade: false,
  jobAddress: "",
  lot: "",
  block: "",
  phase: "",
  city: "",
  zipCode: "",
  country: "",
  province: "",
  numOfBlocks: 0,
  blocks: [],
  numOfBuildings: 0,
  buildings: [],
  errorMsg: {},
};

const ConsolidatedChangeOrderCheckList = ({
    ConsolidatedChangeOrderCheckListModal,
  SetConsolidatedChangeOrderCheckListModal,
}) => {


  const { changeOrderCheckList} =
    useSelector((state) => state.constructionReducer);

  const [ChangeOrderCheckListModal, SetChangeOrderCheckListModal] =useState(false);


  const handleConsolidatedChangeOrderCheckListModalClose = () => {
    SetConsolidatedChangeOrderCheckListModal(false);

  };

  const [iState, UpdateState] = useState(initialState);

  const dispatch = useDispatch()

  const {} = iState;

  //   const handleSubmit = (e) => {
  //     e.preventDefault();

  //     let formIsValid = handleValidation();

  //     if (formIsValid) {
  //       SetProjectAsEntityStep1Modal(false);
  //       SetProjectAsEntityStep2Modal(true);
  //     }
  //   };

 
  return (
    <>
      <Modal
        show={ConsolidatedChangeOrderCheckListModal}
        className="ModalBox LargeModal BgClass"
      >
        <a
          class="CloseModal ClosePermissionId"
          onClick={handleConsolidatedChangeOrderCheckListModalClose}
        >
          ×
        </a>
            <div class="FormArea">
              <div class="ModalTitleBox">
                <h4>Consolidated Change Order Checklists</h4>
              </div>
              <div class="TableHeadingArea">
                <div class="FilesHead">
                  <div class="FilesLeft">
                    <h4>Change Orders</h4>
                    <div class="Search">
                      <span data-toggle="modal" data-target="#NotesList2"><img src="images/search.png" /></span>
                      <input type="text" class="form-control" placeholder="Search" />
                    </div>
                  </div>
                  <div class="FilesRight">
                    <a href="#">
                      <img src="images/pdf.png" />
                    </a>
                    <a href="#">
                      <img src="images/download.png" />
                    </a>
                    <a href="#">
                      <img src="images/setting.png" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="TableList CommonHeading">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Order id</th>
                      <th>Description</th>
                      <th>Account Description</th>
                      <th>Date Created</th>
                      <th>Date Scheduled</th>
                      <th>Client</th>
                      <th>Approved</th>
                      <th>Date approved</th>
                      <th>Status</th>
                      <th>Date Completed</th>
                      <th>Completed by</th>
                      <th>Supporting Doc</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {changeOrderCheckList?.map((item)=>   <tr class="BorderGreen">
                      <td></td>
                      <td>8-0304-F1</td>
                      <td>Change Garage Door<br /> 
                        to Metal Option
                      </td>
                      <td>
                        Overhead Garage <br />
                        Door - Labor
                      </td>
                      <td>04/20/20 24 3:27:45 PM</td>
                      <td>04/25/20 24 10 :00:00 AM</td>
                      <td>Jane Jan</td>
                      <td>No</td>
                      <td></td>
                      <td>Pending</td>
                      <td></td>
                      <td></td>
                      <td><a href="#"><img src="images/notebook.png" /></a><span class="Count">3</span></td>
                      <td>
                        <div class="Actions">

                        <a><img src="images/view-po.png" onClick={()=>{SetChangeOrderCheckListModal(true)
                        SetConsolidatedChangeOrderCheckListModal(false)}} /></a>


                        
                          <a><img src="images/Action-1.png" /></a>
                        </div>
                      </td>
                    </tr>)}
                        <a><img src="images/view-po.png" onClick={()=>{SetChangeOrderCheckListModal(true)
                        SetConsolidatedChangeOrderCheckListModal(false)}} /></a>

                     
                  </tbody>
                </table>
              </div>
              <div class="PaginationArea">
                <h6>Showing 1 to 10 of 27 entries</h6>
                <h6><img src="images/leftarrow.png" /> 01 <img src="images/rightarrow.png" /> Out 10</h6>
              </div>
            </div>
      </Modal>

      <ChangeOrderCheckList
        ChangeOrderCheckListModal={ChangeOrderCheckListModal}
        SetChangeOrderCheckListModal={SetChangeOrderCheckListModal}
        SetConsolidatedChangeOrderCheckListModal={SetConsolidatedChangeOrderCheckListModal}
      />


 
    </>
  );
};

export default ConsolidatedChangeOrderCheckList;
