import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import SideNav from "./SideNav";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import {
  addBillAction,
  addEstimationDocumentAction,
  addEstimationSupportingDocsAction,
  addPurchaseAction,
  approveFPOs,
  approveSCs,
  budgetListAction,
  budgetchangeDetailsAction,
  cancelBackCharge,
  changeEstimationStatusAction,
  createBackChargeCredit,
  createBackChargeFieldPOOrder,
  createBackChargeNotification,
  createBackChargeSupplierCredits,
  deleteBackChargeCredit,
  estimationHistoryAction,
  getAllBillListAction,
  getAllBudgetListingAction,
  getAllEstimationDocsListingAction,
  getAllEventListAction,
  getAllFieldPurchaseDetailsAction,
  getAllFieldPurchaseOrderAction,
  getAllModalBudgetViewListAction,
  getAllPurchaseDetailsAction,
  getAllPurchaseListAction,
  getAllQuoteRequestAction,
  getAllScopeWorkIdListAction,
  getAllSupplierCreditDetailsAction,
  getAllSupplierCreditOrderAction,
  getApproveCreatePOAction,
  getBackChargeCreditList,
  getBackChargeRelatedDocumnets,
  getBillDetailsAction,
  getEstimationSettingCostCategory,
  getMessageList,
  getQRPreferredAction,
  getQuoteRequestCostBreakDownDetails,
  getRelatedDocumnets,
  getReleasedConstructionDocs,
  getUserLimitDetailsAction,
  getquoteDetailsAction,
  hddenCostCodesAPiList,
  hideCostAction,
  hideCostCodes,
  qouteRequestByVendors,
  quoteRequestModalOpen,
  removeBillAction,
  removeEstimationDocsListingAction,
  removeQRAction,
  requestLimitIncreaseAction,
  resendMessage,
  syncBackCharges,
  uploadAdditionalDocsAction,
  uploadBudgetChangeOrderDocsAction,
} from "../redux/action/estimationAction";
import NoDataFound from "./Nodatafound";
import Loader from "./Loader";
import { checkArray } from "../utils/CheckType";
import { errorToast, sucessToast } from "../utils/toast";
import {
  addBudgetChangeOrderAction,
  addFieldPurchaseOrderAction,
  addNewQuoteAction,
  addSupplierCreditAction,
  getAllBlockListAction,
  getAllBuilderSigneeAction,
  getAllEstimationUnitAction,
  getAllProfileListAction,
  getAllReasonListAction,
  getAllUnitListAction,
  updateBillAction,
  updateBudgetChangeOrderAction,
  updateFieldPurchaseOrderAction,
  updatePurchaseDetailsAction,
  updateQuoteRequestAction,
  updateSupplierCreditAction,
} from "../redux/action/estimationAction";

import { getAllListProjectJobs } from "../redux/action/modelsAction";
import {
  VendorSearchAction,
  VendorTypesAction,
  vendorDetailsAction,
} from "../redux/action/vendorAction";
import {
  calculateDateDifference,
  calculateDateDifferenceInDays,
  getDate,
} from "../utils/uploadFile";
import {
  getAllJobListAction,
  getAllProjectListAction,
} from "../redux/action/jobAction";
import { DownloadTableExcel } from "react-export-table-to-excel";
import {
  addSupportingDocsAction,
  getAllStaffListAction,
} from "../redux/action/saleAction";
import {
  commonCostCategoryListAction,
  commonCostCodeListAction,
  resetPasswordAction,
} from "../redux/action/authAction";
import {
  getListProjectJobs,
  getWarrantySettingDiscussion,
  updateWarrantySettingDiscussion,
} from "../redux/action/warrantyAction";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { getMultiBlocksList } from "../redux/action/homeAction";
import {
  AddDiscussionAction,
  AddReplyAction,
  GetAllJobsAction,
  discussionListAction,
} from "../redux/action/constructionsAction";
import GearSettingIconEstimation from "./GearSettingIconEstimation";
const initModal = {
  globalBiddingQuoteModal: false,
  BillModal: false,
  BudgetChangeOrderModal: false,
  FieldPurchaseOrderModal: false,
  SupplyCreditModal: false,
  BackChargeModal: false,
  PurchaseModal: false,
  SelectJobModal: false,
  SelectCostModal: false,
  modalState: "",
  NewQuoteRequestModal: false,
  BillModal: false,
  BudgetChangeOrderModal: false,
  PurchaseModal: false,
  SystemVendorModal: false,
  reminderModal: false,
  BillQuoteReqModal: false,
  givenIndex: "",
  BudgetChangeOrderModal: false,
  biddingDetailsModal: false,
  billDetailsModal: false,
  billListingModal: false,
  quoteDetailsModal: false,
  BackChargeListingModal: false,
  modalId: "",
  addBackChargeModal: false,
  SelectJobModal: false,
  SelectCostModal: false,
  QuoteRequestCostBreakDownModal: false,
  purchaseDetailsModal: false,
  uploadAdditionModal: false,
  purchaseViewCostBreakdownModal: false,
  fieldPurchaseOrderDetailsModal: false,
  supplierCreditOrderDetailsModal: false,
  budgetChangeOrderListingModal: false,
  scopeWorkIdModal: false,
  secondaryState: "",
  ViewQuoteRequestCostBreakDownModal: false,
  ViewSupplierCreditModal: false,
  requestLimitIncresaseModal: false,
  budgetChangeOrderDetailsModal: false,
  updateBudgetChangeOrderModal: false,
  VendorBackChargeNotificationModal: false,
  estimationHistoryModal: false,
  requiredName: "",
  changeStatusModal: false,
  updateBudgetChangeDocsModal: false,
  agreementDetailsModal: false,
  budgetSummaryModal: false,
  NARowsModal: false,
  SystemVendorModalBackCharge: false,
  addTaxProfileModal: false,
  BackChargeNotificationListModal: false,
  ViewMessageModal: false,
  VendorBackChargeNotificationReplyModal: false,
  NavigateModal: false,
};

const initLeadState = {
  showTaxFlag: false,
  vendorName: "",
  vendorType: "",
  systemErrors: {},
  vendorSearchData: [],
  jobCodeId: "",
  jobType: "",
  reasonId: "",
  vendorId: "",
  date: "",
  reminder: "",
  message: "",
  profile: "",
  subtotal: "",
  day: "",
  week: "",
  gst: "",
  grandTotal: "",
  scopeWorkId: "",
  constructionDocsId: "",
  externalQuoteId: "",
  calculatedGstAmount: "",
  newQuoteRequestError: {},
  selectJobError: {},
  selectCostError: {},
  itemErrors: "",
  BlockId: "",
  UnitId: "",
  jobType: "",
  billError: {},
  itemDescription: "",
  documentType: "",
  billNewQuoteError: "",
  billDataCategorySubtotal: "",
  estimationDocumentId: "",
  estimationCreateId: "",
  documentDescription: "",
  increaseAmount: "",
  InvesterEmail: "",
  InvesterfullName: "",
  budgetChangeError: {},
  authorisationType: "",
  supportingDocsError: "",
  files: "",
  builderSigneeId: "",
  relatedDocumentId: "",
  quoteDetailsData: [],
  billDetailsData: [],
  costCategoryId: "",
  costCodeId: "",
  ProjectId: "",
  jobTypeToSend: "",
  quoteDocumentCodeForModal: "",
  quoteDocumentCostCodeForModal: "",
  quoteDocumentJobCodeForModal: "",
  purchaseCodeForModal: "",
  purchaseCostCodeForModal: "",
  purchaseJobCodeForModal: "",
  hideShowId: -1,

  costCodeIds: "",
  originalDocumentId: "",
  costCategoryId: "",
  documentTypes: "",
  vendorIds: "",
  originalDocumentType: "",
  modalType: "",
  supportDocumentId: "",
  increaseReason: "",
  approverUserId: "",
  requestIncreaseAmount: "",
  messages: "",
  estimatedValue: "",
  budgetChangeOrderDetailsData: [],
  estimationHistoryData: [],
  status: "",
  file: "",
  costCodeStatic: "",
  costCodeStaticName: "",
  jobNumberStatic: "",

  vendorTypes: "",
  vendorNames: "",
  vendorIds: "",
  supportingDocFiles: [],
  backChargevendorId: "",
  backChargeDocumentId: "",
  taxName: "",
  taxPercentage: "",
  taxAmount: "",
  messageDetailData: [],
  messageDetailRemData: [],
  hiddeCostCodeData: [],
  backchargeId: "",
  saveUnhideCostId: [],
  reasonDataa: "",
};

const initialState = {
  DiscussionSettingsModal: false,
  discussionData: [],
  visibleInClientView: false,
  visibleInTradeView: false,
  visibleInInvestorView: false,
  itemDetails: [
    {
      parentTableName: "QUOTEREQUEST",
      color: "",
      quantity: null,
      unitId: null,
      price: "",
      total: null,
      name: "",
    },
  ],

  itemDetailss: [
    {
      parentTableName: "QUOTEREQUEST",
      color: "",
      quantity: null,
      unitId: null,
      price: "",
      total: null,
      name: "",
    },
  ],
  ViewAttachData: "",
  ViewAttachDataModal: false,
};
function EstimationModule() {
  const [iModalState, updateModalState] = useState(initModal);
  const [jobDropdownFlag, SetJobDropdownFlag] = useState(false);
  const [iLeadState, updateLeadState] = useState(initLeadState);
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const [iState, updateState] = useState(initialState);
  const selectedProject = JSON.parse(window.localStorage.getItem("tempData"));
  const [jobSearchState, setJobSearchState] = useState("");

  const { multiblocksList } = useSelector((state) => state.homeReducer);
  const {
    DiscussionSettingsModal,
    discussionData,
    visibleInClientView,
    visibleInTradeView,
    visibleInInvestorView,
    itemDetails,
    itemDetailss,
    ViewAttachData,
    ViewAttachDataModal,
  } = iState;

  const quoteRef = useRef();
  const purchaseRef = useRef();
  const billRef = useRef();
  const billListingRef = useRef();
  const billDetailsItemRef = useRef();
  const eventRef = useRef();
  const quoteListRefByCostCode = useRef();
  const quoteDetailsItemRef = useRef();
  const { state, pathname } = useLocation();

  const { listProjectJobs } = useSelector((state) => state.modelsReducer);
  const { discussionList, jobList } = useSelector(
    (state) => state.constructionReducer
  );

  const { staffListData } = useSelector((state) => state.salesReducer);
  const {
    globalBiddingQuoteModal,
    requiredName,
    agreementDetailsModal,
    updateBudgetChangeDocsModal,
    SelectJobModal,
    SelectCostModal,
    modalState,
    NewQuoteRequestModal,
    BillModal,

    BudgetChangeOrderModal,
    FieldPurchaseOrderModal,
    SupplyCreditModal,
    givenIndex,
    BackChargeModal,
    PurchaseModal,
    SystemVendorModal,
    reminderModal,
    BillQuoteReqModal,
    supportingDocsModal,
    biddingDetailsModal,
    billDetailsModal,
    billListingModal,
    modalId,
    quoteDetailsModal,
    BackChargeListingModal,
    addBackChargeModal,
    QuoteRequestCostBreakDownModal,
    purchaseDetailsModal,
    uploadAdditionModal,
    modalType,
    purchaseViewCostBreakdownModal,
    supplierCreditOrderDetailsModal,
    fieldPurchaseOrderDetailsModal,
    budgetChangeOrderListingModal,
    scopeWorkIdModal,
    secondaryState,
    ViewQuoteRequestCostBreakDownModal,
    ViewSupplierCreditModal,
    budgetChangeOrderDetailsModal,
    requestLimitIncresaseModal,
    updateBudgetChangeOrderModal,
    estimationHistoryModal,
    changeStatusModal,
    budgetSummaryModal,
    NARowsModal,
    VendorBackChargeNotificationModal,
    SystemVendorModalBackCharge,
    addTaxProfileModal,
    BackChargeNotificationListModal,
    ViewMessageModal,
    VendorBackChargeNotificationReplyModal,
    NavigateModal,
  } = iModalState;
  const dispatch = useDispatch();
  const {
    reasonListData,
    profileListData,
    estimationUnitListData,
    blockListData,
    unitListData,
    builderSigneeListData,
    quoteRequestListData,
    loader,
    billListData,
    eventListData,
    budgetListData,
    backChargeListData,
    loaderBackCharge,
    purchaseListData,
    fieldPurchaseOrderListData,
    supplierCreditListData,
    modalBudgetChangeListData,
    scopeWorkIdListData,
    estimationDocumentListData,
    quoteRequestCostBreakDownDetails,
    relatedDocumentsList,
    backChargeRelatedDocumentsList,
    messageList,
    estimationSettingData,
    getReleadesConstList,
  } = useSelector((state) => state.estimationReducer);
  const { jobListData } = useSelector((state) => state.jobReducer);

  const [discussionState, updateDiscussionState] = useState({
    jobId: "",
    message: "",
    discussionError: "",
  });
  const [replyState, updateReplyState] = useState({
    messageId: "",
    jobId: "",
    message: "",
    replyError: "",
  });
  const [replyField, updateReplyField] = useState({ flag: false, index: "" });

  const {
    quoteDetailsData,
    vendorName,
    file,
    showTaxFlag,
    vendorType,
    systemErrors,
    vendorSearchData,
    jobCodeId,
    BlockId,
    costCodeId,
    reasonId,
    vendorId,
    date,
    reminder,
    message,
    profile,
    subtotal,
    gst,
    grandTotal,
    scopeWorkId,
    constructionDocsId,
    externalQuoteId,
    newQuoteRequestError,
    day,
    week,
    selectCostError,
    selectJobError,
    calculatedGstAmount,
    itemErrors,
    UnitId,
    jobType,
    billError,
    itemDescription,
    documentType,
    billNewQuoteError,
    billDataCategorySubtotal,
    estimationDocumentId,
    estimationCreateId,
    documentDescription,
    increaseAmount,
    InvesterEmail,
    InvesterfullName,
    budgetChangeError,
    authorisationType,
    supportingDocsError,
    files,
    builderSigneeId,
    relatedDocumentId,
    billDetailsData,
    ProjectId,
    costCategoryId,
    quoteDocumentCodeForModal,
    purchaseCodeForModal,
    purchaseCostCodeForModal,
    purchaseJobCodeForModal,
    hideShowId,
    supportDocumentId,
    increaseReason,
    approverUserId,
    requestIncreaseAmount,
    messages,
    estimatedValue,
    budgetChangeOrderDetailsData,
    estimationHistoryData,
    status,
    costCodeStatic,
    costCodeStaticName,
    jobNumberStatic,
    originalDocumentId,
    originalDocumentType,
    vendorTypes,
    vendorNames,
    vendorIds,
    supportingDocFiles,
    backChargevendorId,
    backChargeDocumentId,
    taxName,
    taxPercentage,
    taxAmount,
    messageDetailData,
    messageDetailRemData,
    hiddeCostCodeData,
    backChargeId,
    saveUnhideCostId,
    reasonDataa,
  } = iLeadState;

  const [jobListState, updateJobListState] = useState([
    { id: state?.id, type: state?.jobType },
  ]);

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const { vendorTypesData, vendorListData, vendorDetailsData } = useSelector(
    (state) => state.constructionVendor
  );

  const handleJobListByProject = (Id) => {
    updateLeadState((prev) => ({
      ...prev,
      hideShowId: prev.hideShowId == Id ? -1 : Id,
    }));
  };

  const { commonCostCodeListData, commonCostCategoryListData } = useSelector(
    (state) => state.authReducer
  );
  const { allProjectList } = useSelector(
    (state) => state.vendorViewConfigReducer
  );

  useEffect(() => {
    dispatch(getAllProjectListAction(iBuildLocalData?.user?.CompanyId));
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === "vendorId") {
      const selectedVendor = vendorSearchData?.find(
        (vendor) => vendor.id == value
      );
      updateLeadState({
        ...iLeadState,
        [name]: value,
        vendorName: selectedVendor?.vendorName || "",
      });
      dispatch(getRelatedDocumnets(value));
    } else if (name === "vendorIds") {
      const selectedVendor = vendorSearchData?.find(
        (vendor) => vendor.id == value
      );
      updateLeadState({
        ...iLeadState,
        [name]: value,
        vendorNames: selectedVendor?.vendorName || "",
      });
      dispatch(getRelatedDocumnets(value));
    } else if (name == "profile") {
      const defaultProfile = profileListData.find((ele) => ele.id == value);
      if (defaultProfile) {
        setTaxNames(defaultProfile.taxRows?.[0]?.taxName || "");
        setTaxPercentage(defaultProfile.taxRows?.[0]?.taxPercent || 0);

        updateLeadState({
          ...iLeadState,
          [name]: value,
        });
      }
    } else if (name === "originalDocumentId") {
      const selectedrelatedDocument = relatedDocumentsList?.data?.find(
        (vendor) => vendor.id == value
      );
      updateLeadState({
        ...iLeadState,
        [name]: value,
        originalDocumentType: selectedrelatedDocument?.documentType || "",
      });
    } else {
      updateLeadState({ ...iLeadState, [name]: value });
    }
  };

  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    updateLeadState({ ...iLeadState, [name]: result });
  };

  const handleCheckbox = (e) => {
    const { name, value, checked } = e.target;
    if (checked == true) {
      updateLeadState({ ...iLeadState, authorisationType: value });
    } else {
      updateLeadState({ ...iLeadState, authorisationType: value });
    }
  };

  const handleInputFile = (e) => {
    const { name, value } = e.target;
    const file = e.target.files[0];
    if (file) {
      updateLeadState({
        ...iLeadState,
        [name]: file,
      });
    }
    e.target.files = null;
  };

  const showBiddingQuoteModal = () => {
    dispatch(getAllQuoteRequestAction(ProjectId, "", "", "", true, true, true));
    updateModalState({ ...iModalState, globalBiddingQuoteModal: true });
  };

  const hideBiddingQuoteModal = () => {
    updateModalState({ ...iModalState, globalBiddingQuoteModal: false });
  };

  // const handleQuoteModalOpen = () => {
  //     dispatch(quoteRequestModalOpen(jobIdStatic));
  //     // hideBiddingQuoteModal()
  //   };

  const showFieldPurchaseOrder = (
    state,
    costCodeIdList,
    jobIdList,
    jobTypeList,
    costCodeStatic1,
    costCodeStaticName1,
    backChargeId
  ) => {
    // if (jobCodeId) {
    dispatch(VendorTypesAction());
    dispatch(getAllReasonListAction());
    dispatch(getAllProfileListAction());
    dispatch(getAllEstimationUnitAction());
    dispatch(
      addFieldPurchaseOrderAction({
        jobCodeId: jobIdList ?? jobCodeId,
        jobType: jobTypeList ?? jobType,
        costCodeId: costCodeIdList ?? costCodeId,
      })
    )
      .then((res) => {
        updateLeadState({
          ...iLeadState,
          jobCodeId: jobIdList ?? jobCodeId,
          jobType: jobTypeList ?? jobType,
          costCodeId: costCodeIdList ?? costCodeId,
          // quoteDocumentCodeForModal:res?.documentCode,
          // quoteDocumentCodeForModal:res?.documentCode,
          estimationDocumentId: res?.data?.documentCode,
          estimationCreateId: res?.data?.id,
          costCodeStatic: costCodeStatic1 ?? costCodeStatic,
          costCodeStaticName: costCodeStaticName1 ?? costCodeStaticName,
        });
      })
      .catch((err) => {
        errorToast(err?.response?.data?.error);
      });
    if (state === "supplierCredit") {
      updateModalState({
        ...iModalState,
        SupplyCreditModal: false,
        FieldPurchaseOrderModal: true,
        modalState: state,
      });
    } else if (state === "filed") {
      updateModalState({
        ...iModalState,
        FieldPurchaseOrderModal: true,
        addBackChargeModal: false,
        modalState: state,
        costCodeId: backChargeId,
      });
    } else {
      updateModalState({
        ...iModalState,
        FieldPurchaseOrderModal: true,
        modalState: "",
      });

      // // setShow(false);
    }
    // } else {
    //   errorToast("Please select the job first");
    // }
  };
  const hideFieldPurchaseOrder = () => {
    if (jobCodeId) {
      if (modalState === "supplierCredit") {
        updateModalState({
          ...iModalState,
          SupplyCreditModal: true,
          FieldPurchaseOrderModal: false,
          modalState: "",
        });
      } else {
        updateModalState({ ...iModalState, FieldPurchaseOrderModal: false });
        // // setShow(true);
        setItems([
          {
            quantity: "",
            color: "",
            unitId: "",
            price: "",
            total: "",
            name: "",
            parentTableName: "FIELDPURCHASEORDER",
            parentTableId: 2,
          },
        ]);
        updateLeadState({
          ...iLeadState,
          // jobCodeId: "",
          // jobType: "",
          // costCodeId: "",
          reasonId: "",
          vendorId: "",
          date: "",
          reminder: "",
          message: "",
          profile: "",
          subtotal: "",
          gst: "",
          grandTotal: "",
          scopeWorkId: "",
          constructionDocsId: "",
          externalQuoteId: "",
          vendorType: "",
          type: "",
          newQuoteRequestError: {},
          day: "",
          week: "",
        });
      }
    } else if (state === "filed") {
      updateModalState({
        ...iModalState,
        FieldPurchaseOrderModal: false,
        BackChargeListingModal: true,
        modalState: "",
        costCodeId: "",
      });
    } else {
      errorToast("Please select the job first");
    }
  };

  const showSupplierCreditModal = (
    state,
    costCodeIdList,
    jobIdList,
    jobTypeList,
    costCodeStatic1,
    costCodeStaticName1,
    backChargeId
  ) => {
    dispatch(VendorTypesAction());
    dispatch(getAllReasonListAction());
    dispatch(getAllProfileListAction());
    dispatch(getAllEstimationUnitAction());
    dispatch(
      addSupplierCreditAction({
        jobCodeId: jobIdList ?? jobCodeId,
        jobType: jobTypeList ?? jobType,
        costCodeId: costCodeIdList ?? costCodeId,
      })
    )
      .then((res) => {
        updateLeadState({
          ...iLeadState,
          jobCodeId: jobIdList ?? jobCodeId,
          jobType: jobTypeList ?? jobType,
          costCodeId: costCodeIdList ?? costCodeId,
          estimationDocumentId: res?.data?.documentCode,
          estimationCreateId: res?.data?.id,
          costCodeStatic: costCodeStatic1 ?? costCodeStatic,
          costCodeStaticName: costCodeStaticName1 ?? costCodeStaticName,
        });
      })
      .catch((err) => {
        errorToast(err?.response?.data?.error);
      });
    if (state === "fieldPurchaseOrder") {
      updateModalState({
        ...iModalState,
        FieldPurchaseOrderModal: false,
        SupplyCreditModal: true,
        modalState: state,
      });
    } else if (state === "supplier") {
      updateModalState({
        ...iModalState,
        addBackChargeModal: false,
        SupplyCreditModal: true,
        modalState: state,
        costCodeId: backChargeId,
      });
    } else if (state === "bidding") {
      updateModalState({
        ...iModalState,
        biddingDetailsModal: false,
        SupplyCreditModal: true,
        modalState: state,
        costCodeId: backChargeId,
      });
    } else {
      updateModalState({ ...iModalState, SupplyCreditModal: true });
      // // setShow(false);
    }
    // } else {
    //   errorToast("Please select the job first");
    // }
  };
  const hideSupplierCreditModal = () => {
    if (jobCodeId) {
      if (modalState === "fieldPurchaseOrder") {
        updateModalState({
          ...iModalState,
          FieldPurchaseOrderModal: true,
          SupplyCreditModal: false,
          modalState,
        });
      } else {
        updateModalState({ ...iModalState, SupplyCreditModal: false });
        // // setShow(true);
        setItems([
          {
            quantity: "",
            color: "",
            unitId: "",
            price: "",
            total: "",
            name: "",
            parentTableName: "FIELDPURCHASEORDER",
            parentTableId: 2,
          },
        ]);
        updateLeadState({
          ...iLeadState,
          // jobCodeId: "",
          // jobType: "",
          // costCodeId: "",
          reasonId: "",
          vendorId: "",
          date: "",
          reminder: "",
          message: "",
          profile: "",
          subtotal: "",
          gst: "",
          grandTotal: "",
          scopeWorkId: "",
          constructionDocsId: "",
          externalQuoteId: "",
          vendorType: "",
          type: "",
          newQuoteRequestError: {},
          day: "",
          week: "",
        });
      }
    } else if (state === "supplier") {
      updateModalState({
        ...iModalState,
        BackChargeListingModal: true,
        SupplyCreditModal: false,
        modalState: "",
        costCodeId: "",
      });
    } else if (state === "bidding") {
      updateModalState({
        ...iModalState,
        biddingDetailsModal: true,
        SupplyCreditModal: false,
        modalState: "",
        costCodeId: "",
      });
    } else {
      errorToast("Please select the job first");
    }
  };

  const [costCodeNumber, setCostCodeNumber] = useState("");
  const [costCodeName, setCostCodeName] = useState("");
  const showNewQuoteRequestModal = (
    state,
    costCodeIdList,
    jobIdList,
    jobTypeList,
    backChargeId,
    costCodeStatic1,
    costCodeStaticName1
  ) => {
    // if (jobCodeId) {
    // "", state?.id, state?.jobType, costCodeId

    dispatch(VendorTypesAction());
    if (state === "ListingQuoteRequest") {
      dispatch(getAllReasonListAction({ isBackCharge: false }));
    } else {
      dispatch(getAllReasonListAction({ isBackCharge: true }));
    }

    dispatch(getAllProfileListAction());
    dispatch(getAllEstimationUnitAction());

    dispatch(
      addNewQuoteAction({
        costCodeId: costCodeIdList || costCodeId,
        jobCodeId: jobIdList || jobCodeId,
        jobType: jobTypeList || jobType,
        backChargeId: backChargeId || 0,
        vendorId: vendorId || 0,
      })
    )
      .then((res) => {
        console.log("ness", res);
        const dummyCostCodeNumber = res?.data?.data?.CostCode?.number;
        const dummyCostCodeName = res?.data?.data?.CostCode?.name;
        setCostCodeNumber(dummyCostCodeNumber);
        setCostCodeName(dummyCostCodeName);
        updateLeadState({
          ...iLeadState,
          quoteDocumentCodeForModal: res?.data?.data?.documentCode,
          jobCodeId: jobIdList ?? jobCodeId,
          jobType: jobTypeList ?? jobType,
          costCodeId: costCodeIdList ?? costCodeId,
          costCodeStatic: costCodeStatic1 ?? costCodeStatic,
          costCodeStaticName: costCodeStaticName1 ?? costCodeStaticName,
          estimationCreateId: res?.data?.data?.id,
          reasonId: res?.data?.data?.reasonId,
        });
      })

      .catch((err) => {
        errorToast(err?.response?.data?.error);
      });

    if (state === "purchaseModal") {
      updateModalState({
        ...iModalState,
        PurchaseModal: false,
        NewQuoteRequestModal: true,
        modalState: state,
      });
    } else if (state === "biddingDetail") {
      updateModalState({
        ...iModalState,
        biddingDetailsModal: false,
        NewQuoteRequestModal: true,
        modalState: state,
      });
    } else if (state === "globalBiddingDetails") {
      updateModalState({
        ...iModalState,
        NewQuoteRequestModal: true,
        globalBiddingQuoteModal: false,
        modalState: state,
      });
      // updateModalState({ ...iModalState, NewQuoteRequestModal: true });
      // // setShow(false);
    } else if (state === "qr") {
      updateModalState({
        ...iModalState,
        NewQuoteRequestModal: true,
        addBackChargeModal: false,
        modalState: state,
        costCodeId: costCodeId ?? backChargeId,
      });
    } else if (state === "ListingQuoteRequest") {
      updateModalState({
        ...iModalState,
        NewQuoteRequestModal: true,
        modalState: state,
      });
    } else {
      updateModalState({
        ...iModalState,
        NewQuoteRequestModal: true,
        modalState: state,
      });
      // setCostCodes(`${ele.number ?? ""} ${ele.name ?? ""}`);
    }
    // } else {
    //   errorToast("Please select the job first");
    // }
  };

  const hideNewQuoteRequestModal = () => {
    setScopeData([]);
    setConstructData([]);
    if (jobCodeId) {
      if (modalState === "purchaseModal") {
        updateModalState({
          ...iModalState,
          PurchaseModal: true,
          NewQuoteRequestModal: false,
          modalState: "",
        });
      } else if (modalState === "biddingDetail") {
        updateModalState({
          ...iModalState,
          biddingDetailsModal: true,
          NewQuoteRequestModal: false,
          modalState: "",
        });
      } else if (modalState === "globalBiddingDetails") {
        updateModalState({
          ...iModalState,
          NewQuoteRequestModal: false,
          globalBiddingQuoteModal: true,
        });
        setItems([
          {
            quantity: "",
            color: "",
            unitId: "",
            price: "",
            total: "",
            name: "",
            parentTableName: "FIELDPURCHASEORDER",
            parentTableId: 2,
          },
        ]);
        updateLeadState({
          ...iLeadState,
          reasonId: "",
          vendorId: "",
          date: "",
          reminder: "",
          message: "",
          profile: "",
          subtotal: "",
          gst: "",
          grandTotal: "",
          scopeWorkId: "",
          constructionDocsId: "",
          externalQuoteId: "",
          vendorType: "",
          type: "",
          newQuoteRequestError: {},
          day: "",
          week: "",
        });
      } else if (state === "qr") {
        updateModalState({
          ...iModalState,
          NewQuoteRequestModal: false,
          BackChargeListingModal: true,
          modalState: "",
          costCodeId: "",
        });
      } else {
        updateModalState({
          ...iModalState,
          NewQuoteRequestModal: false,

          modalState: "",
        });
      }
    } else {
      errorToast("Please select the job first");
    }
  };

  const showBillModal = (
    stateName,
    costCodeIdList,
    jobIdList,
    jobTypeList,
    costCodeStatic,
    costCodeStaticName
  ) => {
    dispatch(VendorTypesAction());
    dispatch(getAllReasonListAction());
    dispatch(getAllProfileListAction());
    dispatch(getAllJobListAction(state?.id ?? state?.ProjectId ?? ProjectId));
    dispatch(getAllEstimationUnitAction());
    dispatch(
      addBillAction({
        jobCodeId: jobIdList ?? jobCodeId,
        jobType: jobTypeList ?? jobType,
        costCodeId: costCodeIdList ?? costCodeId,
      })
    )
      .then((res) => {
        updateLeadState({
          ...iLeadState,
          estimationDocumentId: res?.data?.data?.id,
          estimationCreateId: res?.data?.data?.id,
          jobCodeId: jobIdList ?? jobCodeId,
          jobType: jobTypeList ?? jobType,
          costCodeId: costCodeIdList ?? costCodeId,
          costCodeStatic,
          costCodeStaticName,
        });
      })
      .catch((err) => {
        errorToast(err?.response?.data?.error);
        // console.log("errrrrerererer ,err", err);
      });
    // if (jobCodeId) {
    if (stateName === "costModal") {
      updateModalState({
        ...iModalState,
        BillModal: true,
        SelectCostModal: false,
        modalState: stateName,
      });
    } else if (stateName === "billListing") {
      updateModalState({
        ...iModalState,
        BillModal: true,
        billListingModal: false,
        modalState: stateName,
      });
    } else if (state === "biddingDetail") {
      updateModalState({
        ...iModalState,
        BillModal: true,
        biddingDetailsModal: false,
        modalState: stateName,
      });
    } else if (state === "bidding") {
      updateModalState({
        ...iModalState,
        BillModal: true,
        modalState: stateName,
        globalBiddingQuoteModal: false,
      });
      // setShow(false);
      setItems([
        {
          quantity: "",
          color: "",
          unitId: "",
          price: "",
          total: "",
          name: "",
          parentTableName: "FIELDPURCHASEORDER",
          parentTableId: 2,
        },
      ]);
      updateLeadState({
        ...iLeadState,
        // jobCodeId: "",
        // jobType: "",
        // costCodeId: "",
        reasonId: "",
        vendorId: "",
        date: "",
        reminder: "",
        message: "",
        profile: "",
        subtotal: "",
        gst: "",
        grandTotal: "",
        scopeWorkId: "",
        constructionDocsId: "",
        externalQuoteId: "",
        vendorType: "",
        type: "",
        newQuoteRequestError: {},
        day: "",
        week: "",
      });
    } else {
      updateModalState({
        ...iModalState,
        BillModal: true,
        // biddingDetailsModal: false,
        modalState: stateName,
      });
    }
    // } else {
    //   errorToast("Please select the job first");
    // }
  };

  const hideBillModal = () => {
    if (modalState === "costModal") {
      updateModalState({
        ...iModalState,
        BillModal: false,
        SelectCostModal: true,
        modalState: "",
      });
    } else if (modalState === "billListing") {
      updateModalState({
        ...iModalState,
        BillModal: false,
        billListingModal: true,
        modalState: "",
      });
    } else if (modalState === "biddingDetail") {
      updateModalState({
        ...iModalState,
        BillModal: false,
        biddingDetailsModal: true,
        modalState: "",
      });
    } else if (modalState == "bidding") {
      updateModalState({
        ...iModalState,
        BillModal: false,
        modalState: "",
        globalBiddingQuoteModal: true,
      });
      // setShow(true);
    } else {
      updateModalState({
        ...iModalState,
        BillModal: false,
        modalState: "",
      });
    }
  };

  const showBudgetChangeOrderModal = (
    state,
    costCodeIdList,
    jobIdList,
    jobTypeList,
    jobCostId,
    costCodeStatic1,
    costCodeStaticName1
  ) => {
    // if (jobCodeId) {
    dispatch(getAllReasonListAction());
    dispatch(getAllBuilderSigneeAction());
    dispatch(VendorSearchAction("", vendorType)).then((res) => {
      updateLeadState({
        ...iLeadState,
        vendorSearchData: res.vendors,
      });
    });
    dispatch(
      getAllQuoteRequestAction(
        ProjectId,
        costCodeIdList,
        jobIdList,
        jobTypeList
      )
    );
    dispatch(
      addBudgetChangeOrderAction({
        jobCodeId: jobIdList ?? jobCodeId,
        jobType: jobTypeList ?? jobType,
        costCodeId: costCodeIdList ?? costCodeId,
        jobCostId: jobCostId,
      })
    )
      .then((res) => {
        updateLeadState({
          ...iLeadState,
          jobCodeId: jobIdList ?? jobCodeId,
          jobType: jobTypeList ?? jobType,
          costCodeId: costCodeIdList ?? costCodeId,
          estimationDocumentId: res?.data?.data?.documentCode,
          estimationCreateId: res?.data?.data?.id,
          costCodeStatic: costCodeStatic1 ?? costCodeStatic,
          costCodeStaticName: costCodeStaticName1 ?? costCodeStaticName,
        });
      })
      .catch((err) => {
        errorToast(err?.response?.data?.error);
        // console.log("errrr", err);
      });
    if (state === "costModal") {
      updateModalState({
        ...iModalState,
        BudgetChangeOrderModal: true,
        SelectCostModal: false,
        modalState: state,
      });
    } else {
      updateModalState({
        ...iModalState,
        BudgetChangeOrderModal: true,
        modalState: state,
      });
      // setShow(false);
    }
  };

  const hideBudgetChangeOrderModal = () => {
    if (modalState === "costModal") {
      updateModalState({
        ...iModalState,
        BudgetChangeOrderModal: false,
        SelectCostModal: true,
        modalState: "",
      });
    } else {
      updateModalState({
        ...iModalState,
        BudgetChangeOrderModal: false,
        modalState: "",
      });
      setItems([
        {
          quantity: "",
          color: "",
          unitId: "",
          price: "",
          total: "",
          name: "",
          parentTableName: "FIELDPURCHASEORDER",
          parentTableId: 2,
        },
      ]);
      updateLeadState({
        ...iLeadState,
        // jobCodeId: "",
        // jobType: "",
        // costCodeId: "",
        reasonId: "",
        vendorId: "",
        date: "",
        reminder: "",
        message: "",
        profile: "",
        subtotal: "",
        gst: "",
        grandTotal: "",
        scopeWorkId: "",
        constructionDocsId: "",
        externalQuoteId: "",
        vendorType: "",
        type: "",
        newQuoteRequestError: {},
        day: "",
        week: "",
      });
      // setShow(true);
    }
    updateLeadState({
      ...iLeadState,
      documentDescription: "",
      increaseAmount: "",
      InvesterEmail: "",
      InvesterfullName: "",

      authorisationType: "",
      vendorId: "",
      builderSigneeId: "",
      relatedDocumentId: "",
      costCodeId: 534,
      jobCodeId: "",
      jobType: "",
      authorisationType: "",
    });
  };

  const showPurchaseModal = (
    state,
    costCodeIdList,
    jobTypeList,
    jobIdList,
    quoteId
  ) => {
    const type = week ? week : day;
    const reminderDates = calculateDateDifference(type);

    dispatch(VendorTypesAction());
    dispatch(getAllReasonListAction());
    dispatch(getAllProfileListAction());
    dispatch(getAllEstimationUnitAction());
    dispatch(
      addPurchaseAction({
        jobCodeId: jobIdList ?? jobCodeId,
        jobType: jobTypeList ?? jobType,
        costCodeId: costCodeIdList ?? costCodeId,
      })
    )
      .then((res) => {
        updateLeadState({
          ...iLeadState,
          purchaseCodeForModal: res?.data?.data?.documentCode,
          jobCodeId: jobIdList ?? jobCodeId,
          jobType: jobTypeList ?? jobType,
          costCodeId: costCodeIdList ?? costCodeId,
          // estimationDocumentId: res?.data?.data?.documentCode,
          estimationCreateId: res?.data?.data?.id,
        });
      })
      .catch((err) => {
        errorToast(err?.response?.data?.error);
        // console.log("errrr", err);
      });
    dispatch(getquoteDetailsAction(quoteId)).then((res) => {
      updateLeadState({
        ...iLeadState,
        quoteDetailsData: res?.data,
        jobCodeId: res?.data?.jobCodeId,
        jobType: res?.data?.jobType,
        costCodeId: res?.data?.CostCodeId,
        reasonId: res?.data?.reasonId,
        vendorId: res?.data?.vendorId,
        date: new Date(),
        reminder: reminderDates,
        message: res?.data?.message,
        profile: res?.data?.profile,
        subtotal: res?.data?.subtotal,
        gst: res?.data?.gst,
        grandTotal: res?.data?.grandTotal,
        status: res?.data?.status,
      });
      setItems(res?.data?.BuilderItems || res?.data?.VendorItems);
    });
    if (state === "costModal") {
      updateModalState({
        ...iModalState,
        PurchaseModal: true,
        SelectCostModal: false,
        modalState: state,
      });
    } else {
      updateModalState({
        ...iModalState,
        PurchaseModal: true,
        NewQuoteRequestModal: false,
      });
      // // setShow(false);
    }
  };

  const hidePurchaseModal = () => {
    if (modalState === "costModal") {
      updateModalState({
        ...iModalState,
        PurchaseModal: false,
        SelectCostModal: true,
        modalState: "",
      });
    } else if (modalState === "purchase") {
      updateModalState({
        ...iModalState,
        PurchaseModal: false,
        NewQuoteRequestModal: true,
        modalState: "",
      });
      // // setShow(true);
    }
  };

  // SYSTEMFUNC

  const hideSystemVendor = () => {
    if (modalState === "quoteModal") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: false,
        NewQuoteRequestModal: true,
        vendorName: "",
        vendorType: "",
        modalState: "",
      });
    } else if (modalState === "purchaseModal") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: false,
        PurchaseModal: true,
        vendorName: "",
        vendorType: "",
        modalState: "",
      });
    } else if (modalState === "billModal") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: false,
        BillModal: true,
        vendorName: "",
        vendorType: "",
        modalState: modalState,
      });
    } else if (modalState === "fieldPurchaseOrder") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: false,
        FieldPurchaseOrderModal: true,
        vendorName: "",
        vendorType: "",
        modalState: "",
      });
    } else if (modalState === "supplierCredit") {
      updateModalState({
        ...iModalState,
        modalState: "",
        SystemVendorModal: false,
        SupplyCreditModal: true,
        vendorName: "",
        vendorType: "",
      });
    } else if (modalState === "backChargeModal") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: false,
        addBackChargeModal: true,
        modalState: modalState,
      });
    } else if (modalState === "backChargeNoti") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: false,
        VendorBackChargeNotificationModal: true,
        modalState: modalState,
      });
    }
  };

  const showSystemVendor = (data) => {
    dispatch(VendorTypesAction());
    updateLeadState({
      ...iLeadState,
      vendorId: "",
      vendorType: "",
    });

    if (data === "quoteModal") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: true,
        NewQuoteRequestModal: false,
        vendorName: "",
        vendorType: "",
        modalState: data,
      });
    } else if (data === "purchaseModal") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: true,
        PurchaseModal: false,
        vendorName: "",
        vendorType: "",
        modalState: data,
      });
    } else if (data === "billModal") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: true,
        BillModal: false,
        vendorName: "",
        vendorType: "",
        modalState: data,
      });
    } else if (data === "fieldPurchaseOrder") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: true,
        FieldPurchaseOrderModal: false,
        vendorName: "",
        vendorType: "",
        modalState: data,
      });
    } else if (data === "supplierCredit") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: true,
        SupplyCreditModal: false,
        vendorName: "",
        vendorType: "",
        modalState: data,
      });
    } else if (data === "backChargeModal") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: true,
        addBackChargeModal: false,
        modalState: data,
      });
      // updateLeadState((prevState) => ({
      //   ...prevState,
      //   vendorName: "",
      //   vendorType: "",
      // }));
    } else if (data === "backChargeNoti") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: true,
        VendorBackChargeNotificationModal: false,
        modalState: data,
      });
    }
  };

  const handleValidationSystem = () => {
    let error = {};
    let formIsValid = true;

    if (!vendorId) {
      error.vendorNameError = "*  Vendor name can't be empty";
      formIsValid = false;
    }

    // if (!vendorType) {
    //   error.vendorTypeError = "* Vendor Type can't be empty";
    //   formIsValid = false;
    // }

    updateLeadState({ ...iLeadState, systemErrors: error });
    return formIsValid;
  };

  const handleSystemVendor = (e) => {
    e.preventDefault();
    let formIsValid = handleValidationSystem();
    // updateState({...iState, name:})
    if (formIsValid) {
      hideSystemVendor();
      // handleAddVendorModal1Show();
    }
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    try {
      if (value == "") {
        setData1(data2);
      } else {
        let temp = data1.filter((item) => {
          return item.toLowerCase().indexOf(value.toLowerCase()) > -1;
        });
        setData1(temp);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const onChangeHandlers = (e) => {
    const { name, value } = e.target;
    try {
      if (value == "") {
        setData3(data4);
      } else {
        let temp = data3.filter((item) => {
          return item.toLowerCase().indexOf(value.toLowerCase()) > -1;
        });
        setData3(temp);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleInputSearchCity = (e) => {
    const { name, value } = e.target;
    dispatch(VendorSearchAction(value, vendorType)).then((res) => {
      updateLeadState({ ...iLeadState, vendorSearchData: res.vendors });
    });
  };
  let userId = "";
  let companyId = "";
  let token = "";
  useEffect(() => {
    window.scrollTo(0, 0);
    // dispatch(
    //   resetPasswordAction({ password: "nikgl" }, userId, companyId, token)
    // ).then((res) => {
    //   console.log("ressss", res);
    //   // navigate("/ibuild-system-login")
    // });
    dispatch(
      getAllBudgetListingAction({
        jobs: [
          {
            id: state?.id,
            type: state?.jobType,
          },
        ],
      })
    );
    dispatch(getMultiBlocksList(state?.id));
    dispatch(getEstimationSettingCostCategory());
  }, []);

  useEffect(() => {
    if (SystemVendorModal) {
      setData1(vendorTypesData?.vendorType);
      setData2(vendorTypesData?.vendorType);
    }
  }, [SystemVendorModal, vendorTypesData]);

  useEffect(() => {
    if (SystemVendorModal) {
      setData3(vendorTypesData?.vendorType);
      setData4(vendorTypesData?.vendorType);
    }
  }, [SystemVendorModal, vendorTypesData]);
  useEffect(() => {
    dispatch(VendorSearchAction("", vendorType)).then((res) => {
      updateLeadState({ ...iLeadState, vendorSearchData: res.vendors });
    });
  }, [vendorType]);

  useEffect(() => {
    dispatch(VendorSearchAction("", vendorTypes)).then((res) => {
      updateLeadState({ ...iLeadState, vendorSearchData: res.vendors });
    });
  }, [vendorTypes]);

  const [items, setItems] = useState([
    {
      quantity: "",
      color: "",
      unitId: "",
      price: "",
      total: "",
      name: "",
      parentTableName: "QUOTEREQUEST",
      parentTableId: 2,
    },
  ]);

  useEffect(() => {
    dispatch(vendorDetailsAction(vendorId));
    getDate();
  }, [vendorId]);

  const handleQuoteRequestValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!reasonId) {
      error.reasonIdError = "Reason id can't be empty";
      formIsValid = false;
    }

    if (!vendorId) {
      error.vendorIdError = "Vendor id can't be empty";
      formIsValid = false;
    }

    // if(!date){
    //   error.dateError = "Date can't be empty";
    //   formIsValid = false
    // }

    if (!day && !week) {
      error.reminderDateError = "Remiander can't be empty";
      formIsValid = false;
    }

    if (!message) {
      error.messageError = "Message can't be empty";
      formIsValid = false;
    }

    if (!profile) {
      error.profileError = "Profile can't be empty";
      formIsValid = false;
    }

    if (!subtotal) {
      error.subtotalError = "subtotal can't be empty";
      formIsValid = false;
    }

    if (
      !profileListData?.find((x) => x?.id == profile)?.taxRows[0]?.taxPercent
    ) {
      error.gstError = "GST can't be empty";
      // formIsValid = false;
    }
    if (!grandTotal) {
      error.grandTotalError = "Grand total can't be empty";
      formIsValid = false;
    }

    // if(!jobCodeId){
    //   error.jobCodeIdError = "Job id can't be empty";
    //   formIsValid = false
    // }
    // if(!jobType){
    //   error.jobTypeError = "Job type can't be empty";
    //   formIsValid = false
    // }
    // if(!costCodeId){
    //   error.costCodeIdError = "COst code can't be empty";
    //   formIsValid = false
    // }

    // if(!scopeWorkId){
    //   error.scopeWorkIdError = "Scope work can't be empty";
    //   formIsValid = false
    // }

    // if(!externalQuoteId){
    //   error.externalQuoteIdError = "External quote can't be empty";
    //   formIsValid = false
    // }

    // if(!constructionDocsId){
    //   error.constructionDocsIdError = "Construction id  can't be empty";
    //   formIsValid = false
    // }

    updateLeadState({ ...iLeadState, newQuoteRequestError: error });
    return formIsValid;
  };

  const handleQuoteRequestFunc = (e, status, requestType) => {
    e.preventDefault();

    // let formIsValid = handleQuoteRequestValidation();
    const filled = items.every((item) =>
      Object.values(item).every((value) => value !== "")
    );
    const type = week ? week : day;
    const reminderDates = calculateDateDifference(type);
    let data = {
      jobCodeId,
      jobType,
      costCodeId,
      reasonId,
      vendorId,
      date: new Date(),
      reminder: reminderDates,
      message,
      profile: profiles,
      subtotal: Number(subtotal),
      gst: Number(taxPercentage || 0),
      grandTotal:
        Number(subtotal) +
        Number(subtotal) * (Number(taxPercentages) / 100) +
        (Number(taxAmount) || 0),
      itemDetails: items.every((item) =>
        Object.values(item).every((value) => value !== "")
      )
        ? items
        : [],
      status: status,

      otherTaxes: taxName
        ? [
            {
              taxName,
              taxPercentage,
              taxAmount,
            },
          ]
        : [],

      // scopeWorkId,
      // constructionDocsId,
      // externalQuoteId,
    };

    // if (formIsValid) {
    // if (filled) {
    if (requestType === "quote") {
      dispatch(updateQuoteRequestAction(estimationCreateId, data))
        .then((res) => {
          hideNewQuoteRequestModal();
          sucessToast("New quote created successfully");
          setItems([
            {
              quantity: "",
              color: "",
              unitId: "",
              price: "",
              total: "",
              name: "",
              parentTableName: "QUOTEREQUEST",
              parentTableId: 2,
            },
          ]);
          updateLeadState({
            ...iLeadState,
            // jobCodeId: "",
            // jobType: "",
            // costCodeId: "",
            reasonId: "",
            vendorId: "",
            date: "",
            taxPercentage: "",
            taxName: "",
            showTaxFlag: false,
            message: "",
            profile: "",
            subtotal: "",
            gst: "",
            grandTotal: "",
            scopeWorkId: "",
            constructionDocsId: "",
            externalQuoteId: "",
            vendorType: "",
            type: "",
            newQuoteRequestError: {},
            day: "",
            week: "",
            taxAmount: "",
          });
          setTaxPercentage("");
        })
        .catch((err) => {
          errorToast(err?.response?.data?.error);
        });
    } else if (requestType === "fieldPurchaseOrder") {
      dispatch(updateFieldPurchaseOrderAction(data, estimationCreateId))
        .then((res) => {
          hideFieldPurchaseOrder();
          dispatch(
            getAllFieldPurchaseOrderAction(
              costCodeId,
              state?.id,
              state?.jobType
            )
          );
          sucessToast("New field purchase order created successfully");
          setItems([
            {
              quantity: "",
              color: "",
              unitId: "",
              price: "",
              total: "",
              name: "",
              parentTableName: "FIELDPURCHASEORDER",
              parentTableId: 2,
            },
          ]);
          updateLeadState({
            ...iLeadState,
            // jobCodeId: "",
            // jobType: "",
            // costCodeId: "",
            reasonId: "",
            vendorId: "",
            date: "",
            reminder: "",
            message: "",
            profile: "",
            subtotal: "",
            gst: "",
            grandTotal: "",
            scopeWorkId: "",
            constructionDocsId: "",
            externalQuoteId: "",
            vendorType: "",
            type: "",
            newQuoteRequestError: {},
            day: "",
            week: "",
          });
        })
        .catch((err) => {
          errorToast(err?.response?.data?.error);
        });
    } else if (requestType === "supplierCredit") {
      dispatch(updateSupplierCreditAction(data, estimationCreateId))
        .then((res) => {
          hideSupplierCreditModal();
          dispatch(getAllSupplierCreditOrderAction(costCodeId));
          sucessToast("New field purchase order created successfully");
          setItems([
            {
              quantity: "",
              color: "",
              unitId: "",
              price: "",
              total: "",
              name: "",
              parentTableName: "FIELDPURCHASEORDER",
              parentTableId: 2,
            },
          ]);
          updateLeadState({
            ...iLeadState,
            // jobCodeId: "",
            // jobType: "",
            // costCodeId: "",
            reasonId: "",
            vendorId: "",
            date: "",
            reminder: "",
            message: "",
            profile: "",
            subtotal: "",
            gst: "",
            grandTotal: "",
            scopeWorkId: "",
            constructionDocsId: "",
            externalQuoteId: "",
            vendorType: "",
            type: "",
            newQuoteRequestError: {},
            day: "",
            week: "",
          });
        })
        .catch((err) => {
          errorToast(err?.response?.data?.error);
          // console.log("datawasted", err);
        });
    } else if (requestType === "purchase") {
      dispatch(updatePurchaseDetailsAction(estimationCreateId, data))
        .then((res) => {
          sucessToast("New purchase created successfully");
          // dispatch(getAllPurchaseListAction(costCodeId));
          updateModalState({
            ...iModalState,
            PurchaseModal: false,
            NewQuoteRequestModal: true,
            modalState: "",
          });
          setItems([
            {
              quantity: "",
              color: "",
              unitId: "",
              price: "",
              total: "",
              name: "",
              parentTableName: "QUOTEREQUEST",
              parentTableId: 2,
            },
          ]);
          updateLeadState({
            ...iLeadState,
            // jobCodeId: "",
            // jobType: "",
            // costCodeId: "",
            reasonId: "",
            vendorId: "",
            date: "",
            reminder: "",
            message: "",
            profile: "",
            subtotal: "",
            gst: "",
            grandTotal: "",
            scopeWorkId: "",
            constructionDocsId: "",
            externalQuoteId: "",
            vendorType: "",
            type: "",
            day: "",
            week: "",
            newQuoteRequestError: {},
          });
        })
        .catch((err) => {
          errorToast(err?.response?.data?.error);
          // console.log("datawasted", err);
        });
    }
    //   } else {
    //     updateLeadState({
    //       ...iLeadState,
    //       itemErrors: "Please fill complete data",
    //     });
    //   }
    // }
  };

  const hideAddRemianderModal = () => {
    if (modalState === "quoteModal") {
      updateModalState({
        ...iModalState,
        reminderModal: false,
        NewQuoteRequestModal: true,
        // modalState:""
      });
    } else if (modalState === "addTask") {
      updateModalState({
        ...iModalState,
        reminderModal: false,
        AddTaskModal: true,
        // modalState: "",
      });
    } else if (modalState === "editTodo") {
      updateModalState({
        ...iModalState,
        reminderModal: false,
        updateTodoModal: true,
        // modalState: "",
      });
    } else if (modalState === "purchaseModal") {
      updateModalState({
        ...iModalState,
        reminderModal: false,
        PurchaseModal: true,
        // modalId: id,
        modalState: "",
      });
    } else if (modalState === "billModal") {
      updateModalState({
        ...iModalState,
        reminderModal: false,
        BillModal: true,
        // modalId: id,
        modalState: "",
      });
    } else if (modalState === "fieldPurchaseOrder") {
      updateModalState({
        ...iModalState,
        reminderModal: false,
        FieldPurchaseOrderModal: true,
        // modalId: id,
        modalState: "",
      });
    } else if (modalState === "supplierCredit") {
      updateModalState({
        ...iModalState,
        reminderModal: false,
        SupplyCreditModal: true,
        // modalId: id,
        modalState: "",
      });
    } else if (modalState === "budgetChange") {
      updateModalState({
        ...iModalState,
        reminderModal: false,
        BudgetChangeOrderModal: true,
        // modalId: id,
        modalState: "",
      });
    } else if (modalState == "updatebudgetChange") {
      updateModalState({
        ...iModalState,
        reminderModal: false,
        updateBudgetChangeOrderModal: true,
        // modalId: id,
        modalState: "",
      });
    }
  };

  const showAddRemianderModal = (data, id) => {
    if (data === "quoteModal") {
      updateModalState({
        ...iModalState,
        reminderModal: true,
        NewQuoteRequestModal: false,
        // modalId: id,
        modalState: data,
      });
    } else if (data === "addTask") {
      updateModalState({
        ...iModalState,
        reminderModal: true,
        AddTaskModal: false,
        // modalId: id,
        modalState: data,
      });
    } else if (data === "editTodo") {
      updateModalState({
        ...iModalState,
        reminderModal: true,
        updateTodoModal: false,
        modalState: data,
      });
    } else if (data === "purchaseModal") {
      updateModalState({
        ...iModalState,
        reminderModal: true,
        PurchaseModal: false,
        // modalId: id,
        modalState: data,
      });
    } else if (data === "billModal") {
      updateModalState({
        ...iModalState,
        reminderModal: true,
        BillModal: false,
        // modalId: id,
        modalState: data,
      });
    } else if (data === "fieldPurchaseOrder") {
      updateModalState({
        ...iModalState,
        reminderModal: true,
        FieldPurchaseOrderModal: false,
        // modalId: id,
        modalState: data,
      });
    } else if (data === "supplierCredit") {
      updateModalState({
        ...iModalState,
        reminderModal: true,
        SupplyCreditModal: false,
        // modalId: id,
        modalState: data,
      });
    } else if (data === "budgetChange") {
      updateModalState({
        ...iModalState,
        reminderModal: true,
        BudgetChangeOrderModal: false,
        // modalId: id,
        modalState: data,
      });
    } else if (data == "updatebudgetChange") {
      updateModalState({
        ...iModalState,
        reminderModal: true,
        updateBudgetChangeOrderModal: false,
        // modalId: id,
        modalState: data,
      });
    }

    updateLeadState({ ...iLeadState, day: day, week: week });
  };

  // const selectJobValidation = () => {
  //    let error = {};
  //    let formIsValid = true;
  // }

  // const selectJob = () => {

  // }

  const [billDataCategories, setBillDataCategories] = useState([
    {
      documentType: "",
      costCodeId: "",
      jobCodeId: "",
      jobType: "",
      reasonId: "",
      itemDescription: "",
      subtotal: "",
      itemDetails: [
        {
          quantity: "",
          color: "",
          unitId: "",
          price: "",
          total: "",
          name: "",
          parentTableName: "QUOTEREQUEST",
          parentTableId: 2,
          jobType:
            jobListData?.jobs?.find((x) => x?.id == jobCodeId)?.jobType ??
            state?.jobType,
        },
      ],
    },
  ]);

  console.log("billDataCategories", billDataCategories);

  const [billQuoteItems, setBillQuoteItems] = useState([
    {
      quantity: "",
      color: "",
      unitId: "",
      price: "",
      total: "",
      name: "",
      parentTableName: "QUOTEREQUEST",
      parentTableId: 2,
    },
  ]);

  const removeLine = (i, type) => {
    if (type === "BillQuote") {
      let data = [...billDataCategories];
      data.splice(i, 1);
      setBillDataCategories(data);
    } else if (type === "billItemLine") {
      let data = [...billQuoteItems];
      data.splice(i, 1);
      setBillQuoteItems(data);
    } else {
      let data = [...items];
      data.splice(i, 1);
      setItems(data);
    }
  };

  useEffect(() => {
    let sum = 0;

    billDataCategories?.map((ele, i) => {
      sum = sum + Number(ele?.subtotal ?? 0);
    });
    updateLeadState({ ...iLeadState, billDataCategorySubtotal: sum });
  }, [billDataCategories, profile]);

  useEffect(() => {
    if (billDataCategorySubtotal) {
      updateLeadState({
        ...iLeadState,
        calculatedGstAmount: Math.round(
          billDataCategorySubtotal *
            (profileListData?.find((x) => x?.id == profile)?.taxRows[0]
              ?.taxPercent /
              100)
        ),
        grandTotal:
          billDataCategorySubtotal +
          billDataCategorySubtotal *
            (profileListData?.find((x) => x?.id == profile)?.taxRows[0]
              ?.taxPercent /
              100),
      });
    }
  }, [
    billDataCategorySubtotal,
    profileListData?.find((x) => x?.id == profile)?.taxRows[0]?.taxPercent,
  ]);

  const totalSums = billDataCategories?.reduce((acc, category) => {
    const itemTotal = category?.itemDetails?.reduce((itemAcc, item) => {
      return itemAcc + (Number(item.total) || 0);
    }, 0);
    return acc + itemTotal;
  }, 0);

  const showBillQuoteReqModal = (i) => {
    dispatch(getAllEstimationUnitAction());
    updateModalState({
      ...iModalState,
      // reminderModal:true,
      BillQuoteReqModal: true,
      BillModal: false,
      // modalId: id,
      givenIndex: i,
    });
    setBillQuoteItems([
      {
        quantity: "",
        color: "",
        unitId: "",
        price: "",
        total: "",
        name: "",
        parentTableName: "QUOTEREQUEST",
        parentTableId: 2,
      },
    ]);
    // updateModalState({...iModalState, BillQuoteReqModal:true, })
  };

  const hideBillQuoteReqModal = () => {
    updateModalState({
      ...iModalState,
      BillQuoteReqModal: false,
      BillModal: true,
    });
  };

  const insertQuoteIntoBill = (e, index) => {
    e.preventDefault();

    const filled = billQuoteItems.every((item) =>
      Object.values(item).every((value) => value !== "")
    );
    if (filled) {
      billDataCategories[givenIndex].itemDetails = [...billQuoteItems];
      hideBillQuoteReqModal();
      let totalSum = 0;
      billQuoteItems.forEach((item) => {
        if (item.total) {
          const subtotal = parseInt(item.total);
          totalSum += subtotal;
        }
      });
      billDataCategories[givenIndex].subtotal = totalSum;
    } else {
      errorToast("Please enter complete data first");
    }
  };

  const handleChangeArrayItem = (e, index, type) => {
    const { name, value } = e.target;
    if (type === "billModal") {
      const newItems = [...billDataCategories];
      newItems[index] = {
        ...newItems[index],
        [name]: value,
        jobType:
          jobListData?.jobs?.find((x) => x?.id == jobCodeId)?.jobType ??
          "Land Development",
      };
      setBillDataCategories(newItems);
    } else if (type === "billItemLine") {
      const newItems = [...billQuoteItems];
      newItems[index] = { ...newItems[index], [name]: value };
      setBillQuoteItems(newItems);
    } else {
      const newItems = [...items];
      newItems[index] = { ...newItems[index], [name]: value };
      setItems(newItems);

      let sum = 0;
      newItems.forEach((item) => {
        if (item.quantity && item.price) {
          const quantity = parseInt(item.quantity);
          const price = parseInt(item.price);
          const total = quantity * price;
          item.total = total.toString();
          sum = sum + Number(item?.total);
        }
      });

      // calculatedGstAmount: Math.round(sum * (profileListData?.find((x) => x?.id == profile)?.taxRows[0]?.taxPercent / 100))

      updateLeadState({ ...iLeadState, subtotal: sum });
    }
  };

  const addExtraRowInFunc = (e, type) => {
    e.preventDefault();

    if (type === "billModal") {
      const filled = billDataCategories.every((item) =>
        Object.values(item).every((value) => value !== "")
      );

      if (filled) {
        const newItem = {
          documentType: "",
          costCodeId: "",
          jobCodeId: "",
          jobType: "", // Set this to empty or a default value
          reasonId: "",
          itemDescription: "",
          subtotal: "",
          itemDetails: [
            {
              quantity: "",
              color: "",
              unitId: "",
              price: "",
              total: "",
              name: "",
              parentTableName: "QUOTEREQUEST",
              parentTableId: 2,
              jobType: "", // Ensure this is empty or has a default value
            },
          ],
        };

        // Update the state by adding the new item to the array
        setBillDataCategories((prevItems) => [...prevItems, newItem]);
      } else {
        // Handle the case where the current row is not fully filled
        alert(
          "Please fill out all fields in the current row before adding a new line."
        );
      }
    } else if (type === "billItemLine") {
      const filledBillItem = billQuoteItems.every((item) =>
        Object.values(item).every((value) => value !== "")
      );
      setBillQuoteItems([
        ...billQuoteItems,
        {
          quantity: "",
          color: "",
          unitId: "",
          price: "",
          total: "",
          name: "",
          parentTableName: "QUOTEREQUEST",
          parentTableId: 2,
        },
      ]);
    } else if (type === "quote") {
      const filledQuote = items.every((item) =>
        Object.values(item).every((value) => value !== "")
      );

      if (filledQuote) {
        setItems([
          ...items,
          {
            quantity: "",
            color: "",
            unitId: "",
            price: "",
            total: "",
            name: "",
            parentTableName: "QUOTEREQUEST",
            parentTableId: 2,
          },
        ]);
      } else {
        updateLeadState({
          ...iLeadState,
          itemErrors: "Please fill complete data",
        });
      }
    }
  };

  const clearAllLines = (e, type) => {
    e.preventDefault();
    if (type === "quote") {
      setItems([
        {
          quantity: "",
          color: "",
          unitId: "",
          price: "",
          total: "",
          name: "",
          parentTableName: "QUOTEREQUEST",
          parentTableId: 2,
        },
      ]);
    } else if (type === "billItemLine") {
      setBillQuoteItems([
        {
          quantity: "",
          color: "",
          unitId: "",
          price: "",
          total: "",
          name: "",
          parentTableName: "QUOTEREQUEST",
          parentTableId: 2,
        },
      ]);
    } else if (type === "billModal") {
      setBillDataCategories([
        {
          documentType: "",
          costCodeId: "",
          jobCodeId: "",
          reasonId: "",
          itemDescription: "",
          subtotal: "",
          jobType:
            jobListData?.jobs?.find((x) => x?.id == jobCodeId)?.jobType ??
            "Land Development",
        },
      ]);
    }
  };
  useEffect(() => {
    billQuoteItems.forEach((item) => {
      if (item.quantity && item.price) {
        const quantity = parseInt(item.quantity);
        const price = parseInt(item.price);
        const total = quantity * price;
        item.total = total.toString();
      }
    });
  }, [billQuoteItems?.price, billQuoteItems?.quantity, billQuoteItems]);

  const handleBillRequestValidation = () => {
    let formIsValid = true;
    let error = {};

    const filled = billDataCategories.every((item) =>
      Object.values(item).every((value) => value !== "")
    );
    // if (!filled) {
    //   error.categoryDataError = "Category data can't be empty";
    //   formIsValid = false;
    // }
    if (!vendorId) {
      error.vendorIdError = "Vendor id can't be empty";
      formIsValid = false;
    }

    // if(!date){
    //   error.dateError = "Date can't be empty";
    //   formIsValid = false
    // }

    if (!day && !week) {
      error.reminderDateError = "Remiander can't be empty";
      formIsValid = false;
    }

    if (!message) {
      error.messageError = "Message can't be empty";
      formIsValid = false;
    }

    if (!profile) {
      error.profileError = "Profile can't be empty";
      formIsValid = false;
    }

    if (!billDataCategorySubtotal) {
      error.subtotalError = "subtotal can't be empty";
      formIsValid = false;
    }

    if (
      !profileListData?.find((x) => x?.id == profile)?.taxRows[0]?.taxPercent
    ) {
      error.gstError = "GST can't be empty";
      // formIsValid = false;
    }
    if (!grandTotal) {
      error.grandTotalError = "Grand total can't be empty";
      formIsValid = false;
    }

    // if(!jobCodeId){
    //   error.jobCodeIdError = "Job id can't be empty";
    //   formIsValid = false
    // }
    // if(!jobType){
    //   error.jobTypeError = "Job type can't be empty";
    //   formIsValid = false
    // }
    // if(!costCodeId){
    //   error.costCodeIdError = "COst code can't be empty";
    //   formIsValid = false
    // }

    // if(!scopeWorkId){
    //   error.scopeWorkIdError = "Scope work can't be empty";
    //   formIsValid = false
    // }

    // if(!externalQuoteId){
    //   error.externalQuoteIdError = "External quote can't be empty";
    //   formIsValid = false
    // }

    // if(!constructionDocsId){
    //   error.constructionDocsIdError = "Construction id  can't be empty";
    //   formIsValid = false
    // }

    updateLeadState({ ...iLeadState, billError: error });
    return formIsValid;
  };

  const updateBillFunc = (buttonQuery) => {
    // e.preventDefault();
    // console.log(buttonQuery, "button query");
    // let formIsValid = handleBillRequestValidation();
    const type = week ? week : day;
    const reminderDates = calculateDateDifference(type);
    const filled = billDataCategories.every((item) =>
      Object.values(item).every((value) => value !== "")
    );
    const data = {
      status: buttonQuery === "SEND" ? "SEND" : "DRAFT",
      vendorId: +vendorId,
      date: new Date(),
      reminder: reminderDates,
      message,
      profile: profiles,
      total:
        Number(totalSums) +
        Number(totalSums) * (Number(taxPercentages) / 100) +
        Number(totalSums) * (Number(taxPercentage || 0) / 100),
      subTotal: totalSums,
      Categories: billDataCategories,
      gst: profileListData?.find((x) => x?.id == profile)?.taxRows[0]
        ?.taxPercent,

      projectId: Number(state?.id),
      // jobType:"Land Development"
    };

    // if (filled && formIsValid)
    //    {
    dispatch(updateBillAction(estimationDocumentId, data))
      .then((res) => {
        if (res.message === "Success") {
          dispatch(getAllBillListAction());
          sucessToast("Bill created successfully");
          updateModalState({
            ...iModalState,
            BillModal: false,
            billListingModal: true,
            modalState: "",
          });
        }

        updateLeadState({
          ...iLeadState,
          vendorId: "",
          date: "",
          reminder: "",
          message: "",
          profile: "",
          type: "",
          gst: "",
          billDataCategorySubtotal: "",
          grandTotal: "",
          reminderDates: "",
          week: "",
          day: "",
        });

        setBillDataCategories([
          {
            documentType: "",
            costCodeId: "",
            jobCodeId: "",
            jobType: "",
            reasonId: "",
            itemDescription: "",
            subtotal: "",
            itemDetails: [],
            //  jobType: jobListData?.jobs?.find((x) => x?.id == jobCodeId)?.jobType ?? "Land Development"
          },
        ]);
      })
      .catch((err) => {
        errorToast(err?.response?.data?.error);
      });
  };
  // };

  const budgetChangeValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!reasonId) {
      error.reasonIdError = "Reason id can't be empty";
      formIsValid = false;
    }
    // if(!InvesterEmail){
    //   error.InvesterEmailError = "Inverstor email can't be empty";
    //    formIsValid = false
    // }
    if (!documentDescription) {
      error.documentDescriptionError = "Document desc can't be empty";
      formIsValid = false;
    }
    if (!increaseAmount) {
      error.increaseAmountError = "Increase amount can't be empty";
      formIsValid = false;
    }
    if (!day && !week) {
      error.reminderDateError = "Remiander  can't be empty";
      formIsValid = false;
    }
    // if(!authorisationType){
    //   error.authorisationTypeError = "Authorization type can't be empty";
    //    formIsValid = false
    // }

    // if(!vendorId){
    //   error.vendorIdError = "Vendor can't be empty";
    //    formIsValid = false
    // }

    // if (InvesterEmail && InvesterfullName) {
    //   // Resetting the error for vendorId
    //   error.vendorIdError = "";
    //   formIsValid = true
    // } else {
    //   // Validation for vendorId when name or email is empty
    //   if (!vendorId) {
    //     error.vendorIdError = "Vendor can't be empty";
    //     formIsValid = false;
    //   }
    // }

    // if(vendorId){

    // }

    if (!vendorId || (!InvesterEmail && !InvesterfullName)) {
      error.vendorIdError = "Vendor can't be empty";
      return formIsValid;
    }

    if (!builderSigneeId) {
      error.builderSigneeIdError = "Builder signee can't be empty";
      formIsValid = false;
    }
    if (!relatedDocumentId) {
      error.relatedDocumentIdError = "Related docs can't be empty";
      formIsValid = false;
    }
    if (!files) {
      error.filesError = "Files can't be empty";
      formIsValid = false;
    }

    updateLeadState({ ...iLeadState, budgetChangeError: error });
    return formIsValid;
  };

  const budgetChangeFunc = (e, type) => {
    e.preventDefault();
    let formIsValid = budgetChangeValidation();
    // if (formIsValid) {
    let data = {
      documentDescription,
      increaseAmount,
      email: InvesterEmail,
      InvesterfullName,

      authorisationType: "Hardcopy",
      vendorId,
      builderSigneeId,
      relatedDocumentId: 7,
      costCodeId: 534,
      jobCodeId,
      jobType,
      reasonId,
    };
    dispatch(updateBudgetChangeOrderAction(data, estimationCreateId))
      .then((res) => {
        // // console.log('ressssssssssss', res)
        hideBudgetChangeOrderModal();
        hideUpdateBudgetChangeOrderModal();
        // dispatch(budgetListAction)
        sucessToast("Budget created successfully");
        updateLeadState({
          ...iLeadState,
          documentDescription: "",
          increaseAmount: "",
          InvesterEmail: "",
          InvesterfullName: "",

          authorisationType: "",
          vendorId: "",
          builderSigneeId: "",
          relatedDocumentId: "",
          authorisationType: "",
        });
      })
      .catch((err) => {
        errorToast(err?.response?.data?.error);
        // console.log("errrrr", err);
      });
    // }
  };

  const hideSupportingDocsModal = () => {
    if (modalState === "contractList") {
      updateModalState({
        ...iModalState,
        supportingDocsModal: false,
        modalId: "",
      });
    } else {
      updateModalState({
        ...iModalState,
        supportingDocsModal: false,
        modalId: "",
        BudgetChangeOrderModal: true,
      });
    }
  };

  const showSupportingDocsModal = (data, id) => {
    if (data === "contractList") {
      updateModalState({
        ...iModalState,
        supportingDocsModal: true,
        modalId: id,
        modalState: data,
      });
    } else {
      updateModalState({
        ...iModalState,
        supportingDocsModal: true,
        modalId: id,
        BudgetChangeOrderModal: false,
        modalState: data,
      });
    }
  };

  const addSupportingDocsFunc = () => {
    if (!files) {
      updateLeadState({
        ...iLeadState,
        supportingDocsError: "* Files can't be empty",
      });
    } else {
      dispatch(
        addEstimationSupportingDocsAction(
          { files },
          estimationCreateId,
          "BUDGETCHANGEORDER"
        )
      )
        .then((res) => {
          hideSupportingDocsModal();
          sucessToast("Supporting document added successfully");
        })
        .catch((err) => {
          errorToast(err?.response?.data?.message);
          // // console.log("=>", err);
        });
    }
  };

  const hideBiddingDetailModal = () => {
    if (modalState === "billListing") {
      updateModalState({
        ...iModalState,
        biddingDetailsModal: false,
        modalState: "",
      });
    } else {
      updateModalState({
        ...iModalState,
        biddingDetailsModal: false,
        globalBiddingQuoteModal: true,
      });
    }
  };

  const [biddingData, setBiddingData] = useState({});

  const showBiddingDetailModal = (state, costCOde, jobId, jobType, data) => {
    setBiddingData(data);
    dispatch(getAllQuoteRequestAction(ProjectId, costCOde, jobId, jobType));
    dispatch(getAllFieldPurchaseOrderAction(costCOde, jobId, jobType));
    dispatch(getAllEventListAction(costCOde, jobId, jobType));
    dispatch(getAllSupplierCreditOrderAction(costCOde, jobId, jobType));
    dispatch(getAllPurchaseListAction(costCOde, jobId, jobType));
    updateModalState({
      ...iModalState,
      biddingDetailsModal: true,
      globalBiddingQuoteModal: false,
      modalId: costCOde,
      modalState: state,
    });
    updateLeadState({
      ...iLeadState,
      costCodeId: costCOde,
      jobCodeId: jobId,
      jobType: jobType,
    });
  };

  const hideBillDetailModal = () => {
    if (modalState === "biddingDetail") {
      updateModalState({
        ...iModalState,
        billDetailsModal: false,
        biddingDetailsModal: true,
      });
    } else if (modalState === "bidding") {
      updateModalState({
        ...iModalState,
        billDetailsModal: false,
        globalBiddingQuoteModal: true,
      });
    } else {
      updateModalState({
        ...iModalState,
        billDetailsModal: false,
        billListingModal: true,
      });
    }
  };

  const showBillDetailModal = (state, id) => {
    dispatch(getBillDetailsAction(id)).then((res) => {
      updateLeadState({ ...iLeadState, billDetailsData: res?.data?.[0] });
    });
    if (state === "biddingDetail") {
      updateModalState({
        ...iModalState,
        billDetailsModal: true,
        biddingDetailsModal: false,
        modalState: state,
      });
    } else if (state === "bidding") {
      updateModalState({
        ...iModalState,
        billDetailsModal: true,
        globalBiddingQuoteModal: false,
        modalState: state,
      });
    } else {
      updateModalState({
        ...iModalState,
        billDetailsModal: true,
        billListingModal: false,
        modalState: state,
      });
    }
  };

  const hideBillListingModal = () => {
    updateModalState({ ...iModalState, billListingModal: false });
  };

  const showBillListingModal = () => {
    dispatch(getAllBillListAction());
    updateModalState({ ...iModalState, billListingModal: true });
  };

  const removeQuote = (id) => {
    dispatch(removeQRAction(id)).then((res) => {
      sucessToast("QR removed successfully");
      dispatch(
        getAllQuoteRequestAction(ProjectId, modalId, jobCodeId, jobType)
      );
    });
  };

  const markQRPrefferedFun = (id, pid, data) => {
    if (pid != null) {
      dispatch(getQRPreferredAction(id, data)).then((res) => {
        sucessToast("QR preferred successfully");
        dispatch(
          getAllQuoteRequestAction(ProjectId, modalId, jobCodeId, jobType)
        );
        dispatch(
          getAllBudgetListingAction({
            jobs: [
              {
                id: state?.id,
                type: state?.jobType,
              },
            ],
          })
        );
      });
    } else {
      errorToast("you can't preferred as this doesn't have any value");
    }
  };

  const getApproveFunc = (id, data) => {
    dispatch(getApproveCreatePOAction(id)).then((res) => {
      sucessToast("QR approved successfully");
      sucessToast("PO created successfully");
      dispatch(
        getAllQuoteRequestAction(ProjectId, modalId, jobCodeId, jobType)
      );
      // showPurchaseModal('',data?.CostCodeId,jobType,jobCodeId, id);
      dispatch(
        getAllBudgetListingAction({
          jobs: [
            {
              id: state?.id,
              type: state?.jobType,
            },
          ],
        })
      );
    });
  };

  const hideQuoteDetailsModal = () => {
    if (modalState === "globalBidding") {
      updateModalState({
        ...iModalState,
        quoteDetailsModal: false,
        biddingDetailsModal: true,
        modalState: "",
      });
    } else if (modalState === "qrDetails") {
      updateModalState({
        ...iModalState,
        quoteDetailsModal: false,
        BackChargeListingModal: true,
        modalState: "",
      });
    } else if (modalState === "biddingDetaiModal") {
      updateModalState({
        ...iModalState,
        biddingDetailsModal: true,
        quoteDetailsModal: false,
        modalState: "",
      });
    }
  };
  const showQuoteDetailsModal = (state, id) => {
    dispatch(getquoteDetailsAction(id)).then((res) => {
      // // console.log('ressss,', res)
      updateLeadState({ ...iLeadState, quoteDetailsData: res?.data });
    });
    dispatch(getAllEstimationUnitAction());
    if (state == "globalBidding") {
      updateModalState({
        ...iModalState,
        quoteDetailsModal: true,
        globalBiddingQuoteModal: false,
        modalState: state,
      });
      setVendorEditToggle(false);
    } else if (state === "qr") {
      updateModalState({
        ...iModalState,
        quoteDetailsModal: true,
        BackChargeListingModal: false,
        modalState: state,
      });
      setVendorEditToggle(false);
    } else {
      updateModalState({
        ...iModalState,
        quoteDetailsModal: true,
        biddingDetailsModal: false,
        modalState: state,
      });
      setVendorEditToggle(false);
    }
  };

  const showPurchaseDetailsModal = (state, id) => {
    dispatch(getAllPurchaseDetailsAction(id)).then((res) => {
      updateLeadState({ ...iLeadState, quoteDetailsData: res?.data?.[0] });
    });
    if (state === "biddingView")
      updateModalState({
        ...iModalState,
        purchaseDetailsModal: true,
        biddingDetailsModal: false,
        modalState: state,
      });
    else if (state === "po") {
      updateModalState({
        ...iModalState,
        purchaseDetailsModal: true,
        BackChargeListingModal: false,
        modalState: state,
      });
    }
  };

  const hidePurchaseDetailsModal = () => {
    if (modalState === "biddingView") {
      updateModalState({
        ...iModalState,
        purchaseDetailsModal: false,
        biddingDetailsModal: true,
        modalState: "",
      });
    } else if (modalState === "po") {
      updateModalState({
        ...iModalState,
        purchaseDetailsModal: false,
        BackChargeListingModal: true,
        modalState: "",
      });
    }
  };

  const removeBillFunc = (id) => {
    dispatch(removeBillAction(id)).then((res) => {
      sucessToast("Bill removed successfully");
      getAllBillListAction();
    });
  };
  useEffect(() => {
    dispatch(commonCostCategoryListAction(state?.id, state?.jobType));
  }, [state?.id, state?.jobType]);

  useEffect(() => {
    dispatch(
      commonCostCodeListAction(state?.id, state?.jobType, costCategoryId)
    );
  }, [costCategoryId]);

  const showbackChargeListingModal = () => {
    updateModalState({ ...iModalState, BackChargeListingModal: true });
  };

  const hideBackChargeListingModal = () => {
    updateModalState({ ...iModalState, BackChargeListingModal: false });
  };

  useEffect(() => {
    if (BackChargeListingModal) {
      dispatch(getBackChargeCreditList(state?.id, state?.jobType));
    }
  }, [BackChargeListingModal]);

  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const showAddbackChargeModal = (ele) => {
    // dispatch(getAllJobListAction(ProjectId));
    updateModalState({
      ...iModalState,
      addBackChargeModal: true,
      BackChargeListingModal: false,
    });

    updateLeadState((prevState) => ({
      ...prevState,
      costCodeId: ele?.JobCost?.CostCodeId ?? "",
      costCategoryId: ele?.JobCost?.CostCategoryId ?? "",

      documentType: "",
      backChargeVendorId: "",
      originalDocumentId: "",
      documentType: "",
      vendorName: "",
      vendorNames: "",
    }));
  };

  const hideAddBackChargeModal = () => {
    updateModalState({
      ...iModalState,
      addBackChargeModal: false,
      BackChargeListingModal: false,
    });
    updateLeadState({
      ...iLeadState,
      costCodeId: "",
      costCategoryId: "",
      documentType: "",
      backChargeVendorId: "",
      originalDocumentId: "",
      documentType: "",
      vendorName: "",
      vendorNames: "",
    });
  };

  const handleCreateBackCharge = () => {
    const datta = {
      jobCodeId: state?.id,
      costCodeId,
      costCategoryId,
      documentType: selectedOption,
      jobType: state?.jobType,
      backChargeVendorId: Number(vendorIds),
      vendorId,
      // originalDocumentId,
      // originalDocumentType,
    };
    dispatch(createBackChargeCredit(datta))
      .then((res) => {
        if (res.status === 200) {
          window.localStorage.setItem(
            "backChargeData",
            JSON.stringify(res?.data?.data)
          );
          // dispatch(getBackChargeCreditList(state?.id,state?.jobType));
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });

          if (selectedOption === "QUOTEREQUEST") {
            showNewQuoteRequestModal(
              "qr",
              res?.data?.data?.costCodeId,
              res?.data?.data?.jobCodeId,
              res?.data?.data?.jobType,
              res?.data?.data?.id,

              ""
            );
          } else if (selectedOption === "FIELDPURCHASEORDER") {
            showFieldPurchaseOrder(
              "filed",
              res?.data?.data?.costCodeId,
              res?.data?.data?.jobCodeId,
              res?.data?.data?.jobType,
              res?.data?.data?.id
            );
          } else {
            showSupplierCreditModal(
              "supplier",
              res?.data?.data?.costCodeId,
              res?.data?.data?.jobCodeId,
              res?.data?.data?.jobType,
              res?.data?.data?.id
            );
          }
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateModalState({
            ...iModalState,
            addBackChargeModal: true,
            backChargeId: res?.data?.data?.id,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateModalState({ ...iModalState, addBackChargeModal: true });
      });
  };

  const handleCancelBackCharge = (id) => {
    dispatch(deleteBackChargeCredit(id)).then((res) => {
      if (res.status === 200) {
        dispatch(getBackChargeCreditList(state?.id, state?.jobType));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateModalState({
          ...iLeadState,
          BackChargeListingModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const showSelectJobModal = (state) => {
    if (state === "costModal") {
      updateModalState({
        ...iModalState,
        SelectJobModal: true,
        SelectCostModal: false,
        modalState: state,
      });
    } else {
      updateModalState({ ...iModalState, SelectJobModal: true, modalState });
      // setShow(false);
    }
  };

  const hideSelectJobModal = () => {
    if (modalState === "costModal") {
      updateModalState({
        ...iModalState,
        SelectJobModal: false,
        SelectCostModal: true,
        modalState: "",
      });
    } else {
      updateModalState({
        ...iModalState,
        SelectJobModal: false,
        modalState: "",
      });
      // setShow(true);
    }
  };

  const showSelectCostModal = (modalState) => {
    dispatch(getAllJobListAction(state?.id ?? state?.ProjectId ?? ProjectId));
    if (modalState === "ListingQuoteRequest") {
      updateModalState({ ...iModalState, SelectCostModal: true, modalState });
    } else if (modalState === "ListingBillRequest") {
      updateModalState({ ...iModalState, SelectCostModal: true, modalState });
    } else if (modalState === "ListingFieldPurchaseRequest") {
      updateModalState({ ...iModalState, SelectCostModal: true, modalState });
    } else if (modalState === "ListingSupplyCreditRequest") {
      updateModalState({ ...iModalState, SelectCostModal: true, modalState });
    }

    // setShow(false);
  };
  const hideSelectCostModal = () => {
    if (modalState === "ListingQuoteRequest") {
      updateModalState({ ...iModalState, SelectCostModal: false });
    } else {
      updateModalState({ ...iModalState, SelectCostModal: false });
    }
  };
  // console.log("jobCodeId", jobCodeId, "costCodeID", costCodeId, "costCategoryID", costCategoryId)
  const saveJobCOstCOdeID = () => {
    // if (!costCodeId || !costCategoryId) {
    //   errorToast("Please select the complete data first");
    // } else {
    // updateModalState({ ...iModalState, SelectCostModal: false });
    if (modalState === "ListingQuoteRequest") {
      //   state,
      // costCodeIdList,
      // jobIdList,
      // jobTypeList,
      showNewQuoteRequestModal("", costCodeId, state?.id, state?.jobType, "");
    } else if (modalState === "ListingBillRequest") {
      showBillModal("", state?.id, state?.jobType, costCodeId);
    } else if (modalState === "ListingFieldPurchaseRequest") {
      showFieldPurchaseOrder("", state?.id, state?.jobType, costCodeId);
    } else if (modalState === "ListingSupplyCreditRequest") {
      showSupplierCreditModal("", state?.id, state?.jobType, costCodeId);
    }
    // }
  };

  const handleChildData = (data) => {
    dispatch(getListProjectJobs(iBuildLocalData.user.CompanyId));
    updateLeadState({ ...iLeadState, ProjectId: data });
  };

  const handleQuoteRequestCostBreakDownModalShow = (id, modalState) => {
    dispatch(getquoteDetailsAction(id)).then((res) => {
      updateLeadState({ ...iLeadState, quoteDetailsData: res?.data });
    });
    dispatch(getQuoteRequestCostBreakDownDetails(id));
    if (modalState === "biddingQrs") {
      updateModalState({
        ...iModalState,
        QuoteRequestCostBreakDownModal: true,
        BackChargeListingModal: false,
        modalState: modalState,
      });
    } else if (modalState === "biddingQr") {
      updateModalState({
        ...iModalState,
        QuoteRequestCostBreakDownModal: true,
        biddingDetailsModal: false,
        modalState: modalState,
      });
    }
  };

  const handleQuoteRequestCostBreakDownModalClose = () => {
    if (modalState === "biddingQrs") {
      updateModalState({
        ...iModalState,
        QuoteRequestCostBreakDownModal: false,
        BackChargeListingModal: true,
        modalState: "",
      });
    } else if (modalState === "biddingQr") {
      updateModalState({
        ...iModalState,
        QuoteRequestCostBreakDownModal: false,
        biddingDetailsModal: true,
        modalState: "",
      });
    }
  };

  const showUploadAdditionModal = (state, id, type) => {
    // console.log("statteeee", state);
    if (state === "billModal") {
      updateModalState({
        ...iModalState,
        uploadAdditionModal: true,
        BillModal: false,
        modalType: "Bill",
        modalState: state,
      });
    } else if (state === "quoteModal") {
      updateModalState({
        ...iModalState,
        uploadAdditionModal: true,
        NewQuoteRequestModal: false,
        modalState: state,

        modalType: "QuoteRequest",
      });
    } else if (state === "purchase") {
      updateModalState({
        ...iModalState,
        uploadAdditionModal: true,
        PurchaseModal: false,
        modalState: state,

        modalType: "PurchaseOrder",
      });
    } else if (state === "fieldPurchaseOrder") {
      updateModalState({
        ...iModalState,
        uploadAdditionModal: true,
        FieldPurchaseOrderModal: false,
        modalState: state,
        modalType: "FieldPurchaseOrder",
      });
    } else if (state === "supplierCredit") {
      updateModalState({
        ...iModalState,
        uploadAdditionModal: true,
        SupplyCreditModal: false,
        modalState: state,
        modalType: "SupplierCredit",
      });
    }
  };

  const hideUploadAdditionModal = () => {
    if (modalState === "billModal") {
      updateModalState({
        ...iModalState,
        uploadAdditionModal: false,
        BillModal: true,
        modalState: state,
        modalType: "",
      });
    } else if (modalState === "quoteModal") {
      updateModalState({
        ...iModalState,
        uploadAdditionModal: false,
        NewQuoteRequestModal: true,
        modalState: state,

        modalType: "",
      });
    } else if (modalState === "purchase") {
      updateModalState({
        ...iModalState,
        uploadAdditionModal: false,
        PurchaseModal: true,
        modalState: state,

        modalType: "",
      });
    } else if (modalState === "fieldPurchaseOrder") {
      updateModalState({
        ...iModalState,
        uploadAdditionModal: false,
        FieldPurchaseOrderModal: true,
        modalState: state,
        modalType: "",
      });
    } else if (modalState === "supplierCredit") {
      updateModalState({
        ...iModalState,
        uploadAdditionModal: false,
        SupplyCreditModal: true,
        modalState: state,

        modalType: "SupplierCredit",
      });
    }
  };

  const [additionalDocumentsCount, setAdditionalDocumentsCount] = useState(0);
  const addAdditionalDocuments = (e) => {
    e.preventDefault();

    dispatch(
      uploadAdditionalDocsAction(estimationCreateId, modalType, { files })
    ).then((res) => {
      if (res.status === 201) {
        console.log("reess", res);
        setAdditionalDocumentsCount(res.uploadDetails.length);
      }
      hideUploadAdditionModal();
    });
  };

  const sendPOFunc = (e, id, status) => {
    e.preventDefault();

    dispatch(updatePurchaseDetailsAction(id, { status })).then((res) => {
      // console.log("ressss", res)
      sucessToast("PO has been sent successfully");
      dispatch(getAllPurchaseListAction(costCodeId));
    });
  };

  const cancelPOFunc = (id) => {
    dispatch(updatePurchaseDetailsAction(id, { status: "CANCELLED" })).then(
      (res) => {
        // console.log("ressss", res)
        sucessToast("PO has been cancelled successfully");
        dispatch(getAllPurchaseListAction(costCodeId));
      }
    );
  };

  const showPurchaseViewCostBreakdownModal = (ele, state) => {
    if (state === "purchase") {
      dispatch(getAllPurchaseDetailsAction(ele)).then((res) => {
        updateLeadState({ ...iLeadState, quoteDetailsData: res?.data[0] });
      });
    } else if (state === "FPO") {
      dispatch(getAllFieldPurchaseDetailsAction(ele)).then((res) => {
        updateLeadState({ ...iLeadState, quoteDetailsData: res?.data[0] });
      });
    } else if (state === "SCO") {
      dispatch(getAllSupplierCreditDetailsAction(ele)).then((res) => {
        updateLeadState({ ...iLeadState, quoteDetailsData: res?.data[0] });
      });
    }
    updateModalState({
      ...iModalState,
      purchaseViewCostBreakdownModal: true,
      biddingDetailsModal: false,
    });
  };

  const hidePurchaseViewCostBreakdownModal = () => {
    updateModalState({
      ...iModalState,
      purchaseViewCostBreakdownModal: false,
      biddingDetailsModal: true,
    });
  };

  const sendStatusFunc = (id, status) => {
    const datata = { status: status };
    dispatch(updateQuoteRequestAction(id, datata)).then((res) => {
      dispatch(getAllBillListAction());
      sucessToast("Quote status has been changed successfully");
      // dispatch(getAllQuoteRequestAction());
      setVendorEditToggle(false);
      updateModalState({ ...iModalState, quoteDetailsModal: false });
    });
  };

  const sendStatusFuncs = async (id) => {
    try {
      // Calculate subtotal, GST, and grand total once to avoid redundant calculations
      const subtotal =
        itemDetails?.reduce(
          (total, data) => total + data.quantity * data.price,
          0
        ) || 0;
      const gst = quoteDetailsData?.TaxProfile?.taxRows?.[0]?.taxPercent || 0;
      const gstTotal = (subtotal * gst) / 100;

      const additionalGST = quoteDetailsData?.gst || 0;
      const additionalGSTTotal = (subtotal * additionalGST) / 100;

      const grandTotal = String(subtotal + gstTotal + additionalGSTTotal);

      const dataa = {
        itemDetails: itemDetails?.map((item) => ({
          parentTableName: "QUOTEREQUEST",
          color: item.color,
          quantity: item.quantity,
          unitId: item.unitId,
          price: item.price,
          total: String(item.quantity * item.price),
          name: item.name,
        })),
        subtotal,
        gst,
        grandTotal,
      };

      const res = await dispatch(qouteRequestByVendors(id, dataa));
      if (res.status === 200) {
        sucessToast(res.data.message);
        updateModalState({ ...iModalState, quoteDetailsModal: false });
        setVendorEditToggle(false);
        updateState({
          ...iState,
          itemDetails: [
            {
              parentTableName: "QUOTEREQUEST",
              color: "",
              quantity: null,
              unitId: null,
              price: "",
              total: "",
              name: "",
            },
          ],
        });
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const sendBuilderItems = async (id) => {
    try {
      const subtotal =
        itemDetailss?.reduce(
          (total, data) => total + data.quantity * data.price,
          0
        ) || 0;
      const gst = quoteDetailsData?.TaxProfile?.taxRows?.[0]?.taxPercent || 0;
      const grandTotal = subtotal + (subtotal * gst) / 100;

      const datata = {
        itemDetails: itemDetailss?.map((item) => {
          return {
            parentTableName: "QUOTEREQUEST",
            color: item.color,
            quantity: item.quantity,
            unitId: item.unitId,
            price: item.price,
            total: String(item.quantity * item.price),
            name: item.name,
          };
        }),
        subtotal: subtotal,
        gst: gst,
        grandTotal: grandTotal,
      };

      const res = await dispatch(updateQuoteRequestAction(id, datata));

      await dispatch(getAllBillListAction());
      sucessToast("Quote status has been changed successfully");

      setVendorEditToggle(false);
      updateModalState({ ...iModalState, quoteDetailsModal: false });
      updateState({
        ...iState,
        itemDetailss: [
          {
            parentTableName: "QUOTEREQUEST",
            color: "",
            quantity: null,
            unitId: null,
            price: "",
            total: "",
            name: "",
          },
        ],
      });
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const changeBillStatusFunc = (id, status) => {
    dispatch(updateBillAction(id, { status: status })).then((res) => {
      // console.log("ressss", res)
      sucessToast("Bill status has been changed successfully");
      dispatch(getAllBillListAction());
    });
  };
  const showFieldPurchaseOrderDetailsModal = (modalState, id) => {
    dispatch(getAllFieldPurchaseDetailsAction(id)).then((res) => {
      updateLeadState({ ...iLeadState, quoteDetailsData: res?.data[0] });
    });
    if (modalState === "biddingDetaiModal") {
      updateModalState({
        ...iModalState,
        fieldPurchaseOrderDetailsModal: true,
        biddingDetailsModal: false,
        modalState: modalState,
      });
    }
  };

  const hideFieldPurchaseOrderDetailsModal = () => {
    if (modalState === "biddingDetaiModal") {
      updateModalState({
        ...iModalState,
        biddingDetailsModal: true,
        fieldPurchaseOrderDetailsModal: false,
        modalState: "",
      });
    }
  };

  const showSupplierCreditDetailsModal = (modalState, id) => {
    dispatch(getAllSupplierCreditDetailsAction(id)).then((res) => {
      updateLeadState({ ...iLeadState, quoteDetailsData: res?.data[0] });
    });
    if (modalState === "biddingDetaiModal") {
      updateModalState({
        ...iModalState,
        biddingDetailsModal: false,
        supplierCreditOrderDetailsModal: true,
        modalState: modalState,
      });
    }
  };

  const hideSupplierCreditDetailsModal = () => {
    if (modalState === "biddingDetaiModal") {
      updateModalState({
        ...iModalState,
        supplierCreditOrderDetailsModal: false,
        modalState: "",
        biddingDetailsModal: true,
      });
    }
  };

  const changeFieldPurchaseOrderStatusFunc = (e, id, status) => {
    e.preventDefault();
    dispatch(updateFieldPurchaseOrderAction({ status }, id)).then((res) => {
      // console.log("reseesseesse", res)
      dispatch(getAllFieldPurchaseOrderAction(costCodeId));
    });
  };

  const changeSupplierCreditStatusFunc = (e, id, status) => {
    e.preventDefault();
    dispatch(updateSupplierCreditAction({ status }, id)).then((res) => {
      //  console.log("reseesseesse", res)
      dispatch(getAllSupplierCreditOrderAction(costCodeId));
    });
  };

  const hideBudgetChangeOrderListingModal = () => {
    updateModalState({ ...iModalState, budgetChangeOrderListingModal: false });
  };
  const [costCodes, setCostCodes] = useState("");
  const showBudgetChangeOrderListingModal = (ele) => {
    dispatch(getAllModalBudgetViewListAction());
    updateModalState({
      ...iModalState,
      budgetChangeOrderListingModal: true,
      modalState: ele,
    });
    setCostCodes(`${ele.number ?? ""} ${ele.name ?? ""}`);
  };

  const showScopeWorkIdModal = (modalState, secondaryState) => {
    dispatch(getAllScopeWorkIdListAction(state?.id));
    dispatch(getReleasedConstructionDocs(state?.id));
    dispatch(
      getAllEstimationDocsListingAction(
        estimationCreateId,
        modalState,
        secondaryState
      )
    );

    if (modalState === "quoteRequest") {
      updateModalState({
        ...iModalState,
        scopeWorkIdModal: true,
        modalState,
        NewQuoteRequestModal: false,
        secondaryState,
      });
    } else if (modalState === "purchaseOrder") {
      updateModalState({
        ...iModalState,
        scopeWorkIdModal: true,
        modalState,
        PurchaseModal: false,
        secondaryState,
      });
    } else if (modalState == "supplierCredit") {
      updateModalState({
        ...iModalState,
        scopeWorkIdModal: true,
        modalState,
        SupplyCreditModal: false,
        secondaryState,
      });
    } else if (modalState == "fieldPurchaseOrder") {
      updateModalState({
        ...iModalState,
        scopeWorkIdModal: true,
        modalState,
        FieldPurchaseOrderModal: false,
        secondaryState,
      });
    } else if (modalState == "massMailout") {
      updateModalState({
        ...iModalState,
        scopeWorkIdModal: true,
        modalState,
        BillModal: false,
        secondaryState,
      });
    }
  };

  const hideScopeWorkIdModal = () => {
    if (modalState === "quoteRequest") {
      updateModalState({
        ...iModalState,
        scopeWorkIdModal: false,
        modalState,
        NewQuoteRequestModal: true,
        secondaryState: "",
      });
    } else if (modalState === "purchaseOrder") {
      updateModalState({
        ...iModalState,
        scopeWorkIdModal: false,
        modalState,
        PurchaseModal: true,
        secondaryState: "",
      });
    } else if (modalState == "supplierCredit") {
      updateModalState({
        ...iModalState,
        scopeWorkIdModal: false,
        modalState,
        SupplyCreditModal: true,
        secondaryState: "",
      });
    } else if (modalState == "fieldPurchaseOrder") {
      updateModalState({
        ...iModalState,
        scopeWorkIdModal: false,
        modalState,
        FieldPurchaseOrderModal: true,
        secondaryState: "",
      });
    } else if (modalState == "massMailout") {
      updateModalState({
        ...iModalState,
        scopeWorkIdModal: false,
        modalState,
        BillModal: true,
        secondaryState: "",
      });
    }
  };

  const showRequestLimitIncresaseModal = (modalState) => {
    dispatch(getUserLimitDetailsAction(ProjectId));
    dispatch(getAllScopeWorkIdListAction(ProjectId));
    dispatch(getAllStaffListAction());
    if (modalState === "quoteRequest") {
      updateModalState({
        ...iModalState,
        requestLimitIncresaseModal: true,
        modalState,
        NewQuoteRequestModal: false,
        secondaryState,
      });
    } else if (modalState === "purchaseOrder") {
      updateModalState({
        ...iModalState,
        requestLimitIncresaseModal: true,
        modalState,
        PurchaseModal: false,
      });
    } else if (modalState == "supplierCredit") {
      updateModalState({
        ...iModalState,
        requestLimitIncresaseModal: true,
        modalState,
        SupplyCreditModal: false,
      });
    } else if (modalState == "fieldPurchaseOrder") {
      updateModalState({
        ...iModalState,
        requestLimitIncresaseModal: true,
        modalState,
        FieldPurchaseOrderModal: false,
      });
    }
  };

  const hideRequestLimitIncresaseModal = () => {
    if (modalState === "quoteRequest") {
      updateModalState({
        ...iModalState,
        requestLimitIncresaseModal: false,
        modalState,
        NewQuoteRequestModal: true,
      });
    } else if (modalState === "purchaseOrder") {
      updateModalState({
        ...iModalState,
        requestLimitIncresaseModal: false,
        modalState,
        PurchaseModal: true,
      });
    } else if (modalState == "supplierCredit") {
      updateModalState({
        ...iModalState,
        requestLimitIncresaseModal: false,
        modalState,
        SupplyCreditModal: true,
      });
    } else if (modalState == "fieldPurchaseOrder") {
      updateModalState({
        ...iModalState,
        requestLimitIncresaseModal: false,
        modalState,
        FieldPurchaseOrderModal: true,
      });
    }
  };

  const increaseRequestLimitFunc = (e) => {
    e.preventDefault();
    let data = {
      approverUserId,
      increaseAmount: requestIncreaseAmount,
      increaseReason,
      requestUserId: iBuildLocalData?.user?.id,
    };

    dispatch(requestLimitIncreaseAction(data)).then((res) => {
      // console.log("resss",res)
      sucessToast("Limit increased successfully");
      hideRequestLimitIncresaseModal();
    });
  };

  useEffect(() => {
    if (ProjectId) {
      dispatch(getAllScopeWorkIdListAction(ProjectId));
    }
  }, [ProjectId]);

  const saveEstimationDocsFunc = (e) => {
    e.preventDefault();

    const data = {
      supportDocumentId,
      parentTableId: estimationCreateId,
      parentTableName: modalState,
      for: secondaryState,
    };

    dispatch(addEstimationDocumentAction(data))
      .then((res) => {
        if (res.status === 200) {
          return dispatch(
            getAllEstimationDocsListingAction(
              estimationCreateId,
              modalState,
              secondaryState
            )
          );
        } else {
          throw new Error("Failed to add document");
        }
      })
      .then((response) => {
        if (secondaryState === "work") {
          setScopeData(response.data);
        } else {
          setConstructData(response.data);
        }

        updateLeadState({ ...iLeadState, supportDocumentId: "" });
      })
      .catch((err) => {
        console.error("Error:", err);
        // Optionally, you can show an error toast or message here
      });
  };

  //      const saveEstimationDocsConstructionFunc = ( e) => {
  //       e.preventDefault();
  //       let data = {
  //        supportDocumentId,
  //        parentTableId:estimationCreateId,
  //        parentTableType:modalType,
  //       for:"construction"
  //       }
  //       dispatch(addEstimationDocumentAction(data)).then((res) => {
  //         console.log("resss",res)
  //         dispatch(getAllEstimationDocsListingAction(estimationCreateId,modalType,"work"))

  //       })
  // }

  const removeEstimationDocsFunc = (e, id) => {
    e.preventDefault();
    dispatch(removeEstimationDocsListingAction(id)).then((res) => {
      // console.log("resss",res)
      dispatch(
        getAllEstimationDocsListingAction(
          estimationCreateId,
          modalState,
          secondaryState
        )
      );
    });
  };

  let handleValidationDiscussion = () => {
    let discussionError = "";
    let formIsValid = true;

    if (discussionState.message == "") {
      discussionError = "* Please enter comment";
      formIsValid = false;
    }

    updateDiscussionState({
      ...discussionState,
      discussionError: discussionError,
    });
    return formIsValid;
  };

  const handleAddDiscussion = () => {
    let formIsValid = handleValidationDiscussion();
    if (formIsValid) {
      dispatch(
        AddDiscussionAction({
          message: discussionState.message,
          jobId: jobCodeId,
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(discussionListAction({ jobId: jobCodeId }));

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleAddReply = () => {
    let formIsValid = true;
    if (formIsValid) {
      dispatch(
        AddReplyAction({
          message: replyState.message,
          jobId: replyState.jobId,
          messageId: replyState.messageId,
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(discussionListAction({ jobId: jobCodeId }));
            updateReplyState({
              messageId: "",
              jobId: "",
              message: "",
              replyError: "",
            });

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const hideCOstFUnc = (id) => {
    dispatch(hideCostAction([id]))
      .then((res) => {
        // console.log("resss",res)
        dispatch(
          getAllBudgetListingAction({
            jobs: [
              {
                id: state?.id,
                type: state?.jobType,
              },
            ],
          })
        );
        sucessToast("Cost hidden successfully");
      })
      .catch((err) => {
        // console.log("errrrrr", err)
      });
  };

  const changeBudgetStatusFunc = (id, status) => {
    // console.log("dataaaaaaaaaaa,", id , status)
    dispatch(updateBudgetChangeOrderAction({ status: status }, id)).then(
      (res) => {
        // console.log("ressssss", res)
        dispatch(getAllModalBudgetViewListAction());
      }
    );
  };

  const showBudgetChangeOrderDetailsModal = (id) => {
    dispatch(budgetchangeDetailsAction(id)).then((res) => {
      updateLeadState({ ...iLeadState, budgetChangeOrderDetailsData: res });
    });
    updateModalState({
      ...iModalState,
      budgetChangeOrderDetailsModal: true,
      budgetChangeOrderListingModal: false,
    });
  };

  const hideBudgetChangeOrderDetailsModal = () => {
    updateModalState({
      ...iModalState,
      budgetChangeOrderDetailsModal: false,
      budgetChangeOrderListingModal: true,
    });
  };

  const showUpdateBudgetChangeOrderModal = (
    id,
    jobCodeId,
    jobType,
    costCodeId
  ) => {
    dispatch(getAllReasonListAction());
    dispatch(getAllBuilderSigneeAction());
    dispatch(VendorSearchAction()).then((res) => {
      updateLeadState({
        ...iLeadState,
        vendorSearchData: res.vendors,
        jobCodeId,
        jobType,
        costCodeId,
      });
    });
    // dispatch(getAllQuoteRequestAction(costCodeIdList,jobIdList,jobTypeList));
    updateModalState({
      ...iModalState,
      updateBudgetChangeOrderModal: true,
      budgetChangeOrderListingModal: false,
      modalId: id,
    });
  };

  const hideUpdateBudgetChangeOrderModal = () => {
    updateModalState({
      ...iModalState,
      updateBudgetChangeOrderModal: false,
      budgetChangeOrderListingModal: true,
    });
  };

  const updateBudgetChangeOrderFunc = () => {
    let data = {
      documentDescription,
      increaseAmount,
      email: InvesterEmail,
      InvesterfullName,

      authorisationType: "Hardcopy",
      vendorId,
      builderSigneeId: 5,
      relatedDocumentId: 7,
      costCodeId,
      jobCodeId,
      jobType,
      reasonId,
    };
    dispatch(updateBudgetChangeOrderAction(data, modalId)).then((res) => {
      console.log("resssssss", res);
      sucessToast("Budget change order successfully");
      hideUpdateBudgetChangeOrderModal();
    });
  };

  const showEstimationHistoryModal = (id, name) => {
    dispatch(estimationHistoryAction(id)).then((res) => {
      console.log("resss", res);
      updateLeadState({ ...iLeadState, estimationHistoryData: res?.events });
    });
    updateModalState({
      ...iModalState,
      estimationHistoryModal: true,
      requiredName: name,
    });
  };

  const hideEstimationHistoryModal = (id, name) => {
    updateModalState({
      ...iModalState,
      estimationHistoryModal: false,
      requiredName: "",
    });
  };

  const showChangeStatusModal = (id, name) => {
    updateModalState({
      ...iModalState,
      changeStatusModal: true,
      modalId: id,
    });
  };

  const hideChangeStatusModal = (id, name) => {
    updateModalState({
      ...iModalState,
      changeStatusModal: false,
      requiredName: "",
    });
  };

  const changeEstimationStatusFunc = (e) => {
    e.preventDefault();
    let data = {
      status,
      id: modalId,
    };

    dispatch(changeEstimationStatusAction(data)).then((res) => {
      sucessToast("Status updated successfully");
      hideChangeStatusModal();
    });
  };

  const showUpdateBudgetChangeDocsModal = (id) => {
    updateModalState({
      ...iModalState,
      updateBudgetChangeDocsModal: true,
      modalId: id,
    });
  };

  const hideUpdateBudgetChangeDocsModal = (id, name) => {
    updateModalState({
      ...iModalState,
      updateBudgetChangeDocsModal: false,
    });
  };

  const uploadBudgetChangeOrderFunc = (e) => {
    e.preventDefault();
    dispatch(uploadBudgetChangeOrderDocsAction(modalId, { file })).then(
      (res) => {
        sucessToast("Agreement updated successfully");

        hideUpdateBudgetChangeDocsModal();
      }
    );
  };

  const showAgreementDetailsModal = (id) => {
    dispatch(budgetchangeDetailsAction(id)).then((res) => {
      updateLeadState({ ...iLeadState, budgetChangeOrderDetailsData: res });
    });
    updateModalState({
      ...iModalState,
      agreementDetailsModal: true,
      budgetChangeOrderListingModal: false,
      modalId: id,
    });
  };

  const hideAgreementDetailsModal = (id, name) => {
    updateModalState({
      ...iModalState,
      agreementDetailsModal: false,
      budgetChangeOrderListingModal: true,
    });
  };

  const showBudgetSummaryModal = () => {
    updateModalState({ ...iModalState, budgetSummaryModal: true });
  };
  const hideBugetSummaryModal = () => {
    updateModalState({ ...iModalState, budgetSummaryModal: false });
  };

  const handleViewQuoteRequestCostBreakDownModalShow = (id) => {
    updateModalState({
      ...iModalState,
      ViewQuoteRequestCostBreakDownModal: true,
      BackChargeListingModal: false,
    });
    dispatch(getQuoteRequestCostBreakDownDetails(id));
  };

  const handleViewQuoteRequestCostBreakDownModalClose = () => {
    updateModalState({
      ...iModalState,
      ViewQuoteRequestCostBreakDownModal: false,
      BackChargeListingModal: true,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  const handleViewSupplierCreditModalShow = () => {
    updateModalState({
      ...iModalState,
      ViewSupplierCreditModal: true,
      BackChargeListingModal: false,
    });
  };

  const handleViewSupplierCreditModalClose = () => {
    updateModalState({
      ...iModalState,
      ViewSupplierCreditModal: false,
      BackChargeListingModal: true,
    });
  };

  const handleVendorBackChargeNotificationModalShow = (type, data) => {
    // updateModalState({
    //   ...iModalState,
    //   VendorBackChargeNotificationModal: true,
    //   NewQuoteRequestModal: false,
    //   backChargevendorId:backChargeDatas?.backChargeVendorId,
    //   backChargeDocumentId:backChargeDatas?.id
    // });
    if (type === "list") {
      updateModalState({
        ...iModalState,
        VendorBackChargeNotificationModal: true,
        BackChargeListingModal: false,
        modalState: type,
      });
      updateLeadState({
        ...iLeadState,
        backChargevendorId: data?.backChargeVendorId,
        backChargeDocumentId: data?.id,
        vendorNames: data?.Vendor?.vendorName,
      });
    } else if (type === "list1") {
      const backChargeDatas = JSON.parse(
        localStorage.getItem("backChargeData")
      );

      var backVendId = backChargeDatas?.backChargeVendorId;
      var backDocId = backChargeDatas?.id;

      updateModalState({
        ...iModalState,
        VendorBackChargeNotificationModal: true,
        NewQuoteRequestModal: false,
        modalState: type,
      });
      dispatch(vendorDetailsAction(backChargeDatas?.backChargeVendorId)).then(
        (res) => {
          if (res) {
            const vendorNameData = res?.details?.vendorName;
            updateLeadState((prevState) => ({
              ...prevState,
              backChargevendorId: backVendId,
              backChargeDocumentId: backDocId,
              vendorNames: vendorNameData,
            }));
          }
        }
      );
    }
  };

  const handleVendorBackChargeNotificationModalClose = () => {
    if (modalState === "list") {
      updateModalState({
        ...iModalState,
        VendorBackChargeNotificationModal: false,
        BackChargeListingModal: true,
        modalState,
      });
    } else if (modalState === "list1") {
      updateModalState({
        ...iModalState,
        VendorBackChargeNotificationModal: false,
        NewQuoteRequestModal: true,
        modalState,
      });
    }
  };

  const handleCreateBackChargeNotification = () => {
    const formData = new FormData();
    formData.append("message", messages || "");
    formData.append("estimatedValue", estimatedValue || "");
    formData.append("backChargevendorId", backChargevendorId);
    formData.append("backChargeDocumentId", backChargeDocumentId);
    Array.from(supportingDocFiles).forEach((image) => {
      formData.append("supportingDocFiles", image);
    });

    dispatch(createBackChargeNotification(formData)).then((res) => {
      if (res.status == 201) {
        toast.success("Send Notification Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateModalState({
          ...iModalState,
          VendorBackChargeNotificationModal: false,
          BackChargeListingModal: true,
        });
        updateLeadState({
          ...iLeadState,
          messages: "",
          estimatedValue: "",
          supportingDocFiles: [],
          originalDocumentId: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateModalState({
          ...iModalState,
          VendorBackChargeNotificationModal: true,
        });
      }
    });
  };

  useEffect(() => {
    if (DiscussionSettingsModal) {
      dispatch(getWarrantySettingDiscussion("Project", state?.ProjectId)).then(
        (res) => {
          if (res.status === 200) {
            updateState({
              ...iState,
              discussionData: res.data,
              visibleInTradeView: res.data.settings?.visibleInTradeView,
              visibleInInvestorView: res.data.settings?.visibleInInvestorView,
              visibleInClientView: res.data.settings?.visibleInClientView,
            });
          }
        }
      );
    }
  }, [DiscussionSettingsModal]);

  const handleDiscussionSettingsModalShow = () => {
    updateState({ ...iState, DiscussionSettingsModal: true });
  };
  const handleDiscussionSettingsModalClose = () => {
    updateState({ ...iState, DiscussionSettingsModal: false });
  };

  const handleDiscussionCheckBox = (type) => {
    if (type === "client") {
      updateState({
        ...iState,
        visibleInClientView: !visibleInClientView,
      });
    } else if (type === "trade") {
      updateState({
        ...iState,
        visibleInTradeView: !visibleInTradeView,
      });
    } else {
      updateState({
        ...iState,
        visibleInInvestorView: !visibleInInvestorView,
      });
    }
  };

  const handleDiscussionSettingUpdate = () => {
    const dataa = {
      visibleInClientView,
      visibleInTradeView,
      visibleInInvestorView,
      discussableId: discussionData?.settings?.id,
      discussableType: "Project",
    };
    dispatch(updateWarrantySettingDiscussion(dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(getWarrantySettingDiscussion("Project", state?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          DiscussionSettingsModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          DiscussionSettingsModal: true,
        });
      }
    });
  };

  const [includedConsultantt, setIncludedConsultantt] = useState([]);
  const [FilterModal, setFilterModal] = useState(false);

  const handleFilterModalShow = () => {
    setFilterModal(true);
    setIncludedConsultantt([]);
  };

  const handleFilterModalClose = () => {
    setFilterModal(false);
    setIncludedConsultantt([]);
  };

  const consultModule = [
    { value: "description", label: "Cost Area Descripion" },
    { value: "Proforma", label: "Proforma Budget" },
    { value: "Budget", label: "Budget Change Order(CO)" },
    { value: "Bill", label: "Bill/Quote(QR) Total" },
    { value: "WorkingBudget", label: "Working Budget" },
    { value: "BillBudget", label: "Bill/Budget(PO)" },
    { value: "ProformaBudget", label: "Proforma Budget" },
    { value: "PreTaxSupplierTotal", label: "Pre-Tax Supplier Total" },
    { value: "Variance", label: "Variance" },
    { value: "Status", label: "All Area Cost Code Status" },
    { value: "OutstandingCostCodes", label: "Outstanding Cost Codes" },
  ];

  const handleConsChange = (selectedOption) => {
    setIncludedConsultantt(selectedOption);
  };

  const handleApply = () => {
    setFilterModal(false);
  };

  const [GearIconSettingModalShow, SetGearIconSettingModalShow] =
    useState(false);

  const handleSelectJob = (e, item) => {
    {
      if (e.target.checked) {
        updateJobListState([
          ...jobListState,
          { id: item?.id, type: item?.jobType },
        ]);
      } else {
        let temp = [...jobListState];
        let index = temp.findIndex((x) => x.id === item?.id);

        temp.splice(index, 1);

        updateJobListState(temp);
      }

      dispatch(getAllBudgetListingAction({ jobs: jobListState }));
    }
  };

  const handleBackChargeSync = (id) => {
    const dataa = { status: true };
    dispatch(syncBackCharges(id, dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(getBackChargeCreditList(state?.id, state?.jobType));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const showNARowsModal = () => {
    dispatch(hddenCostCodesAPiList(state?.id, state?.jobType)).then((res) => {
      console.log("ressss", res);
      if (res?.status === 404) {
        errorToast(res?.data?.message);
      } else {
        updateLeadState({ ...iLeadState, hiddeCostCodeData: res?.data?.data });
      }
    });
    updateModalState({ ...iModalState, NARowsModal: true });
  };

  const hideNARowsModal = () => {
    updateModalState({ ...iModalState, NARowsModal: false });
  };
  const handleSystemVendorModalBackChargeShow = () => {
    updateModalState({
      ...iModalState,
      SystemVendorModalBackCharge: true,
      addBackChargeModal: false,
    });
  };
  const handleSystemVendorModalBackChargeClose = () => {
    updateModalState({
      ...iModalState,
      SystemVendorModalBackCharge: false,
      addBackChargeModal: true,
    });
  };

  const handleFileChanges = (event, type) => {
    const files = event?.target?.files;
    if (files) {
      if (type === "docsUpdate") {
        updateLeadState({ ...iLeadState, supportingDocFiles: [...files] });
      }
    }
  };

  const handleRemoveDocsFile = (i) => {
    let user = [...supportingDocFiles];
    user.splice(i, 1);
    updateLeadState({
      ...iLeadState,
      supportingDocFiles: user,
    });
  };
  useEffect(() => {
    if (VendorBackChargeNotificationModal) {
      const backChargeDatas = JSON.parse(
        localStorage.getItem("backChargeData")
      );
      dispatch(getBackChargeRelatedDocumnets(backChargeDatas?.id));
    }
  }, [VendorBackChargeNotificationModal]);

  const showAddTaxProfileModal = () => {
    updateModalState({ ...iModalState, addTaxProfileModal: true });
  };
  const hideAddTaxProfileModal = () => {
    updateModalState({ ...iModalState, addTaxProfileModal: false });
  };

  const addTaxProfileFunc = (e) => {
    e.preventDefault();

    updateLeadState({
      ...iLeadState,
      taxAmount: Number(subtotal) * Number(taxPercentage / 100),
      showTaxFlag: true,
    });
    hideAddTaxProfileModal();
  };

  const removeTaxProfileFunc = () => {
    updateLeadState({
      ...iLeadState,
      taxAmount: 0,
      showTaxFlag: false,
    });
    hideAddTaxProfileModal();
  };

  let totalTaxPercent = profileListData?.[0]?.taxRows?.reduce(
    (acc, obj) => acc + obj.taxPercent,
    0
  );

  let totalBilltaxAmount = billDataCategorySubtotal * (totalTaxPercent / 100);

  const handleBackChargeNotificationListModalShow = (data, modalState) => {
    if (modalState === "list") {
      dispatch(getMessageList(data.id));
      updateModalState({
        ...iModalState,
        BackChargeNotificationListModal: true,
        BackChargeListingModal: false,
        modalState: modalState,
      });
      updateLeadState({
        ...iLeadState,
        backChargevendorId: data?.backChargeVendorId,
        backChargeDocumentId: data?.id,
        vendorNames: data?.Vendor?.vendorName,
      });
    } else if (modalState === "qrDetail") {
      dispatch(getMessageList(data.id));
      updateModalState({
        ...iModalState,
        BackChargeNotificationListModal: true,
        quoteDetailsModal: false,
        modalState: modalState,
      });
      updateLeadState({
        ...iLeadState,
        backChargevendorId: data?.backChargeVendorId,
        backChargeDocumentId: data?.id,
        vendorNames: data?.Vendor?.vendorName,
      });
    } else if (modalState === "poDetail") {
      dispatch(getMessageList(data.id));
      updateModalState({
        ...iModalState,
        BackChargeNotificationListModal: true,
        purchaseDetailsModal: false,
        modalState: modalState,
      });
      updateLeadState({
        ...iLeadState,
        backChargevendorId: data?.backChargeVendorId,
        backChargeDocumentId: data?.id,
        vendorNames: data?.Vendor?.vendorName,
      });
    } else if (modalState === "fpoDetail") {
      dispatch(getMessageList(data.id));
      updateModalState({
        ...iModalState,
        BackChargeNotificationListModal: true,
        fieldPurchaseOrderDetailsModal: false,
        modalState: modalState,
      });
      updateLeadState({
        ...iLeadState,
        backChargevendorId: data?.backChargeVendorId,
        backChargeDocumentId: data?.id,
        vendorNames: data?.Vendor?.vendorName,
      });
    } else if (modalState === "scDetail") {
      dispatch(getMessageList(data.id));
      updateModalState({
        ...iModalState,
        BackChargeNotificationListModal: true,
        supplierCreditOrderDetailsModal: false,
        modalState: modalState,
      });
      updateLeadState({
        ...iLeadState,
        backChargevendorId: data?.backChargeVendorId,
        backChargeDocumentId: data?.id,
        vendorNames: data?.Vendor?.vendorName,
      });
    }
  };
  const handleBackChargeNotificationListModalClose = () => {
    if (modalState === "list") {
      updateModalState({
        ...iModalState,
        BackChargeNotificationListModal: false,
        BackChargeListingModal: true,
        modalState: "",
      });
    } else if (modalState === "qrDetail") {
      updateModalState({
        ...iModalState,
        BackChargeNotificationListModal: false,
        quoteDetailsModal: true,
        modalState: "",
      });
    } else if (modalState === "poDetail") {
      updateModalState({
        ...iModalState,
        BackChargeNotificationListModal: false,
        purchaseDetailsModal: true,
        modalState: "",
      });
    } else if (modalState === "fpoDetail") {
      updateModalState({
        ...iModalState,
        BackChargeNotificationListModal: false,
        fieldPurchaseOrderDetailsModal: true,
        modalState: "",
      });
    }
    if (modalState === "scDetail") {
      updateModalState({
        ...iModalState,
        BackChargeNotificationListModal: false,
        supplierCreditOrderDetailsModal: true,
        modalState: "",
      });
    }
  };

  const handleResendMeassage = (item, idd) => {
    const datta = {
      backChargevendorId: item?.backChargevendorId,
      originalDocumentId: item?.originalDocumentId,
      message: item?.message,
      estimatedValue: item?.estimatedValue,
    };
    dispatch(resendMessage(item?.id, datta)).then((res) => {
      if (res.status === 200) {
        dispatch(getMessageList(idd));
        toast.success("Re-Send Notification Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleViewMessageModalShow = (item, data) => {
    updateModalState({
      ...iModalState,
      BackChargeNotificationListModal: false,
      ViewMessageModal: true,
    });

    updateLeadState({
      ...iLeadState,
      messageDetailData: item,
      messageDetailRemData: data,
    });
  };

  const handleViewMessageModalClose = () => {
    updateModalState({
      ...iModalState,
      BackChargeNotificationListModal: true,
      ViewMessageModal: false,
    });
  };

  const handleCancelBackCharges = (id) => {
    dispatch(cancelBackCharge(id)).then((res) => {
      if (res.status === 200) {
        dispatch(getMessageList(id));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleVendorBackChargeNotificationReplyModalShow = () => {
    updateModalState({
      ...iModalState,
      VendorBackChargeNotificationReplyModal: true,
      BackChargeNotificationListModal: false,
    });
    updateLeadState({
      ...iLeadState,
      backChargevendorId,
      backChargeDocumentId,
      vendorNames,
    });
    dispatch(getRelatedDocumnets(backChargevendorId));
  };

  const handleVendorBackChargeNotificationReplyModalClose = () => {
    updateModalState({
      ...iModalState,
      VendorBackChargeNotificationReplyModal: false,
      BackChargeNotificationListModal: true,
    });
  };

  const handleCreateBackChargeNotificationReply = () => {
    const formData = new FormData();
    formData.append("message", messages || "");
    formData.append("estimatedValue", estimatedValue || "");
    formData.append("backChargevendorId", backChargevendorId);
    formData.append("backChargeDocumentId", backChargeDocumentId);
    formData.append("originalDocumentId", originalDocumentId || "");
    formData.append("originalDocumentType", originalDocumentType || "");
    Array.from(supportingDocFiles).forEach((image) => {
      formData.append("supportingDocFiles", image);
    });

    dispatch(createBackChargeNotification(formData)).then((res) => {
      if (res.status == 201) {
        console.log("resss", res);
        dispatch(getMessageList(res.data.data.backChargeDocumentId));
        toast.success("Send Reply Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateModalState({
          ...iModalState,
          VendorBackChargeNotificationReplyModal: false,
          BackChargeNotificationListModal: true,
        });
        updateLeadState({
          ...iLeadState,
          messages: "",
          estimatedValue: "",
          supportingDocFiles: "",
          originalDocumentId: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateModalState({
          ...iModalState,
          VendorBackChargeNotificationReplyModal: true,
        });
      }
    });
  };

  const handleToConvertFPOSC = (id, type) => {
    if (type === "fpo") {
      const datta = { backChargeId: id };
      dispatch(createBackChargeFieldPOOrder(datta)).then((res) => {
        if (res.status === 201) {
          toast.success("Field Purchase Order Created Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    } else {
      const datta = { backChargeId: id };
      dispatch(createBackChargeSupplierCredits(datta)).then((res) => {
        if (res.status === 201) {
          toast.success("Supplier Credit Created Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const saveUhideCoseIdFunc = (e, id) => {
    // const {name,value} = e.target;
    updateLeadState({
      ...iLeadState,
      saveUnhideCostId: [...saveUnhideCostId, id],
    });
  };

  const unhideCostCodeFunc = (id) => {
    dispatch(hideCostCodes(saveUnhideCostId)).then((res) => {
      dispatch(hddenCostCodesAPiList(state?.id, state?.jobType)).then((res) => {
        updateLeadState({ ...iLeadState, hiddeCostCodeData: res?.data?.data });
      });
    });
  };
  const [profiles, setProfiles] = useState("");
  const [taxNames, setTaxNames] = useState("");
  const [taxPercentages, setTaxPercentage] = useState("");

  useEffect(() => {
    if (profileListData && profileListData.length > 0) {
      const defaultProfile = profileListData.find(
        (ele) => ele.defaultCheck === true
      );
      if (defaultProfile) {
        setProfiles(defaultProfile.id);
        setTaxNames(defaultProfile.taxRows?.[0]?.taxName || "");
        setTaxPercentage(defaultProfile.taxRows?.[0]?.taxPercent || 0);
      }
    }
  }, [profileListData]);

  const handleProfileChanges = (value) => {
    const defaultProfile = profileListData.find((ele) => ele.id == value);
    if (defaultProfile) {
      setProfiles(defaultProfile.id);
      setTaxNames(defaultProfile.taxRows?.[0]?.taxName || "");
      setTaxPercentage(defaultProfile.taxRows?.[0]?.taxPercent || 0);
    }
  };

  const [scopeData, setScopeData] = useState([]);
  const quoteScopeCount = scopeData?.length || 0;

  const [constructData, setConstructData] = useState([]);
  const quoteConstructCount = constructData?.length || 0;

  const handleFPOApprove = (id) => {
    dispatch(approveFPOs(id)).then((res) => {
      if (res.status === 200) {
        dispatch(
          getAllQuoteRequestAction(
            state?.ProjectId,
            costCodeId,
            jobCodeId,
            jobType
          )
        );
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleSuplierCreditApprove = (id) => {
    dispatch(approveSCs(id)).then((res) => {
      if (res.status === 200) {
        dispatch(
          getAllFieldPurchaseOrderAction(costCodeId, jobCodeId, jobType)
        );
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const [JobMenuModal, setJobMenuModal] = useState(false);
  const handleJobMenuModalClose = () => setJobMenuModal(false);
  const handleJobMenuModalShow = () => setJobMenuModal(true);

  const handleNavigateModalShow = () => {
    updateModalState({ ...iModalState, NavigateModal: true });
  };
  const handleNavigateModalClose = () => {
    updateModalState({ ...iModalState, NavigateModal: false });
  };

  const quoteScopeCounts = quoteDetailsData?.workDocuments?.length || 0;
  const quoteScopeDocs =
    quoteDetailsData?.workDocuments?.map((doc) => doc.docUrl) || [];
  const handleDownloadAllScopeDocs = async () => {
    if (Array.isArray(quoteScopeDocs) && quoteScopeDocs.length > 0) {
      for (const docUrl of quoteScopeDocs) {
        const link = document.createElement("a");
        link.href = docUrl;
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    } else {
      toast.error("No documents available to download");
    }
  };

  const quoteConstructCounts =
    quoteDetailsData?.constructionDocuments?.length || 0;
  const quoteConstructDocs =
    quoteDetailsData?.constructionDocuments?.map((doc) => doc.docUrl) || [];
  const handleDownloadAllConstructDocs = async () => {
    if (Array.isArray(quoteConstructDocs) && quoteConstructDocs.length > 0) {
      for (const docUrl of quoteConstructDocs) {
        const link = document.createElement("a");
        link.href = docUrl;
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    } else {
      toast.error("No documents available to download");
    }
  };

  const quoteAdditionalCounts =
    quoteDetailsData?.additionalDocuments?.length || 0;
  const quoteAdditionalDocs =
    quoteDetailsData?.additionalDocuments?.map((doc) => doc.docUrl) || [];
  const handleDownloadAllAdditionalDocs = async () => {
    if (Array.isArray(quoteAdditionalDocs) && quoteAdditionalDocs.length > 0) {
      for (const docUrl of quoteAdditionalDocs) {
        const link = document.createElement("a");
        link.href = docUrl;
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    } else {
      toast.error("No documents available to download");
    }
  };

  const [vendorEditToggle, setVendorEditToggle] = useState(false);

  const handleVendorEditToggle = () => {
    setVendorEditToggle(!vendorEditToggle);
  };

  const addDetailsClick = () => {
    updateState({
      ...iState,
      itemDetails: [
        ...iState.itemDetails,
        {
          parentTableName: "QUOTEREQUEST",
          color: "",
          quantity: null,
          unitId: null,
          price: "",
          total: "",
          name: "",
        },
      ],
    });
  };

  const removeDetailsClick = (i) => {
    let user = [...itemDetails];
    user.splice(i, 1);
    updateState({
      ...iState,
      itemDetails: user,
    });
  };

  const copyBuilderItems = () => {
    const newMappedData =
      quoteDetailsData?.BuilderItems?.map((item) => ({
        parentTableName: "QUOTEREQUEST",
        color: item.color || "",
        quantity: Number(item.quantity) || null,
        unitId: item.unitId || null,
        price: item.price || "",
        total: item.total || "",
        name: item.name || "",
      })) || [];

    updateState({ ...iState, itemDetails: newMappedData });
  };

  const handleClear = () => {
    updateState({ ...iState, itemDetails: [] });
  };

  const handleDetailsChange = (e, i) => {
    const { name, value } = e.target;
    let user = [...itemDetails];
    user[i] = { ...user[i], [name]: value };
    updateState({
      ...iState,
      itemDetails: user,
    });
  };

  const addDetailssClick = () => {
    updateState({
      ...iState,
      itemDetailss: [
        ...iState.itemDetailss,
        {
          parentTableName: "QUOTEREQUEST",
          color: "",
          quantity: null,
          unitId: null,
          price: "",
          total: "",
          name: "",
        },
      ],
    });
  };

  const removeDetailssClick = (i) => {
    let user = [...itemDetailss];
    user.splice(i, 1);
    updateState({
      ...iState,
      itemDetailss: user,
    });
  };

  const handleClears = () => {
    updateState({ ...iState, itemDetailss: [] });
  };

  const handleDetailssChange = (e, i) => {
    const { name, value } = e.target;
    let user = [...itemDetailss];
    user[i] = { ...user[i], [name]: value };
    updateState({
      ...iState,
      itemDetailss: user,
    });
  };
  const [ViewAttachmentsModal, setViewAttachmentsModal] = useState(false);
  const handleViewAttachmentsModalClose = () => {
    setViewAttachmentsModal(false);
    updateModalState({ ...iModalState, BackChargeListingModal: true });
  };
  const [AttachmentsData, setAttachmentsData] = useState([]);
  const handleViewAttachmentsModalShow = (item) => {
    setViewAttachmentsModal(true);
    setAttachmentsData(item);
    updateModalState({ ...iModalState, BackChargeListingModal: false });
  };

  const handleViewAttachDataModalClose = () => {
    updateState({ ...iState, ViewAttachDataModal: false });
  };

  const handleViewAttachDataModalShow = (item) => {
    updateState({
      ...iState,
      ViewAttachDataModal: true,
      ViewAttachData: item?.split("?")[0],
    });
  };
  return (
    <>
      <Header sendDataToParent={handleChildData} />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox WrapperBoxChinook">
          <div className="TitleBox d-flex flex-md-row align-items-md-center flex-column align-items-start">
            <h4 className="Title">Budget Details</h4>
            <div className="BuilderBox">
              <h4>
                Job
                {/* <img src="images/refresh.png" /> */}
              </h4>
              <b>
                {state?.jobNumber} ( {state?.stateName})
              </b>
            </div>
            <div className="TodosButtons mt-0">
              <a href="javascript:void(0)" onClick={showBiddingQuoteModal}>
                <img src="images/bills.png" />
                Global Bidding
              </a>
              <a
                href="javascript:void(0)"
                onClick={() => showBillListingModal()}
              >
                <img src="images/bills.png" />
                QR/PO Mass Mailout
              </a>
            </div>
            <div className="TitleBoxNavigate">
              <div className="NavigateButton gap-2">
                <button className="greenBtn" onClick={handleJobMenuModalShow}>
                  Job Menu
                </button>
                <button
                  className="ml-2 greenBtn"
                  onClick={handleNavigateModalShow}
                >
                  Navigate
                </button>
              </div>
            </div>
          </div>
          <div className="Tableheader flex-sm-row flex-column align-items-sm-center align-items-start">
            <div className="d-flex">
              <div className="SelectJobAreaOuter">
                <div className="SelectJobArea">
                  <h6>Select Job</h6>
                  <img
                    onClick={() => {
                      jobDropdownFlag
                        ? SetJobDropdownFlag(false)
                        : SetJobDropdownFlag(true);
                    }}
                    src="images/select_job_icon.png"
                    className="SelectJobToggle"
                  />
                </div>
                <div
                  className="ChinookLeft"
                  style={{ display: jobDropdownFlag ? "block" : "none" }}
                >
                  <div className="ChinookTitle mb-0">
                    <h4>{state?.projectName}</h4>
                    <h6>({state?.projectType})</h6>
                  </div>
                  <div className="SelectJobDownUp">
                    <figure>
                      <img
                        src="images/select_job_down.png"
                        className="SelectJobDown"
                      />
                    </figure>
                    <figure>
                      <img
                        src="images/select_job_up.png"
                        className="SelectJobUp"
                      />
                    </figure>
                  </div>
                  <div className="Search">
                    <input
                      type="text"
                      onChange={(e) => setJobSearchState(e.target.value)}
                      className="form-control"
                      placeholder="Search"
                    />
                    <span>
                      <img src="images/search.png" />
                    </span>
                  </div>
                  <div className="ChinookStarHomes">
                    <div className="ChinookStarHomesLeft">
                      <input
                        type="text"
                        className="EditHeading"
                        defaultValue="Chinook Star Homes"
                      />
                      <p>{state?.jobNumber}</p>
                      <p>{state?.jobAddress}</p>
                    </div>
                    <div className="ChinookStarIcons">
                      <a href="javascript:void(0);">
                        <img src="images/home_circle.png" />
                      </a>
                    </div>
                  </div>
                  <div className="ChinookListedJobs">
                    <h6 className="listedJobs">
                      All {multiblocksList?.length} Listed jobs
                    </h6>
                    <div className="ListedJobsArea">
                      <ul>
                        {jobSearchState !== ""
                          ? multiblocksList?.jobs
                              ?.filter?.(
                                (ele) => ele?.jobNumber == jobSearchState
                              )
                              ?.map((item, jobIndex) => (
                                <li class={`FirstListing`}>
                                  <div class="ListingLeft">
                                    <div class="ListingBorder">
                                      <span class="Red"></span>
                                    </div>
                                    <div class="ListingRight">
                                      <label class="CheckBox">
                                        {item?.jobNumber}
                                        <input
                                          type="checkbox"
                                          onChange={(e) =>
                                            handleSelectJob(e, item)
                                          }
                                        />
                                        <span class="checkmark"></span>
                                      </label>
                                      <a href="#">
                                        <img
                                          src={require("../assets/images/icon-34.png")}
                                        />
                                      </a>
                                    </div>
                                  </div>

                                  <hr />
                                </li>
                              ))
                          : multiblocksList?.map((item, jobIndex) => (
                              <li class={`FirstListing`}>
                                <div class="ListingLeft">
                                  <div class="ListingBorder">
                                    <span class="Red"></span>
                                  </div>
                                  <div class="ListingRight">
                                    <label class="CheckBox">
                                      {item?.jobNumber}
                                      <input
                                        type="checkbox"
                                        onChange={(e) =>
                                          handleSelectJob(e, item)
                                        }
                                      />
                                      <span class="checkmark"></span>
                                    </label>
                                    <a href="#">
                                      <img
                                        src={require("../assets/images/icon-34.png")}
                                      />
                                    </a>
                                  </div>
                                </div>

                                <hr />
                              </li>
                            ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="NewActions align-items-center">
              <a onClick={handleFilterModalShow} title="filter">
                <img src="images/icon-64.png" />
              </a>
              <a href="javascript:void(0);">
                <img src="images/icon-53.png" />
              </a>
              <a
                href="javascript:void(0);"
                data-toggle="modal"
                data-target="#UploadEstimationProfile"
              >
                <img src="images/upload_icon.png" />
              </a>
              <a href="javascript:void(0);">
                <img src="images/download.png" />
              </a>
              <a onClick={() => SetGearIconSettingModalShow(true)}>
                <img src="images/setting.png" />
              </a>
            </div>
          </div>
          <div className="Tableheader">
            <div className="TodosButtons mt-0 d-flex flex-wrap">
              <a
                onClick={() =>
                  updateLeadState({ ...iLeadState, hideShowId: true })
                }
                href="javascript:void(0)"
              >
                Expand all
              </a>
              <a
                onClick={() =>
                  updateLeadState({ ...iLeadState, hideShowId: false })
                }
                href="javascript:void(0)"
              >
                Collapse all
              </a>
              <a href="javascript:void(0)" onClick={showNARowsModal}>
                Show N/A Rows
              </a>

              <a href="javascript:void(0)" onClick={showBudgetSummaryModal}>
                <img src="images/budget_summary.png" />
                Budget Summary
              </a>
              <a
                href="javascript:void(0)"
                className="Green"
                onClick={showbackChargeListingModal}
              >
                <img src="images/budget_summary.png" />
                Back Charge/Supplier Credit
              </a>
            </div>
          </div>
          {/* change order table start  */}
          <div
            className="JobTableCommon JobTableCommon BorderBottomColor"
            style={{ display: "block" }}
          >
            <table className="table table-hover">
              <thead>
                {includedConsultantt?.length > 0 ? (
                  <>
                    <th>Cost Area</th>
                    {includedConsultantt.map((data, index) => (
                      <th key={index}>{data.label}</th>
                    ))}
                    <th>Action Required</th>
                    <th>Alerts</th>
                  </>
                ) : (
                  <tr>
                    <th>Cost Area</th>
                    <th>Cost Area Descripion</th>
                    {estimationSettingData?.proformaBudget && (
                      <th>Proforma Budget</th>
                    )}
                    {estimationSettingData?.budgetChangeOrder && (
                      <th>Budget Change Order(CO)</th>
                    )}
                    {estimationSettingData?.quoteRequest && (
                      <th>Quote(QR) Total</th>
                    )}
                    {estimationSettingData?.workingBudget && (
                      <th>Working Budget</th>
                    )}
                    {estimationSettingData?.purchaseOrder && (
                      <th>Budget(PO)</th>
                    )}
                    {estimationSettingData?.fieldPurchaseOrder && (
                      <th>FPO's</th>
                    )}

                    {estimationSettingData?.supplierCredit && (
                      <th> Supplier Total</th>
                    )}
                    {estimationSettingData?.preTaxTotal && (
                      <th>Pre-Tax Total</th>
                    )}
                    {estimationSettingData?.variance && <th>Variance</th>}
                    {estimationSettingData?.allAreaCostCodeStatus && (
                      <th>All Area Cost Code Status</th>
                    )}
                    {estimationSettingData?.outstandingCostCodes && (
                      <th>Outstanding Cost Codes</th>
                    )}

                    {estimationSettingData?.actionRequired && (
                      <th>Action Required</th>
                    )}

                    {estimationSettingData?.alerts && <th>Alerts</th>}
                    <th></th>
                  </tr>
                )}
              </thead>

              {loader ? (
                <>
                  <td>
                    {" "}
                    <Loader />
                  </td>
                </>
              ) : (
                <>
                  {checkArray(budgetListData?.curData) ? (
                    budgetListData?.curData?.map((ele, i) => {
                      return (
                        <>
                          <tbody className="addClasss">
                            <tr className="hidtableshowChangeOrder">
                              <td>
                                <div
                                  style={{ color: "red" }}
                                  onClick={() =>
                                    handleJobListByProject(ele.id, i)
                                  }
                                  className="Action"
                                >
                                  {ele?.number}{" "}
                                  <img
                                    src={"images/down-arrow.png"}
                                    style={{
                                      transform: `rotate(${
                                        hideShowId == i ? "180deg" : "0deg"
                                      })`,
                                    }}
                                  />
                                </div>
                              </td>
                              {includedConsultantt.length > 0 &&
                              includedConsultantt.some(
                                (module) => module.value === "description"
                              ) ? (
                                <td style={{ color: "red" }}>{ele?.name}</td>
                              ) : (
                                includedConsultantt.length == 0 && (
                                  <td style={{ color: "red" }}>{ele?.name}</td>
                                )
                              )}
                              {includedConsultantt.length > 0 &&
                              includedConsultantt.some(
                                (module) => module.value === "Proforma"
                              ) ? (
                                <td style={{ color: "red" }}>
                                  {ele?.proformaBudget ?? 0}
                                </td>
                              ) : (
                                includedConsultantt.length == 0 &&
                                estimationSettingData?.proformaBudget && (
                                  <td style={{ color: "red" }}>
                                    {ele?.proformaBudget ?? 0}
                                  </td>
                                )
                              )}
                              {includedConsultantt.length > 0 &&
                              includedConsultantt.some(
                                (module) => module.value === "Budget"
                              ) ? (
                                <td style={{ color: "red" }}>
                                  {ele?.budgetChangeOrder ?? 0}
                                </td>
                              ) : (
                                includedConsultantt.length == 0 &&
                                estimationSettingData?.budgetChangeOrder && (
                                  <td style={{ color: "red" }}>
                                    {ele?.budgetChangeOrder ?? 0}
                                  </td>
                                )
                              )}
                              {includedConsultantt.length > 0 &&
                              includedConsultantt.some(
                                (module) => module.value === "Bill"
                              ) ? (
                                <td style={{ color: "red" }}>
                                  {ele?.quoteRequest}
                                </td>
                              ) : (
                                includedConsultantt.length == 0 &&
                                estimationSettingData?.quoteRequest && (
                                  <td style={{ color: "red" }}>
                                    {ele?.quoteRequest}
                                  </td>
                                )
                              )}

                              {includedConsultantt.length > 0 &&
                              includedConsultantt.some(
                                (module) => module.value === "WorkingBudget"
                              ) ? (
                                <td style={{ color: "red" }}>
                                  {ele?.workingBudget}
                                </td>
                              ) : (
                                includedConsultantt.length == 0 &&
                                estimationSettingData?.workingBudget && (
                                  <td style={{ color: "red" }}>
                                    {ele?.workingBudget}
                                  </td>
                                )
                              )}
                              {includedConsultantt.length > 0 &&
                              includedConsultantt.some(
                                (module) => module.value === "BillBudget"
                              ) ? (
                                <td style={{ color: "red" }}>
                                  {ele?.proformaBudget}
                                </td>
                              ) : (
                                includedConsultantt.length == 0 &&
                                estimationSettingData?.purchaseOrder && (
                                  <td style={{ color: "red" }}>
                                    {ele?.proformaBudget}
                                  </td>
                                )
                              )}

                              {includedConsultantt.length > 0 &&
                              includedConsultantt.some(
                                (module) => module.value === "ProformaBudget"
                              ) ? (
                                <td style={{ color: "red" }}>
                                  {ele?.supplierCredit}
                                </td>
                              ) : (
                                includedConsultantt.length == 0 &&
                                estimationSettingData?.supplierCredit && (
                                  <td style={{ color: "red" }}>
                                    {ele?.supplierCredit}
                                  </td>
                                )
                              )}
                              <td>{ele?.preTaxTotal}</td>
                              {includedConsultantt.length > 0 &&
                              includedConsultantt.some(
                                (module) =>
                                  module.value === "PreTaxSupplierTotal"
                              ) ? (
                                <td style={{ color: "red" }}>
                                  {ele?.preTaxTotal}
                                </td>
                              ) : (
                                includedConsultantt.length == 0 &&
                                estimationSettingData?.preTaxTotal && (
                                  <td style={{ color: "red" }}>
                                    {ele?.preTaxTotal}
                                  </td>
                                )
                              )}
                              {includedConsultantt.length > 0 &&
                              includedConsultantt.some(
                                (module) => module.value === "Variance"
                              ) ? (
                                <td style={{ color: "red" }}>
                                  {ele?.variance}
                                </td>
                              ) : (
                                includedConsultantt.length == 0 &&
                                estimationSettingData?.variance && (
                                  <td style={{ color: "red" }}>
                                    {ele?.variance}
                                  </td>
                                )
                              )}
                              {includedConsultantt.length > 0 &&
                              includedConsultantt.some(
                                (module) => module.value === "Status"
                              ) ? (
                                <td>
                                  <div
                                    className="TextOrange"
                                    style={{ color: "red" }}
                                  >
                                    {ele?.allAreaCostCodeStatus}
                                  </div>
                                </td>
                              ) : (
                                includedConsultantt.length == 0 &&
                                estimationSettingData?.allAreaCostCodeStatus && (
                                  <td>
                                    <div
                                      className="TextOrange"
                                      style={{ color: "red" }}
                                    >
                                      {ele?.allAreaCostCodeStatus}
                                    </div>
                                  </td>
                                )
                              )}
                              {includedConsultantt.length > 0 &&
                              includedConsultantt.some(
                                (module) =>
                                  module.value === "OutstandingCostCodes"
                              ) ? (
                                <td>
                                  <div
                                    className="TextRed"
                                    style={{ color: "red" }}
                                  >
                                    {ele?.outstandingCostCodes}
                                  </div>
                                </td>
                              ) : (
                                includedConsultantt.length == 0 &&
                                estimationSettingData?.outstandingCostCodes && (
                                  <td style={{ color: "red" }}>
                                    <div className="TextRed">
                                      {ele?.outstandingCostCodes}
                                    </div>
                                  </td>
                                )
                              )}
                              {estimationSettingData?.actionRequired && (
                                <td>
                                  <div className="Action">
                                    <img
                                      src="images/action_required_yellow.png"
                                      className="mr-1"
                                    />
                                    <img
                                      src="images/action_required_red.png"
                                      className="ml-1"
                                    />
                                  </div>
                                </td>
                              )}
                              {estimationSettingData?.Alerts && (
                                <td>
                                  <div className="Action d-flex flex-column align-items-start">
                                    {checkArray(ele?.alerts) &&
                                      ele?.alerts?.map((ele, i) => {
                                        return (
                                          <>
                                            {i % 2 === 0 ? (
                                              <div className="TextOrange">
                                                {ele}
                                              </div>
                                            ) : (
                                              <div className="TextRed">
                                                {ele}
                                              </div>
                                            )}
                                          </>
                                        );
                                      })}
                                  </div>
                                </td>
                              )}
                            </tr>

                            <tr>
                              <td colSpan={14} className="padding_none">
                                <div
                                  className="JobBoxTable JobBoxTableHeight TableBackground"
                                  style={{
                                    display:
                                      hideShowId == ele?.id ? "block" : "none",
                                  }}
                                >
                                  <table className="table-hover">
                                    <thead>
                                      <tr>
                                        <th>Cost Area</th>
                                        <th>Cost Area Descripion</th>
                                        <th>History</th>
                                        <th>Proforma Budget</th>
                                        <th>Budget Change Order(CO)</th>
                                        <th>Quote(QR) Total</th>
                                        <th>Working Budget</th>
                                        <th>Budget(PO)</th>
                                        <th>FPO's</th>
                                        <th>Pre-Tax P.O Total</th>
                                        <th>SC Total(-)</th>
                                        <th>Variance</th>
                                        <th>Cost Code Status</th>
                                        <th>Vendor Selected</th>
                                        <th>Action Required</th>
                                        <th>Alerts</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {checkArray(ele?.jobCostCodes) &&
                                        ele?.jobCostCodes?.map((ele, i) => {
                                          return (
                                            <>
                                              <tr>
                                                <td>{ele?.number}</td>
                                                <td>{ele?.name}</td>
                                                <td>
                                                  <a
                                                    href="javascript:void(0);"
                                                    onClick={() =>
                                                      showEstimationHistoryModal(
                                                        ele?.JobCost?.id,
                                                        ele?.name
                                                      )
                                                    }
                                                  >
                                                    <img src="images/create_category.png" />
                                                  </a>
                                                </td>
                                                <td>{ele?.proformaBudget}</td>
                                                <td>
                                                  {ele?.budgetChangeOrder}
                                                </td>
                                                <td>{ele?.quoteRequest}</td>
                                                <td>{ele?.workingBudget}</td>
                                                <td>{ele?.purchaseOrder}</td>
                                                <td>
                                                  {ele?.fieldPurchaseOrder}
                                                </td>
                                                <td>{ele?.preTaxTotal}</td>
                                                <td>{ele?.supplierCredit}</td>
                                                <td>{ele?.variance}</td>
                                                <td>
                                                  <div className="TextGreen">
                                                    {ele?.allAreaCostCodeStatus}
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="Action">
                                                    {ele?.vendorSelected}{" "}
                                                    <img
                                                      src="images/fill_star.png"
                                                      className="ml-2"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <a href="javascript:void(0);">
                                                    <img src="images/action_required_yellow.png" />
                                                  </a>
                                                </td>
                                                <td>
                                                  <div className="TextOrange">
                                                    {/* {checkArray(ele?.alerts) && ele?.alerts?.map((itemAlert,i) => {
                                  return (
                                    <> */}
                                                    {ele?.alerts}
                                                    {/* </>
                                  )
                                })} */}
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="TableThreeDots">
                                                    <ul className="">
                                                      <li className="dropdown">
                                                        <a
                                                          className=""
                                                          role="button"
                                                          data-toggle="dropdown"
                                                          aria-haspopup="true"
                                                          aria-expanded="false"
                                                        >
                                                          <img src="images/dots.png" />
                                                        </a>
                                                        <ol className="dropdown-menu ChangeOrderDropdown3 DropdownBottonPosition">
                                                          <li>
                                                            <a
                                                              onClick={() =>
                                                                showChangeStatusModal(
                                                                  ele?.JobCost
                                                                    ?.id
                                                                )
                                                              }
                                                              href="javascript:void(0);"
                                                            >
                                                              <img src="images/icon-15.png" />
                                                              Edit Status
                                                            </a>
                                                          </li>
                                                          <li>
                                                            <a
                                                              onClick={() =>
                                                                hideCOstFUnc(
                                                                  ele?.JobCost
                                                                    ?.id
                                                                )
                                                              }
                                                              href="javascript:void(0);"
                                                            >
                                                              <img src="images/lock_user.png" />
                                                              Change Cost Code
                                                              to Not Applicable
                                                            </a>
                                                          </li>
                                                          <li>
                                                            <a
                                                              href="javascript:void(0);"
                                                              onClick={() =>
                                                                showBiddingDetailModal(
                                                                  "billListing",
                                                                  ele?.id,
                                                                  ele?.JobCost
                                                                    ?.jobId,
                                                                  ele?.JobCost
                                                                    ?.jobType,
                                                                  ele
                                                                )
                                                              }
                                                            >
                                                              <img src="images/view_bidding.png" />
                                                              View Bidding
                                                            </a>
                                                          </li>
                                                          <li>
                                                            <a
                                                              href="javascript:void(0);"
                                                              onClick={() =>
                                                                showBudgetChangeOrderListingModal(
                                                                  ele
                                                                )
                                                              }
                                                            >
                                                              <img src="images/view_budget_change_order.png" />
                                                              View Budget Change
                                                              Orders
                                                            </a>
                                                          </li>
                                                          <li>
                                                            <a
                                                              href="javascript:void(0);"
                                                              onClick={() =>
                                                                showNewQuoteRequestModal(
                                                                  "ListingQuoteRequest",
                                                                  ele?.id,
                                                                  ele?.JobCost
                                                                    ?.jobId,
                                                                  ele?.JobCost
                                                                    ?.jobType,
                                                                  "",
                                                                  ele?.name
                                                                )
                                                              }
                                                            >
                                                              <img src="images/create_new_quote.png" />
                                                              Create New Quote
                                                              Request/Purchase
                                                              Order
                                                            </a>
                                                          </li>
                                                          <li>
                                                            <a
                                                              href="javascript:void(0);"
                                                              onClickCapture={() =>
                                                                showBillModal(
                                                                  "ListingBillRequest",
                                                                  ele?.id,
                                                                  ele?.JobCost
                                                                    ?.jobId,
                                                                  ele?.JobCost
                                                                    ?.jobType,
                                                                  ele?.number,
                                                                  ele?.name
                                                                )
                                                              }
                                                              // onClick={() => showSelectCostModal("ListingBillRequest")}
                                                            >
                                                              <img src="images/bills_black.png" />
                                                              QR/PO Mass Mailout
                                                            </a>
                                                          </li>
                                                          <li>
                                                            <a
                                                              href="javascript:void(0);"
                                                              onClick={() =>
                                                                showBudgetChangeOrderModal(
                                                                  "ListingBillRequest",
                                                                  ele?.id,
                                                                  ele?.JobCost
                                                                    ?.jobId,
                                                                  ele?.JobCost
                                                                    ?.jobType,
                                                                  ele?.JobCost
                                                                    ?.id,
                                                                  ele?.number,
                                                                  ele?.name
                                                                )
                                                              }
                                                            >
                                                              <img src="images/view_budget_change_order.png" />
                                                              Create New Budget
                                                              Change Order
                                                            </a>
                                                          </li>
                                                          <li>
                                                            <a
                                                              href="javascript:void(0);"
                                                              onClick={() =>
                                                                showFieldPurchaseOrder(
                                                                  "ListingFieldPurchaseRequest",
                                                                  ele?.id,
                                                                  ele?.JobCost
                                                                    ?.jobId,
                                                                  ele?.JobCost
                                                                    ?.jobType,
                                                                  ele?.number,
                                                                  ele?.name
                                                                )
                                                              }
                                                              // onClick={() => showSelectCostModal("ListingFieldPurchaseRequest")}
                                                            >
                                                              <img src="images/view_budget_change_order.png" />
                                                              Field Purchase
                                                              Order
                                                            </a>
                                                          </li>
                                                          <li>
                                                            <li>
                                                              <a
                                                                href="javascript:void(0);"
                                                                onClick={() =>
                                                                  showSupplierCreditModal(
                                                                    "ListingSupplyCreditRequest",
                                                                    ele?.id,
                                                                    ele?.JobCost
                                                                      ?.jobId,
                                                                    ele?.JobCost
                                                                      ?.jobType,
                                                                    ele?.number,
                                                                    ele?.name
                                                                  )
                                                                }
                                                                // onClick={() => showSelectCostModal("ListingSupplyCreditRequest")}
                                                                // onClick={() => showSelectCostModal("ListingSupplyCreditRequest")}
                                                              >
                                                                <img src="images/view_budget_change_order.png" />
                                                                Supply Credit
                                                              </a>
                                                            </li>
                                                          </li>
                                                          <li>
                                                            <a
                                                              onClick={() =>
                                                                showbackChargeListingModal()
                                                              }
                                                            >
                                                              <img src="images/view_budget_change_order.png" />
                                                              View Back-Charge
                                                            </a>
                                                          </li>
                                                          <li>
                                                            <a
                                                              onClick={() =>
                                                                showAddbackChargeModal(
                                                                  ele
                                                                )
                                                              }
                                                            >
                                                              <img src="images/view_budget_change_order.png" />
                                                              Create Back-Charge
                                                            </a>
                                                          </li>
                                                          <li>
                                                            <a
                                                              onClick={() =>
                                                                hideCOstFUnc(
                                                                  ele?.JobCost
                                                                    ?.id
                                                                )
                                                              }
                                                              href="javascript:void(0);"
                                                            >
                                                              <img src="images/close-eyes.png" />
                                                              Hide N/A Line
                                                            </a>
                                                          </li>
                                                        </ol>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </>
                      );
                    })
                  ) : (
                    <td>
                      {" "}
                      <NoDataFound />
                    </td>
                  )}
                </>
              )}
              <tbody className="GrandTotalRaw">
                <tr>
                  <td>Grand Total</td>
                  <td></td>
                  <td>{budgetListData?.grandTotal?.estimationBudget}</td>
                  <td>{budgetListData?.grandTotal?.budgetChangeOrder}</td>
                  <td>{budgetListData?.grandTotal?.quoteRequest}</td>
                  <td></td>
                  <td>{budgetListData?.grandTotal?.purchaseOrder}</td>
                  <td>{budgetListData?.grandTotal?.fieldPurchaseOrder}</td>
                  <td>{budgetListData?.grandTotal?.preTaxTotal}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>

                  {/* <td>{budgetListData?.grandTotal?.supplierCredit}</td> */}
                </tr>
              </tbody>
            </table>
          </div>
          {/* change order table end   */}
        </div>
      </div>

      <Modal
        show={globalBiddingQuoteModal}
        onHide={hideBiddingQuoteModal}
        className="LargeModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideBiddingQuoteModal}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Global Bidding</h4>
            </div>
            <div className="ModalBigheading d-flex justify-content-between">
              {/* <h3>
                <span>Project:</span>Chinook Homes
              </h3> */}
              <div className="TodosButtons mt-0 d-flex align-items-center flex-wrap">
                <a
                  href="javascript:void(0)"
                  className="FolderPermissionId"
                  // ListingQuoteRequest
                  onClick={() => showSelectCostModal("ListingQuoteRequest")}
                  // onClick={() => showNewQuoteRequestModal("bidding")}
                >
                  <img src="images/create_new_bill.png" />
                  Create New Quote Request / Purchase Order
                </a>
                <div className="TodosButtons mt-0">
                  <a
                    href="javascript:void(0)"
                    className="FolderPermissionId"
                    onClick={() => showSelectCostModal("ListingBillRequest")}
                    // onClick={() => showBillModal("bidding")}
                  >
                    <img src="images/create_new_bill.png" />
                    Create QR/PO Mass Mailout
                  </a>
                </div>
              </div>
            </div>
            <div className="TableHeadingArea mt-5">
              <div className="FilesHead">
                <div className="FilesLeft">
                  <h4>Documents</h4>
                </div>
                <div className="d-flex align-items-center">
                  <div className="Search">
                    <span>
                      <img src="images/search.png" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                    />
                  </div>
                  <div className="FilesRight">
                    <a href="#">
                      <img src="images/pdf.png" />
                    </a>
                    <DownloadTableExcel
                      filename="Quote item List"
                      sheet="Quote items list"
                      currentTableRef={quoteRef.current}
                    >
                      <button href="jacascript:void(0);">
                        <img src="images/download.png" />
                      </button>
                    </DownloadTableExcel>
                    <a href="#">
                      <img src="images/setting.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="TableList CommonHeading">
              <table ref={quoteRef}>
                <thead>
                  <tr>
                    <th>Job #</th>
                    <th>Sent By</th>
                    <th>Reason</th>
                    <th>Sent To</th>
                    <th>Document #</th>
                    <th>Cost Code</th>
                    <th>Document Type</th>
                    {/* <th>Item Description</th> */}
                    <th>Related QR/PO Mass Mailout</th>
                    <th>Date Sent</th>
                    <th>Pre-Tax Total</th>
                    <th>Vendor Pre-Tax Total</th>
                    <th>Document Status</th>
                    <th>Reminder</th>
                    <th>Time since last status update</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <>
                      <td>
                        {" "}
                        <Loader />
                      </td>
                    </>
                  ) : (
                    <>
                      {checkArray(quoteRequestListData?.data) ? (
                        quoteRequestListData?.data?.map((ele, ind) => {
                          return (
                            <>
                              <tr className="BorderCyan">
                                <td>{ele?.job?.jobNumber}</td>
                                <td>
                                  {ele?.issuedBy?.name ||
                                    ele?.issuedBy?.email ||
                                    "N/A"}
                                </td>
                                <td>{ele?.Reason?.description ?? "N/A"}</td>
                                <td>
                                  {ele?.Vendor?.vendorName ||
                                    ele?.Vendor?.email ||
                                    "N/A"}
                                </td>
                                <td>
                                  <div className="TextLightSkyBlue">
                                    {ele?.documentCode}
                                  </div>
                                </td>
                                <td> {ele?.CostCode?.name}</td>
                                <td>{ele?.documentType}</td>
                                {/* <td>Appliances Quote</td> */}
                                <td>{ele?.billId}</td>
                                <td>
                                  {ele?.send
                                    ? ele?.sendAt?.split("T")[0]
                                    : "N/A"}
                                </td>
                                <td>$ {ele?.subtotal}</td>
                                <td>$ {ele?.vendorPreTaxTotal}</td>
                                <td>QUOTE {ele?.status}</td>
                                <td>
                                  <a href="javascript:void(0);">
                                    <img
                                      src="images/timer.png"
                                      className="mr-2"
                                    />
                                  </a>
                                  <td>{ele?.reminder?.split("T")[0]}</td>
                                </td>
                                <td>5 Days</td>
                                <td>
                                  <div className="TableThreeDots">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol className="dropdown-menu dropdown-menuwidth2">
                                          <li>
                                            <a
                                              onClick={() =>
                                                showQuoteDetailsModal(
                                                  "globalBidding",
                                                  ele?.id
                                                )
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/view-po.png" />
                                              View Document
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                showBiddingDetailModal(
                                                  "bidding",
                                                  ele?.costCodeId,
                                                  ele?.jobCodeId,
                                                  ele?.jobType,
                                                  ele
                                                )
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/cancecl_message.png" />
                                              View Bidding
                                            </a>
                                          </li>
                                          {ele?.billId ? (
                                            <>
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    showBillDetailModal(
                                                      "bidding",
                                                      ele?.id
                                                    )
                                                  }
                                                  href="javascript:void(0);"
                                                >
                                                  <img src="images/cancecl_message.png" />
                                                  View QR/PO Mass Mailout
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    removeBillFunc(ele?.billId)
                                                  }
                                                  href="javascript:void(0);"
                                                >
                                                  <img src="images/cancecl_message.png" />
                                                  Cancel QR/PO Mass Mailout
                                                </a>
                                              </li>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          <li>
                                            {/* <a onClick={() => removeQuote(ele?.id)} */}
                                            <a
                                              onClick={() =>
                                                sendStatusFunc(
                                                  ele?.id,
                                                  "CANCELLED"
                                                )
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/cancel_documents.png" />
                                              Cancel Document
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                sendStatusFunc(
                                                  ele?.id,
                                                  "RESEND"
                                                )
                                              }
                                            >
                                              <img src="images/resend_message.png" />
                                              Resend Document
                                            </a>
                                          </li>
                                          <li>
                                            <a>
                                              <img src="images/resend_message.png" />
                                              Download Document
                                            </a>
                                          </li>
                                          <li>
                                            <a>
                                              <img src="images/pdf.png" />
                                              Print Document
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <td>
                          {" "}
                          <NoDataFound />
                        </td>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className="PaginationArea">
              <h6>Showing 1 to 10 of 27 entries</h6>
              <h6>
                <img src="images/leftarrow.png" /> 01{" "}
                <img src="images/rightarrow.png" /> Out 10
              </h6>
            </div>
            <div className="Button mt-3 text-center">
              <button className="Outline" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={BillModal} onHide={hideBillModal} className="LargeModal">
        <Modal.Body>
          <a className="CloseModal" onClick={hideBillModal} role="button">
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create QR/PO Mass Mailout</h4>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="col-12">
                  <div className="Heading mb-5">
                    <h3 className="d-flex ">
                      {" "}
                      QR/PO Mass Mailout # {estimationDocumentId}
                      <div className="TableThreeDots ml-3">
                        <ul className="">
                          <li className="dropdown">
                            <a
                              className=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol className="dropdown-menu dropdown-menuwidth2">
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/request_limit.png" />
                                  Request Limit Increase
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/notify_vendor.png" />
                                  Notify Vendor of Back Charge
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </h3>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <div className="CommonModalArea">
                      <h5>Vendor</h5>
                      <div className="CommonModalBox">
                        <h5 className="text-black">
                          <figure onClick={() => showSystemVendor("billModal")}>
                            <img src="images/vendor.png" />
                          </figure>{" "}
                          {vendorDetailsData?.vendorName}
                        </h5>
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {billError?.vendorIdError}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <h6>Date</h6>
                    <p className="ml-0">{getDate()}</p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5>Reminder</h5>
                      <div className="CommonModalBox">
                        <h5>
                          <figure
                            onClick={() => showAddRemianderModal("billModal")}
                          >
                            <img src="images/timer.png" />
                          </figure>{" "}
                          <h5>{day || week} days</h5>
                        </h5>
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {billError?.reminderDateError}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="ShippingAddressArea flex-sm-row flex-column align-items-sm-center align-items-start">
                    <div className="ShippingAddressAreaBox">
                      <h6>Mailing Address</h6>
                      <p>
                        {vendorDetailsData?.addressLine1}{" "}
                        {vendorDetailsData?.addressLine2}{" "}
                        {vendorDetailsData?.city}
                      </p>
                    </div>
                    <div className="ShippingAddressAreaBox">
                      <h6>Email</h6>
                      <p> {vendorDetailsData?.email}</p>
                    </div>
                    <div className="ShippingAddressAreaBox">
                      <h6>Phone</h6>
                      <p> {vendorDetailsData?.phone}</p>
                    </div>
                    {/* <div className="ShippingAddressAreaBox">
                <h6>Shipping Address</h6>
                <p>5384 Peach Rd Chilliwack, BC V2R0Z6</p>
              </div> */}
                  </div>
                </div>

                <div className="col-lg-7">
                  <div className="form-group">
                    <h6>Messsage</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert message to be sent to vendor"
                      name="message"
                      value={message}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {billError?.messageError}
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="ItemDetails">
                    <h6>QR/PO Mass Mailout Items</h6>
                  </div>
                </div>
                <div className="col-12">
                  <div className="Tableheader mb-3">
                    <h6 className="mb-0">Mass Mailout items</h6>
                    <div className="Actions">
                      <a href="javascript:void(0);">
                        <img src="images/icon-53.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/download.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/setting.png" />
                      </a>
                    </div>
                  </div>
                  <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                    <table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Job Id</th>
                          <th>Cost Code</th>
                          <th>Cost Code Description</th>
                          <th>Item Description</th>
                          <th>Document Type</th>
                          <th>Document Reason</th>
                          <th>Item Details</th>
                          <th>Subtotal</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {billDataCategories?.map((item, i) => {
                          // console.log("itemmmm,", item)
                          return (
                            <>
                              <tr>
                                <td>{i + 1}</td>
                                <td>
                                  <div
                                    className="Categories_all Categories_all1"
                                    style={{
                                      minWidth: "250px",
                                      padding: "0px",
                                    }}
                                  >
                                    <select
                                      name="jobCodeId"
                                      value={item?.jobCodeId}
                                      onChange={(e) =>
                                        handleChangeArrayItem(e, i, "billModal")
                                      }
                                    >
                                      <option value=""> Select Job </option>
                                      {checkArray(jobListData?.jobs) &&
                                        jobListData?.jobs?.map((ele, i) => {
                                          return (
                                            <option value={ele?.id}>
                                              {ele?.jobNumber}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    <img src="images/down.png" />
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className="Categories_all Categories_all1"
                                    style={{
                                      minWidth: "250px",
                                      padding: "0px",
                                    }}
                                  >
                                    <select
                                      name="costCodeId"
                                      value={item?.costCodeId}
                                      onChange={(e) =>
                                        handleChangeArrayItem(e, i, "billModal")
                                      }
                                    >
                                      <option value="">
                                        {" "}
                                        Select Cost Code{" "}
                                      </option>
                                      {checkArray(
                                        commonCostCodeListData?.data
                                      ) &&
                                        commonCostCodeListData?.data?.map(
                                          (ele, i) => {
                                            return (
                                              <option value={ele?.id}>
                                                ({ele?.number}) {ele?.name}
                                              </option>
                                            );
                                          }
                                        )}
                                    </select>
                                    <img src="images/down.png" />
                                  </div>
                                </td>
                                <td>
                                  {" "}
                                  {
                                    commonCostCodeListData?.data?.find(
                                      (x) => x?.id == item?.costCodeId
                                    )?.name
                                  }
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Insert description"
                                    name="itemDescription"
                                    value={item?.itemDescription}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "billModal")
                                    }
                                  />
                                </td>
                                <td>
                                  <div
                                    className="Categories_all Categories_all1"
                                    style={{
                                      minWidth: "250px",
                                      padding: "0px",
                                    }}
                                  >
                                    <select
                                      name="documentType"
                                      value={item?.documentType}
                                      onChange={(e) =>
                                        handleChangeArrayItem(e, i, "billModal")
                                      }
                                    >
                                      <option value="">
                                        {" "}
                                        Select Cost Code{" "}
                                      </option>

                                      <option value={"QUOTEREQUEST"}>
                                        {"Quote Request"}
                                      </option>
                                      <option value={"PURCHASEORDER"}>
                                        {"Purchase Order"}
                                      </option>
                                      <option value={"FIELDPURCHASEORDER"}>
                                        {"Field Purchase Order"}
                                      </option>
                                      <option value={"SUPPLIERCREDIT"}>
                                        {"Supplier Credit"}
                                      </option>
                                    </select>
                                    <img src="images/down.png" />
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className="Categories_all Categories_all1"
                                    style={{
                                      minWidth: "250px",
                                      padding: "0px",
                                    }}
                                  >
                                    <select
                                      name="reasonId"
                                      value={item?.reasonId}
                                      onChange={(e) =>
                                        handleChangeArrayItem(e, i, "billModal")
                                      }
                                    >
                                      <option>Select Reason</option>
                                      {checkArray(reasonListData) ? (
                                        <>
                                          {reasonListData?.map((ele, i) => {
                                            return (
                                              <option value={ele?.id}>
                                                {ele?.description}
                                              </option>
                                            );
                                          })}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </select>
                                    <img src="images/down.png" />
                                  </div>
                                </td>
                                <td>
                                  <div
                                    onClick={() => showBillQuoteReqModal(i)}
                                    className="Action"
                                  >
                                    <img
                                      src="images/item_details.png"
                                      className="FolderPermissionId CursorPointer"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Insert amount"
                                    name="subtotal"
                                    value={item?.subtotal}
                                    disabled
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "billModal")
                                    }
                                  />
                                </td>
                                <td>
                                  <div className="TableThreeDots">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol className="dropdown-menu dropdown-menuwidth2">
                                          <li>
                                            <a
                                              href="javascript:void(0);"
                                              onClick={() =>
                                                showUploadAdditionModal(
                                                  "billModal"
                                                )
                                              }
                                            >
                                              <img src="images/edit_item_details.png" />
                                              Additional Documents
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                removeLine(i, "BillQuote")
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/dropdown-delete.png" />
                                              Remove Line
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/request_limit_increase.png" />
                                              Request Limit Increase
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="5">
                                  <div className="TableList CommonHeadingBlack MaterialDescriptionTable">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>
                                            <div className="text-center">
                                              Material Description
                                              <br />
                                              (i.e: Modal/Type/Style/manuf
                                              part#/Dimensions, etc.)
                                            </div>
                                          </th>
                                          <th>Color</th>
                                          <th>Quantity</th>
                                          <th>Units</th>
                                          <th>Price/Unit</th>
                                          <th>Total</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {checkArray(billQuoteItems) &&
                                          item?.itemDetails?.map((ele, i) => {
                                            return (
                                              <>
                                                {" "}
                                                <tr>
                                                  {" "}
                                                  <td>{i + 1}</td>{" "}
                                                  <td>
                                                    {" "}
                                                    <div className="text-center">
                                                      {" "}
                                                      {ele?.name}{" "}
                                                    </div>{" "}
                                                  </td>{" "}
                                                  <td> {ele?.color}</td>{" "}
                                                  <td> {ele?.quantity}</td>{" "}
                                                  <td>{ele?.unitId}</td>{" "}
                                                  <td> $ {ele?.price}</td>{" "}
                                                  <td>$ {ele?.total}</td>{" "}
                                                  <td>
                                                    {" "}
                                                    <div className="TableThreeDots">
                                                      {" "}
                                                      <ul className="">
                                                        {" "}
                                                        <li className="dropdown">
                                                          {" "}
                                                          <a
                                                            className=""
                                                            role="button"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                          >
                                                            {" "}
                                                            <img src="images/dots.png" />{" "}
                                                          </a>{" "}
                                                          <ol className="dropdown-menu dropdown-menuwidth">
                                                            {" "}
                                                            <li> </li>{" "}
                                                            <li>
                                                              {" "}
                                                              <a
                                                                onClick={() =>
                                                                  removeLine(
                                                                    i,
                                                                    "billItemLine"
                                                                  )
                                                                }
                                                                href="javascript:void(0);"
                                                              >
                                                                {" "}
                                                                <img src="images/dropdown-delete.png" />{" "}
                                                                Remove Line{" "}
                                                              </a>{" "}
                                                            </li>{" "}
                                                          </ol>{" "}
                                                        </li>{" "}
                                                      </ul>{" "}
                                                    </div>{" "}
                                                  </td>{" "}
                                                </tr>{" "}
                                              </>
                                            );
                                          })}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })}

                        <span
                          style={{
                            color: "red",
                            fontSize: "15px",
                            textAlign: "center",
                          }}
                        >
                          {billError?.categoryDataError}
                        </span>

                        <tr>
                          <td colSpan={8}>
                            <div className="TableButtons">
                              <button
                                onClick={(e) =>
                                  addExtraRowInFunc(e, "billModal")
                                }
                                className="BrandBtn "
                              >
                                Add Line
                              </button>
                              <button
                                onClick={(e) => clearAllLines(e, "billModal")}
                                className="BrandBtn ml-2"
                              >
                                Clear all lines
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 ml-auto">
                      <div className="GstTable">
                        <table>
                          <tbody>
                            <tr>
                              <td>Profile</td>
                              <div class="CommonSelectBox">
                                <select
                                  value={profiles}
                                  onChange={(e) =>
                                    handleProfileChanges(e.target.value)
                                  }
                                >
                                  <option value="">Select profile</option>
                                  {checkArray(profileListData) &&
                                    profileListData?.map((ele, i) => {
                                      return (
                                        <option
                                          value={ele?.id}
                                          checked={ele.id == profile}
                                        >
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                              {/* <span style={{ color: "red", fontSize: "14px" }}>
                                {billError?.profileError}
                              </span> */}
                            </tr>
                            <tr>
                              <td>Subtotals</td>
                              <td
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {/* {billDataCategorySubtotal ? billDataCategorySubtotal : "0"} */}
                                {totalSums}
                                <span
                                  className="estimationModalBelowProfile"
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {/* {billError?.subtotalError} */}
                                </span>
                              </td>
                              <td>Modify Tax</td>
                            </tr>

                            {showTaxFlag ? (
                              <tr>
                                <td>
                                  {taxName}({taxPercentage}%)
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {Number(totalSums) *
                                    (Number(taxPercentage || 0) / 100)}
                                  <span
                                    className="estimationModalBelowProfile"
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {newQuoteRequestError?.subtotalError}
                                  </span>
                                </td>
                                <td>
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown">
                                        <a
                                          class=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img
                                            src="images/dots.png"
                                            style={{
                                              width: "100px",
                                              height: "20px",
                                            }}
                                          />
                                        </a>
                                        <ol class="dropdown-menu">
                                          <li>
                                            <a
                                              onClick={removeTaxProfileFunc}
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/dropdown-delete.png" />
                                              Remove Line
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              <td>{`${taxNames} (${taxPercentages} %)`}</td>
                              <td
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {Number(totalSums) *
                                  (Number(taxPercentages) / 100)}
                              </td>
                              <span
                                className="estimationModalBelowProfile"
                                style={{ color: "red", fontSize: "14px" }}
                              >
                                {newQuoteRequestError?.gstError}
                              </span>
                              {!showTaxFlag && (
                                <div class="TableThreeDots">
                                  <ul class="">
                                    <li class="dropdown">
                                      <a
                                        class=""
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <img
                                          src="images/dots.png"
                                          style={{
                                            width: "100px",
                                            height: "20px",
                                          }}
                                        />
                                      </a>
                                      <ol class="dropdown-menu">
                                        <li>
                                          <a
                                            onClick={() =>
                                              showAddTaxProfileModal(
                                                "quoteRequest"
                                              )
                                            }
                                            href="javascript:void(0);"
                                          >
                                            <img src="images/add_new_column.png" />
                                            Add New Column
                                          </a>
                                        </li>
                                      </ol>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </tr>
                            <tr>
                              <td>Grand Total</td>
                              {/* <td>{Math.round(grandTotal)}</td> */}
                              <td
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {Number(totalSums) +
                                  Number(totalSums) *
                                    (Number(taxPercentages) / 100) +
                                  Number(totalSums) *
                                    (Number(taxPercentage || 0) / 100)}
                              </td>
                              <span
                                className="estimationModalBelowProfile"
                                style={{ color: "red", fontSize: "14px" }}
                              >
                                {/* {billError?.grandTotalError} */}
                              </span>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-4">
                    <div className="Heading">
                      <h6 className="text-black">Attachments</h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5>Scope of Work</h5>
                      <div className="CommonModalBox">
                        <h5
                          className="CursorPointer"
                          onClick={() =>
                            showScopeWorkIdModal("massMailout", "work")
                          }
                        >
                          <figure>
                            <img src="images/scopeofwork.png" />
                          </figure>{" "}
                          Scope of Work &nbsp;
                          <span className="Count">{quoteScopeCount}</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5>Construction Documents</h5>
                      <div className="CommonModalBox">
                        <h5
                          className="CursorPointer"
                          onClick={() =>
                            showScopeWorkIdModal("massMailout", "construction")
                          }
                        >
                          <figure>
                            <img src="images/construction_documents.png" />
                          </figure>{" "}
                          Construction Documents &nbsp;
                          <span className="Count">{quoteConstructCount}</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5>Additional Documents</h5>
                      <div className="CommonModalBox">
                        <h5
                          onClick={() => showUploadAdditionModal("billModal")}
                          className="CursorPointer"
                        >
                          <figure>
                            <img src="images/upload_additional.png" />
                          </figure>{" "}
                          Additional Documents &nbsp;
                          <span className="Count">
                            {additionalDocumentsCount}
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between mt-4 ">
                <button
                  className="Create"
                  onClick={() => updateBillFunc("SEND")}
                >
                  Save and Send <img src="images/white_down_arrow.png" />
                </button>
                <button
                  className="Create"
                  onClick={() => updateBillFunc("DRAFT")}
                >
                  Save draft
                </button>
                <button className="Outline" onClick={hideBillModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* BUDGETCHANGEORDERMODALSHOW */}
      <Modal
        show={BudgetChangeOrderModal}
        onHide={hideBudgetChangeOrderModal}
        className="LargeModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideBudgetChangeOrderModal}
            role="button"
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create New Budget Change Order</h4>
            </div>
            <div className="FolderForm">
              <div className="ModalBigheading">
                <h3>
                  <span>Budget Change Order</span>
                </h3>
                {/* <h3>
                  <span>Project:</span> Rivers Edge Homes
                </h3> */}
                <h3>
                  <span>Cost Code:</span> {costCodeStaticName} ({costCodeStatic}
                  )
                </h3>
                <h3>
                  <span>Job:</span> {state?.jobNumber}
                </h3>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="form-group col-lg-8">
                      <h6>Document Description</h6>
                      <textarea
                        className="form-control"
                        rows={2}
                        placeholder="Insert Description"
                        name="documentDescription"
                        value={documentDescription}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {budgetChangeError?.documentDescriptionError}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-lg-9">
                      <div className="Categories Categories1">
                        <h6>Reason</h6>
                        <div className="Categories_all Categories_all1 p-0">
                          <select
                            name="reasonId"
                            value={reasonId}
                            onChange={handleInput}
                          >
                            <option>Select Reason</option>
                            {checkArray(reasonListData) ? (
                              <>
                                {reasonListData?.map((ele, i) => {
                                  return (
                                    <option value={ele?.id}>
                                      {ele?.description}
                                    </option>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </select>
                          <img src="images/down.png" />
                        </div>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {budgetChangeError?.reasonIdError}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-10">
                      {/* <div className="ModalBigheading">
                        <h3>
                          <span>Current Working Budget:</span> $6,000.00
                        </h3>
                      </div> */}
                      <div className="form-group">
                        <h6>Budget Increase Amount</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert increase amount pre-tax"
                          name="increaseAmount"
                          value={increaseAmount}
                          onChange={handleInput}
                        />
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {budgetChangeError?.increaseAmountError}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-6">
                  <div className="ModalBigheading">
                    <h3>
                      <span>Remaining Budget:</span> $0.00
                    </h3>
                  </div>
                </div> */}
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-10">
                      <div className="form-group">
                        <div className="Categories Categories1">
                          <h6>Related Document</h6>
                          <div className="Categories_all Categories_all1 p-0">
                            <select
                              name="relatedDocumentId"
                              value={relatedDocumentId}
                              onChange={handleInput}
                            >
                              <option>Select Related Docs</option>
                              {quoteRequestListData?.data?.length > 0 &&
                                quoteRequestListData?.data?.map((ele, i) => {
                                  return (
                                    <option value={ele?.id} key={i}>
                                      {ele?.documentCode}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {budgetChangeError?.relatedDocumentIdError}
                          </span>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="CommonModalArea">
                          <h5>Add Supporting Document</h5>
                          <div className="CommonModalBox">
                            <h5
                              className="CursorPointer FolderPermissionId"
                              onClick={() => showSupportingDocsModal()}
                            >
                              <figure>
                                <img src="images/icon-63.png" />
                              </figure>
                              Add Supporting Document
                            </h5>
                            <span style={{ color: "red", fontSize: "14px" }}>
                              {budgetChangeError?.supportingDocsError}
                            </span>
                            {/* <span
                                className="Count CursorPointer FolderPermissionId"
                                data-toggle="modal"
                                data-target="#SupportingDocumentListQuoteRequestPurchase"
                                data-dismiss="modal"
                              >
                                3
                              </span> */}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories1">
                          <h6>Builder Signee</h6>
                          <div className="Categories_all Categories_all1 p-0">
                            <select
                              name="builderSigneeId"
                              value={builderSigneeId}
                              onChange={handleInput}
                            >
                              <option>Select Builder</option>
                              {checkArray(builderSigneeListData) ? (
                                <>
                                  {builderSigneeListData?.map((ele, i) => {
                                    if (ele.name)
                                      return (
                                        <option value={ele?.id}>
                                          {ele?.name}
                                        </option>
                                      );
                                  })}
                                </>
                              ) : (
                                <></>
                              )}
                            </select>
                          </div>
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {budgetChangeError?.builderSigneeIdError}
                          </span>
                        </div>
                      </div>
                      <div className="ModalBigheading">
                        <h3>
                          <span>Investor Signee</span>
                        </h3>
                        <h3>
                          {/* <span>Company:</span> */}
                          <div className="form-group">
                            <div className="Categories Categories1">
                              <h6>Investor</h6>
                              <div className="Categories_all Categories_all1 p-0">
                                <select
                                  name="vendorId"
                                  value={vendorId}
                                  onChange={handleInput}
                                >
                                  <option>Select Investor</option>
                                  {checkArray(vendorSearchData) &&
                                    vendorSearchData?.map((ele, i) => {
                                      return (
                                        <option value={ele?.id}>
                                          {ele.vendorName}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                              <span style={{ color: "red", fontSize: "14px" }}>
                                {budgetChangeError?.vendorIdError}
                              </span>
                            </div>
                          </div>
                        </h3>
                      </div>
                      <div className="form-group">
                        <h6>Full Name</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Jane Investor"
                          name="InvesterfullName"
                          value={InvesterfullName}
                          onChange={handleInput}
                          // disabled= {vendorId}
                        />
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {budgetChangeError?.vendorIdError}
                        </span>
                      </div>
                      <div className="form-group">
                        <h6>Email</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert email"
                          name="InvesterEmail"
                          value={InvesterEmail}
                          onChange={handleInput}
                          // disabled= {vendorId}
                        />
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {budgetChangeError?.vendorIdError}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="Button mb-4">
                    <button className="Create">
                      Generate /update Budget Change Order
                    </button>
                  </div>
                  <div className="form-group">
                    <div className="CommonModalArea">
                      <h5>Budget Change Order Agreement</h5>
                      <div className="CommonModalBox">
                        <figure
                          className="CursorPointer FolderPermissionId"
                          data-toggle="modal"
                          data-target="#ViewBudgetChangeOrderAgreementLeft"
                          data-dismiss="modal"
                        >
                          <img src="images/view_budget.png" />
                        </figure>
                        &nbsp;
                        <h5>
                          <span className="Count">3</span> Budget Change Order
                          Agreement
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <h6>Authorization Type</h6>
                    <label className="Radio">
                      Electronic
                      <input
                        type="radio"
                        name="authorisationType"
                        value="Electronic"
                        onChange={handleCheckbox}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="Radio">
                      Hardcopy
                      <input
                        type="radio"
                        name="authorisationType"
                        value="Hardcopy"
                        onChange={handleCheckbox}
                      />
                      <span className="checkmark" />
                    </label>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {budgetChangeError?.authorisationTypeError}
                    </span>
                  </div>

                  <div className="text RadioText">
                    {/* <div class="form-group">
                <h6>Message (Max 1000 chars limit)</h6>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Write here ....."
                />
                <small class="form-text text-right"
                  >985 characters remaining</small
                >
              </div> */}
                  </div>
                  <div className="file RadioText">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Download Hardcopy Budget Change Order</h5>
                        <div className="CommonModalBox">
                          <h5>
                            <figure>
                              <img src="images/view_budget.png" />
                            </figure>{" "}
                            <span className="Count">3</span>
                            Download Hardcopy Budget Change Order
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Budget Change Order Agreement</h5>
                        <div className="CommonModalBox">
                          <h5>
                            <figure>
                              <img src="images/upload_signed_agreement.png" />
                            </figure>{" "}
                            <span className="Count">3</span> Budget Change Order
                            Agreement
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <div className="CommonModalArea">
                      <h5>Reminder</h5>
                      <div className="CommonModalBox">
                        <h5>
                          <figure
                            onClick={() =>
                              showAddRemianderModal("budgetChange")
                            }
                          >
                            <img src="images/timer.png" />
                          </figure>{" "}
                          <h5>{day || week} days</h5>
                        </h5>
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {budgetChangeError?.reminderDateError}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button mt-3 d-flex flex-lg-row flex-column justify-content-between">
                <button onClick={(e) => budgetChangeFunc(e)} className="Create">
                  Send Budget Change Order
                </button>
                <button
                  onClick={(e) => budgetChangeFunc(e, "DRAFT")}
                  className="Create my-2 mt-lg-0 "
                >
                  Save Draft
                </button>
                <button
                  className="Outline"
                  onClick={hideBudgetChangeOrderModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={reminderModal}
        onHide={hideAddRemianderModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAddRemianderModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Reminder</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>
                  Weeks <span className="required"></span>
                </h6>
                <div className="CommonSelectBox w-100">
                  <select
                    disabled={day}
                    name="week"
                    value={week}
                    onChange={handleInput}
                  >
                    <option value="">
                      Select amount of weeks for reminder
                    </option>
                    <option value="7">1</option>
                    <option value="14">2</option>
                    <option value="21">3</option>
                    <option value="28">4</option>
                  </select>
                </div>
                {/* </div> */}
              </div>

              <div className="form-group">
                <h6>
                  Days <span className="required"></span>
                </h6>
                <div className="CommonSelectBox w-100">
                  <select
                    disabled={week}
                    name="day"
                    value={day}
                    onChange={handleInput}
                  >
                    <option value="">Select amount of days for reminder</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </select>
                </div>
              </div>
              <div className="Button text-center mt-5">
                <button className="Create" onClick={hideAddRemianderModal}>
                  Set Reminder
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={BillQuoteReqModal}
        onHide={hideBillQuoteReqModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideBillQuoteReqModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Add Item Details</h4>
            </div>
            <div className="FolderForm">
              <div className="ModalBigheading">
                <h3>
                  <span>QR/PO Mass Mailout #</span>147
                </h3>
                <h3>
                  <span>QR/PO Mass Mailout Category #</span>1
                </h3>
              </div>
              <div className="ItemDetails">
                <h6>Item Details</h6>
              </div>
              <div className="Tableheader mb-3">
                <h6 className="mb-0">Item</h6>
                <div className="Actions">
                  <a href="javascript:void(0);">
                    <img src="images/icon-53.png" />
                  </a>
                  <DownloadTableExcel
                    filename="Quote item List"
                    sheet="Quote items list"
                    currentTableRef={quoteRef.current}
                  >
                    <button href="jacascript:void(0);">
                      <img src="images/download.png" />
                    </button>
                  </DownloadTableExcel>
                  <a href="javascript:void(0);">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
              <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                <table ref={quoteRef}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        <div className="text-center">
                          Material Description
                          <br />
                          (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                        </div>
                      </th>
                      <th>Color</th>
                      <th>Quantity</th>
                      <th>Units</th>
                      <th>Price/Unit</th>
                      <th>Total</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {billQuoteItems?.map((item, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              value={item.name}
                              onChange={(e) =>
                                handleChangeArrayItem(e, i, "billItemLine")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              name="color"
                              value={item.color}
                              onChange={(e) =>
                                handleChangeArrayItem(e, i, "billItemLine")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              name="quantity"
                              value={item.quantity}
                              onChange={(e) =>
                                handleChangeArrayItem(e, i, "billItemLine")
                              }
                            />
                          </td>
                          <td>
                            <select
                              className="form-control"
                              name="unitId"
                              value={item.unitId}
                              onChange={(e) =>
                                handleChangeArrayItem(e, i, "billItemLine")
                              }
                            >
                              <option value=""> Select Option</option>
                              {checkArray(estimationUnitListData) &&
                                estimationUnitListData?.map((ele, i) => {
                                  return (
                                    <option value={ele?.id}>
                                      {" "}
                                      {ele?.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              name="price"
                              value={item.price}
                              onChange={(e) =>
                                handleChangeArrayItem(e, i, "billItemLine")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              name="total"
                              disabled
                              value={+item?.price * +item?.quantity}
                              onChange={(e) =>
                                handleChangeArrayItem(e, i, "billItemLine")
                              }
                            />
                          </td>
                          <td>
                            <div className="TableThreeDots">
                              <ul className="">
                                <li className="dropdown">
                                  <a
                                    className=""
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/dots.png" />
                                  </a>
                                  <ol className="dropdown-menu">
                                    <li>
                                      <a
                                        onClick={(e) =>
                                          addExtraRowInFunc(e, "billItemLine")
                                        }
                                        href="javascript:void(0);"
                                      >
                                        <img src="images/add_new_column.png" />
                                        Add New Line
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() =>
                                          removeLine(i, "billItemLine")
                                        }
                                        href="javascript:void(0);"
                                      >
                                        <img src="images/dropdown-delete.png" />
                                        Remove Line
                                      </a>
                                    </li>
                                  </ol>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                    <span style={{ color: "red", fontSize: "18px" }}>
                      {billNewQuoteError}
                    </span>
                    {/* <span style={{color:"red", fontSize:"30px"}}>{"itdjkfhdkjghdfgkldfhglfdkghdflemErrors"}</span> */}
                    <tr>
                      <td colSpan={8}>
                        <div className="TableButtons">
                          <button
                            onClick={(e) =>
                              addExtraRowInFunc(e, "billItemLine")
                            }
                            className="BrandBtn"
                          >
                            Add Line
                          </button>
                          <button
                            onClick={(e) => clearAllLines(e, "billItemLine")}
                            className="BrandBtn ml-2"
                          >
                            Clear all lines
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="Button d-flex justify-content-center mt-5">
                <button className="Create" onClick={insertQuoteIntoBill}>
                  Insert Items
                </button>
                <button
                  className="Outline ml-2"
                  onClick={hideBillQuoteReqModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* NEWQUOTEREQUESTMODALSHOW */}
      <Modal
        show={FieldPurchaseOrderModal}
        onHide={hideFieldPurchaseOrder}
        className="LargeModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideFieldPurchaseOrder}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox ModalTitleBoxGreen">
              <h4>Create New Field Purchase Order</h4>
            </div>
            <div className="FolderForm">
              <div className="BlueTextArea GreenTextArea">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="Heading">
                      <h3>Field Quote Request # {estimationDocumentId} </h3>
                    </div>
                    <a
                      href="javascript:void(0);"
                      className="BlueOutline mb-4"
                      onClick={() =>
                        showSupplierCreditModal("fieldPurchaseOrder")
                      }
                    >
                      <img src="images/create_quote_blue.png" />
                      Create as Supplier Credit
                    </a>
                    <div className="row">
                      <div className="form-group col-lg-9">
                        <div className="Categories Categories1">
                          <h6>Reason for Creating FPO</h6>
                          <div
                            className="Categories_all Categories_all1"
                            style={{ padding: "0px" }}
                          >
                            <select
                              name="reasonId"
                              value={reasonId}
                              onChange={handleInput}
                            >
                              <option>Select Reason</option>
                              {checkArray(reasonListData) ? (
                                <>
                                  {reasonListData?.map((ele, i) => {
                                    return (
                                      <option value={ele?.id}>
                                        {ele?.description}
                                      </option>
                                    );
                                  })}
                                </>
                              ) : (
                                <></>
                              )}
                            </select>
                            <img src="images/down.png" />
                          </div>
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {newQuoteRequestError?.reasonIdError}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="Heading">
                      <h3 className="d-flex">
                        Job Id: {state?.jobNumber}
                        <div className="TableThreeDots ml-5">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    onClick={() =>
                                      showRequestLimitIncresaseModal(
                                        "fieldPurchaseOrder"
                                      )
                                    }
                                  >
                                    <img src="images/request_limit.png" />
                                    Request Limit Increase
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#VendorBackChargeNotificationLeft"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/notify_vendor.png" />
                                    Notify Vendor of Back Charge
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </h3>
                      <h3>
                        <span> Cost Code: </span>
                        {`${costCodeStatic} ${" "} (${costCodeStaticName})`}
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="ModalFilterAreaBox">
                      <h4 className="mb-4">Vendor</h4>
                      <div className="RadioArea d-flex  flex-column">
                        {/* <div className="form-group mb-3">
                          <label className="Radio">
                            Use Cost Code's preferred vendor
                            <input type="radio" name="Cuisine" />
                            <span className="checkmark" />
                          </label>
                        </div> */}
                      </div>
                      {/* <span  style={{color:"red", fontSize:"14px"}}>{newQuoteRequestError?.vendorIdError}</span> */}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <h6>Date</h6>
                      <p className="ml-0">{getDate()}</p>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {newQuoteRequestError?.dateError}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5 className="GreenText">Reminder</h5>
                        <div className="CommonModalBox">
                          <figure
                            className="CursorPointer FolderPermissionId"
                            onClick={() =>
                              showAddRemianderModal("fieldPurchaseOrder")
                            }
                          >
                            <img src="images/timer.png" />
                          </figure>
                          <h5>{day || week} days</h5>
                        </div>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {newQuoteRequestError?.reminderDateError}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <div className="CommonModalBox">
                          <figure
                            className="CursorPointer FolderPermissionId"
                            onClick={() =>
                              showSystemVendor("fieldPurchaseOrder")
                            }
                          >
                            <img src="images/vendor.png" />
                          </figure>
                          <h5 className="text-black">
                            {vendorDetailsData?.vendorName ?? "Select Vendor"}
                          </h5>
                        </div>{" "}
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {newQuoteRequestError?.vendorIdError}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ShippingAddressArea">
                      <div className="ShippingAddressAreaBox">
                        <h6>Mailing Address</h6>
                        <p>
                          {vendorDetailsData?.addressLine1}{" "}
                          {vendorDetailsData?.addressLine2}{" "}
                          {vendorDetailsData?.city}
                        </p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Email</h6>
                        <p> {vendorDetailsData?.email}</p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Phone</h6>
                        <p> {vendorDetailsData?.phone}</p>
                      </div>
                      {/* <div className="ShippingAddressAreaBox">
                <h6>Shipping Address</h6>
                <p>5384 Peach Rd Chilliwack, BC V2R0Z6</p>
              </div> */}
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <h6>Messsage</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert message to be sent to vendor"
                        name="message"
                        value={message}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {newQuoteRequestError?.messageError}
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ItemDetails">
                      <h6>Item Details</h6>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="Tableheader mb-3">
                      <h6 className="mb-0">Item</h6>
                      <div className="Actions">
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <DownloadTableExcel
                          filename="Quote item List"
                          sheet="Quote items list"
                          currentTableRef={quoteRef.current}
                        >
                          <button href="jacascript:void(0);">
                            <img src="images/download.png" />
                          </button>
                        </DownloadTableExcel>
                        <a href="javascript:void(0);">
                          <img src="images/setting.png" />
                        </a>
                      </div>
                    </div>
                    <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                      <table ref={quoteRef}>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>
                              <div className="text-center">
                                Material Description
                                <br />
                                (i.e: Modal/Type/Style/manuf part#/Dimensions,
                                etc.)
                              </div>
                            </th>
                            <th>Color</th>
                            <th>Quantity</th>
                            <th>Units</th>
                            <th>Price/Unit</th>
                            <th>Total</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items?.map((item, i) => {
                            return (
                              <tr>
                                <td>{i + 1}</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={item.name}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="color"
                                    value={item.color}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="quantity"
                                    value={item.quantity}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  {/* <div className="SelectAnOption">
                          <div className="dropdown"> */}
                                  {/* <button
                              className=""
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            > */}
                                  {/* Select measure type
                             <img src="images/black-down-arrow.png" /> */}
                                  <select
                                    className="form-control"
                                    name="unitId"
                                    value={item.unitId}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  >
                                    <option value=""> Select Option</option>
                                    {checkArray(estimationUnitListData) &&
                                      estimationUnitListData?.map((ele, i) => {
                                        return (
                                          <option value={ele?.id}>
                                            {" "}
                                            {ele?.name}
                                          </option>
                                        );
                                      })}
                                  </select>

                                  {/* </button> */}
                                  {/* <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                <input type="text" placeholder="-Search" />
                                <span>
                                  <img src="images/search.png" />
                                </span>
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                <img src="images/add-plus.png" />
                                Add New
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                - Full list of units loaded from the system
                              </a>
                            </div> */}
                                  {/* </div>
                        </div> */}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="price"
                                    value={item.price}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="total"
                                    disabled
                                    value={+item.price * +item.quantity}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <div className="TableThreeDots">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol className="dropdown-menu">
                                          <li>
                                            <a
                                              onClick={(e) =>
                                                addExtraRowInFunc(e, "quote")
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/add_new_column.png" />
                                              Add New Line
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                removeLine(i, "newQuote")
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/dropdown-delete.png" />
                                              Remove Line
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                          <span style={{ color: "red", fontSize: "18px" }}>
                            {itemErrors}
                          </span>
                          {/* <span style={{color:"red", fontSize:"30px"}}>{"itdjkfhdkjghdfgkldfhglfdkghdflemErrors"}</span> */}

                          <tr>
                            <td colSpan={8}>
                              <div className="TableButtons">
                                <button
                                  onClick={(e) => addExtraRowInFunc(e, "quote")}
                                  className="BrandBtn"
                                >
                                  Add Line
                                </button>
                                <button
                                  onClick={(e) => clearAllLines(e, "quote")}
                                  className="BrandBtn ml-2"
                                >
                                  Clear all lines
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 ml-auto">
                        <div className="GstTable">
                          <table>
                            <tbody>
                              <tr>
                                <td>Profile</td>
                                <div class="CommonSelectBox">
                                  <select
                                    // name="profile"
                                    value={profiles}
                                    onChange={(e) =>
                                      handleProfileChanges(e.target.value)
                                    }
                                  >
                                    {/* <option value="">Select profile</option> */}
                                    {checkArray(profileListData) &&
                                      profileListData?.map((ele, i) => {
                                        return (
                                          <option value={ele?.id}>
                                            {ele?.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {newQuoteRequestError?.profileError}
                                </span>
                              </tr>
                              <tr>
                                <td>Subtotal</td>
                                <td>
                                  {subtotal ?? 0}
                                  <span
                                    className="estimationModalBelowProfile"
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {newQuoteRequestError?.subtotalError}
                                  </span>
                                </td>

                                <td>Modify Tax</td>
                              </tr>
                              {showTaxFlag ? (
                                <tr>
                                  <td>
                                    {taxName}({taxPercentage}%)
                                  </td>
                                  <td>
                                    {taxAmount || 0}
                                    <span
                                      className="estimationModalBelowProfile"
                                      style={{ color: "red", fontSize: "14px" }}
                                    >
                                      {newQuoteRequestError?.subtotalError}
                                    </span>
                                  </td>
                                  <td>
                                    <div class="TableThreeDots">
                                      <ul class="">
                                        <li class="dropdown">
                                          <a
                                            class=""
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img
                                              src="images/dots.png"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                            />
                                          </a>
                                          <ol class="dropdown-menu">
                                            <li>
                                              <a
                                                onClick={removeTaxProfileFunc}
                                                href="javascript:void(0);"
                                              >
                                                <img src="images/dropdown-delete.png" />
                                                Remove Line
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )}

                              <tr>
                                <td>{`${taxNames} (${taxPercentages} %)`}</td>
                                <td>
                                  {Number(subtotal) *
                                    (Number(taxPercentages) / 100)}
                                </td>
                                <span
                                  className="estimationModalBelowProfile"
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {newQuoteRequestError?.gstError}
                                </span>
                                {!showTaxFlag && (
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown">
                                        <a
                                          class=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img
                                            src="images/dots.png"
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                            }}
                                          />
                                        </a>
                                        <ol class="dropdown-menu">
                                          <li>
                                            <a
                                              onClick={() =>
                                                showAddTaxProfileModal(
                                                  "quoteRequest"
                                                )
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/add_new_column.png" />
                                              Add New Column
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </tr>

                              <tr>
                                <td>Grand Total</td>
                                <td>
                                  {Number(subtotal) +
                                    Number(subtotal) *
                                      (Number(taxPercentages) / 100) +
                                    (Number(taxAmount) || 0)}
                                </td>
                                <span
                                  className="estimationModalBelowProfile"
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    textAlign: "right",
                                  }}
                                >
                                  {newQuoteRequestError?.grandTotalError}
                                </span>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-4">
                      <div className="Heading">
                        <h6>Attachments</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Scope of Work</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            onClick={() =>
                              showScopeWorkIdModal("fieldPurchaseOrder", "work")
                            }
                          >
                            <figure>
                              <img src="images/scopeofwork.png" />
                            </figure>{" "}
                            Scope of Work &nbsp;
                            <span className="Count">{quoteScopeCount}</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Construction Documents</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            onClick={() =>
                              showScopeWorkIdModal(
                                "fieldPurchaseOrder",
                                "construction"
                              )
                            }
                          >
                            <figure>
                              <img src="images/construction_documents.png" />
                            </figure>{" "}
                            Construction Documents &nbsp;
                            <span className="Count">{quoteConstructCount}</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Additional Document</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            onClick={() =>
                              showUploadAdditionModal("fieldPurchaseOrder")
                            }
                          >
                            <figure>
                              <img src="images/extermal_quote.png" />
                            </figure>{" "}
                            Additional Document &nbsp;
                            <span className="Count">3</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between mt-4 ">
                  <div className="dropdown ml-3">
                    <button
                      className="Create"
                      type="button"
                      onClick={(e) =>
                        handleQuoteRequestFunc(e, "SEND", "fieldPurchaseOrder")
                      }
                    >
                      Save and Send
                      <img src="images/black-down-arrow.png" />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a className="dropdown-item" href="#">
                        Save and Print
                      </a>
                      <a className="dropdown-item" href="#">
                        Save and Send
                      </a>
                    </div>
                  </div>
                  <button
                    onClick={(e) =>
                      handleQuoteRequestFunc(e, "DRAFT", "fieldPurchaseOrder")
                    }
                    className="Create"
                  >
                    Save draft
                  </button>
                  <button className="Create" onClick={hideFieldPurchaseOrder}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* SupplierCreateModalshow */}
      <Modal
        show={SupplyCreditModal}
        onHide={hideSupplierCreditModal}
        className="LargeModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideSupplierCreditModal}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox ModalTitleBoxRed">
              <h4>Create New Supplier Credit Order</h4>
            </div>
            <div className="FolderForm">
              <div className="BlueTextArea RedTextArea">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="Heading">
                      <h3>Supplier credit Request # ${estimationDocumentId}</h3>
                    </div>
                    <a
                      href="javascript:void(0);"
                      className="BlueOutline mb-4"
                      onClick={() => showFieldPurchaseOrder("supplierCredit")}
                    >
                      <img src="images/create_quote_blue.png" />
                      Create as Field Purchase Order
                    </a>
                    <div className="row">
                      <div className="form-group col-lg-9">
                        <div className="Categories Categories1">
                          <h6>Reason for Creating SCO</h6>
                          <div
                            className="Categories_all Categories_all1"
                            style={{ padding: "0px" }}
                          >
                            <select
                              name="reasonId"
                              value={reasonId}
                              onChange={handleInput}
                            >
                              <option>Select Reason</option>
                              {checkArray(reasonListData) ? (
                                <>
                                  {reasonListData?.map((ele, i) => {
                                    return (
                                      <option value={ele?.id}>
                                        {ele?.description}
                                      </option>
                                    );
                                  })}
                                </>
                              ) : (
                                <></>
                              )}
                            </select>
                            <img src="images/down.png" />
                          </div>
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {newQuoteRequestError?.reasonIdError}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="Heading">
                      <h3 className="d-flex">
                        Job Id: {state?.jobNumber}
                        <div className="TableThreeDots ml-5">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    onClick={() =>
                                      showRequestLimitIncresaseModal(
                                        "supplierCredit"
                                      )
                                    }
                                  >
                                    <img src="images/request_limit.png" />
                                    Request Limit Increase
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#VendorBackChargeNotificationLeft"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/notify_vendor.png" />
                                    Notify Vendor of Back Charge
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </h3>
                      <h3>
                        <span> Cost Code: </span>
                        {`${costCodeStatic} ${" "} (${costCodeStaticName})`}
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="ModalFilterAreaBox">
                      <h4 className="mb-4">Vendor</h4>
                      <div className="RadioArea d-flex  flex-column">
                        {/* <div className="form-group mb-3">
                          <label className="Radio">
                            Use Cost Code's preferred vendor
                            <input type="radio" name="Cuisine" />
                            <span className="checkmark" />
                          </label>
                        </div> */}
                        {/* <div className="form-group">
                          <label className="Radio">
                            Select from list
                            <input type="radio" name="Cuisine" />
                            <span className="checkmark" />
                          </label>
                        </div> */}
                      </div>
                      {/* <span  style={{color:"red", fontSize:"14px"}}>{newQuoteRequestError?.vendorIdError}</span> */}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <h6>Date</h6>
                      <p className="ml-0">{getDate()}</p>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {newQuoteRequestError?.dateError}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5 className="RedText">Reminder</h5>
                        <div className="CommonModalBox">
                          <figure
                            className="CursorPointer FolderPermissionId"
                            onClick={() =>
                              showAddRemianderModal("supplierCredit")
                            }
                          >
                            <img src="images/timer.png" />
                          </figure>
                          <h5>{day || week} days</h5>
                        </div>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {newQuoteRequestError?.reminderDateError}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <div className="CommonModalBox">
                          <figure
                            className="CursorPointer FolderPermissionId"
                            onClick={() => showSystemVendor("supplierCredit")}
                          >
                            <img src="images/vendor.png" />
                          </figure>
                          <h5 className="text-black">
                            {vendorDetailsData?.vendorName ?? "Select Vendor"}
                          </h5>
                        </div>{" "}
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {newQuoteRequestError?.vendorIdError}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ShippingAddressArea">
                      <div className="ShippingAddressAreaBox">
                        <h6>Mailing Address</h6>
                        <p>
                          {vendorDetailsData?.addressLine1}{" "}
                          {vendorDetailsData?.addressLine2}{" "}
                          {vendorDetailsData?.city}
                        </p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Email</h6>
                        <p> {vendorDetailsData?.email}</p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Phone</h6>
                        <p> {vendorDetailsData?.phone}</p>
                      </div>
                      {/* <div className="ShippingAddressAreaBox">
                <h6>Shipping Address</h6>
                <p>5384 Peach Rd Chilliwack, BC V2R0Z6</p>
              </div> */}
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <h6>Messsage</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert message to be sent to vendor"
                        name="message"
                        value={message}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {newQuoteRequestError?.messageError}
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ItemDetails">
                      <h6>Item Details</h6>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="Tableheader mb-3">
                      <h6 className="mb-0">Item</h6>
                      <div className="Actions">
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <DownloadTableExcel
                          filename="Quote item List"
                          sheet="Quote items list"
                          currentTableRef={quoteRef.current}
                        >
                          <button href="jacascript:void(0);">
                            <img src="images/download.png" />
                          </button>
                        </DownloadTableExcel>
                        <a href="javascript:void(0);">
                          <img src="images/setting.png" />
                        </a>
                      </div>
                    </div>
                    <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                      <table ref={quoteRef}>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>
                              <div className="text-center">
                                Material Description
                                <br />
                                (i.e: Modal/Type/Style/manuf part#/Dimensions,
                                etc.)
                              </div>
                            </th>
                            <th>Color</th>
                            <th>Quantity</th>
                            <th>Units</th>
                            <th>Price/Unit</th>
                            <th>Total</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items?.map((item, i) => {
                            return (
                              <tr>
                                <td>{i + 1}</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={item.name}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="color"
                                    value={item.color}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="quantity"
                                    value={item.quantity}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  {/* <div className="SelectAnOption">
                          <div className="dropdown"> */}
                                  {/* <button
                              className=""
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            > */}
                                  {/* Select measure type
                             <img src="images/black-down-arrow.png" /> */}
                                  <select
                                    className="form-control"
                                    name="unitId"
                                    value={item.unitId}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  >
                                    <option value=""> Select Option</option>
                                    {checkArray(estimationUnitListData) &&
                                      estimationUnitListData?.map((ele, i) => {
                                        return (
                                          <option value={ele?.id}>
                                            {" "}
                                            {ele?.name}
                                          </option>
                                        );
                                      })}
                                  </select>

                                  {/* </button> */}
                                  {/* <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                <input type="text" placeholder="-Search" />
                                <span>
                                  <img src="images/search.png" />
                                </span>
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                <img src="images/add-plus.png" />
                                Add New
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                - Full list of units loaded from the system
                              </a>
                            </div> */}
                                  {/* </div>
                        </div> */}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="price"
                                    value={item.price}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="total"
                                    disabled
                                    value={+item.price * +item.quantity}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <div className="TableThreeDots">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol className="dropdown-menu">
                                          <li>
                                            <a
                                              onClick={(e) =>
                                                addExtraRowInFunc(e, "quote")
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/add_new_column.png" />
                                              Add New Line
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                removeLine(i, "newQuote")
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/dropdown-delete.png" />
                                              Remove Line
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                          <span style={{ color: "red", fontSize: "18px" }}>
                            {itemErrors}
                          </span>
                          {/* <span style={{color:"red", fontSize:"30px"}}>{"itdjkfhdkjghdfgkldfhglfdkghdflemErrors"}</span> */}

                          <tr>
                            <td colSpan={8}>
                              <div className="TableButtons">
                                <button
                                  onClick={(e) => addExtraRowInFunc(e, "quote")}
                                  className="BrandBtn"
                                >
                                  Add Line
                                </button>
                                <button
                                  onClick={(e) => clearAllLines(e, "quote")}
                                  className="BrandBtn ml-2"
                                >
                                  Clear all lines
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 ml-auto">
                        <div className="GstTable">
                          <table>
                            <tbody>
                              <tr>
                                <td>Profile</td>
                                <div class="CommonSelectBox">
                                  <select
                                    // name="profile"
                                    value={profiles}
                                    onChange={(e) =>
                                      handleProfileChanges(e.target.value)
                                    }
                                  >
                                    {/* <option value="">Select profile</option> */}
                                    {checkArray(profileListData) &&
                                      profileListData?.map((ele, i) => {
                                        return (
                                          <option value={ele?.id}>
                                            {ele?.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {newQuoteRequestError?.profileError}
                                </span>
                              </tr>
                              <tr>
                                <td>Subtotal</td>
                                <td>
                                  {subtotal || 0}
                                  <span
                                    className="estimationModalBelowProfile"
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {newQuoteRequestError?.subtotalError}
                                  </span>
                                </td>
                                <td>Modify Tax</td>
                              </tr>
                              {showTaxFlag ? (
                                <tr>
                                  <td>
                                    {taxName}({taxPercentage}%)
                                  </td>
                                  <td>
                                    {taxAmount || 0}
                                    <span
                                      className="estimationModalBelowProfile"
                                      style={{ color: "red", fontSize: "14px" }}
                                    >
                                      {newQuoteRequestError?.subtotalError}
                                    </span>
                                  </td>
                                  <td>
                                    <div class="TableThreeDots">
                                      <ul class="">
                                        <li class="dropdown">
                                          <a
                                            class=""
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img
                                              src="images/dots.png"
                                              style={{
                                                width: "100px",
                                                height: "20px",
                                              }}
                                            />
                                          </a>
                                          <ol class="dropdown-menu">
                                            <li>
                                              <a
                                                onClick={removeTaxProfileFunc}
                                                href="javascript:void(0);"
                                              >
                                                <img src="images/dropdown-delete.png" />
                                                Remove Line
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )}

                              <tr>
                                <td>{`${taxNames} (${taxPercentages} %)`}</td>
                                <td>
                                  {Number(subtotal) *
                                    (Number(taxPercentages) / 100)}
                                </td>

                                <span
                                  className="estimationModalBelowProfile"
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {newQuoteRequestError?.subtotalError}
                                </span>
                                {!showTaxFlag && (
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown">
                                        <a
                                          class=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img
                                            src="images/dots.png"
                                            style={{
                                              width: "100px",
                                              height: "20px",
                                            }}
                                          />
                                        </a>
                                        <ol class="dropdown-menu">
                                          <li>
                                            <a
                                              onClick={() =>
                                                showAddTaxProfileModal(
                                                  "quoteRequest"
                                                )
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/add_new_column.png" />
                                              Add New Column
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                {/* <td>Modify Tax</td> */}
                              </tr>
                              {/* ) : (
                                <></>
                              )} */}
                              <tr>
                                <td>Grand Total</td>

                                <td>
                                  {Number(subtotal) +
                                    Number(subtotal) *
                                      (Number(taxPercentages) / 100)}
                                </td>
                                <span
                                  className="estimationModalBelowProfile"
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {newQuoteRequestError?.grandTotalError}
                                </span>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-4">
                      <div className="Heading">
                        <h6>Attachments</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Scope of Work</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            onClick={() =>
                              showScopeWorkIdModal("supplierCredit", "work")
                            }
                          >
                            <figure>
                              <img src="images/scopeofwork.png" />
                            </figure>{" "}
                            Scope of Work &nbsp;
                            <span className="Count">{quoteScopeCount}</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Construction Documents</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            onClick={() =>
                              showScopeWorkIdModal(
                                "supplierCredit",
                                "construction"
                              )
                            }
                          >
                            <figure>
                              <img src="images/construction_documents.png" />
                            </figure>{" "}
                            Construction Documents &nbsp;
                            <span className="Count">{quoteConstructCount}</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Additional Document</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            onClick={() =>
                              showUploadAdditionModal("supplierCredit")
                            }
                          >
                            <figure>
                              <img src="images/extermal_quote.png" />
                            </figure>{" "}
                            Additional Document &nbsp;
                            <span className="Count">3</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between mt-4 ">
                  <div className="dropdown ml-3">
                    <button
                      className="Create"
                      type="button"
                      onClick={(e) =>
                        handleQuoteRequestFunc(e, "SEND", "supplierCredit")
                      }
                    >
                      Save and Send
                      <img src="images/black-down-arrow.png" />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a className="dropdown-item" href="#">
                        Save and Print
                      </a>
                      <a className="dropdown-item" href="#">
                        Save and Send
                      </a>
                    </div>
                  </div>
                  <button
                    onClick={(e) =>
                      handleQuoteRequestFunc(e, "DRAFT", "supplierCredit")
                    }
                    className="Create"
                  >
                    Save draft
                  </button>
                  <button className="Create" onClick={hideSupplierCreditModal}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={supportingDocsModal}
        onHide={hideSupportingDocsModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSupportingDocsModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Add New Supporting Docs</h4>
            </div>
            <div className="JobTitleArea">
              <h3>Supporting Documents</h3>
              {/* <div className="JobBox">
                <h6>
                  <span>Job:</span> UUnit3-CHI-2B-315
                </h6>
              </div> */}
              {/* <div className="JobBox">
                <h6>
                  <span>Origin</span> #185
                </h6>
              </div>
              <div className="JobBox">
                <h6>
                  <span>Item:</span> #143
                </h6>
              </div> */}
            </div>
            <div className="form-group">
              <h6>Select Files</h6>
              <div className="Upload">
                <span>-Drag and Drop Files or click to select</span>
                <input type="file" name="files" onChange={handleInputFile} />
              </div>
              <span style={{ color: "red", fontSize: "14px" }}>
                {supportingDocsError}
              </span>
            </div>
            {/* <div className="FilesAddedTitle">
              <h4>Files</h4>
            </div> */}
            {/* <div className="FilesAdded">
              <label>File_name.docs</label>
              <span>
                <img src="images/fileview.png" />
              </span>
              <span>
                <img src="images/Cross.png" className="cross" />
              </span>
            </div>
            <div className="FilesAdded">
              <label>File_name.docs</label>
              <span>
                <img src="images/fileview.png" />
              </span>
              <span>
                <img src="images/Cross.png" className="cross" />
              </span>
            </div> */}
            <div className="Button mt-3">
              <button className="Create" onClick={addSupportingDocsFunc}>
                Add Documents
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={NewQuoteRequestModal}
        onHide={hideNewQuoteRequestModal}
        className="LargeModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideNewQuoteRequestModal}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox ModalTitleBoxYellow">
              <h4>Create New Quote Request</h4>
            </div>
            <div className="FolderForm">
              <div className="BlueTextArea YellowTextArea">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="Heading">
                      <h3>Quote Request # {quoteDocumentCodeForModal}</h3>
                    </div>
                    <a
                      href="javascript:void(0);"
                      className="BlueOutline mb-4"
                      onClick={() => showPurchaseModal(vendorDetailsData?.id)}
                    >
                      <img src="images/create_quote_blue.png" />
                      Create as Purchase Order
                    </a>
                    <div className="row">
                      <div className="form-group col-lg-9">
                        <div className="Categories Categories1">
                          <h6>Reason for Creating QR</h6>
                          <div
                            className="Categories_all Categories_all1"
                            style={{ padding: "0px" }}
                          >
                            <select
                              name="reasonId"
                              value={reasonId}
                              onChange={handleInput}
                              // disabled={reasonId}
                            >
                              <option>Select Reason</option>
                              {checkArray(reasonListData) ? (
                                <>
                                  {reasonListData?.length > 0 &&
                                    reasonListData?.map((ele, i) => {
                                      return (
                                        <option
                                          value={ele?.id}
                                          selected={ele.id == reasonId}
                                        >
                                          {ele?.description}
                                        </option>
                                      );
                                    })}
                                </>
                              ) : (
                                <></>
                              )}
                            </select>
                            <img src="images/down.png" />
                          </div>
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {newQuoteRequestError?.reasonIdError}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="Heading">
                      <h3 className="d-flex">
                        Job Id: {state?.jobNumber}
                        <div className="TableThreeDots ml-5">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    onClick={() =>
                                      showRequestLimitIncresaseModal(
                                        "quoteRequest"
                                      )
                                    }
                                  >
                                    <img src="images/request_limit.png" />
                                    Request Limit Increase
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    onClick={() =>
                                      handleVendorBackChargeNotificationModalShow(
                                        "list1",
                                        ""
                                      )
                                    }
                                  >
                                    <img src="images/notify_vendor.png" />
                                    Notify Vendor of Back Charge
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </h3>
                      <h3>
                        <span>
                          {" "}
                          Cost Code: {`${costCodeNumber}  ${costCodeName}`}
                        </span>
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="ModalFilterAreaBox">
                      <h4 className="mb-4">Select Vendor</h4>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <h6>Date</h6>
                      <p className="ml-0">{getDate()}</p>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {newQuoteRequestError?.dateError}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5 className="YellowText">Reminder</h5>
                        <div className="CommonModalBox">
                          <figure
                            className="CursorPointer FolderPermissionId"
                            onClick={() => showAddRemianderModal("quoteModal")}
                          >
                            <img src="images/timer.png" />
                          </figure>
                          <h5>{day || week} days</h5>
                        </div>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {newQuoteRequestError?.reminderDateError}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <div className="CommonModalBox">
                          <figure
                            className="CursorPointer FolderPermissionId"
                            onClick={() => showSystemVendor("quoteModal")}
                          >
                            <img src="images/vendor.png" />
                          </figure>
                          <h5 className="text-black">
                            {vendorDetailsData?.vendorName ?? "Select Vendor"}
                          </h5>
                        </div>{" "}
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {newQuoteRequestError?.vendorIdError}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ShippingAddressArea">
                      <div className="ShippingAddressAreaBox">
                        <h6>Mailing Address</h6>
                        <p>
                          {vendorDetailsData?.addressLine1}{" "}
                          {vendorDetailsData?.addressLine2}{" "}
                          {vendorDetailsData?.city}
                        </p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Email</h6>
                        <p> {vendorDetailsData?.email}</p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Phone</h6>
                        <p> {vendorDetailsData?.phone}</p>
                      </div>
                    </div>
                  </div>
                  {reasonDataa?.BackCharge && (
                    <>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <div className="CommonModalArea">
                            <h5 className="YellowText">Back Charge Vendor</h5>
                            <div className="CommonModalBox">
                              <h5 className="text-black">
                                {/* <figure>
                              <img src="images/vendor.png" />
                            </figure>{" "} */}
                                {reasonDataa?.BackCharge?.Vendor?.vendorName ??
                                  "N/A"}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-8">
                        <div className="form-group">
                          <div className="CommonModalArea">
                            <h5 className="YellowText">
                              Back Charge Notification Status
                            </h5>
                            <div className="CommonModalBox">
                              <h5 className="text-black">
                                <figure
                                  className="CursorPointer FolderPermissionId"
                                  onClick={() =>
                                    handleBackChargeNotificationListModalShow(
                                      reasonDataa?.BackCharge,
                                      "qrDetail"
                                    )
                                  }
                                >
                                  <img src="images/notification_accept.png" />
                                </figure>
                                {`${
                                  reasonDataa?.BackCharge
                                    ?.BackChargeNotifications?.[0]?.activity ??
                                  ""
                                } -${moment(
                                  reasonDataa?.BackCharge
                                    ?.BackChargeNotifications?.updatedAt
                                ).format("YYYY-MM-DD & hh:mm a")}`}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </>
                  )}
                  <div className="col-lg-7">
                    <div className="form-group">
                      <h6>Messsage</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert message to be sent to vendor"
                        name="message"
                        value={message}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {newQuoteRequestError?.messageError}
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ItemDetails">
                      <h6>Item Details</h6>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="Tableheader mb-3">
                      <h6 className="mb-0">Builder Item</h6>
                      <div className="Actions">
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <DownloadTableExcel
                          filename="Quote item List"
                          sheet="Quote items list"
                          currentTableRef={quoteRef.current}
                        >
                          <button href="jacascript:void(0);">
                            <img src="images/download.png" />
                          </button>
                        </DownloadTableExcel>
                        <a href="javascript:void(0);">
                          <img src="images/setting.png" />
                        </a>
                      </div>
                    </div>
                    <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                      <table ref={quoteRef}>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>
                              <div className="text-center">
                                Material Description
                                <br />
                                (i.e: Modal/Type/Style/manuf part#/Dimensions,
                                etc.)
                              </div>
                            </th>
                            <th>Color</th>
                            <th>Quantity</th>
                            <th>Units</th>
                            <th>Price/Unit</th>
                            <th>Total</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items?.map((item, i) => {
                            return (
                              <tr>
                                <td>{i + 1}</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={item.name}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="color"
                                    value={item.color}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="quantity"
                                    value={item.quantity}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <select
                                    className="form-control"
                                    name="unitId"
                                    value={item.unitId}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  >
                                    <option value=""> Select Option</option>
                                    {checkArray(estimationUnitListData) &&
                                      estimationUnitListData?.map((ele, i) => {
                                        return (
                                          <option value={ele?.id}>
                                            {" "}
                                            {ele?.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="price"
                                    value={item.price}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="total"
                                    value={+item.price * +item.quantity}
                                    disabled
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <div className="TableThreeDots">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol className="dropdown-menu">
                                          <li>
                                            <a
                                              onClick={(e) =>
                                                addExtraRowInFunc(e, "quote")
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/add_new_column.png" />
                                              Add New Line
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                removeLine(i, "newQuote")
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/dropdown-delete.png" />
                                              Remove Line
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                          <span style={{ color: "red", fontSize: "18px" }}>
                            {itemErrors}
                          </span>
                          {/* <span style={{color:"red", fontSize:"30px"}}>{"itdjkfhdkjghdfgkldfhglfdkghdflemErrors"}</span> */}

                          <tr>
                            <td colSpan={8}>
                              <div className="TableButtons">
                                <button
                                  onClick={(e) => addExtraRowInFunc(e, "quote")}
                                  className="BrandBtn"
                                >
                                  Add Line
                                </button>
                                <button
                                  onClick={(e) => clearAllLines(e, "quote")}
                                  className="BrandBtn ml-2"
                                >
                                  Clear all lines
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 ml-auto">
                        <div className="GstTable">
                          <table>
                            <tbody>
                              <tr>
                                <td>Profile</td>
                                <div class="CommonSelectBox">
                                  <select
                                    value={profiles}
                                    onChange={(e) =>
                                      handleProfileChanges(e.target.value)
                                    }
                                  >
                                    <option value="">Select profile</option>
                                    {checkArray(profileListData) &&
                                      profileListData?.map((ele, i) => {
                                        return (
                                          <option
                                            value={ele?.id}
                                            checked={ele.id == profile}
                                          >
                                            {ele?.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {newQuoteRequestError?.profileError}
                                </span>
                              </tr>
                              <tr>
                                <td>Subtotal</td>
                                <td>
                                  {subtotal || 0}
                                  <span
                                    className="estimationModalBelowProfile"
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {newQuoteRequestError?.subtotalError}
                                  </span>
                                </td>
                                <td>Modify Tax</td>
                              </tr>
                              {showTaxFlag ? (
                                <tr>
                                  <td>
                                    {taxName}({taxPercentage}%)
                                  </td>
                                  <td>
                                    {taxAmount || 0}
                                    <span
                                      className="estimationModalBelowProfile"
                                      style={{ color: "red", fontSize: "14px" }}
                                    >
                                      {newQuoteRequestError?.subtotalError}
                                    </span>
                                  </td>
                                  <td>
                                    <div class="TableThreeDots">
                                      <ul class="">
                                        <li class="dropdown">
                                          <a
                                            class=""
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img
                                              src="images/dots.png"
                                              style={{
                                                width: "100px",
                                                height: "20px",
                                              }}
                                            />
                                          </a>
                                          <ol class="dropdown-menu">
                                            <li>
                                              <a
                                                onClick={removeTaxProfileFunc}
                                                href="javascript:void(0);"
                                              >
                                                <img src="images/dropdown-delete.png" />
                                                Remove Line
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )}

                              <tr>
                                <td>{`${taxNames} (${taxPercentages} %)`}</td>
                                <td>
                                  {Number(subtotal) *
                                    (Number(taxPercentages) / 100)}
                                </td>
                                <span
                                  className="estimationModalBelowProfile"
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {newQuoteRequestError?.gstError}
                                </span>
                                {!showTaxFlag && (
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown">
                                        <a
                                          class=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img
                                            src="images/dots.png"
                                            style={{
                                              width: "100px",
                                              height: "20px",
                                            }}
                                          />
                                        </a>
                                        <ol class="dropdown-menu">
                                          <li>
                                            <a
                                              onClick={() =>
                                                showAddTaxProfileModal(
                                                  "quoteRequest"
                                                )
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/add_new_column.png" />
                                              Add New Column
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </tr>

                              <tr>
                                <td>Grand Total</td>
                                <td>
                                  {Number(subtotal) +
                                    Number(subtotal) *
                                      (Number(taxPercentages) / 100) +
                                    (Number(taxAmount) || 0)}
                                </td>
                                <span
                                  className="estimationModalBelowProfile"
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    textAlign: "right",
                                  }}
                                >
                                  {newQuoteRequestError?.grandTotalError}
                                </span>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-4">
                      <div className="Heading">
                        <h6>Attachments</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Scope of Work</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            onClick={() =>
                              showScopeWorkIdModal("quoteRequest", "work")
                            }
                          >
                            <figure>
                              <img src="images/scopeofwork.png" />
                            </figure>{" "}
                            Scope of Work &nbsp;
                            <span className="Count">{quoteScopeCount}</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Construction Documents</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            onClick={() =>
                              showScopeWorkIdModal(
                                "quoteRequest",
                                "construction"
                              )
                            }
                          >
                            <figure>
                              <img src="images/construction_documents.png" />
                            </figure>{" "}
                            Construction Documents &nbsp;
                            <span className="Count">{quoteConstructCount}</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Additional Documents</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            onClick={() =>
                              showUploadAdditionModal("quoteModal")
                            }
                          >
                            <figure>
                              <img src="images/extermal_quote.png" />
                            </figure>{" "}
                            Additional Documents &nbsp;
                            <span className="Count">
                              {additionalDocumentsCount}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between mt-4 ">
                  <div className="dropdown ml-3">
                    <button
                      className="Create"
                      type="button"
                      onClick={(e) =>
                        handleQuoteRequestFunc(e, "SEND", "quote")
                      }
                    >
                      Save and Send <img src="images/black-down-arrow.png" />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a className="dropdown-item">Save and Print</a>
                      <a className="dropdown-item">Save and Send</a>
                    </div>
                  </div>
                  <button
                    onClick={(e) => handleQuoteRequestFunc(e, "DRAFT", "quote")}
                    className="Create"
                  >
                    Save draft
                  </button>
                  <button className="Create" onClick={hideNewQuoteRequestModal}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* VENDORSYSTEMMODALSHOW */}
      <Modal
        show={SystemVendorModal}
        onHide={hideSystemVendor}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                href="javascript:void(0);"
                className="CloseModal"
                onClick={hideSystemVendor}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>System Vendor List</h4>
                </div>
                <div className="FolderForm">
                  {/* <form> */}
                  <div className="form-group">
                    <div className="Categories Categories1 mb-4">
                      <h6>Vendor Type</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="vendorType"
                          value={vendorType}
                          onChange={handleInput}
                        >
                          <option value="">Select a Vendor Type</option>
                          {checkArray(data1) ? (
                            <>
                              {data1?.map((ele, i) => {
                                return <option value={ele}>{ele}</option>;
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input
                            type="text"
                            placeholder="-Search"
                            onChange={(e) => onChangeHandler(e)}
                          />
                          <span className="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>
                        {/* <li className="CursorPointer">
                        - Full list of vendor types loaded from the system
                      </li> */}
                      </ul>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {systemErrors?.vendorTypeError}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>Vendor Name</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="vendorId"
                          value={vendorId}
                          onChange={handleInput}
                        >
                          <option>Select a Vendor Name</option>
                          {checkArray(vendorSearchData) ? (
                            <>
                              {vendorSearchData?.map((ele, i) => {
                                if (ele.vendorName)
                                  return (
                                    <option value={ele?.id}>
                                      {ele.vendorName}
                                    </option>
                                  );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input
                            type="text"
                            placeholder="Search"
                            onChange={handleInputSearchCity}
                          />
                          <span className="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>
                        {/* <li className="CursorPointer">
                        - Full list of names loaded from the system
                      </li> */}
                      </ul>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {systemErrors?.vendorNameError}
                      </span>
                    </div>
                  </div>
                  <div className="Button mt-5 d-flex justify-content-between">
                    <button className="Outline" onClick={hideSystemVendor}>
                      Cancel
                    </button>
                    <button className="Create" onClick={handleSystemVendor}>
                      Confirm Selection
                    </button>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={PurchaseModal}
        onHide={hidePurchaseModal}
        className="LargeModal"
      >
        <Modal.Body>
          <a className="CloseModal" onClick={hidePurchaseModal} role="button">
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox ModalTitleBoxBlue">
              <h4>Create New Purchase Order</h4>
            </div>
            <div className="FolderForm">
              <div className="BlueTextArea">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="Heading">
                      <h3>Purchase Order # {purchaseCodeForModal}</h3>
                    </div>
                    <a
                      href="javascript:void(0);"
                      className="BlueOutline mb-4"
                      onClick={() =>
                        showNewQuoteRequestModal(
                          "purchase",
                          state,
                          costCodeId,
                          jobCodeId,
                          jobType,
                          backChargeId,
                          costCodeStatic
                        )
                      }
                    >
                      <img src="images/create_quote_blue.png" />
                      Create as New Quote Modal
                    </a>
                    <div className="row">
                      <div className="form-group col-lg-9">
                        <div className="Categories Categories1">
                          <h6>Reason for Creating PO</h6>
                          <div className="Categories_all Categories_all1">
                            <select
                              name="reasonId"
                              value={reasonId}
                              onChange={handleInput}
                            >
                              <option>Select Reason</option>
                              {checkArray(reasonListData) ? (
                                <>
                                  {reasonListData?.map((ele, i) => {
                                    return (
                                      <option value={ele?.id}>
                                        {ele?.description}
                                      </option>
                                    );
                                  })}
                                </>
                              ) : (
                                <></>
                              )}
                            </select>
                            <img src="images/down.png" />
                          </div>
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {newQuoteRequestError?.reasonIdError}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="Heading">
                      <h3 className="d-flex">
                        Job ID: {state?.jobNumber}
                        <div className="TableThreeDots ml-5">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    onClick={() =>
                                      showRequestLimitIncresaseModal(
                                        "purchaseOrder"
                                      )
                                    }
                                  >
                                    <img src="images/request_limit.png" />
                                    Request Limit Increase
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#VendorBackChargeNotificationLeft"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/notify_vendor.png" />
                                    Notify Vendor of Back Charge
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </h3>
                      <h3>
                        <span>
                          {" "}
                          Cost Code:{" "}
                          {`${costCodeNumber} ${" "} (${costCodeName})`}
                        </span>
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="ModalFilterAreaBox">
                      <h4 className="mb-4">Vendor</h4>
                      <div className="RadioArea d-flex  flex-column">
                        {/* <div className="form-group mb-3">
                          <label className="Radio">
                            Use Cost Code's preferred vendor
                            <input type="radio" name="Cuisine" />
                            <span className="checkmark" />
                          </label>
                        </div> */}
                      </div>
                      {/* <span  style={{color:"red", fontSize:"14px"}}>{newQuoteRequestError?.vendorIdError}</span> */}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <h6>Date</h6>
                      <p className="ml-0">{getDate()}</p>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {newQuoteRequestError?.dateError}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5 className="BlueText">Reminder</h5>
                        <div className="CommonModalBox">
                          <figure
                            className="CursorPointer FolderPermissionId"
                            onClick={() =>
                              showAddRemianderModal("purchaseModal")
                            }
                          >
                            <img src="images/timer.png" />
                          </figure>
                          <h5>{day || week} days</h5>
                        </div>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {newQuoteRequestError?.reminderDateError}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <div className="CommonModalBox">
                          <figure
                            className="CursorPointer FolderPermissionId"
                            onClick={() => showSystemVendor("purchaseModal")}
                          >
                            <img src="images/vendor.png" />
                          </figure>
                          <h5 className="text-black">
                            {vendorDetailsData?.vendorName ?? "Select Vendor"}
                          </h5>
                        </div>{" "}
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {newQuoteRequestError?.vendorIdError}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ShippingAddressArea">
                      <div className="ShippingAddressAreaBox">
                        <h6>Mailing Address</h6>
                        <p>
                          {vendorDetailsData?.addressLine1}{" "}
                          {vendorDetailsData?.addressLine2}{" "}
                          {vendorDetailsData?.city}
                        </p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Email</h6>
                        <p> {vendorDetailsData?.email}</p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Phone</h6>
                        <p> {vendorDetailsData?.phone}</p>
                      </div>
                      {/* <div className="ShippingAddressAreaBox">
                <h6>Shipping Address</h6>
                <p>5384 Peach Rd Chilliwack, BC V2R0Z6</p>
              </div> */}
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <h6>Messsage</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert message to be sent to vendor"
                        name="message"
                        value={message}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {newQuoteRequestError?.messageError}
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ItemDetails">
                      <h6>Item Details</h6>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="Tableheader mb-3">
                      <h6 className="mb-0">Item</h6>
                      <div className="Actions">
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <DownloadTableExcel
                          filename="Purchase item List"
                          sheet="Purchase items list"
                          currentTableRef={purchaseRef.current}
                        >
                          <button href="jacascript:void(0);">
                            <img src="images/download.png" />
                          </button>
                        </DownloadTableExcel>
                        <a href="javascript:void(0);">
                          <img src="images/setting.png" />
                        </a>
                      </div>
                    </div>
                    <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                      <table ref={purchaseRef}>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>
                              <div className="text-center">
                                Material Description
                                <br />
                                (i.e: Modal/Type/Style/manuf part#/Dimensions,
                                etc.)
                              </div>
                            </th>
                            <th>Color</th>
                            <th>Quantity</th>
                            <th>Units</th>
                            <th>Price/Unit</th>
                            <th>Total</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items?.map((item, i) => {
                            return (
                              <tr>
                                <td>{i + 1}</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={item.name}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="color"
                                    value={item.color}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="quantity"
                                    value={item.quantity}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <select
                                    className="form-control"
                                    name="unitId"
                                    value={item.unitId}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  >
                                    <option value=""> Select Option</option>
                                    {checkArray(estimationUnitListData) &&
                                      estimationUnitListData?.map((ele, i) => {
                                        return (
                                          <option value={ele?.id}>
                                            {" "}
                                            {ele?.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="price"
                                    value={item.price}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="total"
                                    disabled
                                    value={+item.price * +item.quantity}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <div className="TableThreeDots">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol className="dropdown-menu">
                                          <li>
                                            <a
                                              onClick={(e) =>
                                                addExtraRowInFunc(e, "quote")
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/add_new_column.png" />
                                              Add New Line
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                removeLine(i, "purchase")
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/dropdown-delete.png" />
                                              Remove Line
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                          <span style={{ color: "red", fontSize: "18px" }}>
                            {itemErrors}
                          </span>
                          {/* <span style={{color:"red", fontSize:"30px"}}>{"itdjkfhdkjghdfgkldfhglfdkghdflemErrors"}</span> */}

                          <tr>
                            <td colSpan={8}>
                              <div className="TableButtons">
                                <button
                                  onClick={(e) => addExtraRowInFunc(e, "quote")}
                                  className="BrandBtn"
                                >
                                  Add Line
                                </button>
                                <button
                                  onClick={(e) => clearAllLines(e, "quote")}
                                  className="BrandBtn ml-2"
                                >
                                  Clear all lines
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 ml-auto">
                        <div className="GstTable">
                          <table>
                            <tbody>
                              <tr>
                                <td>Profile</td>
                                <div class="CommonSelectBox">
                                  <select
                                    value={profiles}
                                    onChange={(e) =>
                                      handleProfileChanges(e.target.value)
                                    }
                                  >
                                    <option value="">Select profile</option>
                                    {checkArray(profileListData) &&
                                      profileListData?.map((ele, i) => {
                                        return (
                                          <option
                                            value={ele?.id}
                                            checked={ele.id == profile}
                                          >
                                            {ele?.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {newQuoteRequestError?.profileError}
                                </span>
                              </tr>
                              <tr>
                                <td>Subtotal</td>
                                <td>
                                  {subtotal || 0}
                                  <span
                                    className="estimationModalBelowProfile"
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {newQuoteRequestError?.subtotalError}
                                  </span>
                                </td>
                                <td>Modify Tax</td>
                              </tr>
                              {showTaxFlag ? (
                                <tr>
                                  <td>
                                    {taxName}({taxPercentage}%)
                                  </td>
                                  <td>
                                    {taxAmount || 0}
                                    <span
                                      className="estimationModalBelowProfile"
                                      style={{ color: "red", fontSize: "14px" }}
                                    >
                                      {newQuoteRequestError?.subtotalError}
                                    </span>
                                  </td>
                                  <td>
                                    <div class="TableThreeDots">
                                      <ul class="">
                                        <li class="dropdown">
                                          <a
                                            class=""
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img
                                              src="images/dots.png"
                                              style={{
                                                width: "100px",
                                                height: "20px",
                                              }}
                                            />
                                          </a>
                                          <ol class="dropdown-menu">
                                            <li>
                                              <a
                                                onClick={removeTaxProfileFunc}
                                                href="javascript:void(0);"
                                              >
                                                <img src="images/dropdown-delete.png" />
                                                Remove Line
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )}

                              <tr>
                                <td>{`${taxNames} (${taxPercentages} %)`}</td>
                                <td>
                                  {Number(subtotal) *
                                    (Number(taxPercentages) / 100)}
                                </td>
                                <span
                                  className="estimationModalBelowProfile"
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {newQuoteRequestError?.gstError}
                                </span>
                                {!showTaxFlag && (
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown">
                                        <a
                                          class=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img
                                            src="images/dots.png"
                                            style={{
                                              width: "100px",
                                              height: "20px",
                                            }}
                                          />
                                        </a>
                                        <ol class="dropdown-menu">
                                          <li>
                                            <a
                                              onClick={() =>
                                                showAddTaxProfileModal(
                                                  "quoteRequest"
                                                )
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/add_new_column.png" />
                                              Add New Column
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </tr>

                              <tr>
                                <td>Grand Total</td>
                                <td>
                                  {Number(subtotal) +
                                    Number(subtotal) *
                                      (Number(taxPercentages) / 100) +
                                    (Number(taxAmount) || 0)}
                                </td>
                                <span
                                  className="estimationModalBelowProfile"
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    textAlign: "right",
                                  }}
                                >
                                  {newQuoteRequestError?.grandTotalError}
                                </span>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-4">
                      <div className="Heading">
                        <h6>Attachments</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Scope of Work</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            onClick={() =>
                              showScopeWorkIdModal("purchaseOrder", "work")
                            }
                          >
                            <figure>
                              <img src="images/scopeofwork.png" />
                            </figure>{" "}
                            Scope of Work &nbsp;
                            <span className="Count">{quoteScopeCount}</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Construction Documents</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            onClick={() =>
                              showScopeWorkIdModal(
                                "purchaseOrder",
                                "construction"
                              )
                            }
                          >
                            <figure>
                              <img src="images/construction_documents.png" />
                            </figure>{" "}
                            Construction Documents &nbsp;
                            <span className="Count">{quoteConstructCount}</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Additional Document</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            onClick={() => showUploadAdditionModal("purchase")}
                          >
                            <figure>
                              <img src="images/extermal_quote.png" />
                            </figure>{" "}
                            External Quote &nbsp;
                            <span className="Count">3</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="Button d-flex justify-content-between mt-4 ">
                  <div className="dropdown ml-3">
                    <button
                      className="Create"
                      type="button"
                      onClick={(e) =>
                        handleQuoteRequestFunc(e, "SEND", "purchase")
                      }
                    >
                      Save and Send <img src="images/black-down-arrow.png" />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a className="dropdown-item" href="#">
                        Save and Print
                      </a>
                      <a className="dropdown-item" href="#">
                        Save and Send
                      </a>
                    </div>
                  </div>
                  <button
                    onClick={(e) =>
                      handleQuoteRequestFunc(e, "DRAFT", "purchase")
                    }
                    className="Create"
                  >
                    Save draft
                  </button>
                  <button className="Create" onClick={hidePurchaseModal}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={biddingDetailsModal}
        onHide={hideBiddingDetailModal}
        className="LargeModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideBiddingDetailModal}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Bidding</h4>
            </div>
            <div className="ModalMediumheading d-flex flex-lg-row flex-column justify-content-between align-items-lg-center align-items-start">
              <h3 className="fw-16">
                <span>Cost Code:</span>
                {`${biddingData?.number ?? ""} ${biddingData?.name ?? ""}`}
              </h3>

              <h3 className="fw-16">
                <span>Status:</span>
                {biddingData?.JobCost?.status}
              </h3>
            </div>
            <div className="row">
              <div className="c">
                <div className="CommonModalAreaMain my-3">
                  <div className="form-group">
                    <div className="CommonModalArea">
                      <h5>Budget Change Orders</h5>
                      {/* <div className="CommonModalBox">
                        <figure>
                          <img src="images/budget_change_order.png" />
                        </figure>{" "}
                        <span className="Count">3</span>
                        <h5
                          className="CursorPointer FolderPermissionId"
                          data-toggle="modal"
                          data-target="#GlobalBIddingid"
                          data-dismiss="modal"
                        >
                          Budget Change Orders
                        </h5>
                      </div> */}
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>Alerts</h6>
                    <p className="YellowText">
                      {biddingData?.alerts ? "Yes" : "No"}
                    </p>
                  </div>
                </div>
                <div className="ModalMediumheading">
                  <h3>
                    {/* <span>Job:</span> Rivers Edge Unit 366(RIV-2B-366) */}
                  </h3>
                </div>
                <div className="BillsRowBorder mb-5">
                  <h6 className="py-3 mb-0">History</h6>
                </div>
                <div className="Tableheader my-4">
                  <h6 className="mb-0">Events</h6>
                  <div className="Actions">
                    <a href="javascript:void(0);">
                      <img src="images/icon-53.png" />
                    </a>
                    <DownloadTableExcel
                      filename="Event List"
                      sheet="Event List"
                      currentTableRef={eventRef.current}
                    >
                      <a href="javascript:void(0);">
                        <img src="images/download.png" />
                      </a>
                    </DownloadTableExcel>
                    <a href="javascript:void(0);">
                      <img src="images/setting.png" />
                    </a>
                  </div>
                </div>
                <div className="TableList TableHeightScroll CommonHeadingBlack">
                  <table ref={eventRef}>
                    <thead>
                      <tr>
                        <th>User</th>
                        <th>Date</th>
                        <th>Activity</th>
                        <th>Vendor</th>
                        <th>Vendor Total</th>
                        <th>Document Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loader ? (
                        <>
                          <Loader />
                        </>
                      ) : checkArray(eventListData?.data) ? (
                        eventListData?.data?.map((ele, i) => {
                          // // console.log((ele?.jsonData))
                          const parsedData =
                            typeof ele.jsonData === "string"
                              ? JSON.parse(ele.jsonData)
                              : ele.jsonData;
                          return (
                            <>
                              <tr>
                                <td>{ele?.userDetails?.firstName}</td>
                                <td>{ele?.createdAt?.split("T")[0]}</td>
                                <td>{ele?.activity}</td>
                                <td>{parsedData?.vendorName}</td>
                                <td>N/A</td>
                                <td>{parsedData?.total}</td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <NoDataFound />
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="PaginationArea">
                  <h6>Showing 1 to 10 of 27 entries</h6>
                  <h6>
                    <img src="images/leftarrow.png" /> 01{" "}
                    <img src="images/rightarrow.png" /> Out 10
                  </h6>
                </div>
              </div>
            </div>
            <div className="BillsRowBorder d-flex flex-lg-row flex-column align-items-lg-center align-items-start mt-5">
              <h6 className="mb-0">Quotes</h6>
              <div className="d-flex align-items-center">
                <div className="TodosButtons mt-0">
                  <a
                    href="javascript:void(0)"
                    className="FolderPermissionId"
                    onClick={() => showBillModal("biddingDetail")}
                  >
                    <img src="images/create_new_bill.png" />
                    Create New QR/PO Mass Mailout
                  </a>
                  <a
                    href="javascript:void(0)"
                    className="mr-0 FolderPermissionId"
                    onClick={() => showNewQuoteRequestModal("biddingDetail")}
                  >
                    <img src="images/create_new_quote_request.png" />
                    Create New Quote Request/Purchase Order
                  </a>
                </div>
              </div>
            </div>
            <div className="Tableheader my-4">
              <h6 className="mb-0">Quote Requests</h6>
              <div className="Actions">
                <a href="javascript:void(0);">
                  <img src="images/icon-53.png" />
                </a>
                <DownloadTableExcel
                  filename="Quote List"
                  sheet="Quote List"
                  currentTableRef={quoteListRefByCostCode.current}
                >
                  <a href="javascript:void(0);">
                    <img src="images/download.png" />
                  </a>
                </DownloadTableExcel>
                <a href="javascript:void(0);">
                  <img src="images/setting.png" />
                </a>
              </div>
            </div>
            <div className="TableList CommonHeading TableListMaxHeight">
              <table ref={quoteListRefByCostCode}>
                <thead>
                  <tr>
                    <th>QR Number</th>
                    <th>Sent By</th>
                    <th>Reason</th>
                    <th>Sent To</th>
                    <th>Date Sent</th>
                    <th>Date Retuned</th>
                    <th>Pre-Tax Total</th>
                    <th>Vendor Pre-Tax Total</th>
                    <th>Valid Until</th>
                    <th>Status</th>
                    <th>Time since last status update</th>
                    <th>Attachments</th>
                    <th>Reminder</th>
                    <th>P.O./F.P.O. Created</th>
                    <th>Related QR/PO Mass Mailout</th>
                    <th>Back Charge Notifications</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {checkArray(quoteRequestListData?.data) ? (
                    quoteRequestListData?.data?.map((ele, i) => {
                      return (
                        <>
                          {/* ele?.isPreferred ?  */}
                          <tr
                            className={`${
                              ele?.isPreferred && ele?.backChargeId
                                ? "RawBrownColor"
                                : ele?.backChargeId && !ele?.isPreferred
                                ? "RawYellowColor"
                                : ele?.isPreferred
                                ? "RawBlueColor"
                                : ""
                            }`}
                          >
                            <td>{ele?.documentCode}</td>
                            <td>
                              {ele?.issuedBy?.name ||
                                ele?.issuedBy?.email ||
                                "N/A"}
                            </td>
                            <td>{ele?.Reason?.description ?? "N/A"}</td>
                            <td>{ele?.Vendor?.vendorName ?? "N/A"}</td>
                            <td>{ele?.createdAt?.split("T")[0]}</td>
                            <td>{ele?.dateReturned?.split("T")[0]}</td>
                            <td>
                              <div className="Action">
                                <td>$ {ele?.subtotal}</td>
                                <a
                                  href="javascript:void(0);"
                                  onClick={() =>
                                    handleQuoteRequestCostBreakDownModalShow(
                                      ele?.id,
                                      "biddingQr"
                                    )
                                  }
                                >
                                  <img
                                    src="images/pre_tax_total.png"
                                    className="ml-2"
                                  />
                                </a>
                              </div>
                            </td>
                            <td>$ {ele?.subTotal}</td>
                            <td> {ele?.validTill ? ele?.validTill : "N/A"}</td>
                            <td> {ele?.status}</td>
                            <td>
                              <div>
                                <a
                                  href="javascript:void(0);"
                                  className="TextRed"
                                >
                                  {calculateDateDifferenceInDays(
                                    ele?.createdAt,
                                    ele?.updatedAt
                                  )}{" "}
                                  days
                                  <img
                                    src="images/resend_yellow.png"
                                    className="ml-2"
                                  />
                                </a>
                              </div>
                            </td>
                            <td>
                              <div className="Actions">
                                <a
                                  href={ele?.additionalDocuments?.[0]?.docUrl}
                                  target="_blank"
                                >
                                  <img src="images/attachment.png" />
                                </a>
                                <a href="javascript:void(0)">
                                  <span className="Count">
                                    {ele?.additionalDocuments?.length ?? 0}
                                  </span>
                                </a>
                              </div>
                            </td>
                            <td>
                              <a href="javascript:void(0);">
                                <img src="images/timer.png" className="mr-2" />
                              </a>
                              {ele?.reminder?.split("T")[0]}
                            </td>
                            <td />
                            <td>
                              <div className="TextLightBlue" />
                            </td>
                            <td>{ele.backChargeNotificationCount || 0}</td>
                            <td>
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu dropdown-menuwidth2">
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="FolderPermissionId CursorPointer"
                                          onClick={() =>
                                            showQuoteDetailsModal(
                                              "biddingDetaiModal",
                                              ele?.id
                                            )
                                          }
                                        >
                                          <img src="images/view-po.png" />
                                          View QR
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href={
                                            ele?.additionalDocuments?.[0]
                                              ?.docUrl
                                          }
                                          target="_blank"
                                          className="FolderPermissionId"
                                        >
                                          <img src="images/attachment.png" />
                                          View Attachments
                                        </a>
                                      </li>
                                      {ele?.billId ? (
                                        <li>
                                          <a
                                            onClick={() =>
                                              showBillDetailModal(
                                                "biddingDetail",
                                                ele?.billId
                                              )
                                            }
                                            href="javascript:void(0);"
                                          >
                                            <img src="images/view_related_bill.png" />
                                            View Related QR/PO Mass Mailout
                                          </a>
                                        </li>
                                      ) : (
                                        <></>
                                      )}

                                      {!ele?.isPreferred ? (
                                        <>
                                          <li>
                                            <a
                                              onClick={() =>
                                                markQRPrefferedFun(
                                                  ele?.id,
                                                  ele?.subtotal,
                                                  {
                                                    isPreferred: true,
                                                    status: "ACCEPTED",
                                                    ele,
                                                  }
                                                )
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/mark_qr_preffered.png" />
                                              Mark QR as preferred
                                            </a>
                                          </li>
                                        </>
                                      ) : (
                                        <>
                                          {ele?.status === "APPROVED" ? (
                                            <>
                                              <li>
                                                <a
                                                  href="javascript:void(0);"
                                                  className="FolderPermissionId"
                                                  onClick={() =>
                                                    showPurchaseDetailsModal(
                                                      "test",
                                                      ele?.id
                                                    )
                                                  }
                                                >
                                                  <img src="images/approve_create.png" />
                                                  View P.O.
                                                </a>
                                              </li>
                                            </>
                                          ) : (
                                            <>
                                              <li>
                                                <a
                                                  href="javascript:void(0);"
                                                  className="FolderPermissionId"
                                                  onClick={() =>
                                                    getApproveFunc(ele?.id, ele)
                                                  }
                                                >
                                                  <img src="images/approve_create.png" />
                                                  Approve &amp; Create P.O.
                                                </a>
                                              </li>
                                            </>
                                          )}
                                        </>
                                      )}

                                      {ele?.status === "APPROVED" ? (
                                        <>
                                          <li>
                                            <a
                                              href="javascript:void(0);"
                                              className="FolderPermissionId"
                                              onClick={() =>
                                                showPurchaseDetailsModal(
                                                  "test",
                                                  ele?.id
                                                )
                                              }
                                            >
                                              <img src="images/approve_create.png" />
                                              View P.O.
                                            </a>
                                          </li>
                                        </>
                                      ) : (
                                        <>
                                          <li>
                                            <a
                                              href="javascript:void(0);"
                                              className="FolderPermissionId"
                                              onClick={() =>
                                                handleFPOApprove(ele?.id)
                                              }
                                            >
                                              <img src="images/approve_create.png" />
                                              Approve &amp; F.P.O.
                                            </a>
                                          </li>
                                        </>
                                      )}

                                      <li>
                                        <a
                                          onClick={() => removeQuote(ele?.id)}
                                          href="javascript:void(0);"
                                        >
                                          <img src="images/cancecl_message.png" />
                                          Cancel QR
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="FolderPermissionId"
                                          onClick={() =>
                                            handleQuoteRequestCostBreakDownModalShow(
                                              ele?.id,
                                              "biddingQr"
                                            )
                                          }
                                        >
                                          <img src="images/view_cost_breakdown.png" />
                                          View Cost Breakdown
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <img src="images/download.png" />
                                          Download QR
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <img src="images/pdf.png" />
                                          Print QR
                                        </a>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <NoDataFound />
                  )}
                </tbody>
              </table>
            </div>
            <>
              <div className="Tableheader my-4">
                <h6 className="mb-0">Purchase Orders</h6>
                <div className="Actions">
                  <a href="javascript:void(0);">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/download.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
              <div className="TableList CommonHeading TableListMaxHeight">
                <table>
                  <thead>
                    <tr>
                      <th>P.O. Number</th>
                      <th>Sent By</th>
                      <th>Reason</th>
                      <th>Sent To</th>
                      <th>Date Sent</th>
                      <th>Date Accepted</th>
                      <th>Pre-Tax Total</th>
                      <th>Status</th>
                      <th>Time since last status update</th>
                      <th>Attachments</th>
                      <th>Reminder</th>
                      <th>Back Charge Notifications</th>
                      <th>Related QR/PO Mass Mailout</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkArray(purchaseListData?.data) ? (
                      purchaseListData?.data?.map((ele, i) => {
                        return (
                          <>
                            <tr
                              tr
                              className={`${
                                ele?.backChargeId ? "RawBrownColor" : ""
                              }`}
                            >
                              <td>{ele?.documentCode}</td>
                              <td>
                                {ele?.issuedBy?.name ||
                                  ele?.issuedBy?.email ||
                                  "N/A"}
                              </td>
                              <td>{ele?.Reason?.description ?? "N/A"}</td>
                              <td>{ele?.Vendor?.vendorName ?? "N/A"}</td>
                              <td>{ele?.sendAt?.split("T")[0]}</td>
                              <td>{ele?.dateReturned?.split("T")[0]}</td>
                              <td>
                                <div className="Action">
                                  <td>$ {ele?.subtotal}</td>
                                  <a
                                    onClick={() =>
                                      showPurchaseViewCostBreakdownModal(
                                        ele?.id,
                                        "purchase"
                                      )
                                    }
                                  >
                                    <img
                                      src="images/pre_tax_total.png"
                                      className="ml-2"
                                    />
                                  </a>
                                </div>
                              </td>
                              {/* <td>$ {ele?.subTotal}</td> */}
                              {/* <td> {ele?.validTill ? ele?.validTill : "N/A"}</td> */}
                              <td> {ele?.status}</td>
                              <td>
                                <div>
                                  <a
                                    href="javascript:void(0);"
                                    className="TextRed"
                                  >
                                    {calculateDateDifferenceInDays(
                                      ele?.createdAt,
                                      ele?.updatedAt
                                    )}{" "}
                                    days
                                    <img
                                      src="images/resend_yellow.png"
                                      className="ml-2"
                                    />
                                  </a>
                                </div>
                              </td>
                              <td>
                                <div className="Actions">
                                  <a
                                    href={ele?.additionalDocuments?.[0]?.docUrl}
                                    target="_blank"
                                  >
                                    <img src="images/attachment.png" />
                                  </a>
                                  <a href="javascript:void(0)">
                                    <span className="Count">
                                      {ele?.additionalDocuments?.length ?? 0}
                                    </span>
                                  </a>
                                </div>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <img
                                    src="images/timer.png"
                                    className="mr-2"
                                  />
                                </a>
                                {ele?.reminder?.split("T")[0]}
                              </td>
                              {/* <td /> */}
                              <td> {ele.backChargeNotificationCount || 0}</td>
                              <td>
                                <div className="TextLightBlue" />
                              </td>

                              <td>
                                <div className="TableThreeDots">
                                  <ul className="">
                                    <li className="dropdown">
                                      <a
                                        className=""
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <img src="images/dots.png" />
                                      </a>
                                      <ol className="dropdown-menu dropdown-menuwidth2">
                                        <li>
                                          <a
                                            href="javascript:void(0);"
                                            class="FolderPermissionId CursorPointer"
                                            onClick={() =>
                                              showPurchaseDetailsModal(
                                                "biddingView",
                                                ele?.id
                                              )
                                            }
                                          >
                                            <img src="images/view-po.png" />
                                            View PO
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            class="FolderPermissionId"
                                            href={
                                              ele?.additionalDocuments?.[0]
                                                ?.docUrl
                                            }
                                            target="_blank"
                                          >
                                            <img src="images/attachment.png" />
                                            View Attachments
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="javascript:void(0);"
                                            onClick={(e) =>
                                              sendPOFunc(e, ele?.id, "SEND")
                                            }
                                          >
                                            <img src="images/send_po.png" />
                                            Send P.O
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="javascript:void(0);"
                                            onClick={(e) =>
                                              sendPOFunc(
                                                e,
                                                ele?.id,
                                                "CANCELLED"
                                              )
                                            }
                                          >
                                            <img src="images/cancecl_message.png" />
                                            Cancel P.O.
                                          </a>
                                        </li>
                                        <li>
                                          <a href="javascript:void(0);">
                                            <img src="images/override_vendor.png" />
                                            Override Vendor Acceptance
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="javascript:void(0);"
                                            onClick={() =>
                                              showSupplierCreditModal(
                                                "bidding",
                                                ele?.id,
                                                ele?.JobCost?.jobId,
                                                ele?.JobCost?.jobType,
                                                ele?.number,
                                                ele?.name
                                              )
                                            }
                                          >
                                            <img src="images/override_vendor.png" />
                                            Create Supplier Credit
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="javascript:void(0);"
                                            onClick={() =>
                                              showFieldPurchaseOrder(
                                                ele?.costCodeId
                                              )
                                            }
                                          >
                                            <img src="images/override_vendor.png" />
                                            Create Field Purchase Order
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="javascript:void(0);"
                                            class="FolderPermissionId"
                                            onClick={() =>
                                              showPurchaseViewCostBreakdownModal(
                                                ele?.id,
                                                "purchase"
                                              )
                                            }
                                          >
                                            <img src="images/view_cost_breakdown.png" />
                                            View Cost Breakdown
                                          </a>
                                        </li>
                                        <li>
                                          <a href="javascript:void(0);">
                                            <img src="images/download.png" />
                                            Download PO
                                          </a>
                                        </li>
                                        <li>
                                          <a href="javascript:void(0);">
                                            <img src="images/pdf.png" />
                                            Print PO
                                          </a>
                                        </li>
                                      </ol>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <NoDataFound />
                    )}
                  </tbody>
                </table>
              </div>

              <div className="Tableheader my-4">
                <h6 className="mb-0">Field Purchase Order</h6>
                <div className="Actions">
                  <a href="javascript:void(0);">
                    <img src="images/icon-53.png" />
                  </a>
                  <DownloadTableExcel
                    filename="Quote List"
                    sheet="Quote List"
                    currentTableRef={quoteListRefByCostCode.current}
                  >
                    <a href="javascript:void(0);">
                      <img src="images/download.png" />
                    </a>
                  </DownloadTableExcel>
                  <a href="javascript:void(0);">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
              <div className="TableList CommonHeading TableListMaxHeight">
                <table ref={quoteListRefByCostCode}>
                  <thead>
                    <tr>
                      <th>FPO Number</th>
                      <th>Sent By</th>
                      <th>Reason</th>
                      <th>Sent To</th>
                      <th>Date Sent</th>
                      <th>Date Retuned</th>
                      <th>Pre-Tax Total</th>
                      <th>Vendor Pre-Tax Total</th>
                      <th>Valid Until</th>
                      <th>Status</th>
                      <th>Time since last status update</th>
                      <th>Attachments</th>
                      <th>Reminder</th>
                      <th>P.O./F.P.O. Created</th>
                      <th>Related QR/PO Mass Mailout</th>
                      <th>Back Charge Notifications</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkArray(fieldPurchaseOrderListData?.data) ? (
                      fieldPurchaseOrderListData?.data?.map((ele, i) => {
                        return (
                          <>
                            <tr
                              className={`${
                                ele?.backChargeId ? "RawBrownColor" : ""
                              }`}
                            >
                              <td>{ele?.documentCode}</td>
                              <td>
                                {ele?.issuedBy?.name ||
                                  ele?.issuedBy?.email ||
                                  "N/A"}
                              </td>
                              <td>{ele?.Reason?.description ?? "N/A"}</td>
                              <td>{ele?.Vendor?.vendorName ?? "N/A"}</td>
                              <td>{ele?.createdAt?.split("T")[0]}</td>
                              <td>{ele?.dateReturned?.split("T")[0]}</td>
                              <td>
                                <div className="Action">
                                  <td>$ {ele?.subtotal}</td>
                                  <a
                                    onClick={() =>
                                      showPurchaseViewCostBreakdownModal(
                                        ele?.id,
                                        "FPO"
                                      )
                                    }
                                  >
                                    <img
                                      src="images/pre_tax_total.png"
                                      className="ml-2"
                                    />
                                  </a>
                                </div>
                              </td>
                              <td>$ {ele?.subTotal}</td>
                              <td>
                                {" "}
                                {ele?.validTill ? ele?.validTill : "N/A"}
                              </td>
                              <td> {ele?.status}</td>
                              <td>
                                <div>
                                  <a
                                    href="javascript:void(0);"
                                    className="TextRed"
                                  >
                                    {calculateDateDifferenceInDays(
                                      ele?.createdAt,
                                      ele?.updatedAt
                                    )}{" "}
                                    days
                                    <img
                                      src="images/resend_yellow.png"
                                      className="ml-2"
                                    />
                                  </a>
                                </div>
                              </td>
                              <td>
                                <div className="Actions">
                                  <a
                                    href={ele?.additionalDocuments?.[0]?.docUrl}
                                    target="_blank"
                                  >
                                    <img src="images/attachment.png" />
                                  </a>
                                  <a href="javascript:void(0)">
                                    <span className="Count">
                                      {ele?.additionalDocuments?.length ?? 0}
                                    </span>
                                  </a>
                                </div>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <img
                                    src="images/timer.png"
                                    className="mr-2"
                                  />
                                </a>
                                {ele?.reminder?.split("T")[0]}
                              </td>
                              <td />
                              <td>
                                <div className="TextLightBlue" />
                              </td>
                              <td>{ele.backChargeNotificationCount || 0} </td>
                              <td>
                                <div className="TableThreeDots">
                                  <ul className="">
                                    <li className="dropdown">
                                      <a
                                        className=""
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <img src="images/dots.png" />
                                      </a>
                                      <ol className="dropdown-menu dropdown-menuwidth2">
                                        <li>
                                          <a
                                            href="javascript:void(0);"
                                            className="FolderPermissionId CursorPointer"
                                            onClick={() =>
                                              showFieldPurchaseOrderDetailsModal(
                                                "biddingDetaiModal",
                                                ele?.id
                                              )
                                            }
                                          >
                                            <img src="images/view-po.png" />
                                            View FPO
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href={
                                              ele?.additionalDocuments?.[0]
                                                ?.docUrl
                                            }
                                            target="_blank"
                                            className="FolderPermissionId"
                                          >
                                            <img src="images/attachment.png" />
                                            View Attachments
                                          </a>
                                        </li>
                                        {ele?.billId ? (
                                          <li>
                                            <a
                                              onClick={() =>
                                                showBillDetailModal(
                                                  "biddingDetail",
                                                  ele?.billId
                                                )
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/view_related_bill.png" />
                                              View Related QR/PO Mass Mailout
                                            </a>
                                          </li>
                                        ) : (
                                          <></>
                                        )}

                                        <li>
                                          <a
                                            onClick={(e) =>
                                              changeFieldPurchaseOrderStatusFunc(
                                                e,
                                                ele?.id,
                                                "CANCELLLED"
                                              )
                                            }
                                            href="javascript:void(0);"
                                          >
                                            <img src="images/cancecl_message.png" />
                                            Cancel QR
                                          </a>
                                        </li>

                                        <li>
                                          <a
                                            onClick={(e) =>
                                              changeFieldPurchaseOrderStatusFunc(
                                                e,
                                                ele?.id,
                                                "RESEND"
                                              )
                                            }
                                            href="javascript:void(0);"
                                          >
                                            <img src="images/cancecl_message.png" />
                                            Resend QR
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="javascript:void(0);"
                                            className="FolderPermissionId"
                                            onClick={() =>
                                              showPurchaseViewCostBreakdownModal(
                                                ele?.id,
                                                "FPO"
                                              )
                                            }
                                          >
                                            <img src="images/view_cost_breakdown.png" />
                                            View Cost Breakdown
                                          </a>
                                        </li>
                                        <li>
                                          <a href="javascript:void(0);">
                                            <img src="images/download.png" />
                                            Download QR
                                          </a>
                                        </li>
                                        <li>
                                          <a href="javascript:void(0);">
                                            <img src="images/pdf.png" />
                                            Print QR
                                          </a>
                                        </li>
                                        {/* {ele?.backChargeId&& ( */}
                                        <li>
                                          <a
                                            onClick={() =>
                                              handleSuplierCreditApprove(
                                                ele?.id
                                              )
                                            }
                                          >
                                            <img src="images/view_cost_breakdown.png" />
                                            Convert to Supplier Credit
                                          </a>
                                        </li>
                                        {/* )} */}
                                      </ol>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <NoDataFound />
                    )}
                  </tbody>
                </table>
              </div>

              <div className="Tableheader my-4">
                <h6 className="mb-0">Supplier Credit Order</h6>
                <div className="Actions">
                  <a href="javascript:void(0);">
                    <img src="images/icon-53.png" />
                  </a>
                  <DownloadTableExcel
                    filename="Quote List"
                    sheet="Quote List"
                    currentTableRef={quoteListRefByCostCode.current}
                  >
                    <a href="javascript:void(0);">
                      <img src="images/download.png" />
                    </a>
                  </DownloadTableExcel>
                  <a href="javascript:void(0);">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
              <div className="TableList CommonHeading TableListMaxHeight">
                <table ref={quoteListRefByCostCode}>
                  <thead>
                    <tr>
                      <th>SC Number</th>
                      <th>Sent By</th>
                      <th>Reason</th>
                      <th>Sent To</th>
                      <th>Date Sent</th>
                      <th>Date Retuned</th>
                      <th>Pre-Tax Total</th>
                      <th>Vendor Pre-Tax Total</th>
                      <th>Valid Until</th>
                      <th>Status</th>
                      <th>Time since last status update</th>
                      <th>Attachments</th>
                      <th>Reminder</th>
                      <th>P.O./F.P.O. Created</th>
                      <th>Related QR/PO Mass Mailout</th>
                      <th>Back Charge Notifications</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkArray(supplierCreditListData?.data) ? (
                      supplierCreditListData?.data?.map((ele, i) => {
                        return (
                          <>
                            <tr
                              tr
                              className={`${
                                ele?.backChargeId ? "RawBrownColor" : ""
                              }`}
                            >
                              <td>{ele?.documentCode}</td>
                              <td>
                                {ele?.issuedBy?.name ||
                                  ele?.issuedBy?.email ||
                                  "N/A"}
                              </td>
                              <td>{ele?.Reason?.description ?? "N/A"}</td>
                              <td>{ele?.Vendor?.vendorName ?? "N/A"}</td>
                              <td>{ele?.createdAt?.split("T")[0]}</td>
                              <td>{ele?.dateReturned?.split("T")[0]}</td>
                              <td>
                                <div className="Action">
                                  <td>$ {ele?.subtotal}</td>
                                  <a
                                    onClick={() =>
                                      showPurchaseViewCostBreakdownModal(
                                        ele?.id,
                                        "SCO"
                                      )
                                    }
                                  >
                                    <img
                                      src="images/pre_tax_total.png"
                                      className="ml-2"
                                    />
                                  </a>
                                </div>
                              </td>
                              <td>$ {ele?.subTotal}</td>
                              <td>
                                {" "}
                                {ele?.validTill ? ele?.validTill : "N/A"}
                              </td>
                              <td> {ele?.status}</td>
                              <td>
                                <div>
                                  <a
                                    href="javascript:void(0);"
                                    className="TextRed"
                                  >
                                    {calculateDateDifferenceInDays(
                                      ele?.createdAt,
                                      ele?.updatedAt
                                    )}{" "}
                                    days
                                    <img
                                      src="images/resend_yellow.png"
                                      className="ml-2"
                                    />
                                  </a>
                                </div>
                              </td>
                              <td>
                                <div className="Actions">
                                  <a
                                    href={ele?.additionalDocuments?.[0]?.docUrl}
                                    target="_blank"
                                  >
                                    <img src="images/attachment.png" />
                                  </a>
                                  <a href="javascript:void(0)">
                                    <span className="Count">
                                      {ele?.additionalDocuments?.length ?? 0}
                                    </span>
                                  </a>
                                </div>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <img
                                    src="images/timer.png"
                                    className="mr-2"
                                  />
                                </a>
                                {ele?.reminder?.split("T")[0]}
                              </td>
                              <td />
                              <td>
                                <div className="TextLightBlue" />
                              </td>
                              <td> {ele.backChargeNotificationCount || 0}</td>

                              <td>
                                <div className="TableThreeDots">
                                  <ul className="">
                                    <li className="dropdown">
                                      <a
                                        className=""
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <img src="images/dots.png" />
                                      </a>
                                      <ol className="dropdown-menu dropdown-menuwidth2">
                                        <li>
                                          <a
                                            href="javascript:void(0);"
                                            className="FolderPermissionId CursorPointer"
                                            onClick={() =>
                                              showSupplierCreditDetailsModal(
                                                "biddingDetaiModal",
                                                ele?.id
                                              )
                                            }
                                          >
                                            <img src="images/view-po.png" />
                                            View SC
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href={
                                              ele?.additionalDocuments?.[0]
                                                ?.docUrl
                                            }
                                            target="_blank"
                                            className="FolderPermissionId"
                                          >
                                            <img src="images/attachment.png" />
                                            View Attachments
                                          </a>
                                        </li>
                                        {ele?.billId ? (
                                          <li>
                                            <a
                                              onClick={() =>
                                                showBillDetailModal(
                                                  "biddingDetail",
                                                  ele?.billId
                                                )
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/view_related_bill.png" />
                                              View Related QR/PO Mass Mailout
                                            </a>
                                          </li>
                                        ) : (
                                          <></>
                                        )}

                                        <li>
                                          <a
                                            onClick={(e) =>
                                              changeSupplierCreditStatusFunc(
                                                e,
                                                ele?.id,
                                                "CANCELLED"
                                              )
                                            }
                                            href="javascript:void(0);"
                                          >
                                            <img src="images/cancecl_message.png" />
                                            Cancel SC
                                          </a>
                                        </li>

                                        <li>
                                          <a
                                            onClick={(e) =>
                                              changeSupplierCreditStatusFunc(
                                                e,
                                                ele?.id,
                                                "RESEND"
                                              )
                                            }
                                            href="javascript:void(0);"
                                          >
                                            <img src="images/cancecl_message.png" />
                                            Resend SC
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="javascript:void(0);"
                                            className="FolderPermissionId"
                                            onClick={() =>
                                              showPurchaseViewCostBreakdownModal(
                                                ele?.id,
                                                "SCO"
                                              )
                                            }
                                          >
                                            <img src="images/view_cost_breakdown.png" />
                                            View Cost Breakdown
                                          </a>
                                        </li>
                                        <li>
                                          <a href="javascript:void(0);">
                                            <img src="images/download.png" />
                                            Download QR
                                          </a>
                                        </li>
                                        <li>
                                          <a href="javascript:void(0);">
                                            <img src="images/pdf.png" />
                                            Print QR
                                          </a>
                                        </li>
                                        {ele?.backChargeId && (
                                          <li>
                                            <a
                                              onClick={() =>
                                                handleToConvertFPOSC(
                                                  ele?.backChargeId,
                                                  "sc"
                                                )
                                              }
                                            >
                                              <img src="images/view_cost_breakdown.png" />
                                              Convert to Field-Purchase-Order
                                            </a>
                                          </li>
                                        )}
                                      </ol>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <NoDataFound />
                    )}
                  </tbody>
                </table>
              </div>
            </>

            <div className="Button mt-5 text-center">
              <button className="Outline" onClick={hideBiddingDetailModal}>
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={billDetailsModal}
        onHide={hideBillDetailModal}
        className="LargeModal"
      >
        <Modal.Body>
          <a className="CloseModal" onClick={hideBillDetailModal} role="button">
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>View QR/PO Mass Mailout</h4>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-between mb-5">
                    <div className="Heading mb-5">
                      <h3 className="d-flex ">
                        {" "}
                        QR/PO Mass Mailout # {billDetailsData?.id}
                        {/* <div className="TableThreeDots ml-3">
                            <ul className="">
                              <li className="dropdown">
                                <a
                                  className=""
                                  role="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img src="images/dots.png" />
                                </a>
                                <ol className="dropdown-menu dropdown-menuwidth2">
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="CursorPointer FolderPermissionId"
                                      data-toggle="modal"
                                      data-target="#UploadAdditionalDocuments3"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/upload_additional.png" />
                                      Upload Additional Documents
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="CursorPointer FolderPermissionId"
                                      data-toggle="modal"
                                      data-target="#RequestLimitIncrease1"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/request_limit.png" />
                                      Request Limit Increase
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="CursorPointer FolderPermissionId"
                                      data-toggle="modal"
                                      data-target="#VendorBackChargeNotification3"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/notify_vendor.png" />
                                      Notify Vendor of Back Charge
                                    </a>
                                  </li>
                                </ol>
                              </li>
                            </ul>
                          </div> */}
                      </h3>
                    </div>
                    <div className="text-right">
                      <div className="ModalBigheading">
                        <h3 style={{ marginBottom: "10px!important" }}>
                          <span className="fw-700">Status:</span>
                          {billDetailsData?.status}
                        </h3>
                        <h3 style={{ marginBottom: "10px!important" }}>
                          <span className="fw-700">Total Documents:</span>
                          {billDetailsData?.Categories?.length}{" "}
                        </h3>
                      </div>
                      <div className="ModalMediumheading">
                        <h3 style={{ marginBottom: "10px!important" }}>
                          <span className="Green">Completed:</span> 0{" "}
                        </h3>
                        <h3 style={{ marginBottom: "0px!important" }}>
                          <span className="Red">Pending:</span> 0{" "}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="form-group">
                    <div className="CommonModalArea">
                      <h5>Vendor</h5>
                      <div className="CommonModalBox">
                        <figure
                          className="CursorPointer FolderPermissionId"
                          data-toggle="modal"
                          data-target="#SelectVendor6"
                          data-dismiss="modal"
                        >
                          <img src="images/vendor.png" />
                        </figure>
                        <h5 className="text-black">
                          {billDetailsData?.Vendor?.vendorName}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="form-group">
                    <h6>Date Sent</h6>
                    <p className="ml-0">
                      {billDetailsData?.createdAt?.split("T")[0]}
                    </p>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="form-group">
                    <h6>Date</h6>
                    <p className="ml-0">
                      {billDetailsData?.createdAt?.split("T")[0]}
                    </p>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5>Reminder</h5>
                      <div className="CommonModalBox">
                        <figure
                          className="FolderPermissionId CursorPointer"
                          data-toggle="modal"
                          data-target="#SetReminder12"
                          data-dismiss="modal"
                        >
                          <img src="images/timer.png" />
                        </figure>
                        <h5>{billDetailsData?.reminder?.split("T")[0]}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group">
                    <h6>Time since last status update</h6>
                    <p>{billDetailsData?.updatedAt?.split("T")[0]}</p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="ShippingAddressArea">
                    <div className="ShippingAddressAreaBox">
                      <h6>Mailing Address</h6>
                      <p className="text-grey-80">
                        {billDetailsData?.Vendor?.addressLine1},
                        {billDetailsData?.Vendor?.addressLine2}
                      </p>
                    </div>
                    <div className="ShippingAddressAreaBox">
                      <h6>Email</h6>
                      <p className="text-grey-80">
                        {billDetailsData?.Vendor?.email}
                      </p>
                    </div>
                    <div className="ShippingAddressAreaBox">
                      <h6>Phone</h6>
                      <p className="text-grey-80">
                        {billDetailsData?.Vendor?.phone}
                      </p>
                    </div>
                    {/* <div className="ShippingAddressAreaBox">
              <h6>Shipping Address</h6>
              <p className="text-grey-80">
                5384 Peach Rd Chilliwack, BC V2R0Z6
              </p>
            </div> */}
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="form-group">
                    <h6>Messsage</h6>
                    <p className="ml-0">{billDetailsData?.message}</p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="ItemDetails">
                    <h6>QR/PO Mass Mailout Items</h6>
                  </div>
                </div>
                <div className="col-12">
                  <div className="Tableheader mb-3 d-flex flex-sm-row align-items-sm-center flex-column align-items-start">
                    <h6 className="mb-sm-0 mb-3">Categories</h6>
                    <div className="d-flex flex-sm-row align-items-sm-center flex-column align-items-start">
                      <div className="Actions">
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <DownloadTableExcel
                          filename="Item List"
                          sheet="Item List"
                          currentTableRef={billDetailsItemRef.current}
                        >
                          <a href="javascript:void(0);">
                            <img src="images/download.png" />
                          </a>
                        </DownloadTableExcel>
                        {/* <a href="javascript:void(0);">
                            <img src="images/setting.png" />
                          </a> */}
                      </div>
                    </div>
                  </div>

                  <div class="TableList SchedulePaymentsTable TransactionTableShow TableListMaxHeight mb-4">
                    <table ref={billDetailsItemRef}>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Job Id</th>
                          <th>Cost Code</th>
                          <th>Cost Code Description</th>
                          <th>Item Description</th>
                          <th>Document Type</th>
                          <th>Document Reason</th>
                          <th>Document #</th>

                          <th>Builder's Subtototal</th>
                          <th>Vendor's Subtototal</th>
                          <th>Document Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {checkArray(billDetailsData?.Categories) ? (
                          billDetailsData?.Categories?.map((ele, i) => {
                            return (
                              <>
                                <tr class="MaterialTableBgColor">
                                  <td>{1 + 1}</td>
                                  <td>
                                    STATIC {i}
                                    (RIV-2B-366)
                                  </td>
                                  <td>{ele?.CostCode?.number}</td>
                                  <td>{ele?.CostCode?.name}</td>
                                  <td>{ele?.itemDescription}</td>
                                  <td>{ele?.documentType}</td>
                                  <td>{ele?.Reason?.description ?? "N/A"}</td>
                                  <td>{ele?.documentCode}</td>

                                  <td>{ele?.builderPreTaxTotal}</td>
                                  <td>{ele?.vendorPreTaxTotal}</td>
                                  <td>
                                    <div className="TextGreen">
                                      <td>{ele?.status}</td>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="TableThreeDots">
                                      <ul class="">
                                        <li class="dropdown">
                                          <a
                                            class=""
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol class="dropdown-menu dropdown-menuwidth2">
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                class="FolderPermissionId"
                                                data-toggle="modal"
                                                data-target="#ViewQuoteRequestAfterNotification2"
                                                data-dismiss="modal"
                                              >
                                                <img src="images/view-po.png" />
                                                View document
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                class="FolderPermissionId"
                                                data-toggle="modal"
                                                data-target="#BiddingModal2"
                                                data-dismiss="modal"
                                              >
                                                <img src="images/view_cost_code_biding.png" />
                                                View Cost Code Bidding
                                              </a>
                                            </li>
                                            <li>
                                              <a href="javascript:void(0);">
                                                <img src="images/cancel_documents.png" />
                                                Cancel document
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                class="FolderPermissionId"
                                                data-toggle="modal"
                                                data-target="#QuoteRequestCostBreakdown2"
                                                data-dismiss="modal"
                                              >
                                                <img src="images/view_cost_breakdown.png" />
                                                View Cost Breakdown
                                              </a>
                                            </li>
                                            <li>
                                              <a href="javascript:void(0);">
                                                <img src="images/download.png" />
                                                Download document
                                              </a>
                                            </li>
                                            <li>
                                              <a href="javascript:void(0);">
                                                <img src="images/pdf.png" />
                                                Print document
                                              </a>
                                            </li>
                                            <li>
                                              <a href="javascript:void(0);">
                                                <img src="images/resend_message.png" />
                                                Resend Document
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                                <tr class="MaterialTableBgColor">
                                  <td colspan="5">
                                    <div class="TableList CommonHeadingBlack CommonHeadingBlack2">
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>
                                              <div class="text-center">
                                                Material Description
                                                <br />
                                                (i.e: Modal/Type/Style/manuf
                                                part#/Dimensions, etc.)
                                              </div>
                                            </th>
                                            <th>Color</th>
                                            <th>Quantity</th>
                                            <th>Units</th>
                                            <th>Price/Unit</th>
                                            <th>Total</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {checkArray(ele?.BuilderItems) &&
                                            ele?.BuilderItems?.map(
                                              (item, i) => {
                                                return (
                                                  <>
                                                    <tr class="border-0">
                                                      <td>{item?.id}</td>
                                                      <td>
                                                        <div class="text-center">
                                                          {item?.name}
                                                        </div>
                                                      </td>
                                                      <td> {item?.color}</td>
                                                      <td> {item?.quantity}</td>
                                                      <td>
                                                        {" "}
                                                        {item?.Unit?.name}
                                                      </td>
                                                      <td>$ {item?.price}</td>
                                                      <td>$ {item?.total}</td>
                                                    </tr>
                                                  </>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                  <td colspan="8"></td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <NoDataFound />
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="row">
                    <div className="col-lg-4 ml-auto">
                      <div className="GstTable">
                        <table>
                          <tbody>
                            <tr>
                              <td>Profile</td>
                              <td>{billDetailsData?.TaxProfile?.name}</td>
                            </tr>
                            <tr>
                              <td>Subtotal</td>
                              <td>${billDetailsData?.subTotal}</td>
                              {/* <td>Modify Tax</td> */}
                            </tr>
                            <tr>
                              <td>
                                {
                                  billDetailsData?.TaxProfile?.taxRows?.[0]
                                    ?.taxName
                                }
                                (
                                {
                                  billDetailsData?.TaxProfile?.taxRows?.[0]
                                    ?.taxPercent
                                }
                                %)
                              </td>
                              <td>
                                ${" "}
                                {(Number(billDetailsData?.subTotal) *
                                  Number(
                                    billDetailsData?.TaxProfile?.taxRows?.[0]
                                      ?.taxPercent
                                  )) /
                                  100}
                              </td>
                            </tr>
                            <tr>
                              <td>Grand Total</td>
                              <td>$ {billDetailsData?.total}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-12">
                    <div className="mb-4">
                      <div className="Heading">
                        <h6 className="text-black">Attachments</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Scope of Work</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#SelectScopesOfWork11"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/scopeofwork.png" />
                            </figure>{" "}
                            Scope of Work
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Construction Documents</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#SelectConstructionDocuments11"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/construction_documents.png" />
                            </figure>{" "}
                            Construction Documents
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Additional Documents</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#UploadAdditionalDocumentsUnder11"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/upload_additional.png" />
                            </figure>{" "}
                            Additional Documents
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div> */}
              </div>
              <div className="Button d-flex justify-content-between mt-4 ">
                {/* <button className="Create">Print Bill</button> */}
                <button className="Outline" onClick={hideBillDetailModal}>
                  Close
                </button>
              </div>
            </div>
            <div class="DiscussionArea">
              <div class="DiscussionTitle">
                <h4>Discussion</h4> <span>{discussionList?.length ?? 0}</span>
                <div class="DiscussionChat">
                  <img
                    src={require("../assets/images/down.png")}
                    class="DiscussionIcon"
                  />
                  <a>
                    <img src={require("../assets/images/pdf2.png")} />
                  </a>
                  <a onClick={() => handleDiscussionSettingsModalShow()}>
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
              <div class="DiscussionHideArea" style={{ display: "block" }}>
                <div class="DiscussionBox">
                  <span>FM</span>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      value={discussionState?.message}
                      onChange={(e) =>
                        updateDiscussionState({
                          ...discussionState,
                          message: e.target.value,
                        })
                      }
                      placeholder="Add a Comment ....."
                    />
                    {discussionState.discussionError &&
                      !discussionState.message && (
                        <p style={{ color: "red" }}>
                          {discussionState.discussionError}
                        </p>
                      )}

                    <div class="DiscussionOverlay">
                      <a
                        onClick={() =>
                          updateDiscussionState({
                            ...discussionState,
                            message: "",
                          })
                        }
                        class="CancelChat"
                      >
                        <img
                          src={require("../assets/images/plus-cancel1.png")}
                        />
                      </a>
                      <a onClick={handleAddDiscussion} class="SendChat">
                        <img
                          src={require("../assets/images/forwardbutton.png")}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                {discussionList?.map((item, i) => (
                  <>
                    <div class="DiscussionBox">
                      <span>CO</span>
                      <div class="DiscussionRight">
                        <h6>{item?.message}</h6>
                        <small>{moment(item.updatedAt).format("lll")}</small>
                      </div>
                    </div>
                    <div class="ReplyArea">
                      <a
                        onClick={(e) => {
                          updateReplyField({ flag: true, index: i });
                          updateReplyState({ ...replyState, message: "" });
                        }}
                      >
                        Reply
                      </a>
                      {item?.ReplyDiscussions?.map((ele) => (
                        <div class="DiscussionBox">
                          <span>RE</span>
                          <div class="DiscussionRight">
                            <h6>{ele?.message}</h6>
                            <small>{moment(ele.updatedAt).format("lll")}</small>
                          </div>
                        </div>
                      ))}

                      {replyField.index == i && (
                        <div class="DiscussionBox w-100">
                          <span>FM{item?.id}</span>
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              value={replyState?.message}
                              onChange={(e) =>
                                updateReplyState({
                                  ...replyState,
                                  message: e.target.value,
                                  messageId: item?.id,
                                  jobId: item?.jobId,
                                })
                              }
                              placeholder="Add reply ....."
                            />
                            {discussionState.discussionError &&
                              !discussionState.message && (
                                <p style={{ color: "red" }}>
                                  {discussionState.discussionError}
                                </p>
                              )}

                            <div class="DiscussionOverlay">
                              <a
                                onClick={() =>
                                  updateReplyState({
                                    ...replyState,
                                    message: "",
                                  })
                                }
                                class="CancelChat"
                              >
                                <img
                                  src={require("../assets/images/plus-cancel1.png")}
                                />
                              </a>
                              <a onClick={handleAddReply} class="SendChat">
                                <img
                                  src={require("../assets/images/forwardbutton.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={quoteDetailsModal}
        onHide={hideQuoteDetailsModal}
        className="LargeModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={() => hideQuoteDetailsModal()}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div
              className={`ModalTitleBox ${
                quoteDetailsData?.backChargeId
                  ? "ModalTitleBoxBrown"
                  : "ModalTitleBoxYellow"
              }`}
            >
              <h4>View Quote Request</h4>
            </div>
            <div className="FolderForm">
              <div
                className={`BlueTextArea ${
                  quoteDetailsData?.backChargeId
                    ? "BrownTextArea"
                    : "YellowTextArea"
                }`}
              >
                <div className="row">
                  <div className="col-lg-6">
                    <div className="Heading">
                      <h3>Quote Request # {quoteDetailsData?.documentCode}</h3>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="Heading">
                      <h3>
                        Cost Code:{" "}
                        <span>
                          {quoteDetailsData?.CostCode?.number}{" "}
                          {quoteDetailsData?.CostCode?.name}
                        </span>
                      </h3>
                      <h6>
                        Status:<span> {quoteDetailsData?.status}</span>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="form-group col-lg-3">
                    <h6>Reason</h6>
                    <p>{quoteDetailsData?.Reason?.description ?? "N/A"}</p>
                  </div>
                  <div className="form-group col-lg-3">
                    <h6>Sent By</h6>
                    <p>
                      {quoteDetailsData?.issuedBy?.name ||
                        quoteDetailsData?.issuedBy?.email ||
                        "N/A"}
                    </p>
                  </div>
                  <div className="form-group col-lg-3">
                    <div className="CommonModalArea">
                      <h5
                        className={`BlueTextArea ${
                          quoteDetailsData?.backChargeId
                            ? "BrownText"
                            : "YellowText"
                        }`}
                      >
                        History
                      </h5>

                      <div className="CommonModalBox">
                        <h5 className="FolderPermission" data-dismiss="modal">
                          <figure>
                            <p>{quoteDetailsData?.createdAt?.split("T")[0]}</p>
                            {/* <img src="images/timer.png" /> */}
                          </figure>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5
                          className={`BlueTextArea ${
                            quoteDetailsData?.backChargeId
                              ? "BrownText"
                              : "YellowText"
                          }`}
                        >
                          Vendor
                        </h5>
                        <div className="CommonModalBox">
                          <figure className="FolderPermissionId CursorPointer">
                            <img src="images/vendor.png" />
                          </figure>
                          <h5 className="text-black">
                            {" "}
                            <p>{quoteDetailsData?.Vendor?.vendorName}</p>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5
                          className={`BlueTextArea ${
                            quoteDetailsData?.backChargeId
                              ? "BrownText"
                              : "YellowText"
                          }`}
                        >
                          Date Sent
                        </h5>
                        <div className="CommonModalBox">
                          <h5 className="text-black">
                            {quoteDetailsData?.date?.split("T")[0]}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5
                          className={`BlueTextArea ${
                            quoteDetailsData?.backChargeId
                              ? "BrownText"
                              : "YellowText"
                          }`}
                        >
                          Reminder
                        </h5>
                        <div className="CommonModalBox">
                          <figure className="FolderPermissionId CursorPointer">
                            <img src="images/timer.png" />
                          </figure>
                          <h5>
                            {" "}
                            <p>{quoteDetailsData?.reminder?.split("T")[0]}</p>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <h6>Time since last status update</h6>
                      <p className="ml-3">
                        {" "}
                        <p>{quoteDetailsData?.updatedAt?.split("T")[0]}</p>
                      </p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ShippingAddressArea">
                      <div className="ShippingAddressAreaBox">
                        <h6>Mailing Address</h6>
                        <p>
                          {" "}
                          <p>
                            {quoteDetailsData?.Vendor?.addressLine1} <br />
                            {quoteDetailsData?.Vendor?.addressLine2}
                          </p>
                        </p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Email</h6>
                        <p>{quoteDetailsData?.Vendor?.email}</p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Phone</h6>
                        <p>{quoteDetailsData?.Vendor?.phone}</p>
                      </div>
                    </div>
                  </div>
                  {quoteDetailsData?.BackCharge && (
                    <>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <div className="CommonModalArea">
                            <h5
                              className={`BlueTextArea ${
                                quoteDetailsData?.backChargeId
                                  ? "BrownText"
                                  : "YellowText"
                              }`}
                            >
                              Back Charge Vendor
                            </h5>
                            <div className="CommonModalBox">
                              <h5 className="text-black">
                                {/* <figure>
                              <img src="images/vendor.png" />
                            </figure>{" "} */}
                                {quoteDetailsData?.BackCharge?.Vendor
                                  ?.vendorName ?? "N/A"}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group">
                          <div className="CommonModalArea">
                            <h5
                              className={`BlueTextArea ${
                                quoteDetailsData?.backChargeId
                                  ? "BrownText"
                                  : "YellowText"
                              }`}
                            >
                              Back Charge Notification Status
                            </h5>
                            <div className="CommonModalBox">
                              <h5 className="text-black">
                                <figure
                                  className="CursorPointer FolderPermissionId"
                                  onClick={() =>
                                    handleBackChargeNotificationListModalShow(
                                      quoteDetailsData?.BackCharge,
                                      "qrDetail"
                                    )
                                  }
                                >
                                  <img src="images/notification_accept.png" />
                                </figure>
                                {`${
                                  quoteDetailsData?.BackCharge
                                    ?.BackChargeNotifications?.[0]?.title ?? ""
                                } -${moment(
                                  quoteDetailsData?.BackCharge
                                    ?.BackChargeNotifications?.updatedAt
                                ).format("YYYY-MM-DD & hh:mm a")}`}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-lg-7">
                    <div className="form-group">
                      <h6>Messsage</h6>
                      <p className="text-grey-80">
                        {quoteDetailsData?.message}
                      </p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ItemDetails">
                      <h6>Item Details</h6>
                    </div>
                  </div>
                  {(quoteDetailsData?.status === "CANCELLED" ||
                    quoteDetailsData?.status === "RETURNED") && (
                    <>
                      <div className="col-12">
                        <div className="Tableheader mb-3">
                          <h6 className="mb-0">Vendor Item</h6>
                          <div className="Actions">
                            <a href="javascript:void(0);">
                              <img src="images/icon-53.png" />
                            </a>
                            <DownloadTableExcel
                              filename="Item List"
                              sheet="Item List"
                              currentTableRef={quoteDetailsItemRef.current}
                            >
                              <a href="javascript:void(0);">
                                <img src="images/download.png" />
                              </a>
                            </DownloadTableExcel>
                            <a href="javascript:void(0);">
                              <img src="images/setting.png" />
                            </a>
                          </div>
                        </div>
                        <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                          <table ref={quoteDetailsItemRef}>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>
                                  <div className="text-center">
                                    Material Description
                                    <br />
                                    (i.e: Modal/Type/Style/manuf
                                    part#/Dimensions, etc.)
                                  </div>
                                </th>
                                <th>Color</th>
                                <th>Quantity</th>
                                <th>Units</th>
                                <th>Price/Unit</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {vendorEditToggle === true ? (
                                itemDetails?.length > 0 ? (
                                  itemDetails.map((data, index) => (
                                    <tr key={index}>
                                      <td>{data.id}</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="name"
                                          value={data.name}
                                          onChange={(e) =>
                                            handleDetailsChange(e, index)
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="color"
                                          value={data.color}
                                          onChange={(e) =>
                                            handleDetailsChange(e, index)
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="quantity"
                                          value={data.quantity}
                                          min={0}
                                          onChange={(e) =>
                                            handleDetailsChange(e, index)
                                          }
                                        />
                                      </td>
                                      <td>
                                        <select
                                          className="form-control"
                                          name="unitId"
                                          value={data.unitId}
                                          onChange={(e) =>
                                            handleDetailsChange(e, index)
                                          }
                                        >
                                          <option value="">Select</option>
                                          {checkArray(estimationUnitListData) &&
                                            estimationUnitListData?.map(
                                              (ele, i) => {
                                                return (
                                                  <option value={ele?.id}>
                                                    {" "}
                                                    {ele?.name}
                                                  </option>
                                                );
                                              }
                                            )}
                                        </select>
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="price"
                                          value={data.price}
                                          min={0}
                                          onChange={(e) =>
                                            handleDetailsChange(e, index)
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={
                                            Number(data.price) *
                                              Number(data.quantity)?.toFixed(
                                                2
                                              ) || 0
                                          }
                                          readOnly
                                        />
                                      </td>
                                      <td>
                                        <div className="TableThreeDots">
                                          <ul>
                                            <li className="dropdown">
                                              <a
                                                role="button"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                <img
                                                  src="images/dots.png"
                                                  alt="menu"
                                                />
                                              </a>
                                              <ol className="dropdown-menu">
                                                <li
                                                  onClick={() =>
                                                    removeDetailsClick(index)
                                                  }
                                                >
                                                  <a href="javascript:void(0);">
                                                    <img
                                                      src="images/dropdown-delete.png"
                                                      alt="delete"
                                                    />
                                                    Remove Line
                                                  </a>
                                                </li>
                                              </ol>
                                            </li>
                                          </ul>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="12">
                                      <p>No Data found.</p>
                                    </td>
                                  </tr>
                                )
                              ) : quoteDetailsData?.VendorItems?.length > 0 ? (
                                quoteDetailsData?.VendorItems?.map(
                                  (item, i) => (
                                    <tr key={i}>
                                      <td>{item.id}</td>
                                      <td>{item.name}</td>
                                      <td>{item.color}</td>
                                      <td>{item.quantity}</td>
                                      <td>{item.Unit?.name}</td>
                                      <td>$ {item.price}</td>
                                      <td>$ {item.total}</td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <tr>
                                  <td colSpan="12">
                                    <p>No Data found.</p>
                                  </td>
                                </tr>
                              )}

                              {vendorEditToggle === true && (
                                <tr>
                                  <td colSpan={8}>
                                    <div className="TableButtons">
                                      <button
                                        className="BrandBtn"
                                        onClick={addDetailsClick}
                                      >
                                        Add Line
                                      </button>
                                      <button
                                        className="BrandBtn ml-3"
                                        onClick={handleClear}
                                      >
                                        Clear all lines
                                      </button>
                                      <button
                                        className="BrandBtn ml-3"
                                        onClick={copyBuilderItems}
                                      >
                                        <img src="images/copy_builder_item.png" />
                                        Copy Builder´s Items
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-12">
                    <div className="Tableheader mb-3">
                      <h6 className="mb-0">Builder Item</h6>
                      <div className="Actions">
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <DownloadTableExcel
                          filename="Item List"
                          sheet="Item List"
                          currentTableRef={quoteDetailsItemRef.current}
                        >
                          <a href="javascript:void(0);">
                            <img src="images/download.png" />
                          </a>
                        </DownloadTableExcel>
                        <a href="javascript:void(0);">
                          <img src="images/setting.png" />
                        </a>
                      </div>
                    </div>
                    <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                      <table ref={quoteDetailsItemRef}>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>
                              <div className="text-center">
                                Material Description
                                <br />
                                (i.e: Modal/Type/Style/manuf part#/Dimensions,
                                etc.)
                              </div>
                            </th>
                            <th>Color</th>
                            <th>Quantity</th>
                            <th>Units</th>
                            <th>Price/Unit</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {vendorEditToggle &&
                          (quoteDetailsData?.status === "SEND" ||
                            quoteDetailsData?.status === "DRAFT" ||
                            quoteDetailsData?.status === "RESEND") ? (
                            itemDetailss?.length > 0 ? (
                              itemDetailss?.map((data, index) => (
                                <tr key={index}>
                                  <td>{data.id}</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="name"
                                      value={data.name}
                                      onChange={(e) =>
                                        handleDetailssChange(e, index)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="color"
                                      value={data.color}
                                      onChange={(e) =>
                                        handleDetailssChange(e, index)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="quantity"
                                      value={data.quantity}
                                      onChange={(e) =>
                                        handleDetailssChange(e, index)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <select
                                      className="form-control"
                                      name="unitId"
                                      value={data.unitId}
                                      onChange={(e) =>
                                        handleDetailssChange(e, index)
                                      }
                                    >
                                      <option value="">Select</option>
                                      {checkArray(estimationUnitListData) &&
                                        estimationUnitListData?.map(
                                          (ele, i) => {
                                            return (
                                              <option value={ele?.id}>
                                                {" "}
                                                {ele?.name}
                                              </option>
                                            );
                                          }
                                        )}
                                    </select>
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="price"
                                      value={data.price}
                                      onChange={(e) =>
                                        handleDetailssChange(e, index)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={
                                        Number(data.price) *
                                          Number(data.quantity)?.toFixed(2) || 0
                                      }
                                      readOnly
                                    />
                                  </td>
                                  <td>
                                    <div className="TableThreeDots">
                                      <ul>
                                        <li className="dropdown">
                                          <a
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img
                                              src="images/dots.png"
                                              alt="menu"
                                            />
                                          </a>
                                          <ol className="dropdown-menu">
                                            <li
                                              onClick={() =>
                                                removeDetailssClick(index)
                                              }
                                            >
                                              <a href="javascript:void(0);">
                                                <img
                                                  src="images/dropdown-delete.png"
                                                  alt="delete"
                                                />
                                                Remove Line
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="12">
                                  <p>No Data found.</p>
                                </td>
                              </tr>
                            )
                          ) : quoteDetailsData?.BuilderItems?.length > 0 ? (
                            quoteDetailsData?.BuilderItems?.map((item, i) => (
                              <tr key={i}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.color}</td>
                                <td>{item.quantity}</td>
                                <td>{item.Unit?.name}</td>
                                <td>$ {item.price}</td>
                                <td>$ {item.total}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="12">
                                <p>No Data found.</p>
                              </td>
                            </tr>
                          )}

                          {vendorEditToggle === true &&
                            (quoteDetailsData?.status === "SEND" ||
                              quoteDetailsData?.status === "DRAFT" ||
                              quoteDetailsData?.status === "RESEND") && (
                              <tr>
                                <td colSpan={8}>
                                  <div className="TableButtons">
                                    <button
                                      className="BrandBtn"
                                      onClick={addDetailssClick}
                                    >
                                      Add Line
                                    </button>
                                    <button
                                      className="BrandBtn ml-3"
                                      onClick={handleClears}
                                    >
                                      Clear all lines
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 ml-auto">
                        {vendorEditToggle && itemDetails?.[0]?.quantity > 0 ? (
                          <div className="GstTable">
                            <table>
                              <tbody>
                                <tr>
                                  <td>Profile</td>
                                  <td>
                                    {quoteDetailsData?.TaxProfile?.name ??
                                      "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Subtotal</td>
                                  <td>
                                    $
                                    {Number(
                                      itemDetails
                                        ?.reduce(
                                          (total, data) =>
                                            total + data.quantity * data.price,
                                          0
                                        )
                                        .toFixed(2) || 0
                                    )}
                                  </td>
                                  {/* <td>${totalWithoutTax}</td> */}
                                  {/* <td>Modify Tax</td> */}
                                </tr>
                                <tr>
                                  <td>
                                    CGST(
                                    {Number(quoteDetailsData?.gst)}
                                    %)
                                  </td>
                                  <td>
                                    $
                                    {(Number(
                                      itemDetails
                                        ?.reduce(
                                          (total, data) =>
                                            total + data.quantity * data.price,
                                          0
                                        )
                                        .toFixed(2) || 0
                                    ) *
                                      Number(quoteDetailsData?.gst)) /
                                      100}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {
                                      quoteDetailsData?.TaxProfile?.taxRows?.[0]
                                        ?.taxName
                                    }
                                    (
                                    {Number(
                                      quoteDetailsData?.TaxProfile?.taxRows?.[0]
                                        ?.taxPercent
                                    ) || 0}
                                    %)
                                  </td>
                                  <td>
                                    {" "}
                                    $
                                    {(Number(
                                      itemDetails
                                        ?.reduce(
                                          (total, data) =>
                                            total + data.quantity * data.price,
                                          0
                                        )
                                        .toFixed(2) || 0
                                    ) *
                                      (Number(
                                        quoteDetailsData?.TaxProfile
                                          ?.taxRows?.[0]?.taxPercent
                                      ) || 0)) /
                                      100}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Grand Total</td>
                                  <td>
                                    {/* ${Number(totalWithoutTax) + Number(finalTotal)} */}
                                    $
                                    {Number(
                                      itemDetails
                                        ?.reduce(
                                          (total, data) =>
                                            total + data.quantity * data.price,
                                          0
                                        )
                                        .toFixed(2) || 0
                                    ) +
                                      (Number(
                                        itemDetails
                                          ?.reduce(
                                            (total, data) =>
                                              total +
                                              data.quantity * data.price,
                                            0
                                          )
                                          .toFixed(2) || 0
                                      ) *
                                        (Number(
                                          quoteDetailsData?.TaxProfile
                                            ?.taxRows?.[0]?.taxPercent
                                        ) || 0)) /
                                        100 +
                                      (Number(
                                        itemDetails
                                          ?.reduce(
                                            (total, data) =>
                                              total +
                                              data.quantity * data.price,
                                            0
                                          )
                                          .toFixed(2) || 0
                                      ) *
                                        Number(quoteDetailsData?.gst)) /
                                        100}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        ) : vendorEditToggle &&
                          itemDetailss?.[0]?.quantity > 0 ? (
                          <div className="GstTable">
                            <table>
                              <tbody>
                                <tr>
                                  <td>Profile</td>
                                  <td>
                                    {quoteDetailsData?.TaxProfile?.name ??
                                      "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Subtotal</td>
                                  <td>
                                    $
                                    {Number(
                                      itemDetailss
                                        ?.reduce(
                                          (total, data) =>
                                            total + data.quantity * data.price,
                                          0
                                        )
                                        .toFixed(2) || 0
                                    )}
                                  </td>
                                  {/* <td>${totalWithoutTax}</td> */}
                                  {/* <td>Modify Tax</td> */}
                                </tr>
                                <tr>
                                  <td>
                                    CGST(
                                    {Number(quoteDetailsData?.gst)}
                                    %)
                                  </td>
                                  <td>
                                    $
                                    {(Number(
                                      itemDetailss
                                        ?.reduce(
                                          (total, data) =>
                                            total + data.quantity * data.price,
                                          0
                                        )
                                        .toFixed(2) || 0
                                    ) *
                                      Number(quoteDetailsData?.gst)) /
                                      100}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {
                                      quoteDetailsData?.TaxProfile?.taxRows?.[0]
                                        ?.taxName
                                    }
                                    (
                                    {Number(
                                      quoteDetailsData?.TaxProfile?.taxRows?.[0]
                                        ?.taxPercent
                                    ) || 0}
                                    %)
                                  </td>
                                  <td>
                                    {" "}
                                    $
                                    {(Number(
                                      itemDetailss
                                        ?.reduce(
                                          (total, data) =>
                                            total + data.quantity * data.price,
                                          0
                                        )
                                        .toFixed(2) || 0
                                    ) *
                                      (Number(
                                        quoteDetailsData?.TaxProfile
                                          ?.taxRows?.[0]?.taxPercent
                                      ) || 0)) /
                                      100}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Grand Total</td>
                                  <td>
                                    {/* ${Number(totalWithoutTax) + Number(finalTotal)} */}
                                    $
                                    {Number(
                                      itemDetailss
                                        ?.reduce(
                                          (total, data) =>
                                            total + data.quantity * data.price,
                                          0
                                        )
                                        .toFixed(2) || 0
                                    ) +
                                      (Number(
                                        itemDetailss
                                          ?.reduce(
                                            (total, data) =>
                                              total +
                                              data.quantity * data.price,
                                            0
                                          )
                                          .toFixed(2) || 0
                                      ) *
                                        (Number(
                                          quoteDetailsData?.TaxProfile
                                            ?.taxRows?.[0]?.taxPercent
                                        ) || 0)) /
                                        100 +
                                      (Number(
                                        itemDetailss
                                          ?.reduce(
                                            (total, data) =>
                                              total +
                                              data.quantity * data.price,
                                            0
                                          )
                                          .toFixed(2) || 0
                                      ) *
                                        Number(quoteDetailsData?.gst)) /
                                        100}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div className="GstTable">
                            <table>
                              <tbody>
                                <tr>
                                  <td>Profile</td>
                                  <td>
                                    {quoteDetailsData?.TaxProfile?.name ??
                                      "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Subtotal</td>
                                  <td>$ {quoteDetailsData?.subtotal || 0}</td>
                                </tr>
                                {quoteDetailsData?.otherTaxes?.[0] && (
                                  <tr>
                                    <td>{`${quoteDetailsData?.otherTaxes?.[0]?.taxName} (${quoteDetailsData?.otherTaxes?.[0]?.taxPercentage})%`}</td>
                                    <td>
                                      ${" "}
                                      {
                                        quoteDetailsData?.otherTaxes?.[0]
                                          ?.taxAmount
                                      }
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td>
                                    GST(
                                    {Number(
                                      quoteDetailsData?.TaxProfile?.taxRows?.[0]
                                        ?.taxPercent
                                    ) || 0}
                                    % )
                                  </td>
                                  <td>
                                    ${" "}
                                    {((Number(quoteDetailsData?.subtotal) ||
                                      0) *
                                      (Number(
                                        quoteDetailsData?.TaxProfile
                                          ?.taxRows?.[0]?.taxPercent
                                      ) || 0)) /
                                      100}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Grand Total</td>
                                  <td>
                                    ${" "}
                                    {Number(quoteDetailsData?.grandTotal) || 0}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-4">
                      <div className="Heading">
                        <h6>Attachments</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Scope of Work</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            onClick={() => handleDownloadAllScopeDocs()}
                          >
                            <figure>
                              <img src="images/scopeofwork.png" />
                            </figure>{" "}
                            Scope of Work &nbsp;
                            <span className="Count">{quoteScopeCounts}</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Construction Documents</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            onClick={() => handleDownloadAllConstructDocs()}
                          >
                            <figure>
                              <img src="images/construction_documents.png" />
                            </figure>{" "}
                            Construction Documents &nbsp;
                            <span className="Count">
                              {quoteConstructCounts}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Download (Additional) Documents</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            onClick={() => handleDownloadAllAdditionalDocs()}
                          >
                            <figure>
                              <img src="images/extermal_quote.png" />
                            </figure>{" "}
                            Additional Documents &nbsp;
                            <span className="Count">
                              {quoteAdditionalCounts}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between mt-4 ">
                  {quoteDetailsData?.status !== "CANCELLED" &&
                    quoteDetailsData?.status !== "APPROVED" && (
                      <>
                        <button
                          className="Create"
                          onClick={() => {
                            if (
                              (quoteDetailsData?.status === "RESEND" ||
                                quoteDetailsData?.status === "SEND" ||
                                quoteDetailsData?.status === "DRAFT") &&
                              vendorEditToggle
                            ) {
                              sendBuilderItems(quoteDetailsData?.id);
                            } else if (
                              quoteDetailsData?.status === "RETURNED" &&
                              vendorEditToggle
                            ) {
                              sendStatusFuncs(quoteDetailsData?.id);
                            } else {
                              sendStatusFunc(quoteDetailsData?.id, "RESEND");
                            }
                          }}
                        >
                          {vendorEditToggle ? "Save" : "Resend QR"}
                        </button>

                        <button
                          className="Create"
                          disabled={
                            quoteDetailsData?.status === "RETURNED" &&
                            quoteDetailsData?.VendorItems?.length > 0
                          }
                          onClick={handleVendorEditToggle}
                        >
                          {quoteDetailsData?.status === "RESEND" ||
                          quoteDetailsData?.status === "SEND" ||
                          quoteDetailsData?.status === "DRAFT"
                            ? "Edit Draft"
                            : quoteDetailsData?.status === "RETURNED"
                            ? "Key in return quote"
                            : ""}
                        </button>
                      </>
                    )}

                  <button className="Create">Print QR</button>
                  <button
                    className="Create"
                    onClick={() => hideQuoteDetailsModal()}
                  >
                    Close
                  </button>
                </div>
                <div class="DiscussionArea">
                  <div class="DiscussionTitle">
                    <h4>
                      Discussion <span>{discussionList?.length ?? 0}</span>
                    </h4>
                    <p></p>
                    <div class="DiscussionChat">
                      <img
                        src={require("../assets/images/down.png")}
                        class="DiscussionIcon"
                      />
                      <a>
                        <img src={require("../assets/images/pdf2.png")} />
                      </a>
                      <a onClick={() => handleDiscussionSettingsModalShow()}>
                        <img src="images/setting.png" />
                      </a>
                    </div>
                  </div>
                  <div class="DiscussionHideArea" style={{ display: "block" }}>
                    <div class="DiscussionBox">
                      <span>FM</span>
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          value={discussionState?.message}
                          onChange={(e) =>
                            updateDiscussionState({
                              ...discussionState,
                              message: e.target.value,
                            })
                          }
                          placeholder="Add a Comment ....."
                        />
                        {discussionState.discussionError &&
                          !discussionState.message && (
                            <p style={{ color: "red" }}>
                              {discussionState.discussionError}
                            </p>
                          )}

                        <div class="DiscussionOverlay">
                          <a
                            onClick={() =>
                              updateDiscussionState({
                                ...discussionState,
                                message: "",
                              })
                            }
                            class="CancelChat"
                          >
                            <img
                              src={require("../assets/images/plus-cancel1.png")}
                            />
                          </a>
                          <a onClick={handleAddDiscussion} class="SendChat">
                            <img
                              src={require("../assets/images/forwardbutton.png")}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    {discussionList?.map((item, i) => (
                      <>
                        <div class="DiscussionBox">
                          <span>CO</span>
                          <div class="DiscussionRight">
                            <h6>{item?.message}</h6>
                            <small>
                              {moment(item.updatedAt).format("lll")}
                            </small>
                          </div>
                        </div>
                        <div class="ReplyArea">
                          <a
                            onClick={(e) => {
                              updateReplyField({ flag: true, index: i });
                              updateReplyState({
                                ...replyState,
                                message: "",
                              });
                            }}
                          >
                            Reply
                          </a>
                          {item?.ReplyDiscussions?.map((ele) => (
                            <div class="DiscussionBox">
                              <span>RE</span>
                              <div class="DiscussionRight">
                                <h6>{ele?.message}</h6>
                                <small>
                                  {moment(ele.updatedAt).format("lll")}
                                </small>
                              </div>
                            </div>
                          ))}

                          {replyField.index == i && (
                            <div class="DiscussionBox w-100">
                              <span>FM{item?.id}</span>
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={replyState?.message}
                                  onChange={(e) =>
                                    updateReplyState({
                                      ...replyState,
                                      message: e.target.value,
                                      messageId: item?.id,
                                      jobId: item?.jobId,
                                    })
                                  }
                                  placeholder="Add reply ....."
                                />
                                {discussionState.discussionError &&
                                  !discussionState.message && (
                                    <p style={{ color: "red" }}>
                                      {discussionState.discussionError}
                                    </p>
                                  )}

                                <div class="DiscussionOverlay">
                                  <a
                                    onClick={() =>
                                      updateReplyState({
                                        ...replyState,
                                        message: "",
                                      })
                                    }
                                    class="CancelChat"
                                  >
                                    <img
                                      src={require("../assets/images/plus-cancel1.png")}
                                    />
                                  </a>
                                  <a onClick={handleAddReply} class="SendChat">
                                    <img
                                      src={require("../assets/images/forwardbutton.png")}
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={billListingModal}
        onHide={hideBillListingModal}
        className="LargeModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideBillListingModal}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>QR/PO Mass Mailout</h4>
            </div>

            <div className="BillsRowBorder d-flex flex-sm-row flex-column align-items-sm-center align-items-start">
              <h6 className="d-flex align-items-center mb-sm-0 mb-2">
                <img src="images/bills_black.png" className="mr-3" />
                QR/PO Mass Mailout
              </h6>
              <div className="d-flex align-items-center">
                <div className="TodosButtons mb-0 mt-0">
                  <a
                    href="javascript:void(0)"
                    className="FolderPermissionId"
                    onClick={() => showBillModal("billListing")}
                  >
                    <img src="images/create_new_bill.png" />
                    Create New QR/PO Mass Mailout
                  </a>
                </div>
                <div className="Actions">
                  <a href="javascript:void(0);">
                    <img src="images/icon-64.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/pdf.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/download.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
            </div>
            <div className="ModalBigheading mt-4">
              <h3>
                <span>QR/PO Mass Mailout List</span>
              </h3>
            </div>
            <div className="TableList CommonHeading">
              <table>
                <thead>
                  <tr>
                    <th>QR/PO Mass Mailout Number</th>
                    <th>Job #</th>
                    <th>Sent By</th>
                    <th>Sent To</th>
                    <th>Date Sent</th>
                    <th>Cost Code</th>
                    {/* <th>Item Description</th> */}
                    <th>Document Type</th>
                    <th>Document #</th>
                    <th>Pre-Tax Total</th>
                    <th>Vendor Pre-Tax Total</th>
                    <th>Document Status</th>
                    <th>Reminder</th>
                    <th>Time since last status update</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {checkArray(billListData?.data) ? (
                    billListData?.data?.map((ele, i) => {
                      return (
                        <>
                          <tr className="BorderCyan" key={iModalState}>
                            <td>{ele?.id}</td>
                            <td>{ele?.Categories?.[0]?.jobNumber}</td>
                            <td>{ele?.Categories?.[0]?.sendBy}</td>
                            <td>{ele?.Vendor?.vendorName}</td>
                            <td>{ele?.createdAt?.split("T")[0]}</td>
                            <td></td>
                            {/* <td>Appliances Quote</td> */}
                            <td>{ele?.Categories?.[0]?.documentType}</td>
                            <td>
                              <div className="TextLightSkyBlue"></div>
                            </td>
                            <td> ${ele?.total || 0}</td>
                            <td> ${ele?.Vendor?.total || 0}</td>
                            <td> {ele?.status}</td>
                            <td>
                              <a href="javascript:void(0);">
                                <img src="images/timer.png" className="mr-2" />
                              </a>
                              {ele?.reminder?.split("T")[0]}
                            </td>
                            <td>
                              {" "}
                              {calculateDateDifferenceInDays(
                                ele?.createdAt,
                                ele?.updatedAt
                              )}{" "}
                              Days
                            </td>

                            <td>
                              <div class="TableThreeDots">
                                <ul class="">
                                  <li class="dropdown">
                                    <a
                                      class=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol class="dropdown-menu dropdown-menuwidth2">
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          class="FolderPermissionId"
                                          onClick={() =>
                                            showBillDetailModal(
                                              "billListing",
                                              ele?.id
                                            )
                                          }
                                        >
                                          <img src="images/view-po.png" />
                                          View QR/PO Mass Mailout
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() =>
                                            changeBillStatusFunc(
                                              ele?.id,
                                              "CANCELLED"
                                            )
                                          }
                                          href="javascript:void(0);"
                                        >
                                          <img src="images/cancecl_message.png" />
                                          Cancel QR/PO Mass Mailout
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          onClick={() =>
                                            changeBillStatusFunc(
                                              ele?.id,
                                              "RESEND"
                                            )
                                          }
                                        >
                                          <img src="images/resend_all_documents.png" />
                                          Resend All Documents
                                        </a>
                                      </li>
                                      {/* <li>
                                        <a
                                          href="javascript:void(0);"
                                          class="FolderPermissionId"
                                          onClick={
                                            ele?.Categories?.[0]
                                              ?.documentType === "QUOTEREQUEST"
                                              ? () =>
                                                  showQuoteDetailsModal(
                                                    ele?.Categories?.[0]?.id
                                                  )
                                              : ele?.Categories?.[0]
                                                  ?.documentType ===
                                                "PURCHASEORDER"
                                              ? () =>
                                                  showPurchaseDetailsModal(
                                                    "test",
                                                    ele?.Categories?.[0]?.id
                                                  )
                                              : ele?.Categories?.[0]
                                                  ?.documentType ===
                                                "FIELDPURCHASEORDER"
                                              ? () =>
                                                  showFieldPurchaseOrderDetailsModal(
                                                    ele?.Categories?.[0]?.id
                                                  )
                                              : ele?.Categories?.[0]
                                                  ?.documentType ===
                                                "SUPPLIERCREDIT"
                                              ? () =>
                                                  showSupplierCreditDetailsModal(
                                                    "billListing",
                                                    ele?.Categories?.[0]?.id
                                                  )
                                              : ""
                                          }
                                        >
                                          <img src="images/view_documents.png" />
                                          View Document
                                        </a>
                                      </li> */}
                                      {/* <li>
                                        <a
                                          href="javascript:void(0);"
                                          onClick={
                                            ele?.Categories?.[0]
                                              ?.documentType === "QUOTEREQUEST"
                                              ? () =>
                                                  sendStatusFunc(
                                                    ele?.Categories?.[0]?.id,"SEND"
                                                  )
                                              : ele?.Categories?.[0]
                                                  ?.documentType ===
                                                "PURCHASEORDER"
                                              ? (e) =>
                                                  sendPOFunc(
                                                    e,
                                                    ele?.Categories?.[0]?.id,
                                                    "CANCELLED"
                                                  )
                                              : ele?.Categories?.[0]
                                                  ?.documentType ===
                                                "FIELDPURCHASEORDER"
                                              ? (e) =>
                                                  changeFieldPurchaseOrderStatusFunc(
                                                    e,
                                                    "CANCELLED",
                                                    ele?.Categories?.[0]?.id
                                                  )
                                              : ele?.Categories?.[0]
                                                  ?.documentType ===
                                                "SUPPLIERCREDIT"
                                              ? (e) =>
                                                  changeSupplierCreditStatusFunc(
                                                    e,
                                                    ele?.Categories?.[0]?.id,
                                                    "CANCELLED"
                                                  )
                                              : ""
                                          }
                                        >
                                          <img src="images/cancel_documents.png" />
                                          Cancel Document
                                        </a>
                                      </li> */}
                                      {/* <li>
                                        <a
                                          href="javascript:void(0);"
                                          onClick={
                                            ele?.Categories?.[0]
                                              ?.documentType === "QUOTEREQUEST"
                                              ? () =>
                                                  sendStatusFunc(
                                                    ele?.Categories?.[0]?.id
                                                  )
                                              : ele?.Categories?.[0]
                                                  ?.documentType ===
                                                "PURCHASEORDER"
                                              ? (e) =>
                                                  sendPOFunc(
                                                    e,
                                                    ele?.Categories?.[0]?.id,
                                                    "RESEND"
                                                  )
                                              : ele?.Categories?.[0]
                                                  ?.documentType ===
                                                "FIELDPURCHASEORDER"
                                              ? (e) =>
                                                  changeFieldPurchaseOrderStatusFunc(
                                                    e,
                                                    "RESEND",
                                                    ele?.Categories?.[0]?.id
                                                  )
                                              : ele?.Categories?.[0]
                                                  ?.documentType ===
                                                "SUPPLIERCREDIT"
                                              ? (e) =>
                                                  changeSupplierCreditStatusFunc(
                                                    e,
                                                    ele?.Categories?.[0]?.id,
                                                    "RESEND"
                                                  )
                                              : ""
                                          }
                                        >
                                          <img src="images/resend_message.png" />
                                          Resend Document
                                        </a>
                                      </li> */}
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </td>
                            {/* <td>
            <div className="TableThreeDots">
              <ul className="">
                <li className="dropdown show">
                  <a
                    className=""
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    <img src="images/dots.png" />
                  </a>
                  <ol
                    className="dropdown-menu dropdown-menuwidth2 show">
                    <li>
                      <a
                        href="javascript:void(0);"
                        className="FolderPermissionId"
                         onClick={() => showBillDetailModal()}
                      >
                        <img src="images/view-po.png" />
                        View Bill
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <img src="images/cancecl_message.png" />
                        Cancel Bill
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <img src="images/resend_all_documents.png" />
                        Resend All Documents
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        className="FolderPermissionId"
                        data-toggle="modal"
                        data-target="#"
                        data-dismiss="modal"
                      >
                        <img src="images/view_documents.png" />
                        View Document
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <img src="images/cancel_documents.png" />
                        Cancel Document
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <img src="images/resend_message.png" />
                        Resend Document
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <img src="images/download.png" />
                        Download Bill
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <img src="images/pdf.png" />
                        Print Bill
                      </a>
                    </li>
                  </ol>
                </li>
              </ul>
            </div>
          </td> */}
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <NoDataFound />
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className="PaginationArea">
              <h6>Showing 1 to 10 of 27 entries</h6>
              <h6>
                <img src="images/leftarrow.png" /> 01{" "}
                <img src="images/rightarrow.png" /> Out 10
              </h6>
            </div>
            <div className="Button mt-3 text-center">
              <button className="Outline" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={BackChargeListingModal}
        onHide={hideBackChargeListingModal}
        className="LargeModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideBackChargeListingModal}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox flex-lg-row flex-column align-items-lg-center align-items-start">
              <h4>Back Charge Supplier Credit</h4>
              <div className="TodosButtons mt-lg-0 mt-2 d-flex align-items-center">
                <a onClick={() => showAddbackChargeModal("")}>
                  <img src="images/create_new_bill.png" />
                  Create Back Charge
                </a>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-lg-6">
                <div className="form-group">
                  <h6>Back Charge Automatic Document Generation</h6>
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider" />
                  </label>
                </div>
              </div> */}
              <div className="col-lg-6">
                <div className="form-group">
                  <h6>Back Charge Document Automatic Accounting Sync</h6>
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider" />
                  </label>
                </div>
              </div>
            </div>
            <div className="NotesArea mb-2">
              <h6>Supplier Credits</h6>
              <div>
                <a href="javascript:void(0);">
                  <img src="images/pdf.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/download.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/setting.png" />
                </a>
              </div>
            </div>
            <div className="TableList CommonHeading TableHeight500">
              <table>
                <thead>
                  <tr>
                    <th>Supplier Credit Number</th>
                    <th>P.O /FPO Number</th>
                    <th>Quote Request Number</th>
                    <th>Originating Document</th>
                    <th>Cost Code</th>
                    <th>Sent By</th>
                    <th>Reason</th>
                    <th>Sent To</th>
                    <th>Date Created</th>
                    <th>Date Synced to Accounting Software</th>
                    <th>Pre-Tax Total</th>
                    <th>Status</th>
                    <th>Attachments</th>
                    <th>Reminder</th>
                    <th>Back Charge Notifications</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!loaderBackCharge ? (
                    backChargeListData?.data?.length > 0 ? (
                      backChargeListData?.data?.map((data, ind) => {
                        return (
                          <tr key={ind}>
                            <td>
                              {data.SupplierCredit !== null
                                ? data?.SupplierCredit?.documentCode
                                : "N/A"}
                            </td>
                            <td>
                              {data?.PurchaseOrder !== null ? (
                                <div
                                  className="TextBlue"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    showPurchaseDetailsModal(
                                      "po",
                                      data?.PurchaseOrder?.id
                                    )
                                  }
                                >
                                  {data?.PurchaseOrder?.documentCode}
                                </div>
                              ) : (
                                <div className="TextBlue">
                                  {data?.PurchaseOrder?.documentCode ?? "N/A"}
                                </div>
                              )}
                            </td>
                            <td>
                              {data?.QuoteRequest !== null ? (
                                <div
                                  className="TextBlue"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    showQuoteDetailsModal(
                                      "qr",
                                      data?.QuoteRequest?.id
                                    )
                                  }
                                >
                                  {data?.QuoteRequest?.documentCode}
                                </div>
                              ) : (
                                <div className="TextBlue">
                                  {data?.QuoteRequest?.documentCode ?? "N/A"}
                                </div>
                              )}
                            </td>

                            <td>
                              <div className="TextBlue">
                                {data?.originalDocument?.documentCode ?? "N/A"}
                              </div>
                            </td>
                            <td>{`${data?.CostCode.number ?? ""} ${
                              data?.CostCode.name ?? ""
                            }`}</td>
                            <td>
                              {" "}
                              {data?.issuedBy?.name ||
                                data?.issuedBy?.email ||
                                "N/A"}
                            </td>
                            <td>{data?.Reason?.description ?? "N/A"}</td>

                            <td>
                              {data?.Vendor?.vendorName || data?.Vendor?.email}
                            </td>
                            <td>
                              {moment(data?.createdAt).format("YYYY-MM-DD")}
                            </td>
                            <td>
                              {data.documentSyncDate
                                ? moment(data?.documentSyncDate).format(
                                    "YYYY-MM-DD"
                                  )
                                : ""}
                            </td>
                            <td>
                              {data.QuoteRequest?.builderPreTaxTotal ?? 0}
                            </td>
                            <td>
                              <div className="TextRed">{data?.status}</div>
                            </td>
                            <td>
                              <div
                                className="TextRed"
                                onClick={() =>
                                  handleViewAttachmentsModalShow(
                                    data?.QuoteRequest
                                  )
                                }
                              >
                                <a href="javascript:void(0);">
                                  <img src="images/attachment.png" />
                                </a>
                                (
                                {(data.QuoteRequest?.additionalDocuments
                                  ?.length ?? 0) +
                                  (data.QuoteRequest?.constructionDocuments
                                    ?.length ?? 0)}
                                )
                              </div>
                            </td>
                            <td>
                              <a href="javascript:void(0);" className="TextRed">
                                <img src="images/timer.png" />
                              </a>
                              {calculateDateDifferenceInDays(
                                data?.createdAt,
                                data?.updatedAt
                              )}
                              days
                            </td>
                            <td>
                              <a href="javascript:void(0);" className="TextRed">
                                <img src="images/document-support.png" /> (
                                {data.backChargeNotificationCount ?? 0})
                              </a>
                            </td>
                            <td>
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol
                                      className="dropdown-menu dropdown-menuwidth2"
                                      style={{}}
                                    >
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleViewSupplierCreditModalShow()
                                          }
                                        >
                                          <img src="images/view-po.png" />
                                          View Supplier Credit
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleViewAttachmentsModalShow(
                                              data?.QuoteRequest
                                            )
                                          }
                                        >
                                          <img src="images/attachment.png" />
                                          View Attachments
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleVendorBackChargeNotificationModalShow(
                                              "list",
                                              data
                                            )
                                          }
                                        >
                                          {/* <img src="images/attachment.png" /> */}
                                          Send Vendor Notification
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleBackChargeNotificationListModalShow(
                                              data,
                                              "list"
                                            )
                                          }
                                        >
                                          {/* <img src="images/attachment.png" /> */}
                                          View-Vendor Notification
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleBackChargeSync(data.id)
                                          }
                                        >
                                          <img src="images/resend_all_documents.png" />
                                          Sync With Accounting Software
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleCancelBackCharge(data.id)
                                          }
                                        >
                                          <img src="images/cancecl_message.png" />
                                          Cancel Back Charge Process
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleViewQuoteRequestCostBreakDownModalShow(
                                              data?.QuoteRequest?.id
                                            )
                                          }
                                        >
                                          <img src="images/cancel_documents.png" />
                                          View Cost Breakdown
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <img src="images/resend_message.png" />
                                          Download Supplier Credit
                                        </a>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="12s">
                          <p>No Data found.</p>
                        </td>
                      </tr>
                    )
                  ) : (
                    <Loader />
                  )}
                </tbody>
              </table>
            </div>
            <div className="Button mt-5 text-center">
              <button className="Outline" onClick={hideBackChargeListingModal}>
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={addBackChargeModal}
        onHide={hideAddBackChargeModal}
        className="SmallModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideAddBackChargeModal}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Back Charge</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>Select Job</h6>
                <div className="Categories Categories1">
                  <div className="Categories_all Categories_all1 p-0">
                    {/* <select
                      name="jobCodeId"
                      value={jobCodeId}
                      onChange={handleInput}
                    >
                      <option value=""> Select Job </option>
                      {checkArray(jobListData?.jobs) &&
                        jobListData?.jobs?.map((ele, i) => {
                          return (
                            <option value={ele?.id}>{ele?.jobNumber}</option>
                          );
                        })}
                    </select> */}
                    <input
                      type="text"
                      className="w-100 p-2"
                      value={state?.jobNumber}
                      disabled
                    />
                    <img src="images/down.png" />
                  </div>
                </div>
              </div>
              <div className="ModalMediumheading">
                <h3>
                  <span>New Document Details</span>
                </h3>
              </div>
              <div className="NewDocumentsDetailsBox">
                <div className="form-group">
                  <h6>Cost Category</h6>
                  <div className="Categories Categories1">
                    <div className="Categories_all Categories_all1 p-0">
                      <select
                        name="costCategoryId"
                        value={costCategoryId}
                        onChange={handleInput}
                      >
                        <option value=""> Select Cost Category </option>
                        {checkArray(commonCostCategoryListData?.data) &&
                          commonCostCategoryListData?.data?.map((ele, i) => {
                            return (
                              <option
                                value={ele?.id}
                                selected={costCategoryId == ele?.id}
                                key={i}
                              >
                                ({ele?.number}) {ele?.name}
                              </option>
                            );
                          })}
                      </select>
                      <img src="images/down.png" />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <h6>Cost Code</h6>
                  <div className="Categories Categories1">
                    <div className="Categories_all Categories_all1 p-0">
                      <select
                        name="costCodeId"
                        value={costCodeId}
                        onChange={handleInput}
                      >
                        <option value=""> Select CostCode</option>
                        {checkArray(commonCostCodeListData?.data) &&
                          commonCostCodeListData?.data?.map((ele, i) => {
                            return (
                              <option
                                value={ele?.id}
                                selected={costCodeId == ele?.id}
                                key={i}
                              >
                                ({ele?.number}) {ele?.name}
                              </option>
                            );
                          })}
                      </select>
                      <img src="images/down.png" />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Get Quote or start FPO</h5>
                    <div className="CommonModalBox">
                      <h5>
                        <figure
                          onClick={() => showSystemVendor("backChargeModal")}
                        >
                          <img src="images/vendor.png" />
                        </figure>{" "}
                        {vendorName
                          ? vendorName
                          : "The vendor to fix the damage"}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-9">
                    <div className="form-group">
                      <h6>Document Type</h6>
                      <div className="d-flex justify-content-between">
                        <label className="Radio">
                          QR
                          <input
                            type="radio"
                            name="Cuisine"
                            value="QUOTEREQUEST"
                            checked={selectedOption === "QUOTEREQUEST"}
                            onChange={handleOptionChange}
                          />
                          <span className="checkmark" />
                        </label>

                        <label className="Radio">
                          FPO
                          <input
                            type="radio"
                            name="Cuisine"
                            value="FIELDPURCHASEORDER"
                            checked={selectedOption === "FIELDPURCHASEORDER"}
                            onChange={handleOptionChange}
                          />
                          <span className="checkmark" />
                        </label>
                        <label className="Radio">
                          SC
                          <input
                            type="radio"
                            name="Cuisine"
                            value="SUPPLIERCREDIT"
                            checked={selectedOption === "SUPPLIERCREDIT"}
                            onChange={handleOptionChange}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ModalMediumheading mt-4">
                <h3>
                  <span className="text-black">Back-Charge Vendor</span>
                </h3>
              </div>
              <div className="NewDocumentsDetailsBox">
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Back-Charge Vendor</h5>
                    <div className="CommonModalBox">
                      <h5
                        onClick={() => handleSystemVendorModalBackChargeShow()}
                      >
                        <figure>
                          <img src="images/vendor.png" />
                        </figure>{" "}
                        {vendorNames
                          ? vendorNames
                          : "The vendor that caused the damage"}
                      </h5>
                    </div>
                  </div>
                </div>
                {/* <div className="form-group">
                  <h6>Back Charge is related to a current PO/FPO?</h6>
                  <div className="d-flex">
                    <label className="Radio">
                      {" "}
                      Yes
                      <input
                        type="radio"
                        name="Cuisines"
                        // defaultValue="text"
                        // defaultChecked=""
                      />
                      <span className="checkmark" />
                    </label>
                    <div className="ml-4">
                      <label className="Radio">
                        {" "}
                        No
                        <input
                          type="radio"
                          name="Cuisines"
                          // defaultValue="file"
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div> */}
                {/* <div className="text RadioText" style={{ display: "block" }}>
                  <div className="form-group">
                    <h6>Back Charge Subject Document</h6>
                    <div className="Categories Categories1">
                      <div className="Categories_all Categories_all1">
                        <select
                          name="originalDocumentId"
                          value={originalDocumentId}
                          onChange={handleInput}
                        >
                          <option value=""> Select Documents</option>
                          {relatedDocumentsList?.data?.length > 0 &&
                            relatedDocumentsList?.data?.map((ele, i) => {
                              return (
                                <option key={i} value={ele?.id}>
                                  {ele?.documentCode}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="Button mt-4 d-flex justify-content-between">
              <button className="Create" onClick={handleCreateBackCharge}>
                Create Back Charge
              </button>
              <button className="Outline" onClick={hideAddBackChargeModal}>
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* SELECTJOBMODALSHOW */}
      <Modal
        show={SelectJobModal}
        onHide={hideSelectJobModal}
        className="SmallModal"
      >
        <Modal.Body>
          <a className="CloseModal" onClick={hideSelectJobModal} role="button">
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create - Select Job</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>Job</h6>
                <div className="Categories Categories1">
                  <div className="Categories_all Categories_all1">
                    <select
                      name="jobCodeId"
                      value={jobCodeId}
                      onChange={handleInput}
                    >
                      <option value=""> Select Job </option>
                      {checkArray(jobListData?.jobs) &&
                        jobListData?.jobs?.map((ele, i) => {
                          return (
                            <option value={ele?.id}>{ele?.jobNumber}</option>
                          );
                        })}
                    </select>
                    <img src="images/down.png" />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <h6>Block</h6>
                <div className="Categories Categories2">
                  <div className="Categories_all Categories_all2">
                    <select
                      name="BlockId"
                      value={BlockId}
                      onChange={handleInput}
                    >
                      <option> Select Block </option>
                      {checkArray(blockListData?.data) &&
                        blockListData?.data?.map((ele, i) => {
                          return (
                            <option value={ele?.id}>
                              {ele?.blockDescription}
                            </option>
                          );
                        })}
                    </select>
                    <img src="images/down.png" />
                  </div>
                  <ul>
                    <li>
                      <input type="text" placeholder="-Search" />
                      <span className="Search">
                        <img src="images/search.png" />
                      </span>
                    </li>
                    <li>
                      Block jobs loaded from the system after job is selected
                    </li>
                  </ul>
                </div>
              </div>
              <div className="form-group">
                <h6>Unit</h6>
                <div className="Categories Categories3">
                  <div className="Categories_all Categories_all3">
                    <select name="UnitId" value={UnitId} onChange={handleInput}>
                      <option> Select Unit </option>
                      {checkArray(unitListData?.data) &&
                        unitListData?.data?.map((ele, i) => {
                          return (
                            <option value={ele?.id}>{ele?.unitNumber}</option>
                          );
                        })}
                    </select>
                    <img src="images/down.png" />
                  </div>
                  <ul>
                    <li>
                      <input type="text" placeholder="-Search" />
                      <span className="Search">
                        <img src="images/search.png" />
                      </span>
                    </li>
                    <li>
                      Unit jobs loaded from the system after block is selected
                    </li>
                  </ul>
                </div>
              </div>
              <div className="Button d-flex justify-content-between">
                <button
                  className="Create ConfirmSelectionBtn"
                  onClick={hideSelectJobModal}
                >
                  Confirm Selection
                </button>
                <button className="Outline" onClick={hideSelectJobModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* SELECTCOSTMODALSHOW */}
      <Modal
        show={SelectCostModal}
        onHide={hideSelectCostModal}
        className="SmallModal"
      >
        <Modal.Body>
          <a className="CloseModal" onClick={hideSelectCostModal} role="button">
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create - Select Job and Cost Code</h4>
            </div>
            <div className="FolderForm">
              {/* <div className="form-group">
                <div className="CommonModalArea">
                  <h5>Select Job</h5>
                  <div className="CommonModalBox">
                    <h5
                      className="CursorPointer FolderPermissionId"
                      onClick={() => showSelectJobModal("costModal")}
                    >
                      <figure>
                        <img src="images/select_job_black.png" />
                      </figure>{" "}
                      Select Job
                    </h5>
                  </div>
                </div>
              </div> */}

              <div className="form-group">
                <h6>Select Job</h6>
                <div className="Categories Categories1">
                  <div className="Categories_all Categories_all1">
                    <select
                      name="jobCodeId"
                      value={jobCodeId}
                      onChange={handleInput}
                    >
                      <option value=""> Select Job </option>
                      {checkArray(jobListData?.jobs) &&
                        jobListData?.jobs?.map((ele, i) => {
                          return (
                            <option value={ele?.id}>{ele?.jobNumber}</option>
                          );
                        })}
                    </select>
                    <img src="images/down.png" />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <h6>Cost Category</h6>
                <div className="Categories Categories1">
                  <div className="Categories_all Categories_all1">
                    <select
                      name="costCategoryId"
                      value={costCategoryId}
                      onChange={handleInput}
                    >
                      <option value=""> Select Cost Category </option>
                      {checkArray(commonCostCategoryListData?.data) &&
                        commonCostCategoryListData?.data?.map((ele, i) => {
                          return (
                            <option value={ele?.id}>
                              {" "}
                              ({ele?.number}) {ele?.name}{" "}
                            </option>
                          );
                        })}
                    </select>
                    <img src="images/down.png" />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <h6>Cost Code</h6>
                <div className="Categories Categories1">
                  <div className="Categories_all Categories_all1">
                    <select
                      name="costCodeId"
                      value={costCodeId}
                      onChange={handleInput}
                    >
                      <option value=""> Select CostCode</option>
                      {checkArray(commonCostCodeListData?.data) &&
                        commonCostCodeListData?.data?.map((ele, i) => {
                          return (
                            <option value={ele?.id}>
                              ({ele?.number}) {ele?.name}
                            </option>
                          );
                        })}
                    </select>
                    <img src="images/down.png" />
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between">
                <button
                  className="Create ConfirmSelectionBtn2"
                  onClick={saveJobCOstCOdeID}
                >
                  Confirm Selection
                </button>
                <button className="Outline" onClick={hideSelectCostModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={QuoteRequestCostBreakDownModal}
        onHide={handleQuoteRequestCostBreakDownModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleQuoteRequestCostBreakDownModalClose}
          >
            ×
          </a>

          <div className="FolderForm">
            <div className="JobTitleArea mb-2">
              <div className="JobBox">
                <h6>
                  <span>Quote Request:</span> 13-304
                </h6>
              </div>
            </div>
            <div className="TableSmallHeading">
              <h6>Builder's Original Input</h6>
              <h6>*Pre-Tax</h6>
            </div>
            <div className="TableList CommonHeading ">
              <table>
                <thead>
                  <tr>
                    <th>
                      <div className="text-center">
                        Material Description
                        <br />
                        (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                      </div>
                    </th>
                    <th>Color</th>
                    <th>Quantity</th>
                    <th>Units</th>
                    <th>Price/Unit</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {checkArray(quoteDetailsData?.BuilderItems) ? (
                    quoteDetailsData?.BuilderItems?.map((ele) => {
                      return (
                        <>
                          <tr>
                            <td>{ele?.id}</td>
                            <td>{ele?.name}</td>
                            <td>{ele?.color}</td>
                            <td>{ele?.quantity}</td>
                            <td>{ele?.unitId}</td>
                            <td>{ele?.price}</td>
                            <td>{ele?.total}</td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <NoDataFound />
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className="TableSmallHeading mt-5">
              <h6>Vendor's Cost Breakdown</h6>
              <h6>*Pre-Tax</h6>
            </div>
            <div className="TableList CommonHeading">
              <table>
                <thead>
                  <tr>
                    <th>
                      <div className="text-center">
                        Material Description
                        <br />
                        (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                      </div>
                    </th>
                    <th>Color</th>
                    <th>Quantity</th>
                    <th>Units</th>
                    <th>Price/Unit</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {checkArray(quoteDetailsData?.VendorItems) ? (
                    quoteDetailsData?.VendorItems?.map((ele) => {
                      return (
                        <>
                          <tr>
                            <td>{ele?.id}</td>
                            <td>{ele?.name}</td>
                            <td>{ele?.color}</td>
                            <td>{ele?.quantity}</td>
                            <td>{ele?.unitId}</td>
                            <td>{ele?.price}</td>
                            <td>{ele?.total}</td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <NoDataFound />
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group mt-5">
                  <h6>Valid Until</h6>
                  <p className="ml-0">
                    <img src="images/calendar.png" className="mr-2" />
                    06/30//2022
                  </p>
                </div>
              </div>
            </div>
            <div className="Button mt-4 text-center">
              <button
                className="Outline"
                onClick={handleQuoteRequestCostBreakDownModalClose}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={purchaseDetailsModal}
        onHide={hidePurchaseDetailsModal}
        className="LargeModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hidePurchaseDetailsModal}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox ModalTitleBoxBlue">
              <h4>View Purchase Order</h4>
            </div>
            <div className="FolderForm">
              <div className="BlueTextArea BlueTextArea">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="Heading">
                      <h3>Purchase Order # {quoteDetailsData?.documentCode}</h3>
                    </div>
                  </div>
                  {/* <div className="col-lg-6">
                      <div className="Heading">
                        <h3 className="d-flex">
                          Rivers Edge Unit 366(RIV-2B-366)
                          <div className="TableThreeDots ml-5">
                            <ul className="">
                              <li className="dropdown">
                                <a
                                  className=""
                                  role="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img src="images/dots.png" />
                                </a>
                                <ol className="dropdown-menu ChangeOrderDropdown2">
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="d-flex align-items-center"
                                    >
                                      <img src="images/upload_additional.png" />
                                      Upload Additional Documents
                                      <span className="CountThree">3</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/cancel_qr.png" />
                                      Cancel QR
                                    </a>
                                  </li>
                                </ol>
                              </li>
                            </ul>
                          </div>
                        </h3>
                        <h3>
                          Cost Code: <span>23-0101 - Appliances</span>
                        </h3>
                        <h6>
                          Status:<span> Quote Sent</span>
                        </h6>
                      </div>
                    </div> */}
                </div>
                <div className="row mt-3">
                  <div className="form-group col-lg-3">
                    <h6>Reason</h6>
                    <p>{quoteDetailsData?.Reason?.description}</p>
                  </div>
                  <div className="form-group col-lg-3">
                    <h6>Sent By</h6>
                    <p>{quoteDetailsData?.issuedBy?.name}</p>
                  </div>
                  <div className="form-group col-lg-3">
                    <div className="CommonModalArea">
                      <h5 className="YellowText">History</h5>

                      <div className="CommonModalBox">
                        <h5 className="FolderPermission" data-dismiss="modal">
                          <figure>
                            <p>{quoteDetailsData?.createdAt?.split("T")[0]}</p>
                            {/* <img src="images/timer.png" /> */}
                          </figure>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5 className="BlueText">Vendor</h5>
                        <div className="CommonModalBox">
                          <figure className="FolderPermissionId CursorPointer">
                            <img src="images/vendor.png" />
                          </figure>
                          <h5 className="text-black">
                            {" "}
                            <p>{quoteDetailsData?.Vendor?.vendorName}</p>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5 className="BlueText">Date Sent</h5>
                        <div className="CommonModalBox">
                          <h5 className="text-black">
                            {quoteDetailsData?.date?.split("T")[0]}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5 className="BlueText">Reminder</h5>
                        <div className="CommonModalBox">
                          <figure className="FolderPermissionId CursorPointer">
                            <img src="images/timer.png" />
                          </figure>
                          <h5>
                            {" "}
                            <p>{quoteDetailsData?.reminder?.split("T")[0]}</p>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <h6>Time since last status update</h6>
                      <p className="ml-3">
                        {" "}
                        <p>{quoteDetailsData?.updatedAt?.split("T")[0]}</p>
                      </p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ShippingAddressArea">
                      <div className="ShippingAddressAreaBox">
                        <h6>Mailing Address</h6>
                        <p>
                          {" "}
                          <p>
                            {quoteDetailsData?.Vendor?.addressLine1} <br />
                            {quoteDetailsData?.Vendor?.addressLine2}
                          </p>
                        </p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Email</h6>
                        <p>{quoteDetailsData?.Vendor?.email}</p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Phone</h6>
                        <p>{quoteDetailsData?.Vendor?.phone}</p>
                      </div>
                      {/* <div className="ShippingAddressAreaBox">
                <h6>Shipping Address</h6>
                <p>5384 Peach Rd Chilliwack, BC V2R0Z6</p>
              </div> */}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5 className="BlueText">Back Charge Vendor</h5>
                        <div className="CommonModalBox">
                          <h5 className="BlueText">
                            {quoteDetailsData?.Vendor?.vendorName ?? "N/A"}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5 className="BlueText">
                          Back Charge Notification Status
                        </h5>
                        <div className="CommonModalBox">
                          <h5 className="BlueText">
                            <figure
                              className="CursorPointer FolderPermissionId"
                              onClick={() =>
                                handleBackChargeNotificationListModalShow(
                                  quoteDetailsData?.BackCharge,
                                  "poDetail"
                                )
                              }
                            >
                              <img src="images/notification_accept.png" />
                            </figure>
                            Notification Accepted -{" "}
                            {`${
                              quoteDetailsData?.BackCharge
                                ?.BackChargeNotifications?.[0]?.activity ?? ""
                            } -${moment(
                              quoteDetailsData?.BackCharge
                                ?.BackChargeNotifications?.updatedAt
                            ).format("YYYY-MM-DD & hh:mm a")}`}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <h6>Messsage</h6>
                      <p className="text-grey-80">
                        {quoteDetailsData?.message}
                      </p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ItemDetails">
                      <h6>Item Details</h6>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="Tableheader mb-3">
                      <h6 className="mb-0"> Item</h6>
                      <div className="Actions">
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <DownloadTableExcel
                          filename="Item List"
                          sheet="Item List"
                          currentTableRef={quoteDetailsItemRef.current}
                        >
                          <a href="javascript:void(0);">
                            <img src="images/download.png" />
                          </a>
                        </DownloadTableExcel>
                        <a href="javascript:void(0);">
                          <img src="images/setting.png" />
                        </a>
                      </div>
                    </div>
                    <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                      <table ref={quoteDetailsItemRef}>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>
                              <div className="text-center">
                                Material Description
                                <br />
                                (i.e: Modal/Type/Style/manuf part#/Dimensions,
                                etc.)
                              </div>
                            </th>
                            <th>Color</th>
                            <th>Quantity</th>
                            <th>Units</th>
                            <th>Price/Unit</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {checkArray(quoteDetailsData?.Items) ? (
                            quoteDetailsData?.Items?.map((ele) => {
                              return (
                                <>
                                  <tr>
                                    <td>{ele?.id}</td>
                                    <td>{ele?.name}</td>
                                    <td>{ele?.color}</td>
                                    <td>{ele?.quantity}</td>
                                    <td>{ele?.unitId}</td>
                                    <td>{ele?.price}</td>
                                    <td>{ele?.total}</td>
                                  </tr>
                                </>
                              );
                            })
                          ) : (
                            <>
                              <NoDataFound />
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-lg-4 ml-auto">
                        <div className="GstTable">
                          <table>
                            <tbody>
                              <tr>
                                <td>Profile</td>
                                <td>$ {quoteDetailsData?.TaxProfile?.name}</td>
                              </tr>
                              <tr>
                                <td>Subtotal</td>
                                <td>$ {quoteDetailsData?.subtotal}</td>
                              </tr>
                              <tr>
                                <td>
                                  {" "}
                                  GST(
                                  {Number(
                                    quoteDetailsData?.TaxProfile?.taxRows?.[0]
                                      ?.taxPercent
                                  ) || 0}
                                  % )
                                </td>
                                <td>
                                  ${" "}
                                  {((Number(quoteDetailsData?.subtotal) || 0) *
                                    (Number(
                                      quoteDetailsData?.TaxProfile?.taxRows?.[0]
                                        ?.taxPercent
                                    ) || 0)) /
                                    100}
                                </td>
                              </tr>
                              <tr>
                                <td>Grand Total</td>
                                <td>
                                  {" "}
                                  $
                                  {(Number(quoteDetailsData?.subtotal) || 0) +
                                    ((Number(quoteDetailsData?.subtotal) || 0) *
                                      (Number(
                                        quoteDetailsData?.TaxProfile
                                          ?.taxRows?.[0]?.taxPercent
                                      ) || 0)) /
                                      100}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12">
                      <div className="mb-4">
                        <div className="Heading">
                          <h6>Attachments</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="CommonModalArea">
                        <div className="form-group">
                          <h5>Scope of Work</h5>
                          <div className="CommonModalBox">
                            <h5 className="FolderPermissonId CursorPointer">
                              <figure>
                                <img src="images/scopeofwork.png" />
                              </figure>{" "}
                              Scope of Work
                            </h5>
                            &nbsp; <span className="Count">3</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="CommonModalArea">
                        <div className="form-group">
                          <h5>Construction Documents</h5>
                          <div className="CommonModalBox">
                            <h5 className="FolderPermissonId CursorPointer">
                              <figure>
                                <img src="images/construction_documents.png" />
                              </figure>{" "}
                              Construction Documents
                            </h5>
                            &nbsp; <span className="Count">3</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="CommonModalArea">
                        <div className="form-group">
                          <h5>External Quote</h5>
                          <div className="CommonModalBox">
                            <h5 className="FolderPermissonId CursorPointer">
                              <figure>
                                <img src="images/extermal_quote.png" />
                              </figure>{" "}
                              External Quote
                            </h5>
                            &nbsp; <span className="Count">3</span>
                          </div>
                        </div>
                      </div>
                    </div> */}
                </div>
                <div className="Button d-flex justify-content-between mt-4 ">
                  <button
                    className="Create"
                    onClick={(e) =>
                      sendPOFunc(e, quoteDetailsData?.id, "RESEND")
                    }
                  >
                    Resend QR
                  </button>
                  <button
                    className="Create"
                    onClick={(e) =>
                      sendPOFunc(e, quoteDetailsData?.id, "RETURNED")
                    }
                  >
                    Import a Return Quote
                  </button>
                  <button className="Create">Print QR</button>
                  <button className="Create" onClick={hidePurchaseDetailsModal}>
                    Close
                  </button>
                </div>

                <div class="DiscussionArea">
                  <div class="DiscussionTitle">
                    <h4>
                      Discussion <span>{discussionList?.length ?? 0}</span>
                    </h4>
                    <div class="DiscussionChat">
                      <img
                        src={require("../assets/images/down.png")}
                        class="DiscussionIcon"
                      />
                      <a>
                        <img src={require("../assets/images/pdf2.png")} />
                      </a>
                      <a onClick={() => handleDiscussionSettingsModalShow()}>
                        <img src="images/setting.png" />
                      </a>
                    </div>
                  </div>
                  <div class="DiscussionHideArea" style={{ display: "block" }}>
                    <div class="DiscussionBox">
                      <span>FM</span>
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          value={discussionState?.message}
                          onChange={(e) =>
                            updateDiscussionState({
                              ...discussionState,
                              message: e.target.value,
                            })
                          }
                          placeholder="Add a Comment ....."
                        />
                        {discussionState.discussionError &&
                          !discussionState.message && (
                            <p style={{ color: "red" }}>
                              {discussionState.discussionError}
                            </p>
                          )}

                        <div class="DiscussionOverlay">
                          <a
                            onClick={() =>
                              updateDiscussionState({
                                ...discussionState,
                                message: "",
                              })
                            }
                            class="CancelChat"
                          >
                            <img
                              src={require("../assets/images/plus-cancel1.png")}
                            />
                          </a>
                          <a onClick={handleAddDiscussion} class="SendChat">
                            <img
                              src={require("../assets/images/forwardbutton.png")}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    {discussionList?.map((item, i) => (
                      <>
                        <div class="DiscussionBox">
                          <span>CO</span>
                          <div class="DiscussionRight">
                            <h6>{item?.message}</h6>
                            <small>
                              {moment(item.updatedAt).format("lll")}
                            </small>
                          </div>
                        </div>
                        <div class="ReplyArea">
                          <a
                            onClick={(e) => {
                              updateReplyField({ flag: true, index: i });
                              updateReplyState({
                                ...replyState,
                                message: "",
                              });
                            }}
                          >
                            Reply
                          </a>
                          {item?.ReplyDiscussions?.map((ele) => (
                            <div class="DiscussionBox">
                              <span>RE</span>
                              <div class="DiscussionRight">
                                <h6>{ele?.message}</h6>
                                <small>
                                  {moment(ele.updatedAt).format("lll")}
                                </small>
                              </div>
                            </div>
                          ))}

                          {replyField.index == i && (
                            <div class="DiscussionBox w-100">
                              <span>FM{item?.id}</span>
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={replyState?.message}
                                  onChange={(e) =>
                                    updateReplyState({
                                      ...replyState,
                                      message: e.target.value,
                                      messageId: item?.id,
                                      jobId: item?.jobId,
                                    })
                                  }
                                  placeholder="Add reply ....."
                                />
                                {discussionState.discussionError &&
                                  !discussionState.message && (
                                    <p style={{ color: "red" }}>
                                      {discussionState.discussionError}
                                    </p>
                                  )}

                                <div class="DiscussionOverlay">
                                  <a
                                    onClick={() =>
                                      updateReplyState({
                                        ...replyState,
                                        message: "",
                                      })
                                    }
                                    class="CancelChat"
                                  >
                                    <img
                                      src={require("../assets/images/plus-cancel1.png")}
                                    />
                                  </a>
                                  <a onClick={handleAddReply} class="SendChat">
                                    <img
                                      src={require("../assets/images/forwardbutton.png")}
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={uploadAdditionModal}
        onHide={hideUploadAdditionModal}
        className="SmallModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideUploadAdditionModal}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Upload Additional Documents</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input type="file" name="files" onChange={handleInputFile} />
                </div>
              </div>
              {/* <div className="Button mb-4">
        <button className="Create">Add</button>
      </div> */}
              {/* <div className="form-group mb-2">
        <h4>Images</h4>
        <div className="FilesAdded">
          <label>picture01.jpg</label>
          <span>
            <img src="images/view-po.png" />
          </span>
          <span>
            <img src="images/fileview.png" />
          </span>
          <span className="cross">
            <img src="images/Cross.png" />
          </span>
        </div>
        <div className="FilesAdded">
          <label>picture01.jpg</label>
          <span>
            <img src="images/view-po.png" />
          </span>
          <span>
            <img src="images/fileview.png" />
          </span>
          <span className="cross">
            <img src="images/Cross.png" />
          </span>
        </div>
      </div> */}
              <div className="Button d-flex justify-content-between mt-5">
                <button className="Create" onClick={addAdditionalDocuments}>
                  Save
                </button>
                <button className="Outline" onClick={hideUploadAdditionModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={purchaseViewCostBreakdownModal}
        onHide={hidePurchaseViewCostBreakdownModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hidePurchaseViewCostBreakdownModal}
          >
            ×
          </a>

          <div className="FolderForm">
            <div className="JobTitleArea mb-2">
              <div className="JobBox">
                <h6>
                  <span>Request Code:</span>
                  {quoteDetailsData.documentCode}
                </h6>
              </div>
            </div>
            <div className="TableSmallHeading">
              <h6>Item List</h6>
              <h6>*Pre-Tax</h6>
            </div>
            <div className="TableList CommonHeading">
              <table>
                <thead>
                  <tr>
                    <th>
                      <div className="text-center">
                        Material Description
                        <br />
                        (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                      </div>
                    </th>
                    <th>Color</th>
                    <th>Quantity</th>
                    <th>Units</th>
                    <th>Price/Unit</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {checkArray(quoteDetailsData?.Items) ? (
                    quoteDetailsData?.Items?.map((ele) => {
                      return (
                        <>
                          <tr>
                            {/* <td>{ele?.id}</td> */}
                            <td>{ele?.name}</td>
                            <td>{ele?.color}</td>
                            <td>{ele?.quantity}</td>
                            <td>{ele?.unitId}</td>
                            <td>{ele?.price}</td>
                            <td>{ele?.total}</td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <NoDataFound />
                    </>
                  )}
                </tbody>
              </table>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group mt-5">
                  <h6>Valid Until</h6>
                  <p className="ml-0">
                    <img src="images/calendar.png" className="mr-2" />
                    06/30//2022
                  </p>
                </div>
              </div>
            </div>
            <div className="Button mt-4 text-center">
              <button
                className="Outline"
                onClick={hidePurchaseViewCostBreakdownModal}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={fieldPurchaseOrderDetailsModal}
        onHide={hideFieldPurchaseOrderDetailsModal}
        className="LargeModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideFieldPurchaseOrderDetailsModal}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox ModalTitleBoxGreen">
              <h4>View Field Purchase Request</h4>
            </div>
            <div className="FolderForm">
              <div className="BlueTextArea GreenTextArea">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="Heading">
                      <h3>Field Purchase # {quoteDetailsData?.documentCode}</h3>
                    </div>
                  </div>
                  {/* <div className="col-lg-6">
                      <div className="Heading">
                        <h3 className="d-flex">
                          Rivers Edge Unit 366(RIV-2B-366)
                          <div className="TableThreeDots ml-5">
                            <ul className="">
                              <li className="dropdown">
                                <a
                                  className=""
                                  role="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img src="images/dots.png" />
                                </a>
                                <ol className="dropdown-menu ChangeOrderDropdown2">
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="d-flex align-items-center"
                                    >
                                      <img src="images/upload_additional.png" />
                                      Upload Additional Documents
                                      <span className="CountThree">3</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/cancel_qr.png" />
                                      Cancel QR
                                    </a>
                                  </li>
                                </ol>
                              </li>
                            </ul>
                          </div>
                        </h3>
                        <h3>
                          Cost Code: <span>23-0101 - Appliances</span>
                        </h3>
                        <h6>
                          Status:<span> Quote Sent</span>
                        </h6>
                      </div>
                    </div> */}
                </div>
                <div className="row mt-3">
                  <div className="form-group col-lg-3">
                    <h6>Reason</h6>
                    <p>{quoteDetailsData?.Reason?.description}</p>
                  </div>
                  <div className="form-group col-lg-3">
                    <h6>Sent By</h6>
                    <p>
                      {quoteDetailsData?.issuedBy?.name ||
                        quoteDetailsData?.issuedBy?.email ||
                        "N/A"}
                    </p>
                  </div>
                  <div className="form-group col-lg-3">
                    <div className="CommonModalArea">
                      <h5 className="GreenText">History</h5>

                      <div className="CommonModalBox">
                        <h5 className="FolderPermission" data-dismiss="modal">
                          <figure>
                            <p>{quoteDetailsData?.createdAt?.split("T")[0]}</p>
                            {/* <img src="images/timer.png" /> */}
                          </figure>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5 className="GreenText">Vendor</h5>
                        <div className="CommonModalBox">
                          <figure className="FolderPermissionId CursorPointer">
                            <img src="images/vendor.png" />
                          </figure>
                          <h5 className="text-black">
                            {" "}
                            <p>{quoteDetailsData?.Vendor?.vendorName}</p>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5 className="GreenText">Date Sent</h5>
                        <div className="CommonModalBox">
                          <h5 className="text-black">
                            {quoteDetailsData?.date?.split("T")[0]}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5 className="GreenText">Reminder</h5>
                        <div className="CommonModalBox">
                          <figure className="FolderPermissionId CursorPointer">
                            <img src="images/timer.png" />
                          </figure>
                          <h5>
                            {" "}
                            <p>{quoteDetailsData?.reminder?.split("T")[0]}</p>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <h6>Time since last status update</h6>
                      <p className="ml-3">
                        {" "}
                        <p>{quoteDetailsData?.updatedAt?.split("T")[0]}</p>
                      </p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ShippingAddressArea">
                      <div className="ShippingAddressAreaBox">
                        <h6>Mailing Address</h6>
                        <p>
                          {" "}
                          <p>
                            {quoteDetailsData?.Vendor?.addressLine1} <br />
                            {quoteDetailsData?.Vendor?.addressLine2}
                          </p>
                        </p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Email</h6>
                        <p>{quoteDetailsData?.Vendor?.email}</p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Phone</h6>
                        <p>{quoteDetailsData?.Vendor?.phone}</p>
                      </div>
                      {/* <div className="ShippingAddressAreaBox">
                <h6>Shipping Address</h6>
                <p>5384 Peach Rd Chilliwack, BC V2R0Z6</p>
              </div> */}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5 className="GreenText">Back Charge Vendor</h5>
                        <div className="CommonModalBox">
                          <h5 className="text-black">
                            {quoteDetailsData?.Vendor?.vendorName ?? "N/A"}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5 className="GreenText">
                          Back Charge Notification Status
                        </h5>
                        <div className="CommonModalBox">
                          <h5 className="GreenText">
                            <figure
                              className="CursorPointer FolderPermissionId"
                              onClick={() =>
                                handleBackChargeNotificationListModalShow(
                                  quoteDetailsData?.BackCharge,
                                  "fpoDetail"
                                )
                              }
                            >
                              <img src="images/notification_accept.png" />
                            </figure>
                            {`${
                              quoteDetailsData?.BackCharge
                                ?.BackChargeNotifications?.[0]?.activity ?? ""
                            } -${moment(
                              quoteDetailsData?.BackCharge
                                ?.BackChargeNotifications?.updatedAt
                            ).format("YYYY-MM-DD & hh:mm a")}`}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <h6>Messsage</h6>
                      <p className="text-grey-80">
                        {quoteDetailsData?.message}
                      </p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ItemDetails">
                      <h6>Item Details</h6>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="Tableheader mb-3">
                      <h6 className="mb-0"> Item</h6>
                      <div className="Actions">
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <DownloadTableExcel
                          filename="Item List"
                          sheet="Item List"
                          currentTableRef={quoteDetailsItemRef.current}
                        >
                          <a href="javascript:void(0);">
                            <img src="images/download.png" />
                          </a>
                        </DownloadTableExcel>
                        <a href="javascript:void(0);">
                          <img src="images/setting.png" />
                        </a>
                      </div>
                    </div>
                    <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                      <table ref={quoteDetailsItemRef}>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>
                              <div className="text-center">
                                Material Description
                                <br />
                                (i.e: Modal/Type/Style/manuf part#/Dimensions,
                                etc.)
                              </div>
                            </th>
                            <th>Color</th>
                            <th>Quantity</th>
                            <th>Units</th>
                            <th>Price/Unit</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {checkArray(quoteDetailsData?.Items) ? (
                            quoteDetailsData?.Items?.map((ele) => {
                              return (
                                <>
                                  <tr>
                                    <td>{ele?.id}</td>
                                    <td>{ele?.name}</td>
                                    <td>{ele?.color}</td>
                                    <td>{ele?.quantity}</td>
                                    <td>{ele?.unitId}</td>
                                    <td>{ele?.price}</td>
                                    <td>{ele?.total}</td>
                                  </tr>
                                </>
                              );
                            })
                          ) : (
                            <>
                              <NoDataFound />
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-lg-4 ml-auto">
                        <div className="GstTable">
                          <table>
                            <tbody>
                              <tr>
                                <td>Profile</td>
                                <td>
                                  ${" "}
                                  {quoteDetailsData?.TaxProfile?.name ?? "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>Subtotal</td>
                                <td>$ {quoteDetailsData?.subtotal}</td>
                              </tr>
                              <tr>
                                <td>
                                  {" "}
                                  GST(
                                  {Number(
                                    quoteDetailsData?.TaxProfile?.taxRows?.[0]
                                      ?.taxPercent
                                  ) || 0}
                                  % )
                                </td>
                                <td>
                                  ${" "}
                                  {((Number(quoteDetailsData?.subtotal) || 0) *
                                    (Number(
                                      quoteDetailsData?.TaxProfile?.taxRows?.[0]
                                        ?.taxPercent
                                    ) || 0)) /
                                    100}
                                </td>
                              </tr>
                              <tr>
                                <td>Grand Total</td>
                                <td>
                                  {" "}
                                  $
                                  {(Number(quoteDetailsData?.subtotal) || 0) +
                                    ((Number(quoteDetailsData?.subtotal) || 0) *
                                      (Number(
                                        quoteDetailsData?.TaxProfile
                                          ?.taxRows?.[0]?.taxPercent
                                      ) || 0)) /
                                      100}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12">
                      <div className="mb-4">
                        <div className="Heading">
                          <h6>Attachments</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="CommonModalArea">
                        <div className="form-group">
                          <h5>Scope of Work</h5>
                          <div className="CommonModalBox">
                            <h5 className="FolderPermissonId CursorPointer">
                              <figure>
                                <img src="images/scopeofwork.png" />
                              </figure>{" "}
                              Scope of Work
                            </h5>
                            &nbsp; <span className="Count">3</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="CommonModalArea">
                        <div className="form-group">
                          <h5>Construction Documents</h5>
                          <div className="CommonModalBox">
                            <h5 className="FolderPermissonId CursorPointer">
                              <figure>
                                <img src="images/construction_documents.png" />
                              </figure>{" "}
                              Construction Documents
                            </h5>
                            &nbsp; <span className="Count">3</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="CommonModalArea">
                        <div className="form-group">
                          <h5>External Quote</h5>
                          <div className="CommonModalBox">
                            <h5 className="FolderPermissonId CursorPointer">
                              <figure>
                                <img src="images/extermal_quote.png" />
                              </figure>{" "}
                              External Quote
                            </h5>
                            &nbsp; <span className="Count">3</span>
                          </div>
                        </div>
                      </div>
                    </div> */}
                </div>
                <div className="Button d-flex justify-content-between mt-4 ">
                  <button
                    className="Create"
                    onClick={(e) =>
                      changeFieldPurchaseOrderStatusFunc(
                        e,
                        quoteDetailsData?.id,
                        "RESEND"
                      )
                    }
                  >
                    Resend QR
                  </button>
                  <button
                    className="Create"
                    onClick={(e) =>
                      changeFieldPurchaseOrderStatusFunc(
                        e,
                        quoteDetailsData?.id,
                        "RETURNED"
                      )
                    }
                  >
                    Import a Return Quote
                  </button>
                  <button
                    className="Create"
                    data-toggle="modal"
                    data-target="#GlobalBiddingBiddingModal3"
                    data-dismiss="modal"
                  >
                    Print QR
                  </button>
                  <button
                    className="Create"
                    onClick={hideFieldPurchaseOrderDetailsModal}
                  >
                    Close
                  </button>
                </div>

                <div class="DiscussionArea">
                  <div class="DiscussionTitle">
                    <h4>
                      Discussion <span>{discussionList?.length ?? 0}</span>
                    </h4>
                    <div class="DiscussionChat">
                      <img
                        src={require("../assets/images/down.png")}
                        class="DiscussionIcon"
                      />
                      <a>
                        <img src={require("../assets/images/pdf2.png")} />
                      </a>
                      <a onClick={() => handleDiscussionSettingsModalShow()}>
                        <img src="images/setting.png" />
                      </a>
                    </div>
                  </div>
                  <div class="DiscussionHideArea" style={{ display: "block" }}>
                    <div class="DiscussionBox">
                      <span>FM</span>
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          value={discussionState?.message}
                          onChange={(e) =>
                            updateDiscussionState({
                              ...discussionState,
                              message: e.target.value,
                            })
                          }
                          placeholder="Add a Comment ....."
                        />
                        {discussionState.discussionError &&
                          !discussionState.message && (
                            <p style={{ color: "red" }}>
                              {discussionState.discussionError}
                            </p>
                          )}

                        <div class="DiscussionOverlay">
                          <a
                            onClick={() =>
                              updateDiscussionState({
                                ...discussionState,
                                message: "",
                              })
                            }
                            class="CancelChat"
                          >
                            <img
                              src={require("../assets/images/plus-cancel1.png")}
                            />
                          </a>
                          <a onClick={handleAddDiscussion} class="SendChat">
                            <img
                              src={require("../assets/images/forwardbutton.png")}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    {discussionList?.map((item, i) => (
                      <>
                        <div class="DiscussionBox">
                          <span>CO</span>
                          <div class="DiscussionRight">
                            <h6>{item?.message}</h6>
                            <small>
                              {moment(item.updatedAt).format("lll")}
                            </small>
                          </div>
                        </div>
                        <div class="ReplyArea">
                          <a
                            onClick={(e) => {
                              updateReplyField({ flag: true, index: i });
                              updateReplyState({
                                ...replyState,
                                message: "",
                              });
                            }}
                          >
                            Reply
                          </a>
                          {item?.ReplyDiscussions?.map((ele) => (
                            <div class="DiscussionBox">
                              <span>RE</span>
                              <div class="DiscussionRight">
                                <h6>{ele?.message}</h6>
                                <small>
                                  {moment(ele.updatedAt).format("lll")}
                                </small>
                              </div>
                            </div>
                          ))}

                          {replyField.index == i && (
                            <div class="DiscussionBox w-100">
                              <span>FM{item?.id}</span>
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={replyState?.message}
                                  onChange={(e) =>
                                    updateReplyState({
                                      ...replyState,
                                      message: e.target.value,
                                      messageId: item?.id,
                                      jobId: item?.jobId,
                                    })
                                  }
                                  placeholder="Add reply ....."
                                />
                                {discussionState.discussionError &&
                                  !discussionState.message && (
                                    <p style={{ color: "red" }}>
                                      {discussionState.discussionError}
                                    </p>
                                  )}

                                <div class="DiscussionOverlay">
                                  <a
                                    onClick={() =>
                                      updateReplyState({
                                        ...replyState,
                                        message: "",
                                      })
                                    }
                                    class="CancelChat"
                                  >
                                    <img
                                      src={require("../assets/images/plus-cancel1.png")}
                                    />
                                  </a>
                                  <a onClick={handleAddReply} class="SendChat">
                                    <img
                                      src={require("../assets/images/forwardbutton.png")}
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={supplierCreditOrderDetailsModal}
        onHide={hideSupplierCreditDetailsModal}
        className="LargeModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideSupplierCreditDetailsModal}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox ModalTitleBoxRed">
              <h4>View Supplier Credit Request</h4>
            </div>
            <div className="FolderForm">
              <div className="BlueTextArea RedTextArea">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="Heading">
                      <h3>
                        Supplier Credit # {quoteDetailsData?.documentCode}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="form-group col-lg-3">
                    <h6>Reason</h6>
                    <p>{quoteDetailsData?.Reason?.description}</p>
                  </div>
                  <div className="form-group col-lg-3">
                    <h6>Sent By</h6>
                    <p>{quoteDetailsData?.sendBy}</p>
                  </div>
                  <div className="form-group col-lg-3">
                    <div className="CommonModalArea">
                      <h5 className="RedText">History</h5>

                      <div className="CommonModalBox">
                        <h5 className="FolderPermission" data-dismiss="modal">
                          <figure>
                            <p>{quoteDetailsData?.createdAt?.split("T")[0]}</p>
                            {/* <img src="images/timer.png" /> */}
                          </figure>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5 className="RedText">Vendor</h5>
                        <div className="CommonModalBox">
                          <figure className="FolderPermissionId CursorPointer">
                            <img src="images/vendor.png" />
                          </figure>
                          <h5 className="text-black">
                            {" "}
                            <p>{quoteDetailsData?.Vendor?.vendorName}</p>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5 className="RedText">Date Sent</h5>
                        <div className="CommonModalBox">
                          <h5 className="text-black">
                            {quoteDetailsData?.date?.split("T")[0]}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5 className="RedText">Reminder</h5>
                        <div className="CommonModalBox">
                          <figure className="FolderPermissionId CursorPointer">
                            <img src="images/timer.png" />
                          </figure>
                          <h5>
                            {" "}
                            <p>{quoteDetailsData?.reminder?.split("T")[0]}</p>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <h6>Time since last status update</h6>
                      <p className="ml-3">
                        {" "}
                        <p>{quoteDetailsData?.updatedAt?.split("T")[0]}</p>
                      </p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ShippingAddressArea">
                      <div className="ShippingAddressAreaBox">
                        <h6>Mailing Address</h6>
                        <p>
                          {" "}
                          <p>
                            {quoteDetailsData?.Vendor?.addressLine1} <br />
                            {quoteDetailsData?.Vendor?.addressLine2}
                          </p>
                        </p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Email</h6>
                        <p>{quoteDetailsData?.Vendor?.email}</p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Phone</h6>
                        <p>{quoteDetailsData?.Vendor?.phone}</p>
                      </div>
                      {/* <div className="ShippingAddressAreaBox">
                <h6>Shipping Address</h6>
                <p>5384 Peach Rd Chilliwack, BC V2R0Z6</p>
              </div> */}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5 className="RedText">Back Charge Vendor</h5>
                        <div className="CommonModalBox">
                          <h5 className="text-black">
                            {quoteDetailsData?.Vendor?.vendorName ?? "N/A"}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5 className="RedText">
                          Back Charge Notification Status
                        </h5>
                        <div className="CommonModalBox">
                          <h5 className="text-black">
                            <figure
                              className="CursorPointer FolderPermissionId"
                              onClick={() =>
                                handleBackChargeNotificationListModalShow(
                                  quoteDetailsData?.BackCharge,
                                  "scDetail"
                                )
                              }
                            >
                              <img src="images/notification_accept.png" />
                            </figure>
                            {`${
                              quoteDetailsData?.BackCharge
                                ?.BackChargeNotifications?.[0]?.activity ?? ""
                            } -${moment(
                              quoteDetailsData?.BackCharge
                                ?.BackChargeNotifications?.updatedAt
                            ).format("YYYY-MM-DD & hh:mm a")}`}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <h6>Messsage</h6>
                      <p className="text-grey-80">
                        {quoteDetailsData?.message}
                      </p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ItemDetails">
                      <h6>Item Details</h6>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="Tableheader mb-3">
                      <h6 className="mb-0"> Item</h6>
                      <div className="Actions">
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <DownloadTableExcel
                          filename="Item List"
                          sheet="Item List"
                          currentTableRef={quoteDetailsItemRef.current}
                        >
                          <a href="javascript:void(0);">
                            <img src="images/download.png" />
                          </a>
                        </DownloadTableExcel>
                        <a href="javascript:void(0);">
                          <img src="images/setting.png" />
                        </a>
                      </div>
                    </div>
                    <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                      <table ref={quoteDetailsItemRef}>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>
                              <div className="text-center">
                                Material Description
                                <br />
                                (i.e: Modal/Type/Style/manuf part#/Dimensions,
                                etc.)
                              </div>
                            </th>
                            <th>Color</th>
                            <th>Quantity</th>
                            <th>Units</th>
                            <th>Price/Unit</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {checkArray(quoteDetailsData?.Items) ? (
                            quoteDetailsData?.Items?.map((ele) => {
                              return (
                                <>
                                  <tr>
                                    <td>{ele?.id}</td>
                                    <td>{ele?.name}</td>
                                    <td>{ele?.color}</td>
                                    <td>{ele?.quantity}</td>
                                    <td>{ele?.unitId}</td>
                                    <td>{ele?.price}</td>
                                    <td>{ele?.total}</td>
                                  </tr>
                                </>
                              );
                            })
                          ) : (
                            <>
                              <NoDataFound />
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-lg-4 ml-auto">
                        <div className="GstTable">
                          <table>
                            <tbody>
                              <tr>
                                <td>Profile</td>
                                <td>
                                  ${" "}
                                  {quoteDetailsData?.TaxProfile?.name ?? "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>Subtotal</td>
                                <td>$ {quoteDetailsData?.subtotal}</td>
                              </tr>
                              <tr>
                                <td>
                                  {" "}
                                  GST(
                                  {Number(
                                    quoteDetailsData?.TaxProfile?.taxRows?.[0]
                                      ?.taxPercent
                                  ) || 0}
                                  % )
                                </td>
                                <td>
                                  ${" "}
                                  {((Number(quoteDetailsData?.subtotal) || 0) *
                                    (Number(
                                      quoteDetailsData?.TaxProfile?.taxRows?.[0]
                                        ?.taxPercent
                                    ) || 0)) /
                                    100}
                                </td>
                              </tr>
                              <tr>
                                <td>Grand Total</td>
                                <td>
                                  {" "}
                                  $
                                  {(Number(quoteDetailsData?.subtotal) || 0) +
                                    ((Number(quoteDetailsData?.subtotal) || 0) *
                                      (Number(
                                        quoteDetailsData?.TaxProfile
                                          ?.taxRows?.[0]?.taxPercent
                                      ) || 0)) /
                                      100}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between mt-4 ">
                  <button
                    className="Create"
                    onClick={(e) =>
                      changeSupplierCreditStatusFunc(
                        e,
                        quoteDetailsData?.id,
                        "RESEND"
                      )
                    }
                  >
                    Resend QR
                  </button>
                  <button
                    className="Create"
                    onClick={(e) =>
                      changeSupplierCreditStatusFunc(
                        e,
                        quoteDetailsData?.id,
                        "RETURNED"
                      )
                    }
                  >
                    Import a Return Quote
                  </button>
                  <button
                    className="Create"
                    data-toggle="modal"
                    data-target="#GlobalBiddingBiddingModal3"
                    data-dismiss="modal"
                  >
                    Print QR
                  </button>
                  <button
                    className="Create"
                    onClick={hideSupplierCreditDetailsModal}
                  >
                    Close
                  </button>
                </div>
                <div class="DiscussionArea">
                  <div class="DiscussionTitle">
                    <h4>
                      Discussion <span>{discussionList?.length ?? 0}</span>
                    </h4>
                    <div class="DiscussionChat">
                      <img
                        src={require("../assets/images/down.png")}
                        class="DiscussionIcon"
                      />
                      <a>
                        <img src={require("../assets/images/pdf2.png")} />
                      </a>
                      <a onClick={() => handleDiscussionSettingsModalShow()}>
                        <img src="images/setting.png" />
                      </a>
                    </div>
                  </div>
                  <div class="DiscussionHideArea" style={{ display: "block" }}>
                    <div class="DiscussionBox">
                      <span>FM</span>
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          value={discussionState?.message}
                          onChange={(e) =>
                            updateDiscussionState({
                              ...discussionState,
                              message: e.target.value,
                            })
                          }
                          placeholder="Add a Comment ....."
                        />
                        {discussionState.discussionError &&
                          !discussionState.message && (
                            <p style={{ color: "red" }}>
                              {discussionState.discussionError}
                            </p>
                          )}

                        <div class="DiscussionOverlay">
                          <a
                            onClick={() =>
                              updateDiscussionState({
                                ...discussionState,
                                message: "",
                              })
                            }
                            class="CancelChat"
                          >
                            <img
                              src={require("../assets/images/plus-cancel1.png")}
                            />
                          </a>
                          <a onClick={handleAddDiscussion} class="SendChat">
                            <img
                              src={require("../assets/images/forwardbutton.png")}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    {discussionList?.map((item, i) => (
                      <>
                        <div class="DiscussionBox">
                          <span>CO</span>
                          <div class="DiscussionRight">
                            <h6>{item?.message}</h6>
                            <small>
                              {moment(item.updatedAt).format("lll")}
                            </small>
                          </div>
                        </div>
                        <div class="ReplyArea">
                          <a
                            onClick={(e) => {
                              updateReplyField({ flag: true, index: i });
                              updateReplyState({
                                ...replyState,
                                message: "",
                              });
                            }}
                          >
                            Reply
                          </a>
                          {item?.ReplyDiscussions?.map((ele) => (
                            <div class="DiscussionBox">
                              <span>RE</span>
                              <div class="DiscussionRight">
                                <h6>{ele?.message}</h6>
                                <small>
                                  {moment(ele.updatedAt).format("lll")}
                                </small>
                              </div>
                            </div>
                          ))}

                          {replyField.index == i && (
                            <div class="DiscussionBox w-100">
                              <span>FM{item?.id}</span>
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={replyState?.message}
                                  onChange={(e) =>
                                    updateReplyState({
                                      ...replyState,
                                      message: e.target.value,
                                      messageId: item?.id,
                                      jobId: item?.jobId,
                                    })
                                  }
                                  placeholder="Add reply ....."
                                />
                                {discussionState.discussionError &&
                                  !discussionState.message && (
                                    <p style={{ color: "red" }}>
                                      {discussionState.discussionError}
                                    </p>
                                  )}

                                <div class="DiscussionOverlay">
                                  <a
                                    onClick={() =>
                                      updateReplyState({
                                        ...replyState,
                                        message: "",
                                      })
                                    }
                                    class="CancelChat"
                                  >
                                    <img
                                      src={require("../assets/images/plus-cancel1.png")}
                                    />
                                  </a>
                                  <a onClick={handleAddReply} class="SendChat">
                                    <img
                                      src={require("../assets/images/forwardbutton.png")}
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={budgetChangeOrderListingModal}
        onHide={hideBudgetChangeOrderListingModal}
        className="LargeModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideBudgetChangeOrderListingModal}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Budget Change Orders</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="ModalBigheading">
                  <h3>
                    <span>Project:</span>
                    {state?.projectName ?? "N/A"}
                  </h3>
                  <h3>
                    <span> Cost Code: </span>
                    {/* {`${costCodeStatic} ${" "} (${costCodeStaticName})`} */}
                    {costCodes ?? "N/A"}
                  </h3>
                  <h3>
                    <span>Job:</span> {state?.jobNumber ?? "N/A"}
                  </h3>
                </div>
                <div className="BillsRowBorder d-flex flex-lg-row flex-column align-items-lg-center align-items-start">
                  <h6 className="d-flex align-items-center mb-2">
                    <img src="images/bills_black.png" className="mr-3" />
                    Budget Change Orders
                  </h6>
                  <div className="d-flex flex-lg-row flex-column align-items-lg-center align-items-start">
                    <div className="TodosButtons mt-0">
                      <a
                        href="javascript:void(0)"
                        className="FolderPermissionId"
                        onClick={() => showBudgetChangeOrderModal()}
                      >
                        <img src="images/create _new_budget_change_order.png" />
                        Create New Budget Change Order
                      </a>
                    </div>
                    <div className="Actions">
                      <a href="javascript:void(0);">
                        <img src="images/icon-64.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/pdf.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/download.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/setting.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="ModalBigheading mt-4">
                  <h3>
                    <span>Budget Change Orders</span>
                  </h3>
                </div>

                <div className="TableList CommonHeading TableListMaxHeight">
                  `
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Sent By</th>
                        <th>Builder Signing Authority</th>
                        <th>Investor</th>
                        {/* <th>Sent To</th> */}
                        <th>Description</th>
                        <th>Current Working Budget</th>
                        <th>Remaining Budget</th>
                        <th>Budget Increase Amount</th>
                        <th>Reason</th>
                        <th>Date Sent</th>
                        <th>Related Document</th>
                        <th>Supporting Documents</th>
                        <th>Builder Signature Status</th>
                        <th>Investor Signature Status</th>
                        <th>Reminder</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {checkArray(modalBudgetChangeListData) &&
                        modalBudgetChangeListData?.map((ele, i) => {
                          return (
                            <>
                              <tr>
                                <td>{ele?.id}</td>
                                <td>{ele?.issuedBy?.name}</td>
                                <td>{ele?.builderSignee?.name}</td>
                                <td>{ele?.InvesterfullName}</td>
                                {/* <td>{ele?.InvesterfullName}</td> */}
                                <td>{ele?.documentDescription}</td>
                                <td>{ele?.JobCost?.workingBudget}</td>
                                <td>{ele?.JobCost?.remainingBudget}</td>
                                <td>{ele?.increaseAmount}</td>
                                <td>Send</td>
                                <td>{ele?.createdAt?.split("T"[0])}</td>
                                <td>
                                  <div className="TextLightSkyBlue">
                                    CHI-2B-251-13-304
                                  </div>
                                </td>
                                <td>
                                  <div className="Actions">
                                    <a href="javascript:void(0);">
                                      <img src="images/icon-63.png" />
                                    </a>
                                    <a href="javascript:void(0)">
                                      <span className="Count">3</span>
                                    </a>
                                  </div>
                                </td>
                                <td>
                                  <div className="TextOrange">
                                    {" "}
                                    {ele?.builderSignee?.userStatus}
                                  </div>
                                </td>
                                <td>
                                  investorSignStatus
                                  <div className="TextOrange">
                                    {" "}
                                    {ele?.investorSignStatus}
                                  </div>
                                </td>
                                <td>
                                  <a href="javascript:void(0);">
                                    <img
                                      src="images/timer.png"
                                      className="mr-2"
                                    />
                                  </a>
                                  30 days (including)
                                </td>
                                <td>
                                  <div className="TableThreeDots">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol
                                          className="dropdown-menu ChangeOrderDropdown4"
                                          style={{}}
                                        >
                                          <li>
                                            <a
                                              href="javascript:void(0);"
                                              className="FolderPermissionId"
                                              onClick={() =>
                                                showBudgetChangeOrderDetailsModal(
                                                  ele?.id
                                                )
                                              }
                                            >
                                              <img src="images/view-po.png" />
                                              View Budget Change Order
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href="javascript:void(0);"
                                              className="FolderPermissionId"
                                              onClick={() =>
                                                showUpdateBudgetChangeOrderModal(
                                                  ele?.id,
                                                  ele?.jobCost?.jobId,
                                                  ele?.jobCost?.jobType
                                                )
                                              }
                                            >
                                              <img src="images/Action-1.png" />
                                              Edit Budget Change Order
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                changeBudgetStatusFunc(
                                                  ele?.id,
                                                  "CANCELLED"
                                                )
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/cancel_budget.png" />
                                              Cancel Budget Change Order
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                changeBudgetStatusFunc(
                                                  ele?.id,
                                                  "START-ESIGN"
                                                )
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/send_budget.png" />
                                              Send Budget Change Order for
                                              E-sign
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href="javascript:void(0);"
                                              className="FolderPermissionId"
                                              onClick={() =>
                                                showAgreementDetailsModal(
                                                  ele?.id
                                                )
                                              }
                                            >
                                              <img src="images/view_budget.png" />
                                              View Budget Change Order Agreement
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/download.png" />
                                              Download Hardcopy Budget Change
                                              Order Agreement
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href="javascript:void(0);"
                                              className="FolderPermissionId"
                                              onClick={() =>
                                                showUpdateBudgetChangeDocsModal(
                                                  ele?.id
                                                )
                                              }
                                            >
                                              <img src="images/upload_signed_agreement.png" />
                                              Upload Signed Budget Change Order
                                              Agreement
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                changeBudgetStatusFunc(
                                                  ele?.id,
                                                  "CANCEL-ESIGN"
                                                )
                                              }
                                              href="javascript:void(0)"
                                            >
                                              <img src="images/cancel_signature.png" />
                                              Cancel Signature
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </div>

                <div className="Button mt-3 text-center">
                  <button
                    className="Outline"
                    data-toggle="modal"
                    data-target="#BiddingModal"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={scopeWorkIdModal}
        onHide={hideScopeWorkIdModal}
        className="SmallModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideScopeWorkIdModal}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>
                {" "}
                {secondaryState == "construction"
                  ? " Select Construction Documents"
                  : "Select Scopes of Work"}
              </h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>
                  {secondaryState
                    ? "Construction Document"
                    : "Scope of Work Document"}
                </h6>
                <div className="Categories Categories1">
                  <div className="Categories_all Categories_all1">
                    <select
                      name="supportDocumentId"
                      value={supportDocumentId}
                      onChange={handleInput}
                    >
                      <option>Select File</option>
                      {checkArray(
                        secondaryState === "construction"
                          ? getReleadesConstList?.data
                          : scopeWorkIdListData?.data
                      ) ? (
                        (secondaryState === "construction"
                          ? getReleadesConstList?.data
                          : scopeWorkIdListData?.data
                        ).map((ele, i) => (
                          <option key={i} value={ele?.id}>
                            {ele?.fileName}
                          </option>
                        ))
                      ) : (
                        <option disabled>No Data Available</option>
                      )}
                    </select>
                    <img src="images/down.png" alt="Dropdown Icon" />
                  </div>
                </div>
              </div>

              <div className="Button mb-4">
                <button onClick={saveEstimationDocsFunc} className="Create">
                  Add
                </button>
              </div>

              <div className="form-group mb-2">
                <h6>Selected Documents</h6>
                {checkArray(estimationDocumentListData?.data) &&
                  estimationDocumentListData?.data?.map((ele, i) => (
                    <div key={i} className="FilesAdded">
                      <label>{ele.fileName}</label>
                      <span className="ml-5">
                        <img src="images/view-po.png" alt="View Icon" />
                      </span>
                      <span
                        className="cross"
                        onClick={(e) => removeEstimationDocsFunc(e, ele?.id)}
                      >
                        <img src="images/Cross.png" alt="Remove Icon" />
                      </span>
                    </div>
                  ))}
              </div>

              <div className="Button d-flex justify-content-between mt-5">
                <button className="Create" onClick={hideScopeWorkIdModal}>
                  Save
                </button>
                <button className="Outline" onClick={hideScopeWorkIdModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={requestLimitIncresaseModal}
        onHide={hideRequestLimitIncresaseModal}
        className="SmallModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideRequestLimitIncresaseModal}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Request Limit Increase</h4>
            </div>
            <div className="FolderForm">
              {/* <div className="ModalMediumheading">
                <h3 style={{ marginBottom: "10px!important" }}>
                  <span>User: </span>John Doe
                </h3>
                <h3 style={{ marginBottom: "10px!important" }}>
                  <span>Role Limit : </span>$10,000.00
                </h3>
                <h3>
                  <span>User Limit : </span>$12,000.00
                </h3>
              </div> */}
              <div className="form-group">
                <h6>Increase Amount</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert Increase amount $"
                  name="requestIncreaseAmount"
                  value={requestIncreaseAmount}
                  onChange={handleInput}
                />
              </div>
              <div className="form-group">
                <h6>Reason</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert reason for limit increase"
                  name="increaseReason"
                  value={increaseReason}
                  onChange={handleInput}
                />
              </div>
              <div className="form-group">
                <h6>Request Approver</h6>
                <div className="Categories Categories1">
                  <div className="Categories_all Categories_all1">
                    <select
                      name="approverUserId"
                      value={approverUserId}
                      onChange={handleInput}
                      // disabled={modalState === true}
                    >
                      <option>Select Staff </option>
                      {checkArray(staffListData?.staff) ? (
                        <>
                          {staffListData?.staff?.map((ele, i) => {
                            return (
                              <option value={ele?.id}>{ele?.email}</option>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </select>
                    <img src="images/down.png" />
                  </div>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                         {budgetChangeError?.reasonIdError}
                       </span> */}
                </div>
              </div>
              <div className="Button mt-3 d-flex justify-content-between mt-5">
                <button className="Create" onClick={increaseRequestLimitFunc}>
                  Request Limit Increase
                </button>
                <button
                  className="Outline"
                  onClick={hideRequestLimitIncresaseModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={budgetChangeOrderDetailsModal}
        onHide={hideBudgetChangeOrderDetailsModal}
        className="MediumModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideBudgetChangeOrderDetailsModal}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Budget Change Order Details</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="ModalBigheading">
                  <h3>
                    <span>Budget Change Order # 1</span>
                  </h3>
                  <h3>
                    <span>Project:</span>
                    {state?.projectName ?? "N/A"}
                  </h3>
                  <h3>
                    <span> Cost Code: </span>
                    {/* {`${costCodeStatic} ${" "} (${costCodeStaticName})`} */}
                    {costCodes ?? "N/A"}
                  </h3>

                  <h3>
                    <span>Job:</span>{" "}
                    {budgetChangeOrderDetailsData?.job?.jobType} (
                    {budgetChangeOrderDetailsData?.job?.jobNumber})
                  </h3>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="form-group col-lg-8 mt-3">
                        <h6>Document Description</h6>
                        <p className="ml-0">
                          {budgetChangeOrderDetailsData?.documentDescription}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-lg-5 mt-3">
                        <div className="Categories Categories1">
                          <h6>Reason</h6>
                          <p className="ml-0">
                            {" "}
                            {budgetChangeOrderDetailsData?.Reason}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="ModalBigheading mt-3">
                          <h3>
                            <span>Current Working Budget:</span>{" "}
                            {budgetChangeOrderDetailsData?.increaseAmount}
                          </h3>
                        </div>
                        <div className="form-group mt-4">
                          <h6>Budget Increase Amount</h6>
                          <p className="ml-0">
                            {" "}
                            {budgetChangeOrderDetailsData?.increaseAmount}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="ModalBigheading mt-3">
                      <h3>
                        <span>Remaining Budget:</span>{" "}
                        {budgetChangeOrderDetailsData?.increaseAmount}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="form-group">
                          <div className="Categories Categories1">
                            <h6>Related Document</h6>
                            <p className="ml-0">
                              {budgetChangeOrderDetailsData?.relatedDocumentId}
                            </p>
                          </div>
                        </div>
                        {/* <div className="form-group">
                <div className="CommonModalArea mt-3">
                  <h5>Supporting Document</h5>
                  <div className="CommonModalBox">
                    <h5>
                      <figure>
                        <img src="images/icon-63.png" />
                      </figure>{" "}
                      <span className="Count">3</span> Supporting Document
                    </h5>
                  </div>
                </div>
              </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Reminder</h5>
                        <div className="CommonModalBox">
                          <h5>
                            <figure>
                              <img src="images/timer.png" />
                            </figure>{" "}
                            5 Days
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="form-group">
                          <div className="Categories Categories1 mt-4">
                            <h6>Builder Signee</h6>
                            <p className="ml-0">
                              {" "}
                              {
                                budgetChangeOrderDetailsData?.builderSignee
                                  ?.name
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex justify-content-between align-items-start">
                      <div className="form-group">
                        <div className="CommonModalArea">
                          <h5>Signature Status</h5>
                          <div className="CommonModalBox">
                            <h5>
                              {" "}
                              {budgetChangeOrderDetailsData?.builderSignStatus}
                            </h5>
                          </div>
                        </div>
                      </div>
                      {/* <button
              className="SignDocumentBtn"
              data-toggle="modal"
              data-target="#SignBudgetChangeOrderAgreement"
              data-dismiss="modal"
            >
              Sign Document
            </button> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="ModalBigheading mt-4">
                      <h3>
                        <span>Investor Signee</span>
                      </h3>
                      <h3>
                        <span>Company:</span>{" "}
                        {budgetChangeOrderDetailsData?.Vendor?.companyName}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group mt-4">
                      <h6>Full Name</h6>
                      <p className="ml-0">
                        {budgetChangeOrderDetailsData?.Vendor?.vendorName}
                      </p>
                    </div>
                    <div className="form-group mt-4">
                      <h6>Email</h6>
                      <p className="ml-0">
                        {budgetChangeOrderDetailsData?.Vendor?.email}
                      </p>
                    </div>
                    {/* <div className="form-group">
            <div className="CommonModalArea">
              <h5>Budget Change Order Agreement</h5>
              <div className="CommonModalBox">
                <figure
                  className="CursorPointer"
                  data-toggle="modal"
                  data-target="#ViewBudgetChangeOrderAgreementHistory"
                  data-dismiss="modal"
                >
                  <img src="images/view_budget.png" />
                </figure>
                &nbsp;
                <h5>
                  <span className="Count">3</span> Budget Change Order Agreement
                </h5>
              </div>
            </div>
          </div> */}
                    <div className="form-group mb-3">
                      <h6>Authorization Type</h6>
                      <label className="Radio">
                        Electronic
                        <input
                          type="radio"
                          checked={
                            budgetChangeOrderDetailsData?.authorisationType ===
                            "Electronic"
                              ? true
                              : false
                          }
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="Radio">
                        Hard copy
                        <input
                          type="radio"
                          checked={
                            budgetChangeOrderDetailsData?.authorisationType ===
                            "Hardcopy"
                              ? true
                              : false
                          }
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="text RadioText">
                      {/* <div class="form-group">
                  <h6>Message (Max 1000 chars limit)</h6>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Write here ....."
                  />
                  <small class="form-text text-right"
                    >985 characters remaining</small
                  >
                </div> */}
                    </div>
                    {/* <div className="file RadioText">
            <div className="form-group">
              <div className="CommonModalArea">
                <h5>Download Hardcopy Budget Change Order</h5>
                <div className="CommonModalBox">
                  <h5>
                    <figure>
                      <img src="images/view_budget.png" />
                    </figure>{" "}
                    <span className="Count">3</span> Download Hardcopy Budget
                    Change Order
                  </h5>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="CommonModalArea">
                <h5>Budget Change Order Agreement</h5>
                <div className="CommonModalBox">
                  <h5>
                    <figure>
                      <img src="images/upload_signed_agreement.png" />
                    </figure>{" "}
                    <span className="Count">3</span> Budget Change Order
                    Agreement
                  </h5>
                </div>
              </div>
            </div>
          </div> */}
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <div className="CommonModalArea mt-4">
                        <h5>Signature Status</h5>
                        <div className="CommonModalBox">
                          <h5>
                            {" "}
                            {budgetChangeOrderDetailsData?.investorSignStatus}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button mt-3 d-flex justify-content-between">
                  <button
                    className="Create"
                    onClick={hideBudgetChangeOrderDetailsModal}
                  >
                    Print
                  </button>
                  {/* <button className="Create" >
          Cancel Signature
        </button> */}
                  <button
                    className="Outline"
                    onClick={hideBudgetChangeOrderDetailsModal}
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={updateBudgetChangeOrderModal}
        onHide={hideUpdateBudgetChangeOrderModal}
        className="MediumModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideUpdateBudgetChangeOrderModal}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>update New Budget Change Order</h4>
            </div>
            <div className="FolderForm">
              <div className="ModalBigheading">
                <h3>
                  <span>Budget Change Order # 1</span>
                </h3>
                <h3>
                  <span>Project:</span> Rivers Edge Homes
                </h3>
                <h3>
                  <span>Cost Code:</span> 30-0102 - Framing - Labor
                </h3>
                <h3>
                  <span>Job:</span> Rivers Edge Unit 366(RIV-2B-366)
                </h3>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="form-group col-lg-8">
                      <h6>Document Description</h6>
                      <textarea
                        className="form-control"
                        rows={2}
                        placeholder="Insert Description"
                        name="documentDescription"
                        value={documentDescription}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {budgetChangeError?.documentDescriptionError}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-lg-9">
                      <div className="Categories Categories1">
                        <h6>Reason</h6>
                        <div className="Categories_all Categories_all1">
                          <select
                            name="reasonId"
                            value={reasonId}
                            onChange={handleInput}
                          >
                            <option>Select Reason</option>
                            {checkArray(reasonListData) ? (
                              <>
                                {reasonListData?.map((ele, i) => {
                                  return (
                                    <option value={ele?.id}>
                                      {ele?.description}
                                    </option>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </select>
                          <img src="images/down.png" />
                        </div>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {budgetChangeError?.reasonIdError}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-10">
                      <div className="ModalBigheading">
                        <h3>
                          <span>Current Working Budget:</span> $6,000.00
                        </h3>
                      </div>
                      <div className="form-group">
                        <h6>Budget Increase Amount</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert increase amount pre-tax"
                          name="increaseAmount"
                          value={increaseAmount}
                          onChange={handleInput}
                        />
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {budgetChangeError?.increaseAmountError}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="ModalBigheading">
                    <h3>
                      <span>Remaining Budget:</span> $0.00
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-10">
                      <div className="form-group">
                        <div className="Categories Categories1">
                          <h6>Related Document</h6>
                          <div className="Categories_all Categories_all1">
                            <select
                              name="relatedDocumentId"
                              value={relatedDocumentId}
                              onChange={handleInput}
                            >
                              <option>Select Related Docs</option>
                              {checkArray(
                                quoteRequestListData?.data?.length > 0
                              ) ? (
                                <>
                                  {quoteRequestListData?.data?.map((ele, i) => {
                                    return (
                                      <option value={ele?.id}>
                                        {ele?.documentCode}
                                      </option>
                                    );
                                  })}
                                </>
                              ) : (
                                <></>
                              )}
                            </select>
                          </div>
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {budgetChangeError?.relatedDocumentIdError}
                          </span>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="CommonModalArea">
                          <h5>Add Supporting Document</h5>
                          <div className="CommonModalBox">
                            <h5
                              className="CursorPointer FolderPermissionId"
                              onClick={() => showSupportingDocsModal()}
                            >
                              <figure>
                                <img src="images/icon-63.png" />
                              </figure>
                              Add Supporting Document
                            </h5>
                            <span style={{ color: "red", fontSize: "14px" }}>
                              {budgetChangeError?.supportingDocsError}
                            </span>
                            {/* <span
                                className="Count CursorPointer FolderPermissionId"
                                data-toggle="modal"
                                data-target="#SupportingDocumentListQuoteRequestPurchase"
                                data-dismiss="modal"
                              >
                                3
                              </span> */}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories1">
                          <h6>Builder Signee</h6>
                          <div className="Categories_all Categories_all1">
                            <select
                              name="builderSigneeId"
                              value={builderSigneeId}
                              onChange={handleInput}
                            >
                              <option>Select Builder</option>
                              {checkArray(builderSigneeListData) ? (
                                <>
                                  {builderSigneeListData?.map((ele, i) => {
                                    return (
                                      <option value={ele?.id}>
                                        {ele?.name}
                                      </option>
                                    );
                                  })}
                                </>
                              ) : (
                                <></>
                              )}
                            </select>
                          </div>
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {budgetChangeError?.builderSigneeIdError}
                          </span>
                        </div>
                      </div>
                      <div className="ModalBigheading">
                        <h3>
                          <span>Investor Signee</span>
                        </h3>
                        <h3>
                          {/* <span>Company:</span> */}
                          <div className="form-group">
                            <div className="Categories Categories1">
                              <h6>Investor</h6>
                              <div className="Categories_all Categories_all1">
                                <select
                                  name="vendorId"
                                  value={vendorId}
                                  onChange={handleInput}
                                >
                                  <option>Select Investor</option>
                                  {checkArray(vendorSearchData) &&
                                    vendorSearchData?.map((ele, i) => {
                                      return (
                                        <option value={ele?.id}>
                                          {ele.vendorName}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                              <span style={{ color: "red", fontSize: "14px" }}>
                                {budgetChangeError?.vendorIdError}
                              </span>
                            </div>
                          </div>
                        </h3>
                      </div>
                      <div className="form-group">
                        <h6>Full Name</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Jane Investor"
                          name="InvesterfullName"
                          value={InvesterfullName}
                          onChange={handleInput}
                          // disabled= {vendorId}
                        />
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {budgetChangeError?.vendorIdError}
                        </span>
                      </div>
                      <div className="form-group">
                        <h6>Email</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert email"
                          name="InvesterEmail"
                          value={InvesterEmail}
                          onChange={handleInput}
                          // disabled= {vendorId}
                        />
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {budgetChangeError?.vendorIdError}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="Button mb-4">
                    <button className="Create">
                      Generate /update Budget Change Order
                    </button>
                  </div>
                  {/* <div className="form-group">
                    <div className="CommonModalArea">
                      <h5>Budget Change Order Agreement</h5>
                      <div className="CommonModalBox">
                        <figure
                          className="CursorPointer FolderPermissionId"
                          data-toggle="modal"
                          data-target="#ViewBudgetChangeOrderAgreementLeft"
                          data-dismiss="modal"
                        >
                          <img src="images/view_budget.png" />
                        </figure>
                        &nbsp;
                        <h5>
                          <span className="Count">3</span> Budget Change Order
                          Agreement
                        </h5>
                      </div>
                    </div>
                  </div> */}
                  <div className="form-group mb-3">
                    <h6>Authorization Type</h6>
                    <label className="Radio">
                      Electronic
                      <input
                        type="radio"
                        name="authorisationType"
                        value="Electronic"
                        onChange={handleCheckbox}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="Radio">
                      Hardcopy
                      <input
                        type="radio"
                        name="authorisationType"
                        value="Hardcopy"
                        onChange={handleCheckbox}
                      />
                      <span className="checkmark" />
                    </label>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {budgetChangeError?.authorisationTypeError}
                    </span>
                  </div>

                  <div className="text RadioText">
                    {/* <div class="form-group">
                <h6>Message (Max 1000 chars limit)</h6>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Write here ....."
                />
                <small class="form-text text-right"
                  >985 characters remaining</small
                >
              </div> */}
                  </div>
                  {/* <div className="file RadioText">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Download Hardcopy Budget Change Order</h5>
                        <div className="CommonModalBox">
                          <h5>
                            <figure>
                              <img src="images/view_budget.png" />
                            </figure>{" "}
                            <span className="Count">3</span>
                            Download Hardcopy Budget Change Order
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Budget Change Order Agreement</h5>
                        <div className="CommonModalBox">
                          <h5>
                            <figure>
                              <img src="images/upload_signed_agreement.png" />
                            </figure>{" "}
                            <span className="Count">3</span> Budget Change Order
                            Agreement
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <div className="CommonModalArea">
                      <h5>Reminder</h5>
                      <div className="CommonModalBox">
                        <h5>
                          <figure
                            onClick={() =>
                              showAddRemianderModal("updatebudgetChange")
                            }
                          >
                            <img src="images/timer.png" />
                          </figure>{" "}
                          5 Days
                        </h5>
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {budgetChangeError?.reminderDateError}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button mt-3 d-flex justify-content-between">
                <button
                  className="Create"
                  onClick={updateBudgetChangeOrderFunc}
                >
                  Send Budget Change Order
                </button>
                <button
                  className="Create"
                  onClick={updateBudgetChangeOrderFunc}
                >
                  Save Draft
                </button>
                <button
                  className="Outline"
                  onClick={hideUpdateBudgetChangeOrderModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={estimationHistoryModal}
        onHide={hideEstimationHistoryModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideEstimationHistoryModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Estimation History</h4>
            </div>
            <div className="JobTitleArea mb-2">
              <div className="JobBox">
                <h6>
                  <span>Category : </span>
                  {requiredName}
                </h6>
              </div>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Modified By</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <>
                      <td>
                        {" "}
                        <Loader />
                      </td>
                    </>
                  ) : (
                    <>
                      {checkArray(estimationHistoryData) ? (
                        estimationHistoryData?.map((ele, ind) => {
                          return (
                            <>
                              <tr>
                                <td>
                                  {ele?.userDetails?.firstName ||
                                    ele?.userDetails?.lastName ||
                                    ele?.userDetails?.name ||
                                    "-"}
                                </td>
                                <td>
                                  {ele?.userDetails?.firstName ||
                                    ele?.userDetails?.lastName ||
                                    ele?.userDetails?.name ||
                                    "-"}
                                </td>
                                <td>{ele?.createdAt?.split("T")[0]}</td>
                                <td>{ele?.activity}</td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <td>
                          {" "}
                          <NoDataFound />
                        </td>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={changeStatusModal}
        onHide={hideChangeStatusModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0)"
            className="CloseModal ClosePermissionId"
            onClick={hideChangeStatusModal}
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Change Status</h4>
            </div>
            <div class="FolderForm">
              <form>
                <div class="form-group">
                  <h6> Estimation Status</h6>
                  <div class="CommonSelectBox w-100">
                    <select name="status" value={status} onChange={handleInput}>
                      <option selected>Select</option>
                      <option value="N/A">N/A</option>
                      <option value="ACCEPTED">Accepted</option>
                      {/* <option value="">N/A</option> */}
                    </select>
                  </div>
                </div>
                <div className="Button mt-3 d-flex justify-content-between">
                  <button
                    className="Create"
                    onClick={changeEstimationStatusFunc}
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={updateBudgetChangeDocsModal}
        onHide={hideUpdateBudgetChangeDocsModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0)"
            className="CloseModal ClosePermissionId"
            onClick={hideUpdateBudgetChangeDocsModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Upload Signed Budget Change Order Agreement</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="form-group">
                  <h6>Select Files</h6>
                  <div className="Upload">
                    <span>-Drag and Drop Files or click to select</span>
                    <input type="file" name="file" onChange={handleInputFile} />
                  </div>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button
                    className="Create"
                    onClick={uploadBudgetChangeOrderFunc}
                  >
                    Save
                  </button>
                  <button
                    className="Outline"
                    onClick={hideUpdateBudgetChangeDocsModal}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={agreementDetailsModal}
        onHide={hideAgreementDetailsModal}
        className="ModalBox MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0)"
            className="CloseModal ClosePermissionId"
            onClick={hideAgreementDetailsModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>View Budget Change Order Agreement</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="AgreementViewArea">
                  <div className="AgreementViewTop">
                    <a href="javscript:void(0)">
                      <img src="images/fm_builder_logo.png" />
                    </a>
                    <div className="AgreementViewTopRight">
                      <h3>FM Builder</h3>
                      <p>312 Weddenburn Rd SE</p>
                      <p>Calgary, AB CA, T2J 1J1</p>
                      <p>4034005933 www.fmbuilder.com fmbuilder@support.com</p>
                      <p>
                        “The bitterness of poor quality remains long after the
                        sweetness of meeting
                        <br />
                        the schedule has been forgotten”
                      </p>
                    </div>
                  </div>
                  <button className="RedArea">
                    Residential Real Estate Purchase Contract
                  </button>
                  <div className="ResidentialEstateArea">
                    <div className="ResidentialEstateLeft">
                      <h3>Document Number: #1</h3>
                      <p>
                        <label>Date:</label> <span>06/29/2023</span>
                      </p>
                      <p>
                        <label>Current Working Budget:</label>{" "}
                        <span>$6,000.00</span>
                      </p>
                      <p>
                        <label>Budget Increase Amount:</label>{" "}
                        <span>$500.00</span>
                      </p>
                      <p>
                        <label>Cost Code:</label>{" "}
                        <span>30-0102 - Framing - Labor</span>
                      </p>
                      <p>
                        <label>Description:</label>{" "}
                        <span>
                          Move basement framing lumber package to back of
                          building for sidewalk prep and pour
                        </span>
                      </p>
                    </div>
                    <div className="ResidentialEstateRight">
                      <p>
                        <label>Project:</label> <span>Rivers Edge Homes</span>
                      </p>
                      <p>
                        <label>Job:</label>{" "}
                        <span>Rivers Edge Unit 366(RIV-2B-366)</span>
                      </p>
                      <p>
                        <label>Remaining Budget:</label> <span>$0,00</span>
                      </p>
                      <p>
                        <label>Reason:</label> <span>Extra Labour</span>
                      </p>
                    </div>
                  </div>
                  <div className="AgreementViewTable Border BorderRadius">
                    <table>
                      <thead>
                        <tr className="border-bottom">
                          <th>#</th>
                          <th>
                            Material Description <br />
                            (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                          </th>
                          <th>Color</th>
                          <th>Quantity</th>
                          <th>Units</th>
                          <th>Price/Units</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>NNTK913 S Panasonic Convection Trim Kit</td>
                          <td />
                          <td>1</td>
                          <td>Each</td>
                          <td />
                          <td>$ 500.00</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="TableBottom2">
                    <h6>Prex-Tax Total</h6>
                    <h6>$4,000.00</h6>
                  </div>
                  <div className="ChangeOrderCreatArea">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="InputBox">
                              <div className="form-group">
                                <input type="text" />
                                <label>Date</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="InputBox">
                              <div className="form-group">
                                <input type="text" />
                                <label>Jill Manager</label>
                                <label>FM Builder</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="InputBox">
                              <div className="form-group">
                                <input type="text" />
                                <label>Date</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="InputBox">
                              <div className="form-group">
                                <input type="text" />
                                <label>Jane Investor</label>
                                <label>Investor 1</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button text-center">
                  <button
                    className="Outline"
                    onClick={hideAgreementDetailsModal}
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={budgetSummaryModal}
        onHide={hideBugetSummaryModal}
        className="ModalBox MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0)"
            className="CloseModal ClosePermissionId"
            onClick={hideBugetSummaryModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Budget Summary</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="ModalBigheading">
                  <h3>
                    <span>Job :</span>River Edge Unit 366 (RIV-2B-366)
                  </h3>
                </div>
                <div className="BudgetSummaryArea">
                  <div className="d-flex flex-lg-row flex-column  align-items-lg-center align-items-start justify-content-between mb-4">
                    <h6 className="mb-lg-0 mb-2">Budget Summary</h6>
                    <div className="d-flex flex-sm-row align-items-sm-center flex-column align-items-start">
                      <div className="FilesArea">
                        <div className="FilesHead mb-0">
                          <div className="FilesLeft">
                            <aside>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search"
                              />
                              <span>
                                <img src="images/search.png" />
                              </span>
                            </aside>
                          </div>
                        </div>
                      </div>
                      <div className="Actions ml-4">
                        <a href="javascript:void(0);" className="mr-2">
                          <img src="images/pdf.png" />
                        </a>
                        <a href="javascript:void(0);" className="mr-2">
                          <img src="images/download.png" />
                        </a>
                        <a href="javascript:void(0);" className="mr-2">
                          <img src="images/setting.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="BudgetSummaryTable">
                    <table>
                      <tbody>
                        <tr className="Green">
                          <td>Cost Category / Code</td>
                          <td>Cost Category / Code Description</td>
                          <td>Proforma Budget</td>
                          <td>Budget Change Order (CO)</td>
                          <td>Quote (QR) Total</td>
                          <td>Working Budget</td>
                          <td>Budget (PO)</td>
                          <td>Budget(FPO’s) Total</td>
                          <td>Pre-Tax P.O. Total</td>
                          <td>Variance</td>
                          <td>Status</td>
                          <td>Vendor</td>
                        </tr>
                        {loader ? (
                          <>
                            <td>
                              {" "}
                              <Loader />
                            </td>
                          </>
                        ) : (
                          <>
                            {checkArray(budgetListData?.curData) ? (
                              budgetListData?.curData?.map((ele, i) => {
                                return (
                                  <>
                                    <tr className="Blue">
                                      <td>{ele?.number}</td>
                                      <td> {ele?.name}</td>
                                      <td />
                                      <td />
                                      <td />
                                      <td>{ele?.workingBudget}</td>
                                      <td>{ele?.purchaseOrder}</td>
                                      <td>{ele?.fieldPurchaseOrder}</td>
                                      <td>{ele?.preTaxTotal}</td>
                                      <td>{ele?.variance}</td>
                                      <td>{ele?.allAreaCostCodeStatus}</td>
                                    </tr>
                                    {checkArray(ele?.jobCostCodes) &&
                                      ele?.jobCostCodes?.map((ele, i) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>{ele?.number}</td>
                                              <td>{ele?.name}</td>
                                              <td>
                                                <a
                                                  href="javascript:void(0);"
                                                  onClick={() =>
                                                    showEstimationHistoryModal(
                                                      ele?.JobCost?.id,
                                                      ele?.name
                                                    )
                                                  }
                                                >
                                                  <img src="images/create_category.png" />
                                                </a>
                                              </td>
                                              <td>{ele?.proformaBudget}</td>
                                              <td>{ele?.budgetChangeOrder}</td>
                                              <td>{ele?.quoteRequest}</td>
                                              <td>{ele?.workingBudget}</td>
                                              <td>{ele?.purchaseOrder}</td>
                                              <td>{ele?.fieldPurchaseOrder}</td>
                                              <td>{ele?.preTaxTotal}</td>

                                              <td>{ele?.variance}</td>
                                              <td>
                                                <div className="TextGreen">
                                                  {ele?.allAreaCostCodeStatus}
                                                </div>
                                              </td>
                                              <td>
                                                <div className="Action">
                                                  INT Insurance{" "}
                                                  <img
                                                    src="images/fill_star.png"
                                                    className="ml-2"
                                                  />
                                                </div>
                                              </td>
                                              <td>
                                                <a href="javascript:void(0);">
                                                  <img src="images/action_required_yellow.png" />
                                                </a>
                                              </td>
                                              <td>
                                                <div className="TextOrange">
                                                  {/* {checkArray(ele?.alerts) && ele?.alerts?.map((itemAlert,i) => {
                                        return (
                                          <> */}
                                                  {ele?.alerts}
                                                  {/* </>
                                        )
                                      })} */}
                                                </div>
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                  </>
                                );
                              })
                            ) : (
                              <td>
                                {" "}
                                <NoDataFound />
                              </td>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="PaginationArea">
                    <h6>Showing 1 to 10 of 27 entries</h6>
                    <h6>
                      <img src="images/leftarrow.png" /> 01{" "}
                      <img src="images/rightarrow.png" /> <span>Out</span> 10
                    </h6>
                  </div>
                </div>

                <div
                  onClick={hideBugetSummaryModal}
                  className="Button text-center mt-4"
                >
                  <button className="Outline" data-dismiss="modal">
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={requestLimitIncresaseModal}
        onHide={hideRequestLimitIncresaseModal}
        className="SmallModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideRequestLimitIncresaseModal}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Request Limit Increase</h4>
            </div>
            <div className="FolderForm">
              <div className="ModalMediumheading">
                <h3 style={{ marginBottom: "10px!important" }}>
                  <span>User: </span>John Doe
                </h3>
                <h3 style={{ marginBottom: "10px!important" }}>
                  <span>Role Limit : </span>$10,000.00
                </h3>
                <h3>
                  <span>User Limit : </span>$12,000.00
                </h3>
              </div>
              <div className="form-group">
                <h6>Increase Amount</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert Increase amount $"
                  name="requestIncreaseAmount"
                  value={requestIncreaseAmount}
                  onChange={handleInput}
                />
              </div>
              <div className="form-group">
                <h6>Reason</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert reason for limit increase"
                  name="increaseReason"
                  value={increaseReason}
                  onChange={handleInput}
                />
              </div>
              <div className="form-group">
                <h6>Request Approver</h6>
                <div className="Categories Categories1">
                  <div className="Categories_all Categories_all1">
                    <select
                      name="approverUserId"
                      value={approverUserId}
                      onChange={handleInput}
                      // disabled={modalState === true}
                    >
                      <option>Select Staff </option>
                      {checkArray(staffListData?.staff) ? (
                        <>
                          {staffListData?.staff?.map((ele, i) => {
                            return (
                              <option value={ele?.id}>{ele?.email}</option>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </select>
                    <img src="images/down.png" />
                  </div>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                         {budgetChangeError?.reasonIdError}
                       </span> */}
                </div>
              </div>
              <div className="Button mt-3 d-flex justify-content-between mt-5">
                <button className="Create" onClick={increaseRequestLimitFunc}>
                  Request Limit Increase
                </button>
                <button
                  className="Outline"
                  onClick={hideRequestLimitIncresaseModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={budgetChangeOrderDetailsModal}
        onHide={hideBudgetChangeOrderDetailsModal}
        className="MediumModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideBudgetChangeOrderDetailsModal}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Budget Change Order Details</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="ModalBigheading">
                  <h3>
                    <span>Budget Change Order # 1</span>
                  </h3>
                  <h3>
                    <span>Project:</span> Rivers Edge Homes
                  </h3>
                  <h3>
                    <span>Cost Code:</span> 30-0102 - Framing - Labor
                  </h3>
                  <h3>
                    <span>Job:</span> Rivers Edge Unit 366(RIV-2B-366)
                  </h3>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="form-group col-lg-8 mt-3">
                        <h6>Document Description</h6>
                        <p className="ml-0">
                          Move basement framing lumber package to back of
                          building for sidewalk prep and pour
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-lg-5 mt-3">
                        <div className="Categories Categories1">
                          <h6>Reason</h6>
                          <p className="ml-0">Price Increate</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="ModalBigheading mt-3">
                          <h3>
                            <span>Current Working Budget:</span> $6,000.00
                          </h3>
                        </div>
                        <div className="form-group mt-4">
                          <h6>Budget Increase Amount</h6>
                          <p className="ml-0">$500.00</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="ModalBigheading mt-3">
                      <h3>
                        <span>Remaining Budget:</span> $0.00
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="form-group">
                          <div className="Categories Categories1">
                            <h6>Related Document</h6>
                            <p className="ml-0">CHI-2B-251-13-304</p>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="CommonModalArea mt-3">
                            <h5>Supporting Document</h5>
                            <div className="CommonModalBox">
                              <h5>
                                <figure>
                                  <img src="images/icon-63.png" />
                                </figure>{" "}
                                <span className="Count">3</span> Supporting
                                Document
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Reminder</h5>
                        <div className="CommonModalBox">
                          <h5>
                            <figure>
                              <img src="images/timer.png" />
                            </figure>{" "}
                            5 Days
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="form-group">
                          <div className="Categories Categories1 mt-4">
                            <h6>Builder Signee</h6>
                            <p className="ml-0">Jill Manager</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex justify-content-between align-items-start">
                      <div className="form-group">
                        <div className="CommonModalArea">
                          <h5>Signature Status</h5>
                          <div className="CommonModalBox">
                            <h5> Pending with Jill Manager</h5>
                          </div>
                        </div>
                      </div>
                      <button
                        className="SignDocumentBtn"
                        data-toggle="modal"
                        data-target="#SignBudgetChangeOrderAgreement"
                        data-dismiss="modal"
                      >
                        Sign Document
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="ModalBigheading mt-4">
                      <h3>
                        <span>Investor Signee</span>
                      </h3>
                      <h3>
                        <span>Company:</span> Investor 1
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group mt-4">
                      <h6>Full Name</h6>
                      <p className="ml-0">Jane Investor</p>
                    </div>
                    <div className="form-group mt-4">
                      <h6>Email</h6>
                      <p className="ml-0">janve.investor@investor2.com</p>
                    </div>
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Budget Change Order Agreement</h5>
                        <div className="CommonModalBox">
                          <figure
                            className="CursorPointer"
                            data-toggle="modal"
                            data-target="#ViewBudgetChangeOrderAgreementHistory"
                            data-dismiss="modal"
                          >
                            <img src="images/view_budget.png" />
                          </figure>
                          &nbsp;
                          <h5>
                            <span className="Count">3</span> Budget Change Order
                            Agreement
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <h6>Authorization Type</h6>
                      <label className="Radio">
                        Electronic
                        <input
                          type="radio"
                          name="Cuisine"
                          defaultValue="text"
                          defaultChecked=""
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="Radio">
                        Electronic
                        <input
                          type="radio"
                          name="Cuisine"
                          defaultValue="file"
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="text RadioText">
                      {/* <div class="form-group">
                  <h6>Message (Max 1000 chars limit)</h6>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Write here ....."
                  />
                  <small class="form-text text-right"
                    >985 characters remaining</small
                  >
                </div> */}
                    </div>
                    <div className="file RadioText">
                      <div className="form-group">
                        <div className="CommonModalArea">
                          <h5>Download Hardcopy Budget Change Order</h5>
                          <div className="CommonModalBox">
                            <h5>
                              <figure>
                                <img src="images/view_budget.png" />
                              </figure>{" "}
                              <span className="Count">3</span> Download Hardcopy
                              Budget Change Order
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="CommonModalArea">
                          <h5>Budget Change Order Agreement</h5>
                          <div className="CommonModalBox">
                            <h5>
                              <figure>
                                <img src="images/upload_signed_agreement.png" />
                              </figure>{" "}
                              <span className="Count">3</span> Budget Change
                              Order Agreement
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <div className="CommonModalArea mt-4">
                        <h5>Signature Status</h5>
                        <div className="CommonModalBox">
                          <h5> Not Sent</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button mt-3 d-flex justify-content-between">
                  <button className="Create" data-dismiss="modal">
                    Print
                  </button>
                  <button className="Create" data-dismiss="modal">
                    Cancel Signature
                  </button>
                  <button
                    className="Outline"
                    data-toggle="modal"
                    data-target="#BudgetChangeOrders"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={updateBudgetChangeOrderModal}
        onHide={hideUpdateBudgetChangeOrderModal}
        className="MediumModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideUpdateBudgetChangeOrderModal}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>update New Budget Change Order</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="ModalBigheading">
                  <h3>
                    <span>Budget Change Order # 1</span>
                  </h3>
                  <h3>
                    <span>Project:</span> Rivers Edge Homes
                  </h3>
                  <h3>
                    <span>Cost Code:</span> 30-0102 - Framing - Labor
                  </h3>
                  <h3>
                    <span>Job:</span> Rivers Edge Unit 366(RIV-2B-366)
                  </h3>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="form-group col-lg-8">
                        <h6>Document Description</h6>
                        <textarea
                          className="form-control"
                          rows={2}
                          placeholder="Insert Description"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-5">
                        <h6>Reason</h6>
                        <div className="CommonSelectBox w-100">
                          <select>
                            <option selected="">Select Reason</option>
                            <option>- Price Increase</option>
                            <option>- Extra Labour</option>
                            <option>- Extra Materia</option>
                            <option>- Reasons loaded by the system</option>
                            <option>-Other</option>
                          </select>
                          <span>
                            <img src="images/down.png" />
                          </span>
                        </div>
                      </div>
                      <div className="form-group col-5">
                        <div className="Upload" style={{ marginTop: 18 }}>
                          <span>Specify</span>
                          <input type="file" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="ModalBigheading">
                          <h3>
                            <span>Current Working Budget:</span> $6,000.00
                          </h3>
                        </div>
                        <div className="form-group">
                          <h6>Budget Increase Amount</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Insert increase amount pre-tax"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="ModalBigheading">
                      <h3>
                        <span>Remaining Budget:</span> $0.00
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="form-group">
                          <h6>Related Document</h6>
                          <div className="CommonSelectBox w-100">
                            <select>
                              <option selected="">
                                Select Document related to the Budget Increase
                              </option>
                              <option>
                                - List of documents (QRs, PO and FPO's) that
                                exist for this cost code (even locked one's will
                                show)
                              </option>
                            </select>
                            <span>
                              <img src="images/down.png" />
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="CommonModalArea">
                            <h5>Add Supporting Document</h5>
                            <div className="CommonModalBox">
                              <figure
                                className="FolderPermissionId"
                                data-toggle="modal"
                                data-target="#AddNewSupportingDocs11"
                                data-dismiss="modal"
                              >
                                <img src="images/icon-63.png" />
                              </figure>
                              <span
                                className="Count FolderPermissionId"
                                data-toggle="modal"
                                data-target="#SupportingDocumentList11"
                                data-dismiss="modal"
                              >
                                3
                              </span>
                              <h5>Add Supporting Document</h5>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <h6>Builder Signee</h6>
                          <div className="CommonSelectBox w-100">
                            <select>
                              <option selected="">Select Builder Signee</option>
                              <option>
                                -List of employees with authority to sign
                                document
                              </option>
                            </select>
                            <span>
                              <img src="images/down.png" />
                            </span>
                          </div>
                        </div>
                        <div className="ModalBigheading">
                          <h3>
                            <span>Investor Signee</span>
                          </h3>
                          <h3>
                            <span>Company:</span> Investor 1
                          </h3>
                        </div>
                        <div className="form-group">
                          <h6>Full Name</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Jane Investor"
                          />
                        </div>
                        <div className="form-group">
                          <h6>Email</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Insert email"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="Button mb-4">
                      <button className="Create">
                        Generate /update Budget Change Order
                      </button>
                    </div>
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Budget Change Order Agreement</h5>
                        <div className="CommonModalBox">
                          <figure
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#"
                            data-dismiss="modal"
                          >
                            <img src="images/view_budget.png" />
                          </figure>
                          &nbsp;
                          <h5>
                            <span className="Count">3</span> Budget Change Order
                            Agreement
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <h6>Authorization Type</h6>
                      <label className="Radio">
                        Electronic
                        <input
                          type="radio"
                          name="Cuisine"
                          defaultValue="text"
                          defaultChecked=""
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="Radio">
                        {" "}
                        Hardcopy
                        <input
                          type="radio"
                          name="Cuisine"
                          defaultValue="file"
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="text RadioText">
                      {/* <div class="form-group">
                    <h6>Message (Max 1000 chars limit)</h6>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Write here ....."
                    />
                    <small class="form-text text-right"
                      >985 characters remaining</small
                    >
                  </div> */}
                    </div>
                    <div className="file RadioText">
                      <div className="form-group">
                        <div className="CommonModalArea">
                          <h5>Download Hardcopy Budget Change Order</h5>
                          <div className="CommonModalBox">
                            <h5>
                              <figure>
                                <img src="images/view_budget.png" />
                              </figure>{" "}
                              <span className="Count">3</span> Download Hardcopy
                              Budget Change Order
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="CommonModalArea">
                          <h5>Budget Change Order Agreement</h5>
                          <div className="CommonModalBox">
                            <h5>
                              <figure>
                                <img src="images/upload_signed_agreement.png" />
                              </figure>{" "}
                              <span className="Count">3</span> Budget Change
                              Order Agreement
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Reminder</h5>
                        <div className="CommonModalBox">
                          <h5>
                            <figure>
                              <img src="images/timer.png" />
                            </figure>{" "}
                            5 Days
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button mt-3 d-flex justify-content-between">
                  <button
                    className="Create"
                    onClick={hideUpdateBudgetChangeOrderModal}
                  >
                    Send Budget Change Order
                  </button>
                  <button
                    className="Create"
                    onClick={hideUpdateBudgetChangeOrderModal}
                  >
                    Save Draft
                  </button>
                  <button
                    className="Outline"
                    onClick={hideUpdateBudgetChangeOrderModal}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ViewQuoteRequestCostBreakDownModal}
        onHide={handleViewQuoteRequestCostBreakDownModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleViewQuoteRequestCostBreakDownModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Quote Request Cost Breakdown</h4>
            </div>
            <div className="FolderForm">
              <div className="JobTitleArea mb-2">
                <div className="JobBox">
                  <h6>
                    <span>Quote Request:</span>{" "}
                    {quoteRequestCostBreakDownDetails?.data?.documentCode}
                  </h6>
                </div>
              </div>
              <div className="TableSmallHeading">
                <h6>Builder's Original Input</h6>
                <h6>*Pre-Tax</h6>
              </div>
              <div className="TableList CommonHeading">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <div className="text-center">
                          Material Description
                          <br />
                          (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                        </div>
                      </th>
                      <th>Color</th>
                      <th>Quantity</th>
                      <th>Units</th>
                      <th>Price/Unit</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quoteRequestCostBreakDownDetails?.data?.BuilderItems
                      ?.length > 0 ? (
                      quoteRequestCostBreakDownDetails?.data?.BuilderItems?.map(
                        (item, i) => {
                          return (
                            <tr key={i}>
                              {/* <td>{item.id}</td> */}
                              <td>{item.name}</td>
                              <td>{item.color}</td>
                              <td>{item.quantity}</td>
                              <td>{item.unitId}</td>
                              <td>$ {item.price}</td>
                              <td>$ {item.total}</td>
                            </tr>
                          );
                        }
                      )
                    ) : (
                      <tr>
                        <td colSpan="12">
                          <p>No Data found.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="TableSmallHeading mt-5">
                <h6>Vendor's Cost Breakdown</h6>
                <h6>*Pre-Tax</h6>
              </div>
              <div className="TableList CommonHeading">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <div className="text-center">
                          Material Description
                          <br />
                          (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                        </div>
                      </th>
                      <th>Color</th>
                      <th>Quantity</th>
                      <th>Units</th>
                      <th>Price/Unit</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quoteRequestCostBreakDownDetails?.data?.VendorItems
                      ?.length > 0 ? (
                      quoteRequestCostBreakDownDetails?.data?.VendorItems?.map(
                        (item, i) => {
                          return (
                            <tr key={i}>
                              {/* <td>{item.id}</td> */}
                              <td>{item.name}</td>
                              <td>{item.color}</td>
                              <td>{item.quantity}</td>
                              <td>{item.unitId}</td>
                              <td>$ {item.price}</td>
                              <td>$ {item.total}</td>
                            </tr>
                          );
                        }
                      )
                    ) : (
                      <tr>
                        <td colSpan="12">
                          <p>No Data found.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group mt-5">
                    <h6>Valid Until</h6>
                    <p className="ml-0">
                      <img src="images/calendar.png" className="mr-2" />
                      {quoteRequestCostBreakDownDetails?.data?.validTill &&
                        moment(
                          quoteRequestCostBreakDownDetails?.data?.validTill
                        ).format("YYYY-MM-DD")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="Button mt-4 text-center">
                <button
                  className="Outline"
                  onClick={handleViewQuoteRequestCostBreakDownModalClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ViewSupplierCreditModal}
        onHide={handleViewSupplierCreditModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleViewSupplierCreditModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox ModalTitleBoxRed">
              <h4>View Supplier Credit</h4>
            </div>
            <div className="FolderForm">
              {/* <form> */}
              <div className="BlueTextArea RedTextArea">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="Heading">
                      <h3>View Supplier Credit # 2B-366-23-102</h3>
                    </div>
                    {/* <a href="javascript:void(0);" class="BlueOutline GreenOutline mb-4 FolderPermissionId" data-toggle="modal"
                    data-target="#ViewSupplierCreditGreen" data-dismiss="modal"><img
                      src="images/create_filed_purchase.png">Create as Field Purchase Order
                    </a> */}
                    <div className="row">
                      <div className="form-group col-lg-9">
                        <div className="Categories Categories1">
                          <h6>Reason</h6>
                          <p>Back Charge</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="Heading">
                      <h3>Rivers Edge Unit 366(RIV-2B-366)</h3>
                      <div className="d-flex align-items-center">
                        <h3 className="mb-0">
                          Cost Code: <span>23-0101 - Appliances</span>
                        </h3>
                        <div className="TableThreeDots ml-5">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/upload_additional.png" />
                                    View Additional Documents
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/cancel_qr.png" />
                                    Cancel View
                                  </a>
                                </li>
                                {/* <li><a href="javascript:void(0);" class="FolderPermissionId" data-toggle="modal"
                                  data-target="#VendorBackChargeNotification" data-dismiss="modal"><img
                                    src="images/notify_vendor.png">Notify Vendor of Back Charge</a></li> */}
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="ModalFilterAreaBox">
                      <h4 className="mb-4">Vendor</h4>
                      <div className="RadioArea d-flex  flex-column">
                        <div className="form-group mb-3">
                          <label className="Radio text-black">
                            Use Cost Code's preferred vendor
                            <input type="radio" name="Cuisine" />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="form-group">
                          <label className="Radio text-black">
                            Select from list
                            <input type="radio" name="Cuisine" />
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <h6>Date</h6>
                      <p className="ml-0">05/30/ 2023</p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5 className="RedText">Reminder</h5>
                        <div className="CommonModalBox">
                          <h5>
                            <figure>
                              <img src="images/timer.png" />
                            </figure>{" "}
                            30 Days
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <div className="CommonModalBox">
                          <h5 className="text-black">
                            <figure>
                              <img src="images/vendor.png" />
                            </figure>{" "}
                            Vendor 3
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ShippingAddressArea">
                      <div className="ShippingAddressAreaBox">
                        <h6>Mailing Address</h6>
                        <p>Vendor 1 30 Shrewsbury Rd Calgary T3M 1Y6</p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Email</h6>
                        <p>vendor1.estimation@vendot1.ca</p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Phone</h6>
                        <p>587555555</p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Shipping Address</h6>
                        <p>5384 Peach Rd Chilliwack, BC V2R0Z6</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5 className="RedText">Back Charge Vendor</h5>
                        <div className="CommonModalBox">
                          <h5 className="text-black">
                            <figure>
                              <img src="images/vendor.png" />
                            </figure>{" "}
                            Vendor 3
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <h6>Messsage</h6>
                      <p>Vendor</p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ItemDetails">
                      <h6>Item Details</h6>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="Tableheader mb-3">
                      <h6 className="mb-0">Item</h6>
                      <div className="Actions">
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/download.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/setting.png" />
                        </a>
                      </div>
                    </div>
                    <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                      <table>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>
                              <div className="text-center">
                                Material Description
                                <br />
                                (i.e: Modal/Type/Style/manuf part#/Dimensions,
                                etc.)
                              </div>
                            </th>
                            <th>Color</th>
                            <th>Quantity</th>
                            <th>Units</th>
                            <th>Price/Unit</th>
                            <th>Total</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>NNTK913 S Panasonic Convection Trim Kit</td>
                            <td>Stainless</td>
                            <td>1</td>
                            <td>Each</td>
                            <td>$ 500.00</td>
                            <td>$ 500.00</td>
                            <td>
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu">
                                      <li>
                                        <a href="javascript:void(0);">
                                          <img src="images/add_new_column.png" />
                                          Add New Column
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <img src="images/dropdown-delete.png" />
                                          Remove Line
                                        </a>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 ml-auto">
                        <div className="GstTable">
                          <table>
                            <tbody>
                              <tr>
                                <td>Profile</td>
                                <td>Alberta Profile</td>
                              </tr>
                              <tr>
                                <td>Subtotal</td>
                                <td>$4,000.00</td>
                                {/* <td>Modify Tax</td> */}
                              </tr>
                              <tr>
                                <td>GST(5%)</td>
                                <td>$200.00</td>
                                <td>
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown">
                                        <a
                                          class=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol class="dropdown-menu dropdown-menuwidth">
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/remove_tax_line.png" />
                                              Remove Tax line
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href="javascript:void(0);"
                                              class="CursorPointer FolderPermissionId "
                                              //  onClick={() => showAddTaxProfileModal("purchaseOrder")}
                                            >
                                              <img src="images/add_new_tax_line.png" />
                                              Add New Tax line
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Grand Total</td>
                                <td>$4,200.00</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-4">
                      <div className="Heading">
                        <h6>Attachments</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Scope of Work</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#ViewSupplierCreditRedSelect"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/scopeofwork.png" />
                            </figure>{" "}
                            Scope of Work
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Construction Documents</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#ViewSupplierCreditRedConstruction"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/construction_documents.png" />
                            </figure>{" "}
                            Construction Documents
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>External Quote</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#ViewSupplierCreditRedImportReturned"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/extermal_quote.png" />
                            </figure>{" "}
                            External Quote
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between mt-4 ">
                  {/* <button class="Create">Save and <img src="images/white_down_arrow.png"></button>
                <button class="Create">Save draft</button> */}
                  <button
                    className="Create"
                    onClick={handleViewSupplierCreditModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="DiscussionArea">
    <div className="DiscussionTitle bg_f7">
      <h4>Discussion</h4>
      <div className="DiscussionChat">
        <img src="images/down.png" className="DiscussionIcon" />
        <a href="#">
          <img src="images/pdf2.png" />
        </a>
      </div>
    </div>
    <div className="DiscussionHideArea" style={{ display: "block" }}>
      <div className="DiscussionBox">
        <span>FM</span>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Add a Comment ....."
          />
          <div className="DiscussionOverlay">
            <a href="" className="CancelChat">
              <img src="images/plus-cancel1.png" />
            </a>
            <a href="" className="SendChat">
              <img src="images/forwardbutton.png" />
            </a>
          </div>
        </div>
      </div>
      <div className="DiscussionBox">
        <span>TH</span>
        <div className="DiscussionRight">
          <h6>Inspections are delayed</h6>
          <small>04/19/2023, at 02:47 pm</small>
        </div>
      </div>
      <div className="ReplyArea">
        <a href="javascript:void(0);">Reply</a>
        <div className="DiscussionBox">
          <span>TH</span>
          <div className="DiscussionRight">
            <h6>Inspections are delayed</h6>
            <small>04/19/2023, at 02:47 pm</small>
          </div>
        </div>
        <div className="DiscussionBox">
          <span>TH</span>
          <div className="DiscussionRight">
            <h6>Inspections are delayed</h6>
            <small>04/19/2023, at 02:47 pm</small>
          </div>
        </div>
      </div>
    </div>
  </div> */}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={VendorBackChargeNotificationModal}
        onHide={handleVendorBackChargeNotificationModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleVendorBackChargeNotificationModalClose}
          >
            ×
          </a>

          <div className="FolderForm">
            <div className="ModalBigheading">
              {/* <h3>
                <span>Originating Document #</span> 2B-366-37-142
              </h3> */}
            </div>
            <div className="form-group">
              <div className="CommonModalArea">
                <div className="CommonModalBox">
                  <h5
                    className="text-black"
                    // onClick={() => showSystemVendor("backChargeNoti")}
                  >
                    <figure>
                      <img src="images/vendor.png" />
                    </figure>{" "}
                    {vendorNames ? vendorNames : ""}
                  </h5>
                </div>
              </div>
            </div>
            {/* <div className="form-group">
              <h6>Back Charge Subject Document</h6>
              <div className="Categories Categories1">
                <div className="Categories_all Categories_all1">
                  <select
                    name="originalDocumentId"
                    value={originalDocumentId}
                    onChange={handleInput}
                  >
                    <option value=""> Select Documents</option>
                    {relatedDocumentsList?.data?.length > 0 &&
                      relatedDocumentsList?.data?.map((ele, i) => {
                        return (
                          <option key={i} value={ele?.id}>
                            {ele?.documentCode}
                          </option>
                        );
                      })}
                  </select>
                  <select
                    value={originalDocumentId}
                    name="originalDocumentId"
                    onChange={handleInput}
                  >
                    <option value="">Select Document</option>
                    {backChargeRelatedDocumentsList?.data?.length > 0 &&
                      backChargeRelatedDocumentsList?.data?.map((item, i) => {
                        return (
                          <option value={item.id}>{item.documentCode}</option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div> */}
            <div className="form-group">
              <h6>Message</h6>
              <textarea
                className="form-control"
                rows={4}
                placeholder="Include message (optional)"
                name="messages"
                value={messages}
                onChange={handleInput}
              />
            </div>
            <div className="form-group">
              <h6>Attachments</h6>
              <div className="Upload">
                <span>-Drag and Drop Files or click to select</span>
                <input
                  type="file"
                  id="docsInput"
                  multiple
                  onChange={(e) => handleFileChanges(e, "docsUpdate")}
                />
              </div>
            </div>
            {supportingDocFiles?.length > 0 &&
              supportingDocFiles?.map((item, i) => {
                return (
                  <div className="FilesAdded">
                    <label>{item?.name}</label>
                    <span>
                      <img src="images/fileview.png" />
                    </span>
                    <span onClick={() => handleRemoveDocsFile(item.id)}>
                      <img src="images/Cross.png" className="cross" />
                    </span>
                  </div>
                );
              })}
            <div className="form-group">
              <h6>Estimated Value</h6>
              <input
                type="number"
                min={0}
                className="form-control"
                placeholder="Insert value to be charged"
                name="estimatedValue"
                value={estimatedValue}
                onChange={handleInput}
              />
            </div>
            <div className="Button d-flex justify-content-between">
              <button
                className="Create"
                onClick={handleCreateBackChargeNotification}
              >
                Send Notification
              </button>
              <button
                className="Outline"
                onClick={handleVendorBackChargeNotificationModalClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={DiscussionSettingsModal}
        onHide={handleDiscussionSettingsModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleDiscussionSettingsModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Discussion Settings</h4>
            </div>
            <div className="FolderForm">
              <div
                className="FolderFormCreateArea p-0"
                style={{ overflow: "initial" }}
              >
                <div className="row">
                  <div className="col-lg-12">
                    <div className="ModalMediumheading">
                      <h3>
                        <span>Linked to: </span>Warranty Request #
                        {state?.item?.id}
                      </h3>
                    </div>
                    <h6>Visible in :</h6>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mt-2 mb-4 ">
                      <label className="switch">
                        Client View
                        <input
                          type="checkbox"
                          checked={visibleInClientView === true ? true : false}
                          onClick={() => handleDiscussionCheckBox("client")}
                        />
                        <span className="slider" />
                      </label>
                    </div>
                    <div className="form-group mb-4 ">
                      <label className="switch">
                        Trade View
                        <input
                          type="checkbox"
                          checked={visibleInTradeView === true ? true : false}
                          onClick={() => handleDiscussionCheckBox("trade")}
                        />
                        <span className="slider" />
                      </label>
                    </div>
                    <div className="form-group mb-4 ">
                      <label className="switch">
                        Investor View
                        <input
                          type="checkbox"
                          checked={
                            visibleInInvestorView === true ? true : false
                          }
                          onChange={() => handleDiscussionCheckBox("invest")}
                        />
                        <span className="slider" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center">
                <button
                  className="Create"
                  onClick={handleDiscussionSettingUpdate}
                >
                  Save Discussion Settings
                </button>
                <button
                  className="Outline"
                  onClick={handleDiscussionSettingsModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={FilterModal}
        onHide={handleFilterModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleFilterModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Filter Table</h4>
            </div>
            <div className="FolderForm">
              <div className="ModalMediumheading">
                <h3>
                  <span>Table : </span>Table 1
                </h3>
              </div>
              <div className="form-group">
                <h6>Column</h6>
                <div className="CommonSelectBox w-100">
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    value={includedConsultantt}
                    onChange={handleConsChange}
                    options={consultModule}
                  />
                </div>
              </div>

              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={handleApply}>
                  Apply Filter
                </button>
                <button className="Outline" onClick={handleFilterModalClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={SystemVendorModalBackCharge}
        onHide={handleSystemVendorModalBackChargeClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                href="javascript:void(0);"
                className="CloseModal"
                onClick={handleSystemVendorModalBackChargeClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>System Vendor List</h4>
                </div>
                <div className="FolderForm">
                  {/* <form> */}
                  <div className="form-group">
                    <div className="Categories Categories1 mb-4">
                      <h6>Vendor Type</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="vendorTypes"
                          value={vendorTypes}
                          onChange={handleInput}
                        >
                          <option value="">Select a Vendor Type</option>
                          {checkArray(data3) ? (
                            <>
                              {data3?.map((ele, i) => {
                                return <option value={ele}>{ele}</option>;
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input
                            type="text"
                            placeholder="-Search"
                            onChange={(e) => onChangeHandlers(e)}
                          />
                          <span className="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>
                        {/* <li className="CursorPointer">
                        - Full list of vendor types loaded from the system
                      </li> */}
                      </ul>
                      {/* <span style={{ color: "red", fontSize: "14px" }}>
                        {systemErrors?.vendorTypeError}
                      </span> */}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>Vendor Name</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="vendorIds"
                          value={vendorIds}
                          onChange={handleInput}
                        >
                          <option>Select a Vendor Name</option>
                          {checkArray(vendorSearchData) ? (
                            <>
                              {vendorSearchData?.map((ele, i) => {
                                if (ele.vendorName)
                                  return (
                                    <option value={ele?.id}>
                                      {ele.vendorName}
                                    </option>
                                  );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input
                            type="text"
                            placeholder="Search"
                            onChange={handleInputSearchCity}
                          />
                          <span className="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>
                        {/* <li className="CursorPointer">
                        - Full list of names loaded from the system
                      </li> */}
                      </ul>
                      {/* <span style={{ color: "red", fontSize: "14px" }}>
                        {systemErrors?.vendorNameError}
                      </span> */}
                    </div>
                  </div>
                  <div className="Button mt-5 d-flex justify-content-between">
                    <button
                      className="Outline"
                      onClick={handleSystemVendorModalBackChargeClose}
                    >
                      Cancel
                    </button>
                    <button
                      className="Create"
                      onClick={handleSystemVendorModalBackChargeClose}
                    >
                      Confirm Selection
                    </button>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <GearSettingIconEstimation
        GearIconSettingModalShow={GearIconSettingModalShow}
        SetGearIconSettingModalShow={SetGearIconSettingModalShow}
        state={state}
      />

      <Modal
        show={NARowsModal}
        onHide={hideNARowsModal}
        className="SmallModal "
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideNARowsModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Show N/A Rows</h4>
            </div>
            <div className="mb-4">
              <button className="BrandBtn">
                <img src="images/red-close-eye.png" />
                Show Hidden
              </button>
            </div>
            <div className="TableList CommonHeading TableListMaxHeight">
              <table>
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Category Name</th>
                    <th>Cost Code</th>
                    <th>Cost Code Description</th>
                    <th>Select</th>
                  </tr>
                </thead>
                <tbody>
                  {checkArray(hiddeCostCodeData[0]?.jobCostCodes) &&
                    hiddeCostCodeData[0]?.jobCostCodes?.map((ele) => {
                      return (
                        <>
                          <tr>
                            <td>{hiddeCostCodeData[0]?.id}</td>
                            <td>{hiddeCostCodeData[0]?.name}</td>
                            <td>{ele?.number}</td>
                            <td>{ele?.name}</td>
                            <td>
                              <label className="CheckBox">
                                <input
                                  type="checkbox"
                                  value={ele?.id}
                                  onChange={(e) =>
                                    saveUhideCoseIdFunc(e, ele?.id)
                                  }
                                />
                                <span className="checkmark" />
                              </label>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="PaginationArea">
              <h6>Showing 1 to 10 of 27 entries</h6>
              <h6>
                <img src="images/leftarrow.png" /> 01{" "}
                <img src="images/rightarrow.png" /> <span>Out 10</span>{" "}
              </h6>
            </div>
            <div className="Button mt-5 d-flex justify-content-between align-items-center">
              <button className="Create" onClick={unhideCostCodeFunc}>
                Unhide Selected Rows
              </button>
              <button className="Outline" onClick={hideNARowsModal}>
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={addTaxProfileModal}
        onHide={hideAddTaxProfileModal}
        className="SmallModal "
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAddTaxProfileModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Add Tax Definition</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="form-group">
                  <h6>Tax Name</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert Name"
                    name="taxName"
                    value={taxName}
                    onChange={handleInput}
                  />
                </div>
                <div className="form-group">
                  <h6>Percentage</h6>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Insert %"
                    name="taxPercentage"
                    value={taxPercentage}
                    onChange={handleInput}
                  />
                </div>
                <div className="Button d-flex justify-content-between mt-5">
                  <button className="Create" onClick={addTaxProfileFunc}>
                    Save
                  </button>
                  <button className="Outline" onClick={hideAddTaxProfileModal}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={BackChargeNotificationListModal}
        onHide={handleBackChargeNotificationListModalClose}
        className="ModalBox modal fade LargeModal LeftBorder show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleBackChargeNotificationListModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Vendor Back Charge Notification</h4>
            </div>
            <div className="TableHeadingArea">
              <div className="ModalMediumheading">
                <h3>
                  <span>Cost Code #</span>{" "}
                  {`${messageList?.data?.[0]?.CostCode?.number ?? ""}-${
                    messageList?.data?.[0]?.CostCode?.name ?? ""
                  }`}
                </h3>
                <h3>
                  <span>Vendor:</span>{" "}
                  {messageList?.data?.[0]?.Vendor?.vendorName ?? "N/A"}
                </h3>
              </div>
              <div className="ModalMediumheading ">
                <h3>
                  <span>Status:</span>
                  {messageList?.data?.[0]?.status}
                </h3>{" "}
                {"   "}
                <h3 style={{ marginLeft: "40px!important" }}>
                  <span>Date: </span>
                  {moment(messageList?.data?.[0]?.createdAt).format(
                    "YYYY-MM-DD & hh:mm a"
                  )}
                </h3>
              </div>
              <div className="ModalMediumheading">
                {/* <h3>
        <span>Back Charge Subject Document # </span> 2B-366-23-102
      </h3> */}
              </div>
              <div className="FilesHead">
                <div className="FilesLeft">
                  <h4>Message</h4>
                </div>
                <div className="FilesRight d-flex align-items-center">
                  <a href="javascript:void(0);">
                    <img src="images/pdf.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/download.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
            </div>
            <div className="TableList CommonHeadingBlack">
              <table>
                <thead>
                  <tr>
                    <th>Sent By</th>
                    <th>Activity</th>
                    <th>Date</th>
                    <th>Message</th>
                    <th>Attachments</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {messageList?.data?.[0]?.BackChargeNotifications?.length >
                  0 ? (
                    messageList?.data?.[0]?.BackChargeNotifications?.map(
                      (item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item?.sendByUser?.name ?? "N/A"}</td>
                            <td>{item.title ?? "N/A"}</td>
                            <td>
                              {moment(item.createdAt).format(
                                "YYYY-MM-DD & hh:mm a"
                              )}
                            </td>
                            <td>{item?.message ?? "N/A"}</td>
                            <td>
                              <div
                                className="Actions"
                                onClick={() =>
                                  handleViewMessageModalShow(
                                    item,
                                    messageList?.data?.[0]
                                  )
                                }
                              >
                                <a href="javascript:void(0);">
                                  <img src="images/attachment.png" />
                                </a>
                                <a>
                                  <span className="Count">
                                    {item?.attachments?.length ?? 0}
                                  </span>
                                </a>
                              </div>
                            </td>
                            <td>
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol
                                      className="dropdown-menu dropdown-menuwidth"
                                      style={{}}
                                    >
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleViewMessageModalShow(
                                              item,
                                              messageList?.data?.[0]
                                            )
                                          }
                                        >
                                          <img src="images/view-po.png" />
                                          View Message
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleResendMeassage(
                                              item,
                                              messageList?.data?.[0]?.id
                                            )
                                          }
                                        >
                                          <img src="images/resend_message.png" />
                                          Resend Message
                                        </a>
                                      </li>
                                      {/* <li>
                          <a href="javascript:void(0);">
                            <img src="images/cancecl_message.png" />
                            Cancel Message
                          </a>
                        </li> */}
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    )
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-center">
                        <p>No Data found.</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="PaginationArea">
              <h6>Showing 1 to 10 of 27 entries</h6>
              <h6>
                <img src="images/leftarrow.png" /> 01{" "}
                <img src="images/rightarrow.png" /> Out 10
              </h6>
            </div>
            <div className="Button mt-4 d-flex justify-content-between">
              <button
                className="Create FolderPermissionId"
                onClick={() =>
                  handleCancelBackCharges(messageList?.data?.[0]?.id)
                }
              >
                Cancel Back Charge
              </button>
              <button
                className="Create FolderPermissionId"
                onClick={() =>
                  handleVendorBackChargeNotificationReplyModalShow()
                }
              >
                Send Notification
              </button>
              <button
                className="Outline FolderPermissionId"
                onClick={handleBackChargeNotificationListModalClose}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ViewMessageModal}
        onHide={handleViewMessageModalClose}
        className="ModalBox modal fade MediumModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleViewMessageModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>View Back Charge Message</h4>
            </div>
            <div className="FolderForm">
              <div className="NewEmailArea">
                <div className="NewEmailTop">
                  <p>
                    <label>Cost Code #</label>
                    <span>
                      {" "}
                      {`${messageDetailRemData?.CostCode?.number}-${messageDetailRemData?.CostCode?.name}`}
                    </span>
                  </p>
                  <p>
                    <label>Vendor:</label>
                    <span>{messageDetailRemData?.Vendor?.vendorName}</span>
                  </p>
                  {/* <p>
            <label>From</label>
            <span>John Doe</span>
          </p> */}
                  <p>
                    <label>Date</label>
                    <span>
                      {moment(messageDetailRemData.createdAt).format(
                        "YYYY-MM-DD & hh:mm a"
                      )}
                    </span>
                  </p>
                  {/* <p>
            <label>Back Charge Subject Document #</label>
            <span>2B-366-23-102</span>
          </p> */}
                </div>
                <div className="NewEmailBox">
                  <h4>Title</h4>
                  <p>RE: {messageDetailData?.title ?? "N/A"}</p>
                </div>
                <div className="NewEmailBox">
                  <h4>Message</h4>
                  <p>{messageDetailData?.message}</p>
                </div>
                <div className="NewEmailBox">
                  <h4>Attachments:</h4>
                  {messageDetailData?.attachments?.length > 0
                    ? messageDetailData?.attachments?.map((data, i) => {
                        return (
                          <p>
                            {`${i + 1}) ${data.blobName}`}
                            <img
                              src="images/file_name.png"
                              className="ml-3"
                              title={data?.docUrl}
                            />
                          </p>
                        );
                      })
                    : "No Data Available"}
                </div>
                <div className="NewEmailBox">
                  <h4>Estimated Value</h4>
                  <p>$ {messageDetailData?.estimatedValue}</p>
                </div>
              </div>
              <div className="Button text-center">
                <button
                  className="Outline"
                  onClick={handleViewMessageModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={VendorBackChargeNotificationReplyModal}
        onHide={handleVendorBackChargeNotificationReplyModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleVendorBackChargeNotificationReplyModalClose}
          >
            ×
          </a>

          <div className="FolderForm">
            <div className="ModalBigheading">
              {/* <h3>
                <span>Originating Document #</span> 2B-366-37-142
              </h3> */}
            </div>
            <div className="form-group">
              <div className="CommonModalArea">
                <div className="CommonModalBox">
                  <h5
                    className="text-black"
                    // onClick={() => showSystemVendor("backChargeNoti")}
                  >
                    <figure>
                      <img src="images/vendor.png" />
                    </figure>{" "}
                    {vendorNames ? vendorNames : ""}
                  </h5>
                </div>
              </div>
            </div>
            <div className="form-group">
              <h6>Back Charge Subject Document</h6>
              <div className="Categories Categories1">
                <div className="Categories_all Categories_all1">
                  <select
                    name="originalDocumentId"
                    value={originalDocumentId}
                    onChange={handleInput}
                  >
                    <option value=""> Select Documents</option>
                    {relatedDocumentsList?.data?.length > 0 &&
                      relatedDocumentsList?.data?.map((ele, i) => {
                        return (
                          <option key={i} value={ele?.id}>
                            {ele?.documentCode}
                          </option>
                        );
                      })}
                  </select>
                  {/* <select
                    value={originalDocumentId}
                    name="originalDocumentId"
                    onChange={handleInput}
                  >
                    <option value="">Select Document</option>
                    {backChargeRelatedDocumentsList?.data?.length > 0 &&
                      backChargeRelatedDocumentsList?.data?.map((item, i) => {
                        return (
                          <option value={item.id}>{item.documentCode}</option>
                        );
                      })}
                  </select> */}
                </div>
              </div>
            </div>
            <div className="form-group">
              <h6>Message</h6>
              <textarea
                className="form-control"
                rows={4}
                placeholder="Include message (optional)"
                name="messages"
                value={messages}
                onChange={handleInput}
              />
            </div>
            <div className="form-group">
              <h6>Attachments</h6>
              <div className="Upload">
                <span>-Drag and Drop Files or click to select</span>
                <input
                  type="file"
                  id="docsInput"
                  multiple
                  onChange={(e) => handleFileChanges(e, "docsUpdate")}
                />
              </div>
            </div>
            {supportingDocFiles?.length > 0 &&
              supportingDocFiles?.map((item, i) => {
                return (
                  <div className="FilesAdded">
                    <label>{item.name}</label>
                    <span>
                      <img src="images/fileview.png" />
                    </span>
                    <span onClick={() => handleRemoveDocsFile(item.id)}>
                      <img src="images/Cross.png" className="cross" />
                    </span>
                  </div>
                );
              })}
            <div className="form-group">
              <h6>Estimated Value</h6>
              <input
                type="number"
                min={0}
                className="form-control"
                placeholder="Insert value to be charged"
                name="estimatedValue"
                value={estimatedValue}
                onChange={handleInput}
              />
            </div>
            <div className="Button d-flex justify-content-between">
              <button
                className="Create"
                onClick={handleCreateBackChargeNotificationReply}
              >
                Send Notification
              </button>
              <button
                className="Outline"
                onClick={handleVendorBackChargeNotificationReplyModalClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={JobMenuModal}
        onHide={handleJobMenuModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleJobMenuModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Job Menu</h4>
            </div>
            <div className="FolderForm">
              <div className="JobMenuListing">
                <ul>
                  <li>
                    <a href="javascript:void(0);">
                      <figure>
                        <img src="images/add_build.png" />
                      </figure>
                      Add to/Edit Build
                      <img
                        src="images/rightarrow2.png"
                        className="ListingOverflow"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <figure>
                        <img src="images/details.png" />
                      </figure>
                      Details
                    </a>
                  </li>
                  <li className="JobMenuListing_All">
                    <Link to="/change-order-list" state={state}>
                      <figure>
                        <img src="images/change_order.png" />
                      </figure>
                      Change Order
                      <img
                        src="images/rightarrow2.png"
                        className="ListingOverflow"
                      />
                    </Link>
                    <ul className="JobMenuListingUnder">
                      <li>
                        <Link to="/change-order-list" state={state}>
                          Change orders List
                        </Link>
                      </li>
                      <li>
                        <Link to="/change-order-list" state={state}>
                          Change to Contract (CTC) master
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/warranty-dashboard" state={state}>
                      <figure>
                        <img src="images/design.png" />
                      </figure>
                      Warranty
                    </Link>
                  </li>
                  <li>
                    <Link to="/sales" state={state}>
                      <figure>
                        <img src="images/sales.png" />
                      </figure>
                      Sales
                    </Link>
                  </li>

                  <li>
                    <Link to="/construction" state={state}>
                      <figure>
                        <img src="images/construction_icon.png" />
                      </figure>
                      Construction
                      <img
                        src="images/rightarrow2.png"
                        className="ListingOverflow"
                      />
                    </Link>
                  </li>
                  {pathname !== "/estimation" && (
                    <li>
                      <Link to="/estimation" state={state}>
                        <figure>
                          <img src="images/warranty.png" />
                        </figure>
                        Estimation
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link to="/reports" state={state}>
                      <figure>
                        <img src="images/reports.png" />
                      </figure>
                      Reports
                      <img
                        src="images/rightarrow2.png"
                        className="ListingOverflow"
                      />
                    </Link>
                  </li>

                  <li>
                    <Link to="/client-view-config" state={state}>
                      <figure>
                        <img src="images/view_config.png" />
                      </figure>
                      Views Config
                      <img
                        src="images/rightarrow2.png"
                        className="ListingOverflow"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={NavigateModal}
        onHide={handleNavigateModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleNavigateModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Navigate Project</h4>
            </div>
            <div className="FolderForm">
              <div className="NaviagateProjectArea">
                <ul>
                  {allProjectList?.projects?.length > 0 &&
                    allProjectList?.projects.map((item, index) => {
                      return (
                        <>
                          <li className="DarkBlueList AvalonTownsTab">
                            <a href="javascript:void(0);">{item.projectName}</a>
                          </li>
                          <li className="LightBlueList AdministrationTab">
                            <a href="javascript:void(0);">{item.jobPattern}</a>
                            <div className="TableThreeDots">
                              <ul className="">
                                <li className="dropdown">
                                  <a
                                    className=""
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/dots.png" />
                                  </a>
                                  <ol className="dropdown-menu dropdown-menuwidth DropDownMenuZindex">
                                    <li>
                                      <a>
                                        <img src="images/add_build.png" />
                                        Add to/Edit Build
                                      </a>
                                    </li>
                                    <li>
                                      <a>
                                        <img src="images/details.png" />
                                        Details
                                      </a>
                                    </li>
                                    <li className="border-0">
                                      <Link
                                        to="/change-order-list"
                                        state={state}
                                        className="dropdown-item"
                                        style={{
                                          border:
                                            "1px solid transparent!important",
                                        }}
                                      >
                                        <img src="images/change_order.png" />
                                        Change Order &nbsp;&nbsp;
                                        <img src="images/rightarrow2.png" />
                                      </Link>
                                      <ul className="dropdown-menu submenu">
                                        <li>
                                          <Link
                                            to="/change-order-list"
                                            state={state}
                                            className="dropdown-item"
                                          >
                                            Change orders List
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="/change-order-list"
                                            state={state}
                                            className="dropdown-item"
                                          >
                                            Change to Contract (CTC) master
                                          </Link>
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      <Link
                                        to="/design-selections"
                                        state={state}
                                      >
                                        <img src="images/design.png" />
                                        Design
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/sales" state={state}>
                                        <img src="images/sales.png" />
                                        Sales
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/estimation" state={state}>
                                        <img src="images/estimation.png" />
                                        Estimation
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/construction" state={state}>
                                        <img src="images/construction_icon.png" />
                                        Construction
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/warranty-dashboard"
                                        state={state}
                                      >
                                        <img src="images/warranty.png" />
                                        Warranty
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/reports" state={state}>
                                        <img src="images/reports.png" />
                                        Reports
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/client-view-config"
                                        state={state}
                                      >
                                        <img src="images/view_config.png" />
                                        Views Config
                                      </Link>
                                    </li>
                                  </ol>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ViewAttachmentsModal}
        onHide={handleViewAttachmentsModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleViewAttachmentsModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>View Attachments</h4>
            </div>
            <div className="FolderForm">
              <div className="ModalBigheading">
                <h3>
                  <span>Quote Request:</span>
                  {AttachmentsData?.documentCode}
                </h3>
              </div>
              <div className="form-group">
                <h4>Files</h4>
                {AttachmentsData?.additionalDocuments?.length > 0
                  ? AttachmentsData?.additionalDocuments?.map((item, i) => {
                      return (
                        <div className="FilesAdded">
                          <label> {`${i + 1}) ${item.blobName}`}</label>
                          <span
                            onClick={() =>
                              handleViewAttachDataModalShow(item?.docUrl)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src="images/file_name.png"
                              title={item.blobName}
                              className="ml-3"
                            />
                          </span>
                        </div>
                      );
                    })
                  : "No Data Available"}
              </div>
              <div className="Button text-center mt-5">
                <button
                  className="Outline"
                  onClick={handleViewAttachmentsModalClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EstimationModule;
