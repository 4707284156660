import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import "../App.css";
import Modal from "react-bootstrap/Modal";
import Loader from "./Loader";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import {
  constructionScheduleAcceptReject,
  getCalendarData,
  getConstructScheduleList,
  getConstructionScheduleVendorPendingList,
  getConstructionSupportDocsList,
  getSupportAddDocs,
  getSupportDeleteDocs,
  getSupportEditDocs,
} from "../redux/action/tradeViewAuthAction";
import TradeViewSidenav from "./trade-view-sidenav";
import {
  getJobByProjectList,
  getProjectByBuilder,
  getTradeView,
} from "../redux/action/tradeViewAuthAction";
import TradeHeader from "./TradeHeader";

const initialState = {
  AddNewSupportingDocsModal: false,
  id: "",
  supportingDocFiless: null,
  SupportingDocumentsListModal: false,
  SupportDocumentDeleteModal: false,
  parenttableId: "",
  docsId: "",
  taskId: "",
  PendingVendorConfirmationModal: false,
  UpdateNewSupportingDocsModal: false,
  NewTaskAssignModal: false,
  companyId: "",
  projectId: "",
  jobId: "",
  constructionId: "",
  AcceptRejectModal: false,
};

const ConstructionScheduleTradeView = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const {
    AddNewSupportingDocsModal,
    id,
    supportingDocFiless,
    SupportingDocumentsListModal,
    SupportDocumentDeleteModal,
    parenttableId,
    docsId,
    taskId,
    PendingVendorConfirmationModal,
    UpdateNewSupportingDocsModal,
    NewTaskAssignModal,
    companyId,
    projectId,
    jobId,
    constructionId,
    AcceptRejectModal,
  } = iState;
  const {
    constructionScheduleLoader,
    constructionScheduleList,
    constructionSupportListLoader,
    constructionSupportDocsList,
    projectByBuilderData,
    jobByProjectData,
    folderHistoryList,
  } = useSelector((state) => state.tradeViewLogin);
  console.log("constructionSupportDocsList", constructionSupportDocsList);

  const compId = JSON.parse(window.localStorage.getItem("tradeViewLogin"));

  const builderIdd = JSON.parse(window.localStorage.getItem("builderId"));
  useEffect(() => {
    dispatch(getProjectByBuilder(builderIdd));
  }, []);

  const handleFileChanges = (event, type) => {
    const file = event?.target?.files[0];
    console.log("file changed", file);
    if (type === "docsUpdate") {
      updateState({ ...iState, supportingDocFiless: file });
    }
  };

  const handleRemoveDocsFile = () => {
    // Reset the 'templateFile' state to null
    updateState({ ...iState, supportingDocFiless: null });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#docsInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleAddNewSupportingDocsModalShow = (id) => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: true,
      id: id,
      supportingDocFiless: "",
    });
  };
  const handleAddNewSupportingDocsModalClose = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: false,
      supportingDocFiless: "",
    });
  };

  const handleSupportDocs = () => {
    let dataa = {
      files: supportingDocFiless,
    };
    dispatch(getSupportAddDocs(compId?.user?.CompanyId, id, dataa)).then(
      (res) => {
        if (res.status === 201) {
          dispatch(getConstructScheduleList(compId?.user?.id));
          dispatch(getConstructionSupportDocsList(docsId));
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            AddNewSupportingDocsModal: false,
            id: "",
            supportingDocFiless: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            AddNewSupportingDocsModal: true,
          });
        }
      }
    );
  };

  const handleSupportingDocumentsListModalShow = (id, taskId) => {
    updateState({
      ...iState,
      SupportingDocumentsListModal: true,
      docsId: id,
      taskId: taskId,
    });
    dispatch(getConstructionSupportDocsList(docsId));
  };
  const handleSupportingDocumentsListModalClose = () => {
    updateState({
      ...iState,
      SupportingDocumentsListModal: false,
    });
  };

  const handleSupportDocumentDeleteModalHideShow = (type, item) => {
    if (type === "show") {
      updateState({
        ...iState,
        SupportDocumentDeleteModal: true,
        id: item.id,
        parenttableId: item.parentTableId,
        SupportingDocumentsListModal: false,
      });
    } else {
      updateState({
        ...iState,
        SupportDocumentDeleteModal: false,
        id: "",
        parenttableId: "",
        SupportingDocumentsListModal: true,
      });
    }
  };

  const handleDeleteSupportDocs = () => {
    let dataa = { parenttableId };
    dispatch(getSupportDeleteDocs(compId?.user?.CompanyId, id, dataa)).then(
      (res) => {
        if (res.status === 200) {
          dispatch(getConstructionSupportDocsList(docsId));
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            SupportDocumentDeleteModal: false,
            id: "",
            SupportingDocumentsListModal: true,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            SupportDocumentDeleteModal: true,
          });
        }
      }
    );
  };

  const handlePendingVendorConfirmationModalHideShow = (type) => {
    if (type === "show") {
      updateState({
        ...iState,
        PendingVendorConfirmationModal: true,
      });
    } else {
      updateState({
        ...iState,
        PendingVendorConfirmationModal: false,
      });
    }
  };

  const handleUpdateNewSupportingDocsModalShow = (item) => {
    updateState({
      ...iState,
      UpdateNewSupportingDocsModal: true,
      id: item.id,
      supportingDocFiless: item.fileName,
      parenttableId: item.parentTableId,
      SupportingDocumentsListModal: false,
    });
  };
  const handleUpdateNewSupportingDocsModalClose = () => {
    updateState({
      ...iState,
      UpdateNewSupportingDocsModal: false,
      supportingDocFiless: "",
      SupportingDocumentsListModal: true,
    });
  };

  const handleEditSupportDocs = () => {
    let dataa = {
      file: supportingDocFiless,
    };
    dispatch(
      getSupportEditDocs(compId?.user?.CompanyId, id, parenttableId, dataa)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getConstructionSupportDocsList(id));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          UpdateNewSupportingDocsModal: false,
          id: "",
          SupportingDocumentsListModal: true,
          parenttableId: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          UpdateNewSupportingDocsModal: true,
        });
      }
    });
  };

  const handleNewTaskAssignModalShow = () => {
    updateState({
      ...iState,
      NewTaskAssignModal: true,
      PendingVendorConfirmationModal: false,
    });
  };

  const handleNewTaskAssignModalClose = () => {
    updateState({
      ...iState,
      NewTaskAssignModal: false,
      PendingVendorConfirmationModal: true,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "projectId") {
      dispatch(getJobByProjectList(builderIdd, value));
      updateState({
        ...iState,
        [name]: value,
        jobId: "",
      });
    } else if (name === "jobId") {
      dispatch(getConstructScheduleList(compId?.user?.id, value));
      dispatch(
        getConstructionScheduleVendorPendingList(compId?.user?.id, value)
      );
      updateState({
        ...iState,
        [name]: value,
      });
    } else {
      updateState({
        ...iState,
        [name]: value,
      });
    }
  };

  const handleAcceptRejectModalShow = (id) => {
    updateState({ ...iState, AcceptRejectModal: true, appointmentId: id });
  };

  const handleAcceptRejectAppointment = (type) => {
    const datta = {
      constructionId,
      ConfirmStatus: type == "accept" ? "ACCEPT" : "REJECT",
    };
    dispatch(constructionScheduleAcceptReject(datta)).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AcceptRejectModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, AcceptRejectModal: true });
      }
    });
  };
  const handleAcceptRejectModalClose = () => {
    updateState({ ...iState, AcceptRejectModal: false });
  };
  //===============================calendar================

  const calendarRef = useRef(null);

  const [view, setView] = useState("dayGridMonth");
  const [events, setEvents] = useState([]);
  const handleViewChange = (newView) => {
    setView(newView);
  };

  useEffect(() => {
    // Fetch or generate your dynamic events data based on the selected view (date, week, or month)
    const fetchData = async () => {
      // Replace this with your actual data fetching logic
      const data = await fetchEventData(view);
      console.log("data", data);
      setEvents(data);
    };

    fetchData();
  }, [view]);

  const fetchEventData = async (currentView) => {
    if (currentView === "dayGridWeek") {
      const weekData = { vendorId: compId?.user?.id, timeFrame: "Week" };
      const weekEvents = await fetchDataForDateRange(weekData);
      return weekEvents;
    }

    if (currentView === "dayGridMonth") {
      const monthData = { vendorId: compId?.user?.id, timeFrame: "Month" };
      const monthEvents = await fetchDataForDateRange(monthData);
      return monthEvents;
    }

    // Example: Fetch events for the current day
    const dayData = { vendorId: compId?.user?.id, timeFrame: "Day" };
    const dayEvents = await fetchDataForDate(dayData);
    return dayEvents;
  };

  const fetchDataForDateRange = async ({ timeFrame }) => {
    const data = { vendorId: compId?.user?.id, timeFrame };
    return fetchData(data);
  };

  const fetchDataForDate = async ({ timeFrame }) => {
    const data = { vendorId: compId?.user?.id, timeFrame };
    return fetchData(data);
  };

  const fetchData = async (data) => {
    dispatch(getCalendarData(data)).then((res) => {
      if (res.status === 201) {
        const eventsData =
          res?.data?.details?.map((item) => ({
            title: item.taskName,
            start: item.startDate, // Assuming 'date' is the property containing the event date
            description: item.taskNumber, // Assuming 'date' is the property containing the event date
          })) || [];

        // Set the events state

        console.log("date eventsData", eventsData);
        setEvents(eventsData);
        toast.success("Find data Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        // toast.error(res.data.message, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      }
    });
  };

  const handleDateSet = (dateInfo) => {
    console.log("dateInfo");
    // Handle "today" button click by resetting the view to the current date
    if (dateInfo.startStr === dateInfo.endStr) {
      setView("dayGridMonth"); // You can set it to the default view you prefer
    }
  };

  //===========================================================================================//
  const renderEventContent = (eventInfo) => {
    const event = eventInfo.event;

    return (
      <>
        <b>{eventInfo.timeText}</b>
        <p>
          <strong>{event.title}</strong>
        </p>
        <p>
          <em>Start Date:</em> {moment(event.startDate).format("YYYY-MM-DD")}
        </p>
        <p>
          <em>Description:</em> {event.extendedProps.description}
        </p>
      </>
    );
  };
  return (
    <div>
      {/* <Header /> */}
      <TradeHeader/>
      <TradeViewSidenav />
      <div className="WrapperAreaClient">
        <div className="WrapperBoxClient">
          <div className="TodosArea">
            <div className="TodosHeader align-items-start">
              <div className="BuilderBox">
                <h4>Project</h4>
                <div className="SelectBuilder">
                  <select
                    className="CommonSelect"
                    name="projectId"
                    value={projectId}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Project</option>
                    {projectByBuilderData?.data?.length > 0 &&
                      projectByBuilderData?.data?.map((item, i) => {
                        return (
                          <option value={item.id} key={i}>
                            {item.projectName}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              <div className="BuilderBox">
                <h4>Job</h4>
                <div className="SelectBuilder">
                  <select
                    className="CommonSelect"
                    name="jobId"
                    value={jobId}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Jobs</option>
                    {jobByProjectData?.data?.length > 0 &&
                      jobByProjectData?.data?.map((item, i) => {
                        return (
                          <option value={item.id} key={i}>
                            {item.jobType}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="d-flex">
                <div className="TodosButtons mt-0 d-flex">
                  <a
                    onClick={() =>
                      handlePendingVendorConfirmationModalHideShow("show")
                    }
                  >
                    <img src="images/pending_trades_day.png" />
                    Pending Confirmation<span>3</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="CommonTabs mt-3">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#TaskList"
                  >
                    <span>
                      <img src="images/task_list_icon.png" />
                    </span>
                    Task List
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#Calendar">
                    <span>
                      <img src="images/icon-23.png" />
                    </span>
                    Calendar
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="tab-content">
            <div className="tab-pane fade show active" id="TaskList">
              <div className="Tableheader">
                <h6>Construction Schedule Tasks</h6>
                <div className="NewActions">
                  <a  title="filter">
                    <img src="images/icon-64.png" />
                  </a>
                  <a title="pdf">
                    <img src="images/icon-53.png" />
                  </a>
                  <a title="settings">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
              <div className="TableList NewTableList BlueHeader TableHeightScroll">
                <table>
                  <thead>
                    <tr>
                      <th>Job Id</th>
                      <th>WBS</th>
                      <th>Task Name</th>
                      <th>Address</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Duration</th>
                      <th>Notes</th>
                      <th>Status</th>
                      <th>Progress</th>
                      <th>TODO</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {constructionScheduleList?.details?.length > 0 ? (
                      constructionScheduleList?.details?.map((item, i) => {
                        return (
                          <tr className="BorderCyan">
                            <td>{item.SheduleSubTask?.jobId}</td>
                            <td>{item.SheduleSubTask?.taskNumber}</td>
                            <td>{item.SheduleSubTask?.taskName}</td>
                            <td>
                              <div>
                                {item.vendorAddress ?? "N/A"}
                                <img
                                  src="images/location.png"
                                  className="ml-2"
                                />
                              </div>
                            </td>
                            <td>
                              {moment(item.SheduleSubTask?.startDate).format(
                                "YYYY-MM-DD"
                              )}
                            </td>
                            <td>
                              {moment(item.SheduleSubTask?.endDate).format(
                                "YYYY-MM-DD"
                              )}
                            </td>
                            <td>{item.SheduleSubTask?.duration} day</td>
                            <td>
                              <div className="Actions">
                                <a
                                  onClick={() =>
                                    handleAddNewSupportingDocsModalShow(
                                      item.taskId
                                    )
                                  }
                                >
                                  <img src="images/notes_icon.png" />
                                </a>
                                <a
                                  onClick={() =>
                                    handleSupportingDocumentsListModalShow(
                                      item.id,
                                      item.taskId
                                    )
                                  }
                                >
                                  <span className="Count">3</span>
                                </a>
                              </div>
                            </td>
                            <td>{item.ConfirmStatus}</td>
                            <td>{item.SheduleSubTask?.progress}</td>
                            <td />
                            <div className="TableThreeDots">
                              <div className="dropdown show">
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="dropdown"
                                  aria-expanded="true"
                                >
                                  <span>
                                    <img src="images/three-dots.png" />
                                  </span>
                                </a>
                                <div
                                  className="dropdown-menu show"
                                  aria-labelledby="dropdownMenuOffset"
                                  x-placement="bottom-start"
                                >
                                  {/* <li><a class="dropdown-item" href="javascript:void(0);" data-toggle="modal" data-target="#ViewQuoteRequestVendorReturn" ><img src="images/view_pos.png">View P.O.</a></li> */}
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        handleAcceptRejectModalShow(item.id)
                                      }
                                    >
                                      <img src="images/calendar_arrow.png" />
                                      Trades Day Schedule
                                    </a>
                                  </li>
                                </div>
                              </div>
                            </div>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="10" className="text-center">
                          <p>No Data found.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="tab-pane fade" id="Calendar">
              <div className="Tableheader">
                <h6>Change orders</h6>
                <div className="NewActions">
                  <a  title="filter">
                    <img src="images/icon-64.png" />
                  </a>
                  <a title="pdf">
                    <img src="images/icon-53.png" />
                  </a>
                  <a title="settings">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
              <div className="CalendarArea">
                <FullCalendar
                  ref={calendarRef}
                  // weekends={true}
                  events={events}
                  eventContent={renderEventContent}
                  headerToolbar={{
                    start: "today prev next",
                    center: "title",
                    end: "dayGridMonth dayGridWeek dayGridDay",
                  }}
                  plugins={[dayGridPlugin]}
                  views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
                  initialView="dayGridDay"
                  datesSet={(info) => {
                    handleViewChange(info.view.type);
                    handleDateSet(info);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="ModalBox fade SmallModal"
        show={AddNewSupportingDocsModal}
        onHide={handleAddNewSupportingDocsModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a
              className="CloseModal"
              onClick={handleAddNewSupportingDocsModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4> New Supporting Docs</h4>
              </div>
              <div className="JobTitleArea">
                <h3>Supporting Documents #2</h3>
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Origin</span> #185
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Item:</span> #143
                  </h6>
                </div>
              </div>
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input
                    type="file"
                    id="docsInput"
                    onChange={(e) => handleFileChanges(e, "docsUpdate")}
                  />
                </div>
              </div>
              <div className="FilesAddedTitle">
                <h4>Files</h4>
              </div>
              {supportingDocFiless && (
                <div className="FilesAdded">
                  <label>{supportingDocFiless?.name}</label>
                  <span>
                    <img src="images/fileview.png" />
                  </span>
                  <span onClick={handleRemoveDocsFile}>
                    <img src="images/Cross.png" className="cross" />
                  </span>
                </div>
              )}

              <div className="Button mt-3">
                <button className="Create" onClick={handleSupportDocs}>
                  Add Documents
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="ModalBox fade LargeModal show"
        show={SupportingDocumentsListModal}
        onHide={handleSupportingDocumentsListModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a
              className="CloseModal"
              onClick={handleSupportingDocumentsListModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Supporting Documents List</h4>
              </div>
              <div className="JobTitleArea">
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                  <div>
                    <a
                      onClick={() =>
                        handleAddNewSupportingDocsModalShow(taskId)
                      }
                    >
                      <img src="images/notebook.png" />
                    </a>
                  </div>
                </div>
                <div className="JobBox">
                  <h6>
                    <span className="text-red">Origin</span> #185
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span className="text-red">Item:</span> #143
                  </h6>
                </div>
              </div>
              <div className="NotesAreaMain">
                <div className="NotesArea mt-4">
                  <h6>Supporting Documents List</h6>
                  <div>
                    <a href="javascript:void(0);">
                      <img src="images/pdf.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/download.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/setting.png" />
                    </a>
                  </div>
                </div>
                <div className="TableList">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Sent by</th>
                        <th>Date</th>
                        <th>Notes</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {constructionSupportListLoader === false ? (
                        constructionSupportDocsList?.details?.length > 0 ? (
                          constructionSupportDocsList.details.map(
                            (data, ind) => (
                              <tr key={ind}>
                                <td>{ind + 1}</td>
                                <td>{data.sendBy ?? "N/A"}</td>
                                <td>
                                  {moment(data.createdAt).format("YYYY-MM-DD")}
                                </td>
                                <td className="Inspection">
                                  {data.notes ?? "N/A"}
                                </td>
                                <td>
                                  <div className="Actions">
                                    <a
                                      onClick={() =>
                                        handleUpdateNewSupportingDocsModalShow(
                                          data
                                        )
                                      }
                                    >
                                      <img
                                        src="images/Action-1.png"
                                        alt="Action 1"
                                      />
                                    </a>
                                    <a
                                      onClick={() =>
                                        handleSupportDocumentDeleteModalHideShow(
                                          "show",
                                          data
                                        )
                                      }
                                    >
                                      <img
                                        src="images/delete.png"
                                        alt="Delete"
                                      />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td colSpan="5">
                              <p>No Data found.</p>
                            </td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td colSpan="5">
                            <Loader />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="Button mt-5">
                <button
                  className="Create FolderPermissionId"
                  onClick={() => handleAddNewSupportingDocsModalShow(taskId)}
                >
                  Add New Supporting Docs
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={SupportDocumentDeleteModal}
        onHide={() => handleSupportDocumentDeleteModalHideShow("hide", "")}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={() =>
                  handleSupportDocumentDeleteModalHideShow("hide", "")
                }
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Delete</h4>
                </div>
                <div className="FolderForm">
                  <div className="my-5">
                    <h6>Are you sure you want to delete this ?</h6>
                  </div>
                  <div className="Button d-flex justify-content-between">
                    <button
                      className="Create"
                      onClick={handleDeleteSupportDocs}
                    >
                      Delete
                    </button>
                    <button
                      className="Outline"
                      onClick={() =>
                        handleSupportDocumentDeleteModalHideShow("hide", "")
                      }
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={PendingVendorConfirmationModal}
        onHide={() => handlePendingVendorConfirmationModalHideShow("hide", "")}
        className="ModalBox modal fade LargeModal show"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={() =>
                  handlePendingVendorConfirmationModalHideShow("hide", "")
                }
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>
                    Construction Schedule Tasks Pending Vendor Confirmation
                  </h4>
                </div>
                <div className="TableList CommonHeading">
                  <table>
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Type</th>
                        <th>Data Received</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Construction Schedule Task 1.3.1.1.6 -Taping</td>
                        <td>Construction Schedule</td>
                        <td>04/25/2023</td>
                        <td>
                          <div className="Actions">
                            <a onClick={() => handleAcceptRejectModalShow()}>
                              <img src="images/calendar_arrow.png" />
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="ModalBox fade SmallModal"
        show={UpdateNewSupportingDocsModal}
        onHide={handleUpdateNewSupportingDocsModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a
              className="CloseModal"
              onClick={handleUpdateNewSupportingDocsModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4> Update New Supporting Docs</h4>
              </div>
              <div className="JobTitleArea">
                <h3>Supporting Documents #2</h3>
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Origin</span> #185
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Item:</span> #143
                  </h6>
                </div>
              </div>
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input
                    type="file"
                    id="docsInput"
                    onChange={(e) => handleFileChanges(e, "docsUpdate")}
                  />
                </div>
              </div>
              <div className="FilesAddedTitle">
                <h4>Files</h4>
              </div>
              {supportingDocFiless && (
                <div className="FilesAdded">
                  <label>{supportingDocFiless?.name}</label>
                  <span>
                    <img src="images/fileview.png" />
                  </span>
                  <span onClick={handleRemoveDocsFile}>
                    <img src="images/Cross.png" className="cross" />
                  </span>
                </div>
              )}

              <div className="Button mt-3">
                <button className="Create" onClick={handleEditSupportDocs}>
                  Update Documents
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={AcceptRejectModal}
        onHide={handleAcceptRejectModalClose}
        className="ModalBox modal fade SmallModal show NewTaskAssigned1Background"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleAcceptRejectModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBoxBrand">
              <h4>New Task Assigned</h4>
              <a href="">
                <img src="images/logo-white.png" />
              </a>
            </div>
            <div className="FolderForm">
              <div className="NewTaskAssignModalArea">
                <h6>Vendor</h6>
                <p>
                  A new task has been assigned to you by Builder. Please
                  <br />
                  confirm execution date
                </p>
                <div className="NewTaskAssignModalAreaBox">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div>
                            Location: <img src="images/location.png" />
                          </div>
                        </td>
                        <td>26th Zalda Cr, Calgary</td>
                      </tr>
                      <tr>
                        <td>Client:</td>
                        <td>Luke Anderson</td>
                      </tr>
                      <tr>
                        <td>Date Suggested:</td>
                        <td>
                          <input type="date" />
                        </td>
                      </tr>
                      <tr>
                        <td>Duration:</td>
                        <td>
                          <input type="text" placeholder="1 day" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-between my-5">
                  <div className="dropdown modalButtonDropdown">
                    <button
                      className="BrandBtnGreen2"
                      onClick={() => handleAcceptRejectAppointment("accept")}
                    >
                      Approve
                      {/* <span>
                <img src="images/down-arrow-white.png" />
              </span> */}
                    </button>
                  </div>
                  <div className="Button">
                    <button
                      className="Create"
                      onClick={() => handleAcceptRejectAppointment("reject")}
                    >
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ConstructionScheduleTradeView;
