import { itineraryAction } from "../actionTypes";
import { Url } from "../../Config/Config";
import axios from "axios";
import { isLoggedIn } from "../../utils/tokenCheck";
import { multiPartData, multiPartDatas } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));


//==================================addInternity=============================================

export const internityAddInitiate = () => ({
    type: itineraryAction.ITINERTY_ADD_INITIATE,
  });
  
  export const internityAddSuccess = (data) => ({
    type: itineraryAction.ITINERTY_ADD_SUCCESS,
    payload: data,
  });
  
  export const internityAddFailure = (data) => ({
    type: itineraryAction.ITINERTY_ADD_FAILURE,
    payload: data,
  });
  
  export const getInternityAdd = (payload) => async (dispatch) => {
    
    try {
      const token = isLoggedIn("adminData1");
      dispatch(internityAddInitiate(payload));
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/itineraries`,payload,{
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 201) {
        dispatch(internityAddSuccess(response.data));
      } else {
        dispatch(internityAddFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(internityAddFailure(err));
      return err.response;
    }
  };

//==================================listInternity=============================================

export const internityListInitiate = () => ({
    type: itineraryAction.ITINERTY_LIST_INITIATE,
  });
  
  export const internityListSuccess = (data) => ({
    type: itineraryAction.ITINERTY_LIST_SUCCESS,
    payload: data,
  });
  
  export const internityListFailure = (data) => ({
    type: itineraryAction.ITINERTY_LIST_FAILURE,
    payload: data,
  });
  
  export const getInternityList = (payload) => async (dispatch) => {
    
    try {
      const token = isLoggedIn("adminData1");
      dispatch(internityListInitiate(payload));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/itineraries?startDate=${payload.startDate}&endDate=${payload.endDate}`,{
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        dispatch(internityListSuccess(response.data));
      } else {
        dispatch(internityListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(internityListFailure(err));
      return err.response;
    }
  };
//==================================iternarayDelete========================================

export const deleteItinertyInitiate = () => ({
  type: itineraryAction.ITINERTY_DELETE_INITIATE,
});

export const deleteItinertySuccess = (data) => ({
  type: itineraryAction.ITINERTY_DELETE_SUCCESS,
  payload: data,
});

export const deleteItinertyFailure = (data) => ({
  type: itineraryAction.ITINERTY_DELETE_FAILURE,
  payload: data,
});

export const getItinertyDelete = (TaskId) => async (dispatch) => {
  // debugger
  try {
    const token = isLoggedIn("adminData1");
    const { TaskId: extractedDocId } = TaskId;
    dispatch(deleteItinertyInitiate(extractedDocId));
    const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/itineraries/${extractedDocId}`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(deleteItinertySuccess(response.data));
    } else {
      dispatch(deleteItinertyFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(deleteItinertyFailure(err));
    return err.response;
  }
};

//---------------------------------ASSIGNsTAFFF---------------------------------------------------
export const assignStaffInitiate = () => ({
  type: itineraryAction.ASSIGN_STAFF_INITIATE,
});

export const assignStaffSuccess = (data) => ({
  type: itineraryAction.ASSIGN_STAFF_SUCCESS,
  payload: data,
});

export const assignStaffFailure = (data) => ({
  type: itineraryAction.ASSIGN_STAFF_FAILURE,
  payload: data,
});

export const getAssignStaff = (taskId,payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(assignStaffInitiate(payload));
    const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/itineraries/${taskId}/assign-staff`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(assignStaffSuccess(response.data));
    } else {
      dispatch(assignStaffFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(assignStaffFailure(err));
    return err.response;
  }
};


//---------------------------------UPDAteReminder---------------------------------------------------
export const updateReminderInitiate = () => ({
  type: itineraryAction.UPDATE_REMINDER_INITIATE,
});

export const updateReminderSuccess = (data) => ({
  type: itineraryAction.UPDATE_REMINDER_SUCCESS,
  payload: data,
});

export const updateReminderFailure = (data) => ({
  type: itineraryAction.UPDATE_REMINDER_FAILURE,
  payload: data,
});

export const getUpdateReminder = (itId,payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(updateReminderInitiate(payload));
    const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/itineraries/${itId}/set-reminder`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(updateReminderSuccess(response.data));
    } else {
      dispatch(updateReminderFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(updateReminderFailure(err));
    return err.response;
  }
};

//============================ITERnarayUpdate================================

export const iternarayUpdateInitiate = () => ({
  type: itineraryAction.ITINERTY_UPDATE_INITIATE,
});

export const iternarayUpdateSuccess = (data) => ({
  type: itineraryAction.ITINERTY_UPDATE_SUCCESS,
  payload: data,
});

export const iternarayUpdateFailure = (data) => ({
  type: itineraryAction.ITINERTY_UPDATE_FAILURE,
  payload: data,
});

export const getIternarayUpdate = (taskId,payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(iternarayUpdateInitiate(payload));
    const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/itineraries/${taskId}`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(iternarayUpdateSuccess(response.data));
    } else {
      dispatch(iternarayUpdateFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(iternarayUpdateFailure(err));
    return err.response;
  }
};