import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { DocumentKeyListAction } from "../../../redux/action/dropdownAction";
import { ConfiguredNotificationAction } from "../../../redux/action/adminNotificationAction";
import { toast } from "react-toastify";
const initialState = {
  keys: [],
  keysName: [],
  message: "",
  stopper: false,
  userId: "",
  reminder: "2023-01-02",
  assignVendor: true,
  itineraryType: "any",
  includeVendor: false,
  includeClient: false,
  errors: {},
};
const BellConfigure = ({
  BellConfigureModal,
  SetBellConfigureModal,
  notificationId,
  element,
}) => {
  const [iState, updateState] = useState(initialState);
  const {
    includeClient,
    includeVendor,
    assignVendor,
    itineraryType,
    keys,
    keysName,
    userId,
    message,
    errors,
  } = iState;

  const dispatch = useDispatch();
  const { documentkeyList } = useSelector((state) => state.dropdownReducer);

  const handleBellConfigModalClose = () => {
    SetBellConfigureModal(false);
  };

  useEffect(() => {
    if (BellConfigureModal) {
      dispatch(DocumentKeyListAction());
    }
  }, [BellConfigureModal]);

  const handleAddKey = (e) => {
    e.preventDefault();
    let temp = keys;
    temp.push(userId);
    let tempForName = keysName;

    let name = documentkeyList?.filter((key) => key?.id == userId);

    tempForName.push(name[0].label);

    updateState({ ...iState, keys: temp, userId: "", keysName: tempForName });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateState({ ...iState, stopper: true });
    let formIsValid = true;

    if (formIsValid) {
      dispatch(
        ConfiguredNotificationAction(notificationId, {
          assignVendor: assignVendor,
          itineraryType: "any",
          includeVendor: includeVendor,
          includeClient: includeClient,
          notificationTemplate: [
            {
              message: keysName.join("$").concat("_", message),
              keys: keys,
              for: "BODY",
            },
          ],
        })
      )
        .then((res) => {
          if (res.status === 200) {
            toast.success("Users Assigned Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            updateState({
              ...iState,
              stopper: false,
              userId: "",
              assignUsers: [],
            });
            SetBellConfigureModal(false);

            // dispatch(getNestedNotificationAction(element?.id)).then((res) => {
            //   setC(res.data);
            // });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  return (
    <Modal show={BellConfigureModal} className="ModalBox MediumModal BgClass">
      <a
        onClick={handleBellConfigModalClose}
        class="CloseModal ClosePermissionId"
      >
        ×
      </a>
      <div class="FormArea">
        <div class="ModalTitleBox">
          <h4>Configure Notification Details</h4>
        </div>
        <div class="FolderForm GreenSwitch">
          <form>
            <div class="form-group">
              <h6>Event</h6>
              <h6 class="fw-400 fw-14">{element?.eventName}</h6>
            </div>
            <div class="form-group">
              <h6>Notification Type</h6>
              <p class="fw-400 fw-16">Bell Icon</p>
            </div>
            <div class="row">
              <div class="col-md-5">
                <div>
                  <h6 class="fw-20 fw-600">Notification Message</h6>
                </div>
                <div class="form-group">
                  <h6>
                    Insert keys{" "}
                    <span class="ml-3">
                      <img src="images/insert_key_24.png" />
                    </span>{" "}
                  </h6>
                  <div class="CommonSelectBox w-100">
                    <select
                      onChange={(e) =>
                        updateState({ ...iState, userId: e.target.value })
                      }
                    >
                      <option value="">Select document key</option>
                      {documentkeyList?.map((key) => (
                        <option value={key?.id}>{key?.label}</option>
                      ))}
                    </select>
                  </div>
                  {userId && (
                    <div class="Button my-3">
                      <button class="Create px-3" onClick={handleAddKey}>
                        Add Key
                      </button>
                    </div>
                  )}
                  <input
                    type="text"
                    onChange={(e) =>
                      updateState({ ...iState, message: e.target.value })
                    }
                    class="form-control"
                    placeholder="Insert Notification text"
                  />
                </div>
              </div>
            </div>

            <div class="Button d-flex justify-content-between mt-4">
              <button class="Create" onClick={handleSubmit}>
                Save
              </button>
              <button
                class="Outline"
                data-dismiss="modal"
                onClick={handleBellConfigModalClose}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default BellConfigure;
