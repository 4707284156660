
import { adminUserAction } from "../actionTypes";
import { Url } from "../../Config/Config";
import axios from "axios";
import { isLoggedIn } from "../../utils/tokenCheck";
import { multiPartData, multiPartDatas } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));


//===========================createUser===============================

export const createUserInitiate = () => ({
    type: adminUserAction.CREATE_USER_INITIATE,
  });
  
  export const createUserSuccess = (data) => ({
    type: adminUserAction.CREATE_USER_SUCCESS,
    payload: data,
  });
  
  export const createUserFailure = (data) => ({
    type: adminUserAction.CREATE_USER_FAILURE,
    payload: data,
  });
  
  export const createUsers = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        dispatch(createUserInitiate());
        const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/users`,payload,{
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 201) {
          dispatch(createUserSuccess(response.data));
        } else {
          dispatch(createUserFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(createUserFailure(err));
        return err.response
      }
    };

//===============================================role-list=======================


export const roleListInitiate = () => ({
    type: adminUserAction.ROLE_LIST_INITIATE,
  });
  
  export const roleListSuccess = (data) => ({
    type: adminUserAction.ROLE_LIST_SUCCESS,
    payload: data,
  });
  
  export const roleListFailure = (data) => ({
    type: adminUserAction.ROLE_LIST_FAILURE,
    payload: data,
  });
  
  export const getAdminRoleList = () => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        dispatch(roleListInitiate());
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/roles`,{
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(roleListSuccess(response.data));
        } else {
          dispatch(roleListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(roleListFailure(err));
        return err.response
      }
    };

//===========================userList===========================


export const userListInitiate = () => ({
    type: adminUserAction.USER_LIST_INITIATE,
  });
  
  export const userListSuccess = (data) => ({
    type: adminUserAction.USER_LIST_SUCCESS,
    payload: data,
  });
  
  export const userListFailure = (data) => ({
    type: adminUserAction.USER_LIST_FAILURE,
    payload: data,
  });
  
  export const getUserList = () => async (dispatch) => {
    
      try {
        const token = isLoggedIn("adminData1");
        const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        dispatch(userListInitiate());
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/users`,{
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(userListSuccess(response.data));
        } else {
          dispatch(userListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(userListFailure(err));
        return err.response
      }
    };

//============================delete--user================

export const userDeleteInitiate = () => ({
    type: adminUserAction.USER_DELETE_INITIATE,
  });
  
  export const userDeleteSuccess = (data) => ({
    type: adminUserAction.USER_DELETE_SUCCESS,
    payload: data,
  });
  
  export const userDeleteFailure = (data) => ({
    type: adminUserAction.USER_DELETE_FAILURE,
    payload: data,
  });
  
  export const userDelete = (userId) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        dispatch(userDeleteInitiate());
        const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/users/${userId}`,{
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(userDeleteSuccess(response.data));
        } else {
          dispatch(userDeleteFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(userDeleteFailure(err));
        return err.response
      }
    };

//==============================lock=====================

export const lockUserInitiate = () => ({
    type: adminUserAction.LOCK_USER_INITIATE,
  });
  
  export const lockUserSuccess = (data) => ({
    type: adminUserAction.LOCK_USER_SUCCESS,
    payload: data,
  });
  
  export const lockUserFailure = (data) => ({
    type: adminUserAction.LOCK_USER_FAILURE,
    payload: data,
  });
  
  export const lockUser = (userId) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        dispatch(lockUserInitiate());
        const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/users/${userId}/lock`,{},{
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(lockUserSuccess(response.data));
        } else {
          dispatch(lockUserFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(lockUserFailure(err));
        return err.response
      }
    };

//==========================USER-HISTORY=====================


export const userHistoryInitiate = () => ({
    type: adminUserAction.USER_HISTORY_INITIATE,
  });
  
  export const userHistorySuccess = (data) => ({
    type: adminUserAction.USER_HISTORY_SUCCESS,
    payload: data,
  });
  
  export const userHistoryFailure = (data) => ({
    type: adminUserAction.USER_HISTORY_FAILURE,
    payload: data,
  });
  
  export const getUserHistory = (userId,payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        dispatch(userHistoryInitiate());
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/users/${userId}/history?fromDate=${payload.fromDate}&toDate=${payload.toDate}`,{
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(userHistorySuccess(response.data));
        } else {
          dispatch(userHistoryFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(userHistoryFailure(err));
        return err.response
      }
    };
//============================unlock===========================


export const unlockUserInitiate = () => ({
    type: adminUserAction.UNLOCK_USER_INITIATE,
  });
  
  export const unlockUserSuccess = (data) => ({
    type: adminUserAction.UNLOCK_USER_SUCCESS,
    payload: data,
  });
  
  export const unlockUserFailure = (data) => ({
    type: adminUserAction.UNLOCK_USER_FAILURE,
    payload: data,
  });
  
  export const unLockUser = (userId) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        dispatch(unlockUserInitiate());
        const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/users/${userId}/active`,{},{
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(unlockUserSuccess(response.data));
        } else {
          dispatch(unlockUserFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(unlockUserFailure(err));
        return err.response
      }
    };

//============================update--user================


export const updateUserInitiate = () => ({
  type: adminUserAction.USER_UPDATE_INITIATE,
});

export const updateUserSuccess = (data) => ({
  type: adminUserAction.USER_UPDATE_SUCCESS,
  payload: data,
});

export const updateUserFailure = (data) => ({
  type: adminUserAction.USER_UPDATE_FAILURE,
  payload: data,
});

export const updateUsers = (userId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(updateUserInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/users/${userId}`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateUserSuccess(response.data));
      } else {
        dispatch(updateUserFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateUserFailure(err));
      return err.response
    }
  };

//=============================create--role-------------------


export const createRoleInitiate = () => ({
  type: adminUserAction.CREATE_ROLE_INITIATE,
});

export const createRoleSuccess = (data) => ({
  type: adminUserAction.CREATE_ROLE_SUCCESS,
  payload: data,
});

export const createRoleFailure = (data) => ({
  type: adminUserAction.CREATE_ROLE_FAILURE,
  payload: data,
});

export const createRoles = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(createRoleInitiate());
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/roles`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(createRoleSuccess(response.data));
      } else {
        dispatch(createRoleFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createRoleFailure(err));
      return err.response
    }
  };

//============================UPDATE--ROLE===============

export const updateRoleInitiate = () => ({
  type: adminUserAction.UPDATE_ROLE_INITIATE,
});

export const updateRoleSuccess = (data) => ({
  type: adminUserAction.UPDATE_ROLE_SUCCESS,
  payload: data,
});

export const updateRoleFailure = (data) => ({
  type: adminUserAction.UPDATE_ROLE_FAILURE,
  payload: data,
});

export const updateRoles = (roleId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(updateRoleInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/roles/${roleId}`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateRoleSuccess(response.data));
      } else {
        dispatch(updateRoleFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateRoleFailure(err));
      return err.response
    }
  };

//=============================delte--role======================

export const deleteRoleInitiate = () => ({
  type: adminUserAction.DELETE_ROLE_INITIATE,
});

export const deleteRoleSuccess = (data) => ({
  type: adminUserAction.DELETE_ROLE_SUCCESS,
  payload: data,
});

export const  deleteRoleFailure = (data) => ({
  type: adminUserAction.DELETE_ROLE_FAILURE,
  payload: data,
});

export const delteRoles = (roleId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(deleteRoleInitiate());
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/roles/${roleId}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(deleteRoleSuccess(response.data));
      } else {
        dispatch(deleteRoleFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(deleteRoleFailure(err));
      return err.response
    }
  };

//=============================reset==================

export const ibuildUserResetPasswordInitiate = () => ({
  type: adminUserAction.IBUILD_USER_RESET_PASSWORD_INITIATE,
});

export const ibuildUserResetPasswordSuccess = (data) => ({
  type: adminUserAction.IBUILD_USER_RESET_PASSWORD_SUCCESS,
  payload: data,
});

export const  ibuildUserResetPasswordFailure = (data) => ({
  type: adminUserAction.IBUILD_USER_RESET_PASSWORD_FAILURE,
  payload: data,
});

export const ibuildUserResetPassword = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(ibuildUserResetPasswordInitiate());
      const response = await axios.put(`${Url}ibuild-user/send-reset`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(ibuildUserResetPasswordSuccess(response.data));
      } else {
        dispatch(ibuildUserResetPasswordFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(ibuildUserResetPasswordFailure(err));
      return err.response
    }
  };





  //============================SEND TEXT===============

export const sendTextInitiate = () => ({
  type: adminUserAction.UPDATE_ROLE_INITIATE,
});

export const sendTextSuccess = (data) => ({
  type: adminUserAction.UPDATE_ROLE_SUCCESS,
  payload: data,
});

export const sendTextFailure = (data) => ({
  type: adminUserAction.UPDATE_ROLE_FAILURE,
  payload: data,
});

export const sendTextAction = (userId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(sendTextInitiate());
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/users/${userId}/send-sms`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(sendTextSuccess(response.data));
      } else {
        dispatch(sendTextFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(sendTextFailure(err));
      return err.response
    }
  };




  //===========================userList===========================


export const userDetailsInitiate = () => ({
  type: adminUserAction.USER_DETAILS_INITIATE,
});

export const userDetailsSuccess = (data) => ({
  type: adminUserAction.USER_DETAILS_SUCCESS,
  payload: data,
});

export const userDetailsFailure = (data) => ({
  type: adminUserAction.USER_DETAILS_FAILURE,
  payload: data,
});

export const UserDetailsAction = (id) => async (dispatch) => {
  
    try {
      const token = isLoggedIn("adminData1");
      dispatch(userDetailsInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/users/${id}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(userDetailsSuccess(response.data));
      } else {
        dispatch(userDetailsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(userDetailsFailure(err));
      return err.response
    }
  };
//====================ROLES==FORM=======================


export const rolesFormInitiate = () => ({
  type: adminUserAction.ROLES_FORM_INITIATE,
});

export const rolesFormSuccess = (data) => ({
  type: adminUserAction.ROLES_FORM_SUCCESS,
  payload: data,
});

export const  rolesFormFailure = (data) => ({
  type: adminUserAction.ROLES_FORM_FAILURE,
  payload: data,
});

export const getRolesForm = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(rolesFormInitiate());
      const response = await axios.get(`${Url}/companies/${iBuildLocalData?.user?.CompanyId}/roles-form`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(rolesFormSuccess(response.data));
      } else {
        dispatch(rolesFormFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(rolesFormFailure(err));
      return err.response
    }
  };

//========================GET---PROFORMA--SETTING========================

export const getProformaSettingInitiate = () => ({
  type: adminUserAction.GET_PROFORMA_SETTING_INITIATE,
});

export const getProformaSettingSuccess = (data) => ({
  type: adminUserAction.GET_PROFORMA_SETTING_SUCCESS,
  payload: data,
});

export const  getProformaSettingFailure = (data) => ({
  type: adminUserAction.GET_PROFORMA_SETTING_FAILURE,
  payload: data,
});

export const getProformaSetting = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(getProformaSettingInitiate());
      const response = await axios.get(`${Url}/companies/${iBuildLocalData?.user?.CompanyId}/proforma-settings`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getProformaSettingSuccess(response.data.data));
      } else {
        dispatch(getProformaSettingFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getProformaSettingFailure(err));
      return err.response
    }
  };
//===========================update--proforma--setting=============
export const updateProformaSettingInitiate = () => ({
  type: adminUserAction.UPDATE_PROFORMA_SETTING_INITIATE,
});

export const updateProformaSettingSuccess = (data) => ({
  type: adminUserAction.UPDATE_PROFORMA_SETTING_SUCCESS,
  payload: data,
});

export const  updateProformaSettingFailure = (data) => ({
  type: adminUserAction.UPDATE_PROFORMA_SETTING_FAILURE,
  payload: data,
});

export const updateProformaSetting = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      dispatch(updateProformaSettingInitiate());
      const response = await axios.put(`${Url}/companies/${iBuildLocalData?.user?.CompanyId}/proforma-settings`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateProformaSettingSuccess(response.data));
      } else {
        dispatch(updateProformaSettingFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateProformaSettingFailure(err));
      return err.response
    }
  };