import { estimationActionTypes } from "../actionTypes";
const initialState = {
  loader: false,
reasonListData:[],
blockListData:[],
unitListData:[],
profileListData:[],
estimationUnitListData:[],
builderSigneeListData:[],
quoteRequestListData:[],
billListData:[],
budgetListData:[],
backChargeListData: [],
loaderBackCharge:false,
quoteRequestCostBreakDownDetails:[],
purchaseListData:[],
supplierCreditListData:[],
fieldPurchaseOrderListData:[],
modalBudgetChangeListData:[],
scopeWorkIdListData:[],
estimationDocumentListData:[],
relatedDocumentsList:[],
backChargeRelatedDocumentsList:[],
messageList:[],
estimationSettingData:{},
getReleadesConstList:[]
};

export default function estimationReducer(state = initialState, { type, payload }) {
  switch (type) {
    case estimationActionTypes.REASON_LIST_INITIATE: {
        return {
          ...state,
          reasonListData: [],
          loader:true
        };
      }
      case estimationActionTypes.REASON_LIST_SUCCESS: {
        return {
          ...state,
          reasonListData: payload,
          loader:false
        };
      }
      case estimationActionTypes.REASON_LIST_FAILURE: {
        return {
          ...state,
          reasonListData: payload.data,
          loader:false
        };
      }


      case estimationActionTypes.SCOPE_WORK_ID_LIST_INITIATE: {
        return {
          ...state,
          scopeWorkIdListData: [],
          loader:true
        };
      }
      case estimationActionTypes.SCOPE_WORK_ID_LIST_SUCCESS: {
        return {
          ...state,
          scopeWorkIdListData: payload,
          loader:false
        };
      }
      case estimationActionTypes.SCOPE_WORK_ID_LIST_FAILURE: {
        return {
          ...state,
          scopeWorkIdListData: payload.data,
          loader:false
        };
      }


      case estimationActionTypes.MODAL_BUDGET_VIEW_LIST_INITIATE: {
        return {
          ...state,
          modalBudgetChangeListData: [],
          loader:true
        };
      }
      case estimationActionTypes.MODAL_BUDGET_VIEW_LIST_SUCCESS: {
        return {
          ...state,
          modalBudgetChangeListData: payload.data,
          loader:false
        };
      }
      case estimationActionTypes.MODAL_BUDGET_VIEW_LIST_FAILURE: {
        return {
          ...state,
          modalBudgetChangeListData: payload.data,
          loader:false
        };
      }


      case estimationActionTypes.BLOCK_LIST_INITIATE: {
        return {
          ...state,
          blockListData: [],
          loader:true
        };
      }
      case estimationActionTypes.BLOCK_LIST_SUCCESS: {
        return {
          ...state,
          blockListData: payload,
          loader:false
        };
      }
      case estimationActionTypes.BLOCK_LIST_FAILURE: {
        return {
          ...state,
          blockListData: payload.data,
          loader:false
        };
      }

      
      case estimationActionTypes.UNIT_LIST_INITIATE: {
        return {
          ...state,
          unitListData: [],
          loader:true
        };
      }
      case estimationActionTypes.UNIT_LIST_SUCCESS: {
        return {
          ...state,
          unitListData: payload,
          loader:false
        };
      }
      case estimationActionTypes.UNIT_LIST_FAILURE: {
        return {
          ...state,
          unitListData: payload.data,
          loader:false
        };
      }



      case estimationActionTypes.PROFILE_LIST_INITIATE: {
        return {
          ...state,
          profileListData: [],
          loader:true
        };
      }
      case estimationActionTypes.PROFILE_LIST_SUCCESS: {
        return {
          ...state,
          profileListData: payload,
          loader:false
        };
      }
      case estimationActionTypes.PROFILE_LIST_FAILURE: {
        return {
          ...state,
          profileListData: payload.data,
          loader:false
        };
      }

      case estimationActionTypes.ESTIMATION_UNIT_LIST_INITIATE: {
        return {
          ...state,
          estimationUnitListData: [],
          loader:true
        };
      }
      case estimationActionTypes.ESTIMATION_UNIT_LIST_SUCCESS: {
        return {
          ...state,
          estimationUnitListData: payload,
          loader:false
        };
      }
      case estimationActionTypes.ESTIMATION_UNIT_LIST_FAILURE: {
        return {
          ...state,
          estimationUnitListData: payload.data,
          loader:false
        };
      }

      case estimationActionTypes.BUILDER_SIGNEE_LIST_INITIATE: {
        return {
          ...state,
          builderSigneeListData: [],
          loader:true
        };
      }
      case estimationActionTypes.BUILDER_SIGNEE_LIST_SUCCESS: {
        return {
          ...state,
          builderSigneeListData: payload,
          loader:false
        };
      }
      case estimationActionTypes.BUILDER_SIGNEE_LIST_FAILURE: {
        return {
          ...state,
          builderSigneeListData: payload.data,
          loader:false
        };
      }

      case estimationActionTypes.QUOTE_REQUEST_LIST_INITIATE: {
        return {
          ...state,
          quoteRequestListData: [],
          loader:true
        };
      }
      case estimationActionTypes.QUOTE_REQUEST_LIST_SUCCESS: {
        return {
          ...state,
          quoteRequestListData: payload,
          loader:false
        };
      }
      case estimationActionTypes.QUOTE_REQUEST_LIST_FAILURE: {
        return {
          ...state,
          quoteRequestListData: [],
          loader:false
        };
      }


      case estimationActionTypes.BILL_LIST_INITIATE: {
        return {
          ...state,
          billListData: [],
          loader:true
        };
      }
      case estimationActionTypes.BILL_LIST_SUCCESS: {
        return {
          ...state,
          billListData: payload,
          loader:false
        };
      }
      case estimationActionTypes.BILL_LIST_FAILURE: {
        return {
          ...state,
          billListData: payload.data,
          loader:false
        };
      }

      case estimationActionTypes.EVENT_LIST_INITIATE: {
        return {
          ...state,
          eventListData: [],
          loader:true
        };
      }
      case estimationActionTypes.EVENT_LIST_SUCCESS: {
        return {
          ...state,
          eventListData: payload,
          loader:false
        };
      }
      case estimationActionTypes.EVENT_LIST_FAILURE: {
        return {
          ...state,
          eventListData: payload.data,
          loader:false
        };
      }
     
     
      case estimationActionTypes.QUOTE_REQUEST_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: true,
        jobCodeId:payload
      };
    case estimationActionTypes.QUOTE_REQUEST_MODAL_CLOSE:
      return {
        ...state,
        isModalOpen: false,
      };



      
      case estimationActionTypes.BUDGET_LISTING_INITIATE: {
        return {
          ...state,
          budgetListData: [],
          loader:true
        };
      }
      case estimationActionTypes.BUDGET_LISTING_SUCCESS: {
        return {
          ...state,
          budgetListData: payload,
          loader:false
        };
      }
      case estimationActionTypes.BUDGET_LISTING_FAILURE: {
        return {
          ...state,
          budgetListData: payload.data,
          loader:false
        };
      }


      case estimationActionTypes.PURCHASE_LIST_INITIATE: {
        return {
          ...state,
          purchaseListData: [],
          loader:true
        };
      }
      case estimationActionTypes.PURCHASE_LIST_SUCCESS: {
        return {
          ...state,
          purchaseListData: payload,
          loader:false
        };
      }
      case estimationActionTypes.PURCHASE_LIST_FAILURE: {
        return {
          ...state,
          purchaseListData: payload.data,
          loader:false
        };
      }


      

      case estimationActionTypes.SUPPLIER_CREDIT_LIST_INITIATE: {
        return {
          ...state,
          supplierCreditListData: [],
          loader:true
        };
      }
      case estimationActionTypes.SUPPLIER_CREDIT_LIST_SUCCESS: {
        return {
          ...state,
          supplierCreditListData: payload,
          loader:false
        };
      }
      case estimationActionTypes.SUPPLIER_CREDIT_LIST_FAILURE: {
        return {
          ...state,
          supplierCreditListData: payload.data,
          loader:false
        };
      }


      
      case estimationActionTypes.FIELD_PURCHASE_ORDER_LIST_INITIATE: {
        return {
          ...state,
          fieldPurchaseOrderListData: [],
          loader:true
        };
      }
      case estimationActionTypes.FIELD_PURCHASE_ORDER_LIST_SUCCESS: {
        return {
          ...state,
          fieldPurchaseOrderListData: payload,
          loader:false
        };
      }
      case estimationActionTypes.FIELD_PURCHASE_ORDER_LIST_FAILURE: {
        return {
          ...state,
          fieldPurchaseOrderListData: payload.data,
          loader:false
        };
      }


      
      case estimationActionTypes.ESTIMATION_DOCUMENT_LISTING_INITIATE: {
        return {
          ...state,
          estimationDocumentListData: [],
          loader:true
        };
      }
      case estimationActionTypes.ESTIMATION_DOCUMENT_LISTING_SUCCESS: {
        return {
          ...state,
          estimationDocumentListData: payload,
          loader:false
        };
      }
      case estimationActionTypes.ESTIMATION_DOCUMENT_LISTING_FAILURE: {
        return {
          ...state,
          estimationDocumentListData: payload.data,
          loader:false
        };
      }
      



      case estimationActionTypes.BACK_CHARGE_CREDIT_LIST_INITIATE: {
        return {...state,backChargeListData: [], loaderBackCharge:true };
      }
      case estimationActionTypes.BACK_CHARGE_CREDIT_LIST_SUCCESS: {
        return { ...state,backChargeListData: payload,loaderBackCharge:false};
      }
      case estimationActionTypes.BACK_CHARGE_CREDIT_LIST_FAILURE: {
        return {...state,backChargeListData:[], loaderBackCharge:false};
      }


      case estimationActionTypes.QUOTE_REQUEST_COST_BREAKDOWN_DETAILS_INITIATE: {
        return {...state,quoteRequestCostBreakDownDetails: [] };
      }
      case estimationActionTypes.QUOTE_REQUEST_COST_BREAKDOWN_DETAILS_SUCCESS: {
        return { ...state,quoteRequestCostBreakDownDetails: payload};
      }
      case estimationActionTypes.QUOTE_REQUEST_COST_BREAKDOWN_DETAILS_FAILURE: {
        return {...state,quoteRequestCostBreakDownDetails:[]};
      }
     
      case estimationActionTypes.GET_RELATED_DOCUMNETS_INITIATE: {
        return {...state,relatedDocumentsList: [] };
      }
      case estimationActionTypes.GET_RELATED_DOCUMNETS_SUCCESS: {
        return { ...state,relatedDocumentsList: payload};
      }
      case estimationActionTypes.GET_RELATED_DOCUMNETS_FAILURE:{
        return {...state,relatedDocumentsList:[]};
      }

      case estimationActionTypes.GET_BACK_CHARGE_RELATED_DOCUMNETS_INITIATE: {
        return {...state,backChargeRelatedDocumentsList: [] };
      }
      case estimationActionTypes.GET_BACK_CHARGE_RELATED_DOCUMNETS_SUCCESS: {
        return { ...state,backChargeRelatedDocumentsList: payload};
      }
      case estimationActionTypes.GET_BACK_CHARGE_RELATED_DOCUMNETS_FAILURE:{
        return {...state,backChargeRelatedDocumentsList:[]};
      }

      case estimationActionTypes.MESSAGE_LIST_INITIATE: {
        return {...state,messageList: [] };
      }
      case estimationActionTypes.MESSAGE_LIST_SUCCESS: {
        return { ...state,messageList: payload};
      }
      case estimationActionTypes.MESSAGE_LIST_FAILURE:{
        return {...state,messageList:[]};
      }

      case estimationActionTypes.GET_ESTIMATION_SETTING_COST_CATEGORY_INITIATE: {
        return {...state,estimationSettingData: [] };
      }
      case estimationActionTypes.GET_ESTIMATION_SETTING_COST_CATEGORY_SUCCESS: {
        return { ...state,estimationSettingData:payload};
      }
      case estimationActionTypes.GET_ESTIMATION_SETTING_COST_CATEGORY_FAILURE:{
        return {...state,estimationSettingData:[]};
      }

      case estimationActionTypes.RELEASED_CONSTRUCTION_DOCS_INITIATE: {
        return {...state,getReleadesConstList: [] };
      }
      case estimationActionTypes.RELEASED_CONSTRUCTION_DOCS_SUCCESS: {
        return { ...state,getReleadesConstList:payload};
      }
      case estimationActionTypes.RELEASED_CONSTRUCTION_DOCS_FAILURE:{
        return {...state,getReleadesConstList:[]};
      }
    

    default:
      return state;
  }
}