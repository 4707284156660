import React, { useEffect, useState } from "react";
import { getCommonGarageStep3 } from "../../../redux/action/proformaAction";
import { useDispatch } from "react-redux";

const SummeryPreview = ({ handleShowComponent, handleShowComponents }) => {
  const dispatch = useDispatch([]);
  const [commonGarageStep3List, setCommonGarageStep3List] = useState([]);
  useEffect(() => {
    dispatch(getCommonGarageStep3()).then((res) => {
      console.log({ res });
      setCommonGarageStep3List(res?.data?.data);
    });
  }, []);
  return (
    <>
      <div>
        <div className="ModalBigheading d-flex justify-content-between">
          <h3>
            <span>Common Garage Input</span>
          </h3>
          <h6>
            <img src="images/book-search.png" />
            Detailed Costs
          </h6>
        </div>
        <div className="BlockInputs" style={{ width: "75%" }}>
          <table>
            <thead>
              <tr>
                <th>Input</th>
                <th>Irrigation Garage</th>
                <th>Maintenance Garage</th>
                <th>Totals</th>
              </tr>
            </thead>
            <tbody>
              {commonGarageStep3List?.data?.table1?.proforma?.MultiGarages
                ?.length > 0 &&
                commonGarageStep3List?.data?.table1?.proforma?.MultiGarages?.map(
                  (data, ind) => {
                    return (
                      <tr>
                        <td>{data.garageName}</td>
                        <td>{data.garageSqFt}</td>
                        <td>{data.garagePerimeterLinFt}</td>
                        <td>
                          {commonGarageStep3List?.data?.table1?.proforma?.MultiGarages?.reduce(
                            (total, data) =>
                              total +
                              data.garageSqFt +
                              data.garagePerimeterLinFt,
                            0
                          )}
                        </td>
                      </tr>
                    );
                  }
                )}
            </tbody>
          </table>
        </div>
        <div className="BlockCostsPreview">
          <div className="d-flex align-items-center justify-content-between">
            <h4>Common Garage Costs</h4>
            <div className="NewActions">
              <a href="javascript:void(0);" title="pdf">
                <img src="images/pdf.png" />
              </a>
              <a href="javascript:void(0);" title="download">
                <img src="images/download.png" />
              </a>
            </div>
          </div>
          <div className="BlockInputsCollapse">
            {/* {
              commonGarageStep3List?.data?.table2?.blockQuantities[0].ProformaBaseCosts[0].ProformaBlockQuantities[0].MultiGarage.garageName
            } */}
            <div
              className="BcpHeader collapsed"
              data-toggle="collapse"
              href="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <h5>Common Garage Costs</h5>
            </div>
            <div className="collapse" id="collapseExample" style={{}}>
              <div className="card card-body border-0">
                <div className="BlockInputs">
                  <table>
                    <thead>
                      <tr>
                        <th>Code Code #</th>
                        <th>Description</th>
                        <th>Site office</th>
                        <th>Rec Center</th>
                        <th>Total Construction Costs</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>4.0102</td>
                        <td>Windows. and Exterlor Doors</td>
                        <td>$ -</td>
                        <td>$ -</td>
                        <td>$ -</td>
                      </tr>
                      <tr>
                        <td>4.0106</td>
                        <td> Garage Overhead Door(s)</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                      <tr>
                        <td>4.0110</td>
                        <td>O.H. Garage Door Opener(s)</td>
                        <td>2,100</td>
                        <td>2,100</td>
                        <td>8,400</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td />
                        <td>Total Interior Finishing Costs</td>
                        <td>67,480.00</td>
                        <td>67,480.00</td>
                        <td>2,75,480.00</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Button d-flex  justify-content-between align-items-center px-5">
        <button
          className="Outline border text-success border-success"
          onClick={() => handleShowComponents("garageQuanties")}
        >
          Cancel
        </button>
        <button
          className="Create bg-success border border-success"
          onClick={() => handleShowComponent("multiLevelParakade")}
        >
          Save Draft
        </button>
      </div>
    </>
  );
};

export default SummeryPreview;
