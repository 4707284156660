import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import {toast} from "react-toastify"
import { useDispatch } from "react-redux";
import ReminderModal from "./RemiderModal";



const AssignVendorModal = ({ assignVendorModal, setAssignVendorModal, setAssignResouceModal}) => {


    const [reminderModal, setReminderModal] = useState(false)

  const dispatch = useDispatch()
  const handleOkaypayModalClose = () => {setAssignVendorModal(false) 
    setAssignResouceModal(true)
  }

 





  return (
    <>
      <Modal
        show={assignVendorModal}
        onHide={handleOkaypayModalClose}
        className="ModalBox SmallModal"
      >
        <a
          onClick={handleOkaypayModalClose}
          className="CloseModal"
          data-dismiss="modal"
        >
          ×
        </a>
        <div class="FormArea">
        <div class="ModalTitleBox">
          <h4>Assign Staff Member</h4>
        </div>
        <div class="ModalTitleHeading">
          <h2>Assign Staff Member</h2>
        </div>
        <div class="FolderForm">
          <form>
            <div class="form-group">
              <h6>Name</h6>
              <div class="Categories Categories1">
                <div class="Categories_all Categories_all1">
                  <span>Select a Staff Member</span>
                  <img src="images/down.png" />
                </div>
                <ul>
                  <li>List of Staff Members loaded from the system</li>
                </ul>
              </div>
            </div>
            <div class="form-group">
              <h6>Set Reminder</h6>
              <div class="PermissionsName">
                  <h5 class="FolderPermissionId" onClick={()=> {
                    setAssignVendorModal(false)
                    setReminderModal(true)

                  }}>
                      <span><img src="images/timer.png" /></span> Set Reminder
                  </h5>
              </div>
          </div>

            <div class="Button text-center mt-5">
              <button class="Create" data-toggle="modal" data-target="#AssignResourcesHover" data-dismiss="modal">Assign</button>
            </div>
          </form>
        </div>
      </div>
   
      </Modal>


      {/* <ReminderModal
        reminderModal={reminderModal}
        setReminderModal={setReminderModal}
        setAssignVendorModal={setAssignVendorModal}
      /> */}


    </>
  );
};

export default AssignVendorModal;
