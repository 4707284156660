import React from "react";
import IbuildAdminSidenav from "./IbuildAdminSidenav";
import Header from "../Header";
import { Link } from "react-router-dom";
import IbuildAdminHeader from "./IbuildAdminHeader";

const IbuildAdminDashboard = () => {

  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

  console.log(iBuildLocalData?.user?.id ,'IIIIIIIIIIIIIIIII')
  return (
    <>
    <IbuildAdminHeader />
    <IbuildAdminSidenav />
      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="MainTitle2">
            <h2>Welcome,</h2>
          </div>

          <div class="CommonBoxArea">
            <div class="home-carousel owl-carousel owl-theme owl-loaded owl-drag">
              <div class="owl-stage-outer">
                <div
                  class="owl-stage"
                  style={{transform: "translate3d(-1143px, 0px, 0px)", transition: "all 0s ease 0s", width: "3432px" }}
                >
                  <div
                    class="owl-item cloned"
                    style={{width: "361.333px", marginRight: "20px"}}
                  >
                    <div class="item">
                      <a href="indivisual_components_part2_User WorkflowTasks.html">
                        <div class="CommonBox">
                          <div class="CommonBoxHeader">
                            <h6>Task</h6>
                            <h5>4,266</h5>
                            <figure>
                              <img src="images/task-square.png" />
                            </figure>
                          </div>
                          <div class="CommonBoxBody">
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                              <span class="CbbOverlay"></span>
                            </div>
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                              <span class="CbbOverlay"></span>
                            </div>
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                            </div>
                          </div>
                          <div class="CommonBoxFooter">
                            <h5>4,266</h5>
                            <p>Total active tasks group</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                    class="owl-item cloned"
                    style={{width: "361.333px", marginRight: "20px"}}
                  >
                    <div class="item">
                      <a href="notifications.html">
                        <div class="CommonBox">
                          <div class="CommonBoxHeader">
                            <h6>Notification</h6>
                            <h5>4,266</h5>
                            <figure>
                              <img src="images/construction.png" />
                            </figure>
                          </div>
                          <div class="CommonBoxBody">
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                              <span class="CbbOverlay"></span>
                            </div>
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                              <span class="CbbOverlay"></span>
                            </div>
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                            </div>
                          </div>
                          <div class="CommonBoxFooter">
                            <h5>4,266</h5>
                            <p>Total active tasks group</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                    class="owl-item cloned"
                    style={{width: "361.333px", marginRight: "20px"}}
                  >
                    <div class="item">
                      <Link to="/ibuild-admin-itinerary">
                        <div class="CommonBox">
                          <div class="CommonBoxHeader">
                            <h6>Itinerary</h6>
                            <h5>4,266</h5>
                            <figure>
                              <img src="images/task-square.png" />
                            </figure>
                          </div>
                          <div class="CommonBoxBody">
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                              <span class="CbbOverlay"></span>
                            </div>
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                              <span class="CbbOverlay"></span>
                            </div>
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                            </div>
                          </div>
                          <div class="CommonBoxFooter">
                            <h5>4,266</h5>
                            <p>Total active tasks group</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div
                    class="owl-item active"
                    style={{width: "361.333px", marginRight: "20px"}}
                  >
                    <div class="item">
                      <a href="indivisual_components_part2_User WorkflowTasks.html">
                        <div class="CommonBox">
                          <div class="CommonBoxHeader">
                            <h6>Task</h6>
                            <h5>4,266</h5>
                            <figure>
                              <img src="images/task-square.png" />
                            </figure>
                          </div>
                          <div class="CommonBoxBody">
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                              <span class="CbbOverlay"></span>
                            </div>
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                              <span class="CbbOverlay"></span>
                            </div>
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                            </div>
                          </div>
                          <div class="CommonBoxFooter">
                            <h5>4,266</h5>
                            <p>Total active tasks group</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                    class="owl-item active"
                    style={{width: "361.333px", marginRight: "20px"}}
                  >
                    <div class="item">
                      <a href="notifications.html">
                        <div class="CommonBox">
                          <div class="CommonBoxHeader">
                            <h6>Notification</h6>
                            <h5>4,266</h5>
                            <figure>
                              <img src="images/construction.png" />
                            </figure>
                          </div>
                          <div class="CommonBoxBody">
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                              <span class="CbbOverlay"></span>
                            </div>
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                              <span class="CbbOverlay"></span>
                            </div>
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                            </div>
                          </div>
                          <div class="CommonBoxFooter">
                            <h5>4,266</h5>
                            <p>Total active tasks group</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                    class="owl-item active"
                    style={{width: "361.333px", marginRight: "20px"}}
                  >
                    <div class="item">
                    <Link to="/ibuild-admin-itinerary">
                        <div class="CommonBox">
                          <div class="CommonBoxHeader">
                            <h6>Itinerary</h6>
                            <h5>4,266</h5>
                            <figure>
                              <img src="images/task-square.png" />
                            </figure>
                          </div>
                          <div class="CommonBoxBody">
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                              <span class="CbbOverlay"></span>
                            </div>
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                              <span class="CbbOverlay"></span>
                            </div>
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                            </div>
                          </div>
                          <div class="CommonBoxFooter">
                            <h5>4,266</h5>
                            <p>Total active tasks group</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div
                    class="owl-item cloned"
                    style={{width: "361.333px", marginRight: "20px"}}
                  >
                    <div class="item">
                      <a href="indivisual_components_part2_User WorkflowTasks.html">
                        <div class="CommonBox">
                          <div class="CommonBoxHeader">
                            <h6>Task</h6>
                            <h5>4,266</h5>
                            <figure>
                              <img src="images/task-square.png" />
                            </figure>
                          </div>
                          <div class="CommonBoxBody">
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                              <span class="CbbOverlay"></span>
                            </div>
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                              <span class="CbbOverlay"></span>
                            </div>
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                            </div>
                          </div>
                          <div class="CommonBoxFooter">
                            <h5>4,266</h5>
                            <p>Total active tasks group</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                    class="owl-item cloned"
                    style={{width: "361.333px", marginRight: "20px"}}
                  >
                    <div class="item">
                      <a href="notifications.html">
                        <div class="CommonBox">
                          <div class="CommonBoxHeader">
                            <h6>Notification</h6>
                            <h5>4,266</h5>
                            <figure>
                              <img src="images/construction.png" />
                            </figure>
                          </div>
                          <div class="CommonBoxBody">
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                              <span class="CbbOverlay"></span>
                            </div>
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                              <span class="CbbOverlay"></span>
                            </div>
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                            </div>
                          </div>
                          <div class="CommonBoxFooter">
                            <h5>4,266</h5>
                            <p>Total active tasks group</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                    class="owl-item cloned"
                    style={{width: "361.333px", marginRight: "20px"}}
                  >
                    <div class="item">
                      <a href="Itinerary.html">
                        <div class="CommonBox">
                          <div class="CommonBoxHeader">
                            <h6>Itinerary</h6>
                            <h5>4,266</h5>
                            <figure>
                              <img src="images/task-square.png" />
                            </figure>
                          </div>
                          <div class="CommonBoxBody">
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                              <span class="CbbOverlay"></span>
                            </div>
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                              <span class="CbbOverlay"></span>
                            </div>
                            <div class="CommonBoxBodyUser">
                              <div class="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div class="">
                                  <h6>John Doe</h6>
                                  <p>Total active tasks group</p>
                                </div>
                              </div>
                              <figure>
                                <img src="images/taskimg-1.png" />
                              </figure>
                            </div>
                          </div>
                          <div class="CommonBoxFooter">
                            <h5>4,266</h5>
                            <p>Total active tasks group</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="owl-nav disabled">
                <button type="button" role="presentation" class="owl-prev">
                  <span aria-label="Previous">‹</span>
                </button>
                <button type="button" role="presentation" class="owl-next">
                  <span aria-label="Next">›</span>
                </button>
              </div>
              <div class="owl-dots disabled">
                <button role="button" class="owl-dot active">
                  <span></span>
                </button>
              </div>
            </div>
          </div>

          <div class="CommonTableArea ChangeOrderTable">
            <div class="JobTableHeaderOuter">
              <div class="JobTableHeaderInner">
                <h5>Job/Unit List</h5>
                <div class="Actions">
                  <a href="">
                    <img src="images/icon-52.png" />
                  </a>
                  <a href="">
                    <img src="images/icon-51.png" />
                  </a>
                  <a href="">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="">
                    <img src="images/icon-54.png" />
                  </a>
                  <a href="">
                    <img src="images/icon-55.png" />
                  </a>
                </div>
              </div>
            </div>
            <div class="JobTableCommon">
              <table class="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Job Number</th>
                    <th>Build Type</th>
                    <th>Address</th>
                    <th>Lot</th>
                    <th>Block</th>
                    <th>Phase</th>
                    <th>Plan</th>
                    <th>Sub Division</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody class="addClasss">
                  <tr>
                    <td>
                      <a href="change_order_list.html">
                        1{" "}
                        <span class="Dots">
                          <img src="images/dots.png" />
                        </span>
                      </a>{" "}
                    </td>
                    <td>
                      <a
                        class="hidtableshowChangeOrder"
                        href="javascript:void(0);"
                      >
                        HID -2B -370&nbsp;
                        <img src="images/down-arrow.png" />
                      </a>
                    </td>
                    <td>
                      <a href="change_order_list.html">Apartment</a>
                    </td>
                    <td>
                      <a href="change_order_list.html">312 Weddenburn</a>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <a href="change_order_list.html">Chinook Gate</a>
                    </td>
                    <td>
                      <a href="javascript:void(0);">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="10" class="padding_none">
                      <div class="JobBoxTable">
                        <table>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Unit Job Number</th>
                              <th>Unit#</th>
                              <th>Primary Client</th>
                              <th>Square Footage</th>
                              <th>Warranty Requests</th>
                              <th>Description</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <a href="change_order_list.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  Unit #Unit 3
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">John Doe</a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="change_order_list.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  Unit #Unit 3
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">John Doe</a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="change_order_list.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  Unit #Unit 3
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">John Doe</a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="change_order_list.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  Unit #Unit 3
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">John Doe</a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="change_order_list.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  Unit #Unit 3
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">John Doe</a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="change_order_list.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  Unit #Unit 3
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">John Doe</a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="change_order_list.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  Unit #Unit 3
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">John Doe</a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="change_order_list.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td>
                      2{" "}
                      <span class="Dots">
                        <img src="images/dots.png" />
                      </span>{" "}
                    </td>
                    <td>
                      <a href="#">
                        HID -2B -370&nbsp;
                        <img src="images/down-arrow.png" />
                      </a>
                    </td>
                    <td>Apartment</td>
                    <td>312 Weddenburn</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Chinook Gate</td>
                    <td>
                      <a href="javascript:void(0);">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td>
                      3{" "}
                      <span class="Dots">
                        <img src="images/dots.png" />
                      </span>{" "}
                    </td>
                    <td>
                      <a href="#">
                        HID -2B -370&nbsp;
                        <img src="images/down-arrow.png" />
                      </a>
                    </td>
                    <td>Apartment</td>
                    <td>312 Weddenburn</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Chinook Gate</td>
                    <td>
                      <a href="javascript:void(0);">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td>
                      4{" "}
                      <span class="Dots">
                        <img src="images/dots.png" />
                      </span>{" "}
                    </td>
                    <td>
                      <a href="#">
                        HID -2B -370&nbsp;
                        <img src="images/down-arrow.png" />
                      </a>
                    </td>
                    <td>Apartment</td>
                    <td>312 Weddenburn</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Chinook Gate</td>
                    <td>
                      <a href="javascript:void(0);">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td>
                      5{" "}
                      <span class="Dots">
                        <img src="images/dots.png" />
                      </span>{" "}
                    </td>
                    <td>
                      <a href="#">
                        HID -2B -370&nbsp;
                        <img src="images/down-arrow.png" />
                      </a>
                    </td>
                    <td>Apartment</td>
                    <td>312 Weddenburn</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Chinook Gate</td>
                    <td>
                      <a href="javascript:void(0);">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="CommonTableArea DesignSelectionTable">
            <div class="JobTableHeaderOuter">
              <div class="JobTableHeaderInner">
                <h5>Job/Unit List</h5>
                <div class="Actions">
                  <a href="">
                    <img src="images/icon-52.png" />
                  </a>
                  <a href="">
                    <img src="images/icon-51.png" />
                  </a>
                  <a href="">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="">
                    <img src="images/icon-54.png" />
                  </a>
                  <a href="">
                    <img src="images/icon-55.png" />
                  </a>
                </div>
              </div>
            </div>
            <div class="JobTableCommon">
              <table class="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Job Number</th>
                    <th>Build Type</th>
                    <th>Address</th>
                    <th>Lot</th>
                    <th>Block</th>
                    <th>Phase</th>
                    <th>Plan</th>
                    <th>Sub Division</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody class="addClasss">
                  <tr>
                    <td>
                      <a href="design_selections.html">
                        1{" "}
                        <span class="Dots">
                          <img src="images/dots.png" />
                        </span>
                      </a>{" "}
                    </td>
                    <td>
                      <a
                        class="hidtableshowChangeOrder"
                        href="javascript:void(0);"
                      >
                        HID -2B -370&nbsp;
                        <img src="images/down-arrow.png" />
                      </a>
                    </td>
                    <td>
                      <a href="design_selections.html">Apartment</a>
                    </td>
                    <td>
                      <a href="design_selections.html">312 Weddenburn</a>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <a href="design_selections.html">Chinook Gate</a>
                    </td>
                    <td>
                      <a href="javascript:void(0);">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="10" class="padding_none">
                      <div class="JobBoxTable">
                        <table>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Unit Job Number</th>
                              <th>Unit#</th>
                              <th>Primary Client</th>
                              <th>Square Footage</th>
                              <th>Warranty Requests</th>
                              <th>Description</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <a href="design_selections.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  Unit #Unit 3
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">John Doe</a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="design_selections.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  Unit #Unit 3
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">John Doe</a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="design_selections.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  Unit #Unit 3
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">John Doe</a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="design_selections.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  Unit #Unit 3
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">John Doe</a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="design_selections.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  Unit #Unit 3
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">John Doe</a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="design_selections.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  Unit #Unit 3
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">John Doe</a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="design_selections.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  Unit #Unit 3
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">John Doe</a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="design_selections.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td>
                      2{" "}
                      <span class="Dots">
                        <img src="images/dots.png" />
                      </span>{" "}
                    </td>
                    <td>
                      <a href="#">
                        HID -2B -370&nbsp;
                        <img src="images/down-arrow.png" />
                      </a>
                    </td>
                    <td>Apartment</td>
                    <td>312 Weddenburn</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Chinook Gate</td>
                    <td>
                      <a href="javascript:void(0);">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td>
                      3{" "}
                      <span class="Dots">
                        <img src="images/dots.png" />
                      </span>{" "}
                    </td>
                    <td>
                      <a href="#">
                        HID -2B -370&nbsp;
                        <img src="images/down-arrow.png" />
                      </a>
                    </td>
                    <td>Apartment</td>
                    <td>312 Weddenburn</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Chinook Gate</td>
                    <td>
                      <a href="javascript:void(0);">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td>
                      4{" "}
                      <span class="Dots">
                        <img src="images/dots.png" />
                      </span>{" "}
                    </td>
                    <td>
                      <a href="#">
                        HID -2B -370&nbsp;
                        <img src="images/down-arrow.png" />
                      </a>
                    </td>
                    <td>Apartment</td>
                    <td>312 Weddenburn</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Chinook Gate</td>
                    <td>
                      <a href="javascript:void(0);">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td>
                      5{" "}
                      <span class="Dots">
                        <img src="images/dots.png" />
                      </span>{" "}
                    </td>
                    <td>
                      <a href="#">
                        HID -2B -370&nbsp;
                        <img src="images/down-arrow.png" />
                      </a>
                    </td>
                    <td>Apartment</td>
                    <td>312 Weddenburn</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Chinook Gate</td>
                    <td>
                      <a href="javascript:void(0);">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="CommonTableArea ViewConfigTable">
            <div class="JobTableHeaderOuter">
              <div class="JobTableHeaderInner">
                <h5>Job/Unit List</h5>
                <div class="Actions">
                  <a href="">
                    <img src="images/icon-52.png" />
                  </a>
                  <a href="">
                    <img src="images/icon-51.png" />
                  </a>
                  <a href="">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="">
                    <img src="images/icon-54.png" />
                  </a>
                  <a href="">
                    <img src="images/icon-55.png" />
                  </a>
                </div>
              </div>
            </div>
            <div class="JobTableCommon">
              <table class="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Job Number</th>
                    <th>Build Type</th>
                    <th>Address</th>
                    <th>Lot</th>
                    <th>Block</th>
                    <th>Phase</th>
                    <th>Plan</th>
                    <th>Sub Division</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody class="addClasss">
                  <tr>
                    <td>
                      <a href="view_config.html">
                        1{" "}
                        <span class="Dots">
                          <img src="images/dots.png" />
                        </span>
                      </a>{" "}
                    </td>
                    <td>
                      <a
                        class="hidtableshowChangeOrder"
                        href="javascript:void(0);"
                      >
                        HID -2B -370&nbsp;
                        <img src="images/down-arrow.png" />
                      </a>
                    </td>
                    <td>
                      <a href="view_config.html">Apartment</a>
                    </td>
                    <td>
                      <a href="view_config.html">312 Weddenburn</a>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <a href="view_config.html">Chinook Gate</a>
                    </td>
                    <td>
                      <a href="javascript:void(0);">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="10" class="padding_none">
                      <div class="JobBoxTable">
                        <table>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Unit Job Number</th>
                              <th>Unit#</th>
                              <th>Primary Client</th>
                              <th>Square Footage</th>
                              <th>Warranty Requests</th>
                              <th>Description</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <a href="view_config.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="view_config.html">UUnit3 -CHI -2B</a>
                              </td>
                              <td>
                                <a href="view_config.html">Unit #Unit 3</a>
                              </td>
                              <td>
                                <a href="view_config.html">John Doe</a>
                              </td>
                              <td>
                                <a href="view_config.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="view_config.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="view_config.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="view_config.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="view_config.html">UUnit3 -CHI -2B</a>
                              </td>
                              <td>
                                <a href="view_config.html">Unit #Unit 3</a>
                              </td>
                              <td>
                                <a href="view_config.html">John Doe</a>
                              </td>
                              <td>
                                <a href="view_config.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="view_config.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="view_config.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="view_config.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="view_config.html">UUnit3 -CHI -2B</a>
                              </td>
                              <td>
                                <a href="view_config.html">Unit #Unit 3</a>
                              </td>
                              <td>
                                <a href="view_config.html">John Doe</a>
                              </td>
                              <td>
                                <a href="view_config.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="view_config.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="view_config.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="view_config.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="view_config.html">UUnit3 -CHI -2B</a>
                              </td>
                              <td>
                                <a href="view_config.html">Unit #Unit 3</a>
                              </td>
                              <td>
                                <a href="view_config.html">John Doe</a>
                              </td>
                              <td>
                                <a href="view_config.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="view_config.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="view_config.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="view_config.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="view_config.html">UUnit3 -CHI -2B</a>
                              </td>
                              <td>
                                <a href="view_config.html">Unit #Unit 3</a>
                              </td>
                              <td>
                                <a href="view_config.html">John Doe</a>
                              </td>
                              <td>
                                <a href="view_config.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="view_config.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="view_config.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="view_config.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="view_config.html">UUnit3 -CHI -2B</a>
                              </td>
                              <td>
                                <a href="view_config.html">Unit #Unit 3</a>
                              </td>
                              <td>
                                <a href="view_config.html">John Doe</a>
                              </td>
                              <td>
                                <a href="view_config.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="view_config.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="view_config.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="view_config.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="view_config.html">UUnit3 -CHI -2B</a>
                              </td>
                              <td>
                                <a href="view_config.html">Unit #Unit 3</a>
                              </td>
                              <td>
                                <a href="view_config.html">John Doe</a>
                              </td>
                              <td>
                                <a href="view_config.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="view_config.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="view_config.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td>
                      2{" "}
                      <span class="Dots">
                        <img src="images/dots.png" />
                      </span>{" "}
                    </td>
                    <td>
                      <a href="#">
                        HID -2B -370&nbsp;
                        <img src="images/down-arrow.png" />
                      </a>
                    </td>
                    <td>Apartment</td>
                    <td>312 Weddenburn</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Chinook Gate</td>
                    <td>
                      <a href="javascript:void(0);">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td>
                      3{" "}
                      <span class="Dots">
                        <img src="images/dots.png" />
                      </span>{" "}
                    </td>
                    <td>
                      <a href="#">
                        HID -2B -370&nbsp;
                        <img src="images/down-arrow.png" />
                      </a>
                    </td>
                    <td>Apartment</td>
                    <td>312 Weddenburn</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Chinook Gate</td>
                    <td>
                      <a href="javascript:void(0);">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td>
                      4{" "}
                      <span class="Dots">
                        <img src="images/dots.png" />
                      </span>{" "}
                    </td>
                    <td>
                      <a href="#">
                        HID -2B -370&nbsp;
                        <img src="images/down-arrow.png" />
                      </a>
                    </td>
                    <td>Apartment</td>
                    <td>312 Weddenburn</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Chinook Gate</td>
                    <td>
                      <a href="javascript:void(0);">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td>
                      5{" "}
                      <span class="Dots">
                        <img src="images/dots.png" />
                      </span>{" "}
                    </td>
                    <td>
                      <a href="#">
                        HID -2B -370&nbsp;
                        <img src="images/down-arrow.png" />
                      </a>
                    </td>
                    <td>Apartment</td>
                    <td>312 Weddenburn</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Chinook Gate</td>
                    <td>
                      <a href="javascript:void(0);">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="CommonTableArea EstimationTableHome">
            <div class="JobTableHeaderOuter">
              <div class="JobTableHeaderInner">
                <h5>Job/Unit List</h5>
                <div class="Actions">
                  <a href="">
                    <img src="images/icon-52.png" />
                  </a>
                  <a href="">
                    <img src="images/icon-51.png" />
                  </a>
                  <a href="">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="">
                    <img src="images/icon-54.png" />
                  </a>
                  <a href="">
                    <img src="images/icon-55.png" />
                  </a>
                </div>
              </div>
            </div>
            <div class="JobTableCommon">
              <table class="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Job Number</th>
                    <th>Build Type</th>
                    <th>Address</th>
                    <th>Lot</th>
                    <th>Block</th>
                    <th>Phase</th>
                    <th>Plan</th>
                    <th>Sub Division</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody class="addClasss">
                  <tr>
                    <td>
                      <a href="budget_details.html">
                        1{" "}
                        <span class="Dots">
                          <img src="images/dots.png" />
                        </span>
                      </a>{" "}
                    </td>
                    <td>
                      <a
                        class="hidtableshowChangeOrder"
                        href="javascript:void(0);"
                      >
                        HID -2B -370&nbsp;
                        <img src="images/down-arrow.png" />
                      </a>
                    </td>
                    <td>
                      <a href="budget_details.html">Apartment</a>
                    </td>
                    <td>
                      <a href="budget_details.html">312 Weddenburn</a>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <a href="budget_details.html">Chinook Gate</a>
                    </td>
                    <td>
                      <a href="javascript:void(0);">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="10" class="padding_none">
                      <div class="JobBoxTable">
                        <table>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Unit Job Number</th>
                              <th>Unit#</th>
                              <th>Primary Client</th>
                              <th>Square Footage</th>
                              <th>Warranty Requests</th>
                              <th>Description</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <a href="budget_details.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">Unit #Unit 3</a>
                              </td>
                              <td>
                                <a href="budget_details.html">John Doe</a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="budget_details.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">Unit #Unit 3</a>
                              </td>
                              <td>
                                <a href="budget_details.html">John Doe</a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="budget_details.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">Unit #Unit 3</a>
                              </td>
                              <td>
                                <a href="budget_details.html">John Doe</a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="budget_details.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">Unit #Unit 3</a>
                              </td>
                              <td>
                                <a href="budget_details.html">John Doe</a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="budget_details.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">Unit #Unit 3</a>
                              </td>
                              <td>
                                <a href="budget_details.html">John Doe</a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="budget_details.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">Unit #Unit 3</a>
                              </td>
                              <td>
                                <a href="budget_details.html">John Doe</a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="budget_details.html">
                                  1{" "}
                                  <span class="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">Unit #Unit 3</a>
                              </td>
                              <td>
                                <a href="budget_details.html">John Doe</a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="budget_details.html">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  <i
                                    class="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td>
                      2{" "}
                      <span class="Dots">
                        <img src="images/dots.png" />
                      </span>{" "}
                    </td>
                    <td>
                      <a href="#">
                        HID -2B -370&nbsp;
                        <img src="images/down-arrow.png" />
                      </a>
                    </td>
                    <td>Apartment</td>
                    <td>312 Weddenburn</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Chinook Gate</td>
                    <td>
                      <a href="javascript:void(0);">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td>
                      3{" "}
                      <span class="Dots">
                        <img src="images/dots.png" />
                      </span>{" "}
                    </td>
                    <td>
                      <a href="#">
                        HID -2B -370&nbsp;
                        <img src="images/down-arrow.png" />
                      </a>
                    </td>
                    <td>Apartment</td>
                    <td>312 Weddenburn</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Chinook Gate</td>
                    <td>
                      <a href="javascript:void(0);">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td>
                      4{" "}
                      <span class="Dots">
                        <img src="images/dots.png" />
                      </span>{" "}
                    </td>
                    <td>
                      <a href="#">
                        HID -2B -370&nbsp;
                        <img src="images/down-arrow.png" />
                      </a>
                    </td>
                    <td>Apartment</td>
                    <td>312 Weddenburn</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Chinook Gate</td>
                    <td>
                      <a href="javascript:void(0);">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td>
                      5{" "}
                      <span class="Dots">
                        <img src="images/dots.png" />
                      </span>{" "}
                    </td>
                    <td>
                      <a href="#">
                        HID -2B -370&nbsp;
                        <img src="images/down-arrow.png" />
                      </a>
                    </td>
                    <td>Apartment</td>
                    <td>312 Weddenburn</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Chinook Gate</td>
                    <td>
                      <a href="javascript:void(0);">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IbuildAdminDashboard;
