import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ClientHeader from "./ClientHeader";
import ClientViewSidenav from "./client-view-sidenav";
import { useLocation } from "react-router-dom";
import {
  clientViewGeneralConfig,
  createClientComment,
  createClientSubComment,
  getClientCommentList,
  getClientSubCommentList,
} from "../redux/action/clientViewAuthAction";

const initialState = {
  content: "",

  investorId: "",
  subContent: "",
  index: "",
};
const WarrantyRequestClientDetails = () => {
  const dispatch=useDispatch()
  const [iState, updateState] = useState(initialState);
  const {
    content,

    investorId,
    subContent,
    index,
  } = iState;
  const { state } = useLocation();
  console.log("state", state);
  const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
  const { commentList, subCommentList } = useSelector(
    (state) => state.clientViewLogin
  );
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  useEffect(() => {
    dispatch(clientViewGeneralConfig()).then((res) => {
      dispatch(
        getClientCommentList(
          res?.data?.ViewSetting?.id,
          clientViewData?.user?.ClientId
        )
      );
      updateState({ ...iState, investorId: res?.data?.ViewSetting?.id });
    });
  }, []);

  const handleCreateComment = () => {
    const dataa = { content };
    dispatch(
      createClientComment(investorId, clientViewData?.user?.CompanyId, dataa)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(
          getClientCommentList(investorId, clientViewData?.user?.CompanyId)
        );
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          content: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleRemoveComments = (type) => {
    if (type === "comment") {
      updateState({ ...iState, content: "" });
    } else {
      updateState({ ...iState, subContent: "" });
    }
  };
  const handleSubCreateComment = (id) => {
    const dataa = { content: subContent, parentId: id };
    dispatch(
      createClientSubComment(investorId, clientViewData?.user?.CompanyId, dataa)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(
          getClientSubCommentList(
            investorId,
            id,
            clientViewData?.user?.CompanyId
          )
        );
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          subContent: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  return (
    <>
      <ClientHeader />
      <ClientViewSidenav />
      <div className="WrapperAreaClient">
        <div className="WrapperBoxClient pb-5">
          <div className="MainTitleBox"></div>
          <div className="TitleBox">
            <h4 className="Title">Warranty Request #{state?.id}</h4>
            <div className="TitleBox2 TitleBoxRight">
              <div className="dropdown">
                <h6 data-toggle="dropdown">{state?.JobId}</h6>
                <span>{state?.JobType}</span>
              </div>
            </div>
          </div>
          <div className="WarrantyDashboardForm mb-4">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Description <span className="Required">*</span>
                  </label>
                  <p>{state?.description ?? "N/A"}</p>
                </div>
                <div className="form-group d-flex align-items-center">
                  <label className="mb-0">Status:</label>
                  <p className="my-0">{state?.warrantyStatus}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Supporting Document</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer"
                            data-toggle="modal"
                            data-target="#AddNewSupportingDocs2"
                          >
                            <figure>
                              <img src="images/document-support.png" />
                            </figure>
                            Supporting Document
                          </h5>
                          <span
                            className="Count CursorPointer"
                            data-toggle="modal"
                            data-target="#SupportingDocumentList"
                          >
                            3
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Add Notes</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer"
                            data-toggle="modal"
                            data-target="#NoteAdd2"
                          >
                            <figure>
                              <img src="images/document-support.png" />
                            </figure>
                            Add Notes
                          </h5>
                          <span
                            className="Count CursorPointer"
                            data-toggle="modal"
                            data-target="#NotesList"
                          >
                            3
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="WarrantyDashboardForm">
            <h4>Client Details</h4>
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Possession Date</label>
                      <p>Auto-filled by System</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <di className="form-group">
                      <label>Request Date</label>
                      <p>Auto-filled by System</p>
                    </di>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Client Full Legal Name</label>
                      <p>Auto-filled by System</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Phone Number</label>
                      <p>Auto-filled by System</p>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Primary Email</label>
                      <p>Auto-filled by System</p>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        Job Address{" "}
                        <img src="images/location.png" className="ml-2" />
                      </label>
                      <p>Auto-filled by System</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="UrgencyArea">
                  <div className="form-group">
                    <h6>Urgency</h6>
                    <p>Auto-filled by System</p>
                  </div>
                </div>
                <div className="form-group mt-5 ">
                  <div className="CommonModalArea2">
                    <h5>Schedule Trades Day</h5>
                    <div className="CommonModalBox2">
                      <div className="d-flex align-items-center">
                        <h5
                          className="CursorPointer mb-0"
                          data-toggle="modal"
                          data-target="#InspectionDatesSelection"
                        >
                          <figure>
                            <img src="images/grey_calendar.png" />
                          </figure>
                          &nbsp; View Scheduled Trades Day
                          <span className="Count">1</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Tableheader mt-4">
            <h6>Request Items</h6>
            <div className="Actions">
              <a href="">
                <img src="images/icon-53.png" />
              </a>
              <a href="">
                <img src="images/download.png" />
              </a>
            </div>
          </div>
          <div className="TableList NewTableList TableListHeader TableHeightScroll">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Description</th>
                  <th>Service Type</th>
                  <th>Work Order #</th>
                  <th>Item Status</th>
                  <th>Inspector</th>
                  <th>Date Inspected</th>
                  <th>Supporting Doc</th>
                </tr>
              </thead>
              <tbody>
                {state?.requestData?.length > 0 ? (
                  state?.requestData?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>1</td>
                        <td>Leaking sink</td>
                        <td>Warranty</td>
                        <td />
                        <td>Created</td>
                        <td>John Doe</td>
                        <td />
                        <td>
                          <div className="Actions">
                            <a
                              href="javascript:void(0);"
                              data-toggle="modal"
                              data-target="#AddNewSupportingDocs2"
                            >
                              <img src="images/icon-63.png" />
                            </a>
                            <a
                              href="javascript:void(0);"
                              data-toggle="modal"
                              data-target="#SupportingDocumentList"
                            >
                              <span className="Count">3</span>
                            </a>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5">No data found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between mt-3 pb-5">
            {/* <button className="BrandBtn">Cancel</button> */}
            <div className="d-flex ">
              <button className="BrandBtnGreen">Download</button>
              <div className="dropdown ml-3">
                <button className="BrandBtnGreen" type="button">
                  Print
                </button>
              </div>
            </div>
          </div>
          <div className="DiscussionArea">
            <div className="DiscussionTitle">
              <h4>Discussion</h4>
              <div className="DiscussionChat">
                <img src="images/down.png" className="DiscussionIcon" />
                <a href="#">
                  <img src="images/pdf2.png" />
                </a>
              </div>
            </div>
            <div className="DiscussionHideArea" style={{ display: "block" }}>
              <div className="DiscussionBox">
                <span>FM</span>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Add a Comment ....."
                    name="content"
                    value={content}
                    onChange={handleInputChange}
                  />
                  <div className="DiscussionOverlay">
                    <a
                      className="CancelChat"
                      onClick={() => handleRemoveComments("comment")}
                    >
                      <img src="images/plus-cancel1.png" />
                    </a>
                    <button
                      className="SendChat"
                      onClick={handleCreateComment}
                      disabled={content == ""}
                    >
                      <img src="images/forwardbutton.png" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="DiscussionBox"></div>
              {commentList?.comments?.length > 0 &&
                commentList?.comments
                  ?.sort((a, b) => b.id - a.id)
                  ?.map((item, ind) => {
                    return (
                      <div className="DiscussionBox">
                        <span>TH</span>
                        <div className="DiscussionRight">
                          <h6>{item.content}</h6>
                          <small>
                            {moment(item.createdAt).format(
                              "MM/DD/YYYY, [at] hh:mm A"
                            )}
                          </small>

                          <div class="ReplyArea">
                            <a
                              onClick={() =>
                                updateState({
                                  ...iState,
                                  index: ind,
                                  parentId: item.id,
                                })
                              }
                            >
                              Reply
                            </a>
                            {index === ind &&
                              subCommentList?.comments?.length > 0 &&
                              subCommentList?.comments?.map((ele) => (
                                <div class="DiscussionBox">
                                  <span>RE</span>
                                  <div class="DiscussionRight">
                                    <h6>{ele.content}</h6>
                                    <small>
                                      {moment(ele.createdAt).format(
                                        "MM/DD/YYYY, [at] hh:mm A"
                                      )}
                                    </small>
                                  </div>
                                </div>
                              ))}
                            {index === ind && (
                              <div class="DiscussionBox w-100">
                                <span>FM</span>
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Add reply ....."
                                    name="subContent"
                                    value={subContent}
                                    onChange={(e) => handleInputChange(e)}
                                  />

                                  <div class="DiscussionOverlay">
                                    <a
                                      onClick={() =>
                                        handleRemoveComments("subcomment")
                                      }
                                      class="CancelChat"
                                    >
                                      <img
                                        src={require("../assets/images/plus-cancel1.png")}
                                      />
                                    </a>
                                    <a
                                      onClick={() =>
                                        handleSubCreateComment(item.id)
                                      }
                                      class="SendChat"
                                    >
                                      <img
                                        src={require("../assets/images/forwardbutton.png")}
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WarrantyRequestClientDetails;
