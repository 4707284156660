import React, { useEffect, useState } from 'react'
import PerFormaBlockInput from './PerFormaBlockInput'
import BlockQuanty from './BlockQuanty'
import BlockSummaryPreview from './BlockSummaryPreview'
import { useDispatch } from 'react-redux'
import { addProformaBlockStep1aAction, getPerformastep1block } from '../../../redux/action/proformaAction'

const PerformaBlock = ({
  blockFirstStepNumber,
  handleInputNumber,
  blockfirstStep,
  handleBlockFirstStepChange,
  checkArray,
  foundationListData,
  blockQuantitiesData,
  unitList,
  handleBlockQuantityChange,
  proformaBlockStep3Data,
  handleShowComponent,
  showli
}) => {

const[show,setShow]=useState({
  blockInput:false,
  blockQuanties:false,
  blockSummeryPreview:false
})

const handleShow=(component)=>{
setShow({
  blockInput:component==="blockInput",
  blockQuanties:component==="blockQuanties",
  blockSummeryPreview:component==="blockSummeryPreview"
})
}
useEffect(()=>{

setShow(prev=>({
  ...prev,
  blockInput:true
}))
},[])

  return (
    <div className="FolderFormCreateArea Proforma">
    <div className="StepBox Green">
      <ul>
        <li className={show.blockInput?"active":""} onClick={()=>handleShow("blockInput")}>
          <span className="Icon">
           <img src={require("../../../assets/images/block_input.png")} />
          </span>
          <span className="Text">Block Input</span>
        </li>
        <li className={show.blockQuanties?"active":""}  onClick={()=>handleShow("blockQuanties")}>
          <span className="Icon">
           <img src={require("../../../assets/images/block_quantities.png")} />
          </span>
          <span className="Text">
            Block <br /> Quantities
          </span>
        </li>
        <li className={show.blockSummeryPreview?"active":""} onClick={()=>handleShow("blockSummeryPreview")}>
          <span className="Icon">
           <img src={require("../../../assets/images/block_summary.png")} />
          </span>
          <span className="Text">
            Block <br /> Summary <br /> Preview
          </span>
        </li>
      </ul>
    </div>
   {
    show.blockInput && 
    <PerFormaBlockInput
      blockFirstStepNumber={blockFirstStepNumber}
      handleInputNumber={handleInputNumber}
      blockfirstStep={blockfirstStep}
      handleBlockFirstStepChange={handleBlockFirstStepChange}
      checkArray={checkArray}
      foundationListData={foundationListData}
      handleShowComponent={handleShowComponent}
      handleShow={handleShow}
    />
   }

   {
    show.blockQuanties && 
     <BlockQuanty  handleBlockQuantityChange={handleBlockQuantityChange}
      blockQuantitiesData={blockQuantitiesData}
      unitList={unitList}
      handleShow={handleShow}
      />
   }
  { show.blockSummeryPreview &&
    <BlockSummaryPreview
    proformaBlockStep3Data={proformaBlockStep3Data}
    handleShowComponent={handleShowComponent}
    handleShow={handleShow}
    />}
  </div>
  )
}

export default PerformaBlock