import React, { useEffect, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import ClientViewSidenav from "./client-view-sidenav";
import ClientHeader from "./ClientHeader";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  addAdjustment,
  clientDeleteSupportingDocs,
  createClientChangeRequest,
  getAdjustment,
  getChangeOrderList,
  getClientChangeRequestList,
  getHtmlFileClientView,
  removeAdjustment,
  updateClientChangeOrderDocsFiles,
} from "../redux/action/clientViewAuthAction";
import Loader from "./Loader";
import { Link } from "react-router-dom";
const initialState = {
  title: "",
  description: "",
  Quantity: "",
  sentBy: "",
  status: "",
  ClientChangeRequestModal: false,
  ClientChangeRequestListModal: false,
  ViewClientChangeRequestModal: false,
  ViewClientChangeDetails: [],
  expandText: "",
  ViewAggrementModal: false,
  ViewHistoryModal: false,
  ClientAgreementEsignModal: false,
  AddNewSupportingDocsModal: false,
  supportingDocFile: null,

  jobId: "",
  origin: "",
  ProjectId: "",
  itemId: "",
  supportingDocItemsData: [],
  SupportingDocumentsListModal: false,
  viewDocumentData: "",
  ClientAgreementData: [],
  RequestAdjustmentsModal: false,
  RequestAdjustmentsData: [],
  reqType: "",
  itemIds: "",
  quantity: "",
  itemName: "",
  adjustmentList: [],
  titles: "",
  descriptions: "",
};

const ChangeOrderListClient = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const {
    title,
    description,
    Quantity,
    sentBy,
    status,
    ClientChangeRequestModal,
    ClientChangeRequestListModal,
    ViewClientChangeRequestModal,
    ViewClientChangeDetails,
    expandText,
    ViewAggrementModal,
    ViewHistoryModal,
    ClientAgreementEsignModal,
    AddNewSupportingDocsModal,
    supportingDocFile,

    jobId,
    origin,
    ProjectId,
    itemId,
    supportingDocItemsData,
    SupportingDocumentsListModal,
    viewDocumentData,
    ClientAgreementData,
    RequestAdjustmentsModal,
    RequestAdjustmentsData,
    reqType,
    itemIds,
    quantity,
    itemName,
    adjustmentList,
    titles,
    descriptions,
  } = iState;

  const clientViewData = JSON.parse(window.localStorage.getItem("clientViewLogin"));

  const { changeRequestList, clientChangeOrdereList, changeOrderLoader } =
    useSelector((state) => state.clientViewLogin);
  // console.log("clientChangeOrdereList", clientChangeOrdereList);

  useEffect(() => {
    if(clientViewData){
    dispatch(
      getChangeOrderList(
        clientViewData?.jobDetails?.ProjectId,
        clientViewData?.jobDetails?.id,
        clientViewData?.jobDetails?.jobType
      )
    )}
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "itemIds") {
      let itemDataa = RequestAdjustmentsData?.items?.find(
        (data, i) => data.id == value
      );
      updateState({
        ...iState,
        itemIds: itemDataa?.id,
        itemName: itemDataa?.account,
      });
    } else {
      updateState({ ...iState, [name]: value });
    }
  };

  const handleClientChangeRequestModalShow = () => {
    updateState({ ...iState, ClientChangeRequestModal: true });
  };
  const handleClientChangeRequestModalClose = () => {
    updateState({ ...iState, ClientChangeRequestModal: false });
  };

  const handleCreateRequest = () => {
    let dataa = {
      title,
      description,
      Quantity,
      sentBy,
      status,
      clientId: clientViewData?.user?.id,
    };
    dispatch(
      createClientChangeRequest(clientViewData?.jobDetails?.ProjectId, dataa)
    ).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          ClientChangeRequestModal: false,
          title: "",
          description: "",
          Quantity: "",
          sentBy: "",
          status: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          ClientChangeRequestModal: true,
        });
      }
    });
  };

  const handleClientChangeRequestListModalShow = () => {
    updateState({ ...iState, ClientChangeRequestListModal: true });
  };

  useEffect(() => {
    if (ClientChangeRequestListModal) {
      dispatch(getClientChangeRequestList(clientViewData?.jobDetails?.ProjectId));
    }
  }, [ClientChangeRequestListModal]);
  const handleClientChangeRequestListModalClose = () => {
    updateState({ ...iState, ClientChangeRequestListModal: false });
  };

  const handleViewClientChangeRequestModalShow = (data) => {
    updateState({
      ...iState,
      ViewClientChangeRequestModal: true,
      ClientChangeRequestListModal: false,
      ViewClientChangeDetails: data,
    });
  };
  const handleViewClientChangeRequestModalClose = () => {
    updateState({
      ...iState,
      ViewClientChangeRequestModal: false,
      ClientChangeRequestListModal: true,
    });
  };

  const handleToggleExpand = (index) => {
    updateState({ ...iState, expandText: expandText === index ? null : index });
  };

  const handleViewAggrementModalShow = async (documentUrl) => {
    updateState({ ...iState, ViewAggrementModal: true });

    try {
      const urlWithoutQueryString = documentUrl?.split("?")[0];

      const htmlData = await dispatch(
        getHtmlFileClientView({ url: urlWithoutQueryString })
      );
      // console.log("Html", htmlData);

      if (documentUrl) {
        updateState({
          ...iState,
          viewDocumentData: htmlData?.data,
          ViewAggrementModal: true,
        });
      }
    } catch (error) {
      // Handle the error, e.g., log it or show an error message.
      console.error("Error fetching documentation:", error);
    }
  };
  const handleViewAggrementModalClose = () => {
    updateState({ ...iState, ViewAggrementModal: false });
  };

  const handleViewHistoryModalShow = (data) => {
    updateState({
      ...iState,
      ViewAggrementModal: false,
      ViewHistoryModal: true,
    });
  };
  const handleViewHistoryModalClose = () => {
    updateState({
      ...iState,
      ViewAggrementModal: true,
      ViewHistoryModal: false,
    });
  };

  const handleClientAgreementEsignModalShow = (data) => {
    updateState({
      ...iState,
      ClientAgreementEsignModal: true,
      ClientAgreementData: data,
    });
  };
  const handleClientAgreementEsignModalClose = () => {
    updateState({ ...iState, ClientAgreementEsignModal: false });
  };

  const handleFileChanges = (event) => {
    const file = event?.target?.files[0];
    if (file) {
      updateState({ ...iState, supportingDocFile: file });
    }
  };
  const handleRemoveDocsFile = () => {
    // Reset the 'templateFile' state to null
    updateState({
      ...iState,
      supportingDocFile: null,
    });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#docsInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleAddNewSupportingDocsModalShow = (item) => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: true,
      jobId: item.jobId,
      origin: item.changeOrderId,
      itemId: item.id,
      ProjectId: item.projectId,
      supportingDocFile: "",
    });
  };
  const handleAddNewSupportingDocsModalClose = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: false,
    });
  };

  const handleUpdateDocs = () => {
    const dataa = {
      file: supportingDocFile?.name,
      jobId,
      itemId,
      origin,
      type: supportingDocFile?.type,
    };
    dispatch(updateClientChangeOrderDocsFiles(ProjectId, dataa)).then((res) => {
      if (res.status === 200) {
        toast.success(res.data?.data, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AddNewSupportingDocsModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          AddNewSupportingDocsModal: true,
        });
      }
    });
  };

  const handleSupportingDocumentsListModalShow = (item) => {
    updateState({
      ...iState,
      SupportingDocumentsListModal: true,
      supportingDocItemsData: item,
    });
  };

  const handleSupportingDocumentsListModalClose = () => {
    updateState({
      ...iState,
      SupportingDocumentsListModal: false,
    });
  };

  const handleDeleteSupportingDocs = (projectId, id) => {
    dispatch(clientDeleteSupportingDocs(projectId, id)).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        const updatedSupportingDocItemsData = supportingDocItemsData?.filter(
          (item) => item.id !== id
        );
        updateState({
          ...iState,
          supportingDocItemsData: updatedSupportingDocItemsData,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleRequestAdjustmentsModalShow = (data) => {
    updateState({
      ...iState,
      RequestAdjustmentsModal: true,
      ClientAgreementEsignModal: false,
      RequestAdjustmentsData: data,
    });
  };
  const handleRequestAdjustmentsModalClose = () => {
    updateState({
      ...iState,
      ClientAgreementEsignModal: true,
      RequestAdjustmentsModal: false,
    });
  };

  useEffect(() => {
    if (RequestAdjustmentsModal) {
      dispatch(
        getAdjustment(
          RequestAdjustmentsData?.ProjectId,
          RequestAdjustmentsData?.id
        )
      ).then((res) => {
        if (res.status === 200) {
          updateState({ ...iState, adjustmentList: res?.data?.data });
        }
      });
    }
  }, [RequestAdjustmentsModal]);
  // console.log(" adjustmentList", adjustmentList);

  const handleAddAdjustment = () => {
    let dataa = {
      changeorderid: RequestAdjustmentsData?.id,
      jobId: RequestAdjustmentsData?.jobId,
      jobType: RequestAdjustmentsData?.jobType,
      type: reqType,
      title: titles,
      description: descriptions,
      itemId: itemIds,
      itemName,
      qty: quantity,
    };
    dispatch(addAdjustment(RequestAdjustmentsData?.ProjectId, dataa)).then(
      (res) => {
        if (res.status === 200) {
          dispatch(
            getAdjustment(
              RequestAdjustmentsData?.ProjectId,
              RequestAdjustmentsData?.id
            )
          ).then((res) => {
            if (res.status === 200) {
              updateState({
                ...iState,
                adjustmentList: res?.data?.data,
                reqType: "",
                reqType: "",
                qty: "",
                itemIds: "",
                titles:'',
                descriptions:""
              });
            }
          });
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  const handleRemoveAdjustment = (id) => {
    dispatch(removeAdjustment(RequestAdjustmentsData?.ProjectId, id)).then(
      (res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          const updatedAdjustmentList = adjustmentList?.filter(
            (item) => item.id !== id
          );
          updateState({
            ...iState,
            adjustmentList: updatedAdjustmentList,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  
  const [showTable, setShowTable] = useState(true);
  return (
    <div>
      <>
        <ClientHeader />
        <ClientViewSidenav />
        <div className="WrapperAreaClient">
          <div className="WrapperBoxClient">
            {/* <h6 data-toggle="modal" data-target="#AgreementView2">
              Agreement View 2
            </h6> */}
            <div className="MainTitleBox d-flex flex-sm-row align-items-sm-center flex-column align-items-start">
              <div className="MainTitle">
                <h2>Change orders</h2>
              </div>
              <div className="TodosButtons">
                <a onClick={handleClientChangeRequestModalShow}>
                  <h6>
                    <img src="images/create_new_client.png" />
                    Create New Client Change Request
                  </h6>
                  {/* <span>3</span> */}
                </a>
              </div>
            </div>
            <div className="TitleBox d-flex flex-lg-row align-items-lg-center flex-column align-items-start">
              <div className="TodosButtons mt-0 d-flex flex-row">
                <a
                  className="Create mb-2"
                  onClick={handleClientChangeRequestListModalShow}
                >
                  <img src="images/view_client_change_request.png" />
                  View Client Change Requests<span>3</span>
                </a>
                <a href="javascript:void(0)" className="ml-0 mb-2">
                  <h6 data-toggle="modal" data-target="#ViewChangeAgreement">
                    <img src="images/change_contract.png" />
                    Change To Contract Options Agreement
                  </h6>
                  <span data-toggle="modal" data-target="#HistoryModal">
                    3
                  </span>
                </a>
              </div>
              <div className="TitleBoxProjectHeading">
              <h6>
                <span>Project:</span>{clientViewData?.projectDetails?.projectName??"N/A"}
              </h6>
              <h6>
                <span>Unit:</span> {clientViewData?.projectDetails?.id??"N/A"}
              </h6>
            </div>
            </div>
            <div className="Tableheader">
              <h6>Change orders</h6>
              <div className="NewActions">
                <a href="javascript:void(0);">
                  <img src="images/icon-64.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/icon-53.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/download.png" />
                </a>
                {/* <a href="javascript:void(0);">
                  <img src="images/setting.png" />
                </a> */}
              </div>
            </div>
            <div className="TableList TableListHeader NewTableList TableListMaxHeight">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Description</th>
                    <th>Change Order Progress</th>
                    <th>Date Created</th>
                    <th>Client Signature Status</th>
                    <th>Builder Signature Status</th>
                    <th>Initial PO Total</th>
                    <th>Client Pre-Tax</th>
                    <th>Agreement/Adjustments</th>
                    <th>Supporting Documents</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!changeOrderLoader ? (
                    clientChangeOrdereList?.data?.length > 0 ? (
                      clientChangeOrdereList?.data?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item.id}</td>
                            <td>{item.description}</td>
                            <td>{item.orderProgress}</td>
                            <td>
                              {moment(item.createdAt).format(
                                "YYYY-MM-DD & hh:mm A"
                              )}
                            </td>
                            <td>{item.clientSignStatus}</td>
                            <td>{item.builderSignStatus}</td>
                            <td>${item.poLineTotal}</td>
                            <td>${item.clientPreTax}</td>
                            <td>
                              <img src="images/signature_needed.png" />
                              Signed Agreement
                            </td>
                            <td>
                              <div className="text-center">
                                <a
                                  onClick={() =>
                                    handleAddNewSupportingDocsModalShow(item)
                                  }
                                >
                                  <img src="images/notebook.png" />
                                </a>
                                <span
                                  className="Count"
                                  onClick={() =>
                                    handleSupportingDocumentsListModalShow(
                                      item.supportingDocItems
                                    )
                                  }
                                >
                                  3
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu ChangeOrderDropdown">
                                      <li>
                                        <Link to="/change-order-clientview-details">
                                          <img src="images/view-po.png" />
                                          View Change Order
                                        </Link>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleClientAgreementEsignModalShow(
                                              item
                                            )
                                          }
                                        >
                                          <img src="images/sign_agreement.png" />
                                          Sign Agreement
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleViewAggrementModalShow(
                                              item
                                                .changeOrderAgreementfiles?.[0]
                                                ?.agreement
                                            )
                                          }
                                        >
                                          <img src="images/view_agreement.png" />
                                          View Agreement
                                        </a>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) :(
                      <tr>
                        <td colSpan="10" className="text-center">
                          <p>No Data found.</p>
                        </td>
                      </tr>
                    )
                  ) : (
                    <Loader />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
      <Modal
        show={ClientChangeRequestModal}
        onHide={handleClientChangeRequestModalClose}
        className="ModalBox modal fade LargeModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleClientChangeRequestModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create New Client Change Request</h4>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="col-lg-5">
                  <div className="ModalBigheading">
                    <h3>
                      <span>Change Request #</span>
                    </h3>
                  </div>
                  <div className="form-group">
                    <h6>Title</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Samsung Appliances"
                      name="title"
                      value={title}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <h6>Description</h6>
                    <textarea
                      cols={30}
                      rows={8}
                      name="description"
                      value={description}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-lg-5 ml-auto">
                  <div className="ModalBigheading">
                    <h3 className="text-right">
                      <span>Project : </span>{clientViewData?.projectDetails?.projectName??"N/A"}
                    </h3>
                    <h3 className="text-right">
                      <span>Unit : </span> {clientViewData?.projectDetails?.id??"N/A"}
                    </h3>
                 
                  </div>
                  <div className="form-group">
                    <h6>Quantity</h6>
                    <input
                      type="number"
                      inputMode="numeric"
                      className="form-control"
                      name="Quantity"
                      value={Quantity}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group">
                    <h6>Sent By</h6>
                    <input
                      type="text"
                      className="form-control"
                      name="sentBy"
                      value={sentBy}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <h6>Date</h6>
                    <input
                      type="date"
                      className="form-control"
                      name="sentBy"
                      value={sentBy}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <h6>Status</h6>
                    <input
                      type="text"
                      className="form-control"
                      name="status"
                      value={status}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between mt-4">
                <button
                  className="Create"
                  onClick={() => handleCreateRequest("builder")}
                >
                  Create Request and Send to Builder
                </button>
                <button
                  className="Outline"
                  onClick={() => handleCreateRequest("create")}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ClientChangeRequestListModal}
        onHide={handleClientChangeRequestListModalClose}
        className="ModalBox modal fade LargeModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleClientChangeRequestListModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Client Change Request List</h4>
            </div>
            <div className="NotesArea mb-2 d-flex justify-content-end">
              <h6>{clientViewData?.jobDetails?.jobId??"N/A"}</h6>
            </div>
            <div className="NotesArea mb-2 flex-wrap">
              <h6>Client Change Requests</h6>
              <h6> {clientViewData?.jobDetails?.jobType??"N/A"}</h6>
            </div>
            <div className="TableList CommonHeading">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Date</th>
                    <th>Sent By</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {changeRequestList?.data?.length > 0
                    ? changeRequestList?.data?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item.id}</td>
                            <td>{item.title}</td>
                            <td>
                              <div>
                                {expandText ? (
                                  <>
                                    {item.description?.slice(0, 20)}...
                                    <img
                                      src="images/circle.png"
                                      className="ml-2"
                                      alt="Expand"
                                      onClick={() =>
                                        handleToggleExpand(item.id)
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    {item.description}
                                    {item.description?.length > 20 && (
                                      <img
                                        src="images/circle.png"
                                        className="ml-2"
                                        alt="Collapse"
                                        onClick={() =>
                                          handleToggleExpand(item.id)
                                        }
                                      />
                                    )}
                                  </>
                                )}
                              </div>
                            </td>
                            <td>{item.Quantity ?? 0}</td>
                            <td>
                              {moment(item.craetedAt).format("YYYY-MM-DD")}
                            </td>
                            <td>{item.sentBy}</td>
                            <td>{item.status}</td>
                            <td>
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      id="navbarDropdown"
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol
                                      className="dropdown-menu ChangeOrderDropdown"
                                      style={{}}
                                    >
                                      <li>
                                        <a
                                          className="FolderPermissionId"
                                          onClick={() =>
                                            handleViewClientChangeRequestModalShow(
                                              item
                                            )
                                          }
                                        >
                                          <img src="images/view-po.png" />
                                          View Client Change Request
                                        </a>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : (
                      <tr>
                        <td colSpan="5">No data found</td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ViewClientChangeRequestModal}
        onHide={handleViewClientChangeRequestModalClose}
        className="modal fade MediumModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleViewClientChangeRequestModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Client Change Request Details</h4>
            </div>
            <div className="ModalMainheading">
              <h3>Client Change Request </h3>
              <h3>{ViewClientChangeDetails?.id}</h3>
            </div>
            <div className="ModalMainheading d-flex justify-content-end">
              <h3>{clientViewData?.jobDetails?.jobType}</h3>
            </div>
            <div className="ClientChangeRequestTable">
              <table>
                <tbody>
                  <tr>
                    <td>Title</td>
                  </tr>
                  <tr>
                    <td>{ViewClientChangeDetails?.title}</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Description</td>
                    <td>Quantity</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>{ViewClientChangeDetails?.description}</td>
                    <td valign="top">
                      {ViewClientChangeDetails?.Quantity ?? 0}
                    </td>
                  </tr>
                  <tr>
                    <td>Send By</td>
                    <td>Date</td>
                    <td>Status</td>
                  </tr>
                  <tr>
                    <td>{ViewClientChangeDetails?.sentBy ?? "N/A"}</td>
                    <td>
                      {moment(ViewClientChangeDetails?.craetedAt).format(
                        "YYYY-MM-DD"
                      )}
                    </td>
                    <td>{ViewClientChangeDetails?.status}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="Button mt-5 text-center">
              <button
                className="Outline"
                onClick={handleViewClientChangeRequestModalClose}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ViewAggrementModal}
        onHide={handleViewAggrementModalClose}
        className="ModalBox LargeModal GreyModalContent"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleViewAggrementModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Agreement View</h4>
            </div>
            <div className="FolderForm">
              {viewDocumentData && (
                <CKEditor
                  editor={ClassicEditor}
                  // config={configObject}
                  readOnly={true}
                  data={viewDocumentData}
                />
              )}
              <div className="Button d-flex justify-content-between mt-2">
                <button
                  className="Create FolderPermissionId"
                  onClick={() => handleViewHistoryModalShow()}
                >
                  <img src="images/history_icon.png" />
                  History
                </button>
                <button
                  className="Outline"
                  onClick={handleViewAggrementModalClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ViewHistoryModal}
        onHide={handleViewHistoryModalClose}
        className="ModalBox modal fade MediumModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleViewHistoryModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Agreement History</h4>
            </div>
            <h6>Change order: #1</h6>
            <div className="JobTitleArea mb-2">
              <div className="JobBox">
                <h6>
                  <span>Job Id:&nbsp;</span>UUnit2-CHI-2B-314
                </h6>
              </div>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Modified By</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>John Doe</td>
                    <td>John Doe</td>
                    <td>04/25/2023</td>
                    <td>Release for use</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ClientAgreementEsignModal}
        onHide={handleClientAgreementEsignModalClose}
        className="ModalBox modal fade LargeModal GreyModalContent"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleClientAgreementEsignModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Client Agreement E-sign</h4>
            </div>
            <div className="ModalMainheading">
              <h3>Change Order #{ClientAgreementData?.id}</h3>
              <h3>{ClientAgreementData?.changeOrderId}</h3>
            </div>
            <div className="ModalMainheading d-flex justify-content-end">
              <h3>{ClientAgreementData?.jobType}</h3>
            </div>
            <div className="FolderForm">
              <div className="ModalClientDetailsArea">
                <div className="McdBoxMain">
                  <div className="McdBox">
                    <h6>
                      Description<span className="required">*</span>
                    </h6>
                    <p>{ClientAgreementData?.description}</p>
                  </div>
                </div>
                <h4>Client Details</h4>
                <div className="row">
                  <div className="col-md-6">
                    <div className="McdBoxMain">
                      <div className="McdBox">
                        <h6>Possession Date</h6>
                        <p>{ClientAgreementData?.possessionDate!=null&&moment(ClientAgreementData?.possessionDate).format("YYYY-MM-DD")}</p>
                      </div>
                      <div className="McdBox">
                        <h6>Request Date</h6>
                        <p>{moment(ClientAgreementData?.createdAt).format("YYYY-MM-DD")}</p>
                      </div>
                    </div>
                    <div className="McdBoxMain">
                      <div className="McdBox">
                        <h6>Client Full Legal Name</h6>
                        <p>{ClientAgreementData?.clientName??"N/A"}</p>
                      </div>
                      <div className="McdBox">
                        <h6>Phone Number</h6>
                        <p>{ClientAgreementData?.clientPhone??"N/A"}</p>
                      </div>
                    </div>
                    <div className="McdBoxMain">
                      <div className="McdBox">
                        <h6>
                          Primary Email
                          <img src="images/location.png" />
                        </h6>
                        <p>{ClientAgreementData?.clientEmail??"N/A"}</p>
                      </div>
                    </div>
                    <div className="McdBoxMain">
                      <div className="McdBox">
                        <h6>Job Address</h6>
                        <p>
                          # {ClientAgreementData?.jobAddress??"N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="Profile_ontario_modal">
                    <div className="Profile_ontario_box">
                        <h6>Profile:</h6>
                        <p>{ClientAgreementData?.taxProfile}</p>
                      </div>
                      <div className="Profile_ontario_box">
                        <h6>HST:</h6>
                        <p>{ClientAgreementData?.hst??0}%</p>
                      </div>
                    </div>
                    <div className="ModalAgreementViewArea">
                      <h4>Agreement View</h4>
                      <div className="MavMainBox">
                        <div className="MavBox">
                          <h6>Sign Document</h6>
                          <button
                            type="button"
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#SignAgreement"
                            data-dismiss="modal"
                          >
                            Sign Document
                          </button>
                        </div>
                        <div className="MavBox">
                          <h6>Request Adjustments</h6>
                          <button
                            type="button"
                            className="FolderPermissionId"
                            onClick={() =>
                              handleRequestAdjustmentsModalShow(
                                ClientAgreementData
                              )
                            }
                          >
                            Adjustments
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ChangeItemsTable">
                <h2 className="ChangeItemsTableShow" onClick={() => setShowTable(!showTable)}>
                  <img src="images/icon-34.png" />
                  Change Items
                </h2>
                <table style={{ display: showTable ? 'block' : 'none' }}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Description</th>
                      <th>Quantity</th>
                      <th>Measure</th>
                      <th>Per Unit</th>
                      <th>Line Total</th>
                      <th>Pictures</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      ClientAgreementData?.items?.length>0&&
                      ClientAgreementData?.items?.map((item,i)=>{
                        return(
                          <tr key={i}>
                          <td>{item.id}</td>
                          <td>{item.account}</td>
                          <td>{item.quantity??0}</td>
                          <td>{item.measure}</td>
                          <td>${item.perUnit}</td>
                          <td>${item.poLineTotal}</td>
                          <td>
                            <img src={item.picture??""} />
                          </td>
                        </tr>
                        )
                      })
                    }
                   
                  </tbody>
                </table>
              </div>
              <div className="TotalAfterTax">
                <div className="TotalAfterTaxBox">
                  <h6>Total</h6>
                  <p>$ {ClientAgreementData?.clientPreTax}</p>
                </div>
                <div className="TotalAfterTaxBox">
                  <h6>After Tax Total</h6>
                  <p>$ {ClientAgreementData?.poLineTotal}</p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="ModalBox fade SmallModal"
        show={AddNewSupportingDocsModal}
        onHide={handleAddNewSupportingDocsModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a
              className="CloseModal"
              onClick={handleAddNewSupportingDocsModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4> New Supporting Docs</h4>
              </div>
              <div className="JobTitleArea">
                <h3>Supporting Documents #2</h3>
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Origin</span> #185
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Item:</span> #143
                  </h6>
                </div>
              </div>
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input
                    type="file"
                    id="docsInput"
                    onChange={(e) => handleFileChanges(e)}
                  />
                </div>
              </div>
              <div className="FilesAddedTitle">
                <h4>Files</h4>
              </div>
              {supportingDocFile && (
                <div className="FilesAdded">
                  <label>{supportingDocFile?.name}</label>
                  <span>
                    <img src="images/fileview.png" />
                  </span>
                  <span onClick={handleRemoveDocsFile}>
                    <img src="images/Cross.png" className="cross" />
                  </span>
                </div>
              )}

              <div className="Button mt-3">
                <button className="Create" onClick={handleUpdateDocs}>
                  Add Documents
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="ModalBox fade LargeModal show"
        show={SupportingDocumentsListModal}
        onHide={handleSupportingDocumentsListModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a
              className="CloseModal"
              onClick={handleSupportingDocumentsListModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Supporting Documents List</h4>
              </div>
              <div className="JobTitleArea">
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                  <div>
                    <a
                    // onClick={() =>
                    //   handleAddNewSupportingDocsModalShow(taskId)
                    // }
                    >
                      <img src="images/notebook.png" />
                    </a>
                  </div>
                </div>
                <div className="JobBox">
                  <h6>
                    <span className="text-red">Origin</span> #185
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span className="text-red">Item:</span> #143
                  </h6>
                </div>
              </div>
              <div className="NotesAreaMain">
                <div className="NotesArea mt-4">
                  <h6>Supporting Documents List</h6>
                  <div>
                    <a href="javascript:void(0);">
                      <img src="images/pdf.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/download.png" />
                    </a>
                    {/* <a href="javascript:void(0);">
                      <img src="images/setting.png" />
                    </a> */}
                  </div>
                </div>
                <div className="TableList">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Sent by</th>
                        <th>Date</th>
                        <th>Notes</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {supportingDocItemsData?.length > 0
                        ? supportingDocItemsData?.map((data, ind) => (
                            <tr key={ind}>
                              <td>{ind + 1}</td>
                              <td>{data.sendBy ?? "N/A"}</td>
                              <td>
                                {moment(data.createdAt).format("YYYY-MM-DD")}
                              </td>
                              <td className="Inspection">
                                {data.notes ?? "N/A"}
                              </td>
                              <td>
                                <div className="Actions">
                                  {/* <a
                                      onClick={()=>handleUpdateNewSupportingDocsModalShow(data.parenttableId)}
                                    >
                                      <img
                                        src="images/Action-1.png"
                                        alt="Action 1"
                                      />
                                    </a> */}
                                  <a
                                    onClick={() =>
                                      handleDeleteSupportingDocs(
                                        data.projectId,
                                        data.id
                                      )
                                    }
                                  >
                                    <img src="images/delete.png" alt="Delete" />
                                  </a>
                                </div>
                              </td>
                            </tr>
                          ))
                        : "No data found"}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={RequestAdjustmentsModal}
        onHide={handleRequestAdjustmentsModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleRequestAdjustmentsModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Request Adjustments</h4>
            </div>
            <div className="ModalMainheading">
              <h3>Change Order #</h3>
              <h3>{RequestAdjustmentsData?.changeOrderId}</h3>
            </div>
            <div className="ModalMainheading d-flex justify-content-end">
              <h3>{RequestAdjustmentsData?.jobType}</h3>
            </div>
            <div className="FolderForm">
              <div className="ModalClientDetailsArea">
                <div className="McdBoxMain">
                  <div className="McdBox">
                    <h6>
                      Description<span className="required">*</span>
                    </h6>
                    <p>{RequestAdjustmentsData?.description}</p>
                  </div>
                </div>
              </div>
              <div className="ChangeItemsTable">
                <h2 className="ChangeItemsTableShow">
                  <img src="images/icon-34.png" />
                  Change Items
                </h2>
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Description</th>
                      <th>Quantity</th>
                      <th>Measure</th>
                      <th>Per Unit</th>
                      <th>Line Total</th>
                      <th>Pictures</th>
                    </tr>
                  </thead>
                  <tbody>
                    {RequestAdjustmentsData?.items?.length > 0 &&
                      RequestAdjustmentsData?.items?.map((data, ind) => {
                        return (
                          <tr key={ind}>
                            <td>{data.id}</td>
                            <td>{data.account}</td>
                            <td>{data.quantity}</td>
                            <td>{data.measure}</td>
                            <td>${data.perUnit}</td>
                            <td>${data.poLineTotal}</td>
                            <td>
                              {data.picture && <img src={data.picture} />}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="TotalAfterTax">
                <div className="TotalAfterTaxBox">
                  <h6>Total</h6>
                  <p>$ 42,000.00</p>
                </div>
                <div className="TotalAfterTaxBox">
                  <h6>After Tax Total</h6>
                  <p>$ 47,460.00</p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="ModalAdjustmentsArea">
                    <h1>Adjustments</h1>
                    <div className="form-group">
                      <h6>Type</h6>
                      <div className="Categories Categories1 w-100">
                        <select
                          name="reqType"
                          value={reqType}
                          onChange={handleInputChange}
                        >
                          <option value="">Select Type</option>
                          <option value="Remove Item">Remove Item</option>
                          <option value="Add Item">Add Item</option>
                          <option value="Edit Item">Edit Item</option>
                        </select>
                      </div>
                    </div>
                    {
                      (reqType==="Edit Item" || reqType==="Remove Item") &&
                      <div className="form-group">
                        <h6>Select item</h6>
                        <div className="Categories Categories2">
                          <div className="Categories_all Categories_all3 w-100">
                            <select
                              name="itemIds"
                              value={itemIds}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Items</option>
                              {RequestAdjustmentsData?.items?.length > 0 &&
                                RequestAdjustmentsData?.items?.map(
                                  (data, i) => {
                                    return (
                                      <option value={data.id} key={i}>
                                        {data.account}
                                      </option>
                                    );
                                  }
                                )}
                            </select>
                          </div>
                        </div>
                      </div>
                    }
                   
                      
                  

                    {reqType === "Edit Item" && (
                      <div className="form-group">
                        <h6>New Quantity</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert new quantity for the Item"
                          name="quantity"
                          value={quantity}
                          onChange={handleInputChange}
                        />
                      </div>
                    )}

                    {reqType === "Add Item" && (
                      <>
                        <div className="form-group">
                          <h6>Title</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Title"
                            name="titles"
                            value={titles}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="form-group">
                          <h6>Description</h6>
                          <textarea
                            cols={20}
                            rows={4}
                            name="descriptions"
                            value={descriptions}
                            onChange={handleInputChange}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="ModalAdjustmentsListArea">
                    <h1>Adjustments List</h1>
                    <div className="CurrentItemsArea">
                      <h5>Current Items</h5>
                      <table>
                        <thead>
                          <tr>
                            <th>Adjustment</th>
                            <th>Item</th>
                            <th>Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {adjustmentList?.length > 0 &&
                            adjustmentList?.filter((item)=>item.type==="Edit Item" || item.type ==="Remove Item")?.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <div
                                      onClick={() =>
                                        handleRemoveAdjustment(item.id)
                                      }
                                    >
                                      <p>
                                        {i + 1}) {item.type}
                                      </p>
                                      <img src="images/Cross.png" />
                                    </div>
                                  </td>
                                  <td>{item.itemName ?? "N/A"}</td>
                                  <td>{item.qty}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                    <div className="TotalAfterTax mt-3">
                      <div className="TotalAfterTaxBox">
                        <h6>Total</h6>
                        <p>$ 42,000.00</p>
                      </div>
                      <div className="TotalAfterTaxBox">
                        <h6>After Tax Total</h6>
                        <p>$ 47,460.00</p>
                      </div>
                    </div>
                    <div className="CurrentItemsArea mt-5">
                      <h5>New Items/Other</h5>
                      <table>
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>New Items/Other</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                          adjustmentList?.length > 0 &&
                          adjustmentList?.filter((item)=>item.type==="Add Item")?.map((data,ind)=>{
                            return(
                           <tr key={ind}>
                            <td>
                              <div onClick={() =>
                                        handleRemoveAdjustment(data.ind)
                                      }>
                                <p>{ind + 1}) {data.type}</p>
                                <img src="images/Cross.png" />
                              </div>
                            </td>
                            <td>{data.itemName ?? "N/A"}</td>
                          </tr>
                            )
                          })
                          }
                          
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="Button">
                    <button className="Create" onClick={handleAddAdjustment}>
                      Add Adjustment
                    </button>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="Button d-flex justify-content-between">
                    <button
                      className="Create"
                      data-toggle="modal"
                      data-target="#ConfirmationAdjustments"
                      data-dismiss="modal"
                    >
                      Confirm&amp;Send to Builder
                    </button>
                    <button
                      className="Outline"
                      data-toggle="modal"
                      data-target="#ClientAgreementEsign"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ChangeOrderListClient;
