import axios from "axios";
import { AdminNotificationConstant } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config/Config";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));





export const getNotificationHeadingInitate = () => ({
    type: AdminNotificationConstant.GET_NOTIFICATION_HEADING_INITIATE,
  });
  
  export const getNotificationHeadingSuccess = (data) => ({
    type: AdminNotificationConstant.GET_NOTIFICATION_HEADING_SUCCESS,
    payload: data,
  });
  
  export const getNotificationHeadingFailure = (data) => ({
    type: AdminNotificationConstant.GET_NOTIFICATION_HEADING_FAILURE,
    payload: data,
  });
  
  export function getNotificationHeadingAction( pid) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(getNotificationHeadingInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}admin/notification/getNotificationHeading?companyId=${iBuildLocalData?.user?.CompanyId}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            ////console.log("data", data);
            if (data && data.status == 200) {
              dispatch(getNotificationHeadingSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(getNotificationHeadingFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(getNotificationHeadingSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(getNotificationHeadingFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }


export const getAllNotificationInitate = () => ({
    type: AdminNotificationConstant.GET_NOTIFICATION_INITIATE,
  });
  
  export const getAllNotificationSuccess = (data) => ({
    type: AdminNotificationConstant.GET_NOTIFICATION_SUCCESS,
    payload: data,
  });
  
  export const getAllNotificationFailure = (data) => ({
    type: AdminNotificationConstant.GET_NOTIFICATION_FAILURE,
    payload: data,
  });
  
  export function getAllNotificationAction( pid) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(getAllNotificationInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}admin/notification/getNotification?companyId=${iBuildLocalData?.user?.CompanyId}&notificationEventId=${49}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            ////console.log("data", data);
            if (data && data.status == 200) {
              dispatch(getAllNotificationSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(getAllNotificationFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(getAllNotificationSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(getAllNotificationFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }



  

export const getNestedNotificationInitate = () => ({
  type: AdminNotificationConstant.GET_NESTED_NOTIFICATION_INITIATE,
});

export const getNestedNotificationSuccess = (data) => ({
  type: AdminNotificationConstant.GET_NESTED_NOTIFICATION_SUCCESS,
  payload: data,
});

export const getNestedNotificationFailure = (data) => ({
  type: AdminNotificationConstant.GET_NESTED_NOTIFICATION_FAILURE,
  payload: data,
});

export function getNestedNotificationAction( pid) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getNestedNotificationInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}admin/notification/getNestedNotification?companyId=${iBuildLocalData?.user?.CompanyId}&notificationEventId=${pid}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getNestedNotificationSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getNestedNotificationFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getNestedNotificationSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getNestedNotificationFailure(err));
            reject(err);
          }
         
        });
    });
  };
}





export const getNotificationTemplateInitate = () => ({
  type: AdminNotificationConstant.GET_NESTED_NOTIFICATION_INITIATE,
});

export const getNotificationTemplateSuccess = (data) => ({
  type: AdminNotificationConstant.GET_NESTED_NOTIFICATION_SUCCESS,
  payload: data,
});

export const getNotificationTemplateFailure = (data) => ({
  type: AdminNotificationConstant.GET_NESTED_NOTIFICATION_FAILURE,
  payload: data,
});

export function getNotificationTemplateAction( pid) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getNotificationTemplateInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}admin/notification/getNotificationTemplate?notificationId=${49}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getNotificationTemplateSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getNotificationTemplateFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getNestedNotificationSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getNestedNotificationFailure(err));
            reject(err);
          }
         
        });
    });
  };
}





export const editNotificationInitiate = () => ({
  type: AdminNotificationConstant.EDIT_NOTIFICATION_INITIATE,
});

export const editNotificationSuccess = (data) => ({
  type: AdminNotificationConstant.EDIT_NOTIFICATION_SUCCESS,
  payload: data,
});

export const  editNotificationFailure = (data) => ({
  type: AdminNotificationConstant.EDIT_NOTIFICATION_FAILURE,
  payload: data,
});

export const editNotificationAction = (folderId, id, payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(editNotificationSuccess(payload))
    const response = await axios.patch(
      `${Url}admin/notification/editNotification`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(editNotificationSuccess(response.data));
    } else {
      dispatch(editNotificationFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(editNotificationFailure(err));
    return err.response;
  }
};






export const editNotificationStatusInitiate = () => ({
  type: AdminNotificationConstant.EDIT_NOTIFICATION_STATUS_INITIATE,
});

export const editNotificationStatusSuccess = (data) => ({
  type: AdminNotificationConstant.EDIT_NOTIFICATION_STATUS_SUCCESS,
  payload: data,
});

export const  editNotificationStatusFailure = (data) => ({
  type: AdminNotificationConstant.EDIT_NOTIFICATION_STATUS_FAILURE,
  payload: data,
});

export const editNotificationStatusAction = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(editNotificationStatusSuccess(payload))
    const response = await axios.patch(
      `${Url}admin/notification/editNotification`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(editNotificationStatusSuccess(response.data));
    } else {
      dispatch(editNotificationStatusFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(editNotificationStatusFailure(err));
    return err.response;
  }
};




export const addAssignUserInitiate = () => ({
  type: AdminNotificationConstant.ADD_ASSIGN_USER_INITIATE,
});

export const addAssignUserSuccess = (data) => ({
  type: AdminNotificationConstant.ADD_ASSIGN_USER_SUCCESS,
  payload: data,
});

export const  addAssignUserFailure = (data) => ({
  type: AdminNotificationConstant.ADD_ASSIGN_USER_FAILURE,
  payload: data,
});

export const addAssignUserAction = (folderId, id, payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(addAssignUserSuccess(payload))
    const response = await axios.patch(
      `${Url}admin/notification/editNotification`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(addAssignUserSuccess(response.data));
    } else {
      dispatch(addAssignUserFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(addAssignUserFailure(err));
    return err.response;
  }
};




export const configuredNotificationInitiate = () => ({
  type: AdminNotificationConstant.CREATE_NOTIFICATION_INITIATE,
});

export const configuredNotificationSuccess = (data) => ({
  type: AdminNotificationConstant.CREATE_NOTIFICATION_SUCCESS,
  payload: data,
});

export const configuredNotificationFailure = (data) => ({
  type: AdminNotificationConstant.CREATE_NOTIFICATION_FAILURE,
  payload: data,
});

export const ConfiguredNotificationAction = (id ,payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(configuredNotificationSuccess(payload))
    const response = await axios.patch(
      `${Url}admin/notification/configureNotification/${id}`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(configuredNotificationSuccess(response.data));
    } else {
      dispatch(configuredNotificationFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(configuredNotificationFailure(err));
    return err.response;
  }
};








export const quicksBookInitate = () => ({
  type: AdminNotificationConstant.QUICK_BOOK_INITIATE,
});

export const quicksBookSuccess = (data) => ({
  type: AdminNotificationConstant.QUICK_BOOK_SUCCESS,
  payload: data,
});

export const quicksBookFailure = (data) => ({
  type: AdminNotificationConstant.QUICK_BOOK_FAILURE,
  payload: data,
});

export function quicksBookAction( pid) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(quicksBookInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}company/${iBuildLocalData?.user?.id}/authUri`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(quicksBookSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(quicksBookFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
  
            dispatch(quicksBookFailure(err));
            reject(err);
         
        });
    });
  };
}

