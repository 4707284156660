import React from "react";
import Modal from "react-bootstrap/Modal";

const BellView = ({ BellViewModal, SetBellViewModal,element }) => {
  const handleBellViewModalClose = () => {
    SetBellViewModal(false);
  };

  return (
    <Modal show={BellViewModal} className="ModalBox SmallModal BgClass">
      <a
        onClick={handleBellViewModalClose}
        class="CloseModal ClosePermissionId"
      >
        ×
      </a>
      <div class="FormArea">
        <div class="ModalTitleBox">
          <h4>View Notification</h4>
        </div>
        <div class="FolderForm">
          <form>
            <div class="form-group">
              <h6>Event</h6>
              <h6 class="fw-400 fw-14">{element?.eventName}</h6>
            </div>
            <div class="form-group">
              <h6>Notification Type</h6>
              <p class="ml-0">Bell Icon</p>
            </div>
            <div class="form-group">
              <h6>Assign Users</h6>
            </div>
            <div class="form-group">
              <h6>Assign User Roles</h6>
              <p class="ml-0">Estimator</p>
            </div>
            <div class="form-group">
              <h6>Originating User Only</h6>
              <p class="ml-0">On</p>
            </div>
            <div class="ModalBigheading">
              <h3>
                <span>Notification Details</span>
              </h3>
            </div>
            <div class="CommonModalArea">
              <div class="form-group">
                <h5>Text</h5>
                <div class="CommonModalBox">
                  <h5 class="CursorPointer text-black">
                    <figure>
                      <img src="images/quoted.png" />
                    </figure>{" "}
                    Quoted
                  </h5>
                </div>
              </div>
            </div>
            <div class="Button text-center mt-5">
              <button class="Outline">Close</button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default BellView;
