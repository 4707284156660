import React from 'react'

const LoginForgot = () => {
  return (
    <div className="LoginArea">
    <div className="LoginBoxBig">
      <div className="row">
        <div className="col-md-6">
          <div className="LoginBoxLeft">
            <figure>
              <img src="images/login_background.png" />
            </figure>
          </div>
        </div>
        <div className="col-md-6">
          <div className="LoginBox">
            <div className="Logo">
              <img src="images/Logo.png" />
            </div>
            <ul className="nav nav-fill">
              <li className="nav-item">
                <a
                  href="#ClientView"
                  className="nav-link active"
                  data-toggle="tab"
                >
                  Client View
                </a>
              </li>
              <li className="nav-item">
                <a href="#TradeView" className="nav-link" data-toggle="tab">
                  Trade View
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="ClientView">
                <h3>Recover your account</h3>
                <p className="mb-4">
                  Enter email associated with your account and follow the
                  instructions
                </p>
                <form>
                  <div className="form-group mb-5">
                    <label>Enter Email</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter text"
                    />
                  </div>
                  <a className="Button" href="create_new_password.html">
                    Send Reset Email
                  </a>
                  <a className="ButtonOutline mt-3" href="javascript:void(0);">
                    Cancel
                  </a>
                </form>
              </div>
              <div className="tab-pane fade" id="TradeView">
                <h3>Recover your account</h3>
                <p className="mb-4">
                  Enter email associated with your account and follow the
                  instructions
                </p>
                <form>
                  <div className="form-group mb-5">
                    <label>Enter Email</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter text"
                    />
                  </div>
                  <a className="Button" href="create_new_password.html">
                    Send Reset Email
                  </a>
                  <a className="ButtonOutline mt-3" href="javascript:void(0);">
                    Cancel
                  </a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  )
}

export default LoginForgot