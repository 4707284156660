import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import htmlDocx from "html-docx-js/dist/html-docx";
import { useDispatch, useSelector } from "react-redux";
import SignaturePad from "react-signature-canvas";
import { Editor } from "@tinymce/tinymce-react";
import {
  createNewFileDocs,
  createWarrantySignatureDocs,
  getAllServiceTypeList,
  getAllWarrantyList,
  getAllWorkOrderList,
  getCreateWarrantyFolder,
  getDeleteWarranty,
  getInspectorList,
  getNewDocumentList,
  getNewFileListSearchDocumentation,
  getSupportingDocsList,
  getWarrantyFolderHistoryList,
  getWarrantyFolderList,
  getWarrantyFolderSearchList,
  getWarrantyHistoryList,
  getWarrantyPendingEventsList,
  getWarrantySupportDocUpdate,
  getWarrantyViewDocs,
  getWorkOrderDelete,
  removeWarrantyFolder,
  updateServiceTypes,
  warrantyUpdateDocs,
} from "../redux/action/warrantyAction";
import moment from "moment";
import Loader from "./Loader";
import {
  getHtmlFile,
  getShareDocumentationAdd,
} from "../redux/action/fileManagerAction";
import { Link, useLocation } from "react-router-dom";
import { getAllProjectList } from "../redux/action/vendorViewConfigAction";
import { getConverToString } from "../redux/action/saleAction";
import { uploadAggrementFiles } from "../redux/action/designSelectionsAction";

const initialState = {
  FileManagerModal: false,
  CreateFolderModal: false,
  folderName: "",
  FolderDeleteModal: false,
  warntId: "",
  FolderHistoryModal: false,
  NewFileModal: false,
  fileFolderName: "",
  clientFile: "",
  errors: {},
  id: "",
  TemplateActivityModal: false,
  DocumentHistoryModal: false,
  viewDocumentData: "",
  ViewDocumentationModal: false,

  emailBody: "",
  emails: [],
  clientName: "",
  clientEmail: "",
  ShareClientDocumentationModal: false,
  folderId: "",

  signature: "",
  initial: "",
  ManageSignatueModal: false,
  content: "",
  EditDocumentationModal: false,
  SendDocumentModal: false,
  sendClientInformationModal: false,
  AddNewSupportingDocsModal: false,
  supportingDocFiless: null,
  SupportingDocumentsListModal: false,
  WarrantyDeleteModal: false,
  WorkOrderDeleteModal: false,
  workId: "",
  tabChange: false,
  NavigateModal: false,
  ServicTypeModal: false,
  requestedData:[],
  serviceType:"",
  serviceName:"",
  AssignInspectorModal:false,
  inspector:"",
  inspectorName:"",
  htmlUrl:"",
  fileName:"",
  DocumentKeyModal:false,
  draft:false,
  HeaderPreviewModal: false,
};

const Warranty_Dashboard = () => {
  const dispatch = useDispatch();
  const signCanvas = useRef({});
  const intialCanvas = useRef({});
  const [iState, updateState] = useState(initialState);
  const {
    FileManagerModal,
    CreateFolderModal,
    folderName,
    FolderDeleteModal,
    warntId,
    FolderHistoryModal,
    NewFileModal,
    fileFolderName,
    clientFile,
    errors,
    id,
    TemplateActivityModal,
    DocumentHistoryModal,
    viewDocumentData,
    ViewDocumentationModal,

    emailBody,
    emails,
    clientName,
    clientEmail,
    ShareClientDocumentationModal,
    folderId,
    signature,
    initial,
    ManageSignatueModal,
    content,
    EditDocumentationModal,
    SendDocumentModal,
    sendClientInformationModal,
    AddNewSupportingDocsModal,
    supportingDocFiless,
    SupportingDocumentsListModal,
    WarrantyDeleteModal,
    WorkOrderDeleteModal,
    workId,
    tabChange,
    NavigateModal,
    ServicTypeModal,
    requestedData,
    serviceType,
    serviceName,
    AssignInspectorModal,
    inspector,
    htmlUrl,
    fileName,
    DocumentKeyModal,
    draft,
    HeaderPreviewModal,
  } = iState;

  const {
    warrantyFolderList,
    warrantyFolderSearchList,
    warrantyFolderHistoryList,
    newfileDocsList,
    fileLoader,
    newfileSearchDocsList,
    warrantyHistoryList,
    warrantyPendingFileEventsList,
    pendingLoader,
    allWarrantyList,
    allWorkOrderList,
    warrantyLoader,
    workLoader,
    supportDocsList,
    supportDocsLoader,
    allServiceTypeList,
    inspectorList,
    inspectorName,
  } = useSelector((state) => state.warrantyReducer);

  const { state, pathname } = useLocation();
//  console.log("warrantyHistoryList",warrantyHistoryList)

  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

  const { allProjectList } = useSelector(
    (state) => state.vendorViewConfigReducer
  );

  useEffect(() => {
    dispatch(getAllProjectList(iBuildLocalData?.user?.CompanyId));
    dispatch(getWarrantyFolderList(state?.id, state?.jobType, state?.ProjectId));
    dispatch(getAllWarrantyList(state?.id, state?.jobType, state?.ProjectId));
    dispatch(getAllWorkOrderList(state?.id, state?.jobType));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if(name==="serviceType"){
      let serviceData=allServiceTypeList?.warrantyOptions?.find((item)=>item.id==value)
      updateState({
        ...iState,
        [name]: value,
        serviceName:serviceData?.name
      });
    }
    else if(name==="inspector"){
      let inspectorData=inspectorList?.approverList?.find((item)=>item.id==value)
      updateState({
        ...iState,
        [name]: value,
        inspectorName:inspectorData?.name
      });
    }
    else{
      updateState({
        ...iState,
        [name]: value,
      });
    }
   
  };

  const [searchName, setSearchName] = useState("");
  const [searchFolderName, setSearchFolderName] = useState("");
  useEffect(() => {
    if (searchName) {
      dispatch(
        getWarrantyFolderSearchList(
          { name: searchName },
          state?.id,
          state?.jobType
        )
      );
    }
  }, [searchName, dispatch]);
  const [folderIDD, setFolderIDD] = useState("");
  const handleDocument = (e,id) => {
    dispatch(getNewDocumentList(id));
    window.localStorage.setItem("foldersID", JSON.stringify(id));
    e.stopPropagation();
    setFolderIDD(id);
    dispatch(getWarrantyPendingEventsList(id));
  };

  useEffect(() => {
    const id = JSON.parse(window.localStorage.getItem("foldersID"));

    if (searchFolderName) {
      dispatch(
        getNewFileListSearchDocumentation(id, { name: searchFolderName })
      );
    }
  }, [searchFolderName, id, dispatch]);

  const handleFileManagerShow = () => {
    updateState({
      ...iState,
      FileManagerModal: true,
    });
  };

  const handlehandleFileManagerClose = () => {
    updateState({
      ...iState,
      FileManagerModal: false,
    });
  };

  const handleCreateFolderShow = () => {
    updateState({
      ...iState,
      CreateFolderModal: true,
      folderName: "",
      FileManagerModal: false,
    });
  };

  const handleCreateFolderClose = () => {
    updateState({
      ...iState,
      CreateFolderModal: false,
      folderName: "",
      FileManagerModal: true
    });
  };
  const handleCreateFolderSubmit = async (e) => {
    e.preventDefault();
    // const formIsValid = handleValidation();
    // if (!formIsValid) return;
    updateState({ ...iState, loading: true });  
    const dataa = { folderName };

    try {
      const response = await dispatch(
        getCreateWarrantyFolder(
          state?.id,
          state?.jobType,
          state?.ProjectId,
          dataa
        )
      );
  
      if (response.status === 201) {
        await dispatch(getWarrantyFolderList(state?.id, state?.jobType, state?.ProjectId));
        toast.success("Folder created successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          folderName: "",
          CreateFolderModal: false,
          FileManagerModal: true,
        });
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });

      updateState({ ...iState, loading: false, CreateFolderModal: true });
    }
  };
  


  const handleFolderDeleteShow = (id) => {
    updateState({
      ...iState,
      FolderDeleteModal: true,
      warntId: id,
      FileManagerModal:false,
    });
  };

  const handleFolderDeleteClose = () => {
    updateState({
      ...iState,
      FolderDeleteModal: false,
      warntId: "",
      FileManagerModal: true,
    });
  };

  const handleFolderDelete = () => {
    let dataa = { warntId };
    dispatch(removeWarrantyFolder(dataa, state?.id, state?.jobType)).then(
      (res) => {
        if (res.status === 200) {
          dispatch(getWarrantyFolderList(state?.id, state?.jobType, state?.ProjectId));
          toast.success(" Delete Folder Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            FolderDeleteModal: false,
            warntId: "",
            FileManagerModal: true,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            FolderDeleteModal: true,
          });
        }
      }
    );
  };

  const handleFolderHistoryModalShow = (id) => {
    updateState({
      ...iState,
      FolderHistoryModal: true,
      FileManagerModal: false,
    });
    dispatch(getWarrantyFolderHistoryList(id, state?.id, state.jobType));
  };

  const handleFolderHistoryModalClose = () => {
    updateState({
      ...iState,
      FolderHistoryModal: false,
      FileManagerModal: true,
    });
  };

  const handleNewFileShow = () => {
    updateState({
      ...iState,
      NewFileModal: true,
      FileManagerModal: false,
      errors: {},
      fileFolderName: "",
      clientFile: "",
      id: "",
    });
  };

  const handleNewFileClose = () => {
    updateState({
      ...iState,
      NewFileModal: false,
      errors: {},
      fileFolderName: "",
      clientFile: "",
      id: "",
      FileManagerModal: true,
    });
  };

  const handleNewFileValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!fileFolderName) {
      error.nameError = "* Name can't be empty";
      formIsValid = false;
    }

    if (!id) {
      error.foldNameError = "* Please select folder name";
      formIsValid = false;
    }

    if (!clientFile) {
      error.cltfileError = "* Please select file";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: error,
    });
    return formIsValid;
  };

  const handleRemoveClientFile = () => {
    // Reset the 'templateFile' state to null
    updateState({ ...iState, clientFile: null });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#createFileInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleFileChange = (event, type) => {
    const file = event?.target?.files[0];
    if (type === "sign1") {
      updateState({ ...iState, signature: file });
    } else if (type === "sign2") {
      updateState({ ...iState, initial: file });
    } else if (type === "template") {
      updateState({ ...iState, templateFile: file });
    } else {
      updateState({ ...iState, clientFile: file,fileFolderName:file?.name });
    }
  };

  const handleNewFileSubmit = () => {
    let formIsValid = handleNewFileValidation();
    if (formIsValid) {
      const dataa = {
        name: fileFolderName,
        files: clientFile,
        clientView: true,
      };
      dispatch(createNewFileDocs(id, dataa))
        .then((res) => {
          if (res.status === 201) {
            dispatch(getNewDocumentList(id));
            toast.success("Create File Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            updateState({
              ...iState,
              NewFileModal: false,
              FileManagerModal: true,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({ ...iState, NewFileModal: true });
          }
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, NewFileModal: true });
        });
    }
  };

  const handleTemplateActivityModalShow = (id) => {
    updateState({
      ...iState,
      TemplateActivityModal: true,
      FileManagerModal: false,
    });
  };

  const handleTemplateActivityModalClose = () => {
    updateState({
      ...iState,
      TemplateActivityModal: false,
      FileManagerModal: true,
    });
  };

  const handleDocumentHistoryModalShow = (foldid,docsID,name) => {
    dispatch(getWarrantyHistoryList(foldid, docsID))
    updateState({
      ...iState,
      DocumentHistoryModal: true,
      FileManagerModal: false,
      fileName:name
    });
  };

  const handleDocumentHistoryModalClose = () => {
    updateState({
      ...iState,
      DocumentHistoryModal: false,
      FileManagerModal: true,
    });
  };

  const handleViewDocumentationShow = async (item) => {
    try {
      const urlWithoutQueryString = item?.htmlUrl?.split("?")[0];
      const htmlData = await dispatch(
        getConverToString(urlWithoutQueryString, iBuildLocalData?.user?.token)
      );


      if (htmlData) {
        updateState({
          ...iState,
          viewDocumentData: htmlData?.data?.data,
          ViewDocumentationModal: true,
          FileManagerModal: false,
        });
      }
    } catch (error) {
      // Handle the error, e.g., log it or show an error message.
      console.error("Error fetching documentation:", error);
    }
  };

  const handleViewDocumentationClose = () => {
    updateState({
      ...iState,
      ViewDocumentationModal: false,
      viewDocumentData: "",
      FileManagerModal: true,
    });
  };

 
  
  const [contentData, setContentData] = useState([]);
  const handleEditDocumentationShow = async (item) => {
   
    setContentData(item);
    try {
      const urlWithoutQueryString = item?.htmlUrl?.split("?")[0];
      const htmlData = await dispatch(
        getConverToString(urlWithoutQueryString, iBuildLocalData?.user?.token)
      );
      if (htmlData) {
        updateState({
          ...iState,
          EditDocumentationModal: true,
          content: htmlData?.data?.data,
          FileManagerModal:false,
        });
      }
    } catch (error) {
      console.error("Error fetching documentation:", error);
    }
  };

  const handleEditDocumentationClose = () => {
    updateState({
      ...iState,
      EditDocumentationModal: false,
      FileManagerModal:true
    });

  };


  const editorsRef = useRef(null);

  const handleEditorChange = (newContent) => {
    updateState({ ...iState, content: newContent });
  };

  const handleEditDocumentation = async () => {
    updateState({ ...iState, loading: true });
    const blob = new Blob([content], { type: "text/html" });
    const file = new File([blob], "content.html");

    const initialFormData = new FormData();
    initialFormData.append("files", file);
    try {
      const uploadResponse = await dispatch(
        uploadAggrementFiles(initialFormData)
      );
    

      if (uploadResponse.status === 200) {
        const formData = new FormData();
        const docxBlob = htmlDocx.asBlob(content);
        const filesName = `${contentData?.name}`;
        const docxFile = new File([docxBlob], filesName, {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        // saveAs(docxBlob, filesName);
        formData.append("files", docxFile);
        formData.append("htmlUrl", uploadResponse?.data?.data);
        formData.append("draft", draft);
        formData.append("name", contentData?.name);

        const signatureResponse = await dispatch(
          warrantyUpdateDocs(
            contentData?.folderId,
            contentData?.id,
            formData
          )
        );

        if (signatureResponse.status === 200) {
          dispatch(getWarrantyFolderList(state?.id, state?.jobType, state?.ProjectId));
          toast.success("Docs Updated Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            EditDocumentationModal: false,
            loading: false,
            content: "",
            FileManagerModal:true
          });
         
        } else {
          throw new Error(signatureResponse.data.message);
        }
      } else {
        throw new Error(uploadResponse.data.message);
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      updateState({
        ...iState,
        loading: false,
        EditDocumentationModal: true,
      });
    }
  };

  const handleDocumentKeyModalShow = () => {
    updateState({
      ...iState,
      DocumentKeyModal: true,
      JobId: "",
      EditDocumentationModal: false,
    });
  };

  const handleDocumentKeyModalClose = () => {
    updateState({
      ...iState,
      DocumentKeyModal: false,
      JobId: "",
      EditDocumentationModal: true,
    });
  };

  const handleItemClick = (item) => {
    const updatedContent = item ? item + content : content;
    updateState({
      ...iState,
      content: updatedContent,
      DocumentKeyModal: false,
      EditDocumentationModal: true,
    });
  };
  const handleShareClientDocumentShow = (foldId, Id) => {
    updateState({
      ...iState,
      ShareClientDocumentationModal: true,
      FileManagerModal: false,
      emailBody: "",
      emails: [],
      clientName: "",
      clientEmail: "",
      folderId: foldId,
      id: Id,
    });
  };

  const handleShareClientDocumentClose = () => {
    updateState({
      ...iState,
      ShareClientDocumentationModal: false,
      emailBody: "",
      emails: [],
      clientName: "",
      clientEmail: "",
      FileManagerModal: true,
    });
  };

  const handleAddRecipient = () => {
    const { clientName, clientEmail } = iState;

    // Create a copy of the emails array
    const updatedEmails = [...iState.emails];

    // Push a new recipient object with the clientName and clientEmail values
    updatedEmails.push({ name: clientName, email: clientEmail });

    // Update the state with the modified emails array and reset clientName and clientEmail
    updateState({
      ...iState,
      emails: updatedEmails,
      clientName: "",
      clientEmail: "",
    });
  };

  // Function to remove a recipient at a given index
  const handleRemoveRecipient = (indexToRemove) => {
    // Create a copy of the emails array
    const updatedEmails = [...iState.emails];

    // Use the filter method to create a new array without the item at the specified index
    const updatedEmailsFiltered = updatedEmails.filter(
      (_, index) => index !== indexToRemove
    );

    // Update the state with the modified emails array
    updateState({ ...iState, emails: updatedEmailsFiltered });
  };

  const handleShareDocumentValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!emailBody) {
      error.eBodyError = "* Message can't be empty";
      formIsValid = false;
    }

    // if (!clientName) {
    //   error. = "* Name can't be empty";
    //   formIsValid = false;
    // }

    // if (!clientEmail) {
    //   error.cliEmailError = "*  can't be empty";
    //   formIsValid = false;
    // }
    if (emails?.length == 0) {
      error.cliNameError = "* Name & Email can't be empty";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: error,
    });
    return formIsValid;
  };

  const handleShareDocumentSubmit = () => {
    let formIsValid = handleShareDocumentValidation();
    if (formIsValid) {
      const dataa = {
        emailBody,
        emails,
      };

      dispatch(getShareDocumentationAdd(folderId, id, dataa))
        .then((res) => {
          if (res.status === 200) {
            // dispatch(getListDocumentation(folderId));
            toast.success("Share Document Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            updateState({
              ...iState,
              ShareClientDocumentationModal: false,
              FileManagerModal: true,
              folderId: "",
              id: "",
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({
              ...iState,
              loading: false,
              ShareClientDocumentationModal: true,
              FileManagerModal: false,
            });
          }
        })
        .catch((error) => {
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            loading: false,
            ShareClientDocumentationModal: true,
            FileManagerModal: false,
          });
        });
    }
  };

  const handleManageSignatueShow = () => {
    updateState({
      ...iState,
      ManageSignatueModal: true,
      FileManagerModal: false,
      signature: "",
      initial: "",
      errors: {},
    });
  };

  const handleManageSignatueClose = () => {
    updateState({
      ...iState,
      ManageSignatueModal: false,
      FileManagerModal: true,
      signature: "",
      initial: "",
      errors: {},
    });
  };

  const handleSignatureValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!signature) {
      error.signatureError = "* Signature can't be empty";
      formIsValid = false;
    }

    if (!initial) {
      error.initialError = "* Initial can't be empty";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: error,
    });
    return formIsValid;
  };

  const clearSignature = () => {
    signCanvas.current.clear();
  };
  const clearInitials = () => {
    intialCanvas.current.clear();
  };

  const addSignatureFun = () => {
    let formIsValid = handleSignatureValidation();
    if (formIsValid) {
      dispatch(
        createWarrantySignatureDocs(state?.id, state?.jobType, {
          signature,
          initial,
          imageOwner: "test",
        })
      )
        .then((res) => {
          toast.success("Sign Uploded Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            ManageSignatueModal: false,
            FileManagerModal: true,
          });
        })
        .catch((error) => {
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, ManageSignatueModal: true });
        });
    }
  };

  const handleSendDocumentModalShow = (item) => {
    updateState({
      ...iState,
      SendDocumentModal: true,
      FileManagerModal: false,
    });
  };

  const handleSendDocumentModalClose = () => {
    updateState({
      ...iState,
      SendDocumentModal: false,
      FileManagerModal: true,
    });
  };
  const handleSendClientInformationModalShow = (item) => {
    updateState({
      ...iState,
      sendClientInformationModal: true,
      FileManagerModal: false,
    });
  };
  const handleSendClientInformationModalClose = () => {
    updateState({
      ...iState,
      sendClientInformationModal: false,
      FileManagerModal: true,
    });
  };

  const handleFileChanges = (event, type) => {
    const file = event?.target?.files[0];
    if (type === "docsUpdate") {
      updateState({ ...iState, supportingDocFiless: file });
    }
  };

  const handleRemoveDocsFile = () => {
    // Reset the 'templateFile' state to null
    updateState({ ...iState, supportingDocFiless: null });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#docsInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleAddNewSupportingDocsModalShow = (id) => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: true,
      warntId: id,
    });
  };
  const handleAddNewSupportingDocsModalClose = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: false,
    });
  };

  const handleSupportDocs = () => {
    let dataa = {
      supportingDocFiles: supportingDocFiless,
    };
    dispatch(getWarrantySupportDocUpdate(warntId, dataa)).then((res) => {
      if (res.status === 201) {
        dispatch(
          getAllWarrantyList(state?.id, state?.jobType, state?.ProjectId)
        );
        dispatch(getAllWorkOrderList(state?.id, state?.jobType));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AddNewSupportingDocsModal: false,
          warntId: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          AddNewSupportingDocsModal: true,
        });
      }
    });
  };

  const handleSupportingDocumentsListModalShow = (id) => {
    updateState({
      ...iState,
      SupportingDocumentsListModal: true,
    });
    dispatch(getSupportingDocsList(id));
  };
  const handleSupportingDocumentsListModalClose = () => {
    updateState({
      ...iState,
      SupportingDocumentsListModal: false,
    });
  };

  const handleWarrantyDeleteModalShow = (id) => {
    updateState({
      ...iState,
      WarrantyDeleteModal: true,
      warntId: id,
    });
  };
  const handleWarrantyDeleteModalClose = () => {
    updateState({
      ...iState,
      WarrantyDeleteModal: false,
    });
  };

  const handleWarrantyDelete = () => {
    let dataa = { warntId };
    dispatch(getDeleteWarranty(dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(
          getAllWarrantyList(state?.id, state?.jobType, state?.ProjectId)
        );
        toast.success(" Delete Warranty Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          WarrantyDeleteModal: false,
          warntId: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          WarrantyDeleteModal: true,
        });
      }
    });
  };

  const handleWorkOrderDeleteModalShowHide = (data, type) => {
    if (type === "hide") {
      updateState({ ...iState, WorkOrderDeleteModal: false });
    } else {
      updateState({
        ...iState,
        WorkOrderDeleteModal: true,
        workId: data?.id,
      });
    }
  };

  const handleWorKOrderDelete = () => {
    dispatch(getWorkOrderDelete(workId)).then((res) => {
      if (res?.status === 200) {
        dispatch(getAllWorkOrderList(state?.id, state?.jobType));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          WorkOrderDeleteModal: false,
          warntId: "",
          workId: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          WorkOrderDeleteModal: true,
        });
      }
    });
  };

  const handleTabChange = () => {
    updateState({ ...iState, tabChange: !tabChange });
  };

  const [JobMenuModal, setJobMenuModal] = useState(false);
  const handleJobMenuModalClose = () => setJobMenuModal(false);
  const handleJobMenuModalShow = () => setJobMenuModal(true);

  const handlePrints = () => {
    setShowPrint(true);
    const printContent = document.querySelector(".AgreementViewArea").innerHTML;
    document.body.innerHTML = printContent;
    window.print();
    window.location.reload();
  };
  const handleDownload = () => {
    const printContent = document.querySelector(".AgreementViewArea").innerHTML;
    const blob = new Blob([printContent], { type: "text/html" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "agreement_view.html";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleNavigateModalShow = () => {
    updateState({ ...iState, NavigateModal: true });
  };
  const handleNavigateModalClose = () => {
    updateState({ ...iState, NavigateModal: false });
  };
  const [showPrint, setShowPrint] = useState(false);

  const handleServicTypeModalShow = (item) => {
    updateState({ ...iState, 
      ServicTypeModal: true ,
      requestedData:item,
    serviceType:item?.[0]?.serviceType
    });
  };
  const handleServicTypeModalClose = () => {
    updateState({ ...iState, ServicTypeModal: false });
  };

  useEffect(()=>{
  if(ServicTypeModal){
    dispatch(getAllServiceTypeList());
  }
  },[ServicTypeModal])

  const handleUpdateServiceType = () => {
    const dataa={name:serviceName}
    dispatch(updateServiceTypes(serviceType,dataa)).then((res) => {
      if (res?.status === 200) {
        dispatch(getAllWarrantyList(state?.id, state?.jobType,state?.ProjectId));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          ServicTypeModal: false,

        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          ServicTypeModal: true,
        });
      }
    });
  };

  const handleAssignInspectorModalShow = (item) => {
    updateState({ ...iState, 
      AssignInspectorModal: true ,
      requestedData:item,
      inspector:item?.[0]?.inspector
    });
  };
  const handleAssignInspectorModalClose = () => {
    updateState({ ...iState, AssignInspectorModal: false });
  };

  useEffect(()=>{
    if(AssignInspectorModal){
      dispatch(getInspectorList());
    }
    },[AssignInspectorModal])
 

    
  const [selectedEditor, setSelectedEditor] = useState("BaseTemplate");
  const handleSelectedEditorChange = (e) => {
    const newSelectedEditor = e.target.value;
    if (newSelectedEditor === "CreateNewFile") {
      updateState({ ...iState, clientFile: "" });
    } else if (newSelectedEditor === "BaseTemplate") {
      updateState({ ...iState, htmlUrl: "" });
    }
    setSelectedEditor(newSelectedEditor);
  };

  const editorSelectedRef = useRef(null);
  const handleSelectedEditor = (data) => {
    updateState((prevState) => ({
      ...prevState,
      htmlUrl: data,
      fileFolderName,
    }));
  };

  const handlHeaderPreviewModalShow = () => {
    updateState({
      ...iState,
      HeaderPreviewModal: true,
      EditDocumentationModal: false,
    });
  };
  const handlHeaderPreviewModalClose = () => {
    updateState({
      ...iState,
      HeaderPreviewModal: false,
      EditDocumentationModal: true,
    });
  };

  const handleApply = () => {
    const dataa = iBuildLocalData?.companies?.[0]?.letterHeader;
    const updatedContent = dataa ? dataa + content : content;
    updateState({
      ...iState,
      headerPreviewData: dataa,
      content: updatedContent,
      HeaderPreviewModal: false,
      EditDocumentationModal: true,
    });
  };
  
  const createAboutHeader = () => {
    return { __html: iBuildLocalData?.companies?.[0]?.letterHeader };
  };
  return (
    <>
      <Header />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="MainTitleBox">
            <div className="MainTitle">
              <h2>Warranty Dashboard</h2>
            </div>
            <div className="TodosButtons TodosButtons270">
              <a onClick={() => handleFileManagerShow()}>
                <h6>
                  <img src="images/file-manager.png" />
                  File Manager
                </h6>{" "}
                {/* <span>3</span> */}
              </a>
            </div>
          </div>
          <div className="TitleBox d-flex flex-lg-row flex-column align-items-lg-center align-items-start">
            <div className="TodosButtons">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#WarrantyRequest"
                    id="WarrantyRequestTab"
                    onClick={() => handleTabChange()}
                  >
                    <img src="images/warranty-request.png" />
                    Warranty Request
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#WorkOrders"
                    id="WorkOrdersTab"
                    onClick={() => handleTabChange()}
                  >
                    <img src="images/work-order-red.png" />
                    Work Orders
                  </a>
                </li>
              </ul>
            </div>
            <div className="TitleBoxAddress">
              <h6>{state?.clientName}</h6>
              <p>
                {state?.clientEmail} {state?.clientPhone}
              </p>
              <p>{state?.jobAddress}</p>
            </div>
            <div className="TitleBoxNavigate">
              <h5>{state?.jobNumber}</h5>
              <p>{state?.jobType}</p>
              <div className="NavigateButton gap-2">
                <button onClick={handleJobMenuModalShow}>Job Menu</button>
                <button className="ml-2" onClick={handleNavigateModalShow}>
                  Navigate
                </button>
              </div>
            </div>
          </div>
          <div className="tab-content">
            <div className="tab-pane active fade show" id="WarrantyRequest">
              <div
                class="TodosButtons"
                style={{
                  position: "relative",
                  "text-align": "right",
                  top: "-229px",
                }}
              >
                <Link
                  to="/warranty-request"
                  state={state}
                  id="NewWarrantyRequestBtn"
                >
                  <h6>
                    <img src="images/new-warranty-request.png" />
                    New Warranty Request
                  </h6>{" "}
                </Link>
              </div>
              <div className="Tableheader">
                <h6>Warranty Request</h6>
                <div className="NewActions">
                  <a href="javascript:void(0);">
                    <img src="images/icon-64.png" />
                  </a>
                  <a onClick={handlePrints} title="print">
                    <img src="images/icon-53.png" />
                  </a>
                  <a onClick={handleDownload} title="download">
                    <img src="images/download.png" />
                  </a>
                  {/* <a href="javascript:void(0);">
                    <img src="images/setting.png" />
                  </a> */}
                </div>
              </div>
              <div className="AgreementViewArea" id="agreement-view-area">
                {showPrint && (
                  <>
                    <div className="AgreementViewTop">
                      <a href="javscript:void(0)">
                        <img src="images/modal-big-logo.png" />
                      </a>
                      <div className="AgreementViewTopRight">
                        <h3>iBuild</h3>
                        <p>312 Weddenburn Rd SE</p>
                        <p>Calgary, AB CA, T2J 1J1</p>
                        <p>
                          4034005933 www.ibuildapplications.com
                          linden@ibuildapplications.com
                        </p>
                        <p>
                          “The bitterness of poor quality remains long after the
                          sweetness of meeting
                          <br />
                          the schedule has been gorgotten”
                        </p>
                      </div>
                    </div>
                    <aside>
                      <p>
                        <label>Table :</label>
                        <span>Warranty Request</span>
                      </p>
                      <p>
                        <label>Project :</label>
                        <span>{state?.ProjectId}</span>
                      </p>
                      <p>
                        <label>Job :</label>
                        <span>{state?.id} </span>
                      </p>
                    </aside>
                  </>
                )}

                <div className="TableList TableListHeader NewTableList TableHeightScroll TableHeight500">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Urgency</th>
                        <th>Description</th>
                        <th>Date Created</th>
                        <th>Type of Warranty</th>
                        <th>Assigned Inspector</th>
                        <th>Scheduled Inspection Date</th>
                        <th>Assigned/# of Warranty Items</th>
                        <th>Status</th>
                        <th>Supporting Doc</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!warrantyLoader ? (
                        allWarrantyList?.warranties?.length > 0 ? (
                          allWarrantyList?.warranties?.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <a>{item?.id}</a>
                                </td>
                                <td>
                                  <a>{item?.urgency?.name ?? "N.A"}</a>
                                </td>
                                <td>
                                  <a>{item?.description ?? "N/A"}</a>
                                </td>
                                <td>
                                  <a>
                                    {moment(item?.createdAt).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </a>
                                </td>
                                <td>
                                  {item?.requestData?.length > 0 ? (
                                    <a>
                                      {item?.requestData?.[0]?.serviceName ??
                                        "N/A"}
                                      <img
                                        src={require("../assets/images/courtesy.png")}
                                        onClick={() =>
                                          handleServicTypeModalShow(
                                            item.requestData
                                          )
                                        }
                                        alt="Service Icon"
                                      />
                                    </a>
                                  ) : (
                                    "N/A"
                                  )}
                                </td>
                                <td>
                                {item?.requestData?.length > 0 ? (
                                    <a>
                                      {item?.requestData?.[0]?.inspectorName ??
                                        "N/A"}
                                      <img
                                        src={require("../assets/images/assign_icon.png")}
                                        onClick={() =>
                                          handleAssignInspectorModalShow(
                                            item.requestData
                                          )
                                        }
                                        alt="Service Icon"
                                      />
                                    </a>
                                  ) : (
                                    "N/A"
                                  )}
                                </td>
                                <td>
                                  {item?.scheduledInspectionDate ?? "N/A"}
                                </td>
                                <td>
                                  <a>
                                    {item?.assignedOfWarrantyItems ?? "N/A"}
                                  </a>
                                </td>
                                <td>{item?.progressStatus ?? "N/A"}</td>
                                <td>
                                  <div className="Actions">
                                    <a
                                      onClick={() =>
                                        handleAddNewSupportingDocsModalShow(
                                          item.id
                                        )
                                      }
                                    >
                                      <img src="images/icon-63.png" />
                                    </a>
                                    <a
                                      onClick={() =>
                                        handleSupportingDocumentsListModalShow(
                                          item.id
                                        )
                                      }
                                    >
                                      <span className="Count">3</span>
                                    </a>
                                  </div>
                                </td>
                                <td>
                                  <div className="DetailsDots">
                                    <Link
                                      to={`/warranty-request-details`}
                                      state={{ item }}
                                    >
                                      <img src="images/icon-65.png" />
                                    </Link>
                                    <div className="TableThreeDots">
                                      <ul className="">
                                        <li className="dropdown">
                                          <a
                                            className=""
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol className="dropdown-menu dropdown-menuwidth">
                                            {/* <li>
                                              <a>
                                                <img src="images/work-order-black.png" />
                                                Create Work Order
                                              </a>
                                            </li> */}
                                            <li>
                                            <Link to="/warranty-request-work-order-table" 
                                             state={{ ...item, jobNumber: state?.jobNumber }}
                                            >
                                                <img src="images/Action-1.png" />
                                                Edit
                                              </Link>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleWarrantyDeleteModalShow(
                                                    item.id
                                                  )
                                                }
                                              >
                                                <img src="images/dropdown-delete.png" />
                                                Delete
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="10" className="text-center">
                              <p>No Data found.</p>
                            </td>
                          </tr>
                        )
                      ) : (
                        <Loader />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="tab-pane" id="WorkOrders">
              <div
                class="TodosButtons"
                style={{
                  position: "relative",
                  "text-align": "right",
                  top: "-229px",
                }}
              >
                <Link
                  to="/warranty-work-order"
                  state={state}
                  id="NewWorkOrderBtn"
                >
                  <h6>
                    <img src="images/new-work-order.png" />
                    New Work order
                  </h6>{" "}
                </Link>
              </div>
              <div className="Tableheader">
                <h6>Work Order</h6>
                <div className="NewActions">
                  <a href="">
                    <img src="images/icon-64.png" />
                  </a>
                  <a href="">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="">
                    <img src="images/download.png" />
                  </a>
                  {/* <a href="">
                    <img src="images/setting.png" />
                  </a> */}
                </div>
              </div>
              <div className="TableList NewTableList TableListHeader TableHeightScroll TableHeight500">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Description</th>
                      <th>Home Owner Accepted</th>
                      <th>Date Created</th>
                      <th>Vendor/ Technician name</th>
                      <th>Trades Day</th>
                      <th>Completed/# Work Order Items</th>
                      <th>Status</th>
                      <th>Supporting Doc</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {workLoader == false ? (
                      allWorkOrderList?.workOrder?.length > 0 ? (
                        allWorkOrderList?.workOrder?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <a>{data.id}</a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  {data.description ?? "N/A"}
                                </a>
                              </td>
                              <td>
                                <a>
                                  {data.homeOwnerAccept === true ? "Yes" : "No"}
                                </a>
                              </td>
                              <td>
                                <a>
                                  {moment(data.createdAt).format("YYYY-MM-DD")}
                                </a>
                              </td>
                              <td>
                                <a className="VendorInformationModalHover">
                                  {data.clientLegalName ?? "N/A"}
                                </a>
                              </td>
                              <td>
                                <a>
                                  {moment(data.tradesDay).format("YYYY-MM-DD")}
                                </a>
                              </td>
                              <td>
                                <a>{data.workOrderStatus ?? "N/A"}</a>
                              </td>
                              <td>
                                <a>{data.progressStatus ?? "N/A"}</a>
                              </td>
                              <td>
                                <div className="Actions">
                                  <a
                                    onClick={() =>
                                      handleAddNewSupportingDocsModalShow(
                                        data.id
                                      )
                                    }
                                  >
                                    <img src="images/icon-63.png" />
                                  </a>
                                  <a>
                                    <span className="Count">3</span>
                                  </a>
                                </div>
                              </td>
                              <td>
                                <div className="DetailsDots">
                                  <Link
                                    to={`/warranty-work-order-details`}
                                    state={{ data }}
                                  >
                                    <img src="images/icon-65.png" />
                                  </Link>
                                  <div className="TableThreeDots">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol className="dropdown-menu">
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/Action-1.png" />
                                              Edit
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                handleWorkOrderDeleteModalShowHide(
                                                  data,
                                                  "show"
                                                )
                                              }
                                            >
                                              <img src="images/dropdown-delete.png" />
                                              Delete
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="10" className="text-center">
                            <p>No Data found.</p>
                          </td>
                        </tr>
                      )
                    ) : (
                      <Loader />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={FileManagerModal}
          onHide={handlehandleFileManagerClose}
          className="ModalBox modal fade  ExtraLargeModal show"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal ClosePermissionId"
                  onClick={handlehandleFileManagerClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Warranty File Manager</h4>
                  </div>
                  <div className="FolderForm CommonSelectBoxMain">
                    <form>
                      <div className="row">
                        <div className="col-lg-4 col-md-12">
                          <div className="DashboardLeft">
                            <div className="ShowList">
                              <div className="ContactInformationArea">
                                <h6>Contact Information</h6>
                                <div className="ContactInformationBox border-0">
                                  <span className="circle">JL</span>
                                  <div className="ContactInformationName">
                                    <h6>John Lead</h6>
                                    <p>
                                      312 Weddenburn Rd SE <br /> Calgary,
                                      Alberta{" "}
                                      <img
                                        src="images/location.png"
                                        className="CursorPointer FolderPermissionId ml-2"
                                        data-toggle="modal"
                                        data-target="#MapModal2"
                                        data-dismiss="modal"
                                        p=""
                                      />
                                    </p>
                                    <p>
                                      Phone: <span>(403)555-555</span>
                                    </p>
                                    <p>
                                      Email: <span>John.lead@email.com</span>
                                    </p>
                                  </div>
                                </div>
                                <div className="AssignUnit">
                                  <h4>Assigned Unit</h4>
                                  <p>U222-CHI-2B-243 (Unit #222)</p>
                                </div>
                              </div>
                              <aside>
                                <h5>Folder</h5>
                                <div>
                                  <a
                                    className="FolderPermissionId"
                                    onClick={() => handleCreateFolderShow()}
                                    title="Add Folder"
                                  >
                                    <img src="images/Plus.png" />
                                  </a>
                                </div>
                              </aside>
                              <div className="Search">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                  value={searchName}
                                  onChange={(e) =>
                                    setSearchName(e.target.value)
                                  }
                                />
                                <span>
                                  <img src="images/search.png" />
                                </span>
                              </div>
                            </div>
                            <div className="FolderList border-0">
                              <ul>
                                {searchName
                                  ? warrantyFolderSearchList?.FolderDetails
                                      ?.length > 0
                                    ? warrantyFolderSearchList?.FolderDetails?.map(
                                        (data, ind) => {
                                          return (
                                            <li key={data.id}
                                            style={{
                                              backgroundColor:
                                                folderIDD === data.id
                                                  ? "green"
                                                  : "transparent",
                                            }}
                                            onClick={(e) =>
                                              handleDocument(e,data.id)
                                            }
                                            >
                                              <span
                                                className="Icon"
                                               
                                              >
                                                <img src="images/Folder.png" />
                                              </span>
                                              <label>{data.folderName}</label>
                                              <a
                                                onClick={() =>
                                                  handleFolderDeleteShow(
                                                    data.id
                                                  )
                                                }
                                              >
                                                <img src="images/Cross.png" />
                                              </a>
                                              {/* <a
                                                title="Folder History"
                                                className="FolderPermissionId"
                                                // onClick={()=>handleDocumentHistoryModalShow(item.id)}
                                              >
                                                <img src="images/timer.png" />
                                              </a> */}
                                            </li>
                                          );
                                        }
                                      )
                                    : (
                                      <tr>
                                        <td colSpan="10">
                                          <p>No Data found.</p>
                                        </td>
                                      </tr>
                                    )
                                  : warrantyFolderList?.FolderDetails?.length >
                                    0
                                  ? warrantyFolderList?.FolderDetails?.map(
                                      (item, i) => {
                                        return (
                                          <li key={i}  style={{
                                            backgroundColor:
                                              folderIDD === item.id
                                                ? "green"
                                                : "transparent",
                                          }}
                                          onClick={(e) =>
                                            handleDocument(e,item.id)
                                          }>
                                            <span
                                              className="Icon"
                                             
                                            >
                                              <img src="images/Folder.png" />
                                            </span>
                                            <label>{item.folderName}</label>
                                            <a
                                              onClick={() =>
                                                handleFolderDeleteShow(item.id)
                                              }
                                            >
                                              <img src="images/Cross.png" />
                                            </a>
                                            {/* <a
                                              title="Folder History"
                                              onClick={() =>
                                                handleFolderHistoryModalShow(
                                                  item.id
                                                )
                                              }
                                            >
                                              <img src="images/timer.png" />
                                            </a> */}
                                          </li>
                                        );
                                      }
                                    )
                                  : (
                                    <tr>
                                      <td colSpan="10">
                                        <p>No Data found.</p>
                                      </td>
                                    </tr>
                                  )}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                        
                          <div className="EventArea">
                            <h4>Pending File Events</h4>
                            <div className="TableList NewTableList">
                              {pendingLoader ? (
                                <td>
                                  <Loader />
                                </td>
                              ) : (
                                <table>
                                  <thead>
                                    <tr>
                                      <th>File Id</th>
                                      <th>Template</th>
                                      <th>Modified by</th>
                                      <th>Status</th>
                                      <th>Date</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {warrantyPendingFileEventsList?.length > 0
                                      ? warrantyPendingFileEventsList?.map(
                                          (data, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>{data.id}</td>
                                                <td>{data.fileName}</td>
                                                <td>
                                                  {data.modifiedBy ?? "N/A"}
                                                </td>
                                                <td>
                                                  {data.actionStatus ?? "N/A"}
                                                </td>
                                                <td>
                                                  {moment(
                                                    data.createdAt
                                                  ).format("YYYY-MM-DD")}
                                                </td>
                                                <td>
                                                  <div className="Actions">
                                                    <a
                                                      className="FolderPermissionId"
                                                      title="View Document"
                                                      onClick={() =>
                                                        handleViewDocumentationShow(
                                                          data
                                                        )
                                                      }
                                                    >
                                                      <img src="images/view-po.png" />
                                                    </a>
                                                    <a
                                                      onClick={() =>
                                                        handleEditDocumentationShow(
                                                          data
                                                        )
                                                      }
                                                      className="FolderPermissionId"
                                                      title="Edit"
                                                    >
                                                      <img src="images/Action-1.png" />
                                                    </a>
                                                    <a
                                                      title="Share Document"
                                                      onClick={() =>
                                                        handleShareClientDocumentShow(
                                                          data.folderId,
                                                          data.id
                                                        )
                                                      }
                                                    >
                                                      <img src="images/Action-2.png" />
                                                    </a>
                                                    <a
                                                      href="javascript:void(0);"
                                                      title="canceling"
                                                      data-toggle="modal"
                                                      data-target="#DeleteMessage"
                                                      data-dismiss="modal"
                                                    >
                                                      <img src="images/icon-30.png" />
                                                    </a>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      : (
                                        <tr>
                                          <td colSpan="10">
                                            <p>No Data found.</p>
                                          </td>
                                        </tr>
                                      )}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          </div>
                          <div className="TodosButtons d-flex justify-content-between align-items-center mb-4">
                            <a
                              onClick={() =>
                                handleSendClientInformationModalShow()
                              }
                              className="Create FolderPermissionId"
                            >
                              <img
                                src={require("../assets/images/send_clinet.png")}
                              />
                              Send Client Information
                            </a>
                            {/* <a href="javascript:void(0)" class="FolderPermissionId" data-toggle="modal" data-target="#CreateClientCtc2" data-dismiss="modal" >
                    <img src="images/change_contract.png" />Change to Contract (CTC) Agreement <span>!</span>
                  </a> */}
                            <a
                              onClick={handleManageSignatueShow}
                              className="ml-0 FolderPermissionId"
                            >
                              <img src="images/white_manage_signatures.png" />{" "}
                              Manage Signature
                            </a>
                            <div className="FilesArea d-inline-block">
                              <div className="FilesHead mb-0">
                                <div className="FilesLeft FilesLeftSalesdoocument">
                                  <aside>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search"
                                      value={searchFolderName}
                                      onChange={(e) =>
                                        setSearchFolderName(e.target.value)
                                      }
                                    />
                                    <span>
                                      <img src="images/search.png" />
                                    </span>
                                  </aside>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="FilesArea">
                            <div className="FilesHead">
                              <div className="FilesLeft">
                                <h4>Files</h4>
                              </div>
                              <div className="FilesRight">
                            
                                <a
                                  onClick={handleNewFileShow}
                                  title="Create New File"
                                  className="FolderPermissionId"
                                >
                                  <img src="images/document-support.png" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {fileLoader ? (
                              <td>
                                <Loader />
                              </td>
                            ) : (
                              <>
                                {searchFolderName
                                  ? newfileSearchDocsList?.length > 0
                                    ? newfileSearchDocsList?.map(
                                        (item, ind) => {
                                          return (
                                            <div className="col-md-4">
                                              <div className="FilesBox2 Safety">
                                                <figcaption>
                                                  <div>
                                                    <h4>{item.name}</h4>
                                                  </div>
                                                  <ul>
                                                    <li className="dropdown">
                                                      <a
                                                        className="dropdown-toggle"
                                                        role="button"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                      >
                                                        <img src="images/dots.png" />
                                                      </a>
                                                      <ol className="dropdown-menu DropdownMenuSafetyModal">
                                                        <li>
                                                          <a
                                                            href="javascript:void(0);"
                                                            className="FolderPermissionId"
                                                            onClick={() =>
                                                              handleEditDocumentationShow(
                                                                item
                                                              )
                                                            }
                                                          >
                                                            <img src="images/Action-1.png" />
                                                            Edit Document
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            className="FolderPermissionId"
                                                            onClick={() =>
                                                              handleViewDocumentationShow(
                                                                item
                                                              )
                                                            }
                                                          >
                                                            <img src="images/view_jobs_details.png" />
                                                            View Document
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            onClick={() =>
                                                              handleSendDocumentModalShow(
                                                                item
                                                              )
                                                            }
                                                            className="FolderPermissionId"
                                                          >
                                                            <img src="images/sign_document.png" />
                                                            Generate
                                                            Document/Send for
                                                            Client Signature
                                                          </a>
                                                        </li>
                                                        {/* <li><a href="javascript:void(0);" class="FolderPermissionId" data-toggle="modal" data-target="#EditFile2" data-dismiss="modal" ><img src="images/sign_document2.png">Sign documents</a></li>
                                                         */}
                                                        <li>
                                                          <a
                                                            href={item.docUrl}
                                                            target="_blank"
                                                            title="ImageName"
                                                          >
                                                            <img src="images/download.png" />
                                                            Download Document
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="javascript:void(0);"
                                                            className="FolderPermissionId"
                                                            data-toggle="modal"
                                                            data-target="#SendDocumentforClientSignature"
                                                            data-dismiss="modal"
                                                          >
                                                            <img src="images/upload_new.png" />
                                                            Upload Signed
                                                            documents
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            onClick={() =>
                                                              handleShareClientDocumentShow(
                                                                item.folderId,
                                                                item.id
                                                              )
                                                            }
                                                            className="FolderPermissionId"
                                                          >
                                                            <img src="images/Action-2.png" />
                                                            Share documents
                                                          </a>
                                                        </li>
                                                      </ol>
                                                    </li>
                                                  </ul>
                                                </figcaption>
                                                <div className="FilesBoxCenterSafety FilesBoxCenterSafetyModal">
                                                  <div
                                                    className="FilesBoxCenterSafetyLeft FolderPermissionId CursorPointer"
                                                    data-toggle="modal"
                                                    data-target="#TemplateActivity"
                                                    data-dismiss="modal"
                                                  >
                                                    <img
                                                      src="images/file.png"
                                                      title="File"
                                                    />
                                                    <h6
                                                      className="underline-grey"
                                                      onClick={() =>
                                                        handleTemplateActivityModalShow(
                                                          item.id
                                                        )
                                                      }
                                                    >
                                                      Template
                                                    </h6>
                                                    <span className="Count">
                                                      3
                                                    </span>
                                                  </div>
                                                  {/* <div class="FilesBoxCenterSafetyRight">
                                <div class="form-group">
                                  <h6>Client View</h6>
                                  <label class="switch">
                                    <input type="checkbox">
                                    <span class="slider"></span> 
                                </label>
                                </div>
                              </div> */}
                                                </div>
                                                <figcaption>
                                                  <div>
                                                    <p className="CursorPointer">
                                                      Last update{" "}
                                                      {moment(
                                                        item.updatedAt
                                                      ).format("YYYY-MM-DD")}
                                                    </p>
                                                  </div>
                                                  <ul>
                                                    <li>
                                                      <a
                                                        title="Document History"
                                                        onClick={() =>
                                                          handleDocumentHistoryModalShow(
                                                            item.folderId,
                                                            item.id,
                                                            item.name,
                                                          )
                                                        }
                                                      >
                                                        <img src="images/timer.png" />
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </figcaption>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )
                                    : "No Documents"
                                  : newfileDocsList?.length > 0
                                  ? newfileDocsList?.map((item, ind) => {
                                      return (
                                        <div className="col-md-4">
                                          <div className="FilesBox2 Safety">
                                            <figcaption>
                                              <div>
                                                <h4>{item.name}</h4>
                                              </div>
                                              <ul>
                                                <li className="dropdown">
                                                  <a
                                                    className="dropdown-toggle"
                                                    role="button"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                  >
                                                    <img src="images/dots.png" />
                                                  </a>
                                                  <ol className="dropdown-menu DropdownMenuSafetyModal">
                                                    <li>
                                                      <a
                                                        href="javascript:void(0);"
                                                        className="FolderPermissionId"
                                                        onClick={() =>
                                                          handleEditDocumentationShow(
                                                            item
                                                          )
                                                        }
                                                      >
                                                        <img src="images/Action-1.png" />
                                                        Edit Document
                                                      </a>
                                                    </li>
                                                    <li>
                                                      <a
                                                        className="FolderPermissionId"
                                                        onClick={() =>
                                                          handleViewDocumentationShow(
                                                            item
                                                          )
                                                        }
                                                      >
                                                        <img src="images/view_jobs_details.png" />
                                                        View Document
                                                      </a>
                                                    </li>
                                                    <li>
                                                      <a
                                                        className="FolderPermissionId"
                                                        onClick={() =>
                                                          handleSendDocumentModalShow(
                                                            item
                                                          )
                                                        }
                                                      >
                                                        <img src="images/sign_document.png" />
                                                        Generate Document/Send
                                                        for Client Signature
                                                      </a>
                                                    </li>
                                                    {/* <li><a href="javascript:void(0);" class="FolderPermissionId" data-toggle="modal" data-target="#EditFile2" data-dismiss="modal" ><img src="images/sign_document2.png">Sign documents</a></li>
                                                     */}
                                                    <li>
                                                      <a
                                                        href={item.docUrl}
                                                        target="_blank"
                                                        title="ImageName"
                                                      >
                                                        <img src="images/download.png" />
                                                        Download Document
                                                      </a>
                                                    </li>
                                                    <li>
                                                      <a
                                                        href="javascript:void(0);"
                                                        className="FolderPermissionId"
                                                        data-toggle="modal"
                                                        data-target="#SendDocumentforClientSignature"
                                                        data-dismiss="modal"
                                                      >
                                                        <img src="images/upload_new.png" />
                                                        Upload Signed documents
                                                      </a>
                                                    </li>
                                                    <li>
                                                      <a
                                                        className="FolderPermissionId"
                                                        onClick={() =>
                                                          handleShareClientDocumentShow(
                                                            item.folderId,
                                                            item.id
                                                          )
                                                        }
                                                      >
                                                        <img src="images/Action-2.png" />
                                                        Share documents
                                                      </a>
                                                    </li>
                                                  </ol>
                                                </li>
                                              </ul>
                                            </figcaption>
                                            <div className="FilesBoxCenterSafety FilesBoxCenterSafetyModal">
                                              <div
                                                className="FilesBoxCenterSafetyLeft FolderPermissionId CursorPointer"
                                                data-toggle="modal"
                                                data-target="#TemplateActivity"
                                                data-dismiss="modal"
                                              >
                                                <img
                                                  src="images/file.png"
                                                  title="File"
                                                />
                                                <h6
                                                  className="underline-grey"
                                                  onClick={() =>
                                                    handleTemplateActivityModalShow(
                                                      item.id
                                                    )
                                                  }
                                                >
                                                  Template
                                                </h6>
                                                <span className="Count">3</span>
                                              </div>
                                              {/* <div class="FilesBoxCenterSafetyRight">
                                <div class="form-group">
                                  <h6>Client View</h6>
                                  <label class="switch">
                                    <input type="checkbox">
                                    <span class="slider"></span> 
                                </label>
                                </div>
                              </div> */}
                                            </div>
                                            <figcaption>
                                              <div>
                                                <p className="CursorPointer">
                                                  Last update{" "}
                                                  {moment(
                                                    item.updatedAt
                                                  ).format("YYYY-MM-DD")}
                                                </p>
                                              </div>
                                              <ul>
                                                <li>
                                                  <a
                                                    title="Document History"
                                                    onClick={() =>
                                                      handleDocumentHistoryModalShow(
                                                        item.folderId,
                                                        item.id,
                                                        item.name,
                                                      )
                                                    }
                                                  >
                                                    <img src="images/timer.png" />
                                                  </a>
                                                </li>
                                              </ul>
                                            </figcaption>
                                          </div>
                                        </div>
                                      );
                                    })
                                  : (
                                    <tr>
                                      <td colSpan="10">
                                        <p>No Data found.</p>
                                      </td>
                                    </tr>
                                  )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={CreateFolderModal}
          onHide={handleCreateFolderClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleCreateFolderClose}>
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Create Folder</h4>
                  </div>
                  <div className="FolderForm">
                    <form onSubmit={handleCreateFolderSubmit}>
                      <div className="form-group">
                        <h6>Folder Name *</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert new folder name"
                          name="folderName"
                          value={folderName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <h6>Folder Permissions</h6>
                        <div className="PermissionsName">
                          <h5
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#permissionModal2"
                            data-dismiss="modal"
                          >
                            <span>
                              <img src="images/Folder.png" />
                            </span>{" "}
                            Folder Permissions
                          </h5>
                        </div>
                      </div>
                      <div className="form-group">
                        <h6>Folder Actions</h6>
                        <div className="PermissionsName">
                          <h5
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#folderActionModal2"
                            data-dismiss="modal"
                          >
                            <span>
                              <img src="images/Icon-4.png" />
                            </span>{" "}
                            Folder Actions
                          </h5>
                        </div>
                      </div>
                      <div className="Button text-center">
                        <button className="Create ClosePermissionId">
                          Create
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={FolderDeleteModal}
          onHide={handleFolderDeleteClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleFolderDeleteClose}>
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Delete</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="my-5">
                      <h6>Are you sure you want to delete this ?</h6>
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button className="Create" onClick={handleFolderDelete}>
                        Delete
                      </button>
                      <button
                        className="Outline"
                        onClick={handleFolderDeleteClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={FolderHistoryModal}
          onHide={handleFolderHistoryModalClose}
          className="ModalBox modal fade MediumModal show"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleFolderHistoryModalClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Folder History</h4>
                  </div>
                  <div className="JobTitleArea mb-2">
                    <div className="JobBox">
                      <h6>
                        <span>Folder:</span>&nbsp;Custom Layouts
                      </h6>
                    </div>
                  </div>
                  <div className="TableList TableListAlign CommonHeading TableHeightScroll">
                    <table>
                      <thead>
                        <tr>
                          <th>Modified By</th>
                          <th>Created By</th>
                          <th>Date</th>
                          <th>Activity</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>John Doe</td>
                          <td>Jane Williams</td>
                          <td>04/25/2023</td>
                          <td>All documents download by the Trade</td>
                        </tr>
                     
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
       
        <Modal
          show={NewFileModal}
          onHide={handleNewFileClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleNewFileClose}>
                  ×
                </a>

                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Create New File</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="form-group">
                      <h6>Name</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert new entry name"
                        name="fileFolderName"
                        value={fileFolderName}
                        onChange={handleInputChange}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors?.nameError}
                      </span>
                    </div>
                    <div className="form-group">
                      <h6>Folder</h6>
                      <div className="Categories Categories1">
                        <div className="Categories_all Categories_all1">
                          <label>
                            List of folders where user has permission to create
                            file
                          </label>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <div className="">
                            <select
                              className="form-control"
                              name="id"
                              value={id}
                              // onFocus={handleValidation}
                              onChange={handleInputChange}
                            >
                              <option value="">Select</option>
                              {warrantyFolderList?.FolderDetails?.length > 0 &&
                                warrantyFolderList?.FolderDetails?.map((item) => {
                                  return (
                                    <option value={item.id}>
                                      {item.folderName}{" "}
                                    </option>
                                  );
                                })}
                            </select>
                            <span style={{ color: "red" }}>
                              {errors?.foldNameError}
                            </span>
                          </div>
                        </ul>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <h6>Editor Option</h6>
                      <div className="mb-4">
                        <label className="Radio">
                          Create file with base template upload
                          <input
                            type="radio"
                            name="editor"
                            value="BaseTemplate"
                            checked={selectedEditor === "BaseTemplate"}
                            onChange={handleSelectedEditorChange}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div>
                        <label className="Radio">
                          Create new file with editor
                          <input
                            type="radio"
                            name="editor"
                            value="CreateNewFile"
                            checked={selectedEditor === "CreateNewFile"}
                            onChange={handleSelectedEditorChange}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                    <div className="file RadioText"></div>
                    <div
                      className="text RadioText"
                      style={{ display: "block" }}
                    >
                      {selectedEditor === "BaseTemplate" && (
                        <>
                          <div className="form-group">
                            <h6>Select Template File (Docx)</h6>
                            <div className="Upload">
                              <span>
                                -Drag and Drop Files or click to select
                              </span>
                              <input
                                type="file"
                                id="createFileInput"
                                accept=".doc,.docx"
                                onChange={(e) =>
                                  handleFileChange(e, "createFile")
                                }
                              />
                            </div>
                            <span style={{ color: "red", fontSize: "14px" }}>
                              {errors?.cltfileError}
                            </span>
                          </div>

                          <div className="FilesAdded">
                            <label>{clientFile?.name}</label>
                            <span>
                              <img src="images/fileview.png" />
                            </span>
                            <span onClick={handleRemoveClientFile}>
                              <img src="images/Cross.png" className="cross" />
                            </span>
                          </div>
                        </>
                      )}

                      {selectedEditor === "CreateNewFile" && (
                        <div className="mce-pagebreak">
                          <Editor
                            apiKey="rye4mz748ekd8ke0loyu8j7gq7iu1tkxs3nticj32j848g6r" // Use your premium API key
                            onInit={(evt, editor) =>
                              (editorSelectedRef.current = editor)
                            }
                            value={htmlUrl}
                            init={{
                              height: 500,
                              menubar:
                                "file edit view insert format tools table help",
                              plugins: [
                                "advlist",
                                "autolink",
                                "lists",
                                "link",
                                "image",
                                "charmap",
                                "print",
                                "preview",
                                "anchor",
                                "searchreplace",
                                "visualblocks",
                                "code",
                                "fullscreen",
                                "insertdatetime",
                                "media",
                                "table",
                                "paste",
                                "help",
                                "wordcount",
                                "lists",
                                "table",
                                "pagebreak",
                                "spellchecker",
                                "powerpaste",
                                "mediaembed",
                                "advcode",
                                "emoticons",
                                "codesample",
                                "advtable",
                                "visualchars",
                                "visualblocks",
                                "linkchecker",
                                "checklist",
                                "media",
                                "tableofcontents",
                                "a11ychecker",
                                "permanentpen",
                                "casechange",
                                "pageembed",
                                "nonbreaking",
                                "quickbars",
                                "advlist",
                                "autosave",
                                "autolink",
                                "autoresize",
                              ],
                              toolbar:
                                "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | " +
                                "alignleft aligncenter alignright alignjustify | numlist bullist checklist outdent indent | " +
                                "forecolor backcolor casechange permanentpen formatpainter removeformat | " +
                                "pagebreak | charmap emoticons | fullscreen preview save print | insertfile image media pageembed link anchor codesample | " +
                                "a11ycheck ltr rtl | showcomments addcomment",

                              toolbar_sticky: true,

                              autosave_ask_before_unload: true,
                              autosave_interval: "30s",
                              autosave_prefix: "{path}{query}-{id}-",
                              autosave_restore_when_empty: true,
                              autosave_retention: "2m",

                              image_advtab: true,

                              content_css: "default",
                              importcss_append: true,

                              image_title: true,
                              automatic_uploads: true,

                              // Handle drag and drop events
                              setup: (editor) => {
                                editor.on("drop", (e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  // Handle dropped files
                                  if (e.dataTransfer && e.dataTransfer.files) {
                                    const files = e.dataTransfer.files;
                                    for (const file of files) {
                                      const reader = new FileReader();
                                      reader.onload = (event) => {
                                        // Insert image at the cursor position
                                        editor.insertContent(
                                          `<img src="${event.target.result}" alt="${file.name}"/>`
                                        );
                                      };
                                      reader.readAsDataURL(file);
                                    }
                                  }
                                });
                              },

                              link_list: [
                                {
                                  title: "My page 1",
                                  value: "https://www.tinymce.com",
                                },
                                {
                                  title: "My page 2",
                                  value: "http://www.moxiecode.com",
                                },
                              ],
                              image_list: [
                                {
                                  title: "My image 1",
                                  value:
                                    "https://www.tiny.cloud/images/glyph-tinymce.svg",
                                },
                                {
                                  title: "My image 2",
                                  value:
                                    "https://www.tiny.cloud/docs/images/glyph-tinymce.svg",
                                },
                              ],
                              image_class_list: [
                                { title: "None", value: "" },
                                { title: "Some class", value: "some-class" },
                              ],
                              importcss_file_filter: "main.css",
                              templates: [
                                {
                                  title: "New Table",
                                  description: "creates a new table",
                                  content:
                                    "<table><tr><td>Sample Text</td></tr></table>",
                                },
                                {
                                  title: "Starting my story",
                                  description: "A sample document",
                                  content: "Once upon a time...",
                                },
                                {
                                  title: "New list with dates",
                                  description: "New List with Dates",
                                  content:
                                    "<ul><li>12 December</li><li>13 December</li></ul>",
                                },
                              ],
                              template_cdate_format:
                                "[CDATE: %m/%d/%Y : %H:%M:%S]",
                              template_mdate_format:
                                "[MDATE: %m/%d/%Y : %H:%M:%S]",
                              height: 600,
                              image_caption: true,
                              quickbars_selection_toolbar:
                                "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                              noneditable_class: "mceNonEditable",
                              toolbar_mode: "sliding",
                              spellchecker_dialog: true,
                              spellchecker_whitelist: ["Ephox", "Moxiecode"],

                              tinycomments_mode: "embedded",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                              a11y_advanced_options: true,

                              branding: false, // Remove TinyMCE branding
                              powerpaste_word_import: "clean", // Configure PowerPaste
                              powerpaste_html_import: "merge",
                              powerpaste_allow_local_images: true,

                              // Enable live media embeds
                              media_live_embeds: true,

                              // Enable line breaks
                              forced_root_block: "",
                              force_br_newlines: true,
                              force_p_newlines: false,
                            }}
                            onEditorChange={handleSelectedEditor}
                          />
                        </div>
                      )}
                    </div>
                    <div className="Button d-flex justify-content-between mt-5">
                      <button
                        className="Create FolderPermissionId"
                        onClick={handleNewFileSubmit}
                      >
                        Create File
                      </button>
                      <button className="Outline" onClick={handleNewFileClose}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={TemplateActivityModal}
          onHide={handleTemplateActivityModalClose}
          className="ModalBox modal fade MediumModal show"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleTemplateActivityModalClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Template Activity</h4>
                  </div>
                  <div className="JobTitleArea mb-2">
                    <div className="JobBox">
                      <h6>
                        <span>Template:</span> Sales Contract
                      </h6>
                    </div>
                  </div>
                  <div className="Tableheader">
                    <h6>Files</h6>
                    <div className="NewActions">
                      <a href="javascript:void(0);">
                        <img src="images/icon-53.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/download.png" />
                      </a>
                      {/* <a href="javascript:void(0);">
                        <img src="images/setting.png" />
                      </a> */}
                    </div>
                  </div>
                  <div className="TableList TableListAlign CommonHeading TableMinimumHeight">
                    <table>
                      <thead>
                        <tr>
                          <th>File Id</th>
                          <th>File Creation Process</th>
                          <th>Signature Status</th>
                          <th>Executed by</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>F1</td>
                          <td>
                            Sent to Client1 (client1@email.com) on 07/10/20 23
                          </td>
                          <td>Pending client signature</td>
                          <td>John Doe</td>
                          <td>06/16/2023</td>
                          <td>
                            <div className="TableThreeDots">
                              <ul className="">
                                <li className="dropdown">
                                  <a
                                    className=""
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/dots.png" />
                                  </a>
                                  <ol className="dropdown-menu dropdown-menuwidth">
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        data-toggle="modal"
                                        data-target="#CopyTwoViewDocumentTemplate"
                                        data-dismiss="modal"
                                      >
                                        <img src="images/view-po.png" />
                                        View File
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        data-toggle="modal"
                                        data-target="#CopySendForClientSignatureTemplate"
                                        data-dismiss="modal"
                                      >
                                        <img src="images/post_transaction.png" />
                                        Send as new
                                      </a>
                                    </li>
                                    <li>
                                      <a href="javascript:void(0);">
                                        <img src="images/Action-2.png" />
                                        Resend
                                      </a>
                                    </li>
                                    <li>
                                      <a href="javascript:void(0);">
                                        <img src="images/dropdown-delete.png" />
                                        Delete
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        data-toggle="modal"
                                        data-target="#CopySendForClientSignatureTemplate12"
                                        data-dismiss="modal"
                                      >
                                        <img src="images/send_signature.png" />
                                        Sign
                                      </a>
                                    </li>
                                    <li>
                                      <a href="javascript:void(0);">
                                        <img src="images/download.png" />
                                        Download
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        data-toggle="modal"
                                        data-target="#CopyAmendment"
                                        data-dismiss="modal"
                                      >
                                        <img src="images/icon-26.png" />
                                        Amendement
                                      </a>
                                    </li>
                                    {/* <li><a href="javascript:void(0);" data-toggle="modal" data-target="#CopyGrossMargin" data-dismiss="modal" ><img src="images/view-po.png">View Gross Margin</a></li> */}
                                  </ol>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="PaginationArea mt-3">
                    <h6>Showing 1 to 10 of 27 entries</h6>
                    <h6>
                      <img src="images/leftarrow.png" /> 01{" "}
                      <img src="images/rightarrow.png" /> Out 10
                    </h6>
                  </div>
                  <div className="Button  text-center mt-4">
                    <button
                      className="Outline"
                      onClick={handleTemplateActivityModalClose}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={DocumentHistoryModal}
          onHide={handleDocumentHistoryModalClose}
          className="ModalBox modal fade MediumModal show"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleDocumentHistoryModalClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Document History</h4>
                  </div>
                  <div className="JobTitleArea mb-2">
                    <div className="JobBox">
                      <h6>
                        <span>Document:</span>&nbsp;{fileName??""}
                      </h6>
                    </div>
                  </div>
                  <div className="TableList CommonHeading TableHeightScroll">
                    <table>
                      <thead>
                        <tr>
                          <th>Modified By</th>
                          <th>Created By</th>
                          <th>Date</th>
                          <th>Activity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {warrantyHistoryList?.clientHistories?.length > 0 ?
                          warrantyHistoryList?.clientHistories?.map(
                            (item, i) => {
                              return (
                                <tr key={i}>
                                  <td>{item.modifiedBy ?? "N/A"}</td>
                                  <td>{item.createdBy ?? "N/A"}</td>
                                  <td>{moment(item.createdAt).format('dddd, MMMM D, YYYY h:mm A')}</td>
                                  <td>{item.activity}</td>
                                </tr>
                              );
                            }
                          ):(
                            <tr>
                              <td colSpan="10">
                                <p>No Data found.</p>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
        show={ViewDocumentationModal}
        onHide={handleViewDocumentationClose}
        className="ModalBox LargeModal GreyModalContent"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleViewDocumentationClose}>
                ×
              </a>
              <div>
                <div className="ModalTitleBox">
                  <h4>View File</h4>
                </div>
                {viewDocumentData ? (
                  <div dangerouslySetInnerHTML={{ __html: viewDocumentData }} />
                ) : (
                  <p>Loading document...</p>
                )}
              </div>
              <div className="FolderForm">
                <div className="Button d-flex justify-content-between m-3">
                  <button
                    className="Outline"
                    onClick={handleViewDocumentationClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={EditDocumentationModal}
        onHide={handleEditDocumentationClose}
        className="ModalBox modal fade ExtraLargeModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleEditDocumentationClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Edit File</h4>
                </div>
                <div className="FolderForm">
                  <div className="EditOption">
                    <div className="EditOptionBox">
                      <h6>Name</h6>
                      <a href="javascript:void(0);">
                        {contentData?.name ?? "N/A"}
                        &nbsp;&nbsp;
                        <span>
                          <img src="images/fileview.png" />
                        </span>
                      </a>
                    </div>
                    <div className="EditOptionBox">
                      <h6>Folder</h6>
                      <a href="javascript:void(0);">
                        {contentData?.fileName ?? "N/A"}
                        &nbsp;&nbsp;
                        <span>{/* <img src="images/refresh.png" /> */}</span>
                      </a>
                    </div>
                    <div className="EditOptionBox">
                      <h6>Draft</h6>
                      <a href="javascript:void(0);">
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </a>
                    </div>
                    <div className="EditOptionBox">
                      <h6>Use Company Header</h6>
                      <a
                        className="FolderPermissionId"
                        onClick={() => handlHeaderPreviewModalShow()}
                      >
                        <span>
                          <img src="images/company_header.png" />
                        </span>
                      </a>
                    </div>
                    <div className="EditOptionBox">
                      <h6>Download</h6>
                      <a href={contentData?.docUrl} target="_blank">
                        <span>
                          <img src="images/download.png" />
                        </span>
                      </a>
                    </div>
                    <div className="EditOptionBox">
                      <h6>Insert Signature</h6>
                      <a
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#InsertSignatureSmall5"
                        data-dismiss="modal"
                      >
                        <span>
                          <img src="images/signature.png" />
                        </span>
                      </a>
                    </div>
                    <div className="EditOptionBox">
                      <h6>Insert Keys</h6>
                      <a
                        className="FolderpermissionId"
                        onClick={() => handleDocumentKeyModalShow()}
                      >
                        <span>
                          <img src="images/insert_keys.png" />
                        </span>
                      </a>
                    </div>
                  </div>

                  {content ? (
                    <div className="mce-pagebreak">
                      <Editor
                        apiKey="rye4mz748ekd8ke0loyu8j7gq7iu1tkxs3nticj32j848g6r" // Use your premium API key
                        onInit={(evt, editor) => (editorsRef.current = editor)}
                        value={content ?? ""}
                        init={{
                          height: 500,
                          menubar:
                            "file edit view insert format tools table help",
                          plugins: [
                            "advlist",
                            "autolink",
                            "lists",
                            "link",
                            "image",
                            "charmap",
                            "print",
                            "preview",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "code",
                            "fullscreen",
                            "insertdatetime",
                            "media",
                            "table",
                            "paste",
                            "help",
                            "wordcount",
                            "lists",
                            "table",
                            "pagebreak",
                            "spellchecker",
                            "powerpaste",
                            "mediaembed",
                            "advcode",
                            "emoticons",
                            "codesample",
                            "advtable",
                            "visualchars",
                            "visualblocks",
                            "linkchecker",
                            "checklist",
                            "media",
                            "tableofcontents",
                            "a11ychecker",
                            "permanentpen",
                            "casechange",
                            "pageembed",
                            "nonbreaking",
                            "quickbars",
                            "advlist",
                            "autosave",
                            "autolink",
                            "autoresize",
                          ],
                          toolbar:
                            "undo redo | styles| bold italic underline strikethrough | fontfamily fontsize blocks | " +
                            "alignleft aligncenter alignright alignjustify | numlist bullist checklist outdent indent | " +
                            "forecolor backcolor casechange permanentpen formatpainter removeformat | " +
                            "pagebreak | charmap emoticons | fullscreen preview save print | insertfile image media pageembed link anchor codesample | " +
                            "a11ycheck ltr rtl | showcomments addcomment",
                          toolbar_sticky: true,
                          autosave_ask_before_unload: true,
                          autosave_interval: "30s",
                          autosave_prefix: "{path}{query}-{id}-",
                          autosave_restore_when_empty: true,
                          autosave_retention: "2m",
                          image_advtab: true,
                          content_css: "default",
                          importcss_append: true,
                          image_title: true,
                          automatic_uploads: true,
                          powerpaste_word_import: "clean", // options: 'clean', 'merge', 'prompt'
                          powerpaste_html_import: "merge", // options: 'merge', 'clean', 'prompt'
                          forced_root_block: "", // allows for line breaks instead of <p>
                          force_br_newlines: true,
                          force_p_newlines: false,
                          content_style: `
          .mce-item-table:not([border]), .mce-item-table:not([border]) caption, 
          .mce-item-table:not([border]) td, .mce-item-table:not([border]) th, 
          .mce-item-table[border="0"], .mce-item-table[border="0"] caption, 
          .mce-item-table[border="0"] td, .mce-item-table[border="0"] th, 
          table[style*="border-width: 0px"], table[style*="border-width: 0px"] caption, 
          table[style*="border-width: 0px"] td, table[style*="border-width: 0px"] th { 
            border: 0;
          }
        `,
                          setup: (editor) => {
                            editor.on("drop", (e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              if (e.dataTransfer && e.dataTransfer.files) {
                                const files = e.dataTransfer.files;
                                for (const file of files) {
                                  const reader = new FileReader();
                                  reader.onload = (event) => {
                                    editor.insertContent(
                                      `<img src="${event.target.result}" alt="${file.name}"/>`
                                    );
                                  };
                                  reader.readAsDataURL(file);
                                }
                              }
                            });
                          },
                          link_list: [
                            {
                              title: "My page 1",
                              value: "https://www.tinymce.com",
                            },
                            {
                              title: "My page 2",
                              value: "http://www.moxiecode.com",
                            },
                          ],
                          image_list: [
                            {
                              title: "My image 1",
                              value:
                                "https://www.tiny.cloud/images/glyph-tinymce.svg",
                            },
                            {
                              title: "My image 2",
                              value:
                                "https://www.tiny.cloud/docs/images/glyph-tinymce.svg",
                            },
                          ],
                          image_class_list: [
                            { title: "None", value: "" },
                            { title: "Some class", value: "some-class" },
                          ],
                          importcss_file_filter: "main.css",
                          templates: [
                            {
                              title: "New Table",
                              description: "creates a new table",
                              content:
                                "<table><tr><td>Sample Text</td></tr></table>",
                            },
                            {
                              title: "Starting my story",
                              description: "A sample document",
                              content: "Once upon a time...",
                            },
                            {
                              title: "New list with dates",
                              description: "New List with Dates",
                              content:
                                "<ul><li>12 December</li><li>13 December</li></ul>",
                            },
                          ],
                          template_cdate_format: "[CDATE: %m/%d/%Y : %H:%M:%S]",
                          template_mdate_format: "[MDATE: %m/%d/%Y : %H:%M:%S]",
                          height: 600,
                          image_caption: true,
                          quickbars_selection_toolbar:
                            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                          noneditable_class: "mceNonEditable",
                          toolbar_mode: "sliding",
                          spellchecker_dialog: true,
                          spellchecker_whitelist: ["Ephox", "Moxiecode"],
                          tinycomments_mode: "embedded",
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          a11y_advanced_options: true,
                          branding: false, // Remove TinyMCE branding
                          powerpaste_word_import: "clean", // Configure PowerPaste
                          powerpaste_html_import: "merge",
                          powerpaste_allow_local_images: true,
                          media_live_embeds: true, // Enable live media embeds
                          forced_root_block: "",
                          force_br_newlines: true,
                          force_p_newlines: false,
                        }}
                        onEditorChange={handleEditorChange}
                      />
                    </div>
                  ) : (
                    <p>Loading document...</p>
                  )}
                  <div className="Button d-flex justify-content-between">
                    <button
                      className="Create mt-5"
                      onClick={handleEditDocumentation}
                    >
                      Save File
                    </button>
                    <button
                      className="Create mt-5"
                      onClick={handleEditDocumentationClose}
                    >
                      Cancel &amp; Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

        <Modal
          show={ShareClientDocumentationModal}
          onHide={handleShareClientDocumentClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleShareClientDocumentClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Share Document</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="ModalBigheading">
                      <h3>
                        <span>Documents:</span>Statement of Adjustments.pdf
                      </h3>
                    </div>
                    <div className="ModalFilterAreaBox mb-3">
                      <h4 className="mb-4">Select Recipient</h4>
                      <div className="RadioArea d-flex  flex-column">
                        <div className="form-group">
                          <label className="Radio">
                            Select Recipient
                            <input
                              type="radio"
                              name="Cuisine"
                              defaultValue="text"
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="form-group mb-4">
                          <label className="Radio">
                            Other Contact
                            <input
                              type="radio"
                              name="Cuisine"
                              defaultValue="file"
                              defaultChecked=""
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="text RadioText">
                      <div className="form-group"></div>
                    </div>
                    <div
                      className="file RadioText mb-5"
                      style={{ display: "block" }}
                    >
                      <div className="form-group">
                        <h6>Name</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert name"
                          name="clientName"
                          value={clientName}
                          onChange={handleInputChange}
                        />
                        {/* <span style={{ color: "red" }}>
                          {errors?.cliNameError}
                        </span> */}
                      </div>
                      <div className="form-group">
                        <h6>Email</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert Email"
                          name="clientEmail"
                          value={clientEmail}
                          onChange={handleInputChange}
                        />
                        {/* <span style={{ color: "red" }}>
                          {errors?.cliEmailError}
                        </span> */}
                      </div>

                      <div className="Button" onClick={handleAddRecipient}>
                        <button
                          className="Create"
                          disabled={clientEmail == "" && clientName == ""}
                        >
                          Add Recipient
                        </button>
                      </div>
                    </div>
                    <h6>Recipients</h6>
                    <div className="FilesAddedTable">
                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          {emails?.length > 0 &&
                            emails?.map((item, ind) => {
                              return (
                                <tr>
                                  <td>
                                    <div className="FilesAddedName">
                                      <p>{item.name}</p>
                                      <span>
                                        <img
                                          src="images/Cross.png"
                                          onClick={() =>
                                            handleRemoveRecipient(ind)
                                          }
                                        />
                                      </span>
                                    </div>
                                  </td>
                                  <td>{item.email}</td>
                                </tr>
                              );
                            })}
                          <span style={{ color: "red" }}>
                            {errors?.cliNameError}
                          </span>
                        </tbody>
                      </table>
                    </div>
                    <div className="form-group">
                      <h6>Message</h6>
                      <textarea
                        type="text"
                        className="form-control"
                        rows={4}
                        placeholder="Include message (optional)"
                        name="emailBody"
                        value={emailBody}
                        onChange={handleInputChange}
                      />
                      <span style={{ color: "red" }}>{errors?.eBodyError}</span>
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button
                        className="Create mr-3"
                        onClick={handleShareDocumentSubmit}
                      >
                        Share Document
                      </button>
                      <button
                        className="Outline ml-3"
                        onClick={handleShareClientDocumentClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={ManageSignatueModal}
          onHide={handleManageSignatueClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <a className="CloseModal" onClick={handleManageSignatueClose}>
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Manage Signatures</h4>
              </div>
              <div className="FolderForm">
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Add new signature</h5>
                    <div className="CommonModalBox">
                      <h5>
                        <figure>
                          <img src="images/add_new_signature.png" />
                        </figure>{" "}
                        Add new signature
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Signatures</h5>
                    <div className="CommonModalBox">
                      {/* <input
                        // type="file"
                        // name="signature"
                        // onChange={handleInputFile}
                      /> */}
                      <SignaturePad
                        ref={signCanvas}
                        onEnd={() =>
                          updateState({
                            ...iState,
                            signature: signCanvas?.current
                              ?.getTrimmedCanvas()
                              .toDataURL("image/png"),
                          })
                        }
                      />
                      <a onClick={clearSignature}>
                        <img src="images/circle_minus.png" />
                      </a>
                      {/* <a onClick={saveSignature} href="javascript:void(0);">
                        Save
                      </a> */}
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.signatureError}
                    </span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Add new Initials</h5>
                    <div className="CommonModalBox">
                      <h5>
                        <figure>
                          <img src="images/add_new_signature.png" />
                        </figure>{" "}
                        Add new Initials
                      </h5>
                      <div></div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Initials</h5>
                    <div className="CommonModalBox">
                      <SignaturePad
                        ref={intialCanvas}
                        onEnd={() =>
                          updateState({
                            ...iState,
                            initial: signCanvas?.current
                              ?.getTrimmedCanvas()
                              .toDataURL("image/png"),
                          })
                        }
                      />
                      <a onClick={clearInitials}>
                        <img src="images/circle_minus.png" />
                      </a>
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.initialError}
                    </span>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={addSignatureFun}>
                    Save
                  </button>
                  <button
                    className="Outline"
                    onClick={handleManageSignatueClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={SendDocumentModal}
          onHide={handleSendDocumentModalClose}
          className="ModalBox fade MediumModal"
        >
          <Modal.Body>
            <a className="CloseModal" onClick={handleSendDocumentModalClose}>
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Send Document for Client Signature</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="form-group">
                        <h6>Template</h6>
                        <p className="ml-0">Sales Contract</p>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <h6>File Id</h6>
                        <p className="ml-0">F1</p>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="ModalMediumheading">
                        <h3>
                          <span>Client Signees</span>
                        </h3>
                      </div>
                      <div className="form-group">
                        <h6>Number of Signee</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert Number of Signees"
                        />
                      </div>
                      <div className="form-group">
                        <h6>
                          Primary Client Signee{" "}
                          <span className="required">*</span>
                        </h6>
                        <div className="Categories Categories1">
                          <div className="Categories_all Categories_all1">
                            <span>Select Signee</span>
                            <img src="images/down.png" />
                          </div>
                          <ul style={{ display: "none" }}>
                            <li>
                              - Clients already registered in the system will
                              appear on the dropdown to be chosen. Text Format:
                              Client's name (Client's email)
                            </li>
                            <li>+ New</li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group">
                        <h6>
                          Additional Client Signee{" "}
                          <span className="required">*</span>
                        </h6>
                        <div className="Categories Categories1">
                          <div className="Categories_all Categories_all1">
                            <span>Select Signee</span>
                            <img src="images/down.png" />
                          </div>
                          <ul style={{ display: "none" }}>
                            <li>
                              - Clients already registered in the system will
                              appear on the dropdown to be chosen. Text Format:
                              Client's name (Client's email)
                            </li>
                            <li
                              className="CursorPointer FolderPermissionId"
                              data-toggle="modal"
                              data-target="#AddNewClient"
                              data-dismiss="modal"
                            >
                              + New
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group">
                        <h6>Builder Signee</h6>
                        <div className="Categories Categories1">
                          <div className="Categories_all Categories_all1">
                            <span>Select Builder Signee</span>
                            <img src="images/down.png" />
                          </div>
                          <ul style={{ display: "none" }}>
                            <li>
                              - List of employees that are that are authorized
                              to sign documents. Text Format: Employee's
                              name(Employee's email)
                            </li>
                            <li>+ New</li>
                          </ul>
                        </div>
                      </div>
                      {/* <div class="form-group">
                      <h6>Builder Signee</h6>
                      <div class="CommonSelectBox w-100">
                        <select>
                          <option selected>Select Builder Signee</option>
                          <option>Signee 1</option>
                        </select>
                        <span><img src="images/down.png"></span>
                      </div>
                    </div> */}
                    </div>
                  </div>
                  {/* <div class="EnterProjectTable">
                  <table>
                    <thead>
                      <tr>  
                        <th colspan="4">Read-Only Carbon Copies</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>
                          <div class="form-group mr-5">
                            <input type="text" class="form-control" placeholder="Insert Name">
                          </div>
                        </td>
                        <td>
                          <div class="form-group">
                            <input type="text" class="form-control" placeholder="Insert email address">
                          </div>
                        </td>
                        <td><div class="action ml-3"><img src="images/Cross.png"></div></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="Button mt-5">
                  <button class="Create" data-dismiss="modal">
                    Add
                  </button>
                </div> */}
                  <div className="Button d-flex justify-content-between mt-3">
                    <button
                      className="Create FolderPermissionId"
                      data-toggle="modal"
                      data-target="#SendForClientSignature2"
                      data-dismiss="modal"
                    >
                      Generate Document
                    </button>
                    <button
                      className="Outline"
                      data-toggle="modal"
                      data-target="#ClientDocumentation2"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={sendClientInformationModal}
          onHide={handleSendClientInformationModalClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleSendClientInformationModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Send Client Information</h4>
              </div>
              <div className="FolderForm">
                <div className="ModalBigheading2">
                  <h3>Send Client Information</h3>
                </div>
                <div className="form-group">
                  <h6>Message to Receiver</h6>
                  <textarea
                    id="editornew3"
                    className="form-control"
                    rows={5}
                    // defaultValue={""}
                    name="emailBody"
                    // value={emailBody}
                    // onChange={handleInput}
                  />
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                    {clientInfomationError?.emailBodyError}
                  </span> */}
                </div>
                <div className="SelectAvailableArea">
                  <h5>Select Available Documents</h5>
                  <div className="SelectAvailableAreaBox">
                    <h6>Sales Documents + Contracts</h6>
                    {/* {clie} */}
                    {/* {pendingLoader ? (
                      <>
                        <td>
                          {" "}
                          <Loader />
                        </td>
                      </>
                    ) : (
                      <>
                        {checkArray(fileListData?.clientDocDetails) ? (
                          fileListData?.clientDocDetails?.map((ele, ind) => {
                            return (
                              <>
                                <div className="form-group">
                                  <label className="CheckBox">
                                    {" "}
                                    {ele?.name}
                                    <input
                                      type="checkbox"
                                      name={ele.name}
                                      value={ele.name}
                                      onChange={handleCheckboxSpe}
                                    />
                                    <span className="checkmark" />
                                  </label>
                                </div>
                              </>
                            );
                          })
                        ) : (
                          <td>
                            {" "}
                            <NoDataFound />
                          </td>
                        )}
                      </>
                    )} */}

                    {/* <span style={{ color: "red", fontSize: "14px" }}>
                      {clientInfomationError?.signedDocsError}
                    </span> */}
                  </div>
                </div>
                <div className="ModalFilterAreaBox mb-3">
                  <h4 className="mb-4">Select Recipient</h4>
                  <div className="RadioArea d-flex  flex-column">
                    <div className="form-group">
                      <label className="Radio">
                        Registered Vendor
                        <input
                          type="radio"
                          name="showHideCheck"
                          value="registeredVendor"
                          // checked={showHideCheck === true}
                          // onChange={handleRadioChange}
                          // defaultChecked
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="form-group mb-4">
                      <label className="Radio">
                        Other Contact
                        <input
                          type="radio"
                          name="showHideCheck"
                          value="otherContact"
                          // checked={showHideCheck === false} // Check the radio button if showHideCheck is false
                          // onChange={handleRadioChange} // Handle the change event
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="file RadioText">
                  <div className="form-group">
                    <h6>Name</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert name"
                      name="sendInformationName"
                      // value={sendInformationName}
                      // onChange={handleInput}
                    />
                    {/* <span style={{ color: "red", fontSize: "14px" }}>
                        {clientInfomationError?.nameError}
                      </span> */}
                  </div>
                  <div className="form-group">
                    <h6>Email</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert Email"
                      name="sendInformationEmail"
                      // value={sendInformationEmail}
                      // onChange={handleInput}
                    />
                    {/* <span style={{ color: "red", fontSize: "14px" }}>
                        {clientInfomationError?.emailError}
                      </span> */}
                  </div>
                </div>

                <div
                  className="text RadioText mb-5"
                  style={{ display: "block" }}
                >
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <div className="CommonModalBox">
                        <h5
                          className="CursorPointer"
                          // onClick={handleSystemVendorModalShow}
                        >
                          <figure>
                            <img src="images/register_vendor.png" />
                          </figure>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="Button d-flex justify-content-between">
                  <button
                    className="Create"
                    // onClick={sendInformationFun}
                  >
                    Send Information
                  </button>
                  <button
                    className="Outline"
                    onClick={handleSendClientInformationModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          className="ModalBox fade SmallModal"
          show={AddNewSupportingDocsModal}
          onHide={handleAddNewSupportingDocsModalClose}
        >
          <Modal.Body>
            <div className="Category">
              <a
                className="CloseModal"
                onClick={handleAddNewSupportingDocsModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Add New Supporting Docs</h4>
                </div>
                <div className="JobTitleArea">
                  <h3>Supporting Documents #2</h3>
                  <div className="JobBox">
                    <h6>
                      <span>Job:</span> UUnit3-CHI-2B-315
                    </h6>
                  </div>
                  <div className="JobBox">
                    <h6>
                      <span>Origin</span> #185
                    </h6>
                  </div>
                  <div className="JobBox">
                    <h6>
                      <span>Item:</span> #143
                    </h6>
                  </div>
                </div>
                <div className="form-group">
                  <h6>Select Files</h6>
                  <div className="Upload">
                    <span>-Drag and Drop Files or click to select</span>
                    <input
                      type="file"
                      id="docsInput"
                      onChange={(e) => handleFileChanges(e, "docsUpdate")}
                    />
                  </div>
                </div>
                <div className="FilesAddedTitle">
                  <h4>Files</h4>
                </div>
                {supportingDocFiless && (
                  <div className="FilesAdded">
                    <label>{supportingDocFiless?.name}</label>
                    <span>
                      <img src="images/fileview.png" />
                    </span>
                    <span onClick={handleRemoveDocsFile}>
                      <img src="images/Cross.png" className="cross" />
                    </span>
                  </div>
                )}

                <div className="Button mt-3">
                  <button className="Create" onClick={handleSupportDocs}>
                    Add Documents
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          className="ModalBox fade LargeModal show"
          show={SupportingDocumentsListModal}
          onHide={handleSupportingDocumentsListModalClose}
        >
          <Modal.Body>
            <div className="Category">
              <a
                className="CloseModal"
                onClick={handleSupportingDocumentsListModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Supporting Documents List</h4>
                </div>
                <div className="JobTitleArea">
                  <div className="JobBox">
                    <h6>
                      <span>Job:</span> UUnit3-CHI-2B-315
                    </h6>
                    <div>
                      <a
                        href="javascript:void(0);"
                        className="FolderPermissionId"
                        data-toggle="modal"
                        data-target="#AddNewSupportingDocs"
                        data-dismiss="modal"
                      >
                        <img src="images/notebook.png" />
                      </a>
                    </div>
                  </div>
                  <div className="JobBox">
                    <h6>
                      <span className="text-red">Origin</span> #185
                    </h6>
                  </div>
                  <div className="JobBox">
                    <h6>
                      <span className="text-red">Item:</span> #143
                    </h6>
                  </div>
                </div>
                <div className="NotesAreaMain">
                  <div className="NotesArea mt-4">
                    <h6>Supporting Documents List</h6>
                    <div>
                      <a href="javascript:void(0);">
                        <img src="images/pdf.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/download.png" />
                      </a>
                      {/* <a href="javascript:void(0);">
                        <img src="images/setting.png" />
                      </a> */}
                    </div>
                  </div>
                  <div className="TableList">
                    <table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Sent by</th>
                          <th>Date</th>
                          <th>Notes</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {supportDocsLoader == false ? (
                          supportDocsList?.warranty?.SupportDocs?.length > 0 ? (
                            supportDocsList?.warranty?.SupportDocs?.map(
                              (data, ind) => {
                                return (
                                  <tr key={ind}>
                                    <td>1</td>
                                    <td>{data.sendBy ?? "N/A"}</td>
                                    <td>
                                      {moment(data.createdAt).format(
                                        "YYYY-MM-DD"
                                      )}
                                    </td>
                                    <td className="Inspection">
                                      {data.notes ?? "N/A"}
                                    </td>
                                    <td>
                                      <div className="Actions">
                                        <a
                                          href="javascript:void(0);"
                                          className="FolderPermissionId"
                                          data-toggle="modal"
                                          data-target="#SupportingDocsDetails"
                                          data-dismiss="modal"
                                        >
                                          <img src="images/Action-1.png" />
                                        </a>
                                        <a href="javascript:void(0);">
                                          <img src="images/delete.png" />
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          ) : (
                            <tr>
                              <td colSpan="12s">
                                <p>No Data found.</p>
                              </td>
                            </tr>
                          )
                        ) : (
                          <Loader />
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <div className="Button mt-5">
      <button
        className="Create FolderPermissionId"
        data-toggle="modal"
        data-target="#AddNewSupportingDocs"
        data-dismiss="modal"
      >
        Add New Supporting Docs
      </button>
    </div> */}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={WarrantyDeleteModal}
          onHide={handleWarrantyDeleteModalClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleWarrantyDeleteModalClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Delete</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="my-5">
                      <h6>Are you sure you want to delete this ?</h6>
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button className="Create" onClick={handleWarrantyDelete}>
                        Delete
                      </button>
                      <button
                        className="Outline"
                        onClick={handleWarrantyDeleteModalClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={WorkOrderDeleteModal}
          onHide={() => handleWorkOrderDeleteModalShowHide(null, "hide")}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={() =>
                    handleWorkOrderDeleteModalShowHide(null, "hide")
                  }
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Delete</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="my-5">
                      <h6>Are you sure you want to delete this ?</h6>
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button
                        className="Create"
                        onClick={handleWorKOrderDelete}
                      >
                        Delete
                      </button>
                      <button
                        className="Outline"
                        onClick={() =>
                          handleWorkOrderDeleteModalShowHide(null, "hide")
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={JobMenuModal}
          onHide={handleJobMenuModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleJobMenuModalClose}
            >
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Job Menu</h4>
              </div>
              <div className="FolderForm">
                <div className="JobMenuListing">
                  <ul>
                    <li>
                      <a href="javascript:void(0);">
                        <figure>
                          <img src="images/add_build.png" />
                        </figure>
                        Add to/Edit Build
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <figure>
                          <img src="images/details.png" />
                        </figure>
                        Details
                      </a>
                    </li>
                    <li className="JobMenuListing_All">
                      <Link to="/change-order-list" state={state}>
                        <figure>
                          <img src="images/change_order.png" />
                        </figure>
                        Change Order
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                      <ul className="JobMenuListingUnder">
                        <li>
                          <Link to="/change-order-list" state={state}>
                            Change orders List
                          </Link>
                        </li>
                        <li>
                          <Link to="/change-order-list" state={state}>
                            Change to Contract (CTC) master
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/design-selections" state={state}>
                        <figure>
                          <img src="images/design.png" />
                        </figure>
                        Design
                      </Link>
                    </li>
                    <li>
                      <Link to="/sales" state={state}>
                        <figure>
                          <img src="images/sales.png" />
                        </figure>
                        Sales
                      </Link>
                    </li>
                    <li>
                      <Link to="/estimation" state={state}>
                        <figure>
                          <img src="images/estimation.png" />
                        </figure>
                        Estimation
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="/construction" state={state}>
                        <figure>
                          <img src="images/construction_icon.png" />
                        </figure>
                        Construction
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                    </li>
                    {pathname !== "warranty-dashboard" && (
                      <li>
                        <Link to="/warranty-dashboard" state={state}>
                          <figure>
                            <img src="images/warranty.png" />
                          </figure>
                          Warranty
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link to="/reports" state={state}>
                        <figure>
                          <img src="images/reports.png" />
                        </figure>
                        Reports
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                    </li>

                    <li>
                      <Link to="/client-view-config" state={state}>
                        <figure>
                          <img src="images/view_config.png" />
                        </figure>
                        Views Config
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={NavigateModal}
          onHide={handleNavigateModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleNavigateModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Navigate Project</h4>
              </div>
              <div className="FolderForm">
                <div className="NaviagateProjectArea">
                  <ul>
                    {allProjectList?.projects?.length > 0 &&
                      allProjectList?.projects.map((item, index) => {
                        return (
                          <>
                            <li className="DarkBlueList AvalonTownsTab">
                              <a href="javascript:void(0);">
                                {item.projectName}
                              </a>
                            </li>
                            <li className="LightBlueList AdministrationTab">
                              <a href="javascript:void(0);">
                                {item.jobPattern}
                              </a>
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu dropdown-menuwidth DropDownMenuZindex">
                                      <li>
                                        <a>
                                          <img src="images/add_build.png" />
                                          Add to/Edit Build
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <img src="images/details.png" />
                                          Details
                                        </a>
                                      </li>
                                      <li className="border-0">
                                        <Link
                                          to="/change-order-list"
                                          state={state}
                                          className="dropdown-item"
                                          style={{
                                            border:
                                              "1px solid transparent!important",
                                          }}
                                        >
                                          <img src="images/change_order.png" />
                                          Change Order &nbsp;&nbsp;
                                          <img src="images/rightarrow2.png" />
                                        </Link>
                                        <ul className="dropdown-menu submenu">
                                          <li>
                                            <Link
                                              to="/change-order-list"
                                              state={state}
                                              className="dropdown-item"
                                            >
                                              Change orders List
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              to="/change-order-list"
                                              state={state}
                                              className="dropdown-item"
                                            >
                                              Change to Contract (CTC) master
                                            </Link>
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <Link
                                          to="/design-selections"
                                          state={state}
                                        >
                                          <img src="images/design.png" />
                                          Design
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/sales" state={state}>
                                          <img src="images/sales.png" />
                                          Sales
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/estimation" state={state}>
                                          <img src="images/estimation.png" />
                                          Estimation
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/construction" state={state}>
                                          <img src="images/construction_icon.png" />
                                          Construction
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to="/warranty-dashboard"
                                          state={state}
                                        >
                                          <img src="images/warranty.png" />
                                          Warranty
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/reports" state={state}>
                                          <img src="images/reports.png" />
                                          Reports
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to="/client-view-config"
                                          state={state}
                                        >
                                          <img src="images/view_config.png" />
                                          Views Config
                                        </Link>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={ServicTypeModal}
          onHide={handleServicTypeModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleServicTypeModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Service Type Selection</h4>
              </div>
              <div className="FolderForm">
                <div className="ModalMediumheading">
                  <h3>
                    <span>Job: </span> {state?.jobType}
                  </h3>
                  <h3>
                    <span>Warranty request # {requestedData?.[0]?.WarrantyId} </span>
                  </h3>
                </div>
               
                
                    <div className="d-flex flex-column">
                      <div className="form-group">
                        <label className="d-block">Service Type Selection</label>
                        <select
                          name="serviceType"
                          value={serviceType}
                          onChange={handleInputChange}
                          style={{
                            width: "100%",
                            padding: "18px 17px",
                            borderRadius: "10px",
                            borderColor: "#80808066"
                          }}
                        >
                          <option value="">Select Service Type</option>
                          {allServiceTypeList?.warrantyOptions?.length > 0 &&
                            allServiceTypeList?.warrantyOptions?.map(
                              (data, ind) => {
                                return (
                                  <option key={ind} 
                                  value={data.id} selected={data.id==serviceType}>
                                    {data.name}
                                  </option>
                                );
                              }
                            )}
                        </select>
                      </div>
                    </div>
                 
             

                <div className="Button d-flex justify-content-between mt-5">
                  <button className="Create" onClick={handleUpdateServiceType}>
                    Update Service Type
                  </button>
                  <button
                    className="Outline"
                    onClick={handleServicTypeModalClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={AssignInspectorModal}
          onHide={handleAssignInspectorModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleAssignInspectorModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Assigned Inspector</h4>
              </div>
              <div className="FolderForm">
                <div className="ModalMediumheading">
                  <h3>
                    <span>Job: </span> {state?.jobType}
                  </h3>
                  <h3>
                    <span>Warranty request # {requestedData?.[0]?.WarrantyId} </span>
                  </h3>
                </div>
               
                
                    <div className="d-flex flex-column">
                      <div className="form-group">
                        <label className="d-block">Service Assigned Inspector</label>
                        <select
                          name="inspector"
                          value={inspector}
                          onChange={handleInputChange}
                          style={{
                            width: "100%",
                            padding: "18px 17px",
                            borderRadius: "10px",
                            borderColor: "#80808066"
                          }}
                        >
                          <option value="">Select Inspector Name</option>
                          {inspectorList?.approverList?.length > 0 &&
                            inspectorList?.approverList?.map((data, ind) => {
                              return (
                                <option key={ind} value={data.id}>
                                  {data.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                 
             

                <div className="Button d-flex justify-content-between mt-5">
                  <button className="Create" >
                  Update Inspection
                  </button>
                  <button
                    className="Outline"
                    onClick={handleAssignInspectorModalClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
        show={HeaderPreviewModal}
        onHide={handlHeaderPreviewModalClose}
        className="ModalBox modal fade LargeModal GreyModalContent show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handlHeaderPreviewModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Header Preview</h4>
            </div>

            <div className="FolderForm">
              <div className="AgreementViewArea">
                <div className="AgreementViewTop">
                  {/* <a href="javscript:void(0)">
                    <img src="images/modal-big-logo.png" />
                  </a> */}
                  <div className="AgreementViewTopRight">
                    <p
                      dangerouslySetInnerHTML={createAboutHeader()}
                      className="editor"
                    ></p>
                  </div>
                </div>
              </div>

              <div className="Button d-flex justify-content-between mt-5">
                <button className="Create" onClick={() => handleApply()}>
                  Apply
                </button>
                <button
                  className="Outline"
                  onClick={handlHeaderPreviewModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      </div>
    </>
  );
};

export default Warranty_Dashboard;
