import { clientViewConfigAction } from "../actionTypes";
import { Url } from  "../../Config/Config";
import axios from "axios";
import { isLoggedIn } from "../../utils/tokenCheck";
import { multiPartData, multiPartDatas } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

//---------------------------------warrantyList--------------------------------------------------

export const getClientViewWarrantyListInitiate = () => ({
  type: clientViewConfigAction.GET_CLIENT_VIEW_WARRANTY_LIST_INITIATE,
});

export const getClientViewWarrantyListSuccess = (data) => ({
  type: clientViewConfigAction.GET_CLIENT_VIEW_WARRANTY_LIST_SUCCESS,
  payload: data,
});

export const getClientViewWarrantyListFailure = (data) => ({
  type: clientViewConfigAction.GET_CLIENT_VIEW_WARRANTY_LIST_FAILURE,
  payload: data,
});

export const getClientViewWarrantyList = (viewConfigId,payload) => async (dispatch) => {
    try {
        const token = isLoggedIn("adminData1");
      dispatch(getClientViewWarrantyListInitiate(payload));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/view-config/${viewConfigId}/warranties`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch(getClientViewWarrantyListSuccess(response.data));
      } else {
        dispatch(getClientViewWarrantyListFailure(response.data));
      }
      return response
    } catch (err) {
      dispatch(getClientViewWarrantyListFailure(err));
      return err.response
    }
  };

//---------------------------------warrantyuPDATE--------------------------------------------------

export const clientViewWarrantyUpdateInitiate = () => ({
    type: clientViewConfigAction.CLIENT_VIEW_WARRANTY_UPDATE_INITIATE,
  });
  
  export const clientViewWarrantyUpdateSuccess = (data) => ({
    type: clientViewConfigAction.CLIENT_VIEW_WARRANTY_UPDATE_SUCCESS,
    payload: data,
  });
  
  export const clientViewWarrantyUpdateFailure = (data) => ({
    type: clientViewConfigAction.CLIENT_VIEW_WARRANTY_UPDATE_FAILURE,
    payload: data,
  });
  
  export const clientViewWarrantyUpdate = (configId,warntId,payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(clientViewWarrantyUpdateInitiate(payload));
        const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/view-config/${configId}/warranties/${warntId}`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          dispatch(clientViewWarrantyUpdateSuccess(response.data));
        } else {
          dispatch(clientViewWarrantyUpdateFailure(response.data));
        }
        return response
      } catch (err) {
        dispatch(clientViewWarrantyUpdateFailure(err));
        return err.response
      }
    };

//---------------------------------ChangeOrderData-------------------------------------------------

export const getChangeOrderDataInitiate = () => ({
    type: clientViewConfigAction.GET_CHANGE_ORDER_DATA_INITIATE,
  });
  
  export const getChangeOrderDataSuccess = (data) => ({
    type: clientViewConfigAction.GET_CHANGE_ORDER_DATA_SUCCESS,
    payload: data,
  });
  
  export const getChangeOrderDataFailure = (data) => ({
    type: clientViewConfigAction.GET_CHANGE_ORDER_DATA_FAILURE,
    payload: data,
  });
  
  export const getChangeOrderData = (configId,payload) => async (dispatch) => {
      try {
          const token = isLoggedIn("adminData1");
        dispatch(getChangeOrderDataInitiate(payload));
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/view-config/${configId}/change-orders`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          dispatch(getChangeOrderDataSuccess(response.data));
        } else {
          dispatch(getChangeOrderDataFailure(response.data));
        }
        return response
      } catch (err) {
        dispatch(getChangeOrderDataFailure(err));
        return err.response
      }
    };
//========================================ChangeOrderUpdate================================


export const changeOrderUpdateInitiate = () => ({
    type: clientViewConfigAction.CHANGE_ORDER_DATA_UPDATE_INITIATE,
  });
  
  export const changeOrderUpdateSuccess = (data) => ({
    type: clientViewConfigAction.CHANGE_ORDER_DATA_UPDATE_SUCCESS,
    payload: data,
  });
  
  export const changeOrderUpdateFailure = (data) => ({
    type: clientViewConfigAction.CHANGE_ORDER_DATA_UPDATE_FAILURE,
    payload: data,
  });
  
  export const changeOrderUpdate = (configId,warntId,payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(changeOrderUpdateInitiate(payload));
        const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/view-config/${configId}/change-orders/${warntId}`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          dispatch(changeOrderUpdateSuccess(response.data));
        } else {
          dispatch(changeOrderUpdateFailure(response.data));
        }
        return response
      } catch (err) {
        dispatch(changeOrderUpdateFailure(err));
        return err.response
      }
    };

//==========================================getAppointmentData========================================


export const getAppointmentDataInitiate = () => ({
    type: clientViewConfigAction.GET_APPOINTMENT_DATA_INITIATE,
  });
  
  export const getAppointmentDataSuccess = (data) => ({
    type: clientViewConfigAction.GET_APPOINTMENT_DATA_SUCCESS,
    payload: data,
  });
  
  export const getAppointmentDataFailure = (data) => ({
    type: clientViewConfigAction.GET_APPOINTMENT_DATA_FAILURE,
    payload: data,
  });
  
  export const getAppointmentData = (configId) => async (dispatch) => {
      try {
          const token = isLoggedIn("adminData1");
        dispatch(getAppointmentDataInitiate(configId));
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/view-config/${configId}/appointments`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          dispatch(getAppointmentDataSuccess(response.data));
        } else {
          dispatch(getAppointmentDataFailure(response.data));
        }
        return response
      } catch (err) {
        dispatch(getAppointmentDataFailure(err));
        return err.response
      }
    };
//==========================================updateAppointmentData================================


 export const changeAppointmentUpdateInitiate = () => ({
    type: clientViewConfigAction.CHANGE_APPOINTMENT_DATA_UPDATE_INITIATE,
  });
  
  export const changeAppointmentUpdateSuccess = (data) => ({
    type: clientViewConfigAction.CHANGE_APPOINTMENT_DATA_UPDATE_SUCCESS,
    payload: data,
  });
  
  export const changeAppointmentUpdateFailure = (data) => ({
    type: clientViewConfigAction.CHANGE_APPOINTMENT_DATA_UPDATE_FAILURE,
    payload: data,
  });
  
  export const changeAppointmentUpdate = (configId,warntId,payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(changeAppointmentUpdateInitiate(payload));
        const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/view-config/${configId}/appointments/${warntId}`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          dispatch(changeAppointmentUpdateSuccess(response.data));
        } else {
          dispatch(changeAppointmentUpdateFailure(response.data));
        }
        return response
      } catch (err) {
        dispatch(changeAppointmentUpdateFailure(err));
        return err.response
      }
    };

//========================================constructionDaata============================================


export const getConstructionDataInitiate = () => ({
  type: clientViewConfigAction.GET_CONSTRUCTION_DATA_INITIATE,
});

export const getConstructionDataSuccess = (data) => ({
  type: clientViewConfigAction.GET_CONSTRUCTION_DATA_SUCCESS,
  payload: data,
});

export const getConstructionDataFailure = (data) => ({
  type: clientViewConfigAction.GET_CONSTRUCTION_DATA_FAILURE,
  payload: data,
});

export const getConstructionData = (configId) => async (dispatch) => {
    try {
        const token = isLoggedIn("adminData1");
      dispatch(getConstructionDataInitiate(configId));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/view-config/${configId}/constructions`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch(getConstructionDataSuccess(response.data));
      } else {
        dispatch(getConstructionDataFailure(response.data));
      }
      return response
    } catch (err) {
      dispatch(getConstructionDataFailure(err));
      return err.response
    }
  };
//================================================updateConstruction======================================

export const changeConstructionUpdateInitiate = () => ({
  type: clientViewConfigAction.CHANGE_CONSTRUCTION_DATA_UPDATE_INITIATE,
});

export const changeConstructionUpdateSuccess = (data) => ({
  type: clientViewConfigAction.CHANGE_CONSTRUCTION_DATA_UPDATE_SUCCESS,
  payload: data,
});

export const changeConstructionUpdateFailure = (data) => ({
  type: clientViewConfigAction.CHANGE_CONSTRUCTION_DATA_UPDATE_FAILURE,
  payload: data,
});

export const changeConstructionUpdate = (configId,warntId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(changeConstructionUpdateInitiate(payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/view-config/${configId}/constructions/${warntId}`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch(changeConstructionUpdateSuccess(response.data));
      } else {
        dispatch(changeConstructionUpdateFailure(response.data));
      }
      return response
    } catch (err) {
      dispatch(changeConstructionUpdateFailure(err));
      return err.response
    }
  };

//=================================SaleData================================


export const getSaleDataInitiate = () => ({
  type: clientViewConfigAction.GET_SALE_DATA_INITIATE,
});

export const getSaleDataSuccess = (data) => ({
  type: clientViewConfigAction.GET_SALE_DATA_SUCCESS,
  payload: data,
});

export const getSaleDataFailure = (data) => ({
  type: clientViewConfigAction.GET_SALE_DATA_FAILURE,
  payload: data,
});

export const getSaleData = (configId) => async (dispatch) => {
    try {
        const token = isLoggedIn("adminData1");
      dispatch(getSaleDataInitiate(configId));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/view-config/${configId}/client-view-sale`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch(getSaleDataSuccess(response.data));
      } else {
        dispatch(getSaleDataFailure(response.data));
      }
      return response
    } catch (err) {
      dispatch(getSaleDataFailure(err));
      return err.response
    }
  };
//======================================saleUpdate===========================

export const changeSaleUpdateInitiate = () => ({
  type: clientViewConfigAction.CHANGE_SALE_DATA_UPDATE_INITIATE,
});

export const changeSaleUpdateSuccess = (data) => ({
  type: clientViewConfigAction.CHANGE_SALE_DATA_UPDATE_SUCCESS,
  payload: data,
});

export const changeSaleUpdateFailure = (data) => ({
  type: clientViewConfigAction.CHANGE_SALE_DATA_UPDATE_FAILURE,
  payload: data,
});

export const changeSaleUpdate = (configId,warntId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(changeSaleUpdateInitiate(payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/view-config/${configId}/client-view-sale/${warntId}`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch(changeSaleUpdateSuccess(response.data));
      } else {
        dispatch(changeSaleUpdateFailure(response.data));
      }
      return response
    } catch (err) {
      dispatch(changeSaleUpdateFailure(err));
      return err.response
    }
  };

//=====================================generalInformation================================


export const getGeneralInformationDataInitiate = () => ({
  type: clientViewConfigAction.GENERAL_INFORMATION_DATA_INITIATE,
});

export const getGeneralInformationDataSuccess = (data) => ({
  type: clientViewConfigAction.GENERAL_INFORMATION_DATA_SUCCESS,
  payload: data,
});

export const getGeneralInformationDataFailure = (data) => ({
  type: clientViewConfigAction.GENERAL_INFORMATION_DATA_FAILURE,
  payload: data,
});

export const getGeneralInformationData = (jobId,jobType) => async (dispatch) => {
    try {
        const token = isLoggedIn("adminData1");
      dispatch(getGeneralInformationDataInitiate(jobId,jobType));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/view-config?jobId=${jobId}&jobType=${jobType}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch(getGeneralInformationDataSuccess(response.data));
      } else {
        dispatch(getGeneralInformationDataFailure(response.data));
      }
      return response
    } catch (err) {
      dispatch(getGeneralInformationDataFailure(err));
      return err.response
    }
  };

//==================================changeGeneralInformation================================

export const changeGeneralInformationUpdateInitiate = () => ({
  type: clientViewConfigAction.CHANGE_GENERAL_INFORMATION_DATA_UPDATE_INITIATE,
});

export const changeGeneralInformationUpdateSuccess = (data) => ({
  type: clientViewConfigAction.CHANGE_GENERAL_INFORMATION_DATA_UPDATE_SUCCESS,
  payload: data,
});

export const changeGeneralInformationUpdateFailure = (data) => ({
  type: clientViewConfigAction.CHANGE_GENERAL_INFORMATION_DATA_UPDATE_FAILURE,
  payload: data,
});

export const changeGeneralInformationUpdate = (configId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(changeGeneralInformationUpdateInitiate(payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/view-config/${configId}`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch(changeGeneralInformationUpdateSuccess(response.data));
      } else {
        dispatch(changeGeneralInformationUpdateFailure(response.data));
      }
      return response
    } catch (err) {
      dispatch(changeGeneralInformationUpdateFailure(err));
      return err.response
    }
  };

//============================getDesignSelectionData============================


export const designSelectionsDataInitiate = () => ({
  type: clientViewConfigAction.DESGIN_SELECTIONS_DATA_INITIATE,
});

export const designSelectionsDataSuccess = (data) => ({
  type: clientViewConfigAction.DESGIN_SELECTIONS_DATA_SUCCESS,
  payload: data,
});

export const designSelectionsDataFailure = (data) => ({
  type: clientViewConfigAction.DESGIN_SELECTIONS_DATA_FAILURE,
  payload: data,
});

export const getDesignSelectionsData = (configId) => async (dispatch) => {
    try {
        const token = isLoggedIn("adminData1");
      dispatch(designSelectionsDataInitiate(configId));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/view-config/${configId}/design-selections`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch(designSelectionsDataSuccess(response.data));
      } else {
        dispatch(designSelectionsDataFailure(response.data));
      }
      return response
    } catch (err) {
      dispatch(designSelectionsDataFailure(err));
      return err.response
    }
  };

//==================================updateDesignSelections================================

export const changeDesginSelectionsDataUpdateInitiate = () => ({
  type: clientViewConfigAction.CHANGE_DESGIN_SELECTIONS_DATA_UPDATE_INITIATE,
});

export const changeDesginSelectionsDataUpdateSuccess = (data) => ({
  type: clientViewConfigAction.CHANGE_DESGIN_SELECTIONS_DATA_UPDATE_SUCCESS,
  payload: data,
});

export const changeDesginSelectionsDataUpdateFailure = (data) => ({
  type: clientViewConfigAction.CHANGE_DESGIN_SELECTIONS_DATA_UPDATE_FAILURE,
  payload: data,
});

export const changeDesginSelectionsDataUpdate = (configId,desginId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(changeDesginSelectionsDataUpdateInitiate(payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/view-config/${configId}/design-selections/${desginId}`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch(changeDesginSelectionsDataUpdateSuccess(response.data));
      } else {
        dispatch(changeDesginSelectionsDataUpdateFailure(response.data));
      }
      return response
    } catch (err) {
      dispatch(changeDesginSelectionsDataUpdateFailure(err));
      return err.response
    }
  };
//===============================createClinetUser ================================


export const createClientUserInitiate = () => ({
  type: clientViewConfigAction.CREATE_CLIENT_USER_INITIATE,
});

export const createClientUserSuccess = (data) => ({
  type: clientViewConfigAction.CREATE_CLIENT_USER_SUCCESS,
  payload: data,
});

export const createClientUserFailure = (data) => ({
  type: clientViewConfigAction.CREATE_CLIENT_USER_FAILURE,
  payload: data,
});

export const createClientUser = (configId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createClientUserInitiate(payload));
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/view-config/${configId}/client-view-user`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 201) {
        dispatch(createClientUserSuccess(response.data));
      } else {
        dispatch(createClientUserFailure(response.data));
      }
      return response
    } catch (err) {
      dispatch(createClientUserFailure(err));
      return err.response
    }
  };

//========================================allClientUser===============================

export const allClientUserInitiate = () => ({
  type: clientViewConfigAction.ALL_CLIENT_USER_INITIATE,
});

export const allClientUserSuccess = (data) => ({
  type: clientViewConfigAction.ALL_CLIENT_USER_SUCCESS,
  payload: data,
});

export const allClientUserFailure = (data) => ({
  type: clientViewConfigAction.ALL_CLIENT_USER_FAILURE,
  payload: data,
});

export const getAllClientUser = (configId,jobId,jobType) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(allClientUserInitiate(configId,jobId,jobType));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/view-config/${configId}/client-view-user-list?jobId=${jobId}&jobType=${jobType}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch(allClientUserSuccess(response.data));
      } else {
        dispatch(allClientUserFailure(response.data));
      }
      return response
    } catch (err) {
      dispatch(allClientUserFailure(err));
      return err.response
    }
  };

  //=============================REMOVE--USSER==========================

  export const removeClientUserInitiate = () => ({
    type: clientViewConfigAction.REMOVE_CLIENT_USER_INITIATE,
  });
  
  export const removeClientUserSuccess = (data) => ({
    type: clientViewConfigAction.REMOVE_CLIENT_USER_SUCCESS,
    payload: data,
  });
  
  export const removeClientUserFailure = (data) => ({
    type: clientViewConfigAction.REMOVE_CLIENT_USER_FAILURE,
    payload: data,
  });
  
  export const getRemoveClientUser = (configId,userId) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(removeClientUserInitiate())
        const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/view-config/${configId}/users/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          dispatch(removeClientUserSuccess(response.data));
        } else {
          dispatch(removeClientUserFailure(response.data));
        }
        return response
      } catch (err) {
        dispatch(removeClientUserFailure(err));
        return err.response
      }
    };