
import { modelsAction } from "../actionTypes";
import { Url } from "../../Config/Config";
import axios from "axios";
import { isLoggedIn } from "../../utils/tokenCheck";
import { multiPartData, multiPartDatas } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

//===========================createModels================================

export const createModelsInitiate = () => ({
    type: modelsAction.CREATE_MODELS_INITIATE,
  });
  
  export const createModelsSuccess = (data) => ({
    type: modelsAction.CREATE_MODELS_SUCCESS,
    payload: data,
  });
  
  export const createModelsFailure = (data) => ({
    type: modelsAction.CREATE_MODELS_FAILURE,
    payload: data,
  });
  
  export const createModels = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(createModelsInitiate(payload));
        const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/model`, payload,{
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 201) {
          dispatch(createModelsSuccess(response.data));
        } else {
          dispatch(createModelsFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(createModelsFailure(err));
        return err.response
      }
    };

//===============================getModels====================================

export const modelsListInitiate = () => ({
    type: modelsAction.MODELS_LIST_INITIATE,
  });
  
  export const modelsListSuccess = (data) => ({
    type: modelsAction.MODELS_LIST_SUCCESS,
    payload: data,
  });
  
  export const modelsListFailure = (data) => ({
    type: modelsAction.MODELS_LIST_FAILURE,
    payload: data,
  });
  
  export const getModelsList = () => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(modelsListInitiate());
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/model`,{
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(modelsListSuccess(response.data));
        } else {
          dispatch(modelsListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(modelsListFailure(err));
        return err.response
      }
    };
//===============================get-all-projects======================

export const allProjectsListInitiate = () => ({
    type: modelsAction.ALL_PROJECTS_LIST_INITIATE,
  });
  
  export const allProjectsListSuccess = (data) => ({
    type: modelsAction.ALL_PROJECTS_LIST_SUCCESS,
    payload: data,
  });
  
  export const allProjectsListFailure = (data) => ({
    type: modelsAction.ALL_PROJECTS_LIST_FAILURE,
    payload: data,
  });
  
  export const getAllProjects = (ProjectId) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(allProjectsListInitiate());
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/all-project?projectType=Single Family&ProjectId=${ProjectId??""}`,{
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(allProjectsListSuccess(response.data));
        } else {
          dispatch(allProjectsListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(allProjectsListFailure(err));
        return err.response
      }
    };

//==========================get-job-list-by-project-type=============

export const jobListProjectByTypeInitiate = () => ({
    type: modelsAction.JOB_LIST_PROJECT_BY_TYPE_INITIATE,
  });
  
  export const jobListProjectByTypeSuccess = (data) => ({
    type: modelsAction.JOB_LIST_PROJECT_BY_TYPE_SUCCESS,
    payload: data,
  });
  
  export const jobListProjectByTypeFailure = (data) => ({
    type: modelsAction.JOB_LIST_PROJECT_BY_TYPE_FAILURE,
    payload: data,
  });
  
  export const getJobListProjectByType = (projId,projType) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(jobListProjectByTypeInitiate());
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/complete/job?projectId=${projId}&projectType=${projType}`,{
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(jobListProjectByTypeSuccess(response.data));
        } else {
          dispatch(jobListProjectByTypeFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(jobListProjectByTypeFailure(err));
        return err.response
      }
    };

//==============================delete=============================

export const deleteModelsInitiate = () => ({
  type: modelsAction.MODELS_DELETE_INITIATE,
});

export const deleteModelsSuccess = (data) => ({
  type: modelsAction.MODELS_DELETE_SUCCESS,
  payload: data,
});

export const deleteModelsFailure = (data) => ({
  type: modelsAction.MODELS_DELETE_FAILURE,
  payload: data,
});

export const getModelsDelete = (modelId) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    // const { modelId: extractedDocId } = modelId;
    dispatch(deleteModelsInitiate());
    const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/model?modelId=${Object.values(modelId)}`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(deleteModelsSuccess(response.data));
    } else {
      dispatch(deleteModelsFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(deleteModelsFailure(err));
    return err.response;
  }
};

//========================UPDATE==================================

export const updateModelsInitiate = () => ({
  type: modelsAction.UPDATE_MODELS_INITIATE,
});

export const updateModelsSuccess = (data) => ({
  type: modelsAction.UPDATE_MODELS_SUCCESS,
  payload: data,
});

export const updateModelsFailure = (data) => ({
  type: modelsAction.UPDATE_MODELS_FAILURE,
  payload: data,
});

export const updateModels = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(updateModelsInitiate(payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/model`, payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(updateModelsSuccess(response.data));
      } else {
        dispatch(updateModelsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateModelsFailure(err));
      return err.response
    }
  };

//=====================================list===project-==jobs=============================


export const listProjectJobsInitiate = () => ({
  type: modelsAction.LIST_PROJECT_JOBS_INITIATE,
});

export const listProjectJobsSuccess = (data) => ({
  type: modelsAction.LIST_PROJECT_JOBS_SUCCESS,
  payload: data,
});

export const listProjectJobsFailure = (data) => ({
  type: modelsAction.LIST_PROJECT_JOBS_FAILURE,
  payload: data,
});

export const getAllListProjectJobs = (projectId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(listProjectJobsInitiate(projectId));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${projectId}/jobs`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(listProjectJobsSuccess(response.data));
      } else {
        dispatch(listProjectJobsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(listProjectJobsFailure(err));
      return err.response
    }
  };

//=========================delete--archive--jobs===================

export const deleteArchiveJobsInitiate = () => ({
  type: modelsAction.DELETE_ARCHIVE_JOBS_INITIATE,
});

export const deleteArchiveSuccess = (data) => ({
  type: modelsAction.DELETE_ARCHIVE_JOBS_SUCCESS,
  payload: data,
});

export const deleteArchiveFailure = (data) => ({
  type: modelsAction.DELETE_ARCHIVE_JOBS_FAILURE,
  payload: data,
});

export const deleteArchiveJobs = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(deleteArchiveJobsInitiate());
      const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/jobs`, {
        headers: { Authorization: `Bearer ${token}` },
        data: payload,
      });
      if (response.status == 200) {
        dispatch(deleteArchiveSuccess(response.data));
      } else {
        dispatch(deleteArchiveFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(deleteArchiveFailure(err));
      return err.response
    }
  };