import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

const initialState = {
  buildingPermit: "",
  subdividision: "",
  specificationDoc: "",
  taxProfile: "",
  baseModel: "",
  homeType: "",
  defaultCurrency: "",
  costScheme: "",
  timeZone: "",
  accountSoftware: false,
  underParkade: false,
  jobAddress: "",
  lot: "",
  block: "",
  phase: "",
  city: "",
  zipCode: "",
  country: "",
  province: "",
  numOfBlocks: 0,
  blocks: [],
  numOfBuildings: 0,
  buildings: [],
  errorMsg: {},
};

const ChangeOrderCheckList = ({
  ChangeOrderCheckListModal,

  SetChangeOrderCheckListModal,
  SetConsolidatedChangeOrderCheckListModal
}) => {

  const handleChangeOrderCheckListModalClose = () => {
    SetChangeOrderCheckListModal(false);

    SetConsolidatedChangeOrderCheckListModal(true)

  };



  //   const handleSubmit = (e) => {
  //     e.preventDefault();

  //     let formIsValid = handleValidation();

  //     if (formIsValid) {
  //       SetProjectAsEntityStep1Modal(false);
  //       SetProjectAsEntityStep2Modal(true);
  //     }
  //   };



  return (
    <>
      <Modal
        show={ChangeOrderCheckListModal}
        className="ModalBox LargeModal BgClass"
      >
        <a
          class="CloseModal ClosePermissionId"
          onClick={handleChangeOrderCheckListModalClose}
        >
          ×
        </a>
        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Change Order Checklist</h4>
          </div>
          <div class="JobTitleArea mb-2">
            <div class="JobBox">
              <h6>
                <span>Job:</span> UUnit3-CHI-2B-315
              </h6>
            </div>
          </div>
          <div class="NotesArea mb-2">
            <h6>Change Orders</h6>
            <div>
              <a href="#">
                <img src="images/pdf.png" />
              </a>
              <a href="#">
                <img src="images/download.png" />
              </a>
              <a href="#">
                <img src="images/setting.png" />
              </a>
            </div>
          </div>
          <div class="TableList CommonHeading">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Order id</th>
                  <th>Description</th>
                  <th>Account Description</th>
                  <th>Date Created</th>
                  <th>Date Scheduled</th>
                  <th>Client</th>
                  <th>Approved</th>
                  <th>Date approved</th>
                  <th>Status</th>
                  <th>Date Completed</th>
                  <th>Completed by</th>
                  <th>Supporting Doc</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>8-0304-F1</td>
                  <td>Change Garage Door to Metal Option</td>
                  <td>Overhead Garage Door - Labor</td>
                  <td>04/20/20 23 3:27:45 PM</td>
                  <td>04/25/20 23 10 :00:00 AM</td>
                  <td>Jane Jan</td>
                  <td>No</td>
                  <td></td>
                  <td>Pending</td>
                  <td></td>
                  <td></td>
                  <td>
                    <a href="#">
                      <img src="images/notebook.png" />
                    </a>
                  </td>
                  <td>
                    <div class="Actions">
                      <a>
                        <img src="images/Action-1.png" />
                      </a>
                      <a>
                        <img src="images/Icon-5.png" />
                      </a>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>1</td>
                  <td>8-0304-F1</td>
                  <td>Change Garage Door to Metal Option</td>
                  <td>Overhead Garage Door - Labor</td>
                  <td>04/20/20 23 3:27:45 PM</td>
                  <td>04/25/20 23 10 :00:00 AM</td>
                  <td>Jane Jan</td>
                  <td>No</td>
                  <td></td>
                  <td>Pending</td>
                  <td></td>
                  <td></td>
                  <td>
                    <a href="#">
                      <img src="images/notebook.png" />
                    </a>
                  </td>
                  <td>
                    <div class="Actions">
                      <a>
                        <img src="images/Action-1.png" />
                      </a>
                      <a>
                        <img src="images/Icon-5.png" />
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="Button mt-5">
    
            <button class="Create ml-3" data-dismiss="modal">
              Create new Field Order P.O.
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ChangeOrderCheckList;
