import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  addClientUser,
  clientUserPasswordEmailSend,
  clientViewGeneralConfig,
  getClientUserList,
  removeClientUser,
  updateClientUser,
} from "../../redux/action/clientViewAuthAction";
import { update } from "lodash";
import { Checkbox } from "react-bootstrap";
import NoDataFound from "../Nodatafound";
import {
  headerNotifcaitionList,
  removeHeaderNotifcaitionList,
  updateChangePasswordLinkAction,
} from "../../redux/action/authAction";
import { checkArray } from "../../utils/CheckType";
import {
  getAllProjectDetailsAction,
  getAllProjectListAction,
} from "../../redux/action/jobAction";
import { notificationsAction } from "../../redux/actionTypes";
import {
  AddDiscussionAction,
  AddReplyAction,
  discussionListAction,
} from "../../redux/action/constructionsAction";
import UserProfile from "../headerModals/userProfile";
import ContactList from "../headerModals/contactList";
import Loader from "../Loader";
import AddContact from "../headerModals/addContact";
import ContactDetails from "../headerModals/contactDetails";
import {
  addHelpAndSupportAction,
  getHeaderHelpListAction,
  helpDetailsAction,
  helpListAction,
} from "../../redux/action/systemAdministrationAction";
import { errorToast, sucessToast } from "../../utils/toast";
import moment from "moment";
import { AdminUserDropdownAction } from "../../redux/action/dropdownAction";
import { getAllIbuildAdminUserListAction } from "../../redux/action/ibuildAdminRoleManagement";
const initialState = {
  ManageUsersModal: false,
  CreateUserModal: false,
  fullName: "",
  email: "",
  UpdateUserModal: false,
  EmailModal: false,
  emails: "",
};

const init = {
  email: "",
  emailModal: false,
  emailError: "",
  projectDetailsModal: false,
  projectDetailsData: [],
  ProjectNameShow: "",
  companyId: "",
  clientName: "",
  phoneNumber: "",
  availability: "",
  contactPrefrence: "",
  languagePrefrence: "",
  issueDetailsType: "",
  severity: "",
  module: "",
  description: "",
  problemStartDate: "",
  time: "",
  timeType: "",
  timeZone: "",
  errorMessage: "",
  applicationLink: "",
  helpAndSupportError: {},
  avinashEmail: "",
  supportingDocFiles: [],
  falseImage: "",
  addSupportModal: false,
  historyListData: [],
  supportListingModal: false,
  supportDetailsModal: false,
};
const IbuildAdminHeader = () => {
  const naviagte = useNavigate();
  const dispatch = useDispatch();
  const [iAvinashState, updateAvinashState] = useState(initialState);
  const {
    ManageUsersModal,
    CreateUserModal,
    fullName,
    avinashEmail,
    UpdateUserModal,
    emails,
    EmailModal,
  } = iAvinashState;
  const handlelLogout = () => {
    naviagte("/");
    window.localStorage.clear();
  };
  const { adminUserList } = useSelector((state) => state.dropdownReducer);
  const { clientViewConfigData, clientList } = useSelector(
    (state) => state.clientViewLogin
  );
  console.log("clientList", clientList);
  const clientData = JSON.parse(window.localStorage.getItem("clientViewLogin"));
console.log('adminUserList', adminUserList)
  useEffect(() => {
    
   
        dispatch(
      clientViewGeneralConfig(
        clientData?.jobDetails?.id,
        clientData?.jobDetails?.jobType
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getClientUserList(res?.data?.ViewSetting?.id));
      }
    });
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };
  const handleManageUsersModalShow = () => {
    updateAvinashState({ ...iAvinashState, ManageUsersModal: true });
  };

  const handleManageUsersModalClose = () => {
    updateAvinashState({ ...iAvinashState, ManageUsersModal: false });
  };

  const handleCreateUserModalShow = () => {
    updateAvinashState({
      ...iAvinashState,
      CreateUserModal: true,
      fullName: "",
      avinashEmail: "",
      ManageUsersModal: false,
    });
  };

  const handleCreateUsers = () => {
    if (!fullName) {
      toast.error("Name can't empty", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!avinashEmail) {
      toast.error("e-mail can't empty", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const dataa = {
        fullName,
        avinashEmail,
      };
      dispatch(
        addClientUser(clientViewConfigData?.ViewSetting?.id, dataa)
      ).then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateAvinashState({
            ...iAvinashState,
            CreateUserModal: false,
            fullName: "",
            avinashEmail: "",
            ManageUsersModal: true,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateAvinashState({
            ...iAvinashState,
            CreateUserModal: true,
          });
        }
      });
    }
  };
  const handleCreateUserModalClose = () => {
    updateAvinashState({
      ...iAvinashState,
      CreateUserModal: false,
      ManageUsersModal: true,
    });
  };

  const handleUpdateUserModalShow = () => {
    updateAvinashState({
      ...iAvinashState,
      UpdateUserModal: true,
      fullName: "",
      avinashEmail: "",
      ManageUsersModal: false,
    });
  };
  const handleUpdateUserModalClose = () => {
    updateAvinashState({
      ...iAvinashState,
      UpdateUserModal: false,
      ManageUsersModal: true,
    });
  };

  const handleUserDelete = (id) => {
    dispatch(removeClientUser(clientViewConfigData?.ViewSetting?.id, id)).then(
      (res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  const handleUpdateUsers = () => {
    if (!fullName) {
      toast.error("Name can't empty", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!avinashEmail) {
      toast.error("e-mail can't empty", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const dataa = {
        fullName,
        email: avinashEmail,
      };
      dispatch(
        updateClientUser(clientViewConfigData?.ViewSetting?.id, dataa)
      ).then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateAvinashState({
            ...iAvinashState,
            UpdateUserModal: false,
            fullName: "",
            avinashEmail: "",
            ManageUsersModal: true,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateAvinashState({
            ...iAvinashState,
            UpdateUserModal: true,
          });
        }
      });
    }
  };

  const handleEmailModalShow = (avinashEmail) => {
    updateAvinashState({
      ...iAvinashState,
      EmailModal: true,
      ManageUsersModal: false,
      avinashEmail: emails,
    });
  };
  const handleEmailModalClose = () => {
    updateAvinashState({
      ...iAvinashState,
      EmailModal: false,
      ManageUsersModal: true,
    });
  };

  const handleUserResetPasswordEmailSend = (e) => {
    e.preventDefault();
    if (!emails) {
      toast.error("Please enter email", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const dataa = { email: emails };
      dispatch(clientUserPasswordEmailSend(dataa)).then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            EmailModal: false,
            ManageUsersModal: true,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateAvinashState({
            ...iAvinashState,
            EmailModal: true,
          });
        }
      });
    }
  };

  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const { contactList } = useSelector((state) => state.profileReducer);

  const [phonecode, setPhoneCode] = useState("");
  const [iState, updateState] = useState(init);
  const [widthState, setWidth] = useState(false);
  const [helpState, setHelpState] = useState(false);
  const [UserProfileModal, setUserProfile] = useState(false);

  const [ContactListModal, setContactList] = useState(false);

  const [ContactDetailsModal, SetContactDetails] = useState(false);

  const [AddContactModal, SetAddContact] = useState(false);
  const [NavShow, SetNavShow] = useState(false);

  const [NotificationState, setNotificationState] = useState(false);
  const {
    email,
    emailModal,
    emailError,
    projectDetailsModal,
    projectDetailsData,
    ProjectNameShow,
    companyId,
    clientName,
    phoneNumber,
    availability,
    contactPrefrence,
    languagePrefrence,
    issueDetailsType,
    severity,
    module,
    description,
    problemStartDate,
    time,
    timeType,
    timeZone,
    errorMessage,
    applicationLink,
    helpAndSupportError,
    addSupportModal,
    supportingDocFiles,
    falseImage,
    supportListingModal,
    supportDetailsModal,
  } = iState;

  const {
    countryData,
    cityData,
    stateData,
    timeZoneData,
    headerNotificationListData,
    iBuildAdminUserListData,
  } = useSelector((state) => state.authReducer);

  const clientViewLogin = JSON.parse(localStorage.getItem("clientViewLogin"));

  const {
    helpListData,
    helpHistoryListData,
    loader,
    helpDetailsData,
    headerHelpListData,
  } = useSelector((state) => state.systemAdministrationReducer);

  const handleContactListModalClose = () => setContactList(false);
  const handleContactListModalShow = () => {
    setContactList(true);
  };

  const { discussionList } = useSelector((state) => state.constructionReducer);

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };
  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    updateState({ ...iState, [name]: result });
  };
  const handlePhoneInputChange = (value, country) => {
    setPhoneCode(`+${country.dialCode}`);
  };
  const handleInputFile = (e) => {
    const { name, value } = e.target;
    const file = e.target.files[0];
    console.log("======>", file);
    if (file) {
      updateState({
        ...iState,
        [name]: file,
      });
    }
    e.target.files = null;
  };
  let path = window.location.href.split("/");
  let pathLength = parseInt(path.length) - 1;
  let pathMatch = path[pathLength];
  // const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const { projectListData, taxProfileListData, landDetailsData } = useSelector(
    (state) => state.jobReducer
  );
 
  useEffect(() => {
    dispatch(getAllIbuildAdminUserListAction())
    if (iBuildLocalData?.user?.CompanyId != null) {
      dispatch(getAllProjectListAction());
      dispatch(headerNotifcaitionList());
    }
  }, [dispatch, iBuildLocalData?.user?.CompanyId]);
  useEffect(() => {
    dispatch(getHeaderHelpListAction(pathMatch));
  }, [pathMatch]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const sendLinkToEmail = (e) => {
    e.preventDefault();
    if (!email) {
      updateState({ ...iState, emailError: "Email can't be empty" });
    } else if (email) {
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        updateState({ ...iState, emailError: "Please enter a valid email" });
      } else {
        dispatch(updateChangePasswordLinkAction({ email })).then((res) => {});
      }
    }
  };

  const showSupportModal = () => {
    updateState({
      ...iState,
      addSupportModal: true,
    });
  };

  const hideSupportModal = () => {
    updateState({
      ...iState,
      addSupportModal: false,
    });
  };

  const showSupportListingModal = () => {
    dispatch(helpListAction());
    updateState({
      ...iState,
      supportListingModal: true,
    });
  };

  const hideSupportListingModal = () => {
    updateState({
      ...iState,
      supportListingModal: false,
    });
  };

  const [discussionState, updateDiscussionState] = useState({
    jobId: "",
    message: "",
    discussionError: "",
  });
  const [replyState, updateReplyState] = useState({
    messageId: "",
    jobId: "",
    message: "",
    replyError: "",
  });
  const [replyField, updateReplyField] = useState({ flag: false, index: "" });

  let handleValidationDiscussion = () => {
    let discussionError = "";
    let formIsValid = true;

    if (discussionState.message == "") {
      discussionError = "* Please enter comment";
      formIsValid = false;
    }

    updateDiscussionState({
      ...discussionState,
      discussionError: discussionError,
    });
    return formIsValid;
  };

  const hideSupportDetailsModal = (d) => {
    updateState({
      ...iState,
      supportDetailsModal: false,
    });
  };

  const showSupportDetailsModal = (id) => {
    dispatch(helpDetailsAction(id));
    updateState({
      ...iState,
      supportDetailsModal: true,
      modalId: id,
    });
  };

  const handleAddDiscussion = () => {
    let formIsValid = handleValidationDiscussion();
    if (formIsValid) {
      dispatch(
        AddDiscussionAction({
          message: discussionState.message,
          helpId: helpDetailsData[0]?.id,
          type: "Help",
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(
              discussionListAction({
                helpId: helpDetailsData[0]?.id,
                type: "Help",
              })
            );

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleAddReply = () => {
    let formIsValid = true;
    if (formIsValid) {
      dispatch(
        AddReplyAction({
          message: replyState.message,
          helpId: helpDetailsData[0]?.id,
          type: "Help",
          messageId: replyState.messageId,
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(
              discussionListAction({
                helpId: helpDetailsData[0]?.id,
                type: "Help",
              })
            );
            updateReplyState({
              messageId: "",
              jobId: "",
              message: "",
              replyError: "",
            });

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const addSupportFunc = (e) => {
    e.preventDefault();
    let data = {
      companyId: iBuildLocalData?.user?.id,
      companyName: iBuildLocalData?.user?.name,
      clientName,
      phoneNumber,
      phonecode,
      availability,
      contactPrefrence,
      languagePrefrence,
      issueDetailsType,
      severity,
      module,
      description,
      problemStartDate,
      time,
      timeType,
      timeZone,
      errorMessage,
      applicationLink,
      supportingDocFiles,
      // helpAndSupportError
    };
    console.log("addSupport", data);
    dispatch(addHelpAndSupportAction(data))
      .then((res) => {
        console.log(res);
        if (res.status === 400) {
          errorToast(res.data.message);
        } else {
          sucessToast("Support added successfully");
          hideSupportModal();
          dispatch(helpListAction());
        }
      })
      .catch((err) => {
        console.log("errrr", err);
        errorToast(err.response.data.message);
      });
  };

  const navigate = useNavigate();
  const signOutFunc = () => {
    window.location.reload();
    localStorage.clear();
    navigate("/");
  };
  return (
    <div>
      <header>
        <div className="Logo">
          <img src="images/Logo.png" />
        </div>
        <div className="Navigation">
          <div className="Search">
            <span>
              <img src="images/search.png" />
            </span>
            <input type="text" placeholder="Search" />
          </div>
          <div className="NavigationMenu">
            <ul>
              <div id="myTask" className="sidenav">
                <a
                  href="javascript:void(0)"
                  className="closebtn"
                  onclick="closeTask()"
                >
                  ×
                </a>
                <div className="SideNavTopBox">
                  <h1>My Task</h1>
                </div>
                <div className="SideNavBottom">
                  <div className="SideTaskBoxTitle">
                    <h6>Today</h6>
                  </div>
                  <div className="SideTaskBox">
                    <h6>Task 1</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                    <div className="TaskButtonArea">
                      <button className="View">View</button>
                      <button className="Start">Start</button>
                    </div>
                  </div>
                  <div className="SideTaskBox">
                    <h6>Task 2</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                    <div className="TaskButtonArea">
                      <button className="View">View</button>
                      <button className="Start">Start</button>
                    </div>
                  </div>
                  <div className="SideTaskBox">
                    <h6>Task 3</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                    <div className="TaskButtonArea">
                      <button className="View">View</button>
                      <button className="Completed">Completed</button>
                    </div>
                  </div>
                  <div className="SideTaskBox">
                    <h6>Task 4</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                    <div className="TaskButtonArea">
                      <button className="View">View</button>
                      <button className="Ongoing">Ongoing</button>
                    </div>
                  </div>
                </div>
              </div>
              <li onclick="openNotification()">
                <a href="javascript:void(0);">
                  <img src="images/Icon-2.png" />
                </a>
              </li>
              <div id="myNotification" className="sidenav">
                <a
                  href="javascript:void(0)"
                  className="closebtn"
                  onclick="closeNotification()"
                >
                  ×
                </a>
                <div className="SideNavTopBox">
                  <h1>Notification</h1>
                </div>
                <div className="SideNavBottom">
                  <div className="SideTaskBoxTitle">
                    <h6>Today</h6>
                  </div>
                  <div className="SideNotificationBox">
                    <h6>Lorem Ipsum</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                  </div>
                  <div className="text-right">
                    <button className="DeleteBtn">Delete</button>
                  </div>
                  <div className="SideNotificationBox">
                    <h6>Lorem Ipsum</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                  </div>
                  <div className="text-right">
                    <button className="DeleteBtn">Delete</button>
                  </div>
                  <div className="SideNotificationBox">
                    <h6>Lorem Ipsum</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                  </div>
                  <div className="text-right">
                    <button className="DeleteBtn">Delete</button>
                  </div>
                  <div className="SideTaskBoxTitle">
                    <h6>Yesterday</h6>
                  </div>
                  <div className="SideNotificationBox">
                    <h6>Lorem Ipsum</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                  </div>
                  <div className="text-right">
                    <button className="DeleteBtn">Delete</button>
                  </div>
                  <div className="SideNotificationBox">
                    <h6>Lorem Ipsum</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                  </div>
                  <div className="text-right">
                    <button className="DeleteBtn">Delete</button>
                  </div>
                </div>
              </div>
              <li onClick={() => setHelpState(!helpState)}>
                <a href="javascript:void(0);">
                  <img src="images/Icon-3.png" />
                </a>
              </li>
              <div style={{ width: helpState ? 300 : 0 }} className="sidenav">
                <a
                  href="javascript:void(0)"
                  className="closebtn"
                  onClick={() => setHelpState(false)}
                >
                  ×
                </a>
                <div className="SideNavTopBox flex-column">
                  <h1>Help</h1>
                  <a
                    href="javascript:void(0);"
                    onClick={showSupportListingModal}
                  >
                    Support Requests <span className="Count">3</span>
                  </a>
                  <a
                    href="javascript:void(0);"
                    onClick={showSupportModal}
                    className="CreateNewSupportRequest"
                  >
                    <img src="images/icon-90.png" />
                    &nbsp;&nbsp;Create New Support Request
                  </a>
                </div>
                <div className="SideNavBottom">
                  {checkArray(headerHelpListData?.notData) ? (
                    headerHelpListData?.notData?.map((ele, i) => {
                      console.log("eleleleleellee", ele);
                      return (
                        <>
                          <div className="SideNavSettingBox">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: ele?.helpMessage,
                              }}
                              className="editor"
                            ></div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <NoDataFound />
                    </>
                  )}
                </div>
              </div>
              <li onClick={() => setWidth(!widthState)}>
                <a href="javascript:void(0);">
                  <img src="images/Icon-4.png" />
                </a>
              </li>
              <div
                id="mySettings"
                className="sidenav"
                style={{ width: widthState ? 300 : 0 }}
              >
                <a className="closebtn" onClick={() => setWidth(false)}>
                  ×
                </a>
                <div className="SideNavTopBox">
                  <h1>Settings</h1>
                </div>
                <div className="SideNavBottom">
                  <div className="SettingThemeArea">
                    <div className="form-group">
                      <label className="Dark-mode d-block">Password</label>
                      <label href="#" className="text-red">
                        Change Password
                      </label>
                    </div>
                    <div className="SideTaskBoxTitle">
                      <h6>Theme</h6>
                    </div>
                    <div className="SideNavHelpBox">
                      <ul>
                        <li>
                          <img src="images/theme-1.png" />
                        </li>
                        <li>
                          <img src="images/theme-2.png" />
                        </li>
                        <li>
                          <img src="images/theme-3.png" />
                        </li>
                        <li>
                          <img src="images/theme-4.png" />
                        </li>
                        <li>
                          <img src="images/theme-5.png" />
                        </li>
                        <li>
                          <img src="images/theme-6.png" />
                        </li>
                        <li>
                          <img src="images/theme-7.png" />
                        </li>
                        <li>
                          <img src="images/theme-8.png" />
                        </li>
                      </ul>
                    </div>
                    <div className="d-flex justify-content-between mt-4">
                      <div className="form-group">
                        <label className="Radio">
                          English
                          <input type="radio" name="Cuisine" />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="Radio">
                          French
                          <input type="radio" name="Cuisine" />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                    <div className="form-group mt-3 d-flex align-items-center justify-content-between">
                      <label className="Dark-mode mb-0">Dark Mode</label>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider" />
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="Dark-mode d-block">Password</label>
                      <label href="#" className="text-red">
                        Change Password
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="Dark-mode d-block">
                        Contact preferences
                      </label>
                      <label href="#" className="text-red">
                        Update contact preferences
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <h5>User Management</h5>
                    <a onClick={() => handleManageUsersModalShow()}>
                      User Management
                    </a>
                  </div>
                  <div className="SettingsEditArea">
                    <div className="SettingsEdit">
                      <h6>Theme</h6>
                      <div className="settingsEditBox">
                        <h6>Add</h6>
                        <p>Remove</p>
                      </div>
                    </div>
                    <div className="SettingsEdit">
                      <h6>Language</h6>
                      <div className="settingsEditBox">
                        <h6>Add</h6>
                        <p>Remove</p>
                      </div>
                    </div>
                    <div className="SettingsEdit">
                      <h6>Dark Mode</h6>
                      <div className="settingsEditBox">
                        <h6>Add</h6>
                        <p>Remove</p>
                      </div>
                    </div>
                    <div className="SettingsEdit">
                      <h6>Password</h6>
                      <div className="settingsEditBox">
                        <h6>Add</h6>
                        <p>Remove</p>
                      </div>
                    </div>
                    <div className="text-center">
                      <button className="Save SaveBtn">Save</button>
                    </div>
                  </div>
                </div>
              </div>
              <li onclick="openNav()">
                <a
                  class="dropdown-toggle"
                  id="navbarDropdown"
                  onClick={() => SetNavShow(true)}
                  role="button"
                >
                  <span> {iBuildLocalData?.user?.firstName?.charAt(0) +
                  iBuildLocalData?.user?.firstName?.charAt(1)}</span>
                </a>
              </li>
              <div
                id="mySidenav"
                class="sidenav"
                style={{ width: NavShow ? "300px" : "0px" }}
              >
                <a onClick={() => SetNavShow(false)} className="closebtn">
                  ×
                </a>
                <div class="sidenavTop">
                  <span> {iBuildLocalData?.user?.firstName.charAt(0) +
                  iBuildLocalData?.user?.firstName?.charAt(1)}</span>
                  <h6>
                    {clientViewLogin?.user?.name}{" "}
                    {/* {/ {clientViewLogin?.user?.lastName} /} */}
                  </h6>
                  <h6>
                    {clientViewLogin?.user?.email}{" "}
                    {clientViewLogin?.user?.phoneNumber}
                  </h6>
                </div>
                <div class="d-flex flex-column justify-content-between">
                  <ul class="">
                    <li>
                      <a onClick={() => setUserProfile(true)}>
                        <img src="images/profile.png" />
                        Profile
                      </a>
                    </li>
                    <li>
                      <a onClick={() => setContactList(true)}>
                        <img src="images/contacts.png" />
                        Contacts
                      </a>
                    </li>
                  </ul>
                  <ul class="bottomUl">
                    <li>
                      <a onClick={signOutFunc}>
                        <img src="images/signout.png" />
                        Sign Out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </ul>
          </div>
        </div>
     
      <Modal
        show={ManageUsersModal}
        onHide={handleManageUsersModalClose}
        className="ModalBox modal fade MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleManageUsersModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Manage User</h4>
            </div>
            <div className="ModalBigheading">
              <h3>
                <span>Manage Users:</span>Chinook Gate
              </h3>
              <h3>
                <span>Unit:</span>32
              </h3>
            </div>
            <div className="ModalBigheading2">
              <h3>Registered Users (Max 3)</h3>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Primary</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>John Joe</td>
                    <td>joe@email.com</td>
                    <td>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </td>
                    <td>
                      <div className="TableThreeDots">
                        <ul className="">
                          <li className="dropdown">
                            <a
                              className=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol
                              className="dropdown-menu dropdown-menuwidth"
                              style={{}}
                            >
                              <li>
                                <a onClick={() => handleUpdateUserModalShow()}>
                                  <img src="images/Action-1.png" />
                                  Edit User Details
                                </a>
                              </li>
                              <li>
                                <a onClick={() => handleUserDelete()}>
                                  <img src="images/dropdown-delete.png" />
                                  Remove User
                                </a>
                              </li>
                              <li>
                                <a onClick={() => handleEmailModalShow()}>
                                  <img src="images/reset_password.png" />
                                  Reset Password
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>John Joe</td>
                    <td>joe@email.com</td>
                    <td>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </td>
                    <td>
                      <div className="TableThreeDots">
                        <ul className="">
                          <li className="dropdown">
                            <a
                              className=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol
                              className="dropdown-menu dropdown-menuwidth"
                              style={{}}
                            >
                              <li>
                                <a onClick={() => handleUpdateUserModalShow()}>
                                  <img src="images/Action-1.png" />
                                  Edit User Details
                                </a>
                              </li>
                              <li>
                                <a onClick={() => handleUserDelete()}>
                                  <img src="images/dropdown-delete.png" />
                                  Remove User
                                </a>
                              </li>
                              <li>
                                <a onClick={() => handleEmailModalShow()}>
                                  <img src="images/reset_password.png" />
                                  Reset Password
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>John Joe</td>
                    <td>joe@email.com</td>
                    <td>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </td>
                    <td>
                      <div className="TableThreeDots">
                        <ul className="">
                          <li className="dropdown">
                            <a
                              className=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol
                              className="dropdown-menu dropdown-menuwidth"
                              style={{}}
                            >
                              <li>
                                <a onClick={() => handleUpdateUserModalShow()}>
                                  <img src="images/Action-1.png" />
                                  Edit User Details
                                </a>
                              </li>
                              <li>
                                <a onClick={() => handleUserDelete()}>
                                  <img src="images/dropdown-delete.png" />
                                  Remove User
                                </a>
                              </li>
                              <li>
                                <a onClick={() => handleEmailModalShow()}>
                                  <img src="images/reset_password.png" />
                                  Reset Password
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>John Joe</td>
                    <td>joe@email.com</td>
                    <td>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </td>
                    <td>
                      <div className="TableThreeDots">
                        <ul className="">
                          <li className="dropdown">
                            <a
                              className=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol
                              className="dropdown-menu dropdown-menuwidth"
                              style={{}}
                            >
                              <li>
                                <a onClick={() => handleUpdateUserModalShow()}>
                                  <img src="images/Action-1.png" />
                                  Edit User Details
                                </a>
                              </li>
                              <li>
                                <a onClick={() => handleUserDelete()}>
                                  <img src="images/dropdown-delete.png" />
                                  Remove User
                                </a>
                              </li>
                              <li>
                                <a onClick={() => handleEmailModalShow()}>
                                  <img src="images/reset_password.png" />
                                  Reset Password
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="Button mt-5" onClick={handleCreateUserModalShow}>
              <button className="Create">Create User</button>
            </div>
            {/* <div className="Button mt-5 d-flex justify-content-between">
    <button className="Create">Save</button>
    <button className="Outline">Cancel</button>
  </div> */}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={CreateUserModal}
        onHide={handleCreateUserModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleCreateUserModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create New User</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>
                  Name<span className="required">*</span>
                </h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert name"
                  name="fullName"
                  value={fullName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <h6>
                  Email<span className="required">*</span>
                </h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert Email"
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={handleCreateUsers}>
                  Create User
                </button>
                <button
                  className="Outline"
                  onClick={handleCreateUserModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={UpdateUserModal}
        onHide={handleUpdateUserModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleUpdateUserModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Edit User</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>
                  Name<span className="required">*</span>
                </h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert name"
                  name="fullName"
                  value={fullName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <h6>
                  Email<span className="required">*</span>
                </h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert Email"
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={handleUpdateUsers}>
                  Update User
                </button>
                <button
                  className="Outline"
                  onClick={handleUpdateUserModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={EmailModal}
        onHide={handleEmailModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleEmailModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="LoginBox Modal">
              <a href="javascript:void(0);" className="nav-link">
                IBuild Admin
              </a>
              <div className="LoginContentBox">
                <h3>Recover your account</h3>
                <p className="mb-4">
                  Enter email associated with your account and follow the
                  instructions
                </p>
                <form>
                  <div className="form-group">
                    <label>Enter Email</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter text"
                      // name="emails"
                      value={emails}
                      disabled
                      // onChange={handleInputChange}
                    />
                  </div>

                  <a
                    className="Button FolderPermissionId"
                    onClick={handleUserResetPasswordEmailSend}
                  >
                    Send Reset Email
                  </a>
                  <a
                    className="ButtonOutline mt-3"
                    onClick={handleEmailModalClose}
                  >
                    Cancel
                  </a>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={addSupportModal}
        onHide={hideSupportModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSupportModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Support Request</h4>
            </div>
            <div className="FolderForm">
              <div className="ModalBigheading mb-4">
                <h3 className="mb-1">
                  <span>Contact Details</span>
                </h3>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <h6>
                      Company <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="companyId"
                        value={companyId}
                        onChange={handleInput}
                      >
                        <option selected="">Select Company</option>
                        <option value="Company 1">Company 1</option>
                        <option value="Company 2">Company 2</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Client Name <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="clientName"
                        value={clientName}
                        onChange={handleInput}
                      >
                        <option selected="">Select Client</option>
                        <option value="Client 1">Client 1</option>
                        <option value="CLient 2">Client 2</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group">
                    <h6>Country Code</h6>
                    <PhoneInput
                      inputClass="CountryCodeInput"
                      countryCodeEditable={false}
                      enableSearch
                      value={phonecode}
                      onChange={handlePhoneInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <h6>
                      Telephone <span className="required">*</span>
                    </h6>
                    <input
                      type="text"
                      className="form-control"
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={handleInputNumber}
                    />
                  </div>
                </div>
                <div className="col-md-5 ml-auto">
                  <div className="form-group">
                    <h6>
                      Availability <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="availability"
                        value={availability}
                        onChange={handleInput}
                      >
                        <option selected="">Select Availability</option>
                        <option value="Complete">Complete</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Contact Preference <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="contactPrefrence"
                        value={contactPrefrence}
                        onChange={handleInput}
                      >
                        <option selected="">Select Preference</option>
                        <option value="Email">Email </option>
                        <option value="Phone">Phone </option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Email <span className="required">*</span>
                    </h6>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="form-group">
                    <h6>
                      Language Preference <span className="required">*</span>
                    </h6>
                    <div className="d-flex justify-content-between">
                      <label className="Radio text-black ">
                        {" "}
                        English
                        <input
                          type="radio"
                          name="languagePrefrence"
                          value={"English"}
                          onChange={handleRadioChange}
                        />
                        <span className="checkmark" />
                      </label>
                      <label className="Radio text-black ">
                        {" "}
                        French
                        <input
                          type="radio"
                          name="languagePrefrence"
                          value={"French"}
                          onChange={handleRadioChange}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ModalBigheading mb-4">
                <h3 className="mb-1">
                  <span>Issue Details</span>
                </h3>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <h6>
                      Type <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="issueDetailsType"
                        value={issueDetailsType}
                        onChange={handleInput}
                      >
                        <option selected="">Select Type</option>
                        <option value="Type 1">Type 1</option>
                        <option value="Type 2">Type 2</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Module <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="module"
                        value={module}
                        onChange={handleInput}
                      >
                        <option selected="">Select Module</option>
                        <option value="Module 1">Module 1</option>
                        <option value="Module 2">Module 2</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 ml-auto">
                  <div className="form-group">
                    <h6>
                      Severity <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="severity"
                        value={severity}
                        onChange={handleInput}
                      >
                        <option selected="">Select Severity</option>
                        <option value="Severity 1">Severity 1</option>
                        <option value="Severity 2">Severity 2</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <h6>
                      Description <span className="required">*</span>
                    </h6>
                    <textarea
                      className="form-control"
                      placeholder="Insert Description"
                      rows={3}
                      name="description"
                      value={description}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="form-group col-12 mb-0">
                  <h6>
                    Problem Start Date <span className="required">*</span>
                  </h6>
                </div>
                <div className="form-group col-md-4">
                  <input
                    type="date"
                    className="form-control"
                    name="problemStartDate"
                    value={problemStartDate}
                    onChange={handleInput}
                  />
                </div>
                <div className="form-group col-md-4">
                  <input
                    type="time"
                    className="form-control"
                    placeholder="hh:mm"
                    name="time"
                    value={time}
                    onChange={handleInput}
                  />
                </div>
                <div className="form-group col-md-4">
                  <div className="CommonSelectBox CommonSelectBoxWidth">
                    <select
                      name="timeType"
                      value={timeType}
                      onChange={handleInput}
                    >
                      <option selected="">Select</option>
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </div>
                <div className="form-group col-md-5">
                  <div className="CommonSelectBox CommonSelectBoxWidth mb-3">
                    <select
                      name="timeZone"
                      value={timeZone}
                      onChange={handleInput}
                    >
                      <option>Select Timezone</option>
                      {checkArray(timeZoneData?.data) ? (
                        <>
                          {timeZoneData?.data?.map((ele, i) => {
                            return (
                              <option value={ele?.zoneName}>
                                {ele?.zoneName}
                              </option>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </select>
                  </div>
                  <label className="CheckBox">
                    {" "}
                    Not sure, use current time
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="form-group col-md-12">
                  <h6>Error Message</h6>
                  <textarea
                    className="form-control"
                    placeholder="Insert Error Message if applicable, or more details."
                    rows={3}
                    name="errorMessage"
                    value={errorMessage}
                    onChange={handleInput}
                  />
                </div>
                <div className="form-group col-md-6">
                  <h6>Url link of the issue page</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert link where the error is occuring"
                    name="applicationLink"
                    value={applicationLink}
                    onChange={handleInput}
                  />
                </div>
                <div className="ModalBigheading col-md-12">
                  <h3>
                    <span>Supporting Documents</span>
                  </h3>
                </div>
                <div className="form-group col-md-6">
                  <h6>Select Files</h6>
                  <div className="Upload">
                    <span>-Drag and Drop Files or click to select</span>
                    <input
                      type="file"
                      name="supportingDocFiles"
                      onChange={(e) => handleInputFile(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="row"></div>
              {/* <div className="Button mb-3">
        <button className="Create">Add</button>
      </div> */}
              {/* <div className="form-group">
        <h6>Files:</h6>
        <div className="FilesAdded">
          <label>error1_.jpeg</label>
          <span>
            <img src="images/fileview.png" />
          </span>
          <span className="cross">
            <img src="images/Cross.png" />
          </span>
        </div>
        <div className="FilesAdded">
          <label>error1_.jpeg</label>
          <span>
            <img src="images/fileview.png" />
          </span>
          <span className="cross">
            <img src="images/Cross.png" />
          </span>
        </div>
      </div> */}
              {/* <div className="row">
        <div className="form-group col-md-6">
          <h6>
            Assign To <span className="required">*</span>
          </h6>
          <div
            className="CommonSelectBox CommonSelectBoxWidth"
            style={{ width: "100%!important", margin: 0 }}
          >
            <select >
              <option selected="">Select Ibuild Admin</option>
              <option value={"Ibuild Admin"}> Ibuild Admin 1</option>
            </select>
          </div>
        </div>
      </div> */}
              <div className="Button d-flex justify-content-between">
                <button
                  onClick={addSupportFunc}
                  className="Create ClosePermissionId"
                >
                  Create Support Request
                </button>
                <button className="Outline ClosePermissionId">Cancel</button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={supportListingModal}
        onHide={hideSupportListingModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSupportListingModal}
          >
            ×
          </a>

          <a href="javascript:void(0);" onClick={hideSupportListingModal}>
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>View Support Request</h4>
            </div>
            <div className="Tableheader">
              <div className="d-flex align-items-center">
                <h6>Support Requests</h6>
              </div>
              <div className="d-flex">
                <div className="TodosButtons mt-0 d-flex">
                  <a href="javascript:void(0)" onClick={showSupportModal}>
                    <img src="images/icon-90.png" />
                    Create New Support Request
                  </a>
                </div>
                <div className="NewActions">
                  <a href="javascript:void(0);" title="pdf">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="javascript:void(0);" title="download">
                    <img src="images/download.png" />
                  </a>
                  <a href="javascript:void(0);" title="settings">
                    <img src="images/setting.png" />
                  </a>
                  <a href="javascript:void(0);" title="filter">
                    <img src="images/icon-64.png" />
                  </a>
                </div>
              </div>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Client</th>
                    <th>Company</th>
                    <th>Type</th>
                    <th>Module</th>
                    <th>Description</th>
                    <th>Problem Start Date</th>
                    <th>Severity</th>
                    <th>Error Message</th>
                    <th>Url link of the issue page</th>
                    {/* {/ <th>Supporting Documents</th> /} */}
                    <th>Status</th>
                    <th>Creation Date</th>
                    <th>Last Update</th>
                    <th>Solution</th>
                    <th>Root Cause</th>
                    <th>Bug ID</th>
                    <th>Messages</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <>
                      <td>
                        {" "}
                        <Loader />
                      </td>
                    </>
                  ) : (
                    <>
                      {checkArray(helpListData?.notData) ? (
                        helpListData?.notData?.map((ele, ind) => {
                          return (
                            <>
                              <tr className="PendingConfirmation">
                                <td>{ele?.id}</td>
                                <td>{ele?.clientName}</td>
                                <td>{ele?.companyId}</td>
                                <td>{ele?.issueDetailsType}</td>
                                <td>{ele?.module}</td>
                                <td>{ele?.description}</td>
                                <td>
                                  {ele?.problemStartDate}, {ele?.time}
                                </td>
                                <td>{ele?.severity}</td>
                                <td>{ele?.errorMessage}</td>
                                <td>{ele?.applicationLink}</td>
                                {/* <td>
                  <div className="Actions">
                    <a
                      href="javascript:void(0);"
                      onClick={() => showAddSupportingDocsModal()}
                    >
                      <img src="images/icon-63.png" />
                    </a>
                    <a
                      href="javascript:void(0)"
                      onClick={() => showSupportingListModal()}
                    >
                      <span className="Count">3</span>
                    </a>
                  </div>
                </td> */}
                                <td>{ele?.requestStatus}</td>

                                <td>
                                  <div className="text-black">
                                    {ele?.createdAt?.split("T")[0]}
                                  </div>
                                </td>
                                <td>
                                  <div className="text-black">
                                    {ele?.updatedAt?.split("T")[0]}
                                  </div>
                                </td>
                                <td />
                                <td />
                                <td />
                                <td>
                                  <div className="Actions">
                                    <a href="javascript:void(0);">
                                      <img src="images/chat_icon.png" />
                                    </a>
                                    <a href="javascript:void(0);">
                                      <span className="Count">3</span>
                                    </a>
                                  </div>
                                </td>
                                <td>
                                  <div className="TableThreeDots text-center">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol
                                          className="dropdown-menu dropdown-menuwidth2"
                                          style={{}}
                                        >
                                          <li>
                                            <a
                                              onClick={() =>
                                                showSupportDetailsModal(ele?.id)
                                              }
                                            >
                                              <img src="images/view-po.png" />
                                              View Support Request
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <td>
                          {" "}
                          <NoDataFound />
                        </td>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={supportDetailsModal}
        onHide={hideSupportDetailsModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSupportDetailsModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Support Request Details</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="row">
                  <div className="form-group col-md-3">
                    <h6>ID</h6>
                    <p className="ml-0">{helpDetailsData[0]?.id}</p>
                  </div>
                  <div className="form-group col-md-3">
                    <h6>Creation Date</h6>
                    <p className="ml-0">
                      {helpDetailsData[0]?.createdAt?.split("T")[0]}
                    </p>
                  </div>
                  <div className="form-group col-md-3">
                    <h6>Last Update</h6>
                    <p className="ml-0">
                      {helpDetailsData[0]?.updatedAt?.split("T")[0]}
                    </p>
                  </div>
                  <div className="form-group col-md-3">
                    <h6>Status</h6>
                    <p className="ml-0">{helpDetailsData[0]?.status}</p>
                  </div>
                </div>
                <div className="ModalBigheading mb-4">
                  <h3 className="mb-1">
                    <span>Contact Details</span>
                  </h3>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <h6>Company</h6>
                      <p className="ml-0">{helpDetailsData[0]?.id}</p>
                    </div>
                    <div className="form-group">
                      <h6>Client Name</h6>
                      <p className="ml-0">{helpDetailsData[0]?.clientName}</p>
                    </div>
                    <div className="form-group">
                      <h6>Telephone</h6>
                      <p className="ml-0">{helpDetailsData[0]?.phoneNumber}</p>
                    </div>
                  </div>
                  <div className="col-md-5 ml-auto">
                    <div className="form-group">
                      <h6>Availability</h6>
                      <p className="ml-0">{helpDetailsData[0]?.availability}</p>
                    </div>
                    <div className="form-group">
                      <h6>Contact Preference</h6>
                      <p className="ml-0">
                        {helpDetailsData[0]?.contactPrefrence}
                      </p>
                    </div>
                    <div className="form-group">
                      <h6>Email</h6>
                      <p className="ml-0">{helpDetailsData[0]?.email}</p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <h6>Language Preference </h6>
                    <p className="ml-0">
                      {helpDetailsData[0]?.languagePrefrence}
                    </p>
                  </div>
                </div>
                <div className="ModalBigheading mt-4 mb-4">
                  <h3 className="mb-1">
                    <span>Issue Details</span>
                  </h3>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>Type</h6>
                      <p className="ml-0">
                        {helpDetailsData[0]?.languagePrefrence}
                      </p>
                    </div>
                    <div className="form-group">
                      <h6>Module</h6>
                      <p className="ml-0">{helpDetailsData[0]?.module}</p>
                    </div>
                    <div className="form-group">
                      <h6>Description</h6>
                      <p className="ml-0">{helpDetailsData[0]?.description}</p>
                    </div>
                    <div className="form-group">
                      <h6>Problem Start Date</h6>
                      <div className="d-flex mb-4">
                        <p className="ml-0">
                          {helpDetailsData[0]?.problemStartDate?.split("T")[0]}
                        </p>
                        {/* {/ <p>09:00 AM</p> /} */}
                      </div>
                      {/* {/ <p className="ml-0">{helpDetailsData[0]?.problemStartDate?.split("T")[0]}</p> /} */}
                    </div>
                  </div>
                  <div className="col-md-5 ml-auto">
                    <div className="form-group">
                      <h6>Severity</h6>
                      <p className="ml-0">{helpDetailsData[0]?.severity}</p>
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    <h6>Error Message</h6>
                    <p className="ml-0">{helpDetailsData?.errorMessage}</p>
                  </div>
                  <div className="form-group col-md-12">
                    <h6>Url link of the issue page</h6>
                    <p className="ml-0"> {helpDetailsData?.applicationLink}</p>
                  </div>
                  {/* <div className="col-md-12">
          <div className="ModalBigheading">
            <h3>
              <span>Supporting Documents</span>
            </h3>
          </div>
          <div className="form-group">
            <h6>Files:</h6>
            <div className="FilesAdded">
              <label>error1_.jpeg</label>
              <span>
                <img src="images/view_jobs_details.png" />
              </span>
            </div>
            <div className="FilesAdded">
              <label>error1_.jpeg</label>
              <span>
                <img src="images/view_jobs_details.png" />
              </span>
            </div>
          </div>
          <div className="ModalBigheading">
            <h3>
              <span>Support Details</span>
            </h3>
          </div>
        </div> */}
                </div>
                <div className="row">
                  <div className="form-group col-6">
                    <h6>Assign To </h6>
                    <h6 className="fw-400 fw-14">Sysadmin 1</h6>
                  </div>
                  <div className="form-group col-12">
                    <h6>Solution</h6>
                  </div>
                  <div className="form-group col-md-6">
                    <h6>Root Cause</h6>
                    <p className="ml-0">Bug</p>
                  </div>
                  <div className="form-group col-md-6">
                    <h6>Bug Id</h6>
                    <p className="ml-0">Bug 36</p>
                  </div>
                </div>
                <div className="Button text-center">
                  <button className="Outline">Close</button>
                </div>
              </form>
            </div>
            <div class="DiscussionArea">
              <div class="DiscussionTitle">
                <h4>Discussion</h4>
                <div class="DiscussionChat">
                  <img
                    src={require("../../assets/images/down.png")}
                    class="DiscussionIcon"
                  />
                  <a href="#">
                    <img src={require("../../assets/images/pdf2.png")} />
                  </a>
                </div>
              </div>
              <div class="DiscussionHideArea" style={{ display: "block" }}>
                <div class="DiscussionBox">
                  <span>FM</span>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      value={discussionState?.message}
                      onChange={(e) =>
                        updateDiscussionState({
                          ...discussionState,
                          message: e.target.value,
                        })
                      }
                      placeholder="Add a Comment ....."
                    />
                    {discussionState.discussionError &&
                      !discussionState.message && (
                        <p style={{ color: "red" }}>
                          {discussionState.discussionError}
                        </p>
                      )}

                    <div class="DiscussionOverlay">
                      <a
                        onClick={() =>
                          updateDiscussionState({
                            ...discussionState,
                            message: "",
                          })
                        }
                        class="CancelChat"
                      >
                        <img
                          src={require("../../assets/images/plus-cancel1.png")}
                        />
                      </a>
                      <a onClick={handleAddDiscussion} class="SendChat">
                        <img
                          src={require("../../assets/images/forwardbutton.png")}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                {discussionList?.map((item, i) => (
                  <>
                    <div class="DiscussionBox">
                      <span>CO</span>
                      <div class="DiscussionRight">
                        <h6>{item?.message}</h6>
                        <small>{moment(item.updatedAt).format("lll")}</small>
                      </div>
                    </div>
                    <div class="ReplyArea">
                      <a
                        onClick={(e) => {
                          updateReplyField({ flag: true, index: i });
                          updateReplyState({ ...replyState, message: "" });
                        }}
                      >
                        Reply
                      </a>
                      {item?.ReplyDiscussions?.map((ele) => (
                        <div class="DiscussionBox">
                          <span>RE</span>
                          <div class="DiscussionRight">
                            <h6>{ele?.message}</h6>
                            <small>{moment(ele.updatedAt).format("lll")}</small>
                          </div>
                        </div>
                      ))}

                      {replyField.index == i && (
                        <div class="DiscussionBox w-100">
                          <span>FM{item?.id}</span>
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              value={replyState?.message}
                              onChange={(e) =>
                                updateReplyState({
                                  ...replyState,
                                  message: e.target.value,
                                  messageId: item?.id,
                                  jobId: item?.jobId,
                                })
                              }
                              placeholder="Add reply ....."
                            />
                            {discussionState.discussionError &&
                              !discussionState.message && (
                                <p style={{ color: "red" }}>
                                  {discussionState.discussionError}
                                </p>
                              )}

                            <div class="DiscussionOverlay">
                              <a
                                onClick={() =>
                                  updateReplyState({
                                    ...replyState,
                                    message: "",
                                  })
                                }
                                class="CancelChat"
                              >
                                <img
                                  src={require("../../assets/images/plus-cancel1.png")}
                                />
                              </a>
                              <a onClick={handleAddReply} class="SendChat">
                                <img
                                  src={require("../../assets/images/forwardbutton.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ContactList
        ContactListModal={ContactListModal}
        setContactList={setContactList}
        SetAddContact={SetAddContact}
        SetContactDetails={SetContactDetails}
      />

      {AddContactModal && (
        <AddContact
          AddContactModal={AddContactModal}
          SetAddContact={SetAddContact}
        />
      )}
       </header>
    </div>
  );
};

export default IbuildAdminHeader;
