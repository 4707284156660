import React from 'react'
import { Modal } from 'react-bootstrap'

export default function CommonModal({adjustmentStatementModal,hideAdjustStatementModal}) {
  return (
<Modal
        show={adjustmentStatementModal}
        onHide={hideAdjustStatementModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAdjustStatementModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Statement of Adjustments</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="ModalBigheading">
                  <h3>
                    <span>Status: Pending Accountant Release</span>
                  </h3>
                </div>
                <div className="AgreementViewArea">
                  <div className="AgreementViewTop">
                    <a href="javscript:void(0)">
                      <img src="images/modal-big-logo.png" />
                    </a>
                    <div className="AgreementViewTopRight">
                      <h3>iBuild</h3>
                      <p>312 Weddenburn Rd SE</p>
                      <p>Calgary, AB CA, T2J 1J1</p>
                      <p>
                        4034005933 www.ibuildapplications.com
                        linden@ibuildapplications.com
                      </p>
                      <p>
                        “The bitterness of poor quality remains long after the sweetness
                        of meeting
                        <br />
                        the schedule has been gorgotten”
                      </p>
                    </div>
                  </div>
                  <div className="StatementAdjustmentArea px-4">
                    <h6>Statement of Adjustments</h6>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Client Name</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Address</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>City</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>State/Provice</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Possession Date</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Date Prepared</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Job Number</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Sales Consultant</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Model</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Contract Date</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-12">
                        <h6 className="Title">JOB DETAILS</h6>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Address</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>City</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>State/Provice</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Subdivision</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Lot</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Block</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Plan</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Sale Type</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-12">
                        <h6 className="Title">Sale Revenues</h6>
                        <div className="StatementAdjustmentBottom">
                          <label>Contract Purchase Agreement Price (Pre-Tax)</label>
                          <p className="LightGreen">$500,000.00</p>
                        </div>
                        <h6 className="Title">ADD: Tax</h6>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>ADD: Tax</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="StatementAdjustmentBottom">
                          <label>Applied Tax</label>
                          <p className="LightGrey">5.00%</p>
                          <p className="LightRed">$25,000.00</p>
                        </div>
                        <h6 className="Title">Less: Deposit</h6>
                        <div className="StatementAdjustmentTable">
                          <table>
                            <thead>
                              <tr>
                                <th>Notes</th>
                                <th>Transaction ID#</th>
                                <th>Date</th>
                                <th>Tx</th>
                                <th>Tx Type</th>
                                <th>
                                  Supporting <br /> Documents
                                </th>
                                <th>Status</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Condition Removal Deposit</td>
                                <td>OP-CHI-2B-243-1</td>
                                <td>03/30/20 21</td>
                                <td>CH</td>
                                <td>OP</td>
                                <td>
                                  <div className="Actions">
                                    <a
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddNewSupportingDocsStatement2"
                                    >
                                      <img src="images/icon-63.png" />
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      data-toggle="modal"
                                      data-target="#SupportingDocumentListStatement"
                                    >
                                      <span className="Count">3</span>
                                    </a>
                                  </div>
                                </td>
                                <td>Commited</td>
                                <td>
                                  <div className="BgBlue">$35,000.00</div>
                                </td>
                              </tr>
                              <tr>
                                <td>Construction Drawings</td>
                                <td>OP-CHI-2B-243-2</td>
                                <td>06/30/20 21</td>
                                <td>D</td>
                                <td>DP</td>
                                <td>
                                  <div className="Actions">
                                    <a
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddNewSupportingDocsStatement2"
                                    >
                                      <img src="images/icon-63.png" />
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      data-toggle="modal"
                                      data-target="#SupportingDocumentListStatement"
                                    >
                                      <span className="Count">3</span>
                                    </a>
                                  </div>
                                </td>
                                <td>Commited</td>
                                <td>
                                  <div className="BgBlue">$35,000.00</div>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan={6}>Down Payment Amount Required</td>
                                <td colSpan={2}>
                                  <div className="BgBlue">$0.00</div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={6}>Total Deposit</td>
                                <td colSpan={2}>
                                  <div className="BgBlue">$55,000.00</div>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        <h6 className="Title">Summary</h6>
                        <div className="StatementAdjustmentBottom">
                          <label>Contract Purchase Agreement Price (Pre-Tax)</label>
                          <p className="LightGreen">$500,000.00</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ChangeOrderCreatArea mt-5">
                    <div className="row">
                      <div className="col-lg-12"></div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="InputBox">
                          <div className="form-group">
                            <input type="text" />
                            <label>Date</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 ml-auto">
                        <div className="InputBox">
                          <div className="form-group">
                            <input type="text" />
                            {/* <div class="InsertSignature">
                            </div> */}
                            <label>John Doe</label>
                            <label>Company Accountant</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="InputBox">
                          <div className="form-group">
                            <input type="text" />
                            <label>Date</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 ml-auto">
                        <div className="InputBox">
                          <div className="form-group">
                            <input type="text" />
                            <label>John Doe</label>
                            <label>Company Accountant</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button className="Create" data-dismiss="modal">
                    Print
                  </button>
                  <button
                    className="Create FolderPermissionId"
                    data-toggle="modal"
                    data-target="#StatementOfAdjustmentsSaveSignature"
                    data-dismiss="modal"
                  >
                    Save
                  </button>
                  <button
                    className="Create FolderPermissionId"
                    data-toggle="modal"
                    data-target="#SoaHistory1"
                    data-dismiss="modal"
                  >
                    <img src="images/history_icon.png" />
                    History
                  </button>
                  <button className="Outline" onClick={hideAdjustStatementModal}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>

        </Modal.Body>
      </Modal>
  )
}
























// .LoginArea .LoginBox.MultipleLogin{
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 100%;
// }

// .LoginArea .LoginBox.MultipleLogin h6{
//   margin-bottom: 30px;
// }

// .LoginArea .LoginBox.MultipleLogin ul{
//   display: flex;
//   flex-wrap: wrap;
// }

// .LoginArea .LoginBox.MultipleLogin ul li {
//   /* flex: auto; */
//   width: 50%;
//   padding: 10px 10px;
// }


// .LoginArea .LoginBox.MultipleLogin ul li a {
//   font-size: 14px;
//   padding: 40px 0px;
//   font-weight: 500;
//   color: #000;
//   background-color: #fdf0f0;
//   border: 1px solid rgb(237 29 36 / 40%);
//   border-radius: 10px;
//   display: block;
// }

// .LoginArea .LoginBox.MultipleLogin ul li a.active{}


// .estimationModalBelowProfile{
  
// }

// .SpinnerContainer {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh; /* Adjust the height based on your layout */
// } 

// .TodosButtons .tab-content{ display: inline-block; }