import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getProformaBlockStep2 } from '../../../redux/action/proformaAction';

const BlockQuanty = ({
    unitList,
    handleBlockQuantityChange,
    handleShow
    
}) => {
  const dispatch=useDispatch()
  const[blockQuantitiesData,setBlockQuantitiesData]=useState([])
    useEffect(()=>{
      dispatch(getProformaBlockStep2()).then(res=>{
    
        setBlockQuantitiesData(res?.data?.data)
      })
    },[])
    console.log({blockQuantitiesData})
  
  return (
    <>
   
    <div>
         <div className="ModalMediumheading2 d-flex align-items-center justify-content-between mb-3 pl-4">
                  <h3 className="mb-0">Block Quantities</h3>
                  <h3 className="mb-0">
                    {" "}
                    <img src={require("../../../assets/images/cash-flow.png")} />
                    &nbsp;&nbsp;Customize Base Costs
                  </h3>
                </div>
                <div className="ProformaAccordion">
                  <div className="accordion" id="accordionExample">
                    {blockQuantitiesData?.length > 0 &&
                      blockQuantitiesData?.map((item, ind) => {
                        return (
                          <div className="card">
                            <div className="card-header" id="headingOne">
                              <h2 className="mb-0">
                                <button
                                  className="btn btn-link btn-block text-left collapsed"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseOne"
                                  aria-expanded="false"
                                  aria-controls="collapseOne"
                                >
                                  {item.name}
                                </button>
                              </h2>
                              <a href="javascript:void(0);">
                               <img src={require("../../../assets/images/book-search.png")} />
                              </a>
                            </div>
                            <div
                              id="collapseOne"
                              className="collapse show"
                              aria-labelledby="headingOne"
                              data-parent="#accordionExample"
                              style={{}}
                            >
                              <div className="card-body">
                                <div
                                  className="EnterProjectTable"
                                  style={{ overflowX: "scroll" }}
                                >
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Cost Code #</th>
                                        <th>Description</th>
                                        <th>Unit of measaure</th>
                                        <th>Block A</th>
                                        {/* <th>Block B</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item?.ProformaBaseCosts?.length > 0 &&
                                        item?.ProformaBaseCosts?.map(
                                          (datas, i) => {
                                            return (
                                              <tr>
                                                <td>{i + 1}</td>
                                                <td>
                                                  {datas.CostCode?.number}
                                                </td>
                                                <td>
                                                  <div className="CommonSelectBox width-250">
                                                    <select
                                                    // value={item.foundationType}
                                                    // onChange={(e) => handleBuildingFieldChange(e, index, 'foundationType')}
                                                    >
                                                      <option value="">
                                                        Select Foundation Type
                                                      </option>
                                                      {unitList?.length > 0 &&
                                                        unitList?.map(
                                                          (data, i) => {
                                                            return (
                                                              <option
                                                                key={i}
                                                                value={data.id}
                                                              >
                                                                {data.name}
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                    </select>
                                                    {/* <span>
                                                      <img src="images/down.png" />
                                                    </span> */}
                                                  </div>
                                                </td>
                                                {/* {
                                                        item.data[1].ProformaBaseCosts[0].ProformaBlockQuantities[0].quantity
                                                      } */}
                                                {datas?.ProformaBlockQuantities
                                                  ?.length > 0 &&
                                                  datas?.ProformaBlockQuantities?.map(
                                                    (block, blocId) => {
                                                      return (
                                                        <td key={blocId}>
                                                          <div className="form-group">
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Insert Quality"
                                                              name="quantity"
                                                              value={
                                                                block.quantity
                                                              }
                                                              onChange={(e) =>
                                                                handleBlockQuantityChange(
                                                                  e,
                                                                  ind,
                                                                  i,
                                                                  blocId
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </td>
                                                      );
                                                    }
                                                  )}
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                  {/* <div className="PaginationArea px-3">
                                    <h6>Showing 1 to 10 of 27 entries</h6>
                                    <h6>
                                      <img src="images/leftarrow.png" /> 01{" "}
                                      <img src="images/rightarrow.png" /> Out 10
                                    </h6>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
    </div>
    <div className="Button d-flex  justify-content-between align-items-center px-5">
                <button
                  className="Outline border text-success border-success"
                  onClick={() => handleShow("blockInput")}
                >
                  Cancel
                </button>
                <button
                  className="Create bg-success border border-success"
                  onClick={()=>handleShow("blockSummeryPreview")}
                   >
                  Save Draft
                </button>
               
              </div>
    </>
  )
}

export default BlockQuanty