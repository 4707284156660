import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RoleDropdownAction, UserDropdownAction, getUrlImageAction } from "../../redux/action/dropdownAction";
import { CreateProfileAction, ProfileAction, UpdateProfileAction } from "../../redux/action/profileAction";
import {toast} from "react-toastify"
import Profile  from "../../assets/images/user_avtar.png"
import moment from "moment";

const initialSate={
  
    "name": "",
    "UserId":"",
    "userRole":"",
    "email": "",
    "phoneNumber": "",
    "RoleId": "",
    "allProjectsAssigned": false,
    "assignedProjects": [
        1
    ],
    absenceFrom:"",
    absenceTo:"",
    preview:"",
    profilePicture:"",
    roleType:"",
    stopper:false

}


const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

const User = JSON.parse(window.localStorage.getItem('user'))



const UserProfile = ({ UserProfileModal, setUserProfile }) => {

  const [iState , updateState] =useState(initialSate)
  const {userList,roleList} = useSelector((state)=> state.dropdownReducer)
  const {profileData} = useSelector((state)=> state.profileReducer)
const dispatch = useDispatch()

  
  const {name, userRole, UserId, email, phoneNumber, RoleId, allProjectsAssigned, assignedProjects,absenceFrom,absenceTo, profilePicture, stopper,preview,roleType} = iState


  // const [UserProfileModal, setUserProfile] = useState(false);
  const handleUserProfileModalClose = () => setUserProfile(false);
  const handleUserProfileModalShow = () => {
    setUserProfile(true);



  };


  const handleInput = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });  };



    useEffect(()=>{

    
      if(UserProfileModal&&iBuildLocalData){
      dispatch(UserDropdownAction())
      dispatch(RoleDropdownAction())

      dispatch(ProfileAction(iBuildLocalData?.user?.CompanyId, iBuildLocalData?.user?.id))
      }
  
    },[UserProfileModal])



    const handleUpload = (e) => {
      const file = e.target.files[0];
      if (file) {
        if (
          file.type === "image/jpeg" ||
          file.type === "image/jpg" ||
          file.type === "image/png"
        ) {

        //  dispatch( getUrlImageAction(file)).then((res)=>{


        //   }
        //   )

          

          updateState({
            ...iState,
            profilePicture: file,
            preview:  URL.createObjectURL(file),
          });

          window.localStorage.setItem('user',(JSON.stringify({profile: URL.createObjectURL(file)})))

        } 
      
    }  };

    const handleSubmit = (e) => {
      e.preventDefault()
      updateState({...iState,stopper:true})
      let formIsValid= true
  
      if(formIsValid){
        dispatch(
          UpdateProfileAction(iBuildLocalData?.user?.CompanyId, iBuildLocalData?.user?.id,{
          
            name,
            email,
            phonecode:"+1",
            phoneNumber,
            profilePicture :preview,
            absenceFrom:absenceFrom,
            absenceTo:absenceTo,
            backUpUserId:UserId,
            roleType,
            RoleId,
            allProjectsAssigned: true,
            assignedProjects: [
                
            ]          })
        )
          .then((res) => {
            if (res.status === 200) {
              toast.success("User Profile created Successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
              updateState({...iState,stopper:false, userId:"", name:"", email:"", phoneNumber:"",RoleId:"",})
             setUserProfile(false)
    
            } else {

              updateState({...iState,stopper:false})

            
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch((error) => {
            updateState({...iState,stopper:false})

            toast.error(error.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }
     
    };



    useEffect(()=>{

      updateState({...iState, CompanyId: profileData?.user?.CompanyId, 
      
        RoleId: profileData?.user?.RoleId,
        roleType: profileData?.user?.roleType,
        UserId: profileData?.user?.backUpUserId,
        absenceFrom:profileData?.user?.absenceFrom,
        absenceTo:profileData?.user?.absenceTo,


     
        allProjectsAssigned:profileData?.user?.allProjectsAssigned,
   
        email :profileData?.user?.email ,
        
     
        name
        : profileData?.user?.name,
        
        phoneNumber
        :profileData?.user?.phoneNumber,
        profilePicture: User?.profile
       })
      

    },[profileData?.user])


    const handleCancel=(e)=>{
      e.preventDefault()
      setUserProfile(false)
      

    }



  return (<>
    <Modal show={UserProfileModal} className="ModalBox LargeModal">
      <div class="modal-content">
        <a
          onClick={handleUserProfileModalClose}
          class="CloseModal"
          data-dismiss="modal"
        >
          ×
        </a>
        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>User Profile</h4>
          </div>
          <div class="FolderForm">
            <form>
              <div class="row">
                <div class="col-sm-12">
                  <div class="d-flex align-items-center mb-3">
                    <div class="UserProfileBox">

                      
                     {(preview|| profilePicture) && <figure>
                        <img src={preview?preview :profilePicture} />
                      </figure>}
                      <span>
                        <img src="images/camera_icon.png" />
                        <input type="file" name="profile" onChange={handleUpload} />
                      </span>
                    </div>
                    <h6 class="ml-3 CursorPointer d-flex align-items-center">
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      value={name}
                      onChange={handleInput}

                    

                      // placeholder="janewilliams@builder.com"
                    />
                       <img src="images/Action-1.png" className="ml-2" />
                    </h6>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <h6>Email </h6>
                    <input
                      type="text"
                      class="form-control"
                      name="email"
                      value={email}
                      onChange={handleInput}

                    

                      // placeholder="janewilliams@builder.com"
                    />
                  </div>
                  <div class="form-group">
                    <h6>Role </h6>
                    <div class="Categories Categories1">
                    <select disabled={true} value={RoleId} name="RoleId"  className="form-control" onChange={handleInput}>
                        <option value="">
                          Select Role
                        </option>
                        {roleList?.map((user)=>
                           <option value={user?.id}>
                           {user?.roleName}
                         </option>
                        )}
                      </select>
                
                      <ul>
                        <li>
                          - list of  roles
                          
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <h6>Absence</h6>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <h6>From</h6>
                        <input type="date" class="form-control"       
                      value={moment(absenceFrom).format("YYYY-MM-DD")}
                      name="absenceFrom"
                      onChange={handleInput}/>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <h6>To</h6>
                        <input type="date"      name="absenceTo"
                      value={moment(absenceTo).format("YYYY-MM-DD")}
                      onChange={handleInput} class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <h6>Back-up user</h6>
                    <div class="Categories Categories1">
                    <select value={UserId} name="UserId"  className="form-control" onChange={handleInput}>
                        <option value="">
                          Select User
                        </option>
                        {userList?.map((user)=>
                            <option value={user?.id}>
                            {user?.name}
                          </option>
                        )}
                      </select>
                
                      <ul>
                        <li>
                          - list of User 
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <h6>
                      Phone Number <img src="images/Action-1.png" />
                    </h6>
                    <input
                      type="number"
                      class="form-control"
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={handleInput}
                      placeholder="(587)555-4444"
                    />
                  </div>
                  <div class="form-group">
                    <h6>Assigned Projects</h6>
                    {/* <p class="TextBlue2 ml-0 mb-2">Proj-4E-SIN-1</p>
                    <p class="TextBlue2 ml-0 ">Proj-4E-MUL-4</p> */}
                  </div>
                </div>
              </div>

              <div class="Button d-flex justify-content-between mt-4">
                <button onClick={handleSubmit} disabled={stopper} type="button" class="Create" data-dismiss="modal">
                  Save
                </button>
                <button type="button" onClick={handleCancel} class="Create" data-dismiss="modal">
                  Reset Password
                </button>
                <button type="button" onClick={handleCancel} class="Outline" data-dismiss="modal">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>

    </>
  );
};

export default UserProfile;
