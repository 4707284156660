import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getMultiLevelStep2 } from "../../../redux/action/proformaAction";

const Quantities = ({
  handleMultiLevelParkadeChange,
  handleShowComponents,
}) => {
  const dispatch = useDispatch([]);
  const [MulitileveleParkadeQuantiesData, setMulitileveleParkadeQuantiesData] =
    useState([]);
  useEffect(() => {
    dispatch(getMultiLevelStep2()).then((res) => {
      console.log({ res });
      setMulitileveleParkadeQuantiesData(res?.data?.data);
    });
  }, []);
  return (
    <>
      <div className="ModalMediumheading2 d-flex align-items-center justify-content-between mb-3 pl-4">
        <h3 className="mb-0">Multi-Level Parkade Quantities</h3>
        <h3 className="mb-0">
          {" "}
          <img src={require("../../../assets/images/cash-flow.png")} />
          &nbsp;&nbsp;Customize Base Costs
        </h3>
      </div>
      <div className="ProformaAccordion">
        <div className="accordion" id="accordionExample">
          {MulitileveleParkadeQuantiesData?.length > 0 &&
            MulitileveleParkadeQuantiesData?.map((item, ind) => {
              return (
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        {item.name}
                      </button>
                    </h2>
                    <a href="javascript:void(0);">
                      <img src="images/book-search.png" />
                    </a>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                    style={{}}
                  >
                    <div className="card-body">
                      <div
                        className="EnterProjectTable"
                        style={{ overflowX: "scroll" }}
                      >
                        <table>
                          <thead>
                            <tr>
                              <th>Cost Code #</th>
                              <th>Description</th>
                              <th>Unit of measaure</th>
                              <th>Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.ProformaBaseCosts?.length > 0 &&
                              item.ProformaBaseCosts?.map((datas, i) => {
                                return (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{datas.CostCode?.name}</td>
                                    <td>{datas.CostCode?.number}</td>
                                    <td>
                                      {/* <div className="CommonSelectBox width-250">
                                    <select>
                                      <option selected="">Per Hour</option>
                                    </select>
                                    <span>
                                      <img src="images/down.png" />
                                    </span>
                                  </div> */}
                                    </td>
                                    {datas?.ProformaBlockQuantities?.length >
                                      0 &&
                                      datas?.ProformaBlockQuantities?.map(
                                        (block, blocId) => {
                                          return (
                                            <td key={blocId}>
                                              <div className="form-group">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Insert Quality"
                                                  name="quantity"
                                                  value={block.quantity}
                                                  onChange={(e) =>
                                                    handleMultiLevelParkadeChange(
                                                      e,
                                                      ind,
                                                      i,
                                                      blocId
                                                    )
                                                  }
                                                />
                                              </div>
                                            </td>
                                          );
                                        }
                                      )}
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                        <div className="PaginationArea px-3">
                          <h6>Showing 1 to 10 of 27 entries</h6>
                          <h6>
                            <img src="images/leftarrow.png" /> 01{" "}
                            <img src="images/rightarrow.png" /> Out 10
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="Button d-flex  justify-content-between align-items-center px-5">
        <button
          className="Outline border text-success border-success"
          onClick={() => handleShowComponents("Input")}
        >
          Cancel
        </button>
        <button
          className="Create bg-success border border-success"
          onClick={() => handleShowComponents("Preview")}
        >
          Save Draft
        </button>
      </div>
    </>
  );
};

export default Quantities;
