import axios from "axios";
import { estimationActionTypes } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config/Config";
import { multiPartData } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

export const getAllReasonListInitate = () => ({
  type: estimationActionTypes.REASON_LIST_INITIATE,
});

export const getAllReasonListSuccess = (data) => ({
  type: estimationActionTypes.REASON_LIST_SUCCESS,
  payload: data,
});

export const getAllReasonListFailure = (data) => ({
  type: estimationActionTypes.REASON_LIST_FAILURE,
  payload: data,
});

export function getAllReasonListAction(payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllReasonListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}reason`,
          //   ?companyId=${iBuildLocalData?.user?.CompanyId}`
          {
            headers: { Authorization: `Bearer ${token}` },
            params:payload,
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllReasonListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllReasonListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getAllReasonListSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllReasonListFailure(err));
            reject(err);
          }
        });
    });
  };
}

export const getAllProfileListInitate = () => ({
  type: estimationActionTypes.PROFILE_LIST_INITIATE,
});

export const getAllProfileListSuccess = (data) => ({
  type: estimationActionTypes.PROFILE_LIST_SUCCESS,
  payload: data,
});

export const getAllProfileListFailure = () => ({
  type: estimationActionTypes.PROFILE_LIST_FAILURE,
 
});

export function getAllProfileListAction() {
  const token = isLoggedIn("adminData1");
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  return (dispatch) => {
    dispatch(getAllProfileListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}taxprofile?CompanyId=${iBuildLocalData?.user?.CompanyId}`,
          //   ?companyId=${iBuildLocalData?.user?.CompanyId}`
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllProfileListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllProfileListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
  
            dispatch(getAllProfileListFailure(err));
            reject(err);
    })
})}}

export const addBillInitate = () => ({
  type: estimationActionTypes.CREATE_NEW_QUOTE_INITIATE,
});

export const addBillSuccess = (data) => ({
  type: estimationActionTypes.CREATE_NEW_QUOTE_SUCCESS,
  payload: data,
});

export const addBillFailure = (data) => ({
  type: estimationActionTypes.CREATE_NEW_QUOTE_FAILURE,
  payload: data,
});

export function addBillAction(payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(addBillInitate());
    return new Promise((resolve, reject) => {
      axios
        // companies/2/Bill
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/bill`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if ((data && data.status == 201) || data.status == 200) {
            dispatch(addBillSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addBillFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addBillFailure(err));
          reject(err);
        });
    });
  };
}

export const addNewQuoteInitate = () => ({
  type: estimationActionTypes.CREATE_NEW_QUOTE_INITIATE,
});

export const addNewQuoteSuccess = (data) => ({
  type: estimationActionTypes.CREATE_NEW_QUOTE_SUCCESS,
  payload: data,
});

export const addNewQuoteFailure = (data) => ({
  type: estimationActionTypes.CREATE_NEW_QUOTE_FAILURE,
  payload: data,
});

export function addNewQuoteAction(payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(addNewQuoteInitate());
    return new Promise((resolve, reject) => {
      axios
        // companies/2/NewQuote
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/quoteRequest`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if ((data && data.status == 201) || data.status == 200) {
            dispatch(addNewQuoteSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addNewQuoteFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addNewQuoteFailure(err));
          reject(err);
        });
    });
  };
}

export const addPurchaseInitate = () => ({
  type: estimationActionTypes.CREATE_PURCHASE_INITIATE,
});

export const addPurchaseSuccess = (data) => ({
  type: estimationActionTypes.CREATE_PURCHASE_SUCCESS,
  payload: data,
});

export const addPurchaseFailure = (data) => ({
  type: estimationActionTypes.CREATE_PURCHASE_FAILURE,
  payload: data,
});

export function addPurchaseAction(payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(addPurchaseInitate());
    return new Promise((resolve, reject) => {
      axios
        // companies/2/Purchase
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/purchaseorder`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if ((data && data.status == 201) || data.status == 200) {
            dispatch(addPurchaseSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addPurchaseFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addPurchaseFailure(err));
          reject(err);
        });
    });
  };
}

export const updateQuoteRequestInitate = () => ({
  type: estimationActionTypes.UPDATE_NEW_QUOTE_INITIATE,
});

export const updateQuoteRequestSuccess = (data) => ({
  type: estimationActionTypes.UPDATE_NEW_QUOTE_SUCCESS,
  payload: data,
});

export const updateQuoteRequestFailure = (data) => ({
  type: estimationActionTypes.UPDATE_NEW_QUOTE_SUCCESS,
  payload: data,
});

export function updateQuoteRequestAction(params, payload) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({  ...payload });
  return (dispatch) => {
    dispatch(updateQuoteRequestInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/quoteRequest/${params}`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(updateQuoteRequestSuccess(data));
            resolve(data);
          } else {
            dispatch(updateQuoteRequestFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateQuoteRequestFailure(err));
          reject(err);
        });
    });
  };
}

export const updatePurchaseDetailsInitate = () => ({
  type: estimationActionTypes.UPDATE_PURCHASE_INITIATE,
});

export const updatePurchaseDetailsSuccess = (data) => ({
  type: estimationActionTypes.UPDATE_PURCHASE_SUCCESS,
  payload: data,
});

export const updatePurchaseDetailsFailure = (data) => ({
  type: estimationActionTypes.UPDATE_PURCHASE_SUCCESS,
  payload: data,
});

export function updatePurchaseDetailsAction(params , payload) {
  const token = isLoggedIn("adminData1");
  
  // const updatedData = multiPartData({  ...payload });
  return (dispatch) => {
    dispatch(updatePurchaseDetailsInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/purchaseorder/${params}`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            dispatch(updatePurchaseDetailsSuccess(response.data));
            resolve(response.data);
          } else {
            dispatch(updatePurchaseDetailsFailure(response.data));
            reject(response.data);
          }
        })
        .catch((err) => {
          dispatch(updatePurchaseDetailsFailure(err));
          reject(err);
        });
    });
  };
}

export const SelectCostCodeInitate = () => ({
  type: estimationActionTypes.SELECT_COST_CODE_INITIATE,
});

export const SelectCostCodeSuccess = (data) => ({
  type: estimationActionTypes.SELECT_COST_CODE_SUCCESS,
  payload: data,
});

export const SelectCostCodeFailure = (data) => ({
  type: estimationActionTypes.SELECT_COST_CODE_FAILURE,
  payload: data,
});

export function SelectCostCodeAction(payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(SelectCostCodeInitate());
    return new Promise((resolve, reject) => {
      axios
        // companies/2/CostCode
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcode`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201) {
            dispatch(SelectCostCodeSuccess(data.data));
            resolve(data);
          } else {
            dispatch(SelectCostCodeFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(SelectCostCodeFailure(err));
          reject(err);
        });
    });
  };
}

export const SelectJobInitate = () => ({
  type: estimationActionTypes.SELECT_JOB_INITIATE,
});

export const SelectJobSuccess = (data) => ({
  type: estimationActionTypes.SELECT_JOB_SUCCESS,
  payload: data,
});

export const SelectJobFailure = (data) => ({
  type: estimationActionTypes.SELECT_JOB_FAILURE,
  payload: data,
});

export function SelectJobAction(payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(SelectJobInitate());
    return new Promise((resolve, reject) => {
      axios
        // companies/2/CostCode
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcode`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201) {
            dispatch(SelectJobSuccess(data.data));
            resolve(data);
          } else {
            dispatch(SelectJobFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(SelectJobFailure(err));
          reject(err);
        });
    });
  };
}

export const getAllBlockListInitate = () => ({
  type: estimationActionTypes.BLOCK_LIST_INITIATE,
});

export const getAllBlockListSuccess = (data) => ({
  type: estimationActionTypes.BLOCK_LIST_SUCCESS,
  payload: data,
});

export const getAllBlockListFailure = (data) => ({
  type: estimationActionTypes.BLOCK_LIST_FAILURE,
  payload: data,
});

export function getAllBlockListAction(mid) {
  const token = isLoggedIn("adminData1");
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  return (dispatch) => {
    dispatch(getAllBlockListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/multi-family/${mid}/blocks`,
          //   ?companyId=${iBuildLocalData?.user?.CompanyId}`
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllBlockListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllBlockListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getAllBlockListSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllBlockListFailure(err));
            reject(err);
          }
        });
    });
  };
}

export const getAllUnitListInitate = () => ({
  type: estimationActionTypes.UNIT_LIST_INITIATE,
});

export const getAllUnitListSuccess = (data) => ({
  type: estimationActionTypes.UNIT_LIST_SUCCESS,
  payload: data,
});

export const getAllUnitListFailure = (data) => ({
  type: estimationActionTypes.UNIT_LIST_FAILURE,
  payload: data,
});

export function getAllUnitListAction(mid, bid) {
  const token = isLoggedIn("adminData1");
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  return (dispatch) => {
    dispatch(getAllUnitListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/multi-family/${mid}/blocks/${bid}/units`,
          //   ?companyId=${iBuildLocalData?.user?.CompanyId}`
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllUnitListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllUnitListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getAllUnitListSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllUnitListFailure(err));
            reject(err);
          }
        });
    });
  };
}

export const getAllEstimationUnitInitate = () => ({
  type: estimationActionTypes.ESTIMATION_UNIT_LIST_INITIATE,
});

export const getAllEstimationUnitSuccess = (data) => ({
  type: estimationActionTypes.ESTIMATION_UNIT_LIST_SUCCESS,
  payload: data,
});

export const getAllEstimationUnitFailure = (data) => ({
  type: estimationActionTypes.ESTIMATION_UNIT_LIST_FAILURE,
  payload: data,
});

export function getAllEstimationUnitAction(pid) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllEstimationUnitInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}unit`,
          //   ?companyId=${iBuildLocalData?.user?.CompanyId}`
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllEstimationUnitSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllEstimationUnitFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getAllEstimationUnitSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllEstimationUnitFailure(err));
            reject(err);
          }
        });
    });
  };
}

export const updateBillInitate = () => ({
  type: estimationActionTypes.UPDATE_BILL_INITIATE,
});

export const updateBillSuccess = (data) => ({
  type: estimationActionTypes.UPDATE_BILL_SUCCESS,
  payload: data,
});

export const updateBillFailure = (data) => ({
  type: estimationActionTypes.UPDATE_BILL_SUCCESS,
  payload: data,
});

export function updateBillAction(params, payload) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({  ...payload });
  return (dispatch) => {
    dispatch(updateBillInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/bill/${params}`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          if ((response.status == 200)) {
            dispatch(updateBillSuccess(response.data));
            resolve(response.data);
          } else {
            dispatch(updateBillFailure(response.data));
            reject(response.data);
          }
        })
        .catch((err) => {
          dispatch(updateBillFailure(err));
          reject(err);
        });
    });
  };
}

export const updateFieldPurchaseOrderInitate = () => ({
  type: estimationActionTypes.UPDATE_FIELD_PURCHASE_ORDER_INITIATE,
});

export const updateFieldPurchaseOrderSuccess = (data) => ({
  type: estimationActionTypes.UPDATE_FIELD_PURCHASE_ORDER_SUCCESS,
  payload: data,
});

export const updateFieldPurchaseOrderFailure = (data) => ({
  type: estimationActionTypes.UPDATE_FIELD_PURCHASE_ORDER_SUCCESS,
  payload: data,
});

export function updateFieldPurchaseOrderAction(payload, params) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({  ...payload });
  return (dispatch) => {
    dispatch(updateFieldPurchaseOrderInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/fieldpurchaseorder/${params}`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(updateFieldPurchaseOrderSuccess(data));
            resolve(data);
          } else {
            dispatch(updateFieldPurchaseOrderFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateFieldPurchaseOrderFailure(err));
          reject(err);
        });
    });
  };
}

export const updateSupplierCreditInitate = () => ({
  type: estimationActionTypes.UPDATE_SUPPLIER_CREDIT_INITIATE,
});

export const updateSupplierCreditSuccess = (data) => ({
  type: estimationActionTypes.UPDATE_SUPPLIER_CREDIT_SUCCESS,
  payload: data,
});

export const updateSupplierCreditFailure = (data) => ({
  type: estimationActionTypes.UPDATE_SUPPLIER_CREDIT_SUCCESS,
  payload: data,
});

export function updateSupplierCreditAction(payload, params) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({  ...payload });
  return (dispatch) => {
    dispatch(updateSupplierCreditInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/suppliercredit/${params}`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(updateSupplierCreditSuccess(data));
            resolve(data);
          } else {
            dispatch(updateSupplierCreditFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateSupplierCreditFailure(err));
          reject(err);
        });
    });
  };
}

export const addFieldPurchaseOrderInitate = () => ({
  type: estimationActionTypes.ADD_FIELD_PURCHASE_ORDER_INITIATE,
});

export const addFieldPurchaseOrderSuccess = (data) => ({
  type: estimationActionTypes.ADD_FIELD_PURCHASE_ORDER_SUCCESS,
  payload: data,
});

export const addFieldPurchaseOrderFailure = (data) => ({
  type: estimationActionTypes.ADD_FIELD_PURCHASE_ORDER_SUCCESS,
  payload: data,
});

export function addFieldPurchaseOrderAction(payload) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({  ...payload });
  return (dispatch) => {
    dispatch(addFieldPurchaseOrderInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/fieldpurchaseorder`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(addFieldPurchaseOrderSuccess(data));
            resolve(data);
          } else {
            dispatch(addFieldPurchaseOrderFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addFieldPurchaseOrderFailure(err));
          reject(err);
        });
    });
  };
}

export const addSupplierCreditInitate = () => ({
  type: estimationActionTypes.ADD_SUPPLIER_CREDIT_INITIATE,
});

export const addSupplierCreditSuccess = (data) => ({
  type: estimationActionTypes.ADD_SUPPLIER_CREDIT_SUCCESS,
  payload: data,
});

export const addSupplierCreditFailure = (data) => ({
  type: estimationActionTypes.ADD_SUPPLIER_CREDIT_SUCCESS,
  payload: data,
});

export function addSupplierCreditAction(payload, params = 2) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({  ...payload });
  return (dispatch) => {
    dispatch(addSupplierCreditInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/suppliercredit`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(addSupplierCreditSuccess(data));
            resolve(data);
          } else {
            dispatch(addSupplierCreditFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addSupplierCreditFailure(err));
          reject(err);
        });
    });
  };
}

export const addBudgetChangeOrderInitate = () => ({
  type: estimationActionTypes.ADD_BUDGET_CHANGE_ORDER_INITIATE,
});

export const addBudgetChangeOrderSuccess = (data) => ({
  type: estimationActionTypes.ADD_BUDGET_CHANGE_ORDER_SUCCESS,
  payload: data,
});

export const addBudgetChangeOrderFailure = (data) => ({
  type: estimationActionTypes.ADD_BUDGET_CHANGE_ORDER_SUCCESS,
  payload: data,
});

export function addBudgetChangeOrderAction(payload, params = 2) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({  ...payload });
  return (dispatch) => {
    dispatch(addBudgetChangeOrderInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/budgetchangeorder`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          console.log("=======>", data);
          if (data && data.status == 201) {
            dispatch(addBudgetChangeOrderSuccess(data));
            resolve(data);
          } else {
            dispatch(addBudgetChangeOrderFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addBudgetChangeOrderFailure(err));
          reject(err);
        });
    });
  };
}

export const updateBudgetChangeOrderInitate = () => ({
  type: estimationActionTypes.UPDATE_BUDGET_CHANGE_ORDER_INITIATE,
});

export const updateBudgetChangeOrderSuccess = (data) => ({
  type: estimationActionTypes.ADD_BUDGET_CHANGE_ORDER_SUCCESS,
  payload: data,
});

export const updateBudgetChangeOrderFailure = (data) => ({
  type: estimationActionTypes.ADD_BUDGET_CHANGE_ORDER_SUCCESS,
  payload: data,
});

export function updateBudgetChangeOrderAction(payload, params) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({  ...payload });
  return (dispatch) => {
    dispatch(updateBudgetChangeOrderInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/budgetchangeorder/${params}`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log("================>", response);
          const data = response.data;
          if ((data && response.status == 200) ) {
            dispatch(updateBudgetChangeOrderSuccess(data));
            resolve(data);
          } else {
            dispatch(updateBudgetChangeOrderFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateBudgetChangeOrderFailure(err));
          reject(err);
        });
    });
  };
}

export const addEstimationSupportingDocsInitate = () => ({
  type: estimationActionTypes.ADD_BUDGET_CHANGE_ORDER_INITIATE,
});

export const addEstimationSupportingDocsSuccess = (data) => ({
  type: estimationActionTypes.ADD_BUDGET_CHANGE_ORDER_SUCCESS,
  payload: data,
});

export const addEstimationSupportingDocsFailure = (data) => ({
  type: estimationActionTypes.ADD_BUDGET_CHANGE_ORDER_SUCCESS,
  payload: data,
});

export function addEstimationSupportingDocsAction(payload, params, type) {
  const token = isLoggedIn("adminData1");
  const updatedData = multiPartData({ ...payload });
  return (dispatch) => {
    dispatch(addEstimationSupportingDocsInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/support-doc?parenttableId=${params}&parenttableType=${type}`,
          updatedData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          console.log("=======>", data);
          if (data && data.status == 201) {
            dispatch(addEstimationSupportingDocsSuccess(data));
            resolve(data);
          } else {
            dispatch(addEstimationSupportingDocsFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addEstimationSupportingDocsFailure(err));
          reject(err);
        });
    });
  };
}

export const getAllBuilderSigneeInitate = () => ({
  type: estimationActionTypes.BUILDER_SIGNEE_LIST_INITIATE,
});

export const getAllBuilderSigneeSuccess = (data) => ({
  type: estimationActionTypes.BUILDER_SIGNEE_LIST_SUCCESS,
  payload: data,
});

export const getAllBuilderSigneeFailure = (data) => ({
  type: estimationActionTypes.BUILDER_SIGNEE_LIST_FAILURE,
  payload: data,
});

export function getAllBuilderSigneeAction(pid) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllBuilderSigneeInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}users`,
          //   ?companyId=${iBuildLocalData?.user?.CompanyId}`
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllBuilderSigneeSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllBuilderSigneeFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getAllBuilderSigneeSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllBuilderSigneeFailure(err));
            reject(err);
          }
        });
    });
  };
}

export const getAllQuoteRequestInitate = () => ({
  type: estimationActionTypes.QUOTE_REQUEST_LIST_INITIATE,
});

export const getAllQuoteRequestSuccess = (data) => ({
  type: estimationActionTypes.QUOTE_REQUEST_LIST_SUCCESS,
  payload: data,
});

export const getAllQuoteRequestFailure = (data) => ({
  type: estimationActionTypes.QUOTE_REQUEST_LIST_FAILURE,
  payload: data,
});

export function getAllQuoteRequestAction(projectId, cid, jobCodeId, jobType, isQR = true, isPO=false,isFPO=false ) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllQuoteRequestInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}estimation-documents?ProjectId=${projectId}&costCodeId=${cid? cid:""}&jobCodeId=${jobCodeId ? jobCodeId :""}&jobType=${jobType ? jobType:""}&isQR=${isQR}&isPO=${isPO}&isFPO=${isFPO}`,
          //   ?companyId=${iBuildLocalData?.user?.CompanyId}`
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            console.log("data",data);
            dispatch(getAllQuoteRequestSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllQuoteRequestFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
            dispatch(getAllQuoteRequestFailure(err));
            reject(err);
        });
    });
  };
}



export const getAllFieldPurchaseOrderInitate = () => ({
  type: estimationActionTypes.FIELD_PURCHASE_ORDER_LIST_INITIATE,
});

export const getAllFieldPurchaseOrderSuccess = (data) => ({
  type: estimationActionTypes.FIELD_PURCHASE_ORDER_LIST_SUCCESS,
  payload: data,
});

export const getAllFieldPurchaseOrderFailure = (data) => ({
  type: estimationActionTypes.FIELD_PURCHASE_ORDER_LIST_FAILURE,
  payload: data,
});

export function getAllFieldPurchaseOrderAction(cid, jobId, jobType) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllFieldPurchaseOrderInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${
            iBuildLocalData?.user?.CompanyId}/fieldpurchaseorder?costCodeId=${cid ? cid : ""}&jobCodeId=${jobId}&jobType=${jobType}`,
          //   ?companyId=${iBuildLocalData?.user?.CompanyId}`
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(getAllFieldPurchaseOrderSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllFieldPurchaseOrderFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getAllFieldPurchaseOrderSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllFieldPurchaseOrderFailure(err));
            reject(err);
          }
        });
    });
  };
}





export const getAllSupplierCreditOrderInitate = () => ({
  type: estimationActionTypes.SUPPLIER_CREDIT_LIST_INITIATE,
});

export const getAllSupplierCreditOrderSuccess = (data) => ({
  type: estimationActionTypes.SUPPLIER_CREDIT_LIST_SUCCESS,
  payload: data,
});

export const getAllSupplierCreditOrderFailure = (data) => ({
  type: estimationActionTypes.SUPPLIER_CREDIT_LIST_FAILURE,
  payload: data,
});

export function getAllSupplierCreditOrderAction(cid, jobId, jobType) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllSupplierCreditOrderInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${
            iBuildLocalData?.user?.CompanyId}/suppliercredit?costCodeId=${cid ? cid : ""}&jobCodeId=${jobId}&jobType=${jobType}`,
          //   ?companyId=${iBuildLocalData?.user?.CompanyId}`
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(getAllSupplierCreditOrderSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllSupplierCreditOrderFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getAllSupplierCreditOrderSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllSupplierCreditOrderFailure(err));
            reject(err);
          }
        });
    });
  };
}








export const quoteRequestModalOpen = (jobCodeId) => {
  return {
    type: estimationActionTypes.QUOTE_REQUEST_MODAL_OPEN,
    payload: jobCodeId,
  };
};

export const quoteRequestModalClose = () => {
  return {
    type: estimationActionTypes.QUOTE_REQUEST_MODAL_CLOSE,
  };
};

export const getAllBillListInitate = () => ({
  type: estimationActionTypes.BILL_LIST_INITIATE,
});

export const getAllBillListSuccess = (data) => ({
  type: estimationActionTypes.BILL_LIST_SUCCESS,
  payload: data,
});

export const getAllBillListFailure = (data) => ({
  type: estimationActionTypes.BILL_LIST_FAILURE,
  payload: data,
});

export function getAllBillListAction() {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllBillListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/bill`,
          //   ?companyId=${iBuildLocalData?.user?.CompanyId}`
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(getAllBillListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllBillListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getAllBillListSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllBillListFailure(err));
            reject(err);
          }
        });
    });
  };
}

export const getApproveCreatePOInitate = () => ({
  type: estimationActionTypes.APPROVE_PO_INITIATE,
});

export const getApproveCreatePOSuccess = (data) => ({
  type: estimationActionTypes.APPROVE_PO_SUCCESS,
  payload: data,
});

export const getApproveCreatePOFailure = (data) => ({
  type: estimationActionTypes.APPROVE_PO_FAILURE,
  payload: data,
});

export function getApproveCreatePOAction(id) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getApproveCreatePOInitate());
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/quoteRequest/${id}/approve`,
          {},
          //   ?companyId=${iBuildLocalData?.user?.CompanyId}`
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(getApproveCreatePOSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getApproveCreatePOFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getApproveCreatePOSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getApproveCreatePOFailure(err));
            reject(err);
          }
        });
    });
  };
}

export const getQRPreferredInitate = () => ({
  type: estimationActionTypes.MARK_QR_PREFERRED_INITIATE,
});

export const getQRPreferredSuccess = (data) => ({
  type: estimationActionTypes.MARK_QR_PREFERRED_SUCCESS,
  payload: data,
});

export const getQRPreferredFailure = (data) => ({
  type: estimationActionTypes.MARK_QR_PREFERRED_FAILURE,
  payload: data,
});

export function getQRPreferredAction(id, payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getQRPreferredInitate());
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/quoteRequest/${id}/preferred`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(getQRPreferredSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getQRPreferredFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getQRPreferredSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getQRPreferredFailure(err));
            reject(err);
          }
        });
    });
  };
}

export const removeQRInitate = () => ({
  type: estimationActionTypes.DELETE_QUOTE_INITIATE,
});

export const removeQRSuccess = (data) => ({
  type: estimationActionTypes.DELETE_QUOTE_SUCCESS,
  payload: data,
});

export const removeQRFailure = (data) => ({
  type: estimationActionTypes.DELETE_QUOTE_FAILURE,
  payload: data,
});

export function removeQRAction(id, payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(removeQRInitate());
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/quoteRequest/${id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(removeQRSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(removeQRFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(removeQRSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(removeQRFailure(err));
            reject(err);
          }
        });
    });
  };
}

export const getAllEventListInitate = () => ({
  type: estimationActionTypes.EVENT_LIST_INITIATE,
});

export const getAllEventListSuccess = (data) => ({
  type: estimationActionTypes.EVENT_LIST_SUCCESS,
  payload: data,
});

export const getAllEventListFailure = (data) => ({
  type: estimationActionTypes.EVENT_LIST_FAILURE,
  payload: data,
});

export function getAllEventListAction(cid, jid, jobType) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllEventListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/quoteRequest/events?costCodeId=${cid}&jobCodeId=${jid}&jobType=${jobType}`,
          //   ?companyId=${iBuildLocalData?.user?.CompanyId}`
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(getAllEventListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllEventListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getAllEventListSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllEventListFailure(err));
            reject(err);
          }
        });
    });
  };
}

export const getquoteDetailsInitate = () => ({
  type: estimationActionTypes.QUOTE_DETAILS_INITIATE,
});

export const getquoteDetailsSuccess = (data) => ({
  type: estimationActionTypes.QUOTE_DETAILS_SUCCESS,
  payload: data,
});

export const getquoteDetailsFailure = (data) => ({
  type: estimationActionTypes.QUOTE_DETAILS_FAILURE,
  payload: data,
});

export function getquoteDetailsAction(cid, jid) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(getquoteDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/quoteRequest/${cid}`,
          //   ?companyId=${iBuildLocalData?.user?.CompanyId}`
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(getquoteDetailsSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getquoteDetailsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getquoteDetailsSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getquoteDetailsFailure(err));
            reject(err);
          }
        });
    });
  };
}

export const getBillDetailsInitate = () => ({
  type: estimationActionTypes.BILL_DETAILS_INITIATE,
});

export const getBillDetailsSuccess = (data) => ({
  type: estimationActionTypes.BILL_DETAILS_SUCCESS,
  payload: data,
});

export const getBillDetailsFailure = (data) => ({
  type: estimationActionTypes.BILL_DETAILS_FAILURE,
  payload: data,
});

export function getBillDetailsAction(cid, jid) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(getBillDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/bill?id=${cid}`,
          //   ?companyId=${iBuildLocalData?.user?.CompanyId}`
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(getBillDetailsSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getBillDetailsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getBillDetailsSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getBillDetailsFailure(err));
            reject(err);
          }
        });
    });
  };
}

export const removeBillInitate = () => ({
  type: estimationActionTypes.CANCEL_BILL_INITIATE,
});

export const removeBillSuccess = (data) => ({
  type: estimationActionTypes.CANCEL_BILL_SUCCESS,
  payload: data,
});

export const removeBillFailure = (data) => ({
  type: estimationActionTypes.CANCEL_BILL_FAILURE,
  payload: data,
});

export function removeBillAction(id) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(removeBillInitate());
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/bill/${id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(removeBillSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(removeBillFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(removeBillSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(removeBillFailure(err));
            reject(err);
          }
        });
    });
  };
}

export const getAllBudgetListingInitate = () => ({
  type: estimationActionTypes.BUDGET_LISTING_INITIATE,
});

export const getAllBudgetListingSuccess = (data) => ({
  type: estimationActionTypes.BUDGET_LISTING_SUCCESS,
  payload: data,
});

export const getAllBudgetListingFailure = (data) => ({
  type: estimationActionTypes.BUDGET_LISTING_FAILURE,
  payload: data,
});

export function getAllBudgetListingAction(payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllBudgetListingInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}company/${iBuildLocalData?.user?.CompanyId}/budgetdetails`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 201) {
            dispatch(getAllBudgetListingSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllBudgetListingFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getAllBudgetListingSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllBudgetListingFailure(err));
            reject(err);
          }
        });
    });
  };
}




export const getAllModalBudgetViewListInitate = () => ({
  type: estimationActionTypes.MODAL_BUDGET_VIEW_LIST_INITIATE,
});

export const getAllModalBudgetViewListSuccess = (data) => ({
  type: estimationActionTypes.MODAL_BUDGET_VIEW_LIST_SUCCESS,
  payload: data,
});

export const getAllModalBudgetViewListFailure = (data) => ({
  type: estimationActionTypes.MODAL_BUDGET_VIEW_LIST_FAILURE,
  payload: data,
});

export function getAllModalBudgetViewListAction(payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllModalBudgetViewListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/budgetchangeorder`,          
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
         
          if (data && data.status == 200) {
            dispatch(getAllModalBudgetViewListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllModalBudgetViewListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getAllModalBudgetViewListSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllModalBudgetViewListFailure(err));
            reject(err);
          }
        });
    });
  };
}

//====================================BACK-charge-supplier-credit--list==================================

export const backChargeCreditListInitiate = () => ({
  type: estimationActionTypes.BACK_CHARGE_CREDIT_LIST_INITIATE,
});

export const backChargeCreditListSuccess = (data) => ({
  type: estimationActionTypes.BACK_CHARGE_CREDIT_LIST_SUCCESS,
  payload: data,
});

export const backChargeCreditListFailure = (data) => ({
  type: estimationActionTypes.BACK_CHARGE_CREDIT_LIST_FAILURE,
  payload: data,
});

export const getBackChargeCreditList = (jobCodeId,jobType) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(backChargeCreditListInitiate());
    const response = await axios.get(`${Url}backcharge?jobCodeId=${jobCodeId}&jobType=${jobType}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status == 200) {
      dispatch(backChargeCreditListSuccess(response.data));
    } else {
      dispatch(backChargeCreditListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(backChargeCreditListFailure(err));
    return err.response;
  }
};

//==========================================CREATE-back-charge=======================================

export const createBackChargeCreditInitiate = () => ({
  type: estimationActionTypes.CREATE_BACK_CHARGE_CREDIT_INITIATE,
});

export const createBackChargeCreditSuccess = (data) => ({
  type: estimationActionTypes.CREATE_BACK_CHARGE_CREDIT_SUCCESS,
  payload: data,
});

export const createBackChargeCreditFailure = (data) => ({
  type: estimationActionTypes.CREATE_BACK_CHARGE_CREDIT_FAILURE,
  payload: data,
});

export const createBackChargeCredit = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(createBackChargeCreditInitiate());
    const response = await axios.post(`${Url}backcharge`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status == 200) {
      dispatch(createBackChargeCreditSuccess(response.data));
    } else {
      dispatch(createBackChargeCreditFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(createBackChargeCreditFailure(err));
    return err.response;
  }
};

//================================================DELETE-BACK-CHARGE===================================

export const deleteBackChargeCreditInitiate = () => ({
  type: estimationActionTypes.DELETE_BACK_CHARGE_CREDIT_INITIATE,
});

export const deleteBackChargeCreditSuccess = (data) => ({
  type: estimationActionTypes.DELETE_BACK_CHARGE_CREDIT_SUCCESS,
  payload: data,
});

export const deleteBackChargeCreditFailure = (data) => ({
  type: estimationActionTypes.DELETE_BACK_CHARGE_CREDIT_FAILURE,
  payload: data,
});

export const deleteBackChargeCredit = (backId) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(deleteBackChargeCreditInitiate());
    const response = await axios.delete(`${Url}backcharge/${backId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status == 200) {
      dispatch(deleteBackChargeCreditSuccess(response.data));
    } else {
      dispatch(deleteBackChargeCreditFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(deleteBackChargeCreditFailure(err));
    return err.response;
  }
};

//===============================================COST-BREAK-DOWN -DETAILS===================================

export const quoteRequestCostBreakDownDetailsInitiate = () => ({
  type: estimationActionTypes.QUOTE_REQUEST_COST_BREAKDOWN_DETAILS_INITIATE,
});

export const quoteRequestCostBreakDownDetailsSuccess = (data) => ({
  type: estimationActionTypes.QUOTE_REQUEST_COST_BREAKDOWN_DETAILS_SUCCESS,
  payload: data,
});

export const quoteRequestCostBreakDownDetailsFailure = (data) => ({
  type: estimationActionTypes.QUOTE_REQUEST_COST_BREAKDOWN_DETAILS_FAILURE,
  payload: data,
});

export const getQuoteRequestCostBreakDownDetails =
  (quoteId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(quoteRequestCostBreakDownDetailsInitiate());
      const response = await axios.get(
        `${Url}companies/${iBuildLocalData?.user?.CompanyId}/quoteRequest/${quoteId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status == 200) {
        dispatch(quoteRequestCostBreakDownDetailsSuccess(response.data));
      } else {
        dispatch(quoteRequestCostBreakDownDetailsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(quoteRequestCostBreakDownDetailsFailure(err));
      return err.response;
    }
  };

export function uploadAdditionalDocsAction(id , type,payload) {
  const token = isLoggedIn("adminData1");
  const updatedData = multiPartData({  ...payload });
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/support-doc?parenttableId=${id}&parenttableType=${type}`, updatedData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 201) {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            resolve(err.data);
          } else {
            reject(err);
          }
        });
    });
  };
}



export const getAllPurchaseListInitate = () => ({
  type: estimationActionTypes.PURCHASE_LIST_INITIATE,
});

export const getAllPurchaseListSuccess = (data) => ({
  type: estimationActionTypes.PURCHASE_LIST_SUCCESS,
  payload: data,
});

export const getAllPurchaseListFailure = (data) => ({
  type: estimationActionTypes.PURCHASE_LIST_FAILURE,
  payload: data,
});

export function getAllPurchaseListAction(id, jobId, jobType) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllPurchaseListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/purchaseorder?costCodeId=${id}&jobCodeId=${jobId}&jobType=${jobType}`,
          //   ?companyId=${iBuildLocalData?.user?.CompanyId}`
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllPurchaseListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllPurchaseListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getAllPurchaseListSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllPurchaseListFailure(err));
            reject(err);
          }
        });
    });
  };
}





export function getAllPurchaseDetailsAction(id) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/purchaseorder?id=${id}`,
          //   ?companyId=${iBuildLocalData?.user?.CompanyId}`
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            
            resolve(data.data);
          } else {
           
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
         
            resolve(err.data);
          } else {
          
            reject(err);
          }
        });
    });
  };
}





export function getAllFieldPurchaseDetailsAction(id) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/fieldpurchaseorder?id=${id}`,
          //   ?companyId=${iBuildLocalData?.user?.CompanyId}`
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            
            resolve(data.data);
          } else {
           
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
         
            resolve(err.data);
          } else {
          
            reject(err);
          }
        });
    });
  };
}




export function getAllSupplierCreditDetailsAction(id) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/suppliercredit?id=${id}`,
          //   ?companyId=${iBuildLocalData?.user?.CompanyId}`
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            
            resolve(data.data);
          } else {
           
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
         
            resolve(err.data);
          } else {
          
            reject(err);
          }
        });
    });
  };
}




export const budgetListInitate = () => ({
  type: estimationActionTypes.BUDGET_LISTING_INITIATE,
});

export const budgetListSuccess = (data) => ({
  type: estimationActionTypes.BUDGET_LISTING_SUCCESS,
  payload: data,
});

export const budgetListFailure = (data) => ({
  type: estimationActionTypes.BUDGET_LISTING_SUCCESS,
  payload: data,
});

export function budgetListAction(payload) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({  ...payload });
  return (dispatch) => {
    dispatch(budgetListInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/budgetchangeorder`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          console.log("=======>", data);
          if (data && data.status == 201) {
            dispatch(budgetListSuccess(data));
            resolve(data);
          } else {
            dispatch(budgetListFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(budgetListFailure(err));
          reject(err);
        });
    });
  };
}



export const getAllScopeWorkIdListInitate = () => ({
  type: estimationActionTypes.SCOPE_WORK_ID_LIST_INITIATE,
});

export const getAllScopeWorkIdListSuccess = (data) => ({
  type: estimationActionTypes.SCOPE_WORK_ID_LIST_SUCCESS,
  payload: data,
});

export const getAllScopeWorkIdListFailure = (data) => ({
  type: estimationActionTypes.SCOPE_WORK_ID_LIST_FAILURE,
  payload: data,
});

export function getAllScopeWorkIdListAction(pid) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllScopeWorkIdListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${pid}/released-estimations-docs`,          
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(getAllScopeWorkIdListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllScopeWorkIdListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getAllScopeWorkIdListSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllScopeWorkIdListFailure(err));
            reject(err);
          }
        });
    });
  };
}





export function addEstimationDocumentAction(payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/estimation-document`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status === 200) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}



export const getAllEstimationDocsListingInitate = () => ({
  type: estimationActionTypes.ESTIMATION_DOCUMENT_LISTING_INITIATE,
});

export const getAllEstimationDocsListingSuccess = (data) => ({
  type: estimationActionTypes.ESTIMATION_DOCUMENT_LISTING_SUCCESS,
  payload: data,
});

export const getAllEstimationDocsListingFailure = (data) => ({
  type: estimationActionTypes.ESTIMATION_DOCUMENT_LISTING_FAILURE,
  payload: data,
});

export function getAllEstimationDocsListingAction(id, type, typeData) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllEstimationDocsListingInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/estimation-document?parentTableId=${id}&parentTableName=${type}&for=${typeData}`,          
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status === 200) {
            dispatch(getAllEstimationDocsListingSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllEstimationDocsListingFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getAllEstimationDocsListingSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllEstimationDocsListingFailure(err));
            reject(err);
          }
        });
    });
  };
}





export function removeEstimationDocsListingAction(id) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/estimation-document/${id}`,          
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            resolve(err.data);
          } else {
            reject(err);
          }
        });
    });
  };
}




export function getUserLimitDetailsAction(id) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}/api/v1/estimation/userLimit?projectId=${id}`,          
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            resolve(err.data);
          } else {
            reject(err);
          }
        });
    });
  };
}


export function requestLimitIncreaseAction(payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}estimation/requestLimitIncrease`,  payload,        
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            resolve(err.data);
          } else {
            reject(err);
          }
        });
    });
  };
}




export function hideCostAction(payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}hideCost`,  payload,        
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 201) {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            resolve(err.data);
          } else {
            reject(err);
          }
        });
    });
  };
}





export function budgetchangeDetailsAction(payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/budgetchangeorder/${payload}`,        
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 201) {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            resolve(err.data);
          } else {
            reject(err);
          }
        });
    });
  };
}



export function estimationHistoryAction(payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}events?jobCostId=${payload}`,        
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log("ressssssssssss",response)
          const data = response;
          if (data && data.status == 200) {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            resolve(err.data);
          } else {
            reject(err);
          }
        });
    });
  };
}




export function changeEstimationStatusAction(payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${Url}company/${iBuildLocalData?.user?.CompanyId}/budgetdetails/costCodeStatus`,        
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 201) {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            resolve(err.data);
          } else {
            reject(err);
          }
        });
    });
  };
}




export function uploadBudgetChangeOrderDocsAction(id, payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/budgetchangeorder/${id}/agreement`, payload,       
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 201) {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            resolve(err.data);
          } else {
            reject(err);
          }
        });
    });
  };
}

export const createBackChargeNotificationInitate = () => ({
  type: estimationActionTypes.CREATE_BACK_CHARGE_NOTIFICATION_INITIATE,
});

export const createBackChargeNotificationSuccess = (data) => ({
  type: estimationActionTypes.CREATE_BACK_CHARGE_NOTIFICATION_SUCCESS,
  payload: data,
});

export const createBackChargeNotificationFailure = (data) => ({
  type: estimationActionTypes.CREATE_BACK_CHARGE_NOTIFICATION_FAILURE,
  payload: data,
});

export const createBackChargeNotification =(payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(createBackChargeNotificationInitate());
    const response = await axios.post(`${Url}backChargeNotification`,payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 201) {
      dispatch(createBackChargeNotificationSuccess(response.data));
    } else {
      dispatch(createBackChargeNotificationFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(createBackChargeNotificationFailure(err));
    return err.response;
  }
};

//=====================GET-cost-code==ESTIAMTE=========================

export const getEstimationSettingCostCodeInitate = () => ({
  type: estimationActionTypes.GET_ESTIMATION_SETTING_COST_CODE_INITIATE,
});

export const getEstimationSettingCostCodeSuccess = (data) => ({
  type: estimationActionTypes.GET_ESTIMATION_SETTING_COST_CODE_SUCCESS,
  payload: data,
});

export const getEstimationSettingCostCodeFailure = (data) => ({
  type: estimationActionTypes.GET_ESTIMATION_SETTING_COST_CODE_FAILURE,
  payload: data,
});

export const getEstimationSettingCostCode =() => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(getEstimationSettingCostCodeInitate());
    const response = await axios.get(`${Url}estimationsettings?for=CostCode`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(getEstimationSettingCostCodeSuccess(response.data));
    } else {
      dispatch(getEstimationSettingCostCodeFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getEstimationSettingCostCodeFailure(err));
    return err.response;
  }
};

//===============================UPDATE=cost-code===ESTIMATION====================

export const updateEstimationSettingCostCodeInitate = () => ({
  type: estimationActionTypes.UPDATE_ESTIMATION_SETTING_COST_CODE_INITIATE,
});

export const updateEstimationSettingCostCodeSuccess = (data) => ({
  type: estimationActionTypes.UPDATE_ESTIMATION_SETTING_COST_CODE_SUCCESS,
  payload: data,
});

export const updateEstimationSettingCostCodeFailure = (data) => ({
  type: estimationActionTypes.UPDATE_ESTIMATION_SETTING_COST_CODE_FAILURE,
  payload: data,
});

export const updateEstimationSettingCostCode =(payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(updateEstimationSettingCostCodeInitate());
    const response = await axios.put(`${Url}estimationsettings?for=CostCode`,payload?.costCodeData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(updateEstimationSettingCostCodeSuccess(response.data));
    } else {
      dispatch(updateEstimationSettingCostCodeFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(updateEstimationSettingCostCodeFailure(err));
    return err.response;
  }
};


//=====================GET-cost-category==ESTIAMTE=========================

export const getEstimationSettingCostCategoryInitate = () => ({
  type: estimationActionTypes.GET_ESTIMATION_SETTING_COST_CATEGORY_INITIATE,
});

export const getEstimationSettingCostCategorySuccess = (data) => ({
  type: estimationActionTypes.GET_ESTIMATION_SETTING_COST_CATEGORY_SUCCESS,
  payload: data,
});

export const getEstimationSettingCostCategoryFailure = (data) => ({
  type: estimationActionTypes.GET_ESTIMATION_SETTING_COST_CATEGORY_FAILURE,
  payload: data,
});

export const getEstimationSettingCostCategory =() => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(getEstimationSettingCostCategoryInitate());
    const response = await axios.get(`${Url}estimationsettings?for=CostCategory`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(getEstimationSettingCostCategorySuccess(response.data));
    } else {
      dispatch(getEstimationSettingCostCategoryFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getEstimationSettingCostCategoryFailure(err));
    return err.response;
  }
};

//===============================UPDATE=cost-code===ESTIMATION====================

export const updateEstimationSettingCostCategoryInitate = () => ({
  type: estimationActionTypes.UPDATE_ESTIMATION_SETTING_COST_CATEGORY_INITIATE,
});

export const updateEstimationSettingCostCategorySuccess = (data) => ({
  type: estimationActionTypes.UPDATE_ESTIMATION_SETTING_COST_CATEGORY_SUCCESS,
  payload: data,
});

export const updateEstimationSettingCostCategoryFailure = (data) => ({
  type: estimationActionTypes.UPDATE_ESTIMATION_SETTING_COST_CATEGORY_FAILURE,
  payload: data,
});

export const updateEstimationSettingCostCategory =(payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(updateEstimationSettingCostCategoryInitate());
    const response = await axios.put(`${Url}estimationsettings?for=CostCategory`,payload?.costCategoryData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(updateEstimationSettingCostCategorySuccess(response.data));
    } else {
      dispatch(updateEstimationSettingCostCategoryFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(updateEstimationSettingCostCategoryFailure(err));
    return err.response;
  }
};
//=========================GET-RELATED-DOCUMENTS==========================


export const getRelatedDocumnetsInitate = () => ({
  type: estimationActionTypes.GET_RELATED_DOCUMNETS_INITIATE,
});

export const getRelatedDocumnetsSuccess = (data) => ({
  type: estimationActionTypes.GET_RELATED_DOCUMNETS_SUCCESS,
  payload: data,
});

export const getRelatedDocumnetsFailure = (data) => ({
  type: estimationActionTypes.GET_RELATED_DOCUMNETS_FAILURE,
  payload: data,
});

export const getRelatedDocumnets =(vendorId) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(getRelatedDocumnetsInitate());
    const response = await axios.get(`${Url}estimation-documents?isPO=true&isFPO=true&vendorId=${vendorId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(getRelatedDocumnetsSuccess(response.data));
    } else {
      dispatch(getRelatedDocumnetsFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getRelatedDocumnetsFailure(err));
    return err.response;
  }
};
//=====================SYNC=========BACK---CHARGE=====================

export const syncBackChargeInitate = () => ({
  type: estimationActionTypes.SYNC_BACK_CHARGE_INITIATE,
});

export const syncBackChargeSuccess = (data) => ({
  type: estimationActionTypes.SYNC_BACK_CHARGE_SUCCESS,
  payload: data,
});

export const syncBackChargeFailure = (data) => ({
  type: estimationActionTypes.SYNC_BACK_CHARGE_FAILURE,
  payload: data,
});

export const syncBackCharges =(backChargId,payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
    dispatch(syncBackChargeInitate());
    const response = await axios.patch(`${Url}company/${iBuildLocalData?.user?.CompanyId}/backcharge/${backChargId}/sync`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(syncBackChargeSuccess(response.data));
    } else {
      dispatch(syncBackChargeFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(syncBackChargeFailure(err));
    return err.response;
  }
};

//=======================GET-BACK-CHARGE-RELTED---DOCUMENT=============


export const getBackChargeRelatedDocumnetsInitate = () => ({
  type: estimationActionTypes.GET_BACK_CHARGE_RELATED_DOCUMNETS_INITIATE,
});

export const getBackChargeRelatedDocumnetsSuccess = (data) => ({
  type: estimationActionTypes.GET_BACK_CHARGE_RELATED_DOCUMNETS_SUCCESS,
  payload: data,
});

export const getBackChargeRelatedDocumnetsFailure = (data) => ({
  type: estimationActionTypes.GET_BACK_CHARGE_RELATED_DOCUMNETS_FAILURE,
  payload: data,
});

export const getBackChargeRelatedDocumnets =(vendorId) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(getBackChargeRelatedDocumnetsInitate());
    const response = await axios.get(`${Url}backcharge/relatedDocuments/${vendorId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(getBackChargeRelatedDocumnetsSuccess(response.data));
    } else {
      dispatch(getBackChargeRelatedDocumnetsFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getBackChargeRelatedDocumnetsFailure(err));
    return err.response;
  }
};

//=====================CREATE--bc--FPo=====================

export const createBCFieldPOOrderInitate = () => ({
  type: estimationActionTypes.CREATE_BACK_CHARGE_FIELD_PURCHASE_ORDER_INITIATE,
});

export const createBCFieldPOOrderSuccess = (data) => ({
  type: estimationActionTypes.CREATE_BACK_CHARGE_FIELD_PURCHASE_ORDER_SUCCESS,
  payload: data,
});

export const createBCFieldPOOrderFailure = (data) => ({
  type: estimationActionTypes.CREATE_BACK_CHARGE_FIELD_PURCHASE_ORDER_FAILURE,
  payload: data,
});

export const createBackChargeFieldPOOrder =(payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(createBCFieldPOOrderInitate());
    const response = await axios.post(`${Url}backcharge/fpo`,payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 201) {
      dispatch(createBCFieldPOOrderSuccess(response.data));
    } else {
      dispatch(createBCFieldPOOrderFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(createBCFieldPOOrderFailure(err));
    return err.response;
  }
};
//======================create==bc---sc=======================

export const createBCScInitate = () => ({
  type: estimationActionTypes.CREATE_BACK_CHARGE_SUPPLIER_CREDIT_INITIATE,
});

export const createBCScSuccess = (data) => ({
  type: estimationActionTypes.CREATE_BACK_CHARGE_SUPPLIER_CREDIT_SUCCESS,
  payload: data,
});

export const createBCScFailure = (data) => ({
  type: estimationActionTypes.CREATE_BACK_CHARGE_SUPPLIER_CREDIT_FAILURE,
  payload: data,
});

export const createBackChargeSupplierCredits =(payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(createBCScInitate());
    const response = await axios.post(`${Url}backcharge/sc`,payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 201) {
      dispatch(createBCScSuccess(response.data));
    } else {
      dispatch(createBCScFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(createBCScFailure(err));
    return err.response;
  }
};

//=========================message--list======================

export const messageListInitate = () => ({
  type: estimationActionTypes.MESSAGE_LIST_INITIATE,
});

export const messageListSuccess = (data) => ({
  type: estimationActionTypes.MESSAGE_LIST_SUCCESS,
  payload: data,
});

export const messageListFailure = (data) => ({
  type: estimationActionTypes.MESSAGE_LIST_FAILURE,
  payload: data,
});

export const getMessageList =(backChargeDocumentId) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(messageListInitate());
    const response = await axios.get(`${Url}/backChargeNotification?backChargeDocumentId=${backChargeDocumentId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(messageListSuccess(response.data));
    } else {
      dispatch(messageListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(messageListFailure(err));
    return err.response;
  }
};

//===================re-send---message======================
export const resendMessageInitate = () => ({
  type: estimationActionTypes.RESEND_MESSAGE_INITIATE,
});

export const resendMessageSuccess = (data) => ({
  type: estimationActionTypes.RESEND_MESSAGE_SUCCESS,
  payload: data,
});

export const resendMessageFailure = (data) => ({
  type: estimationActionTypes.RESEND_MESSAGE_FAILURE,
  payload: data,
});

export const resendMessage =(backChargeDocumentId,payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(resendMessageInitate());
    const response = await axios.post(`${Url}backChargeNotification/${backChargeDocumentId}`,payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(resendMessageSuccess(response.data));
    } else {
      dispatch(resendMessageFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(resendMessageFailure(err));
    return err.response;
  }
};

//======================cancel--back-charge===================

export const cancelBackChargeInitate = () => ({
  type: estimationActionTypes.CANCEL_BACK_CHARGE_INITIATE,
});

export const cancelBackChargeSuccess = (data) => ({
  type: estimationActionTypes.CANCEL_BACK_CHARGE_SUCCESS,
  payload: data,
});

export const cancelBackChargeFailure = (data) => ({
  type: estimationActionTypes.CANCEL_BACK_CHARGE_FAILURE,
  payload: data,
});

export const cancelBackCharge =(backChargeDocumentId,payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(cancelBackChargeInitate());
    const response = await axios.patch(`${Url}backcharge/${backChargeDocumentId}/cancel`,{},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(cancelBackChargeSuccess(response.data));
    } else {
      dispatch(cancelBackChargeFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(cancelBackChargeFailure(err));
    return err.response;
  }
};




export const hddenCostCodesAPiList =(id,jobType) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    const response = await axios.get(`${Url}showHiddenCost?jobId=${id}&jobType=${jobType}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      return response;
    } else if (response.status === 404) {
      return response;
    }
 
  } catch (err) {
    
    return err.response;
  }
};



export const hideCostCodes =(id) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    const response = await axios.post(`${Url}unHideCost`, id,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
    } else {
    }
    return response;
  } catch (err) {
    dispatch(cancelBackChargeFailure(err));
    return err.response;
  }
};


//================relaesed--construction---docs============

export const getReleasedConstructionDocsInitate = () => ({
  type: estimationActionTypes.RELEASED_CONSTRUCTION_DOCS_INITIATE,
});

export const getReleasedConstructionDocsSuccess = (data) => ({
  type: estimationActionTypes.RELEASED_CONSTRUCTION_DOCS_SUCCESS,
  payload: data,
});

export const getReleasedConstructionDocsFailure = (data) => ({
  type: estimationActionTypes.RELEASED_CONSTRUCTION_DOCS_FAILURE,
  payload: data,
});

export const getReleasedConstructionDocs =(projectId) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(getReleasedConstructionDocsInitate());
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${projectId}/released-constructions-docs`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(getReleasedConstructionDocsSuccess(response.data));
    } else {
      dispatch(getReleasedConstructionDocsFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getReleasedConstructionDocsFailure(err));
    return err.response;
  }
};

//===========================APPROVE==FPO========================

export const approveFPOInitate = () => ({
  type: estimationActionTypes.APPROVE_FPO_INITIATE,
});

export const approveFPOSuccess = (data) => ({
  type: estimationActionTypes.APPROVE_FPO_SUCCESS,
  payload: data,
});

export const approveFPOFailure = (data) => ({
  type: estimationActionTypes.APPROVE_FPO_FAILURE,
  payload: data,
});

export const approveFPOs =(id) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(approveFPOInitate());
    const response = await axios.patch(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/quoteRequest/${id}/fieldpurchaseorder/approve`,{},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(approveFPOSuccess(response.data));
    } else {
      dispatch(approveFPOFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(approveFPOFailure(err));
    return err.response;
  }
};

//=======================sc--approve===========================

export const approveSCInitate = () => ({
  type: estimationActionTypes.APPROVE_SC_INITIATE,
});

export const approveSCSuccess = (data) => ({
  type: estimationActionTypes.APPROVE_SC_SUCCESS,
  payload: data,
});

export const approveSCFailure = (data) => ({
  type: estimationActionTypes.APPROVE_SC_FAILURE,
  payload: data,
});

export const approveSCs =(id) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(approveSCInitate());
    const response = await axios.patch(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/fieldpurchaseorder/${id}/approve`,{},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(approveSCSuccess(response.data));
    } else {
      dispatch(approveSCFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(approveSCFailure(err));
    return err.response;
  }
};
//===========================GET-Bc-NOTIFY BY ID=================

export const getBCNotificationByIdInitate = () => ({
  type: estimationActionTypes.GET_BACK_CHARGE_NOTIFICATION_BY_ID_INITIATE,
});

export const getBCNotificationByIdSuccess = (data) => ({
  type: estimationActionTypes.GET_BACK_CHARGE_NOTIFICATION_BY_ID_SUCCESS,
  payload: data,
});

export const getBCNotificationByIdFailure = (data) => ({
  type: estimationActionTypes.GET_BACK_CHARGE_NOTIFICATION_BY_ID_FAILURE,
  payload: data,
});

export const getBCNotificationById =(id,token) => async (dispatch) => {
  try {
    dispatch(getBCNotificationByIdInitate());
    const response = await axios.get(`${Url}backChargeNotification/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(getBCNotificationByIdSuccess(response.data));
    } else {
      dispatch(getBCNotificationByIdFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getBCNotificationByIdFailure(err));
    return err.response;
  }
};

//==============CARETE==BC================


export const createBackChargeNotificationsInitate = () => ({
  type: estimationActionTypes.CREATE_BACK_CHARGE_NOTIFICATIONS_INITIATE,
});

export const createBackChargeNotificationsSuccess = (data) => ({
  type: estimationActionTypes.CREATE_BACK_CHARGE_NOTIFICATIONS_SUCCESS,
  payload: data,
});

export const createBackChargeNotificationsFailure = (data) => ({
  type: estimationActionTypes.CREATE_BACK_CHARGE_NOTIFICATIONS_FAILURE,
  payload: data,
});

export const createBackChargeNotifications =(payload,token) => async (dispatch) => {
  try {
    dispatch(createBackChargeNotificationsInitate());
    const response = await axios.post(`${Url}backChargeNotification`,payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 201) {
      dispatch(createBackChargeNotificationsSuccess(response.data));
    } else {
      dispatch(createBackChargeNotificationsFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(createBackChargeNotificationFailure(err));
    return err.response;
  }
};

//========================UPDATE--eDIT--QUOTE--REQUEST==================

export const qouteRequestByVendorInitiate = () => ({
  type: estimationActionTypes.QUOTE_REQUEST_BY_VENDOR_INITIATE,
});

export const qouteRequestByVendorSuccess = (data) => ({
  type: estimationActionTypes.QUOTE_REQUEST_BY_VENDOR_SUCCESS,
  payload: data,
});

export const  qouteRequestByVendorFailure = (data) => ({
  type: estimationActionTypes.QUOTE_REQUEST_BY_VENDOR_FAILURE,
  payload: data,
});

export const qouteRequestByVendors = (documentId,payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(qouteRequestByVendorInitiate())
    const response = await axios.patch(`${Url}/companies/${iBuildLocalData?.user?.CompanyId}/quoteRequest/${documentId}/vendor`,payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(qouteRequestByVendorSuccess(response.data));
    } else {
      dispatch(qouteRequestByVendorFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(qouteRequestByVendorFailure(err));
    return err.response;
  }
};

//===========================get--bill--by-id================

export const getBillsByIdInitiate = () => ({
  type: estimationActionTypes.GET_BILLS_BY_ID_INITIATE,
});

export const getBillsByIdSuccess = (data) => ({
  type: estimationActionTypes.GET_BILLS_BY_ID_SUCCESS,
  payload: data,
});

export const  getBillsByIdFailure = (data) => ({
  type: estimationActionTypes.GET_BILLS_BY_ID_FAILURE,
  payload: data,
});

export const getBillsByIds = (companyId,documentId,token) => async (dispatch) => {
  try {
   
    dispatch(getBillsByIdInitiate())
    const response = await axios.get(`${Url}/companies/${companyId}/bill/${documentId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getBillsByIdSuccess(response.data));
    } else {
      dispatch(getBillsByIdFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getBillsByIdFailure(err));
    return err.response;
  }
};

//=============================BILL-ACCEPT=================

export const billsAcceptInitiate = () => ({
  type: estimationActionTypes.BILLS_ACCEPT_INITIATE,
});

export const billsAcceptSuccess = (data) => ({
  type: estimationActionTypes.BILLS_ACCEPT_SUCCESS,
  payload: data,
});

export const  billsAcceptFailure = (data) => ({
  type: estimationActionTypes.BILLS_ACCEPT_FAILURE,
  payload: data,
});

export const billsAccepts = (companyId,documentId,token,payload) => async (dispatch) => {
  try {
   
    dispatch(billsAcceptInitiate())
    const response = await axios.patch(`${Url}/companies/${companyId}/bill/${documentId}/action`,payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(billsAcceptSuccess(response.data));
    } else {
      dispatch(billsAcceptFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(billsAcceptFailure(err));
    return err.response;
  }
};
//====================all-accept====================

export const allbillsAcceptInitiate = () => ({
  type: estimationActionTypes.ALL_BILLS_ACCEPT_INITIATE,
});

export const allbillsAcceptSuccess = (data) => ({
  type: estimationActionTypes.ALL_BILLS_ACCEPT_SUCCESS,
  payload: data,
});

export const  allbillsAcceptFailure = (data) => ({
  type: estimationActionTypes.ALL_BILLS_ACCEPT_FAILURE,
  payload: data,
});

export const AllbillsAccepts = (companyId,documentId,token,payload) => async (dispatch) => {
  try {
   
    dispatch(allbillsAcceptInitiate())
    const response = await axios.patch(`${Url}/companies/${companyId}/bill/${documentId}/accept`,{},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(allbillsAcceptSuccess(response.data));
    } else {
      dispatch(allbillsAcceptFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(allbillsAcceptFailure(err));
    return err.response;
  }
};

