import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  addClientUser,
  clientUserPasswordEmailSend,
  clientViewGeneralConfig,
  getClientUserList,
  removeClientUser,
  updateClientUser,
} from "../redux/action/clientViewAuthAction";
import { update } from "lodash";
import { Checkbox } from "react-bootstrap";
import NoDataFound from "./Nodatafound";
import {
  headerNotifcaitionList,
  removeHeaderNotifcaitionList,
  updateChangePasswordLinkAction,
} from "../redux/action/authAction";
import { checkArray } from "../utils/CheckType";
import {
  getAllProjectDetailsAction,
  getAllProjectListAction,
} from "../redux/action/jobAction";
import { notificationsAction } from "../redux/actionTypes";
import {
  AddDiscussionAction,
  AddReplyAction,
  discussionListAction,
} from "../redux/action/constructionsAction";
import UserProfile from "./headerModals/userProfile";
import ContactList from "./headerModals/contactList";
import Loader from "./Loader";
import AddContact from "./headerModals/addContact";
import ContactDetails from "./headerModals/contactDetails";
import {
  addHelpAndSupportAction,
  getHeaderHelpListAction,
  helpDetailsAction,
  helpListAction,
} from "../redux/action/systemAdministrationAction";
import { errorToast, sucessToast } from "../utils/toast";
import moment from "moment";
const initialState = {
  ManageUsersModal: false,
  CreateUserModal: false,
  fullName: "",
  email: "",
  UpdateUserModal: false,
  EmailModal: false,
  emails: "",
};

const init = {
  email: "",
  emailModal: false,
  emailError: "",
  projectDetailsModal: false,
  projectDetailsData: [],
  ProjectNameShow: "",
  companyId: "",
  clientName: "",
  phoneNumber: "",
  availability: "",
  contactPrefrence: "",
  languagePrefrence: "",
  issueDetailsType: "",
  severity: "",
  module: "",
  description: "",
  problemStartDate: "",
  time: "",
  timeType: "",
  timeZone: "",
  errorMessage: "",
  applicationLink: "",
  helpAndSupportError: {},
  avinashEmail: "",
  supportingDocFiles: [],
  falseImage: "",
  addSupportModal: false,
  historyListData: [],
  supportListingModal: false,
  supportDetailsModal: false,
};

const InvestorHeader = () => {
   const navigate = useNavigate();
  const dispatch = useDispatch();
  const [iAvinashState, updateAvinashState] = useState(initialState);
  const {
    ManageUsersModal,
    CreateUserModal,
    fullName,
    avinashEmail,
    UpdateUserModal,
    emails,
    EmailModal,
  } = iAvinashState;
  const handlelLogout = () => {
    navigate("/");
    window.localStorage.clear();
  };

  const { clientViewConfigData, clientList } = useSelector(
    (state) => state.clientViewLogin
  );
  console.log("clientList", clientList);
  const clientData = JSON.parse(window.localStorage.getItem("clientViewLogin"));

  useEffect(() => {
    dispatch(
      clientViewGeneralConfig(
        clientData?.jobDetails?.id,
        clientData?.jobDetails?.jobType
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getClientUserList(res?.data?.ViewSetting?.id));
      }
    });
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };
  const handleManageUsersModalShow = () => {
    updateAvinashState({ ...iAvinashState, ManageUsersModal: true });
  };

  const handleManageUsersModalClose = () => {
    updateAvinashState({ ...iAvinashState, ManageUsersModal: false });
  };

  const handleCreateUserModalShow = () => {
    updateAvinashState({
      ...iAvinashState,
      CreateUserModal: true,
      fullName: "",
      avinashEmail: "",
      ManageUsersModal: false,
    });
  };

  const handleCreateUsers = () => {
    if (!fullName) {
      toast.error("Name can't empty", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!avinashEmail) {
      toast.error("e-mail can't empty", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const dataa = {
        fullName,
        avinashEmail,
      };
      dispatch(
        addClientUser(clientViewConfigData?.ViewSetting?.id, dataa)
      ).then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateAvinashState({
            ...iAvinashState,
            CreateUserModal: false,
            fullName: "",
            avinashEmail: "",
            ManageUsersModal: true,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateAvinashState({
            ...iAvinashState,
            CreateUserModal: true,
          });
        }
      });
    }
  };
  const handleCreateUserModalClose = () => {
    updateAvinashState({
      ...iAvinashState,
      CreateUserModal: false,
      ManageUsersModal: true,
    });
  };

  const handleUpdateUserModalShow = () => {
    updateAvinashState({
      ...iAvinashState,
      UpdateUserModal: true,
      fullName: "",
      avinashEmail: "",
      ManageUsersModal: false,
    });
  };
  const handleUpdateUserModalClose = () => {
    updateAvinashState({
      ...iAvinashState,
      UpdateUserModal: false,
      ManageUsersModal: true,
    });
  };

  const handleUserDelete = (id) => {
    dispatch(removeClientUser(clientViewConfigData?.ViewSetting?.id, id)).then(
      (res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  const handleUpdateUsers = () => {
    if (!fullName) {
      toast.error("Name can't empty", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!avinashEmail) {
      toast.error("e-mail can't empty", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const dataa = {
        fullName,
        email: avinashEmail,
      };
      dispatch(
        updateClientUser(clientViewConfigData?.ViewSetting?.id, dataa)
      ).then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateAvinashState({
            ...iAvinashState,
            UpdateUserModal: false,
            fullName: "",
            avinashEmail: "",
            ManageUsersModal: true,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateAvinashState({
            ...iAvinashState,
            UpdateUserModal: true,
          });
        }
      });
    }
  };

  const handleEmailModalShow = (avinashEmail) => {
    updateAvinashState({
      ...iAvinashState,
      EmailModal: true,
      ManageUsersModal: false,
      avinashEmail: emails,
    });
  };
  const handleEmailModalClose = () => {
    updateAvinashState({
      ...iAvinashState,
      EmailModal: false,
      ManageUsersModal: true,
    });
  };

  const handleUserResetPasswordEmailSend = (e) => {
    e.preventDefault();
    if (!emails) {
      toast.error("Please enter email", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const dataa = { email: emails };
      dispatch(clientUserPasswordEmailSend(dataa)).then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            EmailModal: false,
            ManageUsersModal: true,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateAvinashState({
            ...iAvinashState,
            EmailModal: true,
          });
        }
      });
    }
  };

  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const { contactList } = useSelector((state) => state.profileReducer);

  const [phonecode, setPhoneCode] = useState("");
  const [iState, updateState] = useState(init);
  const [widthState, setWidth] = useState(false);
  const [helpState, setHelpState] = useState(false);
  const [UserProfileModal, setUserProfile] = useState(false);

  const [ContactListModal, setContactList] = useState(false);

  const [ContactDetailsModal, SetContactDetails] = useState(false);

  const [AddContactModal, SetAddContact] = useState(false);
  const [NavShow, SetNavShow] = useState(false);

  const [NotificationState, setNotificationState] = useState(false);
  const {
    email,
    emailModal,
    emailError,
    projectDetailsModal,
    projectDetailsData,
    ProjectNameShow,
    companyId,
    clientName,
    phoneNumber,
    availability,
    contactPrefrence,
    languagePrefrence,
    issueDetailsType,
    severity,
    module,
    description,
    problemStartDate,
    time,
    timeType,
    timeZone,
    errorMessage,
    applicationLink,
    helpAndSupportError,
    addSupportModal,
    supportingDocFiles,
    falseImage,
    supportListingModal,
    supportDetailsModal,
  } = iState;

  const {
    countryData,
    cityData,
    stateData,
    timeZoneData,
    headerNotificationListData,
  } = useSelector((state) => state.authReducer);

  const clientViewLogin = JSON.parse(localStorage.getItem("clientViewLogin"));

  const {
    helpListData,
    helpHistoryListData,
    loader,
    helpDetailsData,
    headerHelpListData,
  } = useSelector((state) => state.systemAdministrationReducer);

  const handleContactListModalClose = () => setContactList(false);
  const handleContactListModalShow = () => {
    setContactList(true);
  };

  const { discussionList } = useSelector((state) => state.constructionReducer);

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };
  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    updateState({ ...iState, [name]: result });
  };
  const handlePhoneInputChange = (value, country) => {
    setPhoneCode(`+${country.dialCode}`);
  };
  const handleInputFile = (e) => {
    const { name, value } = e.target;
    const file = e.target.files[0];
    console.log("======>", file);
    if (file) {
      updateState({
        ...iState,
        [name]: file,
      });
    }
    e.target.files = null;
  };
  let path = window.location.href.split("/");
  let pathLength = parseInt(path.length) - 1;
  let pathMatch = path[pathLength];
  // const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const { projectListData, taxProfileListData, landDetailsData } = useSelector(
    (state) => state.jobReducer
  );
  useEffect(() => {
    if (iBuildLocalData?.user?.CompanyId != null) {
      dispatch(getAllProjectListAction());
      dispatch(headerNotifcaitionList());
    }
  }, [dispatch, iBuildLocalData?.user?.CompanyId]);
  useEffect(() => {
    dispatch(getHeaderHelpListAction(pathMatch));
  }, [pathMatch]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const sendLinkToEmail = (e) => {
    e.preventDefault();
    if (!email) {
      updateState({ ...iState, emailError: "Email can't be empty" });
    } else if (email) {
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        updateState({ ...iState, emailError: "Please enter a valid email" });
      } else {
        dispatch(updateChangePasswordLinkAction({ email })).then((res) => {});
      }
    }
  };

  const showSupportModal = () => {
    updateState({
      ...iState,
      addSupportModal: true,
    });
  };

  const hideSupportModal = () => {
    updateState({
      ...iState,
      addSupportModal: false,
    });
  };

  const showSupportListingModal = () => {
    dispatch(helpListAction());
    updateState({
      ...iState,
      supportListingModal: true,
    });
  };

  const hideSupportListingModal = () => {
    updateState({
      ...iState,
      supportListingModal: false,
    });
  };

  const [discussionState, updateDiscussionState] = useState({
    jobId: "",
    message: "",
    discussionError: "",
  });
  const [replyState, updateReplyState] = useState({
    messageId: "",
    jobId: "",
    message: "",
    replyError: "",
  });
  const [replyField, updateReplyField] = useState({ flag: false, index: "" });

  let handleValidationDiscussion = () => {
    let discussionError = "";
    let formIsValid = true;

    if (discussionState.message == "") {
      discussionError = "* Please enter comment";
      formIsValid = false;
    }

    updateDiscussionState({
      ...discussionState,
      discussionError: discussionError,
    });
    return formIsValid;
  };

  const hideSupportDetailsModal = (d) => {
    updateState({
      ...iState,
      supportDetailsModal: false,
    });
  };

  const showSupportDetailsModal = (id) => {
    dispatch(helpDetailsAction(id));
    updateState({
      ...iState,
      supportDetailsModal: true,
      modalId: id,
    });
  };

  const handleAddDiscussion = () => {
    let formIsValid = handleValidationDiscussion();
    if (formIsValid) {
      dispatch(
        AddDiscussionAction({
          message: discussionState.message,
          helpId: helpDetailsData[0]?.id,
          type: "Help",
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(
              discussionListAction({
                helpId: helpDetailsData[0]?.id,
                type: "Help",
              })
            );

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleAddReply = () => {
    let formIsValid = true;
    if (formIsValid) {
      dispatch(
        AddReplyAction({
          message: replyState.message,
          helpId: helpDetailsData[0]?.id,
          type: "Help",
          messageId: replyState.messageId,
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(
              discussionListAction({
                helpId: helpDetailsData[0]?.id,
                type: "Help",
              })
            );
            updateReplyState({
              messageId: "",
              jobId: "",
              message: "",
              replyError: "",
            });

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const addSupportFunc = (e) => {
    e.preventDefault();
    let data = {
      companyId: clientViewLogin?.user?.CompanyId,
      companyName: clientViewLogin?.user?.name,
      clientName,
      phoneNumber,
      phonecode,
      availability,
      contactPrefrence,
      languagePrefrence,
      issueDetailsType,
      severity,
      module,
      description,
      problemStartDate,
      time,
      timeType,
      timeZone,
      errorMessage,
      applicationLink,
      supportingDocFiles,
      // helpAndSupportError
    };
    console.log("addSupport", data);
    dispatch(addHelpAndSupportAction(data))
      .then((res) => {
        console.log(res);
        if (res.status === 400) {
          errorToast(res.data.message);
        } else {
          sucessToast("Support added successfully");
          hideSupportModal();
          dispatch(helpListAction());
        }
      })
      .catch((err) => {
        console.log("errrr", err);
        errorToast(err.response.data.message);
      });
  };

 

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([
    { path: "/fileManager-investor-view", element: "System File Manager" },
    { path: "/reports", element: "Reports" },
  ]);

  const [isSearchFocused, setIsSearchFocused] = useState(false);

 
  const handleSearchInputChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    if (query === "") {
      setSearchResults([
        { path: "/fileManager-investor-view", element: "System File Manager" },
        { path: "/reports", element: "Reports" },
      ]);
    } else {
      const filteredResults = searchResults.filter(result =>
        result.element.toLowerCase().includes(query)
      );
      setSearchResults(filteredResults);
      
    }
  };
  
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.SearchPosition')) {
        setIsSearchFocused(false);
      }
    };

    if (isSearchFocused) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isSearchFocused]);
  return (
    <div>
      <header>
        <div className="Logo">
          <img src="images/Logo.png" />
        </div>
        <div className="Navigation">
        <div className="Search SearchPosition">
        <span>
          <img src="images/search.png" />
        </span>
        <input
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={handleSearchInputChange}
          onFocus={() => setIsSearchFocused(true)}
          
        />
        <div className='SearchOverlay' 
        >
           {
        isSearchFocused&& <ul>
        {searchResults.map((result, index) => (
          <li key={index} >
            <Link to={result.path}>{result.element}</Link>
          </li>
        ))}
      </ul>
       }
        </div>
      </div>
          <div className="NavigationMenu">
            <ul>
              <div
                id="myTask"
                className="sidenav"
                style={{ width: NavShow ? "300px" : "0px" }}
              >
                <a
                  href="javascript:void(0)"
                  className="closebtn"
                  onClick={() => SetNavShow(false)}
                >
                  ×
                </a>
                <div className="SideNavTopBox">
                  <h1>My Task</h1>
                </div>
                <div className="SideNavBottom">
                  <div className="SideTaskBoxTitle">
                    <h6>Today</h6>
                  </div>
                  <div className="SideTaskBox">
                    <h6>Task 1</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                    <div className="TaskButtonArea">
                      <button className="View">View</button>
                      <button className="Start">Start</button>
                    </div>
                  </div>
                  <div className="SideTaskBox">
                    <h6>Task 2</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                    <div className="TaskButtonArea">
                      <button className="View">View</button>
                      <button className="Start">Start</button>
                    </div>
                  </div>
                  <div className="SideTaskBox">
                    <h6>Task 3</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                    <div className="TaskButtonArea">
                      <button className="View">View</button>
                      <button className="Completed">Completed</button>
                    </div>
                  </div>
                  <div className="SideTaskBox">
                    <h6>Task 4</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                    <div className="TaskButtonArea">
                      <button className="View">View</button>
                      <button className="Ongoing">Ongoing</button>
                    </div>
                  </div>
                </div>
              </div>
              <li onclick="openNotification()">
                <a href="javascript:void(0);">
                  <img src="images/Icon-2.png" />
                </a>
              </li>
              <div id="myNotification" className="sidenav">
                <a
                  href="javascript:void(0)"
                  className="closebtn"
                  onclick="closeNotification()"
                >
                  ×
                </a>
                <div className="SideNavTopBox">
                  <h1>Notification</h1>
                </div>
                <div className="SideNavBottom">
                  <div className="SideTaskBoxTitle">
                    <h6>Today</h6>
                  </div>
                  <div className="SideNotificationBox">
                    <h6>Lorem Ipsum</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                  </div>
                  <div className="text-right">
                    <button className="DeleteBtn">Delete</button>
                  </div>
                  <div className="SideNotificationBox">
                    <h6>Lorem Ipsum</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                  </div>
                  <div className="text-right">
                    <button className="DeleteBtn">Delete</button>
                  </div>
                  <div className="SideNotificationBox">
                    <h6>Lorem Ipsum</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                  </div>
                  <div className="text-right">
                    <button className="DeleteBtn">Delete</button>
                  </div>
                  <div className="SideTaskBoxTitle">
                    <h6>Yesterday</h6>
                  </div>
                  <div className="SideNotificationBox">
                    <h6>Lorem Ipsum</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                  </div>
                  <div className="text-right">
                    <button className="DeleteBtn">Delete</button>
                  </div>
                  <div className="SideNotificationBox">
                    <h6>Lorem Ipsum</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                  </div>
                  <div className="text-right">
                    <button className="DeleteBtn">Delete</button>
                  </div>
                </div>
              </div>


              <li onClick={() => setHelpState(!helpState)}>
                <a href="javascript:void(0);">
                  <img src="images/Icon-3.png" />
                </a>
              </li>
              <div style={{ width: helpState ? 300 : 0 }} className="sidenav">
                <a
                  href="javascript:void(0)"
                  className="closebtn"
                  onClick={() => setHelpState(false)}
                >
                  ×
                </a>
                <div className="SideNavTopBox flex-column">
                  <h1>Help</h1>
                  <a
                    href="javascript:void(0);"
                    onClick={showSupportListingModal}
                  >
                    Support Requests <span className="Count">3</span>
                  </a>
                  <a
                    href="javascript:void(0);"
                    onClick={showSupportModal}
                    className="CreateNewSupportRequest"
                  >
                    <img src="images/icon-90.png" />
                    &nbsp;&nbsp;Create New Support Request
                  </a>
                </div>
                <div className="SideNavBottom">
                  {checkArray(headerHelpListData?.notData) ? (
                    headerHelpListData?.notData?.map((ele, i) => {
                      console.log("eleleleleellee", ele);
                      return (
                        <>
                          <div className="SideNavSettingBox">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: ele?.helpMessage,
                              }}
                              className="editor"
                            ></div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <NoDataFound />
                    </>
                  )}
                </div>
              </div>
             
             
              <li onClick={() => setWidth(!widthState)}>
                <a href="javascript:void(0);">
                  <img src="images/Icon-4.png" />
                </a>
              </li>
              <div
                id="mySettings"
                className="sidenav"
                style={{ width: widthState ? 300 : 0 }}
              >
                <a className="closebtn" onClick={() => setWidth(false)}>
                  ×
                </a>
                <div className="SideNavTopBox">
                  <h1>Settings</h1>
                </div>
                <div className="SideNavBottom">
                  <div className="SettingThemeArea">
                    <div className="form-group">
                      <label className="Dark-mode d-block">Password</label>
                      <label href="#" className="text-red">
                        Change Password
                      </label>
                    </div>
                    <div className="SideTaskBoxTitle">
                      <h6>Theme</h6>
                    </div>
                    <div className="SideNavHelpBox">
                      <ul>
                        <li>
                          <img src="images/theme-1.png" />
                        </li>
                        <li>
                          <img src="images/theme-2.png" />
                        </li>
                        <li>
                          <img src="images/theme-3.png" />
                        </li>
                        <li>
                          <img src="images/theme-4.png" />
                        </li>
                        <li>
                          <img src="images/theme-5.png" />
                        </li>
                        <li>
                          <img src="images/theme-6.png" />
                        </li>
                        <li>
                          <img src="images/theme-7.png" />
                        </li>
                        <li>
                          <img src="images/theme-8.png" />
                        </li>
                      </ul>
                    </div>
                    <div className="d-flex justify-content-between mt-4">
                      <div className="form-group">
                        <label className="Radio">
                          English
                          <input type="radio" name="Cuisine" />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="Radio">
                          French
                          <input type="radio" name="Cuisine" />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                    <div className="form-group mt-3 d-flex align-items-center justify-content-between">
                      <label className="Dark-mode mb-0">Dark Mode</label>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider" />
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="Dark-mode d-block">Password</label>
                      <label href="#" className="text-red">
                        Change Password
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="Dark-mode d-block">
                        Contact preferences
                      </label>
                      <label href="#" className="text-red">
                        Update contact preferences
                      </label>
                    </div>
                    <div className="form-group">
                      <h5>User Management</h5>
                      <a
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#ManageUserBig"
                      >
                        User Management
                      </a>
                    </div>
                  </div>
                  <div className="SettingsEditArea">
                    <div className="SettingsEdit">
                      <h6>Theme</h6>
                      <div className="settingsEditBox">
                        <h6>Add</h6>
                        <p>Remove</p>
                      </div>
                    </div>
                    <div className="SettingsEdit">
                      <h6>Language</h6>
                      <div className="settingsEditBox">
                        <h6>Add</h6>
                        <p>Remove</p>
                      </div>
                    </div>
                    <div className="SettingsEdit">
                      <h6>Dark Mode</h6>
                      <div className="settingsEditBox">
                        <h6>Add</h6>
                        <p>Remove</p>
                      </div>
                    </div>
                    <div className="SettingsEdit">
                      <h6>Password</h6>
                      <div className="settingsEditBox">
                        <h6>Add</h6>
                        <p>Remove</p>
                      </div>
                    </div>
                    <div className="text-center">
                      <button className="Save SaveBtn">Save</button>
                    </div>
                  </div>
                </div>
              </div>
              <li onclick="openNav()">
                <a
                  class="dropdown-toggle"
                  id="navbarDropdown"
                  onClick={() => SetNavShow(true)}
                  role="button"
                >
                  <span>MB</span>
                </a>
              </li>
              <div
                id="mySidenav"
                class="sidenav"
                style={{ width: NavShow ? "300px" : "0px" }}
              >
                <a onClick={() => SetNavShow(false)} className="closebtn">
                  ×
                </a>
                <div class="sidenavTop">
                  <span>DM</span>
                  <h6>
                    {clientViewLogin?.user?.name}{" "}
                    {/* {/ {clientViewLogin?.user?.lastName} /} */}
                  </h6>
                  <h6>
                    {clientViewLogin?.user?.email}{" "}
                    {clientViewLogin?.user?.phoneNumber}
                  </h6>
                </div>
                <div class="d-flex flex-column justify-content-between">
                  <ul class="">
                    <li>
                      <a onClick={() => setUserProfile(true)}>
                        <img src="images/profile.png" />
                        Profile
                      </a>
                    </li>
                    <li>
                      <a onClick={() => setContactList(true)}>
                        <img src="images/contacts.png" />
                        Contacts
                      </a>
                    </li>
                  </ul>
                  <ul class="bottomUl">
                    <li>
                      <a href="">
                        <img src="images/signout.png" />
                        Sign Out
                      </a>
                    </li>
                  </ul>
                  <ul className="bottomUl">
                    <li>
                      <a onClick={handlelLogout}>
                        <img src="images/signout.png" />
                        Sign Out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </header>
    </div>

    
  );
};

export default InvestorHeader;
