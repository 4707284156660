import React from 'react'

const BlockFoundationPreview = ({handleShowComponent,handleShow}) => {
  return (

    <div>  
       <div className="BlockCostsPreview">
    <div className="d-flex align-items-center justify-content-between">
      <h4>Blocks Foundation Costs Preview</h4>
      <div className="NewActions">
        <a href="javascript:void(0);" title="pdf">
          <img src="images/pdf.png" />
        </a>
        <a href="javascript:void(0);">
          <img src="images/download.png" />
        </a>
      </div>
    </div>
    <div className="BlockInputsCollapse">
      <div
        className="BcpHeader collapsed"
        data-toggle="collapse"
        href="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        <h5>Basement Foundation - Block Foundation Costs</h5>
      </div>
      <div
        // className="collapse"
        id="collapseExample"
        style={{}}
      >
        <div className="card card-body border-0">
          <div className="BlockInputs">
            <table>
              <thead>
                <tr>
                  <th>Code Code #</th>
                  <th>Description</th>
                  <th>Block A</th>
                  <th>Block C</th>
                  <th>Total Construction Costs</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>4.0102</td>
                  <td>Windows. and Exterlor Doors</td>
                  <td>$ -</td>
                  <td>$ -</td>
                  <td>$ -</td>
                </tr>
                <tr>
                  <td>4.0102</td>
                  <td>Windows. and Exterlor Doors</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>4.0102</td>
                  <td>O.H. Garage Door Opener(s)</td>
                  <td>2,100.00</td>
                  <td>2,100.00</td>
                  <td>8,400.00</td>
                </tr>
                <tr>
                  <td>4.0102</td>
                  <td>O.H. Garage Door Opener(s)</td>
                  <td>2,100.00</td>
                  <td>2,100.00</td>
                  <td>8,400.00</td>
                </tr>
                <tr>
                  <td>4.0102</td>
                  <td>O.H. Garage Door Opener(s)</td>
                  <td>2,100.00</td>
                  <td>2,100.00</td>
                  <td>8,400.00</td>
                </tr>
                <tr>
                  <td>4.0102</td>
                  <td>O.H. Garage Door Opener(s)</td>
                  <td>2,100.00</td>
                  <td>2,100.00</td>
                  <td>8,400.00</td>
                </tr>
                <tr>
                  <td>4.0102</td>
                  <td>O.H. Garage Door Opener(s)</td>
                  <td>2,100.00</td>
                  <td>2,100.00</td>
                  <td>8,400.00</td>
                </tr>
                <tr>
                  <td>4.0102</td>
                  <td>O.H. Garage Door Opener(s)</td>
                  <td>2,100.00</td>
                  <td>2,100.00</td>
                  <td>8,400.00</td>
                </tr>
                <tr>
                  <td>4.0102</td>
                  <td>O.H. Garage Door Opener(s)</td>
                  <td>2,100.00</td>
                  <td>2,100.00</td>
                  <td>8,400.00</td>
                </tr>
                <tr>
                  <td>4.0102</td>
                  <td>O.H. Garage Door Opener(s)</td>
                  <td>2,100.00</td>
                  <td>2,100.00</td>
                  <td>8,400.00</td>
                </tr>
                <tr>
                  <td>4.0102</td>
                  <td>O.H. Garage Door Opener(s)</td>
                  <td>2,100.00</td>
                  <td>2,100.00</td>
                  <td>8,400.00</td>
                </tr>
                <tr>
                  <td>4.0102</td>
                  <td>O.H. Garage Door Opener(s)</td>
                  <td>2,100.00</td>
                  <td>2,100.00</td>
                  <td>8,400.00</td>
                </tr>
                <tr>
                  <td>4.0102</td>
                  <td>O.H. Garage Door Opener(s)</td>
                  <td>2,100.00</td>
                  <td>2,100.00</td>
                  <td>8,400.00</td>
                </tr>
                <tr>
                  <td>4.0102</td>
                  <td>O.H. Garage Door Opener(s)</td>
                  <td>2,100.00</td>
                  <td>2,100.00</td>
                  <td>8,400.00</td>
                </tr>
                <tr>
                  <td>4.0102</td>
                  <td>O.H. Garage Door Opener(s)</td>
                  <td>2,100.00</td>
                  <td>2,100.00</td>
                  <td>8,400.00</td>
                </tr>
                <tr>
                  <td>4.0102</td>
                  <td>O.H. Garage Door Opener(s)</td>
                  <td>2,100.00</td>
                  <td>2,100.00</td>
                  <td>8,400.00</td>
                </tr>
                <tr>
                  <td>4.0102</td>
                  <td>O.H. Garage Door Opener(s)</td>
                  <td>2,100.00</td>
                  <td>2,100.00</td>
                  <td>8,400.00</td>
                </tr>
                <tr>
                  <td>4.0102</td>
                  <td>O.H. Garage Door Opener(s)</td>
                  <td>2,100.00</td>
                  <td>2,100.00</td>
                  <td>8,400.00</td>
                </tr>
                <tr>
                  <td>4.0102</td>
                  <td>O.H. Garage Door Opener(s)</td>
                  <td>2,100.00</td>
                  <td>2,100.00</td>
                  <td>8,400.00</td>
                </tr>
                <tr>
                  <td>4.0102</td>
                  <td>O.H. Garage Door Opener(s)</td>
                  <td>2,100.00</td>
                  <td>2,100.00</td>
                  <td>8,400.00</td>
                </tr>
                <tr>
                  <td>4.0102</td>
                  <td>O.H. Garage Door Opener(s)</td>
                  <td>2,100.00</td>
                  <td>2,100.00</td>
                  <td>8,400.00</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td />
                  <td>Total Foundation Cost</td>
                  <td>67,480.00</td>
                  <td>67,480.00</td>
                  <td>2,75,480.00</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

<div className="Button d-flex justify-content-between align-items-center px-5">
  <button
    className="Outline"
    onClick={()=>handleShow("blockFoundationQuanties")}
  >
    Back
  </button>

  <button
    className="Create FolderPermissionId"
    onClick={() => handleShowComponent("units")}
  >
    Foundation
  </button>
</div></div>
  )
}

export default BlockFoundationPreview