import React, { useEffect, useRef, useState } from "react";
import Header from "../Header";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import {
  getEmailCalendar,
  getGoogleCalendar,
  getOutlookCalendar,
  getTaskListJob,
  getTaskMemberList,
} from "../../redux/action/taskAction";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { calculateDateDifference } from "../../utils/uploadFile";
import {
  getAssignStaff,
  getInternityAdd,
  getInternityList,
  getIternarayUpdate,
  getItinertyDelete,
  getUpdateReminder,
  iternarayAssignStaffMember,
} from "../../redux/action/IbuildAdminItineraryAction";
import Loader from "../Loader";
import moment, { months } from "moment";
import IbuildAdminSidenav from "./IbuildAdminSidenav";
import ItinerarySettingModal from "./IbuildAdminItineraryModal/ItinerarySettingModal";

import {
  getTradeCommentList,
  getTradeSubCommentList,
  createTradeComment,
  createTradeSubComment,
} from "../../redux/action/tradeViewAuthAction";
import ViewSharedItineraryModal from "./IbuildAdminItineraryModal/ViewSharedItineraryModal";
const initialState = {
  AddItineraryModal: false,
  ReminderModalInt: false,
  AssignStaffMemberModalInt: false,
  subject: "",
  descriptions: "",
  location: "",
  date: "",
  fromTime: "",
  toTime: "",
  type: "",
  reminderDates: "",
  jobType: "",
  jobId: "",
  relatedToTask: "",
  relatedToJob: "",
  TaskId: "",
  week: "",
  day: "",
  assignStaffss: "",
  ItinerariesListSettingsModal: false,
  selectedOption: "",
  IternaryDeleteModal: false,
  UpdateAssignStaffMemberModal: false,
  UserId: "",
  UpdateReminderModal: false,
  UpdateItineraryModal: false,
  content: "",
  investorId: "",
  compId: "",

  ItrId: "",
};
const IbuildAdminItinerary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { tradeCommentList, tradeSubCommentList } = useSelector(
    (state) => state.tradeViewLogin
  );

  const [ItineraySettingState, SetItineraySettingState] = useState(false);

  const [ViewSharedItineraryState, SetViewSharedItineraryState] =useState(false)
  const [iState, updateState] = useState(initialState);
  const {
    AddItineraryModal,
    ReminderModalInt,
    AssignStaffMemberModalInt,
    subject,
    descriptions,
    location,
    date,
    fromTime,
    toTime,
    type,
    reminderDates,
    jobType,
    jobId,
    relatedToTask,
    relatedToJob,
    TaskId,
    week,
    day,
    assignStaffss,
    ItinerariesListSettingsModal,
    selectedOption,
    IternaryDeleteModal,
    UpdateAssignStaffMemberModal,
    UpdateReminderModal,
    UserId,
    UpdateItineraryModal,
    ItrId,
    content,
    subContent,
    index,
    investorId,
    compId,
  } = iState;

  const { jobList, memberList } = useSelector((state) => state.taskReducer);
  const { itineraryList, loader } = useSelector(
    (state) => state.itineraryReducer
  );
  // console.log("jobList", jobList);
  // console.log("memberList", memberList);

  const { adminUserList } = useSelector((state) => state.dropdownReducer);

  const [requesters, setRequesters] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [userListShow, SetUserListShow] = useState([]);

  useEffect(() => {
    dispatch(getTaskListJob());
    dispatch(getTaskMemberList());
    // dispatch(
    //   getInternityList({
    //     startDate: new Date().toISOString().slice(0, 10),
    //     endDate: new Date().toISOString().slice(0, 10),
    //   })
    // );
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name == "jobType") {
      let index = e.nativeEvent.target.selectedIndex;
      updateState({
        ...iState,
        [name]: e.nativeEvent.target[index].text,
        jobId: value,
      });
    } else {
      updateState({
        ...iState,
        [name]: value,
      });
    }
  };

  const handleAddUser = (e) => {
    e.preventDefault();

    let temp = adminUserList?.users?.filter((user) => user.id == selectedUser);
    let users = userListShow;
    users.push(temp?.[0]);
    SetUserListShow(users);

    setRequesters([...requesters, { UserId: parseInt(selectedUser) }]);
  };

  const handleRemoveUser = (e, id) => {
    e.preventDefault();

    let index = userListShow?.findIndex((item) => (item.id = id));
    let tempRequester = requesters;
    let tempUserShow = userListShow;

    tempRequester.splice(index, 1);

    tempUserShow.splice(index, 1);

    setRequesters(tempUserShow);
    setSelectedUser(tempRequester);
  };

  const handleAddItineraryModalShow = () => {
    updateState({
      ...iState,
      AddItineraryModal: true,
    });
  };

  const handleAddItineraryModalClose = () => {
    updateState({
      ...iState,
      AddItineraryModal: false,
    });
  };

  const handleJobTaskChecked = (e, type) => {
    const { checked } = e.target;
    if (type == "job") {
      updateState({ ...iState, relatedToJob: checked });
    } else {
      updateState({ ...iState, relatedToTask: checked });
    }
  };

  const handleAssignStaffMemberIntShow = () => {
    updateState({
      ...iState,
      AssignStaffMemberModalInt: true,
    });
  };

  const handleAssignStaffMemberIntClose = () => {
    updateState({
      ...iState,
      AssignStaffMemberModalInt: false,
    });
  };

  const handleAssignIn = (e) => {


      e.preventDefault();
      let formIsValid = true;
  
      if (formIsValid) {
        dispatch(iternarayAssignStaffMember(TaskId,{
          "assignStaffs": [
                {
                    "UserId": 80
                }
            ]
        
        
        }))
          .then((res) => {
            if (res.status === 200) {
              toast.success("Users Assigned Successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
  
              setRequesters([]);
              SetUserListShow([]);
              setSelectedUser("");
              updateState({ ...iState, AssignStaffMemberModalInt: false });
            } else {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch((error) => {
            toast.error(error.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }
  
  
  };

  const removeAssignIntMember = () => {
    updateState({ ...iState, assignStaffss: "" });
  };

  const handleReminderIntShow = () => {
    updateState({
      ...iState,
      ReminderModalInt: true,
    });
  };

  const handleReminderIntClose = () => {
    updateState({
      ...iState,
      ReminderModalInt: false,
    });
  };

  const handleReminderInt = () => {
    updateState({ ...iState, ReminderModalInt: false, day: day, week: week });
  };

  const handleAddItinerary = () => {
    const types = week ? week : day;
    const reminderDates = calculateDateDifference(types);
    const datta = {
      subject,
      description: descriptions,
      location,
      date,
      type,
      fromTime: `${fromTime}:00`,
      toTime: `${toTime}:00`,
      jobType: "ResidentialJob",
      reminderDate: reminderDates,
      relatedToTask: relatedToTask == true ? true : false,
      relatedToJob: relatedToJob == true ? true : false,
      TaskId: "1",
      // assignStaffs: [{ UserId: Number(assignStaffss) }],
      assignStaffs: [],
    };
    dispatch(getInternityAdd(datta))
      .then((res) => {
        if (res.status === 201) {
          dispatch(
            getInternityList({
              startDate: new Date().toISOString().slice(0, 10),
              endDate: new Date().toISOString().slice(0, 10),
            })
          );
          toast.success("Add Internity Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            subject: "",
            descriptions: "",
            location: "",
            date: "",
            fromTime: "",
            toTime: "",
            type: "",
            reminderDates: "",
            jobType: "ResidentialJob",
            jobId: "",
            relatedToTask: "",
            relatedToJob: "",
            TaskId: "",
            assignStaffss: "",
            AddItineraryModal: false,
            MoveTaskModal: false,
            day: "",
            week: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, AddItineraryModal: true });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, AddItineraryModal: true });
      });
  };

  const handleItinerariesListSettingsModalShow = () => {
    updateState({
      ...iState,
      ItinerariesListSettingsModal: true,
      selectedOption: "",
    });
  };

  const handleItinerariesListSettingsModalClose = () => {
    updateState({
      ...iState,
      ItinerariesListSettingsModal: false,
    });
  };

  const handleRadioChange = (event) => {
    updateState({ ...iState, selectedOption: event.target.value });
  };

  const handleCreateComment = () => {
    const dataa = { content };
    dispatch(
      createTradeComment(investorId, compId?.user?.CompanyId, dataa)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getTradeCommentList(investorId, compId?.user?.CompanyId));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          content: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleRemoveComments = (type) => {
    if (type === "comment") {
      updateState({ ...iState, content: "" });
    } else {
      updateState({ ...iState, subContent: "" });
    }
  };
  const handleSubCreateComment = (id) => {
    const dataa = { content: subContent, parentId: id };
    dispatch(
      createTradeSubComment(investorId, compId?.user?.CompanyId, dataa)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(
          getTradeSubCommentList(investorId, id, compId?.user?.CompanyId)
        );
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          subContent: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleSave = () => {
    let startDate;
    let endDate;
    if (selectedOption === "week") {
      startDate = new Date().toISOString().slice(0, 10);
      endDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 7)
        .toISOString()
        .slice(0, 10);
    } else if (selectedOption === "month") {
      startDate = new Date().toISOString().slice(0, 10);
      endDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 30)
        .toISOString()
        .slice(0, 10);
    } else {
      startDate = new Date().toISOString().slice(0, 10);
      endDate = new Date().toISOString().slice(0, 10);
    }

    const data = {
      startDate: startDate,
      endDate: endDate,
    };

    dispatch(getInternityList(data))
      .then((res) => {
        if (res.status === 200) {
          toast.success("Get Itinerary list Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            ItinerariesListSettingsModal: false,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, ItinerariesListSettingsModal: true });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, ItinerariesListSettingsModal: true });
      });
  };

  const handleIternaryDeleteShow = (id) => {
    updateState({
      ...iState,
      IternaryDeleteModal: true,
      TaskId: id,
    });
  };

  const handleIternaryDeleteClose = () => {
    updateState({
      ...iState,
      IternaryDeleteModal: false,
    });
  };

  const handleIternaryDelete = () => {
    const datta = { TaskId };
    dispatch(getItinertyDelete(datta)).then((res) => {
      if (res.status === 200) {
        dispatch(
          getInternityList({
            startDate: new Date().toISOString().slice(0, 10),
            endDate: new Date().toISOString().slice(0, 10),
          })
        );
        toast.success("Delete Internity Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          TaskId: "",
          IternaryDeleteModal: false,
        });
      }
    });
  };
  const handleGoogleData = (item) => {
    const datta = {
      title: "Sample Event",
      description: item.description,
      assignDate: item.date.split("T")[0] + "T" + item.fromTime.split("T")[1],
      reminderDate: item.date.split("T")[0] + "T" + item.toTime.split("T")[1],
      location: "Sample Location",
      // assignDate: `${moment(item.date).format("YYYY-MM-DD")}${item.fromTime.slice(10)}`,
    };
    dispatch(getGoogleCalendar(datta))
      .then((res) => {
        if (res.status === 200) {
          window.open(res.data.google, "_blank");
          // dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Find data Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleOutLookData = (item) => {
    const datta = {
      title: "Sample Event",
      description: item.description,
      assignDate: item.date.split("T")[0] + "T" + item.fromTime.split("T")[1],
      reminderDate: item.date.split("T")[0] + "T" + item.toTime.split("T")[1],
      location: "Sample Location",
    };
    dispatch(getOutlookCalendar(datta))
      .then((res) => {
        if (res.status === 200) {
          window.open(res.data.outlook, "_blank");
          // dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Find data Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const handleEmailData = (item) => {
    const datta = {
      title: "Sample Event",
      description: item.description,
      assignDate: item.date.split("T")[0] + "T" + item.fromTime.split("T")[1],
      reminderDate: item.date.split("T")[0] + "T" + item.toTime.split("T")[1],
      location: "Sample Location",
    };
    dispatch(getEmailCalendar(datta))
      .then((res) => {
        if (res.status === 200) {
          window.open(res.data.yahoo, "_blank");
          // dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Find data Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleUpdateAssignStaffMemberShow = (item) => {
    updateState({
      ...iState,
      UpdateAssignStaffMemberModal: true,
      TaskId: item.id,
      UserId: item.UserId,
    });
  };

  const handleUpdateAssignStaffMemberClose = () => {
    updateState({
      ...iState,
      UpdateAssignStaffMemberModal: false,
    });
  };

  const handleAssignStaffMember = () => {
    const datta = { assignStaffs: [{ UserId: Number(selectedUser) }] };
    dispatch(iternarayAssignStaffMember(TaskId, datta))
      .then((res) => {
        if (res.status === 200) {
          // dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Assign Staff  Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            UpdateAssignStaffMemberModal: false,
            taskId: "",
            UserId: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            UpdateAssignStaffMemberModal: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          UpdateAssignStaffMemberModal: true,
        });
      });
  };

  const handleUpdateReminderShow = (id) => {
    updateState({
      ...iState,
      UpdateReminderModal: true,
      TaskId: id,
    });
  };

  const handleUpdateReminderClose = () => {
    updateState({
      ...iState,
      UpdateReminderModal: false,
    });
  };

  const handleReminderUpdate = () => {
    const types = week ? week : day;
    const reminderDates = calculateDateDifference(types);
    const datta = { reminderDate: reminderDates };
    dispatch(getUpdateReminder(TaskId, datta))
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            UpdateReminderModal: false,
            day: "",
            week: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, UpdateReminderModal: true });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, UpdateReminderModal: true });
      });
  };

  const handleUpdateItineraryModalShow = (item) => {
    console.log("item", item);
    updateState({
      ...iState,
      UpdateItineraryModal: true,
      subject: item.subject,
      descriptions: item.description,
      location: item.location,
      date: moment(item.date).format("YYYY-MM-DD"),
      fromTime: moment(item.fromTime).format("HH:mm:ss"),
      toTime: moment(item.toTime).format("HH:mm:ss"),
      type: item.type,
      reminderDates: item.reminderDate,
      jobType: item.jobType,
      jobId: item.jobId,
      relatedToJob: item.relatedToJob === true ? true : false,
      relatedToTask: item.relatedToJob === true ? true : false,
      ItrId: item.id,
      // week: "",
      // day: "",
    });
  };

  const handleUpdateItineraryModalClose = () => {
    updateState({
      ...iState,
      UpdateItineraryModal: false,
    });
  };

  const handleUpdateItinerary = () => {
    const types = week ? week : day;
    const reminderDates = calculateDateDifference(types);
    const datta = {
      subject,
      description: descriptions,
      location,
      date,
      fromTime: `${fromTime}:00`,
      toTime: `${toTime}:00`,
      type,
      jobId,
      jobType,
      reminderDate: reminderDates,
      relatedToTask: relatedToTask == true ? true : false,
      relatedToJob: relatedToJob == true ? true : false,
      TaskId: "1",
      assignStaffs: [{ UserId: Number(assignStaffss) }],
    };
    dispatch(getIternarayUpdate(ItrId, datta))
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            getInternityList({
              startDate: new Date().toISOString().slice(0, 10),
              endDate: new Date().toISOString().slice(0, 10),
            })
          );
          toast.success("Updated Internity Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            subject: "",
            descriptions: "",
            location: "",
            date: "",
            fromTime: "",
            toTime: "",
            type: "",
            reminderDates: "",
            jobType: "",
            jobId: "",
            relatedToTask: "",
            relatedToJob: "",
            TaskId: "",
            assignStaffss: "",
            UpdateItineraryModal: false,
            MoveTaskModal: false,
            day: "",
            week: "",
            ItrId: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, UpdateItineraryModal: true });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, UpdateItineraryModal: true });
      });
  };

  const calendarRef = useRef(null);

  const [view, setView] = useState("dayGridMonth");
  const [events, setEvents] = useState([]);
  const handleViewChange = (newView) => {
    setView(newView);
  };

  useEffect(() => {
    // Fetch or generate your dynamic events data based on the selected view (date, week, or month)
    const fetchData = async () => {
      // Replace this with your actual data fetching logic
      const data = await fetchEventData(view);
      console.log("data", data);
      setEvents(data);
    };

    fetchData();
  }, [view]);

  console.log("events", events);

  const fetchEventData = async (currentView) => {
    // Replace this with your actual data fetching logic based on the current view
    // For example, you might fetch events for the current month, week, or day
    const currentDate = new Date();

    // Example: Fetch events for the current month
    if (currentView === "dayGridMonth") {
      const startOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const endOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );

      // Fetch events for the current month
      const monthEvents = await fetchDataForDateRange(startOfMonth, endOfMonth);

      return monthEvents;
    }

    // Example: Fetch events for the current week
    if (currentView === "dayGridWeek") {
      const startOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - currentDate.getDay()
      );
      const endOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + (6 - currentDate.getDay())
      );

      // Fetch events for the current week
      const weekEvents = await fetchDataForDateRange(startOfWeek, endOfWeek);

      return weekEvents;
    }

    // Example: Fetch events for the current day
    const dayEvents = await fetchDataForDate(currentDate);
    return dayEvents;
  };

  const fetchDataForDateRange = async (startDate, endDate) => {
    const datta = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    };
    dispatch(getInternityList(datta))
      .then((res) => {
        console.log("date datatat", res);
        if (res.status === 200) {
          const eventsData =
            res?.data?.itineraries?.map((item) => ({
              title: item.description,
              start: item.date, // Assuming 'date' is the property containing the event date
            })) || [];

          // Set the events state
          setEvents(eventsData);
          toast.success("Find data Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          // toast.error(res.data.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        }
      })
      .catch((error) => {
        // toast.error(error.message, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      });
  };

  const fetchDataForDate = async (date) => {
    const datta = {
      startDate: moment(date).format("YYYY-MM-DD"),
      endDate: moment(date).format("YYYY-MM-DD"),
    };
    dispatch(getInternityList(datta))
      .then((res) => {
        console.log("date datatat", res);
        if (res.status === 200) {
          const eventsData =
            res?.data?.itineraries?.map((item) => ({
              title: item.description,
              start: item.date, // Assuming 'date' is the property containing the event date
            })) || [];

          // Set the events state
          setEvents(eventsData);
          toast.success("Find data Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          // toast.error(res.data.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        }
      })
      .catch((error) => {
        // toast.error(error.message, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      });
  };

  const handleDateSet = (dateInfo) => {
    // Handle "today" button click by resetting the view to the current date
    if (dateInfo.startStr === dateInfo.endStr) {
      setView("dayGridMonth"); // You can set it to the default view you prefer
    }
  };

  //===========================================================================================//
  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
        <p>{eventInfo.event.extendedProps.description}</p>
      </>
    );
  }





  return (
    <>
      <Header />
      <IbuildAdminSidenav />

      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox d-flex align-items-start">
            <div className="TitleBoxUnder">
              <h4 className="Title">
                Itinerary
                <br />
              </h4>
              <span>05/09/2023 - 09:18 AM</span>
            </div>
            <a onClick={() => SetItineraySettingState(true)}>
              <img src="images/Icon-4.png" />
            </a>
          </div>
          <div className="">
            <div className="row">
              <div className="col-sm-5">
                <div className="NotesArea mb-3">
                  <h6>Scheduled Activities for the Day</h6>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="Button">
                    <button
                      className="Create"
                      onClick={handleAddItineraryModalShow}
                    >
                      Add New Itinerary
                    </button>
                  </div>
                  <div>
                    <a href="javascript:void(0);">
                      <img src="images/Action-5.png" />
                    </a>
                    <a
                      onClick={handleItinerariesListSettingsModalShow}
                      className="ml-2"
                    >
                      <img src="images/setting.png" />
                    </a>
                  </div>
                </div>
                {loader ? (
                  <>
                    <Loader />
                  </>
                ) : itineraryList?.itineraries?.length > 0 ? (
                  itineraryList?.itineraries?.map((item, i) => {
                    return (
                      <div className="UserBox">
                        <p>
                          {moment(item.createdAt).format(
                            "YYYY-MM-DD & HH:mm A"
                          )}
                        </p>
                        <div className="UserDetails">
                          <div className="UserTitle">
                            <h6>{item.type}</h6>
                            <div className="UserIcons">
                              <a
                                onClick={() =>
                                  handleUpdateItineraryModalShow(item)
                                }
                              >
                                <img src="images/edit.png" />
                              </a>
                              <a
                                onClick={() =>
                                  handleIternaryDeleteShow(item.id)
                                }
                              >
                                <img src="images/delete.png" />
                              </a>
                              <a href="javascript:void(0);">
                                <img
                                  src="images/add-friend 5.png"
                                  onClick={() =>
                                    handleUpdateAssignStaffMemberShow(item)
                                  }
                                />
                              </a>

                              <a
                                onClick={() =>
                                  handleUpdateReminderShow(item.id)
                                }
                              >
                                <img src="images/timer.png" />
                              </a>
                              <span class="dropdown ">
                                <a data-toggle="dropdown">
                                  <img src="images/calendar.png" />
                                </a>
                                <div
                                  class="dropdown-menu ItineraryDropdown"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <a
                                    class="dropdown-item text-left"
                                    onClick={() => handleGoogleData(item)}
                                  >
                                    <img src="images/google-calendar.png" />
                                    Google Calendar
                                  </a>
                                  <a
                                    class="dropdown-item text-left mx-0"
                                    onClick={() => handleOutLookData(item)}
                                  >
                                    <img src="images/outlook-calendar.png" />
                                    Outlook Calendar
                                  </a>
                                  <a
                                    class="dropdown-item text-left"
                                    onClick={() => handleEmailData(item)}
                                  >
                                    <img src="images/email-calendar.png" />
                                    Yahoo Calendar
                                  </a>
                                </div>
                              </span>
                            </div>
                          </div>
                          <div className="UserName">
                            <h6>
                              User:
                              {/* {item.ItineraryStaffs?.[0]?.User?.email} */}
                            </h6>
                            <span>Location:{item.location}</span>
                          </div>
                        </div>
                        <a
                          href="javascript:void(0);"
                          className="UserBoxOverlay"
                        >
                          <img src="images/video-chat.png" />
                        </a>
                      </div>
                    );
                  })
                ) : (
                  ""
                )}
              </div>
              <div className="col-sm-7">
                <div className="mb-3 NotesArea justify-content-end text-right">
                  <div className="">
                    <a href="javascript:void(0);">
                      <img src="images/pdf.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/download.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/setting.png" />
                    </a>
                  </div>
                </div>
                <div className="CalendarArea">
                  <FullCalendar
                    ref={calendarRef}
                    // weekends={true}
                    events={events}
                    eventContent={renderEventContent}
                    headerToolbar={{
                      start: "today prev next",
                      center: "title",
                      end: "dayGridMonth dayGridWeek dayGridDay",
                    }}
                    plugins={[dayGridPlugin]}
                    views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
                    initialView="dayGridDay"
                    datesSet={(info) => {
                      handleViewChange(info.view.type);
                      handleDateSet(info);
                    }}
                  />
                </div>
           
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={AddItineraryModal}
          onHide={handleAddItineraryModalClose}
          className="ModalBox modal fade MediumModal show"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal ClosePermissionId"
                  onClick={handleAddItineraryModalClose}
                >
                  ×
                </a>

                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Add New Itinerary Item</h4>
                  </div>
                  <div className="FolderForm">
                    <h6>Item #12</h6>

                    <div className="form-group">
                      <h6>Subject</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Executive Meeting"
                        name="subject"
                        value={subject}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <h6>Description</h6>
                      <textarea
                        rows="5"
                        className="form-control"
                        name="descriptions"
                        value={descriptions}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <h6>Location</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Room 1"
                        name="location"
                        value={location}
                        onChange={handleInputChange}
                      />
                    </div>
                    <h6>Select New Date</h6>
                    <div className="Filter row">
                      <div className="form-group col-4">
                        <label>Date Option 1</label>
                        <input
                          type="date"
                          className="form-control"
                          name="date"
                          value={date}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group col-4">
                        <label>From</label>
                        <input
                          type="time"
                          className="form-control"
                          name="fromTime"
                          value={fromTime}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group col-4">
                        <label>To</label>
                        <input
                          type="time"
                          className="form-control"
                          name="toTime"
                          value={toTime}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="Filter row">
                      <div className="form-group col-4">
                        <label>Type</label>
                        <select
                          className="form-control"
                          name="type"
                          value={type}
                          onChange={handleInputChange}
                        >
                          <option value="">Select Type</option>
                          <option value="Meeting">Meeting</option>
                          <option value="Phone Call">Phone Call</option>
                          <option value="Text">Text</option>
                          <option value="Task">Task</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                      <div className="CommonModalArea ml-auto col-4">
                        <div className="form-group">
                          <h5>Set Reminder</h5>
                          <div className="CommonModalBox">
                            <h5
                              className="FolderPermissionId CursorPointer"
                              onClick={handleReminderIntShow}
                            >
                              <figure>
                                <img src="images/timer-red.png" />
                              </figure>{" "}
                              Set Reminder
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6>Itinerary is related to Job or Schedule's Task?</h6>
                    <div className="row">
                      <div className="form-group col-6">
                        <div className="d-flex justify-content-between">
                          <label className="CheckBox">
                            Job
                            <input
                              type="checkbox"
                              onChange={(e) => handleJobTaskChecked(e, "job")}
                              checked={relatedToJob}
                            />
                            <span className="checkmark" />
                          </label>
                          <label className="CheckBox">
                            Schedule
                            <input
                              type="checkbox"
                              onChange={(e) => handleJobTaskChecked(e, "sch")}
                              checked={relatedToTask}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="Categories Categories1 mb-3">
                      <div className="Categories_all Categories_all1">
                        <span>Select a folder to insert the file</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input type="text" placeholder="-Search" />
                          <span className="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>
                        <li>- List of Jobs already filtered</li>
                      </ul>
                    </div>

                    <div className="StaffMemberArea">
                      <div className="row">
                        <div className="col-4">
                          <h6>Assign Staff Member</h6>
                          <div className="StaffName">
                            <img src="images/member.png" />
                            <div className="Count">3</div>
                            <h6
                              onClick={() =>
                                handleAssignStaffMemberIntShow(true)
                              }
                            >
                              Assign
                            </h6>
                          </div>
                        </div>
                        <div className="col-4 ml-auto">
                          <h6>Assign Staff member</h6>
                          <div className="StaffBox">
                            <div className="StaffName">
                              <p>{assignStaffss}</p>
                              <img
                                src="images/Cross.png"
                                onClick={removeAssignIntMember}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Button text-center mt-5">
                      <button
                        className="Create mr-4"
                        onClick={handleAddItinerary}
                      >
                        Create new Itinerary Item
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={UpdateItineraryModal}
          onHide={handleUpdateItineraryModalClose}
          className="ModalBox modal fade MediumModal show"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal ClosePermissionId"
                  onClick={handleUpdateItineraryModalClose}
                >
                  ×
                </a>

                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Edit Itinerary Item</h4>
                  </div>
                  <div className="FolderForm">
                    <h6>Item #12</h6>

                    <div className="form-group">
                      <h6>Subject</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Executive Meeting"
                        name="subject"
                        value={subject}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <h6>Description</h6>
                      <textarea
                        rows="5"
                        className="form-control"
                        name="descriptions"
                        value={descriptions}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <h6>Location</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Room 1"
                        name="location"
                        value={location}
                        onChange={handleInputChange}
                      />
                    </div>
                    <h6>Select New Date</h6>
                    <div className="Filter row">
                      <div className="form-group col-4">
                        <label>Date Option 1</label>
                        <input
                          type="date"
                          className="form-control"
                          name="date"
                          value={date}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group col-4">
                        <label>From</label>
                        <input
                          type="time"
                          className="form-control"
                          name="fromTime"
                          value={fromTime}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group col-4">
                        <label>To</label>
                        <input
                          type="time"
                          className="form-control"
                          name="toTime"
                          value={toTime}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="Filter row">
                      <div className="form-group col-4">
                        <label>Type</label>
                        <select
                          className="form-control"
                          name="type"
                          value={type}
                          onChange={handleInputChange}
                        >
                          <option value="">Select Type</option>
                          <option value="Meeting">Meeting</option>
                          <option value="Phone Call">Phone Call</option>
                          <option value="Text">Text</option>
                          <option value="Task">Task</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                      <div className="CommonModalArea ml-auto col-4">
                        <div className="form-group">
                          <h5>Set Reminder</h5>
                          <div className="CommonModalBox">
                            <h5
                              className="FolderPermissionId CursorPointer"
                              onClick={handleReminderIntShow}
                            >
                              <figure>
                                <img src="images/timer-red.png" />
                              </figure>{" "}
                              Set Reminder
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6>Itinerary is related to Job or Schedule's Task?</h6>
                    <div className="row">
                      <div className="form-group col-6">
                        <div className="d-flex justify-content-between">
                          <label className="CheckBox">
                            Job
                            <input
                              type="checkbox"
                              onChange={(e) => handleJobTaskChecked(e, "job")}
                              checked={relatedToJob}
                            />
                            <span className="checkmark" />
                          </label>
                          <label className="CheckBox">
                            Schedule
                            <input
                              type="checkbox"
                              onChange={(e) => handleJobTaskChecked(e, "sch")}
                              checked={relatedToTask}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="Categories Categories1 mb-3">
                      <div className="Categories_all Categories_all1">
                        <span>Select a folder to insert the file</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input type="text" placeholder="-Search" />
                          <span className="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>
                        <li>- List of Jobs already filtered</li>
                      </ul>
                    </div>

                    <div className="StaffMemberArea">
                      <div className="row">
                        <div className="col-4">
                          <h6>Assign Staff Member</h6>
                          <div className="StaffName">
                            <img src="images/member.png" />
                            <div className="Count">3</div>
                            <h6
                              onClick={() =>
                                handleAssignStaffMemberIntShow(true)
                              }
                            >
                              Assign
                            </h6>
                          </div>
                        </div>
                        <div className="col-4 ml-auto">
                          <h6>Assign Staff member</h6>
                          <div className="StaffBox">
                            <div className="StaffName">
                              <p>{assignStaffss}</p>
                              <img
                                src="images/Cross.png"
                                onClick={removeAssignIntMember}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Button text-center mt-5">
                      <button
                        className="Create mr-4"
                        onClick={handleUpdateItinerary}
                      >
                        Update Itinerary Item
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={ReminderModalInt}
          onHide={handleReminderIntClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleReminderIntClose}>
                  ×
                </a>
                <div className="FolderForm">
                  <div className="form-group">
                    <h6>
                      Weeks <span className="required"></span>
                    </h6>
                    <div className="CommonSelectBox w-100">
                      <select
                        disabled={day}
                        name="week"
                        value={week}
                        onChange={handleInputChange}
                      >
                        <option value="">
                          Select amount of weeks for reminder
                        </option>
                        <option value="7">1</option>
                        <option value="14">2</option>
                        <option value="21">3</option>
                        <option value="28">4</option>
                      </select>
                      <span>
                        <img src="images/down.png" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Days <span className="required"></span>
                    </h6>
                    <div className="CommonSelectBox w-100">
                      <select
                        disabled={week}
                        name="day"
                        value={day}
                        onChange={handleInputChange}
                      >
                        <option value="">
                          Select amount of days for reminder
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                      </select>
                      <span>
                        <img src="images/down.png" />
                      </span>
                    </div>
                  </div>
                  <div className="Button text-center mt-5">
                    <button className="Create" onClick={handleReminderInt}>
                      Set Reminder
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={UpdateReminderModal}
          onHide={handleUpdateReminderClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleUpdateReminderClose}>
                  ×
                </a>
                <div className="FolderForm">
                  <div className="form-group">
                    <h6>
                      Weeks <span className="required"></span>
                    </h6>
                    <div className="CommonSelectBox w-100">
                      <select
                        disabled={day}
                        name="week"
                        value={week}
                        onChange={handleInputChange}
                      >
                        <option value="">
                          Select amount of weeks for reminder
                        </option>
                        <option value="7">1</option>
                        <option value="14">2</option>
                        <option value="21">3</option>
                        <option value="28">4</option>
                      </select>
                      <span>
                        <img src="images/down.png" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Days <span className="required"></span>
                    </h6>
                    <div className="CommonSelectBox w-100">
                      <select
                        disabled={week}
                        name="day"
                        value={day}
                        onChange={handleInputChange}
                      >
                        <option value="">
                          Select amount of days for reminder
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                      </select>
                      <span>
                        <img src="images/down.png" />
                      </span>
                    </div>
                  </div>
                  <div className="Button text-center mt-5">
                    <button className="Create" onClick={handleReminderUpdate}>
                      Set Reminder
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={AssignStaffMemberModalInt}
          onHide={handleAssignStaffMemberIntClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleAssignStaffMemberIntClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Assign Staff Member</h4>
                  </div>
                  <div className="FolderForm">
                    <h3>Staff Member Information</h3>
                    <h6>Name</h6>

                    <div className="Categories Categories1 mb-4">
                      <div className="Categories_all Categories_all1">
                        <span>Assign Staff Member Modal</span>
                        {/* <img src="images/down.png" />      */}
                        <select
                          className="form-control"
                          name="assignStaffss"
                          value={assignStaffss}
                          onChange={handleInputChange}
                        >
                          <option value="">Select </option>
                          {memberList?.staff?.length > 0 &&
                            memberList?.staff?.map((item, i) => {
                              return (
                                <option value={item.id}>{item.email}</option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>View Shared Task List</h6>
                      <div className="PermissionsName">
                        <h5
                          className="CursorPointer FolderPermissionId"
                          // onClick={handleViewSharedTaskModalShow}
                        >
                          <span>
                            <img src="images/share.png" />
                          </span>
                          <div className="Count">3</div>View Shared Task List
                        </h5>
                      </div>
                    </div>
                    <div
                      className="Button text-center mt-5"
                      onClick={handleAssignIn}
                    >
                      <button className="Create mr-4">Assign</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={ItinerariesListSettingsModal}
          onHide={handleItinerariesListSettingsModalClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleItinerariesListSettingsModalClose}
                >
                  ×
                </a>

                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Itineraries List Settings</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="form-group my-5">
                      <h6>Show itinerary items for the client:</h6>
                      <div className="d-flex justify-content-between">
                        {["Day", "Week", "Month"].map(
                          (timeAttribute, index) => (
                            <label className="Radio" key={index}>
                              {timeAttribute}
                              <input
                                type="radio"
                                name="itineraryOption"
                                value={timeAttribute.toLowerCase()}
                                checked={
                                  selectedOption === timeAttribute.toLowerCase()
                                }
                                onChange={handleRadioChange}
                              />
                              <span className="checkmark" />
                            </label>
                          )
                        )}
                      </div>
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button className="Create" onClick={handleSave}>
                        Save
                      </button>
                      <button
                        className="Outline"
                        onClick={handleItinerariesListSettingsModalClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={IternaryDeleteModal}
          onHide={handleIternaryDeleteClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleIternaryDeleteClose}>
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Delete</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="my-5">
                      <h6>Are you sure you want to delete this ?</h6>
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button className="Create" onClick={handleIternaryDelete}>
                        Delete
                      </button>
                      <button
                        className="Outline"
                        onClick={handleIternaryDeleteClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={UpdateAssignStaffMemberModal}
          onHide={handleUpdateAssignStaffMemberClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleUpdateAssignStaffMemberClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Update Assign Staff Member</h4>
                  </div>
                  <div className="FolderForm">
                    <h3> Staff Member Information</h3>
                    <h6>Name</h6>

                    <div className="Categories Categories1 mb-4">
                      <div className="Categories_all Categories_all1">
                        <span>Assign Staff</span>
                        </div>
                        <select

                          onChange={(e) => setSelectedUser(e.target.value)}
                          className="form-control"
                        >
                          <option value="">Select users</option>
                          {adminUserList?.users?.map((ele, i) => {
                            return (
                              <>
                                <option value={ele?.id}> {ele?.name}</option>
                              </>
                            );
                          })}
                        </select>
                    </div>
                    <div className="form-group">
                      <h6>View Shared Task List</h6>
                      <div className="PermissionsName">
                        <h5
                          className="CursorPointer FolderPermissionId"
                          onClick={()=>{SetViewSharedItineraryState(true)
                          updateState({...iState,UpdateAssignStaffMemberModal:false})}

                        }
                        
                        >
                          <span>
                            <img src="images/share.png" />
                          </span>
                          <div className="Count">3</div>View Shared Task List
                        </h5>
                      </div>
                    </div>
                    <div
                      className="Button text-center mt-5"
                      onClick={handleAssignStaffMember}
                    >
                      <button className="Create mr-4">Assign</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

    {ViewSharedItineraryState && <ViewSharedItineraryModal ViewSharedItineraryState={ViewSharedItineraryState} SetViewSharedItineraryState={SetViewSharedItineraryState} userId={selectedUser} updateState={updateState} />}
      </div>
      {
        <ItinerarySettingModal
          ItineraySettingState={ItineraySettingState}
          SetItineraySettingState={SetItineraySettingState}
        />
      }
    </>
  );
};

export default IbuildAdminItinerary;
