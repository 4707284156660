
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

import { toast } from "react-toastify";

const units = []


const LandDevPhaseUpdate = ({landDevPhaseUpdateModal, setLandDevPhaseUpdateModal}) => {

  return (

    <Modal show={landDevPhaseUpdateModal} className="ModalBox LargeModal">
    <div class="modal-content BgCreate">
      <a
        class="CloseModal"
        onClick={()=> setLandDevPhaseUpdateModal(false)}
        data-dismiss="modal"
      >
        ×
      </a>
      <div class="modal-content">
          <a href="javascript:void(0);" class="CloseModal" data-toggle="modal" data-target="#LandDevelopmentDetailsLot" data-dismiss="modal">×</a>
          <div class="FormArea">
            <div class="ModalTitleBox GrayModalTitle">
              <h4>Land Development Details</h4>
            </div>
            <div class="FolderForm">
              <div class="ResidentialJobArea ResidentialJobAreaPara">

                <div class="ResidentialJobHead">
                  <h3>
                    U10-HIL-2B-381 (Lot #10)
                    <span class="dropdown">
                      <a class="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src="images/dots.png" />
                      </a>
                      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" href="javascript:void(0);"><img src="images/archive_job.png" />Archive
                          Job</a>
                      </div>
                    </span>
                  </h3>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <h6>Created By</h6>
                        <p>John Doe</p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <h6>Creation Date</h6>
                        <p>04/15/2023</p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <h6>Last Update</h6>
                        <p>07/10/2023</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="ModalAccordian">
                  <div id="accordion">

                    <div class="card">
                      <div class="card-header collapsed" data-toggle="collapse" href="#GeneralDetails" aria-expanded="false">
                        <h4><span><img src="images/general_details.png" /></span>General Details</h4>

                      </div>
                      <div id="GeneralDetails" class="collapse" data-parent="#accordion" style="">

                        <div class="card-body">
                          <form>
                            <div class="row">
                              <div class="col-sm-5">
                                <div class="form-group">
                                  <h6>Project</h6>
                                  <p>Project 1</p>
                                </div>
                                <div class="form-group">
                                  <h6>Home Type</h6>
                                  <p>2-Storey</p>
                                </div>
                                <div class="form-group">
                                  <h6>Track Share Cost Scheme</h6>
                                  <p>Square Foot Percentage</p>
                                </div>
                                <div class="form-group">
                                  <h6>Subdivision</h6>
                                  <p>Delta</p>
                                </div>
                                <div class="form-group">
                                  <h6>Building Permit #</h6>
                                  <p>BP2023-11766</p>
                                </div>
                                <div class="form-group">
                                  <h6>Accounting Status</h6>
                                  <h6 class="fw-400">Integrate with Accounting Software</h6>
                                </div>




                              </div>
                              <div class="col-sm-5 ml-auto">
                                <div class="form-group">
                                  <h6>Specification Document</h6>
                                  <p>Standard Specifications</p>
                                </div>
                                <div class="form-group">
                                  <h6>Job Site GMT (UTC) Timezone</h6>
                                  <p>Moutain Time GMT - 06 1</p>
                                </div>

                                <div class="form-group">
                                  <h6>Tax Profile</h6>
                                  <p>Alberta Profile</p>
                                </div>
                                <div class="form-group">
                                  <h6>Default Currency</h6>
                                  <p>CAD</p>
                                </div>
                                <div class="form-group">
                                  <h6>Tract SqFt</h6>
                                  <p>150,000.00</p>
                                </div>


                              </div>
                            </div>
                            <div class="Button d-flex justify-content-center mt-4">
                              <button class="OutlineBlack mr-3">Cancel</button>
                              <button class="Respond ml-3">Save</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" data-toggle="collapse" href="#LandTractsPhasesLots" aria-expanded="true">
                        <h4><span><img src="images/land_track_phase.png" /></span>Land Tracts/Phases &amp; Lots</h4>
                        <a class="Edit" href="javascript:void(0);">
                          <img src="images/Action-1.png" />
                        </a>
                      </div>
                      <div id="LandTractsPhasesLots" class="collapse show" data-parent="#accordion" style="">

                        <div class="card-body">
                          <form>
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <h6>Land Tracts/ Phases</h6>
                                  <p>Land Tracts/ Phase</p>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="TodosButtons mt-0 text-right">
                                  <a href="javascript:void(0)" class="FolderPermissionId" data-toggle="modal" data-target="#CreateLandDevelopmentLots2" data-dismiss="modal"><img src="images/create_lots.png" />Create Lots
                                  </a>
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-md-5">
                                <div class="form-group">
                                  <h6>Lot#</h6>
                                  <input type="text" class="form-control" placeholder="10" />
                                </div>
                                <div class="form-group">
                                  <h6>Lot Type</h6>
                                  <div class="CommonSelectBox">
                                    <select>
                                      <option selected="">Residential</option>
                                      <option>Residential 1</option>
                                    </select>
                                    <span><img src="images/down.png" /></span>
                                  </div>
                                </div>

                              </div>
                              <div class="col-md-5 ml-auto">
                                <div class="form-group">
                                  <h6>Lot Description</h6>
                                  <input type="text" class="form-control" placeholder="Residential Lot 10" />
                                </div>
                              </div>
                            </div>

                            <div class="Button d-flex justify-content-center mt-4">
                              <button class="OutlineBlack mr-3">Cancel</button>
                              <button class="Respond ml-3">Save</button>
                            </div>






                          </form>

                        </div>
                      </div>
                    </div>


                    <div class="card">
                      <div class="card-header" data-toggle="collapse" href="#BusinessAddress">
                        <h4><span><img src="images/business_address.png" /></span>Business Address</h4>

                      </div>
                      <div id="BusinessAddress" class="collapse show" data-parent="#accordion">

                        <div class="card-body">
                          <form>
                            <div class="row">
                              <div class="col-sm-5">
                                <div class="form-group">
                                  <h6>Job Address</h6>
                                  <p>Street 1</p>
                                </div>
                                <div class="form-group">
                                  <h6>Lot</h6>
                                </div>
                                <div class="form-group">
                                  <h6>Block</h6>
                                </div>
                                <div class="form-group mb-0">
                                  <h6>Phase</h6>
                                  <p>Insert Phase</p>
                                </div>


                              </div>
                              <div class="col-sm-5 ml-auto">
                                <div class="form-group">
                                  <h6>City</h6>
                                  <p>Calgary</p>
                                </div>
                                <div class="form-group">
                                  <h6>Zip/ Postal Code</h6>
                                  <p>T3A T3A</p>
                                </div>
                                <div class="form-group">
                                  <h6>Country</h6>
                                  <p>Canada</p>
                                </div>
                                <div class="form-group mb-0">
                                  <h6>State/Province</h6>
                                  <p>AB</p>
                                </div>

                              </div>
                            </div>
                            <div class="Button d-flex justify-content-center mt-4">
                              <button class="OutlineBlack mr-3">Cancel</button>
                              <button class="Respond ml-3">Save</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>


                    <div class="card">
                      <div class="card-header" data-toggle="collapse" href="#ClientInformation">
                        <h4><span><img src="images/estimation_information.png" /></span>Client Information</h4>


                      </div>
                      <div id="ClientInformation" class="collapse show" data-parent="#accordion">

                        <div class="card-body">
                          <form>
                            <div class="row">
                              <div class="col-md-6">
                                <h6>Personal Information</h6>
                                <div class="form-group">
                                  <h6>Client Full Legal name</h6>
                                  <p>Joe Full Client</p>
                                </div>
                                <div class="form-group">
                                  <h6>Primary Phone Number</h6>
                                  <p>(587)555-555</p>
                                </div>
                                <div class="form-group">
                                  <h6>Email</h6>
                                  <p>joe.fclient@email.com</p>
                                </div>
                                <div class="form-group">
                                  <h6>Business Phone Number</h6>
                                </div>
                                <div class="form-group">
                                  <h6>Fax Phone Number</h6>
                                </div>
                                <div class="form-group">
                                  <h6>Preferred Communication</h6>
                                  <p>Email</p>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <h6>Address</h6>
                                <div class="form-group">
                                  <h6>Street Address</h6>
                                  <p>120 4th Street Sw</p>
                                </div>
                                <div class="form-group">
                                  <h6>Address Line two</h6>
                                </div>
                                <div class="form-group">
                                  <h6>City</h6>
                                  <p>Calgary</p>
                                </div>
                                <div class="form-group">
                                  <h6>Zip/Postal Code</h6>
                                  <p>T3A T3A</p>
                                </div>
                                <div class="form-group">
                                  <h6>Country</h6>
                                  <p>Canada</p>
                                </div>
                                <div class="form-group">
                                  <h6>State/Province</h6>
                                  <p>Select State or Province</p>
                                </div>
                              </div>
                            </div>
                            <div class="Button d-flex justify-content-center mt-4">
                              <button class="OutlineBlack mr-3">Cancel</button>
                              <button class="Respond ml-3">Save</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>







                  </div>
                  <div class="CloseButton">
                    <button class="Close" data-toggle="modal" data-target="#LandDevelopmentDetailsLot" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>

          </div>


        </div>
    </div>
  </Modal>


)
}

export default LandDevPhaseUpdate