import React from "react";
import Modal from "react-bootstrap/Modal";

const TodoView = ({ TodoViewModal, SetTodoViewModal, element }) => {
  const handleTodoViewModalClose = () => {
    SetTodoViewModal(false);
  };
  return (
    <Modal show={TodoViewModal} className="ModalBox SmallModal BgClass">
      <a
        onClick={handleTodoViewModalClose}
        class="CloseModal ClosePermissionId"
      >
        ×
      </a>
      <div class="FormArea">
              <div class="ModalTitleBox">
                <h4>View Notification</h4>
              </div>
              <div class="FolderForm">
                <form>
                   <div class="form-group">
                    <h6>Event</h6>
                    <h6 class="fw-400 fw-14">{element?.eventName}</h6>
                    </div>
                   <div class="form-group">
                    <h6>Notification Type</h6>
                    <p class="ml-0">Todo</p>
                   </div>
                   <div class="form-group">
                    <h6>Assigned Users</h6>
                   </div>
                   <div class="form-group">
                    <h6>Assigned User Roles</h6>
                    <p class="ml-0">Estimator</p>
                   </div>
                   <div class="form-group">
                    <h6>Originating User Only</h6>
                    <p class="ml-0">On</p>
                   </div>
                   <div class="ModalBigheading">
                    <h3><span>Notification Details</span></h3>
                   </div>
                   <div class="ModalBigheading">
                    <h3><span>Description</span></h3>
                   </div>
                  <div class="QuotedArea">
                    <h4><span><img src="images/insert_keys.png" /></span>Quoteld</h4>
                    <h4><span><img src="images/insert_keys.png" /></span>JobId has been returned by</h4>
                    <h4><span><img src="images/insert_keys.png" /></span>Vendor Name</h4>
                  </div>
                    <div class="form-group">
                      <h6>Assign Related Vendor</h6>
                      <p class="ml-0">Off</p>
                    </div>
                    <div class="CommonModalArea">
                      <div class="form-group">
                        <h5>Set Reminder</h5>
                        <div class="CommonModalBox">
                          <h5>
                            <figure><img src="images/timer.png" class="mr-2" /></figure>3 days
                          </h5>
                        </div>
                      </div>
                      </div>
                    <div class="Button text-center mt-5">
                      <button class="Outline">Close</button>
                    </div>
                </form>
              </div>
            </div>
    </Modal>
  );
};

export default TodoView;
