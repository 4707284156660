import axios from "axios";
import { AdminMenuSetupConstant } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config/Config";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

export const getSideMenuInitate = () => ({
  type: AdminMenuSetupConstant.GET_SIDE_MENU_INITIATE,
});

export const getSideMenuSuccess = (data) => ({
  type: AdminMenuSetupConstant.GET_SIDE_MENU_SUCCESS,
  payload: data,
});

export const getSideMenuFailure = (data) => ({
  type: AdminMenuSetupConstant.GET_SIDE_MENU_FAILURE,
  payload: data,
});

export function getSideMenuAction(pid) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getSideMenuInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.id}/admin/side-menu`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getSideMenuSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getSideMenuFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getSideMenuSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getSideMenuFailure(err));
            reject(err);
          }
        });
    });
  };
}

export const createSideMenuInitiate = () => ({
  type: AdminMenuSetupConstant.CREATE_SIDE_MENU_INITIATE,
});

export const createSideMenuSuccess = (data) => ({
  type: AdminMenuSetupConstant.CREATE_SIDE_MENU_SUCCESS,
  payload: data,
});

export const createSideMenuFailure = (data) => ({
  type: AdminMenuSetupConstant.CREATE_SIDE_MENU_FAILURE,
  payload: data,
});

export const createSideMenuAction =
  (folderId, id, payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createSideMenuSuccess(payload));
      const response = await axios.post(
        `${Url}companies/${iBuildLocalData?.user?.id}/admin/side-menu`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        dispatch(createSideMenuSuccess(response.data));
      } else {
        dispatch(createSideMenuFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createSideMenuFailure(err));
      return err.response;
    }
  };

export const updateSideMenuInitiate = () => ({
  type: AdminMenuSetupConstant.UPDATE_SIDE_MENU_INITIATE,
});

export const updateSideMenuSuccess = (data) => ({
  type: AdminMenuSetupConstant.UPDATE_SIDE_MENU_SUCCESS,
  payload: data,
});

export const updateSideMenuFailure = (data) => ({
  type: AdminMenuSetupConstant.UPDATE_SIDE_MENU_FAILURE,
  payload: data,
});

export const updateSideMenuAction = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(createSideMenuSuccess(payload));
    const response = await axios.put(
      `${Url}companies/${iBuildLocalData?.user?.id}/admin/side-menu`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` }, 
      }
    );
    if (response.status === 200) {
      dispatch(updateSideMenuSuccess(response.data));
    } else {
      dispatch(updateSideMenuFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(updateSideMenuFailure(err));
    return err.response;
  }
};




export const getJobMenuInitate = () => ({
    type: AdminMenuSetupConstant.GET_JOB_MENU_INITIATE,
  });
  
  export const getJobMenuSuccess = (data) => ({
    type: AdminMenuSetupConstant.GET_JOB_MENU_SUCCESS,
    payload: data,
  });
  
  export const getJobMenuFailure = (data) => ({
    type: AdminMenuSetupConstant.GET_JOB_MENU_FAILURE,
    payload: data,
  });
  
  export function getJobMenuAction(pid) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(getJobMenuInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}companies/${iBuildLocalData?.user?.id}/admin/job-menu`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            ////console.log("data", data);
            if (data && data.status == 200) {
              dispatch(getJobMenuSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(getJobMenuFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if (err.response.status === 404) {
              dispatch(getJobMenuSuccess(err.data));
              resolve(err.data);
            } else {
              dispatch(getJobMenuFailure(err));
              reject(err);
            }
          });
      });
    };
  }
  
  export const createJobMenuInitiate = () => ({
    type: AdminMenuSetupConstant.CREATE_JOB_MENU_INITIATE,
  });
  
  export const createJobMenuSuccess = (data) => ({
    type: AdminMenuSetupConstant.CREATE_JOB_MENU_SUCCESS,
    payload: data,
  });
  
  export const createJobMenuFailure = (data) => ({
    type: AdminMenuSetupConstant.CREATE_JOB_MENU_FAILURE,
    payload: data,
  });
  
  export const createJobMenuAction =
    (folderId, id, payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(createJobMenuSuccess(payload));
        const response = await axios.post(
          `${Url}companies/${iBuildLocalData?.user?.id}/admin/job-menu`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.status === 200) {
          dispatch(createJobMenuSuccess(response.data));
        } else {
          dispatch(createJobMenuFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(createJobMenuFailure(err));
        return err.response;
      }
    };
  
  export const updateJobMenuInitiate = () => ({
    type: AdminMenuSetupConstant.UPDATE_JOB_MENU_INITIATE,
  });
  
  export const updateJobMenuSuccess = (data) => ({
    type: AdminMenuSetupConstant.UPDATE_JOB_MENU_SUCCESS,
    payload: data,
  });
  
  export const updatejobMenuFailure = (data) => ({
    type: AdminMenuSetupConstant.UPDATE_JOB_MENU_FAILURE,
    payload: data,
  });
  
  export const updateJobMenuAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(updateJobMenuSuccess(payload));
      const response = await axios.put(
        `${Url}companies/${iBuildLocalData?.user?.id}/admin/job-menu`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` }, 
        }
      );
      if (response.status === 200) {
        dispatch(updateJobMenuSuccess(response.data));
      } else {
        dispatch(updatejobMenuFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updatejobMenuFailure(err));
      return err.response;
    }
  };
  
