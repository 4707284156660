import axios from "axios";
import { ClientDocumentationActionTypes } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config/Config";
import { multiPartData } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

export const getAllClientDocsListInitate = () => ({
  type: ClientDocumentationActionTypes.CLIENT_LIST_INITIATE,
});

export const getAllClientDocsListSuccess = (data) => ({
  type: ClientDocumentationActionTypes.CLIENT_LIST_SUCCESS,
  payload: data,
});

export const getAllClientDocsListFailure = (data) => ({
  type: ClientDocumentationActionTypes.CLIENT_LIST_FAILURE,
  payload: data,
});

export function getAllClientDocsListAction(payload, id , type) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllClientDocsListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/client-docs?contracttableId=${id}&contracttableType=${type}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllClientDocsListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllClientDocsListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(getAllClientDocsListFailure(err));
          reject(err);
        });
    });
  };
}

export const addClientDocsInitate = () => ({
  type: ClientDocumentationActionTypes.CREATE_CLIENT_DOCS_INITIATE,
});

export const addClientDocsSuccess = (data) => ({
  type: ClientDocumentationActionTypes.CREATE_CLIENT_DOCS_SUCCESS,
  payload: data,
});

export const addClientDocsFailure = (data) => ({
  type: ClientDocumentationActionTypes.CREATE_CLIENT_DOCS_FAILURE,
  payload: data,
});

export function addClientDocsAction(payload, folderId, id, type) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(addClientDocsInitate());
    return new Promise((resolve, reject) => {
      axios
      // companies/2/ClientDocs
        .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/client-docs?${id}=2&contracttableType=${type}`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201) {
            dispatch(addClientDocsSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addClientDocsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addClientDocsFailure(err));
          reject(err);
        });
    });
  };
}

export const searchByNameInitate = () => ({
  type: ClientDocumentationActionTypes.SEARCH_BY_NAME_INITIATE,
});

export const searchByNameSuccess = (data) => ({
  type: ClientDocumentationActionTypes.SEARCH_BY_NAME_SUCCESS,
  payload: data,
});

export const searchByNameFailure = (data) => ({
  type: ClientDocumentationActionTypes.SEARCH_BY_NAME_FAILURE,
  payload: data,
});

export function searchByNameAction(payload, folderId, name,id,type) {
  const token = isLoggedIn("adminData1");
//   const updatedData = multiPartData({ file: payload.file, ...payload }); // Modify this line

  return (dispatch) => {
    dispatch(searchByNameInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}companies/${
            iBuildLocalData?.user?.CompanyId ? iBuildLocalData?.user?.CompanyId : ""
          }/folders/${folderId}/client-docs-search?name=${name}tracttableId=${id}&contracttableType=${type}`,
          payload,
          {
            headers: { Authorization: ` Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(searchByNameSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(searchByNameFailure(data.data));
            reject(data.data);
          }
        })
        .catch((err) => {
          dispatch(searchByNameFailure(err));
          reject(err);
        });
    });
  };
}

export const removeClientDocsInitate = () => ({
  type: ClientDocumentationActionTypes.DELETE_CLIENT_DOCS_INITIATE,
});

export const removeClientDocsSuccess = (data) => ({
  type: ClientDocumentationActionTypes.DELETE_CLIENT_DOCS_SUCCESS,
  payload: data,
});

export const removeClientDocsFailure = (data) => ({
  type: ClientDocumentationActionTypes.DELETE_CLIENT_DOCS_FAILURE,
  payload: data,
});

export function removeClientDocsAction( folderId, clientDocs,  id, type) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(removeClientDocsInitate());
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/client-docs/${clientDocs}?contracttableId=${id}&contracttableType=${type}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 200) || 201) {
            dispatch(removeClientDocsSuccess(data));
            resolve(data);
          } else {
            dispatch(removeClientDocsFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(removeClientDocsFailure(err));
          reject(err);
        });
    });
  };
}

export const updateClientDocsInitate = () => ({
  type: ClientDocumentationActionTypes.UPDATE_CLIENT_DOCS_INITIATE,
});

export const updateClientDocsSuccess = (data) => ({
  type: ClientDocumentationActionTypes.UPDATE_CLIENT_DOCS_SUCCESS,
  payload: data,
});

export const updateClientDocsFailure = (data) => ({
  type: ClientDocumentationActionTypes.UPDATE_CLIENT_DOCS_SUCCESS,
  payload: data,
});

export function updateClientDocsAction(payload, params) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(updateClientDocsInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/ClientDocss/${params}`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(updateClientDocsSuccess(data));
            resolve(data);
          } else {
            dispatch(updateClientDocsFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateClientDocsFailure(err));
          reject(err);
        });
    });
  };
}




export const getAllFolderListInitate = () => ({
    type: ClientDocumentationActionTypes.FOLDER_LIST_INITIATE,
  });
  
  export const getAllFolderListSuccess = (data) => ({
    type: ClientDocumentationActionTypes.FOLDER_LIST_SUCCESS,
    payload: data,
  });
  
  export const getAllFolderListFailure = (data) => ({
    type: ClientDocumentationActionTypes.FOLDER_LIST_FAILURE,
    payload: data,
  });
  
  export function getAllFolderListAction( id , type) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(getAllFolderListInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders?foldertableId=${id}&foldertableType=${type}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            ////console.log("data", data);
            if (data && data.status == 200) {
              dispatch(getAllFolderListSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(getAllFolderListFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            dispatch(getAllFolderListFailure(err));
            reject(err);
          });
      });
    };
  }
  
  export const addFolderInitate = () => ({
    type: ClientDocumentationActionTypes.ADD_FOLDER_INITIATE,
  });
  
  export const addFolderSuccess = (data) => ({
    type: ClientDocumentationActionTypes.ADD_FOLDER_SUCCESS,
    payload: data,
  });
  
  export const addFolderFailure = (data) => ({
    type: ClientDocumentationActionTypes.ADD_FOLDER_FAILURE,
    payload: data,
  });
  
  export function addFolderAction(payload, id, type) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(addFolderInitate());
      return new Promise((resolve, reject) => {
        axios
        // companies/2/Folder
          .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders?foldertableId=${id}&foldertableType=${type}`, payload, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            ////console.log("data", response);
            if (data && data.status == 201) {
              dispatch(addFolderSuccess(data.data));
              resolve(data);
            } else {
              dispatch(addFolderFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            dispatch(addFolderFailure(err));
            reject(err);
          });
      });
    };
  }
  
  export const searchFolderListInitate = () => ({
    type: ClientDocumentationActionTypes.SEARCH_FOLDER_LIST_INITIATE,
  });
  
  export const searchFolderListSuccess = (data) => ({
    type: ClientDocumentationActionTypes.SEARCH_FOLDER_LIST_SUCCESS,
    payload: data,
  });
  
  export const searchFolderListFailure = (data) => ({
    type: ClientDocumentationActionTypes.SEARCH_FOLDER_LIST_FAILURE,
    payload: data,
  });
  
  export function searchFolderListAction( id,type,name) {
    const token = isLoggedIn("adminData1");
 
    return (dispatch) => {
      dispatch(searchFolderListInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${Url}companies/${
              iBuildLocalData?.user?.CompanyId ? iBuildLocalData?.user?.CompanyId : ""
            }/folders?name=${name? name:""}&foldertableId=${id}&foldertableType=${type}`,           
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            const data = response;
            if (data && data.status == 200 || 201) {
              dispatch(searchFolderListSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(searchFolderListFailure(data.data));
              reject(data.data);
            }
          })
          .catch((err) => {
            dispatch(searchFolderListFailure(err));
            reject(err);
          });
      });
    };
  }
  
  export const removeFolderInitate = () => ({
    type: ClientDocumentationActionTypes.DELETE_CLIENT_DOCS_INITIATE,
  });
  
  export const removeFolderSuccess = (data) => ({
    type: ClientDocumentationActionTypes.DELETE_CLIENT_DOCS_SUCCESS,
    payload: data,
  });
  
  export const removeFolderFailure = (data) => ({
    type: ClientDocumentationActionTypes.DELETE_CLIENT_DOCS_FAILURE,
    payload: data,
  });
  
  export function removeFolderAction( folderId,  id, type) {
    const token = isLoggedIn("adminData1");
    return (dispatch) => {
      dispatch(removeFolderInitate());
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}?foldertableId=${id}&foldertableType=${type}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            const data = response.data;
            if ((data && data.status == 200) || 201) {
              dispatch(removeFolderSuccess(data));
              resolve(data);
            } else {
              dispatch(removeFolderFailure(data));
              reject(data);
            }
          })
          .catch((err) => {
            dispatch(removeFolderFailure(err));
            reject(err);
          });
      });
    };
  }
  
  export const updateFolderInitate = () => ({
    type: ClientDocumentationActionTypes.UPDATE_CLIENT_DOCS_INITIATE,
  });
  
  export const updateFolderSuccess = (data) => ({
    type: ClientDocumentationActionTypes.UPDATE_CLIENT_DOCS_SUCCESS,
    payload: data,
  });
  
  export const updateFolderFailure = (data) => ({
    type: ClientDocumentationActionTypes.UPDATE_CLIENT_DOCS_SUCCESS,
    payload: data,
  });
  
  export function updateFolderAction(payload, folderId, id ,type) {
    const token = isLoggedIn("adminData1");
    return (dispatch) => {
      dispatch(updateFolderInitate());
      return new Promise((resolve, reject) => {
        // ${payload?payload:""}
        axios
          .put(
            `${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}?foldertableId=${id}&foldertableType=${type}`,
            payload,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            const data = response.data;
            if ((data && data.status == 201) || 200) {
              dispatch(updateFolderSuccess(data));
              resolve(data);
            } else {
              dispatch(updateFolderFailure(data));
              reject(data);
            }
          })
          .catch((err) => {
            dispatch(updateFolderFailure(err));
            reject(err);
          });
      });
    };
  }


export const getAllFolderDetailsInitate = () => ({
    type: ClientDocumentationActionTypes.FOLDER_LIST_INITIATE,
  });
  
  export const getAllFolderDetailsSuccess = (data) => ({
    type: ClientDocumentationActionTypes.FOLDER_LIST_SUCCESS,
    payload: data,
  });
  
  export const getAllFolderDetailsFailure = (data) => ({
    type: ClientDocumentationActionTypes.FOLDER_LIST_FAILURE,
    payload: data,
  });
  
  export function getAllFolderDetailsAction( id , type) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(getAllFolderDetailsInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders?foldertableId=${id}&foldertableType=${type}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            ////console.log("data", data);
            if (data && data.status == 200 || 201) {
              dispatch(getAllFolderDetailsSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(getAllFolderDetailsFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            console.log("==errerr=====>", err)
            if(err.response.status === 404){
              dispatch(getAllFolderDetailsSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(getAllFolderDetailsFailure(err));
              reject(err);
            }
          });
      });
    };
  }
  

  
export const folderHistoryInitate = () => ({
    type: ClientDocumentationActionTypes.FOLDER_HISTORY_LIST_INITIATE,
  });
  
  export const folderHistorySuccess = (data) => ({
    type: ClientDocumentationActionTypes.FOLDER_HISTORY_LIST_SUCCESS,
    payload: data,
  });
  
  export const folderHistoryFailure = (data) => ({
    type: ClientDocumentationActionTypes.FOLDER_HISTORY_LIST_FAILURE,
    payload: data,
  });
  
  export function folderHistoryAction( folderId, id , type) {
    const token = isLoggedIn("adminData1");
    return (dispatch) => {
      dispatch(folderHistoryInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/history?foldertableId=2&foldertableType=Contract`, {
            headers: { Authorization: `Bearer ${token}`},
          })
          .then((response) => {
            const data = response;
            ////console.log("data", data);
            if (data && data.status == 200 || 201) {
              dispatch(folderHistorySuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(folderHistoryFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            console.log("==errerr=====>", err)
            if(err.response.status === 404){
              dispatch(folderHistorySuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(folderHistoryFailure(err));
              reject(err);
            }
          });
      });
    };
  }
  

  
export const getAllPendingFileListInitate = () => ({
  type: ClientDocumentationActionTypes.PENDING_FILE_LIST_INITIATE,
});

export const getAllPendingFileListSuccess = (data) => ({
  type: ClientDocumentationActionTypes.PENDING_FILE_LIST_SUCCESS,
  payload: data,
});

export const getAllPendingFileListFailure = (data) => ({
  type: ClientDocumentationActionTypes.PENDING_FILE_LIST_FAILURE,
  payload: data,
});

export function getAllPendingFileListAction(contractId) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllPendingFileListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/sales-pending-events/${contractId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllPendingFileListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllPendingFileListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("==errerr=====>", err)
          if(err.response.status === 404){
            dispatch(getAllPendingFileListSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getAllPendingFileListFailure(err));
            reject(err);
          }
         
       
        });
    });
  };
}


export const removePendingFileListInitate = () => ({
  type: ClientDocumentationActionTypes.REMOVE_PENDING_FILE_INITIATE,
});

export const removePendingFileListSuccess = (data) => ({
  type: ClientDocumentationActionTypes.REMOVE_PENDING_FILE_SUCCESS,
  payload: data,
});

export const removePendingFileListFailure = (data) => ({
  type: ClientDocumentationActionTypes.REMOVE_PENDING_FILE_FAILURE,
  payload: data,
});

export function removePendingFileListAction(id,type) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(removePendingFileListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/11/pending-file-events?contracttableId=${id}&contracttableType=${type}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(removePendingFileListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(removePendingFileListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(removePendingFileListFailure(err));
          reject(err);
        });
    });
  };
}



export const addFilesInitate = () => ({
  type: ClientDocumentationActionTypes.ADD_FILES_INITIATE,
});

export const addFilesSuccess = (data) => ({
  type: ClientDocumentationActionTypes.ADD_FILES_SUCCESS,
  payload: data,
});

export const addFilesFailure = (data) => ({
  type: ClientDocumentationActionTypes.ADD_FILES_FAILURE,
  payload: data,
});

export function addFilesAction(payload, folderId, id, type) {
  // console.log("ppppppppppppp, , pa", payload)
  const token = isLoggedIn("adminData1");
  const updatedData = multiPartData({ files: payload.files, ...payload })
  return (dispatch) => {
    dispatch(addFilesInitate());
    return new Promise((resolve, reject) => {
      axios
      // companies/2/Files
        .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/client-docs?contracttableId=${id}&contracttableType=${type}`, updatedData, {
          headers: { Authorization: `Bearer ${token}` },

        })
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201) {
            dispatch(addFilesSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addFilesFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addFilesFailure(err));
          reject(err);
        });
    });
  };
}



export const getAllFileListInitate = () => ({
  type: ClientDocumentationActionTypes.FILES_LIST_INITIATE,
});

export const getAllFileListSuccess = (data) => ({
  type: ClientDocumentationActionTypes.FILES_LIST_SUCCESS,
  payload: data,
});

export const getAllFileListFailure = (data) => ({
  type: ClientDocumentationActionTypes.FILES_LIST_FAILURE,
  payload: data,
});

export function getAllFileListAction(FID,id,type ,name) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(getAllFileListInitate());
    return new Promise((resolve, reject) => {
      
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${FID}/client-docs-search?name=${name ? name :""}&contracttableId=${id}&contracttableType=${type}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data &&( data.status == 200)) {
            dispatch(getAllFileListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllFileListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("==errerr=====>", err)
          if(err.response.status === 404){
            dispatch(getAllFileListSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getAllFileListFailure(err));
            reject(err);
          }
         
        });
    });
  };
}








export const changeStatusInitate = () => ({
  type: ClientDocumentationActionTypes.CHANGE_STATUS_CLIENT_INITIATE,
});

export const changeStatusSuccess = (data) => ({
  type: ClientDocumentationActionTypes.CHANGE_STATUS_CLIENT_SUCCESS,
  payload: data,
});

export const changeStatusFailure = (data) => ({
  type: ClientDocumentationActionTypes.CHANGE_STATUS_CLIENT_FAILURE,
  payload: data,
});

export function changeStatusAction(id,secId, payload) {
  console.log(payload)
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(changeStatusInitate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${id}/client-docs/${secId}/client-view?contracttableId=2&contracttableType=Contract`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(changeStatusSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(changeStatusFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(changeStatusFailure(err));
          reject(err);
        });
    });
  };
}



export const getClientUserListInitate = () => ({
  type: ClientDocumentationActionTypes.CLIENT_USERS_LIST_INITIATE,
});

export const getClientUserListSuccess = (data) => ({
  type: ClientDocumentationActionTypes.CLIENT_USERS_LIST_SUCCESS,
  payload: data,
});

export const getClientUserListFailure = (data) => ({
  type: ClientDocumentationActionTypes.CLIENT_USERS_LIST_FAILURE,
  payload: data,
});

export function getClientUserListAction(FID,id,type ,name) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(getClientUserListInitate());
    return new Promise((resolve, reject) => {
      
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${FID}/client-docs-search?name=${name ? name :""}&contracttableId=${id}&contracttableType=${type}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data &&( data.status == 200)) {
            dispatch(getClientUserListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getClientUserListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("==errerr=====>", err)
          if(err.response.status === 404){
            dispatch(getClientUserListSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getClientUserListFailure(err));
            reject(err);
          }
         
        });
    });
  };
}






  
export const sendClientInformationInitate = () => ({
  type: ClientDocumentationActionTypes.SEND_CLIENT_INFORMATION_INITIATE,
});

export const sendClientInformationSuccess = (data) => ({
  type: ClientDocumentationActionTypes.SEND_CLIENT_INFORMATION_SUCCESS,
  payload: data,
});

export const sendClientInformationFailure = (data) => ({
  type: ClientDocumentationActionTypes.SEND_CLIENT_INFORMATION_FAILURE,
  payload: data,
});

export function sendClientInformationAction(payload,id,type) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(sendClientInformationInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${id ? id :2}/client-docs-send`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(sendClientInformationSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(sendClientInformationFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("==errerr=====>", err)
          if(err.response.status === 404){
            dispatch(sendClientInformationSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(sendClientInformationFailure(err));
            reject(err);
          }
         
        });
    });
  };
}


  
export const addSignatureInitate = () => ({
  type: ClientDocumentationActionTypes.ADD_SIGNATURE_INITIATE,
});

export const addSignatureSuccess = (data) => ({
  type: ClientDocumentationActionTypes.ADD_SIGNATURE_SUCCESS,
  payload: data,
});

export const addSignatureFailure = (data) => ({
  type: ClientDocumentationActionTypes.ADD_SIGNATURE_FAILURE,
  payload: data,
});

export function addSignatureAction(payload, ID) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ ...payload });
  return (dispatch) => {
    dispatch(addSignatureInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${ID}/image-uploads`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
              console.log("response", response);
          const data = response;
      
          if (data.status == 201) {
            dispatch(addSignatureSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(addSignatureFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("==errerr=====>", err)
          if(err.response.status === 404){
            dispatch(addSignatureSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(addSignatureFailure(err));
            reject(err);
          }     
        });
    });
  };
}




  
export const shareDocumentInitate = () => ({
  type: ClientDocumentationActionTypes.SHARE_DOCUMENTS_INITIATE,
});

export const shareDocumentSuccess = (data) => ({
  type: ClientDocumentationActionTypes.SHARE_DOCUMENTS_SUCCESS,
  payload: data,
});

export const shareDocumentFailure = (data) => ({
  type: ClientDocumentationActionTypes.SHARE_DOCUMENTS_FAILURE,
  payload: data,
});

export function shareDocumentAction(payload ,contractId,folderId,id,type) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ ...payload });
  return (dispatch) => {
    dispatch(shareDocumentInitate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/client-docs/${id}/share-doc?contracttableId=${contractId}&contracttableType=${type}`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200 || 201) {
            dispatch(shareDocumentSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(shareDocumentFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("==errerr=====>", err)
          if(err.response.status === 404){
            dispatch(shareDocumentSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(shareDocumentFailure(err));
            reject(err);
          }     
        });
    });
  };
}


 
export const downloadDocumentInitate = () => ({
  type: ClientDocumentationActionTypes.DOWNLOAD_DOCUMENT_INITIATE,
});

export const downloadDocumentSuccess = (data) => ({
  type: ClientDocumentationActionTypes.DOWNLOAD_DOCUMENT_SUCCESS,
  payload: data,
});

export const downloadDocumentFailure = (data) => ({
  type: ClientDocumentationActionTypes.DOWNLOAD_DOCUMENT_FAILURE,
  payload: data,
});

export function downloadDocumentAction(payload ,folderId,id,type, contractId) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ ...payload });
  return (dispatch) => {
    dispatch(downloadDocumentInitate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/client-docs/${id}/download-doc?contracttableId=${contractId}&contracttableType=${type}`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200 || 201) {
            dispatch(downloadDocumentSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(downloadDocumentFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(downloadDocumentSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(downloadDocumentFailure(err));
            reject(err);
          }     
        });
    });
  };
}


 
export const documentDetailsInitate = () => ({
  type: ClientDocumentationActionTypes.DOCUMENT_DETAILS_INITIATE,
});

export const documentDetailsSuccess = (data) => ({
  type: ClientDocumentationActionTypes.DOCUMENT_DETAILS_SUCCESS,
  payload: data,
});

export const documentDetailsFailure = (data) => ({
  type: ClientDocumentationActionTypes.DOCUMENT_DETAILS_FAILURE,
  payload: data,
});

export function documentDetailsAction(payload ,folderId,id,type, contractId) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ ...payload });
  return (dispatch) => {
    dispatch(documentDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/1/client-docs/2?contracttableId=${contractId}contracttableType=${type}payload`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200 || 201) {
            dispatch(documentDetailsSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(documentDetailsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(documentDetailsSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(documentDetailsFailure(err));
            reject(err);
          }     
        });
    });
  };
}



export const updateDocumentInitate = () => ({
  type: ClientDocumentationActionTypes.UPDATE_DOCUMENT_INITIATE,
});

export const updateDocumentSuccess = (data) => ({
  type: ClientDocumentationActionTypes.UPDATE_DOCUMENT_SUCCESS,
  payload: data,
});

export const updateDocumentFailure = (data) => ({
  type: ClientDocumentationActionTypes.UPDATE_DOCUMENT_FAILURE,
  payload: data,
});

export function updateDocumentAction(payload ,folderId,id,type, contractId) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ ...payload });
  return (dispatch) => {
    dispatch(updateDocumentInitate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/client-docs/?contracttableId=${contractId}contracttableType=${type}`, payload,{
          headers: { Authorization: `Bearer ${token}` },
        })  
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200 || 201) {
            dispatch(updateDocumentSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(updateDocumentFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(updateDocumentSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(updateDocumentFailure(err));
            reject(err);
          }     
        });
    });
  };
}

export const sendClientSignatureInitate = () => ({
  type: ClientDocumentationActionTypes.SEND_CLIENT_SIGNATURE_INITIATE,
});

export const sendClientSignatureSuccess = (data) => ({
  type: ClientDocumentationActionTypes.SEND_CLIENT_SIGNATURE_SUCCESS,
  payload: data,
});

export const sendClientSignatureFailure = (data) => ({
  type: ClientDocumentationActionTypes.SEND_CLIENT_SIGNATURE_FAILURE,
  payload: data,
});


export function sendClientSignatureAction(payload ,folderId,id,type, contractId) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(sendClientSignatureInitate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/client-docs/${id}/share-doc?contracttableId=${contractId}&contracttableType=${type}`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if (data && data.status == 200 || 201) {
            dispatch(sendClientSignatureSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(sendClientSignatureFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(sendClientSignatureSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(sendClientSignatureFailure(err));
            reject(err);
          }     
        });
    });
  };
}



export const shareClientDocumentInitate = () => ({
  type: ClientDocumentationActionTypes.SEND_CLIENT_SIGNATURE_INITIATE,
});

export const shareClientDocumentSuccess = (data) => ({
  type: ClientDocumentationActionTypes.SEND_CLIENT_SIGNATURE_SUCCESS,
  payload: data,
});

export const shareClientDocumentFailure = (data) => ({
  type: ClientDocumentationActionTypes.SEND_CLIENT_SIGNATURE_FAILURE,
  payload: data,
});


export function shareClientDocumentAction(payload ,folderId,id,type, contractId) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(shareClientDocumentInitate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/client-docs/${id}/share-doc?contracttableId=${contractId}&contracttableType=${type}`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if (data && data.status == 200 || 201) {
            dispatch(shareClientDocumentSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(shareClientDocumentFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(shareClientDocumentSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(shareClientDocumentFailure(err));
            reject(err);
          }     
        });
    });
  };
}




export const recipentListInitate = () => ({
  type: ClientDocumentationActionTypes.RECIPENT_LIST_INITIATE,
});

export const recipentListSuccess = (data) => ({
  type: ClientDocumentationActionTypes.RECIPENT_LIST_SUCCESS,
  payload: data,
});

export const recipentListFailure = (data) => ({
  type: ClientDocumentationActionTypes.RECIPENT_LIST_FAILURE,
  payload: data,
});


export function recipentListAction(payload ,folderId,id,type, contractId) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(recipentListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/client-docs/${id}/share-doc?contracttableId=${contractId}&contracttableType=${type}`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if (data && data.status == 200 || 201) {
            dispatch(recipentListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(recipentListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(recipentListSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(recipentListFailure(err));
            reject(err);
          }     
        });
    });
  };
}



export const primaryClientListInitate = () => ({
  type: ClientDocumentationActionTypes.PRIMARY_CLIENT_SIGNEE_INITIATE,
});

export const primaryClientListSuccess = (data) => ({
  type: ClientDocumentationActionTypes.PRIMARY_CLIENT_SIGNEE_SUCCESS,
  payload: data,
});

export const primaryClientListFailure = (data) => ({
  type: ClientDocumentationActionTypes.PRIMARY_CLIENT_SIGNEE_FAILURE,
  payload: data,
});


export function primaryClientListAction(payload ,folderId,id,type, contractId) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(primaryClientListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/client-docs/${id}/share-doc?contracttableId=${contractId}&contracttableType=${type}`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if (data && data.status == 200 || 201) {
            dispatch(primaryClientListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(primaryClientListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(primaryClientListSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(primaryClientListFailure(err));
            reject(err);
          }     
        });
    });
  };
}


export const builderSigneeListInitate = () => ({
  type: ClientDocumentationActionTypes.BUILDER_SIGNEE_INITIATE,
});

export const builderSigneeListSuccess = (data) => ({
  type: ClientDocumentationActionTypes.BUILDER_SIGNEE_SUCCESS,
  payload: data,
});

export const builderSigneeListFailure = (data) => ({
  type: ClientDocumentationActionTypes.BUILDER_SIGNEE_FAILURE,
  payload: data,
});


export function builderSigneeListAction(payload ,folderId,id,type, contractId) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(builderSigneeListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/client-docs/${id}/share-doc?contracttableId=${contractId}&contracttableType=${type}`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if (data && data.status == 200 || 201) {
            dispatch(builderSigneeListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(builderSigneeListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(builderSigneeListSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(builderSigneeListFailure(err));
            reject(err);
          }     
        });
    });
  };
}