import React, { useState } from "react";
import CommonBuildingFoundationInput from "./commonBuildingFoundationInput";
import CommonBuildingFoundationQuanties from "./commonBuildingFoundationQuanties";
import FoundationSummaryPreview from "./foundationSummaryPreview";

const CommonBuildingFoundation = ({
  handleCommonBuildingUnitChange,
  commonBuildingInput,
  commonBuildingFoundationInput,
  unitList,
  handleCommonBuildingfoundationChange,
  commonBuildingFoundationStep3List,
  updateId,
  handleShowComponent,
}) => {
  const [show, setShow] = useState({
    commonBuildingFoundationInput: true,
    commonBuildingFoundationQuanties: false,
    commonBuildingFoundationSummeryPreview: false,
  });

  const handleShowComponents = (component) => {
    setShow({
      commonBuildingFoundationInput:
        component === "commonBuildingFoundationInput",
      commonBuildingFoundationQuanties:
        component === "commonBuildingFoundationQuanties",
      commonBuildingFoundationSummeryPreview:
        component === "commonBuildingFoundationSummeryPreview",
    });
  };
  return (
    <div className="FolderFormCreateArea Proforma">
      <div className="StepBox Green">
        <ul>
          <li
            className={show.commonBuildingFoundationInput ? "active" : ""}
            onClick={() =>
              handleShowComponents("commonBuildingFoundationInput")
            }
          >
            <span className="Icon">
              <img src={require("../../../assets/images/block_input.png")} />
            </span>
            <span className="Text">
              Common <br />
              Buildings <br />
              Foundation <br />
              Input
            </span>
          </li>
          <li
            className={show.commonBuildingFoundationQuanties ? "active" : ""}
            onClick={() =>
              handleShowComponents("commonBuildingFoundationQuanties")
            }
          >
            <span
              className="Icon FolderPermissionId"
              data-toggle="modal"
              data-target="#CreateProformaCommonBuildingsFoundationQuantities"
              data-dismiss="modal"
            >
              <img
                src={require("../../../assets/images/block_quantities.png")}
              />
            </span>
            <span className="Text">
              Common <br />
              Buildings <br />
              Foundation <br />
              Quantities
            </span>
          </li>
          <li
            className={
              show.commonBuildingFoundationSummeryPreview ? "active" : ""
            }
            onClick={() =>
              handleShowComponents("commonBuildingFoundationSummeryPreview")
            }
          >
            <span
              className="Icon FolderPermissionId"
              data-toggle="modal"
              data-target="#CreateProformaCommonBuildingsFoundationSummaryPreview"
              data-dismiss="modal"
            >
              <img src={require("../../../assets/images/block_summary.png")} />
            </span>
            <span className="Text">
              Common <br />
              Buildings <br />
              Foundation <br />
              Summary <br />
              Preview
            </span>
          </li>
        </ul>
      </div>
      <div className="FieldsMandatory">
        <h6>
          Fields with <span className="required">*</span> are mandatory
        </h6>
      </div>
      {show.commonBuildingFoundationInput && (
        <CommonBuildingFoundationInput
          handleCommonBuildingUnitChange={handleCommonBuildingUnitChange}
          commonBuildingInput={commonBuildingInput}
          handleShowComponent={handleShowComponent}
          handleShowComponents={handleShowComponents}
        />
      )}

      {show.commonBuildingFoundationQuanties && (
        <CommonBuildingFoundationQuanties
          commonBuildingFoundationInput={commonBuildingFoundationInput}
          unitList={unitList}
          handleCommonBuildingfoundationChange={
            handleCommonBuildingfoundationChange
          }
          updateId={updateId}
          handleShowComponents={handleShowComponents}
        />
      )}
      {show.commonBuildingFoundationSummeryPreview && (
        <FoundationSummaryPreview
          commonBuildingFoundationStep3List={commonBuildingFoundationStep3List}
          handleShowComponent={handleShowComponent}
          handleShowComponents={handleShowComponents}
        />
      )}
    </div>
  );
};

export default CommonBuildingFoundation;
