import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import moment from "moment";
import Loader from "./Loader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import SmsInfo from "./SmsInfo";
import Creatable from "react-select/creatable";
import { useReactToPrint } from "react-to-print";
import { useDownloadExcel } from "react-export-table-to-excel";
import { getAllProjectList } from "../redux/action/vendorViewConfigAction";
import {
  createRoles,
  createUsers,
  delteRoles,
  getAdminRoleList,
  getRolesForm,
  getUserHistory,
  getUserList,
  ibuildUserResetPassword,
  lockUser,
  unLockUser,
  updateRoles,
  updateUsers,
  userDelete,
} from "../redux/action/adminUserAction";
import { filterData } from "../utils/blog";
import ContactDetails from "./headerModals/contactDetails";
const initialState = {
  CreateUserModal: false,
  name: "",
  email: "",
  phoneNumber: "",
  RoleId: "",
  allProjectsAssigned: "",
  roleSearch: "",
  DeleteModal: false,
  userID: "",
  userSearch: "",
  UserActivityHistoryModal: false,
  fromDate: "",
  toDate: "",
  ModifiyUserModal: false,
  userStatus: "",
  createdAt: "",
  CreateNewRoleModal: false,
  roleName: "",
  roleDescription: "",
  modelRole: null,
  modelRoleSearch: "",
  AssignMemberModal: "",
  memberDetails: [],
  phonecode: "",
  UpdateNewRoleModal: false,
  roleId: "",
  emails: "",
  rolesData: [],
  selectedRole: null,
  selectedRoles: null,
  userName: "",
};

const AdminUserManagement = () => {
  const dispatch = useDispatch();
  const { roleList, userList, userLoader, rolesForm, userHistoryList } =
    useSelector((state) => state.adminUserReducers);
  console.log("userHistoryList",userHistoryList);
  const [ContactDetailsModal, setContactDetailsModal] = useState(false);
  const [contactDetailsData, setContactDetailsData] = useState({});

  const [SmsInfoModal, SetSmsInfoModal] = useState(false);
  const [userData, setUserData] = useState({});

  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const [iState, updateState] = useState(initialState);
  const {
    CreateUserModal,
    name,
    email,
    phoneNumber,
    RoleId,
    allProjectsAssigned,
    roleSearch,
    DeleteModal,
    userID,
    userSearch,
    UserActivityHistoryModal,
    fromDate,
    toDate,
    ModifiyUserModal,
    userStatus,
    createdAt,
    CreateNewRoleModal,
    roleName,
    roleDescription,
    modelRole,
    modelRoleSearch,
    AssignMemberModal,
    memberDetails,
    phonecode,
    UpdateNewRoleModal,
    roleId,
    emails,
    rolesData,
    selectedRole,
    selectedRoles,
    userName,
  } = iState;

  const { allProjectList } = useSelector(
    (state) => state.vendorViewConfigReducer
  );

  useEffect(() => {
    dispatch(getAllProjectList(iBuildLocalData?.user?.CompanyId));
    dispatch(getAdminRoleList());
    dispatch(getUserList());
  }, []);

  useEffect(() => {
    dispatch(getRolesForm()).then((res) => {
      if (res.status === 200) {
        updateState({
          ...iState,
          rolesData: res?.data?.roleNewForm?.RolePermissions,
        });
      }
    });
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const [selectedItems, setSelectedItems] = useState([]);
  const handleCreateUserModalShow = () => {
    updateState({
      ...iState,
      CreateUserModal: true,
      name: "",
      email: "",
      phoneNumber: "",
      RoleId: "",
      allProjectsAssigned: "",
    });
    setSelectedItems([]);
  };

  const handleCreateUserModalClose = () => {
    updateState({ ...iState, CreateUserModal: false });
  };

  const handleCheckboxChange = (event, item) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((selectedItem) => selectedItem !== item)
      );
    }
  };

  const handleCreateUsers = () => {
    const dataa = {
      name,
      email,
      phoneNumber,
      RoleId: Number(RoleId),
      allProjectsAssigned: false,
      assignedProjects: [...new Set(selectedItems?.map((data) => data.id))],
      phonecode,
    };
    dispatch(createUsers(dataa)).then((res) => {
      if (res.status === 201) {
        dispatch(getUserList());
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          CreateUserModal: false,
          name: "",
          email: "",
          phoneNumber: "",
          RoleId: "",
          allProjectsAssigned: "",
        });
        setSelectedItems([]);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          CreateUserModal: true,
        });
      }
    });
  };

  const handleDeleteShow = (id) => {
    updateState({ ...iState, DeleteModal: true, userID: id });
  };

  const handleDeleteClose = () => {
    updateState({ ...iState, DeleteModal: false });
  };

  const handleDeleteAssign = () => {
    dispatch(userDelete(userID)).then((res) => {
      if (res.status === 200) {
        dispatch(getUserList());
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          DeleteModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          DeleteModal: true,
        });
      }
    });
  };

  const handleLockUser = (id) => {
    dispatch(lockUser(id)).then((res) => {
      if (res.status === 200) {
        dispatch(getUserList());
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleUnLocUser = (id) => {
    dispatch(unLockUser(id)).then((res) => {
      if (res.status === 200) {
        dispatch(getUserList());
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleUserActivityHistoryModalShow = (id, name) => {
    dispatch(getUserHistory(id))
    updateState({
      ...iState,
      UserActivityHistoryModal: true,
      userID: id,
      userName: name,
      fromDate:"",
      toDate: "",
    });
  };

  useEffect(()=>{
    if(UserActivityHistoryModal){

      const dataa = { fromDate, toDate };
      dispatch(getUserHistory(userID, dataa))
    }
  },[UserActivityHistoryModal])

  const handleUserActivityHistoryModalClose = () => {
    updateState({
      ...iState,
      UserActivityHistoryModal: false,
      userID: "",
      userName: "",
      fromDate:"",
      toDate: "",
    });
  };

  const handleUserHistory = () => {
    const dataa = { fromDate, toDate };
    dispatch(getUserHistory(userID, dataa)).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleModifiyUserModalShow = (item) => {
    const selectedRoleData = roleList?.companyRoles?.find(
      (role) => role.id == item?.RoleId
    );
    updateState({
      ...iState,
      ModifiyUserModal: true,
      name: item.name,
      email: item.email,
      phoneNumber: item.phoneNumber,
      selectedRole: selectedRoleData
        ? { value: selectedRoleData?.id, label: selectedRoleData?.roleName }
        : null,
      userStatus: item.userStatus,
      createdAt: moment(item.updatedAt).format("YYYY-MM-DD"),
      userID: item.id,
      phonecode: item.phonecode,
      RoleId: item.RoleId,
    });
    let newDataa = item.AssignedProjects?.map((data) => ({
      ...data,
      id: data?.ProjectId,
    }));

    setSelectedItems(newDataa);
  };

  const handleModifyUsers = () => {
    const dataa = {
      name,
      email,
      phoneNumber,
      phonecode,
      RoleId: Number(RoleId),
      allProjectsAssigned: false,
      assignedProjects: [...new Set(selectedItems?.map((data) => data.id))],
    };
    dispatch(updateUsers(userID, dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(getUserList());
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          ModifiyUserModal: false,
          name: "",
          email: "",
          phoneNumber: "",
          RoleId: "",
          allProjectsAssigned: "",
        });
        setSelectedItems([]);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          ModifiyUserModal: true,
        });
      }
    });
  };

  const handleModifiyUserModalClose = () => {
    updateState((prevState) => ({
      ...prevState,
      ModifiyUserModal: false,
    }));
  };

  const handleCreateNewRoleModalShow = () => {
    updateState({
      ...iState,
      CreateNewRoleModal: true,
      roleName: "",
      roleDescription: "",
      modelRole: "",
      selectedRoles: null,
    });
  };

  const handleCreateNewRoleModalClose = () => {
    updateState((prevState) => ({
      ...prevState,
      roleName: "",
      roleDescription: "",
      modelRole: "",
      CreateNewRoleModal: false,
    }));
  };

  const handleCreateRole = () => {
    const dataa = {
      roleName,
      roleDescription,
      modelRole,
      RolePermissions: rolesData,
    };
    dispatch(createRoles(dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(getAdminRoleList());
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          CreateNewRoleModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          CreateNewRoleModal: true,
        });
      }
    });
  };

  const handleAssignMemberModalShow = (item) => {
    updateState({ ...iState, AssignMemberModal: true, memberDetails: item });
  };

  const handleAssignMemberModalClose = () => {
    updateState({ ...iState, AssignMemberModal: false });
  };

  const handlePhoneInputChange = (value, country) => {
    updateState({
      ...iState,
      phonecode: `+${country.dialCode}`,
    });
  };

  const handleUpdateNewRoleModalShow = (item) => {
    const selectedRoleData = roleList?.companyRoles?.find(
      (role) => role.id == item?.modelRole
    );
    updateState({
      ...iState,
      UpdateNewRoleModal: true,
      roleName: item.roleName,
      roleDescription: item.roleDescription,
      modelRole: item.modelRole,
      selectedRoles: selectedRoleData
        ? { value: selectedRoleData?.id, label: selectedRoleData?.roleName }
        : null,
      roleId: item.id,
      rolesData: item?.rolePermission?.map((data) => ({
        ...data,
        subPermissions: data.SubPermissions,
      })),
    });
  };

  const handleUpdateNewRoleModalClose = () => {
    updateState({ ...iState, UpdateNewRoleModal: false });
  };

  const handleUpdateRole = () => {
    const dataa = {
      roleName,
      roleDescription,
      modelRole,
      RolePermissions: rolesData,
    };
    dispatch(updateRoles(roleId, dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(getAdminRoleList());
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          UpdateNewRoleModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          UpdateNewRoleModal: true,
        });
      }
    });
  };

  const handleDeleteRole = (roleId) => {
    dispatch(delteRoles(roleId)).then((res) => {
      if (res.status === 200) {
        dispatch(getAdminRoleList());
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  const [showUser, setShowUser] = useState(true);
  const [showUserRole, setShowUserRole] = useState(false);
  const handleShowUser = () => {
    setShowUser(true);
    setShowUserRole(false);
  };
  const handleShowUserRole = () => {
    setShowUser(false);
    setShowUserRole(true);
  };

  const handleRolePermission = (e, i) => {
    const { name, value, checked } = e.target;
    let newlist = [...rolesData];
    newlist[i] = {
      ...newlist[i],
      [name]: checked ? true : false,
      subPermissions: newlist?.[i]?.subPermissions?.map((item, i) => ({
        ...item,
        [name]: checked ? true : false,
      })),
    };
    updateState({ ...iState, rolesData: newlist });
  };
  const handleSubrolePermission = (e, indx, i) => {
    const { name, value, checked } = e.target;
    let newlist = [...rolesData];
    newlist[indx].subPermissions[i] = {
      ...newlist[indx]?.subPermissions[i],
      [name]: checked ? true : false,
    };
    updateState({ ...iState, rolesData: newlist });
  };

  const [EmailModal, setEmailModal] = useState(false);
  const handleEmailModalClose = () => setEmailModal(false);
  const handleEmailModalShow = (mail) => {
    setEmailModal(true);
    updateState({ ...iState, emails: mail });
  };

  const handleUserResetPasswordEmailSend = (e) => {
    e.preventDefault();
    if (!emails) {
      toast.error("Please enter email", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const dataa = { email: emails };
      dispatch(ibuildUserResetPassword(dataa)).then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setEmailModal(false);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setEmailModal(true);
        }
      });
    }
  };

  const roleListOption =
    roleList?.companyRoles?.map((item) => ({
      value: item.id,
      label: item.roleName,
    })) || [];

  const handleChange = (selectedOption) => {
    const dummyData = selectedOption?.value;
    updateState((prevState) => ({
      ...prevState,
      RoleId: dummyData,
      selectedRole: selectedOption,
    }));
  };

  const handleChanges = (selectedOption) => {
    if (selectedOption === null) {
      dispatch(getRolesForm()).then((res) => {
        if (res.status === 200) {
          updateState({
            ...iState,
            modelRole: null,
            selectedRoles: null,
            rolesData: res?.data?.roleNewForm?.RolePermissions,
          });
        }
      });
    } else {
      // Update rolesData based on the selected role's permissions
      const selectedRoleItem = roleList?.companyRoles?.find(
        (role) => role.id === selectedOption?.value
      );
      const dummyDatas = filterData(selectedRoleItem?.rolePermission);
      const selectedOptionData = selectedOption?.value;
      updateState((prevState) => ({
        ...prevState,
        modelRole: selectedOptionData,
        selectedRoles: selectedOption,
        rolesData: dummyDatas,
      }));
    }
  };

  const tableRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "User Table",
    sheet: "User",
  });

  return (
    <>
      <div className="tab-pane fade show active" id="UserManagement">
        <div
          className="ResidentialJobArea ViewConfigArea"
          style={{ backgroundColor: "transparent", padding: 0 }}
        >
          <div className="ModalAccordian">
            <div id="accordion">
              <div className="TodosButtons mt-0 text-light d-flex justify-content-end mb-4">
                {showUser && (
                  <a onClick={handleCreateUserModalShow}>
                    <img src="images/create_new_user.png" />
                    Create New User
                  </a>
                )}
                {showUserRole && (
                  <a onClick={handleCreateNewRoleModalShow}>
                    <img src="images/create_new_role.png" />
                    Create New User Role
                  </a>
                )}
              </div>
              <div className="TodosButtons mt-0 d-flex text-light justify-content-start mb-4">
                <a
                  onClick={() => handleShowUser()}
                  className={` ${showUser ? "orangebg" : "orangetext"}`}
                >
                  Show User
                  <span className={"borderOrange"}>
                    {userList?.users?.length ?? 0}
                  </span>
                </a>
                <a
                  onClick={() => handleShowUserRole()}
                  className={` ${showUserRole ? "orangebg" : "orangetext"}`}
                >
                  Show New User Role
                  <span className="borderOrange">
                    {roleList?.companyRoles?.length ?? 0}
                  </span>
                </a>
              </div>
              {showUser && (
                <div className="FilesArea">
                  <div className="FilesHead">
                    <div className="FilesLeft">
                      <h4 className="mr-4">User</h4>
                      <aside>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          name="userSearch"
                          value={userSearch}
                          onChange={handleInputChange}
                        />
                        <span>
                          <img src="images/search.png" />
                        </span>
                      </aside>
                    </div>
                    <div className="NewActions">
                      <a>
                        <img src="images/icon-53.png" onClick={handlePrint} />
                      </a>
                      <a>
                        <img src="images/download.png" onClick={onDownload} />
                      </a>
                      {/* <a >
                      <img src="images/setting.png" />
                    </a> */}
                    </div>
                  </div>
                  <div className="TableList TableListHeader NewTableList TableHeightScroll TableHeight500">
                    <table ref={tableRef} id="my-table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone Number</th>
                          <th>Status</th>
                          <th>Role</th>
                          <th>Assigned Projects</th>
                          <th>Creation Date</th>
                          <th>Absence</th>
                          <th>Permission Granted to Receive SMS</th>
                          <th>Back-up user</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!userLoader ? (
                          userList?.users?.length > 0 ? (
                            userList?.users
                              .filter((data) =>
                                userSearch.toLowerCase() === ""
                                  ? true
                                  : data.name &&
                                    data.name
                                      .toLowerCase()
                                      .includes(userSearch.toLowerCase())
                              )
                              ?.map((item, i) => (
                                <tr key={i}>
                                  <td>
                                    <div
                                      className="SalesPersonHoverName TextBlue"
                                      onClick={() => {
                                        setContactDetailsModal(true);
                                        setContactDetailsData(item);
                                      }}
                                    >
                                      {item.name}
                                    </div>
                                  </td>
                                  <td>{item.email}</td>
                                  <td>{item.phoneNumber}</td>
                                  <td>{item.userStatus}</td>
                                  <td>{item.roleName ?? "N/A"}</td>
                                  <td>{item?.AssignedProjects?.length ?? 0}</td>
                                  <td>
                                    {moment(item.createdAt).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </td>
                                  <td>
                                    {item.absenceFrom
                                      ? moment(item.absenceFrom).format(
                                          "YYYY-MM-DD"
                                        )
                                      : ""}{" "}
                                    to{" "}
                                    {item.absenceTo
                                      ? moment(item.absenceTo).format(
                                          "YYYY-MM-DD"
                                        )
                                      : ""}
                                  </td>

                                  <td>
                                    Yes{" "}
                                    <img
                                      onClick={() => {
                                        SetSmsInfoModal(true);
                                        setUserData(item);
                                      }}
                                      src="images/icon_62.png"
                                    />
                                  </td>

                                  <td>
                                    {userList?.users?.filter(
                                      (ele) => ele.id == item?.backUpUserId
                                    )?.[0]?.name ?? ""}
                                  </td>
                                  <td>
                                    <div className="TableThreeDots">
                                      <ul className="">
                                        <li className="dropdown">
                                          <a
                                            className=""
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img
                                              src="images/dots.png"
                                              alt="dots"
                                            />
                                          </a>
                                          <ol
                                            className="dropdown-menu"
                                            style={{}}
                                          >
                                            <li>
                                              <a
                                                className="FolderPermissionId"
                                                onClick={() =>
                                                  handleModifiyUserModalShow(
                                                    item
                                                  )
                                                }
                                              >
                                                <img
                                                  src="images/icon-15.png"
                                                  alt="modify"
                                                />
                                                Modify User
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleLockUser(item.id)
                                                }
                                              >
                                                <img
                                                  src="images/lock_user.png"
                                                  alt="lock"
                                                />
                                                Lock User
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleUnLocUser(item.id)
                                                }
                                              >
                                                <img
                                                  src="images/unlock_user.png"
                                                  alt="unlock"
                                                />
                                                Unlock User
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleEmailModalShow(
                                                    item.email
                                                  )
                                                }
                                              >
                                                <img
                                                  src="images/reset_password_admin.png"
                                                  alt="reset"
                                                />
                                                Reset Password
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleUserActivityHistoryModalShow(
                                                    item.id,
                                                    item.name
                                                  )
                                                }
                                              >
                                                <img
                                                  src="images/user_activity.png"
                                                  alt="activity"
                                                />
                                                User Activity
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleDeleteShow(item.id)
                                                }
                                                className="ml-4"
                                              >
                                                Delete User
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td colSpan="10" className="text-center">
                                <p>No Data found.</p>
                              </td>
                            </tr>
                          )
                        ) : (
                          <Loader />
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="PaginationArea">
                    <h6>Showing 1 to 10 of 27 entries</h6>
                    <h6>
                      <img src="images/leftarrow.png" /> 01{" "}
                      <img src="images/rightarrow.png" /> Out 10
                    </h6>
                  </div>
                </div>
              )}
              {showUserRole && (
                <div className="FilesArea">
                  <div className="TableList TableListHeader NewTableList TableHeightScroll">
                    <table>
                      <thead>
                        <tr>
                          <th>Role Name</th>
                          <th>Role Description</th>
                          <th>Role Type</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {roleList?.companyRoles?.length > 0 ? (
                          roleList?.companyRoles?.map((item, i) => {
                            return (
                              <tr>
                                <td>{item.roleName}</td>
                                <td>{item.roleDescription}</td>
                                <td>{item.roleType}</td>
                                <td>
                                  <div className="TableThreeDots">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img
                                            src="images/dots.png"
                                            alt="dots"
                                          />
                                        </a>
                                        <ol
                                          className="dropdown-menu"
                                          style={{}}
                                        >
                                          <li>
                                            <a
                                              className="FolderPermissionId"
                                              onClick={() =>
                                                handleUpdateNewRoleModalShow(
                                                  item
                                                )
                                              }
                                            >
                                              <img
                                                src="images/icon-15.png"
                                                alt="modify"
                                              />
                                              Modify Role
                                            </a>
                                          </li>

                                          <li>
                                            <a
                                              onClick={() =>
                                                handleDeleteRole(item.id)
                                              }
                                              className="ml-4"
                                            >
                                              Delete Role
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="10" className="text-center">
                              <p>No Data found.</p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="PaginationArea">
                    <h6>Showing 1 to 10 of 27 entries</h6>
                    <h6>
                      <img src="images/leftarrow.png" /> 01{" "}
                      <img src="images/rightarrow.png" /> Out 10
                    </h6>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          show={CreateUserModal}
          onHide={handleCreateUserModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a className="CloseModal" onClick={handleCreateUserModalClose}>
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Create New User</h4>
              </div>
              <div className="FolderForm">
                <div className="form-group">
                  <h6>
                    Name<span className="required">*</span>
                  </h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert name"
                    name="name"
                    value={name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <h6>
                    Email<span className="required">*</span>
                  </h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert Email"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <h6>Country Code</h6>
                  <PhoneInput
                    inputClass="CountryCodeInput"
                    countryCodeEditable={false}
                    enableSearch
                    value={phonecode}
                    onChange={handlePhoneInputChange}
                  />
                </div>

                <div className="form-group">
                  <h6>Phone Number</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert Phone Number"
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <h6>
                    Role<span className="required">*</span>
                  </h6>
                  <div className="Categories Categories1">
                    <Creatable
                      isClearable
                      placeholder="Select User Role"
                      options={roleListOption}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <h6>
                    Assign Projects<span className="required">*</span>
                  </h6>
                  <div className="Categories Categories2">
                    <div className="Categories_all Categories_all2">
                      <span>Select projects</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      {allProjectList?.projects?.length > 0 &&
                        allProjectList?.projects?.map((item, ind) => {
                          return (
                            <li key={ind}>
                              <label className="CheckBox">
                                {item.projectName}
                                <input
                                  type="checkbox"
                                  checked={selectedItems.includes(item)}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, item)
                                  }
                                />
                                <span className="checkmark" />
                              </label>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={handleCreateUsers}>
                    Create User
                  </button>
                  <button
                    className="Outline"
                    onClick={handleCreateUserModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={DeleteModal}
          onHide={handleDeleteClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleDeleteClose}>
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Delete</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="my-5">
                      <h6>Are you sure you want to delete this ?</h6>
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button className="Create" onClick={handleDeleteAssign}>
                        Delete
                      </button>
                      <button className="Outline" onClick={handleDeleteClose}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={UserActivityHistoryModal}
          onHide={handleUserActivityHistoryModalClose}
          className="ModalBox SmallModal show"
        >
          <Modal.Body>
            <a
              className="CloseModal"
              onClick={handleUserActivityHistoryModalClose}
            >
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>User Activity</h4>
              </div>
              <div className="FolderForm">
                <div className="form-group">
                  <h6>
                    Name<span className="required">*</span>
                  </h6>
                  <p className="ml-0">{userName}</p>
                </div>
                <div className="row">
                  <div className="col-12">
                    <h6>Dates</h6>
                  </div>
                  <div className="col-lg-5">
                    <div className="form-group">
                      <h6>From</h6>
                      <input
                        type="date"
                        className="form-control"
                        name="fromDate"
                        value={fromDate}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 ml-auto">
                    <div className="form-group">
                      <h6>To</h6>
                      <input
                        type="date"
                        className="form-control"
                        name="toDate"
                        value={toDate}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="Tableheader">
                  <h6 className="mb-0">Activity</h6>
                  <div className="Actions">
                    <a>
                      <img src="images/icon-53.png" onClick={handlePrint} />
                    </a>
                    <a>
                      <img src="images/download.png" onClick={onDownload} />
                    </a>
                    {/* <a >
                      <img src="images/setting.png" />
                    </a> */}
                  </div>
                </div>
                <div className="TableList CommonHeading TableHeightScroll">
                  <table ref={tableRef} id="my-table">
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userHistoryList?.companyUserHistories?.length > 0 ? (
                        userHistoryList?.companyUserHistories?.map(
                          (item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.activity ?? "N/A"}</td>
                                <td>
                                  {moment(item.createdAt).format(
                                    "YYYY-MM-DD & hh:mm A"
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <tr>
                          <td colSpan="4">No data found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div
                  className="Button text-center mt-5"
                  onClick={handleUserHistory}
                >
                  <button className="Outline">Submit</button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={ModifiyUserModal}
          onHide={handleModifiyUserModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a className="CloseModal" onClick={handleModifiyUserModalClose}>
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Modify User</h4>
              </div>
              <div className="FolderForm">
                <div className="form-group">
                  <h6>
                    Name<span className="required">*</span>
                  </h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Jane williams"
                    name="name"
                    value={name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <h6>
                    Email<span className="required">*</span>
                  </h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="janewilliams@builder.com"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <h6>Country Code</h6>
                  <PhoneInput
                    inputClass="CountryCodeInput"
                    countryCodeEditable={false}
                    enableSearch
                    value={phonecode}
                    onChange={handlePhoneInputChange}
                  />
                </div>
                <div className="form-group">
                  <h6>Phone Number</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="(587)555-4444"
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <h6>
                    Role<span className="required">*</span>
                  </h6>
                  <div className="Categories Categories1">
                    <Creatable
                      isClearable
                      value={selectedRole}
                      placeholder="Select User Role"
                      options={roleListOption}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <h6>
                    Assign Projects<span className="required">*</span>
                  </h6>
                  <div className="Categories Categories2">
                    <div className="Categories_all Categories_all2">
                      <span>Select projects</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      {allProjectList?.projects?.length > 0 &&
                        allProjectList?.projects?.map((item, ind) => {
                          return (
                            <li key={ind}>
                              <label className="CheckBox">
                                {item.projectName}
                                <input
                                  type="checkbox"
                                  checked={selectedItems?.some(
                                    (data) => data?.id == item?.id
                                  )}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, item)
                                  }
                                />
                                <span className="checkmark" />
                              </label>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                {/* <div className="Button d-flex justify-content-between">
                  <button className="Create">Add</button>
                </div>
                <div className="form-group mt-4">
                  <h6>Assigned Projects:</h6>
                  <div className="FilesAdded">
                    <p>Proj-4E-SIN-1</p>
                    <span className="cross">
                      <img src="images/Cross.png" />
                    </span>
                  </div>
                  <div className="FilesAdded">
                    <p>Proj-4E-SIN-1</p>
                    <span className="cross">
                      <img src="images/Cross.png" />
                    </span>
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <h6>Status</h6>
                      <p className="TextRed">{userStatus}</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <h6>Creation Date</h6>
                      <p className="ml-0">{createdAt}</p>
                    </div>
                  </div>
                  {/* <div className="col-12">
                    <h6>Absence</h6>
                  </div> */}
                  {/* <div className="col-lg-6">
                    <div className="form-group">
                      <h6>From</h6>
                      <input type="date" className="form-control" />
                    </div>
                  </div> */}
                  {/* <div className="col-lg-6">
                    <div className="form-group">
                      <h6>To</h6>
                      <input type="date" className="form-control" />
                    </div>
                  </div> */}
                  {/* <div className="col-lg-6" /> */}
                </div>
                {/* <div className="form-group">
                  <h6>Back-up-user</h6>
                  <div className="Categories Categories3">
                    <div className="Categories_all Categories_all3">
                      <span>Select User</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>
                        - List of users with the same or similar permission
                        levels
                      </li>
                    </ul>
                  </div>
                </div> */}
                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={handleModifyUsers}>
                    Update User
                  </button>
                  {/* <button className="Create py-2 px-2" data-dismiss="modal">
          Reset Password
        </button> */}
                  {/* <button className="Create py-2 px-2" data-dismiss="modal">
          Unlock User
        </button> */}
                  <button
                    className="Create"
                    onClick={handleModifiyUserModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={CreateNewRoleModal}
          onHide={handleCreateNewRoleModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a className="CloseModal" onClick={handleCreateNewRoleModalClose}>
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Create New Role</h4>
              </div>
              <div className="FolderForm">
                <div className="form-group">
                  <h6>
                    Role Name<span className="required">*</span>
                  </h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert name"
                    name="roleName"
                    value={roleName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <h6>
                    Role Description<span className="required">*</span>
                  </h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="roleDescription"
                    name="roleDescription"
                    value={roleDescription}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <h6>Permissions</h6>
                  <h6>Model Role</h6>
                  <div className="Categories Categories3">
                    <Creatable
                      isClearable
                      placeholder="Select User Role"
                      options={roleListOption}
                      onChange={handleChanges}
                    />
                  </div>
                </div>
                <div className="CheckBoxTable CheckBoxTableTop">
                  <table>
                    <thead>
                      <tr>
                        <th />
                        <th>Function</th>
                        <th>View</th>
                        <th>Full</th>
                        <th />
                      </tr>
                    </thead>
                  </table>
                </div>

                <div className="CheckBoxTable CheckBoxTable1">
                  {rolesData?.length > 0 &&
                    rolesData?.map((item, indexx) => (
                      <table key={indexx}>
                        <thead>
                          <tr>
                            <th>{item?.moduleName}</th>
                            <th />
                            <th>
                              <label className="CheckBox">
                                <input
                                  type="checkbox"
                                  name="view"
                                  checked={item?.view}
                                  onChange={(e) =>
                                    handleRolePermission(e, indexx)
                                  }
                                />
                                <span className="checkmark" />
                              </label>
                            </th>
                            <th>
                              <label className="CheckBox">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleRolePermission(e, indexx)
                                  }
                                  name="full"
                                  checked={item.full}
                                />
                                <span className="checkmark" />
                              </label>
                            </th>
                            <th>
                              <img
                                src="images/up_arrow.png"
                                className="UpArrow"
                                alt="Up Arrow"
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {item?.subPermissions?.map((data, i) => (
                            <tr key={i}>
                              <td>{data.subModuleName}</td>
                              <td />
                              <td>
                                <label className="CheckBox">
                                  <input
                                    type="checkbox"
                                    name="view"
                                    checked={data.view}
                                    onChange={(e) =>
                                      handleSubrolePermission(e, indexx, i)
                                    }
                                  />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input
                                    type="checkbox"
                                    name="full"
                                    checked={data.full}
                                    onChange={(e) =>
                                      handleSubrolePermission(e, indexx, i)
                                    }
                                  />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td />
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ))}
                </div>
                <div className="Button mt-5 d-flex justify-content-between">
                  <button className="Create" onClick={handleCreateRole}>
                    Create Role
                  </button>
                  <button
                    className="Outline"
                    onClick={handleCreateNewRoleModalClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={UpdateNewRoleModal}
          onHide={handleUpdateNewRoleModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a className="CloseModal" onClick={handleUpdateNewRoleModalClose}>
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Update Role</h4>
              </div>
              <div className="FolderForm">
                <div className="form-group">
                  <h6>
                    Role Name<span className="required">*</span>
                  </h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert name"
                    name="roleName"
                    value={roleName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <h6>
                    Role Description<span className="required">*</span>
                  </h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="roleDescription"
                    name="roleDescription"
                    value={roleDescription}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <h6>Permissions</h6>
                  <h6>Model Role</h6>
                  <div className="Categories Categories3">
                    <Creatable
                      isClearable
                      value={selectedRoles}
                      placeholder="Select User Role"
                      options={roleListOption}
                      onChange={handleChanges}
                    />
                  </div>
                </div>
                <div className="CheckBoxTable CheckBoxTableTop">
                  <table>
                    <thead>
                      <tr>
                        <th />
                        <th>Function</th>
                        <th>View</th>
                        <th>Full</th>
                        <th />
                      </tr>
                    </thead>
                  </table>
                </div>

                <div className="CheckBoxTable CheckBoxTable1">
                  {rolesData?.length > 0 &&
                    rolesData?.map((item, indexx) => (
                      <table key={indexx}>
                        <thead>
                          <tr>
                            <th>{item?.moduleName}</th>
                            <th />
                            <th>
                              <label className="CheckBox">
                                <input
                                  type="checkbox"
                                  name="view"
                                  checked={item?.view}
                                  onChange={(e) =>
                                    handleRolePermission(e, indexx)
                                  }
                                />
                                <span className="checkmark" />
                              </label>
                            </th>
                            <th>
                              <label className="CheckBox">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleRolePermission(e, indexx)
                                  }
                                  name="full"
                                  checked={item.full}
                                />
                                <span className="checkmark" />
                              </label>
                            </th>
                            <th>
                              <img
                                src="images/up_arrow.png"
                                className="UpArrow"
                                alt="Up Arrow"
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {item?.subPermissions?.map((data, i) => (
                            <tr key={i}>
                              <td>{data.subModuleName}</td>
                              <td />
                              <td>
                                <label className="CheckBox">
                                  <input
                                    type="checkbox"
                                    name="view"
                                    checked={data.view}
                                    onChange={(e) =>
                                      handleSubrolePermission(e, indexx, i)
                                    }
                                  />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input
                                    type="checkbox"
                                    name="full"
                                    checked={data.full}
                                    onChange={(e) =>
                                      handleSubrolePermission(e, indexx, i)
                                    }
                                  />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td />
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ))}
                </div>

                <div className="Button mt-5 d-flex justify-content-between">
                  <button className="Create" onClick={handleUpdateRole}>
                    Update Role
                  </button>
                  <button
                    className="Outline"
                    onClick={handleUpdateNewRoleModalClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={AssignMemberModal}
          onHide={handleAssignMemberModalClose}
          className="ModalBox modal fade ExtraSmallModal show"
        >
          <Modal.Body>
            <a className="CloseModal" onClick={handleAssignMemberModalClose}>
              ×
            </a>

            <div className="FormArea">
              <div className="ContactTopBox">
                <div className="SalesPersonarea">
                  <span>MB</span>
                  <h6>{memberDetails?.name ?? "N/A"}</h6>
                </div>
              </div>
              <div className="FolderForm">
                <div className="SendEmailText">
                  <p>
                    <img src="images/send-email.png" />
                    Send E-mail
                  </p>
                  <p className="SendText SendText1">
                    <img src="images/send-text.png" />
                    Send Text
                  </p>
                  <div
                    className="SendTextSearch SendTextSearch1"
                    style={{ display: "none" }}
                  >
                    <input type="text" />
                    <button>Apply</button>
                  </div>
                </div>
                <div className="MolalContactForm mb-4">
                  <h6>Contact</h6>
                  <p>
                    <img src="images/send-email.png" />
                    {memberDetails?.email}
                  </p>
                  <p className="mb-4">
                    <img src="images/send-text.png" />
                    {memberDetails?.phoneNumber ?? "N/A"}
                  </p>
                  {/* <a  className="ShowMoreBtn">
                  Show more
                </a>
                <a  className="ShowLessBtn">
                  Show less
                </a> */}
                </div>
                <div className="form-group mt-3">
                  <div className="ContactModalAddressArea">
                    <h6>Address</h6>
                    <p>
                      {memberDetails?.addressLineTwo ?? "N/A"} <br />
                      {memberDetails?.streetAddress ?? "N/A"}
                      <img src="images/location.png" />
                    </p>
                  </div>
                </div>
                <div className="DiscussionHide">
                  <div className="form-group mb-0">
                    <h6>Discussion</h6>
                    <input
                      type="text"
                      placeholder="Write here ....."
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={EmailModal}
          onHide={handleEmailModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a className="CloseModal email" onClick={handleEmailModalClose}>
              ×
            </a>
            <div className="FormArea">
              <div className="LoginBox Modal">
                <a className="nav-link">IBuild Admin</a>
                <div className="LoginContentBox">
                  <h3>Recover your account</h3>
                  <p className="mb-4">
                    Enter email associated with your account and follow the
                    instructions
                  </p>
                  <form>
                    <div className="form-group">
                      <label>Enter Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter text"
                        // name="emails"
                        value={emails}
                        disabled
                        // onChange={handleInputChange}
                      />
                    </div>

                    <a
                      className="Button FolderPermissionId"
                      onClick={handleUserResetPasswordEmailSend}
                    >
                      Send Reset Email
                    </a>
                    <a
                      className="ButtonOutline mt-3"
                      onClick={handleEmailModalClose}
                    >
                      Cancel
                    </a>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      {SmsInfoModal && (
        <SmsInfo
          SmsInfoModal={SmsInfoModal}
          SetSmsInfoModal={SetSmsInfoModal}
          userData={userData}
        />
      )}

      {ContactDetailsModal && (
        <ContactDetails
          ContactDetailsModal={ContactDetailsModal}
          setContactDetailsModal={setContactDetailsModal}
          contactDetailsData={contactDetailsData}
        />
      )}
    </>
  );
};

export default AdminUserManagement;
