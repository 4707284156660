import { clientViewConfigAction } from '../actionTypes'

const initialState = {
  clientViewConfigWarrantyList: [],
  loader: false,
  changeOrderData:[],
  appointmentData:[],
  constructionData:[],
  saleData:[],
  generalInformationData:[],
  desginSelectionData:[],
  allClientData:[],
  loaderClient:false,
}

export default function clientViewConfigData(state = initialState, {type, payload}) {
  switch(type) {
    case clientViewConfigAction.GET_CLIENT_VIEW_WARRANTY_LIST_INITIATE: {
      return {...state, loader: true, clientViewConfigWarrantyList: [] }
    }
    case clientViewConfigAction.GET_CLIENT_VIEW_WARRANTY_LIST_SUCCESS: {
      return {...state, loader: false, clientViewConfigWarrantyList: payload}
    }
    case clientViewConfigAction.GET_CLIENT_VIEW_WARRANTY_LIST_FAILURE: {
      return {...state, loader: false,clientViewConfigWarrantyList: payload}
    }

    case clientViewConfigAction.GET_CHANGE_ORDER_DATA_INITIATE: {
      return {...state, loader: true, changeOrderData: [] }
    }
    case clientViewConfigAction.GET_CHANGE_ORDER_DATA_SUCCESS: {
      return {...state, loader: false, changeOrderData: payload}
    }
    case clientViewConfigAction.GET_CHANGE_ORDER_DATA_FAILURE: {
      return {...state, loader: false,changeOrderData: payload}
    }

    case clientViewConfigAction.GET_APPOINTMENT_DATA_INITIATE: {
      return {...state, loader: true, appointmentData: [] }
    }
    case clientViewConfigAction.GET_APPOINTMENT_DATA_SUCCESS: {
      return {...state, loader: false, appointmentData: payload}
    }
    case clientViewConfigAction.GET_APPOINTMENT_DATA_FAILURE: {
      return {...state, loader: false,appointmentData: payload}
    }

    case clientViewConfigAction.GET_CONSTRUCTION_DATA_INITIATE: {
      return {...state, loader: true, constructionData: [] }
    }
    case clientViewConfigAction.GET_CONSTRUCTION_DATA_SUCCESS: {
      return {...state, loader: false, constructionData: payload}
    }
    case clientViewConfigAction.GET_CONSTRUCTION_DATA_FAILURE: {
      return {...state, loader: false,constructionData: payload}
    }

    case clientViewConfigAction.GET_SALE_DATA_INITIATE: {
      return {...state, loader: true, saleData: [] }
    }
    case clientViewConfigAction.GET_SALE_DATA_SUCCESS: {
      return {...state, loader: false, saleData: payload}
    }
    case clientViewConfigAction.GET_SALE_DATA_FAILURE: {
      return {...state, loader: false,saleData: payload}
    }

    case clientViewConfigAction.GENERAL_INFORMATION_DATA_INITIATE: {
      return {...state, loader: true, generalInformationData: [] }
    }
    case clientViewConfigAction.GENERAL_INFORMATION_DATA_SUCCESS: {
      return {...state, loader: false, generalInformationData: payload}
    }
    case clientViewConfigAction.GENERAL_INFORMATION_DATA_FAILURE: {
      return {...state, loader: false,generalInformationData: payload}
    }

    case clientViewConfigAction.DESGIN_SELECTIONS_DATA_INITIATE: {
      return {...state, loader: true, desginSelectionData: [] }
    }
    case clientViewConfigAction.DESGIN_SELECTIONS_DATA_SUCCESS: {
      return {...state, loader: false, desginSelectionData: payload}
    }
    case clientViewConfigAction.DESGIN_SELECTIONS_DATA_FAILURE: {
      return {...state, loader: false,desginSelectionData: payload}
    }

    case clientViewConfigAction.ALL_CLIENT_USER_INITIATE: {
      return {...state, loaderClient: true, allClientData: [] }
    }
    case clientViewConfigAction.ALL_CLIENT_USER_SUCCESS: {
      return {...state, loaderClient: false, allClientData: payload}
    }
    case clientViewConfigAction.ALL_CLIENT_USER_FAILURE: {
      return {...state, loaderClient: false,allClientData: payload}
    }

    default: 
    return state
}
}