import { tradeViewAuthAction } from "../actionTypes";
import { Url } from  "../../Config/Config";
import axios from "axios";
import { multiPartData, multiPartDatas } from "../../utils/uploadFile";


//----------------------------------tardeView==login--------------------------------------------------

export const tradeViewLoginInitiate = () => ({
  type: tradeViewAuthAction.TRADE_VIEW_LOGIN_INITIATE,
});

export const tradeViewLoginSuccess = (data) => ({
  type: tradeViewAuthAction.TRADE_VIEW_LOGIN_SUCCESS,
  payload: data,
});

export const tradeViewLoginFailure = (data) => ({
  type: tradeViewAuthAction.TRADE_VIEW_LOGIN_FAILURE,
  payload: data,
});

export const tradeViewLogin = (payload) => async (dispatch) => {
    try {
      dispatch(tradeViewLoginInitiate(payload));
      const response = await axios.post(`${Url}trade-views/login`, payload);
      if (response.status === 200) {
        dispatch(tradeViewLoginSuccess(response.data));
      } else {
        dispatch(tradeViewLoginFailure(response.data));
      }
      return response
    } catch (err) {
      dispatch(tradeViewLoginFailure(err));
      return err.response
    }
  };

//=================================TradeSendEmailRequest================================

export const tradeSendEmailInitiate = () => ({
  type: tradeViewAuthAction.TRADE_SEND_EMAIL_INITIATE,
});

export const tradeSendEmailSuccess = (data) => ({
  type: tradeViewAuthAction.TRADE_SEND_EMAIL_FAILURE,
  payload: data,
});

export const tradeSendEmailFailure = (data) => ({
  type: tradeViewAuthAction.TRADE_SEND_EMAIL_SUCCESS,
  payload: data,
});

export const sendTradeClientEmail = (payload) => async (dispatch) => {
    try {
      dispatch(tradeSendEmailInitiate(payload));
      const response = await axios.put(`${Url}trade-user/send-reset`, payload);
      if (response.status === 200) {
        dispatch(tradeSendEmailSuccess(response.data));
      } else {
        dispatch(tradeSendEmailFailure(response.data));
      }
      return response
    } catch (err) {
      dispatch(tradeSendEmailFailure(err));
      return err.response
    }
  };
//========================getVendorId========================
  export const getTradeVendorByUserIdInitiate = () => ({
    type: tradeViewAuthAction.GET_TRADE_VENDOR_BY_USER_ID_INITIATE,
  });
  
  export const getTradeVendorByUserIdSuccess = (data) => ({
    type: tradeViewAuthAction.GET_TRADE_VENDOR_BY_USER_ID_SUCCESS,
    payload: data,
  });
  
  export const getTradeVendorByUserIdFailure = (data) => ({
    type: tradeViewAuthAction.GET_TRADE_VENDOR_BY_USER_ID_FAILURE,
    payload: data,
  });
  
  export const getTradeVendorByUserId = (tradViewId,userId,payload) => async (dispatch) => {
      try {
        const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
        dispatch(getTradeVendorByUserIdInitiate(payload));
        const response = await axios.get(`${Url}trade-view/${tradViewId}/user/${userId}/vendor`,{
            headers: { Authorization: `Bearer ${tradeViewData?.user?.token}`}})
        if (response.status === 200) {
          dispatch(getTradeVendorByUserIdSuccess(response.data));
        } else {
          dispatch(getTradeVendorByUserIdFailure(response.data));
        }
        return response
      } catch (err) {
        dispatch(getTradeVendorByUserIdFailure(err));
        return err.response
      }
    };

//=============================================uploadIcons============================================

    export const uploadTradeIconImageInitiate = () => ({
      type: tradeViewAuthAction.UPLOAD_TRADE_ICON_IMAGE_INITIATE,
    });
    
    export const uploadTradeIconImageSuccess = (data) => ({
      type: tradeViewAuthAction.UPLOAD_TRADE_ICON_IMAGE_FAILURE,
      payload: data,
    });
    
    export const uploadTradeIconImageFailure = (data) => ({
      type: tradeViewAuthAction.UPLOAD_TRADE_ICON_IMAGE_FAILURE,
      payload: data,
    });
    
      export const getUploadTradeIconImage = (vendorId,payload) => async (dispatch) => {
          try {
            const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
            // const updatedData = multiPartDatas(payload );
            dispatch(uploadTradeIconImageInitiate());
            const response = await axios.put(`${Url}vendors/${vendorId}/investor-icon-image`,payload, {
              headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
            });
            if (response.status == 200) {
              dispatch(uploadTradeIconImageSuccess(response.data));
            } else {
              dispatch(uploadTradeIconImageFailure(response.data));
            }
            return response;
          } catch (err) {
            dispatch(uploadTradeIconImageFailure(err));
            return err.response
          }
        };
  

//================================upload-main================================================
        export const uploadMainTradeImageInitiate = () => ({
          type: tradeViewAuthAction.UPLOAD_MAIN_TRADE_IMAGE_INITIATE,
        });
        
        export const uploadMainTradeImageSuccess = (data) => ({
          type: tradeViewAuthAction.UPLOAD_MAIN_TRADE_IMAGE_SUCCESS,
          payload: data,
        });
        
        export const uploadMainTradeImageFailure = (data) => ({
          type: tradeViewAuthAction.UPLOAD_MAIN_TRADE_IMAGE_FAILURE,
          payload: data,
        });
        
          export const getUploadMainTradeImage = (vendorId,payload) => async (dispatch) => {
              try {
                const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
                // const updatedData = multiPartDatas(payload );
                dispatch(uploadMainTradeImageInitiate());
                const response = await axios.put(`${Url}vendors/${vendorId}/investor-main-image`,payload, {
                  headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
                });
                if (response.status == 200) {
                  dispatch(uploadMainTradeImageSuccess(response.data));
                } else {
                  dispatch(uploadMainTradeImageFailure(response.data));
                }
                return response;
              } catch (err) {
                dispatch(uploadMainTradeImageFailure(err));
                return err.response
              }
            };

//====================================craeteComment =======================


export const createTradeCommentInitiate = () => ({
  type: tradeViewAuthAction.CREATE_TRADE_COMMENT_INITIATE,
});

export const createTradeCommentSuccess = (data) => ({
  type: tradeViewAuthAction.CREATE_TRADE_COMMENT_SUCCESS,
  payload: data,
});

export const createTradeCommentFailure = (data) => ({
  type: tradeViewAuthAction.CREATE_TRADE_COMMENT_FAILURE,
  payload: data,
});

  export const createTradeComment = (investId,compId,payload) => async (dispatch) => {
      try {
        const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
        dispatch(createTradeCommentInitiate(payload));
        const response = await axios.post(`${Url}trade-view/${investId}/discussions?commentableType=Company&commentableId=${compId}`,payload, {
          headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
        });
        if (response.status == 200) {
          dispatch(createTradeCommentSuccess(response.data));
        } else {
          dispatch(createTradeCommentFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(createTradeCommentFailure(err));
        return err.response
      }
    };
//================================getComment=====================================


export const getTradeCommentInitiate = () => ({
  type: tradeViewAuthAction.GET_TRADE_COMMENT_INITIATE,
});

export const getTradeCommentSuccess = (data) => ({
  type: tradeViewAuthAction.GET_TRADE_COMMENT_SUCCESS,
  payload: data,
});

export const getTradeCommentFailure = (data) => ({
  type: tradeViewAuthAction.GET_TRADE_COMMENT_FAILURE,
  payload: data,
});

  export const getTradeCommentList = (investId,compId) => async (dispatch) => {
      try {
        const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
        dispatch(getTradeCommentInitiate());
        const response = await axios.get(`${Url}trade-view/${investId}/discussions?commentableType=Company&commentableId=${compId}`, {
          headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
        });
        if (response.status == 200) {
          dispatch(getTradeCommentSuccess(response.data));
        } else {
          dispatch(getTradeCommentFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(getTradeCommentFailure(err));
        return err.response
      }
    };
//==============================investor-trade=--view============================


export const tradeViewInitiate = () => ({
  type: tradeViewAuthAction.TRADE_VIEW_ACCESS_INITIATE,
});

export const tradeViewSuccess = (data) => ({
  type: tradeViewAuthAction.TRADE_VIEW_ACCESS_SUCCESS,
  payload: data,
});

export const tradeViewFailure = (data) => ({
  type: tradeViewAuthAction.TRADE_VIEW_ACCESS_FAILURE,
  payload: data,
});

  export const getTradeView = (compId) => async (dispatch) => {
      try {
        const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
        dispatch(tradeViewInitiate());
        const response = await axios.get(`${Url}companies/${compId}/trade-view`, {
          headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
        });
        if (response.status == 200) {
          dispatch(tradeViewSuccess(response.data));
        } else {
          dispatch(tradeViewFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(tradeViewFailure(err));
        return err.response
      }
    };

//====================================craeteSub-Comment =======================


export const createTradeSubCommentInitiate = () => ({
  type: tradeViewAuthAction.CREATE_TRADE_SUB_COMMENT_INITIATE,
});

export const createTradeSubCommentSuccess = (data) => ({
  type: tradeViewAuthAction.CREATE_TRADE_SUB_COMMENT_SUCCESS,
  payload: data,
});

export const createTradeSubCommentFailure = (data) => ({
  type: tradeViewAuthAction.CREATE_TRADE_SUB_COMMENT_FAILURE,
  payload: data,
});

  export const createTradeSubComment = (investId,compId,payload) => async (dispatch) => {
      try {
        const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
        dispatch(createTradeSubCommentInitiate(payload));
        const response = await axios.post(`${Url}trade-view/${investId}/sub-discussions?commentableType=Company&commentableId=${compId}`,payload, {
          headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
        });
        if (response.status == 200) {
          dispatch(createTradeSubCommentSuccess(response.data));
        } else {
          dispatch(createTradeSubCommentFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(createTradeSubCommentFailure(err));
        return err.response
      }
    };
//================================getSubComment=====================================


export const getTradeSubCommentInitiate = () => ({
  type: tradeViewAuthAction.GET_SUB_TRADE_COMMENT_INITIATE,
});

export const getTradeSubCommentSuccess = (data) => ({
  type: tradeViewAuthAction.GET_SUB_TRADE_COMMENT_SUCCESS,
  payload: data,
});

export const getTradeSubCommentFailure = (data) => ({
  type: tradeViewAuthAction.GET_SUB_TRADE_COMMENT_FAILURE,
  payload: data,
});

  export const getTradeSubCommentList = (investId,disId,compId) => async (dispatch) => {
      try {
        const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
        dispatch(getTradeSubCommentInitiate());
        const response = await axios.get(`${Url}trade-view/${investId}/discussions/${disId}/sub-discussions?commentableType=Company&commentableId=${compId}`, {
          headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
        });
        if (response.status == 200) {
          dispatch(getTradeSubCommentSuccess(response.data));
        } else {
          dispatch(getTradeSubCommentFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(getTradeSubCommentFailure(err));
        return err.response
      }
    };

//==================================CONSTuction-Schedule-List-------------------------


export const constructScheduleListInitiate = () => ({
  type: tradeViewAuthAction.CONSTRUCTION_SCHEDULE_LIST_INITIATE,
});

export const constructScheduleListSuccess = (data) => ({
  type: tradeViewAuthAction.CONSTRUCTION_SCHEDULE_LIST_SUCCESS,
  payload: data,
});

export const constructScheduleListFailure = (data) => ({
  type: tradeViewAuthAction.CONSTRUCTION_SCHEDULE_LIST_FAILURE,
  payload: data,
});

  export const getConstructScheduleList = (vendorId,jobId) => async (dispatch) => {
      try {
        const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
        dispatch(constructScheduleListInitiate());
        const response = await axios.get(`${Url}construction/getTaskForVendor/${vendorId}/${jobId}`, {
          headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
        });
        if (response.status == 201) {
          dispatch(constructScheduleListSuccess(response.data));
        } else {
          dispatch(constructScheduleListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(constructScheduleListFailure(err));
        return err.response
      }
    };

//============================update-support-docs--------------------------------


export const constructSupportEditDocsInitiate = () => ({
  type: tradeViewAuthAction.CONSTRUCTION_SUPPORT_EDIT_DOCS_INITIATE,
});

export const constructSupportEditDocsSuccess = (data) => ({
  type: tradeViewAuthAction.CONSTRUCTION_SUPPORT_EDIT_DOCS_SUCCESS,
  payload: data,
});

export const constructSupportEditDocsFailure = (data) => ({
  type: tradeViewAuthAction.CONSTRUCTION_SUPPORT_EDIT_DOCS_FAILURE,
  payload: data,
});

  export const getSupportEditDocs = (compId,supprtId,taskId,payload) => async (dispatch) => {
      try {
        const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
        const updatedData = multiPartData({ ...payload });
        dispatch(constructSupportEditDocsInitiate(updatedData));
        const response = await axios.put(`${Url}companies/${compId}/support-doc/${supprtId}?parenttableId=${taskId}&parenttableType=task`,updatedData, {
          headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
        });
        if (response.status == 200) {
          dispatch(constructSupportEditDocsSuccess(response.data));
        } else {
          dispatch(constructSupportEditDocsFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(constructSupportEditDocsFailure(err));
        return err.response
      }
    };

//===============================================supportDocsList==============================


export const constructSupportDocsListInitiate = () => ({
  type: tradeViewAuthAction.CONSTRUCTION_SUPPORT_DOCS_LIST_INITIATE,
});

export const constructSupportDocsListSuccess = (data) => ({
  type: tradeViewAuthAction.CONSTRUCTION_SUPPORT_DOCS_LIST_SUCCESS,
  payload: data,
});

export const constructSupportDocsListFailure = (data) => ({
  type: tradeViewAuthAction.CONSTRUCTION_SUPPORT_DOCS_LIST_FAILURE,
  payload: data,
});

  export const getConstructionSupportDocsList = (vendorId) => async (dispatch) => {
      try {
        const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
        dispatch(constructSupportDocsListInitiate());
        const response = await axios.get(`${Url}construction/getNotesTask/${vendorId}`, {
          headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
        });
        if (response.status == 201) {
          dispatch(constructSupportDocsListSuccess(response.data));
        } else {
          dispatch(constructSupportDocsListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(constructSupportDocsListFailure(err));
        return err.response
      }
    };


//==============================docsAddd============================


export const constructSupportAddDocsInitiate = () => ({
  type: tradeViewAuthAction.CONSTRUCTION_SUPPORT_ADD_DOCS_INITIATE,
});

export const constructSupportAddDocsSuccess = (data) => ({
  type: tradeViewAuthAction.CONSTRUCTION_SUPPORT_ADD_DOCS_SUCCESS,
  payload: data,
});

export const constructSupportAddDocsFailure = (data) => ({
  type: tradeViewAuthAction.CONSTRUCTION_SUPPORT_ADD_DOCS_FAILURE,
  payload: data,
});

  export const getSupportAddDocs = (compId,taskId,payload) => async (dispatch) => {
    
      try {
        const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
        const updatedData = multiPartData({ ...payload });
        dispatch(constructSupportAddDocsInitiate(updatedData));
        const response = await axios.post(`${Url}companies/${compId}/support-doc?parenttableId=${taskId}&parenttableType=task`,updatedData, {
          headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
        });
        if (response.status == 201) {
          dispatch(constructSupportAddDocsSuccess(response.data));
        } else {
          dispatch(constructSupportAddDocsFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(constructSupportAddDocsFailure(err));
        return err.response
      }
    };

//====================================delete=-support-------------------------------

export const constructSupportDeleteDocsInitiate = () => ({
  type: tradeViewAuthAction.CONSTRUCTION_SUPPORT_DOCS_DELETE_INITIATE,
});

export const constructSupportDeleteDocsSuccess = (data) => ({
  type: tradeViewAuthAction.CONSTRUCTION_SUPPORT_DOCS_DELETE_SUCCESS,
  payload: data,
});

export const constructSupportDeleteDocsFailure = (data) => ({
  type: tradeViewAuthAction.CONSTRUCTION_SUPPORT_DOCS_DELETE_FAILURE,
  payload: data,
});

  export const getSupportDeleteDocs = (compId,Id,parntId) => async (dispatch) => {

      try {
        const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
        const { parenttableId: extractedDocId } = parntId;
        dispatch(constructSupportDeleteDocsInitiate(extractedDocId));
        const response = await axios.delete(`${Url}companies/${compId}/support-doc/${Id}?parenttableId=${extractedDocId}&parenttableType=task`, {
          headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
        });
        if (response.status == 200) {
          dispatch(constructSupportDeleteDocsSuccess(response.data));
        } else {
          dispatch(constructSupportDeleteDocsFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(constructSupportDeleteDocsFailure(err));
        return err.response
      }
    };

//=======================================CAlendarData======================================

export const constructCalendarDataInitiate = () => ({
  type: tradeViewAuthAction.CONSTRUCTION_CALENDAR_DATA_INITIATE,
});

export const constructCalendarDataSuccess = (data) => ({
  type: tradeViewAuthAction.CONSTRUCTION_CALENDAR_DATA_SUCCESS,
  payload: data,
});

export const constructCalendarDataFailure = (data) => ({
  type: tradeViewAuthAction.CONSTRUCTION_CALENDAR_DATA_FAILURE,
  payload: data,
});

  export const getCalendarData = (payload) => async (dispatch) => {

      try {
        const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
        dispatch(constructCalendarDataInitiate());
        const response = await axios.post(`${Url}construction/calenderDataForVendor`, payload,{
          headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
        });
        if (response.status == 201) {
          dispatch(constructCalendarDataSuccess(response.data));
        } else {
          dispatch(constructCalendarDataFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(constructCalendarDataFailure(err));
        return err.response
      }
    };

//====================================todojovList====================

export const todoListJobInitiate = () => ({
  type: tradeViewAuthAction.TODO_JOB_LIST_INITIATE,
});

export const todoListJobSuccess = (data) => ({
  type: tradeViewAuthAction.TODO_JOB_LIST_SUCCESS,
  payload: data,
});

export const todoListJobFailure = (data) => ({
  type: tradeViewAuthAction.TODO_JOB_LIST_FAILURE,
  payload: data,
});

export const gettodoListJob = (compId) => async (dispatch) => {
    try {
      const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
      dispatch(todoListJobInitiate(compId));
      const response = await axios.get(`${Url}companies/${compId}/jobs`, {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      });
      if (response.status == 200) {
        dispatch(todoListJobSuccess(response.data));
      } else {
        dispatch(todoListJobFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(todoListJobFailure(err));
      return err.response
    }
  };

//======================================VendorList===========================


export const todoVendorListInitiate = () => ({
  type: tradeViewAuthAction.TODO_VENDOR_LIST_INITIATE,
});

export const todoVendorListSuccess = (data) => ({
  type: tradeViewAuthAction.TODO_VENDOR_LIST_SUCCESS,
  payload: data,
});

export const todoVendorListFailure = (data) => ({
  type: tradeViewAuthAction.TODO_VENDOR_LIST_FAILURE,
  payload: data,
});

export const getTodoVendorList = (compId) => async (dispatch) => {
    try {
      const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
      dispatch(todoVendorListInitiate(compId));
      const response = await axios.get(`${Url}companies/${compId}/vendors`, {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      });
      if (response.status == 200) {
        dispatch(todoVendorListSuccess(response.data));
      } else {
        dispatch(todoVendorListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(todoVendorListFailure(err));
      return err.response
    }
  };

//==================================create-todo-===============================


export const createTodoInitiate = () => ({
  type: tradeViewAuthAction.CREATE_TODO_INITIATE,
});

export const createTodoSuccess = (data) => ({
  type: tradeViewAuthAction.CREATE_TODO_SUCCESS,
  payload: data,
});

export const createTodoFailure = (data) => ({
  type: tradeViewAuthAction.CREATE_TODO_FAILURE,
  payload: data,
});

export const createTodoTask = (compID,viewId,payload) => async (dispatch) => {
    try {
      const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
      const updatedData = multiPartData({ ...payload });
      dispatch(createTodoInitiate(payload));
      const response = await axios.post(`${Url}companies/${compID}/todos?viewType=TradeView&viewId=${viewId}`, updatedData,{
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      });
      if (response.status == 200) {
        dispatch(createTodoSuccess(response.data));
      } else {
        dispatch(createTodoFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createTodoFailure(err));
      return err.response
    }
  };

//=========================todo-task-member-list==============================

export const todoMemberListInitiate = () => ({
  type: tradeViewAuthAction.TODO_MEMBER_LIST_INITIATE,
});

export const todoMemberListSuccess = (data) => ({
  type: tradeViewAuthAction.TODO_MEMBER_LIST_SUCCESS,
  payload: data,
});

export const todoMemberListFailure = (data) => ({
  type: tradeViewAuthAction.TODO_MEMBER_LIST_FAILURE,
  payload: data,
});

export const getTodoMemberList = (compID) => async (dispatch) => {
    try {
      const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
      dispatch(todoMemberListInitiate(compID));
      const response = await axios.get(`${Url}companies/${compID}/user-list`, {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      });
      if (response.status == 200) {
        dispatch(todoMemberListSuccess(response.data));
      } else {
        dispatch(todoMemberListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(todoMemberListFailure(err));
      return err.response
    }
  };

//==========================================todoTaskList===============================================

export const todoTaskListInitiate = () => ({
  type: tradeViewAuthAction.TODO_TASK_LIST_INITIATE,
});

export const todoTaskListSuccess = (data) => ({
  type: tradeViewAuthAction.TODO_TASK_LIST_SUCCESS,
  payload: data,
});

export const todoTaskListFailure = (data) => ({
  type: tradeViewAuthAction.TODO_TASK_LIST_FAILURE,
  payload: data,
});

export const getTodoTaskList = (compID) => async (dispatch) => {
    try {
      const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
      dispatch(todoTaskListSuccess(compID));
      const response = await axios.get(`${Url}companies/${compID}/todos`, {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      });
      if (response.status == 200) {
        dispatch(todoTaskListSuccess(response.data));
      } else {
        dispatch(todoTaskListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(todoTaskListFailure(err));
      return err.response
    }
  };
//==========================UPDATErEminder=========================================


export const todoUpdateReminderInitiate = () => ({
  type: tradeViewAuthAction.TODO_UPDATE_REMINDER_INITIATE,
});

export const todoUpdateReminderSuccess = (data) => ({
  type: tradeViewAuthAction.TODO_UPDATE_REMINDER_SUCCESS,
  payload: data,
});

export const todoUpdateReminderFailure = (data) => ({
  type: tradeViewAuthAction.TODO_UPDATE_REMINDER_FAILURE,
  payload: data,
});

export const UpdateReminder = (compID,taskId,payload) => async (dispatch) => {
    try {
      const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
      dispatch(todoUpdateReminderInitiate(compID,payload));
      const response = await axios.put(`${Url}companies/${compID}/todos/${taskId}/set-reminder`,payload, {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      });
      if (response.status == 200) {
        dispatch(todoUpdateReminderSuccess(response.data));
      } else {
        dispatch(todoUpdateReminderFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(todoUpdateReminderFailure(err));
      return err.response
    }
  };


//---------------------------------GOOglecalendar-----------------------------------------------
export const todoGoogleCalendarInitiate = () => ({
  type: tradeViewAuthAction.TODO_GOOGLE_CALENDAR_DATA_INITIATE,
});

export const todoGoogleCalendarSuccess = (data) => ({
  type: tradeViewAuthAction.TODO_GOOGLE_CALENDAR_DATA_SUCCESS,
  payload: data,
});

export const todoGoogleCalendarFailure = (data) => ({
  type: tradeViewAuthAction.TODO_GOOGLE_CALENDAR_DATA_FAILURE,
  payload: data,
});

export const getTodoGoogleCalendar = (compID,payload) => async (dispatch) => {
  
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(todoGoogleCalendarInitiate(compID,payload));
    const response = await axios.post(`${Url}companies/${compID}/create-google-calender`,payload,{
      headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(todoGoogleCalendarSuccess(response.data));
    } else {
      dispatch(todoGoogleCalendarFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(todoGoogleCalendarFailure(err));
    return err.response;
  }
};


//---------------------------------OUTLOOKcalendar-----------------------------------------------
export const todoOutlookCalendarInitiate = () => ({
  type: tradeViewAuthAction.TODO_OUTLOOK_DATA_INITIATE,
});

export const todoOutlookCalendarSuccess = (data) => ({
  type: tradeViewAuthAction.TODO_OUTLOOK_DATA_SUCCESS,
  payload: data,
});

export const todoOutlookCalendarFailure = (data) => ({
  type: tradeViewAuthAction.TODO_OUTLOOK_DATA_FAILURE,
  payload: data,
});

export const getTodoOutlookCalendar = (compID,payload) => async (dispatch) => {
  
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(todoOutlookCalendarInitiate(payload));
    const response = await axios.post(`${Url}companies/${compID}/create-outlook-calender`,payload,{
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(todoOutlookCalendarSuccess(response.data));
    } else {
      dispatch(todoOutlookCalendarFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(todoOutlookCalendarFailure(err));
    return err.response;
  }
};


//---------------------------------email-calendar-----------------------------------------------
export const todoEmailCalendarInitiate = () => ({
  type: tradeViewAuthAction.TODO_EMAIL_DATA_INITIATE,
});

export const todoEmailCalendarSuccess = (data) => ({
  type: tradeViewAuthAction.TODO_EMAIL_DATA_SUCCESS,
  payload: data,
});

export const todoEmailCalendarFailure = (data) => ({
  type: tradeViewAuthAction.TODO_EMAIL_DATA_SUCCESS,
  payload: data,
});

export const getTodoEmailCalendar = (compId,payload) => async (dispatch) => {
  
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(todoEmailCalendarInitiate(compId,payload));
    const response = await axios.post(`${Url}companies/${compId}/create-yahoo-calender`,payload,{
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(todoEmailCalendarSuccess(response.data));
    } else {
      dispatch(todoEmailCalendarFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(todoEmailCalendarFailure(err));
    return err.response;
  }
};

//=======================================VIEWTask============================================

export const todoViewTaskInitiate = () => ({
  type: tradeViewAuthAction.TODO_VIEW_TASK_DETAILS_INITIATE,
});

export const todoViewTaskSuccess = (data) => ({
  type: tradeViewAuthAction.TODO_VIEW_TASK_DETAILS_SUCCESS,
  payload: data,
});

export const todoViewTaskFailure = (data) => ({
  type: tradeViewAuthAction.TODO_VIEW_TASK_DETAILS_FAILURE,
  payload: data,
});

export const getTodoViewTask = (compID,taskId) => async (dispatch) => {
  
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(todoViewTaskInitiate(taskId));
    const response = await axios.get(`${Url}companies/${compID}/todos/${taskId}`,{
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(todoViewTaskSuccess(response.data));
    } else {
      dispatch(todoViewTaskFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(todoViewTaskFailure(err));
    return err.response;
  }
};

//==============================================view-task-history================================


export const todoViewTaskHistoryInitiate = () => ({
  type: tradeViewAuthAction.TODO_VIEW_TASK_HISTORY_INITIATE,
});

export const todoViewTaskHistorySuccess = (data) => ({
  type: tradeViewAuthAction.TODO_VIEW_TASK_HISTORY_SUCCESS,
  payload: data,
});

export const todoViewTaskHistoryFailure = (data) => ({
  type: tradeViewAuthAction.TODO_VIEW_TASK_HISTORY_FAILURE,
  payload: data,
});

export const getTodoViewTaskHistoryList = (compID,taskId) => async (dispatch) => {
  
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(todoViewTaskHistoryInitiate(taskId));
    const response = await axios.get(`${Url}companies/${compID}/todos/${taskId}/history`,{
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(todoViewTaskHistorySuccess(response.data));
    } else {
      dispatch(todoViewTaskHistoryFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(todoViewTaskHistoryFailure(err));
    return err.response;
  }
};

//============================warramty-work-order-list======================================


export const todoWarrantyWorkOrderListInitiate = () => ({
  type: tradeViewAuthAction.TODO_WARRANTY_WORK_ORDER_LIST_INITIATE,
});

export const todoWarrantyWorkOrderListSuccess = (data) => ({
  type: tradeViewAuthAction.TODO_WARRANTY_WORK_ORDER_LIST_SUCCESS,
  payload: data,
});

export const todoWarrantyWorkOrderListFailure = (data) => ({
  type: tradeViewAuthAction.TODO_WARRANTY_WORK_ORDER_LIST_FAILURE,
  payload: data,
});

export const getTodoWarrantyWorkOrderList = (compID) => async (dispatch) => {
  
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(todoWarrantyWorkOrderListInitiate());
    const response = await axios.get(`${Url}companies/${compID}/all-trade-work-order`,{
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(todoWarrantyWorkOrderListSuccess(response.data));
    } else {
      dispatch(todoWarrantyWorkOrderListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(todoWarrantyWorkOrderListFailure(err));
    return err.response;
  }
};


//========================================getVindorCompanies================================

export const getBuilderListInitiate = () => ({
  type: tradeViewAuthAction.TODO_BUILDER_LIST_INITIATE,
});

export const getBuilderListSuccess = (data) => ({
  type: tradeViewAuthAction.TODO_BUILDER_LIST_SUCCESS,
  payload: data,
});

export const getBuilderListFailure = (data) => ({
  type: tradeViewAuthAction.TODO_BUILDER_LIST_FAILURE,
  payload: data,
});

  export const getTodoBuilderList = (vendorId) => async (dispatch) => {
      try {
        const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
        dispatch(getBuilderListInitiate(vendorId));
        const response = await axios.get(`${Url}vendors/${vendorId}/vendor-companies`, {
          headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
        });
        if (response.status == 200) {
          dispatch(getBuilderListSuccess(response.data));
        } else {
          dispatch(getBuilderListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(getBuilderListFailure(err));
        return err.response
      }
    };
//========================================getaLLproject================================

export const getAllProjectInitiate = () => ({
  type: tradeViewAuthAction.TODO_PORJECT_LIST_INITIATE,
});

export const getAllProjectSuccess = (data) => ({
  type: tradeViewAuthAction.TODO_PORJECT_LIST_SUCCESS,
  payload: data,
});

export const getAllProjectFailure = (data) => ({
  type: tradeViewAuthAction.TODO_PORJECT_LIST_FAILURE,
  payload: data,
});

  export const getTodoAllProjectList = (compId) => async (dispatch) => {
      try {
        const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
        dispatch(getAllProjectInitiate());
        const response = await axios.get(`${Url}companies/${compId}/all-project`, {
          headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` }, 
        });
        if (response.status == 200) {
          dispatch(getAllProjectSuccess(response.data));
        } else {
          dispatch(getAllProjectFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(getAllProjectFailure(err));
        return err.response
      }
    };
//=================================LISTProjectJobs================================


export const projectJobListInitiate = () => ({
  type: tradeViewAuthAction.TODO_ALL_JOB_LIST_INITIATE,
});

export const projectJobListSuccess = (data) => ({
  type: tradeViewAuthAction.TODO_ALL_JOB_LIST_SUCCESS,
  payload: data,
});

export const projectJobListFailure = (data) => ({
  type: tradeViewAuthAction.TODO_ALL_JOB_LIST_FAILURE,
  payload: data,
});

  export const getTodoProjectJobList = (compId,projId) => async (dispatch) => {
      try {
        const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
        dispatch(projectJobListInitiate());
        const response = await axios.get(`${Url}companies/${compId}/projects/${projId}/jobs`, {
          headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
        });
        if (response.status == 200) {
          dispatch(projectJobListSuccess(response.data));
        } else {
          dispatch(projectJobListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(projectJobListFailure(err));
        return err.response
      }
    };


    

//===================================updateWarrantySuportDocs==============================

export const todoWarrantySupportDocsInitiate = () => ({
  type: tradeViewAuthAction.TODO_WARRANTY_SUPPORT_DOCS_INITIATE,
});

export const todoWarrantySupportDocsSuccess = (data) => ({
  type: tradeViewAuthAction.TODO_WARRANTY_SUPPORT_DOCS_SUCCESS,
  payload: data,
});

export const todoWarrantySupportDocsFailure = (data) => ({
  type: tradeViewAuthAction.TODO_WARRANTY_SUPPORT_DOCS_FAILURE,
  payload: data,
});

  export const todoWarrantySupportDocs = (compId,warntId,payload) => async (dispatch) => {
      try {
        const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
        const updatedData = multiPartDatas(payload );
        dispatch(todoWarrantySupportDocsInitiate());
        const response = await axios.put(`${Url}companies/${compId}/warranties/${warntId}/support-doc`,updatedData, {
          headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
        });
        if (response.status == 200) {
          dispatch(todoWarrantySupportDocsSuccess(response.data));
        } else {
          dispatch(todoWarrantySupportDocsFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(todoWarrantySupportDocsFailure(err));
        return err.response
      }
    };

//=================================warranty-Support-Docs=====================================

export const todoWarrantySupportDocsListInitiate = () => ({
  type: tradeViewAuthAction.TODO_WARRANTY_SUPPORT_DOCS_LIST_INITIATE,
});

export const todoWarrantySupportDocsListSuccess = (data) => ({
  type: tradeViewAuthAction.TODO_WARRANTY_SUPPORT_DOCS_LIST_SUCCESS,
  payload: data,
});

export const todoWarrantySupportDocsListFailure = (data) => ({
  type: tradeViewAuthAction.TODO_WARRANTY_SUPPORT_DOCS_LIST_FAILURE,
  payload: data,
});

  export const getTodoWarrantySupportDocsList = (compId,warntId) => async (dispatch) => {
      try {
        const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
        dispatch(todoWarrantySupportDocsListInitiate(compId,warntId));
        const response = await axios.get(`${Url}companies/${compId}/warranties/${warntId}/support-doc`, {
          headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
        });
        if (response.status == 201) {
          dispatch(todoWarrantySupportDocsListSuccess(response.data));
        } else {
          dispatch(todoWarrantySupportDocsListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(todoWarrantySupportDocsListFailure(err));
        return err.response
      }
    };

//================================Todo=--warranty---delete---------------

export const todoWarrantySupportDeleteInitiate = () => ({
  type: tradeViewAuthAction.TODO_WARRANTY_DELETE_INITIATE,
});

export const todoWarrantySupportDeleteSuccess = (data) => ({
  type: tradeViewAuthAction.TODO_WARRANTY_DELETE_SUCCESS,
  payload: data,
});

export const todoWarrantySupportDeleteFailure = (data) => ({
  type: tradeViewAuthAction.TODO_WARRANTY_DELETE_FAILURE,
  payload: data,
});

  export const getSupportDocsDelete = (compId,warntId) => async (dispatch) => {
      try {
        const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
        dispatch(todoWarrantySupportDeleteInitiate(compId,warntId));
        const response = await axios.delete(`${Url}companies/${compId}/warranties/${warntId}`, {
          headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
        });
        if (response.status == 201) {
          dispatch(todoWarrantySupportDeleteSuccess(response.data));
        } else {
          dispatch(todoWarrantySupportDeleteFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(todoWarrantySupportDeleteFailure(err));
        return err.response
      }
    };

//========================================tradeCalendarTaskList============================================

export const todoCalendarTaskListInitiate = () => ({
  type: tradeViewAuthAction.TODO_CALENDAR_TASK_LIST_INITIATE,
});

export const todoCalendarTaskListSuccess = (data) => ({
  type: tradeViewAuthAction.TODO_CALENDAR_TASK_LIST_SUCCESS,
  payload: data,
});

export const todoCalendarTaskListFailure = (data) => ({
  type: tradeViewAuthAction.TODO_CALENDAR_TASK_LIST_FAILURE,
  payload: data,
});

export const getTodoCalendarTaskList = (compId,payload) => async (dispatch) => {
  
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(todoCalendarTaskListInitiate(payload));
    const response = await axios.get(`${Url}companies/${compId}/tasks-calendar?startDate=${payload.startDate}&endDate=${payload.endDate}`,{
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(todoCalendarTaskListSuccess(response.data));
    } else {
      dispatch(todoCalendarTaskListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(todoCalendarTaskListFailure(err));
    return err.response;
  }
};
//=========================================warranty-details=====================


export const todoWarrantyWorkOrderDetailsInitiate = () => ({
  type: tradeViewAuthAction.TODO_WARRANTY_WORK_ORDER_DETAILS_INITIATE,
});

export const todoWarrantyWorkOrderDetailsSuccess = (data) => ({
  type: tradeViewAuthAction.TODO_WARRANTY_WORK_ORDER_DETAILS_SUCCESS,
  payload: data,
});

export const todoWarrantyWorkOrderDetailsFailure = (data) => ({
  type: tradeViewAuthAction.TODO_WARRANTY_WORK_ORDER_DETAILS_FAILURE,
  payload: data,
});

export const getTodoWarrantyWorkOrderDetails = (compId,warntId) => async (dispatch) => {
  
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(todoWarrantyWorkOrderDetailsInitiate());
    const response = await axios.get(`${Url}companies/${compId}/trade-work-order/${warntId}`,{
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(todoWarrantyWorkOrderDetailsSuccess(response.data));
    } else {
      dispatch(todoWarrantyWorkOrderDetailsFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(todoWarrantyWorkOrderDetailsFailure(err));
    return err.response;
  }
};

//=====================================addNotes===============================


export const todoWarrantyWorkOrderAddNotesInitiate = () => ({
  type: tradeViewAuthAction.TODO_WARRANTY_WORK_ORDER_ADD_NOTES_INITIATE,
});

export const todoWarrantyWorkOrderAddNotesSuccess = (data) => ({
  type: tradeViewAuthAction.TODO_WARRANTY_WORK_ORDER_ADD_NOTES_SUCCESS,
  payload: data,
});

export const todoWarrantyWorkOrderAddNotesFailure = (data) => ({
  type: tradeViewAuthAction.TODO_WARRANTY_WORK_ORDER_ADD_NOTES_FAILURE,
  payload: data,
});

export const todoWarrantyWorkOrderAddNotes = (compID,waranId,payload) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    const updatedData = multiPartData({ ...payload });
    dispatch(todoWarrantyWorkOrderAddNotesInitiate(payload));
    const response = await axios.put(`${Url}companies/${compID}/warranties/${waranId}`,updatedData, {
      headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
    });
    if (response.status == 200) {
      dispatch(todoWarrantyWorkOrderAddNotesSuccess(response.data));
    } else {
      dispatch(todoWarrantyWorkOrderAddNotesFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(todoWarrantyWorkOrderAddNotesFailure(err));
    return err.response
  }
};

//=================================getQutoerequest=========================


export const getQuoteRequestInitiate = () => ({
  type: tradeViewAuthAction.GET_QUOTE_REQUEST_INITIATE,
});

export const getQuoteRequestSuccess = (data) => ({
  type: tradeViewAuthAction.GET_QUOTE_REQUEST_SUCCESS,
  payload: data,
});

export const getQuoteRequestFailure = (data) => ({
  type: tradeViewAuthAction.GET_QUOTE_REQUEST_FAILURE,
  payload: data,
});

export const getQuoteRequestList = (compID) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(getQuoteRequestInitiate(compID));
    const response = await axios.get(`${Url}companies/${compID}/quoteRequest`,{
      headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
    });
    if (response.status == 200) {
      dispatch(getQuoteRequestSuccess(response.data));
    } else {
      dispatch(getQuoteRequestFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getQuoteRequestFailure(err));
    return err.response
  }
};


//========================purchase=Order==========================

export const getPurchaseOrderInitiate = () => ({
  type: tradeViewAuthAction.GET_PURCHASE_ORDER_INITIATE,
});

export const getPurchaseOrderSuccess = (data) => ({
  type: tradeViewAuthAction.GET_PURCHASE_ORDER_SUCCESS,
  payload: data,
});

export const getPurchaseOrderFailure = (data) => ({
  type: tradeViewAuthAction.GET_PURCHASE_ORDER_FAILURE,
  payload: data,
});

export const getPurchaseOrderList = (compID) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(getPurchaseOrderInitiate(compID));
    const response = await axios.get(`${Url}companies/${compID}/purchaseorder`,{
      headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
    });
    if (response.status == 200) {
      dispatch(getPurchaseOrderSuccess(response.data));
    } else {
      dispatch(getPurchaseOrderFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getPurchaseOrderFailure(err));
    return err.response
  }
};

//=====================================change-order-list=====================


export const changeOrderChecklistInitiate = () => ({
  type: tradeViewAuthAction.CHANGE_ORDER_CHECKLIST_LIST_INITIATE,
});

export const changeOrderChecklistSuccess = (data) => ({
  type: tradeViewAuthAction.CHANGE_ORDER_CHECKLIST_LIST_SUCCESS,
  payload: data,
});

export const changeOrderChecklistFailure = (data) => ({
  type: tradeViewAuthAction.CHANGE_ORDER_CHECKLIST_LIST_FAILURE,
  payload: data,
});

export const getChangeOrderCheckList = (compID,projId,jobId) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(changeOrderChecklistInitiate());
    const response = await axios.get(`${Url}trade/change-order/checklist?companyId=${compID}&projectId=${projId}&jobId=${jobId}`,{
      headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
    });
    if (response.status == 200) {
      dispatch(changeOrderChecklistSuccess(response.data));
    } else {
      dispatch(changeOrderChecklistFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(changeOrderChecklistFailure(err));
    return err.response
  }
};
//=================================uploadFiles============================

export const changeOrderUploadFilesInitiate = () => ({
  type: tradeViewAuthAction.CHANGE_ORDER_UPLOAD_FILES_INITIATE,
});

export const changeOrderUploadFilesSuccess = (data) => ({
  type: tradeViewAuthAction.CHANGE_ORDER_UPLOAD_FILES_SUCCESS,
  payload: data,
});

export const changeOrderUploadFilesFailure = (data) => ({
  type: tradeViewAuthAction.CHANGE_ORDER_UPLOAD_FILES_FAILURE,
  payload: data,
});

  export const getChangeOrderUploadFiles = (payload) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    const updatedData = multiPartDatas(payload );
    dispatch(changeOrderUploadFilesInitiate(updatedData));
    const response = await axios.post(`${Url}upload/files`,updatedData,{
      headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
    });
    if (response.status == 200) {
      dispatch(changeOrderUploadFilesSuccess(response.data));
    } else {
      dispatch(changeOrderUploadFilesFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(changeOrderUploadFilesFailure(err));
    return err.response
  }
};

//===================================folder-n-files-list=========================

export const getFoldernFileListInitiate = () => ({
  type: tradeViewAuthAction.GET_FOLDER_N_FILE_LIST_INITIATE,
});

export const getFoldernFileListSuccess = (data) => ({
  type: tradeViewAuthAction.GET_FOLDER_N_FILE_LIST_SUCCESS,
  payload: data,
});

export const getFoldernFileListFailure = (data) => ({
  type: tradeViewAuthAction.GET_FOLDER_N_FILE_LIST_FAILURE,
  payload: data,
});

export const getFoldernFileList = (compID,tradId) => async (dispatch) => {

  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(getFoldernFileListInitiate());
    const response = await axios.get(`${Url}companies/${compID}/trade-view/${tradId}/trade-file-manager`,{
      headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
    });
    if (response.status == 200) {
      dispatch(getFoldernFileListSuccess(response.data));
    } else {
      dispatch(getFoldernFileListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getFoldernFileListFailure(err));
    return err.response
  }
};

//=====================document--lst==================================


export const getFolderDocumentListInitiate = () => ({
  type: tradeViewAuthAction.GET_FOLDER_DOCUMENT_LIST_INITIATE,
});

export const getFolderDocumentListSuccess = (data) => ({
  type: tradeViewAuthAction.GET_FOLDER_DOCUMENT_LIST_SUCCESS,
  payload: data,
});

export const getFolderDocumentListFailure = (data) => ({
  type: tradeViewAuthAction.GET_FOLDER_DOCUMENT_LIST_FAILURE,
  payload: data,
});

export const getFolderDocumentList = (compID,tradId) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(getFolderDocumentListInitiate());
    const response = await axios.get(`${Url}companies/${compID}/trade-view/${tradId}/folders/docs`,{
      headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
    });
    if (response.status == 200) {
      dispatch(getFolderDocumentListSuccess(response.data));
    } else {
      dispatch(getFolderDocumentListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getFolderDocumentListFailure(err));
    return err.response
  }
};

//===========================Update-quote-----request=====================


export const updateQuoteRequestInitiate = () => ({
  type: tradeViewAuthAction.UPDATE_QUOTE_REQUEST_INITIATE,
});

export const updateQuoteRequestSuccess = (data) => ({
  type: tradeViewAuthAction.UPDATE_QUOTE_REQUEST_SUCCESS,
  payload: data,
});

export const updateQuoteRequestFailure = (data) => ({
  type: tradeViewAuthAction.UPDATE_QUOTE_REQUEST_FAILURE,
  payload: data,
});

export const updateQuoteRequest = (compID,quotId,payload) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(updateQuoteRequestInitiate(compID,quotId,payload));
    const response = await axios.put(`${Url}companies/${compID}/quoteRequest/${quotId}`,payload,{
      headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
    });
    if (response.status == 200) {
      dispatch(updateQuoteRequestSuccess(response.data));
    } else {
      dispatch(updateQuoteRequestFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(updateQuoteRequestFailure(err));
    return err.response
  }
};

//======================================get-unit-list-=========================

export const unitListInitiate = () => ({
  type: tradeViewAuthAction.UNIT_LIST_INITIATE,
});

export const unitListSuccess = (data) => ({
  type: tradeViewAuthAction.UNIT_LIST_SUCCESS,
  payload: data,
});

export const unitListFailure = (data) => ({
  type: tradeViewAuthAction.UNIT_LIST_FAILURE,
  payload: data,
});

export const getUnitList = () => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(unitListInitiate());
    const response = await axios.get(`${Url}unit`,{
      headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
    });
    if (response.status == 200) {
      dispatch(unitListSuccess(response.data));
    } else {
      dispatch(unitListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(unitListFailure(err));
    return err.response
  }
};

//======================create-unit---------===============

export const createUnitInitiate = () => ({
  type: tradeViewAuthAction.CREATE_UNIT_INITIATE,
});

export const createUnitSuccess = (data) => ({
  type: tradeViewAuthAction.CREATE_UNIT_SUCCESS,
  payload: data,
});

export const createUnitFailure = (data) => ({
  type: tradeViewAuthAction.CREATE_UNIT_FAILURE,
  payload: data,
});

export const createUnit = (payload) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(createUnitInitiate(payload));
    const response = await axios.post(`${Url}unit`,payload,{
      headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
    });
    if (response.status == 200) {
      dispatch(createUnitSuccess(response.data));
    } else {
      dispatch(createUnitFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(createUnitFailure(err));
    return err.response
  }
};

//==============================edit-qr================


export const editQrRequestInitiate = () => ({
  type: tradeViewAuthAction.EDIT_QR_REQUEST_INITIATE,
});

export const editQrRequestSuccess = (data) => ({
  type: tradeViewAuthAction.EDIT_QR_REQUEST_SUCCESS,
  payload: data,
});

export const editQrRequestFailure = (data) => ({
  type: tradeViewAuthAction.EDIT_QR_REQUEST_FAILURE,
  payload: data,
});

export const editQr = (compID,quotId,payload) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(editQrRequestInitiate(compID,quotId,payload));
    const response = await axios.patch(`${Url}companies/${compID}/quoteRequest/${quotId}/vendor`,payload,{
      headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
    });
    if (response.status == 200) {
      dispatch(editQrRequestSuccess(response.data));
    } else {
      dispatch(editQrRequestFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(editQrRequestFailure(err));
    return err.response
  }
};

//===================================getdesign-by-category-id========================

export const getDesignByCategoryInteriorInitiate = () => ({
  type: tradeViewAuthAction.GET_DESIGN_BY_CATEGORY_INTERIOR_FAILURE,
});

export const getDesignByCategoryInteriorSuccess = (data) => ({
  type: tradeViewAuthAction.GET_DESIGN_BY_CATEGORY_INTERIOR_SUCCESS,
  payload: data,
});

export const getDesignByCategoryInteriorFailure = (data) => ({
  type: tradeViewAuthAction.GET_DESIGN_BY_CATEGORY_INTERIOR_FAILURE,
  payload: data,
});

export const getDesignByCategoryInterior = (compID,projId,jobType,jobId) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(getDesignByCategoryInteriorInitiate());
    const response = await axios.get(`${Url}companies/${compID}/projects/${projId}/trade/design-selection/category?jobType=${jobType}&jobId=${jobId}`,{
      headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
    });
    if (response.status == 200) {
      dispatch(getDesignByCategoryInteriorSuccess(response.data));
    } else {
      dispatch(getDesignByCategoryInteriorFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getDesignByCategoryInteriorFailure(err));
    return err.response
  }
};

//========================================EXTERIOR====================


export const getDesignByCategoryExteriorInitiate = () => ({
  type: tradeViewAuthAction.GET_DESIGN_BY_CATEGORY_EXTERIOR_INITIATE,
});

export const getDesignByCategoryExteriorSuccess = (data) => ({
  type: tradeViewAuthAction.GET_DESIGN_BY_CATEGORY_EXTERIOR_SUCCESS,
  payload: data,
});

export const getDesignByCategoryExteriorFailure = (data) => ({
  type: tradeViewAuthAction.GET_DESIGN_BY_CATEGORY_EXTERIOR_FAILURE,
  payload: data,
});

export const getDesignByCategoryExterior = (compID,projId,jobType,jobId) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(getDesignByCategoryExteriorInitiate());
    const response = await axios.get(`${Url}companies/${compID}/projects/${projId}/trade/design-selection/category?selectionType=exterior&jobType=${jobType}&jobId=${jobId}`,{
      headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
    });
    if (response.status == 200) {
      dispatch(getDesignByCategoryExteriorSuccess(response.data));
    } else {
      dispatch(getDesignByCategoryExteriorFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getDesignByCategoryExteriorFailure(err));
    return err.response
  }
};

//==========================GEt-builder--list==================

export const builderListInitiate = () => ({
  type: tradeViewAuthAction.BUILDER_LIST_INITIATE,
});

export const builderListSuccess = (data) => ({
  type: tradeViewAuthAction.BUILDER_LIST_SUCCESS,
  payload: data,
});

export const builderListFailure = (data) => ({
  type: tradeViewAuthAction.BUILDER_LIST_FAILURE,
  payload: data,
});

export const getBuilderList = () => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(builderListInitiate());
    const response = await axios.get(`${Url}trade/appointments/builderList`,{
      headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
    });
    if (response.status == 200) {
      dispatch(builderListSuccess(response.data));
    } else {
      dispatch(builderListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(builderListFailure(err));
    return err.response
  }
};

//=============================get-project-by-builder======================

export const getProjectByBuilderInitiate = () => ({
  type: tradeViewAuthAction.GET_PROJECT_BY_BUILDER_INITIATE,
});

export const getProjectByBuilderSuccess = (data) => ({
  type: tradeViewAuthAction.GET_PROJECT_BY_BUILDER_SUCCESS,
  payload: data,
});

export const getProjectByBuilderFailure = (data) => ({
  type: tradeViewAuthAction.GET_PROJECT_BY_BUILDER_FAILURE,
  payload: data,
});

export const getProjectByBuilder = (compId) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(getProjectByBuilderInitiate());
    const response = await axios.get(`${Url}trade/appointments/builderList/${compId}/projects`,{
      headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
    });
    if (response.status == 200) {
      dispatch(getProjectByBuilderSuccess(response.data));
    } else {
      dispatch(getProjectByBuilderFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getProjectByBuilderFailure(err));
    return err.response
  }
};

//=========================get--job--by--project================

export const getJobByProjectInitiate = () => ({
  type: tradeViewAuthAction.GET_JOB_BY_PROJECT_INITIATE,
});

export const getJobByProjectSuccess = (data) => ({
  type: tradeViewAuthAction.GET_JOB_BY_PROJECT_SUCCESS,
  payload: data,
});

export const getJobByProjectFailure = (data) => ({
  type: tradeViewAuthAction.GET_JOB_BY_PROJECT_FAILURE,
  payload: data,
});

export const getJobByProjectList = (CompanyId,projId) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(getJobByProjectInitiate());
    const response = await axios.get(`${Url}trade/appointments/builderList/${CompanyId}/projects/${projId}/jobs`,{
      headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
    });
    if (response.status == 200) {
      dispatch(getJobByProjectSuccess(response.data));
    } else {
      dispatch(getJobByProjectFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getJobByProjectFailure(err));
    return err.response
  }
};
//================================get-appointment--all==================================


export const getAppointmentAllInitiate = () => ({
  type: tradeViewAuthAction.GET_APPOINTMENT_ALL_INITIATE,
});

export const getAppointmentAllSuccess = (data) => ({
  type: tradeViewAuthAction.GET_APPOINTMENT_ALL_SUCCESS,
  payload: data,
});

export const getAppointmentAllFailure = (data) => ({
  type: tradeViewAuthAction.GET_APPOINTMENT_ALL_FAILURE,
  payload: data,
});

export const getAppointmentAll = (compId,projId,jobId,jobType) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(getAppointmentAllInitiate());
    const response = await axios.get(`${Url}companies/${compId}/projects/${projId}/appointment?jobId=${jobId}&jobType=${jobType}`,{
      headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
    });
    if (response.status == 200) {
      dispatch(getAppointmentAllSuccess(response.data));
    } else {
      dispatch(getAppointmentAllFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getAppointmentAllFailure(err));
    return err.response
  }
};

//=================================get-folder-history--list--------------


export const getFolderHistoryInitiate = () => ({
  type: tradeViewAuthAction.GET_FOLDER_HISTORY_INITIATE,
});

export const getFolderHistorySuccess = (data) => ({
  type: tradeViewAuthAction.GET_FOLDER_HISTORY_SUCCESS,
  payload: data,
});

export const getFolderHistoryFailure = (data) => ({
  type: tradeViewAuthAction.GET_FOLDER_HISTORY_FAILURE,
  payload: data,
});

export const getFolderHistoryList = (compId,foldId) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(getFolderHistoryInitiate());
    const response = await axios.get(`${Url}companies/${compId}/folders/${foldId}/history`,{
      headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
    });
    if (response.status == 200) {
      dispatch(getFolderHistorySuccess(response.data));
    } else {
      dispatch(getFolderHistoryFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getFolderHistoryFailure(err));
    return err.response
  }
};

//==================================set-appointment--reminder==================


export const setAppointmentReminderInitiate = () => ({
  type: tradeViewAuthAction.SET_APPOINTMENT_REMINDER_INITIATE,
});

export const setAppointmentReminderSuccess = (data) => ({
  type: tradeViewAuthAction.SET_APPOINTMENT_REMINDER_SUCCESS,
  payload: data,
});

export const setAppointmentReminderFailure = (data) => ({
  type: tradeViewAuthAction.SET_APPOINTMENT_REMINDER_FAILURE,
  payload: data,
});

export const setAppointmentReminder = (compId,projId,payload) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(setAppointmentReminderInitiate());
    const response = await axios.put(`${Url}companies/${compId}/projects/${projId}/appointment/reminder/set`,payload,{
      headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
    });
    if (response.status == 200) {
      dispatch(setAppointmentReminderSuccess(response.data));
    } else {
      dispatch(setAppointmentReminderFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(setAppointmentReminderFailure(err));
    return err.response
  }
};

//---------------------------------GOOglecalendar-----------------------------------------------
export const googleCalendarInitiate = () => ({
  type: tradeViewAuthAction.GOOGLE_CALENDAR_TRADE_VIEW_INITIATE,
});

export const googleCalendarSuccess = (data) => ({
  type: tradeViewAuthAction.GOOGLE_CALENDAR_TRADE_VIEW_SUCCESS,
  payload: data,
});

export const googleCalendarFailure = (data) => ({
  type: tradeViewAuthAction.GOOGLE_CALENDAR_TRADE_VIEW_FAILURE,
  payload: data,
});

export const getGoogleCalendartTradeView = (compId,payload) => async (dispatch) => {
  
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(googleCalendarInitiate());
    const response = await axios.post(`${Url}companies/${compId}/create-google-calender`,payload,{
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(googleCalendarSuccess(response.data));
    } else {
      dispatch(googleCalendarFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(googleCalendarFailure(err));
    return err.response;
  }
};


//---------------------------------OUTLOOKcalendar-----------------------------------------------
export const outlookCalendarInitiate = () => ({
  type: tradeViewAuthAction.OUTLOOK_CALENDAR_TRADE_VIEW_INITIATE,
});

export const outlookCalendarSuccess = (data) => ({
  type: tradeViewAuthAction.OUTLOOK_CALENDAR_TRADE_VIEW_SUCCESS,
  payload: data,
});

export const outlookCalendarFailure = (data) => ({
  type: tradeViewAuthAction.OUTLOOK_CALENDAR_TRADE_VIEW_FAILURE,
  payload: data,
});

export const getOutlookCalendarTradeView = (compId,payload) => async (dispatch) => {
  
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(outlookCalendarInitiate());
    const response = await axios.post(`${Url}companies/${compId}/create-outlook-calender`,payload,{
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(outlookCalendarSuccess(response.data));
    } else {
      dispatch(outlookCalendarFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(outlookCalendarFailure(err));
    return err.response;
  }
};


//---------------------------------yahoo-calendar-----------------------------------------------
export const emailCalendarInitiate = () => ({
  type: tradeViewAuthAction.YAHOO_CALENDAR_TRADE_VIEW_INITIATE,
});

export const emailCalendarSuccess = (data) => ({
  type: tradeViewAuthAction.YAHOO_CALENDAR_TRADE_VIEW_SUCCESS,
  payload: data,
});

export const emailCalendarFailure = (data) => ({
  type: tradeViewAuthAction.YAHOO_CALENDAR_TRADE_VIEW_FAILURE,
  payload: data,
});

export const getYahooCalendarTradeView = (compId,payload) => async (dispatch) => {
  
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(emailCalendarInitiate());
    const response = await axios.post(`${Url}companies/${compId}/create-yahoo-calender`,payload,{
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(emailCalendarSuccess(response.data));
    } else {
      dispatch(emailCalendarFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(emailCalendarFailure(err));
    return err.response;
  }
}

//=================================get-appointment--pending---list=====================

export const pendingDateSelectionListInitiate = () => ({
  type: tradeViewAuthAction.PENDING_DATE_SELECTION_LIST_INITIATE,
});

export const pendingDateSelectionListSuccess = (data) => ({
  type: tradeViewAuthAction.PENDING_DATE_SELECTION_LIST_SUCCESS,
  payload: data,
});

export const pendingDateSelectionListFailure = (data) => ({
  type: tradeViewAuthAction.PENDING_DATE_SELECTION_LIST_FAILURE,
  payload: data,
});

export const getPendingDateSelectionList = (compId,projId,jobId,jobType,vendId) => async (dispatch) => {
  
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(pendingDateSelectionListInitiate());
    const response = await axios.get(`${Url}companies/${compId}/projects/${projId}/trade/pendingAppointment?jobId=${jobId}&jobType=${jobType}&vendorId=${vendId}`,{
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(pendingDateSelectionListSuccess(response.data));
    } else {
      dispatch(pendingDateSelectionListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(pendingDateSelectionListFailure(err));
    return err.response;
  }
}

//================================ACCept===reject==========appointment==========

export const acceptRejectAppointmentInitiate = () => ({
  type: tradeViewAuthAction.ACCEPT_REJECT_APPOINMENTS_INITIATE,
});

export const acceptRejectAppointmentSuccess = (data) => ({
  type: tradeViewAuthAction.ACCEPT_REJECT_APPOINMENTS_SUCCESS,
  payload: data,
});

export const acceptRejectAppointmentFailure = (data) => ({
  type: tradeViewAuthAction.ACCEPT_REJECT_APPOINMENTS_FAILURE,
  payload: data,
});

export const acceptRejectAppointment = (compId,projId,payload) => async (dispatch) => {
  
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(pendingDateSelectionListInitiate());
    const response = await axios.put(`${Url}companies/${compId}/projects/${projId}/trade/confirmAppointment`,payload,{
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(acceptRejectAppointmentSuccess(response.data));
    } else {
      dispatch(acceptRejectAppointmentFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(acceptRejectAppointmentFailure(err));
    return err.response;
  }
}

//================================CONSTRUCTION=SCHEdule===vendor----pending ==listy==========

export const constructionScheduleVendorPendingListInitiate = () => ({
  type: tradeViewAuthAction.CONSTRUCTION_SCHEDULE_VENDOR_PENDING_LIST_INITIATE,
});

export const constructionScheduleVendorPendingListSuccess = (data) => ({
  type: tradeViewAuthAction.CONSTRUCTION_SCHEDULE_VENDOR_PENDING_LIST_SUCCESS,
  payload: data,
});

export const constructionScheduleVendorPendingListFailure = (data) => ({
  type: tradeViewAuthAction.CONSTRUCTION_SCHEDULE_VENDOR_PENDING_LIST_FAILURE,
  payload: data,
});

export const getConstructionScheduleVendorPendingList = (vendorId,jobId) => async (dispatch) => {
  
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(constructionScheduleVendorPendingListInitiate());
    const response = await axios.get(`${Url}construction/pendingTaskForVendor?vendorId=${vendorId}&jobId=${jobId}`,{
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(constructionScheduleVendorPendingListSuccess(response.data));
    } else {
      dispatch(constructionScheduleVendorPendingListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(constructionScheduleVendorPendingListFailure(err));
    return err.response;
  }
}

//=============================construction-schdule--acept-reject=============

export const constructionScheduleAcceptRejectInitiate = () => ({
  type: tradeViewAuthAction.CONSTRUCTION_SCHEDULE_ACCEPT_REJECT_INITIATE,
});

export const constructionScheduleAcceptRejectSuccess = (data) => ({
  type: tradeViewAuthAction.CONSTRUCTION_SCHEDULE_ACCEPT_REJECT_SUCCESS,
  payload: data,
});

export const constructionScheduleAcceptRejectFailure = (data) => ({
  type: tradeViewAuthAction.CONSTRUCTION_SCHEDULE_ACCEPT_REJECT_FAILURE,
  payload: data,
});

export const constructionScheduleAcceptReject = (payload) => async (dispatch) => {
  
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(constructionScheduleAcceptRejectInitiate());
    const response = await axios.post(`${Url}construction/statusUpdate`,payload,{
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(constructionScheduleAcceptRejectSuccess(response.data));
    } else {
      dispatch(constructionScheduleAcceptRejectFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(constructionScheduleAcceptRejectFailure(err));
    return err.response;
  }
}

//================================html-convertor==========================

export const htmlConverterInitiate = () => ({
  type: tradeViewAuthAction.TRADE_VIEW_HTML_CONVERTOR_INITIATE,
});

export const htmlConverterSuccess = (data) => ({
  type: tradeViewAuthAction.TRADE_VIEW_HTML_CONVERTOR_SUCCESS,
  payload: data,
});

export const  htmlConverterFailure = (data) => ({
  type: tradeViewAuthAction.TRADE_VIEW_HTML_CONVERTOR_FAILURE,
  payload: data,
});

export const getHtmlFiletTradeView = (payload) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(htmlConverterInitiate(payload))
    const response = await axios.get(`${Url}/convertToHtml?url=${payload.url}`,
      {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(htmlConverterSuccess(response.data));
    } else {
      dispatch(htmlConverterFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(htmlConverterFailure(err));
    return err.response;
  }
};
//============================GET-design-category---by---id=================
export const getDesignCategoryByIdInitiate = () => ({
  type: tradeViewAuthAction.GET_DESIGN_CATEGORY_BY_ID_INITIATE,
});

export const getDesignCategoryByIdSuccess = (data) => ({
  type: tradeViewAuthAction.GET_DESIGN_CATEGORY_BY_ID_SUCCESS,
  payload: data,
});

export const  getDesignCategoryByIdFailure = (data) => ({
  type: tradeViewAuthAction.GET_DESIGN_CATEGORY_BY_ID_FAILURE,
  payload: data,
});

export const getDesignCategoryById = (compId,projectId,cateId) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(getDesignCategoryByIdInitiate())
    const response = await axios.get(`${Url}companies/${compId}/projects/${projectId}/design-selection/category/id/${cateId}`,
      {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getDesignCategoryByIdSuccess(response.data));
    } else {
      dispatch(getDesignCategoryByIdFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getDesignCategoryByIdFailure(err));
    return err.response;
  }
}

//=============================get-option===by---category=========================


export const getOptionByCategoryInitiate = () => ({
  type: tradeViewAuthAction.GET_OPTION_BY_CATEGORY_INITIATE,
});

export const getOptionByCategorySuccess = (data) => ({
  type: tradeViewAuthAction.GET_OPTION_BY_CATEGORY_SUCCESS,
  payload: data,
});

export const  getOptionByCategoryFailure = (data) => ({
  type: tradeViewAuthAction.GET_OPTION_BY_CATEGORY_FAILURE,
  payload: data,
});

export const getOptionByCategory = (compId,projectId,cateId) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(getOptionByCategoryInitiate())
    const response = await axios.get(`${Url}companies/${compId}/projects/${projectId}/design-selection/option/category/${cateId}`,
      {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getOptionByCategorySuccess(response.data));
    } else {
      dispatch(getOptionByCategoryFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getOptionByCategoryFailure(err));
    return err.response;
  }
}

//===============================SElected-work--order=========================

export const workOrderSelectedItemInitiate = () => ({
  type: tradeViewAuthAction.WORK_ORDER_SELECTED_ITEM_INITIATE,
});

export const workOrderSelectedItemSuccess = (data) => ({
  type: tradeViewAuthAction.WORK_ORDER_SELECTED_ITEM_SUCCESS,
  payload: data,
});

export const  workOrderSelectedItemFailure = (data) => ({
  type: tradeViewAuthAction.WORK_ORDER_SELECTED_ITEM_FAILURE,
  payload: data,
});

export const getWorkOrderSelectedItem = (compId,workId) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(workOrderSelectedItemInitiate())
    const response = await axios.get(`${Url}companies/${compId}/work-orders/${workId}/selected-work-order-request-items`,
      {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(workOrderSelectedItemSuccess(response.data));
    } else {
      dispatch(workOrderSelectedItemFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(workOrderSelectedItemFailure(err));
    return err.response;
  }
}
//===========================Action---required----sales---document--list====

export const actionRequriedSaleDocumentListInitiate = () => ({
  type: tradeViewAuthAction.ACTION_REQURIED_SALES_DOCUMENT_LIST_INITIATE,
});

export const actionRequriedSaleDocumentListSuccess = (data) => ({
  type: tradeViewAuthAction.ACTION_REQURIED_SALES_DOCUMENT_LIST_SUCCESS,
  payload: data,
});

export const  actionRequriedSaleDocumentListFailure = (data) => ({
  type: tradeViewAuthAction.ACTION_REQURIED_SALES_DOCUMENT_LIST_FAILURE,
  payload: data,
});

export const actionRequriedSaleDocumentList = (compId) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(actionRequriedSaleDocumentListInitiate())
    const response = await axios.get(`${Url}companies/${compId}/pending-trade-signatures`,
      {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(actionRequriedSaleDocumentListSuccess(response.data));
    } else {
      dispatch(actionRequriedSaleDocumentListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(actionRequriedSaleDocumentListFailure(err));
    return err.response;
  }
}

//==============================ACCEPRT-PO====================

export const acceptPoInitiate = () => ({
  type: tradeViewAuthAction.ACCEPT_PURCHASE_ORDER_INITIATE,
});

export const acceptPoSuccess = (data) => ({
  type: tradeViewAuthAction.ACCEPT_PURCHASE_ORDER_SUCCESS,
  payload: data,
});

export const  acceptPoFailure = (data) => ({
  type: tradeViewAuthAction.ACCEPT_PURCHASE_ORDER_FAILURE,
  payload: data,
});

export const acceptPo = (compId,PO) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(acceptPoInitiate())
    const response = await axios.patch(`${Url}companies/${compId}/purchaseorder/${PO}/accept`,{},
      {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(acceptPoSuccess(response.data));
    } else {
      dispatch(acceptPoFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(acceptPoFailure(err));
    return err.response;
  }
}

//===============================reject-po=============================

export const rejectPoInitiate = () => ({
  type: tradeViewAuthAction.ACCEPT_PURCHASE_ORDER_INITIATE,
});

export const rejectPoSuccess = (data) => ({
  type: tradeViewAuthAction.ACCEPT_PURCHASE_ORDER_SUCCESS,
  payload: data,
});

export const  rejectPoFailure = (data) => ({
  type: tradeViewAuthAction.ACCEPT_PURCHASE_ORDER_FAILURE,
  payload: data,
});

export const rejectPo = (compId,PO,payload) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(rejectPoInitiate())
    const response = await axios.patch(`${Url}companies/${compId}/purchaseorder/${PO}/reject`,{},
      {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(rejectPoSuccess(response.data));
    } else {
      dispatch(rejectPoFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(rejectPoFailure(err));
    return err.response;
  }
}

//==============================================GET_LIST_CHECKLIST_CHANGE_ORDER==============

export const getListCheckListChangeOrderInitiate = () => ({
  type: tradeViewAuthAction.GET_LIST_CHECKLIST_CHANGE_ORDER_INITIATE,
});

export const getListCheckListChangeOrderSuccess = (data) => ({
  type: tradeViewAuthAction.GET_LIST_CHECKLIST_CHANGE_ORDER_SUCCESS,
  payload: data,
});

export const  getListCheckListChangeOrderFailure = (data) => ({
  type: tradeViewAuthAction.GET_LIST_CHECKLIST_CHANGE_ORDER_FAILURE,
  payload: data,
});

export const getListCheckListChangeOrder = (compId,projectId,jobId) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(getListCheckListChangeOrderInitiate())
    const response = await axios.get(`${Url}trade/change-order/checklist?companyId=${compId}&projectId=${projectId}&jobId=${jobId}`,{
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },}
    );
    if (response.status === 200) {
      dispatch(getListCheckListChangeOrderSuccess(response.data));
    } else {
      dispatch(getListCheckListChangeOrderFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getListCheckListChangeOrderFailure(err));
    return err.response;
  }
}

//================================JOBlIST============================

export const tradeListJobInitiate = () => ({
  type: tradeViewAuthAction.TRADE_VIEW_JOB_LIST_INITIATE,
});

export const tradeListJobSuccess = (data) => ({
  type: tradeViewAuthAction.TRADE_VIEW_JOB_LIST_SUCCESS,
  payload: data,
});

export const tradeListJobFailure = (data) => ({
  type: tradeViewAuthAction.TRADE_VIEW_JOB_LIST_FAILURE,
  payload: data,
});

export const getTradeListJob = (companyId) => async (dispatch) => {
    try {
      const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
      dispatch(tradeListJobInitiate());
      const response = await axios.get(`${Url}companies/${companyId}/jobs`, {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      });
      if (response.status == 200) {
        dispatch(tradeListJobSuccess(response.data));
      } else {
        dispatch(tradeListJobFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(tradeListJobFailure(err));
      return err.response
    }
  };

  //---------------------------------MemberList----------------------------------------------------
export const tradeMemberListInitiate = () => ({
  type: tradeViewAuthAction.TRADE_VIEW_MEMBER_LIST_INITIATE,
});

export const tradeMemberListSuccess = (data) => ({
  type: tradeViewAuthAction.TRADE_VIEW_MEMBER_LIST_SUCCESS,
  payload: data,
});

export const tradeMemberListFailure = (data) => ({
  type: tradeViewAuthAction.TRADE_VIEW_MEMBER_LIST_FAILURE,
  payload: data,
});

export const getTradeTaskMemberList = (companyId) => async (dispatch) => {
    try {
      const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
      dispatch(tradeMemberListInitiate(companyId));
      const response = await axios.get(`${Url}companies/${companyId}/user-list`, {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      });
      if (response.status == 200) {
        dispatch(tradeMemberListSuccess(response.data));
      } else {
        dispatch(tradeMemberListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(tradeMemberListFailure(err));
      return err.response
    }
  };

  
 //=======================================CRAETE--TODO======================
 export const addTodoInitiate = () => ({
  type: tradeViewAuthAction.CREATE_TODO_TRADE_VIEW_INITIATE,
});

export const addTodoSuccess = (data) => ({
  type: tradeViewAuthAction.CREATE_TODO_TRADE_VIEW_SUCCESS,
  payload: data,
});

export const addTodoFailure = (data) => ({
  type: tradeViewAuthAction.CREATE_TODO_TRADE_VIEW_FAILURE,
  payload: data,
});

export const addTodoTradeView = (companyId,viewId,payload) => async (dispatch) => {
  const updatedData = multiPartData({  ...payload });
    try {
      const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
      dispatch(addTodoInitiate(payload));
      const response = await axios.post(`${Url}companies/${companyId}/todos?viewId=${viewId}&viewType=Trade View&module=changeOrdersChecklist`,updatedData, {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      });
      if (response.status == 201) {
        dispatch(addTodoSuccess(response.data));
      } else {
        dispatch(addTodoFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addTodoFailure(err));
      return err.response
    }
  };

//==============================create--trade-view------------signatures==============

export const craeteTradeViewSignaturesInitiate = () => ({
  type: tradeViewAuthAction.CREATE_TRADE_VIEW_SIGNATURES_INITIATE,
});

export const craeteTradeViewSignaturesSuccess = (data) => ({
  type: tradeViewAuthAction.CREATE_TRADE_VIEW_SIGNATURES_SUCCESS,
  payload: data,
});

export const craeteTradeViewSignaturesFailure = (data) => ({
  type: tradeViewAuthAction.CREATE_TRADE_VIEW_SIGNATURES_FAILURE,
  payload: data,
});

export const craeteTradeViewSignatures = (companyId,payload) => async (dispatch) => {
  // const updatedData = multiPartData({  ...payload });
    try {
      const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
      dispatch(craeteTradeViewSignaturesInitiate(payload));
      const response = await axios.post(`${Url}companies/${companyId}/trade-view-manage-signatures`,payload, {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      });
      if (response.status == 201) {
        dispatch(craeteTradeViewSignaturesSuccess(response.data));
      } else {
        dispatch(craeteTradeViewSignaturesFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(craeteTradeViewSignaturesFailure(err));
      return err.response
    }
  };

//==========================retun ===quote ---request-----------

export const returnQuoteRequestInitiate = () => ({
  type: tradeViewAuthAction.RETURN_QUOTE_REQUEST_INITIATE,
});

export const returnQuoteRequestSuccess = (data) => ({
  type: tradeViewAuthAction.RETURN_QUOTE_REQUEST_SUCCESS,
  payload: data,
});

export const returnQuoteRequestFailure = (data) => ({
  type: tradeViewAuthAction.RETURN_QUOTE_REQUEST_FAILURE,
  payload: data,
});

export const returnQuoteRequest = (companyId,payload) => async (dispatch) => {
    try {
      const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
      dispatch(returnQuoteRequestInitiate());
      const response = await axios.patch(`${Url}companies/${companyId}/quoteRequest/return`,payload, {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      });
      if (response.status == 200) {
        dispatch(returnQuoteRequestSuccess(response.data));
      } else {
        dispatch(returnQuoteRequestFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(returnQuoteRequestFailure(err));
      return err.response
    }
  };

//===========================accept--pending--purchse------=====

export const acceptPendingPurchasetInitiate = () => ({
  type: tradeViewAuthAction.ACCEPT_PENIDNG_PURCHASE_INITIATE,
});

export const acceptPendingPurchaseSuccess = (data) => ({
  type: tradeViewAuthAction.ACCEPT_PENIDNG_PURCHASE_SUCCESS,
  payload: data,
});

export const acceptPendingPurchaseFailure = (data) => ({
  type: tradeViewAuthAction.ACCEPT_PENIDNG_PURCHASE_FAILURE,
  payload: data,
});

export const acceptPendingPurchase = (companyId,payload) => async (dispatch) => {
    try {
      const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
      dispatch(acceptPendingPurchasetInitiate());
      const response = await axios.patch(`${Url}companies/${companyId}/purchaseorder/accept`,payload, {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      });
      if (response.status == 200) {
        dispatch(acceptPendingPurchaseSuccess(response.data));
      } else {
        dispatch(acceptPendingPurchaseFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(acceptPendingPurchaseFailure(err));
      return err.response
    }
  };
//==========================role-list=======================

export const getRolesForTradeViewInitiate = () => ({
  type: tradeViewAuthAction.GET_ROLES_FOR_TRADE_VIEW_INITIATE,
});

export const getRolesForTradeViewSuccess = (data) => ({
  type: tradeViewAuthAction.GET_ROLES_FOR_TRADE_VIEW_SUCCESS,
  payload: data,
});

export const getRolesForTradeViewFailure = (data) => ({
  type: tradeViewAuthAction.GET_ROLES_FOR_TRADE_VIEW_FAILURE,
  payload: data,
});

export const getRolesForTradeView = (companyId,vendId) => async (dispatch) => {
    try {
      const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
      dispatch(getRolesForTradeViewInitiate());
      const response = await axios.get(`${Url}companies/${companyId}/vendors/${vendId}/trade-view-roles`, {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      });
      if (response.status == 200) {
        dispatch(getRolesForTradeViewSuccess(response.data));
      } else {
        dispatch(getRolesForTradeViewFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getRolesForTradeViewFailure(err));
      return err.response
    }
  };

//====================all-project==========================

export const getAllProjectTradeViewInitiate = () => ({
  type: tradeViewAuthAction.GET_ALL_PROJECTS_TRADE_VIEW_INITIATE,
});

export const getAllProjectTradeViewSuccess = (data) => ({
  type: tradeViewAuthAction.GET_ALL_PROJECTS_TRADE_VIEW_SUCCESS,
  payload: data,
});

export const getAllProjectTradeViewFailure = (data) => ({
  type: tradeViewAuthAction.GET_ALL_PROJECTS_TRADE_VIEW_FAILURE,
  payload: data,
});

export const getAllProjectTradeView = (companyId) => async (dispatch) => {
    try {
      const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
      dispatch(getAllProjectTradeViewInitiate());
      const response = await axios.get(`${Url}companies/${companyId}/all-project`, {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      });
      if (response.status == 200) {
        dispatch(getAllProjectTradeViewSuccess(response.data));
      } else {
        dispatch(getAllProjectTradeViewFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getAllProjectTradeViewFailure(err));
      return err.response
    }
  };

//=======================================ADD--VENDOR=============USER===============


export const tradeViewAddVendorUserInitiate = () => ({
  type: tradeViewAuthAction.TRADE_VIEW_ADD_VENDOR_USER_INITIATE,
});

export const tradeViewAddVendorUserSuccess = (data) => ({
  type: tradeViewAuthAction.TRADE_VIEW_ADD_VENDOR_USER_SUCCESS,
  payload: data,
});

export const tradeViewAddVendorUserFailure = (data) => ({
  type: tradeViewAuthAction.TRADE_VIEW_ADD_VENDOR_USER_SUCCESS,
  payload: data,
});

export const tradeViewAddVendorUser = (companyId,viewId,vendId,payload) => async (dispatch) => {
    try {
      const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
      dispatch(tradeViewAddVendorUserInitiate());
      const response = await axios.post(`${Url}companies/${companyId}/trade-view/${viewId}/vendors/${vendId}/add-users?viewType=Trade&module=home&viewId=${viewId}`,payload, {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      });
      if (response.status == 201) {
        dispatch(tradeViewAddVendorUserSuccess(response.data));
      } else {
        dispatch(tradeViewAddVendorUserFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(tradeViewAddVendorUserFailure(err));
      return err.response
    }
  };

//==================================get-vendor-user-list=====================

export const getVendorUserInitiate = () => ({
  type: tradeViewAuthAction.GET_VENDOR_USER_INITIATE,
});

export const getVendorUserSuccess = (data) => ({
  type: tradeViewAuthAction.GET_VENDOR_USER_SUCCESS,
  payload: data,
});

export const  getVendorUserFailure = (data) => ({
  type: tradeViewAuthAction.GET_VENDOR_USER_FAILURE,
  payload: data,
});

export const getVendorUser = (companyId,viewId,vendId) => async (dispatch) => {
    try {
      const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
      dispatch(getVendorUserInitiate());
      const response = await axios.get(`${Url}companies/${companyId}/trade-view/${viewId}/vendors/${vendId}/users-list`, {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      });
      if (response.status === 200) {
        dispatch(getVendorUserSuccess(response.data));
      } else {
        dispatch(getVendorUserFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getVendorUserFailure(err));
      return err.response
    }
  };
//=========================SEND--RESET --PASSWORD=================

export const tradeUserResetPasswordEmailSendInitiate = () => ({
  type: tradeViewAuthAction.TRADE_USER_RESET_PASSWORD_EMAIL_SEND_INITIATE,
});

export const tradeUserResetPasswordEmailSendSuccess = (data) => ({
  type: tradeViewAuthAction.TRADE_USER_RESET_PASSWORD_EMAIL_SEND_SUCCESS,
  payload: data,
});

export const  tradeUserResetPasswordEmailSendFailure = (data) => ({
  type: tradeViewAuthAction.TRADE_USER_RESET_PASSWORD_EMAIL_SEND_FAILURE,
  payload: data,
});

export const tradeUserResetPasswordEmailSend = (payload) => async (dispatch) => {
    try {
      const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
      dispatch(tradeUserResetPasswordEmailSendInitiate());
      const response = await axios.put(`${Url}trade-user/send-reset`,payload, {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      });
      if (response.status === 200) {
        dispatch(tradeUserResetPasswordEmailSendSuccess(response.data));
      } else {
        dispatch(tradeUserResetPasswordEmailSendFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(tradeUserResetPasswordEmailSendFailure(err));
      return err.response
    }
  };

//==================================UPDATE--VENDOR0----USER================


export const updateVendorUserInitiate = () => ({
  type: tradeViewAuthAction.UPDATE_VENDOR_USER_INITIATE,
});

export const updateVendorUserSuccess = (data) => ({
  type: tradeViewAuthAction.UPDATE_VENDOR_USER_SUCCESS,
  payload: data,
});

export const updateVendorUserFailure = (data) => ({
  type: tradeViewAuthAction.UPDATE_VENDOR_USER_FAILURE,
  payload: data,
});

export const updateTradeViewVendorUser = (companyId,viewId,vendId,userId,payload) => async (dispatch) => {
    try {
      const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
      dispatch(updateVendorUserInitiate());
      const response = await axios.put(`${Url}companies/${companyId}/trade-view/${viewId}/vendors/${vendId}/users/${userId}?viewType=Trade&module=home&viewId=${viewId}`,payload, {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      });
      if (response.status == 201) {
        dispatch(updateVendorUserSuccess(response.data));
      } else {
        dispatch(updateVendorUserFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateVendorUserFailure(err));
      return err.response
    }
  };

//==============================GET-notification==================

export const getNotificationsInitiate = () => ({
  type: tradeViewAuthAction.GET_TRADE_NOTIFICATIONS_INITIATE,
});

export const getNotificationsSuccess = (data) => ({
  type: tradeViewAuthAction.GET_TRADE_NOTIFICATIONS_SUCCESS,
  payload: data,
});

export const  getNotificationsFailure = (data) => ({
  type: tradeViewAuthAction.GET_TRADE_NOTIFICATIONS_FAILURE,
  payload: data,
});

export const getTradeNotifications = () => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(getNotificationsInitiate())
    const response = await axios.get(`${Url}companies/${tradeViewData?.user?.CompanyId}/notifications?timeFrame=Today`,
      {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getNotificationsSuccess(response.data));
    } else {
      dispatch(getNotificationsFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getNotificationsFailure(err));
    return err.response;
  }
};

//==========================add--discusiion====================

export const addDiscussionInitiate = () => ({
  type: tradeViewAuthAction.ADD_DISCUSSION_TRADE_VIEW_INITIATE,
});

export const addDiscussionSuccess = (data) => ({
  type: tradeViewAuthAction.ADD_DISCUSSION_TRADE_VIEW_SUCCESS,
  payload: data,
});

export const  addDiscussionFailure = (data) => ({
  type: tradeViewAuthAction.ADD_DISCUSSION_TRADE_VIEW_FAILURE,
  payload: data,
});

export const addDiscussionTradeView = (payload) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(addDiscussionInitiate())
    const response = await axios.post(`${Url}/construction/createDiscussion`,payload,
      {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 201) {
      dispatch(addDiscussionSuccess(response.data));
    } else {
      dispatch(addDiscussionFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(addDiscussionFailure(err));
    return err.response;
  }
};
//=======================discussion-list====================

export const listDiscussionInitiate = () => ({
  type: tradeViewAuthAction.LIST_DISCUSSION_TRADE_VIEW_INITIATE,
});

export const listDiscussionSuccess = (data) => ({
  type: tradeViewAuthAction.LIST_DISCUSSION_TRADE_VIEW_SUCCESS,
  payload: data,
});

export const  listDiscussionFailure = (data) => ({
  type: tradeViewAuthAction.LIST_DISCUSSION_TRADE_VIEW_FAILURE,
  payload: data,
});

export const getDiscussionListTradeView = (payload) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(listDiscussionInitiate())
    const response = await axios.get(`${Url}/construction/discussionList`,
      {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
        params: payload
      }
    );
    if (response.status === 201) {
      dispatch(listDiscussionSuccess(response.data));
    } else {
      dispatch(listDiscussionFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(listDiscussionFailure(err));
    return err.response;
  }
};
//==============================ADD-REPLY==================

export const addReplyInitiate = () => ({
  type: tradeViewAuthAction.ADD_REPLY_TRADE_VIEW_INITIATE,
});

export const addReplySuccess = (data) => ({
  type: tradeViewAuthAction.ADD_REPLY_TRADE_VIEW_SUCCESS,
  payload: data,
});

export const  addReplyFailure = (data) => ({
  type: tradeViewAuthAction.ADD_REPLY_TRADE_VIEW_FAILURE,
  payload: data,
});

export const addReplyTradeView = (payload) => async (dispatch) => {
  try {
    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
    dispatch(addReplyInitiate())
    const response = await axios.post(`${Url}/construction/replyDiscussion`,payload,
      {
        headers: { Authorization: `Bearer ${tradeViewData?.user?.token}` },
      }
    );
    if (response.status === 201) {
      dispatch(addReplySuccess(response.data));
    } else {
      dispatch(addReplyFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(addReplyFailure(err));
    return err.response;
  }
};

//========================EDIT--QOUTE--REQUEST--BY--ID==================
export const getEditQouteRequestByIdInitiate = () => ({
  type: tradeViewAuthAction.GET_EDIT_QUOTE_REQUEST_BY_ID_INITIATE,
});

export const getEditQouteRequestByIdSuccess = (data) => ({
  type: tradeViewAuthAction.GET_EDIT_QUOTE_REQUEST_BY_ID_SUCCESS,
  payload: data,
});

export const  getEditQouteRequestByIdFailure = (data) => ({
  type: tradeViewAuthAction.GET_EDIT_QUOTE_REQUEST_BY_ID_FAILURE,
  payload: data,
});

export const getEditQouteRequestById = (companyId,token,documentId) => async (dispatch) => {
  try {
    dispatch(getEditQouteRequestByIdInitiate())
    const response = await axios.get(`${Url}/companies/${companyId}/quoteRequest/${documentId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getEditQouteRequestByIdSuccess(response.data));
    } else {
      dispatch(getEditQouteRequestByIdFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getEditQouteRequestByIdFailure(err));
    return err.response;
  }
};

//========================UPDATE--eDIT--QUOTE--REQUEST==================

export const updateEditQouteRequestByIdInitiate = () => ({
  type: tradeViewAuthAction.UPDATE_EDIT_QUOTE_REQUEST_BY_ID_INITIATE,
});

export const updateEditQouteRequestByIdSuccess = (data) => ({
  type: tradeViewAuthAction.UPDATE_EDIT_QUOTE_REQUEST_BY_ID_SUCCESS,
  payload: data,
});

export const  updateEditQouteRequestByIdFailure = (data) => ({
  type: tradeViewAuthAction.UPDATE_EDIT_QUOTE_REQUEST_BY_ID_FAILURE,
  payload: data,
});

export const updateEditQouteRequestById = (companyId,token,documentId,payload) => async (dispatch) => {
  try {
    dispatch(getEditQouteRequestByIdInitiate())
    const response = await axios.patch(`${Url}/companies/${companyId}/quoteRequest/${documentId}/vendor`,payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(updateEditQouteRequestByIdSuccess(response.data));
    } else {
      dispatch(updateEditQouteRequestByIdFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(updateEditQouteRequestByIdFailure(err));
    return err.response;
  }
};
//=====================edi--get--po----====================
export const getEditPOByIdInitiate = () => ({
  type: tradeViewAuthAction.GET_EDIT_PURCHSE_ORDER_BY_ID_INITIATE,
});

export const getEditPOSuccess = (data) => ({
  type: tradeViewAuthAction.GET_EDIT_PURCHSE_ORDER_BY_ID_SUCCESS,
  payload: data,
});

export const  getEditPOFailure = (data) => ({
  type: tradeViewAuthAction.GET_EDIT_PURCHSE_ORDER_BY_ID_FAILURE,
  payload: data,
});

export const getEditPOById = (companyId,token,documentId) => async (dispatch) => {
  try {
    dispatch(getEditPOByIdInitiate())
    const response = await axios.get(`${Url}/companies/${companyId}/purchaseorder/${documentId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getEditPOSuccess(response.data));
    } else {
      dispatch(getEditPOFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getEditPOFailure(err));
    return err.response;
  }
};

//===========================ACCEPT---REJECT==================EDIT---PO============

export const acceptRejectEditPOInitiate = () => ({
  type: tradeViewAuthAction.ACCEPT_REJECT_EDIT_PURCHSE_ORDER_INITIATE,
});

export const acceptRejectEditPOSuccess = (data) => ({
  type: tradeViewAuthAction.ACCEPT_REJECT_EDIT_PURCHSE_ORDER_SUCCESS,
  payload: data,
});

export const  acceptRejectEditPOFailure = (data) => ({
  type: tradeViewAuthAction.ACCEPT_REJECT_EDIT_PURCHSE_ORDER_FAILURE,
  payload: data,
});

export const acceptRejectEditPO = (companyId,documentId,token) => async (dispatch) => {
  try {
    dispatch(acceptRejectEditPOInitiate())
    const response = await axios.patch(`${Url}/companies/${companyId}/purchaseorder/${documentId}/accept`,{},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(acceptRejectEditPOSuccess(response.data));
    } else {
      dispatch(acceptRejectEditPOFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(acceptRejectEditPOFailure(err));
    return err.response;
  }
};

//===============================reject--po=================


export const rejectPOInitiate = () => ({
  type: tradeViewAuthAction.REJECT_PURCHSE_ORDER_INITIATE,
});

export const rejectPOSuccess = (data) => ({
  type: tradeViewAuthAction.REJECT_PURCHSE_ORDER_SUCCESS,
  payload: data,
});

export const  rejectPOFailure = (data) => ({
  type: tradeViewAuthAction.REJECT_PURCHSE_ORDER_FAILURE,
  payload: data,
});

export const rejectPOs = (companyId,documentId,token) => async (dispatch) => {
  try {
    dispatch(rejectPOInitiate())
    const response = await axios.patch(`${Url}/companies/${companyId}/purchaseorder/${documentId}/reject`,{},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(rejectPOSuccess(response.data));
    } else {
      dispatch(rejectPOFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(rejectPOFailure(err));
    return err.response;
  }
};

//==========================GET--UNIT===============

export const getUnitQrInitiate = () => ({
  type: tradeViewAuthAction.GET_UNIT_QR_INITIATE,
});

export const getUnitQrSuccess = (data) => ({
  type: tradeViewAuthAction.GET_UNIT_QR_SUCCESS,
  payload: data,
});

export const  getUnitQrFailure = (data) => ({
  type: tradeViewAuthAction.GET_UNIT_QR_SUCCESS,
  payload: data,
});

export const getUnitQr = (token) => async (dispatch) => {
  try {
    dispatch(getUnitQrInitiate())
    const response = await axios.get(`${Url}/unit`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getUnitQrSuccess(response.data));
    } else {
      dispatch(getUnitQrFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getUnitQrFailure(err));
    return err.response;
  }
};

//=====================UPLOAD--ADDITINAL=============================


export const uploadAdditionalDocumentsQrInitiate = () => ({
  type: tradeViewAuthAction.UPLOAD_ADDITIONAL_DOCUMENTS_QR_INITIATE,
});

export const uploadAdditionalDocumentsQrSuccess = (data) => ({
  type: tradeViewAuthAction.UPLOAD_ADDITIONAL_DOCUMENTS_QR_SUCCESS,
  payload: data,
});

export const  uploadAdditionalDocumentsQrFailure = (data) => ({
  type: tradeViewAuthAction.UPLOAD_ADDITIONAL_DOCUMENTS_QR_FAILURE,
  payload: data,
});

export const uploadAdditionalDocumentsQr = (companyId,documentId,documentType,token,payload) => async (dispatch) => {
  try {
    dispatch(uploadAdditionalDocumentsQrInitiate())
    const response = await axios.post(`${Url}/companies/${companyId}/support-doc?parenttableId=${documentId}&parenttableType=${documentType}&isImmutableUrl=true`,payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 201) {
      dispatch(uploadAdditionalDocumentsQrSuccess(response.data));
    } else {
      dispatch(uploadAdditionalDocumentsQrFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(uploadAdditionalDocumentsQrFailure(err));
    return err.response;
  }
};
//=============================GET-fPO-BY--ID================

export const getFPOByIdInitiate = () => ({
  type: tradeViewAuthAction.GET_FIELD_PURCHASE_ORDER_BY_ID_INITIATE,
});

export const getFPOByIdSuccess = (data) => ({
  type: tradeViewAuthAction.GET_FIELD_PURCHASE_ORDER_BY_ID_SUCCESS,
  payload: data,
});

export const  getFPOByIdFailure = (data) => ({
  type: tradeViewAuthAction.GET_FIELD_PURCHASE_ORDER_BY_ID_FAILURE,
  payload: data,
});

export const getFPOById = (companyId,documentId,token) => async (dispatch) => {
  try {
    dispatch(getFPOByIdInitiate())
    const response = await axios.get(`${Url}companies/${companyId}/fieldpurchaseorder/${documentId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getFPOByIdSuccess(response.data));
    } else {
      dispatch(getFPOByIdFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getFPOByIdFailure(err));
    return err.response;
  }
};
//=======================get--SC=byId===============================

export const getSCByIdInitiate = () => ({
  type: tradeViewAuthAction.GET_SUPPLIER_CREDIT_BY_ID_INITIATE,
});

export const getSCByIdSuccess = (data) => ({
  type: tradeViewAuthAction.GET_SUPPLIER_CREDIT_BY_ID_SUCCESS,
  payload: data,
});

export const  getSCByIdFailure = (data) => ({
  type: tradeViewAuthAction.GET_SUPPLIER_CREDIT_BY_ID_FAILURE,
  payload: data,
});

export const getSCById = (companyId,documentId,token) => async (dispatch) => {
  try {
    dispatch(getSCByIdInitiate())
    const response = await axios.get(`${Url}companies/${companyId}/suppliercredit/${documentId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getSCByIdSuccess(response.data));
    } else {
      dispatch(getSCByIdFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getSCByIdFailure(err));
    return err.response;
  }
};

//==============================GET-DISCUSSIONLIST=================

export const getDiscussionListInitiate = () => ({
  type: tradeViewAuthAction.GET_DISCUSSION_LISTS_INITIATE,
});

export const getDiscussionListSuccess = (data) => ({
  type: tradeViewAuthAction.GET_DISCUSSION_LISTS_SUCCESS,
  payload: data,
});

export const  getDiscussionListFailure = (data) => ({
  type: tradeViewAuthAction.GET_DISCUSSION_LISTS_FAILURE,
  payload: data,
});

export const getDiscussionList = (jobId,token) => async (dispatch) => {
  try {
    dispatch(getDiscussionListInitiate())
    const response = await axios.get(`${Url}construction/discussionList?jobId=${jobId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 201) {
      dispatch(getDiscussionListSuccess(response.data));
    } else {
      dispatch(getDiscussionListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getDiscussionListFailure(err));
    return err.response;
  }
};

//===============================ADD-REPLY=========================

export const  addDiscussionReplysInitiate = () => ({
  type: tradeViewAuthAction.ADD_DISCUSSION_REPLYS_INITIATE,
});

export const addDiscussionReplysSuccess = (data) => ({
  type: tradeViewAuthAction.ADD_DISCUSSION_REPLYS_SUCCESS,
  payload: data,
});

export const  addDiscussionReplysFailure = (data) => ({
  type: tradeViewAuthAction.ADD_DISCUSSION_REPLYS_FAILURE,
  payload: data,
});

export const addDiscussionReplys = (payload,token) => async (dispatch) => {
  try {
    dispatch(addDiscussionReplysInitiate())
    const response = await axios.post(`${Url}construction/replyDiscussion`,payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 201) {
      dispatch(addDiscussionReplysSuccess(response.data));
    } else {
      dispatch(addDiscussionReplysFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(addDiscussionReplysFailure(err));
    return err.response;
  }
};
//========================ADD-DISCUSSIONS===================

export const  addDiscussionsInitiate = () => ({
  type: tradeViewAuthAction.ADD_DISCUSSIONS_INITIATE,
});

export const addDiscussionsSuccess = (data) => ({
  type: tradeViewAuthAction.ADD_DISCUSSIONS_SUCCESS,
  payload: data,
});

export const  addDiscussionsFailure = (data) => ({
  type: tradeViewAuthAction.ADD_DISCUSSIONS_FAILURE,
  payload: data,
});

export const addDiscussions = (payload,token) => async (dispatch) => {
  try {
    dispatch(addDiscussionsInitiate())
    const response = await axios.post(`${Url}construction/createDiscussion`,payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 201) {
      dispatch(addDiscussionsSuccess(response.data));
    } else {
      dispatch(addDiscussionsFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(addDiscussionsFailure(err));
    return err.response;
  }
};

//=================ACCEPT==FPO===============

export const acceptFPOInitiate = () => ({
  type: tradeViewAuthAction.ACCEPT_FPO_INITIATE,
});

export const acceptFPOSuccess = (data) => ({
  type: tradeViewAuthAction.ACCEPT_FPO_SUCCESS,
  payload: data,
});

export const  acceptFPOFailure = (data) => ({
  type: tradeViewAuthAction.ACCEPT_FPO_FAILURE,
  payload: data,
});

export const acceptFPOs = (companyId,documentId,token) => async (dispatch) => {
  try {
    dispatch(acceptFPOInitiate())
    const response = await axios.patch(`${Url}/companies/${companyId}/fieldpurchaseorder/${documentId}/accept`,{},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(acceptFPOSuccess(response.data));
    } else {
      dispatch(acceptFPOFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(acceptFPOFailure(err));
    return err.response;
  }
};

//=================reject==FPO===============

export const rejectFPOInitiate = () => ({
  type: tradeViewAuthAction.REJECT_FPO_INITIATE,
});

export const rejectFPOSuccess = (data) => ({
  type: tradeViewAuthAction.REJECT_FPO_SUCCESS,
  payload: data,
});

export const  rejectFPOFailure = (data) => ({
  type: tradeViewAuthAction.REJECT_FPO_FAILURE,
  payload: data,
});

export const rejectFPOs = (companyId,documentId,token) => async (dispatch) => {
  try {
    dispatch(rejectFPOInitiate())
    const response = await axios.patch(`${Url}/companies/${companyId}/fieldpurchaseorder/${documentId}/reject`,{},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(rejectFPOSuccess(response.data));
    } else {
      dispatch(rejectFPOFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(rejectFPOFailure(err));
    return err.response;
  }
};
//============================accept--====Sc===================

export const acceptSCInitiate = () => ({
  type: tradeViewAuthAction.ACCEPT_SC_INITIATE,
});

export const acceptSCSuccess = (data) => ({
  type: tradeViewAuthAction.ACCEPT_SC_SUCCESS,
  payload: data,
});

export const  acceptSCFailure = (data) => ({
  type: tradeViewAuthAction.ACCEPT_SC_FAILURE,
  payload: data,
});

export const acceptSCs = (companyId,documentId,token) => async (dispatch) => {
  try {
    dispatch(acceptSCInitiate())
    const response = await axios.patch(`${Url}/companies/${companyId}/suppliercredit/${documentId}/accept`,{},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(acceptSCSuccess(response.data));
    } else {
      dispatch(acceptSCFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(acceptSCFailure(err));
    return err.response;
  }
};
//==========================reject========suppliercredit===============

export const rejectSCInitiate = () => ({
  type: tradeViewAuthAction.REJECT_SC_INITIATE,
});

export const rejectSCSuccess = (data) => ({
  type: tradeViewAuthAction.REJECT_SC_SUCCESS,
  payload: data,
});

export const  rejectSCFailure = (data) => ({
  type: tradeViewAuthAction.REJECT_SC_FAILURE,
  payload: data,
});

export const rejectSCs = (companyId,documentId,token) => async (dispatch) => {
  try {
    dispatch(rejectSCInitiate())
    const response = await axios.patch(`${Url}/companies/${companyId}/suppliercredit/${documentId}/reject`,{},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(rejectSCSuccess(response.data));
    } else {
      dispatch(rejectSCFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(rejectSCFailure(err));
    return err.response;
  }
};