import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";



const ProjectAsEntityStep5 = ({
    ProjectAsEntityStep5Modal,
    SetProjectAsEntityStep1Modal,
    SetProjectAsEntityStep2Modal,
    SetProjectAsEntityStep3Modal,
    SetProjectAsEntityStep4Modal,
    SetProjectAsEntityStep5Modal,
    SetProjectAsEntityStep6Modal,
    SetProjectAsEntityStep7Modal,
    SetProjectAsEntityStep8Modal,
    setShow,
    iState,
    UpdateState,
  
}) => {
  const [connect, setConnect] =useState(false)
  const handleProjectAsEntityStep5ModalClose = () => {
    setShow(true);
    SetProjectAsEntityStep5Modal(false);
  };
  const handleProjectAsEntityStep1ModalShow = () => {
    SetProjectAsEntityStep5Modal(true);
  };


  const {
    useCompFiscalYearEnd,
    useCompTaxRegistration,
    useTaxProfiles,
    errorMsg,
  } = iState;

  const handleValidation = () => {
    let formIsValid = true;
    let errorMsg = {};

    UpdateState({ ...iState, errorMsg: errorMsg });

    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    UpdateState({
      ...iState,
      [name]: value,
    });
  };


 

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();

    if (formIsValid) {
      SetProjectAsEntityStep5Modal(false);
      SetProjectAsEntityStep6Modal(true);
    }
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;

    UpdateState({ ...iState, [name]: checked });
  };

  return (
    <>
      <Modal show={ProjectAsEntityStep5Modal} className="ModalBox LargeModal BgClass">
        <div>
          <a
            onClick={handleProjectAsEntityStep5ModalClose}
            class="CloseModal"
            data-toggle="modal"
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Create New Project as an Entity</h4>
            </div>
            <div class="StepBox">
              <ul>
                  <li class="active">
                      <span class="Icon"  onClick={()=> {SetProjectAsEntityStep1Modal(true) 
                      SetProjectAsEntityStep5Modal(false)}}>
                          <img src={require("../../assets/images/step_1.png")} />
                      </span>
                      <span class="Text">Details</span>
                  </li>
                  <li className="active">
                    <span onClick={()=> {SetProjectAsEntityStep2Modal(true) 
                      SetProjectAsEntityStep5Modal(false)}}  class="Icon FolderPermissionId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityAddress" data-dismiss="modal">
                        <img src={require("../../assets/images/step_2.png")} />
                    </span>
                    <span class="Text">Address</span>
                </li>
                  <li className="active">
                    <span onClick={()=> {SetProjectAsEntityStep3Modal(true) 
                      SetProjectAsEntityStep5Modal(false)}} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityType" data-dismiss="modal">
                        <img src={require("../../assets/images/step_8.png")} />
                    </span>
                    <span class="Text">Type</span>
                </li>
                  <li className="active">
                      <span onClick={()=> {SetProjectAsEntityStep4Modal(true) 
                      SetProjectAsEntityStep5Modal(false)}} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntitySubscription" data-dismiss="modal">
                          <img  src={require("../../assets/images/subscription_step.png")}/>
                      </span>
                      <span class="Text">Subscription</span>
                  </li>
                  <li className="active">
                      <span class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityTax" data-dismiss="modal">
                          <img src={require("../../assets/images/step_3.png")} />
                      </span>
                      <span class="Text">Accounting <br /> &amp; Tax</span>
                  </li> 
                  <li>
                    <span onClick={()=> {SetProjectAsEntityStep6Modal(true) 
                      SetProjectAsEntityStep5Modal(false)}} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityNumber" data-dismiss="modal">
                        <img src={require("../../assets/images/step_5.png")} />
                    </span>
                    <span class="Text">Job Number</span>
                </li> 
                <li>
                  <span onClick={()=> {SetProjectAsEntityStep7Modal(true) 
                      SetProjectAsEntityStep5Modal(false)}} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityInitial" data-dismiss="modal">
                      <img  src={require("../../assets/images/initial_settings.png")} />
                  </span>
                  <span class="Text">Initial <br />
                    Settings</span>
              </li> 
              <li>
                <span onClick={()=> {SetProjectAsEntityStep8Modal(true) 
                      SetProjectAsEntityStep5Modal(false)}} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityConfirmation" data-dismiss="modal">
                    <img src={require("../../assets/images/step_7.png")} />
                </span>
                <span class="Text">Confirmation</span>
            </li> 
              </ul>
            </div>
            <div class="FolderForm">
              
            <form>
                <div class="FolderFormCreateArea">
                  <div class="FieldsMandatory">
                    <h6>Fields with <span class="required">*</span> are mandatory</h6>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div>
                        <h6>Select Accounting Software<span class="required">*</span></h6>
                        <div class="form-group">
                          <label class="Radio"> <img src="images/quickbooks.png" />
                            <input type="radio" name="Cuisine1" />
                            <span class="checkmark mt-2"></span>
                          </label>
                        </div>
                        {/* <div class="form-group">
                          <label class="Radio"> <img src="images/sage.png" />
                            <input type="radio" name="Cuisine2" />
                            <span class="checkmark mt-2"></span>
                          </label>
                        </div> */}
                      </div>
                      <div class="KeySelectionArea">
                    <div class="KeySelectionBox">
                     
                      <aside>
                    
                     {connect ?                        <p><button onClick={(e)=>{ e.preventDefault() 
                     setConnect(false)}} style={{minWidth:"185px"}} class="BlueBtn">Disconnect Quickbooks</button></p>:
                      <p><button style={{minWidth:"185px"}} onClick={(e)=>{ e.preventDefault() 
                        setConnect(true)}} class="GreenBtn">Connect Quickbooks</button></p>
                    }
                      </aside>
                  
                    </div>
                  </div>
                      <div class="form-group">
                        <h6>Tax Registration #</h6>
                        <label class="switch"> Use Company's Tax Registration #
                          <input type="checkbox" onChange={handleCheck} checked={useCompTaxRegistration} name="useCompTaxRegistration" />
                          <span class="slider"></span>
                        </label>
                        <h6 class="mt-3">713158681RT0001</h6>
                      </div>
                      <div class="form-group">
                        <h6>Tax Profile*</h6>
                        <label class="switch"> Use Company's Tax Profiles
                          <input type="checkbox" onChange={handleCheck} checked={useTaxProfiles} name="useTaxProfiles"  />
                          <span class="slider"></span>
                        </label>
                        <h6 class="mt-3">713158681RT0001</h6>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <h6>Fiscal Year End</h6>
                        <label class="switch"> Use Company's Fiscal Year End
                          <input type="checkbox" onChange={handleCheck} checked={useCompFiscalYearEnd} name="useCompFiscalYearEnd" />
                          <span class="slider"></span>
                        </label>
                        <h6 class="mt-3">March</h6>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">

                    <div class="col-12">
                      <div class="AlbertaTaxMain">
                        <div class="AlbertaTax AlbertaTaxBtn">
                          <h5>Alberta Taxs</h5>
                          <img src="images/down_arrow_fill.png" />
                        </div>
                        <div class="AlbertaTaxHide">
                          <div class="row">
                            <div class="col-md-7">
                              <div class="mb-2">
                                <label class="CheckBox"> Default
                                  <input type="checkbox" />
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                              <div class="mb-2">
                                <label class="CheckBox"> Active
                                  <input type="checkbox" />
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="form-group">
                                <h6>Name</h6>
                                <input type="text" class="form-control" placeholder="Alberta Tax" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="AlbertaTaxMain">
                        <div class="TaxRow">
                          <h5>Tax Rows</h5>
                        </div>
                        <div class="TaxRowHide">
                          <table>
                            <thead>
                              <tr>
                                <th>Tax Location</th>
                                <th>Tax Name</th>
                                <th>Tax Percent</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <p>Alberta (AB)</p>
                                </td>
                                <td>
                                  <p>GST</p>
                                </td>
                                <td>
                                  <p>5%</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="AlbertaTaxMain">
                        <div class="Rebate">
                          <h5>Rebates</h5>
                        </div>
                        <div class="TaxRowHide">
                          <table>
                            <thead>
                              <tr>
                                <th>Applicable Row</th>
                                <th>Sale Price Range</th>
                                <th>Applied as</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <p>GST(AB 5%)</p>
                                </td>
                                <td>
                                  <p>$0.00</p>
                                </td>
                                <td>
                                  <p>Sliding Scale</p>
                                </td>
                                <td>
                                  <p>$6,300.00</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="AlbertaTaxMain">
                        <div class="AlbertaTax AlbertaTaxBtn">
                          <h5>Ontario Tax</h5>
                          <img src="images/down_arrow_fill.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            
                <div class="Button d-flex justify-content-between align-items-center px-5">
                  <button class="Outline" data-dismiss="modal"onClick={()=> {
                    SetProjectAsEntityStep5Modal(false)
                    SetProjectAsEntityStep4Modal(true)
                  }} >Back</button>
                  <button class="Create FolderPermissionId" data-toggle="modal" 
                    onClick={handleSubmit
                  }
                  >Next</button>
                </div>  
                </form>
              
            </div>

          </div>
        </div>
      </Modal>

   
      
    </>
  );
};

export default ProjectAsEntityStep5;
