import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import FolderAction from "./FolderAction";
import FolderPermission from "./FolderPermission";
import {toast} from 'react-toastify'
import { useDispatch } from "react-redux";
import { getCreateFolder } from "../../../../redux/action/constructionFileManger";


const initialState = {
  folderName: "",
  loading:false,
  errors: {},
};


const CreateFolder = ({ CreateFolderModal, SetCreateFolderModal,SetFileMangerModal ,state}) => {


  const dispatch = useDispatch()
  const handleCreateFolderModalClose = () => {
    SetCreateFolderModal(false);
    SetFileMangerModal(true)
  };


  const [FolderActionModal, SetFolderActionModal] = useState(false)
  const [FolderPermissionModal, SetFolderPermissionModal] = useState(false)
  const [iState, updateState] = useState(initialState);

  const {loading, folderName}=iState


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };


  const handleCreateFolderSubmit = (e) => {
    e.preventDefault();
    // let formIsValid = handleValidation();
    // if (formIsValid) {
    updateState({ ...iState, loading: true });
    const dataa = {
      folderName,
      "subFileManager": "Construction"
    };
    dispatch(getCreateFolder(dataa , state))
      .then((res) => {
       if(res && res.status === 201){
        toast.success("Folder created successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });

        SetCreateFolderModal(false)
        SetFileMangerModal(true)
        updateState({
          ...iState,
          folderName: "",
          loading:false,
        });
       }else{
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          loading:false,
        });
       }
      })
      .catch((error) => {
        toast.error(error.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, loading: false, });
      });
  };
  return (
    <>
      <Modal
      show={CreateFolderModal}
      className="ModalBox modal fade SmallModal show"
      style={{ display: "block" }}
    >
      <a
        class="CloseModal CloseModalNew2"
        onClick={handleCreateFolderModalClose}
      >
        ×
      </a>
      <div class="FormArea">
        <div class="ModalTitleBox">
          <h4>Create Folder</h4>
        </div>
        <div class="FolderForm">
        <form onSubmit={handleCreateFolderSubmit}>
            <div class="form-group">
              <h6>Folder Name *</h6>
              <input
                type="text"
                class="form-control"
                placeholder="Insert new folder name"
                value={folderName}
                name="folderName"
                onChange={handleInputChange}
              />
            </div>
            <div class="form-group">
              <h6>Folder Permissions</h6>
              <div class="PermissionsName">
                <h5
                  class="FolderPermissionId"
                  onClick={()=> {SetFolderPermissionModal(true)
                    SetCreateFolderModal(false)
                }}

                >
                  <span>
                    <img src="images/Folder.png" />
                  </span>{" "}
                  Folder Permissions
                </h5>
              </div>
            </div>
            <div class="form-group">
              <h6>Folder Actions</h6>
              <div class="PermissionsName">
                <h5
                  class="FolderPermissionId"
                  onClick={()=>{ SetFolderActionModal(true)
                    SetCreateFolderModal(false)
                }}
                >
                  <span>
                    <img src="images/Icon-4.png" />{" "}
                  </span>{" "}
                  Folder Actions
                </h5>
              </div>
            </div>
            <div class="Button text-center">
              <button
                class="Create"

                disabled={loading}
          
              >
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
    
    <FolderAction FolderActionModal={FolderActionModal} SetFolderActionModal={SetFolderActionModal} SetCreateFolderModal={SetCreateFolderModal} />
    <FolderPermission FolderPermissionModal={FolderPermissionModal} SetFolderPermissionModal={SetFolderPermissionModal} SetCreateFolderModal={SetCreateFolderModal} />

    </>
  
  );
};

export default CreateFolder;
