import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPerformastep1block } from "../../../redux/action/proformaAction";

const PerFormaBlockInput = ({
  blockFirstStepNumber,
  handleInputNumber,
  handleBlockFirstStepChange,
  checkArray,
  foundationListData,

  handleShowComponent,
  handleShow,
}) => {
  const disptach = useDispatch();
  const [blockfirstStep, setBlockFirstStep] = useState([
    {
      blockDescription: "",
      foundationType: "",
      numOfUnits: "",
      numOfStories: "",
      foundationAndSubFloor: "",
      blockTotal: "",
      commonAreas: "",
      roof: "",
      exteriorWalls: "",
    },
  ]);
  useEffect(() => {
    disptach(getPerformastep1block()).then((data) => {
      console.log({ data });
      setBlockFirstStep([
        {
          blockDescription: data?.data?.data?.[0]?.blockDescription,
          foundationType: data?.data?.data?.[0]?.foundationType,
          numOfUnits: data?.data?.data?.[0]?.numOfUnits,
          numOfStories: data?.data?.data?.[0]?.numOfStories,
          foundationAndSubFloor: data?.data?.data?.[0]?.foundationAndSubFloor,
          blockTotal: data?.data?.data?.[0]?.blockTotal,
          commonAreas: data?.data?.data?.[0]?.commonAreas,
          roof: data?.data?.data?.[0]?.roof,
          exteriorWalls: data?.data?.data?.[0]?.exteriorWalls,
        },
      ]);
    });
  }, []);
  return (
    <>
      <div>
        <div className="ModalBigheading2 d-flex align-items-center mb-3">
          <h3 className="mb-0">Enter Number of Blocks in the Project</h3>
          <input
            className="ModalBigheadingBox"
            name="blockFirstStepNumber"
            value={blockFirstStepNumber}
            onChange={handleInputNumber}
          />
        </div>
        <div className="EnterProjectTable" style={{ overflowX: "scroll" }}>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Block Description</th>
                <th>Foundation Type</th>
                <th># of Units Per Block</th>
                <th># of Stories Per Block</th>
                <th>Foundation &amp; Subfloor Sq/Ft</th>
                <th>Block Total Sq/Ft</th>
                <th>Common Areas Sq/Ft</th>
                <th>Roof Sq/Ft</th>
                <th>Exterior Walls Sq/Ft</th>
              </tr>
            </thead>
            <tbody>
              {blockfirstStep?.map((ele, i) => {
                return (
                  <>
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Block 1"
                            name="blockDescription"
                            value={ele?.blockDescription}
                            onChange={(e) => handleBlockFirstStepChange(e, i)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="CommonSelectBox width-250">
                          <select
                            name="foundationType"
                            value={ele?.foundationType}
                            onChange={(e) => handleBlockFirstStepChange(e, i)}
                          >
                            <option>Select Foundation</option>
                            {checkArray(foundationListData) ? (
                              <>
                                {foundationListData?.map((ele, i) => {
                                  return (
                                    <option value={ele?.id}>{ele?.name}</option>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </select>
                          {/* <span>
                                    <img src="images/down.png" />
                                  </span> */}
                        </div>
                      </td>
                      <td>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={3}
                            name="numOfUnits"
                            value={ele?.numOfUnits}
                            onChange={(e) => handleBlockFirstStepChange(e, i)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control width-210"
                            placeholder="Insert number of stories"
                            name="numOfStories"
                            value={ele?.numOfStories}
                            onChange={(e) => handleBlockFirstStepChange(e, i)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Insert number of stories"
                            name="foundationAndSubFloor"
                            value={ele?.foundationAndSubFloor}
                            onChange={(e) => handleBlockFirstStepChange(e, i)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control width-210"
                            placeholder="Insert number of stories"
                            name="blockTotal"
                            value={ele?.blockTotal}
                            onChange={(e) => handleBlockFirstStepChange(e, i)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control width-210"
                            placeholder="Insert number of stories"
                            name="commonAreas"
                            value={ele?.commonAreas}
                            onChange={(e) => handleBlockFirstStepChange(e, i)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control width-210"
                            placeholder="Insert number of stories"
                            name="roof"
                            value={ele?.roof}
                            onChange={(e) => handleBlockFirstStepChange(e, i)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control width-210"
                            placeholder="Insert number of stories"
                            name="exteriorWalls"
                            value={ele?.exteriorWalls}
                            onChange={(e) => handleBlockFirstStepChange(e, i)}
                          />
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
          <div className="PaginationArea px-3">
            <h6>Showing 1 to 10 of 27 entries</h6>
            <h6>
              <img src="images/leftarrow.png" /> 01{" "}
              <img src="images/rightarrow.png" /> Out 10
            </h6>
          </div>
        </div>
      </div>
      <div className="Button d-flex  justify-content-between align-items-center px-5">
        <button
          className="Outline border text-success border-success"
          onClick={() => handleShowComponent("details")}
        >
          Cancel
        </button>
        <button
          className="Create bg-success border border-success"
          onClick={() => handleShow("blockQuanties")}
        >
          Save Draft
        </button>
      </div>
    </>
  );
};

export default PerFormaBlockInput;
