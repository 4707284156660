
import { designSelectionsAction } from "../actionTypes";
import { Url } from "../../Config/Config";
import axios from "axios";
import { isLoggedIn } from "../../utils/tokenCheck";
import { multiPartData, multiPartDatas } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

//===========================interiorCategory================================

export const interiorCategoryInitiate = () => ({
    type: designSelectionsAction.INTERIOR_CATEGORY_INITIATE,
  });
  
  export const interiorCategorySuccess = (data) => ({
    type: designSelectionsAction.INTERIOR_CATEGORY_SUCCESS,
    payload: data,
  });
  
  export const interiorCategoryFailure = (data) => ({
    type: designSelectionsAction.INTERIOR_CATEGORY_FAILURE,
    payload: data,
  });
  
  export const getInteriorCategoryList = (ProjectId) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(interiorCategoryInitiate(ProjectId));
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/getcatgory`, {
          headers: { Authorization: `Bearer ${token}` },
        });
     ;
        if (response.status == 200) {
          dispatch(interiorCategorySuccess(response.data));
        } else {
          dispatch(interiorCategoryFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(interiorCategoryFailure(err));
        return err.response
      }
    };

//====================================craeteCategory====================================

export const interiorCreateCategoryInitiate = () => ({
  type: designSelectionsAction.INTERIOR_CREATE_CATEGORY_INITIATE,
});

export const interiorCreateCategorySuccess = (data) => ({
  type: designSelectionsAction.INTERIOR_CREATE_CATEGORY_SUCCESS,
  payload: data,
});

export const interiorCreateCategoryFailure = (data) => ({
  type: designSelectionsAction.INTERIOR_CREATE_CATEGORY_FAILURE,
  payload: data,
});

export const createInteriorCategory = (ProjectId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(interiorCreateCategoryInitiate(payload));
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/category`, payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
   ;
      if (response.status == 200) {
        dispatch(interiorCreateCategorySuccess(response.data));
      } else {
        dispatch(interiorCreateCategoryFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(interiorCreateCategoryFailure(err));
      return err.response
    }
  };

//===============================interior-categoryList====================================

export const interiorCreateCategoryListInitiate = () => ({
  type: designSelectionsAction.INTERIOR_CREATE_CATEGORY_LIST_INITIATE,
});

export const interiorCreateCategoryListSuccess = (data) => ({
  type: designSelectionsAction.INTERIOR_CREATE_CATEGORY_LIST_SUCCESS,
  payload: data,
});

export const interiorCreateCategoryListFailure = (data) => ({
  type: designSelectionsAction.INTERIOR_CREATE_CATEGORY_LIST_FAILURE,
  payload: data,
});

export const getInteriorCreateCategoryList = (ProjectId,jobType,jobId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(interiorCreateCategoryListInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/category?selectionType=interior&jobType=${jobType}&jobId=${jobId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
   ;
      if (response.status == 200) {
        dispatch(interiorCreateCategoryListSuccess(response.data));
      } else {
        dispatch(interiorCreateCategoryListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(interiorCreateCategoryListFailure(err));
      return err.response
    }
  };

//========================================interior-categoryrEMOVE===================================

export const interiorCreateCategoryRemoveInitiate = () => ({
  type: designSelectionsAction.INTERIOR_CREATE_CATEGORY_REOMVE_INITIATE,
});

export const interiorCreateCategoryRemoveSuccess = (data) => ({
  type: designSelectionsAction.INTERIOR_CREATE_CATEGORY_REOMVE_SUCCESS,
  payload: data,
});

export const interiorCreateCategoryRemoveFailure = (data) => ({
  type: designSelectionsAction.INTERIOR_CREATE_CATEGORY_REOMVE_FAILURE,
  payload: data,
});

export const getInteriorCreateCategoryRemove = (ProjectId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(interiorCreateCategoryRemoveInitiate(ProjectId,payload));
      const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/category?id=${payload.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
   ;
      if (response.status == 200) {
        dispatch(interiorCreateCategoryRemoveSuccess(response.data));
      } else {
        dispatch(interiorCreateCategoryRemoveFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(interiorCreateCategoryRemoveFailure(err));
      return err.response
    }
  };

//====================================pendingAppointmentList================================

export const getPendingAppointmentInitiate = () => ({
  type: designSelectionsAction.GET_PENDING_APPOINTMENT_INITIATE,
});

export const getPendingAppointmentSuccess = (data) => ({
  type: designSelectionsAction.GET_PENDING_APPOINTMENT_SUCCESS,
  payload: data,
});

export const getPendingAppointmentFailure = (data) => ({
  type: designSelectionsAction.GET_PENDING_APPOINTMENT_FAILURE,
  payload: data,
});

export const getPendingAppointmentList = (ProjectId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getPendingAppointmentInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/appointment/pending`, {
        headers: { Authorization: `Bearer ${token}` },
      });
   ;
      if (response.status == 202) {
        dispatch(getPendingAppointmentSuccess(response.data));
      } else {
        dispatch(getPendingAppointmentFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getPendingAppointmentFailure(err));
      return err.response
    }
  };

//===========================delete-penidng------------appointment---------------------


export const deletePenidngAppointmentInitiate = () => ({
  type: designSelectionsAction.DELETE_PENDING_APPOINTMENT_INITIATE,
});

export const deletePenidngAppointmentSuccess = (data) => ({
  type: designSelectionsAction.DELETE_PENDING_APPOINTMENT_SUCCESS,
  payload: data,
});

export const deletePenidngAppointmentFailure = (data) => ({
  type: designSelectionsAction.DELETE_PENDING_APPOINTMENT_FAILURE,
  payload: data,
});

export const deletePenidngAppointment = (ProjectId,payload) => async (dispatch) => {

    try {
      const token = isLoggedIn("adminData1");
      dispatch(deletePenidngAppointmentInitiate(ProjectId,payload));
      const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/appointment/${payload.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
   ;
      if (response.status == 200) {
        dispatch(deletePenidngAppointmentSuccess(response.data));
      } else {
        dispatch(deletePenidngAppointmentFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(deletePenidngAppointmentFailure(err));
      return err.response
    }
  };

//================================getAppointmentcategory=============================

export const getAppointmentCategoryInitiate = () => ({
  type: designSelectionsAction.GET_APPOINTMENT_CATEGORY_INITIATE,
});

export const getAppointmentCategorySuccess = (data) => ({
  type: designSelectionsAction.GET_APPOINTMENT_CATEGORY_SUCCESS,
  payload: data,
});

export const getAppointmentCategoryFailure = (data) => ({
  type: designSelectionsAction.GET_APPOINTMENT_CATEGORY_FAILURE,
  payload: data,
});

export const getAppointmentCategoryList = (ProjectId,jobType,jobId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getAppointmentCategoryInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/appointment?appointmentType=interior&jobType=${jobType}&jobId=${jobId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
   ;
      if (response.status == 200) {
        dispatch(getAppointmentCategorySuccess(response.data));
      } else {
        dispatch(getAppointmentCategoryFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getAppointmentCategoryFailure(err));
      return err.response
    }
  };

//=======================================getDesignervendor===========================================


export const getDesignerVendorInitiate = () => ({
  type: designSelectionsAction.GET_DESIGNER_VENDOR_INITIATE,
});

export const getDesignerVendorSuccess = (data) => ({
  type: designSelectionsAction.GET_DESIGNER_VENDOR_SUCCESS,
  payload: data,
});

export const getDesignerVendorFailure = (data) => ({
  type: designSelectionsAction.GET_DESIGNER_VENDOR_FAILURE,
  payload: data,
});

export const getDesignerVendorList = (ProjectId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getDesignerVendorInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/getDesigner`, {
        headers: { Authorization: `Bearer ${token}` },
      });
   ;
      if (response.status == 200) {
        dispatch(getDesignerVendorSuccess(response.data));
      } else {
        dispatch(getDesignerVendorFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getDesignerVendorFailure(err));
      return err.response
    }
  };

//=========================================appointmentCreate===============================


export const appointmentCreateInitiate = () => ({
  type: designSelectionsAction.APPOINTMENT_CREATE_INITIATE,
});

export const appointmentCreateSuccess = (data) => ({
  type: designSelectionsAction.APPOINTMENT_CREATE_SUCCESS,
  payload: data,
});

export const appointmentCreateFailure = (data) => ({
  type: designSelectionsAction.APPOINTMENT_CREATE_FAILURE,
  payload: data,
});

export const appointmentCreate = (ProjectId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(appointmentCreateInitiate(ProjectId,payload));
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/appointment`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
   ;
      if (response.status == 200) {
        dispatch(appointmentCreateSuccess(response.data));
      } else {
        dispatch(appointmentCreateFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(appointmentCreateFailure(err));
      return err.response
    }
  };

//====================================ApprovedAppointmentList================================

export const getApprovedAppointmentInitiate = () => ({
  type: designSelectionsAction.GET_APPROVED_APPOINTMENT_INITIATE,
});

export const getApprovedAppointmentSuccess = (data) => ({
  type: designSelectionsAction.GET_APPROVED_APPOINTMENT_SUCCESS,
  payload: data,
});

export const getApprovedAppointmentFailure = (data) => ({
  type: designSelectionsAction.GET_APPROVED_APPOINTMENT_FAILURE,
  payload: data,
});

export const getApprovedAppointmentList = (ProjectId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getApprovedAppointmentInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/appointment/approved`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getApprovedAppointmentSuccess(response.data));
      } else {
        dispatch(getApprovedAppointmentFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getApprovedAppointmentFailure(err));
      return err.response
    }
  };

//===============================getPriceBookCategoryList===========================


export const getPriceBookCategoryInitiate = () => ({
  type: designSelectionsAction.GET_PRICE_BOOK_CATEGORY_INITIATE,
});

export const getPriceBookCategorySuccess = (data) => ({
  type: designSelectionsAction.GET_PRICE_BOOK_CATEGORY_SUCCESS,
  payload: data,
});

export const getPriceBookCategoryFailure = (data) => ({
  type: designSelectionsAction.GET_PRICE_BOOK_CATEGORY_FAILURE,
  payload: data,
});

export const getPriceBookCategoryList = (ProjectId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getPriceBookCategoryInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/getcatgory`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getPriceBookCategorySuccess(response.data));
      } else {
        dispatch(getPriceBookCategoryFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getPriceBookCategoryFailure(err));
      return err.response
    }
  };

//=====================================================Sselect===Item=======================================

export const getSelectItemInitiate = () => ({
  type: designSelectionsAction.GET_SELECT_ITEM_INITIATE,
});

export const getSelectItemSuccess = (data) => ({
  type: designSelectionsAction.GET_SELECT_ITEM_SUCCESS,
  payload: data,
});

export const getSelectItemFailure = (data) => ({
  type: designSelectionsAction.GET_SELECT_ITEM_FAILURE,
  payload: data,
});

export const getSelectItemList = (ProjectId,assemblId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getSelectItemInitiate(ProjectId,assemblId));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/getassembly/${assemblId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getSelectItemSuccess(response.data));
      } else {
        dispatch(getSelectItemFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getSelectItemFailure(err));
      return err.response
    }
  };

//============================================VENDORList==============================


export const getVendorListInitiate = () => ({
  type: designSelectionsAction.GET_VENDOR_LIST_INITIATE,
});

export const getVendorListSuccess = (data) => ({
  type: designSelectionsAction.GET_VENDOR_LIST_SUCCESS,
  payload: data,
});

export const getVendorListFailure = (data) => ({
  type: designSelectionsAction.GET_VENDOR_LIST_FAILURE,
  payload: data,
});

export const getVendorList = (ProjectId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getVendorListInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/getvendor `, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getVendorListSuccess(response.data));
      } else {
        dispatch(getVendorListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getVendorListFailure(err));
      return err.response
    }
  };

//====================================create-folder===============================



export const createFolderInitiate = () => ({
  type: designSelectionsAction.CREATE_FOLDER_INITIATE,
});

export const createFolderSuccess = (data) => ({
  type: designSelectionsAction.CREATE_FOLDER_SUCCESS,
  payload: data,
});

export const  createFolderFailure = (data) => ({
  type: designSelectionsAction.CREATE_FOLDER_FAILURE,
  payload: data,
});

export const createDesignFolder = (jobId,jobType,projId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createFolderInitiate());
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/design-folders?foldertableId=${jobId}&foldertableType=${jobType}&ProjectId=${projId} `,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(createFolderSuccess(response.data));
      } else {
        dispatch(createFolderFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createFolderFailure(err));
      return err.response
    }
  };

//======================================folderList=============================

export const folderListInitiate = () => ({
  type: designSelectionsAction.FOLDER_LIST_INITIATE,
});

export const folderListSuccess = (data) => ({
  type: designSelectionsAction.FOLDER_LIST_SUCCESS,
  payload: data,
});

export const  folderListFailure = (data) => ({
  type: designSelectionsAction.FOLDER_LIST_FAILURE,
  payload: data,
});

export const getDesignFolderList = (jobId,jobType,projectId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(folderListInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/design-folders?foldertableId=${jobId}&foldertableType=${jobType}&projectId=${projectId}`,
       {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(folderListSuccess(response.data));
      } else {
        dispatch(folderListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(folderListFailure(err));
      return err.response
    }
  };

//=================================PendingFile-eventslist========================


export const pendingFileEventListInitiate = () => ({
  type: designSelectionsAction.PENDING_FILE_EVENT_LIST_INITIATE,
});

export const pendingFileEventListSuccess = (data) => ({
  type: designSelectionsAction.PENDING_FILE_EVENT_LIST_SUCCESS,
  payload: data,
});

export const  pendingFileEventListFailure = (data) => ({
  type: designSelectionsAction.PENDING_FILE_EVENT_LIST_FAILURE,
  payload: data,
});

export const getDesignPendingFileEventList = (folderID) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(pendingFileEventListInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderID}/design-docs-pending-file-events`,
       {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(pendingFileEventListSuccess(response.data));
      } else {
        dispatch(pendingFileEventListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(pendingFileEventListFailure(err));
      return err.response
    }
  };

//==================================uploadFiles==================================

export const uploadFilesInitiate = () => ({
  type: designSelectionsAction.UPLOAD_FILES_INITIATE,
});

export const uploadFilesSuccess = (data) => ({
  type: designSelectionsAction.UPLOAD_FILES_SUCCESS,
  payload: data,
});

export const  uploadFilesFailure = (data) => ({
  type: designSelectionsAction.UPLOAD_FILES_FAILURE,
  payload: data,
});

export const getUploadFiles = (folderID) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(uploadFilesInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderID}/design-docs-pending-file-events`,
       {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(uploadFilesSuccess(response.data));
      } else {
        dispatch(uploadFilesFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(uploadFilesFailure(err));
      return err.response
    }
  };

//=====================================CraeteDisgnDocument=====================

export const createDesignDocumentationInitiate = () => ({
  type: designSelectionsAction.CREATE_DESIGN_DOCUMENTATION_INITIATE,
});

export const createDesignDocumentationSuccess = (data) => ({
  type: designSelectionsAction.CREATE_DESIGN_DOCUMENTATION_SUCCESS,
  payload: data,
});

export const  createDesignDocumentationFailure = (data) => ({
  type: designSelectionsAction.CREATE_DESIGN_DOCUMENTATION_FAILURE,
  payload: data,
});

export const createDesignDocumentation = (folderID,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const updatedData = multiPartData({ ...payload });
      dispatch(createDesignDocumentationInitiate());
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderID}/design-docs`,updatedData,
       {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 201) {
        dispatch(createDesignDocumentationSuccess(response.data));
      } else {
        dispatch(createDesignDocumentationFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createDesignDocumentationFailure(err));
      return err.response
    }
  };

//=========================================list-design-document-===========================

export const getListDesignDocumentInitiate = () => ({
  type: designSelectionsAction.GET_LIST_DESIGN_DOCUMENT_INITIATE,
});

export const getListDesignDocumentSuccess = (data) => ({
  type: designSelectionsAction.GET_LIST_DESIGN_DOCUMENT_SUCCESS,
  payload: data,
});

export const  getListDesignDocumentFailure = (data) => ({
  type: designSelectionsAction.GET_LIST_DESIGN_DOCUMENT_FAILURE,
  payload: data,
});

export const getListDesignDocument = (folderID) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getListDesignDocumentInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderID}/design-docs`,
       {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getListDesignDocumentSuccess(response.data));
      } else {
        dispatch(getListDesignDocumentFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getListDesignDocumentFailure(err));
      return err.response
    }
  };

//======================================================designDocumentHistory=========================

export const getDesignDocumentHistoryInitiate = () => ({
  type: designSelectionsAction.GET_DESIGN_DOCUMENT_HISTORY_INITIATE,
});

export const getDesignDocumentHistorySuccess = (data) => ({
  type: designSelectionsAction.GET_DESIGN_DOCUMENT_HISTORY_SUCCESS,
  payload: data,
});

export const  getDesignDocumentHistoryFailure = (data) => ({
  type: designSelectionsAction.GET_DESIGN_DOCUMENT_HISTORY_FAILURE,
  payload: data,
});

export const getDesignDocumentHistory = (folderID,desinDoc) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getDesignDocumentHistoryInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderID}/design-docs/${desinDoc}/history`,
       {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getDesignDocumentHistorySuccess(response.data));
      } else {
        dispatch(getDesignDocumentHistoryFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getDesignDocumentHistoryFailure(err));
      return err.response
    }
  };

//=======================================shareDocument========================================

export const shareDesignDocumentInitiate = () => ({
  type: designSelectionsAction.SHARE_DESIGN_DOCUMENT_INITIATE,
});

export const shareDesignDocumentSuccess = (data) => ({
  type: designSelectionsAction.SHARE_DESIGN_DOCUMENT_SUCCESS,
  payload: data,
});

export const shareDesignDocumentFailure = (data) => ({
  type: designSelectionsAction.SHARE_DESIGN_DOCUMENT_FAILURE,
  payload: data,
});

export const getShareDesignDocument = (folderId, id, payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(shareDesignDocumentInitiate(payload))
    const response = await axios.put(
      `${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/client-docs/${id}/design-share-doc`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(shareDesignDocumentSuccess(response.data));
    } else {
      dispatch(shareDesignDocumentFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(shareDesignDocumentFailure(err));
    return err.response;
  }
};
//=================================delte-folder===========================


export const deleteFolderInitiate = () => ({
  type: designSelectionsAction.DELETE_DESIGN_FOLDER_INITIATE,
});

export const deleteFolderSuccess = (data) => ({
  type: designSelectionsAction.DELETE_DESIGN_FOLDER_SUCCESS,
  payload: data,
});

export const  deleteFolderFailure = (data) => ({
  type: designSelectionsAction.DELETE_DESIGN_FOLDER_FAILURE,
  payload: data,
});

export const getDesignFolderRemove = (docId,jobId,jobType, payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(deleteFolderInitiate(docId));

    const response = await axios.delete(
      `${Url}companies/${iBuildLocalData?.user?.CompanyId}/design-folders/${docId}?foldertableId=${jobId}&foldertableType=${jobType}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        data: payload, // Use "data" for the request body
      }
    );
    if (response.status === 200) {
      dispatch(deleteFolderSuccess(response.data));
    } else {
      dispatch(deleteFolderFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(deleteFolderFailure(err));
    return err.response;
  }
};

//=====================================RESEND-date--selection==============


export const resendDateSelectionInitiate = () => ({
  type: designSelectionsAction.DELETE_DESIGN_FOLDER_INITIATE,
});

export const resendDateSelectionSuccess = (data) => ({
  type: designSelectionsAction.DELETE_DESIGN_FOLDER_SUCCESS,
  payload: data,
});

export const  resendDateSelectionFailure = (data) => ({
  type: designSelectionsAction.DELETE_DESIGN_FOLDER_FAILURE,
  payload: data,
});

export const resendDateSelection = (ProjectId,payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(resendDateSelectionInitiate());
    const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/appointment/resend`,payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(resendDateSelectionSuccess(response.data));
    } else {
      dispatch(resendDateSelectionFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(resendDateSelectionFailure(err));
    return err.response;
  }
};

//==================Update--reminer----------

export const updateReminderInitiate = () => ({
  type: designSelectionsAction.UPDATE_REMINDER_INITIATE,
});

export const updateReminderSuccess = (data) => ({
  type: designSelectionsAction.UPDATE_REMINDER_SUCCESS,
  payload: data,
});

export const  updateReminderFailure = (data) => ({
  type: designSelectionsAction.UPDATE_REMINDER_FAILURE,
  payload: data,
});

export const updateReminder = (ProjectId,payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(updateReminderInitiate());
    const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/appointment/reminder/set`,payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(updateReminderSuccess(response.data));
    } else {
      dispatch(updateReminderFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(updateReminderFailure(err));
    return err.response;
  }
};

//===============================exterior-categoryList====================================

export const exteriorCreateCategoryListInitiate = () => ({
  type: designSelectionsAction.EXTERIOR_CREATE_CATEGORY_LIST_INITIATE,
});

export const exteriorCreateCategoryListSuccess = (data) => ({
  type: designSelectionsAction.EXTERIOR_CREATE_CATEGORY_LIST_SUCCESS,
  payload: data,
});

export const exteriorCreateCategoryListFailure = (data) => ({
  type: designSelectionsAction.EXTERIOR_CREATE_CATEGORY_LIST_FAILURE,
  payload: data,
});

export const getExteriorCreateCategoryList = (ProjectId,jobType,jobId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(exteriorCreateCategoryListInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/category?selectionType=exterior&jobType=${jobType}&jobId=${jobId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
   ;
      if (response.status == 200) {
        dispatch(exteriorCreateCategoryListSuccess(response.data));
      } else {
        dispatch(exteriorCreateCategoryListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(exteriorCreateCategoryListFailure(err));
      return err.response
    }
  };
//=========================update-category====================================


export const updateCategoryInitiate = () => ({
  type: designSelectionsAction.UPDATE_CATEGORY_INITIATE,
});

export const updateCategorySuccess = (data) => ({
  type: designSelectionsAction.UPDATE_CATEGORY_SUCCESS,
  payload: data,
});

export const updateCategoryFailure = (data) => ({
  type: designSelectionsAction.UPDATE_CATEGORY_FAILURE,
  payload: data,
});

export const updateCategory = (ProjectId,id,designSelectionId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(updateCategoryInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/category?id=${id}&designSelectionId=${designSelectionId}`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
   ;
      if (response.status == 200) {
        dispatch(updateCategorySuccess(response.data));
      } else {
        dispatch(updateCategoryFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateCategoryFailure(err));
      return err.response
    }
  };

//=================================RELESASE--FILES===========================

export const releaseFilesInitiate = () => ({
  type: designSelectionsAction.RELEASE_FILES_INITIATE,
});

export const releaseFilesSuccess = (data) => ({
  type: designSelectionsAction.RELEASE_FILES_SUCCESS,
  payload: data,
});

export const releaseFilesFailure = (data) => ({
  type: designSelectionsAction.RELEASE_FILES_FAILURE,
  payload: data,
});

export const ReleaseFiles = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(releaseFilesInitiate());
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/release-template-in`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
   ;
      if (response.status == 200) {
        dispatch(releaseFilesSuccess(response.data));
      } else {
        dispatch(releaseFilesFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(releaseFilesFailure(err));
      return err.response
    }
  };

//=============================release---folder--files=======================

export const releaseFolderFilesInitiate = () => ({
  type: designSelectionsAction.RELEASE_FOLDER_FILES_INITIATE,
});

export const releaseFolderFilesSuccess = (data) => ({
  type: designSelectionsAction.RELEASE_FOLDER_FILES_SUCCESS,
  payload: data,
});

export const releaseFolderFilesFailure = (data) => ({
  type: designSelectionsAction.RELEASE_FOLDER_FILES_FAILURE,
  payload: data,
});

export const ReleaseFolderFiles = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(releaseFolderFilesInitiate());
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/release-folder-in`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(releaseFolderFilesSuccess(response.data));
      } else {
        dispatch(releaseFolderFilesFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(releaseFolderFilesFailure(err));
      return err.response
    }
  };
//=============================GET-primary-client--list===============
export const getPrimaryClientInitiate = () => ({
  type: designSelectionsAction.GET_PRIMARY_CLIENT_INITIATE,
});

export const getPrimaryClientSuccess = (data) => ({
  type: designSelectionsAction.GET_PRIMARY_CLIENT_SUCCESS,
  payload: data,
});

export const getPrimaryClientFailure = (data) => ({
  type: designSelectionsAction.GET_PRIMARY_CLIENT_FAILURE,
  payload: data,
});

export const getPrimaryClient = (configID,jobId,jobType) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getPrimaryClientInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/view-config/${configID}/client-view-user-list?jobId=${jobId}&jobType=${jobType}&clientType=primary`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getPrimaryClientSuccess(response.data));
      } else {
        dispatch(getPrimaryClientFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getPrimaryClientFailure(err));
      return err.response
    }
  };

//=============================GET-secondry-client--list===============
export const getSecondaryClientInitiate = () => ({
  type: designSelectionsAction.GET_SECONDARY_CLIENT_INITIATE,
});

export const getSecondaryClientSuccess = (data) => ({
  type: designSelectionsAction.GET_SECONDARY_CLIENT_SUCCESS,
  payload: data,
});

export const getSecondaryClientFailure = (data) => ({
  type: designSelectionsAction.GET_SECONDARY_CLIENT_FAILURE,
  payload: data,
});

export const getSecondaryClient = (configID,jobId,jobType) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getSecondaryClientInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/view-config/${configID}/client-view-user-list?jobId=${jobId}&jobType=${jobType}&clientType=secondary`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getSecondaryClientSuccess(response.data));
      } else {
        dispatch(getSecondaryClientFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getSecondaryClientFailure(err));
      return err.response
    }
  };
//============================send--for--client--------signature-----------------

export const sendForClientSignatureInitiate = () => ({
  type: designSelectionsAction.SEND_FOR_CLIENT_SIGNATURE_INITIATE,
});

export const sendForClientSignatureSuccess = (data) => ({
  type: designSelectionsAction.SEND_FOR_CLIENT_SIGNATURE_SUCCESS,
  payload: data,
});

export const sendForClientSignatureFailure = (data) => ({
  type: designSelectionsAction.SEND_FOR_CLIENT_SIGNATURE_FAILURE,
  payload: data,
});

export const sendForClientSignature = (folderId,docsId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(sendForClientSignatureInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/design-docs/${docsId}/send-for-signature`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(sendForClientSignatureSuccess(response.data));
      } else {
        dispatch(sendForClientSignatureFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(sendForClientSignatureFailure(err));
      return err.response
    }
  };

//===============================create----option==================

export const createOptionInitiate = () => ({
  type: designSelectionsAction.CREATE_OPTION_INITIATE,
});

export const createOptionSuccess = (data) => ({
  type: designSelectionsAction.CREATE_OPTION_SUCCESS,
  payload: data,
});

export const createOptionFailure = (data) => ({
  type: designSelectionsAction.CREATE_OPTION_FAILURE,
  payload: data,
});

export const createOption = (ProjectId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createOptionInitiate());
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/option`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(createOptionSuccess(response.data));
      } else {
        dispatch(createOptionFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createOptionFailure(err));
      return err.response
    }
  };

//=======================get-cost--code-dropdown==================


export const getCostCodeDropDownInitiate = () => ({
  type: designSelectionsAction.GET_COST_CODE_DROPDOWN_INITIATE,
});

export const getCostCodeDropDownSuccess = (data) => ({
  type: designSelectionsAction.GET_COST_CODE_DROPDOWN_SUCCESS,
  payload: data,
});

export const getCostCodeDropDownFailure = (data) => ({
  type: designSelectionsAction.GET_COST_CODE_DROPDOWN_FAILURE,
  payload: data,
});

export const getCostCodeDropDown = (ProjectId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getCostCodeDropDownInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/costcode`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getCostCodeDropDownSuccess(response.data));
      } else {
        dispatch(getCostCodeDropDownFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getCostCodeDropDownFailure(err));
      return err.response
    }
  };

//=========================get-measure-drop-down================


export const getMeasureDropDownInitiate = () => ({
  type: designSelectionsAction.GET_MEASURE_DROPDOWN_INITIATE,
});

export const getMeasureDropDownSuccess = (data) => ({
  type: designSelectionsAction.GET_MEASURE_DROPDOWN_SUCCESS,
  payload: data,
});

export const getMeasureDropDownFailure = (data) => ({
  type: designSelectionsAction.GET_MEASURE_DROPDOWN_FAILURE,
  payload: data,
});

export const getMeasureDropDown = (ProjectId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getMeasureDropDownInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/measure`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getMeasureDropDownSuccess(response.data));
      } else {
        dispatch(getMeasureDropDownFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getMeasureDropDownFailure(err));
      return err.response
    }
  };

//==================================delete-option--by---id-------

export const deleteOptionByIdInitiate = () => ({
  type: designSelectionsAction.DELETE_OPTION_BY_ID_INITIATE,
});

export const deleteOptionByIdSuccess = (data) => ({
  type: designSelectionsAction.DELETE_OPTION_BY_ID_SUCCESS,
  payload: data,
});

export const deleteOptionByIdFailure = (data) => ({
  type: designSelectionsAction.DELETE_OPTION_BY_ID_FAILURE,
  payload: data,
});

export const deleteOptionById = (ProjectId,id) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(deleteOptionByIdInitiate());
      const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/option/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(deleteOptionByIdSuccess(response.data));
      } else {
        dispatch(deleteOptionByIdFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(deleteOptionByIdFailure(err));
      return err.response
    }
  };

//============================GET-option-by -category=====================

export const getOptionByCategoryIdInitiate = () => ({
  type: designSelectionsAction.GET_OPTION_BY_CATEGORYID_INITIATE,
});

export const getOptionByCategoryIdSuccess = (data) => ({
  type: designSelectionsAction.GET_OPTION_BY_CATEGORYID_SUCCESS,
  payload: data,
});

export const getOptionByCategoryIdFailure = (data) => ({
  type: designSelectionsAction.GET_OPTION_BY_CATEGORYID_FAILURE,
  payload: data,
});

export const getOptionByCategoryId = (ProjectId,catId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getOptionByCategoryIdInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/option/category/${catId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getOptionByCategoryIdSuccess(response.data));
      } else {
        dispatch(getOptionByCategoryIdFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getOptionByCategoryIdFailure(err));
      return err.response
    }
  };

//===============================SElect--design---option=====================

export const selectDesignOptionInitiate = () => ({
  type: designSelectionsAction.SELECT_DESIGN_OPTION_INITIATE,
});

export const selectDesignOptionSuccess = (data) => ({
  type: designSelectionsAction.SELECT_DESIGN_OPTION_SUCCESS,
  payload: data,
});

export const selectDesignOptionFailure = (data) => ({
  type: designSelectionsAction.SELECT_DESIGN_OPTION_FAILURE,
  payload: data,
});

export const selectedDesignOption = (ProjectId,catId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(selectDesignOptionInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/option?optionId=${catId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 202) {
        dispatch(selectDesignOptionSuccess(response.data));
      } else {
        dispatch(selectDesignOptionFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(selectDesignOptionFailure(err));
      return err.response
    }
  };

  export const updateOptionInitiate = () => ({
    type: designSelectionsAction.UPDATE_OPTION_INITIATE,
  });
  
  export const updateOptionSuccess = (data) => ({
    type: designSelectionsAction.UPDATE_OPTION_SUCCESS,
    payload: data,
  });
  
  export const updateOptionFailure = (data) => ({
    type: designSelectionsAction.UPDATE_OPTION_FAILURE,
    payload: data,
  });
  
  export const updateOptions = (ProjectId,payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(updateOptionInitiate());
        const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/option`, payload,{
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(updateOptionSuccess(response.data));
        } else {
          dispatch(updateOptionFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(updateOptionFailure(err));
        return err.response
      }
    };
  


//=====================SEND FOR CLIENT APPROVAL====================//
export const sendForClientApprovalInitiate = () => ({
  type: designSelectionsAction.SEND_FOR_CLIENT_APPROVAL_INITIATE,
});

export const sendForClientApprovalSuccess = (data) => ({
  type: designSelectionsAction.SEND_FOR_CLIENT_APPROVAL_SUCCESS,
  payload: data,
});

export const sendForClientApprovalFailure = (data) => ({
  type: designSelectionsAction.SEND_FOR_CLIENT_APPROVAL_FAILURE,
  payload: data,
});

export const sendForClientApproval = (ProjectId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(sendForClientApprovalInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/option`, payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 202) {
        dispatch(sendForClientApprovalSuccess(response.data));
      } else {
        dispatch(sendForClientApprovalFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(sendForClientApprovalFailure(err));
      return err.response
    }
  };


//=====================DELETE SELECTION====================//
export const deleteSelectionInitiate = () => ({
  type: designSelectionsAction.DELETE_SELECTION_INITIATE,
});

export const deleteSelectionSuccess = (data) => ({
  type: designSelectionsAction.DELETE_SELECTION_SUCCESS,
  payload: data,
});

export const deleteSelectionFailure = (data) => ({
  type: designSelectionsAction.DELETE_SELECTION_FAILURE,
  payload: data,
});

export const deleteSelection = (ProjectId,id) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(deleteSelectionInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/option/delete/select?designCategoryId=${id}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(deleteSelectionSuccess(response.data));
      } else {
        dispatch(deleteSelectionFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(deleteSelectionFailure(err));
      return err.response
    }
  };



//=====================Design selection agrement send====================//
export const designSelectionAggrementSendInitiate = () => ({
  type: designSelectionsAction.DESIGN_SELECTION_AGGREMENT_SEND_INITIATE,
});

export const designSelectionAggrementSendSuccess = (data) => ({
  type: designSelectionsAction.DESIGN_SELECTION_AGGREMENT_SEND_SUCCESS,
  payload: data,
});

export const designSelectionAggrementSendFailure = (data) => ({
  type: designSelectionsAction.DESIGN_SELECTION_AGGREMENT_SEND_FAILURE,
  payload: data,
});

export const designSelectionAggrementSend = (ProjectId,fileUrl,clientId,designCategoryId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(designSelectionAggrementSendInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/agreement/send?clientId=${clientId}&agreement=${encodeURIComponent(fileUrl)}&designCategoryId=${designCategoryId}
`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(designSelectionAggrementSendSuccess(response.data));
      } else {
        dispatch(designSelectionAggrementSendFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(designSelectionAggrementSendFailure(err));
      return err.response
    }
  };




  
//=================================UPLOAD AGGREMENT DOCS FILE=====================

export const uploadAggrementFilesInitiate = () => ({
  type: designSelectionsAction.UPLOAD_AGGREMENT_FILES_INITIATE,
});

export const uploadAggrementFilesSuccess = (data) => ({
  type: designSelectionsAction.UPLOAD_AGGREMENT_FILES_SUCCESS,
  payload: data,
});

export const uploadAggrementFilesFailure = (data) => ({
  type: designSelectionsAction.UPLOAD_AGGREMENT_FILES_FAILURE,
  payload: data,
});

export const uploadAggrementFiles = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(uploadAggrementFilesInitiate(payload));
      const response = await axios.post(`${Url}upload/files`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(uploadAggrementFilesSuccess(response.data));
      } else {
        dispatch(uploadAggrementFilesFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(uploadAggrementFilesFailure(err));
      return err.response
    }
  };
  
//=================================UPLOAD MULTIPLE FILE FILE=====================

export const uploadmultipleFilesInitiate = () => ({
  type: designSelectionsAction.UPLOAD_AGGREMENT_FILES_INITIATE,
});

export const uploadmultipleFilesSuccess = (data) => ({
  type: designSelectionsAction.UPLOAD_AGGREMENT_FILES_SUCCESS,
  payload: data,
});

export const uploadmultipleFilesFailure = (data) => ({
  type: designSelectionsAction.UPLOAD_AGGREMENT_FILES_FAILURE,
  payload: data,
});

export const uploadmultipleFiles = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(uploadmultipleFilesInitiate(payload));
      const response = await axios.post(`${Url}upload/multiple/files`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(uploadmultipleFilesSuccess(response.data));
      } else {
        dispatch(uploadmultipleFilesFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(uploadmultipleFilesFailure(err));
      return err.response
    }
  };

  
  //===============================SElect--design---option BY ID=====================

export const selectDesignOptionByIdInitiate = () => ({
  type: designSelectionsAction.SELECT_DESIGN_OPTION_INITIATE,
});

export const selectDesignOptionByIdSuccess = (data) => ({
  type: designSelectionsAction.SELECT_DESIGN_OPTION_SUCCESS,
  payload: data,
});

export const selectDesignOptionByIdFailure = (data) => ({
  type: designSelectionsAction.SELECT_DESIGN_OPTION_FAILURE,
  payload: data,
});

export const selectedDesignOptionById = (ProjectId,catId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(selectDesignOptionByIdInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/option/${catId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(selectDesignOptionByIdSuccess(response.data));
      } else {
        dispatch(selectDesignOptionByIdFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(selectDesignOptionByIdFailure(err));
      return err.response
    }
  };


//=================================UGET SELECTION HISTORY=====================

export const getSelectionHistoryInitiate = () => ({
  type: designSelectionsAction.GET_SELECTION_HISTORY_INITIATE,
});

export const getSelectionHistorySuccess = (data) => ({
  type: designSelectionsAction.GET_SELECTION_HISTORY_SUCCESS,
  payload: data,
});

export const getSelectionHistoryFailure = (data) => ({
  type: designSelectionsAction.GET_SELECTION_HISTORY_FAILURE,
  payload: data,
});

export const getSelectionHistory = (ProjectId,categoryId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getSelectionHistoryInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/history?designCategoryId=${categoryId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getSelectionHistorySuccess(response.data));
      } else {
        dispatch(getSelectionHistoryFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getSelectionHistoryFailure(err));
      return err.response
    }
  };


  
//=================================GET_DESIGN_CATEGORY_BY_ID=====================

export const getDesignCategoryByIdInitiate = () => ({
  type: designSelectionsAction.GET_DESIGN_CATEGORY_BY_ID_INITIATE,
});

export const getDesignCategoryByIdSuccess = (data) => ({
  type: designSelectionsAction.GET_DESIGN_CATEGORY_BY_ID_SUCCESS,
  payload: data,
});

export const getDesignCategoryByIdFailure = (data) => ({
  type: designSelectionsAction.GET_DESIGN_CATEGORY_BY_ID_FAILURE,
  payload: data,
});

export const getDesignCategoryById = (CompanyId,ProjectId,categoryId,token) => async (dispatch) => {
    try {
      dispatch(getDesignCategoryByIdInitiate());
      const response = await axios.get(`${Url}companies/${CompanyId}/projects/${ProjectId}/design-selection/category/id/${categoryId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getDesignCategoryByIdSuccess(response.data));
      } else {
        dispatch(getDesignCategoryByIdFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getDesignCategoryByIdFailure(err));
      return err.response
    }
  };

//==============================ADD-SIGNATURE---IN--AGREEMENT============


export const addSignatureInAggrementInitiate = () => ({
  type: designSelectionsAction.ADD_SIGNATURE_IN_AGGREMENT_INITIATE,
});

export const addSignatureInAggrementSuccess = (data) => ({
  type: designSelectionsAction.ADD_SIGNATURE_IN_AGGREMENT_SUCCESS,
  payload: data,
});

export const addSignatureInAggrementFailure = (data) => ({
  type: designSelectionsAction.ADD_SIGNATURE_IN_AGGREMENT_FAILURE,
  payload: data,
});

export const addSignatureInAggrement = (CompanyId,ProjectId,token,payload) => async (dispatch) => {
  
    try {
      dispatch(addSignatureInAggrementInitiate());
      const response = await axios.post(`${Url}companies/${CompanyId}/projects/${ProjectId}/design-selection/signature`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(addSignatureInAggrementSuccess(response.data));
      } else {
        dispatch(addSignatureInAggrementFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addSignatureInAggrementFailure(err));
      return err.response
    }
  };
//========================CLIENTS-DETAILS=========================


export const clientDetailsInitiate = () => ({
  type: designSelectionsAction.CLIENT_DETAILS_INITIATE,
});

export const clientDetailsSuccess = (data) => ({
  type: designSelectionsAction.CLIENT_DETAILS_SUCCESS,
  payload: data,
});

export const clientDetailsFailure = (data) => ({
  type: designSelectionsAction.CLIENT_DETAILS_FAILURE,
  payload: data,
});

export const clientDetails = (CompanyId,clientId,token) => async (dispatch) => {
  
    try {
      dispatch(clientDetailsInitiate());
      const response = await axios.get(`${Url}companies/${CompanyId}/view-config/users/${clientId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(clientDetailsSuccess(response.data));
      } else {
        dispatch(clientDetailsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(clientDetailsFailure(err));
      return err.response
    }
  };

//============================COMAPNY--DETAILS=================

export const companyDetailsInitiate = () => ({
  type: designSelectionsAction.COMPANY_DETAILS_INITIATE,
});

export const companyDetailsSuccess = (data) => ({
  type: designSelectionsAction.COMPANY_DETAILS_SUCCESS,
  payload: data,
});

export const companyDetailsFailure = (data) => ({
  type: designSelectionsAction.COMPANY_DETAILS_FAILURE,
  payload: data,
});

export const companyDetails = (CompanyId,token) => async (dispatch) => {
  
    try {
      dispatch(companyDetailsInitiate());
      const response = await axios.get(`${Url}companies/${CompanyId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(companyDetailsSuccess(response.data));
      } else {
        dispatch(companyDetailsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(companyDetailsFailure(err));
      return err.response
    }
  };

//============================GET-GENERATE-AGREMENT=================


export const getGenerateAgreementInitiate = () => ({
  type: designSelectionsAction.GET_GENERATE_AGREEMENT_INITIATE,
});

export const getGenerateAgreementSuccess = (data) => ({
  type: designSelectionsAction.GET_GENERATE_AGREEMENT_SUCCESS,
  payload: data,
});

export const getGenerateAgreementFailure = (data) => ({
  type: designSelectionsAction.GET_GENERATE_AGREEMENT_FAILURE,
  payload: data,
});

export const getGenerateAgreement = (ProjectId,designCategoryId,name) => async (dispatch) => {
  
  
    try { const token = isLoggedIn("adminData1");
      dispatch(getGenerateAgreementInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${ProjectId}/design-selection/agreement?designCategoryId=${designCategoryId}&name=${name}&designation=Design Manager`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getGenerateAgreementSuccess(response.data));
      } else {
        dispatch(getGenerateAgreementFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getGenerateAgreementFailure(err));
      return err.response
    }
  };

//==========================update--doce=====

export const updateDesignDocsInitiate = () => ({
  type: designSelectionsAction.UPDATE_DESIGN_DOCS_INITIATE,
});

export const updateDesignDocsSuccess = (data) => ({
  type: designSelectionsAction.UPDATE_DESIGN_DOCS_SUCCESS,
  payload: data,
});

export const updateDesignDocsFailure = (data) => ({
  type: designSelectionsAction.UPDATE_DESIGN_DOCS_FAILURE,
  payload: data,
});

export const updateDesignDocss = (folderId,docsId,payload) => async (dispatch) => {

    try { const token = isLoggedIn("adminData1");
      dispatch(updateDesignDocsInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${folderId}/design-docs/${docsId}`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateDesignDocsSuccess(response.data));
      } else {
        dispatch(updateDesignDocsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateDesignDocsFailure(err));
      return err.response
    }
  };