import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import Loader from "./Loader";
import {
  createModels,
  getAllProjects,
  getJobListProjectByType,
  getModelsDelete,
  getModelsList,
  updateModels,
} from "../redux/action/modelsAction";
import { useLocation } from "react-router-dom";
import { getAllJobListAction } from "../redux/action/jobAction";
import { checkArray } from "../utils/CheckType";

const initialState = {
  CreateModelsModal: false,
  projectId: "",
  name: "",
  jobId: "",
  jobType: "",
  construction: false,
  designselection: false,
  estimation: false,
  errors: {},
  loading: false,
  UpdateModelsModal: false,
  DeleteModal: false,
  id: "",
};

const Models = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const {
    CreateModelsModal,
    projectId,
    name,
    jobId,
    jobType,
    construction,
    designselection,
    estimation,
    errors,
    loading,
    UpdateModelsModal,
    DeleteModal,
    id,
  } = iState;

  const { modelsList, loader, projectsList, jobListProjectByType } =
    useSelector((state) => state.modelsReducer);
  console.log("jobListProjectByType", jobListProjectByType);

  const { jobListData } = useSelector(
    (state) => state.jobReducer
  );


  const {state}=useLocation()
  useEffect(() => {
    dispatch(getModelsList());
    dispatch(getAllProjects());
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "projectId") {
      const jobData = projectsList?.projects?.find((item) => item.id == value);
      const projectType = jobData?.projectType; // Retrieve projectType from jobData
      dispatch(getAllJobListAction(value));

      dispatch(getJobListProjectByType(value, projectType)); // Dispatch action with id and projectType
      updateState({
        ...iState,
        projectId: value,
      });
    } else if (name === "jobType") {
      var index = e.nativeEvent.target.selectedIndex;
      updateState({
        ...iState,
        [name]: e.nativeEvent.target[index].text,
        jobId: value,
      });
    } else {
      updateState({
        ...iState,
        [name]: value,
      });
    }
  };

  const handleCreateModelsModalShow = () => {
    updateState({
      ...iState,
      CreateModelsModal: true,
      projectId: "",
      name: "",
      jobId: "",
      jobType: "",
      errors: "",
      loading: false,
      id: "",
    });
  };
  const handleCreateModelsModalClose = () => {
    updateState({ ...iState, CreateModelsModal: false });
  };

  const handleCheck = (type) => {
    if (type === "construct") {
      updateState({ ...iState, construction: !construction });
    }
    if (type === "design") {
      updateState({ ...iState, designselection: !designselection });
    }
    if (type === "estimate") {
      updateState({ ...iState, estimation: !estimation });
    }
    // if (type === "report") {
    //     updateState({ ...iState, reports: !reports });
    //   }
  };

  let handleValidation = () => {
    let error = {};
    let formIsValid = true;

    if (!name.trim()) {
      error.nameError = " * Name can't be empty";
      formIsValid = false;
    }
    // else {
    //   if ( !/^[A-Za-z ]+$/.test(concernedPersonName)) {
    //     error.nameError = " * Only Alphabets are allowed ";
    //     formIsValid = false;
    //   }
    // }

    if (!projectId) {
      error.projIdError = "* Please select Project";
      formIsValid = false;
    }
    if (!jobType) {
      error.jobError = "* Please select Job";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: error,
    });
    return formIsValid;
  };

  const handleAddModels = () => {
    let formIsValid = handleValidation();
    if (formIsValid) {
      updateState({ ...iState, loading: true });
      const dataa = {
        projectId,
        name,
        jobId,
        jobType,
        construction,
        designselection,
        estimation,
      };
      dispatch(createModels(dataa))
        .then((res) => {
          if (res.status === 201) {
            dispatch(getModelsList());
            toast.success("Add Models Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            updateState({
              ...iState,
              projectId: "",
              name: "",
              jobId: "",
              jobType: "",
              errors: "",
              loading: false,
              CreateModelsModal: false,
            });
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({ ...iState, loading: false, CreateModelsModal: true });
          }
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, loading: false, CreateModelsModal: true });
        });
    }
  };

  const handleUpdateModelsModalShow = (item) => {
    console.log("items", item);
    updateState({
      ...iState,
      UpdateModelsModal: true,
      projectId: item.projectId,
      name: item.name,
      jobId: item.jobId.id,
      jobType: item.jobType,
      construction: item.construction,
      designselection: item.designselection,
      estimation: item.estimation,
      id: item.id,
    });
  };
  const handleUpdateModelsModalClose = () => {
    updateState({ ...iState, UpdateModelsModal: false });
  };
  const handleUpdateModels = () => {
    let formIsValid = handleValidation();
    if (formIsValid) {
      updateState({ ...iState, loading: true });
      const dataa = {
        id,
        projectId,
        name,
        jobId,
        jobType,
        construction,
        designselection,
        estimation,
      };
      dispatch(updateModels(dataa))
        .then((res) => {
          if (res.status === 201) {
            dispatch(getModelsList());
            toast.success("Add Models Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });

            
            updateState({
              ...iState,
              projectId: "",
              name: "",
              jobId: "",
              jobType: "",
              errors: "",
              loading: false,
              id: "",
              UpdateModelsModal: false,
            });
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({ ...iState, loading: false, CreateModelsModal: true });
          }
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, loading: false, CreateModelsModal: true });
        });
    }
  };

  const handleDeleteShow = (id) => {
    updateState({ ...iState, DeleteModal: true, id: id });
  };

  const handleDeleteClose = () => {
    updateState({ ...iState, DeleteModal: false });
  };

  const handleDeleteModels = () => {
    let dataa = { id };
    dispatch(getModelsDelete(dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(getModelsList());
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          DeleteModal: false,
          id: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          DeleteModal: true,
        });
      }
    });
  };

  const handlePrints = () => {
    const printContent = document.querySelector('.AgreementViewArea').innerHTML;
    document.body.innerHTML = printContent;
    window.print();
    window.location.reload();
  };
  
  
  const handleDownload = () => {
    const printContent = document.querySelector('.AgreementViewArea').innerHTML;
    const blob = new Blob([printContent], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'agreement_view.html';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };


  console.log(iState ,"MODEL")
  return (
    <>
      <Header />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox align-items-start">
            <h4 className="Title">Models</h4>
          </div>
          <div className="FilesArea">
            <div className="FilesHead">
              <div className="FilesLeft">
                <h4 className="mr-4">Base Model Jobs</h4>
              </div>
              <div className="d-flex align-items-center">
                <div className="TodosButtons mt-0 d-flex justify-content-end">
                  <a onClick={handleCreateModelsModalShow}>
                    <img src="images/create_new_model.png" />
                    Create New Model
                  </a>
                </div>
                <div className="NewActions ml-5">
                <a onClick={handlePrints} title="print">
                    <img src="images/icon-53.png" />
                  </a>
                  <a onClick={handleDownload} title="download">
                    <img src="images/download.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="AgreementViewArea" id="agreement-view-area">
                {/* <div className="AgreementViewTop">
                  <a href="javscript:void(0)">
                    <img src="images/modal-big-logo.png" />
                  </a>
                  <div className="AgreementViewTopRight">
                    <h3>iBuild</h3>
                    <p>312 Weddenburn Rd SE</p>
                    <p>Calgary, AB CA, T2J 1J1</p>
                    <p>
                      4034005933 www.ibuildapplications.com
                      linden@ibuildapplications.com
                    </p>
                    <p>
                      “The bitterness of poor quality remains long after the
                      sweetness of meeting
                      <br />
                      the schedule has been gorgotten”
                    </p>
                  </div>
                </div>
                <aside>
                  <p>
                    <label>Table :</label>
                    <span>Warranty Request</span>
                  </p>
                  <p>
                    <label>Project :</label>
                    <span>{state?.ProjectId}</span>
                  </p>
                  <p>
                    <label>Job :</label>
                    <span>{state?.id} </span>
                  </p>
                </aside> */}
          <div className="TableList TableListHeader NewTableList TableHeightScroll">
            <table>
              <thead>
                <tr>
                  <th>Job Number</th>
                  <th>Job Type</th>
                  <th>Model Name</th>
                  <th>Base Foundation Type</th>
                  <th>Copied Modules</th>
                  <th>Creation Date</th>
                  <th>Creation By</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!loader ? (
                  modelsList?.result?.length > 0 ? (
                    modelsList?.result?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.jobId?.jobNumber}</td>
                          <td>{item.jobType}</td>
                          <td>{item.name}</td>
                          <td>N/A</td>
                          <td>
                            {item.construction && "construction "},
                            {item.designselection && "designselection "},
                            {item.estimation && "estimation "}
                          </td>

                          <td>{moment(item.createdAt).format("YYYY-MM-DD")}</td>
                          <td>{item.createdBy??"N/A"}</td>
                          <td>
                            <div className="TableThreeDots text-center">
                              <ul className="">
                                <li className="dropdown">
                                  <a
                                    className=""
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/dots.png" />
                                  </a>
                                  <ol className="dropdown-menu">
                                    <li>
                                      <a
                                        onClick={() =>
                                          handleUpdateModelsModalShow(item)
                                        }
                                        className="FolderPermissionId"
                                      >
                                        <img src="images/Action-1.png" />
                                        Edit Model
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() =>
                                          handleDeleteShow(item.id)
                                        }
                                      >
                                        <img src="images/dropdown-delete.png" />
                                        Remove Model
                                      </a>
                                    </li>
                                  </ol>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    "No data fornd"
                  )
                ) : (
                  <Loader />
                )}
              </tbody>
            </table>
          </div>
          <div className="PaginationArea">
            <h6>Showing 1 to 10 of 27 entries</h6>
            <h6>
              <img src="images/leftarrow.png" /> <span>01</span>{" "}
              <img src="images/rightarrow.png" /> Out 10
            </h6>
          </div>
        </div>
        </div>
      </div>
      <Modal
        show={CreateModelsModal}
        onHide={handleCreateModelsModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleCreateModelsModalClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Create New Model</h4>
                </div>
                <div className="FolderForm">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="form-group">
                        <h6>
                          Name <span className="required">*</span>{" "}
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert Model Name"
                          name="name"
                          value={name}
                          onChange={handleInputChange}
                        />
                        <span style={{ color: "red" }}>{errors.nameError}</span>
                      </div>
                      <div className="form-group">
                        <h6>
                          Project <span className="required">*</span>
                        </h6>
                        <div className="CommonSelectBox w-100">
                          <select
                            name="projectId"
                            value={projectId}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Project</option>
                            {projectsList?.projects?.length > 0 &&
                              projectsList?.projects?.map((data, ind) => {
                                return (
                                  <option key={ind} value={data.id}>
                                    {data.projectName}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <span style={{ color: "red" }}>
                          {errors.projIdError}
                        </span>
                      </div>
                      <div className="form-group">
                        <h6>
                          Job <span className="required">*</span>
                        </h6>
                        <div className="CommonSelectBox w-100">
                          <select
                            name="jobType"
                            // value={jobType}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Job</option>
                            {checkArray(jobListData?.jobs) &&
                          jobListData?.jobs?.map((ele, i) => {
                            return (
                              <>
                                <option value={ele?.id}>
                                  {ele?.jobNumber}
                                </option>
                              </>
                            );
                          })}
                          </select>
                        </div>
                        <span style={{ color: "red" }}>{errors.jobError}</span>
                      </div>
                      <div className="ModalBigheading mb-4">
                        <h6 className="fw-20">
                          Modules <span className="required">*</span>{" "}
                        </h6>
                      </div>
                      <div className="form-group mb-1 ResidentialJobArea d-flex justify-content-between ">
                        <h6>Construction</h6>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={construction}
                            onClick={() => handleCheck("construct")}
                          />
                          <span className="slider" />
                        </label>
                      </div>
                      <div className="form-group mb-1 ResidentialJobArea d-flex justify-content-between ">
                        <h6>Design Selections</h6>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={designselection}
                            onClick={() => handleCheck("design")}
                          />
                          <span className="slider" />
                        </label>
                      </div>
                      <div className="form-group mb-1 ResidentialJobArea d-flex justify-content-between ">
                        <h6>Estimation</h6>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={estimation}
                            onClick={() => handleCheck("estimate")}
                          />
                          <span className="slider" />
                        </label>
                      </div>
                      {/* <div className="form-group mb-1 ResidentialJobArea d-flex justify-content-between ">
                        <h6>Reports</h6>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={reports} 
                            onClick={() => handleCheck("report")}
                          />
                          <span className="slider" />
                        </label>
                      </div> */}
                    </div>
                  </div>
                  <div className="Button text-center mt-4 d-flex justify-content-between">
                    <button
                      className="Create"
                      onClick={handleAddModels}
                      disabled={loading}
                    >
                      Create Model
                    </button>
                    <button
                      className="Outline"
                      onClick={handleCreateModelsModalClose}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={UpdateModelsModal}
        onHide={handleCreateModelsModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleUpdateModelsModalClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Edit Model</h4>
                </div>
                <div className="FolderForm">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <h6>
                          Name <span className="required">*</span>{" "}
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert Model Name"
                          name="name"
                          value={name}
                          onChange={handleInputChange}
                        />
                        <span style={{ color: "red" }}>{errors.nameError}</span>
                      </div>
                      <div className="form-group">
                        <h6>
                          Project <span className="required">*</span>
                        </h6>
                        <div className="CommonSelectBox w-100">
                          <select
                            name="projectId"
                            value={projectId}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Project</option>
                            {projectsList?.projects?.length > 0 &&
                              projectsList?.projects?.map((data, ind) => {
                                return (
                                  <option
                                    value={data?.id}
                                  >
                                    {data?.projectName}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <span style={{ color: "red" }}>
                          {errors.projIdError}
                        </span>
                      </div>
                      <div className="form-group">
                        <h6>
                          Job <span className="required">*</span>
                        </h6>
                        <div className="CommonSelectBox w-100">
                        <select
                            name="jobType"
                            value={jobType}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Job</option>
                            {checkArray(jobListData?.jobs) &&
                          jobListData?.jobs?.map((ele, i) => {
                            return (
                              <>
                                <option value={ele?.jobNumber}>
                                  {ele?.jobNumber}
                                </option>
                              </>
                            );
                          })}
                          </select>
                        </div>
                        <span style={{ color: "red" }}>{errors.jobError}</span>
                      </div>
                      <div className="ModalBigheading mb-4">
                        <h6 className="fw-20">
                          Modules <span className="required">*</span>{" "}
                        </h6>
                      </div>
                      <div className="form-group mb-1 ResidentialJobArea d-flex justify-content-between ">
                        <h6>Construction</h6>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={construction}
                            onClick={() => handleCheck("construct")}
                          />
                          <span className="slider" />
                        </label>
                      </div>
                      <div className="form-group mb-1 ResidentialJobArea d-flex justify-content-between ">
                        <h6>Design Selections</h6>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={designselection}
                            onClick={() => handleCheck("design")}
                          />
                          <span className="slider" />
                        </label>
                      </div>
                      <div className="form-group mb-1 ResidentialJobArea d-flex justify-content-between ">
                        <h6>Estimation</h6>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={estimation}
                            onClick={() => handleCheck("estimate")}
                          />
                          <span className="slider" />
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Creation Date</h6>
                        <p className="ml-0">08/01/2023</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <h6>Creation By</h6>
                        <p className="ml-0">John Doe</p>
                      </div>
                    </div>
                  </div>
                  <div className="Button text-center mt-4 d-flex justify-content-between">
                    <button
                      className="Create ClosePermissionId"
                      onClick={handleUpdateModels}
                      disabled={loading}
                    >
                      Update Model
                    </button>
                    <button
                      className="Outline ClosePermissionId"
                      onClick={handleUpdateModelsModalClose}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={DeleteModal}
        onHide={handleDeleteClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleDeleteClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Delete</h4>
                </div>
                <div className="FolderForm">
                  <div className="my-5">
                    <h6>Are you sure you want to delete this ?</h6>
                  </div>
                  <div className="Button d-flex justify-content-between">
                    <button className="Create" onClick={handleDeleteModels}>
                      Delete
                    </button>
                    <button className="Outline" onClick={handleDeleteClose}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Models;
