import React, { useCallback, useEffect, useState } from "react";
import EventTable from "./EventTable";
import { useDispatch } from "react-redux";

const CommunicationSetup = ({ item }) => {
  const [selectedEvent, setSelectedEvent] = useState({
    id: null,
    flag: "none",
  });

  return (
    <div class="NewAccording">
      <div class="card">
        <div class="card-header">
          <h4
            data-toggle="collapse"
            href={`#col${item?.id}`}
            aria-expanded="false"
            class="collapsed"
          >
            <span>
              <img src="images/down_arrow_white.png" />
            </span>{" "}
            {item?.name}{" "}
          </h4>
        </div>

        <div id={`col${item?.id}`} class="collapse">
          <div class="Title">
            <h6>Event</h6>
            <h6>Notification Types</h6>
            <h6>&nbsp;</h6>
          </div>

          {item?.AdminNotificationEvents?.map((ele) => (
            <>
              <div class="NewAccordingHeader">
                <div
                  onClick={() =>
                    setSelectedEvent({
                      id: ele?.id,
                      flag: selectedEvent.flag == "block" ? "none" : "block",
                    })
                  }
                  class="EventHeading EventHeadingBtn"
                >
                  <h6>
                    <span>
                      <img src="images/type_7.png" />
                    </span>
                    {ele?.eventName}
                  </h6>
                </div>
                <div class="NotificationIcons">
                  {ele?.AdminNotifications?.filter((item) =>
                    ["TASK"].includes(item?.notificationType)
                  ) && (
                    <a href="javascript:void(0);">
                      <img src="images/type_1.png" />
                    </a>
                  )}

                  {ele?.AdminNotifications?.filter((item) =>
                    ["BELL"].includes(item?.notificationType)
                  ) && (
                    <a href="javascript:void(0);">
                      <img src="images/type_2.png" />
                    </a>
                  )}

                  {ele?.AdminNotifications?.filter((item) =>
                    ["EMAIL"].includes(item?.notificationType)
                  ) && (
                    <a href="javascript:void(0);">
                      <img src="images/type_3.png" />
                    </a>
                  )}

                  {ele?.AdminNotifications?.filter((item) =>
                    ["ITINERARY"].includes(item?.notificationType)
                  ) && (
                    <a href="javascript:void(0);">
                      <img src="images/type_4.png" />
                    </a>
                  )}

                  {ele?.AdminNotifications?.filter((item) =>
                    ["TODO"].includes(item?.notificationType)
                  ) && (
                    <a href="javascript:void(0);">
                      <img src="images/type_5.png" />
                    </a>
                  )}
                </div>
                <div class="ActionIcons">
                  <h6>&nbsp;</h6>
                </div>
              </div>

              {/* <EventTable
                selectedEvent={selectedEvent}
                flag={
                  selectedEvent.id == ele?.id
                    ? selectedEvent.flag == "none"
                      ? "block"
                      : "none"
                    : "none"
                }
                element={ele}
              /> */}
              {selectedEvent.id == ele?.id && (
                <EventTable
                  flag={
                    selectedEvent.id == ele?.id
                      ? selectedEvent.flag == "none"
                        ? "block"
                        : "none"
                      : "none"
                  }
                  element={ele}
                />
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CommunicationSetup;
