import { warrantyAction } from "../actionTypes";
import { Url } from "../../Config/Config";
import axios from "axios";
import { isLoggedIn } from "../../utils/tokenCheck";
import { multiPartData, } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

//---------------------------------createJobList----------------------------------------------------
export const listProjectJobsInitiate = () => ({
    type: warrantyAction.LIST_PROJECT_JOBS_INITIATE,
  });
  
  export const listProjectJobsSuccess = (data) => ({
    type: warrantyAction.LIST_PROJECT_JOBS_SUCCESS,
    payload: data,
  });
  
  export const listProjectJobsFailure = (data) => ({
    type: warrantyAction.LIST_PROJECT_JOBS_FAILURE,
    payload: data,
  });
  
  export const getListProjectJobs = (compId) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(listProjectJobsInitiate(compId));
        const response = await axios.get(`${Url}companies/${compId}/jobs`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(listProjectJobsSuccess(response.data));
        } else {
          dispatch(listProjectJobsFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(listProjectJobsFailure(err));
        return err.response
      }
    };


 //---------------------------------createWarrantyFolder---------------------------------------------------
export const createWarrantyFolderInitiate = () => ({
    type: warrantyAction.CREATE_WARRANTY_FOLDER_INITIATE,
  });
  
  export const createWarrantyFolderSuccess = (data) => ({
    type: warrantyAction.CREATE_WARRANTY_FOLDER_SUCCESS,
    payload: data,
  });
  
  export const createWarrantyFolderFailure = (data) => ({
    type: warrantyAction.CREATE_WARRANTY_FOLDER_FAILURE,
    payload: data,
  });
  
  export const getCreateWarrantyFolder = (jobId,jobType,projectId,payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(createWarrantyFolderInitiate(jobId,jobType,projectId,payload));
        const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders?foldertableId=${jobId}&foldertableType=${jobType}&ProjectId=${projectId}`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 201) {
          dispatch(createWarrantyFolderSuccess(response.data));
        } else {
          dispatch(createWarrantyFolderFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(createWarrantyFolderFailure(err));
        return err.response
      }
    };

 //---------------------------------getWarrantyFolderList---------------------------------------------------
export const warrantyFolderListInitiate = () => ({
    type: warrantyAction.WARRANTY_FOLDER_LIST_INITIATE,
  });
  
  export const warrantyFolderListSuccess = (data) => ({
    type: warrantyAction.WARRANTY_FOLDER_LIST_SUCCESS,
    payload: data,
  });
  
  export const warrantyFolderListFailure = (data) => ({
    type: warrantyAction.WARRANTY_FOLDER_LIST_FAILURE,
    payload: data,
  });
  
  export const getWarrantyFolderList = (jobId,jobType,projectId) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(warrantyFolderListInitiate(jobId,jobType,projectId));
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders?foldertableId=${jobId}&foldertableType=${jobType}&projectId=${projectId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(warrantyFolderListSuccess(response.data));
        } else {
          dispatch(warrantyFolderListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(warrantyFolderListFailure(err));
        return err.response
      }
    };

//---------------------------------getWarrantySearchFolderList---------------------------------------------------
export const warrantyFolderSearchListInitiate = () => ({
    type: warrantyAction.WARRANTY_FOLDER_SEARCH_LIST_INITIATE,
  });
  
  export const warrantyFolderSearchListSuccess = (data) => ({
    type: warrantyAction.WARRANTY_FOLDER_SEARCH_LIST_SUCCESS,
    payload: data,
  });
  
  export const warrantyFolderSearchListFailure = (data) => ({
    type: warrantyAction.WARRANTY_FOLDER_SEARCH_LIST_FAILURE,
    payload: data,
  });
  
  export const getWarrantyFolderSearchList = (payload,jobId,jobType,) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(warrantyFolderSearchListInitiate(payload,jobId,jobType,));
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders-search?name=${payload.name}&foldertableId=${jobId}&foldertableType=${jobType}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(warrantyFolderSearchListSuccess(response.data));
        } else {
          dispatch(warrantyFolderSearchListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(warrantyFolderSearchListFailure(err));
        return err.response
      }
    };

//====================================deletewarrantyFolder================================

export const warrantyFolderDeleteInitiate = () => ({
  type: warrantyAction.WARRANTY_FOLDER_DELETE_INITIATE,
});

export const warrantyFolderDeleteSuccess = (data) => ({
  type: warrantyAction.WARRANTY_FOLDER_DELETE_SUCCESS,
  payload: data,
});

export const warrantyFolderDeleteFailure = (data) => ({
  type: warrantyAction.WARRANTY_FOLDER_DELETE_FAILURE,
  payload: data,
});

export const removeWarrantyFolder = (warntId,jobId,jobType) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    const { warntId: extractedDocId } = warntId;
    
    dispatch(warrantyFolderDeleteInitiate(extractedDocId));
  
    const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${extractedDocId}?foldertableId=${jobId}&foldertableType=${jobType}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (response.status === 200) {
      dispatch(warrantyFolderDeleteSuccess(response.data));
    } else {
      dispatch(warrantyFolderDeleteFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(warrantyFolderDeleteFailure(err));
    return err.response;
  }
};

 //---------------------------------getWarrantyhIstoryList---------------------------------------------------
 export const warrantyFolderHistoryListInitiate = () => ({
  type: warrantyAction.WARRANTY_FOLDER_HISTORY_LIST_INITIATE,
});

export const warrantyFolderHistoryListSuccess = (data) => ({
  type: warrantyAction.WARRANTY_FOLDER_HISTORY_LIST_SUCCESS,
  payload: data,
});

export const warrantyFolderHistoryListFailure = (data) => ({
  type: warrantyAction.WARRANTY_FOLDER_HISTORY_LIST_FAILURE,
  payload: data,
});

export const getWarrantyFolderHistoryList = (warntId,jobId,jobType) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(warrantyFolderHistoryListInitiate(warntId,jobId,jobType));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${warntId}/history?foldertableId=${jobId}&foldertableType=${jobType}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(warrantyFolderHistoryListSuccess(response.data));
      } else {
        dispatch(warrantyFolderHistoryListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(warrantyFolderHistoryListFailure(err));
      return err.response
    }
  };

  
 //---------------------------------createnEWFile--------------------------------------------------
export const createNewFileInitiate = () => ({
  type: warrantyAction.CREATE_NEW_FILE_INITIATE,
});

export const createNewFileSuccess = (data) => ({
  type: warrantyAction.CREATE_NEW_FILE_SUCCESS,
  payload: data,
});

export const createNewFileFailure = (data) => ({
  type: warrantyAction.CREATE_NEW_FILE_FAILURE,
  payload: data,
});

export const createNewFileDocs = (id,payload) => async (dispatch) => {
  try {
    const updatedData = multiPartData({ ...payload });
    const token = isLoggedIn("adminData1");
    dispatch(createNewFileInitiate(payload));
    const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${id}/warranty-docs`,updatedData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status == 201) {
      dispatch(createNewFileSuccess(response.data));
    } else {
      dispatch(createNewFileFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(createNewFileFailure(err));
    return err.response
  }
};

 //---------------------------------NEWFILELIST-------------------------------------------------
 export const newFileListInitiate = () => ({
  type: warrantyAction.NEW_FILE_LIST_INITIATE,
});

export const newFileListSuccess = (data) => ({
  type: warrantyAction.NEW_FILE_LIST_SUCCESS,
  payload: data,
});

export const newFileListFailure = (data) => ({
  type: warrantyAction.NEW_FILE_LIST_FAILURE,
  payload: data,
});

export const getNewDocumentList = (id,payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(newFileListInitiate(payload));
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${id}/warranty-docs`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      dispatch(newFileListSuccess(response.data.clientDocDetails));
    } else {
      dispatch(newFileListFailure(response.data.clientDocDetails));
    }
    return response;
  } catch (err) {
    dispatch(newFileListFailure(err));
    return err.response
  }
};

//=====================searchListDocumentation=========================================

export const newFileListSearchitiate = () => ({
  type: warrantyAction.NEW_FILE_LIST_SEARCH_INITIATE,
});

export const newFileListSearchSuccess = (data) => ({
  type: warrantyAction.NEW_FILE_LIST_SEARCH_SUCCESS,
  payload: data,
});

export const newFileListSearchFailure = (data) => ({
  type: warrantyAction.NEW_FILE_LIST_SEARCH_FAILURE,
  payload: data,
});

export const getNewFileListSearchDocumentation = (id,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(newFileListSearchitiate(payload));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${id}/warranty-docs-search?name=${payload.name}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(newFileListSearchSuccess(response.data.clientDocDetails));
      } else {
        dispatch(newFileListSearchFailure(response.data.clientDocDetails));
      }
      return response;
    } catch (err) {
      dispatch(newFileListSearchFailure(err));
      return err.response
    }
  };


//====================================warratanyHistroryList=========================================

export const warrantyHistoryListInitiate = () => ({
  type: warrantyAction.WARRANTY_HISTORY_LIST_INITIATE,
});

export const warrantyHistoryListSuccess = (data) => ({
  type: warrantyAction.WARRANTY_HISTORY_LIST_SUCCESS,
  payload: data,
});

export const   warrantyHistoryListFailure = (data) => ({
  type: warrantyAction.WARRANTY_HISTORY_LIST_FAILURE,
  payload: data,
});

export const getWarrantyHistoryList = (id,docsId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(warrantyHistoryListInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${id}/warranty-docs/${docsId}/history`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch(warrantyHistoryListSuccess(response.data));
      } else {
        dispatch(warrantyHistoryListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(warrantyHistoryListFailure(err));
      return err.response
    }
  };


  //====================================warratanyPendingFieEventsList=========================================

export const warrantyPendingFileEventsListInitiate = () => ({
  type: warrantyAction.WARRANTY_PENDING_FILE_EVENTS_LIST_INITIATE,
});

export const warrantyPendingFileEventsListSuccess = (data) => ({
  type: warrantyAction.WARRANTY_PENDING_FILE_EVENTS_LIST_SUCCESS,
  payload: data,
});

export const  warrantyPendingFileEventsListFailure = (data) => ({
  type: warrantyAction.WARRANTY_PENDING_FILE_EVENTS_LIST_FAILURE,
  payload: data,
});

export const getWarrantyPendingEventsList = (id) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(warrantyPendingFileEventsListInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${id}/warranty-docs-pending-file-events`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(warrantyPendingFileEventsListSuccess(response.data.pendingFileEvents));
      } else {
        dispatch(warrantyPendingFileEventsListFailure(response.data.pendingFileEvents));
      }
      return response;
    } catch (err) {
      dispatch(warrantyPendingFileEventsListFailure(err));
      return err.response
    }
  };

//======================================warrantyViewDocs===============================================


  export const warrantyViewDocsInitiate = () => ({
    type: warrantyAction.WARRANTY_VIEW_DOCS_INITIATE,
  });
  
  export const warrantyViewDocsSuccess = (data) => ({
    type: warrantyAction.WARRANTY_VIEW_DOCS_SUCCESS,
    payload: data,
  });
  
  export const  warrantyViewDocsFailure = (data) => ({
    type: warrantyAction.WARRANTY_VIEW_DOCS_FAILURE,
    payload: data,
  });
  
  export const getWarrantyViewDocs = (id) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(warrantyViewDocsInitiate(id));
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/warranties/${id}/support-doc`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(warrantyViewDocsSuccess(response.data));
        } else {
          dispatch(warrantyViewDocsFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(warrantyViewDocsFailure(err));
        return err.response
      }
    };
//===========================warrantyUpdateDocs=====================================


export const warrantyUpdateDocsInitiate = () => ({
  type: warrantyAction.WARRANTY_UPDATE_DOCS_INITIATE,
});

export const warrantyUpdateDocsSuccess = (data) => ({
  type: warrantyAction.WARRANTY_UPDATE_DOCS_SUCCESS,
  payload: data,
});

export const  warrantyUpdateDocsFailure = (data) => ({
  type: warrantyAction.WARRANTY_UPDATE_DOCS_FAILURE,
  payload: data,
});

export const warrantyUpdateDocs = (foldId,warntId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(warrantyUpdateDocsInitiate(payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/${foldId}/warranty-docs/${warntId}`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(warrantyUpdateDocsSuccess(response.data));
      } else {
        dispatch(warrantyUpdateDocsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(warrantyUpdateDocsFailure(err));
      return err.response
    }
  };

//==================================CreateWarrantySignature================================

export const createWarrantySignatureInitiate = () => ({
  type: warrantyAction.CREATE_WARRANTY_SIGNATURE_INITIATE,
});

export const createWarrantySignatureSuccess = (data) => ({
  type: warrantyAction.CREATE_WARRANTY_SIGNATURE_SUCCESS,
  payload: data,
});

export const  createWarrantySignatureFailure = (data) => ({
  type: warrantyAction.CREATE_WARRANTY_SIGNATURE_FAILURE,
  payload: data,
});

export const createWarrantySignatureDocs = (jobId,jobType,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createWarrantySignatureInitiate(jobId,jobType,payload));
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/manage-signatures?jobId=${jobId}&jobType=${jobType}`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(createWarrantySignatureSuccess(response.data));
      } else {
        dispatch(createWarrantySignatureFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createWarrantySignatureFailure(err));
      return err.response
    }
  };


//==================================sendForClientSignature================================

export const sendForClientSignatureInitiate = () => ({
  type: warrantyAction.SEND_FOR_CLIENT_SIGNATURE_INITIATE,
});

export const sendForClientSignatureSuccess = (data) => ({
  type: warrantyAction.SEND_FOR_CLIENT_SIGNATURE_SUCCESS,
  payload: data,
});

export const  sendForClientSignatureFailure = (data) => ({
  type: warrantyAction.SEND_FOR_CLIENT_SIGNATURE_FAILURE,
  payload: data,
});

export const sendForClientSignatureUpload = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(sendForClientSignatureInitiate(payload));
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/folders/1/client-docs/39/send-for-signature?contracttableId=2&contracttableType=Contract`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(sendForClientSignatureSuccess(response.data));
      } else {
        dispatch(sendForClientSignatureFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(sendForClientSignatureFailure(err));
      return err.response
    }
  };

//=================================getDraftWarranty================================================

export const getDraftWarrantyInitiate = () => ({
  type: warrantyAction.GET_DRAFT_WARRANTY_INITIATE,
});

export const getDraftWarrantySuccess = (data) => ({
  type: warrantyAction.GET_DRAFT_WARRANTY_SUCCESS,
  payload: data,
});

export const  getDraftWarrantyFailure = (data) => ({
  type: warrantyAction.GET_DRAFT_WARRANTY_FAILURE,
  payload: data,
});

export const getDraftWarranty = (projectId,jobId,jobType,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getDraftWarrantyInitiate(payload));
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/draft-warranties?projectId=${projectId}&jobId=${jobId}&jobType=${jobType}`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getDraftWarrantySuccess(response.data));
      } else {
        dispatch(getDraftWarrantyFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getDraftWarrantyFailure(err));
      return err.response
    }
  };

//=================================addWarranty================================================

export const addWarrantyInitiate = () => ({
  type: warrantyAction.ADD_NOTES_WARRANTY_INITIATE,
});

export const addWarrantySuccess = (data) => ({
  type: warrantyAction.ADD_NOTES_WARRANTY_SUCCESS,
  payload: data,
});

export const  addWarrantyFailure = (data) => ({
  type: warrantyAction.ADD_NOTES_WARRANTY_FAILURE,
  payload: data,
});

export const addWarranty = (waranId,payload) => async (dispatch) => {
    try {
      const updatedData = multiPartData({ ...payload });
      const token = isLoggedIn("adminData1");
      dispatch(addWarrantyInitiate(payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/warranties/${waranId}`,updatedData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(addWarrantySuccess(response.data));
      } else {
        dispatch(addWarrantyFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addWarrantyFailure(err));
      return err.response
    }
  };

//=================================WarrantydocsUpdate==============================================

export const warrantySupportDocsUpdateInitiate = () => ({
  type: warrantyAction.WARRANTY_SUPPORT_DOCS_UPDATE_INITIATE,
});

export const warrantySupportDocsUpdateSuccess = (data) => ({
  type: warrantyAction.WARRANTY_SUPPORT_DOCS_UPDATE_SUCCESS,
  payload: data,
});

export const  warrantySupportDocsUpdateFailure = (data) => ({
  type: warrantyAction.WARRANTY_SUPPORT_DOCS_UPDATE_FAILURE,
  payload: data,
});

export const getWarrantySupportDocsUpdate = (waranId,payload) => async (dispatch) => {
    try {
      const updatedData = multiPartData({ ...payload });
      const token = isLoggedIn("adminData1");
      dispatch(warrantySupportDocsUpdateSuccess(payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/warranties/${waranId}/support-doc`,updatedData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(addWarrantySuccess(response.data));
      } else {
        dispatch(warrantySupportDocsUpdateFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(warrantySupportDocsUpdateFailure(err));
      return err.response
    }
  };


//=================================ADDUserUrgency==============================================

export const addUserUrgencyInitiate = () => ({
  type: warrantyAction.ADD_USER_URGENCY_INITIATE,
});

export const addUserUrgencySuccess = (data) => ({
  type: warrantyAction.ADD_USER_URGENCY_SUCCESS,
  payload: data,
});

export const  addUserUrgencyFailure = (data) => ({
  type: warrantyAction.ADD_USER_URGENCY_FAILURE,
  payload: data,
});

export const addUserUrgency = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(addUserUrgencyInitiate(payload));
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/urgency`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(addUserUrgencySuccess(response.data));
      } else {
        dispatch(addUserUrgencyFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addUserUrgencyFailure(err));
      return err.response
    }
  };


//=================================allSystemUrgencyList==============================================

export const allSystemUrgencyListdInitiate = () => ({
  type: warrantyAction.ALL_SYSTEM_URGENCY_LIST_INITIATE,
});

export const allSystemUrgencyListSuccess = (data) => ({
  type: warrantyAction.ALL_SYSTEM_URGENCY_LIST_SUCCESS,
  payload: data,
});

export const  allSystemUrgencyListFailure = (data) => ({
  type: warrantyAction.ALL_SYSTEM_URGENCY_LIST_FAILURE,
  payload: data,
});

export const getAllSystemUrgencyList = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(allSystemUrgencyListdInitiate(payload));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/urgency?definedType=System`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(allSystemUrgencyListSuccess(response.data));
      } else {
        dispatch(allSystemUrgencyListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(allSystemUrgencyListFailure(err));
      return err.response
    }
  };


//=================================allUrgencyList==============================================

export const allUrgencyListdInitiate = () => ({
  type: warrantyAction.ALL_URGENCY_LIST_INITIATE,
});

export const allUrgencyListSuccess = (data) => ({
  type: warrantyAction.ALL_URGENCY_LIST_SUCCESS,
  payload: data,
});

export const  allUrgencyListFailure = (data) => ({
  type: warrantyAction.ALL_URGENCY_LIST_FAILURE,
  payload: data,
});

export const getAllUrgencyList = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(allUrgencyListdInitiate(payload));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/urgency`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(allUrgencyListSuccess(response.data));
      } else {
        dispatch(allUrgencyListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(allUrgencyListFailure(err));
      return err.response
    }
  };


//=================================allUserUrgencyList==============================================

export const allUserUrgencyListInitiate = () => ({
  type: warrantyAction.ALL_USER_URGENCY_LIST_INITIATE,
});

export const allUserUrgencyListSuccess = (data) => ({
  type: warrantyAction.ALL_USER_URGENCY_LIST_SUCCESS,
  payload: data,
});

export const  allUserUrgencyListFailure = (data) => ({
  type: warrantyAction.ALL_USER_URGENCY_LIST_FAILURE,
  payload: data,
});

export const getAllUserUrgencyList = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(allUserUrgencyListInitiate(payload));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/urgency?definedType=User`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(allUserUrgencyListSuccess(response.data));
      } else {
        dispatch(allUserUrgencyListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(allUserUrgencyListFailure(err));
      return err.response
    }
  };

//========================================createrequestItem========================================

export const createRequestItemInitiate = () => ({
  type: warrantyAction.CREATE_REQUEST_ITEM_INITIATE,
});

export const createRequestItemSuccess = (data) => ({
  type: warrantyAction.CREATE_REQUEST_ITEM_SUCCESS,
  payload: data,
});

export const  createRequestItemFailure = (data) => ({
  type: warrantyAction.CREATE_REQUEST_ITEM_FAILURE,
  payload: data,
});

export const createRequestItem = (warntId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createRequestItemInitiate(warntId,payload));
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/warranties/${warntId}/request-items`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(createRequestItemSuccess(response.data));
      } else {
        dispatch(createRequestItemFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createRequestItemFailure(err));
      return err.response
    }
  };


//=================================allRequestITEMList==============================================

export const allRequestItemListInitiate = () => ({
  type: warrantyAction.ALL_REQUEST_ITEM_LIST_INITIATE,
});

export const allRequestItemListSuccess = (data) => ({
  type: warrantyAction.ALL_REQUEST_ITEM_LIST_SUCCESS,
  payload: data,
});

export const  allRequestItemListFailure = (data) => ({
  type: warrantyAction.ALL_REQUEST_ITEM_LIST_FAILURE,
  payload: data,
});

export const getAllRequestItemList = (warntId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(allRequestItemListInitiate(warntId));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/warranties/${warntId}/request-items`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(allRequestItemListSuccess(response.data));
      } else {
        dispatch(allRequestItemListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(allRequestItemListFailure(err));
      return err.response
    }
  };


//=================================allserviceTypeList==============================================

export const allServiceTypeListInitiate = () => ({
  type: warrantyAction.ALL_SERVICE_TYPE_LIST_INITIATE,
});

export const allServiceTypeListSuccess = (data) => ({
  type: warrantyAction.ALL_SERVICE_TYPE_LIST_SUCCESS,
  payload: data,
});

export const  allServiceTypeListFailure = (data) => ({
  type: warrantyAction.ALL_SERVICE_TYPE_LIST_FAILURE,
  payload: data,
});

export const getAllServiceTypeList = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(allServiceTypeListInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/service-type-option`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(allServiceTypeListSuccess(response.data));
      } else {
        dispatch(allServiceTypeListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(allServiceTypeListFailure(err));
      return err.response
    }
  };

//===========================allItemTypes===============================================


export const allItemTypeListInitiate = () => ({
  type: warrantyAction.ALL_ITEM_TYPE_LIST_INITIATE,
});

export const allItemTypeListSuccess = (data) => ({
  type: warrantyAction.ALL_ITEM_TYPE_LIST_SUCCESS,
  payload: data,
});

export const  allItemTypeListFailure = (data) => ({
  type: warrantyAction.ALL_ITEM_TYPE_LIST_FAILURE,
  payload: data,
});

export const getAllItemTypeList = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(allItemTypeListInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/item-type-option`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(allItemTypeListSuccess(response.data));
      } else {
        dispatch(allItemTypeListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(allItemTypeListFailure(err));
      return err.response
    }
  };
//==============================clearTabledata============================

export const clearTableDataInitiate = () => ({
  type: warrantyAction.CLEAR_TABLE_DATA_INITIATE,
});

export const clearTableDataSuccess = (data) => ({
  type: warrantyAction.CLEAR_TABLE_DATA_SUCCESS,
  payload: data,
});

export const  clearTableDataFailure = (data) => ({
  type: warrantyAction.CLEAR_TABLE_DATA_FAILURE,
  payload: data,
});

export const getClearTableData = (warantId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(clearTableDataInitiate(warantId));
      const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/warranties/${warantId}/clear-table`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(clearTableDataSuccess(response.data));
      } else {
        dispatch(clearTableDataFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(clearTableDataFailure(err));
      return err.response
    }
  };

//================================================deleteRequestItem================================

export const deleteRequestItemInitiate = () => ({
  type: warrantyAction.DELETE_REQUEST_ITEM_INITIATE,
});

export const deleteRequestItemSuccess = (data) => ({
  type: warrantyAction.DELETE_REQUEST_ITEM_SUCCESS,
  payload: data,
});

export const  deleteRequestItemFailure = (data) => ({
  type: warrantyAction.DELETE_REQUEST_ITEM_FAILURE,
  payload: data,
});

export const getDeleteRequestItem = (warantId,reqId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const { reqId: extractedReqId } = reqId;
    dispatch(deleteRequestItemInitiate(extractedReqId));
      const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/warranties/${warantId}/request-items/${extractedReqId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(deleteRequestItemSuccess(response.data));
      } else {
        dispatch(deleteRequestItemFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(deleteRequestItemFailure(err));
      return err.response
    }
  };

//================================================warrantyEditableByClient================================

export const warrantyEditableByClientInitiate = () => ({
  type: warrantyAction.WARRANTY_EDITABLE_BY_CLIENT_INITIATE,
});

export const warrantyEditableByClientSuccess = (data) => ({
  type: warrantyAction.WARRANTY_EDITABLE_BY_CLIENT_SUCCESS,
  payload: data,
});

export const  warrantyEditableByClientFailure = (data) => ({
  type: warrantyAction.WARRANTY_EDITABLE_BY_CLIENT_FAILURE,
  payload: data,
});

export const getWarrantyEditableByClient = (warantId,bolType,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
    dispatch(warrantyEditableByClientInitiate(payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/warranties/${warantId}/editable?editByClient=${bolType}`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(warrantyEditableByClientSuccess(response.data));
      } else {
        dispatch(warrantyEditableByClientFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(warrantyEditableByClientFailure(err));
      return err.response
    }
  };

//==========================warrantyUpdate===========================================


export const warrantyUpdateInitiate = () => ({
  type: warrantyAction.WARRANTY_UPDATE_INITIATE,
});

export const warrantyUpdateSuccess = (data) => ({
  type: warrantyAction.WARRANTY_UPDATE_SUCCESS,
  payload: data,
});

export const  warrantyUpdateFailure = (data) => ({
  type: warrantyAction.WARRANTY_UPDATE_FAILURE,
  payload: data,
});

export const getWarrantyUpdate = (warantId,payload) => async (dispatch) => {
    try {
      const updatedData = multiPartData({ ...payload });
      const token = isLoggedIn("adminData1");
    dispatch(warrantyUpdateInitiate(payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/warranties/${warantId}`,updatedData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(warrantyUpdateSuccess(response.data));
      } else {
        dispatch(warrantyUpdateFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(warrantyUpdateFailure(err));
      return err.response
    }
  };



//==========================itemSupportDOcUpdate===========================================

export const itemSupportDocUpdateInitiate = () => ({
  type: warrantyAction.ITEM_SUPPORT_DOC_UPDATE_INITIATE,
});

export const itemSupportDocUpdateSuccess = (data) => ({
  type: warrantyAction.ITEM_SUPPORT_DOC_UPDATE_SUCCESS,
  payload: data,
});

export const  itemSupportDocUpdateFailure = (data) => ({
  type: warrantyAction.ITEM_SUPPORT_DOC_UPDATE_FAILURE,
  payload: data,
});

export const getItemSupportDocUpdate= (warantId,payload) => async (dispatch) => {
    try {
      const updatedData = multiPartData({ ...payload });
      const token = isLoggedIn("adminData1");
    dispatch(itemSupportDocUpdateInitiate(payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/warranties/${warantId}`,updatedData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(itemSupportDocUpdateSuccess(response.data));
      } else {
        dispatch(itemSupportDocUpdateFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(itemSupportDocUpdateFailure(err));
      return err.response
    }
  };

//============================craeteItemStatus================================

export const craeteNewItemTypeInitiate = () => ({
  type: warrantyAction.CREATE_NEW_ITEM_TYPE_INITIATE,
});

export const  craeteNewItemTypeSuccess = (data) => ({
  type: warrantyAction.CREATE_NEW_ITEM_TYPE_SUCCESS,
  payload: data,
});

export const  craeteNewItemTypeFailure = (data) => ({
  type: warrantyAction.CREATE_NEW_ITEM_TYPE_FAILURE,
  payload: data,
});

export const craeteNewItemType = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(craeteNewItemTypeInitiate(payload));
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/item-type-option`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(craeteNewItemTypeSuccess(response.data));
      } else {
        dispatch(craeteNewItemTypeFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(craeteNewItemTypeFailure(err));
      return err.response
    }
  };

//============================craeteNewserviceType================================

export const craeteNewServiceTypeInitiate = () => ({
  type: warrantyAction.CREATE_NEW_SERVICE_TYPE_INITIATE,
});

export const  craeteNewServiceTypeSuccess = (data) => ({
  type: warrantyAction.CREATE_NEW_SERVICE_TYPE_SUCCESS,
  payload: data,
});

export const  craeteNewServiceTypeFailure = (data) => ({
  type: warrantyAction.CREATE_NEW_SERVICE_TYPE_FAILURE,
  payload: data,
});

export const craeteNewServiceType = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(craeteNewServiceTypeInitiate(payload));
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/service-type-option`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(craeteNewServiceTypeSuccess(response.data));
      } else {
        dispatch(craeteNewServiceTypeFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(craeteNewServiceTypeFailure(err));
      return err.response
    }
  };

//============================allSystemserviceTypeList================================

export const allSystemServiceTypeListInitiate = () => ({
  type: warrantyAction.ALL_SYSTEM_SERVICE_TYPE_LIST_INITIATE,
});

export const  allSystemServiceTypeListSuccess = (data) => ({
  type: warrantyAction.ALL_SYSTEM_SERVICE_TYPE_LIST_SUCCESS,
  payload: data,
});

export const  allSystemServiceTypeListFailure = (data) => ({
  type: warrantyAction.ALL_SYSTEM_SERVICE_TYPE_LIST_FAILURE,
  payload: data,
});

export const getAllSystemServiceTypeList = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(allSystemServiceTypeListInitiate(payload));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/service-type-option?definedType=System`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(allSystemServiceTypeListSuccess(response.data));
      } else {
        dispatch(allSystemServiceTypeListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(allSystemServiceTypeListFailure(err));
      return err.response
    }
  };


//============================alluserserviceTypeList================================

export const allUserServiceTypeListInitiate = () => ({
  type: warrantyAction.ALL_USER_SERVICE_TYPE_LIST_INITIATE,
});

export const  allUserServiceTypeListSuccess = (data) => ({
  type: warrantyAction.ALL_USER_SERVICE_TYPE_LIST_SUCCESS,
  payload: data,
});

export const  allUserServiceTypeListFailure = (data) => ({
  type: warrantyAction.ALL_USER_SERVICE_TYPE_LIST_FAILURE,
  payload: data,
});

export const getAllUserServiceTypeList = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(allUserServiceTypeListInitiate(payload));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/service-type-option?definedType=User`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(allUserServiceTypeListSuccess(response.data));
      } else {
        dispatch(allUserServiceTypeListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(allUserServiceTypeListFailure(err));
      return err.response
    }
  };


//=============================================deleteServiceType===============================

export const deleteServiceTypeInitiate = () => ({
  type: warrantyAction.DELETE_SERVICE_TYPE_INITIATE,
});

export const deleteServiceTypeSuccess = (data) => ({
  type: warrantyAction.DELETE_SERVICE_TYPE_SUCCESS,
  payload: data,
});

export const  deleteServiceTypeFailure = (data) => ({
  type: warrantyAction.DELETE_SERVICE_TYPE_FAILURE,
  payload: data,
});

export const getDeleteServiceType = (reqId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      const { reqId: extractedReqId } = reqId;
    dispatch(deleteServiceTypeInitiate(extractedReqId));
      const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/service-type-option/${extractedReqId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(deleteServiceTypeSuccess(response.data));
      } else {
        dispatch(deleteServiceTypeFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(deleteServiceTypeFailure(err));
      return err.response
    }
  };

//============================allSystemITEMTypeList================================

export const allSystemItemListInitiate = () => ({
  type: warrantyAction.ALL_SYSTEM_ITEM_TYPE_LIST_INITIATE,
});

export const  allSystemItemListSuccess = (data) => ({
  type: warrantyAction.ALL_SYSTEM_ITEM_TYPE_LIST_SUCCESS,
  payload: data,
});

export const  allSystemItemListFailure = (data) => ({
  type: warrantyAction.ALL_SYSTEM_ITEM_TYPE_LIST_FAILURE,
  payload: data,
});

export const getAllSystemItemTypeList = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(allSystemItemListInitiate(payload));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/item-type-option?definedType=System`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(allSystemItemListSuccess(response.data));
      } else {
        dispatch(allSystemItemListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(allSystemItemListFailure(err));
      return err.response
    }
  };

//============================alluserITEMTypeList================================

export const allUserItemTypeListInitiate = () => ({
  type: warrantyAction.ALL_USER_ITEM_TYPE_LIST_INITIATE,
});

export const  allUserItemTypeListSuccess = (data) => ({
  type: warrantyAction.ALL_USER_ITEM_TYPE_LIST_SUCCESS,
  payload: data,
});

export const  allUserItemTypeListFailure = (data) => ({
  type: warrantyAction.ALL_USER_ITEM_TYPE_LIST_FAILURE,
  payload: data,
});

export const getAllUserItemTypeList = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(allUserItemTypeListInitiate(payload));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/item-type-option?definedType=User`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(allUserItemTypeListSuccess(response.data));
      } else {
        dispatch(allUserItemTypeListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(allUserItemTypeListFailure(err));
      return err.response
    }
  };



//=============================================deleteUrgency===============================

export const deleteUrgencyInitiate = () => ({
  type: warrantyAction.DELETE_URGENCY_INITIATE,
});

export const deleteUrgencySuccess = (data) => ({
  type: warrantyAction.DELETE_URGENCY_SUCCESS,
  payload: data,
});

export const  deleteUrgencyFailure = (data) => ({
  type: warrantyAction.DELETE_URGENCY_FAILURE,
  payload: data,
});

export const getDeleteUrgency = (reqId) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
      const { reqId: extractedUrgId } = reqId;
    dispatch(deleteUrgencyInitiate(extractedUrgId));
      const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/urgency/${extractedUrgId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(deleteUrgencySuccess(response.data));
      } else {
        dispatch(deleteUrgencyFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(deleteUrgencyFailure(err));
      return err.response
    }
  };

//==================================workOrderRequestItemList================================

export const workOrderRequestItemListInitiate = () => ({
  type: warrantyAction.WORK_ORDER_REQUEST_ITEM_LIST_INITIATE,
});

export const workOrderRequestItemListSuccess = (data) => ({
  type: warrantyAction.WORK_ORDER_REQUEST_ITEM_LIST_SUCCESS,
  payload: data,
});

export const  workOrderRequestItemListFailure = (data) => ({
  type: warrantyAction.WORK_ORDER_REQUEST_ITEM_LIST_FAILURE,
  payload: data,
});

export const getWorkOrderRequestItemList = () => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
    dispatch(workOrderRequestItemListInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/work-order-request-items`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(workOrderRequestItemListSuccess(response.data));
      } else {
        dispatch(workOrderRequestItemListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(workOrderRequestItemListFailure(err));
      return err.response
    }
  };


//==================================VENdortypesList================================

export const listOfVendorTypesInitiate = () => ({
  type: warrantyAction.LIST_OF_VENDOR_TYPES_INITIATE,
});

export const listOfVendorTypesSuccess = (data) => ({
  type: warrantyAction.LIST_OF_VENDOR_TYPES_SUCCESS,
  payload: data,
});

export const  listOfVendorTypesFailure = (data) => ({
  type: warrantyAction.LIST_OF_VENDOR_TYPES_FAILURE,
  payload: data,
});

export const getListOfVendorTypes = () => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
    dispatch(listOfVendorTypesInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/vendor-types`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(listOfVendorTypesSuccess(response.data));
      } else {
        dispatch(listOfVendorTypesFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(listOfVendorTypesFailure(err));
      return err.response
    }
  };

//==========================vendorList========================

export const vendorListInitiate = () => ({
  type: warrantyAction.VENDOR_LIST_INITIATE,
});

export const vendorListSuccess = (data) => ({
  type: warrantyAction.VENDOR_LIST_SUCCESS,
  payload: data,
});

export const  vendorListFailure = (data) => ({
  type: warrantyAction.VENDOR_LIST_FAILURE,
  payload: data,
});

export const getVendorList = (vendorType) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
    dispatch(vendorListInitiate());
      const response = await axios.get(`${Url}vendors-search?vendorType=${vendorType}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(vendorListSuccess(response.data));
      } else {
        dispatch(vendorListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(vendorListFailure(err));
      return err.response
    }
  };


  //==========================CreateWorkOrder=======================

export const createWorkOrderInitiate = () => ({
  type: warrantyAction.CREATE_WORK_ORDER_INITIATE,
});

export const createWorkOrderSuccess = (data) => ({
  type: warrantyAction.CREATE_WORK_ORDER_SUCCESS,
  payload: data,
});

export const  createWorkOrderFailure = (data) => ({
  type: warrantyAction.CREATE_WORK_ORDER_FAILURE,
  payload: data,
});

export const getCreateWorkOrder = (warntId,payload) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
    dispatch(createWorkOrderInitiate(payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/warranties/${warntId}/work-order`, payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(createWorkOrderSuccess(response.data));
      } else {
        dispatch(createWorkOrderFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createWorkOrderFailure(err));
      return err.response
    }
  };

//==================================getAllWarrantyLists================================


export const getAllWarrantyListInitiate = () => ({
  type: warrantyAction.GET_ALL_WARRANTY_LIST_INITIATE,
});

export const getAllWarrantyListSuccess = (data) => ({
  type: warrantyAction.GET_ALL_WARRANTY_LIST_SUCCESS,
  payload: data,
});

export const  getAllWarrantyListFailure = (data) => ({
  type: warrantyAction.GET_ALL_WARRANTY_LIST_FAILURE,
  payload: data,
});

export const getAllWarrantyList = (jobId,jobType,projectId) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
    dispatch(getAllWarrantyListInitiate(jobId,jobType,projectId));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/warranties?jobId=${jobId}&jobType=${jobType}&projectId=${projectId}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getAllWarrantyListSuccess(response.data));
      } else {
        dispatch(getAllWarrantyListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getAllWarrantyListFailure(err));
      return err.response
    }
  };

//============================getAllOrderList==========================================


export const getAllWorkOrderListInitiate = () => ({
  type: warrantyAction.GET_ALL_WORK_ORDER_LIST_INITIATE,
});

export const getAllWorkOrderListSuccess = (data) => ({
  type: warrantyAction.GET_ALL_WORK_ORDER_LIST_SUCCESS,
  payload: data,
});

export const  getAllWorkOrderListFailure = (data) => ({
  type: warrantyAction.GET_ALL_WORK_ORDER_LIST_FAILURE,
  payload: data,
});

export const getAllWorkOrderList = (jobId,jobType) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
    dispatch(getAllWorkOrderListInitiate(jobId,jobType));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/all-work-order?jobId=${jobId}&jobType=${jobType}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getAllWorkOrderListSuccess(response.data));
      } else {
        dispatch(getAllWorkOrderListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getAllWorkOrderListFailure(err));
      return err.response
    }
  };

//==============================warrantySupportDocsUpdate============================

export const warrantySupportDocUpdateInitiate = () => ({
  type: warrantyAction.WARRANTY_SUPPORT_DOC_UPDATE_INITIATE,
});

export const warrantySupportDocUpdateSuccess = (data) => ({
  type: warrantyAction.WARRANTY_SUPPORT_DOC_UPDATE_SUCCESS,
  payload: data,
});

export const  warrantySupportDocUpdateFailure = (data) => ({
  type: warrantyAction.WARRANTY_SUPPORT_DOC_UPDATE_FAILURE,
  payload: data,
});

export const getWarrantySupportDocUpdate = (waranId,payload) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
      const updatedData = multiPartData({ ...payload });
    dispatch(warrantySupportDocUpdateInitiate(payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/warranties/${waranId}/support-doc`,updatedData,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(warrantySupportDocUpdateSuccess(response.data));
      } else {
        dispatch(warrantySupportDocUpdateFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(warrantySupportDocUpdateFailure(err));
      return err.response
    }
  };

//=================================supportDocsList============================

export const supportingDocsListInitiate = () => ({
  type: warrantyAction.SUPPORTING_DOCS_LIST_INITIATE,
});

export const supportingDocsListSuccess = (data) => ({
  type: warrantyAction.SUPPORTING_DOCS_LIST_SUCCESS,
  payload: data,
});

export const  supportingDocsListFailure = (data) => ({
  type: warrantyAction.SUPPORTING_DOCS_LIST_FAILURE,
  payload: data,
});

export const getSupportingDocsList = (waranId) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
      dispatch(supportingDocsListInitiate(waranId));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/warranties/${waranId}/supporting-documents-list`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(supportingDocsListSuccess(response.data));
      } else {
        dispatch(supportingDocsListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(supportingDocsListFailure(err));
      return err.response
    }
  };

//==================================================deleteWarranty================================

export const deleteWarrantyInitiate = () => ({
  type: warrantyAction.DELETE_WARRANTY_INITIATE,
});

export const deleteWarrantySuccess = (data) => ({
  type: warrantyAction.DELETE_WARRANTY_SUCCESS,
  payload: data,
});

export const  deleteWarrantyFailure = (data) => ({
  type: warrantyAction.DELETE_WARRANTY_FAILURE,
  payload: data,
});

export const getDeleteWarranty = (waranId) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
      const { warntId: extractedDocId } = waranId;
      dispatch(deleteWarrantyInitiate(extractedDocId));
      const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/warranties/${extractedDocId}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(deleteWarrantySuccess(response.data));
      } else {
        dispatch(deleteWarrantyFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(deleteWarrantyFailure(err));
      return err.response
    }
  };

//==================================================deletewORKoRDERWarranty================================

export const deleteWorkOrderInitiate = () => ({
  type: warrantyAction.DELETE_WORK_ORDER_INITIATE,
});

export const deleteWorkOrderSuccess = (data) => ({
  type: warrantyAction.DELETE_WORK_ORDER_SUCCESS,
  payload: data,
});

export const  deleteWorkOrderFailure = (data) => ({
  type: warrantyAction.DELETE_WORK_ORDER_FAILURE,
  payload: data,
});

export const getWorkOrderDelete = (workId) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(deleteWorkOrderInitiate(workId));

    const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/work-order/${workId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      dispatch(deleteWorkOrderSuccess(response.data));
    } else {
      dispatch(deleteWorkOrderFailure(response.data));
    }

    return response;
  } catch (err) {
    dispatch(deleteWorkOrderFailure(err));
    return err.response;
  }
};

//=========================================inspector-list===========================================


export const inspectorListInitiate = () => ({
  type: warrantyAction.INSPECTOR_LIST_INITIATE,
});

export const inspectorListSuccess = (data) => ({
  type: warrantyAction.INSPECTOR_LIST_SUCCESS,
  payload: data,
});

export const  inspectorListFailure = (data) => ({
  type: warrantyAction.INSPECTOR_LIST_FAILURE,
  payload: data,
});

export const getInspectorList = () => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
      dispatch(inspectorListInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/inspector-list`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(inspectorListSuccess(response.data));
      } else {
        dispatch(inspectorListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(inspectorListFailure(err));
      return err.response
    }
  };

//=================================create-inspector========================


export const createInspectorInitiate = () => ({
  type: warrantyAction.CREATE_INSPECTOR_INITIATE,
});

export const createInspectorSuccess = (data) => ({
  type: warrantyAction.CREATE_INSPECTOR_SUCCESS,
  payload: data,
});

export const  createInspectorFailure = (data) => ({
  type: warrantyAction.CREATE_INSPECTOR_FAILURE,
  payload: data,
});

export const createInspector = (payload) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
      dispatch(createInspectorInitiate(payload));
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/request-items/1/send-email-inspector`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(createInspectorSuccess(response.data));
      } else {
        dispatch(createInspectorFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createInspectorFailure(err));
      return err.response
    }
  };

//==============================work-order-draft=============================

export const getDraftWorkOrderInitiate = () => ({
  type: warrantyAction.GET_DRAFT_WORK_ORDER_INITIATE,
});

export const getDraftWorkOrderSuccess = (data) => ({
  type: warrantyAction.GET_DRAFT_WORK_ORDER_SUCCESS,
  payload: data,
});

export const  getDraftWorkOrderFailure = (data) => ({
  type: warrantyAction.GET_DRAFT_WORK_ORDER_FAILURE,
  payload: data,
});

export const getDraftWorkOrder = (jobId,jobType) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
      dispatch(getDraftWorkOrderInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/work-order?jobId=${jobId}&jobType=${jobType}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getDraftWorkOrderSuccess(response.data));
      } else {
        dispatch(getDraftWorkOrderFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getDraftWorkOrderFailure(err));
      return err.response
    }
  };

//=============================update-work-order=======================


export const updateWorkOrderInitiate = () => ({
  type: warrantyAction.UPDATE_WORK_ORDER_INITIATE,
});

export const updateWorkOrderSuccess = (data) => ({
  type: warrantyAction.UPDATE_WORK_ORDER_SUCCESS,
  payload: data,
});

export const  updateWorkOrderFailure = (data) => ({
  type: warrantyAction.UPDATE_WORK_ORDER_FAILURE,
  payload: data,
});

export const updateWorkOrder = (warntId,payload) => async (dispatch) => {
    
  try {
   
      const token = isLoggedIn("adminData1");
      dispatch(updateWorkOrderInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/work-order/${warntId}`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateWorkOrderSuccess(response.data));
      } else {
        dispatch(updateWorkOrderFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateWorkOrderFailure(err));
      return err.response
    }
  };

//=====================================getSelectedRequest-items==========================


export const getSelectedRequestItemsInitiate = () => ({
  type: warrantyAction.GET_SELECTED_REQUEST_ITEMS_INITIATE,
});

export const getSelectedRequestItemsSuccess = (data) => ({
  type: warrantyAction.GET_SELECTED_REQUEST_ITEMS_SUCCESS,
  payload: data,
});

export const  getSelectedRequestItemsFailure = (data) => ({
  type: warrantyAction.GET_SELECTED_REQUEST_ITEMS_FAILURE,
  payload: data,
});

export const getSelectedRequestItems = (warntId) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
      dispatch(getSelectedRequestItemsInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/work-orders/${warntId}/selected-work-order-request-items`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getSelectedRequestItemsSuccess(response.data));
      } else {
        dispatch(getSelectedRequestItemsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getSelectedRequestItemsFailure(err));
      return err.response
    }
  };

//========================getWarranty-selected-items====================


export const getSelectedWarrantyRequestItemsInitiate = () => ({
  type: warrantyAction.GET_SELECTED_WARRANTY_REQUEST_ITEMS_INITIATE,
});

export const getSelectedWarrantyRequestItemsSuccess = (data) => ({
  type: warrantyAction.GET_SELECTED_WARRANTY_REQUEST_ITEMS_SUCCESS,
  payload: data,
});

export const  getSelectedWarrantyRequestItemsFailure = (data) => ({
  type: warrantyAction.GET_SELECTED_WARRANTY_REQUEST_ITEMS_FAILURE,
  payload: data,
});

export const getSelectedWarrantyRequestItems = (warntId) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
      dispatch(getSelectedWarrantyRequestItemsInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/warranties/${warntId}/request-items`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getSelectedWarrantyRequestItemsSuccess(response.data));
      } else {
        dispatch(getSelectedWarrantyRequestItemsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getSelectedWarrantyRequestItemsFailure(err));
      return err.response
    }
  };

//============================get-warranty-discussion-list================

export const getWarrantySettingDiscussionInitiate = () => ({
  type: warrantyAction.GET_WARRANTY_SETTING_DISCUSSION_INITIATE,
});

export const getWarrantySettingDiscussionSuccess = (data) => ({
  type: warrantyAction.GET_WARRANTY_SETTING_DISCUSSION_SUCCESS,
  payload: data,
});

export const  getWarrantySettingDiscussionFailure = (data) => ({
  type: warrantyAction.GET_WARRANTY_SETTING_DISCUSSION_FAILURE,
  payload: data,
});

export const getWarrantySettingDiscussion = (commentableType,warntId) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
      dispatch(getWarrantySettingDiscussionInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/discussion-settings?commentableType=${commentableType}&commentableId=${warntId}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getWarrantySettingDiscussionSuccess(response.data));
      } else {
        dispatch(getWarrantySettingDiscussionFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getWarrantySettingDiscussionFailure(err));
      return err.response
    }
  };

//============================warranty-discussion--update======================

export const updateWarrantySettingDiscussionInitiate = () => ({
  type: warrantyAction.UPDATE_WARRANTY_SETTING_DISCUSSION_INITIATE,
});

export const updateWarrantySettingDiscussionSuccess = (data) => ({
  type: warrantyAction.UPDATE_WARRANTY_SETTING_DISCUSSION_SUCCESS,
  payload: data,
});

export const  updateWarrantySettingDiscussionFailure = (data) => ({
  type: warrantyAction.UPDATE_WARRANTY_SETTING_DISCUSSION_FAILURE,
  payload: data,
});

export const updateWarrantySettingDiscussion = (payload) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
      dispatch(updateWarrantySettingDiscussionInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/discussion-settings`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateWarrantySettingDiscussionSuccess(response.data));
      } else {
        dispatch(updateWarrantySettingDiscussionFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateWarrantySettingDiscussionFailure(err));
      return err.response
    }
  };

//=================================get-work-order--discussion==================

export const getWorkOrderSettingDiscussionInitiate = () => ({
  type: warrantyAction.GET_WORK_ORDER_SETTING_DISCUSSION_INITIATE,
});

export const getWorkOrderSettingDiscussionSuccess = (data) => ({
  type: warrantyAction.GET_WORK_ORDER_SETTING_DISCUSSION_SUCCESS,
  payload: data,
});

export const  getWorkOrderSettingDiscussionFailure = (data) => ({
  type: warrantyAction.GET_WORK_ORDER_SETTING_DISCUSSION_FAILURE,
  payload: data,
});

export const getWorkOrderSettingDiscussion = (commentableType,warntId) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
      dispatch(getWorkOrderSettingDiscussionInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/discussion-settings?commentableType=${commentableType}&commentableId=${warntId}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getWorkOrderSettingDiscussionSuccess(response.data));
      } else {
        dispatch(getWorkOrderSettingDiscussionFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getWorkOrderSettingDiscussionFailure(err));
      return err.response
    }
  };

//===============================work-order-discussion-----update========================

export const updateWorkOrderSettingDiscussionInitiate = () => ({
  type: warrantyAction.UPDATE_WORK_ORDER_SETTING_DISCUSSION_INITIATE,
});

export const updateWorkOrderSettingDiscussionSuccess = (data) => ({
  type: warrantyAction.UPDATE_WORK_ORDER_SETTING_DISCUSSION_SUCCESS,
  payload: data,
});

export const  updateWorkOrderSettingDiscussionFailure = (data) => ({
  type: warrantyAction.UPDATE_WORK_ORDER_SETTING_DISCUSSION_FAILURE,
  payload: data,
});

export const updateWorkOrderSettingDiscussion = (payload) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
      dispatch(updateWorkOrderSettingDiscussionInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/discussion-settings`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateWorkOrderSettingDiscussionSuccess(response.data));
      } else {
        dispatch(updateWorkOrderSettingDiscussionFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateWorkOrderSettingDiscussionFailure(err));
      return err.response
    }
  };

//=======================GET-warranty--jobs=======================

export const getWarrantyJobsInitiate = () => ({
  type: warrantyAction.GET_WARRANTY_JOBS_INITIATE,
});

export const getWarrantyJobsSuccess = (data) => ({
  type: warrantyAction.GET_WARRANTY_JOBS_SUCCESS,
  payload: data,
});

export const  getWarrantyJobsFailure = (data) => ({
  type: warrantyAction.GET_WARRANTY_JOBS_FAILURE,
  payload: data,
});

export const getWarrantyJobs = () => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
      dispatch(getWarrantyJobsInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/warranty-jobs`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getWarrantyJobsSuccess(response.data));
      } else {
        dispatch(getWarrantyJobsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getWarrantyJobsFailure(err));
      return err.response
    }
  };
//=============================SHow--in--warranty--jobs===============

export const showInWarrantyJobsInitiate = () => ({
  type: warrantyAction.SHOW_IN_WARRANTY_JOBS_INITIATE,
});

export const showInWarrantyJobsSuccess = (data) => ({
  type: warrantyAction.SHOW_IN_WARRANTY_JOBS_SUCCESS,
  payload: data,
});

export const  showInWarrantyJobsFailure = (data) => ({
  type: warrantyAction.SHOW_IN_WARRANTY_JOBS_FAILURE,
  payload: data,
});

export const showInWarranty = (payload) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
      dispatch(showInWarrantyJobsInitiate());
      const response = await axios.patch(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/warranty-jobs`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(showInWarrantyJobsSuccess(response.data));
      } else {
        dispatch(showInWarrantyJobsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(showInWarrantyJobsFailure(err));
      return err.response
    }
  };

//=====================update-service--type================

export const updateServiceTypeInitiate = () => ({
  type: warrantyAction.UPDATE_SERVICE_TYPE_INITIATE,
});

export const updateServiceTypeSuccess = (data) => ({
  type: warrantyAction.UPDATE_SERVICE_TYPE_SUCCESS,
  payload: data,
});

export const  updateServiceTypeFailure = (data) => ({
  type: warrantyAction.UPDATE_SERVICE_TYPE_FAILURE,
  payload: data,
});

export const updateServiceTypes = (id,payload) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
      dispatch(updateServiceTypeInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/service-type-option/${id}`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateServiceTypeSuccess(response.data));
      } else {
        dispatch(updateServiceTypeFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateServiceTypeFailure(err));
      return err.response
    }
  };
//=======================INSEPECTOR=UPDATE=========================

export const updateInspectorInitiate = () => ({
  type: warrantyAction.UPDATE_INSPECTOR_INITIATE,
});

export const updateInspectorSuccess = (data) => ({
  type: warrantyAction.UPDATE_INSPECTOR_SUCCESS,
  payload: data,
});

export const  updateInspectorFailure = (data) => ({
  type: warrantyAction.UPDATE_INSPECTOR_FAILURE,
  payload: data,
});

export const updateInspectors = (id,payload) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
      dispatch(updateInspectorInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/${id}`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateInspectorSuccess(response.data));
      } else {
        dispatch(updateInspectorFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateInspectorFailure(err));
      return err.response
    }
  };
  
//=============================WORK-ORDER-INSPECTION===============

export const workOrderInspectionInitiate = () => ({
  type: warrantyAction.SHOW_IN_WARRANTY_JOBS_INITIATE,
});

export const workOrderInspectionSuccess = (data) => ({
  type: warrantyAction.SHOW_IN_WARRANTY_JOBS_SUCCESS,
  payload: data,
});

export const  workOrderInspectionFailure = (data) => ({
  type: warrantyAction.SHOW_IN_WARRANTY_JOBS_FAILURE,
  payload: data,
});

export const workOrderInspection = (id,payload) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
      dispatch(workOrderInspectionInitiate());
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/work-orders/${id}/inspections`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(workOrderInspectionSuccess(response.data));
      } else {
        dispatch(workOrderInspectionFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(workOrderInspectionFailure(err));
      return err.response
    }
  };


//=============================WORK-ORDER-SELCTION-LIST-TABLE===============

export const workOrderSelectionListTableInitiate = (data) => ({
  type: warrantyAction.WORK_ORDER_ACTION_SELECTION_LIST_TABLE_INITIATE,
  payload: data,
});

export const workOrderSelectionListTableSuccess = (data) => ({
  type: warrantyAction.WORK_ORDER_ACTION_SELECTION_LIST_TABLE_SUCCESS,
  payload: data,
});

export const  workOrderSelectionListTableFailure = (data) => ({
  type: warrantyAction.WORK_ORDER_ACTION_SELECTION_LIST_TABLE_FAILURE,
  payload: data,
});

export const workOrderSelectionListTable = (id,payload) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
      dispatch(workOrderSelectionListTableInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/work-orders/${id}/all-inspections`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(workOrderSelectionListTableSuccess(response.data));
      } else {
        dispatch(workOrderSelectionListTableFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(workOrderInspectionFailure(err));
      return err.response
    }
  };


//=============================CANCEL_SELECTION_WORK_FLOW===============

export const cancelSelectionWorkFlowInitiate = (data) => ({
  type: warrantyAction.CANCEL_SELECTION_WORK_FLOW_INITIATE,
  payload: data,
});

export const cancelSelectionWorkFlowSuccess = (data) => ({
  type: warrantyAction.CANCEL_SELECTION_WORK_FLOW_SUCCESS,
  payload: data,
});

export const  cancelSelectionWorkFlowFailure = (data) => ({
  type: warrantyAction.CANCEL_SELECTION_WORK_FLOW_FAILURE,
  payload: data,
});

export const cancelSelectionWorkFlow = (id,cancelId) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
      dispatch(cancelSelectionWorkFlowInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/work-orders/${id}/inspections/${cancelId}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(cancelSelectionWorkFlowSuccess(response.data));
      } else {
        dispatch(cancelSelectionWorkFlowFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(workOrderInspectionFailure(err));
      return err.response
    }
  };

//=============================RESEND_SELECTION_WORK_FLOW===============

export const resendSelectionWorkFlowInitiate = (data) => ({
  type: warrantyAction.RESEND_SELECTION_WORK_FLOW_INITIATE,
  payload: data,
});

export const resendSelectionWorkFlowSuccess = (data) => ({
  type: warrantyAction.RESEND_SELECTION_WORK_FLOW_SUCCESS,
  payload: data,
});

export const  resendSelectionWorkFlowFailure = (data) => ({
  type: warrantyAction.RESEND_SELECTION_WORK_FLOW_FAILURE,
  payload: data,
});

export const resendSelectionWorkFlow = (id,cancelId) => async (dispatch) => {
    
  try {
      const token = isLoggedIn("adminData1");
      dispatch(resendSelectionWorkFlowInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/work-orders/${id}/inspections/${cancelId}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(resendSelectionWorkFlowSuccess(response.data));
      } else {
        dispatch(resendSelectionWorkFlowFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(workOrderInspectionFailure(err));
      return err.response
    }
  };

//==================UPDATE--WARRANTY--ITEMS==================


export const updateWarrantyItemInitiate = () => ({
  type: warrantyAction.UPDATE_WARRANTY_ITEM_INITIATE,
});

export const updateWarrantyItemSuccess = (data) => ({
  type: warrantyAction.UPDATE_WARRANTY_ITEM_SUCCESS,
  payload: data,
});

export const  updateWarrantyItemFailure = (data) => ({
  type: warrantyAction.UPDATE_WARRANTY_ITEM_FAILURE,
  payload: data,
});

export const updateWarrantyItem = (warntId,requestItemId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(updateWarrantyItemInitiate(warntId));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/warranties/${warntId}/request-items/${requestItemId}`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateWarrantyItemSuccess(response.data));
      } else {
        dispatch(updateWarrantyItemFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateWarrantyItemFailure(err));
      return err.response
    }
  };

//===========================DELETE --ITEM--TYPE===========================

export const  deleteItemTypeInitiate = () => ({
  type: warrantyAction.DELETE_ITEM_TYPE_INITIATE,
});

export const deleteItemTypeSuccess = (data) => ({
  type: warrantyAction.DELETE_ITEM_TYPE_SUCCESS,
  payload: data,
});

export const deleteItemTypeFailure = (data) => ({
  type: warrantyAction.DELETE_ITEM_TYPE_FAILURE,
  payload: data,
});

export const deleteItemTypes = (itemId) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(deleteItemTypeInitiate());
    const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/item-type-option/${itemId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (response.status === 200) {
      dispatch(deleteItemTypeSuccess(response.data));
    } else {
      dispatch(deleteItemTypeFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(deleteItemTypeFailure(err));
    return err.response;
  }
};



//===========================UPDATE-WARRANTY--TYPE===========================

export const  updateWarrantTypeInitiate = () => ({
  type: warrantyAction.UPDATE_SERVICE_TYPE_INITIATE,
});

export const updateWarrantTypeSuccess = (data) => ({
  type: warrantyAction.UPDATE_SERVICE_TYPE_SUCCESS,
  payload: data,
});

export const updateWarrantTypeFailure = (data) => ({
  type: warrantyAction.UPDATE_SERVICE_TYPE_FAILURE,
  payload: data,
});

export const updateWarrantTypes = (updateId,data) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(updateWarrantTypeInitiate());
    const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/service-type-option/${updateId}`,data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (response.status === 200) {
      dispatch(updateWarrantTypeSuccess(response.data));
    } else {
      dispatch(updateWarrantTypeFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(updateWarrantTypeFailure(err));
    return err.response;
  }
};

//================UPDATE--ITEM--TYPE============================
export const  updateItemTypeInitiate = () => ({
  type: warrantyAction.UPDATE_ITEM_TYPE_INITIATE,
});

export const updateItemTypeSuccess = (data) => ({
  type: warrantyAction.UPDATE_ITEM_TYPE_SUCCESS,
  payload: data,
});

export const updateItemTypeFailure = (data) => ({
  type: warrantyAction.UPDATE_ITEM_TYPE_FAILURE,
  payload: data,
});

export const updateItemTypes = (updateId,data) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(updateItemTypeInitiate());
    const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/item-type-option/${updateId}`,data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (response.status === 200) {
      dispatch(updateItemTypeSuccess(response.data));
    } else {
      dispatch(updateItemTypeFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(updateItemTypeFailure(err));
    return err.response;
  }
};
//===================UPDATE--URGENCY================
export const  updateUrgencyInitiate = () => ({
  type: warrantyAction.UPDATE_URGENCY_INITIATE,
});

export const updateUrgencySuccess = (data) => ({
  type: warrantyAction.UPDATE_URGENCY_SUCCESS,
  payload: data,
});

export const updateUrgencyFailure = (data) => ({
  type: warrantyAction.UPDATE_URGENCY_FAILURE,
  payload: data,
});

export const updateUrgencys = (updateId,data) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(updateUrgencyInitiate());
    const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/urgency/${updateId}`,data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (response.status === 200) {
      dispatch(updateUrgencySuccess(response.data));
    } else {
      dispatch(updateUrgencyFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(updateUrgencyFailure(err));
    return err.response;
  }
};

//===================GET-PRIMARY--CLIENT--BY--JOB==================

export const  getPrimaryClientByJobInitiate = () => ({
  type: warrantyAction.GET_PRIMARY_CLIENT_BY_JOB_INITIATE,
});

export const  getPrimaryClientByJobSuccess = (data) => ({
  type: warrantyAction.GET_PRIMARY_CLIENT_BY_JOB_SUCCESS,
  payload: data,
});

export const getPrimaryClientByJobFailure = (data) => ({
  type: warrantyAction.GET_PRIMARY_CLIENT_BY_JOB_FAILURE,
  payload: data,
});

export const getPrimaryClientByJobs = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(getPrimaryClientByJobInitiate());
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/clients`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params:payload
      }
    );

    if (response.status === 200) {
      dispatch(getPrimaryClientByJobSuccess(response.data));
    } else {
      dispatch(getPrimaryClientByJobFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getPrimaryClientByJobFailure(err));
    return err.response;
  }
};