import React, { useState,useEffect } from 'react'
import { getCommonAreaStep2 } from '../../../redux/action/proformaAction'
import { useDispatch } from 'react-redux'

const FoundationSummaryPreviwe = ({handleShowComponent,handleShowComponents}) => {
  const dispatch=useDispatch([])
  const[commonAreaStep2List,setCommonAreaStep2List]=useState([])
  useEffect(()=>{
    dispatch(getCommonAreaStep2()).then(res=>{
      console.log(res);
      setCommonAreaStep2List(res?.data)
    })
  },[])
  return (
    <>
    <div>
          <div className="row">
                  {commonAreaStep2List?.data?.table1?.blocks?.length > 0 &&
                    commonAreaStep2List?.data?.table1?.blocks?.map(
                      (item, ind) => (
                        <div key={ind} className="col-md-3">
                          <div className="SmallTableArea">
                            <table>
                              <thead>
                                <tr>
                                  <th colSpan={2}>{item.blockDescription}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <strong>Story</strong>
                                  </td>
                                  <td>
                                    <strong>Sq/Ft</strong>
                                  </td>
                                </tr>
                                {item?.MultiStories?.length > 0 &&
                                  item?.MultiStories?.map((data, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{data.value}</td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td>Total</td>
                                  <td>
                                    {item?.MultiStories?.reduce(
                                      (sum, data) => sum + data.value,
                                      0
                                    )}
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      )
                    )}
                </div>

                <div className="BlockCostsPreview">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4>Common Areas Costs</h4>
                    <div className="NewActions">
                      <a href="javascript:void(0);" title="pdf">
                        <img src="images/pdf.png" />
                      </a>
                      <a href="javascript:void(0);" title="download">
                        <img src="images/download.png" />
                      </a>
                    </div>
                  </div>
                  {commonAreaStep2List?.data?.table2?.blockQuantities?.length >
                    0 &&
                    commonAreaStep2List?.data?.table2?.blockQuantities?.map(
                      (data, ind) => {
                        return (
                          <div className="BlockInputsCollapse">
                            <div
                              className="BcpHeader"
                              data-toggle="collapse"
                              href="#collapseExample"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              <h5>{data.name}</h5>
                            </div>
                            <div className="collapse show" id="collapseExample">
                              <div className="card card-body border-0">
                                <div className="BlockInputs">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Code Code #</th>
                                        <th>Description</th>
                                        <th>Block A</th>
                                        <th>Block B</th>

                                        <th>Total Construction Costs</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {data?.ProformaBaseCosts?.length > 0 &&
                                        data?.ProformaBaseCosts?.map(
                                          (data, ind) => {
                                            return (
                                              <tr>
                                                <td>{data.CostCode?.number}</td>
                                                <td>{data.CostCode?.name}</td>
                                                <td>
                                                  {
                                                    data
                                                      .ProformaBlockQuantities?.[0]
                                                      ?.MultiBlock
                                                      ?.blockDescription
                                                  }
                                                </td>

                                                <td>
                                                  $
                                                  {Number(
                                                    data
                                                      .ProformaBlockQuantities?.[0]
                                                      ?.totalCost
                                                  ).toFixed(2) ?? 0}
                                                </td>
                                                <td>{data.totalCostSum}</td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td />
                                        <td>Total Interior Finishing Costs</td>
                                        <td>67,480.00</td>
                                        <td>67,480.00</td>
                                        <td>67,480.00</td>
                                        <td>67,480.00</td>
                                        <td>2,75,480.00</td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
    </div>
    <div className="Button d-flex  justify-content-between align-items-center px-5">
                <button
                  className="Outline border text-success border-success"
                  onClick={() => handleShowComponents('commonUnit')}
                >
                  Cancel
                </button>
                <button
                  className="Create bg-success border border-success"
                  onClick={() => handleShowComponent("commonBuilding")}
                   >
                  Save Draft
                </button>
               
              </div>
    </>
  )
}

export default FoundationSummaryPreviwe