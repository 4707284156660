import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getCommonBuildingStep1,
  getFoundationType,
} from "../../../redux/action/proformaAction";

const CommonBuildingUnit = ({
  numBlocks,
  handleNumBlocksChange,
  handleBuildingFieldChange,
  foundationTypeData,
  handleShowComponent,
  handleShowComponents,
}) => {
  const dispatch = useDispatch([]);
  const [buildings, setBuildings] = useState([]);
  useEffect(() => {
    dispatch(getCommonBuildingStep1()).then((res) => {
      console.log({ res });
      setBuildings(res?.data?.data);
    });
    dispatch(getFoundationType());
  }, []);
  return (
    <>
      <div className="ModalBigheading2 d-flex align-items-center mb-3">
        <h3 className="mb-0">Enter Number of Blocks in the Project</h3>
        <input
          className="ModalBigheadingBox"
          type="number"
          value={numBlocks}
          onChange={handleNumBlocksChange}
        />
        {/* <span className="ModalBigheadingBox"></span> */}
      </div>
      <div className="EnterProjectTable">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Building Name</th>
              <th>Foundation type</th>
              <th>Building Sq /Ft</th>
              <th>Concrete Cubic /Yards</th>
              <th>Foundation Sq/Ft</th>
              <th>Driveways &amp; Sidewalks Sq/Ft</th>
              <th>Driveways &amp; Sidewalks Concrete Cubic/yards</th>
            </tr>
          </thead>
          <tbody>
            {buildings?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <div className="form-group mr-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Site Office"
                      value={item.buildingName}
                      onChange={(e) =>
                        handleBuildingFieldChange(e, index, "buildingName")
                      }
                    />
                  </div>
                </td>
                <td>
                  <div className="CommonSelectBox width-250">
                    <select
                      onChange={(e) =>
                        handleBuildingFieldChange(e, index, "foundationType")
                      }
                    >
                      <option value="">Select Foundation Type</option>
                      {foundationTypeData?.data?.length > 0 &&
                        foundationTypeData?.data?.map((data, i) => {
                          return (
                            <option
                              key={i}
                              selected={data.id == item?.foundationType}
                            >
                              {data.name}
                            </option>
                          );
                        })}
                    </select>
                    {/* <span>
                  <img src="images/down.png" />
                </span> */}
                  </div>
                </td>
                <td>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert Sq/ Ft"
                      value={item.buildingSqFt}
                      onChange={(e) =>
                        handleBuildingFieldChange(e, index, "buildingSqFt")
                      }
                    />
                  </div>
                </td>
                <td>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control width-250"
                      placeholder="Insert cubic / yards"
                      value={item.concreteCubicYards}
                      onChange={(e) =>
                        handleBuildingFieldChange(
                          e,
                          index,
                          "concreteCubicYards"
                        )
                      }
                    />
                  </div>
                </td>
                <td>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert Sq/ Ft"
                      value={item.foundationSqFt}
                      onChange={(e) =>
                        handleBuildingFieldChange(e, index, "foundationSqFt")
                      }
                    />
                  </div>
                </td>
                <td>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert Sq/ Ft"
                      value={item.drivewaysAndSidewalksSqFt}
                      onChange={(e) =>
                        handleBuildingFieldChange(
                          e,
                          index,
                          "drivewaysAndSidewalksSqFt"
                        )
                      }
                    />
                  </div>
                </td>
                <td>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert cubic / yards"
                      value={item.drivewaysAndSidewalksCubicYards}
                      onChange={(e) =>
                        handleBuildingFieldChange(
                          e,
                          index,
                          "drivewaysAndSidewalksCubicYards"
                        )
                      }
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="PaginationArea px-3">
          <h6>Showing 1 to 10 of 27 entries</h6>
          <h6>
            <img src="images/leftarrow.png" /> 01{" "}
            <img src="images/rightarrow.png" /> Out 10
          </h6>
        </div>
      </div>
      <div className="Button d-flex  justify-content-between align-items-center px-5">
        <button
          className="Outline border text-success border-success"
          onClick={() => handleShowComponent("commonarae")}
        >
          Cancel
        </button>
        <button
          className="Create bg-success border border-success"
          onClick={() => handleShowComponents("commonBuildingQuanties")}
        >
          Save Draft
        </button>
      </div>
    </>
  );
};

export default CommonBuildingUnit;
