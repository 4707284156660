import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom'
import { SubscriptionPlanListAction } from '../redux/action/authAction';

const init = {
  currencyType:"usd",
  paymentTime:"", 
  finalPrice:"",
  validUpto:"",
  errors:{}
}
const PlanScreen = () => {
  const [iState, updateState] = useState(init)
  const {currencyType, paymentTime, finalPrice,validUpto, errors} = iState
  const dispatch = useDispatch();
  const state = useLocation()
  const navigate = useNavigate();
  const {id, price} = useParams();
  const [planDetailsData, setPlanDetailsData] = useState([]);
  const {planListData, loader} = useSelector((state) => state.authReducer);

useEffect(() => {
  dispatch(SubscriptionPlanListAction(id)).then((res) => {
    setPlanDetailsData(res.data.plan)
  })
  updateState({...iState, finalPrice:price, validUpto:""})
},[])

const handleRadio = (e) => {
  const { name, value, checked } = e.target;
  if (value === "monthly" && checked == true) {
    updateState({ ...iState , paymentTime:value,finalPrice: planDetailsData?.[0]?.price });
  } else {
    updateState({ ...iState, paymentTime:value,finalPrice: planDetailsData?.[1]?.price });
  }
};
 
useEffect(() => {
  calculateDate();
}, [paymentTime]);
const calculateDate = () => {
  const today = new Date(); // current date
  let futureDate ;
  if(paymentTime === "monthly"){
     futureDate = new Date(today.setDate(today.getDate() + 30));
  } else {
     futureDate = new Date(today.setDate(today.getDate() + 365));
  }
  let year = futureDate.getFullYear(); // get last two digits of year
  let month = (futureDate.getMonth() + 1).toString().padStart(2, "0"); // add leading zero if necessary
  let day = futureDate.getDate().toString().padStart(2, "0"); // add leading zero if necessary
  if (day < 10) {
    day = "0" + day;
  }
  updateState({ ...iState, validUpto: `${day}-${month}-${year}` });
};

const handleValidation = () => {
  let error = {};
  let formIsValid = true;
  if(!paymentTime){
    error.paymentTimeError = "Please select the billing cycle";
   formIsValid = false
  }
   updateState({...iState, errors:error})
   return formIsValid
}


const ContinueFun = (e)=> {
  e.preventDefault();
  let formIsValid = handleValidation()
  if(formIsValid){
    let paramsId ;
    let paramsPrice ;
    if(paymentTime === "monthly"){
      paramsId = planDetailsData?.[0]?.id ;
      paramsPrice = planDetailsData?.[0]?.price
    } else {
      paramsId = planDetailsData?.[1]?.id;
      paramsPrice = planDetailsData?.[1]?.price
    }
  navigate(`/checkout-screen/${paramsId}/${paramsPrice}`)
  }
}

  return (
   <>
  <div className="SubsCriptionBackground">
    <div className="SubscriptionTitleBox">
      <h4 className="Title">Subscription starts</h4>
      <div>
        <img src="images/Logo.png" />
      </div>
    </div>
    <div className="PlanScreenArea">
      <div className="row">
        <div className="col-md-6">
          <div className="YourProductArea">
            <div className="YourProduct">
              <h4>Your Product</h4>
              <ul className="breadcumbs">
                <li className="breadcumb-link active">Your Product &gt; </li>
                <li className="breadcumb-link"> Checkout</li>
              </ul>
            </div>
            <div className="YourProductBox">
              <div className="YourProductBoxTop">
                <h6>Multi-Family and Residential Platform</h6>
                <a href="javascript:void(0);">
                  <img src="images/black-down-arrow.png" />
                </a>
              </div>
              <div className="YourProductBoxStarter">
                <div className="StarterLeft">
                  <h4>
                   {planDetailsData[0]?.planName}
                    <img src="images/circle_check.png" />
                  </h4>
                  <p data-toggle="modal" data-target="#">
                    Plan Details
                  </p>
                </div>
                <div className="StarterRight">
                  <img src="images/Logo.png" />
                </div>
              </div>
              <div className="SelectBillingCycleArea">
                <h6>Select a billing cycle</h6>
                <div className="facilitiesBox">
                  <div className="AmmentitiesBox">
                    <ul>
                      <li>
                        <input type="radio" name="paymentTime"
                                defaultValue="monthly"
                                onChange={handleRadio} />
                        <aside>
                          <label>Monthly</label>
                          {currencyType === "usd" ? <>
                          <label>$  {planDetailsData[0]?.price}</label>
                    </> : <>
                    <label>$  {(planDetailsData[0]?.price * 0.80).toFixed(2)}</label>
                    </>}
                        
                        </aside>
                      </li>
                      <li>
                        <input type="radio" name="paymentTime"
                                defaultValue="yearly"
                                onChange={handleRadio} />
                        <aside>
                          <label>Yearly</label>
                          {currencyType === "usd" ? <>
                          <label>$  {planDetailsData[1]?.price}</label>
                    </> : <>
                    <label>$  {(planDetailsData[1]?.price * 0.80).toFixed(2)}</label>
                    </>}
                        </aside>
                      </li> 
                    </ul>
                  
                  </div>
                  <span style={{color:"red", fontSize:"16px", textAlign:"center", marginLeft:"80px"}}> {errors?.paymentTimeError}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="OrderSummaryArea">
            <div className="OrderSummaryBox">
              <div className="BillingAnnually border-0 pt-0 pb-4">
                <div className="BillingAnnuallyLeft">
                  <h3>Order Summary</h3>
                </div>
                <div class="CurrencyOverlay">
                    <img src="images/usd.png"/>
                    <div class="CurrencyOverlaySelect">
                    <select name='currencyType' value={currencyType} onChange={(e) => {
                    updateState({...iState, currencyType:e.target.value})
                  }}>
                      <option value="usd">US Dollars </option>
                    <option value="csd">Canadian Dollars</option>
                  
                  </select>
                      <span><img src="images/black-down-arrow.png"/></span>
                    </div>
                  </div>
                
              </div>
              <div className="BillingAnnually">
                <div className="BillingAnnuallyLeft">
                  <h3> {planDetailsData?.planName}</h3>
                  <h6>Monthly Subscription</h6>
                 
                </div>
                <div className="BillingAnnuallyRight">
                  
                  {currencyType === "usd" ? <>
                          <h6>$  {planDetailsData[0]?.price}</h6>
                    </> : <>
                    <h6>$  {(planDetailsData[0]?.price * 0.80).toFixed(2)}</h6>
                    </>}
                 
                </div>
                 {/* <div className="BillingAnnuallyRight">
                  <h6>${planDetailsData[1]?.price}</h6>
                </div> */}
              </div>
              <div className="BillingAnnually">
                <div className="BillingAnnuallyLeft">
                  <h3>Total Billed Annually</h3>
                  <p>Next charge date { validUpto }</p>
                  <p>
                    Based on the billing information you have provided, your
                    purchase may be subject to local taxes. The final charge may
                    be different than the amount shown here and will be
                    displayed on your invoice
                  </p>
                </div>
                <div className="BillingAnnuallyRight">
                {currencyType === "usd" ? <>
                          <h6>$  {planDetailsData[1]?.price}</h6>
                    </> : <>
                    <h6>$  {(planDetailsData[1]?.price * 0.80).toFixed(2)}</h6>
                    </>}
                </div>
              </div>
              <div className="BillingAnnually">
                <div className="BillingAnnuallyLeft">
                  <h3> Total </h3>
                </div>
                <div className="BillingAnnuallyRight">
                {currencyType === "usd" ? <>
                          <h6>$  {finalPrice ? finalPrice : 0}</h6>
                    </> : <>
                    <h6>$  {finalPrice ? (finalPrice * 0.80).toFixed(2) : 0}</h6>
                    </>}
                </div>
              </div>
              <div className="mt-4">
                <button onClick={ContinueFun} to={`/checkout-screen/${id}/${price}`} className="PlanContinueBtn">Continue</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 
</>

  )
}

export default PlanScreen