import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {toast} from 'react-toastify'

import { useDispatch, useSelector } from "react-redux";
import { getAccessToMyItenaryUserAccessAction, giveItinarayApproveAccess, giveItinarayRequestAccess } from "../../../redux/action/IbuildAdminItineraryAction";
import { AdminUserDropdownAction } from "../../../redux/action/dropdownAction";

const ItineraryRequestAccessModal = ({
  SetItineraryRequestAccessStatus,
  ItineraryRequestAccessStatus,
  SetItineraySettingState,
}) => {
  const { giveMyItineraryUser } = useSelector(
    (state) => state.itineraryReducer
  );
  const dispatch = useDispatch();

  const handleItineraySettingModalClose = () => {
    SetItineraySettingState(true);
    SetItineraryRequestAccessStatus(false);
  };
  const handleItineraySettingModalShow = () => {
    SetItineraryRequestAccessStatus(true);
  };

  useEffect(() => {
    dispatch(getAccessToMyItenaryUserAccessAction());
  }, []);

  const { adminUserList } = useSelector((state) => state.dropdownReducer);

  const [requesters, setRequesters] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [userListShow, SetUserListShow] = useState([]);

  useEffect(() => {
    dispatch(AdminUserDropdownAction());
  }, []);

  const handleAddUser = (e) => {
    e.preventDefault();

    let temp = adminUserList?.users?.filter((user) => user.id == selectedUser);
    let users = userListShow;
    users.push(temp?.[0]);
    SetUserListShow(users);

    setRequesters([...requesters, { UserId: parseInt(selectedUser) }]);
  };

  const handleRemoveUser = (e, id) => {
    e.preventDefault();

    let index = userListShow?.findIndex((item) => (item.id = id));
    let tempRequester = requesters;
    let tempUserShow = userListShow;

    tempRequester.splice(index, 1);

    tempUserShow.splice(index, 1);

    setRequesters(tempUserShow);
    setSelectedUser(tempRequester);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formIsValid = true;

    if (formIsValid) {
      dispatch(giveItinarayApproveAccess({ requesters }))
        .then((res) => {
          if (res.status === 200) {
            toast.success("Users Assigned Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });

            setRequesters([]);
            SetUserListShow([]);
            setSelectedUser("");
            SetItineraySettingState(true);
            SetItineraryRequestAccessStatus(false)
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  return (
    <Modal show={ItineraryRequestAccessStatus} className="ModalBox SmallModal">
      <div class="modal-content BgCreate">
        <a
          onClick={handleItineraySettingModalClose}
          class="CloseModal"
          data-toggle="modal"
        >
          ×
        </a>

        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Request Access to Users Itinerary Lists</h4>
          </div>
          <div class="FolderForm">
            <form>
              <div class="form-group">
                <h6>Users</h6>
                <div class="Categories Categories1">
                  <div class="Categories_all Categories_all1">
                    <span>Select one of more users</span>
                    <img src="images/down.png" />
                  </div>
                  <ul>
                    <li>
                      <input type="text" placeholder="-Search" />
                      <span class="Search">
                        <img src="images/search.png" />
                      </span>
                    </li>
                    <li>
                      <label class="CheckBox">
                        {" "}
                        List of users that still do not have access to user's
                        tasks
                        <input type="checkbox" />
                        <span class="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                  <select
                    onChange={(e) => setSelectedUser(e.target.value)}
                    className="form-control"
                  >
                    <option value="">Select users</option>
                    {adminUserList?.users?.map((ele, i) => {
                      return (
                        <>
                          <option value={ele?.id}> {ele?.name}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div class="Button my-4">
                <button onClick={handleAddUser} class="Create">
                  Add
                </button>
              </div>
              {requesters?.length > 0 && (
                <div class="form-group">
                  <h6>Select Users</h6>
                  {userListShow?.map((user) => (
                    <div class="FilesAdded">
                      <label>{user?.name}</label>
                      <span
                        class="cross"
                        onClick={(e) => handleRemoveUser(e, user?.id)}
                      >
                        <img src="images/Cross.png" />
                      </span>
                    </div>
                  ))}
                </div>
              )}

              <div class="Button d-flex justify-content-between mt-5">
                <button class="Create" onClick={handleSubmit}>
                  Request Access
                </button>
                <button
                  class="Outline"
                  onClick={handleItineraySettingModalClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ItineraryRequestAccessModal;
