import React from 'react'
import Modal from "react-bootstrap/Modal";

const EmailConfigure = ({EmailConfigureModal, SetEmailConfigureModal, element}) => {

    const handleEmailConfigModalClose = () => {
        SetEmailConfigureModal(false);
    
    
      };
    

  return (

    <Modal show={EmailConfigureModal} className="ModalBox LargeModal BgClass">
    <a
      onClick={handleEmailConfigModalClose}
      class="CloseModal ClosePermissionId"
    >
      ×
    </a>
    <form>
                  <div class="form-group">
                    <h6>Event</h6>
                    <h6 class="fw-400 fw-14">{element?.eventName}</h6>
                    </div>
                  <div class="form-group">
                    <h6>Notification Type</h6>
                    <h6 class="fw-400 fw-14">Email</h6>
                  </div>
                  <div class="row">
                    <div class="col-md-5">
                      
                       <div class="ModalBigheading">
                        <h3><span>Notification Details</span></h3>
                      </div>
    
                      <div class="form-group mb-2">
                        <label class="CheckBox"> <strong>Add External Recipient(s)</strong>
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="ml-5">
                        <div class="form-group mb-2">
                          <label class="CheckBox text-grey-80"> Include Client
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                         </div>
                         <div class="form-group mb-2">
                          <label class="CheckBox text-grey-80"> Include Vendor
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                         </div>
                      </div>
                      <div class="form-group">
                        <input type="text" class="form-control" placeholder="Insert Notification text" />
                      </div>
                      <div class="">
                        <h6 class="fw-20 fw-600">Subject <span class="required">*</span></h6>
                      </div>
                      <div class="form-group">
                        <h6>Insert keys <span class="ml-3"><img src="images/insert_key_24.png" /></span> </h6>
                        <div class="CommonSelectBox w-100">
                         <select>
                           <option selected="">Select document key</option>
                         </select>
                       </div>
                       <div class="Button my-3">
                        <button class="Create px-3">Add Key</button>
                       </div>
                       <input type="text" class="form-control" placeholder="Insert Notification text" />
                       </div>
                       <div class="">
                        <h6 class="fw-20 fw-600">Body <span class="required">*</span></h6>
                      </div>
                      <div class="form-group">
                        <h6>Insert keys <span class="ml-3"><img src="images/insert_key_24.png" /></span> </h6>
                        <div class="CommonSelectBox w-100">
                         <select>
                           <option selected="">Select document key</option>
                         </select>
                       </div>
                       <div class="Button my-3">
                        <button class="Create px-3">Add Key</button>
                       </div>
                       </div>
                    </div>
                  </div>


    <textarea id="editornew2" class="form-control" rows="5" ></textarea>

                  <div class="Button d-flex justify-content-between mt-4">
                    <button class="Create">Save</button>
                    <button class="Outline">Cancel</button>
                  </div>
                   
                </form>
  </Modal>  )
}

export default EmailConfigure