import React, { useState } from "react";
import "../App.css";
import sidenav1 from "../assets/images/sidenav-1.png";
import sidenav2 from "../assets/images/sidenav-2.png";
import sidenav3 from "../assets/images/sidenav-3.png";
import sidenav4 from "../assets/images/sidenav-4.png";
import sidenav5 from "../assets/images/sidenav-5.png";
import sidenav6 from "../assets/images/sidenav-6.png";
import sidenav7 from "../assets/images/sidenav-7.png";
import sidenav8 from "../assets/images/sidenav-8.png";
import sidenav9 from "../assets/images/sidenav-9.png";
import sidenav10 from "../assets/images/sidenav-10.png";
import sidenav11 from "../assets/images/sidenav-11.png";
import sidenav12 from "../assets/images/sidenav-11.png";
import { Link, useLocation } from "react-router-dom";

const ClientViewSidenav = () => {
  const clientViewData = JSON.parse(
    window.localStorage.getItem("clientViewLogin")
  );

  const location = useLocation();
  const isActive = (path) => {
    return (
      location.pathname === path ||
      (location.pathname !== "/" && location.pathname.startsWith(path + "/"))
    );
  };

  return (
    <div>
      {clientViewData?.user?.role === "Client View" && (
        <div className="SidenavBarClient">
          <ul>
            <li>
              <a className="TextRed">Client View</a>
            </li>
            {/* <li>
              <a className="Black">Menu</a>
            </li> */}
            <li className={isActive("/client-view-home") ? "active" : ""}>
              <Link to="/client-view-home" className="Home">
                <span>
                  {" "}
                  <img src="images/home_grey_nav.png" />
                </span>
                Home
              </Link>
            </li>
            <li className={isActive("/design-selections-client-view") ? "active" : ""}>
              <Link to="/design-selections-client-view">
                Design Selections <span className="Number">3</span>
              </Link>
            </li>
            <li className={isActive("/sales-document") ? "active" : ""}>
              <Link to="/sales-document">
                Sales Documents <span className="Number">3</span>
              </Link>
            </li>
            <li
              className={isActive("/appointments-client-view") ? "active" : ""}
            >
              <Link to="/appointments-client-view">
                Appointments <span className="Number">3</span>
              </Link>
            </li>
            <li  className={isActive("/change-order-list-client") ? "active" : ""}>
              <Link to="/change-order-list-client">
                Change Orders <span className="Number">3</span>
              </Link>
            </li>
            <li
              className={
                isActive("/house-warranty") ||
                isActive("/warranty-request-client")||
                isActive("/warranty-request-client-details")
                  ? "active"
                  : ""
              }
            >
              <Link to="/house-warranty">
                House Warranty <span className="Number">3</span>
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default ClientViewSidenav;
