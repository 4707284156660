import React, { useEffect } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import { getAllNotificationAction } from "../redux/action/notificationActionDk";
import { toast } from "react-toastify";
import Loader from "./Loader";
import NoDataFound from "./Nodatafound";
import { checkArray } from "../utils/CheckType";

const Notifications = () => {
  const dispatch = useDispatch();

  const  {notificationList, loader}= useSelector((state)=> state.notificationReducerDk)

  useEffect(() => {
    dispatch(getAllNotificationAction())
      .then((res) => {})
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, []);

  return (
    <>
      <Header />
      <SideNav />

      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="TitleBox align-items-start">
            <h4 class="Title">Notifications</h4>
          </div>

          <div class="FilesArea">
            <div class="FilesHead justify-content-end">
              <div class="NewActions">
                <a href="javascript:void(0);">
                  <img src="images/icon-53.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/download.png" />
                </a>
                {/* <a href="javascript:void(0);">
                  <img src="images/setting.png" />
                </a> */}
              </div>
            </div>
          </div>
          <div class="TableList TableListHeader NewTableList TableHeight500">
            <table>
              <thead>
                <tr>
                  <th>Job Number</th>
                  <th>Notification</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {loader ? (
                      <>
                        <td>
                          {" "}
                          <Loader />
                        </td>
                      </>
                    ) : (
                      <>
                        {checkArray(notificationList) ? (
                          notificationList?.map((ele, ind) => {
                            return (
                              <>
                                <tr>
                <td>CHI-2B-387</td>
                <td>A new Discussion Message for Warranty Request #281 <br />
                  has been posted</td>
                <td>08/17/2023 - 10:14 AM</td>
                <td>New</td>
                <td>
                  <div class="TableThreeDots text-center NotificationMenu">
                    <ul class="">
                      <li class="dropdown">
                        <a class="" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <img src="images/dots.png" />
                          </a>
                        <ol class="dropdown-menu dropdown-menuwidth">
                          <li><a href="javascript:void(0);"><img src="images/goto_item.png" />Go to Item</a></li>
                          <li><a href="javascript:void(0);"><img src="images/mark_as_read.png" />Mark as Read</a></li>
                          <li class="d-flex justify-content-between align-items-center justify-content-between"><a href="javascript:void(0);"><img src="images/generate.png" />Generate &nbsp; &nbsp; &nbsp; &nbsp; <img src="images/rightarrow2.png" /></a>
                          <ul class="dropdown-menu submenu">
                            <li><a href="javascript:void(0);"><img src="images/system_task_new.png" />System Task</a></li>
                            <li><a href="javascript:void(0);"><img src="images/todo_new.png" />ToDo</a></li>
                            <li><a href="javascript:void(0);"><img src="images/itinerary_new.png"/>Itinerary</a></li>  
                          </ul>
                          </li>
                          <li><a href="javascript:void(0);"><img src="images/dropdown-delete.png" />Delete Notification</a></li>
                        </ol>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr> 
                              </>
                            );
                          })
                        ) : (
                          <td>
                            {" "}
                            <NoDataFound />
                          </td>
                        )}                      </>
                        )}
    

              </tbody>
            </table>
          </div>
          {/* <div class="PaginationArea">
            <h6>Showing 1 to 4 of 27 entries</h6>
            <h6>
              <img src="images/leftarrow.png" /> <span>01</span>{" "}
              <img src="images/rightarrow.png" /> Out 10
            </h6>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Notifications;
