import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getProformaConfirmationSumarry } from '../../redux/action/proformaAction'

const Conformation = ({
    moment,
}) => {
  const dispatch=useDispatch([])
  const[proformaConfirmSummaryList,setProformaConfirmSummaryList]=useState([])
  useEffect(()=>{
    dispatch(getProformaConfirmationSumarry()).then(res=>{
      console.log({res});
      setProformaConfirmSummaryList(res?.data)
    })
  },[])
  return (
    <div className="FolderFormCreateArea Proforma">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <h6>Name</h6>
                      <p>
                        {
                          proformaConfirmSummaryList?.data?.table1?.proforma
                            ?.name
                        }
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <h6>Type</h6>
                      <p>
                        {
                          proformaConfirmSummaryList?.data?.table1?.proforma
                            ?.proformaTypeId
                        }
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <h6>Home Type</h6>
                      <p>
                        {
                          proformaConfirmSummaryList?.data?.table1?.proforma
                            ?.homeTypeId
                        }
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <h6>City</h6>
                      <p>
                        {
                          proformaConfirmSummaryList?.data?.table1?.proforma
                            ?.cityCode
                        }
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <h6>State/Province</h6>
                      <p>--</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <h6>Job Site GMT (UTC) Timezone</h6>
                      <p>
                        {moment(
                          proformaConfirmSummaryList?.data?.table1?.proforma
                            ?.jobTimeZone
                        ).format("YYYY-MM-DD & HH:MM A")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="BlockCostsPreview">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4>Proforma Summary Costs</h4>
                    <div className="NewActions">
                      <a href="javascript:void(0);" title="pdf">
                        <img src="images/pdf.png" />
                      </a>
                      <a href="javascript:void(0);" title="download">
                        <img src="images/download.png" />
                      </a>
                    </div>
                  </div>
                  {proformaConfirmSummaryList?.data?.table2?.blockQuantities
                    ?.length > 0 &&
                    proformaConfirmSummaryList?.data?.table2?.blockQuantities?.map(
                      (item, ind) => {
                        return (
                          <div className="BlockInputsCollapse">
                            <div
                              className="BcpHeader collapsed"
                              data-toggle="collapse"
                              href="#collapseExample"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              <h5>{item.name}</h5>
                            </div>
                            <div
                              className="collapse"
                              id="collapseExample"
                              style={{}}
                            >
                              <div className="card card-body border-0">
                                <div className="BlockInputs">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Major Costs Area</th>
                                        <th>
                                          Design, Development, Admin Soft Costs
                                        </th>
                                        <th>Construction Hard Costs</th>
                                        <th>Project Total Forecast Costs</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item?.ProformaBaseCosts?.length > 0 &&
                                        item?.ProformaBaseCosts?.map(
                                          (data, i) => {
                                            return (
                                              <tr key={i}>
                                                <td>{data.CostCode?.number}</td>
                                                <td>{data.CostCode?.name}</td>
                                                <td>
                                                  {
                                                    data
                                                      .ProformaBlockQuantities?.[0]
                                                      ?.MultiBlock
                                                      ?.blockDescription
                                                  }
                                                </td>

                                                <td>
                                                  $
                                                  {Number(
                                                    data
                                                      .ProformaBlockQuantities?.[0]
                                                      ?.totalCost
                                                  ).toFixed(2) ?? 0}
                                                </td>
                                                <td>{data.totalCostSum}</td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td>
                                          Total Project Land, Design,
                                          Development, Admin Costs
                                        </td>
                                        <td>$ 67,480.00</td>
                                        <td>$ -</td>
                                        <td>$ 275,590.00</td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}

                  <div className="BlockInputs Red">
                    <table>
                      <tfoot>
                        <tr>
                          <td>Grand Total Project Costs</td>
                          <td>$ 32,275,590.00</td>
                          <td>$ 2,275,590.00</td>
                          <td>$ 32,275,590.00</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
  )
}

export default Conformation