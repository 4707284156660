import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCommonBuildingStep3 } from "../../../redux/action/proformaAction";

const CommonbuildingSummaryPreviwe = ({
  handleShowComponent,
  handleShowComponents,
}) => {
  const dispatch = useDispatch([]);
  const [commonBuildingStep3List, setCommonBuildingStep3List] = useState([]);
  useEffect(() => {
    dispatch(getCommonBuildingStep3()).then((res) => {
      console.log({ res });
      setCommonBuildingStep3List(res?.data);
    });
  }, []);
  return (
    <>
      <div className="ModalBigheading d-flex justify-content-between">
        <h3>
          <span>Block Quantities</span>
        </h3>
        <h6>
          <img src={require("../../../assets/images/book-search.png")} />
          Detailed Costs
        </h6>
      </div>
      <div className="BlockInputs" style={{ width: "50%" }}>
        <table>
          <thead>
            <tr>
              <th>Input</th>
              <th>Site Office</th>
              <th>Rec Center</th>
              <th>Totals</th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
                <td>Foundation Type</td>
                <td>
                  <strong>Basement Foundation</strong>
                </td>
                <td>Slab on Grade Foundation</td>
                <td>1</td>
              </tr> */}
            {commonBuildingStep3List?.data?.table1?.proforma?.MultiBuildings
              ?.length > 0 &&
              commonBuildingStep3List?.data?.table1?.proforma?.MultiBuildings?.map(
                (item, ind) => {
                  return (
                    <tr key={ind}>
                      <td>{item.jobNumber}</td>
                      <td>--</td>
                      <td>--</td>
                      <td>--</td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </table>
      </div>
      <div className="BlockCostsPreview">
        <div className="d-flex align-items-center justify-content-between">
          <h4>Common Areas Costs</h4>
          <div className="NewActions">
            <a href="javascript:void(0);" title="pdf">
              <img src="images/pdf.png" />
            </a>
            <a href="javascript:void(0);" title="download">
              <img src="images/download.png" />
            </a>
          </div>
        </div>
        {commonBuildingStep3List?.data?.table2?.blockQuantities?.length > 0 &&
          commonBuildingStep3List?.data?.table2?.blockQuantities?.map(
            (data, i) => {
              return (
                <div className="BlockInputsCollapse">
                  <div
                    className="BcpHeader"
                    data-toggle="collapse"
                    href="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <h5>{data.name}</h5>
                  </div>
                  <div className="collapse show" id="collapseExample">
                    <div className="card card-body border-0">
                      <div className="BlockInputs">
                        <table>
                          <thead>
                            <tr>
                              <th>Code Code #</th>
                              <th>Description</th>
                              <th>Site office</th>
                              <th>Rec Center</th>
                              <th>Total Construction Costs</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>4.0102</td>
                              <td>Windows. and Exterlor Doors</td>
                              <td>$ -</td>
                              <td>$ -</td>
                              <td>$ -</td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td />
                              <td>Total Interior Finishing Costs</td>
                              <td>67,480.00</td>
                              <td>67,480.00</td>
                              <td>2,75,480.00</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
      </div>
      <div className="Button d-flex  justify-content-between align-items-center px-5">
        <button
          className="Outline border text-success border-success"
          onClick={() => handleShowComponents("commonBuildingQuanties")}
        >
          Cancel
        </button>
        <button
          className="Create bg-success border border-success"
          onClick={() => handleShowComponent("commonBuildingFoundation")}
        >
          Save Draft
        </button>
      </div>
    </>
  );
};

export default CommonbuildingSummaryPreviwe;
