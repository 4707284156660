import React, { useState } from 'react'
import BlockFoundationInput from './BlockFoundationInput'
import BlockFoundationQuantities from './BlockFoundationQuantities'
import BlockFoundationPreview from './BlockFoundationPreview'

const BlockFoundation = ({blockFoundationStep1List,
  handleInputChange,
  foundationValues,
  updateId,
  blockQuantitiesData,
  unitList,
  handleBlockQuantityChange,
  handleProformaBlockFoundationStep2,
  handleShowComponent
}) => {

  const[show,setShow]=useState({
    blockFoundationInput:true,
    blockFoundationQuanties:false,
    blockFoundationSummeryPreview:false
  })
  
  const handleShow=(component)=>{
  setShow({
    blockFoundationInput:component==="blockFoundationInput",
    blockFoundationQuanties:component==="blockFoundationQuanties",
    blockFoundationSummeryPreview:component==="blockFoundationSummeryPreview"
  })
  }
  return (
    <div className="FolderFormCreateArea Proforma">
    <div className="StepBox Green">
      <ul>
      <li className={show.blockFoundationInput?"active":""} onClick={()=>handleShow("blockFoundationInput")}>
         <span className="Icon">
           <img src={require("../../../assets/images/block_input.png")} />
          </span>
          <span className="Text">
            Block <br /> Foundation <br /> Input
          </span>
        </li>
        <li className={show.blockFoundationQuanties?"active":""} onClick={()=>handleShow("blockFoundationQuanties")}>
         <span className="Icon">
           <img src={require("../../../assets/images/block_quantities.png")} />
          </span>
          <span className="Text">
            Block <br /> Foundation <br /> Quantities
          </span>
        </li>
        <li className={show.blockFoundationSummeryPreview?"active":""} onClick={()=>handleShow("blockFoundationSummeryPreview")}>
      
          <span className="Icon">
           <img src={require("../../../assets/images/block_summary.png")} />
          </span>
          <span className="Text">
            Blocks <br />
            Foundation <br />
            Summary <br />
            Preview
          </span>
        </li>
      </ul>
    </div>
    <div className="FieldsMandatory">
      <h6>
        Fields with <span className="required">*</span> are
        mandatory
      </h6>
    </div>
    <div className="ModalBigheading pl-4 mb-3">
      <h3 className="mb-0">
        <span>Blocks Foundation Inputs</span>
      </h3>
    </div>

    {
      show.blockFoundationInput && 
   <BlockFoundationInput 
    blockFoundationStep1List={blockFoundationStep1List}
    foundationValues={foundationValues}
    handleInputChange={handleInputChange}
    handleShowComponent={handleShowComponent}
    handleShow={handleShow}
   />
    }
{
  show.blockFoundationQuanties && 
   <BlockFoundationQuantities
   blockQuantitiesData={blockQuantitiesData}
   unitList={unitList}
   handleBlockQuantityChange={handleBlockQuantityChange}
   updateId={updateId}
   handleProformaBlockFoundationStep2={handleProformaBlockFoundationStep2}
   handleShow={handleShow}        
   />
}
{
  show.blockFoundationSummeryPreview && 
   <BlockFoundationPreview
   handleShowComponent={handleShowComponent} 
   handleShow={handleShow}  
   />
}
  </div>
  )
}

export default BlockFoundation