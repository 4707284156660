import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";



const ProjectAsEntityStep6 = ({
  iState,
  UpdateState,
    ProjectAsEntityStep6Modal,
    SetProjectAsEntityStep1Modal,
    SetProjectAsEntityStep2Modal,
    SetProjectAsEntityStep3Modal,
    SetProjectAsEntityStep4Modal,
    SetProjectAsEntityStep5Modal,
    SetProjectAsEntityStep6Modal,
    SetProjectAsEntityStep7Modal,
    SetProjectAsEntityStep8Modal,
    setShow,
  
}) => {
  const handleProjectAsEntityStep6ModalClose = () => {
    setShow(true);
    SetProjectAsEntityStep6Modal(false);
  };
  const handleProjectAsEntityStep1ModalShow = () => {
    SetProjectAsEntityStep6Modal(true);
  };




  const {jobPattern ,useCompanyPattern,errorMsg} = iState


  const handleChange = (e) => {
    const { name, value } = e.target;

    UpdateState({
      ...iState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = true;

    if (formIsValid) {
      SetProjectAsEntityStep6Modal(false);
      SetProjectAsEntityStep7Modal(true);
    }
  };

 


  const handleCheck =(e)=>{

    UpdateState({...iState, useCompanyPattern:e.target.checked})
   
     }

     const handleKeySelection=(e,value)=>{
      e.preventDefault()
      if(!jobPattern){
  
      UpdateState({...iState, jobPattern:value})
      }
      else{
  
        let prev = jobPattern + `-${value}`
        UpdateState({...iState, jobPattern:prev})
  
      }}  

  return (
    <>
      <Modal show={ProjectAsEntityStep6Modal} className="ModalBox LargeModal BgClass">
        <div>
          <a
            onClick={handleProjectAsEntityStep6ModalClose}
            class="CloseModal"
            data-toggle="modal"
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Create New Project as an Entity</h4>
            </div>
            <div class="StepBox">
              <ul>
                  <li class="active">
                      <span class="Icon"  onClick={()=> {SetProjectAsEntityStep1Modal(true) 
                      SetProjectAsEntityStep6Modal(false)}}>
                          <img src={require("../../assets/images/step_1.png")} />
                      </span>
                      <span class="Text">Details</span>
                  </li>
                  <li className="active">
                    <span onClick={()=> {SetProjectAsEntityStep2Modal(true) 
                      SetProjectAsEntityStep6Modal(false)}}  class="Icon FolderPermissionId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityAddress" data-dismiss="modal">
                        <img src={require("../../assets/images/step_2.png")} />
                    </span>
                    <span class="Text">Address</span>
                </li>
                  <li className="active">
                    <span onClick={()=> {SetProjectAsEntityStep3Modal(true) 
                      SetProjectAsEntityStep6Modal(false)}} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityType" data-dismiss="modal">
                        <img src={require("../../assets/images/step_8.png")} />
                    </span>
                    <span class="Text">Type</span>
                </li>
                  <li className="active">
                      <span onClick={()=> {SetProjectAsEntityStep4Modal(true) 
                      SetProjectAsEntityStep6Modal(false)}} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntitySubscription" data-dismiss="modal">
                          <img  src={require("../../assets/images/subscription_step.png")}/>
                      </span>
                      <span class="Text">Subscription</span>
                  </li>
                  <li className="active">
                      <span onClick={()=> {SetProjectAsEntityStep5Modal(true) 
                      SetProjectAsEntityStep6Modal(false)}} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityTax" data-dismiss="modal">
                          <img src={require("../../assets/images/step_3.png")} />
                      </span>
                      <span class="Text">Accounting <br /> &amp; Tax</span>
                  </li> 
                  <li className="active">
                    <span  class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityNumber" data-dismiss="modal">
                        <img src={require("../../assets/images/step_5.png")} />
                    </span>
                    <span class="Text">Job Number</span>
                </li> 
                <li>
                  <span onClick={()=> {SetProjectAsEntityStep7Modal(true) 
                      SetProjectAsEntityStep6Modal(false)}} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityInitial" data-dismiss="modal">
                      <img  src={require("../../assets/images/initial_settings.png")} />
                  </span>
                  <span class="Text">Initial <br />
                    Settings</span>
              </li> 
              <li>
                <span onClick={()=> {SetProjectAsEntityStep8Modal(true) 
                      SetProjectAsEntityStep6Modal(false)}} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityConfirmation" data-dismiss="modal">
                    <img src={require("../../assets/images/step_7.png")} />
                </span>
                <span class="Text">Confirmation</span>
            </li> 
              </ul>
            </div>
            <div class="FolderForm">
            <form>
                <div class="FolderFormCreateArea">
                  <div class="FieldsMandatory">
                    <h6>Fields with <span class="required">*</span> are mandatory</h6>
                  </div>
                  <div class="row">

                    <div class="col-md-6">
                      <div class="form-group">
                        <h6>Job Pattern Definition</h6>
                        <label class="switch"> Use Company's Pattern
                        <input onChange={handleCheck} type="checkbox" name="useCompanyPattern" checked={useCompanyPattern} />
                          <span class="slider"></span>
                        </label>
                      </div>
                      <div class="form-group">
                        <h6>Job Number Pattern</h6>
                        <input type="text" class="form-control" value={jobPattern} onChange={handleChange} name="jobPattern" placeholder="[UNUM]-[SD3]-[CID]-[JN]" />
                          {errorMsg.jobPattern && !jobPattern && (
                        <p style={{ color: "red" }}>{errorMsg.jobPattern}</p>
                      )}                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <h6>Sample Job Number</h6>
                        <input type="text" class="form-control InputCyanBg" placeholder="MAH-2B-377" />
                      </div>
                    </div>
                  </div>
                  <div class="RestrictionArea">
                    <h6>Restrictions</h6>
                    <ul>
                      <li>-Max length for generated job number is 40 characters.</li>
                      <li>-[CID] and [JN] must both occur at least once.</li>
                      <li>-Only alpha numeric characters and single dashes are allowed.</li>
                      <li>-It cannot end/start in a dash.</li>
                    </ul>
                  </div>
                  <div class="KeySelectionArea">
                      <h6>Key Selection</h6>
                      <div class="KeySelectionBox">
                        <aside>
                          <p><button onClick={(e)=> handleKeySelection(e,'[ACD]')} class="BlueBtn">[ACD]</button><span>Agreement or Creation Date</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[ACDM2]')} class="BlueBtn">[ACDM2]</button><span>Agreement or Creation <br />Date: Two Digit Month</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[SD3]')} class="BlueBtn">[SD3]</button><span>Three Characteres of Subdivision</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[FY2]')} class="BlueBtn">[FY2]</button><span>Two Digit Fiscal Year</span></p>
                        </aside>
                        <aside>
                          <p><button onClick={(e)=> handleKeySelection(e,'[ACDY2]')} class="BlueBtn">[ACDY2]</button><span>Agreement or Creation <br />
                            Date: Two Digit Year</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[CID]')} class="GreenBtn">[CID]</button><span>Company ID</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[SD4]')} class="BlueBtn">[SD4]</button><span>Four Characteres of 
                            Subdivision</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[FY4]')} class="BlueBtn">[FY4]</button><span>Four Digit Fiscal Year</span></p>
                        </aside>
                        <aside>
                          <p><button onClick={(e)=> handleKeySelection(e,'[ACDY4]')} class="BlueBtn">[ACDY4]</button><span>Agreement or Creation <br />
                            Date: Four Digit Year</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[JN]')} class="GreenBtn">[JN]</button><span>Job Number</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[UNUM]')} class="BlueBtn">[UNUM]</button><span>Three Characteres of Subdivision</span></p>
                        </aside>
                      </div>
                    </div>
                </div>
             
                <div class="Button d-flex justify-content-between align-items-center px-5">
                  <button class="Outline" data-dismiss="modal"onClick={()=> {
                    SetProjectAsEntityStep6Modal(false)
                    SetProjectAsEntityStep5Modal(true)
                  }} >Back</button>
                  <button class="Create FolderPermissionId" data-toggle="modal" 
                    onClick={handleSubmit}
                  >Next</button>
                </div>  
                </form>
              
            </div>

          </div>
        </div>
      </Modal>

   
      
    </>
  );
};

export default ProjectAsEntityStep6;
