import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../App.css";
import { toast } from "react-toastify";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  addDiscussionReplys,
  addDiscussions,
  getDiscussionList,
  getFPOById,
  getSCById,
} from "../redux/action/tradeViewAuthAction";


const initialState = {
  viewFP0Data: [],
  viewSCData: [],
  discussionList:[],
};

const TradeViewFpoSc = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const { viewFP0Data, viewSCData,discussionList } = iState;
  const location = useLocation();
  const urlParams = new URLSearchParams(
    location?.search?.split("?")[1]?.split("#")[0]
  );
  const companyId = urlParams?.get("companyId");
  const documentId = urlParams?.get("documentId");
  const token = urlParams?.get("token");
  const documentType = urlParams?.get("documentType");
  

  useEffect(() => {
    if (documentType === "FIELDPURCHASEORDER") {
      dispatch(getFPOById(companyId, documentId, token)).then((res) => {
        if (res.status === 200) {
          updateState({ ...iState, viewFP0Data: res?.data?.data });
        }
      });
    } else {
      dispatch(getSCById(companyId, documentId, token)).then((res) => {
        if (res.status === 200) {
          updateState({ ...iState, viewSCData: res?.data?.data });
        }
      });
    }
  }, []);
  const [jobId, setJobId] = useState(1);
  const [discussionState, updateDiscussionState] = useState({
    jobId: "",
    message: "",
    discussionError: "",
  });
  const [replyState, updateReplyState] = useState({
    messageId: "",
    jobId: "",
    message: "",
    replyError: "",
  });
  const [replyField, updateReplyField] = useState({ flag: false, index: "" });

  let handleValidationDiscussion = () => {
    let discussionError = "";
    let formIsValid = true;

    if (discussionState.message == "") {
      discussionError = "* Please enter comment";
      formIsValid = false;
    }

    updateDiscussionState({
      ...discussionState,
      discussionError: discussionError,
    });
    return formIsValid;
  };

  useEffect(() => {
    dispatch(getDiscussionList(jobId, token)).then((res) => {
      if (res.status===201) {
        updateState((prev) => ({
          ...prev,
          discussionList:res?.data?.details,
        }))
      }
    });
  }, []);

  const handleAddDiscussion = () => {
    let formIsValid = handleValidationDiscussion();
    if (formIsValid) {
      dispatch(addDiscussions({ message: discussionState.message, jobId:jobId },token))
        .then((res) => {
          if (res.status === 201) {
            dispatch(getDiscussionList(jobId, token)).then((res) => {
              if (res.status===201) {
                updateState((prev) => ({
                  ...prev,
                  discussionList:res?.data?.details,
                }))
              }
            });
            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleAddReply = () => {
    let formIsValid = true;
    if (formIsValid) {
      dispatch(
        addDiscussionReplys({
          message: replyState.message,
          jobId: replyState.jobId,
          messageId: replyState.messageId,
        },token)
      )
        .then((res) => {
          if (res.status ===201) {
            dispatch(getDiscussionList(jobId, token)).then((res) => {
              if (res.status===201) {
                updateState((prev) => ({
                  ...prev,
                  discussionList:res?.data?.details,
                }))
              }
            });
            updateReplyState({
              messageId: "",
              jobId: "",
              message: "",
              replyError: "",
            });

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const [showDiscussion, setShowDiscussion] = useState(false);
  const handleDownloadAll = () => {
    if (viewSCData?.additionalDocuments?.length > 0) {
      viewSCData.additionalDocuments.forEach((doc, index) => {
        setTimeout(() => {
          const link = document.createElement('a');
          link.href = doc.docUrl;
          link.download = doc.fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }, index * 1000); // Delay each download by 1 second
      });
    }
  }
    const totalWithoutTaxVendor =
    viewSCData?.VendorItems?.reduce(
      (total, data) => total + Number(data.quantity) * Number(data.price),
      0
    ) ?? 0;


  const taxPercentTotal =
  viewSCData?.TaxProfile?.taxRows?.[0]?.taxPercent ?? 0;
  const totalWithTaxVendor =
  totalWithoutTaxVendor * (1 + taxPercentTotal / 100);
const finalTotalVendor = totalWithTaxVendor - totalWithoutTaxVendor;

const handlePrint=()=>{
  window.print();
}
  return (
    <>
      {documentType === "FIELDPURCHASEORDER" ? (
        <div className="FormArea p-5">
          <div className="ModalTitleBox ModalTitleBoxGreen">
            <h4>View Field Purchase Order</h4>
          </div>
          <div className="FolderForm">
            <div className="BlueTextArea GreenTextArea">
              <div className="row">
                <div className="col-md-6">
                  <div className="Heading">
                    <h3>Field Quote Request # {viewFP0Data?.documentCode}</h3>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-9">
                      <div className="Categories Categories1">
                        <h6>Reason</h6>
                        <p>{viewFP0Data?.Reason?.description ?? "N/A"}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="Heading">
                    <h3> {viewFP0Data?.job?.jobNumber ?? "N/A"}</h3>
                    <div className="d-flex align-items-center">
                      <h3 className="mb-0">
                        Cost Code:{" "}
                        <span>{`${viewFP0Data?.CostCode?.number}-${viewFP0Data?.CostCode?.name}`}</span>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <h6>Date</h6>
                    <p className="ml-0">
                      {" "}
                      {moment(viewFP0Data?.createdAt).format("YYYY-MM-DD")}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5 className="GreenText">Reminder</h5>
                      <div className="CommonModalBox">
                        <h5>
                          <figure>
                            <img src="images/timer.png" />
                          </figure>{" "}
                          {viewFP0Data?.reminder !== null
                            ? moment(viewFP0Data?.reminder).format("YYYY-MM-DD")
                            : "N/A"}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <div className="CommonModalBox">
                        <h5 className="text-black">
                          <figure>
                            <img src="images/vendor.png" />
                          </figure>{" "}
                          Vendor
                          {viewFP0Data?.Vendor?.vendorName ?? "N/A"}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="ShippingAddressArea">
                    <div className="ShippingAddressAreaBox">
                      <h6>Mailing Address</h6>
                      <p>{`${viewFP0Data?.Vendor?.addressLine1 ?? ""}
                                ${viewFP0Data?.Vendor?.addressLine2 ?? ""}`}</p>
                    </div>
                    <div className="ShippingAddressAreaBox">
                      <h6>Email</h6>
                      <p>{viewFP0Data?.Vendor?.email}</p>
                    </div>
                    <div className="ShippingAddressAreaBox">
                      <h6>Phone</h6>
                      <p>{viewFP0Data?.Vendor?.phone}</p>
                    </div>
                    <div className="ShippingAddressAreaBox">
                      <h6>Shipping Address</h6>
                      <p>{`${viewFP0Data?.Vendor?.addressLine1 ?? ""}
                                ${viewFP0Data?.Vendor?.addressLine2 ?? ""}`}</p>
                    </div>
                  </div>
                </div>
                {/* <div className="col-12">
                  <div className="form-group">
                    <div className="CommonModalArea">
                      <h5 className="GreenText">Back Charge Vendor</h5>
                      <div className="CommonModalBox">
                        <h5 className="text-black">
                          <figure>
                            <img src="images/vendor.png" />
                          </figure>{" "}
                          Vendor 3
                        </h5>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-7">
                  <div className="form-group">
                    <h6>Messsage</h6>
                    <p>{viewFP0Data?.message ?? "N/A"}</p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="ItemDetails">
                    <h6>Item Details</h6>
                  </div>
                </div>
                <div className="col-12">
                  <div className="Tableheader mb-3">
                    <h6 className="mb-0">Item</h6>
                    <div className="Actions">
                      <a onClick={handlePrint}>
                        <img src="images/icon-53.png" />
                      </a>
                      <a onClick={handlePrint}>
                        <img src="images/download.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/setting.png" />
                      </a>
                    </div>
                  </div>
                  <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                    <table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>
                            <div className="text-center">
                              Material Description
                              <br />
                              (i.e: Modal/Type/Style/manuf part#/Dimensions,
                              etc.)
                            </div>
                          </th>
                          <th>Color</th>
                          <th>Quantity</th>
                          <th>Units</th>
                          <th>Price/Unit</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {viewFP0Data?.Items?.length > 0 ? (
                          viewFP0Data?.Items?.map((item, ind) => {
                            return (
                              <>
                                <tr key={ind}>
                                  <td>{item.id}</td>
                                  <td>{item.name}</td>
                                  <td>{item.color}</td>
                                  <td>{item.quantity}</td>
                                  <td>{item.unitId}</td>
                                  <td>$ {item.price}</td>
                                  <td>$ {item.total}</td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="12">
                              <p>No Data found.</p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-4">
                    <div className="Heading">
                      <h6>Attachments</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5>Scope of Work</h5>
                      <div className="CommonModalBox">
                        <h5 className="CursorPointer">
                          <figure>
                            <img src="images/scopeofwork.png" />
                          </figure>{" "}
                          <span className="Count">
                            {viewFP0Data?.workDocuments?.length ?? 0}
                          </span>
                          Scope of Work
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5>Construction Documents</h5>
                      <div className="CommonModalBox">
                        <h5 className="CursorPointer">
                          <figure>
                            <img src="images/construction_documents.png" />
                          </figure>{" "}
                          <span className="Count">
                            {viewFP0Data?.constructionDocuments?.length ?? 0}
                          </span>
                          Construction Documents
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5>External Quote</h5>
                      <div className="CommonModalBox">
                        <h5 className="CursorPointer">
                          <figure>
                            <img src="images/extermal_quote.png" />
                          </figure>{" "}
                          <span className="Count">
                            {viewFP0Data?.additionalDocuments?.length}
                          </span>
                          External Quote
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between mt-4 ">
                {/* <button className="Create">Save draft</button> */}
                {/* <button
                  className="Create mx-auto"
                  data-toggle="modal"
                  data-target="#CreateBackCharge"
                  data-dismiss="modal"
                >
                  Cancel
                </button> */}
              </div>
            </div>
          </div>
          <div className="DiscussionArea">
            <div
              className="DiscussionTitle bg_f7"
              onClick={() => setShowDiscussion(!showDiscussion)}
            >
              <div className="d-flex align-items-center">
              <h4>Discussion</h4>{" "}
              <span className="borderOranges ml-2 mb-1">{discussionList?.length??0}</span>
              </div>
              <div class="DiscussionChat">
                <img
                  src={require("../assets/images/down.png")}
                  class="DiscussionIcon"
                />
                <a>
                  <img src={require("../assets/images/pdf2.png")} />
                </a>
              </div>
            </div>
            <div
              className="DiscussionHideArea"
              style={{
                display: showDiscussion ? "block" : "none",
                color: "red",
              }}
            >
              <div class="DiscussionBox">
                <span>FM</span>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    value={discussionState?.message}
                    onChange={(e) =>
                      updateDiscussionState({
                        ...discussionState,
                        message: e.target.value,
                      })
                    }
                    placeholder="Add a Comment ....."
                  />
                  {discussionState.discussionError &&
                    !discussionState.message && (
                      <p style={{ color: "red" }}>
                        {discussionState.discussionError}
                      </p>
                    )}

                  <div class="DiscussionOverlay">
                    <a
                      onClick={() =>
                        updateDiscussionState({
                          ...discussionState,
                          message: "",
                        })
                      }
                      class="CancelChat"
                    >
                      <img src={require("../assets/images/plus-cancel1.png")} />
                    </a>
                    <a onClick={handleAddDiscussion} class="SendChat">
                      <img
                        src={require("../assets/images/forwardbutton.png")}
                      />
                    </a>
                  </div>
                </div>
              </div>
               {discussionList?.map((item, i) => (
                  <>
                    <div class="DiscussionBox">
                      <span>CO</span>
                      <div class="DiscussionRight">
                        <h6>{item?.message}</h6>
                        <small>{moment(item.updatedAt).format("lll")}</small>
                      </div>
                    </div>
                    <div class="ReplyArea">
                      <a
                        onClick={(e) => {
                          updateReplyField({ flag: true, index: i });
                          updateReplyState({ ...replyState, message: "" });
                        }}
                      >
                        Reply
                      </a>
                      {item?.ReplyDiscussions?.map((ele) => (
                        <div class="DiscussionBox">
                          <span>RE</span>
                          <div class="DiscussionRight">
                            <h6>{ele?.message}</h6>
                            <small>{moment(ele.updatedAt).format("lll")}</small>
                          </div>
                        </div>
                      ))}

                      {replyField.index == i && (
                        <div class="DiscussionBox w-100">
                          <span>FM{item?.id}</span>
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              value={replyState?.message}
                              onChange={(e) =>
                                updateReplyState({
                                  ...replyState,
                                  message: e.target.value,
                                  messageId: item?.id,
                                  jobId: item?.jobId,
                                })
                              }
                              placeholder="Add reply ....."
                            />
                            {discussionState.discussionError &&
                              !discussionState.message && (
                                <p style={{ color: "red" }}>
                                  {discussionState.discussionError}
                                </p>
                              )}

                            <div class="DiscussionOverlay">
                              <a
                                onClick={() =>
                                  updateReplyState({
                                    ...replyState,
                                    message: "",
                                  })
                                }
                                class="CancelChat"
                              >
                                <img
                                  src={require("../assets/images/plus-cancel1.png")}
                                />
                              </a>
                              <a onClick={handleAddReply} class="SendChat">
                                <img
                                  src={require("../assets/images/forwardbutton.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ))} 
            </div>
          </div>
        </div>
      ) : (
        <div className="FormArea p-5">
          <div className="ModalTitleBox ModalTitleBoxRed">
            <h4>View Supplier Credit</h4>
          </div>
          <div className="FolderForm">
            <div className="BlueTextArea RedTextArea">
              <div className="row">
                <div className="col-md-6">
                  <div className="Heading">
                    <h3>View Supplier Credit # {viewSCData?.documentCode}</h3>
                  </div>
                  {/* <a href="javascript:void(0);" class="BlueOutline GreenOutline mb-4 FolderPermissionId" data-toggle="modal"
                    data-target="#ViewSupplierCreditGreen" data-dismiss="modal"><img
                      src="images/create_filed_purchase.png">Create as Field Purchase Order
                    </a> */}
                  <div className="row">
                    <div className="form-group col-md-9">
                      <div className="Categories Categories1">
                        <h6>Reason</h6>
                        <p>{viewSCData?.Reason?.description ?? "N/A"}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="Heading">
                    <h3>{viewSCData?.job?.jobNumber ?? "N/A"}</h3>
                    <div className="d-flex align-items-center">
                      <h3 className="mb-0">
                        Cost Code:{" "}
                        <span>{`${viewSCData?.CostCode?.number}-${viewSCData?.CostCode?.name}`}</span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ModalFilterAreaBox">
                    <h4 className="mb-4">Vendor</h4>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <h6>Date</h6>
                    <p className="ml-0">
                      {" "}
                      {moment(viewSCData?.createdAt).format("YYYY-MM-DD")}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5 className="RedText">Reminder</h5>
                      <div className="CommonModalBox">
                        <h5>
                          <figure>
                            <img src="images/timer.png" />
                          </figure>{" "}
                          {viewSCData?.reminder !== null
                            ? moment(viewSCData?.reminder).format("YYYY-MM-DD")
                            : "N/A"}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <div className="CommonModalBox">
                        <h5 className="text-black">
                          <figure>
                            <img src="images/vendor.png" />
                          </figure>{" "}
                          Vendor
                          {viewSCData?.Vendor?.vendorName ?? "N/A"}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="ShippingAddressArea">
                    <div className="ShippingAddressAreaBox">
                      <h6>Mailing Address</h6>
                      <p>{`${viewSCData?.Vendor?.addressLine1 ?? ""}
                                ${viewSCData?.Vendor?.addressLine2 ?? ""}`}</p>
                    </div>
                    <div className="ShippingAddressAreaBox">
                      <h6>Email</h6>
                      <p>{viewSCData?.Vendor?.email}</p>
                    </div>
                    <div className="ShippingAddressAreaBox">
                      <h6>Phone</h6>
                      <p>{viewSCData?.Vendor?.phone}</p>
                    </div>
                    <div className="ShippingAddressAreaBox">
                      <h6>Shipping Address</h6>
                      <p>{`${viewSCData?.Vendor?.addressLine1 ?? ""}
                                ${viewSCData?.Vendor?.addressLine2 ?? ""}`}</p>
                    </div>
                  </div>
                </div>
                {/* <div className="col-12">
                  <div className="form-group">
                    <div className="CommonModalArea">
                      <h5 className="RedText">Back Charge Vendor</h5>
                      <div className="CommonModalBox">
                        <h5 className="text-black">
                          <figure>
                            <img src="images/vendor.png" />
                          </figure>{" "}
                          Vendor 3
                        </h5>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-7">
                  <div className="form-group">
                    <h6>Messsage</h6>
                    <p>{viewSCData?.message ?? "N/A"}</p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="ItemDetails">
                    <h6>Item Details</h6>
                  </div>
                </div>
                <div className="col-12">
                  <div className="Tableheader mb-3">
                    <h6 className="mb-0">Item</h6>
                    <div className="Actions">
                      <a onClick={handlePrint}>
                        <img src="images/icon-53.png" />
                      </a>
                      <a onClick={handlePrint}>
                        <img src="images/download.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/setting.png" />
                      </a>
                    </div>
                  </div>
                  <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                    <table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>
                            <div className="text-center">
                              Material Description
                              <br />
                              (i.e: Modal/Type/Style/manuf part#/Dimensions,
                              etc.)
                            </div>
                          </th>
                          <th>Color</th>
                          <th>Quantity</th>
                          <th>Units</th>
                          <th>Price/Unit</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {viewSCData?.Items?.length > 0 ? (
                          viewSCData?.Items?.map((item, ind) => {
                            return (
                              <>
                                <tr key={ind}>
                                  <td>{item.id}</td>
                                  <td>{item.name}</td>
                                  <td>{item.color}</td>
                                  <td>{item.quantity}</td>
                                  <td>{item.unitId}</td>
                                  <td>$ {item.price}</td>
                                  <td>$ {item.total}</td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="12">
                              <p>No Data found.</p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="row">
                    <div className="col-md-4 ml-auto">
                      <div className="GstTable">
                        <table>
                          <tbody>
                            <tr>
                              <td>Profile</td>
                              <td>{viewSCData?.TaxProfile?.name}</td>
                            </tr>
                            <tr>
                              <td>Subtotal</td>
                              <td>$ {totalWithoutTaxVendor}</td>
                              {/* <td>Modify Tax</td> */}
                            </tr>
                            <tr>
                              <td>TaxPercent(
                              {taxPercentTotal}
                              %)</td>
                              <td>${finalTotalVendor}</td>
                            </tr>
                            <tr>
                              <td>Grand Total</td>
                              <td>$ {Number(totalWithoutTaxVendor) +
                                Number(finalTotalVendor)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-4">
                    <div className="Heading">
                      <h6>Attachments</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5>Scope of Work</h5>
                      <div className="CommonModalBox">
                        <h5 className="CursorPointer FolderPermissionId">
                          <figure>
                            <img src="images/scopeofwork.png" />
                          </figure>{" "}
                          <span className="Count">
                            {viewSCData?.workDocuments?.length ?? 0}
                          </span>
                          Scope of Work
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5>Construction Documents</h5>
                      <div className="CommonModalBox">
                        <h5 className="CursorPointer FolderPermissionId">
                          <figure>
                            <img src="images/construction_documents.png" />
                          </figure>{" "}
                          <span className="Count">
                            {viewSCData?.constructionDocuments?.length ?? 0}
                          </span>
                          Construction Documents
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5>External Quote</h5>
                      <div className="CommonModalBox">
                        <h5
                          className="CursorPointer FolderPermissionId"
                         onClick={handleDownloadAll}
                        >
                          <figure>
                            <img src="images/extermal_quote.png" />
                          </figure>{" "}
                          <span className="Count">
                            {viewSCData?.additionalDocuments?.length ?? 0}
                          </span>
                          External Quote
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between mt-4 ">
                {/* <button class="Create">Save and <img src="images/white_down_arrow.png"></button>
                <button class="Create">Save draft</button> */}
                {/* <button
                  className="Create"
                  data-toggle="modal"
                  data-target="#BackChargeSupplierCredit"
                  data-dismiss="modal"
                >
                  Cancel
                </button> */}
              </div>
            </div>
          </div>
          <div className="DiscussionArea">
            <div
              className="DiscussionTitle bg_f7"
              onClick={() => setShowDiscussion(!showDiscussion)}
            >
              <div className="d-flex align-items-center">
              <h4>Discussion</h4>{" "}
              <span className="borderOranges ml-2 mb-1">{discussionList?.length??0}</span>
              </div>
              <div class="DiscussionChat">
                <img
                  src={require("../assets/images/down.png")}
                  class="DiscussionIcon"
                />
                <a>
                  <img src={require("../assets/images/pdf2.png")} />
                </a>
              </div>
            </div>
            <div
              className="DiscussionHideArea"
              style={{
                display: showDiscussion ? "block" : "none",
                color: "red",
              }}
            >
              <div class="DiscussionBox">
                <span>FM</span>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    value={discussionState?.message}
                    onChange={(e) =>
                      updateDiscussionState({
                        ...discussionState,
                        message: e.target.value,
                      })
                    }
                    placeholder="Add a Comment ....."
                  />
                  {discussionState.discussionError &&
                    !discussionState.message && (
                      <p style={{ color: "red" }}>
                        {discussionState.discussionError}
                      </p>
                    )}

                  <div class="DiscussionOverlay">
                    <a
                      onClick={() =>
                        updateDiscussionState({
                          ...discussionState,
                          message: "",
                        })
                      }
                      class="CancelChat"
                    >
                      <img src={require("../assets/images/plus-cancel1.png")} />
                    </a>
                    <a onClick={handleAddDiscussion} class="SendChat">
                      <img
                        src={require("../assets/images/forwardbutton.png")}
                      />
                    </a>
                  </div>
                </div>
              </div>
               {discussionList?.map((item, i) => (
                  <>
                    <div class="DiscussionBox">
                      <span>CO</span>
                      <div class="DiscussionRight">
                        <h6>{item?.message}</h6>
                        <small>{moment(item.updatedAt).format("lll")}</small>
                      </div>
                    </div>
                    <div class="ReplyArea">
                      <a
                        onClick={(e) => {
                          updateReplyField({ flag: true, index: i });
                          updateReplyState({ ...replyState, message: "" });
                        }}
                      >
                        Reply
                      </a>
                      {item?.ReplyDiscussions?.map((ele) => (
                        <div class="DiscussionBox">
                          <span>RE</span>
                          <div class="DiscussionRight">
                            <h6>{ele?.message}</h6>
                            <small>{moment(ele.updatedAt).format("lll")}</small>
                          </div>
                        </div>
                      ))}

                      {replyField.index == i && (
                        <div class="DiscussionBox w-100">
                          <span>FM{item?.id}</span>
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              value={replyState?.message}
                              onChange={(e) =>
                                updateReplyState({
                                  ...replyState,
                                  message: e.target.value,
                                  messageId: item?.id,
                                  jobId: item?.jobId,
                                })
                              }
                              placeholder="Add reply ....."
                            />
                            {discussionState.discussionError &&
                              !discussionState.message && (
                                <p style={{ color: "red" }}>
                                  {discussionState.discussionError}
                                </p>
                              )}

                            <div class="DiscussionOverlay">
                              <a
                                onClick={() =>
                                  updateReplyState({
                                    ...replyState,
                                    message: "",
                                  })
                                }
                                class="CancelChat"
                              >
                                <img
                                  src={require("../assets/images/plus-cancel1.png")}
                                />
                              </a>
                              <a onClick={handleAddReply} class="SendChat">
                                <img
                                  src={require("../assets/images/forwardbutton.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ))} 
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TradeViewFpoSc;
