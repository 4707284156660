import axios from "axios";
import { AdminSalesConstant } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config/Config";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

export const getGeneralSettingInitate = () => ({
  type: AdminSalesConstant.GET_GENERAL_SETTING_INITIATE,
});

export const getGeneralSettingSuccess = (data) => ({
  type: AdminSalesConstant.GET_GENERAL_SETTING_SUCCESS,
  payload: data,
});

export const getGeneralSettingFailure = (data) => ({
  type: AdminSalesConstant.GET_GENERAL_SETTING_FAILURE,
  payload: data,
});

export function getGeneralSettingAction(pid) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getGeneralSettingInitate());
    return new Promise((resolve, reject) => {
      axios.get(`${Url}companies/${iBuildLocalData?.user?.id}/sale/generalsetting`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getGeneralSettingSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getGeneralSettingFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getGeneralSettingSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getGeneralSettingFailure(err));
            reject(err);
          }
        });
    });
  };
}



export const createGeneralSettingInitiate = () => ({
    type: AdminSalesConstant.CREATE_GENERAL_SETTING_INITIATE,
  });
  
  export const createGeneralSettingSuccess = (data) => ({
    type: AdminSalesConstant.CREATE_GENERAL_SETTING_SUCCESS,
    payload: data,
  });
  
  export const  createGeneralSettingFailure = (data) => ({
    type: AdminSalesConstant.CREATE_GENERAL_SETTING_FAILURE,
    payload: data,
  });
  
  export const createGeneralSettingAction = (folderId, id, payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createGeneralSettingSuccess(payload))
      const response = await axios.post(
        `${Url}companies/${iBuildLocalData?.user?.id}/sale/generalsetting`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        dispatch(createGeneralSettingSuccess(response.data));
      } else {
        dispatch(createGeneralSettingFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createGeneralSettingFailure(err));
      return err.response;
    }
  };
  


  export const updateGeneralSettingInitiate = () => ({
    type: AdminSalesConstant.CREATE_GENERAL_SETTING_INITIATE,
  });
  
  export const updateGeneralSettingSuccess = (data) => ({
    type: AdminSalesConstant.CREATE_GENERAL_SETTING_SUCCESS,
    payload: data,
  });
  
  export const  updateGeneralSettingFailure = (data) => ({
    type: AdminSalesConstant.CREATE_GENERAL_SETTING_FAILURE,
    payload: data,
  });
  
  export const updateGeneralSettingAction = ( payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createGeneralSettingSuccess(payload))
      const response = await axios.put(
        `${Url}companies/${iBuildLocalData?.user?.id}/sale/generalsetting`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        dispatch(updateGeneralSettingSuccess(response.data));
      } else {
        dispatch(updateGeneralSettingFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateGeneralSettingFailure(err));
      return err.response;
    }
  };
  
  
  