import React, { useState } from "react";
import Input from "./input";
import Quantities from "./Quantities";
import SummeryPreview from "./summeryPreview";

const CommonGarage = ({
  handleBuildingGarageFieldChange,
  garage,
  handleNumGarageBlocksChange,
  numBlocksGarage,
  commonGarageQuantity,
  handlecommonGarageQuantityChange,
  commonGarageStep3List,
  handleShowComponent,
}) => {
  const [show, setShow] = useState({
    garageInput: true,
    garageQuanties: false,
    garageSummeryPreview: false,
  });

  const handleShowComponents = (component) => {
    setShow({
      garageInput: component === "garageInput",
      garageQuanties: component === "garageQuanties",
      garageSummeryPreview: component === "garageSummeryPreview",
    });
  };
  return (
    <div className="FolderFormCreateArea Proforma">
      <div className="StepBox Green">
        <ul>
          <li
            className={show.garageInput ? "active" : ""}
            onClick={() => handleShowComponents("garageInput")}
          >
            <span className="Icon">
              <img src={require("../../../assets/images/block_input.png")} />
            </span>
            <span className="Text">
              Common <br /> Garage <br /> Input
            </span>
          </li>
          <li
            className={show.garageQuanties ? "active" : ""}
            onClick={() => handleShowComponents("garageQuanties")}
          >
            <span
              className="Icon FolderPermissionId"
              data-toggle="modal"
              data-target="#CreateProformaCommonGarageQuantities"
              data-dismiss="modal"
            >
              <img
                src={require("../../../assets/images/block_quantities.png")}
              />
            </span>
            <span className="Text">
              Common <br /> Garage <br /> Quantities
            </span>
          </li>
          <li
            className={show.garageSummeryPreview ? "active" : ""}
            onClick={() => handleShowComponents("garageSummeryPreview")}
          >
            <span
              className="Icon FolderPermissionId"
              data-toggle="modal"
              data-target="#CreateProformaCommonGarageSummaryPreview"
              data-dismiss="modal"
            >
              <img
                src={require("../../../assets/images/block_quantities.png")}
              />
            </span>
            <span className="Text">
              Common <br /> Garage <br /> Summary <br /> Preview
            </span>
          </li>
        </ul>
      </div>
      <div className="FieldsMandatory">
        <h6>
          Fields with <span className="required">*</span> are mandatory
        </h6>
      </div>
      {show.garageInput && (
        <Input
          handleBuildingGarageFieldChange={handleBuildingGarageFieldChange}
          garage={garage}
          handleNumGarageBlocksChange={handleNumGarageBlocksChange}
          numBlocksGarage={numBlocksGarage}
          handleShowComponent={handleShowComponent}
          handleShowComponents={handleShowComponents}
        />
      )}
      {show.garageQuanties && (
        <Quantities
          commonGarageQuantity={commonGarageQuantity}
          handlecommonGarageQuantityChange={handlecommonGarageQuantityChange}
          handleShowComponents={handleShowComponents}
        />
      )}
      {show.garageSummeryPreview && (
        <SummeryPreview
          commonGarageStep3List={commonGarageStep3List}
          handleShowComponent={handleShowComponent}
          handleShowComponents={handleShowComponents}
        />
      )}
    </div>
  );
};

export default CommonGarage;
