import { proformaActionTypes } from "../actionTypes";
import { Url } from "../../Config/Config";
import axios from "axios";
import { isLoggedIn } from "../../utils/tokenCheck";
import { multiPartData, multiPartDatas } from "../../utils/uploadFile";




//==============================UNIT-types-list=====================

export const getUnitsInitiate = () => ({
  type: proformaActionTypes.GET_UNITS_INITIATE,
});

export const getUnitsSuccess = (data) => ({
  type: proformaActionTypes.GET_UNITS_SUCCESS,
  payload: data,
});

export const getUnitsFailure = (data) => ({
  type: proformaActionTypes.GET_UNITS_FAILURE,
  payload: data,
});

export const getUnitsList = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getUnitsInitiate());
      const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}unit`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status ===200) {
        dispatch(getUnitsSuccess(response.data));
      } else {
        dispatch(getUnitsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getUnitsFailure(err));
      return err.response
    }
  };

//===============================================

export const addProformInitiate = () => ({
    type: proformaActionTypes.ADD_PROFORMA_INITIATE,
  });
  
  export const addProformSuccess = (data) => ({
    type: proformaActionTypes.ADD_PROFORMA_SUCCESS,
    payload: data,
  });
  
  export const addProformFailure = (data) => ({
    type: proformaActionTypes.ADD_PROFORMA_FAILURE,
    payload: data,
  });
  
  export const addProformaAction = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(addProformInitiate(payload));
        const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma?currentStep=1`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 201) {
          dispatch(addProformSuccess(response.data));
        } else {
          dispatch(addProformFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(addProformFailure(err));
        return err.response
      }
    };
//---------------------------------tasklISTjOB----------------------------------------------------
export const proformaTypesListInitiate = () => ({
    type: proformaActionTypes.PROFORMA_TYPES_INITIATE,
  });
  
  export const proformaTypesListSuccess = (data) => ({
    type: proformaActionTypes.PROFORMA_TYPES_SUCCESS,
    payload: data,
  });
  
  export const proformaTypesListFailure = (data) => ({
    type: proformaActionTypes.PROFORMA_TYPES_FAILURE,
    payload: data,
  });
  
  export const ProformaTypesListAction = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(proformaTypesListInitiate(payload));
        const response = await axios.get(`${Url}/proformaType`, {
          headers: { Authorization: `Bearer ${token}` },
        //   ?jobType=ResidentialJob
        });
        if (response.status == 200 || response.status == 201) {
          dispatch(proformaTypesListSuccess(response.data));
        } else {
          dispatch(proformaTypesListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(proformaTypesListFailure(err));
        return err.response
      }
    };



    
export const addProformaBlockStep1Initiate = () => ({
  type: proformaActionTypes.FIRST_STEP_1_BLOCK_INITIATE,
});

export const addProformaBlockStep1Success = (data) => ({
  type: proformaActionTypes.FIRST_STEP_1_BLOCK_SUCCESS,
  payload: data,
});

export const addProformaBlockStep1Failure = (data) => ({
  type: proformaActionTypes.FIRST_STEP_1_BLOCK_FAILURE,
  payload: data,
});

export const addProformaBlockStep1aAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(addProformaBlockStep1Initiate(payload));
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=2&subStep=1`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(addProformaBlockStep1Success(response.data));
      } else {
        dispatch(addProformaBlockStep1Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addProformaBlockStep1Failure(err));
      return err.response
    }
  };


     
export const addProformaBlockStep2Initiate = () => ({
  type: proformaActionTypes.FIRST_STEP_2_BLOCK_INITIATE,
});

export const addProformaBlockStep2Success = (data) => ({
  type: proformaActionTypes.FIRST_STEP_2_BLOCK_SUCCESS,
  payload: data,
});

export const addProformaBlockStep2Failure = (data) => ({
  type: proformaActionTypes.FIRST_STEP_2_BLOCK_FAILURE,
  payload: data,
});

export const addProformaBlockStep2Action = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(addProformaBlockStep2Initiate(payload));
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma?currentStep=1`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(addProformaBlockStep2Success(response.data));
      } else {
        dispatch(addProformaBlockStep2Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addProformaBlockStep2Failure(err));
      return err.response
    }
  };


  
export const addProformaBlockStep3Initiate = () => ({
  type: proformaActionTypes.FIRST_STEP_3_BLOCK_INITIATE,
});

export const addProformaBlockStep3Success = (data) => ({
  type: proformaActionTypes.FIRST_STEP_3_BLOCK_SUCCESS,
  payload: data,
});

export const addProformaBlockStep3Failure = (data) => ({
  type: proformaActionTypes.FIRST_STEP_3_BLOCK_FAILURE,
  payload: data,
});

export const addProformaBlockStep3Action = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(addProformaBlockStep3Initiate(payload));
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma?currentStep=1`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(addProformaBlockStep3Success(response.data));
      } else {
        dispatch(addProformaBlockStep3Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addProformaBlockStep3Failure(err));
      return err.response
    }
  };




export const FoundationListInitiate = () => ({
    type: proformaActionTypes.FOUNDATION_LIST_INITIATE,
  });
  
  export const FoundationListSuccess = (data) => ({
    type: proformaActionTypes.FOUNDATION_LIST_SUCCESS,
    payload: data,
  });
  
  export const FoundationListFailure = (data) => ({
    type: proformaActionTypes.FOUNDATION_LIST_FAILURE,
    payload: data,
  });
  
  export const FoundationListListAction = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(FoundationListInitiate(payload));
        const response = await axios.get(`${Url}foundationtype`, {
          headers: { Authorization: `Bearer ${token}` },
        //   ?jobType=ResidentialJob
        });
        if (response.status == 200) {
          dispatch(FoundationListSuccess(response.data));
        } else {
          dispatch(FoundationListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(FoundationListFailure(err));
        return err.response
      }
    };


//=====================================BlockFoundationStep1=======================

export const getBlockFoundationStep1Initiate = () => ({
    type: proformaActionTypes.GET_BLOCK_FOUNDATION_STEP1_INITIATE,
  });
  
  export const getBlockFoundationStep1Success = (data) => ({
    type: proformaActionTypes.GET_BLOCK_FOUNDATION_STEP1_SUCCESS,
    payload: data,
  });
  
  export const getBlockFoundationStep1Failure = (data) => ({
    type: proformaActionTypes.GET_BLOCK_FOUNDATION_STEP1_FAILURE,
    payload: data,
  });
  
  export const getBlockFoundationStep1 = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(getBlockFoundationStep1Initiate(payload));
                const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
        const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=3&subStep=1`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(getBlockFoundationStep1Success(response.data));
        } else {
          dispatch(getBlockFoundationStep1Failure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(getBlockFoundationStep1Failure(err));
        return err.response
      }
    };

//=========================================createBlockFoundationStep1-==================

export const createBlockFoundationStep1Initiate = () => ({
    type: proformaActionTypes.CREATE_BLOCK_FOUNDATION_STEP1_INITIATE,
  });
  
  export const createBlockFoundationStep1Success = (data) => ({
    type: proformaActionTypes.CREATE_BLOCK_FOUNDATION_STEP1_SUCCESS,
    payload: data,
  });
  
  export const createBlockFoundationStep1Failure = (data) => ({
    type: proformaActionTypes.CREATE_BLOCK_FOUNDATION_STEP1_FAILURE,
    payload: data,
  });
  
  export const createBlockFoundationStep1 = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(createBlockFoundationStep1Initiate(payload));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
                const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=3&subStep=1`, payload.changedValues,{
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(createBlockFoundationStep1Success(response.data));
        } else {
          dispatch(createBlockFoundationStep1Failure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(createBlockFoundationStep1Failure(err));
        return err.response
      }
    };

//===============================================BlockFoundationStep2=======================

export const getBlockFoundationStep2Initiate = () => ({
    type: proformaActionTypes.GET_BLOCK_FOUNDATION_STEP2_INITIATE,
  });
  
  export const getBlockFoundationStep2Success = (data) => ({
    type: proformaActionTypes.GET_BLOCK_FOUNDATION_STEP2_SUCCESS,
    payload: data,
  });
  
  export const getBlockFoundationStep2Failure = (data) => ({
    type: proformaActionTypes.GET_BLOCK_FOUNDATION_STEP2_FAILURE,
    payload: data,
  });
  
  export const getBlockFoundationStep2 = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(getBlockFoundationStep2Initiate(payload));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
                const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=3&subStep=2`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(getBlockFoundationStep2Success(response.data));
        } else {
          dispatch(getBlockFoundationStep2Failure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(getBlockFoundationStep2Failure(err));
        return err.response
      }
    };

//================================================createBlockFoundationStep2================================
export const createBlockFoundationStep2Initiate = () => ({
    type: proformaActionTypes.CREATE_BLOCK_FOUNDATION_STEP2_INITIATE,
  });
  
  export const createBlockFoundationStep2Success = (data) => ({
    type: proformaActionTypes.CREATE_BLOCK_FOUNDATION_STEP2_SUCCESS,
    payload: data,
  });
  
  export const createBlockFoundationStep2Failure = (data) => ({
    type: proformaActionTypes.CREATE_BLOCK_FOUNDATION_STEP2_FAILURE,
    payload: data,
  });
  
  export const createBlockFoundationStep2 = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(createBlockFoundationStep2Initiate(payload));
                const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
        const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=3&subStep=2`, payload,{
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(createBlockFoundationStep2Success(response.data));
        } else {
          dispatch(createBlockFoundationStep2Failure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(createBlockFoundationStep2Failure(err));
        return err.response
      }
    };


//================================================GetblockfoundationProformaStep3=======================================

export const getBlockFoundationStep3Initiate = () => ({
  type: proformaActionTypes.GET_BLOCK_FOUNDATION_STEP3_INITIATE,
});

export const getBlockFoundationStep3Success = (data) => ({
  type: proformaActionTypes.GET_BLOCK_FOUNDATION_STEP3_SUCCESS,
  payload: data,
});

export const getBlockFoundationStep3Failure = (data) => ({
  type: proformaActionTypes.GET_BLOCK_FOUNDATION_STEP3_FAILURE,
  payload: data,
});

export const getBlockFoundationStep3 = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getBlockFoundationStep3Initiate(payload));
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=3&subStep=3`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getBlockFoundationStep3Success(response.data));
      } else {
        dispatch(getBlockFoundationStep3Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getBlockFoundationStep3Failure(err));
      return err.response
    }
  };

//========================================getUnitStep1=================================

export const getUnitStep1Initiate = () => ({
  type: proformaActionTypes.GET_UNIT_STEP1_INITIATE,
});

export const getUnitStep1Success = (data) => ({
  type: proformaActionTypes.GET_UNIT_STEP1_SUCCESS,
  payload: data,
});

export const getUnitStep1Failure = (data) => ({
  type: proformaActionTypes.GET_UNIT_STEP1_FAILURE,
  payload: data,
});

export const getUnitStep1 = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getUnitStep1Initiate(payload));
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=4&subStep=1`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getUnitStep1Success(response.data));
      } else {
        dispatch(getUnitStep1Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getUnitStep1Failure(err));
      return err.response
    }
  };

//======================================getUnitStep2=============


export const getUnitStep2Initiate = () => ({
  type: proformaActionTypes.GET_UNIT_STEP2_INITIATE,
});

export const getUnitStep2Success = (data) => ({
  type: proformaActionTypes.GET_UNIT_STEP2_SUCCESS,
  payload: data,
});

export const getUnitStep2Failure = (data) => ({
  type: proformaActionTypes.GET_UNIT_STEP2_FAILURE,
  payload: data,
});

export const getUnitStep2 = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getUnitStep2Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=4&subStep=2`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getUnitStep2Success(response.data));
      } else {
        dispatch(getUnitStep2Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getUnitStep2Failure(err));
      return err.response
    }
  };

//=================================create-unit1-===========================================

export const createUnitStep1Initiate = () => ({
  type: proformaActionTypes.CREATE_UNIT_STEP1_INITIATE,
});

export const createUnitStep1Success = (data) => ({
  type: proformaActionTypes.CREATE_UNIT_STEP1_SUCCESS,
  payload: data,
});

export const createUnitStep1Failure = (data) => ({
  type: proformaActionTypes.CREATE_UNIT_STEP1_FAILURE,
  payload: data,
});

export const createUnitStep1 = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createUnitStep1Initiate(payload));
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=4&subStep=1`, payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(createUnitStep1Success(response.data));
      } else {
        dispatch(createUnitStep1Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createUnitStep1Failure(err));
      return err.response
    }
  };

//=======================================create2=unit-2===============================================


export const createUnitStep2Initiate = () => ({
  type: proformaActionTypes.CREATE_UNIT_STEP2_INITIATE,
});

export const createUnitStep2Success = (data) => ({
  type: proformaActionTypes.CREATE_UNIT_STEP2_SUCCESS,
  payload: data,
});

export const createUnitStep2Failure = (data) => ({
  type: proformaActionTypes.CREATE_UNIT_STEP2_FAILURE,
  payload: data,
});

export const createUnitStep2 = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createUnitStep2Initiate(payload));
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=4&subStep=2`, payload.unitTypes,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(createUnitStep2Success(response.data));
      } else {
        dispatch(createUnitStep2Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createUnitStep2Failure(err));
      return err.response
    }
  };
//=============================================getunit-step-3====================================

export const getUnitStep3Initiate = () => ({
  type: proformaActionTypes.GET_UNIT_STEP3_INITIATE,
});

export const getUnitStep3Success = (data) => ({
  type: proformaActionTypes.GET_UNIT_STEP3_SUCCESS,
  payload: data,
});

export const getUnitStep3Failure = (data) => ({
  type: proformaActionTypes.GET_UNIT_STEP3_FAILURE,
  payload: data,
});

export const getUnitStep3 = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getUnitStep3Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=4&subStep=3`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getUnitStep3Success(response.data));
      } else {
        dispatch(getUnitStep3Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getUnitStep3Failure(err));
      return err.response
    }
  };
//===========================================get-unit-step-4========================================

export const getUnitStep4Initiate = () => ({
  type: proformaActionTypes.GET_UNIT_STEP4_INITIATE,
});

export const getUnitStep4Success = (data) => ({
  type: proformaActionTypes.GET_UNIT_STEP4_SUCCESS,
  payload: data,
});

export const getUnitStep4Failure = (data) => ({
  type: proformaActionTypes.GET_UNIT_STEP4_FAILURE,
  payload: data,
});

export const getUnitStep4 = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getUnitStep4Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=4&subStep=4`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getUnitStep4Success(response.data));
      } else {
        dispatch(getUnitStep4Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getUnitStep4Failure(err));
      return err.response
    }
  };

//==========================================create-unit-step-3===========================

export const createUnitStep3Initiate = () => ({
  type: proformaActionTypes.CREATE_UNIT_STEP3_INITIATE,
});

export const createUnitStep3Success = (data) => ({
  type: proformaActionTypes.CREATE_UNIT_STEP3_SUCCESS,
  payload: data,
});

export const createUnitStep3Failure = (data) => ({
  type: proformaActionTypes.CREATE_UNIT_STEP3_FAILURE,
  payload: data,
});

export const createUnitStep3 = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createUnitStep3Initiate(payload));
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=4&subStep=3`, payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(createUnitStep3Success(response.data));
      } else {
        dispatch(createUnitStep3Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createUnitStep3Failure(err));
      return err.response
    }
  };
//============================GET-COMMON_AREA_STEP1=====================

export const getCommonAreaStep1Initiate = () => ({
  type: proformaActionTypes.GET_COMMON_AREA_STEP1_INITIATE,
});

export const getCommonAreaStep1Success = (data) => ({
  type: proformaActionTypes.GET_COMMON_AREA_STEP1_SUCCESS,
  payload: data,
});

export const getCommonAreaStep1Failure = (data) => ({
  type: proformaActionTypes.GET_COMMON_AREA_STEP1_FAILURE,
  payload: data,
});

export const getCommonAreaStep1 = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getCommonAreaStep1Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=5&subStep=1`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getCommonAreaStep1Success(response.data));
      } else {
        dispatch(getCommonAreaStep1Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getCommonAreaStep1Failure(err));
      return err.response
    }
  };

//===========================create-common-Area-1====================

export const createCommonAreaStep1Initiate = () => ({
  type: proformaActionTypes.CREATE_COMMON_AREA_STEP1_INITIATE,
});

export const createCommonAreaStep1Success = (data) => ({
  type: proformaActionTypes.CREATE_COMMON_AREA_STEP1_SUCCESS,
  payload: data,
});

export const createCommonAreaStep1Failure = (data) => ({
  type: proformaActionTypes.CREATE_COMMON_AREA_STEP1_FAILURE,
  payload: data,
});

export const createCommonAreaStep1 = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createCommonAreaStep1Initiate(payload));
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=5&subStep=1`, payload.unitTypes,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(createCommonAreaStep1Success(response.data));
      } else {
        dispatch(createCommonAreaStep1Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createCommonAreaStep1Failure(err));
      return err.response
    }
  };

//====================get-common-area-step-2=======================

export const getCommonAreaStep2Initiate = () => ({
  type: proformaActionTypes.GET_COMMON_AREA_STEP2_INITIATE,
});

export const getCommonAreaStep2Success = (data) => ({
  type: proformaActionTypes.GET_COMMON_AREA_STEP2_SUCCESS,
  payload: data,
});

export const getCommonAreaStep2Failure = (data) => ({
  type: proformaActionTypes.GET_COMMON_AREA_STEP2_FAILURE,
  payload: data,
});

export const getCommonAreaStep2 = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getCommonAreaStep2Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=5&subStep=2`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getCommonAreaStep2Success(response.data));
      } else {
        dispatch(getCommonAreaStep2Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getCommonAreaStep2Failure(err));
      return err.response
    }
  };

//========================================Get-common-buildings-step1==============

export const getCommonBuildingStep1Initiate = () => ({
  type: proformaActionTypes.GET_COMMON_BUILDING_STEP1_INITIATE,
});

export const getCommonBuildingStep1Success = (data) => ({
  type: proformaActionTypes.GET_COMMON_BUILDING_STEP1_SUCCESS,
  payload: data,
});

export const getCommonBuildingStep1Failure = (data) => ({
  type: proformaActionTypes.GET_COMMON_BUILDING_STEP1_FAILURE,
  payload: data,
});

export const getCommonBuildingStep1 = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getCommonBuildingStep1Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=6&subStep=1`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getCommonBuildingStep1Success(response.data));
      } else {
        dispatch(getCommonBuildingStep1Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getCommonBuildingStep1Failure(err));
      return err.response
    }
  };
//==================================GET-common-building-step-2=====================


export const getCommonBuildingStep2Initiate = () => ({
  type: proformaActionTypes.GET_COMMON_BUILDING_STEP2_INITIATE,
});

export const getCommonBuildingStep2Success = (data) => ({
  type: proformaActionTypes.GET_COMMON_BUILDING_STEP2_SUCCESS,
  payload: data,
});

export const getCommonBuildingStep2Failure = (data) => ({
  type: proformaActionTypes.GET_COMMON_BUILDING_STEP2_FAILURE,
  payload: data,
});

export const getCommonBuildingStep2 = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getCommonBuildingStep2Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=6&subStep=2`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getCommonBuildingStep2Success(response.data));
      } else {
        dispatch(getCommonBuildingStep2Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getCommonBuildingStep2Failure(err));
      return err.response
    }
  };
//=====================================get-common-building-step3=====================


export const getCommonBuildingStep3Initiate = () => ({
  type: proformaActionTypes.GET_COMMON_BUILDING_STEP3_INITIATE,
});

export const getCommonBuildingStep3Success = (data) => ({
  type: proformaActionTypes.GET_COMMON_BUILDING_STEP3_SUCCESS,
  payload: data,
});

export const getCommonBuildingStep3Failure = (data) => ({
  type: proformaActionTypes.GET_COMMON_BUILDING_STEP3_FAILURE,
  payload: data,
});

export const getCommonBuildingStep3 = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getCommonBuildingStep3Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=6&subStep=3`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getCommonBuildingStep3Success(response.data));
      } else {
        dispatch(getCommonBuildingStep3Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getCommonBuildingStep3Failure(err));
      return err.response
    }
  };

//=====================================get-Land-devl-step1=====================


export const getLandDevelopmentStep1Initiate = () => ({
  type: proformaActionTypes.GET_LAND_DEVELOPMENT_STEP1_INITIATE,
});

export const getLandDevelopmentStep1Success = (data) => ({
  type: proformaActionTypes.GET_LAND_DEVELOPMENT_STEP1_SUCCESS,
  payload: data,
});

export const getLandDevelopmentStep1Failure = (data) => ({
  type: proformaActionTypes.GET_LAND_DEVELOPMENT_STEP1_FAILURE,
  payload: data,
});

export const getLandDevelopmentStep1 = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getLandDevelopmentStep1Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=10&subStep=1`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getLandDevelopmentStep1Success(response.data));
      } else {
        dispatch(getLandDevelopmentStep1Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getLandDevelopmentStep1Failure(err));
      return err.response
    }
  };
//===============================================get-land-devlop-2==============================================


export const getLandDevelopmentStep2Initiate = () => ({
  type: proformaActionTypes.GET_LAND_DEVELOPMENT_STEP2_INITIATE,
});

export const getLandDevelopmentStep2Success = (data) => ({
  type: proformaActionTypes.GET_LAND_DEVELOPMENT_STEP2_SUCCESS,
  payload: data,
});

export const getLandDevelopmentStep2Failure = (data) => ({
  type: proformaActionTypes.GET_LAND_DEVELOPMENT_STEP2_FAILURE,
  payload: data,
});

export const getLandDevelopmentStep2 = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getLandDevelopmentStep2Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=10&subStep=2`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getLandDevelopmentStep2Success(response.data));
      } else {
        dispatch(getLandDevelopmentStep2Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getLandDevelopmentStep2Failure(err));
      return err.response
    }
  };

//=================================================get-land-devlp-step-3===============================================


export const getLandDevelopmentStep3Initiate = () => ({
  type: proformaActionTypes.GET_LAND_DEVELOPMENT_STEP3_INITIATE,
});

export const getLandDevelopmentStep3Success = (data) => ({
  type: proformaActionTypes.GET_LAND_DEVELOPMENT_STEP3_SUCCESS,
  payload: data,
});

export const getLandDevelopmentStep3Failure = (data) => ({
  type: proformaActionTypes.GET_LAND_DEVELOPMENT_STEP3_FAILURE,
  payload: data,
});

export const getLandDevelopmentStep3 = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getLandDevelopmentStep3Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=10&subStep=3`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getLandDevelopmentStep3Success(response.data));
      } else {
        dispatch(getLandDevelopmentStep3Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getLandDevelopmentStep3Failure(err));
      return err.response
    }
  };

//============================================create-land-devlop-1==========================

export const createLandDevelopmentStep1Initiate = () => ({
  type: proformaActionTypes.CREATE_LAND_DEVELOPMENT_STEP1_INITIATE,
});

export const createLandDevelopmentStep1Success = (data) => ({
  type: proformaActionTypes.CREATE_LAND_DEVELOPMENT_STEP1_SUCCESS,
  payload: data,
});

export const createLandDevelopmentStep1Failure = (data) => ({
  type: proformaActionTypes.CREATE_LAND_DEVELOPMENT_STEP1_FAILURE,
  payload: data,
});

export const createLandDevelopmentStep1 = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createLandDevelopmentStep1Initiate(payload));
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=10&subStep=1`, payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(createLandDevelopmentStep1Success(response.data));
      } else {
        dispatch(createLandDevelopmentStep1Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createLandDevelopmentStep1Failure(err));
      return err.response
    }
  };

//==========================get-proforma-confirmation-summary--------------


export const getProformaConfirmationSumarryInitiate = () => ({
  type: proformaActionTypes.GET_PROFORMA_CONFIRMATION_SUMARRY_INITIATE,
});

export const getProformaConfirmationSumarrySuccess = (data) => ({
  type: proformaActionTypes.GET_PROFORMA_CONFIRMATION_SUMARRY_SUCCESS,
  payload: data,
});

export const getProformaConfirmationSumarryFailure = (data) => ({
  type: proformaActionTypes.GET_PROFORMA_CONFIRMATION_SUMARRY_FAILURE,
  payload: data,
});

export const getProformaConfirmationSumarry = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getProformaConfirmationSumarryInitiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=11`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getProformaConfirmationSumarrySuccess(response.data));
      } else {
        dispatch(getProformaConfirmationSumarryFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getProformaConfirmationSumarryFailure(err));
      return err.response
    }
  };


//=================================create-common-building-1-===========================================

export const createCommonBuildingStep1Initiate = () => ({
  type: proformaActionTypes.CREATE_COMMON_BUILDING_STEP1_INITIATE,
});

export const createCommonBuildingStep1Success = (data) => ({
  type: proformaActionTypes.CREATE_COMMON_BUILDING_STEP1_SUCCESS,
  payload: data,
});

export const createCommonBuildingStep1Failure = (data) => ({
  type: proformaActionTypes.CREATE_COMMON_BUILDING_STEP1_FAILURE,
  payload: data,
});

export const createCommonBuildingStep1 = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createCommonBuildingStep1Initiate(payload));
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=6&subStep=1`, payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(createCommonBuildingStep1Success(response.data));
      } else {
        dispatch(createCommonBuildingStep1Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createCommonBuildingStep1Failure(err));
      return err.response
    }
  };

//=======================================get-foundation-type=======================

export const getFoundationTypeInitiate = () => ({
  type: proformaActionTypes.GET_FOUNDATION_TYPE_INITIATE,
});

export const getFoundationTypeSuccess = (data) => ({
  type: proformaActionTypes.GET_FOUNDATION_TYPE_SUCCESS,
  payload: data,
});

export const getFoundationTypeFailure = (data) => ({
  type: proformaActionTypes.GET_FOUNDATION_TYPE_FAILURE,
  payload: data,
});

export const getFoundationType = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getFoundationTypeInitiate());
      const response = await axios.get(`${Url}foundationtype`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getFoundationTypeSuccess(response.data));
      } else {
        dispatch(getFoundationTypeFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getFoundationTypeFailure(err));
      return err.response
    }
  };

//=================================create-common-building-2-===========================================

export const createCommonBuildingStep2Initiate = () => ({
  type: proformaActionTypes.CREATE_COMMON_BUILDING_STEP2_INITIATE,
});

export const createCommonBuildingStep2Success = (data) => ({
  type: proformaActionTypes.CREATE_COMMON_BUILDING_STEP2_SUCCESS,
  payload: data,
});

export const createCommonBuildingStep2Failure = (data) => ({
  type: proformaActionTypes.CREATE_COMMON_BUILDING_STEP2_FAILURE,
  payload: data,
});

export const createCommonBuildingStep2 = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createCommonBuildingStep2Initiate(payload));
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=6&subStep=2`, payload.commonBuildingQuantities,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(createCommonBuildingStep2Success(response.data));
      } else {
        dispatch(createCommonBuildingStep2Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createCommonBuildingStep2Failure(err));
      return err.response
    }
  };


//=================================get-common-building-foundation-step-1==========

export const getCommonBuildingFoundationStep1Initiate = () => ({
  type: proformaActionTypes.GET_COMMON_BUILDING_FOUNDATION_STEP1_INITIATE,
});

export const getCommonBuildingFoundationStep1Success = (data) => ({
  type: proformaActionTypes.GET_COMMON_BUILDING_FOUNDATION_STEP1_SUCCESS,
  payload: data,
});

export const getCommonBuildingFoundationStep1Failure = (data) => ({
  type: proformaActionTypes.GET_COMMON_BUILDING_FOUNDATION_STEP1_FAILURE,
  payload: data,
});

export const getCommonBuildingFoundationStep1 = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getCommonBuildingFoundationStep1Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=7&subStep=1`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getCommonBuildingFoundationStep1Success(response.data));
      } else {
        dispatch(getCommonBuildingFoundationStep1Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getCommonBuildingFoundationStep1Failure(err));
      return err.response
    }
  };

//============================create-common-building-foundation-step1==========================

export const createCommonBuildingFoundationStep1Initiate = () => ({
  type: proformaActionTypes.CREATE_COMMON_BUILDING_FOUNDATION_STEP1_INITIATE,
});

export const createCommonBuildingFoundationStep1Success = (data) => ({
  type: proformaActionTypes.CREATE_COMMON_BUILDING_FOUNDATION_STEP1_SUCCESS,
  payload: data,
});

export const createCommonBuildingFoundationStep1Failure = (data) => ({
  type: proformaActionTypes.CREATE_COMMON_BUILDING_FOUNDATION_STEP1_FAILURE,
  payload: data,
});

export const createCommonBuildingFoundationStep1 = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createCommonBuildingFoundationStep1Initiate(payload));
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=7&subStep=1`, payload.commonBuildingQuantities,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(createCommonBuildingFoundationStep1Success(response.data));
      } else {
        dispatch(createCommonBuildingFoundationStep1Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createCommonBuildingFoundationStep1Failure(err));
      return err.response
    }
  };

//=============================================get--common-building-step2-------------------------

export const getCommonBuildingFoundationStep2Initiate = () => ({
  type: proformaActionTypes.GET_COMMON_BUILDING_FOUNDATION_STEP2_INITIATE,
});

export const getCommonBuildingFoundationStep2Success = (data) => ({
  type: proformaActionTypes.GET_COMMON_BUILDING_FOUNDATION_STEP2_SUCCESS,
  payload: data,
});

export const getCommonBuildingFoundationStep2Failure = (data) => ({
  type: proformaActionTypes.GET_COMMON_BUILDING_FOUNDATION_STEP2_FAILURE,
  payload: data,
});

export const getCommonBuildingFoundationStep2 = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getCommonBuildingFoundationStep2Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=7&subStep=2`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getCommonBuildingFoundationStep2Success(response.data));
      } else {
        dispatch(getCommonBuildingFoundationStep2Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getCommonBuildingFoundationStep2Failure(err));
      return err.response
    }
  };

//=====================================create-common-building-foundation-step--2===========================

export const createCommonBuildingFoundationStep2Initiate = () => ({
  type: proformaActionTypes.CREATE_COMMON_BUILDING_FOUNDATION_STEP2_INITIATE,
});

export const createCommonBuildingFoundationStep2Success = (data) => ({
  type: proformaActionTypes.CREATE_COMMON_BUILDING_FOUNDATION_STEP2_SUCCESS,
  payload: data,
});

export const createCommonBuildingFoundationStep2Failure = (data) => ({
  type: proformaActionTypes.CREATE_COMMON_BUILDING_FOUNDATION_STEP2_FAILURE,
  payload: data,
});

export const createCommonBuildingFoundationStep2 = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createCommonBuildingFoundationStep2Initiate(payload));
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=7&subStep=2`, payload.commonBuildingFoundationInput,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(createCommonBuildingFoundationStep2Success(response.data));
      } else {
        dispatch(createCommonBuildingFoundationStep2Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createCommonBuildingFoundationStep2Failure(err));
      return err.response
    }
  };

//=============================================get-common-building====foundataion-step=3================================

export const getCommonBuildingFoundationStep3Initiate = () => ({
  type: proformaActionTypes.GET_COMMON_BUILDING_FOUNDATION_STEP3_INITIATE,
});

export const getCommonBuildingFoundationStep3Success = (data) => ({
  type: proformaActionTypes.GET_COMMON_BUILDING_FOUNDATION_STEP3_SUCCESS,
  payload: data,
});

export const getCommonBuildingFoundationStep3Failure = (data) => ({
  type: proformaActionTypes.GET_COMMON_BUILDING_FOUNDATION_STEP3_FAILURE,
  payload: data,
});

export const getCommonBuildingFoundationStep3 = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getCommonBuildingFoundationStep3Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=7&subStep=3`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getCommonBuildingFoundationStep3Success(response.data));
      } else {
        dispatch(getCommonBuildingFoundationStep3Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getCommonBuildingFoundationStep3Failure(err));
      return err.response
    }
  };

//========================================get-common-garage-step1-===============


export const getCommonGarageStep1Initiate = () => ({
  type: proformaActionTypes.GET_COMMON_GARAGE_STEP1_INITIATE,
});

export const getCommonGarageStep1Success = (data) => ({
  type: proformaActionTypes.GET_COMMON_GARAGE_STEP1_SUCCESS,
  payload: data,
});

export const getCommonGarageStep1Failure = (data) => ({
  type: proformaActionTypes.GET_COMMON_GARAGE_STEP1_FAILURE,
  payload: data,
});

export const getCommonGarageStep1 = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getCommonGarageStep1Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=8&subStep=1`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getCommonGarageStep1Success(response.data));
      } else {
        dispatch(getCommonGarageStep1Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getCommonGarageStep1Failure(err));
      return err.response
    }
  };

//=========================================create-common-garage-step1=================

export const craeteCommonGarageStep1Initiate = () => ({
  type: proformaActionTypes.CREATE_COMMON_GARAGE_STEP1_INITIATE,
});

export const craeteCommonGarageStep1Success = (data) => ({
  type: proformaActionTypes.CREATE_COMMON_GARAGE_STEP1_SUCCESS,
  payload: data,
});

export const craeteCommonGarageStep1Failure = (data) => ({
  type: proformaActionTypes.CREATE_COMMON_GARAGE_STEP1_FAILURE,
  payload: data,
});

export const createCommonGarageStep1 = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(craeteCommonGarageStep1Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=8&subStep=1`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(craeteCommonGarageStep1Success(response.data));
      } else {
        dispatch(craeteCommonGarageStep1Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(craeteCommonGarageStep1Failure(err));
      return err.response
    }
  };

//=================================get-common-garage-step2=========================


export const getCommonGarageStep2Initiate = () => ({
  type: proformaActionTypes.GET_COMMON_GARAGE_STEP2_INITIATE,
});

export const getCommonGarageStep2Success = (data) => ({
  type: proformaActionTypes.GET_COMMON_GARAGE_STEP2_SUCCESS,
  payload: data,
});

export const getCommonGarageStep2Failure = (data) => ({
  type: proformaActionTypes.GET_COMMON_GARAGE_STEP2_FAILURE,
  payload: data,
});

export const getCommonGarageStep2 = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getCommonGarageStep2Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=8&subStep=2`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getCommonGarageStep2Success(response.data));
      } else {
        dispatch(getCommonGarageStep2Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getCommonGarageStep2Failure(err));
      return err.response
    }
  };

//=========================================create-common-garage-step2====================
export const craeteCommonGarageStep2Initiate = () => ({
  type: proformaActionTypes.CREATE_COMMON_GARAGE_STEP2_INITIATE,
});

export const craeteCommonGarageStep2Success = (data) => ({
  type: proformaActionTypes.CREATE_COMMON_GARAGE_STEP2_SUCCESS,
  payload: data,
});

export const craeteCommonGarageStep2Failure = (data) => ({
  type: proformaActionTypes.CREATE_COMMON_GARAGE_STEP2_FAILURE,
  payload: data,
});

export const createCommonGarageStep2 = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(craeteCommonGarageStep2Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=8&subStep=2`,payload.commonGarageQuantity,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(craeteCommonGarageStep2Success(response.data));
      } else {
        dispatch(craeteCommonGarageStep2Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(craeteCommonGarageStep2Failure(err));
      return err.response
    }
  };
//=======================get-common-garage===step3========================


export const getCommonGarageStep3Initiate = () => ({
  type: proformaActionTypes.GET_COMMON_GARAGE_STEP3_INITIATE,
});

export const getCommonGarageStep3Success = (data) => ({
  type: proformaActionTypes.GET_COMMON_GARAGE_STEP3_SUCCESS,
  payload: data,
});

export const getCommonGarageStep3Failure = (data) => ({
  type: proformaActionTypes.GET_COMMON_GARAGE_STEP3_FAILURE,
  payload: data,
});

export const getCommonGarageStep3 = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getCommonGarageStep3Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=8&subStep=3`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getCommonGarageStep3Success(response.data));
      } else {
        dispatch(getCommonGarageStep3Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getCommonGarageStep3Failure(err));
      return err.response
    }
  };

//================================get-multilevele-step1============================

export const getMultiLevelStep1Initiate = () => ({
  type: proformaActionTypes.GET_MULTI_LEVEL_STEP1_INITIATE,
});

export const getMultiLevelStep1Success = (data) => ({
  type: proformaActionTypes.GET_MULTI_LEVEL_STEP1_INITIATE,
  payload: data,
});

export const getMultiLevelStep1Failure = (data) => ({
  type: proformaActionTypes.GET_MULTI_LEVEL_STEP1_INITIATE,
  payload: data,
});

export const getMultiLevelStep1 = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getMultiLevelStep1Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=9&subStep=1`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getMultiLevelStep1Success(response.data));
      } else {
        dispatch(getMultiLevelStep1Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getMultiLevelStep1Failure(err));
      return err.response
    }
  };

//==========================create-muiltilevel-step2=============================


export const createMultiLevelStep1Initiate = () => ({
  type: proformaActionTypes.CREATE_MULTI_LEVEL_STEP1_INITIATE,
});

export const createMultiLevelStep1Success = (data) => ({
  type: proformaActionTypes.CREATE_MULTI_LEVEL_STEP1_SUCCESS,
  payload: data,
});

export const createMultiLevelStep1Failure = (data) => ({
  type: proformaActionTypes.CREATE_MULTI_LEVEL_STEP1_FAILURE,
  payload: data,
});

export const createMultiLevelStep1 = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createMultiLevelStep1Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=9&subStep=1`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(createMultiLevelStep1Success(response.data));
      } else {
        dispatch(createMultiLevelStep1Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createMultiLevelStep1Failure(err));
      return err.response
    }
  };

//==================================get-multilevle-step-2========================


export const getMultiLevelStep2Initiate = () => ({
  type: proformaActionTypes.GET_MULTI_LEVEL_STEP2_INITIATE,
});

export const getMultiLevelStep2Success = (data) => ({
  type: proformaActionTypes.GET_MULTI_LEVEL_STEP2_SUCCESS,
  payload: data,
});

export const getMultiLevelStep2Failure = (data) => ({
  type: proformaActionTypes.GET_MULTI_LEVEL_STEP2_FAILURE,
  payload: data,
});

export const getMultiLevelStep2 = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getMultiLevelStep2Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=9&subStep=2`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getMultiLevelStep2Success(response.data));
      } else {
        dispatch(getMultiLevelStep2Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getMultiLevelStep2Failure(err));
      return err.response
    }
  };
//==============================create-multilevel---step-2===================

export const createMultiLevelStep2Initiate = () => ({
  type: proformaActionTypes.CREATE_MULTI_LEVEL_STEP2_INITIATE,
});

export const createMultiLevelStep2Success = (data) => ({
  type: proformaActionTypes.CREATE_MULTI_LEVEL_STEP2_SUCCESS,
  payload: data,
});

export const createMultiLevelStep2Failure = (data) => ({
  type: proformaActionTypes.CREATE_MULTI_LEVEL_STEP2_FAILURE,
  payload: data,
});

export const createMultiLevelStep2 = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createMultiLevelStep2Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=9&subStep=2`,payload.MulitileveleParkadeQuantiesData,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(createMultiLevelStep2Success(response.data));
      } else {
        dispatch(createMultiLevelStep2Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createMultiLevelStep2Failure(err));
      return err.response
    }
  };

//=====================================get-multi-level-step-3==============================


export const getMultiLevelStep3Initiate = () => ({
  type: proformaActionTypes.GET_MULTI_LEVEL_STEP3_INITIATE,
});

export const getMultiLevelStep3Success = (data) => ({
  type: proformaActionTypes.GET_MULTI_LEVEL_STEP3_INITIATE,
  payload: data,
});

export const getMultiLevelStep3Failure = (data) => ({
  type: proformaActionTypes.GET_MULTI_LEVEL_STEP3_INITIATE,
  payload: data,
});

export const getMultiLevelStep3 = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getMultiLevelStep3Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=9&subStep=3`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getMultiLevelStep3Success(response.data));
      } else {
        dispatch(getMultiLevelStep3Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getMultiLevelStep3Failure(err));
      return err.response
    }
  };

//============================create-land-develop--2=====


export const createLandDevelopmentStep2Initiate = () => ({
  type: proformaActionTypes.CREATE_LAND_DEVELOPMENT_STEP2_INITIATE,
});

export const createLandDevelopmentStep2Success = (data) => ({
  type: proformaActionTypes.CREATE_LAND_DEVELOPMENT_STEP2_SUCCESS,
  payload: data,
});

export const createLandDevelopmentStep2Failure = (data) => ({
  type: proformaActionTypes.CREATE_LAND_DEVELOPMENT_STEP2_FAILURE,
  payload: data,
});

export const createLandDevelopmentStep2 = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createLandDevelopmentStep2Initiate(payload));
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=10&subStep=2`, payload.LandDevlopmentQuantites,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(createLandDevelopmentStep2Success(response.data));
      } else {
        dispatch(createLandDevelopmentStep2Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createLandDevelopmentStep2Failure(err));
      return err.response
    }
  };

//==========================activate-proforma====================



export const activeProformaInitiate = () => ({
  type: proformaActionTypes.ACTIVE_PROFORMA_INITIATE,
});

export const activeProformaSuccess = (data) => ({
  type: proformaActionTypes.ACTIVE_PROFORMA_SUCCESS,
  payload: data,
});

export const activeProformaFailure = (data) => ({
  type: proformaActionTypes.ACTIVE_PROFORMA_FAILURE,
  payload: data,
});

export const activeProforma = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(activeProformaInitiate(payload));
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.patch(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}/activate`, payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(activeProformaSuccess(response.data));
      } else {
        dispatch(activeProformaFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(activeProformaFailure(err));
      return err.response
    }
  };












//=================================getproforma-block-step2===============================


export const getProformaBlockStep2Initiate = () => ({
  type: proformaActionTypes.GET_PROFORMA_BLOCK_STEP2_INITIATE,
});

export const getProformaBlockStep2Success = (data) => ({
  type: proformaActionTypes.GET_PROFORMA_BLOCK_STEP2_SUCCESS,
  payload: data,
});

export const getProformaBlockStep2Failure = (data) => ({
  type: proformaActionTypes.GET_PROFORMA_BLOCK_STEP2_FAILURE,
  payload: data,
});

export const getProformaBlockStep2 = (id) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getProformaBlockStep2Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=2&subStep=2`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getProformaBlockStep2Success(response.data));
      } else {
        dispatch(getProformaBlockStep2Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getProformaBlockStep2Failure(err));
      return err.response
    }
  };


//=============================get-proforma-block-step3======================================

export const getProformaBlockStep3Initiate = () => ({
  type: proformaActionTypes.GET_PROFORMA_BLOCK_STEP3_INITIATE,
});

export const getProformaBlockStep3Success = (data) => ({
  type: proformaActionTypes.GET_PROFORMA_BLOCK_STEP3_SUCCESS,
  payload: data,
});

export const getProformaBlockStep3Failure = (data) => ({
  type: proformaActionTypes.GET_PROFORMA_BLOCK_STEP3_FAILURE,
  payload: data,
});

export const getProformaBlockStep3 = () => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getProformaBlockStep3Initiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=2&subStep=3`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getProformaBlockStep3Success(response.data));
      } else {
        dispatch(getProformaBlockStep3Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getProformaBlockStep3Failure(err));
      return err.response
    }
  };

//==============================create-proforma===block====step==2==================

export const createProformaBlock2StepInitiate = () => ({
  type: proformaActionTypes.CREATE_PROFORMA_BLOCK_STEP2_INITIATE,
});

export const createProformaBlock2Success = (data) => ({
  type: proformaActionTypes.CREATE_PROFORMA_BLOCK_STEP2_SUCCESS,
  payload: data,
});

export const createProformaBlock2Failure = (data) => ({
  type: proformaActionTypes.CREATE_PROFORMA_BLOCK_STEP2_FAILURE,
  payload: data,
});

export const createProformaBlockStep2 = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createProformaBlock2StepInitiate());
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=2&subStep=2`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(createProformaBlock2Success(response.data));
      } else {
        dispatch(createProformaBlock2Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createProformaBlock2Failure(err));
      return err.response
    }
  };

  
//========================create-projects-jobs====================


export const createProjectsJobsProformaInitiate = () => ({
  type: proformaActionTypes.CREATE_PROJECTS_JOBS_PROFORMA_INITIATE,
});

export const createProjectsJobsProformaSuccess = (data) => ({
  type: proformaActionTypes.CREATE_PROJECTS_JOBS_PROFORMA_SUCCESS,
  payload: data,
});

export const createProjectsJobsProformaFailure = (data) => ({
  type: proformaActionTypes.CREATE_PROJECTS_JOBS_PROFORMA_FAILURE,
  payload: data,
});

export const createProjectsJobs = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createProjectsJobsProformaInitiate(payload));
              const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const proformaId = JSON.parse(localStorage.getItem("profomaId"))
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}/projectJobs`, payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(createProjectsJobsProformaSuccess(response.data));
      } else {
        dispatch(createProjectsJobsProformaFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createProjectsJobsProformaFailure(err));
      return err.response
    }
  };


//================================performa--step===1=====================


export const getProformastep1Initiate = () => ({
  type: proformaActionTypes.GET_PROFORMA_STEP1_INITIATE,
});

export const getProformastep1Success = (data) => ({
  type: proformaActionTypes.GET_PROFORMA_STEP2_SUCCESS,
  payload: data,
});

export const getProformastep1Failure = (data) => ({
  type: proformaActionTypes.GET_PROFORMA_STEP3_FAILURE,
  payload: data,
});


  export const getPerforma = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getProformastep1Initiate(payload));
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${payload}?currentStep=1&subStep=1`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getProformastep1Success(response.data));
      } else {
        dispatch(getProformastep1Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getProformastep1Failure(err));
      return err.response
    }
  };

//================================performa--block--step===1=====================


export const getProformablockstep1Initiate = () => ({
  type: proformaActionTypes.GET_PROFORMA_BLOCK_STEP1_INITIATE,
});

export const getProformablockstep1Success = (data) => ({
  type: proformaActionTypes.GET_PROFORMA_BLOCK_STEP1_SUCCESS,
  payload: data,
});

export const getProformablockstep1Failure = (data) => ({
  type: proformaActionTypes.GET_PROFORMA_BLOCK_STEP1_FAILURE,
  payload: data,
});


  export const getPerformastep1block = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getProformablockstep1Initiate(payload));
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${payload}?currentStep=2&subStep=1`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getProformablockstep1Success(response.data));
      } else {
        dispatch(getProformablockstep1Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getProformablockstep1Failure(err));
      return err.response
    }
  };

//================================performa--block--step===2=====================


export const getProformablockstep2Initiate = () => ({
  type: proformaActionTypes.GET_PROFORMA_BLOCK_STEP2_INITIATE,
});

export const getProformablockstep2Success = (data) => ({
  type: proformaActionTypes.GET_PROFORMA_BLOCK_STEP2_SUCCESS,
  payload: data,
});

export const getProformablockstep2Failure = (data) => ({
  type: proformaActionTypes.GET_PROFORMA_BLOCK_STEP2_FAILURE,
  payload: data,
});


  export const getPerformastep2block = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getProformablockstep2Initiate(payload));
      const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${payload}?currentStep=2&subStep=1`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getProformablockstep2Success(response.data));
      } else {
        dispatch(getProformablockstep2Failure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getProformablockstep2Failure(err));
      return err.response
    }
  };