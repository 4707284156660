import { update } from "lodash";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch ,useSelector} from "react-redux";
import { getFoundationType } from '../../redux/action/proformaAction';


const MultifamilyJobStep2 = ({
  MultifamilyJobStep2Modal,
  SetMultifamilyJobStep2Modal,
  SetMultifamilyJobStep1Modal,
  SetMultifamilyJobStep3Modal,
  SetMultifamilyJobStep4Modal,
  SetMultifamilyJobStep5Modal,
  SetMultifamilyJobStep6Modal,
  iState,
  UpdateState,
  setShow,
}) => {
  const handleMultifamilyJobStep2ModalClose = () => {
    SetMultifamilyJobStep2Modal(false);
    setShow(true);
  };
  const handleMultifamilyJobStep2ModalShow = () => {
    SetMultifamilyJobStep2Modal(true);
  };


  const dispatch = useDispatch()
  const {
    foundationListData,

    foundationTypeData,

  } = useSelector((state) => state.ProformaReducer);



  const [count, setCount] = useState(1);
  const [blocks, setBlocks] = useState(
    Array(+count).fill({
      blockDescription: "Block A",
      foundationType: 1,
      numOfUnits: 2,
      numOfStories: 2,
      units:[],
      stories: [
        {
          value: 222,
        },
      ],
    })
  );

  const handleCount = (e) => {
    setCount(e.target.value);

    let newArray;
    let localValue = count;
    let tempTotalPerson = [...blocks];
    let currentValue = Math.abs(localValue - e.target.value);

    if (count > e.target.value) {
      newArray = blocks.slice(0, e.target.value);
    } else {
      let tempArray = new Array(currentValue).fill({
        blockDescription: "Block A",
        foundationType: 1,
        numOfUnits: 0,
        numOfStories: 0,
        units:[],
        stories: [
          {
            value: 0,
          },
        ],
      });

      newArray = [...tempTotalPerson, ...tempArray];
    }
    setBlocks(newArray);
  };



  const handleSubmit =(e)=>{
    e.preventDefault()

    UpdateState({...iState, blocks:blocks})


    SetMultifamilyJobStep2Modal(false);
    SetMultifamilyJobStep3Modal(true);

  }

  useEffect(()=>{

    dispatch(getFoundationType());


  },[])

  return (
    <Modal show={MultifamilyJobStep2Modal} className="ModalBox LargeModal">
      <div class="modal-content BgCreate">
        <a
          class="CloseModal"
          onClick={handleMultifamilyJobStep2ModalClose}
          data-dismiss="modal"
        >
          ×
        </a>
        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Create Multi-Family Jobs</h4>
          </div>
          <div class="StepBox">
            <ul>
              <li class="active">
                <span class="Icon">
                  <img
                    src={require("../../assets/images/step_1.png")}
                    onClick={() => {
                      SetMultifamilyJobStep1Modal(true);
                      SetMultifamilyJobStep2Modal(false);
                    }}
                  />
                </span>
                <span class="Text">Details</span>
              </li>
              <li class="active">
                <span class="Icon FolderPermissionId ">
                  <img src={require("../../assets/images/blocks.png")} />
                </span>
                <span class="Text">Blocks</span>
              </li>
              <li>
                <span
                  class="Icon FolderPermissionId "
                  data-toggle="modal"
                  onClick={() => {
                    SetMultifamilyJobStep3Modal(true);
                    SetMultifamilyJobStep2Modal(false);
                  }}
                >
                  <img src={require("../../assets/images/units.png")} />
                </span>
                <span class="Text">Units </span>
              </li>
              <li>
                <span
                  class="Icon FolderPermissionId "
                  data-toggle="modal"
                  onClick={() => {
                    SetMultifamilyJobStep4Modal(true);
                    SetMultifamilyJobStep2Modal(false);
                  }}
                >
                  <img src={require("../../assets/images/step_8.png")} />
                </span>
                <span class="Text">
                  Common <br /> Buildings
                </span>
              </li>
              <li>
                <span
                  class="Icon FolderPermissionId "
                  data-toggle="modal"
                  onClick={() => {
                    SetMultifamilyJobStep5Modal(true);
                    SetMultifamilyJobStep2Modal(false);
                  }}
                >
                  <img src={require("../../assets/images/step_2.png")} />
                </span>
                <span class="Text">
                  Job <br /> Address
                </span>
              </li>
              <li>
                <span
                  class="Icon FolderPermissionId "
                  data-toggle="modal"
                  onClick={() => {
                    SetMultifamilyJobStep6Modal(true);
                    SetMultifamilyJobStep2Modal(false);
                  }}
                >
                  <img src={require("../../assets/images/step_7.png")} />
                </span>
                <span class="Text">Confirmation</span>
              </li>
            </ul>
          </div>
          <div class="FolderForm">
            <form>
              <div class="FolderFormCreateArea">
                <div class="FieldsMandatory">
                  <h6>
                    Fields with <span class="required">*</span> are mandatory
                  </h6>
                </div>
                <div class="ModalBigheading2 d-flex align-items-center mb-3">
                  <h3 class="mb-0">Enter Number of Blocks in the Project</h3>{" "}
                  <input
                    class="form-control ModalBigheadingBox"
                    onChange={handleCount}
                  />
                
                </div>
                <div class="EnterProjectTable">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Block Description</th>
                        <th>Foundation type</th>
                        <th># of Units Per Block</th>
                        <th># of Stories Per Block</th>
                      </tr>
                    </thead>
                    <tbody>
                      {blocks?.map((item, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>
                            <div class="form-group">
                              <input
                                type="text"
                                onChange={(e) => {
                                  blocks[i].blockDescription = e.target.value;
                                  let value = [...blocks];
                                  setBlocks(value);
                                }}
                                class="form-control"
                                placeholder="Block A"
                              />
                            </div>
                          </td>
                          <td>
                            <div class="CommonSelectBox">
                              <select
                                onChange={(e) => {
                                  blocks[i].foundationType = e.target.value;
                                  let value = [...blocks];
                                  setBlocks(value);
                                }}
                              >       
                                <option value="">Select Foundation Type</option>
                              {foundationTypeData?.data?.length > 0 &&
                                foundationTypeData?.data?.map((data, i) => {
                                  return (
                                    <option key={i} value={data?.id}>
                                      {data?.name}
                                    </option>
                                  );
                                })}
                                  </select>
                              <span>
                              </span>
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input
                                type="number"
                                onChange={(e) => {
                                  blocks[i].numOfUnits = e.target.value;
                                  let value = [...blocks];
                                  setBlocks(value);
                                }}
                                class="form-control"
                                placeholder="Units per block"
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input
                                type="number"
                                onChange={(e) => {
                                  blocks[i].numOfStories = e.target.value;
                                  let value = [...blocks];
                                  setBlocks(value);
                                }}
                                class="form-control"
                                placeholder="Insert number of stories"
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div class="PaginationArea">
                    <h6>Showing 1 to 10 of 27 entries</h6>
                    <h6>
                      <img src="images/leftarrow.png" /> 01{" "}
                      <img src="images/rightarrow.png" /> Out 10
                    </h6>
                  </div>
                </div>
              </div>

              <div class="Button d-flex justify-content-between align-items-center px-5">
                <button
                  class="Outline"
                  onClick={() => {
                    SetMultifamilyJobStep2Modal(false);
                    SetMultifamilyJobStep1Modal(true);
                  }}
                >
                  Back
                </button>
                <button
                  class="Create FolderPermissionId"
                  onClick={handleSubmit}
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MultifamilyJobStep2;
