import { todoAction } from "../actionTypes";
const initialState = {
  loader: false,
  todoListData: [],
  todoDetailsData:[],
  inProcessTaskList:[],
  completeTaskList:[],
  memberList:[],
  historyTaskList:[],
};

export default function todoReducer(state = initialState, { type, payload }) {
  switch (type) {
    case todoAction.TODO_JOB_LIST_INITIATE: {
      return {
        ...state,todoListData: [],
      };
    }
    case todoAction.TODO_JOB_LIST_SUCCESS: {
      return {
        ...state, todoListData: payload
      };
    }
    case todoAction.TODO_JOB_LIST_FAILURE: {
      return {
        ...state,todoListData: payload,
      };
    }

    case todoAction.TODO_HISTORY_LIST_INITIATE: {
      return {
        ...state,
        todoHistoryListData: [],
        loader: true,
      };
    }
    case todoAction.TODO_HISTORY_LIST_SUCCESS: {
      return {
        ...state,
        todoHistoryListData: payload,
        loader: false,
      };
    }
    case todoAction.TODO_HISTORY_LIST_FAILURE: {
      return {
        ...state,
        todoHistoryListData: payload,
        loader: false,
      };
    }  
    case todoAction.TODO_DETAILS_INITIATE: {
      return {
        ...state,
        todoDetailsData: [],
        loader: true,
      };
    }
    case todoAction.TODO_DETAILS_SUCCESS: {
      return {
        ...state,
        todoDetailsData: payload,
        loader: false,
      };
    }
    case todoAction.TODO_DETAILS_FAILURE: {
      return {
        ...state,
        todoDetailsData: payload.data,
        loader: false,
      };
    }

    case todoAction.TASK_MEMBER_LIST_INITIATE: {
      return {
        ...state,
        memberList: [],
        loader: true,
      };
    }
    case todoAction.TASK_MEMBER_LIST_SUCCESS: {
      return {
        ...state,
        memberList: payload,
        loader: false,
      };
    }
    case todoAction.TASK_MEMBER_LIST_FAILURE: {
      return {
        ...state,
        memberList: payload,
        loader: false,
      };
    }



    case todoAction.TASK_HISTORY_LIST_INITIATE: {
      return {
        ...state,historyTaskList: [],
      };
    }
    case todoAction.TASK_HISTORY_LIST_SUCCESS: {
      return {
        ...state, historyTaskList: payload
      };
    }
    case todoAction.TASK_HISTORY_LIST_FAILURE: {
      return {
        ...state,historyTaskList: payload,
      };
    }

    default:
      return state;
  }
}
