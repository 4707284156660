import React, { useState, useEffect } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import "../App.css";
import taskSquare from "../assets/images/task-square.png";
import tasking1 from "../assets/images/taskimg-1.png";
import construction from "../assets/images/construction.png";
import box1 from "../assets/images/box-1.png";
import box2 from "../assets/images/box-2.png";
import taskHistory from "../assets/images/task-history.png";
import exportToCalendar from "../assets/images/export-to-calendar.png";
import rightarrow2 from "../assets/images/rightarrow2.png";
import googleCalendar from "../assets/images/google-calendar.png";
import outlookCalendar from "../assets/images/outlook-calendar.png";
import SetReminder from "../assets/images/set-reminder.png";
import emailCalendar from "../assets/images/email-calendar.png";
import next_calendar from "../assets/images/next_calendar.png";
import OwlCarousel from "react-owl-carousel2";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getListProjectJobs } from "../redux/action/warrantyAction";
import {
  getAllLandDevelopmentHomelListAction,
  getAllMultiFamilyHomelListAction,
  getAllResidentaiHomelListAction,
  getAllTaskHomelListAction,
  getAllTodoHomelListAction,
  getIternaryHomeList,
  getLandDevelopmentProjectList,
  getLandPhaseList,
  getMultiBlocksList,
  getMultiFamilyProjectList,
} from "../redux/action/homeAction";
import {
  getAllListProjectJobs,
  getAllProjects,
} from "../redux/action/modelsAction";
import { checkArray } from "../utils/CheckType";

const initLeadState = {
  ProjectId: "",
};

const constructionInit = {
  jobListShow: false,
  insideJobListShow: false,
  changeOrderShow: false,
  designSelectionShow: false,
  clientViewConfigShow: false,
  accountingShow: false,
  warrantyShow: false,
  estimationShow: false,
  constructionShow: false,
  resdentalId: -1,

  multiWarrantyShow: false,
  multiJobUnitOpenIndex: -1,
  multiConstructionShow: false,
  multiDesignShow: false,
  multiChangeorderShow: false,
  multiEstimationShow: false,
  multiAccountingShow: false,
 
};

const MultiFamily = () => {
  const [iLeadState, updateLeadState] = useState(initLeadState);
  const { ProjectId } = iLeadState;
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const [iState, updateState] = useState(constructionInit);
  const {
    jobListShow,
    insideJobListShow,
    estimationShow,
    changeOrderShow,
    designSelectionShow,
    clientViewConfigShow,
    accountingShow,
    warrantyShow,
    constructionShow,
    resdentalId,

    multiWarrantyShow,
    multiJobUnitOpenIndex,
    multiConstructionShow,
    multiDesignShow,
    multiChangeorderShow,
    multiEstimationShow,
    multiAccountingShow,
   
  } = iState;
  const dispatch = useDispatch();
  const {
    residentialHomeListData,
    landDevelopmentHomeListData,
    multiFamilyHomeListData,
    taskHomeListData,
    todoHomeListData,
    iternaryHomeListData,
    multiblocksList,
    multiFamilyProjectList,
    landDevelopmentProjectList,
    landPhaseList,
  } = useSelector((state) => state.homeReducer);

  const { projectsList, listProjectJobs } = useSelector(
    (state) => state.modelsReducer
  );
  // console.log("multiblocksList", multiblocksList);
  console.log("landDevelopmentProjectList", landDevelopmentProjectList);
  console.log("landPhaseList", landPhaseList);

  

  const options = {
    items: 3,
    nav: true,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
    rewind: true,
    autoplay: false,
    margin: 20,
  };

  const handleHideShow = () => {
    updateState({ ...iState, insideJobListShow: jobListShow });
  };

  const { projectJobList } = useSelector((state) => state.warrantyReducer);

  const handleChangeOrderShow = () => {
    updateState({ ...iState, changeOrderShow: !changeOrderShow });
  };

  useEffect(() => {
    if (changeOrderShow) {
      dispatch(getAllProjects());
    }
  }, [changeOrderShow]);

  const handleConstructionShow = () => {
    updateState({ ...iState, constructionShow: !constructionShow });
  };

  useEffect(() => {
    if (constructionShow) {
      dispatch(getAllProjects());
    }
  }, [constructionShow]);

  useEffect(() => {
    if (iBuildLocalData.user.CompanyId != null) {
      // dispatch(getListProjectJobs(iBuildLocalData.user.CompanyId));
      dispatch(getAllLandDevelopmentHomelListAction(ProjectId));
      dispatch(getAllMultiFamilyHomelListAction(ProjectId));
      dispatch(getAllResidentaiHomelListAction(ProjectId));
      dispatch(getAllTodoHomelListAction(ProjectId));
      dispatch(getAllTaskHomelListAction(ProjectId));
      dispatch(getIternaryHomeList());
    }
  }, [ProjectId, dispatch]);
  const handleChildData = (data) => {
    dispatch(getListProjectJobs(iBuildLocalData.user.CompanyId));
    updateLeadState({ ...iLeadState, ProjectId: data });
  };

  //  useEffect(()=>{
  //   if(iBuildLocalData?.user?.CompanyId!=null){
  //     dispatch(getListProjectJobs(iBuildLocalData?.user?.CompanyId))
  //   }

  //  },[])

  const handleDesignSelectionShow = () => {
    // dispatch(getListProjectJobs(iBuildLocalData.user.CompanyId));
    updateState({ ...iState, designSelectionShow: !designSelectionShow });
  };

  useEffect(() => {
    if (designSelectionShow) {
      dispatch(getAllProjects());
    }
  }, [designSelectionShow]);

  const handleClientViewConfigShow = () => {
    // dispatch(getListProjectJobs(iBuildLocalData.user.CompanyId));
    updateState({ ...iState, clientViewConfigShow: !clientViewConfigShow });
  };

  const handleAccountingShow = () => {
    // dispatch(getListProjectJobs(iBuildLocalData.user.CompanyId));
    updateState({ ...iState, accountingShow: !accountingShow });
  };

  const handleEstimationShow = () => {
    // dispatch(getListProjectJobs(iBuildLocalData.user.CompanyId));
    updateState({ ...iState, estimationShow: !estimationShow });
  };

  useEffect(() => {
    if (estimationShow) {
      dispatch(getAllProjects());
    }
  }, [estimationShow]);

  const handleWarrantyShow = () => {
    updateState({ ...iState, warrantyShow: !warrantyShow });
  };

  useEffect(() => {
    if (warrantyShow) {
      dispatch(getAllProjects());
    }
  }, [warrantyShow]);

  const handleJobListByProject = (Id, Index) => {
    dispatch(getAllListProjectJobs(Id));
    updateState((prev) => ({
      ...prev,
      resdentalId: prev.resdentalId == Index ? -1 : Index,
    }));
  };

  //=======================multi-family==========================

  const handleMultiWarrantyShow = () => {
    updateState({
      ...iState,
      multiConstructionShow: false,
      multiChangeorderShow: false,
      multiDesignShow: false,
      multiEstimationShow: false,
      multiWarrantyShow: !multiWarrantyShow,
    });
  };

  useEffect(() => {
    if (multiWarrantyShow) {
      dispatch(getMultiFamilyProjectList());
    }
  }, [multiWarrantyShow]);

  const handleMultiBlockListByProject = (Id, Index) => {
    dispatch(getMultiBlocksList(Id));
    updateState((prev) => ({
      ...prev,
      resdentalId: prev.resdentalId == Index ? -1 : Index,
    }));
  };

  useEffect(() => {
    if (multiblocksList?.length > 0) {
      // console.log(
      //   "=====multiblocksListmultiblocksListmultiblocksListmultiblocksList===>",
      //   multiblocksList
      // );
    }
  }, [multiblocksList]);

  const handleMultiConstructionShow = () => {
    updateState({
      ...iState,
      multiConstructionShow: !multiConstructionShow,
      multiChangeorderShow: false,
      multiDesignShow: false,
      multiEstimationShow: false,
      multiWarrantyShow: false,
    });
  };

  useEffect(() => {
    if (multiConstructionShow) {
      dispatch(getMultiFamilyProjectList());
    }
  }, [multiConstructionShow]);

  const handleMultiDesignnShow = () => {
    updateState({
      ...iState,
      multiConstructionShow: false,
      multiChangeorderShow: false,
      multiDesignShow: !multiDesignShow,
      multiEstimationShow: false,
      multiWarrantyShow: false,
    });
  };

  useEffect(() => {
    if (multiDesignShow) {
      dispatch(getMultiFamilyProjectList());
    }
  }, [multiDesignShow]);

  const handleMultiChangeOrderShow = () => {
    updateState({
      ...iState,
      multiConstructionShow: false,
      multiChangeorderShow: !multiChangeorderShow,
      multiDesignShow: false,
      multiEstimationShow: false,
      multiWarrantyShow: false,
    });
  };

  useEffect(() => {
    if (multiChangeorderShow) {
      dispatch(getMultiFamilyProjectList());
    }
  }, [multiChangeorderShow]);

  const handleMultiEstimationrShow = () => {
    updateState({
      ...iState,
      multiConstructionShow: false,
      multiChangeorderShow: false,
      multiDesignShow: false,
      multiEstimationShow: !multiEstimationShow,
      multiWarrantyShow: false,
    });
  };

  useEffect(() => {
    if (multiEstimationShow) {
      dispatch(getMultiFamilyProjectList());
    }
  }, [multiEstimationShow]);


  const handleMultiAccountingShow = () => {
    updateState({
      ...iState,
      multiConstructionShow: false,
      multiChangeorderShow: false,
      multiDesignShow: false,
      multiEstimationShow:false,
      multiWarrantyShow: false,
      multiAccountingShow:!multiAccountingShow
    });
  };

  useEffect(() => {
    if (multiAccountingShow) {
      dispatch(getMultiFamilyProjectList());
    }
  }, [multiAccountingShow]);
 
  return (
    <div>
    <Header sendDataToParent={handleChildData} data="dfkldsjflsd" />
    <SideNav />
    <div className="WrapperArea">
      <div className="WrapperBox">
        <div className="MainTitle2">
          <h2>Welcome,</h2>
        </div>
        <div className="CommonBoxArea">
          <OwlCarousel options={options}>
            <div className="item">
              {/* <Link to="/individual_components_part2_User-WorkflowTasks"> */}
              <Link to="/individual-components-part-User-WorkflowTasks">
                <div className="CommonBox">
                  <div className="CommonBoxHeader">
                    <h6>Task</h6>
                    <h5>{taskHomeListData?.task?.count}</h5>
                    <figure>
                      <img src={taskSquare} />
                    </figure>
                  </div>
                  <div className="CommonBoxBody">
                    {checkArray(taskHomeListData?.task?.task) &&
                      taskHomeListData?.task?.task?.map((ele, i) => {
                        return (
                          <>
                            <div className="CommonBoxBodyUser">
                              <div className="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div className="">
                                  <h6>{ele?.description}</h6>
                                  <p>{ele?.JobType}</p>
                                </div>
                              </div>
                              <figure>
                                <img src={tasking1} />
                              </figure>
                            </div>
                          </>
                        );
                      })}
                  </div>
                  <div className="CommonBoxFooter">
                    <h5>{taskHomeListData?.task?.count}</h5>
                    <p>Total active tasks group</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="/notification">
                <div className="CommonBox">
                  <div className="CommonBoxHeader">
                    <h6>Notification</h6>
                    <h5>4,266</h5>
                    <figure>
                      <img src={construction} />
                    </figure>
                  </div>
                  <div className="CommonBoxBody">
                    <div className="CommonBoxBodyUser">
                      <div className="CommonBoxBodyTitle">
                        <span>MB</span>
                        <div className="">
                          <h6>John Doe</h6>
                          <p>Total active tasks group</p>
                        </div>
                      </div>
                      <figure>
                        <img src={tasking1} />
                      </figure>
                      <span className="CbbOverlay" />
                    </div>
                    <div className="CommonBoxBodyUser">
                      <div className="CommonBoxBodyTitle">
                        <span>MB</span>
                        <div className="">
                          <h6>John Doe</h6>
                          <p>Total active tasks group</p>
                        </div>
                      </div>
                      <figure>
                        <img src={tasking1} />
                      </figure>
                      <span className="CbbOverlay" />
                    </div>
                    <div className="CommonBoxBodyUser">
                      <div className="CommonBoxBodyTitle">
                        <span>MB</span>
                        <div className="">
                          <h6>John Doe</h6>
                          <p>Total active tasks group</p>
                        </div>
                      </div>
                      <figure>
                        <img src={tasking1} />
                      </figure>
                    </div>
                  </div>
                  <div className="CommonBoxFooter">
                    <h5>4,266</h5>
                    <p>Total active tasks group</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="/todos">
                <div className="CommonBox">
                  <div className="CommonBoxHeader">
                    <h6>Todo</h6>
                    <h5>{todoHomeListData?.todo?.count}</h5>
                    <figure>
                      <img src={taskSquare} />
                    </figure>
                  </div>
                  <div className="CommonBoxBody">
                    {checkArray(todoHomeListData?.todo?.todo) &&
                      todoHomeListData?.todo?.todo?.map((ele, i) => {
                        return (
                          <>
                            <div className="CommonBoxBodyUser">
                              <div className="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div className="">
                                  <h6>{ele?.description}</h6>
                                  <p>{ele?.JobType}</p>
                                </div>
                              </div>
                              <figure>
                                <img src={tasking1} />
                              </figure>
                            </div>
                          </>
                        );
                      })}
                  </div>
                  <div className="CommonBoxFooter">
                    <h5>{todoHomeListData?.todo?.count}</h5>
                    <p>Total active tasks group</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="/itinerary">
                <div className="CommonBox">
                  <div className="CommonBoxHeader">
                    <h6>Itinerary</h6>
                    <h5>{iternaryHomeListData?.itineraries?.count ?? 0}</h5>
                    <figure>
                      <img src={construction} />
                    </figure>
                  </div>
                  {iternaryHomeListData?.itineraries?.itineraries?.length >
                    0 &&
                    iternaryHomeListData?.itineraries?.itineraries?.map(
                      (item, ind) => {
                        return (
                          <>
                            <div className="CommonBoxBody">
                              <div className="CommonBoxBodyUser">
                                <div className="CommonBoxBodyTitle">
                                  <span>MB</span>
                                  <div className="">
                                    <h6>{item?.description?.slice(0, 30)}</h6>
                                    <p>{item?.JobType}</p>
                                  </div>
                                </div>
                                <figure>
                                  <img src={tasking1} />
                                </figure>
                                <span className="CbbOverlay" />
                              </div>
                            </div>
                          </>
                        );
                      }
                    )}

                  <div className="CommonBoxFooter">
                    <h5>{iternaryHomeListData?.itineraries?.count ?? 0}</h5>
                    <p>Total active tasks group</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="clear"></div>
          </OwlCarousel>
          <div className="clear"></div>
        </div>
        {/* residential owl carousel  */}

      
        {/* <div className='clear'></div> */}

        {/* multi family owl carousel  */}
        <div className="MultiFamilyCommonBoxArea">
          <div className="CommonBoxArea ">
            <div className="MainTitle">
              <h2>Multi-Family</h2>
            </div>
            {/* <div className="home-carousel owl-carousel owl-theme"> */}
            <OwlCarousel options={options}>
              <div className="item">
                <div
                  className="CommonBox CommonBoxWarranty"
                  onClick={() => handleMultiWarrantyShow()}
                >
                  <div className="CommonBoxHeader">
                    <h6>Warranty</h6>
                    <h5>{multiFamilyHomeListData?.warranties?.count}</h5>

                    <figure>
                      <img src={box1} />
                    </figure>
                  </div>
                  <div className="CommonBoxBody">
                    {checkArray(
                      multiFamilyHomeListData?.warranties?.warranties
                    ) &&
                      multiFamilyHomeListData?.warranties?.warranties?.map(
                        (ele, i) => {
                          return (
                            <>
                              <div className="CommonBoxBodyUser">
                                <div className="CommonBoxBodyTitle">
                                  <span>MB</span>
                                  <div className="">
                                    <h6>{ele?.subdividision}</h6>
                                    <p>{ele?.jobNumber}</p>
                                  </div>
                                </div>

                                <figure>
                                  <img src="images/taskimg-1.png" />
                                </figure>
                                <span className="CbbOverlay" />
                              </div>
                            </>
                          );
                        }
                      )}
                  </div>
                  <div className="CommonBoxFooter">
                    <h5>{multiFamilyHomeListData?.warranties?.count}</h5>
                    <p>Total active tasks group</p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div
                  className="CommonBox CommonBoxTable"
                  onClick={() => handleMultiConstructionShow()}
                >
                  <div className="CommonBoxHeader">
                    <h6>Construction</h6>
                    <h5>{multiFamilyHomeListData?.construction?.count}</h5>

                    <figure>
                      <img src={box2} />
                    </figure>
                  </div>
                  <div className="CommonBoxBody">
                    {checkArray(
                      multiFamilyHomeListData?.construction?.construction
                    ) &&
                      multiFamilyHomeListData?.construction?.construction?.map(
                        (ele, i) => {
                          return (
                            <>
                              <div className="CommonBoxBodyUser">
                                <div className="CommonBoxBodyTitle">
                                  <span>MB</span>
                                  <div className="">
                                    <h6>
                                      {ele?.ResidentialJob?.subdividision}
                                    </h6>
                                    <p>{ele?.ResidentialJob?.jobNumber}</p>
                                  </div>
                                </div>

                                <figure>
                                  <img src="images/taskimg-1.png" />
                                </figure>
                                <span className="CbbOverlay" />
                              </div>
                            </>
                          );
                        }
                      )}
                  </div>
                  <div className="CommonBoxFooter">
                    <h5>{multiFamilyHomeListData?.construction?.count}</h5>
                    <p>Total active tasks group</p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div
                  className="CommonBox"
                  onClick={() => handleMultiDesignnShow()}
                >
                  <div className="CommonBoxHeader">
                    {/* <Link to="/design-selections"> */}
                    <h6>Design Selections</h6>
                    <h5>
                      {multiFamilyHomeListData?.designSelections?.count}
                    </h5>
                    {/* </Link> */}
                    <figure>
                      <img src="images/box-6.png" />
                    </figure>
                  </div>
                  <div className="CommonBoxBody">
                    {checkArray(
                      multiFamilyHomeListData?.designSelections
                        ?.designSelections
                    ) &&
                      multiFamilyHomeListData?.designSelections?.designSelections?.map(
                        (ele, i) => {
                          return (
                            <>
                              <div className="CommonBoxBodyUser">
                                <div className="CommonBoxBodyTitle">
                                  <span>MB</span>
                                  <div className="">
                                    <h6>
                                      {ele?.ResidentialJob?.subdividision}
                                    </h6>
                                    <p>{ele?.ResidentialJob?.jobNumber}</p>
                                  </div>
                                </div>

                                <figure>
                                  <img src="images/taskimg-1.png" />
                                </figure>
                                <span className="CbbOverlay" />
                              </div>
                            </>
                          );
                        }
                      )}
                  </div>
                  <div className="CommonBoxFooter">
                    <h5>
                      {multiFamilyHomeListData?.designSelections?.count}
                    </h5>
                    <p>Total active tasks group</p>
                  </div>
                </div>
              </div>

              <div className="item">
                <div
                  className="CommonBox CommonBoxWarranty"
                  onClick={() => handleMultiChangeOrderShow()}
                >
                  <div className="CommonBoxHeader">
                    <h6>Change Order</h6>
                    <h5>{multiFamilyHomeListData?.changeOrder?.count}</h5>
                    <figure>
                      <img src="images/box-6.png" />
                    </figure>
                  </div>
                  <div className="CommonBoxBody">
                    {checkArray(
                      multiFamilyHomeListData?.changeOrder?.changeOrder
                    ) &&
                      multiFamilyHomeListData?.changeOrder?.changeOrder?.map(
                        (ele, i) => {
                          return (
                            <>
                              <div className="CommonBoxBodyUser">
                                <div className="CommonBoxBodyTitle">
                                  <span>MB</span>
                                  <div className="">
                                    <h6>
                                      {ele?.ResidentialJob?.subdividision}
                                    </h6>
                                    <p>{ele?.ResidentialJob?.jobNumber}</p>
                                  </div>
                                </div>

                                <figure>
                                  <img src="images/taskimg-1.png" />
                                </figure>
                                <span className="CbbOverlay" />
                              </div>
                            </>
                          );
                        }
                      )}
                  </div>
                  <div className="CommonBoxFooter">
                    <h5>{multiFamilyHomeListData?.changeOrder?.count}</h5>
                    <p>Total active tasks group</p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div
                  className="CommonBox CommonBoxWarranty"
                  onClick={() => handleMultiEstimationrShow()}
                >
                  <div className="CommonBoxHeader">
                    <h6>Estimation</h6>
                    <h5>{multiFamilyHomeListData?.estimation?.count}</h5>
                    <figure>
                      <img src="images/box-6.png" />
                    </figure>
                  </div>
                  <div className="CommonBoxBody">
                    {checkArray(
                      multiFamilyHomeListData?.estimation?.estimation
                    ) &&
                      multiFamilyHomeListData?.estimation?.estimation?.map(
                        (ele, i) => {
                          return (
                            <>
                              <div className="CommonBoxBodyUser">
                                <div className="CommonBoxBodyTitle">
                                  <span>MB</span>
                                  <div className="">
                                    <h6>
                                      {ele?.ResidentialJob?.subdividision}
                                    </h6>
                                    <p>{ele?.ResidentialJob?.jobNumber}</p>
                                  </div>
                                </div>

                                <figure>
                                  <img src="images/taskimg-1.png" />
                                </figure>
                                <span className="CbbOverlay" />
                              </div>
                            </>
                          );
                        }
                      )}
                  </div>
                  <div className="CommonBoxFooter">
                    <h5>{multiFamilyHomeListData?.estimation?.count}</h5>
                    <p>Total active tasks group</p>
                  </div>
                </div>
              </div>
              <div className="item">
                  <div
                    className="CommonBox CommonBoxWarranty"
                    onClick={() => handleMultiAccountingShow()}
                  >
                    <div className="CommonBoxHeader">
                      <h6>Accounting</h6>
                      <h5>{multiFamilyHomeListData?.accounting?.count}</h5>
                      <figure>
                        <img src="images/box-6.png" />
                      </figure>
                    </div>
                    <div className="CommonBoxBody">
                      {checkArray(
                        multiFamilyHomeListData?.accounting?.accounting
                      ) &&
                        multiFamilyHomeListData?.accounting?.accounting?.map(
                          (ele, i) => {
                            //  console.log('eleleleleel',ele)
                            return (
                              <>
                                <div className="CommonBoxBodyUser">
                                  <div className="CommonBoxBodyTitle">
                                    <span>MB</span>
                                    <div className="">
                                      <h6>{ele?.tranId}</h6>
                                      <p>{ele?.notes}</p>
                                    </div>
                                  </div>

                                  <figure>
                                    <img src="images/taskimg-1.png" />
                                  </figure>
                                  <span className="CbbOverlay" />
                                </div>
                              </>
                            );
                          }
                        )}
                    </div>
                    <div className="CommonBoxFooter">
                      <h5>{multiFamilyHomeListData?.estimation?.count}</h5>
                      <p>Total active tasks group</p>
                    </div>
                  </div>
                </div>
            </OwlCarousel>

            {/* </div> */}
          </div>
        </div>
        {multiWarrantyShow && (
          <>
            <div
              className="CommonTableArea MultiFamilyConstructionTable"
              style={{ display: "block" }}
            >
              <div className="JobTableHeaderOuter">
                <div className="JobTableHeaderInner">
                  <h5>Job/Unit List</h5>
                  <div className="Actions">
                    <a
                      href="javascript:void(0);"
                      data-toggle="modal"
                      data-target="#LegalDescriptionSettings"
                    >
                      <img src="images/icon-52.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/icon-51.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/icon-53.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/icon-54.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/icon-55.png" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="JobTableCommon">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Job Number</th>
                      <th>Build Type</th>
                      <th>Address</th>
                      <th>Lot</th>
                      <th>Block</th>
                      <th>Phase</th>
                      <th>Plan</th>
                      <th>Sub Division</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="addClasss JobTabletbodyRed">
                    {multiFamilyProjectList?.projects?.length > 0 ? (
                      multiFamilyProjectList?.projects?.map((item, i) => {
                        return (
                          <>
                            <tr key={i}>
                              <td>
                                {item.i}
                                <span className="Dots">
                                  <img src="images/dots.png" />
                                </span>{" "}
                              </td>
                              <td>
                                <a
                                  id="hidtableshow2"
                                  onClick={() =>
                                    handleMultiBlockListByProject(item.id, i)
                                  }
                                >
                                  {item.jobNumber}&nbsp;
                                  <img
                                    src={"images/down-arrow.png"}
                                    style={{
                                      transform: `rotate(${
                                        resdentalId === i ? "180deg" : "0deg"
                                      })`,
                                    }}
                                  />
                                </a>
                              </td>
                              <td>
                                <a>{item.projectType}</a>
                              </td>
                              <td>
                                <a>{item.projectAddress}</a>
                              </td>
                              <td>{item.lot}</td>
                              <td>{item.block}</td>
                              <td>{item.phase}</td>
                              <td>{item.plan ?? "N/A"}</td>

                              <td>
                                <a>{item.subdivisions?.map((x) => x)}</a>
                              </td>
                              <td>
                                <div className="TodoUser TodoUserHome">
                                  <div className="TodoUserJob">
                                    <div className="dropdown">
                                      <p data-toggle="dropdown">
                                        <span>
                                          <img src="images/three-dots.png" />
                                        </span>
                                      </p>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuOffset"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/add_build.png" />{" "}
                                            Add to/Edit Build
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="lead_contract_managment.html"
                                          >
                                            <img src="images/details.png" />
                                            Details
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/change_order.png" />{" "}
                                            Change Order
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="change_order_list.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/change_order.png" />
                                                Change orders List
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="design_selections.html"
                                          >
                                            <img src="images/design.png" />
                                            Design
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                            data-toggle="modal"
                                            data-targer="#ClientContractDetailsNoUnit"
                                          >
                                            <img src="images/sales.png" />
                                            Sales
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/estimation.png" />{" "}
                                            Estimation
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="budget_details.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/estimation.png" />
                                                Budget Details
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="construction.html"
                                          >
                                            <img src="images/construction_icon.png" />{" "}
                                            Construction
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_1.png" />{" "}
                                                Schedule View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_2.png" />{" "}
                                                Gantt View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_3.png" />{" "}
                                                Calendar View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_4.png" />{" "}
                                                List View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_5.png" />{" "}
                                                Baseline
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/warranty-dashboard"
                                          >
                                            <img src="images/warranty.png" />
                                            Warranty
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/models"
                                          >
                                            <img src="images/model.png" />{" "}
                                            Model
                                            <img src="images/rightarrow2.png" />
                                          </Link>
                                          {/* <ul className="dropdown-menu submenu">
        <li>
          <a
            to="javascript:void(0);"
            className="dropdown-item text-center"
            data-toggle="modal"
            data-target="#CreateNewModelHome"
          >
            <img src="images/model.png" /> Create New Model
          </a>
        </li>
      </ul> */}
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="reports.html"
                                          >
                                            <img src="images/reports.png" />
                                            Reports
                                          </a>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/client-view-config"
                                          >
                                            <img src="images/view_config.png" />
                                            Views Configs
                                          </Link>
                                        </li>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={10} className="padding_none">
                                <div
                                  className="JobBoxTable"
                                  style={{
                                    display:
                                      resdentalId == i ? "block" : "none",
                                  }}
                                >
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Unit Job Number</th>
                                        <th>Unit#</th>
                                        <th>Primary Client</th>
                                        <th>Square Footage</th>
                                        <th>Warranty Requests</th>
                                        <th>Description</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {resdentalId == i &&
                                        multiblocksList?.length > 0 &&
                                        multiblocksList?.map(
                                          (data, index) => {
                                            let tempMultiStoredata =
                                              data?.jobType == "MultiBlock" &&
                                              [
                                                ...data?.MultiUnits,
                                                ...data?.MultiStories,
                                              ]?.flat();
                                            // console.log(
                                            //   "tempMultiStoredata=====------>",
                                            //   tempMultiStoredata, data?.jobType
                                            // );
                                            return (
                                              <>
                                                <tr key={index}>
                                                  <td>
                                                    <Link
                                                      to="/warranty-dashboard"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data?.id ?? "N/A"}
                                                      <span className="Dots">
                                                        <img src="images/dots.png" />
                                                      </span>
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    {/* <Link
                                                  to="/change-order-list"
                                                  state={data}
                                                > */}
                                                    {data?.jobNumber ?? "N.A"}
                                                    {/* </Link> */}
                                                    &nbsp;
                                                    {data?.jobType ==
                                                      "MultiBlock" && (
                                                      <img
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          e.stopPropagation();
                                                          updateState(
                                                            (prev) => ({
                                                              ...prev,
                                                              multiJobUnitOpenIndex:
                                                                prev.multiJobUnitOpenIndex ==
                                                                index
                                                                  ? -1
                                                                  : index,
                                                            })
                                                          );
                                                        }}
                                                        src={
                                                          "images/down-arrow.png"
                                                        }
                                                        style={{
                                                          transform: `rotate(${
                                                            multiJobUnitOpenIndex ===
                                                            index
                                                              ? "180deg"
                                                              : "0deg"
                                                          })`,
                                                        }}
                                                      />
                                                    )}
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/warranty-dashboard"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data.unit ?? "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/warranty-dashboard"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data.client ?? "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/warranty-dashboard"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data.footage ?? "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/warranty-dashboard"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data?.warranties ??
                                                        "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/warranty-dashboard"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data?.description ??
                                                        "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    {/* <a to="javascript:void(0);">
                                    <i
                                      className="fa fa-caret-down"
                                      aria-hidden="true"
                                    />
                                  </a> */}
                                                  </td>
                                                </tr>
                                                {data?.jobType ==
                                                  "MultiBlock" &&
                                                  multiJobUnitOpenIndex ==
                                                    index && (
                                                    <tr>
                                                      <td colSpan={7}>
                                                        <div
                                                          className="ThirdLevelTable"
                                                          style={{
                                                            border:
                                                              "1px solid red",
                                                            borderRadius: 15,
                                                            padding: 5,
                                                          }}
                                                        >
                                                          <table>
                                                            <thead>
                                                              <tr>
                                                                <th>#</th>
                                                                <th>
                                                                  Unit /
                                                                  Common Area
                                                                  Job Number
                                                                </th>
                                                                <th>
                                                                  Unit /
                                                                  Common Area
                                                                  #
                                                                </th>
                                                                <th>
                                                                  Primary
                                                                  Client
                                                                </th>
                                                                <th>
                                                                  Square
                                                                  Footage
                                                                  Description
                                                                </th>
                                                                <th>
                                                                  Description
                                                                </th>
                                                                <th>
                                                                  Action
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {tempMultiStoredata?.length >
                                                                0 &&
                                                                tempMultiStoredata?.map(
                                                                  (
                                                                    itemm,
                                                                    indd
                                                                  ) => {
                                                                    // console.log("itemm---->",itemm);
                                                                    return (
                                                                      <tr>
                                                                        <td>
                                                                          <div className="d-flex">
                                                                            <Link
                                                                              to="/warranty-dashboard"
                                                                              state={{
                                                                                ...data,
                                                                                ProjectId:
                                                                                  item.id,
                                                                              }}
                                                                            >
                                                                              {
                                                                                itemm?.id
                                                                              }
                                                                            </Link>
                                                                            <div className="dropdown">
                                                                              <span
                                                                                className="Dots"
                                                                                data-toggle="dropdown"
                                                                                aria-haspopup="true"
                                                                                aria-expanded="false"
                                                                              >
                                                                                <img src="images/dots.png" />
                                                                              </span>
                                                                              {/* <div className="dropdown-menu">
                                                                                <a
                                                                                  className="dropdown-item"
                                                                                  href="archive_residential.html"
                                                                                >
                                                                                  {" "}
                                                                                  <img src="images/archive_job.png" />{" "}
                                                                                  Archive
                                                                                  Job
                                                                                </a>
                                                                                <a
                                                                                  className="dropdown-item"
                                                                                  href="archive_residential.html"
                                                                                >
                                                                                  {" "}
                                                                                  <img src="images/home_warranty.png" />{" "}
                                                                                  Show
                                                                                  in
                                                                                  Warranty
                                                                                  &nbsp;&nbsp;
                                                                                </a>
                                                                              </div> */}
                                                                            </div>
                                                                          </div>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/warranty-dashboard"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.jobNumber ??
                                                                              "N/A"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/warranty-dashboard"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.unitNumber ??
                                                                              "N/A"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/warranty-dashboard"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.name ??
                                                                              "N/A"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/warranty-dashboard"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.unitsSqFt ??
                                                                              itemm?.value ??
                                                                              "0"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/warranty-dashboard"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.jobType ??
                                                                              "N/A"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <div className="TodoUser TodoUserHome text-center">
                                                                            <div className="TodoUserJob">
                                                                              <div className="dropdown">
                                                                                <p data-toggle="dropdown">
                                                                                  <span>
                                                                                    <img src="images/three-dots.png" />
                                                                                  </span>
                                                                                </p>
                                                                                <div
                                                                                  className="dropdown-menu"
                                                                                  aria-labelledby="dropdownMenuOffset"
                                                                                >
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="javascript:void(0);"
                                                                                    >
                                                                                      <img src="images/add_build.png" />{" "}
                                                                                      Add
                                                                                      to/Edit
                                                                                      Build
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="lead_contract_managment.html"
                                                                                    >
                                                                                      <img src="images/details.png" />
                                                                                      Details
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="javascript:void(0);"
                                                                                    >
                                                                                      <img src="images/change_order.png" />{" "}
                                                                                      Change
                                                                                      Order
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                                      <li>
                                                                                        <a
                                                                                          href="change_order_list.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/change_order.png" />
                                                                                          Change
                                                                                          orders
                                                                                          List
                                                                                        </a>
                                                                                      </li>
                                                                                    </ul>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="design_selections.html"
                                                                                    >
                                                                                      <img src="images/design.png" />
                                                                                      Design
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="javascript:void(0);"
                                                                                      data-toggle="modal"
                                                                                      data-targer="#ClientContractDetailsNoUnit"
                                                                                    >
                                                                                      <img src="images/sales.png" />
                                                                                      Sales
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="javascript:void(0);"
                                                                                    >
                                                                                      <img src="images/estimation.png" />{" "}
                                                                                      Estimation
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                                      <li>
                                                                                        <a
                                                                                          href="budget_details.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/estimation.png" />
                                                                                          Budget
                                                                                          Details
                                                                                        </a>
                                                                                      </li>
                                                                                    </ul>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="construction.html"
                                                                                    >
                                                                                      <img src="images/construction_icon.png" />{" "}
                                                                                      Construction
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_1.png" />{" "}
                                                                                          Schedule
                                                                                          View
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_2.png" />{" "}
                                                                                          Gantt
                                                                                          View
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_3.png" />{" "}
                                                                                          Calendar
                                                                                          View
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_4.png" />{" "}
                                                                                          List
                                                                                          View
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_5.png" />{" "}
                                                                                          Baseline
                                                                                        </a>
                                                                                      </li>
                                                                                    </ul>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="Warranty_Dashboard.html"
                                                                                    >
                                                                                      <img src="images/warranty.png" />
                                                                                      Warranty
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="models.html"
                                                                                    >
                                                                                      <img src="images/model.png" />{" "}
                                                                                      Model
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                    <ul className="dropdown-menu submenu">
                                                                                      <li>
                                                                                        <a
                                                                                          href="javascript:void(0);"
                                                                                          className="dropdown-item text-center"
                                                                                          data-toggle="modal"
                                                                                          data-target="#CreateNewModelHome"
                                                                                        >
                                                                                          <img src="images/model.png" />{" "}
                                                                                          Create
                                                                                          New
                                                                                          Model
                                                                                        </a>
                                                                                      </li>
                                                                                    </ul>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="reports.html"
                                                                                    >
                                                                                      <img src="images/reports.png" />
                                                                                      Reports
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="client-view-config.html"
                                                                                    >
                                                                                      <img src="images/view_config.png" />
                                                                                      Views
                                                                                      Configs
                                                                                    </a>
                                                                                  </li>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                    );
                                                                  }
                                                                )}
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  )}
                                              </>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}

        {multiConstructionShow && (
          <>
            <div
              className="CommonTableArea MultiFamilyConstructionTable"
              style={{ display: "block" }}
            >
              <div className="JobTableHeaderOuter">
                <div className="JobTableHeaderInner">
                  <h5>Job/Unit List</h5>
                  <div className="Actions">
                    <a
                      href="javascript:void(0);"
                      data-toggle="modal"
                      data-target="#LegalDescriptionSettings"
                    >
                      <img src="images/icon-52.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/icon-51.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/icon-53.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/icon-54.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/icon-55.png" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="JobTableCommon">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Job Number</th>
                      <th>Build Type</th>
                      <th>Address</th>
                      <th>Lot</th>
                      <th>Block</th>
                      <th>Phase</th>
                      <th>Plan</th>
                      <th>Sub Division</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="addClasss JobTabletbodyRed">
                    {multiFamilyProjectList?.projects?.length > 0 ? (
                      multiFamilyProjectList?.projects?.map((item, i) => {
                        return (
                          <>
                            <tr key={i}>
                              <td>
                                {item.i}
                                <span className="Dots">
                                  <img src="images/dots.png" />
                                </span>{" "}
                              </td>
                              <td>
                                <a
                                  id="hidtableshow2"
                                  onClick={() =>
                                    handleMultiBlockListByProject(item.id, i)
                                  }
                                >
                                  {item.jobNumber}&nbsp;
                                  <img
                                    src={"images/down-arrow.png"}
                                    style={{
                                      transform: `rotate(${
                                        resdentalId === i ? "180deg" : "0deg"
                                      })`,
                                    }}
                                  />
                                </a>
                              </td>
                              <td>
                                <a>{item.projectType}</a>
                              </td>
                              <td>
                                <a>{item.projectAddress}</a>
                              </td>
                              <td>{item.lot}</td>
                              <td>{item.block}</td>
                              <td>{item.phase}</td>
                              <td>{item.plan ?? "N/A"}</td>

                              <td>
                                <a>{item.subdivisions?.map((x) => x)}</a>
                              </td>
                              <td>
                                <div className="TodoUser TodoUserHome">
                                  <div className="TodoUserJob">
                                    <div className="dropdown">
                                      <p data-toggle="dropdown">
                                        <span>
                                          <img src="images/three-dots.png" />
                                        </span>
                                      </p>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuOffset"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/add_build.png" />{" "}
                                            Add to/Edit Build
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="lead_contract_managment.html"
                                          >
                                            <img src="images/details.png" />
                                            Details
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/change_order.png" />{" "}
                                            Change Order
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="change_order_list.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/change_order.png" />
                                                Change orders List
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="design_selections.html"
                                          >
                                            <img src="images/design.png" />
                                            Design
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                            data-toggle="modal"
                                            data-targer="#ClientContractDetailsNoUnit"
                                          >
                                            <img src="images/sales.png" />
                                            Sales
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/estimation.png" />{" "}
                                            Estimation
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="budget_details.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/estimation.png" />
                                                Budget Details
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="construction.html"
                                          >
                                            <img src="images/construction_icon.png" />{" "}
                                            Construction
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_1.png" />{" "}
                                                Schedule View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_2.png" />{" "}
                                                Gantt View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_3.png" />{" "}
                                                Calendar View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_4.png" />{" "}
                                                List View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_5.png" />{" "}
                                                Baseline
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/warranty-dashboard"
                                          >
                                            <img src="images/warranty.png" />
                                            Warranty
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/models"
                                          >
                                            <img src="images/model.png" />{" "}
                                            Model
                                            <img src="images/rightarrow2.png" />
                                          </Link>
                                          {/* <ul className="dropdown-menu submenu">
        <li>
          <a
            to="javascript:void(0);"
            className="dropdown-item text-center"
            data-toggle="modal"
            data-target="#CreateNewModelHome"
          >
            <img src="images/model.png" /> Create New Model
          </a>
        </li>
      </ul> */}
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="reports.html"
                                          >
                                            <img src="images/reports.png" />
                                            Reports
                                          </a>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/client-view-config"
                                          >
                                            <img src="images/view_config.png" />
                                            Views Configs
                                          </Link>
                                        </li>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={10} className="padding_none">
                                <div
                                  className="JobBoxTable"
                                  style={{
                                    display:
                                      resdentalId == i ? "block" : "none",
                                  }}
                                >
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Unit Job Number</th>
                                        <th>Unit#</th>
                                        <th>Primary Client</th>
                                        <th>Square Footage</th>
                                        <th>Warranty Requests</th>
                                        <th>Description</th>
                                        <th />
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {resdentalId == i &&
                                        multiblocksList?.length > 0 &&
                                        multiblocksList?.map(
                                          (data, index) => {
                                            let tempMultiStoredata =
                                              data?.jobType == "MultiBlock" &&
                                              [
                                                ...data?.MultiUnits,
                                                ...data?.MultiStories,
                                              ]?.flat();
                                            // console.log(
                                            //   "tempMultiStoredata=====------>",
                                            //   tempMultiStoredata, data?.jobType
                                            // );
                                            return (
                                              <>
                                                <tr key={index}>
                                                  <td>
                                                    <Link
                                                      to="/construction"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data?.id ?? "N/A"}
                                                      <span className="Dots">
                                                        <img src="images/dots.png" />
                                                      </span>
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    {/* <Link
                                                  to="/change-order-list"
                                                  state={data}
                                                > */}
                                                    {data?.jobNumber ?? "N.A"}
                                                    {/* </Link> */}
                                                    &nbsp;
                                                    {data?.jobType ==
                                                      "MultiBlock" && (
                                                      <img
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          e.stopPropagation();
                                                          updateState(
                                                            (prev) => ({
                                                              ...prev,
                                                              multiJobUnitOpenIndex:
                                                                prev.multiJobUnitOpenIndex ==
                                                                index
                                                                  ? -1
                                                                  : index,
                                                            })
                                                          );
                                                        }}
                                                        src={
                                                          "images/down-arrow.png"
                                                        }
                                                        style={{
                                                          transform: `rotate(${
                                                            multiJobUnitOpenIndex ===
                                                            index
                                                              ? "180deg"
                                                              : "0deg"
                                                          })`,
                                                        }}
                                                      />
                                                    )}
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/construction"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data.unit ?? "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/construction"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data.client ?? "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/construction"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data.footage ?? "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/construction"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data?.warranties ??
                                                        "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/construction"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data?.description ??
                                                        "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    {/* <a to="javascript:void(0);">
                                    <i
                                      className="fa fa-caret-down"
                                      aria-hidden="true"
                                    />
                                  </a> */}
                                                  </td>
                                                </tr>
                                                {data?.jobType ==
                                                  "MultiBlock" &&
                                                  multiJobUnitOpenIndex ==
                                                    index && (
                                                    <tr>
                                                      <td colSpan={7}>
                                                        <div
                                                          className="ThirdLevelTable"
                                                          style={{
                                                            border:
                                                              "1px solid red",
                                                            borderRadius: 15,
                                                            padding: 5,
                                                          }}
                                                        >
                                                          <table>
                                                            <thead>
                                                              <tr>
                                                                <th>#</th>
                                                                <th>
                                                                  Unit /
                                                                  Common Area
                                                                  Job Number
                                                                </th>
                                                                <th>
                                                                  Unit /
                                                                  Common Area
                                                                  #
                                                                </th>
                                                                <th>
                                                                  Primary
                                                                  Client
                                                                </th>
                                                                <th>
                                                                  Square
                                                                  Footage
                                                                  Description
                                                                </th>
                                                                <th>
                                                                  Description
                                                                </th>
                                                                <th>
                                                                  Action
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {tempMultiStoredata?.length >
                                                                0 &&
                                                                tempMultiStoredata?.map(
                                                                  (
                                                                    itemm,
                                                                    indd
                                                                  ) => {
                                                                    // console.log("itemm---->",itemm);
                                                                    return (
                                                                      <tr>
                                                                        <td>
                                                                          <div className="d-flex">
                                                                            <Link
                                                                              to="/construction"
                                                                              state={{
                                                                                ...data,
                                                                                ProjectId:
                                                                                  item.id,
                                                                              }}
                                                                            >
                                                                              {
                                                                                itemm?.id
                                                                              }
                                                                            </Link>
                                                                            <div className="dropdown">
                                                                              <span
                                                                                className="Dots"
                                                                                data-toggle="dropdown"
                                                                                aria-haspopup="true"
                                                                                aria-expanded="false"
                                                                              >
                                                                                <img src="images/dots.png" />
                                                                              </span>
                                                                              {/* <div className="dropdown-menu">
                                                                                <a
                                                                                  className="dropdown-item"
                                                                                  href="archive_residential.html"
                                                                                >
                                                                                  {" "}
                                                                                  <img src="images/archive_job.png" />{" "}
                                                                                  Archive
                                                                                  Job
                                                                                </a>
                                                                                <a
                                                                                  className="dropdown-item"
                                                                                  href="archive_residential.html"
                                                                                >
                                                                                  {" "}
                                                                                  <img src="images/home_warranty.png" />{" "}
                                                                                  Show
                                                                                  in
                                                                                  Warranty
                                                                                  &nbsp;&nbsp;
                                                                                </a>
                                                                              </div> */}
                                                                            </div>
                                                                          </div>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/construction"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.jobNumber ??
                                                                              "N/A"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/construction"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.unitNumber ??
                                                                              "N/A"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/construction"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.name ??
                                                                              "N/A"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/construction"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.unitsSqFt ??
                                                                              itemm?.value ??
                                                                              "0"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/construction"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.jobType ??
                                                                              "N/A"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <div className="TodoUser TodoUserHome text-center">
                                                                            <div className="TodoUserJob">
                                                                              <div className="dropdown">
                                                                                <p data-toggle="dropdown">
                                                                                  <span>
                                                                                    <img src="images/three-dots.png" />
                                                                                  </span>
                                                                                </p>
                                                                                <div
                                                                                  className="dropdown-menu"
                                                                                  aria-labelledby="dropdownMenuOffset"
                                                                                >
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="javascript:void(0);"
                                                                                    >
                                                                                      <img src="images/add_build.png" />{" "}
                                                                                      Add
                                                                                      to/Edit
                                                                                      Build
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="lead_contract_managment.html"
                                                                                    >
                                                                                      <img src="images/details.png" />
                                                                                      Details
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="javascript:void(0);"
                                                                                    >
                                                                                      <img src="images/change_order.png" />{" "}
                                                                                      Change
                                                                                      Order
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                                      <li>
                                                                                        <a
                                                                                          href="change_order_list.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/change_order.png" />
                                                                                          Change
                                                                                          orders
                                                                                          List
                                                                                        </a>
                                                                                      </li>
                                                                                    </ul>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="design_selections.html"
                                                                                    >
                                                                                      <img src="images/design.png" />
                                                                                      Design
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="javascript:void(0);"
                                                                                      data-toggle="modal"
                                                                                      data-targer="#ClientContractDetailsNoUnit"
                                                                                    >
                                                                                      <img src="images/sales.png" />
                                                                                      Sales
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="javascript:void(0);"
                                                                                    >
                                                                                      <img src="images/estimation.png" />{" "}
                                                                                      Estimation
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                                      <li>
                                                                                        <a
                                                                                          href="budget_details.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/estimation.png" />
                                                                                          Budget
                                                                                          Details
                                                                                        </a>
                                                                                      </li>
                                                                                    </ul>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="construction.html"
                                                                                    >
                                                                                      <img src="images/construction_icon.png" />{" "}
                                                                                      Construction
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_1.png" />{" "}
                                                                                          Schedule
                                                                                          View
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_2.png" />{" "}
                                                                                          Gantt
                                                                                          View
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_3.png" />{" "}
                                                                                          Calendar
                                                                                          View
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_4.png" />{" "}
                                                                                          List
                                                                                          View
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_5.png" />{" "}
                                                                                          Baseline
                                                                                        </a>
                                                                                      </li>
                                                                                    </ul>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="Warranty_Dashboard.html"
                                                                                    >
                                                                                      <img src="images/warranty.png" />
                                                                                      Warranty
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="models.html"
                                                                                    >
                                                                                      <img src="images/model.png" />{" "}
                                                                                      Model
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                    <ul className="dropdown-menu submenu">
                                                                                      <li>
                                                                                        <a
                                                                                          href="javascript:void(0);"
                                                                                          className="dropdown-item text-center"
                                                                                          data-toggle="modal"
                                                                                          data-target="#CreateNewModelHome"
                                                                                        >
                                                                                          <img src="images/model.png" />{" "}
                                                                                          Create
                                                                                          New
                                                                                          Model
                                                                                        </a>
                                                                                      </li>
                                                                                    </ul>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="reports.html"
                                                                                    >
                                                                                      <img src="images/reports.png" />
                                                                                      Reports
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="client-view-config.html"
                                                                                    >
                                                                                      <img src="images/view_config.png" />
                                                                                      Views
                                                                                      Configs
                                                                                    </a>
                                                                                  </li>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                    );
                                                                  }
                                                                )}
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  )}
                                              </>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}

        {multiDesignShow && (
          <>
            <div
              className="CommonTableArea MultiFamilyConstructionTable"
              style={{ display: "block" }}
            >
              <div className="JobTableHeaderOuter">
                <div className="JobTableHeaderInner">
                  <h5>Job/Unit List</h5>
                  <div className="Actions">
                    <a
                      href="javascript:void(0);"
                      data-toggle="modal"
                      data-target="#LegalDescriptionSettings"
                    >
                      <img src="images/icon-52.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/icon-51.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/icon-53.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/icon-54.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/icon-55.png" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="JobTableCommon">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Job Number</th>
                      <th>Build Type</th>
                      <th>Address</th>
                      <th>Lot</th>
                      <th>Block</th>
                      <th>Phase</th>
                      <th>Plan</th>
                      <th>Sub Division</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="addClasss JobTabletbodyRed">
                    {multiFamilyProjectList?.projects?.length > 0 ? (
                      multiFamilyProjectList?.projects?.map((item, i) => {
                        return (
                          <>
                            <tr key={i}>
                              <td>
                                {item.i}
                                <span className="Dots">
                                  <img src="images/dots.png" />
                                </span>{" "}
                              </td>
                              <td>
                                <a
                                  id="hidtableshow2"
                                  onClick={() =>
                                    handleMultiBlockListByProject(item.id, i)
                                  }
                                >
                                  {item.jobNumber}&nbsp;
                                  <img
                                    src={"images/down-arrow.png"}
                                    style={{
                                      transform: `rotate(${
                                        resdentalId === i ? "180deg" : "0deg"
                                      })`,
                                    }}
                                  />
                                </a>
                              </td>
                              <td>
                                <a>{item.projectType}</a>
                              </td>
                              <td>
                                <a>{item.projectAddress}</a>
                              </td>
                              <td>{item.lot}</td>
                              <td>{item.block}</td>
                              <td>{item.phase}</td>
                              <td>{item.plan ?? "N/A"}</td>

                              <td>
                                <a>{item.subdivisions?.map((x) => x)}</a>
                              </td>
                              <td>
                                <div className="TodoUser TodoUserHome">
                                  <div className="TodoUserJob">
                                    <div className="dropdown">
                                      <p data-toggle="dropdown">
                                        <span>
                                          <img src="images/three-dots.png" />
                                        </span>
                                      </p>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuOffset"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/add_build.png" />{" "}
                                            Add to/Edit Build
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="lead_contract_managment.html"
                                          >
                                            <img src="images/details.png" />
                                            Details
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/change_order.png" />{" "}
                                            Change Order
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="change_order_list.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/change_order.png" />
                                                Change orders List
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="design_selections.html"
                                          >
                                            <img src="images/design.png" />
                                            Design
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                            data-toggle="modal"
                                            data-targer="#ClientContractDetailsNoUnit"
                                          >
                                            <img src="images/sales.png" />
                                            Sales
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/estimation.png" />{" "}
                                            Estimation
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="budget_details.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/estimation.png" />
                                                Budget Details
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="construction.html"
                                          >
                                            <img src="images/construction_icon.png" />{" "}
                                            Construction
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_1.png" />{" "}
                                                Schedule View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_2.png" />{" "}
                                                Gantt View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_3.png" />{" "}
                                                Calendar View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_4.png" />{" "}
                                                List View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_5.png" />{" "}
                                                Baseline
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/warranty-dashboard"
                                          >
                                            <img src="images/warranty.png" />
                                            Warranty
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/models"
                                          >
                                            <img src="images/model.png" />{" "}
                                            Model
                                            <img src="images/rightarrow2.png" />
                                          </Link>
                                          {/* <ul className="dropdown-menu submenu">
        <li>
          <a
            to="javascript:void(0);"
            className="dropdown-item text-center"
            data-toggle="modal"
            data-target="#CreateNewModelHome"
          >
            <img src="images/model.png" /> Create New Model
          </a>
        </li>
      </ul> */}
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="reports.html"
                                          >
                                            <img src="images/reports.png" />
                                            Reports
                                          </a>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/client-view-config"
                                          >
                                            <img src="images/view_config.png" />
                                            Views Configs
                                          </Link>
                                        </li>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={10} className="padding_none">
                                <div
                                  className="JobBoxTable"
                                  style={{
                                    display:
                                      resdentalId == i ? "block" : "none",
                                  }}
                                >
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Unit Job Number</th>
                                        <th>Unit#</th>
                                        <th>Primary Client</th>
                                        <th>Square Footage</th>
                                        <th>Warranty Requests</th>
                                        <th>Description</th>
                                        <th />
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {resdentalId == i &&
                                        multiblocksList?.length > 0 &&
                                        multiblocksList?.map(
                                          (data, index) => {
                                            let tempMultiStoredata =
                                              data?.jobType == "MultiBlock" &&
                                              [
                                                ...data?.MultiUnits,
                                                ...data?.MultiStories,
                                              ]?.flat();
                                            // console.log(
                                            //   "tempMultiStoredata=====------>",
                                            //   tempMultiStoredata, data?.jobType
                                            // );
                                            return (
                                              <>
                                                <tr key={index}>
                                                  <td>
                                                    <Link
                                                      to="/design-selections"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data?.id ?? "N/A"}
                                                      <span className="Dots">
                                                        <img src="images/dots.png" />
                                                      </span>
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    {/* <Link
                                                  to="/change-order-list"
                                                  state={data}
                                                > */}
                                                    {data?.jobNumber ?? "N.A"}
                                                    {/* </Link> */}
                                                    &nbsp;
                                                    {data?.jobType ==
                                                      "MultiBlock" && (
                                                      <img
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          e.stopPropagation();
                                                          updateState(
                                                            (prev) => ({
                                                              ...prev,
                                                              multiJobUnitOpenIndex:
                                                                prev.multiJobUnitOpenIndex ==
                                                                index
                                                                  ? -1
                                                                  : index,
                                                            })
                                                          );
                                                        }}
                                                        src={
                                                          "images/down-arrow.png"
                                                        }
                                                        style={{
                                                          transform: `rotate(${
                                                            multiJobUnitOpenIndex ===
                                                            index
                                                              ? "180deg"
                                                              : "0deg"
                                                          })`,
                                                        }}
                                                      />
                                                    )}
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/design-selections"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data.unit ?? "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/design-selections"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data.client ?? "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/design-selections"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data.footage ?? "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/design-selections"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data?.warranties ??
                                                        "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/design-selections"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data?.description ??
                                                        "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    {/* <a to="javascript:void(0);">
                                    <i
                                      className="fa fa-caret-down"
                                      aria-hidden="true"
                                    />
                                  </a> */}
                                                  </td>
                                                </tr>
                                                {data?.jobType ==
                                                  "MultiBlock" &&
                                                  multiJobUnitOpenIndex ==
                                                    index && (
                                                    <tr>
                                                      <td colSpan={7}>
                                                        <div
                                                          className="ThirdLevelTable"
                                                          style={{
                                                            border:
                                                              "1px solid red",
                                                            borderRadius: 15,
                                                            padding: 5,
                                                          }}
                                                        >
                                                          <table>
                                                            <thead>
                                                              <tr>
                                                                <th>#</th>
                                                                <th>
                                                                  Unit /
                                                                  Common Area
                                                                  Job Number
                                                                </th>
                                                                <th>
                                                                  Unit /
                                                                  Common Area
                                                                  #
                                                                </th>
                                                                <th>
                                                                  Primary
                                                                  Client
                                                                </th>
                                                                <th>
                                                                  Square
                                                                  Footage
                                                                  Description
                                                                </th>
                                                                <th>
                                                                  Description
                                                                </th>
                                                                <th>
                                                                  Action
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {tempMultiStoredata?.length >
                                                                0 &&
                                                                tempMultiStoredata?.map(
                                                                  (
                                                                    itemm,
                                                                    indd
                                                                  ) => {
                                                                    // console.log("itemm---->",itemm);
                                                                    return (
                                                                      <tr>
                                                                        <td>
                                                                          <div className="d-flex">
                                                                            <Link
                                                                              to="/design-selections"
                                                                              state={{
                                                                                ...data,
                                                                                ProjectId:
                                                                                  item.id,
                                                                              }}
                                                                            >
                                                                              {
                                                                                itemm?.id
                                                                              }
                                                                            </Link>
                                                                            <div className="dropdown">
                                                                              <span
                                                                                className="Dots"
                                                                                data-toggle="dropdown"
                                                                                aria-haspopup="true"
                                                                                aria-expanded="false"
                                                                              >
                                                                                <img src="images/dots.png" />
                                                                              </span>
                                                                              {/* <div className="dropdown-menu">
                                                                                <a
                                                                                  className="dropdown-item"
                                                                                  href="archive_residential.html"
                                                                                >
                                                                                  {" "}
                                                                                  <img src="images/archive_job.png" />{" "}
                                                                                  Archive
                                                                                  Job
                                                                                </a>
                                                                                <a
                                                                                  className="dropdown-item"
                                                                                  href="archive_residential.html"
                                                                                >
                                                                                  {" "}
                                                                                  <img src="images/home_warranty.png" />{" "}
                                                                                  Show
                                                                                  in
                                                                                  Warranty
                                                                                  &nbsp;&nbsp;
                                                                                </a>
                                                                              </div> */}
                                                                            </div>
                                                                          </div>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/design-selections"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.jobNumber ??
                                                                              "N/A"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/design-selections"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.unitNumber ??
                                                                              "N/A"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/design-selections"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.name ??
                                                                              "N/A"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/design-selections"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.unitsSqFt ??
                                                                              itemm?.value ??
                                                                              "0"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/design-selections"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.jobType ??
                                                                              "N/A"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <div className="TodoUser TodoUserHome text-center">
                                                                            <div className="TodoUserJob">
                                                                              <div className="dropdown">
                                                                                <p data-toggle="dropdown">
                                                                                  <span>
                                                                                    <img src="images/three-dots.png" />
                                                                                  </span>
                                                                                </p>
                                                                                <div
                                                                                  className="dropdown-menu"
                                                                                  aria-labelledby="dropdownMenuOffset"
                                                                                >
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="javascript:void(0);"
                                                                                    >
                                                                                      <img src="images/add_build.png" />{" "}
                                                                                      Add
                                                                                      to/Edit
                                                                                      Build
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="lead_contract_managment.html"
                                                                                    >
                                                                                      <img src="images/details.png" />
                                                                                      Details
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="javascript:void(0);"
                                                                                    >
                                                                                      <img src="images/change_order.png" />{" "}
                                                                                      Change
                                                                                      Order
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                                      <li>
                                                                                        <a
                                                                                          href="change_order_list.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/change_order.png" />
                                                                                          Change
                                                                                          orders
                                                                                          List
                                                                                        </a>
                                                                                      </li>
                                                                                    </ul>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="design_selections.html"
                                                                                    >
                                                                                      <img src="images/design.png" />
                                                                                      Design
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="javascript:void(0);"
                                                                                      data-toggle="modal"
                                                                                      data-targer="#ClientContractDetailsNoUnit"
                                                                                    >
                                                                                      <img src="images/sales.png" />
                                                                                      Sales
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="javascript:void(0);"
                                                                                    >
                                                                                      <img src="images/estimation.png" />{" "}
                                                                                      Estimation
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                                      <li>
                                                                                        <a
                                                                                          href="budget_details.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/estimation.png" />
                                                                                          Budget
                                                                                          Details
                                                                                        </a>
                                                                                      </li>
                                                                                    </ul>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="construction.html"
                                                                                    >
                                                                                      <img src="images/construction_icon.png" />{" "}
                                                                                      Construction
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_1.png" />{" "}
                                                                                          Schedule
                                                                                          View
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_2.png" />{" "}
                                                                                          Gantt
                                                                                          View
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_3.png" />{" "}
                                                                                          Calendar
                                                                                          View
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_4.png" />{" "}
                                                                                          List
                                                                                          View
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_5.png" />{" "}
                                                                                          Baseline
                                                                                        </a>
                                                                                      </li>
                                                                                    </ul>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="Warranty_Dashboard.html"
                                                                                    >
                                                                                      <img src="images/warranty.png" />
                                                                                      Warranty
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="models.html"
                                                                                    >
                                                                                      <img src="images/model.png" />{" "}
                                                                                      Model
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                    <ul className="dropdown-menu submenu">
                                                                                      <li>
                                                                                        <a
                                                                                          href="javascript:void(0);"
                                                                                          className="dropdown-item text-center"
                                                                                          data-toggle="modal"
                                                                                          data-target="#CreateNewModelHome"
                                                                                        >
                                                                                          <img src="images/model.png" />{" "}
                                                                                          Create
                                                                                          New
                                                                                          Model
                                                                                        </a>
                                                                                      </li>
                                                                                    </ul>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="reports.html"
                                                                                    >
                                                                                      <img src="images/reports.png" />
                                                                                      Reports
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="client-view-config.html"
                                                                                    >
                                                                                      <img src="images/view_config.png" />
                                                                                      Views
                                                                                      Configs
                                                                                    </a>
                                                                                  </li>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                    );
                                                                  }
                                                                )}
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  )}
                                              </>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}

        {multiChangeorderShow && (
          <>
            <div
              className="CommonTableArea MultiFamilyConstructionTable"
              style={{ display: "block" }}
            >
              <div className="JobTableHeaderOuter">
                <div className="JobTableHeaderInner">
                  <h5>Job/Unit List</h5>
                  <div className="Actions">
                    <a
                      href="javascript:void(0);"
                      data-toggle="modal"
                      data-target="#LegalDescriptionSettings"
                    >
                      <img src="images/icon-52.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/icon-51.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/icon-53.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/icon-54.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/icon-55.png" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="JobTableCommon">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Job Number</th>
                      <th>Build Type</th>
                      <th>Address</th>
                      <th>Lot</th>
                      <th>Block</th>
                      <th>Phase</th>
                      <th>Plan</th>
                      <th>Sub Division</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="addClasss JobTabletbodyRed">
                    {multiFamilyProjectList?.projects?.length > 0 ? (
                      multiFamilyProjectList?.projects?.map((item, i) => {
                        return (
                          <>
                            <tr key={i}>
                              <td>
                                {item.i}
                                <span className="Dots">
                                  <img src="images/dots.png" />
                                </span>{" "}
                              </td>
                              <td>
                                <a
                                  id="hidtableshow2"
                                  onClick={() =>
                                    handleMultiBlockListByProject(item.id, i)
                                  }
                                >
                                  {item.jobNumber}&nbsp;
                                  <img
                                    src={"images/down-arrow.png"}
                                    style={{
                                      transform: `rotate(${
                                        resdentalId === i ? "180deg" : "0deg"
                                      })`,
                                    }}
                                  />
                                </a>
                              </td>
                              <td>
                                <a>{item.projectType}</a>
                              </td>
                              <td>
                                <a>{item.projectAddress}</a>
                              </td>
                              <td>{item.lot}</td>
                              <td>{item.block}</td>
                              <td>{item.phase}</td>
                              <td>{item.plan ?? "N/A"}</td>

                              <td>
                                <a>{item.subdivisions?.map((x) => x)}</a>
                              </td>
                              <td>
                                <div className="TodoUser TodoUserHome">
                                  <div className="TodoUserJob">
                                    <div className="dropdown">
                                      <p data-toggle="dropdown">
                                        <span>
                                          <img src="images/three-dots.png" />
                                        </span>
                                      </p>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuOffset"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/add_build.png" />{" "}
                                            Add to/Edit Build
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="lead_contract_managment.html"
                                          >
                                            <img src="images/details.png" />
                                            Details
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/change_order.png" />{" "}
                                            Change Order
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="change_order_list.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/change_order.png" />
                                                Change orders List
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="design_selections.html"
                                          >
                                            <img src="images/design.png" />
                                            Design
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                            data-toggle="modal"
                                            data-targer="#ClientContractDetailsNoUnit"
                                          >
                                            <img src="images/sales.png" />
                                            Sales
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/estimation.png" />{" "}
                                            Estimation
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="budget_details.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/estimation.png" />
                                                Budget Details
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="construction.html"
                                          >
                                            <img src="images/construction_icon.png" />{" "}
                                            Construction
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_1.png" />{" "}
                                                Schedule View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_2.png" />{" "}
                                                Gantt View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_3.png" />{" "}
                                                Calendar View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_4.png" />{" "}
                                                List View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_5.png" />{" "}
                                                Baseline
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/warranty-dashboard"
                                          >
                                            <img src="images/warranty.png" />
                                            Warranty
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/models"
                                          >
                                            <img src="images/model.png" />{" "}
                                            Model
                                            <img src="images/rightarrow2.png" />
                                          </Link>
                                          {/* <ul className="dropdown-menu submenu">
        <li>
          <a
            to="javascript:void(0);"
            className="dropdown-item text-center"
            data-toggle="modal"
            data-target="#CreateNewModelHome"
          >
            <img src="images/model.png" /> Create New Model
          </a>
        </li>
      </ul> */}
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="reports.html"
                                          >
                                            <img src="images/reports.png" />
                                            Reports
                                          </a>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/client-view-config"
                                          >
                                            <img src="images/view_config.png" />
                                            Views Configs
                                          </Link>
                                        </li>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={10} className="padding_none">
                                <div
                                  className="JobBoxTable"
                                  style={{
                                    display:
                                      resdentalId == i ? "block" : "none",
                                  }}
                                >
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Unit Job Number</th>
                                        <th>Unit#</th>
                                        <th>Primary Client</th>
                                        <th>Square Footage</th>
                                        <th>Warranty Requests</th>
                                        <th>Description</th>
                                        <th />
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {resdentalId == i &&
                                        multiblocksList?.length > 0 &&
                                        multiblocksList?.map(
                                          (data, index) => {
                                            let tempMultiStoredata =
                                              data?.jobType == "MultiBlock" &&
                                              [
                                                ...data?.MultiUnits,
                                                ...data?.MultiStories,
                                              ]?.flat();
                                            // console.log(
                                            //   "tempMultiStoredata=====------>",
                                            //   tempMultiStoredata, data?.jobType
                                            // );
                                            return (
                                              <>
                                                <tr key={index}>
                                                  <td>
                                                    <Link
                                                      to="/change-order-list"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data?.id ?? "N/A"}
                                                      <span className="Dots">
                                                        <img src="images/dots.png" />
                                                      </span>
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    {/* <Link
                                                  to="/change-order-list"
                                                  state={data}
                                                > */}
                                                    {data?.jobNumber ?? "N.A"}
                                                    {/* </Link> */}
                                                    &nbsp;
                                                    {data?.jobType ==
                                                      "MultiBlock" && (
                                                      <img
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          e.stopPropagation();
                                                          updateState(
                                                            (prev) => ({
                                                              ...prev,
                                                              multiJobUnitOpenIndex:
                                                                prev.multiJobUnitOpenIndex ==
                                                                index
                                                                  ? -1
                                                                  : index,
                                                            })
                                                          );
                                                        }}
                                                        src={
                                                          "images/down-arrow.png"
                                                        }
                                                        style={{
                                                          transform: `rotate(${
                                                            multiJobUnitOpenIndex ===
                                                            index
                                                              ? "180deg"
                                                              : "0deg"
                                                          })`,
                                                        }}
                                                      />
                                                    )}
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/change-order-list"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data.unit ?? "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/change-order-list"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data.client ?? "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/change-order-list"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data.footage ?? "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/change-order-list"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data?.warranties ??
                                                        "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/change-order-list"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data?.description ??
                                                        "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    {/* <a to="javascript:void(0);">
                                    <i
                                      className="fa fa-caret-down"
                                      aria-hidden="true"
                                    />
                                  </a> */}
                                                  </td>
                                                </tr>
                                                {data?.jobType ==
                                                  "MultiBlock" &&
                                                  multiJobUnitOpenIndex ==
                                                    index && (
                                                    <tr>
                                                      <td colSpan={7}>
                                                        <div
                                                          className="ThirdLevelTable"
                                                          style={{
                                                            border:
                                                              "1px solid red",
                                                            borderRadius: 15,
                                                            padding: 5,
                                                          }}
                                                        >
                                                          <table>
                                                            <thead>
                                                              <tr>
                                                                <th>#</th>
                                                                <th>
                                                                  Unit /
                                                                  Common Area
                                                                  Job Number
                                                                </th>
                                                                <th>
                                                                  Unit /
                                                                  Common Area
                                                                  #
                                                                </th>
                                                                <th>
                                                                  Primary
                                                                  Client
                                                                </th>
                                                                <th>
                                                                  Square
                                                                  Footage
                                                                  Description
                                                                </th>
                                                                <th>
                                                                  Description
                                                                </th>
                                                                <th>
                                                                  Action
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {tempMultiStoredata?.length >
                                                                0 &&
                                                                tempMultiStoredata?.map(
                                                                  (
                                                                    itemm,
                                                                    indd
                                                                  ) => {
                                                                    // console.log("itemm---->",itemm);
                                                                    return (
                                                                      <tr>
                                                                        <td>
                                                                          <div className="d-flex">
                                                                            <Link
                                                                              to="/change-order-list"
                                                                              state={{
                                                                                ...data,
                                                                                ProjectId:
                                                                                  item.id,
                                                                              }}
                                                                            >
                                                                              {
                                                                                itemm?.id
                                                                              }
                                                                            </Link>
                                                                            <div className="dropdown">
                                                                              <span
                                                                                className="Dots"
                                                                                data-toggle="dropdown"
                                                                                aria-haspopup="true"
                                                                                aria-expanded="false"
                                                                              >
                                                                                <img src="images/dots.png" />
                                                                              </span>
                                                                              {/* <div className="dropdown-menu">
                                                                                <a
                                                                                  className="dropdown-item"
                                                                                  href="archive_residential.html"
                                                                                >
                                                                                  {" "}
                                                                                  <img src="images/archive_job.png" />{" "}
                                                                                  Archive
                                                                                  Job
                                                                                </a>
                                                                                <a
                                                                                  className="dropdown-item"
                                                                                  href="archive_residential.html"
                                                                                >
                                                                                  {" "}
                                                                                  <img src="images/home_warranty.png" />{" "}
                                                                                  Show
                                                                                  in
                                                                                  Warranty
                                                                                  &nbsp;&nbsp;
                                                                                </a>
                                                                              </div> */}
                                                                            </div>
                                                                          </div>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/change-order-list"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.jobNumber ??
                                                                              "N/A"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/change-order-list"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.unitNumber ??
                                                                              "N/A"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/change-order-list"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.name ??
                                                                              "N/A"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/change-order-list"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.unitsSqFt ??
                                                                              itemm?.value ??
                                                                              "0"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/change-order-list"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.jobType ??
                                                                              "N/A"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <div className="TodoUser TodoUserHome text-center">
                                                                            <div className="TodoUserJob">
                                                                              <div className="dropdown">
                                                                                <p data-toggle="dropdown">
                                                                                  <span>
                                                                                    <img src="images/three-dots.png" />
                                                                                  </span>
                                                                                </p>
                                                                                <div
                                                                                  className="dropdown-menu"
                                                                                  aria-labelledby="dropdownMenuOffset"
                                                                                >
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="javascript:void(0);"
                                                                                    >
                                                                                      <img src="images/add_build.png" />{" "}
                                                                                      Add
                                                                                      to/Edit
                                                                                      Build
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="lead_contract_managment.html"
                                                                                    >
                                                                                      <img src="images/details.png" />
                                                                                      Details
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="javascript:void(0);"
                                                                                    >
                                                                                      <img src="images/change_order.png" />{" "}
                                                                                      Change
                                                                                      Order
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                                      <li>
                                                                                        <a
                                                                                          href="change_order_list.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/change_order.png" />
                                                                                          Change
                                                                                          orders
                                                                                          List
                                                                                        </a>
                                                                                      </li>
                                                                                    </ul>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="design_selections.html"
                                                                                    >
                                                                                      <img src="images/design.png" />
                                                                                      Design
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="javascript:void(0);"
                                                                                      data-toggle="modal"
                                                                                      data-targer="#ClientContractDetailsNoUnit"
                                                                                    >
                                                                                      <img src="images/sales.png" />
                                                                                      Sales
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="javascript:void(0);"
                                                                                    >
                                                                                      <img src="images/estimation.png" />{" "}
                                                                                      Estimation
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                                      <li>
                                                                                        <a
                                                                                          href="budget_details.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/estimation.png" />
                                                                                          Budget
                                                                                          Details
                                                                                        </a>
                                                                                      </li>
                                                                                    </ul>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="construction.html"
                                                                                    >
                                                                                      <img src="images/construction_icon.png" />{" "}
                                                                                      Construction
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_1.png" />{" "}
                                                                                          Schedule
                                                                                          View
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_2.png" />{" "}
                                                                                          Gantt
                                                                                          View
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_3.png" />{" "}
                                                                                          Calendar
                                                                                          View
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_4.png" />{" "}
                                                                                          List
                                                                                          View
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_5.png" />{" "}
                                                                                          Baseline
                                                                                        </a>
                                                                                      </li>
                                                                                    </ul>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="Warranty_Dashboard.html"
                                                                                    >
                                                                                      <img src="images/warranty.png" />
                                                                                      Warranty
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="models.html"
                                                                                    >
                                                                                      <img src="images/model.png" />{" "}
                                                                                      Model
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                    <ul className="dropdown-menu submenu">
                                                                                      <li>
                                                                                        <a
                                                                                          href="javascript:void(0);"
                                                                                          className="dropdown-item text-center"
                                                                                          data-toggle="modal"
                                                                                          data-target="#CreateNewModelHome"
                                                                                        >
                                                                                          <img src="images/model.png" />{" "}
                                                                                          Create
                                                                                          New
                                                                                          Model
                                                                                        </a>
                                                                                      </li>
                                                                                    </ul>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="reports.html"
                                                                                    >
                                                                                      <img src="images/reports.png" />
                                                                                      Reports
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="client-view-config.html"
                                                                                    >
                                                                                      <img src="images/view_config.png" />
                                                                                      Views
                                                                                      Configs
                                                                                    </a>
                                                                                  </li>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                    );
                                                                  }
                                                                )}
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  )}
                                              </>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}

        {multiEstimationShow && (
          <>
            <div
              className="CommonTableArea MultiFamilyConstructionTable"
              style={{ display: "block" }}
            >
              <div className="JobTableHeaderOuter">
                <div className="JobTableHeaderInner">
                  <h5>Job/Unit List</h5>
                  <div className="Actions">
                    <a
                      href="javascript:void(0);"
                      data-toggle="modal"
                      data-target="#LegalDescriptionSettings"
                    >
                      <img src="images/icon-52.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/icon-51.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/icon-53.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/icon-54.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/icon-55.png" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="JobTableCommon">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Job Number</th>
                      <th>Build Type</th>
                      <th>Address</th>
                      <th>Lot</th>
                      <th>Block</th>
                      <th>Phase</th>
                      <th>Plan</th>
                      <th>Sub Division</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="addClasss JobTabletbodyRed">
                    {multiFamilyProjectList?.projects?.length > 0 ? (
                      multiFamilyProjectList?.projects?.map((item, i) => {
                        return (
                          <>
                            <tr key={i}>
                              <td>
                                {item.i}
                                <span className="Dots">
                                  <img src="images/dots.png" />
                                </span>{" "}
                              </td>
                              <td>
                                <a
                                  id="hidtableshow2"
                                  onClick={() =>
                                    handleMultiBlockListByProject(item.id, i)
                                  }
                                >
                                  {item.jobNumber}&nbsp;
                                  <img
                                    src={"images/down-arrow.png"}
                                    style={{
                                      transform: `rotate(${
                                        resdentalId === i ? "180deg" : "0deg"
                                      })`,
                                    }}
                                  />
                                </a>
                              </td>
                              <td>
                                <a>{item.projectType}</a>
                              </td>
                              <td>
                                <a>{item.projectAddress}</a>
                              </td>
                              <td>{item.lot}</td>
                              <td>{item.block}</td>
                              <td>{item.phase}</td>
                              <td>{item.plan ?? "N/A"}</td>

                              <td>
                                <a>{item.subdivisions?.map((x) => x)}</a>
                              </td>
                              <td>
                                <div className="TodoUser TodoUserHome">
                                  <div className="TodoUserJob">
                                    <div className="dropdown">
                                      <p data-toggle="dropdown">
                                        <span>
                                          <img src="images/three-dots.png" />
                                        </span>
                                      </p>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuOffset"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/add_build.png" />{" "}
                                            Add to/Edit Build
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="lead_contract_managment.html"
                                          >
                                            <img src="images/details.png" />
                                            Details
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/change_order.png" />{" "}
                                            Change Order
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="change_order_list.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/change_order.png" />
                                                Change orders List
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="design_selections.html"
                                          >
                                            <img src="images/design.png" />
                                            Design
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                            data-toggle="modal"
                                            data-targer="#ClientContractDetailsNoUnit"
                                          >
                                            <img src="images/sales.png" />
                                            Sales
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/estimation.png" />{" "}
                                            Estimation
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="budget_details.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/estimation.png" />
                                                Budget Details
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="construction.html"
                                          >
                                            <img src="images/construction_icon.png" />{" "}
                                            Construction
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_1.png" />{" "}
                                                Schedule View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_2.png" />{" "}
                                                Gantt View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_3.png" />{" "}
                                                Calendar View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_4.png" />{" "}
                                                List View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_5.png" />{" "}
                                                Baseline
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/warranty-dashboard"
                                          >
                                            <img src="images/warranty.png" />
                                            Warranty
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/models"
                                          >
                                            <img src="images/model.png" />{" "}
                                            Model
                                            <img src="images/rightarrow2.png" />
                                          </Link>
                                          {/* <ul className="dropdown-menu submenu">
        <li>
          <a
            to="javascript:void(0);"
            className="dropdown-item text-center"
            data-toggle="modal"
            data-target="#CreateNewModelHome"
          >
            <img src="images/model.png" /> Create New Model
          </a>
        </li>
      </ul> */}
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="reports.html"
                                          >
                                            <img src="images/reports.png" />
                                            Reports
                                          </a>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/client-view-config"
                                          >
                                            <img src="images/view_config.png" />
                                            Views Configs
                                          </Link>
                                        </li>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={10} className="padding_none">
                                <div
                                  className="JobBoxTable"
                                  style={{
                                    display:
                                      resdentalId == i ? "block" : "none",
                                  }}
                                >
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Unit Job Number</th>
                                        <th>Unit#</th>
                                        <th>Primary Client</th>
                                        <th>Square Footage</th>
                                        <th>Warranty Requests</th>
                                        <th>Description</th>
                                        <th />
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {resdentalId == i &&
                                        multiblocksList?.length > 0 &&
                                        multiblocksList?.map(
                                          (data, index) => {
                                            let tempMultiStoredata =
                                              data?.jobType == "MultiBlock" &&
                                              [
                                                ...data?.MultiUnits,
                                                ...data?.MultiStories,
                                              ]?.flat();
                                            // console.log(
                                            //   "tempMultiStoredata=====------>",
                                            //   tempMultiStoredata, data?.jobType
                                            // );
                                            return (
                                              <>
                                                <tr key={index}>
                                                  <td>
                                                    <Link
                                                      to="/estimation"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data?.id ?? "N/A"}
                                                      <span className="Dots">
                                                        <img src="images/dots.png" />
                                                      </span>
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    {/* <Link
                                                  to="/estimation"
                                                  state={data}
                                                > */}
                                                    {data?.jobNumber ?? "N.A"}
                                                    {/* </Link> */}
                                                    &nbsp;
                                                    {data?.jobType ==
                                                      "MultiBlock" && (
                                                      <img
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          e.stopPropagation();
                                                          updateState(
                                                            (prev) => ({
                                                              ...prev,
                                                              multiJobUnitOpenIndex:
                                                                prev.multiJobUnitOpenIndex ==
                                                                index
                                                                  ? -1
                                                                  : index,
                                                            })
                                                          );
                                                        }}
                                                        src={
                                                          "images/down-arrow.png"
                                                        }
                                                        style={{
                                                          transform: `rotate(${
                                                            multiJobUnitOpenIndex ===
                                                            index
                                                              ? "180deg"
                                                              : "0deg"
                                                          })`,
                                                        }}
                                                      />
                                                    )}
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/estimation"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data.unit ?? "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/estimation"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data.client ?? "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/estimation"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data.footage ?? "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/estimation"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data?.warranties ??
                                                        "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/estimation"
                                                      state={{
                                                        ...data,
                                                        ProjectId: item.id,
                                                      }}
                                                    >
                                                      {data?.description ??
                                                        "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    {/* <a to="javascript:void(0);">
                                    <i
                                      className="fa fa-caret-down"
                                      aria-hidden="true"
                                    />
                                  </a> */}
                                                  </td>
                                                </tr>
                                                {data?.jobType ==
                                                  "MultiBlock" &&
                                                  multiJobUnitOpenIndex ==
                                                    index && (
                                                    <tr>
                                                      <td colSpan={7}>
                                                        <div
                                                          className="ThirdLevelTable"
                                                          style={{
                                                            border:
                                                              "1px solid red",
                                                            borderRadius: 15,
                                                            padding: 5,
                                                          }}
                                                        >
                                                          <table>
                                                            <thead>
                                                              <tr>
                                                                <th>#</th>
                                                                <th>
                                                                  Unit /
                                                                  Common Area
                                                                  Job Number
                                                                </th>
                                                                <th>
                                                                  Unit /
                                                                  Common Area
                                                                  #
                                                                </th>
                                                                <th>
                                                                  Primary
                                                                  Client
                                                                </th>
                                                                <th>
                                                                  Square
                                                                  Footage
                                                                  Description
                                                                </th>
                                                                <th>
                                                                  Description
                                                                </th>
                                                                <th>
                                                                  Action
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {tempMultiStoredata?.length >
                                                                0 &&
                                                                tempMultiStoredata?.map(
                                                                  (
                                                                    itemm,
                                                                    indd
                                                                  ) => {
                                                                    // console.log("itemm---->",itemm);
                                                                    return (
                                                                      <tr>
                                                                        <td>
                                                                          <div className="d-flex">
                                                                            <Link
                                                                              to="/estimation"
                                                                              state={{
                                                                                ...data,
                                                                                ProjectId:
                                                                                  item.id,
                                                                              }}
                                                                            >
                                                                              {
                                                                                itemm?.id
                                                                              }
                                                                            </Link>
                                                                            <div className="dropdown">
                                                                              <span
                                                                                className="Dots"
                                                                                data-toggle="dropdown"
                                                                                aria-haspopup="true"
                                                                                aria-expanded="false"
                                                                              >
                                                                                <img src="images/dots.png" />
                                                                              </span>
                                                                              {/* <div className="dropdown-menu">
                                                                                <a
                                                                                  className="dropdown-item"
                                                                                  href="archive_residential.html"
                                                                                >
                                                                                  {" "}
                                                                                  <img src="images/archive_job.png" />{" "}
                                                                                  Archive
                                                                                  Job
                                                                                </a>
                                                                                <a
                                                                                  className="dropdown-item"
                                                                                  href="archive_residential.html"
                                                                                >
                                                                                  {" "}
                                                                                  <img src="images/home_warranty.png" />{" "}
                                                                                  Show
                                                                                  in
                                                                                  Warranty
                                                                                  &nbsp;&nbsp;
                                                                                </a>
                                                                              </div> */}
                                                                            </div>
                                                                          </div>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/estimation"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.jobNumber ??
                                                                              "N/A"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/estimation"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.unitNumber ??
                                                                              "N/A"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/estimation"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.name ??
                                                                              "N/A"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/estimation"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.unitsSqFt ??
                                                                              itemm?.value ??
                                                                              "0"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <Link
                                                                            to="/estimation"
                                                                            state={{
                                                                              ...data,
                                                                              ProjectId:
                                                                                item.id,
                                                                            }}
                                                                          >
                                                                            {itemm?.jobType ??
                                                                              "N/A"}
                                                                          </Link>
                                                                        </td>
                                                                        <td>
                                                                          <div className="TodoUser TodoUserHome text-center">
                                                                            <div className="TodoUserJob">
                                                                              <div className="dropdown">
                                                                                <p data-toggle="dropdown">
                                                                                  <span>
                                                                                    <img src="images/three-dots.png" />
                                                                                  </span>
                                                                                </p>
                                                                                <div
                                                                                  className="dropdown-menu"
                                                                                  aria-labelledby="dropdownMenuOffset"
                                                                                >
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="javascript:void(0);"
                                                                                    >
                                                                                      <img src="images/add_build.png" />{" "}
                                                                                      Add
                                                                                      to/Edit
                                                                                      Build
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="lead_contract_managment.html"
                                                                                    >
                                                                                      <img src="images/details.png" />
                                                                                      Details
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="javascript:void(0);"
                                                                                    >
                                                                                      <img src="images/change_order.png" />{" "}
                                                                                      Change
                                                                                      Order
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                                      <li>
                                                                                        <a
                                                                                          href="change_order_list.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/change_order.png" />
                                                                                          Change
                                                                                          orders
                                                                                          List
                                                                                        </a>
                                                                                      </li>
                                                                                    </ul>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="design_selections.html"
                                                                                    >
                                                                                      <img src="images/design.png" />
                                                                                      Design
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="javascript:void(0);"
                                                                                      data-toggle="modal"
                                                                                      data-targer="#ClientContractDetailsNoUnit"
                                                                                    >
                                                                                      <img src="images/sales.png" />
                                                                                      Sales
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="javascript:void(0);"
                                                                                    >
                                                                                      <img src="images/estimation.png" />{" "}
                                                                                      Estimation
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                                      <li>
                                                                                        <a
                                                                                          href="budget_details.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/estimation.png" />
                                                                                          Budget
                                                                                          Details
                                                                                        </a>
                                                                                      </li>
                                                                                    </ul>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="construction.html"
                                                                                    >
                                                                                      <img src="images/construction_icon.png" />{" "}
                                                                                      Construction
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_1.png" />{" "}
                                                                                          Schedule
                                                                                          View
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_2.png" />{" "}
                                                                                          Gantt
                                                                                          View
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_3.png" />{" "}
                                                                                          Calendar
                                                                                          View
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_4.png" />{" "}
                                                                                          List
                                                                                          View
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          href="construction.html"
                                                                                          className="dropdown-item"
                                                                                        >
                                                                                          <img src="images/construuction_5.png" />{" "}
                                                                                          Baseline
                                                                                        </a>
                                                                                      </li>
                                                                                    </ul>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="Warranty_Dashboard.html"
                                                                                    >
                                                                                      <img src="images/warranty.png" />
                                                                                      Warranty
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="models.html"
                                                                                    >
                                                                                      <img src="images/model.png" />{" "}
                                                                                      Model
                                                                                      <img src="images/rightarrow2.png" />
                                                                                    </a>
                                                                                    <ul className="dropdown-menu submenu">
                                                                                      <li>
                                                                                        <a
                                                                                          href="javascript:void(0);"
                                                                                          className="dropdown-item text-center"
                                                                                          data-toggle="modal"
                                                                                          data-target="#CreateNewModelHome"
                                                                                        >
                                                                                          <img src="images/model.png" />{" "}
                                                                                          Create
                                                                                          New
                                                                                          Model
                                                                                        </a>
                                                                                      </li>
                                                                                    </ul>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="reports.html"
                                                                                    >
                                                                                      <img src="images/reports.png" />
                                                                                      Reports
                                                                                    </a>
                                                                                  </li>
                                                                                  <li>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      href="client-view-config.html"
                                                                                    >
                                                                                      <img src="images/view_config.png" />
                                                                                      Views
                                                                                      Configs
                                                                                    </a>
                                                                                  </li>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                    );
                                                                  }
                                                                )}
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  )}
                                              </>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}

{multiAccountingShow && (
            <>
              <div
                className="CommonTableArea MultiFamilyConstructionTable"
                style={{ display: "block" }}
              >
                <div className="JobTableHeaderOuter">
                  <div className="JobTableHeaderInner">
                    <h5>Job/Unit List</h5>
                    <div className="Actions">
                      <a
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#LegalDescriptionSettings"
                      >
                        <img src="images/icon-52.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/icon-51.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/icon-53.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/icon-54.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/icon-55.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="JobTableCommon">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Job Number</th>
                        <th>Build Type</th>
                        <th>Address</th>
                        <th>Lot</th>
                        <th>Block</th>
                        <th>Phase</th>
                        <th>Plan</th>
                        <th>Sub Division</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="addClasss JobTabletbodyRed">
                      {multiFamilyProjectList?.projects?.length > 0 ? (
                        multiFamilyProjectList?.projects?.map((item, i) => {
                          return (
                            <>
                              <tr key={i}>
                                <td>
                                  {item.i}
                                  <span className="Dots">
                                    <img src="images/dots.png" />
                                  </span>{" "}
                                </td>
                                <td>
                                  <a
                                    id="hidtableshow2"
                                    onClick={() =>
                                      handleMultiBlockListByProject(item.id, i)
                                    }
                                  >
                                    {item.jobNumber}&nbsp;
                                    <img
                                      src={"images/down-arrow.png"}
                                      style={{
                                        transform: `rotate(${
                                          resdentalId === i ? "180deg" : "0deg"
                                        })`,
                                      }}
                                    />
                                  </a>
                                </td>
                                <td>
                                  <a>{item.projectType}</a>
                                </td>
                                <td>
                                  <a>{item.projectAddress}</a>
                                </td>
                                <td>{item.lot}</td>
                                <td>{item.block}</td>
                                <td>{item.phase}</td>
                                <td>{item.plan ?? "N/A"}</td>

                                <td>
                                  <a>{item.subdivisions?.map((x) => x)}</a>
                                </td>
                                <td>
                                  <div className="TodoUser TodoUserHome">
                                    <div className="TodoUserJob">
                                      <div className="dropdown">
                                        <p data-toggle="dropdown">
                                          <span>
                                            <img src="images/three-dots.png" />
                                          </span>
                                        </p>
                                        <div
                                          className="dropdown-menu"
                                          aria-labelledby="dropdownMenuOffset"
                                        >
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              to="javascript:void(0);"
                                            >
                                              <img src="images/add_build.png" />{" "}
                                              Add to/Edit Build
                                              <img src="images/rightarrow2.png" />
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              to="lead_contract_managment.html"
                                            >
                                              <img src="images/details.png" />
                                              Details
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              to="javascript:void(0);"
                                            >
                                              <img src="images/change_order.png" />{" "}
                                              Change Order
                                              <img src="images/rightarrow2.png" />
                                            </a>
                                            <ul className="dropdown-menu submenu submenuhome">
                                              <li>
                                                <a
                                                  to="change_order_list.html"
                                                  className="dropdown-item"
                                                >
                                                  <img src="images/change_order.png" />
                                                  Change orders List
                                                </a>
                                              </li>
                                            </ul>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              to="design_selections.html"
                                            >
                                              <img src="images/design.png" />
                                              Design
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              to="javascript:void(0);"
                                              data-toggle="modal"
                                              data-targer="#ClientContractDetailsNoUnit"
                                            >
                                              <img src="images/sales.png" />
                                              Sales
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              to="javascript:void(0);"
                                            >
                                              <img src="images/estimation.png" />{" "}
                                              Estimation
                                              <img src="images/rightarrow2.png" />
                                            </a>
                                            <ul className="dropdown-menu submenu submenuhome">
                                              <li>
                                                <a
                                                  to="budget_details.html"
                                                  className="dropdown-item"
                                                >
                                                  <img src="images/estimation.png" />
                                                  Budget Details
                                                </a>
                                              </li>
                                            </ul>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              to="construction.html"
                                            >
                                              <img src="images/construction_icon.png" />{" "}
                                              Construction
                                              <img src="images/rightarrow2.png" />
                                            </a>
                                            <ul className="dropdown-menu submenu submenuhome">
                                              <li>
                                                <a
                                                  to="construction.html"
                                                  className="dropdown-item"
                                                >
                                                  <img src="images/construuction_1.png" />{" "}
                                                  Schedule View
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  to="construction.html"
                                                  className="dropdown-item"
                                                >
                                                  <img src="images/construuction_2.png" />{" "}
                                                  Gantt View
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  to="construction.html"
                                                  className="dropdown-item"
                                                >
                                                  <img src="images/construuction_3.png" />{" "}
                                                  Calendar View
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  to="construction.html"
                                                  className="dropdown-item"
                                                >
                                                  <img src="images/construuction_4.png" />{" "}
                                                  List View
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  to="construction.html"
                                                  className="dropdown-item"
                                                >
                                                  <img src="images/construuction_5.png" />{" "}
                                                  Baseline
                                                </a>
                                              </li>
                                            </ul>
                                          </li>
                                          <li>
                                            <Link
                                              className="dropdown-item"
                                              to="/warranty-dashboard"
                                            >
                                              <img src="images/warranty.png" />
                                              Warranty
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              className="dropdown-item"
                                              to="/models"
                                            >
                                              <img src="images/model.png" />{" "}
                                              Model
                                              <img src="images/rightarrow2.png" />
                                            </Link>
                                            {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              to="reports.html"
                                            >
                                              <img src="images/reports.png" />
                                              Reports
                                            </a>
                                          </li>
                                          <li>
                                            <Link
                                              className="dropdown-item"
                                              to="/client-view-config"
                                            >
                                              <img src="images/view_config.png" />
                                              Views Configs
                                            </Link>
                                          </li>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td colSpan={10} className="padding_none">
                                  <div
                                    className="JobBoxTable"
                                    style={{
                                      display:
                                        resdentalId == i ? "block" : "none",
                                    }}
                                  >
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>Unit Job Number</th>
                                          <th>Unit#</th>
                                          <th>Primary Client</th>
                                          <th>Square Footage</th>
                                          <th>Warranty Requests</th>
                                          <th>Description</th>
                                          <th />
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {resdentalId == i &&
                                          multiblocksList?.length > 0 &&
                                          multiblocksList?.map(
                                            (data, index) => {
                                              let tempMultiStoredata =
                                                data?.jobType == "MultiBlock" &&
                                                [
                                                  ...data?.MultiUnits,
                                                  ...data?.MultiStories,
                                                ]?.flat();
                                              // console.log(
                                              //   "tempMultiStoredata=====------>",
                                              //   tempMultiStoredata, data?.jobType
                                              // );
                                              return (
                                                <>
                                                  <tr key={index}>
                                                    <td>
                                                      <Link
                                                        to="/transactions-manager"
                                                        state={{
                                                          ...data,
                                                          ProjectId: item.id,
                                                        }}
                                                      >
                                                        {data?.id ?? "N/A"}
                                                        <span className="Dots">
                                                          <img src="images/dots.png" />
                                                        </span>
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      {/* <Link
                                                    to="/estimation"
                                                    state={data}
                                                  > */}
                                                      {data?.jobNumber ?? "N.A"}
                                                      {/* </Link> */}
                                                      &nbsp;
                                                      {data?.jobType ==
                                                        "MultiBlock" && (
                                                        <img
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            updateState(
                                                              (prev) => ({
                                                                ...prev,
                                                                multiJobUnitOpenIndex:
                                                                  prev.multiJobUnitOpenIndex ==
                                                                  index
                                                                    ? -1
                                                                    : index,
                                                              })
                                                            );
                                                          }}
                                                          src={
                                                            "images/down-arrow.png"
                                                          }
                                                          style={{
                                                            transform: `rotate(${
                                                              multiJobUnitOpenIndex ===
                                                              index
                                                                ? "180deg"
                                                                : "0deg"
                                                            })`,
                                                          }}
                                                        />
                                                      )}
                                                    </td>
                                                    <td>
                                                      <Link
                                                        to="/transactions-manager"
                                                        state={{
                                                          ...data,
                                                          ProjectId: item.id,
                                                        }}
                                                      >
                                                        {data.unit ?? "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        to="/transactions-manager"
                                                        state={{
                                                          ...data,
                                                          ProjectId: item.id,
                                                        }}
                                                      >
                                                        {data.client ?? "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        to="/transactions-manager"
                                                        state={{
                                                          ...data,
                                                          ProjectId: item.id,
                                                        }}
                                                      >
                                                        {data.footage ?? "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        to="/transactions-manager"
                                                        state={{
                                                          ...data,
                                                          ProjectId: item.id,
                                                        }}
                                                      >
                                                        {data?.warranties ??
                                                          "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        to="/transactions-manager"
                                                        state={{
                                                          ...data,
                                                          ProjectId: item.id,
                                                        }}
                                                      >
                                                        {data?.description ??
                                                          "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      {/* <a to="javascript:void(0);">
                                      <i
                                        className="fa fa-caret-down"
                                        aria-hidden="true"
                                      />
                                    </a> */}
                                                    </td>
                                                  </tr>
                                                  {data?.jobType ==
                                                    "MultiBlock" &&
                                                    multiJobUnitOpenIndex ==
                                                      index && (
                                                      <tr>
                                                        <td colSpan={7}>
                                                          <div
                                                            className="ThirdLevelTable"
                                                            style={{
                                                              border:
                                                                "1px solid red",
                                                              borderRadius: 15,
                                                              padding: 5,
                                                            }}
                                                          >
                                                            <table>
                                                              <thead>
                                                                <tr>
                                                                  <th>#</th>
                                                                  <th>
                                                                    Unit /
                                                                    Common Area
                                                                    Job Number
                                                                  </th>
                                                                  <th>
                                                                    Unit /
                                                                    Common Area
                                                                    #
                                                                  </th>
                                                                  <th>
                                                                    Primary
                                                                    Client
                                                                  </th>
                                                                  <th>
                                                                    Square
                                                                    Footage
                                                                    Description
                                                                  </th>
                                                                  <th>
                                                                    Description
                                                                  </th>
                                                                  <th>
                                                                    Action
                                                                  </th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                {tempMultiStoredata?.length >
                                                                  0 &&
                                                                  tempMultiStoredata?.map(
                                                                    (
                                                                      itemm,
                                                                      indd
                                                                    ) => {
                                                                      // console.log("itemm---->",itemm);
                                                                      return (
                                                                        <tr>
                                                                          <td>
                                                                            <div className="d-flex">
                                                                              <Link
                                                                                to="/transactions-manager"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {
                                                                                  itemm?.id
                                                                                }
                                                                              </Link>
                                                                              <div className="dropdown">
                                                                                <span
                                                                                  className="Dots"
                                                                                  data-toggle="dropdown"
                                                                                  aria-haspopup="true"
                                                                                  aria-expanded="false"
                                                                                >
                                                                                  <img src="images/dots.png" />
                                                                                </span>
                                                                                {/* <div className="dropdown-menu">
                                                                                  <a
                                                                                    className="dropdown-item"
                                                                                    href="archive_residential.html"
                                                                                  >
                                                                                    {" "}
                                                                                    <img src="images/archive_job.png" />{" "}
                                                                                    Archive
                                                                                    Job
                                                                                  </a>
                                                                                  <a
                                                                                    className="dropdown-item"
                                                                                    href="archive_residential.html"
                                                                                  >
                                                                                    {" "}
                                                                                    <img src="images/home_warranty.png" />{" "}
                                                                                    Show
                                                                                    in
                                                                                    Warranty
                                                                                    &nbsp;&nbsp;
                                                                                  </a>
                                                                                </div> */}
                                                                              </div>
                                                                            </div>
                                                                          </td>
                                                                          <td>
                                                                            <Link
                                                                              to="/transactions-manager"
                                                                              state={{
                                                                                ...data,
                                                                                ProjectId:
                                                                                  item.id,
                                                                              }}
                                                                            >
                                                                              {itemm?.jobNumber ??
                                                                                "N/A"}
                                                                            </Link>
                                                                          </td>
                                                                          <td>
                                                                            <Link
                                                                              to="/transactions-manager"
                                                                              state={{
                                                                                ...data,
                                                                                ProjectId:
                                                                                  item.id,
                                                                              }}
                                                                            >
                                                                              {itemm?.unitNumber ??
                                                                                "N/A"}
                                                                            </Link>
                                                                          </td>
                                                                          <td>
                                                                            <Link
                                                                              to="/transactions-manager"
                                                                              state={{
                                                                                ...data,
                                                                                ProjectId:
                                                                                  item.id,
                                                                              }}
                                                                            >
                                                                              {itemm?.name ??
                                                                                "N/A"}
                                                                            </Link>
                                                                          </td>
                                                                          <td>
                                                                            <Link
                                                                              to="/transactions-manager"
                                                                              state={{
                                                                                ...data,
                                                                                ProjectId:
                                                                                  item.id,
                                                                              }}
                                                                            >
                                                                              {itemm?.unitsSqFt ??
                                                                                itemm?.value ??
                                                                                "0"}
                                                                            </Link>
                                                                          </td>
                                                                          <td>
                                                                            <Link
                                                                              to="/transactions-manager"
                                                                              state={{
                                                                                ...data,
                                                                                ProjectId:
                                                                                  item.id,
                                                                              }}
                                                                            >
                                                                              {itemm?.jobType ??
                                                                                "N/A"}
                                                                            </Link>
                                                                          </td>
                                                                          <td>
                                                                            <div className="TodoUser TodoUserHome text-center">
                                                                              <div className="TodoUserJob">
                                                                                <div className="dropdown">
                                                                                  <p data-toggle="dropdown">
                                                                                    <span>
                                                                                      <img src="images/three-dots.png" />
                                                                                    </span>
                                                                                  </p>
                                                                                  <div
                                                                                    className="dropdown-menu"
                                                                                    aria-labelledby="dropdownMenuOffset"
                                                                                  >
                                                                                    <li>
                                                                                      <a
                                                                                        className="dropdown-item"
                                                                                        href="javascript:void(0);"
                                                                                      >
                                                                                        <img src="images/add_build.png" />{" "}
                                                                                        Add
                                                                                        to/Edit
                                                                                        Build
                                                                                        <img src="images/rightarrow2.png" />
                                                                                      </a>
                                                                                    </li>
                                                                                    <li>
                                                                                      <a
                                                                                        className="dropdown-item"
                                                                                        href="lead_contract_managment.html"
                                                                                      >
                                                                                        <img src="images/details.png" />
                                                                                        Details
                                                                                      </a>
                                                                                    </li>
                                                                                    <li>
                                                                                      <a
                                                                                        className="dropdown-item"
                                                                                        href="javascript:void(0);"
                                                                                      >
                                                                                        <img src="images/change_order.png" />{" "}
                                                                                        Change
                                                                                        Order
                                                                                        <img src="images/rightarrow2.png" />
                                                                                      </a>
                                                                                      <ul className="dropdown-menu submenu submenuhome">
                                                                                        <li>
                                                                                          <a
                                                                                            href="change_order_list.html"
                                                                                            className="dropdown-item"
                                                                                          >
                                                                                            <img src="images/change_order.png" />
                                                                                            Change
                                                                                            orders
                                                                                            List
                                                                                          </a>
                                                                                        </li>
                                                                                      </ul>
                                                                                    </li>
                                                                                    <li>
                                                                                      <a
                                                                                        className="dropdown-item"
                                                                                        href="design_selections.html"
                                                                                      >
                                                                                        <img src="images/design.png" />
                                                                                        Design
                                                                                      </a>
                                                                                    </li>
                                                                                    <li>
                                                                                      <a
                                                                                        className="dropdown-item"
                                                                                        href="javascript:void(0);"
                                                                                        data-toggle="modal"
                                                                                        data-targer="#ClientContractDetailsNoUnit"
                                                                                      >
                                                                                        <img src="images/sales.png" />
                                                                                        Sales
                                                                                      </a>
                                                                                    </li>
                                                                                    <li>
                                                                                      <a
                                                                                        className="dropdown-item"
                                                                                        href="javascript:void(0);"
                                                                                      >
                                                                                        <img src="images/estimation.png" />{" "}
                                                                                        Estimation
                                                                                        <img src="images/rightarrow2.png" />
                                                                                      </a>
                                                                                      <ul className="dropdown-menu submenu submenuhome">
                                                                                        <li>
                                                                                          <a
                                                                                            href="budget_details.html"
                                                                                            className="dropdown-item"
                                                                                          >
                                                                                            <img src="images/estimation.png" />
                                                                                            Budget
                                                                                            Details
                                                                                          </a>
                                                                                        </li>
                                                                                      </ul>
                                                                                    </li>
                                                                                    <li>
                                                                                      <a
                                                                                        className="dropdown-item"
                                                                                        href="construction.html"
                                                                                      >
                                                                                        <img src="images/construction_icon.png" />{" "}
                                                                                        Construction
                                                                                        <img src="images/rightarrow2.png" />
                                                                                      </a>
                                                                                      <ul className="dropdown-menu submenu submenuhome">
                                                                                        <li>
                                                                                          <a
                                                                                            href="construction.html"
                                                                                            className="dropdown-item"
                                                                                          >
                                                                                            <img src="images/construuction_1.png" />{" "}
                                                                                            Schedule
                                                                                            View
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            href="construction.html"
                                                                                            className="dropdown-item"
                                                                                          >
                                                                                            <img src="images/construuction_2.png" />{" "}
                                                                                            Gantt
                                                                                            View
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            href="construction.html"
                                                                                            className="dropdown-item"
                                                                                          >
                                                                                            <img src="images/construuction_3.png" />{" "}
                                                                                            Calendar
                                                                                            View
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            href="construction.html"
                                                                                            className="dropdown-item"
                                                                                          >
                                                                                            <img src="images/construuction_4.png" />{" "}
                                                                                            List
                                                                                            View
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            href="construction.html"
                                                                                            className="dropdown-item"
                                                                                          >
                                                                                            <img src="images/construuction_5.png" />{" "}
                                                                                            Baseline
                                                                                          </a>
                                                                                        </li>
                                                                                      </ul>
                                                                                    </li>
                                                                                    <li>
                                                                                      <a
                                                                                        className="dropdown-item"
                                                                                        href="Warranty_Dashboard.html"
                                                                                      >
                                                                                        <img src="images/warranty.png" />
                                                                                        Warranty
                                                                                      </a>
                                                                                    </li>
                                                                                    <li>
                                                                                      <a
                                                                                        className="dropdown-item"
                                                                                        href="models.html"
                                                                                      >
                                                                                        <img src="images/model.png" />{" "}
                                                                                        Model
                                                                                        <img src="images/rightarrow2.png" />
                                                                                      </a>
                                                                                      <ul className="dropdown-menu submenu">
                                                                                        <li>
                                                                                          <a
                                                                                            href="javascript:void(0);"
                                                                                            className="dropdown-item text-center"
                                                                                            data-toggle="modal"
                                                                                            data-target="#CreateNewModelHome"
                                                                                          >
                                                                                            <img src="images/model.png" />{" "}
                                                                                            Create
                                                                                            New
                                                                                            Model
                                                                                          </a>
                                                                                        </li>
                                                                                      </ul>
                                                                                    </li>
                                                                                    <li>
                                                                                      <a
                                                                                        className="dropdown-item"
                                                                                        href="reports.html"
                                                                                      >
                                                                                        <img src="images/reports.png" />
                                                                                        Reports
                                                                                      </a>
                                                                                    </li>
                                                                                    <li>
                                                                                      <a
                                                                                        className="dropdown-item"
                                                                                        href="client-view-config.html"
                                                                                      >
                                                                                        <img src="images/view_config.png" />
                                                                                        Views
                                                                                        Configs
                                                                                      </a>
                                                                                    </li>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </td>
                                                                        </tr>
                                                                      );
                                                                    }
                                                                  )}
                                                              </tbody>
                                                            </table>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    )}
                                                </>
                                              );
                                            }
                                          )}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
      </div>
    </div>
  </div>
   
  
  )
}

export default MultiFamily