import { authActionTypes, ibuildAdminActionTypes, widCastTypes } from "../actionTypes";
const initialState = {
  loader: false,
  planListData:[],
  countryData:[],
  stateData:[],
  cityData:[],
  timeZoneData:[],
  preferredVendorListData:[],
  viewWidCastData:[],
  widCastListData:[],
  subDivisionListData:[],
  commonCostCodeListData:[],
  commonCostCategoryListData:[],
  iBuildAdminRoleListData:[],
iBuildAdminUserListData:[],
messageListData:[],
getAllCostCategoryListData:[],
getAllCostCodeListData:[],
blockListData:[],
speicficReleasedDocsListData:[]
 
};

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case authActionTypes.SUBSCRIPTION_PLAN_LIST_INITIATE: {
        return {
          ...state,
          planListData: [],
          loader:true
        };
      }
      case authActionTypes.SUBSCRIPTION_PLAN_LIST_SUCCESS: {

        return {
          ...state,
          planListData: payload,
          loader:false
        };
      }
      case authActionTypes.SUBSCRIPTION_PLAN_LIST_FAILURE: {
        return {
          ...state,
          planListData: payload.data,
          loader:false
        };
      }
      case authActionTypes.GET_COUNTRIES_LIST_INITIATE: {
        return {
          ...state,
          loader: true,
          countryData: [],
        };
      }
      case authActionTypes.GET_COUNTRIES_LIST_SUCCESS: {
        // console.log(payload)
        return {

          ...state,
          loader: false,
          countryData: payload,
        };
      }
      case authActionTypes.GET_COUNTRIES_LIST_FAILURE: {
        return {
          ...state,
          loader: false,
          countryData: payload.data,
        };
      }


      case authActionTypes.MESSAGE_LIST_INITIATE: {
        return {
          ...state,
          loader: true,
          messageListData: [],
        };
      }
   
      case authActionTypes.MESSAGE_LIST_SUCCESS: {
        // console.log(payload)
        return {
          ...state,
          loader: false,
          messageListData: payload,
        };
      }
      case authActionTypes.MESSAGE_LIST_FAILURE: {
        return {
          ...state,
          loader: false,
          messageListData: payload.data,
        };
      }

      case authActionTypes.GET_ALL_COSTCATEGORY_INITIATE: {
        return {
          ...state,
          loader: true,
          getAllCostCategoryListData: [],
        };
      }
  
      case authActionTypes.GET_ALL_COSTCATEGORY_SUCCESS: {
        // console.log(payload)
        return {
          ...state,
          loader: false,
          getAllCostCategoryListData: payload,
        };
      }
      case authActionTypes.GET_ALL_COSTCATEGORY_FAILURE: {
        return {
          ...state,
          loader: false,
          getAllCostCategoryListData: payload.data,
        };
      }



      case authActionTypes.GET_ALL_COSTCODE_INITIATE: {
        return {
          ...state,
          loader: true,
          getAllCostCodeListData: [],
        };
      }
  
      case authActionTypes.GET_ALL_COSTCODE_SUCCESS: {
        // console.log(payload)
        return {
          ...state,
          loader: false,
          getAllCostCodeListData: payload,
        };
      }
      case authActionTypes.GET_ALL_COSTCODE_FAILURE: {
        return {
          ...state,
          loader: false,
          getAllCostCodeListData: payload.data,
        };
      }

   
      case authActionTypes.GET_STATE_LIST_INITIATE: {
        return {
          ...state,
          stateData: [],
        };
      }
      case authActionTypes.GET_STATE_LIST_SUCCESS: {
        return {
          ...state,
          //   loader: false,
          stateData: payload,
        };
      }
      case authActionTypes.GET_STATE_LIST_FAILURE: {
        return {
          ...state,
          stateData: payload.data,
        };
      }
  
      case authActionTypes.GET_CITIES_LIST_INITIATE: {
          return {
            ...state,
            cityData: [],
          };
        }
        case authActionTypes.GET_CITIES_LIST_SUCCESS: {
          return {
            ...state,
            //   loader: false,
            cityData: payload,
          };
        }
        case authActionTypes.GET_CITIES_LIST_FAILURE: {
          return {
            ...state,
            cityData: payload.data,
          };
        }


        case authActionTypes.GET_BLOCK_LIST_INITIATE: {
          return {
            ...state,
            blockListData: [],
          };
        }
        case authActionTypes.GET_BLOCK_LIST_SUCCESS: {
          return {
            ...state,
            //   loader: false,
            blockListData: payload,
          };
        }
        case authActionTypes.GET_BLOCK_LIST_FAILURE: {
          return {
            ...state,
            blockListData: payload.data,
          };
        }


        case authActionTypes.GET_SPECIFICATION_DOCS_LIST_INITIATE: {
          return {
            ...state,
            speicficReleasedDocsListData: [],
          };
        }
        case authActionTypes.GET_SPECIFICATION_DOCS_LIST_SUCCESS: {
          return {
            ...state,
            //   loader: false,
            speicficReleasedDocsListData: payload,
          };
        }
        case authActionTypes.GET_SPECIFICATION_DOCS_LIST_FAILURE: {
          return {
            ...state,
            speicficReleasedDocsListData: payload.data,
          };
        }


        case authActionTypes.TIMEZONE_LIST_INITIATE: {
          return {
            ...state,  
             timeZoneData: [],
          };
        }
        case authActionTypes.TIMEZONE_LIST_SUCCESS: {
          return {
            ...state,
            //   loader: false,
            timeZoneData: payload,
          };
        }
        case authActionTypes.TIMEZONE_LIST_FAILURE: {
          return {
            ...state,
            timeZoneData: payload.data,
          };
        }
       

        case authActionTypes.HEADER_NOTIFICATION_LIST_INITIATE: {
          return {
            ...state,  
             headerNotificationListData: [],
          };
        }
        case authActionTypes.HEADER_NOTIFICATION_LIST_SUCCESS: {
          return {
            ...state,
            //   loader: false,
            headerNotificationListData: payload,
          };
        }
        case authActionTypes.HEADER_NOTIFICATION_LIST_FAILURE: {
          return {
            ...state,
            headerNotificationListData: payload.data,
          };
        }


        case widCastTypes.VIEW_WIDCAST_INITIATE: {
          return {
            ...state,  
             viewWidCastData: [],
          };
        }
        case widCastTypes.VIEW_WIDCAST_SUCCESS: {
          return {
            ...state,
            //   loader: false,
            viewWidCastData: payload,
          };
        }
        case widCastTypes.VIEW_WIDCAST_FAILURE: {
          return {
            ...state,
            viewWidCastData: payload.data,
          };
        }



        
        case widCastTypes.WIDCAST_LIST_INITIATE: {
          return {
            ...state,  
            loader:true,
             widCastListData: [],
          };
        }
        case widCastTypes.WIDCAST_LIST_SUCCESS: {
          return {
            ...state,
              loader: false,
            widCastListData: payload,
          };
        }
        case widCastTypes.WIDCAST_LIST_FAILURE: {
          return {
            ...state,
            loader: false,
            widCastListData: payload.data,
          };
        }


        case authActionTypes.SUBDIVISION_LIST_INITIATE: {
          return {
            ...state,  
            loader:true,
             subDivisionListData: [],
          };
        }
        case authActionTypes.SUBDIVISION_LIST_SUCCESS: {
          return {
            ...state,
              loader: false,
            subDivisionListData: payload,
          };
        }
        case authActionTypes.SUBDIVISION_LIST_FAILURE: {
          return {
            ...state,
            loader: false,
            subDivisionListData: payload.data,
          };
        }


        case authActionTypes.COMMON_COST_CODE_LIST_INITIATE: {
          return {
            ...state,  
            loader:true,
             commonCostCodeListData: [],
          };
        }
        case authActionTypes.COMMON_COST_CODE_LIST_SUCCESS: {
          return {
            ...state,
              loader: false,
            commonCostCodeListData: payload,
          };
        }
        case authActionTypes.COMMON_COST_CODE_LIST_FAILURE: {
          return {
            ...state,
            loader: false,
            commonCostCodeListData: payload.data,
          };
        }


        
        case authActionTypes.COMMON_COST_CATEGORY_LIST_INITIATE: {
          return {
            ...state,  
            loader:true,
             commonCostCategoryListData: [],
          };
        }
        case authActionTypes.COMMON_COST_CATEGORY_LIST_SUCCESS: {
          return {
            ...state,
              loader: false,
            commonCostCategoryListData: payload,
          };
        }
        case authActionTypes.COMMON_COST_CATEGORY_LIST_FAILURE: {
          return {
            ...state,
            loader: false,
            commonCostCategoryListData: payload.data,
          };
        }

        case ibuildAdminActionTypes.IBUILDADMIN_USER_LIST_INITIATE: {
          return {
            ...state,
            iBuildAdminUserListData: [],
            loader:true
          };
        }
        case ibuildAdminActionTypes.IBUILDADMIN_USER_LIST_SUCCESS: {
          return {
            ...state,
            iBuildAdminUserListData: payload,
            loader:false
          };
        }
        case ibuildAdminActionTypes.IBUILDADMIN_USER_LIST_FAILURE: {
          return {
            ...state,
            iBuildAdminUserListData: payload.data,
            loader:false
          };
        }
  
  
        case ibuildAdminActionTypes.IBUILD_ADMIN_ROLE_LIST_INITIATE: {
          return {
            ...state,
            iBuildAdminRoleListData: [],
            loader:true
          };
        }
        case ibuildAdminActionTypes.IBUILD_ADMIN_ROLE_LIST_SUCCESS: {
          return {
            ...state,
            iBuildAdminRoleListData: payload,
            loader:false
          };
        }
        case ibuildAdminActionTypes.IBUILD_ADMIN_ROLE_LIST_FAILURE: {
          return {
            ...state,
            iBuildAdminRoleListData: payload.data,
            loader:false
          };
        }


       
       
  
  
     
 

    default:
      return state;
  }
}
