import { AdminSalesConstant } from "../actionTypes";
const initialState={
    generalSettingList:{},
    loader:false,
}


export default function adminSalesReducer(state = initialState, {type, payload}) {
    
    switch(type) {
      case AdminSalesConstant.GET_GENERAL_SETTING_INITIATE: {
        return {
          ...state, loader:true, generalSettingList:[]
        }
      }
      case AdminSalesConstant.GET_GENERAL_SETTING_SUCCESS: {
        return {
          ...state, loader:false, generalSettingList:payload?.generalSetting
        }
      }
      case AdminSalesConstant.GET_GENERAL_SETTING_FAILURE: {
        return {
          ...state, loader:false,generalSettingList:{}
        }
      }
  
      
      default: 
      return state
  }
}