import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import {
  addWarrantyUrgency,
  clientWarrantyUpdate,
  getAllWarrantyUrgencyList,
  getDraftData,
  getWarrantieyAllRequestItemList,
  getWarrantyAddNewSupportDocs,
  getWarrantyAllSystemUrgencyList,
  warrantyAddNotes,
} from "../redux/action/clientViewAuthAction";
import ClientViewSidenav from "./client-view-sidenav";
import ClientHeader from "./ClientHeader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {useNavigate} from "react-router-dom"
const initialState = {
  AddNotesModal: false,
  supportingDocFiles: null,
  message: "",
  supportingDocFiless: null,
  AddNewSupportingDocsModal: false,
  name: "",
  actionId: "",
  AddNewUrgencyModal: false,

  description: "",
  requestDate: "",
  phonecode: "",
  UrgencyId: "",
};

const Warranty_request_client = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [iState, updateState] = useState(initialState);
  const {
    AddNotesModal,
    supportingDocFiles,
    message,
    supportingDocFiless,
    AddNewSupportingDocsModal,
    name,
    actionId,
    AddNewUrgencyModal,
    description,
    requestDate,
    UrgencyId,
    phonecode,
  } = iState;

  const clientViewData = JSON.parse(
    window.localStorage.getItem("clientViewLogin")
  );
  const {
    warrantyDraftData,
    warrantyAllSystemUrgencyList,
    warrantyUrgencyList,
    warrantyAllRequestItemList,
  } = useSelector((state) => state.clientViewLogin);
  console.log("warrantyDraftData", warrantyDraftData);

  useEffect(() => {
    dispatch(getDraftData());
    dispatch(getWarrantyAllSystemUrgencyList());
    dispatch(getAllWarrantyUrgencyList());
  }, []);

  useEffect(() => {
    if (warrantyDraftData?.warranty?.id) {
      dispatch(
        getWarrantieyAllRequestItemList(warrantyDraftData?.warranty?.id)
      );
    }
  }, [dispatch, warrantyDraftData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const modifiedValue = value.slice(0, 1000);
    updateState({
      ...iState,
      [name]: modifiedValue,
    });
  };

  const handleInputChanges = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  const [selectedOption, setSelectedOption] = useState("Text");
  const [textInput, setTextInput] = useState(true);
  const [fileData, setFileData] = useState(false);

  const onValueChange = (event) => {
    updateState({ ...iState, message: "", supportingDocFiles: "" });
    const value = event.target.value;
    setSelectedOption(value);
    setTextInput(value === "Text");
    setFileData(value === "File");
  };

  const handleRemoveClientFile = () => {
    // Reset the 'templateFile' state to null
    updateState({
      ...iState,
      supportingDocFiles: null,
      supportingDocFiless: null,
      itemDocs: null,
    });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#fileInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleAddNotesShow = () => {
    updateState({
      ...iState,
      AddNotesModal: true,
      message: "",
      supportingDocFiles: "",
    });
  };
  const handleAddNotesClose = () => {
    updateState({
      ...iState,
      AddNotesModal: false,
    });
  };

  const handleFileChange = (event, type) => {
    const file = event?.target?.files[0];
    if (type === "notes") {
      updateState({ ...iState, supportingDocFiles: file });
    }
    if (type === "supportDocs") {
      updateState({ ...iState, supportingDocFiless: file });
    }
    // else {
    //   updateState({ ...iState, supportingDocFiless: file });
    // }
  };

  const handleAddNotes = () => {
    let dataa;
    if (textInput) {
      dataa = {
        warrantyNotes: [{ noteType: "text", message: message }],
        description: warrantyDraftData?.warranty?.description
          ? warrantyDraftData?.warranty?.description
          : "N/A",
        // jobType:clientViewData?.jobData?.jobType,
        // jobId:clientViewData?.jobData?.id,
      };
    } else {
      dataa = {
        warrantyNotes: [{ noteType: "files" }],
        supportingDocFiles,
        description: warrantyDraftData?.warranty?.description
          ? warrantyDraftData?.warranty?.description
          : "N/A",
        // jobType:clientViewData?.jobData?.jobType,
        // jobId:clientViewData?.jobData?.id,
      };
    }

    dispatch(warrantyAddNotes(warrantyDraftData?.warranty?.id, dataa)).then(
      (res) => {
        if (res.status === 200) {
          toast.success("Add Notes Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            AddNotesModal: false,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            AddNotesModal: true,
          });
        }
      }
    );
  };

  const handleAddNewSupportingDocsModalShow = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: true,
    });
  };
  const handleAddNewSupportingDocsModalClose = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: false,
    });
  };

  const handleSupportDocs = () => {
    let dataa = {
      supportingDocFiles: supportingDocFiless,
    };

    dispatch(
      getWarrantyAddNewSupportDocs(warrantyDraftData?.warranty?.id, dataa)
    ).then((res) => {
      if (res.status === 201) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AddNewSupportingDocsModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          AddNewSupportingDocsModal: true,
        });
      }
    });
  };

  const handleAddNewUrgencyModalShow = () => {
    updateState({
      ...iState,
      AddNewUrgencyModal: true,
    });
  };
  const handleAddNewUrgencyModalClose = () => {
    updateState({
      ...iState,
      AddNewUrgencyModal: false,
    });
  };

  const handleUrgencyClick = (id) => {
    updateState({ ...iState, UrgencyId: id });
  };

  const handleurgencyAdd = () => {
    let dataa = { name, definedType: "User", configType: "Urgency", actionId };
    dispatch(addWarrantyUrgency(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AddNewUrgencyModal: false,
          name: "",
          actionId: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          AddNewUrgencyModal: true,
        });
      }
    });
  };

  const handlePhoneInputChange = (value,country) => {
    updateState({
      ...iState,
      phonecode: `+${country.dialCode}`,
    });
  };

  

  const handleSaveAndEmail = (type) => {
      const datta = {
        description,
        requestDate,
        UrgencyId,
        sendEmail:type === "email"?true:false,
        JobId: clientViewData?.jobDetails?.id,
        // jobType: clientViewData?.jobDetails?.jobType,
        phonecode, 
      };
      dispatch(clientWarrantyUpdate(Number(warrantyDraftData?.warranty?.id), datta)).then((res) => {
        if (res.status === 200) {
          navigate("/house-warranty");
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState(initialState);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    } 
  

  return (
    <>
      <ClientHeader />
      <ClientViewSidenav />
      <div className="WrapperAreaClient">
        <div className="WrapperBoxClient pb-5">
          <div className="MainTitleBox"></div>
          <div className="TitleBox">
            <h4 className="Title">Warranty Request #{warrantyDraftData?.warranty?.JobNumber}</h4>
            <div className="TitleBox2 TitleBoxRight">
              <div className="dropdown">
                <h6 data-toggle="dropdown">{warrantyDraftData?.warranty?.JobId}</h6>
                <span>{warrantyDraftData?.warranty?.JobType}</span>
              </div>
            </div>
          </div>
          <div className="WarrantyDashboardForm mb-4">
            <div className="row">
              <div className="col-xl-6">
                <div className="form-group">
                  <label>
                    Description <span className="Required">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    rows={3}
                    placeholder="Write here ....."
                    name="description"
                    value={description}
                    onChange={handleInputChanges}
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="row">
                  <div className="col-xl-6 col-lg-12">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Supporting Document</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer"
                            onClick={handleAddNewSupportingDocsModalShow}
                          >
                            <figure>
                              <img src="images/document-support.png" />
                            </figure>
                            Supporting Document
                          </h5>
                          <span
                            className="Count CursorPointer"
                            data-toggle="modal"
                            data-target="#SupportingDocumentList"
                          >
                            3
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Add Notes</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer"
                            onClick={handleAddNotesShow}
                          >
                            <figure>
                              <img src="images/document-support.png" />
                            </figure>
                            Add Notes
                          </h5>
                          <span
                            className="Count CursorPointer"
                            data-toggle="modal"
                            data-target="#NotesList"
                          >
                            3
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="WarrantyDashboardForm">
            <h4>Client Details</h4>
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className="row">
                  <div className="col-xl-6 col-lg-6">
                    <div className="form-group">
                      <label>Possession Date</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <div className="form-group">
                      <label>Request Date</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Auto-filled by System"
                        name="requestDate"
                        value={requestDate}
                        onChange={handleInputChanges}
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <div className="form-group">
                      <label>Client Full Legal Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <div className="form-group">
                      <label>Country Code</label>
                      <PhoneInput
                        inputClass="CountryCodeInput"
                        countryCodeEditable={false}
                        enableSearch
                        value={phonecode}
                        onChange={handlePhoneInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <label>Primary Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <label>
                        Job Address{" "}
                        <img src="images/location.png" className="ml-2" />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="UrgencyArea">
                  <div className="form-group">
                    <h6>Urgency</h6>
                    <div className="Categories Categories2">
                      <div className="Categories_all Categories_all2">
                        <span>Select an Option</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li onClick={handleAddNewUrgencyModalShow}>
                          <span className="AddNew">+</span>Add New
                        </li>
                        {warrantyUrgencyList?.warrantyOptions?.length > 0
                          ? warrantyUrgencyList?.warrantyOptions?.map((item, i) => (
                              <li
                                // value={item.id}
                                key={i}
                                onClick={() => handleUrgencyClick(item.id)}
                                style={{
                                  backgroundColor:
                                    UrgencyId == item.id
                                      ? "#e71e09"
                                      : "transparent",
                                }}
                              >
                                {item.name}
                              </li>
                            ))
                          : "No data found"}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="TableList NewTableList TableListHeader TableHeightScroll">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Description</th>
                  <th>Service Type</th>
                  <th>Work Order #</th>
                  <th>Item Status</th>
                  <th>Inspector</th>
                  <th>Date Inspected</th>
                  <th>Supporting Doc</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Leaking sink</td>
                  <td></td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>
                    <div className="Actions">
                      <a
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#AddNewSupportingDocs2"
                      >
                        <img src="images/icon-63.png" />
                      </a>
                      <a
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#SupportingDocumentList"
                      >
                        <span className="Count">3</span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div className="DetailsDots">
                      <div className="TableThreeDots">
                        <ul className="">
                          <li className="dropdown">
                            <a
                              className=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol className="dropdown-menu dropdown-menuwidth">
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/Action-1.png" />
                                  Edit Description
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/dropdown-delete.png" />
                                  Remove Item
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Leaking sink</td>
                  <td></td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>
                    <div className="Actions">
                      <a
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#AddNewSupportingDocs2"
                      >
                        <img src="images/icon-63.png" />
                      </a>
                      <a
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#SupportingDocumentList"
                      >
                        <span className="Count">3</span>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div className="DetailsDots">
                      <div className="TableThreeDots">
                        <ul className="">
                          <li className="dropdown">
                            <a
                              className=""
                              id="navbarDropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol className="dropdown-menu dropdown-menuwidth">
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/Action-1.png" />
                                  Edit Description
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/dropdown-delete.png" />
                                  Remove Item
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={9}>
                    <div className="TableButtons">
                      <button className="BrandBtn">Add Item</button>
                      <button className="BrandBtn">Clear Table</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between mt-3 pb-5">
            <button className="BrandBtn">Cencel</button>
            <div className="d-flex ">
              <button
                className="BrandBtnGreen"
                onClick={() => handleSaveAndEmail("save")}
              >
                Save
              </button>
              <div className="dropdown ml-3">
                <button
                  className="BrandBtnGreen"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Save and <img src="images/down-arrow-white.png" />
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a className="dropdown-item">Save and Print</a>
                  <a
                    className="dropdown-item"
                    onClick={() => handleSaveAndEmail("email")}
                  >
                    Save and E-mail
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="DiscussionArea">
      <div class="DiscussionTitle">
        <h4>Discussion</h4>
        <div class="DiscussionChat">
          <img src="images/down.png" class="DiscussionIcon" />
          <a href="#"><img src="images/pdf2.png" /></a>
        </div>
      </div>
      <div class="DiscussionHideArea" style="display: block">
        <div class="DiscussionBox">
          <span>FM</span>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Add a Comment ....."
            />
            <div class="DiscussionOverlay">
              <a href="" class="CancelChat"><img src="images/plus-cancel1.png"></a>
              <a href="" class="SendChat"><img src="images/forwardbutton.png"></a>
            
            </div>
          </div>
        </div>
        <div class="DiscussionBox">
          <span>TH</span>
          <div class="DiscussionRight">
            <h6>Inspections are delayed</h6>
            <small>04/19/2023, at 02:47 pm</small>
          </div>
        </div>
        <div class="ReplyArea">
          <a href="javascript:void(0);">Reply</a>
          <div class="DiscussionBox">
            <span>TH</span>
            <div class="DiscussionRight">
              <h6>Inspections are delayed</h6>
              <small>04/19/2023, at 02:47 pm</small>
            </div>
          </div>
          <div class="DiscussionBox">
            <span>TH</span>
            <div class="DiscussionRight">
              <h6>Inspections are delayed</h6>
              <small>04/19/2023, at 02:47 pm</small>
            </div>
          </div>
        </div>
      </div>
    </div> */}
        </div>
      </div>
      <Modal
        className="ModalBox fade SmallModal"
        show={AddNotesModal}
        onHide={handleAddNotesClose}
      >
        <Modal.Body>
          <div className="Category">
            <a className="CloseModal" onClick={handleAddNotesClose}>
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Add New Note</h4>
              </div>
              <h6>Note #1</h6>
              <div className="JobTitleArea">
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                </div>
                <div className="JobBox mb-3">
                  <h6>
                    <span>Origin:</span> #185
                  </h6>
                </div>
                <h6>Type</h6>

                <div className="FolderForm">
                  <div className="form-group mb-4">
                    <label className="Radio">
                      Text
                      <input
                        type="radio"
                        value="Text"
                        checked={selectedOption === "Text"}
                        onChange={onValueChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="Radio">
                      File
                      <input
                        type="radio"
                        value="File"
                        checked={selectedOption === "File"}
                        onChange={onValueChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>

                  {textInput == true && (
                    <div className="form-group">
                      <h6>Message (Max 1000 chars limit)</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Write here ....."
                        name="message"
                        value={message}
                        onChange={handleInputChange}
                      />
                      <small className="form-text text-right">
                        {1000 - Number(message.length)} characters remaining
                      </small>
                    </div>
                  )}

                  {fileData == true && (
                    <>
                      <div className="form-group">
                        <h6>Select Files</h6>
                        <div className="Upload">
                          <span>-Drag and Drop Files or click to select</span>
                          <input
                            type="file"
                            id="fileInput"
                            onChange={(e) => handleFileChange(e, "notes")}
                          />
                        </div>
                      </div>
                      {supportingDocFiles && (
                        <div className="FilesAdded">
                          <label>{supportingDocFiles?.name}</label>
                          <span>
                            <img src="images/fileview.png" alt="File view" />
                          </span>
                          <span onClick={handleRemoveClientFile}>
                            <img
                              src="images/Cross.png"
                              className="cross"
                              alt="Cross"
                            />
                          </span>
                        </div>
                      )}
                    </>
                  )}

                  <div className="Button d-flex justify-content-between mt-4">
                    <button className="Create" onClick={handleAddNotes}>
                      Update Note
                    </button>
                    <button className="Outline" onClick={handleAddNotesClose}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="ModalBox fade SmallModal"
        show={AddNewSupportingDocsModal}
        onHide={handleAddNewSupportingDocsModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a
              className="CloseModal"
              onClick={handleAddNewSupportingDocsModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Add New Supporting Docs</h4>
              </div>
              <div className="JobTitleArea">
                <h3>Supporting Documents #2</h3>
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Origin</span> #185
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Item:</span> #143
                  </h6>
                </div>
              </div>
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input
                    type="file"
                    id="fileInput"
                    onChange={(e) => handleFileChange(e, "supportDocs")}
                  />
                </div>
              </div>
              <div className="FilesAddedTitle">
                <h4>Files</h4>
              </div>
              {supportingDocFiless && (
                <div className="FilesAdded">
                  <label>{supportingDocFiless.name}</label>
                  <span>
                    <img src="images/fileview.png" />
                  </span>
                  <span onClick={handleRemoveClientFile}>
                    <img src="images/Cross.png" className="cross" />
                  </span>
                </div>
              )}

              <div className="Button mt-3">
                <button className="Create" onClick={handleSupportDocs}>
                  Add Documents
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="ModalBox  fade MediumModal"
        show={AddNewUrgencyModal}
        onHide={handleAddNewUrgencyModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a className="CloseModal" onClick={handleAddNewUrgencyModalClose}>
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Add New Urgency</h4>
              </div>
              <div className="FolderForm">
                <div className="row">
                  <div className="form-group col-md-6">
                    <h6>Urgency Name</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert Urgency Name"
                      name="name"
                      value={name}
                      onChange={handleInputChanges}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <h6>Action #</h6>
                  <div className="CommonSelectBox w-100">
                    <select
                      name="actionId"
                      value={actionId}
                      onChange={handleInputChanges}
                    >
                      <option value="">Select Action #</option>
                      {warrantyAllSystemUrgencyList?.warrantyOptions?.length >
                        0 &&
                        warrantyAllSystemUrgencyList?.warrantyOptions?.map(
                          (item, i) => {
                            return <option value={item.id}>{item.name}</option>;
                          }
                        )}
                    </select>
                    <span>
                      <img src="images/down.png" />
                    </span>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between mt-5">
                  <button className="Create" onClick={handleurgencyAdd}>
                    Add New Urgency
                  </button>
                  <button
                    className="Outline"
                    onClick={handleAddNewUrgencyModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Warranty_request_client;
