import React, { useEffect, useState ,useRef} from "react";
import { useDispatch } from "react-redux";
import "../App.css";
import { toast } from "react-toastify";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getEditQouteRequestById,
  getUnitQr,
  updateEditQouteRequestById,
  uploadAdditionalDocumentsQr,
} from "../redux/action/tradeViewAuthAction";
import { useSelector } from "react-redux";
const initialState = {
  ViewQuoteRequestVendorData: [],
  itemDetails: [
    {
      parentTableName: "QUOTEREQUEST",
      color: "",
      quantity: null,
      unitId: null,
      price: "",
      total: "",
      name: "",
    },
  ],
  AddNewSupportingDocsModal: false,
  supportingDocFiless: null,
  date: "",
};
const TradeViewQuoteRequest = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const {
    ViewQuoteRequestVendorData,
    itemDetails,
    AddNewSupportingDocsModal,
    supportingDocFiless,
    date,
  } = iState;
  const location = useLocation();
  const urlParams = new URLSearchParams(
    location?.search?.split("?")[1].split("#")[0]
  );
  const companyId = urlParams?.get("companyId");
  const documentId = urlParams?.get("documentId");
  const token = urlParams?.get("token");

  const { unitListQr } = useSelector((state) => state.tradeViewLogin);

  useEffect(() => {
    dispatch(getEditQouteRequestById(companyId, token, documentId)).then(
      (res) => {
        if (res.status === 200) {
          updateState({
            ...iState,
            ViewQuoteRequestVendorData: res?.data?.data,
          });
        }
      }
    );
    dispatch(getUnitQr(token));
  }, []);

  const addDetailsClick = () => {
    updateState({
      ...iState,
      itemDetails: [
        ...iState.itemDetails,
        {
          parentTableName: "QUOTEREQUEST",
          color: "",
          quantity: null,
          unitId: null,
          price: "",
          total: "",
          name: "",
        },
      ],
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const removeDetailsClick = (i) => {
    let user = [...itemDetails];
    user.splice(i, 1);
    updateState({
      ...iState,
      itemDetails: user,
    });
  };

  const copyBuilderItems = () => {
    const newMappedData =
      ViewQuoteRequestVendorData?.BuilderItems?.map((item) => ({
        parentTableName: "QUOTEREQUEST",
        color: item.color || "",
        quantity: Number(item.quantity) || null,
        unitId: item.unitId || null,
        price: item.price || "",
        total: item.total || "",
        name: item.name || "",
      })) || [];

    updateState({ ...iState, itemDetails: newMappedData });
  };

  const handleClear = () => {
    updateState({ ...iState, itemDetails: [] });
  };

  const handleDetailsChange = (e, i) => {
    const { name, value } = e.target;
    let user = [...itemDetails];
    user[i] = { ...user[i], [name]: value };
    updateState({
      ...iState,
      itemDetails: user,
    });
  };

  const handleAddNewSupportingDocsModalShow = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: true,
      supportingDocFiless: "",
    });
  };
  const handleAddNewSupportingDocsModalClose = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: false,
    });
  };

  const handleRemoveDocsFile = () => {
    // Reset the 'templateFile' state to null
    updateState({
      ...iState,
      supportingDocFiless: null,
    });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#docsInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleUploadAdditionalDocuments = () => {
    const formData = new FormData();
    formData.append("files", supportingDocFiless);
    dispatch(
      uploadAdditionalDocumentsQr(
        companyId,
        documentId,
        ViewQuoteRequestVendorData?.documentType,
        token,
        formData
      )
    ).then((res) => {
      if (res.status === 201) {
        dispatch(getEditQouteRequestById(companyId, token, documentId)).then(
          (res) => {
            if (res.status === 200) {
              updateState({
                ...iState,
                ViewQuoteRequestVendorData: res?.data?.data,
                supportingDocFiless: null,
                AddNewSupportingDocsModal: false,
              });
            }
          }
        );
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      updateState({ ...iState, AddNewSupportingDocsModal: true });
    });
  };

  const handleFileChanges = (event, type) => {
    const file = event?.target?.files[0];
    if (type === "docsUpdate") {
      updateState({ ...iState, supportingDocFiless: file });
    }
  };

  const handleUpdateQr = () => {
    const dataa = {
      jobCodeId: ViewQuoteRequestVendorData?.jobCodeId,
      jobType: ViewQuoteRequestVendorData?.jobType,
      costCodeId: ViewQuoteRequestVendorData?.CostCodeId,
      reasonId: ViewQuoteRequestVendorData?.reasonId,
      // subtotal,
      date,
      status: "RETURNED",
      subtotal: totalWithoutTax,
      gst: Number(ViewQuoteRequestVendorData?.gst),
      grandTotal: parseInt(Number(totalWithoutTax) + Number(finalTotal)),
      subtotal: Number(
        itemDetails?.reduce(
          (total, data) => total + data.quantity * data.price,
          0
        ) ?? 0
      ),
      itemDetails: itemDetails?.map((data) => ({
        parentTableName: data.parentTableName,
        color: data.color,
        quantity: Number(data.quantity),
        unitId: Number(data.unitId),
        price: data.price,
        total: String(data.quantity * data.price),
        name: data.name,
      })),
    };

    dispatch(
      updateEditQouteRequestById(companyId, token, documentId, dataa)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getEditQouteRequestById(companyId, token, documentId)).then(
          (res) => {
            if (res.status === 200) {
              updateState({
                ...iState,
                ViewQuoteRequestVendorData: res?.data?.data,
              });
            }
          }
        );
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const totalWithoutTax =
    itemDetails?.reduce(
      (total, data) => total + data.quantity * data.price,
      0
    ) ?? 0;
  const totalWithoutTaxVendor =
    ViewQuoteRequestVendorData?.VendorItems?.reduce(
      (total, data) => total + Number(data.quantity) * Number(data.price),
      0
    ) ?? 0;

  // const taxPercentTotal =
  //   ViewQuoteRequestVendorData?.TaxProfile?.taxRows?.reduce(
  //     (total, data) => total + data.taxPercent,
  //     0
  //   ) ?? 0;

  const taxPercentTotal =
    ViewQuoteRequestVendorData?.TaxProfile?.taxRows?.[0]?.taxPercent || 0;
  const totalWithTax = totalWithoutTax * (1 + taxPercentTotal / 100);
  const finalTotal = (totalWithTax - totalWithoutTax).toFixed(2);

  const totalWithTaxVendor =
    totalWithoutTaxVendor * (1 + taxPercentTotal / 100);
  const finalTotalVendor = totalWithTaxVendor - totalWithoutTaxVendor;

  const  quoteScopeDocs = ViewQuoteRequestVendorData?.workDocuments?.map(doc => doc.docUrl) || [];
  const handleDownloadAllScopeDocs = async () => {
    if (Array.isArray(quoteScopeDocs) && quoteScopeDocs.length > 0) {
      for (const docUrl of quoteScopeDocs) {
        const link = document.createElement("a");
        link.href = docUrl;
        link.setAttribute("download", ""); 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        await new Promise(resolve => setTimeout(resolve, 2000)); 
      }
    } else {
      toast.error("No documents available to download");
    }
  }
  

  const  quoteConstructDocs = ViewQuoteRequestVendorData?.constructionDocuments?.map(doc => doc.docUrl) || [];
  const handleDownloadAllConstructDocs = async () => {
    if (Array.isArray(quoteConstructDocs) && quoteConstructDocs.length > 0) {
      for (const docUrl of quoteConstructDocs) {
        const link = document.createElement("a");
        link.href = docUrl;
        link.setAttribute("download", ""); 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        await new Promise(resolve => setTimeout(resolve, 2000)); 
      }
    } else {
      toast.error("No documents available to download");
    }
  };

  const handlePrint=()=>{
    window.print();
  }


  return (
    <>
      <div className="FormArea p-5">
        <div className="ModalTitleBox ModalTitleBoxYellow">
          <h4>Edit Quote Request - Vendor</h4>
        </div>
        <div className="FolderForm">
          <div className="BlueTextArea YellowTextArea">
            <div className="row">
              <div className="col-lg-6">
                <div className="Heading">
                  <h3>
                    Quote Request # {ViewQuoteRequestVendorData?.documentCode}
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="Heading">
                  <h3 className="d-flex justify-content-between">
                    Rivers Edge Unit{" "}
                    {ViewQuoteRequestVendorData?.job?.jobNumber ?? "N/A"}
                  </h3>
                  <h3 className="mb-2">
                    Cost Code:{" "}
                    <span>{`${
                      ViewQuoteRequestVendorData?.CostCode?.number ?? ""
                    }-${
                      ViewQuoteRequestVendorData?.CostCode?.name ?? ""
                    }`}</span>
                  </h3>
                  <h6>
                    Status:<span>{ViewQuoteRequestVendorData?.status}</span>
                  </h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group">
                  <h6>Reason</h6>
                  <p className="ml-0">
                    {ViewQuoteRequestVendorData?.Reason?.description ?? "N/A"}
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <h6>Sent By</h6>
                  <p className="ml-0">
                    {ViewQuoteRequestVendorData?.issuedBy?.firstName ||
                      ViewQuoteRequestVendorData?.issuedBy?.lastName ||
                      ViewQuoteRequestVendorData?.issuedBy?.name}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group">
                  <h6>Date Received</h6>
                  <p className="ml-0">
                    {moment(ViewQuoteRequestVendorData?.createdAt).format(
                      "YYYY-MM-DD"
                    )}
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <h6>Date Sent</h6>
                  <p>
                    {moment(ViewQuoteRequestVendorData?.date).format(
                      "YYYY-MM-DD"
                    )}
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <h6>Access Expires</h6>
                  <p className="ml-0">
                    {moment(ViewQuoteRequestVendorData?.updatedAt).format(
                      "YYYY-MM-DD"
                    )}
                  </p>
                </div>
              </div>
              <div className="col-12">
                <div className="ShippingAddressArea">
                  <div className="ShippingAddressAreaBox">
                    <h6>Job Address</h6>
                    <p>
                      {`${
                        ViewQuoteRequestVendorData?.Vendor?.addressLine1 ?? ""
                      }
                                ${
                                  ViewQuoteRequestVendorData?.Vendor
                                    ?.addressLine2 ?? ""
                                }`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="form-group">
                  <h6>Messsage</h6>
                  <p>{ViewQuoteRequestVendorData?.message}</p>
                </div>
              </div>
              <div className="col-12">
                <div className="ItemDetails">
                  <h6>Item Details</h6>
                </div>
              </div>
              <div className="col-12">
                <h6 className="YellowText">Builder</h6>
                <div className="Tableheader mb-3">
                  <h6 className="mb-0">Item</h6>
                  <div className="Actions">
                    <a onClick={handlePrint}>
                      <img src="images/icon-53.png" />
                    </a>
                    <a onClick={handlePrint}>
                      <img src="images/download.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/setting.png" />
                    </a>
                  </div>
                </div>
                <div className="SchedulePaymentsTable TransactionTableShow mb-0">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>
                          <div className="text-center">
                            Material Description
                            <br />
                            (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                          </div>
                        </th>
                        <th>Color</th>
                        <th>Quantity</th>
                        <th>Units</th>
                        <th>Price/Unit</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ViewQuoteRequestVendorData?.BuilderItems?.length > 0 ? (
                        ViewQuoteRequestVendorData?.BuilderItems?.map(
                          (item, i) => {
                            return (
                              <tr key={i}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.color}</td>
                                <td>{item.quantity}</td>
                                <td>{item.Unit?.name}</td>
                                <td>$ {item.price}</td>
                                <td>$ {item.total}</td>
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <tr>
                          <td colSpan="12">
                            <p>No Data found.</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="TableBottom">
                  <h6>
                    <span>Builder's Prex-Tax Total:</span> $
                    {Number(
                      ViewQuoteRequestVendorData?.BuilderItems?.reduce(
                        (total, data) => total + data.quantity * data.price,
                        0
                      ) ?? 0
                    )}
                  </h6>
                </div>
              </div>
              <div className="col-12 mt-4">
                <h6 className="YellowText">Vendor</h6>
                <div className="Tableheader mb-3">
                  <h6 className="mb-0">Item</h6>
                  <div className="Actions">
                    <a onClick={handlePrint}>
                      <img src="images/icon-53.png" />
                    </a>
                    <a onClick={handlePrint}>
                      <img src="images/download.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/setting.png" />
                    </a>
                  </div>
                </div>
                <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>
                          <div className="text-center">
                            Material Description
                            <br />
                            (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                          </div>
                        </th>
                        <th>Color</th>
                        <th>Quantity</th>
                        <th>Units</th>
                        <th>Price/Unit</th>
                        <th>Total</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ViewQuoteRequestVendorData?.status === "SEND" ? (
                        itemDetails?.length > 0 ? (
                          itemDetails.map((data, index) => (
                            <tr key={index}>
                              <td>{data.id}</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  value={data.name}
                                  onChange={(e) =>
                                    handleDetailsChange(e, index)
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="color"
                                  value={data.color}
                                  onChange={(e) =>
                                    handleDetailsChange(e, index)
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="quantity"
                                  value={data.quantity}
                                  min={0}
                                  onChange={(e) =>
                                    handleDetailsChange(e, index)
                                  }
                                />
                              </td>
                              <td>
                                <select
                                  className="form-control"
                                  name="unitId"
                                  value={data.unitId}
                                  onChange={(e) =>
                                    handleDetailsChange(e, index)
                                  }
                                >
                                  <option value="">Select</option>
                                  {unitListQr?.map((item, i) => (
                                    <option value={item.id} key={i}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="price"
                                  value={data.price}
                                  min={0}
                                  onChange={(e) =>
                                    handleDetailsChange(e, index)
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={
                                    (data.quantity * data.price).toFixed(2) ?? 0
                                  }
                                  readOnly
                                />
                              </td>
                              <td>
                                <div className="TableThreeDots">
                                  <ul>
                                    <li className="dropdown">
                                      <a
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <img src="images/dots.png" alt="menu" />
                                      </a>
                                      <ol className="dropdown-menu">
                                        <li
                                          onClick={() =>
                                            removeDetailsClick(index)
                                          }
                                        >
                                          <a href="javascript:void(0);">
                                            <img
                                              src="images/dropdown-delete.png"
                                              alt="delete"
                                            />
                                            Remove Line
                                          </a>
                                        </li>
                                      </ol>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="12">
                              <p>No Data found.</p>
                            </td>
                          </tr>
                        )
                      ) : ViewQuoteRequestVendorData?.VendorItems?.length >
                        0 ? (
                        ViewQuoteRequestVendorData?.VendorItems?.map(
                          (item, i) => (
                            <tr key={i}>
                              <td>{item.id}</td>
                              <td>{item.name}</td>
                              <td>{item.color}</td>
                              <td>{item.quantity}</td>
                              <td>{item.Unit?.name}</td>
                              <td>$ {item.price}</td>
                              <td>$ {item.total}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan="12">
                            <p>No Data found.</p>
                          </td>
                        </tr>
                      )}

                      {ViewQuoteRequestVendorData?.status === "SEND" && (
                        <tr>
                          <td colSpan={8}>
                            <div className="TableButtons">
                              <button
                                className="BrandBtn"
                                onClick={addDetailsClick}
                              >
                                Add Line
                              </button>
                              <button
                                className="BrandBtn ml-3"
                                onClick={handleClear}
                              >
                                Clear all lines
                              </button>
                              <button
                                className="BrandBtn ml-3"
                                onClick={copyBuilderItems}
                              >
                                <img src="images/copy_builder_item.png" />
                                Copy Builder´s Items
                              </button>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="TableBottom">
                  {ViewQuoteRequestVendorData?.status === "SEND" ? (
                    <>
                      <h6>
                        <span>Vendor's Pre-Tax Total: </span> $
                        {Number(
                          itemDetails
                            ?.reduce(
                              (total, data) =>
                                total + data.quantity * data.price,
                              0
                            )
                            .toFixed(2) || 0
                        )}
                      </h6>
                    </>
                  ) : (
                    <>
                      <h6>
                        <span>Vendor's Pre-Tax Total: </span> $
                        {Number(
                          ViewQuoteRequestVendorData?.VendorItems?.reduce(
                            (total, data) => total + data.quantity * data.price,
                            0
                          ).toFixed(2) ?? 0
                        )}
                      </h6>
                    </>
                  )}
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="form-group">
                    <div className="CommonModalArea">
                      <h5 className="YellowText">Price Valid Until</h5>
                      <div className="CommonModalBox">
                        {ViewQuoteRequestVendorData?.status === "SEND" ? (
                          <h5>
                            <figure>
                              <img src="images/calendar.png" />
                            </figure>{" "}
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Insert new entry name"
                              name="date"
                              value={date}
                              onChange={handleInputChange}
                            />
                          </h5>
                        ) : (
                          <h5>
                            <figure>
                              <img src="images/calendar.png" />
                            </figure>{" "}
                            <p>
                              {moment(ViewQuoteRequestVendorData?.date).format(
                                "YYYY-MM-DD"
                              )}
                            </p>
                          </h5>
                        )}
                      </div>
                    </div>
                  </div>
                  {ViewQuoteRequestVendorData.status === "SEND" ? (
                    <div className="GstTable">
                      <table>
                        <tbody>
                          <tr>
                            <td>Profile</td>
                            <td>
                              {ViewQuoteRequestVendorData?.TaxProfile?.name ??
                                "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>Subtotal</td>
                            <td>
                              $
                              {Number(
                                itemDetails
                                  ?.reduce(
                                    (total, data) =>
                                      total + data.quantity * data.price,
                                    0
                                  )
                                  .toFixed(2) || 0
                              )}
                            </td>
                            {/* <td>${totalWithoutTax}</td> */}
                            {/* <td>Modify Tax</td> */}
                          </tr>
                          <tr>
                            <td>
                              CGST(
                              { Number(ViewQuoteRequestVendorData?.gst)}
                              %)
                            </td>
                            <td>
                              $
                              {Number(
                                itemDetails
                                  ?.reduce(
                                    (total, data) =>
                                      total + data.quantity * data.price,
                                    0
                                  )
                                  .toFixed(2) || 0
                              )*Number(ViewQuoteRequestVendorData?.gst)/100}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {
                                ViewQuoteRequestVendorData?.TaxProfile
                                  ?.taxRows?.[0]?.taxName
                              }
                              (
                              {Number(
                                ViewQuoteRequestVendorData?.TaxProfile
                                  ?.taxRows?.[0]?.taxPercent
                              ) || 0}
                              %)
                            </td>
                            <td>
                              {" "}
                              $
                              {(Number(
                                itemDetails
                                  ?.reduce(
                                    (total, data) =>
                                      total + data.quantity * data.price,
                                    0
                                  )
                                  .toFixed(2) || 0
                              ) *
                                (Number(
                                  ViewQuoteRequestVendorData?.TaxProfile
                                    ?.taxRows?.[0]?.taxPercent
                                ) || 0)) /
                                100}
                            </td>
                          </tr>
                          <tr>
                            <td>Grand Total</td>
                            <td>
                              {/* ${Number(totalWithoutTax) + Number(finalTotal)} */}
                              $
                              {Number(
                                itemDetails
                                  ?.reduce(
                                    (total, data) =>
                                      total + data.quantity * data.price,
                                    0
                                  )
                                  .toFixed(2) || 0
                              ) +
                                (Number(
                                  itemDetails
                                    ?.reduce(
                                      (total, data) =>
                                        total + data.quantity * data.price,
                                      0
                                    )
                                    .toFixed(2) || 0
                                ) *
                                  (Number(
                                    ViewQuoteRequestVendorData?.TaxProfile
                                      ?.taxRows?.[0]?.taxPercent
                                  ) || 0)) /
                                  100+ Number(
                                    itemDetails
                                      ?.reduce(
                                        (total, data) =>
                                          total + data.quantity * data.price,
                                        0
                                      )
                                      .toFixed(2) || 0
                                  )*Number(ViewQuoteRequestVendorData?.gst)/100}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="GstTable">
                      <table>
                        <tbody>
                          <tr>
                            <td>Profile</td>
                            <td>
                              {ViewQuoteRequestVendorData?.TaxProfile?.name ??
                                "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>Subtotal</td>
                            <td>
                              ${Number(ViewQuoteRequestVendorData?.subtotal||0)}
                            </td>
                            {/* <td>Modify Tax</td> */}
                          </tr>
                          <tr>
                            <td>
                              CGST(
                              {
                                Number(ViewQuoteRequestVendorData?.gst)}
                              %)
                            </td>
                            <td>
                            ${
                            Number(ViewQuoteRequestVendorData?.subtotal)* (Number(ViewQuoteRequestVendorData?.gst/100))}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {
                                ViewQuoteRequestVendorData?.TaxProfile
                                  ?.taxRows?.[0]?.taxName
                              }
                              (
                              {Number(
                                ViewQuoteRequestVendorData?.TaxProfile
                                  ?.taxRows?.[0]?.taxPercent
                              ) || 0}
                              %)
                            </td>
                            <td>
                              {" "}
                              $
                              {(Number(ViewQuoteRequestVendorData?.subtotal) *
                                (Number(
                                  ViewQuoteRequestVendorData?.TaxProfile
                                    ?.taxRows?.[0]?.taxPercent
                                ) || 0)) /
                                100}
                            </td>
                          </tr>
                          <tr>
                            <td>Grand Total</td>
                            <td>
                              {/* ${Number(totalWithoutTax) + Number(finalTotal)} */}
                              $
                              {Number(ViewQuoteRequestVendorData?.subtotal) +
                              Number(ViewQuoteRequestVendorData?.subtotal)* (Number(ViewQuoteRequestVendorData?.gst/100))+
                                (Number(ViewQuoteRequestVendorData?.subtotal) *
                                  (Number(
                                    ViewQuoteRequestVendorData?.TaxProfile
                                      ?.taxRows?.[0]?.taxPercent
                                  ) || 0)) /
                                  100}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="mb-4">
                  <div className="Heading">
                    <h6>Attachments</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="CommonModalArea">
                  <div className="form-group" style={{cursor:"pointer"}}>
                    <h5>Download Scope of Work</h5>
                    <div className="CommonModalBox">
                      <h5 onClick={handleDownloadAllScopeDocs}>
                        <figure>
                          <img src="images/scopeofwork.png" />
                        </figure>{" "}
                        <span className="Count" >
                          {ViewQuoteRequestVendorData?.workDocuments?.length ??
                            0}
                        </span>
                        Download Scopes of Work
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="CommonModalArea">
                  <div className="form-group" style={{cursor:"pointer"}}>
                    <h5>Download Construction Documents</h5>
                    <div className="CommonModalBox">
                      <h5 onClick={handleDownloadAllConstructDocs}>
                        <figure>
                          <img src="images/construction_documents.png" />
                        </figure>{" "}
                        <span className="Count" >
                          {ViewQuoteRequestVendorData?.constructionDocuments
                            ?.length ?? 0}
                        </span>{" "}
                        Download Construction Documents
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="CommonModalArea">
                  <div className="form-group">
                    <h5>
                      {ViewQuoteRequestVendorData?.status === "SEND"
                        ? "Upload Your Quote"
                        : "Download Your Quote"}
                    </h5>
                    <div className="CommonModalBox">
                      {ViewQuoteRequestVendorData?.status === "SEND" ? (
                        <h5
                          className="CursorPointer"
                          onClick={handleAddNewSupportingDocsModalShow}
                        >
                          <figure>
                            <img src="images/extermal_quote.png" />
                          </figure>{" "}
                          <span className="Count">
                            {ViewQuoteRequestVendorData?.additionalDocuments
                              ?.length ?? 0}
                          </span>{" "}
                          Upload Your Quote
                        </h5>
                      ) : (
                        <h5 className="CursorPointer">
                          <figure>
                            <img src="images/extermal_quote.png" />
                          </figure>{" "}
                          <span className="Count">
                            {ViewQuoteRequestVendorData?.additionalDocuments
                              ?.length ?? 0}
                          </span>{" "}
                          <a
                            href={
                              ViewQuoteRequestVendorData
                                ?.additionalDocuments?.[0]?.docUrl
                            }
                            target="_self"
                          >
                            Download Your Quote
                          </a>
                        </h5>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {ViewQuoteRequestVendorData?.status === "SEND" ? (
              <div className="Button d-flex justify-content-center mt-4 ">
                <button className="Create" onClick={() => handleUpdateQr()}>
                  Save & Send
                </button>
              </div>
            ) : (
              <p>An Action has been already performed</p>
            )}
          </div>
        </div>
      </div>
      <Modal
        className="ModalBox fade SmallModal"
        show={AddNewSupportingDocsModal}
        onHide={handleAddNewSupportingDocsModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a
              className="CloseModal"
              onClick={handleAddNewSupportingDocsModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4> New Supporting Docs</h4>
              </div>
              <div className="JobTitleArea">
                <h3>Supporting Documents </h3>
              </div>
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input
                    type="file"
                    id="docsInput"
                    onChange={(e) => handleFileChanges(e, "docsUpdate")}
                  />
                </div>
              </div>
              <div className="FilesAddedTitle">
                <h4>Files</h4>
              </div>
              {supportingDocFiless && (
                <div className="FilesAdded">
                  <label>{supportingDocFiless?.name}</label>
                  <span>
                    <img src="images/fileview.png" />
                  </span>
                  <span onClick={handleRemoveDocsFile}>
                    <img src="images/Cross.png" className="cross" />
                  </span>
                </div>
              )}

              <div className="Button mt-3">
                <button
                  className="Create"
                  onClick={handleUploadAdditionalDocuments}
                >
                  Add Documents
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TradeViewQuoteRequest;
