import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getPerforma } from '../../redux/action/proformaAction';
import { getCitiesList, getCountriesList, getStateList, getTimeZoneList } from '../../redux/action/authAction';
import { useSelector } from 'react-redux';

const UpdatePerfomaGeneralDetail = ({
  handleInput,
  checkArray,
  proformaTypesListData,
  updateId,
  setShowli,
  hideUpdateProformaModal,
  handleShowComponent
}) => {
const dispatch=useDispatch()
const [getdataBack, setGetDataBack] = useState({
  id: updateId,
  companyId: null,
  count: null,
});
const [data,SetData]=useState({
  name: "",
  proformaTypeId: "",
  homeTypeId: "",
  stateCode: "",
  cityCode: "",
  jobTimeZone: "",
  country: "",
  projectName: "",
  firstStageErrors: {},
  blockFirstStepNumber: 1,
  blockfirstStep: [
    {
      blockDescription: "",
      foundationType: "",
      numOfUnits: "",
      numOfStories: "",
      foundationAndSubFloor: "",
      blockTotal: "",
      commonAreas: "",
      roof: "",
      exteriorWalls: "",
    },
  ],
})
const { countryData, cityData, stateData, timeZoneData } = useSelector(
  (state) => state.authReducer
);


useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await dispatch(getPerforma(getdataBack.id));
      const data = response.data;

      SetData((prev) => ({
        ...prev,
        name: data.data.name,
        proformaTypeId: data.data.proformaTypeId,
        homeTypeId: data.data.homeTypeId,
        stateCode: data.data.stateCode,
        cityCode: data.data.cityCode,
        jobTimeZone: data.data.jobTimeZone,
        projectName: data.data.projectName,
        country: data.data.country,
      }));

      await dispatch(getCountriesList());
      await dispatch(getTimeZoneList());

      const country = countryData?.countries?.find(
        (x) => x.name === data.data.country
      )?.isoCode;

    const stateData=  await dispatch(getStateList(country))
    const state=stateData.states.find(
      (x) => x.name === data.data.stateCode
      )?.isoCode;
      await dispatch(getCitiesList(country, state));

     

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  fetchData();
}, []);

const selected=(val1,val2)=>{
return val1==val2?true:false
}
 


  return (
    <>
    <div className="FolderFormCreateArea">
      <div className="FieldsMandatory">
        <h6>
          Fields with <span className="required">*</span> are
          mandatory
        </h6>
      </div>
      <div className="row">
        <div className="col-md-5">
          <div className="form-group">
            <h6>
              Proforma Name<span className="required">*</span>
            </h6>
            <input
              type="text"
              className="form-control"
              placeholder="Insert Proforma name"
              name="name"
              value={data.name}
              onChange={handleInput}
            />
            <span style={{ color: "red", fontSize: "14px" }}>
            
            </span>
          </div>
          <div className="form-group">
            <h6>
              Type <span className="required">*</span>
            </h6>
            <div className="CommonSelectBox">
              <select
                name="proformaTypeId"
                value={data.proformaTypeId}
                onChange={handleInput}
              >
                <option>Select Type</option>
                {checkArray(proformaTypesListData?.data) ? (
                  <>
                    {proformaTypesListData?.data?.map((ele, i) => {
                      return (
                        <option value={ele?.id}>{ele?.name}</option>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </select>
              {/* <span>
                <img src="images/down.png" />
              </span> */}
            </div>
            <span style={{ color: "red", fontSize: "14px" }}>
            </span>
          </div>
          <div className="form-group">
            <h6>
              Home Type <span className="required">*</span>
            </h6>
            <div className="CommonSelectBox">
              <select
                name="homeTypeId"
                value={data.homeTypeId}
                onChange={handleInput}
              >
                <option>Select Home type</option>
                {checkArray(proformaTypesListData?.data) ? (
                  <>
                    {proformaTypesListData?.data?.map((ele, i) => {
                      return (
                        <option value={ele?.id}>{ele?.name}</option>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </select>
              {/* <span>
                <img src="images/down.png" />
              </span> */}
            </div>
            <span style={{ color: "red", fontSize: "14px" }}>
            </span>
          </div>
          <div className="form-group">
            <h6>
              Country <span className="required">*</span>
            </h6>
            <div className="CommonSelectBox">
              <select
                name="country"
                value={data.country}
                onChange={handleInput}
              >
                <option>Select Country</option>
                {checkArray(countryData?.countries) ? (
                  <>
                    {countryData?.countries?.map((ele, i) => {
                      return (
                        <option value={ele?.name}
                        >{ele?.name}</option>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </select>
              {/* <span>
                <img src="images/down.png" />
              </span> */}
            </div>
            {/* <span style={{color:"red", fontSize:"14px"}}>{firstStageErrors?.countryError}</span> */}
          </div>
          <div className="form-group">
            <h6>
              State/Province <span className="required">*</span>
            </h6>
            <div className="CommonSelectBox">
              <select
                name="stateCode"
                value={data?.stateCode}
                onChange={handleInput}
              >
                <option>Select State</option>
                {checkArray(stateData?.states) ? (
                  <>
                    {stateData?.states?.map((ele, i) => {
                      return (
                        <option value={ele?.name}
                        selected={selected(data?.stateCode,ele?.name)}
                        >{ele?.name}</option>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </select>
              {/* <span>
                <img src="images/down.png" />
              </span> */}
            </div>
            <span style={{ color: "red", fontSize: "14px" }}>
            </span>
          </div>
          <div className="form-group">
            <h6>
              City <span className="required">*</span>
            </h6>
            <div className="CommonSelectBox">
              <select
                name="cityCode"
                value={data?.cityCode}
                onChange={handleInput}
              >
                <option>Select City</option>
                {checkArray(cityData?.cities) ? (
                  <>
                    {cityData?.cities?.map((ele, i) => {
                      return (
                        <option 
                        selected={data?.cityCode==ele?.name}
                        value={ele?.name }>{ele?.name}</option>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </select>
              {/* <span>
                <img src="images/down.png" />
              </span> */}
            </div>
            <span style={{ color: "red", fontSize: "14px" }}>
            </span>
          </div>
          <div className="form-group">
            <h6>
              Job Site GMT (UTC) Timezone{" "}
              <span className="required">*</span>
            </h6>
            <div className="CommonSelectBox">
              <select
                name="jobTimeZone"
                value={data.jobTimeZone}
                onChange={handleInput}
              >
                <option>Select Timezone</option>
                {checkArray(timeZoneData?.data) ? (
                  <>
                    {timeZoneData?.data?.map((ele, i) => {
                      return (
                        <option value={ele?.zoneName}
                        // selected={selected(data?.jobTimeZone,ele?.gmtOffset)}
                        >
                          {ele?.zoneName}
                        </option>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </select>
              {/* <span>
                <img src="images/down.png" />
              </span> */}
            </div>
            <span style={{ color: "red", fontSize: "14px" }}>
            </span>
          </div>
        </div>
        <div className="col-md-5 ml-auto">
          <div className="form-group">
            <h6>Name of Project</h6>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Name of Project"
              name="projectName"
              value={data.projectName}
              onChange={handleInput}
            />
            <span style={{ color: "red", fontSize: "14px" }}>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div className="Button d-flex  justify-content-between align-items-center px-5">
                <button
                  className="Outline border text-success border-success"
                  onClick={hideUpdateProformaModal}
                >
                  Cancel
                </button>
                <button
                  className="Create bg-success border border-success"
                   onClick={() => handleShowComponent("block")}
                   >
                  Save Draft
                </button>
               
              </div>
    </>
    
  
  )
}

export default UpdatePerfomaGeneralDetail