import { createContext } from "react";

export const themes = {
  yellow: "yellow",
  cyan:"cyan",
  lightgreen: "lightgreen",
};

export const ThemeContext = createContext({
    theme: themes.yellow,
  changeTheme: () => {return themes.type},
});