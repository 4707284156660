import React, {useState, useEffect} from 'react'
import Modal from "react-bootstrap/Modal";
import { getFoundationType } from '../../redux/action/proformaAction';
import { useDispatch , useSelector} from 'react-redux';
import CreateUnits from './CreateUnits';
import {toast} from "react-toastify"
import { CreateUnitBlockAction, getMultifamilyJobAction, updateMultiFamilyJobAction } from '../../redux/action/MultifamilyJobActionDk';

const CreateBlock = ({createBlockModal, setCreateBlockModal, setEditJobModal,selectedId,jobData}) => {

  const [itemId, setItemId] = useState()

    const handleJobDetialsModalClose = () => {
        setCreateBlockModal(false);
        // setEditJobModal(true)
      };
      const handleJobDetialsModalShow = () => {
        setCreateBlockModal(true);
      };


    

      const [createUnitsModal, setCreateUnitsModal] = useState(false)

      const dispatch = useDispatch()
      const {
        foundationListData,
    
        foundationTypeData,
    
      } = useSelector((state) => state.ProformaReducer);
    

      const [count, setCount] = useState(1);
      const [blocks, setBlocks] = useState(
        Array(+count).fill({
          blockDescription: "Block A",
          foundationType: 1,
          numOfUnits: 2,
          numOfStories: 2,
          units:[],
          stories: [
            {
              value: 222,
            },
          ],
        })
      );

    
      const handleCount = (e) => {
        setCount(e.target.value);
    
        let newArray;
        let localValue = count;
        let tempTotalPerson = [...blocks];
        let currentValue = Math.abs(localValue - e.target.value);
    
        if (count > e.target.value) {
          newArray = blocks.slice(0, e.target.value);
        } else {
          let tempArray = new Array(currentValue).fill({
            blockDescription: "Block A",
            foundationType: 1,
            numOfUnits: 0,
            numOfStories: 0,
            units:[],
            stories: [
              {
                value: 0,
              },
            ],
          });
    
          newArray = [...tempTotalPerson, ...tempArray];
        }
        setBlocks(newArray);
      };
    
      useEffect(()=>{
        dispatch(getFoundationType());
         if(createBlockModal){
          dispatch(getMultifamilyJobAction(selectedId)).then((res) => {
            setItemId(res?.data?.[0]?.id)
          })
        } 
      },[createBlockModal])

      const handleSubmit =(e)=>{
        e.preventDefault()
    
        // UpdateState({...iState, blocks:blocks})
        setCreateUnitsModal(true)
        setCreateBlockModal(false)
    
    
    
      }


      const handleSubmitBlock = (e) => {
        e.preventDefault();
    
        let formIsValid = true
    
        let data ={
        "numOfBlocks": count,
        blocks
     
        
        }
    
        if (formIsValid) {
          dispatch(CreateUnitBlockAction(selectedId ,itemId,data))
          .then((res) => {

            if (res.data.message == 'Block created successfully') {
              toast.success("Block created successfully", {
                position: toast.POSITION.TOP_RIGHT,
              });
    
              setCreateBlockModal(false);
            } else {
              setCreateBlockModal(false);

              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch((error) => {
            setCreateBlockModal(false);

            // toast.success(error.message, {
            //   position: toast.POSITION.TOP_RIGHT,
            // });
          });    }
      };


      console.log(jobData ,'JJJJJJJJJJJJJJJJJJ')
  return (
   <>
    <Modal show={createBlockModal} className="ModalBox LargeModal">
    <div class="modal-content BgCreate">
      <a
        class="CloseModal"
        onClick={handleJobDetialsModalClose}
        data-dismiss="modal"
      >
        ×
      </a>


      <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Create Multi-Family Blocks</h4>
            </div>


            <div class="StepBox">
              <ul>
                <li class="active">
                  <span class="Icon">
                    <img src="images/blocks.png" />
                  </span>
                  <span class="Text">Blocks</span>
                </li>
                <li>
                </li>
                <li>
                </li>
                <li>
                </li>
                <li>
                </li>
                <li>
                </li>
                <li>
                  <span class="Icon">
                    <img src="images/units.png" />
                  </span>
                  <span class="Text">Units</span>
                </li>
              </ul>
            </div>
            <div class="FolderForm">

              <form>
                <div class="FolderFormCreateArea">
                  <div class="ModalMediumheading">
                    <h3><span>Project:</span> {jobData?.projectName}</h3>
                    <h3><span>Job id:</span> {jobData?.jobNumber}</h3>
                  </div>
                  <div class="ModalBigheading2 d-flex align-items-center mb-3">
                    <h3 class="mb-0">Enter Number of Blocks to be added</h3>
                    <input
                    class="form-control ModalBigheadingBox"
                    onChange={handleCount}
                  />                  </div>
                  <div class="EnterProjectTable">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Block Description</th>
                        <th>Foundation type</th>
                        <th># of Units Per Block</th>
                        <th># of Stories Per Block</th>
                      </tr>
                    </thead>
                    <tbody>
                      {blocks?.map((item, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>
                            <div class="form-group">
                              <input
                                type="text"
                                onChange={(e) => {
                                  blocks[i].blockDescription = e.target.value;
                                  let value = [...blocks];
                                  setBlocks(value);
                                }}
                                class="form-control"
                                placeholder="Block A"
                              />
                            </div>
                          </td>
                          <td>
                            <div class="CommonSelectBox">
                              <select
                                onChange={(e) => {
                                  blocks[i].foundationType = e.target.value;
                                  let value = [...blocks];
                                  setBlocks(value);
                                }}
                              >       
                                <option value="">Select Foundation Type</option>
                              {foundationTypeData?.data?.length > 0 &&
                                foundationTypeData?.data?.map((data, i) => {
                                  return (
                                    <option key={i} value={data?.id}>
                                      {data?.name}
                                    </option>
                                  );
                                })}
                                  </select>
                              <span>
                              </span>
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input
                                type="number"
                                onChange={(e) => {
                                  blocks[i].numOfUnits = parseInt(e.target.value)
                                  let value = [...blocks];
                                  setBlocks(value);
                                }}
                                class="form-control"
                                placeholder="Units per block"
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              <input
                                type="number"
                                onChange={(e) => {
                                  blocks[i].numOfStories = parseInt(e.target.value)
                                  let value = [...blocks];
                                  setBlocks(value);
                                }}
                                class="form-control"
                                placeholder="Insert number of stories"
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                    <div class="PaginationArea">
                      <h6>Showing 1 to 10 of 27 entries</h6>
                      <h6><img src="images/leftarrow.png" /> 01 <img src="images/rightarrow.png" /> Out 10</h6>
                    </div>
                  </div>
                </div>
                <div class="Button d-flex justify-content-between align-items-center">
                  <button class="Outline" data-dismiss="modal" >Back</button>
                  <button class="Create FolderPermissionId" onClick={handleSubmitBlock}>Create Blocks Only</button>
                  <button onClick={handleSubmit} class="Create FolderPermissionId">Create Units</button>
                </div>
              </form>

            </div>

          </div>
      </div>
      </Modal> 
      <CreateUnits createUnitsModal={createUnitsModal} setCreateUnitsModal={setCreateUnitsModal} setCreateBlockModal={setCreateBlockModal} units={blocks} jobId={itemId} selectedId={selectedId}  createdFrom={'block'} jobData={jobData} /></> )
}

export default CreateBlock