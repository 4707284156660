import React from 'react'

const MainNewPasswordCreated = () => {
  return (
    <>
    <div className="LoginArea">
  <div className="LoginBoxBig">
    <div className="row">
      <div className="col-md-6">
        <div className="LoginBoxLeft">
          <figure>
            <img src="images/login_background.png" />
          </figure>
        </div>
      </div>
      <div className="col-md-6">
        <div className="LoginBox">
          <div className="Logo">
            <img src="images/Logo.png" />
          </div>
          <ul className="nav nav-fill">
            <li className="nav-item">
              <a
                href="#SystemAdmin"
                className="nav-link active"
                data-toggle="tab"
              >
                System Admin
              </a>
            </li>
            <li className="nav-item">
              <a href="#ClientView" className="nav-link" data-toggle="tab">
                Client View
              </a>
            </li>
            <li className="nav-item">
              <a href="#TradeView" className="nav-link" data-toggle="tab">
                Trade View
              </a>
            </li>
            <li className="nav-item">
              <a href="#BuilderUser" className="nav-link" data-toggle="tab">
                Builder User
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane fade show active" id="ClientView">
              <h3 className="mb-5">New password created!</h3>
              <p className="mb-5">
                Your new password has been created and is ready to be used!
                Please click on the button bellow to be redirected to the login
                page or click close login at a later time.
              </p>
              <form>
                <a className="Button" href="main_login.html">
                  Back to Login
                </a>
                <a className="ButtonOutline mt-3" href="main_login.html">
                  Close
                </a>
              </form>
              <div className="loginSocials">
                <a href="javascript:void(0);">
                  <img src="images/facebook_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/earth_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/mail_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/linkdin_red.png" />
                </a>
              </div>
              <div className="TabContentBottom">
                <p className="">
                  Not an <span>Ibuild</span> customer yet?{" "}
                  <a href="javascript:void(0);">Subscribe Now</a>
                </p>
                <div className="Logo">
                  <img src="images/Logo.png" />
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="ClientView">
              <h3 className="mb-5">New password created!</h3>
              <p className="mb-5">
                Your new password has been created and is ready to be used!
                Please click on the button bellow to be redirected to the login
                page or click close login at a later time.
              </p>
              <form>
                <a className="Button" href="main_login.html">
                  Back to Login
                </a>
                <a className="ButtonOutline mt-3" href="main_login.html">
                  Close
                </a>
              </form>
              <div className="loginSocials">
                <a href="javascript:void(0);">
                  <img src="images/facebook_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/earth_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/mail_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/linkdin_red.png" />
                </a>
              </div>
              <div className="TabContentBottom">
                <p className="">
                  Not an <span>Ibuild</span> customer yet?{" "}
                  <a href="javascript:void(0);">Subscribe Now</a>
                </p>
                <div className="Logo">
                  <img src="images/Logo.png" />
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="ClientView">
              <h3 className="mb-5">New password created!</h3>
              <p className="mb-5">
                Your new password has been created and is ready to be used!
                Please click on the button bellow to be redirected to the login
                page or click close login at a later time.
              </p>
              <form>
                <a className="Button" href="main_login.html">
                  Back to Login
                </a>
                <a className="ButtonOutline mt-3" href="main_login.html">
                  Close
                </a>
              </form>
              <div className="loginSocials">
                <a href="javascript:void(0);">
                  <img src="images/facebook_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/earth_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/mail_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/linkdin_red.png" />
                </a>
              </div>
              <div className="TabContentBottom">
                <p className="">
                  Not an <span>Ibuild</span> customer yet?{" "}
                  <a href="javascript:void(0);">Subscribe Now</a>
                </p>
                <div className="Logo">
                  <img src="images/Logo.png" />
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="ClientView">
              <h3 className="mb-5">New password created!</h3>
              <p className="mb-5">
                Your new password has been created and is ready to be used!
                Please click on the button bellow to be redirected to the login
                page or click close login at a later time.
              </p>
              <form>
                <a className="Button" href="main_login.html">
                  Back to Login
                </a>
                <a className="ButtonOutline mt-3" href="main_login.html">
                  Close
                </a>
              </form>
              <div className="loginSocials">
                <a href="javascript:void(0);">
                  <img src="images/facebook_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/earth_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/mail_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/linkdin_red.png" />
                </a>
              </div>
              <div className="TabContentBottom">
                <p className="">
                  Not an <span>Ibuild</span> customer yet?{" "}
                  <a href="javascript:void(0);">Subscribe Now</a>
                </p>
                <div className="Logo">
                  <img src="images/Logo.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default MainNewPasswordCreated