import { taskAction } from "../actionTypes";
import { Url } from "../../Config/Config";
import axios from "axios";
import { isLoggedIn } from "../../utils/tokenCheck";
import { multiPartData, multiPartDatas } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

//---------------------------------taskCreate----------------------------------------------------
export const addTaskInitiate = () => ({
    type: taskAction.ADD_TASK_INITIATE,
  });
  
  export const addTaskSuccess = (data) => ({
    type: taskAction.ADD_TASK_SUCCESS,
    payload: data,
  });
  
  export const addTaskFailure = (data) => ({
    type: taskAction.ADD_TASK_FAILURE,
    payload: data,
  });
  
  export const getTaskCreate = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(addTaskInitiate(payload));
        const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 201) {
          dispatch(addTaskSuccess(response.data));
        } else {
          dispatch(addTaskFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(addTaskFailure(err));
        return err.response
      }
    };
//---------------------------------tasklISTjOB----------------------------------------------------
export const taskListJobInitiate = () => ({
    type: taskAction.TASK_JOB_LIST_INITIATE,
  });
  
  export const taskListJobSuccess = (data) => ({
    type: taskAction.TASK_JOB_LIST_SUCCESS,
    payload: data,
  });
  
  export const taskListJobFailure = (data) => ({
    type: taskAction.TASK_JOB_LIST_FAILURE,
    payload: data,
  });
  
  export const getTaskListJob = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(taskListJobInitiate(payload));
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/jobs`, {
          headers: { Authorization: `Bearer ${token}` },
        //   ?jobType=ResidentialJob
        });
        if (response.status == 200) {
          dispatch(taskListJobSuccess(response.data));
        } else {
          dispatch(taskListJobFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(taskListJobFailure(err));
        return err.response
      }
    };

//---------------------------------CreatedtaskList----------------------------------------------------
export const createdTaskListInitiate = () => ({
    type: taskAction.CREATED_TASK_LIST_INITIATE,
  });
  
  export const createdTaskListSuccess = (data) => ({
    type: taskAction.CREATED_TASK_LIST_SUCCESS,
    payload: data,
  });
  
  export const createdTaskListFailure = (data) => ({
    type: taskAction.CREATED_TASK_LIST_FAILURE,
    payload: data,
  });
  
  export const getCreatedTaskList = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(createdTaskListInitiate(payload));
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks?taskStatus=Created&showAll=${payload.showAll??""}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(createdTaskListSuccess(response.data));
        } else {
          dispatch(createdTaskListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(createdTaskListFailure(err));
        return err.response
      }
    };
//---------------------------------iNprocesstaskList----------------------------------------------------
export const inProcessTaskListInitiate = () => ({
  type: taskAction.INPROGRESS_TASK_LIST_INITIATE,
});

export const inProcessTaskListSuccess = (data) => ({
  type: taskAction.INPROGRESS_TASK_LIST_SUCCESS,
  payload: data,
});

export const inProcessTaskListFailure = (data) => ({
  type: taskAction.INPROGRESS_TASK_LIST_FAILURE,
  payload: data,
});

export const getInProcessTaskList = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(inProcessTaskListInitiate(payload));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks?taskStatus=In-Progress&showAll=false`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(inProcessTaskListSuccess(response.data));
      } else {
        dispatch(inProcessTaskListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(inProcessTaskListFailure(err));
      return err.response
    }
  };

  //---------------------------------completedtaskList----------------------------------------------------
export const completeTaskListInitiate = () => ({
  type: taskAction.COMPLETED_TASK_LIST_INITIATE,
});

export const completeTaskListSuccess = (data) => ({
  type: taskAction.COMPLETED_TASK_LIST_SUCCESS,
  payload: data,
});

export const completeTaskListFailure = (data) => ({
  type: taskAction.COMPLETED_TASK_LIST_FAILURE,
  payload: data,
});

export const getCompleteTaskList = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(completeTaskListInitiate(payload));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks?taskStatus=Completed&showAll=false`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(completeTaskListSuccess(response.data));
      } else {
        dispatch(completeTaskListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(completeTaskListFailure(err));
      return err.response
    }
  };

//---------------------------------taskMemberList----------------------------------------------------
export const taskMemberListInitiate = () => ({
  type: taskAction.TASK_MEMBER_LIST_INITIATE,
});

export const taskMemberListSuccess = (data) => ({
  type: taskAction.TASK_MEMBER_LIST_SUCCESS,
  payload: data,
});

export const taskMemberListFailure = (data) => ({
  type: taskAction.TASK_MEMBER_LIST_FAILURE,
  payload: data,
});

export const getTaskMemberList = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(taskMemberListInitiate(payload));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/user-list`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(taskMemberListSuccess(response.data));
      } else {
        dispatch(taskMemberListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(taskMemberListFailure(err));
      return err.response
    }
  };

  
//---------------------------------taskhISTROYList----------------------------------------------------
export const taskHistoryListInitiate = () => ({
  type: taskAction.TASK_HISTORY_LIST_INITIATE,
});

export const taskHistoryListSuccess = (data) => ({
  type: taskAction.TASK_HISTORY_LIST_SUCCESS,
  payload: data,
});

export const taskHistoryListFailure = (data) => ({
  type: taskAction.TASK_HISTORY_LIST_FAILURE,
  payload: data,
});

export const getTaskHistoryList = (taskId) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(taskHistoryListInitiate(taskId));
    const response = await axios.get(
      `${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks/${taskId}/history`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(taskHistoryListSuccess(response.data));
    } else {
      dispatch(taskHistoryListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(taskHistoryListFailure(err));
    return err.response;
  }
};

//---------------------------------taskdELETE----------------------------------------------------
export const deleteTasknitiate = () => ({
  type: taskAction.DELETE_TASK_INITIATE,
});

export const deleteTaskSuccess = (data) => ({
  type: taskAction.DELETE_TASK_SUCCESS,
  payload: data,
});

export const deleteTaskFailure = (data) => ({
  type: taskAction.DELETE_TASK_FAILURE,
  payload: data,
});

export const getTaskDelete = (taskId) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    const { taskId: extractedDocId } = taskId;
    dispatch(deleteTasknitiate(extractedDocId));
    const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks/${extractedDocId}`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(deleteTaskSuccess(response.data));
    } else {
      dispatch(deleteTaskFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(deleteTaskFailure(err));
    return err.response;
  }
};

//---------------------------------taskaSSIGN----------------------------------------------------
export const assignTaskInitiate = () => ({
  type: taskAction.ASSIGN_TASK_INITIATE,
});

export const assignTaskSuccess = (data) => ({
  type: taskAction.ASSIGN_TASK_SUCCESS,
  payload: data,
});

export const assignTaskFailure = (data) => ({
  type: taskAction.ASSIGN_TASK_FAILURE,
  payload: data,
});

export const getTaskAssign = (taskId,payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(assignTaskInitiate(payload));
    const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks/${taskId}/update-assignees`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(assignTaskSuccess(response.data));
    } else {
      dispatch(assignTaskFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(assignTaskFailure(err));
    return err.response;
  }
};

//---------------------------------manageUsertaskList-----------------------------------------------
export const manageAccessTaskListInitiate = () => ({
  type: taskAction.MANAGE_USER_TASK_LIST_INITIATE,
});

export const manageAccessTaskListSuccess = (data) => ({
  type: taskAction.MANAGE_USER_TASK_LIST_SUCCESS,
  payload: data,
});

export const manageAccessTaskListFailure = (data) => ({
  type: taskAction.MANAGE_USER_TASK_LIST_FAILURE,
  payload: data,
});

export const getManageAccessTaskList = (payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(manageAccessTaskListInitiate(payload));
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/manage-access-to-users-task-lists?page=${payload.page}&itemsPerPage=10`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(manageAccessTaskListSuccess(response.data));
    } else {
      dispatch(manageAccessTaskListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(manageAccessTaskListFailure(err));
    return err.response;
  }
};

//---------------------------------REminderUpdate-----------------------------------------------
export const updateReminderInitiate = () => ({
  type: taskAction.UPDATE_REMINDER_INITIATE,
});

export const updateReminderSuccess = (data) => ({
  type: taskAction.UPDATE_REMINDER_SUCCESS,
  payload: data,
});

export const updateReminderFailure = (data) => ({
  type: taskAction.UPDATE_REMINDER_FAILURE,
  payload: data,
});

export const updatedReminder = (taskId,payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(updateReminderInitiate(payload));
    const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks/${taskId}/set-reminder`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(updateReminderSuccess(response.data));
    } else {
      dispatch(updateReminderFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(updateReminderFailure(err));
    return err.response;
  }
};

//---------------------------------taskRequestAccess-----------------------------------------------
export const taskRequestAccessInitiate = () => ({
  type: taskAction.TASK_REQUEST_ACCESS_INITIATE,
});

export const taskRequestAccessSuccess = (data) => ({
  type: taskAction.TASK_REQUEST_ACCESS_SUCCESS,
  payload: data,
});

export const taskRequestAccessFailure = (data) => ({
  type: taskAction.TASK_REQUEST_ACCESS_FAILURE,
  payload: data,
});

export const getTaskRequestAccess = (payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(taskRequestAccessInitiate(payload));
    const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks-request-access`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(taskRequestAccessSuccess(response.data));
    } else {
      dispatch(taskRequestAccessFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(taskRequestAccessFailure(err));
    return err.response;
  }
};

//---------------------------------GOOglecalendar-----------------------------------------------
export const googleCalendarInitiate = () => ({
  type: taskAction.GOOGLE_CALENDAR_DATA_INITIATE,
});

export const googleCalendarSuccess = (data) => ({
  type: taskAction.GOOGLE_CALENDAR_DATA_SUCCESS,
  payload: data,
});

export const googleCalendarFailure = (data) => ({
  type: taskAction.GOOGLE_CALENDAR_DATA_FAILURE,
  payload: data,
});

export const getGoogleCalendar = (payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(googleCalendarInitiate(payload));
    const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/create-google-calender`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(googleCalendarSuccess(response.data));
    } else {
      dispatch(googleCalendarFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(googleCalendarFailure(err));
    return err.response;
  }
};


//---------------------------------OUTLOOKcalendar-----------------------------------------------
export const outlookCalendarInitiate = () => ({
  type: taskAction.OUTLOOK_CALENDAR_DATA_INITIATE,
});

export const outlookCalendarSuccess = (data) => ({
  type: taskAction.OUTLOOK_CALENDAR_DATA_SUCCESS,
  payload: data,
});

export const outlookCalendarFailure = (data) => ({
  type: taskAction.OUTLOOK_CALENDAR_DATA_FAILURE,
  payload: data,
});

export const getOutlookCalendar = (payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(outlookCalendarInitiate(payload));
    const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/create-outlook-calender`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(outlookCalendarSuccess(response.data));
    } else {
      dispatch(outlookCalendarFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(outlookCalendarFailure(err));
    return err.response;
  }
};


//---------------------------------email-calendar-----------------------------------------------
export const emailCalendarInitiate = () => ({
  type: taskAction.EMAIL_CALENDAR_DATA_INITIATE,
});

export const emailCalendarSuccess = (data) => ({
  type: taskAction.EMAIL_CALENDAR_DATA_SUCCESS,
  payload: data,
});

export const emailCalendarFailure = (data) => ({
  type: taskAction.EMAIL_CALENDAR_DATA_FAILURE,
  payload: data,
});

export const getEmailCalendar = (payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(emailCalendarInitiate(payload));
    const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/create-yahoo-calender`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(emailCalendarSuccess(response.data));
    } else {
      dispatch(emailCalendarFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(emailCalendarFailure(err));
    return err.response;
  }
};


//---------------------------------hideTask-----------------------------------------------
export const hideTaskInitiate = () => ({
  type: taskAction.HIDE_TASK_INITIATE,
});

export const hideTaskSuccess = (data) => ({
  type: taskAction.HIDE_TASK_SUCCESS,
  payload: data,
});

export const hideTaskFailure = (data) => ({
  type: taskAction.HIDE_TASK_FAILURE,
  payload: data,
});

export const taskHide = (taskId,payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(hideTaskInitiate(payload));
    const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks/${taskId}/task-hide`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(hideTaskSuccess(response.data));
    } else {
      dispatch(hideTaskFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(hideTaskFailure(err));
    return err.response;
  }
};


//---------------------------------UnhideTask-----------------------------------------------
export const unhideTaskInitiate = () => ({
  type: taskAction.UNHIDE_TASK_INITIATE,
});

export const unhideTaskSuccess = (data) => ({
  type: taskAction.UNHIDE_TASK_SUCCESS,
  payload: data,
});

export const unhideTaskFailure = (data) => ({
  type: taskAction.UNHIDE_TASK_FAILURE,
  payload: data,
});

export const taskUnHide = (taskId,payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(unhideTaskInitiate(payload));
    const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks/${taskId}/task-unhide`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(unhideTaskSuccess(response.data));
    } else {
      dispatch(unhideTaskFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(unhideTaskFailure(err));
    return err.response;
  }
};


//---------------------------------showAllHiddenTask-----------------------------------------------
export const showAllTaskInitiate = () => ({
  type: taskAction.SHOW_ALL_TASK_INITIATE,
});

export const showAllTaskSuccess = (data) => ({
  type: taskAction.SHOW_ALL_TASK_SUCCESS,
  payload: data,
});

export const showAllTaskFailure = (data) => ({
  type: taskAction.SHOW_ALL_TASK_FAILURE,
  payload: data,
});

export const geAllShowTask = (payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(showAllTaskInitiate(payload));
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks?showAll=true`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(showAllTaskSuccess(response.data));
    } else {
      dispatch(showAllTaskFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(showAllTaskFailure(err));
    return err.response;
  }
};


//---------------------------------CANCELTask---------------------------------------------------
export const cancelTaskInitiate = () => ({
  type: taskAction.CANCEL_TASK_INITIATE,
});

export const cancelTaskSuccess = (data) => ({
  type: taskAction.CANCEL_TASK_SUCCESS,
  payload: data,
});

export const cancelTaskFailure = (data) => ({
  type: taskAction.CANCEL_TASK_FAILURE,
  payload: data,
});

export const getTaskCancel = (taskId,payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    const { taskId: extractedDocId } = taskId;
    dispatch(cancelTaskInitiate(extractedDocId));
    const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks/${extractedDocId}/task-status-cancelled`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(cancelTaskSuccess(response.data));
    } else {
      dispatch(cancelTaskFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(cancelTaskFailure(err));
    return err.response;
  }
};

//---------------------------------ShowHidennLlIST--------------------------------------------------
export const showHiddenListInitiate = () => ({
  type: taskAction.SHOW_HIDDEN_LIST_INITIATE,
});

export const showHiddenListSuccess = (data) => ({
  type: taskAction.SHOW_HIDDEN_LIST_SUCCESS,
  payload: data,
});

export const showHiddenListFailure = (data) => ({
  type: taskAction.SHOW_HIDDEN_LIST_FAILURE,
  payload: data,
});

export const getShowHiddenList = (payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(showHiddenListInitiate(payload));
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks?showAll=true`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(showHiddenListSuccess(response.data));
    } else {
      dispatch(showHiddenListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(showHiddenListFailure(err));
    return err.response;
  }
};


//---------------------------------ShowCANCELlIST--------------------------------------------------
export const showCancelListInitiate = () => ({
  type: taskAction.SHOW_CANCEL_LIST_INITIATE,
});

export const showCancelListSuccess = (data) => ({
  type: taskAction.SHOW_CANCEL_LIST_SUCCESS,
  payload: data,
});

export const showCancelListFailure = (data) => ({
  type: taskAction.SHOW_CANCEL_LIST_FAILURE,
  payload: data,
});

export const getShowCancelList = (payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(showCancelListInitiate(payload));
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks?taskStatus=Cancelled`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(showCancelListSuccess(response.data));
    } else {
      dispatch(showCancelListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(showCancelListFailure(err));
    return err.response;
  }
};


//---------------------------------ShowMOVElIST--------------------------------------------------
export const showMoveListInitiate = () => ({
  type: taskAction.SHOW_MOVE_LIST_INITIATE,
});

export const showMoveListSuccess = (data) => ({
  type: taskAction.SHOW_MOVE_LIST_SUCCESS,
  payload: data,
});

export const showMoveListFailure = (data) => ({
  type: taskAction.SHOW_MOVE_LIST_FAILURE,
  payload: data,
});

export const getShowMoveList = (payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(showMoveListInitiate(payload));
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks?taskStatus=Moved`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(showMoveListSuccess(response.data));
    } else {
      dispatch(showMoveListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(showMoveListFailure(err));
    return err.response;
  }
};

//==================================addInternity=============================================

export const addInternityInitiate = () => ({
  type: taskAction.ADD_ITINERTY_INITIATE,
});

export const addInternitySuccess = (data) => ({
  type: taskAction.ADD_ITINERTY_SUCCESS,
  payload: data,
});

export const addInternityFailure = (data) => ({
  type: taskAction.ADD_ITINERTY_FAILURE,
  payload: data,
});

export const getAddInternity = (payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(addInternityInitiate(payload));
    const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/itineraries`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 201) {
      dispatch(addInternitySuccess(response.data));
    } else {
      dispatch(addInternityFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(addInternityFailure(err));
    return err.response;
  }
};

//========================================CalendarTaskList============================================

export const calendarTaskListInitiate = () => ({
  type: taskAction.CALENDAR_TASK_LIST_INITIATE,
});

export const calendarTaskListSuccess = (data) => ({
  type: taskAction.CALENDAR_TASK_LIST_SUCCESS,
  payload: data,
});

export const calendarTaskListFailure = (data) => ({
  type: taskAction.CALENDAR_TASK_LIST_FAILURE,
  payload: data,
});

export const getCalendarTaskList = (payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(calendarTaskListInitiate(payload));
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks-calendar?startDate=${payload.startDate}&endDate=${payload.endDate}&taskStatus=Created`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(calendarTaskListSuccess(response.data));
    } else {
      dispatch(calendarTaskListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(calendarTaskListFailure(err));
    return err.response;
  }
};

//=======================================VIEWTask============================================

export const viewTaskInitiate = () => ({
  type: taskAction.VIEW_TASK_INITIATE,
});

export const viewTaskSuccess = (data) => ({
  type: taskAction.VIEW_TASK_SUCCESS,
  payload: data,
});

export const viewTaskFailure = (data) => ({
  type: taskAction.VIEW_TASK_FAILURE,
  payload: data,
});

export const getViewTask = (taskId) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(viewTaskInitiate(taskId));
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks/${taskId}`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(viewTaskSuccess(response.data));
    } else {
      dispatch(viewTaskFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(viewTaskFailure(err));
    return err.response;
  }
};

//=======================================incomingTaskAccessRequestList============================================

export const incomingTaskAccessRequestListInitiate = () => ({
  type: taskAction.INCOMING_ACCESS_TASK_REQUEST_LIST_INITIATE,
});

export const incomingTaskAccessRequestListSuccess = (data) => ({
  type: taskAction.INCOMING_ACCESS_TASK_REQUEST_LIST_SUCCESS,
  payload: data,
});

export const incomingTaskAccessRequestListFailure = (data) => ({
  type: taskAction.INCOMING_ACCESS_TASK_REQUEST_LIST_FAILURE,
  payload: data,
});

export const getIncomingTaskAccessRequestList = (payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(incomingTaskAccessRequestListInitiate(payload));
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/list-request-access?page=${payload.page}&itemsPerPage=10`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(incomingTaskAccessRequestListSuccess(response.data));
    } else {
      dispatch(incomingTaskAccessRequestListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(incomingTaskAccessRequestListFailure(err));
    return err.response;
  }
};


//=======================================ManageAccessToMyTaskList============================================

export const manageAccessToMyTaskListInitiate = () => ({
  type: taskAction.MANAGE_ACCESS_TO_MY_TASK_LIST_INITIATE,
});

export const manageAccessToMyTaskListSuccess = (data) => ({
  type: taskAction.MANAGE_ACCESS_TO_MY_TASK_LIST_SUCCESS,
  payload: data,
});

export const manageAccessToMyTaskListFailure = (data) => ({
  type: taskAction.MANAGE_ACCESS_TO_MY_TASK_LIST_FAILURE,
  payload: data,
});

export const getManageAccessToMyTaskListList = (payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(manageAccessToMyTaskListInitiate(payload));
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/manage-access-to-my-tasks?page=${payload.page}&itemsPerPage=10`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(manageAccessToMyTaskListSuccess(response.data));
    } else {
      dispatch(manageAccessToMyTaskListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(manageAccessToMyTaskListFailure(err));
    return err.response;
  }
};


//=======================================ManageAccessTaskUSERList============================================

export const manageAccessUserTaskListInitiate = () => ({
  type: taskAction.MANAGE_ACCESS_USER_TASK_LIST_INITIATE,
});

export const manageAccessUserTaskListSuccess = (data) => ({
  type: taskAction.MANAGE_ACCESS_USER_TASK_LIST_SUCCESS,
  payload: data,
});

export const manageAccessUserTaskListFailure = (data) => ({
  type: taskAction.MANAGE_ACCESS_USER_TASK_LIST_FAILURE,
  payload: data,
});

export const getManageAccessUserTaskList = (payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(manageAccessUserTaskListInitiate(payload));
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/manage-access-to-users-task-lists?page=${payload.page}&itemsPerPage=10`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(manageAccessUserTaskListSuccess(response.data));
    } else {
      dispatch(manageAccessUserTaskListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(manageAccessUserTaskListFailure(err));
    return err.response;
  }
};



//===================================Edittask=============================
export const editTaskInitiate = () => ({
  type: taskAction.EDIT_TASK_INITIATE,
});

export const editTaskSuccess = (data) => ({
  type: taskAction.EDIT_TASK_SUCCESS,
  payload: data,
});

export const  editTaskFailure = (data) => ({
  type: taskAction.EDIT_TASK_FAILURE,
  payload: data,
});

export const updateTask = ( taskId,payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(editTaskInitiate(payload))
    const response = await axios.put(
      `${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks/${taskId}`,payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(editTaskSuccess(response.data));
    } else {
      dispatch(editTaskFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(editTaskFailure(err));
    return err.response;
  }
};



//---------------------------------taskRequestAccessuSER-----------------------------------------------
export const taskRequestAccessUserInitiate = () => ({
  type: taskAction.TASK_REQUEST_ACCESS_USER_INITIATE,
});

export const taskRequestAccessUserSuccess = (data) => ({
  type: taskAction.TASK_REQUEST_ACCESS_USER_SUCCESS,
  payload: data,
});

export const taskRequestAccessUserFailure = (data) => ({
  type: taskAction.TASK_REQUEST_ACCESS_USER_FAILURE,
  payload: data,
});

export const getTaskRequestAccessUser = (payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(taskRequestAccessUserInitiate(payload));
    const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks-approve-access`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(taskRequestAccessUserSuccess(response.data));
    } else {
      dispatch(taskRequestAccessUserFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(taskRequestAccessUserFailure(err));
    return err.response;
  }
};


//============================update-target-date============================

export const updateTargetDateInitiate = () => ({
  type: taskAction.UPDATE_TARGET_DATE_INITIATE,
});

export const updateTargetDateSuccess = (data) => ({
  type: taskAction.UPDATE_TARGET_DATE_SUCCESS,
  payload: data,
});

export const updateTargetDateFailure = (data) => ({
  type: taskAction.UPDATE_TARGET_DATE_FAILURE,
  payload: data,
});

export const updateTargetDate = (taskId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(updateTargetDateInitiate(taskId,payload));
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks/${taskId}/target-date`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateTargetDateSuccess(response.data));
      } else {
        dispatch(updateTargetDateFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateTargetDateFailure(err));
      return err.response
    }
  };