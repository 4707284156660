import React, { useEffect, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  changeAppointmentUpdate,
  changeConstructionUpdate,
  changeDesginSelectionsDataUpdate,
  changeGeneralInformationUpdate,
  changeOrderUpdate,
  changeSaleUpdate,
  clientViewWarrantyUpdate,
  createClientUser,
  getAllClientUser,
  getAppointmentData,
  getChangeOrderData,
  getClientViewWarrantyList,
  getConstructionData,
  getDesignSelectionsData,
  getGeneralInformationData,
  getRemoveClientUser,
  getSaleData,
} from "../redux/action/clientViewConfigAction";
import { toast } from "react-toastify";
import { calculateDateDifference } from "../utils/uploadFile";
import Loader from "./Loader";
import { useLocation } from "react-router-dom";
import SmsInfo from "./SmsInfo";
import { getAllProjectList } from "../redux/action/vendorViewConfigAction";
const initialState = {
  showWarrannty: false,
  showWarranntyNotification: false,
  warrantyReqCreationClient: true,
  displayWarrantyReqHomepage: true,
  newWarrantyRequest: true,
  warrantyRequestUpdated: true,
  newWorkOrder: true,
  workOrderUpdated: true,
  workOrderApprovalpending: true,

  showChangeOrder: false,
  showChangeOrderNotification: false,
  ClientChangeRequests: true,
  displayChangeOrderHomepage: true,
  changeOrderPendingSignature: true,
  changeRequestUpdated: true,
  changeOrderSignedBuilder: true,
  changeRequestRejected: true,

  showAppointment: false,
  showAppointmentNotification: false,
  displayCalendarHomepage: true,
  displayPastAppointments: true,
  defaultAppoReminderDate: "",
  newAppointment: true,
  appointmentCancelled: true,
  appointmentUpdate: true,
  newDateSelectionEvent: true,

  showConstructionApp: false,
  showConstructionProgress: false,
  showConstructionPhotos: false,
  constructionPictures: true,
  showcompletedPhases: true,
  showprogress: true,
  noOfPicturesDisplayed: "",
  constFileManagerFolderId: "",
  clientDownload: true,
  projectProgress: true,

  showSaleHome: false,
  showSaleDocument: false,
  showSaleNotification: false,
  ScheduledPayments: true,
  Finances: true,
  SalesContactId: "",
  docClientDocumentDownload: true,
  docClientDocumentShare: true,
  docInitialLotInspectForm: true,
  docFinalLotInspectForm: true,
  docSalesDocuments: true,
  docConstructionDocs: true,
  notiNewDocAvailable: true,
  notiClientSignaturePending: true,
  notiBuilderSignatureCompleted: true,
  notiSchePayDateAproaching: true,
  notiMissedPayntent: true,

  showDesign: false,
  newSelectionAvailable: true,
  newDocumentAvailable: true,
  selectionCanceled: true,
  clientSignaturePending: true,
  builderSignatureCompleted: true,
  selectionDeadlineDateApro: true,
  missedSelectionDeadline: true,

  showGeneralGeneration: false,
  showGeneralAccess: false,
  showGeneralNotification: false,
  designSelections: true,
  salesDocuments: true,
  appointments: true,
  changeOrders: true,
  houseWarranty: true,
  clientLogin: true,
  emailNotifications: true,
  notifications: true,
  pushNotifications: true,
  fullName: "",
  email: "",
  isPrimary: false,

  viewConfigId: "",
  NavigateModal: false,
};
const ClientViewConfig = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const {
    showWarrannty,
    showWarranntyNotification,
    showAppointment,
    showAppointmentNotification,
    warrantyReqCreationClient,
    displayWarrantyReqHomepage,
    newWarrantyRequest,
    warrantyRequestUpdated,
    newWorkOrder,
    workOrderUpdated,
    workOrderApprovalpending,

    showChangeOrder,
    showChangeOrderNotification,
    ClientChangeRequests,
    displayChangeOrderHomepage,
    changeOrderPendingSignature,
    changeRequestUpdated,
    changeOrderSignedBuilder,
    changeRequestRejected,

    displayCalendarHomepage,
    displayPastAppointments,
    defaultAppoReminderDate,
    newAppointment,
    appointmentCancelled,
    appointmentUpdate,
    newDateSelectionEvent,

    showConstructionApp,
    showConstructionProgress,
    showConstructionPhotos,
    constructionPictures,
    showcompletedPhases,
    showprogress,
    noOfPicturesDisplayed,
    constFileManagerFolderId,
    clientDownload,
    projectProgress,

    showSaleHome,
    showSaleDocument,
    showSaleNotification,
    ScheduledPayments,
    Finances,
    SalesContactId,
    docClientDocumentDownload,
    docClientDocumentShare,
    docInitialLotInspectForm,
    docFinalLotInspectForm,
    docSalesDocuments,
    docConstructionDocs,
    notiNewDocAvailable,
    notiClientSignaturePending,
    notiBuilderSignatureCompleted,
    notiSchePayDateAproaching,
    notiMissedPayntent,

    showDesign,
    newSelectionAvailable,
    newDocumentAvailable,
    selectionCanceled,
    clientSignaturePending,
    builderSignatureCompleted,
    selectionDeadlineDateApro,
    missedSelectionDeadline,

    showGeneralGeneration,
    showGeneralAccess,
    showGeneralNotification,
    designSelections,
    salesDocuments,
    appointments,
    changeOrders,
    houseWarranty,
    clientLogin,
    emailNotifications,
    notifications,
    pushNotifications,
    fullName,
    email,
    isPrimary,
    viewConfigId,
    NavigateModal,
  } = iState;
  const {
    clientViewConfigWarrantyList,
    changeOrderData,
    appointmentData,
    constructionData,
    saleData,
    generalInformationData,
    desginSelectionData,
    allClientData,
    loaderClient,
  } = useSelector((state) => state.clientViewConfigData);

  console.log("generalInformationData", generalInformationData);

  const { state, pathname } = useLocation();
  console.log("state",state);

  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

  const { allProjectList } = useSelector(
    (state) => state.vendorViewConfigReducer
  );

  useEffect(() => {
    dispatch(getAllProjectList(iBuildLocalData?.user?.CompanyId));
  }, []);
  useEffect(() => {
    if ((state?.data?.jobType != null, state?.data?.id != null)) {
      dispatch(
        getGeneralInformationData(state?.data?.id, state?.data?.jobType)
      ).then((res) => {
        if (res.status === 200) {
          dispatch(
            getAllClientUser(
              res?.data?.ViewSetting?.id,
              state?.data?.id,
              state?.data?.jobType
            )
          );
          dispatch(getClientViewWarrantyList(res?.data?.ViewSetting?.id));
          dispatch(getChangeOrderData(res?.data?.ViewSetting?.id));
          dispatch(getAppointmentData(res?.data?.ViewSetting?.id));
          dispatch(getConstructionData(res?.data?.ViewSetting?.id));
          dispatch(getSaleData(res?.data?.ViewSetting?.id));
          dispatch(getDesignSelectionsData(res?.data?.ViewSetting?.id));
          updateState({ ...iState, viewConfigId: res?.data?.ViewSetting?.id });
        }
      });
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  const handleWarrantyToggle = () => {
    updateState({ ...iState, showWarrannty: !showWarrannty });
  };
  const handleWarrantyCheck = (type) => {
    if (type === "client") {
      updateState({
        ...iState,
        warrantyReqCreationClient: !warrantyReqCreationClient,
      });
    }
    if (type === "home") {
      updateState({
        ...iState,
        displayWarrantyReqHomepage: !displayWarrantyReqHomepage,
      });
    }
    if (type === "newWarnt") {
      updateState({ ...iState, newWarrantyRequest: !newWarrantyRequest });
    }
    if (type === "warntReq") {
      updateState({
        ...iState,
        warrantyRequestUpdated: !warrantyRequestUpdated,
      });
    }
    if (type === "newWorkOrd") {
      updateState({ ...iState, newWorkOrder: !newWorkOrder });
    }
    if (type === "workOrderUpdate") {
      updateState({ ...iState, workOrderUpdated: !workOrderUpdated });
    }
    if (type === "workOrderApproval") {
      updateState({
        ...iState,
        workOrderApprovalpending: !workOrderApprovalpending,
      });
    }
  };
  const handleWarrantyClientHomeUpdate = () => {
    const dataa = { warrantyReqCreationClient, displayWarrantyReqHomepage };
    dispatch(
      clientViewWarrantyUpdate(
        generalInformationData?.ViewSetting?.id,
        clientViewConfigWarrantyList?.warranties?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(
          getClientViewWarrantyList(generalInformationData?.ViewSetting?.id)
        );
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          displayWarrantyReqHomepage: true,
          warrantyReqCreationClient: true,
          showWarrannty: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleWarrantyNotificationToggle = () => {
    updateState({
      ...iState,
      showWarranntyNotification: !showWarranntyNotification,
    });
  };

  const handleWarrantyNotificationUpdate = () => {
    const dataa = {
      newWarrantyRequest,
      warrantyRequestUpdated,
      newWorkOrder,
      workOrderUpdated,
      workOrderApprovalpending,
    };
    dispatch(
      clientViewWarrantyUpdate(
        generalInformationData?.ViewSetting?.id,
        clientViewConfigWarrantyList?.warranties?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(
          getClientViewWarrantyList(generalInformationData?.ViewSetting?.id)
        );
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          showWarranntyNotification: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleChangeOrderToggle = () => {
    updateState({ ...iState, showChangeOrder: !showChangeOrder });
  };

  const handleChangeOrderNotification = () => {
    updateState({
      ...iState,
      showChangeOrderNotification: !showChangeOrderNotification,
    });
  };
  const handleChangeOrderCheck = (type) => {
    if (type === "client") {
      updateState({
        ...iState,
        ClientChangeRequests: !ClientChangeRequests,
      });
    }
    if (type === "home") {
      updateState({
        ...iState,
        displayChangeOrderHomepage: !displayChangeOrderHomepage,
      });
    }
    if (type === "Signature") {
      updateState({
        ...iState,
        changeOrderPendingSignature: !changeOrderPendingSignature,
      });
    }
    if (type === "Updated") {
      updateState({
        ...iState,
        changeRequestUpdated: !changeRequestUpdated,
      });
    }
    if (type === "Builder") {
      updateState({
        ...iState,
        changeOrderSignedBuilder: !changeOrderSignedBuilder,
      });
    }
    if (type === "Rejected") {
      updateState({ ...iState, changeRequestRejected: !changeRequestRejected });
    }
  };
  const handleChangeOrderClientHomeUpdate = () => {
    const dataa = { ClientChangeRequests, displayChangeOrderHomepage };
    dispatch(
      changeOrderUpdate(
        generalInformationData?.ViewSetting?.id,
        changeOrderData?.changeOrders.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getChangeOrderData(generalInformationData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          // displayWarrantyReqHomepage: !displayWarrantyReqHomepage,
          // warrantyReqCreationClient: !warrantyReqCreationClient,
          showChangeOrder: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  const handleChangeOrderNotificationsUpdate = () => {
    const dataa = {
      changeOrderPendingSignature,
      changeRequestUpdated,
      changeOrderSignedBuilder,
      changeRequestRejected,
    };
    dispatch(
      changeOrderUpdate(
        generalInformationData?.ViewSetting?.id,
        changeOrderData?.changeOrders?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getChangeOrderData(generalInformationData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          // displayWarrantyReqHomepage: !displayWarrantyReqHomepage,
          // warrantyReqCreationClient: !warrantyReqCreationClient,
          showChangeOrderNotification: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleAppointmentToggle = () => {
    updateState({ ...iState, showAppointment: !showAppointment });
  };

  const handleAppointmentNotificationToggle = () => {
    updateState({
      ...iState,
      showAppointmentNotification: !showAppointmentNotification,
    });
  };
  const handleAppointmentCheck = (type) => {
    if (type === "client") {
      updateState({
        ...iState,
        displayCalendarHomepage: !displayCalendarHomepage,
      });
    }
    if (type === "home") {
      updateState({
        ...iState,
        displayPastAppointments: !displayPastAppointments,
      });
    }
    if (type === "Signature") {
      updateState({
        ...iState,
        newAppointment: !newAppointment,
      });
    }
    if (type === "Updated") {
      updateState({
        ...iState,
        appointmentCancelled: !appointmentCancelled,
      });
    }
    if (type === "Builder") {
      updateState({
        ...iState,
        appointmentUpdate: !appointmentUpdate,
      });
    }
    if (type === "Rejected") {
      updateState({ ...iState, newDateSelectionEvent: !newDateSelectionEvent });
    }
  };

  const handleAppointmentClientHome = () => {
    const dataa = {
      displayCalendarHomepage,
      displayPastAppointments,
      defaultAppoReminderDate: calculateDateDifference(defaultAppoReminderDate),
    };
    dispatch(
      changeAppointmentUpdate(
        generalInformationData?.ViewSetting?.id,
        appointmentData?.appointments?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getAppointmentData(generalInformationData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          // displayWarrantyReqHomepage: !displayWarrantyReqHomepage,
          // warrantyReqCreationClient: !warrantyReqCreationClient,
          showAppointment: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleAppointmentNotification = () => {
    const dataa = {
      newAppointment,
      appointmentCancelled,
      appointmentUpdate,
      newDateSelectionEvent,
    };
    dispatch(
      changeAppointmentUpdate(
        generalInformationData?.ViewSetting?.id,
        appointmentData?.appointments?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getAppointmentData(generalInformationData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          // displayWarrantyReqHomepage: !displayWarrantyReqHomepage,
          // warrantyReqCreationClient: !warrantyReqCreationClient,
          showAppointmentNotification: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleConstructionAppToggle = () => {
    updateState({ ...iState, showConstructionApp: !showConstructionApp });
  };

  const handleConstructionCheck = (type) => {
    if (type === "client") {
      updateState({
        ...iState,
        projectProgress: !projectProgress,
      });
    }
    if (type === "home") {
      updateState({
        ...iState,
        constructionPictures: !constructionPictures,
      });
    }
    if (type === "Signature") {
      updateState({
        ...iState,
        showcompletedPhases: !showcompletedPhases,
      });
    }
    if (type === "Updated") {
      updateState({
        ...iState,
        showprogress: !showprogress,
      });
    }
    if (type === "Builder") {
      updateState({
        ...iState,
        clientDownload: !clientDownload,
      });
    }
  };

  const handleConstructionApp = () => {
    const dataa = {
      projectProgress,
      constructionPictures,
    };
    dispatch(
      changeConstructionUpdate(
        generalInformationData?.ViewSetting?.id,
        constructionData?.construction?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getConstructionData(generalInformationData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          // displayWarrantyReqHomepage: !displayWarrantyReqHomepage,
          // warrantyReqCreationClient: !warrantyReqCreationClient,
          showConstructionApp: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  const handleConstructionProgressToggle = () => {
    updateState({
      ...iState,
      showConstructionProgress: false,
    });
  };

  const handleConstructionProgress = () => {
    const dataa = {
      showcompletedPhases,
      showprogress,
    };
    dispatch(
      changeConstructionUpdate(
        generalInformationData?.ViewSetting?.id,
        constructionData?.construction?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getConstructionData(generalInformationData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          // displayWarrantyReqHomepage: !displayWarrantyReqHomepage,
          // warrantyReqCreationClient: !warrantyReqCreationClient,
          showConstructionProgress: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleConstructionPhotosToggle = () => {
    updateState({ ...iState, showConstructionPhotos: !showConstructionPhotos });
  };

  const handleConstructionPhotos = () => {
    const dataa = {
      noOfPicturesDisplayed,
      constFileManagerFolderId: Number(constFileManagerFolderId),
      clientDownload,
    };
    dispatch(
      changeConstructionUpdate(
        generalInformationData?.ViewSetting?.id,
        constructionData?.construction?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getConstructionData(generalInformationData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          // displayWarrantyReqHomepage: !displayWarrantyReqHomepage,
          // warrantyReqCreationClient: !warrantyReqCreationClient,
          showConstructionPhotos: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleSaleHomeToggle = () => {
    updateState({ ...iState, showSaleHome: !showSaleHome });
  };

  const handleSalesCheck = (type) => {
    if (type === "client") {
      updateState({
        ...iState,
        ScheduledPayments: !ScheduledPayments,
      });
    }
    if (type === "home") {
      updateState({
        ...iState,
        Finances: !Finances,
      });
    }
    if (type === "newWarnt") {
      updateState({
        ...iState,
        docClientDocumentDownload: !docClientDocumentDownload,
      });
    }
    if (type === "warntReq") {
      updateState({
        ...iState,
        docClientDocumentShare: !docClientDocumentShare,
      });
    }
    if (type === "newWorkOrd") {
      updateState({
        ...iState,
        docInitialLotInspectForm: !docInitialLotInspectForm,
      });
    }
    if (type === "workOrderUpdate") {
      updateState({
        ...iState,
        docFinalLotInspectForm: !docFinalLotInspectForm,
      });
    }
    if (type === "workOrderApproval") {
      updateState({
        ...iState,
        docSalesDocuments: !docSalesDocuments,
      });
    }
    if (type === "constDoc") {
      updateState({ ...iState, docConstructionDocs: !docConstructionDocs });
    }
    if (type === "newDoc") {
      updateState({ ...iState, notiNewDocAvailable: !notiNewDocAvailable });
    }
    if (type === "clientSign") {
      updateState({
        ...iState,
        notiClientSignaturePending: !notiClientSignaturePending,
      });
    }
    if (type === "buildSign") {
      updateState({
        ...iState,
        notiBuilderSignatureCompleted: !notiBuilderSignatureCompleted,
      });
    }
    if (type === "schPayment") {
      updateState({
        ...iState,
        notiSchePayDateAproaching: !notiSchePayDateAproaching,
      });
    }
    if (type === "notiMissPayment") {
      updateState({ ...iState, notiMissedPayntent: !notiMissedPayntent });
    }
  };

  const handleSalesHome = () => {
    const dataa = {
      ScheduledPayments,
      Finances,
    };
    dispatch(
      changeSaleUpdate(
        generalInformationData?.ViewSetting?.id,
        saleData?.clientViewSale?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getSaleData(generalInformationData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          // displayWarrantyReqHomepage: !displayWarrantyReqHomepage,
          // warrantyReqCreationClient: !warrantyReqCreationClient,
          showSaleHome: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleSaleDocumentToggle = () => {
    updateState({ ...iState, showSaleDocument: !showSaleDocument });
  };
  const handleSalesDocumnet = () => {
    const dataa = {
      docClientDocumentDownload,
      docClientDocumentShare,
      docInitialLotInspectForm,
      docFinalLotInspectForm,
      docSalesDocuments,
      docConstructionDocs,
    };
    dispatch(
      changeSaleUpdate(
        generalInformationData?.ViewSetting?.id,
        saleData?.clientViewSale?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getSaleData(generalInformationData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          // displayWarrantyReqHomepage: !displayWarrantyReqHomepage,
          // warrantyReqCreationClient: !warrantyReqCreationClient,
          showSaleDocument: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleSaleNotificationToggle = () => {
    updateState({ ...iState, showSaleNotification: !showSaleNotification });
  };

  const handleSalesNotification = () => {
    const dataa = {
      notiNewDocAvailable,
      notiClientSignaturePending,
      notiBuilderSignatureCompleted,
      notiSchePayDateAproaching,
      notiMissedPayntent,
    };
    dispatch(
      changeSaleUpdate(
        generalInformationData?.ViewSetting?.id,
        saleData?.clientViewSale?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getSaleData(generalInformationData?.ViewSetting?.id));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          // displayWarrantyReqHomepage: !displayWarrantyReqHomepage,
          // warrantyReqCreationClient: !warrantyReqCreationClient,
          showSaleNotification: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleDesignToggle = () => {
    updateState({ ...iState, showDesign: !showDesign });
  };
  const handleDesignCheck = (type) => {
    if (type === "client") {
      updateState({
        ...iState,
        newSelectionAvailable: !newSelectionAvailable,
      });
    }
    if (type === "home") {
      updateState({
        ...iState,
        newDocumentAvailable: !newDocumentAvailable,
      });
    }
    if (type === "newWarnt") {
      updateState({ ...iState, selectionCanceled: !selectionCanceled });
    }
    if (type === "warntReq") {
      updateState({
        ...iState,
        clientSignaturePending: !clientSignaturePending,
      });
    }
    if (type === "newWorkOrd") {
      updateState({
        ...iState,
        builderSignatureCompleted: !builderSignatureCompleted,
      });
    }
    if (type === "workOrderUpdate") {
      updateState({
        ...iState,
        selectionDeadlineDateApro: !selectionDeadlineDateApro,
      });
    }
    if (type === "workOrderApproval") {
      updateState({
        ...iState,
        missedSelectionDeadline: !missedSelectionDeadline,
      });
    }
  };

  const handleDesgin = () => {
    const dataa = {
      newSelectionAvailable,
      newDocumentAvailable,
      selectionCanceled,
      clientSignaturePending,
      builderSignatureCompleted,
      selectionDeadlineDateApro,
      missedSelectionDeadline,
    };
    dispatch(
      changeDesginSelectionsDataUpdate(
        generalInformationData?.ViewSetting?.id,
        desginSelectionData?.designSelections?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(
          getDesignSelectionsData(generalInformationData?.ViewSetting?.id)
        );
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          // displayWarrantyReqHomepage: !displayWarrantyReqHomepage,
          // warrantyReqCreationClient: !warrantyReqCreationClient,
          showDesign: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleGenerationToggle = () => {
    updateState({ ...iState, showGeneralGeneration: !showGeneralGeneration });
  };
  const handleGeneralCheck = (type) => {
    if (type === "client") {
      updateState({
        ...iState,
        designSelections: !designSelections,
      });
    }
    if (type === "home") {
      updateState({
        ...iState,
        salesDocuments: !salesDocuments,
      });
    }
    if (type === "newWarnt") {
      updateState({ ...iState, appointments: !appointments });
    }
    if (type === "warntReq") {
      updateState({
        ...iState,
        changeOrders: !changeOrders,
      });
    }
    if (type === "newWorkOrd") {
      updateState({
        ...iState,
        houseWarranty: !houseWarranty,
      });
    }
    if (type === "workOrderUpdate") {
      updateState({
        ...iState,
        emailNotifications: !emailNotifications,
      });
    }
    if (type === "workOrderApproval") {
      updateState({
        ...iState,
        notifications: !notifications,
      });
    }
    if (type === "pushNotifi") {
      updateState({
        ...iState,
        pushNotifications: !pushNotifications,
      });
    }
  };

  const handleGeneralGeneration = () => {
    const dataa = {
      designSelections,
      salesDocuments,
      appointments,
      changeOrders,
      houseWarranty,
    };
    dispatch(
      changeGeneralInformationUpdate(
        generalInformationData?.ViewSetting?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(
          getGeneralInformationData(state?.data?.id, state?.data?.jobType)
        );
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          // displayWarrantyReqHomepage: !displayWarrantyReqHomepage,
          // warrantyReqCreationClient: !warrantyReqCreationClient,
          showGeneralGeneration: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleGenerationNotificationToggle = () => {
    updateState({
      ...iState,
      showGeneralNotification: !showGeneralNotification,
    });
  };
  const handleGeneralNotification = () => {
    const dataa = {
      emailNotifications,
      notifications,
      pushNotifications,
    };
    dispatch(
      changeGeneralInformationUpdate(
        generalInformationData?.ViewSetting?.id,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(
          getGeneralInformationData(state?.data?.id, state?.data?.jobType)
        );
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          // displayWarrantyReqHomepage: !displayWarrantyReqHomepage,
          // warrantyReqCreationClient: !warrantyReqCreationClient,
          showGeneralNotification: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleGeneralAccessToggle = () => {
    updateState({ ...iState, showGeneralAccess: !showGeneralAccess });
  };
  const handleAccessCheck = (type) => {
    if (type === "client") {
      updateState({ ...iState, clientLogin: !clientLogin });
    }
    if (type === "isPrimary") {
      updateState({ ...iState, isPrimary: !isPrimary });
    }
  };

  const handleCreateUserClient = () => {
    const dataa = {
      fullName,
      email,
      isPrimary,
      status: clientLogin === true ? "ACTIVE" : "LOCKED",
    };
    dispatch(
      createClientUser(generalInformationData?.ViewSetting?.id, dataa)
    ).then((res) => {
      if (res.status === 201) {
        dispatch(
          getAllClientUser(
            generalInformationData?.ViewSetting?.id,
            state?.data?.id,
            state?.data?.jobType
          )
        );
        toast.success("Create Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          showGeneralAccess: false,
          fullName: "",
          email: "",
          clientLogin: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const [JobMenuModal, setJobMenuModal] = useState(false);
  const handleJobMenuModalClose = () => setJobMenuModal(false);
  const handleJobMenuModalShow = () => setJobMenuModal(true);
  const handleNavigateModalShow = () => {
    updateState({ ...iState, NavigateModal: true });
  };
  const handleNavigateModalClose = () => {
    updateState({ ...iState, NavigateModal: false });
  };

  const handleRemoveUser = (id) => {
    dispatch(getRemoveClientUser(generalInformationData?.ViewSetting?.id,id)).then((res) => {
      if (res.status === 200) {
        dispatch(
          getAllClientUser(
            generalInformationData?.ViewSetting?.id,
            state?.data?.id,
            state?.data?.jobType
          )
        );
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  return (
    <>
      <div>
        <Header />
        <SideNav />
        <div className="WrapperArea">
          <div className="WrapperBox">
            <div className="TitleBox">
              <div>
                <div className="TitleBox bg-none border-0 p-0">
                  <h4 className="Title">Views Config</h4>
                </div>
              </div>
              {/* <div className="TitleBoxAddress">
              <h6>Luke Anderson</h6>
              <p>johndoe@gmail.com / +91 956023620</p>
              <p>Unit 2, 312 Weddernburn RD SE</p>
              <p>Chinook Gate, Calgary</p>
            </div> */}
              <div className="TitleBoxNavigate">
                <h5>{state?.data?.jobNumber ?? "N/A"}</h5>
                <p>{state?.data?.jobType ?? "N/A"}</p>
                <div className="NavigateButton">
                  <button onClick={handleJobMenuModalShow}>Job Menu</button>
                  <button data-toggle="modal" onClick={handleNavigateModalShow}>
                    Navigate
                  </button>
                </div>
              </div>
            </div>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="ClientView">
                <div className="row">
                  <div className="col-md-3">
                    <div className="ViewsConfigLeft">
                      {generalInformationData?.ViewSetting?.id && (
                        <ul className="nav nav-tabs">
                          <li className="nav-item">
                            <a
                              href="#GeneralConfigurationLeft"
                              className="nav-link active"
                              data-toggle="tab"
                            >
                              General Configuration
                            </a>
                          </li>
                          {generalInformationData?.ViewSetting
                            ?.designSelections && (
                            <li className="nav-item">
                              <a
                                href="#DesignSelectionsLeft"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Design Selections
                              </a>
                            </li>
                          )}
                          {generalInformationData?.ViewSetting
                            ?.salesDocuments && (
                            <li className="nav-item">
                              <a
                                href="#SalesLeft"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Sales
                              </a>
                            </li>
                          )}
                          <li className="nav-item">
                            <a
                              href="#ConstructionLeft"
                              className="nav-link"
                              data-toggle="tab"
                            >
                              Construction
                            </a>
                          </li>
                          {generalInformationData?.ViewSetting
                            ?.appointments && (
                            <li className="nav-item">
                              <a
                                href="#AppointmentsLeft"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Appointments
                              </a>
                            </li>
                          )}
                          {generalInformationData?.ViewSetting
                            ?.changeOrders && (
                            <li className="nav-item">
                              <a
                                href="#ChangeOrdersLeft"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Change Orders
                              </a>
                            </li>
                          )}
                          {generalInformationData?.ViewSetting
                            ?.houseWarranty && (
                            <li className="nav-item">
                              <a
                                href="#WarrantyLeft"
                                className="nav-link"
                                data-toggle="tab"
                              >
                                Warranty
                              </a>
                            </li>
                          )}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="col-md-9">
                    {generalInformationData?.ViewSetting?.id && (
                      <div className="tab-content">
                        <div
                          className="tab-pane fade show active"
                          id="GeneralConfigurationLeft"
                        >
                          <div className="ResidentialJobArea ViewConfigArea">
                            <div className="ModalAccordian">
                              <div id="accordion">
                                <div className="card">
                                  <div className="card-header">
                                    <h4
                                      data-toggle="collapse"
                                      href="#GenerationInformation"
                                    >
                                      <span>
                                        <img src="images/general_inform.png" />
                                      </span>
                                      Generation Information
                                    </h4>
                                    <a
                                      className="Edit EditViewConfigBodyBtn"
                                      onClick={handleGenerationToggle}
                                    >
                                      <img src="images/Action-1.png" />
                                    </a>
                                  </div>
                                  <div
                                    id="GenerationInformation"
                                    className="collapse show"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body">
                                      {showGeneralGeneration === false && (
                                        <div className="ViewConfigBodyMain">
                                          <div className="ViewConfigBody">
                                            <aside>
                                              <p>
                                                <label>Design Selections</label>
                                                <span>
                                                  {generalInformationData
                                                    ?.ViewSetting
                                                    ?.designSelections === true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>Sales Documents</label>
                                                <span>
                                                  {generalInformationData
                                                    ?.ViewSetting
                                                    ?.salesDocuments === true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>Appointments</label>
                                                <span>
                                                  {generalInformationData
                                                    ?.ViewSetting
                                                    ?.appointments === true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>Change Orders</label>
                                                <span>
                                                  {generalInformationData
                                                    ?.ViewSetting
                                                    ?.changeOrders === true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>House Warranty</label>
                                                <span>
                                                  {generalInformationData
                                                    ?.ViewSetting
                                                    ?.houseWarranty === true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                            </aside>
                                            <aside>
                                              <div className="ViewConfigHeading ml-3">
                                                <h6>
                                                  <span>
                                                    <img src="images/client_view_preview.png" />
                                                  </span>
                                                  Client View Preview
                                                </h6>
                                              </div>
                                            </aside>
                                          </div>
                                        </div>
                                      )}
                                      {showGeneralGeneration === true && (
                                        <div className="ViewConfigBodyHideMain">
                                          <div className="ViewConfigBodyHide">
                                            <aside>
                                              <label className="switch">
                                                {" "}
                                                Design Selections
                                                <input
                                                  type="checkbox"
                                                  checked={designSelections}
                                                  onClick={() =>
                                                    handleGeneralCheck("client")
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Sales Document
                                                <input
                                                  type="checkbox"
                                                  checked={salesDocuments}
                                                  onClick={() =>
                                                    handleGeneralCheck("home")
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Appointments
                                                <input
                                                  type="checkbox"
                                                  checked={appointments}
                                                  onClick={() =>
                                                    handleGeneralCheck(
                                                      "newWarnt"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Change Orders
                                                <input
                                                  type="checkbox"
                                                  checked={changeOrders}
                                                  onClick={() =>
                                                    handleGeneralCheck(
                                                      "warntReq"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                House Warranty
                                                <input
                                                  type="checkbox"
                                                  checked={houseWarranty}
                                                  onClick={() =>
                                                    handleGeneralCheck(
                                                      "newWorkOrd"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                            </aside>
                                            <aside>
                                              <div className="ViewConfigHeading">
                                                <h6>
                                                  <span>
                                                    <img src="images/client_view_preview.png" />
                                                  </span>
                                                  Client View Preview
                                                </h6>
                                              </div>
                                            </aside>
                                          </div>
                                          <div className="Button d-flex justify-content-center">
                                            <button
                                              className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                              onClick={handleGenerationToggle}
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              className="Respond ml-3 SaveViewConfigBodyBtn"
                                              onClick={handleGeneralGeneration}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="card">
                                  <div className="card-header">
                                    <h4 data-toggle="collapse" href="#Access">
                                      <span>
                                        <img src="images/access.png" />
                                      </span>
                                      Access
                                    </h4>
                                    <a
                                      className="Edit EditViewConfigBodyBtn"
                                      onClick={handleGeneralAccessToggle}
                                    >
                                      <img src="images/Action-1.png" />
                                    </a>
                                  </div>
                                  <div
                                    id="Access"
                                    className="collapse show"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body">
                                      {showGeneralAccess === false && (
                                        <div className="ViewConfigBodyMain">
                                          <div className="ViewConfigBody">
                                            <aside>
                                              <p>
                                                <label>Client Login</label>
                                                <span>On</span>
                                              </p>
                                            </aside>
                                          </div>
                                          <div className="Tableheader mt-3">
                                            {/* <h6>Regular Project</h6> */}
                                            <h6>Client View Users</h6>
                                            <div className="NewActions">
                                              <a href="jacascript:void(0);">
                                                <img src="images/icon-53.png" />
                                              </a>
                                              <a href="jacascript:void(0);">
                                                <img src="images/download.png" />
                                              </a>
                                            </div>
                                          </div>
                                          <div className="TableList TableListAlign TableListHeader NewTableList TableHeightScroll">
                                            {loaderClient ? (
                                              <td>
                                                <Loader />
                                              </td>
                                            ) : (
                                              <table>
                                                <thead>
                                                  <tr>
                                                    <th>Full Name</th>
                                                    <th>Email</th>
                                                    <th>
                                                      Permission Granted to
                                                      Receive SMS
                                                    </th>

                                                    <th>Primary</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {allClientData?.clientsDetails
                                                    ?.length > 0 ? (
                                                    allClientData?.clientsDetails?.map(
                                                      (item, i) => {
                                                        return (
                                                          <tr key={i}>
                                                            <td>
                                                              {item.legalName ??
                                                                "N/A"}
                                                            </td>
                                                            <td>
                                                              {item.email}
                                                            </td>
                                                            <td>Yes</td>
                                                            {/* <td>Yes <img onClick={() => {SetSmsInfoModal(true)
                                setUserData(item)
                              }} src="images/icon_62.png"/></td> */}
                                                            <td>
                                                              {/* <label className="CheckBox mb-0"> */}
                                                              {item.isPrimary ===
                                                              true
                                                                ? "ACTIVE"
                                                                : "LOCKED"}
                                                              {/* </label> */}
                                                            </td>
                                                            <td>
                                                              <div className="ContactListHover">
                                                                {item.status}
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="TableThreeDots">
                                                                <ul className="">
                                                                  <li className="dropdown">
                                                                    <a
                                                                      className=""
                                                                      id="navbarDropdown"
                                                                      role="button"
                                                                      data-toggle="dropdown"
                                                                      aria-haspopup="true"
                                                                      aria-expanded="false"
                                                                    >
                                                                      <img src="images/dots.png" />
                                                                    </a>
                                                                    <ol className="dropdown-menu dropdown-menuwidth">
                                                                      <li>
                                                                        <a
                                                                          onClick={() =>
                                                                            handleRemoveUser(
                                                                              item.id
                                                                            )
                                                                          }
                                                                        >
                                                                          <img src="images/dropdown-delete.png" />
                                                                          Remove
                                                                          user
                                                                        </a>
                                                                      </li>

                                                                      <li>
                                                                        <a href="javascript:void(0);">
                                                                          <img src="images/reset_password.png" />
                                                                          Reset
                                                                          Password
                                                                        </a>
                                                                      </li>
                                                                    </ol>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )
                                                  ) : (
                                                    <div className="text-center w-100">
                                                      <h6>No data found</h6>
                                                    </div>
                                                  )}
                                                </tbody>
                                              </table>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                      {showGeneralAccess === true && (
                                        <div className="ViewConfigBodyHideMain">
                                          <div className="ViewConfigBodyHide">
                                            <aside>
                                              <label className="switch">
                                                {" "}
                                                Client Login
                                                <input
                                                  type="checkbox"
                                                  checked={clientLogin}
                                                  onClick={() =>
                                                    handleAccessCheck("client")
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                            </aside>
                                          </div>
                                          <div className="row mt-4">
                                            <div className="col-md-5">
                                              <div className="form-group">
                                                <h6>Full Name</h6>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Insert Full Name"
                                                  name="fullName"
                                                  value={fullName}
                                                  onChange={handleInputChange}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-5">
                                              <div className="form-group">
                                                <h6>Email</h6>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Insert Email"
                                                  name="email"
                                                  value={email}
                                                  onChange={handleInputChange}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-2">
                                              <div className="form-group text-center">
                                                <h6>Primary</h6>
                                                <label className="CheckBox">
                                                  <input
                                                    type="checkbox"
                                                    checked={isPrimary}
                                                    onClick={() =>
                                                      handleAccessCheck(
                                                        "isPrimary"
                                                      )
                                                    }
                                                  />
                                                  <span className="checkmark" />
                                                </label>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="Button d-flex justify-content-center mt-3">
                                            <button
                                              className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                              onClick={
                                                handleGeneralAccessToggle
                                              }
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              className="Respond ml-3 SaveViewConfigBodyBtn"
                                              onClick={handleCreateUserClient}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="card">
                                  <div className="card-header">
                                    <h4
                                      data-toggle="collapse"
                                      href="#NotificationsSetup"
                                    >
                                      <span>
                                        <img src="images/notification.png" />
                                      </span>{" "}
                                      Notifications Setup
                                    </h4>
                                    <a
                                      className="Edit EditViewConfigBodyBtn"
                                      onClick={() =>
                                        handleGenerationNotificationToggle()
                                      }
                                    >
                                      <img src="images/Action-1.png" />
                                    </a>
                                  </div>
                                  <div
                                    id="NotificationsSetup"
                                    className="collapse show"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body">
                                      {showGeneralNotification === false && (
                                        <div className="ViewConfigBodyMain">
                                          <div className="ViewConfigBody">
                                            <aside>
                                              <p>
                                                <label>Email </label>
                                                <span>
                                                  {generalInformationData
                                                    ?.ViewSetting
                                                    ?.emailNotifications ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>Notifications </label>
                                                <span>
                                                  {generalInformationData
                                                    ?.ViewSetting
                                                    ?.notifications === true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>Push </label>
                                                <span>
                                                  {generalInformationData
                                                    ?.ViewSetting
                                                    ?.pushNotifications === true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                            </aside>
                                          </div>
                                        </div>
                                      )}
                                      {showGeneralNotification === true && (
                                        <div className="ViewConfigBodyHideMain">
                                          <div className="ViewConfigBodyHide">
                                            <aside>
                                              <label className="switch">
                                                {" "}
                                                Email
                                                <input
                                                  type="checkbox"
                                                  checked={emailNotifications}
                                                  onClick={() =>
                                                    handleGeneralCheck(
                                                      "workOrderUpdate"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Notifications
                                                <input
                                                  type="checkbox"
                                                  checked={notifications}
                                                  onClick={() =>
                                                    handleGeneralCheck(
                                                      "workOrderApproval"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Push
                                                <input
                                                  type="checkbox"
                                                  checked={pushNotifications}
                                                  onClick={() =>
                                                    handleGeneralCheck(
                                                      "pushNotifi"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                            </aside>
                                          </div>
                                          <div className="Button d-flex justify-content-center">
                                            <button
                                              className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                              onClick={() =>
                                                handleGenerationNotificationToggle()
                                              }
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              className="Respond ml-3 SaveViewConfigBodyBtn"
                                              onClick={
                                                handleGeneralNotification
                                              }
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="DesignSelectionsLeft"
                        >
                          <div className="ResidentialJobArea ViewConfigArea">
                            <div className="ModalAccordian">
                              <div id="accordion">
                                <div className="card">
                                  <div className="card-header">
                                    <h4
                                      data-toggle="collapse"
                                      href="#NotificationsSetup2"
                                    >
                                      <span>
                                        <img src="images/notification.png" />
                                      </span>{" "}
                                      Notifications Setup
                                    </h4>
                                    <a
                                      className="Edit EditViewConfigBodyBtn"
                                      onClick={handleDesignToggle}
                                    >
                                      <img src="images/Action-1.png" />
                                    </a>
                                  </div>
                                  <div
                                    id="NotificationsSetup2"
                                    className="collapse show"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body">
                                      {showDesign === false && (
                                        <div className="ViewConfigBodyMain">
                                          <div className="ViewConfigBody">
                                            <aside>
                                              <p>
                                                <label>
                                                  New Selection Available
                                                </label>
                                                <span>
                                                  {desginSelectionData
                                                    ?.designSelections
                                                    ?.newSelectionAvailable ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  New Document Available
                                                </label>
                                                <span>
                                                  {desginSelectionData
                                                    ?.designSelections
                                                    ?.newDocumentAvailable ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Selection Canceled
                                                </label>
                                                <span>
                                                  {desginSelectionData
                                                    ?.designSelections
                                                    ?.selectionCanceled === true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Client Signature Pending
                                                </label>
                                                <span>
                                                  {desginSelectionData
                                                    ?.designSelections
                                                    ?.clientSignaturePending ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Builder Signature Completed
                                                </label>
                                                <span>
                                                  {desginSelectionData
                                                    ?.designSelections
                                                    ?.builderSignatureCompleted ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Selection Deadline Date
                                                  Aproaching
                                                </label>
                                                <span>
                                                  {desginSelectionData
                                                    ?.designSelections
                                                    ?.selectionDeadlineDateApro ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Missed Selection Deadline
                                                </label>
                                                <span>
                                                  {desginSelectionData
                                                    ?.designSelections
                                                    ?.missedSelectionDeadline ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                            </aside>
                                          </div>
                                        </div>
                                      )}
                                      {showDesign === true && (
                                        <div className="ViewConfigBodyHideMain">
                                          <div className="ViewConfigBodyHide">
                                            <aside>
                                              <label className="switch">
                                                {" "}
                                                New Selection Available
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    newSelectionAvailable
                                                  }
                                                  onClick={() =>
                                                    handleDesignCheck("client")
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                New Document Available
                                                <input
                                                  type="checkbox"
                                                  checked={newDocumentAvailable}
                                                  onClick={() =>
                                                    handleDesignCheck("home")
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Selection Canceled
                                                <input
                                                  type="checkbox"
                                                  checked={selectionCanceled}
                                                  onClick={() =>
                                                    handleDesignCheck(
                                                      "newWarnt"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Client Signature Pending
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    clientSignaturePending
                                                  }
                                                  onClick={() =>
                                                    handleDesignCheck(
                                                      "warntReq"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Builder Signature Completed
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    builderSignatureCompleted
                                                  }
                                                  onClick={() =>
                                                    handleDesignCheck(
                                                      "newWorkOrd"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Selection Deadline Date
                                                Aproaching
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    selectionDeadlineDateApro
                                                  }
                                                  onClick={() =>
                                                    handleDesignCheck(
                                                      "workOrderUpdate"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Missed Selection Deadline
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    missedSelectionDeadline
                                                  }
                                                  onClick={() =>
                                                    handleDesignCheck(
                                                      "workOrderApproval"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                            </aside>
                                          </div>
                                          <div className="Button d-flex justify-content-center">
                                            <button
                                              className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                              onClick={handleDesignToggle}
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              className="Respond ml-3 SaveViewConfigBodyBtn"
                                              onClick={handleDesgin}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="SalesLeft">
                          <div className="ResidentialJobArea ViewConfigArea">
                            <div className="ModalAccordian">
                              <div id="accordion">
                                <div className="card">
                                  <div className="card-header">
                                    <h4
                                      data-toggle="collapse"
                                      href="#SalesHomepageSettings"
                                    >
                                      <span>
                                        <img src="images/sales_information.png" />
                                      </span>{" "}
                                      Sales Homepage Settings
                                    </h4>
                                    <a
                                      className="Edit EditViewConfigBodyBtn"
                                      onClick={handleSaleHomeToggle}
                                    >
                                      <img src="images/Action-1.png" />
                                    </a>
                                  </div>
                                  <div
                                    id="SalesHomepageSettings"
                                    className="collapse show"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body">
                                      {showSaleHome === false && (
                                        <div className="ViewConfigBodyMain">
                                          <div className="ViewConfigBody">
                                            <aside>
                                              <p>
                                                <label>
                                                  Scheduled Payments
                                                </label>
                                                <span>
                                                  {saleData?.clientViewSale
                                                    ?.ScheduledPayments === true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>Finances</label>
                                                <span>
                                                  {saleData?.clientViewSale
                                                    ?.Finances === true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>Sales Contact</label>
                                                <span>
                                                  {saleData?.clientViewSale
                                                    ?.SalesContactId ?? "N/A"}
                                                </span>
                                              </p>
                                            </aside>
                                          </div>
                                        </div>
                                      )}
                                      {showSaleHome === true && (
                                        <div className="ViewConfigBodyHideMain">
                                          <div className="ViewConfigBodyHide">
                                            <aside>
                                              <label className="switch">
                                                {" "}
                                                Scheduled Payments
                                                <input
                                                  type="checkbox"
                                                  checked={ScheduledPayments}
                                                  onClick={() =>
                                                    handleSalesCheck("client")
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Finances
                                                <input
                                                  type="checkbox"
                                                  checked={Finances}
                                                  onClick={() =>
                                                    handleSalesCheck("home")
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <div className="form-group">
                                                <h6>Project</h6>
                                                <div className="CommonSelectBox">
                                                  <select>
                                                    <option value="">
                                                      Select
                                                    </option>
                                                    <option>
                                                      Jane Williams 1
                                                    </option>
                                                  </select>
                                                  <span>
                                                    <img src="images/down.png" />
                                                  </span>
                                                </div>
                                              </div>
                                            </aside>
                                          </div>
                                          <div className="Button d-flex justify-content-center">
                                            <button
                                              className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                              onClick={handleSaleHomeToggle}
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              className="Respond ml-3 SaveViewConfigBodyBtn"
                                              onClick={handleSalesHome}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="card">
                                  <div className="card-header">
                                    <h4 data-toggle="collapse" href="#Access">
                                      <span>
                                        <img src="images/sales_documents.png" />
                                      </span>{" "}
                                      Sales Documents
                                    </h4>
                                    <a
                                      className="Edit EditViewConfigBodyBtn"
                                      onClick={handleSaleDocumentToggle}
                                    >
                                      <img src="images/Action-1.png" />
                                    </a>
                                  </div>
                                  <div
                                    id="Access"
                                    className="collapse show"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body">
                                      {showSaleDocument === false && (
                                        <div className="ViewConfigBodyMain">
                                          <div className="ViewConfigBody">
                                            <aside>
                                              <p>
                                                <label>
                                                  Client Document Download
                                                </label>
                                                <span>
                                                  {saleData?.clientViewSale
                                                    ?.docClientDocumentDownload ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Client Document Share
                                                </label>
                                                <span>
                                                  {saleData?.clientViewSale
                                                    ?.docClientDocumentShare ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Initial Lot Inspection Form
                                                </label>
                                                <span>
                                                  {saleData?.clientViewSale
                                                    ?.docInitialLotInspectForm ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Final Lot Inspection Form
                                                </label>
                                                <span>
                                                  {saleData?.clientViewSale
                                                    ?.docFinalLotInspectForm ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>Sales Document</label>
                                                <span>
                                                  {saleData?.clientViewSale
                                                    ?.docSalesDocuments === true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Construction Documents
                                                </label>
                                                <span>
                                                  {saleData?.clientViewSale
                                                    ?.docConstructionDocs ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                            </aside>
                                          </div>
                                        </div>
                                      )}
                                      {showSaleDocument === true && (
                                        <div className="ViewConfigBodyHideMain">
                                          <div className="ViewConfigBodyHide">
                                            <aside>
                                              <label className="switch">
                                                {" "}
                                                Client Document Download
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    docClientDocumentDownload
                                                  }
                                                  onClick={() =>
                                                    handleSalesCheck("newWarnt")
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Client Document Share
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    docClientDocumentShare
                                                  }
                                                  onClick={() =>
                                                    handleSalesCheck("warntReq")
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Initial Lot Inspection Form
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    docInitialLotInspectForm
                                                  }
                                                  onClick={() =>
                                                    handleSalesCheck(
                                                      "newWorkOrd"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Final Lot Inspection Form
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    docFinalLotInspectForm
                                                  }
                                                  onClick={() =>
                                                    handleSalesCheck(
                                                      "workOrderUpdate"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Sales Documents
                                                <input
                                                  type="checkbox"
                                                  checked={docSalesDocuments}
                                                  onClick={() =>
                                                    handleSalesCheck(
                                                      "workOrderApproval"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Construction Documents
                                                <input
                                                  type="checkbox"
                                                  checked={docConstructionDocs}
                                                  onClick={() =>
                                                    handleSalesCheck("constDoc")
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                            </aside>
                                          </div>
                                          <div className="Button d-flex justify-content-center mt-3">
                                            <button
                                              className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                              onClick={handleSaleDocumentToggle}
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              className="Respond ml-3 SaveViewConfigBodyBtn"
                                              onClick={handleSalesDocumnet}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="card">
                                  <div className="card-header">
                                    <h4
                                      data-toggle="collapse"
                                      href="#Notifications"
                                    >
                                      <span>
                                        <img src="images/notification.png" />
                                      </span>{" "}
                                      Notifications
                                    </h4>
                                    <a
                                      className="Edit EditViewConfigBodyBtn"
                                      onClick={handleSaleNotificationToggle}
                                    >
                                      <img src="images/Action-1.png" />
                                    </a>
                                  </div>
                                  <div
                                    id="Notifications"
                                    className="collapse show"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body">
                                      {showSaleNotification === false && (
                                        <div className="ViewConfigBodyMain">
                                          <div className="ViewConfigBody">
                                            <aside>
                                              <p>
                                                <label>
                                                  New Document Available
                                                </label>
                                                <span>
                                                  {saleData?.clientViewSale
                                                    ?.notiNewDocAvailable ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Client Signature Pending
                                                </label>
                                                <span>
                                                  {saleData?.clientViewSale
                                                    ?.notiClientSignaturePending ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Builder Signature Completed
                                                </label>
                                                <span>
                                                  {saleData?.clientViewSale
                                                    ?.notiBuilderSignatureCompleted ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Scheduled Payment Date
                                                  Aproaching
                                                </label>
                                                <span>
                                                  {saleData?.clientViewSale
                                                    ?.notiSchePayDateAproaching ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>Missed Payment</label>
                                                <span>
                                                  {saleData?.clientViewSale
                                                    ?.notiMissedPayntent ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                            </aside>
                                          </div>
                                        </div>
                                      )}
                                      {showSaleNotification === true && (
                                        <div className="ViewConfigBodyHideMain">
                                          <div className="ViewConfigBodyHide">
                                            <aside>
                                              <label className="switch">
                                                {" "}
                                                New Document Available
                                                <input
                                                  type="checkbox"
                                                  checked={notiNewDocAvailable}
                                                  onClick={() =>
                                                    handleSalesCheck("newDoc")
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Client Signature Pending
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    notiClientSignaturePending
                                                  }
                                                  onClick={() =>
                                                    handleSalesCheck(
                                                      "clientSign"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Builder Signature Completed
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    notiBuilderSignatureCompleted
                                                  }
                                                  onClick={() =>
                                                    handleSalesCheck(
                                                      "buildSign"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Scheduled Payment Date
                                                Aproaching
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    notiSchePayDateAproaching
                                                  }
                                                  onClick={() =>
                                                    handleSalesCheck(
                                                      "schPayment"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Missed Payment
                                                <input
                                                  type="checkbox"
                                                  checked={notiMissedPayntent}
                                                  onClick={() =>
                                                    handleSalesCheck(
                                                      "notiMissPayment"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                            </aside>
                                          </div>
                                          <div className="Button d-flex justify-content-center">
                                            <button
                                              className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                              onClick={
                                                handleSaleNotificationToggle
                                              }
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              className="Respond ml-3 SaveViewConfigBodyBtn"
                                              onClick={handleSalesNotification}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="ConstructionLeft">
                          <div className="ResidentialJobArea ViewConfigArea">
                            <div className="ModalAccordian">
                              <div id="accordion">
                                <div className="card">
                                  <div className="card-header">
                                    <h4
                                      data-toggle="collapse"
                                      href="#ConstructionApps2"
                                    >
                                      <span>
                                        <img src="images/construction_apps.png" />
                                      </span>{" "}
                                      Construction Apps
                                    </h4>
                                    <a
                                      className="Edit EditViewConfigBodyBtn"
                                      onClick={handleConstructionAppToggle}
                                    >
                                      <img src="images/Action-1.png" />
                                    </a>
                                  </div>
                                  <div
                                    id="ConstructionApps2"
                                    className="collapse show"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body">
                                      {showConstructionApp === false && (
                                        <div className="ViewConfigBodyMain">
                                          <div className="ViewConfigBody">
                                            <aside>
                                              <p>
                                                <label>Project Progress</label>
                                                <span>
                                                  {constructionData
                                                    ?.construction
                                                    ?.projectProgress === true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Construction Photos
                                                </label>
                                                <span>
                                                  {constructionData
                                                    ?.construction
                                                    ?.constructionPictures ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                            </aside>
                                          </div>
                                        </div>
                                      )}
                                      {showConstructionApp === true && (
                                        <div className="ViewConfigBodyHideMain">
                                          <div className="ViewConfigBodyHide">
                                            <aside>
                                              <label className="switch">
                                                {" "}
                                                Project Progress
                                                <input
                                                  type="checkbox"
                                                  checked={projectProgress}
                                                  onClick={() =>
                                                    handleConstructionCheck(
                                                      "client"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Construction Photos
                                                <input
                                                  type="checkbox"
                                                  checked={constructionPictures}
                                                  onClick={() =>
                                                    handleConstructionCheck(
                                                      "home"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                            </aside>
                                          </div>
                                          <div className="Button d-flex justify-content-center">
                                            <button
                                              className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                              onClick={
                                                handleConstructionAppToggle
                                              }
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              className="Respond ml-3 SaveViewConfigBodyBtn"
                                              onClick={handleConstructionApp}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="card">
                                  <div className="card-header">
                                    <h4
                                      data-toggle="collapse"
                                      href="#ProjectProgress"
                                    >
                                      <span>
                                        <img src="images/sales_documents.png" />
                                      </span>{" "}
                                      Project Progress
                                    </h4>
                                    <a
                                      className="Edit EditViewConfigBodyBtn"
                                      onClick={handleConstructionProgressToggle}
                                    >
                                      <img src="images/Action-1.png" />
                                    </a>
                                  </div>
                                  <div
                                    id="ProjectProgress"
                                    className="collapse show"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body">
                                      {showConstructionProgress === false && (
                                        <div className="ViewConfigBodyMain">
                                          <div className="ViewConfigBody">
                                            <aside>
                                              <p>
                                                <label>
                                                  Show completed Phases
                                                </label>
                                                <span>
                                                  {constructionData
                                                    ?.construction
                                                    ?.showcompletedPhases ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>Show progress %</label>
                                                <span>
                                                  {constructionData
                                                    ?.construction
                                                    ?.showprogress === true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                            </aside>
                                          </div>
                                        </div>
                                      )}
                                      {showConstructionProgress === true && (
                                        <div className="ViewConfigBodyHideMain">
                                          <div className="ViewConfigBodyHide">
                                            <aside>
                                              <label className="switch">
                                                {" "}
                                                Show completed Phases
                                                <input
                                                  type="checkbox"
                                                  checked={showcompletedPhases}
                                                  onClick={() =>
                                                    handleConstructionCheck(
                                                      "Signature"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Show progress %
                                                <input
                                                  type="checkbox"
                                                  checked={showprogress}
                                                  onClick={() =>
                                                    handleConstructionCheck(
                                                      "Updated"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                            </aside>
                                          </div>
                                          <div className="Button d-flex justify-content-center mt-3">
                                            <button
                                              className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                              onClick={
                                                handleConstructionProgressToggle
                                              }
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              className="Respond ml-3 SaveViewConfigBodyBtn"
                                              onClick={
                                                handleConstructionProgress
                                              }
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="card">
                                  <div className="card-header">
                                    <h4
                                      data-toggle="collapse"
                                      href="#ConstructionPhotos"
                                    >
                                      <span>
                                        <img src="images/photo.png" />
                                      </span>{" "}
                                      Construction Photos
                                    </h4>
                                    <a
                                      className="Edit EditViewConfigBodyBtn"
                                      onClick={handleConstructionPhotosToggle}
                                    >
                                      <img src="images/Action-1.png" />
                                    </a>
                                  </div>
                                  <div
                                    id="ConstructionPhotos"
                                    className="collapse show"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body">
                                      {showConstructionPhotos === false && (
                                        <div className="ViewConfigBodyMain">
                                          <div className="ViewConfigBody">
                                            <aside>
                                              <p>
                                                <label>
                                                  Number of Pictures Displayed
                                                </label>
                                                <span>
                                                  {constructionData
                                                    ?.construction
                                                    ?.noOfPicturesDisplayed ??
                                                    "0"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Construction File Manager
                                                  Folder
                                                </label>
                                                <span>
                                                  {constructionData
                                                    ?.construction
                                                    ?.constFileManagerFolderId ??
                                                    "N/A"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>Client Download</label>
                                                <span>
                                                  {constructionData
                                                    ?.construction
                                                    ?.clientDownload === true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                            </aside>
                                          </div>
                                        </div>
                                      )}
                                      {showConstructionPhotos === true && (
                                        <div className="ViewConfigBodyHideMain">
                                          <div className="ViewConfigBodyHide">
                                            <aside>
                                              <p>
                                                <label>
                                                  Number of Pictures Displayed
                                                </label>
                                                <span className="">
                                                  <input
                                                    type="text"
                                                    name="noOfPicturesDisplayed"
                                                    value={
                                                      noOfPicturesDisplayed
                                                    }
                                                    onChange={handleInputChange}
                                                  />
                                                </span>
                                              </p>
                                              <div className="form-group">
                                                <h6>
                                                  Construction File Manager
                                                  Folder
                                                </h6>
                                                <div className="CommonSelectBox">
                                                  <select
                                                    name="constFileManagerFolderId"
                                                    value={
                                                      constFileManagerFolderId
                                                    }
                                                    onChange={handleInputChange}
                                                  >
                                                    <option value="">
                                                      Construction_Pictures_2023
                                                    </option>
                                                    <option>
                                                      Construction_Pictures_2023
                                                      1
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                              <label className="switch">
                                                {" "}
                                                Client Download
                                                <input
                                                  type="checkbox"
                                                  checked={clientDownload}
                                                  onClick={() =>
                                                    handleConstructionCheck(
                                                      "Builder"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                            </aside>
                                          </div>
                                          <div className="Button d-flex justify-content-center">
                                            <button
                                              className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                              onClick={
                                                handleConstructionPhotosToggle
                                              }
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              className="Respond ml-3 SaveViewConfigBodyBtn"
                                              onClick={handleConstructionPhotos}
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="AppointmentsLeft">
                          <div className="ResidentialJobArea ViewConfigArea">
                            <div className="ModalAccordian">
                              <div id="accordion">
                                <div className="card">
                                  <div className="card-header">
                                    <h4
                                      data-toggle="collapse"
                                      href="#ConstructionApps"
                                    >
                                      <span>
                                        <img src="images/calendar_settings.png" />
                                      </span>{" "}
                                      Calendar Settings
                                    </h4>
                                    <a
                                      className="Edit EditViewConfigBodyBtn"
                                      onClick={handleAppointmentToggle}
                                    >
                                      <img src="images/Action-1.png" />
                                    </a>
                                  </div>
                                  <div
                                    id="ConstructionApps"
                                    className="collapse show"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body">
                                      {showAppointment === false && (
                                        <div className="ViewConfigBodyMain">
                                          <div className="ViewConfigBody">
                                            <aside>
                                              <p>
                                                <label>
                                                  Display Calendar in the
                                                  Homepage
                                                </label>
                                                <span>
                                                  {appointmentData?.appointments
                                                    ?.displayCalendarHomepage ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Display Past Appointments
                                                </label>
                                                <span>
                                                  {appointmentData?.appointments
                                                    ?.displayPastAppointments ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                            </aside>
                                          </div>
                                        </div>
                                      )}
                                      {showAppointment === true && (
                                        <div className="ViewConfigBodyHideMain">
                                          <div className="ViewConfigBodyHide">
                                            <aside>
                                              <label className="switch">
                                                Display Calendar in the Homepage
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    displayCalendarHomepage
                                                  }
                                                  onClick={() =>
                                                    handleAppointmentCheck(
                                                      "client"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Display Past Appointments
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    displayPastAppointments
                                                  }
                                                  onClick={() =>
                                                    handleAppointmentCheck(
                                                      "home"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <p>
                                                <label>
                                                  Display Past Appointments
                                                </label>
                                                <span className="CommonSelectBox">
                                                  <select
                                                    name="defaultAppoReminderDate"
                                                    value={
                                                      defaultAppoReminderDate
                                                    }
                                                    onChange={handleInputChange}
                                                  >
                                                    <option value="">
                                                      Select Day
                                                    </option>
                                                    <option value="7">1</option>
                                                    <option value="14">
                                                      2
                                                    </option>
                                                    <option value="21">
                                                      3
                                                    </option>
                                                    <option value="28">
                                                      4
                                                    </option>
                                                  </select>
                                                  {/* <span>
                                                <img src="images/down.png" />
                                              </span> */}
                                                </span>
                                              </p>
                                            </aside>
                                          </div>
                                          <div className="Button d-flex justify-content-center">
                                            <button
                                              className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                              onClick={handleAppointmentToggle}
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              className="Respond ml-3 SaveViewConfigBodyBtn"
                                              onClick={
                                                handleAppointmentClientHome
                                              }
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="card">
                                  <div className="card-header">
                                    <h4
                                      data-toggle="collapse"
                                      href="#Notifications"
                                    >
                                      <span>
                                        <img src="images/notification.png" />
                                      </span>{" "}
                                      Notifications
                                    </h4>
                                    <a
                                      className="Edit EditViewConfigBodyBtn"
                                      onClick={
                                        handleAppointmentNotificationToggle
                                      }
                                    >
                                      <img src="images/Action-1.png" />
                                    </a>
                                  </div>
                                  <div
                                    id="Notifications"
                                    className="collapse show"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body">
                                      {showAppointmentNotification ===
                                        false && (
                                        <div className="ViewConfigBodyMain">
                                          <div className="ViewConfigBody">
                                            <aside>
                                              <p>
                                                <label>New Appointment</label>
                                                <span>
                                                  {appointmentData?.appointments
                                                    ?.newAppointment === true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Appointment Cancelled
                                                </label>
                                                <span>
                                                  {appointmentData?.appointments
                                                    ?.appointmentCancelled ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Appointment Update
                                                </label>
                                                <span>
                                                  {appointmentData?.appointments
                                                    ?.appointmentUpdate === true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  New Date Selection Event
                                                </label>
                                                <span>
                                                  {appointmentData?.appointments
                                                    ?.newDateSelectionEvent ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                            </aside>
                                          </div>
                                        </div>
                                      )}
                                      {showAppointmentNotification === true && (
                                        <div className="ViewConfigBodyHideMain">
                                          <div className="ViewConfigBodyHide">
                                            <aside>
                                              <label className="switch">
                                                {" "}
                                                New Appointment
                                                <input
                                                  type="checkbox"
                                                  checked={newAppointment}
                                                  onClick={() =>
                                                    handleAppointmentCheck(
                                                      "Signature"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Appointment Cancelled
                                                <input
                                                  type="checkbox"
                                                  checked={appointmentCancelled}
                                                  onClick={() =>
                                                    handleAppointmentCheck(
                                                      "Updated"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Appointment Update
                                                <input
                                                  type="checkbox"
                                                  checked={appointmentUpdate}
                                                  onClick={() =>
                                                    handleAppointmentCheck(
                                                      "Builder"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                New Date Selection Event
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    newDateSelectionEvent
                                                  }
                                                  onClick={() =>
                                                    handleAppointmentCheck(
                                                      "Rejected"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                            </aside>
                                          </div>
                                          <div className="Button d-flex justify-content-center">
                                            <button
                                              className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                              onClick={
                                                handleAppointmentNotificationToggle
                                              }
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              className="Respond ml-3 SaveViewConfigBodyBtn"
                                              onClick={
                                                handleAppointmentNotification
                                              }
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="ChangeOrdersLeft">
                          <div className="ResidentialJobArea ViewConfigArea">
                            <div className="ModalAccordian">
                              <div id="accordion">
                                <div className="card">
                                  <div className="card-header">
                                    <h4
                                      data-toggle="collapse"
                                      href="#ConstructionApps"
                                    >
                                      <span>
                                        <img src="images/change_order_settings.png" />
                                      </span>{" "}
                                      Change Order Settings
                                    </h4>
                                    <a
                                      className="Edit EditViewConfigBodyBtn"
                                      onClick={handleChangeOrderToggle}
                                    >
                                      <img src="images/Action-1.png" />
                                    </a>
                                  </div>
                                  <div
                                    id="ConstructionApps"
                                    className="collapse show"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body">
                                      {showChangeOrder === false && (
                                        <div className="ViewConfigBodyMain">
                                          <div className="ViewConfigBody">
                                            <aside>
                                              <p>
                                                <label>
                                                  Client Change Requests
                                                </label>
                                                <span>
                                                  {changeOrderData?.changeOrders
                                                    ?.ClientChangeRequests ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Display Change Order in the
                                                  Homepage
                                                </label>
                                                <span>
                                                  {changeOrderData?.changeOrders
                                                    ?.displayChangeOrderHomepage ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                            </aside>
                                          </div>
                                        </div>
                                      )}
                                      {showChangeOrder === true && (
                                        <div className="ViewConfigBodyHideMain">
                                          <div className="ViewConfigBodyHide">
                                            <aside>
                                              <label className="switch">
                                                {" "}
                                                Client Change Requests
                                                <input
                                                  type="checkbox"
                                                  checked={ClientChangeRequests}
                                                  onClick={() =>
                                                    handleChangeOrderCheck(
                                                      "client"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Display Change Order in the
                                                Homepage
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    displayChangeOrderHomepage
                                                  }
                                                  onClick={() =>
                                                    handleChangeOrderCheck(
                                                      "home"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                            </aside>
                                          </div>
                                          <div className="Button d-flex justify-content-center">
                                            <button
                                              className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                              onClick={handleChangeOrderToggle}
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              className="Respond ml-3 SaveViewConfigBodyBtn"
                                              onClick={
                                                handleChangeOrderClientHomeUpdate
                                              }
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="card">
                                  <div className="card-header">
                                    <h4
                                      data-toggle="collapse"
                                      href="#Notifications"
                                    >
                                      <span>
                                        <img src="images/notification.png" />
                                      </span>{" "}
                                      Notifications
                                    </h4>
                                    <a
                                      className="Edit EditViewConfigBodyBtn"
                                      onClick={handleChangeOrderNotification}
                                    >
                                      <img src="images/Action-1.png" />
                                    </a>
                                  </div>
                                  <div
                                    id="Notifications"
                                    className="collapse show"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body">
                                      {showChangeOrderNotification ===
                                        false && (
                                        <div className="ViewConfigBodyMain">
                                          <div className="ViewConfigBody">
                                            <aside>
                                              <p>
                                                <label>
                                                  Change Order Pending Signature
                                                </label>
                                                <span>
                                                  {changeOrderData?.changeOrders
                                                    ?.changeOrderPendingSignature ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Change Request Updated
                                                </label>
                                                <span>
                                                  {changeOrderData?.changeOrders
                                                    ?.changeRequestUpdated ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Change Order Signed by Builder
                                                </label>
                                                <span>
                                                  {changeOrderData?.changeOrders
                                                    ?.changeOrderSignedBuilder ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Change Request Rejected
                                                </label>
                                                <span>
                                                  {changeOrderData?.changeOrders
                                                    ?.changeRequestRejected ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                            </aside>
                                          </div>
                                        </div>
                                      )}
                                      {showChangeOrderNotification === true && (
                                        <div className="ViewConfigBodyHideMain">
                                          <div className="ViewConfigBodyHide">
                                            <aside>
                                              <label className="switch">
                                                {" "}
                                                Change Order Pending Signature
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    changeOrderPendingSignature
                                                  }
                                                  onClick={() =>
                                                    handleChangeOrderCheck(
                                                      "Signature"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Change Request Updated
                                                <input
                                                  type="checkbox"
                                                  checked={changeRequestUpdated}
                                                  onClick={() =>
                                                    handleChangeOrderCheck(
                                                      "Updated"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Change Order Signed by Builder
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    changeOrderSignedBuilder
                                                  }
                                                  onClick={() =>
                                                    handleChangeOrderCheck(
                                                      "Builder"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Change Request Rejected
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    changeRequestRejected
                                                  }
                                                  onClick={() =>
                                                    handleChangeOrderCheck(
                                                      "Rejected"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                            </aside>
                                          </div>
                                          <div className="Button d-flex justify-content-center">
                                            <button
                                              className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                              onClick={
                                                handleChangeOrderNotification
                                              }
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              className="Respond ml-3 SaveViewConfigBodyBtn"
                                              onClick={
                                                handleChangeOrderNotificationsUpdate
                                              }
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="WarrantyLeft">
                          <div className="ResidentialJobArea ViewConfigArea">
                            <div className="ModalAccordian">
                              <div id="accordion">
                                <div className="card">
                                  <div className="card-header">
                                    <h4
                                      data-toggle="collapse"
                                      href="#ConstructionApps"
                                    >
                                      <span>
                                        <img src="images/home_warranty.png" />
                                      </span>{" "}
                                      House Warranty Settings
                                    </h4>
                                    <a
                                      className="Edit EditViewConfigBodyBtn"
                                      onClick={handleWarrantyToggle}
                                    >
                                      <img src="images/Action-1.png" />
                                    </a>
                                  </div>
                                  <div
                                    id="ConstructionApps"
                                    className="collapse show"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body">
                                      {showWarrannty === false && (
                                        <div className="ViewConfigBodyMain">
                                          <div className="ViewConfigBody">
                                            <aside>
                                              <p>
                                                <label>
                                                  Warranty Request Creation by
                                                  Client
                                                </label>
                                                <span>
                                                  {clientViewConfigWarrantyList
                                                    ?.warranties
                                                    ?.warrantyReqCreationClient ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Display Warranty Request in
                                                  the Homepage
                                                </label>
                                                <span>
                                                  {clientViewConfigWarrantyList
                                                    ?.warranties
                                                    ?.displayWarrantyReqHomepage ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                            </aside>
                                          </div>
                                        </div>
                                      )}

                                      {showWarrannty === true && (
                                        <div className="ViewConfigBodyHideMain">
                                          <div className="ViewConfigBodyHide">
                                            <aside>
                                              <label className="switch">
                                                {" "}
                                                Warranty Request Creation by
                                                Client
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    warrantyReqCreationClient
                                                  }
                                                  onClick={() =>
                                                    handleWarrantyCheck(
                                                      "client"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Display Warranty Request in the
                                                Homepage
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    displayWarrantyReqHomepage
                                                  }
                                                  onClick={() =>
                                                    handleWarrantyCheck("home")
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                            </aside>
                                          </div>
                                          <div className="Button d-flex justify-content-center">
                                            <button
                                              className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                              onClick={handleWarrantyToggle}
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              className="Respond ml-3 SaveViewConfigBodyBtn"
                                              onClick={
                                                handleWarrantyClientHomeUpdate
                                              }
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="card">
                                  <div className="card-header">
                                    <h4
                                      data-toggle="collapse"
                                      href="#Notifications"
                                    >
                                      <span>
                                        <img src="images/notification.png" />
                                      </span>{" "}
                                      Notifications
                                    </h4>
                                    <a
                                      className="Edit EditViewConfigBodyBtn"
                                      onClick={handleWarrantyNotificationToggle}
                                    >
                                      <img src="images/Action-1.png" />
                                    </a>
                                  </div>
                                  <div
                                    id="Notifications"
                                    className="collapse show"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body">
                                      {showWarranntyNotification === false && (
                                        <div className="ViewConfigBodyMain">
                                          <div className="ViewConfigBody">
                                            <aside>
                                              <p>
                                                <label>
                                                  New Warranty Request{" "}
                                                </label>
                                                <span>
                                                  {clientViewConfigWarrantyList
                                                    ?.warranties
                                                    ?.newWarrantyRequest ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Warranty Request Updated
                                                </label>
                                                <span>
                                                  {clientViewConfigWarrantyList
                                                    ?.warranties
                                                    ?.warrantyRequestUpdated ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>New Work Order</label>
                                                <span>
                                                  {clientViewConfigWarrantyList
                                                    ?.warranties
                                                    ?.newWorkOrder === true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Work Order updated
                                                </label>
                                                <span>
                                                  {clientViewConfigWarrantyList
                                                    ?.warranties
                                                    ?.workOrderUpdated === true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                              <p>
                                                <label>
                                                  Work Order approval pending
                                                </label>
                                                <span>
                                                  {clientViewConfigWarrantyList
                                                    ?.warranties
                                                    ?.workOrderApprovalpending ===
                                                  true
                                                    ? "On"
                                                    : "Off"}
                                                </span>
                                              </p>
                                            </aside>
                                          </div>
                                        </div>
                                      )}

                                      {showWarranntyNotification === true && (
                                        <div className="ViewConfigBodyHideMain">
                                          <div className="ViewConfigBodyHide">
                                            <aside>
                                              <label className="switch">
                                                {" "}
                                                New Warranty Request
                                                <input
                                                  type="checkbox"
                                                  checked={newWarrantyRequest}
                                                  onClick={() =>
                                                    handleWarrantyCheck(
                                                      "newWarnt"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Warranty Request Updated
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    warrantyRequestUpdated
                                                  }
                                                  onClick={() =>
                                                    handleWarrantyCheck(
                                                      "warntReq"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                New Work Order
                                                <input
                                                  type="checkbox"
                                                  checked={newWorkOrder}
                                                  onClick={() =>
                                                    handleWarrantyCheck(
                                                      "newWorkOrd"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Work Order updated
                                                <input
                                                  type="checkbox"
                                                  checked={workOrderUpdated}
                                                  onClick={() =>
                                                    handleWarrantyCheck(
                                                      "workOrderUpdate"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                              <label className="switch">
                                                {" "}
                                                Work Order approval pending
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    workOrderApprovalpending
                                                  }
                                                  onClick={() =>
                                                    handleWarrantyCheck(
                                                      "workOrderApproval"
                                                    )
                                                  }
                                                />
                                                <span className="slider" />
                                              </label>
                                            </aside>
                                          </div>
                                          <div className="Button d-flex justify-content-center">
                                            <button
                                              className="OutlineBlack mr-3 SaveViewConfigBodyBtn"
                                              onClick={
                                                handleWarrantyNotificationToggle
                                              }
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              className="Respond ml-3 SaveViewConfigBodyBtn"
                                              onClick={
                                                handleWarrantyNotificationUpdate
                                              }
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={JobMenuModal}
          onHide={handleJobMenuModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleJobMenuModalClose}
            >
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Job Menu</h4>
              </div>
              <div className="FolderForm">
                <div className="JobMenuListing">
                  <ul>
                    <li>
                      <a href="javascript:void(0);">
                        <figure>
                          <img src="images/add_build.png" />
                        </figure>
                        Add to/Edit Build
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <figure>
                          <img src="images/details.png" />
                        </figure>
                        Details
                      </a>
                    </li>
                    <li className="JobMenuListing_All">
                      <Link to="/change-order-list" state={state}>
                        <figure>
                          <img src="images/change_order.png" />
                        </figure>
                        Change Order
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                      <ul className="JobMenuListingUnder">
                        <li>
                          <Link to="/change-order-list" state={state}>
                            Change orders List
                          </Link>
                        </li>
                        <li>
                          <Link to="/change-order-list" state={state}>
                            Change to Contract (CTC) master
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/design-selections" state={state}>
                        <figure>
                          <img src="images/design.png" />
                        </figure>
                        Design
                      </Link>
                    </li>
                    <li>
                      <Link to="/sales" state={state}>
                        <figure>
                          <img src="images/sales.png" />
                        </figure>
                        Sales
                      </Link>
                    </li>
                    <li>
                      <Link to="/estimation" state={state}>
                        <figure>
                          <img src="images/estimation.png" />
                        </figure>
                        Estimation
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="/construction" state={state}>
                        <figure>
                          <img src="images/construction_icon.png" />
                        </figure>
                        Construction
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="/warranty-dashboard" state={state}>
                        <figure>
                          <img src="images/warranty.png" />
                        </figure>
                        Warranty
                      </Link>
                    </li>
                    <li>
                      <Link to="/reports" state={state}>
                        <figure>
                          <img src="images/reports.png" />
                        </figure>
                        Reports
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                    </li>
                    {pathname !== "client-view-config" && (
                      <li>
                        <Link to="/client-view-config" state={state}>
                          <figure>
                            <img src="images/view_config.png" />
                          </figure>
                          Views Config
                          <img
                            src="images/rightarrow2.png"
                            className="ListingOverflow"
                          />
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={NavigateModal}
          onHide={handleNavigateModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleNavigateModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Navigate Project</h4>
              </div>
              <div className="FolderForm">
                <div className="NaviagateProjectArea">
                  <ul>
                    {allProjectList?.projects?.length > 0 &&
                      allProjectList?.projects.map((item, index) => {
                        return (
                          <>
                            <li className="DarkBlueList AvalonTownsTab">
                              <a href="javascript:void(0);">
                                {item.projectName}
                              </a>
                            </li>
                            <li className="LightBlueList AdministrationTab">
                              <a href="javascript:void(0);">
                                {item.jobPattern}
                              </a>
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu dropdown-menuwidth DropDownMenuZindex">
                                      <li>
                                        <a>
                                          <img src="images/add_build.png" />
                                          Add to/Edit Build
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <img src="images/details.png" />
                                          Details
                                        </a>
                                      </li>
                                      <li className="border-0">
                                        <Link
                                          to="/change-order-list"
                                          state={state}
                                          className="dropdown-item"
                                          style={{
                                            border:
                                              "1px solid transparent!important",
                                          }}
                                        >
                                          <img src="images/change_order.png" />
                                          Change Order &nbsp;&nbsp;
                                          <img src="images/rightarrow2.png" />
                                        </Link>
                                        <ul className="dropdown-menu submenu">
                                          <li>
                                            <Link
                                              to="/change-order-list"
                                              state={state}
                                              className="dropdown-item"
                                            >
                                              Change orders List
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              to="/change-order-list"
                                              state={state}
                                              className="dropdown-item"
                                            >
                                              Change to Contract (CTC) master
                                            </Link>
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <Link
                                          to="/design-selections"
                                          state={state}
                                        >
                                          <img src="images/design.png" />
                                          Design
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/sales" state={state}>
                                          <img src="images/sales.png" />
                                          Sales
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/estimation" state={state}>
                                          <img src="images/estimation.png" />
                                          Estimation
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/construction" state={state}>
                                          <img src="images/construction_icon.png" />
                                          Construction
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to="/warranty-dashboard"
                                          state={state}
                                        >
                                          <img src="images/warranty.png" />
                                          Warranty
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/reports" state={state}>
                                          <img src="images/reports.png" />
                                          Reports
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to="/client-view-config"
                                          state={state}
                                        >
                                          <img src="images/view_config.png" />
                                          Views Config
                                        </Link>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ClientViewConfig;
