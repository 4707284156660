import React, { useEffect, useState } from "react";
import Header from "../Header";
import SideNav from "../SideNav";
import IbuildAdminSidenav from "./IbuildAdminSidenav";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import {
  UnlockIbuildAdminUserRoleAction,
  addIbuildRoleAction,
  addIbuildUserAction,
  getAllIbuildAdminRoleListAction,
  getAllIbuildAdminUserListAction,
  lockIbuildAdminUserRoleAction,
  removeIbuildAdminUserAction,
  resetIbuildAdminUserPasswordAction,
  updateIbuildUserAction,
  userActivityDataAction,
} from "../../redux/action/ibuildAdminRoleManagement";
import { errorToast, sucessToast } from "../../utils/toast";
import { checkArray } from "../../utils/CheckType";
import PhoneInput from "react-phone-input-2";
import Loader from "../Loader";
import {
  headerNotifcaitionList,
  removeHeaderNotifcaitionList,
  updateChangePasswordLinkAction,
} from "../../redux/action/authAction";
import {
  getAllProjectDetailsAction,
  getAllProjectListAction,
} from "../../redux/action/jobAction";
import { notificationsAction } from "../../redux/actionTypes";
import {
  AddDiscussionAction,
  AddReplyAction,
  discussionListAction,
} from "../../redux/action/constructionsAction";
import NoDataFound from "../Nodatafound";
import { toast } from "react-toastify";
import moment from "moment";
import {
  addHelpAndSupportAction,
  getHeaderHelpListAction,
  helpDetailsAction,
  helpListAction,
} from "../../redux/action/systemAdministrationAction";
import AddContact from "../headerModals/addContact";
import ContactList from "../headerModals/contactList";
import IbuildAdminHeader from "./IbuildAdminHeader";
const init = {
  addRoleModal: false,
  addUserModal: false,
  addUserRoleModal: false,
  editUserRoleModal: false,
  userActivityDataModal: false,
  roleName: "",
  roleDescription: "",
  modelRole: null,
  RolePermissions: [
    {
      moduleName: "",
      view: false,
      full: false,
    },
    {
      moduleName: "",
      view: false,
      full: false,
    },
    {
      moduleName: "Subscription Plans",
      view: false,
      full: false,
    },
    {
      moduleName: "",
      view: false,
      full: false,
    },
    {
      moduleName: "",
      view: false,
      full: false,
    },
    {
      moduleName: "",
      view: false,
      full: false,
    },
    {
      moduleName: "",
      view: false,
      full: false,
    },
    {
      moduleName: "",
      view: false,
      full: false,
    },
    {
      moduleName: "",
      view: false,
      full: false,
    },
    {
      moduleName: "",
      view: false,
      full: false,
    },
  ],
  name: "",
  email: "",
  phoneNumber: "",
  phonecode: "",
  RoleId: "",
  modalId: "",
  modalName: "",
  emailModal: false,
  emailError: "",
  projectDetailsModal: false,
  projectDetailsData: [],
  ProjectNameShow: "",
  companyId: "",
  clientName: "",
  availability: "",
  contactPrefrence: "",
  languagePrefrence: "",
  issueDetailsType: "",
  severity: "",
  module: "",
  description: "",
  problemStartDate: "",
  time: "",
  timeType: "",
  timeZone: "",
  errorMessage: "",
  applicationLink: "",
  helpAndSupportError: {},
  avinashEmail: "",
  supportingDocFiles: [],
  falseImage: "",
  addSupportModal: false,
  historyListData: [],
  supportListingModal: false,
  supportDetailsModal: false,
  userActivityDetailsData: [],
};
function IbuildUserManagement() {
  const [iState, updateState] = useState(init);
  const { addRoleModal, addUserModal, addUserRoleModal } = iState;
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const [widthState, setWidth] = useState(false);
  const [helpState, setHelpState] = useState(false);
  const [UserProfileModal, setUserProfile] = useState(false);

  const [ContactListModal, setContactList] = useState(false);
  const clientData = JSON.parse(window.localStorage.getItem("clientViewLogin"));
  const [ContactDetailsModal, SetContactDetails] = useState(false);
  const {
    helpListData,
    helpHistoryListData,
    helpDetailsData,
    headerHelpListData,
  } = useSelector((state) => state.systemAdministrationReducer);
  const [AddContactModal, SetAddContact] = useState(false);
  const [NavShow, SetNavShow] = useState(false);

  const { discussionList } = useSelector((state) => state.constructionReducer);
  const {
    roleName,
    roleDescription,
    modelRole,
    RolePermissions,
    name,
    email,
    phoneNumber,
    phonecode,
    RoleId,
    editUserRoleModal,
    userActivityDataModal,
    modalId,
    modalName,
    emailModal,
    emailError,
    projectDetailsModal,
    projectDetailsData,
    ProjectNameShow,
    companyId,
    clientName,
    availability,
    contactPrefrence,
    languagePrefrence,
    issueDetailsType,
    severity,
    module,
    description,
    problemStartDate,
    time,
    timeType,
    timeZone,
    errorMessage,
    applicationLink,
    helpAndSupportError,
    addSupportModal,
    supportingDocFiles,
    falseImage,
    supportListingModal,
    supportDetailsModal,
    userActivityDetailsData,
  } = iState;

  const { iBuildAdminUserListData, loader, iBuildAdminRoleListData } =
    useSelector((state) => state.authReducer);
  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };
  let path = window.location.href.split("/");
  let pathLength = parseInt(path.length) - 1;
  let pathMatch = path[pathLength];
  useEffect(() => {
    dispatch(getAllIbuildAdminUserListAction());
    dispatch(getAllIbuildAdminRoleListAction());
  }, []);
  const clientViewLogin = JSON.parse(localStorage.getItem("clientViewLogin"));
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const {
    countryData,
    cityData,
    stateData,
    timeZoneData,
    headerNotificationListData,
  } = useSelector((state) => state.authReducer);
  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    updateState({ ...iState, [name]: result });
  };

  useEffect(() => {
    if (iBuildLocalData?.user?.CompanyId != null) {
      dispatch(getAllProjectListAction());
      dispatch(headerNotifcaitionList());
    }
  }, [dispatch, iBuildLocalData?.user?.CompanyId]);
  useEffect(() => {
    dispatch(getHeaderHelpListAction(pathMatch));
  }, [pathMatch]);

  const hideAddRoleModal = () => {
    updateState({ ...iState, addRoleModal: false });
  };

  const showAddRoleModal = () => {
    updateState({ ...iState, addRoleModal: true });
  };

  const hideAddUserModal = () => {
    updateState({ ...iState, addUserModal: false });
  };

  const showAddUserModal = () => {
    updateState({ ...iState, addUserModal: true });
  };

  const hideEditUserModal = () => {
    updateState({ ...iState, editUserRoleModal: false });
  };

  const showEditUserModal = (id) => {
    updateState({ ...iState, editUserRoleModal: true, modalId: id });
  };

  const hideUserActivityModal = () => {
    updateState({ ...iState, userActivityDataModal: false });
  };

  const showUserActivityModal = (id, name) => {
    dispatch(userActivityDataAction(id)).then((res) => {
      updateState({
        ...iState,
        userActivityDetailsData: res?.data,
        userActivityDataModal: true,
        modalName: name,
      });
    });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const [modulePermissions, setModulePermissions] = useState([]);

  const modules = [
    { name: "Customer Information" },
    { name: "Help Messages" },
    { name: "Subscription Plans" },
    { name: "Customer Support" },
    { name: "Cost Code Master Profile" },
    { name: "Ibuild Demo" },
    { name: "Client Login" },
    { name: "System File Manager" },
    { name: "Price Book" },
    { name: "User Management" },
  ];
  const handleInputFile = (e) => {
    const { name, value } = e.target;
    const file = e.target.files[0];
    console.log("======>", file);
    if (file) {
      updateState({
        ...iState,
        [name]: file,
      });
    }
    e.target.files = null;
  };

  const handlePermissionChange = (index, field, value) => {
    const updatedPermissions = [...modulePermissions];
    // updatedPermissions[index] = {
    //   ...updatedPermissions[index],
    //   [field]: value,
    // };

    if (field === "view" && value == true) {
      updatedPermissions[index].full = !value;
      updatedPermissions[index].view = value;
    } else if (field === "full" && value == true) {
      updatedPermissions[index].view = !value;
      updatedPermissions[index].full = value;
    }
    setModulePermissions(updatedPermissions);
  };
  const updatePermissionData = () => {
    const updatedPermissions = modules.map((module) => ({
      moduleName: module.name,
      view: false,
      full: false,
    }));

    setModulePermissions(updatedPermissions);
  };
  useEffect(() => {
    updatePermissionData();
    const element = document.getElementById("subadmin-id");
    if (element) {
      element.scrollIntoView();
    }
  }, []);

  const renderRows = () => {
    return modulePermissions.map((module, index) => (
      <tr key={index}>
        <td>
          <strong>{module.moduleName}</strong>
        </td>

        <td></td>
        <td>
          <label class="CheckBox">
            <input
              type="checkbox"
              name={`view${index}`}
              checked={module.view}
              onChange={(e) =>
                handlePermissionChange(index, "view", e.target.checked)
              }
            />
            <span class="checkmark"></span>
          </label>
        </td>

        <td>
          <label class="CheckBox">
            <input
              type="checkbox"
              name={`full${index}`}
              checked={module.full}
              onChange={(e) =>
                handlePermissionChange(index, "full", e.target.checked)
              }
            />
            <span class="checkmark"></span>
          </label>
        </td>
      </tr>
    ));
  };

  const addRoleFunc = (e) => {
    e.preventDefault();
    let data = {
      roleName,
      roleDescription,
      modelRole,
      RolePermissions: modulePermissions,
    };
    dispatch(addIbuildRoleAction(data)).then((Res) => {
      hideAddRoleModal();
      dispatch(getAllIbuildAdminRoleListAction());
      sucessToast("Role added successfully");
      console.log("REssssss", Res);
    });
  };

  const addUserFunc = (e) => {
    e.preventDefault();
    let data = {
      name,
      email,
      phoneNumber,
      phonecode,
      RoleId,
    };
    dispatch(addIbuildUserAction(data))
      .then((res) => {
        sucessToast("User added successfully");
        hideAddUserModal();
        dispatch(getAllIbuildAdminUserListAction());
      })
      .catch((err) => {
        errorToast(err.response.data.message);
      });
  };

  const handlePhoneInputChange = (value, country) => {
    updateState({
      ...iState,
      phonecode: `+${country.dialCode}`,
    });
  };

  const editUserFunc = (e) => {
    e.preventDefault();
    let data = {
      name,
      email,
      phoneNumber,
      phonecode,
      RoleId,
      userStatus: "ACTIVE",
      absenceFrom: "2024-01-06T12:30:45.678Z",
      absenceTo: "2024-01-06T12:30:45.678Z",
      backUpUserId: null,
    };
    dispatch(updateIbuildUserAction(modalId, data))
      .then((res) => {
        sucessToast("User updated successfully");
        hideEditUserModal();
        dispatch(getAllIbuildAdminUserListAction());
      })
      .catch((err) => {
        errorToast(err.response.data.message);
      });
  };

  const removeUserFunc = (id) => {
    dispatch(removeIbuildAdminUserAction(id))
      .then((res) => {
        sucessToast("User removed successfully");
        dispatch(getAllIbuildAdminUserListAction());
      })
      .catch((err) => {
        errorToast(err.response.data.message);
      });
  };

  const lockUserFunc = (id) => {
    dispatch(lockIbuildAdminUserRoleAction(id))
      .then((res) => {
        sucessToast("User locked successfully");
        dispatch(getAllIbuildAdminUserListAction());
      })
      .catch((err) => {
        errorToast(err.response.data.message);
      });
  };

  const unlockUserFunc = (id) => {
    dispatch(UnlockIbuildAdminUserRoleAction(id))
      .then((res) => {
        sucessToast("User unlocked successfully");
        dispatch(getAllIbuildAdminUserListAction());
      })
      .catch((err) => {
        errorToast(err.response.data.message);
      });
  };

  const resetPasswordFunc = (id) => {
    let data = { email: id };
    dispatch(resetIbuildAdminUserPasswordAction(data))
      .then((res) => {
        sucessToast("User unlocked successfully");
        dispatch(getAllIbuildAdminUserListAction());
      })
      .catch((err) => {
        errorToast(err.response.data.message);
      });
  };

  const showSupportModal = () => {
    updateState({
      ...iState,
      addSupportModal: true,
    });
  };

  const hideSupportModal = () => {
    updateState({
      ...iState,
      addSupportModal: false,
    });
  };

  const showSupportListingModal = () => {
    dispatch(helpListAction());
    updateState({
      ...iState,
      supportListingModal: true,
    });
  };

  const hideSupportListingModal = () => {
    updateState({
      ...iState,
      supportListingModal: false,
    });
  };

  const [discussionState, updateDiscussionState] = useState({
    jobId: "",
    message: "",
    discussionError: "",
  });
  const [replyState, updateReplyState] = useState({
    messageId: "",
    jobId: "",
    message: "",
    replyError: "",
  });
  const [replyField, updateReplyField] = useState({ flag: false, index: "" });

  let handleValidationDiscussion = () => {
    let discussionError = "";
    let formIsValid = true;

    if (discussionState.message == "") {
      discussionError = "* Please enter comment";
      formIsValid = false;
    }

    updateDiscussionState({
      ...discussionState,
      discussionError: discussionError,
    });
    return formIsValid;
  };

  const hideSupportDetailsModal = (d) => {
    updateState({
      ...iState,
      supportDetailsModal: false,
    });
  };

  const showSupportDetailsModal = (id) => {
    dispatch(helpDetailsAction(id));
    updateState({
      ...iState,
      supportDetailsModal: true,
      modalId: id,
    });
  };

  const handleAddDiscussion = () => {
    let formIsValid = handleValidationDiscussion();
    if (formIsValid) {
      dispatch(
        AddDiscussionAction({
          message: discussionState.message,
          helpId: helpDetailsData[0]?.id,
          type: "Help",
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(
              discussionListAction({
                helpId: helpDetailsData[0]?.id,
                type: "Help",
              })
            );

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleAddReply = () => {
    let formIsValid = true;
    if (formIsValid) {
      dispatch(
        AddReplyAction({
          message: replyState.message,
          helpId: helpDetailsData[0]?.id,
          type: "Help",
          messageId: replyState.messageId,
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(
              discussionListAction({
                helpId: helpDetailsData[0]?.id,
                type: "Help",
              })
            );
            updateReplyState({
              messageId: "",
              jobId: "",
              message: "",
              replyError: "",
            });

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const addSupportFunc = (e) => {
    e.preventDefault();
    let data = {
      companyId: clientViewLogin?.user?.CompanyId,
      companyName: clientViewLogin?.user?.name,
      clientName,
      phoneNumber,
      phonecode,
      availability,
      contactPrefrence,
      languagePrefrence,
      issueDetailsType,
      severity,
      module,
      description,
      problemStartDate,
      time,
      timeType,
      timeZone,
      errorMessage,
      applicationLink,
      supportingDocFiles,
      // helpAndSupportError
    };
    console.log("addSupport", data);
    dispatch(addHelpAndSupportAction(data))
      .then((res) => {
        console.log(res);
        if (res.status === 400) {
          errorToast(res.data.message);
        } else {
          sucessToast("Support added successfully");
          hideSupportModal();
          dispatch(helpListAction());
        }
      })
      .catch((err) => {
        console.log("errrr", err);
        errorToast(err.response.data.message);
      });
  };

  const navigate = useNavigate();
  const signOutFunc = () => {
    window.location.reload();
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <IbuildAdminHeader />
      <IbuildAdminSidenav />

      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TodosArea ">
            <div className="d-flex align-items-center justify-content-between TodosAreaFlex">
              <div>
                <div className="TitleBox bg-none border-0 p-0">
                  <h4 className="Title">User Management</h4>
                </div>
              </div>
              <div className="TodosButtons mt-0 d-flex">
                <a href="javascript:void(0)" onClick={showAddUserModal}>
                  <img src="images/create_new_lead.png" />
                  Create New User Button <span className="Count">3</span>
                </a>
                <a onClick={showAddUserModal} href="javascript:void(0)">
                  <h6>
                    <img src="images/create_new_lead.png" />
                    Create New User Role{" "}
                  </h6>
                  <span className="Count">3</span>
                </a>
              </div>
            </div>
          </div>
          <div className="FilesArea">
            <div className="FilesHead">
              <div className="FilesLeft">
                <h4 className="mr-4">User</h4>
                <aside>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                  <span className="CursorPointer">
                    <img src="images/search.png" />
                  </span>
                </aside>
              </div>
              <div className="NewActions">
                <a href="javascript:void(0);">
                  <img src="images/icon-53.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/download.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/setting.png" />
                </a>
              </div>
            </div>
          </div>
          <div className="TableList TableListDecoration CommonHeading">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Status</th>
                  <th>Role</th>
                  <th>Assigned Projects</th>
                  <th>Creation Date</th>
                  <th>Absence</th>
                  <th>Back-up user</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <>
                    <td>
                      {" "}
                      <Loader />
                    </td>
                  </>
                ) : (
                  <>
                    {checkArray(iBuildAdminUserListData?.users) ? (
                      iBuildAdminUserListData?.users?.map((ele, ind) => {
                        return (
                          <>
                            <tr>
                              <td>{ele?.name}</td>
                              <td>{ele?.email}</td>
                              <td>{ele?.phoneNumber}</td>
                              <td>{ele?.userStatus}</td>
                              <td>
                                <div className="TextRed">
                                  {ele?.Role?.roleName}
                                </div>
                              </td>
                              <td>All</td>
                              <td>{ele?.createdAt?.split("T")[0]}</td>
                              <td />
                              <td />
                              <td>
                                <div className="TableThreeDots">
                                  <ul className="">
                                    <li className="dropdown">
                                      <a
                                        className=""
                                        role="button"
                                        data-toggle="dropdown"
                                      >
                                        <img src="images/dots.png" />
                                      </a>
                                      <ol className="dropdown-menu">
                                        <li>
                                          <a
                                            href="javascript:void(0);"
                                            onClick={() =>
                                              showEditUserModal(ele?.id)
                                            }
                                          >
                                            <img src="images/modify-user.png" />
                                            Modify User
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            onClick={() =>
                                              lockUserFunc(ele?.id)
                                            }
                                            href="javascript:void(0);"
                                          >
                                            <img src="images/lock_user.png" />{" "}
                                            Lock User
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            onClick={() =>
                                              unlockUserFunc(ele?.id)
                                            }
                                            href="javascript:void(0);"
                                          >
                                            <img src="images/unlock_user.png" />
                                            Unlock User
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            onClick={() =>
                                              resetPasswordFunc(ele?.email)
                                            }
                                            href="javascript:void(0);"
                                          >
                                            <img src="images/reset_password.png" />{" "}
                                            Reset Password
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="javascript:void(0);"
                                            onClick={() =>
                                              showUserActivityModal(
                                                ele?.id,
                                                ele?.name
                                              )
                                            }
                                          >
                                            <img src="images/user_activity.png" />{" "}
                                            User Activity
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            onClick={() =>
                                              removeUserFunc(ele?.id)
                                            }
                                            href="javascript:void(0);"
                                          >
                                            {" "}
                                            <img src="images/dropdown-delete.png" />
                                            Delete User
                                          </a>
                                        </li>
                                      </ol>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <td>
                        {" "}
                        <NoDataFound />
                      </td>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
          {/* <div className="PaginationArea">
      <h6>Showing 1 to 10 of 27 entries</h6>
      <h6>
        <img src="images/leftarrow.png" /> <span>01</span>{" "}
        <img src="images/rightarrow.png" /> Out 10
      </h6>
    </div> */}
        </div>
      </div>

      <Modal
        show={addRoleModal}
        onHide={hideAddRoleModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAddRoleModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create New Role</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="form-group">
                  <h6>
                    Role Name<span className="required">*</span>
                  </h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert name"
                    name="roleName"
                    value={roleName}
                    onChange={handleInput}
                  />
                </div>
                <div className="form-group">
                  <h6>
                    Role Description<span className="required">*</span>
                  </h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert desc..."
                    name="roleDescription"
                    value={roleDescription}
                    onChange={handleInput}
                  />
                </div>
                <div className="form-group">
                  <h6>Permissions</h6>
                  <h6>Model Role</h6>
                  <div className="Categories Categories3">
                    <div className="Categories_all Categories_all3">
                      <span>Select user role</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>+ Search</li>
                      <li>-List of available user roles</li>
                    </ul>
                  </div>
                </div>
                <div className="CheckBoxTable CheckBoxTableTop">
                  <table>
                    <thead>
                      <tr>
                        <th />
                        <th>Function</th>
                        <th>View</th>
                        <th>Full</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className="CheckBoxTable CheckBoxTable1 UserManagementTable">
                  <table>
                    <thead>
                      <tr>
                        <th>Customer Information</th>
                        <th />
                        <th>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </th>
                        <th>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{renderRows()}</tbody>
                  </table>
                </div>
                <div className="Button mt-5 d-flex justify-content-center">
                  <button onClick={addRoleFunc} className="Create">
                    Create Role
                  </button>
                  <button onClick={hideAddRoleModal} className="Outline">
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={addUserModal}
        onHide={hideAddUserModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAddUserModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create New User</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>
                  Name <span className="required">*</span>{" "}
                </h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert Name"
                  name="name"
                  value={name}
                  onChange={handleInput}
                />
              </div>
              <div className="form-group">
                <h6>
                  Email <span className="required">*</span>{" "}
                </h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert email address"
                  name="email"
                  value={email}
                  onChange={handleInput}
                />
              </div>

              <div className="form-group">
                <h6>Country Code</h6>
                <PhoneInput
                  inputClass="CountryCodeInput"
                  countryCodeEditable={false}
                  enableSearch
                  value={phonecode}
                  onChange={handlePhoneInputChange}
                />
              </div>
              <div className="form-group">
                <h6>Phone Number </h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert Phone Number"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={handleInput}
                />
              </div>
              <div className="form-group">
                <h6>
                  Role <span className="required">*</span>{" "}
                </h6>
                <div className="Categories Categories1">
                  <div className="Categories_all Categories_all1">
                    <select name="RoleId" value={RoleId} onChange={handleInput}>
                      <option>Select user role</option>
                      {checkArray(iBuildAdminRoleListData?.data) &&
                        iBuildAdminRoleListData?.data?.map((ele, i) => {
                          return (
                            <option key={i} value={ele.id}>
                              {ele.roleName}
                            </option>
                          );
                        })}
                    </select>
                    <img src="images/down.png" />
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between mt-4">
                <button className="Create" onClick={addUserFunc}>
                  Create User
                </button>
                <button className="Outline" onClick={hideAddUserModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={editUserRoleModal}
        onHide={hideEditUserModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideEditUserModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create New User</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>
                  Name <span className="required">*</span>{" "}
                </h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert Name"
                  name="name"
                  value={name}
                  onChange={handleInput}
                />
              </div>
              <div className="form-group">
                <h6>
                  Email <span className="required">*</span>{" "}
                </h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert email address"
                  name="email"
                  value={email}
                  onChange={handleInput}
                />
              </div>

              <div className="form-group">
                <h6>Country Code</h6>
                <PhoneInput
                  inputClass="CountryCodeInput"
                  countryCodeEditable={false}
                  enableSearch
                  value={phonecode}
                  onChange={handlePhoneInputChange}
                />
              </div>
              <div className="form-group">
                <h6>Phone Number </h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert Phone Number"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={handleInput}
                />
              </div>
              <div className="form-group">
                <h6>
                  Role <span className="required">*</span>{" "}
                </h6>
                <div className="Categories Categories1">
                  <div className="Categories_all Categories_all1">
                    <select name="RoleId" value={RoleId} onChange={handleInput}>
                      <option>Select user role</option>
                      {checkArray(iBuildAdminRoleListData?.data) &&
                        iBuildAdminRoleListData?.data?.map((ele, i) => {
                          return (
                            <option key={i} value={ele.id}>
                              {ele.roleName}
                            </option>
                          );
                        })}
                    </select>
                    <img src="images/down.png" />
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between mt-4">
                <button className="Create" onClick={editUserFunc}>
                  Update User
                </button>
                <button className="Outline" onClick={hideEditUserModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={userActivityDataModal}
        onHide={hideUserActivityModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideUserActivityModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>User Activity</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="row">
                  <div className="col-md-7">
                    <div className="form-group">
                      <h6>
                        Name <span className="required">*</span>{" "}
                      </h6>
                      <p className="ml-0">{modalName}</p>
                    </div>
                  </div>
                </div>

                <div className="NotesArea mb-2">
                  <h6>Activity</h6>
                  <div>
                    <a href="javascript:void(0);" title="pdf">
                      <img src="images/pdf.png" />
                    </a>
                    <a href="javascript:void(0);" title="download">
                      <img src="images/download.png" />
                    </a>
                    <a href="javascript:void(0);" title="settings">
                      <img src="images/setting.png" />
                    </a>
                  </div>
                </div>
                <div className="TableList CommonHeading">
                  <table>
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {checkArray(userActivityDetailsData) &&
                        userActivityDetailsData?.map((ele, i) => {
                          return (
                            <>
                              <tr>
                                <td>{ele?.activity}</td>
                                <td>{ele?.date?.split("T")[0]}</td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="Button  text-center  mt-4">
                  <button className="Outline" onClick={hideUserActivityModal}>
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={addSupportModal}
        onHide={hideSupportModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSupportModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Support Request</h4>
            </div>
            <div className="FolderForm">
              <div className="ModalBigheading mb-4">
                <h3 className="mb-1">
                  <span>Contact Details</span>
                </h3>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <h6>
                      Company <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="companyId"
                        value={companyId}
                        onChange={handleInput}
                      >
                        <option selected="">Select Company</option>
                        <option value="Company 1">Company 1</option>
                        <option value="Company 2">Company 2</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Client Name <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="clientName"
                        value={clientName}
                        onChange={handleInput}
                      >
                        <option selected="">Select Client</option>
                        <option value="Client 1">Client 1</option>
                        <option value="CLient 2">Client 2</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group">
                    <h6>Country Code</h6>
                    <PhoneInput
                      inputClass="CountryCodeInput"
                      countryCodeEditable={false}
                      enableSearch
                      value={phonecode}
                      onChange={handlePhoneInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <h6>
                      Telephone <span className="required">*</span>
                    </h6>
                    <input
                      type="text"
                      className="form-control"
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={handleInputNumber}
                    />
                  </div>
                </div>
                <div className="col-md-5 ml-auto">
                  <div className="form-group">
                    <h6>
                      Availability <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="availability"
                        value={availability}
                        onChange={handleInput}
                      >
                        <option selected="">Select Availability</option>
                        <option value="Complete">Complete</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Contact Preference <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="contactPrefrence"
                        value={contactPrefrence}
                        onChange={handleInput}
                      >
                        <option selected="">Select Preference</option>
                        <option value="Email">Email </option>
                        <option value="Phone">Phone </option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Email <span className="required">*</span>
                    </h6>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="form-group">
                    <h6>
                      Language Preference <span className="required">*</span>
                    </h6>
                    <div className="d-flex justify-content-between">
                      <label className="Radio text-black ">
                        {" "}
                        English
                        <input
                          type="radio"
                          name="languagePrefrence"
                          value={"English"}
                          onChange={handleRadioChange}
                        />
                        <span className="checkmark" />
                      </label>
                      <label className="Radio text-black ">
                        {" "}
                        French
                        <input
                          type="radio"
                          name="languagePrefrence"
                          value={"French"}
                          onChange={handleRadioChange}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ModalBigheading mb-4">
                <h3 className="mb-1">
                  <span>Issue Details</span>
                </h3>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <h6>
                      Type <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="issueDetailsType"
                        value={issueDetailsType}
                        onChange={handleInput}
                      >
                        <option selected="">Select Type</option>
                        <option value="Type 1">Type 1</option>
                        <option value="Type 2">Type 2</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Module <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="module"
                        value={module}
                        onChange={handleInput}
                      >
                        <option selected="">Select Module</option>
                        <option value="Module 1">Module 1</option>
                        <option value="Module 2">Module 2</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 ml-auto">
                  <div className="form-group">
                    <h6>
                      Severity <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="severity"
                        value={severity}
                        onChange={handleInput}
                      >
                        <option selected="">Select Severity</option>
                        <option value="Severity 1">Severity 1</option>
                        <option value="Severity 2">Severity 2</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <h6>
                      Description <span className="required">*</span>
                    </h6>
                    <textarea
                      className="form-control"
                      placeholder="Insert Description"
                      rows={3}
                      name="description"
                      value={description}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="form-group col-12 mb-0">
                  <h6>
                    Problem Start Date <span className="required">*</span>
                  </h6>
                </div>
                <div className="form-group col-md-4">
                  <input
                    type="date"
                    className="form-control"
                    name="problemStartDate"
                    value={problemStartDate}
                    onChange={handleInput}
                  />
                </div>
                <div className="form-group col-md-4">
                  <input
                    type="time"
                    className="form-control"
                    placeholder="hh:mm"
                    name="time"
                    value={time}
                    onChange={handleInput}
                  />
                </div>
                <div className="form-group col-md-4">
                  <div className="CommonSelectBox CommonSelectBoxWidth">
                    <select
                      name="timeType"
                      value={timeType}
                      onChange={handleInput}
                    >
                      <option selected="">Select</option>
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </div>
                <div className="form-group col-md-5">
                  <div className="CommonSelectBox CommonSelectBoxWidth mb-3">
                    <select
                      name="timeZone"
                      value={timeZone}
                      onChange={handleInput}
                    >
                      <option>Select Timezone</option>
                      {checkArray(timeZoneData?.data) ? (
                        <>
                          {timeZoneData?.data?.map((ele, i) => {
                            return (
                              <option value={ele?.zoneName}>
                                {ele?.zoneName}
                              </option>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </select>
                  </div>
                  <label className="CheckBox">
                    {" "}
                    Not sure, use current time
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="form-group col-md-12">
                  <h6>Error Message</h6>
                  <textarea
                    className="form-control"
                    placeholder="Insert Error Message if applicable, or more details."
                    rows={3}
                    name="errorMessage"
                    value={errorMessage}
                    onChange={handleInput}
                  />
                </div>
                <div className="form-group col-md-6">
                  <h6>Url link of the issue page</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert link where the error is occuring"
                    name="applicationLink"
                    value={applicationLink}
                    onChange={handleInput}
                  />
                </div>
                <div className="ModalBigheading col-md-12">
                  <h3>
                    <span>Supporting Documents</span>
                  </h3>
                </div>
                <div className="form-group col-md-6">
                  <h6>Select Files</h6>
                  <div className="Upload">
                    <span>-Drag and Drop Files or click to select</span>
                    <input
                      type="file"
                      name="supportingDocFiles"
                      onChange={(e) => handleInputFile(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="row"></div>
              {/* <div className="Button mb-3">
        <button className="Create">Add</button>
      </div> */}
              {/* <div className="form-group">
        <h6>Files:</h6>
        <div className="FilesAdded">
          <label>error1_.jpeg</label>
          <span>
            <img src="images/fileview.png" />
          </span>
          <span className="cross">
            <img src="images/Cross.png" />
          </span>
        </div>
        <div className="FilesAdded">
          <label>error1_.jpeg</label>
          <span>
            <img src="images/fileview.png" />
          </span>
          <span className="cross">
            <img src="images/Cross.png" />
          </span>
        </div>
      </div> */}
              {/* <div className="row">
        <div className="form-group col-md-6">
          <h6>
            Assign To <span className="required">*</span>
          </h6>
          <div
            className="CommonSelectBox CommonSelectBoxWidth"
            style={{ width: "100%!important", margin: 0 }}
          >
            <select >
              <option selected="">Select Ibuild Admin</option>
              <option value={"Ibuild Admin"}> Ibuild Admin 1</option>
            </select>
          </div>
        </div>
      </div> */}
              <div className="Button d-flex justify-content-between">
                <button
                  onClick={addSupportFunc}
                  className="Create ClosePermissionId"
                >
                  Create Support Request
                </button>
                <button className="Outline ClosePermissionId">Cancel</button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={supportListingModal}
        onHide={hideSupportListingModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSupportListingModal}
          >
            ×
          </a>

          <a href="javascript:void(0);" onClick={hideSupportListingModal}>
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>View Support Request</h4>
            </div>
            <div className="Tableheader">
              <div className="d-flex align-items-center">
                <h6>Support Requests</h6>
              </div>
              <div className="d-flex">
                <div className="TodosButtons mt-0 d-flex">
                  <a href="javascript:void(0)" onClick={showSupportModal}>
                    <img src="images/icon-90.png" />
                    Create New Support Request
                  </a>
                </div>
                <div className="NewActions">
                  <a href="javascript:void(0);" title="pdf">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="javascript:void(0);" title="download">
                    <img src="images/download.png" />
                  </a>
                  <a href="javascript:void(0);" title="settings">
                    <img src="images/setting.png" />
                  </a>
                  <a href="javascript:void(0);" title="filter">
                    <img src="images/icon-64.png" />
                  </a>
                </div>
              </div>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Client</th>
                    <th>Company</th>
                    <th>Type</th>
                    <th>Module</th>
                    <th>Description</th>
                    <th>Problem Start Date</th>
                    <th>Severity</th>
                    <th>Error Message</th>
                    <th>Url link of the issue page</th>
                    {/* {/ <th>Supporting Documents</th> /} */}
                    <th>Status</th>
                    <th>Creation Date</th>
                    <th>Last Update</th>
                    <th>Solution</th>
                    <th>Root Cause</th>
                    <th>Bug ID</th>
                    <th>Messages</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <>
                      <td>
                        {" "}
                        <Loader />
                      </td>
                    </>
                  ) : (
                    <>
                      {checkArray(helpListData?.notData) ? (
                        helpListData?.notData?.map((ele, ind) => {
                          return (
                            <>
                              <tr className="PendingConfirmation">
                                <td>{ele?.id}</td>
                                <td>{ele?.clientName}</td>
                                <td>{ele?.companyId}</td>
                                <td>{ele?.issueDetailsType}</td>
                                <td>{ele?.module}</td>
                                <td>{ele?.description}</td>
                                <td>
                                  {ele?.problemStartDate}, {ele?.time}
                                </td>
                                <td>{ele?.severity}</td>
                                <td>{ele?.errorMessage}</td>
                                <td>{ele?.applicationLink}</td>
                                {/* <td>
                  <div className="Actions">
                    <a
                      href="javascript:void(0);"
                      onClick={() => showAddSupportingDocsModal()}
                    >
                      <img src="images/icon-63.png" />
                    </a>
                    <a
                      href="javascript:void(0)"
                      onClick={() => showSupportingListModal()}
                    >
                      <span className="Count">3</span>
                    </a>
                  </div>
                </td> */}
                                <td>{ele?.requestStatus}</td>

                                <td>
                                  <div className="text-black">
                                    {ele?.createdAt?.split("T")[0]}
                                  </div>
                                </td>
                                <td>
                                  <div className="text-black">
                                    {ele?.updatedAt?.split("T")[0]}
                                  </div>
                                </td>
                                <td />
                                <td />
                                <td />
                                <td>
                                  <div className="Actions">
                                    <a href="javascript:void(0);">
                                      <img src="images/chat_icon.png" />
                                    </a>
                                    <a href="javascript:void(0);">
                                      <span className="Count">3</span>
                                    </a>
                                  </div>
                                </td>
                                <td>
                                  <div className="TableThreeDots text-center">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol
                                          className="dropdown-menu dropdown-menuwidth2"
                                          style={{}}
                                        >
                                          <li>
                                            <a
                                              onClick={() =>
                                                showSupportDetailsModal(ele?.id)
                                              }
                                            >
                                              <img src="images/view-po.png" />
                                              View Support Request
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <td>
                          {" "}
                          <NoDataFound />
                        </td>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={supportDetailsModal}
        onHide={hideSupportDetailsModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSupportDetailsModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Support Request Details</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="row">
                  <div className="form-group col-md-3">
                    <h6>ID</h6>
                    <p className="ml-0">{helpDetailsData[0]?.id}</p>
                  </div>
                  <div className="form-group col-md-3">
                    <h6>Creation Date</h6>
                    <p className="ml-0">
                      {helpDetailsData[0]?.createdAt?.split("T")[0]}
                    </p>
                  </div>
                  <div className="form-group col-md-3">
                    <h6>Last Update</h6>
                    <p className="ml-0">
                      {helpDetailsData[0]?.updatedAt?.split("T")[0]}
                    </p>
                  </div>
                  <div className="form-group col-md-3">
                    <h6>Status</h6>
                    <p className="ml-0">{helpDetailsData[0]?.status}</p>
                  </div>
                </div>
                <div className="ModalBigheading mb-4">
                  <h3 className="mb-1">
                    <span>Contact Details</span>
                  </h3>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <h6>Company</h6>
                      <p className="ml-0">{helpDetailsData[0]?.id}</p>
                    </div>
                    <div className="form-group">
                      <h6>Client Name</h6>
                      <p className="ml-0">{helpDetailsData[0]?.clientName}</p>
                    </div>
                    <div className="form-group">
                      <h6>Telephone</h6>
                      <p className="ml-0">{helpDetailsData[0]?.phoneNumber}</p>
                    </div>
                  </div>
                  <div className="col-md-5 ml-auto">
                    <div className="form-group">
                      <h6>Availability</h6>
                      <p className="ml-0">{helpDetailsData[0]?.availability}</p>
                    </div>
                    <div className="form-group">
                      <h6>Contact Preference</h6>
                      <p className="ml-0">
                        {helpDetailsData[0]?.contactPrefrence}
                      </p>
                    </div>
                    <div className="form-group">
                      <h6>Email</h6>
                      <p className="ml-0">{helpDetailsData[0]?.email}</p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <h6>Language Preference </h6>
                    <p className="ml-0">
                      {helpDetailsData[0]?.languagePrefrence}
                    </p>
                  </div>
                </div>
                <div className="ModalBigheading mt-4 mb-4">
                  <h3 className="mb-1">
                    <span>Issue Details</span>
                  </h3>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>Type</h6>
                      <p className="ml-0">
                        {helpDetailsData[0]?.languagePrefrence}
                      </p>
                    </div>
                    <div className="form-group">
                      <h6>Module</h6>
                      <p className="ml-0">{helpDetailsData[0]?.module}</p>
                    </div>
                    <div className="form-group">
                      <h6>Description</h6>
                      <p className="ml-0">{helpDetailsData[0]?.description}</p>
                    </div>
                    <div className="form-group">
                      <h6>Problem Start Date</h6>
                      <div className="d-flex mb-4">
                        <p className="ml-0">
                          {helpDetailsData[0]?.problemStartDate?.split("T")[0]}
                        </p>
                        {/* {/ <p>09:00 AM</p> /} */}
                      </div>
                      {/* {/ <p className="ml-0">{helpDetailsData[0]?.problemStartDate?.split("T")[0]}</p> /} */}
                    </div>
                  </div>
                  <div className="col-md-5 ml-auto">
                    <div className="form-group">
                      <h6>Severity</h6>
                      <p className="ml-0">{helpDetailsData[0]?.severity}</p>
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    <h6>Error Message</h6>
                    <p className="ml-0">{helpDetailsData?.errorMessage}</p>
                  </div>
                  <div className="form-group col-md-12">
                    <h6>Url link of the issue page</h6>
                    <p className="ml-0"> {helpDetailsData?.applicationLink}</p>
                  </div>
                  {/* <div className="col-md-12">
          <div className="ModalBigheading">
            <h3>
              <span>Supporting Documents</span>
            </h3>
          </div>
          <div className="form-group">
            <h6>Files:</h6>
            <div className="FilesAdded">
              <label>error1_.jpeg</label>
              <span>
                <img src="images/view_jobs_details.png" />
              </span>
            </div>
            <div className="FilesAdded">
              <label>error1_.jpeg</label>
              <span>
                <img src="images/view_jobs_details.png" />
              </span>
            </div>
          </div>
          <div className="ModalBigheading">
            <h3>
              <span>Support Details</span>
            </h3>
          </div>
        </div> */}
                </div>
                <div className="row">
                  <div className="form-group col-6">
                    <h6>Assign To </h6>
                    <h6 className="fw-400 fw-14">Sysadmin 1</h6>
                  </div>
                  <div className="form-group col-12">
                    <h6>Solution</h6>
                  </div>
                  <div className="form-group col-md-6">
                    <h6>Root Cause</h6>
                    <p className="ml-0">Bug</p>
                  </div>
                  <div className="form-group col-md-6">
                    <h6>Bug Id</h6>
                    <p className="ml-0">Bug 36</p>
                  </div>
                </div>
                <div className="Button text-center">
                  <button className="Outline">Close</button>
                </div>
              </form>
            </div>
            <div class="DiscussionArea">
              <div class="DiscussionTitle">
                <h4>Discussion</h4>
                <div class="DiscussionChat">
                  <img
                    src={require("../../assets/images/down.png")}
                    class="DiscussionIcon"
                  />
                  <a href="#">
                    <img src={require("../../assets/images/pdf2.png")} />
                  </a>
                </div>
              </div>
              <div class="DiscussionHideArea" style={{ display: "block" }}>
                <div class="DiscussionBox">
                  <span>FM</span>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      value={discussionState?.message}
                      onChange={(e) =>
                        updateDiscussionState({
                          ...discussionState,
                          message: e.target.value,
                        })
                      }
                      placeholder="Add a Comment ....."
                    />
                    {discussionState.discussionError &&
                      !discussionState.message && (
                        <p style={{ color: "red" }}>
                          {discussionState.discussionError}
                        </p>
                      )}

                    <div class="DiscussionOverlay">
                      <a
                        onClick={() =>
                          updateDiscussionState({
                            ...discussionState,
                            message: "",
                          })
                        }
                        class="CancelChat"
                      >
                        <img
                          src={require("../../assets/images/plus-cancel1.png")}
                        />
                      </a>
                      <a onClick={handleAddDiscussion} class="SendChat">
                        <img
                          src={require("../../assets/images/forwardbutton.png")}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                {discussionList?.map((item, i) => (
                  <>
                    <div class="DiscussionBox">
                      <span>CO</span>
                      <div class="DiscussionRight">
                        <h6>{item?.message}</h6>
                        <small>{moment(item.updatedAt).format("lll")}</small>
                      </div>
                    </div>
                    <div class="ReplyArea">
                      <a
                        onClick={(e) => {
                          updateReplyField({ flag: true, index: i });
                          updateReplyState({ ...replyState, message: "" });
                        }}
                      >
                        Reply
                      </a>
                      {item?.ReplyDiscussions?.map((ele) => (
                        <div class="DiscussionBox">
                          <span>RE</span>
                          <div class="DiscussionRight">
                            <h6>{ele?.message}</h6>
                            <small>{moment(ele.updatedAt).format("lll")}</small>
                          </div>
                        </div>
                      ))}

                      {replyField.index == i && (
                        <div class="DiscussionBox w-100">
                          <span>FM{item?.id}</span>
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              value={replyState?.message}
                              onChange={(e) =>
                                updateReplyState({
                                  ...replyState,
                                  message: e.target.value,
                                  messageId: item?.id,
                                  jobId: item?.jobId,
                                })
                              }
                              placeholder="Add reply ....."
                            />
                            {discussionState.discussionError &&
                              !discussionState.message && (
                                <p style={{ color: "red" }}>
                                  {discussionState.discussionError}
                                </p>
                              )}

                            <div class="DiscussionOverlay">
                              <a
                                onClick={() =>
                                  updateReplyState({
                                    ...replyState,
                                    message: "",
                                  })
                                }
                                class="CancelChat"
                              >
                                <img
                                  src={require("../../assets/images/plus-cancel1.png")}
                                />
                              </a>
                              <a onClick={handleAddReply} class="SendChat">
                                <img
                                  src={require("../../assets/images/plus-cancel1.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ContactList
        ContactListModal={ContactListModal}
        setContactList={setContactList}
        SetAddContact={SetAddContact}
        SetContactDetails={SetContactDetails}
      />

      {AddContactModal && (
        <AddContact
          AddContactModal={AddContactModal}
          SetAddContact={SetAddContact}
        />
      )}
    </>
  );
}

export default IbuildUserManagement;
