import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import "../App.css";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import Loader from "./Loader";
import moment from "moment";
import {
  archiveDeleteJob,
  getArchiveEventList,
  getArchiveList,
  getArchiveRestoreJob,
} from "../redux/action/archiveAction";
import {
  deleteArchiveJobs,
  getAllListProjectJobs,
  getAllProjects,
} from "../redux/action/modelsAction";

const initialState = {
  JobDeleteModal: false,
  resdentalId: -1,
  jobId:"",
};

const ArchiveResidential = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const { JobDeleteModal, resdentalId ,jobId} = iState;
  const { archiveList, archiveLoader, archiveEventList, archiveEventLoader } =
    useSelector((state) => state.archiveReducer);

   
  const { projectsList ,listProjectJobs} = useSelector(
    (state) => state.modelsReducer
  );

  useEffect(() => {
    dispatch(getAllProjects());
    dispatch(getArchiveEventList("Residential"));
  }, []);

  const handleJobListByProject = (Id, Index) => {
    dispatch(getAllListProjectJobs(Id));
    updateState((prev) => ({
      ...prev,
      resdentalId: prev.resdentalId == Index ? -1 : Index,
    }));
  };

  const handleRestoreJob = (id) => {
    const dataa = { jobId: id, jobType: "ResidentialJob" };
    dispatch(getArchiveRestoreJob(dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(getArchiveList("Residential"));
        toast.success("Restore Job Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          id: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleJobDeleteShow = (id) => {
    updateState({ ...iState, JobDeleteModal: true ,jobId:id});
  };

  const handleJobDeleteClose = () => {
    updateState({ ...iState, JobDeleteModal: false,jobId:"" });
  };

  const handleJobDelete = async () => {
    try {
      const data = { jobId, jobType: "ResidentialJob" };
      const response = await dispatch(archiveDeleteJob(data));
  
      if (response.status === 200) {
        await dispatch(getArchiveList("Residential"));
        toast.success("Delete Job Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState((prevState) => ({
          ...prevState,
          jobId: "",
          JobDeleteModal:false,
        }));
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState((prevState) => ({
          ...prevState,
          jobId: "",
          JobDeleteModal:true,
        }));
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      updateState((prevState) => ({
        ...prevState,
        jobId: "",
        JobDeleteModal:true,
      }));
    }
  };
  

  const handleArchiveJobs = (id, type) => {
    let dataa = { jobId: id, jobType: type };
    dispatch(deleteArchiveJobs(dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(getAllProjects());
        toast.success("Deleted Archive Job", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  return (
    <div>
      <Header />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Archive Residential</h4>
            <a href="javascript:void(0);">
              <img src="images/Icon-4.png" />
            </a>
          </div>
          <div className="mb-5" style={{ width: "60%" }}>
            <div className="Tableheader">
              <h6>
                Latest Activity <img src="images/create_category.png" />
              </h6>
              <div className="NewActions">
                <a href="jacascript:void(0);">
                  <img src="images/icon-53.png" />
                </a>
                <a href="jacascript:void(0);">
                  <img src="images/download.png" />
                </a>
                {/* <a href="jacascript:void(0);">
                  <img src="images/setting.png" />
                </a> */}
              </div>
            </div>
            <div className="TableList TableListHeader NewTableList TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Job Id</th>
                    <th>Modified by</th>
                    <th>Date</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {!archiveEventLoader ? (
                    archiveEventList?.details?.length > 0 ? (
                      archiveEventList?.details?.map((item, i) => (
                        <tr key={i}>
                          <td>{item.id}</td>
                          <td>{item.modifiedBy || "N/A"}</td>
                          <td>{moment(item.createdAt).format("YYYY-MM-DD")}</td>
                          <td>{item.activity}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4">No data found</td>
                      </tr>
                    )
                  ) : (
                    <Loader />
                  )}
                </tbody>
              </table>
            </div>
            <div className="PaginationArea">
              <h6>Showing 1 to 10 of 27 entries</h6>
              <h6>
                <img src="images/leftarrow.png" /> 01{" "}
                <img src="images/rightarrow.png" /> Out 10
              </h6>
            </div>
          </div>
          <div className="Tableheader">
            <h6>Archived Jobs</h6>
            <div className="NewActions">
              <a href="jacascript:void(0);">
                <img src="images/icon-64.png" />
              </a>
              <a href="jacascript:void(0);">
                <img src="images/icon-53.png" />
              </a>
              <a href="jacascript:void(0);">
                <img src="images/download.png" />
              </a>
              {/* <a href="jacascript:void(0);">
                <img src="images/setting.png" />
              </a> */}
            </div>
          </div>
          <div className="CommonTableArea ChangeOrderTable d-block">
            <div className="JobTableCommon">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Job Number</th>
                    <th>Build Type</th>
                    <th>Address</th>
                    <th>Lot</th>
                    <th>Block</th>
                    <th>Phase</th>
                    <th>Plan</th>
                    <th>Sub Division</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="addClasss">
                  {projectsList?.projects?.length > 0
                    ? projectsList?.projects?.map((item, i) => {
                        return (
                          <>
                            <tr key={i}>
                              <td>
                                <div className="d-flex">
                                  {item.id}
                                  <div className="dropdown">
                                    <span
                                      className="Dots"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </span>
                                    <div className="dropdown-menu">
                                      <a
                                        className="dropdown-item"
                                        onClick={() =>
                                          handleArchiveJobs(
                                            item.id,
                                            item.jobType
                                          )
                                        }
                                      >
                                        {" "}
                                        <img src="images/archive_job.png" />{" "}
                                        Archive Job
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <a
                                  className="hidtableshowChangeOrder"
                                  onClick={() => handleJobListByProject(item.id, i)}
                                >
                                  {item.jobNumber}&nbsp;
                                  <img src="images/down-arrow.png" />
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  {item.projectType}
                                </a>
                              </td>
                              <td>
                                <a href="javascript:void(0);">
                                  {item.projectAddress}
                                </a>
                              </td>
                              <td />
                              <td />
                              <td />
                              <td />
                              <td>
                                <a href="javascript:void(0);">
                                  {item.subdivisions?.map((x) => x)}
                                </a>
                              </td>
                              <td>
                                <div className="TableThreeDots">
                                  <ul className="">
                                    <li className="dropdown">
                                      <a
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <img src="images/dots.png" />
                                      </a>
                                      <ol className="dropdown-menu dropdown-menuwidth">
                                        <li>
                                          <a
                                            onClick={() =>
                                              handleRestoreJob(item.id)
                                            }
                                          >
                                            <img src="images/restore_job.png" />
                                            Restore Job
                                          </a>
                                        </li>
                                        <li>
                                          <a href="lead_contract_managment.html">
                                            <img src="images/view_jobs_details.png" />
                                            View Job Details
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            onClick={() =>
                                              handleJobDeleteShow(item.id)
                                            }
                                          >
                                            <img src="images/dropdown-delete.png" />
                                            Delete Job
                                          </a>
                                        </li>
                                      </ol>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={10} className="padding_none">
                                <div
                                  className="JobBoxTable"
                                  style={{
                                    display:
                                      resdentalId == i ? "block" : "none",
                                  }}
                                >
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Unit Job Number</th>
                                        <th>Unit#</th>
                                        <th>Primary Client</th>
                                        <th>Square Footage</th>
                                        <th>Warranty Requests</th>
                                        <th>Description</th>
                                        <th />
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {resdentalId == i &&
                                        listProjectJobs?.jobs?.length > 0 &&
                                        listProjectJobs?.jobs?.map(
                                          (data, index) => {
                                            return (
                                              <>
                                                <tr key={index}>
                                                  <td>
                                                    <div className="d-flex">
                                                      {data.id}
                                                      <div className="dropdown">
                                                        <span
                                                          className="Dots"
                                                          data-toggle="dropdown"
                                                          aria-haspopup="true"
                                                          aria-expanded="false"
                                                        >
                                                          <img src="images/dots.png" />
                                                        </span>
                                                        <div
                                                          className="dropdown-menu"
                                                          aria-labelledby="dropdownMenuButton"
                                                        >
                                                          <a
                                                            className="dropdown-item"
                                                            onClick={() =>
                                                              handleArchiveJobs(
                                                                data.id,
                                                                data.jobType
                                                              )
                                                            }
                                                          >
                                                            {" "}
                                                            <img src="images/archive_job.png" />{" "}
                                                            Archive Job
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <a href="javascript:void(0);">
                                                      {data.jobNumber??"N/A"}
                                                    </a>
                                                  </td>
                                                  <td>
                                                    <a href="javascript:void(0);">
                                                      {data.unit ?? "N/A"}
                                                    </a>
                                                  </td>
                                                  <td>
                                                    <a href="javascript:void(0);">
                                                      {data.client ?? "N/A"}
                                                    </a>
                                                  </td>
                                                  <td>
                                                    <a href="javascript:void(0);">
                                                      {data.footage ?? "N/A"}
                                                    </a>
                                                  </td>
                                                  <td>
                                                    <a href="javascript:void(0);">
                                                      {data?.warranties ??
                                                        "N/A"}
                                                    </a>
                                                  </td>
                                                  <td>
                                                    <a href="javascript:void(0);">
                                                      {data?.description ??
                                                        "N/A"}
                                                    </a>
                                                  </td>
                                                  <td>
                                                    <div className="TableThreeDots">
                                                      <ul className="">
                                                        <li className="dropdown">
                                                          <a
                                                            role="button"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                          >
                                                            <img src="images/dots.png" />
                                                          </a>
                                                          <ol className="dropdown-menu dropdown-menuwidth">
                                                            <li>
                                                              <a
                                                                onClick={() =>
                                                                  handleRestoreJob(
                                                                    data.id
                                                                  )
                                                                }
                                                              >
                                                                <img src="images/restore_job.png" />
                                                                Restore Job
                                                              </a>
                                                            </li>
                                                            <li>
                                                              <a href="lead_contract_managment.html">
                                                                <img src="images/view_jobs_details.png" />
                                                                View Job Details
                                                              </a>
                                                            </li>
                                                            <li>
                                                              <a
                                                                onClick={() =>
                                                                  handleJobDeleteShow(
                                                                    data.id
                                                                  )
                                                                }
                                                              >
                                                                <img src="images/dropdown-delete.png" />
                                                                Delete Job
                                                              </a>
                                                            </li>
                                                          </ol>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          </div>

          <div className="PaginationArea">
            <h6>Showing 1 to 10 of 27 entries</h6>
            <h6>
              <img src="images/leftarrow.png" /> 01{" "}
              <img src="images/rightarrow.png" /> Out 10
            </h6>
          </div>
        </div>
      </div>
      <Modal
        show={JobDeleteModal}
        onHide={handleJobDeleteClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleJobDeleteClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Delete</h4>
                </div>
                <div className="FolderForm">
                  <div className="my-5">
                    <h6>Are you sure you want to delete this ?</h6>
                  </div>
                  <div className="Button d-flex justify-content-between">
                    <button className="Create" onClick={handleJobDelete}>
                      Delete
                    </button>
                    <button className="Outline" onClick={handleJobDeleteClose}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ArchiveResidential;
