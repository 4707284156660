import axios from "axios";
import { priceBookActionTypes } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config/Config";  
import { multiPartData } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));





export const getAllCategoryListInitate = () => ({
    type: priceBookActionTypes.CATEGORY_LIST_INITIATE,
  });
  
  export const getAllCategoryListSuccess = (data) => ({
    type: priceBookActionTypes.CATEGORY_LIST_SUCCESS,
    payload: data,
  });
  
  export const getAllCategoryListFailure = (data) => ({
    type: priceBookActionTypes.CATEGORY_LIST_FAILURE,
    payload: data,
  });
  
  export function getAllCategoryListAction( category, name) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(getAllCategoryListInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/search-category?name=${name ? name:""}&categoryType=${category}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            ////console.log("data", data);
            if (data && data.status == 200) {
              dispatch(getAllCategoryListSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(getAllCategoryListFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(getAllCategoryListSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(getAllCategoryListFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }
  
  export const addCategoryInitate = () => ({
    type: priceBookActionTypes.ADD_CATEGORY_INITIATE,
  });
  
  export const addCategorySuccess = (data) => ({
    type: priceBookActionTypes.ADD_CATEGORY_SUCCESS,
    payload: data,
  });
  
  export const addCategoryFailure = (data) => ({
    type: priceBookActionTypes.ADD_CATEGORY_FAILURE,
    payload: data,
  });
  
  export function addCategoryAction(payload) {
    const token = isLoggedIn("adminData1");
    const updatedData = multiPartData({ image: payload.image, ...payload })
    return (dispatch) => {
      dispatch(addCategoryInitate());
      return new Promise((resolve, reject) => {
        axios
        // companies/2/Category
          .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/categories`, updatedData, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            ////console.log("data", response);
            if (data && data.status == 201) {
              dispatch(addCategorySuccess(data.data));
              resolve(data);
            } else {
              dispatch(addCategoryFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            dispatch(addCategoryFailure(err));
            reject(err);
          });
      });
    };
  }

  
export const removeCategoryInitate = () => ({
  type: priceBookActionTypes.DELETE_CATEGORY_INITIATE,
});

export const removeCategorySuccess = (data) => ({
  type: priceBookActionTypes.DELETE_CATEGORY_SUCCESS,
  payload: data,
});

export const removeCategoryFailure = (data) => ({
  type: priceBookActionTypes.DELETE_CATEGORY_FAILURE,
  payload: data,
});

export function removeCategoryAction( id, type) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(removeCategoryInitate());
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/categories/${
            id ? id : ""
          }?categoryType=${type}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 200) || 201) {
            dispatch(removeCategorySuccess(data));
            resolve(data);
          } else {
            dispatch(removeCategoryFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(removeCategoryFailure(err));
          reject(err);
        });
    });
  };
}



export const updateCategoryInitate = () => ({
  type: priceBookActionTypes.UPDATE_CATEGORY_INITIATE,
});

export const updateCategorySuccess = (data) => ({
  type: priceBookActionTypes.UPDATE_CATEGORY_SUCCESS,
  payload: data,
});

export const updateCategoryFailure = (data) => ({
  type: priceBookActionTypes.UPDATE_CATEGORY_SUCCESS,
  payload: data,
});

export function updateCategoryAction(payload, params) {
  const token = isLoggedIn("adminData1");
  const updatedData = multiPartData({ image: payload.image, ...payload })
  return (dispatch) => {
    dispatch(updateCategoryInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/categories/${params}`,
          updatedData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(updateCategorySuccess(data));
            resolve(data);
          } else {
            dispatch(updateCategoryFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateCategoryFailure(err));
          reject(err);
        });
    });
  };
}



export const getCategoryDetailsInitate = () => ({
  type: priceBookActionTypes.CATEGORY_DETAILS_INITIATE,
});

export const getCategoryDetailsSuccess = (data) => ({
  type: priceBookActionTypes.CATEGORY_DETAILS_SUCCESS,
  payload: data,
});

export const getCategoryDetailsFailure = (data) => ({
  type: priceBookActionTypes.CATEGORY_DETAILS_FAILURE,
  payload: data,
});

export function getCategoryDetailsAction( id, type ) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(getCategoryDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/categories/${id}?categoryType=${type}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200 || 201) {
            dispatch(getCategoryDetailsSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getCategoryDetailsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("==errerr=====>", err)
          if(err.response.status === 404){
            dispatch(getCategoryDetailsSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getCategoryDetailsFailure(err));
            reject(err);
          }
        });
    });
  };
}



export const categoryHistoryInitate = () => ({
  type: priceBookActionTypes.CATEGORY_HISTORY_INITIATE,
});

export const categoryHistorySuccess = (data) => ({
  type: priceBookActionTypes.CATEGORY_HISTORY_SUCCESS,
  payload: data,
});

export const categoryHistoryFailure = (data) => ({
  type: priceBookActionTypes.CATEGORY_HISTORY_FAILURE,
  payload: data,
});

export function categoryHistoryAction(  id ) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(categoryHistoryInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/categories/${id}/history`, {
          headers: { Authorization: `Bearer ${token}`},
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200 || 201) {
            dispatch(categoryHistorySuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(categoryHistoryFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("==errerr=====>", err)
          if(err.response.status === 404){
            dispatch(categoryHistorySuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(categoryHistoryFailure(err));
            reject(err);
          }
        });
    });
  };
}




export const getAllEntryListInitate = () => ({
  type: priceBookActionTypes.ENTRIES_LIST_INITIATE,
});

export const getAllEntryListSuccess = (data) => ({
  type: priceBookActionTypes.ENTRIES_LIST_SUCCESS,
  payload: data,
});

export const getAllEntryListFailure = (data) => ({
  type: priceBookActionTypes.ENTRIES_LIST_FAILURE,
  payload: data,
});

export function getAllEntryListAction(sid, name, ) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllEntryListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/entries?assembly=${sid ? sid :""}&q=${name ? name:""}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          console.log("data", data);
          if (data && data.status == 200 || 201)   {
            dispatch(getAllEntryListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllEntryListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getAllEntryListSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getAllEntryListFailure(err));
            reject(err)
          }
        
        });
    });
  };
}

export const addEntriesInitate = () => ({
  type: priceBookActionTypes.ADD_ENTRIES_INITIATE,
});

export const addEntriesSuccess = (data) => ({
  type: priceBookActionTypes.ADD_ENTRIES_SUCCESS,
  payload: data,
});

export const addEntriesFailure = (data) => ({
  type: priceBookActionTypes.ADD_ENTRIES_FAILURE,
  payload: data,
});

export function addEntriesAction(payload) {
  // debugger;
  const token = isLoggedIn("adminData1");

  let obj = payload
  if(payload.images){
    obj['images'] = payload.images
  }
  else{
    obj = payload
  }
  const updatedData = multiPartData(obj)
  // const updatedData = multiPartData( {images:payload?.images})
 
  return (dispatch) => {
    dispatch(addEntriesInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/entries`,updatedData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201) {
            dispatch(addEntriesSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addEntriesFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addEntriesFailure(err));
          reject(err);
        });
    });
  };
}


export const removeEntriesInitate = () => ({
type: priceBookActionTypes.DELETE_ENTRIES_INITIATE,
});

export const removeEntriesSuccess = (data) => ({
type: priceBookActionTypes.DELETE_ENTRIES_SUCCESS,
payload: data,
});

export const removeEntriesFailure = (data) => ({
type: priceBookActionTypes.DELETE_ENTRIES_FAILURE,
payload: data,
});

export function removeEntriesAction( id, type) {
const token = isLoggedIn("adminData1");
return (dispatch) => {
  dispatch(removeEntriesInitate());
  return new Promise((resolve, reject) => {
    axios
      .delete(
        `${Url}companies/${iBuildLocalData?.user?.CompanyId}/entries/${
          id ? id : ""
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        const data = response.data;
        if ((data && data.status == 200) || 201) {
          dispatch(removeEntriesSuccess(data));
          resolve(data);
        } else {
          dispatch(removeEntriesFailure(data));
          reject(data);
        }
      })
      .catch((err) => {
        dispatch(removeEntriesFailure(err));
        reject(err);
      });
  });
};
}



export const updateEntriesInitate = () => ({
type: priceBookActionTypes.UPDATE_ENTRIES_INITIATE,
});

export const updateEntriesSuccess = (data) => ({
type: priceBookActionTypes.UPDATE_ENTRIES_SUCCESS,
payload: data,
});

export const updateEntriesFailure = (data) => ({
type: priceBookActionTypes.UPDATE_ENTRIES_SUCCESS,
payload: data,
});

export function updateEntriesAction(payload, params) {
const token = isLoggedIn("adminData1");
console.log("paylaoddddddd", payload)
// if()
const updatedData = multiPartData({  ...payload })
return (dispatch) => {
  dispatch(updateEntriesInitate());
  return new Promise((resolve, reject) => {
    // ${payload?payload:""}
    axios
      .put(
        `${Url}companies/${iBuildLocalData?.user?.CompanyId}/entries/${params}`,
        updatedData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        const data = response.data;
        if ((data && data.status == 201) || 200) {
          dispatch(updateEntriesSuccess(data));
          resolve(data);
        } else {
          dispatch(updateEntriesFailure(data));
          reject(data);
        }
      })
      .catch((err) => {
        dispatch(updateEntriesFailure(err));
        reject(err);
      });
  });
};
}



export const getEntriesDetailsInitate = () => ({
type: priceBookActionTypes.ENTRIES_DETAILS_INITIATE,
});

export const getEntriesDetailsSuccess = (data) => ({
type: priceBookActionTypes.ENTRIES_DETAILS_SUCCESS,
payload: data,
});

export const getEntriesDetailsFailure = (data) => ({
type: priceBookActionTypes.ENTRIES_DETAILS_FAILURE,
payload: data,
});

export function getEntriesDetailsAction( id, type ) {
const token = isLoggedIn("adminData1");
return (dispatch) => {
  dispatch(getEntriesDetailsInitate());
  return new Promise((resolve, reject) => {
    axios
      .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/entries/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const data = response;
        ////console.log("data", data);
        if (data && data.status == 200 || 201) {
          dispatch(getEntriesDetailsSuccess(data.data));
          resolve(data.data);
        } else {
          dispatch(getEntriesDetailsFailure(data.data));
          reject(data);
        }
      })
      .catch((err) => {
        console.log("==errerr=====>", err)
        if(err.response.status === 404){
          dispatch(getEntriesDetailsSuccess(err.data));
          resolve(err.data)
        }else {
          dispatch(getEntriesDetailsFailure(err));
          reject(err);
        }
      });
  });
};
}




export const entryHistoryInitate = () => ({
  type: priceBookActionTypes.ENTRY_HISTORY_INITIATE,
});

export const entryHistorySuccess = (data) => ({
  type: priceBookActionTypes.ENTRY_HISTORY_SUCCESS,
  payload: data,
});

export const entryHistoryFailure = (data) => ({
  type: priceBookActionTypes.ENTRY_HISTORY_FAILURE,
  payload: data,
});

export function entryHistoryAction(  id ) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(entryHistoryInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/entries/${id}/history`, {
          headers: { Authorization: `Bearer ${token}`},
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200 || 201) {
            dispatch(entryHistorySuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(entryHistoryFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("==errerr=====>", err)
          if(err.response.status === 404){
            dispatch(entryHistorySuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(entryHistoryFailure(err));
            reject(err);
          }
        });
    });
  };
}




export const updateEntryMarkupInitate = () => ({
  type: priceBookActionTypes.UPDATE_ENTRIES_MARKUP_INITIATE,
  });
  
  export const updateEntryMarkupSuccess = (data) => ({
  type: priceBookActionTypes.UPDATE_ENTRIES_MARKUP_SUCCESS,
  payload: data,
  });
  
  export const updateEntryMarkupFailure = (data) => ({
  type: priceBookActionTypes.UPDATE_ENTRIES_MARKUP_SUCCESS,
  payload: data,
  });
  
  export function updateEntryMarkupAction(payload, params) {
  const token = isLoggedIn("adminData1");
  console.log("paylaoddddddd", payload)
  // if()
  const updatedData = multiPartData({ images: payload.images, ...payload })
  return (dispatch) => {
    dispatch(updateEntryMarkupInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/entries/${params}/markup`,
          payload?.images ? updatedData : payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(updateEntryMarkupSuccess(data));
            resolve(data);
          } else {
            dispatch(updateEntryMarkupFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateEntryMarkupFailure(err));
          reject(err);
        });
    });
  };
  }
  






  // ASSEMBLIES


  

  
export const addAssembliesInitate = () => ({
  type: priceBookActionTypes.ADD_ASSEMBLY_INITIATE,
});

export const addAssembliesSuccess = (data) => ({
  type: priceBookActionTypes.ADD_ASSEMBLY_SUCCESS,
  payload: data,
});

export const addAssembliesFailure = (data) => ({
  type: priceBookActionTypes.ADD_ASSEMBLY_FAILURE,
  payload: data,
});

export function addAssembliesAction(id,payload) {
  // debugger;
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ images: payload.images, ...payload })
  // const updatedData = multiPartData( {images:payload?.images})
  return (dispatch) => {
    dispatch(addAssembliesInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}categories/${id}/assemblies?id=${iBuildLocalData?.user?.CompanyId}&type=company`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201) {
            dispatch(addAssembliesSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addAssembliesFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addAssembliesFailure(err));
          reject(err);
        });
    });
  };
}


export const removeASSEMBLYInitate = () => ({
type: priceBookActionTypes.DELETE_ASSEMBLY_INITIATE,
});

export const removeASSEMBLYSuccess = (data) => ({
type: priceBookActionTypes.DELETE_ASSEMBLY_SUCCESS,
payload: data,
});

export const removeASSEMBLYFailure = (data) => ({
type: priceBookActionTypes.DELETE_ASSEMBLY_FAILURE,
payload: data,
});

export function removeASSEMBLYAction( id, secId) {
const token = isLoggedIn("adminData1");
return (dispatch) => {
  dispatch(removeASSEMBLYInitate());
  return new Promise((resolve, reject) => {
    axios
      .delete(
        `${Url}categories/${id}/assemblies/${secId}?id=${iBuildLocalData?.user?.CompanyId}&type=company`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        const data = response.data;
        if ((data && data.status == 200) || 201) {
          dispatch(removeASSEMBLYSuccess(data));
          resolve(data);
        } else {
          dispatch(removeASSEMBLYFailure(data));
          reject(data);
        }
      })
      .catch((err) => {
        dispatch(removeASSEMBLYFailure(err));
        reject(err);
      });
  });
};
}



export const updateASSEMBLYInitate = () => ({
type: priceBookActionTypes.UPDATE_ASSEMBLY_INITIATE,
});

export const updateASSEMBLYSuccess = (data) => ({
type: priceBookActionTypes.UPDATE_ASSEMBLY_SUCCESS,
payload: data,
});

export const updateASSEMBLYFailure = (data) => ({
type: priceBookActionTypes.UPDATE_ASSEMBLY_SUCCESS,
payload: data,
});

export function updateASSEMBLYAction(payload, cid,id) {
const token = isLoggedIn("adminData1");
console.log("paylaoddddddd", payload)
// if()
const updatedData = multiPartData({ images: payload.images, ...payload })
return (dispatch) => {
  dispatch(updateASSEMBLYInitate());
  return new Promise((resolve, reject) => {
    // ${payload?payload:""}
    axios
      .put(
        `${Url}categories/${cid}/assemblies/${id}?id=${iBuildLocalData?.user?.CompanyId}&type=company`,
        payload?.images ? updatedData : payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        const data = response.data;
        if ((data && data.status == 201) || 200) {
          dispatch(updateASSEMBLYSuccess(data));
          resolve(data);
        } else {
          dispatch(updateASSEMBLYFailure(data));
          reject(data);
        }
      })
      .catch((err) => {
        dispatch(updateASSEMBLYFailure(err));
        reject(err);
      });
  });
};
}



export const getAssemblyDetailsInitate = () => ({
type: priceBookActionTypes.ASSEMBLY_DETAILS_INITIATE,
});

export const getAssemblyDetailsSuccess = (data) => ({
type: priceBookActionTypes.ASSEMBLY_DETAILS_SUCCESS,
payload: data,
});

export const getAssemblyDetailsFailure = (data) => ({
type: priceBookActionTypes.ASSEMBLY_DETAILS_FAILURE,
payload: data,
});

export function getAssemblyDetailsAction( id, paramID, type ) {
const token = isLoggedIn("adminData1");
return (dispatch) => {
  dispatch(getAssemblyDetailsInitate());
  return new Promise((resolve, reject) => {
    axios
      .get(`${Url}categories/${id}/assemblies/${paramID}?id=${iBuildLocalData?.user?.CompanyId}&type=company`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const data = response;
        ////console.log("data", data);
        if (data && data.status == 200 || 201) {
          dispatch(getAssemblyDetailsSuccess(data.data));
          resolve(data.data);
        } else {
          dispatch(getAssemblyDetailsFailure(data.data));
          reject(data);
        }
      })
      .catch((err) => {
        console.log("==errerr=====>", err)
        if(err.response.status === 404){
          dispatch(getAssemblyDetailsSuccess(err.data));
          resolve(err.data)
        }else {
          dispatch(getAssemblyDetailsFailure(err));
          reject(err);
        }
      });
  });
};
}




export const assemblyHistoryInitate = () => ({
  type: priceBookActionTypes.ASSEMBLY_HISTORY_INITIATE,
});

export const assemblyHistorySuccess = (data) => ({
  type: priceBookActionTypes.ASSEMBLY_HISTORY_SUCCESS,
  payload: data,
});

export const assemblyHistoryFailure = (data) => ({
  type: priceBookActionTypes.ASSEMBLY_HISTORY_FAILURE,
  payload: data,
});

export function assemblyHistoryAction(  id , secID) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(assemblyHistoryInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}categories/${id}/assemblies/${secID}/history?id=${iBuildLocalData?.user?.CompanyId}&type=company`, {
          headers: { Authorization: `Bearer ${token}`},
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200 || 201) {
            dispatch(assemblyHistorySuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(assemblyHistoryFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("==errerr=====>", err)
          if(err.response.status === 404){
            dispatch(assemblyHistorySuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(assemblyHistoryFailure(err));
            reject(err);
          }
        });
    });
  };
}



export const getAllAssemblyListInitate = () => ({
  type: priceBookActionTypes.ASSEMBLY_LIST_INITIATE,
});

export const getAllAssemblyListSuccess = (data) => ({
  type: priceBookActionTypes.ASSEMBLY_LIST_SUCCESS,
  payload: data,
});

export const getAllAssemblyListFailure = (data) => ({
  type: priceBookActionTypes.ASSEMBLY_LIST_FAILURE,
  payload: data,
});

export function getAllAssemblyListAction( id, name, tags) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllAssemblyListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}categories/${id ? id :""}/assemblies?name=${name?name:""}&id=${iBuildLocalData?.user?.CompanyId}&type=company&tag=${tags ? tags :""}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          console.log("search-assembly?name", data);
          if (data && data.status == 200 ||  data.status ==  201) {
            dispatch(getAllAssemblyListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllAssemblyListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getAllAssemblyListSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getAllAssemblyListFailure(err));
            reject(err)
          }
        
        });
    });
  };
}




export const updateAssemblyMarkupInitate = () => ({
  type: priceBookActionTypes.UPDATE_ASSEMBLY_MARKUP_INITIATE,
  });
  
  export const updateAssemblyMarkupSuccess = (data) => ({
  type: priceBookActionTypes.UPDATE_ASSEMBLY_MARKUP_SUCCESS,
  payload: data,
  });
  
  export const updateAssemblyMarkupFailure = (data) => ({
  type: priceBookActionTypes.UPDATE_ASSEMBLY_MARKUP_SUCCESS,
  payload: data,
  });
  
  export function updateAssemblyMarkupAction(params, secId,payload) {
  const token = isLoggedIn("adminData1");
  // if()
  // const updatedData = multiPartData({ images: payload.images, ...payload })
  return (dispatch) => {
    dispatch(updateAssemblyMarkupInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}categories/${params}/assemblies/${secId}/markup?id=${iBuildLocalData?.user?.CompanyId}&type=company`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(updateAssemblyMarkupSuccess(data));
            resolve(data);
          } else {
            dispatch(updateAssemblyMarkupFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateAssemblyMarkupFailure(err));
          reject(err);
        });
    });
  };
  }
  










export const getAllMeasureListInitate = () => ({
  type: priceBookActionTypes.MEASURE_LIST_INITIATE,
});

export const getAllMeasureListSuccess = (data) => ({
  type: priceBookActionTypes.MEASURE_LIST_SUCCESS,
  payload: data,
});

export const getAllMeasureListFailure = (data) => ({
  type: priceBookActionTypes.MEASURE_LIST_FAILURE,
  payload: data,
});

export function getAllMeasureListAction( name) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllMeasureListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/measures?name=${name ? name :""}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          console.log("data", data);
          if (data && data.status == 200 || data.status == 201) {
            dispatch(getAllMeasureListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllMeasureListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getAllMeasureListSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getAllMeasureListFailure(err));
            reject(err)
          }
        
        });
    });
  };
}



  
export const addMeasureInitate = () => ({
  type: priceBookActionTypes.ADD_MEASURE_INITIATE,
});

export const addMeasureSuccess = (data) => ({
  type: priceBookActionTypes.ADD_MEASURE_SUCCESS,
  payload: data,
});

export const addMeasureFailure = (data) => ({
  type: priceBookActionTypes.ADD_MEASURE_FAILURE,
  payload: data,
});

export function addMeasureAction(payload) {
  // debugger;
  const token = isLoggedIn("adminData1");
  const updatedData = multiPartData({ images: payload.images, ...payload })
  // const updatedData = multiPartData( {images:payload?.images})
  return (dispatch) => {
    dispatch(addMeasureInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/measures`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201) {
            dispatch(addMeasureSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addMeasureFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addMeasureFailure(err));
          reject(err);
        });
    });
  };
}




 
export const addDownloadPriceBookInitate = () => ({
  type: priceBookActionTypes.DOWNLOAD_PRICEBOOK_INITIATE,
});

export const addDownloadPriceBookSuccess = (data) => ({
  type: priceBookActionTypes.DOWNLOAD_PRICEBOOK_SUCCESS,
  payload: data,
});

export const addDownloadPriceBookFailure = (data) => ({
  type: priceBookActionTypes.DOWNLOAD_PRICEBOOK_FAILURE,
  payload: data,
});

export function addDownloadPriceBookAction() {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(addDownloadPriceBookInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/download-spb`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
         console.log("data", response);
          if (data && data.status == 200 ) {
            dispatch(addDownloadPriceBookSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addDownloadPriceBookFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addDownloadPriceBookFailure(err));
          reject(err);
        });
    });
  };
}








export const addDownloadClientBookInitate = () => ({
  type: priceBookActionTypes.DOWNLOAD_CLIENTBOOK_INITIATE,
});

export const addDownloadClientBookSuccess = (data) => ({
  type: priceBookActionTypes.DOWNLOAD_CLIENTBOOK_SUCCESS,
  payload: data,
});

export const addDownloadClientBookFailure = (data) => ({
  type: priceBookActionTypes.DOWNLOAD_CLIENTBOOK_FAILURE,
  payload: data,
});

export function addDownloadClientBookAction(payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(addDownloadClientBookInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/download-cpb`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 200) {
            dispatch(addDownloadClientBookSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addDownloadClientBookFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addDownloadClientBookFailure(err));
          reject(err);
        });
    });
  };
}



export const addUpdloadPriceBookInitate = () => ({
  type: priceBookActionTypes.DOWNLOAD_CLIENTBOOK_INITIATE,
});

export const addUpdloadPriceBookSuccess = (data) => ({
  type: priceBookActionTypes.DOWNLOAD_CLIENTBOOK_SUCCESS,
  payload: data,
});

export const addUpdloadPriceBookFailure = (data) => ({
  type: priceBookActionTypes.DOWNLOAD_CLIENTBOOK_FAILURE,
  payload: data,
});

export function addUpdloadPriceBookAction(payload) {
  const token = isLoggedIn("adminData1");
  const updatedData = multiPartData({ file: payload.file, ...payload })
  return (dispatch) => {
    dispatch(addUpdloadPriceBookInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/client-price-book`,updatedData,  {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201  || data.status == 200) {
            dispatch(addUpdloadPriceBookSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addUpdloadPriceBookFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addUpdloadPriceBookFailure(err));
          reject(err);
        });
    });
  };
}

 





// CTC 





export const getAllTemplateListInitate = () => ({
  type: priceBookActionTypes.TEMPLATE_LIST_INITIATE,
});

export const getAllTemplateListSuccess = (data) => ({
  type: priceBookActionTypes.TEMPLATE_LIST_SUCCESS,
  payload: data,
});

export const getAllTemplateListFailure = (data) => ({
  type: priceBookActionTypes.TEMPLATE_LIST_FAILURE,
  payload: data,
});

export function getAllTemplateListAction( id, name,tag) {
  const token = isLoggedIn("adminData1");
  // ?name=${name ? name:""}&tag=${tag ? tag :""}
  return (dispatch) => {
    dispatch(getAllTemplateListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/ctc-categories/${id}/ctc-templates`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          console.log("data", data);
          if (data && data.status == 200 ||  data.status ==201) {
            dispatch(getAllTemplateListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllTemplateListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getAllTemplateListSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getAllTemplateListFailure(err));
            reject(err)
          }
        
        });
    });
  };
}

export const addTemplateInitate = () => ({
  type: priceBookActionTypes.CREATE_TEMPLATE_INITIATE,
});

export const addTemplateSuccess = (data) => ({
  type: priceBookActionTypes.CREATE_TEMPLATE_SUCCESS,
  payload: data,
});

export const addTemplateFailure = (data) => ({
  type: priceBookActionTypes.CREATE_TEMPLATE_FAILURE,
  payload: data,
});

export function addTemplateAction(id,payload) {
  const updatedData = multiPartData( payload )
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(addTemplateInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/ctc-categories/${id}/ctc-templates`,updatedData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201) {
            dispatch(addTemplateSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addTemplateFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addTemplateFailure(err));
          reject(err);
        });
    });
  };
}


export const removeTemplateInitate = () => ({
type: priceBookActionTypes.DELETE_TEMPLATE_INITIATE,
});

export const removeTemplateSuccess = (data) => ({
type: priceBookActionTypes.DELETE_TEMPLATE_SUCCESS,
payload: data,
});

export const removeTemplateFailure = (data) => ({
type: priceBookActionTypes.DELETE_TEMPLATE_FAILURE,
payload: data,
});

export function removeTemplateAction( id, sid) {
const token = isLoggedIn("adminData1");
return (dispatch) => {
  dispatch(removeTemplateInitate());
  return new Promise((resolve, reject) => {
    axios
      .delete(
        `${Url}companies/${iBuildLocalData?.user?.CompanyId}/ctc-categories/${id}/ctc-templates/${
          sid ? sid : ""
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        const data = response.data;
        if ((data && data.status == 200) || 201) {
          dispatch(removeTemplateSuccess(data));
          resolve(data);
        } else {
          dispatch(removeTemplateFailure(data));
          reject(data);
        }
      })
      .catch((err) => {
        dispatch(removeTemplateFailure(err));
        reject(err);
      });
  });
};
}



export const updateTemplateInitate = () => ({
type: priceBookActionTypes.UPDATE_TEMPLATE_INITIATE,
});

export const updateTemplateSuccess = (data) => ({
type: priceBookActionTypes.UPDATE_TEMPLATE_SUCCESS,
payload: data,
});

export const updateTemplateFailure = (data) => ({
type: priceBookActionTypes.UPDATE_TEMPLATE_SUCCESS,
payload: data,
});

export function updateTemplateAction( id, sid,payload) {
const token = isLoggedIn("adminData1");
return (dispatch) => {
  dispatch(updateTemplateInitate());
  return new Promise((resolve, reject) => {
    // ${payload?payload:""}
    axios
      .put(
        `${Url}companies/${iBuildLocalData?.user?.CompanyId}/ctc-categories/${id}/ctc-templates/${sid}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        const data = response.data;
        if ((data && data.status == 201) || 200) {
          dispatch(updateTemplateSuccess(data));
          resolve(data);
        } else {
          dispatch(updateTemplateFailure(data));
          reject(data);
        }
      })
      .catch((err) => {
        dispatch(updateTemplateFailure(err));
        reject(err);
      });
  });
};
}





export const updateTemplatePicInitate = () => ({
  type: priceBookActionTypes.UPDATE_TEMPLATE_INITIATE,
  });
  
  export const updateTemplatePicSuccess = (data) => ({
  type: priceBookActionTypes.UPDATE_TEMPLATE_SUCCESS,
  payload: data,
  });
  
  export const updateTemplatePicFailure = (data) => ({
  type: priceBookActionTypes.UPDATE_TEMPLATE_SUCCESS,
  payload: data,
  });
  
  export function updateTemplatePicAction(payload, id, sid) {
  const token = isLoggedIn("adminData1");
  const updatedData = multiPartData({ images: payload.images, ...payload })
  return (dispatch) => {
    dispatch(updateTemplatePicInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/ctc-categories/${id}/ctc-templates/${sid}/upload-pic`,
          updatedData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(updateTemplatePicSuccess(data));
            resolve(data);
          } else {
            dispatch(updateTemplatePicFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateTemplatePicFailure(err));
          reject(err);
        });
    });
  };
  }
  
  

export const getTemplateDetailsInitate = () => ({
type: priceBookActionTypes.TEMPLATE_DETAILS_INITIATE,
});

export const getTemplateDetailsSuccess = (data) => ({
type: priceBookActionTypes.TEMPLATE_DETAILS_SUCCESS,
payload: data,
});

export const getTemplateDetailsFailure = (data) => ({
type: priceBookActionTypes.TEMPLATE_DETAILS_FAILURE,
payload: data,
});

export function getTemplateDetailsAction( id, sid ) {
const token = isLoggedIn("adminData1");
return (dispatch) => {
  dispatch(getTemplateDetailsInitate());
  return new Promise((resolve, reject) => {
    axios
      .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/ctc-categories/${id}/ctc-templates/${sid}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const data = response;
        ////console.log("data", data);
        if (data && data.status == 200 || 201) {
          dispatch(getTemplateDetailsSuccess(data.data));
          resolve(data.data);
        } else {
          dispatch(getTemplateDetailsFailure(data.data));
          reject(data);
        }
      })
      .catch((err) => {
        console.log("==errerr=====>", err)
        if(err.response.status === 404){
          dispatch(getTemplateDetailsSuccess(err.data));
          resolve(err.data)
        }else {
          dispatch(getTemplateDetailsFailure(err));
          reject(err);
        }
      });
  });
};
}



export const purchaseDetailsInitate = () => ({
  type: priceBookActionTypes.PURCHASE_DETAILS_LIST_INITIATE,
  });
  
  export const purchaseDetailsSuccess = (data) => ({
  type: priceBookActionTypes.PURCHASE_DETAILS_LIST_SUCCESS,
  payload: data,
  });
  
  export const purchaseDetailsFailure = (data) => ({
  type: priceBookActionTypes.PURCHASE_DETAILS_LIST_FAILURE,
  payload: data,
  });
  
  export function purchaseDetailsAction( id, sid ) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(purchaseDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/ctc-categories/${id}/ctc-templates/${sid}/list-purchase-details`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200 || 201) {
            dispatch(purchaseDetailsSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(purchaseDetailsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("==errerr=====>", err)
          if(err.response.status === 404){
            dispatch(purchaseDetailsSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(purchaseDetailsFailure(err));
            reject(err);
          }
        });
    });
  };
  }
  
  


export const TemplateHistoryInitate = () => ({
  type: priceBookActionTypes.TEMPLATE_HISTORY_INITIATE,
});

export const TemplateHistorySuccess = (data) => ({
  type: priceBookActionTypes.TEMPLATE_HISTORY_SUCCESS,
  payload: data,
});

export const TemplateHistoryFailure = (data) => ({
  type: priceBookActionTypes.TEMPLATE_HISTORY_FAILURE,
  payload: data,
});

export function TemplateHistoryAction(  id, sid ) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(TemplateHistoryInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/ctc-categories/${id}/ctc-templates/${sid}/history`, {
          headers: { Authorization: `Bearer ${token}`},
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200 || 201) {
            dispatch(TemplateHistorySuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(TemplateHistoryFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("==errerr=====>", err)
          if(err.response.status === 404){
            dispatch(TemplateHistorySuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(TemplateHistoryFailure(err));
            reject(err);
          }
        });
    });
  };
}




export const getAllTagListInitate = () => ({
  type: priceBookActionTypes.TAG_LIST_INITIATE,
});

export const getAllTagListSuccess = (data) => ({
  type: priceBookActionTypes.TAG_LIST_SUCCESS,
  payload: data,
});

export const getAllTagListFailure = (data) => ({
  type: priceBookActionTypes.TAG_LIST_FAILURE,
  payload: data,
});

export function getAllTagListAction( name) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllTagListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/price-book-tags`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          console.log("data", data);
          if (data && data.status == 200 || 201) {
            dispatch(getAllTagListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllTagListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getAllTagListSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getAllTagListFailure(err));
            reject(err)
          }
        
        });
    });
  };
}







export const getAllCTCListInitate = () => ({
  type: priceBookActionTypes.CTC_LIST_INITIATE,
});

export const getAllCTCListSuccess = (data) => ({
  type: priceBookActionTypes.CTC_LIST_SUCCESS,
  payload: data,
});

export const getAllCTCListFailure = (data) => ({
  type: priceBookActionTypes.CTC_LIST_FAILURE,
  payload: data,
});

export function getAllCTCListAction( name) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllCTCListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/search-ctc-categories?name=${name ? name:""}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          console.log("data", data);
          if (data && data.status == 200 || 201) {
            dispatch(getAllCTCListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllCTCListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getAllCTCListSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getAllCTCListFailure(err));
            reject(err)
          }
        
        });
    });
  };
}

export const addCTCInitate = () => ({
  type: priceBookActionTypes.CREATE_CTC_INITIATE,
});

export const addCTCSuccess = (data) => ({
  type: priceBookActionTypes.CREATE_CTC_SUCCESS,
  payload: data,
});

export const addCTCFailure = (data) => ({
  type: priceBookActionTypes.CREATE_CTC_FAILURE,
  payload: data,
});

export function addCTCAction(payload) {
  // debugger;
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(addCTCInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/ctc-categories`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201) {
            dispatch(addCTCSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addCTCFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addCTCFailure(err));
          reject(err);
        });
    });
  };
}


export const removeCTCInitate = () => ({
type: priceBookActionTypes.DELETE_CTC_INITIATE,
});

export const removeCTCSuccess = (data) => ({
type: priceBookActionTypes.DELETE_CTC_SUCCESS,
payload: data,
});

export const removeCTCFailure = (data) => ({
type: priceBookActionTypes.DELETE_CTC_FAILURE,
payload: data,
});

export function removeCTCAction( id, type) {
const token = isLoggedIn("adminData1");
return (dispatch) => {
  dispatch(removeCTCInitate());
  return new Promise((resolve, reject) => {
    axios
      .delete(
        `${Url}companies/${iBuildLocalData?.user?.CompanyId}/ctc-categories/${
          id ? id : ""
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        const data = response.data;
        if ((data && data.status == 200) || 201) {
          dispatch(removeCTCSuccess(data));
          resolve(data);
        } else {
          dispatch(removeCTCFailure(data));
          reject(data);
        }
      })
      .catch((err) => {
        dispatch(removeCTCFailure(err));
        reject(err);
      });
  });
};
}



export const updateCTCInitate = () => ({
type: priceBookActionTypes.UPDATE_CTC_INITIATE,
});

export const updateCTCSuccess = (data) => ({
type: priceBookActionTypes.UPDATE_CTC_SUCCESS,
payload: data,
});

export const updateCTCFailure = (data) => ({
type: priceBookActionTypes.UPDATE_CTC_SUCCESS,
payload: data,
});

export function updateCTCAction(payload, params) {
const token = isLoggedIn("adminData1");
return (dispatch) => {
  dispatch(updateCTCInitate());
  return new Promise((resolve, reject) => {
    // ${payload?payload:""}
    axios
      .put(
        `${Url}companies/${iBuildLocalData?.user?.CompanyId}/ctc-categories/${params}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        const data = response.data;
        if ((data && data.status == 201) || 200) {
          dispatch(updateCTCSuccess(data));
          resolve(data);
        } else {
          dispatch(updateCTCFailure(data));
          reject(data);
        }
      })
      .catch((err) => {
        dispatch(updateCTCFailure(err));
        reject(err);
      });
  });
};
}



export const getCTCDetailsInitate = () => ({
type: priceBookActionTypes.CTC_DETAILS_INITIATE,
});

export const getCTCDetailsSuccess = (data) => ({
type: priceBookActionTypes.CTC_DETAILS_SUCCESS,
payload: data,
});

export const getCTCDetailsFailure = (data) => ({
type: priceBookActionTypes.CTC_DETAILS_FAILURE,
payload: data,
});

export function getCTCDetailsAction( id, type ) {
const token = isLoggedIn("adminData1");
return (dispatch) => {
  dispatch(getCTCDetailsInitate());
  return new Promise((resolve, reject) => {
    axios
      .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/CTC/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const data = response;
        ////console.log("data", data);
        if (data && data.status == 200 || 201) {
          dispatch(getCTCDetailsSuccess(data.data));
          resolve(data.data);
        } else {
          dispatch(getCTCDetailsFailure(data.data));
          reject(data);
        }
      })
      .catch((err) => {
        console.log("==errerr=====>", err)
        if(err.response.status === 404){
          dispatch(getCTCDetailsSuccess(err.data));
          resolve(err.data)
        }else {
          dispatch(getCTCDetailsFailure(err));
          reject(err);
        }
      });
  });
};
}




export const CTCHistoryInitate = () => ({
  type: priceBookActionTypes.CTC_HISTORY_INITIATE,
});

export const CTCHistorySuccess = (data) => ({
  type: priceBookActionTypes.CTC_HISTORY_SUCCESS,
  payload: data,
});

export const CTCHistoryFailure = (data) => ({
  type: priceBookActionTypes.CTC_HISTORY_FAILURE,
  payload: data,
});

export function CTCHistoryAction(  id ) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(CTCHistoryInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/ctc-categories/${id}/history`, {
          headers: { Authorization: `Bearer ${token}`},
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200 || 201) {
            dispatch(CTCHistorySuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(CTCHistoryFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("==errerr=====>", err)
          if(err.response.status === 404){
            dispatch(CTCHistorySuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(CTCHistoryFailure(err));
            reject(err);
          }
        });
    });
  };
}




 
export const addTemplatePurchaseInitate = () => ({
  type: priceBookActionTypes.ADD_TEMPLATE_PURCHASE_INITIATE,
});

export const addTemplatePurchaseSuccess = (data) => ({
  type: priceBookActionTypes.ADD_TEMPLATE_PURCHASE_SUCCESS,
  payload: data,
});

export const addTemplatePurchaseFailure = (data) => ({
  type: priceBookActionTypes.ADD_TEMPLATE_PURCHASE_FAILURE,
  payload: data,
});

export function addTemplatePurchaseAction(payload) {
  
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(addTemplatePurchaseInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/add-items`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201 || 200) {
            dispatch(addTemplatePurchaseSuccess(data.data.data));
            resolve(data.data.data);
          } else {
            dispatch(addTemplatePurchaseFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addTemplatePurchaseFailure(err));
          reject(err);
        });
    });
  };
}



export const getAllPermissionPriceBookInitate = () => ({
  type: priceBookActionTypes.PRICEBOOK_PERMISSION_LIST_INITIATE,
});

export const getAllPermissionPriceBookSuccess = (data) => ({
  type: priceBookActionTypes.PRICEBOOK_PERMISSION_LIST_SUCCESS,
  payload: data,
});

export const getAllPermissionPriceBookFailure = (data) => ({
  type: priceBookActionTypes.PRICEBOOK_PERMISSION_LIST_FAILURE,
  payload: data,
});

export function getAllPermissionPriceBookAction( name) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllPermissionPriceBookInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/put-in-use`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllPermissionPriceBookSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllPermissionPriceBookFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getAllPermissionPriceBookSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getAllPermissionPriceBookFailure(err));
            reject(err)
          }
        
        });
    });
  };
}




export const updatePriceBookPermissionInitate = () => ({
  type: priceBookActionTypes.UPDATE_PRICEBOOK_PERMISSION_INITIATE,
});

export const updatePriceBookPermissionSuccess = (data) => ({
  type: priceBookActionTypes.UPDATE_PRICEBOOK_PERMISSION_SUCCESS,
  payload: data,
});

export const updatePriceBookPermissionFailure = (data) => ({
  type: priceBookActionTypes.UPDATE_PRICEBOOK_PERMISSION_SUCCESS,
  payload: data,
});

export function updatePriceBookPermissionAction(payload, params) {
  const token = isLoggedIn("adminData1");
  const updatedData = multiPartData({ image: payload.image, ...payload })
  return (dispatch) => {
    dispatch(updatePriceBookPermissionInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/put-in-use`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(updatePriceBookPermissionSuccess(data));
            resolve(data);
          } else {
            dispatch(updatePriceBookPermissionFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updatePriceBookPermissionFailure(err));
          reject(err);
        });
    });
  };
}




export const updateDisableTemplateInitate = () => ({
  type: priceBookActionTypes.DISABLE_TEMPLATE_INITIATE,
  });
  
  export const updateDisableTemplateSuccess = (data) => ({
  type: priceBookActionTypes.DISABLE_TEMPLATE_SUCCESS,
  payload: data,
  });
  
  export const updateDisableTemplateFailure = (data) => ({
  type: priceBookActionTypes.DISABLE_TEMPLATE_SUCCESS,
  payload: data,
  });
  
  export function updateDisableTemplateAction(params, secId) {
  const token = isLoggedIn("adminData1");
  // if()
  // const updatedData = multiPartData({ images: payload.images, ...payload })
  return (dispatch) => {
    dispatch(updateDisableTemplateInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/ctc-categories/${params}/ctc-templates/${secId}/disable-for-use`,
          "",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(updateDisableTemplateSuccess(data));
            resolve(data);
          } else {
            dispatch(updateDisableTemplateFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateDisableTemplateFailure(err));
          reject(err);
        });
    });
  };
  }
  


  

export const updateEnableTemplateInitate = () => ({
  type: priceBookActionTypes.ENABLE_TEMPLATE_INITIATE,
  });
  
  export const updateEnableTemplateSuccess = (data) => ({
  type: priceBookActionTypes.ENABLE_TEMPLATE_SUCCESS,
  payload: data,
  });
  
  export const updateEnableTemplateFailure = (data) => ({
  type: priceBookActionTypes.ENABLE_TEMPLATE_SUCCESS,
  payload: data,
  });
  
  export function updateEnableTemplateAction(params, secId,payload) {
  const token = isLoggedIn("adminData1");
  // if()
  // const updatedData = multiPartData({ images: payload.images, ...payload })
  return (dispatch) => {
    dispatch(updateEnableTemplateInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/ctc-categories/${params}/ctc-templates/${secId}/release-for-use`,
          "",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(updateEnableTemplateSuccess(data));
            resolve(data);
          } else {
            dispatch(updateEnableTemplateFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateEnableTemplateFailure(err));
          reject(err);
        });
    });
  };
  }
  

  

export const importTemplateInitate = () => ({
  type: priceBookActionTypes.IMPORT_TEMPLATE_INITIATE,
  });
  
  export const importTemplateSuccess = (data) => ({
  type: priceBookActionTypes.IMPORT_TEMPLATE_SUCCESS,
  payload: data,
  });
  
  export const importTemplateFailure = (data) => ({
  type: priceBookActionTypes.IMPORT_TEMPLATE_FAILURE,
  payload: data,
  });
  
  export function importTemplateAction( payload) {
    const updatedData = multiPartData({ file: payload.file })
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(importTemplateInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/import-template`, updatedData,
          {
            headers: { Authorization: `Bearer ${token}` }, 
          }
        )
        .then((response) => {
          const data = response.data;
          console.log("datataart", data);
          if ((response && response.status == 200)) {
            dispatch(importTemplateSuccess(data));
            resolve(data);
          } else {
            dispatch(importTemplateFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(importTemplateFailure(err));
          reject(err);
        });
    });
  };
  }


  
  export function dowloadTemplateAction( payload) {
    // const update/dData = multiPartData({ file: payload.file })
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}download-cpb-template`,
          {
            headers: {Authorization: `Bearer ${token}`},
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 200 || data.status == 201)) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  }
  
   

  
export function uploadExcelgetData(sid, name, ) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/getAllEntries`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          console.log("data", data);
          if (data && data.status == 200 || 201)   {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            resolve(err.data)
          }else {
            reject(err)
          }
        
        });
    });
  };
}


export function getExcelData(id ) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/getAllEntriesByCompanies?ctcCategory=${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          console.log("data", data);
          if (data && data.status == 200 || 201)   {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            resolve(err.data)
          }else {
            reject(err)
          }
        
        });
    });
  };
}



export const getCTCCategoryListInitate = () => ({
  type: priceBookActionTypes.CTC_CATEGORY_LIST_INITIATE,
});

export const getCTCCategoryListSuccess = (data) => ({
  type: priceBookActionTypes.CTC_CATEGORY_LIST_SUCCESS,
  payload: data,
});

export const getCTCCategoryListFailure = (data) => ({
  type: priceBookActionTypes.CTC_CATEGORY_LIST_FAILURE,
  payload: data,
});

export function getCTCCategoryListAction( folderId, templateId) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getCTCCategoryListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/ctc-categories/${folderId}/ctc-templates/${templateId}/category`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {

            console.log("RESPONSE==============>",data.data)
            dispatch(getCTCCategoryListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getCTCCategoryListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getCTCCategoryListSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getCTCCategoryListFailure(err));
            reject(err);
          }
         
        });
    });
  };
}





export const getCTCAssemblyListInitate = () => ({
  type: priceBookActionTypes.CTC_ASSEMBLY_LIST_INITIATE,
});

export const getCTCAssemblyListSuccess = (data) => ({
  type: priceBookActionTypes.CTC_ASSEMBLY_LIST_SUCCESS,
  payload: data,
});

export const getCTCAssemblyListFailure = (data) => ({
  type: priceBookActionTypes.CTC_ASSEMBLY_LIST_FAILURE,
  payload: data,
});

export function getCTCAssemblyListAction( folderId, templateId,categoryId) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getCTCAssemblyListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/ctc-categories/${folderId}/ctc-templates/${templateId}/assembly?categoryId=${categoryId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getCTCAssemblyListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getCTCAssemblyListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getCTCAssemblyListSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getCTCAssemblyListFailure(err));
            reject(err);
          }
         
        });
    });
  };
}


