import axios from "axios";
import { DropdownConstant } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config/Config";
import { multiPartData } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
const tradeViewData = JSON.parse(localStorage.getItem("tradeViewLogin"))
const clientViewData = JSON.parse(localStorage.getItem("clientViewLogin"))
const ivestorViewData = JSON.parse(localStorage.getItem("investorViewLogin"));
const ibuildAdminData = JSON.parse(localStorage.getItem("investorViewLogin"));
  // console.log("clientViewData?.user?.CompanyId",iBuildLocalData?.user?.CompanyId)

export const dropdownUserInitate = () => ({
    type: DropdownConstant.USER_LIST_INITIATE,
  });
  
  export const dropdownUserSuccess = (data) => ({
    type: DropdownConstant.USER_LIST_SUCCESS,
    payload: data,
  });
  
  export const dropdownUserFailure = (data) => ({
    type: DropdownConstant.USER_LIST_FAILURE,
    payload: data,
  });
  
  export function UserDropdownAction() {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(dropdownUserInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId ?? tradeViewData?.user?.CompanyId ?? clientViewData?.user?.CompanyId ?? ivestorViewData?.user?.CompanyId}/users`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            console.log("data", data);
            if (data && data.status == 200) {
              dispatch(dropdownUserSuccess(data.data));
              resolve(data.data);
              console.log("data", data);
            } else {
              dispatch(dropdownUserFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(dropdownUserSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(dropdownUserFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }




  export const dropdownAdminUserInitate = () => ({
    type: DropdownConstant.ADMIN_USER_LIST_INITIATE,
  });
  
  export const dropdownAdminUserSuccess = (data) => ({
    type: DropdownConstant.ADMIN_USER_LIST_SUCCESS,
    payload: data,
  });
  
  export const dropdownAdminUserFailure = (data) => ({
    type: DropdownConstant.ADMIN_USER_LIST_FAILURE,
    payload: data,
  });
  
  export function AdminUserDropdownAction( pid) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(dropdownAdminUserInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}ibuild-admin-user`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            ////console.log("data", data);
            if (data && data.status == 200) {
              dispatch(dropdownAdminUserSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(dropdownAdminUserFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(dropdownAdminUserSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(dropdownAdminUserFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }





  
export const dropdownRoleInitate = () => ({
  type: DropdownConstant.ROLE_LIST_INITIATE,
});

export const dropdownRoleSuccess = (data) => ({
  type: DropdownConstant.ROLE_LIST_SUCCESS,
  payload: data,
});

export const dropdownRoleFailure = (data) => ({
  type: DropdownConstant.ROLE_LIST_FAILURE,
  payload: data,
});

export function RoleDropdownAction( pid) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(dropdownRoleInitate());
    const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId ?? tradeViewData?.user?.CompanyId ?? clientViewData?.user?.CompanyId ?? ivestorViewData?.user?.CompanyId}/roles`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(dropdownRoleSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(dropdownRoleFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(dropdownRoleSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(dropdownRoleFailure(err));
            reject(err);
          }
         
        });
    });
  };
}










  
export const documentKeyListInitate = () => ({
  type: DropdownConstant.DOCUMENT_KEY_LIST_INITIATE,
});

export const documentKeyListSuccess = (data) => ({
  type: DropdownConstant.DOCUMENT_KEY_LIST_SUCCESS,
  payload: data,
});

export const documentKeyListFailure = (data) => ({
  type: DropdownConstant.DOCUMENT_KEY_LIST_FAILURE,
  payload: data,
});

export function DocumentKeyListAction( pid) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(documentKeyListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}documentkey`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(documentKeyListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(documentKeyListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(documentKeyListSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(documentKeyListFailure(err));
            reject(err);
          }
         
        });
    });
  };
}





export const costCodeListInitate = () => ({
  type: DropdownConstant.COST_CODE_LIST_INITIATE,
});

export const costCodeListSuccess = (data) => ({
  type: DropdownConstant.COST_CODE_LIST_SUCCESS,
  payload: data,
});

export const costCodeListFailure = (data) => ({
  type: DropdownConstant.COST_CODE_LIST_FAILURE,
  payload: data,
});

export function CostCodeDropdownAction( pid) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(costCodeListInitate());
    return new Promise((resolve, reject) => {
      
      axios.get(`${Url}companies/${1}/sale/costCodes`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(costCodeListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(costCodeListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(costCodeListSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(costCodeListFailure(err));
            reject(err);
          }
         
        });
    });
  };
}




export const getUrlInitate = () => ({
  type: DropdownConstant.GET_FILE_URL_INITIATE,
});

export const getUrlSuccess = (data) => ({
  type: DropdownConstant.GET_FILE_URL_SUCCESS,
  payload: data,
});

export const getUrlFailure = (data) => ({
  type: DropdownConstant.GET_FILE_URL_FAILURE,
  payload: data,
});

export function getUrlImageAction(id,payload) {
  const token = isLoggedIn("adminData1");
  const updatedData = multiPartData(payload)
  return (dispatch) => {
    dispatch(getUrlInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}upload/files`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getUrlSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getUrlFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getUrlSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getUrlFailure(err));
            reject(err);
          }
         
        });
    });
  };
}



