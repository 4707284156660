import React from 'react'
import Modal from "react-bootstrap/Modal";

const CreateCommonBuilding = ({createCommonBuildingModal, setCreateCommonBuildingModal, iState, UpdateState,setEditJobModal}) => {


    const handleJobDetialsModalClose = () => {
        setCreateCommonBuildingModal(false);
        setEditJobModal(true)
      };
      const handleJobDetialsModalShow = () => {
        setCreateCommonBuildingModal(true);
      
      };
  return (
    <Modal show={createCommonBuildingModal} className="ModalBox LargeModal">
    <div class="modal-content BgCreate">
      <a
        class="CloseModal"
        onClick={handleJobDetialsModalClose}
        data-dismiss="modal"
      >
        ×
      </a>


      <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Create Multi-Family Common Building</h4>
            </div>
            <div class="FolderForm">

              <form>
                <div class="FolderFormCreateArea">
                  <div class="ModalMediumheading">
                    <h3><span>Project:</span> Proj-4E-SIN-1</h3>
                    <h3><span>Job id:</span> HIL-2B-376</h3>
                  </div>
                  <div class="ModalBigheading2 d-flex align-items-center mb-3">
                    <h3 class="mb-0">Enter Number of Common Buildings to be added</h3>
                    <span class="ModalBigheadingBox">3</span>
                  </div>
                  <div class="EnterProjectTable">
                    <table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Common Building Name</th>
                          <th>
                            <div class="ml-5">Foundation type</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            <div class="form-group mr-5">
                              <input type="text" class="form-control" placeholder="Maintenance Garage" />
                            </div>
                          </td>
                          <td>
                            <div class="CommonSelectBox ml-5">
                              <select>
                                <option selected="">Select Foundation type</option>
                                <option>Select Foundation type 1</option>
                              </select>
                              <span><img src="images/down.png" /></span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            <div class="form-group mr-5">
                              <input type="text" class="form-control" placeholder="Irrigation Housing Facility" />
                            </div>
                          </td>
                          <td>
                            <div class="CommonSelectBox ml-5">
                              <select>
                                <option selected="">Slab-On-Grade-Foundation - No Piles</option>
                                <option>Slab-On-Grade-Foundation - No Piles 1</option>
                              </select>
                              <span><img src="images/down.png" /></span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>
                            <div class="form-group mr-5">
                              <input type="text" class="form-control" placeholder="Recreational Building " />
                            </div>
                          </td>
                          <td>
                            <div class="CommonSelectBox ml-5">
                              <select>
                                <option selected="">Grade Beam Slab-On-Grade Foundation - ...</option>
                                <option>Select Foundation type 1</option>
                              </select>
                              <span><img src="images/down.png" /></span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="PaginationArea">
                      <h6>Showing 3 of 27 entries</h6>
                      <h6><img src="images/leftarrow.png" /> 01 <img src="images/rightarrow.png" /> Out 10</h6>
                    </div>
                  </div>
                </div>
                <div class="Button d-flex justify-content-between align-items-center px-5">
                  <button type="button" class="Outline" data-toggle="modal" data-target="#MultiFamilyJobDetails7" data-dismiss="modal">Back</button>
                  <button type="button" class="Create">Create Common Buildings</button>
                </div>
              </form>

            </div>

          </div>

      </div>
      </Modal>
  )
}

export default CreateCommonBuilding