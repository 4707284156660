import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";

const MultifamilyJobStep4 = ({
  iState,
  UpdateState,
  setShow,
  MultifamilyJobStep4Modal,
  SetMultifamilyJobStep3Modal,
  SetMultifamilyJobStep1Modal,
  SetMultifamilyJobStep2Modal,
  SetMultifamilyJobStep4Modal,
  SetMultifamilyJobStep5Modal,
  SetMultifamilyJobStep6Modal,
}) => {
  const handleMultifamilyJobStep4ModalClose = () => {
    SetMultifamilyJobStep4Modal(false);
    setShow(true);
  };
  const handleMultifamilyJobStep4ModalShow = () => {


    SetMultifamilyJobStep4Modal(true);
  };

  const {
    foundationListData,

    foundationTypeData,

  } = useSelector((state) => state.ProformaReducer);
  const [count, setCount] = useState(1);

  const [buildings, setBuilding] = useState([]);

  const handleCount = (e) => {
    setCount(e.target.value);

    let newArray;
    let localValue = count;
    let tempTotalPerson = [...buildings];
    let currentValue = Math.abs(localValue - e.target.value);

    if (count > e.target.value) {
      newArray = buildings.slice(0, e.target.value);
    } else {
      let tempArray = new Array(currentValue).fill({
        buildingName: "",
        foundationType: "",
      });

      newArray = [...tempTotalPerson, ...tempArray];
    }
    setBuilding(newArray);
  };



  return (
    <Modal show={MultifamilyJobStep4Modal} className="ModalBox LargeModal">
      <div class="modal-content BgCreate">
        <a
          href="javascript:void(0);"
          class="CloseModal"
          onClick={handleMultifamilyJobStep4ModalClose}
        >
          ×
        </a>
        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Create Multi-Family Jobs</h4>
          </div>
          <div class="StepBox">
            <ul>
              <li class="active">
                <span class="Icon">
                  <img
                    src={require("../../assets/images/step_1.png")}
                    onClick={() => {
                      SetMultifamilyJobStep1Modal(true);
                      SetMultifamilyJobStep4Modal(false);
                    }}
                  />
                </span>
                <span class="Text">Details</span>
              </li>
              <li
                class="active"
                onClick={() => {
                  SetMultifamilyJobStep2Modal(true);
                  SetMultifamilyJobStep4Modal(false);
                }}
              >
                <span class="Icon FolderPermissionId ">
                  <img src={require("../../assets/images/blocks.png")} />
                </span>
                <span class="Text">Blocks</span>
              </li>
              <li class="active">
                <span
                  class="Icon FolderPermissionId "
                  data-toggle="modal"
                  onClick={() => {
                    SetMultifamilyJobStep3Modal(true);
                    SetMultifamilyJobStep4Modal(false);
                  }}
                >
                  <img src={require("../../assets/images/units.png")} />
                </span>
                <span class="Text">Units </span>
              </li>
              <li className="active">
                <span class="Icon FolderPermissionId " data-toggle="modal">
                  <img src={require("../../assets/images/step_8.png")} />
                </span>
                <span class="Text">
                  Common <br /> Buildings
                </span>
              </li>
              <li>
                <span
                  class="Icon FolderPermissionId "
                  data-toggle="modal"
                  onClick={() => {
                    SetMultifamilyJobStep5Modal(true);
                    SetMultifamilyJobStep4Modal(false);
                  }}
                >
                  <img src={require("../../assets/images/step_2.png")} />
                </span>
                <span class="Text">
                  Job <br /> Address
                </span>
              </li>
              <li>
                <span
                  class="Icon FolderPermissionId "
                  data-toggle="modal"
                  onClick={() => {
                    SetMultifamilyJobStep6Modal(true);
                    SetMultifamilyJobStep4Modal(false);
                  }}
                >
                  <img src={require("../../assets/images/step_7.png")} />
                </span>
                <span class="Text">Confirmation</span>
              </li>
            </ul>
          </div>
          <div class="FolderForm">
            <form>
              <div class="FolderFormCreateArea">
                <div class="FieldsMandatory">
                  <h6>
                    Fields with <span class="required">*</span> are mandatory
                  </h6>
                </div>
                <div class="ModalBigheading2 d-flex align-items-center mb-3">
                  <h3 class="mb-0">Enter Number of Blocks in the Project</h3>{" "}
                  <input
                    class="form-control ModalBigheadingBox"
                    style={{ width: "80px" }}
                    onChange={handleCount}
                  />
              
                </div>
                <div class="EnterProjectTable">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Common Building Name</th>
                        <th>
                          <div class="ml-5">Foundation type</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {buildings?.map((item, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>
                            <div class="form-group mr-5">
                              <input
                                type="text"
                                class="form-control"
                                onChange={(e) => {
                                  let obj = buildings[i];
                                  buildings[i] = {
                                    ...obj,
                                    buildingName: e.target.value,
                                  };

                                  let value = [...buildings];

                                  setBuilding(value);
                                }}
                                placeholder="Maintenance Garage"
                              />
                            </div>
                          </td>
                          <td>
                            <div class="CommonSelectBox ml-5">
                              <select                                 onChange={(e) => {
                                  let obj = buildings[i];
                                  buildings[i] = {
                                    ...obj,
                                    foundationType: e.target.value,
                                  };

                                  let value = [...buildings];

                                  setBuilding(value);
                                }}
>
<option value="">Select Foundation Type</option>
                              {foundationTypeData?.data?.length > 0 &&
                                foundationTypeData?.data?.map((data, i) => {
                                  return (
                                    <option key={i} value={data?.id}>
                                      {data?.name}
                                    </option>
                                  );
                                })}
                              </select>
                              <span>
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div class="PaginationArea">
                    <h6>Showing 1 to 10 of 27 entries</h6>
                    <h6>
                      <img src="images/leftarrow.png" /> 01{" "}
                      <img src="images/rightarrow.png" /> Out 10
                    </h6>
                  </div>
                </div>
              </div>

              <div class="Button d-flex justify-content-between align-items-center px-5">
                <button
                  class="Outline"
                  onClick={() => {
                    SetMultifamilyJobStep4Modal(false);
                    SetMultifamilyJobStep3Modal(true);
                  }}
                >
                  Back
                </button>
                <button
                  class="Create"
                  data-toggle="modal"
                  onClick={(e) => {
                    e.preventDefault()
                    UpdateState({...iState,buildings:buildings})
                    SetMultifamilyJobStep4Modal(false);
                    SetMultifamilyJobStep5Modal(true);
                  }}
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MultifamilyJobStep4;
