import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import {toast} from "react-toastify"
import { useDispatch } from "react-redux";



const OkaypayModal = ({ okaypayModal, setOkaypayModal}) => {

  const dispatch = useDispatch()
  const handleOkaypayModalClose = () => setOkaypayModal(false);

 





  return (
    <>
      <Modal
        show={okaypayModal}
        onHide={handleOkaypayModalClose}
        className="ModalBox ExtraSmallModal"
      >
        <a
          onClick={handleOkaypayModalClose}
          className="CloseModal"
          data-dismiss="modal"
        >
          ×
        </a>

        <div class="FormArea">
              <div class="ModalTitleBox">
                <h4>Ok to Pay</h4>
              </div>
              <div class="FolderForm">
                <form>
                  <div class="form-group">
                    <label class="Radio">
                      Start workflow
                      <input type="radio" name="Cuisine" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label class="Radio">
                      Start and create a new To-Do task
                      <img src="images/icon-35.png" />
                      <input type="radio" name="Cuisine" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label class="Radio">
                      Start and create a new To-Do task
                      <img src="images/icon-35.png" />
                      <input type="radio" name="Cuisine" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="Button d-flex justify-content-between">
                    <button class="Outline" data-dismiss="modal">Cancel</button>
                    <button class="Create" data-dismiss="modal">Proceed</button>
                  </div>
                </form>
              </div>
            </div>

   
      </Modal>


    </>
  );
};

export default OkaypayModal;
