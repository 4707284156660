import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getLandDevelopmentStep2 } from "../../../redux/action/proformaAction";

const Quantities = ({ unitList, handleShowComponents }) => {
  const dispatch = useDispatch([]);
  const [LandDevlopmentQuantites, setLandDevlopmentQuantites] = useState([]);
  useEffect(() => {
    dispatch(getLandDevelopmentStep2()).then((res) => {
      console.log({ res });
      setLandDevlopmentQuantites(res?.data?.data);
    });
  }, []);
  const handleLandDevelopmentQuantityChange = (e, ind, i, blocId) => {
    const { name, value } = e.target;
    const dummyQuantity = [...LandDevlopmentQuantites];
    dummyQuantity[ind].ProformaBaseCosts[i].ProformaBlockQuantities[blocId] = {
      ...dummyQuantity[ind].ProformaBaseCosts[i].ProformaBlockQuantities[
        blocId
      ],
      [name]: value,
    };
    console.log({ dummyQuantity });
    setLandDevlopmentQuantites(dummyQuantity);
  };
  return (
    <>
      <div className="ModalMediumheading2 d-flex align-items-center justify-content-between mb-3 pl-4">
        <h3 className="mb-0">
          Land, Design, Permits, Servicing, Warranty, insurance, Condo, <br />
          Management, Legal &amp; Financing Quantities
        </h3>
        <h3 className="mb-0">
          <img src={require("../../../assets/images/cash-flow.png")} /> &nbsp;
          Customize Base Costs
        </h3>
      </div>
      <div className="ProformaAccordion">
        <div className="accordion" id="accordionExample">
          {LandDevlopmentQuantites?.length > 0 &&
            LandDevlopmentQuantites?.map((item, ind) => {
              return (
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#LandAcquisitionCosts"
                        aria-expanded="false"
                        aria-controls="LandAcquisitionCosts"
                      >
                        Land Acquisition Costs
                      </button>
                    </h2>
                    <a href="javascript:void(0);">
                      <img
                        src={require("../../../assets/images/book-search.png")}
                      />
                    </a>
                  </div>
                  <div
                    id="LandAcquisitionCosts"
                    className="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                    style={{}}
                  >
                    <div className="card-body">
                      <div
                        className="EnterProjectTable GreyTd"
                        style={{ overflowX: "scroll" }}
                      >
                        <table>
                          <thead>
                            <tr>
                              <th>Cost Code #</th>
                              <th>Description</th>
                              <th>Unit of measaure</th>
                              <th>Block A</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item?.ProformaBaseCosts?.length > 0 &&
                              item?.ProformaBaseCosts?.map((datas, i) => {
                                return (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{datas.CostCode?.name}</td>
                                    <td>
                                      <div className="CommonSelectBox width-250">
                                        <select
                                        // value={item.foundationType}
                                        // onChange={(e) => handleBuildingFieldChange(e, index, 'foundationType')}
                                        >
                                          <option value="">
                                            Select Foundation Type
                                          </option>
                                          {unitList?.length > 0 &&
                                            unitList?.map((data, i) => {
                                              return (
                                                <option
                                                  key={i}
                                                  name="unitId"
                                                  value={data.id}
                                                  onChange={(e) =>
                                                    handleLandDevelopmentQuantityChange(
                                                      e,
                                                      ind,
                                                      i
                                                    )
                                                  }
                                                >
                                                  {data.name}
                                                </option>
                                              );
                                            })}
                                        </select>
                                        {/* <span>
                                                      <img src="images/down.png" />
                                                    </span> */}
                                      </div>
                                    </td>
                                    {datas?.ProformaBlockQuantities?.length >
                                      0 &&
                                      datas?.ProformaBlockQuantities?.map(
                                        (block, blocId) => {
                                          return (
                                            <td key={blocId}>
                                              <div className="form-group">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Insert Quantity"
                                                  name="quantity"
                                                  value={block.quantity}
                                                  onChange={(e) =>
                                                    handleLandDevelopmentQuantityChange(
                                                      e,
                                                      ind,
                                                      i,
                                                      blocId
                                                    )
                                                  }
                                                />
                                              </div>
                                            </td>
                                          );
                                        }
                                      )}
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="Button d-flex  justify-content-between align-items-center px-5">
        <button
          className="Outline border text-success border-success"
          onClick={() => handleShowComponents("Input")}
        >
          Cancel
        </button>
        <button
          className="Create bg-success border border-success"
          onClick={() => handleShowComponents("Preview")}
        >
          Save Draft
        </button>
      </div>
    </>
  );
};

export default Quantities;
