import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { clientViewLogin } from '../redux/action/clientViewAuthAction';
import { toast } from "react-toastify";
import { investorViewAuthLogin } from '../redux/action/investorViewAuthAction';

const initialState = {
  email: "",
  password: "",
  loading: false,
};

const InvestorViewLogin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    const [showPassword, setShowPassword] = useState(true);
    const [iState, updateState] = useState(initialState);
    const { email, password, errors, loading, isChecked  } = iState;
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      updateState({
        ...iState,
        [name]: value,
      });
    };
    let handleValidation = () => {
      let error = {};
      let formIsValid = true;
      if (!email.trim()) {
        error.emailIdError = " * Email id can't be empty";
        formIsValid = false;
      } else {
        if (!/^.+?@.+?\..+$/.test(email)) {
          error.emailIdError = " * Email format is not valid";
          formIsValid = false;
        }
      }
      if (!password.trim()) {
        error.passwordError = " * Password can't be empty";
        formIsValid = false;
      }    
      updateState({
        ...iState,
        errors: error,
      });
      return formIsValid;
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      let formIsValid = handleValidation();
      if (formIsValid) {
        updateState({ ...iState, loading: true })
          const data = {
            email: email.trim(),
            password: password.trim(),
          };
          try {
            const res = await dispatch(investorViewAuthLogin(data));
            if (res.status === 200) {
              navigate('/investor-view-home')
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
                theme: "colored",
              });
    
              if (isChecked) {
                window.localStorage.setItem("emailData", email);
                window.localStorage.setItem("passwordData", password);
                window.localStorage.setItem("checkboxData", isChecked);
              } else {
                window.localStorage.removeItem("emailData");
                window.localStorage.removeItem("passwordData");
                window.localStorage.removeItem("checkboxData");
              }
              updateState({
                ...iState,
                errors: "",
                email: "",
                password: "",
                loading: false,
              });
            } else {
              toast.error(res?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
                theme: "colored",
              });
              updateState({
                ...iState,
                loading: false,
              });
            }
          } catch (err) {
            console.log("error", err);
            toast.error(err?.data?.error, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
              theme: "colored",
            });
            updateState({
              ...iState,
              loading: false,
            });
          }
        
      
      }
    };
  
    const handleCheck = (e) => {
      const { checked } = e.target;
      updateState({ ...iState, isChecked: checked });
    };
  
    useEffect(() => {
      const checkbox = window.localStorage.getItem("checkboxData") === "true";
      const email = window.localStorage.getItem("emailData");
      const password = window.localStorage.getItem("passwordData");
      updateState({
        ...iState,
        isChecked: checkbox,
        email: email || "",
        password: password || "",
      });
    }, []);
    const handleTogglePassword = () => {
      setShowPassword(!showPassword);
    };
  return (
    <div>
      <div className="LoginArea">
        <div className="LoginBoxBig">
          <div className="row">
            <div className="col-md-5">
              <div className="LoginBoxLeft">
                <figure>
                  <img src="images/login_background.png" />
                </figure>
              </div>
            </div>
            <div className="col-md-7">
              <div className="LoginBox">
                <h6 className="mb-0">Multi-Family and Residential Platform</h6>
                <div className="TabContenArea">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h3>Welcome Back</h3>
                      <p>Login to continue</p>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit}>
              <div className="form-group">
                <h4>Investor View</h4>
              </div>
              <div className="form-group">
                <label>Username</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter text"
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                />
                  <span style={{ color: "red" }} className='d-flex'>{errors?.emailIdError}</span>
              </div>
              <div className="form-group">
                <label>Password</label>
                <div className="InputPasswordView">
                  <input
                      type={!showPassword ? "password" : "text"}
                    className="form-control"
                    placeholder="Enter text password"
                    name="password"
                    value={password}
                    onChange={handleInputChange}
                  />
                    <span className="" onClick={handleTogglePassword}>
                      <div>
                        {!showPassword ? (
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        ) : (
                          <i className="fa fa-eye-slash" aria-hidden="true"></i>
                        )}
                      </div>
                    </span>
                </div>
                <span style={{ color: "red" }} className="d-flex">
                      {errors?.passwordError}
                    </span>
              </div>
              <div className="d-flex justify-content-between">
                <div className="form-group">
                  <label className="CheckBox">
                    {" "}
                    Remember me
                    <input type="checkbox" />
                    <span className="checkmark" handleCheck={handleCheck} />
                  </label>
                </div>
                <div className="form-group">
                  <div className="Checkboxs">
                    <span>&nbsp;</span>
                    <Link to="/investor-forgot">Forgot password?</Link>
                  </div>
                </div>
              </div>
              <button type="submit" className="Button" disabled={loading}>
             
             {loading ? (
                           <>
                             {" "}
                           <span
                             className="spinner-border spinner-border-md"
                             role="status"
                             aria-hidden="true"
                           ></span>
                           </>
                        
                         ) : "Login" }

           </button>
            </form>
                  <div className="loginSocials">
                    <a href="javascript:void(0);">
                      <img src="images/facebook_red.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/earth_red.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/mail_red.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/linkdin_red.png" />
                    </a>
                  </div>
                  <div className="TabContentBottom">
                    <p className="">
                      Not an <span>Ibuild</span> customer yet?{" "}
                      <a href="javascript:void(0);">Subscribe Noew</a>
                    </p>
                    <div className="Logo">
                      <img src="images/Logo.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorViewLogin;
