import { modelsAction } from "../actionTypes";
const initialState = {
loader: false,
modelsList:[],
projectsList:[],
jobListProjectByType:[],
listProjectJobs:[],
};

export default function modelsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case modelsAction.MODELS_LIST_INITIATE: {
        return {...state,modelsList: [],loader:true};
      }
      case modelsAction.MODELS_LIST_SUCCESS: {
        return {  ...state,modelsList: payload,loader:false};
      }
      case modelsAction.MODELS_LIST_FAILURE: {
        return { ...state,modelsList:[]};
      }

      case modelsAction.ALL_PROJECTS_LIST_INITIATE: {
        return {...state,projectsList: []};
      }
      case modelsAction.ALL_PROJECTS_LIST_SUCCESS: {
        return {  ...state,projectsList: payload};
      }
      case modelsAction.ALL_PROJECTS_LIST_FAILURE: {
        return { ...state,projectsList:[]};
      }
      
      case modelsAction.JOB_LIST_PROJECT_BY_TYPE_INITIATE: {
        return {...state,jobListProjectByType: []};
      }
      case modelsAction.JOB_LIST_PROJECT_BY_TYPE_SUCCESS: {
        return {  ...state,jobListProjectByType: payload};
      }
      case modelsAction.JOB_LIST_PROJECT_BY_TYPE_FAILURE: {
        return { ...state,jobListProjectByType:[]};
      }

      case modelsAction.LIST_PROJECT_JOBS_INITIATE: {
        return {...state,listProjectJobs: []};
      }
      case modelsAction.LIST_PROJECT_JOBS_SUCCESS: {
        return {  ...state,listProjectJobs: payload};
      }
      case modelsAction.LIST_PROJECT_JOBS_FAILURE: {
        return { ...state,listProjectJobs:[]};
      }

    default:
      return state;
  }
}
