import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getMultiLevelStep3 } from "../../../redux/action/proformaAction";

const Preview = ({ handleShowComponent, handleShowComponents }) => {
  const dispatch = useDispatch([]);
  const [multiLevelStep3List, setMultiLevelStep3List] = useState([]);
  useEffect(() => {
    dispatch(getMultiLevelStep3()).then((res) => {
      console.log({ res });
      setMultiLevelStep3List(res?.data);
    });
  }, []);
  return (
    <>
      <div className="ModalMediumheading d-flex align-items-center justify-content-between">
        <h3>
          <span>Multi-Level Parkade Input</span>
        </h3>
        <h6>
          <img src="images/details-cost.png" />
          Detailed Costs
        </h6>
      </div>
      <div className="BlockInputs">
        <table>
          <thead>
            <tr>
              <th>Input</th>
              <th>Level 1</th>
              <th>Level 2</th>
              <th>Totals</th>
            </tr>
          </thead>
          <tbody>
            {multiLevelStep3List?.data?.table1?.proforma?.MultiParkades
              ?.length > 0 &&
              multiLevelStep3List?.data?.table1?.proforma?.MultiParkades?.map(
                (data, ind) => {
                  return (
                    <tr>
                      <td>{data.garageName}</td>
                      <td>{data.garageSqFt}</td>
                      <td>{data.garagePerimeterLinFt}</td>
                      <td>
                        {multiLevelStep3List?.data?.table1?.proforma?.MultiParkades?.reduce(
                          (total, data) =>
                            total + data.garageSqFt + data.garagePerimeterLinFt,
                          0
                        )}
                      </td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </table>
      </div>
      <div className="BlockCostsPreview">
        <div className="d-flex align-items-center justify-content-between">
          <h4>Multi-Level Parkade Costs Preview</h4>
          <div className="NewActions">
            <a href="javascript:void(0);" title="pdf">
              <img src="images/pdf.png" />
            </a>
            <a href="javascript:void(0);">
              <img src="images/download.png" />
            </a>
            <a href="javascript:void(0);">
              <img src="images/setting.png" />
            </a>
          </div>
        </div>
        <div className="BlockInputsCollapse">
          <div
            className="BcpHeader collapsed"
            data-toggle="collapse"
            href="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <h5>Concrete Grade Level Foundation</h5>
          </div>
          <div className="collapse" id="collapseExample" style={{}}>
            <div className="card card-body border-0">
              <div className="BlockInputs">
                <table>
                  <thead>
                    <tr>
                      <th>Code Code #</th>
                      <th>Description</th>
                      <th>Level 1</th>
                      <th>Level 2</th>
                      <th>Total Garage Costs</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>2.0202</td>
                      <td>Garbage Bins - Construction Waste &amp; Recycling</td>
                      <td>$ 10,800.00</td>
                      <td>$ 10,800.00</td>
                      <td>$ 43,200.00</td>
                    </tr>
                    <tr>
                      <td>2.0202</td>
                      <td>Garbage Bins - Construction Waste &amp; Recycling</td>
                      <td>$ 10,800.00</td>
                      <td>$ 10,800.00</td>
                      <td>$ 10,800.00</td>
                    </tr>
                    <tr>
                      <td>2.0202</td>
                      <td>Garbage Bins - Construction Waste &amp; Recycling</td>
                      <td>$ 10,800.00</td>
                      <td>$ 10,800.00</td>
                      <td>$ 10,800.00</td>
                    </tr>
                    <tr>
                      <td>2.0202</td>
                      <td>Garbage Bins - Construction Waste &amp; Recycling</td>
                      <td>$ 10,800.00</td>
                      <td>$ 10,800.00</td>
                      <td>$ 10,800.00</td>
                    </tr>
                    <tr>
                      <td>2.0202</td>
                      <td>Garbage Bins - Construction Waste &amp; Recycling</td>
                      <td>$ 10,800.00</td>
                      <td>$ 10,800.00</td>
                      <td>$ 10,800.00</td>
                    </tr>
                    <tr>
                      <td>2.0202</td>
                      <td>Garbage Bins - Construction Waste &amp; Recycling</td>
                      <td>$ 10,800.00</td>
                      <td>$ 10,800.00</td>
                      <td>$ 10,800.00</td>
                    </tr>
                    <tr>
                      <td>2.0202</td>
                      <td>Garbage Bins - Construction Waste &amp; Recycling</td>
                      <td>$ 10,800.00</td>
                      <td>$ 10,800.00</td>
                      <td>$ 10,800.00</td>
                    </tr>
                    <tr>
                      <td>2.0202</td>
                      <td>Garbage Bins - Construction Waste &amp; Recycling</td>
                      <td>$ 10,800.00</td>
                      <td>$ 10,800.00</td>
                      <td>$ 10,800.00</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td />
                      <td>Total Concrete Grade Level Foundation Costs</td>
                      <td>67,480.00</td>
                      <td>67,480.00</td>
                      <td>2,75,480.00</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="BlockInputs Red">
          <table>
            <tfoot>
              <tr>
                <td />
                <td>Grand Total</td>
                <td>$ 1,67,480.00</td>
                <td>$ 1,67,480.00</td>
                <td>$ 32,275,590.00</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div className="Button d-flex  justify-content-between align-items-center px-5">
        <button
          className="Outline border text-success border-success"
          onClick={() => handleShowComponents("Quanties")}
        >
          Cancel
        </button>
        <button
          className="Create bg-success border border-success"
          onClick={() => handleShowComponent("landDevelopment")}
        >
          Save Draft
        </button>
      </div>
    </>
  );
};

export default Preview;
