

import { adminUserAction,  } from "../actionTypes";
const initialState = {
    roleList:[],
    userList: [],
    userLoader:false,
    userData:{},
    rolesForm:[],
    userHistoryList:[],
};

export default function adminUserReducers(state = initialState, { type, payload }) {
  switch (type) {
    case adminUserAction.ROLE_LIST_INITIATE: {
        return {  ...state, roleList: []};
      }
      case adminUserAction.ROLE_LIST_SUCCESS: {
        return {  ...state,roleList:payload};
      }
      case adminUserAction.ROLE_LIST_FAILURE: {
        return {  ...state,roleList: []};
      }

      case adminUserAction.USER_LIST_INITIATE: {
        return {  ...state, userList: [] , userLoader:true};
      }
      case adminUserAction.USER_LIST_SUCCESS: {
        return {  ...state,userList:payload,userLoader:false};
      }
      case adminUserAction.USER_LIST_FAILURE: {
        return {  ...state,userList: [],userLoader:false};
      }


      case adminUserAction.USER_DETAILS_INITIATE: {
        return {  ...state, userData:{} , userLoader:true};
      }
      case adminUserAction.USER_DETAILS_SUCCESS: {
        return {  ...state,userData:payload?.user,userLoader:false};
      }
      case adminUserAction.USER_DETAILS_FAILURE: {
        return {  ...state,userData: {},userLoader:false};}

      case adminUserAction.ROLES_FORM_INITIATE: {
        return {  ...state, rolesForm: [] };
      }
      case adminUserAction.ROLES_FORM_SUCCESS: {
        return {  ...state,rolesForm:payload};
      }
      case adminUserAction.ROLES_FORM_FAILURE: {
        return {  ...state,rolesForm: []};
      }

      case adminUserAction.USER_HISTORY_INITIATE: {
        return {  ...state, userHistoryList: [] };
      }
      case adminUserAction.USER_HISTORY_SUCCESS: {
        return {  ...state,userHistoryList:payload};
      }
      case adminUserAction.USER_HISTORY_FAILURE: {
        return {  ...state,userHistoryList: []};
      }

      case adminUserAction.GET_PROFORMA_SETTING_INITIATE: {
        return {  ...state, proformaData: [] };
      }
      case adminUserAction.GET_PROFORMA_SETTING_SUCCESS: {
        return {  ...state,proformaData:payload};
      }
      case adminUserAction.GET_PROFORMA_SETTING_FAILURE: {
        return {  ...state,proformaData: []};
      }
    default:
      return state;
  }
}
