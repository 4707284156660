
import axios from "axios";
import { widCastTypes } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config/Config";
import { multiPartData } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

export const viewWidCastInitate = () => ({
    type: widCastTypes.VIEW_WIDCAST_INITIATE,
  });
  
  export const viewWidCastSuccess = (data) => ({
    type: widCastTypes.VIEW_WIDCAST_SUCCESS,
    payload: data,
  });
  
  export const viewWidCastFailure = (data) => ({
    type: widCastTypes.VIEW_WIDCAST_FAILURE,
    payload: data,
  });
  
  export function viewWidCastAction( pid = 3, ID) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(viewWidCastInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}widcast/viewWidCast?companyId=${iBuildLocalData?.user?.CompanyId}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            if (data && data.status == 200) {
              dispatch(viewWidCastSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(viewWidCastFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(viewWidCastSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(viewWidCastFailure(err));
              reject(err);
            }    
          });
      });
    };
  }
  

  
export const updateWidCastInitate = () => ({
    type: widCastTypes.UPDATE_WIDCAST_INITIATE,
  });
  
  export const updateWidCastSuccess = (data) => ({
    type: widCastTypes.UPDATE_WIDCAST_SUCCESS,
    payload: data,
  });
  
  export const updateWidCastFailure = (data) => ({
    type: widCastTypes.UPDATE_WIDCAST_FAILURE,
    payload: data,
  });
  
  export function updateWidCastAction( id) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(updateWidCastInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${Url}widcast/editWidCast`, id, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            if (data && data.status == 201) {
              dispatch(updateWidCastSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(updateWidCastFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(updateWidCastSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(updateWidCastFailure(err));
              reject(err);
            }    
          });
      });
    };
  }
  



  
export const addWidCastNotificInitate = () => ({
    type: widCastTypes.ADD_WIDCAST_NOTIFICATION_INITIATE,
  });
  
  export const addWidCastNotificSuccess = (data) => ({
    type: widCastTypes.ADD_WIDCAST_NOTIFICATION_SUCCESS,
    payload: data,
  });
  
  export const addWidCastNotificFailure = (data) => ({
    type: widCastTypes.ADD_WIDCAST_NOTIFICATION_FAILURE,
    payload: data,
  });
  
  export function addWidCastNotificAction( data) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(addWidCastNotificInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${Url}widcast/createWidCastNotification`, data, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            if (data && data.status == 201) {
              dispatch(addWidCastNotificSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(addWidCastNotificFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(addWidCastNotificSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(addWidCastNotificFailure(err));
              reject(err);
            }    
          });
      });
    };
  }
  




  
  
export const widCastListInitate = () => ({
    type: widCastTypes.WIDCAST_LIST_INITIATE,
  });
  
  export const widCastListSuccess = (data) => ({
    type: widCastTypes.WIDCAST_LIST_SUCCESS,
    payload: data,
  });
  
  export const widCastListFailure = (data) => ({
    type: widCastTypes.WIDCAST_LIST_FAILURE,
    payload: data,
  });
  
  export function widCastListAction(eid ) {
    const token = isLoggedIn("adminData1");
    return (dispatch) => {
      dispatch(widCastListInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}widcast/getWidCastNotification?companyId=${iBuildLocalData?.user?.CompanyId}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            if (data && data.status == 200) {
              dispatch(widCastListSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(widCastListFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(widCastListSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(widCastListFailure(err));
              reject(err);
            }    
          });
      });
    };
  }



  
  
export const turnOnWidCastInitate = () => ({
    type: widCastTypes.TURN_ON_WIDCAST_INITIATE,
  });
  
  export const turnOnWidCastSuccess = (data) => ({
    type: widCastTypes.TURN_ON_WIDCAST_SUCCESS,
    payload: data,
  });
  
  export const turnOnWidCastFailure = (data) => ({
    type: widCastTypes.TURN_ON_WIDCAST_FAILURE,
    payload: data,
  });
  
  export function turnOnWidCastAction(eid ) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(turnOnWidCastInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}turnOnWidCast?id=${eid}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            if (data && data.status == 200) {
              dispatch(turnOnWidCastSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(turnOnWidCastFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(turnOnWidCastSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(turnOnWidCastFailure(err));
              reject(err);
            }    
          });
      });
    };
  }
  


  
export const turnOffWidCastInitate = () => ({
    type: widCastTypes.TURN_OFF_WIDCAST_INITIATE,
  });
  
  export const turnOffWidCastSuccess = (data) => ({
    type: widCastTypes.TURN_OFF_WIDCAST_SUCCESS,
    payload: data,
  });
  
  export const turnOffWidCastFailure = (data) => ({
    type: widCastTypes.TURN_OFF_WIDCAST_FAILURE,
    payload: data,
  });
  
  export function turnOffWidCastAction(eid ) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(turnOffWidCastInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}companies/turnOffWidCast?id=${eid}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            if (data && data.status == 200) {
              dispatch(turnOffWidCastSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(turnOffWidCastFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(turnOffWidCastSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(turnOffWidCastFailure(err));
              reject(err);
            }    
          });
      });
    };
  }
  


  
  
export const removeWidCastInitate = () => ({
    type: widCastTypes.TURN_ON_WIDCAST_INITIATE,
  });
  
  export const removeWidCastSuccess = (data) => ({
    type: widCastTypes.TURN_ON_WIDCAST_SUCCESS,
    payload: data,
  });
  
  export const removeWidCastFailure = (data) => ({
    type: widCastTypes.TURN_ON_WIDCAST_FAILURE,
    payload: data,
  });
  
  export function removeWidCastAction(eid ) {
    console.log("error removing",eid)
    const token = isLoggedIn("adminData1"); 
    return (dispatch) => {
      dispatch(removeWidCastInitate());
      return new Promise((resolve, reject) => {
        axios
          .post(`${Url}widcast/deleteWidCast`,{id:eid}, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            if (data && data.status == 200) {
              dispatch(removeWidCastSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(removeWidCastFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 4045){
              dispatch(removeWidCastSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(removeWidCastFailure(err));
              reject(err);
            }    
          });
      });
    };
  }
  