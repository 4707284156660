
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

import { toast } from "react-toastify";

const units = []



const initLeadState = {
  ProjectId:"",
  timeZone:"",
  taxProfile:"",
  subDivisionArray:[],
  subdividision:"",
  buildingPermit: "",
  subdividision: "",
  specificationDoc: "",
  taxProfile: 1,
  defaultCurrency: "",
  costScheme: "",
  timeZone: "",
  tractSqFt: 5000,
  accountSoftware: false,
  numLandPhases: "",
  phases: [
      {
          landTractPhaseDes: "",
          numOFLots: "",
          lots: [
              {
                  numOfLot:"" ,
                  lotDescription: "",
                  lotType: ""
              },
             
          ]
      },
    
  ],
  jobAddress: "",
  "city": "",
  "zipCode": "",
  "country": "",
  "province": "",
  "shareCosts": false,
  "residentMulFamPro": "",
  "costCodeShareds": {
      "landCosts": true,
      "archConsFees": false,
      "engineersFees": true,
      "permitFees": true,
      "siteLandDevSerCosts": false,
      "sitePerAndTeaOffiCosts": true,
      "siteLandRetaWallRprs": true,
      "newHomeWarrAndInsuFees": false,
      "condMiniPlanCoprCosts": false,
      "markAndSaleCosts": true,
      "managLegalFinFees": true
  },
  curStep:1
  }

const CreatePhaseModal = ({createPhaseModal, setcreatePhaseModal}) => {



  const[iLeadState, updateLeadState] = useState(initLeadState);



  const{
    subDivisionArray,
phases,
buildingPermit ,
      subdividision,
      specificationDoc,
      taxProfile,
      defaultCurrency,
      costScheme,
      timeZone,
      tractSqFt  ,
      accountSoftware,
      numLandPhases,
      jobAddress ,
      city,
      zipCode ,
      country   ,
      province  ,
      shareCosts  ,
      residentMulFamPro,
      ProjectId,
  } = iLeadState;
  
  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    if(name === "numLandPhases"){
      let tempArray  = ((new Array(+value)).fill( {
        numOFLots: "",
        landTractPhaseDes: "",
      }))
      // 
     
      updateLeadState({ ...iLeadState, [name]: result, phases:tempArray })
    }else {
     
      updateLeadState({ ...iLeadState, [name]: result })
    }
   
  };


  const handleBlockFirstStepChange = (e,i) => {
    const {name,value} = e?.target;
    let tempphases = [...phases];
    tempphases[i] = {...phases[i],[name]:value}; 
  if(name=="numOFLots"){
  
    let lot =   ((new Array(+value)).fill( {
        numOFLots: "",
        landTractPhaseDes: "",
      }))
    tempphases[i] = {...phases[i],Lots:lot,[name]:value}
  
  
  }
  
    updateLeadState({...iLeadState,phases: tempphases})
  
  
  }

  return (

    <Modal show={createPhaseModal} className="ModalBox LargeModal">
    <div class="modal-content BgCreate">
      <a
        class="CloseModal"
        onClick={()=> setcreatePhaseModal(false)}
        data-dismiss="modal"
      >
        ×
      </a>
      <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Create Land Development Land Tracts/Phases</h4>
            </div>

            <div class="StepBox">
              <ul>
                <li class="active">
                  <span class="Icon">
                    <img src="images/land_track_phase.png" />
                  </span>
                  <span class="Text">Land Track/ <br />Phase</span>
                </li>
                <li>
                </li>
                <li>
                </li>
                <li>
                </li>
                <li>
                </li>
                <li>
                </li>
                <li>
                  <span class="Icon">
                    <img src="images/lots_step.png" />
                  </span>
                  <span class="Text">Lots</span>
                </li>
              </ul>
            </div>



            <div class="FolderForm">

              <form>
                <div class="FolderFormCreateArea">
                  <div class="ModalMediumheading">
                    <h3><span>Project:</span> Proj-4E-SIN-1</h3>
                    <h3><span>Job id:</span> HIL-2B-376</h3>
                  </div>
                  <div class="ModalBigheading2 d-flex align-items-center mb-3">
                    <h3 class="mb-0">Enter Number of Land Tracts/Phases to be added</h3>
                    <input className="ModalBigheadingBox" name='numLandPhases' value={numLandPhases} onChange={handleInputNumber}/>
                  </div>
                  <div class="EnterProjectTable">
                    <table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Land Tract/Phase Description</th>
                          <th>
                            <div class="ml-5"># of Lots Per Land Tract/Phase</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      { phases?.map((ele,i) => {                           
                              return (
                                <>
       <tr>
                <td>1</td>
                <td>
                  <div className="form-group mr-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert Description"
                      name='landTractPhaseDes'
                      value={ele?.landTractPhaseDes}
                      onChange={(e) => handleBlockFirstStepChange(e,i)}
                    />
                  </div>
                </td>
                <td>
                  <div className="form-group ml-5">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Insert number of lots"
                      name='numOFLots'
                      value={ele?.numOFLots}
                      min={0}
                      onChange={(e) => handleBlockFirstStepChange(e,i)}
                    />
                  </div>
                </td>
              </tr>
                                </>
                              )
                            })
                          }
            
                      </tbody>
                    </table>
                    <div class="PaginationArea px-3">
                      <h6>Showing 3 of 3 entries</h6>
                      <h6><img src="images/leftarrow.png" /> 01 <img src="images/rightarrow.png" /> Out 10</h6>
                    </div>
                  </div>
                </div>
                <div class="Button d-flex justify-content-between align-items-center">
                  <button type="button" onClick={()=> setcreatePhaseModal(false)} class="Outline" data-toggle="modal" data-target="#LandDevelopmentDetails1" data-dismiss="modal">Back</button>
                  <button type="button" class="Create">Create Land Tracts/ Phases only</button>
                  <button type="button" class="Create FolderPermissionId" data-toggle="modal" data-target="#CreateLandDevelopmentLandTractsPhasesLots" data-dismiss="modal">Create Lots</button>
                </div>
              </form>

            </div>

          </div>
    </div>
  </Modal>


)
}

export default CreatePhaseModal