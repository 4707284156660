import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { quicksBookAction } from "../../redux/action/adminNotificationAction";

const MultifamilyJobStep3 = ({
  iState,
  UpdateState,
  setShow,
  MultifamilyJobStep3Modal,
  SetMultifamilyJobStep3Modal,
  SetMultifamilyJobStep1Modal,
  SetMultifamilyJobStep2Modal,
  SetMultifamilyJobStep4Modal,
  SetMultifamilyJobStep5Modal,
  SetMultifamilyJobStep6Modal,
}) => {

  const dispatch = useDispatch()
  const handleMultifamilyJobStep3ModalClose = () => {
    SetMultifamilyJobStep3Modal(false);
    setShow(true);
  };
  const handleMultifamilyJobStep3ModalShow = () => {
    SetMultifamilyJobStep3Modal(true);
  };

  const [blocks, setBlocks] = useState([]);

  useEffect(() => {
    let temp;

    if (iState?.blocks) {
      temp = iState?.blocks?.map((item) => {
        return {
          ...item,
          units: Array(+item?.numOfUnits).fill({
            unitNumber: "0",
            unitsSqFt: "0",
            numOfBedrooms: "0",
            numOfBathrooms: "0",
            basementDev: false,
          }),
        };
      });
      dispatch(quicksBookAction())



      setBlocks(temp);
    }
  }, [iState]);



  const handleSubmit =(e)=>{
    e.preventDefault()

    UpdateState({...iState, blocks:blocks})


    SetMultifamilyJobStep3Modal(false);
    SetMultifamilyJobStep4Modal(true);

  }

  return (
    <Modal show={MultifamilyJobStep3Modal} className="ModalBox LargeModal">
      <div class="modal-content BgCreate">
        <a
          href="javascript:void(0);"
          class="CloseModal"
          onClick={handleMultifamilyJobStep3ModalClose}
        >
          ×
        </a>
        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Create Multi-Family Jobs</h4>
          </div>
          <div class="StepBox">
            <ul>
              <li class="active">
                <span class="Icon">
                  <img
                    src={require("../../assets/images/step_1.png")}
                    onClick={() => {
                      SetMultifamilyJobStep1Modal(true);
                      SetMultifamilyJobStep3Modal(false);
                    }}
                  />
                </span>
                <span class="Text">Details</span>
              </li>
              <li
                class="active"
                onClick={() => {
                  SetMultifamilyJobStep2Modal(true);
                  SetMultifamilyJobStep3Modal(false);
                }}
              >
                <span class="Icon FolderPermissionId ">
                  <img src={require("../../assets/images/blocks.png")} />
                </span>
                <span class="Text">Blocks</span>
              </li>
              <li class="active">
                <span class="Icon FolderPermissionId " data-toggle="modal">
                  <img src={require("../../assets/images/units.png")} />
                </span>
                <span class="Text">Units </span>
              </li>
              <li>
                <span
                  class="Icon FolderPermissionId "
                  data-toggle="modal"
                  onClick={() => {
                    SetMultifamilyJobStep4Modal(true);
                    SetMultifamilyJobStep3Modal(false);
                  }}
                >
                  <img src={require("../../assets/images/step_8.png")} />
                </span>
                <span class="Text">
                  Common <br /> Buildings
                </span>
              </li>
              <li>
                <span
                  class="Icon FolderPermissionId "
                  data-toggle="modal"
                  onClick={() => {
                    SetMultifamilyJobStep5Modal(true);
                    SetMultifamilyJobStep3Modal(false);
                  }}
                >
                  <img src={require("../../assets/images/step_2.png")} />
                </span>
                <span class="Text">
                  Job <br /> Address
                </span>
              </li>
              <li>
                <span
                  class="Icon FolderPermissionId "
                  data-toggle="modal"
                  onClick={() => {
                    SetMultifamilyJobStep6Modal(true);
                    SetMultifamilyJobStep3Modal(false);
                  }}
                >
                  <img src={require("../../assets/images/step_7.png")} />
                </span>
                <span class="Text">Confirmation</span>
              </li>
            </ul>
          </div>
          <div class="FolderForm">
            <form>
              <div class="FolderFormCreateArea">
                <div class="FieldsMandatory">
                  <h6>
                    Fields with <span class="required">*</span> are mandatory
                  </h6>
                </div>
                <div class="ModalMediumheading">
                  <h3>
                    <span>Units Setup</span>
                  </h3>
                </div>
                <div class="EnterProjectTable UnitSetupTable">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Block's Units #</th>
                        <th>Unit's Sq/Ft</th>
                        <th># of Bedrooms</th>
                        <th># of Bathrooms</th>
                        <th>Basement Development</th>
                      </tr>
                    </thead>
                    <tbody>
                      {blocks?.map((ele, index) => (
                        <>
                          <tr>
                            <td colspan="6">
                              <div class="Action EnterProjectTableAction">
                                <h6 class="mb-0">{ele?.blockDescription}</h6>
                                <img
                                  src="images/block_icon.png"
                                  class="BlockImg"
                                />
                                <div class="CommonSelectBox">
                                  <select>
                                    <option selected="">Select Lot Type</option>
                                    <option>Lot type 1</option>
                                  </select>
                                  <span>
                                    <img src="images/down.png" />
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          {ele?.units?.map((item, i) => (
                            <tr>
                              <td>Unit {i + 1}</td>
                              <td>
                                <div class="form-group">
                                  <input
                                    onChange={(e) => {
                                      let temp = blocks[index];
                                      let temp2 = temp["units"];
                                      temp2[i] = {
                                        ...temp2[2],
                                        unitNumber: e.target.value,
                                      };

                                      let value = [...blocks];

                                      setBlocks(value);
                                    }}
                                    type="number"
                                    class="form-control"
                                    placeholder={""}
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="form-group">
                                  <input
                                    onChange={(e) => {
                                      let temp = blocks[index];
                                      let temp2 = temp["units"];
                                      temp2[i] = {
                                        ...temp2[2],
                                        unitsSqFt: e.target.value,
                                      };

                                      let value = [...blocks];

                                      setBlocks(value);
                                    }}
                                    type="number"
                                    class="form-control"
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="form-group">
                                  <input
                                    type="number"
                                    class="form-control"
                                    onChange={(e) => {
                                      let temp = blocks[index];
                                      let temp2 = temp["units"];
                                      temp2[i] = {
                                        ...temp2[2],
                                        numOfBedrooms: e.target.value,
                                      };

                                      let value = [...blocks];

                                      setBlocks(value);
                                    }}
                                    placeholder=""
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="form-group">
                                  <input
                                    type="number"
                                    onChange={(e) => {
                                      let temp = blocks[index];
                                      let temp2 = temp["units"];
                                      temp2[i] = {
                                        ...temp2[2],
                                        numOfBathrooms: e.target.value,
                                      };

                                      let value = [...blocks];

                                      setBlocks(value);
                                    }}
                                    class="form-control"
                                    placeholder=""
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="text-center">
                                  <label class="CheckBox">
                                    <input type="checkbox" />
                                    <span class="checkmark"></span>
                                  </label>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </>
                      ))}
                    </tbody>
                  </table>
                  <div class="PaginationArea mt-5 px-3">
                    <h6>Showing 4 of 4 block entries</h6>
                    <h6>
                      <img src="images/leftarrow.png" /> 01{" "}
                      <img src="images/rightarrow.png" /> Out 10
                    </h6>
                  </div>
                </div>
              </div>

              <div class="Button d-flex justify-content-between align-items-center px-5">
                <button
                  class="Outline"
                  onClick={() => {
                    SetMultifamilyJobStep3Modal(false);
                    SetMultifamilyJobStep2Modal(true);
                  }}
                  data-dismiss="modal"
                >
                  Back
                </button>
                <button
                  class="Create FolderPermissionId"
                  onClick={handleSubmit}
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MultifamilyJobStep3;
