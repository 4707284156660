import { profileConstant } from "../actionTypes";

import axios from "axios";
import { Url } from "../../Config/Config";
import { isLoggedIn } from "../../utils/tokenCheck";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));


export const profileInitiate = () => ({
  type: profileConstant.GET_PROFILE_INITIATE,
});

export const profileSuccess = (data) => ({
  type: profileConstant.GET_PROFILE_SUCCESS,
  payload: data,
});

export const profileFailure = (data) => ({
  type: profileConstant.GET_PROFILE_FAILURE,
  payload: data,
});

export const ProfileAction = (companyId,userId,payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(profileInitiate(payload));
    const response = await axios.get(`${Url}companies/${companyId}/users/${userId}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    if (response.status == 200) {
      dispatch(profileSuccess(response.data));
    } else {
      dispatch(profileFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(profileFailure(err));
    return err.response;
  }
};



export const updateProfileInitiate = () => ({
  type: profileConstant.UPDATE_PROFILE_INITIATE,
});

export const updateProfileSuccess = (data) => ({
  type: profileConstant.UPDATE_PROFILE_SUCCESS,
  payload: data,
});

export const updateProfileFailure = (data) => ({
  type: profileConstant.UPDATE_PROFILE_FAILURE,
  payload: data,
});

export const UpdateProfileAction = (companyId,userId,payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(updateProfileInitiate(payload));
    const response = await axios.put(`${Url}companies/${companyId}/users/${userId}`,payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status == 200) {
      dispatch(updateProfileSuccess(response.data));
    } else {
      dispatch(updateProfileFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(updateProfileFailure(err));
    return err.response;
  }
};



export const createProfileInitiate = () => ({
  type: profileConstant.CREATE_PROFILE_INITIATE,
});

export const createProfileSuccess = (data) => ({
  type: profileConstant.CREATE_PROFILE_SUCCESS,
  payload: data,
});

export const createProfileFailure = (data) => ({
  type: profileConstant.CREATE_PROFILE_FAILURE,
  payload: data,
});

export const CreateProfileAction = (companyId,payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(createProfileInitiate(payload));
    const response = await axios.post(`${Url}companies/${companyId}/users/`,payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status == 200) {
      dispatch(createProfileSuccess(response.data));
    } else {
      dispatch(createProfileFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(createProfileFailure(err));
    return err.response;
  }
};



export const contactDetailsInitiate = () => ({
  type: profileConstant.CONTACT_LIST_INITIATE,
});

export const contactDetailsSuccess = (data) => ({
  type: profileConstant.CONTACT_LIST_SUCCESS,
  payload: data,
});

export const contactDetailsFailure = (data) => ({
  type: profileConstant.CONTACT_LIST_FAILURE,
  payload: data,
});

export const ContactDetialsAction = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(contactDetailsInitiate());
    const response = await axios.get(`${Url}profileUser/conatctList`, {
      headers: { Authorization: `Bearer ${token}` },
      params: payload,
    });
    if (response.status == 200) {
      dispatch(contactDetailsSuccess(response.data));
    } else {
      dispatch(contactDetailsFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(contactDetailsFailure(err));
    return err.response;
  }
};





export const addContactInitiate = () => ({
  type: profileConstant.ADD_CONTACT_INITIATE,
});

export const addContactSuccess = (data) => ({
  type: profileConstant.ADD_CONTACT_SUCCESS,
  payload: data,
});

export const addContactFailure = (data) => ({
  type: profileConstant.ADD_CONTACT_FAILURE,
  payload: data,
});

export const addContactAction = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(addContactInitiate(payload));
    const response = await axios.post(`${Url}profileUser/addContact`,payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status == 201) {
      dispatch(addContactSuccess(response.data));
    } else {
      dispatch(addContactFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(addContactFailure(err));
    return err.response;
  }
};







export const updateContactInitiate = () => ({
  type: profileConstant.UPDATE_CONTACT_INITIATE,
});

export const updateContactSuccess = (data) => ({
  type: profileConstant.UPDATE_CONTACT_SUCCESS,
  payload: data,
});

export const updateContactFailure = (data) => ({
  type: profileConstant.UPDATE_CONTACT_FAILURE,
  payload: data,
});

export const UpdateContactAction = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(updateContactInitiate(payload));
    const response = await axios.post(`${Url}user/addContact`,payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status == 201) {
      dispatch(updateContactSuccess(response.data));
    } else {
      dispatch(updateContactFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(updateContactFailure(err));
    return err.response;
  }
};




export const contactListInitiate = () => ({
    type: profileConstant.CONTACT_LIST_INITIATE,
  });
  
  export const contactListSuccess = (data) => ({
    type: profileConstant.CONTACT_LIST_SUCCESS,
    payload: data,
  });
  
  export const contactListFailure = (data) => ({
    type: profileConstant.CONTACT_LIST_FAILURE,
    payload: data,
  });
  
  export const contactListAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(contactListInitiate());
      const response = await axios.get(`${Url}profileUser/conatctList`, {
        headers: { Authorization: `Bearer ${token}` },
        params: payload,
      });
      if (response.status == 201) {
        dispatch(contactListSuccess(response.data));
      } else {
        dispatch(contactListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(contactListFailure(err));
      return err.response;
    }
  };
  



  export const deleteContactInitiate = () => ({
    type: profileConstant.DELETE_CONTACT_INITIATE,
  });
  
  export const deleteContactSuccess = (data) => ({
    type: profileConstant.DELETE_CONTACT_SUCCESS,
    payload: data,
  });
  
  export const deleteContactFailure = (data) => ({
    type: profileConstant.DELETE_CONTACT_FAILURE,
    payload: data,
  });
  
  export const deleteContactAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(deleteContactInitiate());
      const response = await axios.get(`${Url}profileUser/deleteContact?id=${payload}`, {
        headers: { Authorization: `Bearer ${token}` },
        // params: payload,
      });
      if (response.status == 200) {
        dispatch(deleteContactSuccess(response.data));
      } else {
        dispatch(deleteContactFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(deleteContactFailure(err));
      return err.response;
    }
  };
  




  export const addVendorContactInitiate = () => ({
    type: profileConstant.ADD_CONTACT_INITIATE,
  });
  
  export const addVendorContactSuccess = (data) => ({
    type: profileConstant.ADD_CONTACT_SUCCESS,
    payload: data,
  });
  
  export const addVendorContactFailure = (data) => ({
    type: profileConstant.ADD_CONTACT_FAILURE,
    payload: data,
  });
  
  export const addVendorContactAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(addVendorContactInitiate(payload));
      const response = await axios.post(`${Url}profileUser/addContactVendorUser`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(addVendorContactSuccess(response.data));
      } else {
        dispatch(addVendorContactFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addVendorContactFailure(err));
      return err.response;
    }
  };
  
  



  export const addOtherContactInitiate = () => ({
    type: profileConstant.ADD_CONTACT_INITIATE,
  });
  
  export const addOtherContactSuccess = (data) => ({
    type: profileConstant.ADD_CONTACT_SUCCESS,
    payload: data,
  });
  
  export const addOtherContactFailure = (data) => ({
    type: profileConstant.ADD_CONTACT_FAILURE,
    payload: data,
  });
  
  export const addOtherContactAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(addOtherContactInitiate(payload));
      const response = await axios.post(`${Url}profileUser/addContactVendorUser`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(addOtherContactSuccess(response.data));
      } else {
        dispatch(addOtherContactFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addOtherContactFailure(err));
      return err.response;
    }
  };
  

  export const messageCountInitiate = () => ({
    type: profileConstant.GET_MESSAGE_COUNT_INITIATE,
  });
  
  export const messageCountSuccess = (data) => ({
    type: profileConstant.GET_MESSAGE_COUNT_SUCCESS,
    payload: data,
  });
  
  export const messageCountFailure = (data) => ({
    type: profileConstant.GET_MESSAGE_COUNT_FAILURE ,
    payload: data,
  });
  
  export const messageCountAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(messageCountInitiate());
      const response = await axios.get(`${Url}discussions/getMessagesCount?id=${iBuildLocalData?.user?.id}`, {
        headers: { Authorization: `Bearer ${token}` },
        params: payload,
      });
      if (response.status == 201) {
        dispatch(messageCountSuccess(response.data));
      } else {
        dispatch(messageCountFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(messageCountFailure(err));
      return err.response;
    }
  };
  




  

  export const updatemessageCountInitiate = () => ({
    type: profileConstant.UPDATE_MESSAGE_COUNT_INITIATE,
  });
  
  export const updatemessageCountSuccess = (data) => ({
    type: profileConstant.UPDATE_MESSAGE_COUNT_SUCCESS,
    payload: data,
  });
  
  export const updatemessageCountFailure = (data) => ({
    type: profileConstant.UPDATE_CONTACT_FAILURE ,
    payload: data,
  });
  
  export const updatemessageCountAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(updatemessageCountInitiate());
      const response = await axios.get(`${Url}discussions/messageCountUpdate?id=${iBuildLocalData?.user?.id}`, {
        headers: { Authorization: `Bearer ${token}` },
        params: payload,
      });
      if (response.status == 201) {
        dispatch(updatemessageCountSuccess(response.data));
      } else {
        dispatch(updatemessageCountFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updatemessageCountFailure(err));
      return err.response;
    }
  };
  


 