export function isLoggedIn(userType) {
  const iBuildAdminData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const clientViewData = JSON.parse(localStorage.getItem("clientViewLogin"));
  const tradeViewData = JSON.parse(localStorage.getItem("tradeViewLogin"));
  const ivestorViewData = JSON.parse(localStorage.getItem("investorViewLogin"));

    let session = getObject("iBuildAdminData") || {};
    session = Object.keys(session).length && JSON.parse(session);
    let accessToken = (session.user && session.user["token"]) || "";

    

    let session1 = getObject("clientViewLogin") || {};
    session1 = Object.keys(session1).length && JSON.parse(session1);
    let accessToken1 = (session1.user && session1.user["token"]) || "";
 

    let session2 = getObject("tradeViewLogin") || {};
    session2 = Object.keys(session2).length && JSON.parse(session2);
    let accessToken2 = (session2.user && session2.user["token"]) || "";
   

   

    let session3 = getObject("investorViewLogin") || {};
    session3 = Object.keys(session3).length && JSON.parse(session3);
    let accessToken3 = (session3.user && session3.user["token"]) || "";
  return  iBuildAdminData !== null ? accessToken : clientViewData !== null ? accessToken1 : tradeViewData !== null ? accessToken2 :ivestorViewData !== null ? accessToken3:""
    // return accessToken1 ?? accessToken ?? accessToken2;



  }
  export function getObject(key) {
    if (window && window.localStorage) {
      return (localStorage.getItem(key));
    }
  }