import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import {
  AddConstructionCategoryAction,
  AddProjectProfileAction,
  ConstructionCategoryListAction,
  DeleteConstructionCategoryAction,
  DeleteProjectProfileAction,
  ProjectProfileDetailsAction,
  ProjectProfileListAction,
  SearchConstructionCategoryAction,
  SearchProjectProfileListAction,
  UpdateConstructionCategoryAction,
  UpdateProjectProfileAction,
  MasterConstructionCategoryListAction,
  AddMasterProjectCategoryAction,
  ConstructionTaskListAction,
  MasterTaskListAction,
  addManualPhaseAction
} from "../../redux/action/adminConstructionAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const AdminConstructor = ({adminConstructionStatus}) => {
  const dispatch = useDispatch();
  const [projectProfileDetails, setProjectProfileDetails] = useState(null);
  const [constructionCategoryDetails, setConstructionCategoryDetails] =
    useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const [taskList, setTaskList] =useState([])
  const [selectedProjectProfile, setSelectedProjectProfile] = useState(null);
  const [selectedPhaseId,setSelectedPhaseId] = useState("")

  const [constructionProfileCategory, UpdateConstructionProfileCategory] =
    useState({ projectTypeDescription: "", projectTypeDescriptionError: "" });

  const [constructionCategory, UpdateConstructionCategory] = useState({
    projectTypeId: "",
    projectTypeName: "",
    description: "",
    constructionCategoryError: "",
  });

  const [masterConstructionCategory, UpdateMasterConstructionCategory] = useState({
    projectTypeId: "",
    projectTypeName: "",
    description: "",
    masterConstructionCategoryError: "",
  });


  const { constructionProjectProfileList, constructionCategoryList,constructionTaskList ,constructionMasterTaskList} =
    useSelector((state) => state.adminConstructionReducer);
  const [AddProfileModal, setAddProfile] = useState(false);
  const handleAddProfileModalClose = () => setAddProfile(false);
  const handleAddProfileModalShow = () => {
    setAddProfile(true);
  };

  const [UpdateProfileModal, setUpdateProfile] = useState(false);
  const handleUpdateProfileModalClose = () => setUpdateProfile(false);
  const handleUpdateProfileModalShow = (item) => {
    setProjectProfileDetails(item);
    UpdateConstructionProfileCategory({
      ...constructionProfileCategory,
      projectTypeDescription: item.projectTypeDescription,
    });

    setUpdateProfile(true);
  };

  const [AddTaskModal, setAddTask] = useState(false);
  const handleAddTaskModalClose = () => setAddTask(false);
  const handleAddTaskModalShow = (item) => {
    setAddTask(true);
  };

  const [ProfileDetailsModal, ProfileDetails] = useState(false);
  const handleProfileDetailsModalClose = () => ProfileDetails(false);
  const handleProfileDetailsModalShow = (item) => {
    setProjectProfileDetails(item);
    ProfileDetails(true);
  };

  const [AddConstructionCategoryModal, setAddConstructionCategory] =
    useState(false);
  const handleAddConstructionCategoryModalClose = () =>
    setAddConstructionCategory(false);
  const handleAddConstructionCategoryModalShow = () => {
    setAddConstructionCategory(true);
  };


  const [AddMasterConstructionCategoryModal, setAddMasterConstructionCategory] =
  useState(false);
  const handleAddMasterConstructionCategoryModalClose = () =>
  setAddMasterConstructionCategory(false);
  const handleAddMasterConstructionCategoryModalShow = () => {
  setAddMasterConstructionCategory(true);
  };

  const [UpdateConstructionCategoryModal, setUpdateConstructionCategory] =
    useState(false);
  const handleUpdateConstructionCategoryModalClose = () =>
    setUpdateConstructionCategory(false);
  const handleUpdateConstructionCategoryModalShow = (item) => {
    setUpdateConstructionCategory(true);
    setConstructionCategoryDetails(item)
    UpdateConstructionCategory({
      ...constructionCategory,
      description: item.description,
      projectTypeName:item.projectTypeName
    });

  };

  const [ConstructionCategoryDetailsModal, ConstructionCategoryDetails] =
    useState(false);
  const handleConstructionCategoryDetailsModalClose = () =>
    ConstructionCategoryDetails(false);
  const handleConstructionCategoryDetailsModalShow = (item) => {
    setConstructionCategoryDetails(item);
    ConstructionCategoryDetails(true);
  };

  let handleValidationConstructionProfileCategory = () => {
    let errorMsg = "";
    let formIsValid = true;

    if (constructionProfileCategory.projectTypeDescription == "") {
      errorMsg = "* Please enter category name";
      formIsValid = false;
    }

    UpdateConstructionProfileCategory({
      ...constructionProfileCategory,
      projectTypeDescriptionError: errorMsg,
    });
    return formIsValid;
  };

  const handleSubmitConstructionProfileCategory = () => {
    let formIsValid = handleValidationConstructionProfileCategory();

    if (formIsValid) {
      dispatch(
        AddProjectProfileAction({
          projectTypeDescription:
            constructionProfileCategory.projectTypeDescription,
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(ProjectProfileListAction());

            setAddProfile(false);
            toast.success(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  useEffect(() => {
    dispatch(ProjectProfileListAction());
    dispatch(ProjectProfileDetailsAction("1"));
    dispatch(MasterConstructionCategoryListAction());

  }, [adminConstructionStatus]);

  const handleSearchProjectProfile = (e) => {
    dispatch(SearchProjectProfileListAction({ search: e.target.value }));
  };

  const handleDeleteProjectProfile = (id) => {
    dispatch(DeleteProjectProfileAction(id))
      .then((res) => {
        if (res.status == 201) {
          dispatch(ProjectProfileListAction());

          toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleUpdateConstructionProfileCategory = () => {
    let formIsValid = handleValidationConstructionProfileCategory();

    if (formIsValid) {
      dispatch(
        UpdateProjectProfileAction({
          projectTypeDescription:
            constructionProfileCategory.projectTypeDescription,
          id: projectProfileDetails.id,
        })
      ).then((res) => {
        if (res.status == 201) {
          setUpdateProfile(false);
          dispatch(ProjectProfileListAction());

          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  let handleValidationConstructionCategory = () => {
    let errorMsg = "";
    let formIsValid = true;

    if (constructionCategory.description == "") {
      errorMsg = "* Please enter description";
      formIsValid = false;
    }

    UpdateConstructionCategory({
      ...constructionCategory,
      constructionCategoryError: errorMsg,
    });
    return formIsValid;
  };

  const handleSubmitConstructionCategory = () => {
    let formIsValid = handleValidationConstructionCategory();

    if (formIsValid) {
      dispatch(
        addManualPhaseAction({
          projectTypeName: constructionCategory.projectTypeName,
          projectTypeId: constructionCategory.projectTypeId,
          phaseName: constructionCategory.description,
        })
      ).then((res) => {
        if (res.status == 201) {
          setAddConstructionCategory(false);
          UpdateConstructionCategory({
            projectTypeId: "",
            projectTypeName: "",
            description: "",
            constructionCategoryError: "",
          });
          dispatch(ConstructionCategoryListAction());

          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const handleSearchConstructionCategory = (e) => {
    dispatch(SearchConstructionCategoryAction({ search: e.target.value }));
  };


  const handleDeleteConstructionCategory = (id) => {
    dispatch(DeleteConstructionCategoryAction(id))
      .then((res) => {
        if (res.status == 201) {
          dispatch(ConstructionCategoryListAction());

          toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };


  const handleUpdateConstructionCategory = () => {
    let formIsValid = handleValidationConstructionCategory();

    if (formIsValid) {
      dispatch(
        UpdateConstructionCategoryAction({
          projectTypeName: constructionCategory.projectTypeName,
          description:
          constructionCategory.description,
          id: constructionCategoryDetails.projectTypeId,
        })
      ).then((res) => {
        if (res.status == 201) {
          setUpdateConstructionCategory(false);
          dispatch(ConstructionCategoryListAction());

          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };


  let handleValidationConstructionMasterCategory = () => {
    let errorMsg = "";
    let formIsValid = true;

    if (masterConstructionCategory.description == "") {
      errorMsg = "* Please enter description";
      formIsValid = false;
    }

    UpdateMasterConstructionCategory({
      ...masterConstructionCategory,
      masterConstructionCategoryError: errorMsg,
    });
    return formIsValid;
  };


  const handleSubmitMasterConstructionCategory = () => {
    let formIsValid = handleValidationConstructionMasterCategory();

    if (formIsValid) {
      dispatch(
        AddMasterProjectCategoryAction({
          projectTypeName: masterConstructionCategory.projectTypeName,
          projectTypeId: masterConstructionCategory.projectTypeId,
          description: masterConstructionCategory.description,
        })
      ).then((res) => {
        if (res.status == 201) {
          setAddMasterConstructionCategory(false);
          UpdateMasterConstructionCategory({
            projectTypeId: "",
            projectTypeName: "",
            description: "",
            masterConstructionCategoryError: "",
          });
          dispatch(MasterConstructionCategoryListAction());

          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };



  useEffect(()=>{
if(selectedProjectProfile)
    dispatch(ConstructionCategoryListAction({id:selectedProjectProfile?.id}));
  },[selectedProjectProfile])






  useEffect(()=>{


      let temp = constructionCategoryList?.[0]?.StaticPhases
        ?.map((item) => {
          return item?.Statictasks?.flat();
        })
        ?.flat();
  
      setTaskList(temp);
  
  


  },[constructionCategoryList])


  useEffect(()=>{
    if(isEdit){

      dispatch(ConstructionTaskListAction())
      dispatch(MasterTaskListAction())


    }


  },[isEdit])








  return (
    <>
    <div
    class="tab-pane fade show"
    id="ConstructionTab"
  >
    <div class="ResidentialJobArea ViewConfigArea">
      <div class="ModalAccordian">
        <div id="accordion">
          <div class="card">
            <div class="card-header">
              <h4
                data-toggle="collapse"
                href="#CostCodeProfileSetupTabs"
                class=""
                aria-expanded="true"
              >
                <span>
                  <img
                    src={require("../../assets/images/construction_apps.png")}
                  />
                </span>{" "}
                Construction Schedule Setup
              </h4>
              <a
                onClick={() => {
                  isEdit ? setIsEdit(false) : setIsEdit(true);
                }}
                class="Edit EditViewConfigBodyBtn"
              >
                <img
                  src={require("../../assets/images/Action-1.png")}
                />
              </a>
            </div>
            <div
              id="CostCodeProfileSetupTabs"
              class="collapse show"
              data-parent="#accordion"
            >
              <div class="card-body">
                <div
                  class="ViewConfigBodyMain"
                  style={{ display: "none" }}
                >
                  <div class="ViewConfigBody">
                    <aside class="w-100">
                      <div class="Tablewidth-50">
                        <div class="ModalBigheading">
                          <h3>
                            <span>Construction Profile: </span>
                            {constructionCategoryList?.[0]?.projectTypeDescription}
                          </h3>
                          <h3>
                            <span>
                              Construction Project Profile Options
                            </span>
                          </h3>
                        </div>
                        <div class="FilesArea">
                          <div class="FilesHead  justify-content-end mb-2">
                            <div class="FilesLeft">
                              <aside class="mr-0">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Search"
                                />
                                <span>
                                  <img src="images/search.png" />
                                </span>
                              </aside>
                            </div>
                          </div>
                        </div>
                        <div class="MainProjectProfilesArea">
                          <ul>
                            <li>
                              <a href="javascript:void(0);">
                                Land Acquisition &amp; Admin
                              </a>
                              <div class="dropdown">
                                <span
                                  class="Dots"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img src="images/dots.png" />
                                </span>
                                <div
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <a
                                    class="dropdown-item"
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#ConstructionProjectProfileOptionDetails"
                                  >
                                    {" "}
                                    <img src="images/view-po.png" />
                                    View Details
                                  </a>
                                </div>
                              </div>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                Land Development
                              </a>
                              <div class="dropdown">
                                <span
                                  class="Dots"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img src="images/dots.png" />
                                </span>
                                <div
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <a
                                    class="dropdown-item"
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#ConstructionProjectProfileOptionDetails"
                                  >
                                    {" "}
                                    <img src="images/view-po.png" />
                                    View Details
                                  </a>
                                </div>
                              </div>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                class="active"
                              >
                                Blocks
                              </a>
                              <div class="dropdown">
                                <span
                                  class="Dots" 
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img src="images/dots.png" />
                                </span>
                                <div
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <a
                                    class="dropdown-item"
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#ConstructionProjectProfileOptionDetails"
                                  >
                                    {" "}
                                    <img src="images/view-po.png" />
                                    View Details
                                  </a>
                                </div>
                              </div>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                Other Common Buildings
                              </a>
                              <div class="dropdown">
                                <span
                                  class="Dots"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img src="images/dots.png" />
                                </span>
                                <div
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <a
                                    class="dropdown-item"
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#ConstructionProjectProfileOptionDetails"
                                  >
                                    {" "}
                                    <img src="images/view-po.png" />
                                    View Details
                                  </a>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div class="Button d-flex justify-content-between align-items-center mt-4">
                          <button
                            class="Create"
                            data-toggle="modal"
                          >
                            Add New Profile Option
                          </button>
                        </div>
                        <div class="ModalBigheading mt-5">
                          <h3>
                            <span>Construction Categories</span>
                          </h3>
                        </div>
                        <div class="FilesArea">
                          <div class="FilesHead  justify-content-end mb-2">
                            <div class="FilesLeft">
                              <aside class="mr-0">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Search"
                                />
                                <span>
                                  <img src="images/search.png" />
                                </span>
                              </aside>
                            </div>
                          </div>
                        </div>
                        <div class="MainProjectProfilesArea">
                          <ul>
                            <li>
                              <a href="javascript:void(0);">
                                Block(s) Interior Units
                              </a>
                              <div class="dropdown">
                                <span
                                  class="Dots"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img src="images/dots.png" />
                                </span>
                                <div
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <a
                                    class="dropdown-item"
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#ConstructionProfileCategoryDetails"
                                  >
                                    {" "}
                                    <img src="images/view-po.png" />
                                    View Details
                                  </a>
                                </div>
                              </div>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                Block(s) Interior Common Areas
                              </a>
                              <div class="dropdown">
                                <span
                                  class="Dots"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img src="images/dots.png" />
                                </span>
                                <div
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <a
                                    class="dropdown-item"
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#ConstructionProfileCategoryDetails"
                                  >
                                    {" "}
                                    <img src="images/view-po.png" />
                                    View Details
                                  </a>
                                </div>
                              </div>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                Other Common Buildings
                              </a>
                              <div class="dropdown">
                                <span
                                  class="Dots"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img src="images/dots.png" />
                                </span>
                                <div
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <a
                                    class="dropdown-item"
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#ConstructionProfileCategoryDetails"
                                  >
                                    {" "}
                                    <img src="images/view-po.png" />
                                    View Details
                                  </a>
                                </div>
                              </div>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                Block(s) Interior Common Areas
                              </a>
                              <div class="dropdown">
                                <span
                                  class="Dots"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img src="images/dots.png" />
                                </span>
                                <div
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <a
                                    class="dropdown-item"
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#ConstructionProfileCategoryDetails"
                                  >
                                    {" "}
                                    <img src="images/view-po.png" />
                                    View Details
                                  </a>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div class="Button d-flex justify-content-between align-items-center mt-4">
                          <button
                            class="Create"
                            data-toggle="modal"
                            data-target="#AddNewConstructionCategory"
                          >
                            Add New Category
                          </button>
                        </div>
                      </div>
                      <div class="ModalBigheading mt-5 d-flex align-items-center justify-content-between">
                        <h3>
                          <span>Task</span>
                        </h3>
                        <div class="FilesArea">
                          <div class="FilesHead  justify-content-end mb-2">
                            <div class="FilesLeft">
                              <aside class="mr-0">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Search"
                                />
                                <span>
                                  <img src="images/search.png" />
                                </span>
                              </aside>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="MainProjectProfilesArea WhiteSpace"
                        style={{ width: "100%" }}
                      >
                        <table>
                          <thead>
                            <tr>
                              <th>Phase</th>
                              <th>Task Name</th>
                              <th>Cost Code / Vendor</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                Paint, Staage-1 Fishing, Interior
                                Railings
                              </td>
                              <td>
                                ***Sweep-vac Prior To Painting -
                                Site Labor
                              </td>
                              <td>1801.10</td>
                              <td>
                                <div class="dropdown">
                                  <span
                                    class="Dots"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/dots.png" />
                                  </span>
                                  <div
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <a
                                      class="dropdown-item"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#TaskDetails"
                                    >
                                      <img src="images/view-po.png" />
                                      View Details
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Paint, Staage-1 Fishing, Interior
                                Railings
                              </td>
                              <td>
                                ***Sweep-vac Prior To Painting -
                                Site Labor
                              </td>
                              <td>1801.14</td>
                              <td>
                                <div class="dropdown">
                                  <span
                                    class="Dots"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/dots.png" />
                                  </span>
                                  <div
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <a
                                      class="dropdown-item"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#TaskDetails"
                                    >
                                      <img src="images/view-po.png" />
                                      View Details
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="Button d-flex justify-content-between align-items-center mt-4">
                        <button
                          class="Create"
                          data-toggle="modal"
                          data-target="#AddNewTask"
                        >
                          Add Phase/Task
                        </button>
                      </div>
                    </aside>
                  </div>
                </div>
                <div
                  class="ViewConfigBodyHideMain"
                  style={{ display: "block" }}
                >
                  <div class="ViewConfigBodyHide flex-column"></div>
                  <aside class="w-100 ml-0">
                    <div class="Tablewidth-50">
                      <div class="ModalBigheading">
                        <h3>
                          <span>Construction Profile: </span>
                          {constructionCategoryList?.[0]?.projectTypeDescription}
                        </h3>
                        <h3>
                          <span>
                            Construction Project Profile Options
                          </span>
                        </h3>
                      </div>
                      <div class="FilesArea">
                        <div class="FilesHead  justify-content-end mb-2">
                          <div class="FilesLeft">
                            <aside class="mr-0">
                              <input
                                type="text"
                                onChange={
                                  handleSearchProjectProfile
                                }
                                class="form-control"
                                placeholder="Search"
                              />
                              <span>
                                <img
                                  src={require("../../assets/images/search.png")}
                                />
                              </span>
                            </aside>
                          </div>
                        </div>
                      </div>
                      <div class="MainProjectProfilesArea">
                        <ul>
                          {constructionProjectProfileList?.map(
                            (item) => (
                              <li>
                                <a
                                  className={
                                    selectedProjectProfile?.id ==
                                    item?.id
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() => {
                                    setSelectedProjectProfile(item);
                                    UpdateConstructionCategory({
                                      ...constructionCategory,
                                      projectTypeName:
                                        item?.projectTypeDescription,
                                      projectTypeId: item?.id,
                                    });
                                    UpdateMasterConstructionCategory({
                                      ...masterConstructionCategory,
                                      projectTypeName:
                                        item?.projectTypeDescription,
                                      projectTypeId: item?.id,
                                    });
                                  }}
                                >
                                  {item?.projectTypeDescription}
                                </a>
                                <div class="dropdown">
                                  <span
                                    class="Dots"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img
                                      src={require("../../assets/images/dots.png")}
                                    />
                                  </span>
                                  <div
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <a
                                      class="dropdown-item"
                                      onClick={() =>
                                        handleProfileDetailsModalShow(
                                          item
                                        )
                                      }
                                    >
                                      {" "}
                                      <img
                                        src={require("../../assets/images/view-po.png")}
                                      />
                                      View Details
                                    </a>
                                    {isEdit && (
                                      <a
                                        class="dropdown-item"
                                        onClick={() =>
                                          handleUpdateProfileModalShow(
                                            item
                                          )
                                        }
                                      >
                                        <img
                                          src={require("../../assets/images/Action-1.png")}
                                        />
                                        Modify
                                      </a>
                                    )}
                                    {isEdit && (
                                      <a
                                        class="dropdown-item"
                                        onClick={() =>
                                          handleDeleteProjectProfile(
                                            item?.id
                                          )
                                        }
                                      >
                                        {" "}
                                        <img
                                          src={require("../../assets/images/dropdown-delete.png")}
                                        />
                                        Delete
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                      <div class="Button d-flex justify-content-between align-items-center mt-4">
                        <button
                          class="Create"
                          data-toggle="modal"
                          onClick={handleAddProfileModalShow}
                        >
                          Add New Profile Option
                        </button>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between ">
                      <div class="w-100">
                        <div class="ModalBigheading mt-5">
                          <h3>
                            <span>Construction Categories</span>
                          </h3>
                        </div>
                        <div class="FilesArea">
                          <div class="FilesHead  justify-content-end mb-2">
                            <div class="FilesLeft">
                              <aside class="mr-0">
                                <input

                                 onChange={handleSearchConstructionCategory}
                                  type="text"
                                  class="form-control"
                                  placeholder="Search"
                                />
                                <span>
                                  <img
                                    src={require("../../assets/images/search.png")}
                                  />
                                </span>
                              </aside>
                            </div>
                          </div>
                        </div>
                        <div class="MainProjectProfilesArea">
                          <ul>
                            {constructionCategoryList?.[0]?.StaticPhases?.map(
                              (item) => (
                                <li>
                                  <a
                                  onClick={()=>setSelectedPhaseId(item?.id)}
                                  >
                                    {item?.phaseName}
                                  </a>
                                  <div class="dropdown">
                                    <span
                                      class="Dots"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img
                                        src={require("../../assets/images/dots.png")}
                                      />
                                    </span>
                                    <div
                                      class="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      <a
                                        class="dropdown-item"
                                        onClick={() =>
                                          handleConstructionCategoryDetailsModalShow(
                                            item
                                          )
                                        }
                                      >
                                        {" "}
                                        <img
                                          src={require("../../assets/images/view-po.png")}
                                        />
                                        View Details
                                      </a>
                                      {isEdit && (
                                        <a
                                          class="dropdown-item"
                                          onClick={() =>
                                            handleUpdateConstructionCategoryModalShow(
                                              item
                                            )
                                          }
                                        >
                                          <img
                                            src={require("../../assets/images/Action-1.png")}
                                          />
                                          Modify
                                        </a>
                                      )}
                                      {isEdit && (
                                        <a
                                          class="dropdown-item"
                                          onClick={() =>
                                            handleDeleteConstructionCategory(
                                              item?.id
                                            )
                                          }
                                        >
                                          {" "}
                                          <img
                                            src={require("../../assets/images/dropdown-delete.png")}
                                          />
                                          Delete
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                        <div class="Button d-flex justify-content-between align-items-center mt-4">
                          <button
                            onClick={
                              handleAddConstructionCategoryModalShow
                            }
                            class="Create"
                          >
                            Add New Category
                          </button>
                        </div>
                      </div>
                      {isEdit && (
                        <div class="Button d-flex flex-column align-items-center justify-content-center mx-4">
                          <button class="Create mb-2">Add</button>
                          <button class="Outline my-2">
                            Remove
                          </button>
                        </div>
                      )}
                      {isEdit && (
                        <div class="w-100">
                          <div class="ModalBigheading mt-5">
                            <h3>
                              <span>Master File </span>Construction
                              Categories List
                            </h3>
                          </div>
                          <div class="FilesArea">
                            <div class="FilesHead  justify-content-end mb-2">
                              <div class="FilesLeft">
                                <aside class="mr-0">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search"
                                  />
                                  <span>
                                    <img src="images/search.png" />
                                  </span>
                                </aside>
                              </div>
                            </div>
                          </div>
                          <div
                            class="MainProjectProfilesArea Red DropwdownCss"
                            style={{ "min-height": "200px" }}
                          >
                            <ul>
                              <li>
                                <a href="javascript:void(0);">
                                  Schedule 1
                                </a>
                                <div class="dropdown">
                                  <span
                                    class="Dots"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/dots.png" />
                                  </span>
                                  <div
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <a
                                      class="dropdown-item"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#ConstructionProfileCategoryDetails"
                                    >
                                      {" "}
                                      <img src="images/dropdown-delete.png" />
                                      Delete
                                    </a>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="Button d-flex justify-content-between align-items-center mt-4">
                            <button
                              class="Create"
                              onClick={handleAddMasterConstructionCategoryModalShow}
                            >
                              Add New Category
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        overflowX: "scroll",
                        display: "flex",
                      }}
                      class="pb-4"
                    >
                      <div class="w-100">
                        <div class="ModalBigheading mt-5 d-flex align-items-center justify-content-between">
                          <div>
                            <h3>
                              <span>Task</span>
                            </h3>
                            <h3>
                              <span>Active</span>Construction
                              Schedule Tasks
                            </h3>
                          </div>
                          <div class="FilesArea">
                            <div class="FilesHead  justify-content-end mb-2">
                              <div class="FilesLeft">
                                <aside class="mr-0">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search"
                                  />
                                  <span>
                                    <img
                                      src={require("../../assets/images/search.png")}
                                    />
                                  </span>
                                </aside>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="MainProjectProfilesArea DropdownShow WhiteSpace"
                          style={{ width: "100%" }}
                        >
                          <table>
                            <thead>
                              <tr>
                                <th>Description</th>
                                <th>Task Name</th>
                                <th>Cost Code / Vendor</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {taskList?.filter((task)=> task?.phaseId==selectedPhaseId)?.map((item)=>
                                   <tr>
                                   <td>
                                     Paint, Staage-1 Fishing, Interior
                                     Railings
                                   </td>
                                   <td>{item?.taskName}
                                   </td>
                                   <td>{item?.coast_code}</td>
                                   <td>
                                     <div class="dropdown">
                                       <span
                                         class="Dots"
                                         data-toggle="dropdown"
                                         aria-haspopup="true"
                                         aria-expanded="false"
                                       >
                                         <img
                                           src={require("../../assets/images/dots.png")}
                                         />
                                       </span>
                                       <div
                                         class="dropdown-menu"
                                         aria-labelledby="dropdownMenuButton"
                                       >
                                         <a
                                           class="dropdown-item"
                                           href="javascript:void(0);"
                                           data-toggle="modal"
                                           data-target="#TaskDetails"
                                         >
                                           <img
                                             src={require("../../assets/images/view-po.png")}
                                           />
                                           View Details
                                         </a>
                                         <a
                                           class="dropdown-item"
                                           href="javascript:void(0);"
                                           data-toggle="modal"
                                           data-target="#UpdateTaskDetails"
                                         >
                                           <img
                                             src={require("../../assets/images/Action-1.png")}
                                           />
                                           Modify
                                         </a>
                                         <a
                                           class="dropdown-item"
                                           href="javascript:void(0);"
                                         >
                                           <img
                                             src={require("../../assets/images/dropdown-delete.png")}
                                           />
                                           Delete
                                         </a>
                                       </div>
                                     </div>
                                   </td>
                                 </tr>
                              )}
                         
                            </tbody>
                          </table>
                        </div>
                        <div class="Button d-flex justify-content-between align-items-center mt-4">
                          <button
                            class="Create"
                            onClick={handleAddTaskModalShow}
                          >
                            Add Task
                          </button>
                        </div>
                      </div>
                      {isEdit && (
                        <div class="Button d-flex flex-column align-items-center justify-content-center mx-4">
                          <button class="Create mb-2">Add</button>
                          <button class="Outline my-2">
                            Remove
                          </button>
                        </div>
                      )}
                      {isEdit && (
                        <div class="w-100">
                          <div class="ModalBigheading mt-5 d-flex align-items-center justify-content-between">
                            <div>
                              <h3>
                                <span>Task</span>
                              </h3>
                              <h3>
                                <span>Active</span>Construction
                                Schedule Tasks
                              </h3>
                            </div>
                            <div class="FilesArea">
                              <div class="FilesHead  justify-content-end mb-2">
                                <div class="FilesLeft">
                                  <aside class="mr-0">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Search"
                                    />
                                    <span>
                                      <img src="images/search.png" />
                                    </span>
                                  </aside>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            class="MainProjectProfilesArea DropdownShow  WhiteSpace"
                            style={{ width: "100%" }}
                          >
                            <table>
                              <thead>
                                <tr>
                                  <th>Description</th>
                                  <th>Task Name</th>
                                  <th>Cost Code / Vednor</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
{/* <tr class="Red">
                                  <td>
                                    Paint, Staage-1 Fishing,
                                    Interior Railings
                                  </td>
                                  <td>
                                    ***Sweep-vac Prior To Painting -
                                    Site Labor
                                  </td>
                                  <td>1301.02</td>
                                  <td>
                                    <div class="dropdown">
                                      <span
                                        class="Dots"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <img src="images/dots.png" />
                                      </span>
                                      <div
                                        class="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                          data-toggle="modal"
                                          data-target="#TaskDetails"
                                        >
                                          <img src="images/dropdown-delete.png" />
                                          Delete
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                </tr> */}
{constructionTaskList?.map((task)=> 
   <tr>
   <td>
     {task?.description}
   </td>
   <td>
    {task?.projectTypeName}
   </td>
   <td>{task?.costCode}</td>
   <td>
     <div class="dropdown">
       <span
         class="Dots"
         data-toggle="dropdown"
         aria-haspopup="true"
         aria-expanded="false"
       >
         <img src="images/dots.png" />
       </span>
       <div
         class="dropdown-menu"
         aria-labelledby="dropdownMenuButton"
       >
         <a
           class="dropdown-item"
           href="javascript:void(0);"
           data-toggle="modal"
           data-target="#TaskDetails"
         >
           <img src="images/dropdown-delete.png" />
           Delete
         </a>
       </div>
     </div>
   </td>
 </tr>
)}

                             
                              
                              </tbody>
                            </table>
                          </div>

                          <div class="Button d-flex justify-content-between align-items-center mt-4">
                            <button
                              class="Create"
                              data-toggle="modal"
                              data-target="#AddNewTask"
                            >
                              Add Task
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 

  <Modal
          show={AddProfileModal}
          onHide={handleAddProfileModalClose}
          className="ModalBox MediumModal"
        >
          <Modal.Body>
            <div class="Decline">
              <a onClick={handleAddProfileModalClose} class="CloseModal">
                ×
              </a>
              <div class="FormArea">
                <div class="ModalTitleBox">
                  <h4>Add New Construction Project Profile Option</h4>
                </div>
                <div class="FolderForm">
                  <form>
                    <div class="row">
                      <div class="col-md-7">
                        <div class="form-group">
                          <h6>
                            Construction Project Profile Option Description{" "}
                            <span class="required">*</span>
                          </h6>
                          <input
                            type="text"
                            class="form-control"
                            value={
                              constructionProfileCategory.projectTypeDescription
                            }
                            onChange={(e) =>
                              UpdateConstructionProfileCategory({
                                ...constructionProfileCategory,
                                projectTypeDescription: e.target.value,
                              })
                            }
                            placeholder="Construction Project Profile Option Description *"
                          />
                          {constructionProfileCategory.projectTypeDescriptionError &&
                            !constructionProfileCategory.projectTypeDescription && (
                              <p style={{ color: "red" }}>
                                {
                                  constructionProfileCategory.projectTypeDescriptionError
                                }
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                    <div class="Button d-flex justify-content-between mt-3">
                      <button
                        class="Create"
                        data-dismiss="modal"
                        onClick={handleSubmitConstructionProfileCategory}
                      >
                        Create New Profile Option
                      </button>
                      <button
                        class="Outline"
                        data-dismiss="modal"
                        onClick={handleAddProfileModalClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={UpdateProfileModal}
          onHide={handleAddProfileModalClose}
          className="ModalBox MediumModal"
        >
          <Modal.Body>
            <div class="Decline">
              <a onClick={handleUpdateProfileModalClose} class="CloseModal">
                ×
              </a>
              <div class="FormArea">
                <div class="ModalTitleBox">
                  <h4>Update Construction Project Profile Option</h4>
                </div>
                <div class="FolderForm">
                  <form>
                    <div class="row">
                      <div class="col-md-7">
                        <div class="form-group">
                          <h6>
                            Construction Project Profile Option Description{" "}
                            <span class="required">*</span>
                          </h6>
                          <input
                            type="text"
                            class="form-control"
                            value={
                              constructionProfileCategory.projectTypeDescription
                            }
                            onChange={(e) =>
                              UpdateConstructionProfileCategory({
                                ...constructionProfileCategory,
                                projectTypeDescription: e.target.value,
                              })
                            }
                            placeholder="Construction Project Profile Option Description *"
                          />
                          {constructionProfileCategory.projectTypeDescriptionError &&
                            !constructionProfileCategory.projectTypeDescription && (
                              <p style={{ color: "red" }}>
                                {
                                  constructionProfileCategory.projectTypeDescriptionError
                                }
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                    <div class="Button d-flex justify-content-between mt-3">
                      <button
                        class="Create"
                        data-dismiss="modal"
                        onClick={handleUpdateConstructionProfileCategory}
                      >
                        Update Profile Option
                      </button>
                      <button
                        class="Outline"
                        data-dismiss="modal"
                        onClick={handleUpdateProfileModalClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={ProfileDetailsModal}
          onHide={handleProfileDetailsModalClose}
          className="ModalBox MediumModal"
        >
          <Modal.Body>
            <div class="Decline">
              <a onClick={handleProfileDetailsModalClose} class="CloseModal">
                ×
              </a>
              <div class="FormArea">
                <div class="ModalTitleBox">
                  <h4>Construction Project Profile Details</h4>
                </div>
                <div class="FolderForm ParagraphMargin">
                  <form>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <h6>Construction Project Profile Description</h6>
                          <p>{projectProfileDetails?.projectTypeDescription}</p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <h6>Last Update</h6>
                          <p>
                            {moment(projectProfileDetails?.updatedAt).format(
                              "ll"
                            )}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <h6>Updated By</h6>
                          <p>John Doe</p>
                        </div>
                      </div>
                    </div>
                    <div class="Button text-center mt-5">
                      <button
                        class="Outline"
                        data-dismiss="modal"
                        onClick={handleProfileDetailsModalClose}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={AddConstructionCategoryModal}
          onHide={handleAddConstructionCategoryModalClose}
          className="ModalBox MediumModal"
        >
          <Modal.Body>
            <div class="Decline">
              <a
                onClick={handleAddConstructionCategoryModalClose}
                class="CloseModal"
              >
                ×
              </a>
              <div class="FormArea">
                <div class="ModalTitleBox">
                  <h4>Add New Construction Category</h4>
                </div>
                <div class="FolderForm">
                  <form>
                    <div class="row">
                      <div class="col-md-7">
                        <div class="form-group">
                          <h6>Construction Project Profile Options</h6>
                          <input
                            type="text"
                            class="form-control"
                            value={constructionCategory.projectTypeName}
                            onChange={(e) =>
                              UpdateConstructionCategory({
                                ...constructionCategory,
                                projectTypeDescription: e.target.value,
                              })
                            }
                            placeholder="Construction Project ConstructionCategory Option Description *"
                          />
                        </div>
                        <div class="form-group">
                          <h6>
                            Construction Category Description{" "}
                            <span class="required">*</span>
                          </h6>
                          <input
                            type="text"
                            class="form-control"
                            value={constructionCategory.description}
                            onChange={(e) =>
                              UpdateConstructionCategory({
                                ...constructionCategory,
                                description: e.target.value,
                              })
                            }
                            placeholder="Construction Project ConstructionCategory Option Description *"
                          />
                          {constructionCategory.constructionCategoryError &&
                            !constructionCategory.description && (
                              <p style={{ color: "red" }}>
                                {constructionCategory.constructionCategoryError}
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                    <div class="Button d-flex justify-content-between mt-3">
                      <button
                        class="Create"
                        data-dismiss="modal"
                        onClick={handleSubmitConstructionCategory}
                      >
                        Create New Construction Category
                      </button>
                      <button
                        class="Outline"
                        data-dismiss="modal"
                        onClick={handleAddConstructionCategoryModalClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={UpdateConstructionCategoryModal}
          onHide={handleAddConstructionCategoryModalClose}
          className="ModalBox MediumModal"
        >
          <Modal.Body>
            <div class="Decline">
              <a
                onClick={handleUpdateConstructionCategoryModalClose}
                class="CloseModal"
              >
                ×
              </a>
              <div class="FormArea">
                <div class="ModalTitleBox">
                  <h4>Update Construction Category</h4>
                </div>
                <div class="FolderForm">
                  <form>
                    <div class="row">
                      <div class="col-md-7">
                      <div class="CommonSelectBox w-100">
                          <select                             value={constructionCategory.projectTypeName}
                            onChange={(e) =>
                              UpdateConstructionCategory({
                                ...constructionCategory,
                                projectTypeName: e.target.value,
                              })
                            }
>
                            <option selected="">Construction Profile Options</option>
                            {constructionProjectProfileList?.map((item)=>
                                                        <option value={item?.projectTypeDescription}>{item?.projectTypeDescription}</option>

                            )}

                          </select>
                          <span><img src={require("../../assets/images/down.png")} /></span>
                        </div>
                        {/* <div class="form-group">
                          <h6>Construction Project Profile Options</h6>
                          <input
                            type="text"
                            class="form-control"
                            value={constructionCategory.projectTypeName}
                            onChange={(e) =>
                              UpdateConstructionCategory({
                                ...constructionCategory,
                                projectTypeDescription: e.target.value,
                              })
                            }
                            placeholder="Construction Project ConstructionCategory Option Description *"
                          />
                        </div> */}
                        <div class="form-group">
                          <h6>
                            Construction Category Description{" "}
                            <span class="required">*</span>
                          </h6>
                          <input
                            type="text"
                            class="form-control"
                            value={constructionCategory.description}
                            onChange={(e) =>
                              UpdateConstructionCategory({
                                ...constructionCategory,
                                description: e.target.value,
                              })
                            }
                            placeholder="Construction Project ConstructionCategory Option Description *"
                          />
                          {constructionCategory.constructionCategoryError &&
                            !constructionCategory.description && (
                              <p style={{ color: "red" }}>
                                {constructionCategory.constructionCategoryError}
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                    <div class="Button d-flex justify-content-between mt-3">
                      <button
                        class="Create"
                        data-dismiss="modal"
                        onClick={handleUpdateConstructionCategory}
                      >
                        Update  Construction Category
                      </button>
                      <button
                        class="Outline"
                        data-dismiss="modal"
                        onClick={handleUpdateConstructionCategoryModalClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={ConstructionCategoryDetailsModal}
          onHide={handleConstructionCategoryDetailsModalClose}
          className="ModalBox MediumModal"
        >
          <Modal.Body>
            <div class="Decline">
              <a
                onClick={handleConstructionCategoryDetailsModalClose}
                class="CloseModal"
              >
                ×
              </a>
              <div class="FormArea">
                <div class="ModalTitleBox">
                  <h4>Construction  Profile Category Details</h4>
                </div>
                <div class="FolderForm ParagraphMargin">
                  <form>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <h6>Construction Project Profile Description</h6>
                          <p>
                            {
                              constructionCategoryDetails?.projectTypeName
                            }
                          </p>
                        </div>
                        <div class="form-group">
                        <h6>Construction Category Description <span class="required">*</span> </h6>
                        <p>{constructionCategoryDetails?.description}</p>
                      </div>

                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <h6>Last Update</h6>
                          <p>
                            {moment(
                              ConstructionCategoryDetails?.updatedAt
                            ).format("ll")}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <h6>Updated By</h6>
                          <p>John Doe</p>
                        </div>
                      </div>
                    </div>
                    <div class="Button text-center mt-5">
                      <button
                        class="Outline"
                        data-dismiss="modal"
                        onClick={handleConstructionCategoryDetailsModalClose}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>


        
        <Modal
          show={AddTaskModal}
          onHide={handleAddTaskModalClose}
          className="ModalBox MediumModal"
        >
          <Modal.Body>
            <div class="Decline">
              <a
                onClick={handleAddTaskModalClose}
                class="CloseModal"
              >
                ×
              </a>
              <div class="FormArea">
              <div class="ModalTitleBox">
                <h4>Add New Task</h4>
              </div>
              <div class="FolderForm ParagraphMargin">
                <form>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <h6>Phase <span class="required">*</span></h6>
                        <div class="CommonSelectBox w-100">
                          <select>
                            <option selected="">Select Phase</option>
                            {constructionCategoryList?.[0]?.StaticPhases?.map((item)=>
                                                        <option >{item?.phaseName}</option>

                            )}
                          </select>
                        </div>
                      </div>
                      <div class="form-group">
                        <h6>Task Name <span class="required">*</span></h6>
                        <input type="text" class="form-control" placeholder="Insert Task Name" />
                      </div>
                      <div class="form-group">
                        <h6>Cost Code / Vendor</h6>
                        <div class="CommonSelectBox w-100">
                          <select>
                            <option selected="">Select Cost Code</option>
                          </select>
                          <span><img src="images/down.png" /></span>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                    <div class="Button  d-flex justify-content-between mt-4">
                      <button  class="Create" data-dismiss="modal">Add Task</button>
                      <button class="Outline" data-dismiss="modal">Close</button>
                    </div>
                </form>
              </div>
            </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={AddMasterConstructionCategoryModal}
          onHide={handleAddMasterConstructionCategoryModalClose}
          className="ModalBox MediumModal"
        >
          <Modal.Body>
            <div class="Decline">
              <a
                onClick={handleAddMasterConstructionCategoryModalClose}
                class="CloseModal"
              >
                ×
              </a>
              <div class="FormArea">
                <div class="ModalTitleBox">
                  <h4>Add New Construction Category</h4>
                </div>
                <div class="FolderForm">
                  <form>
                    <div class="row">
                      <div class="col-md-7">
                        <div class="form-group">
                          <h6>Construction Project Profile Options</h6>
                          <input
                            type="text"
                            class="form-control"
                            value={masterConstructionCategory.projectTypeName}
                            onChange={(e) =>
                              UpdateMasterConstructionCategory({
                                ...masterConstructionCategory,
                                projectTypeDescription: e.target.value,
                              })
                            }
                            placeholder="Construction Project ConstructionCategory Option Description *"
                          />
                        </div>
                        <div class="form-group">
                          <h6>
                            Construction Category Description{" "}
                            <span class="required">*</span>
                          </h6>
                          <input
                            type="text"
                            class="form-control"
                            value={masterConstructionCategory.description}
                            onChange={(e) =>
                              UpdateMasterConstructionCategory({
                                ...masterConstructionCategory,
                                description: e.target.value,
                              })
                            }
                            placeholder="Construction Project ConstructionCategory Option Description *"
                          />
                          {masterConstructionCategory.masterConstructionCategoryError &&
                            !masterConstructionCategory.description && (
                              <p style={{ color: "red" }}>
                                {masterConstructionCategory.masterConstructionCategoryError}
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                    <div class="Button d-flex justify-content-between mt-3">
                      <button
                        class="Create"
                        data-dismiss="modal"
                        onClick={handleSubmitMasterConstructionCategory}
                      >
                        Create New Profile Option
                      </button>
                      <button
                        class="Outline"
                        data-dismiss="modal"
                        onClick={handleAddMasterConstructionCategoryModalClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

  </>
  
  )
}

export default AdminConstructor