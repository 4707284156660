import React from 'react'
import Header from './Header'
import SideNav from './SideNav'
const LeadContractManagment = () => {
  return (
    <>
    <Header/>
    <SideNav/>
    <div className="WrapperArea">
      <div className="WrapperBox">
        <div className="d-flex flex-wrap mb-5">
          <h6
            className="CursorPointer"
            data-toggle="modal"
            data-target="#ContactModal"
          >
            Contact Modal
          </h6>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h6
            className="CursorPointer"
            data-toggle="modal"
            data-target="#NewText"
          >
            New Text
          </h6>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h6
            className="CursorPointer"
            data-toggle="modal"
            data-target="#ImportContactInformation"
          >
            Import Contact Information
          </h6>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h6
            className="CursorPointer"
            data-toggle="modal"
            data-target="#AssignSalesRepresentative"
          >
            Assign Sales Representative
          </h6>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h6
            className="CursorPointer"
            data-toggle="modal"
            data-target="#AssignStaffMember"
          >
            Assign Staff Member
          </h6>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h6
            className="CursorPointer"
            data-toggle="modal"
            data-target="#ReminderModal"
          >
            Reminder
          </h6>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h6
            className="CursorPointer"
            data-toggle="modal"
            data-target="#NewEmail"
          >
            New Email
          </h6>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h6
            className="CursorPointer"
            data-toggle="modal"
            data-target="#ViewMessage"
          >
            View Message
          </h6>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h6
            className="CursorPointer"
            data-toggle="modal"
            data-target="#ItineraryItemDetails"
          >
            Itinerary Item Details
          </h6>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h6
            className="CursorPointer"
            data-toggle="modal"
            data-target="#ScheduleNewFollowUpActivity"
          >
            Schedule New Follow up activity
          </h6>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h6
            className="CursorPointer"
            data-toggle="modal"
            data-target="#EditFollowUpActivity"
          >
            Edit Follow up activity
          </h6>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h6
            className="CursorPointer"
            data-toggle="modal"
            data-target="#LeadDetails"
          >
            Lead Details
          </h6>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h6
            className="CursorPointer"
            data-toggle="modal"
            data-target="#LeadDetails2"
          >
            Lead Details2
          </h6>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h6
            className="CursorPointer"
            data-toggle="modal"
            data-target="#CreateNewLead"
          >
            Create New Lead
          </h6>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h6
            className="CursorPointer"
            data-toggle="modal"
            data-target="#EditContactInformation"
          >
            Edit Contact Information
          </h6>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <div className="my-5 d-flex flex-wrap">
          <h6
            className="CursorPointer"
            data-toggle="modal"
            data-target="#AssignUnit"
          >
            Assign Unit
          </h6>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h6
            className="CursorPointer"
            data-toggle="modal"
            data-target="#ClientContractDetails"
          >
            Client Contract Details
          </h6>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h6
            className="CursorPointer"
            data-toggle="modal"
            data-target="#ClientContractDetails2"
          >
            Client Contract Details2
          </h6>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h6
            className="CursorPointer"
            data-toggle="modal"
            data-target="#ClientContractDetails3"
          >
            Client Contract Details3
          </h6>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h6
            className="CursorPointer"
            data-toggle="modal"
            data-target="#SelectVendor"
          >
            Select Vendor
          </h6>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <div className="TodosArea">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <div className="TitleBox bg-none border-0 p-0">
                <h4 className="Title">Lead &amp; Contract Managment</h4>
              </div>
              <div className="CommonTabs mt-3">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="LeadsTabId"
                      data-toggle="tab"
                      href="#Leads"
                    >
                      <span>
                        <img src="images/leads.png" />
                      </span>
                      Leads
                    </a>
                    <span className="Count">3</span>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="ContractsTabId"
                      data-toggle="tab"
                      href="#Contracts"
                    >
                      <span>
                        <img src="images/contract_red.png" />
                      </span>
                      Contracts
                    </a>
                    <span className="Count">3</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="TodosButtons mt-0">
              <a
                href="javascript:void(0)"
                id="CreateNewLeadBtn"
                data-toggle="modal"
                data-target="#CreateNewLead"
              >
                <img src="images/create_new_lead.png" />
                Create New Lead
              </a>
              <a
                href="javascript:void(0)"
                id="CreateNewContractClientBtn"
                data-toggle="modal"
                data-target="#"
              >
                <img src="images/create_new_lead.png" />
                Create New Contract Client
              </a>
            </div>
          </div>
        </div>
        <div className="tab-content">
          <div className="tab-pane fade show active" id="Leads">
            <div className="TableHeadingArea mt-5">
              <div className="FilesHead">
                <div className="FilesLeft">
                  <h4>Leads</h4>
                </div>
                <div className="NewActions">
                  <a href="Javascript:void(0);">
                    <img src="images/icon-64.png" />
                  </a>
                  <a href="Javascript:void(0);">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="Javascript:void(0);">
                    <img src="images/download.png" />
                  </a>
                  <a href="Javascript:void(0);">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
            </div>
            <div className="TableList CommonHeading TableMinimumHeight">
              <table>
                <thead>
                  <tr>
                    <th />
                    <th>Client Contact</th>
                    <th>Date Created</th>
                    <th>Subdivision</th>
                    <th>Status</th>
                    <th>Reason</th>
                    <th>Latest Follow up Activity</th>
                    <th>Age</th>
                    <th>Confidence level</th>
                    <th>Assigned to</th>
                    <th>Discussion</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="NewButton">
                        <button type="button" className="NewBtn">
                          New
                        </button>
                      </div>
                    </td>
                    <td>
                      <div data-toggle="modal" data-target="#LeadDetails">
                        John Lead
                      </div>
                    </td>
                    <td>05/24/2023</td>
                    <td>Chinook Homes</td>
                    <td>New</td>
                    <td>Budget PO</td>
                    <td />
                    <td>
                      <div>&lt; 1 day</div>
                    </td>
                    <td />
                    <td>
                      <div>
                        <img src="images/assign_icon.png" />
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <a href="#">
                          <img src="images/discussion_icon.png" />
                        </a>
                        <span className="Count">3</span>
                      </div>
                    </td>
                    <td>
                      <div className="TableThreeDots">
                        <ul className="">
                          <li className="dropdown">
                            <a className="" role="button" data-toggle="dropdown">
                              <img src="images/dots.png" />
                            </a>
                            <ol className="dropdown-menu ChangeOrderDropdown2">
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#LeadDetails2"
                                >
                                  <img src="images/icon-15.png" />
                                  Edit Lead Details
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#EditContactInformation"
                                >
                                  <img src="images/edit_contact.png" />
                                  Edit Contact Information
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/calendar.png" />
                                  Schedule New Follow up Activity
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/icon-26.png" />
                                  Convert lead to Contract
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#Survey"
                                >
                                  <img src="images/survey.png" />
                                  View/Edit Survey
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/send-text.png" />
                                  Sent Text
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/send-email.png" />
                                  Send Email
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/icon-53.png" />
                                  Print Lead details
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td>
                      <div data-toggle="modal" data-target="#LeadDetails">
                        Jill Lead
                      </div>
                    </td>
                    <td>05/24/2023</td>
                    <td>Chinook Homes</td>
                    <td>In Progress</td>
                    <td>Field PO</td>
                    <td>Meeting on 06/ 07/2023</td>
                    <td>30 day</td>
                    <td>High</td>
                    <td>
                      <div className="Action">
                        <img src="images/icon-32.png" />
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <a href="#">
                          <img src="images/discussion_icon.png" />
                        </a>
                        <span className="Count">3</span>
                      </div>
                    </td>
                    <td>
                      <div className="TableThreeDots">
                        <ul className="">
                          <li className="dropdown">
                            <a className="" role="button" data-toggle="dropdown">
                              <img src="images/dots.png" />
                            </a>
                            <ol className="dropdown-menu ChangeOrderDropdown2">
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#LeadDetails2"
                                >
                                  <img src="images/icon-15.png" />
                                  Edit Lead Details
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#EditContactInformation"
                                >
                                  <img src="images/edit_contact.png" />
                                  Edit Contact Information
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/calendar.png" />
                                  Schedule New Follow up Activity
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/icon-26.png" />
                                  Convert lead to Contract
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#Survey"
                                >
                                  <img src="images/survey.png" />
                                  View/Edit Survey
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/send-text.png" />
                                  Sent Text
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/send-email.png" />
                                  Send Email
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/icon-53.png" />
                                  Print Lead details
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td>
                      <div data-toggle="modal" data-target="#LeadDetails">
                        Marna Lead
                      </div>
                    </td>
                    <td>05/24/2023</td>
                    <td>Arbour Lake Homes</td>
                    <td>Not Interested</td>
                    <td>Change Order PO</td>
                    <td>Call on 05/30/2023</td>
                    <td>63 days</td>
                    <td>Medium</td>
                    <td>
                      <div className="Action">
                        <img src="images/icon-32.png" />
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <a href="javascript:void(0);">
                          <img src="images/discussion_icon.png" />
                        </a>
                        <span className="Count">3</span>
                      </div>
                    </td>
                    <td>
                      <div className="TableThreeDots">
                        <ul className="">
                          <li className="dropdown">
                            <a className="" role="button" data-toggle="dropdown">
                              <img src="images/dots.png" />
                            </a>
                            <ol className="dropdown-menu ChangeOrderDropdown2">
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#LeadDetails2"
                                >
                                  <img src="images/icon-15.png" />
                                  Edit Lead Details
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#EditContactInformation"
                                >
                                  <img src="images/edit_contact.png" />
                                  Edit Contact Information
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/calendar.png" />
                                  Schedule New Follow up Activity
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/icon-26.png" />
                                  Convert lead to Contract
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#Survey"
                                >
                                  <img src="images/survey.png" />
                                  View/Edit Survey
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/send-text.png" />
                                  Sent Text
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/send-email.png" />
                                  Send Email
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/icon-53.png" />
                                  Print Lead details
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td>
                      <div data-toggle="modal" data-target="#LeadDetails">
                        Joice Lead
                      </div>
                    </td>
                    <td>05/24/2023</td>
                    <td>Arbour Lake Homes</td>
                    <td>Not Interested</td>
                    <td>Change Order PO</td>
                    <td>Discuss message on 02/ 20/2023</td>
                    <td>12 days</td>
                    <td>Low</td>
                    <td>
                      <div className="Action">
                        <img src="images/icon-32.png" />
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <a href="#">
                          <img src="images/discussion_icon.png" />
                        </a>
                        <span className="Count">3</span>
                      </div>
                    </td>
                    <td>
                      <div className="TableThreeDots">
                        <ul className="">
                          <li className="dropdown">
                            <a className="" role="button" data-toggle="dropdown">
                              <img src="images/dots.png" />
                            </a>
                            <ol className="dropdown-menu ChangeOrderDropdown2">
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#LeadDetails2"
                                >
                                  <img src="images/icon-15.png" />
                                  Edit Lead Details
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#EditContactInformation"
                                >
                                  <img src="images/edit_contact.png" />
                                  Edit Contact Information
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/calendar.png" />
                                  Schedule New Follow up Activity
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/icon-26.png" />
                                  Convert lead to Contract
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#Survey"
                                >
                                  <img src="images/survey.png" />
                                  View/Edit Survey
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/send-text.png" />
                                  Sent Text
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/send-email.png" />
                                  Send Email
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/icon-53.png" />
                                  Print Lead details
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="tab-pane fade" id="Contracts">
            <div className="TableHeadingArea mt-5">
              <div className="FilesHead">
                <div className="FilesLeft">
                  <h4>Leads</h4>
                </div>
                <div className="NewActions">
                  <a href="Javascript:void(0);">
                    <img src="images/icon-64.png" />
                  </a>
                  <a href="Javascript:void(0);">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="Javascript:void(0);">
                    <img src="images/download.png" />
                  </a>
                  <a href="Javascript:void(0);">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
            </div>
            <div className="TableList CommonHeading TableMinimumHeight">
              <table>
                <thead>
                  <tr>
                    <th />
                    <th>Client Contact</th>
                    <th>Date Created</th>
                    <th>Sale Type</th>
                    <th>Client Assigned Unit</th>
                    <th>Client Purchase Price</th>
                    <th>Status</th>
                    <th>Deposit Paid</th>
                    <th>Contract Signed</th>
                    <th>Down Payment Paid</th>
                    <th>Financing Confirmed</th>
                    <th>Waiver of Conditions Signed</th>
                    <th>View SOA</th>
                    <th>Next Scheduled Transaction</th>
                    <th>Assigned to</th>
                    <th>Documents</th>
                    <th>Discussion</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="NewButton">
                        <button type="button" className="NewBtn">
                          New
                        </button>
                      </div>
                    </td>
                    <td>
                      <div className="TextBlue">John Contact</div>
                    </td>
                    <td>05/24/2023</td>
                    <td />
                    <td>
                      <div
                        className="CursorPointer"
                        data-toggle="modal"
                        data-target="#AssignUnit"
                      >
                        <img src="images/client_assigned_unit.png" />
                      </div>
                    </td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td>
                      <div>
                        <img src="images/assign_icon.png" />
                      </div>
                    </td>
                    <td>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#AddNewSupportingDocs2"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0)"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList"
                        >
                          <span className="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <a href="#">
                          <img src="images/discussion_icon.png" />
                        </a>
                        <span className="Count">3</span>
                      </div>
                    </td>
                    <td>
                      <div className="TableThreeDots">
                        <ul className="">
                          <li className="dropdown">
                            <a className="" role="button" data-toggle="dropdown">
                              <img src="images/dots.png" />
                            </a>
                            <ol className="dropdown-menu ChangeOrderDropdown2">
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#ClientContractDetails"
                                >
                                  <img src="images/Action-1.png" />
                                  Edit Client Contrats Details
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#EditContactInformation"
                                >
                                  <img src="images/contact-list.png" />
                                  Edit Contact Information
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/assign_unit.png" />
                                  Assign Unit
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/add_transactions.png" />
                                  Add Transactions
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/schedule_payment.png" />
                                  Schedule Payments
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/icon-63.png" />
                                  Sales Docuements
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/configure_client_view.png" />
                                  Configure Client View
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td>
                      <div className="TextBlue">Jill Lead</div>
                    </td>
                    <td>05/24/2023</td>
                    <td>Residential</td>
                    <td>
                      <div className="TextBlue">U231-CHI-2B-243 (Unit #231)</div>
                    </td>
                    <td>$530,000.00</td>
                    <td>Conditional Job Assigned</td>
                    <td>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </td>
                    <td>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </td>
                    <td>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </td>
                    <td>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </td>
                    <td>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </td>
                    <td />
                    <td>Friday, June 16, 2023</td>
                    <td>
                      <div className="Actions">
                        <img src="images/icon-32.png" />
                      </div>
                    </td>
                    <td>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#AddNewSupportingDocs2"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0)"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList"
                        >
                          <span className="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <a href="#">
                          <img src="images/discussion_icon.png" />
                        </a>
                        <span className="Count">3</span>
                      </div>
                    </td>
                    <td>
                      <div className="TableThreeDots">
                        <ul className="">
                          <li className="dropdown">
                            <a className="" role="button" data-toggle="dropdown">
                              <img src="images/dots.png" />
                            </a>
                            <ol className="dropdown-menu ChangeOrderDropdown2">
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#ClientContractDetails"
                                >
                                  <img src="images/Action-1.png" />
                                  Edit Client Contrats Details
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#EditContactInformation"
                                >
                                  <img src="images/contact-list.png" />
                                  Edit Contact Information
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/update_satisfied.png" />
                                  Update Satisfied Conditions
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/view_update.png" />
                                  View/Update Job Details
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/add_transactions.png" />
                                  Add Transactions
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/schedule_payment.png" />
                                  Schedule Payments
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/icon-63.png" />
                                  Sales Docuements
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/configure_client_view.png" />
                                  Configure Client View
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td>
                      <div className="TextBlue">Jill Lead</div>
                    </td>
                    <td>05/24/2023</td>
                    <td>Residential</td>
                    <td>
                      <div className="TextBlue">U231-CHI-2B-243 (Unit #231)</div>
                    </td>
                    <td>$530,000.00</td>
                    <td>Conditional Job Assigned</td>
                    <td>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </td>
                    <td>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </td>
                    <td>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </td>
                    <td>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </td>
                    <td>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </td>
                    <td />
                    <td>
                      <div className="Actions">
                        <a href="javascript:void(0);">
                          <img src="images/visa_icon.png" />
                        </a>
                        <a href="javascript:void(0)">
                          <span className="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div className="Actions">
                        <img src="images/icon-32.png" />
                      </div>
                    </td>
                    <td>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#AddNewSupportingDocs2"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0)"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList"
                        >
                          <span className="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <a href="#">
                          <img src="images/discussion_icon.png" />
                        </a>
                        <span className="Count">3</span>
                      </div>
                    </td>
                    <td>
                      <div className="TableThreeDots">
                        <ul className="">
                          <li className="dropdown">
                            <a className="" role="button" data-toggle="dropdown">
                              <img src="images/dots.png" />
                            </a>
                            <ol className="dropdown-menu ChangeOrderDropdown2">
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#ClientContractDetails"
                                >
                                  <img src="images/Action-1.png" />
                                  Edit Client Contrats Details
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#EditContactInformation"
                                >
                                  <img src="images/contact-list.png" />
                                  Edit Contact Information
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/view_update.png" />
                                  View/Update Job Details
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/add_transactions.png" />
                                  Add Transactions
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/schedule_payment.png" />
                                  Schedule Payments
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/icon-63.png" />
                                  Sales Docuements
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/configure_client_view.png" />
                                  Configure Client View
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/visa_icon.png" />
                                  Visa SOA
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td>
                      <div className="TextBlue">Jill Lead</div>
                    </td>
                    <td>05/24/2023</td>
                    <td>Residential</td>
                    <td>
                      <div className="TextBlue">U231-CHI-2B-243 (Unit #231)</div>
                    </td>
                    <td>$530,000.00</td>
                    <td>Conditional Job Assigned</td>
                    <td>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </td>
                    <td>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </td>
                    <td>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </td>
                    <td>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </td>
                    <td>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </td>
                    <td />
                    <td>
                      <div className="Actions">
                        <a href="javascript:void(0);">
                          <img src="images/visa_icon.png" />
                        </a>
                        <a href="javascript:void(0)">
                          <span className="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div className="Actions">
                        <img src="images/icon-32.png" />
                      </div>
                    </td>
                    <td>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#AddNewSupportingDocs2"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0)"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList"
                        >
                          <span className="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <a href="#">
                          <img src="images/discussion_icon.png" />
                        </a>
                        <span className="Count">3</span>
                      </div>
                    </td>
                    <td>
                      <div className="TableThreeDots">
                        <ul className="">
                          <li className="dropdown">
                            <a className="" role="button" data-toggle="dropdown">
                              <img src="images/dots.png" />
                            </a>
                            <ol className="dropdown-menu ChangeOrderDropdown2">
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#ClientContractDetails"
                                >
                                  <img src="images/Action-1.png" />
                                  Edit Client Contrats Details
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#EditContactInformation"
                                >
                                  <img src="images/contact-list.png" />
                                  Edit Contact Information
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/view_update.png" />
                                  View/Update Job Details
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/add_transactions.png" />
                                  Add Transactions
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/schedule_payment.png" />
                                  Schedule Payments
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/icon-63.png" />
                                  Sales Docuements
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/configure_client_view.png" />
                                  Configure Client View
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/visa_icon.png" />
                                  Visa SOA
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="ModalBox">
      <div className="modal fade LargeModal show" id="Survey">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Survey</h4>
              </div>
              <div className="JobTitleArea">
                <div className="JobBox">
                  <h6>
                    <span>Status:</span> New
                  </h6>
                  <h6>
                    <span>Last Update:</span> 06/062023
                  </h6>
                </div>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <h6>Lead Source</h6>
                      <div className="Categories Categories1">
                        <div className="Categories_all Categories_all1">
                          <span>Choose</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>-Not Applicable/No Answer</li>
                          <li>-Newspaper AD</li>
                          <li>-TV AD</li>
                          <li>-Radio AD</li>
                          <li>-Signage</li>
                          <li>-Internet</li>
                          <li>-Passing By</li>
                          <li>-Other</li>
                          <li>-Not Applicable/No Answer</li>
                          <li>-Not Applicable/No Answer</li>
                        </ul>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <h6>When Planning to Purchase</h6>
                      <div className="Categories Categories2">
                        <div className="Categories_all Categories_all2">
                          <span>Choose</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>-Not Applicable/No Answer</li>
                          <li>-Now</li>
                          <li>-1-3 Months</li>
                          <li>-3-6 Months</li>
                          <li>-6-12 Months</li>
                          <li>-6-12 Months</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <h6>Current Home Style</h6>
                      <div className="Categories Categories3">
                        <div className="Categories_all Categories_all3">
                          <span>Choose</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>-Not Applicable/No Answer</li>
                          <li>-2 Story</li>
                          <li>-By-Level</li>
                          <li>-Bunglow</li>
                          <li>-Duplex</li>
                          <li>-Townhouse</li>
                          <li>-Apartment</li>
                        </ul>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <h6>Preferred Price</h6>
                      <div className="Categories Categories4">
                        <div className="Categories_all Categories_all4">
                          <span>Choose</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>-Not Applicable/No Answer</li>
                          <li>-Up to $200,000</li>
                          <li>-$200,000 - $400,000</li>
                          <li>-$200,000 - $400,000</li>
                          <li>-Over $1,000,000</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <h6>Current Home Size</h6>
                      <div className="Categories Categories5">
                        <div className="Categories_all Categories_all5">
                          <span>Choose</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>-Not Applicable/No Answer</li>
                          <li>-500 - 1000 Sq/Ft</li>
                          <li>-1001 - 1500 Sq/Ft</li>
                          <li>-1501 - 2000 Sq/Ft</li>
                          <li>-2001 - 2500 Sq/F</li>
                          <li>-Larger than 2500 Sq/Ft</li>
                        </ul>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <h6>Reason to Move</h6>
                      <div className="Categories Categories6">
                        <div className="Categories_all Categories_all6">
                          <span>Choose</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>-Not Applicable/No Answer</li>
                          <li>-Larger Home</li>
                          <li>-Smaller Home</li>
                          <li>-Change of Community</li>
                          <li>-Features</li>
                          <li>-Affordability</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <h6>Seeking Home Size</h6>
                      <div className="Categories Categories7">
                        <div className="Categories_all Categories_all7">
                          <span>Choose</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>-Not Applicable/No Answer</li>
                          <li>-500 - 1000 Sq/Ft</li>
                          <li>-1001 - 1500 Sq/Ft</li>
                          <li>-1501 - 2000 Sq/Ft</li>
                          <li>-2001 - 2500 Sq/F</li>
                          <li>-Larger than 2500 Sq/Ft</li>
                        </ul>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <h6>Require Realtor Assistance</h6>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <h6>Seeking Home Style</h6>
                      <div className="Categories Categories8">
                        <div className="Categories_all Categories_all8">
                          <span>Choose</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>-Not Applicable/No Answer</li>
                          <li>-2 Story</li>
                          <li>-By-Leve</li>
                          <li>-Bungalow</li>
                          <li>-Duplex</li>
                          <li>-Townhouse</li>
                          <li>-Apartment</li>
                        </ul>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <h6>Require Mortgage Assistance</h6>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <h6>Seeking Home Site</h6>
                      <div className="Categories Categories9">
                        <div className="Categories_all Categories_all9">
                          <span>Choose</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>-Not Applicable/No Answer</li>
                          <li>-Level</li>
                          <li>-Walk-Out</li>
                          <li>-Pie</li>
                          <li>-Corner</li>
                          <li>-Green Space</li>
                          <li>-Acreage</li>
                          <li>-Other</li>
                        </ul>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <h6>Requested more info</h6>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <h6>How Long Been Searching</h6>
                      <div className="Categories Categories10">
                        <div className="Categories_all Categories_all10">
                          <span>Choose</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>-Not Applicable/No Answer</li>
                          <li>-Just Started</li>
                          <li>-1 Month</li>
                          <li>-6-12 Months</li>
                          <li>-Longer</li>
                        </ul>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <h6>Requested more info</h6>
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                  <div className="Button d-flex justify-content-between mt-3">
                    <button className="Create">Save</button>
                    <button className="Create">Reopen Survey</button>
                    <button className="Outline">Assign</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade LargeModal show" id="CreateNewLead">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Create New Lead</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="col-12">
                    <div className="form-group mb-5">
                      <div className="CommonModalArea">
                        <h5>Contact Information</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#ImportContactInformation"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/upload_icon.png" />
                            </figure>{" "}
                            Import contact information
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="form-group col-md-12">
                        <div className="ModalBigheading">
                          <h3>
                            <span>Personal Information</span>
                          </h3>
                        </div>
                      </div>
                      <div className="form-group col-md-12">
                        <h6>
                          Client Full Legal name
                          <span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input client's full legal name"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <h6>
                          Primary Phone Number<span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input primary phone number"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <h6>
                          Email<span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input email"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <h6>Business Phone Number</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input business phone number"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <h6>Fax Phone Number</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input fax phone number"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <h6>Preferred Communication</h6>
                        <div className="Categories Categories1">
                          <div className="Categories_all Categories_all1">
                            <span>Select preference</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>-No preference</li>
                            <li>-Phone</li>
                            <li>-Email</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 ml-auto">
                      <div className="form-group col-md-12">
                        <div className="ModalBigheading">
                          <h3>
                            <span>Address</span>
                          </h3>
                        </div>
                      </div>
                      <div className="form-group col-md-12">
                        <h6>Street Address</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input street name"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <h6>Address Line two</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input address line two"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <h6>
                          City<span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input city name"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <h6>
                          Zip/Postal Code<span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input postal name"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <h6>
                          Country<span className="required">*</span>
                        </h6>
                        <div className="CommonSelectBox">
                          <select>
                            <option selected="">Select Country</option>
                            <option>India</option>
                            <option>USA</option>
                            <option>Australia</option>
                          </select>
                          <span>
                            <img src="images/down.png" />
                          </span>
                        </div>
                      </div>
                      <div className="form-group col-md-12">
                        <h6>
                          State/Province<span className="required">*</span>
                        </h6>
                        <div className="CommonSelectBox">
                          <select>
                            <option selected="">Select State or Province</option>
                            <option>India</option>
                            <option>USA</option>
                            <option>Australia</option>
                          </select>
                          <span>
                            <img src="images/down.png" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="TodosButtons mt-0 text-center">
                        <a href="javascript:void(0)">
                          <img src="images/general_information.png" />
                          General Information
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="GeneralInformation mt-4">
                    <div className="row">
                      <div className="form-group col-md-4">
                        <div className="CommonModalArea">
                          <h5>Assigned to</h5>
                          <div className="CommonModalBox">
                            <h5 className="">
                              <figure>
                                <img src="images/icon-32.png" />
                              </figure>{" "}
                              John Doe
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <h6>Sub Division</h6>
                        <div className="Categories Categories2">
                          <div className="Categories_all Categories_all2">
                            <span>Select Sub Division</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>-List of Subdivisions offered by the builder</li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <div className="CommonModalArea">
                          <h5>Survey</h5>
                          <div className="CommonModalBox">
                            <figure>
                              <img src="images/survey.png" />
                            </figure>
                            <span className="Count">3</span>
                            <h5 className="">Survey</h5>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <div className="CommonModalArea">
                          <h5>Lead Age</h5>
                          <div className="CommonModalBox">
                            <h5 className="">&lt; 1day</h5>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <h6>Survey</h6>
                        <div className="Categories Categories3">
                          <div className="Categories_all Categories_all3">
                            <span>Select lead satus</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>-New</li>
                            <li>-In progress</li>
                            <li>-Interested</li>
                            <li>-Not Interested</li>
                            <li>-Cancelled</li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <h6>Confidence Level</h6>
                        <div className="Categories Categories4">
                          <div className="Categories_all Categories_all4">
                            <span>Confidence Level</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>-Low</li>
                            <li>-Medium</li>
                            <li>-High</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-12">
                        <h6 className="mb-4">Realtor/Referrer</h6>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="form-group col-md-10">
                            <h6>Name</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Input Name"
                            />
                          </div>
                          <div className="form-group col-md-10">
                            <h6>Email</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Input Email"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group col-md-10 ml-auto">
                          <h6>Agency</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Input Agency"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="Button text-center">
                      <button className="Create mr-3" data-dismiss="modal">
                        Create Lead
                      </button>
                      <button className="Outline ml-3" data-dismiss="modal">
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade LargeModal show"
        id="EditContactInformation"
        data-backdrop="Static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Edit Contact Information</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="form-group col-md-12">
                        <div className="ModalBigheading">
                          <h3>
                            <span>Personal Information</span>
                          </h3>
                        </div>
                      </div>
                      <div className="form-group col-md-12">
                        <h6>
                          Client Full Legal name
                          <span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input client's full legal name"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <h6>
                          Primary Phone Number<span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input primary phone number"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <h6>
                          Email<span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input email"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <h6>Business Phone Number</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input business phone number"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <h6>Fax Phone Number</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input fax phone number"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <h6>Preferred Communication</h6>
                        <div className="Categories Categories1">
                          <div className="Categories_all Categories_all1">
                            <span>Select preference</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>-No preference</li>
                            <li>-Phone</li>
                            <li>-Email</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 ml-auto">
                      <div className="form-group col-md-12">
                        <div className="ModalBigheading">
                          <h3>
                            <span>Address</span>
                          </h3>
                        </div>
                      </div>
                      <div className="form-group col-md-12">
                        <h6>Street Address</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input street name"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <h6>Address Line two</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input address line two"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <h6>
                          City<span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input city name"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <h6>
                          Zip/Postal Code<span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input postal name"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <h6>
                          Country<span className="required">*</span>
                        </h6>
                        <div className="CommonSelectBox">
                          <select>
                            <option selected="">Select Country</option>
                            <option>India</option>
                            <option>USA</option>
                            <option>Australia</option>
                          </select>
                          <span>
                            <img src="images/down.png" />
                          </span>
                        </div>
                      </div>
                      <div className="form-group col-md-12">
                        <h6>
                          State/Province<span className="required">*</span>
                        </h6>
                        <div className="CommonSelectBox">
                          <select>
                            <option selected="">Select State or Province</option>
                            <option>India</option>
                            <option>USA</option>
                            <option>Australia</option>
                          </select>
                          <span>
                            <img src="images/down.png" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Button d-flex justify-content-center">
                    <button className="Create mr-4">
                      Update Contact Information
                    </button>
                    <button className="Outline ml-4">Cancel</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade ExtraSmallModal show"
        id="ContactModal"
        data-backdrop="Static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal ClosePermissionId"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ContactTopBox">
                <h6>
                  Task 1.3.1.1.1
                  <a
                    href="javascript:void(0);"
                    className="FolderPermissionId"
                    data-toggle="modal"
                    data-target="#EditTaskDetails"
                    data-dismiss="modal"
                  >
                    <img src="images/edit-33.png" />
                  </a>
                  <br />
                  Assigned to Vendor 1/Staff member
                </h6>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="SendEmailText">
                    <p>
                      <img src="images/send-email.png" />
                      Send E-mail
                    </p>
                    <p className="SendText SendText1">
                      <img src="images/send-text.png" />
                      Send Text
                    </p>
                    <div className="SendTextSearch SendTextSearch1">
                      <input type="text" />
                      <button>Apply</button>
                    </div>
                  </div>
                  <div className="MolalContactForm">
                    <h6>Contact</h6>
                    <p>
                      <img src="images/send-email.png" />
                      johndoe@gmail.com
                    </p>
                    <p>
                      <img src="images/send-text.png" />
                      +91 990000000
                    </p>
                    <a href="#">Show more</a>
                  </div>
                  <div className="form-group">
                    <div className="ContactModalAddressArea">
                      <h6>Address</h6>
                      <p>
                        312 Weddenburn Rd SE <br />
                        Calgary, Alberta, T2J 1J
                        <img src="images/location.png" />
                      </p>
                    </div>
                  </div>
                  <div className="form-group mb-0">
                    <h6>Discussion</h6>
                    <input
                      type="text"
                      placeholder="Write here ....."
                      className="form-control"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade SmallModal" id="NewText" data-backdrop="Static">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-toggle="modal"
              data-target="#LeadDetails"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Next Text</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="form-group">
                    <h6>Message</h6>
                    <textarea
                      className="form-control"
                      rows={4}
                      placeholder="Input Message"
                      defaultValue={""}
                    />
                  </div>
                  <div className="Button d-flex justify-content-between ">
                    <button
                      className="Create"
                      data-toggle="modal"
                      data-target="#LeadDetails"
                      data-dismiss="modal"
                    >
                      Send Text
                    </button>
                    <button
                      className="Outline"
                      data-toggle="modal"
                      data-target="#LeadDetails"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade MediumModal"
        id="LeadDetails"
        data-backdrop="Static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal ClosePermissionId"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Lead Details</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="ContactInformationArea">
                        <h6>Contact Information</h6>
                        <div className="ContactInformationBox">
                          <span className="circle">JL</span>
                          <div className="ContactInformationName">
                            <h6>John Lead</h6>
                            <p>
                              312 Weddenburn Rd SE <br /> Calgary, Alberta{" "}
                              <img src="images/location.png" />
                            </p>
                            <p>
                              Phone: <span>(403)555-555</span>
                            </p>
                            <p>
                              Email: <span>John.lead@email.com</span>
                            </p>
                          </div>
                          <div className="ContactInformationIcon">
                            <a href="javascript:void(0);">
                              <img src="images/pdf.png" />
                            </a>
                            <a href="javascript:void(0);">
                              <img src="images/download.png" />
                            </a>
                            <a href="javascript:void(0);">
                              <img src="images/icon-75.png" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="TodosButtons mt-4">
                    <a href="javascript:void(0)" className="m-0">
                      <img src="images/general_information.png" />
                      General Information
                    </a>
                    <a className="Create ml-3">
                      <img src="images/timer-red.png" />
                      Follow up Activity<span>3</span>
                    </a>
                  </div>
                  <div className="TableList CommonHeading mt-4">
                    <table>
                      <thead>
                        <tr>
                          <th colSpan={6}>
                            <div className="TodosButtons mt-0 d-flex align-items-center justify-content-center">
                              <a
                                href="javascript:void(0)"
                                className="m-0"
                                data-toggle="modal"
                                data-target="#ScheduleNewFollowUpActivity"
                                data-dismiss="modal"
                              >
                                <img src="images/schedule_white.png" />
                                Schedule New Activity
                              </a>
                              <a
                                href="javascript:void(0);"
                                className="Create mx-3 FolderPermissionId"
                                data-toggle="modal"
                                data-target="#NewEmail"
                                data-dismiss="modal"
                              >
                                <img src="images/new_mail.png" />
                                New Email
                              </a>
                              <a
                                href="javascript:void(0)"
                                className="m-0 FolderPermissionId"
                                data-toggle="modal"
                                data-target="#NewText"
                                data-dismiss="modal"
                              >
                                <img src="images/new_text.png" />
                                New Text
                              </a>
                            </div>
                          </th>
                        </tr>
                        <tr>
                          <th />
                          <th>Type</th>
                          <th>Sent By</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="ActionsWidth">
                              <img src="images/meeting_icon.png" />
                            </div>
                          </td>
                          <td>Meeting</td>
                          <td>John Doe</td>
                          <td>06/12/2023 10:30 AM</td>
                          <td>Scheduled</td>
                          <td>
                            <div className="dropdown CursorPointer">
                              <p data-toggle="dropdown">
                                <span>
                                  <img src="images/three-dots.png" />
                                </span>
                              </p>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuOffset"
                              >
                                <li>
                                  <a
                                    className="dropdown-item FolderPermissionId"
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#EditFollowUpActivity"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/icon-15.png" />
                                    Edit Activity Details
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/dropdown-delete.png" />
                                    Cancel Activity
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/send_to_itinary.png" />
                                    Send to itinery
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/export-to-calendar.png" />
                                    Export to Calender
                                    <img src="images/rightarrow2.png" />
                                  </a>
                                  <ul className="dropdown-menu submenu submenuLead">
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        <img src="images/google-calendar.png" />
                                        Google Calendar
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        <img src="images/outlook-calendar.png" />
                                        Outlook Calender
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        <img src="images/email-calendar.png" />
                                        Email Calender
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/set-reminder.png" />
                                    Set Reminder
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/send_invitaiton.png" />
                                    Send Invitation
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/assign_icon.png" />
                                    Assign Staff member
                                  </a>
                                </li>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="ActionsWidth">
                              <img src="images/call.png" />
                            </div>
                          </td>
                          <td>Call</td>
                          <td>John Doe</td>
                          <td>06/12/2023 10:30 AM</td>
                          <td>Scheduled</td>
                          <td>
                            <div className="dropdown CursorPointer">
                              <p data-toggle="dropdown">
                                <span>
                                  <img src="images/three-dots.png" />
                                </span>
                              </p>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuOffset"
                              >
                                <li>
                                  <a
                                    className="dropdown-item FolderPermissionId"
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#EditFollowUpActivity"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/icon-15.png" />
                                    Edit Activity Details
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/dropdown-delete.png" />
                                    Cancel Activity
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/send_to_itinary.png" />
                                    Send to itinery
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/export-to-calendar.png" />
                                    Export to Calender
                                    <img src="images/rightarrow2.png" />
                                  </a>
                                  <ul className="dropdown-menu submenu submenuLead">
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        <img src="images/google-calendar.png" />
                                        Google Calendar
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        <img src="images/outlook-calendar.png" />
                                        Outlook Calender
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        <img src="images/email-calendar.png" />
                                        Email Calender
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/set-reminder.png" />
                                    Set Reminder
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/send_invitaiton.png" />
                                    Send Invitation
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/assign_icon.png" />
                                    Assign Staff member
                                  </a>
                                </li>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="ActionsWidth">
                              <img src="images/message.png" />
                            </div>
                          </td>
                          <td>Text</td>
                          <td>John Doe</td>
                          <td>06/12/2023 10:30 AM</td>
                          <td>Start</td>
                          <td>
                            <div className="dropdown CursorPointer">
                              <p data-toggle="dropdown">
                                <span>
                                  <img src="images/three-dots.png" />
                                </span>
                              </p>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuOffset"
                              >
                                <li>
                                  <a
                                    className="dropdown-item FolderPermissionId"
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#EditFollowUpActivity"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/icon-15.png" />
                                    Edit Activity Details
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/dropdown-delete.png" />
                                    Cancel Activity
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/send_to_itinary.png" />
                                    Send to itinery
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/export-to-calendar.png" />
                                    Export to Calender
                                    <img src="images/rightarrow2.png" />
                                  </a>
                                  <ul className="dropdown-menu submenu submenuLead">
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        <img src="images/google-calendar.png" />
                                        Google Calendar
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        <img src="images/outlook-calendar.png" />
                                        Outlook Calender
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        <img src="images/email-calendar.png" />
                                        Email Calender
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/set-reminder.png" />
                                    Set Reminder
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/send_invitaiton.png" />
                                    Send Invitation
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/assign_icon.png" />
                                    Assign Staff member
                                  </a>
                                </li>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="ActionsWidth">
                              <img src="images/send-email.png" />
                            </div>
                          </td>
                          <td>Email</td>
                          <td>John Doe</td>
                          <td>06/12/2023 10:30 AM</td>
                          <td>Received</td>
                          <td>
                            <div className="dropdown CursorPointer">
                              <p data-toggle="dropdown">
                                <span>
                                  <img src="images/three-dots.png" />
                                </span>
                              </p>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuOffset"
                              >
                                <li>
                                  <a
                                    className="dropdown-item FolderPermissionId"
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#EditFollowUpActivity"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/icon-15.png" />
                                    Edit Activity Details
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/dropdown-delete.png" />
                                    Cancel Activity
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/send_to_itinary.png" />
                                    Send to itinery
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/export-to-calendar.png" />
                                    Export to Calender
                                    <img src="images/rightarrow2.png" />
                                  </a>
                                  <ul className="dropdown-menu submenu submenuLead">
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        <img src="images/google-calendar.png" />
                                        Google Calendar
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        <img src="images/outlook-calendar.png" />
                                        Outlook Calender
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        <img src="images/email-calendar.png" />
                                        Email Calender
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/set-reminder.png" />
                                    Set Reminder
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/send_invitaiton.png" />
                                    Send Invitation
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/assign_icon.png" />
                                    Assign Staff member
                                  </a>
                                </li>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="ActionsWidth">
                              <img src="images/discussion_icon.png" />
                            </div>
                          </td>
                          <td>Discussion message</td>
                          <td>John Doe</td>
                          <td>06/12/2023 10:30 AM</td>
                          <td>Received</td>
                          <td>
                            <div className="dropdown CursorPointer">
                              <p data-toggle="dropdown">
                                <span>
                                  <img src="images/three-dots.png" />
                                </span>
                              </p>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuOffset"
                              >
                                <li>
                                  <a
                                    className="dropdown-item FolderPermission"
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#EditFollowUpActivity"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/icon-15.png" />
                                    Edit Activity Details
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/dropdown-delete.png" />
                                    Cancel Activity
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/send_to_itinary.png" />
                                    Send to itinery
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/export-to-calendar.png" />
                                    Export to Calender
                                    <img src="images/rightarrow2.png" />
                                  </a>
                                  <ul className="dropdown-menu submenu submenuLead">
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        <img src="images/google-calendar.png" />
                                        Google Calendar
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        <img src="images/outlook-calendar.png" />
                                        Outlook Calender
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        <img src="images/email-calendar.png" />
                                        Email Calender
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/set-reminder.png" />
                                    Set Reminder
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/send_invitaiton.png" />
                                    Send Invitation
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/assign_icon.png" />
                                    Assign Staff member
                                  </a>
                                </li>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="ActionsWidth">
                              <img src="images/discussion_icon.png" />
                            </div>
                          </td>
                          <td>Discussion message</td>
                          <td>John Doe</td>
                          <td>06/12/2023 10:30 AM</td>
                          <td>Sent</td>
                          <td>
                            <div className="dropdown CursorPointer">
                              <p data-toggle="dropdown">
                                <span>
                                  <img src="images/three-dots.png" />
                                </span>
                              </p>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuOffset"
                              >
                                <li>
                                  <a
                                    className="dropdown-item FolderPermissionId"
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#EditFollowUpActivity"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/icon-15.png" />
                                    Edit Activity Details
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/dropdown-delete.png" />
                                    Cancel Activity
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/send_to_itinary.png" />
                                    Send to itinery
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/export-to-calendar.png" />
                                    Export to Calender
                                    <img src="images/rightarrow2.png" />
                                  </a>
                                  <ul className="dropdown-menu submenu submenuLead">
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        <img src="images/google-calendar.png" />
                                        Google Calendar
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        <img src="images/outlook-calendar.png" />
                                        Outlook Calender
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        <img src="images/email-calendar.png" />
                                        Email Calender
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/set-reminder.png" />
                                    Set Reminder
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/send_invitaiton.png" />
                                    Send Invitation
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/assign_icon.png" />
                                    Assign Staff member
                                  </a>
                                </li>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="ActionsWidth">
                              <img src="images/send-email.png" />
                            </div>
                          </td>
                          <td>Email</td>
                          <td>John Doe</td>
                          <td>06/12/2023 10:30 AM</td>
                          <td>Sent</td>
                          <td>
                            <div className="dropdown CursorPointer">
                              <p data-toggle="dropdown">
                                <span>
                                  <img src="images/three-dots.png" />
                                </span>
                              </p>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuOffset"
                              >
                                <li>
                                  <a
                                    className="dropdown-item FolderPermissionId"
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#EditFollowUpActivity"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/icon-15.png" />
                                    Edit Activity Details
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/dropdown-delete.png" />
                                    Cancel Activity
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/send_to_itinary.png" />
                                    Send to itinery
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/export-to-calendar.png" />
                                    Export to Calender
                                    <img src="images/rightarrow2.png" />
                                  </a>
                                  <ul className="dropdown-menu submenu submenuLead">
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        <img src="images/google-calendar.png" />
                                        Google Calendar
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        <img src="images/outlook-calendar.png" />
                                        Outlook Calender
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        <img src="images/email-calendar.png" />
                                        Email Calender
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/set-reminder.png" />
                                    Set Reminder
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/send_invitaiton.png" />
                                    Send Invitation
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/assign_icon.png" />
                                    Assign Staff member
                                  </a>
                                </li>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="PaginationArea">
                    <h6>Showing 1 to 10 of 27 entries</h6>
                    <h6>
                      <img src="images/leftarrow.png" /> 01{" "}
                      <img src="images/rightarrow.png" /> Out 10
                    </h6>
                  </div>
                  <div className="Button text-center">
                    <button className="Outline">Cancel</button>
                  </div>
                </form>
              </div>
              <hr />
              <div className="DiscussionArea">
                <div className="DiscussionTitle">
                  <h4>Discussion</h4>
                  <div className="DiscussionChat">
                    <img src="images/down.png" className="DiscussionIcon" />
                    <a href="#">
                      <img src="images/pdf2.png" />
                    </a>
                  </div>
                </div>
                <div className="DiscussionHideArea" style={{ display: "block" }}>
                  <div className="DiscussionBox">
                    <span>FM</span>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Add a Comment ....."
                      />
                      <div className="DiscussionOverlay">
                        <a href="" className="CancelChat">
                          <img src="images/plus-cancel1.png" />
                        </a>
                        <a href="" className="SendChat">
                          <img src="images/forwardbutton.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="DiscussionBox">
                    <span>TH</span>
                    <div className="DiscussionRight">
                      <h6>Inspections are delayed</h6>
                      <small>04/19/2023, at 02:47 pm</small>
                    </div>
                  </div>
                  <div className="ReplyArea">
                    <a href="javascript:void(0);">Reply</a>
                    <div className="DiscussionBox">
                      <span>TH</span>
                      <div className="DiscussionRight">
                        <h6>Inspections are delayed</h6>
                        <small>04/19/2023, at 02:47 pm</small>
                      </div>
                    </div>
                    <div className="DiscussionBox">
                      <span>TH</span>
                      <div className="DiscussionRight">
                        <h6>Inspections are delayed</h6>
                        <small>04/19/2023, at 02:47 pm</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade LargeModal"
        id="LeadDetails2"
        data-backdrop="Static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal ClosePermissionId"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Lead Details</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="ContactInformationArea">
                        <h6>Contact Information</h6>
                        <div className="ContactInformationBox">
                          <span className="circle">JL</span>
                          <div className="ContactInformationName">
                            <h6>John Lead</h6>
                            <p>
                              312 Weddenburn Rd SE <br /> Calgary, Alberta{" "}
                              <img src="images/location.png" />
                            </p>
                            <p>
                              Phone: <span>(403)555-555</span>
                            </p>
                            <p>
                              Email: <span>John.lead@email.com</span>
                            </p>
                          </div>
                          <div className="ContactInformationIcon">
                            <a href="javascript:void(0);">
                              <img src="images/pdf.png" />
                            </a>
                            <a href="javascript:void(0);">
                              <img src="images/download.png" />
                            </a>
                            <a href="javascript:void(0);">
                              <img src="images/icon-75.png" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="TodosButtons mt-4">
                    <a href="javascript:void(0)" className="m-0">
                      <img src="images/general_information.png" />
                      General Information
                    </a>
                    <a className="Create ml-3">
                      <img src="images/timer-red.png" />
                      Follow up Activity<span>3</span>
                    </a>
                  </div>
                  <div className="LeadDetailsInput mt-4">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="CommonModalArea">
                          <div className="form-group">
                            <h5>Assigned to</h5>
                            <div className="CommonModalBox">
                              <h5
                                className="CursorPointer FolderPermissionId"
                                data-toggle="modal"
                                data-target="#AssignSalesRepresentative"
                                data-dismiss="modal"
                              >
                                <figure>
                                  <img src="images/icon-32.png" />
                                </figure>
                                <figure>
                                  <img src="images/refresh.png" />
                                </figure>
                                John Doe
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <h6>Sub Division</h6>
                          <div className="Categories Categories3">
                            <div className="Categories_all Categories_all3">
                              <span>Select lead status</span>
                              <img src="images/down.png" />
                            </div>
                            <ul>
                              <li>-New</li>
                              <li>-In progress</li>
                              <li>-Interested</li>
                              <li>-Not Interested</li>
                              <li>-Cancelled</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="CommonModalArea">
                          <div className="form-group">
                            <h5>Survey</h5>
                            <div className="CommonModalBox">
                              <h5 className="">
                                <figure>
                                  <img src="images/survey.png" />
                                </figure>
                                <span className="Count">3</span>John Doe
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="CommonModalArea">
                          <h5>Lead Age</h5>
                          <div className="CommonModalBox">
                            <h5 className="">&lt; 1day</h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <h6>Status</h6>
                          <div className="Categories Categories2">
                            <div className="Categories_all Categories_all2">
                              <span>Select lead status</span>
                              <img src="images/down.png" />
                            </div>
                            <ul>
                              <li>-New</li>
                              <li>-In progress</li>
                              <li>-Interested</li>
                              <li>-Not Interested</li>
                              <li>-Cancelled</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <h6>Confidence Level</h6>
                          <div className="Categories Categories1">
                            <div className="Categories_all Categories_all1">
                              <span>Select confidence level</span>
                              <img src="images/down.png" />
                            </div>
                            <ul>
                              <li>-Low</li>
                              <li>-Medium</li>
                              <li>-High</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <h6 className="mb-4">Realtor/Referrer</h6>
                      </div>
                      <div className="form-group col-md-5">
                        <h6>Name</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input Name"
                        />
                      </div>
                      <div className="form-group col-md-5 ml-auto">
                        <h6>Agency</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input Agency"
                        />
                      </div>
                      <div className="form-group col-md-5">
                        <h6>Email</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input Email"
                        />
                      </div>
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button className="Create">Save</button>
                      <button className="Create">Convert lead to Contract</button>
                      <button className="Outline">Cancel</button>
                    </div>
                  </div>
                </form>
              </div>
              <hr />
              <div className="DiscussionArea">
                <div className="DiscussionTitle">
                  <h4>Discussion</h4>
                  <div className="DiscussionChat">
                    <img src="images/down.png" className="DiscussionIcon" />
                    <a href="#">
                      <img src="images/pdf2.png" />
                    </a>
                  </div>
                </div>
                <div className="DiscussionHideArea" style={{ display: "block" }}>
                  <div className="DiscussionBox">
                    <span>FM</span>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Add a Comment ....."
                      />
                      <div className="DiscussionOverlay">
                        <a href="" className="CancelChat">
                          <img src="images/plus-cancel1.png" />
                        </a>
                        <a href="" className="SendChat">
                          <img src="images/forwardbutton.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="DiscussionBox">
                    <span>TH</span>
                    <div className="DiscussionRight">
                      <h6>Inspections are delayed</h6>
                      <small>04/19/2023, at 02:47 pm</small>
                    </div>
                  </div>
                  <div className="ReplyArea">
                    <a href="javascript:void(0);">Reply</a>
                    <div className="DiscussionBox">
                      <span>TH</span>
                      <div className="DiscussionRight">
                        <h6>Inspections are delayed</h6>
                        <small>04/19/2023, at 02:47 pm</small>
                      </div>
                    </div>
                    <div className="DiscussionBox">
                      <span>TH</span>
                      <div className="DiscussionRight">
                        <h6>Inspections are delayed</h6>
                        <small>04/19/2023, at 02:47 pm</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade LargeModal"
        id="ClientContractDetails"
        data-backdrop="Static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal ClosePermissionId"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Client Contract Details</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="ContactInformationArea">
                        <h6>Contact Information</h6>
                        <div className="ContactInformationBox">
                          <span className="circle">JL</span>
                          <div className="ContactInformationName">
                            <h6>John Lead</h6>
                            <p>
                              312 Weddenburn Rd SE <br /> Calgary, Alberta{" "}
                              <img src="images/location.png" />
                            </p>
                            <p>
                              Phone: <span>(403)555-555</span>
                            </p>
                            <p>
                              Email: <span>John.lead@email.com</span>
                              <img src="images/fill_star.png" className="ml-2" />
                            </p>
                          </div>
                          <div className="ContactInformationIcon">
                            <a href="javascript:void(0);">
                              <img src="images/pdf.png" />
                            </a>
                            <a href="javascript:void(0);">
                              <img src="images/download.png" />
                            </a>
                            <a href="javascript:void(0);">
                              <img src="images/icon-75.png" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="TodosButtons mt-5">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-toggle="tab"
                          href="#GeneralInformation"
                        >
                          <img src="images/general_information_red.png" />
                          General Information
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="tab"
                          href="#ConditionalDetails"
                        >
                          <img src="images/conditional_details.png" />
                          Conditional Details<span>3</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="tab"
                          href="#AdjustmentsAndDeposit"
                        >
                          <img src="images/adjustment_deposit.png" />
                          Adjustments and Deposits<span>3</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="LeadDetailsInput mt-4">
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="GeneralInformation"
                      >
                        <div className="row">
                          <div className="col-md-3">
                            <div className="CommonModalArea">
                              <div className="form-group">
                                <h5>Assigned to</h5>
                                <div className="CommonModalBox">
                                  <h5
                                    className="CursorPointer FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#AssignSalesRepresentative2"
                                    data-dismiss="modal"
                                  >
                                    <figure>
                                      <img src="images/icon-32.png" />
                                    </figure>
                                    <figure>
                                      <img src="images/refresh.png" />
                                    </figure>
                                    John Doe
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 mx-auto">
                            <div className="form-group">
                              <h6>Sub Division</h6>
                              <div className="Categories Categories3">
                                <div className="Categories_all Categories_all3">
                                  <span>Select SubDivision</span>
                                  <img src="images/down.png" />
                                </div>
                                <ul>
                                  <li>
                                    -List of Subdivisions offered by the builder
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 ml-auto">
                            <div className="CommonModalArea">
                              <div className="form-group">
                                <h5>Status</h5>
                                <div className="CommonModalBox">
                                  <h5 className="">Interested</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <h6 className="mb-4">Realtor/Referrer</h6>
                          </div>
                          <div className="form-group col-md-5">
                            <h6>Name</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Input Name"
                            />
                          </div>
                          <div className="form-group col-md-5 ml-auto">
                            <h6>Agency</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Input Agency"
                            />
                          </div>
                          <div className="form-group col-md-5">
                            <h6>Email</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Input Email"
                            />
                          </div>
                          <div className="col-12">
                            <div className="form-group ">
                              <div className="CommonModalArea">
                                <h5>Configure Client View</h5>
                                <div className="CommonModalBox">
                                  <h5 className="">
                                    <figure>
                                      <img src="images/configure_client_view.png" />
                                    </figure>
                                    Configure Client View
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="Button d-flex justify-content-between mt-4">
                          <button className="Create">Save</button>
                          <button className="Outline">Cancel</button>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="ConditionalDetails">
                        <div className="row">
                          <div className="col-md-5 mx-auto">
                            <div className="form-group">
                              <h6>Sale Type</h6>
                              <div className="Categories Categories3">
                                <div className="Categories_all Categories_all3">
                                  <span>Sale Type</span>
                                  <img src="images/down.png" />
                                </div>
                                <ul>
                                  <li>
                                    -List of sales type for that company loaded
                                    from the systems (for example Multi-Family,
                                    Residential)
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5 mx-auto">
                            <div className="form-group">
                              <h6>Client Assigned Unit</h6>
                              <div className="Categories Categories3">
                                <div className="Categories_all Categories_all3">
                                  <span>Client Assigned Unit</span>
                                  <img src="images/down.png" />
                                </div>
                                <ul>
                                  <li>
                                    -List of available units (no client assined)
                                    loaded from the system based on the sale type
                                    selection
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-11 mx-auto">
                            <div className="Button d-flex justify-content-between mt-4">
                              <button className="Create">Assign Unit</button>
                              <button className="Outline">Cancel</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="AdjustmentsAndDeposit">
                        <div className="SchedulePaymentsTableMain">
                          <div className="TableHeader">
                            <h6 id="SchedulePaymentsBtn">
                              Schedule Payments{" "}
                              <img src="images/down-arrow.png" />
                            </h6>
                            <div className="Actions">
                              <a href="">
                                <img src="images/icon-53.png" />
                              </a>
                              <a href="">
                                <img src="images/download.png" />
                              </a>
                              <a href="">
                                <img src="images/setting.png" />
                              </a>
                            </div>
                          </div>
                          <div className="SchedulePaymentsTable SchedulePaymentsTableShow">
                            <table>
                              <thead>
                                <tr>
                                  <th>Scheduled Payment Date</th>
                                  <th>Transaction Type</th>
                                  <th>Total</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>06/30/2023</td>
                                  <td>DP:Down Payment Deposit</td>
                                  <td>$ 30,000.00</td>
                                  <td>
                                    <div className="TableThreeDots">
                                      <ul className="">
                                        <li className="dropdown">
                                          <a
                                            className=""
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol className="dropdown-menu dropdown-menuwidth2">
                                            <li>
                                              <a href="javascript:void(0);">
                                                <img src="images/work-order-black.png" />
                                                Edit Scheduled Payment
                                              </a>
                                            </li>
                                            <li>
                                              <a href="javascript:void(0);">
                                                <img src="images/dropdown-delete.png" />
                                                Remove Scheduled Payment
                                              </a>
                                            </li>
                                            <li>
                                              <a href="javascript:void(0);">
                                                <img src="images/dropdown-delete.png" />
                                                Create New Transaction Entry
                                              </a>
                                            </li>
                                            <li>
                                              <a href="javascript:void(0);">
                                                <img src="images/dropdown-delete.png" />
                                                Set Payment Reminder
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div>
                                      <input
                                        type="date"
                                        className="form-control"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="SelectAnOption">
                                      <div className="dropdown">
                                        <button
                                          className=""
                                          type="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          Select an option
                                          <img src="images/black-down-arrow.png" />
                                        </button>
                                        <div
                                          className="dropdown-menu"
                                          aria-labelledby="dropdownMenuButton"
                                        >
                                          <a
                                            className="dropdown-item fw-16"
                                            href="javascript:void(0);"
                                          >
                                            <b>Sales</b>
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -ADJ: Other Ajustments
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -ADRJ: Revenue Ajustments
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -CO: Change Order Deposit
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -DP: Down Payment Deposit
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -OP: Offer to Purchase
                                          </a>
                                          <a
                                            className="dropdown-item fw-16"
                                            href="javascript:void(0);"
                                          >
                                            <b>Accounting</b>
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -Balance Due on Closing
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -Closing Transactions
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -Revenue - Change Order
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -Revenue - Contract Price (Pre-Tax)
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -Revenue - Parking
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -Tax - Base
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -Tax - Rebate
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="TableThreeDots">
                                      <ul className="">
                                        <li className="dropdown">
                                          <a
                                            className=""
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol className="dropdown-menu dropdown-menuwidth2">
                                            <li>
                                              <a href="javascript:void(0);">
                                                <img src="images/work-order-black.png" />
                                                Edit Scheduled Payment
                                              </a>
                                            </li>
                                            <li>
                                              <a href="javascript:void(0);">
                                                <img src="images/dropdown-delete.png" />
                                                Remove Scheduled Payment
                                              </a>
                                            </li>
                                            <li>
                                              <a href="javascript:void(0);">
                                                <img src="images/dropdown-delete.png" />
                                                Create New Transaction Entry
                                              </a>
                                            </li>
                                            <li>
                                              <a href="javascript:void(0);">
                                                <img src="images/dropdown-delete.png" />
                                                Set Payment Reminder
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="SchedulePaymentsTableMain mt-5">
                          <div className="TableHeader">
                            <h6 id="TransactionBtn">
                              Transactions
                              <img src="images/down-arrow.png" />
                            </h6>
                            <div className="Actions">
                              <a href="">
                                <img src="images/add_transactions.png" />
                              </a>
                              <a href="">
                                <img src="images/icon-53.png" />
                              </a>
                              <a href="">
                                <img src="images/download.png" />
                              </a>
                              <a href="">
                                <img src="images/setting.png" />
                              </a>
                            </div>
                          </div>
                          <div className="SchedulePaymentsTable TransactionTableShow">
                            <table>
                              <thead>
                                <tr>
                                  <th>Transaction ID#</th>
                                  <th>Transaction Date</th>
                                  <th>Payment Method</th>
                                  <th>Transaction Type</th>
                                  <th>Total</th>
                                  <th>Notes</th>
                                  <th>Supporting Documents</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>ADJR-ARB-1B-243-1</td>
                                  <td>06/12/2023</td>
                                  <td>American Express</td>
                                  <td>ADRJ: Revenue Adjustments</td>
                                  <td>$ 500.00</td>
                                  <td>Price Updates</td>
                                  <td>
                                    <div className="Actions">
                                      <a
                                        href="javascript:void(0);"
                                        data-toggle="modal"
                                        data-target="#AddNewSupportingDocs2"
                                      >
                                        <img src="images/icon-63.png" />
                                      </a>
                                      <a
                                        href="javascript:void(0)"
                                        data-toggle="modal"
                                        data-target="#SupportingDocumentList"
                                      >
                                        <span className="Count">3</span>
                                      </a>
                                    </div>
                                  </td>
                                  <td>
                                    <button className="Commited">Commited</button>
                                  </td>
                                </tr>
                                <tr>
                                  <td>DP-ARB-1B-243-2</td>
                                  <td>06/12/2023</td>
                                  <td>Cheque</td>
                                  <td>DP : Down Payment Depsoit</td>
                                  <td>$ 45,000.00</td>
                                  <td>Cheque number #123</td>
                                  <td>
                                    <div className="Actions">
                                      <a
                                        href="javascript:void(0);"
                                        data-toggle="modal"
                                        data-target="#AddNewSupportingDocs2"
                                      >
                                        <img src="images/icon-63.png" />
                                      </a>
                                      <a
                                        href="javascript:void(0)"
                                        data-toggle="modal"
                                        data-target="#SupportingDocumentList"
                                      >
                                        <span className="Count">3</span>
                                      </a>
                                    </div>
                                  </td>
                                  <td>
                                    <button className="Commited">Commited</button>
                                  </td>
                                </tr>
                                <tr>
                                  <td>DP-ARB-1B-243-2</td>
                                  <td>06/12/2023</td>
                                  <td>
                                    <div className="SelectAnOption">
                                      <div className="dropdown">
                                        <button
                                          className=""
                                          type="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          Select an option
                                          <img src="images/black-down-arrow.png" />
                                        </button>
                                        <div
                                          className="dropdown-menu"
                                          aria-labelledby="dropdownMenuButton"
                                        >
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -Ajustments
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -American Express
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -Bank Draft
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -Cash
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -Cheque
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -Direct Deposit
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -Joural Entry
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -Mastercard
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -Visa
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="SelectAnOption">
                                      <div className="dropdown">
                                        <button
                                          className=""
                                          type="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          Select an option
                                          <img src="images/black-down-arrow.png" />
                                        </button>
                                        <div
                                          className="dropdown-menu"
                                          aria-labelledby="dropdownMenuButton"
                                        >
                                          <a
                                            className="dropdown-item fw-16"
                                            href="javascript:void(0);"
                                          >
                                            <b>Sales</b>
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -ADJ: Other Ajustments
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -ADRJ: Revenue Ajustments
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -CO: Change Order Deposit
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -DP: Down Payment Deposit
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            -OP: Offer to Purchase
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      placeholder="Insert Amount"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      placeholder="Insert notes"
                                    />
                                  </td>
                                  <td>
                                    <div className="Actions">
                                      <a
                                        href="javascript:void(0);"
                                        data-toggle="modal"
                                        data-target="#AddNewSupportingDocs2"
                                      >
                                        <img src="images/icon-63.png" />
                                      </a>
                                      <a
                                        href="javascript:void(0)"
                                        data-toggle="modal"
                                        data-target="#SupportingDocumentList"
                                      >
                                        <span className="Count">3</span>
                                      </a>
                                    </div>
                                  </td>
                                  <td />
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <hr className="HrLine" />
              <div className="DiscussionArea">
                <div className="DiscussionTitle bg_f7">
                  <h4>Discussion</h4>
                  <div className="DiscussionChat">
                    <img src="images/down.png" className="DiscussionIcon" />
                    <a href="#">
                      <img src="images/pdf2.png" />
                    </a>
                  </div>
                </div>
                <div className="DiscussionHideArea" style={{ display: "block" }}>
                  <div className="DiscussionBox">
                    <span>FM</span>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Add a Comment ....."
                      />
                      <div className="DiscussionOverlay">
                        <a href="" className="CancelChat">
                          <img src="images/plus-cancel1.png" />
                        </a>
                        <a href="" className="SendChat">
                          <img src="images/forwardbutton.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="DiscussionBox">
                    <span>TH</span>
                    <div className="DiscussionRight">
                      <h6>Inspections are delayed</h6>
                      <small>04/19/2023, at 02:47 pm</small>
                    </div>
                  </div>
                  <div className="ReplyArea">
                    <a href="javascript:void(0);">Reply</a>
                    <div className="DiscussionBox">
                      <span>TH</span>
                      <div className="DiscussionRight">
                        <h6>Inspections are delayed</h6>
                        <small>04/19/2023, at 02:47 pm</small>
                      </div>
                    </div>
                    <div className="DiscussionBox">
                      <span>TH</span>
                      <div className="DiscussionRight">
                        <h6>Inspections are delayed</h6>
                        <small>04/19/2023, at 02:47 pm</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade LargeModal"
        id="ClientContractDetails2"
        data-backdrop="Static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal ClosePermissionId"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Client Contract Details</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="ContactInformationArea">
                        <h6>Contact Information</h6>
                        <div className="ContactInformationBox">
                          <span className="circle">JL</span>
                          <div className="ContactInformationName">
                            <h6>John Lead</h6>
                            <p>
                              312 Weddenburn Rd SE <br /> Calgary, Alberta{" "}
                              <img src="images/location.png" />
                            </p>
                            <p>
                              Phone: <span>(403)555-555</span>
                            </p>
                            <p>
                              Email: <span>John.lead@email.com</span>
                              <img src="images/fill_star.png" className="ml-2" />
                            </p>
                          </div>
                          <div className="ContactInformationIcon">
                            <a href="javascript:void(0);">
                              <img src="images/pdf.png" />
                            </a>
                            <a href="javascript:void(0);">
                              <img src="images/download.png" />
                            </a>
                            <a href="javascript:void(0);">
                              <img src="images/icon-75.png" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="TodosButtons mt-4">
                    <a href="javascript:void(0)" className="m-0">
                      <img src="images/general_information.png" />
                      General Information
                    </a>
                    <a className="Create ml-3">
                      <img src="images/conditional_details.png" />
                      Conditional Details<span>3</span>
                    </a>
                    <a className="Create ml-3">
                      <img src="images/adjustment_deposit.png" />
                      Adjustments and Deposits<span>3</span>
                    </a>
                  </div>
                  <div className="LeadDetailsInput mt-4">
                    <div className="row">
                      <div className="col-md-5 mx-auto">
                        <div className="form-group">
                          <h6>Sale Type</h6>
                          <div className="Categories Categories3">
                            <div className="Categories_all Categories_all3">
                              <span>Sale Type</span>
                              <img src="images/down.png" />
                            </div>
                            <ul>
                              <li>
                                -List of sales type for that company loaded from
                                the systems (for example Multi-Family,
                                Residential)
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 mx-auto">
                        <div className="form-group">
                          <h6>Client Assigned Unit</h6>
                          <div className="Categories Categories3">
                            <div className="Categories_all Categories_all3">
                              <span>Client Assigned Unit</span>
                              <img src="images/down.png" />
                            </div>
                            <ul>
                              <li>
                                -List of available units (no client assined)
                                loaded from the system based on the sale type
                                selection
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-11 mx-auto">
                        <div className="Button d-flex justify-content-between mt-4">
                          <button className="Create">Assign Unit</button>
                          <button className="Outline">Cancel</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <hr className="HrLine" />
              <div className="DiscussionArea">
                <div className="DiscussionTitle bg_f7">
                  <h4>Discussion</h4>
                  <div className="DiscussionChat">
                    <img src="images/down.png" className="DiscussionIcon" />
                    <a href="#">
                      <img src="images/pdf2.png" />
                    </a>
                  </div>
                </div>
                <div className="DiscussionHideArea" style={{ display: "block" }}>
                  <div className="DiscussionBox">
                    <span>FM</span>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Add a Comment ....."
                      />
                      <div className="DiscussionOverlay">
                        <a href="" className="CancelChat">
                          <img src="images/plus-cancel1.png" />
                        </a>
                        <a href="" className="SendChat">
                          <img src="images/forwardbutton.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="DiscussionBox">
                    <span>TH</span>
                    <div className="DiscussionRight">
                      <h6>Inspections are delayed</h6>
                      <small>04/19/2023, at 02:47 pm</small>
                    </div>
                  </div>
                  <div className="ReplyArea">
                    <a href="javascript:void(0);">Reply</a>
                    <div className="DiscussionBox">
                      <span>TH</span>
                      <div className="DiscussionRight">
                        <h6>Inspections are delayed</h6>
                        <small>04/19/2023, at 02:47 pm</small>
                      </div>
                    </div>
                    <div className="DiscussionBox">
                      <span>TH</span>
                      <div className="DiscussionRight">
                        <h6>Inspections are delayed</h6>
                        <small>04/19/2023, at 02:47 pm</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade LargeModal"
        id="ClientContractDetails3"
        data-backdrop="Static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal ClosePermissionId"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Client Contract Details</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="ContactInformationArea">
                        <h6>Contact Information</h6>
                        <div className="ContactInformationBox">
                          <span className="circle">JL</span>
                          <div className="ContactInformationName">
                            <h6>John Lead</h6>
                            <p>
                              312 Weddenburn Rd SE <br /> Calgary, Alberta{" "}
                              <img src="images/location.png" className="ml-2" />
                            </p>
                            <p>
                              Phone: <span>(403)555-555</span>
                            </p>
                            <p>
                              Email: <span>John.lead@email.com</span>
                              <img src="images/fill_star.png" className="ml-2" />
                            </p>
                          </div>
                          <div className="ContactInformationIcon">
                            <a href="javascript:void(0);">
                              <img src="images/pdf.png" />
                            </a>
                            <a href="javascript:void(0);">
                              <img src="images/download.png" />
                            </a>
                            <a href="javascript:void(0);">
                              <img src="images/icon-75.png" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="TodosButtons mt-4 d-flex justify-content-between">
                    <a href="javascript:void(0)" className="m-0">
                      <img src="images/general_information.png" />
                      General Information
                    </a>
                    <a className="Create ml-3">
                      <img src="images/timer-red.png" />
                      Conditional Details<span>3</span>
                    </a>
                    <a className="Create ml-3">
                      <img src="images/timer-red.png" />
                      Adjustments and Deposit<span>3</span>
                    </a>
                  </div>
                  <div className="LeadDetailsInput mt-4">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="CommonModalArea">
                          <div className="form-group">
                            <h5>Assigned to</h5>
                            <div className="CommonModalBox">
                              <h5
                                className="CursorPointer FolderPermissionId"
                                data-toggle="modal"
                                data-target="#AssignSalesRepresentative"
                                data-dismiss="modal"
                              >
                                <figure>
                                  <img src="images/icon-32.png" />
                                </figure>
                                <figure>
                                  <img src="images/refresh.png" />
                                </figure>
                                John Doe
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <h6>Sub Division</h6>
                          <div className="Categories Categories3">
                            <div className="Categories_all Categories_all3">
                              <span>Select lead status</span>
                              <img src="images/down.png" />
                            </div>
                            <ul>
                              <li>-New</li>
                              <li>-In progress</li>
                              <li>-Interested</li>
                              <li>-Not Interested</li>
                              <li>-Cancelled</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="CommonModalArea">
                          <div className="form-group">
                            <h5>Survey</h5>
                            <div className="CommonModalBox">
                              <h5 className="">
                                <figure>
                                  <img src="images/survey.png" />
                                </figure>
                                <span className="Count">3</span>John Doe
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="CommonModalArea">
                          <h5>Lead Age</h5>
                          <div className="CommonModalBox">
                            <h5 className="">&lt; 1day</h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <h6>Status</h6>
                          <div className="Categories Categories2">
                            <div className="Categories_all Categories_all2">
                              <span>Select lead status</span>
                              <img src="images/down.png" />
                            </div>
                            <ul>
                              <li>-New</li>
                              <li>-In progress</li>
                              <li>-Interested</li>
                              <li>-Not Interested</li>
                              <li>-Cancelled</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <h6>Confidence Level</h6>
                          <div className="Categories Categories1">
                            <div className="Categories_all Categories_all1">
                              <span>Select confidence level</span>
                              <img src="images/down.png" />
                            </div>
                            <ul>
                              <li>-Low</li>
                              <li>-Medium</li>
                              <li>-High</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-5">
                        <h6>Client Purchase Price</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert deposit amount"
                        />
                      </div>
                      <div className="form-group col-md-5 ml-auto">
                        <h6>Deposit Amount</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert deposit amount"
                        />
                      </div>
                      <div className="form-group col-md-5">
                        <h6>Down Payment Percentage</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Down Payment Percentage"
                        />
                      </div>
                    </div>
                    <div className="Button">
                      <button className="Create">Add</button>
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button className="Create">Save</button>
                      <button className="Outline">Cancel</button>
                    </div>
                  </div>
                </form>
              </div>
              <hr />
              <div className="DiscussionArea">
                <div className="DiscussionTitle">
                  <h4>Discussion</h4>
                  <div className="DiscussionChat">
                    <img src="images/down.png" className="DiscussionIcon" />
                    <a href="#">
                      <img src="images/pdf2.png" />
                    </a>
                  </div>
                </div>
                <div className="DiscussionHideArea" style={{ display: "block" }}>
                  <div className="DiscussionBox">
                    <span>FM</span>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Add a Comment ....."
                      />
                      <div className="DiscussionOverlay">
                        <a href="" className="CancelChat">
                          <img src="images/plus-cancel1.png" />
                        </a>
                        <a href="" className="SendChat">
                          <img src="images/forwardbutton.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="DiscussionBox">
                    <span>TH</span>
                    <div className="DiscussionRight">
                      <h6>Inspections are delayed</h6>
                      <small>04/19/2023, at 02:47 pm</small>
                    </div>
                  </div>
                  <div className="ReplyArea">
                    <a href="javascript:void(0);">Reply</a>
                    <div className="DiscussionBox">
                      <span>TH</span>
                      <div className="DiscussionRight">
                        <h6>Inspections are delayed</h6>
                        <small>04/19/2023, at 02:47 pm</small>
                      </div>
                    </div>
                    <div className="DiscussionBox">
                      <span>TH</span>
                      <div className="DiscussionRight">
                        <h6>Inspections are delayed</h6>
                        <small>04/19/2023, at 02:47 pm</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade MediumModal"
        id="AssignUnit"
        data-backdrop="Static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Assign Unit</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="ContactInformationArea">
                        <h6>Contact Information</h6>
                        <div className="ContactInformationBox">
                          <span className="circle">JL</span>
                          <div className="ContactInformationName">
                            <h6>John Lead</h6>
                            <p>
                              312 Weddenburn Rd SE <br /> Calgary, Alberta{" "}
                              <img src="images/location.png" />
                            </p>
                            <p>
                              Phone: <span>(403)555-555</span>
                            </p>
                            <p>
                              Email: <span>John.lead@email.com</span>
                            </p>
                          </div>
                          <div className="ContactInformationIcon">
                            <a href="javascript:void(0);">
                              <img src="images/pdf.png" />
                            </a>
                            <a href="javascript:void(0);">
                              <img src="images/download.png" />
                            </a>
                            <a href="javascript:void(0);">
                              <img src="images/icon-75.png" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="LeadDetailsInput mt-4">
                    <div className="row">
                      <div className="col-md-5 mx-auto">
                        <div className="form-group">
                          <h6>Sales Type</h6>
                          <div className="Categories Categories3">
                            <div className="Categories_all Categories_all3">
                              <span>Select Sale Type</span>
                              <img src="images/down.png" />
                            </div>
                            <ul>
                              <li>
                                -List of sales type for that company loaded from
                                the systems (for example Multi-Family,
                                Residential)
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 mx-auto">
                        <div className="form-group">
                          <h6>Client Assigned Unit</h6>
                          <div className="Categories Categories2">
                            <div className="Categories_all Categories_all2">
                              <span>Select available unit</span>
                              <img src="images/down.png" />
                            </div>
                            <ul>
                              <li>
                                -List of available units (no client assined)
                                loaded from the system based on the sale type
                                selection
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-11 mx-auto">
                        <div className="Button d-flex justify-content-between">
                          <button className="Create" data-dismiss="modal">
                            Assign Unit
                          </button>
                          <button className="Outline" data-dismiss="modal">
                            {" "}
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade MediumModal"
        id="ItineraryItemDetails"
        data-backdrop="Static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Itinerary Item Details</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="d-flex justify-content-end">
                    <h6>Itinerary Item: #256</h6>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-5">
                      <h6>Type</h6>
                      <div className="CommonSelectBox">
                        <select>
                          <option selected="">Meeting</option>
                          <option>India</option>
                          <option>USA</option>
                          <option>Australia</option>
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </div>
                    <div className="form-group col-md-5 ml-auto">
                      <h6>Location</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert Location"
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <h6>Date</h6>
                      <input type="date" className="form-control" />
                    </div>
                    <div className="form-group col-md-4">
                      <h6>From</h6>
                      <input type="time" className="form-control" />
                    </div>
                    <div className="form-group col-md-4">
                      <h6>To</h6>
                      <input type="time" className="form-control" />
                    </div>
                  </div>
                  <div className="CommonModalAreaMain align-items-start mt-2">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Set Reminder</h5>
                        <div className="CommonModalBox">
                          <h5 className="">
                            <figure>
                              <img src="images/timer.png" />
                            </figure>{" "}
                            Set Reminder
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Assign Staff Member</h5>
                        <div className="CommonModalBox">
                          <h5 className="">
                            <figure>
                              <img src="images/assign-resources.png" />
                            </figure>{" "}
                            <span className="Count">3</span> Assign
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="StaffMemberArea mt-0">
                      <div className="form-group">
                        <h6>Assign Staff member</h6>
                        <div className="StaffBox">
                          <div className="StaffName">
                            <p>John Doe</p>
                            <img src="images/Cross.png" />
                          </div>
                          <div className="StaffName">
                            <p>John Williams</p>
                            <img src="images/Cross.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Button d-flex justify-content-center">
                    <button className="Create mr-3">
                      Update <img src="images/down-arrow-white.png" />
                    </button>
                    <button className="Outline ml-3">Cancel</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade MediumModal"
        id="ScheduleNewFollowUpActivity"
        data-backdrop="Static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-toggle="modal"
              data-target="#LeadDetails"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Schedule New Follow up activity</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="d-flex justify-content-end">
                    <h6>Itinerary Item: #256</h6>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-5">
                      <h6>Type</h6>
                      <div className="CommonSelectBox">
                        <select>
                          <option selected="">Select Type</option>
                          <option>-Meeting</option>
                          <option>-Phone call</option>
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </div>
                    <div className="form-group col-md-5 ml-auto">
                      <h6>Location</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert Location"
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <h6>Date</h6>
                      <input type="date" className="form-control" />
                    </div>
                    <div className="form-group col-md-4">
                      <h6>From</h6>
                      <input type="time" className="form-control" />
                    </div>
                    <div className="form-group col-md-4">
                      <h6>To</h6>
                      <input type="time" className="form-control" />
                    </div>
                  </div>
                  <div className="CommonModalAreaMain align-items-start mt-2">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Set Reminder</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#ReminderModal"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/timer.png" />
                            </figure>{" "}
                            Set Reminder
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Assign Staff Member</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#AssignStaffMember"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/assign-resources.png" />
                            </figure>{" "}
                            <span className="Count">3</span> Assign
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="StaffMemberArea mt-0">
                      <div className="form-group">
                        <h6>Assign Staff member</h6>
                        <div className="StaffBox">
                          <div className="StaffName">
                            <p>John Doe</p>
                            <img src="images/Cross.png" />
                          </div>
                          <div className="StaffName">
                            <p>John Williams</p>
                            <img src="images/Cross.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Button d-flex justify-content-center">
                    <button
                      className="Create mr-3"
                      data-toggle="modal"
                      data-target="#LeadDetails"
                      data-dismiss="modal"
                    >
                      Schedule <img src="images/down-arrow-white.png" />
                    </button>
                    <button
                      className="Outline ml-3"
                      data-toggle="modal"
                      data-target="#LeadDetails"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade MediumModal"
        id="EditFollowUpActivity"
        data-backdrop="Static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Edit Follow up activity</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="d-flex justify-content-end">
                    <h6>Itinerary Item: #256</h6>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-5">
                      <h6>Type</h6>
                      <div className="CommonSelectBox">
                        <select>
                          <option selected="">Select Type</option>
                          <option>-Meeting</option>
                          <option>-Phone call</option>
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </div>
                    <div className="form-group col-md-5 ml-auto">
                      <h6>Location</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert Location"
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <h6>Date</h6>
                      <input type="date" className="form-control" />
                    </div>
                    <div className="form-group col-md-4">
                      <h6>From</h6>
                      <input type="time" className="form-control" />
                    </div>
                    <div className="form-group col-md-4">
                      <h6>To</h6>
                      <input type="time" className="form-control" />
                    </div>
                  </div>
                  <div className="CommonModalAreaMain align-items-start mt-2">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Set Reminder</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#ReminderModal2"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/timer.png" />
                            </figure>{" "}
                            Set Reminder
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Assign Staff Member</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#AssignStaffMember2"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/assign-resources.png" />
                            </figure>{" "}
                            <span className="Count">3</span> Assign
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="StaffMemberArea mt-0">
                      <div className="form-group">
                        <h6>Assign Staff member</h6>
                        <div className="StaffBox">
                          <div className="StaffName">
                            <p>John Doe</p>
                            <img src="images/Cross.png" />
                          </div>
                          <div className="StaffName">
                            <p>John Williams</p>
                            <img src="images/Cross.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Button d-flex justify-content-center">
                    <button
                      className="Create mr-3"
                      data-toggle="modal"
                      data-target="#LeadDetails"
                      data-dismiss="modal"
                    >
                      Schedule <img src="images/down-arrow-white.png" />
                    </button>
                    <button
                      className="Outline ml-3"
                      data-toggle="modal"
                      data-target="#LeadDetails"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade SmallModal"
        id="ImportContactInformation"
        data-backdrop="static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-toggle="modal"
              data-target="#CreateNewLead"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Import Contact Information</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="form-group">
                    <h6>Select File (Ibuild or xlsx)</h6>
                    <div className="Upload">
                      <span>-Drag and Drop Files or click to select</span>
                      <input type="file" />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="FilesAdded">
                      <label>file_name.pdf</label>
                      <span>
                        <img src="images/Cross.png" />
                      </span>
                    </div>
                  </div>
                  <div className="Button d-flex justify-content-between ">
                    <button
                      className="Create"
                      data-toggle="modal"
                      data-target="#CreateNewLead"
                      data-dismiss="modal"
                    >
                      Import Contact
                    </button>
                    <button
                      className="Outline"
                      data-toggle="modal"
                      data-target="#CreateNewLead"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade SmallModal show"
        id="AssignStaffMember"
        data-backdrop="Static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              data-toggle="modal"
              data-target="#ScheduleNewFollowUpActivity"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Assign Staff Member</h4>
              </div>
              <div className="ModalTitleHeading">
                <h2>Staff Member Information</h2>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="form-group">
                    <h6>Name</h6>
                    <div className="Categories Categories1">
                      <div className="Categories_all Categories_all1">
                        <span>Select a Staff Member</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>List of Staff Members loaded from the system</li>
                      </ul>
                    </div>
                  </div>
                  <div className="Button text-center mt-3">
                    <button
                      className="Create"
                      data-toggle="modal"
                      data-target="#ScheduleNewFollowUpActivity"
                      data-dismiss="modal"
                    >
                      Assign
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade SmallModal show"
        id="AssignStaffMember2"
        data-backdrop="Static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              data-toggle="modal"
              data-target="#EditFollowUpActivity"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Assign Staff Member</h4>
              </div>
              <div className="ModalTitleHeading">
                <h2>Staff Member Information</h2>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="form-group">
                    <h6>Name</h6>
                    <div className="Categories Categories1">
                      <div className="Categories_all Categories_all1">
                        <span>Select a Staff Member</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>List of Staff Members loaded from the system</li>
                      </ul>
                    </div>
                  </div>
                  <div className="Button text-center mt-3">
                    <button
                      className="Create"
                      data-toggle="modal"
                      data-target="#EditFollowUpActivity"
                      data-dismiss="modal"
                    >
                      Assign
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade SmallModal show"
        id="AssignSalesRepresentative"
        data-backdrop="Static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              data-toggle="modal"
              data-target="#LeadDetails2"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Assign Sales Representative</h4>
              </div>
              <div className="ModalTitleHeading">
                <h2>Staff Member Information</h2>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="form-group">
                    <h6>Name</h6>
                    <div className="Categories Categories1">
                      <div className="Categories_all Categories_all1">
                        <span>Select a Staff Member</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>List of Staff Members loaded from the system</li>
                      </ul>
                    </div>
                  </div>
                  <div className="Button text-center mt-3">
                    <button
                      className="Create"
                      data-toggle="modal"
                      data-target="#LeadDetails2"
                      data-dismiss="modal"
                    >
                      Assign
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade SmallModal show"
        id="SelectVendor"
        data-backdrop="Static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Select Vendor</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="form-group">
                    <h6>Vendor Type</h6>
                    <div className="Categories Categories1">
                      <div className="Categories_all Categories_all1">
                        <span>Select a Vendor Type</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>List of Staff Members loaded from the system</li>
                      </ul>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>Vendor Name</h6>
                    <div className="Categories Categories2">
                      <div className="Categories_all Categories_all2">
                        <span>Select a Vendor Type</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>List of Staff Members loaded from the system</li>
                      </ul>
                    </div>
                  </div>
                  <div className="Button mt-3 d-flex justify-content-between">
                    <button className="Create">Confirm Selection</button>
                    <button className="Outline">Cancel</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade SmallModal show"
        id="AssignSalesRepresentative2"
        data-backdrop="Static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              data-toggle="modal"
              data-target="#ClientContractDetails"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Assign Sales Representative</h4>
              </div>
              <div className="ModalTitleHeading">
                <h2>Staff Member Information</h2>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="form-group">
                    <h6>Name</h6>
                    <div className="Categories Categories1">
                      <div className="Categories_all Categories_all1">
                        <span>Select a Staff Member</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>List of Staff Members loaded from the system</li>
                      </ul>
                    </div>
                  </div>
                  <div className="Button text-center mt-3">
                    <button
                      className="Create"
                      data-toggle="modal"
                      data-target="#ClientContractDetails"
                      data-dismiss="modal"
                    >
                      Assign
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade SmallModal show"
        id="ReminderModal"
        data-backdrop="Static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              data-toggle="modal"
              data-target="#ScheduleNewFollowUpActivity"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Reminder</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="form-group">
                    <h6>Time</h6>
                    <div className="Categories Categories2">
                      <div className="Categories_all Categories_all2">
                        <span>Select amount of timer for reminder</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>-0 minutes</li>
                        <li>-5 minutes</li>
                        <li>-15 minutes</li>
                        <li>-30 minutes</li>
                        <li>-1 hour</li>
                        <li>-12 hour</li>
                        <li>-1 day</li>
                        <li>-1 week</li>
                      </ul>
                    </div>
                  </div>
                  <div className="Button text-center mt-5">
                    <button
                      className="Create"
                      data-toggle="modal"
                      data-target="#ScheduleNewFollowUpActivity"
                      data-dismiss="modal"
                    >
                      Set Reminder
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade SmallModal show"
        id="ReminderModal2"
        data-backdrop="Static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              data-toggle="modal"
              data-target="#EditFollowUpActivity"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Reminder</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="form-group">
                    <h6>Time</h6>
                    <div className="Categories Categories2">
                      <div className="Categories_all Categories_all2">
                        <span>Select amount of timer for reminder</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>-0 minutes</li>
                        <li>-5 minutes</li>
                        <li>-15 minutes</li>
                        <li>-30 minutes</li>
                        <li>-1 hour</li>
                        <li>-12 hour</li>
                        <li>-1 day</li>
                        <li>-1 week</li>
                      </ul>
                    </div>
                  </div>
                  <div className="Button text-center mt-5">
                    <button
                      className="Create"
                      data-toggle="modal"
                      data-target="#EditFollowUpActivity"
                      data-dismiss="modal"
                    >
                      Set Reminder
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade SmallModal" id="NewEmail" data-backdrop="Static">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-toggle="modal"
              data-target="#LeadDetails"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>New Email</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="form-group mb-4">
                    <label className="Radio">
                      Send email
                      <input type="radio" name="Cuisine" defaultValue="text" />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="Radio">
                      Upload client email
                      <input type="radio" name="Cuisine" defaultValue="file" />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="text RadioText">
                    <div className="form-group">
                      <h6>Title</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Input email title"
                      />
                    </div>
                    <div className="form-group">
                      <h6>Message</h6>
                      <textarea
                        className="form-control"
                        rows={4}
                        placeholder="Input Message"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="file RadioText">
                    <div className="form-group">
                      <h6>Select Files</h6>
                      <div className="Upload">
                        <span>-Drag and Drop Files or click to select</span>
                        <input type="file" />
                      </div>
                    </div>
                    <div className="FilesAdded">
                      <label>File_name.docs</label>
                      <span>
                        <img src="images/fileview.png" />
                      </span>
                      <span>
                        <img src="images/Cross.png" className="cross" />
                      </span>
                    </div>
                    <div className="Button">
                      <button className="Create">Upload Email</button>
                    </div>
                  </div>
                  <div className="NewEmailArea">
                    <div className="NewEmailBox">
                      <h4>Title</h4>
                      <p>RE: Follow up</p>
                    </div>
                    <div className="NewEmailBox">
                      <h4>Message</h4>
                      <p>Hi,</p>
                      <p>thank you for reaching out.</p>
                      <p>I have filled the survey document and attached it to</p>
                      <p>the email.</p>
                      <p>Best Reards,</p>
                      <p>John Lead</p>
                    </div>
                    <div className="NewEmailBox">
                      <h4>Attachments</h4>
                      <p>
                        survey.pdf
                        <img src="images/file_name.png" />
                      </p>
                    </div>
                  </div>
                  <div className="Button d-flex justify-content-between mt-4">
                    <button className="Create">Create Email</button>
                    <button className="Outline">Cancel</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade SmallModal"
        id="ViewMessage"
        data-backdrop="Static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-toggle="modal"
              data-target="#NotesList"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>View Message</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="NewEmailArea">
                    <div className="NewEmailTop">
                      <p>
                        <label>Type</label>
                        <span>Email</span>
                      </p>
                      <p>
                        <label>Date</label>
                        <span>06/01/2023 - 12:00 AM</span>
                      </p>
                      <p>
                        <label>Sent by</label>
                        <span>John Lead</span>
                      </p>
                    </div>
                    <div className="NewEmailBox">
                      <h4>Title</h4>
                      <p>RE: Follow up</p>
                    </div>
                    <div className="NewEmailBox">
                      <h4>Message</h4>
                      <p>Hi,</p>
                      <p>thank you for reaching out.</p>
                      <p>I have filled the survey document and attached it to</p>
                      <p>the email.</p>
                      <p>Best Reards,</p>
                      <p>John Lead</p>
                    </div>
                    <div className="NewEmailBox">
                      <h4>Attachments</h4>
                      <p>
                        survey.pdf
                        <img src="images/file_name.png" className="ml-3" />
                      </p>
                    </div>
                  </div>
                  <div className="Button d-flex justify-content-between mt-4">
                    <button className="Create">Reply</button>
                    <button className="Outline">Close</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  
  )
}

export default LeadContractManagment