import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import "../App.css";
import Modal from "react-bootstrap/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Loader from "./Loader";
import { Editor } from "@tinymce/tinymce-react";
import SignaturePad from "react-signature-canvas";
import {
  fileMangerEventHistoryRead,
  getAllCompanyClient,
  getAllDocumentKeyList,
  getAllFolderList,
  getAllTagList,
  getAllVendors,
  getCreateDocumentation,
  getCreateFolder,
  getDocumentationApproval,
  getFileHistoryList,
  getFileMangerEventHistory,
  getFolderByName,
  getFolderRemove,
  getHtmlFile,
  getListDocumentation,
  getManageSignatureList,
  getManageSignatureUpload,
  getRemoveDocumentation,
  getSearchListDocumentation,
  getShareDocumentationAdd,
  getUploadTemplateMainPic,
  getViewDocumentation,
  getpendingFileEventList,
  lockUnlockFiles,
  releaseTemplate,
  removeManageSignatures,
  sendForApprovalDocument,
  updateEditDocumentation,
} from "../redux/action/fileManagerAction";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { addSignatureAction } from "../redux/action/clientDocumentationAction";
import moment from "moment";
import htmlDocx from "html-docx-js/dist/html-docx";
import { saveAs } from "file-saver";
import { convertToPNG, dataURLtoBlob } from "../utils/blog";
import { getAllProjectList } from "../redux/action/vendorViewConfigAction";
import { uploadAggrementFiles } from "../redux/action/designSelectionsAction";
import {
  getAllStaffListAction,
  getConverToString,
} from "../redux/action/saleAction";
import axios from "axios";
const initialState = {
  folderName: "",
  CreateFolderModal: false,
  ManageSignatueModal: false,
  signature: "",
  initial: "",
  errors: {},
  NewFileModal: false,
  fileFolderName: "",
  clientFile: "",
  DocumentationDeleteModal: false,
  docId: "",
  ShareClientDocumentationModal: false,
  emailBody: "",
  clientEmail: "",
  clientName: "",
  emails: [{ name: "", email: "" }],
  folderId: "",
  id: "",
  SendForApprovalModal: false,
  approverType: "",
  contactSelection: [{ name: "", email: "" }],
  UploadTemplateModal: false,
  templateFile: "",
  ViewDocumentationModal: false,
  viewDocumentData: "",
  ApproveDocumentationModal: false,
  FolderDeleteModal: false,
  EditDocumentationModal: false,
  content: "",
  viewDocumentEditData: "",
  RelaseTemplateModal: false,
  LockTemplateModal: false,
  FilesHistoryModal: false,
  DocumentKeyModal: false,
  documentSearch: "",
  JobId: "",
  HeaderPreviewModal: false,
  documentData: [],
  projectId: "",
  folderData: [],
  folderDatas: [],
  loading: false,
  fileNames: "",
  InsertSignatureModal: false,
  htmlUrls: "",
  docUrls: "",
  draft: false,
  clientId: "",
  staffId: "",
  vendorId: "",
  htmlUrl: "",
};

const FileManager = () => {
  const dispatch = useDispatch();
  const signCanvas = useRef({});
  const intialCanvas = useRef({});
  const editorRef = useRef("");
  const [iState, updateState] = useState(initialState);
  const {
    folderName,
    CreateFolderModal,
    ManageSignatueModal,
    signature,
    initial,
    errors,
    NewFileModal,
    fileFolderName,
    clientFile,
    DocumentationDeleteModal,
    docId,
    ShareClientDocumentationModal,
    emailBody,
    clientName,
    clientEmail,
    emails,
    folderId,
    id,
    SendForApprovalModal,
    approverType,
    contactSelection,
    UploadTemplateModal,
    templateFile,
    ViewDocumentationModal,
    ApproveDocumentationModal,
    FolderDeleteModal,
    EditDocumentationModal,
    viewDocumentData,
    content,
    viewDocumentEditData,
    RelaseTemplateModal,
    LockTemplateModal,
    FilesHistoryModal,
    DocumentKeyModal,
    documentSearch,
    JobId,
    HeaderPreviewModal,
    projectId,
    folderData,
    folderDatas,
    loading,
    fileNames,
    InsertSignatureModal,
    htmlUrls,
    docUrls,
    draft,
    clientId,
    staffId,
    vendorId,
    htmlUrl,
  } = iState;
  const {
    allFolderList,
    searchFolderList,
    documentationList,
    searchDocumentationList,
    viewDocumentation,
    tagList,
    pendingFileList,
    fileHistoryList,
    loader,
    pendingLoader,
    folderLoader,
    allDocumentKey,
    manageSignatureList,
    allVendorList,
    allComapnyClientList,
    fileMangerEventHistoryList,
  } = useSelector((state) => state.fileManagerData);
  const { staffListData } = useSelector((state) => state.salesReducer);

  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

  useEffect(() => {
    dispatch(getAllFolderList());
    dispatch(getAllDocumentKeyList());
    dispatch(getAllVendors());
    dispatch(getAllStaffListAction());
    dispatch(getAllCompanyClient());
    dispatch(getFileMangerEventHistory());
  }, []);

  // const handlePendingEventList = (id) => {
  //   dispatch(getpendingFileEventList(id));
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "folderId") {
      dispatch(getListDocumentation(value)).then((res) => {
        if (res.status === 200) {
          updateState({
            ...iState,
            folderData: res?.data?.clientDocDetails,
            [name]: value,
          });
        }
      });
    } else if (name === "clientId") {
      const dummyClietData = allComapnyClientList?.clientDetails?.find(
        (item) => item.id == value
      );
      if (dummyClietData) {
        updateState({
          ...iState,
          [name]: value,
          contactSelection: [
            {
              name: dummyClietData?.name || "",
              email: dummyClietData?.email || "",
            },
          ],
        });
      }
    } else if (name === "staffId") {
      const dummyStaffData = staffListData?.staff?.find(
        (item) => item.id == value
      );
      if (dummyStaffData) {
        updateState({
          ...iState,
          [name]: value,
          contactSelection: [
            {
              name: dummyStaffData?.name || "",
              email: dummyStaffData?.email || "",
            },
          ],
        });
      }
    } else if (name === "vendorId") {
      const dummyVendorData = allVendorList?.VendorList?.find(
        (item) => item.id == value
      );
      if (dummyVendorData) {
        updateState({
          ...iState,
          [name]: value,
          contactSelection: [
            {
              name: dummyVendorData?.vendorName || "",
              email: dummyVendorData?.email || "",
            },
          ],
        });
      }
    } else {
      updateState({ ...iState, [name]: value });
    }
  };

  const handleCreateFolderShow = () => {
    updateState({
      ...iState,
      CreateFolderModal: true,
      folderName: "",
    });
  };

  const handleCreateFolderClose = () => {
    updateState({
      ...iState,
      CreateFolderModal: false,
      folderName: "",
    });
  };

  const [searchName, setSearchName] = useState("");
  const [searchFolderName, setSearchFolderName] = useState("");
  useEffect(() => {
    if (searchName) {
      dispatch(getFolderByName({ name: searchName }));
    }
  }, [searchName, dispatch]);

  const [folderIDD, setFolderIDD] = useState("");

  const handleTagDocument = (id) => {
    setFolderIDD(id);
    dispatch(getAllTagList(id));
    window.localStorage.setItem("folderID", JSON.stringify(id));
    dispatch(getListDocumentation(id));
    // dispatch(getpendingFileEventList(id));
  };

  useEffect(() => {
    const id = JSON.parse(window.localStorage.getItem("folderID"));

    if (searchFolderName) {
      dispatch(getSearchListDocumentation(id, { name: searchFolderName }));
    }
  }, [searchFolderName, id, dispatch]);

  const handleCreateFolderSubmit = (e) => {
    e.preventDefault();
    // let formIsValid = handleValidation();
    // if (formIsValid) {
    updateState({ ...iState, loading: true });
    const dataa = {
      folderName,
    };
    dispatch(getCreateFolder(dataa))
      .then((res) => {
        dispatch(getAllFolderList());
        toast.success("Folder created successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          folderName: "",
          CreateFolderModal: false,
        });
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, loading: false, CreateFolderModal: true });
      });
  };

  // }

  const handleManageSignatueShow = () => {
    updateState({
      ...iState,
      ManageSignatueModal: true,
      EditDocumentationModal: false,
      signature: "",
      initial: "",
      errors: {},
    });
  };

  const handleManageSignatueClose = () => {
    updateState({
      ...iState,
      ManageSignatueModal: false,
      signature: "",
      initial: "",
      errors: {},
    });
  };
  const handleFileChange = (event, type) => {
    const file = event?.target?.files[0];
    if (type === "sign1") {
      updateState({ ...iState, signature: file });
    } else if (type === "sign2") {
      updateState({ ...iState, initial: file });
    } else if (type === "template") {
      updateState({ ...iState, templateFile: file });
    } else {
      updateState({ ...iState, clientFile: file ,fileFolderName:file?.name});
    }
  };

  const handleSignatureValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!signature) {
      error.signatureError = "* Signature can't be empty";
      formIsValid = false;
    }

    if (!initial) {
      error.initialError = "* Initial can't be empty";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: error,
    });
    return formIsValid;
  };

  const clearSignature = () => {
    signCanvas.current.clear();
  };
  const clearInitials = () => {
    intialCanvas.current.clear();
  };

  const addSignatureFun = () => {
    // let formIsValid = handleSignatureValidation();
    // if (formIsValid) {

    const signatureBlob = dataURLtoBlob(signature);
    const initialBlob = dataURLtoBlob(initial);
    const convertToValidFormat = (blob, fileName) => {
      const fileExtension = fileName.split(".").pop().toLowerCase();
      if (
        ![
          "jpg",
          "jpeg",
          "png",
          "gif",
          "pdf",
          "bmp",
          "webp",
          "svg+xml",
        ].includes(fileExtension)
      ) {
        const convertedBlob = convertToPNG(blob);
        return convertedBlob;
      }
      return blob;
    };
    const validSignatureBlob = convertToValidFormat(
      signatureBlob,
      "signature.png"
    );
    const validInitialBlob = convertToValidFormat(initialBlob, "initial.png");
    const formData = new FormData();
    formData.append("signature", validSignatureBlob, "signature.png");
    formData.append("initial", validInitialBlob, "initial.png");
    formData.append("imageOwner", "test");
    dispatch(getManageSignatureUpload(formData))
      .then((res) => {
        if (res.status === 201) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            ManageSignatueModal: false,
            signature: null,
            initial: null,
          });
        } else {
          toast.error("Failed to upload signature", {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, ManageSignatueModal: true });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, ManageSignatueModal: true });
      });
  };
  // };

  const handleNewFileShow = () => {
    updateState({
      ...iState,
      NewFileModal: true,
      errors: {},
      fileFolderName: "",
      clientFile: "",
      id: "",
    });
  };

  const handleNewFileClose = () => {
    updateState({
      ...iState,
      NewFileModal: false,
      errors: {},
      fileFolderName: "",
      clientFile: "",
      id: "",
    });
  };

  const handleNewFileValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!fileFolderName) {
      error.nameError = "* Name can't be empty";
      formIsValid = false;
    }

    if (!id) {
      error.foldNameError = "* Please select folder name";
      formIsValid = false;
    }

    if (!clientFile) {
      error.cltfileError = "* Please select file";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: error,
    });
    return formIsValid;
  };

  const handleRemoveClientFile = () => {
    // Reset the 'templateFile' state to null
    updateState({ ...iState, clientFile: null });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#createFileInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleNewFileSubmit = async () => {
    try {
      let fileUrl = "";
      if (htmlUrl) {
        const blobs = new Blob([htmlUrl], { type: "text/html" });
        const formData = new FormData();
        formData.append("files", blobs);
        const uploadResponse = await dispatch(uploadAggrementFiles(formData));
        if (uploadResponse.status === 200) {
          fileUrl = uploadResponse?.data?.data;
          //  const docxBlob =  await htmlDocx.asBlob(fileUrl)
          //  const fileNameData = await `${fileFolderName}`;
          //  var docxFile =  await new File([docxBlob],`${fileNameData}.docx`, {
          //    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          //  });
        }
      }

      const data = {
        name: fileFolderName,
        htmlUrl: fileUrl || "",
        files: clientFile,
      };

      const response = await dispatch(getCreateDocumentation(id, data));

      if (response?.status === 201) {
        await dispatch(getListDocumentation(id));
        toast.success("Create File Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setSelectedEditor("BaseTemplate");
        updateState({
          ...iState,
          NewFileModal: false,
          htmlUrl: "",
          clientFile: "",
        });
      }
    } catch (error) {
      toast.error(error?.data?.message, { position: toast.POSITION.TOP_RIGHT });
      updateState({ ...iState, NewFileModal: true });
    }
  };

  const handleDocumentationDeleteShow = (id,folderId) => {
    updateState({
      ...iState,
      DocumentationDeleteModal: true,
      docId: id,
      folderId:folderId,
    });
  };

  const handleDocumentationDeleteClose = () => {
    updateState({
      ...iState,
      DocumentationDeleteModal: false,
      docId: "",
    });
  };

  const handleDocumentationDelete = () => {
    let dataa = { docId };
    dispatch(getRemoveDocumentation(folderId, dataa))
      .then((res) => {
        if (res.status === 200) {
          dispatch(getListDocumentation(folderId));
          // dispatch(getpendingFileEventList(id));
          toast.success("Delete Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
            theme: "colored",
          });
          updateState(initialState);
        }
      })
      .catch((error) => {
        toast.error(error.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
          theme: "colored",
        });
      });
    updateState({ ...iState, DocumentationDeleteModal: true });
  };

  const handleShareClientDocumentShow = (foldId, Id, name) => {
    updateState({
      ...iState,
      ShareClientDocumentationModal: true,
      emailBody: "",
      emails: [],
      clientName: "",
      clientEmail: "",
      folderId: foldId,
      id: Id,
      fileNames: name,
    });
  };

  const handleShareClientDocumentClose = () => {
    updateState({
      ...iState,
      ShareClientDocumentationModal: false,
      emailBody: "",
      emails: [],
      clientName: "",
      clientEmail: "",
      fileNames: "",
    });
  };

  const handleAddRecipient = () => {
    const { clientName, clientEmail } = iState;

    // Create a copy of the emails array
    const updatedEmails = [...iState.emails];

    // Push a new recipient object with the clientName and clientEmail values
    updatedEmails.push({ name: clientName, email: clientEmail });

    // Update the state with the modified emails array and reset clientName and clientEmail
    updateState({
      ...iState,
      emails: updatedEmails,
      clientName: "",
      clientEmail: "",
    });
  };

  // Function to remove a recipient at a given index
  const handleRemoveRecipient = (indexToRemove) => {
    // Create a copy of the emails array
    const updatedEmails = [...iState.emails];

    // Use the filter method to create a new array without the item at the specified index
    const updatedEmailsFiltered = updatedEmails.filter(
      (_, index) => index !== indexToRemove
    );

    // Update the state with the modified emails array
    updateState({ ...iState, emails: updatedEmailsFiltered });
  };

  const handleShareDocumentValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!emailBody) {
      error.eBodyError = "* Message can't be empty";
      formIsValid = false;
    }

    // if (!clientName) {
    //   error. = "* Name can't be empty";
    //   formIsValid = false;
    // }

    // if (!clientEmail) {
    //   error.cliEmailError = "*  can't be empty";
    //   formIsValid = false;
    // }
    if (emails?.length == 0) {
      error.cliNameError = "* Name & Email can't be empty";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: error,
    });
    return formIsValid;
  };

  const handleShareDocumentSubmit = () => {
    let formIsValid = handleShareDocumentValidation();
    if (formIsValid) {
      const dataa = {
        emailBody,
        emails,
      };

      dispatch(getShareDocumentationAdd(folderId, id, dataa))
        .then((res) => {
          if (res.status === 200) {
            dispatch(getListDocumentation(folderId));
            toast.success("Share Document Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            updateState({
              ...iState,
              ShareClientDocumentationModal: false,
              folderId: "",
              id: "",
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({
              ...iState,
              loading: false,
              ShareClientDocumentationModal: true,
            });
          }
        })
        .catch((error) => {
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            loading: false,
            ShareClientDocumentationModal: true,
          });
        });
    }
  };

  const handleTypeChange = (type) => {
    updateState({ ...iState, approverType: type });
  };

  const handleNameEmailChange = (e, i) => {
    const { name, value } = e.target;
    let contactData = [...contactSelection];
    contactData[i] = {
      ...contactData[i],
      [name]: value,
    };
    updateState({
      ...iState,
      contactSelection: contactData,
    });
  };

  const handleSendForApprovalShow = (foldId, Id) => {
    updateState({
      ...iState,
      SendForApprovalModal: true,
      folderId: foldId,
      id: Id,
    });
  };

  const handleSendForApprovalClose = () => {
    updateState({
      ...iState,
      SendForApprovalModal: false,
      contactSelection: "",
      folderId: "",
      id: "",
    });
  };

  const handleSendForApprovalSubmit = () => {
    const dataa = {
      approverType: [approverType],
      contactSelection,
    };

    dispatch(sendForApprovalDocument(folderId, id, dataa))
      .then((res) => {
        if (res.status === 200) {
          dispatch(getListDocumentation(folderId));
          toast.success("Send file approval successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            SendForApprovalModal: false,
            folderId: "",
            id: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            loading: false,
            SendForApprovalModal: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          loading: false,
          SendForApprovalModal: true,
        });
      });
  };

  const handleUploadTemplateShow = (foldId, Id) => {
    updateState({
      ...iState,
      UploadTemplateModal: true,
      folderId: foldId,
      id: Id,
      templateFile: "",
    });
  };

  const handleUploadTemplateClose = () => {
    updateState({
      ...iState,
      UploadTemplateModal: false,
      folderId: "",
      id: "",
      templateFile: "",
    });
  };

  const handleRemoveTemplate = () => {
    // Reset the 'templateFile' state to null
    updateState({ ...iState, templateFile: null });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#templateFileInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleUploadTemplateSubmit = () => {
    if (!templateFile) {
      toast.error("Please select the File", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      const dataa = {
        file: templateFile,
      };

      dispatch(getUploadTemplateMainPic(folderId, id, dataa))
        .then((res) => {
          if (res.status === 200) {
            dispatch(getListDocumentation(folderId));
            toast.success("Upload Template Document Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            updateState({
              ...iState,
              UploadTemplateModal: false,
              folderId: "",
              id: "",
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({
              ...iState,
              loading: false,
              UploadTemplateModal: true,
            });
          }
        })
        .catch((error) => {
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            loading: false,
            UploadTemplateModal: true,
          });
        });
    }
  };

  const handleViewDocumentationShow = async (foldId, Id) => {
    updateState({
      ...iState,
      ViewDocumentationModal: true,
      folderId: foldId,
      id: Id,
    });

    try {
      const response = await dispatch(
        getViewDocumentation(
          iBuildLocalData?.user?.CompanyId,
          foldId,
          Id,
          iBuildLocalData?.user?.token
        )
      );
      let urlWithoutQueryString, htmlData, docData;
      if (response?.data?.ClientDocDetails?.htmlUrl) {
        urlWithoutQueryString =
          response.data.ClientDocDetails.htmlUrl?.split("?")[0];
        htmlData = await dispatch(
          getConverToString(urlWithoutQueryString, iBuildLocalData?.user?.token)
        );
      } else {
        urlWithoutQueryString =
          response.data.ClientDocDetails.docUrl.split("?")[0];
        docData = await dispatch(getHtmlFile({ url: urlWithoutQueryString }));
      }

      if (htmlData) {
        updateState({
          ...iState,
          viewDocumentData: htmlData?.data?.data,
          ViewDocumentationModal: true,
        });
      } else if (docData) {
        updateState({
          ...iState,
          viewDocumentData: docData?.data,
          ViewDocumentationModal: true,
        });
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleViewDocumentationClose = () => {
    updateState({
      ...iState,
      ViewDocumentationModal: false,
      folderId: "",
      id: "",
      viewDocumentData: "",
    });
  };

  const handleApproveDocumentationShow = async (foldId, Id) => {
    updateState({
      ...iState,
      ApproveDocumentationModal: true,
    });

    try {
      const response = await dispatch(
        getViewDocumentation(
          iBuildLocalData?.user?.CompanyId,
          foldId,
          Id,
          iBuildLocalData?.user?.token
        )
      );
      let urlWithoutQueryString, htmlData, docData;
      if (response?.data?.ClientDocDetails?.htmlUrl) {
        urlWithoutQueryString =
          response.data.ClientDocDetails.htmlUrl.split("?")[0];
        htmlData = await dispatch(
          getConverToString(urlWithoutQueryString, iBuildLocalData?.user?.token)
        );
      } else {
        urlWithoutQueryString =
          response.data.ClientDocDetails.docUrl.split("?")[0];
        docData = await dispatch(getHtmlFile({ url: urlWithoutQueryString }));
      }

      if (htmlData) {
        updateState({
          ...iState,
          viewDocumentEditData: htmlData?.data?.data,
          ApproveDocumentationModal: true,
          folderId: foldId,
          id: Id,
        });
      } else if (docData) {
        updateState({
          ...iState,
          viewDocumentEditData: docData?.data,
          ApproveDocumentationModal: true,
          folderId: foldId,
          id: Id,
        });
      }
    } catch (error) {
      // Handle the error, e.g., log it or show an error message.
      console.error("Error fetching documentation:", error);
    }
  };

  const handleApproveDocumentationClose = () => {
    updateState({
      ...iState,
      ApproveDocumentationModal: false,
      folderId: "",
      id: "",
    });
  };

  const handleApprovReject = (type) => {
    const dataa = {
      actionStatus: type === "approve" ? "Approval" : "Reject",
    };

    dispatch(
      getDocumentationApproval(
        iBuildLocalData?.user?.CompanyId,
        folderId,
        id,
        dataa,
        iBuildLocalData?.user?.token
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getListDocumentation(folderId));
        if (type === "approve") {
          toast.success("File Approved Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.success("File Rejected Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }

        updateState({
          ...iState,
          ApproveDocumentationModal: false,
          folderId: "",
          id: "",
          viewDocumentEditData: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });

        updateState({
          ...iState,
          loading: false,
          ApproveDocumentationModal: true,
        });
      }
    });
  };

  const handleFolderDeleteShow = (id, foldName) => {
    updateState({
      ...iState,
      FolderDeleteModal: true,
      docId: id,
      folderName: foldName,
    });
  };

  const handleFolderDeleteClose = () => {
    updateState({
      ...iState,
      FolderDeleteModal: false,
      docId: "",
    });
  };

  const handleFolderDelete = () => {
    let dataa = { folderName };
    dispatch(getFolderRemove(docId, dataa))
      .then((res) => {
        if (res.status === 200) {
          dispatch(getAllFolderList());
          toast.success(" Delete Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            FolderDeleteModal: false,
            docId: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            FolderDeleteModal: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          FolderDeleteModal: true,
        });
      });
  };

  const handleEditDocumentationShow = async (foldId, Id, name, foldName) => {
    setFileFolderShow(false);
    updateState({
      ...iState,
      EditDocumentationModal: true,
      folderId: foldId,
      id: Id,
      content: "",
      fileFolderName: foldName,
    });

    try {
      const response = await dispatch(
        getViewDocumentation(
          iBuildLocalData?.user?.CompanyId,
          foldId,
          Id,
          iBuildLocalData?.user?.token
        )
      );
      let urlWithoutQueryString, htmlData, docData;
      if (response?.data?.ClientDocDetails?.htmlUrl) {
        urlWithoutQueryString =
          response.data.ClientDocDetails.htmlUrl.split("?")[0];
        htmlData = await dispatch(
          getConverToString(urlWithoutQueryString, iBuildLocalData?.user?.token)
        );
      } else {
        urlWithoutQueryString =
          response.data.ClientDocDetails.docUrl.split("?")[0];
        docData = await dispatch(getHtmlFile({ url: urlWithoutQueryString }));
      }

      if (htmlData) {
        updateState({
          ...iState,
          content: htmlData?.data?.data,
          EditDocumentationModal: true,
          folderId: foldId,
          id: Id,
          fileNames: name,
          fileFolderName: foldName,
        });
      } else if (docData) {
        updateState({
          ...iState,
          content: docData?.data,
          EditDocumentationModal: true,
          folderId: foldId,
          id: Id,
          fileNames: name,
          fileFolderName: foldName,
        });
      }
    } catch (error) {
      // Handle the error, e.g., log it or show an error message.
      console.error("Error fetching documentation:", error);
    }
  };

  const handleEditDocumentationClose = () => {
    setFileFolderShow(false);
    updateState({
      ...iState,
      EditDocumentationModal: false,
      folderId,
      id,
      fileFolderName: "",
    });
  };

  const handleDraftChange = (e) => {
    updateState({ ...iState, draft: e.target.checked });
  };

  const handleEditDocumentation = async () => {
    updateState({ ...iState, loading: true });
    const blob = new Blob([content], { type: "text/html" });
    const file = new File([blob], "content.html");

    const initialFormData = new FormData();
    initialFormData.append("files", file);
    try {
      const uploadResponse = await dispatch(
        uploadAggrementFiles(initialFormData)
      );

      if (uploadResponse.status === 200) {
        const formData = new FormData();
        const docxBlob = htmlDocx.asBlob(content);
        const filesName = `${fileNames}`;
        const docxFile = new File([docxBlob], filesName, {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        // saveAs(docxBlob, filesName);
        formData.append("files", docxFile);
        formData.append("htmlUrl", uploadResponse?.data?.data);
        formData.append("draft", draft);
        formData.append("name", fileFolderName);

        const signatureResponse = await dispatch(
          updateEditDocumentation(folderId, id, formData)
        );

        if (signatureResponse.status === 200) {
          dispatch(getListDocumentation(folderId));
          toast.success("Docs Updated Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            EditDocumentationModal: false,
            folderId: "",
            id: "",
            loading: false,
            content: "",
            fileNames: "",
            fileFolderName: "",
          });
        } else {
          throw new Error(signatureResponse.data.message);
        }
      } else {
        throw new Error(uploadResponse.data.message);
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      updateState({
        ...iState,
        loading: false,
        EditDocumentationModal: true,
      });
    }
  };

  const handleRelaseTemplateModalShow = () => {
    updateState({
      ...iState,
      RelaseTemplateModal: true,
      projectId: "",
      folderId: "",
    });
    setSelectedOption([]);
    setSelectedItems([]);
  };

  const handleRelaseTemplateModalClose = () => {
    updateState({
      ...iState,
      RelaseTemplateModal: false,
    });
  };

  const handleReleaseTemplate = () => {
    const datta = {
      projectId: projectId,
      subFileManager: selectedOption,
      data: [
        {
          folderId: selectedItems?.[0]?.FolderId,
          Templates: selectedItems?.map((data) => ({
            id: data.id,
          })),
        },
      ],
    };
    dispatch(releaseTemplate(datta)).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          RelaseTemplateModal: false,
          projectId: "",
          folderId: "",
        });
        setSelectedOption([]);
        setSelectedItems([]);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, RelaseTemplateModal: true });
      }
    });
  };

  const handleLockTemplateModalShow = (id) => {
    updateState({
      ...iState,
      LockTemplateModal: true,
      folderId: id,
    });
    dispatch(getListDocumentation(id)).then((res) => {
      if (res.status === 200) {
        updateState({
          ...iState,
          folderDatas: res?.data?.clientDocDetails,
          LockTemplateModal: true,
          folderId: id,
        });
      }
    });
    setTemplates([]);
  };

  const handleLockTemplateModalClose = () => {
    updateState({
      ...iState,
      LockTemplateModal: false,
    });
  };

  const handleFilesHistoryModalShow = (folderID, id, name) => {
    updateState({
      ...iState,
      FilesHistoryModal: true,
      fileNames: name,
    });
    dispatch(getFileHistoryList(folderID, id));
  };

  const handleFilesHistoryModalClose = () => {
    updateState({
      ...iState,
      FilesHistoryModal: false,
      fileNames: "",
    });
  };

  const handleDocumentKeyModalShow = () => {
    updateState({
      ...iState,
      DocumentKeyModal: true,
      JobId: "",
      EditDocumentationModal: false,
    });
  };

  const handleDocumentKeyModalClose = () => {
    updateState({
      ...iState,
      DocumentKeyModal: false,
      JobId: "",
      EditDocumentationModal: true,
    });
  };

  const handleItemClick = (e) => {
    const { value } = e.target;
    const updatedContent = value ? value + content : content;
    updateState({
      ...iState,
      content: updatedContent,
      DocumentKeyModal: false,
      EditDocumentationModal: true,
    });
  };

  const createAboutMarkup = () => {
    return { __html: iBuildLocalData?.companies?.[0]?.letterHeader };
  };

  const handleApply = () => {
    const dataa = iBuildLocalData?.companies?.[0]?.letterHeader;
    const updatedContent = dataa ? dataa + content : content;
    updateState({
      ...iState,
      content: updatedContent,
      HeaderPreviewModal: false,
      EditDocumentationModal: true,
    });
  };

  const handlHeaderPreviewModalShow = () => {
    updateState({
      ...iState,
      HeaderPreviewModal: true,
      EditDocumentationModal: false,
    });
  };
  const handlHeaderPreviewModalClose = () => {
    updateState({
      ...iState,
      HeaderPreviewModal: false,
      EditDocumentationModal: true,
    });
  };

  const { allProjectList } = useSelector(
    (state) => state.vendorViewConfigReducer
  );

  useEffect(() => {
    dispatch(getAllProjectList(iBuildLocalData?.user?.CompanyId));
  }, []);

  const [selectedOption, setSelectedOption] = useState("");
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const [selectedItems, setSelectedItems] = useState([]);

  const handleFolderCheckboxChange = (event, item) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((selectedItem) => selectedItem !== item)
      );
    }
  };

  const [templates, setTemplates] = useState([]);
  const handleTemplateCheckboxChange = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setTemplates([...templates, { ...item, lock: true }]);
    } else {
      setTemplates(templates.filter((templates) => templates.id !== item.id));
    }
  };

  const handleLockUnlock = () => {
    const datta = {
      templates: templates.map((data) => ({
        fileId: data.id,
        lock: data.lock,
      })),
    };
    dispatch(lockUnlockFiles(folderId, datta)).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          LockTemplateModal: false,
          folderId: "",
        });
        setTemplates([]);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, LockTemplateModal: true });
      }
    });
  };

  const handlInsertSignatureModalShow = () => {
    updateState({
      ...iState,
      InsertSignatureModal: true,
      EditDocumentationModal: false,
    });
  };

  useEffect(() => {
    if (InsertSignatureModal) dispatch(getManageSignatureList());
  }, [InsertSignatureModal]);

  const handlInsertSignatureModalClose = () => {
    updateState({
      ...iState,
      InsertSignatureModal: false,
      EditDocumentationModal: true,
    });
  };

  const handleRemoveManageSignature = (id) => {
    dispatch(removeManageSignatures(id)).then((res) => {
      if (res.status === 200) {
        dispatch(getManageSignatureList());
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleAddSignature = () => {
    if (manageSignatureList?.images?.length > 0) {
      let updatedContent = content; // Initialize with current content

      manageSignatureList?.images?.forEach((image) => {
        const imgTag = `<img src="${image.docUrl}" alt="Signature Image" />`;
        updatedContent += imgTag; // Append each image HTML to existing content
      });

      updateState({
        ...iState,
        content: updatedContent,
        EditDocumentationModal: true,
        InsertSignatureModal: false,
      }); // Update editor content
    }
  };

  const editorsRef = useRef(null);

  const handleEditorChange = (newContent) => {
    updateState({ ...iState, content: newContent });
  };

  const [contactOption, setContactOption] = useState("");
  const handleContactChange = (e) => {
    setContactOption(e.target.value);
  };

  const handleEventHistoryDelete = (id) => {
    dispatch(fileMangerEventHistoryRead(id)).then((res) => {
      if (res.status === 200) {
        dispatch(getFileMangerEventHistory());
        toast.success("Delete Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
          theme: "colored",
        });
      }
    });
  };
  const [fileFolderNameShow, setFileFolderShow] = useState(false);
  const fileFolderNameChange = () => {
    setFileFolderShow(!fileFolderNameShow);
  };

  const [selectedEditor, setSelectedEditor] = useState("BaseTemplate");
  const handleSelectedEditorChange = (e) => {
    const newSelectedEditor = e.target.value;
    if (newSelectedEditor === "CreateNewFile") {
      updateState({ ...iState, clientFile: "" });
    } else if (newSelectedEditor === "BaseTemplate") {
      updateState({ ...iState, htmlUrl: "" });
    }
    setSelectedEditor(newSelectedEditor);
  };

  const editorSelectedRef = useRef(null);
  const handleSelectedEditor = (data) => {
    updateState((prevState) => ({
      ...prevState,
      htmlUrl: data,
      fileFolderName,
    }));
  };

  return (
    <div>
      <Header />
      <SideNav />
      <>
        <div className="WrapperArea">
          <div className="WrapperBox">
            <div className="TitleBox">
              <h4 className="Title">System File Manager</h4>
              <a
                href="#"
                data-toggle="modal"
                data-target="#folderPermissionsModal"
              >
                <img src="images/Icon-4.png" />
              </a>
            </div>
            <div className="">
              <div className="row">
                <div className="col-lg-4 col-md-5 col-sm-12">
                  <div className="DashboardLeft">
                    <div className="ShowList">
                      <h4>Show :</h4>
                      {/* <div className="show-carousel owl-carousel owl-theme">
                        <div className="item">
                          <h6 className="active">System Templates</h6>
                        </div>
                        <div className="item">
                          <h6>Documents</h6>
                        </div>
                        <div className="item">
                          <h6>Pictures</h6>
                        </div>
                        <div className="item">
                          <h6>letters</h6>
                        </div>
                      </div> */}
                      <article>
                        <button onClick={handleNewFileShow}>Upload File</button>
                      </article>
                      <aside>
                        <h5>Folder</h5>
                        <div>
                          <a onClick={handleRelaseTemplateModalShow}>
                            <img
                              src={require("../assets/images/release_template.png")}
                            />
                          </a>
                          <a onClick={handleCreateFolderShow}>
                            <img src="images/Plus.png" />
                          </a>
                          <a
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#permissionModal3"
                          >
                            <img src="images/Folder.png" />
                          </a>
                          <a
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#folderActionModal3"
                          >
                            <img src="images/Icon-4.png" />
                          </a>
                        </div>
                      </aside>
                      <div className="Search">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          value={searchName}
                          onChange={(e) => setSearchName(e.target.value)}
                        />
                        <span>
                          <img src="images/search.png" />
                        </span>
                      </div>
                    </div>
                    <div className="FolderList">
                      <ul>
                        {folderLoader ? (
                          <td>
                            <Loader />
                          </td>
                        ) : (
                          <>
                            {searchName ? (
                              searchFolderList?.length > 0 ? (
                                searchFolderList?.map((item) => (
                                  <li>
                                    <span
                                      className="Icon"
                                      onClick={() => handleTagDocument(item.id)}
                                    >
                                      <img src="images/Folder.png" />
                                    </span>
                                    <label>{item.folderName}</label>
                                    <a
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleLockTemplateModalShow(item.id);
                                    }}
                                    >
                                      <img src="images/Lock.png" />
                                    </a>
                                    <a
                                     onClick={(e) => {
                                      e.stopPropagation(); 
                                      handleFolderDeleteShow(item.id, item.folderName);
                                    }}
                                    >
                                      <img src="images/Cross.png" />
                                    </a>
                                  </li>
                                ))
                              ) : (
                                <div className="text-center w-100">
                                  <h6>No data found</h6>
                                </div>
                              )
                            ) : allFolderList?.length > 0 ? (
                              allFolderList?.map((item) => (
                                <li
                                  style={{
                                    backgroundColor:
                                      folderIDD === item.id
                                        ? "green"
                                        : "transparent",
                                  }}
                                  onClick={() => handleTagDocument(item.id)}
                                >
                                  <span className="Icon">
                                    <img src="images/Folder.png" />
                                  </span>
                                  <label>{item.folderName}</label>
                                  <a
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleLockTemplateModalShow(item.id);
                                    }}
                                  >
                                    <img src="images/Lock.png" />
                                  </a>
                                  <a
                                    onClick={(e) => {
                                      e.stopPropagation(); 
                                      handleFolderDeleteShow(item.id, item.folderName);
                                    }}
                                  >
                                    <img src="images/Cross.png" />
                                  </a>
                                </li>
                              ))
                            ) : (
                              <div className="text-center w-100">
                                <h6>No data found</h6>
                              </div>
                            )}
                          </>
                        )}
                      </ul>
                    </div>
                    <div className="TagsList">
                      <h4>TAGS</h4>
                      <ul>
                        {tagList?.tags?.length > 0
                          ? tagList?.tags?.map((item, i) => {
                              return (
                                <li>
                                  <span>{item.name}</span>
                                </li>
                              );
                            })
                          : "No Tags Founds"}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-7 col-sm-12">
                  <div className="EventArea">
                    <h4> File Events</h4>
                    <div className="TableList">
                      <table>
                        <thead>
                          <tr>
                            <th>File/Folder Name</th>
                            <th>Activity</th>
                            <th>Modified by</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {fileMangerEventHistoryList?.data?.length > 0 ? (
                            fileMangerEventHistoryList?.data?.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    {item.clientdoc?.fileName ||
                                      item.folder?.folderName}
                                  </td>
                                  <td>{item.activity}</td>
                                  <td>{item.userDetails?.name}</td>
                                  <td>
                                    {moment(item.createdAt).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </td>
                                  <td>
                                    <div className="Actions">
                                      <a
                                        onClick={() =>
                                          handleEventHistoryDelete(item.id)
                                        }
                                      >
                                        <img src="images/Cross.png" />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="5" className="text-center">
                                <p>No Data found.</p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="FilesArea">
                    <div className="FilesHead">
                      <div className="FilesLeft">
                        <h4>Files</h4>
                        <aside>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            value={searchFolderName}
                            onChange={(e) =>
                              setSearchFolderName(e.target.value)
                            }
                          />
                        </aside>
                      </div>
                      <div className="TodosButtons mt-3">
                        <a
                          className="ml-0 FolderPermissionId"
                          onClick={handleDocumentKeyModalShow}
                        >
                          <img
                            src={require("../assets/images/insert_keys.png")}
                          />{" "}
                          Document Keys
                        </a>
                        <a
                          onClick={handleManageSignatueShow}
                          className="ml-0 FolderPermissionId"
                        >
                          <img src="images/white_manage_signatures.png" />{" "}
                          Manage Signatures
                        </a>
                      </div>
                      <div className="FilesRight">
                        <a onClick={handleNewFileShow}>
                          {" "}
                          <img src="images/Icon-5.png" />
                        </a>
                        {/* <a onClick={handleNewFileShow}>
                          <img src="images/Icon-6.png" />
                        </a> */}
                      </div>
                    </div>
                    <div className="FilesBody">
                      <div className="row">
                        {loader ? (
                          <td>
                            <Loader />
                          </td>
                        ) : (
                          <>
                            {searchFolderName ? (
                              searchDocumentationList?.length > 0 ? (
                                searchDocumentationList?.map((data, i) => {
                                  return (
                                    <div className="col-lg-4 col-md-12">
                                      <div className="FilesBox">
                                        <figure>
                                          <img src={data?.image?.docUrl} />
                                        </figure>
                                        <figcaption>
                                          <div>
                                            <h4>{data.name}</h4>
                                            <p>
                                              Added:{" "}
                                              {moment(data.createdAt).format(
                                                "YYYY-MM-DD"
                                              )}
                                            </p>
                                          </div>
                                          <ul>
                                            <li className="dropdown">
                                              <a
                                                className="dropdown-toggle"
                                                id="navbarDropdown"
                                                role="button"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                <img src="images/dots.png" />
                                              </a>

                                              <ol className="dropdown-menu dropdown-menuwidth">
                                                <li>
                                                  <a
                                                    onClick={() =>
                                                      handleViewDocumentationShow(
                                                        data.folderId,
                                                        data.id
                                                      )
                                                    }
                                                  >
                                                    <img src="images/document-view.png" />
                                                    View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    onClick={() =>
                                                      handleEditDocumentationShow(
                                                        data.folderId,
                                                        data.id,
                                                        data.fileName,
                                                        data.name
                                                      )
                                                    }
                                                  >
                                                    <img src="images/document-edit.png" />
                                                    Edit
                                                  </a>
                                                </li>
                                                {/* <li>
                                                  <a
                                           
                                                  >
                                                    <img
                                                      src={require("../assets/images/insert_signature.png")}
                                                    />
                                                    Insert Signature
                                                  </a>
                                                </li> */}
                                                <li>
                                                  <a
                                                    onClick={() =>
                                                      handleApproveDocumentationShow(
                                                        data.folderId,
                                                        data.id
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={require("../assets/images/approve_icon.png")}
                                                    />
                                                    Approval
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    onClick={() =>
                                                      handleSendForApprovalShow(
                                                        data.folderId,
                                                        data.id
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={require("../assets/images/send_approval.png")}
                                                    />
                                                    Send for approval
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    href={data.docUrl}
                                                    target="_blank"
                                                    title="ImageName"
                                                  >
                                                    <img src="images/download.png" />
                                                    Download File
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    onClick={() =>
                                                      handleShareClientDocumentShow(
                                                        data.folderId,
                                                        data.id,
                                                        data.name
                                                      )
                                                    }
                                                  >
                                                    <img src="images/Action-2.png" />
                                                    Share documents
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    onClick={() =>
                                                      handleDocumentationDeleteShow(
                                                        data.id,
                                                        data.folderId
                                                      )
                                                    }
                                                  >
                                                    <img src="images/dropdown-delete.png" />
                                                    Delete
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    onClick={() =>
                                                      handleUploadTemplateShow(
                                                        data.folderId,
                                                        data.id
                                                      )
                                                    }
                                                  >
                                                    <img src="images/upload_template.png" />
                                                    Upload Template main picture
                                                  </a>
                                                </li>
                                              </ol>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleFilesHistoryModalShow(
                                                    data.folderId,
                                                    data.id,
                                                    data.name
                                                  )
                                                }
                                              >
                                                <img src="images/timer.png" />
                                              </a>
                                            </li>
                                          </ul>
                                        </figcaption>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="text-center w-100">
                                  <h6>No data found</h6>
                                </div>
                              )
                            ) : documentationList?.length > 0 ? (
                              documentationList?.map((data, i) => {
                                return (
                                  <div className="col-sm-4">
                                    <div className="FilesBox">
                                      <figure>
                                        <img src={data?.image?.docUrl} />
                                      </figure>
                                      <figcaption>
                                        <div>
                                          <h4>{data.name}</h4>
                                          <p>
                                            Added:{" "}
                                            {moment(data.createdAt).format(
                                              "YYYY-MM-DD"
                                            )}
                                          </p>
                                        </div>
                                        <ul>
                                          <li className="dropdown">
                                            <a
                                              className="dropdown-toggle"
                                              id="navbarDropdown"
                                              role="button"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <img src="images/dots.png" />
                                            </a>

                                            <ol className="dropdown-menu dropdown-menuwidth">
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    handleViewDocumentationShow(
                                                      data.folderId,
                                                      data.id
                                                    )
                                                  }
                                                >
                                                  <img src="images/document-view.png" />
                                                  View
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    handleEditDocumentationShow(
                                                      data.folderId,
                                                      data.id,
                                                      data.fileName,
                                                      data.name
                                                    )
                                                  }
                                                >
                                                  <img src="images/document-edit.png" />
                                                  Edit
                                                </a>
                                              </li>

                                              <li>
                                                <a
                                                  onClick={() =>
                                                    handleApproveDocumentationShow(
                                                      data.folderId,
                                                      data.id
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src={require("../assets/images/approve_icon.png")}
                                                  />
                                                  Approval
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    handleSendForApprovalShow(
                                                      data.folderId,
                                                      data.id
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src={require("../assets/images/send_approval.png")}
                                                  />
                                                  Send for approval
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  href={data.docUrl}
                                                  target="_blank"
                                                  title="ImageName"
                                                  download="document.docx"
                                                >
                                                  <img src="images/download.png" />
                                                  Download File
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    handleShareClientDocumentShow(
                                                      data.folderId,
                                                      data.id,
                                                      data.name
                                                    )
                                                  }
                                                >
                                                  <img src="images/Action-2.png" />
                                                  Share documents
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    handleDocumentationDeleteShow(
                                                      data.id,
                                                      data.folderId
                                                    )
                                                  }
                                                >
                                                  <img src="images/dropdown-delete.png" />
                                                  Delete
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    handleUploadTemplateShow(
                                                      data.folderId,
                                                      data.id
                                                    )
                                                  }
                                                >
                                                  <img src="images/upload_template.png" />
                                                  Upload Template main picture
                                                </a>
                                              </li>
                                            </ol>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                handleFilesHistoryModalShow(
                                                  data.folderId,
                                                  data.id,
                                                  data.name
                                                )
                                              }
                                            >
                                              <img src="images/timer.png" />
                                            </a>
                                          </li>
                                        </ul>
                                      </figcaption>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="text-center w-100">
                                <h6>No data found</h6>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={CreateFolderModal}
          onHide={handleCreateFolderClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleCreateFolderClose}>
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Create Folder</h4>
                  </div>
                  <div className="FolderForm">
                    <form onSubmit={handleCreateFolderSubmit}>
                      <div className="form-group">
                        <h6>Folder Name *</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert new folder name"
                          name="folderName"
                          value={folderName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <h6>Folder Permissions</h6>
                        <div className="PermissionsName">
                          <h5
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#permissionModal2"
                            data-dismiss="modal"
                          >
                            <span>
                              <img src="images/Folder.png" />
                            </span>{" "}
                            Folder Permissions
                          </h5>
                        </div>
                      </div>
                      <div className="form-group">
                        <h6>Folder Actions</h6>
                        <div className="PermissionsName">
                          <h5
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#folderActionModal2"
                            data-dismiss="modal"
                          >
                            <span>
                              <img src="images/Icon-4.png" />
                            </span>{" "}
                            Folder Actions
                          </h5>
                        </div>
                      </div>
                      <div className="Button text-center">
                        <button className="Create ClosePermissionId">
                          Create
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={ManageSignatueModal}
          onHide={handleManageSignatueClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <a className="CloseModal" onClick={handleManageSignatueClose}>
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Manage Signatures</h4>
              </div>
              <div className="FolderForm">
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Add new signature</h5>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea border p-2">
                    <h5>Signatures</h5>
                    <div className="CommonModalBox">
                      <SignaturePad
                        ref={signCanvas}
                        penColor="green"
                        canvasProps={{
                          width: 400,
                          height: 300,
                          className: "sigCanvas",
                        }}
                        onEnd={() =>
                          updateState({
                            ...iState,
                            signature: signCanvas?.current
                              ?.getTrimmedCanvas()
                              .toDataURL("image/png"),
                          })
                        }
                      />
                      <a onClick={clearSignature}>
                        <img src="images/circle_minus.png" />
                      </a>
                      {/* <a onClick={saveSignature} href="javascript:void(0);">
                        Save
                      </a> */}
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.signatureError}
                    </span>
                  </div>
                  <div className="Button d-flex justify-content-between m-2">
                    <button className="Create" onClick={addSignatureFun}>
                      Save
                    </button>
                    <button
                      className="Outline"
                      onClick={handleManageSignatueClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Add new Initials</h5>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea border p-2">
                    <h5>Initials</h5>
                    <div className="CommonModalBox">
                      <SignaturePad
                        ref={intialCanvas}
                        penColor="green"
                        canvasProps={{
                          width: 400,
                          height: 300,
                          className: "sigCanvas",
                        }}
                        onEnd={() =>
                          updateState({
                            ...iState,
                            initial: intialCanvas?.current
                              ?.getTrimmedCanvas()
                              .toDataURL("image/png"),
                          })
                        }
                      />
                      <a onClick={clearInitials}>
                        <img src="images/circle_minus.png" />
                      </a>
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.initialError}
                    </span>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={addSignatureFun}>
                    Save
                  </button>
                  <button
                    className="Outline"
                    onClick={handleManageSignatueClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={NewFileModal}
          onHide={handleNewFileClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleNewFileClose}>
                  ×
                </a>

                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Create New File</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="form-group">
                      <h6>Name</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert new entry name"
                        name="fileFolderName"
                        value={fileFolderName}
                        onChange={handleInputChange}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors?.nameError}
                      </span>
                    </div>
                    <div className="form-group">
                      <h6>Folder</h6>
                      <div className="Categories Categories1">
                        <div className="Categories_all Categories_all1">
                          <label>
                            List of folders where user has permission to create
                            file
                          </label>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <div className="">
                            <select
                              className="form-control"
                              name="id"
                              value={id}
                              // onFocus={handleValidation}
                              onChange={handleInputChange}
                            >
                              <option value="">Select</option>
                              {allFolderList?.length > 0 &&
                                allFolderList?.map((item) => {
                                  return (
                                    <option value={item.id}>
                                      {item.folderName}{" "}
                                    </option>
                                  );
                                })}
                            </select>
                            <span style={{ color: "red" }}>
                              {errors?.foldNameError}
                            </span>
                          </div>
                        </ul>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <h6>Editor Option</h6>
                      <div className="mb-4">
                        <label className="Radio">
                          Create file with base template upload
                          <input
                            type="radio"
                            name="editor"
                            value="BaseTemplate"
                            checked={selectedEditor === "BaseTemplate"}
                            onChange={handleSelectedEditorChange}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div>
                        <label className="Radio">
                          Create new file with editor
                          <input
                            type="radio"
                            name="editor"
                            value="CreateNewFile"
                            checked={selectedEditor === "CreateNewFile"}
                            onChange={handleSelectedEditorChange}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                    <div className="file RadioText"></div>
                    <div
                      className="text RadioText"
                      style={{ display: "block" }}
                    >
                      {selectedEditor === "BaseTemplate" && (
                        <>
                          <div className="form-group">
                            <h6>Select Template File (Docx)</h6>
                            <div className="Upload">
                              <span>
                                -Drag and Drop Files or click to select
                              </span>
                              <input
                                type="file"
                                id="createFileInput"
                                accept=".doc,.docx"
                                onChange={(e) =>
                                  handleFileChange(e, "createFile")
                                }
                              />
                            </div>
                            <span style={{ color: "red", fontSize: "14px" }}>
                              {errors?.cltfileError}
                            </span>
                          </div>

                          <div className="FilesAdded">
                            <label>{clientFile?.name}</label>
                            <span>
                              <img src="images/fileview.png" />
                            </span>
                            <span onClick={handleRemoveClientFile}>
                              <img src="images/Cross.png" className="cross" />
                            </span>
                          </div>
                        </>
                      )}

                      {selectedEditor === "CreateNewFile" && (
                        <div className="mce-pagebreak">
                          <Editor
                            apiKey="rye4mz748ekd8ke0loyu8j7gq7iu1tkxs3nticj32j848g6r" // Use your premium API key
                            onInit={(evt, editor) =>
                              (editorSelectedRef.current = editor)
                            }
                            value={htmlUrl}
                            init={{
                              height: 500,
                              menubar:
                                "file edit view insert format tools table help",
                              plugins: [
                                "advlist",
                                "autolink",
                                "lists",
                                "link",
                                "image",
                                "charmap",
                                "print",
                                "preview",
                                "anchor",
                                "searchreplace",
                                "visualblocks",
                                "code",
                                "fullscreen",
                                "insertdatetime",
                                "media",
                                "table",
                                "paste",
                                "help",
                                "wordcount",
                                "lists",
                                "table",
                                "pagebreak",
                                "spellchecker",
                                "powerpaste",
                                "mediaembed",
                                "advcode",
                                "emoticons",
                                "codesample",
                                "advtable",
                                "visualchars",
                                "visualblocks",
                                "linkchecker",
                                "checklist",
                                "media",
                                "tableofcontents",
                                "a11ychecker",
                                "permanentpen",
                                "casechange",
                                "pageembed",
                                "nonbreaking",
                                "quickbars",
                                "advlist",
                                "autosave",
                                "autolink",
                                "autoresize",
                              ],
                              toolbar:
                                "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | " +
                                "alignleft aligncenter alignright alignjustify | numlist bullist checklist outdent indent | " +
                                "forecolor backcolor casechange permanentpen formatpainter removeformat | " +
                                "pagebreak | charmap emoticons | fullscreen preview save print | insertfile image media pageembed link anchor codesample | " +
                                "a11ycheck ltr rtl | showcomments addcomment",

                              toolbar_sticky: true,

                              autosave_ask_before_unload: true,
                              autosave_interval: "30s",
                              autosave_prefix: "{path}{query}-{id}-",
                              autosave_restore_when_empty: true,
                              autosave_retention: "2m",

                              image_advtab: true,

                              content_css: "default",
                              importcss_append: true,

                              image_title: true,
                              automatic_uploads: true,

                              // Handle drag and drop events
                              setup: (editor) => {
                                editor.on("drop", (e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  // Handle dropped files
                                  if (e.dataTransfer && e.dataTransfer.files) {
                                    const files = e.dataTransfer.files;
                                    for (const file of files) {
                                      const reader = new FileReader();
                                      reader.onload = (event) => {
                                        // Insert image at the cursor position
                                        editor.insertContent(
                                          `<img src="${event.target.result}" alt="${file.name}"/>`
                                        );
                                      };
                                      reader.readAsDataURL(file);
                                    }
                                  }
                                });
                              },

                              link_list: [
                                {
                                  title: "My page 1",
                                  value: "https://www.tinymce.com",
                                },
                                {
                                  title: "My page 2",
                                  value: "http://www.moxiecode.com",
                                },
                              ],
                              image_list: [
                                {
                                  title: "My image 1",
                                  value:
                                    "https://www.tiny.cloud/images/glyph-tinymce.svg",
                                },
                                {
                                  title: "My image 2",
                                  value:
                                    "https://www.tiny.cloud/docs/images/glyph-tinymce.svg",
                                },
                              ],
                              image_class_list: [
                                { title: "None", value: "" },
                                { title: "Some class", value: "some-class" },
                              ],
                              importcss_file_filter: "main.css",
                              templates: [
                                {
                                  title: "New Table",
                                  description: "creates a new table",
                                  content:
                                    "<table><tr><td>Sample Text</td></tr></table>",
                                },
                                {
                                  title: "Starting my story",
                                  description: "A sample document",
                                  content: "Once upon a time...",
                                },
                                {
                                  title: "New list with dates",
                                  description: "New List with Dates",
                                  content:
                                    "<ul><li>12 December</li><li>13 December</li></ul>",
                                },
                              ],
                              template_cdate_format:
                                "[CDATE: %m/%d/%Y : %H:%M:%S]",
                              template_mdate_format:
                                "[MDATE: %m/%d/%Y : %H:%M:%S]",
                              height: 600,
                              image_caption: true,
                              quickbars_selection_toolbar:
                                "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                              noneditable_class: "mceNonEditable",
                              toolbar_mode: "sliding",
                              spellchecker_dialog: true,
                              spellchecker_whitelist: ["Ephox", "Moxiecode"],

                              tinycomments_mode: "embedded",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                              a11y_advanced_options: true,

                              branding: false, // Remove TinyMCE branding
                              powerpaste_word_import: "clean", // Configure PowerPaste
                              powerpaste_html_import: "merge",
                              powerpaste_allow_local_images: true,

                              // Enable live media embeds
                              media_live_embeds: true,

                              // Enable line breaks
                              forced_root_block: "",
                              force_br_newlines: true,
                              force_p_newlines: false,
                            }}
                            onEditorChange={handleSelectedEditor}
                          />
                        </div>
                      )}
                    </div>
                    <div className="Button d-flex justify-content-between mt-5">
                      <button
                        className="Create FolderPermissionId"
                        onClick={handleNewFileSubmit}
                      >
                        Create File
                      </button>
                      <button className="Outline" onClick={handleNewFileClose}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={DocumentationDeleteModal}
          onHide={handleDocumentationDeleteClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleDocumentationDeleteClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Delete</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="my-5">
                      <h6>Are you sure you want to delete this ?</h6>
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button
                        className="Create"
                        onClick={handleDocumentationDelete}
                      >
                        Delete
                      </button>
                      <button
                        className="Outline"
                        onClick={handleDocumentationDeleteClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={ShareClientDocumentationModal}
          onHide={handleShareClientDocumentClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleShareClientDocumentClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Share Document</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="ModalBigheading">
                      <h3>
                        <span>Documents:</span>
                        {fileNames ?? "N/A"}
                      </h3>
                    </div>
                    <div className="ModalFilterAreaBox mb-3">
                      <h4 className="mb-4">Select Recipient</h4>
                      <div className="RadioArea d-flex  flex-column">
                        <div className="form-group">
                          <label className="Radio">
                            Select Recipient
                            <input
                              type="radio"
                              name="Cuisine"
                              defaultValue="text"
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="form-group mb-4">
                          <label className="Radio">
                            Other Contact
                            <input
                              type="radio"
                              name="Cuisine"
                              defaultValue="file"
                              defaultChecked=""
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="text RadioText">
                      <div className="form-group"></div>
                    </div>
                    <div
                      className="file RadioText mb-5"
                      style={{ display: "block" }}
                    >
                      <div className="form-group">
                        <h6>Name</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert name"
                          name="clientName"
                          value={clientName}
                          onChange={handleInputChange}
                        />
                        {/* <span style={{ color: "red" }}>
                          {errors?.cliNameError}
                        </span> */}
                      </div>
                      <div className="form-group">
                        <h6>Email</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert Email"
                          name="clientEmail"
                          value={clientEmail}
                          onChange={handleInputChange}
                        />
                        {/* <span style={{ color: "red" }}>
                          {errors?.cliEmailError}
                        </span> */}
                      </div>

                      <div className="Button" onClick={handleAddRecipient}>
                        <button
                          className="Create"
                          disabled={clientEmail == "" && clientName == ""}
                        >
                          Add Recipient
                        </button>
                      </div>
                    </div>
                    <h6>Recipients</h6>
                    <div className="FilesAddedTable">
                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          {emails?.length > 0 &&
                            emails?.map((item, ind) => {
                              return (
                                <tr>
                                  <td>
                                    <div className="FilesAddedName">
                                      <p>{item.name}</p>
                                      <span>
                                        <img
                                          src="images/Cross.png"
                                          onClick={() =>
                                            handleRemoveRecipient(ind)
                                          }
                                        />
                                      </span>
                                    </div>
                                  </td>
                                  <td>{item.email}</td>
                                </tr>
                              );
                            })}
                          <span style={{ color: "red" }}>
                            {errors?.cliNameError}
                          </span>
                        </tbody>
                      </table>
                    </div>
                    <div className="form-group">
                      <h6>Message</h6>
                      <textarea
                        type="text"
                        className="form-control"
                        rows={4}
                        placeholder="Include message (optional)"
                        name="emailBody"
                        value={emailBody}
                        onChange={handleInputChange}
                      />
                      <span style={{ color: "red" }}>{errors?.eBodyError}</span>
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button
                        className="Create mr-3"
                        onClick={handleShareDocumentSubmit}
                      >
                        Share Document
                      </button>
                      <button
                        className="Outline ml-3"
                        onClick={handleShareClientDocumentClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={SendForApprovalModal}
          onHide={handleSendForApprovalClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleSendForApprovalClose}>
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Send file for Approval</h4>
                  </div>
                  <div className="form-group mb-4">
                    <h6>Approver Type</h6>
                    {["Client", "Staff Member", "Vendor"]?.map((type) => (
                      <div key={type} className="mb-4">
                        <label className="Radio">
                          {type}
                          <input
                            type="radio"
                            value={type}
                            checked={approverType === type}
                            onChange={() => handleTypeChange(type)}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="FolderForm">
                    <div className="form-group">
                      <h6>Contact Selection</h6>
                      <div className="mb-4">
                        <label className="Radio">
                          {" "}
                          Select from System
                          <input
                            type="radio"
                            value="Contact Selection"
                            checked={contactOption === "Contact Selection"}
                            onChange={handleContactChange}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <label className="Radio">
                        {" "}
                        Input Manually
                        <input
                          type="radio"
                          value="Input Manually"
                          checked={contactOption === "Input Manually"}
                          onChange={handleContactChange}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div
                      className="text RadioText"
                      style={{ display: "block" }}
                    >
                      {contactOption === "Contact Selection" ? (
                        approverType === "Client" ? (
                          <div className="form-group mt-4">
                            <h6>Select Client</h6>
                            <div className="CommonSelectBox w-100">
                              <select
                                name="clientId"
                                value={clientId}
                                onChange={handleInputChange}
                              >
                                <option value="">Select Client</option>
                                {allComapnyClientList?.clientDetails?.length >
                                  0 &&
                                  allComapnyClientList?.clientDetails?.map(
                                    (item, i) => {
                                      if (item.name)
                                        return (
                                          <option value={item.id} key={i}>
                                            {item.name}{" "}
                                            {item?.isPrimary ? "(Primary)" : ""}
                                          </option>
                                        );
                                    }
                                  )}
                              </select>
                            </div>
                          </div>
                        ) : approverType === "Staff Member" ? (
                          <div className="form-group mt-4">
                            <h6>Select Staff</h6>
                            <div className="CommonSelectBox w-100">
                              <select
                                name="staffId"
                                value={staffId}
                                onChange={handleInputChange}
                              >
                                <option value="">Select Staff</option>
                                {staffListData?.staff?.length > 0 &&
                                  staffListData?.staff?.map((item, i) => {
                                    return (
                                      <option value={item.id} key={i}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                        ) : (
                          <div className="form-group mt-4">
                            <h6>Select Vendor</h6>
                            <div className="CommonSelectBox w-100">
                              <select
                                name="vendorId"
                                value={vendorId}
                                onChange={handleInputChange}
                              >
                                <option value="">Select Vendor</option>
                                {allVendorList?.VendorList?.length > 0 &&
                                  allVendorList?.VendorList?.map((item, i) => {
                                    if (item.vendorName)
                                      return (
                                        <option value={item.id} key={i}>
                                          {item.vendorName}
                                        </option>
                                      );
                                  })}
                              </select>
                            </div>
                          </div>
                        )
                      ) : (
                        <>
                          {Array.isArray(contactSelection) &&
                            contactSelection?.map((contact, index) => (
                              <>
                                <div key={index} className="form-group">
                                  <h6>Name</h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Insert name"
                                    name="name"
                                    value={contact.name}
                                    onChange={(e) =>
                                      handleNameEmailChange(e, index)
                                    }
                                  />
                                </div>
                                <div className="form-group">
                                  <h6>Email</h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Insert Email"
                                    name="email"
                                    value={contact.email}
                                    onChange={(e) =>
                                      handleNameEmailChange(e, index)
                                    }
                                  />
                                </div>
                              </>
                            ))}
                        </>
                      )}
                    </div>

                    <div className="Button d-flex justify-content-between mt-4">
                      <button
                        className="Create"
                        onClick={handleSendForApprovalSubmit}
                      >
                        Confirm Selection
                      </button>
                      <button
                        className="Outline"
                        onClick={handleSendForApprovalClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={UploadTemplateModal}
          onHide={handleUploadTemplateClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleUploadTemplateClose}>
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Upload Images</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="form-group">
                      <h6>Select Files</h6>
                      <div className="Upload">
                        <span>-Drag and Drop Files or click to select</span>
                        <input
                          type="file"
                          id="templateFileInput"
                          onChange={(e) => handleFileChange(e, "template")}
                        />
                      </div>
                    </div>
                    {/* <div className="Button mb-4">
                        <button className="Create">Add</button>
                      </div> */}
                    {templateFile && (
                      <div className="form-group FilesAddedGroup">
                        <h6>Images</h6>
                        <div className="FilesAdded">
                          <label>{templateFile?.name}</label>
                          <span>
                            <img
                              src={URL.createObjectURL(templateFile)}
                              alt="Image Preview"
                            />
                          </span>
                          <span className="cross">
                            <img
                              src="images/Cross.png"
                              onClick={handleRemoveTemplate}
                            />
                          </span>
                        </div>
                      </div>
                    )}

                    {/* <div className="Button mb-5">
                        <button className="Create">View</button>
                      </div> */}
                    <div className="Button d-flex justify-content-between">
                      <button
                        className="Create"
                        onClick={handleUploadTemplateSubmit}
                      >
                        Save
                      </button>
                      <button
                        className="Outline"
                        onClick={handleUploadTemplateClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={ViewDocumentationModal}
          onHide={handleViewDocumentationClose}
          className="ModalBox LargeModal GreyModalContent"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleViewDocumentationClose}
                >
                  ×
                </a>
                <div>
                  <div className="ModalTitleBox">
                    <h4>View File</h4>
                  </div>
                  {viewDocumentData ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: viewDocumentData }}
                    />
                  ) : (
                    <p>Loading document...</p>
                  )}
                </div>
                <div className="FolderForm">
                  <div className="Button d-flex justify-content-between m-3">
                    <button
                      className="Outline"
                      onClick={handleViewDocumentationClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={ApproveDocumentationModal}
          onHide={handleApproveDocumentationClose}
          className="ModalBox LargeModal GreyModalContent"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleApproveDocumentationClose}
                >
                  ×
                </a>
                <div className="ModalTitleBox">
                  <h4>Approval File</h4>
                </div>
                <div className="FormArea">
                  {viewDocumentEditData ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: viewDocumentEditData }}
                    />
                  ) : (
                    <p>Loading document...</p>
                  )}
                  <div className="FolderForm">
                    <div className="Button d-flex justify-content-between m-3">
                      <button
                        className="BrandBtnGreen"
                        onClick={() => handleApprovReject("approve")}
                      >
                        Approve
                      </button>
                      <button
                        className="Create"
                        onClick={() => handleApprovReject("reject")}
                      >
                        Reject
                      </button>
                      <button
                        className="Outline"
                        onClick={handleApproveDocumentationClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={FolderDeleteModal}
          onHide={handleFolderDeleteClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleFolderDeleteClose}>
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Delete</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="my-5">
                      <h6>Are you sure you want to delete this ?</h6>
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button className="Create" onClick={handleFolderDelete}>
                        Delete
                      </button>
                      <button
                        className="Outline"
                        onClick={handleFolderDeleteClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={EditDocumentationModal}
          onHide={handleEditDocumentationClose}
          className="ModalBox ExtraLargeModal GreyModalContent CkEditorHeight"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleEditDocumentationClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Edit File</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="EditOption">
                      <div className="EditOptionBox">
                        <h6>Name</h6>
                        {!fileFolderNameShow ? (
                          <a href="javascript:void(0);">
                            {viewDocumentation?.ClientDocDetails?.name ?? "N/A"}
                            &nbsp;&nbsp;
                            <span onClick={fileFolderNameChange}>
                              <img src="images/fileview.png" />
                            </span>
                          </a>
                        ) : (
                          <>
                            <input
                              type="text"
                              name="fileFolderName"
                              value={fileFolderName}
                              onChange={handleInputChange}
                            />
                          </>
                        )}
                      </div>
                      <div className="EditOptionBox">
                        <h6>Folder</h6>
                        <a href="javascript:void(0);">
                          {viewDocumentation?.ClientDocDetails?.folder
                            ?.folderName ?? "N/A"}
                          &nbsp;&nbsp;
                        </a>
                      </div>
                      <div className="EditOptionBox">
                        <h6>Draft</h6>
                        <a>
                          <label className="CheckBox">
                            <input
                              type="checkbox"
                              checked={draft}
                              onChange={handleDraftChange}
                            />
                            <span className="checkmark" />
                          </label>
                        </a>
                      </div>
                      <div className="EditOptionBox">
                        <h6>Use Company Header</h6>
                        <a
                          className="FolderPermissionId"
                          onClick={() => handlHeaderPreviewModalShow()}
                        >
                          <span>
                            <img src="images/company_header.png" />
                          </span>
                        </a>
                      </div>
                      <div className="EditOptionBox">
                        <h6>Download</h6>
                        <a
                          href={viewDocumentation?.ClientDocDetails?.docUrl}
                          target="_blank"
                        >
                          <span>
                            <img src="images/download.png" />
                          </span>
                        </a>
                      </div>
                      <div className="EditOptionBox">
                        <h6>Insert Signature</h6>
                        <a onClick={() => handlInsertSignatureModalShow()}>
                          <span>
                            <img src="images/signature.png" />
                          </span>
                        </a>
                      </div>
                      <div className="EditOptionBox">
                        <h6>Insert Keys</h6>
                        <a
                          className="FolderpermissionId"
                          onClick={() => handleDocumentKeyModalShow()}
                        >
                          <span>
                            <img src="images/insert_keys.png" />
                          </span>
                        </a>
                      </div>
                    </div>

                    {content ? (
                      <div className="mce-pagebreak">
                        <Editor
                          apiKey="rye4mz748ekd8ke0loyu8j7gq7iu1tkxs3nticj32j848g6r" // Use your premium API key
                          onInit={(evt, editor) =>
                            (editorsRef.current = editor)
                          }
                          value={content ?? ""}
                          init={{
                            height: 500,
                            menubar:
                              "file edit view insert format tools table help",
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "print",
                              "preview",
                              "anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "media",
                              "table",
                              "paste",
                              "help",
                              "wordcount",
                              "lists",
                              "table",
                              "pagebreak",
                              "spellchecker",
                              "powerpaste",
                              "mediaembed",
                              "advcode",
                              "emoticons",
                              "codesample",
                              "advtable",
                              "visualchars",
                              "visualblocks",
                              "linkchecker",
                              "checklist",
                              "media",
                              "tableofcontents",
                              "a11ychecker",
                              "permanentpen",
                              "casechange",
                              "pageembed",
                              "nonbreaking",
                              "quickbars",
                              "advlist",
                              "autosave",
                              "autolink",
                              "autoresize",
                            ],
                            toolbar:
                              "undo redo | styles| bold italic underline strikethrough | fontfamily fontsize blocks | " +
                              "alignleft aligncenter alignright alignjustify | numlist bullist checklist outdent indent | " +
                              "forecolor backcolor casechange permanentpen formatpainter removeformat | " +
                              "pagebreak | charmap emoticons | fullscreen preview save print | insertfile image media pageembed link anchor codesample | " +
                              "a11ycheck ltr rtl | showcomments addcomment",
                            toolbar_sticky: true,
                            autosave_ask_before_unload: true,
                            autosave_interval: "30s",
                            autosave_prefix: "{path}{query}-{id}-",
                            autosave_restore_when_empty: true,
                            autosave_retention: "2m",
                            image_advtab: true,
                            content_css: "default",
                            importcss_append: true,
                            image_title: true,
                            automatic_uploads: true,
                            powerpaste_word_import: "clean", // options: 'clean', 'merge', 'prompt'
                            powerpaste_html_import: "merge", // options: 'merge', 'clean', 'prompt'
                            forced_root_block: "", // allows for line breaks instead of <p>
                            force_br_newlines: true,
                            force_p_newlines: false,
                            content_style: `
          .mce-item-table:not([border]), .mce-item-table:not([border]) caption, 
          .mce-item-table:not([border]) td, .mce-item-table:not([border]) th, 
          .mce-item-table[border="0"], .mce-item-table[border="0"] caption, 
          .mce-item-table[border="0"] td, .mce-item-table[border="0"] th, 
          table[style*="border-width: 0px"], table[style*="border-width: 0px"] caption, 
          table[style*="border-width: 0px"] td, table[style*="border-width: 0px"] th { 
            border: 0;
          }
        `,
                            setup: (editor) => {
                              editor.on("drop", (e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                if (e.dataTransfer && e.dataTransfer.files) {
                                  const files = e.dataTransfer.files;
                                  for (const file of files) {
                                    const reader = new FileReader();
                                    reader.onload = (event) => {
                                      editor.insertContent(
                                        `<img src="${event.target.result}" alt="${file.name}"/>`
                                      );
                                    };
                                    reader.readAsDataURL(file);
                                  }
                                }
                              });
                            },
                            link_list: [
                              {
                                title: "My page 1",
                                value: "https://www.tinymce.com",
                              },
                              {
                                title: "My page 2",
                                value: "http://www.moxiecode.com",
                              },
                            ],
                            image_list: [
                              {
                                title: "My image 1",
                                value:
                                  "https://www.tiny.cloud/images/glyph-tinymce.svg",
                              },
                              {
                                title: "My image 2",
                                value:
                                  "https://www.tiny.cloud/docs/images/glyph-tinymce.svg",
                              },
                            ],
                            image_class_list: [
                              { title: "None", value: "" },
                              { title: "Some class", value: "some-class" },
                            ],
                            importcss_file_filter: "main.css",
                            templates: [
                              {
                                title: "New Table",
                                description: "creates a new table",
                                content:
                                  "<table><tr><td>Sample Text</td></tr></table>",
                              },
                              {
                                title: "Starting my story",
                                description: "A sample document",
                                content: "Once upon a time...",
                              },
                              {
                                title: "New list with dates",
                                description: "New List with Dates",
                                content:
                                  "<ul><li>12 December</li><li>13 December</li></ul>",
                              },
                            ],
                            template_cdate_format:
                              "[CDATE: %m/%d/%Y : %H:%M:%S]",
                            template_mdate_format:
                              "[MDATE: %m/%d/%Y : %H:%M:%S]",
                            height: 600,
                            image_caption: true,
                            quickbars_selection_toolbar:
                              "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                            noneditable_class: "mceNonEditable",
                            toolbar_mode: "sliding",
                            spellchecker_dialog: true,
                            spellchecker_whitelist: ["Ephox", "Moxiecode"],
                            tinycomments_mode: "embedded",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            a11y_advanced_options: true,
                            branding: false, // Remove TinyMCE branding
                            powerpaste_word_import: "clean", // Configure PowerPaste
                            powerpaste_html_import: "merge",
                            powerpaste_allow_local_images: true,
                            media_live_embeds: true, // Enable live media embeds
                            forced_root_block: "",
                            force_br_newlines: true,
                            force_p_newlines: false,
                          }}
                          onEditorChange={handleEditorChange}
                        />
                      </div>
                    ) : (
                      <p>Loading document...</p>
                    )}
                    <div className="Button d-flex justify-content-between">
                      <button
                        className="Create mt-5"
                        onClick={handleEditDocumentation}
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            {" "}
                            <span
                              className="spinner-border spinner-border-md"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </>
                        ) : (
                          "Save File"
                        )}
                      </button>
                      <button
                        className="Create mt-5"
                        onClick={handleEditDocumentationClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={RelaseTemplateModal}
          onHide={handleRelaseTemplateModalClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleRelaseTemplateModalClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Release Templates</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="form-group">
                      <h6>Project</h6>
                      <div className="CommonSelectBox w-100">
                        <select
                          name="projectId"
                          value={projectId}
                          onChange={handleInputChange}
                        >
                          <option value="">Select Project</option>
                          {allProjectList?.projects?.length > 0 &&
                            allProjectList?.projects?.map((item, i) => {
                              return (
                                <option value={item.id}>
                                  {item.projectName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="SubFileManager">
                          <div className="ModalMediumheading">
                            <h3>
                              <span>Sub File Manager</span>
                            </h3>
                          </div>
                          <div className="form-group">
                            <h6>Construction</h6>
                            <label className="Radio">
                              <input
                                type="radio"
                                name="designFiles"
                                value="Construction"
                                checked={selectedOption === "Construction"}
                                onChange={handleOptionChange}
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="form-group">
                            <h6>Design</h6>
                            <label className="Radio">
                              <input
                                type="radio"
                                name="designFiles"
                                value="Design"
                                checked={selectedOption === "Design"}
                                onChange={handleOptionChange}
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="form-group">
                            <h6>Sales</h6>
                            <label className="Radio">
                              <input
                                type="radio"
                                name="designFiles"
                                value="Sales"
                                checked={selectedOption === "Sales"}
                                onChange={handleOptionChange}
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="form-group">
                            <h6>Warranty</h6>
                            <label className="Radio">
                              <input
                                type="radio"
                                name="designFiles"
                                value="Warranty"
                                checked={selectedOption === "Warranty"}
                                onChange={handleOptionChange}
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="form-group">
                            <h6>Scope of Work</h6>
                            <label className="Radio">
                              <input
                                type="radio"
                                name="designFiles"
                                value="Estimation"
                                checked={selectedOption === "Estimation"}
                                onChange={handleOptionChange}
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mt-4">
                      <h6>System File Manager Folder</h6>
                      <div className="CommonSelectBox w-100">
                        <select
                          name="folderId"
                          value={folderId}
                          onChange={handleInputChange}
                        >
                          <option value="">Select Project</option>
                          {allFolderList?.length > 0 &&
                            allFolderList?.map((item, i) => {
                              return (
                                <option
                                  value={item.id}
                                  key={i}
                                  selected={item.id == folderId}
                                >
                                  {item.folderName}{" "}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    {folderId != null && (
                      <div className="row">
                        <div className="col-12">
                          <div className="ModalMediumheading">
                            <h3>
                              <span>Templates</span>
                            </h3>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="DisplayLoginOnPage">
                            <div className="form-group">
                              {folderData?.length > 0 &&
                                folderData?.map((item, i) => {
                                  return (
                                    <div className="mb-2">
                                      <label className="CheckBox">
                                        {item.name}
                                        <input
                                          type="checkbox"
                                          checked={selectedItems.includes(item)}
                                          onChange={(e) =>
                                            handleFolderCheckboxChange(e, item)
                                          }
                                        />
                                        <span className="checkmark" />
                                      </label>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="Button d-flex justify-content-between mt-2">
                      <button
                        className="Create"
                        onClick={handleReleaseTemplate}
                      >
                        Release Templates
                      </button>
                      <button
                        className="Outline"
                        onClick={handleRelaseTemplateModalClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={LockTemplateModal}
          onHide={handleLockTemplateModalClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleLockTemplateModalClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Lock Template</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="ModalMediumheading">
                      <h3>
                        <span>Folder:</span>Sales Contract
                      </h3>
                    </div>
                    <div className="AdvancedCostCodes Lock">
                      <table>
                        <thead>
                          <tr>
                            <th>Templates</th>
                            <th>Lock</th>
                          </tr>
                        </thead>
                        <tbody>
                          {folderDatas?.length > 0 &&
                            folderDatas?.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>{item.name}</td>
                                  <td>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        checked={templates.some(
                                          (template) => template.id === item.id
                                        )}
                                        onChange={(e) =>
                                          handleTemplateCheckboxChange(e, item)
                                        }
                                      />
                                      <span className="slider" />
                                    </label>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                    <div className="Button d-flex justify-content-between mt-5">
                      <button className="Create" onClick={handleLockUnlock}>
                        Lock templates
                      </button>
                      <button
                        className="Outline"
                        onClick={handleLockTemplateModalClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={FilesHistoryModal}
          onHide={handleFilesHistoryModalClose}
          className="ModalBox fade SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleFilesHistoryModalClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Files History</h4>
                  </div>
                  <div className="ModalTitleHeading">
                    <h2>
                      <span>Name: </span>
                      {fileNames}
                    </h2>
                  </div>
                  <div className="TableList">
                    <table>
                      <thead>
                        <tr>
                          <th>Modified by</th>
                          <th>Created by</th>
                          <th>Date</th>
                          <th>Activity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fileHistoryList?.clientHistories?.length > 0
                          ? fileHistoryList?.clientHistories?.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>{item.userDetails?.name ?? "N/A"}</td>
                                  <td>
                                    {item?.clientdoc?.createdByUser?.name ??
                                      "N/A"}
                                  </td>
                                  <td>
                                    {moment(item.createdAt).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </td>
                                  <td>{item.activity}</td>
                                </tr>
                              );
                            })
                          : "No Data Found"}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={DocumentKeyModal}
          onHide={handleDocumentKeyModalClose}
          className="ModalBox modal fade MediumModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleDocumentKeyModalClose}
            >
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Document Key</h4>
              </div>
              <div className="FolderForm">
                <div className="row">
                  <div className="col-md-7">
                    <div className="form-group">
                      <h6>Keys List</h6>
                      <div className="Categories Categories1">
                        {/* <div className="Categories_all Categories_all1">
                          <span>Block Job</span>
                          <img src="images/down.png" />
                        </div> */}
                        {/* <ul style={{ display: "block" }}>
                          <li>
                            <input
                              type="text"
                              placeholder="Search"
                              name="documentSearch"
                              value={documentSearch}
                              onChange={handleInputChange}
                            />
                            <span className="Search">
                              <img src="images/search.png" />
                            </span>
                          </li>

                          {allDocumentKey?.length > 0 &&
                            allDocumentKey
                              ?.filter((data) =>
                                documentSearch.toLowerCase() === ""
                                  ? true
                                  : data.label
                                      .toLowerCase()
                                      .includes(documentSearch.toLowerCase())
                              )
                              .map((item, i) => {
                                return (
                                  <li
                                    value={item.label}
                                    key={i}
                                    onClick={() => handleItemClick(item.html)}
                                    style={{
                                      backgroundColor:
                                        JobId === item.label
                                          ? "#e71e09"
                                          : "transparent",
                                    }}
                                  >
                                    {item.label}
                                  </li>
                                );
                              })}
                        </ul>  */}
                        <select
                          className="Categories_all Categories_all1"
                          onChange={handleItemClick}
                        >
                          <option value="">Select</option>
                          {allDocumentKey?.length > 0 &&
                            allDocumentKey?.map((data, i) => {
                              return (
                                <option value={data.html} key={i}>
                                  {data.label}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-4 offset-1">
                    <div className="form-group">
                      <h6>Key</h6>
                      <p className="GreenSignedColor ml-0">
                        <img src="images/insert_keys.png" />
                        &nbsp;&nbsp;{JobId ?? "N/A"}
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={HeaderPreviewModal}
          onHide={handlHeaderPreviewModalClose}
          className="ModalBox modal fade LargeModal GreyModalContent show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handlHeaderPreviewModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Header Preview</h4>
              </div>
              <div className="FolderForm">
                <div className="AgreementViewArea">
                  <div className="AgreementViewTop">
                    {/* <a href="javscript:void(0)">
                    <img src="images/modal-big-logo.png" />
                  </a> */}
                    <div className="AgreementViewTopRight">
                      <p
                        dangerouslySetInnerHTML={createAboutMarkup()}
                        className="editor"
                      ></p>
                    </div>
                  </div>
                </div>
                {/* <div className="CommonModalArea">
      <div className="CommonModalBox">
        <h5 className="CursorPointer text-black">
          <figure>
            <img src="images/configure_header.png" />
          </figure>{" "}
          Configure Company Header
        </h5>
      </div>
    </div> */}
                <div className="Button d-flex justify-content-between mt-5">
                  <button className="Create" onClick={() => handleApply()}>
                    Apply
                  </button>
                  <button
                    className="Outline"
                    onClick={handlHeaderPreviewModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={InsertSignatureModal}
          onHide={handlInsertSignatureModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handlInsertSignatureModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Insert Signature</h4>
              </div>
              <div className="FolderForm">
                {manageSignatureList?.images?.length > 0 &&
                  manageSignatureList?.images?.map((item, i) => {
                    return (
                      <div className="SignatureArea border-bottom py-2" key={i}>
                        <a>
                          <img src={item.docUrl} />
                        </a>

                        <div className="Button">
                          <button
                            className="Create px-2"
                            onClick={() => handleRemoveManageSignature(item.id)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    );
                  })}

                <div className="Button d-flex justify-content-between mt-5">
                  <button
                    className="Create FolderPermissionId"
                    onClick={handleAddSignature}
                  >
                    Add Signature
                  </button>
                  <button
                    className="Outline"
                    onClick={handlInsertSignatureModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    </div>
  );
};

export default FileManager;
