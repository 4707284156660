import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ibuildAdminLoginAction } from "../../redux/action/authAction";
import { errorToast } from "../../utils/toast";

const init = {
  email: "",
  password: "",
  errors: {},
  loading: false,
};

const IbuildAdminLogin = () => {
  const [iState, updateState] = useState(init);
  const { email, password, errors, loading } = iState;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ibuildShow, setIbuildShow] = useState(false);
  const handleValidation = () => {
    let formIsValid = true;
    let error = {};
    if (!email) {
      error.emailError = "* Email address can't be empty";
      formIsValid = false;
    }

    if (email) {
      // if (
      //   !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      //     email
      //   )
      // ) {
      //   error.emailError = "Please enter a valid email";
      //   formIsValid = false;
      // }
    }

    if (!password?.trim("")) {
      error.passwordError = "! Password can't be empty";
      formIsValid = false;
    }

    // if (password) {
    //   if (
    //     !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    //       password
    //     )
    //   ) {
    //     error.passwordError =
    //       "Please enter atleast one speical character one alphnumeric and one capital letter";
    //     formIsValid = false;
    //   }
    // }

    updateState({ ...iState, errors: error });
    return formIsValid;
  };

  const handleLogin = (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    if (formIsValid) {
      updateState({ ...iState, loading: true });
      dispatch(ibuildAdminLoginAction({ email, password }))
        .then((res) => {
          // console.log("==============res", res);
          navigate("/ibuild-admin-dashboard");
          updateState({ ...iState, loading: false });
        })
        .catch((err) => {
          console.log("======>", err);
          updateState({ ...iState, loading: false });
          errorToast("Invalid credentials");
        });
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  return (
    <>
      <div className="LoginArea">
        <div className="LoginBoxBig">
          <div className="row">
            <div className="col-md-5">
              <div className="LoginBoxLeft">
                <figure>
                  <img src="images/login_background.png" />
                </figure>
              </div>
            </div>
            <div className="col-md-7">
              <div className="LoginBox">
                {/* <a href="javascript:void(0);"><img src="images/login_background.png" /></a> */}
                <h6 className="mb-0">Multi-Family and Residential Platform</h6>
                <div className="TabContenArea">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h3>Welcome Back, Ibuild</h3>
                      <p>Login to continue</p>
                    </div>
                  </div>

                  <form onSubmit={handleLogin}>
                    <div className="form-group">
                      <h4> Ibuild </h4>
                    </div>
                    <div className="form-group">
                      <label>Username</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter text"
                        name="email"
                        value={email}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors?.emailError}
                      </span>
                    </div>

                    <div className="form-group">
                      <label>Password</label>
                      <div className="InputPasswordView">
                        <input
                          type={ibuildShow ? "text" : "password"}
                          className="form-control"
                          placeholder="Enter text"
                          name="password"
                          value={password}
                          onChange={handleInput}
                        />

                        <span
                          onClick={() => setIbuildShow(!ibuildShow)}
                          className={ibuildShow ? "Icon cross" : "Icon"}
                        >
                          <img src="images/lock_password.png" />
                        </span>
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors?.passwordError}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="form-group">
                        <label className="CheckBox">
                          {" "}
                          Remember me
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div className="form-group">
                        <div className="Checkboxs">
                          <span>&nbsp;</span>
                          <Link to="/main-login-forgot">Forgot password?</Link>
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="Button" disabled={loading}>
                      {loading ? (
                        <>
                          {" "}
                          <span
                            className="spinner-border spinner-border-md"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </>
                      ) : (
                        "Login"
                      )}
                    </button>
                  </form>
                  <div className="loginSocials">
                    <a href="javascript:void(0);">
                      <img src="images/facebook_red.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/earth_red.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/mail_red.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/linkdin_red.png" />
                    </a>
                  </div>
                  <div className="TabContentBottom">
                    <p className="">
                      Not an <span>Ibuild</span> customer yet?{" "}
                      <Link to="/subscription-start">Subscribe Now</Link>
                    </p>
                    <div className="Logo">
                      <img src="images/Logo.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IbuildAdminLogin;
