import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../App.css";
import Modal from "react-bootstrap/Modal";
import Loader from "./Loader";
import moment from "moment";
import { toast } from "react-toastify";
import { Link, useLocation, useParams } from "react-router-dom";
import TradeViewSidenav from "./trade-view-sidenav";
import TradeHeader from "./TradeHeader";
const initialState={
  agreementDocumentShow:false,
}
const EditChangeOrderTradeView = () => {
  const [iState,updateState]=useState(initialState);
  const {agreementDocumentShow}=iState
    const {state}=useLocation();
    console.log(state);

    const handleAgreementDocumentShow=()=>{

      updateState({...iState,agreementDocumentShow:!agreementDocumentShow})
    }
    const [showClient,setShowClient]=useState(false)
    const [showDiscussion,setShowDiscussion]=useState(false)
  return (
    <div>
   <TradeHeader/>
      <TradeViewSidenav />
      <div className="WrapperAreaClient">
  <div className="WrapperBoxClient">
    <div className="TitleBox">
      <h4 className="Title">Change Order #1</h4>
      <div className="TitleBoxProjectHeading">
        <h6>
          <span>Status:</span>&nbsp;Purchase Orders Created
        </h6>
        <h6>
          <span>Authorization Status:</span>&nbsp;In progess
        </h6>
      </div>
      <div className="TitleBox2 TitleBoxRight">
        <div>
          <h6>UUnit3 -CHI -2B -315</h6>
          <span>Multi-Family - Residential Unit</span>
        </div>
      </div>
    </div>
    <div className="WarrantyDashboardForm mb-4">
      <div className="row">
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label>
                  Description <span className="Required">*</span>
                </label>
                {/* <textarea
                class="form-control"
                rows="3"
                placeholder="Write here ....."
              >
            </textarea> */}
                <p>Plumbing Fixes</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <div className="CommonModalArea">
              <h5>Supporting Document</h5>
              <div className="CommonModalBox">
                <h5
                  className="CursorPointer"
                  data-toggle="modal"
                  data-target="#AddNewSupportingDocs2"
                >
                  <figure>
                    <img src="images/document-support.png" />
                  </figure>
                  Supporting Document
                </h5>
                <span
                  className="Count CursorPointer"
                  data-toggle="modal"
                  data-target="#SupportingDocumentList"
                >
                  3
                </span>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="CommonModalArea">
              <h5>Add Notes</h5>
              <div className="CommonModalBox">
                <h5
                  className="CursorPointer"
                  data-toggle="modal"
                  data-target="#NoteAdd2"
                >
                  <figure>
                    <img src="images/document-support.png" />
                  </figure>
                  Add Notes
                </h5>
                <span
                  className="Count CursorPointer"
                  data-toggle="modal"
                  data-target="#NotesList"
                >
                  3
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="WarrantyDashboardForm">
      <h4>Client Details</h4>
      <div className="row">
        <div className="col-lg-6">
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Possession Date</label>
                {/* <input
                type="text"
                class="form-control"
                placeholder="Auto-filled by System"
              /> */}
                <p>Auto-filled by System</p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Request Date</label>
                {/* <input
                type="text"
                class="form-control"
                placeholder="Auto-filled by System"
              /> */}
                <p>Auto-filled by System</p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Client Full Legal Name</label>
                {/* <input
                type="text"
                class="form-control"
                placeholder="Auto-filled by System"
              /> */}
                <p>Auto-filled by System</p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Phone Number</label>
                {/* <input
                type="text"
                class="form-control"
                placeholder="Auto-filled by System"
              /> */}
                <p>Auto-filled by System</p>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label>Primary Email</label>
                {/* <input
                type="text"
                class="form-control"
                placeholder="Auto-filled by System"
              /> */}
                <p>Auto-filled by System</p>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label>
                  Job Address <img src="images/location.png" className="ml-2" />
                </label>
                {/* <input
                type="text"
                class="form-control"
                placeholder="Auto-filled by System"
              /> */}
                <p>Auto-filled by System</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="Profile_ontario mb-5">
            <div className="Profile_ontario_box">
              <h6>Profile:</h6>
              <p>Ontario Tax</p>
            </div>
            <div className="Profile_ontario_box">
              <h6>HST:</h6>
              <p>13%</p>
            </div>
          </div>
          <div className="form-group mb-5">
            <div className="CommonModalArea">
              <h5>Adjust Change Order</h5>
              <div className="CommonModalBox">
                <h5
                  className="CursorPointer"
                  data-toggle="modal"
                  data-target="#SelectItemsfromPriceBook"
                  data-dismiss="modal"
                >
                  <figure>
                    <img src="images/adjust_change_order.png" />
                  </figure>
                  Adjust Change Order
                </h5>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="CommonModalArea">
              <h5>Add Items from Pricebook</h5>
              <div className="CommonModalBox">
                <h5
                  className="CursorPointer"
                  data-toggle="modal"
                  data-target="#SelectItemsfromPriceBook"
                >
                  <figure>
                    <img src="images/add_items_from_pricebook.png" />
                  </figure>
                  Add Items from Pricebook
                </h5>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="CommonModalArea">
              <h5>Add Custom Item</h5>
              <div className="CommonModalBox">
                <h5
                  className="CursorPointer"
                  data-toggle="modal"
                  data-target="#AddCustomItem"
                >
                  <figure>
                    <img src="images/add_custom_item.png" />
                  </figure>
                  Add Custom Item
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="Tableheader mt-5">
      <h6>Change Items</h6>
    </div>
    <div className="TableList NewTableList TableListHeader TableHeightScroll">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Description</th>
            <th>Quantity</th>
            <th>Measure</th>
            <th>Per Unit</th>
            <th>Client Total</th>
            <th>Po Line Total</th>
            <th>Markup</th>
            <th>Accounts</th>
            <th>Pictures</th>
            <th>Supporting Doc</th>
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Sandstone Color Pallete</td>
            <td>2</td>
            <td>
              10
              {/* <div class="SelectAnOption">
                <div class="dropdown">
                  <button class="" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Select an option
                    <img src="images/black-down-arrow.png">
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <div class="DropdownItemSearch">
                        <input type="text" placeholder="-Search">
                        
                          <img src="images/search.png">
                        
                      </div>
                      <a class="dropdown-item" href="javascript:void(0);" data-toggle="modal" data-target="#CreateMeasure" ><img src="images/add-plus.png">Add New</a>
                      <a class="dropdown-item" href="javascript:void(0);">-Warranty</a>
                      <a class="dropdown-item" href="javascript:void(0);">-   Full list of units 
                        loaded from 
                        the system</a>
                    </div>
                </div>
              </div> */}
            </td>
            <td>$1,000.00</td>
            <td>$1,000.00</td>
            <td>$1,000.00</td>
            <td>20 %</td>
            <td>
              10
              {/* <div class="SelectAnOption">
                <div class="dropdown">
                  <button class="" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Select an option
                    <img src="images/black-down-arrow.png">
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <div class="DropdownItemSearch">
                        <input type="text" placeholder="-Search">
                        
                          <img src="images/search.png">
                        
                      </div>
                      <div class="TableCheckBoxArea">
                        <label class="CheckBox"> List of Cost Codes loaded by the system
                          <input type="checkbox">
                          <span class="checkmark"></span>
                      </label>
                      </div>
                      
                      
                    </div>
                </div>
              </div> */}
            </td>
            <td>
              <figure>
                <img src="images/picture-11.png" />
              </figure>
            </td>
            <td>
              <div className="Actions">
                <a
                  href="javascript:void(0);"
                  data-toggle="modal"
                  data-target="#AddNewSupportingDocs2"
                >
                  <img src="images/icon-63.png" />
                </a>
                <a
                  href="javascript:void(0)"
                  data-toggle="modal"
                  data-target="#SupportingDocumentList"
                >
                  <span className="Count">3</span>
                </a>
              </div>
            </td>
            {/* <td>
              <div class="TableThreeDots">
                <ul class="">
                  <li class="dropdown">
                    <a class="" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <img src="images/dots.png">
                    </a>
                    <ol class="dropdown-menu dropdown-menuwidth2">
                      <li><a href="javascript:void(0);"><img src="images/update_price_book.png">Update Price Book's Entry</a></li>
                      <li><a href="javascript:void(0);"><img src="images/dropdown-delete.png">Remove item</a></li>
                    </ol>
                  </li>
                </ul>
               </div>
            </td> */}
          </tr>
          
        </tbody>
      </table>
    </div>
    {/* <div class="TodosButtons mt-5">
      <a href="javascript:void(0)" class="mr-5 Create" data-toggle="modal" data-target="#GenerateCreateNewFieldPurchaseOrderGreen"><h6 style="color: rgb(252, 52, 0);"><img src="images/generate_purchase_order.png">Generate Purchase Orders</h6>
        <span>3</span>
      </a>
      <a href="javascript:void(0)" class="mx-5" data-toggle="modal" data-target="#ChangeOrderPurchaseOrderList"><h6><img src="images/show-create-purchase.png">Purchase Order List</h6>
        <span>3</span>
      </a>
      <a href="javascript:void(0)" class="mx-5" data-toggle="modal" data-target="#AdjustmentRequestListBtn"><h6><img src="images/show-create-purchase.png">Adjustment Requests</h6>
        <span>3</span>
      </a>
    </div> */}
    <div className="ClientContactArea mt-5">
      <div className="ClientContactTitle ClientContactTitle1" onClick={()=>setShowClient(!showClient)}>
        <h4>Client Contact</h4>
        <div className="DiscussionChat">
          <img src="images/down.png" className="DiscussionIcon" />
        </div>
      </div>
      <div
        className="ClientContactHideArea ClientContactHideArea1"
        style={{ display: showClient ? 'block' : 'none', color: 'red' }}
      >
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-5">
                <div className="UrgencyArea mb-5">
                  <div className="form-group">
                    <label>Builder Signee</label>
                    <p>Lorem Ipsum</p>
                    {/* <div class="Categories Categories1">
                      <div class="Categories_all Categories_all1">
                        <span>Select Builder Signee</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>List of employees that are authorized to sign documents. Text Format: Employee's name(Employee's email)</li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-5 ml-auto">
                <div className="FolderForm">
                  <div className="form-group">
                    <h6>Client Signees</h6>
                    <h6>Number of Signees</h6>
                    {/* <input type="text" class="form-control" placeholder="Insert Number of Signees"> */}
                    <p>Lorem Ipsum</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5">
                <div className="UrgencyArea">
                  <div className="form-group">
                    <label>Primary Client Signee</label>
                    <p>Lorem Ipsum</p>
                    {/* <div class="Categories Categories1">
                      <div class="Categories_all Categories_all1">
                        <span>Select Builder Signee</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>List of employees that are authorized to sign documents. Text Format: Employee's name(Employee's email)</li>
                        <li><span class="AddNew">+</span>Add New</li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-5 ml-auto">
                <div className="UrgencyArea">
                  <div className="form-group">
                    <label>Additional Client Signee</label>
                    <p>Lorem Ipsum</p>
                    {/* <div class="Categories Categories1">
                      <div class="Categories_all Categories_all1">
                        <span>Select Builder Signee</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>List of employees that are authorized to sign documents. Text Format: Employee's name(Employee's email)</li>
                        <li class="CursorPointer FolderPermissionId" data-toggle="modal" data-target="#AddNewClient"><span class="AddNew">+</span>Add New</li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 ml-auto">
            <div className="FolderForm">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="ClinetSignatureStatus">
                      <h6>Signature Status</h6>
                      <p className="RedStatusColor ml-0">
                        Pending with John Doe
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="ClinetSignatureStatus">
                      <h6>Signature Status</h6>
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target="#SignAgreement"
                      >
                        Sign Document
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="ClinetSignatureStatus">
                      <h6>Signature Status</h6>
                      <p className="GreenSignedColor ml-0 mb-4">Signed</p>
                      <p className="RedStatusColor ml-0">Pending</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <h6 className="">Request Adjustments Function</h6>
                    <label className="CheckBox">
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="Button my-4">
          <button className="Create">Generate/Update Agreement</button>
        </div> */}
      </div>
    </div>
    <div className="ClientContactArea">
      <div className="ClientContactTitle ClientContactTitle2" onClick={()=>handleAgreementDocumentShow()}>
        <h4>Agreement Document</h4>
        <div className="DiscussionChat">
          <img src="images/down.png" className="DiscussionIcon" />
        </div>
      </div>
      <div
        className="ClientContactHideArea ClientContactHideArea2"
        style={{ display: agreementDocumentShow ? 'block' : 'none', color: 'red' }}
      >
        <div className="FolderForm">
          <form>
            <div className="row">
              <div className="form-group col-md-6 mb-4">
                <div className="CommonModalArea">
                  <h5>Agreement Document</h5>
                  <div
                    className="CommonModalBox CursorPointer"
                    data-toggle="modal"
                    data-target="#AgreementView"
                  >
                    <figure className="mb-0">
                      <img src="images/agreement_document.png" />
                    </figure>
                    <span className="Count CursorPointer">3</span>
                    <h5 className="ml-2"> Agreement Document</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="ModalFilterAreaBox">
                  <h4 className="mb-4">
                    Client Acknowledgement of Change Contract
                  </h4>
                  <div className="RadioArea">
                    {/* <div class="form-group">
                          <label class="Radio">Electronic
                            <input type="radio" name="Cuisine">
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="form-group ml-5">
                          <label class="Radio">Hardcopy
                            <input type="radio" name="Cuisine">
                            <span class="checkmark"></span>
                          </label>
                        </div> */}
                    <div className="form-group">
                      <label className="Radio">
                        Electronic
                        <input
                          type="radio"
                          name="Cuisine"
                          defaultValue="text"
                          defaultChecked=""
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="form-group ml-5">
                      <label className="Radio">
                        Hardcopy
                        <input
                          type="radio"
                          name="Cuisine"
                          defaultValue="file"
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>
                {/* <div class="text RadioText">
                  <div class="form-group">
                    <h6>Message (Max 1000 chars limit)</h6>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Write here ....."
                    />
                    <small class="form-text text-right"
                      >985 characters remaining</small
                    >
                  </div>
                </div> */}
                <div className="file RadioText">
                  <div className="row">
                    <div className="form-group col-md-6">
                      <div className="CommonModalArea">
                        <h5>Download Hardcopy Agreement</h5>
                        <div className="CommonModalBox">
                          <figure className="mb-0">
                            <img src="images/agreement_document.png" />
                          </figure>
                          <h5 className="ml-2"> Download Hardcopy Agreement</h5>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="CommonModalArea">
                        <h5>Upload Signed Agreement</h5>
                        <div className="CommonModalBox">
                          <figure className="mb-0">
                            <img src="images/agreement_document.png" />
                          </figure>
                          <h5 className="ml-2"> Download Hardcopy Agreement</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group mt-4">
                  <h6>Read-Only Carbon Copies</h6>
                  <div className="ReadOnlyCarbonCopies">
                    <h6 className="mb-0">1</h6>
                    {/* <input type="text" class="form-control" placeholder="Auto-filled by System">
                  <input type="text" class="form-control" placeholder="Auto-filled by System"> */}
                    <p>Lorem Ipsum</p>
                    <p>Lorem Ipsum</p>
                    <span className="cross">
                      <img src="images/cancel_icon.png" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button className="AddCopy">Add Copy</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div className="SaveDraftBtn d-flex justify-content-between mt-5">
      <button className="BrandBtn">Cancel</button>
      <div className="d-flex ">
        <button className="BrandBtnGreen">Save draft</button>
        <div className="dropdown ml-3">
          <button
            className="BrandBtnGreen"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Save and <img src="images/down-arrow-white.png" />
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item" href="javascript:void(0);">
              Save and Print
            </a>
            <a
              className="dropdown-item"
              href="javascript:void(0);"
              data-toggle="modal"
              data-target="#ClientAgreementEsign"
            >
              Save and E-Sign
            </a>
            <a className="dropdown-item" href="javascript:void(0);">
              Save and Authorize
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="DiscussionArea">
      <div className="DiscussionTitle">
        <h4>Discussion</h4>
        <div className="DiscussionChat">
          <img src="images/down.png" className="DiscussionIcon" />
          <a href="#">
            <img src="images/pdf2.png" />
          </a>
        </div>
      </div>
      <div className="DiscussionHideArea" style={{ display: "block" }}>
        <div className="DiscussionBox">
          <span>FM</span>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Add a Comment ....."
            />
            <div className="DiscussionOverlay">
              <a href="" className="CancelChat">
                <img src="images/plus-cancel1.png" />
              </a>
              <a href="" className="SendChat">
                <img src="images/forwardbutton.png" />
              </a>
            </div>
          </div>
        </div>
        <div className="DiscussionBox">
          <span>TH</span>
          <div className="DiscussionRight">
            <h6>Inspections are delayed</h6>
            <small>04/19/2023, at 02:47 pm</small>
          </div>
        </div>
        <div className="ReplyArea">
          <a href="javascript:void(0);">Reply</a>
          <div className="DiscussionBox">
            <span>TH</span>
            <div className="DiscussionRight">
              <h6>Inspections are delayed</h6>
              <small>04/19/2023, at 02:47 pm</small>
            </div>
          </div>
          <div className="DiscussionBox">
            <span>TH</span>
            <div className="DiscussionRight">
              <h6>Inspections are delayed</h6>
              <small>04/19/2023, at 02:47 pm</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


    </div>
  )
}

export default EditChangeOrderTradeView