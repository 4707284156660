import React from 'react'
import Header from './Header'
import SideNav from './SideNav'

const ConstructionScheduleTradeview = () => {
  return (
    <div>
      <>
      <Header/>
    <SideNav/>
  <div className="WrapperAreaClient">
    <div className="WrapperBoxClient">
      <div className="TodosArea">
        <div className="TodosHeader align-items-start TitleBoxWidth" style={{display:"block"}}>
          <div className='row'>
          <div className="BuilderBox">
            <h4>
              Builder
              <img src="images/refresh.png" />
            </h4>
            <p>Builder 1</p>
          </div>
          <div className="BuilderBox">
            <h4>
              Project
              <img src="images/refresh.png" />
            </h4>
          </div>
          <div className="BuilderBox">
            <h4>
              Job
              <img src="images/refresh.png" />
            </h4>
          </div>
          <div className="TodosButtons mt-0 d-flex">
              <a
                href="javascript:void(0)"
                className=""
                data-toggle="modal"
                data-target="#ConstructionScheduleTasksPendingVendorConfirmation"
              >
                <img src="images/pending_trades_day.png" />
                Pending Confirmation<span>3</span>
              </a>
          </div>
          </div>
        </div>
        <div className="CommonTabs mt-3">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a className="nav-link active" data-toggle="tab" href="#TaskList">
                <span>
                  <img src="images/task_list_icon.png" />
                </span>
                Task List
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#Calendar">
                <span>
                  <img src="images/icon-23.png" />
                </span>
                Calendar
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="tab-content">
        <div className="tab-pane fade show active" id="TaskList">
          <div className="Tableheader">
            <h6>Construction Schedule Tasks</h6>
            <div className="NewActions">
              <a href="javascript:void(0);">
                <img src="images/icon-64.png" />
              </a>
              <a href="javascript:void(0);">
                <img src="images/icon-53.png" />
              </a>
              <a href="javascript:void(0);">
                <img src="images/download.png" />
              </a>
            </div>
          </div>
          <div className="TableList NewTableList BlueHeader TableHeightScroll">
            <table>
              <thead>
                <tr>
                  <th>Job Id</th>
                  <th>WBS</th>
                  <th>Task Name</th>
                  <th>Address</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Duration</th>
                  <th>Notes</th>
                  <th>Status</th>
                  <th>Progress</th>
                  <th>TODO</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr className="BorderCyan">
                  <td>UUnit3-CHI-2B-315</td>
                  <td>1.3.1.1.2</td>
                  <td>Insulation &amp; Vapor Barrier</td>
                  <td>
                    <div>
                      123 Avalon Square
                      <img src="images/location.png" className="ml-2" />
                    </div>
                  </td>
                  <td>05/24/2023</td>
                  <td>05/24/2023</td>
                  <td>01 day</td>
                  <td>
                    <div className="Actions">
                      <a href="javascript:void(0);">
                        <img src="images/notes_icon.png" />
                      </a>
                      <a href="javascript:void(0)">
                        <span className="Count">3</span>
                      </a>
                    </div>
                  </td>
                  <td>Confirmed</td>
                  <td>100%</td>
                  <td />
                  <td>
                    <div className="TableThreeDots">
                      <div className="dropdown">
                        <a href="javascript:void(0);" data-toggle="dropdown">
                          <span>
                            <img src="images/three-dots.png" />
                          </span>
                        </a>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuOffset"
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              <img src="images/view_pos.png" />
                              View P.O.
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <img src="images/calendar_arrow.png" />
                              Trades Day Selection
                            </a>
                          </li>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="BorderCyan">
                  <td>UUnit3-CHI-2B-315</td>
                  <td>1.3.1.1.2</td>
                  <td>Insulation &amp; Vapor Barrier</td>
                  <td>
                    <div>
                      123 Avalon Square
                      <img src="images/location.png" className="ml-2" />
                    </div>
                  </td>
                  <td>05/24/2023</td>
                  <td>05/24/2023</td>
                  <td>01 day</td>
                  <td>
                    <div className="Actions">
                      <a href="javascript:void(0);">
                        <img src="images/notes_icon.png" />
                      </a>
                      <a href="javascript:void(0)">
                        <span className="Count">3</span>
                      </a>
                    </div>
                  </td>
                  <td>Confirmed</td>
                  <td>100%</td>
                  <td />
                  <td>
                    <div className="TableThreeDots">
                      <div className="dropdown">
                        <a href="javascript:void(0);" data-toggle="dropdown">
                          <span>
                            <img src="images/three-dots.png" />
                          </span>
                        </a>
                        <div
                          className="dropdown-menu ChangeOrderDropdown"
                          aria-labelledby="dropdownMenuOffset"
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              <img src="images/view_pos.png" />
                              View P.O.
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              data-toggle="modal"
                              data-target="#NewTaskAssigned"
                            >
                              <img src="images/view_trades_day_confirmation.png" />
                              View Trades Day Confirmation
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item border-bottom" href="#">
                              <img src="images/export-to-calendar.png" />
                              Export to Calender&nbsp;&nbsp;
                              <img src="images/rightarrow2.png" />
                            </a>
                            <ul className="dropdown-menu submenu ConstructionSubmenu">
                              <li>
                                <a href="#" className="dropdown-item">
                                  <img src="images/google-calendar.png" />
                                  Google Calendar
                                </a>
                              </li>
                              <li>
                                <a href="#" className="dropdown-item">
                                  <img src="images/outlook-calendar.png" />
                                  Outlook Calender
                                </a>
                              </li>
                              <li>
                                <a href="#" className="dropdown-item">
                                  <img src="images/email-calendar.png" />
                                  Email Calender
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              data-toggle="modal"
                              data-target="#ReminderModal"
                            >
                              <img src="images/set-reminder.png" />
                              Set Reminder
                            </a>
                          </li>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="BorderRed PendingConfirmation">
                  <td>UUnit3-CHI-2B-315</td>
                  <td>1.3.1.1.2</td>
                  <td>Insulation &amp; Vapor Barrier</td>
                  <td>
                    <div>
                      123 Avalon Square
                      <img src="images/location.png" className="ml-2" />
                    </div>
                  </td>
                  <td>05/24/2023</td>
                  <td>05/24/2023</td>
                  <td>01 day</td>
                  <td>
                    <div className="Actions">
                      <a href="javascript:void(0);">
                        <img src="images/notes_icon.png" />
                      </a>
                      <a href="javascript:void(0)">
                        <span className="Count">3</span>
                      </a>
                    </div>
                  </td>
                  <td>Pending Confirmation</td>
                  <td>0%</td>
                  <td />
                  <td>
                    <div className="TableThreeDots">
                      <div className="dropdown">
                        <a href="javascript:void(0);" data-toggle="dropdown">
                          <span>
                            <img src="images/three-dots.png" />
                          </span>
                        </a>
                        <div
                          className="dropdown-menu ChangeOrderDropdown"
                          aria-labelledby="dropdownMenuOffset"
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              <img src="images/view_pos.png" />
                              View P.O.
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              data-toggle="modal"
                              data-target="#NewTaskAssigned"
                            >
                              <img src="images/view_trades_day_confirmation.png" />
                              View Trades Day Confirmation
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item border-bottom" href="#">
                              <img src="images/export-to-calendar.png" />
                              Export to Calender&nbsp;&nbsp;
                              <img src="images/rightarrow2.png" />
                            </a>
                            <ul className="dropdown-menu submenu ConstructionSubmenu">
                              <li>
                                <a href="#" className="dropdown-item">
                                  <img src="images/google-calendar.png" />
                                  Google Calendar
                                </a>
                              </li>
                              <li>
                                <a href="#" className="dropdown-item">
                                  <img src="images/outlook-calendar.png" />
                                  Outlook Calender
                                </a>
                              </li>
                              <li>
                                <a href="#" className="dropdown-item">
                                  <img src="images/email-calendar.png" />
                                  Email Calender
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              data-toggle="modal"
                              data-target="#ReminderModal"
                            >
                              <img src="images/set-reminder.png" />
                              Set Reminder
                            </a>
                          </li>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="tab-pane fade" id="Calendar">
          <div className="Tableheader">
            <h6>Change orders</h6>
            <div className="NewActions">
              <a href="javascript:void(0);">
                <img src="images/icon-64.png" />
              </a>
              <a href="javascript:void(0);">
                <img src="images/icon-53.png" />
              </a>
              <a href="javascript:void(0);">
                <img src="images/download.png" />
              </a>
            </div>
          </div>
          <div className="CalendarArea">
            <div className="CalendarHeader">
              <div className="CalendarHeaderArea">
                <img src="images/leftarrow.png" />
                <img src="images/rightarrow.png" />
                <button className="Red">Today</button>
                <button className="Black">All jobs</button>
              </div>
              <div className="CalendarHeaderArea">
                <h3>August</h3>
                <img src="images/unfold.png" />
                <h3>2022</h3>
                <img src="images/unfold.png" />
              </div>
              <div className="CalendarHeaderArea">
                <button className="Red">Monthly</button>
                <button className="Black">Weekly</button>
                <button className="Black">Day</button>
              </div>
            </div>
            <div className="CalendarTable">
              <table>
                <tbody>
                  <tr>
                    <th>Sunday</th>
                    <th>Monday</th>
                    <th>Tuesday</th>
                    <th>Wednesday</th>
                    <th>Thursday</th>
                    <th>Friday</th>
                    <th>Saturday</th>
                  </tr>
                  <tr>
                    <td>29</td>
                    <td>30</td>
                    <td>1</td>
                    <td>2</td>
                    <td>3</td>
                    <td>4</td>
                    <td>5</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>7</td>
                    <td>8</td>
                    <td>9</td>
                    <td>10</td>
                    <td>11</td>
                    <td>12</td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td>14</td>
                    <td>15</td>
                    <td>16</td>
                    <td>17</td>
                    <td>18</td>
                    <td>19</td>
                  </tr>
                  <tr>
                    <td>20</td>
                    <td>21</td>
                    <td>22</td>
                    <td>23</td>
                    <td>24</td>
                    <td>25</td>
                    <td>26</td>
                  </tr>
                  <tr>
                    <td>27</td>
                    <td>28</td>
                    <td>29</td>
                    <td>30</td>
                    <td>1</td>
                    <td>2</td>
                    <td>3</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="ModalBox">
    <div
      className="modal fade SmallModal show NewTaskAssigned1Background"
      id="NewTaskAssigned"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content pb-0">
          <a
            href="javascript:void(0);"
            className="CloseModal"
            data-dismiss="modal"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBoxBrand">
              <h4>New Task Assigned</h4>
              <a href="">
                <img src="images/logo-white.png" />
              </a>
            </div>
            <div className="FolderForm">
              <form>
                <div className="NewTaskAssignModalArea">
                  <h6>Vendor</h6>
                  <p>
                    A new task has been assigned to you by Builder. Please
                    <br />
                    confirm execution date
                  </p>
                  <div className="NewTaskAssignModalAreaBox">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <div>
                              Location: <img src="images/location.png" />
                            </div>
                          </td>
                          <td>26th Zalda Cr, Calgary</td>
                        </tr>
                        <tr>
                          <td>Client:</td>
                          <td>Luke Anderson</td>
                        </tr>
                        <tr>
                          <td>Date Suggested:</td>
                          <td>
                            <input type="date" />
                          </td>
                        </tr>
                        <tr>
                          <td>Duration:</td>
                          <td>
                            <input type="text" placeholder="1 day" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-between mt-3 pb-5">
                    <div className="dropdown modalButtonDropdown">
                      <button
                        className="BrandBtnGreen2"
                        type="button"
                        data-toggle="dropdown"
                      >
                        Approve
                        <span>
                          <img src="images/down-arrow-white.png" />
                        </span>
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuOffset"
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            href="javascript:void(0);"
                          >
                            <img src="images/view-po.png" />
                            Print
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="javascript:void(0);"
                          >
                            <img src="images/export-to-calendar.png" />
                            Add to &nbsp;
                            <img src="images/rightarrow2.png" />
                          </a>
                          <ul className="dropdown-menu submenu">
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="dropdown-item"
                              >
                                <img src="images/google-calendar.png" />
                                Google Calendar
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="dropdown-item"
                              >
                                <img src="images/outlook-calendar.png" />
                                Outlook Calender
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="dropdown-item"
                              >
                                <img src="images/yahoo-calendar.png" />
                                Yahoo Calender
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="dropdown-item"
                              >
                                <img src="images/email-calendar.png" />
                                Email Calender
                              </a>
                            </li>
                          </ul>
                        </li>
                      </div>
                    </div>
                    <div className="Button">
                      <button className="Create">Reject</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade LargeModal"
      id="ConstructionScheduleTasksPendingVendorConfirmation"
      data-backdrop="static"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <a
            href="javascript:void(0);"
            className="CloseModal"
            data-dismiss="modal"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Construction Schedule Tasks Pending Vendor Confirmation</h4>
            </div>
            <div className="TableList CommonHeading">
              <table>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Type</th>
                    <th>Data Received</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Construction Schedule Task 1.3.1.1.6 -Taping</td>
                    <td>Construction Schedule</td>
                    <td>04/25/2023</td>
                    <td>
                      <div className="Actions">
                        <a href="javascript:void(0);">
                          <img src="images/calendar_arrow.png" />
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade SmallModal show NewTaskAssigned1Background"
      id="NewTaskAssigned2"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content pb-0">
          <a
            href="javascript:void(0);"
            className="CloseModal"
            data-dismiss="modal"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBoxBrand">
              <h4>New Task Assigned</h4>
              <a href="">
                <img src="images/logo-white.png" />
              </a>
            </div>
            <div className="FolderForm">
              <form>
                <div className="NewTaskAssignModalArea">
                  <h6>Vendor</h6>
                  <p>
                    A new task has been assigned to you by Builder. Please
                    <br />
                    confirm execution date
                  </p>
                  <div className="NewTaskAssignModalAreaBox">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <div>
                              Location: <img src="images/location.png" />
                            </div>
                          </td>
                          <td>26th Zalda Cr, Calgary</td>
                        </tr>
                        <tr>
                          <td>Client:</td>
                          <td>Luke Anderson</td>
                        </tr>
                        <tr>
                          <td>Date Suggested:</td>
                          <td>
                            <input type="date" />
                          </td>
                        </tr>
                        <tr>
                          <td>Duration:</td>
                          <td>
                            <input type="text" placeholder="1 day" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-between mt-3 pb-5">
                    <div className="dropdown modalButtonDropdown">
                      <button
                        className="BrandBtnGreen2"
                        type="button"
                        data-toggle="dropdown"
                      >
                        Approve
                        <span>
                          <img src="images/down-arrow-white.png" />
                        </span>
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuOffset"
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            href="javascript:void(0);"
                          >
                            <img src="images/view-po.png" />
                            Print
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="javascript:void(0);"
                          >
                            <img src="images/export-to-calendar.png" />
                            Add to &nbsp;
                            <img src="images/rightarrow2.png" />
                          </a>
                          <ul className="dropdown-menu submenu">
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="dropdown-item"
                              >
                                <img src="images/google-calendar.png" />
                                Google Calendar
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="dropdown-item"
                              >
                                <img src="images/outlook-calendar.png" />
                                Outlook Calender
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="dropdown-item"
                              >
                                <img src="images/yahoo-calendar.png" />
                                Yahoo Calender
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="dropdown-item"
                              >
                                <img src="images/email-calendar.png" />
                                Email Calender
                              </a>
                            </li>
                          </ul>
                        </li>
                      </div>
                    </div>
                    <div className="Button">
                      <button className="Create">Reject</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade MediumModal show"
      id="TradesDaySelection"
      data-backdrop="static"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-content pb-0">
          <a
            href="javascript:void(0);"
            className="CloseModal"
            data-dismiss="modal"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBoxBrand">
              <h4>Trades Day Selection</h4>
              <a href="">
                <img src="images/logo-white.png" />
              </a>
            </div>
            <div className="FolderForm">
              <h3>Work Order #158</h3>
              <form>
                <div className="row">
                  <div className="col-lg-6">
                    <h3 className="fw-700">Trades Day Confirmation</h3>
                    <div className="ClientsDetails">
                      <p>Client Details</p>
                      <h6 className="ClientName">Luke Anderson</h6>
                      <p className="ClientEmail">
                        linden@ibuildapplications.com
                      </p>
                      <p className="ClientNumber">4034005933</p>
                    </div>
                    <div className="ClientLocation">
                      <p>
                        Location <img src="images/location.png" />
                      </p>
                      <p className="ClientAddress">
                        #Unit 2 312 Weddenburn Rd SE <br /> Chinook Gate,
                        Calgary
                      </p>
                    </div>
                    <div className="ClientsDetails">
                      <p>Vendor Details</p>
                      <h6 className="ClientName">Bigfoot Drywall</h6>
                      <p className="ClientEmail">
                        linden@ibuildapplications.com
                      </p>
                      <p className="ClientNumber">4034005933</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <h3 className="fw-700">Items to be inspected</h3>
                    <div className="ItemAreaBox ItemAreaBoxUnderline mb-5">
                      <h6>#1 - Leaking Sink</h6>
                      <h6>#2 Faucet Problem</h6>
                    </div>
                    <div className="ClientsDetails">
                      <h6 className="ClientName">Technician</h6>
                      <p className="ClientEmail">John Doe</p>
                      <p className="ClientNumber">Contact: (403)555-555</p>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="DatesBoxAreaMain">
                      <h3>Dates</h3>
                      <div className="DatesBoxArea">
                        <h6>#1 - Leaking Sink</h6>
                        <p>02:30pm - 04:30pm. Wednesday, May 17, 2023 </p>
                        <p className="mb-3">Mountain Time - US &amp; Canada</p>
                        <div className="AddToSocialArea">
                          <h6>Add to</h6>
                          <a href="javascript:void(0);">
                            <img src="images/google-calendar.png" /> Calendar
                          </a>
                          <a href="javascript:void(0);">
                            <img src="images/outlook-calendar.png" />
                            Outlook Calender
                          </a>
                          <a href="javascript:void(0);">
                            <img src="images/yahoo-calendar.png" />
                            Yahoo Calender
                          </a>
                        </div>
                      </div>
                      <div className="DatesBoxArea">
                        <h6>#2 - Faucet Problem</h6>
                        <p>02:30pm - 04:30pm. Wednesday, May 17, 2023 </p>
                        <p className="mb-3">Mountain Time - US &amp; Canada</p>
                        <div className="AddToSocialArea">
                          <h6>Add to</h6>
                          <a href="javascript:void(0);">
                            <img src="images/google-calendar.png" /> Calendar
                          </a>
                          <a href="javascript:void(0);">
                            <img src="images/outlook-calendar.png" />
                            Outlook Calender
                          </a>
                          <a href="javascript:void(0);">
                            <img src="images/yahoo-calendar.png" />
                            Yahoo Calender
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

    </div>
  )
}

export default ConstructionScheduleTradeview