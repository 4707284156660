import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCommonGarageStep2 } from "../../../redux/action/proformaAction";

const Quantities = ({
  handlecommonGarageQuantityChange,
  handleShowComponents,
}) => {
  const dispatch = useDispatch([]);
  const [commonGarageQuantity, setCommonGarageQuantity] = useState([]);
  useEffect(() => {
    dispatch(getCommonGarageStep2()).then((res) => {
      console.log({ res });
      setCommonGarageQuantity(res?.data?.data);
    });
  }, []);
  return (
    <div>
      <div className="ModalBigheading pl-4 mb-3 d-flex justify-content-between align-items-center">
        <h3 className="mb-0">
          <span>Common Garage Quantities</span>
        </h3>
        <h6>
          <img src="images/book-search.png" />
          Customize Base Costs
        </h6>
      </div>
      <div className="ProformaAccordion">
        <div className="accordion" id="accordionExample">
          {commonGarageQuantity?.length > 0 &&
            commonGarageQuantity?.map((item, ind) => {
              return (
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left"
                        type="button"
                        data-toggle="collapse"
                        data-target="#CommonBuildingCosts"
                        aria-expanded="true"
                        aria-controls="CommonBuildingCosts"
                      >
                        {item.name}
                      </button>
                    </h2>
                    <a href="javascript:void(0);">
                      <img src="images/book-search.png" />
                    </a>
                  </div>
                  <div
                    id="CommonBuildingCosts"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body py-0">
                      <div className="EnterProjectTable GreyTd">
                        <table>
                          <thead>
                            <tr>
                              <th>Code Code #</th>
                              <th>Description</th>
                              <th>Unit of measure</th>
                              <th>Irrigation Garage</th>
                              <th>Maintenance Garage</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.ProformaBaseCosts?.length > 0 &&
                              item.ProformaBaseCosts?.map((datas, i) => {
                                return (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{datas.CostCode?.name}</td>
                                    <td>{datas.CostCode?.number}</td>
                                    <td>
                                      {/* <div className="CommonSelectBox width-250">
                                    <select>
                                      <option selected="">Per Hour</option>
                                    </select>
                                    <span>
                                      <img src="images/down.png" />
                                    </span>
                                  </div> */}
                                    </td>
                                    {datas?.ProformaBlockQuantities?.length >
                                      0 &&
                                      datas?.ProformaBlockQuantities?.map(
                                        (block, blocId) => {
                                          return (
                                            <td key={blocId}>
                                              <div className="form-group">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Insert Quality"
                                                  name="quantity"
                                                  value={block.quantity}
                                                  onChange={(e) =>
                                                    handlecommonGarageQuantityChange(
                                                      e,
                                                      ind,
                                                      i,
                                                      blocId
                                                    )
                                                  }
                                                />
                                              </div>
                                            </td>
                                          );
                                        }
                                      )}
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="Button d-flex  justify-content-between align-items-center px-5">
        <button
          className="Outline border text-success border-success"
          onClick={() => handleShowComponents("garageInput")}
        >
          Cancel
        </button>
        <button
          className="Create bg-success border border-success"
          onClick={() => handleShowComponents("garageSummeryPreview")}
        >
          Save Draft
        </button>
      </div>
    </div>
  );
};

export default Quantities;
