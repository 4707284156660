import React, { useState,useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { getCitiesList, getStateList } from "../../redux/action/authAction";
import { useDispatch, useSelector } from "react-redux";


const initialState = {
  buildingPermit: "",
  subdividision: "",
  specificationDoc: "",
  taxProfile: "",
  baseModel: "",
  homeType: "",
  defaultCurrency: "",
  costScheme: "",
  timeZone: "",
  accountSoftware: false,
  underParkade: false,
  jobAddress: "",
  lot: "",
  block: "",
  phase: "",
  city: "",
  zipCode: "",
  country: "",
  province: "",
  numOfBlocks: 0,
  blocks: [],
  numOfBuildings: 0,
  buildings: [],
  errorMsg: {},
};

const ProjectAsEntityStep2 = ({
  iState,
  UpdateState,
    ProjectAsEntityStep2Modal,
    SetProjectAsEntityStep1Modal,
    SetProjectAsEntityStep2Modal,
    SetProjectAsEntityStep3Modal,
    SetProjectAsEntityStep4Modal,
    SetProjectAsEntityStep5Modal,
    SetProjectAsEntityStep6Modal,
    SetProjectAsEntityStep7Modal,
    SetProjectAsEntityStep8Modal,
    setShow,
  
}) => {
  const handleProjectAsEntityStep1ModalClose = () => {
    setShow(true);
    SetProjectAsEntityStep1Modal(false);
  };
  const handleProjectAsEntityStep1ModalShow = () => {
    SetProjectAsEntityStep1Modal(true);
  };


  const dispatch = useDispatch()
  const { countryData, cityData, stateData, timeZoneData } = useSelector(
    (state) => state.authReducer
  );


  const { projectAddress, country, city, province, zipCode, errorMsg } = iState;



 
  const handleValidation = () => {
    let formIsValid = true;
    let errorMsg = {};
    if (!projectAddress) {
      formIsValid = false;
      errorMsg.projectAddress = "* Please enter project address.";
    }

    if (!city) {
      formIsValid = false;
      errorMsg.city = "* Please enter city.";
    }

    if (!projectAddress) {
      formIsValid = false;
      errorMsg.projectAddress = "* Please enter project address.";
    }

    if (!zipCode) {
      formIsValid = false;
      errorMsg.zipCode = "* Please enter zip code.";
    }

    if (!country) {
      formIsValid = false;
      errorMsg.country = "* Please select country.";
    }

    if (!province) {
      formIsValid = false;
      errorMsg.province = "* Please select province.";
    }

    UpdateState({ ...iState, errorMsg: errorMsg });



    UpdateState({ ...iState, errorMsg: errorMsg });

    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    UpdateState({
      ...iState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();

    if (formIsValid) {
      SetProjectAsEntityStep2Modal(false);
      SetProjectAsEntityStep3Modal(true);
    }
  };

  const handleCheck = (e) => {
    UpdateState({ ...iState, accountSoftware: e.target.value });
  };


  useEffect(() => {
    if(country)
    dispatch(
      getStateList(
        countryData?.countries?.find((x) => x.name == country)?.isoCode
      )
    );
  }, [country,countryData?.countries,dispatch]);
  useEffect(() => {
    dispatch(
      getCitiesList(
        countryData?.countries?.find((x) => x.name == country)?.isoCode,
        stateData?.states?.find((x) => x.name == province)?.isoCode
      )
    );
  }, [province]);


  return (
    <>
      <Modal show={ProjectAsEntityStep2Modal} className="ModalBox LargeModal BgClass">
        <div >
          <a
            onClick={handleProjectAsEntityStep1ModalClose}
            class="CloseModal"
            data-toggle="modal"
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Create New Project as an Entity</h4>
            </div>
            <div class="StepBox">
              <ul>
                  <li class="active">
                      <span class="Icon"  onClick={()=> {SetProjectAsEntityStep1Modal(true) 
                      SetProjectAsEntityStep2Modal(false)}}>
                          <img src={require("../../assets/images/step_1.png")} />
                      </span>
                      <span class="Text">Details</span>
                  </li>
                  <li className="active">
                    <span   class="Icon FolderPermissionId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityAddress" data-dismiss="modal">
                        <img src={require("../../assets/images/step_2.png")} />
                    </span>
                    <span class="Text">Address</span>
                </li>
                  <li>
                    <span    onClick={() => {
                      SetProjectAsEntityStep3Modal(true);
                      SetProjectAsEntityStep2Modal(false);
                    }}  class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityType" data-dismiss="modal">
                        <img src={require("../../assets/images/step_8.png")} />
                    </span>
                    <span class="Text">Type</span>
                </li>
                  <li >
                      <span onClick={() => {
                      SetProjectAsEntityStep4Modal(true);
                      SetProjectAsEntityStep2Modal(false);
                    }} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntitySubscription" data-dismiss="modal">
                          <img  src={require("../../assets/images/subscription_step.png")}/>
                      </span>
                      <span class="Text">Subscription</span>
                  </li>
                  <li >
                      <span onClick={() => {
                      SetProjectAsEntityStep5Modal(true);
                      SetProjectAsEntityStep2Modal(false);
                    }} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityTax" data-dismiss="modal">
                          <img src={require("../../assets/images/step_3.png")} />
                      </span>
                      <span class="Text">Accounting <br /> &amp; Tax</span>
                  </li> 
                  <li >
                    <span onClick={() => {
                      SetProjectAsEntityStep6Modal(true);
                      SetProjectAsEntityStep2Modal(false);
                    }} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityNumber" data-dismiss="modal">
                        <img src={require("../../assets/images/step_5.png")} />
                    </span>
                    <span class="Text">Job Number</span>
                </li> 
                <li>
                  <span onClick={() => {
                      SetProjectAsEntityStep7Modal(true);
                      SetProjectAsEntityStep2Modal(false);
                    }} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityInitial" data-dismiss="modal">
                      <img  src={require("../../assets/images/initial_settings.png")} />
                  </span>
                  <span class="Text">Initial <br />
                    Settings</span>
              </li> 
              <li>
                <span onClick={() => {
                      SetProjectAsEntityStep8Modal(true);
                      SetProjectAsEntityStep2Modal(false);
                    }} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityConfirmation" data-dismiss="modal">
                    <img src={require("../../assets/images/step_7.png")} />
                </span>
                <span class="Text">Confirmation</span>
            </li> 
              </ul>
            </div>
            <div class="FolderForm">
              
            <form>
                <div class="FolderFormCreateArea">
                  <div class="FieldsMandatory">
                    <h6>Fields with <span class="required">*</span> are mandatory</h6>
                  </div>
                  <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <h6>
                        Project Address<span class="required">*</span>
                      </h6>
                      <input
                        type="text"
                        onChange={handleChange}
                        value={projectAddress}
                        name="projectAddress"
                        class="form-control"
                        placeholder="Insert full address"
                      />

                      {errorMsg.projectAddress && !projectAddress && (
                        <p style={{ color: "red" }}>
                          {errorMsg.projectAddress}
                        </p>
                      )}
                    </div>
                  
                    <div class="form-group">
                      <h6>
                        Zip/ Postal Codes<span class="required">*</span>
                      </h6>
                      <input
                        type="text"
                        value={zipCode}
                        onChange={handleChange}
                        name="zipCode"
                        class="form-control"
                        placeholder="Insert zip code"
                      />

                      {errorMsg.zipCode && !zipCode && (
                        <p style={{ color: "red" }}>{errorMsg.zipCode}</p>
                      )}
                    </div>
                    <div class="form-group">
                      <h6>
                        Country <span class="required">*</span>
                      </h6>
                      <div class="CommonSelectBox">
                      <select
                                name="country"
                                value={country}
                                onChange={handleChange}
                              >
                                <option>Select Country</option>
                                {countryData?.countries ? (
                                  <>
                                    {countryData?.countries?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>

                      {errorMsg.country && !country && (
                        <p style={{ color: "red" }}>{errorMsg.country}</p>
                      )}
                    </div>
                    <div class="form-group">
                      <h6>
                        State/Province <span class="required">*</span>
                      </h6>
                      <div class="CommonSelectBox">
                            <select
                                name="province"
                                value={province}
                                onChange={handleChange}
                              >
                                <option>Select Province</option>
                                {stateData?.states ? (
                                  <>
                                    {stateData?.states?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>

                      {errorMsg.province && !province && (
                        <p style={{ color: "red" }}>{errorMsg.province}</p>
                      )}
                    </div>
                    <div class="form-group">
                      <h6>
                        City<span class="required">*</span>
                      </h6>
                      <div class="CommonSelectBox">

                      <select
                                name="city"
                                value={city}
                                onChange={handleChange}
                              >
                                <option>Select City</option>
                                {cityData?.cities ? (
                                  <>
                                    {cityData?.cities?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                              <span>
                          <img src="images/down.png" />
                        </span>
                              </div>

                      {errorMsg.city && !city && (
                        <p style={{ color: "red" }}>{errorMsg.city}</p>
                      )}
                    </div>
                  </div>
                </div>
                </div>
                <div class="Button d-flex justify-content-between align-items-center px-5">
                <button
                    class="Outline"
                    data-dismiss="modal"
                    onClick={() => {
                      SetProjectAsEntityStep2Modal(false);
                      SetProjectAsEntityStep1Modal(true);
                    }}
                  >
                    Back
                  </button>
                  <button
                    class="Create FolderPermissionId"
                    data-toggle="modal"
                    onClick={handleSubmit}
                  >
                    Next
                  </button>
                </div>
              </form>
              
            </div>

          </div>
        </div>
      </Modal>

   
      
    </>
  );
};

export default ProjectAsEntityStep2;
