import axios from "axios";
import { vendorActionTypes } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config/Config";
import { multiPartData } from "../../utils/uploadFile";

const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

export const VendorListInitate = () => ({
  type: vendorActionTypes.VENDOR_LIST_INITIATE,
});

export const VendorListSuccess = (data) => ({
  type: vendorActionTypes.VENDOR_LIST_SUCCESS,
  payload: data,
});

export const VendorListFailure = (data) => ({
  type: vendorActionTypes.VENDOR_LIST_FAILURE,
  payload: data,
});

export function VendorListAction(payload) {
  const token = isLoggedIn("adminData1");
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  return (dispatch) => {
    dispatch(VendorListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/vendors?q=${payload ? payload :""}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(VendorListSuccess(data.data));
            resolve(data);
          } else {
            dispatch(VendorListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(VendorListFailure(err));
          reject(err);
        });
    });
  };
}

export const addAddressInitate = () => ({
  type: vendorActionTypes.ADD_ADDRESS_INITIATE,
});

export const addAddressSuccess = (data) => ({
  type: vendorActionTypes.ADD_ADDRESS_SUCCESS,
  payload: data,
});

export const addAddressFailure = (data) => ({
  type: vendorActionTypes.ADD_ADDRESS_FAILURE,
  payload: data,
});

export function addAddressAction(payload, id) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(addAddressInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/vendors/${id}/addresses`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 201) {
            dispatch(addAddressSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addAddressFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addAddressFailure(err));
          reject(err);
        });
    });
  };
}

export const AddressListInitate = () => ({
  type: vendorActionTypes.ADDRESS_LIST_INITIATE,
});

export const AddressListSuccess = (data) => ({
  type: vendorActionTypes.ADDRESS_LIST_SUCCESS,
  payload: data,
});

export const AddressListFailure = (data) => ({
  type: vendorActionTypes.ADDRESS_LIST_FAILURE,
  payload: data,
});

export function AddressListAction(payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(AddressListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/vendors/${payload}/addresses`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(AddressListSuccess(data.data));
            resolve(data);
          } else {
            dispatch(AddressListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(AddressListFailure(err));
          reject(err);
        });
    });
  };
}

export const addVendorInitate = () => ({
  type: vendorActionTypes.ADD_VENDOR_INITIATE,
});

export const addVendorSuccess = (data) => ({
  type: vendorActionTypes.ADD_VENDOR_SUCCESS,
  payload: data,
});

export const addVendorFailure = (data) => ({
  type: vendorActionTypes.ADD_VENDOR_FAILURE,
  payload: data,
});

export function addVendorAction(payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(addVendorInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/vendors`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if ((data && data.status == 201) || 200) {
            dispatch(addVendorSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addVendorFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addVendorFailure(err));
          reject(err);
        });
    });
  };
}






export const addBulkVendorInitiate = () => ({
  type: vendorActionTypes.BULK_UPLOAD_VENDOR_INITIATE,
});

export const addBulkVendorSuccess = (data) => ({
  type: vendorActionTypes.BULK_UPLOAD_VENDOR_SUCCESS,
  payload: data,
});

export const addBulkVendorFailure = (error) => ({
  type: vendorActionTypes.BULK_UPLOAD_VENDOR_FAILURE,
  payload: error,
});

export function addBulkVendorAction(payload) {
  const token = isLoggedIn("adminData1"); // Make sure isLoggedIn is defined
  const updatedData = multiPartData({ file: payload.file, ...payload }); // Modify this line
  return (dispatch) => {
    dispatch(addBulkVendorInitiate(payload));
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/bulk-vendors`, updatedData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response.data; 
          if (data && (response.status === 201 || response.status === 200)) {
            dispatch(addBulkVendorSuccess(data));
            resolve(data);
          } else {
            dispatch(addBulkVendorFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addBulkVendorFailure(err));
          reject(err);
        });
    });
  };
}


export const BulkUploadTemplateInitate = () => ({
  type: vendorActionTypes.BULK_UPLOAD_TEMPLATE_INITIATE,
});

export const BulkUploadTemplateSuccess = (data) => ({
  type: vendorActionTypes.BULK_UPLOAD_TEMPLATE_SUCCESS,
  payload: data,
});

export const BulkUploadTemplateFailure = (data) => ({
  type: vendorActionTypes.BULK_UPLOAD_TEMPLATE_FAILURE,
  payload: data,
});

export function BulkUploadTemplateAction(payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(BulkUploadTemplateInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}/vendor-bulk-upload-temp`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if (data && data.status == 200 || 201) {
            dispatch(BulkUploadTemplateSuccess(data.data));
            resolve(data);
          } else {
            dispatch(BulkUploadTemplateFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(BulkUploadTemplateFailure(err));
          reject(err);
        });
    });
  };
}






export const updateVendorInitate = () => ({
  type: vendorActionTypes.UPDATE_VENDOR_INITIATE,
});

export const updateVendorSuccess = (data) => ({
  type: vendorActionTypes.UPDATE_VENDOR_SUCCESS,
  payload: data,
});

export const updateVendorFailure = (data) => ({
  type: vendorActionTypes.UPDATE_VENDOR_SUCCESS,
  payload: data,
});

export function updateVendorAction(payload, params) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(updateVendorInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/vendors/${params}`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(updateVendorSuccess(data));
            resolve(data);
          } else {
            dispatch(updateVendorFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateVendorFailure(err));
          reject(err);
        });
    });
  };
}

export const removeVendorInitate = () => ({
  type: vendorActionTypes.DELETE_VENDOR_INITIATE,
});

export const removeVendorSuccess = (data) => ({
  type: vendorActionTypes.DELETE_VENDOR_SUCCESS,
  payload: data,
});

export const removeVendorFailure = (data) => ({
  type: vendorActionTypes.DELETE_VENDOR_FAILURE,
  payload: data,
});

export function removeVendor(payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(removeVendorInitate());
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/vendors/${
            payload ? payload : ""
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 200) || 201) {
            dispatch(removeVendorSuccess(data));
            resolve(data);
          } else {
            dispatch(removeVendorFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(removeVendorFailure(err));
          reject(err);
        });
    });
  };
}

export const VendorTypesInitate = () => ({
  type: vendorActionTypes.VENDOR_TYPES_INITIATE,
});

export const VendorTypesSuccess = (data) => ({
  type: vendorActionTypes.VENDOR_TYPES_SUCCESS,
  payload: data,
});

export const VendorTypesFailure = (data) => ({
  type: vendorActionTypes.VENDOR_TYPES_FAILURE,
  payload: data,
});

export function VendorTypesAction(payload) {
  const token = isLoggedIn("adminData1");
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  return (dispatch) => {
    dispatch(VendorTypesInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/vendor-types`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(VendorTypesSuccess(data.data));
            resolve(data);
          } else {
            dispatch(VendorTypesFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(VendorTypesFailure(err));
          reject(err);
        });
    });
  };
}

export const vendorDetailsInitate = () => ({
  type: vendorActionTypes.VENDOR_DETAILS_INITIATE,
});

export const vendorDetailsSuccess = (data) => ({
  type: vendorActionTypes.VENDOR_DETAILS_SUCCESS,
  payload: data,
});

export const vendorDetailsFailure = (data) => ({
  type: vendorActionTypes.VENDOR_DETAILS_FAILURE,
  payload: data,
});

export function vendorDetailsAction(payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(vendorDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/vendors/${
            payload ? payload : ""
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(vendorDetailsSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(vendorDetailsFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(vendorDetailsFailure(err));
          reject(err);
        });
    });
  };
}

export const VendorSearchInitate = () => ({
  type: vendorActionTypes.VENDOR_SEARCH_INITIATE,
});

export const VendorSearchSuccess = (data) => ({
  type: vendorActionTypes.VENDOR_SEARCH_SUCCESS,
  payload: data,
});

export const VendorSearchFailure = (data) => ({
  type: vendorActionTypes.VENDOR_SEARCH_FAILURE,
  payload: data,
});

export function VendorSearchAction(  payload, vendorType) {
  const token = isLoggedIn("adminData1");
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  return (dispatch) => {
    dispatch(VendorSearchInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}vendors-search?q=${payload ? payload : ""}&vendorType=${vendorType ? vendorType:""}&CompanyId=${iBuildLocalData?.user?.CompanyId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if (data && data.status == 200 ) {
            dispatch(VendorSearchSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(VendorSearchFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          
            dispatch(VendorSearchFailure(err));
            reject(err);
          
         
       
        });
    });
  };
}

export const addContactInitate = () => ({
  type: vendorActionTypes.ADD_CONTACT_INITIATE,
});

export const addContactSuccess = (data) => ({
  type: vendorActionTypes.ADD_CONTACT_SUCCESS,
  payload: data,
});

export const addContactFailure = (data) => ({
  type: vendorActionTypes.ADD_CONTACT_FAILURE,
  payload: data,
});

export function addContactAction(payload, id) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(addContactInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/vendors/${id}/contacts`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 201) {
            dispatch(addContactSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addContactFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addContactFailure(err));
          reject(err);
        });
    });
  };
}

export const updateContactInitate = () => ({
  type: vendorActionTypes.UPDATE_CONTACT_INITIATE,
});

export const updateContactSuccess = (data) => ({
  type: vendorActionTypes.UPDATE_CONTACT_SUCCESS,
  payload: data,
});

export const updateContactFailure = (data) => ({
  type: vendorActionTypes.UPDATE_CONTACT_FAILURE,
  payload: data,
});

export function updateContactAction(payload,  editId, id) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(updateContactInitate());
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/vendors/${
            editId ? editId : ""
          }/contacts/${id?id:""}`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 201 || data.status == 200) {
            dispatch(updateContactSuccess(data.data));
            resolve(data);
          } else {
            dispatch(updateContactFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateContactFailure(err));
          reject(err);
        });
    });
  };
}

export const ContactListInitate = () => ({
  type: vendorActionTypes.CONTACT_LIST_INITIATE,
});

export const ContactListSuccess = (data) => ({
  type: vendorActionTypes.CONTACT_LIST_SUCCESS,
  payload: data,
});

export const ContactListFailure = (data) => ({
  type: vendorActionTypes.CONTACT_LIST_FAILURE,
  payload: data,
});

export function ContactListAction(payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(ContactListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/vendors/${payload}/contacts`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(ContactListSuccess(data.data));
            resolve(data);
          } else {
            dispatch(ContactListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(ContactListFailure(err));
          reject(err);
        });
    });
  };
}


export const contactDetailsInitate = () => ({
  type: vendorActionTypes.CONTACT_DETAILS_INITIATE,
});

export const contactDetailsSuccess = (data) => ({
  type: vendorActionTypes.CONTACT_DETAILS_SUCCESS,
  payload: data,
});

export const contactDetailsFailure = (data) => ({
  type: vendorActionTypes.CONTACT_DETAILS_FAILURE,
  payload: data,
});

export function contactDetailsAction(payload, id) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(contactDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/vendors/${
            payload ? payload : ""
          }/contacts/${id ? id : ""}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(contactDetailsSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(contactDetailsFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(contactDetailsFailure(err));
          reject(err);
        });
    });
  };
}


export const prefferedCostDetailsInitate = () => ({
  type: vendorActionTypes.CONTACT_DETAILS_INITIATE,
});

export const prefferedCostDetailsSuccess = (data) => ({
  type: vendorActionTypes.CONTACT_DETAILS_SUCCESS,
  payload: data,
});

export const prefferedCostDetailsFailure = (data) => ({
  type: vendorActionTypes.CONTACT_DETAILS_FAILURE,
  payload: data,
});

export function prefferedCostDetailsAction(payload, id) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(prefferedCostDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/vendors/${
            payload ? payload : ""
          }/preferred/${id ? id : ""}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(prefferedCostDetailsSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(prefferedCostDetailsFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(prefferedCostDetailsFailure(err));
          reject(err);
        });
    });
  };
}



export const updatePrefferedCostInitate = () => ({
  type: vendorActionTypes.UPDATE_PREFFERED_COST_INITIATE,
});

export const updatePrefferedCostSuccess = (data) => ({
  type: vendorActionTypes.UPDATE_PREFFERED_COST_SUCCESS,
  payload: data,
});

export const updatePrefferedCostFailure = (data) => ({
  type: vendorActionTypes.UPDATE_PREFFERED_COST_FAILURE,
  payload: data,
});

export function updatePrefferedCostAction(payload,  editId, id) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(updatePrefferedCostInitate());
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/vendors/${
            editId ? editId : ""
          }/preferred/${id?id:""}`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 201 || 200) {
            dispatch(updatePrefferedCostSuccess(data.data));
            resolve(data);
          } else {
            dispatch(updatePrefferedCostFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updatePrefferedCostFailure(err));
          reject(err);
        });
    });
  };
}

export const removeContactInitate = () => ({
  type: vendorActionTypes.DELETE_CONTACT_INITIATE,
});

export const removeContactSuccess = (data) => ({
  type: vendorActionTypes.DELETE_CONTACT_SUCCESS,
  payload: data,
});

export const removeContactFailure = (data) => ({
  type: vendorActionTypes.DELETE_CONTACT_FAILURE,
  payload: data,
});

export function removeContactAction(payload, id) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(removeContactInitate());
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/vendors/${
            payload ? payload : ""
          }/contacts/${id ? id : ""}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 200) || 201) {
            dispatch(removeContactSuccess(data));
            resolve(data);
          } else {
            dispatch(removeContactFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(removeContactFailure(err));
          reject(err);
        });
    });
  };
}

export const addPrefferedCostCodeInitate = () => ({
  type: vendorActionTypes.ADD_PREFFERED_COST_INITIATE,
});

export const addPrefferedCostCodeSuccess = (data) => ({
  type: vendorActionTypes.ADD_PREFFERED_COST_SUCCESS,
  payload: data,
});

export const addPrefferedCostCodeFailure = (data) => ({
  type: vendorActionTypes.ADD_PREFFERED_COST_FAILURE,
  payload: data,
});

export function addPrefferedCostCodeAction(id, payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(addPrefferedCostCodeInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/vendors/${id}/preferred`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if ((data && data.status == 201) || 200) {
            dispatch(addPrefferedCostCodeSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addPrefferedCostCodeFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addPrefferedCostCodeFailure(err));
          reject(err);
        });
    });
  };
}

export const PrefferedCostListInitate = () => ({
  type: vendorActionTypes.PREFFERED_COST_LIST_INITIATE,
});

export const PrefferedCostListSuccess = (data) => ({
  type: vendorActionTypes.PREFFERED_COST_LIST_SUCCESS,
  payload: data,
});

export const PrefferedCostListFailure = (data) => ({
  type: vendorActionTypes.PREFFERED_COST_LIST_FAILURE,
  payload: data,
});

export function PrefferedCostListAction(payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(PrefferedCostListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/vendors/${payload}/preferred`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(PrefferedCostListSuccess(data.data));
            resolve(data);
          } else {
            dispatch(PrefferedCostListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(PrefferedCostListFailure(err));
          reject(err);
        });
    });
  };
}



export const removePrefferedCostInitate = () => ({
  type: vendorActionTypes.DELETE_PREFFERED_COST_INITIATE,
});

export const removePrefferedCostSuccess = (data) => ({
  type: vendorActionTypes.DELETE_PREFFERED_COST_SUCCESS,
  payload: data,
});

export const removePrefferedCostFailure = (data) => ({
  type: vendorActionTypes.DELETE_PREFFERED_COST_FAILURE,
  payload: data,
});

export function removePrefferedCostAction(payload, id) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(removePrefferedCostInitate());
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/vendors/${
            payload ? payload : ""
          }/preferred/${id ? id : ""}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 200 || 201) ) {
            dispatch(removePrefferedCostSuccess(data));
            resolve(data);
          } else {
            dispatch(removePrefferedCostFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(removePrefferedCostFailure(err));
          reject(err);
        });
    });
  };
}



export const setPrefferedCostCodeInitate = () => ({
  type: vendorActionTypes.SET_VENDOR_PREFERRED_INITIATE,
});

export const setPrefferedCostCodeSuccess = (data) => ({
  type: vendorActionTypes.SET_VENDOR_PREFERRED_SUCCESS,
  payload: data,
});

export const setPrefferedCostCodeFailure = (data) => ({
  type: vendorActionTypes.SET_VENDOR_PREFERRED_FAILURE,
  payload: data,
});

export function setPrefferedCostCodeAction(payload, id) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(setPrefferedCostCodeInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/vendors/${id}/bulk-preferred`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if ((data && data.status == 201|| 200) ) {
            dispatch(setPrefferedCostCodeSuccess(data.data));
            resolve(data);
          } else {
            dispatch(setPrefferedCostCodeFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(setPrefferedCostCodeFailure(err));
          reject(err);
        });
    });
  };
}
















































































