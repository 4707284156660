import React, { useEffect, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { Link } from "react-router-dom";
import {
  archiveDeleteJob,
  getArchiveEventList,
  getArchiveList,
  getArchiveRestoreJob,
} from "../redux/action/archiveAction";
import {
  deleteArchiveJobs,
  getAllProjects,
} from "../redux/action/modelsAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import moment from "moment";
import { toast } from "react-toastify";
import {
  getLandDevelopmentProjectList,
  getLandPhaseList,
  getMultiBlocksList,
  getMultiFamilyProjectList,
} from "../redux/action/homeAction";
import { showInWarranty } from "../redux/action/warrantyAction";

const initialState = {
  JobDeleteModal: false,
  resdentalId: -1,
  jobId: "",
  multiJobUnitOpenIndex: -1,
  landJobUnitOpenIndex: -1,
  landId: -1,
};

const ArchiveLandDevelopment = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const {
    JobDeleteModal,
    resdentalId,
    jobId,
    multiJobUnitOpenIndex,
    landJobUnitOpenIndex,
    landId,
  } = iState;
  const { archiveList, archiveLoader, archiveEventList, archiveEventLoader } =
    useSelector((state) => state.archiveReducer);

  const {
    multiblocksList,
    multiFamilyProjectList,
    landDevelopmentProjectList,
    landPhaseList,
  } = useSelector((state) => state.homeReducer);

  useEffect(() => {
    dispatch(getLandDevelopmentProjectList());
    dispatch(getArchiveEventList("LandDevelopment"));
  }, []);

  const handleLandPhaseListByProject = (Id, Index) => {
    dispatch(getLandPhaseList(Id));
    updateState((prev) => ({
      ...prev,
      landId: prev.landId == Index ? -1 : Index,
    }));
  };

  const handleRestoreJob = (id) => {
    const dataa = { jobId: id, jobType: "LandDevelopmentJob" };
    dispatch(getArchiveRestoreJob(dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(getArchiveList("LandDevelopment"));
        dispatch(getLandDevelopmentProjectList());
        toast.success("Restore Job Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          id: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleJobDeleteShow = (id) => {
    updateState({ ...iState, JobDeleteModal: true, jobId: id });
  };

  const handleJobDeleteClose = () => {
    updateState({ ...iState, JobDeleteModal: false, jobId: "" });
  };

  const handleJobDelete = async () => {
    try {
      const data = { jobId, jobType: "LandDevelopmentJob" };
      const response = await dispatch(archiveDeleteJob(data));

      if (response.status === 200) {
        await dispatch(getArchiveList("LandDevelopment"));
        dispatch(getLandDevelopmentProjectList());
        toast.success("Delete Job Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState((prevState) => ({
          ...prevState,
          jobId: "",
          JobDeleteModal: false,
        }));
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState((prevState) => ({
          ...prevState,
          jobId: "",
          JobDeleteModal: true,
        }));
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      updateState((prevState) => ({
        ...prevState,
        jobId: "",
        JobDeleteModal: true,
      }));
    }
  };

  const handleArchiveJobs = (id, type) => {
    let dataa = { jobId: id, jobType: type };
    dispatch(deleteArchiveJobs(dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(getLandDevelopmentProjectList());
        toast.success("Deleted Archive Job", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleShowInWarranty = (id, type) => {
    let dataa = { jobId: id, jobType: type };
    dispatch(showInWarranty(dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(getLandDevelopmentProjectList());
        toast.success("Move To Warranty", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  return (
    <>
      <Header />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Archive Land Development</h4>
            <a href="javascript:void(0);">
              <img src="images/Icon-4.png" />
            </a>
          </div>
          <div className="mb-5" style={{ width: "60%" }}>
            <div className="Tableheader">
              <h6>
                Latest Activity <img src="images/create_category.png" />
              </h6>
              <div className="NewActions">
                <a href="jacascript:void(0);">
                  <img src="images/icon-53.png" />
                </a>
                <a href="jacascript:void(0);">
                  <img src="images/download.png" />
                </a>
                {/* <a href="jacascript:void(0);">
              <img src={setting} />
            </a> */}
              </div>
            </div>
            <div className="TableList TableListHeader NewTableList TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Job Id</th>
                    <th>Modified by</th>
                    <th>Date</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {!archiveEventLoader ? (
                    archiveEventList?.details?.length > 0 ? (
                      archiveEventList?.details?.map((item, i) => (
                        <tr key={i}>
                          <td>{item.id}</td>
                          <td>{item.modifiedBy || "N/A"}</td>
                          <td>{moment(item.createdAt).format("YYYY-MM-DD")}</td>
                          <td>{item.activity}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4">No data found</td>
                      </tr>
                    )
                  ) : (
                    <Loader />
                  )}
                </tbody>
              </table>
            </div>
            <div className="PaginationArea">
              <h6>Showing 1 to 10 of 27 entries</h6>
              <h6>
                <img src="images/leftarrow.png" /> 01{" "}
                <img src="images/rightarrow.png" /> Out 10
              </h6>
            </div>
          </div>
          <div className="Tableheader">
            <h6>Archived Jobs</h6>
            <div className="NewActions">
              <a href="jacascript:void(0);">
                <img src="images/icon-53.png" />
              </a>
              <a href="jacascript:void(0);">
                <img src="images/download.png" />
              </a>
              {/* <a href="jacascript:void(0);">
            <img src={setting}/>
          </a> */}
            </div>
          </div>
          <div className="TableList TableListHeader NewTableList TableHeightScroll">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Job Number</th>
                  <th>Build Type</th>
                  <th>Address</th>
                  <th>Lot</th>
                  <th>Block</th>
                  <th>Phase</th>
                  <th>Plan</th>
                  <th>Sub Division</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {landDevelopmentProjectList?.projects?.length > 0 ? (
                  landDevelopmentProjectList?.projects?.map((item, i) => {
                    return (
                      <>
                        <tr key={i}>
                          <td>
                            <div className="d-flex">
                              {item.id}
                              <div className="dropdown">
                                <span
                                  className="Dots"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img src="images/dots.png" />
                                </span>
                                <div className="dropdown-menu">
                                  <a
                                    onClick={() =>
                                      handleArchiveJobs(item.id, item.jobType)
                                    }
                                    className="dropdown-item"
                                  >
                                    {" "}
                                    <img src="images/archive_job.png" /> Archive
                                    Job
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <a
                              id="hidtableshow2"
                              onClick={() =>
                                handleLandPhaseListByProject(item.id, i)
                              }
                            >
                              {item.jobNumber} &nbsp;
                              <img
                                src={"images/down-arrow.png"}
                                style={{
                                  transform: `rotate(${
                                    landId === i ? "180deg" : "0deg"
                                  })`,
                                }}
                              />
                            </a>
                          </td>
                          <td>{item.projectType}</td>
                          <td>{item.projectAddress}</td>
                          <td>{item.lot}</td>
                          <td>{item.block}</td>
                          <td>{item.phase}</td>
                          <td>{item.plan ?? "N/A"}</td>
                          <td>{item.subdivisions?.map((x) => x)}</td>
                          <td>
                            <div className="TableThreeDots">
                              <ul className="">
                                <li className="dropdown">
                                  <a
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/dots.png" />
                                  </a>
                                  <ol className="dropdown-menu dropdown-menuwidth">
                                    <li>
                                      <a
                                        onClick={() =>
                                          handleRestoreJob(item.id)
                                        }
                                      >
                                        <img src="images/restore_job.png" />
                                        Restore Job
                                      </a>
                                    </li>
                                    <li>
                                      <a href="lead_contract_managment.html">
                                        <img src="images/view_jobs_details.png" />
                                        View Job Details
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() =>
                                          handleJobDeleteShow(item.id)
                                        }
                                      >
                                        <img src="images/dropdown-delete.png" />
                                        Delete Job
                                      </a>
                                    </li>
                                  </ol>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={10} className="padding_none">
                            <div
                              className="JobBoxTable"
                              style={{
                                display: landId == i ? "block" : "none",
                              }}
                            >
                              <table>
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Land Track /Phase Job Number</th>
                                    <th>Description</th>
                                    <th>Land Track Square Footage</th>
                                    <th />
                                  </tr>
                                </thead>
                                <tbody>
                                  {landId == i &&
                                    landPhaseList?.landPhase?.length > 0 &&
                                    landPhaseList?.landPhase?.map(
                                      (data, index) => {
                                        return (
                                          <>
                                            <tr key={index}>
                                              <td>
                                                <div className="d-flex">
                                                  {data.id}
                                                  <div className="dropdown">
                                                    <span
                                                      className="Dots"
                                                      data-toggle="dropdown"
                                                      aria-haspopup="true"
                                                      aria-expanded="false"
                                                    >
                                                      <img src="images/dots.png" />
                                                    </span>
                                                    <div className="dropdown-menu">
                                                      <a
                                                        onClick={() =>
                                                          handleArchiveJobs(
                                                            data.id,
                                                            data.jobType
                                                          )
                                                        }
                                                        className="dropdown-item"
                                                      >
                                                        {" "}
                                                        <img src="images/archive_job.png" />{" "}
                                                        Archive Job
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                {/* <Link
                                                    to="/estimation"
                                                    state={data}
                                                  > */}
                                                {data?.jobNumber ?? "N.A"}
                                                {/* </Link> */}
                                                &nbsp;
                                                {data?.Lots?.length > 0 && (
                                                  <img
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      updateState((prev) => ({
                                                        ...prev,
                                                        landJobUnitOpenIndex:
                                                          prev.landJobUnitOpenIndex ==
                                                          index
                                                            ? -1
                                                            : index,
                                                      }));
                                                    }}
                                                    src={
                                                      "images/down-arrow.png"
                                                    }
                                                    style={{
                                                      transform: `rotate(${
                                                        landJobUnitOpenIndex ===
                                                        index
                                                          ? "180deg"
                                                          : "0deg"
                                                      })`,
                                                    }}
                                                  />
                                                )}
                                              </td>
                                              <td>
                                                {data.landTractPhaseDes ??
                                                  "N/A"}
                                              </td>
                                              <td>{data.footage ?? "N/A"}</td>
                                            </tr>
                                            {data?.Lots?.length > 0 &&
                                              landJobUnitOpenIndex == index && (
                                                <tr>
                                                  <td colSpan={7}>
                                                    <div
                                                      className="ThirdLevelTable"
                                                      style={{
                                                        border: "1px solid red",
                                                        borderRadius: 15,
                                                        padding: 5,
                                                      }}
                                                    >
                                                      <table>
                                                        <thead>
                                                          <tr>
                                                            <th>#</th>
                                                            <th>
                                                              Lot Job Number
                                                            </th>
                                                            <th>Lot #</th>
                                                            <th>Lot Type</th>
                                                            <th>
                                                              Primary Client
                                                            </th>
                                                            <th>
                                                              Square Footage
                                                              Description
                                                            </th>
                                                            <th>
                                                              {" "}
                                                              Description
                                                            </th>
                                                            <th>Action</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {data?.Lots?.length >
                                                            0 &&
                                                            data?.Lots?.map(
                                                              (itemm, indd) => {
                                                                // console.log("itemm---->",itemm);
                                                                return (
                                                                  <tr>
                                                                    <td>
                                                                      <div className="d-flex">
                                                                        {
                                                                          itemm.id
                                                                        }
                                                                        <div className="dropdown">
                                                                          <span
                                                                            className="Dots"
                                                                            data-toggle="dropdown"
                                                                            aria-haspopup="true"
                                                                            aria-expanded="false"
                                                                          >
                                                                            <img src="images/dots.png" />
                                                                          </span>
                                                                          <div className="dropdown-menu">
                                                                            <a
                                                                              onClick={() =>
                                                                                handleArchiveJobs(
                                                                                  itemm.id,
                                                                                  itemm.jobType
                                                                                )
                                                                              }
                                                                              className="dropdown-item"
                                                                            >
                                                                              {" "}
                                                                              <img src="images/archive_job.png" />{" "}
                                                                              Archive
                                                                              Job
                                                                            </a>
                                                                            <a
                                                                              className="dropdown-item"
                                                                              onClick={() =>
                                                                                handleShowInWarranty(
                                                                                  itemm.id,
                                                                                  itemm.jobType
                                                                                )
                                                                              }
                                                                            >
                                                                              {" "}
                                                                              <img src="images/home_warranty.png" />{" "}
                                                                              Show
                                                                              in
                                                                              Warranty
                                                                              &nbsp;&nbsp;
                                                                            </a>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </td>
                                                                    <td>
                                                                      {itemm?.jobNumber ??
                                                                        "N/A"}
                                                                    </td>
                                                                    <td>
                                                                      {itemm?.numOfLot ??
                                                                        "0"}
                                                                    </td>
                                                                    <td>
                                                                      {itemm?.lotType ??
                                                                        "N/A"}
                                                                    </td>
                                                                    <td>
                                                                      {itemm?.name ??
                                                                        "N/A"}
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        itemm?.lotDescription
                                                                      }
                                                                    </td>
                                                                    <td>
                                                                      {itemm?.lotDescription ??
                                                                        "N/A"}
                                                                    </td>
                                                                    <td>
                                                                      <div className="TableThreeDots">
                                                                        <ul className="">
                                                                          <li className="dropdown">
                                                                            <a
                                                                              role="button"
                                                                              data-toggle="dropdown"
                                                                              aria-haspopup="true"
                                                                              aria-expanded="false"
                                                                            >
                                                                              <img src="images/dots.png" />
                                                                            </a>
                                                                            <ol className="dropdown-menu dropdown-menuwidth">
                                                                              <li>
                                                                                <a
                                                                                  onClick={() =>
                                                                                    handleRestoreJob(
                                                                                      itemm.id
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  <img src="images/restore_job.png" />
                                                                                  Restore
                                                                                  Job
                                                                                </a>
                                                                              </li>
                                                                              <li>
                                                                                <a href="lead_contract_managment.html">
                                                                                  <img src="images/view_jobs_details.png" />
                                                                                  View
                                                                                  Job
                                                                                  Details
                                                                                </a>
                                                                              </li>
                                                                              <li>
                                                                                <a
                                                                                  onClick={() =>
                                                                                    handleJobDeleteShow(
                                                                                      itemm.id
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  <img src="images/dropdown-delete.png" />
                                                                                  Delete
                                                                                  Job
                                                                                </a>
                                                                              </li>
                                                                            </ol>
                                                                          </li>
                                                                        </ul>
                                                                      </div>
                                                                    </td>
                                                                  </tr>
                                                                );
                                                              }
                                                            )}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </td>
                                                </tr>
                                              )}
                                          </>
                                        );
                                      }
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
          <div className="PaginationArea">
            <h6>Showing 1 to 10 of 27 entries</h6>
            <h6>
              <img src="images/leftarrow.png" /> 01{" "}
              <img src="images/rightarrow.png" /> Out 10
            </h6>
          </div>
          {/* <div class="PaginationArea">
       <h6>Showing 1 to 4 of 27 entries</h6>
       <div class="PaginationRight">
           <a href=""><span><img src="images/pagination_left_arrow.png"></span>01<span><img src="images/pagination_right_arrow.png"></span></a>
           <h6>Out 10</h6>
       </div>
    </div> */}
        </div>
      </div>
    </>
  );
};

export default ArchiveLandDevelopment;
