import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import EditPhase from "./EditPhase";
import { useDispatch } from "react-redux";
import { GetSystemPhaseAction } from "../../../redux/action/constructionsAction";

const SystemDefault = ({
  DefaultSystemModal,
  setDefaultSystem,
  setAddSchedule,
  scheduleState,
}) => {
  const dispatch = useDispatch();

  const companyType="SINGLE_FAMILY_RESIDENTIAL"


  const [phaseList, setPhaseList] = useState([]);
  const [phaseSelection, setPhaseSelection] = useState({
    all: false,
    onlySystem: false,
  });

  const [taskList, setTaskList] = useState([]);

  const handleDefaultSystemModalClose = () => {
    setDefaultSystem(false);
    setAddSchedule(true);
  };

  const [EditPhaseModal, setEditPhaseModal] = useState(false);
  const handleEditPhaseModalShow = (tasks) => {
    setTaskList(tasks);
    setDefaultSystem(false);
    setEditPhaseModal(true);
  };

  useEffect(() => {
    dispatch(GetSystemPhaseAction({companyType:companyType})).then((res) => {
      setPhaseList(res?.data?.details);
    });
  }, [DefaultSystemModal]);

  const handleCheckbox = (e) => {
    const { checked, name } = e.target;

    setPhaseSelection({ ...phaseSelection, [name]: checked });
  };




   const  handleDelete=(i)=>{

    let removedSemesterData = [...phaseList];
    removedSemesterData.splice(i, 1);
    setPhaseList(removedSemesterData);



   }

  return (
    <>
      <Modal
        show={DefaultSystemModal}
        onHide={handleDefaultSystemModalClose}
        className="ModalBox SmallModal"
      >
        <div class="modal-content">
          <a
            onClick={handleDefaultSystemModalClose}
            className="CloseModal"
            data-dismiss="modal"
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>System Default Schedule</h4>
            </div>
            <div class="FolderForm">
              <form>
                <div class="form-group">
                  <h6>Phase Selection</h6>
                  <div class="Categories Categories2">
                    <div class="Categories_all Categories_all2">
                      <span>Select roles to acess folder's content</span>
                      <img src="images/down.png" />
                    </div>
                    <ul style={{ display: "block" }}>
                      <li>
                        <label class="CheckBox">
                          All
                          <input
                            onChange={handleCheckbox}
                            name="all"
                            type="checkbox"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label class="CheckBox">
                          Full List of phases loaded by the system
                          <input
                            type="checkbox"
                            name="onlySystem"
                            onChange={handleCheckbox}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="Button mb-4">
                  <button class="Create">Confirm Selection</button>
                </div>
                <div class="PhaseActionTable">
                  <table>
                    <thead>
                      <tr>
                        <th>Selected Phases</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(phaseSelection?.all || phaseSelection?.onlySystem) &&
                        phaseList?.map((item,i) => (
                          <tr>
                            <td>
                              {item?.phaseName} ({item?.Statictasks?.length}{" "}
                              tasks)
                            </td>
                            <td>
                              <div class="Actions">
                                <a
                                  onClick={() =>
                                    handleEditPhaseModalShow(item)
                                  }
                                >
                                  <img src="images/document-edit.png" />
                                </a>
                                <a href="javascript:void(0);">
                                  <img src="images/fileview.png" />
                                </a>
                                <a onClick={()=>handleDelete(i)}>
                                  <img src="images/Cross.png" />
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div class="Button d-flex justify-content-between mt-4">
                  <button
                    class="Create"
                    data-toggle="modal"
                    data-target="#ConstructionScheduleGeneration"
                    data-dismiss="modal"
                  >
                    Add System Schedule
                  </button>
                  <button
                    class="Outline"
                    data-toggle="modal"
                    data-target="#ConstructionScheduleGeneration"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>


      {EditPhaseModal &&<EditPhase
        setEditPhaseModal={setEditPhaseModal}
        taskList={taskList}
        scheduleState={scheduleState}
        EditPhaseModal={EditPhaseModal}
        setDefaultSystem={setDefaultSystem}
      />}
    </>
  );
};

export default SystemDefault;
