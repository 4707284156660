import { taskAction , iBuildAdministrationActionTypes} from "../actionTypes";
const initialState = {
  loader: false,
  helpListData:[],
  createdTaskList: [],
  inProcessTaskList:[],
  completeTaskList:[],
  memberList:[],
  historyTaskList:[],
  showCancelList:[],
  viewTaskDetail:[],
  incomingAccessList:[],
  manageAccessToMyList:[],
  manageAccessUserTaskList:[],
  helpDetailsData:[],
  helptabListData:[],
  headerHelpListData:[],
  customerDemoRequestListData:[],
  customerListData:[],
  customerRequestListData:[],
  customerHistoryListData:[]
};

export default function systemAdministrationReducer(state = initialState, { type, payload }) {
  switch (type) {

    case iBuildAdministrationActionTypes.GET_HELP_REQUEST_INITIATE: {
      return {
        ...state,helpListData: [],
      };
    }
    case iBuildAdministrationActionTypes.GET_HELP_REQUEST_SUCCESS: {
      return {
        ...state, helpListData: payload
      };
    }
    case iBuildAdministrationActionTypes.GET_HELP_REQUEST_FAILURE: {
      return {
        ...state,helpListData: payload,
      };
    }


    case iBuildAdministrationActionTypes.GET_CUSTOMER_DEMO_REQUEST_INITIATE: {
      return {
        ...state,customerDemoRequestListData: [],
      };
    }
    case iBuildAdministrationActionTypes.GET_CUSTOMER_DEMO_REQUEST_SUCCESS: {
      return {
        ...state, customerDemoRequestListData: payload
      };
    }
    case iBuildAdministrationActionTypes.GET_CUSTOMER_DEMO_REQUEST_FAILURE: {
      return {
        ...state,customerDemoRequestListData: payload,
      };
    }


    
    case iBuildAdministrationActionTypes.CUSTOMER_LIST_INITIATE: {
      return {
        ...state,customerListData: [],
      };
    }
    case iBuildAdministrationActionTypes.CUSTOMER_LIST_SUCCESS: {
      return {
        ...state, customerListData: payload
      };
    }
    case iBuildAdministrationActionTypes.CUSTOMER_LIST_FAILURE: {
      return {
        ...state,customerListData: payload,
      };
    }



    
    case iBuildAdministrationActionTypes.CUSTOMER_HISTORY_LIST_INITIATE: {
      return {
        ...state,customerHistoryListData: [],
      };
    }
    case iBuildAdministrationActionTypes.CUSTOMER_HISTORY_LIST_SUCCESS: {
      return {
        ...state, customerHistoryListData: payload
      };
    }
    case iBuildAdministrationActionTypes.CUSTOMER_HISTORY_LIST_FAILURE: {
      return {
        ...state,customerHistoryListData: payload,
      };
    }


     
    case iBuildAdministrationActionTypes.CUSTOMER_REQUEST_LIST_INITIATE: {
      return {
        ...state,customerRequestListData: [],
      };
    }
    case iBuildAdministrationActionTypes.CUSTOMER_REQUEST_LIST_SUCCESS: {
      return {
        ...state, customerRequestListData: payload
      };
    }
    case iBuildAdministrationActionTypes.CUSTOMER_REQUEST_LIST_FAILURE: {
      return {
        ...state,customerRequestListData: payload,
      };
    }
      

    case iBuildAdministrationActionTypes.CUSTOMER_CODE_LIST_INITIATE: {
      return {
        ...state,customerCodeListData: [],
      };
    }
    case iBuildAdministrationActionTypes.CUSTOMER_CODE_LIST_SUCCESS: {
      return {
        ...state, customerCodeListData: payload
      };
    }
    case iBuildAdministrationActionTypes.CUSTOMER_CODE_LIST_FAILURE: {
      return {
        ...state,customerCodeListData: payload,
      };
    }


    case iBuildAdministrationActionTypes.GET_HELP_TAB_REQUEST_INITIATE: {
      return {
        ...state,helptabListData: [],
      };
    }
    case iBuildAdministrationActionTypes.GET_HELP_TAB_REQUEST_SUCCESS: {
      return {
        ...state, helptabListData: payload
      };
    }
    case iBuildAdministrationActionTypes.GET_HELP_TAB_REQUEST_FAILURE: {
      return {
        ...state,helptabListData: payload,
      };
    }



    case iBuildAdministrationActionTypes.GET_HEADER_HELP_REQUEST_INITIATE: {
      return {
        ...state,headerHelpListData: [],
      };
    }
    case iBuildAdministrationActionTypes.GET_HEADER_HELP_REQUEST_SUCCESS: {
      return {
        ...state, headerHelpListData: payload
      };
    }
    case iBuildAdministrationActionTypes.GET_HEADER_HELP_REQUEST_FAILURE: {
      return {
        ...state,headerHelpListData: payload,
      };
    }


    case iBuildAdministrationActionTypes.GET_HELP_REQUEST_DETAILS_INITIATE: {
      return {
        ...state,helpDetailsData: [],
      };
    }
    case iBuildAdministrationActionTypes.GET_HELP_REQUEST_DETAILS_SUCCESS: {
      return {
        ...state, helpDetailsData: payload?.notData
      };
    }
    case iBuildAdministrationActionTypes.GET_HELP_REQUEST_DETAILS_FAILURE: {
      return {
        ...state,helpDetailsData: payload,
      };
    }

    case taskAction.CREATED_TASK_LIST_INITIATE: {
      return {
        ...state,createdTaskList: [],
      };
    }
    case taskAction.CREATED_TASK_LIST_SUCCESS: {
      return {
        ...state, createdTaskList: payload
      };
    }
    case taskAction.CREATED_TASK_LIST_FAILURE: {
      return {
        ...state,createdTaskList: payload,
      };
    }

    case taskAction.INPROGRESS_TASK_LIST_INITIATE: {
      return {
        ...state,
        inProcessTaskList: [],
        loader: true,
      };
    }
    case taskAction.INPROGRESS_TASK_LIST_SUCCESS: {
      return {
        ...state,
        inProcessTaskList: payload,
        loader: false,
      };
    }
    case taskAction.INPROGRESS_TASK_LIST_FAILURE: {
      return {
        ...state,
        inProcessTaskList: payload,
        loader: false,
      };
    }  
    case taskAction.COMPLETED_TASK_LIST_INITIATE: {
      return {
        ...state,
        completeTaskList: [],
        loader: true,
      };
    }
    case taskAction.COMPLETED_TASK_LIST_SUCCESS: {
      return {
        ...state,
        completeTaskList: payload,
        loader: false,
      };
    }
    case taskAction.COMPLETED_TASK_LIST_FAILURE: {
      return {
        ...state,
        completeTaskList: payload.data,
        loader: false,
      };
    }

    case taskAction.TASK_MEMBER_LIST_INITIATE: {
      return {
        ...state,
        memberList: [],
        loader: true,
      };
    }
    case taskAction.TASK_MEMBER_LIST_SUCCESS: {
      return {
        ...state,
        memberList: payload,
        loader: false,
      };
    }
    case taskAction.TASK_MEMBER_LIST_FAILURE: {
      return {
        ...state,
        memberList: payload,
        loader: false,
      };
    }

    case taskAction.TASK_HISTORY_LIST_INITIATE: {
      return {
        ...state,historyTaskList: [],
      };
    }
    case taskAction.TASK_HISTORY_LIST_SUCCESS: {
      return {
        ...state, historyTaskList: payload
      };
    }
    case taskAction.TASK_HISTORY_LIST_FAILURE: {
      return {
        ...state,historyTaskList: payload,
      };
    }

    case taskAction.SHOW_CANCEL_LIST_INITIATE: {
      return {
        ...state,showCancelList: [],
      };
    }
    case taskAction.SHOW_CANCEL_LIST_SUCCESS: {
      return {
        ...state, showCancelList: payload
      };
    }
    case taskAction.SHOW_CANCEL_LIST_FAILURE: {
      return {
        ...state,showCancelList: payload,
      };
    }

    case taskAction.VIEW_TASK_INITIATE: {
      return {
        ...state,viewTaskDetail: [],
      };
    }
    case taskAction.VIEW_TASK_SUCCESS: {
      return {
        ...state, viewTaskDetail: payload
      };
    }
    case taskAction.VIEW_TASK_FAILURE: {
      return {
        ...state,viewTaskDetail: payload,
      };
    }

    case taskAction.INCOMING_ACCESS_TASK_REQUEST_LIST_INITIATE: {
      return {
        ...state,incomingAccessList: [],
      };
    }
    case taskAction.INCOMING_ACCESS_TASK_REQUEST_LIST_SUCCESS: {
      return {
        ...state, incomingAccessList: payload
      };
    }
    case taskAction.INCOMING_ACCESS_TASK_REQUEST_LIST_FAILURE: {
      return {
        ...state,incomingAccessList: payload,
      };
    }

    case taskAction.MANAGE_ACCESS_TO_MY_TASK_LIST_INITIATE: {
      return {
        ...state,manageAccessToMyList: [],
      };
    }
    case taskAction.MANAGE_ACCESS_TO_MY_TASK_LIST_SUCCESS: {
      return {
        ...state, manageAccessToMyList: payload
      };
    }
    case taskAction.MANAGE_ACCESS_TO_MY_TASK_LIST_FAILURE: {
      return {
        ...state,manageAccessToMyList: payload,
      };
    }

    case taskAction.MANAGE_ACCESS_USER_TASK_LIST_INITIATE: {
      return {
        ...state,manageAccessUserTaskList: [],
      };
    }
    case taskAction.MANAGE_ACCESS_USER_TASK_LIST_SUCCESS: {
      return {
        ...state, manageAccessUserTaskList: payload
      };
    }
    case taskAction.MANAGE_ACCESS_USER_TASK_LIST_FAILURE: {
      return {
        ...state,manageAccessUserTaskList: payload,
      };
    }


    default:
      return state;
  }
}
