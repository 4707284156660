import { combineReducers } from "redux";
import authReducer from "./reducer/authReducer";
import constructionVendor from "./reducer/ConstructionVendorReducer";
import salesReducer from "./reducer/salesReducer";
import clientReducer from "./reducer/clientReducer";
import PriceBookReducer from "./reducer/priceBookReducer";
import fileManagerData from "./reducer/fileManagerReducer";
import todoReducer from "./reducer/todoReducer";
import taskReducer from "./reducer/taskReducer";
import costReducer from "./reducer/costReducer";
import estimationReducer from "./reducer/estimationReducer";
import jobReducer from "./reducer/jobReducer";
import adminUserReducers from "./reducer/adminUserReducer";
import itineraryReducer from "./reducer/ItineraryReducer";
import warrantyReducer from "./reducer/warrantyReducer";
import constructionReducer from "./reducer/constructionReducer";
import ProformaReducer from "./reducer/ProformaReducer";
import clientViewLogin from "./reducer/clientAuthReducer";
import tradeViewLogin from "./reducer/tradeViewAuthReducer";
import clientViewConfigData from "./reducer/clientViewConfigReducer";
import investorViewLogin from "./reducer/investorAuthReducer";
import designSelectionsReducers from "./reducer/designSelectiosReducer";
import changeOrderReducer from "./reducer/changeOrderReducer";
import homeReducer from "./reducer/homeReducer";
import archiveReducer from "./reducer/archiveReducer";
import modelsReducer from "./reducer/modelsReducer";
import vendorViewConfigReducer from "./reducer/vendorViewConfigReducer";
import projectProformaReducer from "./reducer/projectProformaReducer";
import systemAdministrationReducer from "./reducer/systemAdministrationReducer";

import profileReducer from "./reducer/profileReducer";
import projectReducerDk from "./reducer/projectReducerDk";
import notificationReducerDk from "./reducer/notificationReducerDk";
import adminNotification from "./reducer/adminNotificationReducer";
import dropdownReducer from "./reducer/dropdownReducer";
import adminSalesReducer from "./reducer/adminSalesReducer";
import adminMenuSetupReducer from "./reducer/adminMenuSetupReducer";
import adminConstructionReducer from "./reducer/adminConstructionReducer";
import adminSigningAuthorityReducer from './reducer/adminSigningAuthorityReducer';
import adminAccountingReducer from './reducer/adminAccountingReducer';
import accountingReducer from "./reducer/accountingReducer";
import adminPlanReducer from "./reducer/planReducer";
import reportsReducer from "./reducer/reportsReducer";
import companyReducer from "./reducer/companyReducer";
import multiFamilyReducer from "./reducer/multiFamilyReducer";




const rootReducer=combineReducers({
  authReducer,constructionVendor, salesReducer, clientReducer,PriceBookReducer,fileManagerData,
   todoReducer,taskReducer, costReducer,jobReducer,estimationReducer,
   itineraryReducer, warrantyReducer,
   constructionReducer,ProformaReducer,
   clientViewLogin,
  tradeViewLogin,
  clientViewConfigData,
  investorViewLogin,
  designSelectionsReducers,
  changeOrderReducer,
  homeReducer,
  vendorViewConfigReducer,
  archiveReducer,
  modelsReducer,
  projectProformaReducer,
  systemAdministrationReducer,

  constructionReducer,
  adminConstructionReducer,

  profileReducer,
  projectReducerDk,
  notificationReducerDk,
  adminNotification,
  dropdownReducer,
  adminSalesReducer,
  adminMenuSetupReducer,
  adminSigningAuthorityReducer,
  adminAccountingReducer,
  adminUserReducers,
  adminPlanReducer,
  accountingReducer,
  reportsReducer,
  companyReducer,
  multiFamilyReducer,


})


  
   
   


export default rootReducer;
