import { JobActionTypes, companyActionTypes } from "../actionTypes";
const initialState = {
  loader: false,
jobListData:[],
projectListData:[],
foundationListData:[],
taxListData:[],
homeListData:[],
residentialDetailsData:[],
landDetailsData:[]

};

export default function jobReducer(state = initialState, { type, payload }) {
  switch (type) {
    case JobActionTypes.JOB_LIST_INITIATE: {
        return {
          ...state,
          jobListData: [],
          loader:true
        };
      }
      case JobActionTypes.JOB_LIST_SUCCESS: {
        return {
          ...state,
          jobListData: payload,
          loader:false
        };
      }
      case JobActionTypes.JOB_LIST_FAILURE: {
        return {
          ...state,
          jobListData: payload?.data,
          loader:false
        };
      }

     
      case JobActionTypes.PROJECT_LIST_INITIATE: {
        return {
          ...state,
          loader:true
        };
      }
      case JobActionTypes.PROJECT_LIST_SUCCESS: {
        return {
          ...state,
          projectListData: payload?.projects,
          loader:false
        };
      }
      case JobActionTypes.PROJECT_LIST_FAILURE: {
        return {
          ...state,
          projectListData: payload.data,
          loader:false
        };
      }

      case JobActionTypes.FOUNDATION_TYPE_INITIATE: {
        return {
          ...state,
          foundationListData: [],
          loader:true
        };
      }
      case JobActionTypes.FOUNDATION_TYPE_SUCCESS: {
        return {
          ...state,
          foundationListData: payload?.data,
          loader:false
        };
      }
      case JobActionTypes.FOUNDATION_TYPE_FAILURE: {
        return {
          ...state,
          foundationListData: payload.data,
          loader:false
        };
      }

      case JobActionTypes.HOME_TYPE_INITIATE: {
        return {
          ...state,
          homeListData: [],
          loader:true
        };
      }
      case JobActionTypes.HOME_TYPE_SUCCESS: {
        return {
          ...state,
          homeListData: payload?.data,
          loader:false
        };
      }
      case JobActionTypes.HOME_TYPE_FAILURE: {
        return {
          ...state,
          homeListData: payload.data,
          loader:false
        };
      }


      case JobActionTypes.TAX_PROFILE_LIST_INITIATE: {
        return {
          ...state,
          taxProfileListData: [],
          loader:true
        };
      }
      case JobActionTypes.TAX_PROFILE_LIST_SUCCESS: {
        return {
          ...state,
          taxProfileListData: payload,
          loader:false
        };
      }
      case JobActionTypes.TAX_PROFILE_LIST_FAILURE: {
        return {
          ...state,
          taxProfileListData: payload?.data,
          loader:false
        };
      }

      case JobActionTypes.RESIDENTIAL_DETAILS_INITIATE: {
        return {
          ...state,
          residentialDetailsData: [],
          loader:true
        };
      }
      case JobActionTypes.RESIDENTIAL_DETAILS_SUCCESS: {
        return {
          ...state,
          residentialDetailsData: payload,
          loader:false
        };
      }
      case JobActionTypes.RESIDENTIAL_DETAILS_FAILURE: {
        return {
          ...state,
          residentialDetailsData: payload?.data,
          loader:false
        };
      }

      case JobActionTypes.LAND_DEVELOPMENT_DETAILS_INITIATE: {
        return {
          ...state,
          landDetailsData: [],
          loader:true
        };
      }
      case JobActionTypes.LAND_DEVELOPMENT_DETAILS_SUCCESS: {
        return {
          ...state,
          landDetailsData: payload,
          loader:false
        };
      }
      case JobActionTypes.LAND_DEVELOPMENT_DETAILS_FAILURE: {
        return {
          ...state,
          landDetailsData: payload?.data,
          loader:false
        };
      }


    default:
      return state;
  }
}