import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCommonBuildingFoundationStep2 } from "../../../redux/action/proformaAction";

const CommonBuildingFoundationQuanties = ({
  unitList,
  handleCommonBuildingfoundationChange,
  handleShowComponents,
}) => {
  const dispatch = useDispatch([]);
  const [commonBuildingFoundationInput, setCommonBuildingFoundationInput] =
    useState([]);
  useEffect(() => {
    dispatch(getCommonBuildingFoundationStep2()).then((res) => {
      console.log({ res });
      setCommonBuildingFoundationInput(res?.data?.data);
    });
  }, []);
  return (
    <>
      <div className="ModalBigheading pl-4 mb-3 d-flex justify-content-between align-items-center">
        <h3 className="mb-0" style={{ marginBottom: "0px!important" }}>
          <span>Common Buildings Foundation Quantities</span>
        </h3>
        <h6 className="mb-0">
          <img src="images/book-search.png" /> Customize Base Costs
        </h6>
      </div>
      <div className="ProformaAccordion">
        <div className="accordion" id="accordionExample">
          {commonBuildingFoundationInput?.length > 0 &&
            commonBuildingFoundationInput?.map((item, ind) => {
              return (
                <div className="card">
                  <div className="card-header" id="headingMain">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseMain"
                        aria-expanded="false"
                        aria-controls="collapseMain"
                      >
                        {item.name}
                      </button>
                    </h2>
                    <a href="javascript:void(0);">
                      <img src="images/book-search.png" />
                    </a>
                  </div>
                  <div
                    id="collapseMain"
                    className="collapse"
                    aria-labelledby="headingMain"
                    data-parent="#accordionExample"
                    style={{}}
                  >
                    <div className="card-body">
                      <div>
                        {item?.ProformaCostHeadings?.length > 0 &&
                          item?.ProformaCostHeadings?.map((data, inde) => {
                            return (
                              <div className="card">
                                <div className="card-header" id="headingOne">
                                  <h2 className="mb-0">
                                    <button
                                      className="btn btn-link btn-block text-left"
                                      type="button"
                                    >
                                      {data?.name}
                                    </button>
                                  </h2>
                                  <a href="javascript:void(0);">
                                    <img src="images/book-search.png" />
                                  </a>
                                </div>
                                <div style={{}}>
                                  <div className="card-body">
                                    <div
                                      className="EnterProjectTable  GreyTd"
                                      style={{ overflowX: "scroll" }}
                                    >
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>Cost Code #</th>
                                            <th>Description</th>
                                            <th>
                                              <div className="ml-5">
                                                Unit of measure
                                              </div>
                                            </th>
                                            <th>Block A</th>
                                            {/* <th>Block C</th> */}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {data?.ProformaBaseCosts?.length >
                                            0 &&
                                            data?.ProformaBaseCosts?.map(
                                              (datas, i) => {
                                                return (
                                                  <tr>
                                                    <td>{i + 1}</td>
                                                    <td>
                                                      {datas.CostCode?.number}
                                                    </td>
                                                    <td>
                                                      <div className="CommonSelectBox width-250">
                                                        <select
                                                        // value={item.foundationType}
                                                        // onChange={(e) => handleBuildingFieldChange(e, index, 'foundationType')}
                                                        >
                                                          <option value="">
                                                            Select Foundation
                                                            Type
                                                          </option>
                                                          {unitList?.length >
                                                            0 &&
                                                            unitList?.map(
                                                              (data, i) => {
                                                                return (
                                                                  <option
                                                                    key={i}
                                                                    value={
                                                                      data.id
                                                                    }
                                                                  >
                                                                    {data.name}
                                                                  </option>
                                                                );
                                                              }
                                                            )}
                                                        </select>
                                                      </div>
                                                    </td>
                                                    {/* {
                                                        item.data[1].ProformaBaseCosts[0].ProformaBlockQuantities[0].quantity
                                                      } */}
                                                    {datas
                                                      ?.ProformaBlockQuantities
                                                      ?.length > 0 &&
                                                      datas?.ProformaBlockQuantities?.map(
                                                        (block, blocId) => {
                                                          return (
                                                            <td key={blocId}>
                                                              <div className="form-group">
                                                                <input
                                                                  type="text"
                                                                  className="form-control"
                                                                  placeholder="Insert Quality"
                                                                  name="quantity"
                                                                  value={
                                                                    block.quantity
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleCommonBuildingfoundationChange(
                                                                      e,
                                                                      ind,
                                                                      inde,
                                                                      i,
                                                                      blocId
                                                                    )
                                                                  }
                                                                />
                                                              </div>
                                                            </td>
                                                          );
                                                        }
                                                      )}
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                      <div className="PaginationArea pl-0">
                                        <h6>Showing 1 to 10 of 27 entries</h6>
                                        <h6>
                                          <img src="images/leftarrow.png" /> 01{" "}
                                          <img src="images/rightarrow.png" />{" "}
                                          Out 10
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="Button d-flex  justify-content-between align-items-center px-5">
        <button
          className="Outline border text-success border-success"
          onClick={() => handleShowComponents("commonBuildingFoundationInput")}
        >
          Cancel
        </button>
        <button
          className="Create bg-success border border-success"
          onClick={() =>
            handleShowComponents("commonBuildingFoundationSummeryPreview")
          }
        >
          Save Draft
        </button>
      </div>
    </>
  );
};

export default CommonBuildingFoundationQuanties;
