import React, { useEffect } from 'react'
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from 'react-redux';
import { getSharedItineriesAction } from '../../../redux/action/IbuildAdminItineraryAction';

const ViewSharedItineraryModal = ({ViewSharedItineraryState,SetViewSharedItineraryState,userId, updateState}) => {


    const dispatch = useDispatch()
    const { getSharedItinerary } = useSelector((state) => state.itineraryReducer);


    const handleItineraySettingModalClose = () => {
        SetViewSharedItineraryState(false);
        updateState((prev)=>({...prev,UpdateAssignStaffMemberModal:true}))
      };
        const handleItineraySettingModalShow = () => {
            SetViewSharedItineraryState(true);
            updateState((prev)=>({...prev,UpdateAssignStaffMemberModal:false}))

        };


    
        

    useEffect(()=>{
        dispatch(getSharedItineriesAction(userId))

    },[])




    


  return (
    <Modal show={ViewSharedItineraryState} className="ModalBox LargeModal">
    <div class="modal-content BgCreate">
      <a
        onClick={handleItineraySettingModalClose}
        class="CloseModal"
        data-toggle="modal"
      >
        ×
      </a>

      <div class="FormArea">
        <div class="ModalTitleBox">
          <h4>View Shared Itineraries</h4>
        </div>
        <div class="JobTitleArea mb-2">
          <div class="JobBox">
            <h6><span>User :</span> User 1</h6>
          </div>
        </div>
       
        <div class="TableList CommonHeading TableHeightScroll">
          <table>
            <thead>
              <tr>
                <th>Item #</th>
                <th>Type</th>
                <th>Subject</th>
                <th>Date</th>
                <th>From</th>
                <th>To</th>
              </tr>
            </thead>
            <tbody>
                {getSharedItinerary?.map((item)=>
                      <tr>
                      <td>10</td>
                      <td>Meeting</td>
                      <td>Meeting 1</td>
                      <td>10/18/2023</td>
                      <td>02:00 PM</td>
                      <td>03:00 PM</td>
                    </tr>
                )}
        
            
          
            </tbody>
          </table>
        </div>
        <div class="PaginationArea">
          <h6>Showing 1 to 10 of 27 entries</h6>
          <h6><img src="images/leftarrow.png" /> <span>01</span> <img src="images/rightarrow.png"/> Out 10</h6>
        </div>
      </div>

     </div>
     </Modal>
  )
}

export default ViewSharedItineraryModal