import React from "react";
import Modal from "react-bootstrap/Modal";

const EmailView = ({ EmailViewModal, SetEmailViewModal ,element}) => {
  const handleEmailViewModalClose = () => {
    SetEmailViewModal(false);
  };
  return (
    <Modal show={EmailViewModal} className="ModalBox SmallModal BgClass">
      <a
        onClick={handleEmailViewModalClose}
        class="CloseModal ClosePermissionId"
      >
        ×
      </a>
      <div class="FormArea">
        <div class="ModalTitleBox">
          <h4>View Notification</h4>
        </div>
        <div class="FolderForm">
        <div class="FormArea">
              <div class="ModalTitleBox">
                <h4>View Notification</h4>
              </div>
              <div class="FolderForm">
                <form>
                   <div class="form-group">
                    <h6>Event</h6>
                    <h6 class="fw-400 fw-14">{element?.eventName}</h6>
                    </div>
                   <div class="form-group">
                    <h6>Notification Type</h6>
                    <p class="ml-0">Email</p>
                   </div>
                   <div class="form-group">
                    <h6>Assign Users</h6>
                   </div>
                   <div class="form-group">
                    <h6>Assign User Roles</h6>
                    <p class="ml-0">Estimator</p>
                   </div>
                   <div class="form-group">
                    <h6>Originating User Only</h6>
                    <p class="ml-0">On</p>
                   </div>
                   <div class="ModalBigheading">
                    <h3><span>Notification Details</span></h3>
                   </div>
                   <div class="form-group">
                    <h6>External Recipient(s)</h6>
                    <p class="mb-3 ml-0">Vendor</p>
                    <p class="ml-0">Vendor1@vendor.com</p>
                   </div>
                   <div class="ModalBigheading">
                    <h3><span>Subject</span></h3>
                   </div>
                   <div class="QuotedArea">
                    <h4><span><img src="images/insert_keys.png" /></span>Quoteld</h4>
                    <h4><span><img src="images/insert_keys.png" /></span>JobId has been returned by</h4>
                    <h4><span><img src="images/insert_keys.png" /></span>Vendor Name</h4>
                  </div>
                  <div class="ModalBigheading mt-4">
                    <h3><span>Body</span></h3>
                   </div>
                   <div class="QuotedArea flex-column">
                    <h4 class="mb-3">Hello,</h4>
                    <div class="d-flex my-3">
                      <h4><span><img src="images/insert_keys.png"  /></span>Quoteld</h4>
                      <h4><span><img src="images/insert_keys.png"  /></span>JobId has been returned by</h4>
                      <h4><span><img src="images/insert_keys.png"  /></span>Vendor Name</h4>
                    </div>
                    <h4>Ibuild Notification System</h4>
                  </div>
                    <div class="Button text-center mt-5">
                      <button class="Outline">Close</button>
                    </div>
                </form>
              </div>
            </div>
        </div>
      </div>
    </Modal>
  );
};

export default EmailView;
