import React, { useEffect, useState } from "react";
import Header from "../Header";
import SideNav from "../SideNav";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllJobListAction,
  getAllProjectListAction,
} from "../../redux/action/jobAction";
import {
  statsBudgePoChanageOrderAction,
  statsFpoAnalysisAction,
  statsFpoAnalysisSummaryAction,
  statsPurchasingReportAction,
  statsSalesAction,
  statsWarrantyAction,
} from "../../redux/action/reportsAction";
import Financial from "./ReportsTabs/Financial";
import Statistical from "./ReportsTabs/Statistical";

import ColumnModal from "./ReportsModal/ColumnModal";
import CostDistribution from "./ReportsModal/CostDistribution";
import ProjectOneModal from "./ReportsModal/ProjectOneModal";
import ProjectTwoModal from "./ReportsModal/ProjectTwoModal";
import ShareReportModal from "./ReportsModal/ShareReportModal";
import CreateReportModal from "./ReportsModal/CreateReportModal";

const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

const initialState = {
  reportName: "Sales & Cash Deposits Status",
  ProjectId: "",
  allJob: false,
  JobIds: [],
  JobType: "MultiUnit",
  AvaiInvestorView: true,
  toDateChecked: true,
  fromDate: "2023-02-20T00:00:00Z",
  toDate: "2023-02-20T00:00:00Z",
};

const ReportsMain = () => {


  const [activeTab, setActiveTab] = useState("");
  const [iState, updateState] = useState(initialState);
  const dispatch = useDispatch();

  const [columnModal, setColumnModal] = useState(false);
  const [costDistribution, setCostDistribution] = useState(false);
  const [createReportModal, setCreateReportModal] = useState(false);
  const [projectOneModal, setProjectOneModal] = useState(false);
  const [projectTwoModal, setProjectTwoModal] = useState(false);
  const [shareReportModal, setShareReportModal] = useState(false);

  const { projectListData, jobListData } = useSelector(
    (state) => state.jobReducer
  );

  const {
    blocksActualCosts,
    blocksUnitsActualCosts,
    financialSalesCashDeposite,
    estimatiActual,

    statsPurchasingReport,
    statsBudgePoChanageOrder,
    statsFpoAnalysis,
    statsFpoAnalysisSummary,
    statsSale,
    statsWarranty,
    statsWarrantyChartFirst,
    statsWarrantyChartSecond,
    statsWarrantyChartThird,
  } = useSelector((state) => state.reportsReducer);

  useEffect(() => {
    if (iBuildLocalData?.user?.CompanyId != null) {
      dispatch(getAllProjectListAction());
    }
  }, []);

  const handleSelectProject = (e) => {
    updateState({ ...iState, ProjectId: e.target.value });
    dispatch(getAllJobListAction(e.target.value));
  };

  const handleSelectJob = (e) => {
    updateState({ ...iState, JobIds: [e.target.value] });
  };

  return (
    <>
      <Header />
      <SideNav />

      <div className="WrapperArea">
        <div className="WrapperBox">
          <div class="d-flex flex-wrap my-5">
            <h6
              class="CursorPointer"
              data-toggle="modal"
              onClick={() => setColumnModal(true)}
            >
              Column Modal
            </h6>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <h6
              class="CursorPointer"
              data-toggle="modal"
              onClick={() => setShareReportModal(true)}
            >
              Share Report
            </h6>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <h6
              class="CursorPointer"
              data-toggle="modal"
              onClick={() => setCreateReportModal(true)}
              data-target="#CreateCustomReport"
            >
              Create Custom Report
            </h6>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <h6
              class="CursorPointer"
              data-toggle="modal"
              onClick={() => setProjectOneModal(true)}
              data-target="#ProjectOneModal"
            >
              Project One Modal
            </h6>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <h6
              class="CursorPointer"
              data-toggle="modal"
              onClick={() => setProjectTwoModal(true)}
              data-target="#ProjectOneModal2"
            >
              Project One Modal 2
            </h6>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <h6
              class="CursorPointer"
              data-toggle="modal"
              onClick={() => setCostDistribution(true)}
            >
              Cost Distribution
            </h6>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <div class="MainTitleBox">
            <div class="MainTitle">
              <h2>System Reports</h2>
            </div>
          </div>

          <div class="ReportTabBox">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link active" href="#Financial" data-toggle="tab">
                  {" "}
                  <span>
                    <img
                      src={require("../../assets/images/financial-icon.png")}
                    />
                  </span>{" "}
                  Financial
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#SatisticalTab" data-toggle="tab">
                  {" "}
                  <span>
                    <img
                      src={require("../../assets/images/satistical-icon.png")}
                    />
                  </span>{" "}
                  Satistical
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#Productivity" data-toggle="tab">
                  {" "}
                  <span>
                    <img
                      src={require("../../assets/images/productivity-icon.png")}
                    />
                  </span>{" "}
                  Productivity
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#Custom" data-toggle="tab">
                  {" "}
                  <span>
                    <img src={require("../../assets/images/custom-icon.png")} />
                  </span>{" "}
                  Custom
                </a>
              </li>
            </ul>

            <div class="FilesArea">
              <div class="FilesHead">
                <div class="FilesLeft">
                  <aside>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search report by name"
                    />
                    <span class="CursorPointer">
                      <img src={require("../../assets/images/search.png")} />
                    </span>
                  </aside>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-content">
            <Financial
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              iState={iState}
              updateState={updateState}
            />
            <Statistical
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              iState={iState}
              updateState={updateState}
            />
            <div class="tab-pane fade" id="Productivity">
              <div class="SalesDocumentArea">
                <div class="row">
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../assets/images/sales-report.png")}
                            />{" "}
                            &nbsp; Sales
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                              <option value="other">Vendor 1</option>
                            </select>
                            <span>
                              <img
                                src={require("../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../assets/images/design-report.png")}
                            />{" "}
                            &nbsp; Design
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../assets/images/estimation-report.png")}
                            />{" "}
                            &nbsp; Estimation
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../assets/images/estimation-report.png")}
                            />{" "}
                            &nbsp; Purchasing
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../assets/images/construction_apps.png")}
                            />{" "}
                            &nbsp; Constrcution
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../assets/images/warranty-reports.png")}
                            />{" "}
                            &nbsp; Warranty
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../assets/images/accounting-report.png")}
                            />{" "}
                            &nbsp; Accounting
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../assets/images/accounting-report.png")}
                            />{" "}
                            &nbsp; Proforma
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../assets/images/electronic-signing.png")}
                            />{" "}
                            &nbsp; Electronic Signing
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../assets/images/job-icon.png")}
                            />{" "}
                            &nbsp; Job
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../assets/images/vendor-icon.png")}
                            />{" "}
                            &nbsp; Vendors
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="FinalcialReportArea" style={{ display: "none" }}>
                <div class="FraHeader">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="BackReportBtn">
                        <img
                          src={require("../../assets/images/left-arrow-red.png")}
                        />
                        Back Report List
                      </a>
                    </li>
                  </ul>
                  <div class="FraHeaderLeft">
                    <h5>
                      <img
                        src={require("../../assets/images/sales-icon.png")}
                      />
                      Sales
                    </h5>
                    <h6>Sales Report 1</h6>
                  </div>
                  <div class="Filter">
                    <div class="form-group">
                      <h6>To-Date</h6>
                      <label class="CheckBox">
                        <input type="checkbox" />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="form-group">
                      <h6>Or</h6>
                      <input type="date" class="form-control" />
                    </div>
                    <div class="form-group">
                      <h6>To</h6>
                      <input type="date" class="form-control" />
                    </div>
                    <a href="javascript:void(0);" class="RunReportBtn">
                      Run Report
                    </a>
                  </div>
                </div>
                <div class="FinalcialReportBox">
                  <div class="FinalcialReportBoxHeader">
                    <a href="javascript:void(0);" class="ReportBtn">
                      {" "}
                      <img
                        src={require("../../assets/images/chart-img.png")}
                      />{" "}
                      Report
                    </a>
                    <a href="javascript:void(0);" class="ChartBtn">
                      {" "}
                      <img
                        src={require("../../assets/images/chart-img.png")}
                      />{" "}
                      Charts
                    </a>
                  </div>
                  <div class="FinalcialReportBoxCenter">
                    <div class="SortByArea">
                      <h6 class="mb-2">
                        <img
                          src={require("../../assets/images/sort-ascending.png")}
                        />
                        Sort
                      </h6>
                      <div class="SortByBox">
                        <h6 class="mb-3">Sort By</h6>
                        <select class="form-control">
                          <option selected="">Column name</option>
                        </select>
                        <div class="form-group mt-3">
                          <h6 class="mb-3">Sort in</h6>
                          <label class="Radio">
                            {" "}
                            Ascending order
                            <input
                              type="radio"
                              name="Cuisine"
                              value="file"
                              checked=""
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="form-group">
                          <label class="Radio">
                            {" "}
                            Descending order
                            <input type="radio" name="Cuisine" value="file" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="FrbcRight">
                      <div class="Actions">
                        <a href="javscript:void(0);">
                          <img
                            src={require("../../assets/images/Action-2.png")}
                          />
                        </a>
                        <a href="javscript:void(0);">
                          <img src={require("../../assets/images/pdf.png")} />
                        </a>
                        <span class="dropdown">
                          <a
                            href="javscript:void(0);"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img
                              src={require("../../assets/images/download.png")}
                            />
                          </a>
                          <div
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a class="dropdown-item" href="#">
                              Action
                            </a>
                            <a class="dropdown-item" href="#">
                              Another action
                            </a>
                            <a class="dropdown-item" href="#">
                              Something else here
                            </a>
                          </div>
                        </span>
                        <a href="javscript:void(0);">
                          <img
                            src={require("../../assets/images/setting.png")}
                          />
                        </a>
                      </div>
                      <figure>
                        <img
                          src={require("../../assets/images/large-logo.png")}
                        />
                      </figure>
                      <h6 class="text-right">January 1 - October 7, 2023</h6>
                    </div>
                  </div>
                  <div class="FinalcialReportBoxBottom">
                    <table>
                      <thead>
                        <tr>
                          <th>Column 1</th>
                          <th>Column 2</th>
                          <th>Column 3</th>
                          <th>Column 4</th>
                          <th>Column 5</th>
                          <th>Column 6</th>
                          <th>Column 7</th>
                        </tr>
                      </thead>
                    </table>
                    <h6>Report</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="Custom">
              <div class="SalesDocumentArea">
                <div class="row">
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../assets/images/sales-report.png")}
                            />{" "}
                            &nbsp; Sales
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                              <option value="other">Vendor 1</option>
                            </select>
                            <span>
                              <img
                                src={require("../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../assets/images/design-report.png")}
                            />{" "}
                            &nbsp; Design
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../assets/images/estimation-report.png")}
                            />{" "}
                            &nbsp; Estimation
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../assets/images/estimation-report.png")}
                            />{" "}
                            &nbsp; Purchasing
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../assets/images/construction_apps.png")}
                            />{" "}
                            &nbsp; Constrcution
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../assets/images/warranty-reports.png")}
                            />{" "}
                            &nbsp; Warranty
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../assets/images/accounting-report.png")}
                            />{" "}
                            &nbsp; Accounting
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../assets/images/accounting-report.png")}
                            />{" "}
                            &nbsp; Proforma
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../assets/images/electronic-signing.png")}
                            />{" "}
                            &nbsp; Electronic Signing
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../assets/images/job-icon.png")}
                            />{" "}
                            &nbsp; Job
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../assets/images/vendor-icon.png")}
                            />{" "}
                            &nbsp; Vendors
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="FinalcialReportArea" style={{ display: "none" }}>
                <div class="FraHeader">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="BackReportBtn">
                        <img
                          src={require("../../assets/images/left-arrow-red.png")}
                        />
                        Back Report List
                      </a>
                    </li>
                  </ul>
                  <div class="FraHeaderLeft">
                    <h5>
                      <img
                        src={require("../../assets/images/sales-icon.png")}
                      />
                      Sales
                    </h5>
                    <h6>Sales Report 1</h6>
                  </div>
                  <div class="Filter">
                    <div class="form-group">
                      <h6>To-Date</h6>
                      <label class="CheckBox">
                        <input type="checkbox" />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="form-group">
                      <h6>Or</h6>
                      <input type="date" class="form-control" />
                    </div>
                    <div class="form-group">
                      <h6>To</h6>
                      <input type="date" class="form-control" />
                    </div>
                    <a href="javascript:void(0);" class="RunReportBtn">
                      Run Report
                    </a>
                  </div>
                </div>
                <div class="FinalcialReportBox">
                  <div class="FinalcialReportBoxHeader">
                    <a href="javascript:void(0);" class="ReportBtn">
                      {" "}
                      <img
                        src={require("../../assets/images/chart-img.png")}
                      />{" "}
                      Report
                    </a>
                    <a href="javascript:void(0);" class="ChartBtn">
                      {" "}
                      <img
                        src={require("../../assets/images/chart-img.png")}
                      />{" "}
                      Charts
                    </a>
                  </div>
                  <div class="FinalcialReportBoxCenter">
                    <div class="SortByArea">
                      <h6 class="mb-2">
                        <img
                          src={require("../../assets/images/sort-ascending.png")}
                        />
                        Sort
                      </h6>
                      <div class="SortByBox">
                        <h6 class="mb-3">Sort By</h6>
                        <select class="form-control">
                          <option selected="">Column name</option>
                        </select>
                        <div class="form-group mt-3">
                          <h6 class="mb-3">Sort in</h6>
                          <label class="Radio">
                            {" "}
                            Ascending order
                            <input
                              type="radio"
                              name="Cuisine"
                              value="file"
                              checked=""
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="form-group">
                          <label class="Radio">
                            {" "}
                            Descending order
                            <input type="radio" name="Cuisine" value="file" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="FrbcRight">
                      <div class="Actions">
                        <a href="javscript:void(0);">
                          <img
                            src={require("../../assets/images/Action-2.png")}
                          />
                        </a>
                        <a href="javscript:void(0);">
                          <img src={require("../../assets/images/pdf.png")} />
                        </a>
                        <span class="dropdown">
                          <a
                            href="javscript:void(0);"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img
                              src={require("../../assets/images/download.png")}
                            />
                          </a>
                          <div
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a class="dropdown-item" href="#">
                              Action
                            </a>
                            <a class="dropdown-item" href="#">
                              Another action
                            </a>
                            <a class="dropdown-item" href="#">
                              Something else here
                            </a>
                          </div>
                        </span>
                        <a href="javscript:void(0);">
                          <img
                            src={require("../../assets/images/setting.png")}
                          />
                        </a>
                      </div>
                      <figure>
                        <img
                          src={require("../../assets/images/large-logo.png")}
                        />
                      </figure>
                      <h6 class="text-right">January 1 - October 7, 2023</h6>
                    </div>
                  </div>
                  <div class="FinalcialReportBoxBottom">
                    <table>
                      <thead>
                        <tr>
                          <th>Column 1</th>
                          <th>Column 2</th>
                          <th>Column 3</th>
                          <th>Column 4</th>
                          <th>Column 5</th>
                          <th>Column 6</th>
                          <th>Column 7</th>
                        </tr>
                      </thead>
                    </table>
                    <h6>Report</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ColumnModal columnModal={columnModal} setColumnModal={setColumnModal} />
      <CostDistribution
        costDistribution={costDistribution}
        setCostDistribution={setCostDistribution}
      />
      <CreateReportModal
        createReportModal={createReportModal}
        setCreateReportModal={setCreateReportModal}
      />
      <ProjectOneModal
        projectOneModal={projectOneModal}
        setProjectOneModal={setProjectOneModal}
      />
      <ProjectTwoModal
        projectTwoModal={projectTwoModal}
        setProjectTwoModal={setProjectTwoModal}
      />
      <ShareReportModal
        shareReportModal={shareReportModal}
        setShareReportModal={setShareReportModal}
      />
    </>
  );
};

export default ReportsMain;
