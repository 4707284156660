import React from 'react'
import Modal from "react-bootstrap/Modal";

const ProjectOneModal = ({projectOneModal, setProjectOneModal}) => {

    const handleAddContactModalClose = () => setProjectOneModal(false);
    const handleAddContactModalShow = () => {
        setProjectOneModal(true);
    };

  return (
<Modal show={projectOneModal} className="ModalBox LargeModal">
    <div class="modal-content">
      <a
        onClick={handleAddContactModalClose}
        class="CloseModal"
        data-dismiss="modal"
      >
        ×
      </a>{" "}
      <div class="FormArea">
              <div class="ModalTitleBox">
                <h4>Project Name &amp; Project # or Job Name &amp; Job #</h4>
                <h4 class="mt-2">Field Variance Purchase Order (FPO’s) Detail By Reason</h4>
              </div>
              <div class="FolderForm">
                <form>
                  <div class="LightGreenTable">
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Reason</th>
                          <th colspan="7">Extra Materials Required</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                          <td>Vendor Name</td>
                          <td>Job #</td>
                          <td>PO #</td>
                          <td>PO Date</td>
                          <td>Issued By</td>
                          <td>PO $</td>
                          <td>$ Amt Paid</td>
                          <td>$ Amt O/S</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Home Depot</td>
                          <td>Job # 1</td>
                          <td>6-101-FPO-2</td>
                          <td>8/12/2020</td>
                          <td>Dale Anderson</td>
                          <td>$ 650.00</td>
                          <td>$ 650.00</td>
                          <td>$ -</td>
                        </tr>
                     
                        <tr>
                          <td></td>
                          <td colspan="8">Etc.,Etc.</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="6">Totals</td>
                          <td>$   18,300.00</td>
                          <td>$   1,250.00</td>
                          <td>$  600.00</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </form>
              </div>
            </div>
    </div>
  </Modal>  )
}

export default ProjectOneModal