import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCommonGarageStep1 } from "../../../redux/action/proformaAction";

const Input = ({
  handleBuildingGarageFieldChange,
  handleShowComponent,
  handleNumGarageBlocksChange,
  numBlocksGarage,
  handleShowComponents,
}) => {
  const dispatch = useDispatch([]);
  const [garage, setGarage] = useState([]);
  useEffect(() => {
    dispatch(getCommonGarageStep1()).then((res) => {
      console.log({ res });
      setGarage(res?.data?.data);
    });
  }, []);
  return (
    <>
      <div>
        <div className="ModalBigheading2 d-flex align-items-center mb-3">
          <h3 className="mb-0">
            Enter Number of Common Garages in the Project
          </h3>
          <input
            className="ModalBigheadingBox"
            type="number"
            value={numBlocksGarage}
            onChange={handleNumGarageBlocksChange}
          />
        </div>
        <div className="EnterProjectTable">
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Garage Name</th>
                <th>Garage Sq /Ft</th>
                <th>
                  Garage Perimeter <br />
                  Lin / Ft
                </th>
                <th>Garage Cubic /Yards</th>
                <th>
                  Driveways &amp; Sidewalks <br />
                  Sq/Ft
                </th>
                <th>
                  Driveways &amp; Sidewalks <br />
                  Concrete Cubic/yards
                </th>
              </tr>
            </thead>
            <tbody>
              {garage?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <div className="form-group mr-5">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Site Office"
                        value={item.garageName}
                        onChange={(e) =>
                          handleBuildingGarageFieldChange(
                            e,
                            index,
                            "garageName"
                          )
                        }
                      />
                    </div>
                  </td>
                  <td></td>
                  <td>
                    <div className="form-group">
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        placeholder="Insert Sq/ Ft"
                        value={item.garageSqFt}
                        onChange={(e) =>
                          handleBuildingGarageFieldChange(
                            e,
                            index,
                            "garageSqFt"
                          )
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <input
                        type="number"
                        min={0}
                        className="form-control width-250"
                        placeholder="Insert cubic / yards"
                        value={item.garagePerimeterLinFt}
                        onChange={(e) =>
                          handleBuildingGarageFieldChange(
                            e,
                            index,
                            "garagePerimeterLinFt"
                          )
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        placeholder="Insert Sq/ Ft"
                        value={item.foundationSqFt}
                        onChange={(e) =>
                          handleBuildingGarageFieldChange(
                            e,
                            index,
                            "foundationSqFt"
                          )
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        placeholder="Insert Sq/ Ft"
                        value={item.garageCubicYard}
                        onChange={(e) =>
                          handleBuildingGarageFieldChange(
                            e,
                            index,
                            "garageCubicYard"
                          )
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        placeholder="Insert cubic / yards"
                        value={item.drivewaysAndSideWalkSqFt}
                        onChange={(e) =>
                          handleBuildingGarageFieldChange(
                            e,
                            index,
                            "drivewaysAndSideWalkSqFt"
                          )
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        placeholder="Insert cubic / yards"
                        value={item.drivew}
                        onChange={(e) =>
                          handleBuildingGarageFieldChange(e, index, "drivew")
                        }
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="PaginationArea px-3">
            <h6>Showing 1 to 10 of 27 entries</h6>
            <h6>
              <img src="images/leftarrow.png" /> 01{" "}
              <img src="images/rightarrow.png" /> Out 10
            </h6>
          </div>
        </div>
      </div>
      <div className="Button d-flex  justify-content-between align-items-center px-5">
        <button
          className="Outline border text-success border-success"
          onClick={() => handleShowComponent("commonBuildingFoundation")}
        >
          Cancel
        </button>
        <button
          className="Create bg-success border border-success"
          onClick={() => handleShowComponents("garageQuanties")}
        >
          Save Draft
        </button>
      </div>
    </>
  );
};

export default Input;
