import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import {toast} from "react-toastify"
import { useDispatch } from "react-redux";



const TaskAssignModal = ({ taskAssignModal, setTaskAssignModal}) => {

  const dispatch = useDispatch()
  const handleTaskAssignModalClose = () => setTaskAssignModal(false);

 





  return (
    <>
      <Modal
        show={taskAssignModal}
        onHide={handleTaskAssignModalClose}
        className="ModalBox ExtraSmallModal"
      >
        <a
          onClick={handleTaskAssignModalClose}
          className="CloseModal"
          data-dismiss="modal"
        >
          ×
        </a>

        <div class="FormArea">
        <div class="ContactTopBox">
          <h6>Task 1.3.1.1.1 <a href="javascript:void(0);" class="FolderPermissionId" data-toggle="modal" data-target="#EditTaskDetails" data-dismiss="modal"><img src="images/edit-33.png" /></a> <br /> Assigned to Vendor 1/Staff member</h6>
        </div>
        <div class="SendEmailText">
          <p><img src="images/send-email.png" />Send E-mail</p>
          <p class="SendText SendText1"><img src="images/send-text.png" />Send Text</p>
          <div class="SendTextSearch SendTextSearch1">
            <input type="text" /><button>Apply</button>
        </div>
        </div>
        <div class="MolalContactForm">
          <h6>Contact</h6>
          <p><img src="images/send-email.png" />johndoe@gmail.com</p>
          <p><img src="images/send-text.png" />+91 990000000</p>
          <a href="#">Show more</a>
        </div>
        <div class="FolderForm">
          <form>
            <div class="form-group">
              <h6>Note <span class="Count">3</span></h6>
              <input type="text" placeholder="Write here ....." class="form-control" />
            </div>
            <div class="form-group">
              <h6>Supporting Document<span class="Count">3</span></h6>
              <div class="PermissionsName">
                  <h5 class="FolderPermissionId" data-toggle="modal" data-target="#AddNewSupportingDocsContactModal" data-dismiss="modal">
                      <span><img src="images/document-support.png" /></span> Supporting Document
                  </h5>
              </div>
          </div>
          <div class="form-group">
            <h6>Set Reminder</h6>
            <div class="PermissionsName">
                <h5 class="FolderPermissionId" data-toggle="modal" data-target="#ReminderModalContact" data-dismiss="modal">
                    <span><img src="images/timer.png" /></span> Set Reminder
                </h5>
            </div>
        </div>
        <div class="form-group">
          <h6>Assign New Resources</h6>
          <div class="PermissionsName">
              <h5 class="FolderPermissionId" data-toggle="modal" data-target="#AssignResourcesContact" data-dismiss="modal">
                  <span><img src="images/icon-32.png" /></span> Assign New Resources
              </h5>
          </div>
      </div>
      <div class="form-group">
        <h6>Create New To-Do Task</h6>
        <div class="PermissionsName">
            <h5 class="FolderPermissionId" data-toggle="modal" data-target="#TodoAddNewTask" data-dismiss="modal">
                <span><img src="images/icon-35.png" /></span> Create New To-Do Task
            </h5>
        </div>
    </div>
    <div class="form-group">
      <h6>Create New Itineraty item</h6>
      <div class="PermissionsName">
          <h5 class="FolderPermissionId" data-toggle="modal" data-target="#AddNewItineraryItem" data-dismiss="modal">
              <span><img src="images/double-check.png" /></span> Create New Itineraty item
          </h5>
      </div>
  </div>
  <div class="form-group">
    <h6>Discussion</h6>
    <input type="text" placeholder="Write here ....." class="form-control" />
  </div>
          </form>
        </div>
      </div>

   
      </Modal>


    </>
  );
};

export default TaskAssignModal;
