
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

import { toast } from "react-toastify";

const units = []


const RejectCtcDocModal = ({rejectCtcDocModal, setrejectCtcDocModal}) => {


  return (
    <>

    <Modal show={rejectCtcDocModal} className="ModalBox SmallModal">
    <div class="modal-content BgCreate">
      <a
        class="CloseModal"
        onClick={()=> setrejectCtcDocModal(false)}
        data-dismiss="modal"
      >
        ×
      </a>
      <div class="FormArea">
              <div class="ModalTitleBox">
                <h4>Reject/Return CTC Doc</h4>
              </div>
              <div class="FolderForm">
                <form>
                      <div class="ModalBigheading">
                        <h3><span>Job Id:</span>U222-CHI-2B-243</h3>
                      </div>
                       
                      <div class="row">
                        <div class="col-md-10">
                          <div class="form-group">
                            <h6>Reject/Return Reason <span class="required">*</span></h6>
                            <div class="CommonSelectBox w-100">
                              <select>
                                <option selected="">Select Country</option>
                                <option>India</option>
                                <option>USA</option>
                                <option>Australia</option>
                              </select>
                            </div>
                          </div>
    
                          <div class="form-group">
                            <h6>Details</h6>
                            <textarea class="form-control" rows="5" placeholder="Insert Rejection Details (Optional)"></textarea>
                          </div>
                        </div>
                      </div>
                     
                      
      
                        <div class="Button d-flex justify-content-between">
                          <button class="Create" data-toggle="modal" data-target="#TwoViewSignClientCtc" data-dismiss="modal">Reject CTC Doc</button>
                          <button class="Outline" data-toggle="modal" data-target="#TwoViewSignClientCtc" data-dismiss="modal">Cancel</button>
                        </div>

                </form>
              </div>
              
            </div>


        
    </div>
  </Modal>



  </>

  


)
}

export default RejectCtcDocModal