import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  editNotificationStatusAction,
  getNestedNotificationAction,
} from "../../redux/action/adminNotificationAction";
import BellConfigure from "./ConfiguredDetails/BellConfigure";
import EmailConfigure from "./ConfiguredDetails/EmailConfigure";
import ItinaryConfigure from "./ConfiguredDetails/ItinaryConfigure";
import TodoConfigure from "./ConfiguredDetails/TodoConfigure";
import TaskConfigure from "./ConfiguredDetails/TaskConfigure";
import BellEdit from "./EditNotification/BellEdit";
import EmailEdit from "./EditNotification/EmailEdit";
import ItinaryEdit from "./EditNotification/ItinaryEdit";
import TaskEdit from "./EditNotification/TaskEdit";
import TodoEdit from "./EditNotification/TodoEdit";
import BellView from "./VIewNotification/BellView";
import EmailView from "./VIewNotification/EmailView";
import TodoView from "./VIewNotification/TodoView";
import TaskView from "./VIewNotification/TaskView";
import ItinaryView from "./VIewNotification/ItinaryView";
import { RoleDropdownAction, UserDropdownAction } from "../../redux/action/dropdownAction";



const initialState={ 
  assignUsers:[],
  assignRole:[],
  stopper:false,
  userId:"",
errors: {},}
const EventTable = ({ element, flag }) => {

  const [iState, updateState] = useState(initialState)
  const {assignUsers,userId,assignRole, errors} = iState
  const [notificationId, setNotificationId] = useState({});
  const {userList,roleList} = useSelector((state)=> state.dropdownReducer)

  const [AssignUserModal, SetAssignUserModal] = useState(false);
  const [AssignRoleModal, SetAssignRoleModal] = useState(false);
  const [CompletionModal, SetCompletionModal] = useState(false);

  const [BellEditModal, SetBellEditModal] = useState(false);
  const [EmailEditModal, SetEmailEditModal] = useState(false);
  const [ItinaryEditModal, SetItinaryEditModal] = useState(false);
  const [TaskEditModal, SetTaskEditModal] = useState(false);
  const [TodoEditModal, SetTodoEditModal] = useState(false);

  const [BellConfigureModal, SetBellConfigureModal] = useState(false);
  const [EmailConfigureModal, SetEmailConfigureModal] = useState(false);
  const [ItinaryConfigureModal, SetItinaryConfigureModal] = useState(false);
  const [TaskConfigureModal, SetTaskConfigureModal] = useState(false);
  const [TodoConfigureModal, SetTodoConfigureModal] = useState(false);

  const [BellViewModal, SetBellViewModal] = useState(false);
  const [EmailViewModal, SetEmailViewModal] = useState(false);
  const [ItinaryViewModal, SetItinaryViewModal] = useState(false);
  const [TaskViewModal, SetTaskViewModal] = useState(false);
  const [TodoViewModal, SetTodoViewModal] = useState(false);

  const [notificationType, setNotificationType] =useState('')

  const handleAssignUserrModalClose = () => {
    SetAssignUserModal(false);
  };
  const handleAssignRoleModalClose = () => {
    SetAssignRoleModal(false);
  };

  const handleCompletionModalClose = () => {
    SetCompletionModal(false);
  };

  const dispatch = useDispatch();

  const [EventData, SetEventData] = useState([]);

  useEffect(() => {
    dispatch(getNestedNotificationAction(element?.id)).then((res) => {
      SetEventData(res.data);
    });
  }, [flag]);

  const handleStatusChange = (item) => {
    dispatch(
      editNotificationStatusAction({
        id: item.id,
        is_enabled: item.is_enabled ? false : true,
      })
    )
      .then((res) => {
        if (res.status === 201) {
          toast.success("Status changed Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });

          dispatch(getNestedNotificationAction(element?.id)).then((res) => {
            SetEventData(res.data);
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleOriginUserStatusChange = (item) => {
    dispatch(
      editNotificationStatusAction({
        id: item.id,
        originUser: item.originUser ? false : true,
      })
    )
      .then((res) => {
        if (res.status === 201) {
          toast.success("Originating User Status changed Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });

          dispatch(getNestedNotificationAction(element?.id)).then((res) => {
            SetEventData(res.data);
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };


  useEffect(()=>{

    dispatch(UserDropdownAction())
    dispatch(RoleDropdownAction())


  },[])




  const handleAddUser=(e)=>{
    e.preventDefault()
    let temp =assignUsers
    temp.push(userId)
    updateState({...iState,assignUsers: temp })

  }

  const handleAddRole=(e)=>{
    e.preventDefault()
    let temp =assignRole
    temp.push(userId)
    updateState({...iState,assignRole: temp ,userId:""})

  }
  const handleRomoveUser=(e,id)=>{
    e.preventDefault()
    let temp =assignUsers
    let index =assignUsers.indexOf(id)

    temp.splice(index, 1);
    updateState({...iState,assignUsers: temp , userId:""})

  }

  const handleRomoveRole=(e,id)=>{
    e.preventDefault()
    let temp =assignRole
    let index =assignRole.indexOf(id)

    temp.splice(index, 1);
    updateState({...iState,assignRole: temp , userId:""})

  }
  const handleValidation = () => {
    let formIsValid = true;
    let error = {};
  
    if (assignUsers.length <1) {
      error.assignUsers = "* Users can't be empty";
      formIsValid = false;
    }
  
    updateState({
      ...iState,
      errors: error,
    });
    return formIsValid;
  };


  const handleValidationRole = () => {
    let formIsValid = true;
    let error = {};
  
    if (assignRole.length <1) {
      error.assignRole = "* Roles can't be empty";
      formIsValid = false;
    }
  
    updateState({
      ...iState,
      errors: error,
    });
    return formIsValid;
  };
  
  


  const handleAssignUser = (e) => {
    e.preventDefault()
    updateState({...iState,stopper:true})
    let formIsValid= handleValidation()

    if(formIsValid){
      dispatch(
        editNotificationStatusAction({
          id: notificationId,
          assignUsers: assignUsers
        })
      )
        .then((res) => {
          if (res.status === 201) {
            toast.success("Users Assigned Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            updateState({...iState,stopper:false, userId:"", assignUsers:[]})
           SetAssignUserModal(false)
  
            dispatch(getNestedNotificationAction(element?.id)).then((res) => {
              SetEventData(res.data);
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
   
  };

  const handleAssignRole = (e) => {
    e.preventDefault()
    updateState({...iState,stopper:true})
    let formIsValid= handleValidationRole()

    if(formIsValid){
      dispatch(
        editNotificationStatusAction({
          id: notificationId,
          assignRole: assignRole
        })
      )
        .then((res) => {
          if (res.status === 201) {
            toast.success("Role Assigned Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            updateState({...iState,stopper:false, userId:"", assignUsers:[]})
           SetAssignRoleModal(false)
  
            dispatch(getNestedNotificationAction(element?.id)).then((res) => {
              SetEventData(res.data);
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
   
  };





  console.log(element,'EEEEEEEEEEEEEEEEEEEEEEEEEEEEEE')




  return (
    <>
      <div className="EstimationTableOverflow">
      <div
        class="EstimationTable FirstTh EstimationTablePadding"
        style={{ display: flag }}
      >
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Notification Message</th>
              <th>Status</th>
              <th>Assigned User(s)</th>
              <th>Assigned Role(s)</th>
              <th>Originating user only</th>
              <th>On Event Completion</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {EventData?.map((item) => (
              <tr>
                <td>
                  <h6>
                    {item?.notificationType?.[0]?.toUpperCase() +
                      item?.notificationType?.slice(1).toLowerCase()}{" "}
                    Notification
                  </h6>{" "}
                  <a href="javascript:void(0);" class="MarginLeft5rem">
                    {item?.notificationType == "BELL" && (
                      <img src="images/type_2.png" />
                    )}

                    {item?.notificationType == "EMAIL" && (
                      <img src="images/type_3.png" />
                    )}

                    {item?.notificationType == "ITINERARY" && (
                      <img src="images/type_4.png" />
                    )}
                    {item?.notificationType == "TASK" && (
                      <img src="images/type_1.png" />
                    )}

                    {item?.notificationType == "TODO" && (
                      <img src="images/type_5.png" />
                    )}
                  </a>
                </td>
                <td>
                  Configured{" "}
                  {item?.notificationType == "BELL" && !item?.is_configured && (
                    <a
                      href="javascript:void(0);"
                      data-toggle="modal"
                      onClick={() => {SetBellConfigureModal(true)
                      
                      setNotificationId(item?.id)}}
                    >
                      <img src="images/Action-1.png" />
                    </a>
                  )}
                  {item?.notificationType == "EMAIL" &&
                    !item?.is_configured && (
                      <a
                        href="javascript:void(0);"
                        data-toggle="modal"
                        onClick={() => {SetEmailConfigureModal(true)
                          setNotificationId(item?.id)}}
                      >
                        <img src="images/Action-1.png" />
                      </a>
                    )}
                  {item?.notificationType == "ITINERARY" &&
                    !item?.is_configured && (
                      <a
                        href="javascript:void(0);"
                        data-toggle="modal"
                        onClick={() => {SetItinaryConfigureModal(true)
                          setNotificationId(item?.id)}}
                      >
                        <img src="images/Action-1.png" />
                      </a>
                    )}
                  {item?.notificationType == "TASK" && !item?.is_configured && (
                    <a
                      href="javascript:void(0);"
                      data-toggle="modal"
                      onClick={() => {SetTaskConfigureModal(true)
                        setNotificationId(item?.id)}}
                    >
                      <img src="images/Action-1.png" />
                    </a>
                  )}
                  {item?.notificationType == "TODO" && !item?.is_configured && (
                    <a
                      href="javascript:void(0);"
                      data-toggle="modal"
                      onClick={() =>{ SetTodoConfigureModal(true)
                        setNotificationId(item?.id)}}
                    >
                      <img src="images/Action-1.png" />
                    </a>
                  )}
                </td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      onChange={() => handleStatusChange(item)}
                      checked={item?.is_enabled}
                    />
                    <span class="slider"></span>
                  </label>
                </td>
                <td>
                  <div class="Actions">
                    <a
                      onClick={() => {SetAssignUserModal(true)
                        setNotificationId(item?.id)
                        setNotificationType(item?.notificationType)
                      }}
                      data-target="#AssignUsers"
                    > 
                    {userList?.filter((ele)=> ele?.id ==item?.assignUsers?.[0])?.map((user)=>
                    <p>{user?.name}</p>)} 

                      <img
                        src={require("../../assets/images/assign_icon.png")}
                      />
                    </a>
                  </div>
                </td>
                <td>
                    {<p>{item?.assignRole?.[0]}</p>} 


                  <a onClick={() => {SetAssignRoleModal(true)
                                          setNotificationId(item?.id)
                                          setNotificationType(item?.notificationType)


                  }}>
                    <img src={require("../../assets/images/estimator.png")} />
                  </a>
                </td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      onChange={() => handleOriginUserStatusChange(item)}
                      checked={item?.originUser}
                    />
                    <span class="slider"></span>
                  </label>
                </td>
                <td>
                  <div class="Actions">
                    <a onClick={() => SetCompletionModal(true)}>
                      <img
                        src={require("../../assets/images/completion.png")}
                      />
                      <span class="Count">3</span>
                    </a>
                  </div>
                </td>
                <td>
                  <div class="TableThreeDots ThreeDotsSwitch text-center">
                    <ul class="">
                      <li class="dropdown">
                        <a
                          class=""
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img src="images/dots.png" />
                        </a>
                        <ol class="dropdown-menu dropdown-menuwidth2">
                          <li>
                            {item?.notificationType == "BELL" && (
                              <a onClick={() => SetBellViewModal(true)}>
                                <img src="images/view-po.png" />
                                View Notification
                              </a>
                            )}
                            {item?.notificationType == "TODO" && (
                              <a onClick={() => SetTodoViewModal(true)}>
                                <img src="images/view-po.png" />
                                View Notification
                              </a>
                            )}

                            {item?.notificationType == "TASK" && (
                              <a onClick={() => SetTaskViewModal(true)}>
                                <img src="images/view-po.png" />
                                View Notification
                              </a>
                            )}

                            {item?.notificationType == "EMAIL" && (
                              <a onClick={() => SetEmailViewModal(true)}>
                                <img src="images/view-po.png" />
                                View Notification
                              </a>
                            )}

                            {item?.notificationType == "ITINERARY" && (
                              <a onClick={() => SetItinaryViewModal(true)}>
                                <img src="images/view-po.png" />
                                View Notification
                              </a>
                            )}
                          </li>
                          <li>
                            {item?.notificationType == "BELL" && (
                              <a onClick={() => SetBellEditModal(true)}>
                                <img src="images/Action-1.png" />
                                Edit Notification
                              </a>
                            )}
                            {item?.notificationType == "TODO" && (
                              <a onClick={() => SetTodoEditModal(true)}>
                                <img src="images/Action-1.png" />
                                Edit Notification
                              </a>
                            )}

                            {item?.notificationType == "TASK" && (
                              <a onClick={() => SetTaskEditModal(true)}>
                                <img src="images/Action-1.png" />
                                Edit Notification
                              </a>
                            )}

                            {item?.notificationType == "EMAIL" && (
                              <a onClick={() => SetEmailEditModal(true)}>
                                <img src="images/Action-1.png" />
                                Edit Notification
                              </a>
                            )}

                            {item?.notificationType == "ITINERARY" && (
                              <a onClick={() => SetItinaryEditModal(true)}>
                                <img src="images/Action-1.png" />
                                Edit Notification
                              </a>
                            )}
                          </li>

                          <li>
                            <a
                              href="Warranty_Work_Order1.html"
                              data-toggle="modal"
                              data-target="OnEventCompletion"
                            >
                              <img
                                src={require("../../assets/images/completion.png")}
                              />
                              On Event Complete Notification
                            </a>
                          </li>
                        </ol>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>

      <Modal show={AssignUserModal} className="ModalBox SmallModal BgClass">
        <a
          onClick={handleAssignUserrModalClose}
          class="CloseModal ClosePermissionId"
        >
          ×
        </a>
        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Assign Users</h4>
          </div>
          <div class="FolderForm">
            <form>
              <div class="row">
                <div class="col-md-8">
                  <div class="form-group">
                    <h6>Event</h6>
                    <h6 class="fw-400 fw-14">{element?.eventName}</h6>
                    </div>
                  <div class="form-group">
                    <h6>Notification Type</h6>
                    <p class="ml-0">{notificationType}</p>
                  </div>
                  <div class="form-group">
                    <h6>Notification Type</h6>
                    <p class="ml-0">Notification</p>
                  </div>
                  <div class="form-group">
                    <h6>
                      Assign Users <span class="required">*</span>
                    </h6>
                    <div class="CommonSelectBox w-100">
                      <select value={userId} onChange={(e)=> updateState({...iState, userId:e.target.value})}>
                        <option value="">
                          Select users to receive notification
                        </option>
                        {userList?.map((user)=>
                            <option value={user?.id}>
                            {user?.name}
                          </option>
                        )}
                      </select>
                
                     
                    </div>
                  </div>
                  {userId &&
                  <div class="Button">
                    <button class="Create" onClick={handleAddUser}>Add User</button>
                  </div>}
                 { assignUsers.length>0 && <div class="form-group">
                    <h4>Assigned Users</h4>
                    {
                    assignUsers && assignUsers.map((id)=>{
                      let userName =userList?.filter((user)=> user?.id == id)

                      return(
                    
                    <div class="FilesAdded">
                    <label>{userName?.[0]?.name}</label>
                    <span onClick={(e)=>handleRomoveUser(e,id)} class="cross">
                      <img src="images/Cross.png" />
                    </span>
                  </div>)}
                    )
                   }
                  </div>}
              
                </div>
                {assignUsers.length<1 && <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.assignUsers}
                      </span>}
              </div>

              <div class="Button d-flex justify-content-between mt-4">
                <button onClick={handleAssignUser} class="Create">Save</button>
                <button class="Outline" onClick={handleAssignUserrModalClose}>Close</button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal show={AssignRoleModal} className="ModalBox SmallModal BgClass">
        <a
          onClick={handleAssignRoleModalClose}
          class="CloseModal ClosePermissionId"
        >
          ×
        </a>
        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Assign Users Roles</h4>
          </div>
          <div class="FolderForm">
            <form>
              <div class="row">
                <div class="col-md-8">
                  <div class="form-group">
                    <h6>Event</h6>
                    <h6 class="fw-400 fw-14">{element?.eventName}</h6>
                  </div>
                  <div class="form-group">
                    <h6>Notification Type</h6>
                    <p class="ml-0">{notificationType}</p>
                  </div>
                 
                  <div class="form-group">
                    <h6>
                      Assign Roles <span class="required">*</span>
                    </h6>
                    <div class="CommonSelectBox w-100">
                    <select value={userId} onChange={(e)=> updateState({...iState, userId:e.target.value})}>
                        <option value="">
                          Select user roles to receive notification
                        </option>
                        {roleList?.map((user)=>
                            <option value={user?.roleName}>
                            {user?.roleName}
                          </option>
                        )}

                      </select>
                
                    </div>
                  </div>
                  {userId &&
                  <div class="Button">
                    <button class="Create" onClick={handleAddRole}>Add User Role</button>
                  </div>}
                 { assignRole.length>0 && <div class="form-group">
                    <h4>Assigned Users</h4>
                    {
                    assignRole && assignRole.map((id)=>{

                      return(
                    
                    <div class="FilesAdded">
                    <label>{id}</label>
                    <span onClick={(e)=>handleRomoveRole(e,id)} class="cross">
                      <img src="images/Cross.png" />
                    </span>
                  </div>)}
                    )
                   }
                  </div>}
                 {assignRole.length<1 && <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.assignRole}
                      </span>}
                </div>
              </div>

              <div class="Button d-flex justify-content-between mt-4">
                <button class="Create" onClick={handleAssignRole}>Save</button>
                <button class="Outline" onClick={handleAssignRoleModalClose}>Close</button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal show={CompletionModal} className="ModalBox LargeModal BgClass">
        <a
          onClick={handleCompletionModalClose}
          class="CloseModal ClosePermissionId"
        >
          ×
        </a>
        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>On Event Completion</h4>
          </div>
          <div class="FolderForm">
            <form>
              <div class="row">
                <div class="col-md-4">
                  <h6 class="mb-0">Event</h6>
                  <h6 class="fw-14 fw-600">Quote Request Returned</h6>
                </div>
                <div class="col-md-4">
                  <h6 class="mb-0">First Level Notification</h6>
                  <h6 class="fw-14 fw-600">
                    Bell Notification{" "}
                    <a href="javascript:void(0);">
                      <img src="images/type_2.png" />
                    </a>
                  </h6>
                </div>
              </div>
              <h6>Triggered Notification</h6>

              <div
                class="EstimationTable FirstTh EstimationTablePadding d-block"
                style={{ display: "none" }}
              >
                <table>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Notification Details</th>
                      <th>Status</th>
                      <th>Assigned User(s)</th>
                      <th>Assigned Role(s)</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <h6>Bell Notification</h6>{" "}
                        <a href="javascript:void(0);"></a>
                      </td>
                      <td>
                        Configured{" "}
                        <a href="javascript:void(0);">
                          <img src="images/Action-1.png" />
                        </a>
                      </td>
                      <td>
                        <label class="switch">
                          <input type="checkbox" />
                          <span class="slider"></span>
                        </label>
                      </td>
                      <td>
                        <div class="Actions">
                          <a href="javascript:void(0);">
                            <img src={require("../../assets/images/assigned-user.png")} />
                          </a>
                        </div>
                      </td>
                      <td>
                        <a href="javascript:void(0);">
                          <img src={require("../../assets/images/estimator.png")} />
                        </a>
                      </td>
                      <td>
                        <div class="TableThreeDots ThreeDotsSwitch text-center">
                          <ul class="">
                            <li class="dropdown">
                              <a
                                class=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol class="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a href="Warranty_Work_Order1.html">
                                    <img src="images/view-po.png" />
                                    View Notification
                                  </a>
                                </li>
                                <li>
                                  <a href="Warranty_Work_Order1.html">
                                    <img src="images/Action-1.png" />
                                    Edit Notification
                                  </a>
                                </li>
                                <li>
                                  <a href="Warranty_Work_Order1.html">
                                    <img src={require("../../assets/images/completion.png")} />
                                    On Event Complete Notification
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6>Email Notification</h6>{" "}
                        <a href="javascript:void(0);"></a>
                      </td>
                      <td>
                        Configured{" "}
                        <a href="javascript:void(0);">
                          <img src="images/Action-1.png" />
                        </a>
                      </td>
                      <td>
                        <label class="switch">
                          <input type="checkbox" />
                          <span class="slider"></span>
                        </label>
                      </td>
                      <td>
                        <div class="Actions">
                          <a href="javascript:void(0);">
                            <img src={require("../../assets/images/assigned-user.png")} />
                          </a>
                        </div>
                      </td>
                      <td>
                        <a href="javascript:void(0);">
                          <img src={require("../../assets/images/estimator.png")} />
                        </a>
                      </td>
                      <td>
                        <div class="TableThreeDots ThreeDotsSwitch text-center">
                          <ul class="">
                            <li class="dropdown">
                              <a
                                class=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol class="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a href="Warranty_Work_Order1.html">
                                    <img src="images/view-po.png" />
                                    View Notification
                                  </a>
                                </li>
                                <li>
                                  <a href="Warranty_Work_Order1.html">
                                    <img src="images/Action-1.png" />
                                    Edit Notification
                                  </a>
                                </li>

                                <li>
                                  <a href="Warranty_Work_Order1.html">
                                    <img src="images/completion.png" />
                                    On Event Complete Notification
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6>Task Notification</h6>{" "}
                        <a href="javascript:void(0);"></a>
                      </td>
                      <td>
                        Configured{" "}
                        <a href="javascript:void(0);">
                          <img src="images/Action-1.png" />
                        </a>
                      </td>
                      <td>
                        <label class="switch">
                          <input type="checkbox" />
                          <span class="slider"></span>
                        </label>
                      </td>
                      <td>
                        <div class="Actions">
                          <a href="javascript:void(0);">
                            <img src={require("../../assets/images/assigned-user.png")} />
                          </a>
                        </div>
                      </td>
                      <td>
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#AssignUsersRoles"
                        >
                          <img src={require("../../assets/images/estimator.png")} />
                        </a>
                      </td>
                      <td>
                        <div class="TableThreeDots ThreeDotsSwitch text-center">
                          <ul class="">
                            <li class="dropdown">
                              <a
                                class=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol class="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a href="Warranty_Work_Order1.html">
                                    <img src="images/view-po.png" />
                                    View Notification
                                  </a>
                                </li>
                                <li>
                                  <a href="Warranty_Work_Order1.html">
                                    <img src="images/Action-1.png" />
                                    Edit Notification
                                  </a>
                                </li>

                                <li>
                                  <a href="Warranty_Work_Order1.html">
                                    <img src="images/completion.png" />
                                    On Event Complete Notification
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6>Itinerary Notification</h6>
                        <a href="javascript:void(0);"></a>
                      </td>
                      <td>
                        Configured{" "}
                        <a href="javascript:void(0);">
                          <img src="images/Action-1.png" />
                        </a>
                      </td>
                      <td>
                        <label class="switch">
                          <input type="checkbox" />
                          <span class="slider"></span>
                        </label>
                      </td>
                      <td>
                        <div class="Actions">
                          <a href="javascript:void(0);">
                            <img src={require("../../assets/images/assigned-user.png")} />
                          </a>
                        </div>
                      </td>
                      <td>
                        <a href="javascript:void(0);">
                          <img src={require("../../assets/images/estimator.png")} />
                        </a>
                      </td>
                      <td>
                        <div class="TableThreeDots ThreeDotsSwitch text-center">
                          <ul class="">
                            <li class="dropdown">
                              <a
                                class=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol class="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a href="Warranty_Work_Order1.html">
                                    <img src="images/view-po.png" />
                                    View Notification
                                  </a>
                                </li>
                                <li>
                                  <a href="Warranty_Work_Order1.html">
                                    <img src="images/Action-1.png" />
                                    Edit Notification
                                  </a>
                                </li>

                                <li>
                                  <a href="Warranty_Work_Order1.html">
                                    <img src="images/completion.png" />
                                    On Event Complete Notification
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6>ToDo Notification</h6>{" "}
                        <a href="javascript:void(0);"></a>
                      </td>
                      <td>
                        <a href="javascript:void(0);">
                          <img src="images/Action-1.png" />
                        </a>
                      </td>
                      <td>
                        <label class="switch">
                          <input type="checkbox" />
                          <span class="slider"></span>
                        </label>
                      </td>
                      <td>
                        <div class="Actions">
                          John Doe
                          <a
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#AssignUsers"
                          >
                            <img src={require("../../assets/images/assigned-user.png")} />
                          </a>
                        </div>
                      </td>
                      <td>
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#AssignUsersRoles"
                        >
                          <img src={require("../../assets/images/estimator.png")} />
                        </a>
                      </td>
                      <td>
                        <div class="TableThreeDots ThreeDotsSwitch text-center">
                          <ul class="">
                            <li class="dropdown">
                              <a
                                class=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol class="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a href="Warranty_Work_Order1.html">
                                    <img src="images/view-po.png" />
                                    View Notification
                                  </a>
                                </li>
                                <li>
                                  <a href="Warranty_Work_Order1.html">
                                    <img src="images/Action-1.png" />
                                    Edit Notification
                                  </a>
                                </li>
                                <li>
                                  <a href="Warranty_Work_Order1.html">
                                    <img src={require("../../assets/images/completion.png")} />
                                    On Event Complete Notification
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="Button text-center mt-4">
                <button class="Outline" data-dismiss="modal">
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <BellConfigure
        BellConfigureModal={BellConfigureModal}

        notificationId={notificationId}
        element={element}
        SetBellConfigureModal={SetBellConfigureModal}
      />

      <EmailConfigure
        EmailConfigureModal={EmailConfigureModal}
        notificationId={notificationId}
        element={element}


        SetEmailConfigureModal={SetEmailConfigureModal}
      />

      <ItinaryConfigure
        ItinaryConfigureModal={ItinaryConfigureModal}
        notificationId={notificationId}
        element={element}


        SetItinarytConfigureModal={SetItinaryConfigureModal}
      />

      <TaskConfigure
        TaskConfigureModal={TaskConfigureModal}
        notificationId={notificationId}
        element={element}


        SetTaskConfigureModal={SetTaskConfigureModal}
      />

      <TodoConfigure
        TodoConfigureModal={TodoConfigureModal}
        notificationId={notificationId}
        element={element}


        SetTodotConfigureModal={SetTodoConfigureModal}
      />
      <BellEdit
        BellEditModal={BellEditModal}
        element={element}

        SetBellEditModal={SetBellEditModal}
      />
      <EmailEdit
              element={element}

        EmailEditModal={EmailEditModal}
        SetEmailEditModal={SetEmailEditModal}
      />
      <ItinaryEdit
              element={element}

        ItinaryEditModal={ItinaryEditModal}
        SetItinaryEditModal={SetItinaryEditModal}
      />
      <TaskEdit
              element={element}

        TaskEditModal={TaskEditModal}
        SetTaskEditModal={SetTaskEditModal}
      />
      <TodoEdit
              element={element}

        TodoEditModal={TodoEditModal}
        SetTodoEditModal={SetTodoEditModal}
      />

      <BellView
              element={element}

        BellViewModal={BellViewModal}
        SetBellViewModal={SetBellViewModal}
      />

      <EmailView
        EmailViewModal={EmailViewModal}
        SetEmailViewModal={SetEmailViewModal}
      />

      <TodoView
              element={element}

        TodoViewModal={TodoViewModal}
        SetTodoViewModal={SetTodoViewModal}
      />

      <TaskView
              element={element}

        TaskViewModal={TaskViewModal}
        SetTaskViewModal={SetTaskViewModal}
      />

      <ItinaryView
              element={element}

        ItinaryViewModal={ItinaryViewModal}
        SetItinaryViewModal={SetItinaryViewModal}
      /> 
    </>
  );
};

export default EventTable;
