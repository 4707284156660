import { companyActionTypes,  } from "../actionTypes";
const initialState = {
  loader: false,
  companyDetailData:{},
  getTaxProfile:[],
};

export default function companyReducer(state = initialState, { type, payload }) {
  switch (type) {
    case companyActionTypes.COMPANY_DETAILS_INITIATE: {
        return {
          ...state,
          companyDetailData: {},
          loader:true
        };
      }
      case companyActionTypes.COMPANY_DETAILS_SUCCESS: {
        return {
          ...state,
          companyDetailData: payload?.company,
          loader:false
        };
      }
      case companyActionTypes.COMPANY_DETAILS_FAILURE: {
        return {
          ...state,
          companyDetailData: payload.company,
          loader:false
        };
      }


      case companyActionTypes.GET_TAX_PROFILE_INITIATE: {
        return {...state,getTaxProfile: [] }
      }
      case companyActionTypes.GET_TAX_PROFILE_SUCCESS: {
         return {...state, getTaxProfile: payload }
      }
      case companyActionTypes.GET_TAX_PROFILE_FAILURE: {
        return { ...state,getTaxProfile:[] }
      }


    default:
      return state;
  }
}
