import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  addUserUrgency,
  addWarranty,
  craeteNewItemType,
  craeteNewServiceType,
  createInspector,
  createRequestItem,
  deleteItemTypes,
  getAllItemTypeList,
  getAllRequestItemList,
  getAllServiceTypeList,
  getAllSystemItemTypeList,
  getAllSystemServiceTypeList,
  getAllSystemUrgencyList,
  getAllUrgencyList,
  getAllUserItemTypeList,
  getAllUserServiceTypeList,
  getAllUserUrgencyList,
  getClearTableData,
  getDeleteRequestItem,
  getDeleteServiceType,
  getDeleteUrgency,
  getDraftWarranty,
  getInspectorList,
  getPrimaryClientByJobs,
  getWarrantyEditableByClient,
  getWarrantySupportDocsUpdate,
  getWarrantyUpdate,
  updateItemTypes,
  updateUrgencys,
  updateWarrantTypes,
  updateWarrantyItem,
} from "../redux/action/warrantyAction";
import { toast } from "react-toastify";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
const initialState = {
  AddNotesModal: false,
  message: "",
  supportingDocFiles: null,
  AddNewSupportingDocsModal: false,
  supportingDocFiless: null,
  AddNewUrgencyModal: false,

  name: "",
  actionId: "",
  showToggleSetting: false,
  ConfigServiceTypesModal: false,

  description: "",
  serviceType: "",
  itemStatus: "",
  inspector: "",
  dateInspected: "",
  ItemDeleteModal: false,
  reqId: "",
  AddItemDocsModal: false,
  itemDocs: "",
  AddItemStatusModal: false,
  AddNewServiceTypeModal: false,
  UserServiceDeleteModal: false,
  ConfigItemTypesModal: false,
  ConfigUrgencyModal: false,
  ConfigUrgencyDeleteModal: false,

  descriptions: "",
  requestDate: "",
  UrgencyId: "",
  SendMailModal: false,
  fullName: "",
  companyName: "",
  phoneNumber: "",
  email: "",
  phonecode: "",
  inspectorName: "",
  serviceName: "",
  itemStatusName: "",
  errors: {},
  itemId: "",
};

const WarrantyRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [iState, updateState] = useState(initialState);
  const {
    AddNotesModal,
    message,
    supportingDocFiles,
    AddNewSupportingDocsModal,
    supportingDocFiless,
    AddNewUrgencyModal,
    name,
    actionId,
    showToggleSetting,
    ConfigServiceTypesModal,

    description,
    serviceType,
    itemStatus,
    inspector,
    dateInspected,
    ItemDeleteModal,
    reqId,
    AddItemDocsModal,
    itemDocs,
    AddItemStatusModal,
    AddNewServiceTypeModal,
    UserServiceDeleteModal,
    ConfigItemTypesModal,
    ConfigUrgencyModal,
    ConfigUrgencyDeleteModal,
    descriptions,
    requestDate,
    UrgencyId,

    SendMailModal,
    fullName,
    companyName,
    phoneNumber,
    email,
    phonecode,
    inspectorName,
    serviceName,
    itemStatusName,
    errors,
    itemId,
  } = iState;

  const {
    draftWarranty,
    allSystemUrgencyList,
    allUrgencyList,
    allRequestItemList,
    allServiceTypeList,
    allItemTypeList,
    allSystemServiceTypeList,
    allUserServiceTypeList,
    allSystemItemTypeList,
    allUserItemTypeList,
    allUserUrgencyList,
    inspectorList,
    primaryClientsDatas,
  } = useSelector((state) => state.warrantyReducer);
  console.log("primaryClientsDatas",primaryClientsDatas);
  const { state } = useLocation();

  useEffect(() => {
    dispatch(getDraftWarranty(state?.ProjectId, state?.id, state?.jobType));
    dispatch(getAllSystemUrgencyList());
    dispatch(getAllUrgencyList());
    dispatch(getAllServiceTypeList());
    dispatch(getAllItemTypeList());
    dispatch(getAllSystemServiceTypeList());
    dispatch(getAllUserServiceTypeList());
    dispatch(getAllSystemItemTypeList());
    dispatch(getAllUserItemTypeList());
    dispatch(getAllUserUrgencyList());
    dispatch(getInspectorList());
    dispatch(getPrimaryClientByJobs({id: state?.id,jobType: state?.jobType,isPrimary: true}));
  }, []);

  useEffect(() => {
    if (draftWarranty?.warranty?.id) {
      dispatch(getAllRequestItemList(draftWarranty.warranty.id));
    }
  }, [dispatch, draftWarranty]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const modifiedValue = value.slice(0, 1000);
    updateState({
      ...iState,
      [name]: modifiedValue,
    });
  };

  const handleInputChanges = (e) => {
    const { name, value } = e.target;
    if (name === "serviceType") {
      let serviceData = allServiceTypeList?.warrantyOptions?.find(
        (item) => item.id == value
      );
      updateState({
        ...iState,
        [name]: value,
        serviceName: serviceData?.name,
      });
    } else if (name === "itemStatus") {
      let itemStatusData = allItemTypeList?.warrantyOptions?.find(
        (item) => item.id == value
      );
      updateState({
        ...iState,
        [name]: value,
        itemStatusName: itemStatusData?.name,
      });
    } else if (name === "inspector") {
      let inspectorData = inspectorList?.approverList?.find(
        (item) => item.id == value
      );
      updateState({
        ...iState,
        [name]: value,
        inspectorName: inspectorData?.name,
      });
    } else {
      updateState({
        ...iState,
        [name]: value,
      });
    }
  };

  const [selectedOption, setSelectedOption] = useState("Text");
  const [textInput, setTextInput] = useState(true);
  const [fileData, setFileData] = useState(false);

  const onValueChange = (event) => {
    updateState({ ...iState, message: "", supportingDocFiles: "" });
    const value = event.target.value;
    setSelectedOption(value);
    setTextInput(value === "Text");
    setFileData(value === "File");
  };

  const handleRemoveClientFile = () => {
    // Reset the 'templateFile' state to null
    updateState({
      ...iState,
      supportingDocFiles: null,
      supportingDocFiless: null,
      itemDocs: null,
    });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#fileInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleAddNotesShow = () => {
    updateState({
      ...iState,
      AddNotesModal: true,
    });
  };
  const handleAddNotesClose = () => {
    updateState({
      ...iState,
      AddNotesModal: false,
    });
  };

  const handleFileChange = (event, type) => {
    const file = event?.target?.files[0];
    if (type === "support") {
      updateState({ ...iState, supportingDocFiles: file });
    }
    if (type === "itemDoc") {
      updateState({ ...iState, itemDocs: file });
    } else {
      updateState({ ...iState, supportingDocFiless: file });
    }
  };

  const handleAddNotes = () => {
    let dataa;
    if (textInput) {
      dataa = {
        warrantyNotes: [{ noteType: "text", message: message }],
        description: draftWarranty?.warranty?.description
          ? draftWarranty?.warranty?.description
          : "N/A",
        // jobType:state?.jobType,
        // jobId:state?.id,
      };
    } else {
      dataa = {
        warrantyNotes: [{ noteType: "files" }],
        supportingDocFiles,
        description: draftWarranty?.warranty?.description
          ? draftWarranty?.warranty?.description
          : "N/A",
        // jobType:state?.jobType,
        // jobId:state?.id,
      };
    }

    dispatch(addWarranty(draftWarranty?.warranty?.id, dataa)).then((res) => {
      if (res.status === 200) {
        toast.success("Add Notes Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AddNotesModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          AddNotesModal: true,
        });
      }
    });
  };

  const handleAddNewSupportingDocsModalShow = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: true,
    });
  };
  const handleAddNewSupportingDocsModalClose = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: false,
      supportingDocFiles,
    });
  };

  const handleSupportDocs = () => {
    let dataa = {
      supportingDocFiles: supportingDocFiless,
    };

    dispatch(
      getWarrantySupportDocsUpdate(draftWarranty?.warranty?.id, dataa)
    ).then((res) => {
      if (res.status === 201) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AddNewSupportingDocsModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          AddNewSupportingDocsModal: true,
        });
      }
    });
  };

  const handleAddItemDocsModalShow = () => {
    updateState({
      ...iState,
      AddItemDocsModal: true,
    });
  };
  const handleAddItemDocsModalClose = () => {
    updateState({
      ...iState,
      AddItemDocsModal: false,
    });
  };

  const handleItemDocs = () => {
    let dataa = {
      supportingDocFiles: itemDocs,
    };

    dispatch(
      getWarrantySupportDocsUpdate(draftWarranty?.warranty?.id, dataa)
    ).then((res) => {
      if (res.status === 201) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AddItemDocsModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          AddItemDocsModal: true,
        });
      }
    });
  };

  const handleAddNewUrgencyModalShow = () => {
    updateState({
      ...iState,
      AddNewUrgencyModal: true,
      ConfigUrgencyModal: false,
    });
  };
  useEffect(() => {
    if (ConfigUrgencyModal) dispatch(getAllUserUrgencyList());
  }, [ConfigUrgencyModal]);
  const handleAddNewUrgencyModalClose = () => {
    updateState({
      ...iState,
      AddNewUrgencyModal: false,
    });
  };

  const handleurgencyAdd = () => {
    let dataa = { name, definedType: "User", configType: "Urgency", actionId };
    dispatch(addUserUrgency(dataa)).then((res) => {
      if (res.status === 201) {
        dispatch(getAllUrgencyList());
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AddNewUrgencyModal: false,
          name: "",
          actionId: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          AddNewUrgencyModal: true,
        });
      }
    });
  };

  const handleToggleSetting = () => {
    updateState({ ...iState, showToggleSetting: !showToggleSetting });
  };

  const handleToggleSettingClose = () => {
    updateState({ ...iState, showToggleSetting: false });
  };

  const handleAConfigServiceTypesModalShow = () => {
    updateState({
      ...iState,
      ConfigServiceTypesModal: true,
    });
  };
  const handleAConfigServiceTypesModalClose = () => {
    updateState({
      ...iState,
      ConfigServiceTypesModal: false,
    });
  };

  const handleAddItem = () => {
    const dataa = {
      description,
      serviceType,
      itemStatus,
      inspector,
      dateInspected,
      inspectorName,
      serviceName,
      itemStatusName,
    };

    const filteredData = Object.fromEntries(
      Object.entries(dataa).filter(([_, value]) => value !== "")
    );

    dispatch(
      createRequestItem(Number(draftWarranty?.warranty?.id), filteredData)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getAllRequestItemList(draftWarranty?.warranty?.id));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          serviceType: "",
          itemStatus: "",
          inspector: "",
          dateInspected: "",
          description: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleClearTable = () => {
    dispatch(getClearTableData(Number(draftWarranty?.warranty?.id))).then(
      (res) => {
        if (res.status === 200) {
          dispatch(getAllRequestItemList(draftWarranty?.warranty?.id));
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  const handleFolderDeleteShow = (id) => {
    updateState({
      ...iState,
      ItemDeleteModal: true,
      reqId: id,
    });
  };

  const handleItemDeleteClose = () => {
    updateState({
      ...iState,
      ItemDeleteModal: false,
      reqId: "",
    });
  };

  const handleFolderDelete = () => {
    let dataa = { reqId };
    dispatch(
      getDeleteRequestItem(Number(draftWarranty?.warranty?.id), dataa)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getAllRequestItemList(draftWarranty?.warranty?.id));
        toast.success(" Delete Folder Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          ItemDeleteModal: false,
          reqId: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          ItemDeleteModal: true,
        });
      }
    });
  };

  const handleCheckboxChange = () => {
    const datta = draftWarranty?.warranty?.editByClient === true ? false : true;
    dispatch(
      getWarrantyEditableByClient(Number(draftWarranty?.warranty?.id), datta)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getDraftWarranty(state?.ProjectId, state?.id, state?.jobType));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleUrgencyClick = (id) => {
    updateState({ ...iState, UrgencyId: id });
  };

  const handleSaveAndEmail = async (type) => {
    let formIsValid = handleValidation();
    if (formIsValid) {
      try {
        const baseData = {
          description: descriptions,
          requestDate,
          UrgencyId,
          sendEmail: type === "email" ? true : false,
          JobId: state?.id,
          JobType: state?.jobType,
          phonecode,
          supportingDocFiles: supportingDocFiless,
        };

        const datta = {
          ...baseData,
          warrantyNotes: textInput
            ? [{ noteType: "text", message: message }]
            : [{ noteType: "files", files: supportingDocFiles }],
          description: draftWarranty?.warranty?.description
            ? draftWarranty?.warranty?.description
            : "N/A",
        };

        const res = await dispatch(
          getWarrantyUpdate(Number(draftWarranty?.warranty?.id), datta)
        );

        if (res && res.status === 200) {
          navigate("/warranty-dashboard", { state });
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState(initialState);
        } else {
          toast.error(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        toast.error(error?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const handleAddItemStatusModalShow = () => {
    updateState({
      ...iState,
      AddItemStatusModal: true,
      ConfigItemTypesModal: false,
    });
  };

  useEffect(() => {
    if (AddItemStatusModal) dispatch(getAllSystemItemTypeList());
    dispatch(getAllUserItemTypeList());
  }, [AddItemStatusModal]);
  const handleAddItemStatusModalClose = () => {
    updateState({
      ...iState,
      AddItemStatusModal: false,
      ConfigItemTypesModal: true,
    });
  };

  const handleItemAddStatus = () => {
    let dataa = { name, definedType: "User", configType: "Item", actionId };
    dispatch(craeteNewItemType(dataa)).then((res) => {
      if (res.status === 201) {
        dispatch(getAllUserServiceTypeList());
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AddItemStatusModal: false,
          name: "",
          actionId: "",
          ConfigItemTypesModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          AddItemStatusModal: true,
        });
      }
    });
  };

  useEffect(() => {
    if (ConfigServiceTypesModal) dispatch(getAllUserServiceTypeList());
    dispatch(getAllSystemUrgencyList());
  }, [ConfigServiceTypesModal]);

  const [sysType, setSysType] = useState("");
  const handleAddNewServiceTypeModalShow = (type) => {
    updateState({
      ...iState,
      AddNewServiceTypeModal: true,
      ConfigServiceTypesModal: false,
    });
    setSysType(type);
  };
  const handleAddNewServiceTypeModalClose = () => {
    updateState({
      ...iState,
      AddNewServiceTypeModal: false,
      ConfigServiceTypesModal: true,
    });
    setSysType();
  };

  const handleServiceAddStatus = () => {
    let dataa = {
      name,
      definedType: sysType,
      configType: "Service",
    };
    if (sysType !== "System") {
      dataa.actionId = actionId;
    }

    dispatch(craeteNewServiceType(dataa)).then((res) => {
      if (res.status === 201) {
        dispatch(getAllUserServiceTypeList());
        dispatch(getAllSystemServiceTypeList());
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AddNewServiceTypeModal: false,
          name: "",
          actionId: "",
          ConfigServiceTypesModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          AddNewServiceTypeModal: true,
        });
      }
    });
  };

  const handleUserServiceDeleteModalShow = (id) => {
    updateState({
      ...iState,
      UserServiceDeleteModal: true,
      ConfigServiceTypesModal: false,
      reqId: id,
    });
  };

  const handleUserServiceDeleteModalClose = () => {
    updateState({
      ...iState,
      UserServiceDeleteModal: false,
      ConfigServiceTypesModal: true,
      reqId: "",
    });
  };

  const handleUserServiceDelete = () => {
    let dataa = { reqId };
    dispatch(getDeleteServiceType(dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(getAllUserServiceTypeList());
        toast.success(" Delete Folder Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          UserServiceDeleteModal: false,
          reqId: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          UserServiceDeleteModal: true,
        });
      }
    });
  };

  const handleConfigItemTypesModalShow = () => {
    updateState({
      ...iState,
      ConfigItemTypesModal: true,
    });
  };
  const handleConfigItemTypesModalClose = () => {
    updateState({
      ...iState,
      ConfigItemTypesModal: false,
    });
  };

  const handleConfigUrgencyModalShow = () => {
    updateState({
      ...iState,
      ConfigUrgencyModal: true,
    });
  };

  const handleConfigUrgencyModalClose = () => {
    updateState({
      ...iState,
      ConfigUrgencyModal: false,
    });
  };

  const handleUrgencyDeleteModalShow = (id) => {
    updateState({
      ...iState,
      ConfigUrgencyDeleteModal: true,
      ConfigUrgencyModal: false,
      reqId: id,
    });
  };

  const handleUrgencyDeleteModalClose = () => {
    updateState({
      ...iState,
      ConfigUrgencyDeleteModal: false,
      ConfigUrgencyModal: true,
      reqId: "",
    });
  };

  const handleUrgencyDelete = () => {
    let dataa = { reqId };
    dispatch(getDeleteUrgency(dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(getAllUserUrgencyList());
        toast.success(" Delete Folder Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          ConfigUrgencyDeleteModal: false,
          ConfigUrgencyModal: true,
          reqId: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          ConfigUrgencyDeleteModal: true,
        });
      }
    });
  };

  const handleSendMailModalShow = () => {
    updateState({ ...iState, SendMailModal: true });
  };

  const handleSendMailModalClose = () => {
    updateState({ ...iState, SendMailModal: false });
  };

  const handleSendMail = () => {
    let dataa = {
      fullName,
      companyName,
      phoneNumber,
      email,
    };
    dispatch(createInspector(draftWarranty?.warranty?.id, dataa)).then(
      (res) => {
        if (res.status === 200) {
          toast.success("Add Notes Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            SendMailModal: false,
            fullName: "",
            companyName: "",
            phoneNumber: "",
            email: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            SendMailModal: true,
          });
        }
      }
    );
  };

  const handlePhoneInputChange = (value, country) => {
    updateState({
      ...iState,
      phonecode: `+${country.dialCode}`,
    });
  };
  let handleValidation = () => {
    let error = {};
    let formIsValid = true;
    if (!requestDate) {
      error.requestDateError = " * Request Date can't be empty";
      formIsValid = false;
    }

    if (!descriptions || !descriptions.trim()) {
      error.descError = " * Description can't be empty";
      formIsValid = false;
    }
    if (!phonecode) {
      error.phonecodeError = " * countrycode  can't be empty";
      formIsValid = false;
    }
    if (!UrgencyId) {
      error.UrgencyIdError = " * Urgency can't be empty";
      formIsValid = false;
    }
    if (!message || !message.trim()) {
      error.messageError = "  Add Notes  can't be empty";
      formIsValid = false;
    }
    if (!supportingDocFiless) {
      error.supportingDocFilesError = " * supportingDocFiles  can't be empty";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: error,
    });
    return formIsValid;
  };

  const handleSetEdit = (data) => {
    updateState({
      ...iState,
      description: data.description,
      serviceType: data.serviceType,
      itemStatus: data.itemStatus,
      inspector: data.inspector,
      itemId: data?.id,
      dateInspected: moment(data.dateInspected).format("YYYY-MM-DD"),
    });
  };

  const handleUpdateItem = () => {
    const dataa = {
      description,
      serviceType,
      itemStatus,
      inspector,
      dateInspected,
      inspectorName,
      serviceName,
      itemStatusName,
    };

    const filteredData = Object.fromEntries(
      Object.entries(dataa).filter(([_, value]) => value !== "")
    );

    dispatch(
      updateWarrantyItem(
        Number(draftWarranty?.warranty?.id),
        itemId,
        filteredData
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getAllRequestItemList(draftWarranty?.warranty?.id));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          serviceType: "",
          itemStatus: "",
          inspector: "",
          dateInspected: "",
          description: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleDeleteItemType = (id) => {
    dispatch(deleteItemTypes(id)).then((res) => {
      if (res.status === 200) {
        dispatch(getAllUserItemTypeList());
        // dispatch(getAllUserServiceTypeList());
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  const [showAction, setShowAction] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const handleServiceTypeUpdate = (id, type) => {
    const data = {
      name: updateName,
    };
    dispatch(updateWarrantTypes(id, data)).then((res) => {
      if (res.status === 200) {
        if (type === "system") {
          dispatch(getAllSystemServiceTypeList());
        } else {
          dispatch(getAllUserServiceTypeList());
        }
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setEditIndex(null);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  const [updateName, setUpdateName] = useState("");
  const handleChange = (e, id) => {
    setUpdateName(e?.target?.value);
  };

  const handleItemTypeUpdate = (id, type) => {
    const data = {
      name: updateName,
    };
    dispatch(updateItemTypes(id, data)).then((res) => {
      if (res.status === 200) {
        if (type === "system") {
          dispatch(getAllSystemItemTypeList());
        } else {
          dispatch(getAllUserItemTypeList());
        }
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setEditIndex(null);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleUrgencyUpdate = (id, type) => {
    const data = {
      name: updateName,
    };
    dispatch(updateUrgencys(id, data)).then((res) => {
      if (res.status === 200) {
        if (type === "system") {
          dispatch(getAllSystemUrgencyList());
        } else {
          dispatch(getAllUserUrgencyList());
        }
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setEditIndex(null);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  return (
    <>
      <Header />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox pb-5">
          <div className="MainTitleBox">
            <div className="MainTitle">
              <h2>New Warranty Request</h2>
            </div>
            <div className="TodosButtons d-flex">
              <div>
                <a onClick={handleAddNotesShow}>
                  <h6 data-toggle="modal">
                    <img src="images/add-notes-1.png" />
                    Add Notes
                  </h6>{" "}
                  <span data-toggle="modal" data-target="#NotesList2">
                    3
                  </span>
                </a>
                <span className="d-flex" style={{ color: "red" }}>
                  {errors?.messageError}
                </span>
              </div>
              <div>
                <a onClick={handleAddNewSupportingDocsModalShow}>
                  <h6 data-toggle="modal">
                    <img src="images/add-docuement-1.png" />
                    Add Supporting documents
                  </h6>
                  <span
                    data-toggle="modal"
                    data-target="#SupportingDocumentList"
                  >
                    3
                  </span>
                </a>
                <span className="d-flex" style={{ color: "red" }}>
                  {errors?.supportingDocFilesError}
                </span>
              </div>
            </div>
          </div>
          <div className="TitleBox">
            <h4 className="Title">
              Warranty Request #{draftWarranty?.warranty?.id}
            </h4>
            <div className="TitleBox2 TitleBoxRight">
              <div className="dropdown">
                <h6 data-toggle="dropdown">{state?.jobNumber ?? "N/A"}</h6>
                <span>{state?.jobType ?? "N/A"}</span>
              </div>
              {/* <a href="#">
                <img src="images/icon-61.png" />
              </a>
              <a href="#" className="mx-3">
                <img src="images/icon-62.png" />
              </a> */}
              <a onClick={() => handleToggleSetting()}>
                <img src="images/Icon-4.png" />
              </a>
            </div>
          </div>
          <div className="WarrantyDashboardForm mb-4">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>
                        Description <span className="Required">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        rows={3}
                        placeholder="Write here ....."
                        name="descriptions"
                        value={descriptions}
                        onChange={handleInputChanges}
                      />
                      <span style={{ color: "red" }}>{errors?.descError}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="WarrantyDashboardForm">
            <h4>Client Details</h4>
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Possession Date</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                        disabled
                        value={primaryClientsDatas?.clientsDetails?.possessionDate ?? "N/A"}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Request Date</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Auto-filled by System"
                        name="requestDate"
                        value={requestDate}
                        onChange={handleInputChanges}
                      />
                      <span style={{ color: "red" }}>
                        {errors?.requestDateError}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Client Full Legal Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                        disabled
                        value={
                          primaryClientsDatas?.clientsDetails?.legalName ?? "N/A"
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label>Country Code</label>
                      <PhoneInput
                        inputClass="CountryCodeInput"
                        countryCodeEditable={false}
                        enableSearch
                        value={phonecode}
                        onChange={handlePhoneInputChange}
                      />
                      <span style={{ color: "red" }}>
                        {errors?.phonecodeError}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                        disabled
                        value={primaryClientsDatas?.clientsDetails?.primaryPhoneNum ?? "N/A"}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Primary Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                        disabled
                        value={primaryClientsDatas?.clientsDetails?.email ?? "N/A"}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        Job Address{" "}
                        <img src="images/location.png" className="ml-2" />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                        disabled
                        value={primaryClientsDatas?.clientsDetails?.streetAddress ?? "N/A"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="UrgencyArea">
                  <div className="form-group">
                    <h6>Urgency</h6>
                    <div className="Categories Categories2">
                      <div className="Categories_all Categories_all2">
                        <span>Select an Option</span>

                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li onClick={handleAddNewUrgencyModalShow}>
                          <span className="AddNew">+</span>Add New
                        </li>
                        {allUrgencyList?.warrantyOptions?.length > 0
                          ? allUrgencyList?.warrantyOptions?.map((item, i) => (
                              <li
                                // value={item.id}
                                key={i}
                                onClick={() => handleUrgencyClick(item.id)}
                                style={{
                                  backgroundColor:
                                    UrgencyId == item.id
                                      ? "#e71e09"
                                      : "transparent",
                                }}
                              >
                                {item.name}
                              </li>
                            ))
                          : "No data found"}
                      </ul>
                      <span style={{ color: "red" }}>
                        {errors?.UrgencyIdError}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="EditableByClient">
            <div className="form-group">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={draftWarranty?.warranty?.editByClient == true}
                  onChange={handleCheckboxChange}
                />
                <span className="slider" />
              </label>
              <h6>Editable by client</h6>
            </div>
          </div>
          <div className="TableList NewTableList TableListHeader TableHeightScroll TableCommonSelect TableCommonSelectNew ">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Description</th>
                  <th>Service Type</th>
                  <th>Work Order #</th>
                  <th>Item Status</th>
                  <th>Inspector</th>
                  <th>Date Inspected</th>
                  <th>Supporting Doc</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allRequestItemList?.data?.length > 0
                  ? allRequestItemList?.data?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.id}</td>
                          <td>{item.description}</td>
                          <td>
                            {/* <div className="SelectAnOption">
                            <div className="dropdown">
                              <button
                                className=""
                                type="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Select an option
                                <img src="images/black-down-arrow.png" />
                              </button>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <a
                                  className="dropdown-item"
                                  onClick={handleAddNewServiceTypeModalShow}
                                >
                                  <img src="images/add-plus.png" />
                                  Add New
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  -Warranty
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  -Courtesy
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  -Maintenance
                                </a>
                              </div>
                            </div>
                          </div> */}
                            {item.serviceTypeOption?.name ?? "N/A"}
                          </td>
                          <td>{item?.workOrderNumber ?? 0}</td>
                          <td>
                            {/* <div className="SelectAnOption">
                            <div className="dropdown">
                              <button
                                className=""
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Select an option{" "}
                                <img src="images/black-down-arrow.png" />
                              </button>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <a
                                  className="dropdown-item"
                                  onClick={handleAddItemStatusModalShow}
                                >
                                  <img src="images/add-plus.png" />
                                  Add New
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  -Warranty
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  -Courtesy
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  -Maintenance
                                </a>
                              </div>
                            </div>
                          </div> */}
                            {item.itemStatusOption?.name ?? "N/A"}
                          </td>

                          <td>
                            {/* <div className="SelectAnOption">
                            <div className="dropdown">
                              <p
                                className=""
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Select an option{" "}
                                <img src="images/black-down-arrow.png" />
                              </p>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <a
                                  className="dropdown-item"
                                  onClick={() => handleSendMailModalShow()}
                                >
                                  <img src="images/add-plus.png" />
                                  Send Email
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  -Warranty
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  -Courtesy
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  -Maintenance
                                </a>
                              </div>
                            </div>
                          </div> */}
                            {item.inspectorUser?.name ?? "N/A"}
                          </td>

                          <td>
                            {moment(item.dateInspected).format("YYYY-MM-DD")}
                          </td>

                          <td>
                            <div className="Actions">
                              <a onClick={handleAddItemDocsModalShow}>
                                <img src="images/icon-63.png" />
                              </a>
                            </div>
                          </td>
                          <td>
                            <div className="DetailsDots">
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu">
                                      {/* {draftWarranty?.warranty?.editByClient ==
                                        false && ( */}
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          onClick={() => handleSetEdit(item)}
                                        >
                                          <img src="images/Action-1.png" />
                                          Edit
                                        </a>
                                      </li>
                                      {/* )} */}

                                      <li>
                                        <a
                                          onClick={() =>
                                            handleFolderDeleteShow(item.id)
                                          }
                                        >
                                          <img src="images/dropdown-delete.png" />
                                          Delete
                                        </a>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : // <div className="text-center w-150">
                    //   <h6>No data found</h6>
                    // </div>
                    ""}

                <tr>
                  <td></td>
                  <td>
                    <div className="form-group">
                      <h6>Description </h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter description"
                        name="description"
                        value={description}
                        onChange={handleInputChanges}
                      />
                    </div>
                  </td>

                  <td>
                    <div className="form-group">
                      <h6>&nbsp;</h6>
                      <div class="CommonSelectBox">
                        <select
                          name="serviceType"
                          value={serviceType}
                          onChange={handleInputChanges}
                        >
                          <option value="">Select Service Type</option>
                          {allServiceTypeList?.warrantyOptions?.length > 0 &&
                            allServiceTypeList?.warrantyOptions?.map(
                              (data, ind) => {
                                return (
                                  <option key={ind} value={data.id}>
                                    {data.name}
                                  </option>
                                );
                              }
                            )}
                        </select>
                        {/* <span>
                          <img src="images/down.png" />
                        </span> */}
                      </div>
                    </div>
                  </td>
                  <td></td>
                  <td>
                    <div className="form-group">
                      <h6>&nbsp;</h6>
                      <div class="CommonSelectBox">
                        <select
                          name="itemStatus"
                          value={itemStatus}
                          onChange={handleInputChanges}
                        >
                          <option value="">Select Item Status</option>
                          {allItemTypeList?.warrantyOptions?.length > 0 &&
                            allItemTypeList?.warrantyOptions?.map(
                              (data, ind) => {
                                return (
                                  <option key={ind} value={data.id}>
                                    {data.name}
                                  </option>
                                );
                              }
                            )}
                        </select>
                        {/* <span>
                        <img src="images/down.png" />
                      </span> */}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <h6>&nbsp;</h6>
                      <div class="CommonSelectBox">
                        <select
                          name="inspector"
                          value={inspector}
                          onChange={handleInputChanges}
                        >
                          <option value="">Select Inspector Name</option>
                          {inspectorList?.approverList?.length > 0 &&
                            inspectorList?.approverList?.map((data, ind) => {
                              return (
                                <option key={ind} value={data.id}>
                                  {data.name}
                                </option>
                              );
                            })}
                        </select>
                        {/* <span>
                        <img src="images/down.png" />
                      </span> */}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <h6>Date Inspected </h6>
                      <input
                        type="date"
                        className="form-control"
                        name="dateInspected"
                        value={dateInspected}
                        onChange={handleInputChanges}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="Actions">
                      <a onClick={handleAddItemDocsModalShow}>
                        <img src="images/icon-63.png" />
                      </a>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan={9}>
                    <div className="TableButtons">
                      <button className="BrandBtn mr-2" onClick={handleAddItem}>
                        Add Item
                      </button>
                      <button
                        className="BrandBtn mr-2"
                        onClick={handleClearTable}
                      >
                        Clear Table
                      </button>
                      <button className="BrandBtn" onClick={handleUpdateItem}>
                        Update Item
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between mt-3 pb-5">
            <button className="BrandBtn">Cancel</button>
            <div className="d-flex ">
              <button
                className="BrandBtnGreen"
                onClick={() => handleSaveAndEmail("save")}
              >
                Save
              </button>
              <div className="dropdown ml-3">
                <button
                  className="BrandBtnGreen"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Save and <img src="images/down-arrow-white.png" />
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a className="dropdown-item">Save and Print</a>
                  <a
                    className="dropdown-item"
                    onClick={() => handleSaveAndEmail("email")}
                  >
                    Save and E-mail
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="DiscussionArea">
      <div class="DiscussionTitle">
        <h4>Discussion</h4>
        <div class="DiscussionChat">
          <img src="images/down.png" class="DiscussionIcon" />
          <a href="#"><img src="images/pdf2.png" /></a>
        </div>
      </div>
      <div class="DiscussionHideArea" style="display: block">
        <div class="DiscussionBox">
          <span>FM</span>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Add a Comment ....."
            />
            <div class="DiscussionOverlay">
              <a href="" class="CancelChat"><img src="images/plus-cancel1.png"></a>
              <a href="" class="SendChat"><img src="images/forwardbutton.png"></a>
            
            </div>
          </div>
        </div>
        <div class="DiscussionBox">
          <span>TH</span>
          <div class="DiscussionRight">
            <h6>Inspections are delayed</h6>
            <small>04/19/2023, at 02:47 pm</small>
          </div>
        </div>
        <div class="ReplyArea">
          <a href="javascript:void(0);">Reply</a>
          <div class="DiscussionBox">
            <span>TH</span>
            <div class="DiscussionRight">
              <h6>Inspections are delayed</h6>
              <small>04/19/2023, at 02:47 pm</small>
            </div>
          </div>
          <div class="DiscussionBox">
            <span>TH</span>
            <div class="DiscussionRight">
              <h6>Inspections are delayed</h6>
              <small>04/19/2023, at 02:47 pm</small>
            </div>
          </div>
        </div>
      </div>
    </div> */}
        </div>
      </div>

      <>
        {showToggleSetting == true && (
          <div
            className="sidenav BoxSettings"
            style={{ width: showToggleSetting == true ? "250px" : "0" }}
          >
            <a className="closebtn" onClick={handleToggleSettingClose}>
              ×
            </a>
            <div className="SideNavTopBox">
              <h1>Settings</h1>
            </div>
            <div className="SideNavBottom">
              <div className="mySettingsWarrantyBox">
                <h6>Config Service Types</h6>
                <a onClick={handleAConfigServiceTypesModalShow}>
                  Config Service Types
                </a>
              </div>
              <div className="mySettingsWarrantyBox">
                <h6>Config Item Status</h6>
                <a onClick={handleConfigItemTypesModalShow}>
                  Config Item Status
                </a>
              </div>
              <div className="mySettingsWarrantyBox">
                <h6>Config Urgency</h6>
                <a onClick={handleConfigUrgencyModalShow}>Config Urgency</a>
              </div>
            </div>
          </div>
        )}
      </>

      <Modal
        className="ModalBox fade SmallModal"
        show={AddNotesModal}
        onHide={handleAddNotesClose}
      >
        <Modal.Body>
          <div className="Category">
            <a className="CloseModal" onClick={handleAddNotesClose}>
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Add New Note</h4>
              </div>
              <h6>Note </h6>
              <div className="JobTitleArea">
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> {state?.jobType ?? "N/A"}
                  </h6>
                </div>
                {/* <div className="JobBox mb-3">
                  <h6>
                    <span>Origin:</span> #185
                  </h6>
                </div> */}
                <h6>Type</h6>

                <div className="FolderForm">
                  <div className="form-group mb-4">
                    <label className="Radio">
                      Text
                      <input
                        type="radio"
                        value="Text"
                        checked={selectedOption === "Text"}
                        onChange={onValueChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="Radio">
                      File
                      <input
                        type="radio"
                        value="File"
                        checked={selectedOption === "File"}
                        onChange={onValueChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>

                  {textInput == true && (
                    <div className="form-group">
                      <h6>Message (Max 1000 chars limit)</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Write here ....."
                        name="message"
                        value={message}
                        onChange={handleInputChange}
                      />
                      <small className="form-text text-right">
                        {1000 - Number(message.length)} characters remaining
                      </small>
                    </div>
                  )}

                  {fileData == true && (
                    <>
                      <div className="form-group">
                        <h6>Select Files</h6>
                        <div className="Upload">
                          <span>-Drag and Drop Files or click to select</span>
                          <input
                            type="file"
                            id="fileInput"
                            onChange={(e) => handleFileChange(e, "support")}
                          />
                        </div>
                      </div>
                      {supportingDocFiles && (
                        <div className="FilesAdded">
                          <label>{supportingDocFiles?.name}</label>
                          <span>
                            <img src="images/fileview.png" alt="File view" />
                          </span>
                          <span onClick={handleRemoveClientFile}>
                            <img
                              src="images/Cross.png"
                              className="cross"
                              alt="Cross"
                            />
                          </span>
                        </div>
                      )}
                    </>
                  )}

                  <div className="Button d-flex justify-content-between mt-4">
                    <button className="Create" onClick={handleAddNotesClose}>
                      Update Note
                    </button>
                    <button className="Outline" onClick={handleAddNotesClose}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="ModalBox fade SmallModal"
        show={AddNewSupportingDocsModal}
        onHide={handleAddNewSupportingDocsModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a
              className="CloseModal"
              onClick={handleAddNewSupportingDocsModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Add New Supporting Docs</h4>
              </div>
              <div className="JobTitleArea">
                <h3>Supporting Documents </h3>
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> {state?.jobType ?? "N/A"}
                  </h6>
                </div>
                {/* <div className="JobBox">
                  <h6>
                    <span>Origin</span> #185
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Item:</span> #143
                  </h6>
                </div> */}
              </div>
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input
                    type="file"
                    id="fileInput"
                    accept=".doc,.docx,.txt,.pdf,.jpeg,.jpg"
                    onChange={(e) => handleFileChange(e, "supportDocs")}
                  />
                </div>
              </div>
              <div className="FilesAddedTitle">
                <h4>Files</h4>
              </div>
              {supportingDocFiless && (
                <div className="FilesAdded">
                  <label>{supportingDocFiless.name}</label>
                  <span>
                    <img src="images/fileview.png" />
                  </span>
                  <span onClick={handleRemoveClientFile}>
                    <img src="images/Cross.png" className="cross" />
                  </span>
                </div>
              )}

              <div className="Button mt-3">
                <button
                  className="Create"
                  onClick={handleAddNewSupportingDocsModalClose}
                >
                  Add Documents
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="ModalBox  fade MediumModal"
        show={AddNewUrgencyModal}
        onHide={handleAddNewUrgencyModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a className="CloseModal" onClick={handleAddNewUrgencyModalClose}>
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Add New Urgency</h4>
              </div>
              <div className="FolderForm">
                <div className="row">
                  <div className="form-group col-md-6">
                    <h6>Urgency Name</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert Urgency Name"
                      name="name"
                      value={name}
                      onChange={handleInputChanges}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <h6>Action #</h6>
                  <div className="CommonSelectBox w-100">
                    <select
                      name="actionId"
                      value={actionId}
                      onChange={handleInputChanges}
                    >
                      <option value="">Select Action #</option>
                      {allSystemUrgencyList?.warrantyOptions?.length > 0 &&
                        allSystemUrgencyList?.warrantyOptions?.map(
                          (item, i) => {
                            return <option value={item.id}>{item.name}</option>;
                          }
                        )}
                    </select>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between mt-5">
                  <button className="Create" onClick={handleurgencyAdd}>
                    Add New Urgency
                  </button>
                  <button
                    className="Outline"
                    onClick={handleAddNewUrgencyModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="ModalBox  fade LargeModal"
        show={ConfigServiceTypesModal}
        onHide={handleAConfigServiceTypesModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a
              className="CloseModal"
              onClick={handleAConfigServiceTypesModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Config Service Types</h4>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="SystemDefineTable">
                    <h4>System Defined Service Types</h4>
                    <div className="TableList ConfigServiceTypes">
                      <table>
                        <thead>
                          <tr>
                            <th>Action #</th>
                            <th>
                              <div className="Actions">Name </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {allSystemServiceTypeList?.warrantyOptions?.length >
                            0 &&
                            allSystemServiceTypeList?.warrantyOptions?.map(
                              (data, ind) => {
                                const isEditing = editIndex === data.id;
                                return (
                                  <tr key={ind}>
                                    <td>{data.id}</td>
                                    <td
                                      style={{
                                        display: "flex",
                                        gap: "10px",
                                      }}
                                    >
                                      {isEditing ? (
                                        <input
                                          type="text"
                                          className="form-control"
                                          style={{ display: "inline-block" }}
                                          value={updateName}
                                          onChange={(e) => {
                                            handleChange(e, data.id);
                                          }}
                                        />
                                      ) : (
                                        data.name
                                      )}
                                      {isEditing ? (
                                        <button
                                          className="UpdateBtn Warranty"
                                          onClick={() =>
                                            handleServiceTypeUpdate(
                                              data?.id,
                                              "system"
                                            )
                                          }
                                        >
                                          Update
                                        </button>
                                      ) : (
                                        <a
                                          onClick={() => {
                                            setEditIndex(data.id);
                                            setUpdateName(data?.name);
                                          }}
                                        >
                                          <img
                                            src="images/edit-32.png"
                                            alt="Edit"
                                          />
                                        </a>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                    </div>
                    <div className="Button mt-2 d-flex justify-content-between">
                      <button
                        type="button"
                        className="Create px-3"
                        onClick={() => {
                          handleAddNewServiceTypeModalShow("System");
                          setShowAction(false);
                        }}
                      >
                        Add line
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ml-auto">
                  <div className="SystemDefineTable">
                    <h4>User Defined Service Types</h4>
                    <div className="TableList ConfigServiceTypes">
                      <table>
                        <thead>
                          <tr>
                            <th>Action #</th>
                            <th>Name</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allUserServiceTypeList?.warrantyOptions?.length >
                            0 &&
                            allUserServiceTypeList?.warrantyOptions?.map(
                              (data, ind) => {
                                const isEditing = editIndex === data.id;
                                return (
                                  <tr key={ind}>
                                    <td>
                                      <div className="SelectAction_All CursorPointer">
                                        {/* Select Action #  */}
                                        {data.id}
                                        {/* <img src="images/down.png" /> */}
                                      </div>
                                    </td>
                                    <td> {isEditing ? (
                                        <input
                                          type="text"
                                          className="form-control"
                                          style={{ display: "inline-block" }}
                                          value={updateName}
                                          onChange={(e) => {
                                            handleChange(e, data.id);
                                          }}
                                        />
                                      ) : (
                                        data.name
                                      )}</td>
                                    <td style={{
                                      display:"flex",
                                      gap:"10px",
                                      alignItems: "center"
                                    }}>
                                      <a
                                        className="mr-2"
                                        
                                        onClick={() =>
                                          handleUserServiceDeleteModalShow(
                                            data.id
                                          )
                                        }
                                      >
                                        <img src="images/delete.png" style={{

                                        }} />
                                      </a>
                                      {isEditing ? (
                                        <button
                                          className="UpdateBtn Warranty"
                                          onClick={() =>
                                            handleServiceTypeUpdate(
                                              data?.id,
                                              "user"
                                            )
                                          }
                                        >
                                          Update
                                        </button>
                                      ) : (
                                        <a
                                          onClick={() => {
                                            setEditIndex(data.id);
                                            setUpdateName(data?.name);
                                          }}
                                        >
                                          <img
                                            src="images/edit-32.png"
                                            alt="Edit"
                                          />
                                        </a>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="SelectAction_Main d-flex">
                      <div className="SelectAction">
                        <ul>
                          <li>-01</li>
                          <li>-02</li>
                          <li>-03</li>
                        </ul>
                      </div>
                    </div> */}
                    <div className="Button mt-2 d-flex justify-content-between">
                      <button
                        type="button"
                        className="Create px-3"
                        onClick={() => {
                          handleAddNewServiceTypeModalShow("User");
                          setShowAction(true);
                        }}
                      >
                        Add line
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="ModalBox  fade LargeModal"
        show={ConfigItemTypesModal}
        onHide={handleConfigItemTypesModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a className="CloseModal" onClick={handleConfigItemTypesModalClose}>
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Config Item Status</h4>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="SystemDefineTable">
                    <h4>System Defined Item Types</h4>
                    <div className="TableList ConfigServiceTypes">
                      <table>
                        <thead>
                          <tr>
                            <th>Action #</th>
                            <th>
                              <div className="Actions">Name </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {allSystemItemTypeList?.warrantyOptions?.length > 0 &&
                            allSystemItemTypeList?.warrantyOptions?.map(
                              (data, ind) => {
                                const isEditing = editIndex === data.id;
                                return (
                                  <tr key={ind}>
                                    <td>{data.id}</td>
                                    <td
                                      style={{
                                        display: "flex",
                                        gap: "10px",
                                      }}
                                    >
                                      {isEditing ? (
                                        <input
                                          type="text"
                                          className="form-control"
                                          style={{ display: "inline-block" }}
                                          value={updateName}
                                          onChange={(e) => {
                                            handleChange(e, data.id);
                                          }}
                                        />
                                      ) : (
                                        data.name
                                      )}
                                      {isEditing ? (
                                        <button
                                          className="UpdateBtn Warranty"
                                          onClick={() =>
                                            handleItemTypeUpdate(
                                              data?.id,
                                              "system"
                                            )
                                          }
                                        >
                                          Update
                                        </button>
                                      ) : (
                                        <a
                                          onClick={() => {
                                            setEditIndex(data.id);
                                            setUpdateName(data?.name);
                                          }}
                                        >
                                          <img
                                            src="images/edit-32.png"
                                            alt="Edit"
                                          />
                                        </a>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ml-auto">
                  <div className="SystemDefineTable">
                    <h4>User Defined Item Types</h4>
                    <div className="TableList ConfigServiceTypes">
                      <table>
                        <thead>
                          <tr>
                            <th>Action #</th>
                            <th>Name</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allUserItemTypeList?.warrantyOptions?.length > 0 &&
                            allUserItemTypeList?.warrantyOptions?.map(
                              (data, ind) => {
                                const isEditing = editIndex === data.id;
                                return (
                                  <tr key={ind}>
                                    <td>
                                      <div className="SelectAction_All CursorPointer">
                                        {/* Select Action #  */}
                                        {data.id}
                                        {/* <img src="images/down.png" /> */}
                                      </div>
                                    </td>
                                    
                                    <td> {isEditing ? (
                                        <input
                                          type="text"
                                          className="form-control"
                                          style={{ display: "inline-block" }}
                                          value={updateName}
                                          onChange={(e) => {
                                            handleChange(e, data.id);
                                          }}
                                        />
                                      ) : (
                                        data.name
                                      )}</td>
                                    <td style={{
                                      display:"flex",
                                      gap:"10px",
                                      alignItems: "center"
                                    }}>
                                      <a
                                        className="mr-2"
                                        
                                        onClick={() =>
                                          handleDeleteItemType(
                                            data.id
                                          )
                                        }
                                      >
                                        <img src="images/delete.png" style={{

                                        }} />
                                      </a>
                                      {isEditing ? (
                                        <button
                                          className="UpdateBtn Warranty"
                                          onClick={() =>
                                            handleItemTypeUpdate(
                                              data?.id,
                                              "user"
                                            )
                                          }
                                        >
                                          Update
                                        </button>
                                      ) : (
                                        <a
                                          onClick={() => {
                                            setEditIndex(data.id);
                                            setUpdateName(data?.name);
                                          }}
                                        >
                                          <img
                                            src="images/edit-32.png"
                                            alt="Edit"
                                          />
                                        </a>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="SelectAction_Main d-flex">
                      <div className="SelectAction">
                        <ul>
                          <li>-01</li>
                          <li>-02</li>
                          <li>-03</li>
                        </ul>
                      </div>
                    </div> */}
                    <div className="Button mt-2 d-flex justify-content-between">
                      <button
                        type="button"
                        className="Create px-3"
                        onClick={handleAddItemStatusModalShow}
                      >
                        Add line
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ItemDeleteModal}
        onHide={handleItemDeleteClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleItemDeleteClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Delete</h4>
                </div>
                <div className="FolderForm">
                  <div className="my-5">
                    <h6>Are you sure you want to delete this ?</h6>
                  </div>
                  <div className="Button d-flex justify-content-between">
                    <button className="Create" onClick={handleFolderDelete}>
                      Delete
                    </button>
                    <button className="Outline" onClick={handleItemDeleteClose}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="ModalBox fade SmallModal"
        show={AddItemDocsModal}
        onHide={handleAddItemDocsModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a className="CloseModal" onClick={handleAddItemDocsModalClose}>
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Add New Supporting Docs</h4>
              </div>
              <div className="JobTitleArea">
                <h3>Supporting Documents #</h3>
                <div className="JobBox">
                  <h6>
                    <span>Job:</span>
                    {state?.jobType}
                  </h6>
                </div>
                <div className="JobBox">
                  {/* <h6>
                    <span>Origin</span> #185
                  </h6> */}
                </div>
                <div className="JobBox">
                  {/* <h6>
                    <span>Item:</span> #143
                  </h6> */}
                </div>
              </div>
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input
                    type="file"
                    id="itemDocumnet"
                    onChange={(e) => handleFileChange(e, "itemDoc")}
                  />
                </div>
              </div>
              <div className="FilesAddedTitle">
                <h4>Files</h4>
              </div>
              {itemDocs && (
                <div className="FilesAdded">
                  <label>{itemDocs.name}</label>
                  <span>
                    <img src="images/fileview.png" />
                  </span>
                  <span onClick={handleRemoveClientFile}>
                    <img src="images/Cross.png" className="cross" />
                  </span>
                </div>
              )}

              <div className="Button mt-3">
                <button className="Create" onClick={handleItemDocs}>
                  Add Documents
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="ModalBox  fade MediumModal"
        show={AddItemStatusModal}
        onHide={handleAddItemStatusModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a className="CloseModal" onClick={handleAddItemStatusModalClose}>
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Add New Item Status</h4>
              </div>
              <div className="FolderForm">
                <div className="row">
                  <div className="form-group col-md-6">
                    <h6>Item Status Name</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert Status Name"
                      name="name"
                      value={name}
                      onChange={handleInputChanges}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <h6>Action #</h6>
                  <div className="CommonSelectBox w-100">
                    <select
                      name="actionId"
                      value={actionId}
                      onChange={handleInputChanges}
                    >
                      <option value="">Select Action #</option>
                      {allItemTypeList?.warrantyOptions?.length > 0 &&
                        allItemTypeList?.warrantyOptions?.map((item, i) => {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                    </select>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between mt-5">
                  <button className="Create" onClick={handleItemAddStatus}>
                    Add New Item Status
                  </button>
                  <button
                    className="Outline"
                    onClick={handleAddItemStatusModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="ModalBox  fade MediumModal"
        show={AddNewServiceTypeModal}
        onHide={handleAddNewServiceTypeModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a
              className="CloseModal"
              onClick={handleAddNewServiceTypeModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Add New Service Type</h4>
              </div>
              <div className="FolderForm">
                <div className="row">
                  <div className="form-group col-md-6">
                    <h6>Service Type Name</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=" Service Type Name"
                      name="name"
                      value={name}
                      onChange={handleInputChanges}
                    />
                  </div>
                </div>
                {showAction && (
                  <div className="form-group">
                    <h6>Action #</h6>
                    <div className="CommonSelectBox w-100">
                      <select
                        name="actionId"
                        value={actionId}
                        onChange={handleInputChanges}
                      >
                        <option value="">Select Action #</option>
                        {allServiceTypeList?.warrantyOptions?.length > 0 &&
                          allServiceTypeList?.warrantyOptions?.map(
                            (item, i) => {
                              return (
                                <option value={item.id} key={i}>
                                  {item.name}
                                </option>
                              );
                            }
                          )}
                      </select>
                    </div>
                  </div>
                )}
                <div className="Button d-flex justify-content-between mt-5">
                  <button className="Create" onClick={handleServiceAddStatus}>
                    Add New Service Type
                  </button>
                  <button
                    className="Outline"
                    onClick={handleAddNewServiceTypeModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={UserServiceDeleteModal}
        onHide={handleUserServiceDeleteModalClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={handleUserServiceDeleteModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Delete</h4>
                </div>
                <div className="FolderForm">
                  <div className="my-5">
                    <h6>Are you sure you want to delete this ?</h6>
                  </div>
                  <div className="Button d-flex justify-content-between">
                    <button
                      className="Create"
                      onClick={handleUserServiceDelete}
                    >
                      Delete
                    </button>
                    <button
                      className="Outline"
                      onClick={handleUserServiceDeleteModalClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="ModalBox  fade LargeModal"
        show={ConfigUrgencyModal}
        onHide={handleConfigUrgencyModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a className="CloseModal" onClick={handleConfigUrgencyModalClose}>
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Config Urgency</h4>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="SystemDefineTable">
                    <h4>System Defined Service Types</h4>
                    <div className="TableList ConfigServiceTypes">
                      <table>
                        <thead>
                          <tr>
                            <th>Action #</th>
                            <th>
                              <div className="Actions">Name </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {allSystemUrgencyList?.warrantyOptions?.length > 0 &&
                            allSystemUrgencyList?.warrantyOptions?.map(
                              (data, ind) => {
                                const isEditing = editIndex === data.id;
                                return (
                                  <tr key={ind}>
                                    <td>{data.id}</td>
                                    <td
                                      style={{
                                        display: "flex",
                                        gap: "10px",
                                      }}
                                    >
                                      {isEditing ? (
                                        <input
                                          type="text"
                                          className="form-control"
                                          style={{ display: "inline-block" }}
                                          value={updateName}
                                          onChange={(e) => {
                                            handleChange(e, data.id);
                                          }}
                                        />
                                      ) : (
                                        data.name
                                      )}
                                      {isEditing ? (
                                        <button
                                          className="UpdateBtn Warranty"
                                          onClick={() =>
                                            handleUrgencyUpdate(
                                              data?.id,
                                              "system"
                                            )
                                          }
                                        >
                                          Update
                                        </button>
                                      ) : (
                                        <a
                                          onClick={() => {
                                            setEditIndex(data.id);
                                            setUpdateName(data?.name);
                                          }}
                                        >
                                          <img
                                            src="images/edit-32.png"
                                            alt="Edit"
                                          />
                                        </a>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ml-auto">
                  <div className="SystemDefineTable">
                    <h4>User Defined Service Types</h4>
                    <div className="TableList ConfigServiceTypes">
                      <table>
                        <thead>
                          <tr>
                            <th>Action #</th>
                            <th>Name</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allUserUrgencyList?.warrantyOptions?.length > 0 &&
                            allUserUrgencyList?.warrantyOptions?.map(
                              (data, ind) => {
                                const isEditing = editIndex === data.id;
                                return (
                                  <tr key={ind}>
                                    <td>
                                      <div className="SelectAction_All CursorPointer">
                                        {/* Select Action #  */}
                                        {data.id}
                                        {/* <img src="images/down.png" /> */}
                                      </div>
                                    </td>
                                   
                                    <td> {isEditing ? (
                                        <input
                                          type="text"
                                          className="form-control"
                                          style={{ display: "inline-block" }}
                                          value={updateName}
                                          onChange={(e) => {
                                            handleChange(e, data.id);
                                          }}
                                        />
                                      ) : (
                                        data.name
                                      )}</td>
                                    <td style={{
                                      display:"flex",
                                      gap:"10px",
                                      alignItems: "center"
                                    }}>
                                      <a
                                        className="mr-2"
                                        
                                        onClick={() =>
                                          handleUrgencyDeleteModalShow(
                                            data.id
                                          )
                                        }
                                      >
                                        <img src="images/delete.png" style={{

                                        }} />
                                      </a>
                                      {isEditing ? (
                                        <button
                                          className="UpdateBtn Warranty"
                                          onClick={() =>
                                            handleUrgencyUpdate(
                                              data?.id,
                                              "user"
                                            )
                                          }
                                        >
                                          Update
                                        </button>
                                      ) : (
                                        <a
                                          onClick={() => {
                                            setEditIndex(data.id);
                                            setUpdateName(data?.name);
                                          }}
                                        >
                                          <img
                                            src="images/edit-32.png"
                                            alt="Edit"
                                          />
                                        </a>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="SelectAction_Main d-flex">
                      <div className="SelectAction">
                        <ul>
                          <li>-01</li>
                          <li>-02</li>
                          <li>-03</li>
                        </ul>
                      </div>
                    </div> */}
                    <div className="Button mt-2 d-flex justify-content-between">
                      <button
                        type="button"
                        className="Create px-3"
                        onClick={handleAddNewUrgencyModalShow}
                      >
                        Add line
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ConfigUrgencyDeleteModal}
        onHide={handleUrgencyDeleteModalClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleUrgencyDeleteModalClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Delete</h4>
                </div>
                <div className="FolderForm">
                  <div className="my-5">
                    <h6>Are you sure you want to delete this ?</h6>
                  </div>
                  <div className="Button d-flex justify-content-between">
                    <button className="Create" onClick={handleUrgencyDelete}>
                      Delete
                    </button>
                    <button
                      className="Outline"
                      onClick={handleUrgencyDeleteModalClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={SendMailModal}
        onHide={handleSendMailModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleSendMailModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Item# 1 Send Email</h4>
            </div>
            <div className="FolderForm">
              <div className="FormTitleBox">
                <div className="FormTitle">
                  <h4>Inspector Contact Information</h4>
                </div>
              </div>
              <div className="CommonForm">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Full Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Full name"
                        name="fullName"
                        value={fullName}
                        onChange={handleInputChanges}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Company</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Company"
                        name="companyName"
                        value={companyName}
                        onChange={handleInputChanges}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Phone Number</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Phone number"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={handleInputChanges}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Email"
                        name="email"
                        value={email}
                        onChange={handleInputChanges}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="Button text-center mt-4">
                      <button className="Create" onClick={handleSendMail}>
                        Send E-mail
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WarrantyRequest;
