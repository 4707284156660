import React from 'react'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux';

const CopyFromJob = ({CopyFromJobModal,setCopyFromJobModal,setAddSchedule}) => {



  const {  jobList } = useSelector(
    (state) => state.constructionReducer
  );


    const handleDefaultSystemModalClose = () =>{ 
        setCopyFromJobModal(false)
        setAddSchedule(true)

    }


  return (

    <Modal
    show={CopyFromJobModal}
    onHide={handleDefaultSystemModalClose}
    className="ModalBox SmallModal"
  >
    <div class="modal-content">
      <a
        onClick={handleDefaultSystemModalClose}
        className="CloseModal"
        data-dismiss="modal"
      >
        ×
      </a>

      <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Copy From Job</h4>
          </div>
          <div class="FolderForm">
            <form>

              <div class="form-group mb-4">
                <h6>Job Selection</h6>
                <label class="Radio">Text
                  <input type="radio" name="Cuisine" value="text" /> 
                  <span class="checkmark"></span>
                </label>
              </div>

              <div class="form-group">
                <label class="Radio">File
                  <input type="radio" name="Cuisine" value="file" />
                  <span class="checkmark"></span>
                </label>
              </div>
              
              <div class="form-group">
                
                
                <div class="Categories Categories3">
                  <div class="Categories_all Categories_all3">
                    <span>Select a folder to insert the file</span>
                    <img src="images/down.png" />
                  </div>
                  <ul style={{display: "block"}}>
                    <li>
                      <input type="text" placeholder="-Search" />
                      <span class="Search"><img src="images/search.png" /></span>
                    </li>
                    <li>- List of Jobs already filtered</li>
                  </ul>
                </div>
              </div>

              
              <div class="form-group">
                <h6>Phase Selection</h6>
                <div class="Categories Categories2">
                  <div class="Categories_all Categories_all2">
                    <span>Select Phases to be used in the schedule generation</span>
                    <img src="images/down.png" />
                  </div>
                  <ul style={{display: "block"}}>
                    <li>
                      <label class="CheckBox">All
                        <input type="checkbox" />
                        <span class="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label class="CheckBox">Full List of phases loaded by the system
                        <input type="checkbox" />
                        <span class="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="Button mb-4">
                <button class="Create">Confirm Selection</button>
              </div>
              <div class="PhaseActionTable">
                <table>
                  <thead>
                    <tr>
                      <th>Selected Phases</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Site Preparation (11 tasks)</td>
                      <td>
                        <div class="Actions">
                          <a href="javascript:void(0);" data-toggle="modal" data-target="#ConstructionEditPhase2" data-dismiss="modal"><img src="images/document-edit.png" /></a>
                          <a href="javascript:void(0);"><img src="images/fileview.png"/></a>
                          <a href="javascript:void(0);"><img src="images/Cross.png" /></a>
                        </div>
                      </td>
                      </tr>
                      <tr>
                      <td>Survey &amp; Stake, Order Materials (6 tasks )</td>
                      <td>
                        <div class="Actions">
                          <a href="javascript:void(0);" data-toggle="modal" data-target="#ConstructionEditPhase2" data-dismiss="modal"><img src="images/document-edit.png"/></a>
                          <a href="javascript:void(0);"><img src="images/fileview.png" /></a>
                          <a href="javascript:void(0);"><img src="images/Cross.png" /></a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="Button d-flex justify-content-between mt-4">
                <button class="Create" data-toggle="modal" data-target="#ConstructionScheduleGeneration" data-dismiss="modal">Add Copied Schedule</button>
                <button class="Outline" data-toggle="modal" data-target="#ConstructionScheduleGeneration" data-dismiss="modal">Cancel</button>
              </div>
            </form>
          </div>
        </div>


      </div>
    </Modal>
  )
}

export default CopyFromJob