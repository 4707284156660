import React, { Component } from 'react';
import { gantt } from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import { useDispatch } from 'react-redux';
import { AddGanttAction } from '../../redux/action/constructionsAction';
import { Url } from '../../Config/Config';
import { isLoggedIn } from '../../utils/tokenCheck';
import axios from 'axios';





export default class Gantt extends Component {


  // instance of gantt.dataProcessor
  dataProcessor = null;


  initZoom() {
    gantt.ext.zoom.init({
      levels: [
        {
          name: 'Hours',
          scale_height: 60,
          min_column_width: 30,
          scales: [
            { unit: 'day', step: 1, format: '%d %M' },
            { unit: 'hour', step: 1, format: '%H' }
          ]
        },
        {
          name: 'Days',
          scale_height: 60,
          min_column_width: 70,     
          scales: [
            { unit: 'week', step: 1, format: 'Week #%W' },
            { unit: 'day', step: 1, format: '%d %M' }
          ]
        },
        {
          name: 'Months',
          scale_height: 60,
          min_column_width: 70,
          scales: [
            { unit: "month", step: 1, format: '%F' },
            { unit: 'week', step: 1, format: '#%W' }
          ]
        }
      ]
    });
  }


  


  setZoom(value) {
    if(!gantt.ext.zoom.getLevels()){
      this.initZoom();
    }
    gantt.ext.zoom.setLevel(value);
  }



  initGanttDataProcessor() {
    /**
     * type: "task"|"link"
     * action: "create"|"update"|"delete"
     * item: data object object
     */
    const onDataUpdated = this.props.onDataUpdated;
    this.dataProcessor = gantt.createDataProcessor((type, action, item, id) => {


      const token = isLoggedIn("adminData1");





      if(action =="create"){
        let data ={ jobId: this.props.jobId,
          parent: item?.parent,
          endDate: item?.end_date,
          startDate: item?.start_date,
          duration: item?.duration,
          text: item?.text,
          progress: item?.progress


}

        try {
          const token = isLoggedIn("adminData1");
          const response =  axios.post(`${Url}construction/createPhaseGant`,data, {
            headers: { Authorization: `Bearer ${token}` },
          });
        } catch (err) {
          return err.response;
        }
      }

      if(action =="update"){

        try {
          const token = isLoggedIn("adminData1");
          const response =  axios.post(`${Url}construction/editGantTask`,{id:id}, {
            headers: { Authorization: `Bearer ${token}` },
          });
        } catch (err) {
          return err.response;
        }
      }

      if(action =="delete"){

        try {
          const token = isLoggedIn("adminData1");
          const response =  axios.post(`${Url}construction/deleteGantTask`,{id:id}, {
            headers: { Authorization: `Bearer ${token}` },
          });
        } catch (err) {
          return err.response;
        }
      }





      return new Promise((resolve, reject) => {
        if (onDataUpdated) {
          onDataUpdated(type, action, item, id);
        }

        // if onDataUpdated changes returns a permanent id of the created item, you can return it from here so dhtmlxGantt could apply it
        // resolve({id: databaseId});
        return resolve();
      });
    });
  }

  shouldComponentUpdate(nextProps) {
    return this.props.zoom !== nextProps.zoom;
  }

  

  componentDidMount() {

    gantt.config.order_branch = true;
    







//********************************************* */

    gantt.config.date_format = "%Y-%m-%d %H:%i";
    const { tasks ,SetPhaseTaskManagementModal} = this.props;
    gantt.init(this.ganttContainer);
    this.initGanttDataProcessor();
    gantt.parse(tasks);
    // gantt.config.rtl = true;


  //   gantt.config.layout = {
  //     css: "gantt_container",
  //     rows: [
  //         {
  //             cols: [
  //                 {view: "scrollbar", id: "scrollVer"},
  //                 {view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer"},
  //                 {resizer: true, width: 100},
  //                 {view: "grid", scrollX: "scrollHor", scrollY: "scrollVer"}
  //             ]
  //         }
  //     ]
  // };



   

    gantt.config.columns = [
      {name:"add", width:40, label:"Action"},
      {name:"wbs", label:"WBS", width:20, template:gantt.getWBSCode }, 
      {name:"text", label:"Task name", tree:true, width:150 },
      {name:"start_date",label:"Start Date" ,align:"center", width: 90},
      {name:"end_date", label:"End Date", align:"center", width: 90},
      // {
      //   name: "buttons", label: "Assigned", width: 120, template: function () {
      //       const readonly = `<img src="images/icon-31.png"  />`;
      //       return  readonly;
      //   }},
      // {name:"predecessors", label:"Predecessors", align:"center", width: 160},
      // {name:"progress", label:"Progress", align:"center", width: 60},

      // {name:"compeltion_date", label:"Completion Date", align:"center", width: 180},
      // {name:"Ok_to_pay", label:"Okay To Pay", align:"center", width: 160},

   
      

  ];

  function modifyTask(e) {
    console.log("modify called",e,e?.stopPropagation)
    e?.preventDefault?.()

    SetPhaseTaskManagementModal(true)

  }

 


  }

  

  componentWillUnmount() {
    if (this.dataProcessor) {
      this.dataProcessor.destructor();
      this.dataProcessor = null;
    }
  }

  render() {
    const { zoom } = this.props;
    this.setZoom(zoom);
    console.log("Gantt chart 2");
    return (
      <div
        ref={(input) => { this.ganttContainer = input }}
        style={{ width: '100%', height: '100%' }}
      ></div>
    );
  }
}