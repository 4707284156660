import React, { useState, useEffect } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import "../App.css";
import taskSquare from "../assets/images/task-square.png";
import tasking1 from "../assets/images/taskimg-1.png";
import construction from "../assets/images/construction.png";
import box1 from "../assets/images/box-1.png";
import box2 from "../assets/images/box-2.png";
import taskHistory from "../assets/images/task-history.png";
import exportToCalendar from "../assets/images/export-to-calendar.png";
import rightarrow2 from "../assets/images/rightarrow2.png";
import googleCalendar from "../assets/images/google-calendar.png";
import outlookCalendar from "../assets/images/outlook-calendar.png";
import SetReminder from "../assets/images/set-reminder.png";
import emailCalendar from "../assets/images/email-calendar.png";
import next_calendar from "../assets/images/next_calendar.png";
import OwlCarousel from "react-owl-carousel2";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";


import {
  getListProjectJobs,
  getWarrantyJobs,
  showInWarranty,
} from "../redux/action/warrantyAction";
import {
  getAllLandDevelopmentHomelListAction,
  getAllMultiFamilyHomelListAction,
  getAllResidentaiHomelListAction,
  getAllTaskHomelListAction,
  getAllTodoHomelListAction,
  getIternaryHomeList,
  getLandDevelopmentProjectList,
  getLandPhaseList,
  getMultiBlocksList,
  getMultiFamilyProjectList,
} from "../redux/action/homeAction";

import {
  deleteArchiveJobs,
  getAllListProjectJobs,
  getAllProjects,
} from "../redux/action/modelsAction";
import { checkArray } from "../utils/CheckType";
import { toast } from "react-toastify";
import ProjectDetailsModal from "./DashboardModal/ProjectDetailsModal";
import EditJobModal from "./DashboardModal/EditJobModal";
import CreateBlock from "./DashboardModal/CreateBlock";
import CreateUnits from "./DashboardModal/CreateUnits";
import CreateOnlyUnits from "./DashboardModal/createOnlyUnit";
import JobDetailsForBlock from "./DashboardModal/JobDetailsForBlock";
import JobDetailsForUnit from "./DashboardModal/JobDetailsForUnit";
import CreatePhaseModal from "./DashboardModal/LandDevelopment/CreatePhaseModal";
import CreateLotModal from "./DashboardModal/LandDevelopment/CreateLotModal";
import LandDevPhaseDetails from "./DashboardModal/LandDevelopment/LandDevPhaseDetails";
import { getAllProjectListAction } from "../redux/action/jobAction";
import ResidentialJobModal from "./ResidentialJobModal";
import ResidentialDetails from "./DashboardModal/ResidentialModal/ResidentialDetails";
import ResidentialUpdate from "./DashboardModal/ResidentialModal/ResidentialUpdate";

const initLeadState = {
  ProjectId: "",
};

const constructionInit = {
  jobListShow: false,
  insideJobListShow: false,
  changeOrderShow: false,
  designSelectionShow: false,
  clientViewConfigShow: false,
  accountingShow: false,
  warrantyShow: false,
  estimationShow: false,
  constructionShow: false,
  resdentalId: -1,

  multiWarrantyShow: false,
  multiJobUnitOpenIndex: -1,
  multiConstructionShow: false,
  multiDesignShow: false,
  multiChangeorderShow: false,
  multiEstimationShow: false,
  multiAccountingShow: false,
  multiClientViewConfigShow: false,

  landWarrantyShow: false,
  landJobUnitOpenIndex: -1,
  landConstructionShow: false,
  landDesignShow: false,
  landChangeorderShow: false,
  landEstimationShow: false,
  landAccountingShow: false,
  landClientViewConfigShow: false,
  landId: -1,
};

function Home() {
  const [units, setUnits] = useState([]);

  const [show, setShow] = useState(false)
  const [projectDetailsModal, setJobDetialsModal] = useState(false);
  const [editJobModal, setEditJobModal] = useState(false);
  const [jobDetailsForBlock, setJobDetailsForBlock] = useState(false)
  const [jobDetailsForUnit, setJobDetailsForUnit] = useState(false)
  const [jobData, setJobData] =useState({})

  const [selectedId, setSelectedId] = useState("");
  const [jobId, setJobId] = useState("");
  const [jobName, setJobName] = useState("");
  const [iLeadState, updateLeadState] = useState(initLeadState);
  const { ProjectId } = iLeadState;
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const [iState, updateState] = useState(constructionInit);
  const [createUnitsModal, setCreateUnitsModal] = useState(false);
  const [createLotModal ,setCreateLotModal] = useState(false);
  const [landDevPhaseDetailsModal,setLandDevPhaseDetailsModal] = useState(false);
  const [residentialDetailsModal,setResidentialDetailsModal] = useState(false)
  

  const {
    jobListShow, 
    insideJobListShow,
    estimationShow,
    changeOrderShow,
    designSelectionShow,
    clientViewConfigShow,
    accountingShow,
    warrantyShow,
    constructionShow,
    resdentalId,

    multiWarrantyShow,
    multiJobUnitOpenIndex,
    multiConstructionShow,
    multiDesignShow,
    multiChangeorderShow,
    multiEstimationShow,
    multiAccountingShow,
    multiClientViewConfigShow,

    landWarrantyShow,
    landJobUnitOpenIndex,
    landConstructionShow,
    landDesignShow,
    landChangeorderShow,
    landEstimationShow,
    landAccountingShow,
    landClientViewConfigShow,
    landId,
  } = iState;
  const dispatch = useDispatch();
  const {
    residentialHomeListData,
    landDevelopmentHomeListData,
    multiFamilyHomeListData,
    taskHomeListData,
    todoHomeListData,
    iternaryHomeListData,
    multiblocksList,
    multiFamilyProjectList,
    landDevelopmentProjectList,
    landPhaseList,
  } = useSelector((state) => state.homeReducer);

  const { projectsList, listProjectJobs } = useSelector(
    (state) => state.modelsReducer
  );

  const options = {
    items: 3,
    nav: true,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
    rewind: true,
    autoplay: false,
    margin: 20,
  };

  const { projectJobList, warrantyJobsList } = useSelector(
    (state) => state.warrantyReducer
  );
  // console.log("warrantyJobsList", warrantyJobsList);
  const [createBlockModal, setCreateBlockModal] = useState(false);
  const [createOnlyUnitsModal, setCreateOnlyUnitsModal] = useState(false);

  const [createPhaseModal, setcreatePhaseModal] = useState(false)

  const [residentialJobModal,setResidentialJobModal]=useState(false)



  const [UnitData, setUnitData] = useState({});

  useEffect(() => {
    if (iBuildLocalData?.user?.CompanyId && ProjectId != null) {
      // dispatch(getListProjectJobs(iBuildLocalData.user.CompanyId));
      dispatch(getAllLandDevelopmentHomelListAction(ProjectId));
      dispatch(getAllMultiFamilyHomelListAction(ProjectId));
      dispatch(getAllResidentaiHomelListAction(ProjectId));
      dispatch(getAllTodoHomelListAction(ProjectId));
      dispatch(getAllTaskHomelListAction(ProjectId));
      dispatch(getIternaryHomeList());
    }
  }, [ProjectId, dispatch]);

  const handleWarrantyShow = () => {
    updateState({
      ...iState,
      warrantyShow: !warrantyShow,
      changeOrderShow: false,
      constructionShow: false,
      designSelectionShow: false,
      clientViewConfigShow: false,
      accountingShow: false,
      estimationShow: false,
    });
  };

  useEffect(() => {
    if (warrantyShow) {
      dispatch(getAllProjects(ProjectId));
      dispatch(getWarrantyJobs());
    }
  }, [warrantyShow, ProjectId]);

  const handleChangeOrderShow = () => {
    updateState({
      ...iState,
      changeOrderShow: !changeOrderShow,
      warrantyShow: false,
      constructionShow: false,
      designSelectionShow: false,
      clientViewConfigShow: false,
      accountingShow: false,
      estimationShow: false,
    });
  };

  useEffect(() => {
    if (changeOrderShow) {
      dispatch(getAllProjects(ProjectId));
    }
  }, [changeOrderShow, ProjectId]);

  const handleConstructionShow = () => {
    updateState({
      ...iState,
      constructionShow: !constructionShow,
      warrantyShow: false,
      changeOrderShow: false,
      designSelectionShow: false,
      clientViewConfigShow: false,
      accountingShow: false,
      estimationShow: false,
    });
  };

  useEffect(() => {
    if (constructionShow) {
      dispatch(getAllProjects(ProjectId));
    }
  }, [constructionShow, ProjectId]);

  const handleChildData = (data) => {
    dispatch(getListProjectJobs(iBuildLocalData?.user?.CompanyId));
    updateLeadState({ ...iLeadState, ProjectId: data });
  };

  const handleDesignSelectionShow = () => {
    // dispatch(getListProjectJobs(iBuildLocalData.user.CompanyId));
    updateState({
      ...iState,
      designSelectionShow: !designSelectionShow,
      warrantyShow: false,
      changeOrderShow: false,
      constructionShow: false,
      clientViewConfigShow: false,
      accountingShow: false,
      estimationShow: false,
    });
  };

  useEffect(() => {
    if (designSelectionShow) {
      dispatch(getAllProjects(ProjectId));
    }
  }, [designSelectionShow, ProjectId]);

  const handleClientViewConfigShow = () => {
    // dispatch(getListProjectJobs(iBuildLocalData.user.CompanyId));
    updateState({
      ...iState,
      clientViewConfigShow: !clientViewConfigShow,
      warrantyShow: false,
      changeOrderShow: false,
      constructionShow: false,
      accountingShow: false,
      estimationShow: false,
    });
  };

  useEffect(() => {
    if (clientViewConfigShow) {
      dispatch(getAllProjects(ProjectId));
    }
  }, [clientViewConfigShow, ProjectId]);

  const handleAccountingShow = () => {
    // dispatch(getListProjectJobs(iBuildLocalData.user.CompanyId));
    updateState({
      ...iState,
      accountingShow: !accountingShow,
      warrantyShow: false,
      changeOrderShow: false,
      constructionShow: false,
      clientViewConfigShow: false,
      estimationShow: false,
    });
  };

  useEffect(
    () => {
      if (accountingShow) {
        dispatch(getAllProjects(ProjectId));
      }
    },
    [accountingShow],
    ProjectId
  );

  const handleEstimationShow = () => {
    // dispatch(getListProjectJobs(iBuildLocalData.user.CompanyId));
    updateState({
      ...iState,
      estimationShow: !estimationShow,
      warrantyShow: false,
      changeOrderShow: false,
      constructionShow: false,
      clientViewConfigShow: false,
      accountingShow: false,
    });
  };

  useEffect(() => {
    if (estimationShow) {
      dispatch(getAllProjects(ProjectId));
    }
  }, [estimationShow, ProjectId]);

  const handleJobListByProject = (Id, Index) => {
    dispatch(getAllListProjectJobs(Id));
    updateState((prev) => ({
      ...prev,
      resdentalId: prev.resdentalId == Index ? -1 : Index,
    }));
  };

  //=======================multi-family==========================

  const handleMultiWarrantyShow = () => {
    updateState({
      ...iState,
      multiConstructionShow: false,
      multiChangeorderShow: false,
      multiDesignShow: false,
      multiEstimationShow: false,
      multiWarrantyShow: !multiWarrantyShow,
      multiAccountingShow: false,
      multiClientViewConfigShow: false,
    });
  };

  useEffect(() => {
    if (multiWarrantyShow) {
      // dispatch(getMultiFamilyProjectList());
      dispatch(getWarrantyJobs());
    }
  }, [multiWarrantyShow]);

  const handleMultiBlockListByProject = (Id, Index) => {
    dispatch(getMultiBlocksList(Id));
    updateState((prev) => ({
      ...prev,
      resdentalId: prev.resdentalId == Index ? -1 : Index,
    }));
  };

  useEffect(() => {
    if (multiblocksList?.length > 0) {
      // console.log(
      //   "=====multiblocksListmultiblocksListmultiblocksListmultiblocksList===>",
      //   multiblocksList
      // );
    }
  }, [multiblocksList]);

  const handleMultiConstructionShow = () => {
    updateState({
      ...iState,
      multiConstructionShow: !multiConstructionShow,
      multiChangeorderShow: false,
      multiDesignShow: false,
      multiEstimationShow: false,
      multiWarrantyShow: false,
      multiAccountingShow: false,
      multiClientViewConfigShow: false,
    });
  };

  useEffect(() => {
    if (multiConstructionShow) {
      dispatch(getMultiFamilyProjectList(ProjectId));
    }
  }, [multiConstructionShow, ProjectId]);

  const handleMultiDesignnShow = () => {
    updateState({
      ...iState,
      multiConstructionShow: false,
      multiChangeorderShow: false,
      multiDesignShow: !multiDesignShow,
      multiEstimationShow: false,
      multiWarrantyShow: false,
      multiAccountingShow: false,
      multiClientViewConfigShow: false,
    });
  };

  useEffect(() => {
    if (multiDesignShow) {
      dispatch(getMultiFamilyProjectList(ProjectId));
    }
  }, [multiDesignShow, ProjectId]);

  const handleMultiChangeOrderShow = () => {
    updateState({
      ...iState,
      multiConstructionShow: false,
      multiChangeorderShow: !multiChangeorderShow,
      multiDesignShow: false,
      multiEstimationShow: false,
      multiWarrantyShow: false,
      multiAccountingShow: false,
      multiClientViewConfigShow: false,
    });
  };

  useEffect(() => {
    if (multiChangeorderShow) {
      dispatch(getMultiFamilyProjectList(ProjectId));
    }
  }, [multiChangeorderShow, ProjectId]);

  const handleMultiEstimationrShow = () => {
    updateState({
      ...iState,
      multiConstructionShow: false,
      multiChangeorderShow: false,
      multiDesignShow: false,
      multiEstimationShow: !multiEstimationShow,
      multiWarrantyShow: false,
      multiAccountingShow: false,
      multiClientViewConfigShow: false,
    });
  };

  useEffect(() => {
    if (multiEstimationShow) {
      dispatch(getMultiFamilyProjectList(ProjectId));
    }
  }, [multiEstimationShow, ProjectId]);

  const handleMultiAccountingShow = () => {
    updateState({
      ...iState,
      multiConstructionShow: false,
      multiChangeorderShow: false,
      multiDesignShow: false,
      multiEstimationShow: false,
      multiWarrantyShow: false,
      multiAccountingShow: !multiAccountingShow,
      multiClientViewConfigShow: false,
    });
  };

  useEffect(() => {
    if (multiAccountingShow) {
      dispatch(getMultiFamilyProjectList(ProjectId));
    }
  }, [multiAccountingShow, ProjectId]);

  const handleMultiClientViewConfigShow = () => {
    updateState({
      ...iState,
      multiConstructionShow: false,
      multiChangeorderShow: false,
      multiDesignShow: false,
      multiEstimationShow: false,
      multiWarrantyShow: false,
      multiAccountingShow: false,
      multiClientViewConfigShow: !multiAccountingShow,
    });
  };

  useEffect(() => {
    if (multiClientViewConfigShow) {
      dispatch(getMultiFamilyProjectList(ProjectId));
    }
  }, [multiClientViewConfigShow, ProjectId]);
  //===================================land-devlopment===================

  const handleLandWarrantyShow = () => {
    updateState({
      ...iState,
      landWarrantyShow: !landWarrantyShow,
      landConstructionShow: false,
      landDesignShow: false,
      landChangeorderShow: false,
      landEstimationShow: false,
      landAccountingShow: false,
      landClientViewConfigShow: false,
    });
  };

  useEffect(() => {
    if (landWarrantyShow) {
      // dispatch(getLandDevelopmentProjectList());
      dispatch(getWarrantyJobs());
    }
  }, [landWarrantyShow]);

  const handleLandConstructionShow = () => {
    updateState({
      ...iState,
      landWarrantyShow: false,
      landConstructionShow: !landConstructionShow,
      landDesignShow: false,
      landChangeorderShow: false,
      landEstimationShow: false,
      landAccountingShow: false,
      landClientViewConfigShow: false,
    });
  };

  useEffect(() => {
    if (landConstructionShow) {
      dispatch(getLandDevelopmentProjectList(ProjectId));
    }
  }, [landConstructionShow, ProjectId]);

  const handleLandDesignShow = () => {
    updateState({
      ...iState,
      landWarrantyShow: false,
      landConstructionShow: false,
      landDesignShow: !landDesignShow,
      landChangeorderShow: false,
      landEstimationShow: false,
      landAccountingShow: false,
      landClientViewConfigShow: false,
    });
  };

  useEffect(() => {
    if (landDesignShow) {
      dispatch(getLandDevelopmentProjectList(ProjectId));
    }
  }, [landDesignShow, ProjectId]);

  const handleLandChangeorderShow = () => {
    updateState({
      ...iState,
      landWarrantyShow: false,
      landConstructionShow: false,
      landDesignShow: false,
      landChangeorderShow: !landChangeorderShow,
      landEstimationShow: false,
      landAccountingShow: false,
      landClientViewConfigShow: false,
    });
  };

  useEffect(() => {
    if (landChangeorderShow) {
      dispatch(getLandDevelopmentProjectList(ProjectId));
    }
  }, [landChangeorderShow, ProjectId]);

  const handleLandEstimationShow = () => {
    updateState({
      ...iState,
      landWarrantyShow: false,
      landConstructionShow: false,
      landDesignShow: false,
      landChangeorderShow: false,
      landEstimationShow: !landEstimationShow,
      landAccountingShow: false,
      landClientViewConfigShow: false,
    });
  };

  useEffect(() => {
    if (landEstimationShow) {
      dispatch(getLandDevelopmentProjectList(ProjectId));
    }
  }, [landEstimationShow, ProjectId]);

  const handleLandAccountingShow = () => {
    updateState({
      ...iState,
      landWarrantyShow: false,
      landConstructionShow: false,
      landDesignShow: false,
      landChangeorderShow: false,
      landEstimationShow: false,
      landAccountingShow: !landAccountingShow,
      landClientViewConfigShow: false,
    });
  };

  useEffect(() => {
    if (landAccountingShow) {
      dispatch(getLandDevelopmentProjectList(ProjectId));
    }
  }, [landAccountingShow, ProjectId]);

  const handleLandClientViewConfigShow = () => {
    updateState({
      ...iState,
      landWarrantyShow: false,
      landConstructionShow: false,
      landDesignShow: false,
      landChangeorderShow: false,
      landEstimationShow: false,
      landAccountingShow: false,
      landClientViewConfigShow: !landClientViewConfigShow,
    });
  };

  useEffect(() => {
    if (landClientViewConfigShow) {
      dispatch(getLandDevelopmentProjectList(ProjectId));
    }
  }, [landClientViewConfigShow, ProjectId]);

  //=========================================================
  const handleLandPhaseListByProject = (Id, Index) => {
    dispatch(getLandPhaseList(Id));
    updateState((prev) => ({
      ...prev,
      landId: prev.landId == Index ? -1 : Index,
    }));
  };

  const handleShowInWarranty = (id, type, types) => {
    let dataa = { jobId: id, jobType: type };
    dispatch(showInWarranty(dataa)).then((res) => {
      if (res.status === 200) {
        if (types === "residential") {
          dispatch(getAllProjects(ProjectId));
        } else if (types === "multi") {
          dispatch(getMultiFamilyProjectList(ProjectId));
        } else {
          dispatch(getLandDevelopmentProjectList(ProjectId));
        }
        toast.success("Move To Warranty", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleArchiveJobs = (id, type, types) => {
    let dataa = { jobId: id, jobType: type };
    dispatch(deleteArchiveJobs(dataa)).then((res) => {
      if (res.status === 200) {
        if (types === "residential") {
          dispatch(getAllProjects(ProjectId));
        } else if (types === "multi") {
          dispatch(getMultiFamilyProjectList(ProjectId));
        } else {
          dispatch(getLandDevelopmentProjectList(ProjectId));
        }
        toast.success("Deleted Archive Job", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const showResidentialJobFunc = () => {
    dispatch(getAllProjectListAction("","Single Family"))
    setResidentialJobModal(true)
  }




  console.log(jobData ,'JOB =======================>')
   return (
    <>
      <div>
        <Header sendDataToParent={handleChildData} data="dfkldsjflsd" />
        <SideNav />
        <div className="WrapperArea">
          <div className="WrapperBox">
            <div className="MainTitle2">
              <h2>Welcome,</h2>
            </div>
            <div className="CommonBoxArea">
              <OwlCarousel options={options}>
                <div className="item">
                  {/* <Link to="/individual_components_part2_User-WorkflowTasks"> */}
                  <Link to="/individual-components-part-User-WorkflowTasks">
                    <div className="CommonBox">
                      <div className="CommonBoxHeader">
                        <h6>Task</h6>
                        <h5>{taskHomeListData?.task?.count}</h5>
                        <figure>
                          <img src={taskSquare} />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(taskHomeListData?.task?.task) &&
                          taskHomeListData?.task?.task?.map((ele, i) => {
                            return (
                              <>
                                <div className="CommonBoxBodyUser">
                                  <div className="CommonBoxBodyTitle">
                                    <span>MB</span>
                                    <div className="">
                                      <h6>{ele?.description}</h6>
                                      <p>{ele?.JobType}</p>
                                    </div>
                                  </div>
                                  <figure>
                                    <img src={tasking1} />
                                  </figure>
                                </div>
                              </>
                            );
                          })}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>{taskHomeListData?.task?.count}</h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="item">
                  <Link to="/notification">
                    <div className="CommonBox">
                      <div className="CommonBoxHeader">
                        <h6>Notification</h6>
                        <h5>0</h5>
                        <figure>
                          <img src={construction} />
                        </figure>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="item">
                  <Link to="/todos">
                    <div className="CommonBox">
                      <div className="CommonBoxHeader">
                        <h6>Todo</h6>
                        <h5>{todoHomeListData?.todo?.count}</h5>
                        <figure>
                          <img src={taskSquare} />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(todoHomeListData?.todo?.todo) &&
                          todoHomeListData?.todo?.todo?.map((ele, i) => {
                            return (
                              <>
                                <div className="CommonBoxBodyUser">
                                  <div className="CommonBoxBodyTitle">
                                    <span>MB</span>
                                    <div className="">
                                      <h6>{ele?.description}</h6>
                                      <p>{ele?.JobType}</p>
                                    </div>
                                  </div>
                                  <figure>
                                    <img src={tasking1} />
                                  </figure>
                                </div>
                              </>
                            );
                          })}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>{todoHomeListData?.todo?.count}</h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="item">
                  <Link to="/itinerary">
                    <div className="CommonBox">
                      <div className="CommonBoxHeader">
                        <h6>Itinerary</h6>
                        <h5>{iternaryHomeListData?.itineraries?.count ?? 0}</h5>
                        <figure>
                          <img src={construction} />
                        </figure>
                      </div>
                      {iternaryHomeListData?.itineraries?.itineraries?.length >
                        0 &&
                        iternaryHomeListData?.itineraries?.itineraries?.map(
                          (item, ind) => {
                            return (
                              <>
                                <div className="CommonBoxBody">
                                  <div className="CommonBoxBodyUser">
                                    <div className="CommonBoxBodyTitle">
                                      <span>MB</span>
                                      <div className="">
                                        <h6>
                                          {item?.description?.slice(0, 30)}
                                        </h6>
                                        <p>{item?.JobType}</p>
                                      </div>
                                    </div>
                                    <figure>
                                      <img src={tasking1} />
                                    </figure>
                                    <span className="CbbOverlay" />
                                  </div>
                                </div>
                              </>
                            );
                          }
                        )}

                      <div className="CommonBoxFooter">
                        <h5>{iternaryHomeListData?.itineraries?.count ?? 0}</h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="clear"></div>
              </OwlCarousel>
              <div className="clear"></div>
            </div>
            {/* residential owl carousel  */}

            <div className="ResidentialCommonBoxArea">
              <div className="CommonBoxArea ">
                <div className="MainTitle">
                  <h2>Residential</h2>
                </div>
                {/* <div className="home-carousel owl-carousel owl-theme"> */}
                <OwlCarousel options={options}>
                  <div className="item">
                    <div
                      className="CommonBox CommonBoxViewConfig"
                      onClick={handleAccountingShow}
                    >
                      <div className="CommonBoxHeader">
                        <h6>Accounting</h6>
                        <h5>{residentialHomeListData?.accounting?.count}</h5>
                        <figure>
                          <img src="images/box-13.png" />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(
                          residentialHomeListData?.accounting?.accounting
                        ) &&
                          residentialHomeListData?.accounting?.accounting?.map(
                            (ele, i) => {
                              return (
                                <>
                                  <div className="CommonBoxBodyUser">
                                    <div className="CommonBoxBodyTitle">
                                      <span>MB</span>
                                      <div className="">
                                        <h6>{ele?.tranId}</h6>
                                        <p>{ele?.notes}</p>
                                      </div>
                                    </div>

                                    <figure>
                                      <img src="images/taskimg-1.png" />
                                    </figure>
                                    <span className="CbbOverlay" />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>{residentialHomeListData?.accounting?.count}</h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="CommonBox CommonBoxWarranty"
                      onClick={() => handleChangeOrderShow()}
                    >
                      <div className="CommonBoxHeader">
                        <h6>Change Order</h6>
                        <h5>{residentialHomeListData?.changeOrder?.count}</h5>
                        <figure>
                          <img src="images/box-6.png" />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(
                          residentialHomeListData?.changeOrder?.changeOrder
                        ) &&
                          residentialHomeListData?.changeOrder?.changeOrder?.map(
                            (ele, i) => {
                              return (
                                <>
                                  <div className="CommonBoxBodyUser">
                                    <div className="CommonBoxBodyTitle">
                                      <span>MB</span>
                                      <div className="">
                                        <h6>
                                          {ele?.ResidentialJob?.subdividision}
                                        </h6>
                                        <p>{ele?.ResidentialJob?.jobNumber}</p>
                                      </div>
                                    </div>

                                    <figure>
                                      <img src="images/taskimg-1.png" />
                                    </figure>
                                    <span className="CbbOverlay" />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>{residentialHomeListData?.changeOrder?.count}</h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div
                      className="CommonBox CommonBoxViewConfig"
                      onClick={handleClientViewConfigShow}
                    >
                      <div className="CommonBoxHeader">
                        <h6>Client View Config</h6>
                        <h5>
                          {residentialHomeListData?.clientCount?.count ?? 0}
                        </h5>
                        <figure>
                          <img src="images/box-13.png" />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(
                          residentialHomeListData?.clientCount?.clientView
                        ) &&
                          residentialHomeListData?.clientCount?.clientView?.map(
                            (ele, i) => {
                              return (
                                <>
                                  <div className="CommonBoxBodyUser">
                                    <div className="CommonBoxBodyTitle">
                                      <span>MB</span>
                                      <div className="">
                                        <h6>
                                          {ele?.ResidentialJob?.subdividision}
                                        </h6>
                                        <p>{ele?.ResidentialJob?.jobNumber}</p>
                                      </div>
                                    </div>

                                    <figure>
                                      <img src="images/taskimg-1.png" />
                                    </figure>
                                    <span className="CbbOverlay" />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>
                          {residentialHomeListData?.clientCount?.count ?? 0}
                        </h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="CommonBox CommonBoxTable"
                      onClick={handleConstructionShow}
                    >
                      <div className="CommonBoxHeader">
                        <h6>Construction</h6>
                        <h5>{residentialHomeListData?.construction?.count}</h5>
                        <figure>
                          <img src={box2} />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(
                          residentialHomeListData?.construction?.construction
                        ) &&
                          residentialHomeListData?.construction?.construction?.map(
                            (ele, i) => {
                              return (
                                <>
                                  <div className="CommonBoxBodyUser">
                                    <div className="CommonBoxBodyTitle">
                                      <span>MB</span>
                                      <div className="">
                                        <h6>
                                          {ele?.ResidentialJob?.subdividision}
                                        </h6>
                                        <p>{ele?.ResidentialJob?.jobNumber}</p>
                                      </div>
                                    </div>

                                    <figure>
                                      <img src="images/taskimg-1.png" />
                                    </figure>
                                    <span className="CbbOverlay" />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>{residentialHomeListData?.construction?.count}</h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="CommonBox"
                      onClick={() => handleDesignSelectionShow()}
                    >
                      <div className="CommonBoxHeader">
                        {/* <Link to="/design-selections"> */}
                        <h6>Design Selections</h6>
                        <h5>
                          {residentialHomeListData?.designSelections?.count}
                        </h5>
                        {/* </Link> */}
                        <figure>
                          <img src="images/box-6.png" />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(
                          residentialHomeListData?.designSelections
                            ?.designSelections
                        ) &&
                          residentialHomeListData?.designSelections?.designSelections?.map(
                            (ele, i) => {
                              return (
                                <>
                                  <div className="CommonBoxBodyUser">
                                    <div className="CommonBoxBodyTitle">
                                      <span>MB</span>
                                      <div className="">
                                        <h6>
                                          {ele?.ResidentialJob?.subdividision}
                                        </h6>
                                        <p>{ele?.ResidentialJob?.jobNumber}</p>
                                      </div>
                                    </div>

                                    <figure>
                                      <img src="images/taskimg-1.png" />
                                    </figure>
                                    <span className="CbbOverlay" />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>
                          {residentialHomeListData?.designSelections?.count}
                        </h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div
                      className="CommonBox CommonBoxWarranty"
                      onClick={() => handleEstimationShow()}
                    >
                      <div className="CommonBoxHeader">
                        <h6>Estimation</h6>
                        <h5>{residentialHomeListData?.estimation?.count}</h5>
                        <figure>
                          <img src="images/box-6.png" />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(
                          residentialHomeListData?.estimation?.estimation
                        ) &&
                          residentialHomeListData?.estimation?.estimation?.map(
                            (ele, i) => {
                              return (
                                <>
                                  <div className="CommonBoxBodyUser">
                                    <div className="CommonBoxBodyTitle">
                                      <Link to="/estimation">MB</Link>
                                      <div className="">
                                        <h6>
                                          {ele?.ResidentialJob?.subdividision}
                                        </h6>
                                        <p>{ele?.ResidentialJob?.jobNumber}</p>
                                      </div>
                                    </div>

                                    <figure>
                                      <img src="images/taskimg-1.png" />
                                    </figure>
                                    <span className="CbbOverlay" />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>{residentialHomeListData?.estimation?.count}</h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div
                      className="CommonBox CommonBoxWarranty"
                      onClick={() => handleWarrantyShow()}
                    >
                      <div className="CommonBoxHeader">
                        <h6>Warranty</h6>
                        <h5>{residentialHomeListData?.warranties?.count}</h5>

                        <figure>
                          <img src={box1} />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(
                          residentialHomeListData?.warranties?.warranties
                        ) &&
                          residentialHomeListData?.warranties?.warranties?.map(
                            (ele, i) => {
                              return (
                                <>
                                  <div className="CommonBoxBodyUser">
                                    <div className="CommonBoxBodyTitle">
                                      <span>MB</span>
                                      <div className="">
                                        <h6>
                                          {ele?.ResidentialJob?.subdividision}
                                        </h6>
                                        <p>{ele?.ResidentialJob?.jobNumber}</p>
                                      </div>
                                    </div>

                                    <figure>
                                      <img src="images/taskimg-1.png" />
                                    </figure>
                                    <span className="CbbOverlay" />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>{residentialHomeListData?.warranties?.count}</h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
                {/* </div> */}
              </div>
            </div>

            {warrantyShow && (
              <>
                <div className="JobTableHeadersWarranty">
                  <div className="JobTableHeaderWarranty">
                    <h5>Warranty Job/Unit List</h5>
                    <div className="Actions">
                      <a>
                        <img src="images/icon-52.png" />
                      </a>
                      <a>
                        <img src="images/icon-51.png" />
                      </a>
                      <a>
                        <img src="images/icon-53.png" />
                      </a>
                      <a>
                        <img src="images/icon-54.png" />
                      </a>
                      <a>
                        <img src="images/icon-55.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="JobTableWarranty">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Job Number</th>
                        <th>Build Type</th>
                        <th>Address</th>
                        {/* <th>Lot</th>
                      <th>Block</th>
                      <th>Phase</th>
                      <th>Plan</th> */}
                        <th>Sub Division</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="addClasss">
                      {warrantyJobsList?.data?.length > 0
                        ? warrantyJobsList?.data?.map((item, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td>{item.id}</td>

                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/warranty-dashboard"
                                      state={item}
                                    >
                                      {item.jobNumber ?? "N/A"}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/warranty-dashboard"
                                      state={item}
                                    >
                                      {item.projectType}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/warranty-dashboard"
                                      state={item}
                                    >
                                      {item.projectAddress}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/warranty-dashboard"
                                      state={item}
                                    >
                                      {/* {item?.subdivisions?.map((x) => x)} */}
                                    </Link>
                                  </td>
                                  <td>
                                    <div className="TodoUser TodoUserHome">
                                      <div className="TodoUserJob">
                                        <div className="dropdown">
                                          <p data-toggle="dropdown">
                                            <span>
                                              <img src="images/three-dots.png" />
                                            </span>
                                          </p>
                                          <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuOffset"
                                          >
                                            <li>
                                              <a className="dropdown-item">
                                                <img src="images/add_build.png" />{" "}
                                                Add to/Edit Build
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                            </li>
                                            {/* <li>
                                              <a className="dropdown-item">
                                                <img src="images/details.png" />
                                                Details
                                              </a>
                                            </li> */}
                                            <li>
                                              <Link
                                                to="/change-order-list"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/change_order.png" />{" "}
                                                Change Order
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <Link
                                                    to="/change-order-list"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/change_order.png" />
                                                    Change orders List
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/design-selections"
                                                state={item}
                                              >
                                                <img src="images/design.png" />
                                                Design
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                to="/sales"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/sales.png" />
                                                Sales
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                to="/estimation"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/estimation.png" />{" "}
                                                Estimation
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="budget_details.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/estimation.png" />
                                                    Budget Details
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                to="/construction"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/construction_icon.png" />{" "}
                                                Construction
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_1.png" />{" "}
                                                    Schedule View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_2.png" />{" "}
                                                    Gantt View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_3.png" />{" "}
                                                    Calendar View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_4.png" />{" "}
                                                    List View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_5.png" />{" "}
                                                    Baseline
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/warranty-dashboard"
                                                state={item}
                                              >
                                                <img src="images/warranty.png" />
                                                Warranty
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/models"
                                                state={item}
                                              >
                                                <img src="images/model.png" />{" "}
                                                Model
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu">
                                                <li>
                                                  <Link
                                                    to="/models"
                                                    state={item}
                                                    className="dropdown-item text-center"
                                                  >
                                                    <img src="images/model.png" />{" "}
                                                    Create New Model
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                to="/reports"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/reports.png" />
                                                Reports
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to={`/client-view-config`}
                                                state={{ item }}
                                              >
                                                <img src="images/view_config.png" />
                                                Views Configs
                                              </Link>
                                            </li>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        : ""}
                    </tbody>
                  </table>
                </div>
              </>
            )}

            {estimationShow && (
              <>
                <div className="JobTableHeadersWarranty">
                  <div className="JobTableHeaderWarranty">
                    <h5>Estimation Job/Unit List</h5>
                    <div className="Actions">
                      <a>
                        <img src="images/icon-52.png" />
                      </a>
                      <a>
                        <img src="images/icon-51.png" />
                      </a>
                      <a>
                        <img src="images/icon-53.png" />
                      </a>
                      <a>
                        <img src="images/icon-54.png" />
                      </a>
                      <a>
                        <img src="images/icon-55.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="JobTableWarranty">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Job Number</th>
                        <th>Build Type</th>
                        <th>Address</th>
                        {/* <th>Lot</th>
                    <th>Block</th>
                    <th>Phase</th>
                    <th>Plan</th> */}
                        <th>Sub Division</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="addClasss">
                      {projectsList?.projects?.length > 0
                        ? projectsList?.projects?.map((item, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td>
                                    <div className="d-flex">
                                      {item.id}
                                      <div className="dropdown">
                                        <span
                                          className="Dots"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </span>
                                        <div className="dropdown-menu">
                                          <a
                                            onClick={() =>
                                              handleArchiveJobs(
                                                item.id,
                                                item.jobType,
                                                "residential"
                                              )
                                            }
                                            className="dropdown-item"
                                          >
                                            {" "}
                                            <img src="images/archive_job.png" />{" "}
                                            Archive Job
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <a
                                      style={{ color: "red" }}
                                      id="hidtableshow2"
                                      onClick={() =>
                                        handleJobListByProject(item.id, i)
                                      }
                                    >
                                      {item.jobNumber}&nbsp;
                                      <img
                                        src={"images/down-arrow.png"}
                                        style={{
                                          transform: `rotate(${
                                            resdentalId === i
                                              ? "180deg"
                                              : "0deg"
                                          })`,
                                        }}
                                      />
                                    </a>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/estimation"
                                      state={item}
                                    >
                                      {item.projectType}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/estimation"
                                      state={item}
                                    >
                                      {item.projectAddress}
                                    </Link>
                                  </td>
                                  {/* <td>{item.lot}</td>
                              <td>{item.block}</td>
                              <td>{item.phase}</td>
                              <td /> */}

                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/estimation"
                                      state={item}
                                    >
                                      {/* {item?.subdivisions?.map((x) => x)} */}
                                    </Link>
                                  </td>
                                  <td>
                                    <div className="TodoUser TodoUserHome">
                                      <div className="TodoUserJob">
                                        <div className="dropdown">
                                          <p data-toggle="dropdown">
                                            <span>
                                              <img src="images/three-dots.png" />
                                            </span>
                                          </p>
                                          <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuOffset"
                                          >
                                            <li>
                                              <a className="dropdown-item">
                                                <img src="images/add_build.png" />{" "}
                                                Add to/Edit Build
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                            </li>
                                            {/* <li>
                                              <a
                                                onClick={() => {
                                                  setJobDetialsModal(true);
                                                  setSelectedId(item?.id);
                                                  setJobName("Estimation");
                                                  setJobId(
                                                    item?.MultiFamilyJobId
                                                  );
                                                }}
                                              >
                                                <img src="images/details.png" />
                                                Details
                                              </a>
                                            </li> */}
                                            <li>
                                              <Link
                                                to="/change-order-list"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/change_order.png" />{" "}
                                                Change Order
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <Link
                                                    to="/change-order-list"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/change_order.png" />
                                                    Change orders List
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/design-selections"
                                                state={item}
                                              >
                                                <img src="images/design.png" />
                                                Design
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                to="/sales"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/sales.png" />
                                                Sales
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                to="/estimation"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/estimation.png" />{" "}
                                                Estimation
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="budget_details.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/estimation.png" />
                                                    Budget Details
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                to="/construction"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/construction_icon.png" />{" "}
                                                Construction
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_1.png" />{" "}
                                                    Schedule View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_2.png" />{" "}
                                                    Gantt View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_3.png" />{" "}
                                                    Calendar View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_4.png" />{" "}
                                                    List View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_5.png" />{" "}
                                                    Baseline
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/warranty-dashboard"
                                                state={item}
                                              >
                                                <img src="images/warranty.png" />
                                                Warranty
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/models"
                                                state={item}
                                              >
                                                <img src="images/model.png" />{" "}
                                                Model
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu">
                                                <li>
                                                  <Link
                                                    to="/models"
                                                    state={item}
                                                    className="dropdown-item text-center"
                                                  >
                                                    <img src="images/model.png" />{" "}
                                                    Create New Model
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                to="/reports"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/reports.png" />
                                                Reports
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to={`/client-view-config`}
                                                state={{ item }}
                                              >
                                                <img src="images/view_config.png" />
                                                Views Configs
                                              </Link>
                                            </li>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td colSpan={10} className="padding_none">
                                    <div
                                      className="JobBoxTable"
                                      style={{
                                        display:
                                          resdentalId == i ? "block" : "none",
                                      }}
                                    >
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Unit Job Number</th>
                                            <th>Unit#</th>
                                            <th>Primary Client</th>
                                            <th>Square Footage</th>
                                            <th>Warranty Requests</th>
                                            <th>Description</th>
                                            <th />
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {resdentalId == i &&
                                            listProjectJobs?.jobs?.length > 0 &&
                                            listProjectJobs?.jobs?.map(
                                              (data, index) => {
                                                return (
                                                  <tr key={index}>
                                                    <td>
                                                      <div className="d-flex">
                                                        {item.id}
                                                        <div className="dropdown">
                                                          <span
                                                            className="Dots"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                          >
                                                            <img src="images/dots.png" />
                                                          </span>
                                                          <div className="dropdown-menu">
                                                            <a
                                                              onClick={() =>
                                                                handleArchiveJobs(
                                                                  item.id,
                                                                  item.jobType,
                                                                  "residential"
                                                                )
                                                              }
                                                              className="dropdown-item"
                                                            >
                                                              {" "}
                                                              <img src="images/archive_job.png" />{" "}
                                                              Archive Job
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/estimation"
                                                        state={data}
                                                      >
                                                        {data.jobNumber}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/estimation"
                                                        state={data}
                                                      >
                                                        {data.unit ?? "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/estimation"
                                                        state={data}
                                                      >
                                                        {data.client ?? "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/estimation"
                                                        state={data}
                                                      >
                                                        {data.footage ?? "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/estimation"
                                                        state={data}
                                                      >
                                                        {data?.warranties ??
                                                          "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/estimation"
                                                        state={data}
                                                      >
                                                        {data?.description ??
                                                          "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      {/* <a to="javascript:void(0);">
                                      <i
                                        className="fa fa-caret-down"
                                        aria-hidden="true"
                                      />
                                    </a> */}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        : ""}
                    </tbody>
                  </table>
                </div>
              </>
            )}

            {clientViewConfigShow && (
              <>
                <div className="JobTableHeadersWarranty">
                  <div className="JobTableHeaderWarranty">
                    <h5>Client-View-Config Job/Unit List</h5>
                    <div className="Actions">
                      <a>
                        <img src="images/icon-52.png" />
                      </a>
                      <a>
                        <img src="images/icon-51.png" />
                      </a>
                      <a>
                        <img src="images/icon-53.png" />
                      </a>
                      <a>
                        <img src="images/icon-54.png" />
                      </a>
                      <a>
                        <img src="images/icon-55.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="JobTableWarranty">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Job Number</th>
                        <th>Build Type</th>
                        <th>Address</th>
                        {/* <th>Lot</th>
                    <th>Block</th>
                    <th>Phase</th>
                    <th>Plan</th> */}
                        <th>Sub Division</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="addClasss">
                      {projectsList?.projects?.length > 0
                        ? projectsList?.projects?.map((item, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td>
                                    <div className="d-flex">
                                      {item.id}
                                      <div className="dropdown">
                                        <span
                                          className="Dots"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </span>
                                        <div className="dropdown-menu">
                                          <a
                                            onClick={() =>
                                              handleArchiveJobs(
                                                item.id,
                                                item.jobType,
                                                "residential"
                                              )
                                            }
                                            className="dropdown-item"
                                          >
                                            {" "}
                                            <img src="images/archive_job.png" />{" "}
                                            Archive Job
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <a
                                      style={{ color: "red" }}
                                      id="hidtableshow2"
                                      onClick={() =>
                                        handleJobListByProject(item.id, i)
                                      }
                                    >
                                      {item.jobNumber}&nbsp;
                                      <img
                                        src={"images/down-arrow.png"}
                                        style={{
                                          transform: `rotate(${
                                            resdentalId === i
                                              ? "180deg"
                                              : "0deg"
                                          })`,
                                        }}
                                      />
                                    </a>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to={`/client-view-config`}
                                      state={{ item }}
                                    >
                                      {item.projectType}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to={`/client-view-config`}
                                      state={{ item }}
                                    >
                                      {item.projectAddress}
                                    </Link>
                                  </td>
                                  {/* <td>{item.lot}</td>
                              <td>{item.block}</td>
                              <td>{item.phase}</td>
                              <td /> */}

                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to={`/client-view-config`}
                                      state={{ item }}
                                    >
                                      {/* {item?.subdivisions?.map((x) => x)} */}
                                    </Link>
                                  </td>
                                  <td>
                                    <div className="TodoUser TodoUserHome">
                                      <div className="TodoUserJob">
                                        <div className="dropdown">
                                          <p data-toggle="dropdown">
                                            <span>
                                              <img src="images/three-dots.png" />
                                            </span>
                                          </p>
                                          <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuOffset"
                                          >
                                            <li>
                                            <a className="dropdown-item" onClick={showResidentialJobFunc}>
                                                <img src="images/add_build.png" />{" "}
                                                Create Residential Job wizard 
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                            </li>
                                            {/* <li>
                                              <a
                                                onClick={() => {
                                                  setResidentialDetailsModal(true);
                                                  setSelectedId(item?.id);
                                                  setJobData(item)
                                                  setJobName("Change Order");
                                                }}
                                              >
                                                {" "}
                                                <img src="images/details.png" />
                                                Details
                                              </a>
                                            </li> */}
                                            <li>
                                              <Link
                                                to="/change-order-list"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/change_order.png" />{" "}
                                                Change Order
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <Link
                                                    to="/change-order-list"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/change_order.png" />
                                                    Change orders List
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/design-selections"
                                                state={item}
                                              >
                                                <img src="images/design.png" />
                                                Design
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                to="/sales"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/sales.png" />
                                                Sales
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                to="/estimation"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/estimation.png" />{" "}
                                                Estimation
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="budget_details.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/estimation.png" />
                                                    Budget Details
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                to="/construction"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/construction_icon.png" />{" "}
                                                Construction
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_1.png" />{" "}
                                                    Schedule View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_2.png" />{" "}
                                                    Gantt View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_3.png" />{" "}
                                                    Calendar View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_4.png" />{" "}
                                                    List View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_5.png" />{" "}
                                                    Baseline
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/warranty-dashboard"
                                                state={item}
                                              >
                                                <img src="images/warranty.png" />
                                                Warranty
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/models"
                                                state={item}
                                              >
                                                <img src="images/model.png" />{" "}
                                                Model
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu">
                                                <li>
                                                  <Link
                                                    to="/models"
                                                    state={item}
                                                    className="dropdown-item text-center"
                                                  >
                                                    <img src="images/model.png" />{" "}
                                                    Create New Model
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                to="/reports"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/reports.png" />
                                                Reports
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to={`/client-view-config`}
                                                state={{ item }}
                                              >
                                                <img src="images/view_config.png" />
                                                Views Configs
                                              </Link>
                                            </li>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td colSpan={10} className="padding_none">
                                    <div
                                      className="JobBoxTable"
                                      style={{
                                        display:
                                          resdentalId == i ? "block" : "none",
                                      }}
                                    >
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Unit Job Number</th>
                                            <th>Unit#</th>
                                            <th>Primary Client</th>
                                            <th>Square Footage</th>
                                            <th>Warranty Requests</th>
                                            <th>Description</th>
                                            <th>Action</th>
                                            <th />
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {resdentalId == i &&
                                            listProjectJobs?.jobs?.length > 0 &&
                                            listProjectJobs?.jobs?.map(
                                              (data, index) => {
                                                return (
                                                  <tr key={index}>
                                                    <td>
                                                      <div className="d-flex">
                                                        {data.id}
                                                        <div className="dropdown">
                                                          <span
                                                            className="Dots"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                          >
                                                            <img src="images/dots.png" />
                                                          </span>
                                                          <div className="dropdown-menu">
                                                            <a
                                                              onClick={() =>
                                                                handleArchiveJobs(
                                                                  data.id,
                                                                  data.jobType,
                                                                  "residential"
                                                                )
                                                              }
                                                              className="dropdown-item"
                                                            >
                                                              {" "}
                                                              <img src="images/archive_job.png" />{" "}
                                                              Archive Job
                                                            </a>
                                                            <a
                                                              className="dropdown-item"
                                                              onClick={() =>
                                                                handleShowInWarranty(
                                                                  data.id,
                                                                  data.jobType,
                                                                  "residential"
                                                                )
                                                              }
                                                            >
                                                              {" "}
                                                              <img src="images/home_warranty.png" />{" "}
                                                              Show in Warranty
                                                              &nbsp;&nbsp;
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to={`/client-view-config`}
                                                        state={{ data }}
                                                      >
                                                        {data.jobNumber}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to={`/client-view-config`}
                                                        state={{ data }}
                                                      >
                                                        {data.unit ?? "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to={`/client-view-config`}
                                                        state={{ data }}
                                                      >
                                                        {data.client ?? "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to={`/client-view-config`}
                                                        state={{ data }}
                                                      >
                                                        {data.footage ?? "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to={`/client-view-config`}
                                                        state={{ data }}
                                                      >
                                                        {data?.warranties ??
                                                          "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to={`/client-view-config`}
                                                        state={{ data }}
                                                      >
                                                        {data?.description ??
                                                          "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      {/* <a to="javascript:void(0);">
                                      <i
                                        className="fa fa-caret-down"
                                        aria-hidden="true"
                                      />
                                    </a> */}
                                                    </td>
                                                    <td>
                                                      <div className="TodoUser TodoUserHome">
                                                        <div className="TodoUserJob">
                                                          <div className="dropdown">
                                                            <p data-toggle="dropdown">
                                                              <span>
                                                                <img src="images/three-dots.png" />
                                                              </span>
                                                            </p>
                                                            <div
                                                              className="dropdown-menu"
                                                              aria-labelledby="dropdownMenuOffset"
                                                            >
                                                              {/* <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="javascript:void(0);"
                                                                >
                                                                  <img src="images/add_build.png" />{" "}
                                                                  Add to/Edit
                                                                  Build
                                                                  <img src="images/rightarrow2.png" />
                                                                </a>
                                                              </li> */}
                                                              <li>
                                                                <a
                                                                  onClick={() => {
                                                                    setResidentialDetailsModal(
                                                                      true
                                                                    );
                                                                    setSelectedId(
                                                                      data?.ProjectId
                                                                    );
                                                                    setJobId(
                                                                      data?.id
                                                                    );
                                                                  }}
                                                                >
                                                                  <img src="images/details.png" />
                                                                  Details
                                                                </a>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="javascript:void(0);"
                                                                >
                                                                  <img src="images/change_order.png" />{" "}
                                                                  Change Order
                                                                  <img src="images/rightarrow2.png" />
                                                                </a>
                                                                <ul className="dropdown-menu submenu submenuhome">
                                                                  <li>
                                                                    <a
                                                                      to="change_order_list.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/change_order.png" />
                                                                      Change
                                                                      orders
                                                                      List
                                                                    </a>
                                                                  </li>
                                                                </ul>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="design_selections.html"
                                                                >
                                                                  <img src="images/design.png" />
                                                                  Design
                                                                </a>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="javascript:void(0);"
                                                                  data-toggle="modal"
                                                                  data-targer="#ClientContractDetailsNoUnit"
                                                                >
                                                                  <img src="images/sales.png" />
                                                                  Sales
                                                                </a>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="javascript:void(0);"
                                                                >
                                                                  <img src="images/estimation.png" />{" "}
                                                                  Estimation
                                                                  <img src="images/rightarrow2.png" />
                                                                </a>
                                                                <ul className="dropdown-menu submenu submenuhome">
                                                                  <li>
                                                                    <a
                                                                      to="budget_details.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/estimation.png" />
                                                                      Budget
                                                                      Details
                                                                    </a>
                                                                  </li>
                                                                </ul>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="construction.html"
                                                                >
                                                                  <img src="images/construction_icon.png" />{" "}
                                                                  Construction
                                                                  <img src="images/rightarrow2.png" />
                                                                </a>
                                                                <ul className="dropdown-menu submenu submenuhome">
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_1.png" />{" "}
                                                                      Schedule
                                                                      View
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_2.png" />{" "}
                                                                      Gantt View
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_3.png" />{" "}
                                                                      Calendar
                                                                      View
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_4.png" />{" "}
                                                                      List View
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_5.png" />{" "}
                                                                      Baseline
                                                                    </a>
                                                                  </li>
                                                                </ul>
                                                              </li>
                                                              <li>
                                                                <Link
                                                                  className="dropdown-item"
                                                                  to="/warranty-dashboard"
                                                                >
                                                                  <img src="images/warranty.png" />
                                                                  Warranty
                                                                </Link>
                                                              </li>
                                                              <li>
                                                                <Link
                                                                  className="dropdown-item"
                                                                  to="/models"
                                                                >
                                                                  <img src="images/model.png" />{" "}
                                                                  Model
                                                                  <img src="images/rightarrow2.png" />
                                                                </Link>
                                                                {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="reports.html"
                                                                >
                                                                  <img src="images/reports.png" />
                                                                  Reports
                                                                </a>
                                                              </li>
                                                              <li>
                                                                <Link
                                                                  className="dropdown-item"
                                                                  to={`/client-view-config`}
                                                                  state={{
                                                                    item,
                                                                  }}
                                                                >
                                                                  <img src="images/view_config.png" />
                                                                  Views Configs
                                                                </Link>
                                                              </li>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        : ""}
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {changeOrderShow && (
              <>
                <div className="JobTableHeadersWarranty">
                  <div className="JobTableHeaderWarranty">
                    <h5>Change Order Job/Unit List</h5>
                    <div className="Actions">
                      <a>
                        <img src="images/icon-52.png" />
                      </a>
                      <a>
                        <img src="images/icon-51.png" />
                      </a>
                      <a>
                        <img src="images/icon-53.png" />
                      </a>
                      <a>
                        <img src="images/icon-54.png" />
                      </a>
                      <a>
                        <img src="images/icon-55.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="JobTableWarranty">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Job Number</th>
                        <th>Build Type</th>
                        <th>Address</th>
                        {/* <th>Lot</th>
                    <th>Block</th>
                    <th>Phase</th>
                    <th>Plan</th> */}
                        <th>Sub Division</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="addClasss">
                      {projectsList?.projects?.length > 0
                        ? projectsList?.projects?.map((item, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td>
                                    <div className="d-flex">
                                      {item.id}
                                      <div className="dropdown">
                                        <span
                                          className="Dots"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </span>
                                        <div className="dropdown-menu">
                                          <a
                                            onClick={() =>
                                              handleArchiveJobs(
                                                item.id,
                                                item.jobType,
                                                "residential"
                                              )
                                            }
                                            className="dropdown-item"
                                          >
                                            {" "}
                                            <img src="images/archive_job.png" />{" "}
                                            Archive Job
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <a
                                      style={{ color: "red" }}
                                      id="hidtableshow2"
                                      onClick={() =>
                                        handleJobListByProject(item.id, i)
                                      }
                                    >
                                      {item.jobNumber}&nbsp;
                                      <img
                                        src={"images/down-arrow.png"}
                                        style={{
                                          transform: `rotate(${
                                            resdentalId === i
                                              ? "180deg"
                                              : "0deg"
                                          })`,
                                        }}
                                      />
                                    </a>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/change-order-list"
                                      state={item}
                                    >
                                      {item.projectType}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/change-order-list"
                                      state={item}
                                    >
                                      {item.projectAddress}
                                    </Link>
                                  </td>
                                  {/* <td>{item.lot}</td>
                              <td>{item.block}</td>
                              <td>{item.phase}</td>
                              <td /> */}

                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/change-order-list"
                                      state={item}
                                    >
                                      {/* {item?.subdivisions?.map((x) => x)} */}
                                    </Link>
                                  </td>
                                  <td>
                                    <div className="TodoUser TodoUserHome">
                                      <div className="TodoUserJob">
                                        <div className="dropdown">
                                          <p data-toggle="dropdown">
                                            <span>
                                              <img src="images/three-dots.png" />
                                            </span>
                                          </p>
                                          <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuOffset"
                                          >
                                            <li>
                                            <a className="dropdown-item" onClick={showResidentialJobFunc}>
                                                <img src="images/add_build.png" />{" "}
                                                Create Residential Job wizard 
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                            </li>
                                            {/* <li>
                                              <a
                                                onClick={() => {
                                                  setJobDetialsModal(true);
                                                  setSelectedId(item?.id);
                                                  setJobName("Change Order");
                                                }}
                                              >
                                                {" "}
                                                <img src="images/details.png" />
                                                Details
                                              </a>
                                            </li> */}
                                            <li>
                                              <Link
                                                to="/change-order-list"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/change_order.png" />{" "}
                                                Change Order
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <Link
                                                    to="/change-order-list"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/change_order.png" />
                                                    Change orders List
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/design-selections"
                                                state={item}
                                              >
                                                <img src="images/design.png" />
                                                Design
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                to="/sales"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/sales.png" />
                                                Sales
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                to="/estimation"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/estimation.png" />{" "}
                                                Estimation
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="budget_details.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/estimation.png" />
                                                    Budget Details
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                to="/construction"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/construction_icon.png" />{" "}
                                                Construction
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_1.png" />{" "}
                                                    Schedule View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_2.png" />{" "}
                                                    Gantt View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_3.png" />{" "}
                                                    Calendar View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_4.png" />{" "}
                                                    List View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_5.png" />{" "}
                                                    Baseline
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/warranty-dashboard"
                                                state={item}
                                              >
                                                <img src="images/warranty.png" />
                                                Warranty
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/models"
                                                state={item}
                                              >
                                                <img src="images/model.png" />{" "}
                                                Model
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu">
                                                <li>
                                                  <Link
                                                    to="/models"
                                                    state={item}
                                                    className="dropdown-item text-center"
                                                  >
                                                    <img src="images/model.png" />{" "}
                                                    Create New Model
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                to="/reports"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/reports.png" />
                                                Reports
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to={`/client-view-config`}
                                                state={{ item }}
                                              >
                                                <img src="images/view_config.png" />
                                                Views Configs
                                              </Link>
                                            </li>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td colSpan={10} className="padding_none">
                                    <div
                                      className="JobBoxTable"
                                      style={{
                                        display:
                                          resdentalId == i ? "block" : "none",
                                      }}
                                    >
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Unit Job Number</th>
                                            <th>Unit#</th>
                                            <th>Primary Client</th>
                                            <th>Square Footage</th>
                                            <th>Warranty Requests</th>
                                            <th>Description</th>
                                            <th>Action</th>
                                            <th />
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {resdentalId == i &&
                                            listProjectJobs?.jobs?.length > 0 &&
                                            listProjectJobs?.jobs?.map(
                                              (data, index) => {
                                                return (
                                                  <tr key={index}>
                                                    <td>
                                                      <div className="d-flex">
                                                        {data.id}
                                                        <div className="dropdown">
                                                          <span
                                                            className="Dots"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                          >
                                                            <img src="images/dots.png" />
                                                          </span>
                                                          <div className="dropdown-menu">
                                                            <a
                                                              onClick={() =>
                                                                handleArchiveJobs(
                                                                  data.id,
                                                                  data.jobType,
                                                                  "residential"
                                                                )
                                                              }
                                                              className="dropdown-item"
                                                            >
                                                              {" "}
                                                              <img src="images/archive_job.png" />{" "}
                                                              Archive Job
                                                            </a>
                                                            <a
                                                              className="dropdown-item"
                                                              onClick={() =>
                                                                handleShowInWarranty(
                                                                  data.id,
                                                                  data.jobType,
                                                                  "residential"
                                                                )
                                                              }
                                                            >
                                                              {" "}
                                                              <img src="images/home_warranty.png" />{" "}
                                                              Show in Warranty
                                                              &nbsp;&nbsp;
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/change-order-list"
                                                        state={data}
                                                      >
                                                        {data.jobNumber}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/change-order-list"
                                                        state={data}
                                                      >
                                                        {data.unit ?? "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/change-order-list"
                                                        state={data}
                                                      >
                                                        {data.client ?? "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/change-order-list"
                                                        state={data}
                                                      >
                                                        {data.footage ?? "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/change-order-list"
                                                        state={data}
                                                      >
                                                        {data?.warranties ??
                                                          "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/change-order-list"
                                                        state={data}
                                                      >
                                                        {data?.description ??
                                                          "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      {/* <a to="javascript:void(0);">
                                      <i
                                        className="fa fa-caret-down"
                                        aria-hidden="true"
                                      />
                                    </a> */}
                                                    </td>
                                                    <td>
                                                      <div className="TodoUser TodoUserHome">
                                                        <div className="TodoUserJob">
                                                          <div className="dropdown">
                                                            <p data-toggle="dropdown">
                                                              <span>
                                                                <img src="images/three-dots.png" />
                                                              </span>
                                                            </p>
                                                            <div
                                                              className="dropdown-menu"
                                                              aria-labelledby="dropdownMenuOffset"
                                                            >
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="javascript:void(0);"
                                                                >
                                                                  <img src="images/add_build.png" />{" "}
                                                                  Add to/Edit
                                                                  Build
                                                                  <img src="images/rightarrow2.png" />
                                                                </a>
                                                              </li>
                                                              {/* <li>
                                                                <a
                                                                  onClick={() => {
                                                                    setJobDetialsModal(
                                                                      true
                                                                    );
                                                                    setSelectedId(
                                                                      item?.id
                                                                    );
                                                                    setJobName(
                                                                      "Clent View Config"
                                                                    );
                                                                  }}
                                                                >
                                                                  <img src="images/details.png" />
                                                                  Details
                                                                </a>
                                                              </li> */}
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="javascript:void(0);"
                                                                >
                                                                  <img src="images/change_order.png" />{" "}
                                                                  Change Order
                                                                  <img src="images/rightarrow2.png" />
                                                                </a>
                                                                <ul className="dropdown-menu submenu submenuhome">
                                                                  <li>
                                                                    <a
                                                                      to="change_order_list.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/change_order.png" />
                                                                      Change
                                                                      orders
                                                                      List
                                                                    </a>
                                                                  </li>
                                                                </ul>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="design_selections.html"
                                                                >
                                                                  <img src="images/design.png" />
                                                                  Design
                                                                </a>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="javascript:void(0);"
                                                                  data-toggle="modal"
                                                                  data-targer="#ClientContractDetailsNoUnit"
                                                                >
                                                                  <img src="images/sales.png" />
                                                                  Sales
                                                                </a>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="javascript:void(0);"
                                                                >
                                                                  <img src="images/estimation.png" />{" "}
                                                                  Estimation
                                                                  <img src="images/rightarrow2.png" />
                                                                </a>
                                                                <ul className="dropdown-menu submenu submenuhome">
                                                                  <li>
                                                                    <a
                                                                      to="budget_details.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/estimation.png" />
                                                                      Budget
                                                                      Details
                                                                    </a>
                                                                  </li>
                                                                </ul>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="construction.html"
                                                                >
                                                                  <img src="images/construction_icon.png" />{" "}
                                                                  Construction
                                                                  <img src="images/rightarrow2.png" />
                                                                </a>
                                                                <ul className="dropdown-menu submenu submenuhome">
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_1.png" />{" "}
                                                                      Schedule
                                                                      View
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_2.png" />{" "}
                                                                      Gantt View
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_3.png" />{" "}
                                                                      Calendar
                                                                      View
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_4.png" />{" "}
                                                                      List View
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_5.png" />{" "}
                                                                      Baseline
                                                                    </a>
                                                                  </li>
                                                                </ul>
                                                              </li>
                                                              <li>
                                                                <Link
                                                                  className="dropdown-item"
                                                                  to="/warranty-dashboard"
                                                                >
                                                                  <img src="images/warranty.png" />
                                                                  Warranty
                                                                </Link>
                                                              </li>
                                                              <li>
                                                                <Link
                                                                  className="dropdown-item"
                                                                  to="/models"
                                                                >
                                                                  <img src="images/model.png" />{" "}
                                                                  Model
                                                                  <img src="images/rightarrow2.png" />
                                                                </Link>
                                                                {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="reports.html"
                                                                >
                                                                  <img src="images/reports.png" />
                                                                  Reports
                                                                </a>
                                                              </li>
                                                              <li>
                                                                <Link
                                                                  className="dropdown-item"
                                                                  to={`/client-view-config`}
                                                                  state={{
                                                                    item,
                                                                  }}
                                                                >
                                                                  <img src="images/view_config.png" />
                                                                  Views Configs
                                                                </Link>
                                                              </li>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        : ""}
                    </tbody>
                  </table>
                </div>
              </>
            )}

            {constructionShow && (
              <>
                <div className="JobTableHeadersWarranty">
                  <div className="JobTableHeaderWarranty">
                    <h5>Construction Job/Unit List</h5>
                    <div className="Actions">
                      <a>
                        <img src="images/icon-52.png" />
                      </a>
                      <a>
                        <img src="images/icon-51.png" />
                      </a>
                      <a>
                        <img src="images/icon-53.png" />
                      </a>
                      <a>
                        <img src="images/icon-54.png" />
                      </a>
                      <a to="">
                        <img src="images/icon-55.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="JobTableWarranty">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Job Number</th>
                        <th>Build Type</th>
                        <th>Address</th>
                        {/* <th>Lot</th>
                     <th>Block</th>
                     <th>Phase</th>
                     <th>Plan</th> */}
                        <th>Sub Division</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="addClasss">
                      {projectsList?.projects?.length > 0
                        ? projectsList?.projects?.map((item, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td>
                                    <div className="d-flex">
                                      {item.id}
                                      <div className="dropdown">
                                        <span
                                          className="Dots"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </span>
                                        <div className="dropdown-menu">
                                          <a
                                            onClick={() =>
                                              handleArchiveJobs(
                                                item.id,
                                                item.jobType,
                                                "residential"
                                              )
                                            }
                                            className="dropdown-item"
                                          >
                                            {" "}
                                            <img src="images/archive_job.png" />{" "}
                                            Archive Job
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <a
                                      style={{ color: "red" }}
                                      id="hidtableshow2"
                                      onClick={() =>
                                        handleJobListByProject(item.id, i)
                                      }
                                    >
                                      {item.jobNumber}&nbsp;
                                      <img
                                        src={"images/down-arrow.png"}
                                        style={{
                                          transform: `rotate(${
                                            resdentalId === i
                                              ? "180deg"
                                              : "0deg"
                                          })`,
                                        }}
                                      />
                                    </a>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/construction"
                                      state={item}
                                    >
                                      {item.projectType}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/construction"
                                      state={item}
                                    >
                                      {item.projectAddress}
                                    </Link>
                                  </td>
                                  {/* <td>{item.lot}</td>
                               <td>{item.block}</td>
                               <td>{item.phase}</td>
                               <td /> */}

                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/construction"
                                      state={item}
                                    >
                                      {/* {item?.subdivisions?.map((x) => x)} */}
                                    </Link>
                                  </td>
                                  <td>
                                    <div className="TodoUser TodoUserHome">
                                      <div className="TodoUserJob">
                                        <div className="dropdown">
                                          <p data-toggle="dropdown">
                                            <span>
                                              <img src="images/three-dots.png" />
                                            </span>
                                          </p>
                                          <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuOffset"
                                          >
                                            <li>
                                            <a className="dropdown-item" onClick={showResidentialJobFunc}>
                                                <img src="images/add_build.png" />{" "}
                                                Create Residential Job wizard 
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                            </li>
                                            {/* <li>
                                              <a
                                                onClick={() => {
                                                  setJobDetialsModal(true);
                                                  setSelectedId(item?.id);
                                                  setJobName("Construction");
                                                }}
                                              >
                                                {" "}
                                                <img src="images/details.png" />
                                                Details
                                              </a>
                                            </li> */}
                                            <li>
                                              <Link
                                                to="/change-order-list"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/change_order.png" />{" "}
                                                Change Order
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <Link
                                                    to="/change-order-list"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/change_order.png" />
                                                    Change orders List
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/design-selections"
                                                state={item}
                                              >
                                                <img src="images/design.png" />
                                                Design
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                to="/sales"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/sales.png" />
                                                Sales
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                to="/estimation"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/estimation.png" />{" "}
                                                Estimation
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="budget_details.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/estimation.png" />
                                                    Budget Details
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                to="/construction"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/construction_icon.png" />{" "}
                                                Construction
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_1.png" />{" "}
                                                    Schedule View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_2.png" />{" "}
                                                    Gantt View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_3.png" />{" "}
                                                    Calendar View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_4.png" />{" "}
                                                    List View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_5.png" />{" "}
                                                    Baseline
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/warranty-dashboard"
                                                state={item}
                                              >
                                                <img src="images/warranty.png" />
                                                Warranty
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/models"
                                                state={item}
                                              >
                                                <img src="images/model.png" />{" "}
                                                Model
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu">
                                                <li>
                                                  <Link
                                                    to="/models"
                                                    state={item}
                                                    className="dropdown-item text-center"
                                                  >
                                                    <img src="images/model.png" />{" "}
                                                    Create New Model
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                to="/reports"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/reports.png" />
                                                Reports
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to={`/client-view-config`}
                                                state={{ item }}
                                              >
                                                <img src="images/view_config.png" />
                                                Views Configs
                                              </Link>
                                            </li>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td colSpan={10} className="padding_none">
                                    <div
                                      className="JobBoxTable"
                                      style={{
                                        display:
                                          resdentalId == i ? "block" : "none",
                                      }}
                                    >
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Unit Job Number</th>
                                            <th>Unit#</th>
                                            <th>Primary Client</th>
                                            <th>Square Footage</th>
                                            <th>Warranty Requests</th>
                                            <th>Description</th>
                                            <th>Action</th>
                                            <th />
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {resdentalId == i &&
                                            listProjectJobs?.jobs?.length > 0 &&
                                            listProjectJobs?.jobs?.map(
                                              (data, index) => {
                                                return (
                                                  <tr key={index}>
                                                    <td>
                                                      <div className="d-flex">
                                                        {data.id}
                                                        <div className="dropdown">
                                                          <span
                                                            className="Dots"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                          >
                                                            <img src="images/dots.png" />
                                                          </span>
                                                          <div className="dropdown-menu">
                                                            <a
                                                              onClick={() =>
                                                                handleArchiveJobs(
                                                                  data.id,
                                                                  data.jobType,
                                                                  "residential"
                                                                )
                                                              }
                                                              className="dropdown-item"
                                                            >
                                                              {" "}
                                                              <img src="images/archive_job.png" />{" "}
                                                              Archive Job
                                                            </a>
                                                            <a
                                                              className="dropdown-item"
                                                              onClick={() =>
                                                                handleShowInWarranty(
                                                                  data.id,
                                                                  data.jobType,
                                                                  "residential"
                                                                )
                                                              }
                                                            >
                                                              {" "}
                                                              <img src="images/home_warranty.png" />{" "}
                                                              Show in Warranty
                                                              &nbsp;&nbsp;
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/construction"
                                                        state={data}
                                                      >
                                                        {data.jobNumber}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/construction"
                                                        state={data}
                                                      >
                                                        {data.unit ?? "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/construction"
                                                        state={data}
                                                      >
                                                        {data.client ?? "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/construction"
                                                        state={data}
                                                      >
                                                        {data.footage ?? "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/construction"
                                                        state={data}
                                                      >
                                                        {data?.warranties ??
                                                          "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/construction"
                                                        state={data}
                                                      >
                                                        {data?.description ??
                                                          "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      {/* <a to="javascript:void(0);">
                                       <i
                                         className="fa fa-caret-down"
                                         aria-hidden="true"
                                       />
                                     </a> */}
                                                    </td>
                                                    <td>
                                                      <div className="TodoUser TodoUserHome">
                                                        <div className="TodoUserJob">
                                                          <div className="dropdown">
                                                            <p data-toggle="dropdown">
                                                              <span>
                                                                <img src="images/three-dots.png" />
                                                              </span>
                                                            </p>
                                                            <div
                                                              className="dropdown-menu"
                                                              aria-labelledby="dropdownMenuOffset"
                                                            >
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="javascript:void(0);"
                                                                >
                                                                  <img src="images/add_build.png" />{" "}
                                                                  Add to/Edit
                                                                  Build
                                                                  <img src="images/rightarrow2.png" />
                                                                </a>
                                                              </li>
                                                              {/* <li>
                                                                <a
                                                                  onClick={() => {
                                                                    setJobDetialsModal(
                                                                      true
                                                                    );
                                                                    setSelectedId(
                                                                      item?.id
                                                                    );
                                                                    setJobName(
                                                                      "Clent View Config"
                                                                    );
                                                                  }}
                                                                >
                                                                  <img src="images/details.png" />
                                                                  Details
                                                                </a>
                                                              </li> */}
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="javascript:void(0);"
                                                                >
                                                                  <img src="images/change_order.png" />{" "}
                                                                  Change Order
                                                                  <img src="images/rightarrow2.png" />
                                                                </a>
                                                                <ul className="dropdown-menu submenu submenuhome">
                                                                  <li>
                                                                    <a
                                                                      to="change_order_list.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/change_order.png" />
                                                                      Change
                                                                      orders
                                                                      List
                                                                    </a>
                                                                  </li>
                                                                </ul>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="design_selections.html"
                                                                >
                                                                  <img src="images/design.png" />
                                                                  Design
                                                                </a>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="javascript:void(0);"
                                                                  data-toggle="modal"
                                                                  data-targer="#ClientContractDetailsNoUnit"
                                                                >
                                                                  <img src="images/sales.png" />
                                                                  Sales
                                                                </a>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="javascript:void(0);"
                                                                >
                                                                  <img src="images/estimation.png" />{" "}
                                                                  Estimation
                                                                  <img src="images/rightarrow2.png" />
                                                                </a>
                                                                <ul className="dropdown-menu submenu submenuhome">
                                                                  <li>
                                                                    <a
                                                                      to="budget_details.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/estimation.png" />
                                                                      Budget
                                                                      Details
                                                                    </a>
                                                                  </li>
                                                                </ul>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="construction.html"
                                                                >
                                                                  <img src="images/construction_icon.png" />{" "}
                                                                  Construction
                                                                  <img src="images/rightarrow2.png" />
                                                                </a>
                                                                <ul className="dropdown-menu submenu submenuhome">
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_1.png" />{" "}
                                                                      Schedule
                                                                      View
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_2.png" />{" "}
                                                                      Gantt View
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_3.png" />{" "}
                                                                      Calendar
                                                                      View
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_4.png" />{" "}
                                                                      List View
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_5.png" />{" "}
                                                                      Baseline
                                                                    </a>
                                                                  </li>
                                                                </ul>
                                                              </li>
                                                              <li>
                                                                <Link
                                                                  className="dropdown-item"
                                                                  to="/warranty-dashboard"
                                                                >
                                                                  <img src="images/warranty.png" />
                                                                  Warranty
                                                                </Link>
                                                              </li>
                                                              <li>
                                                                <Link
                                                                  className="dropdown-item"
                                                                  to="/models"
                                                                >
                                                                  <img src="images/model.png" />{" "}
                                                                  Model
                                                                  <img src="images/rightarrow2.png" />
                                                                </Link>
                                                                {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="reports.html"
                                                                >
                                                                  <img src="images/reports.png" />
                                                                  Reports
                                                                </a>
                                                              </li>
                                                              <li>
                                                                <Link
                                                                  className="dropdown-item"
                                                                  to={`/client-view-config`}
                                                                  state={{
                                                                    item,
                                                                  }}
                                                                >
                                                                  <img src="images/view_config.png" />
                                                                  Views Configs
                                                                </Link>
                                                              </li>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        : ""}
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {designSelectionShow && (
              <>
                <div className="JobTableHeadersWarranty">
                  <div className="JobTableHeaderWarranty">
                    <h5>Design Selection Job/Unit List</h5>
                    <div className="Actions">
                      <a>
                        <img src="images/icon-52.png" />
                      </a>
                      <a>
                        <img src="images/icon-51.png" />
                      </a>
                      <a>
                        <img src="images/icon-53.png" />
                      </a>
                      <a>
                        <img src="images/icon-54.png" />
                      </a>
                      <a>
                        <img src="images/icon-55.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="JobTableWarranty">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Job Number</th>
                        <th>Build Type</th>
                        <th>Address</th>
                        {/* <th>Lot</th>
                    <th>Block</th>
                    <th>Phase</th>
                    <th>Plan</th> */}
                        <th>Sub Division</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="addClasss">
                      {projectsList?.projects?.length > 0
                        ? projectsList?.projects?.map((item, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td>
                                    <div className="d-flex">
                                      {item.id}
                                      <div className="dropdown">
                                        <span
                                          className="Dots"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </span>
                                        <div className="dropdown-menu">
                                          <a
                                            onClick={() =>
                                              handleArchiveJobs(
                                                item.id,
                                                item.jobType,
                                                "residential"
                                              )
                                            }
                                            className="dropdown-item"
                                          >
                                            {" "}
                                            <img src="images/archive_job.png" />{" "}
                                            Archive Job
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <a
                                      style={{ color: "red" }}
                                      id="hidtableshow2"
                                      onClick={() =>
                                        handleJobListByProject(item.id, i)
                                      }
                                    >
                                      {item.jobNumber}&nbsp;
                                      <img
                                        src={"images/down-arrow.png"}
                                        style={{
                                          transform: `rotate(${
                                            resdentalId === i
                                              ? "180deg"
                                              : "0deg"
                                          })`,
                                        }}
                                      />
                                    </a>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/design-selections"
                                      state={item}
                                    >
                                      {item.projectType}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/design-selections"
                                      state={item}
                                    >
                                      {item.projectAddress}
                                    </Link>
                                  </td>
                                  {/* <td>{item.lot}</td>
                              <td>{item.block}</td>
                              <td>{item.phase}</td>
                              <td /> */}

                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/design-selections"
                                      state={item}
                                    >
                                      {/* {item?.subdivisions?.map((x) => x)} */}
                                    </Link>
                                  </td>
                                  <td>
                                    <div className="TodoUser TodoUserHome">
                                      <div className="TodoUserJob">
                                        <div className="dropdown">
                                          <p data-toggle="dropdown">
                                            <span>
                                              <img src="images/three-dots.png" />
                                            </span>
                                          </p>
                                          <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuOffset"
                                          >
                                            <li>
                                              <a className="dropdown-item">
                                                <img src="images/add_build.png" />{" "}
                                                Add to/Edit Build
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                            </li>
                                            {/* <li>
                                              <a
                                                onClick={() => {
                                                  setJobDetialsModal(true);
                                                  setSelectedId(item?.id);
                                                  setJobName(
                                                    "Design Selection"
                                                  );
                                                }}
                                              >
                                                {" "}
                                                <img src="images/details.png" />
                                                Details
                                              </a>
                                            </li> */}
                                            <li>
                                              <Link
                                                to="/change-order-list"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/change_order.png" />{" "}
                                                Change Order
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <Link
                                                    to="/change-order-list"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/change_order.png" />
                                                    Change orders List
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/design-selections"
                                                state={item}
                                              >
                                                <img src="images/design.png" />
                                                Design
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                to="/sales"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/sales.png" />
                                                Sales
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                to="/estimation"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/estimation.png" />{" "}
                                                Estimation
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="budget_details.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/estimation.png" />
                                                    Budget Details
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                to="/construction"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/construction_icon.png" />{" "}
                                                Construction
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_1.png" />{" "}
                                                    Schedule View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_2.png" />{" "}
                                                    Gantt View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_3.png" />{" "}
                                                    Calendar View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_4.png" />{" "}
                                                    List View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_5.png" />{" "}
                                                    Baseline
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/warranty-dashboard"
                                                state={item}
                                              >
                                                <img src="images/warranty.png" />
                                                Warranty
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/models"
                                                state={item}
                                              >
                                                <img src="images/model.png" />{" "}
                                                Model
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu">
                                                <li>
                                                  <Link
                                                    to="/models"
                                                    state={item}
                                                    className="dropdown-item text-center"
                                                  >
                                                    <img src="images/model.png" />{" "}
                                                    Create New Model
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                to="/reports"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/reports.png" />
                                                Reports
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to={`/client-view-config`}
                                                state={{ item }}
                                              >
                                                <img src="images/view_config.png" />
                                                Views Configs
                                              </Link>
                                            </li>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td colSpan={10} className="padding_none">
                                    <div
                                      className="JobBoxTable"
                                      style={{
                                        display:
                                          resdentalId == i ? "block" : "none",
                                      }}
                                    >
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Unit Job Number</th>
                                            <th>Unit#</th>
                                            <th>Primary Client</th>
                                            <th>Square Footage</th>
                                            <th>Warranty Requests</th>
                                            <th>Description</th>
                                            <th>Action</th>
                                            <th />
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {resdentalId == i &&
                                            listProjectJobs?.jobs?.length > 0 &&
                                            listProjectJobs?.jobs?.map(
                                              (data, index) => {
                                                return (
                                                  <tr key={index}>
                                                    <td>
                                                      <div className="d-flex">
                                                        {data.id}
                                                        <div className="dropdown">
                                                          <span
                                                            className="Dots"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                          >
                                                            <img src="images/dots.png" />
                                                          </span>
                                                          <div className="dropdown-menu">
                                                            <a
                                                              onClick={() =>
                                                                handleArchiveJobs(
                                                                  data.id,
                                                                  data.jobType,
                                                                  "residential"
                                                                )
                                                              }
                                                              className="dropdown-item"
                                                            >
                                                              {" "}
                                                              <img src="images/archive_job.png" />{" "}
                                                              Archive Job
                                                            </a>
                                                            <a
                                                              className="dropdown-item"
                                                              onClick={() =>
                                                                handleShowInWarranty(
                                                                  data.id,
                                                                  data.jobType,
                                                                  "residential"
                                                                )
                                                              }
                                                            >
                                                              {" "}
                                                              <img src="images/home_warranty.png" />{" "}
                                                              Show in Warranty
                                                              &nbsp;&nbsp;
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/design-selections"
                                                        state={data}
                                                      >
                                                        {data.jobNumber}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/design-selections"
                                                        state={data}
                                                      >
                                                        {data.unit ?? "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/design-selections"
                                                        state={data}
                                                      >
                                                        {data.client ?? "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/design-selections"
                                                        state={data}
                                                      >
                                                        {data.footage ?? "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/design-selections"
                                                        state={data}
                                                      >
                                                        {data?.warranties ??
                                                          "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/design-selections"
                                                        state={data}
                                                      >
                                                        {data?.description ??
                                                          "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      {/* <a to="javascript:void(0);">
                                      <i
                                        className="fa fa-caret-down"
                                        aria-hidden="true"
                                      />
                                    </a> */}
                                                    </td>
                                                    <td>
                                                      <div className="TodoUser TodoUserHome">
                                                        <div className="TodoUserJob">
                                                          <div className="dropdown">
                                                            <p data-toggle="dropdown">
                                                              <span>
                                                                <img src="images/three-dots.png" />
                                                              </span>
                                                            </p>
                                                            <div
                                                              className="dropdown-menu"
                                                              aria-labelledby="dropdownMenuOffset"
                                                            >
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="javascript:void(0);"
                                                                >
                                                                  <img src="images/add_build.png" />{" "}
                                                                  Add to/Edit
                                                                  Build
                                                                  <img src="images/rightarrow2.png" />
                                                                </a>
                                                              </li>
                                                              {/* <li>
                                                                <a
                                                                  onClick={() => {
                                                                    setJobDetialsModal(
                                                                      true
                                                                    );
                                                                    setSelectedId(
                                                                      item?.id
                                                                    );
                                                                    setJobName(
                                                                      "Clent View Config"
                                                                    );
                                                                  }}
                                                                >
                                                                  <img src="images/details.png" />
                                                                  Details
                                                                </a>
                                                              </li> */}
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="javascript:void(0);"
                                                                >
                                                                  <img src="images/change_order.png" />{" "}
                                                                  Change Order
                                                                  <img src="images/rightarrow2.png" />
                                                                </a>
                                                                <ul className="dropdown-menu submenu submenuhome">
                                                                  <li>
                                                                    <a
                                                                      to="change_order_list.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/change_order.png" />
                                                                      Change
                                                                      orders
                                                                      List
                                                                    </a>
                                                                  </li>
                                                                </ul>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="design_selections.html"
                                                                >
                                                                  <img src="images/design.png" />
                                                                  Design
                                                                </a>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="javascript:void(0);"
                                                                  data-toggle="modal"
                                                                  data-targer="#ClientContractDetailsNoUnit"
                                                                >
                                                                  <img src="images/sales.png" />
                                                                  Sales
                                                                </a>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="javascript:void(0);"
                                                                >
                                                                  <img src="images/estimation.png" />{" "}
                                                                  Estimation
                                                                  <img src="images/rightarrow2.png" />
                                                                </a>
                                                                <ul className="dropdown-menu submenu submenuhome">
                                                                  <li>
                                                                    <a
                                                                      to="budget_details.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/estimation.png" />
                                                                      Budget
                                                                      Details
                                                                    </a>
                                                                  </li>
                                                                </ul>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="construction.html"
                                                                >
                                                                  <img src="images/construction_icon.png" />{" "}
                                                                  Construction
                                                                  <img src="images/rightarrow2.png" />
                                                                </a>
                                                                <ul className="dropdown-menu submenu submenuhome">
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_1.png" />{" "}
                                                                      Schedule
                                                                      View
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_2.png" />{" "}
                                                                      Gantt View
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_3.png" />{" "}
                                                                      Calendar
                                                                      View
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_4.png" />{" "}
                                                                      List View
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_5.png" />{" "}
                                                                      Baseline
                                                                    </a>
                                                                  </li>
                                                                </ul>
                                                              </li>
                                                              <li>
                                                                <Link
                                                                  className="dropdown-item"
                                                                  to="/warranty-dashboard"
                                                                >
                                                                  <img src="images/warranty.png" />
                                                                  Warranty
                                                                </Link>
                                                              </li>
                                                              <li>
                                                                <Link
                                                                  className="dropdown-item"
                                                                  to="/models"
                                                                >
                                                                  <img src="images/model.png" />{" "}
                                                                  Model
                                                                  <img src="images/rightarrow2.png" />
                                                                </Link>
                                                                {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="reports.html"
                                                                >
                                                                  <img src="images/reports.png" />
                                                                  Reports
                                                                </a>
                                                              </li>
                                                              <li>
                                                                <Link
                                                                  className="dropdown-item"
                                                                  to={`/client-view-config`}
                                                                  state={{
                                                                    item,
                                                                  }}
                                                                >
                                                                  <img src="images/view_config.png" />
                                                                  Views Configs
                                                                </Link>
                                                              </li>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        : ""}
                    </tbody>
                  </table>
                </div>
              </>
            )}

            {accountingShow && (
              <>
                <div className="JobTableHeadersWarranty">
                  <div className="JobTableHeaderWarranty">
                    <h5>Accounting Job/Unit List</h5>
                    <div className="Actions">
                      <a>
                        <img src="images/icon-52.png" />
                      </a>
                      <a>
                        <img src="images/icon-51.png" />
                      </a>
                      <a>
                        <img src="images/icon-53.png" />
                      </a>
                      <a>
                        <img src="images/icon-54.png" />
                      </a>
                      <a>
                        <img src="images/icon-55.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="JobTableWarranty">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Job Number</th>
                        <th>Build Type</th>
                        <th>Address</th>
                        {/* <th>Lot</th>
                    <th>Block</th>
                    <th>Phase</th>
                    <th>Plan</th> */}
                        <th>Sub Division</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="addClasss">
                      {projectsList?.projects?.length > 0
                        ? projectsList?.projects?.map((item, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td>
                                    <div className="d-flex">
                                      {item.id}
                                      <div className="dropdown">
                                        <span
                                          className="Dots"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </span>
                                        <div className="dropdown-menu">
                                          <a
                                            onClick={() =>
                                              handleArchiveJobs(
                                                item.id,
                                                item.jobType,
                                                "residential"
                                              )
                                            }
                                            className="dropdown-item"
                                          >
                                            {" "}
                                            <img src="images/archive_job.png" />{" "}
                                            Archive Job
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <a
                                      style={{ color: "red" }}
                                      id="hidtableshow2"
                                      onClick={() =>
                                        handleJobListByProject(item.id, i)
                                      }
                                    >
                                      {item.jobNumber}&nbsp;
                                      <img
                                        src={"images/down-arrow.png"}
                                        style={{
                                          transform: `rotate(${
                                            resdentalId === i
                                              ? "180deg"
                                              : "0deg"
                                          })`,
                                        }}
                                      />
                                    </a>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/transactions_manager"
                                      state={item}
                                    >
                                      {item.projectType}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/transactions_manager"
                                      state={item}
                                    >
                                      {item.projectAddress}
                                    </Link>
                                  </td>
                                  {/* <td>{item.lot}</td>
                              <td>{item.block}</td>
                              <td>{item.phase}</td>
                              <td /> */}

                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/transactions_manager"
                                      state={item}
                                    >
                                      {/* {item?.subdivisions?.map((x) => x)} */}
                                    </Link>
                                  </td>
                                  <td>
                                    <div className="TodoUser TodoUserHome">
                                      <div className="TodoUserJob">
                                        <div className="dropdown">
                                          <p data-toggle="dropdown">
                                            <span>
                                              <img src="images/three-dots.png" />
                                            </span>
                                          </p>
                                          <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuOffset"
                                          >
                                            <li>
                                              <a className="dropdown-item" onClick={showResidentialJobFunc}>
                                                <img src="images/add_build.png" />{" "}
                                                Create Residential Job wizard 
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                            </li>
                                            {/* <li>
                                              <a
                                                onClick={() => {
                                                  setJobDetialsModal(true);
                                                  setSelectedId(item?.id);
                                                  setJobName("Accounting");
                                                }}
                                              >
                                                {" "}
                                                <img src="images/details.png" />
                                                Details
                                              </a>
                                            </li> */}
                                            <li>
                                              <Link
                                                to="/change-order-list"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/change_order.png" />{" "}
                                                Change Order
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <Link
                                                    to="/change-order-list"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/change_order.png" />
                                                    Change orders List
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/design-selections"
                                                state={item}
                                              >
                                                <img src="images/design.png" />
                                                Design
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                to="/sales"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/sales.png" />
                                                Sales
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                to="/estimation"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/estimation.png" />{" "}
                                                Estimation
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="budget_details.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/estimation.png" />
                                                    Budget Details
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                to="/construction"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/construction_icon.png" />{" "}
                                                Construction
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_1.png" />{" "}
                                                    Schedule View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_2.png" />{" "}
                                                    Gantt View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_3.png" />{" "}
                                                    Calendar View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_4.png" />{" "}
                                                    List View
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="/construction"
                                                    state={item}
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_5.png" />{" "}
                                                    Baseline
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/warranty-dashboard"
                                                state={item}
                                              >
                                                <img src="images/warranty.png" />
                                                Warranty
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/models"
                                                state={item}
                                              >
                                                <img src="images/model.png" />{" "}
                                                Model
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              <ul className="dropdown-menu submenu">
                                                <li>
                                                  <Link
                                                    to="/models"
                                                    state={item}
                                                    className="dropdown-item text-center"
                                                  >
                                                    <img src="images/model.png" />{" "}
                                                    Create New Model
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                to="/reports"
                                                state={item}
                                                className="dropdown-item"
                                              >
                                                <img src="images/reports.png" />
                                                Reports
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to={`/client-view-config`}
                                                state={{ item }}
                                              >
                                                <img src="images/view_config.png" />
                                                Views Configs
                                              </Link>
                                            </li>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td colSpan={10} className="padding_none">
                                    <div
                                      className="JobBoxTable"
                                      style={{
                                        display:
                                          resdentalId == i ? "block" : "none",
                                      }}
                                    >
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Unit Job Number</th>
                                            <th>Unit#</th>
                                            <th>Primary Client</th>
                                            <th>Square Footage</th>
                                            <th>Warranty Requests</th>
                                            <th>Description</th>
                                            <th>Action</th>
                                            <th />
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {resdentalId == i &&
                                            listProjectJobs?.jobs?.length > 0 &&
                                            listProjectJobs?.jobs?.map(
                                              (data, index) => {
                                                return (
                                                  <tr key={index}>
                                                    <td>
                                                      <div className="d-flex">
                                                        {item.id}
                                                        <div className="dropdown">
                                                          <span
                                                            className="Dots"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                          >
                                                            <img src="images/dots.png" />
                                                          </span>
                                                          <div className="dropdown-menu">
                                                            <a
                                                              onClick={() =>
                                                                handleArchiveJobs(
                                                                  item.id,
                                                                  item.jobType,
                                                                  "residential"
                                                                )
                                                              }
                                                              className="dropdown-item"
                                                            >
                                                              {" "}
                                                              <img src="images/archive_job.png" />{" "}
                                                              Archive Job
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/transactions_manager"
                                                        state={data}
                                                      >
                                                        {data.jobNumber}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/transactions_manager"
                                                        state={data}
                                                      >
                                                        {data.unit ?? "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/transactions_manager"
                                                        state={data}
                                                      >
                                                        {data.client ?? "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/transactions_manager"
                                                        state={data}
                                                      >
                                                        {data.footage ?? "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/transactions_manager"
                                                        state={data}
                                                      >
                                                        {data?.warranties ??
                                                          "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        to="/transactions_manager"
                                                        state={data}
                                                      >
                                                        {data?.description ??
                                                          "N/A"}
                                                      </Link>
                                                    </td>
                                                    <td>
                                                      {/* <a to="javascript:void(0);">
                                      <i
                                        className="fa fa-caret-down"
                                        aria-hidden="true"
                                      />
                                    </a> */}
                                                    </td>
                                                    <td>
                                                      <div className="TodoUser TodoUserHome">
                                                        <div className="TodoUserJob">
                                                          <div className="dropdown">
                                                            <p data-toggle="dropdown">
                                                              <span>
                                                                <img src="images/three-dots.png" />
                                                              </span>
                                                            </p>
                                                            <div
                                                              className="dropdown-menu"
                                                              aria-labelledby="dropdownMenuOffset"
                                                            >
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="javascript:void(0);"
                                                                >
                                                                  <img src="images/add_build.png" />{" "}
                                                                  Add to/Edit
                                                                  Build
                                                                  <img src="images/rightarrow2.png" />
                                                                </a>
                                                              </li>
                                                              {/* <li>
                                                                <a
                                                                  onClick={() => {
                                                                    setJobDetialsModal(
                                                                      true
                                                                    );
                                                                    setSelectedId(
                                                                      item?.id
                                                                    );
                                                                    setJobName(
                                                                      "Clent View Config"
                                                                    );
                                                                  }}
                                                                >
                                                                  <img src="images/details.png" />
                                                                  Details
                                                                </a>
                                                              </li> */}
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="javascript:void(0);"
                                                                >
                                                                  <img src="images/change_order.png" />{" "}
                                                                  Change Order
                                                                  <img src="images/rightarrow2.png" />
                                                                </a>
                                                                <ul className="dropdown-menu submenu submenuhome">
                                                                  <li>
                                                                    <a
                                                                      to="change_order_list.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/change_order.png" />
                                                                      Change
                                                                      orders
                                                                      List
                                                                    </a>
                                                                  </li>
                                                                </ul>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="design_selections.html"
                                                                >
                                                                  <img src="images/design.png" />
                                                                  Design
                                                                </a>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="javascript:void(0);"
                                                                  data-toggle="modal"
                                                                  data-targer="#ClientContractDetailsNoUnit"
                                                                >
                                                                  <img src="images/sales.png" />
                                                                  Sales
                                                                </a>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="javascript:void(0);"
                                                                >
                                                                  <img src="images/estimation.png" />{" "}
                                                                  Estimation
                                                                  <img src="images/rightarrow2.png" />
                                                                </a>
                                                                <ul className="dropdown-menu submenu submenuhome">
                                                                  <li>
                                                                    <a
                                                                      to="budget_details.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/estimation.png" />
                                                                      Budget
                                                                      Details
                                                                    </a>
                                                                  </li>
                                                                </ul>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="construction.html"
                                                                >
                                                                  <img src="images/construction_icon.png" />{" "}
                                                                  Construction
                                                                  <img src="images/rightarrow2.png" />
                                                                </a>
                                                                <ul className="dropdown-menu submenu submenuhome">
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_1.png" />{" "}
                                                                      Schedule
                                                                      View
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_2.png" />{" "}
                                                                      Gantt View
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_3.png" />{" "}
                                                                      Calendar
                                                                      View
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_4.png" />{" "}
                                                                      List View
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      to="construction.html"
                                                                      className="dropdown-item"
                                                                    >
                                                                      <img src="images/construuction_5.png" />{" "}
                                                                      Baseline
                                                                    </a>
                                                                  </li>
                                                                </ul>
                                                              </li>
                                                              <li>
                                                                <Link
                                                                  className="dropdown-item"
                                                                  to="/warranty-dashboard"
                                                                >
                                                                  <img src="images/warranty.png" />
                                                                  Warranty
                                                                </Link>
                                                              </li>
                                                              <li>
                                                                <Link
                                                                  className="dropdown-item"
                                                                  to="/models"
                                                                >
                                                                  <img src="images/model.png" />{" "}
                                                                  Model
                                                                  <img src="images/rightarrow2.png" />
                                                                </Link>
                                                                {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  to="reports.html"
                                                                >
                                                                  <img src="images/reports.png" />
                                                                  Reports
                                                                </a>
                                                              </li>
                                                              <li>
                                                                <Link
                                                                  className="dropdown-item"
                                                                  to={`/client-view-config`}
                                                                  state={{
                                                                    item,
                                                                  }}
                                                                >
                                                                  <img src="images/view_config.png" />
                                                                  Views Configs
                                                                </Link>
                                                              </li>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        : ""}
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {/* <div className='clear'></div> */}

            {/* multi family owl carousel  */}
            <div className="MultiFamilyCommonBoxArea">
              <div className="CommonBoxArea ">
                <div className="MainTitle">
                  <h2>Multi-Family</h2>
                </div>
                {/* <div className="home-carousel owl-carousel owl-theme"> */}
                <OwlCarousel options={options}>
                  <div className="item">
                    <div
                      className="CommonBox CommonBoxWarranty"
                      onClick={() => handleMultiAccountingShow()}
                    >
                      <div className="CommonBoxHeader">
                        <h6>Accounting</h6>
                        <h5>{multiFamilyHomeListData?.accounting?.count}</h5>
                        <figure>
                          <img src="images/box-6.png" />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(
                          multiFamilyHomeListData?.accounting?.accounting
                        ) &&
                          multiFamilyHomeListData?.accounting?.accounting?.map(
                            (ele, i) => {
                              //  console.log('eleleleleel',ele)
                              return (
                                <>
                                  <div className="CommonBoxBodyUser">
                                    <div className="CommonBoxBodyTitle">
                                      <span>MB</span>
                                      <div className="">
                                        <h6>{ele?.tranId}</h6>
                                        <p>{ele?.notes}</p>
                                      </div>
                                    </div>

                                    <figure>
                                      <img src="images/taskimg-1.png" />
                                    </figure>
                                    <span className="CbbOverlay" />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>{multiFamilyHomeListData?.estimation?.count}</h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div
                      className="CommonBox CommonBoxWarranty"
                      onClick={() => handleMultiChangeOrderShow()}
                    >
                      <div className="CommonBoxHeader">
                        <h6>Change Order</h6>
                        <h5>{multiFamilyHomeListData?.changeOrder?.count}</h5>
                        <figure>
                          <img src="images/box-6.png" />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(
                          multiFamilyHomeListData?.changeOrder?.changeOrder
                        ) &&
                          multiFamilyHomeListData?.changeOrder?.changeOrder?.map(
                            (ele, i) => {
                              return (
                                <>
                                  <div className="CommonBoxBodyUser">
                                    <div className="CommonBoxBodyTitle">
                                      <span>MB</span>
                                      <div className="">
                                        <h6>
                                          {ele?.ResidentialJob?.subdividision}
                                        </h6>
                                        <p>{ele?.ResidentialJob?.jobNumber}</p>
                                      </div>
                                    </div>

                                    <figure>
                                      <img src="images/taskimg-1.png" />
                                    </figure>
                                    <span className="CbbOverlay" />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>{multiFamilyHomeListData?.changeOrder?.count}</h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="CommonBox CommonBoxViewConfig"
                      onClick={handleMultiClientViewConfigShow}
                    >
                      <div className="CommonBoxHeader">
                        <h6>Client View Config</h6>
                        <h5>
                          {multiFamilyHomeListData?.clientCount?.count ?? 0}
                        </h5>
                        <figure>
                          <img src="images/box-13.png" />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(
                          multiFamilyHomeListData?.clientCount?.clientView
                        ) &&
                          multiFamilyHomeListData?.clientCount?.clientView?.map(
                            (ele, i) => {
                              return (
                                <>
                                  <div className="CommonBoxBodyUser">
                                    <div className="CommonBoxBodyTitle">
                                      <span>MB</span>
                                      <div className="">
                                        <h6>
                                          {ele?.ResidentialJob?.subdividision}
                                        </h6>
                                        <p>{ele?.ResidentialJob?.jobNumber}</p>
                                      </div>
                                    </div>

                                    <figure>
                                      <img src="images/taskimg-1.png" />
                                    </figure>
                                    <span className="CbbOverlay" />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>
                          {multiFamilyHomeListData?.clientCount?.count ?? 0}
                        </h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="CommonBox CommonBoxTable"
                      onClick={handleMultiConstructionShow}
                    >
                      <div className="CommonBoxHeader">
                        <h6>Construction</h6>
                        <h5>{residentialHomeListData?.construction?.count}</h5>
                        <figure>
                          <img src={box2} />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(
                          residentialHomeListData?.construction?.construction
                        ) &&
                          residentialHomeListData?.construction?.construction?.map(
                            (ele, i) => {
                              return (
                                <>
                                  <div className="CommonBoxBodyUser">
                                    <div className="CommonBoxBodyTitle">
                                      <span>MB</span>
                                      <div className="">
                                        <h6>
                                          {ele?.ResidentialJob?.subdividision}
                                        </h6>
                                        <p>{ele?.ResidentialJob?.jobNumber}</p>
                                      </div>
                                    </div>

                                    <figure>
                                      <img src="images/taskimg-1.png" />
                                    </figure>
                                    <span className="CbbOverlay" />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>{residentialHomeListData?.construction?.count}</h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div
                      className="CommonBox"
                      onClick={() => handleMultiDesignnShow()}
                    >
                      <div className="CommonBoxHeader">
                        {/* <Link to="/design-selections"> */}
                        <h6>Design Selections</h6>
                        <h5>
                          {multiFamilyHomeListData?.designSelections?.count}
                        </h5>
                        {/* </Link> */}
                        <figure>
                          <img src="images/box-6.png" />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(
                          multiFamilyHomeListData?.designSelections
                            ?.designSelections
                        ) &&
                          multiFamilyHomeListData?.designSelections?.designSelections?.map(
                            (ele, i) => {
                              return (
                                <>
                                  <div className="CommonBoxBodyUser">
                                    <div className="CommonBoxBodyTitle">
                                      <span>MB</span>
                                      <div className="">
                                        <h6>
                                          {ele?.ResidentialJob?.subdividision}
                                        </h6>
                                        <p>{ele?.ResidentialJob?.jobNumber}</p>
                                      </div>
                                    </div>

                                    <figure>
                                      <img src="images/taskimg-1.png" />
                                    </figure>
                                    <span className="CbbOverlay" />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>
                          {multiFamilyHomeListData?.designSelections?.count}
                        </h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="CommonBox CommonBoxWarranty"
                      onClick={() => handleMultiEstimationrShow()}
                    >
                      <div className="CommonBoxHeader">
                        <h6>Estimation</h6>
                        <h5>{multiFamilyHomeListData?.estimation?.count}</h5>
                        <figure>
                          <img src="images/box-6.png" />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(
                          multiFamilyHomeListData?.estimation?.estimation
                        ) &&
                          multiFamilyHomeListData?.estimation?.estimation?.map(
                            (ele, i) => {
                              return (
                                <>
                                  <div className="CommonBoxBodyUser">
                                    <div className="CommonBoxBodyTitle">
                                      <span>MB</span>
                                      <div className="">
                                        <h6>
                                          {ele?.ResidentialJob?.subdividision}
                                        </h6>
                                        <p>{ele?.ResidentialJob?.jobNumber}</p>
                                      </div>
                                    </div>

                                    <figure>
                                      <img src="images/taskimg-1.png" />
                                    </figure>
                                    <span className="CbbOverlay" />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>{multiFamilyHomeListData?.estimation?.count}</h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div
                      className="CommonBox CommonBoxWarranty"
                      onClick={() => handleMultiWarrantyShow()}
                    >
                      <div className="CommonBoxHeader">
                        <h6>Warranty</h6>
                        <h5>{multiFamilyHomeListData?.warranties?.count}</h5>

                        <figure>
                          <img src={box1} />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(
                          multiFamilyHomeListData?.warranties?.warranties
                        ) &&
                          multiFamilyHomeListData?.warranties?.warranties?.map(
                            (ele, i) => {
                              return (
                                <>
                                  <div className="CommonBoxBodyUser">
                                    <div className="CommonBoxBodyTitle">
                                      <span>MB</span>
                                      <div className="">
                                        <h6>{ele?.subdividision}</h6>
                                        <p>{ele?.jobNumber}</p>
                                      </div>
                                    </div>

                                    <figure>
                                      <img src="images/taskimg-1.png" />
                                    </figure>
                                    <span className="CbbOverlay" />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>{multiFamilyHomeListData?.warranties?.count}</h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>

                {/* </div> */}
              </div>
            </div>
            {multiWarrantyShow && (
              <>
                <div
                  className="CommonTableArea MultiFamilyConstructionTable"
                  style={{ display: "block" }}
                >
                  <div className="JobTableHeaderOuter">
                    <div className="JobTableHeaderInner">
                      <h5>Warranty Job/Unit List</h5>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#LegalDescriptionSettings"
                        >
                          <img src="images/icon-52.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-51.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-54.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-55.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="JobTableCommon">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Job Number</th>
                          <th>Build Type</th>
                          <th>Address</th>
                          {/* <th>Lot</th>
                      <th>Block</th>
                      <th>Phase</th>
                      <th>Plan</th> */}
                          <th>Sub Division</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="addClasss JobTabletbodyRed">
                        {warrantyJobsList?.data?.length > 0
                          ? warrantyJobsList?.data?.map((item, i) => {
                              return (
                                <>
                                  <tr key={i}>
                                    <td>{item.id}</td>
                                    <td>
                                      <Link
                                        to="/warranty-dashboard"
                                        state={item}
                                      >
                                        {item.jobNumber ?? "N/A"}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to="/warranty-dashboard"
                                        state={item}
                                      >
                                        {item.projectType}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to="/warranty-dashboard"
                                        state={item}
                                      >
                                        {item.projectAddress}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to="/warranty-dashboard"
                                        state={item}
                                      >
                                        {/* {item?.subdivisions?.map((x) => x)} */}
                                      </Link>
                                    </td>
                                    <td>
                                      <div className="TodoUser TodoUserHome">
                                        <div className="TodoUserJob">
                                          <div className="dropdown">
                                            <p data-toggle="dropdown">
                                              <span>
                                                <img src="images/three-dots.png" />
                                              </span>
                                            </p>
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuOffset"
                                            >
                                              <li>
                                                <a
                                                  onClick={() => {
                                                    setCreateBlockModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName("Warranty");
                                                    setJobData(item)
                                                  }}
                                                >
                                                  <img src="images/add_build.png" />{" "}
                                                  Add to/Edit Build
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={() => {
                                                    setJobDetialsModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName("Warranty");
                                                  }}
                                                >
                                                  <img src="images/details.png" />
                                                  Details
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/change_order.png" />{" "}
                                                  Change Order
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="change_order_list.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/change_order.png" />
                                                      Change orders List
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="design_selections.html"
                                                >
                                                  <img src="images/design.png" />
                                                  Design
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                  data-toggle="modal"
                                                  data-targer="#ClientContractDetailsNoUnit"
                                                >
                                                  <img src="images/sales.png" />
                                                  Sales
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/estimation.png" />{" "}
                                                  Estimation
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="budget_details.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/estimation.png" />
                                                      Budget Details
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="construction.html"
                                                >
                                                  <img src="images/construction_icon.png" />{" "}
                                                  Construction
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_1.png" />{" "}
                                                      Schedule View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_2.png" />{" "}
                                                      Gantt View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_3.png" />{" "}
                                                      Calendar View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_4.png" />{" "}
                                                      List View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_5.png" />{" "}
                                                      Baseline
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/warranty-dashboard"
                                                >
                                                  <img src="images/warranty.png" />
                                                  Warranty
                                                </Link>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/models"
                                                >
                                                  <img src="images/model.png" />{" "}
                                                  Model
                                                  <img src="images/rightarrow2.png" />
                                                </Link>
                                                {/* <ul className="dropdown-menu submenu">
            <li>
              <a
                to="javascript:void(0);"
                className="dropdown-item text-center"
                data-toggle="modal"
                data-target="#CreateNewModelHome"
              >
                <img src="images/model.png" /> Create New Model
              </a>
            </li>
          </ul> */}
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="reports.html"
                                                >
                                                  <img src="images/reports.png" />
                                                  Reports
                                                </a>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to={`/client-view-config`}
                                                  state={{ item }}
                                                >
                                                  <img src="images/view_config.png" />
                                                  Views Configs
                                                </Link>
                                              </li>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}

            {multiConstructionShow && (
              <>
                <div
                  className="CommonTableArea MultiFamilyConstructionTable"
                  style={{ display: "block" }}
                >
                  <div className="JobTableHeaderOuter">
                    <div className="JobTableHeaderInner">
                      <h5>Construction Job/Unit List</h5>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#LegalDescriptionSettings"
                        >
                          <img src="images/icon-52.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-51.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-54.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-55.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="JobTableCommon">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Job Number</th>
                          <th>Build Type</th>
                          <th>Address</th>
                          <th>Lot</th>
                          <th>Block</th>
                          <th>Phase</th>
                          <th>Plan</th>
                          <th>Sub Division</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="addClasss JobTabletbodyRed">
                        {multiFamilyProjectList?.projects?.length > 0 ? (
                          multiFamilyProjectList?.projects?.map((item, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td style={{ color: "green" }}>
                                    <div className="d-flex">
                                      {item.id}
                                      <div className="dropdown">
                                        <span
                                          className="Dots"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </span>
                                        <div className="dropdown-menu">
                                          <a
                                            onClick={() =>
                                              handleArchiveJobs(
                                                item.id,
                                                item.jobType,
                                                "multi"
                                              )
                                            }
                                            className="dropdown-item"
                                          >
                                            {" "}
                                            <img src="images/archive_job.png" />{" "}
                                            Archive Job
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td style={{ color: "green" }}>
                                    <a
                                      id="hidtableshow2"
                                      onClick={() =>
                                        handleMultiBlockListByProject(
                                          item.id,
                                          i
                                        )
                                      }
                                    >
                                      {item.jobNumber}&nbsp;
                                      <img
                                        src={"images/down-arrow.png"}
                                        style={{
                                          transform: `rotate(${
                                            resdentalId === i
                                              ? "180deg"
                                              : "0deg"
                                          })`,
                                        }}
                                      />
                                    </a>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "green" }}
                                      to="/construction"
                                      state={item}
                                    >
                                      {item.projectType}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "green" }}
                                      to="/construction"
                                      state={item}
                                    >
                                      {item.projectAddress}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "green" }}
                                      Link
                                      to="/construction"
                                      state={item}
                                    >
                                      {item.lot}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "green" }}
                                      Link
                                      to="/construction"
                                      state={item}
                                    >
                                      {item.block}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "green" }}
                                      Link
                                      to="/construction"
                                      state={item}
                                    >
                                      {item.phase}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "green" }}
                                      Link
                                      to="/construction"
                                      state={item}
                                    >
                                      {item.plan ?? "N/A"}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "green" }}
                                      to="/construction"
                                      state={item}
                                    >
                                      {/* {item?.subdivisions?.map((x) => x)} */}
                                    </Link>
                                  </td>
                                  <td>
                                    <div className="TodoUser TodoUserHome">
                                      <div className="TodoUserJob">
                                        <div className="dropdown">
                                          <p data-toggle="dropdown">
                                            <span>
                                              <img src="images/three-dots.png" />
                                            </span>
                                          </p>
                                          <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuOffset"
                                          >
                                            <li>
                                              <a
                                                onClick={() => {
                                                  setCreateBlockModal(true);
                                                  setSelectedId(item?.id);
                                                  setJobName("Warranty");
                                                  setJobData(item)

                                                }}
                                              >
                                                <img src="images/add_build.png" />{" "}
                                                Add to/Edit Build
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                            </li>
                                            {/* <li>
                                              <a
                                                onClick={() => {
                                                  setJobDetialsModal(true);
                                                  setSelectedId(item?.id);
                                                  setJobName("Construction");
                                                }}
                                              >
                                                <img src="images/details.png" />
                                                Details
                                              </a>
                                            </li> */}
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="javascript:void(0);"
                                              >
                                                <img src="images/change_order.png" />{" "}
                                                Change Order
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="change_order_list.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/change_order.png" />
                                                    Change orders List
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="design_selections.html"
                                              >
                                                <img src="images/design.png" />
                                                Design
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="javascript:void(0);"
                                                data-toggle="modal"
                                                data-targer="#ClientContractDetailsNoUnit"
                                              >
                                                <img src="images/sales.png" />
                                                Sales
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="javascript:void(0);"
                                              >
                                                <img src="images/estimation.png" />{" "}
                                                Estimation
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="budget_details.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/estimation.png" />
                                                    Budget Details
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="construction.html"
                                              >
                                                <img src="images/construction_icon.png" />{" "}
                                                Construction
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_1.png" />{" "}
                                                    Schedule View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_2.png" />{" "}
                                                    Gantt View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_3.png" />{" "}
                                                    Calendar View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_4.png" />{" "}
                                                    List View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_5.png" />{" "}
                                                    Baseline
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/warranty-dashboard"
                                              >
                                                <img src="images/warranty.png" />
                                                Warranty
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/models"
                                              >
                                                <img src="images/model.png" />{" "}
                                                Model
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="reports.html"
                                              >
                                                <img src="images/reports.png" />
                                                Reports
                                              </a>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to={`/client-view-config`}
                                                state={{ item }}
                                              >
                                                <img src="images/view_config.png" />
                                                Views Configs
                                              </Link>
                                            </li>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td colSpan={10} className="padding_none">
                                    <div
                                      className="JobBoxTable"
                                      style={{
                                        display:
                                          resdentalId == i ? "block" : "none",
                                      }}
                                    >
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Unit Job Number</th>
                                            <th>Unit#</th>
                                            <th>Primary Client</th>
                                            <th>Square Footage</th>
                                            <th>Warranty Requests</th>
                                            <th>Description</th>
                                            <th>Actions</th>
                                            <th />
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {resdentalId == i &&
                                            multiblocksList?.length > 0 &&
                                            multiblocksList?.map(
                                              (data, index) => {
                                                let tempMultiStoredata =
                                                  data?.jobType ==
                                                    "MultiBlock" &&
                                                  [
                                                    ...data?.MultiUnits,
                                                    ...data?.MultiStories,
                                                  ]?.flat();

                                                return (
                                                  <>
                                                    <tr key={index}>
                                                      <td>
                                                        <div className="d-flex">
                                                          {data.id}
                                                          <div className="dropdown">
                                                            <span
                                                              className="Dots"
                                                              data-toggle="dropdown"
                                                              aria-haspopup="true"
                                                              aria-expanded="false"
                                                            >
                                                              <img src="images/dots.png" />
                                                            </span>
                                                            <div className="dropdown-menu">
                                                              <a
                                                                onClick={() =>
                                                                  handleArchiveJobs(
                                                                    data.id,
                                                                    data.jobType,
                                                                    "multi"
                                                                  )
                                                                }
                                                                className="dropdown-item"
                                                              >
                                                                {" "}
                                                                <img src="images/archive_job.png" />{" "}
                                                                Archive Job
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        {/* <Link
                                                    to="/change-order-list"
                                                    state={data}
                                                  > */}
                                                        {data?.jobNumber ??
                                                          "N.A"}
                                                        {/* </Link> */}
                                                        &nbsp;
                                                        {data?.jobType ==
                                                          "MultiBlock" && (
                                                          <img
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              e.stopPropagation();
                                                              updateState(
                                                                (prev) => ({
                                                                  ...prev,
                                                                  multiJobUnitOpenIndex:
                                                                    prev.multiJobUnitOpenIndex ==
                                                                    index
                                                                      ? -1
                                                                      : index,
                                                                })
                                                              );
                                                            }}
                                                            src={
                                                              "images/down-arrow.png"
                                                            }
                                                            style={{
                                                              transform: `rotate(${
                                                                multiJobUnitOpenIndex ===
                                                                index
                                                                  ? "180deg"
                                                                  : "0deg"
                                                              })`,
                                                            }}
                                                          />
                                                        )}
                                                      </td>
                                                      <td>
                                                        <Link
                                                          to="/construction"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data.unit ?? "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          to="/construction"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data.client ?? "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          to="/construction"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data.footage ??
                                                            "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          to="/construction"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data?.warranties ??
                                                            "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          to="/construction"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data?.description ??
                                                            "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        {/* <a to="javascript:void(0);">
                                      <i
                                        className="fa fa-caret-down"
                                        aria-hidden="true"
                                      />
                                    </a> */}
                                                      </td>
                                                      <td>
                                                        <div className="TodoUser TodoUserHome">
                                                          <div className="TodoUserJob">
                                                            <div className="dropdown">
                                                              <p data-toggle="dropdown">
                                                                <span>
                                                                  <img src="images/three-dots.png" />
                                                                </span>
                                                              </p>
                                                              <div
                                                                className="dropdown-menu"
                                                                aria-labelledby="dropdownMenuOffset"
                                                              >
                                                                <li>
                                                                  <a
                                                                    onClick={() => {
                                                                      setCreateOnlyUnitsModal(
                                                                        true
                                                                      );
                                                                      setSelectedId(
                                                                        item?.id
                                                                      );
                                                                      setUnits([
                                                                        data,
                                                                      ]);
                                                                      setJobId(
                                                                        data?.MultiFamilyJobId
                                                                      );
                                                                      setJobName(
                                                                        "Warranty"
                                                                      );
                                                                    }}
                                                                  >
                                                                    <img src="images/add_build.png" />{" "}
                                                                    Add to/Edit
                                                                    Build
                                                                    <img src="images/rightarrow2.png" />
                                                                  </a>
                                                                </li>
                                                                <li>
                                                                  <a
                                                                    className="dropdown-item"
                                                                    onClick={() => {
                                                                      setJobDetailsForBlock(
                                                                        true
                                                                      );
                                                                      setSelectedId(
                                                                        item?.id
                                                                      );

                                                                      setJobData(data)

                                                                    }}

                                                                  >
                                                                    <img src="images/details.png" />
                                                                    Details
                                                                  </a>
                                                                </li>

                                                                <li>
                                                                  <a
                                                                    className="dropdown-item"
                                                                    to="javascript:void(0);"
                                                                  >
                                                                    <img src="images/change_order.png" />{" "}
                                                                    Change Order
                                                                    <img src="images/rightarrow2.png" />
                                                                  </a>
                                                                  <ul className="dropdown-menu submenu submenuhome">
                                                                    <li>
                                                                      <a
                                                                        to="change_order_list.html"
                                                                        className="dropdown-item"
                                                                      >
                                                                        <img src="images/change_order.png" />
                                                                        Change
                                                                        orders
                                                                        List
                                                                      </a>
                                                                    </li>
                                                                  </ul>
                                                                </li>
                                                                <li>
                                                                  <a
                                                                    className="dropdown-item"
                                                                    to="design_selections.html"
                                                                  >
                                                                    <img src="images/design.png" />
                                                                    Design
                                                                  </a>
                                                                </li>
                                                                <li>
                                                                  <a
                                                                    className="dropdown-item"
                                                                    to="javascript:void(0);"
                                                                    data-toggle="modal"
                                                                    data-targer="#ClientContractDetailsNoUnit"
                                                                  >
                                                                    <img src="images/sales.png" />
                                                                    Sales
                                                                  </a>
                                                                </li>
                                                                <li>
                                                                  <a
                                                                    className="dropdown-item"
                                                                    to="javascript:void(0);"
                                                                  >
                                                                    <img src="images/estimation.png" />{" "}
                                                                    Estimation
                                                                    <img src="images/rightarrow2.png" />
                                                                  </a>
                                                                  <ul className="dropdown-menu submenu submenuhome">
                                                                    <li>
                                                                      <a
                                                                        to="budget_details.html"
                                                                        className="dropdown-item"
                                                                      >
                                                                        <img src="images/estimation.png" />
                                                                        Budget
                                                                        Details
                                                                      </a>
                                                                    </li>
                                                                  </ul>
                                                                </li>
                                                                <li>
                                                                  <a
                                                                    className="dropdown-item"
                                                                    to="construction.html"
                                                                  >
                                                                    <img src="images/construction_icon.png" />{" "}
                                                                    Construction
                                                                    <img src="images/rightarrow2.png" />
                                                                  </a>
                                                                  <ul className="dropdown-menu submenu submenuhome">
                                                                    <li>
                                                                      <a
                                                                        to="construction.html"
                                                                        className="dropdown-item"
                                                                      >
                                                                        <img src="images/construuction_1.png" />{" "}
                                                                        Schedule
                                                                        View
                                                                      </a>
                                                                    </li>
                                                                    <li>
                                                                      <a
                                                                        to="construction.html"
                                                                        className="dropdown-item"
                                                                      >
                                                                        <img src="images/construuction_2.png" />{" "}
                                                                        Gantt
                                                                        View
                                                                      </a>
                                                                    </li>
                                                                    <li>
                                                                      <a
                                                                        to="construction.html"
                                                                        className="dropdown-item"
                                                                      >
                                                                        <img src="images/construuction_3.png" />{" "}
                                                                        Calendar
                                                                        View
                                                                      </a>
                                                                    </li>
                                                                    <li>
                                                                      <a
                                                                        to="construction.html"
                                                                        className="dropdown-item"
                                                                      >
                                                                        <img src="images/construuction_4.png" />{" "}
                                                                        List
                                                                        View
                                                                      </a>
                                                                    </li>
                                                                    <li>
                                                                      <a
                                                                        to="construction.html"
                                                                        className="dropdown-item"
                                                                      >
                                                                        <img src="images/construuction_5.png" />{" "}
                                                                        Baseline
                                                                      </a>
                                                                    </li>
                                                                  </ul>
                                                                </li>
                                                                <li>
                                                                  <Link
                                                                    className="dropdown-item"
                                                                    to="/warranty-dashboard"
                                                                  >
                                                                    <img src="images/warranty.png" />
                                                                    Warranty
                                                                  </Link>
                                                                </li>
                                                                <li>
                                                                  <Link
                                                                    className="dropdown-item"
                                                                    to="/models"
                                                                  >
                                                                    <img src="images/model.png" />{" "}
                                                                    Model
                                                                    <img src="images/rightarrow2.png" />
                                                                  </Link>
                                                                  {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                                                </li>
                                                                <li>
                                                                  <a
                                                                    className="dropdown-item"
                                                                    to="reports.html"
                                                                  >
                                                                    <img src="images/reports.png" />
                                                                    Reports
                                                                  </a>
                                                                </li>
                                                                <li>
                                                                  <Link
                                                                    className="dropdown-item"
                                                                    to={`/client-view-config`}
                                                                    state={{
                                                                      item,
                                                                    }}
                                                                  >
                                                                    <img src="images/view_config.png" />
                                                                    Views
                                                                    Configs
                                                                  </Link>
                                                                </li>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    {data?.jobType ==
                                                      "MultiBlock" &&
                                                      multiJobUnitOpenIndex ==
                                                        index && (
                                                        <tr>
                                                          <td colSpan={7}>
                                                            <div
                                                              className="ThirdLevelTable"
                                                              style={{
                                                                border:
                                                                  "1px solid red",
                                                                borderRadius: 15,
                                                                padding: 5,
                                                              }}
                                                            >
                                                              <table>
                                                                <thead>
                                                                  <tr>
                                                                    <th>#</th>
                                                                    <th>
                                                                      Unit /
                                                                      Common
                                                                      Area Job
                                                                      Number
                                                                    </th>
                                                                    <th>
                                                                      Unit /
                                                                      Common
                                                                      Area #
                                                                    </th>
                                                                    <th>
                                                                      Primary
                                                                      Client
                                                                    </th>
                                                                    <th>
                                                                      Square
                                                                      Footage
                                                                      Description
                                                                    </th>
                                                                    <th>
                                                                      Description
                                                                    </th>
                                                                    <th>
                                                                      Action
                                                                    </th>
                                                                  </tr>
                                                                </thead>
                                                                <tbody>
                                                                  {tempMultiStoredata?.length >
                                                                    0 &&
                                                                    tempMultiStoredata?.map(
                                                                      (
                                                                        itemm,
                                                                        indd
                                                                      ) => {
                                                                        // console.log("itemm---->",itemm);
                                                                        return (
                                                                          <tr>
                                                                            <td>
                                                                              <div className="d-flex">
                                                                                {
                                                                                  itemm.id
                                                                                }
                                                                                <div className="dropdown">
                                                                                  <span
                                                                                    className="Dots"
                                                                                    data-toggle="dropdown"
                                                                                    aria-haspopup="true"
                                                                                    aria-expanded="false"
                                                                                  >
                                                                                    <img src="images/dots.png" />
                                                                                  </span>
                                                                                  <div className="dropdown-menu">
                                                                                    <a
                                                                                      onClick={() =>
                                                                                        handleArchiveJobs(
                                                                                          itemm.id,
                                                                                          itemm.jobType,
                                                                                          "multi"
                                                                                        )
                                                                                      }
                                                                                      className="dropdown-item"
                                                                                    >
                                                                                      {" "}
                                                                                      <img src="images/archive_job.png" />{" "}
                                                                                      Archive
                                                                                      Job
                                                                                    </a>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      onClick={() =>
                                                                                        handleShowInWarranty(
                                                                                          itemm.id,
                                                                                          itemm.jobType,
                                                                                          "multi"
                                                                                        )
                                                                                      }
                                                                                    >
                                                                                      {" "}
                                                                                      <img src="images/home_warranty.png" />{" "}
                                                                                      Show
                                                                                      in
                                                                                      Warranty
                                                                                      &nbsp;&nbsp;
                                                                                    </a>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                to="/construction"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.jobNumber ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                to="/construction"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.unitNumber ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                to="/construction"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.name ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                to="/construction"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.unitsSqFt ??
                                                                                  itemm?.value ??
                                                                                  "0"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                to="/construction"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.jobType ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <div className="TodoUser TodoUserHome text-center">
                                                                                <div className="TodoUserJob">
                                                                                  <div className="dropdown">
                                                                                    <p data-toggle="dropdown">
                                                                                      <span>
                                                                                        <img src="images/three-dots.png" />
                                                                                      </span>
                                                                                    </p>
                                                                                    <div
                                                                                      className="dropdown-menu"
                                                                                      aria-labelledby="dropdownMenuOffset"
                                                                                    >
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          onClick={() => {
                                                                                            setJobDetailsForUnit(
                                                                                              true
                                                                                            );
                                                                                            setSelectedId(
                                                                                              item?.id
                                                                                            );
                    
                                                                                            setJobData(data)
                    
                                                                                          }}
                    
                                                                                        >
                                                                                          <img src="images/details.png" />
                                                                                          Details
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="javascript:void(0);"
                                                                                        >
                                                                                          <img src="images/change_order.png" />{" "}
                                                                                          Change
                                                                                          Order
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu submenuhome">
                                                                                          <li>
                                                                                            <a
                                                                                              href="change_order_list.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/change_order.png" />
                                                                                              Change
                                                                                              orders
                                                                                              List
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="design_selections.html"
                                                                                        >
                                                                                          <img src="images/design.png" />
                                                                                          Design
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="javascript:void(0);"
                                                                                          data-toggle="modal"
                                                                                          data-targer="#ClientContractDetailsNoUnit"
                                                                                        >
                                                                                          <img src="images/sales.png" />
                                                                                          Sales
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="javascript:void(0);"
                                                                                        >
                                                                                          <img src="images/estimation.png" />{" "}
                                                                                          Estimation
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu submenuhome">
                                                                                          <li>
                                                                                            <a
                                                                                              href="budget_details.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/estimation.png" />
                                                                                              Budget
                                                                                              Details
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="construction.html"
                                                                                        >
                                                                                          <img src="images/construction_icon.png" />{" "}
                                                                                          Construction
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu submenuhome">
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_1.png" />{" "}
                                                                                              Schedule
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_2.png" />{" "}
                                                                                              Gantt
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_3.png" />{" "}
                                                                                              Calendar
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_4.png" />{" "}
                                                                                              List
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_5.png" />{" "}
                                                                                              Baseline
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="Warranty_Dashboard.html"
                                                                                        >
                                                                                          <img src="images/warranty.png" />
                                                                                          Warranty
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="models.html"
                                                                                        >
                                                                                          <img src="images/model.png" />{" "}
                                                                                          Model
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu">
                                                                                          <li>
                                                                                            <a
                                                                                              href="javascript:void(0);"
                                                                                              className="dropdown-item text-center"
                                                                                              data-toggle="modal"
                                                                                              data-target="#CreateNewModelHome"
                                                                                            >
                                                                                              <img src="images/model.png" />{" "}
                                                                                              Create
                                                                                              New
                                                                                              Model
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="reports.html"
                                                                                        >
                                                                                          <img src="images/reports.png" />
                                                                                          Reports
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="client-view-config.html"
                                                                                        >
                                                                                          <img src="images/view_config.png" />
                                                                                          Views
                                                                                          Configs
                                                                                        </a>
                                                                                      </li>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </td>
                                                                          </tr>
                                                                        );
                                                                      }
                                                                    )}
                                                                </tbody>
                                                              </table>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      )}
                                                  </>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}

            {multiDesignShow && (
              <>
                <div
                  className="CommonTableArea MultiFamilyConstructionTable"
                  style={{ display: "block" }}
                >
                  <div className="JobTableHeaderOuter">
                    <div className="JobTableHeaderInner">
                      <h5>Design Selection Job/Unit List</h5>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#LegalDescriptionSettings"
                        >
                          <img src="images/icon-52.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-51.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-54.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-55.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="JobTableCommon">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Job Number</th>
                          <th>Build Type</th>
                          <th>Address</th>
                          <th>Lot</th>
                          <th>Block</th>
                          <th>Phase</th>
                          <th>Plan</th>
                          <th>Sub Division</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="addClasss JobTabletbodyRed">
                        {multiFamilyProjectList?.projects?.length > 0 ? (
                          multiFamilyProjectList?.projects?.map((item, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td>
                                    <div className="d-flex">
                                      {item.id}
                                      <div className="dropdown">
                                        <span
                                          className="Dots"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </span>
                                        <div className="dropdown-menu">
                                          <a
                                            onClick={() =>
                                              handleArchiveJobs(
                                                item.id,
                                                item.jobType,
                                                "multi"
                                              )
                                            }
                                            className="dropdown-item"
                                          >
                                            {" "}
                                            <img src="images/archive_job.png" />{" "}
                                            Archive Job
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <a
                                      id="hidtableshow2"
                                      onClick={() =>
                                        handleMultiBlockListByProject(
                                          item.id,
                                          i
                                        )
                                      }
                                    >
                                      {item.jobNumber}&nbsp;
                                      <img
                                        src={"images/down-arrow.png"}
                                        style={{
                                          transform: `rotate(${
                                            resdentalId === i
                                              ? "180deg"
                                              : "0deg"
                                          })`,
                                        }}
                                      />
                                    </a>
                                  </td>
                                  <td>
                                    <Link to="/design-selections" state={item}>
                                      {item.projectType}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link to="/design-selections" state={item}>
                                      {item.projectAddress}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      Link
                                      to="/design-selections"
                                      state={item}
                                    >
                                      {item.lot}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      Link
                                      to="/design-selections"
                                      state={item}
                                    >
                                      {item.block}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      Link
                                      to="/design-selections"
                                      state={item}
                                    >
                                      {item.phase}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      Link
                                      to="/design-selections"
                                      state={item}
                                    >
                                      {item.plan ?? "N/A"}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      Link
                                      to="/design-selections"
                                      state={item}
                                    >
                                      {/* {item?.subdivisions?.map((x) => x)} */}
                                    </Link>
                                  </td>
                                  <td>
                                    <div className="TodoUser TodoUserHome">
                                      <div className="TodoUserJob">
                                        <div className="dropdown">
                                          <p data-toggle="dropdown">
                                            <span>
                                              <img src="images/three-dots.png" />
                                            </span>
                                          </p>
                                          <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuOffset"
                                          >
                                            <li>
                                              <a
                                                onClick={() => {
                                                  setCreateBlockModal(true);
                                                  setSelectedId(item?.id);
                                                  setJobId(
                                                    item?.MultiFamilyJobId
                                                  );
                                                  setJobName("Warranty");
                                                }}
                                              >
                                                <img src="images/add_build.png" />{" "}
                                                Add to/Edit Build
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                            </li>
                                            {/* <li>
                                              <a
                                                onClick={() => {
                                                  setJobDetialsModal(true);
                                                  setSelectedId(item?.id);
                                                  setJobName(
                                                    "Design Selection"
                                                  );
                                                }}
                                              >
                                                <img src="images/details.png" />
                                                Details
                                              </a>
                                            </li> */}
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="javascript:void(0);"
                                              >
                                                <img src="images/change_order.png" />{" "}
                                                Change Order
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="change_order_list.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/change_order.png" />
                                                    Change orders List
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="design_selections.html"
                                              >
                                                <img src="images/design.png" />
                                                Design
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="javascript:void(0);"
                                                data-toggle="modal"
                                                data-targer="#ClientContractDetailsNoUnit"
                                              >
                                                <img src="images/sales.png" />
                                                Sales
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="javascript:void(0);"
                                              >
                                                <img src="images/estimation.png" />{" "}
                                                Estimation
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="budget_details.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/estimation.png" />
                                                    Budget Details
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="construction.html"
                                              >
                                                <img src="images/construction_icon.png" />{" "}
                                                Construction
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_1.png" />{" "}
                                                    Schedule View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_2.png" />{" "}
                                                    Gantt View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_3.png" />{" "}
                                                    Calendar View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_4.png" />{" "}
                                                    List View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_5.png" />{" "}
                                                    Baseline
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/warranty-dashboard"
                                              >
                                                <img src="images/warranty.png" />
                                                Warranty
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/models"
                                              >
                                                <img src="images/model.png" />{" "}
                                                Model
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="reports.html"
                                              >
                                                <img src="images/reports.png" />
                                                Reports
                                              </a>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to={`/client-view-config`}
                                                state={{ item }}
                                              >
                                                <img src="images/view_config.png" />
                                                Views Configs
                                              </Link>
                                            </li>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td colSpan={10} className="padding_none">
                                    <div
                                      className="JobBoxTable"
                                      style={{
                                        display:
                                          resdentalId == i ? "block" : "none",
                                      }}
                                    >
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Unit Job Number</th>
                                            <th>Unit#</th>
                                            <th>Primary Client</th>
                                            <th>Square Footage</th>
                                            <th>Warranty Requests</th>
                                            <th>Description</th>
                                            <th>Actions</th>
                                            <th />
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {resdentalId == i &&
                                            multiblocksList?.length > 0 &&
                                            multiblocksList?.map(
                                              (data, index) => {
                                                let tempMultiStoredata =
                                                  data?.jobType ==
                                                    "MultiBlock" &&
                                                  [
                                                    ...data?.MultiUnits,
                                                    ...data?.MultiStories,
                                                  ]?.flat();

                                                return (
                                                  <>
                                                    <tr key={index}>
                                                      <td
                                                        style={{
                                                          color: "green",
                                                        }}
                                                      >
                                                        <div className="d-flex">
                                                          {data.id}
                                                          <div className="dropdown">
                                                            <span
                                                              className="Dots"
                                                              data-toggle="dropdown"
                                                              aria-haspopup="true"
                                                              aria-expanded="false"
                                                            >
                                                              <img src="images/dots.png" />
                                                            </span>
                                                            <div className="dropdown-menu">
                                                              <a
                                                                onClick={() =>
                                                                  handleArchiveJobs(
                                                                    data.id,
                                                                    data.jobType,
                                                                    "multi"
                                                                  )
                                                                }
                                                                className="dropdown-item"
                                                              >
                                                                {" "}
                                                                <img src="images/archive_job.png" />{" "}
                                                                Archive Job
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td
                                                        style={{
                                                          color: "green",
                                                        }}
                                                      >
                                                        {/* <Link
                                                    to="/change-order-list"
                                                    state={data}
                                                  > */}
                                                        {data?.jobNumber ??
                                                          "N.A"}
                                                        {/* </Link> */}
                                                        &nbsp;
                                                        {data?.jobType ==
                                                          "MultiBlock" && (
                                                          <img
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              e.stopPropagation();
                                                              updateState(
                                                                (prev) => ({
                                                                  ...prev,
                                                                  multiJobUnitOpenIndex:
                                                                    prev.multiJobUnitOpenIndex ==
                                                                    index
                                                                      ? -1
                                                                      : index,
                                                                })
                                                              );
                                                            }}
                                                            src={
                                                              "images/down-arrow.png"
                                                            }
                                                            style={{
                                                              transform: `rotate(${
                                                                multiJobUnitOpenIndex ===
                                                                index
                                                                  ? "180deg"
                                                                  : "0deg"
                                                              })`,
                                                            }}
                                                          />
                                                        )}
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/design-selections"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data.unit ?? "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/design-selections"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data.client ?? "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/design-selections"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data.footage ??
                                                            "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/design-selections"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data?.warranties ??
                                                            "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/design-selections"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data?.description ??
                                                            "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <td>
                                                          <div className="TodoUser TodoUserHome">
                                                            <div className="TodoUserJob">
                                                              <div className="dropdown">
                                                                <p data-toggle="dropdown">
                                                                  <span>
                                                                    <img src="images/three-dots.png" />
                                                                  </span>
                                                                </p>
                                                                <div
                                                                  className="dropdown-menu"
                                                                  aria-labelledby="dropdownMenuOffset"
                                                                >
                                                                  <li>
                                                                    <a
                                                                      onClick={() => {
                                                                        setCreateOnlyUnitsModal(
                                                                          true
                                                                        );
                                                                        setSelectedId(
                                                                          item?.id
                                                                        );
                                                                        setUnits(
                                                                          [data]
                                                                        );
                                                                        setJobId(
                                                                          data?.MultiFamilyJobId
                                                                        );
                                                                        setJobName(
                                                                          "Warranty"
                                                                        );
                                                                      }}
                                                                    >
                                                                      <img src="images/add_build.png" />{" "}
                                                                      Add
                                                                      to/Edit
                                                                      Build
                                                                      <img src="images/rightarrow2.png" />
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      onClick={() => {
                                                                        setJobDetailsForBlock(
                                                                          true
                                                                        );
                                                                        setSelectedId(
                                                                          item?.id
                                                                        );
                                                                        setJobName(
                                                                          "Estimation"
                                                                        );
                                                                      }}
                                                                    >
                                                                      <img src="images/details.png" />
                                                                      Details
                                                                    </a>
                                                                  </li>

                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="javascript:void(0);"
                                                                    >
                                                                      <img src="images/change_order.png" />{" "}
                                                                      Change
                                                                      Order
                                                                      <img src="images/rightarrow2.png" />
                                                                    </a>
                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                      <li>
                                                                        <a
                                                                          to="change_order_list.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/change_order.png" />
                                                                          Change
                                                                          orders
                                                                          List
                                                                        </a>
                                                                      </li>
                                                                    </ul>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="design_selections.html"
                                                                    >
                                                                      <img src="images/design.png" />
                                                                      Design
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="javascript:void(0);"
                                                                      data-toggle="modal"
                                                                      data-targer="#ClientContractDetailsNoUnit"
                                                                    >
                                                                      <img src="images/sales.png" />
                                                                      Sales
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="javascript:void(0);"
                                                                    >
                                                                      <img src="images/estimation.png" />{" "}
                                                                      Estimation
                                                                      <img src="images/rightarrow2.png" />
                                                                    </a>
                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                      <li>
                                                                        <a
                                                                          to="budget_details.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/estimation.png" />
                                                                          Budget
                                                                          Details
                                                                        </a>
                                                                      </li>
                                                                    </ul>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="construction.html"
                                                                    >
                                                                      <img src="images/construction_icon.png" />{" "}
                                                                      Construction
                                                                      <img src="images/rightarrow2.png" />
                                                                    </a>
                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                      <li>
                                                                        <a
                                                                          to="construction.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/construuction_1.png" />{" "}
                                                                          Schedule
                                                                          View
                                                                        </a>
                                                                      </li>
                                                                      <li>
                                                                        <a
                                                                          to="construction.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/construuction_2.png" />{" "}
                                                                          Gantt
                                                                          View
                                                                        </a>
                                                                      </li>
                                                                      <li>
                                                                        <a
                                                                          to="construction.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/construuction_3.png" />{" "}
                                                                          Calendar
                                                                          View
                                                                        </a>
                                                                      </li>
                                                                      <li>
                                                                        <a
                                                                          to="construction.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/construuction_4.png" />{" "}
                                                                          List
                                                                          View
                                                                        </a>
                                                                      </li>
                                                                      <li>
                                                                        <a
                                                                          to="construction.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/construuction_5.png" />{" "}
                                                                          Baseline
                                                                        </a>
                                                                      </li>
                                                                    </ul>
                                                                  </li>
                                                                  <li>
                                                                    <Link
                                                                      className="dropdown-item"
                                                                      to="/warranty-dashboard"
                                                                    >
                                                                      <img src="images/warranty.png" />
                                                                      Warranty
                                                                    </Link>
                                                                  </li>
                                                                  <li>
                                                                    <Link
                                                                      className="dropdown-item"
                                                                      to="/models"
                                                                    >
                                                                      <img src="images/model.png" />{" "}
                                                                      Model
                                                                      <img src="images/rightarrow2.png" />
                                                                    </Link>
                                                                    {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="reports.html"
                                                                    >
                                                                      <img src="images/reports.png" />
                                                                      Reports
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <Link
                                                                      className="dropdown-item"
                                                                      to={`/client-view-config`}
                                                                      state={{
                                                                        item,
                                                                      }}
                                                                    >
                                                                      <img src="images/view_config.png" />
                                                                      Views
                                                                      Configs
                                                                    </Link>
                                                                  </li>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </td>
                                                        {/* <a to="javascript:void(0);">
                                      <i
                                        className="fa fa-caret-down"
                                        aria-hidden="true"
                                      />
                                    </a> */}
                                                      </td>
                                                    </tr>
                                                    {data?.jobType ==
                                                      "MultiBlock" &&
                                                      multiJobUnitOpenIndex ==
                                                        index && (
                                                        <tr>
                                                          <td colSpan={7}>
                                                            <div
                                                              className="ThirdLevelTable"
                                                              style={{
                                                                border:
                                                                  "1px solid blue",
                                                                borderRadius: 15,
                                                                padding: 5,
                                                              }}
                                                            >
                                                              <table>
                                                                <thead>
                                                                  <tr>
                                                                    <th>#</th>
                                                                    <th>
                                                                      Unit /
                                                                      Common
                                                                      Area Job
                                                                      Number
                                                                    </th>
                                                                    <th>
                                                                      Unit /
                                                                      Common
                                                                      Area #
                                                                    </th>
                                                                    <th>
                                                                      Primary
                                                                      Client
                                                                    </th>
                                                                    <th>
                                                                      Square
                                                                      Footage
                                                                      Description
                                                                    </th>
                                                                    <th>
                                                                      Description
                                                                    </th>
                                                                    <th>
                                                                      Action
                                                                    </th>
                                                                  </tr>
                                                                </thead>
                                                                <tbody>
                                                                  {tempMultiStoredata?.length >
                                                                    0 &&
                                                                    tempMultiStoredata?.map(
                                                                      (
                                                                        itemm,
                                                                        indd
                                                                      ) => {
                                                                        // console.log("itemm---->",itemm);
                                                                        return (
                                                                          <tr>
                                                                            <td
                                                                              style={{
                                                                                color:
                                                                                  "blue",
                                                                              }}
                                                                            >
                                                                              <div className="d-flex">
                                                                                {
                                                                                  itemm.id
                                                                                }
                                                                                <div className="dropdown">
                                                                                  <span
                                                                                    className="Dots"
                                                                                    data-toggle="dropdown"
                                                                                    aria-haspopup="true"
                                                                                    aria-expanded="false"
                                                                                  >
                                                                                    <img src="images/dots.png" />
                                                                                  </span>
                                                                                  <div className="dropdown-menu">
                                                                                    <a
                                                                                      onClick={() =>
                                                                                        handleArchiveJobs(
                                                                                          itemm.id,
                                                                                          itemm.jobType,
                                                                                          "multi"
                                                                                        )
                                                                                      }
                                                                                      className="dropdown-item"
                                                                                    >
                                                                                      {" "}
                                                                                      <img src="images/archive_job.png" />{" "}
                                                                                      Archive
                                                                                      Job
                                                                                    </a>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      onClick={() =>
                                                                                        handleShowInWarranty(
                                                                                          itemm.id,
                                                                                          itemm.jobType,
                                                                                          "multi"
                                                                                        )
                                                                                      }
                                                                                    >
                                                                                      {" "}
                                                                                      <img src="images/home_warranty.png" />{" "}
                                                                                      Show
                                                                                      in
                                                                                      Warranty
                                                                                      &nbsp;&nbsp;
                                                                                    </a>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                }}
                                                                                to="/design-selections"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.jobNumber ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                }}
                                                                                to="/design-selections"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.unitNumber ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                }}
                                                                                to="/design-selections"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.name ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                }}
                                                                                to="/design-selections"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.unitsSqFt ??
                                                                                  itemm?.value ??
                                                                                  "0"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                }}
                                                                                to="/design-selections"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.jobType ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <div className="TodoUser TodoUserHome text-center">
                                                                                <div className="TodoUserJob">
                                                                                  <div className="dropdown">
                                                                                    <p data-toggle="dropdown">
                                                                                      <span>
                                                                                        <img src="images/three-dots.png" />
                                                                                      </span>
                                                                                    </p>
                                                                                    <div
                                                                                      className="dropdown-menu"
                                                                                      aria-labelledby="dropdownMenuOffset"
                                                                                    >
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          onClick={() => {
                                                                                            setJobDetailsForUnit(
                                                                                              true
                                                                                            );
                                                                                            setSelectedId(
                                                                                              item?.id
                                                                                            );
                                                                                            setJobName(
                                                                                              "Estimation"
                                                                                            );
                                                                                          }}
                                                                                        >
                                                                                          <img src="images/details.png" />
                                                                                          Details
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="javascript:void(0);"
                                                                                        >
                                                                                          <img src="images/change_order.png" />{" "}
                                                                                          Change
                                                                                          Order
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu submenuhome">
                                                                                          <li>
                                                                                            <a
                                                                                              href="change_order_list.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/change_order.png" />
                                                                                              Change
                                                                                              orders
                                                                                              List
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="design_selections.html"
                                                                                        >
                                                                                          <img src="images/design.png" />
                                                                                          Design
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="javascript:void(0);"
                                                                                          data-toggle="modal"
                                                                                          data-targer="#ClientContractDetailsNoUnit"
                                                                                        >
                                                                                          <img src="images/sales.png" />
                                                                                          Sales
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="javascript:void(0);"
                                                                                        >
                                                                                          <img src="images/estimation.png" />{" "}
                                                                                          Estimation
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu submenuhome">
                                                                                          <li>
                                                                                            <a
                                                                                              href="budget_details.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/estimation.png" />
                                                                                              Budget
                                                                                              Details
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="construction.html"
                                                                                        >
                                                                                          <img src="images/construction_icon.png" />{" "}
                                                                                          Construction
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu submenuhome">
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_1.png" />{" "}
                                                                                              Schedule
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_2.png" />{" "}
                                                                                              Gantt
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_3.png" />{" "}
                                                                                              Calendar
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_4.png" />{" "}
                                                                                              List
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_5.png" />{" "}
                                                                                              Baseline
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="Warranty_Dashboard.html"
                                                                                        >
                                                                                          <img src="images/warranty.png" />
                                                                                          Warranty
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="models.html"
                                                                                        >
                                                                                          <img src="images/model.png" />{" "}
                                                                                          Model
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu">
                                                                                          <li>
                                                                                            <a
                                                                                              href="javascript:void(0);"
                                                                                              className="dropdown-item text-center"
                                                                                              data-toggle="modal"
                                                                                              data-target="#CreateNewModelHome"
                                                                                            >
                                                                                              <img src="images/model.png" />{" "}
                                                                                              Create
                                                                                              New
                                                                                              Model
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="reports.html"
                                                                                        >
                                                                                          <img src="images/reports.png" />
                                                                                          Reports
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="client-view-config.html"
                                                                                        >
                                                                                          <img src="images/view_config.png" />
                                                                                          Views
                                                                                          Configs
                                                                                        </a>
                                                                                      </li>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </td>
                                                                          </tr>
                                                                        );
                                                                      }
                                                                    )}
                                                                </tbody>
                                                              </table>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      )}
                                                  </>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}

            {multiChangeorderShow && (
              <>
                <div
                  className="CommonTableArea MultiFamilyConstructionTable"
                  style={{ display: "block" }}
                >
                  <div className="JobTableHeaderOuter">
                    <div className="JobTableHeaderInner">
                      <h5>Change Order Job/Unit List</h5>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#LegalDescriptionSettings"
                        >
                          <img src="images/icon-52.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-51.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-54.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-55.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="JobTableCommon">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Job Number</th>
                          <th>Build Type</th>
                          <th>Address</th>
                          <th>Lot</th>
                          <th>Block</th>
                          <th>Phase</th>
                          <th>Plan</th>
                          <th>Sub Division</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="addClasss JobTabletbodyRed">
                        {multiFamilyProjectList?.projects?.length > 0 ? (
                          multiFamilyProjectList?.projects?.map((item, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td>
                                    <div className="d-flex">
                                      {item.id}
                                      <div className="dropdown">
                                        <span
                                          className="Dots"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </span>
                                        <div className="dropdown-menu">
                                          <a
                                            onClick={() =>
                                              handleArchiveJobs(
                                                item.id,
                                                item.jobType,
                                                "multi"
                                              )
                                            }
                                            className="dropdown-item"
                                          >
                                            {" "}
                                            <img src="images/archive_job.png" />{" "}
                                            Archive Job
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <a
                                      id="hidtableshow2"
                                      onClick={() =>
                                        handleMultiBlockListByProject(
                                          item.id,
                                          i
                                        )
                                      }
                                    >
                                      {item.jobNumber}&nbsp;
                                      <img
                                        src={"images/down-arrow.png"}
                                        style={{
                                          transform: `rotate(${
                                            resdentalId === i
                                              ? "180deg"
                                              : "0deg"
                                          })`,
                                        }}
                                      />
                                    </a>
                                  </td>
                                  <td>
                                    <Link to="/change-order-list" state={item}>
                                      {item.projectType}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link to="/change-order-list" state={item}>
                                      {item.projectAddress}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      Link
                                      to="/change-order-list"
                                      state={item}
                                    >
                                      {item.lot}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      Link
                                      to="/change-order-list"
                                      state={item}
                                    >
                                      {item.block}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      Link
                                      to="/change-order-list"
                                      state={item}
                                    >
                                      {item.phase}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      Link
                                      to="/change-order-list"
                                      state={item}
                                    >
                                      {item.plan ?? "N/A"}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      Link
                                      to="/change-order-list"
                                      state={item}
                                    >
                                      {/* {item?.subdivisions?.map((x) => x)} */}
                                    </Link>
                                  </td>

                                  <td>
                                    {/* <a>{item?.subdivisions?.map((x) => x)}</a> */}
                                  </td>
                                  <td>
                                    <div className="TodoUser TodoUserHome">
                                      <div className="TodoUserJob">
                                        <div className="dropdown">
                                          <p data-toggle="dropdown">
                                            <span>
                                              <img src="images/three-dots.png" />
                                            </span>
                                          </p>
                                          <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuOffset"
                                          >
                                            <li>
                                              <a
                                                onClick={() => {
                                                  setCreateBlockModal(true);
                                                  setSelectedId(item?.id);
                                                  setJobId(
                                                    item?.MultiFamilyJobId
                                                  );
                                                  setJobName("Change Order");
                                                }}
                                              >
                                                <img src="images/add_build.png" />{" "}
                                                Add to/Edit Build
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                            </li>

                                            {/* <li>
                                              <a
                                                onClick={() => {
                                                  setJobDetialsModal(true);
                                                  setSelectedId(item?.id);
                                                  setJobName(
                                                    "Clent View Config"
                                                  );
                                                }}
                                              >
                                                <img src="images/details.png" />
                                                Details
                                              </a>
                                            </li> */}
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="javascript:void(0);"
                                              >
                                                <img src="images/change_order.png" />{" "}
                                                Change Order
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="change_order_list.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/change_order.png" />
                                                    Change orders List
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="design_selections.html"
                                              >
                                                <img src="images/design.png" />
                                                Design
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="javascript:void(0);"
                                                data-toggle="modal"
                                                data-targer="#ClientContractDetailsNoUnit"
                                              >
                                                <img src="images/sales.png" />
                                                Sales
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="javascript:void(0);"
                                              >
                                                <img src="images/estimation.png" />{" "}
                                                Estimation
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="budget_details.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/estimation.png" />
                                                    Budget Details
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="construction.html"
                                              >
                                                <img src="images/construction_icon.png" />{" "}
                                                Construction
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_1.png" />{" "}
                                                    Schedule View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_2.png" />{" "}
                                                    Gantt View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_3.png" />{" "}
                                                    Calendar View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_4.png" />{" "}
                                                    List View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_5.png" />{" "}
                                                    Baseline
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/warranty-dashboard"
                                              >
                                                <img src="images/warranty.png" />
                                                Warranty
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/models"
                                              >
                                                <img src="images/model.png" />{" "}
                                                Model
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="reports.html"
                                              >
                                                <img src="images/reports.png" />
                                                Reports
                                              </a>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to={`/client-view-config`}
                                                state={{ item }}
                                              >
                                                <img src="images/view_config.png" />
                                                Views Configs
                                              </Link>
                                            </li>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td colSpan={10} className="padding_none">
                                    <div
                                      className="JobBoxTable"
                                      style={{
                                        display:
                                          resdentalId == i ? "block" : "none",
                                      }}
                                    >
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Unit Job Number</th>
                                            <th>Unit#</th>
                                            <th>Primary Client</th>
                                            <th>Square Footage</th>
                                            <th>Warranty Requests</th>
                                            <th>Description</th>
                                            <th>Actions</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {resdentalId == i &&
                                            multiblocksList?.length > 0 &&
                                            multiblocksList?.map(
                                              (data, index) => {
                                                let tempMultiStoredata =
                                                  data?.jobType ==
                                                    "MultiBlock" &&
                                                  [
                                                    ...data?.MultiUnits,
                                                    ...data?.MultiStories,
                                                  ]?.flat();

                                                return (
                                                  <>
                                                    <tr key={index}>
                                                      <td
                                                        style={{
                                                          color: "green",
                                                        }}
                                                      >
                                                        <div className="d-flex">
                                                          {data.id}
                                                          <div className="dropdown">
                                                            <span
                                                              className="Dots"
                                                              data-toggle="dropdown"
                                                              aria-haspopup="true"
                                                              aria-expanded="false"
                                                            >
                                                              <img src="images/dots.png" />
                                                            </span>
                                                            <div className="dropdown-menu">
                                                              <a
                                                                onClick={() =>
                                                                  handleArchiveJobs(
                                                                    data.id,
                                                                    data.jobType,
                                                                    "multi"
                                                                  )
                                                                }
                                                                className="dropdown-item"
                                                              >
                                                                {" "}
                                                                <img src="images/archive_job.png" />{" "}
                                                                Archive Job
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        {/* <Link
                                                    to="/change-order-list"
                                                    state={data}
                                                  > */}
                                                        {data?.jobNumber ??
                                                          "N.A"}
                                                        {/* </Link> */}
                                                        &nbsp;
                                                        {data?.jobType ==
                                                          "MultiBlock" && (
                                                          <img
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              e.stopPropagation();
                                                              updateState(
                                                                (prev) => ({
                                                                  ...prev,
                                                                  multiJobUnitOpenIndex:
                                                                    prev.multiJobUnitOpenIndex ==
                                                                    index
                                                                      ? -1
                                                                      : index,
                                                                })
                                                              );
                                                            }}
                                                            src={
                                                              "images/down-arrow.png"
                                                            }
                                                            style={{
                                                              transform: `rotate(${
                                                                multiJobUnitOpenIndex ===
                                                                index
                                                                  ? "180deg"
                                                                  : "0deg"
                                                              })`,
                                                            }}
                                                          />
                                                        )}
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/change-order-list"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data.unit ?? "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/change-order-list"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data.client ?? "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/change-order-list"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data.footage ??
                                                            "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/change-order-list"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data?.warranties ??
                                                            "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "color",
                                                          }}
                                                          to="/change-order-list"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data?.description ??
                                                            "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        {/* <a to="javascript:void(0);">
                                      <i
                                        className="fa fa-caret-down"
                                        aria-hidden="true"
                                      />
                                    </a> */}
                                                      </td>
                                                      <td>
                                                        <div className="TodoUser TodoUserHome">
                                                          <div className="TodoUserJob">
                                                            <div className="dropdown">
                                                              <p data-toggle="dropdown">
                                                                <span>
                                                                  <img src="images/three-dots.png" />
                                                                </span>
                                                              </p>
                                                              <div
                                                                className="dropdown-menu"
                                                                aria-labelledby="dropdownMenuOffset"
                                                              >
                                                                <li>
                                                                  <a
                                                                    onClick={() => {
                                                                      setCreateOnlyUnitsModal(
                                                                        true
                                                                      );
                                                                      setSelectedId(
                                                                        item?.id
                                                                      );

                                                                      setUnitData(
                                                                        item
                                                                      );

                                                                      setJobId(
                                                                        data?.MultiFamilyJobId
                                                                      );
                                                                    }}
                                                                  >
                                                                    <img src="images/add_build.png" />{" "}
                                                                    Add to/Edit
                                                                    Build
                                                                    <img src="images/rightarrow2.png" />
                                                                  </a>
                                                                </li>
                                                                <li>
                                                                  <a
                                                                    className="dropdown-item"
                                                                    onClick={() => {
                                                                      setJobDetailsForBlock(
                                                                        true
                                                                      );
                                                                      setSelectedId(
                                                                        item?.id
                                                                      );

                                                                      setJobData(data)

                                                                    }}

                                                                  >
                                                                    <img src="images/details.png" />
                                                                    Details
                                                                  </a>
                                                                </li>
                                                                <li>
                                                                  <a
                                                                    className="dropdown-item"
                                                                    to="javascript:void(0);"
                                                                  >
                                                                    <img src="images/change_order.png" />{" "}
                                                                    Change Order
                                                                    <img src="images/rightarrow2.png" />
                                                                  </a>
                                                                  <ul className="dropdown-menu submenu submenuhome">
                                                                    <li>
                                                                      <a
                                                                        to="change_order_list.html"
                                                                        className="dropdown-item"
                                                                      >
                                                                        <img src="images/change_order.png" />
                                                                        Change
                                                                        orders
                                                                        List
                                                                      </a>
                                                                    </li>
                                                                  </ul>
                                                                </li>
                                                                <li>
                                                                  <a
                                                                    className="dropdown-item"
                                                                    to="design_selections.html"
                                                                  >
                                                                    <img src="images/design.png" />
                                                                    Design
                                                                  </a>
                                                                </li>
                                                                <li>
                                                                  <a
                                                                    className="dropdown-item"
                                                                    to="javascript:void(0);"
                                                                    data-toggle="modal"
                                                                    data-targer="#ClientContractDetailsNoUnit"
                                                                  >
                                                                    <img src="images/sales.png" />
                                                                    Sales
                                                                  </a>
                                                                </li>
                                                                <li>
                                                                  <a
                                                                    className="dropdown-item"
                                                                    to="javascript:void(0);"
                                                                  >
                                                                    <img src="images/estimation.png" />{" "}
                                                                    Estimation
                                                                    <img src="images/rightarrow2.png" />
                                                                  </a>
                                                                  <ul className="dropdown-menu submenu submenuhome">
                                                                    <li>
                                                                      <a
                                                                        to="budget_details.html"
                                                                        className="dropdown-item"
                                                                      >
                                                                        <img src="images/estimation.png" />
                                                                        Budget
                                                                        Details
                                                                      </a>
                                                                    </li>
                                                                  </ul>
                                                                </li>
                                                                <li>
                                                                  <a
                                                                    className="dropdown-item"
                                                                    to="construction.html"
                                                                  >
                                                                    <img src="images/construction_icon.png" />{" "}
                                                                    Construction
                                                                    <img src="images/rightarrow2.png" />
                                                                  </a>
                                                                  <ul className="dropdown-menu submenu submenuhome">
                                                                    <li>
                                                                      <a
                                                                        to="construction.html"
                                                                        className="dropdown-item"
                                                                      >
                                                                        <img src="images/construuction_1.png" />{" "}
                                                                        Schedule
                                                                        View
                                                                      </a>
                                                                    </li>
                                                                    <li>
                                                                      <a
                                                                        to="construction.html"
                                                                        className="dropdown-item"
                                                                      >
                                                                        <img src="images/construuction_2.png" />{" "}
                                                                        Gantt
                                                                        View
                                                                      </a>
                                                                    </li>
                                                                    <li>
                                                                      <a
                                                                        to="construction.html"
                                                                        className="dropdown-item"
                                                                      >
                                                                        <img src="images/construuction_3.png" />{" "}
                                                                        Calendar
                                                                        View
                                                                      </a>
                                                                    </li>
                                                                    <li>
                                                                      <a
                                                                        to="construction.html"
                                                                        className="dropdown-item"
                                                                      >
                                                                        <img src="images/construuction_4.png" />{" "}
                                                                        List
                                                                        View
                                                                      </a>
                                                                    </li>
                                                                    <li>
                                                                      <a
                                                                        to="construction.html"
                                                                        className="dropdown-item"
                                                                      >
                                                                        <img src="images/construuction_5.png" />{" "}
                                                                        Baseline
                                                                      </a>
                                                                    </li>
                                                                  </ul>
                                                                </li>
                                                                <li>
                                                                  <Link
                                                                    className="dropdown-item"
                                                                    to="/warranty-dashboard"
                                                                  >
                                                                    <img src="images/warranty.png" />
                                                                    Warranty
                                                                  </Link>
                                                                </li>
                                                                <li>
                                                                  <Link
                                                                    className="dropdown-item"
                                                                    to="/models"
                                                                  >
                                                                    <img src="images/model.png" />{" "}
                                                                    Model
                                                                    <img src="images/rightarrow2.png" />
                                                                  </Link>
                                                                  {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                                                </li>
                                                                <li>
                                                                  <a
                                                                    className="dropdown-item"
                                                                    to="reports.html"
                                                                  >
                                                                    <img src="images/reports.png" />
                                                                    Reports
                                                                  </a>
                                                                </li>
                                                                <li>
                                                                  <Link
                                                                    className="dropdown-item"
                                                                    to={`/client-view-config`}
                                                                    state={{
                                                                      item,
                                                                    }}
                                                                  >
                                                                    <img src="images/view_config.png" />
                                                                    Views
                                                                    Configs
                                                                  </Link>
                                                                </li>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    {data?.jobType ==
                                                      "MultiBlock" &&
                                                      multiJobUnitOpenIndex ==
                                                        index && (
                                                        <tr>
                                                          <td colSpan={7}>
                                                            <div
                                                              className="ThirdLevelTable"
                                                              style={{
                                                                border:
                                                                  "1px solid red",
                                                                borderRadius: 15,
                                                                padding: 5,
                                                              }}
                                                            >
                                                              <table>
                                                                <thead>
                                                                  <tr>
                                                                    <th>#</th>
                                                                    <th>
                                                                      Unit /
                                                                      Common
                                                                      Area Job
                                                                      Number
                                                                    </th>
                                                                    {/* <th>
                                                                      Unit /
                                                                      Common
                                                                      Area #
                                                                    </th> */}
                                                                    <th>
                                                                      Primary
                                                                      Client
                                                                    </th>
                                                                    <th>
                                                                      Square
                                                                      Footage
                                                                      Description
                                                                    </th>
                                                                    <th>
                                                                      Description
                                                                    </th>
                                                                    <th>
                                                                      Action
                                                                    </th>
                                                                  </tr>
                                                                </thead>
                                                                <tbody>
                                                                  {tempMultiStoredata?.length >
                                                                    0 &&
                                                                    tempMultiStoredata?.map(
                                                                      (
                                                                        itemm,
                                                                        indd
                                                                      ) => {
                                                                        // console.log("itemm---->",itemm);
                                                                        return (
                                                                          <tr>
                                                                            <td>
                                                                              <div className="d-flex">
                                                                                {
                                                                                  itemm.id
                                                                                }
                                                                                <div className="dropdown">
                                                                                  <span
                                                                                    className="Dots"
                                                                                    data-toggle="dropdown"
                                                                                    aria-haspopup="true"
                                                                                    aria-expanded="false"
                                                                                  >
                                                                                    <img src="images/dots.png" />
                                                                                  </span>
                                                                                  <div className="dropdown-menu">
                                                                                    <a
                                                                                      onClick={() =>
                                                                                        handleArchiveJobs(
                                                                                          itemm.id,
                                                                                          itemm.jobType,
                                                                                          "multi"
                                                                                        )
                                                                                      }
                                                                                      className="dropdown-item"
                                                                                    >
                                                                                      {" "}
                                                                                      <img src="images/archive_job.png" />{" "}
                                                                                      Archive
                                                                                      Job
                                                                                    </a>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      onClick={() =>
                                                                                        handleShowInWarranty(
                                                                                          itemm.id,
                                                                                          itemm.jobType,
                                                                                          "multi"
                                                                                        )
                                                                                      }
                                                                                    >
                                                                                      {" "}
                                                                                      <img src="images/home_warranty.png" />{" "}
                                                                                      Show
                                                                                      in
                                                                                      Warranty
                                                                                      &nbsp;&nbsp;
                                                                                    </a>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                to="/change-order-list"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.jobNumber ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            {/* <td>
                                                                              <Link
                                                                                to="/change-order-list"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.unitNumber ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td> */}
                                                                            <td>
                                                                              <Link
                                                                                to="/change-order-list"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.name ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                to="/change-order-list"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.unitsSqFt ??
                                                                                  itemm?.value ??
                                                                                  "0"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                to="/change-order-list"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.jobType ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <div className="TodoUser TodoUserHome text-center">
                                                                                <div className="TodoUserJob">
                                                                                  <div className="dropdown">
                                                                                    <p data-toggle="dropdown">
                                                                                      <span>
                                                                                        <img src="images/three-dots.png" />
                                                                                      </span>
                                                                                    </p>
                                                                                    <div
                                                                                      className="dropdown-menu"
                                                                                      aria-labelledby="dropdownMenuOffset"
                                                                                    >
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          onClick={() => {
                                                                                            setJobDetailsForUnit(
                                                                                              true
                                                                                            );
                                                                                            setSelectedId(
                                                                                              item?.id
                                                                                            );
                    
                                                                                            setJobData(data)
                    
                                                                                          }}
                    
                                                                                        >
                                                                                          <img src="images/details.png" />
                                                                                          Details
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="javascript:void(0);"
                                                                                        >
                                                                                          <img src="images/change_order.png" />{" "}
                                                                                          Change
                                                                                          Order
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu submenuhome">
                                                                                          <li>
                                                                                            <a
                                                                                              href="change_order_list.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/change_order.png" />
                                                                                              Change
                                                                                              orders
                                                                                              List
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="design_selections.html"
                                                                                        >
                                                                                          <img src="images/design.png" />
                                                                                          Design
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="javascript:void(0);"
                                                                                          data-toggle="modal"
                                                                                          data-targer="#ClientContractDetailsNoUnit"
                                                                                        >
                                                                                          <img src="images/sales.png" />
                                                                                          Sales
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="javascript:void(0);"
                                                                                        >
                                                                                          <img src="images/estimation.png" />{" "}
                                                                                          Estimation
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu submenuhome">
                                                                                          <li>
                                                                                            <a
                                                                                              href="budget_details.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/estimation.png" />
                                                                                              Budget
                                                                                              Details
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="construction.html"
                                                                                        >
                                                                                          <img src="images/construction_icon.png" />{" "}
                                                                                          Construction
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu submenuhome">
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_1.png" />{" "}
                                                                                              Schedule
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_2.png" />{" "}
                                                                                              Gantt
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_3.png" />{" "}
                                                                                              Calendar
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_4.png" />{" "}
                                                                                              List
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_5.png" />{" "}
                                                                                              Baseline
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="Warranty_Dashboard.html"
                                                                                        >
                                                                                          <img src="images/warranty.png" />
                                                                                          Warranty
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="models.html"
                                                                                        >
                                                                                          <img src="images/model.png" />{" "}
                                                                                          Model
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu">
                                                                                          <li>
                                                                                            <a
                                                                                              href="javascript:void(0);"
                                                                                              className="dropdown-item text-center"
                                                                                              data-toggle="modal"
                                                                                              data-target="#CreateNewModelHome"
                                                                                            >
                                                                                              <img src="images/model.png" />{" "}
                                                                                              Create
                                                                                              New
                                                                                              Model
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="reports.html"
                                                                                        >
                                                                                          <img src="images/reports.png" />
                                                                                          Reports
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="client-view-config.html"
                                                                                        >
                                                                                          <img src="images/view_config.png" />
                                                                                          Views
                                                                                          Configs
                                                                                        </a>
                                                                                      </li>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </td>
                                                                          </tr>
                                                                        );
                                                                      }
                                                                    )}
                                                                </tbody>
                                                              </table>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      )}
                                                  </>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}

            {multiEstimationShow && (
              <>
                <div
                  className="CommonTableArea MultiFamilyConstructionTable"
                  style={{ display: "block" }}
                >
                  <div className="JobTableHeaderOuter">
                    <div className="JobTableHeaderInner">
                      <h5>Estimation Job/Unit List</h5>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#LegalDescriptionSettings"
                        >
                          <img src="images/icon-52.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-51.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-54.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-55.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="JobTableCommon">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Job Number</th>
                          <th>Build Type</th>
                          <th>Address</th>
                          <th>Lot</th>
                          <th>Block</th>
                          <th>Phase</th>
                          <th>Plan</th>
                          <th>Sub Division</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="addClasss JobTabletbodyRed">
                        {multiFamilyProjectList?.projects?.length > 0 ? (
                          multiFamilyProjectList?.projects?.map((item, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td>
                                    <div className="d-flex">
                                      {item.id}
                                      <div className="dropdown">
                                        <span
                                          className="Dots"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </span>
                                        <div className="dropdown-menu">
                                          <a
                                            onClick={() =>
                                              handleArchiveJobs(
                                                item.id,
                                                item.jobType,
                                                "multi"
                                              )
                                            }
                                            className="dropdown-item"
                                          >
                                            {" "}
                                            <img src="images/archive_job.png" />{" "}
                                            Archive Job
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <a
                                      style={{ color: "red" }}
                                      id="hidtableshow2"
                                      onClick={() =>
                                        handleMultiBlockListByProject(
                                          item.id,
                                          i
                                        )
                                      }
                                    >
                                      {item.jobNumber}&nbsp;
                                      <img
                                        src={"images/down-arrow.png"}
                                        style={{
                                          transform: `rotate(${
                                            resdentalId === i
                                              ? "180deg"
                                              : "0deg"
                                          })`,
                                        }}
                                      />
                                    </a>
                                  </td>

                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/estimation"
                                      state={item}
                                    >
                                      {item.projectType}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/estimation"
                                      state={item}
                                    >
                                      {item.projectAddress}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      Link
                                      to="/estimation"
                                      state={item}
                                    >
                                      {item.lot}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/estimation"
                                      state={item}
                                    >
                                      {item.block}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/estimation"
                                      state={item}
                                    >
                                      {item.phase}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/estimation"
                                      state={item}
                                    >
                                      {item.plan ?? "N/A"}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      style={{ color: "red" }}
                                      to="/estimation"
                                      state={item}
                                    >
                                      {/* {item?.subdivisions?.map((x) => x)} */}
                                    </Link>
                                  </td>
                                  <td>
                                    <div className="TodoUser TodoUserHome">
                                      <div className="TodoUserJob">
                                        <div className="dropdown">
                                          <p data-toggle="dropdown">
                                            <span>
                                              <img src="images/three-dots.png" />
                                            </span>
                                          </p>
                                          <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuOffset"
                                          >
                                            <li>
                                              <a
                                                onClick={() => {
                                                  setCreateBlockModal(true);
                                                  setSelectedId(item?.id);
                                                  setJobId(
                                                    item?.MultiFamilyJobId
                                                  );
                                                  setJobName("Warranty");
                                                }}
                                              >
                                                <img src="images/add_build.png" />{" "}
                                                Add to/Edit Build
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                            </li>
                                            {/* <li>
                                              <a
                                                className="dropdown-item"
                                                onClick={() => {
                                                  setJobDetialsModal(true);
                                                  setSelectedId(item?.id);
                                                  setJobName("Estimation");
                                                }}
                                              >
                                                <img src="images/details.png" />
                                                Details
                                              </a>
                                            </li> */}
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="javascript:void(0);"
                                              >
                                                <img src="images/change_order.png" />{" "}
                                                Change Order
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="change_order_list.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/change_order.png" />
                                                    Change orders List
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="design_selections.html"
                                              >
                                                <img src="images/design.png" />
                                                Design
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="javascript:void(0);"
                                                data-toggle="modal"
                                                data-targer="#ClientContractDetailsNoUnit"
                                              >
                                                <img src="images/sales.png" />
                                                Sales
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="javascript:void(0);"
                                              >
                                                <img src="images/estimation.png" />{" "}
                                                Estimation
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="budget_details.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/estimation.png" />
                                                    Budget Details
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="construction.html"
                                              >
                                                <img src="images/construction_icon.png" />{" "}
                                                Construction
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_1.png" />{" "}
                                                    Schedule View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_2.png" />{" "}
                                                    Gantt View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_3.png" />{" "}
                                                    Calendar View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_4.png" />{" "}
                                                    List View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_5.png" />{" "}
                                                    Baseline
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/warranty-dashboard"
                                              >
                                                <img src="images/warranty.png" />
                                                Warranty
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/models"
                                              >
                                                <img src="images/model.png" />{" "}
                                                Model
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="reports.html"
                                              >
                                                <img src="images/reports.png" />
                                                Reports
                                              </a>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to={`/client-view-config`}
                                                state={{ item }}
                                              >
                                                <img src="images/view_config.png" />
                                                Views Configs
                                              </Link>
                                            </li>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td colSpan={10} className="padding_none">
                                    <div
                                      className="JobBoxTable"
                                      style={{
                                        display:
                                          resdentalId == i ? "block" : "none",
                                      }}
                                    >
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Unit Job Number</th>
                                            <th>Unit#</th>
                                            <th>Primary Client</th>
                                            <th>Square Footage</th>
                                            <th>Warranty Requests</th>
                                            <th>Description</th>
                                            <th>Actions</th>
                                            <th />
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {resdentalId == i &&
                                            multiblocksList?.length > 0 &&
                                            multiblocksList?.map(
                                              (data, index) => {
                                                let tempMultiStoredata =
                                                  data?.jobType ==
                                                    "MultiBlock" &&
                                                  [
                                                    ...data?.MultiUnits,
                                                    ...data?.MultiStories,
                                                  ]?.flat();

                                                return (
                                                  <>
                                                    <tr key={index}>
                                                      <td
                                                        style={{
                                                          color: "green",
                                                        }}
                                                      >
                                                        <div className="d-flex">
                                                          {data.id}
                                                          <div className="dropdown">
                                                            <span
                                                              className="Dots"
                                                              data-toggle="dropdown"
                                                              aria-haspopup="true"
                                                              aria-expanded="false"
                                                            >
                                                              <img src="images/dots.png" />
                                                            </span>
                                                            <div className="dropdown-menu">
                                                              <a
                                                                onClick={() =>
                                                                  handleArchiveJobs(
                                                                    data.id,
                                                                    data.jobType,
                                                                    "multi"
                                                                  )
                                                                }
                                                                className="dropdown-item"
                                                              >
                                                                {" "}
                                                                <img src="images/archive_job.png" />{" "}
                                                                Archive Job
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td
                                                        style={{
                                                          color: "green",
                                                        }}
                                                      >
                                                        {/* <Link
                                                    to="/estimation"
                                                    state={data}
                                                  > */}
                                                        {data?.jobNumber ??
                                                          "N.A"}
                                                        {/* </Link> */}
                                                        &nbsp;
                                                        {data?.jobType ==
                                                          "MultiBlock" && (
                                                          <img
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              e.stopPropagation();
                                                              updateState(
                                                                (prev) => ({
                                                                  ...prev,
                                                                  multiJobUnitOpenIndex:
                                                                    prev.multiJobUnitOpenIndex ==
                                                                    index
                                                                      ? -1
                                                                      : index,
                                                                })
                                                              );
                                                            }}
                                                            src={
                                                              "images/down-arrow.png"
                                                            }
                                                            style={{
                                                              transform: `rotate(${
                                                                multiJobUnitOpenIndex ===
                                                                index
                                                                  ? "180deg"
                                                                  : "0deg"
                                                              })`,
                                                            }}
                                                          />
                                                        )}
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/estimation"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data.unit ?? "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/estimation"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data.client ?? "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/estimation"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data.footage ??
                                                            "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/estimation"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data?.warranties ??
                                                            "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/estimation"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data?.description ??
                                                            "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td
                                                        style={{
                                                          color: "green",
                                                        }}
                                                      >
                                                        <td>
                                                          <div className="TodoUser TodoUserHome">
                                                            <div className="TodoUserJob">
                                                              <div className="dropdown">
                                                                <p data-toggle="dropdown">
                                                                  <span>
                                                                    <img src="images/three-dots.png" />
                                                                  </span>
                                                                </p>
                                                                <div
                                                                  className="dropdown-menu"
                                                                  aria-labelledby="dropdownMenuOffset"
                                                                >
                                                                  <li>
                                                                    <a
                                                                      onClick={() => {
                                                                        setCreateOnlyUnitsModal(
                                                                          true
                                                                        );
                                                                        setSelectedId(
                                                                          item?.id
                                                                        );
                                                                        setUnits(
                                                                          [data]
                                                                        );
                                                                        setJobId(
                                                                          data?.MultiFamilyJobId
                                                                        );
                                                                        setJobName(
                                                                          "Warranty"
                                                                        );
                                                                      }}
                                                                    >
                                                                      <img src="images/add_build.png" />{" "}
                                                                      Add
                                                                      to/Edit
                                                                      Build
                                                                      <img src="images/rightarrow2.png" />
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      onClick={() => {
                                                                        setJobDetailsForBlock(
                                                                          true
                                                                        );
                                                                        setSelectedId(
                                                                          item?.id
                                                                        );

                                                                        setJobData(data)

                                                                      }}

                                                                    >
                                                                      <img src="images/details.png" />
                                                                      Details
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="javascript:void(0);"
                                                                    >
                                                                      <img src="images/change_order.png" />{" "}
                                                                      Change
                                                                      Order
                                                                      <img src="images/rightarrow2.png" />
                                                                    </a>
                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                      <li>
                                                                        <a
                                                                          to="change_order_list.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/change_order.png" />
                                                                          Change
                                                                          orders
                                                                          List
                                                                        </a>
                                                                      </li>
                                                                    </ul>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="design_selections.html"
                                                                    >
                                                                      <img src="images/design.png" />
                                                                      Design
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="javascript:void(0);"
                                                                      data-toggle="modal"
                                                                      data-targer="#ClientContractDetailsNoUnit"
                                                                    >
                                                                      <img src="images/sales.png" />
                                                                      Sales
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="javascript:void(0);"
                                                                    >
                                                                      <img src="images/estimation.png" />{" "}
                                                                      Estimation
                                                                      <img src="images/rightarrow2.png" />
                                                                    </a>
                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                      <li>
                                                                        <a
                                                                          to="budget_details.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/estimation.png" />
                                                                          Budget
                                                                          Details
                                                                        </a>
                                                                      </li>
                                                                    </ul>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="construction.html"
                                                                    >
                                                                      <img src="images/construction_icon.png" />{" "}
                                                                      Construction
                                                                      <img src="images/rightarrow2.png" />
                                                                    </a>
                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                      <li>
                                                                        <a
                                                                          to="construction.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/construuction_1.png" />{" "}
                                                                          Schedule
                                                                          View
                                                                        </a>
                                                                      </li>
                                                                      <li>
                                                                        <a
                                                                          to="construction.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/construuction_2.png" />{" "}
                                                                          Gantt
                                                                          View
                                                                        </a>
                                                                      </li>
                                                                      <li>
                                                                        <a
                                                                          to="construction.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/construuction_3.png" />{" "}
                                                                          Calendar
                                                                          View
                                                                        </a>
                                                                      </li>
                                                                      <li>
                                                                        <a
                                                                          to="construction.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/construuction_4.png" />{" "}
                                                                          List
                                                                          View
                                                                        </a>
                                                                      </li>
                                                                      <li>
                                                                        <a
                                                                          to="construction.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/construuction_5.png" />{" "}
                                                                          Baseline
                                                                        </a>
                                                                      </li>
                                                                    </ul>
                                                                  </li>
                                                                  <li>
                                                                    <Link
                                                                      className="dropdown-item"
                                                                      to="/warranty-dashboard"
                                                                    >
                                                                      <img src="images/warranty.png" />
                                                                      Warranty
                                                                    </Link>
                                                                  </li>
                                                                  <li>
                                                                    <Link
                                                                      className="dropdown-item"
                                                                      to="/models"
                                                                    >
                                                                      <img src="images/model.png" />{" "}
                                                                      Model
                                                                      <img src="images/rightarrow2.png" />
                                                                    </Link>
                                                                    {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="reports.html"
                                                                    >
                                                                      <img src="images/reports.png" />
                                                                      Reports
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <Link
                                                                      className="dropdown-item"
                                                                      to={`/client-view-config`}
                                                                      state={{
                                                                        item,
                                                                      }}
                                                                    >
                                                                      <img src="images/view_config.png" />
                                                                      Views
                                                                      Configs
                                                                    </Link>
                                                                  </li>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </td>

                                                        {/* <a to="javascript:void(0);">
                                      <i
                                        className="fa fa-caret-down"
                                        aria-hidden="true"
                                      />
                                    </a> */}
                                                      </td>
                                                    </tr>
                                                    {data?.jobType ==
                                                      "MultiBlock" &&
                                                      multiJobUnitOpenIndex ==
                                                        index && (
                                                        <tr>
                                                          <td colSpan={7}>
                                                            <div
                                                              className="ThirdLevelTable"
                                                              style={{
                                                                border:
                                                                  "1px solid green",
                                                                borderRadius: 15,
                                                                padding: 5,
                                                              }}
                                                            >
                                                              <table>
                                                                <thead>
                                                                  <tr>
                                                                    <th>#</th>
                                                                    <th>
                                                                      Unit /
                                                                      Common
                                                                      Area Job
                                                                      Number
                                                                    </th>
                                                                    <th>
                                                                      Unit /
                                                                      Common
                                                                      Area #
                                                                    </th>
                                                                    <th>
                                                                      Primary
                                                                      Client
                                                                    </th>
                                                                    <th>
                                                                      Square
                                                                      Footage
                                                                      Description
                                                                    </th>
                                                                    <th>
                                                                      Description
                                                                    </th>
                                                                    <th>
                                                                      Action
                                                                    </th>
                                                                  </tr>
                                                                </thead>
                                                                <tbody>
                                                                  {tempMultiStoredata?.length >
                                                                    0 &&
                                                                    tempMultiStoredata?.map(
                                                                      (
                                                                        itemm,
                                                                        indd
                                                                      ) => {
                                                                        // console.log("itemm---->",itemm);
                                                                        return (
                                                                          <tr>
                                                                            <td
                                                                              style={{
                                                                                color:
                                                                                  "blue",
                                                                              }}
                                                                            >
                                                                              <div className="d-flex">
                                                                                {
                                                                                  itemm.id
                                                                                }
                                                                                <div className="dropdown">
                                                                                  <span
                                                                                    className="Dots"
                                                                                    data-toggle="dropdown"
                                                                                    aria-haspopup="true"
                                                                                    aria-expanded="false"
                                                                                  >
                                                                                    <img src="images/dots.png" />
                                                                                  </span>
                                                                                  <div className="dropdown-menu">
                                                                                    <a
                                                                                      onClick={() =>
                                                                                        handleArchiveJobs(
                                                                                          itemm.id,
                                                                                          itemm.jobType,
                                                                                          "multi"
                                                                                        )
                                                                                      }
                                                                                      className="dropdown-item"
                                                                                    >
                                                                                      {" "}
                                                                                      <img src="images/archive_job.png" />{" "}
                                                                                      Archive
                                                                                      Job
                                                                                    </a>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      onClick={() =>
                                                                                        handleShowInWarranty(
                                                                                          itemm.id,
                                                                                          itemm.jobType,
                                                                                          "multi"
                                                                                        )
                                                                                      }
                                                                                    >
                                                                                      {" "}
                                                                                      <img src="images/home_warranty.png" />{" "}
                                                                                      Show
                                                                                      in
                                                                                      Warranty
                                                                                      &nbsp;&nbsp;
                                                                                    </a>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                }}
                                                                                to="/estimation"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.jobNumber ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                }}
                                                                                to="/estimation"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.unitNumber ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                }}
                                                                                to="/estimation"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.name ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                }}
                                                                                to="/estimation"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.unitsSqFt ??
                                                                                  itemm?.value ??
                                                                                  "0"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                }}
                                                                                to="/estimation"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.jobType ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <div className="TodoUser TodoUserHome text-center">
                                                                                <div className="TodoUserJob">
                                                                                  <div className="dropdown">
                                                                                    <p data-toggle="dropdown">
                                                                                      <span>
                                                                                        <img src="images/three-dots.png" />
                                                                                      </span>
                                                                                    </p>
                                                                                    <div
                                                                                      className="dropdown-menu"
                                                                                      aria-labelledby="dropdownMenuOffset"
                                                                                    >
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          onClick={() => {
                                                                                            setJobDetailsForUnit(
                                                                                              true
                                                                                            );
                                                                                            setSelectedId(
                                                                                              item?.id
                                                                                            );
                    
                                                                                            setJobData(data)
                    
                                                                                          }}
                    
                                                                                        >
                                                                                          <img src="images/details.png" />
                                                                                          Details
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="javascript:void(0);"
                                                                                        >
                                                                                          <img src="images/change_order.png" />{" "}
                                                                                          Change
                                                                                          Order
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu submenuhome">
                                                                                          <li>
                                                                                            <a
                                                                                              href="change_order_list.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/change_order.png" />
                                                                                              Change
                                                                                              orders
                                                                                              List
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="design_selections.html"
                                                                                        >
                                                                                          <img src="images/design.png" />
                                                                                          Design
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="javascript:void(0);"
                                                                                          data-toggle="modal"
                                                                                          data-targer="#ClientContractDetailsNoUnit"
                                                                                        >
                                                                                          <img src="images/sales.png" />
                                                                                          Sales
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="javascript:void(0);"
                                                                                        >
                                                                                          <img src="images/estimation.png" />{" "}
                                                                                          Estimation
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu submenuhome">
                                                                                          <li>
                                                                                            <a
                                                                                              href="budget_details.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/estimation.png" />
                                                                                              Budget
                                                                                              Details
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="construction.html"
                                                                                        >
                                                                                          <img src="images/construction_icon.png" />{" "}
                                                                                          Construction
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu submenuhome">
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_1.png" />{" "}
                                                                                              Schedule
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_2.png" />{" "}
                                                                                              Gantt
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_3.png" />{" "}
                                                                                              Calendar
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_4.png" />{" "}
                                                                                              List
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_5.png" />{" "}
                                                                                              Baseline
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="Warranty_Dashboard.html"
                                                                                        >
                                                                                          <img src="images/warranty.png" />
                                                                                          Warranty
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="models.html"
                                                                                        >
                                                                                          <img src="images/model.png" />{" "}
                                                                                          Model
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu">
                                                                                          <li>
                                                                                            <a
                                                                                              href="javascript:void(0);"
                                                                                              className="dropdown-item text-center"
                                                                                              data-toggle="modal"
                                                                                              data-target="#CreateNewModelHome"
                                                                                            >
                                                                                              <img src="images/model.png" />{" "}
                                                                                              Create
                                                                                              New
                                                                                              Model
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="reports.html"
                                                                                        >
                                                                                          <img src="images/reports.png" />
                                                                                          Reports
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="client-view-config.html"
                                                                                        >
                                                                                          <img src="images/view_config.png" />
                                                                                          Views
                                                                                          Configs
                                                                                        </a>
                                                                                      </li>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </td>
                                                                          </tr>
                                                                        );
                                                                      }
                                                                    )}
                                                                </tbody>
                                                              </table>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      )}
                                                  </>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
            {multiAccountingShow && (
              <>
                <div
                  className="CommonTableArea MultiFamilyConstructionTable"
                  style={{ display: "block" }}
                >
                  <div className="JobTableHeaderOuter">
                    <div className="JobTableHeaderInner">
                      <h5>Accounting Job/Unit List</h5>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#LegalDescriptionSettings"
                        >
                          <img src="images/icon-52.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-51.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-54.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-55.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="JobTableCommon">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Job Number</th>
                          <th>Build Type</th>
                          <th>Address</th>
                          <th>Lot</th>
                          <th>Block</th>
                          <th>Phase</th>
                          <th>Plan</th>
                          <th>Sub Division</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="addClasss JobTabletbodyRed">
                        {multiFamilyProjectList?.projects?.length > 0 ? (
                          multiFamilyProjectList?.projects?.map((item, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td>
                                    <div className="d-flex">
                                      {item.id}
                                      <div className="dropdown">
                                        <span
                                          className="Dots"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </span>
                                        <div className="dropdown-menu">
                                          <a
                                            onClick={() =>
                                              handleArchiveJobs(
                                                item.id,
                                                item.jobType,
                                                "multi"
                                              )
                                            }
                                            className="dropdown-item"
                                          >
                                            {" "}
                                            <img src="images/archive_job.png" />{" "}
                                            Archive Job
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <a
                                      id="hidtableshow2"
                                      onClick={() =>
                                        handleMultiBlockListByProject(
                                          item.id,
                                          i
                                        )
                                      }
                                    >
                                      {item.jobNumber}&nbsp;
                                      <img
                                        src={"images/down-arrow.png"}
                                        style={{
                                          transform: `rotate(${
                                            resdentalId === i
                                              ? "180deg"
                                              : "0deg"
                                          })`,
                                        }}
                                      />
                                    </a>
                                  </td>
                                  <td>
                                    <Link
                                      to="/transactions_manager"
                                      state={item}
                                    >
                                      {item.projectType}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      to="/transactions_manager"
                                      state={item}
                                    >
                                      {item.projectAddress}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      Link
                                      to="/transactions_manager"
                                      state={item}
                                    >
                                      {item.lot}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      Link
                                      to="/transactions_manager"
                                      state={item}
                                    >
                                      {item.block}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      Link
                                      to="/transactions_manager"
                                      state={item}
                                    >
                                      {item.phase}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      Link
                                      to="/transactions_manager"
                                      state={item}
                                    >
                                      {item.plan ?? "N/A"}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      Link
                                      to="/transactions_manager"
                                      state={item}
                                    >
                                      {/* {item?.subdivisions?.map((x) => x)} */}
                                    </Link>
                                  </td>
                                  <td>
                                    <div className="TodoUser TodoUserHome">
                                      <div className="TodoUserJob">
                                        <div className="dropdown">
                                          <p data-toggle="dropdown">
                                            <span>
                                              <img src="images/three-dots.png" />
                                            </span>
                                          </p>
                                          <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuOffset"
                                          >
                                            <li>
                                              <a
                                                onClick={() => {
                                                  setCreateBlockModal(true);
                                                  setSelectedId(item?.id);
                                                  setJobData(item)
                                                }}
                                              >
                                                <img src="images/add_build.png" />{" "}
                                                Add to/Edit Build
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                            </li>

                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="javascript:void(0);"
                                              >
                                                <img src="images/change_order.png" />{" "}
                                                Change Order
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="change_order_list.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/change_order.png" />
                                                    Change orders List
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="design_selections.html"
                                              >
                                                <img src="images/design.png" />
                                                Design
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="javascript:void(0);"
                                                data-toggle="modal"
                                                data-targer="#ClientContractDetailsNoUnit"
                                              >
                                                <img src="images/sales.png" />
                                                Sales
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="javascript:void(0);"
                                              >
                                                <img src="images/estimation.png" />{" "}
                                                Estimation
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="budget_details.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/estimation.png" />
                                                    Budget Details
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="construction.html"
                                              >
                                                <img src="images/construction_icon.png" />{" "}
                                                Construction
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_1.png" />{" "}
                                                    Schedule View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_2.png" />{" "}
                                                    Gantt View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_3.png" />{" "}
                                                    Calendar View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_4.png" />{" "}
                                                    List View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_5.png" />{" "}
                                                    Baseline
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/warranty-dashboard"
                                              >
                                                <img src="images/warranty.png" />
                                                Warranty
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/models"
                                              >
                                                <img src="images/model.png" />{" "}
                                                Model
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="reports.html"
                                              >
                                                <img src="images/reports.png" />
                                                Reports
                                              </a>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to={`/client-view-config`}
                                                state={{ item }}
                                              >
                                                <img src="images/view_config.png" />
                                                Views Configs
                                              </Link>
                                            </li>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td colSpan={10} className="padding_none">
                                    <div
                                      className="JobBoxTable"
                                      style={{
                                        display:
                                          resdentalId == i ? "block" : "none",
                                      }}
                                    >
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Unit Job Number</th>
                                            <th>Unit#</th>
                                            <th>Primary Client</th>
                                            <th>Square Footage</th>
                                            <th>Warranty Requests</th>
                                            <th>Description</th>
                                            <th>Action</th>

                                            <th />
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {resdentalId == i &&
                                            multiblocksList?.length > 0 &&
                                            multiblocksList?.map(
                                              (data, index) => {
                                                let tempMultiStoredata =
                                                  data?.jobType ==
                                                    "MultiBlock" &&
                                                  [
                                                    ...data?.MultiUnits,
                                                    ...data?.MultiStories,
                                                  ]?.flat();

                                                return (
                                                  <>
                                                    <tr key={index}>
                                                      <td
                                                        style={{
                                                          color: "green",
                                                        }}
                                                      >
                                                        <div className="d-flex">
                                                          {data.id}
                                                          <div className="dropdown">
                                                            <span
                                                              className="Dots"
                                                              data-toggle="dropdown"
                                                              aria-haspopup="true"
                                                              aria-expanded="false"
                                                            >
                                                              <img src="images/dots.png" />
                                                            </span>
                                                            <div className="dropdown-menu">
                                                              <a
                                                                onClick={() =>
                                                                  handleArchiveJobs(
                                                                    data.id,
                                                                    data.jobType,
                                                                    "multi"
                                                                  )
                                                                }
                                                                className="dropdown-item"
                                                              >
                                                                {" "}
                                                                <img src="images/archive_job.png" />{" "}
                                                                Archive Job
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td
                                                        style={{
                                                          color: "green",
                                                        }}
                                                      >
                                                        {/* <Link
                                                    to="/estimation"
                                                    state={data}
                                                  > */}
                                                        {data?.jobNumber ??
                                                          "N.A"}
                                                        {/* </Link> */}
                                                        &nbsp;
                                                        {data?.jobType ==
                                                          "MultiBlock" && (
                                                          <img
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              e.stopPropagation();
                                                              updateState(
                                                                (prev) => ({
                                                                  ...prev,
                                                                  multiJobUnitOpenIndex:
                                                                    prev.multiJobUnitOpenIndex ==
                                                                    index
                                                                      ? -1
                                                                      : index,
                                                                })
                                                              );
                                                            }}
                                                            src={
                                                              "images/down-arrow.png"
                                                            }
                                                            style={{
                                                              transform: `rotate(${
                                                                multiJobUnitOpenIndex ===
                                                                index
                                                                  ? "180deg"
                                                                  : "0deg"
                                                              })`,
                                                            }}
                                                          />
                                                        )}
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/transactions_manager"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data.unit ?? "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/transactions_manager"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data.client ?? "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/transactions_manager"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data.footage ??
                                                            "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/transactions_manager"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data?.warranties ??
                                                            "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/transactions_manager"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data?.description ??
                                                            "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <td>
                                                          <div className="TodoUser TodoUserHome">
                                                            <div className="TodoUserJob">
                                                              <div className="dropdown">
                                                                <p data-toggle="dropdown">
                                                                  <span>
                                                                    <img src="images/three-dots.png" />
                                                                  </span>
                                                                </p>
                                                                <div
                                                                  className="dropdown-menu"
                                                                  aria-labelledby="dropdownMenuOffset"
                                                                >
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      onClick={() => {
                                                                        setCreateOnlyUnitsModal(
                                                                          true
                                                                        );
                                                                        setSelectedId(
                                                                          item?.id
                                                                        );

                                                                        setUnitData(
                                                                          item
                                                                        );
                                                                      }}
                                                                    >
                                                                      <img src="images/add_build.png" />{" "}
                                                                      Add
                                                                      to/Edit
                                                                      Build
                                                                      <img src="images/rightarrow2.png" />
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      onClick={() => {
                                                                        setJobDetailsForBlock(
                                                                          true
                                                                        );
                                                                        setSelectedId(
                                                                          item?.id
                                                                        );

                                                                        setJobData(data)

                                                                      }}
                                                                    >
                                                                      <img src="images/details.png" />
                                                                      Details
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="javascript:void(0);"
                                                                    >
                                                                      <img src="images/change_order.png" />{" "}
                                                                      Change
                                                                      Order
                                                                      <img src="images/rightarrow2.png" />
                                                                    </a>
                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                      <li>
                                                                        <a
                                                                          to="change_order_list.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/change_order.png" />
                                                                          Change
                                                                          orders
                                                                          List
                                                                        </a>
                                                                      </li>
                                                                    </ul>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="design_selections.html"
                                                                    >
                                                                      <img src="images/design.png" />
                                                                      Design
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="javascript:void(0);"
                                                                      data-toggle="modal"
                                                                      data-targer="#ClientContractDetailsNoUnit"
                                                                    >
                                                                      <img src="images/sales.png" />
                                                                      Sales
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="javascript:void(0);"
                                                                    >
                                                                      <img src="images/estimation.png" />{" "}
                                                                      Estimation
                                                                      <img src="images/rightarrow2.png" />
                                                                    </a>
                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                      <li>
                                                                        <a
                                                                          to="budget_details.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/estimation.png" />
                                                                          Budget
                                                                          Details
                                                                        </a>
                                                                      </li>
                                                                    </ul>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="construction.html"
                                                                    >
                                                                      <img src="images/construction_icon.png" />{" "}
                                                                      Construction
                                                                      <img src="images/rightarrow2.png" />
                                                                    </a>
                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                      <li>
                                                                        <a
                                                                          to="construction.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/construuction_1.png" />{" "}
                                                                          Schedule
                                                                          View
                                                                        </a>
                                                                      </li>
                                                                      <li>
                                                                        <a
                                                                          to="construction.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/construuction_2.png" />{" "}
                                                                          Gantt
                                                                          View
                                                                        </a>
                                                                      </li>
                                                                      <li>
                                                                        <a
                                                                          to="construction.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/construuction_3.png" />{" "}
                                                                          Calendar
                                                                          View
                                                                        </a>
                                                                      </li>
                                                                      <li>
                                                                        <a
                                                                          to="construction.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/construuction_4.png" />{" "}
                                                                          List
                                                                          View
                                                                        </a>
                                                                      </li>
                                                                      <li>
                                                                        <a
                                                                          to="construction.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/construuction_5.png" />{" "}
                                                                          Baseline
                                                                        </a>
                                                                      </li>
                                                                    </ul>
                                                                  </li>
                                                                  <li>
                                                                    <Link
                                                                      className="dropdown-item"
                                                                      to="/warranty-dashboard"
                                                                    >
                                                                      <img src="images/warranty.png" />
                                                                      Warranty
                                                                    </Link>
                                                                  </li>
                                                                  <li>
                                                                    <Link
                                                                      className="dropdown-item"
                                                                      to="/models"
                                                                    >
                                                                      <img src="images/model.png" />{" "}
                                                                      Model
                                                                      <img src="images/rightarrow2.png" />
                                                                    </Link>
                                                                    {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="reports.html"
                                                                    >
                                                                      <img src="images/reports.png" />
                                                                      Reports
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <Link
                                                                      className="dropdown-item"
                                                                      to={`/client-view-config`}
                                                                      state={{
                                                                        item,
                                                                      }}
                                                                    >
                                                                      <img src="images/view_config.png" />
                                                                      Views
                                                                      Configs
                                                                    </Link>
                                                                  </li>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </td>
                                                        {/* <a to="javascript:void(0);">
                                      <i
                                        className="fa fa-caret-down"
                                        aria-hidden="true"
                                      />
                                    </a> */}
                                                      </td>
                                                    </tr>
                                                    {data?.jobType ==
                                                      "MultiBlock" &&
                                                      multiJobUnitOpenIndex ==
                                                        index && (
                                                        <tr>
                                                          <td colSpan={7}>
                                                            <div
                                                              className="ThirdLevelTable"
                                                              style={{
                                                                border:
                                                                  "1px solid green",
                                                                borderRadius: 15,
                                                                padding: 5,
                                                              }}
                                                            >
                                                              <table>
                                                                <thead>
                                                                  <tr>
                                                                    <th>#</th>
                                                                    <th>
                                                                      Unit /
                                                                      Common
                                                                      Area Job
                                                                      Number
                                                                    </th>
                                                                    <th>
                                                                      Unit /
                                                                      Common
                                                                      Area #
                                                                    </th>
                                                                    <th>
                                                                      Primary
                                                                      Client
                                                                    </th>
                                                                    <th>
                                                                      Square
                                                                      Footage
                                                                      Description
                                                                    </th>
                                                                    <th>
                                                                      Description
                                                                    </th>
                                                                    <th>
                                                                      Action
                                                                    </th>
                                                                  </tr>
                                                                </thead>
                                                                <tbody>
                                                                  {tempMultiStoredata?.length >
                                                                    0 &&
                                                                    tempMultiStoredata?.map(
                                                                      (
                                                                        itemm,
                                                                        indd
                                                                      ) => {
                                                                        // console.log("itemm---->",itemm);
                                                                        return (
                                                                          <tr>
                                                                            <td
                                                                              style={{
                                                                                color:
                                                                                  "blue",
                                                                              }}
                                                                            >
                                                                              <div className="d-flex">
                                                                                {
                                                                                  itemm.id
                                                                                }
                                                                                <div className="dropdown">
                                                                                  <span
                                                                                    className="Dots"
                                                                                    data-toggle="dropdown"
                                                                                    aria-haspopup="true"
                                                                                    aria-expanded="false"
                                                                                  >
                                                                                    <img src="images/dots.png" />
                                                                                  </span>
                                                                                  <div className="dropdown-menu">
                                                                                    <a
                                                                                      onClick={() =>
                                                                                        handleArchiveJobs(
                                                                                          itemm.id,
                                                                                          itemm.jobType,
                                                                                          "multi"
                                                                                        )
                                                                                      }
                                                                                      className="dropdown-item"
                                                                                    >
                                                                                      {" "}
                                                                                      <img src="images/archive_job.png" />{" "}
                                                                                      Archive
                                                                                      Job
                                                                                    </a>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      onClick={() =>
                                                                                        handleShowInWarranty(
                                                                                          itemm.id,
                                                                                          itemm.jobType,
                                                                                          "multi"
                                                                                        )
                                                                                      }
                                                                                    >
                                                                                      {" "}
                                                                                      <img src="images/home_warranty.png" />{" "}
                                                                                      Show
                                                                                      in
                                                                                      Warranty
                                                                                      &nbsp;&nbsp;
                                                                                    </a>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </td>{" "}
                                                                            <td>
                                                                              <Link
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                }}
                                                                                to="/transactions_manager"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.jobNumber ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                }}
                                                                                to="/transactions_manager"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.unitNumber ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                }}
                                                                                to="/transactions_manager"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.name ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                }}
                                                                                to="/transactions_manager"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.unitsSqFt ??
                                                                                  itemm?.value ??
                                                                                  "0"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                }}
                                                                                to="/transactions_manager"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.jobType ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <div className="TodoUser TodoUserHome text-center">
                                                                                <div className="TodoUserJob">
                                                                                  <div className="dropdown">
                                                                                    <p data-toggle="dropdown">
                                                                                      <span>
                                                                                        <img src="images/three-dots.png" />
                                                                                      </span>
                                                                                    </p>
                                                                                    <div
                                                                                      className="dropdown-menu"
                                                                                      aria-labelledby="dropdownMenuOffset"
                                                                                    >
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="javascript:void(0);"
                                                                                        >
                                                                                          <img src="images/add_build.png" />{" "}
                                                                                          Add
                                                                                          to/Edit
                                                                                          Build
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          onClick={() => {
                                                                                            setJobDetailsForUnit(
                                                                                              true
                                                                                            );
                                                                                            setSelectedId(
                                                                                              item?.id
                                                                                            );
                                                                                            setJobData(itemm)
                                                                                            setJobName(
                                                                                              item?.jobNumber
                                                                                            );
                                                                                          }}
                                                                                        >
                                                                                          <img src="images/details.png" />
                                                                                          Details
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="javascript:void(0);"
                                                                                        >
                                                                                          <img src="images/change_order.png" />{" "}
                                                                                          Change
                                                                                          Order
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu submenuhome">
                                                                                          <li>
                                                                                            <a
                                                                                              href="change_order_list.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/change_order.png" />
                                                                                              Change
                                                                                              orders
                                                                                              List
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="design_selections.html"
                                                                                        >
                                                                                          <img src="images/design.png" />
                                                                                          Design
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="javascript:void(0);"
                                                                                          data-toggle="modal"
                                                                                          data-targer="#ClientContractDetailsNoUnit"
                                                                                        >
                                                                                          <img src="images/sales.png" />
                                                                                          Sales
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="javascript:void(0);"
                                                                                        >
                                                                                          <img src="images/estimation.png" />{" "}
                                                                                          Estimation
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu submenuhome">
                                                                                          <li>
                                                                                            <a
                                                                                              href="budget_details.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/estimation.png" />
                                                                                              Budget
                                                                                              Details
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="construction.html"
                                                                                        >
                                                                                          <img src="images/construction_icon.png" />{" "}
                                                                                          Construction
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu submenuhome">
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_1.png" />{" "}
                                                                                              Schedule
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_2.png" />{" "}
                                                                                              Gantt
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_3.png" />{" "}
                                                                                              Calendar
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_4.png" />{" "}
                                                                                              List
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_5.png" />{" "}
                                                                                              Baseline
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="Warranty_Dashboard.html"
                                                                                        >
                                                                                          <img src="images/warranty.png" />
                                                                                          Warranty
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="models.html"
                                                                                        >
                                                                                          <img src="images/model.png" />{" "}
                                                                                          Model
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu">
                                                                                          <li>
                                                                                            <a
                                                                                              href="javascript:void(0);"
                                                                                              className="dropdown-item text-center"
                                                                                              data-toggle="modal"
                                                                                              data-target="#CreateNewModelHome"
                                                                                            >
                                                                                              <img src="images/model.png" />{" "}
                                                                                              Create
                                                                                              New
                                                                                              Model
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="reports.html"
                                                                                        >
                                                                                          <img src="images/reports.png" />
                                                                                          Reports
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="client-view-config.html"
                                                                                        >
                                                                                          <img src="images/view_config.png" />
                                                                                          Views
                                                                                          Configs
                                                                                        </a>
                                                                                      </li>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </td>
                                                                          </tr>
                                                                        );
                                                                      }
                                                                    )}
                                                                </tbody>
                                                              </table>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      )}
                                                  </>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
            {multiClientViewConfigShow && (
              <>
                <div
                  className="CommonTableArea MultiFamilyConstructionTable"
                  style={{ display: "block" }}
                >
                  <div className="JobTableHeaderOuter">
                    <div className="JobTableHeaderInner">
                      <h5>Client View Config Job/Unit List</h5>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#LegalDescriptionSettings"
                        >
                          <img src="images/icon-52.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-51.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-54.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-55.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="JobTableCommon">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Job Number</th>
                          <th>Build Type</th>
                          <th>Address</th>
                          <th>Lot</th>
                          <th>Block</th>
                          <th>Phase</th>
                          <th>Plan</th>
                          <th>Sub Division</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="addClasss JobTabletbodyRed">
                        {multiFamilyProjectList?.projects?.length > 0 ? (
                          multiFamilyProjectList?.projects?.map((item, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td>
                                    <div className="d-flex">
                                      {item.id}
                                      <div className="dropdown">
                                        <span
                                          className="Dots"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </span>
                                        <div className="dropdown-menu">
                                          <a
                                            onClick={() =>
                                              handleArchiveJobs(
                                                item.id,
                                                item.jobType,
                                                "multi"
                                              )
                                            }
                                            className="dropdown-item"
                                          >
                                            {" "}
                                            <img src="images/archive_job.png" />{" "}
                                            Archive Job
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <a
                                      id="hidtableshow2"
                                      onClick={() =>
                                        handleMultiBlockListByProject(
                                          item.id,
                                          i
                                        )
                                      }
                                    >
                                      {item.jobNumber}&nbsp;
                                      <img
                                        src={"images/down-arrow.png"}
                                        style={{
                                          transform: `rotate(${
                                            resdentalId === i
                                              ? "180deg"
                                              : "0deg"
                                          })`,
                                        }}
                                      />
                                    </a>
                                  </td>
                                  <td>
                                    <Link
                                      to={`/client-view-config`}
                                      state={{ item }}
                                    >
                                      {item.projectType}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      to={`/client-view-config`}
                                      state={{ item }}
                                    >
                                      {item.projectAddress}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      to={`/client-view-config`}
                                      state={{ item }}
                                    >
                                      {item.lot}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      to={`/client-view-config`}
                                      state={{ item }}
                                    >
                                      {item.block}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      to={`/client-view-config`}
                                      state={{ item }}
                                    >
                                      {item.phase}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      to={`/client-view-config`}
                                      state={{ item }}
                                    >
                                      {item.plan ?? "N/A"}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link
                                      to={`/client-view-config`}
                                      state={{ item }}
                                    >
                                      {/* {item?.subdivisions?.map((x) => x)} */}
                                    </Link>
                                  </td>
                                  <td>
                                    <div className="TodoUser TodoUserHome">
                                      <div className="TodoUserJob">
                                        <div className="dropdown">
                                          <p data-toggle="dropdown">
                                            <span>
                                              <img src="images/three-dots.png" />
                                            </span>
                                          </p>
                                          <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuOffset"
                                          >
                                            <li>
                                              <a
                                                onClick={() => {
                                                  setCreateBlockModal(true);
                                                  setSelectedId(item?.id);
                                                  setJobId(
                                                    item?.MultiFamilyJobId
                                                  );
                                                  setJobName("Warranty");
                                                }}
                                              >
                                                <img src="images/add_build.png" />{" "}
                                                Add to/Edit Build
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                            </li>
                                            {/* <li>
                                              <a
                                                onClick={() => {
                                                  setJobDetialsModal(true);
                                                  setSelectedId(item?.id);
                                                  setJobName(
                                                    "Clent View Config"
                                                  );
                                                }}
                                              >
                                                <img src="images/details.png" />
                                                Details
                                              </a>
                                            </li> */}
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="javascript:void(0);"
                                              >
                                                <img src="images/change_order.png" />{" "}
                                                Change Order
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="change_order_list.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/change_order.png" />
                                                    Change orders List
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="design_selections.html"
                                              >
                                                <img src="images/design.png" />
                                                Design
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="javascript:void(0);"
                                                data-toggle="modal"
                                                data-targer="#ClientContractDetailsNoUnit"
                                              >
                                                <img src="images/sales.png" />
                                                Sales
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="javascript:void(0);"
                                              >
                                                <img src="images/estimation.png" />{" "}
                                                Estimation
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="budget_details.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/estimation.png" />
                                                    Budget Details
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="construction.html"
                                              >
                                                <img src="images/construction_icon.png" />{" "}
                                                Construction
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                              <ul className="dropdown-menu submenu submenuhome">
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_1.png" />{" "}
                                                    Schedule View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_2.png" />{" "}
                                                    Gantt View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_3.png" />{" "}
                                                    Calendar View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_4.png" />{" "}
                                                    List View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    to="construction.html"
                                                    className="dropdown-item"
                                                  >
                                                    <img src="images/construuction_5.png" />{" "}
                                                    Baseline
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/warranty-dashboard"
                                              >
                                                <img src="images/warranty.png" />
                                                Warranty
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="/models"
                                              >
                                                <img src="images/model.png" />{" "}
                                                Model
                                                <img src="images/rightarrow2.png" />
                                              </Link>
                                              {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                to="reports.html"
                                              >
                                                <img src="images/reports.png" />
                                                Reports
                                              </a>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to={`/client-view-config`}
                                                state={{ item }}
                                              >
                                                <img src="images/view_config.png" />
                                                Views Configs
                                              </Link>
                                            </li>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td colSpan={10} className="padding_none">
                                    <div
                                      className="JobBoxTable"
                                      style={{
                                        display:
                                          resdentalId == i ? "block" : "none",
                                      }}
                                    >
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Unit Job Number</th>
                                            <th>Unit#</th>
                                            <th>Primary Client</th>
                                            <th>Square Footage</th>
                                            <th>Warranty Requests</th>
                                            <th>Description</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {resdentalId == i &&
                                            multiblocksList?.length > 0 &&
                                            multiblocksList?.map(
                                              (data, index) => {
                                                let tempMultiStoredata =
                                                  data?.jobType ==
                                                    "MultiBlock" &&
                                                  [
                                                    ...data?.MultiUnits,
                                                    ...data?.MultiStories,
                                                  ]?.flat();

                                                return (
                                                  <>
                                                    <tr key={index}>
                                                      <td
                                                        style={{
                                                          color: "green",
                                                        }}
                                                      >
                                                        <div className="d-flex">
                                                          {data.id}
                                                          <div className="dropdown">
                                                            <span
                                                              className="Dots"
                                                              data-toggle="dropdown"
                                                              aria-haspopup="true"
                                                              aria-expanded="false"
                                                            >
                                                              <img src="images/dots.png" />
                                                            </span>
                                                            <div className="dropdown-menu">
                                                              <a
                                                                onClick={() =>
                                                                  handleArchiveJobs(
                                                                    data.id,
                                                                    data.jobType,
                                                                    "multi"
                                                                  )
                                                                }
                                                                className="dropdown-item"
                                                              >
                                                                {" "}
                                                                <img src="images/archive_job.png" />{" "}
                                                                Archive Job
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td
                                                        style={{
                                                          color: "green",
                                                        }}
                                                      >
                                                        {/* <Link
                                                    to="/estimation"
                                                    state={data}
                                                  > */}
                                                        {data?.jobNumber ??
                                                          "N.A"}
                                                        {/* </Link> */}
                                                        &nbsp;
                                                        {data?.jobType ==
                                                          "MultiBlock" && (
                                                          <img
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              e.stopPropagation();
                                                              updateState(
                                                                (prev) => ({
                                                                  ...prev,
                                                                  multiJobUnitOpenIndex:
                                                                    prev.multiJobUnitOpenIndex ==
                                                                    index
                                                                      ? -1
                                                                      : index,
                                                                })
                                                              );
                                                            }}
                                                            src={
                                                              "images/down-arrow.png"
                                                            }
                                                            style={{
                                                              transform: `rotate(${
                                                                multiJobUnitOpenIndex ===
                                                                index
                                                                  ? "180deg"
                                                                  : "0deg"
                                                              })`,
                                                            }}
                                                          />
                                                        )}
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/transactions_manager"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data.unit ?? "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/transactions_manager"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data.client ?? "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/transactions_manager"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data.footage ??
                                                            "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/transactions_manager"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data?.warranties ??
                                                            "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          to="/transactions_manager"
                                                          state={{
                                                            ...data,
                                                            ProjectId: item.id,
                                                          }}
                                                        >
                                                          {data?.description ??
                                                            "N/A"}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <td>
                                                          <div className="TodoUser TodoUserHome">
                                                            <div className="TodoUserJob">
                                                              <div className="dropdown">
                                                                <p data-toggle="dropdown">
                                                                  <span>
                                                                    <img src="images/three-dots.png" />
                                                                  </span>
                                                                </p>
                                                                <div
                                                                  className="dropdown-menu"
                                                                  aria-labelledby="dropdownMenuOffset"
                                                                >
                                                                  <li>
                                                                    <a
                                                                      onClick={() => {
                                                                        setCreateOnlyUnitsModal(
                                                                          true
                                                                        );
                                                                        setSelectedId(
                                                                          item?.id
                                                                        );

                                                                        setUnitData(
                                                                          item
                                                                        );

                                                                        setJobId(
                                                                          data?.MultiFamilyJobId
                                                                        );
                                                                      }}
                                                                    >
                                                                      <img src="images/add_build.png" />{" "}
                                                                      Add
                                                                      to/Edit
                                                                      Build
                                                                      <img src="images/rightarrow2.png" />
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                              <a
                                                onClick={() => {
                                                  setEditJobModal(true);
                                                  setSelectedId(item?.id);
                                                  setJobName(
                                                    "Clent View Config"
                                                  );
                                                }}
                                              >
                                                <img src="images/details.png" />
                                                Details
                                              </a>
                                            </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="javascript:void(0);"
                                                                    >
                                                                      <img src="images/change_order.png" />{" "}
                                                                      Change
                                                                      Order
                                                                      <img src="images/rightarrow2.png" />
                                                                    </a>
                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                      <li>
                                                                        <a
                                                                          to="change_order_list.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/change_order.png" />
                                                                          Change
                                                                          orders
                                                                          List
                                                                        </a>
                                                                      </li>
                                                                    </ul>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="design_selections.html"
                                                                    >
                                                                      <img src="images/design.png" />
                                                                      Design
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="javascript:void(0);"
                                                                      data-toggle="modal"
                                                                      data-targer="#ClientContractDetailsNoUnit"
                                                                    >
                                                                      <img src="images/sales.png" />
                                                                      Sales
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="javascript:void(0);"
                                                                    >
                                                                      <img src="images/estimation.png" />{" "}
                                                                      Estimation
                                                                      <img src="images/rightarrow2.png" />
                                                                    </a>
                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                      <li>
                                                                        <a
                                                                          to="budget_details.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/estimation.png" />
                                                                          Budget
                                                                          Details
                                                                        </a>
                                                                      </li>
                                                                    </ul>
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="construction.html"
                                                                    >
                                                                      <img src="images/construction_icon.png" />{" "}
                                                                      Construction
                                                                      <img src="images/rightarrow2.png" />
                                                                    </a>
                                                                    <ul className="dropdown-menu submenu submenuhome">
                                                                      <li>
                                                                        <a
                                                                          to="construction.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/construuction_1.png" />{" "}
                                                                          Schedule
                                                                          View
                                                                        </a>
                                                                      </li>
                                                                      <li>
                                                                        <a
                                                                          to="construction.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/construuction_2.png" />{" "}
                                                                          Gantt
                                                                          View
                                                                        </a>
                                                                      </li>
                                                                      <li>
                                                                        <a
                                                                          to="construction.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/construuction_3.png" />{" "}
                                                                          Calendar
                                                                          View
                                                                        </a>
                                                                      </li>
                                                                      <li>
                                                                        <a
                                                                          to="construction.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/construuction_4.png" />{" "}
                                                                          List
                                                                          View
                                                                        </a>
                                                                      </li>
                                                                      <li>
                                                                        <a
                                                                          to="construction.html"
                                                                          className="dropdown-item"
                                                                        >
                                                                          <img src="images/construuction_5.png" />{" "}
                                                                          Baseline
                                                                        </a>
                                                                      </li>
                                                                    </ul>
                                                                  </li>
                                                                  <li>
                                                                    <Link
                                                                      className="dropdown-item"
                                                                      to="/warranty-dashboard"
                                                                    >
                                                                      <img src="images/warranty.png" />
                                                                      Warranty
                                                                    </Link>
                                                                  </li>
                                                                  <li>
                                                                    <Link
                                                                      className="dropdown-item"
                                                                      to="/models"
                                                                    >
                                                                      <img src="images/model.png" />{" "}
                                                                      Model
                                                                      <img src="images/rightarrow2.png" />
                                                                    </Link>
                                                                    {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                                                  </li>
                                                                  <li>
                                                                    <a
                                                                      className="dropdown-item"
                                                                      to="reports.html"
                                                                    >
                                                                      <img src="images/reports.png" />
                                                                      Reports
                                                                    </a>
                                                                  </li>
                                                                  <li>
                                                                    <Link
                                                                      className="dropdown-item"
                                                                      to={`/client-view-config`}
                                                                      state={{
                                                                        item,
                                                                      }}
                                                                    >
                                                                      <img src="images/view_config.png" />
                                                                      Views
                                                                      Configs
                                                                    </Link>
                                                                  </li>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </td>
                                                        {/* <a to="javascript:void(0);">
                                      <i
                                        className="fa fa-caret-down"
                                        aria-hidden="true"
                                      />
                                    </a> */}
                                                      </td>
                                                    </tr>
                                                    {data?.jobType ==
                                                      "MultiBlock" &&
                                                      multiJobUnitOpenIndex ==
                                                        index && (
                                                        <tr>
                                                          <td colSpan={7}>
                                                            <div
                                                              className="ThirdLevelTable"
                                                              style={{
                                                                border:
                                                                  "1px solid green",
                                                                borderRadius: 15,
                                                                padding: 5,
                                                              }}
                                                            >
                                                              <table>
                                                                <thead>
                                                                  <tr>
                                                                    <th>#</th>
                                                                    <th>
                                                                      Unit /
                                                                      Common
                                                                      Area Job
                                                                      Number
                                                                    </th>
                                                                    <th>
                                                                      Unit /
                                                                      Common
                                                                      Area #
                                                                    </th>
                                                                    <th>
                                                                      Primary
                                                                      Client
                                                                    </th>
                                                                    <th>
                                                                      Square
                                                                      Footage
                                                                      Description
                                                                    </th>
                                                                    <th>
                                                                      Description
                                                                    </th>
                                                                    <th>
                                                                      Action
                                                                    </th>
                                                                  </tr>
                                                                </thead>
                                                                <tbody>
                                                                  {tempMultiStoredata?.length >
                                                                    0 &&
                                                                    tempMultiStoredata?.map(
                                                                      (
                                                                        itemm,
                                                                        indd
                                                                      ) => {
                                                                        // console.log("itemm---->",itemm);
                                                                        return (
                                                                          <tr>
                                                                            <td
                                                                              style={{
                                                                                color:
                                                                                  "blue",
                                                                              }}
                                                                            >
                                                                              <div className="d-flex">
                                                                                {
                                                                                  itemm.id
                                                                                }
                                                                                <div className="dropdown">
                                                                                  <span
                                                                                    className="Dots"
                                                                                    data-toggle="dropdown"
                                                                                    aria-haspopup="true"
                                                                                    aria-expanded="false"
                                                                                  >
                                                                                    <img src="images/dots.png" />
                                                                                  </span>
                                                                                  <div className="dropdown-menu">
                                                                                    <a
                                                                                      onClick={() =>
                                                                                        handleArchiveJobs(
                                                                                          itemm.id,
                                                                                          itemm.jobType,
                                                                                          "multi"
                                                                                        )
                                                                                      }
                                                                                      className="dropdown-item"
                                                                                    >
                                                                                      {" "}
                                                                                      <img src="images/archive_job.png" />{" "}
                                                                                      Archive
                                                                                      Job
                                                                                    </a>
                                                                                    <a
                                                                                      className="dropdown-item"
                                                                                      onClick={() =>
                                                                                        handleShowInWarranty(
                                                                                          itemm.id,
                                                                                          itemm.jobType,
                                                                                          "multi"
                                                                                        )
                                                                                      }
                                                                                    >
                                                                                      {" "}
                                                                                      <img src="images/home_warranty.png" />{" "}
                                                                                      Show
                                                                                      in
                                                                                      Warranty
                                                                                      &nbsp;&nbsp;
                                                                                    </a>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </td>{" "}
                                                                            <td>
                                                                              <Link
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                }}
                                                                                to="/transactions_manager"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.jobNumber ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                }}
                                                                                to="/transactions_manager"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.unitNumber ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                }}
                                                                                to="/transactions_manager"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.name ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                }}
                                                                                to="/transactions_manager"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.unitsSqFt ??
                                                                                  itemm?.value ??
                                                                                  "0"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <Link
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                }}
                                                                                to="/transactions_manager"
                                                                                state={{
                                                                                  ...data,
                                                                                  ProjectId:
                                                                                    item.id,
                                                                                }}
                                                                              >
                                                                                {itemm?.jobType ??
                                                                                  "N/A"}
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              <div className="TodoUser TodoUserHome text-center">
                                                                                <div className="TodoUserJob">
                                                                                  <div className="dropdown">
                                                                                    <p data-toggle="dropdown">
                                                                                      <span>
                                                                                        <img src="images/three-dots.png" />
                                                                                      </span>
                                                                                    </p>
                                                                                    <div
                                                                                      className="dropdown-menu"
                                                                                      aria-labelledby="dropdownMenuOffset"
                                                                                    >
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          onClick={() => {
                                                                                            setJobDetailsForUnit(
                                                                                              true
                                                                                            );
                                                                                            setSelectedId(
                                                                                              item?.id
                                                                                            );
                    
                                                                                            setJobData(data)
                    
                                                                                          }}
                    
                                                                                        >
                                                                                          <img src="images/details.png" />
                                                                                          Details
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="javascript:void(0);"
                                                                                        >
                                                                                          <img src="images/change_order.png" />{" "}
                                                                                          Change
                                                                                          Order
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu submenuhome">
                                                                                          <li>
                                                                                            <a
                                                                                              href="change_order_list.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/change_order.png" />
                                                                                              Change
                                                                                              orders
                                                                                              List
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="design_selections.html"
                                                                                        >
                                                                                          <img src="images/design.png" />
                                                                                          Design
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="javascript:void(0);"
                                                                                          data-toggle="modal"
                                                                                          data-targer="#ClientContractDetailsNoUnit"
                                                                                        >
                                                                                          <img src="images/sales.png" />
                                                                                          Sales
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="javascript:void(0);"
                                                                                        >
                                                                                          <img src="images/estimation.png" />{" "}
                                                                                          Estimation
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu submenuhome">
                                                                                          <li>
                                                                                            <a
                                                                                              href="budget_details.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/estimation.png" />
                                                                                              Budget
                                                                                              Details
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="construction.html"
                                                                                        >
                                                                                          <img src="images/construction_icon.png" />{" "}
                                                                                          Construction
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu submenuhome">
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_1.png" />{" "}
                                                                                              Schedule
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_2.png" />{" "}
                                                                                              Gantt
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_3.png" />{" "}
                                                                                              Calendar
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_4.png" />{" "}
                                                                                              List
                                                                                              View
                                                                                            </a>
                                                                                          </li>
                                                                                          <li>
                                                                                            <a
                                                                                              href="construction.html"
                                                                                              className="dropdown-item"
                                                                                            >
                                                                                              <img src="images/construuction_5.png" />{" "}
                                                                                              Baseline
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="Warranty_Dashboard.html"
                                                                                        >
                                                                                          <img src="images/warranty.png" />
                                                                                          Warranty
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="models.html"
                                                                                        >
                                                                                          <img src="images/model.png" />{" "}
                                                                                          Model
                                                                                          <img src="images/rightarrow2.png" />
                                                                                        </a>
                                                                                        <ul className="dropdown-menu submenu">
                                                                                          <li>
                                                                                            <a
                                                                                              href="javascript:void(0);"
                                                                                              className="dropdown-item text-center"
                                                                                              data-toggle="modal"
                                                                                              data-target="#CreateNewModelHome"
                                                                                            >
                                                                                              <img src="images/model.png" />{" "}
                                                                                              Create
                                                                                              New
                                                                                              Model
                                                                                            </a>
                                                                                          </li>
                                                                                        </ul>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="reports.html"
                                                                                        >
                                                                                          <img src="images/reports.png" />
                                                                                          Reports
                                                                                        </a>
                                                                                      </li>
                                                                                      <li>
                                                                                        <a
                                                                                          className="dropdown-item"
                                                                                          href="client-view-config.html"
                                                                                        >
                                                                                          <img src="images/view_config.png" />
                                                                                          Views
                                                                                          Configs
                                                                                        </a>
                                                                                      </li>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </td>
                                                                          </tr>
                                                                        );
                                                                      }
                                                                    )}
                                                                </tbody>
                                                              </table>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      )}
                                                  </>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}

            <div className="LandDevelopmentCommonBoxArea">
              <div className="CommonBoxArea ">
                <div className="MainTitle">
                  <h2>Land Development</h2>
                </div>
                {/* <div className="home-carousel owl-carousel owl-theme"> */}
                <OwlCarousel options={options}>
                  <div className="item">
                    <div
                      className="CommonBox CommonBoxWarranty"
                      onClick={() => handleLandAccountingShow()}
                    >
                      <div className="CommonBoxHeader">
                        <h6>Accounting</h6>
                        <h5>
                          {landDevelopmentHomeListData?.estimation?.count}
                        </h5>
                        <figure>
                          <img src="images/box-6.png" />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(
                          landDevelopmentHomeListData?.accounting?.accounting
                        ) &&
                          landDevelopmentHomeListData?.accounting?.accounting?.map(
                            (ele, i) => {
                              return (
                                <>
                                  <div className="CommonBoxBodyUser">
                                    <div className="CommonBoxBodyTitle">
                                      <span>MB</span>
                                      <div className="">
                                        <h6>
                                          {ele?.ResidentialJob?.subdividision}
                                        </h6>
                                        <p>{ele?.ResidentialJob?.jobNumber}</p>
                                      </div>
                                    </div>

                                    <figure>
                                      <img src="images/taskimg-1.png" />
                                    </figure>
                                    <span className="CbbOverlay" />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>
                          {landDevelopmentHomeListData?.accounting?.count}
                        </h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="CommonBox CommonBoxWarranty"
                      onClick={() => handleLandChangeorderShow()}
                    >
                      <div className="CommonBoxHeader">
                        <h6>Change Order</h6>
                        <h5>
                          {landDevelopmentHomeListData?.changeOrder?.count}
                        </h5>
                        <figure>
                          <img src="images/box-6.png" />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(
                          landDevelopmentHomeListData?.changeOrder?.changeOrder
                        ) &&
                          landDevelopmentHomeListData?.changeOrder?.changeOrder?.map(
                            (ele, i) => {
                              return (
                                <>
                                  <div className="CommonBoxBodyUser">
                                    <div className="CommonBoxBodyTitle">
                                      <span>MB</span>
                                      <div className="">
                                        <h6>
                                          {ele?.ResidentialJob?.subdividision}
                                        </h6>
                                        <p>{ele?.ResidentialJob?.jobNumber}</p>
                                      </div>
                                    </div>

                                    <figure>
                                      <img src="images/taskimg-1.png" />
                                    </figure>
                                    <span className="CbbOverlay" />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>
                          {landDevelopmentHomeListData?.changeOrder?.count}
                        </h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="CommonBox CommonBoxViewConfig"
                      onClick={handleLandClientViewConfigShow}
                    >
                      <div className="CommonBoxHeader">
                        <h6>Client View Config</h6>
                        <h5>
                          {landDevelopmentHomeListData?.clientCount?.count ?? 0}
                        </h5>
                        <figure>
                          <img src="images/box-13.png" />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(
                          landDevelopmentHomeListData?.clientCount?.clientView
                        ) &&
                          landDevelopmentHomeListData?.clientCount?.clientView?.map(
                            (ele, i) => {
                              return (
                                <>
                                  <div className="CommonBoxBodyUser">
                                    <div className="CommonBoxBodyTitle">
                                      <span>MB</span>
                                      <div className="">
                                        <h6>
                                          {ele?.ResidentialJob?.subdividision}
                                        </h6>
                                        <p>{ele?.ResidentialJob?.jobNumber}</p>
                                      </div>
                                    </div>

                                    <figure>
                                      <img src="images/taskimg-1.png" />
                                    </figure>
                                    <span className="CbbOverlay" />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>
                          {landDevelopmentHomeListData?.clientCount?.count ?? 0}
                        </h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="CommonBox CommonBoxTable"
                      onClick={() => handleLandConstructionShow()}
                    >
                      <div className="CommonBoxHeader">
                        <h6>Construction</h6>
                        <h5>
                          {landDevelopmentHomeListData?.construction?.count}
                        </h5>
                        <figure>
                          <img src={box2} />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(
                          landDevelopmentHomeListData?.construction
                            ?.construction
                        ) &&
                          landDevelopmentHomeListData?.construction?.construction?.map(
                            (ele, i) => {
                              return (
                                <>
                                  <div className="CommonBoxBodyUser">
                                    <div className="CommonBoxBodyTitle">
                                      <span>MB</span>
                                      <div className="">
                                        <h6>
                                          {ele?.ResidentialJob?.subdividision}
                                        </h6>
                                        <p>{ele?.ResidentialJob?.jobNumber}</p>
                                      </div>
                                    </div>

                                    <figure>
                                      <img src="images/taskimg-1.png" />
                                    </figure>
                                    <span className="CbbOverlay" />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>
                          {landDevelopmentHomeListData?.construction?.count}
                        </h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="CommonBox"
                      onClick={() => handleLandDesignShow()}
                    >
                      <div className="CommonBoxHeader">
                        {/* <Link to="/design-selections"> */}
                        <h6>Design Selections</h6>
                        <h5>
                          {landDevelopmentHomeListData?.designSelections?.count}
                        </h5>
                        {/* </Link> */}
                        <figure>
                          <img src="images/box-6.png" />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(
                          landDevelopmentHomeListData?.designSelections
                            ?.designSelections
                        ) &&
                          landDevelopmentHomeListData?.designSelections?.designSelections?.map(
                            (ele, i) => {
                              return (
                                <>
                                  <div className="CommonBoxBodyUser">
                                    <div className="CommonBoxBodyTitle">
                                      <span>MB</span>
                                      <div className="">
                                        <h6>
                                          {ele?.ResidentialJob?.subdividision}
                                        </h6>
                                        <p>{ele?.ResidentialJob?.jobNumber}</p>
                                      </div>
                                    </div>

                                    <figure>
                                      <img src="images/taskimg-1.png" />
                                    </figure>
                                    <span className="CbbOverlay" />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>
                          {landDevelopmentHomeListData?.designSelections?.count}
                        </h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div
                      className="CommonBox CommonBoxWarranty"
                      onClick={() => handleLandEstimationShow()}
                    >
                      <div className="CommonBoxHeader">
                        <h6>Estimation</h6>
                        <h5>
                          {landDevelopmentHomeListData?.estimation?.count}
                        </h5>
                        <figure>
                          <img src="images/box-6.png" />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(
                          landDevelopmentHomeListData?.estimation?.estimation
                        ) &&
                          landDevelopmentHomeListData?.estimation?.estimation?.map(
                            (ele, i) => {
                              return (
                                <>
                                  <div className="CommonBoxBodyUser">
                                    <div className="CommonBoxBodyTitle">
                                      <span>MB</span>
                                      <div className="">
                                        <h6>
                                          {ele?.ResidentialJob?.subdividision}
                                        </h6>
                                        <p>{ele?.ResidentialJob?.jobNumber}</p>
                                      </div>
                                    </div>

                                    <figure>
                                      <img src="images/taskimg-1.png" />
                                    </figure>
                                    <span className="CbbOverlay" />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>
                          {landDevelopmentHomeListData?.estimation?.count}
                        </h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div
                      className="CommonBox CommonBoxWarranty"
                      onClick={() => handleLandWarrantyShow()}
                    >
                      <div className="CommonBoxHeader">
                        <h6>Warranty</h6>
                        <h5>
                          {landDevelopmentHomeListData?.warranties?.count}
                        </h5>
                        <figure>
                          <img src={box1} />
                        </figure>
                      </div>
                      <div className="CommonBoxBody">
                        {checkArray(
                          landDevelopmentHomeListData?.warranties?.warranties
                        ) &&
                          landDevelopmentHomeListData?.warranties?.warranties?.map(
                            (ele, i) => {
                              return (
                                <>
                                  <div className="CommonBoxBodyUser">
                                    <div className="CommonBoxBodyTitle">
                                      <span>MB</span>
                                      <div className="">
                                        <h6>
                                          {ele?.ResidentialJob?.subdividision}
                                        </h6>
                                        <p>{ele?.ResidentialJob?.jobNumber}</p>
                                      </div>
                                    </div>

                                    <figure>
                                      <img src="images/taskimg-1.png" />
                                    </figure>
                                    <span className="CbbOverlay" />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                      <div className="CommonBoxFooter">
                        <h5>
                          {landDevelopmentHomeListData?.warranties?.count}
                        </h5>
                        <p>Total active tasks group</p>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
                {/* </div> */}
              </div>
            </div>

            {landWarrantyShow && (
              <>
                <div
                  className="CommonTableArea MultiFamilyConstructionTable"
                  style={{ display: "block" }}
                >
                  <div className="JobTableHeaderOuter">
                    <div className="JobTableHeaderInner">
                      <h5>Warranty Job/Unit List</h5>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#LegalDescriptionSettings"
                        >
                          <img src="images/icon-52.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-51.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-54.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-55.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="JobTableCommon">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Job Number</th>
                          <th>Build Type</th>
                          <th>Address</th>
                          {/* <th>Lot</th>
                      <th>Block</th>
                      <th>Phase</th>
                      <th>Plan</th> */}
                          <th>Sub Division</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="addClasss JobTabletbodyRed">
                        {warrantyJobsList?.data?.length > 0
                          ? warrantyJobsList?.data?.map((item, i) => {
                              return (
                                <>
                                  <tr key={i}>
                                    <td>{item.id}</td>
                                    <td>
                                      <Link
                                        to="/warranty-dashboard"
                                        state={item}
                                      >
                                        {item.jobNumber ?? "N/A"}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to="/warranty-dashboard"
                                        state={item}
                                      >
                                        {item.projectType}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to="/warranty-dashboard"
                                        state={item}
                                      >
                                        {item.projectAddress}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to="/warranty-dashboard"
                                        state={item}
                                      >
                                        {/* {item?.subdivisions?.map((x) => x)} */}
                                      </Link>
                                    </td>
                                    <td>
                                      <div className="TodoUser TodoUserHome">
                                        <div className="TodoUserJob">
                                          <div className="dropdown">
                                            <p data-toggle="dropdown">
                                              <span>
                                                <img src="images/three-dots.png" />
                                              </span>
                                            </p>
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuOffset"
                                            >
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    setcreatePhaseModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}                                                >
                                                  <img src="images/add_build.png" />{" "}
                                                  Add to/Edit Build
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                              </li>
                                              {/* <li>
                                                <a
                                                  onClick={() => {
                                                    setJobDetialsModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}
                                                >
                                                  <img src="images/details.png" />
                                                  Details
                                                </a>
                                              </li> */}
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/change_order.png" />{" "}
                                                  Change Order
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="change_order_list.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/change_order.png" />
                                                      Change orders List
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="design_selections.html"
                                                >
                                                  <img src="images/design.png" />
                                                  Design
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                  data-toggle="modal"
                                                  data-targer="#ClientContractDetailsNoUnit"
                                                >
                                                  <img src="images/sales.png" />
                                                  Sales
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/estimation.png" />{" "}
                                                  Estimation
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="budget_details.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/estimation.png" />
                                                      Budget Details
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="construction.html"
                                                >
                                                  <img src="images/construction_icon.png" />{" "}
                                                  Construction
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_1.png" />{" "}
                                                      Schedule View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_2.png" />{" "}
                                                      Gantt View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_3.png" />{" "}
                                                      Calendar View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_4.png" />{" "}
                                                      List View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_5.png" />{" "}
                                                      Baseline
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/warranty-dashboard"
                                                >
                                                  <img src="images/warranty.png" />
                                                  Warranty
                                                </Link>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/models"
                                                >
                                                  <img src="images/model.png" />{" "}
                                                  Model
                                                  <img src="images/rightarrow2.png" />
                                                </Link>
                                                {/* <ul className="dropdown-menu submenu">
            <li>
              <a
                to="javascript:void(0);"
                className="dropdown-item text-center"
                data-toggle="modal"
                data-target="#CreateNewModelHome"
              >
                <img src="images/model.png" /> Create New Model
              </a>
            </li>
          </ul> */}
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="reports.html"
                                                >
                                                  <img src="images/reports.png" />
                                                  Reports
                                                </a>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to={`/client-view-config`}
                                                  state={{ item }}
                                                >
                                                  <img src="images/view_config.png" />
                                                  Views Configs
                                                </Link>
                                              </li>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}

            {landConstructionShow && (
              <>
                <div
                  className="CommonTableArea MultiFamilyConstructionTable"
                  style={{ display: "block" }}
                >
                  <div className="JobTableHeaderOuter">
                    <div className="JobTableHeaderInner">
                      <h5>Construction Job/Unit List</h5>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#LegalDescriptionSettings"
                        >
                          <img src="images/icon-52.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-51.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-54.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-55.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="JobTableCommon">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Job Number</th>
                          <th>Build Type</th>
                          <th>Address</th>
                          <th>Lot</th>
                          <th>Block</th>
                          <th>Phase</th>
                          <th>Plan</th>
                          <th>Sub Division</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="addClasss JobTabletbodyRed">
                        {landDevelopmentProjectList?.projects?.length > 0 ? (
                          landDevelopmentProjectList?.projects?.map(
                            (item, i) => {
                              return (
                                <>
                                  <tr key={i}>
                                    <td>
                                      <div className="d-flex">
                                        {item.id}
                                        <div className="dropdown">
                                          <span
                                            className="Dots"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </span>
                                          <div className="dropdown-menu">
                                            <a
                                              onClick={() =>
                                                handleArchiveJobs(
                                                  item.id,
                                                  item.jobType,
                                                  "land"
                                                )
                                              }
                                              className="dropdown-item"
                                            >
                                              {" "}
                                              <img src="images/archive_job.png" />{" "}
                                              Archive Job
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <a
                                        id="hidtableshow2"
                                        onClick={() =>
                                          handleLandPhaseListByProject(
                                            item.id,
                                            i
                                          )
                                        }
                                      >
                                        {item.jobNumber}&nbsp;
                                        <img
                                          src={"images/down-arrow.png"}
                                          style={{
                                            transform: `rotate(${
                                              landId === i ? "180deg" : "0deg"
                                            })`,
                                          }}
                                        />
                                      </a>
                                    </td>

                                    <td>
                                      <Link to="/construction" state={item}>
                                        {item.projectType}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link to="/construction" state={item}>
                                        {item.projectAddress}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        Link
                                        to="/construction"
                                        state={item}
                                      >
                                        {item.lot}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        Link
                                        to="/construction"
                                        state={item}
                                      >
                                        {item.block}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        Link
                                        to="/construction"
                                        state={item}
                                      >
                                        {item.phase}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        Link
                                        to="/construction"
                                        state={item}
                                      >
                                        {item.plan ?? "N/A"}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        Link
                                        to="/construction"
                                        state={item}
                                      >
                                        {/* {item?.subdivisions?.map((x) => x)} */}
                                      </Link>
                                    </td>
                                    <td>
                                      <div className="TodoUser TodoUserHome">
                                        <div className="TodoUserJob">
                                          <div className="dropdown">
                                            <p data-toggle="dropdown">
                                              <span>
                                                <img src="images/three-dots.png" />
                                              </span>
                                            </p>
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuOffset"
                                            >
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    setcreatePhaseModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}                                                 >
                                                  <img src="images/add_build.png" />{" "}
                                                  Create Land Development Land Tract/Phase wizard                                                   <img src="images/rightarrow2.png" />
                                                </a>
                                              </li>
                                              {/* <li>
                                                <a
                                                  onClick={() => {
                                                    setJobDetialsModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}
                                                >
                                                  <img src="images/details.png" />
                                                  Details
                                                </a>
                                              </li> */}
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/change_order.png" />{" "}
                                                  Change Order
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="change_order_list.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/change_order.png" />
                                                      Change orders List
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="design_selections.html"
                                                >
                                                  <img src="images/design.png" />
                                                  Design
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                  data-toggle="modal"
                                                  data-targer="#ClientContractDetailsNoUnit"
                                                >
                                                  <img src="images/sales.png" />
                                                  Sales
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/estimation.png" />{" "}
                                                  Estimation
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="budget_details.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/estimation.png" />
                                                      Budget Details
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="construction.html"
                                                >
                                                  <img src="images/construction_icon.png" />{" "}
                                                  Construction
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_1.png" />{" "}
                                                      Schedule View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_2.png" />{" "}
                                                      Gantt View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_3.png" />{" "}
                                                      Calendar View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_4.png" />{" "}
                                                      List View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_5.png" />{" "}
                                                      Baseline
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/warranty-dashboard"
                                                >
                                                  <img src="images/warranty.png" />
                                                  Warranty
                                                </Link>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/models"
                                                >
                                                  <img src="images/model.png" />{" "}
                                                  Model
                                                  <img src="images/rightarrow2.png" />
                                                </Link>
                                                {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="reports.html"
                                                >
                                                  <img src="images/reports.png" />
                                                  Reports
                                                </a>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to={`/client-view-config`}
                                                  state={{ item }}
                                                >
                                                  <img src="images/view_config.png" />
                                                  Views Configs
                                                </Link>
                                              </li>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td colSpan={10} className="padding_none">
                                      <div
                                        className="JobBoxTable"
                                        style={{
                                          display:
                                            landId == i ? "block" : "none",
                                        }}
                                      >
                                        <table>
                                          <thead>
                                            <tr>
                                              <th>#</th>
                                              <th>
                                                Land Track /Phase Job Number
                                              </th>
                                              <th>Description</th>
                                              <th>Land Track Square Footage</th>
                                              <th>Action</th>

                                              
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {landId == i &&
                                              landPhaseList?.landPhase?.length >
                                                0 &&
                                              landPhaseList?.landPhase?.map(
                                                (data, index) => {
                                                  return (
                                                    <>
                                                      <tr key={index}>
                                                        <td>
                                                          <div className="d-flex">
                                                            {data.id}
                                                            <div className="dropdown">
                                                              <span
                                                                className="Dots"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                              >
                                                                <img src="images/dots.png" />
                                                              </span>
                                                              <div className="dropdown-menu">
                                                                <a
                                                                  onClick={() =>
                                                                    handleArchiveJobs(
                                                                      data.id,
                                                                      data.jobType,
                                                                      "land"
                                                                    )
                                                                  }
                                                                  className="dropdown-item"
                                                                >
                                                                  {" "}
                                                                  <img src="images/archive_job.png" />{" "}
                                                                  Archive Job
                                                                </a>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          {/* <Link
                                                    to="/estimation"
                                                    state={data}
                                                  > */}
                                                          {data?.jobNumber ??
                                                            "N.A"}
                                                          {/* </Link> */}
                                                          &nbsp;
                                                          {data?.Lots?.length >
                                                            0 && (
                                                            <img
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                updateState(
                                                                  (prev) => ({
                                                                    ...prev,
                                                                    landJobUnitOpenIndex:
                                                                      prev.landJobUnitOpenIndex ==
                                                                      index
                                                                        ? -1
                                                                        : index,
                                                                  })
                                                                );
                                                              }}
                                                              src={
                                                                "images/down-arrow.png"
                                                              }
                                                              style={{
                                                                transform: `rotate(${
                                                                  landJobUnitOpenIndex ===
                                                                  index
                                                                    ? "180deg"
                                                                    : "0deg"
                                                                })`,
                                                              }}
                                                            />
                                                          )}
                                                        </td>
                                                        <td>
                                                          <Link
                                                            to="/construction"
                                                            state={data}
                                                          >
                                                            {data.landTractPhaseDes ??
                                                              "N/A"}
                                                          </Link>
                                                        </td>
                                                        <td>
                                                          <Link
                                                            to="/construction"
                                                            state={data}
                                                          >
                                                            {data.footage ??
                                                              "N/A"}
                                                          </Link>
                                                        </td>
                                                        <td>
                                      <div className="TodoUser TodoUserHome">
                                        <div className="TodoUserJob">
                                          <div className="dropdown">
                                            <p data-toggle="dropdown">
                                              <span>
                                                <img src="images/three-dots.png" />
                                              </span>
                                            </p>
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuOffset"
                                            >
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    setCreateLotModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}                                                 >
                                                  <img src="images/add_build.png" />{" "}
                                                  Add to/Edit Build
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={() => {
                                                    setLandDevPhaseDetailsModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}
                                                >
                                                  <img src="images/details.png" />
                                                  Details
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/change_order.png" />{" "}
                                                  Change Order
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="change_order_list.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/change_order.png" />
                                                      Change orders List
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="design_selections.html"
                                                >
                                                  <img src="images/design.png" />
                                                  Design
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                  data-toggle="modal"
                                                  data-targer="#ClientContractDetailsNoUnit"
                                                >
                                                  <img src="images/sales.png" />
                                                  Sales
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/estimation.png" />{" "}
                                                  Estimation
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="budget_details.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/estimation.png" />
                                                      Budget Details
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="construction.html"
                                                >
                                                  <img src="images/construction_icon.png" />{" "}
                                                  Construction
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_1.png" />{" "}
                                                      Schedule View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_2.png" />{" "}
                                                      Gantt View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_3.png" />{" "}
                                                      Calendar View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_4.png" />{" "}
                                                      List View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_5.png" />{" "}
                                                      Baseline
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/warranty-dashboard"
                                                >
                                                  <img src="images/warranty.png" />
                                                  Warranty
                                                </Link>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/models"
                                                >
                                                  <img src="images/model.png" />{" "}
                                                  Model
                                                  <img src="images/rightarrow2.png" />
                                                </Link>
                                                {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="reports.html"
                                                >
                                                  <img src="images/reports.png" />
                                                  Reports
                                                </a>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to={`/client-view-config`}
                                                  state={{ item }}
                                                >
                                                  <img src="images/view_config.png" />
                                                  Views Configs
                                                </Link>
                                              </li>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                                        
                                                      </tr>
                                                      {data?.Lots?.length > 0 &&
                                                        landJobUnitOpenIndex ==
                                                          index && (
                                                          <tr>
                                                            <td colSpan={7}>
                                                              <div
                                                                className="ThirdLevelTable"
                                                                style={{
                                                                  border:
                                                                    "1px solid red",
                                                                  borderRadius: 15,
                                                                  padding: 5,
                                                                }}
                                                              >
                                                                <table>
                                                                  <thead>
                                                                    <tr>
                                                                      <th>#</th>
                                                                      <th>
                                                                        Lot Job
                                                                        Number
                                                                      </th>
                                                                      <th>
                                                                        Lot #
                                                                      </th>
                                                                      <th>
                                                                        Lot Type
                                                                      </th>
                                                                      <th>
                                                                        Primary
                                                                        Client
                                                                      </th>
                                                                      <th>
                                                                        Square
                                                                        Footage
                                                                        Description
                                                                      </th>
                                                                      <th>
                                                                        {" "}
                                                                        Description
                                                                      </th>
                                                                      <th>
                                                                        Action
                                                                      </th>
                                                                    </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                    {data?.Lots
                                                                      ?.length >
                                                                      0 &&
                                                                      data?.Lots?.map(
                                                                        (
                                                                          itemm,
                                                                          indd
                                                                        ) => {
                                                                          // console.log("itemm---->",itemm);
                                                                          return (
                                                                            <tr>
                                                                              <td>
                                                                                <div className="d-flex">
                                                                                  {
                                                                                    itemm.id
                                                                                  }
                                                                                  <div className="dropdown">
                                                                                    <span
                                                                                      className="Dots"
                                                                                      data-toggle="dropdown"
                                                                                      aria-haspopup="true"
                                                                                      aria-expanded="false"
                                                                                    >
                                                                                      <img src="images/dots.png" />
                                                                                    </span>
                                                                                    <div className="dropdown-menu">
                                                                                      <a
                                                                                        onClick={() =>
                                                                                          handleArchiveJobs(
                                                                                            itemm.id,
                                                                                            itemm.jobType,
                                                                                            "land"
                                                                                          )
                                                                                        }
                                                                                        className="dropdown-item"
                                                                                      >
                                                                                        {" "}
                                                                                        <img src="images/archive_job.png" />{" "}
                                                                                        Archive
                                                                                        Job
                                                                                      </a>
                                                                                      <a
                                                                                        className="dropdown-item"
                                                                                        onClick={() =>
                                                                                          handleShowInWarranty(
                                                                                            itemm.id,
                                                                                            itemm.jobType,
                                                                                            "land"
                                                                                          )
                                                                                        }
                                                                                      >
                                                                                        {" "}
                                                                                        <img src="images/home_warranty.png" />{" "}
                                                                                        Show
                                                                                        in
                                                                                        Warranty
                                                                                        &nbsp;&nbsp;
                                                                                      </a>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/construction"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.jobNumber ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/construction"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.numOfLot ??
                                                                                    "0"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/construction"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.lotType ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/construction"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.name ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/construction"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {
                                                                                    itemm?.lotDescription
                                                                                  }
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/construction"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.lotDescription ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <div className="TodoUser TodoUserHome text-center">
                                                                                  <div className="TodoUserJob">
                                                                                    <div className="dropdown">
                                                                                      <p data-toggle="dropdown">
                                                                                        <span>
                                                                                          <img src="images/three-dots.png" />
                                                                                        </span>
                                                                                      </p>
                                                                                      <div
                                                                                        className="dropdown-menu"
                                                                                        aria-labelledby="dropdownMenuOffset"
                                                                                      >
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                          >
                                                                                            <img src="images/add_build.png" />{" "}
                                                                                            Add
                                                                                            to/Edit
                                                                                            Build
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                        </li>
                                                                                        {/* <li>
                                                                                          <a
                                                                                            onClick={() => {
                                                                                              setJobDetialsModal(
                                                                                                true
                                                                                              );
                                                                                              setSelectedId(
                                                                                                item?.id
                                                                                              );
                                                                                              setJobName(
                                                                                                "Clent View Config"
                                                                                              );
                                                                                            }}
                                                                                          >
                                                                                            <img src="images/details.png" />
                                                                                            Details
                                                                                          </a>
                                                                                        </li> */}
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                          >
                                                                                            <img src="images/change_order.png" />{" "}
                                                                                            Change
                                                                                            Order
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu submenuhome">
                                                                                            <li>
                                                                                              <a
                                                                                                href="change_order_list.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/change_order.png" />
                                                                                                Change
                                                                                                orders
                                                                                                List
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="design_selections.html"
                                                                                          >
                                                                                            <img src="images/design.png" />
                                                                                            Design
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                            data-toggle="modal"
                                                                                            data-targer="#ClientContractDetailsNoUnit"
                                                                                          >
                                                                                            <img src="images/sales.png" />
                                                                                            Sales
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                          >
                                                                                            <img src="images/estimation.png" />{" "}
                                                                                            Estimation
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu submenuhome">
                                                                                            <li>
                                                                                              <a
                                                                                                href="budget_details.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/estimation.png" />
                                                                                                Budget
                                                                                                Details
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="construction.html"
                                                                                          >
                                                                                            <img src="images/construction_icon.png" />{" "}
                                                                                            Construction
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu submenuhome">
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_1.png" />{" "}
                                                                                                Schedule
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_2.png" />{" "}
                                                                                                Gantt
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_3.png" />{" "}
                                                                                                Calendar
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_4.png" />{" "}
                                                                                                List
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_5.png" />{" "}
                                                                                                Baseline
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="Warranty_Dashboard.html"
                                                                                          >
                                                                                            <img src="images/warranty.png" />
                                                                                            Warranty
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="models.html"
                                                                                          >
                                                                                            <img src="images/model.png" />{" "}
                                                                                            Model
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu">
                                                                                            <li>
                                                                                              <a
                                                                                                href="javascript:void(0);"
                                                                                                className="dropdown-item text-center"
                                                                                                data-toggle="modal"
                                                                                                data-target="#CreateNewModelHome"
                                                                                              >
                                                                                                <img src="images/model.png" />{" "}
                                                                                                Create
                                                                                                New
                                                                                                Model
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="reports.html"
                                                                                          >
                                                                                            <img src="images/reports.png" />
                                                                                            Reports
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="client-view-config.html"
                                                                                          >
                                                                                            <img src="images/view_config.png" />
                                                                                            Views
                                                                                            Configs
                                                                                          </a>
                                                                                        </li>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </td>
                                                                            </tr>
                                                                          );
                                                                        }
                                                                      )}
                                                                  </tbody>
                                                                </table>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        )}
                                                    </>
                                                  );
                                                }
                                              )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            }
                          )
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}

            {landDesignShow && (
              <>
                <div
                  className="CommonTableArea MultiFamilyConstructionTable"
                  style={{ display: "block" }}
                >
                  <div className="JobTableHeaderOuter">
                    <div className="JobTableHeaderInner">
                      <h5>Design Selections Job/Unit List</h5>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#LegalDescriptionSettings"
                        >
                          <img src="images/icon-52.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-51.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-54.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-55.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="JobTableCommon">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Job Number</th>
                          <th>Build Type</th>
                          <th>Address</th>
                          <th>Lot</th>
                          <th>Block</th>
                          <th>Phase</th>
                          <th>Plan</th>
                          <th>Sub Division</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="addClasss JobTabletbodyRed">
                        {landDevelopmentProjectList?.projects?.length > 0 ? (
                          landDevelopmentProjectList?.projects?.map(
                            (item, i) => {
                              return (
                                <>
                                  <tr key={i}>
                                    <td>
                                      <div className="d-flex">
                                        {item.id}
                                        <div className="dropdown">
                                          <span
                                            className="Dots"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </span>
                                          <div className="dropdown-menu">
                                            <a
                                              onClick={() =>
                                                handleArchiveJobs(
                                                  item.id,
                                                  item.jobType,
                                                  "land"
                                                )
                                              }
                                              className="dropdown-item"
                                            >
                                              {" "}
                                              <img src="images/archive_job.png" />{" "}
                                              Archive Job
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <a
                                        id="hidtableshow2"
                                        onClick={() =>
                                          handleLandPhaseListByProject(
                                            item.id,
                                            i
                                          )
                                        }
                                      >
                                        {item.jobNumber}&nbsp;
                                        <img
                                          src={"images/down-arrow.png"}
                                          style={{
                                            transform: `rotate(${
                                              landId === i ? "180deg" : "0deg"
                                            })`,
                                          }}
                                        />
                                      </a>
                                    </td>
                                    <td>
                                      <Link
                                        to="/design-selections"
                                        state={item}
                                      >
                                        {item.projectType}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to="/design-selections"
                                        state={item}
                                      >
                                        {item.projectAddress}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        Link
                                        to="/design-selections"
                                        state={item}
                                      >
                                        {item.lot}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        Link
                                        to="/design-selections"
                                        state={item}
                                      >
                                        {item.block}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        Link
                                        to="/design-selections"
                                        state={item}
                                      >
                                        {item.phase}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        Link
                                        to="/design-selections"
                                        state={item}
                                      >
                                        {item.plan ?? "N/A"}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        Link
                                        to="/design-selections"
                                        state={item}
                                      >
                                        {/* {item?.subdivisions?.map((x) => x)} */}
                                      </Link>
                                    </td>
                                    <td>
                                      <div className="TodoUser TodoUserHome">
                                        <div className="TodoUserJob">
                                          <div className="dropdown">
                                            <p data-toggle="dropdown">
                                              <span>
                                                <img src="images/three-dots.png" />
                                              </span>
                                            </p>
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuOffset"
                                            >
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    setcreatePhaseModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}                                                 >
                                                  <img src="images/add_build.png" />{" "}
                                                  Add to/Edit Build
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                              </li>
                                              {/* <li>
                                                <a
                                                  onClick={() => {
                                                    setJobDetialsModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}
                                                >
                                                  <img src="images/details.png" />
                                                  Details
                                                </a>
                                              </li> */}
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/change_order.png" />{" "}
                                                  Change Order
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="change_order_list.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/change_order.png" />
                                                      Change orders List
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="design_selections.html"
                                                >
                                                  <img src="images/design.png" />
                                                  Design
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                  data-toggle="modal"
                                                  data-targer="#ClientContractDetailsNoUnit"
                                                >
                                                  <img src="images/sales.png" />
                                                  Sales
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/estimation.png" />{" "}
                                                  Estimation
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="budget_details.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/estimation.png" />
                                                      Budget Details
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="construction.html"
                                                >
                                                  <img src="images/construction_icon.png" />{" "}
                                                  Construction
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_1.png" />{" "}
                                                      Schedule View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_2.png" />{" "}
                                                      Gantt View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_3.png" />{" "}
                                                      Calendar View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_4.png" />{" "}
                                                      List View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_5.png" />{" "}
                                                      Baseline
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/design-selections"
                                                >
                                                  <img src="images/warranty.png" />
                                                  Warranty
                                                </Link>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/models"
                                                >
                                                  <img src="images/model.png" />{" "}
                                                  Model
                                                  <img src="images/rightarrow2.png" />
                                                </Link>
                                                {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="reports.html"
                                                >
                                                  <img src="images/reports.png" />
                                                  Reports
                                                </a>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to={`/client-view-config`}
                                                  state={{ item }}
                                                >
                                                  <img src="images/view_config.png" />
                                                  Views Configs
                                                </Link>
                                              </li>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td colSpan={10} className="padding_none">
                                      <div
                                        className="JobBoxTable"
                                        style={{
                                          display:
                                            landId == i ? "block" : "none",
                                        }}
                                      >
                                        <table>
                                          <thead>
                                            <tr>
                                              <th>#</th>
                                              <th>
                                                Land Track /Phase Job Number
                                              </th>
                                              <th>Description</th>
                                              <th>Land Track Square Footage</th>
                                              <th>Action</th>
                                              <th />
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {landId == i &&
                                              landPhaseList?.landPhase?.length >
                                                0 &&
                                              landPhaseList?.landPhase?.map(
                                                (data, index) => {
                                                  return (
                                                    <>
                                                      <tr key={index}>
                                                        <td>
                                                          <div className="d-flex">
                                                            {data.id}
                                                            <div className="dropdown">
                                                              <span
                                                                className="Dots"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                              >
                                                                <img src="images/dots.png" />
                                                              </span>
                                                              <div className="dropdown-menu">
                                                                <a
                                                                  onClick={() =>
                                                                    handleArchiveJobs(
                                                                      data.id,
                                                                      data.jobType,
                                                                      "land"
                                                                    )
                                                                  }
                                                                  className="dropdown-item"
                                                                >
                                                                  {" "}
                                                                  <img src="images/archive_job.png" />{" "}
                                                                  Archive Job
                                                                </a>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          {/* <Link
                                                    to="/estimation"
                                                    state={data}
                                                  > */}
                                                          {data?.jobNumber ??
                                                            "N.A"}
                                                          {/* </Link> */}
                                                          &nbsp;
                                                          {data?.Lots?.length >
                                                            0 && (
                                                            <img
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                updateState(
                                                                  (prev) => ({
                                                                    ...prev,
                                                                    landJobUnitOpenIndex:
                                                                      prev.landJobUnitOpenIndex ==
                                                                      index
                                                                        ? -1
                                                                        : index,
                                                                  })
                                                                );
                                                              }}
                                                              src={
                                                                "images/down-arrow.png"
                                                              }
                                                              style={{
                                                                transform: `rotate(${
                                                                  landJobUnitOpenIndex ===
                                                                  index
                                                                    ? "180deg"
                                                                    : "0deg"
                                                                })`,
                                                              }}
                                                            />
                                                          )}
                                                        </td>
                                                        <td>
                                                          <Link
                                                            to="/design-selections"
                                                            state={data}
                                                          >
                                                            {data.landTractPhaseDes ??
                                                              "N/A"}
                                                          </Link>
                                                        </td>
                                                        <td>
                                                          <Link
                                                            to="/design-selections"
                                                            state={data}
                                                          >
                                                            {data.footage ??
                                                              "N/A"}
                                                          </Link>
                                                        </td>
                                                        <td>
                                      <div className="TodoUser TodoUserHome">
                                        <div className="TodoUserJob">
                                          <div className="dropdown">
                                            <p data-toggle="dropdown">
                                              <span>
                                                <img src="images/three-dots.png" />
                                              </span>
                                            </p>
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuOffset"
                                            >
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    setCreateLotModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}                                                 >
                                                  <img src="images/add_build.png" />{" "}
                                                  Add to/Edit Build
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={() => {
                                                    setLandDevPhaseDetailsModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}
                                                >
                                                  <img src="images/details.png" />
                                                  Details
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/change_order.png" />{" "}
                                                  Change Order
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="change_order_list.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/change_order.png" />
                                                      Change orders List
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="design_selections.html"
                                                >
                                                  <img src="images/design.png" />
                                                  Design
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                  data-toggle="modal"
                                                  data-targer="#ClientContractDetailsNoUnit"
                                                >
                                                  <img src="images/sales.png" />
                                                  Sales
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/estimation.png" />{" "}
                                                  Estimation
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="budget_details.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/estimation.png" />
                                                      Budget Details
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="construction.html"
                                                >
                                                  <img src="images/construction_icon.png" />{" "}
                                                  Construction
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_1.png" />{" "}
                                                      Schedule View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_2.png" />{" "}
                                                      Gantt View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_3.png" />{" "}
                                                      Calendar View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_4.png" />{" "}
                                                      List View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_5.png" />{" "}
                                                      Baseline
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/warranty-dashboard"
                                                >
                                                  <img src="images/warranty.png" />
                                                  Warranty
                                                </Link>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/models"
                                                >
                                                  <img src="images/model.png" />{" "}
                                                  Model
                                                  <img src="images/rightarrow2.png" />
                                                </Link>
                                                {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="reports.html"
                                                >
                                                  <img src="images/reports.png" />
                                                  Reports
                                                </a>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to={`/client-view-config`}
                                                  state={{ item }}
                                                >
                                                  <img src="images/view_config.png" />
                                                  Views Configs
                                                </Link>
                                              </li>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                                      </tr>
                                                      {data?.Lots?.length > 0 &&
                                                        landJobUnitOpenIndex ==
                                                          index && (
                                                          <tr>
                                                            <td colSpan={7}>
                                                              <div
                                                                className="ThirdLevelTable"
                                                                style={{
                                                                  border:
                                                                    "1px solid red",
                                                                  borderRadius: 15,
                                                                  padding: 5,
                                                                }}
                                                              >
                                                                <table>
                                                                  <thead>
                                                                    <tr>
                                                                      <th>#</th>
                                                                      <th>
                                                                        Lot Job
                                                                        Number
                                                                      </th>
                                                                      <th>
                                                                        Lot #
                                                                      </th>
                                                                      <th>
                                                                        Lot Type
                                                                      </th>
                                                                      <th>
                                                                        Primary
                                                                        Client
                                                                      </th>
                                                                      <th>
                                                                        Square
                                                                        Footage
                                                                        Description
                                                                      </th>
                                                                      <th>
                                                                        {" "}
                                                                        Description
                                                                      </th>
                                                                      <th>
                                                                        Action
                                                                      </th>
                                                                    </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                    {data?.Lots
                                                                      ?.length >
                                                                      0 &&
                                                                      data?.Lots?.map(
                                                                        (
                                                                          itemm,
                                                                          indd
                                                                        ) => {
                                                                          // console.log("itemm---->",itemm);
                                                                          return (
                                                                            <tr>
                                                                              <td>
                                                                                <div className="d-flex">
                                                                                  {
                                                                                    itemm.id
                                                                                  }
                                                                                  <div className="dropdown">
                                                                                    <span
                                                                                      className="Dots"
                                                                                      data-toggle="dropdown"
                                                                                      aria-haspopup="true"
                                                                                      aria-expanded="false"
                                                                                    >
                                                                                      <img src="images/dots.png" />
                                                                                    </span>
                                                                                    <div className="dropdown-menu">
                                                                                      <a
                                                                                        onClick={() =>
                                                                                          handleArchiveJobs(
                                                                                            itemm.id,
                                                                                            itemm.jobType,
                                                                                            "land"
                                                                                          )
                                                                                        }
                                                                                        className="dropdown-item"
                                                                                      >
                                                                                        {" "}
                                                                                        <img src="images/archive_job.png" />{" "}
                                                                                        Archive
                                                                                        Job
                                                                                      </a>
                                                                                      <a
                                                                                        className="dropdown-item"
                                                                                        onClick={() =>
                                                                                          handleShowInWarranty(
                                                                                            itemm.id,
                                                                                            itemm.jobType,
                                                                                            "land"
                                                                                          )
                                                                                        }
                                                                                      >
                                                                                        {" "}
                                                                                        <img src="images/home_warranty.png" />{" "}
                                                                                        Show
                                                                                        in
                                                                                        Warranty
                                                                                        &nbsp;&nbsp;
                                                                                      </a>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/design-selections"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.jobNumber ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/design-selections"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.numOfLot ??
                                                                                    "0"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/design-selections"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.lotType ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/design-selections"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.name ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/design-selections"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {
                                                                                    itemm?.lotDescription
                                                                                  }
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/design-selections"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.lotDescription ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <div className="TodoUser TodoUserHome text-center">
                                                                                  <div className="TodoUserJob">
                                                                                    <div className="dropdown">
                                                                                      <p data-toggle="dropdown">
                                                                                        <span>
                                                                                          <img src="images/three-dots.png" />
                                                                                        </span>
                                                                                      </p>
                                                                                      <div
                                                                                        className="dropdown-menu"
                                                                                        aria-labelledby="dropdownMenuOffset"
                                                                                      >
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                          >
                                                                                            <img src="images/add_build.png" />{" "}
                                                                                            Add
                                                                                            to/Edit
                                                                                            Build
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                        </li>
                                                                                        {/* <li>
                                                                                          <a
                                                                                            onClick={() => {
                                                                                              setJobDetialsModal(
                                                                                                true
                                                                                              );
                                                                                              setSelectedId(
                                                                                                item?.id
                                                                                              );
                                                                                              setJobName(
                                                                                                "Clent View Config"
                                                                                              );
                                                                                            }}
                                                                                          >
                                                                                            <img src="images/details.png" />
                                                                                            Details
                                                                                          </a>
                                                                                        </li> */}
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                          >
                                                                                            <img src="images/change_order.png" />{" "}
                                                                                            Change
                                                                                            Order
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu submenuhome">
                                                                                            <li>
                                                                                              <a
                                                                                                href="change_order_list.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/change_order.png" />
                                                                                                Change
                                                                                                orders
                                                                                                List
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="design_selections.html"
                                                                                          >
                                                                                            <img src="images/design.png" />
                                                                                            Design
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                            data-toggle="modal"
                                                                                            data-targer="#ClientContractDetailsNoUnit"
                                                                                          >
                                                                                            <img src="images/sales.png" />
                                                                                            Sales
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                          >
                                                                                            <img src="images/estimation.png" />{" "}
                                                                                            Estimation
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu submenuhome">
                                                                                            <li>
                                                                                              <a
                                                                                                href="budget_details.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/estimation.png" />
                                                                                                Budget
                                                                                                Details
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="construction.html"
                                                                                          >
                                                                                            <img src="images/construction_icon.png" />{" "}
                                                                                            Construction
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu submenuhome">
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_1.png" />{" "}
                                                                                                Schedule
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_2.png" />{" "}
                                                                                                Gantt
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_3.png" />{" "}
                                                                                                Calendar
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_4.png" />{" "}
                                                                                                List
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_5.png" />{" "}
                                                                                                Baseline
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="Warranty_Dashboard.html"
                                                                                          >
                                                                                            <img src="images/warranty.png" />
                                                                                            Warranty
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="models.html"
                                                                                          >
                                                                                            <img src="images/model.png" />{" "}
                                                                                            Model
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu">
                                                                                            <li>
                                                                                              <a
                                                                                                href="javascript:void(0);"
                                                                                                className="dropdown-item text-center"
                                                                                                data-toggle="modal"
                                                                                                data-target="#CreateNewModelHome"
                                                                                              >
                                                                                                <img src="images/model.png" />{" "}
                                                                                                Create
                                                                                                New
                                                                                                Model
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="reports.html"
                                                                                          >
                                                                                            <img src="images/reports.png" />
                                                                                            Reports
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="client-view-config.html"
                                                                                          >
                                                                                            <img src="images/view_config.png" />
                                                                                            Views
                                                                                            Configs
                                                                                          </a>
                                                                                        </li>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </td>
                                                                            </tr>
                                                                          );
                                                                        }
                                                                      )}
                                                                  </tbody>
                                                                </table>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        )}
                                                    </>
                                                  );
                                                }
                                              )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            }
                          )
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}

            {landChangeorderShow && (
              <>
                <div
                  className="CommonTableArea MultiFamilyConstructionTable"
                  style={{ display: "block" }}
                >
                  <div className="JobTableHeaderOuter">
                    <div className="JobTableHeaderInner">
                      <h5>Change Order Job/Unit List</h5>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#LegalDescriptionSettings"
                        >
                          <img src="images/icon-52.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-51.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-54.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-55.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="JobTableCommon">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Job Number</th>
                          <th>Build Type</th>
                          <th>Address</th>
                          <th>Lot</th>
                          <th>Block</th>
                          <th>Phase</th>
                          <th>Plan</th>
                          <th>Sub Division</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="addClasss JobTabletbodyRed">
                        {landDevelopmentProjectList?.projects?.length > 0 ? (
                          landDevelopmentProjectList?.projects?.map(
                            (item, i) => {
                              return (
                                <>
                                  <tr key={i}>
                                    <td>
                                      <div className="d-flex">
                                        {item.id}
                                        <div className="dropdown">
                                          <span
                                            className="Dots"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </span>
                                          <div className="dropdown-menu">
                                            <a
                                              onClick={() =>
                                                handleArchiveJobs(
                                                  item.id,
                                                  item.jobType,
                                                  "land"
                                                )
                                              }
                                              className="dropdown-item"
                                            >
                                              {" "}
                                              <img src="images/archive_job.png" />{" "}
                                              Archive Job
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <a
                                        id="hidtableshow2"
                                        onClick={() =>
                                          handleLandPhaseListByProject(
                                            item.id,
                                            i
                                          )
                                        }
                                      >
                                        {item.jobNumber}&nbsp;
                                        <img
                                          src={"images/down-arrow.png"}
                                          style={{
                                            transform: `rotate(${
                                              landId === i ? "180deg" : "0deg"
                                            })`,
                                          }}
                                        />
                                      </a>
                                    </td>
                                    <td>
                                      <Link
                                        to="/change-order-list"
                                        state={item}
                                      >
                                        {item.projectType}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to="/change-order-list"
                                        state={item}
                                      >
                                        {item.projectAddress}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        Link
                                        to="/change-order-list"
                                        state={item}
                                      >
                                        {item.lot}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        Link
                                        to="/change-order-list"
                                        state={item}
                                      >
                                        {item.block}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        Link
                                        to="/change-order-list"
                                        state={item}
                                      >
                                        {item.phase}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        Link
                                        to="/change-order-list"
                                        state={item}
                                      >
                                        {item.plan ?? "N/A"}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        Link
                                        to="/change-order-list"
                                        state={item}
                                      >
                                        {/* {item?.subdivisions?.map((x) => x)} */}
                                      </Link>
                                    </td>
                                    <td>
                                      <div className="TodoUser TodoUserHome">
                                        <div className="TodoUserJob">
                                          <div className="dropdown">
                                            <p data-toggle="dropdown">
                                              <span>
                                                <img src="images/three-dots.png" />
                                              </span>
                                            </p>
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuOffset"
                                            >
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    setcreatePhaseModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }} 
                                                >
                                                  <img src="images/add_build.png" />{" "}
                                                  Create Land Development Land Tract/Phase wizard                                                   <img src="images/rightarrow2.png" />
                                                </a>
                                              </li>
                                              {/* <li>
                                                <a
                                                  onClick={() => {
                                                    setJobDetialsModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}
                                                >
                                                  <img src="images/details.png" />
                                                  Details
                                                </a>
                                              </li> */}
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/change_order.png" />{" "}
                                                  Change Order
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="change_order_list.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/change_order.png" />
                                                      Change orders List
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="design_selections.html"
                                                >
                                                  <img src="images/design.png" />
                                                  Design
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                  data-toggle="modal"
                                                  data-targer="#ClientContractDetailsNoUnit"
                                                >
                                                  <img src="images/sales.png" />
                                                  Sales
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/estimation.png" />{" "}
                                                  Estimation
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="budget_details.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/estimation.png" />
                                                      Budget Details
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="construction.html"
                                                >
                                                  <img src="images/construction_icon.png" />{" "}
                                                  Construction
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_1.png" />{" "}
                                                      Schedule View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_2.png" />{" "}
                                                      Gantt View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_3.png" />{" "}
                                                      Calendar View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_4.png" />{" "}
                                                      List View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_5.png" />{" "}
                                                      Baseline
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/design-selections"
                                                >
                                                  <img src="images/warranty.png" />
                                                  Warranty
                                                </Link>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/models"
                                                >
                                                  <img src="images/model.png" />{" "}
                                                  Model
                                                  <img src="images/rightarrow2.png" />
                                                </Link>
                                                {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="reports.html"
                                                >
                                                  <img src="images/reports.png" />
                                                  Reports
                                                </a>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to={`/client-view-config`}
                                                  state={{ item }}
                                                >
                                                  <img src="images/view_config.png" />
                                                  Views Configs
                                                </Link>
                                              </li>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td colSpan={10} className="padding_none">
                                      <div
                                        className="JobBoxTable"
                                        style={{
                                          display:
                                            landId == i ? "block" : "none",
                                        }}
                                      >
                                        <table>
                                          <thead>
                                            <tr>
                                              <th>#</th>
                                              <th>
                                                Land Track /Phase Job Number
                                              </th>
                                              <th>Description</th>
                                              <th>Land Track Square Footage</th>
                                              <th>Action</th>
                                              <th />
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {landId == i &&
                                              landPhaseList?.landPhase?.length >
                                                0 &&
                                              landPhaseList?.landPhase?.map(
                                                (data, index) => {
                                                  return (
                                                    <>
                                                      <tr key={index}>
                                                        <td>
                                                          <div className="d-flex">
                                                            {data.id}
                                                            <div className="dropdown">
                                                              <span
                                                                className="Dots"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                              >
                                                                <img src="images/dots.png" />
                                                              </span>
                                                              <div className="dropdown-menu">
                                                                <a
                                                                  onClick={() =>
                                                                    handleArchiveJobs(
                                                                      data.id,
                                                                      data.jobType,
                                                                      "land"
                                                                    )
                                                                  }
                                                                  className="dropdown-item"
                                                                >
                                                                  {" "}
                                                                  <img src="images/archive_job.png" />{" "}
                                                                  Archive Job
                                                                </a>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          {/* <Link
                                                    to="/estimation"
                                                    state={data}
                                                  > */}
                                                          {data?.jobNumber ??
                                                            "N.A"}
                                                          {/* </Link> */}
                                                          &nbsp;
                                                          {data?.Lots?.length >
                                                            0 && (
                                                            <img
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                updateState(
                                                                  (prev) => ({
                                                                    ...prev,
                                                                    landJobUnitOpenIndex:
                                                                      prev.landJobUnitOpenIndex ==
                                                                      index
                                                                        ? -1
                                                                        : index,
                                                                  })
                                                                );
                                                              }}
                                                              src={
                                                                "images/down-arrow.png"
                                                              }
                                                              style={{
                                                                transform: `rotate(${
                                                                  landJobUnitOpenIndex ===
                                                                  index
                                                                    ? "180deg"
                                                                    : "0deg"
                                                                })`,
                                                              }}
                                                            />
                                                          )}
                                                        </td>
                                                        <td>
                                                          <Link
                                                            to="/change-order-list"
                                                            state={data}
                                                          >
                                                            {data.landTractPhaseDes ??
                                                              "N/A"}
                                                          </Link>
                                                        </td>
                                                        <td>
                                                          <Link
                                                            to="/change-order-list"
                                                            state={data}
                                                          >
                                                            {data.footage ??
                                                              "N/A"}
                                                          </Link>
                                                        </td>
                                                        <td>
                                      <div className="TodoUser TodoUserHome">
                                        <div className="TodoUserJob">
                                          <div className="dropdown">
                                            <p data-toggle="dropdown">
                                              <span>
                                                <img src="images/three-dots.png" />
                                              </span>
                                            </p>
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuOffset"
                                            >
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    setCreateLotModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}                                                 >
                                                  <img src="images/add_build.png" />{" "}
                                                  Add to/Edit Build
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={() => {
                                                    setLandDevPhaseDetailsModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}
                                                >
                                                  <img src="images/details.png" />
                                                  Details
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/change_order.png" />{" "}
                                                  Change Order
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="change_order_list.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/change_order.png" />
                                                      Change orders List
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="design_selections.html"
                                                >
                                                  <img src="images/design.png" />
                                                  Design
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                  data-toggle="modal"
                                                  data-targer="#ClientContractDetailsNoUnit"
                                                >
                                                  <img src="images/sales.png" />
                                                  Sales
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/estimation.png" />{" "}
                                                  Estimation
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="budget_details.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/estimation.png" />
                                                      Budget Details
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="construction.html"
                                                >
                                                  <img src="images/construction_icon.png" />{" "}
                                                  Construction
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_1.png" />{" "}
                                                      Schedule View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_2.png" />{" "}
                                                      Gantt View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_3.png" />{" "}
                                                      Calendar View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_4.png" />{" "}
                                                      List View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_5.png" />{" "}
                                                      Baseline
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/warranty-dashboard"
                                                >
                                                  <img src="images/warranty.png" />
                                                  Warranty
                                                </Link>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/models"
                                                >
                                                  <img src="images/model.png" />{" "}
                                                  Model
                                                  <img src="images/rightarrow2.png" />
                                                </Link>
                                                {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="reports.html"
                                                >
                                                  <img src="images/reports.png" />
                                                  Reports
                                                </a>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to={`/client-view-config`}
                                                  state={{ item }}
                                                >
                                                  <img src="images/view_config.png" />
                                                  Views Configs
                                                </Link>
                                              </li>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                                      </tr>
                                                      {data?.Lots?.length > 0 &&
                                                        landJobUnitOpenIndex ==
                                                          index && (
                                                          <tr>
                                                            <td colSpan={7}>
                                                              <div
                                                                className="ThirdLevelTable"
                                                                style={{
                                                                  border:
                                                                    "1px solid red",
                                                                  borderRadius: 15,
                                                                  padding: 5,
                                                                }}
                                                              >
                                                                <table>
                                                                  <thead>
                                                                    <tr>
                                                                      <th>#</th>
                                                                      <th>
                                                                        Lot Job
                                                                        Number
                                                                      </th>
                                                                      <th>
                                                                        Lot #
                                                                      </th>
                                                                      <th>
                                                                        Lot Type
                                                                      </th>
                                                                      <th>
                                                                        Primary
                                                                        Client
                                                                      </th>
                                                                      <th>
                                                                        Square
                                                                        Footage
                                                                        Description
                                                                      </th>
                                                                      <th>
                                                                        {" "}
                                                                        Description
                                                                      </th>
                                                                      <th>
                                                                        Action
                                                                      </th>
                                                                    </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                    {data?.Lots
                                                                      ?.length >
                                                                      0 &&
                                                                      data?.Lots?.map(
                                                                        (
                                                                          itemm,
                                                                          indd
                                                                        ) => {
                                                                          // console.log("itemm---->",itemm);
                                                                          return (
                                                                            <tr>
                                                                              <td>
                                                                                <div className="d-flex">
                                                                                  {
                                                                                    itemm.id
                                                                                  }
                                                                                  <div className="dropdown">
                                                                                    <span
                                                                                      className="Dots"
                                                                                      data-toggle="dropdown"
                                                                                      aria-haspopup="true"
                                                                                      aria-expanded="false"
                                                                                    >
                                                                                      <img src="images/dots.png" />
                                                                                    </span>
                                                                                    <div className="dropdown-menu">
                                                                                      <a
                                                                                        onClick={() =>
                                                                                          handleArchiveJobs(
                                                                                            itemm.id,
                                                                                            itemm.jobType,
                                                                                            "land"
                                                                                          )
                                                                                        }
                                                                                        className="dropdown-item"
                                                                                      >
                                                                                        {" "}
                                                                                        <img src="images/archive_job.png" />{" "}
                                                                                        Archive
                                                                                        Job
                                                                                      </a>
                                                                                      <a
                                                                                        className="dropdown-item"
                                                                                        onClick={() =>
                                                                                          handleShowInWarranty(
                                                                                            itemm.id,
                                                                                            itemm.jobType,
                                                                                            "land"
                                                                                          )
                                                                                        }
                                                                                      >
                                                                                        {" "}
                                                                                        <img src="images/home_warranty.png" />{" "}
                                                                                        Show
                                                                                        in
                                                                                        Warranty
                                                                                        &nbsp;&nbsp;
                                                                                      </a>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/change-order-list"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.jobNumber ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/change-order-list"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.numOfLot ??
                                                                                    "0"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/change-order-list"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.lotType ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/change-order-list"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.name ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/change-order-list"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {
                                                                                    itemm?.lotDescription
                                                                                  }
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/change-order-list"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.lotDescription ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <div className="TodoUser TodoUserHome text-center">
                                                                                  <div className="TodoUserJob">
                                                                                    <div className="dropdown">
                                                                                      <p data-toggle="dropdown">
                                                                                        <span>
                                                                                          <img src="images/three-dots.png" />
                                                                                        </span>
                                                                                      </p>
                                                                                      <div
                                                                                        className="dropdown-menu"
                                                                                        aria-labelledby="dropdownMenuOffset"
                                                                                      >
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                          >
                                                                                            <img src="images/add_build.png" />{" "}
                                                                                            Add
                                                                                            to/Edit
                                                                                            Build
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                        </li>
                                                                                        {/* <li>
                                                                                          <a
                                                                                            onClick={() => {
                                                                                              setJobDetialsModal(
                                                                                                true
                                                                                              );
                                                                                              setSelectedId(
                                                                                                item?.id
                                                                                              );
                                                                                              setJobName(
                                                                                                "Clent View Config"
                                                                                              );
                                                                                            }}
                                                                                          >
                                                                                            <img src="images/details.png" />
                                                                                            Details
                                                                                          </a>
                                                                                        </li> */}
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                          >
                                                                                            <img src="images/change_order.png" />{" "}
                                                                                            Change
                                                                                            Order
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu submenuhome">
                                                                                            <li>
                                                                                              <a
                                                                                                href="change_order_list.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/change_order.png" />
                                                                                                Change
                                                                                                orders
                                                                                                List
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="design_selections.html"
                                                                                          >
                                                                                            <img src="images/design.png" />
                                                                                            Design
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                            data-toggle="modal"
                                                                                            data-targer="#ClientContractDetailsNoUnit"
                                                                                          >
                                                                                            <img src="images/sales.png" />
                                                                                            Sales
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                          >
                                                                                            <img src="images/estimation.png" />{" "}
                                                                                            Estimation
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu submenuhome">
                                                                                            <li>
                                                                                              <a
                                                                                                href="budget_details.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/estimation.png" />
                                                                                                Budget
                                                                                                Details
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="construction.html"
                                                                                          >
                                                                                            <img src="images/construction_icon.png" />{" "}
                                                                                            Construction
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu submenuhome">
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_1.png" />{" "}
                                                                                                Schedule
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_2.png" />{" "}
                                                                                                Gantt
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_3.png" />{" "}
                                                                                                Calendar
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_4.png" />{" "}
                                                                                                List
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_5.png" />{" "}
                                                                                                Baseline
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="Warranty_Dashboard.html"
                                                                                          >
                                                                                            <img src="images/warranty.png" />
                                                                                            Warranty
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="models.html"
                                                                                          >
                                                                                            <img src="images/model.png" />{" "}
                                                                                            Model
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu">
                                                                                            <li>
                                                                                              <a
                                                                                                href="javascript:void(0);"
                                                                                                className="dropdown-item text-center"
                                                                                                data-toggle="modal"
                                                                                                data-target="#CreateNewModelHome"
                                                                                              >
                                                                                                <img src="images/model.png" />{" "}
                                                                                                Create
                                                                                                New
                                                                                                Model
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="reports.html"
                                                                                          >
                                                                                            <img src="images/reports.png" />
                                                                                            Reports
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="client-view-config.html"
                                                                                          >
                                                                                            <img src="images/view_config.png" />
                                                                                            Views
                                                                                            Configs
                                                                                          </a>
                                                                                        </li>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </td>
                                                                            </tr>
                                                                          );
                                                                        }
                                                                      )}
                                                                  </tbody>
                                                                </table>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        )}
                                                    </>
                                                  );
                                                }
                                              )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            }
                          )
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}

            {landEstimationShow && (
              <>
                <div
                  className="CommonTableArea MultiFamilyConstructionTable"
                  style={{ display: "block" }}
                >
                  <div className="JobTableHeaderOuter">
                    <div className="JobTableHeaderInner">
                      <h5>Estimation Unit List</h5>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#LegalDescriptionSettings"
                        >
                          <img src="images/icon-52.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-51.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-54.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-55.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="JobTableCommon">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Job Number</th>
                          <th>Build Type</th>
                          <th>Address</th>
                          <th>Lot</th>
                          <th>Block</th>
                          <th>Phase</th>
                          <th>Plan</th>
                          <th>Sub Division</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="addClasss JobTabletbodyRed">
                        {landDevelopmentProjectList?.projects?.length > 0 ? (
                          landDevelopmentProjectList?.projects?.map(
                            (item, i) => {
                              return (
                                <>
                                  <tr key={i}>
                                    <td>
                                      <div className="d-flex">
                                        {item.id}
                                        <div className="dropdown">
                                          <span
                                            className="Dots"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </span>
                                          <div className="dropdown-menu">
                                            <a
                                              onClick={() =>
                                                handleArchiveJobs(
                                                  item.id,
                                                  item.jobType,
                                                  "land"
                                                )
                                              }
                                              className="dropdown-item"
                                            >
                                              {" "}
                                              <img src="images/archive_job.png" />{" "}
                                              Archive Job
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <a
                                        id="hidtableshow2"
                                        onClick={() =>
                                          handleLandPhaseListByProject(
                                            item.id,
                                            i
                                          )
                                        }
                                      >
                                        {item.jobNumber}&nbsp;
                                        <img
                                          src={"images/down-arrow.png"}
                                          style={{
                                            transform: `rotate(${
                                              landId === i ? "180deg" : "0deg"
                                            })`,
                                          }}
                                        />
                                      </a>
                                    </td>
                                    <td>
                                      <Link to="/estimation" state={item}>
                                        {item.projectType}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link to="/estimation" state={item}>
                                        {item.projectAddress}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link Link to="/estimation" state={item}>
                                        {item.lot}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link Link to="/estimation" state={item}>
                                        {item.block}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link Link to="/estimation" state={item}>
                                        {item.phase}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link Link to="/estimation" state={item}>
                                        {item.plan ?? "N/A"}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link Link to="/estimation" state={item}>
                                        {/* {item?.subdivisions?.map((x) => x)} */}
                                      </Link>
                                    </td>
                                    <td>
                                      <div className="TodoUser TodoUserHome">
                                        <div className="TodoUserJob">
                                          <div className="dropdown">
                                            <p data-toggle="dropdown">
                                              <span>
                                                <img src="images/three-dots.png" />
                                              </span>
                                            </p>
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuOffset"
                                            >
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    setcreatePhaseModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}                                                 >
                                                  <img src="images/add_build.png" />{" "}
                                                  Add to/Edit Build
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                              </li>
                                              {/* <li>
                                                <a
                                                  onClick={() => {
                                                    setJobDetialsModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}
                                                >
                                                  <img src="images/details.png" />
                                                  Details
                                                </a>
                                              </li> */}
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/change_order.png" />{" "}
                                                  Change Order
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="change_order_list.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/change_order.png" />
                                                      Change orders List
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="design_selections.html"
                                                >
                                                  <img src="images/design.png" />
                                                  Design
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                  data-toggle="modal"
                                                  data-targer="#ClientContractDetailsNoUnit"
                                                >
                                                  <img src="images/sales.png" />
                                                  Sales
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/estimation.png" />{" "}
                                                  Estimation
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="budget_details.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/estimation.png" />
                                                      Budget Details
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="construction.html"
                                                >
                                                  <img src="images/construction_icon.png" />{" "}
                                                  Construction
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_1.png" />{" "}
                                                      Schedule View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_2.png" />{" "}
                                                      Gantt View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_3.png" />{" "}
                                                      Calendar View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_4.png" />{" "}
                                                      List View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_5.png" />{" "}
                                                      Baseline
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/design-selections"
                                                >
                                                  <img src="images/warranty.png" />
                                                  Warranty
                                                </Link>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/models"
                                                >
                                                  <img src="images/model.png" />{" "}
                                                  Model
                                                  <img src="images/rightarrow2.png" />
                                                </Link>
                                                {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="reports.html"
                                                >
                                                  <img src="images/reports.png" />
                                                  Reports
                                                </a>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to={`/client-view-config`}
                                                  state={{ item }}
                                                >
                                                  <img src="images/view_config.png" />
                                                  Views Configs
                                                </Link>
                                              </li>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td colSpan={10} className="padding_none">
                                      <div
                                        className="JobBoxTable"
                                        style={{
                                          display:
                                            landId == i ? "block" : "none",
                                        }}
                                      >
                                        <table>
                                          <thead>
                                            <tr>
                                              <th>#</th>
                                              <th>
                                                Land Track /Phase Job Number
                                              </th>
                                              <th>Description</th>
                                              <th>Land Track Square Footage</th>
                                              <th>Action</th>
                                              <th />
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {landId == i &&
                                              landPhaseList?.landPhase?.length >
                                                0 &&
                                              landPhaseList?.landPhase?.map(
                                                (data, index) => {
                                                  return (
                                                    <>
                                                      <tr key={index}>
                                                        <td>
                                                          <div className="d-flex">
                                                            {data.id}
                                                            <div className="dropdown">
                                                              <span
                                                                className="Dots"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                              >
                                                                <img src="images/dots.png" />
                                                              </span>
                                                              <div className="dropdown-menu">
                                                                <a
                                                                  onClick={() =>
                                                                    handleArchiveJobs(
                                                                      data.id,
                                                                      data.jobType,
                                                                      "land"
                                                                    )
                                                                  }
                                                                  className="dropdown-item"
                                                                >
                                                                  {" "}
                                                                  <img src="images/archive_job.png" />{" "}
                                                                  Archive Job
                                                                </a>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          {/* <Link
                                                    to="/estimation"
                                                    state={data}
                                                  > */}
                                                          {data?.jobNumber ??
                                                            "N.A"}
                                                          {/* </Link> */}
                                                          &nbsp;
                                                          {data?.Lots?.length >
                                                            0 && (
                                                            <img
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                updateState(
                                                                  (prev) => ({
                                                                    ...prev,
                                                                    landJobUnitOpenIndex:
                                                                      prev.landJobUnitOpenIndex ==
                                                                      index
                                                                        ? -1
                                                                        : index,
                                                                  })
                                                                );
                                                              }}
                                                              src={
                                                                "images/down-arrow.png"
                                                              }
                                                              style={{
                                                                transform: `rotate(${
                                                                  landJobUnitOpenIndex ===
                                                                  index
                                                                    ? "180deg"
                                                                    : "0deg"
                                                                })`,
                                                              }}
                                                            />
                                                          )}
                                                        </td>
                                                        <td>
                                                          <Link
                                                            to="/estimation"
                                                            state={data}
                                                          >
                                                            {data.landTractPhaseDes ??
                                                              "N/A"}
                                                          </Link>
                                                        </td>
                                                        <td>
                                                          <Link
                                                            to="/estimation"
                                                            state={data}
                                                          >
                                                            {data.footage ??
                                                              "N/A"}
                                                          </Link>
                                                        </td>
                                                        <td>
                                      <div className="TodoUser TodoUserHome">
                                        <div className="TodoUserJob">
                                          <div className="dropdown">
                                            <p data-toggle="dropdown">
                                              <span>
                                                <img src="images/three-dots.png" />
                                              </span>
                                            </p>
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuOffset"
                                            >
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    setCreateLotModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}                                                 >
                                                  <img src="images/add_build.png" />{" "}
                                                  Add to/Edit Build
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={() => {
                                                    setLandDevPhaseDetailsModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}
                                                >
                                                  <img src="images/details.png" />
                                                  Details
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/change_order.png" />{" "}
                                                  Change Order
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="change_order_list.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/change_order.png" />
                                                      Change orders List
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="design_selections.html"
                                                >
                                                  <img src="images/design.png" />
                                                  Design
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                  data-toggle="modal"
                                                  data-targer="#ClientContractDetailsNoUnit"
                                                >
                                                  <img src="images/sales.png" />
                                                  Sales
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/estimation.png" />{" "}
                                                  Estimation
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="budget_details.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/estimation.png" />
                                                      Budget Details
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="construction.html"
                                                >
                                                  <img src="images/construction_icon.png" />{" "}
                                                  Construction
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_1.png" />{" "}
                                                      Schedule View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_2.png" />{" "}
                                                      Gantt View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_3.png" />{" "}
                                                      Calendar View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_4.png" />{" "}
                                                      List View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_5.png" />{" "}
                                                      Baseline
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/warranty-dashboard"
                                                >
                                                  <img src="images/warranty.png" />
                                                  Warranty
                                                </Link>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/models"
                                                >
                                                  <img src="images/model.png" />{" "}
                                                  Model
                                                  <img src="images/rightarrow2.png" />
                                                </Link>
                                                {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="reports.html"
                                                >
                                                  <img src="images/reports.png" />
                                                  Reports
                                                </a>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to={`/client-view-config`}
                                                  state={{ item }}
                                                >
                                                  <img src="images/view_config.png" />
                                                  Views Configs
                                                </Link>
                                              </li>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                                      </tr>
                                                      {data?.Lots?.length > 0 &&
                                                        landJobUnitOpenIndex ==
                                                          index && (
                                                          <tr>
                                                            <td colSpan={7}>
                                                              <div
                                                                className="ThirdLevelTable"
                                                                style={{
                                                                  border:
                                                                    "1px solid red",
                                                                  borderRadius: 15,
                                                                  padding: 5,
                                                                }}
                                                              >
                                                                <table>
                                                                  <thead>
                                                                    <tr>
                                                                      <th>#</th>
                                                                      <th>
                                                                        Lot Job
                                                                        Number
                                                                      </th>
                                                                      <th>
                                                                        Lot #
                                                                      </th>
                                                                      <th>
                                                                        Lot Type
                                                                      </th>
                                                                      <th>
                                                                        Primary
                                                                        Client
                                                                      </th>
                                                                      <th>
                                                                        Square
                                                                        Footage
                                                                        Description
                                                                      </th>
                                                                      <th>
                                                                        {" "}
                                                                        Description
                                                                      </th>
                                                                      <th>
                                                                        Action
                                                                      </th>
                                                                    </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                    {data?.Lots
                                                                      ?.length >
                                                                      0 &&
                                                                      data?.Lots?.map(
                                                                        (
                                                                          itemm,
                                                                          indd
                                                                        ) => {
                                                                          // console.log("itemm---->",itemm);
                                                                          return (
                                                                            <tr>
                                                                              <td>
                                                                                <div className="d-flex">
                                                                                  {
                                                                                    itemm.id
                                                                                  }
                                                                                  <div className="dropdown">
                                                                                    <span
                                                                                      className="Dots"
                                                                                      data-toggle="dropdown"
                                                                                      aria-haspopup="true"
                                                                                      aria-expanded="false"
                                                                                    >
                                                                                      <img src="images/dots.png" />
                                                                                    </span>
                                                                                    <div className="dropdown-menu">
                                                                                      <a
                                                                                        onClick={() =>
                                                                                          handleArchiveJobs(
                                                                                            itemm.id,
                                                                                            itemm.jobType,
                                                                                            "land"
                                                                                          )
                                                                                        }
                                                                                        className="dropdown-item"
                                                                                      >
                                                                                        {" "}
                                                                                        <img src="images/archive_job.png" />{" "}
                                                                                        Archive
                                                                                        Job
                                                                                      </a>
                                                                                      <a
                                                                                        className="dropdown-item"
                                                                                        onClick={() =>
                                                                                          handleShowInWarranty(
                                                                                            itemm.id,
                                                                                            itemm.jobType,
                                                                                            "land"
                                                                                          )
                                                                                        }
                                                                                      >
                                                                                        {" "}
                                                                                        <img src="images/home_warranty.png" />{" "}
                                                                                        Show
                                                                                        in
                                                                                        Warranty
                                                                                        &nbsp;&nbsp;
                                                                                      </a>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/estimation"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.jobNumber ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/estimation"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.numOfLot ??
                                                                                    "0"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/estimation"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.lotType ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/estimation"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.name ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/estimation"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {
                                                                                    itemm?.lotDescription
                                                                                  }
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/estimation"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.lotDescription ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <div className="TodoUser TodoUserHome text-center">
                                                                                  <div className="TodoUserJob">
                                                                                    <div className="dropdown">
                                                                                      <p data-toggle="dropdown">
                                                                                        <span>
                                                                                          <img src="images/three-dots.png" />
                                                                                        </span>
                                                                                      </p>
                                                                                      <div
                                                                                        className="dropdown-menu"
                                                                                        aria-labelledby="dropdownMenuOffset"
                                                                                      >
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                          >
                                                                                            <img src="images/add_build.png" />{" "}
                                                                                            Add
                                                                                            to/Edit
                                                                                            Build
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                        </li>
                                                                                        {/* <li>
                                                                                          <a
                                                                                            onClick={() => {
                                                                                              setJobDetialsModal(
                                                                                                true
                                                                                              );
                                                                                              setSelectedId(
                                                                                                item?.id
                                                                                              );
                                                                                              setJobName(
                                                                                                "Clent View Config"
                                                                                              );
                                                                                            }}
                                                                                          >
                                                                                            <img src="images/details.png" />
                                                                                            Details
                                                                                          </a>
                                                                                        </li> */}
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                          >
                                                                                            <img src="images/change_order.png" />{" "}
                                                                                            Change
                                                                                            Order
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu submenuhome">
                                                                                            <li>
                                                                                              <a
                                                                                                href="change_order_list.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/change_order.png" />
                                                                                                Change
                                                                                                orders
                                                                                                List
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="design_selections.html"
                                                                                          >
                                                                                            <img src="images/design.png" />
                                                                                            Design
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                            data-toggle="modal"
                                                                                            data-targer="#ClientContractDetailsNoUnit"
                                                                                          >
                                                                                            <img src="images/sales.png" />
                                                                                            Sales
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                          >
                                                                                            <img src="images/estimation.png" />{" "}
                                                                                            Estimation
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu submenuhome">
                                                                                            <li>
                                                                                              <a
                                                                                                href="budget_details.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/estimation.png" />
                                                                                                Budget
                                                                                                Details
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="construction.html"
                                                                                          >
                                                                                            <img src="images/construction_icon.png" />{" "}
                                                                                            Construction
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu submenuhome">
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_1.png" />{" "}
                                                                                                Schedule
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_2.png" />{" "}
                                                                                                Gantt
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_3.png" />{" "}
                                                                                                Calendar
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_4.png" />{" "}
                                                                                                List
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_5.png" />{" "}
                                                                                                Baseline
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="Warranty_Dashboard.html"
                                                                                          >
                                                                                            <img src="images/warranty.png" />
                                                                                            Warranty
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="models.html"
                                                                                          >
                                                                                            <img src="images/model.png" />{" "}
                                                                                            Model
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu">
                                                                                            <li>
                                                                                              <a
                                                                                                href="javascript:void(0);"
                                                                                                className="dropdown-item text-center"
                                                                                                data-toggle="modal"
                                                                                                data-target="#CreateNewModelHome"
                                                                                              >
                                                                                                <img src="images/model.png" />{" "}
                                                                                                Create
                                                                                                New
                                                                                                Model
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="reports.html"
                                                                                          >
                                                                                            <img src="images/reports.png" />
                                                                                            Reports
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="client-view-config.html"
                                                                                          >
                                                                                            <img src="images/view_config.png" />
                                                                                            Views
                                                                                            Configs
                                                                                          </a>
                                                                                        </li>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </td>
                                                                            </tr>
                                                                          );
                                                                        }
                                                                      )}
                                                                  </tbody>
                                                                </table>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        )}
                                                    </>
                                                  );
                                                }
                                              )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            }
                          )
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
            {landAccountingShow && (
              <>
                <div
                  className="CommonTableArea MultiFamilyConstructionTable"
                  style={{ display: "block" }}
                >
                  <div className="JobTableHeaderOuter">
                    <div className="JobTableHeaderInner">
                      <h5>Accounting Job/Unit List</h5>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#LegalDescriptionSettings"
                        >
                          <img src="images/icon-52.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-51.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-54.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-55.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="JobTableCommon">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Job Number</th>
                          <th>Build Type</th>
                          <th>Address</th>
                          <th>Lot</th>
                          <th>Block</th>
                          <th>Phase</th>
                          <th>Plan</th>
                          <th>Sub Division</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="addClasss JobTabletbodyRed">
                        {landDevelopmentProjectList?.projects?.length > 0 ? (
                          landDevelopmentProjectList?.projects?.map(
                            (item, i) => {
                              return (
                                <>
                                  <tr key={i}>
                                    <td>
                                      <div className="d-flex">
                                        {item.id}
                                        <div className="dropdown">
                                          <span
                                            className="Dots"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </span>
                                          <div className="dropdown-menu">
                                            <a
                                              onClick={() =>
                                                handleArchiveJobs(
                                                  item.id,
                                                  item.jobType,
                                                  "land"
                                                )
                                              }
                                              className="dropdown-item"
                                            >
                                              {" "}
                                              <img src="images/archive_job.png" />{" "}
                                              Archive Job
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <a
                                        id="hidtableshow2"
                                        onClick={() =>
                                          handleLandPhaseListByProject(
                                            item.id,
                                            i
                                          )
                                        }
                                      >
                                        {item.jobNumber}&nbsp;
                                        <img
                                          src={"images/down-arrow.png"}
                                          style={{
                                            transform: `rotate(${
                                              landId === i ? "180deg" : "0deg"
                                            })`,
                                          }}
                                        />
                                      </a>
                                    </td>

                                    <td>
                                      <Link
                                        to="/transactions_manager"
                                        state={item}
                                      >
                                        {item.projectType}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to="/transactions_manager"
                                        state={item}
                                      >
                                        {item.projectAddress}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        Link
                                        to="/transactions_manager"
                                        state={item}
                                      >
                                        {item.lot}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        Link
                                        to="/transactions_manager"
                                        state={item}
                                      >
                                        {item.block}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        Link
                                        to="/transactions_manager"
                                        state={item}
                                      >
                                        {item.phase}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        Link
                                        to="/transactions_manager"
                                        state={item}
                                      >
                                        {item.plan ?? "N/A"}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        Link
                                        to="/transactions_manager"
                                        state={item}
                                      >
                                        {/* {item?.subdivisions?.map((x) => x)} */}
                                      </Link>
                                    </td>
                                    <td>
                                      <div className="TodoUser TodoUserHome">
                                        <div className="TodoUserJob">
                                          <div className="dropdown">
                                            <p data-toggle="dropdown">
                                              <span>
                                                <img src="images/three-dots.png" />
                                              </span>
                                            </p>
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuOffset"
                                            >
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    setcreatePhaseModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}                                                 >
                                                  <img src="images/add_build.png" />{" "}
                                                  Create Land Development Land Tract/Phase wizard 
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                              </li>
                                              {/* <li>
                                                <a
                                                  onClick={() => {
                                                    setJobDetialsModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}
                                                >
                                                  <img src="images/details.png" />
                                                  Details
                                                </a>
                                              </li> */}
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/change_order.png" />{" "}
                                                  Change Order
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="change_order_list.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/change_order.png" />
                                                      Change orders List
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="design_selections.html"
                                                >
                                                  <img src="images/design.png" />
                                                  Design
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                  data-toggle="modal"
                                                  data-targer="#ClientContractDetailsNoUnit"
                                                >
                                                  <img src="images/sales.png" />
                                                  Sales
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/estimation.png" />{" "}
                                                  Estimation
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="budget_details.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/estimation.png" />
                                                      Budget Details
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="construction.html"
                                                >
                                                  <img src="images/construction_icon.png" />{" "}
                                                  Construction
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_1.png" />{" "}
                                                      Schedule View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_2.png" />{" "}
                                                      Gantt View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_3.png" />{" "}
                                                      Calendar View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_4.png" />{" "}
                                                      List View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_5.png" />{" "}
                                                      Baseline
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/design-selections"
                                                >
                                                  <img src="images/warranty.png" />
                                                  Warranty
                                                </Link>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/models"
                                                >
                                                  <img src="images/model.png" />{" "}
                                                  Model
                                                  <img src="images/rightarrow2.png" />
                                                </Link>
                                                {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="reports.html"
                                                >
                                                  <img src="images/reports.png" />
                                                  Reports
                                                </a>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to={`/client-view-config`}
                                                  state={{ item }}
                                                >
                                                  <img src="images/view_config.png" />
                                                  Views Configs
                                                </Link>
                                              </li>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td colSpan={10} className="padding_none">
                                      <div
                                        className="JobBoxTable"
                                        style={{
                                          display:
                                            landId == i ? "block" : "none",
                                        }}
                                      >
                                        <table>
                                          <thead>
                                            <tr>
                                              <th>#</th>
                                              <th>
                                                Land Track /Phase Job Number
                                              </th>
                                              <th>Description</th>
                                              <th>Land Track Square Footage</th>
                                              <th>Action</th>
                                              <th />
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {landId == i &&
                                              landPhaseList?.landPhase?.length >
                                                0 &&
                                              landPhaseList?.landPhase?.map(
                                                (data, index) => {
                                                  return (
                                                    <>
                                                      <tr key={index}>
                                                        <td>
                                                          <div className="d-flex">
                                                            {data.id}
                                                            <div className="dropdown">
                                                              <span
                                                                className="Dots"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                              >
                                                                <img src="images/dots.png" />
                                                              </span>
                                                              <div className="dropdown-menu">
                                                                <a
                                                                  onClick={() =>
                                                                    handleArchiveJobs(
                                                                      data.id,
                                                                      data.jobType,
                                                                      "land"
                                                                    )
                                                                  }
                                                                  className="dropdown-item"
                                                                >
                                                                  {" "}
                                                                  <img src="images/archive_job.png" />{" "}
                                                                  Archive Job
                                                                </a>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          {/* <Link
                                                    to="/transactions_manager"
                                                    state={data}
                                                  > */}
                                                          {data?.jobNumber ??
                                                            "N.A"}
                                                          {/* </Link> */}
                                                          &nbsp;
                                                          {data?.Lots?.length >
                                                            0 && (
                                                            <img
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                updateState(
                                                                  (prev) => ({
                                                                    ...prev,
                                                                    landJobUnitOpenIndex:
                                                                      prev.landJobUnitOpenIndex ==
                                                                      index
                                                                        ? -1
                                                                        : index,
                                                                  })
                                                                );
                                                              }}
                                                              src={
                                                                "images/down-arrow.png"
                                                              }
                                                              style={{
                                                                transform: `rotate(${
                                                                  landJobUnitOpenIndex ===
                                                                  index
                                                                    ? "180deg"
                                                                    : "0deg"
                                                                })`,
                                                              }}
                                                            />
                                                          )}
                                                        </td>
                                                        <td>
                                                          <Link
                                                            to="/transactions_manager"
                                                            state={data}
                                                          >
                                                            {data.landTractPhaseDes ??
                                                              "N/A"}
                                                          </Link>
                                                        </td>
                                                        <td>
                                                          <Link
                                                            to="/transactions_manager"
                                                            state={data}
                                                          >
                                                            {data.footage ??
                                                              "N/A"}
                                                          </Link>
                                                        </td>
                                                        <td>
                                      <div className="TodoUser TodoUserHome">
                                        <div className="TodoUserJob">
                                          <div className="dropdown">
                                            <p data-toggle="dropdown">
                                              <span>
                                                <img src="images/three-dots.png" />
                                              </span>
                                            </p>
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuOffset"
                                            >
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    setCreateLotModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}                                                 >
                                                  <img src="images/add_build.png" />{" "}
                                                  Add to/Edit Build
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={() => {
                                                    setLandDevPhaseDetailsModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}
                                                >
                                                  <img src="images/details.png" />
                                                  Details
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/change_order.png" />{" "}
                                                  Change Order
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="change_order_list.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/change_order.png" />
                                                      Change orders List
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="design_selections.html"
                                                >
                                                  <img src="images/design.png" />
                                                  Design
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                  data-toggle="modal"
                                                  data-targer="#ClientContractDetailsNoUnit"
                                                >
                                                  <img src="images/sales.png" />
                                                  Sales
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/estimation.png" />{" "}
                                                  Estimation
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="budget_details.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/estimation.png" />
                                                      Budget Details
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="construction.html"
                                                >
                                                  <img src="images/construction_icon.png" />{" "}
                                                  Construction
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_1.png" />{" "}
                                                      Schedule View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_2.png" />{" "}
                                                      Gantt View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_3.png" />{" "}
                                                      Calendar View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_4.png" />{" "}
                                                      List View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_5.png" />{" "}
                                                      Baseline
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/warranty-dashboard"
                                                >
                                                  <img src="images/warranty.png" />
                                                  Warranty
                                                </Link>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/models"
                                                >
                                                  <img src="images/model.png" />{" "}
                                                  Model
                                                  <img src="images/rightarrow2.png" />
                                                </Link>
                                                {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="reports.html"
                                                >
                                                  <img src="images/reports.png" />
                                                  Reports
                                                </a>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to={`/client-view-config`}
                                                  state={{ item }}
                                                >
                                                  <img src="images/view_config.png" />
                                                  Views Configs
                                                </Link>
                                              </li>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                                      </tr>
                                                      {data?.Lots?.length > 0 &&
                                                        landJobUnitOpenIndex ==
                                                          index && (
                                                          <tr>
                                                            <td colSpan={7}>
                                                              <div
                                                                className="ThirdLevelTable"
                                                                style={{
                                                                  border:
                                                                    "1px solid red",
                                                                  borderRadius: 15,
                                                                  padding: 5,
                                                                }}
                                                              >
                                                                <table>
                                                                  <thead>
                                                                    <tr>
                                                                      <th>#</th>
                                                                      <th>
                                                                        Lot Job
                                                                        Number
                                                                      </th>
                                                                      <th>
                                                                        Lot #
                                                                      </th>
                                                                      <th>
                                                                        Lot Type
                                                                      </th>
                                                                      <th>
                                                                        Primary
                                                                        Client
                                                                      </th>
                                                                      <th>
                                                                        Square
                                                                        Footage
                                                                        Description
                                                                      </th>
                                                                      <th>
                                                                        {" "}
                                                                        Description
                                                                      </th>
                                                                      <th>
                                                                        Action
                                                                      </th>
                                                                    </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                    {data?.Lots
                                                                      ?.length >
                                                                      0 &&
                                                                      data?.Lots?.map(
                                                                        (
                                                                          itemm,
                                                                          indd
                                                                        ) => {
                                                                          // console.log("itemm---->",itemm);
                                                                          return (
                                                                            <tr>
                                                                              <td>
                                                                                <div className="d-flex">
                                                                                  {
                                                                                    itemm.id
                                                                                  }
                                                                                  <div className="dropdown">
                                                                                    <span
                                                                                      className="Dots"
                                                                                      data-toggle="dropdown"
                                                                                      aria-haspopup="true"
                                                                                      aria-expanded="false"
                                                                                    >
                                                                                      <img src="images/dots.png" />
                                                                                    </span>
                                                                                    <div className="dropdown-menu">
                                                                                      <a
                                                                                        onClick={() =>
                                                                                          handleArchiveJobs(
                                                                                            itemm.id,
                                                                                            itemm.jobType,
                                                                                            "land"
                                                                                          )
                                                                                        }
                                                                                        className="dropdown-item"
                                                                                      >
                                                                                        {" "}
                                                                                        <img src="images/archive_job.png" />{" "}
                                                                                        Archive
                                                                                        Job
                                                                                      </a>
                                                                                      <a
                                                                                        className="dropdown-item"
                                                                                        onClick={() =>
                                                                                          handleShowInWarranty(
                                                                                            itemm.id,
                                                                                            itemm.jobType,
                                                                                            "land"
                                                                                          )
                                                                                        }
                                                                                      >
                                                                                        {" "}
                                                                                        <img src="images/home_warranty.png" />{" "}
                                                                                        Show
                                                                                        in
                                                                                        Warranty
                                                                                        &nbsp;&nbsp;
                                                                                      </a>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/transactions_manager"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.jobNumber ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/transactions_manager"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.numOfLot ??
                                                                                    "0"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/transactions_manager"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.lotType ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/transactions_manager"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.name ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/transactions_manager"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {
                                                                                    itemm?.lotDescription
                                                                                  }
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/transactions_manager"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.lotDescription ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <div className="TodoUser TodoUserHome text-center">
                                                                                  <div className="TodoUserJob">
                                                                                    <div className="dropdown">
                                                                                      <p data-toggle="dropdown">
                                                                                        <span>
                                                                                          <img src="images/three-dots.png" />
                                                                                        </span>
                                                                                      </p>
                                                                                      <div
                                                                                        className="dropdown-menu"
                                                                                        aria-labelledby="dropdownMenuOffset"
                                                                                      >
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                          >
                                                                                            <img src="images/add_build.png" />{" "}
                                                                                            Add
                                                                                            to/Edit
                                                                                            Build
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                        </li>
                                                                                        {/* <li>
                                                                                          <a
                                                                                            onClick={() => {
                                                                                              setJobDetialsModal(
                                                                                                true
                                                                                              );
                                                                                              setSelectedId(
                                                                                                item?.id
                                                                                              );
                                                                                              setJobName(
                                                                                                "Clent View Config"
                                                                                              );
                                                                                            }}
                                                                                          >
                                                                                            <img src="images/details.png" />
                                                                                            Details
                                                                                          </a>
                                                                                        </li> */}
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                          >
                                                                                            <img src="images/change_order.png" />{" "}
                                                                                            Change
                                                                                            Order
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu submenuhome">
                                                                                            <li>
                                                                                              <a
                                                                                                href="change_order_list.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/change_order.png" />
                                                                                                Change
                                                                                                orders
                                                                                                List
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="design_selections.html"
                                                                                          >
                                                                                            <img src="images/design.png" />
                                                                                            Design
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                            data-toggle="modal"
                                                                                            data-targer="#ClientContractDetailsNoUnit"
                                                                                          >
                                                                                            <img src="images/sales.png" />
                                                                                            Sales
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                          >
                                                                                            <img src="images/estimation.png" />{" "}
                                                                                            Estimation
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu submenuhome">
                                                                                            <li>
                                                                                              <a
                                                                                                href="budget_details.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/estimation.png" />
                                                                                                Budget
                                                                                                Details
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="construction.html"
                                                                                          >
                                                                                            <img src="images/construction_icon.png" />{" "}
                                                                                            Construction
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu submenuhome">
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_1.png" />{" "}
                                                                                                Schedule
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_2.png" />{" "}
                                                                                                Gantt
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_3.png" />{" "}
                                                                                                Calendar
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_4.png" />{" "}
                                                                                                List
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_5.png" />{" "}
                                                                                                Baseline
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="Warranty_Dashboard.html"
                                                                                          >
                                                                                            <img src="images/warranty.png" />
                                                                                            Warranty
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="models.html"
                                                                                          >
                                                                                            <img src="images/model.png" />{" "}
                                                                                            Model
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu">
                                                                                            <li>
                                                                                              <a
                                                                                                href="javascript:void(0);"
                                                                                                className="dropdown-item text-center"
                                                                                                data-toggle="modal"
                                                                                                data-target="#CreateNewModelHome"
                                                                                              >
                                                                                                <img src="images/model.png" />{" "}
                                                                                                Create
                                                                                                New
                                                                                                Model
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="reports.html"
                                                                                          >
                                                                                            <img src="images/reports.png" />
                                                                                            Reports
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="client-view-config.html"
                                                                                          >
                                                                                            <img src="images/view_config.png" />
                                                                                            Views
                                                                                            Configs
                                                                                          </a>
                                                                                        </li>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </td>
                                                                            </tr>
                                                                          );
                                                                        }
                                                                      )}
                                                                  </tbody>
                                                                </table>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        )}
                                                    </>
                                                  );
                                                }
                                              )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            }
                          )
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}

            {landClientViewConfigShow && (
              <>
                <div
                  className="CommonTableArea MultiFamilyConstructionTable"
                  style={{ display: "block" }}
                >
                  <div className="JobTableHeaderOuter">
                    <div className="JobTableHeaderInner">
                      <h5>Client View Config Job/Unit List</h5>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#LegalDescriptionSettings"
                        >
                          <img src="images/icon-52.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-51.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-54.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/icon-55.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="JobTableCommon">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Job Number</th>
                          <th>Build Type</th>
                          <th>Address</th>
                          <th>Lot</th>
                          <th>Block</th>
                          <th>Phase</th>
                          <th>Plan</th>
                          <th>Sub Division</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="addClasss JobTabletbodyRed">
                        {landDevelopmentProjectList?.projects?.length > 0 ? (
                          landDevelopmentProjectList?.projects?.map(
                            (item, i) => {
                              return (
                                <>
                                  <tr key={i}>
                                    <td>
                                      <div className="d-flex">
                                        {item.id}
                                        <div className="dropdown">
                                          <span
                                            className="Dots"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </span>
                                          <div className="dropdown-menu">
                                            <a
                                              onClick={() =>
                                                handleArchiveJobs(
                                                  item.id,
                                                  item.jobType,
                                                  "land"
                                                )
                                              }
                                              className="dropdown-item"
                                            >
                                              {" "}
                                              <img src="images/archive_job.png" />{" "}
                                              Archive Job
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <a
                                        id="hidtableshow2"
                                        onClick={() =>
                                          handleLandPhaseListByProject(
                                            item.id,
                                            i
                                          )
                                        }
                                      >
                                        {item.jobNumber}&nbsp;
                                        <img
                                          src={"images/down-arrow.png"}
                                          style={{
                                            transform: `rotate(${
                                              landId === i ? "180deg" : "0deg"
                                            })`,
                                          }}
                                        />
                                      </a>
                                    </td>

                                    <td>
                                      <Link
                                        to={`/client-view-config`}
                                        state={{ item }}
                                      >
                                        {item.projectType}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/client-view-config`}
                                        state={{ item }}
                                      >
                                        {item.projectAddress}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/client-view-config`}
                                        state={{ item }}
                                      >
                                        {item.lot}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/client-view-config`}
                                        state={{ item }}
                                      >
                                        {item.block}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/client-view-config`}
                                        state={{ item }}
                                      >
                                        {item.phase}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/client-view-config`}
                                        state={{ item }}
                                      >
                                        {item?.plan ?? "N/A"}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/client-view-config`}
                                        state={{ item }}
                                      >
                                        {/* {item?.subdivisions?.map((x) => x)} */}
                                      </Link>
                                    </td>
                                    <td>
                                      <div className="TodoUser TodoUserHome">
                                        <div className="TodoUserJob">
                                          <div className="dropdown">
                                            <p data-toggle="dropdown">
                                              <span>
                                                <img src="images/three-dots.png" />
                                              </span>
                                            </p>
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuOffset"
                                            >
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    setcreatePhaseModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}                                                 >
                                                  <img src="images/add_build.png" />{" "}
                                                  Create Land Development Land Tract/Phase wizard                                                   <img src="images/rightarrow2.png" />
                                                </a>
                                              </li>
                                              {/* <li>
                                                <a
                                                  onClick={() => {
                                                    setJobDetialsModal(true);
                                                    setSelectedId(item?.id);
                                                    setJobName(
                                                      "Clent View Config"
                                                    );
                                                  }}
                                                >
                                                  <img src="images/details.png" />
                                                  Details
                                                </a>
                                              </li> */}
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/change_order.png" />{" "}
                                                  Change Order
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="change_order_list.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/change_order.png" />
                                                      Change orders List
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="design_selections.html"
                                                >
                                                  <img src="images/design.png" />
                                                  Design
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                  data-toggle="modal"
                                                  data-targer="#ClientContractDetailsNoUnit"
                                                >
                                                  <img src="images/sales.png" />
                                                  Sales
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="javascript:void(0);"
                                                >
                                                  <img src="images/estimation.png" />{" "}
                                                  Estimation
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="budget_details.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/estimation.png" />
                                                      Budget Details
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="construction.html"
                                                >
                                                  <img src="images/construction_icon.png" />{" "}
                                                  Construction
                                                  <img src="images/rightarrow2.png" />
                                                </a>
                                                <ul className="dropdown-menu submenu submenuhome">
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_1.png" />{" "}
                                                      Schedule View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_2.png" />{" "}
                                                      Gantt View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_3.png" />{" "}
                                                      Calendar View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_4.png" />{" "}
                                                      List View
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      to="construction.html"
                                                      className="dropdown-item"
                                                    >
                                                      <img src="images/construuction_5.png" />{" "}
                                                      Baseline
                                                    </a>
                                                  </li>
                                                </ul>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/design-selections"
                                                >
                                                  <img src="images/warranty.png" />
                                                  Warranty
                                                </Link>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to="/models"
                                                >
                                                  <img src="images/model.png" />{" "}
                                                  Model
                                                  <img src="images/rightarrow2.png" />
                                                </Link>
                                                {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  to="reports.html"
                                                >
                                                  <img src="images/reports.png" />
                                                  Reports
                                                </a>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  to={`/client-view-config`}
                                                  state={{ item }}
                                                >
                                                  <img src="images/view_config.png" />
                                                  Views Configs
                                                </Link>
                                              </li>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td colSpan={10} className="padding_none">
                                      <div
                                        className="JobBoxTable"
                                        style={{
                                          display:
                                            landId == i ? "block" : "none",
                                        }}
                                      >
                                        <table>
                                          <thead>
                                            <tr>
                                              <th>#</th>
                                              <th>
                                                Land Track /Phase Job Number
                                              </th>
                                              <th>Description</th>
                                              <th>Land Track Square Footage</th>
                                              <th />
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {landId == i &&
                                              landPhaseList?.landPhase?.length >
                                                0 &&
                                              landPhaseList?.landPhase?.map(
                                                (data, index) => {
                                                  return (
                                                    <>
                                                      <tr key={index}>
                                                        <td>
                                                          <div className="d-flex">
                                                            {data.id}
                                                            <div className="dropdown">
                                                              <span
                                                                className="Dots"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                              >
                                                                <img src="images/dots.png" />
                                                              </span>
                                                              <div className="dropdown-menu">
                                                                <a
                                                                  onClick={() =>
                                                                    handleArchiveJobs(
                                                                      data.id,
                                                                      data.jobType,
                                                                      "land"
                                                                    )
                                                                  }
                                                                  className="dropdown-item"
                                                                >
                                                                  {" "}
                                                                  <img src="images/archive_job.png" />{" "}
                                                                  Archive Job
                                                                </a>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          {/* <Link
                                                    to="/transactions_manager"
                                                    state={data}
                                                  > */}
                                                          {data?.jobNumber ??
                                                            "N.A"}
                                                          {/* </Link> */}
                                                          &nbsp;
                                                          {data?.Lots?.length >
                                                            0 && (
                                                            <img
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                updateState(
                                                                  (prev) => ({
                                                                    ...prev,
                                                                    landJobUnitOpenIndex:
                                                                      prev.landJobUnitOpenIndex ==
                                                                      index
                                                                        ? -1
                                                                        : index,
                                                                  })
                                                                );
                                                              }}
                                                              src={
                                                                "images/down-arrow.png"
                                                              }
                                                              style={{
                                                                transform: `rotate(${
                                                                  landJobUnitOpenIndex ===
                                                                  index
                                                                    ? "180deg"
                                                                    : "0deg"
                                                                })`,
                                                              }}
                                                            />
                                                          )}
                                                        </td>
                                                        <td>
                                                          <Link
                                                            to="/transactions_manager"
                                                            state={data}
                                                          >
                                                            {data.landTractPhaseDes ??
                                                              "N/A"}
                                                          </Link>
                                                        </td>
                                                        <td>
                                                          <Link
                                                            to="/transactions_manager"
                                                            state={data}
                                                          >
                                                            {data.footage ??
                                                              "N/A"}
                                                          </Link>
                                                        </td>
                                                      </tr>
                                                      {data?.Lots?.length > 0 &&
                                                        landJobUnitOpenIndex ==
                                                          index && (
                                                          <tr>
                                                            <td colSpan={7}>
                                                              <div
                                                                className="ThirdLevelTable"
                                                                style={{
                                                                  border:
                                                                    "1px solid red",
                                                                  borderRadius: 15,
                                                                  padding: 5,
                                                                }}
                                                              >
                                                                <table>
                                                                  <thead>
                                                                    <tr>
                                                                      <th>#</th>
                                                                      <th>
                                                                        Lot Job
                                                                        Number
                                                                      </th>
                                                                      <th>
                                                                        Lot #
                                                                      </th>
                                                                      <th>
                                                                        Lot Type
                                                                      </th>
                                                                      <th>
                                                                        Primary
                                                                        Client
                                                                      </th>
                                                                      <th>
                                                                        Square
                                                                        Footage
                                                                        Description
                                                                      </th>
                                                                      <th>
                                                                        {" "}
                                                                        Description
                                                                      </th>
                                                                      <th>
                                                                        Action
                                                                      </th>
                                                                    </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                    {data?.Lots
                                                                      ?.length >
                                                                      0 &&
                                                                      data?.Lots?.map(
                                                                        (
                                                                          itemm,
                                                                          indd
                                                                        ) => {
                                                                          // console.log("itemm---->",itemm);
                                                                          return (
                                                                            <tr>
                                                                              <td>
                                                                                <div className="d-flex">
                                                                                  {
                                                                                    itemm.id
                                                                                  }
                                                                                  <div className="dropdown">
                                                                                    <span
                                                                                      className="Dots"
                                                                                      data-toggle="dropdown"
                                                                                      aria-haspopup="true"
                                                                                      aria-expanded="false"
                                                                                    >
                                                                                      <img src="images/dots.png" />
                                                                                    </span>
                                                                                    <div className="dropdown-menu">
                                                                                      <a
                                                                                        onClick={() =>
                                                                                          handleArchiveJobs(
                                                                                            itemm.id,
                                                                                            itemm.jobType,
                                                                                            "land"
                                                                                          )
                                                                                        }
                                                                                        className="dropdown-item"
                                                                                      >
                                                                                        {" "}
                                                                                        <img src="images/archive_job.png" />{" "}
                                                                                        Archive
                                                                                        Job
                                                                                      </a>
                                                                                      <a
                                                                                        className="dropdown-item"
                                                                                        onClick={() =>
                                                                                          handleShowInWarranty(
                                                                                            itemm.id,
                                                                                            itemm.jobType,
                                                                                            "land"
                                                                                          )
                                                                                        }
                                                                                      >
                                                                                        {" "}
                                                                                        <img src="images/home_warranty.png" />{" "}
                                                                                        Show
                                                                                        in
                                                                                        Warranty
                                                                                        &nbsp;&nbsp;
                                                                                      </a>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/transactions_manager"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.jobNumber ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/transactions_manager"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.numOfLot ??
                                                                                    "0"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/transactions_manager"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.lotType ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/transactions_manager"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.name ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/transactions_manager"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {
                                                                                    itemm?.lotDescription
                                                                                  }
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <Link
                                                                                  to="/transactions_manager"
                                                                                  state={
                                                                                    data
                                                                                  }
                                                                                >
                                                                                  {itemm?.lotDescription ??
                                                                                    "N/A"}
                                                                                </Link>
                                                                              </td>
                                                                              <td>
                                                                                <div className="TodoUser TodoUserHome text-center">
                                                                                  <div className="TodoUserJob">
                                                                                    <div className="dropdown">
                                                                                      <p data-toggle="dropdown">
                                                                                        <span>
                                                                                          <img src="images/three-dots.png" />
                                                                                        </span>
                                                                                      </p>
                                                                                      <div
                                                                                        className="dropdown-menu"
                                                                                        aria-labelledby="dropdownMenuOffset"
                                                                                      >
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                          >
                                                                                            <img src="images/add_build.png" />{" "}
                                                                                            Add
                                                                                            to/Edit
                                                                                            Build
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                        </li>
                                                                                        {/* <li>
                                                                                          <a
                                                                                            onClick={() => {
                                                                                              setJobDetialsModal(
                                                                                                true
                                                                                              );
                                                                                              setSelectedId(
                                                                                                item?.id
                                                                                              );
                                                                                              setJobName(
                                                                                                "Clent View Config"
                                                                                              );
                                                                                            }}
                                                                                          >
                                                                                            <img src="images/details.png" />
                                                                                            Details
                                                                                          </a>
                                                                                        </li> */}
                                                                                        <li>
                                                                                           <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                          >
                                                                                            <img src="images/change_order.png" />{" "}
                                                                                            Change
                                                                                            Order
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu submenuhome">
                                                                                            <li>
                                                                                              <a
                                                                                                href="change_order_list.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/change_order.png" />
                                                                                                Change
                                                                                                orders
                                                                                                List
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="design_selections.html"
                                                                                          >
                                                                                            <img src="images/design.png" />
                                                                                            Design
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                            data-toggle="modal"
                                                                                            data-targer="#ClientContractDetailsNoUnit"
                                                                                          >
                                                                                            <img src="images/sales.png" />
                                                                                            Sales
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="javascript:void(0);"
                                                                                          >
                                                                                            <img src="images/estimation.png" />{" "}
                                                                                            Estimation
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu submenuhome">
                                                                                            <li>
                                                                                              <a
                                                                                                href="budget_details.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/estimation.png" />
                                                                                                Budget
                                                                                                Details
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="construction.html"
                                                                                          >
                                                                                            <img src="images/construction_icon.png" />{" "}
                                                                                            Construction
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu submenuhome">
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_1.png" />{" "}
                                                                                                Schedule
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_2.png" />{" "}
                                                                                                Gantt
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_3.png" />{" "}
                                                                                                Calendar
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_4.png" />{" "}
                                                                                                List
                                                                                                View
                                                                                              </a>
                                                                                            </li>
                                                                                            <li>
                                                                                              <a
                                                                                                href="construction.html"
                                                                                                className="dropdown-item"
                                                                                              >
                                                                                                <img src="images/construuction_5.png" />{" "}
                                                                                                Baseline
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="Warranty_Dashboard.html"
                                                                                          >
                                                                                            <img src="images/warranty.png" />
                                                                                            Warranty
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="models.html"
                                                                                          >
                                                                                            <img src="images/model.png" />{" "}
                                                                                            Model
                                                                                            <img src="images/rightarrow2.png" />
                                                                                          </a>
                                                                                          <ul className="dropdown-menu submenu">
                                                                                            <li>
                                                                                              <a
                                                                                                href="javascript:void(0);"
                                                                                                className="dropdown-item text-center"
                                                                                                data-toggle="modal"
                                                                                                data-target="#CreateNewModelHome"
                                                                                              >
                                                                                                <img src="images/model.png" />{" "}
                                                                                                Create
                                                                                                New
                                                                                                Model
                                                                                              </a>
                                                                                            </li>
                                                                                          </ul>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="reports.html"
                                                                                          >
                                                                                            <img src="images/reports.png" />
                                                                                            Reports
                                                                                          </a>
                                                                                        </li>
                                                                                        <li>
                                                                                          <a
                                                                                            className="dropdown-item"
                                                                                            href="client-view-config.html"
                                                                                          >
                                                                                            <img src="images/view_config.png" />
                                                                                            Views
                                                                                            Configs
                                                                                          </a>
                                                                                        </li>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </td>
                                                                            </tr>
                                                                          );
                                                                        }
                                                                      )}
                                                                  </tbody>
                                                                </table>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        )}
                                                    </>
                                                  );
                                                }
                                              )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            }
                          )
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {projectDetailsModal && (
        <ProjectDetailsModal
          projectDetailsModal={projectDetailsModal}
          jobName={jobName}
          setJobDetialsModal={setJobDetialsModal}
          selectedId={selectedId}
        />
      )}

      {editJobModal && (
        <EditJobModal
          editJobModal={editJobModal}
          jobName={jobName}
          jobId={jobId}
          setEditJobModal={setEditJobModal}
          selectedId={selectedId}
        />
      )}

      <CreateBlock
        createBlockModal={createBlockModal}
        setCreateBlockModal={setCreateBlockModal}
        setEditJobModal={setEditJobModal}
        selectedId={selectedId}
        jobId={jobId}
        UnitData={UnitData}
        
        jobData={jobData}
      />

      <CreateOnlyUnits
        createOnlyUnitsModal={createOnlyUnitsModal}
        setCreateOnlyUnitsModal={setCreateOnlyUnitsModal}
        selectedId={selectedId}
        jobData={jobData}

      />
      <CreateUnits
        createUnitsModal={createUnitsModal}
        setCreateUnitsModal={setCreateUnitsModal}
        setEditJobModal={setEditJobModal}
        units={units}
        jobData={jobData}

        jobId={jobId}
        selectedId={selectedId}
        createdFrom={"home"}
      />

<JobDetailsForBlock
          jobDetailsForBlock={jobDetailsForBlock}
          jobName={jobName}
          jobId={jobId}
          jobData={jobData}
          setJobDetailsForBlock={setJobDetailsForBlock}
          selectedId={selectedId}
        />

<JobDetailsForUnit
          jobDetailsForUnit={jobDetailsForUnit}
          jobName={jobName}
          jobId={jobId}
          jobData={jobData}
          setJobDetailsForUnit={setJobDetailsForUnit}
          selectedId={selectedId}
        />


<CreatePhaseModal
        createPhaseModal={createPhaseModal}
        setcreatePhaseModal={setcreatePhaseModal}
        jobId={jobId}
        selectedId={selectedId}
        createdFrom={"home"}
      />


<CreateLotModal
        createLotModal={createLotModal}
        setCreateLotModal={setCreateLotModal}
        jobId={jobId}
        selectedId={selectedId}
        createdFrom={"home"}
      />

<LandDevPhaseDetails
        landDevPhaseDetailsModal={landDevPhaseDetailsModal}
        setLandDevPhaseDetailsModal={setLandDevPhaseDetailsModal}
        jobId={jobId}
        selectedId={selectedId}
        createdFrom={"home"}
      />

{residentialJobModal &&   <ResidentialJobModal  residentialJobModal={residentialJobModal} setResidentialJobModal={setResidentialJobModal} setShow={setShow}/>}


<ResidentialDetails
        residentialDetailsModal={residentialDetailsModal}
        setResidentialDetailsModal={setResidentialDetailsModal}
        jobId={jobId}
        selectedId={selectedId}
        createdFrom={"home"}

      />

      

    </>
  );
}

export default Home;
