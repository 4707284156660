import React, { useEffect, useState } from "react";
import SortableList from "./SortableList";
import { useDispatch } from "react-redux";
import {toast} from 'react-toastify'
import { getJobMenuAction, getSideMenuAction, updateJobMenuAction, updateSideMenuAction } from "../../redux/action/adminMenuSetupAction";

const AdminMenuSetup = () => {
  const dispatch = useDispatch();

  const [editSideMenu, setEditSideMenu] = useState(false);

  const [editJobMenu, setEditJobMenu] = useState(false);

  const [menuIndexList, setmenuIndexList] = useState([]);
  const [sideMenuIndexList, setSideMenuIndexList] = useState([]);

  const [menuList, setmenuList] = useState([]);

  const [sidMenuList, setSidMenuList] = useState([]);

  useEffect(() => {
    let arrayOfObjects = menuList.map((string, index) => {
      return { ...string, order: index + 1 };
    });

    setmenuIndexList(arrayOfObjects);
  }, [menuList]);

  useEffect(() => {
    let arrayOfObjects = sidMenuList.map((string, index) => {
      return { ...string, order: index + 1 };
    });

    setSideMenuIndexList(arrayOfObjects);
  }, [sidMenuList]);

  useEffect(() => {
    dispatch(getSideMenuAction()).then((res) => {
      setmenuList(res.sideMenu);
    });


    dispatch(getJobMenuAction()).then((res) => {
      setSidMenuList(res.jobMenu);
    });

  }, []);

  const handleUpdateMenuSetupStatus = (e) => {
    const { name, checked } = e.target;
    console.log(name, checked, "STATUS");

    let temp = menuList.map((item) => {
      if (item.modules == name) {
        let obj = item;
        obj["active"] = checked;
        return obj;
      } else {
        return item;
      }
    });

    setmenuList(temp);
  };



  const handleUpdateJobSetupStatus = (e) => {
    const { name, checked } = e.target;
    console.log(name, checked, "STATUS");

    let temp = sidMenuList.map((item) => {
      if (item.modules == name) {
        let obj = item;
        obj["active"] = checked;
        return obj;
      } else {
        return item;
      }
    });

    setSidMenuList(temp);
  };

  const handleUpdateSideMenu = (e) => {
    e.preventDefault();
  

      dispatch(
        updateSideMenuAction(menuList)
      )
        .then((res) => {
          if (res.status === 202) {
            toast.success("Sidemenu Updated Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });

            setEditSideMenu(false);

        
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    
  };


  const handleUpdateJobMenu = (e) => {
    e.preventDefault();
  

      dispatch(
        updateJobMenuAction(sidMenuList)
      )
        .then((res) => {
          if (res.status === 202) {
            toast.success("Job Menu Updated Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });

            setEditJobMenu(false);

        
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    
  };

  return (
    <>
      <div className="tab-pane fade" id="MenuSetup">
        <div className="ResidentialJobArea ViewConfigArea">
          <div className="ModalAccordian">
            <div id="accordion">
              <div className="card">
                <div className="card-header">
                  <h4 data-toggle="collapse" href="#SideMenuTabs">
                    <span>
                      <img src="images/side_menu.png" />
                    </span>{" "}
                    Side Menu
                  </h4>
                  <a
                    className="Edit EditViewConfigBodyBtn"
                    onClick={() => setEditSideMenu(editSideMenu ? false : true)}
                  >
                    <img src="images/Action-1.png" />
                  </a>
                </div>
                <div
                  id="SideMenuTabs"
                  className="collapse show"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <div
                      className="ViewConfigBodyMain"
                      style={{ display: editSideMenu ? "none" : "block" }}
                    >
                      <div className="ViewConfigBody">
                        <aside>
                          <div className="ModalBigheading">
                            <h3>
                              <span>Apps</span>
                            </h3>
                          </div>
                          {menuList?.map((item)=> 
                             <p>
                             <label>{item?.modules}</label>
                             <span>{item?.active ?"On":"Off"}</span>
                           </p>
                          )}
                       
                        </aside>
                      </div>
                    </div>
                    <div
                      className="ViewConfigBodyHideMain"
                      style={{ display: editSideMenu ? "block" : "none" }}
                    >
                      <div className="ViewConfigBodyHide">
                        <aside>
                          {menuList?.map((item) => (
                            <>
                              <label className="switch">
                                {" "}
                                {item?.modules}
                                <input
                                  name={item?.modules}
                                  checked={item?.active}
                                  onChange={handleUpdateMenuSetupStatus}
                                  type="checkbox"
                                />
                                <span className="slider" />
                              </label>
                            </>
                          ))}
                        </aside>
                        <aside
                          style={{
                            width: "calc(40% - 10px)",
                            marginLeft: "auto",
                          }}
                        >
                          <div className="ModalBigheading">
                            <h3>
                              <span>Display Order</span>
                            </h3>
                          </div>
                          <div className="DisplayOrder">
                            <SortableList
                              menuList={menuList}
                              setmenuList={setmenuList}
                            />
                          </div>
                        </aside>
                      </div>
                      <div className="Button d-flex justify-content-center mt-4">
                        <button onClick={()=>setEditSideMenu(false)} className="OutlineBlack mr-5 SaveViewConfigBodyBtn">
                          Cancel
                        </button>
                        <button onClick={handleUpdateSideMenu}  className="Respond ml-5 SaveViewConfigBodyBtn">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h4 data-toggle="collapse" href="#JobMenuTabs">
                    <span>
                      <img src="images/job_menu.png" />
                    </span>{" "}
                    Job Menu
                  </h4>
                  <a
                    className="Edit EditViewConfigBodyBtn"
                    onClick={() => setEditJobMenu(editJobMenu ? false : true)}
                  >
                    <img src="images/Action-1.png" />
                  </a>
                </div>
                <div
                  id="JobMenuTabs"
                  className="collapse show"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <div
                      className="ViewConfigBodyMain"
                      style={{ display: editJobMenu ? "none" : "block" }}
                    >
                      <div className="ViewConfigBody">
                        <aside>
                          <div className="ModalBigheading">
                            <h3>
                              <span>Apps/Functions</span>
                            </h3>
                          </div>

                          {sidMenuList?.map((item)=>

<p>
<label>{item?.modules}</label>
<span>{item?.active ?"On":"Off"}</span>
</p>
                          )}
                      
                        </aside>
                      </div>
                    </div>
                    <div
                      className="ViewConfigBodyHideMain"
                      style={{ display: editJobMenu ? "block" : "none" }}
                    >
                      <div className="ViewConfigBodyHide">
                        <aside>
                        {sidMenuList?.map((item) => (
                            <>
                              <label className="switch">
                                {" "}
                                {item?.modules}
                                <input
                                  name={item?.modules}
                                  checked={item?.active}
                                  onChange={handleUpdateJobSetupStatus}
                                  type="checkbox"
                                />
                                <span className="slider" />
                              </label>
                            </>
                          ))}                        </aside>
                        <aside
                          style={{
                            width: "calc(40% - 10px)",
                            marginLeft: "auto",
                          }}
                        >
                          <div className="ModalBigheading">
                            <h3>
                              <span>Display Order</span>
                            </h3>
                          </div>
                          <div className="DisplayOrder">
                            <SortableList
                              menuList={sidMenuList}
                              setmenuList={setSidMenuList}
                            />
                          </div>
                        </aside>
                      </div>
                      <div className="Button my-5 d-flex justify-content-center">
                        <button onClick={()=> setEditJobMenu(false)} className="OutlineBlack mr-5 SaveViewConfigBodyBtn">
                          Cancel
                        </button>
                        <button onClick={handleUpdateJobMenu} className="Respond ml-5 SaveViewConfigBodyBtn">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminMenuSetup;
