import { adminPlanConstant } from "../actionTypes";
const initialState={
    featureList:[],
    planList:[],
    loader:false,
}


export default function adminPlanReducer(state = initialState, {type, payload}) {
    
    switch(type) {
      case adminPlanConstant.GET_FEATURE_LIST_INITIATE: {
        return {
          ...state, loader:true, featureList:[]
        }
      }
      case adminPlanConstant.GET_FEATURE_LIST_SUCCESS: {
        return {
          ...state, loader:false, featureList:payload.data
        }
      }
      case adminPlanConstant.GET_FEATURE_LIST_FAILURE: {
        return {
          ...state, loader:false,featureList:[]
        }
      }


      case adminPlanConstant.PLAN_LIST_INITIATE: {
        return {
          ...state, loader:true, planList:[]
        }
      }
      case adminPlanConstant.PLAN_LIST_SUCCESS: {
        return {
          ...state, loader:false, planList:payload.plans
        }
      }
      case adminPlanConstant.PLAN_LIST_FAILURE: {
        return {
          ...state, loader:false,planList:[]
        }
      }


  
      
      default: 
      return state
  }
}