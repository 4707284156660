import { taskAction } from "../actionTypes";
const initialState = {
  loader: false,
  jobList:[],
  createdTaskList: [],
  inProcessTaskList:[],
  completeTaskList:[],
  memberList:[],
  historyTaskList:[],
  showCancelList:[],
  viewTaskDetail:[],
  incomingAccessList:[],
  manageAccessToMyList:[],
  manageAccessUserTaskList:[],
  showHiddenList:[],
  showMoveList:[],
};

export default function taskReducer(state = initialState, { type, payload }) {
  switch (type) {

    case taskAction.TASK_JOB_LIST_INITIATE: {
      return {
        ...state,jobList: [],
      };
    }
    case taskAction.TASK_JOB_LIST_SUCCESS: {
      return {
        ...state, jobList: payload
      };
    }
    case taskAction.TASK_JOB_LIST_FAILURE: {
      return {
        ...state,jobList: payload,
      };
    }

    case taskAction.CREATED_TASK_LIST_INITIATE: {
      return {
        ...state,createdTaskList: [],
      };
    }
    case taskAction.CREATED_TASK_LIST_SUCCESS: {
      return {
        ...state, createdTaskList: payload
      };
    }
    case taskAction.CREATED_TASK_LIST_FAILURE: {
      return {
        ...state,createdTaskList: payload,
      };
    }

    case taskAction.INPROGRESS_TASK_LIST_INITIATE: {
      return {
        ...state,
        inProcessTaskList: [],
        loader: true,
      };
    }
    case taskAction.INPROGRESS_TASK_LIST_SUCCESS: {
      return {
        ...state,
        inProcessTaskList: payload,
        loader: false,
      };
    }
    case taskAction.INPROGRESS_TASK_LIST_FAILURE: {
      return {
        ...state,
        inProcessTaskList: payload,
        loader: false,
      };
    }  
    case taskAction.COMPLETED_TASK_LIST_INITIATE: {
      return {
        ...state,
        completeTaskList: [],
        loader: true,
      };
    }
    case taskAction.COMPLETED_TASK_LIST_SUCCESS: {
      return {
        ...state,
        completeTaskList: payload,
        loader: false,
      };
    }
    case taskAction.COMPLETED_TASK_LIST_FAILURE: {
      return {
        ...state,
        completeTaskList: payload.data,
        loader: false,
      };
    }

    case taskAction.TASK_MEMBER_LIST_INITIATE: {
      return {
        ...state,
        memberList: [],
        loader: true,
      };
    }
    case taskAction.TASK_MEMBER_LIST_SUCCESS: {
      return {
        ...state,
        memberList: payload,
        loader: false,
      };
    }
    case taskAction.TASK_MEMBER_LIST_FAILURE: {
      return {
        ...state,
        memberList: payload,
        loader: false,
      };
    }

    case taskAction.TASK_HISTORY_LIST_INITIATE: {
      return {
        ...state,historyTaskList: [],
      };
    }
    case taskAction.TASK_HISTORY_LIST_SUCCESS: {
      return {
        ...state, historyTaskList: payload
      };
    }
    case taskAction.TASK_HISTORY_LIST_FAILURE: {
      return {
        ...state,historyTaskList: payload,
      };
    }

    case taskAction.SHOW_CANCEL_LIST_INITIATE: {
      return {
        ...state,showCancelList: [],
      };
    }
    case taskAction.SHOW_CANCEL_LIST_SUCCESS: {
      return {
        ...state, showCancelList: payload
      };
    }
    case taskAction.SHOW_CANCEL_LIST_FAILURE: {
      return {
        ...state,showCancelList: payload,
      };
    }

    case taskAction.VIEW_TASK_INITIATE: {
      return {
        ...state,viewTaskDetail: [],
      };
    }
    case taskAction.VIEW_TASK_SUCCESS: {
      return {
        ...state, viewTaskDetail: payload
      };
    }
    case taskAction.VIEW_TASK_FAILURE: {
      return {
        ...state,viewTaskDetail: payload,
      };
    }

    case taskAction.INCOMING_ACCESS_TASK_REQUEST_LIST_INITIATE: {
      return {
        ...state,incomingAccessList: [],
      };
    }
    case taskAction.INCOMING_ACCESS_TASK_REQUEST_LIST_SUCCESS: {
      return {
        ...state, incomingAccessList: payload
      };
    }
    case taskAction.INCOMING_ACCESS_TASK_REQUEST_LIST_FAILURE: {
      return {
        ...state,incomingAccessList: payload,
      };
    }

    case taskAction.MANAGE_ACCESS_TO_MY_TASK_LIST_INITIATE: {
      return {
        ...state,manageAccessToMyList: [],
      };
    }
    case taskAction.MANAGE_ACCESS_TO_MY_TASK_LIST_SUCCESS: {
      return {
        ...state, manageAccessToMyList: payload
      };
    }
    case taskAction.MANAGE_ACCESS_TO_MY_TASK_LIST_FAILURE: {
      return {
        ...state,manageAccessToMyList: payload,
      };
    }

    case taskAction.MANAGE_ACCESS_USER_TASK_LIST_INITIATE: {
      return {
        ...state,manageAccessUserTaskList: [],
      };
    }
    case taskAction.MANAGE_ACCESS_USER_TASK_LIST_SUCCESS: {
      return {
        ...state, manageAccessUserTaskList: payload
      };
    }
    case taskAction.MANAGE_ACCESS_USER_TASK_LIST_FAILURE: {
      return {
        ...state,manageAccessUserTaskList: payload,
      };
    }


    
    case taskAction.SHOW_HIDDEN_LIST_INITIATE: {
      return {
        ...state,showHiddenList: [],
      };
    }
    case taskAction.SHOW_HIDDEN_LIST_SUCCESS: {
      return {
        ...state, showHiddenList: payload
      };
    }
    case taskAction.SHOW_HIDDEN_LIST_FAILURE: {
      return {
        ...state,showHiddenList: payload,
      };
    }



    case taskAction.SHOW_MOVE_LIST_INITIATE: {
      return {
        ...state,showMoveList: [],
      };
    }
    case taskAction.SHOW_MOVE_LIST_SUCCESS: {
      return {
        ...state, showMoveList: payload
      };
    }
    case taskAction.SHOW_MOVE_LIST_FAILURE: {
      return {
        ...state,showMoveList: payload,
      };
    }
    

    default:
      return state;
  }
}
