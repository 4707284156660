import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import moment from 'moment';
import { useDispatch } from "react-redux";
import { AddDiscussionAction, AddReplyAction, discussionListAction } from "../../redux/action/constructionsAction";
import { useSelector } from "react-redux";
import { sendTextAction } from "../../redux/action/adminUserAction";

const ContactDetails = ({ ContactDetailsModal, setContactDetailsModal ,contactDetailsData,showNewEmailModal,showNewTextModal}) => {
  const handleContactDetailsModalShow = () => {
    setContactDetailsModal(true);
  };

  const {  userList } = useSelector((state) => state.adminUserReducers
  );
  const User = JSON.parse(window.localStorage.getItem('user'))
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));


  const handleContactDetailsModalClose=()=> setContactDetailsModal(false)

  const [showTextBox, setShowTextBox] = useState(false)
  const [showDiscussion, setShowDiscussion] = useState(false)


const dispatch = useDispatch()
  const [messageBody, setMessageBody] = useState('');
  const {  discussionList} =useSelector((state) => state.constructionReducer);


  
const handleAddDiscussion = () => {
  let formIsValid = handleValidationDiscussion();
  if (formIsValid) {
    dispatch(AddDiscussionAction({ message: discussionState.message, helpId: contactDetailsData?.id, type:"Help" }))
      .then((res) => {
        if (res.status == 201) {
          dispatch(discussionListAction({ helpId: contactDetailsData?.id, type:"Help" }));

          updateDiscussionState({ message: "", discussionError: "" });
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }
};

const handleAddReply = () => {
  let formIsValid = true;
  if (formIsValid) {
    dispatch(
      AddReplyAction({
        message: replyState.message,
        helpId: contactDetailsData?.id, type:"Help",
        messageId: replyState.messageId,
      })
    )
      .then((res) => {
        if (res.status == 201) {
          dispatch(discussionListAction({ helpId: contactDetailsData?.id, type:"Help" }));
          updateReplyState({
            messageId: "",
            jobId: "",
            message: "",
            replyError: "",
          });

          updateDiscussionState({ message: "", discussionError: "" });
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }
};


const handleAddText = (e) => {
  e.preventDefault()
  let formIsValid = true;
  if (formIsValid) {
    dispatch(
      sendTextAction(contactDetailsData?.id,{
        messageBody}
      )
    )
      .then((res) => {
        if (res.status == 201) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }
};


const [discussionState, updateDiscussionState] = useState({
  jobId: "",
  message: "",
  discussionError: "",
});
const [replyState, updateReplyState] = useState({
  messageId: "",
  jobId: "",
  message: "",
  replyError: "",
});
const [replyField, updateReplyField] = useState({ flag: false, index: "" });

let handleValidationDiscussion = () => {
  let discussionError = "";
  let formIsValid = true;

  if (discussionState.message == "") {
    discussionError = "* Please enter comment";
    formIsValid = false;
  }

  updateDiscussionState({
    ...discussionState,
    discussionError: discussionError,
  });
  return formIsValid;
};




  return (
    <Modal show={ContactDetailsModal} className="ModalBox  ExtraSmallModal ">
      <a
          onClick={handleContactDetailsModalClose}
          class="CloseModal"
          style={{top: "-11px",
            right: "-6px" }}
        >
          ×
        </a>
        <div className="FormArea">
        <div class="ContactTopBox">
                <div class="SalesPersonarea">
                <span>{((iBuildLocalData?.user?.name ===(contactDetailsData?.name || contactDetailsData?.legalName) ) && User ) ?                <img src={User?.profile} />
:contactDetailsData?.name ?(contactDetailsData?.name?.charAt(0) + contactDetailsData?.name?.charAt(1)):(contactDetailsData?.legalName?.charAt(0) + contactDetailsData?.legalName?.charAt(1))} </span>
                  <h6>{contactDetailsData?.name}</h6>
                </div>
              </div>
        <div class="FolderForm">
                <form>
                  <div class="SendEmailText">
                    <a onClick={showNewEmailModal}><img src="images/send-email.png" />Send E-mail</a>
                    <p onClick={()=>showNewTextModal("detils")} class="SendText SendText1">
                      <img src="images/send-text.png" />Send Text
                    </p>
                    <div class="SendTextSearch SendTextSearch1" style={{display:showTextBox?"block":"none"}}>
                      <input type="text" onChange={(e)=>setMessageBody(e.target.value)} /><button onClick={handleAddText}>Apply</button>

                    </div>
                  </div>
                  <div class="MolalContactForm mb-4">
                    <h6>Contact</h6>
                    <p><img src="images/send-email.png" />{contactDetailsData?.email}</p>
                    <p class="mb-4"><img src="images/send-text.png" />{contactDetailsData?.phoneNumber}</p>
                  <a onClick={()=> setShowDiscussion(true)} class="ShowMoreBtn" style={{display:showDiscussion?"none":""}}>Show more</a>
                    <a onClick={()=> setShowDiscussion(false)} class="ShowLessBtn" style={{display:showDiscussion?"":"none"}}>Show less</a>
                  </div>
                  <div className="form-group mt-3">
                  <div className="ContactModalAddressArea">
                    <h6>Address</h6>
                    <p>
                      {contactDetailsData?.addressLineTwo ?? "N/A"} <br />
                      {contactDetailsData?.streetAddress ?? "N/A"}
                      <img src="images/location.png" />
                    </p>
                  </div>
                  <div className="ContactModalAddressArea">
                    <h6>Absence</h6>
                    <p>
                      {moment(contactDetailsData?.absenceFrom )?.format('ll')?? ""} <br />
                      {moment(contactDetailsData?.absenceTo )?.format('ll')?? ""}
                    </p>
                  </div>

                  <div className="ContactModalAddressArea">
                    <h6>Back-Up User</h6>
                    <p>
                      {userList?.users?.filter((ele)=>ele.id ==contactDetailsData?.backUpUserId)?.[0]?.name ?? ""} 
                    </p>
                  </div>
                  
                </div>
                  <div class="DiscussionArea">
            <div >
              <h6>Discussion</h6>
      
            </div>
            <div class="DiscussionHide" style={{ display: showDiscussion?"block":"none"}}>
              <div class="DiscussionBox">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    value={discussionState?.message}
                    onChange={(e) =>
                      updateDiscussionState({
                        ...discussionState,
                        message: e.target.value,
                      })
                    }
                    placeholder="Add a Comment ....."
                  />
                  {discussionState.discussionError &&
                    !discussionState.message && (
                      <p style={{ color: "red" }}>
                        {discussionState.discussionError}
                      </p>
                    )}

                  <div class="DiscussionOverlay">
                    <a
                      onClick={() =>
                        updateDiscussionState({
                          ...discussionState,
                          message: "",
                        })
                      }
                      class="CancelChat"
                    >
                      <img src="images/plus-cancel1.png" />
                    </a>
                    <a onClick={handleAddDiscussion} class="SendChat">
                      <img
                        src="images/forwardbutton.png"
                      />
                    </a>
                  </div>
                </div>
              </div>
              {discussionList?.map((item, i) => (
                <>
                  <div class="DiscussionBox">
                  <span>{((iBuildLocalData?.user?.name ===(contactDetailsData?.name || contactDetailsData?.legalName) ) && User ) ?                <img src={User?.profile} />
:contactDetailsData?.name ?(contactDetailsData?.name?.charAt(0) + contactDetailsData?.name?.charAt(1)):(contactDetailsData?.legalName?.charAt(0) + contactDetailsData?.legalName?.charAt(1))} </span>

                    <div class="DiscussionRight">
                      <h6>{item?.message}</h6>
                      <small>{moment(item.updatedAt).format("lll")}</small>
                    </div>
                  </div>
                  {/* <div class="ReplyArea">
                    <a
                      onClick={(e) => {
                        updateReplyField({ flag: true, index: i });
                        updateReplyState({ ...replyState, message: "" });
                      }}
                    >
                      Reply
                    </a>
                    {item?.ReplyDiscussions?.map((ele) => (
                      <div class="DiscussionBox">
                        <span>RE</span>
                        <div class="DiscussionRight">
                          <h6>{ele?.message}</h6>
                          <small>{moment(ele.updatedAt).format("lll")}</small>
                        </div>
                      </div>
                    ))}

                    {replyField.index == i && (
                      <div class="DiscussionBox w-100">
                        <span>FM{item?.id}</span>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            value={replyState?.message}
                            onChange={(e) =>
                              updateReplyState({
                                ...replyState,
                                message: e.target.value,
                                messageId: item?.id,
                                jobId: item?.jobId,
                              })
                            }
                            placeholder="Add reply ....."
                          />
                          {discussionState.discussionError &&
                            !discussionState.message && (
                              <p style={{ color: "red" }}>
                                {discussionState.discussionError}
                              </p>
                            )}

                          <div class="DiscussionOverlay">
                            <a
                              onClick={() =>
                                updateReplyState({ ...replyState, message: "" })
                              }
                              class="CancelChat"
                            >
                              <img
                                src="images/plus-cancel1.png"
                              />
                            </a>
                            <a onClick={handleAddReply} class="SendChat">
                              <img
                                src="images/forwardbutton.png"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </div> */}
                </>
              ))}
            </div>
          </div>
                </form>
              </div>
              </ div>
    </Modal>
  );
};

export default ContactDetails;
