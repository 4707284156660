import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { workOrderSelectionListTable } from '../redux/action/warrantyAction'

const TradeDaySelectionTable = ({id}) => {
    const dispatch=useDispatch()

    useEffect(()=>{
    dispatch(workOrderSelectionListTable(id))
    },[])
  return (
    <div>
        <div className="FormArea">
  <div className="ModalTitleBox">
    <h4>Trades Day Selection Table</h4>
  </div>
  <div className="JobTitleArea mb-2">
    <div className="JobBox">
      <h6>
        <span>Job:</span> 
      </h6>
    </div>
  </div>
  <div className="MainTitleBox">
    <div className="MainTitle">
      <h2>Work Order # {id}</h2>
    </div>
    {/* <div className="TodosButtons">
      <a href="javascript:void(0);">
        <h6 className="mb-0 text-white">New Trades Day</h6>
      </a>
    </div> */}
  </div>
  <div className="TableList CommonHeading TableListMaxHeight">
    <table>
      <thead>
        <tr>
          <th>Trades Day Selection</th>
          <th>Vendor</th>
          <th>Work Order Items</th>
          <th>Client Selection Status</th>
          <th>Vendor Selection Status</th>
          <th>Trades Day</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {/* <tr>
          <td>3</td>
          <td>Vendor 1</td>
          <td>1</td>
          <td>Pending</td>
          <td>Pending</td>
          <td />
          <td>
            <div className="TableThreeDots">
              <ul className="">
                <li className="dropdown">
                  <a
                    className=""
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src="images/dots.png" />
                  </a>
                  <ol className="dropdown-menu dropdown-menuwidth2" style={{}}>
                    <li>
                      <a href="javascript:void(0);">
                        <img src="images/accept_po.png" />
                        Select Dates
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <img src="images/cancel_qr.png" />
                        Cancel Selection Workflow
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        className="FolderPermissionId"
                        data-toggle="modal"
                        data-target="#InspectionDatesSelection13"
                        data-dismiss="modal"
                      >
                        <img src="images/view-po.png" />
                        View Confirmation
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <img src="images/resend_message.png" />
                        Resend Selection Workflow
                      </a>
                    </li>
                  </ol>
                </li>
              </ul>
            </div>
          </td>
        </tr> */}
        
                      <tr>
                        <td colSpan="4">No data found</td>
                      </tr>
                    
       
      </tbody>
    </table>
  </div>
  <div className="Button mt-5 text-center">
    <button className="Outline" data-dismiss="modal">
      Close
    </button>
  </div>
</div>

    </div>
  )
}

export default TradeDaySelectionTable