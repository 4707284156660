import { constructionConstant } from "../actionTypes";
const initialState = {
    phaseList: [],
    jobList:[],
    taskList:[],
    discussionList:[],
    allPhaseList:[],
    ganttData:[],

    replyList:[],
    constructionDashboard:{},
    changeOrderCheckList:[],
    fieldPurchaseOrderList:[],
    loader: false,
    error: {},
  };
  
  export default function constructionReducer(
    state = initialState,
    { type, payload }
    
  ) {
    switch (type) {

      case constructionConstant.GET_ALL_PHASE_INITIATE:
        return { ...state, loader: true, phaseList: [] };
  
      case constructionConstant.GET_ALL_PHASE_SUCCESS:
        return { ...state, loader: false, phaseList: payload.details };
  
      case constructionConstant.GET_ALL_PHASE_FAILURE:
        return { ...state, loader: false, error: payload };

        case constructionConstant.FIND_ALL_TASK_INITIATE:
            return { ...state, loader: true, taskList: [] };
      
          case constructionConstant.FIND_ALL_TASK_SUCCESS:
            return { ...state, loader: false, taskList: payload.details };
      
          case constructionConstant.FIND_ALL_TASK_FAILURE:
            return { ...state, loader: false, error: payload };


            
      case constructionConstant.GET_ALL_JOBS_INITIATE:
        return { ...state, loader: true, jobList: [] };
  
      case constructionConstant.GET_ALL_JOBS_SUCCESS:
        return { ...state, loader: false, jobList: payload.jobs };
  
      case constructionConstant.GET_ALL_JOBS_FAILURE:
        return { ...state, loader: false, error: payload };



        case constructionConstant.DISCUSSION_LIST_INITIATE:
          return { ...state, loader: true, discussionList: [] };
    
        case constructionConstant.DISCUSSION_LIST_SUCCESS:
          return { ...state, loader: false, discussionList: payload.details };
    
        case constructionConstant.DISCUSSION_LIST_FAILURE:
          return { ...state, loader: false, error: payload };



          case constructionConstant.GET_ALL_PHASE_INITIATE:
            return { ...state, loader: true, allPhaseList: [] };
      
          case constructionConstant.GET_ALL_PHASE_SUCCESS:
            return { ...state, loader: false, allPhaseList: payload };
      
          case constructionConstant.GET_ALL_PHASE_FAILURE:
            return { ...state, loader: false, error: payload };
  
  
            case constructionConstant.GANTT_DATA_INITIATE:
              return { ...state, loader: true, ganttData: [] };
        
            case constructionConstant.GANTT_DATA_SUCCESS:
              return { ...state, loader: false, ganttData: payload?.details };
        
            case constructionConstant.GANTT_DATA_FAILURE:
              return { ...state, loader: false, error: payload };


              case constructionConstant.CONSTRUCTION_DASHBOARD_INITIATE:
                return { ...state, loader: true, constructionDashboard: {} };
          
              case constructionConstant.CONSTRUCTION_DASHBOARD_SUCCESS:
                return { ...state, loader: false, constructionDashboard: payload?.details };
          
              case constructionConstant.CONSTRUCTION_DASHBOARD_FAILURE:
                return { ...state, loader: false, error: payload };


                case constructionConstant.CHANGE_ORDER_CHECK_LIST_INITIATE:
                  return { ...state, loader: true, changeOrderCheckList: [] };
            
                case constructionConstant.CHANGE_ORDER_CHECK_LIST_SUCCESS:
                  return { ...state, loader: false, changeOrderCheckList: payload?.data };
            
                case constructionConstant.CHANGE_ORDER_CHECK_LIST_FAILURE:
                  return { ...state, loader: false, error: payload };
  
  

                  case constructionConstant.FIELD_PURCAHSE_ORDER_LIST_INITIATE:
                    return { ...state, loader: true, fieldPurchaseOrderList: [] };
              
                  case constructionConstant.FIELD_PURCAHSE_ORDER_LIST_SUCCESS:
                    return { ...state, loader: false, fieldPurchaseOrderList: payload?.data };
              
                  case constructionConstant.FIELD_PURCAHSE_ORDER_LIST_FAILURE:
                    return { ...state, loader: false, error: payload };
    
      

  
      default:
        return state;
    }
  }
  