import axios from "axios";
import { AdminAccountingConstant } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config/Config";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));





export const getTransactionAccountInitate = () => ({
    type: AdminAccountingConstant.GET_TRANSACTION_ACCOUNT_INITIATE ,
  });
  
  export const getTransactionAccountSuccess = (data) => ({
    type: AdminAccountingConstant.GET_TRANSACTION_ACCOUNT_SUCCESS,
    payload: data,
  });
  
  export const getTransactionAccountFailure = (data) => ({
    type: AdminAccountingConstant.GET_TRANSACTION_ACCOUNT_FAILURE,
    payload: data,
  });
  
  export function getTransactionAccountAction( pid) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(getTransactionAccountInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}companies/${iBuildLocalData?.user?.id}/transaction-settings`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            ////console.log("data", data);
            if (data && data.status == 200) {
              dispatch(getTransactionAccountSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(getTransactionAccountFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(getTransactionAccountSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(getTransactionAccountFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }




  export const updateAccountSettingInitiate = () => ({
    type: AdminAccountingConstant.UPDATE_ACCOUNT_SETTING_INITIATE,
  });
  
  export const updateAccountSettingSuccess = (data) => ({
    type: AdminAccountingConstant.UPDATE_ACCOUNT_SETTING_SUCCESS,
    payload: data,
  });
  
  export const  updateAccountSettingFailure = (data) => ({
    type: AdminAccountingConstant.UPDATE_ACCOUNT_SETTING_FAILURE,
    payload: data,
  });
  
export const updateAccountSettingAction = (id,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(updateAccountSettingSuccess(payload))
      const response = await axios.put(
        `${Url}companies/${iBuildLocalData?.user?.CompanyId}/account-settings/${id}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 201) {
        dispatch(updateAccountSettingSuccess(response.data));
      } else {
        dispatch(updateAccountSettingFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateAccountSettingFailure(err));
      return err.response;
    }
  };
  
  

  export const updateTransactionSettingInitiate = () => ({
    type: AdminAccountingConstant.UPDATE_ACCOUNT_SETTING_INITIATE,
  });
  
  export const updateTransactionSettingSuccess = (data) => ({
    type: AdminAccountingConstant.UPDATE_ACCOUNT_SETTING_SUCCESS,
    payload: data,
  });
  
  export const  updateTransactionSettingFailure = (data) => ({
    type: AdminAccountingConstant.UPDATE_ACCOUNT_SETTING_FAILURE,
    payload: data,
  });
  
  export const updateTransactionSettingAction = (id,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(updateTransactionSettingSuccess(payload))
      const response = await axios.put(
        `${Url}companies/${iBuildLocalData?.user?.id}/transaction-settings/${id}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 201) {
        dispatch(updateTransactionSettingSuccess(response.data));
      } else {
        dispatch(updateTransactionSettingFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateTransactionSettingFailure(err));
      return err.response;
    }
  };
  
  








  export const getAccountSystemPostingInitate = () => ({
    type: AdminAccountingConstant.GET_ACCOUNT_SYSTEM_POSTING_INITIATE ,
  });
  
export const getAccountSystemPostingSuccess = (data) => ({
    type: AdminAccountingConstant.GET_ACCOUNT_SYSTEM_POSTING_SUCCESS,
    payload: data,
  });
  
  export const getAccountSystemPostingFailure = (data) => ({
    type: AdminAccountingConstant.GET_ACCOUNT_SYSTEM_POSTING_FAILURE,
    payload: data,
  });
  
  export function getAccountSystemPostingAction( pid) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(getAccountSystemPostingInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/account-postings`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            ////console.log("data", data);
            if (data && data.status == 200) {
              dispatch(getAccountSystemPostingSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(getAccountSystemPostingFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(getAccountSystemPostingSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(getAccountSystemPostingFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }






  export const updateAccountSettingPostingInitiate = () => ({
    type: AdminAccountingConstant.UPDATE_ACCOUNT_POSTING_INITIATE,
  });
  
  export const updateAccountSettingPostingSuccess = (data) => ({
    type: AdminAccountingConstant.UPDATE_ACCOUNT_POSTING_SUCCESS,
    payload: data,
  });
  
  export const  updateAccountSettingPostingFailure = (data) => ({
    type: AdminAccountingConstant.UPDATE_ACCOUNT_POSTING_FAILURE,
    payload: data,
  });
  
  export const updateAccountSettingPostingAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(updateAccountSettingPostingSuccess(payload))
      const response = await axios.put(
        `${Url}companies/${iBuildLocalData?.user?.CompanyId}/accounting-account-postings`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 201) {
        dispatch(updateAccountSettingPostingSuccess(response.data));
      } else {
        dispatch(updateAccountSettingPostingFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateAccountSettingPostingFailure(err));
      return err.response;
    }
  };




  export const updateAccountSalesPostingInitiate = () => ({
    type: AdminAccountingConstant.UPDATE_SALES_POSTING_INITIATE,
  });
  
  export const updateAccountSalesPostingSuccess = (data) => ({
    type: AdminAccountingConstant.UPDATE_SALES_POSTING_SUCCESS,
    payload: data,
  });
  
  export const  updateAccountSalesPostingFailure = (data) => ({
    type: AdminAccountingConstant.UPDATE_SALES_POSTING_FAILURE,
    payload: data,
  });
  
  export const updateAccountSalesPostingAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(updateAccountSalesPostingSuccess(payload))
      const response = await axios.put(
        `${Url}companies/${iBuildLocalData?.user?.id}/sales-account-postings`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );


      if (response.status === 201) {
        dispatch(updateAccountSalesPostingSuccess(response.data));
        console.log(response,'RRRRRRRRRRRRRRRR')

      } else {
        dispatch(updateAccountSalesPostingFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateAccountSalesPostingFailure(err));
      return err.response;
    }
  };
  
  

  
  


  export const updateSystemPostingInitiate = () => ({
    type: AdminAccountingConstant.UPDATE_ACCOUNT_POSTING_INITIATE,
  });
  
  export const updateSystemPostingSuccess = (data) => ({
    type: AdminAccountingConstant.UPDATE_ACCOUNT_POSTING_SUCCESS,
    payload: data,
  });
  
  export const  updateSystemPostingFailure = (data) => ({
    type: AdminAccountingConstant.UPDATE_ACCOUNT_POSTING_FAILURE,
    payload: data,
  });
  
  export const updateSystemPostingAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(updateAccountSettingPostingSuccess(payload))
      const response = await axios.put(
        `${Url}companies/${iBuildLocalData?.user?.CompanyId}/system-account-postings`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 201) {
        dispatch(updateSystemPostingSuccess(response.data));
      } else {
        dispatch(updateSystemPostingFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateSystemPostingFailure(err));
      return err.response;
    }
  };


