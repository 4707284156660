import {applyMiddleware, createStore, compose} from 'redux';

import ReduxThunk from 'redux-thunk';
import rootReducer from './rootReducer'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;;

const createStoreMiddleware = composeEnhancers(applyMiddleware(ReduxThunk))(createStore)


const store= createStoreMiddleware(rootReducer);


export default store;