import axios from "axios";
import { AdminSigningAuthorityConstant } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config/Config";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

export const getAuthorityInitate = () => ({
  type: AdminSigningAuthorityConstant.GET_AUTHORITY_INITIATE,
});

export const getAuthoritySuccess = (data) => ({
  type: AdminSigningAuthorityConstant.GET_AUTHORITY_SUCCESS,
  payload: data,
});

export const getAuthorityFailure = (data) => ({
  type: AdminSigningAuthorityConstant.GET_AUTHORITY_FAILURE,
  payload: data,
});



export const getAuthorityAction = () => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(getAuthorityInitate())
    const response = await axios.get(
      `${Url}sign/getSignAuthority?userId=${iBuildLocalData?.user?.id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getAuthoritySuccess(response.data));
    } else {
      dispatch(getAuthorityFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getAuthorityFailure(err));
    return err.response;
  }
};



export const createAuthorityInitiate = () => ({
    type: AdminSigningAuthorityConstant.CREATE_AUTHORITY_INITIATE,
  });
  
  export const createAuthoritySuccess = (data) => ({
    type: AdminSigningAuthorityConstant.CREATE_AUTHORITY_SUCCESS,
    payload: data,
  });
  
  export const  createAuthorityFailure = (data) => ({
    type: AdminSigningAuthorityConstant.CREATE_AUTHORITY_FAILURE,
    payload: data,
  });
  
  export const createAuthorityAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createAuthoritySuccess(payload))
      const response = await axios.post(
        `${Url}sign/createSignAuthority`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        dispatch(createAuthoritySuccess(response.data));
      } else {
        dispatch(createAuthorityFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createAuthorityFailure(err));
      return err.response;
    }
  };
  


  export const deleteAuthorityInitiate = () => ({
    type: AdminSigningAuthorityConstant.DELETE_AUTHORITY_INITIATE,
  });
  
  export const deleteAuthoritySuccess = (data) => ({
    type: AdminSigningAuthorityConstant.DELETE_AUTHORITY_SUCCESS,
    payload: data,
  });
  
  export const  deleteAuthorityFailure = (data) => ({
    type: AdminSigningAuthorityConstant.DELETE_AUTHORITY_FAILURE,
    payload: data,
  });
  
  export function deleteAuthorityAction(id) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(deleteAuthorityInitiate());
      return new Promise((resolve, reject) => {
        axios.get(`${Url}sign/deleteSignAuthority?id=${id}`, {
            headers: { Authorization: `Bearer ${token}` }
          })
          .then((response) => {
            const data = response;
            ////console.log("data", data);
            if (data && data.status == 200) {
              dispatch(deleteAuthoritySuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(deleteAuthorityFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if (err.response.status === 404) {
              dispatch(deleteAuthoritySuccess(err.data));
              resolve(err.data);
            } else {
              dispatch(deleteAuthorityFailure(err));
              reject(err);
            }
          });
      });
    };
  }  
  