import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {toast} from 'react-toastify'
import { getNewProjectAction } from "../../redux/action/projectActionDk";
import { getCitiesList, getStateList } from "../../redux/action/authAction";

const NewProjectStep2 = ({
  iState,
  UpdateState,
  NewProjectStep2Modal,
  SetNewProjectStep2Modal,
  SetNewProjectStep1Modal,
  SetNewProjectStep3Modal,
  SetNewProjectStep4Modal,
  SetNewProjectStep5Modal,
  SetNewProjectStep6Modal,
  setShow,
}) => {

  const dispatch = useDispatch()
  const handleNewProjectStep2ModalClose = () => {
    SetNewProjectStep2Modal(false);
    setShow(true);
  };
  const handleNewProjectStep2ModalShow = () => {
    SetNewProjectStep2Modal(true);
  };


  const { countryData, cityData, stateData, timeZoneData } = useSelector(
    (state) => state.authReducer
  );
  const { projectAddress, country, city, province, zipCode,integrateAccountingSoftware, errorMsg } = iState;

  const handleChange = (e) => {
    const { name, value } = e.target;

    UpdateState({
      ...iState,
      [name]: value,
    });
  };

  const handleValidation = () => {
    let formIsValid = true;
    let errorMsg = {};

    if (!projectAddress) {
      formIsValid = false;
      errorMsg.projectAddress = "* Please enter project address.";
    }

    if (!city) {
      formIsValid = false;
      errorMsg.city = "* Please enter city.";
    }

    if (!projectAddress) {
      formIsValid = false;
      errorMsg.projectAddress = "* Please enter project address.";
    }

    if (!zipCode) {
      formIsValid = false;
      errorMsg.zipCode = "* Please enter zip code.";
    }

    if (!country) {
      formIsValid = false;
      errorMsg.country = "* Please select country.";
    }

    if (!province) {
      formIsValid = false;
      errorMsg.province = "* Please select province.";
    }

    UpdateState({ ...iState, errorMsg: errorMsg });

    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();

    if (formIsValid) {
      SetNewProjectStep2Modal(false);
      SetNewProjectStep3Modal(true);
    }
  };





  useEffect(()=>{
    dispatch(getNewProjectAction())
    .then((res) => {})
    .catch((error) => {
      // toast.error(error.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    });


  },[])


  useEffect(() => {
    if(country)
    dispatch(
      getStateList(
        countryData?.countries?.find((x) => x.name == country)?.isoCode
      )
    );
  }, [country,countryData?.countries,dispatch]);
  useEffect(() => {
    dispatch(
      getCitiesList(
        countryData?.countries?.find((x) => x.name == country)?.isoCode,
        stateData?.states?.find((x) => x.name == province)?.isoCode
      )
    );
  }, [province]);


  return (
    <Modal show={NewProjectStep2Modal} className="ModalBox LargeModal">
      <div class="modal-content BgCreate">
        <a
          class="CloseModal"
          onClick={handleNewProjectStep2ModalClose}
          data-dismiss="modal"
        >
          ×
        </a>
        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Create New Project</h4>
          </div>
          <div class="StepBox">
            <ul>
              <li class="active">
                <span class="Icon">
                  <img
                    src={require("../../assets/images/step_1.png")}
                    onClick={() => {
                      SetNewProjectStep1Modal(true);
                      SetNewProjectStep2Modal(false);
                    }}
                  />
                </span>
                <span class="Text">Details</span>
              </li>
              <li class="active">
                <span class="Icon FolderPermissionId ">
                  <img src={require("../../assets/images/step_2.png")} />
                </span>
                <span class="Text">Address</span>
              </li>
              <li>
                <span
                  class="Icon FolderPermissionId "
                  data-toggle="modal"
                  onClick={() => {
                    SetNewProjectStep3Modal(true);
                    SetNewProjectStep2Modal(false);
                  }}
                >
                  <img src={require("../../assets/images/step_8.png")} />
                </span>
                <span class="Text">
                  Type&amp; <br /> Accounting{" "}
                </span>
              </li>
              <li>
                <span
                  class="Icon FolderPermissionId "
                  data-toggle="modal"
                  onClick={() => {
                    SetNewProjectStep4Modal(true);
                    SetNewProjectStep2Modal(false);
                  }}
                >
                  <img src={require("../../assets/images/step_5.png")} />
                </span>
                <span class="Text">
                  Job <br /> Numbering
                </span>
              </li>
              <li>
                <span
                  class="Icon FolderPermissionId "
                  data-toggle="modal"
                  onClick={() => {
                    SetNewProjectStep5Modal(true);
                    SetNewProjectStep2Modal(false);
                  }}
                >
                  <img src={require("../../assets/images/step_6.png")} />
                </span>
                <span class="Text">
                  Cost <br /> Profiles
                </span>
              </li>
              <li>
                <span
                  class="Icon FolderPermissionId "
                  data-toggle="modal"
                  onClick={() => {
                    SetNewProjectStep6Modal(true);
                    SetNewProjectStep2Modal(false);
                  }}
                >
                  <img src={require("../../assets/images/step_7.png")} />
                </span>
                <span class="Text">Confirmation</span>
              </li>
            </ul>
          </div>
          <div class="FolderForm">
            <form>
              <div class="FolderFormCreateArea">
                <div class="FieldsMandatory">
                  <h6>
                    Fields with <span class="required">*</span> are mandatory
                  </h6>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <h6>
                        Project Address<span class="required">*</span>
                      </h6>
                      <input
                        type="text"
                        onChange={handleChange}
                        value={projectAddress}
                        name="projectAddress"
                        class="form-control"
                        placeholder="Insert full address"
                      />

                      {errorMsg.projectAddress && !projectAddress && (
                        <p style={{ color: "red" }}>
                          {errorMsg.projectAddress}
                        </p>
                      )}
                    </div>
                 
                    <div class="form-group">
                      <h6>
                        Zip/ Postal Codes<span class="required">*</span>
                      </h6>
                      <input
                        type="text"
                        value={zipCode}
                        onChange={handleChange}
                        name="zipCode"
                        class="form-control"
                        placeholder="Insert zip code"
                      />

                      {errorMsg.zipCode && !zipCode && (
                        <p style={{ color: "red" }}>{errorMsg.zipCode}</p>
                      )}
                    </div>
                    <div class="form-group">
                      <h6>
                        Country <span class="required">*</span>
                      </h6>
                      <div class="CommonSelectBox">
                      <select
                                name="country"
                                value={country}
                                onChange={handleChange}
                              >
                                <option>Select Country</option>
                                {countryData?.countries ? (
                                  <>
                                    {countryData?.countries?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>

                      {errorMsg.country && !country && (
                        <p style={{ color: "red" }}>{errorMsg.country}</p>
                      )}
                    </div>
                    <div class="form-group">
                      <h6>
                        State/Province <span class="required">*</span>
                      </h6>
                      <div class="CommonSelectBox">
                      <select
                                name="province"
                                value={province}
                                onChange={handleChange}
                              >
                                <option>Select Province</option>
                                {stateData?.states ? (
                                  <>
                                    {stateData?.states?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>

                      {errorMsg.province && !province && (
                        <p style={{ color: "red" }}>{errorMsg.province}</p>
                      )}
                    </div>
                    <div class="form-group">
                      <h6>
                        City<span class="required">*</span>
                      </h6>
                      <div class="CommonSelectBox">

                      <select
                                name="city"
                                value={city}
                                onChange={handleChange}
                              >
                                <option>Select City</option>
                                {cityData?.cities ? (
                                  <>
                                    {cityData?.cities?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                              <span>
                          <img src="images/down.png" />
                        </span>
                              </div>

                      {errorMsg.city && !city && (
                        <p style={{ color: "red" }}>{errorMsg.city}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div class="Button d-flex justify-content-between align-items-center px-5">
                <button
                  class="Outline"
                  onClick={() => {
                    SetNewProjectStep2Modal(false);
                    SetNewProjectStep1Modal(true);
                  }}
                >
                  Back
                </button>
                <button
                  class="Create FolderPermissionId"
                  onClick={handleSubmit}
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NewProjectStep2;
