import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUnitStep1 } from "../../../redux/action/proformaAction";

const Unitinput = ({
  handleUnitChange,
  unitStep1List,
  updateId,
  handleShowComponent,
  handleShowComponents,
}) => {
  const dispatch = useDispatch();
  const [unitTypes, setUnitTypes] = useState([]);
  useEffect(() => {
    dispatch(getUnitStep1(updateId)).then((data) => {
      console.log({ data });
      setUnitTypes(data?.data?.data?.table1?.units);
    });
  }, []);
  return (
    <>
      <div className="ModalBigheading">
        <h3>
          <span>Unit Type Inputs</span>
        </h3>
      </div>
      <div className="UnitTypeInputsTable" style={{ width: "60%" }}>
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Unit's Sq/Ft</th>
              <th># of Bedrooms</th>
              <th># of BathRooms</th>
            </tr>
          </thead>
          <tbody>
            {unitTypes?.length > 0 &&
              unitTypes?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.type}</td>
                    <td>
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control width-250"
                          placeholder="Insert Sq/Ft"
                          name="value"
                          min={0}
                          value={item?.UnitOptions?.[0]?.unitValue}
                          onChange={(event) => handleUnitChange(event, index)}
                        />
                      </div>
                    </td>
                    <td>{item.bedRooms}</td>
                    <td>{item.bathRooms}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="ToggleTableArea AllBlocksCommon ">
        <div className="ModalBigheading mt-5">
          <h3>
            <span>Block Unit Inputs</span>
          </h3>
        </div>
        <table>
          <thead>
            <tr>
              <th />
              <th>Block's Units #</th>
              <th>Unit Type</th>
              <th>Attached Garages</th>
              <th>
                Basement <br />
                Development
              </th>
              <th>
                Driveways &amp; <br />
                Sidewalks
              </th>
              <th>
                Patios / Garden <br />
                Suite Concrete Pads
              </th>
              <th>
                Detached Garages / <br />
                Project Common Garages
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={8}>
                <div className="ProformaAccordion">
                  <div className="accordion" id="accordionExample61">
                    {unitStep1List?.data?.table2?.length > 0 &&
                      unitStep1List?.data?.table2?.map((data, index) => {
                        return (
                          <div className="card">
                            <div
                              className="card-header Width20"
                              id="headingOne61"
                            >
                              <h2 className="mb-0">
                                <button
                                  className="btn btn-link btn-block text-left collapsed"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#AttachedGarages61"
                                  aria-expanded="false"
                                  aria-controls="AttachedGarages61"
                                >
                                  {data.blockDescription}
                                </button>
                              </h2>
                            </div>
                            <div
                              id="AttachedGarages61"
                              className="collapse"
                              aria-labelledby="headingOne61"
                              data-parent="#accordionExample61"
                              style={{}}
                            >
                              <div className="card-body py-0">
                                <div className="ToggleTableArea">
                                  <div
                                    className="AdvancedCostCodes"
                                    style={{ overflowX: "scroll" }}
                                  >
                                    <table>
                                      <tbody>
                                        {data?.MultiUnits?.length > 0 &&
                                          data?.MultiUnits?.map((unit, i) => {
                                            return (
                                              <tr>
                                                <td>
                                                  <div className="SmallBox">
                                                    Unit {i + 1}
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="LargeBox">
                                                    <div className="form-group">
                                                      <input
                                                        type="text"
                                                        className="form-control width-250"
                                                        value={unit.unitNumber}
                                                      />
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="LargeBox">
                                                    <div className="CommonSelectBox w-100 mx-0">
                                                      <select>
                                                        <option>
                                                          Select Unit Type
                                                        </option>
                                                        {unitStep1List?.data
                                                          ?.table1?.units
                                                          ?.length > 0 &&
                                                          unitStep1List?.data?.table1?.units?.map(
                                                            (unitData) => {
                                                              return (
                                                                <option
                                                                  selected={
                                                                    unitData?.id ==
                                                                    unit?.unitTypeId
                                                                  }
                                                                >
                                                                  {
                                                                    unitData.type
                                                                  }
                                                                </option>
                                                              );
                                                            }
                                                          )}
                                                      </select>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="MediumBox">
                                                    <label className="switch">
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          unit.attachedGarage ===
                                                          true
                                                        }
                                                      />
                                                      <span className="slider" />
                                                    </label>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="MediumBox">
                                                    <label className="switch">
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          unit.basementDevelopment ===
                                                          true
                                                        }
                                                      />
                                                      <span className="slider" />
                                                    </label>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="MediumBox">
                                                    <label className="switch">
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          unit.drivewaysSideWalks ===
                                                          true
                                                        }
                                                      />
                                                      <span className="slider" />
                                                    </label>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="MediumBox">
                                                    <label className="switch">
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          unit.concretePads ===
                                                          true
                                                        }
                                                      />
                                                      <span className="slider" />
                                                    </label>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="MediumBox">
                                                    <label className="switch">
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          unit.detachedAndCommonGarages ===
                                                          true
                                                        }
                                                      />
                                                      <span className="slider" />
                                                    </label>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="Button d-flex  justify-content-between align-items-center px-5">
        <button
          className="Outline border text-success border-success"
          onClick={() => handleShowComponent("blockFoundation")}
        >
          Cancel
        </button>
        <button
          className="Create bg-success border border-success"
          onClick={() => handleShowComponents("unitOptionInput")}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default Unitinput;
