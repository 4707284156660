
import { archiveAction } from "../actionTypes";
import { Url } from "../../Config/Config";
import axios from "axios";
import { isLoggedIn } from "../../utils/tokenCheck";
import { multiPartData, multiPartDatas } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

//===========================getArchivelist===============================

export const archiveListInitiate = () => ({
    type: archiveAction.ARCHIVE_LIST_INITIATE,
  });
  
  export const archiveListSuccess = (data) => ({
    type: archiveAction.ARCHIVE_LIST_SUCCESS,
    payload: data,
  });
  
  export const archiveListFailure = (data) => ({
    type: archiveAction.ARCHIVE_LIST_FAILURE,
    payload: data,
  });
  
  export const getArchiveList = (jobType) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(archiveListInitiate());
        const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
        const response = await axios.get(`${Url}archive/archivedList?jobType=${jobType}&CompanyId=${iBuildLocalData?.user?.CompanyId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 201) {
          dispatch(archiveListSuccess(response.data));
        } else {
          dispatch(archiveListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(archiveListFailure(err));
        return err.response
      }
    };

//-===================================Archive-eventList=============================


export const archiveEventListInitiate = () => ({
    type: archiveAction.ARCHIVE_EVENT_LIST_INITIATE,
  });
  
  export const archiveEventListSuccess = (data) => ({
    type: archiveAction.ARCHIVE_EVENT_LIST_SUCCESS,
    payload: data,
  });
  
  export const archiveEventListFailure = (data) => ({
    type: archiveAction.ARCHIVE_EVENT_LIST_FAILURE,
    payload: data,
  });
  
  export const getArchiveEventList = (jobType) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(archiveEventListInitiate());
        const response = await axios.get(`${Url}archive/archivedEventList?jobType=${jobType}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 201) {
          dispatch(archiveEventListSuccess(response.data));
        } else {
          dispatch(archiveEventListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(archiveEventListFailure(err));
        return err.response
      }
    };
//===================================archieve-restore-job============================

export const archiveRestoreJobInitiate = () => ({
    type: archiveAction.ARCHIVE_RESTORE_JOB_INITIATE,
  });
  
  export const archiveRestoreJobSuccess = (data) => ({
    type: archiveAction.ARCHIVE_RESTORE_JOB_SUCCESS,
    payload: data,
  });
  
  export const archiveRestoreJobFailure = (data) => ({
    type: archiveAction.ARCHIVE_RESTORE_JOB_FAILURE,
    payload: data,
  });
  
  export const getArchiveRestoreJob = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(archiveRestoreJobInitiate());
        const response = await axios.post(`${Url}archive/restoreJob`, payload,{
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(archiveRestoreJobSuccess(response.data));
        } else {
          dispatch(archiveRestoreJobFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(archiveRestoreJobFailure(err));
        return err.response
      }
    };
//================================archive-delete-job===========================================

export const archiveDeleteJobInitiate = () => ({
    type: archiveAction.ARCHIVE_DELETE_JOB_INITIATE,
  });
  
  export const archiveDeleteJobSuccess = (data) => ({
    type: archiveAction.ARCHIVE_DELETE_JOB_SUCCESS,
    payload: data,
  });
  
  export const archiveDeleteJobFailure = (data) => ({
    type: archiveAction.ARCHIVE_DELETE_JOB_FAILURE,
    payload: data,
  });
  
  export const archiveDeleteJob = (payload) => async (dispatch) => {
      try {
        
        const token = isLoggedIn("adminData1");
        dispatch(archiveDeleteJobInitiate(payload));
        const response = await axios.post(`${Url}archive/permanentDeleteJob`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(archiveDeleteJobSuccess(response.data));
        } else {
          dispatch(archiveDeleteJobFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(archiveDeleteJobFailure(err));
        return err.response
      }
    };