import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ConsolidatedFieldOrderList from "./ConsolidatedFieldOrderList";
import { useSelector } from "react-redux";

const initialState = {
  buildingPermit: "",
  subdividision: "",
  specificationDoc: "",
  taxProfile: "",
  baseModel: "",
  homeType: "",
  defaultCurrency: "",
  costScheme: "",
  timeZone: "",
  accountSoftware: false,
  underParkade: false,
  jobAddress: "",
  lot: "",
  block: "",
  phase: "",
  city: "",
  zipCode: "",
  country: "",
  province: "",
  numOfBlocks: 0,
  blocks: [],
  numOfBuildings: 0,
  buildings: [],
  errorMsg: {},
};

const FieldOrderList = ({
  FieldOrderModal,

  SetFieldOrderModal,
}) => {
  
  const handleFieldOrderModalClose = () => {
    SetFieldOrderModal(false);



  };



  const [ConsolidatedFieldOrderListModal, SetConsolidatedFieldOrderListModal] = useState(false);

  const { fieldPurchaseOrderList} =
    useSelector((state) => state.constructionReducer);

  //   const handleSubmit = (e) => {
  //     e.preventDefault();

  //     let formIsValid = handleValidation();

  //     if (formIsValid) {
  //       SetProjectAsEntityStep1Modal(false);
  //       SetProjectAsEntityStep2Modal(true);
  //     }
  //   };

  return (
    <>
      <Modal show={FieldOrderModal} className="ModalBox LargeModal BgClass">
        <a
          onClick={handleFieldOrderModalClose}
          class="CloseModal ClosePermissionId"
        >
          ×
        </a>
        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Field Order's Purchase Order List</h4>
          </div>
          <div class="JobTitleArea mb-2">
            <div class="JobBox">
              <h6>
                <span>Job:</span> UUnit3-CHI-2B-315
              </h6>
            </div>
          </div>
          <div class="NotesArea mb-2">
            <h6>Field Purchase Orders</h6>
            <div>
              <a href="#">
                <img src="images/pdf.png" />
              </a>
              <a href="#">
                <img src="images/download.png" />
              </a>
              <a href="#">
                <img src="images/setting.png" />
              </a>
            </div>
          </div>
          <div class="TableList">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Documents id</th>
                  <th>Company Name</th>
                  <th>Cost Area</th>
                  <th>Account</th>
                  <th>Date Accepted</th>
                  <th>Ok to Pay Date</th>
                  <th>Reason Code</th>
                  <th>Total Pre Tax</th>
                  <th>Total</th>
                  <th>Supplier Credit</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {fieldPurchaseOrderList?.map((item)=>
                      <tr>
                      <td>1</td>
                      <td>2B-292-9-101-F2</td>
                      <td>Gen Sicard Consulting</td>
                      <td>Decks</td>
                      <td>Exterior Decks(s) - Pkg(s) - Materials</td>
                      <td>05/14/2024</td>
                      <td></td>
                      <td>Changes to Contract</td>
                      <td>$-4,000.00</td>
                      <td>$-4,200.00</td>
                      <td>False</td>
                      <td>
                        <div class="Actions">
                          <a href="javascript:void(0);">
                            <img src="images/note-search.png" />
                          </a>
                          <a
                            href="javascript:void(0);"
                            class="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#OkModal"
                            data-dismiss="modal"
                          >
                            <img src="images/dollar.png" />
                          </a>
                        </div>
                      </td>
                    </tr>
                )}
          
              </tbody>
            </table>
          </div>
          <div class="Button mt-5">
            <button
              class="Create FolderPermissionId"
              onClick={()=> {SetFieldOrderModal(false)
              SetConsolidatedFieldOrderListModal(true)}}
            >
              View Consolidated Field Purchase Order
            </button>
            <button class="Create ml-3" data-dismiss="modal">
              Create new Field Order P.O.
            </button>
          </div>
        </div>
      </Modal>
      <ConsolidatedFieldOrderList ConsolidatedFieldOrderListModal={ConsolidatedFieldOrderListModal} SetConsolidatedFieldOrderListModal={SetConsolidatedFieldOrderListModal}  SetFieldOrderModal={SetFieldOrderModal} />
    </>
  );
};

export default FieldOrderList;
