import React, { useEffect, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
  addSupportingDocsAction,
  getAllStaffListAction,
  getAllsupportingDocsListAction,
} from "../redux/action/saleAction";
import { errorToast, sucessToast } from "../utils/toast";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  TodoListAction,
  addGoogleCalendayAction,
  addOutlookCalendayAction,
  addTodoAction,
  addYahooAction,
  getCompleteTaskList,
  getCreatedTaskList,
  getInProcessTaskList,
  // getTaskCreate,
  getTaskHistoryList,
  getTodoCalendarAction,
  getTodoDelete,
  todoDetailsAction,
  updateAssignStaffAction,
  updateRemianderAction,
  updateTodoAction,
  updateTradeAction,
} from "../redux/action/todoAction";
import NoDataFound from "./Nodatafound";
import Loader from "./Loader";
import { checkArray } from "../utils/CheckType";
import { useRef } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { VendorListAction } from "../redux/action/vendorAction";
import { formToJSON } from "axios";
import { calculateDateDifference, convertToISOFormat } from "../utils/uploadFile";
import { getAddInternity, getTaskCreate, updateTask } from "../redux/action/taskAction";
import Multiselect from "multiselect-react-dropdown";
import FullCalendar from "@fullcalendar/react";
import moment from "moment";
import dayGridPlugin from "@fullcalendar/daygrid";
const initModal = {
  AddTodoModal: false,
  supportingDocsModal: false,
  addNotesModal: false,
  tradeModal: false,
  modalState: "",
  modalId: "",
  secondaryId: "",
  supportingDocsError: "",
  reminderModal: false,
  updateReminaderModal: false,
  todoHistoryModal: false,
  SupportingDocsListModal: false,
  todoDetailsModal: false,
  updateAssignTradeModal: false,
  moveTaskModal: false,
  AddTaskModal: false,
  assignSaleModal: false,
  AddItineraryModal: false,
  updateTodoModal: false,
  updateAssignModal: false,
  billDetailsModal:false,
  SelectJobModal:false,
};
const initLeadState = {
  files: "",
  tradeError: "",
  contact: "",
  email: "",
  message: "",
  VendorId: "",
  description: "",
  WorkFlowId: "",
  JobId: "",
  JobType: "",
  reminderDate: "",
  assignStaffs: "",
  day: "",
  week: "",
  subject: "",
  itinerayDesc: "",
  location: "",
  date: "",
  fromTime: "",
  toTime: "",
  type: "",
  jobId: "",
  jobType: "",
  reminderDate: "",
  relatedToTask: "",
  relatedToJob: "",
  TaskId: "",
  assignStaffs: "",
  assignStaffId: [],
  emailData: [],
  tempAssignList: [],
  targetDate: "",
  supportingDocFiles: "",
  todoError: {},
  scheRelatedToDo: false,
  reminderDateError: "",
  noteType: "text",
  notesMessage: "",
  UserId: "",
  AssignStaffError:"",
  itineraryError:{}
};
const Todos = () => {
  const todoListRef = useRef()
  const [iModalState, updateModalState] = useState(initModal);
  const [iLeadState, updateLeadState] = useState(initLeadState);
  const {
    files,
    supportingDocsError,
    contact,
    email,
    message,
    VendorId,
    tradeError,
    description,
    WorkFlowId,
    JobId,
    JobType,
    reminderDate,
    assignStaffs,
    
    day,
    week,
    subject,
    itinerayDesc,
    location,
    date,
    fromTime,
    toTime,
    type,
    relatedToTask,
    relatedToJob,
    TaskId,
    getId,
    assignStaffId,
    emailData,
    tempAssignList,
    targetDate,
    supportingDocFiles,
    scheRelatedToDo,
    todoError,
    noteType,
    reminderDateError,
    notesMessage,
    UserId,
    itineraryError,
    AssignStaffError
  } = iLeadState;
  const { todoListData, loader, todoHistoryListData, todoDetailsData } =
    useSelector((state) => state.todoReducer);
  const { vendorListData } = useSelector((state) => state.constructionVendor);
  const { staffListData } = useSelector((state) => state.salesReducer);
  const todoHistoryRef = useRef();
  const supportingDocsRef = useRef();
  const {
    AddTodoModal,
    supportingDocsModal,
    addNotesModal,
    modalState,
    modalId,
    secondaryId,
    tradeModal,
    reminderModal,
    todoHistoryModal,
    SupportingDocsListModal,
    todoDetailsModal,
    updateAssignTradeModal,
    updateReminaderModal,
    moveTaskModal,
    AddTaskModal,
    AddItineraryModal,
    assignSaleModal,
    updateTodoModal,
    updateAssignModal,
    billDetailsModal,
    SelectJobModal
  } = iModalState;
  const dispatch = useDispatch();
  const handleInput = (e) => {
    const { name, value } = e.target;
    updateLeadState({ ...iLeadState, [name]: value });
  };

  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    updateLeadState({ ...iLeadState, [name]: result });
  };

  const handleCheckbox = (e) => {
    const { name, value, checked } = e.target;

    if (checked == true) {
      updateLeadState({ ...iLeadState, [name]: value });
    } else {
      updateLeadState({ ...iLeadState, [name]: value });
    }
  };

  const handleJobTaskChecked = (e, type) => {
    const { checked } = e.target;
    if (type == "job") {
      updateLeadState({ ...iLeadState, relatedToJob: checked });
    } else {
      updateLeadState({ ...iLeadState, relatedToTask: checked });
    }
  };

  const handleInputFile = (e) => {
    const { name, value } = e.target;
    const file = e.target.files[0];
    if (file) {
      updateLeadState({
        ...iLeadState,
        [name]: file,
      });
    }
    e.target.files = null;
  };

  useEffect(() => {
    dispatch(getAllStaffListAction());
    dispatch(TodoListAction());
    dispatch(VendorListAction());
  }, []);

  const hideSupportingDocsModal = () => {
    if (modalState === "addTodo") {
      updateModalState({
        ...iModalState,
        supportingDocsModal: false,
        AddTodoModal: true,
        // modalId: "",
      });
    } else {
      updateModalState({
        ...iModalState,
        supportingDocsModal: false,
        // modalId: "",
        updateTodoModal: true,
      });
    }
  };

  const showSupportingDocsModal = (data, id) => {
    if (data === "addTodo") {
      updateModalState({
        ...iModalState,
        supportingDocsModal: true,
        AddTodoModal: false,
        modalId: id,
        modalState: data,
      });
    } else if (data === "editTodo") {
      updateModalState({
        ...iModalState,
        supportingDocsModal: true,
        updateTodoModal: false,
        // modalId: id,
        modalState: data,
      });
    }
  };


  const hideSelectJobModal = () => {
    updateModalState({ ...iModalState, SelectJobModal: false });
  };
  const showSelectJobModal = () => {
    updateModalState({ ...iModalState, SelectJobModal: true });
  };

  const addSupportingDocsFunc = () => {
    if (!supportingDocFiles) {
      updateLeadState({
        ...iLeadState,
        supportingDocsError: "* Files can't be empty",
      });
    } else {
      hideSupportingDocsModal();
      // dispatch(addSupportingDocsAction({ files }, modalId))
      //   .then((res) => {
      //     hideSupportingDocsModal();
      //     sucessToast("Supporting document added successfully");
      //     // dispatch(getAllsupportingDocsListAction(modalId));
      //   })
      //   .catch((err) => {
      //     console.log("=>", err);
      //   });
    }
  };

  const showUpdateStaffModal = (id, mid) => {
    updateModalState({ ...iModalState, updateAssignModal: true, modalId: mid });
    updateLeadState({ ...iLeadState, UserId: id });
  };

  const hideUpdateAssignModal = () => {
    updateModalState({ ...iModalState, updateAssignModal: false });
  };

  const updateAssignFun = () => {
    if (!UserId) {
      updateLeadState({
        ...iLeadState,
        AssignStaffError: "Assign staff can't be empty",
      });
    } else {
      dispatch(updateAssignStaffAction(modalId, { assignStaffs: [{ UserId }] }))
        .then((res) => {
          console.log("resssssssssss, res", res);
          hideUpdateAssignModal();
          sucessToast("Assign staff updated successfully");
        })
        .catch((err) => {
          console.log("dfsdfdfsgfd", err);
        });
    }
  };

  const showAddTodoModal = () => {
    updateModalState({ ...iModalState, AddTodoModal: true });
  };
  const hideAddTodoModal = () => {
    updateModalState({ ...iModalState, AddTodoModal: false });
  };


  const showAddNotesModal = (state) => {
    if (state === "addTodo") {
      updateModalState({
        ...iModalState,
        addNotesModal: true,
        AddTodoModal: false,
        modalState: state,
      });
    } else if (state === "editTodo") {
      updateModalState({
        ...iModalState,
        addNotesModal: true,
        updateTodoModal: false,
        modalState: state,
      });
    }
  };
  const hideAddNotesModal = () => {
    if (modalState === "addTodo") {
      updateModalState({
        ...iModalState,
        addNotesModal: false,
        AddTodoModal: true,
        modalState: "",
      });
    } else if (modalState === "editTodo") {
      updateModalState({
        ...iModalState,
        addNotesModal: false,
        updateTodoModal: true,
        modalState: "",
      });
    }
  };

  const hideAddTradingModal = () => {
    if (modalState === "addTodo") {
      updateModalState({
        ...iModalState,
        tradeModal: false,
        AddTodoModal: true,
        modalId: "",
      });
    } else if (modalState === "editTodo") {
      updateModalState({
        ...iModalState,
        tradeModal: false,
        modalId: "",
        updateTodoModal: true,
      });
    }
  };

  const showAddTradingModal = (data, id) => {
    dispatch(VendorListAction());
    if (data === "addTodo") {
      updateModalState({
        ...iModalState,
        tradeModal: true,
        AddTodoModal: false,
        modalId: id,
        modalState: data,
      });
    } else if (data === "editTodo") {
      updateModalState({
        ...iModalState,
        tradeModal: true,
        modalId: id,
        updateTodoModal: false,
        modalState: data,
      });
    }
  };

  const hideAddRemianderModal = () => {
    if (modalState === "addTodo") {
      updateModalState({
        ...iModalState,
        reminderModal: false,
        AddTodoModal: true,
        // modalState:""
      });
    } else if (modalState === "addTask") {
      updateModalState({
        ...iModalState,
        reminderModal: false,
        AddTaskModal: true,
        // modalState: "",
      });
    } else if (modalState === "editTodo") {
      updateModalState({
        ...iModalState,
        reminderModal: false,
        updateTodoModal: true,
        // modalState: "",
      });
    }else if (modalState === "addItinerary") {
      updateModalState({
        ...iModalState,
        reminderModal:false ,
        AddItineraryModal: true,
        modalState: modalState,
      });
    }
  };

  const showAddRemianderModal = (data, id) => {
    if (data === "addTodo") {
      updateModalState({
        ...iModalState,
        reminderModal: true,
        AddTodoModal: false,
        // modalId: id,
        modalState: data,
      });
    } else if (data === "addTask") {
      updateModalState({
        ...iModalState,
        reminderModal: true,
        AddTaskModal: false,
        // modalId: id,
        modalState: data,
      });
    } else if (data === "editTodo") {
      updateModalState({
        ...iModalState,
        reminderModal: true,
        updateTodoModal: false,
        modalState: data,
      });
    } else if (data === "addItinerary") {
      updateModalState({
        ...iModalState,
        reminderModal: true,
        AddItineraryModal: false,
        modalState: data,
      });
    }
    updateLeadState({ ...iLeadState, day: day, week: week });
  };

  const showAssignSaleModal = (state, id, email) => {
    console.log(id);
    // updateLeadState({...iLeadState, assignStaffId:[]})
    if (state) {
      // dispatch(getAllStaffListAction())
      updateModalState({
        ...iModalState,
        // AddLeadModal: false,
        assignSaleModal: true,
        modalState: state,
        // assignStaffs:[{UserId:id}],
      });
      updateLeadState({
        ...iLeadState,
        emailData: [email],
        assignStaffs: [{ UserId: id }],
      });
    } else if (!state) {
      updateModalState({
        ...iModalState,
        AddLeadModal: false,
        assignSaleModal: true,
        modalState: state,
      });
    }

    if (state == "editFollowUpActivity") {
      updateModalState({
        ...iModalState,
        assignSaleModal: true,
        editFollowUpActivityModal: false,
        modalState: state,
      });
    } else if (state == "discussion") {
      updateModalState({
        ...iModalState,
        assignSaleModal: true,
        discussionModal: false,
        modalState: state,
      });
      updateLeadState({ ...iLeadState, UserId: id });
    } else if (state == "Itinerary") {
      updateModalState({
        ...iModalState,
        assignSaleModal: true,
        addItineraryModal: false,
        modalState: state,
      });
      updateLeadState({ ...iLeadState, UserId: id });
    } else if (state == "editLeadDetails") {
      updateModalState({
        ...iModalState,
        assignSaleModal: true,
        editLeadDetailsModal: false,
        modalState: state,
      });
      updateLeadState({ ...iLeadState, UserId: id });
    } else if (state == "scheduleNewFollow") {
      updateModalState({
        ...iModalState,
        assignSaleModal: true,
        scheduleNewFollowModal: false,
        modalState: state,
      });
    } else if (state === "contract") {
      updateModalState({
        ...iModalState,
        assignSaleModal: true,
        AddContractModal: false,
        modalState: state,
      });
    } else if (state === "editContract") {
      updateModalState({
        ...iModalState,
        assignSaleModal: true,
        editContactInformationModal: false,
        modalState: state,
      });
    }
  };

 

  const hideAssignSaleModal = () => {
    if (modalState) {
      updateModalState({
        ...iModalState,
        // AddLeadModal: true,
        assignSaleModal: false,
      });
    } else {
      updateModalState({
        ...iModalState,
        AddLeadModal: true,
        assignSaleModal: false,
      });
    }
    if (modalState == "editFollowUpActivity") {
      updateModalState({
        ...iModalState,
        assignSaleModal: false,
        editFollowUpActivityModal: true,
      });
    } else if (modalState == "discussion") {
      updateModalState({
        ...iModalState,
        assignSaleModal: false,
        discussionModal: true,
      });
    } else if (modalState == "Itinerary") {
      updateModalState({
        ...iModalState,
        assignSaleModal: false,
        addItineraryModal: true,
        modalState: "",
      });
    } else if (modalState == "editLeadDetails") {
      updateModalState({
        ...iModalState,
        assignSaleModal: false,
        editLeadDetailsModal: true,
        modalState: "",
      });
    } else if (modalState == "scheduleNewFollow") {
      updateModalState({
        ...iModalState,
        assignSaleModal: false,
        scheduleNewFollowModal: true,
        modalState: "",
      });
    } else if (modalState === "contract") {
      updateModalState({
        ...iModalState,
        assignSaleModal: false,
        AddContractModal: true,
        modalState: "",
      });
    } else if (modalState === "editContract") {
      updateModalState({
        ...iModalState,
        assignSaleModal: true,
        editContactInformationModal: false,
        modalState: "",
      });
    }
  };

  const showTodoHistoryModal = (id) => {
    dispatch(getTaskHistoryList(id));
    updateModalState({ ...iModalState, todoHistoryModal: true });
  };

  const hideTodoHistoryModal = () => {
    updateModalState({ ...iModalState, todoHistoryModal: false });
  };

  const deleteTodo = (id) => {
    dispatch(getTodoDelete(id)).then((res) => {
      sucessToast("Todo deleted successfully");
      dispatch(TodoListAction());
    });
  };
  const handleGoogleData = (item) => {
    const datta = {
      title: "Sample Event",
      description: item.description,
      assignDate: item.assignDate,
      reminderDate: item.reminderDate,
      location: "Sample Location",
    };
    dispatch(addGoogleCalendayAction(datta))
      .then((res) => {
        window.open(res?.google, "_blank");
        dispatch(TodoListAction());
        sucessToast("Calendar links generated successfully");
      })
      .catch((error) => {
        errorToast("Something went wrong", error?.response?.message);
      });
  };

  const handleYahooData = (item) => {
    const datta = {
      title: "Sample Event",
      description: item.description,
      assignDate: item.assignDate,
      reminderDate: item.reminderDate,
      location: "Sample Location",
    };
    dispatch(addYahooAction(datta))
      .then((res) => {
        window.open(res?.yahoo, "_blank");
        dispatch(TodoListAction());
        sucessToast("Yahoo links generated successfully");
      })
      .catch((error) => {
        errorToast("Something went wrong", error?.response?.message);
      });
  };

  const handleOutlookData = (item) => {
    const datta = {
      title: "Sample Event",
      description: item.description,
      assignDate: item.assignDate,
      reminderDate: item.reminderDate,
      location: "Sample Location",
    };
    dispatch(addOutlookCalendayAction(datta))
      .then((res) => {
        window.open(res?.outlook, "_blank");
        dispatch(TodoListAction());
        sucessToast("Outlook links generated successfully");
      })
      .catch((error) => {
        errorToast("Something went wrong", error?.response?.message);
      });
  };

  const showSupportingDocsListModal = (state) => {
    if (state === "addTodo") {
      updateModalState({
        ...iModalState,
        SupportingDocsListModal: true,
        AddTodoModal: false,
        modalState: state,
      });
    }
  };
  const hideSupportingDocsListModal = () => {
    if (modalState === "addTodo") {
      updateModalState({
        ...iModalState,
        SupportingDocsListModal: false,
        AddTodoModal: true,
        modalState: "",
      });
    } else {
      updateModalState({
        ...iModalState,
        SupportingDocsListModal: false,

        modalState: "",
      });
    }
  };

  const showTodoDetailsModal = (state, id) => {
    dispatch(todoDetailsAction(id));
    if (state === "todoList") {
      updateModalState({
        ...iModalState,
        todoDetailsModal: true,
        modalState:state
        //  AddTodoModal:false, modalState:state
      });
    }
  };
  const hideTodoDetailsModal = () => {
    if (modalState === "todoList") {
      updateModalState({
        ...iModalState,
        todoDetailsModal: false,
        //  AddTodoModal:true,
        modalState: "",
      });
    }
  };

  const hideUpdateTradingModal = () => {
    if (modalState === "addTodo") {
      updateModalState({
        ...iModalState,
        updateAssignTradeModal: false,
        // AddTodoModal: true,
        modalId: "",
      });
      updateLeadState({
        ...iLeadState,
        VendorId: "",
        contact: "",
        email: "",
        message: "",
        tradeError: {},
      });
    } else {
      updateModalState({
        ...iModalState,
        updateAssignTradeModal: false,
        modalId: "",
        // SupportingDocsListModal: true,
      });
      updateLeadState({
        ...iLeadState,
        VendorId: "",
        contact: "",
        email: "",
        message: "",
        tradeError: {},
      });
    }
  };

  const showUpdateTradingModal = (
    data,
    id,
    Tid,
    Vid,
    mail,
    message,
    contact
  ) => {
    if (data === "addTodo") {
      updateModalState({
        ...iModalState,
        updateAssignTradeModal: true,
        // AddTodoModal: false,
        modalId: id,
        modalState: data,
        secondaryId: Tid,
      });
    } else {
      updateModalState({
        ...iModalState,
        updateAssignTradeModal: true,
        modalId: id,
        modalState: data,
        secondaryId: Tid,
      });
      updateLeadState({
        ...iLeadState,
        VendorId: Vid,
        email: mail,
        message: message,
        contact: contact,
      });
    }
  };

  const handleTradeValidation = () => {
    let error = {};
    let formIsValid = true;

    if (!contact) {
      error.contactError = "Contact name can't be empty";
      formIsValid = false;
    }
    if (contact) {
      if (!/^[1-9][0-9]{9,12}$/.test(contact)) {
        error.contactError = "Please enter a valid phone number";
        formIsValid = false;
      }
    }

    if (!email) {
      error.emailError = "Email can't be empty";
      formIsValid = false;
    }

    if (email) {
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        error.emailError = "Please enter a valid email";
        formIsValid = false;
      }
    }
    if (!message) {
      error.messageError = "Message can't be empty";
      formIsValid = false;
    }

    if (!VendorId) {
      error.VenderIdError = "Sub trade be empty";
      formIsValid = false;
    }
    updateLeadState({ ...iLeadState, tradeError: error });
    return formIsValid;
  };

  const UpdateTradeFun = (e) => {
    e.preventDefault();
    let formIsValid = handleTradeValidation();
    if (formIsValid) {
      let assignTrade = {
        contact,
        email,
        message,
        VendorId,
        
      };
      dispatch(
        updateTradeAction(modalId, secondaryId, { assignTrade: assignTrade })
      ).then((res) => {
        sucessToast("Trade updated successfully");
        dispatch(TodoListAction());
        hideUpdateTradingModal();
      });
    }
  };

  const hideUpdateRemianderModal = () => {
    if (modalState === "todoList") {
      updateModalState({
        ...iModalState,
        updateReminaderModal: false,
        // AddTodoModal: true,
        modalId: "",
      });
      // updateLeadState({...iLeadState, VendorId:"", contact:"", email:"", message:""})
    } else {
      updateModalState({
        ...iModalState,
        updateReminaderModal: false,
        modalId: "",
        // SupportingDocsListModal: true,
      });
      // updateLeadState({...iLeadState, VendorId:"", contact:"", email:"", message:""})
    }
  };

  const showUpdateRemianderModal = (data, id) => {
    dispatch(VendorListAction());
    if (data === "addTodo") {
      updateModalState({
        ...iModalState,
        updateReminaderModal: true,
        // AddTodoModal: false,
        modalId: id,
        modalState: data,
      });
    } else {
      updateModalState({
        ...iModalState,
        updateReminaderModal: true,
        modalId: id,
        modalState: data,
      });
    }
  };

  const hideMoveTaskModal = () => {
    if (modalState === "todoList") {
      updateModalState({
        ...iModalState,
        moveTaskModal: false,
        // AddTodoModal: true,
        modalId: "",
      });
      // updateLeadState({...iLeadState, VendorId:"", contact:"", email:"", message:""})
    } else {
      updateModalState({
        ...iModalState,
        moveTaskModal: false,
        modalId: "",
        // SupportingDocsListModal: true,
      });
      // updateLeadState({...iLeadState, VendorId:"", contact:"", email:"", message:""})
    }
  };

  const showMoveTaskModal = (data, id) => {
    dispatch(VendorListAction());
    if (data === "addTodo") {
      updateModalState({
        ...iModalState,
        moveTaskModal: true,
        // AddTodoModal: false,
        modalId: id,
        modalState: data,
      });
    } else {
      updateModalState({
        ...iModalState,
        moveTaskModal: true,
        modalId: id,
        modalState: data,
      });
    }
  };

  const showAddTaskModal = (state, id) => {
    if (state === "moveTask") {
      updateModalState({
        ...iModalState,
        AddTaskModal: true,
        moveTaskModal: false,
        //  modalId:id
      });
      updateLeadState({
        ...iLeadState,
        week: "",
        day: "",
        reminderDate: "",
        assignStaffs: "",
        description: "",
      });
    }
  };

  const hideHandleAddTaskClose = (state) => {
    updateModalState({
      ...iModalState,
      AddTaskModal: false,
      moveTaskModal: true,
    });
  };

  const handleTaskAdd = () => {
    // let formIsValid = handleShareDocumentValidation();
    // if (formIsValid) {
    const type = week ? week : day;
    const dataa = {
      description,
      WorkFlowId: "1",
      JobId: "1",
      JobType: "ResidentialJob",
      reminderDate: calculateDateDifference(type),
      assignStaffs: [{ UserId: Number(2) }],
      getFrom: "ToDo",
      getId: modalId,
    };
    dispatch(getTaskCreate(dataa)).then((res) => {
      if (res.status === 201) {
        dispatch(getCreatedTaskList({ showAll: false }));
        updateModalState({
          ...iModalState,
          AddTaskModal: false,
          week: "",
          day: "",
          reminderDate: "",
          assignStaffs: "",
        });
        sucessToast("Task created successfully");
      }
    });
  };
  console.log("modalzstate", modalState)

  const showAddItineraryModal = (state) => {
    if (state === "moveTask") {
      updateModalState({
        ...iModalState,
        AddItineraryModal: true,
        moveTaskModal: false,
        modalState: state,
        //  modalId:id
      });
      // updateLeadState({
      //   ...iLeadState,
      //   week: "",
      //   day: r"",
      //   reminderDate: "",
      //   assignStaffs: "",
      //   description: "",
      // });
    }
  };

  const hideAddItineraryModal = () => {
    if (modalState === "moveTask" || "addItinerary") {
      updateModalState({
        ...iModalState,
        AddItineraryModal: false,
        moveTaskModal: true,
        //  modalId:id
      });
    }else {

    }
  };

const handleItineraryValidation = () => {
     let error = {};
     let formIsValid = true;
     if(!subject){
      error.subjectError = "Subject can't b empty";
      formIsValid = false
     }

     if(!itinerayDesc){
      error.itinerayDescError = "Itineray desc can't b empty";
      formIsValid = false
     }

     if(!location){
      error.locationError = "Location can't b empty";
      formIsValid = false
     }

     if(!date){
      error.dateError = "Date can't b empty";
      formIsValid = false
     }


     if(!fromTime){
      error.fromTimeError = "From time can't b empty";
      formIsValid = false
     }

     if(!toTime){
      error.toTimeError = "To time can't b empty";
      formIsValid = false
     }

     if(!type){
      error.typeError = "Type can't b empty";
      formIsValid = false
     }

     if(!day && !week){
      error.reminderDateError = "Reminder date can't b empty";
      formIsValid = false
     }

     
     if(!UserId){
      error.UserIdError = "Assign staff can't b empty";
      formIsValid = false
     }


    //  if(!JobId){
    //   error.JobIdError = "Job can't b empty";
    //   formIsValid = false
    //  }

     
    //  if(!JobType){
    //   error.JobTypeError = "Job type can't b empty";
    //   formIsValid = false
    //  }

     updateLeadState({...iLeadState, itineraryError:error})
     return formIsValid
}
  
  

  const handleAddItinerary = () => {
    let formIsValid = handleItineraryValidation();
    if(formIsValid){
      const type = week ? week : day;
      const reminderDates = calculateDateDifference(type);
      const datta = {
        subject,
        description: itinerayDesc,
        location,
        date,
        fromTime: `${fromTime}:00`,
        toTime: `${toTime}:00`,
        type,
        jobId: "1",
        jobType: "ResidentialJob",
        reminderDate: reminderDates,
        relatedToTask: relatedToTask == true ? true : false,
        relatedToJob: relatedToJob == true ? true : false,
        TaskId: +2,
        assignStaffs: [{
          UserId
        }],
        getFrom: "ToDo",
        getId: modalId,
      };
      dispatch(getAddInternity(datta)).then((res) => {
        console.log("resssss",res)
        if(res?.status === 201){
          sucessToast("Itinerary added Sucessfully");
          hideAddItineraryModal();
          updateLeadState({
            ...iLeadState,
            subject: "",
            itinerayDesc: "",
            location: "",
            date: "",
            fromTime: "",
            toTime: "",
            type: "",
            reminderDates: "",
            jobType: "",
            jobId: "",
            relatedToTask: "",
            relatedToJob: "",
            TaskId: "",
            assignStaffss: "",
            AddItineraryModal: false,
            MoveTaskModal: false,
            day: "",
            week: "",
          });
        }
       
      }).catch((err) =>{
        console.log('errr------>',err)
      })
    }
   
  };

  useEffect(() => {
    let tempSubStreamList;
    if (
      assignStaffId &&
      assignStaffId.length > 0 &&
      assignStaffId?.some((x) => x?.UserId?.toString().trim() == "")
    ) {
      tempSubStreamList = assignStaffId?.map((item, i) => {
        return {
          id: item?.UserId,
        };
      });

      tempSubStreamList = emailData?.map((item, i) => {
        return {
          email: item,
        };
      });
      updateLeadState({ ...iLeadState, tempAssignList: tempSubStreamList });
    }
  }, [assignStaffId]);

  function onSelectStream(selectedList, selectedItem) {
    for (let i = 0; i < selectedList?.length; i++) {
      let assignStaffIdLocal = [...assignStaffId];
      // let emailDataLocal = [...emailData];
      let streamData = {
        UserId: selectedItem?.id,
      };
      assignStaffIdLocal = [...assignStaffIdLocal, streamData];
      // emailDataLocal = [...emailDataLocal, selectedItem?.email];
      updateLeadState({
        ...iLeadState,
        assignStaffId: assignStaffIdLocal,
        // emailData: emailDataLocal,
      });
    }
  }

  const staffList = {
    options: staffListData?.staff,
  };

  const handleTodoValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!JobId) {
      error.JobIdError = "Job Id can't be empty";
      formIsValid = false;
    }

    // if (!JobType) {
    //   error.JobTypeError = "Job can't be empty";
    //   formIsValid = false;
    // }

    if (!TaskId) {
      error.TaskIdError = "Task can't be empty";
      formIsValid = false;
    }

    if (!description) {
      error.descriptionError = "Description can't be empty";
      formIsValid = false;
    }

    if (!day && !week) {
      error.reminderDateError = "Remiander can't be empty";
      formIsValid = false;
    }

    if (!targetDate) {
      error.targetDateError = "Job type can't be empty";
      formIsValid = false;
    }

    if (!supportingDocFiles) {
      error.supportingDocFilesError = "Supporting file can't be empty";
      formIsValid = false;
    }

    if (!VendorId || !message || !email || !contact) {
      error.tradeError = "Trade can't be empty";
      formIsValid = false;
    }

    // if (assignStaffId?.length  === 0) {
    //   error.assignStaffIdError = "Assign staff can't be empty";
    //   formIsValid = false;
    // }
    if (!UserId) {
      error.assignStaffIdError = "Assign staff can't be empty";
      formIsValid = false;
    }
    // if (!noteType || (!notesMessage)) {
    //   error.noteError = "Notes can't be empty";
    //   formIsValid = false;
    // }

    updateLeadState({ ...iLeadState, todoError: error });
    return formIsValid;
  };

  const addTodoFunc = (e) => {
    e.preventDefault();
    let formIsValid = handleTodoValidation();
    if (formIsValid) {
    const type = week ? week : day;
    let data = {
      toDoNotes: {
        noteType :"text",
        message: "notesMessage",
      },
      assignTrade: {
        message,
        VendorId,
        contact,
        email,
      },
      JobId: +JobId,
      JobType: "ResidentialJob",
      scheRelatedToDo: true,
      TaskId: +TaskId,
      description,
      targetDate: convertToISOFormat(targetDate),
      assignStaffs: [{ UserId }],
      supportingDocFiles,
      reminderDate: calculateDateDifference(type),
    };

      dispatch(addTodoAction(data)).then((res) => {
        dispatch(TodoListAction());
        hideAddTodoModal();
        sucessToast("Todo Created successfully")
        updateLeadState({
          // todoNotes: {
          //   noteType :"text",
          //   message :"dgf",
          // },
          assignTrade: {},
          JobId: "",
          JobType: "",
          scheRelatedToDo: true,
          TaskId: "",
          description: "",
          targetDate: "",
          assignStaffs: "",
          supportingDocFiles: "",
          reminderDate: "",
        });
      });
    }
  };

  console.log("new Date(targetDate).toLocaleString()",convertToISOFormat(targetDate))
  const CreateTradeFun = (e) => {
    e.preventDefault();
    let formIsValid = handleTradeValidation();
    if (formIsValid) {
      hideAddTradingModal();
    }
  };

  const updateRemianderFun = () => {
    const type = week ? week : day;
    if (!day && !week) {
      updateLeadState({
        ...iLeadState,
        reminderDateError: "Remiander can't be empty",
      });
    } else {
      dispatch(
        updateRemianderAction(modalId, {
          reminderDate: calculateDateDifference(type),
        })
      ).then((res) => {
        sucessToast("Remiander updated successfully");
        hideUpdateRemianderModal();
      });
    }
  };

  const showUpdateTodoModal = (ele) => {
    dispatch(todoDetailsAction(ele?.id)).then((res) => {
      console.log("res", res);
      updateLeadState({
        ...iLeadState,
        JobId: res?.toDo?.JobId,
        TaskId: res?.toDo?.TaskId,
        description: res?.toDo?.description,
        targetDate: res?.toDo?.targetDate?.split("T")[0],
        noteType: res?.toDo?.ToDoNotes?.[0]?.noteType,
        notesMessage: res?.toDo?.ToDoNotes?.[0]?.message,
        VendorId: res?.toDo?.AssignTrade?.VendorId,
        contact: res?.toDo?.AssignTrade?.contact,
        email: res?.toDo?.AssignTrade?.email,
        message: res?.toDo?.AssignTrade?.message,
        supportingDocFiles: res?.toDo?.SupportDocs?.[0]?.docUrl,
        assignStaffs: res?.toDo?.SupportDocs?.[0]?.ToDoAssignStaffs?.UserId,
      });
    });
    updateModalState({
      ...iModalState,
      updateTodoModal: true,
      modalId: ele?.id,
    });
  };

  const hideUpdateTodoModal = () => {
    updateModalState({ ...iModalState, updateTodoModal: false });
  };

  const updateTodoFunc = (e) => {
    e.preventDefault();
    let formIsValid = handleTodoValidation();
    const type = week ? week : day;
    let data = {
      toDoNotes: {
        noteType,
        message: notesMessage,
      },
      assignTrade: {
        message,
        VendorId,
        contact,
        email,
      },
      JobId: +JobId,
      JobType: "ResidentialJob",
      scheRelatedToDo: true,
      TaskId: +TaskId,
      description,
      targetDate: calculateDateDifference(type),
      assignStaffs: [{ UserId }],

      reminderDate: calculateDateDifference(type),
    };
    if (typeof supportingDocFiles == "object") {
      data["supportingDocFiles"] = supportingDocFiles;
    }

    if (formIsValid) {
      dispatch(updateTodoAction(modalId, data)).then((res) => {
        dispatch(TodoListAction());
        hideUpdateTodoModal();
        sucessToast("Todo updated successfully");
        updateLeadState({
          // todoNotes: {
          //   noteType :"text",
          //   message :"dgf",
          // },
          assignTrade: {},
          JobId: "",
          JobType: "",
          scheRelatedToDo: true,
          TaskId: "",
          description: "",
          targetDate: "",
          assignStaffs: "",
          supportingDocFiles: "",
          reminderDate: "",
        });
      });
    }
  };

  const calendarRef = useRef(null);

  const [view, setView] = useState("dayGridMonth");
  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Fetch or generate your dynamic events data based on the selected view (date, week, or month)
    const fetchData = async () => {
      // Replace this with your actual data fetching logic
      const data = await fetchEventData(view);
      console.log("datttattttttttttttttttttt",data)
      setEvents(data);
    };

    fetchData();
  }, [view]);

  const fetchEventData = async (currentView) => {
    const currentDate = new Date();
    if (currentView === "dayGridMonth") {
      const startOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const endOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );

      // Fetch events for the current month
      const monthEvents = await fetchDataForDateRange(startOfMonth, endOfMonth);

      return monthEvents;
    }

    // Example: Fetch events for the current week
    if (currentView === "dayGridWeek") {
      const startOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - currentDate.getDay()
      );
      const endOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + (6 - currentDate.getDay())
      );

      // Fetch events for the current week
      const weekEvents = await fetchDataForDateRange(startOfWeek, endOfWeek);

      return weekEvents;
    }

    // Example: Fetch events for the current day
    const dayEvents = await fetchDataForDate(currentDate);
    return dayEvents;
  };

  const fetchDataForDateRange = async (startDate, endDate) => {
    const datta = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    };
    dispatch(getTodoCalendarAction(datta))
      .then((res) => {
        // if (res.status === 200) {
          console.log("Updated", res)
          const eventsData = res?.toDos?.map((item) => ({
            // console.log("item", item),
            title: item.description ,
            start: item.createdAt, // Assuming 'date' is the property containing the event date
          })) || [];
          console.log("Events", eventsData)
    
          // Set the events state
          setEvents(eventsData);
          sucessToast("Find data successfuly");
        // }
      })
      .catch((error) => {
        console.log("=========>", error);
        // errorToast(error.response?.data?.message);
      });
  };

  const fetchDataForDate = async (date) => {
    const datta = {
      startDate: moment(date).format("YYYY-MM-DD"),
      endDate: moment(date).format("YYYY-MM-DD"),
    };
    dispatch(getTodoCalendarAction(datta))
      .then((res) => {
        console.log("ressssssssss",res)
        // if (res.status === 200) {
          console.log("Updated", res)
          const eventsData = res?.toDos?.map((item) => ({
            title: item.description,
            start: item.createdAt, // Assuming 'date' is the property containing the event date
          })) || [];
    
          // Set the events state
          setEvents(eventsData);
          sucessToast("Find data successfuly");
        // }
      })
      .catch((error) => {
        // errorToast(error.response?.data?.message);
      });
  };

  console.log("_______________fdkusdhfkdfhdg",events)
  const handleViewChange = (newView) => {
    setView(newView);
  };

  const handleDateSet = (dateInfo) => {
    if (dateInfo.startStr === dateInfo.endStr) {
      setView("dayGridMonth"); // You can set it to the default view you prefer
    }
  };

  const {
    jobList,
    createdTaskList,
    inProcessTaskList,
    completeTaskList, 
    memberList,
    historyTaskList,
    showCancelList,
    viewTaskDetail,
    incomingAccessList,
    manageAccessToMyList,
    manageAccessUserTaskList,
    showHiddenList,
    showMoveList,
   
  } = useSelector((state) => state.taskReducer);
  //===========================================================================================//
  function renderEventContent(eventInfo) {
    console.log("---- m ------------>,", eventInfo);
    return (
      <>
         <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
        <p>{eventInfo.event.extendedProps.description}</p>
      </>
    );
  }
  const onDragEnd = (result) => {
    const { destination, source } = result;
   
    // Early exit if no destination
    if (!destination) {
      return;
    }
  
    // Early exit if the item is dropped in the same position
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
  
    let newItems1 = createdTaskList?.tasks || [];
    let newItems2 = inProcessTaskList?.tasks || [];
    let newItems3 = completeTaskList?.tasks || [];
    let draggedItem;
  
    if (source.droppableId === 'droppable1' && destination.droppableId === 'droppable2') {
      if (source.index >= newItems1.length || destination.index > newItems2.length) {
        console.error('Index out of bounds');
        return;
      }
  
      draggedItem = newItems1[source.index];
      newItems1.splice(source.index, 1);
      newItems2.splice(destination.index, 0, draggedItem);
  
      dispatch(updateTask(newItems2?.[0]?.id, {
        taskStatus: "In-Progress",
        WorkFlowId: 1,
        JobId: newItems2?.[0]?.JobId,
        JobType: newItems2?.[0]?.JobType
      })).then((res) => {
        if (res.status === 200) {
          dispatch(getInProcessTaskList());
          dispatch(getCreatedTaskList());
        }
      });
    } else if (source.droppableId === 'droppable2' && destination.droppableId === 'droppable3') {
      if (source.index >= newItems2.length || destination.index > newItems3.length) {
        console.error('Index out of bounds');
        return;
      }
  
      draggedItem = newItems2[source.index];
      newItems2.splice(source.index, 1);
      newItems3.splice(destination.index, 0, draggedItem);
  
      dispatch(updateTask(newItems3?.[0]?.id, {
        taskStatus: "Completed",
        WorkFlowId: 1,
        JobId: newItems3?.[0]?.JobId,
        JobType: newItems3?.[0]?.JobType
      })).then((res) => {
        if (res.status === 200) {
          dispatch(getCompleteTaskList());
          dispatch(getInProcessTaskList());
        }
      });
    } else {
      return;
    }
  };
  
 
  return (
    <>
      <Header />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TodosArea">
            <div className="TodosHeader">
              <h6>TODOS</h6>  
              <h6>
                Global View
                <img
                  src="images/setting.png"
                  data-toggle="modal"
                  data-target="#TodoSettings"
                />
              </h6>
            </div>
            <div className="TodosButtons mt-0 d-flex justify-content-between flex-wrap">
              <p>Multi-family/Home/Construction Dashboard/To do list</p>
              <a
                className="Create"
                data-toggle="modal"
                data-target="#SelectJobModal"
                onClick={showSelectJobModal}
              >
                <img src="images/select_job.png" />
                Select Jobs
              </a>
            </div>
            <div className="TodosButtons">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#BoardTabs"
                  >
                    <img src="images/board_red.png" />
                    Board
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                  >
                    <img src="images/show_all_job_red.png" />
                    Show All Jobs
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#CalendarTabs"
                  >
                    <img src="images/icon-23.png" />
                    Calendar
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="tab-content">
            <div className="tab-pane active show" id="BoardTabs">
              <div className="TodoBoxArea">
              <div className="row">
              <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="droppable1">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef} className="col-4">
          <div className="">
            <div className="TodoBox TodoBoxHeight">
              <div className="TodoBoxHeader">
                <div className="d-flex align-items-center">
                  <h6>Created</h6>
                  {/* <img
                    src="images/eye-icon.png"
                    onClick={()=>handleAllHiddenTask("create")}
                  /> */}
                </div>
                <img src="images/add.png" onClick={showAddTodoModal} />
                <figure className="mb-0">
                  <img src="images/task-square.png" />
                </figure>
              </div>

              {todoListData?.toDos?.length > 0 &&
                todoListData?.toDos?.map((ele, i) => (
                  <Draggable key={ele.id} draggableId={ele.id.toString()} index={i}>
                    {(provided, snapshot) => (
                       <div   ref={provided.innerRef}
                       {...provided.draggableProps}
                       {...provided.dragHandleProps} className="TodoUser RedLeftBorder">
                       <div className="TodoUserJob">
                         <h6>{ele?.description}</h6>
                         <div className="dropdown">
                           <p data-toggle="dropdown">
                             Job: {ele?.JobId}
                             <span>
                               <img src="images/three-dots.png" />
                             </span>
                           </p>
                           <div
                             className="dropdown-menu"
                             aria-labelledby="dropdownMenuOffset"
                           >
                             <li>
                               <a
                                 className="dropdown-item"
                                 href="javascript:void(0);"
                                 onClick={() =>
                                   showUpdateStaffModal(
                                     ele?.ToDoAssignStaffs?.[0]
                                       ?.UserId,
                                     ele?.id
                                   )
                                 }
                               >
                                 <img src="images/assign-resources.png" />
                                 Assign Staff
                               </a>
                             </li>
                             <li>
                               <a
                                 className="dropdown-item FolderPermissionId"
                                 href="javascript:void(0);"
                                 onClick={() =>
                                   showUpdateTradingModal(
                                     "todoList",
                                     ele?.id,
                                     ele?.AssignTrade?.id,
                                     ele?.AssignTrade?.VendorId,
                                     ele?.AssignTrade?.email,
                                     ele?.AssignTrade?.message,
                                     ele?.AssignTrade?.contact
                                   )
                                 }
                               >
                                 <img src="images/assign-trade.png" />
                                 Assign Trade
                               </a>
                             </li>
                             <li>
                               <a
                                 className="dropdown-item FolderPermissionId"
                                 href="javascript:void(0);"
                                 onClick={() =>
                                   showTodoHistoryModal(ele?.id)
                                 }
                               >
                                 l
                                 <img src="images/task-history.png" />
                                 Task History
                               </a>
                             </li>
                             <li>
                               <a
                                 className="dropdown-item FolderPermissionId"
                                 href="javascript:void(0);"
                                 onClick={() =>
                                   showTodoDetailsModal(
                                     "todoList",
                                     ele?.id
                                   )
                                 }
                               >
                                 <img src="images/view-po.png" />
                                 View To-Do
                               </a>
                             </li>
                             <li>
                               <a
                                 className="dropdown-item"
                                 href="javascript:void(0);"
                               >
                                 <img src="images/export-to-calendar.png" />
                                 Export to Calender
                                 <img src="images/rightarrow2.png" />
                               </a>
                               <ul className="dropdown-menu submenu">
                                 <li>
                                   <a
                                     onClick={() =>
                                       handleGoogleData(ele)
                                     }
                                     href="javascript:void(0);"
                                     className="dropdown-item"
                                   >
                                     <img src="images/google-calendar.png" />
                                     Google Calendar
                                   </a>
                                 </li>
                                 <li>
                                   <a
                                     onClick={() =>
                                       handleOutlookData(ele)
                                     }
                                     href="javascript:void(0);"
                                     className="dropdown-item"
                                   >
                                     <img src="images/outlook-calendar.png" />
                                     Outlook Calender
                                   </a>
                                 </li>
                                 <li>
                                   <a
                                     onClick={() =>
                                       handleYahooData(ele)
                                     }
                                     href="javascript:void(0);"
                                     className="dropdown-item"
                                   >
                                     <img src="images/email-calendar.png" />
                                     Yahoo Calender
                                   </a>
                                 </li>
                               </ul>
                             </li>
                             <li>
                               <a
                                 className="dropdown-item FolderPermissionId"
                                 onClick={() =>
                                   showMoveTaskModal(
                                     "todoList",
                                     ele?.id
                                   )
                                 }
                               >
                                 <img src="images/move-to-task.png" />
                                 Move to Task
                               </a>
                             </li>
                             <li>
                               <a
                                 className="dropdown-item"
                                 href="javascript:void(0);"
                                 onClick={() =>
                                   showUpdateRemianderModal(
                                     "todoList",
                                     ele?.id
                                   )
                                 }
                               >
                                 <img src="images/set-reminder.png" />
                                 Set Reminder
                               </a>
                             </li>
                           </div>
                         </div>
                       </div>
                       <div className="TodoUserDetails">
                         <p>{ele?.createdAt?.split("T")[0]}</p>
                         <div className="TodoUserIcons">
                           <a
                             href="javascript:void(0);"
                             className="FolderPermissionId"
                             onClick={() =>
                               showUpdateTodoModal(ele)
                             }
                           >
                             <img src="images/todo-edit.png" />
                           </a>
                           <a
                             onClick={() => handleGoogleData(ele)}
                             href="javascript:void(0);"
                           >
                             <img src="images/todo-calendar.png" />
                           </a>
                           <a
                             onClick={() => deleteTodo(ele?.id)}
                             href="javascript:void(0);"
                           >
                             <img src="images/todo-delete.png" />
                           </a>
                         </div>
                       </div>
                     </div>
                    )}
                  </Draggable>
                ))}
            </div>
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
    <Droppable droppableId="droppable2">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef} className="col-4">
          <div className="">
            <div className="TodoBox TodoBoxHeight">
              <div className="TodoBoxHeader">
                <div className="d-flex align-items-center">
                  <h6>In-Progress</h6>
                  {/* <img
                    src="images/eye-icon.png"
                    onClick={()=>handleAllHiddenTask("progress")}
                  /> */}
                </div>
                {/* <img src="images/add.png" onClick={handleAddTaskShow} /> */}
                {/* <figure className="mb-0">
                  <img src="images/task-square.png" />
                </figure> */}
              </div>

              {todoListData?.toDos?.length > 0 &&
                todoListData?.toDos?.map((ele, i) => (
                  <Draggable key={ele.id} draggableId={ele.id.toString()} index={i}>
                    {(provided, snapshot) => (
                     <div   ref={provided.innerRef}
                     {...provided.draggableProps}
                     {...provided.dragHandleProps} className="TodoUser RedLeftBorder">
                     <div className="TodoUserJob">
                       <h6>{ele?.description}</h6>
                       <div className="dropdown">
                         <p data-toggle="dropdown">
                           Job: {ele?.JobId}
                           <span>
                             <img src="images/three-dots.png" />
                           </span>
                         </p>
                         <div
                           className="dropdown-menu"
                           aria-labelledby="dropdownMenuOffset"
                         >
                           <li>
                             <a
                               className="dropdown-item"
                               href="javascript:void(0);"
                               onClick={() =>
                                 showUpdateStaffModal(
                                   ele?.ToDoAssignStaffs?.[0]
                                     ?.UserId,
                                   ele?.id
                                 )
                               }
                             >
                               <img src="images/assign-resources.png" />
                               Assign Staff
                             </a>
                           </li>
                           <li>
                             <a
                               className="dropdown-item FolderPermissionId"
                               href="javascript:void(0);"
                               onClick={() =>
                                 showUpdateTradingModal(
                                   "todoList",
                                   ele?.id,
                                   ele?.AssignTrade?.id,
                                   ele?.AssignTrade?.VendorId,
                                   ele?.AssignTrade?.email,
                                   ele?.AssignTrade?.message,
                                   ele?.AssignTrade?.contact
                                 )
                               }
                             >
                               <img src="images/assign-trade.png" />
                               Assign Trade
                             </a>
                           </li>
                           <li>
                             <a
                               className="dropdown-item FolderPermissionId"
                               href="javascript:void(0);"
                               onClick={() =>
                                 showTodoHistoryModal(ele?.id)
                               }
                             >
                               l
                               <img src="images/task-history.png" />
                               Task History
                             </a>
                           </li>
                           <li>
                             <a
                               className="dropdown-item FolderPermissionId"
                               href="javascript:void(0);"
                               onClick={() =>
                                 showTodoDetailsModal(
                                   "todoList",
                                   ele?.id
                                 )
                               }
                             >
                               <img src="images/view-po.png" />
                               View To-Do
                             </a>
                           </li>
                           <li>
                             <a
                               className="dropdown-item"
                               href="javascript:void(0);"
                             >
                               <img src="images/export-to-calendar.png" />
                               Export to Calender
                               <img src="images/rightarrow2.png" />
                             </a>
                             <ul className="dropdown-menu submenu">
                               <li>
                                 <a
                                   onClick={() =>
                                     handleGoogleData(ele)
                                   }
                                   href="javascript:void(0);"
                                   className="dropdown-item"
                                 >
                                   <img src="images/google-calendar.png" />
                                   Google Calendar
                                 </a>
                               </li>
                               <li>
                                 <a
                                   onClick={() =>
                                     handleOutlookData(ele)
                                   }
                                   href="javascript:void(0);"
                                   className="dropdown-item"
                                 >
                                   <img src="images/outlook-calendar.png" />
                                   Outlook Calender
                                 </a>
                               </li>
                               <li>
                                 <a
                                   onClick={() =>
                                     handleYahooData(ele)
                                   }
                                   href="javascript:void(0);"
                                   className="dropdown-item"
                                 >
                                   <img src="images/email-calendar.png" />
                                   Yahoo Calender
                                 </a>
                               </li>
                             </ul>
                           </li>
                           <li>
                             <a
                               className="dropdown-item FolderPermissionId"
                               onClick={() =>
                                 showMoveTaskModal(
                                   "todoList",
                                   ele?.id
                                 )
                               }
                             >
                               <img src="images/move-to-task.png" />
                               Move to Task
                             </a>
                           </li>
                           <li>
                             <a
                               className="dropdown-item"
                               href="javascript:void(0);"
                               onClick={() =>
                                 showUpdateRemianderModal(
                                   "todoList",
                                   ele?.id
                                 )
                               }
                             >
                               <img src="images/set-reminder.png" />
                               Set Reminder
                             </a>
                           </li>
                         </div>
                       </div>
                     </div>
                     <div className="TodoUserDetails">
                       <p>{ele?.createdAt?.split("T")[0]}</p>
                       <div className="TodoUserIcons">
                         <a
                           href="javascript:void(0);"
                           className="FolderPermissionId"
                           onClick={() =>
                             showUpdateTodoModal(ele)
                           }
                         >
                           <img src="images/todo-edit.png" />
                         </a>
                         <a
                           onClick={() => handleGoogleData(ele)}
                           href="javascript:void(0);"
                         >
                           <img src="images/todo-calendar.png" />
                         </a>
                         <a
                           onClick={() => deleteTodo(ele?.id)}
                           href="javascript:void(0);"
                         >
                           <img src="images/todo-delete.png" />
                         </a>
                       </div>
                     </div>
                   </div>
                    )}
                  </Draggable>
                ))}
            </div>
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  
    <Droppable droppableId="droppable3">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef} className="col-4">
          <div className="">
            <div className="TodoBox TodoBoxHeight">
              <div className="TodoBoxHeader">
                <div className="d-flex align-items-center">
                  <h6>Completed</h6>
                  {/* <img
                    src="images/eye-icon.png"
                    onClick={()=>handleAllHiddenTask("complete")}
                  /> */}
                </div>
                {/* <img src="images/add.png" onClick={handleAddTaskShow} />
                <figure className="mb-0">
                  <img src="images/task-square.png" />
                </figure> */}
              </div>

              {todoListData?.toDos?.length > 0 &&
                todoListData?.toDos?.map((ele, i) => (
                  <Draggable key={ele.id} draggableId={ele?.id?.toString()} index={i}>
                    {(provided, snapshot) => (
                      <div   ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps} className="TodoUser RedLeftBorder">
                      <div className="TodoUserJob">
                        <h6>{ele?.description}</h6>
                        <div className="dropdown">
                          <p data-toggle="dropdown">
                            Job: {ele?.JobId}
                            <span>
                              <img src="images/three-dots.png" />
                            </span>
                          </p>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuOffset"
                          >
                            <li>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                                onClick={() =>
                                  showUpdateStaffModal(
                                    ele?.ToDoAssignStaffs?.[0]
                                      ?.UserId,
                                    ele?.id
                                  )
                                }
                              >
                                <img src="images/assign-resources.png" />
                                Assign Staff
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item FolderPermissionId"
                                href="javascript:void(0);"
                                onClick={() =>
                                  showUpdateTradingModal(
                                    "todoList",
                                    ele?.id,
                                    ele?.AssignTrade?.id,
                                    ele?.AssignTrade?.VendorId,
                                    ele?.AssignTrade?.email,
                                    ele?.AssignTrade?.message,
                                    ele?.AssignTrade?.contact
                                  )
                                }
                              >
                                <img src="images/assign-trade.png" />
                                Assign Trade
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item FolderPermissionId"
                                href="javascript:void(0);"
                                onClick={() =>
                                  showTodoHistoryModal(ele?.id)
                                }
                              >
                                l
                                <img src="images/task-history.png" />
                                Task History
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item FolderPermissionId"
                                href="javascript:void(0);"
                                onClick={() =>
                                  showTodoDetailsModal(
                                    "todoList",
                                    ele?.id
                                  )
                                }
                              >
                                <img src="images/view-po.png" />
                                View To-Do
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                <img src="images/export-to-calendar.png" />
                                Export to Calender
                                <img src="images/rightarrow2.png" />
                              </a>
                              <ul className="dropdown-menu submenu">
                                <li>
                                  <a
                                    onClick={() =>
                                      handleGoogleData(ele)
                                    }
                                    href="javascript:void(0);"
                                    className="dropdown-item"
                                  >
                                    <img src="images/google-calendar.png" />
                                    Google Calendar
                                  </a>
                                </li>
                                <li>
                                  <a
                                    onClick={() =>
                                      handleOutlookData(ele)
                                    }
                                    href="javascript:void(0);"
                                    className="dropdown-item"
                                  >
                                    <img src="images/outlook-calendar.png" />
                                    Outlook Calender
                                  </a>
                                </li>
                                <li>
                                  <a
                                    onClick={() =>
                                      handleYahooData(ele)
                                    }
                                    href="javascript:void(0);"
                                    className="dropdown-item"
                                  >
                                    <img src="images/email-calendar.png" />
                                    Yahoo Calender
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a
                                className="dropdown-item FolderPermissionId"
                                onClick={() =>
                                  showMoveTaskModal(
                                    "todoList",
                                    ele?.id
                                  )
                                }
                              >
                                <img src="images/move-to-task.png" />
                                Move to Task
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                                onClick={() =>
                                  showUpdateRemianderModal(
                                    "todoList",
                                    ele?.id
                                  )
                                }
                              >
                                <img src="images/set-reminder.png" />
                                Set Reminder
                              </a>
                            </li>
                          </div>
                        </div>
                      </div>
                      <div className="TodoUserDetails">
                        <p>{ele?.createdAt?.split("T")[0]}</p>
                        <div className="TodoUserIcons">
                          <a
                            href="javascript:void(0);"
                            className="FolderPermissionId"
                            onClick={() =>
                              showUpdateTodoModal(ele)
                            }
                          >
                            <img src="images/todo-edit.png" />
                          </a>
                          <a
                            onClick={() => handleGoogleData(ele)}
                            href="javascript:void(0);"
                          >
                            <img src="images/todo-calendar.png" />
                          </a>
                          <a
                            onClick={() => deleteTodo(ele?.id)}
                            href="javascript:void(0);"
                          >
                            <img src="images/todo-delete.png" />
                          </a>
                        </div>
                      </div>
                    </div>
                    )}
                  </Draggable>
                ))}
            </div>
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>
                {/* <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="TodoBox TodoBoxHeight">
                      <div className="TodoBoxHeader">
                        <h6>To-do</h6>
                        <img src="images/add.png" onClick={showAddTodoModal} />
                        <figure>
                          <img src="images/task-square.png" />
                        </figure>
                      </div>
                      {loader ? (
                        <>
                          <td>
                            {" "}
                            <Loader />
                          </td>
                        </>
                      ) : (
                        <>
                          {checkArray(todoListData?.toDos) ? (
                            todoListData?.toDos?.map((ele, ind) => {
                              return (
                                <>
                                  <div   ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps} className="TodoUser RedLeftBorder">
                                    <div className="TodoUserJob">
                                      <h6>{ele?.description}</h6>
                                      <div className="dropdown">
                                        <p data-toggle="dropdown">
                                          Job: {ele?.JobId}
                                          <span>
                                            <img src="images/three-dots.png" />
                                          </span>
                                        </p>
                                        <div
                                          className="dropdown-menu"
                                          aria-labelledby="dropdownMenuOffset"
                                        >
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                              onClick={() =>
                                                showUpdateStaffModal(
                                                  ele?.ToDoAssignStaffs?.[0]
                                                    ?.UserId,
                                                  ele?.id
                                                )
                                              }
                                            >
                                              <img src="images/assign-resources.png" />
                                              Assign Staff
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item FolderPermissionId"
                                              href="javascript:void(0);"
                                              onClick={() =>
                                                showUpdateTradingModal(
                                                  "todoList",
                                                  ele?.id,
                                                  ele?.AssignTrade?.id,
                                                  ele?.AssignTrade?.VendorId,
                                                  ele?.AssignTrade?.email,
                                                  ele?.AssignTrade?.message,
                                                  ele?.AssignTrade?.contact
                                                )
                                              }
                                            >
                                              <img src="images/assign-trade.png" />
                                              Assign Trade
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item FolderPermissionId"
                                              href="javascript:void(0);"
                                              onClick={() =>
                                                showTodoHistoryModal(ele?.id)
                                              }
                                            >
                                              l
                                              <img src="images/task-history.png" />
                                              Task History
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item FolderPermissionId"
                                              href="javascript:void(0);"
                                              onClick={() =>
                                                showTodoDetailsModal(
                                                  "todoList",
                                                  ele?.id
                                                )
                                              }
                                            >
                                              <img src="images/view-po.png" />
                                              View To-Do
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/export-to-calendar.png" />
                                              Export to Calender
                                              <img src="images/rightarrow2.png" />
                                            </a>
                                            <ul className="dropdown-menu submenu">
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    handleGoogleData(ele)
                                                  }
                                                  href="javascript:void(0);"
                                                  className="dropdown-item"
                                                >
                                                  <img src="images/google-calendar.png" />
                                                  Google Calendar
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    handleOutlookData(ele)
                                                  }
                                                  href="javascript:void(0);"
                                                  className="dropdown-item"
                                                >
                                                  <img src="images/outlook-calendar.png" />
                                                  Outlook Calender
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    handleYahooData(ele)
                                                  }
                                                  href="javascript:void(0);"
                                                  className="dropdown-item"
                                                >
                                                  <img src="images/email-calendar.png" />
                                                  Yahoo Calender
                                                </a>
                                              </li>
                                            </ul>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item FolderPermissionId"
                                              onClick={() =>
                                                showMoveTaskModal(
                                                  "todoList",
                                                  ele?.id
                                                )
                                              }
                                            >
                                              <img src="images/move-to-task.png" />
                                              Move to Task
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              href="javascript:void(0);"
                                              onClick={() =>
                                                showUpdateRemianderModal(
                                                  "todoList",
                                                  ele?.id
                                                )
                                              }
                                            >
                                              <img src="images/set-reminder.png" />
                                              Set Reminder
                                            </a>
                                          </li>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="TodoUserDetails">
                                      <p>{ele?.createdAt?.split("T")[0]}</p>
                                      <div className="TodoUserIcons">
                                        <a
                                          href="javascript:void(0);"
                                          className="FolderPermissionId"
                                          onClick={() =>
                                            showUpdateTodoModal(ele)
                                          }
                                        >
                                          <img src="images/todo-edit.png" />
                                        </a>
                                        <a
                                          onClick={() => handleGoogleData(ele)}
                                          href="javascript:void(0);"
                                        >
                                          <img src="images/todo-calendar.png" />
                                        </a>
                                        <a
                                          onClick={() => deleteTodo(ele?.id)}
                                          href="javascript:void(0);"
                                        >
                                          <img src="images/todo-delete.png" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <td>
                              {" "}
                              <NoDataFound />
                            </td>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="TodoBox TodoBoxHeight">
                      <div className="TodoBoxHeader">
                        <h6>In-Process</h6>
                        <figure>
                          <img src="images/task-square.png" />
                        </figure>
                      </div>
                      <div className="TodoUser YellowLeftBorder">
                        <div className="TodoUserJob">
                          <h6>Door</h6>
                          <div className="dropdown">
                            <p data-toggle="dropdown">
                              Job: U2-CHI-2B-307
                              <span>
                                <img src="images/three-dots.png" />
                              </span>
                            </p>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuOffset"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#AssignStaffMemberDesktop"
                                >
                                  <img src="images/assign-resources.png" />
                                  Assign Staff
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item FolderPermissionId"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#AssignTradeDesktop"
                                >
                                  <img src="images/assign-trade.png" />
                                  Assign Trade
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item FolderPermissionId"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#TaskHistory"
                                >
                                  <img src="images/task-history.png" />
                                  Task History
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item FolderPermissionId"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#OnesTaskDetails"
                                >
                                  <img src="images/view-po.png" />
                                  View To-Do
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/export-to-calendar.png" />
                                  Export to Calender
                                  <img src="images/rightarrow2.png" />
                                </a>
                                <ul className="dropdown-menu submenu">
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/google-calendar.png" />
                                      Google Calendar
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/outlook-calendar.png" />
                                      Outlook Calender
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/email-calendar.png" />
                                      Email Calender
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item FolderPermissionId"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#Todo1MoveTask"
                                >
                                  <img src="images/move-to-task.png" />
                                  Move to Task
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#ReminderModalToggle"
                                >
                                  <img src="images/set-reminder.png" />
                                  Set Reminder
                                </a>
                              </li>
                            </div>
                          </div>
                        </div>
                        <div className="TodoUserDetails">
                          <p>05 May 2023</p>
                          <div className="TodoUserIcons">
                            <a
                              href="javascript:void(0);"
                              data-toggle="modal"
                              data-target="#TaskDetails"
                            >
                              <img src="images/esclamation.png" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="TodoUser YellowLeftBorder">
                        <div className="TodoUserJob">
                          <h6>Door</h6>
                          <div className="dropdown">
                            <p data-toggle="dropdown">
                              Job: U2-CHI-2B-307
                              <span>
                                <img src="images/three-dots.png" />
                              </span>
                            </p>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuOffset"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#AssignStaffMemberDesktop"
                                >
                                  <img src="images/assign-resources.png" />
                                  Assign Staff
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#AssignTradeDesktop"
                                >
                                  <img src="images/assign-trade.png" />
                                  Assign Trade
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#TaskHistory"
                                >
                                  <img src="images/task-history.png" />
                                  Task History
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#OnesTaskDetails"
                                >
                                  <img src="images/view-po.png" />
                                  View To-Do
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/export-to-calendar.png" />
                                  Export to Calender
                                  <img src="images/rightarrow2.png" />
                                </a>
                                <ul className="dropdown-menu submenu">
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/google-calendar.png" />
                                      Google Calendar
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/outlook-calendar.png" />
                                      Outlook Calender
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/email-calendar.png" />
                                      Email Calender
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item FolderPermissionId"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#Todo1MoveTask"
                                >
                                  <img src="images/move-to-task.png" />
                                  Move to Task
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#ReminderModalToggle"
                                >
                                  <img src="images/set-reminder.png" />
                                  Set Reminder
                                </a>
                              </li>
                            </div>
                          </div>
                        </div>
                        <div className="TodoUserDetails">
                          <p>05 May 2023</p>
                          <div className="TodoUserIcons">
                            <a
                              href="javascript:void(0);"
                              data-toggle="modal"
                              data-target="#TaskDetails"
                            >
                              <img src="images/esclamation.png" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="TodoBox TodoBoxHeight">
                      <div className="TodoBoxHeader TodoBoxHeaderComplete">
                        <h6>Completed</h6>
                        <img src="images/eye-icon.png" alt="" />
                        <figure>
                          <img src="images/task-square.png" />
                        </figure>
                      </div>
                      <div className="TodoUser GreenLeftBorder">
                        <div className="TodoUserJob">
                          <h6>Door</h6>
                          <div className="dropdown">
                            <p data-toggle="dropdown">
                              Job: U2-CHI-2B-307
                              <span>
                                <img src="images/three-dots.png" />
                              </span>
                            </p>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuOffset"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#AssignStaffMemberDesktop"
                                >
                                  <img src="images/assign-resources.png" />
                                  Assign Staff
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#AssignTradeDesktop"
                                >
                                  <img src="images/assign-trade.png" />
                                  Assign Trade
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#TaskHistory"
                                >
                                  <img src="images/task-history.png" />
                                  Task History
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#OnesTaskDetails"
                                >
                                  <img src="images/view-po.png" />
                                  View To-Do
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/export-to-calendar.png" />
                                  Export to Calender
                                  <img src="images/rightarrow2.png" />
                                </a>
                                <ul className="dropdown-menu submenu">
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/google-calendar.png" />
                                      Google Calendar
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/outlook-calendar.png" />
                                      Outlook Calender
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/email-calendar.png" />
                                      Email Calender
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item FolderPermissionId"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#Todo1MoveTask"
                                >
                                  <img src="images/move-to-task.png" />
                                  Move to Task
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#ReminderModalToggle"
                                >
                                  <img src="images/set-reminder.png" />
                                  Set Reminder
                                </a>
                              </li>
                            </div>
                          </div>
                        </div>
                        <div className="TodoUserDetails">
                          <p>05 May 2023</p>
                          <div className="TodoUserIcons">
                            <a
                              href="javascript:void(0);"
                              data-toggle="modal"
                              data-target="#TaskDetails"
                            >
                              <img src="images/esclamation.png" />
                            </a>
                            <a href="javascript:void(0);">
                              <img src="images/close-eyes.png" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="TodoUser GreenLeftBorder">
                        <div className="TodoUserJob">
                          <h6>Door</h6>
                          <div className="dropdown">
                            <p data-toggle="dropdown">
                              Job: U2-CHI-2B-307
                              <span>
                                <img src="images/three-dots.png" />
                              </span>
                            </p>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuOffset"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#AssignStaffMemberDesktop"
                                >
                                  <img src="images/assign-resources.png" />
                                  Assign Staff
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#AssignTradeDesktop"
                                >
                                  <img src="images/assign-trade.png" />
                                  Assign Trade
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#TaskHistory"
                                >
                                  <img src="images/task-history.png" />
                                  Task History
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#OnesTaskDetails"
                                >
                                  <img src="images/view-po.png" />
                                  View To-Do
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/export-to-calendar.png" />
                                  Export to Calender
                                  <img src="images/rightarrow2.png" />
                                </a>
                                <ul className="dropdown-menu submenu">
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/google-calendar.png" />
                                      Google Calendar
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/outlook-calendar.png" />
                                      Outlook Calender
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/email-calendar.png" />
                                      Email Calender
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item FolderPermissionId"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#Todo1MoveTask"
                                >
                                  <img src="images/move-to-task.png" />
                                  Move to Task
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#ReminderModalToggle"
                                >
                                  <img src="images/set-reminder.png" />
                                  Set Reminder
                                </a>
                              </li>
                            </div>
                          </div>
                        </div>
                        <div className="TodoUserDetails">
                          <p>05 May 2023</p>
                          <div className="TodoUserIcons">
                            <a
                              href="javascript:void(0);"
                              data-toggle="modal"
                              data-target="#TaskDetails"
                            >
                              <img src="images/esclamation.png" />
                            </a>
                            <a href="javascript:void(0);">
                              <img src="images/close-eyes.png" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              </div>
            </div>
            <div className="tab-pane fade" id="ShowAllJobsTabs">
              <div className="ShowAllJobsTable">
                <div className="Tableheader d-flex flex-lg-row align-items-lg-center flex-wrap d-flex flex-column align-items-start">
                  <h6>System Tasks</h6>
                  <div className="d-flex flex-wrap flex-md-row flex-column align-items-md-center align-items-start">
                    <div className="d-flex mr-3 Button3">
                      <button onClick={() => dispatch(TodoListAction("Created"))} className="BrandBtn">
                        <img src="images/red-close-eye.png" />
                        Show Created
                      </button>
                      <button onClick={() => dispatch(TodoListAction("Cancelled"))} className="BrandBtn">
                        <img src="images/cancel.png" />
                        Show Cancelled
                      </button>
                      <button onClick={() => dispatch(TodoListAction("Moved"))} className="BrandBtn">
                        <img src="images/arrow-four.png" />
                        Show Moved
                      </button>
                    </div>
                    <div className="NewActions">
                      <a href="javascript:void(0);">
                        <img src="images/icon-64.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/icon-53.png" />
                      </a>
                      <DownloadTableExcel
                  filename="Todo List"
                  sheet="Task List"
                  currentTableRef={todoListRef.current}
                >
                  <a href="javascript:void(0);">
                    <img src="images/download.png" />
                  </a>
                </DownloadTableExcel>
                      {/* <a href="javascript:void(0);">
                        <img src="images/setting.png" />
                      </a> */}
                    </div>
                  </div>
                </div>
                <div className="TableList NewTableList BlueHeader TableHeightScrollTodo TableHeight500">
                  <table ref = {todoListRef}>
                    <thead>
                      <tr>
                        <th>Job Id</th>
                        <th>To-Do #</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Date Cereated</th>
                        <th>Date Assign</th>
                        <th>Target Date</th>
                        <th>Date Completed</th>
                        <th>Age (Days)</th>
                        <th>Assign member</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loader ? (
                        <>
                          <td>
                            {" "}
                            <Loader />
                          </td>
                        </>
                      ) : (
                        <>
                          {checkArray(todoListData?.toDos) ? (
                            todoListData?.toDos?.map((ele, ind) => {
                              return (
                                <>
                                  <tr className= {ind % 2 ==0 ?"BorderCyan" : "BorderDarkYellow" } >
                                    <td>{ele?.JobId}</td>
                                    <td>{ele?.id}</td>
                                    <td>{ele?.description}</td>
                                    <td>{ele?.toDoStatus}</td>
                                    <td>{ele?.createdAt?.split("T")[0]}</td>
                                    <td>{ele?.assignDate?.split("T")[0]}</td>
                                    <td>{ele?.targetDate?.split("T")[0]}</td>
                                    <td>{ele?.completedDate?.split("T")[0]}</td>
                                    
                                    <td>11</td>
                                    <td>{ele?.ToDoAssignStaffs?.[0]?.email}</td>
                                    <td>
                                      <div className="TableThreeDots">
                                        <div className="dropdown">
                                          <p data-toggle="dropdown">
                                            <span>
                                              <img src="images/three-dots.png" />
                                            </span>
                                          </p>
                                          <div
                                            className="dropdown-menu ChangeOrderDropdown"
                                            aria-labelledby="dropdownMenuOffset"
                                          >
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="javascript:void(0);"
                                                 onClick={() =>
                                                  showUpdateStaffModal(
                                                    ele?.ToDoAssignStaffs?.[0]?.UserId,
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                <img src="images/assign-resources.png" />
                                                Assign Resources
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="javascript:void(0);"
                                                  onClick= {() =>showTodoDetailsModal(
                                                    "todoList",
                                                    ele?.id
                                                  )}
                                              >
                                                <img src="images/view-po.png" />
                                                View To-Do
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                            
                                                onClick={() =>
                                                  showTodoHistoryModal(ele?.id)
                                                }
                                              >
                                                <img src="images/task-history.png" />
                                                To-Do History
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="javascript:void(0);"
                                                
                                                style={{
                                                  borderBottom:
                                                    "none!important",
                                                }}
                                              >
                                                <img src="images/export-to-calendar.png" />
                                                Export to Calender
                                                <img src="images/rightarrow2.png" />
                                              </a>
                                              <ul className="dropdown-menu submenu">
                                                <li>
                                                  <a
                                                    href="javascript:void(0);"
                                                    className="dropdown-item"
                                                    onClick={() =>handleGoogleData(ele)}
                                                  >
                                                    <img src="images/google-calendar.png" />
                                                    Google Calendar
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    href="javascript:void(0);"
                                                    className="dropdown-item"
                                                    onClick={() =>handleOutlookData(ele)}
                                                  >
                                                    <img src="images/outlook-calendar.png" />
                                                    Outlook Calender
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    href="javascript:void(0);"
                                                    className="dropdown-item"
                                                    onClick={() => handleYahooData(ele)}
                                                  >
                                                    <img src="images/email-calendar.png" />
                                                    Yahoo Calender
                                                  </a>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="javascript:void(0);"
                                               onClick={() => showMoveTaskModal("todoList",
                                               ele?.id)}
                                              >
                                                <img src="images/move-to-task.png" />
                                                Move to To-Do
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                 onClick={() =>showUpdateRemianderModal("todoList",ele?.id)}
                                              >
                                                <img src="images/set-reminder.png" />
                                                Set Reminder
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                data-target="#Todo2GiveAccessToMyTasksDesktop"
                                              >
                                                <img src="images/share.png" />
                                                Give access to my To-Do
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                data-target="#Todo2RequestAccessToUsersTasksListsDesktop"
                                              >
                                                <img src="images/requests-access.png" />
                                                Request access to user's To-Do
                                              </a>
                                            </li>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          ) : (
                            <td>
                              {" "}
                              <NoDataFound />
                            </td>
                          )}
                        </>
                      )}
{/* BorderDarkYellow */}
{/* BorderBlue  */}
                     
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="CalendarTabs">
              <FullCalendar
                ref={calendarRef}
                // weekends={true}
                events={events}
                eventContent={renderEventContent}
                headerToolbar={{
                  start: "today prev next",
                  center: "title",
                  end: "dayGridMonth dayGridWeek dayGridDay",
                }}
                plugins={[dayGridPlugin]}
                views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
                initialView="dayGridDay"
                datesSet={(info) => {
                  handleViewChange(info.view.type);
                  handleDateSet(info);
                }}
              />
              {/* <FullCalendar
            plugins={[dayGridPlugin]}
            initialView='dayGridMonth'
            weekends={true}
            events={events}
            eventContent={renderEventContent}
            headerToolbar={{
              right: 'today,week,month',
              center: 'title',
              left: 'dayGridMonth,dayGridWeek'
            }}
            datesSet={(info) => {
              handleViewChange(info.view.type);
              handleDateSet(info);
            }}
          /> */}
              ;
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={AddTodoModal}
        onHide={hideAddTodoModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideAddTodoModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Add New To-Do</h4>
            </div>
            <div className="ModalTitleHeading">
              <h2>To-Do Information</h2>
            </div>
            <div className="FolderForm">
              <form>
                <div className="form-group">
                  <h6>Job</h6>
                  <div className="Categories Categories1">
                    <div className="Categories_all Categories_all1">
                      {/* <span>Select a Job</span> */}
                      <select name="JobId" value={JobId} onChange={handleInput}>
                        <option>Select Job</option>
                        <option value="1">Static Job</option>
                      </select>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>
                        <input type="text" placeholder="-Search" />
                        <span className="Search">
                          <img src="images/search.png" />
                        </span>
                      </li>
                      <li>- List of Jobs already filtered</li>
                    </ul>
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {todoError?.JobIdError}
                  </span>
                </div>
                <div className="form-group">
                  <h6>
                    Schedule related To-Do?<span className="required">*</span>
                  </h6>
                  <label className="CheckBox mr-4">
                    {" "}
                    Yes
                    <input
                      type="radio"
                      name="scheRelatedToDo"
                      value={true}
                      // checked={scheRelatedToDo}
                      onChange={(e) => handleCheckbox(e)}
                    />
                    <span className="checkmark" />
                  </label>
                  <label className="CheckBox">
                    {" "}
                    No
                    <input
                      type="radio"
                      name="scheRelatedToDo"
                      value={false}
                      // checked={!scheRelatedToDo}
                      onChange={(e) => handleCheckbox(e)}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="form-group">
                  <h6>Schedule's Task</h6>
                  <div className="Categories Categories2">
                    <div className="Categories_all Categories_all2">
                      <select
                        name="TaskId"
                        value={TaskId}
                        onChange={handleInput}
                      >
                        <option>Select Schedule's Task</option>
                        <option value="1">Schedule's Task</option>
                      </select>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>
                        <input type="text" placeholder="-Search" />
                        <span className="Search">
                          <img src="images/search.png" />
                        </span>
                      </li>
                      <li>- List of Schedule tasks for job</li>
                    </ul>
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {todoError?.TaskIdError}
                  </span>
                </div>
                <div className="form-group">
                  <h6>
                    Description <span className="required">*</span>
                  </h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Write here ....."
                    name="description"
                    value={description}
                    onChange={handleInput}
                  />
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {todoError?.descriptionError}
                  </span>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>Target Date</h6>
                      <input
                        type="date"
                        className="form-control"
                        name="targetDate"
                        value={targetDate}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {todoError?.targetDateError}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="StaffMemberArea StaffMemberAreaConst">
                  <aside>
                    <h6>Add Supporting Document</h6>
                    <div className="StaffName">
                      <img src="images/document-support.png" />

                      <h6
                        className="CursorPointer FolderPermissionId"
                        onClick={() => showSupportingDocsModal("addTodo")}
                      >
                        Add Supporting Document
                      </h6>
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {todoError?.supportingDocFilesError}
                    </span>
                  </aside>
                </div>
                <div className="StaffMemberArea StaffMemberAreaConst">
                  <aside>
                    <h6>Add Notes</h6>
                    <div className="StaffName">
                      <img src="images/add-notes.png" />
                      <h6
                        className="CursorPointer FolderPermissionId"
                        onClick={() => showAddNotesModal("addTodo")}
                      >
                        Add Notes
                      </h6>
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {todoError?.noteError}
                    </span>
                  </aside>
                  <aside>
                    <h6>Assign Trade</h6>
                    <div className="StaffName">
                      <img src="images/assign-trade.png" />
                      {/* <div className="Count">3</div> */}
                      <h6
                        className="FolderPermissionId"
                        onClick={() => showAddTradingModal("addTodo")}
                      >
                        Assign
                      </h6>
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {todoError?.tradeError}
                    </span>
                  </aside>
                </div>
                <div className="StaffMemberArea StaffMemberAreaConst">
                  <aside>
                    <h6>Assign Staff Member</h6>
                    <div className="StaffName">
                      <img src="images/member.png" />
                      {/* <div className="Count">3</div> */}
                      <h6
                        className="FolderPermissionId"
                        onClick={() => showAssignSaleModal("addTodo")}
                      >
                        Assign
                      </h6>
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {todoError?.assignStaffIdError}
                    </span>
                  </aside>
                  <aside>
                    <h6>Set Reminder</h6>
                    <div className="StaffName">
                      <img src="images/timer-red.png" />
                      <h6
                        className="FolderPermissionId"
                        onClick={() => showAddRemianderModal("addTodo")}
                      >
                        Reminder
                      </h6>
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {todoError?.reminderDateError}
                    </span>
                  </aside>
                </div>
                <div className="Button text-center mt-5">
                  <button className="Create" onClick={addTodoFunc}>
                    Create Todo
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* SUPPORTINGSHOW */}
      <Modal
        show={supportingDocsModal}
        onHide={hideSupportingDocsModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSupportingDocsModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Add New Supporting Docs</h4>
            </div>
            <div className="JobTitleArea">
              <h3>Supporting Documents #2</h3>
              <div className="JobBox">
                <h6>
                  <span>Job:</span> Unit3-CHI-2B-315
                </h6>
              </div>
              <div className="JobBox">
                <h6>
                  <span>Origin</span> #185
                </h6>
              </div>
              <div className="JobBox">
                <h6>
                  <span>Item:</span> #143
                </h6>
              </div>
            </div>
            <div className="form-group">
              <h6>Select Files</h6>
              <div className="Upload">
                <span>-Drag and Drop Files or click to select</span>
                <input
                  type="file"
                  name="supportingDocFiles"
                  onChange={handleInputFile}
                />
                {supportingDocFiles?.type === "image/png" ? (
                  <img src={URL.createObjectURL(supportingDocFiles)} />
                ) : (
                  ""
                )}
                {/* <img src={URL.createObjectURL(e.target.files[0])}/> */}
              </div>
              <span style={{ color: "red", fontSize: "14px" }}>
                {supportingDocsError}
              </span>
            </div>

            <div className="Button mt-3">
              <button className="Create" onClick={addSupportingDocsFunc}>
                Add Documents
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={addNotesModal}
        onHide={hideAddNotesModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAddNotesModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Add New Note</h4>
            </div>
            <h6>Note #1</h6>
            <div className="JobTitleArea">
              <div className="JobBox">
                <h6>
                  <span>Job:</span> UUnit3-CHI-2B-315
                </h6>
              </div>
              <div className="JobBox mb-3">
                <h6>
                  <span>Origin:</span> #185
                </h6>
              </div>
              <h6>Type</h6>
              <div className="FolderForm">
                <div className="form-group mb-4">
                  <label className="Radio">
                    Text
                    <input
                      type="radio"
                      name="noteType"
                      defaultValue="text"
                      onChange={handleCheckbox}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="form-group">
                  <label className="Radio">
                    File
                    <input
                      type="radio"
                      name="noteType"
                      defaultValue="file"
                      onChange={handleCheckbox}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                {noteType === "file" ? (
                  <>
                    <div className="file RadioText">
                      <div className="form-group">
                        <h6>Select Files</h6>
                        <div className="Upload">
                          <span>-Drag and Drop Files or click to select</span>
                          <input
                            type="file"
                            name="file"
                            onChange={handleInputFile}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text RadioText">
                      <div className="form-group">
                        <h6>Message </h6>
                        {/* (Max 1000 chars limit) */}
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Write here ....."
                          name="notesMessage"
                          value={notesMessage}
                          onChange={handleInput}
                        />
                        {/* <small className="form-text text-right">
                      985 characters remaining
                    </small> */}
                      </div>
                    </div>
                  </>
                )}

                <div className="Button d-flex justify-content-between mt-4">
                  <button className="Create" onClick={hideAddNotesModal}>
                    Add Note
                  </button>
                  <button className="Outline" onClick={hideAddNotesModal}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={tradeModal}
        onHide={hideAddTradingModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAddTradingModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleHeading">
              <h2>Trade Information</h2>
            </div>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Assign Trade</h4>
              </div>
              <div className="ModalTitleHeading">
                <h2>Trade Information</h2>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>
                        Sub Trade Name<span className="required">*</span>
                      </h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="VendorId"
                          value={VendorId}
                          onChange={handleInput}
                        >
                          <option>Select Sub Trade</option>
                          {checkArray(vendorListData?.VendorList) &&
                            vendorListData?.VendorList?.map((ele, i) => {
                              return (
                                <>
                                  <option value={ele?.id}>
                                    {ele?.vendorName}
                                  </option>
                                </>
                              );
                            })}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {tradeError?.VenderIdError}
                      </span>
                    </div>
                  </div>

                  <div className="form-group">
                    <h6>Contact</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Contact"
                      name="contact"
                      value={contact}
                      onChange={handleInputNumber}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {tradeError?.contactError}
                    </span>
                  </div>
                  <div className="form-group">
                    <h6>Email</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Email"
                      name="email"
                      value={email}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {tradeError?.emailError}
                    </span>
                  </div>
                  <div className="form-group">
                    <h6>Message</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Write here ..."
                      name="message"
                      value={message}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {tradeError?.messageError}
                    </span>
                  </div>
                  <div className="Button text-center mt-5">
                    <button className="Create" onClick={CreateTradeFun}>
                      Create Trade
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={reminderModal}
        onHide={hideAddRemianderModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAddRemianderModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Reminder</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>
                  Weeks <span className="required"></span>
                </h6>
                <div className="CommonSelectBox w-100">
                  <select
                    disabled={day}
                    name="week"
                    value={week}
                    onChange={handleInput}
                  >
                    <option value="">
                      Select amount of weeks for reminder
                    </option>
                    <option value="7">1</option>
                    <option value="14">2</option>
                    <option value="21">3</option>
                    <option value="28">4</option>
                  </select>
                </div>
                {/* </div> */}
              </div>

              <div className="form-group">
                <h6>
                  Days <span className="required"></span>
                </h6>
                <div className="CommonSelectBox w-100">
                  <select
                    disabled={week}
                    name="day"
                    value={day}
                    onChange={handleInput}
                  >
                    <option value="">Select amount of days for reminder</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </select>
                </div>
              </div>
              <div className="Button text-center mt-5">
                <button className="Create" onClick={hideAddRemianderModal}>
                  Set Reminder
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={updateReminaderModal}
        onHide={hideUpdateRemianderModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideUpdateRemianderModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Reminder</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>
                  Weeks <span className="required"></span>
                </h6>
                <div className="CommonSelectBox w-100">
                  <select
                    disabled={day}
                    name="week"
                    value={week}
                    onChange={handleInput}
                  >
                    <option value="">
                      Select amount of weeks for reminder
                    </option>
                    <option value="7">1</option>
                    <option value="14">2</option>
                    <option value="21">3</option>
                    <option value="28">4</option>
                  </select>
                </div>
                {/* </div> */}
              </div>

              <div className="form-group">
                <h6>
                  Days <span className="required"></span>
                </h6>
                <div className="CommonSelectBox w-100">
                  <select
                    disabled={week}
                    name="day"
                    value={day}
                    onChange={handleInput}
                  >
                    <option value="">Select amount of days for reminder</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </select>
                </div>
              </div>
              <div className="Button text-center mt-5">
                <button className="Create" onClick={updateRemianderFun}>
                  Set Reminder
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* VIEWTODODETAILS  */}

      <Modal
        show={todoDetailsModal}
        onHide={hideTodoDetailsModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideTodoDetailsModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>View To-Do</h4>
            </div>
            <div className="ModalTitleHeading">
              <h2>To-Do #154</h2>
            </div>
            <div className="FolderForm">
              <form>
                <div className="form-group">
                  <h6>Job</h6>
                  <p className="ml-0">{todoDetailsData?.toDo?.JobId}</p>
                </div>
                <div className="form-group">
                  <h6>
                    Schedule related To-Do?<span className="required">*</span>
                  </h6>
                  <p className="ml-0">
                    {todoDetailsData?.toDo?.scheRelatedToDo ? "Yes" : "No"}
                  </p>
                </div>
                <div className="form-group">
                  <h6>Schedule's Task</h6>
                  <p className="TextBlue2 ml-0">1.3.1.3</p>
                </div>
                <div className="form-group">
                  <h6>
                    Description <span className="required">*</span>
                  </h6>
                  <h6 className="fw-400">
                    {todoDetailsData?.toDo?.description}
                  </h6>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>Date Created</h6>
                      <p className="ml-0">
                        {todoDetailsData?.toDo?.createdAt?.split("T")[0]}
                      </p>
                    </div>
                    <div className="form-group">
                      <h6>Target Date</h6>
                      <p className="ml-0">
                        {todoDetailsData?.toDo?.targetDate?.split("T")[0]}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>Date Assigned</h6>

                      <p className="ml-0">
                        {todoDetailsData?.toDo?.assignDate?.split("T")[0]}
                      </p>
                    </div>
                    <div className="form-group">
                      <h6>Date Completed</h6>
                      <p className="ml-0">
                        {todoDetailsData?.toDo?.completedDate?.split("T")[0]}
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="StaffMemberArea StaffMemberAreaConst">
        <aside>
          <h6>Add Supporting Document</h6>
          <div className="StaffName">
            <img src="images/document-support.png" />
            <div
              className="Count FolderPermissionId"
              data-toggle="modal"
              data-target="#OnesSupportingDocumentList3"
              data-dismiss="modal"
            >
              3
            </div>
            <h6
              className="CursorPointer FolderPermissionId"
              data-toggle="modal"
              data-target="#OnesAddNewSupportingDocs4"
              data-dismiss="modal"
            >
              Add Supporting Document
            </h6>
          </div>
        </aside>
      </div>
      <div className="StaffMemberArea StaffMemberAreaConst">
        <aside>
          <h6>Add Notes</h6>
          <div className="StaffName">
            <img src="images/add-notes.png" />
            <div
              className="Count FolderPermissionId"
              data-toggle="modal"
              data-target="#OnesNotesList3"
              data-dismiss="modal"
            >
              3
            </div>
            <h6
              className="FolderPermissionId"
              data-toggle="modal"
              data-target="#OnesNoteAdd4"
              data-dismiss="modal"
            >
              Add Notes
            </h6>
          </div>
        </aside>
        <aside>
          <h6>Assign Trade</h6>
          <div
            className="StaffName"
            data-toggle="modal"
            data-target="#OnesTaskDetailsAssignTrade"
            data-dismiss="modal"
          >
            <img src="images/assign-trade.png" />
            <div className="Count">3</div>
            <h6 className="FolderPermissionId">Assign</h6>
          </div>
        </aside>
      </div>
      <div className="StaffMemberArea StaffMemberAreaConst">
        <aside>
          <h6>Assign Staff Member</h6>
          <div
            className="StaffName"
            data-toggle="modal"
            data-target="#OnesTaskDetailsAssignStaffMember"
            data-dismiss="modal"
          >
            <img src="images/member.png" />
            <div className="Count">3</div>
            <h6 className="FolderPermissionId">Assign</h6>
          </div>
        </aside>
      </div>
      <div className="Button text-center mt-5">
        <button className="Outline ClosePermissionId" data-dismiss="modal">
          Close
        </button>
      </div>
      <div className="DiscussionArea">
        <div className="DiscussionTitle">
          <h4>Discussion</h4>
          <div className="DiscussionChat">
            <img src="images/down.png" className="DiscussionIcon" />
            <a href="#">
              <img src="images/pdf2.png" />
            </a>
          </div>
        </div>
        <div className="DiscussionHideArea" style={{ display: "block" }}>
          <div className="DiscussionBox">
            <span>FM</span>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Add a Comment ....."
              />
              <div className="DiscussionOverlay">
                <a href="" className="CancelChat">
                  <img src="images/plus-cancel1.png" />
                </a>
                <a href="" className="SendChat">
                  <img src="images/forwardbutton.png" />
                </a>
              </div>
            </div>
          </div>
          <div className="DiscussionBox">
            <span>TH</span>
            <div className="DiscussionRight">
              <h6>Inspections are delayed</h6>
              <small>04/19/2023, at 02:47 pm</small>
            </div>
          </div>
          <div className="ReplyArea">
            <a href="javascript:void(0);">Reply</a>
            <div className="DiscussionBox">
              <span>TH</span>
              <div className="DiscussionRight">
                <h6>Inspections are delayed</h6>
                <small>04/19/2023, at 02:47 pm</small>
              </div>
            </div>
            <div className="DiscussionBox">
              <span>TH</span>
              <div className="DiscussionRight">
                <h6>Inspections are delayed</h6>
                <small>04/19/2023, at 02:47 pm</small>
              </div>
            </div>
          </div>
        </div>
      </div> */}
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={todoHistoryModal}
        onHide={hideTodoHistoryModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideTodoHistoryModal}
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Todo History</h4>
            </div>
            <div class="ModalTitleHeading">
              {/* <h2>Task #112</h2> */}
              <div class="Actions">
                <a href="javascript:void(0);">
                  <img src="images/pdf.png" />
                </a>
                <DownloadTableExcel
                  filename="Task History List"
                  sheet="Task History"
                  currentTableRef={todoHistoryRef.current}
                >
                  <a href="javascript:void(0);">
                    <img src="images/download.png" />
                  </a>
                </DownloadTableExcel>
              </div>
            </div>
            <div class="TableList TableHeightScroll">
              <table ref={todoHistoryRef}>
                <thead>
                  <tr>
                    <th>Action Taken</th>
                    <th>Date</th>
                    <th>Performed By</th>
                    <th>Assigned User</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <>
                      <td>
                        {" "}
                        <Loader />
                      </td>
                    </>
                  ) : (
                    <>
                      {checkArray(todoHistoryListData?.toDos) ? (
                        todoHistoryListData?.toDos?.map((ele, ind) => {
                          return (
                            <>
                              <tr>
                                <td>Created</td>
                                <td>02/05/2023 02:30 PM</td>
                                <td>John Doe</td>
                                <td>Jane Williams</td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <td>
                          {" "}
                          <NoDataFound />
                        </td>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={SupportingDocsListModal}
        onHide={hideSupportingDocsListModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSupportingDocsListModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Supporting Documents List</h4>
            </div>
            <div className="JobTitleArea">
              <div className="JobBox">
                <h6>
                  <span>Job:</span> UUnit3-CHI-2B-315
                </h6>
                <div>
                  <a
                    href="javascript:void(0);"
                    className="FolderPermissionId"
                    onClick={() =>
                      showSupportingDocsModal("supportingDocsContract", modalId)
                    }
                  >
                    <img src="images/notebook.png" />
                  </a>
                </div>
              </div>
              {/* <div className="JobBox">
                <h6>
                  <span className="text-red">Origin</span> #185
                </h6>
              </div>
              <div className="JobBox">
                <h6>
                  <span className="text-red">Item:</span> #143
                </h6>
              </div> */}
            </div>
            <div className="NotesAreaMain">
              <div className="NotesArea mt-4">
                <h6>Supporting Documents List</h6>
                <div>
                  <a href="javascript:void(0);">
                    <img src="images/pdf.png" />
                  </a>
                  <DownloadTableExcel
                    filename="Supporting document List"
                    sheet="Vendors"
                    currentTableRef={supportingDocsRef.current}
                  >
                    <button href="jacascript:void(0);">
                      <img src="images/download.png" />
                    </button>
                  </DownloadTableExcel>
                  {/* <a href="javascript:void(0);">
                    <img src="images/setting.png" />
                  </a> */}
                </div>
              </div>
              <div className="TableList">
                <table ref={supportingDocsRef}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Sent by</th>
                      <th>Date</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {loader ? (
                      <>
                        <td>
                          {" "}
                          <Loader />
                        </td>
                      </>
                    ) : (
                      <>
                        {checkArray(supprtingDocsListData?.supportDocs) ? (
                          supprtingDocsListData?.supportDocs?.map(
                            (ele, ind) => {
                              return (
                                <> 
                                  <tr>
                                    <td>{ind + 1}</td>
                                    <td>{ele?.sendBy}</td>
                                    <td>{ele?.createdAt?.split("T")[0]}</td>
                                    <td className="Inspection">
                                      <a href={ele?.docUrl}>Docs</a>
                                    </td>
                                    <td>
                                      <div className="Actions">
                                        <a
                                          href="javascript:void(0);"
                                          className="FolderPermissionId"
                                          // onClick={() =>
                                          //   showEditSupportingModal(
                                          //     "supportingList",
                                          //     ele?.id
                                          //   )
                                          // }
                                        >
                                          <img src="images/Action-1.png" />
                                        </a>
                                        <a
                                          // onClick={() =>
                                          //   deleteSupportingDocs(ele?.id)
                                          // }
                                          href="javascript:void(0);"
                                        >
                                          <img src="images/delete.png" />
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                               </>
                               );
                            }
                          )
                        ) : (
                          <td>
                            {" "}
                            <NoDataFound />
                          </td>
                        )}
                      </>
                    )} */}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="Button mt-5">
              {/* <button
                className="Create FolderPermissionId"
                onClick={() => showSupportingDocsModal("supportingList")}
              >
                Add New Supporting Docs
              </button> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={updateAssignTradeModal}
        onHide={hideUpdateTradingModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideUpdateTradingModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Assign Trade</h4>
            </div>
            <div className="ModalTitleHeading">
              <h2>Trade Information</h2>
            </div>
            <div className="FolderForm">
              <form>
                <div className="form-group">
                  <div className="Categories Categories2 mb-4">
                    <h6>
                      Sub Trade Name<span className="required">*</span>
                    </h6>
                    <div className="Categories_all Categories_all2">
                      <select
                        name="VendorId"
                        value={VendorId}
                        onChange={handleInput}
                      >
                        <option>Select Sub Trade</option>
                        {checkArray(vendorListData?.VendorList) &&
                          vendorListData?.VendorList?.map((ele, i) => {
                            return (
                              <>
                                <option value={ele?.id}>
                                  {ele?.vendorName}
                                </option>
                              </>
                            );
                          })}
                      </select>
                      <img src="images/down.png" />
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {tradeError?.VenderIdError}
                    </span>
                  </div>
                </div>

                <div className="form-group">
                  <h6>Contact</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Contact"
                    name="contact"
                    value={contact}
                    onChange={handleInputNumber}
                  />
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {tradeError?.contactError}
                  </span>
                </div>
                <div className="form-group">
                  <h6>Email</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Email"
                    name="email"
                    value={email}
                    onChange={handleInput}
                  />
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {tradeError?.emailError}
                  </span>
                </div>
                <div className="form-group">
                  <h6>Message</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Write here ..."
                    name="message"
                    value={message}
                    onChange={handleInput}
                  />
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {tradeError?.messageError}
                  </span>
                </div>
                <div className="Button text-center mt-5">
                  <button className="Create" onClick={UpdateTradeFun}>
                    Update Trade
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={moveTaskModal}
        onHide={hideMoveTaskModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideMoveTaskModal}
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Move Task</h4>
            </div>
            <div class="FolderForm">
              <h3>Task #112</h3>
              <h6 class="mb-4">Job: CHI-2B-345</h6>
              <form>
                <div class="form-group">
                  <h6>Create New To-Do Task</h6>
                  <div class="PermissionsName">
                    <h5
                      class="CursorPointer FolderPermissionId"
                      onClick={() => showAddTaskModal("moveTask")}
                    >
                      <span>
                        <img src="images/icon-35.png" />
                      </span>{" "}
                      Create New To-Do Task
                    </h5>
                  </div>
                </div>

                <div class="form-group">
                  <h6>Create New Itineraty item</h6>
                  <div class="PermissionsName">
                    <h5
                      class="CursorPointer FolderPermissionId"
                      onClick={() => showAddItineraryModal("moveTask")}
                    >
                      <span>
                        <img src="images/double-check.png" />
                      </span>{" "}
                      Create New Itineraty item
                    </h5>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={AddTaskModal}
        onHide={hideHandleAddTaskClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={hideHandleAddTaskClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Add Task</h4>
                </div>
                <div className="FolderForm">
                  <div className="form-group">
                    <h6>
                      Description <span className="required">*</span>
                    </h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert task Description"
                      name="description"
                      value={description}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>Workflow</h6>
                      <div className="Categories_all Categories_all2">
                        <span>Select Workflow</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          - List of Communication Workflows defined for the
                          company
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="Categories Categories3 mb-4">
                      <h6>Job</h6>
                      <div className="Categories_all Categories_all3">
                        <span>CHI-2B-345</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input type="text" placeholder="-Search" />
                          <span className="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>
                        <li>- List of Schedule tasks for job</li>
                      </ul>
                    </div>
                  </div>
                  <div className="CommonModalAreaMain">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Assign Staff Member</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            onClick={() => showAssignSaleModal("addTask")}
                          >
                            <figure>
                              <img src="images/assign-resources.png" />
                            </figure>{" "}
                            <span className="Count">3</span> Assign
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Set Reminder</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="FolderPermissionId CursorPointer"
                            onClick={() => showAddRemianderModal("addTask")}
                          >
                            <figure>
                              <img src="images/timer.png" />
                            </figure>{" "}
                            Set Reminder
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Button d-flex justify-content-between">
                    <button
                      className="Outline"
                      onClick={hideHandleAddTaskClose}
                    >
                      Cancel
                    </button>
                    <button className="Create" onClick={handleTaskAdd}>
                      Update Task
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={AddItineraryModal}
        onHide={hideAddItineraryModal}
        className="ModalBox MediumModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={hideAddItineraryModal}>
                ×
              </a>

              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Add New Itinerary Item</h4>
                </div>
                <div className="FolderForm">
                  <h6>Item #12</h6>

                  <div className="form-group">
                    <h6>Subject</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Executive Meeting"
                      name="subject"
                      value={subject}
                      onChange={handleInput}
                    />
                     <span style={{color:"red", fontSize:"14px"}}>{itineraryError?.subjectError}</span>
                  </div>
                  <div className="form-group">
                    <h6>Description</h6>
                    <textarea
                      rows="5"
                      className="form-control"
                      name="itinerayDesc"
                      value={itinerayDesc}
                      onChange={handleInput}
                    ></textarea>
                      <span style={{color:"red", fontSize:"14px"}}>{itineraryError?.itinerayDescError}</span>
                  </div>
                  <div className="form-group">
                    <h6>Location</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Room 1"
                      name="location"
                      value={location}
                      onChange={handleInput}
                    />
                      <span style={{color:"red", fontSize:"14px"}}>{itineraryError?.locationError}</span>
                  </div>
                  <h6>Select New Date</h6>
                  <div className="Filter row">
                    <div className="form-group col-4">
                      <label>Date Option 1</label>
                      <input
                        type="date"
                        className="form-control"
                        name="date"
                        value={date}
                        onChange={handleInput}
                      />
                        <span style={{color:"red", fontSize:"14px"}}>{itineraryError?.dateError}</span>
                    </div>
                    <div className="form-group col-4">
                      <label>From</label>
                      <input
                        type="time"
                        className="form-control"
                        name="fromTime"
                        value={fromTime}
                        onChange={handleInput}
                      />
                        <span style={{color:"red", fontSize:"14px"}}>{itineraryError?.fromTimeError}</span>
                    </div>
                    <div className="form-group col-4">
                      <label>To</label>
                      <input
                        type="time"
                        className="form-control"
                        name="toTime"
                        value={toTime}
                        onChange={handleInput}
                      />
                        <span style={{color:"red", fontSize:"14px"}}>{itineraryError?.toTimeError}</span>
                    </div>
                  </div>
                  <div className="Filter row">
                    <div className="form-group col-4">
                      <label>Type</label>
                      <select
                        className="form-control"
                        name="type"
                        value={type}
                        onChange={handleInput}
                      >
                        <option value="">Select Type</option>
                        <option value="Meeting">Meeting</option>
                        <option value="Phone Call">Phone Call</option>
                        <option value="Text">Text</option>
                        <option value="Task">Task</option>
                        <option value="Other">Other</option>
                      </select>
                      <span style={{color:"red", fontSize:"14px"}}>{itineraryError?.typeError}</span>
                    </div>
                    <div className="CommonModalArea ml-auto col-4">
                      <div className="form-group">
                        <h5>Set Reminder</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="FolderPermissionId CursorPointer"
                            onClick={() =>
                              showAddRemianderModal("addItinerary")
                            }
                          >
                            <figure>
                              <img src="images/timer-red.png" />
                            </figure>{" "}
                            Set Reminder
                          </h5>
                        </div>
                        <span style={{color:"red", fontSize:"14px"}}>{itineraryError?.reminderDateError}</span>
                      </div>
                    </div>
                  </div>
                  <h6>Itinerary is related to Job or Schedule's Task?</h6>
                  <div className="row">
                    <div className="form-group col-6">
                      <div className="d-flex justify-content-between">
                        <label className="CheckBox">
                          Job
                          <input
                            type="checkbox"
                            onChange={(e) => handleJobTaskChecked(e, "job")}
                            checked={relatedToJob}
                          />
                          <span className="checkmark" />
                        </label>
                        <label className="CheckBox">
                          Schedule
                          <input
                            type="checkbox"
                            onChange={(e) => handleJobTaskChecked(e, "sch")}
                            checked={relatedToTask}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <span style={{color:"red", fontSize:"14px"}}>{itineraryError?.subjectError}</span>
                    </div>
                  </div>
                  <div className="Categories Categories1 mb-3">
                    <div className="Categories_all Categories_all1">
                      <span>Select a folder to insert the file</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>
                        <input type="text" placeholder="-Search" />
                        <span className="Search">
                          <img src="images/search.png" />
                        </span>
                      </li>
                      <li>- List of Jobs already filtered</li>
                    </ul>
                  </div>
                  <div className="Categories Categories2">
                    <div className="Categories_all Categories_all2">
                      <span>Select a Schedule Task</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>
                        <input type="text" placeholder="-Search" />
                        <span className="Search">
                          <img src="images/search.png" />
                        </span>
                      </li>
                      <li>- List of Schedule tasks for job</li>
                    </ul>
                  </div>
                  <div className="StaffMemberArea">
                    <div className="row">
                      <div className="col-4">
                        <h6>Assign Staff Member</h6>
                        <div className="StaffName">
                          <img src="images/member.png" />
                          <div className="Count">3</div>
                          <h6 onClick={showAssignSaleModal}>Assign</h6>
                        </div>
                        <span style={{color:"red", fontSize:"14px"}}>{itineraryError?.UserIdError}</span>
                      </div>
                      <div className="col-4 ml-auto">
                        <h6>Assign Staff member</h6>
                        <div className="StaffBox">
                          <div className="StaffName">
                            {/* <p>{assignStaffss}</p>
                            <img
                              src="images/Cross.png"
                              onClick={removeAssignIntMember}
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Button text-center mt-5">
                    <button
                      className="Create mr-4"
                      onClick={handleAddItinerary}
                    >
                      Create new Itinerary Item
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={assignSaleModal}
        onHide={hideAssignSaleModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAssignSaleModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Assign Sales Representative</h4>
            </div>
            <div className="ModalTitleHeading">
              <h2>Staff Member Information</h2>
            </div>
            <div className="FolderForm">
              <div className="form-group col-md-12">
                <div className="Categories Categories2 mb-4">
                  <h6>
                    Name<span className="required">*</span>
                  </h6>
                  <div className="Categories_all Categories_all2">
                    {/* <Multiselect
                      options={staffList.options}
                      selectedValues={tempAssignList ?? []}
                      displayValue="email"
                      onSelect={onSelectStream}
                    /> */}
                    <select
                      name="UserId"
                      value={UserId}
                      onChange={handleInput}
                      disabled={modalState === true}
                    >
                      <option value="">Select Staff </option>
                      {checkArray(staffListData?.staff) ? (
                        <>
                          {staffListData?.staff?.map((ele, i) => {
                            return (
                              <option value={ele?.id}>
                                {ele?.email}
                              </option>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </select>
                    <img src="images/down.png" />
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {todoError?.AssignStaffError}
                  </span>
                </div>
              </div>
              <div className="Button text-center mt-3">
                <button onClick={hideAssignSaleModal} className="Create">
                  Assign
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={updateAssignModal}
        onHide={hideUpdateAssignModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideUpdateAssignModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Assign Sales Representative</h4>
            </div>
            <div className="ModalTitleHeading">
              <h2>Staff Member Information</h2>
            </div>
            <div className="FolderForm">
              <div className="form-group col-md-12">
                <div className="Categories Categories2 mb-4">
                  <h6>
                    Name<span className="required">*</span>
                  </h6>
                  <div className="Categories_all Categories_all2">
                    {/* <Multiselect
                      options={staffList.options}
                      selectedValues={tempAssignList ?? []}
                      displayValue="email"
                      onSelect={onSelectStream}
                    /> */}
                    <select
                      name="UserId"
                      value={UserId}
                      onChange={handleInput}
                      disabled={modalState === true}
                    >
                      <option>Select Staff </option>
                      {checkArray(staffListData?.staff) ? (
                        <>
                          {staffListData?.staff?.map((ele, i) => {
                            return (
                              <option value={ele?.id}>{ele?.email}</option>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </select>
                    <img src="images/down.png" />
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {AssignStaffError}
                  </span>
                </div>
              </div>
              <div className="Button text-center mt-3">
                <button onClick={updateAssignFun} className="Create">
                  Update
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={updateTodoModal}
        onHide={hideUpdateTodoModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideUpdateTodoModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Update New To-Do</h4>
            </div>
            <div className="ModalTitleHeading">
              <h2>To-Do Information</h2>
            </div>
            <div className="FolderForm">
              <form>
                <div className="form-group">
                  <h6>Job</h6>
                  <div className="Categories Categories1">
                    <div className="Categories_all Categories_all1">
                      {/* <span>Select a Job</span> */}
                      <select name="JobId" value={JobId} onChange={handleInput}>
                        <option>Select Job</option>
                        <option value="1">Static Job</option>
                      </select>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>
                        <input type="text" placeholder="-Search" />
                        <span className="Search">
                          <img src="images/search.png" />
                        </span>
                      </li>
                      <li>- List of Jobs already filtered</li>
                    </ul>
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {todoError?.JobIdError}
                  </span>
                </div>
                <div className="form-group">
                  <h6>
                    Schedule related To-Do?<span className="required">*</span>
                  </h6>
                  <label className="CheckBox mr-4">
                    {" "}
                    Yes
                    <input
                      type="radio"
                      name="scheRelatedToDo"
                      value={true}
                      // checked={scheRelatedToDo}
                      onChange={(e) => handleCheckbox(e)}
                    />
                    <span className="checkmark" />
                  </label>
                  <label className="CheckBox">
                    {" "}
                    No
                    <input
                      type="radio"
                      name="scheRelatedToDo"
                      value={false}
                      // checked={!scheRelatedToDo}
                      onChange={(e) => handleCheckbox(e)}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="form-group">
                  <h6>Schedule's Task</h6>
                  <div className="Categories Categories2">
                    <div className="Categories_all Categories_all2">
                      <select
                        name="TaskId"
                        value={TaskId}
                        onChange={handleInput}
                      >
                        <option>Select Schedule's Task</option>
                        <option value="1">Schedule's Task</option>
                      </select>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>
                        <input type="text" placeholder="-Search" />
                        <span className="Search">
                          <img src="images/search.png" />
                        </span>
                      </li>
                      <li>- List of Schedule tasks for job</li>
                    </ul>
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {todoError?.TaskIdError}
                  </span>
                </div>
                <div className="form-group">
                  <h6>
                    Description <span className="required">*</span>
                  </h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Write here ....."
                    name="description"
                    value={description}
                    onChange={handleInput}
                  />
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {todoError?.descriptionError}
                  </span>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>Target Date</h6>
                      <input
                        type="date"
                        className="form-control"
                        name="targetDate"
                        value={targetDate}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {todoError?.targetDateError}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="StaffMemberArea StaffMemberAreaConst">
                  <aside>
                    <h6>Add Supporting Document</h6>
                    <div className="StaffName">
                      <img src="images/document-support.png" />

                      <h6
                        className="CursorPointer FolderPermissionId"
                        onClick={() => showSupportingDocsModal("editTodo")}
                      >
                        Add Supporting Document
                      </h6>
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {todoError?.supportingDocFilesError}
                    </span>
                  </aside>
                </div>
                <div className="StaffMemberArea StaffMemberAreaConst">
                  <aside>
                    <h6>Add Notes</h6>
                    <div className="StaffName">
                      <img src="images/add-notes.png" />
                      <h6
                        className="CursorPointer FolderPermissionId"
                        onClick={() => showAddNotesModal("editTodo")}
                      >
                        Add Notes
                      </h6>
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {todoError?.notesError}
                    </span>
                  </aside>
                  <aside>
                    <h6>Assign Trade</h6>
                    <div className="StaffName">
                      <img src="images/assign-trade.png" />
                      {/* <div className="Count">3</div> */}
                      <h6
                        className="FolderPermissionId"
                        onClick={() => showAddTradingModal("editTodo")}
                      >
                        Assign
                      </h6>
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {todoError?.tradeError}
                    </span>
                  </aside>
                </div>
                <div className="StaffMemberArea StaffMemberAreaConst">
                  <aside>
                    <h6>Assign Staff Member</h6>
                    <div className="StaffName">
                      <img src="images/member.png" />
                      {/* <div className="Count">3</div> */}
                      <h6
                        className="FolderPermissionId"
                        onClick={() => showAssignSaleModal("editTodo")}
                      >
                        Assign
                      </h6>
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {todoError?.assignStaffIdError}
                    </span>
                  </aside>
                  <aside>
                    <h6>Set Reminder</h6>
                    <div className="StaffName">
                      <img src="images/timer-red.png" />
                      <h6
                        className="FolderPermissionId"
                        onClick={() => showAddRemianderModal("editTodo")}
                      >
                        Reminder
                      </h6>
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {todoError?.reminderDateError}
                    </span>
                  </aside>
                </div>
                <div className="Button text-center mt-5">
                  <button className="Create" onClick={updateTodoFunc}>
                    Update Todo
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <Modal
        show={SelectJobModal}
        onHide={hideSelectJobModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideSelectJobModal}
          >
            ×
          </a>
        <div class="FormArea">
              <div class="ModalTitleBox">
                <h4>Select Job</h4>
              </div>
              <div class="FolderForm">
                <form>
                  <div class="form-group">
                    <div class="ModalSearch">
                      <span><img src="images/search.png" /></span>
                      <input type="text" placeholder="Search" />
                    </div>
                  </div>
                  <div class="form-group">
                    <h6>Job</h6>
                    <div class="Categories Categories1">
                      <div class="Categories_all Categories_all1">
                        <span>Select a Job</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input type="text" placeholder="-Search" />
                          <span class="Search"><img src="images/search.png" /></span>
                        </li>
                        <li>- Full list of Jobs loaded from the system</li>
                      </ul>
                    </div>
                  </div>
                  <div class="form-group">
                    <h6>Block</h6>
                    <div class="Categories Categories2">
                      <div class="Categories_all Categories_all2">
                        <span>Block</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input type="text" placeholder="-Search" />
                          <span class="Search"><img src="images/search.png" /></span>
                        </li>
                        <li>- Block jobs loaded from the system after 
                          job is selected</li>
                      </ul>
                    </div>
                  </div>

                  <div class="form-group">
                    <h6>Unit</h6>
                    <div class="Categories Categories3">
                      <div class="Categories_all Categories_all3">
                        <span>Select a unit</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input type="text" placeholder="-Search" />
                          <span class="Search"><img src="images/search.png" /></span>
                        </li>
                        <li>- Unit jobs loaded from the system after 
                          block is selected</li>
                      </ul>
                    </div>
                  </div>

                   <div class="Button d-flex justify-content-between mb-4">
                    <button class="Outline">Add Job</button>
                   <button class="Outline">Cancel Selection</button>
                   </div>

                  <div class="form-group">
                    <h6>Selected Jobs<span class="required">*</span></h6>
                    <input type="text" class="form-control" placeholder="List of jobs/blocks/units selected using search and/or 
                      dropdown selection" />
                  </div>

                  <div class="Button text-center mt-5">
                    <button class="Create" data-dismiss="modal">View Selected</button>
                  </div>
                </form>
              </div>
            </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Todos;
