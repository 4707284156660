import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCitiesList,
  getCountriesList,
  getStateList,
  getTimeZoneList,
} from "../redux/action/authAction";
import { checkArray } from "../utils/CheckType";
import {
  FoundationListListAction,
  ProformaTypesListAction,
  activeProforma,
  addProformaAction,
  addProformaBlockStep1Failure,
  addProformaBlockStep1aAction,
  createBlockFoundationStep1,
  createBlockFoundationStep2,
  createCommonAreaStep1,
  createCommonBuildingFoundationStep1,
  createCommonBuildingFoundationStep2,
  createCommonBuildingStep1,
  createCommonBuildingStep2,
  createCommonGarageStep1,
  createCommonGarageStep2,
  createLandDevelopmentStep1,
  createLandDevelopmentStep2,
  createMultiLevelStep1,
  createMultiLevelStep2,
  createProformaBlockStep2,
  createProjectsJobs,
  createUnitStep1,
  createUnitStep2,
  getBlockFoundationStep1,
  getBlockFoundationStep2,
  getBlockFoundationStep3,
  getCommonAreaStep1,
  getCommonAreaStep2,
  getCommonBuildingFoundationStep1,
  getCommonBuildingFoundationStep2,
  getCommonBuildingFoundationStep3,
  getCommonBuildingStep1,
  getCommonBuildingStep2,
  getCommonBuildingStep3,
  getCommonGarageStep1,
  getCommonGarageStep2,
  getCommonGarageStep3,
  getFoundationType,
  getLandDevelopmentStep1,
  getLandDevelopmentStep2,
  getLandDevelopmentStep3,
  getMultiLevelStep1,
  getMultiLevelStep2,
  getMultiLevelStep3,
  getProformaBlockStep2,
  getProformaBlockStep3,
  getProformaConfirmationSumarry,
  getUnitStep1,
  getUnitStep2,
  getUnitStep3,
  getUnitStep4,
  getUnitsList,
  getPerforma,
  getPerformastep1block,
} from "../redux/action/proformaAction";
import MultifamilyJobStep1 from "./MultifamilyJob/MultifamilyJobStep1";
import { DayTableModel } from "@fullcalendar/core/internal.js";
import { values } from "lodash";
import Handle from "rc-slider/lib/Handles/Handle";
import { getAllHomeListAction } from "../redux/action/jobAction";
const initModal = {
  ProformaBlockStep1Modal: false,
  ProformaBlockStep2Modal: false,
  ProformaBlockStep3Modal: false,
  modalManagementState: "",

  blockQuantitiesData: [],
};

const initLeadState = {
  name: "",
  proformaTypeId: "",
  homeTypeId: "",
  stateCode: "",
  cityCode: "",
  jobTimeZone: "",
  country: "",
  projectName: "",
  firstStageErrors: {},
  blockFirstStepNumber: 1,
  blockfirstStep: [
    {
      blockDescription: "",
      foundationType: "",
      numOfUnits: "",
      numOfStories: "",
      foundationAndSubFloor: "",
      blockTotal: "",
      commonAreas: "",
      roof: "",
      exteriorWalls: "",
    },
  ],
};

const initialState = {
  ProformaBlockFoundationModal: false,
  ProformaBlockFoundationQuantityModal: false,
  ProformaBlockFoundationSummaryModal: false,
  UnitInputsModal: false,
  UnitOptionsInputsModal: false,
  UnitOptionQuantitiesModal: false,
  UnitSummaryPreviewModal: false,
  CommonAreasModal: false,
  FoundationSummaryPreviewModal: false,
  commonAreaUnit: [],
  CommonBuildingUnitsModal: false,
  CommonBuildingQuantitiesModal: false,
  LandDevlopmentCostModal: false,
  acres: "",
  LandDevlopmentQuantitiesModal: false,
  LandDevlopmentCostPreviewModal: false,
  ProformaConfirmSummaryModal: false,

  commonBuildingQuantities: [],
  CommonBuildingSummaryModal: false,

  CommonBuildingFoundationModal: false,
  CommonBuildingFoundationQuantitesModal: false,
  CommonBuildingFoundationSummaryModal: false,
  commonBuildingInput: [],
  CommonGarageInputModal: false,
  CommonGarageQuantitiesModal: false,
  CommonGarageSummaryModal: false,
  MultilevelParkadeModal: false,
  MultilevelParkadeQuantitiesModal: false,
  MulitileveleParkadeSummaryModal: false,

  commonBuildingFoundationInput: [],
  commonGarageQuantity: [],
  MulitileveleParkadeQuantiesData: [],
  LandDevlopmentQuantites: [],
  blockFoundationStep2List: [],
  commonAreaStep2List: [],
};
const ProformaModal = ({
  proformaModal,
  setProformaModal,
  show,
  setShow,
  count,
}) => {
  const [iModalState, updateModalState] = useState(initModal);
  const [iLeadState, updateLeadState] = useState(initLeadState);
  const [iState, updateState] = useState(initialState);
  const [MultifamilyJobStep1Modal, SetMultifamilyJobStep1Modal] =
    useState(false);

  const [perFormaId, setPerFomaId] = useState("");

  const {
    ProformaBlockFoundationModal,
    ProformaBlockFoundationQuantityModal,
    ProformaBlockFoundationSummaryModal,
    UnitInputsModal,
    UnitOptionsInputsModal,
    UnitOptionQuantitiesModal,
    UnitSummaryPreviewModal,
    CommonAreasModal,
    FoundationSummaryPreviewModal,
    commonAreaUnit,
    CommonBuildingUnitsModal,
    CommonBuildingQuantitiesModal,
    LandDevlopmentCostModal,
    acres,
    LandDevlopmentQuantitiesModal,
    LandDevlopmentCostPreviewModal,
    ProformaConfirmSummaryModal,

    commonBuildingQuantities,
    CommonBuildingSummaryModal,

    CommonBuildingFoundationModal,
    CommonBuildingFoundationQuantitesModal,
    CommonBuildingFoundationSummaryModal,
    commonBuildingInput,
    CommonGarageInputModal,
    CommonGarageQuantitiesModal,
    CommonGarageSummaryModal,
    MultilevelParkadeModal,
    MultilevelParkadeQuantitiesModal,
    MulitileveleParkadeSummaryModal,
    commonAreaStep2List,
    commonBuildingFoundationInput,
    commonGarageQuantity,
    MulitileveleParkadeQuantiesData,
    LandDevlopmentQuantites,
    blockFoundationStep2List,
  } = iState;

  const { state } = useLocation();
  const {
    ProformaBlockStep1Modal,
    ProformaBlockStep2Modal,
    modalManagementState,
    ProformaBlockStep3Modal,
    blockQuantitiesData,
  } = iModalState;
  const {
    name,
    proformaTypeId,
    homeTypeId,
    stateCode,
    cityCode,
    jobTimeZone,
    country,
    firstStageErrors,
    projectName,
    blockfirstStep,
    blockFirstStepNumber,
  } = iLeadState;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { countryData, cityData, stateData, timeZoneData } = useSelector(
    (state) => state.authReducer
  );
  const {
    homeListData,
  } = useSelector((state) => state.jobReducer);
  const {
    proformaTypesListData,
    foundationListData,
    blockFoundationStep1List,

    blockFoundationStep3List,
    unitStep2List,
    unitStep3List,
    unitStep4List,
    commonAreaStep1List,
    commonBuildingStep1List,
    commonBuildingStep2List,
    commonBuildingStep3List,
    landDevelopmentStep1List,
    landDevelopmentStep2List,
    landDevelopmentStep3List,
    proformaConfirmSummaryList,
    foundationTypeData,
    unitList,
    proformaBlockStep2Data,
    proformaBlockStep3Data,

    commonBuildingFoundationStep1List,
    commonBuildingFoundationStep2List,
    commonBuildingFoundationStep3List,
    commonGarageStep1List,
    commonGarageStep2List,
    commonGarageStep3List,
    multiLevelStep1List,
    multiLevelStep2List,
    multiLevelStep3List,
  } = useSelector((state) => state.ProformaReducer);
  // console.log("foundationTypeData", foundationTypeData);
  // console.log("proformaBlockStep3Data", proformaBlockStep3Data);
  const handleInput = (e) => {
    const { name, value } = e.target;
    updateLeadState({ ...iLeadState, [name]: value });
  };
console.log({proformaBlockStep3Data});
  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    if (name === "blockFirstStepNumber") {
      // console.log("value--->", value, name);
      const tempArray = Array.from({ length: +value }, () => ({
        blockDescription: "",
        foundationType: "",
        numOfUnits: "",
        numOfStories: "",
        foundationAndSubFloor: "",
        blockTotal: "",
        commonAreas: "",
        roof: "",
        exteriorWalls: "",
      }));
      // console.log("tempArray", tempArray);
      //
      updateLeadState({
        ...iLeadState,
        [name]: result,
        blockfirstStep: tempArray,
      });
    } else {
      updateLeadState({ ...iLeadState, [name]: result });
    }
  };

  // const [numBlocks, setNumBlocks] = useState(1);
  // const [buildings, setBuildings] = useState([
  //   {
  //     id: 1,
  //     buildingName: "",
  //     foundationType: "",
  //     buildingSqFt: "",
  //     concreteCubicYards: "",
  //     foundationSqFt: "",
  //     drivewaysAndSidewalksSqFt: "",
  //     drivewaysAndSidewalksCubicYards: "",
  //   },
  // ]);

  // const handleNumBlocksChange = (e) => {
  //   const newNumBlocks = parseInt(e.target.value, 10);
  //   setNumBlocks(newNumBlocks > 0 ? newNumBlocks : 1);
  //   // Update buildings array length based on the number of blocks
  //   setBuildings((prevBuildings) => {
  //     const updatedBuildings = [...prevBuildings];
  //     while (updatedBuildings.length < newNumBlocks) {
  //       const newIndex = updatedBuildings.length + 1;
  //       updatedBuildings.push({
  //         id: newIndex,
  //         buildingName: "",
  //         foundationType: "",
  //         buildingSqFt: "",
  //         concreteCubicYards: "",
  //         foundationSqFt: "",
  //         drivewaysAndSidewalksSqFt: "",
  //         drivewaysAndSidewalksCubicYards: "",
  //       });
  //     }
  //     return updatedBuildings.slice(0, newNumBlocks);
  //   });
  // };

  // const handleBuildingFieldChange = (e, index, fieldName) => {
  //   const { value } = e.target;
  //   setBuildings((prevBuildings) => {
  //     const updatedBuildings = [...prevBuildings];
  //     updatedBuildings[index] = {
  //       ...updatedBuildings[index],
  //       [fieldName]: value,
  //     };
  //     return updatedBuildings;
  //   });
  // };

  // const handleCreateCommonBuildingStep1 = () => {
  //   const dataa = {
  //     noOfBuildings: numBlocks,
  //     buildings,
  //   };
  //   dispatch(createCommonBuildingStep1(dataa)).then((res) => {
  //     if (res.status === 201) {
  //       toast.success("Updated Successfully", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         autoClose: 1000,
  //       });
  //       updateState({
  //         ...iState,
  //         CommonBuildingUnitsModal: false,
  //         CommonBuildingQuantitiesModal: true,
  //       });
  //     } else {
  //       toast.error(res.data.message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       updateState({ ...iState, CommonBuildingUnitsModal: true });
  //     }
  //   });
  // };

  const handleCheckbox = (e) => {
    const { name, value, checked } = e.target;
    if (checked == true) {
      updateLeadState({ ...iLeadState, [name]: checked });
    } else {
      updateLeadState({ ...iLeadState, [name]: false });
    }
  };
  //============================================================
  const isSelected = (a, b, c, d) => {
    console.log("a", a, "b", a, "c", c, "d", d);
  };

  //======================
  useEffect(() => {
    dispatch(getAllHomeListAction());
    dispatch(getCountriesList());
    dispatch(getTimeZoneList());
    dispatch(ProformaTypesListAction());
    dispatch(FoundationListListAction());
    dispatch(getUnitsList());
  }, []);
  useEffect(() => {
    if(country)
    dispatch(
      getStateList(
        countryData?.countries?.find((x) => x.name == country)?.isoCode
      )
    );
  }, [country]);
  useEffect(() => {
    dispatch(
      getCitiesList(
        countryData?.countries?.find((x) => x.name == country)?.isoCode,
        stateData?.states?.find((x) => x.name == stateCode)?.isoCode
      )
    );
  }, [stateCode]);
  const hideMainCreateModal = () => {
    setProformaModal(false);
    setShow(true);
    setShowCreateModal(false);
  };

  const handleFirstStageValidation = () => {
    let error = {};
    let formIsValid = true;

    if (!name) {
      error.nameError = "Name can't be empty";
      formIsValid = false;
    }

    if (!proformaTypeId) {
      error.proformaTypeIdError = "Proforma Type can't be empty";
      formIsValid = false;
    }

    if (!homeTypeId) {
      error.homeTypeIdError = "Home type can't be empty";
      formIsValid = false;
    }

    if (!stateCode) {
      error.stateCodeError = "State can't be empty";
      formIsValid = false;
    }

    if (!cityCode) {
      error.cityCodeError = "City can't be empty";
      formIsValid = false;
    }
    if (!jobTimeZone) {
      error.jobTimeZoneError = "Timezone can't be empty";
      formIsValid = false;
    }

    if (!projectName) {
      error.projectNameError = "Project can't can't be empty";
      formIsValid = false;
    }
    updateLeadState({ ...iLeadState, firstStageErrors: error });
    return formIsValid;
  };
  const [getdataBack, setGetDataBack] = useState({
    id: null,
    companyId: null,
    count: null,
  });
  console.log({ jobTimeZone });
  const firstStepCreateFunc = () => {
    let formIsValid = handleFirstStageValidation();
    var currentDate = new Date();
    var gmtOffset = jobTimeZone;
    var offsetSign = gmtOffset[0];
    var offsetHours = parseInt(gmtOffset.slice(1, 3), 10);
    var offsetMinutes = parseInt(gmtOffset.slice(4, 6), 10);

    // Calculate the total offset in milliseconds
    var totalOffsetInMilliseconds =
      (offsetHours * 60 + offsetMinutes) * 60 * 1000;
    if (offsetSign === "-") {
      totalOffsetInMilliseconds = -totalOffsetInMilliseconds;
    }
    var offsetInMilliseconds = offsetHours * 60 * 60 * 1000;
    var adjustedDate = new Date(
      currentDate.getTime() + totalOffsetInMilliseconds
    );

    var year = adjustedDate.getUTCFullYear();
    var month = String(adjustedDate.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    var day = String(adjustedDate.getUTCDate()).padStart(2, "0");
    var hours = String(adjustedDate.getUTCHours()).padStart(2, "0");
    var minutes = String(adjustedDate.getUTCMinutes()).padStart(2, "0");
    var seconds = String(adjustedDate.getUTCSeconds()).padStart(2, "0");

    var formattedTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
    console.log({ formattedTime });
    if (formIsValid) {
      let data = {
        name,
        proformaTypeId: +proformaTypeId,
        homeTypeId: +homeTypeId,
        stateCode,
        cityCode,
        // jobTimeZone: formattedTime,
        jobTimeZone: "2024-01-06T12:00:00Z",
        country,
        projectName,
      };
      dispatch(addProformaAction(data)).then((res) => {
        console.log("resss", res);

        setGetDataBack((prev) => ({
          ...prev,
          id: res?.data?.data?.id,
          companyId: res?.data?.data?.companyId,
          count: null,
        }));
        window.localStorage.setItem(
          "profomaId",
          JSON.stringify(res?.data?.data?.id)
        );

        showProformaBlockStep1Modal();
        updateLeadState({
          ...iLeadState,
          name: "",
          proformaTypeId: "",
          homeTypeId: "",
          stateCode: "",
          cityCode: "",
          projectName: "",
          jobTimeZone: "",
        });
      });
    }
  };

  const blockListStep1Func = (e) => {
    e.preventDefault();
    const dataa = {
      noOfBlocks: Number(blockFirstStepNumber),
      blocks: blockfirstStep,
    };
    dispatch(addProformaBlockStep1aAction(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success("Added Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateModalState({
          ...iModalState,
          ProformaBlockStep1Modal: false,
          ProformaBlockStep2Modal: true,
        });
        dispatch(getProformaBlockStep2()).then((res) => {
          console.log({ res });
          if (res.status === 200) {
            updateModalState({
              ...iModalState,
              blockQuantitiesData: res?.data?.data,
              ProformaBlockStep1Modal: false,
              ProformaBlockStep2Modal: true,
            });
          }
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateModalState({ ...iModalState, ProformaBlockStep1Modal: true });
      }
    });
  };

  const [showcreateModal, setShowCreateModal] = useState(true);

  const showProformaBlockStep1Modal = () => {
    if (handleFirstStageValidation()) {
      setShowCreateModal(false);
      // setProformaModal(false)
      updateModalState((prevState) => ({
        ...prevState,
        ProformaBlockStep1Modal: true,
      }));
    } else {
    }
  };

  const hideProformaBlockStep1Modal = () => {
    updateModalState({ ...iModalState, ProformaBlockStep1Modal: false });
    setGetDataBack((prev) => ({
      ...prev,
      count: true,
    }));
    setShowCreateModal(true);
  };

  const handleBlockFirstStepChange = (e, i) => {
    const { name, value } = e?.target;
    let tempBlockFirstStep = [...blockfirstStep];

    tempBlockFirstStep[i] = { ...blockfirstStep[i], [name]: value };
    updateLeadState({ ...iLeadState, blockfirstStep: tempBlockFirstStep });
  };

  // const blockListStep1Func = (e) => {
  //   e.preventDefault();
  //   dispatch(addProformaBlockStep1aAction(state, blockfirstStep)).then(
  //     (res) => {
  //       // console.log("re=eeeee", res);
  //     }
  //   );
  // };

  const hideProformaBlockStep2Modal = () => {
    updateModalState({
      ...iModalState,
      ProformaBlockStep2Modal: false,
      ProformaBlockStep1Modal: true,
    });
  };

  const showProformaBlockStep2Modal = () => {
    updateModalState({
      ...iModalState,
      ProformaBlockStep2Modal: true,
      ProformaBlockStep1Modal: false,
    });
  };

  useEffect(() => {
    if (ProformaBlockStep2Modal) {
      dispatch(getProformaBlockStep2()).then((res) => {
        console.log({ res });
        if (res.status === 200) {
          updateModalState({
            ...iModalState,
            blockQuantitiesData: res?.data?.data,
          });
        }
      });
    }
  }, [ProformaBlockStep2Modal]);
  console.log({ blockQuantitiesData });
  const handleBlockQuantityChange = (e, ind, i, blocId) => {
    console.log("function executed====");
    const { name, value } = e.target;
    const dummyQuantity = [...blockQuantitiesData];
    console.log({ name }, { value });

    dummyQuantity[ind].ProformaBaseCosts[i].ProformaBlockQuantities[blocId] = {
      ...dummyQuantity[ind].ProformaBaseCosts[i].ProformaBlockQuantities[
        blocId
      ],
      [name]: value,
    };

    console.log({ dummyQuantity });
    updateModalState({ ...iModalState, blockQuantitiesData: dummyQuantity });
  };
  console.log({ blockQuantitiesData });
  const [blockStep2Id, setBlockStep2id] = useState("");
  const handleCreateBlockStep2 = () => {
    const dataa = blockQuantitiesData?.flatMap((item) =>
      item?.ProformaBaseCosts?.flatMap((itm) =>
        itm?.ProformaBlockQuantities?.map((itemm) => ({
          id: itemm.id,
          quantity: itemm.quantity,
          unitId: itemm.unitId,
        }))
      )
    );
    console.log({ dataa });
    dispatch(createProformaBlockStep2(dataa)).then((res) => {
      if (res.status === 200) {
        console.log({ res });
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setBlockStep2id(res?.data?.data?.[0]?.parentTableId);
        updateModalState({
          ...iModalState,
          ProformaBlockStep2Modal: false,
          ProformaBlockStep3Modal: true,
          blockQuantitiesData: [],
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateModalState({ ...iModalState, ProformaBlockStep2Modal: true });
      }
    });
  };

  const hideProformaBlockStep3Modal = () => {
    updateModalState({
      ...iModalState,
      ProformaBlockStep3Modal: false,
      ProformaBlockStep2Modal: true,
    });
  };

  useEffect(() => {
    if (ProformaBlockStep3Modal) {
      dispatch(getProformaBlockStep3());
    }
  }, [ProformaBlockStep3Modal]);

  const showProformaBlockStep3Modal = () => {
    updateModalState({
      ...iModalState,
      ProformaBlockStep3Modal: true,
      ProformaBlockStep2Modal: false,
    });

    dispatch(getProformaBlockStep3)
  };

  //===========================blockFoundation===================================

  const handleProformaBlockFoundationModalShow = () => {
    updateModalState((prev) => ({
      ...prev,
      ProformaBlockStep3Modal: false,
    }));
    updateState((prev) => ({
      ...prev,
      ProformaBlockFoundationModal: true,
    }));
    dispatch(getBlockFoundationStep1()).then((data) => {
      console.log({ data });
    });
  };

  const handleProformaBlockFoundationModalClose = () => {
    updateModalState({
      ...iModalState,
      ProformaBlockStep3Modal: true,
    });
    updateState({
      ...iState,
      ProformaBlockFoundationModal: false,
    });
  };

  const [foundationValues, setFoundationValues] = useState([]);
  const [changedValues, setChangedValues] = useState([]);

  useEffect(() => {
    if (blockFoundationStep1List?.data?.length > 0) {
      const initialValues = blockFoundationStep1List?.data.map((data) => ({
        id: data.id,
        blocks: data.FoundationPerimeters?.flatMap(
          (item) =>
            item.BlockFoundations?.map((block) => ({
              id: block.id,
              value: block.defaultValue || "",
            })) || []
        ),
      }));
      setFoundationValues(initialValues);
    }
  }, [blockFoundationStep1List?.data]);

  const handleInputChange = (foundationId, blockId, value) => {
    setFoundationValues((prevValues) =>
      prevValues.map((foundation) =>
        foundation.id === foundationId
          ? {
              ...foundation,
              blocks: (foundation.blocks || []).map((block) =>
                block.id === blockId ? { ...block, value } : block
              ),
            }
          : foundation
      )
    );

    // Update changed values state
    const updatedValues = [...changedValues];
    const existingIndex = updatedValues.findIndex(
      (item) => item.id === blockId
    );

    if (existingIndex !== -1) {
      updatedValues[existingIndex] = { id: blockId, value };
    } else {
      updatedValues.push({ id: blockId, value });
    }

    setChangedValues(updatedValues);
  };

  const handleCreateBlockFoundationStep1 = () => {
    const dataa = {
      changedValues,
    };
    dispatch(createBlockFoundationStep1(dataa)).then((res) => {
      if (res.status === 200) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          ProformaBlockFoundationModal: false,
          ProformaBlockFoundationQuantityModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, ProformaBlockFoundationModal: true });
      }
    });
  };
  const [blockFoundationStep2, setBlockFoundationStep2] = useState([]);
  const handleProformaBlockFoundationModalQuantityShow = () => {
    updateState({
      ...iState,
      ProformaBlockFoundationQuantityModal: true,
      ProformaBlockFoundationModal: false,
    });
    dispatch(getBlockFoundationStep2()).then((data) => {
      console.log({ data });
      setBlockFoundationStep2(data?.data);
      updateState((prev) => ({
        ...prev,
        blockFoundationStep2List: data?.data,
      }));
    });
  };
  console.log({ blockFoundationStep2List }, { blockFoundationStep2 });
  const handleProformaBlockFoundationModalQuantityClose = () => {
    updateState({
      ...iState,
      ProformaBlockFoundationModal: true,
      ProformaBlockFoundationQuantityModal: false,
    });
  };

  const handleProformaBlockFoundationModalSummaryShow = () => {
    updateState({
      ...iState,
      ProformaBlockFoundationQuantityModal: false,
      ProformaBlockFoundationSummaryModal: true,
    });
    dispatch(getBlockFoundationStep3());
  };

  const handleProformaBlockFoundationModalSummaryClose = () => {
    updateState({
      ...iState,
      ProformaBlockFoundationSummaryModal: false,
      ProformaBlockFoundationQuantityModal: true,
    });
  };
  const [unitStep1List, setUnitStep1List] = useState([]);
  const handleUnitInputsModalShow = () => {
    updateState({
      ...iState,
      ProformaBlockFoundationSummaryModal: false,
      UnitInputsModal: true,
    });
    dispatch(getUnitStep1()).then((data) => {
      console.log({ data });
      setUnitStep1List(data?.data?.data);
      const newData = data?.data?.data?.table1?.units?.map((item, index) => ({
        id: item?.id,
        value: item.UnitOptions?.[0]?.unitValue,
        type: item.type,
        bedRooms: item.bedRooms,
        bathRooms: item.bathRooms,
      }));
      setUnitTypes(newData);
    });
  };
  console.log({ unitStep1List });
  const handleUnitInputsModalClose = () => {
    updateState({
      ...iState,
      ProformaBlockFoundationSummaryModal: true,
      UnitInputsModal: false,
    });
  };
  const [unitTypes, setUnitTypes] = useState([]);
  useEffect(() => {
    if (UnitInputsModal) {
      dispatch(getUnitStep1()).then((data) => {
        console.log({ data });
        const newData = data?.data?.data?.table1?.units?.map((item, index) => ({
          id: item?.id,
          value: item.UnitOptions?.[0]?.unitValue,
          type: item.type,
          bedRooms: item.bedRooms,
          bathRooms: item.bathRooms,
        }));
        setUnitTypes(newData);
      });
    }
  }, [UnitInputsModal]);
  console.log("unitTypes", unitTypes);

  const handleUnitChange = (event, index) => {
    const { name, value } = event.target;

    // Update the corresponding value in the state
    setUnitTypes((prevUnitTypes) => {
      const updatedUnitTypes = [...prevUnitTypes];
      updatedUnitTypes[index] = { ...updatedUnitTypes[index], value: value };
      return updatedUnitTypes;
    });
  };

  console.log({ commonBuildingQuantities });
  const handleUnitStep1ListChange = (e, ind, i) => {
    const { name, value, checked } = e.target;
    console.log({ name }, { value });
    let tempUnitList = { ...unitStep1List };
    let tempTable2 = tempUnitList.table2;
    if (name == "unitNumber" || name == "unitTypeId") {
      tempTable2[ind].MultiUnits[i] = {
        ...tempTable2[ind].MultiUnits[i],
        [name]: Number(value),
      };
      tempUnitList.table2 = tempTable2;
      console.log({ tempUnitList });
      updateState((prev) => ({ ...prev, unitStep1List: tempUnitList }));
    } else {
      tempTable2[ind].MultiUnits[i] = {
        ...tempTable2[ind].MultiUnits[i],
        [name]: checked ? true : false,
      };
      tempUnitList.table2 = tempTable2;

      updateState((prev) => ({ ...prev, unitStep1List: tempUnitList }));
    }
  };
  console.log({ unitStep1List });

  const handleCreateUnitsInputStep1 = () => {
    console.log({ unitStep1List });
    const unitData = unitStep1List?.table2?.[0]?.MultiUnits.map((unit) => ({
      id: unit.id,
      unitTypeId: unit.unitTypeId,
      attachedGarage: unit.attachedGarage,
      basementDevelopment: unit.basementDevelopment,
      drivewaysSideWalks: unit.drivewaysSideWalks,
      carportPads: unit.carportPads,
      concretePads: unit.concretePads,
      detachedAndCommonGarages: unit.detachedAndCommonGarages,
      slabOnGrade: unit.slabOnGrade,
    }));
    const dataa = {
      unitTypes: unitTypes?.map((data) => ({
        id: data.id,
        value: +data.value,
      })),
      multiUnits: unitData,
    };
    dispatch(createUnitStep1(dataa)).then((res) => {
      if (res.status === 200) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          UnitInputsModal: false,
          UnitOptionsInputsModal: true,
        });
        dispatch(getUnitStep2()).then((data) => {
          console.log({ data });
          setUnitOption(data?.data?.data);
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, UnitInputsModal: true });
      }
    });
  };

  const handleUnitOptionsInputsModalShow = () => {
    updateState({
      ...iState,
      UnitOptionsInputsModal: true,
      UnitInputsModal: false,
    });
  };

  const [unitOption, setUnitOption] = useState([]);

  // console.log("unitOption", unitOption);
  useEffect(() => {
    if (UnitOptionsInputsModal) {
      dispatch(getUnitStep2()).then((data) => {
        console.log({ data });
        setUnitOption(data?.data?.data);
      });
      // setUnitOption([
      //   {
      //     id: unitStep2List?.data?.[0]?.id,
      //     totalSqFt: unitStep2List?.data?.[0]?.totalSqFt,
      //     name: unitStep2List?.data?.[0]?.name,
      //   },
      //   {
      //     id: unitStep2List?.data?.[1]?.id,
      //     totalSqFt: unitStep2List?.data?.[1]?.totalSqFt,
      //     name: unitStep2List?.data?.[1]?.name,
      //     totalCubicYards: unitStep2List?.data?.[1]?.totalCubicYards,
      //   },
      //   {
      //     id: unitStep2List?.data?.[2]?.id,
      //     totalSqFt: unitStep2List?.data?.[2]?.totalSqFt,
      //     name: unitStep2List?.data?.[2]?.name,
      //     totalCubicYards: unitStep2List?.data?.[2]?.totalCubicYards,
      //   },
      //   {
      //     id: unitStep2List?.data?.[3]?.id,
      //     totalSqFt: unitStep2List?.data?.[3]?.totalSqFt,
      //     name: unitStep2List?.data?.[3]?.name,
      //     totalCubicYards: unitStep2List?.data?.[3]?.totalCubicYards,
      //   },
      //   {
      //     id: unitStep2List?.data?.[4]?.id,
      //     totalSqFt: unitStep2List?.data?.[4]?.totalSqFt,
      //     name: unitStep2List?.data?.[4]?.name,
      //     totalCubicYards: unitStep2List?.data?.[4]?.totalCubicYards,
      //   },
      //   {
      //     id: unitStep2List?.data?.[5]?.id,
      //     totalSqFt: unitStep2List?.data?.[5]?.totalSqFt,
      //     name: unitStep2List?.data?.[5]?.name,
      //     totalCubicYards: unitStep2List?.data?.[5]?.totalCubicYards,
      //     perimeterLinFt: unitStep2List?.data?.[5]?.perimeterLinFt,
      //   },
      // ]);
    }
  }, [UnitOptionsInputsModal]);

  const handleUnitOptionChange = (event, index) => {
    const { name, value } = event.target;

    // Update the corresponding value in the state
    setUnitOption((prevUnitTypes) => {
      const updatedUnitTypes = [...prevUnitTypes];
      updatedUnitTypes[index] = { ...updatedUnitTypes[index], [name]: value };
      return updatedUnitTypes;
    });
  };

  const handleUnitOptionsInputsModalClose = () => {
    updateState({
      ...iState,
      UnitOptionsInputsModal: false,
      UnitInputsModal: true,
    });
  };

  const handleCreateUnitsInputStep2 = () => {
    const dataa = {
      unitTypes: unitOption?.map((data) => ({
        id: data?.id,
        totalSqFt: data?.totalSqFt ?? 0,
        totalCubicYards: data?.totalCubicYards ?? 0,
        perimeterLinFt: data?.perimeterLinFt ?? 0,
      })),
    };
    dispatch(createUnitStep2(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          UnitOptionsInputsModal: false,
          UnitOptionQuantitiesModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, UnitOptionsInputsModal: true });
      }
    });
  };

  const handleUnitOptionQuantitiesModalShow = () => {
    updateState({
      ...iState,
      UnitOptionsInputsModal: false,
      UnitOptionQuantitiesModal: true,
    });
    // dispatch(getUnitStep2());
  };

  const [unitOptionQuantities, setUnitOptionQuantities] = useState([]);

  // console.log("unitOption", unitOption);
  useEffect(() => {
    if (UnitOptionQuantitiesModal) {
      dispatch(getUnitStep3());
      // setUnitOption([
      //   {
      //     id: unitStep2List?.data?.[0]?.id,
      //     totalSqFt: unitStep2List?.data?.[0]?.totalSqFt,
      //     name: unitStep2List?.data?.[0]?.name,
      //   },
      //   {
      //     id: unitStep2List?.data?.[1]?.id,
      //     totalSqFt: unitStep2List?.data?.[1]?.totalSqFt,
      //     name: unitStep2List?.data?.[1]?.name,
      //     totalCubicYards: unitStep2List?.data?.[1]?.totalCubicYards,
      //   },
      //   {
      //     id: unitStep2List?.data?.[2]?.id,
      //     totalSqFt: unitStep2List?.data?.[2]?.totalSqFt,
      //     name: unitStep2List?.data?.[2]?.name,
      //     totalCubicYards: unitStep2List?.data?.[2]?.totalCubicYards,
      //   },
      //   {
      //     id: unitStep2List?.data?.[3]?.id,
      //     totalSqFt: unitStep2List?.data?.[3]?.totalSqFt,
      //     name: unitStep2List?.data?.[3]?.name,
      //     totalCubicYards: unitStep2List?.data?.[3]?.totalCubicYards,
      //   },
      //   {
      //     id: unitStep2List?.data?.[4]?.id,
      //     totalSqFt: unitStep2List?.data?.[4]?.totalSqFt,
      //     name: unitStep2List?.data?.[4]?.name,
      //     totalCubicYards: unitStep2List?.data?.[4]?.totalCubicYards,
      //   },
      //   {
      //     id: unitStep2List?.data?.[5]?.id,
      //     totalSqFt: unitStep2List?.data?.[5]?.totalSqFt,
      //     name: unitStep2List?.data?.[5]?.name,
      //     totalCubicYards: unitStep2List?.data?.[5]?.totalCubicYards,
      //     perimeterLinFt: unitStep2List?.data?.[5]?.perimeterLinFt,
      //   },
      // ])
    }
  }, [UnitOptionQuantitiesModal]);

  const handleUnitOptionQuantitiesModalClose = () => {
    updateState({
      ...iState,
      UnitOptionsInputsModal: true,
      UnitOptionQuantitiesModal: false,
    });
  };

  const handleUnitSummaryPreviewModalShow = () => {
    updateState({
      ...iState,
      UnitSummaryPreviewModal: true,
      UnitOptionQuantitiesModal: false,
    });
    // dispatch(getUnitStep2());
  };

  useEffect(() => {
    if (UnitSummaryPreviewModal) {
      dispatch(getUnitStep4());
    }
  }, []);

  const handleUnitSummaryPreviewModalClose = () => {
    updateState({
      ...iState,
      UnitSummaryPreviewModal: false,
      UnitOptionQuantitiesModal: true,
    });
  };

  const handleCommonAreasModalShow = () => {
    updateState({
      ...iState,
      CommonAreasModal: true,
      UnitSummaryPreviewModal: false,
    });
    // dispatch(getUnitStep2());
  };

  useEffect(() => {
    if (CommonAreasModal) {
      dispatch(getCommonAreaStep1()).then((data) => {
        console.log({ data });
        updateState({ ...iState, commonAreaUnit: data?.data?.data });
      });
    }
  }, [CommonAreasModal]);
  const handleCommonAreasModalClose = () => {
    updateState({
      ...iState,
      UnitSummaryPreviewModal: true,
      CommonAreasModal: false,
    });
  };

  const handleMultiStorieschange = (e, i, index) => {
    const { name, value } = e.target;
    const newList = [...commonAreaUnit];
    newList[index].MultiStories[i] = {
      ...newList[index].MultiStories[i],
      [name]: value,
    };
    updateState({ ...iState, commonAreaUnit: newList });
  };

  // console.log("commonAreaUnit", commonAreaUnit);

  const handleCreateCommonAreaUnitStep1 = () => {
    const dataa = {
      unitTypes: commonAreaUnit?.flatMap(
        (data) =>
          data.MultiStories?.map((x) => ({
            id: x.id,
            value: Number(x.value),
          })) ?? []
      ),
    };
    dispatch(createCommonAreaStep1(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          CommonAreasModal: false,
          FoundationSummaryPreviewModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, CommonAreasModal: true });
      }
    });
  };

  const handleFoundationSummaryPreviewModalShow = () => {
    updateState({
      ...iState,
      CommonAreasModal: false,
      FoundationSummaryPreviewModal: true,
    });
  };

  useEffect(() => {
    if (FoundationSummaryPreviewModal) {
      dispatch(getCommonAreaStep2()).then((data) => {
        updateState({
          ...iState,
          commonAreaStep2List: data?.data,
        });
      });
    }
  }, [FoundationSummaryPreviewModal]);
  console.log({ commonAreaStep2List });
  const handleFoundationSummaryPreviewModalClose = () => {
    updateState({
      ...iState,
      FoundationSummaryPreviewModal: false,
      CommonAreasModal: true,
    });
  };
  console.log({ commonBuildingQuantities });

  const [numBlocks, setNumBlocks] = useState(1);
  const [buildings, setBuildings] = useState([
    {
      id: 1,
      buildingName: "",
      foundationType: "",
      buildingSqFt: "",
      concreteCubicYards: "",
      foundationSqFt: "",
      drivewaysAndSidewalksSqFt: "",
      drivewaysAndSidewalksCubicYards: "",
    },
  ]);

  const handleNumBlocksChange = (e) => {
    const newNumBlocks = parseInt(e.target.value, 10);
    setNumBlocks(newNumBlocks > 0 ? newNumBlocks : 1);
    // Update buildings array length based on the number of blocks
    setBuildings((prevBuildings) => {
      const updatedBuildings = [...prevBuildings];
      while (updatedBuildings.length < newNumBlocks) {
        const newIndex = updatedBuildings.length + 1;
        updatedBuildings.push({
          id: newIndex,
          buildingName: "",
          foundationType: "",
          buildingSqFt: "",
          concreteCubicYards: "",
          foundationSqFt: "",
          drivewaysAndSidewalksSqFt: "",
          drivewaysAndSidewalksCubicYards: "",
        });
      }
      return updatedBuildings.slice(0, newNumBlocks);
    });
  };

  const handleBuildingFieldChange = (e, index, fieldName) => {
    const { value } = e.target;
    setBuildings((prevBuildings) => {
      const updatedBuildings = [...prevBuildings];
      updatedBuildings[index] = {
        ...updatedBuildings[index],
        [fieldName]: value,
      };
      console.log({ updatedBuildings });
      return updatedBuildings;
    });
  };

  const handleCreateCommonBuildingStep1 = () => {
    const dataa = {
      noOfBuildings: numBlocks,
      buildings,
    };
    dispatch(createCommonBuildingStep1(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });

        console.log({ commonBuildingQuantities });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, CommonBuildingUnitsModal: true });
      }
    });
  };

  const handleCommonBuildingUnitsModalShow = () => {
    updateState({
      ...iState,
      CommonBuildingUnitsModal: true,
      FoundationSummaryPreviewModal: false,
    });
  };

  useEffect(() => {
    if (CommonBuildingUnitsModal) {
      dispatch(getCommonBuildingStep1());
      dispatch(getFoundationType());
    }
  }, [CommonBuildingUnitsModal]);

  const handleCommonBuildingUnitsModalClose = () => {
    updateState({
      ...iState,
      FoundationSummaryPreviewModal: true,
      CommonBuildingUnitsModal: false,
    });
  };

  const handleCommonBuildingQuantitiesModalShow = () => {
    updateState({
      ...iState,
      CommonBuildingUnitsModal: false,
      CommonBuildingQuantitiesModal: true,
    });
  };

  useEffect(() => {
    if (CommonBuildingQuantitiesModal) {
      dispatch(getCommonBuildingStep2()).then((data) => {
        console.log({ data });
        updateState({
          ...iState,
          commonBuildingQuantities: data?.data?.data,
        });
      });
    }
  }, [CommonBuildingQuantitiesModal]);

  const handleQuantityChange = (e, ind, i, blocId) => {
    const { name, value } = e.target;
    const dummyQuantity = [...commonBuildingQuantities];
    dummyQuantity[ind].ProformaBaseCosts[i].ProformaBlockQuantities[blocId] = {
      ...dummyQuantity[ind].ProformaBaseCosts[i].ProformaBlockQuantities[
        blocId
      ],
      [name]: value,
    };
    updateState({ ...iState, commonBuildingQuantities: dummyQuantity });
  };

  const handleCommonBuildingQuantitiesModalClose = () => {
    updateState({
      ...iState,
      CommonBuildingQuantitiesModal: false,
      CommonBuildingUnitsModal: true,
    });
  };

  const handleCreateCommonBuildingStep2 = () => {
    const dataa = {
      commonBuildingQuantities: commonBuildingQuantities?.flatMap((item) =>
        item?.ProformaBaseCosts.flatMap((itm) =>
          itm?.ProformaBlockQuantities.map((itemm) => {
            return { id: itemm.id, quantity: itemm.quantity };
          })
        )
      ),
    };
    dispatch(createCommonBuildingStep2(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success("Created Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          CommonBuildingSummaryModal: true,
          CommonBuildingQuantitiesModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, CommonBuildingQuantitiesModal: true });
      }
    });
  };

  const handleCommonBuildingSummaryModalShow = () => {
    updateState({
      ...iState,
      CommonBuildingSummaryModal: true,
      CommonBuildingQuantitiesModal: false,
    });
  };
  useEffect(() => {
    if (CommonBuildingSummaryModal) {
      dispatch(getCommonBuildingStep3());
    }
  }, [CommonBuildingSummaryModal]);
  const handleCommonBuildingSummaryModalClose = () => {
    updateState({
      ...iState,
      CommonBuildingSummaryModal: false,
      CommonBuildingQuantitiesModal: true,
    });
  };

  const handleCommonBuildingFoundationModalShow = () => {
    updateState({
      ...iState,
      CommonBuildingFoundationModal: true,
      CommonBuildingQuantitiesModal: false,
    });
  };
  useEffect(() => {
    if (CommonBuildingFoundationModal) {
      dispatch(getCommonBuildingFoundationStep1()).then((data) => {
        console.log({ data });
        updateState({
          ...iState,
          commonBuildingInput: data?.data?.data,
        });
      });
    }
  }, [CommonBuildingFoundationModal]);

  console.log("commonBuildingInput", commonBuildingInput);
  const handleCommonBuildingFoundationModalClose = () => {
    updateState({
      ...iState,
      CommonBuildingFoundationModal: false,
      CommonBuildingQuantitiesModal: true,
    });
  };

  const handleCommonBuildingUnitChange = (e, i, ind, index) => {
    const { name, value } = e.target;
    const newList = [...commonBuildingInput];
    commonBuildingInput[i].FoundationPerimeters[ind].BlockFoundations[index] = {
      ...commonBuildingInput[i].FoundationPerimeters[ind].BlockFoundations[
        index
      ],
      [name]: value,
    };
    updateState({ ...iState, commonBuildingInput: newList });
  };

  const handleCreateCommonBuildingFoundationStep1 = () => {
    const dataa = {
      commonBuildingQuantities: commonBuildingInput?.flatMap((item) =>
        item?.FoundationPerimeters.flatMap((itm) =>
          itm?.BlockFoundations.map((itemm) => {
            return { id: itemm.id, value: Number(itemm.value) };
          })
        )
      ),
    };
    dispatch(createCommonBuildingFoundationStep1(dataa)).then((res) => {
      if (res.status === 200) {
        toast.success("Created Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, CommonBuildingFoundationModal: true });
      }
    });
  };

  const handleCommonBuildingFoundationQuantitesModalShow = () => {
    updateState({
      ...iState,
      CommonBuildingFoundationModal: false,
      CommonBuildingFoundationQuantitesModal: true,
    });
  };

  useEffect(() => {
    if (CommonBuildingFoundationQuantitesModal) {
      dispatch(getCommonBuildingFoundationStep2()).then((data) => {
        console.log({ data });
        updateState({
          ...iState,
          commonBuildingFoundationInput: data?.data?.data,
        });
      });
    }
  }, [CommonBuildingFoundationQuantitesModal]);

  const handleCommonBuildingfoundationChange = (e, ind, inde, i, blocId) => {
    const { name, value } = e.target;
    const dummyQuantity = [...commonBuildingFoundationInput];
    dummyQuantity[ind].ProformaCostHeadings[inde].ProformaBaseCosts[
      i
    ].ProformaBlockQuantities[blocId] = {
      ...dummyQuantity[ind].ProformaCostHeadings[inde].ProformaBaseCosts[i]
        .ProformaBlockQuantities[blocId],
      [name]: value,
    };
    updateState({ ...iState, commonBuildingFoundationInput: dummyQuantity });
  };
  console.log({ commonBuildingFoundationInput });
  const handleCreateCommonBuildingFoundationStep2 = () => {
    const dataa = {
      commonBuildingFoundationInput: commonBuildingFoundationInput?.flatMap(
        (item) =>
          item?.ProformaCostHeadings?.flatMap((itm) =>
            itm?.ProformaBaseCosts?.flatMap((itemm) =>
              itemm?.ProformaBlockQuantities?.map((items) => ({
                id: items.id,
                quantity: Number(items.quantity),
              }))
            )
          )
      ),
    };
    dispatch(createCommonBuildingFoundationStep2(dataa)).then((res) => {
      if (res.status === 200) {
        toast.success("Created Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          CommonBuildingFoundationQuantitesModal: false,
          CommonBuildingFoundationSummaryModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          CommonBuildingFoundationQuantitesModal: true,
        });
      }
    });
  };

  const handleCommonBuildingFoundationQuantitesModalClose = () => {
    updateState({
      ...iState,
      CommonBuildingFoundationModal: true,
      CommonBuildingFoundationQuantitesModal: false,
    });
  };

  const handleCommonBuildingFoundationSummaryModalShow = () => {
    updateState({
      ...iState,
      CommonBuildingFoundationSummaryModal: true,
      CommonBuildingFoundationQuantitesModal: false,
    });
  };

  useEffect(() => {
    if (CommonBuildingFoundationSummaryModal) {
      dispatch(getCommonBuildingFoundationStep3());
    }
  }, [CommonBuildingFoundationSummaryModal]);

  const handleCommonBuildingFoundationSummaryModalClose = () => {
    updateState({
      ...iState,
      CommonBuildingFoundationSummaryModal: false,
      CommonBuildingFoundationQuantitesModal: true,
    });
  };

  const handleCommonGarageInputModalShow = () => {
    updateState({
      ...iState,
      CommonBuildingFoundationSummaryModal: false,
      CommonGarageInputModal: true,
    });
  };

  useEffect(() => {
    if (CommonGarageInputModal) {
      dispatch(getCommonGarageStep1());
    }
  }, [CommonGarageInputModal]);

  const [numBlocksGarage, setNumBlocksGarage] = useState(1);
  const [garage, setGarage] = useState([
    {
      id: 1,
      garageName: "",
      garageSqFt: "",
      garagePerimeterLinFt: "",
      garageCubicYard: "",
      drivewaysAndSideWalkSqFt: "",
      drivew: "",
    },
  ]);

  const handleNumGarageBlocksChange = (e) => {
    const newNumBlocks = parseInt(e.target.value, 10);
    setNumBlocksGarage(newNumBlocks > 0 ? newNumBlocks : 1);
    // Update buildings array length based on the number of blocks
    setGarage((prevBuildings) => {
      const updatedBuildings = [...prevBuildings];
      while (updatedBuildings.length < newNumBlocks) {
        const newIndex = updatedBuildings.length + 1;
        updatedBuildings.push({
          id: newIndex,
          garageName: "",
          garageSqFt: "",
          garagePerimeterLinFt: "",
          garageCubicYard: "",
          drivewaysAndSideWalkSqFt: "",
          drivew: "",
        });
      }
      return updatedBuildings.slice(0, newNumBlocks);
    });
  };

  const handleBuildingGarageFieldChange = (e, index, fieldName) => {
    const { value } = e.target;
    setGarage((prevBuildings) => {
      const updatedBuildings = [...prevBuildings];
      updatedBuildings[index] = {
        ...updatedBuildings[index],
        [fieldName]: value,
      };
      return updatedBuildings;
    });
  };

  const handleCreateCommonGarageStep1 = () => {
    const dataa = {
      noOfCommonGarage: numBlocksGarage,
      garages: garage,
    };
    dispatch(createCommonGarageStep1(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, CommonGarageInputModal: true });
      }
    });
  };

  const handleCommonGarageInputModalClose = () => {
    updateState({
      ...iState,
      CommonBuildingFoundationSummaryModal: true,
      CommonGarageInputModal: false,
    });
  };

  const handleCommonGarageQuantitiesModalShow = () => {
    updateState({
      ...iState,
      CommonGarageQuantitiesModal: true,
      CommonGarageInputModal: false,
    });
    dispatch(getCommonGarageStep2()).then((data) => {
      updateState({
        ...iState,
        commonGarageQuantity: data?.data?.data,
      });
    });
  };

  useEffect(() => {
    if (CommonGarageQuantitiesModal) {
      dispatch(getCommonGarageStep2()).then((data) => {
        updateState({
          ...iState,
          commonGarageQuantity: data?.data?.data,
        });
      });
    }
  }, [CommonGarageQuantitiesModal]);

  const handlecommonGarageQuantityChange = (e, ind, i, blocId) => {
    const { name, value } = e.target;
    const dummyQuantity = [...commonGarageQuantity];
    dummyQuantity[ind].ProformaBaseCosts[i].ProformaBlockQuantities[blocId] = {
      ...dummyQuantity[ind].ProformaBaseCosts[i].ProformaBlockQuantities[
        blocId
      ],
      [name]: value,
    };
    updateState({ ...iState, commonGarageQuantity: dummyQuantity });
  };

  const handleCreateCommonGarageStep2 = () => {
    const dataa = {
      commonGarageQuantity: commonGarageQuantity?.flatMap((item) =>
        item?.ProformaBaseCosts.flatMap((itm) =>
          itm?.ProformaBlockQuantities.map((itemm) => {
            return { id: itemm.id, quantity: itemm.quantity };
          })
        )
      ),
    };

    dispatch(createCommonGarageStep2(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          CommonGarageQuantitiesModal: false,
          CommonGarageSummaryModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, CommonGarageQuantitiesModal: true });
      }
    });
  };
  const handleCommonGarageQuantitiesModalClose = () => {
    updateState({
      ...iState,
      CommonGarageQuantitiesModal: true,
      CommonGarageInputModal: false,
    });
  };

  const handleCommonGarageSummaryModalShow = () => {
    updateState({
      ...iState,
      CommonGarageQuantitiesModal: false,
      CommonGarageSummaryModal: true,
    });
  };

  useEffect(() => {
    if (CommonGarageSummaryModal) {
      dispatch(getCommonGarageStep3());
    }
  }, [CommonGarageSummaryModal]);

  const handleCommonGarageSummaryModalClose = () => {
    updateState({
      ...iState,
      CommonGarageQuantitiesModal: true,
      CommonGarageSummaryModal: false,
    });
  };

  const handleMultilevelParkadeModalShow = () => {
    updateState({
      ...iState,
      MultilevelParkadeModal: true,
      CommonGarageSummaryModal: false,
    });
  };

  useEffect(() => {
    if (MultilevelParkadeModal) {
      dispatch(getMultiLevelStep1());
    }
  }, [MultilevelParkadeModal]);

  const [multiLevelParkade, setMultiLevelParkade] = useState(false);

  const handleRadioChange = (value) => {
    setMultiLevelParkade(value);
  };

  const [noOfMultiParkade, setNoOfMultiParkade] = useState(1);
  const [parkades, setParkades] = useState([
    {
      id: 1,
      footingTotalSqFt: "",
      footingTotalLinFt: "",
      footingYardsConcrete: "",
      wallYardsConcrete: "",
      slabYardsConcrete: "",
      heatedParking: true,
    },
  ]);

  const handleMultiParkadeChange = (e) => {
    const newNumBlocks = parseInt(e.target.value, 10);
    setNoOfMultiParkade(newNumBlocks > 0 ? newNumBlocks : 1);
    // Update buildings array length based on the number of blocks
    setParkades((prevBuildings) => {
      const updatedBuildings = [...prevBuildings];
      while (updatedBuildings.length < newNumBlocks) {
        const newIndex = updatedBuildings.length + 1;
        updatedBuildings.push({
          id: newIndex,
          footingTotalSqFt: "",
          footingTotalLinFt: "",
          footingYardsConcrete: "",
          wallYardsConcrete: "",
          slabYardsConcrete: "",
          heatedParking: true,
        });
      }
      return updatedBuildings.slice(0, newNumBlocks);
    });
  };

  const handleMultiParkadeFieldChange = (e, index, fieldName) => {
    const { value } = e.target;
    setParkades((prevBuildings) => {
      const updatedBuildings = [...prevBuildings];
      updatedBuildings[index] = {
        ...updatedBuildings[index],
        [fieldName]: value,
      };
      return updatedBuildings;
    });
  };

  const handleCreateMultiParkadeStep1 = () => {
    const dataa = {
      noOfMultiParkade,
      parkades,
      multiLevelParkade,
    };
    dispatch(createMultiLevelStep1(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, MultilevelParkadeModal: true });
      }
    });
  };

  const handleMultilevelParkadeModalClose = () => {
    updateState({
      ...iState,
      MultilevelParkadeModal: false,
      CommonGarageSummaryModal: true,
    });
  };

  const handleMultilevelParkadeQuantitiesModalShow = () => {
    updateState({
      ...iState,
      MultilevelParkadeModal: false,
      MultilevelParkadeQuantitiesModal: true,
    });
  };

  useEffect(() => {
    if (MultilevelParkadeQuantitiesModal) {
      dispatch(getMultiLevelStep2()).then((data) => {
        updateState({
          ...iState,
          MulitileveleParkadeQuantiesData: data?.data?.data,
        });
      });
    }
  }, [MultilevelParkadeQuantitiesModal]);

  const handleMultiLevelParkadeChange = (e, ind, i, blocId) => {
    const { name, value } = e.target;
    const dummyQuantity = [...MulitileveleParkadeQuantiesData];
    dummyQuantity[ind].ProformaBaseCosts[i].ProformaBlockQuantities[blocId] = {
      ...dummyQuantity[ind].ProformaBaseCosts[i].ProformaBlockQuantities[
        blocId
      ],
      [name]: value,
    };
    updateState({ ...iState, MulitileveleParkadeQuantiesData: dummyQuantity });
  };

  const handleCreateMultilLevelParkadeStep2 = () => {
    const dataa = {
      MulitileveleParkadeQuantiesData: MulitileveleParkadeQuantiesData?.flatMap(
        (item) =>
          item?.ProformaBaseCosts.flatMap((itm) =>
            itm?.ProformaBlockQuantities.map((itemm) => {
              return { id: itemm.id, quantity: itemm.quantity };
            })
          )
      ),
    };

    dispatch(createMultiLevelStep2(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          MultilevelParkadeQuantitiesModal: false,
          MulitileveleParkadeSummaryModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, MultilevelParkadeQuantitiesModal: true });
      }
    });
  };

  const handleMultilevelParkadeQuantitiesModalClose = () => {
    updateState({
      ...iState,
      MultilevelParkadeModal: true,
      MultilevelParkadeQuantitiesModal: false,
    });
  };

  const handleMulitileveleParkadeSummaryModalShow = () => {
    updateState({
      ...iState,
      MulitileveleParkadeSummaryModal: true,
      MultilevelParkadeQuantitiesModal: false,
    });
  };

  useEffect(() => {
    if (MulitileveleParkadeSummaryModal) {
      dispatch(getMultiLevelStep3());
    }
  }, [MulitileveleParkadeSummaryModal]);

  const handleMulitileveleParkadeSummaryModalClose = () => {
    updateState({
      ...iState,
      MulitileveleParkadeSummaryModal: false,
      MultilevelParkadeQuantitiesModal: true,
    });
  };

  const handleLandDevlopmentCostModalShow = () => {
    updateState({
      ...iState,
      LandDevlopmentCostModal: true,
      MulitileveleParkadeSummaryModal: false,
    });
  };

  useEffect(() => {
    if (LandDevlopmentCostModal) {
      dispatch(getLandDevelopmentStep1());
      updateState({
        ...iState,
        acres: landDevelopmentStep1List?.data?.acres ?? 0,
      });
    }
  }, [LandDevlopmentCostModal]);

  const handleAcresChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const handleCreateLandDevelopmentStep1 = () => {
    const dataa = { acres };
    dispatch(createLandDevelopmentStep1(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          LandDevlopmentQuantitiesModal: true,
          LandDevlopmentCostModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, LandDevlopmentCostModal: true });
      }
    });
  };

  const handleLandDevlopmentCostModalClose = () => {
    updateState({
      ...iState,
      CommonBuildingQuantitiesModal: true,
      LandDevlopmentCostModal: false,
    });
  };

  const handleLandDevlopmentQuantitiesModalShow = () => {
    updateState({
      ...iState,
      LandDevlopmentQuantitiesModal: true,
      LandDevlopmentCostModal: false,
    });
    dispatch(getLandDevelopmentStep2()).then((data) => {
      updateState({
        ...iState,
        LandDevlopmentQuantites: data?.data?.data,
      });
    });
  };

  useEffect(() => {
    if (LandDevlopmentQuantitiesModal) {
      dispatch(getLandDevelopmentStep2()).then((data) => {
        updateState({
          ...iState,
          LandDevlopmentQuantites: data?.data?.data,
        });
      });
    }
  }, [LandDevlopmentQuantitiesModal]);

  const handleLandDevelopmentQuantityChange = (e, ind, i, blocId) => {
    const { name, value } = e.target;
    const dummyQuantity = [...LandDevlopmentQuantites];
    dummyQuantity[ind].ProformaBaseCosts[i].ProformaBlockQuantities[blocId] = {
      ...dummyQuantity[ind].ProformaBaseCosts[i].ProformaBlockQuantities[
        blocId
      ],
      [name]: value,
    };
    console.log({ dummyQuantity });
    updateState({ ...iState, LandDevlopmentQuantites: dummyQuantity });
  };
  console.log({ LandDevlopmentQuantites });
  const handleLandDevlopmentStep2 = () => {
    const dataa = {
      LandDevlopmentQuantites: LandDevlopmentQuantites?.flatMap((item) =>
        item?.ProformaBaseCosts.flatMap((itm) =>
          itm?.ProformaBlockQuantities.map((itemm) => {
            return { id: itemm.id, quantity: itemm.quantity };
          })
        )
      ),
    };

    dispatch(createLandDevelopmentStep2(dataa)).then((res) => {
      if (res.status === 200) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          LandDevlopmentQuantitiesModal: false,
          LandDevlopmentCostPreviewModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, LandDevlopmentQuantitiesModal: true });
      }
    });
  };

  const handleLandDevlopmentQuantitiesModalClose = () => {
    updateState({
      ...iState,
      LandDevlopmentQuantitiesModal: false,
      LandDevlopmentCostModal: true,
    });
  };

  const handleLandDevlopmentCostPreviewModalShow = () => {
    updateState({
      ...iState,
      LandDevlopmentQuantitiesModal: false,
      LandDevlopmentCostPreviewModal: true,
    });
  };

  useEffect(() => {
    if (LandDevlopmentCostPreviewModal) {
      dispatch(getLandDevelopmentStep3());
    }
  }, [LandDevlopmentCostPreviewModal]);

  const handleLandDevlopmentCostPreviewModalClose = () => {
    updateState({
      ...iState,
      LandDevlopmentQuantitiesModal: true,
      LandDevlopmentCostPreviewModal: false,
    });
  };

  const handleProformaConfirmSummaryModalShow = () => {
    updateState({
      ...iState,
      ProformaConfirmSummaryModal: true,
      LandDevlopmentCostPreviewModal: false,
    });
  };

  useEffect(() => {
    if (ProformaConfirmSummaryModal) {
      dispatch(getProformaConfirmationSumarry());
    }
  }, [ProformaConfirmSummaryModal]);

  const handleProformaConfirmSummaryModalClose = () => {
    updateState({
      ...iState,
      ProformaConfirmSummaryModal: false,
      LandDevlopmentCostPreviewModal: true,
    });
  };

  const handleActiveProforma = () => {
    dispatch(activeProforma()).then((res) => {
      if (res.status === 200) {
        setPerFomaId(res?.data?.id);
        SetMultifamilyJobStep1Modal(true);

        updateState(initialState);

        toast.success("Active proforma Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        // updateState({initialState});
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        // updateState({ ...iState, LandDevlopmentQuantitiesModal: true });
      }
    });
  };

  const handleCreateJobsProjectsProforma = () => {
    dispatch(createProjectsJobs()).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({ initialState });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        // updateState({ ...iState, LandDevlopmentQuantitiesModal: true });
      }
    });
  };
  useEffect(() => {
    if (getdataBack.count) {
      dispatch(getPerforma(getdataBack.id)).then((data) => {
        console.log(data);

        updateLeadState({
          ...iLeadState,
          name: data.data.data.name,
          proformaTypeId: data.data.data.proformaTypeId,
          homeTypeId: data.data.data.homeTypeId,
          stateCode: data.data.data.stateCode,
          cityCode: data.data.data.cityCode,
          jobTimeZone: data.data.data.jobTimeZone,
          projectName: data.data.data.projectName,
        });
      });
    }
  }, [showcreateModal]);
  useEffect(() => {
    if (true) {
      dispatch(getPerformastep1block(getdataBack.id)).then((data) => {
        console.log(data);
        // updateLeadState({
        //   ...iLeadState,
        //   name:data.data.data.name,
        //   proformaTypeId: data.data.data.proformaTypeId,
        //   homeTypeId: data.data.data.homeTypeId,
        //   stateCode:data.data.data.stateCode,
        //   cityCode:data.data.data.cityCode,
        //   jobTimeZone:data.data.data.jobTimeZone,
        //   projectName:data.data.data.projectName,
        // })
      });
    }
  }, [showcreateModal]);
  useEffect(() => {
    if (proformaModal && count == 0) {
      count = 1;
      setShowCreateModal(true);
      setShow(false);
    }
  }, []);
  const handleStep1 = () => {
    firstStepCreateFunc();
  };

  const handleBlock = (e) => {
    blockListStep1Func(e);
    showProformaBlockStep2Modal();
  };

  const handleformaBlockstep2Back = () => {
    updateModalState({
      ...iModalState,
      ProformaBlockStep1Modal: true,
      ProformaBlockStep2Modal: false,
    });
  };
  const handleformaBlockstep3Back = () => {
    updateModalState({
      ...iModalState,
      ProformaBlockStep2Modal: true,
      ProformaBlockStep3Modal: false,
    });
  };
  console.log({ unitList });
  const handleProformaBlockFoundationModalShowstep1Back = () => {
    updateModalState({
      ...iModalState,

      ProformaBlockFoundationModal: false,
      ProformaBlockStep3Modal: true,
    });
  };
  const handleProformaBlockFoundationModal2Back = () => {
    console.log("sssssssss");
    updateState({
      ...iModalState,
      ProformaBlockFoundationQuantityModal: false,
      ProformaBlockFoundationModal: true,
    });
  };
  const handleProformaBlockFoundationModal3Back = () => {
    updateState({
      ...iModalState,
      ProformaBlockFoundationSummaryModal: false,
      ProformaBlockFoundationQuantityModal: true,
    });
  };

  const handleunitstep1Back = () => {
    updateState({
      ...iState,
      ProformaBlockFoundationSummaryModal: true,
      UnitInputsModal: false,
    });
  };

  const handleUnitOptionsInputsModalBack = () => {
    updateState({
      ...iState,
      UnitOptionsInputsModal: false,
      UnitInputsModal: true,
    });
  };

  const handleUnitOptionsModalBack = () => {
    updateState({
      ...iState,
      UnitOptionsInputsModal: true,
      UnitOptionQuantitiesModal: false,
    });
    dispatch(getUnitStep2()).then((data) => {
      console.log({ data });
      setUnitOption(data?.data?.data);
    });
  };

  const [showbtn, setshowbtn] = useState(false);
  const [showbtn1, setShowbtn1] = useState(false);
  const [showbtn2, setShowbtn2] = useState(false);

  const toggleButton1 = () => {
    setShowbtn1((prev) => !prev);
  };

  const toggleButton2 = () => {
    setShowbtn2((prev) => !prev);
  };
  const handleSetdataBlockFoundationModal = () => {
    const data = blockFoundationStep2.data.flatMap((val) =>
      val.ProformaCostHeadings.flatMap((heading) =>
        heading.ProformaBaseCosts.map((newVal) => ({
          id: newVal.costCodeId,
          quantity: Number(newVal.quantity),
        }))
      )
    );

    console.log({ data });
    dispatch(createBlockFoundationStep2(data));
  };
  console.log({ blockFoundationStep2 });
const handleProformaBlockFoundationStep2 = (e, dataId, ind, i, k, quantityId) => {
  const { name, value } = e.target;
  console.log({ blockFoundationStep2 }, { name }, { value });

  // Creating a deep copy of the relevant nested structure
  const newblockFoundationStep2 = { ...blockFoundationStep2 };
  newblockFoundationStep2.data = [...blockFoundationStep2.data];

  // Further deep copy the nested structure where changes will happen
  newblockFoundationStep2.data[ind] = {
    ...newblockFoundationStep2.data[ind],
    ProformaCostHeadings: [
      ...newblockFoundationStep2.data[ind].ProformaCostHeadings,
    ],
  };

  newblockFoundationStep2.data[ind].ProformaCostHeadings[i] = {
    ...newblockFoundationStep2.data[ind].ProformaCostHeadings[i],
    ProformaBaseCosts: [
      ...newblockFoundationStep2.data[ind].ProformaCostHeadings[i].ProformaBaseCosts,
    ],
  };

  // Handle unitName change
  if (name === "unitName") {
    newblockFoundationStep2.data[ind].ProformaCostHeadings[i].ProformaBaseCosts[k] = {
      ...newblockFoundationStep2.data[ind].ProformaCostHeadings[i].ProformaBaseCosts[k],
      unit: {
        ...newblockFoundationStep2.data[ind].ProformaCostHeadings[i].ProformaBaseCosts[k].unit,
        name: value,
      },
    };
  } else {
    // Handle quantity change
    newblockFoundationStep2.data[ind].ProformaCostHeadings[i].ProformaBaseCosts[k].ProformaBlockQuantities = 
      newblockFoundationStep2.data[ind].ProformaCostHeadings[i].ProformaBaseCosts[k].ProformaBlockQuantities.map(
        (quantityItem) =>
          quantityItem.id === quantityId ? { ...quantityItem, quantity: value } : quantityItem
      );
  }

  setBlockFoundationStep2(newblockFoundationStep2);
};
  const handleUnitStep4Back = () => {
    updateState((prev) => ({
      ...prev,
      UnitSummaryPreviewModal: false,
      UnitOptionQuantitiesModal: true,
    }));
  };
  const handleCommonareaStep1Back = () => {
    updateState((prev) => ({
      ...prev,
      UnitSummaryPreviewModal: true,
      CommonAreasModal: false,
    }));
  };
  const handleCommonareaStep2Back = () => {
    updateState((prev) => ({
      ...prev,
      CommonAreasModal: true,
      FoundationSummaryPreviewModal: false,
    }));
  };
  const handleCommonBuildingStep1Back = () => {
    updateState((prev) => ({
      ...prev,
      FoundationSummaryPreviewModal: true,
      CommonBuildingUnitsModal: false,
    }));
  };
  const handleCommonBuildingStep2Back = () => {
    updateState((prev) => ({
      ...prev,
      CommonBuildingUnitsModal: true,
      CommonBuildingQuantitiesModal: false,
    }));
  };
  const handleCommonBuildingStep3Back = () => {
    updateState((prev) => ({
      ...prev,
      CommonBuildingQuantitiesModal: true,
      CommonBuildingSummaryModal: false,
    }));
  };
  const handleCommonBuildingFoundationStep1Back = () => {
    updateState((prev) => ({
      ...prev,
      CommonBuildingFoundationModal: false,
      CommonBuildingSummaryModal: true,
    }));
  };
  const handleCommonBuildingFoundationStep2Back = () => {
    updateState((prev) => ({
      ...prev,
      CommonBuildingFoundationQuantitesModal: false,
      CommonBuildingFoundationModal: true,
    }));
  };
  const handleCommonBuildingFoundationStep3Back = () => {
    updateState((prev) => ({
      ...prev,
      CommonBuildingFoundationSummaryModal: false,
      CommonBuildingFoundationQuantitesModal: true,
    }));
  };
  const handleCommonGarageStep1Back = () => {
    updateState((prev) => ({
      ...prev,
      CommonGarageInputModal: false,
      CommonBuildingFoundationSummaryModal: true,
    }));
  };
  const handleCommonGarageStep2Back = () => {
    updateState((prev) => ({
      ...prev,
      CommonGarageQuantitiesModal: false,
      CommonGarageInputModal: true,
    }));
  };
  const handleCommonGarageStep3Back = () => {
    updateState((prev) => ({
      ...prev,
      CommonGarageSummaryModal: false,
      CommonGarageQuantitiesModal: true,
    }));
  };
  const handleMultiParkadeStep1Back = () => {
    updateState((prev) => ({
      ...prev,
      MultilevelParkadeModal: false,
      CommonGarageSummaryModal: true,
    }));
  };
  const handleMultiParkadeStep2Back = () => {
    updateState((prev) => ({
      ...prev,
      MultilevelParkadeQuantitiesModal: false,
      MultilevelParkadeModal: true,
    }));
  };
  const handleMultiParkadeStep3Back = () => {
    updateState((prev) => ({
      ...prev,
      MulitileveleParkadeSummaryModal: false,
      MultilevelParkadeQuantitiesModal: true,
    }));
  };
  const handleLandDevelopmentStep1Back = () => {
    updateState((prev) => ({
      ...prev,
      LandDevlopmentCostModal: false,
      MulitileveleParkadeSummaryModal: true,
    }));
  };
  const handleLandDevelopmentStep2Back = () => {
    updateState((prev) => ({
      ...prev,
      LandDevlopmentQuantitiesModal: false,
      LandDevlopmentCostModal: true,
    }));
  };
  const handleLandDevelopmentStep3Back = () => {
    updateState((prev) => ({
      ...prev,
      LandDevlopmentCostPreviewModal: false,
      LandDevlopmentQuantitiesModal: true,
    }));
  };
  const handleProformaConfirmSummaryBack = () => {
    updateState((prev) => ({
      ...prev,
      ProformaConfirmSummaryModal: false,
      LandDevlopmentCostPreviewModal: true,
    }));
  };
  const handleMainClose = () => {
    count = 0;
    setProformaModal(false);
    localStorage.removeItem("profomaId");
  };

  const handleShow = (modal1, modal2) => {
    updateState((prev) => ({
      ...prev,
      [modal1]: false,
      [modal2]: true,
    }));
  };

  //====================================
  // const isSelected = (a,b,c,d)=>{
  //   console.log('a',a,'b',a,'c',c,'d',d)
  // }
    const data =
      proformaBlockStep3Data?.data?.table1?.proforma?.MultiBlocks || [];
    const totals =
      proformaBlockStep3Data?.data?.table1?.totals?.[0] || {};
    console.log({totals});
    // Labels and corresponding keys for each row
    const rows = [
      { label: "Foundation Type", key: "FoundationType", subKey: "name" },
      {
        label: "# Units Per Block",
        key: "numOfUnits",
        totalKey: "totalNumOfUnits",
      },
      {
        label: "# Stories Per Block",
        key: "numOfStories",
        totalKey: "totalNumOfStories",
      },
      {
        label: "Foundation & Subfloor Sq/Ft",
        key: "foundationAndSubFloor",
        totalKey: "totalFoundationAndSubFloor",
      },
      {
        label: "Block Total Sq/Ft",
        key: "blockTotal",
        totalKey: "totalBlockTotal",
      },
      {
        label: "Common Areas Sq/Ft",
        key: "commonAreas",
        totalKey: "totalCommonAreas",
      },
      { label: "Roof Sq/Ft", key: "roof", totalKey: "totalRoof" },
      {
        label: "Exterior Walls Sq/Ft",
        key: "exteriorWalls",
        totalKey: "totalExteriorWalls",
      },
    ];
    console.log({blockFoundationStep1List});
  return (
    <>
      <Modal
        show={showcreateModal}
        onHide={hideMainCreateModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideMainCreateModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span className="Icon">
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlocksInput"
                    data-dismiss="modal"
                  >
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonAreasInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonGarageInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaMultiLevelParkadeInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaLandDevelopmentAdminCostsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaConfirmation"
                    data-dismiss="modal"
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea">
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <h6>
                        Proforma Name<span className="required">*</span>
                      </h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert Proforma name"
                        name="name"
                        value={name}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {firstStageErrors?.nameError}
                      </span>
                    </div>
                    <div className="form-group">
                      <h6>
                        Type <span className="required">*</span>
                      </h6>
                      <div className="CommonSelectBox">
                        <select
                          name="proformaTypeId"
                          value={proformaTypeId}
                          onChange={handleInput}
                        >
                          <option>Select Type</option>
                          {checkArray(proformaTypesListData?.data) ? (
                            <>
                              {proformaTypesListData?.data?.map((ele, i) => {
                                return (
                                  <option value={ele?.id}>{ele?.name}</option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        {/* <span>
                          <img src="images/down.png" />
                        </span> */}
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {firstStageErrors?.proformaTypeIdError}
                      </span>
                    </div>
                    <div className="form-group">
                      <h6>
                        Home Type <span className="required">*</span>
                      </h6>
                      <div className="CommonSelectBox">
                        <select
                          name="homeTypeId"
                          value={homeTypeId}
                          onChange={handleInput}
                        >
                          <option>Select Home type</option>
                          {checkArray(homeListData) &&
                            homeListData?.map((ele, i) => {
                              return (
                                <>
                                  <option value={ele?.id}>{ele?.name}</option>
                                </>
                              );
                            })}
                        </select>
                        {/* <span>
                          <img src="images/down.png" />
                        </span> */}
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {firstStageErrors?.homeTypeIdError}
                      </span>
                    </div>
                    <div className="form-group">
                      <h6>
                        Country <span className="required">*</span>
                      </h6>
                      <div className="CommonSelectBox">
                        <select
                          name="country"
                          value={country}
                          onChange={handleInput}
                        >
                          <option>Select Country</option>
                          {checkArray(countryData?.countries) ? (
                            <>
                              {countryData?.countries?.map((ele, i) => {
                                return (
                                  <option value={ele?.name}>{ele?.name}</option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        {/* <span>
                          <img src="images/down.png" />
                        </span> */}
                      </div>
                      {/* <span style={{color:"red", fontSize:"14px"}}>{firstStageErrors?.countryError}</span> */}
                    </div>
                    <div className="form-group">
                      <h6>
                        State/Province <span className="required">*</span>
                      </h6>
                      <div className="CommonSelectBox">
                        <select
                          name="stateCode"
                          value={stateCode}
                          onChange={handleInput}
                        >
                          <option>Select State</option>
                          {checkArray(stateData?.states) ? (
                            <>
                              {stateData?.states?.map((ele, i) => {
                                return (
                                  <option
                                    value={ele?.name}
                                    selected={stateCode}
                                  >
                                    {ele?.name}
                                  </option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        {/* <span>
                          <img src="images/down.png" />
                        </span> */}
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {firstStageErrors?.stateCodeError}
                      </span>
                    </div>
                    <div className="form-group">
                      <h6>
                        City <span className="required">*</span>
                      </h6>
                      <div className="CommonSelectBox">
                        <select
                          name="cityCode"
                          value={cityCode}
                          onChange={handleInput}
                        >
                          <option>Select City</option>
                          {checkArray(cityData?.cities) ? (
                            <>
                              {cityData?.cities?.map((ele, i) => {
                                return (
                                  <option value={ele?.name}>{ele?.name}</option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        {/* <span>
                          <img src="images/down.png" />
                        </span> */}
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {firstStageErrors?.cityCodeError}
                      </span>
                    </div>
                    <div className="form-group">
                      <h6>
                        Job Site GMT (UTC) Timezone{" "}
                        <span className="required">*</span>
                      </h6>
                      <div className="CommonSelectBox">
                        <select
                          name="jobTimeZone"
                          value={jobTimeZone}
                          onChange={handleInput}
                        >
                          <option>Select Timezone</option>
                          {checkArray(timeZoneData?.data) ? (
                            <>
                              {timeZoneData?.data?.map((ele, i) => {
                                return (
                                  <option value={ele?.gmtOffset}>
                                    {ele?.zoneName}
                                  </option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        {/* <span>
                          <img src="images/down.png" />
                        </span> */}
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {firstStageErrors?.jobTimeZoneError}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-5 ml-auto">
                    <div className="form-group">
                      <h6>Name of Project</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Name of Project"
                        name="projectName"
                        value={projectName}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {firstStageErrors?.projectNameError}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button className="Outline" onClick={hideMainCreateModal}>
                  Cancel
                </button>

                <button
                  className="Create FolderPermissionId"
                  onClick={handleStep1}
                >
                  Save & Next
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ProformaBlockStep1Modal}
        onHide={hideProformaBlockStep1Modal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonAreasInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonGarageInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaMultiLevelParkadeInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaLandDevelopmentAdminCostsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaConfirmation"
                    data-dismiss="modal"
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">Block Input</span>
                    </li>
                    <li className="">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Block <br /> Quantities
                      </span>
                    </li>
                    <li className="">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_summary.png")}
                        />
                      </span>
                      <span className="Text">
                        Block <br /> Summary <br /> Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalBigheading2 d-flex align-items-center mb-3">
                  <h3 className="mb-0">
                    Enter Number of Blocks in the Project
                  </h3>
                  <input
                    className="ModalBigheadingBox"
                    name="blockFirstStepNumber"
                    value={blockFirstStepNumber}
                    onChange={handleInputNumber}
                  />
                </div>
                <div
                  className="EnterProjectTable"
                  style={{ overflowX: "scroll" }}
                >
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Block Description</th>
                        <th>Foundation Type</th>
                        <th># of Units Per Block</th>
                        <th># of Stories Per Block</th>
                        <th>Foundation &amp; Subfloor Sq/Ft</th>
                        <th>Block Total Sq/Ft</th>
                        <th>Common Areas Sq/Ft</th>
                        <th>Roof Sq/Ft</th>
                        <th>Exterior Walls Sq/Ft</th>
                      </tr>
                    </thead>
                    <tbody>
                      {blockfirstStep?.map((ele, i) => {
                        return (
                          <>
                            <tr>
                              <td>{i + 1}</td>
                              <td>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Block 1"
                                    name="blockDescription"
                                    value={ele?.blockDescription}
                                    onChange={(e) =>
                                      handleBlockFirstStepChange(e, i)
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="CommonSelectBox width-250">
                                  <select
                                    name="foundationType"
                                    value={ele?.foundationType}
                                    onChange={(e) =>
                                      handleBlockFirstStepChange(e, i)
                                    }
                                  >
                                    <option>Select Foundation</option>
                                    {checkArray(foundationListData) ? (
                                      <>
                                        {foundationListData?.map((ele, i) => {
                                          return (
                                            <option value={ele?.id}>
                                              {ele?.name}
                                            </option>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </select>
                                  {/* <span>
                                    <img src="images/down.png" />
                                  </span> */}
                                </div>
                              </td>
                              <td>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={3}
                                    name="numOfUnits"
                                    value={ele?.numOfUnits}
                                    onChange={(e) =>
                                      handleBlockFirstStepChange(e, i)
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control width-210"
                                    placeholder="Insert number of Sq/Ft"
                                    name="numOfStories"
                                    value={ele?.numOfStories}
                                    onChange={(e) =>
                                      handleBlockFirstStepChange(e, i)
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control "
                                    placeholder="Insert number of Sq/Ft"
                                    name="foundationAndSubFloor"
                                    value={ele?.foundationAndSubFloor}
                                    onChange={(e) =>
                                      handleBlockFirstStepChange(e, i)
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control width-210"
                                    placeholder="Insert number of Sq/Ft"
                                    name="blockTotal"
                                    value={ele?.blockTotal}
                                    onChange={(e) =>
                                      handleBlockFirstStepChange(e, i)
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control width-210"
                                    placeholder="Insert number of Sq/Ft"
                                    name="commonAreas"
                                    value={ele?.commonAreas}
                                    onChange={(e) =>
                                      handleBlockFirstStepChange(e, i)
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control width-210"
                                    placeholder="Insert number of Sq/Ft"
                                    name="roof"
                                    value={ele?.roof}
                                    onChange={(e) =>
                                      handleBlockFirstStepChange(e, i)
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control width-210"
                                    placeholder="Insert number of Sq/Ft"
                                    name="exteriorWalls"
                                    value={ele?.exteriorWalls}
                                    onChange={(e) =>
                                      handleBlockFirstStepChange(e, i)
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="PaginationArea px-3">
                    <h6>Showing 1 to 10 of 27 entries</h6>
                    <h6>
                      <img src="images/leftarrow.png" /> 01{" "}
                      <img src="images/rightarrow.png" /> Out 10
                    </h6>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button
                  className="Outline"
                  onClick={hideProformaBlockStep1Modal}
                >
                  Cancel
                </button>

                <button
                  className="Create FolderPermissionId"
                  onClick={(e) => handleBlock(e)}
                >
                  Save And Next
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ProformaBlockStep2Modal}
        onHide={hideProformaBlockStep2Modal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonAreasInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonGarageInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaMultiLevelParkadeInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaLandDevelopmentAdminCostsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaConfirmation"
                    data-dismiss="modal"
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">Block Input</span>
                    </li>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Block <br /> Quantities
                      </span>
                    </li>
                    <li className="">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_summary.png")}
                        />
                      </span>
                      <span className="Text">
                        Block <br /> Summary <br /> Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalMediumheading2 d-flex align-items-center justify-content-between mb-3 pl-4">
                  <h3 className="mb-0">Block Quantities</h3>
                  <h3 className="mb-0">
                    {" "}
                    <img src={require("../assets/images/cash-flow.png")} />
                    &nbsp;&nbsp;Customize Base Costs
                  </h3>
                </div>
                <div className="ProformaAccordion">
                  <div className="accordion" id="accordionExample">
                    {blockQuantitiesData?.length > 0 &&
                      blockQuantitiesData?.map((item, ind) => {
                        return (
                          <div className="card">
                            <div className="card-header" id="headingOne">
                              <h2 className="mb-0">
                                <button
                                  className="btn btn-link btn-block text-left collapsed"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseOne"
                                  aria-expanded="false"
                                  aria-controls="collapseOne"
                                >
                                  {item.name}
                                </button>
                              </h2>
                              <a href="javascript:void(0);">
                                <img
                                  src={require("../assets/images/book-search.png")}
                                />
                              </a>
                            </div>
                            <div
                              id="collapseOne"
                              className="collapse show"
                              aria-labelledby="headingOne"
                              data-parent="#accordionExample"
                              style={{}}
                            >
                              <div className="card-body">
                                <div
                                  className="EnterProjectTable"
                                  style={{ overflowX: "scroll" }}
                                >
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Cost Code #</th>
                                        <th>Description</th>
                                        <th>Unit of measaure</th>
                                        {item?.ProformaBaseCosts?.[0]?.ProformaBlockQuantities?.map(
                                          (block) => {
                                            return (
                                              <th>
                                                {
                                                  block?.MultiBlock
                                                    ?.blockDescription
                                                }
                                              </th>
                                            );
                                          }
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item?.ProformaBaseCosts?.length > 0 &&
                                        item?.ProformaBaseCosts?.map(
                                          (datas, i) => {
                                            return (
                                              <tr>
                                                <td>
                                                  {datas.CostCode?.number}
                                                </td>
                                                <td>{datas.CostCode?.name}</td>
                                                <td>
                                                  <div className="CommonSelectBox width-250">
                                                    <select
                                                      name="unitId"
                                                      onChange={(e) =>
                                                        handleBlockQuantityChange(
                                                          e,
                                                          ind,
                                                          i,
                                                          0
                                                        )
                                                      }
                                                    >
                                                    
                                                      {unitList?.length > 0 &&
                                                        unitList?.map(
                                                          (data, i) => {
                                                            return (
                                                              <option
                                                                key={i}
                                                                value={data.id}
                                                                selected={
                                                                  data.id ===
                                                                  datas.Unit.id
                                                                }
                                                              >
                                                                {data.name}
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                    </select>
                                                    {/* <span>
                                                      <img src="images/down.png" />
                                                    </span> */}
                                                  </div>
                                                </td>
                                                {/* {
                                                        item.data[1].ProformaBaseCosts[0].ProformaBlockQuantities[0].quantity
                                                      } */}
                                                {datas?.ProformaBlockQuantities
                                                  ?.length > 0 &&
                                                  datas?.ProformaBlockQuantities?.map(
                                                    (block, blocId) => {
                                                      return (
                                                        <td key={blocId}>
                                                          <div className="form-group">
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Insert Quality"
                                                              name="quantity"
                                                              value={
                                                                block.quantity
                                                              }
                                                              onChange={(e) =>
                                                                handleBlockQuantityChange(
                                                                  e,
                                                                  ind,
                                                                  i,
                                                                  blocId
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </td>
                                                      );
                                                    }
                                                  )}
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                  {/* <div className="PaginationArea px-3">
                                    <h6>Showing 1 to 10 of 27 entries</h6>
                                    <h6>
                                      <img src="images/leftarrow.png" /> 01{" "}
                                      <img src="images/rightarrow.png" /> Out 10
                                    </h6>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button className="Outline" onClick={handleformaBlockstep2Back}>
                  Cancel
                </button>

                <button
                  className="Create FolderPermissionId"
                  onClick={() => {
                    showProformaBlockStep3Modal();
                    handleCreateBlockStep2();
                  }}
                >
                  Save & Next
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ProformaBlockStep3Modal}
        onHide={hideProformaBlockStep3Modal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonAreasInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonGarageInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaMultiLevelParkadeInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaLandDevelopmentAdminCostsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaConfirmation"
                    data-dismiss="modal"
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaBlocksInput"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">Block Input</span>
                    </li>
                    <li className="active">
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaBlocksQuantities"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Block <br /> Quantities
                      </span>
                    </li>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_summary.png")}
                        />
                      </span>
                      <span className="Text">
                        Block <br /> Summary <br /> Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalMediumheading d-flex align-items-center justify-content-between">
                  <h3>
                    <span>Block Inputs</span>
                  </h3>
                  <h6>
                    <img src="images/details-cost.png" />
                    Detailed Costs
                  </h6>
                </div>
                <div className="BlockInputs">
                   <table>
      <thead>
        <tr>
          <th>Input</th>
          {data.map((block,index) => (
            <th key={index}>{block?.blockDescription}</th>
          ))}
          <th>Totals</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr key={index}>
            <td>{row.label}</td>
            {data.map((item, ind) => (
              <td key={ind}>{row.subKey ? item[row.key]?.[row.subKey] : item[row.key]}</td>
            ))}
            <td>{totals[row.totalKey]}</td>
          </tr>
        ))}
      </tbody>
    </table>
                </div>
                <div className="BlockCostsPreview">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4>Block Costs Preview</h4>
                    <div className="NewActions">
                      <a href="javascript:void(0);" title="pdf">
                        <img src="images/pdf.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/download.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/setting.png" />
                      </a>
                    </div>
                  </div>
                  {proformaBlockStep3Data?.data?.table2?.blockQuantities
                    ?.length > 0 &&
                    proformaBlockStep3Data?.data?.table2?.blockQuantities?.map(
                      (data, ind) => {
                        return (
                          <div className="BlockInputsCollapse">
                            <div
                              className="BcpHeader"
                              data-toggle="collapse"
                              href="#collapseExample"
                              aria-expanded="true"
                              aria-controls="collapseExample"
                            >
                              <h5>{data.name}</h5>
                            </div>
                            <div
                              className="collapse show"
                              id="collapseExample"
                              style={{}}
                            >
                              <div className="card card-body border-0">
                                <div className="BlockInputs">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Code Code #</th>
                                        <th>Description</th>
                                       
                                        {data?.ProformaBaseCosts?.[0]?.ProformaBlockQuantities?.map(
                                          (block) => {
                                            return (
                                              <th>
                                                {
                                                  block?.MultiBlock
                                                    ?.blockDescription
                                                }
                                              </th>
                                            );
                                          }
                                        )}
                                        {/* <th>Block B</th>
                        <th>Block C</th>
                        <th>Block D</th>
                        <th>Total Construction Costs</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {data?.ProformaBaseCosts?.length > 0 &&
                                        data?.ProformaBaseCosts?.map(
                                          (data, ind) => {
                                            return (
                                              <tr>
                                                <td>{data.CostCode?.number}</td>
                                                <td>{data.CostCode?.name}</td>
                                                
                                                  {
                                                    data
                                                      .ProformaBlockQuantities?.map(total=>{
                                                        return <td>${ total?.totalCost}</td>
                                                      })
                                                  }
                                                

                                                
                                              </tr>

                                            );
                                          }
                                        )}
                                        
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td></td>
                                        <td>{data.name}</td>
                                        <td>

                                      ${data?.ProformaBaseCosts?.length > 0 &&
                                        data?.ProformaBaseCosts?.reduce(
                                          (a, b) => {
                                            return a+b?.totalCostSum
                                          },0)
                                        }
                                        </td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}

                  <div className="BlockInputs Red">
                    <table>
                      <tfoot>
                        <tr>
                          <td />
                          <td>Grand Total</td>
                          {
                            proformaBlockStep3Data?.data?.table2?.grandTotal?.map(total=>{
                              return <td>${total?.wholeCost}</td>
                            })
                          }
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button className="Outline" onClick={handleformaBlockstep3Back}>
                  Cancel
                </button>

                <button
                  className="Create FolderPermissionId"
                  onClick={handleProformaBlockFoundationModalShow}
                >
                  Save & Foundation
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ProformaBlockFoundationModal}
        onHide={handleProformaBlockFoundationModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlocksInput"
                    data-dismiss="modal"
                  >
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonAreasInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonGarageInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaMultiLevelParkadeInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaLandDevelopmentAdminCostsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaConfirmation"
                    data-dismiss="modal"
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Block <br /> Foundation <br /> Input
                      </span>
                    </li>
                    <li className="">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Block <br /> Foundation <br /> Quantities
                      </span>
                    </li>
                    <li className="">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_summary.png")}
                        />
                      </span>
                      <span className="Text">
                        Blocks <br />
                        Foundation <br />
                        Summary <br />
                        Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalBigheading pl-4 mb-3">
                  <h3 className="mb-0">
                    <span>Blocks Foundation Inputs</span>
                  </h3>
                </div>
                <div className="ProformaAccordion">
                  <div className="accordion" id="accordionExample">
                    {blockFoundationStep1List?.data?.length > 0 &&
                      blockFoundationStep1List?.data?.map((data, ind) => {
                        return (
                          <div className="card">
                            <div className="card-header" id="headingOne">
                              <h2 className="mb-0">
                                <button
                                  className="btn btn-link btn-block text-left collapsed"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseOne"
                                  aria-expanded="false"
                                  aria-controls="collapseOne"
                                >
                                  {data.name}
                                </button>
                              </h2>
                            </div>
                            <div
                              id="collapseOne"
                              className="collapse"
                              aria-labelledby="headingOne"
                              data-parent="#accordionExample"
                              style={{}}
                            >
                              <div className="card-body">
                                <div
                                  className="EnterProjectTable"
                                  style={{ overflowX: "scroll" }}
                                >
                                  <table>
                                    <thead>
                                      <tr >
                                      <th></th>
                                        {data?.FoundationPerimeters?.[0]?.BlockFoundations?.length > 0 &&
                                        data?.FoundationPerimeters?.[0]?.BlockFoundations?.map(
                                          (item, i) => {
                                            return (
                                                <th>
                                                {item?.MultiBlock?.blockDescription}
                                                </th>
                                               
                                            );
                                          }
                                        )}
                                     </tr>
                                       
                                    </thead>
                                    <tbody>
                                      {data?.FoundationPerimeters?.length > 0 &&
                                        data?.FoundationPerimeters?.map(
                                          (item, i) => {
                                            return (
                                              <tr key={i}>
                                                <td>{item.name}</td>
                                                {item?.BlockFoundations?.map(
                                                  (blocks, index) => {
                                                    return (
                                                      <td key={index}>
                                                        <div className="form-group">
                                                          <input
                                                            type="text"
                                                            className="form-control width-250"
                                                            placeholder="Insert cubic yards"
                                                            defaultValue={
                                                              blocks.value
                                                            }
                                                            value={
                                                              foundationValues
                                                                .find(
                                                                  (
                                                                    foundation
                                                                  ) =>
                                                                    foundation.id ===
                                                                    data.id
                                                                )
                                                                ?.blocks.find(
                                                                  (block) =>
                                                                    block.id ===
                                                                    blocks.id
                                                                )?.value
                                                            }
                                                            onChange={(e) =>
                                                              handleInputChange(
                                                                data.id,
                                                                blocks.id,
                                                                e.target.value
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </td>
                                                    );
                                                  }
                                                )}
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                  <div className="PaginationArea pl-0">
                                    <h6>Showing 1 to 10 of 27 entries</h6>
                                    <h6>
                                      <img src="images/leftarrow.png" /> 01{" "}
                                      <img src="images/rightarrow.png" /> Out 10
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button
                  className="Outline"
                  onClick={handleProformaBlockFoundationModalShowstep1Back}
                >
                  Back
                </button>

                <button
                  className="Create FolderPermissionId"
                  onClick={() => {
                    handleCreateBlockFoundationStep1();
                    handleProformaBlockFoundationModalQuantityShow();
                  }}
                >
                  Save & Next
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ProformaBlockFoundationQuantityModal}
        onHide={handleProformaBlockFoundationModalQuantityClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlocksInput"
                    data-dismiss="modal"
                  >
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonAreasInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonGarageInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaMultiLevelParkadeInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaLandDevelopmentAdminCostsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaConfirmation"
                    data-dismiss="modal"
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Block <br /> Foundation <br /> Input
                      </span>
                    </li>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Block <br /> Foundation <br /> Quantities
                      </span>
                    </li>
                    <li className="">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_summary.png")}
                        />
                      </span>
                      <span className="Text">
                        Blocks <br />
                        Foundation <br />
                        Summary <br />
                        Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalBigheading pl-4 mb-3 d-flex justify-content-between align-items-center">
                  <h3 className="mb-0">
                    <span>Blocks Foundation Inputs</span>
                  </h3>
                  <h3 className="mb-0">
                    <span>
                      {" "}
                      <img
                        src={require("../assets/images/book-search.png")}
                      />{" "}
                      Customize Base Costs
                    </span>
                  </h3>
                </div>
                <div className="ProformaAccordion">
                  <div className="accordion" id="accordionExample">
                    {blockFoundationStep2?.data?.length > 0 &&
                      blockFoundationStep2?.data?.map((data, ind) => {
                        return (
                          <div className="card">
                            <div className="card-header" id="headingMain">
                              <h2 className="mb-0">
                                <button
                                  className={`btn btn-link btn-block text-left `}
                                  data-toggle="collapse"
                                  data-target="#collapseMain"
                                  aria-expanded="false"
                                  aria-controls="collapseMain"
                                >
                                  {data.name}
                                </button>
                              </h2>
                              <a href="javascript:void(0);">
                                <img
                                  src={require("../assets/images/book-search.png")}
                                  alt="Book Search"
                                />
                              </a>
                            </div>
                            <div
                              id="collapseMain"
                              className="collapse"
                              aria-labelledby="headingMain"
                              data-parent="#accordionExample"
                            >
                              <div className="card-body">
                                <div>
                                  {data?.ProformaCostHeadings?.length > 0 &&
                                    data?.ProformaCostHeadings?.map(
                                      (item, i) => {
                                        const itemHeadingId = `heading-${i}`;
                                        const itemCollapseId = `collapse-${i}`;

                                        return (
                                          <div className="card" key={i}>
                                            <div
                                              className="card-header"
                                              id={itemHeadingId}
                                            >
                                              <h2 className="mb-0">
                                                <button
                                                  className={`btn btn-link btn-block text-left collapsed aria-expanded=${
                                                    showbtn2 ? "false" : "true"
                                                  }`}
                                                  type="button"
                                                  data-toggle="collapse"
                                                  data-target={`#${itemCollapseId}`}
                                                  aria-expanded="false"
                                                  aria-controls={itemCollapseId}
                                                  onClick={toggleButton2}
                                                >
                                                  {item.name}
                                                </button>
                                              </h2>
                                              <a href="javascript:void(0);">
                                                <img
                                                  src={require("../assets/images/book-search.png")}
                                                  alt="Book Search"
                                                />
                                              </a>
                                            </div>
                                            <div>
                                              <div className="card-body">
                                                <div
                                                  className="EnterProjectTable"
                                                  style={{
                                                    overflowX: "scroll",
                                                  }}
                                                >
                                                  <table>
                                                    <thead>
                                                      <tr>
                                                        <th>Cost Code #</th>
                                                        <th>Description</th>
                                                        <th>
                                                          <div className="ml-5">
                                                            Unit of measure
                                                          </div>
                                                        </th>
                                                        {item?.ProformaBaseCosts?.[0]?.ProformaBlockQuantities?.map(
                                                          (blocks, j) => (
                                                            <th key={j}>
                                                              {
                                                                blocks
                                                                  ?.MultiBlock
                                                                  ?.blockDescription
                                                              }
                                                            </th>
                                                          )
                                                        )}
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {item?.ProformaBaseCosts?.map(
                                                        (datas, k) => (
                                                          <tr key={k}>
                                                            <td>
                                                              {
                                                                datas.CostCode
                                                                  ?.number
                                                              }
                                                            </td>
                                                            <td>
                                                              {
                                                                datas.CostCode
                                                                  ?.name
                                                              }
                                                            </td>
                                                            <td>
                                                              <div className="CommonSelectBox width-250 ml-5">
                                                                <select
                                                                  name="unitName"
                                                                 
                                                                >
                                                                 
                                                                  {unitList?.length >
                                                                    0 &&
                                                                    unitList.map(
                                                                      (
                                                                        data,
                                                                        index
                                                                      ) => (
                                                                        <option
                                                                          key={
                                                                            index
                                                                          }
                                                                          value={
                                                                            datas?.Unit?.id
                                                                          }
                                                                        >
                                                                          {
                                                                            data.name
                                                                          }
                                                                        </option>
                                                                      )
                                                                    )}
                                                                </select>
                                                              </div>
                                                            </td>
                                                            {
                                                              datas.ProformaBlockQuantities?.map((iitem)=>{
                                                                return <td>
                                                                <div className="form-group">
                                                                <input
                                                                  type="text"
                                                                  className="form-control width-250"
                                                                  placeholder="Insert Quantity"
                                                                  name="quantity"
                                                                  value={  iitem.quantity || 0}
                                                                  onChange={( e ) =>
                                                                    handleProformaBlockFoundationStep2(
                                                                      e,
                                                                      datas.id,
                                                                      ind,
                                                                      i,
                                                                      k,
                                                                       iitem.id
                                                                    )
                                                                  }
                                                                />
                                                              </div>
                                                            </td>
                                                              })
                                                            }
                                             
                                                          </tr>
                                                        )
                                                      )}
                                                    </tbody>
                                                  </table>
                                                  <div className="PaginationArea pl-0">
                                                    <h6>
                                                      Showing 1 to 10 of 27
                                                      entries
                                                    </h6>
                                                    <h6>
                                                      <img
                                                        src="images/leftarrow.png"
                                                        alt="Left Arrow"
                                                      />{" "}
                                                      01{" "}
                                                      <img
                                                        src="images/rightarrow.png"
                                                        alt="Right Arrow"
                                                      />{" "}
                                                      Out 10
                                                    </h6>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button
                  className="Outline"
                  onClick={handleProformaBlockFoundationModal2Back}
                >
                  Back
                </button>

                <button
                  className="Create FolderPermissionId"
                  onClick={() => {
                    handleProformaBlockFoundationModalSummaryShow();
                    handleSetdataBlockFoundationModal();
                  }}
                >
                  Save & Next
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ProformaBlockFoundationSummaryModal}
        onHide={handleProformaBlockFoundationModalSummaryClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlocksInput"
                    data-dismiss="modal"
                  >
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonAreasInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonGarageInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaMultiLevelParkadeInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaLandDevelopmentAdminCostsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaConfirmation"
                    data-dismiss="modal"
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Block <br /> Foundation <br /> Input
                      </span>
                    </li>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Block <br /> Foundation <br /> Quantities
                      </span>
                    </li>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_summary.png")}
                        />
                      </span>
                      <span className="Text">
                        Blocks <br />
                        Foundation <br />
                        Summary <br />
                        Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                {blockFoundationStep3List?.data?.table1?.foundation?.length >
                  0 &&
                  blockFoundationStep3List?.data?.table1?.foundation?.map(
                    (data, ind) => {
                      return (
                        <>
                          <div className="ModalMediumheading d-flex align-items-center justify-content-between">
                            <h3>
                              <span>{data.name}</span>
                            </h3>
                            <h6>
                              <img src="images/details-cost.png" />
                              Detailed Costs
                            </h6>
                          </div>
                          <div className="BlockInputs">
                            <table>
                              <thead>
                                <tr>
                                  <th>Input</th>

                                  {data?.FoundationPerimeters?.[0]
                                    ?.BlockFoundations?.length > 0 &&
                                    data?.FoundationPerimeters?.[0]?.BlockFoundations?.map(
                                      (block) => {
                                        return (
                                          <th>
                                            {
                                              block?.MultiBlock
                                                ?.blockDescription
                                            }
                                          </th>
                                        );
                                      }
                                    )}
                                  <th>Totals</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.FoundationPerimeters?.length > 0 &&
                                  data?.FoundationPerimeters?.map(
                                    (item, ind) => {
                                      return (
                                        <tr key={ind}>
                                          <td>Footing Cubic/Yards </td>
                                          {item?.BlockFoundations?.length > 0 &&
                                            item?.BlockFoundations?.map(
                                              (block) => {
                                                return <td>{block.value}</td>;
                                              }
                                            )}

                                          {item?.BlockFoundations?.length > 0 &&
                                            item?.BlockFoundations?.map(
                                              (block) => Number(block.value)
                                            ) // Convert value to number
                                              .reduce(
                                                (acc, currentValue) =>
                                                  acc + currentValue,
                                                0
                                              )}
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </>
                      );
                    }
                  )}

                <div className="BlockCostsPreview">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4>Blocks Foundation Costs Preview</h4>
                    <div className="NewActions">
                      <a href="javascript:void(0);" title="pdf">
                        <img src="images/pdf.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/download.png" />
                      </a>
                    </div>
                  </div>
                  <div className="BlockInputsCollapse">
                    <div
                      className="BcpHeader collapsed"
                      data-toggle="collapse"
                      href="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      <h5>Basement Foundation - Block Foundation Costs</h5>
                    </div>
                    <div
                      // className="collapse"
                      id="collapseExample"
                      style={{}}
                    >
                      <div className="card card-body border-0">
                        <div className="BlockInputs">
                          <table>
                            <thead>
                              <tr>
                                <th>Code Code #</th>
                                <th>Description</th>
                                <th>Block A</th>
                                <th>Block C</th>
                                <th>Total Construction Costs</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>4.0102</td>
                                <td>Windows. and Exterlor Doors</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                              </tr>
                              <tr>
                                <td>4.0102</td>
                                <td>Windows. and Exterlor Doors</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                              </tr>
                              <tr>
                                <td>4.0102</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100.00</td>
                                <td>2,100.00</td>
                                <td>8,400.00</td>
                              </tr>
                              <tr>
                                <td>4.0102</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100.00</td>
                                <td>2,100.00</td>
                                <td>8,400.00</td>
                              </tr>
                              <tr>
                                <td>4.0102</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100.00</td>
                                <td>2,100.00</td>
                                <td>8,400.00</td>
                              </tr>
                              <tr>
                                <td>4.0102</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100.00</td>
                                <td>2,100.00</td>
                                <td>8,400.00</td>
                              </tr>
                              <tr>
                                <td>4.0102</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100.00</td>
                                <td>2,100.00</td>
                                <td>8,400.00</td>
                              </tr>
                              <tr>
                                <td>4.0102</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100.00</td>
                                <td>2,100.00</td>
                                <td>8,400.00</td>
                              </tr>
                              <tr>
                                <td>4.0102</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100.00</td>
                                <td>2,100.00</td>
                                <td>8,400.00</td>
                              </tr>
                              <tr>
                                <td>4.0102</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100.00</td>
                                <td>2,100.00</td>
                                <td>8,400.00</td>
                              </tr>
                              <tr>
                                <td>4.0102</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100.00</td>
                                <td>2,100.00</td>
                                <td>8,400.00</td>
                              </tr>
                              <tr>
                                <td>4.0102</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100.00</td>
                                <td>2,100.00</td>
                                <td>8,400.00</td>
                              </tr>
                              <tr>
                                <td>4.0102</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100.00</td>
                                <td>2,100.00</td>
                                <td>8,400.00</td>
                              </tr>
                              <tr>
                                <td>4.0102</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100.00</td>
                                <td>2,100.00</td>
                                <td>8,400.00</td>
                              </tr>
                              <tr>
                                <td>4.0102</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100.00</td>
                                <td>2,100.00</td>
                                <td>8,400.00</td>
                              </tr>
                              <tr>
                                <td>4.0102</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100.00</td>
                                <td>2,100.00</td>
                                <td>8,400.00</td>
                              </tr>
                              <tr>
                                <td>4.0102</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100.00</td>
                                <td>2,100.00</td>
                                <td>8,400.00</td>
                              </tr>
                              <tr>
                                <td>4.0102</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100.00</td>
                                <td>2,100.00</td>
                                <td>8,400.00</td>
                              </tr>
                              <tr>
                                <td>4.0102</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100.00</td>
                                <td>2,100.00</td>
                                <td>8,400.00</td>
                              </tr>
                              <tr>
                                <td>4.0102</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100.00</td>
                                <td>2,100.00</td>
                                <td>8,400.00</td>
                              </tr>
                              <tr>
                                <td>4.0102</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100.00</td>
                                <td>2,100.00</td>
                                <td>8,400.00</td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td />
                                <td>Total Foundation Cost</td>
                                <td>67,480.00</td>
                                <td>67,480.00</td>
                                <td>2,75,480.00</td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button
                  className="Outline"
                  onClick={handleProformaBlockFoundationModal3Back}
                >
                  Back
                </button>

                <button
                  className="Create FolderPermissionId"
                  onClick={handleUnitInputsModalShow}
                >
                  Foundation
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={UnitInputsModal}
        onHide={handleUnitInputsModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlocksInput"
                    data-dismiss="modal"
                  >
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonAreasInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonGarageInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaMultiLevelParkadeInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaLandDevelopmentAdminCostsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaConfirmation"
                    data-dismiss="modal"
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Units <br /> Input
                      </span>
                    </li>
                    <li>
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Units <br /> Options <br /> Input
                      </span>
                    </li>
                    <li>
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Units <br />
                        Option <br />
                        Quantities{" "}
                      </span>
                    </li>
                    <li>
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_summary.png")}
                        />
                      </span>
                      <span className="Text">
                        Units <br />
                        Summary <br />
                        Preview{" "}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalBigheading">
                  <h3>
                    <span>Unit Type Inputs</span>
                  </h3>
                </div>
                <div className="UnitTypeInputsTable" style={{ width: "60%" }}>
                  <table>
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Unit's Sq/Ft</th>
                        <th># of Bedrooms</th>
                        <th># of BathRooms</th>
                      </tr>
                    </thead>
                    <tbody>
                      {unitTypes?.length > 0 &&
                        unitTypes?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.type}</td>
                              <td>
                                <div className="form-group">
                                  <input
                                    type="number"
                                    className="form-control width-250"
                                    placeholder="Insert Sq/Ft"
                                    name="value"
                                    min={0}
                                    value={item?.value}
                                    onChange={(event) =>
                                      handleUnitChange(event, index)
                                    }
                                  />
                                </div>
                              </td>
                              <td>{item.bedRooms}</td>
                              <td>{item.bathRooms}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="ToggleTableArea AllBlocksCommon ">
                  <div className="ModalBigheading mt-5">
                    <h3>
                      <span>Block Unit Inputs</span>
                    </h3>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th />
                        <th>Block's Units #</th>
                        <th>Unit Type</th>
                        <th>Attached Garages</th>
                        <th>
                          Basement <br />
                          Development
                        </th>
                        <th>
                          Driveways &amp; <br />
                          Sidewalks
                        </th>
                        <th>
                          Patios / Garden <br />
                          Suite Concrete Pads
                        </th>
                        <th>
                          Detached Garages / <br />
                          Project Common Garages
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={8}>
                          <div className="ProformaAccordion">
                            <div className="accordion" id="accordionExample61">
                              {/* ***************WORKIN HERE****************** */}
                              {unitStep1List?.table2?.length > 0 &&
                                unitStep1List?.table2?.map((data, index) => {
                                  return (
                                    <div className="card">
                                      <div
                                        className="card-header Width20"
                                        id="headingOne61"
                                      >
                                        <h2 className="mb-0">
                                          <button
                                            className="btn btn-link btn-block text-left collapsed"
                                            type="button"
                                            data-toggle="collapse"
                                            data-target="#AttachedGarages61"
                                            aria-expanded="false"
                                            aria-controls="AttachedGarages61"
                                          >
                                            {data.blockDescription}
                                          </button>
                                        </h2>
                                      </div>
                                      <div
                                        id="AttachedGarages61"
                                        className="collapse"
                                        aria-labelledby="headingOne61"
                                        data-parent="#accordionExample61"
                                        style={{}}
                                      >
                                        <div className="card-body py-0">
                                          <div className="ToggleTableArea">
                                            <div
                                              className="AdvancedCostCodes"
                                              style={{ overflowX: "scroll" }}
                                            >
                                              <table>
                                                <tbody>
                                                  {data?.MultiUnits?.length >
                                                    0 &&
                                                    data?.MultiUnits?.map(
                                                      (unit, i) => {
                                                        return (
                                                          <tr>
                                                            <td>
                                                              <div className="SmallBox">
                                                                Unit {i + 1}
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="LargeBox">
                                                                <div className="form-group">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control width-250"
                                                                    name="unitNumber"
                                                                    value={
                                                                      unit.unitNumber
                                                                    }
                                                                    onChange={(
                                                                      event
                                                                    ) =>
                                                                      handleUnitStep1ListChange(
                                                                        event,
                                                                        index,
                                                                        i
                                                                      )
                                                                    }
                                                                  />
                                                                </div>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="LargeBox">
                                                                <div className="CommonSelectBox w-100 mx-0">
                                                                  <select
                                                                    name="unitTypeId"
                                                                    value={
                                                                      unit?.unitTypeId
                                                                    }
                                                                    onChange={(
                                                                      event
                                                                    ) =>
                                                                      handleUnitStep1ListChange(
                                                                        event,
                                                                        index,
                                                                        i
                                                                      )
                                                                    }
                                                                  >
                                                                    <option>
                                                                      Select
                                                                      Unit Type
                                                                    </option>
                                                                    {unitStep1List
                                                                      ?.table1
                                                                      ?.units
                                                                      ?.length >
                                                                      0 &&
                                                                      unitStep1List?.table1?.units?.map(
                                                                        (
                                                                          unitt,
                                                                          indd
                                                                        ) => {
                                                                          return (
                                                                            <option
                                                                              key={
                                                                                unitt?.id
                                                                              }
                                                                              id={
                                                                                indd
                                                                              }
                                                                              value={
                                                                                unitt.id
                                                                              }
                                                                            >
                                                                              {
                                                                                unitt.type
                                                                              }
                                                                            </option>
                                                                          );
                                                                        }
                                                                      )}
                                                                  </select>
                                                                </div>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="MediumBox">
                                                                <label className="switch">
                                                                  <input
                                                                    type="checkbox"
                                                                    name="attachedGarage"
                                                                    checked={
                                                                      unit.attachedGarage
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    onChange={(
                                                                      event
                                                                    ) =>
                                                                      handleUnitStep1ListChange(
                                                                        event,
                                                                        index,
                                                                        i
                                                                      )
                                                                    }
                                                                  />
                                                                  <span className="slider" />
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="MediumBox">
                                                                <label className="switch">
                                                                  <input
                                                                    type="checkbox"
                                                                    name="basementDevelopment"
                                                                    checked={
                                                                      unit.basementDevelopment
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    onChange={(
                                                                      event
                                                                    ) =>
                                                                      handleUnitStep1ListChange(
                                                                        event,
                                                                        index,
                                                                        i
                                                                      )
                                                                    }
                                                                  />
                                                                  <span className="slider" />
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="MediumBox">
                                                                <label className="switch">
                                                                  <input
                                                                    type="checkbox"
                                                                    name="drivewaysSideWalks"
                                                                    checked={
                                                                      unit.drivewaysSideWalks
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    onChange={(
                                                                      event
                                                                    ) =>
                                                                      handleUnitStep1ListChange(
                                                                        event,
                                                                        index,
                                                                        i
                                                                      )
                                                                    }
                                                                  />
                                                                  <span className="slider" />
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="MediumBox">
                                                                <label className="switch">
                                                                  <input
                                                                    type="checkbox"
                                                                    name="concretePads"
                                                                    checked={
                                                                      unit.concretePads
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    onChange={(
                                                                      event
                                                                    ) =>
                                                                      handleUnitStep1ListChange(
                                                                        event,
                                                                        index,
                                                                        i
                                                                      )
                                                                    }
                                                                  />
                                                                  <span className="slider" />
                                                                </label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <div className="MediumBox">
                                                                <label className="switch">
                                                                  <input
                                                                    type="checkbox"
                                                                    name="detachedAndCommonGarages"
                                                                    checked={
                                                                      unit.detachedAndCommonGarages
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    onChange={(
                                                                      event
                                                                    ) =>
                                                                      handleUnitStep1ListChange(
                                                                        event,
                                                                        index,
                                                                        i
                                                                      )
                                                                    }
                                                                  />
                                                                  <span className="slider" />
                                                                </label>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button
                  className="Outline"
                  data-toggle="modal"
                  data-target="#CreateProformaBlockFoundationQuantities"
                  data-dismiss="modal"
                  onClick={handleunitstep1Back}
                >
                  Back
                </button>

                <button
                  className="Create FolderPermissionId"
                  onClick={() => {
                    handleUnitOptionsInputsModalShow();
                    handleCreateUnitsInputStep1();
                  }}
                >
                  Save & Next
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={UnitOptionsInputsModal}
        onHide={handleUnitOptionsInputsModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlocksInput"
                    data-dismiss="modal"
                  >
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonAreasInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonGarageInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaMultiLevelParkadeInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaLandDevelopmentAdminCostsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaConfirmation"
                    data-dismiss="modal"
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Units <br /> Input
                      </span>
                    </li>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Units <br />
                        Options <br /> Input
                      </span>
                    </li>
                    <li>
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Units <br /> Options <br /> Quantities{" "}
                      </span>
                    </li>
                    <li>
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_summary.png")}
                        />
                      </span>
                      <span className="Text">
                        Units <br />
                        Summary <br />
                        Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalBigheading pl-4 mb-3">
                  <h3 className="mb-0">
                    <span>Unit Options Input</span>
                  </h3>
                </div>
                <div className="ProformaAccordion">
                  <div className="accordion" id="accordionExample">
                    {unitOption?.length > 0 &&
                      unitOption?.map((datta, ind) => {
                        return (
                          <div className="card">
                            <div className="card-header" id="headingOne">
                              <h2 className="mb-0">
                                <button
                                  className="btn btn-link btn-block text-left"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#AttachedGarages"
                                  aria-expanded="true"
                                  aria-controls="AttachedGarages"
                                >
                                  {datta.name}
                                </button>
                              </h2>
                            </div>
                            <div
                              id="AttachedGarages"
                              className="collapse show"
                              aria-labelledby="headingOne"
                              data-parent="#accordionExample"
                            >
                              <div className="card-body py-0">
                                <div className="EnterProjectTable">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th />
                                        <th>Quantity</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>totalSqFt</td>
                                        <td>
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              className="form-control width-250"
                                              placeholder="Insert Sq/Ft"
                                              name="totalSqFt"
                                              value={datta.totalSqFt}
                                              onChange={(e) =>
                                                handleUnitOptionChange(e, ind)
                                              }
                                            />
                                          </div>
                                        </td>
                                      </tr>

                                      {datta.name != "Basement Development" && (
                                        <tr>
                                          <td>totalCubicYards</td>
                                          <td>
                                            <div className="form-group">
                                              <input
                                                type="text"
                                                className="form-control width-250"
                                                placeholder="Insert cubic yards"
                                                name="totalCubicYards"
                                                value={datta.totalCubicYards}
                                                onChange={(e) =>
                                                  handleUnitOptionChange(e, ind)
                                                }
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                      {datta.name ===
                                        "Detached Garages / Project Common Garages Costs" && (
                                        <tr>
                                          <td>perimeterLinFt</td>
                                          <td>
                                            <div className="form-group">
                                              <input
                                                type="text"
                                                className="form-control width-250"
                                                placeholder="Insert cubic yards"
                                                name="perimeterLinFt"
                                                value={datta.perimeterLinFt}
                                                onChange={(e) =>
                                                  handleUnitOptionChange(e, ind)
                                                }
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className="Button d-flex justify-content-between align-items-center px-5">
              <button
                className="Outline"
                data-toggle="modal"
                data-target="#CreateProformaBlocksSummary"
                data-dismiss="modal"
                onClick={handleUnitOptionsInputsModalBack}
              >
                Back
              </button>

              <button
                className="Create FolderPermissionId"
                onClick={() => {
                  handleUnitOptionQuantitiesModalShow();
                  handleCreateUnitsInputStep2();
                }}
              >
                Save & Next
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={UnitOptionQuantitiesModal}
        onHide={handleUnitOptionQuantitiesModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlocksInput"
                    data-dismiss="modal"
                  >
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonAreasInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonGarageInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaMultiLevelParkadeInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaLandDevelopmentAdminCostsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaConfirmation"
                    data-dismiss="modal"
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Units <br /> Input
                      </span>
                    </li>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Units <br />
                        Options <br /> Input
                      </span>
                    </li>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Units <br /> Options <br /> Quantities{" "}
                      </span>
                    </li>
                    <li>
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_summary.png")}
                        />
                      </span>
                      <span className="Text">
                        Units <br />
                        Summary <br />
                        Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalBigheading pl-4 mb-3 d-flex justify-content-between align-items-center">
                  <h3 className="mb-0">
                    <span>Unit Quantities</span>
                  </h3>
                  <h6>
                    <img src={require("../assets/images/book-search.png")} />
                    Detailed Costs
                  </h6>
                </div>
                <div className="ProformaAccordion">
                  <div className="accordion" id="accordionExample">
                    {unitStep3List?.data?.length > 0 &&
                      unitStep3List?.data?.map((item, i) => {
                        return (
                          <div className="card">
                            <div className="card-header" id="headingFour">
                              <h2 className="mb-0">
                                <button
                                  className="btn btn-link btn-block text-left collapsed"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#ParkingPads"
                                  aria-expanded="false"
                                  aria-controls="ParkingPads"
                                >
                                  {item.name}
                                </button>
                              </h2>
                              <a href="javscript:void(0);">
                                <img
                                  src={require("../assets/images/book-search.png")}
                                />
                              </a>
                            </div>
                            <div
                              id="ParkingPads"
                              className="collapse"
                              aria-labelledby="headingFour"
                              data-parent="#accordionExample"
                              style={{}}
                            >
                              <div className="card-body py-0">
                                <div className="EnterProjectTable">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Cost Code #</th>
                                        <th>Description</th>
                                        <th>Unit of measure</th>
                                        <th>Block A</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item?.ProformaBaseCosts?.length > 0 &&
                                        item?.ProformaBaseCosts?.map(
                                          (data, ind) => {
                                            return (
                                              <tr key={ind}>
                                                <td>
                                                  <p>{data.CostCode?.number}</p>
                                                </td>
                                                <td>
                                                  <p>{data.CostCode?.name}</p>
                                                </td>
                                                <td>
                                                  <div className="CommonSelectBox width-250">
                                                    <select>
                                                      <option>
                                                        {data?.Unit?.name}
                                                      </option>
                                                    </select>
                                                    {/* <span>
                                                        <img src="images/down.png" />
                                                      </span> */}
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="form-group">
                                                    <input
                                                      type="text"
                                                      className="form-control width-250"
                                                      placeholder="Insert Quantity"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className="Button d-flex justify-content-between align-items-center px-5">
              <button
                className="Outline"
                data-toggle="modal"
                data-target="#CreateProformaUnitsOptionsInput"
                data-dismiss="modal"
                onClick={handleUnitOptionsModalBack}
              >
                Back
              </button>

              <button
                className="Create FolderPermissionId"
                onClick={handleUnitSummaryPreviewModalShow}
              >
                Save & Next
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={UnitSummaryPreviewModal}
        onHide={handleUnitSummaryPreviewModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlocksInput"
                    data-dismiss="modal"
                  >
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonAreasInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonGarageInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaMultiLevelParkadeInput"
                    data-dismiss="modal"
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaLandDevelopmentAdminCostsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaConfirmation"
                    data-dismiss="modal"
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Units <br /> Input
                      </span>
                    </li>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Units <br />
                        Options <br /> Input
                      </span>
                    </li>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Units <br /> Options <br /> Quantities{" "}
                      </span>
                    </li>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_summary.png")}
                        />
                      </span>
                      <span className="Text">
                        Units <br />
                        Summary <br />
                        Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalBugheading d-flex align-items-center justify-content-between">
                  <h3>
                    <span>Unit Inputs</span>
                  </h3>
                </div>
                <h6>Unit Types</h6>
                <div className="BlockInputs" style={{ width: "50%" }}>
                  <table>
                    <thead>
                      <tr>
                        <th>Types</th>
                        <th>Sq/Ft</th>
                        <th># of Bedrooms</th>
                        <th># of Bathrooms</th>
                      </tr>
                    </thead>
                    <tbody>
                      {unitStep4List?.data?.table1?.length > 0 &&
                        unitStep4List?.data?.table1?.map((item, ind) => {
                          return (
                            <tr key={ind}>
                              <td>{item.type}</td>
                              <td>{item.UnitOptions?.[0]?.unitValue}</td>
                              <td>{item.bedRooms}</td>
                              <td>{item.bathRooms}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="ModalBigheading mt-4">
                  <h3>
                    <span>Block Unit Inputs</span>
                  </h3>
                </div>
                <div className="GreenCheckTable">
                  <table>
                    <thead>
                      <tr className="DarkBlue">
                        <th colSpan={7}>Block A</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="LightBlue">
                        <td colSpan={7}>Story 1</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Units #</strong>
                        </td>
                        <td>
                          <strong>Units Type</strong>
                        </td>
                        <td>
                          <strong>
                            Attached <br /> Garage
                          </strong>
                        </td>
                        <td>
                          <strong>
                            Basement <br /> Development
                          </strong>
                        </td>
                        <td>
                          <strong>
                            Driveways &amp; <br /> Sidewalks
                          </strong>{" "}
                        </td>
                        <td>
                          <strong>
                            Patios / Garden
                            <br /> Suite Concrete Pads
                          </strong>{" "}
                        </td>
                        <td>
                          <strong>
                            Detached Garage
                            <br />
                            Project Common Garage
                          </strong>{" "}
                        </td>
                      </tr>

                      <tr>
                        <td>1-1</td>
                        <td>1-1</td>
                        <td>
                          <div className="Actions">
                            <span className="GreenCheck">
                              <i className="fa fa-check" aria-hidden="true" />
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="Actions">
                            <span className="GreenCheck">
                              <i className="fa fa-check" aria-hidden="true" />
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="Actions">
                            <span className="GreenCheck">
                              <i className="fa fa-check" aria-hidden="true" />
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="Actions">
                            <span className="GreenCheck">
                              <i className="fa fa-check" aria-hidden="true" />
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="Actions">
                            <span className="GreenCheck">
                              <i className="fa fa-check" aria-hidden="true" />
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="BlockCostsPreview">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4>Block Unit Costs Preview</h4>
                    <div className="NewActions">
                      <a href="javascript:void(0);" title="pdf">
                        <img src="images/pdf.png" />
                      </a>
                      <a href="javascript:void(0);" title="download">
                        <img src="images/download.png" />
                      </a>
                    </div>
                  </div>
                  {unitStep4List?.data?.table2?.blockQuantities?.length > 0 &&
                    unitStep4List?.data?.table2?.blockQuantities?.map(
                      (data, ind) => {
                        return (
                          <div className="BlockInputsCollapse">
                            <div
                              className="BcpHeader collapsed"
                              data-toggle="collapse"
                              href="#collapseExample"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              <h5>{data.name}</h5>
                            </div>
                            <div
                              className="collapse"
                              id="collapseExample"
                              style={{}}
                            >
                              <div className="card card-body border-0">
                                <div className="BlockInputs">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Code Code #</th>
                                        <th>Description</th>
                                        <th>BlockA</th>
                                        {/* {
                                        data?.ProformaBaseCosts?.[0]?.ProformaBlockQuantities?.length > 0 &&
                                        data?.ProformaBaseCosts?.[0]?.ProformaBlockQuantities?.map((item)=>{
                                          return(
                                         <th>{item.MultiBlock?.blockDescription}</th>
                                          )
                                        })
                                      } */}

                                        <th>Total Construction Costs</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {data?.ProformaBaseCosts?.length > 0 &&
                                        data?.ProformaBaseCosts?.map(
                                          (data, ind) => {
                                            return (
                                              <tr>
                                                <td>{data.CostCode?.number}</td>
                                                <td>{data.CostCode?.name}</td>
                                                <td>
                                                  {
                                                    data
                                                      .ProformaBlockQuantities?.[0]
                                                      ?.MultiBlock
                                                      ?.blockDescription
                                                  }
                                                </td>

                                                <td>
                                                  $
                                                  {data
                                                    .ProformaBlockQuantities?.[0]
                                                    ?.totalCost ?? 0}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td />
                                        <td>Total Interior Finishing Costs</td>
                                        <td>67,480.00</td>
                                        <td>67,480.00</td>
                                        <td>67,480.00</td>
                                        <td>67,480.00</td>
                                        <td>2,75,480.00</td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}

                  <div className="BlockInputs Red">
                    <table>
                      <tfoot>
                        <tr>
                          <td />
                          <td>Grand Total</td>
                          <td>$ 1,67,480.00</td>
                          <td>$ 1,67,480.00</td>
                          <td>$ 1,67,480.00</td>
                          <td>$ 1,67,480.00</td>
                          <td>$ 32,275,590.00</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button className="Outline" onClick={handleUnitStep4Back}>
                  Cancel
                </button>

                <button
                  className="Create FolderPermissionId"
                  onClick={handleCommonAreasModalShow}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={CommonAreasModal}
        onHide={handleCommonAreasModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlocksInput"
                    data-dismiss="modal"
                  >
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li className="active">
                  <span className="Icon ">
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonAreasModal",
                        "CommonBuildingUnitsModal"
                      );
                    }}
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonAreasModal",
                        "CommonBuildingFoundationModal"
                      );
                    }}
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow("CommonAreasModal", "CommonGarageInputModal");
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow("CommonAreasModal", "MultilevelParkadeModal");
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow("CommonAreasModal", "LandDevlopmentCostModal");
                    }}
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonAreasModal",
                        "ProformaConfirmSummaryModal"
                      );
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green" style={{ width: "30%" }}>
                  <ul>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br /> Areas <br /> Units
                      </span>
                    </li>
                    <li>
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Foundation <br />
                        Summary <br /> Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalBigheading pl-4 mb-3">
                  <h3 className="mb-0">
                    <span>Common Areas Input</span>
                  </h3>
                </div>
                <div className="ProformaAccordion">
                  <div className="accordion" id="accordionExample">
                    {commonAreaUnit?.length > 0 &&
                      commonAreaUnit?.map((item, index) => {
                        return (
                          <div className="card">
                            <div className="card-header" id="headingOne">
                              <h2 className="mb-0">
                                <button
                                  className="btn btn-link btn-block text-left"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#BlockA"
                                  aria-expanded="true"
                                  aria-controls="BlockA"
                                >
                                  {item.blockDescription}
                                </button>
                              </h2>
                              <a href="javascript:void(0);">
                                <img
                                  src={require("../assets/images/book-search.png")}
                                />
                              </a>
                            </div>
                            <div
                              id="BlockA"
                              className="collapse show"
                              aria-labelledby="headingOne"
                              data-parent="#accordionExample"
                            >
                              <div className="card-body py-0">
                                <div className="EnterProjectTable">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th />
                                        <th>Sq/Ft</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item?.MultiStories?.length > 0 &&
                                        item?.MultiStories?.map((data, i) => {
                                          return (
                                            <tr key={i}>
                                              <td>Story {i + 1}</td>
                                              <td>
                                                <div className="form-group">
                                                  <input
                                                    type="text"
                                                    className="form-control width-250"
                                                    placeholder="Insert Sq/Ft"
                                                    name="value"
                                                    value={data.value}
                                                    onChange={(e) =>
                                                      handleMultiStorieschange(
                                                        e,
                                                        i,
                                                        index
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className="Button d-flex justify-content-between align-items-center px-5">
              <button className="Outline" onClick={handleCommonareaStep1Back}>
                Back
              </button>

              <button
                className="Create FolderPermissionId"
                onClick={() => {
                  handleCreateCommonAreaUnitStep1();
                }}
              >
                Save & Next
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={FoundationSummaryPreviewModal}
        onHide={handleFoundationSummaryPreviewModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlocksInput"
                    data-dismiss="modal"
                  >
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li className="active">
                  <span className="Icon ">
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        FoundationSummaryPreviewModal,
                        CommonBuildingUnitsModal
                      );
                    }}
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        FoundationSummaryPreviewModal,
                        CommonBuildingFoundationModal
                      );
                    }}
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        FoundationSummaryPreviewModal,
                        CommonGarageInputModal
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        FoundationSummaryPreviewModal,
                        MultilevelParkadeModal
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        FoundationSummaryPreviewModal,
                        LandDevlopmentCostModal
                      );
                    }}
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        FoundationSummaryPreviewModal,
                        ProformaConfirmSummaryModal
                      );
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green" style={{ width: "30%" }}>
                  <ul>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br /> Areas <br /> Units
                      </span>
                    </li>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Foundation <br />
                        Summary <br /> Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalBigheading pl-0 mb-3">
                  <h3 className="mb-0">
                    <span>Common Areas Input</span>
                  </h3>
                </div>
                <div className="row">
                  {commonAreaStep2List?.data?.table1?.blocks?.length > 0 &&
                    commonAreaStep2List?.data?.table1?.blocks?.map(
                      (item, ind) => (
                        <div key={ind} className="col-md-3">
                          <div className="SmallTableArea">
                            <table>
                              <thead>
                                <tr>
                                  <th colSpan={2}>{item.blockDescription}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <strong>Story</strong>
                                  </td>
                                  <td>
                                    <strong>Sq/Ft</strong>
                                  </td>
                                </tr>
                                {item?.MultiStories?.length > 0 &&
                                  item?.MultiStories?.map((data, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{data.value}</td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td>Total</td>
                                  <td>
                                    {item?.MultiStories?.reduce(
                                      (sum, data) => sum + data.value,
                                      0
                                    )}
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      )
                    )}
                </div>

                <div className="BlockCostsPreview">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4>Common Areas Costs</h4>
                    <div className="NewActions">
                      <a href="javascript:void(0);" title="pdf">
                        <img src="images/pdf.png" />
                      </a>
                      <a href="javascript:void(0);" title="download">
                        <img src="images/download.png" />
                      </a>
                    </div>
                  </div>
                  {commonAreaStep2List?.data?.table2?.blockQuantities?.length >
                    0 &&
                    commonAreaStep2List?.data?.table2?.blockQuantities?.map(
                      (data, ind) => {
                        return (
                          <div className="BlockInputsCollapse">
                            <div
                              className="BcpHeader"
                              data-toggle="collapse"
                              href="#collapseExample"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              <h5>{data.name}</h5>
                            </div>
                            <div className="collapse show" id="collapseExample">
                              <div className="card card-body border-0">
                                <div className="BlockInputs">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Code Code #</th>
                                        <th>Description</th>
                                        <th>Block A</th>
                                        <th>Block B</th>

                                        <th>Total Construction Costs</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {data?.ProformaBaseCosts?.length > 0 &&
                                        data?.ProformaBaseCosts?.map(
                                          (data, ind) => {
                                            return (
                                              <tr>
                                                <td>{data.CostCode?.number}</td>
                                                <td>{data.CostCode?.name}</td>
                                                <td>
                                                  {
                                                    data
                                                      .ProformaBlockQuantities?.[0]
                                                      ?.MultiBlock
                                                      ?.blockDescription
                                                  }
                                                </td>

                                                <td>
                                                  $
                                                  {Number(
                                                    data
                                                      .ProformaBlockQuantities?.[0]
                                                      ?.totalCost
                                                  ).toFixed(2) ?? 0}
                                                </td>
                                                <td>{data.totalCostSum}</td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td />
                                        <td>Total Interior Finishing Costs</td>
                                        <td>67,480.00</td>
                                        <td>67,480.00</td>
                                        <td>67,480.00</td>
                                        <td>67,480.00</td>
                                        <td>2,75,480.00</td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
              </div>
            </div>
            <div className="Button d-flex justify-content-between align-items-center px-5">
              <button className="Outline" onClick={handleCommonareaStep2Back}>
                Back
              </button>

              <button
                className="Create FolderPermissionId"
                onClick={() => {
                  handleCommonBuildingUnitsModalShow();
                }}
              >
                Common Buildings
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={CommonBuildingUnitsModal}
        onHide={handleCommonBuildingUnitsModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlocksInput"
                    data-dismiss="modal"
                  >
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingUnitsModal",
                        "CommonAreasModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingUnitsModal",
                        "CommonBuildingFoundationModal"
                      );
                    }}
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingUnitsModal",
                        "CommonGarageInputModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingUnitsModal",
                        "MultilevelParkadeModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingUnitsModal",
                        "LandDevlopmentCostModal"
                      );
                    }}
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingUnitsModal",
                        "ProformaConfirmSummaryModal"
                      );
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br /> Buildings <br /> Units
                      </span>
                    </li>
                    <li>
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaCommonBuildingsQuantities"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br /> Buildings <br />
                        Quantities
                      </span>
                    </li>
                    <li>
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaCommonBuildingsSummaryPreview"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br /> Buildings <br /> Summary <br /> Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalBigheading2 d-flex align-items-center mb-3">
                  <h3 className="mb-0">
                    Enter Number of Blocks in the Project
                  </h3>
                  <input
                    className="ModalBigheadingBox"
                    type="number"
                    value={numBlocks}
                    onChange={handleNumBlocksChange}
                  />
                  {/* <span className="ModalBigheadingBox"></span> */}
                </div>
                <div className="EnterProjectTable">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Building Name</th>
                        <th>Foundation type</th>
                        <th>Building Sq /Ft</th>
                        <th>Concrete Cubic /Yards</th>
                        <th>Foundation Sq/Ft</th>
                        <th>Driveways &amp; Sidewalks Sq/Ft</th>
                        <th>Driveways &amp; Sidewalks Concrete Cubic/yards</th>
                      </tr>
                    </thead>
                    <tbody>
                      {buildings?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <div className="form-group mr-5">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Site Office"
                                value={item.buildingName}
                                onChange={(e) =>
                                  handleBuildingFieldChange(
                                    e,
                                    index,
                                    "buildingName"
                                  )
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className="CommonSelectBox width-250">
                              <select
                                value={item.foundationType}
                                onChange={(e) =>
                                  handleBuildingFieldChange(
                                    e,
                                    index,
                                    "foundationType"
                                  )
                                }
                              >
                                <option value="">Select Foundation Type</option>
                                {foundationTypeData?.data?.length > 0 &&
                                  foundationTypeData?.data?.map((data, i) => {
                                    return (
                                      <option key={i} value={data.id}>
                                        {data.name}
                                      </option>
                                    );
                                  })}
                              </select>
                              {/* <span>
                              <img src="images/down.png" />
                            </span> */}
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Insert Sq/ Ft"
                                value={item.buildingSqFt}
                                onChange={(e) =>
                                  handleBuildingFieldChange(
                                    e,
                                    index,
                                    "buildingSqFt"
                                  )
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control width-250"
                                placeholder="Insert cubic / yards"
                                value={item.concreteCubicYards}
                                onChange={(e) =>
                                  handleBuildingFieldChange(
                                    e,
                                    index,
                                    "concreteCubicYards"
                                  )
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Insert Sq/ Ft"
                                value={item.foundationSqFt}
                                onChange={(e) =>
                                  handleBuildingFieldChange(
                                    e,
                                    index,
                                    "foundationSqFt"
                                  )
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Insert Sq/ Ft"
                                value={item.drivewaysAndSidewalksSqFt}
                                onChange={(e) =>
                                  handleBuildingFieldChange(
                                    e,
                                    index,
                                    "drivewaysAndSidewalksSqFt"
                                  )
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Insert cubic / yards"
                                value={item.drivewaysAndSidewalksCubicYards}
                                onChange={(e) =>
                                  handleBuildingFieldChange(
                                    e,
                                    index,
                                    "drivewaysAndSidewalksCubicYards"
                                  )
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="PaginationArea px-3">
                    <h6>Showing 1 to 10 of 27 entries</h6>
                    <h6>
                      <img src="images/leftarrow.png" /> 01{" "}
                      <img src="images/rightarrow.png" /> Out 10
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="Button d-flex justify-content-between align-items-center px-5">
              <button
                className="Outline"
                onClick={handleCommonBuildingStep1Back}
              >
                Back
              </button>

              <button
                className="Create FolderPermissionId"
                onClick={() => {
                  handleCommonBuildingQuantitiesModalShow();
                  handleCreateCommonBuildingStep1();
                }}
              >
                Next
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={CommonBuildingQuantitiesModal}
        onHide={handleCommonBuildingQuantitiesModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlocksInput"
                    data-dismiss="modal"
                  >
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingQuantitiesModal",
                        "CommonAreasModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingQuantitiesModal",
                        "CommonBuildingFoundationModal"
                      );
                    }}
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingQuantitiesModal",
                        "CommonGarageInputModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingQuantitiesModal",
                        "MultilevelParkadeModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingQuantitiesModal",
                        "LandDevlopmentCostModal"
                      );
                    }}
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingQuantitiesModal",
                        "ProformaConfirmSummaryModal"
                      );
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaCommonBuildingsInput"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br /> Buildings <br /> Units
                      </span>
                    </li>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br /> Buildings <br />
                        Quantities
                      </span>
                    </li>
                    <li>
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaCommonBuildingsSummaryPreview"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br /> Buildings <br /> Summary <br /> Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalBigheading pl-4 mb-3 d-flex justify-content-between align-items-center">
                  <h3 className="mb-0">
                    <span>Block Quantitiess</span>
                  </h3>
                  <h6>
                    <img src={require("../assets/images/book-search.png")} />
                    Customize Base Costs
                  </h6>
                </div>
                <div className="ProformaAccordion">
                  <div className="accordion" id="accordionExample">
                    {commonBuildingQuantities?.length > 0 &&
                      commonBuildingQuantities?.map((item, ind) => {
                        return (
                          <div className="card">
                            <div className="card-header" id="headingOne">
                              <h2 className="mb-0">
                                <button
                                  className="btn btn-link btn-block text-left"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#CommonBuildingCosts"
                                  aria-expanded="true"
                                  aria-controls="CommonBuildingCosts"
                                >
                                  {item.name}
                                </button>
                              </h2>
                              <a href="javascript:void(0);">
                                <img
                                  src={require("../assets/images/book-search.png")}
                                />
                              </a>
                            </div>
                            <div
                              id="CommonBuildingCosts"
                              className="collapse show"
                              aria-labelledby="headingOne"
                              data-parent="#accordionExample"
                            >
                              <div className="card-body py-0">
                                <div className="EnterProjectTable GreyTd">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Code Code #</th>
                                        <th>Description</th>
                                        <th>Unit of measure</th>
                                        <th>Site Office</th>
                                        <th>Rec Center</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item?.ProformaBaseCosts?.length > 0 &&
                                        item?.ProformaBaseCosts?.map(
                                          (datas, i) => {
                                            return (
                                              <tr>
                                                <td>{i + 1}</td>
                                                <td>
                                                  {datas.CostCode?.number}
                                                </td>
                                                <td>
                                                  <div className="CommonSelectBox width-250">
                                                    <select
                                                      value={
                                                        datas
                                                          ?.ProformaBlockQuantities?.[0]
                                                          ?.unitId
                                                      }
                                                      name="unitId"
                                                      onChange={(e) =>
                                                        handleQuantityChange(
                                                          e,
                                                          ind,
                                                          i,
                                                          0
                                                        )
                                                      }
                                                    >
                                                      <option value="">
                                                        Select Foundation Type
                                                      </option>
                                                      {unitList?.length > 0 &&
                                                        unitList?.map(
                                                          (data, i) => {
                                                            return (
                                                              <option
                                                                key={i}
                                                                value={data.id}
                                                              >
                                                                {data.name}
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                    </select>
                                                    {/* <span>
                                                        <img src="images/down.png" />
                                                      </span> */}
                                                  </div>
                                                </td>
                                                {/* {
                                                        item.data[1].ProformaBaseCosts[0].ProformaBlockQuantities[0].quantity
                                                      } */}
                                                {datas?.ProformaBlockQuantities
                                                  ?.length > 0 &&
                                                  datas?.ProformaBlockQuantities?.map(
                                                    (block, blocId) => {
                                                      return (
                                                        <td key={blocId}>
                                                          <div className="form-group">
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Insert Quality"
                                                              name="quantity"
                                                              value={
                                                                block.quantity
                                                              }
                                                              onChange={(e) =>
                                                                handleQuantityChange(
                                                                  e,
                                                                  ind,
                                                                  i,
                                                                  blocId
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </td>
                                                      );
                                                    }
                                                  )}
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className="Button d-flex justify-content-between align-items-center px-5">
              <button
                className="Outline"
                onClick={handleCommonBuildingStep2Back}
              >
                Back
              </button>

              <button
                className="Create FolderPermissionId"
                onClick={() => {
                  handleCommonBuildingSummaryModalShow();
                  handleCreateCommonBuildingStep2();
                }}
              >
                Save & Next
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={CommonBuildingSummaryModal}
        onHide={handleCommonBuildingSummaryModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlocksInput"
                    data-dismiss="modal"
                  >
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(CommonBuildingSummaryModal, CommonAreasModal);
                    }}
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        CommonBuildingSummaryModal,
                        CommonBuildingFoundationModal
                      );
                    }}
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        CommonBuildingSummaryModal,
                        CommonGarageInputModal
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        CommonBuildingSummaryModal,
                        MultilevelParkadeModal
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        CommonBuildingSummaryModal,
                        LandDevlopmentCostModal
                      );
                    }}
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        CommonBuildingSummaryModal,
                        ProformaConfirmSummaryModal
                      );
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaCommonBuildingsInput"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br /> Buildings <br /> Units
                      </span>
                    </li>
                    <li className="active">
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaCommonBuildingsQuantities"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br /> Buildings <br />
                        Quantities
                      </span>
                    </li>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br /> Buildings <br /> Summary <br /> Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalBigheading d-flex justify-content-between">
                  <h3>
                    <span>Block Quantities</span>
                  </h3>
                  <h6>
                    <img src={require("../assets/images/book-search.png")} />
                    Detailed Costs
                  </h6>
                </div>
                <div className="BlockInputs" style={{ width: "50%" }}>
                  <table>
                    <thead>
                      <tr>
                        <th>Input</th>
                        <th>Site Office</th>
                        <th>Rec Center</th>
                        <th>Totals</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <tr>
                <td>Foundation Type</td>
                <td>
                  <strong>Basement Foundation</strong>
                </td>
                <td>Slab on Grade Foundation</td>
                <td>1</td>
              </tr> */}
                      {commonBuildingStep3List?.data?.table1?.proforma
                        ?.MultiBuildings?.length > 0 &&
                        commonBuildingStep3List?.data?.table1?.proforma?.MultiBuildings?.map(
                          (item, ind) => {
                            return (
                              <tr key={ind}>
                                <td>{item.jobNumber}</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
                <div className="BlockCostsPreview">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4>Common Areas Costs</h4>
                    <div className="NewActions">
                      <a href="javascript:void(0);" title="pdf">
                        <img src="images/pdf.png" />
                      </a>
                      <a href="javascript:void(0);" title="download">
                        <img src="images/download.png" />
                      </a>
                    </div>
                  </div>
                  {commonBuildingStep3List?.data?.table2?.blockQuantities
                    ?.length > 0 &&
                    commonBuildingStep3List?.data?.table2?.blockQuantities?.map(
                      (data, i) => {
                        return (
                          <div className="BlockInputsCollapse">
                            <div
                              className="BcpHeader"
                              data-toggle="collapse"
                              href="#collapseExample"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              <h5>{data.name}</h5>
                            </div>
                            <div className="collapse show" id="collapseExample">
                              <div className="card card-body border-0">
                                <div className="BlockInputs">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Code Code #</th>
                                        <th>Description</th>
                                        <th>Site office</th>
                                        <th>Rec Center</th>
                                        <th>Total Construction Costs</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>4.0102</td>
                                        <td>Windows. and Exterlor Doors</td>
                                        <td>$ -</td>
                                        <td>$ -</td>
                                        <td>$ -</td>
                                      </tr>
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td />
                                        <td>Total Interior Finishing Costs</td>
                                        <td>67,480.00</td>
                                        <td>67,480.00</td>
                                        <td>2,75,480.00</td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
              </div>
            </div>
            <div className="Button d-flex justify-content-between align-items-center px-5">
              <button
                className="Outline"
                onClick={handleCommonBuildingStep3Back}
              >
                Back
              </button>

              <button
                className="Create FolderPermissionId"
                onClick={handleCommonBuildingFoundationModalShow}
              >
                Common Buildings Foundation
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={LandDevlopmentCostModal}
        onHide={handleLandDevlopmentCostModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(LandDevlopmentCostModal, CommonAreasModal);
                    }}
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "LandDevlopmentCostModal",
                        "CommonBuildingFoundationModal"
                      );
                    }}
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "LandDevlopmentCostModal",
                        "CommonGarageInputModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "LandDevlopmentCostModal",
                        "MultilevelParkadeModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li className="active">
                  <span className="Icon FolderPermissionId">
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "LandDevlopmentCostModal",
                        "ProformaConfirmSummaryModal"
                      );
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Land <br />
                        Development <br />
                        &amp; Admin <br />
                        Costs Input
                      </span>
                    </li>
                    <li>
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaLandDevelopmentAdminCostsQuantities"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Land <br />
                        Development <br />
                        &amp; Admin <br />
                        Costs <br />
                        Quantities
                      </span>
                    </li>
                    <li>
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaLandDevelopmentAdminCostsPreview"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_summary.png")}
                        />
                      </span>
                      <span className="Text">
                        Land <br />
                        Development <br />
                        &amp; Admin <br />
                        Costs Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>

                <div className="ModalBigheading2 d-flex align-items-center mb-3">
                  <h3 className="mb-0">Enter Number of Acres in the Project</h3>
                  <div className="form-group mb-0 ml-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert number of Acres"
                      value={acres}
                      name="acres"
                      onChange={handleAcresChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="Button d-flex justify-content-between align-items-center px-5">
              <button
                className="Outline"
                onClick={handleLandDevelopmentStep1Back}
              >
                Back
              </button>

              <button
                className="Create FolderPermissionId"
                onClick={() => {
                  handleLandDevlopmentQuantitiesModalShow();
                  handleCreateLandDevelopmentStep1();
                }}
              >
                Next
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={LandDevlopmentQuantitiesModal}
        onHide={handleLandDevlopmentQuantitiesModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "LandDevlopmentQuantitiesModal",
                        "CommonAreasModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "LandDevlopmentQuantitiesModal",
                        "CommonBuildingFoundationModal"
                      );
                    }}
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "LandDevlopmentQuantitiesModal",
                        "CommonGarageInputModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "LandDevlopmentQuantitiesModal",
                        "MultilevelParkadeModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li className="active">
                  <span className="Icon FolderPermissionId">
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "LandDevlopmentQuantitiesModal",
                        "ProformaConfirmSummaryModal"
                      );
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaLandDevelopmentAdminCostsInput"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Land <br />
                        Development <br />
                        &amp; Admin <br />
                        Costs Input
                      </span>
                    </li>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Land <br />
                        Development <br />
                        &amp; Admin <br />
                        Costs <br />
                        Quantities
                      </span>
                    </li>
                    <li>
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaLandDevelopmentAdminCostsPreview"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_summary.png")}
                        />
                      </span>
                      <span className="Text">
                        Land <br />
                        Development <br />
                        &amp; Admin <br />
                        Costs Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalMediumheading2 d-flex align-items-center justify-content-between mb-3 pl-4">
                  <h3 className="mb-0">
                    Land, Design, Permits, Servicing, Warranty, insurance,
                    Condo, <br />
                    Management, Legal &amp; Financing Quantities
                  </h3>
                  <h3 className="mb-0">
                    <img src={require("../assets/images/cash-flow.png")} />{" "}
                    &nbsp; Customize Base Costs
                  </h3>
                </div>
                <div className="ProformaAccordion">
                  <div className="accordion" id="accordionExample">
                    {LandDevlopmentQuantites?.length > 0 &&
                      LandDevlopmentQuantites?.map((item, ind) => {
                        return (
                          <div className="card">
                            <div className="card-header" id="headingOne">
                              <h2 className="mb-0">
                                <button
                                  className="btn btn-link btn-block text-left collapsed"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#LandAcquisitionCosts"
                                  aria-expanded="false"
                                  aria-controls="LandAcquisitionCosts"
                                >
                                  Land Acquisition Costs
                                </button>
                              </h2>
                              <a href="javascript:void(0);">
                                <img
                                  src={require("../assets/images/book-search.png")}
                                />
                              </a>
                            </div>
                            <div
                              id="LandAcquisitionCosts"
                              className="collapse"
                              aria-labelledby="headingOne"
                              data-parent="#accordionExample"
                              style={{}}
                            >
                              <div className="card-body">
                                <div
                                  className="EnterProjectTable GreyTd"
                                  style={{ overflowX: "scroll" }}
                                >
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Cost Code #</th>
                                        <th>Description</th>
                                        <th>Unit of measaure</th>
                                        <th>Block A</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item?.ProformaBaseCosts?.length > 0 &&
                                        item?.ProformaBaseCosts?.map(
                                          (datas, i) => {
                                            return (
                                              <tr>
                                                <td>{i + 1}</td>
                                                <td>{datas.CostCode?.name}</td>
                                                <td>
                                                  <div className="CommonSelectBox width-250">
                                                    <select
                                                    // value={item.foundationType}
                                                    // onChange={(e) => handleBuildingFieldChange(e, index, 'foundationType')}
                                                    >
                                                     
                                                      {unitList?.length > 0 &&
                                                        unitList?.map(
                                                          (data, i) => {
                                                            return (
                                                              <option
                                                                key={i}
                                                                name="unitId"
                                                                value={data.id}
                                                                 selected={
                                                                  data.id ===
                                                                  datas.Unit.id
                                                                }
                                                                onChange={(e) =>
                                                                  handleLandDevelopmentQuantityChange(
                                                                    e,
                                                                    ind,
                                                                    i
                                                                  )
                                                                }
                                                              >
                                                                {data.name}
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                    </select>
                                                    {/* <span>
                                                      <img src="images/down.png" />
                                                    </span> */}
                                                  </div>
                                                </td>
                                                {datas?.ProformaBlockQuantities
                                                  ?.length > 0 &&
                                                  datas?.ProformaBlockQuantities?.map(
                                                    (block, blocId) => {
                                                      return (
                                                        <td key={blocId}>
                                                          <div className="form-group">
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Insert Quantity"
                                                              name="quantity"
                                                              value={
                                                                block.quantity
                                                              }
                                                              onChange={(e) =>
                                                                handleLandDevelopmentQuantityChange(
                                                                  e,
                                                                  ind,
                                                                  i,
                                                                  blocId
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </td>
                                                      );
                                                    }
                                                  )}
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button
                  className="Outline"
                  onClick={handleLandDevelopmentStep2Back}
                >
                  Cancel
                </button>

                <button
                  className="Create FolderPermissionId"
                  onClick={() => {
                    handleLandDevlopmentCostPreviewModalShow();
                    handleLandDevlopmentStep2();
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={LandDevlopmentCostPreviewModal}
        onHide={handleLandDevlopmentCostPreviewModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "LandDevlopmentCostPreviewModal",
                        "CommonAreasModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "LandDevlopmentCostPreviewModal",
                        "CommonBuildingFoundationModal"
                      );
                    }}
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "LandDevlopmentCostPreviewModal",
                        "CommonGarageInputModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "LandDevlopmentCostPreviewModal",
                        "MultilevelParkadeModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li className="active">
                  <span className="Icon FolderPermissionId">
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "LandDevlopmentCostPreviewModal",
                        "ProformaConfirmSummaryModal"
                      );
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaLandDevelopmentAdminCostsInput"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Land <br />
                        Development <br />
                        &amp; Admin <br />
                        Costs Input
                      </span>
                    </li>
                    <li className="active">
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaLandDevelopmentAdminCostsQuantities"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Land <br />
                        Development <br />
                        &amp; Admin <br />
                        Costs <br />
                        Quantities
                      </span>
                    </li>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_summary.png")}
                        />
                      </span>
                      <span className="Text">
                        Land <br />
                        Development <br />
                        &amp; Admin <br />
                        Costs Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="ModalBigheadibg">
                  <h3>
                    <span>Land Development &amp; Admin Costs Input</span>
                  </h3>
                </div>
                <div
                  className="ModalMediumheading d-flex justify-content-between"
                  style={{ width: "25%" }}
                >
                  <h3>
                    <span className="fw-500">Number of Acres </span>
                  </h3>
                  <h3>
                    <span className="fw-500">
                      {landDevelopmentStep3List?.data?.table1?.proforma
                        ?.acres ?? 0}{" "}
                    </span>
                  </h3>
                </div>
                <div className="BlockCostsPreview">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4>
                      Land, Design, Permits, Servicing, Warranty, insurance,
                      Condo, Management, Legal &amp; Financing Costs
                    </h4>
                    <div className="NewActions">
                      <a href="javascript:void(0);" title="pdf">
                        <img src="images/pdf.png" />
                      </a>
                      <a href="javascript:void(0);" title="download">
                        <img src="images/download.png" />
                      </a>
                    </div>
                  </div>
                  {landDevelopmentStep3List?.data?.table2?.blockQuantities
                    ?.length > 0 &&
                    landDevelopmentStep3List?.data?.table2?.blockQuantities?.map(
                      (item, i) => {
                        return (
                          <div className="BlockInputsCollapse">
                            <div
                              className="BcpHeader collapsed"
                              data-toggle="collapse"
                              href="#collapseExample"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              <h5>{item.name}</h5>
                            </div>
                            <div
                              className="collapse"
                              id="collapseExample"
                              style={{}}
                            >
                              <div className="card card-body border-0">
                                <div className="BlockInputs">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Code Code #</th>
                                        <th>Description</th>
                                        <th>Total Construction Costs</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item?.ProformaBaseCosts?.length > 0 &&
                                        item?.ProformaBaseCosts?.map(
                                          (data, i) => {
                                            return (
                                              <tr key={i}>
                                                <td>{data.CostCode?.number}</td>
                                                <td>{data.CostCode?.name}</td>
                                                <td>
                                                  {
                                                    data
                                                      .ProformaBlockQuantities?.[0]
                                                      ?.MultiBlock
                                                      ?.blockDescription
                                                  }
                                                </td>

                                                <td>
                                                  $
                                                  {Number(
                                                    data
                                                      .ProformaBlockQuantities?.[0]
                                                      ?.totalCost
                                                  ).toFixed(2) ?? 0}
                                                </td>
                                                <td>{data.totalCostSum}</td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td />
                                        <td>Total Interior Finishing Costs</td>
                                        <td>275,590.00</td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}

                  <div className="BlockInputs Red">
                    <table>
                      <tfoot>
                        <tr>
                          <td />
                          <td>Grand Total</td>
                          <td>$ 32,275,590.00</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="Button d-flex justify-content-between align-items-center">
              <button
                className="Outline"
                onClick={handleLandDevelopmentStep3Back}
              >
                Back
              </button>

              <button
                className="Create FolderPermissionId"
                onClick={handleProformaConfirmSummaryModalShow}
              >
                Next
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ProformaConfirmSummaryModal}
        onHide={handleProformaConfirmSummaryModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "ProformaConfirmSummaryModal",
                        "CommonAreasModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "ProformaConfirmSummaryModal",
                        "CommonBuildingFoundationModal"
                      );
                    }}
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "ProformaConfirmSummaryModal",
                        "CommonGarageInputModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "ProformaConfirmSummaryModal",
                        "MultilevelParkadeModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "ProformaConfirmSummaryModal",
                        "LandDevlopmentCostModal"
                      );
                    }}
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li className="active">
                  <span className="Icon FolderPermissionId">
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <h6>Name</h6>
                      <p>
                        {
                          proformaConfirmSummaryList?.data?.table1?.proforma
                            ?.name
                        }
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <h6>Type</h6>
                      <p>
                        {
                          proformaConfirmSummaryList?.data?.table1?.proforma
                            ?.proformaTypeId
                        }
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <h6>Home Type</h6>
                      <p>
                        {
                          proformaConfirmSummaryList?.data?.table1?.proforma
                            ?.homeTypeId
                        }
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <h6>City</h6>
                      <p>
                        {
                          proformaConfirmSummaryList?.data?.table1?.proforma
                            ?.cityCode
                        }
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <h6>State/Province</h6>
                      <p>--</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <h6>Job Site GMT (UTC) Timezone</h6>
                      <p>
                        {moment(
                          proformaConfirmSummaryList?.data?.table1?.proforma
                            ?.jobTimeZone
                        ).format("YYYY-MM-DD & HH:MM A")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="BlockCostsPreview">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4>Proforma Summary Costs</h4>
                    <div className="NewActions">
                      <a href="javascript:void(0);" title="pdf">
                        <img src="images/pdf.png" />
                      </a>
                      <a href="javascript:void(0);" title="download">
                        <img src="images/download.png" />
                      </a>
                    </div>
                  </div>
                  {proformaConfirmSummaryList?.data?.table2?.blockQuantities
                    ?.length > 0 &&
                    proformaConfirmSummaryList?.data?.table2?.blockQuantities?.map(
                      (item, ind) => {
                        return (
                          <div className="BlockInputsCollapse">
                            <div
                              className="BcpHeader collapsed"
                              data-toggle="collapse"
                              href="#collapseExample"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              <h5>{item.name}</h5>
                            </div>
                            <div
                              className="collapse"
                              id="collapseExample"
                              style={{}}
                            >
                              <div className="card card-body border-0">
                                <div className="BlockInputs">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Major Costs Area</th>
                                        <th>
                                          Design, Development, Admin Soft Costs
                                        </th>
                                        <th>Construction Hard Costs</th>
                                        <th>Project Total Forecast Costs</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item?.ProformaBaseCosts?.length > 0 &&
                                        item?.ProformaBaseCosts?.map(
                                          (data, i) => {
                                            return (
                                              <tr key={i}>
                                                <td>{data.CostCode?.number}</td>
                                                <td>{data.CostCode?.name}</td>
                                                <td>
                                                  {
                                                    data
                                                      .ProformaBlockQuantities?.[0]
                                                      ?.MultiBlock
                                                      ?.blockDescription
                                                  }
                                                </td>

                                                <td>
                                                  $
                                                  {Number(
                                                    data
                                                      .ProformaBlockQuantities?.[0]
                                                      ?.totalCost
                                                  ).toFixed(2) ?? 0}
                                                </td>
                                                <td>{data.totalCostSum}</td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td>
                                          Total Project Land, Design,
                                          Development, Admin Costs
                                        </td>
                                        <td>$ 67,480.00</td>
                                        <td>$ -</td>
                                        <td>$ 275,590.00</td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}

                  <div className="BlockInputs Red">
                    <table>
                      <tfoot>
                        <tr>
                          <td>Grand Total Project Costs</td>
                          <td>$ 32,275,590.00</td>
                          <td>$ 2,275,590.00</td>
                          <td>$ 32,275,590.00</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="Button d-flex justify-content-between align-items-center">
              <button
                className="Outline"
                onClick={handleProformaConfirmSummaryBack}
              >
                Back
              </button>

              <button
                className="Create"
                onClick={handleCreateJobsProjectsProforma}
              >
                Create Proforma
              </button>
              <button className="Create" onClick={handleActiveProforma}>
                Create &amp; Activate Proforma
              </button>
              {/* <button className="Create" data-dismiss="modal">
                Create &amp; Insert Unit Pricing
              </button> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={CommonBuildingFoundationModal}
        onHide={handleCommonBuildingFoundationModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlocksInput"
                    data-dismiss="modal"
                  >
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingFoundationModal",
                        "CommonAreasModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingFoundationModal",
                        "CommonBuildingUnitsModal"
                      );
                    }}
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li className="active">
                  <span className="Icon FolderPermissionId">
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingFoundationModal",
                        "CommonGarageInputModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingFoundationModal",
                        "MultilevelParkadeModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingFoundationModal",
                        "LandDevlopmentCostModal"
                      );
                    }}
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingFoundationModal",
                        "ProformaConfirmSummaryModal"
                      );
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br />
                        Buildings <br />
                        Foundation <br />
                        Input
                      </span>
                    </li>
                    <li>
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaCommonBuildingsFoundationQuantities"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br />
                        Buildings <br />
                        Foundation <br />
                        Quantities
                      </span>
                    </li>
                    <li>
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaCommonBuildingsFoundationSummaryPreview"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_summary.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br />
                        Buildings <br />
                        Foundation <br />
                        Summary <br />
                        Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalBigheading pl-4 mb-3">
                  <h3 className="mb-0">
                    <span>Common Buildings Foundation Inputss</span>
                  </h3>
                </div>
                <div className="ProformaAccordion">
                  <div className="accordion" id="accordionExample">
                    {commonBuildingInput?.length > 0 &&
                      commonBuildingInput?.map((data, i) => {
                        return (
                          <div className="card">
                            <div className="card-header" id="headingOne">
                              <h2 className="mb-0">
                                <button
                                  className="btn btn-link btn-block text-left"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseOne"
                                  aria-expanded="true"
                                  aria-controls="collapseOne"
                                >
                                  {data.name}
                                </button>
                              </h2>
                            </div>
                            <div
                              id="collapseOne"
                              className="collapse show"
                              aria-labelledby="headingOne"
                              data-parent="#accordionExample"
                              style={{}}
                            >
                              <div className="card-body">
                                <div
                                  className="EnterProjectTable"
                                  style={{ overflowX: "scroll" }}
                                >
                                  <table>
                                    <thead>
                                      <tr>
                                        <th />
                                        <th>Site Office</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {data?.FoundationPerimeters?.length > 0 &&
                                        data?.FoundationPerimeters?.map(
                                          (item, ind) => {
                                            return (
                                              <tr key={ind}>
                                                <td>{item.name}</td>
                                                {item?.BlockFoundations
                                                  ?.length > 0 &&
                                                  item?.BlockFoundations?.map(
                                                    (blocks, index) => {
                                                      return (
                                                        <td>
                                                          <div className="form-group">
                                                            <input
                                                              type="text"
                                                              className="form-control width-250"
                                                              placeholder="Insert cubic yards"
                                                              name="value"
                                                              value={
                                                                blocks.value
                                                              }
                                                              onChange={(e) =>
                                                                handleCommonBuildingUnitChange(
                                                                  e,
                                                                  i,
                                                                  ind,
                                                                  index
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </td>
                                                      );
                                                    }
                                                  )}
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                  <div className="PaginationArea pl-0">
                                    <h6>Showing 1 to 10 of 27 entries</h6>
                                    <h6>
                                      <img src="images/leftarrow.png" /> 01{" "}
                                      <img src="images/rightarrow.png" /> Out 10
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button
                  className="Outline"
                  onClick={handleCommonBuildingFoundationStep1Back}
                >
                  Back
                </button>

                <button
                  className="Create FolderPermissionId"
                  onClick={() => {
                    handleCommonBuildingFoundationQuantitesModalShow();
                    handleCreateCommonBuildingFoundationStep1();
                  }}
                >
                  Save & Next
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={CommonBuildingFoundationQuantitesModal}
        onHide={handleCommonBuildingFoundationQuantitesModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlocksInput"
                    data-dismiss="modal"
                  >
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingFoundationQuantitesModal",
                        "CommonAreasModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingFoundationQuantitesModal",
                        "CommonBuildingUnitsModal"
                      );
                    }}
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li className="active">
                  <span className="Icon FolderPermissionId">
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingFoundationQuantitesModal",
                        "CommonGarageInputModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingFoundationQuantitesModal",
                        "MultilevelParkadeModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingFoundationQuantitesModal",
                        "LandDevlopmentCostModal"
                      );
                    }}
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingFoundationQuantitesModal",
                        "ProformaConfirmSummaryModal"
                      );
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaCommonBuildingsFoundationInput"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br />
                        Buildings <br />
                        Foundation <br />
                        Input
                      </span>
                    </li>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br />
                        Buildings <br />
                        Foundation <br />
                        Quantities
                      </span>
                    </li>
                    <li>
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaCommonBuildingsFoundationSummaryPreview"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_summary.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br />
                        Buildings <br />
                        Foundation <br />
                        Summary <br />
                        Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalBigheading pl-4 mb-3 d-flex justify-content-between align-items-center">
                  <h3
                    className="mb-0"
                    style={{ marginBottom: "0px!important" }}
                  >
                    <span>Common Buildings Foundation Quantities</span>
                  </h3>
                  <h6 className="mb-0">
                    <img src="images/book-search.png" /> Customize Base Costs
                  </h6>
                </div>
                <div className="ProformaAccordion">
                  <div className="accordion" id="accordionExample">
                    {commonBuildingFoundationInput?.length > 0 &&
                      commonBuildingFoundationInput?.map((item, ind) => {
                        return (
                          <div className="card">
                            <div className="card-header" id="headingMain">
                              <h2 className="mb-0">
                                <button
                                  className="btn btn-link btn-block text-left collapsed"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseMain"
                                  aria-expanded="false"
                                  aria-controls="collapseMain"
                                >
                                  {item.name}
                                </button>
                              </h2>
                              <a href="javascript:void(0);">
                                <img src="images/book-search.png" />
                              </a>
                            </div>
                            <div
                              id="collapseMain"
                              className="collapse"
                              aria-labelledby="headingMain"
                              data-parent="#accordionExample"
                              style={{}}
                            >
                              <div className="card-body">
                                <div>
                                  {item?.ProformaCostHeadings?.length > 0 &&
                                    item?.ProformaCostHeadings?.map(
                                      (data, inde) => {
                                        return (
                                          <div className="card">
                                            <div
                                              className="card-header"
                                              id="headingOne"
                                            >
                                              <h2 className="mb-0">
                                                <button
                                                  className="btn btn-link btn-block text-left"
                                                  type="button"
                                                  data-toggle="collapse"
                                                  data-target="#collapseOne"
                                                  aria-expanded="true"
                                                  aria-controls="collapseOne"
                                                >
                                                  {data?.name}
                                                </button>
                                              </h2>
                                              <a href="javascript:void(0);">
                                                <img src="images/book-search.png" />
                                              </a>
                                            </div>
                                            <div
                                              id="collapseOne"
                                              className="collapse show"
                                              style={{}}
                                            >
                                              <div className="card-body">
                                                <div
                                                  className="EnterProjectTable  GreyTd"
                                                  style={{
                                                    overflowX: "scroll",
                                                  }}
                                                >
                                                  <table>
                                                    <thead>
                                                      <tr>
                                                        <th>Cost Code #</th>
                                                        <th>Description</th>
                                                        <th>
                                                          <div className="ml-5">
                                                            Unit of measure
                                                          </div>
                                                        </th>
                                                        <th>Block A</th>
                                                        {/* <th>Block C</th> */}
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {data?.ProformaBaseCosts
                                                        ?.length > 0 &&
                                                        data?.ProformaBaseCosts?.map(
                                                          (datas, i) => {
                                                            return (
                                                              <tr>
                                                                <td>{i + 1}</td>
                                                                <td>
                                                                  {
                                                                    datas
                                                                      .CostCode
                                                                      ?.number
                                                                  }
                                                                </td>
                                                                <td>
                                                                  <div className="CommonSelectBox width-250">
                                                                    <select
                                                                      value={
                                                                        item.foundationType
                                                                      }
                                                                      name="unitId"
                                                                      // onChange={(e) => handleCommonBuildingfoundationChange(e, inde, i,0)}
                                                                    >
                                                                    
                                                                      {unitList?.length >
                                                                        0 &&
                                                                        unitList?.map(
                                                                          (
                                                                            data,
                                                                            i
                                                                          ) => {
                                                                            return (
                                                                              <option
                                                                                key={
                                                                                  i
                                                                                }
                                                                                value={
                                                                                  data.id
                                                                                }
                                                                                selected={
                                                                                  data.id ===
                                                                                  datas
                                                                                    .Unit
                                                                                    .id
                                                                                }
                                                                              >
                                                                                {
                                                                                  data.name
                                                                                }
                                                                              </option>
                                                                            );
                                                                          }
                                                                        )}
                                                                    </select>
                                                                  </div>
                                                                </td>
                                                                {/* {
                                                        item.data[1].ProformaBaseCosts[0].ProformaBlockQuantities[0].quantity
                                                      } */}
                                                                {datas
                                                                  ?.ProformaBlockQuantities
                                                                  ?.length >
                                                                  0 &&
                                                                  datas?.ProformaBlockQuantities?.map(
                                                                    (
                                                                      block,
                                                                      blocId
                                                                    ) => {
                                                                      return (
                                                                        <td
                                                                          key={
                                                                            blocId
                                                                          }
                                                                        >
                                                                          <div className="form-group">
                                                                            <input
                                                                              type="text"
                                                                              className="form-control"
                                                                              placeholder="Insert Quality"
                                                                              name="quantity"
                                                                              value={
                                                                                block.quantity
                                                                              }
                                                                              onChange={(
                                                                                e
                                                                              ) =>
                                                                                handleCommonBuildingfoundationChange(
                                                                                  e,
                                                                                  ind,
                                                                                  inde,
                                                                                  i,
                                                                                  blocId
                                                                                )
                                                                              }
                                                                            />
                                                                          </div>
                                                                        </td>
                                                                      );
                                                                    }
                                                                  )}
                                                              </tr>
                                                            );
                                                          }
                                                        )}
                                                    </tbody>
                                                  </table>
                                                  <div className="PaginationArea pl-0">
                                                    <h6>
                                                      Showing 1 to 10 of 27
                                                      entries
                                                    </h6>
                                                    <h6>
                                                      <img src="images/leftarrow.png" />{" "}
                                                      01{" "}
                                                      <img src="images/rightarrow.png" />{" "}
                                                      Out 10
                                                    </h6>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button
                  className="Outline"
                  onClick={handleCommonBuildingFoundationStep2Back}
                >
                  Back
                </button>

                <button
                  className="Create FolderPermissionId"
                  onClick={() => {
                    handleCommonBuildingFoundationSummaryModalShow();
                    handleCreateCommonBuildingFoundationStep2();
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={CommonBuildingFoundationSummaryModal}
        onHide={handleCommonBuildingFoundationSummaryModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Land Development</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlocksInput"
                    data-dismiss="modal"
                  >
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingFoundationSummaryModal",
                        "CommonAreasModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingFoundationSummaryModal",
                        "CommonBuildingUnitsModal"
                      );
                    }}
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li className="active">
                  <span className="Icon FolderPermissionId">
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingFoundationSummaryModal",
                        "CommonGarageInputModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingFoundationSummaryModal",
                        "MultilevelParkadeModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingFoundationSummaryModal",
                        "LandDevlopmentCostModal"
                      );
                    }}
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonBuildingFoundationSummaryModal",
                        "ProformaConfirmSummaryModal"
                      );
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaCommonBuildingsFoundationInput"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br />
                        Buildings <br />
                        Foundation <br />
                        Input
                      </span>
                    </li>
                    <li className="active">
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaCommonBuildingsFoundationQuantities"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br />
                        Buildings <br />
                        Foundation <br />
                        Quantities
                      </span>
                    </li>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_summary.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br />
                        Buildings <br />
                        Foundation <br />
                        Summary <br />
                        Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalBigheading">
                  <h3>
                    <span>Common Buildings Foundation Input</span>
                  </h3>
                </div>
                <div className="ModalMediumheading d-flex align-items-center justify-content-between">
                  <h3>
                    <span>Basement Foundation</span>
                  </h3>
                  <h6>
                    <img src="images/details-cost.png" />
                    Detailed Costs
                  </h6>
                </div>
                <div className="BlockInputs Tablewidth-50">
                  <table>
                    <thead>
                      <tr>
                        <th>Input</th>
                        <th>Site Office</th>
                        <th>Totals</th>
                      </tr>
                    </thead>
                    <tbody>
                      {commonBuildingFoundationStep3List?.data?.table1
                        ?.foundation?.length > 0 &&
                        commonBuildingFoundationStep3List?.data?.table1?.foundation?.map(
                          (data, i) => {
                            return (
                              <tr>
                                <td>{data.name}</td>
                                <td>6.4</td>
                                <td>6.4</td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
                {/* <div className="ModalMediumheading d-flex align-items-center justify-content-between mt-5">
                    <h3>
                      <span>Slab-On-Grade Foundation</span>
                    </h3>
                  </div> */}
                {/* <div className="BlockInputs Tablewidth-50">
                    <table>
                      <thead>
                        <tr>
                          <th>Input</th>
                          <th>Rec Center</th>
                          <th>Totals</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Slab/Floor Cubic/ Yard</td>
                          <td>18.5</td>
                          <td>18.5</td>
                        </tr>
                        <tr>
                          <td>Foundation Perimeter Lin/FT</td>
                          <td>160</td>
                          <td>160</td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
                <div className="BlockCostsPreview">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4>Common Areas Costs</h4>
                    <div className="NewActions">
                      <a href="javascript:void(0);" title="pdf">
                        <img src="images/pdf.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/download.png" />
                      </a>
                    </div>
                  </div>
                  <div className="BlockInputsCollapse">
                    <div
                      className="BcpHeader"
                      data-toggle="collapse"
                      href="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      <h5>
                        Basement Foundation - Common Building Foundation Costs
                      </h5>
                    </div>
                    <div className="collapse show" id="collapseExample">
                      <div className="card card-body border-0">
                        <div className="BlockInputs">
                          <table>
                            <thead>
                              <tr>
                                <th>Code Code #</th>
                                <th>Description</th>
                                <th>Site Office</th>
                                <th>Total Construction Costs</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>4.0102</td>
                                <td>Windows. and Exterlor Doors</td>
                                <td>$ -</td>
                                <td>$ -</td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td />
                                <td>Total Foundation Cost</td>
                                <td>67,480.00</td>
                                <td>2,75,480.00</td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="BlockInputs Red">
                    <table>
                      <tfoot>
                        <tr>
                          <td colSpan={3}>Base Foundation</td>
                          <td>$ 32,275,590.00</td>
                        </tr>
                        <tr>
                          <td colSpan={3}>Slab-on-grade Foundation </td>
                          <td>$ 32,275,590.00</td>
                        </tr>
                        <tr>
                          <td colSpan={3}>Grand Total</td>
                          <td>$ 32,275,590.00</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button
                  className="Outline"
                  onClick={handleCommonBuildingFoundationStep3Back}
                >
                  Back
                </button>

                <button
                  className="Create FolderPermissionId"
                  onClick={handleCommonGarageInputModalShow}
                >
                  Save & Next
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={CommonGarageInputModal}
        onHide={handleCommonGarageInputModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlocksInput"
                    data-dismiss="modal"
                  >
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow("CommonGarageInputModal", "CommonAreasModal");
                    }}
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    onClick={() => {
                      handleShow(
                        "CommonGarageInputModal",
                        "CommonBuildingUnitsModal"
                      );
                    }}
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonGarageInputModal",
                        "CommonBuildingFoundationModal"
                      );
                    }}
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li className="active">
                  <span className="Icon FolderPermissionId">
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonGarageInputModal",
                        "MultilevelParkadeModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonGarageInputModal",
                        "LandDevlopmentCostModal"
                      );
                    }}
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonGarageInputModal",
                        "ProformaConfirmSummaryModal"
                      );
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br /> Garage <br /> Input
                      </span>
                    </li>
                    <li>
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaCommonGarageQuantities"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br /> Garage <br /> Quantities
                      </span>
                    </li>
                    <li>
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaCommonGarageSummaryPreview"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br /> Garage <br /> Summary <br /> Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalBigheading2 d-flex align-items-center mb-3">
                  <h3 className="mb-0">
                    Enter Number of Common Garages in the Project
                  </h3>
                  <input
                    className="ModalBigheadingBox"
                    type="number"
                    value={numBlocksGarage}
                    onChange={handleNumGarageBlocksChange}
                  />
                </div>
                <div className="EnterProjectTable">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Garage Name</th>
                        <th>Garage Sq /Ft</th>
                        <th>
                          Garage Perimeter <br />
                          Lin / Ft
                        </th>
                        <th>Garage Cubic /Yards</th>
                        <th>
                          Driveways &amp; Sidewalks <br />
                          Sq/Ft
                        </th>
                        <th>
                          Driveways &amp; Sidewalks <br />
                          Concrete Cubic/yards
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {garage?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <div className="form-group mr-5">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Site Office"
                                value={item.garageName}
                                onChange={(e) =>
                                  handleBuildingGarageFieldChange(
                                    e,
                                    index,
                                    "garageName"
                                  )
                                }
                              />
                            </div>
                          </td>
                          <td></td>
                          <td>
                            <div className="form-group">
                              <input
                                type="number"
                                min={0}
                                className="form-control"
                                placeholder="Insert Sq/ Ft"
                                value={item.garageSqFt}
                                onChange={(e) =>
                                  handleBuildingGarageFieldChange(
                                    e,
                                    index,
                                    "garageSqFt"
                                  )
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <input
                                type="number"
                                min={0}
                                className="form-control width-250"
                                placeholder="Insert cubic / yards"
                                value={item.garagePerimeterLinFt}
                                onChange={(e) =>
                                  handleBuildingGarageFieldChange(
                                    e,
                                    index,
                                    "garagePerimeterLinFt"
                                  )
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <input
                                type="number"
                                min={0}
                                className="form-control"
                                placeholder="Insert Sq/ Ft"
                                value={item.foundationSqFt}
                                onChange={(e) =>
                                  handleBuildingGarageFieldChange(
                                    e,
                                    index,
                                    "foundationSqFt"
                                  )
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <input
                                type="number"
                                min={0}
                                className="form-control"
                                placeholder="Insert Sq/ Ft"
                                value={item.garageCubicYard}
                                onChange={(e) =>
                                  handleBuildingGarageFieldChange(
                                    e,
                                    index,
                                    "garageCubicYard"
                                  )
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <input
                                type="number"
                                min={0}
                                className="form-control"
                                placeholder="Insert cubic / yards"
                                value={item.drivewaysAndSideWalkSqFt}
                                onChange={(e) =>
                                  handleBuildingGarageFieldChange(
                                    e,
                                    index,
                                    "drivewaysAndSideWalkSqFt"
                                  )
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <input
                                type="number"
                                min={0}
                                className="form-control"
                                placeholder="Insert cubic / yards"
                                value={item.drivew}
                                onChange={(e) =>
                                  handleBuildingGarageFieldChange(
                                    e,
                                    index,
                                    "drivew"
                                  )
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="PaginationArea px-3">
                    <h6>Showing 1 to 10 of 27 entries</h6>
                    <h6>
                      <img src="images/leftarrow.png" /> 01{" "}
                      <img src="images/rightarrow.png" /> Out 10
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="Button d-flex justify-content-between align-items-center px-5">
              <button className="Outline" onClick={handleCommonGarageStep1Back}>
                Back
              </button>

              <button
                className="Create FolderPermissionId"
                onClick={() => {
                  handleCommonGarageQuantitiesModalShow();
                  handleCreateCommonGarageStep1();
                }}
              >
                Save & Next
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={CommonGarageQuantitiesModal}
        onHide={handleCommonGarageQuantitiesModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlocksInput"
                    data-dismiss="modal"
                  >
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonGarageQuantitiesModal",
                        "CommonAreasModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    onClick={() => {
                      handleShow(
                        "CommonGarageQuantitiesModal",
                        "CommonBuildingUnitsModal"
                      );
                    }}
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonGarageQuantitiesModal",
                        "CommonBuildingFoundationModal"
                      );
                    }}
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li className="active">
                  <span className="Icon FolderPermissionId">
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonGarageQuantitiesModal",
                        "MultilevelParkadeModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonGarageQuantitiesModal",
                        "LandDevlopmentCostModal"
                      );
                    }}
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonGarageQuantitiesModal",
                        "ProformaConfirmSummaryModal"
                      );
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaCommonGarageInput"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br /> Garage <br /> Input
                      </span>
                    </li>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br /> Garage <br /> Quantities
                      </span>
                    </li>
                    <li>
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaCommonGarageSummaryPreview"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br /> Garage <br /> Summary <br /> Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalBigheading pl-4 mb-3 d-flex justify-content-between align-items-center">
                  <h3 className="mb-0">
                    <span>Common Garage Quantities</span>
                  </h3>
                  <h6>
                    <img src="images/book-search.png" />
                    Customize Base Costs
                  </h6>
                </div>
                <div className="ProformaAccordion">
                  <div className="accordion" id="accordionExample">
                    {commonGarageQuantity?.length > 0 &&
                      commonGarageQuantity?.map((item, ind) => {
                        return (
                          <div className="card">
                            <div className="card-header" id="headingOne">
                              <h2 className="mb-0">
                                <button
                                  className="btn btn-link btn-block text-left"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#CommonBuildingCosts"
                                  aria-expanded="true"
                                  aria-controls="CommonBuildingCosts"
                                >
                                  {item.name}
                                </button>
                              </h2>
                              <a href="javascript:void(0);">
                                <img src="images/book-search.png" />
                              </a>
                            </div>
                            <div
                              id="CommonBuildingCosts"
                              className="collapse show"
                              aria-labelledby="headingOne"
                              data-parent="#accordionExample"
                            >
                              <div className="card-body py-0">
                                <div className="EnterProjectTable GreyTd">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Code Code #</th>
                                        <th>Description</th>
                                        <th>Unit of measure</th>
                                        <th>Irrigation Garage</th>
                                        <th>Maintenance Garage</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item.ProformaBaseCosts?.length > 0 &&
                                        item.ProformaBaseCosts?.map(
                                          (datas, i) => {
                                            return (
                                              <tr>
                                                <td>{i + 1}</td>
                                                <td>{datas.CostCode?.name}</td>
                                                {/* <td>
                                                  {datas.CostCode?.number}
                                                </td> */}
                                                <td>
                                                  <div className="CommonSelectBox width-250">
                                                    <select
                                                      name="unitId"
                                                      onChange={(e) =>
                                                        handleBlockQuantityChange(
                                                          e,
                                                          ind,
                                                          i,
                                                          0
                                                        )
                                                      }
                                                    >
                                                      {unitList?.length > 0 &&
                                                        unitList?.map(
                                                          (data, i) => {
                                                            return (
                                                              <option
                                                                key={i}
                                                                value={data.id}
                                                                selected={
                                                                  data.id ===
                                                                  datas.Unit.id
                                                                }
                                                              >
                                                                {data.name}
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                    </select>
                                                    {/* <span>
                                                      <img src="images/down.png" />
                                                    </span> */}
                                                  </div>
                                                </td>
                                                {datas?.ProformaBlockQuantities
                                                  ?.length > 0 &&
                                                  datas?.ProformaBlockQuantities?.map(
                                                    (block, blocId) => {
                                                      return (
                                                        <td key={blocId}>
                                                          <div className="form-group">
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Insert Quality"
                                                              name="quantity"
                                                              value={
                                                                block.quantity
                                                              }
                                                              onChange={(e) =>
                                                                handlecommonGarageQuantityChange(
                                                                  e,
                                                                  ind,
                                                                  i,
                                                                  blocId
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </td>
                                                      );
                                                    }
                                                  )}
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className="Button d-flex justify-content-between align-items-center px-5">
              <button className="Outline" onClick={handleCommonGarageStep2Back}>
                Back
              </button>

              <button
                className="Create FolderPermissionId"
                onClick={() => {
                  handleCommonGarageSummaryModalShow();
                  handleCreateCommonGarageStep2();
                }}
              >
                Next
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={CommonGarageSummaryModal}
        onHide={handleCommonGarageSummaryModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlocksInput"
                    data-dismiss="modal"
                  >
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonGarageSummaryModal",
                        "CommonAreasModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    onClick={() => {
                      handleShow(
                        "CommonGarageSummaryModal",
                        "CommonBuildingUnitsModal"
                      );
                    }}
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonGarageSummaryModal",
                        "CommonBuildingFoundationModal"
                      );
                    }}
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li className="active">
                  <span className="Icon FolderPermissionId">
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonGarageSummaryModal",
                        "MultilevelParkadeModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonGarageSummaryModal",
                        "LandDevlopmentCostModal"
                      );
                    }}
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "CommonGarageSummaryModal",
                        "ProformaConfirmSummaryModal"
                      );
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaCommonGarageInput"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br /> Garage <br /> Input
                      </span>
                    </li>
                    <li className="active">
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaCommonGarageQuantities"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br /> Garage <br /> Quantities
                      </span>
                    </li>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Common <br /> Garage <br /> Summary <br /> Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalBigheading d-flex justify-content-between">
                  <h3>
                    <span>Common Garage Input</span>
                  </h3>
                  <h6>
                    <img src="images/book-search.png" />
                    Detailed Costs
                  </h6>
                </div>
                <div className="BlockInputs" style={{ width: "75%" }}>
                  <table>
                    <thead>
                      <tr>
                        <th>Input</th>
                        <th>Irrigation Garage</th>
                        <th>Maintenance Garage</th>
                        <th>Totals</th>
                      </tr>
                    </thead>
                    <tbody>
                      {commonGarageStep3List?.data?.table1?.proforma
                        ?.MultiGarages?.length > 0 &&
                        commonGarageStep3List?.data?.table1?.proforma?.MultiGarages?.map(
                          (data, ind) => {
                            return (
                              <tr>
                                <td>{data.garageName}</td>
                                <td>{data.garageSqFt}</td>
                                <td>{data.garagePerimeterLinFt}</td>
                                <td>
                                  {commonGarageStep3List?.data?.table1?.proforma?.MultiGarages?.reduce(
                                    (total, data) =>
                                      total +
                                      data.garageSqFt +
                                      data.garagePerimeterLinFt,
                                    0
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
                <div className="BlockCostsPreview">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4>Common Garage Costs</h4>
                    <div className="NewActions">
                      <a href="javascript:void(0);" title="pdf">
                        <img src="images/pdf.png" />
                      </a>
                      <a href="javascript:void(0);" title="download">
                        <img src="images/download.png" />
                      </a>
                    </div>
                  </div>
                  <div className="BlockInputsCollapse">
                    {/* {
              commonGarageStep3List?.data?.table2?.blockQuantities[0].ProformaBaseCosts[0].ProformaBlockQuantities[0].MultiGarage.garageName
            } */}
                    <div
                      className="BcpHeader collapsed"
                      data-toggle="collapse"
                      href="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      <h5>Common Garage Costs</h5>
                    </div>
                    <div
                      //  className="collapse"
                      //  id="collapseExample"
                      style={{}}
                    >
                      <div className="card card-body border-0">
                        <div className="BlockInputs">
                          <table>
                            <thead>
                              <tr>
                                <th>Code Code #</th>
                                <th>Description</th>
                                <th>Site office</th>
                                <th>Rec Center</th>
                                <th>Total Construction Costs</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>4.0102</td>
                                <td>Windows. and Exterlor Doors</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                              </tr>
                              <tr>
                                <td>4.0106</td>
                                <td> Garage Overhead Door(s)</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                              <tr>
                                <td>4.0110</td>
                                <td>O.H. Garage Door Opener(s)</td>
                                <td>2,100</td>
                                <td>2,100</td>
                                <td>8,400</td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td />
                                <td>Total Interior Finishing Costs</td>
                                <td>67,480.00</td>
                                <td>67,480.00</td>
                                <td>2,75,480.00</td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Button d-flex justify-content-between align-items-center px-5">
              <button className="Outline" onClick={handleCommonGarageStep3Back}>
                Back
              </button>

              <button
                className="Create FolderPermissionId"
                onClick={handleMultilevelParkadeModalShow}
              >
                Next
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={MultilevelParkadeModal}
        onHide={handleMultilevelParkadeModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlocksInput"
                    data-dismiss="modal"
                  >
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow("MultilevelParkadeModal", "CommonAreasModal");
                    }}
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    onClick={() => {
                      handleShow(
                        "MultilevelParkadeModal",
                        "CommonBuildingUnitsModal"
                      );
                    }}
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "MultilevelParkadeModal",
                        "CommonBuildingFoundationModal"
                      );
                    }}
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "MultilevelParkadeModal",
                        "CommonGarageInputModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li className="active">
                  <span className="Icon FolderPermissionId">
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "MultilevelParkadeModal",
                        "LandDevlopmentCostModal"
                      );
                    }}
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "MultilevelParkadeModal",
                        "ProformaConfirmSummaryModal"
                      );
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Multi-Level <br /> Parkade <br /> Input
                      </span>
                    </li>
                    <li>
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaMultiLevelParkadeQuantities"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Multi-Level <br /> Parkade <br /> Quantities
                      </span>
                    </li>
                    <li>
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaMultiLevelParkadeSummaryPreview"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_summary.png")}
                        />
                      </span>
                      <span className="Text">
                        Multi-Level <br /> Parkade <br /> Summary <br /> Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="row mb-5">
                  <div className="col-md-6">
                    <div className="d-flex justify-content-between">
                      <h6>Project will have Multi-Level Parkade ?</h6>
                      <div className="form-group">
                        <label className="Radio">
                          Yes
                          <input
                            type="radio"
                            name="Cuisine"
                            checked={multiLevelParkade} // Set checked based on state
                            onChange={() => handleRadioChange(true)} // Handle onChange event
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="Radio">
                          No
                          <input
                            type="radio"
                            name="Cuisine"
                            checked={!multiLevelParkade} // Set checked based on state
                            onChange={() => handleRadioChange(false)} // Handle onChange event
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ModalBigheading2 d-flex align-items-center mb-3">
                  <h3 className="mb-0">
                    Enter Number of levels in the Parkade (max 6)
                  </h3>
                  <input
                    className="ModalBigheadingBox"
                    type="number"
                    min={1}
                    max={6}
                    value={noOfMultiParkade}
                    onChange={handleMultiParkadeChange}
                  />
                </div>
                <div
                  className="EnterProjectTable"
                  style={{ overflowX: "scroll" }}
                >
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Level</th>
                        <th>Foundation/Level Total Sq/Ft</th>
                        <th>Foundation/Footings Total L in/Ft</th>
                        <th>
                          Footings &amp; Grade Beams Total Cubic/ Yards Concrete
                        </th>
                        <th>Walls Total Cubic/Yards Concrete</th>
                        <th>Slab/Floor Total Cubic/Yards Concrete</th>
                        <th>Parkade - Grade Level Covered Heated Parking</th>
                      </tr>
                    </thead>
                    <tbody>
                      {parkades?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>Level{index + 1}</td>
                            <td>
                              <div className="form-group">
                                <input
                                  type="number"
                                  min={0}
                                  className="form-control"
                                  placeholder="Insert Sq/Ft"
                                  value={item.footingTotalSqFt}
                                  onChange={(e) =>
                                    handleMultiParkadeFieldChange(
                                      e,
                                      index,
                                      "footingTotalSqFt"
                                    )
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                <input
                                  type="number"
                                  min={0}
                                  className="form-control"
                                  placeholder="Insert Lin/Ft"
                                  value={item.footingTotalLinFt}
                                  onChange={(e) =>
                                    handleMultiParkadeFieldChange(
                                      e,
                                      index,
                                      "footingTotalLinFt"
                                    )
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                <input
                                  type="number"
                                  min={0}
                                  className="form-control"
                                  placeholder="Insert cubic/yards"
                                  value={item.footingYardsConcrete}
                                  onChange={(e) =>
                                    handleMultiParkadeFieldChange(
                                      e,
                                      index,
                                      "footingYardsConcrete"
                                    )
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                <input
                                  type="number"
                                  min={0}
                                  className="form-control"
                                  placeholder="Insert cubic/yards"
                                  value={item.wallYardsConcrete}
                                  onChange={(e) =>
                                    handleMultiParkadeFieldChange(
                                      e,
                                      index,
                                      "wallYardsConcrete"
                                    )
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                <input
                                  type="number"
                                  min={0}
                                  className="form-control"
                                  placeholder="Insert cubic/yards"
                                  value={item.slabYardsConcrete}
                                  onChange={(e) =>
                                    handleMultiParkadeFieldChange(
                                      e,
                                      index,
                                      "slabYardsConcrete"
                                    )
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              <div className="CommonSelectBox width-250">
                                <select
                                  value={item.heatedParking}
                                  onChange={(e) =>
                                    handleMultiParkadeFieldChange(
                                      e,
                                      index,
                                      "heatedParking"
                                    )
                                  }
                                >
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                                <span>
                                  <img src="images/down.png" />
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="PaginationArea px-3">
                    <h6>Showing 2 of 2 common garages</h6>
                    <h6>
                      <img src="images/leftarrow.png" /> <span>01</span>{" "}
                      <img src="images/rightarrow.png" /> Out 10
                    </h6>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button
                  className="Outline"
                  onClick={handleMultiParkadeStep1Back}
                >
                  Back
                </button>

                <button
                  className="Create FolderPermissionId"
                  onClick={() => {
                    handleMultilevelParkadeQuantitiesModalShow();
                    handleCreateMultiParkadeStep1();
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={MultilevelParkadeQuantitiesModal}
        onHide={handleMultilevelParkadeQuantitiesModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlocksInput"
                    data-dismiss="modal"
                  >
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "MultilevelParkadeQuantitiesModal",
                        "CommonAreasModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    onClick={() => {
                      handleShow(
                        "MultilevelParkadeQuantitiesModal",
                        "CommonBuildingUnitsModal"
                      );
                    }}
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "MultilevelParkadeQuantitiesModal",
                        "CommonBuildingFoundationModal"
                      );
                    }}
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "MultilevelParkadeQuantitiesModal",
                        "CommonGarageInputModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li className="active">
                  <span className="Icon FolderPermissionId">
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "MultilevelParkadeQuantitiesModal",
                        "LandDevlopmentCostModal"
                      );
                    }}
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "MultilevelParkadeQuantitiesModal",
                        "ProformaConfirmSummaryModal"
                      );
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaMultiLevelParkadeInput"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Multi-Level <br /> Parkade <br /> Input
                      </span>
                    </li>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Multi-Level <br /> Parkade <br /> Quantities
                      </span>
                    </li>
                    <li>
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaMultiLevelParkadeSummaryPreview"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_summary.png")}
                        />
                      </span>
                      <span className="Text">
                        Multi-Level <br /> Parkade <br /> Summary <br /> Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalMediumheading2 d-flex align-items-center justify-content-between mb-3 pl-4">
                  <h3 className="mb-0">Multi-Level Parkade Quantities</h3>
                  <h3 className="mb-0">
                    {" "}
                    <img src={require("../assets/images/cash-flow.png")} />
                    &nbsp;&nbsp;Customize Base Costs
                  </h3>
                </div>
                <div className="ProformaAccordion">
                  <div className="accordion" id="accordionExample">
                    {MulitileveleParkadeQuantiesData?.length > 0 &&
                      MulitileveleParkadeQuantiesData?.map((item, ind) => {
                        return (
                          <div className="card">
                            <div className="card-header" id="headingOne">
                              <h2 className="mb-0">
                                <button
                                  className="btn btn-link btn-block text-left collapsed"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseOne"
                                  aria-expanded="false"
                                  aria-controls="collapseOne"
                                >
                                  {item.name}
                                </button>
                              </h2>
                              <a href="javascript:void(0);">
                                <img src="images/book-search.png" />
                              </a>
                            </div>
                            <div
                              id="collapseOne"
                              className="collapse"
                              aria-labelledby="headingOne"
                              data-parent="#accordionExample"
                              style={{}}
                            >
                              <div className="card-body">
                                <div
                                  className="EnterProjectTable"
                                  style={{ overflowX: "scroll" }}
                                >
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Cost Code #</th>
                                        <th>Description</th>
                                        <th>Unit of measaure</th>
                                        <th>Quantity</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item.ProformaBaseCosts?.length > 0 &&
                                        item.ProformaBaseCosts?.map(
                                          (datas, i) => {
                                            return (
                                              <tr>
                                                <td>{i + 1}</td>
                                                <td>{datas.CostCode?.name}</td>
                                                <td>
                                                  {datas.CostCode?.number}
                                                </td>
                                                <td>
                                                  <div className="CommonSelectBox width-250">
                                                    <select
                                                    // value={item.foundationType}
                                                    // onChange={(e) => handleBuildingFieldChange(e, index, 'foundationType')}
                                                    >
                                                      {unitList?.length > 0 &&
                                                        unitList?.map(
                                                          (data, i) => {
                                                            return (
                                                              <option
                                                                key={i}
                                                                name="unitId"
                                                                value={data.id}
                                                                selected={
                                                                  data.id ===
                                                                  datas.Unit.id
                                                                }
                                                                onChange={(e) =>
                                                                  handleLandDevelopmentQuantityChange(
                                                                    e,
                                                                    ind,
                                                                    i
                                                                  )
                                                                }
                                                              >
                                                                {data.name}
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                    </select>
                                                    {/* <span>
                                                      <img src="images/down.png" />
                                                    </span> */}
                                                  </div>
                                                </td>
                                                {datas?.ProformaBlockQuantities
                                                  ?.length > 0 &&
                                                  datas?.ProformaBlockQuantities?.map(
                                                    (block, blocId) => {
                                                      return (
                                                        <td key={blocId}>
                                                          <div className="form-group">
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Insert Quality"
                                                              name="quantity"
                                                              value={
                                                                block.quantity
                                                              }
                                                              onChange={(e) =>
                                                                handleMultiLevelParkadeChange(
                                                                  e,
                                                                  ind,
                                                                  i,
                                                                  blocId
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </td>
                                                      );
                                                    }
                                                  )}
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                  <div className="PaginationArea px-3">
                                    <h6>Showing 1 to 10 of 27 entries</h6>
                                    <h6>
                                      <img src="images/leftarrow.png" /> 01{" "}
                                      <img src="images/rightarrow.png" /> Out 10
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button
                  className="Outline"
                  onClick={handleMultiParkadeStep2Back}
                >
                  Cancel
                </button>

                <button
                  className="Create FolderPermissionId"
                  onClick={() => {
                    handleMulitileveleParkadeSummaryModalShow();
                    handleCreateMultilLevelParkadeStep2();
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={MulitileveleParkadeSummaryModal}
        onHide={handleMulitileveleParkadeSummaryModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleMainClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlocksInput"
                    data-dismiss="modal"
                  >
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "MulitileveleParkadeSummaryModal",
                        "CommonAreasModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_areas.png")} />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon"
                    onClick={() => {
                      handleShow(
                        "MulitileveleParkadeSummaryModal",
                        "CommonBuildingUnitsModal"
                      );
                    }}
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "MulitileveleParkadeSummaryModal",
                        "CommonBuildingFoundationModal"
                      );
                    }}
                  >
                    <img
                      src={require("../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "MulitileveleParkadeSummaryModal",
                        "CommonGarageInputModal"
                      );
                    }}
                  >
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li className="active">
                  <span className="Icon FolderPermissionId">
                    <img src={require("../assets/images/common_garage.png")} />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "MulitileveleParkadeSummaryModal",
                        "LandDevlopmentCostModal"
                      );
                    }}
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    onClick={() => {
                      handleShow(
                        "MulitileveleParkadeSummaryModal",
                        "ProformaConfirmSummaryModal"
                      );
                    }}
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea Proforma">
                <div className="StepBox Green">
                  <ul>
                    <li className="active">
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaMultiLevelParkadeInput"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_input.png")}
                        />
                      </span>
                      <span className="Text">
                        Multi-Level <br /> Parkade <br /> Input
                      </span>
                    </li>
                    <li className="active">
                      <span
                        className="Icon FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateProformaMultiLevelParkadeQuantities"
                        data-dismiss="modal"
                      >
                        <img
                          src={require("../assets/images/block_quantities.png")}
                        />
                      </span>
                      <span className="Text">
                        Multi-Level <br /> Parkade <br /> Quantities
                      </span>
                    </li>
                    <li className="active">
                      <span className="Icon">
                        <img
                          src={require("../assets/images/block_summary.png")}
                        />
                      </span>
                      <span className="Text">
                        Multi-Level <br /> Parkade <br /> Summary <br /> Preview
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="ModalMediumheading d-flex align-items-center justify-content-between">
                  <h3>
                    <span>Multi-Level Parkade Input</span>
                  </h3>
                  <h6>
                    <img src="images/details-cost.png" />
                    Detailed Costs
                  </h6>
                </div>
                <div className="BlockInputs">
                  <table>
                    <thead>
                      <tr>
                        <th>Input</th>
                        <th>Level 1</th>
                        <th>Level 2</th>
                        <th>Totals</th>
                      </tr>
                    </thead>
                    <tbody>
                      {multiLevelStep3List?.data?.table1?.proforma
                        ?.MultiParkades?.length > 0 &&
                        multiLevelStep3List?.data?.table1?.proforma?.MultiParkades?.map(
                          (data, ind) => {
                            return (
                              <tr>
                                <td>{data.garageName}</td>
                                <td>{data.garageSqFt}</td>
                                <td>{data.garagePerimeterLinFt}</td>
                                <td>
                                  {multiLevelStep3List?.data?.table1?.proforma?.MultiParkades?.reduce(
                                    (total, data) =>
                                      total +
                                      data.garageSqFt +
                                      data.garagePerimeterLinFt,
                                    0
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
                <div className="BlockCostsPreview">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4>Multi-Level Parkade Costs Preview</h4>
                    <div className="NewActions">
                      <a href="javascript:void(0);" title="pdf">
                        <img src="images/pdf.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/download.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/setting.png" />
                      </a>
                    </div>
                  </div>
                  <div className="BlockInputsCollapse">
                    <div
                      className="BcpHeader collapsed"
                      data-toggle="collapse"
                      href="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      <h5>Concrete Grade Level Foundation</h5>
                    </div>
                    <div className="collapse" id="collapseExample" style={{}}>
                      <div className="card card-body border-0">
                        <div className="BlockInputs">
                          <table>
                            <thead>
                              <tr>
                                <th>Code Code #</th>
                                <th>Description</th>
                                <th>Level 1</th>
                                <th>Level 2</th>
                                <th>Total Garage Costs</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>2.0202</td>
                                <td>
                                  Garbage Bins - Construction Waste &amp;
                                  Recycling
                                </td>
                                <td>$ 10,800.00</td>
                                <td>$ 10,800.00</td>
                                <td>$ 43,200.00</td>
                              </tr>
                              <tr>
                                <td>2.0202</td>
                                <td>
                                  Garbage Bins - Construction Waste &amp;
                                  Recycling
                                </td>
                                <td>$ 10,800.00</td>
                                <td>$ 10,800.00</td>
                                <td>$ 10,800.00</td>
                              </tr>
                              <tr>
                                <td>2.0202</td>
                                <td>
                                  Garbage Bins - Construction Waste &amp;
                                  Recycling
                                </td>
                                <td>$ 10,800.00</td>
                                <td>$ 10,800.00</td>
                                <td>$ 10,800.00</td>
                              </tr>
                              <tr>
                                <td>2.0202</td>
                                <td>
                                  Garbage Bins - Construction Waste &amp;
                                  Recycling
                                </td>
                                <td>$ 10,800.00</td>
                                <td>$ 10,800.00</td>
                                <td>$ 10,800.00</td>
                              </tr>
                              <tr>
                                <td>2.0202</td>
                                <td>
                                  Garbage Bins - Construction Waste &amp;
                                  Recycling
                                </td>
                                <td>$ 10,800.00</td>
                                <td>$ 10,800.00</td>
                                <td>$ 10,800.00</td>
                              </tr>
                              <tr>
                                <td>2.0202</td>
                                <td>
                                  Garbage Bins - Construction Waste &amp;
                                  Recycling
                                </td>
                                <td>$ 10,800.00</td>
                                <td>$ 10,800.00</td>
                                <td>$ 10,800.00</td>
                              </tr>
                              <tr>
                                <td>2.0202</td>
                                <td>
                                  Garbage Bins - Construction Waste &amp;
                                  Recycling
                                </td>
                                <td>$ 10,800.00</td>
                                <td>$ 10,800.00</td>
                                <td>$ 10,800.00</td>
                              </tr>
                              <tr>
                                <td>2.0202</td>
                                <td>
                                  Garbage Bins - Construction Waste &amp;
                                  Recycling
                                </td>
                                <td>$ 10,800.00</td>
                                <td>$ 10,800.00</td>
                                <td>$ 10,800.00</td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td />
                                <td>
                                  Total Concrete Grade Level Foundation Costs
                                </td>
                                <td>67,480.00</td>
                                <td>67,480.00</td>
                                <td>2,75,480.00</td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="BlockInputs Red">
                    <table>
                      <tfoot>
                        <tr>
                          <td />
                          <td>Grand Total</td>
                          <td>$ 1,67,480.00</td>
                          <td>$ 1,67,480.00</td>
                          <td>$ 32,275,590.00</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button
                  className="Outline"
                  onClick={handleMultiParkadeStep3Back}
                >
                  Back
                </button>

                <button
                  className="Create FolderPermissionId"
                  onClick={handleLandDevlopmentCostModalShow}
                >
                  Land Development
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <MultifamilyJobStep1
        perFormaId={perFormaId}
        setShow={setShow}
        MultifamilyJobStep1Modal={MultifamilyJobStep1Modal}
      />
    </>
  );
};

export default ProformaModal;
