import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const FolderHistory = ({folderHistoryMod,folderHistoryList,SetFileMangerModal,setFolderHistoryMod,filesName}) => {
  return (
    <div>
        <Modal  show={folderHistoryMod}
         className="ModalBox modal fade SmallModal show"
         style={{ display: "block" }}
        >
          <a
        class="CloseModal"
        data-toggle="modal"
        onClick={()=>{setFolderHistoryMod(false);SetFileMangerModal(true)}}
      >
        ×
      </a>  
      <div class="FormArea">
              <div class="ModalTitleBox">
                <h4>Folder History</h4>
              </div>
              <div class="JobTitleArea mb-2">
                <div class="JobBox">
                  <h6><span>Folder:</span>&nbsp;{filesName??""}</h6>
                </div>
              </div>
             
              <div class="TableList TableListAlign CommonHeading TableHeightScroll">
                <table>
                  <thead>
                    <tr>
                      <th>Modified By</th>
                      <th>Created By</th>
                      <th>Date</th>
                      <th>Activity</th>
                    </tr>
                  </thead>
                  <tbody>
                   {
                    folderHistoryList?.folderHistories?.length>0?
                    folderHistoryList.folderHistories.map((elem,id)=>{
                     return (
                      <tr key={id}>
                      <td>{elem?.modifiedBy}</td>
                      <td>{elem?.createdBy}</td>
                      <td>{elem?.createdAt.split("T")[0]}</td>
                      <td>{elem?.activity}</td>
                    </tr>
                     )
                    }):"NO Data Found"
                   }
                  </tbody>
                </table>
              </div>
            </div>
        </Modal>
    </div>
  )
}

export default FolderHistory