import React, { useState } from 'react'
import Modal from "react-bootstrap/Modal";
import GiveAccessModal from './GiveAccessModal';
import ItineraryManageAccessMyModal from './ItineraryManageAccessMyModal';
import ItineraryManageAccessUserModal from './ItineraryManageAccessUserModal';
import ItineraryRequestModal from './ItineraryRequestModal';
import ItineraryRequestAccessModal from './ItineraryRequestAccessModal';


const ItinerarySettingModal = ({SetItineraySettingState,ItineraySettingState}) => {

    const handleItineraySettingModalClose = () => {
        SetItineraySettingState(false);
      };
      const handleItineraySettingModalShow = () => {
        SetItineraySettingState(true);
      };



      const [GiveAccessStatus, SetGiveAccessStatus] = useState(false)
      const [ItineraryManageAccessMyStatus, SetItineraryManageAccessMyStatus] = useState(false)
      const [ItineraryManageAccessUserStatus, SetItineraryManageAccessUserStatus] = useState(false)
      const [ItineraryRequestStatus, SetItineraryRequestStatus] = useState(false)
      const [ItineraryRequestAccessStatus, SetItineraryRequestAccessStatus] =useState(false)


    
  return (
    <>
    <Modal show={ItineraySettingState} className="ModalBox SmallModal">
    <div class="modal-content BgCreate">
      <a
        onClick={handleItineraySettingModalClose}
        class="CloseModal"
        data-toggle="modal"
      >
        ×
      </a>

      <div class="FormArea">
              <div class="ModalTitleBox">
                <h4>Itinerary Settings</h4>
              </div>
              <div class="FolderForm">
                <form>
                  <div class="ItineraryArea">
                    <h3>My Itinerary</h3>
                    <div class="form-group">
                      <h6>Share Itinerary</h6>
                      <div class="PermissionsName">
                        <h5 class="FolderPermissionId" onClick={()=> {SetGiveAccessStatus(true)
                        SetItineraySettingState(false)
                       }}>
                          <span><img src="images/share.png" /></span> Share
                          Itinerary
                        </h5>
                      </div>
                    </div>
                    <div class="ItineraryBox">
                      <div class="form-group">
                        <h6>Requests</h6>
                        <div class="PermissionsName">
                          <h5 class="FolderPermissionId" onClick={()=> {SetItineraryRequestStatus(true)
                                                  SetItineraySettingState(false)
                                                }}>
                            <span><img src="images/requests.png" /></span>
                            <div class="Count">3</div>
                            Requests
                          </h5>
                        </div>
                      </div>
                      <div class="form-group">
                        <h6>Manage Access</h6>
                        <div class="PermissionsName">
                          <h5 class="FolderPermissionId" data-toggle="modal" onClick={()=>{ SetItineraryManageAccessMyStatus(true)
                                                  SetItineraySettingState(false)
                                                }}>
                            <span><img src="images/access-control.png" /></span>
                            Manage Access
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="ItineraryArea">
                    <h3>Shared Itineraries</h3>
                    <div class="form-group">
                      <h6>Requests Access</h6>
                      <div class="PermissionsName">
                        <h5 class="FolderPermissionId" data-toggle="modal" onClick={()=>{SetItineraryRequestAccessStatus(true)
                                                SetItineraySettingState(false)
                                              }}>
                          <span><img src="images/requests-access.png" /></span>
                          Requests Access
                        </h5>
                      </div>
                    </div>
                    <div class="form-group">
                      <h6>Manage Access</h6>
                      <div class="PermissionsName">
                        <h5 class="FolderPermissionId" data-toggle="modal" onClick={()=>{SetItineraryManageAccessUserStatus(true)
                                                SetItineraySettingState(false)
                                              }}>
                          <span><img src="images/access-control.png" /></span>
                          Manage Access
                        </h5>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

     </div>
     </Modal>

     {GiveAccessStatus && <GiveAccessModal  GiveAccessStatus={GiveAccessStatus} SetGiveAccessStatus={SetGiveAccessStatus} SetItineraySettingState={SetItineraySettingState} /> }
     {ItineraryManageAccessMyStatus && <ItineraryManageAccessMyModal  ItineraryManageAccessMyStatus={ItineraryManageAccessMyStatus} SetItineraryManageAccessMyStatus={SetItineraryManageAccessMyStatus} SetItineraySettingState={SetItineraySettingState} /> }

     {ItineraryManageAccessUserStatus && <ItineraryManageAccessUserModal  ItineraryManageAccessUserStatus={ItineraryManageAccessUserStatus} SetItineraryManageAccessUserStatus={SetItineraryManageAccessUserStatus} SetItineraySettingState={SetItineraySettingState} /> }
     {ItineraryRequestStatus && <ItineraryRequestModal  ItineraryRequestStatus={ItineraryRequestStatus} SetItineraryRequestStatus={SetItineraryRequestStatus} SetItineraySettingState={SetItineraySettingState} /> }
     {ItineraryRequestAccessStatus && <ItineraryRequestAccessModal  ItineraryRequestAccessStatus={ItineraryRequestAccessStatus} SetItineraryRequestAccessStatus={SetItineraryRequestAccessStatus} SetItineraySettingState={SetItineraySettingState} /> }


     </>

)
}

export default ItinerarySettingModal