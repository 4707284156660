import axios from "axios";
import { notificationConstantDK } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config/Config";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));


export const getAllNotificationInitate = () => ({
    type: notificationConstantDK.GET_ALL_NOTIFICATION_INITIATE,
  });
  
  export const getAllNotificationSuccess = (data) => ({
    type: notificationConstantDK.GET_ALL_NOTIFICATION_SUCCESS,
    payload: data,
  });
  
  export const getAllNotificationFailure = (data) => ({
    type: notificationConstantDK.GET_ALL_NOTIFICATION_FAILURE,
    payload: data,
  });
  
  export function getAllNotificationAction( pid) {
    const token = isLoggedIn("adminData1");
    const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
    return (dispatch) => {
      dispatch(getAllNotificationInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}companies/${iBuildLocalData?.user?.id}/notifications`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            ////console.log("data", data);
            if (data && data.status == 200) {
              dispatch(getAllNotificationSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(getAllNotificationFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(getAllNotificationSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(getAllNotificationFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }



  export const getSingleNotificationInitate = () => ({
    type: notificationConstantDK.GET_SINGLE_NOTIFICATION_INITIATE,
  });
  
  export const getSingleNotificationSuccess = (data) => ({
    type: notificationConstantDK.GET_SINGLE_NOTIFICATION_SUCCESS,
    payload: data,
  });
  
  export const getSingleNotificationFailure = (data) => ({
    type: notificationConstantDK.GET_SINGLE_NOTIFICATION_FAILURE,
    payload: data,
  });
  
  export function getSingleNotificationAction( pid) {
    const token = isLoggedIn("adminData1");
    const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
    return (dispatch) => {
      dispatch(getAllNotificationInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}companies/${iBuildLocalData?.user?.id}/notifications`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            ////console.log("data", data);
            if (data && data.status == 200) {
              dispatch(getSingleNotificationSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(getSingleNotificationFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(getSingleNotificationSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(getSingleNotificationFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }



  export const deleteNotificationInitate = () => ({
    type: notificationConstantDK.DELETE_NOTIFICATION_INITIATE,
  });
  
  export const deleteNotificationSuccess = (data) => ({
    type: notificationConstantDK.DELETE_NOTIFICATION_SUCCESS,
    payload: data,
  });
  
  export const deleteNotificationFailure = (data) => ({
    type: notificationConstantDK.DELETE_NOTIFICATION_FAILURE,
    payload: data,
  });
  
  export function deleteNotificationAction( pid) {
    const token = isLoggedIn("adminData1");
    const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
    return (dispatch) => {
      dispatch(getAllNotificationInitate());
      return new Promise((resolve, reject) => {
        axios
          .delete(`${Url}companies/${iBuildLocalData?.user?.id}/notifications`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            ////console.log("data", data);
            if (data && data.status == 200) {
              dispatch(deleteNotificationSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(deleteNotificationFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(deleteNotificationSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(deleteNotificationFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }





  export const mark_as_readInitiate = () => ({
    type: notificationConstantDK.MARK_AS_READ_INITIATE,
  });
  
  export const mark_as_readSuccess = (data) => ({
    type: notificationConstantDK.MARK_AS_READ_SUCCESS,
    payload: data,
  });
  
  export const mark_as_readFailure = (data) => ({
    type: notificationConstantDK.MARK_AS_READ_FAILURE,
    payload: data,
  });
  
  export const MarkAsReadAction = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(mark_as_readInitiate(payload));
        const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.id}/notifications`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = response;
        if (data && data.status == 201) {
          dispatch(mark_as_readSuccess(response.data));
        } else {
          dispatch(mark_as_readFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(mark_as_readFailure(err));
        return err.response
      }
    };



