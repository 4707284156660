import React, { useState, useEffect, useRef } from "react";
import Header from './Header'
import SideNav from './SideNav'
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useLocation ,Link,useNavigate} from 'react-router-dom'
import { useDispatch ,useSelector} from 'react-redux'
import SignaturePad from "react-signature-canvas";
import { Button } from "react-bootstrap";

import { getWarrantySettingDiscussion, updateWarrantySettingDiscussion } from '../redux/action/warrantyAction'
import { AddDiscussionAction, AddReplyAction, discussionListAction } from "../redux/action/constructionsAction";
import {
  addChangeOrderItem,
  addSignatureInAgreements,
  changeOrderDetails,
  createChangeOrder,
  createCustomItem,
  createSupportingDocsFiles,
  generateAgreement,
  getAllCategoryLists,
  getAssemblyLists,
  getChangeOrderList,
  getCostCategoryLists,
  getCostCodeLists,
  getDeleteItem,
  getItemByChangeOrderId,
  getMeasureLists,
  historyChangeOrders,
  sendEmailAgreement,
  updateChangeOrder,
  updateChangeOrderDocsFiles,
} from "../redux/action/changeOrderAction";
import { getVendorList } from "../redux/action/warrantyAction";
import {
  getPriceBookCategoryList,
  getPrimaryClient,
  getSecondaryClient,
  getSelectItemList,
} from "../redux/action/designSelectionsAction";
import { getGeneralInformationData } from "../redux/action/clientViewConfigAction";
import moment from "moment";
import { convertToPNG, dataURLtoBlob } from "../utils/blog";
const initialState = {
  DiscussionSettingsModal: false,
  discussionData: [],
  visibleInClientView: false,
  visibleInTradeView: false,
  visibleInInvestorView: false,
  agreementDocumentShow: false,
  AddCustomModal: false,

  search: "",
  assemblySearch: "",
  measuresSearch: "",
  UploadImagesModal: false,
  attachments: [],
  SelectAccountsModal: false,
  costCategorySearch: "",
  costCodeSearch: "",

  name: "",
  CategoryId: "",
  entryType: "",
  Assembly: "",
  AssemblyId: "",
  unitCost: "",
  MeasureId: "",
  markup: "",
  costCode: "",
  costArea: "",
  AddNewSupportingDocsModal: false,
  supportingDocFiless: null,

  SelectItemBookPriceModal: false,
  categorySearch: "",
  itemSearch: "",
  clientContractShow: false,
  agreementDocumentShow: false,
  message: "",
  notesFiles: null,
  AddNotesModal: false,
  notes: [],
  changeOrderId: "",
  measure: "",
  UpdatingNewSupportingDocsModal: false,
  updateFiles: null,
  itemId: "",
  designCategoryId: "",
  categoryToggle: false,

  primarySignee: "",
  additionalSignee: "",
  numberOfSignee: "",
  requestAdjustment: false,
  description: "",
  requestDate: "",
   possessionDate:"",
  clientName:"",
  clientPhone:"",
  clientEmail:"",
  jobAddress:"",
  SelectionHistoryModal: false,
  ManageSignatueModal: false,
  signature: "",
  isGenerateAggremet: false,
  changeOrderData: [],
};

const ChangeOrderDiscussion = () => {
  const {state}=useLocation()
  console.log("state",state);
const navigate=useNavigate()
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const {
    DiscussionSettingsModal,
    discussionData,
    visibleInClientView,
    visibleInTradeView,
    visibleInInvestorView,
    requestAdjustment,
    AddCustomModal,
    search,
    assemblySearch,
    measuresSearch,
    UploadImagesModal,
    attachments,
    SelectAccountsModal,
    costCategorySearch,
    costCodeSearch,

    name,
    CategoryId,
    entryType,
    Assembly,
    AssemblyId,
    unitCost,
    MeasureId,
    markup,
    costCode,
    costArea,
    AddNewSupportingDocsModal,
    supportingDocFiless,

    SelectItemBookPriceModal,
    categorySearch,
    itemSearch,
    clientContractShow,
    agreementDocumentShow,
    message,
    notesFiles,
    AddNotesModal,
    notes,
    changeOrderId,
    measure,
    UpdatingNewSupportingDocsModal,
    updateFiles,
    itemId,
    designCategoryId,
    categoryToggle,
    primarySignee,
    additionalSignee,
    numberOfSignee,
    description,
    requestDate,
    SelectionHistoryModal,
    ManageSignatueModal,
    signature,
    isGenerateAggremet,
   changeOrderData,
     possessionDate,
  clientName,
  clientPhone,
  clientEmail,
  jobAddress,
  } = iState;
 const {
   allCategoryList,
   allAssemblyList,
   allMeasureList,
   costCategoryList,
   costCodeList,
   changeOrderList,
   itemList,
   historyChangeOrderList,
 } = useSelector((state) => state.changeOrderReducer);
  const signCanvas = useRef({});
  useEffect(()=>{
    
  },[])
  const {  discussionList, } =
    useSelector((state) => state.constructionReducer);
  useEffect(() => {
    if(DiscussionSettingsModal){
    dispatch(getWarrantySettingDiscussion("Project",state?.projectId)).then((res) => {
      if (res.status === 200) {
        updateState({
          ...iState,
          discussionData: res.data,
          visibleInTradeView: res.data.settings?.visibleInTradeView,
          visibleInInvestorView: res.data.settings?.visibleInInvestorView,
          visibleInClientView: res.data.settings?.visibleInClientView,
        });
      }
    });}
  }, [DiscussionSettingsModal]);
console.log({itemList});
  useEffect(()=>{
    updateState(prev=>({
      ...prev,
      description:state?.description,
      requestDate:state?.requestDate,
      numberOfSignee:state?.numberOfClientSignees,
      requestAdjustment:state?.requestAdjustment,

    }))
  },[])

  const handleDiscussionSettingsModalShow = () => {
    updateState({ ...iState, DiscussionSettingsModal: true });
  };
  const handleDiscussionSettingsModalClose = () => {
    updateState({ ...iState, DiscussionSettingsModal: false });
  };

  const handleDiscussionCheckBox = (type) => {
    if (type === "client") {
      updateState({
        ...iState,
        visibleInClientView: !visibleInClientView,
      });
    } else if (type === "trade") {
      updateState({
        ...iState,
        visibleInTradeView: !visibleInTradeView,
      });
    } else {
      updateState({
        ...iState,
        visibleInInvestorView: !visibleInInvestorView,
      });
    }
  };

  const handleDiscussionSettingUpdate = () => {
    const dataa = {
      visibleInClientView,
      visibleInTradeView,
      visibleInInvestorView,
      discussableId:discussionData?.settings?.id,
      discussableType: "Project",
      
    };
    dispatch(
      updateWarrantySettingDiscussion(dataa)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getWarrantySettingDiscussion("Project",state?.projectId))
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          DiscussionSettingsModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          DiscussionSettingsModal: true,
        });
      }
    });
  };

  

  const [discussionState, updateDiscussionState] = useState({
    jobId: "",
    message: "",
    discussionError: "",
  });
  const [replyState, updateReplyState] = useState({
    messageId: "",
    jobId: "",
    message: "",
    replyError: "",
  });
  const [replyField, updateReplyField] = useState({ flag: false, index: "" })

  let handleValidationDiscussion = () => {
    let discussionError = "";
    let formIsValid = true;

    if (discussionState.message == "") {
      discussionError = "* Please enter comment";
      formIsValid = false;
    }

    updateDiscussionState({
      ...discussionState,
      discussionError: discussionError,
    });
    return formIsValid;
  };

  const handleAddDiscussion = () => {
    let formIsValid = handleValidationDiscussion();
    if (formIsValid) {
      dispatch(
        AddDiscussionAction({ message: discussionState.message, jobId: state?.jobId })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(discussionListAction({ jobId: state?.jobId }));

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleAddReply = () => {
    let formIsValid = true;
    if (formIsValid) {
      dispatch(
        AddReplyAction({
          message: replyState.message,
          jobId: replyState.jobId,
          messageId: replyState.messageId,
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(discussionListAction({ jobId: state?.jobId  }));
            updateReplyState({
              messageId: "",
              jobId: "",
              message: "",
              replyError: "",
            });

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const [showClient,setShowClient]=useState(false)
  const [showDiscussion,setShowDiscussion]=useState(false)
 useEffect(() => {
   dispatch(getAllCategoryLists(state?.projectId));
   dispatch(getMeasureLists());
   dispatch(getCostCategoryLists());
   dispatch(getCostCodeLists());
   dispatch(getChangeOrderList(state?.projectId, state?.id, state?.jobType));
   dispatch(getItemByChangeOrderId(state?.projectId, state?.id));
 }, []);
 const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

 const { priceBookCategoryList, assembleList, vendorList } = useSelector(
   (state) => state.designSelectionsReducers
 );

 const { primaryList, secondaryList } = useSelector(
   (state) => state.designSelectionsReducers
 );
 const [primary, setPrimary] = useState([]);
 console.log("primary", primary);
 useEffect(() => {
   dispatch(getPriceBookCategoryList());
   dispatch(getVendorList());
   dispatch(getGeneralInformationData(state?.jobId, state?.jobType)).then(
     (res) => {
       if (res.status === 200) {
        
         dispatch(
           getPrimaryClient(
             res?.data?.ViewSetting?.id,
             state?.jobId,
             state?.jobType
           )
         ).then((resp) => {
           if (resp.status === 200) {
             const primary = resp?.data?.clientsDetails?.find(
               (item) => item.isPrimary === true
             );
             setPrimary(primary);
           }
         });
         dispatch(
           getSecondaryClient(
             res?.data?.ViewSetting?.id,
             state?.jobId,
             state?.jobType
           )
         );
       }
     }
   );
 }, []);

 const handleInputChange = (e) => {
   const { name, value } = e.target;
   updateState({ ...iState, [name]: value });
 };

 useEffect(() => {
   if (CategoryId != "") {
     dispatch(getAssemblyLists(state?.projectId, CategoryId));
   }
 }, [CategoryId]);

 const handleAddCustomModalShow = () => {
   updateState({ ...iState, AddCustomModal: true });
 };
 const handleAddCustomModalClose = () => {
   updateState({ ...iState, AddCustomModal: false });
 };

 const handleCategoryItemClick = (id) => {
   updateState({ ...iState, CategoryId: id });
 };

 const handleAsssemblyItemClick = (id) => {
   updateState({ ...iState, AssemblyId: id });
 };

 const handleMeasureItemClick = (item) => {
   updateState({ ...iState, MeasureId: item.id, measure: item.name });
 };

 const handleUploadImagesModalShow = () => {
   updateState({ ...iState, UploadImagesModal: true, AddCustomModal: false });
 };
 const handleUploadImagesModalClose = () => {
   updateState({ ...iState, UploadImagesModal: false, AddCustomModal: true });
 };
 const handleFileChange = (event) => {
   const files = event.target.files;

   // Extract file names from the selected files
   const fileNames = Array.from(files).map((file) => file.name);

   // Update the state with the file names
   updateState({ ...iState, attachments: fileNames });
 };

 const handleFileRemove = (i) => {
   let user = [...attachments];
   user.splice(i, 1);
   updateState({
     ...iState,
     attachments: user,
   });
 };

 const handleSelectAccountsModalShow = () => {
   updateState({
     ...iState,
     SelectAccountsModal: true,
     AddCustomModal: false,
   });
 };
 const handleSelectAccountsModalClose = () => {
   updateState({
     ...iState,
     SelectAccountsModal: false,
     AddCustomModal: true,
   });
 };

 const [selectedCostCodes, setSelectedCostCodes] = useState([]);

 const handleCheckboxChange = (item) => {
   // Check if the item is already in the array
   if (selectedCostCodes.includes(item)) {
     // If it is, remove it
     setSelectedCostCodes((prevSelected) =>
       prevSelected.filter((selectedItem) => selectedItem !== item)
     );
   } else {
     // If it's not, add it
     setSelectedCostCodes((prevSelected) => [...prevSelected, item]);
   }
 };

 const handleselectedCostCodesRemove = (i) => {
   let user = [...selectedCostCodes];
   user.splice(i, 1);
   setSelectedCostCodes(user);
 };

 const handleRadioChange = (value) => {
   updateState({ ...iState, entryType: value });
 };

 const handleCostCategoryItemClick = (name) => {
   updateState({ ...iState, costArea: name });
 };

 const handleCreateCustomItem = async () => {
   try {
     let dataa = {
       items: [
         {
           name,
           // CategoryId,
           // entryType,
           // AssemblyId,
           jobId: state?.jobId,
           unitCost,
           measure,
           markup,
           changeOrderId: state?.id,
           clientTotal: unitCost,
           quantity: 1,
           poLineTotal: unitCost,
           account: "akk",
           picture: attachments?.[0],
         },
       ],
     };

     const res = await dispatch(addChangeOrderItem(state?.projectId, dataa));

     if (res.status === 200) {
       dispatch(getItemByChangeOrderId(state?.projectId, state?.id));
       toast.success(res.data?.message, {
         position: toast.POSITION.TOP_RIGHT,
         autoClose: 1000,
       });
       updateState({
         ...iState,
         AddCustomModal: false,
       });
     } else {
       toast.error(res.data?.message, {
         position: toast.POSITION.TOP_RIGHT,
       });
       updateState({ ...iState, AddCustomModal: true });
     }
   } catch (error) {
     toast.error(error.data?.message, {
       position: toast.POSITION.TOP_RIGHT,
     });
     updateState({ ...iState, AddCustomModal: true });
   }
 };

 const handleFileChanges = (event, type) => {
   const file = event?.target?.files[0];
   if (type === "support") {
     updateState({ ...iState, supportingDocFiles: file });
   }
   if (type === "docsUpdate") {
     updateState({ ...iState, supportingDocFiless: file });
   }
 };

 const handleRemoveDocsFile = () => {
   // Reset the 'templateFile' state to null
   updateState({
     ...iState,
     supportingDocFiless: null,
   });

   // Clear the file input field by resetting its value
   const fileInput = document.querySelector("#docsInput");
   if (fileInput) {
     fileInput.value = "";
   }
 };

 const handleAddNewSupportingDocsModalShow = (id) => {
   updateState({
     ...iState,
     AddNewSupportingDocsModal: true,
     id: id,
     supportingDocFiless: "",
   });
 };
 const handleAddNewSupportingDocsModalClose = () => {
   updateState({
     ...iState,
     AddNewSupportingDocsModal: false,
   });
 };

 const handleAddNewSupportingDocs = () => {
   const dataa = { files: supportingDocFiless };
   dispatch(createSupportingDocsFiles(dataa)).then((res) => {
     if (res.status === 200) {
       toast.success("Add Files Successfully", {
         position: toast.POSITION.TOP_RIGHT,
         autoClose: 1000,
       });
       updateState({
         ...iState,
         AddNewSupportingDocsModal: false,
         supportingDocFiless: null,
       });
     } else {
       toast.error(res.data.message, {
         position: toast.POSITION.TOP_RIGHT,
       });
       updateState({
         ...iState,
         AddNewSupportingDocsModal: true,
       });
     }
   });
 };
 const handleDisplayShow = () => {
   updateState({ ...iState, clientContractShow: !clientContractShow });
 };

 const handleAgreementDocumentShow = () => {
   updateState({ ...iState, agreementDocumentShow: !agreementDocumentShow });
 };

 const [selectedOption, setSelectedOption] = useState("Text");
 const [textInput, setTextInput] = useState(true);
 const [fileData, setFileData] = useState(false);

 const onValueChange = (event) => {
   updateState({ ...iState, message: "", supportingDocFiles: "" });
   const value = event.target.value;
   setSelectedOption(value);
   setTextInput(value === "Text");
   setFileData(value === "File");
 };

 const handleRemoveClientFile = () => {
   // Reset the 'templateFile' state to null
   updateState({
     ...iState,
     supportingDocFiles: null,
     supportingDocFiless: null,
     itemDocs: null,
   });

   // Clear the file input field by resetting its value
   const fileInput = document.querySelector("#fileInput");
   if (fileInput) {
     fileInput.value = "";
   }
 };

 const handleAddNotesShow = () => {
   updateState({
     ...iState,
     AddNotesModal: true,
     message: "",
   });
 };
 const handleAddNotesClose = () => {
   updateState({
     ...iState,
     AddNotesModal: false,
   });
 };

 const handleAddNotesChanges = (event) => {
   const file = event?.target?.files[0];
   updateState({ ...iState, notesFiles: file });
 };

 const handleAddNotes = () => {
   let updatedNotes = [];

   if (selectedOption === "Text") {
     updatedNotes = [
       {
         type: "text",
         data: message,
       },
     ];
   } else {
     updatedNotes = [
       {
         type: "file",
         data: notesFiles,
       },
     ];
   }

   updateState({ ...iState, notes: updatedNotes, AddNotesModal: false });
 };

 const handleSaveDraft = (type) => {
   const dataa = {
    draft: type==="draft"?true:false,
     draft: true,
     jobId: state?.jobId,
     jobType: state?.jobType,
     notes,
     id: state?.id,
     primaryClientSignee: primarySignee,
     additionalClientSignee: additionalSignee,
     clientSignee: numberOfSignee,
     requestAdjustment,
     description: description,
     possessionDate: primary?.createdAt,
     clientName: primary?.legalName,
     clientPhone: primary?.primaryPhoneNum,
     clientEmail: primary?.email,
     jobAddress: primary?.streetAddress,
     requestDate: requestDate,
     possessionDate:moment(primary?.createdAt).format(
        "YYYY-MM-DD"
      ),
      items : itemList?.data?.map((item) => ({
        id: item.id,
        changeOrderId: item.changeOrderId,
        quantity: item.quantity,
        measure: item.measure,
        perUnit: item.perUnit,
        clientTotal: item.clientTotal,
        poLineTotal: item.poLineTotal,
        markup: item.markup,
        account: "kk",
        picture: item.picture,
      })) || [],
   };
   dispatch(updateChangeOrder(state?.projectId, dataa)).then((res) => {
     if (res.status === 202) {
      const { projectId, jobId,...rest } = state;
    const newState = { ProjectId: projectId,jobId:jobId ,...rest };
    
    navigate("/change-order-list", { state: newState });
       toast.success(res.data.message, {
         position: toast.POSITION.TOP_RIGHT,
         autoClose: 1000,
       });
       updateState({
         ...iState,
         draft: false,
       });
     } else {
       toast.error(res.data.message, {
         position: toast.POSITION.TOP_RIGHT,
       });
     }
   });
 };

 const handleDeleteItem = (id) => {
   dispatch(getDeleteItem(id)).then((res) => {
     if (res.status === 200) {
       dispatch(getItemByChangeOrderId(state?.projectId, state?.id));
       toast.success(res.data.message, {
         position: toast.POSITION.TOP_RIGHT,
         autoClose: 1000,
       });
     } else {
       toast.error(res.data.message, {
         position: toast.POSITION.TOP_RIGHT,
       });
     }
   });
 };

 const handleUpdateDocsChanges = (event) => {
   const file = event?.target?.files[0];
   updateState({ ...iState, updateFiles: file });
 };

 const handleUpdatingNewSupportingDocsModalShow = (id) => {
   updateState({
     ...iState,
     UpdatingNewSupportingDocsModal: true,
     itemId: id,
     updateFiles: null,
   });
 };
 const handleUpdatingNewSupportingDocsModalClose = () => {
   updateState({
     ...iState,
     UpdatingNewSupportingDocsModal: false,
     updateFiles: null,
   });
 };

 const handleUpdateDocs = () => {
   const dataa = {
     file: updateFiles?.name,
     jobId: state?.jobId,
     origin: state?.id,
     itemId,
     type: updateFiles?.type,
   };
   dispatch(updateChangeOrderDocsFiles(state?.projectId, dataa)).then((res) => {
     if (res.status === 200) {
       dispatch(getItemByChangeOrderId(state?.projectId, state?.id));
       toast.success("Supporting document updated", {
         position: toast.POSITION.TOP_RIGHT,
         autoClose: 1000,
       });
       updateState({
         ...iState,
         UpdatingNewSupportingDocsModal: false,
       });
     } else {
       toast.error(res.data.message, {
         position: toast.POSITION.TOP_RIGHT,
       });
       updateState({
         ...iState,
         UpdatingNewSupportingDocsModal: true,
       });
     }
   });
 };
 const [typeEdit, setTypeEdit] = useState(false);
 const [selectedAssemblies, setSelectedAssemblies] = useState([]);

 const [showSelectItemBookPriceModal, setShowSelectItemBookPriceModal] =
   useState(false);
 const handleAssemblyCheckboxChange = (item) => {
   const isSelected = selectedAssemblies.some(
     (assembly) => assembly.entryId == item.id
   );

   if (isSelected) {
     setSelectedAssemblies(
       selectedAssemblies?.filter((assembly) => assembly.entryId !== item.id)
     );
   } else {
     const selectedAssembly = {
       id: state?.id,
       entryId: item.id,
       changeOrderId: state?.id,
       entryName: item.name,
       quantity: "",
       measure: item?.measure?.name,
       markup: item.markup,
       perUnit: item?.unitCost,
       account: "akk",
       poLineTotal: Number(item?.unitCost),
       clientTotal: Number(item?.unitCost),
     };

     setSelectedAssemblies([...selectedAssemblies, selectedAssembly]);
   }
   if (typeEdit) {
     if (isSelected) {
       setSelectedAssemblies([]);
     } else {
       const selectedAssembly = {
         entryId: item.id,
         entryName: item.name,
         quantity: "",
         measure: item?.measure?.name,
         costcode: "",
         markup: "",
         perUnit: "",
         builderTotal: "",
         discount: "",
         clientTotal: "",
       };

       setSelectedAssemblies([selectedAssembly]);
     }
   }
 };

 const handleAssemblyChange = (e, i) => {
   const { name, value } = e.target;
   let user = [...selectedAssemblies];
   user[i] = { ...user[i], [name]: value };
   setSelectedAssemblies(user);
 };

 const handleCategoryClick = (id) => {
   updateState({ ...iState, designCategoryId: id });
 };

 const handleCategoryToggle = () => {
   updateState({ ...iState, categoryToggle: !categoryToggle });
 };

 useEffect(() => {
   if (designCategoryId != "") {
     dispatch(getSelectItemList(state?.projectId, designCategoryId));
   }
 }, [designCategoryId]);

 const handleSelectItemBookPriceModalClose = () => {
   setShowSelectItemBookPriceModal(false);
 };

 const handleSelectItemBookPriceModalShow = () => {
   setShowSelectItemBookPriceModal(true);
   setSelectedAssemblies([]);
 };

 const handleAddSelectedItems = async () => {
   try {
     let dataa = {
       items: selectedAssemblies?.map((data) => ({
         measure: data?.measure,
         markup: data?.markup,
         changeOrderId: data.changeOrderId,
         quantity: Number(data?.quantity),
         poLineTotal: Number(data?.poLineTotal) * Number(data?.quantity),
         clientTotal: Number(data?.poLineTotal) * Number(data?.quantity),
         account: data?.account,
       })),
     };
     const res = await dispatch(addChangeOrderItem(state?.projectId, dataa));
     if (res.status === 200) {
       setShowSelectItemBookPriceModal(false);
       setSelectedAssemblies([]);
       updateState({ ...iState, changeOrderId: "" });
       await dispatch(
         getItemByChangeOrderId(state?.projectId, state?.id)
       );
       toast.success(res.data?.message, {
         position: toast.POSITION.TOP_RIGHT,
         autoClose: 1000,
       });
     } else {
       toast.error(res.data?.message, {
         position: toast.POSITION.TOP_RIGHT,
       });
     }
   } catch (error) {
     toast.error(error?.data?.message, {
       position: toast.POSITION.TOP_RIGHT,
     });
   }
 };

 const handleCheck = (e) => {
   updateState({ ...iState, requestAdjustment: e.target.checked });
 };

 const [aggrementView, setAggrementView] = useState(false);
 const [aggrementViewDocument, setAggrementViewDocument] = useState(false);
 const [signatureData, setSignatureData] = useState([]);
 const handleAgreementViewShow = () => {
   setAggrementView(true);
 };

 useEffect(() => {
   if (aggrementView) {
     if (primary?.legalName) {
       const datta = {
         clientId: primary?.id,
         name: primary?.legalName ?? "",
         designation: "Design Manager",
         changeOrderId: state?.id,
       };
       dispatch(generateAgreement(state?.projectId, datta))
         .then((res) => {
           if (res.status === 200) {
             dispatch(
               changeOrderDetails(
                 iBuildLocalData?.user?.CompanyId,
                 state?.projectId,
                 state?.changeOrderId,
                 iBuildLocalData?.user?.token
               )
             ).then((resp) => {
               if (resp.status === 200) {
                 const signDummyData =
                   resp?.data?.data?.changeOrderAgreementSignatures?.at(-1);
                 updateState({
                   ...iState,
                   changeOrderData: resp?.data?.data,
                   isGenerateAggremet: true,
                 });
                 setSignatureData(signDummyData);
               }
             });
           }
         })
         .catch((error) => {
           toast.error("Failed to generate agreement", {
             position: toast.POSITION.TOP_RIGHT,
           });
         });
     } else {
       toast.error("Please Assign Primary Client for this job", {
         position: toast.POSITION.TOP_RIGHT,
       });
       updateState({
         ...iState,
         isGenerateAggremet: false,
       });
     }
   }
 }, [aggrementView, primary]);

 const handleAgreementViewClose = () => {
   setAggrementView(false);
   updateState({ ...iState, isGenerateAggremet: true });
 };
 const handleAgreementViewDocumentClose = () => {
   setAggrementViewDocument(false);
 };

 useEffect(() => {
   if (aggrementViewDocument) {
     dispatch(
       changeOrderDetails(
         iBuildLocalData?.user?.CompanyId,
         state?.projectId,
         state?.changeOrderId,
         iBuildLocalData?.user?.token
       )
     ).then((resp) => {
       if (resp.status === 200) {
         const signDummyData =
           resp?.data?.data?.changeOrderAgreementSignatures?.at(-1);
         updateState({ ...iState, changeOrderData: resp?.data?.data });
         setSignatureData(signDummyData);
       }
     });
   }
 }, [aggrementViewDocument]);

 const createAboutMarkup = () => {
   return { __html: iBuildLocalData?.companies?.[0]?.letterHeader };
 };

 const handleSelectionHistoryModalClose = () => {
   updateState((prev) => ({
     ...prev,
     SelectionHistoryModal: false,
   }));
 };
 const handleSelectionHistoryModalShow = (item) => {
   updateState((prev) => ({
     ...prev,
     SelectionHistoryModal: true,
   }));
 };

 useEffect(() => {
   if (SelectionHistoryModal)
     dispatch(historyChangeOrders(state?.projectId, state?.changeOrderId));
 }, [SelectionHistoryModal]);

 const taxTotal =
   itemList?.data?.reduce(
     (accumulator, item) => accumulator + item.poLineTotal,
     0
   ) || 0;

 const handleSendForClient = async () => {
   let dataa = {
     changeOrderId: state?.changeOrderId,
     clientIds: primaryList?.clientsDetails?.map((data) => data.id) || [],
   };

   try {
     const res = await dispatch(sendEmailAgreement(state?.projectId, dataa));

     if (res.status === 200) {
       toast.success(res.data?.message, {
         position: toast.POSITION.TOP_RIGHT,
         autoClose: 1000,
       });
     } else {
       toast.error(res.data?.message, {
         position: toast.POSITION.TOP_RIGHT,
       });
     }
   } catch (error) {
     toast.error("An error occurred", {
       position: toast.POSITION.TOP_RIGHT,
     });
   }
 };

 const handleManageSignatueShow = () => {
   updateState({
     ...iState,
     ManageSignatueModal: true,
     signature: "",
   });
 };
 const handleManageSignatueClose = () => {
   updateState({
     ...iState,
     ManageSignatueModal: false,
     signature: "",
   });
 };

 const addSignatureFun = () => {
   const signatureBlob = dataURLtoBlob(signature);

   const convertToValidFormat = (blob, fileName) => {
     const fileExtension = fileName.split(".").pop().toLowerCase();
     if (
       !["jpg", "jpeg", "png", "gif", "pdf", "bmp", "webp", "svg+xml"].includes(
         fileExtension
       )
     ) {
       const convertedBlob = convertToPNG(blob); // Ensure convertToPNG is defined
       return convertedBlob;
     }
     return blob;
   };

   const validSignatureBlob = convertToValidFormat(
     signatureBlob,
     "signature.png"
   );

   const formData = new FormData();
   formData.append("files", validSignatureBlob, "signature.png");
   formData.append("name", primary?.legalName);
   formData.append("designation", "Design Manager");
   formData.append("changeOrderId", state?.changeOrderId);

   dispatch(
     addSignatureInAgreements(
       iBuildLocalData?.user?.CompanyId,
       state?.projectId,
       formData,
       iBuildLocalData?.user?.token
     )
   )
     .then((res) => {
       if (res.status === 200) {
         toast.success(res.data.message, {
           position: toast.POSITION.TOP_RIGHT,
           autoClose: 1000,
         });
         updateState({
           ...iState,
           ManageSignatueModal: false,
           signature,
         });
       } else {
         toast.error("Failed to upload signature", {
           position: toast.POSITION.TOP_RIGHT,
         });
         updateState({ ...iState, ManageSignatueModal: true });
       }
     })
     .catch((error) => {
       toast.error(error.message, {
         position: toast.POSITION.TOP_RIGHT,
       });
       updateState({ ...iState, ManageSignatueModal: true });
     });
 };

 const clearSignature = () => {
   signCanvas.current.clear();
 };
 console.log({state});
  return (
    <div>
      <Header />
      <SideNav />
      <>
        <div className="WrapperArea">
          <div className="WrapperBox">
            <div className="TitleBox">
              <h4 className="Title">Change Order #{state.id}</h4>
              {/* <div className="TitleBoxProjectHeading">
                <h6>
                  <span>Status:</span>&nbsp;Purchase Orders Created
                </h6>
                <h6>
                  <span>Authorization Status:</span>&nbsp;In progess
                </h6>
              </div> */}
              <div className="TitleBox2 TitleBoxRight">
                <div>
                  <h6>{state?.jobNumber}</h6>
                  <span>{state?.jobType}</span>
                </div>
              </div>
            </div>
            <div className="WarrantyDashboardForm mb-4">
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>
                          Description <span className="Required">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          rows={3}
                          placeholder="Write here ....."
                          name="description"
                          value={description??"N/A"}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  {/* <div className="form-group">
                    <div className="CommonModalArea">
                      <h5>Supporting Document</h5>
                      <div className="CommonModalBox">
                        <h5
                          className="CursorPointer"
                          data-toggle="modal"
                          data-target="#AddNewSupportingDocs2"
                        >
                          <figure>
                            <img src="images/document-support.png" />
                          </figure>
                          Supporting Document
                        </h5>
                        <span
                          className="Count CursorPointer"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList"
                        >
                          3
                        </span>
                      </div>
                    </div>
                  </div> */}
                  <div className="form-group">
                    <div className="CommonModalArea">
                      <h5>Add Notes</h5>
                      <div className="CommonModalBox">
                        <figure>
                          <img src="images/document-support.png" />
                        </figure>
                        <span
                          className="Count CursorPointer"
                          data-toggle="modal"
                          data-target="#NotesList"
                        >
                          3
                        </span>
                        <h5
                          className="CursorPointer"
                          onClick={handleAddNotesShow}
                        >
                          Add Notes
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="WarrantyDashboardForm">
              <h4>Client Details</h4>
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Possession Date</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Auto-filled by System"
                          name="possessionDate"
                          value={moment(primary?.createdAt).format(
                            "YYYY-MM-DD"
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Request Date</label>
                        <input
                          type="date"
                          className="form-control"
                          name="requestDate"
                          placeholder="Auto-filled by System"
                          value={moment(requestDate).format(
                            "YYYY-MM-DD"
                          )}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Client Full Legal Name</label>
                        <input
                          type="text"
                          className="form-control"
                          // placeholder="Auto-filled by System"
                          value={primary?.legalName}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Phone Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Auto-filled by System"
                          value={primary?.primaryPhoneNum ?? "N/A"}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Primary Email</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Auto-filled by System"
                          value={primary?.email  ?? "N/A"}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>
                          Job Address{" "}
                          <img src="images/location.png" className="ml-2" />
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Auto-filled by System"
                          value={primary?.streetAddress  ?? "N/A"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="Profile_ontario mb-5">
                    <div className="Profile_ontario_box">
                      <h6>Profile:</h6>
                      <p>{state?.TaxProfile?.name ?? "N/A"}</p>
                    </div>
                      <div className="Profile_ontario_box flex-column"
                      
                      >
                      {state?.TaxProfile?.taxRows?.map((data, i) => {
                        return (
                          <>
                            <h6>{data.taxName} : </h6> 
                            <p>{data.taxPercent}%</p><br></br>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="form-group mb-5">
                    <div className="CommonModalArea">
                      <h5>Adjust Change Order</h5>
                      <div className="CommonModalBox">
                        <h5
                          className="CursorPointer"
                          onClick={() => handleSelectItemBookPriceModalShow()}
                        >
                          <figure>
                            <img src="images/adjust_change_order.png" />
                          </figure>
                          Adjust Change Order
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="CommonModalArea">
                      <h5>Add Items from Pricebook</h5>
                      <div className="CommonModalBox">
                        <h5
                          className="CursorPointer"
                          onClick={() => handleSelectItemBookPriceModalShow()}
                        >
                          <figure>
                            <img src="images/add_items_from_pricebook.png" />
                          </figure>
                          Add Items from Pricebook
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="CommonModalArea">
                      <h5>Add Custom Item</h5>
                      <div className="CommonModalBox">
                        <h5
                          className="CursorPointer"
                          onClick={() => handleAddCustomModalShow()}
                        >
                          <figure>
                            <img src="images/add_custom_item.png" />
                          </figure>
                          Add Custom Item
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Tableheader mt-5">
              <h6>Change Items</h6>
            </div>
            <div className="TableList NewTableList TableListHeader TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Measure</th>
                    <th>Per Unit</th>
                    <th>Client Total</th>
                    <th>Po Line Total</th>
                    <th>Markup</th>
                    <th>Accounts</th>
                    <th>Pictures</th>
                    <th>Supporting Doc</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {itemList?.data?.length > 0 &&
                    itemList?.data?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.id}</td>
                          <td>{item.description ?? "N/A"}</td>
                          <td>{item.quantity ?? "0"}</td>
                          <td>{item.measure ?? "N/A"}</td>
                          {/* <td> */}
                          {/* <div className="SelectAnOption">
                            <div className="dropdown">
                              <button
                                className=""
                                type="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Select an option
                                <img src="images/black-down-arrow.png" />
                              </button>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div className="DropdownItemSearch">
                                  <input type="text" placeholder="-Search" />
                                  <img src="images/search.png" />
                                </div>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#CreateMeasure"
                                >
                                  <img src="images/add-plus.png" />
                                  Add New
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  -Warranty
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  - Full list of units loaded from the system
                                </a>
                              </div>
                            </div>
                          </div> */}
                          {/* </td> */}
                          <td>$ {item.perUnit ?? "N/A"}</td>
                          <td>$ {item.clientTotal ?? 0}</td>
                          <td>$ {item.poLineTotal ?? 0}</td>
                          <td>{item.markup}</td>
                          <td>{item.account}</td>
                          {/* <td> */}
                          {/* <div className="SelectAnOption">
                            <div className="dropdown">
                              <button
                                className=""
                                type="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Select an option
                                <img src="images/black-down-arrow.png" />
                              </button>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div className="DropdownItemSearch">
                                  <input type="text" placeholder="-Search" />
                                  <img src="images/search.png" />
                                </div>
                                <div className="TableCheckBoxArea">
                                  <label className="CheckBox">
                                    {" "}
                                    List of Cost Codes loaded by the system
                                    <input type="checkbox" />
                                    <span className="checkmark" />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          {/* </td> */}
                          <td>
                            {/* <figure>
                            <img src= alt="" />
                          </figure> */}
                            {item?.picture ?? "N/A"}
                          </td>
                          {/* <td></td> */}
                          <td>
                            <div className="Actions">
                              <a
                                onClick={() =>
                                  handleUpdatingNewSupportingDocsModalShow(
                                    item.id
                                  )
                                }
                              >
                                <img src="images/icon-63.png" />
                              </a>
                              <a
                                href="javascript:void(0)"
                                data-toggle="modal"
                                data-target="#SupportingDocumentList"
                              >
                                <span className="Count">3</span>
                              </a>
                            </div>
                          </td>
                          <td>
                            <div className="TableThreeDots">
                              <ul className="">
                                <li className="dropdown ">
                                  <a
                                    className=""
                                    id="navbarDropdown"
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/dots.png" />
                                  </a>
                                  <ol className="dropdown-menu dropdown-menuwidth2">
                                    {/* <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/update_price_book.png" />
                                      Update Price Book's Entry
                                    </a>
                                  </li> */}
                                    <li>
                                      <a
                                        onClick={() =>
                                          handleDeleteItem(item.id)
                                        }
                                      >
                                        <img src="images/dropdown-delete.png" />
                                        Remove item
                                      </a>
                                    </li>
                                  </ol>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })}

                  {/* <tr>
                    <td colSpan={12}>
                      <div className="TableButtons">
                        <button className="BrandBtn mr-2">Add Item</button>
                        <button className="BrandBtn">Clear Table</button>
                      </div>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
            <div className="ClientContactArea mt-5">
              <div
                className="ClientContactTitle"
                onClick={() => handleDisplayShow()}
              >
                <h4>Client Contact</h4>
                <div className="DiscussionChat">
                  <img src="images/down.png" className="DiscussionIcon" />
                </div>
              </div>
              <div
                className="ClientContactHideArea"
                style={{
                  display: clientContractShow ? "block" : "none",
                  color: "red",
                }}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="UrgencyArea mb-5">
                          <div className="form-group">
                            <label>Builder Signee</label>
                            <div className="Categories Categories1">
                              <p className="ml-0">
                                {iBuildLocalData?.user?.id}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 ml-auto">
                        <div className="FolderForm">
                          <div className="form-group">
                            <h6>Number of Signees</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Insert Number of Signees"
                              name="numberOfSignee"
                              value={numberOfSignee}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-5">
                        <div className="UrgencyArea">
                          <div className="form-group">
                            <label>Primary Client Signee</label>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                value={primary?.legalName}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 ml-auto">
                        <div className="UrgencyArea">
                          <div className="form-group">
                            <label>Additional Client Signee</label>
                            <div className="Categories Categories1">
                              <select
                                name="additionalSignee"
                                value={additionalSignee}
                                style={{
                                  display: "inline",
                                  padding: " 10px 15px",
                                  width: "200px",
                                  borderRadius: "10px",
                                  marginTop: "10px",
                                }}
                                onChange={handleInputChange}
                              >
                                <option value="">Select</option>
                                {secondaryList?.clientsDetails?.length > 0 &&
                                  secondaryList?.clientsDetails?.map(
                                    (item, i) => {
                                      return (
                                        <option value={item.id}>
                                          {item.legalName}
                                        </option>
                                      );
                                    }
                                  )}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="FolderForm">
                      <div className="form-group">
                        <h6>Request Adjustments Function</h6>
                        <label className="CheckBox">
                          <input
                            type="checkbox"
                            name="requestAdjustment"
                            checked={requestAdjustment}
                            onChange={(e) => handleCheck(e)}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {!isGenerateAggremet && (
                  <div className="Button my-4">
                    <button
                      className="Create"
                      onClick={handleAgreementViewShow}
                    >
                      Generate/Update Agreement
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="ClientContactArea">
              <div
                className="ClientContactTitle"
                onClick={() => handleAgreementDocumentShow()}
              >
                <h4>Agreement Document</h4>
                <div className="DiscussionChat">
                  <img src="images/down.png" className="DiscussionIcon" />
                </div>
              </div>
              <div
                className="ClientContactHideArea"
                style={{
                  display: agreementDocumentShow ? "block" : "none",
                  color: "red",
                }}
              >
                <div className="FolderForm">
                  <div className="row">
                    <div className="form-group col-md-6 mb-4">
                      <div className="CommonModalArea">
                        <h5>Agreement Document</h5>
                        <div
                          className="CommonModalBox CursorPointer"
                          onClick={() => setAggrementViewDocument(true)}
                        >
                          <figure className="mb-0">
                            <img src="images/agreement_document.png" />
                          </figure>
                          <span className="Count CursorPointer">3</span>
                          <h5 className="ml-2"> Agreement Document</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="ModalFilterAreaBox">
                        <h4 className="mb-4">
                          Client Acknowledgement of Change Contract
                        </h4>
                        <div className="RadioArea">
                          {/* <div class="form-group">
                          <label class="Radio">Electronic
                            <input type="radio" name="Cuisine">
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="form-group ml-5">
                          <label class="Radio">Hardcopy
                            <input type="radio" name="Cuisine">
                            <span class="checkmark"></span>
                          </label>
                        </div> */}
                          <div className="form-group">
                            <label className="Radio">
                              Electronic
                              <input
                                type="radio"
                                name="Cuisine"
                                defaultValue="text"
                                defaultChecked=""
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="form-group ml-5">
                            <label className="Radio">
                              Hardcopy
                              <input
                                type="radio"
                                name="Cuisine"
                                defaultValue="file"
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* <div class="text RadioText">
                  <div class="form-group">
                    <h6>Message (Max 1000 chars limit)</h6>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Write here ....."
                    />
                    <small class="form-text text-right"
                      >985 characters remaining</small
                    >
                  </div>
                </div> */}
                      <div className="file RadioText">
                        <div className="row">
                          <div className="form-group col-md-6">
                            <div className="CommonModalArea">
                              <h5>Download Hardcopy Agreement</h5>
                              <div className="CommonModalBox">
                                <figure className="mb-0">
                                  <img src="images/agreement_document.png" />
                                </figure>
                                <h5 className="ml-2">
                                  {" "}
                                  Download Hardcopy Agreement
                                </h5>
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <div className="CommonModalArea">
                              <h5>Upload Signed Agreement</h5>
                              <div className="CommonModalBox">
                                <figure className="mb-0">
                                  <img src="images/agreement_document.png" />
                                </figure>
                                <h5 className="ml-2">
                                  {" "}
                                  Download Hardcopy Agreement
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mt-4">
                        <h6>Read-Only Carbon Copies</h6>
                        <div className="ReadOnlyCarbonCopies">
                          <h6 className="mb-0">1</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Auto-filled by System"
                          />
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Auto-filled by System"
                          />
                          <span className="cross">
                            <img src="images/cancel_icon.png" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-content-between">
                    <button className="AddCopy">Add Copy</button>
                    <button
                      className="AddCopy ml-4"
                      onClick={handleSelectionHistoryModalShow}
                    >
                      {/* <img src="images/history_icon.png" /> */}
                      Agreement Document History
                    </button>
                  </div>
                </div>
              </div>
              <div className="SaveDraftBtn d-flex justify-content-between mt-5">
                <button className="BrandBtn">Cancel</button>
                <div className="d-flex ">
                  <button className="BrandBtnGreen" onClick={()=>handleSaveDraft("draft")}>
                    Save draft
                  </button>
                  <div className="dropdown ml-3">
                    <button
                      className="BrandBtnGreen"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Save and <img src="images/down-arrow-white.png" />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a className="dropdown-item" onClick={()=>handleSaveDraft("save")}>
                        Save and Print
                      </a>
                      <a
                        className="dropdown-item"
                       onClick={()=>handleSaveDraft("save")}
                      >
                        Save and E-Sign
                      </a>
                      <a className="dropdown-item" onClick={()=>handleSaveDraft("save")}>
                        Save and Authorize
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="DiscussionArea">
              <div
                class="DiscussionTitle"
                onClick={() => setShowDiscussion(!showDiscussion)}
              >
                <h4>Discussion</h4>
                <div class="DiscussionChat">
                  <img
                    src={require("../assets/images/down.png")}
                    class="DiscussionIcon"
                  />
                  <a>
                    <img src={require("../assets/images/pdf2.png")} />
                  </a>
                  <a onClick={() => handleDiscussionSettingsModalShow()}>
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
              <div
                class="DiscussionHideArea"
                style={{
                  display: showDiscussion ? "block" : "none",
                  color: "red",
                }}
              >
                <div class="DiscussionBox">
                  <span>FM</span>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      value={discussionState?.message}
                      onChange={(e) =>
                        updateDiscussionState({
                          ...discussionState,
                          message: e.target.value,
                        })
                      }
                      placeholder="Add a Comment ....."
                    />
                    {discussionState.discussionError &&
                      !discussionState.message && (
                        <p style={{ color: "red" }}>
                          {discussionState.discussionError}
                        </p>
                      )}

                    <div class="DiscussionOverlay">
                      <a
                        onClick={() =>
                          updateDiscussionState({
                            ...discussionState,
                            message: "",
                          })
                        }
                        class="CancelChat"
                      >
                        <img
                          src={require("../assets/images/plus-cancel1.png")}
                        />
                      </a>
                      <a onClick={handleAddDiscussion} class="SendChat">
                        <img
                          src={require("../assets/images/forwardbutton.png")}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                {discussionList?.map((item, i) => (
                  <>
                    <div class="DiscussionBox">
                      <span>CO</span>
                      <div class="DiscussionRight">
                        <h6>{item?.message}</h6>
                        <small>{moment(item.updatedAt).format("lll")}</small>
                      </div>
                    </div>
                    <div class="ReplyArea">
                      <a
                        onClick={(e) => {
                          updateReplyField({ flag: true, index: i });
                          updateReplyState({ ...replyState, message: "" });
                        }}
                      >
                        Reply
                      </a>
                      {item?.ReplyDiscussions?.map((ele) => (
                        <div class="DiscussionBox">
                          <span>RE</span>
                          <div class="DiscussionRight">
                            <h6>{ele?.message}</h6>
                            <small>{moment(ele.updatedAt).format("lll")}</small>
                          </div>
                        </div>
                      ))}

                      {replyField.index == i && (
                        <div class="DiscussionBox w-100">
                          <span>FM{item?.id}</span>
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              value={replyState?.message}
                              onChange={(e) =>
                                updateReplyState({
                                  ...replyState,
                                  message: e.target.value,
                                  messageId: item?.id,
                                  jobId: item?.jobId,
                                })
                              }
                              placeholder="Add reply ....."
                            />
                            {discussionState.discussionError &&
                              !discussionState.message && (
                                <p style={{ color: "red" }}>
                                  {discussionState.discussionError}
                                </p>
                              )}

                            <div class="DiscussionOverlay">
                              <a
                                onClick={() =>
                                  updateReplyState({
                                    ...replyState,
                                    message: "",
                                  })
                                }
                                class="CancelChat"
                              >
                                <img
                                  src={require("../assets/images/plus-cancel1.png")}
                                />
                              </a>
                              <a onClick={handleAddReply} class="SendChat">
                                <img
                                  src={require("../assets/images/forwardbutton.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={AddCustomModal}
          onHide={handleAddCustomModalClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleAddCustomModalClose}>
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Add Custom Item</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="form-group">
                      <h6>Name</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert new entry name"
                        name="name"
                        value={name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <h6>Category</h6>
                      <div className="Categories Categories1">
                        <div className="Categories_all Categories_all1">
                          <span>Select a category</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>
                            <input
                              type="text"
                              placeholder="Search"
                              name="search"
                              value={search}
                              onChange={handleInputChange}
                            />
                            <span className="Search">
                              <img src="images/search.png" />
                            </span>
                          </li>
                          {allCategoryList?.categoryDetails?.length > 0 &&
                            allCategoryList.categoryDetails
                              .filter((data) =>
                                search.toLowerCase() === ""
                                  ? true
                                  : data.name
                                      .toLowerCase()
                                      .includes(search.toLowerCase())
                              )
                              .map((item, i) => (
                                <li
                                  // value={item.id}
                                  key={i}
                                  onClick={() =>
                                    handleCategoryItemClick(item.id)
                                  }
                                  style={{
                                    backgroundColor:
                                      CategoryId === item.id
                                        ? "#e71e09"
                                        : "transparent",
                                  }}
                                >
                                  {item.name}
                                </li>
                              ))}
                        </ul>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>Type</h6>
                      <label className="Radio margin-bottom">
                        Assembly entry
                        <input
                          type="radio"
                          name="entryType"
                          value="assembly"
                          checked={entryType === "assembly"}
                          onChange={() => handleRadioChange("assembly")}
                        />
                        <span className="checkmark" />
                      </label>
                      <label className="Radio">
                        Standalone entry
                        <input
                          type="radio"
                          name="entryType"
                          value="standalone"
                          checked={entryType === "standalone"}
                          onChange={() => handleRadioChange("standalone")}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>

                    <div className="form-group">
                      <h6>Assembly</h6>
                      <div className="Categories Categories2">
                        <div className="Categories_all Categories_all2">
                          <span>Select an assembly</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>
                            <input
                              type="text"
                              placeholder="Search"
                              name="assemblySearch"
                              value={assemblySearch}
                              onChange={handleInputChange}
                            />
                            <span className="Search">
                              <img src="images/search.png" />
                            </span>
                          </li>
                          {allAssemblyList?.assemblyDetails?.length > 0 &&
                            allAssemblyList.assemblyDetails
                              .filter((data) =>
                                assemblySearch.toLowerCase() === ""
                                  ? true
                                  : data.name
                                      .toLowerCase()
                                      .includes(assemblySearch.toLowerCase())
                              )
                              .map((item, i) => (
                                <li
                                  value={item.id}
                                  key={i}
                                  onClick={() =>
                                    handleAsssemblyItemClick(item.id)
                                  }
                                  style={{
                                    backgroundColor:
                                      AssemblyId === item.id
                                        ? "#e71e09"
                                        : "transparent",
                                  }}
                                >
                                  {item.name}
                                </li>
                              ))}
                        </ul>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>Measure (unit)</h6>
                      <div className="Categories Categories3">
                        <div className="Categories_all Categories_all3">
                          <span>Select measure type</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>
                            <input
                              type="text"
                              placeholder="Search"
                              name="measuresSearch"
                              value={measuresSearch}
                              onChange={handleInputChange}
                            />
                            <span className="Search">
                              <img src="images/search.png" />
                            </span>
                          </li>
                          {allMeasureList?.measures?.length > 0 &&
                            allMeasureList.measures
                              .filter((data) =>
                                measuresSearch.toLowerCase() === ""
                                  ? true
                                  : data.name
                                      .toLowerCase()
                                      .includes(measuresSearch.toLowerCase())
                              )
                              .map((item, i) => (
                                <li
                                  value={item.id}
                                  key={i}
                                  onClick={() => handleMeasureItemClick(item)}
                                  style={{
                                    backgroundColor:
                                      MeasureId === item.id
                                        ? "#e71e09"
                                        : "transparent",
                                  }}
                                >
                                  {item.name}
                                </li>
                              ))}
                        </ul>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>Unit Cost</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert Unit Cost in $"
                        name="unitCost"
                        value={unitCost}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <h6>Markup %</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert mark up"
                        name="markup"
                        value={markup}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="CommonModalAreaMain">
                      <div className="CommonModalArea">
                        <div className="form-group">
                          <h5>Images</h5>
                          <div className="CommonModalBox">
                            <h5
                              className="CursorPointer FolderPermissionId"
                              onClick={handleUploadImagesModalShow}
                            >
                              <figure>
                                <img src="images/image-icon.png" />
                              </figure>
                              Images
                            </h5>
                            <span
                              className="Count CursorPointer FolderPermissionId"
                              data-toggle="modal"
                              data-target="#CarouselInModal"
                              data-dismiss="modal"
                            >
                              3
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="CommonModalArea">
                        <div className="form-group">
                          <h5>Select Accounts</h5>
                          <div className="CommonModalBox">
                            <h5
                              className="CursorPointer FolderPermissionId "
                              onClick={handleSelectAccountsModalShow}
                            >
                              <figure>
                                <img src="images/select-account.png" />
                              </figure>{" "}
                              Select Account
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="FilesAdded">
                      <label>23-0101 Appliances</label>
                      <span>
                        <img src="images/Cross.png" className="Cross" />
                      </span>
                    </div>
                    <div className="form-group">
                      <label className="CheckBox">
                        {" "}
                        Create New Entry in the Price Book
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button
                        className="Create ClosePermissionId"
                        onClick={handleCreateCustomItem}
                      >
                        Add Item to Change Order
                      </button>
                      <button
                        className="Outline ClosePermissionId"
                        onClick={handleAddCustomModalClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={UploadImagesModal}
          onHide={handleUploadImagesModalClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleUploadImagesModalClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Upload Images</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="form-group">
                      <h6>Select Files</h6>
                      <div className="Upload">
                        <span>-Drag and Drop Files or click to select</span>
                        <input
                          type="file"
                          multiple
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                    {/* <div className="Button mb-4">
                      <button className="Create">Add</button>
                    </div> */}
                    <div className="form-group FilesAddedGroup">
                      <h6>Images</h6>
                      {attachments?.length > 0 &&
                        attachments?.map((item, ind) => {
                          return (
                            <div className="FilesAdded">
                              <label>{item}</label>
                              <span>
                                <img src="images/fileview.png" />
                              </span>
                              <span
                                className="cross"
                                onClick={() => handleFileRemove(item.ind)}
                              >
                                <img src="images/Cross.png" />
                              </span>
                            </div>
                          );
                        })}
                    </div>
                    {/* <div className="Button mb-5">
                      <button className="Create">View</button>
                    </div> */}
                    <div className="Button d-flex justify-content-between">
                      <button
                        className="Create"
                        onClick={handleUploadImagesModalClose}
                      >
                        Save
                      </button>
                      <button
                        className="Outline"
                        onClick={handleUploadImagesModalClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={SelectAccountsModal}
          onHide={handleSelectAccountsModalClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleSelectAccountsModalClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Select Accounts</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="form-group">
                      <h6>Cost Category</h6>
                      <div className="Categories Categories1">
                        <div className="Categories_all Categories_all1">
                          <span>Select a cost Category</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>
                            <input
                              type="text"
                              placeholder="Search"
                              name="costCategorySearch"
                              value={costCategorySearch}
                              onChange={handleInputChange}
                            />
                            <span className="Search">
                              <img src="images/search.png" />
                            </span>
                          </li>
                          {costCategoryList?.data?.length > 0 &&
                            costCategoryList?.data
                              ?.filter((data) =>
                                costCategorySearch.toLowerCase() === ""
                                  ? true
                                  : data.name
                                      .toLowerCase()
                                      .includes(
                                        costCategorySearch.toLowerCase()
                                      )
                              )
                              .map((item, i) => (
                                <li
                                  value={item.id}
                                  key={i}
                                  onClick={() =>
                                    handleCostCategoryItemClick(item.name)
                                  }
                                  style={{
                                    backgroundColor:
                                      costArea === item.name
                                        ? "#e71e09"
                                        : "transparent",
                                  }}
                                >
                                  {item.name}
                                </li>
                              ))}
                        </ul>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>Cost Code</h6>
                      <div className="Categories Categories2">
                        <div className="Categories_all Categories_all2">
                          <span>Select one or more cost codes</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>
                            <input
                              type="text"
                              placeholder="Search"
                              name="costCodeSearch"
                              value={costCodeSearch}
                              onChange={handleInputChange}
                            />
                            <span className="Search">
                              <img src="images/search.png" />
                            </span>
                          </li>
                          {costCodeList?.data?.length > 0 &&
                            costCodeList?.data
                              ?.filter((data) =>
                                costCodeSearch.toLowerCase() === ""
                                  ? true
                                  : data.name
                                      .toLowerCase()
                                      .includes(costCodeSearch.toLowerCase())
                              )
                              .map((item, i) => (
                                <li key={i}>
                                  <label className="CheckBox">
                                    {item.name}
                                    <input
                                      type="checkbox"
                                      checked={selectedCostCodes.includes(item)}
                                      onChange={() =>
                                        handleCheckboxChange(item)
                                      }
                                    />
                                    <span className="checkmark" />
                                  </label>
                                </li>
                              ))}
                        </ul>
                      </div>
                    </div>
                    <div className="Button mb-5">
                      {/* <button className="Create" data-dismiss="modal">
                        Add
                      </button> */}
                    </div>
                    {selectedCostCodes?.length > 0 &&
                      selectedCostCodes?.map((data, ind) => (
                        <div className="form-group">
                          <h6>Selected Cost Codes</h6>
                          <div className="FilesAdded">
                            <label>{data.name}</label>
                            <span
                              onClick={() => handleselectedCostCodesRemove(ind)}
                            >
                              <img src="images/Cross.png" />
                            </span>
                          </div>
                        </div>
                      ))}

                    <div className="Button d-flex justify-content-between">
                      <button
                        className="Create"
                        onClick={handleSelectAccountsModalClose}
                      >
                        Confirm Selection
                      </button>
                      <button
                        className="Outline"
                        onClick={handleSelectAccountsModalClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          className="ModalBox fade SmallModal"
          show={AddNewSupportingDocsModal}
          onHide={handleAddNewSupportingDocsModalClose}
        >
          <Modal.Body>
            <div className="Category">
              <a
                className="CloseModal"
                onClick={handleAddNewSupportingDocsModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4> New Supporting Docs</h4>
                </div>
                <div className="JobTitleArea">
                  <h3>Supporting Documents #</h3>
                  <div className="JobBox">
                    <h6>
                      <span>Job:</span>
                      {state?.jobNumber}
                    </h6>
                  </div>
                  <div className="JobBox">
                    {/* <h6>
                      <span>Origin</span> #185
                    </h6> */}
                  </div>
                  <div className="JobBox">
                    {/* <h6>
                      <span>Item:</span> 
                    </h6> */}
                  </div>
                </div>
                <div className="form-group">
                  <h6>Select Files</h6>
                  <div className="Upload">
                    <span>-Drag and Drop Files or click to select</span>
                    <input
                      type="file"
                      id="docsInput"
                      onChange={(e) => handleFileChanges(e, "docsUpdate")}
                    />
                  </div>
                </div>
                <div className="FilesAddedTitle">
                  <h4>Files</h4>
                </div>
                {supportingDocFiless && (
                  <div className="FilesAdded">
                    <label>{supportingDocFiless?.name}</label>
                    <span>
                      <img src="images/fileview.png" />
                    </span>
                    <span onClick={handleRemoveDocsFile}>
                      <img src="images/Cross.png" className="cross" />
                    </span>
                  </div>
                )}

                <div className="Button mt-3">
                  <button
                    className="Create"
                    onClick={handleAddNewSupportingDocs}
                  >
                    Add Documents
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showSelectItemBookPriceModal}
          onHide={() => handleSelectItemBookPriceModalClose()}
          className="ModalBox modal fade LargeModal show"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={() => handleSelectItemBookPriceModalClose()}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Select Items from Price Book</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="form-row">
                      <div className="form-group col-md-5">
                        <h6>Category</h6>
                        <div className="Categories Categories1">
                          <div
                            className="Categories_all Categories_all1"
                            onClick={handleCategoryToggle}
                          >
                            <span>Select a category</span>
                            <img src="images/down.png" alt="Dropdown" />
                          </div>
                          {categoryToggle && (
                            <ul>
                              <li>
                                <input
                                  type="text"
                                  placeholder="-Search"
                                  name="categorySearch"
                                  value={categorySearch}
                                  onChange={handleInputChange}
                                />
                                <span className="Search">
                                  <img src="images/search.png" alt="Search" />
                                </span>
                              </li>
                              {priceBookCategoryList?.categoryDetails?.length >
                                0 &&
                                priceBookCategoryList.categoryDetails
                                  .filter((data) =>
                                    categorySearch.trim() === ""
                                      ? true
                                      : data.name
                                          .toLowerCase()
                                          .includes(
                                            categorySearch.trim().toLowerCase()
                                          )
                                  )
                                  .map((item) => (
                                    <li
                                      key={item.id}
                                      onClick={() =>
                                        handleCategoryClick(item.id)
                                      }
                                      style={{
                                        backgroundColor:
                                          designCategoryId === item.id
                                            ? "#e71e09"
                                            : "transparent",
                                        cursor: "pointer", // Ensure the cursor changes to pointer on hover
                                        padding: "8px", // Example: Add padding for better UI
                                        marginBottom: "5px", // Example: Add margin for spacing between items
                                      }}
                                    >
                                      {item.name}
                                    </li>
                                  ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-5">
                        <h6>Select Items</h6>
                        <div className="ModalInputSearch">
                          <input
                            type="text"
                            placeholder="Search entry or Assembly name"
                            name="itemSearch"
                            value={itemSearch}
                            onChange={handleInputChange}
                          />
                          <span>
                            <img src="images/search.png" alt="Search" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="BaselineTable BaselineTableModal">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Assembly</th>
                            <th>Measure</th>
                            <th />
                            <th>Markup</th>
                            <th />
                            <th>Select</th>
                          </tr>
                        </thead>
                        <tbody>
                          {assembleList?.categoryDetails?.length > 0 ? (
                            assembleList.categoryDetails
                              .filter((data) =>
                                (
                                  itemSearch && typeof itemSearch === "string"
                                    ? itemSearch.trim() === ""
                                    : true
                                )
                                  ? true
                                  : data.name
                                      .toLowerCase()
                                      .includes(
                                        (itemSearch &&
                                        typeof itemSearch === "string"
                                          ? itemSearch.trim()
                                          : ""
                                        ).toLowerCase()
                                      )
                              )
                              .map((item, index) => (
                                <React.Fragment key={item.id}>
                                  <tr className="BaselineShowBorder">
                                    <td>
                                      <div className="BigBox BaselineShow">
                                        <img
                                          src="images/rightarrow2.png"
                                          alt="Arrow"
                                        />
                                        {item.name}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="SmallBox BaselineShow">
                                        {item.measureId}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="SmallBox BaselineShow" />
                                    </td>
                                    <td>
                                      <div className="SmallBox BaselineShow">
                                        {item.defaultMarkup}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="SmallBox BaselineShow" />
                                    </td>
                                    <td>
                                      {/* Render your checkbox or selection UI here */}
                                    </td>
                                  </tr>
                                  <tr className="">
                                    <td colSpan={6} className="padding_none">
                                      <table>
                                        <tbody>
                                          <tr>
                                            <th>Assembly</th>
                                            <th>Measure</th>
                                            <th>Unit Cost</th>
                                            <th>Markup</th>
                                            <th>Account (s)</th>
                                            <th>Select</th>
                                          </tr>
                                          {item?.Entries?.length > 0 &&
                                            item.Entries.map((data) => (
                                              <tr key={data.id}>
                                                <td>
                                                  <div className="BigBox">
                                                    {data.name}
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="SmallBox">
                                                    {data.measure?.name}
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="SmallBox">
                                                    ${data.unitCost}
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="SmallBox">
                                                    {data.markup}%
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="SmallBox">
                                                    {moment(
                                                      data.createdAt
                                                    ).format("YYYY-MM-DD")}
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="SmallBox">
                                                    <label className="CheckBox">
                                                      <input
                                                        type="checkbox"
                                                        checked={selectedAssemblies.some(
                                                          (assembly) =>
                                                            assembly.entryId ===
                                                            data.id
                                                        )}
                                                        onChange={() =>
                                                          handleAssemblyCheckboxChange(
                                                            data
                                                          )
                                                        }
                                                      />
                                                      <span className="checkmark" />
                                                    </label>
                                                  </div>
                                                </td>
                                              </tr>
                                            ))}
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))
                          ) : (
                            <tr>
                              <td colSpan={6}>No Data Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="ChooseQualityTable">
                      <h6>Choose Quantity for each selected Item</h6>
                      <table>
                        <thead>
                          <tr>
                            <th>Item</th>
                            <th>Measure</th>
                            <th>Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedAssemblies?.length > 0 &&
                            selectedAssemblies.map((data, i) => (
                              <tr key={i}>
                                <td>{data.entryName}</td>
                                <td>{data.measure}</td>
                                <td>
                                  <input
                                    type="number"
                                    min={0}
                                    className="form-control"
                                    name="quantity"
                                    value={data.quantity}
                                    onChange={(e) => handleAssemblyChange(e, i)}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="Button d-flex justify-content-center mt-5">
                      <button
                        className="Create mr-5"
                        onClick={handleAddSelectedItems}
                      >
                        Add Selected Item
                      </button>
                      <button
                        className="Outline ml-5"
                        onClick={() => handleSelectItemBookPriceModalClose()}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          className="ModalBox fade SmallModal"
          show={AddNotesModal}
          onHide={handleAddNotesClose}
        >
          <Modal.Body>
            <div className="Category">
              <a className="CloseModal" onClick={handleAddNotesClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Add New Note</h4>
                </div>
                <h6>Note </h6>
                <div className="JobTitleArea">
                  <div className="JobBox">
                    <h6>
                      <span>Job:</span>
                      {state?.jobNumber}
                    </h6>
                  </div>
                  <div className="JobBox mb-3">
                    {/* <h6>
                      <span>Origin:</span> #185
                    </h6> */}
                  </div>
                  <h6>Type</h6>

                  <div className="FolderForm">
                    <div className="form-group mb-4">
                      <label className="Radio">
                        Text
                        <input
                          type="radio"
                          value="Text"
                          checked={selectedOption === "Text"}
                          onChange={onValueChange}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="Radio">
                        File
                        <input
                          type="radio"
                          value="File"
                          checked={selectedOption === "File"}
                          onChange={onValueChange}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>

                    {textInput == true && (
                      <div className="form-group">
                        <h6>Message (Max 1000 chars limit)</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Write here ....."
                          name="message"
                          value={message}
                          onChange={handleInputChange}
                        />
                        <small className="form-text text-right">
                          {1000 - Number(message.length)} characters remaining
                        </small>
                      </div>
                    )}

                    {fileData == true && (
                      <>
                        <div className="form-group">
                          <h6>Select Files</h6>
                          <div className="Upload">
                            <span>-Drag and Drop Files or click to select</span>
                            <input
                              type="file"
                              id="fileInput"
                              onChange={(e) => handleAddNotesChanges(e)}
                            />
                          </div>
                        </div>
                        {notesFiles && (
                          <div className="FilesAdded">
                            <label>{notesFiles?.name}</label>
                            <span>
                              <img src="images/fileview.png" alt="File view" />
                            </span>
                            <span onClick={handleRemoveClientFile}>
                              <img
                                src="images/Cross.png"
                                className="cross"
                                alt="Cross"
                              />
                            </span>
                          </div>
                        )}
                      </>
                    )}

                    <div className="Button d-flex justify-content-between mt-4">
                      <button className="Create" onClick={handleAddNotes}>
                        Update Note
                      </button>
                      <button className="Outline" onClick={handleAddNotesClose}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          className="ModalBox fade SmallModal"
          show={UpdatingNewSupportingDocsModal}
          onHide={handleUpdatingNewSupportingDocsModalClose}
        >
          <Modal.Body>
            <div className="Category">
              <a
                className="CloseModal"
                onClick={handleUpdatingNewSupportingDocsModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4> Update New Supporting Docs</h4>
                </div>
                <div className="JobTitleArea">
                  <h3>Supporting Documents #</h3>
                  <div className="JobBox">
                    <h6>
                      <span>Job:</span> {state?.jobType}
                    </h6>
                  </div>
                  <div className="JobBox">
                    {/* <h6>
                      <span>Origin</span> #185
                    </h6> */}
                  </div>
                  <div className="JobBox">
                    {/* <h6>
                      <span>Item:</span> #
                    </h6> */}
                  </div>
                </div>
                <div className="form-group">
                  <h6>Select Files</h6>
                  <div className="Upload">
                    <span>-Drag and Drop Files or click to select</span>
                    <input
                      type="file"
                      id="docsInput"
                      onChange={(e) => handleUpdateDocsChanges(e)}
                    />
                  </div>
                </div>
                <div className="FilesAddedTitle">
                  <h4>Files</h4>
                </div>
                {updateFiles && (
                  <div className="FilesAdded">
                    <label>{updateFiles?.name}</label>
                    <span>
                      <img src="images/fileview.png" />
                    </span>
                    <span onClick={handleRemoveDocsFile}>
                      <img src="images/Cross.png" className="cross" />
                    </span>
                  </div>
                )}

                <div className="Button mt-3">
                  <button className="Create" onClick={handleUpdateDocs}>
                    Add Documents
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={aggrementView}
          onHide={handleAgreementViewClose}
          className="ModalBox modal fade LargeModal GreyModalContent show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleAgreementViewClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Agreement View</h4>
              </div>
              <div className="FolderForm">
                <div className="AgreementViewArea">
                  <div className="AgreementViewTop">
                    <div className="AgreementViewTopRight">
                      <p
                        dangerouslySetInnerHTML={createAboutMarkup()}
                        className="editor"
                      ></p>
                    </div>
                  </div>
                  <button>Change Order C2</button>
                  {/* <div className="AgreementViewAdress">
                    <h6>
                      Wednesday, May 24, 2023
                      <br />
                      Fred Obi Felipe Moreira
                      <br />
                      27 Zelda ST
                      <br />
                      Calgary, AB T3M1Y6
                    </h6>
                    <h6>U1-CHI-2B-306</h6>
                  </div> */}
                  <div className="AgreementViewTable">
                    <table>
                      <thead>
                        <tr>
                          <th>Description</th>
                          <th>Quanity</th>
                          <th>Measure</th>
                          <th>Amount</th>
                          <th>Line Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {changeOrderData?.items?.length > 0 &&
                          changeOrderData?.items?.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{data.description ?? "N/A"}</td>
                                <td>{data.quantity ?? "0"}</td>
                                <td>{data.measure ?? "N/A"}</td>
                                <td>$ {data.clientTotal ?? 0}</td>
                                <td>$ {data.poLineTotal ?? 0}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={4}>Total</td>
                          <td>$ {taxTotal ?? 0}</td>
                        </tr>
                        <tr>
                          <td colSpan={4}>After Tax Total</td>
                          <td>$ {taxTotal ?? 0}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <p>
                    Note : The TAX rebate, If Applicable, will be adjusted on
                    the statement of adjusting at closing
                  </p>
                  <div className="ChangeOrderCreatArea">
                    <h5>
                      <span>Change Order Created by :</span>{" "}
                      {primary?.legalName}
                    </h5>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="InputBox">
                              <div className="form-group">
                                <input type="text" />
                                <label>Date</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="InputBox">
                              <div className="form-group">
                                <Button
                                  className="mb-4"
                                  onClick={handleManageSignatueShow}
                                >
                                  signature
                                </Button>
                                {!signature ? (
                                  <img
                                    src={signatureData?.imageUrl}
                                    style={{
                                      border: "1px solid gray",
                                      padding: "5px",
                                      marginBottom: "10px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={signature}
                                    style={{
                                      border: "1px solid gray",
                                      padding: "5px",
                                      marginBottom: "10px",
                                    }}
                                  />
                                )}
                                <label>{primary?.legalName}</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="InputBox">
                              <div className="form-group">
                                <input type="text" />
                                <label>Date</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="InputBox">
                              <div className="form-group">
                                <input type="text" />
                                <label>iBuild Client Signature</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button
                    className="Create FolderPermissionId"
                    onClick={handleAgreementViewClose}
                  >
                    <img src="images/edit_document.png" />
                    Edit Document
                  </button>
                  <button
                    className="Create ClosePermissionId"
                    onClick={handleSendForClient}
                  >
                    {/* <img src="images/history_icon.png" /> */}
                    Send for Client Approval
                  </button>
                  <button
                    className="Outline"
                    onClick={handleAgreementViewClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={aggrementViewDocument}
          onHide={handleAgreementViewDocumentClose}
          className="ModalBox modal fade LargeModal GreyModalContent show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleAgreementViewDocumentClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Agreement View</h4>
              </div>
              <div className="FolderForm">
                <div className="AgreementViewArea">
                  <div className="AgreementViewTop">
                    <div className="AgreementViewTopRight">
                      <p
                        dangerouslySetInnerHTML={createAboutMarkup()}
                        className="editor"
                      ></p>
                    </div>
                  </div>
                  <button>Change Order C2</button>
                  {/* <div className="AgreementViewAdress">
                    <h6>
                      Wednesday, May 24, 2023
                      <br />
                      Fred Obi Felipe Moreira
                      <br />
                      27 Zelda ST
                      <br />
                      Calgary, AB T3M1Y6
                    </h6>
                    <h6>U1-CHI-2B-306</h6>
                  </div> */}
                  <div className="AgreementViewTable">
                    <table>
                      <thead>
                        <tr>
                          <th>Description</th>
                          <th>Quanity</th>
                          <th>Measure</th>
                          <th>Amount</th>
                          <th>Line Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {changeOrderData?.items?.length > 0 &&
                          changeOrderData?.items?.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{data.description ?? "N/A"}</td>
                                <td>{data.quantity ?? "0"}</td>
                                <td>{data.measure ?? "N/A"}</td>
                                <td>$ {data.clientTotal ?? 0}</td>
                                <td>$ {data.poLineTotal ?? 0}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={4}>Total</td>
                          <td>$ {taxTotal ?? 0}</td>
                        </tr>
                        <tr>
                          <td colSpan={4}>After Tax Total</td>
                          <td>$ {taxTotal ?? 0}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <p>
                    Note : The TAX rebate, If Applicable, will be adjusted on
                    the statement of adjusting at closing
                  </p>
                  <div className="ChangeOrderCreatArea">
                    <h5>
                      <span>Change Order Created by :</span>{" "}
                      {primary?.legalName}
                    </h5>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="InputBox">
                              <div className="form-group">
                                <input type="text" />
                                <label>Date</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="InputBox">
                              <div className="form-group">
                                <img
                                  src={signatureData?.imageUrl}
                                  style={{
                                    border: "1px solid gray",
                                    padding: "5px",
                                    marginBottom: "10px",
                                  }}
                                />

                                <label>{primary?.legalName}</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="InputBox">
                              <div className="form-group">
                                <input type="text" />
                                <label>Date</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="InputBox">
                              <div className="form-group">
                                <input type="text" />
                                <label>iBuild Client Signature</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button
                    className="Outline"
                    onClick={handleAgreementViewDocumentClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={SelectionHistoryModal}
          onHide={handleSelectionHistoryModalClose}
          className="ModalBox modal fade MediumModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleSelectionHistoryModalClose}
            >
              ×
            </a>
            <div class="FormArea">
              <div class="ModalTitleBox">
                <h4>Selection History</h4>
              </div>
              <div class="ModalMediumheading">
                <h3>
                  <span>Job:</span>
                  {state?.jobNumber}
                </h3>
                {/* <h3>
                  <span>Selection Type:</span>
                  {selectionType}
                </h3> */}
              </div>

              <div class="TableList CommonHeading TableHeightScroll">
                <table>
                  <thead>
                    <tr>
                      <th>Modified By</th>
                      <th>Created By</th>
                      <th>Date</th>
                      <th>Activity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyChangeOrderList?.data?.length > 0 ? (
                      historyChangeOrderList?.data?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item.modifyBy ?? "N/A"}</td>
                            <td>{item.createdBy ?? "N/A"}</td>
                            <td>
                              {moment(item.createdAt).format("YYYY-MM-DD")}
                            </td>
                            <td>{item.activity ?? "N/A"}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="10" className="text-center">
                          <p>No Data found.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div class="Button mt-5 text-center">
                <button
                  class="Outline"
                  onClick={handleSelectionHistoryModalClose}
                >
                  Close
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={ManageSignatueModal}
          onHide={handleManageSignatueClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <a className="CloseModal" onClick={handleManageSignatueClose}>
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Manage Signatures</h4>
              </div>
              <div className="FolderForm">
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Add new signature</h5>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea border p-2">
                    <h5>Signatures</h5>
                    <div className="CommonModalBox">
                      <SignaturePad
                        ref={signCanvas}
                        penColor="green"
                        canvasProps={{
                          width: 400,
                          height: 300,
                          className: "sigCanvas",
                        }}
                        onEnd={() =>
                          updateState({
                            ...iState,
                            signature: signCanvas?.current
                              ?.getTrimmedCanvas()
                              .toDataURL("image/png"),
                          })
                        }
                      />
                      <a onClick={clearSignature}>
                        <img src="images/circle_minus.png" />
                      </a>
                      {/* <a onClick={saveSignature} href="javascript:void(0);">
                        Save
                      </a> */}
                    </div>

                    {/* <span style={{ color: "red", fontSize: "14px" }}>
                    {errors?.signatureError}
                  </span> */}
                  </div>
                </div>

                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={addSignatureFun}>
                    Save
                  </button>
                  <button
                    className="Outline"
                    onClick={handleManageSignatueClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={DiscussionSettingsModal}
          onHide={handleDiscussionSettingsModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleDiscussionSettingsModalClose}
            >
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Discussion Settings</h4>
              </div>
              <div className="FolderForm">
                <div
                  className="FolderFormCreateArea p-0"
                  style={{ overflow: "initial" }}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="ModalMediumheading">
                        <h3>
                          <span>Linked to: </span>Warranty Request #
                          {state?.item?.id}
                        </h3>
                      </div>
                      <h6>Visible in :</h6>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mt-2 mb-4 ">
                        <label className="switch">
                          Client View
                          <input
                            type="checkbox"
                            checked={
                              visibleInClientView === true ? true : false
                            }
                            onClick={() => handleDiscussionCheckBox("client")}
                          />
                          <span className="slider" />
                        </label>
                      </div>
                      <div className="form-group mb-4 ">
                        <label className="switch">
                          Trade View
                          <input
                            type="checkbox"
                            checked={visibleInTradeView === true ? true : false}
                            onClick={() => handleDiscussionCheckBox("trade")}
                          />
                          <span className="slider" />
                        </label>
                      </div>
                      <div className="form-group mb-4 ">
                        <label className="switch">
                          Investor View
                          <input
                            type="checkbox"
                            checked={
                              visibleInInvestorView === true ? true : false
                            }
                            onChange={() => handleDiscussionCheckBox("invest")}
                          />
                          <span className="slider" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between align-items-center">
                  <button
                    className="Create"
                    onClick={handleDiscussionSettingUpdate}
                  >
                    Save Discussion Settings
                  </button>
                  <button
                    className="Outline"
                    onClick={handleDiscussionSettingsModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    </div>
  );
}

export default ChangeOrderDiscussion