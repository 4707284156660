import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { quicksBookAction } from "../../redux/action/adminNotificationAction";
import { useDispatch } from "react-redux";


const ProjectAsEntityStep4 = ({
    ProjectAsEntityStep4Modal,
    SetProjectAsEntityStep1Modal,
    SetProjectAsEntityStep2Modal,
    SetProjectAsEntityStep3Modal,
    SetProjectAsEntityStep4Modal,
    SetProjectAsEntityStep5Modal,
    SetProjectAsEntityStep6Modal,
    SetProjectAsEntityStep7Modal,
    SetProjectAsEntityStep8Modal,
    setShow,
    iState,
    UpdateState,
  
}) => {
  const handleProjectAsEntityStep4ModalClose = () => {
    setShow(true);
    SetProjectAsEntityStep4Modal(false);
  };
  const handleProjectAsEntityStep4ModalShow = () => {
    SetProjectAsEntityStep4Modal(true);
  };




  const {
    billingOption,
    Subscription,
    products,
    contractCode,
    errorMsg,
  } = iState;

  const dispatch = useDispatch()

  const [index, setIndex] = useState('')


  const handleValidation = () => {
    let formIsValid = true;
    let errorMsg = {};

    if (!billingOption) {
      formIsValid = false;
      errorMsg.billingOption = "* Please select billing option.";
    }
    if (!products) {
      formIsValid = false;
      errorMsg.products = "* Please select products.";
    }


    UpdateState({ ...iState, errorMsg: errorMsg });

    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    UpdateState({
      ...iState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();

    if (formIsValid) {
      SetProjectAsEntityStep4Modal(false);
      SetProjectAsEntityStep5Modal(true);
    }
  };

  
  const handleSelectProducts=(value)=>{
    UpdateState({...iState, products:value})
  }

  const handleSelectBilling=(value)=>{
    UpdateState({...iState, billingOption:value})
  }

  
  useEffect(()=>{if(ProjectAsEntityStep4){
    dispatch(quicksBookAction())


  }

  },[])

  return (
    <>
      <Modal show={ProjectAsEntityStep4Modal} className="ModalBox LargeModal BgClass">
        <div>
          <a
            onClick={handleProjectAsEntityStep4ModalClose}
            class="CloseModal"
            data-toggle="modal"
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Create New Project as an Entity</h4>
            </div>
            <div class="StepBox">
              <ul>
                  <li class="active">
                      <span class="Icon"  onClick={()=> {SetProjectAsEntityStep1Modal(true) 
                      SetProjectAsEntityStep4Modal(false)}}>
                          <img src={require("../../assets/images/step_1.png")} />
                      </span>
                      <span class="Text">Details</span>
                  </li>
                  <li className="active">
                    <span onClick={()=> {SetProjectAsEntityStep2Modal(true) 
                      SetProjectAsEntityStep4Modal(false)}}  class="Icon FolderPermissionId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityAddress" data-dismiss="modal">
                        <img src={require("../../assets/images/step_2.png")} />
                    </span>
                    <span class="Text">Address</span>
                </li>
                  <li className="active">
                    <span onClick={()=> {SetProjectAsEntityStep3Modal(true) 
                      SetProjectAsEntityStep4Modal(false)}} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityType" data-dismiss="modal">
                        <img src={require("../../assets/images/step_8.png")} />
                    </span>
                    <span class="Text">Type</span>
                </li>
                  <li className="active">
                      <span class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntitySubscription" data-dismiss="modal">
                          <img  src={require("../../assets/images/subscription_step.png")}/>
                      </span>
                      <span class="Text">Subscription</span>
                  </li>
                  <li>
                      <span onClick={()=> {SetProjectAsEntityStep5Modal(true) 
                      SetProjectAsEntityStep3Modal(false)}} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityTax" data-dismiss="modal">
                          <img src={require("../../assets/images/step_3.png")} />
                      </span>
                      <span class="Text">Accounting <br /> &amp; Tax</span>
                  </li> 
                  <li>
                    <span onClick={()=> {SetProjectAsEntityStep6Modal(true) 
                      SetProjectAsEntityStep3Modal(false)}} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityNumber" data-dismiss="modal">
                        <img src={require("../../assets/images/step_5.png")} />
                    </span>
                    <span class="Text">Job Number</span>
                </li> 
                <li>
                  <span onClick={()=> {SetProjectAsEntityStep7Modal(true) 
                      SetProjectAsEntityStep3Modal(false)}} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityInitial" data-dismiss="modal">
                      <img  src={require("../../assets/images/initial_settings.png")} />
                  </span>
                  <span class="Text">Initial <br />
                    Settings</span>
              </li> 
              <li>
                <span onClick={()=> {SetProjectAsEntityStep8Modal(true) 
                      SetProjectAsEntityStep3Modal(false)}} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityConfirmation" data-dismiss="modal">
                    <img src={require("../../assets/images/step_7.png")} />
                </span>
                <span class="Text">Confirmation</span>
            </li> 
              </ul>
            </div>
            <div class="FolderForm">
              
            <form>
                <div class="FolderFormCreateArea">
                  <div class="FieldsMandatory">
                    <h6>Fields with <span class="required">*</span> are mandatory</h6>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="">
                        <h6>Product <span class="required">*</span></h6>
                        <div class="row">

                          <div class="form-group col-md-6">
                            <label class="Radio"> Subscription
                              <input type="radio" onChange={()=>handleSelectProducts('Subscription')} checked={products=="Subscription"? true:false}  name="Subscription" />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                          <div class="form-group col-md-6">
                            <label class="Radio"> No Subscription
                              <input type="radio" onChange={()=>handleSelectProducts('No Subscription')} checked={products=="No  Subscription"? true:false} name="Subscription" />
                              <span class="checkmark"></span>
                            </label>
                          </div>

                          {errorMsg.products && !products && (
                        <p style={{ color: "red" }}>{errorMsg.products}</p>
                      )}
                        </div>
                      </div>
                      <div class="">
                        <h6>Billing Option <span class="required">*</span></h6>
                        <div class="row">

                          <div class="form-group col-md-6">
                            <label class="Radio"> Monthly
                              <input type="radio" name="Cuisine" onChange={()=>handleSelectBilling('Monthly')} checked={billingOption=="Monthly"? true:false} />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                          <div class="form-group col-md-6">
                            <label class="Radio"> Yearly
                              <input type="radio" name="Cuisine" onChange={()=>handleSelectBilling('Yearly')} checked={billingOption=="Yearly"? true:false} />
                              <span class="checkmark"></span>
                            </label>
                          </div>

                          {errorMsg.billingOption && !billingOption && (
                        <p style={{ color: "red" }}>{errorMsg.billingOption}</p>
                      )}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <h6>Contract Code</h6>
                        <input onChange={handleChange} value={contractCode} name="contractCode" type="text" class="form-control" placeholder="Insert Promotional Code" />
                      </div>
                    </div>
                  </div>

                  <div class="IbuiltMonthlyYearlyArea">

                    <div class="IbuiltMonthlyYearlyBox">
                      <div class="IbuiltMonthlyYearlyBottom">
                        <h6>Ibuilt</h6>
                      </div>

                    </div>
                    <div class="IbuiltMonthlyYearlyBox">
                      <div class="IbuiltMonthlyYearlyTop">
                        <h6>Plan 1 <img src="images/refresh.png" /></h6>
                        <h6>$ 20 / month</h6>
                      </div>
                      <div class="IbuiltMonthlyYearlyBottom">
                        <h6>Description of the plan 1</h6>
                      <a onClick={()=> setIndex(0)} class="BlueBtn"style={{ "backgroundColor":index==0 ?"green":"#2E75B5"}}>Select</a>
                      </div>

                    </div>
                    <div class="IbuiltMonthlyYearlyBox">
                      <div class="IbuiltMonthlyYearlyTop">
                        <h6>Plan 2 <img src="images/refresh.png" /></h6>
                        <h6>$30 / month</h6>
                      </div>
                      <div class="IbuiltMonthlyYearlyBottom">
                        <h6>Description of the plan 2</h6>
                        <a onClick={()=> setIndex(1)} class="BlueBtn" style={{ "backgroundColor":index==1 ?"green":"#2E75B5"}}>Select</a>
                      </div>

                    </div>
                    <div class="IbuiltMonthlyYearlyBox">
                      <div class="IbuiltMonthlyYearlyTop">
                        <h6>Plan 3 <img src="images/refresh.png" /></h6>
                        <h6>$ 40 / month</h6>
                      </div>
                      <div class="IbuiltMonthlyYearlyBottom">
                        <h6>Description of the plan 3</h6>
                        <a onClick={()=> setIndex(2)} class="BlueBtn" style={{ "backgroundColor":index==2 ?"green":"#2E75B5"}}>Select</a>
                      </div>

                    </div>
                  </div>

                </div>
              
                <div class="Button d-flex justify-content-between align-items-center px-5">
                  <button class="Outline" data-dismiss="modal"onClick={()=> {
                    SetProjectAsEntityStep4Modal(false)
                    SetProjectAsEntityStep3Modal(true)
                  }} >Back</button>
                  <button class="Create FolderPermissionId" data-toggle="modal" 
                    onClick={handleSubmit}
                  >Next</button>
                </div>  
                </form>
              
            </div>

          </div>
        </div>
      </Modal>

   
      
    </>
  );
};

export default ProjectAsEntityStep4;
