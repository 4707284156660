import React from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import "../App.css";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel2";

const Archive = () => {
  const options = {
    items: 3,
    margin:15,
    loop:true,
    nav: true,
    navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
    dots: true,
  };

  return (
    <div>
      <Header />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Archive</h4>
            <a href="#" data-toggle="modal" data-target="#ArchivePermissions">
              <img src="images/Icon-4.png" />
            </a>
          </div>
          <div className="MainTitle2">
            <h2>Apps</h2>
          </div>
          <div className="CommonBoxArea">
            <OwlCarousel  options={options}>
              <div className="item">
                <Link to="/archive-residential">
                  <div className="CommonBox">
                    <div className="CommonBoxHeader">
                      <h6>Residential</h6>
                      <figure>
                        <img src="images/task-square.png" />
                      </figure>
                    </div>
                    <div className="CommonBoxBody CommonBoxBodyArchive">
                      <div className="CommonBoxBodyUser CommonBoxBodyUserArchive">
                        <div className="CommonBoxBodyTitle">
                          <span>MB</span>
                          <div className="">
                            <h6>John Doe</h6>
                          </div>
                        </div>
                      </div>
                      <div className="CommonBoxBodyUser CommonBoxBodyUserArchive">
                        <div className="CommonBoxBodyTitle">
                          <span>MB</span>
                          <div className="">
                            <h6>John Doe</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="CommonBoxFooter">
                      <p>Last update 05/16/2023</p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="/archive-multi-family">
                  <div className="CommonBox">
                    <div className="CommonBoxHeader">
                      <h6>Multi-Family</h6>
                      <figure>
                        <img src="images/task-square.png" />
                      </figure>
                    </div>
                    <div className="CommonBoxBody CommonBoxBodyArchive">
                      <div className="CommonBoxBodyUser CommonBoxBodyUserArchive">
                        <div className="CommonBoxBodyTitle">
                          <span>MB</span>
                          <div className="">
                            <h6>John Doe</h6>
                          </div>
                        </div>
                      </div>
                      <div className="CommonBoxBodyUser CommonBoxBodyUserArchive">
                        <div className="CommonBoxBodyTitle">
                          <span>MB</span>
                          <div className="">
                            <h6>John Doe</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="CommonBoxFooter">
                      <p>Last update 05/16/2023</p>
                    </div>
                  </div>
              </Link>
              </div>

              <div className="item">
                <Link to="/archive-land-development">
                  <div className="CommonBox">
                    <div className="CommonBoxHeader">
                      <h6>Land Development</h6>
                      <figure>
                        <img src="images/task-square.png" />
                      </figure>
                    </div>
                    <div className="CommonBoxBody CommonBoxBodyArchive">
                      <div className="CommonBoxBodyUser CommonBoxBodyUserArchive">
                        <div className="CommonBoxBodyTitle">
                          <span>MB</span>
                          <div className="">
                            <h6>John Doe</h6>
                          </div>
                        </div>
                      </div>
                      <div className="CommonBoxBodyUser CommonBoxBodyUserArchive">
                        <div className="CommonBoxBodyTitle">
                          <span>MB</span>
                          <div className="">
                            <h6>John Doe</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="CommonBoxFooter">
                      <p>Last update 05/16/2023</p>
                    </div>
                  </div>
                </Link>
              </div>
             
            </OwlCarousel>
            <div className="home-carousel owl-carousel owl-theme"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Archive;
