import React, { useEffect, useState } from "react";
import { getLandDevelopmentStep3 } from "../../../redux/action/proformaAction";
import { useDispatch } from "react-redux";

const Preview = ({ handleShowComponent, handleShowComponents }) => {
  const dispatch = useDispatch([]);
  const [landDevelopmentStep3List, setLandDevelopmentStep3List] = useState([]);
  useEffect(() => {
    dispatch(getLandDevelopmentStep3()).then((res) => {
      console.log({ res });
      setLandDevelopmentStep3List(res?.data);
    });
  }, []);
  return (
    <>
      <div className="ModalBigheadibg">
        <h3>
          <span>Land Development &amp; Admin Costs Input</span>
        </h3>
      </div>
      <div
        className="ModalMediumheading d-flex justify-content-between"
        style={{ width: "25%" }}
      >
        <h3>
          <span className="fw-500">Number of Acres </span>
        </h3>
        <h3>
          <span className="fw-500">
            {landDevelopmentStep3List?.data?.table1?.proforma?.acres ?? 0}{" "}
          </span>
        </h3>
      </div>
      <div className="BlockCostsPreview">
        <div className="d-flex align-items-center justify-content-between">
          <h4>
            Land, Design, Permits, Servicing, Warranty, insurance, Condo,
            Management, Legal &amp; Financing Costs
          </h4>
          <div className="NewActions">
            <a href="javascript:void(0);" title="pdf">
              <img src="images/pdf.png" />
            </a>
            <a href="javascript:void(0);" title="download">
              <img src="images/download.png" />
            </a>
          </div>
        </div>
        {landDevelopmentStep3List?.data?.table2?.blockQuantities?.length > 0 &&
          landDevelopmentStep3List?.data?.table2?.blockQuantities?.map(
            (item, i) => {
              return (
                <div className="BlockInputsCollapse">
                  <div
                    className="BcpHeader collapsed"
                    data-toggle="collapse"
                    href="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <h5>{item.name}</h5>
                  </div>
                  <div className="collapse" id="collapseExample" style={{}}>
                    <div className="card card-body border-0">
                      <div className="BlockInputs">
                        <table>
                          <thead>
                            <tr>
                              <th>Code Code #</th>
                              <th>Description</th>
                              <th>Total Construction Costs</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item?.ProformaBaseCosts?.length > 0 &&
                              item?.ProformaBaseCosts?.map((data, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{data.CostCode?.number}</td>
                                    <td>{data.CostCode?.name}</td>
                                    <td>
                                      {
                                        data.ProformaBlockQuantities?.[0]
                                          ?.MultiBlock?.blockDescription
                                      }
                                    </td>

                                    <td>
                                      $
                                      {Number(
                                        data.ProformaBlockQuantities?.[0]
                                          ?.totalCost
                                      ).toFixed(2) ?? 0}
                                    </td>
                                    <td>{data.totalCostSum}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td />
                              <td>Total Interior Finishing Costs</td>
                              <td>275,590.00</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}

        <div className="BlockInputs Red">
          <table>
            <tfoot>
              <tr>
                <td />
                <td>Grand Total</td>
                <td>$ 32,275,590.00</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div className="Button d-flex  justify-content-between align-items-center px-5">
        <button
          className="Outline border text-success border-success"
          onClick={() => handleShowComponents("Quanties")}
        >
          Cancel
        </button>
        <button
          className="Create bg-success border border-success"
          onClick={() => handleShowComponent("confirmation")}
        >
          Save Draft
        </button>
      </div>
    </>
  );
};

export default Preview;
