import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import {
  getCitiesList,
  getStateList,
  getCountriesList,
} from "../../redux/action/authAction";
import { getAllProjectDetailsAction } from "../../redux/action/jobAction";
import { toast } from "react-toastify";
import { createProjectAsEntityAction } from "../../redux/action/projectActionDk";

const initialState = {
  CompanyId: "",
  developer: "",

  projectEmail: "",

  projectName: "",

  projectAddress: "",

  city: "",

  country: "",

  zipCode: "",

  province: "",

  projectType: "",

  creationType: "",

  subdivisions: [],

  taxProfile: 1,

  useCompanyPattern: false,

  jobPattern: "",
  profileCategories: {
    landCosts: false,
    archConsultantsFees: false,
    engineersFees: false,
    permitFees: false,
    siteLandDevServCosts: false,
    siteLanRetaiWallsRPR: false,
    sitePrepTemOfficeCosts: false,
    newHomeWarInsuranceFees: false,
    condPlanCorpoCosts: false,
    markSalesCosts: false,
    managLegFinFees: false,
  },

  loginUrl: "",

  projectLogo: "",
  products: "",
  Subscription: "",

  contractCode: "",

  billingOption: "",

  PlanId: "",

  accoTransSett: false,

  useCompFiscalYearEnd: false,

  fiscalYearEnd: 3,

  useCompTaxRegistration: false,

  taxRegistration: "",

  useTaxProfiles: false,

  costCodeStructure: false,

  commWorkflows: false,

  designSettings: false,

  jobModels: false,

  quickBooks: false,

  sage: false,

  proformaId: 1,

  ctcMaster: false,

  fileManager: true,

  priceBook: true,

  vendorList: true,

  selAccoSoft: 0,
  imagePreview: "",

  errorMsg: {},
};

const EditProjectModal = ({
  editProjectModal,
  setEditProjectModal,
  selectedId,
  jobName,
  setJobDetialsModal,
}) => {
  const [iState, UpdateState] = useState(initialState);
  const dispatch = useDispatch();

  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

  const handleJobDetialsModalClose = () => {
    setEditProjectModal(false);
    setJobDetialsModal(true);
  };
  const handleJobDetialsModalShow = () => {
    setEditProjectModal(true);
  };

  const {
    projectName,
    projectEmail,
    projectLogo,
    loginUrl,
    imagePreview,
    subdivisions,
    developer,
    province,
    country,
    city,
    projectAddress,
    zipCode,
    jobPattern,

    errorMsg,
  } = iState;

  const [phonecode, setPhoneCode] = useState("");
  const { companyDetailData } = useSelector((state) => state.companyReducer);

  const { countryData, cityData, stateData } = useSelector(
    (state) => state.authReducer
  );

  const handleChange = (e) => {
    const { name, value } = e.target;

    UpdateState({
      ...iState,
      [name]: value,
    });
  };

  const handleUpload = (e) => {
    const { name, value } = e.target;
    const file = e.target.files[0];
    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png"
      ) {
        UpdateState({
          ...iState,
          projectLogo: e.target.files[0],
          imagePreview: URL.createObjectURL(file),
        });
      }
    }
  };

  useEffect(() => {
    if(country)
    dispatch(
      getStateList(
        countryData?.countries?.find((x) => x.name == country)?.isoCode
      )
    );
  }, [country, countryData?.countries, dispatch]);
  useEffect(() => {
    dispatch(
      getCitiesList(
        countryData?.countries?.find((x) => x.name == country)?.isoCode,
        stateData?.states?.find((x) => x.name == province)?.isoCode
      )
    );
  }, [province]);

  useEffect(() => {
    dispatch(getCountriesList());

    dispatch(getAllProjectDetailsAction(selectedId)).then((res) => {
      // console.log("details updated", res)
      UpdateState({
        ...iState,
        developer: res?.projects?.developer,

        projectEmail: res?.projects?.projectEmail,

        projectName: res?.projects?.projectName,

        projectAddress: res?.projects?.projectAddress,

        city: res?.projects?.city,

        country: res?.projects?.country,

        zipCode: res?.projects?.zipCode,

        province: res?.projects?.province,

        projectType: res?.projects?.projectType,

        creationType: res?.projects?.creationType,

        subdivisions: res?.projects?.subdivisions
          ? res?.projects?.subdivisions
          : [],

        taxProfile: res?.projects?.taxProfile,

        useCompanyPattern: false,

        jobPattern: res?.projects?.jobPattern,

        loginUrl: res?.projects?.loginUrl,

        projectLogo: res?.projects?.projectLogo,
        products: res?.projects?.products,
        Subscription: res?.projects?.Subscription,

        contractCode: res?.projects?.contractCode,

        billingOption: res?.projects?.billingOption,

        PlanId: res?.projects?.PlanId,

        fiscalYearEnd: 3,

        taxRegistration: res?.projects?.taxRegistration,
      });
    });
  }, [EditProjectModal]);

  const handleCheck = (e) => {
    const { name, checked } = e.target;

    UpdateState({
      ...iState,
      [name]: checked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      // CompanyId :iBuildLocalData?.user?.CompanyId,
      // projectEmail,
      // projectName,
      // projectAddress,
      // city,
      // country,
      // zipCode,
      // province,
      // projectType,
      // creationType,
      // subdivisions,
      // taxProfile:1,
      // useCompanyPattern,
      // jobPattern,
      // projectLogo,
      // products,
      // contractCode,
      // billingOption,
      // PlanId:1,
      // accoTransSett,
      // useCompFiscalYearEnd,
      // fiscalYearEnd,
      // useCompTaxRegistration,
      // taxRegistration,
      // useTaxProfiles,
      // costCodeStructure,
      // commWorkflows,
      // designSettings,
      // jobModels,
      // quickBooks,
      // sage,
      // proformaId,
    };

    dispatch(createProjectAsEntityAction(data))
      .then((res) => {
        if (res.status == 201) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          setEditProjectModal(false);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <Modal show={editProjectModal} className="ModalBox LargeModal">
      <div class="modal-content BgCreate">
        <a
          class="CloseModal"
          onClick={handleJobDetialsModalClose}
          data-dismiss="modal"
        >
          ×
        </a>
        <div class="FormArea">
          <div class="ModalTitleBox GrayModalTitle">
            <h4>Project Details</h4>
          </div>
          <div class="FolderForm">
            <div class="ResidentialJobArea ResidentialJobAreaPara">
              <div class="ResidentialJobHead">
                <h3>
                  Proj-4E-SIN-1
                  <span class="dropdown">
                    <a
                      class="dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img src="images/dots.png" />
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                      <a class="dropdown-item" href="javascript:void(0);">
                        <img src="images/cancecl_message.png" />
                        Cancel Project
                      </a>
                    </div>
                  </span>
                </h3>
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <h6>Created By</h6>
                      <p>John Doe</p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <h6>Creation Date</h6>
                      <p>04/15/2023</p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <h6>Last Update</h6>
                      <p>07/10/2023</p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <h6>Type</h6>
                      <p>Single Family</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="ModalAccordian">
                <div id="accordion">
                  <div class="card">
                    <div
                      class="card-header"
                      data-toggle="collapse"
                      href="#GenerationInformation"
                      aria-expanded="true"
                    >
                      <h4>
                        <span>
                          <img src="images/generation_information.png" />
                        </span>
                        Generation Information
                      </h4>
                    </div>
                    <div
                      id="GenerationInformation"
                      class="collapse show"
                      data-parent="#accordion"
                    >
                      <form>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-sm-5">
                              <div class="form-group">
                                <h6>Campany Name</h6>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Company 1"
                                  value={iBuildLocalData?.companies?.[0]?.name}
                                  disabled={true}
                                />
                              </div>
                              <div class="form-group">
                                <h6>Project Name</h6>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="project name"
                                  value={projectName}
                                  name="projectName"
                                  onChange={handleChange}
                                />
                              </div>
                              <div class="form-group">
                                <h6>Email</h6>
                                <input
                                  class="form-control"
                                  placeholder="contact@company1.com"
                                  type="text"
                                  name="projectEmail"
                                  onChange={handleChange}
                                  value={projectEmail}
                                />
                              </div>
                              <div class="form-group">
                                <h6>Login URL Shorthand</h6>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="project1"
                                  onChange={handleChange}
                                  value={loginUrl}
                                  name="loginUrl"
                                />
                              </div>
                            </div>
                            <div class="col-sm-5 ml-auto">
                              <div class="form-group">
                                <h6>Logo</h6>
                                {projectLogo ? (
                                  <>
                                    <img
                                      src={
                                        imagePreview
                                          ? imagePreview
                                          : projectLogo
                                      }
                                    />
                                    <input
                                      type="file"
                                      name="logo"
                                      onChange={handleUpload}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type="file"
                                      name="logo"
                                      onChange={handleUpload}
                                    />
                                  </>
                                )}
                                <div>
                                  <button class="AlterBtn mt-3">Alter</button>
                                </div>
                              </div>
                              <div class="form-group">
                                <h6>Developer</h6>
                                <div class="CommonSelectBox">
                                  <select
                                    onChange={handleChange}
                                    value={developer}
                                    name="developer"
                                  >
                                    <option value={""}>Select Developer</option>
                                    <option value={"Developer 1"}>
                                      Developer 1
                                    </option>
                                    <option value={"Developer 2"}>
                                      Developer 2
                                    </option>
                                  </select>
                                  <span>
                                    <img src="images/down.png" />
                                  </span>
                                </div>
                              </div>

                              <div class="SubdivisionsArea">
                                <h6>Subdivisions</h6>
                                <div class="SubdivisionsBoxMain">
                                  <div class="SubdivisionsBox">
                                    <div class="form-group">
                                      <h6>Subdivision</h6>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Delta"
                                        value={subdivisions}
                                        onChange={(e) =>
                                          UpdateState({
                                            ...iState,
                                            subdivisions: [e.target.value],
                                          })
                                        }
                                      />
                                    </div>
                                    <div class="form-group">
                                      <h6>Action</h6>
                                      <div class="Action">
                                        <a href="javascript:void(0);">
                                          <img src="images/Action-1.png" />
                                        </a>
                                        <a
                                          onClick={() =>
                                            UpdateState({
                                              ...iState,
                                              subdivisions: [],
                                            })
                                          }
                                        >
                                          <img src="images/dropdown-delete.png" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="SubdivisionsBox">
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Beta"
                                      />
                                    </div>
                                    <div class="form-group">
                                      <div class="Action">
                                        <a href="javascript:void(0);">
                                          <img src="images/Action-1.png" />
                                        </a>
                                        <a href="javascript:void(0);">
                                          <img src="images/dropdown-delete.png" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="SubdivisionsBox">
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Add Line"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="Button d-flex justify-content-center">
                            <button class="OutlineBlack mr-3">Cancel</button>
                            <button class="Respond ml-3">Save</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div class="card">
                    <div
                      class="card-header"
                      data-toggle="collapse"
                      href="#businessAddress"
                      aria-expanded="true"
                    >
                      <h4>
                        <span>
                          <img src="images/business_address.png" />
                        </span>{" "}
                        Business Address
                      </h4>
                    </div>
                    <div
                      id="businessAddress"
                      class="collapse show"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <h6>Address</h6>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Street Address"
                                onChange={handleChange}
                                value={projectAddress}
                                name="projectAddress"
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <h6>Zip/ Postal Code</h6>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="T3A T3A"
                                value={zipCode}
                                onChange={handleChange}
                                name="zipCode"
                              />
                            </div>
                            <div class="form-group">
                              <h6>Country</h6>
                              <select
                                name="country"
                                value={country}
                                onChange={handleChange}
                              >
                                <option>Select Country</option>
                                {countryData?.countries ? (
                                  <>
                                    {countryData?.countries?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                            </div>
                            <div class="form-group">
                              <h6>State/Province</h6>
                              <select
                                name="province"
                                value={province}
                                onChange={handleChange}
                              >
                                <option>Select Province</option>
                                {stateData?.states ? (
                                  <>
                                    {stateData?.states?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                            </div>
                            <div class="form-group">
                              <h6>City</h6>

                              <select
                                name="city"
                                value={city}
                                onChange={handleChange}
                              >
                                <option>Select City</option>
                                {cityData?.cities ? (
                                  <>
                                    {cityData?.cities?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="Button d-flex justify-content-center">
                          <button class="OutlineBlack mr-3">Cancel</button>
                          <button class="Respond ml-3">Save</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div
                      class="card-header"
                      data-toggle="collapse"
                      href="#Accounting"
                    >
                      <h4>
                        <span>
                          <img src="images/accounting.png" />
                        </span>
                        Accounting
                      </h4>
                    </div>
                    <div
                      id="Accounting"
                      class="collapse show"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <div class="AccounitingCollapseArea">
                          <h6>
                            Recommended Accounting Configuration Procedures
                            Before Integration
                          </h6>
                          <p>
                            - You must first purchase the{" "}
                            <span>QuickBooks Online Plus</span> version as the
                            <span>Plus</span> version allows class usage
                          </p>
                          <p>
                            - Second, ensure the{" "}
                            <span>Chart of Accounts (COA)</span> has an{" "}
                            <span>Accounts Payables (A/P)</span> account already
                            set-up, if not, set one up.
                          </p>
                          <p>
                            - In your QuickBooks Online Plus version you must
                            first Turn-On the <span>Purchase Order</span>{" "}
                            Option, the <span>Enable account numbers</span>
                            option, then the the <span>Class</span> Option as
                            follows:
                          </p>
                          <ul>
                            <li>
                              --Click on the{" "}
                              <span>
                                Gear Icon in the Upper Right-Hand Corner of on
                                your Home Dashboard screen.
                              </span>
                            </li>
                            <li>
                              --Click on <span>Account and Settings</span>
                            </li>
                            <li>
                              --Click on the <span>Account and Settings</span>{" "}
                              option.{" "}
                            </li>

                            <li>
                              --Click on the <span>Expenses</span> option.{" "}
                            </li>
                            <li>
                              --Click on the box in front of the{" "}
                              <span>Use Purchase Orders</span> option.
                            </li>
                            <li>
                              --Click the green <span>Save</span> button.
                            </li>
                            <li>
                              --Click on the <span>Advanced</span> option.
                            </li>
                            <li>
                              --Click on the <span>Chart of Accounts</span>{" "}
                              option.
                            </li>
                            <li>
                              --Click on the box in front of{" "}
                              <span>Enable Account Numbers</span>{" "}
                            </li>
                            <li>
                              --Click the green <span>Save</span> button.
                            </li>
                            <li>
                              --Click the box in front of the{" "}
                              <span>Track Classes</span> option.
                            </li>
                            <li>
                              --Click the green <span>Save</span> button
                            </li>
                          </ul>
                          <p>
                            -Return to this page and click on the Green{" "}
                            <span>Connect to QuickBooks </span>button.
                          </p>
                        </div>

                        <div class="row mt-4">
                          <div class="col-md-4">
                            <div class="form-group">
                              <h6>Software</h6>
                              <img src="images/quickbooks.png" />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="d-flex">
                              <div>
                                <h6>Software</h6>
                                <h6 class="fw-500">Not Syncd</h6>
                              </div>
                              <div class="Button ml-2">
                                <button class="Respond p-1">
                                  Connect to <br /> Quickbooks
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <h6>Fiscal Year End</h6>
                              <p>March</p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <button class="AlterBtn">Alter</button>
                        </div>

                        <div class="Button d-flex justify-content-center">
                          <button class="OutlineBlack mr-3">Cancel</button>
                          <button class="Respond ml-3">Save</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header" data-toggle="collapse" href="#Tax">
                      <h4>
                        <span>
                          <img src="images/accounting.png" />
                        </span>
                        Tax
                      </h4>
                    </div>
                    <div
                      id="Tax"
                      class="collapse show"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <h6>Tax Registration</h6>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="713158681RT0001"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-6">
                            <h6>Tax Profiles</h6>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Insert New Tax Profile Name"
                            />
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control AddNewTaxProfile"
                                placeholder="Add New Tax Profile"
                              />
                            </div>
                          </div>

                          <div class="col-12">
                            <div class="AlbertTaxMainBorder">
                              <div class="AlbertaTaxMain">
                                <div class="AlbertaTax AlbertaTaxBtn">
                                  <h5>Alberta Taxs</h5>
                                  <img src="images/down_arrow_fill.png" />
                                </div>
                                <div class="AlbertaTaxHide">
                                  <div class="row">
                                    <div class="col-md-7">
                                      <div class="mb-2">
                                        <label class="CheckBox">
                                          {" "}
                                          Default
                                          <input type="checkbox" />
                                          <span class="checkmark"></span>
                                        </label>
                                      </div>
                                      <div class="mb-2">
                                        <label class="CheckBox">
                                          {" "}
                                          Active
                                          <input type="checkbox" />
                                          <span class="checkmark"></span>
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-md-5">
                                      <div class="form-group">
                                        <h6>Name</h6>
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="Alberta Tax"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="AlbertaTaxMain">
                                <div class="TaxRow">
                                  <h5>Tax Rows</h5>
                                </div>
                                <div class="TaxRowHide">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Tax Location</th>
                                        <th>Tax Name</th>
                                        <th>Tax Percent</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div class="CommonSelectBox">
                                            <select>
                                              <option selected="">
                                                Select Tax Location
                                              </option>
                                              <option>India</option>
                                              <option>USA</option>
                                              <option>Australia</option>
                                            </select>
                                            <span>
                                              <img src="images/down.png" />
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="form-group">
                                            <input
                                              type="text"
                                              class="form-control width-50"
                                              placeholder="Insert Tax Name"
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div class="form-group">
                                            <input
                                              type="text"
                                              class="form-control width-50"
                                              placeholder="Insert Tax Percent"
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div class="TableThreeDots text-center">
                                            <ul class="">
                                              <li class="dropdown">
                                                <a
                                                  class=""
                                                  role="button"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                >
                                                  <img src="images/dots.png" />
                                                </a>
                                                <ol class="dropdown-menu dropdown-menuwidth">
                                                  <li>
                                                    <a href="javascript:void(0);">
                                                      <img src="images/remove_tax_line.png" />
                                                      Remove line
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a href="javascript:void(0);">
                                                      <img src="images/add_new_tax_line.png" />
                                                      Add New Line
                                                    </a>
                                                  </li>
                                                </ol>
                                              </li>
                                            </ul>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div class="AlbertaTaxMain">
                                <div class="Rebate">
                                  <h5>Rebates</h5>
                                </div>
                                <div class="TaxRowHide">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Applicable Row</th>
                                        <th>Sale Price Range</th>
                                        <th>Applied as</th>
                                        <th>Amount</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div class="CommonSelectBox">
                                            <select>
                                              <option selected="">
                                                Select Tax Row
                                              </option>
                                              <option>India</option>
                                              <option>USA</option>
                                              <option>Australia</option>
                                            </select>
                                            <span>
                                              <img src="images/down.png" />
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="d-flex">
                                            <div class="form-group mr-1">
                                              <input
                                                type="text"
                                                class="form-control width-50"
                                                placeholder="Insert Tax Name"
                                              />
                                            </div>
                                            <div class="form-group ml-1">
                                              <input
                                                type="text"
                                                class="form-control width-50"
                                                placeholder="Insert Tax Name"
                                              />
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="form-group">
                                            <input
                                              type="text"
                                              class="form-control width-50"
                                              placeholder="Insert Tax Percent"
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div class="form-group">
                                            <input
                                              type="text"
                                              class="form-control width-50"
                                              placeholder="Insert Tax Percent"
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div class="TableThreeDots text-center">
                                            <ul class="">
                                              <li class="dropdown">
                                                <a
                                                  class=""
                                                  role="button"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                >
                                                  <img src="images/dots.png" />
                                                </a>
                                                <ol class="dropdown-menu dropdown-menuwidth">
                                                  <li>
                                                    <a href="javascript:void(0);">
                                                      <img src="images/remove_tax_line.png" />
                                                      Remove line
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a href="javascript:void(0);">
                                                      <img src="images/add_new_tax_line.png" />
                                                      Add New Line
                                                    </a>
                                                  </li>
                                                </ol>
                                              </li>
                                            </ul>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div class="SaveDeleteProfile mt-4">
                                  <button>Save Profile</button>
                                  <button>Delete Profile</button>
                                </div>
                              </div>
                              <div class="AlbertaTaxMain">
                                <div class="AlbertaTax AlbertaTaxBtn">
                                  <h5>Ontario Tax</h5>
                                  <img src="images/down_arrow_fill.png" />
                                </div>
                              </div>
                            </div>
                            <div class="Button  mt-5 d-flex justify-content-center">
                              <button class="OutlineBlack mr-3">Cancel</button>
                              <button class="Respond ml-3">Save</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div
                      class="card-header"
                      data-toggle="collapse"
                      href="#JobNumbering"
                    >
                      <h4>
                        <span>
                          <img src="images/job_numbering.png" />
                        </span>{" "}
                        Job Numbering
                      </h4>
                    </div>
                    <div
                      id="JobNumbering"
                      class="collapse show"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-5">
                            <div class="form-group">
                              <h6>Job Number Pattern</h6>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="[UNUM]-[SD3]-[CID]-[JN]"
                                value={jobPattern}
                                onChange={handleChange}
                                name="jobPattern"
                              />
                            </div>
                          </div>
                          <div class="col-md-5 ml-auto">
                            <div class="form-group">
                              <h6>Sample Job Number</h6>
                              <input
                                type="text"
                                class="form-control InputCyanBg"
                                placeholder="MAH-2B-377"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="RestrictionArea">
                          <h6>Restrictions</h6>
                          <ul>
                            <li>
                              -Max length for generated job number is 40
                              characters.
                            </li>
                            <li>
                              -[CID] and [JN] must both occur at least once.
                            </li>
                            <li>
                              -Only alpha numeric characters and single dashes
                              are allowed.
                            </li>
                            <li>-It cannot end/start in a dash.</li>
                          </ul>
                        </div>
                        <div class="KeySelectionArea">
                          <h6>Key Selection</h6>
                          <p>
                            Below is a list of available keys to put details
                            within your job number. Adding a key below, into the
                            job pattern above, will replace that value with the
                            real value when the number is actually being
                            generated (Check Sample Job Number).
                          </p>
                          <div class="KeySelectionBox">
                            <aside>
                              <p>
                                <button class="BlueBtn">[ACD]</button>
                                <span>Agreement or Creation Date</span>
                              </p>
                              <p>
                                <button class="BlueBtn">[ACDM2]</button>
                                <span>
                                  Agreement or Creation <br />
                                  Date: Two Digit Month
                                </span>
                              </p>
                              <p>
                                <button class="BlueBtn">[SD3]</button>
                                <span>Three Characteres of Subdivision</span>
                              </p>
                              <p>
                                <button class="BlueBtn">[FY2]</button>
                                <span>Two Digit Fiscal Year</span>
                              </p>
                            </aside>
                            <aside>
                              <p>
                                <button class="BlueBtn">[ACDY2]</button>
                                <span>
                                  Agreement or Creation <br />
                                  Date: Two Digit Year
                                </span>
                              </p>
                              <p>
                                <button class="GreenBtn">[CID]</button>
                                <span>Company ID</span>
                              </p>
                              <p>
                                <button class="BlueBtn">[SD4]</button>
                                <span>Four Characteres of Subdivision</span>
                              </p>
                              <p>
                                <button class="BlueBtn">[FY4]</button>
                                <span>Four Digit Fiscal Year</span>
                              </p>
                            </aside>
                            <aside>
                              <p>
                                <button class="BlueBtn">[ACDY4]</button>
                                <span>
                                  Agreement or Creation <br />
                                  Date: Four Digit Year
                                </span>
                              </p>
                              <p>
                                <button class="GreenBtn">[JN]</button>
                                <span>Job Number</span>
                              </p>
                              <p>
                                <button class="BlueBtn">[UNUM]</button>
                                <span>Three Characteres of Subdivision</span>
                              </p>
                            </aside>
                          </div>
                        </div>
                        <div class="Button mt-4 d-flex justify-content-center">
                          <button class="OutlineBlack mr-3">Cancel</button>
                          <button class="Respond ml-3">Save</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div
                      class="card-header"
                      data-toggle="collapse"
                      href="#Subscription"
                    >
                      <h4>
                        <span>
                          <img src="images/subscription.png" />
                        </span>{" "}
                        Subscription
                      </h4>
                    </div>
                    <div
                      id="Subscription"
                      class="collapse show"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-3">
                            <div class="form-group">
                              <h6>Company Id</h6>
                              <p>Company Id</p>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <h6>Subscription</h6>
                              <p>Plan 1</p>
                              <button class="AlterBtn mt-3">Alter</button>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <h6>Billing Option</h6>
                              <p>Monthly</p>
                              <button class="AlterBtn mt-3">Alter</button>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <h6>Contract Code</h6>
                              <p>CO001FULL</p>
                            </div>
                          </div>
                        </div>
                        <div class="Button mt-4 d-flex justify-content-center">
                          <button class="OutlineBlack mr-3">Cancel</button>
                          <button class="Respond ml-3">Save</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div
                      class="card-header"
                      data-toggle="collapse"
                      href="#CostProfiles"
                    >
                      <h4>
                        <span>
                          <img src="images/cost_profile_collapse.png" />
                        </span>{" "}
                        Cost Profiles
                      </h4>
                    </div>
                    <div
                      id="CostProfiles"
                      class="collapse show"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-5">
                            <h6>Job Pattern Definition</h6>
                            <div class="form-group mb-2 ">
                              <label class="switch">
                                {" "}
                                Land Costs
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                            <div class="form-group mb-2 ">
                              <label class="switch">
                                {" "}
                                Architects &amp; Consultants Fees
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                            <div class="form-group mb-2 ">
                              <label class="switch">
                                {" "}
                                Engineers Fees
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                            <div class="form-group mb-2 ">
                              <label class="switch">
                                {" "}
                                Permit Fees
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>

                            <div class="form-group mb-2 ">
                              <label class="switch">
                                {" "}
                                Site Land Development Servicing Costs
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                            <div class="form-group mb-2 ">
                              <label class="switch">
                                {" "}
                                Site Preparation &amp; Tem Office Costs
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                            <div class="form-group mb-2 ">
                              <label class="switch">
                                {" "}
                                Site Landscaping, Retaining Walls, RPR's
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                            <div class="form-group mb-2 ">
                              <label class="switch">
                                {" "}
                                New Home Warranty &amp; Insurance Fees
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                            <div class="form-group mb-2 ">
                              <label class="switch">
                                {" "}
                                Condominium Plan Corporation Costs
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                            <div class="form-group mb-2 ">
                              <label class="switch">
                                {" "}
                                Marketing &amp; Sales Costs
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                            <div class="form-group mb-2 ">
                              <label class="switch">
                                {" "}
                                Management, Legal, Financing Fees
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-md-5 ml-auto">
                            <h6>Include Land Development Shared Costs</h6>
                            <div class="form-group my-4 text-center">
                              <label class="switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                            <div class="form-group">
                              <h6>Job</h6>
                              <div class="CommonSelectBox">
                                <select>
                                  <option selected="">CHI-2B-382</option>
                                  <option>CHI-2B-382 1</option>
                                </select>
                                <span>
                                  <img src="images/down.png" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="Button d-flex justify-content-center mt-4">
                          <button class="OutlineBlack mr-3">Cancel</button>
                          <button class="Respond ml-3">Save</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div
                      class="card-header"
                      data-toggle="collapse"
                      href="#Jobs"
                    >
                      <h4>
                        <span>
                          <img src="images/jobs_collpase.png" />
                        </span>{" "}
                        Jobs
                      </h4>
                    </div>
                    <div
                      id="Jobs"
                      class="collapse show"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <div class="Tableheader">
                          <h6 class="mb-0">Residential Jobs</h6>
                          <div class="d-flex align-items-center">
                            <div class="TodosButtons mt-0 d-flex">
                              <a href="javascript:void(0)" class="">
                                <img src="images/create_new_job.png" />
                                Create New Job
                              </a>
                            </div>
                            <div class="NewActions">
                              <a href="jacascript:void(0);">
                                <img src="images/icon-53.png" />
                              </a>
                              <a href="jacascript:void(0);">
                                <img src="images/download.png" />
                              </a>
                              <a href="jacascript:void(0);">
                                <img src="images/setting.png" />
                              </a>
                            </div>
                          </div>
                        </div>

                        <div class="TableList TableListHeader NewTableList TableHeightScroll">
                          <table>
                            <thead>
                              <tr>
                                <th>Id</th>
                                <th>Home Type</th>
                                <th>Tax Profile</th>
                                <th>Foundation</th>
                                <th>Address</th>
                                <th>Creation Date</th>
                                <th>Created By</th>
                                <th>Timezone</th>
                                <th>Accounting Status</th>
                                <th>Add-Ons</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div class="TextBlue">HIL-2B-376</div>
                                </td>
                                <td>2-Storey</td>
                                <td>Alberta Tax</td>
                                <td>Basement</td>
                                <td>
                                  312 Weddenburn Rd SE Calgary, Alberta, T2J
                                  1J1, Canada
                                </td>
                                <td>05/24/2023 </td>
                                <td>John Doe</td>
                                <td>Moutain Time GMT - 06</td>
                                <td>Integrated with Accouting Software</td>
                                <td>20</td>
                                <td>
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown">
                                        <a
                                          class=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol class="dropdown-menu dropdown-menuwidth2">
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/Action-1.png" />
                                              Edit Job Details
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/archive_job.png" />
                                              Archive Job
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/enable_account.png" />
                                              Enable Accouting Integration
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/add_build.png" />
                                              Create Add-Ons
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="TextBlue">HIL-2B-376</div>
                                </td>
                                <td>2-Storey</td>
                                <td>Alberta Tax</td>
                                <td>Basement</td>
                                <td>
                                  312 Weddenburn Rd SE Calgary, Alberta, T2J
                                  1J1, Canada
                                </td>
                                <td>05/24/2023 </td>
                                <td>John Doe</td>
                                <td>Moutain Time GMT - 06</td>
                                <td>Integrated with Accouting Software</td>
                                <td>20</td>
                                <td>
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown">
                                        <a
                                          class=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol class="dropdown-menu dropdown-menuwidth2">
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/Action-1.png" />
                                              Edit Job Details
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/archive_job.png" />
                                              Archive Job
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/enable_account.png" />
                                              Enable Accouting Integration
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/add_build.png" />
                                              Create Add-Ons
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="TextBlue">HIL-2B-376</div>
                                </td>
                                <td>2-Storey</td>
                                <td>Alberta Tax</td>
                                <td>Basement</td>
                                <td>
                                  312 Weddenburn Rd SE Calgary, Alberta, T2J
                                  1J1, Canada
                                </td>
                                <td>05/24/2023 </td>
                                <td>John Doe</td>
                                <td>Moutain Time GMT - 06</td>
                                <td>Integrated with Accouting Software</td>
                                <td>20</td>
                                <td>
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown">
                                        <a
                                          class=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol class="dropdown-menu dropdown-menuwidth2">
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/Action-1.png" />
                                              Edit Job Details
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/archive_job.png" />
                                              Archive Job
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/enable_account.png" />
                                              Enable Accouting Integration
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/add_build.png" />
                                              Create Add-Ons
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="TextBlue">HIL-2B-376</div>
                                </td>
                                <td>2-Storey</td>
                                <td>Alberta Tax</td>
                                <td>Basement</td>
                                <td>
                                  312 Weddenburn Rd SE Calgary, Alberta, T2J
                                  1J1, Canada
                                </td>
                                <td>05/24/2023 </td>
                                <td>John Doe</td>
                                <td>Moutain Time GMT - 06</td>
                                <td>Integrated with Accouting Software</td>
                                <td>20</td>
                                <td>
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown">
                                        <a
                                          class=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol class="dropdown-menu dropdown-menuwidth2">
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/Action-1.png" />
                                              Edit Job Details
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/archive_job.png" />
                                              Archive Job
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/enable_account.png" />
                                              Enable Accouting Integration
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/add_build.png" />
                                              Create Add-Ons
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="TextBlue">HIL-2B-376</div>
                                </td>
                                <td>2-Storey</td>
                                <td>Alberta Tax</td>
                                <td>Basement</td>
                                <td>
                                  312 Weddenburn Rd SE Calgary, Alberta, T2J
                                  1J1, Canada
                                </td>
                                <td>05/24/2023 </td>
                                <td>John Doe</td>
                                <td>Moutain Time GMT - 06</td>
                                <td>Integrated with Accouting Software</td>
                                <td>20</td>
                                <td>
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown">
                                        <a
                                          class=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol class="dropdown-menu dropdown-menuwidth2">
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/Action-1.png" />
                                              Edit Job Details
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/archive_job.png" />
                                              Archive Job
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/enable_account.png" />
                                              Enable Accouting Integration
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/add_build.png" />
                                              Create Add-Ons
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="TextBlue">HIL-2B-376</div>
                                </td>
                                <td>2-Storey</td>
                                <td>Alberta Tax</td>
                                <td>Basement</td>
                                <td>
                                  312 Weddenburn Rd SE Calgary, Alberta, T2J
                                  1J1, Canada
                                </td>
                                <td>05/24/2023 </td>
                                <td>John Doe</td>
                                <td>Moutain Time GMT - 06</td>
                                <td>Integrated with Accouting Software</td>
                                <td>20</td>
                                <td>
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown">
                                        <a
                                          class=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol class="dropdown-menu dropdown-menuwidth2">
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/Action-1.png" />
                                              Edit Job Details
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/archive_job.png" />
                                              Archive Job
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/enable_account.png" />
                                              Enable Accouting Integration
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/add_build.png" />
                                              Create Add-Ons
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="PaginationArea">
                          <h6>Showing 3 of 3 entries</h6>
                          <h6>
                            <img src="images/leftarrow.png" /> 01{" "}
                            <img src="images/rightarrow.png" /> Out 10
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="CloseButton">
                  <button
                    class="Close"
                    data-toggle="modal"
                    data-target="#ProjectDetailsResidentialEntity"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditProjectModal;
