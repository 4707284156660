import React from 'react'
import IbuildAdminSidenav from "./IbuildAdminSidenav";
import Header from "../Header";

import IbuldAdminSubscription from "./IbuldAdminSubscription";


const IbuildAdminTab = () => {
  return (

    <>
    <Header />
    <IbuildAdminSidenav />
    <div class="WrapperArea">
      <div class="WrapperBox">
        <div class="MainTitleBox">
          <div class="MainTitle">
            <h2>Admin Administration</h2>
          </div>
        </div>
        <div class="TitleBox justify-content-end">
          <div class="TodosButtons">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a
                  class="nav-link active px-2"
                  data-toggle="tab"
                  href="#CustomersInformationTab"
                >
                  <img src="images/customer_information.png" />
                  Customers Information<span>3</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link px-2"
                  data-toggle="tab"
                  href="#HelpMessagesTab"
                >
                  <img src="images/help_message.png" />
                  Help Messages<span>3</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link px-2"
                  data-toggle="tab"
                  href="#SubscriptionPlansTab"
                >
                  <img src="images/subscription_plan.png" />
                  Subscription Plans<span>3</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link px-2"
                  data-toggle="tab"
                  href="#CustomerSupportTab"
                >
                  <img src="images/customer_support.png" />
                  Customer Support<span>3</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link px-2"
                  data-toggle="tab"
                  href="#ChatMessageTab"
                >
                  <img src="images/customer_support.png" />
                  Chat Messages<span>3</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="tab-content">
          <div class="tab-pane fade active show" id="CustomersInformationTab">
            <div class="Tableheader">
              <div class="d-flex align-items-center">
                <h6>Customers</h6>
                <div class="TodosButtons mt-0 d-flex">
                  <a
                    href="javascript:void(0)"
                    data-toggle="modal"
                    data-target="#NewContractRequests"
                    data-dismiss="modal"
                  >
                    <img src="images/new_contracts_request.png" />
                    New Contract Requests<span>3</span>
                  </a>
                </div>
              </div>
              <div class="d-flex">
                <div class="TodosButtons mt-0 d-flex">
                  <a
                    href="javascript:void(0)"
                    data-toggle="modal"
                    data-target="#CreateContractCode"
                    data-dismiss="modal"
                  >
                    <img src="images/create_contract.png" />
                    Create Contract Code
                  </a>
                </div>
                <div class="NewActions">
                  <a href="javascript:void(0);" title="pdf">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="javascript:void(0);" title="download">
                    <img src="images/download.png" />
                  </a>
                  <a href="javascript:void(0);" title="settings">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
            </div>

            <div
              class="JobTableCommon JobTableCommon"
              style={{ display: "block" }}
            >
              <table class="table">
                <thead>
                  <tr>
                    <th>Company ID</th>
                    <th>Company Name</th>
                    <th>Subscription Plan</th>
                    <th>Payment Type</th>
                    <th>Billing Option</th>
                    <th>Contract Code</th>
                    <th>Status</th>
                    <th>Payment Status</th>
                    <th>Next Payment</th>
                    <th>Activation Date</th>
                    <th>Active Projects</th>
                    <th>Active Projects as an Entity</th>
                    <th>Active Jobs</th>
                    <th>Active Users</th>
                    <th>Warranty Jobs</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody class="addClasss">
                  <tr>
                    <td class="hidtableshowChangeOrder">
                      <div class="Action">
                        4E <img src="images/icon-34.png" class="ml-2" />
                      </div>
                    </td>
                    <td class="hidtableshowChangeOrder">Builder1</td>
                    <td class="hidtableshowChangeOrder">Plan 1</td>
                    <td class="hidtableshowChangeOrder">Contract</td>
                    <td class="hidtableshowChangeOrder">Yearly</td>
                    <td class="hidtableshowChangeOrder">CO4E00FULL</td>
                    <td class="hidtableshowChangeOrder">Active</td>
                    <td class="hidtableshowChangeOrder">Received</td>
                    <td class="hidtableshowChangeOrder">07/13/2024</td>
                    <td class="hidtableshowChangeOrder">07/13/2024</td>
                    <td class="hidtableshowChangeOrder">2</td>
                    <td class="hidtableshowChangeOrder">2</td>
                    <td class="hidtableshowChangeOrder">400</td>
                    <td class="hidtableshowChangeOrder">50</td>
                    <td class="hidtableshowChangeOrder">200</td>
                    <td>
                      <div class="TableThreeDots">
                        <ul class="">
                          <li class="dropdown">
                            <a
                              class=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol class="dropdown-menu dropdown-menuwidth2">
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#CreateContract"
                                  data-dismiss="modal"
                                >
                                  <img src="images/Action-1.png" />
                                  Edit Contract
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/block.png" />
                                  Block Company
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/update_contract.png" />
                                  Update Contract Code
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/view_contract.png" />
                                  View Contract
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/dropdown-delete.png" />
                                  Delete Company
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="15" class="padding_none">
                      <div class="JobBoxTable JobBoxTableHeight">
                        <table>
                          <thead>
                            <tr>
                              <th>Project Id</th>
                              <th>Project Name</th>
                              <th>Option</th>
                              <th>Payment Type</th>
                              <th>Billing Option</th>
                              <th>Contract Code</th>
                              <th>Status</th>
                              <th>Payment Status</th>
                              <th>Next Payment</th>
                              <th>Activation Date</th>
                              <th>Active Jobs</th>
                              <th>Active Users</th>
                              <th></th>
                              <th></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>4E-E1</td>
                              <td>Project 1</td>
                              <td>Unlimited</td>
                              <td>Contract</td>
                              <td>Yearly</td>
                              <td>CO4E00FULL</td>
                              <td>Active</td>
                              <td>Received</td>
                              <td>07/13/2024</td>
                              <td>06/01/2023</td>
                              <td>200</td>
                              <td>50</td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td>2F</td>
                    <td>Builder 2</td>
                    <td>Plan 2</td>
                    <td>Credit Card</td>
                    <td>Monthly</td>
                    <td></td>
                    <td>Active</td>
                    <td>Received</td>
                    <td>07/13/2024</td>
                    <td>07/13/2022</td>
                    <td>0</td>
                    <td>0</td>
                    <td>2000</td>
                    <td>100</td>
                    <td>500</td>
                    <td>
                      <div class="TableThreeDots">
                        <ul class="">
                          <li class="dropdown">
                            <a
                              class=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol class="dropdown-menu dropdown-menuwidth2">
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/Action-1.png" />
                                  Edit Contract
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/block.png" />
                                  Block Company
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/update_contract.png" />
                                  Update Contract Code
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/view_contract.png" />
                                  View Contract
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/dropdown-delete.png" />
                                  Delete Company
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>3E</td>
                    <td>Builder 3</td>
                    <td>Plan 2</td>
                    <td>Contract</td>
                    <td>Monthly</td>
                    <td>CO3G10DISC</td>
                    <td>Active</td>
                    <td>Received</td>
                    <td>07/13/2024</td>
                    <td>07/13/2022</td>
                    <td>1</td>
                    <td>0</td>
                    <td>3000</td>
                    <td>200</td>
                    <td>1000</td>
                    <td>
                      <div class="TableThreeDots">
                        <ul class="">
                          <li class="dropdown">
                            <a
                              class=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol class="dropdown-menu dropdown-menuwidth2">
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/Action-1.png" />
                                  Edit Contract
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/block.png" />
                                  Block Company
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/update_contract.png" />
                                  Update Contract Code
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/view_contract.png" />
                                  View Contract
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/dropdown-delete.png" />
                                  Delete Company
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>5G</td>
                    <td>Builder 4</td>
                    <td>Plan 2</td>
                    <td>Contract</td>
                    <td>Yearly</td>
                    <td></td>
                    <td>
                      <div class="TextRed">Blocked</div>
                    </td>
                    <td>
                      <div class="TextRed">Pending</div>
                    </td>
                    <td>07/13/2024</td>
                    <td>07/13/2022</td>
                    <td>1</td>
                    <td>0</td>
                    <td>100</td>
                    <td>10</td>
                    <td>10</td>
                    <td>
                      <div class="TableThreeDots">
                        <ul class="">
                          <li class="dropdown">
                            <a
                              class=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol class="dropdown-menu dropdown-menuwidth2">
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/Action-1.png" />
                                  Edit Contract
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/block.png" />
                                  Block Company
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/update_contract.png" />
                                  Update Contract Code
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/view_contract.png" />
                                  View Contract
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/dropdown-delete.png" />
                                  Delete Company
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="PaginationArea">
              <h6>Showing 1 to 10 of 27 entries</h6>
              <h6>
                <img src="images/leftarrow.png" /> <span>01</span>{" "}
                <img src="images/rightarrow.png" /> Out 10
              </h6>
            </div>
          </div>
          <div class="tab-pane fade" id="HelpMessagesTab">
            <div class="Tableheader">
              <div class="d-flex align-items-center">
                <h6>System Help Messages</h6>
                <div class="TodosButtons mt-0 d-flex">
                  <a href="javascript:void(0)" class="">
                    <img src="images/hide_display.png" />
                    Hide disabled help messages
                  </a>
                </div>
              </div>
              <div class="d-flex">
                <div class="TodosButtons mt-0 d-flex">
                  <a
                    href="javascript:void(0)"
                    data-toggle="modal"
                    data-target="#CreateNewHelpMessage"
                  >
                    <img src="images/create_new_help.png" />
                    Create New Help Message
                  </a>
                </div>
                <div class="NewActions">
                  <a href="javascript:void(0);" title="pdf">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="javascript:void(0);" title="download">
                    <img src="images/download.png" />
                  </a>
                  <a href="javascript:void(0);" title="settings">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
            </div>

            <div class="TableList NewTableList TableListHeader TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Application Path</th>
                    <th>Creation Date</th>
                    <th>Created By</th>
                    <th>Status</th>
                    <th>
                      <div class="text-center">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="PendingConfirmation">
                    <td>//ibuildresidential.com (draft)</td>
                    <td>07/12/2023</td>
                    <td>Sysadmin 1</td>
                    <td>Draft</td>
                    <td>
                      <div class="TableThreeDots text-center">
                        <ul class="">
                          <li class="dropdown">
                            <a
                              class=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol class="dropdown-menu dropdown-menuwidth">
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/work-order-black.png" />
                                  View Help Message
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0;)"
                                  data-toggle="modal"
                                  data-target="#EditHelpMessage"
                                >
                                  <img src="images/Action-1.png" />
                                  Edit Help Message
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/dropdown-delete.png" />
                                  Delete Help Message
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                  Enable Help Message
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                  Disable Help Message
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>/AdminGroup/AccountingSetup</td>
                    <td>07/12/2023</td>
                    <td>Sysadmin 1</td>
                    <td>
                      <div class="TextGreen">Active</div>
                    </td>
                    <td>
                      <div class="TableThreeDots text-center">
                        <ul class="">
                          <li class="dropdown">
                            <a
                              class=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol class="dropdown-menu dropdown-menuwidth">
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/work-order-black.png" />
                                  View Help Message
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0;)"
                                  data-toggle="modal"
                                  data-target="#EditHelpMessage"
                                >
                                  <img src="images/Action-1.png" />
                                  Edit Help Message
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/dropdown-delete.png" />
                                  Delete Help Message
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                  Enable Help Message
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                  Disable Help Message
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>/ChangeOrders/List/</td>
                    <td>07/12/2023</td>
                    <td>Sysadmin 2</td>
                    <td>
                      <div class="TextGreen">Active</div>
                    </td>
                    <td>
                      <div class="TableThreeDots text-center">
                        <ul class="">
                          <li class="dropdown">
                            <a
                              class=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol class="dropdown-menu dropdown-menuwidth">
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/work-order-black.png" />
                                  View Help Message
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0;)"
                                  data-toggle="modal"
                                  data-target="#EditHelpMessage"
                                >
                                  <img src="images/Action-1.png" />
                                  Edit Help Message
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/dropdown-delete.png" />
                                  Delete Help Message
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                  Enable Help Message
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                  Disable Help Message
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>/Construction/ConstructionSchedule/</td>
                    <td>07/12/2023</td>
                    <td>Sysadmin 2</td>
                    <td>
                      <div class="TextGreen">Active</div>
                    </td>
                    <td>
                      <div class="TableThreeDots text-center">
                        <ul class="">
                          <li class="dropdown">
                            <a
                              class=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol class="dropdown-menu dropdown-menuwidth">
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/work-order-black.png" />
                                  View Help Message
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0;)"
                                  data-toggle="modal"
                                  data-target="#EditHelpMessage"
                                >
                                  <img src="images/Action-1.png" />
                                  Edit Help Message
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/dropdown-delete.png" />
                                  Delete Help Message
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                  Enable Help Message
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                  Disable Help Message
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>/ClientView/Home</td>
                    <td>07/12/2023</td>
                    <td>Sysadmin 1</td>
                    <td>
                      <div class="TextRed">Disabled</div>
                    </td>
                    <td>
                      <div class="TableThreeDots text-center">
                        <ul class="">
                          <li class="dropdown">
                            <a
                              class=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol class="dropdown-menu dropdown-menuwidth">
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/work-order-black.png" />
                                  View Help Message
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0;)"
                                  data-toggle="modal"
                                  data-target="#EditHelpMessage"
                                >
                                  <img src="images/Action-1.png" />
                                  Edit Help Message
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/dropdown-delete.png" />
                                  Delete Help Message
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                  Enable Help Message
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                  Disable Help Message
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="PaginationArea">
              <h6>Showing 1 to 10 of 27 entries</h6>
              <h6>
                <img src="images/leftarrow.png" /> <span>01</span>{" "}
                <img src="images/rightarrow.png" /> Out 10
              </h6>
            </div>
          </div>

          <IbuldAdminSubscription />

          <div class="tab-pane fade" id="CustomerSupportTab">
            <div class="Tableheader">
              <div class="d-flex align-items-center">
                <h6>Support Requests</h6>
                <div class="TodosButtons mt-0 d-flex">
                  <a href="javascript:void(0)" class="">
                    <img src="images/hide_display.png" />
                    Hide Solved/Cancelled
                  </a>
                </div>
              </div>
              <div class="d-flex">
                <div class="TodosButtons mt-0 d-flex">
                  <a
                    href="javascript:void(0)"
                    data-toggle="modal"
                    data-target="#CreateSupportRequestCommonView"
                  >
                    <img src="images/create_new_support.png" />
                    Create New Support Request
                  </a>
                </div>
                <div class="NewActions">
                  <a href="javascript:void(0);" title="pdf">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="javascript:void(0);" title="download">
                    <img src="images/download.png" />
                  </a>
                  <a href="javascript:void(0);" title="settings">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
            </div>

            <div class="TableList NewTableList TableListHeader TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Client</th>
                    <th>Company</th>
                    <th>Type</th>
                    <th>Module</th>
                    <th>Description</th>
                    <th>Problem Start Date</th>
                    <th>Severity</th>
                    <th>Error Message</th>
                    <th>Application Link</th>
                    <th>Supporting Documents</th>
                    <th>Status</th>
                    <th>Assigned To</th>
                    <th>Creation Date</th>
                    <th>Last Update</th>
                    <th>Solution</th>
                    <th>Root Cause</th>
                    <th>Bug ID</th>
                    <th>Messages</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="PendingConfirmation">
                    <td>SR7C002</td>
                    <td>Jane Client</td>
                    <td>Builder 7</td>
                    <td>Question</td>
                    <td>Sales</td>
                    <td>Help with lead survey</td>
                    <td></td>
                    <td>4- No Impact</td>
                    <td></td>
                    <td></td>
                    <td>
                      <div class="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#AddNewSupportingDocs2"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0)"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList"
                        >
                          <span class="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>New</td>
                    <td></td>
                    <td>
                      <div class="text-black">08/03/2023</div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <div class="Actions">
                        <a href="javascript:void(0);">
                          <img src="images/chat_icon.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <span class="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div class="TableThreeDots text-center">
                        <ul class="">
                          <li class="dropdown">
                            <a
                              class=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol class="dropdown-menu dropdown-menuwidth2">
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/view-po.png" />
                                  View Support Request
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/Action-1.png" />
                                  Update Support Request
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/timer.png" />
                                  View History
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>SR1C020</td>
                    <td>Jane Client</td>
                    <td>Builder 1</td>
                    <td>Problem</td>
                    <td>Estimation</td>
                    <td>QR not sent to specific vendor</td>
                    <td>08/03/2023 - 09:00 AM UTC -07:00) Mountain Time</td>
                    <td>3- Minor Impact</td>
                    <td>No budget available</td>
                    <td>
                      http://Ibuildresidential.com/Estimation/
                      Company=1C&amp;QR=QR1234
                    </td>
                    <td>
                      <div class="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#AddNewSupportingDocs2"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0)"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList"
                        >
                          <span class="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>In Progress</td>
                    <td>Sysadmin 1</td>
                    <td>08/03/2023</td>
                    <td>08/03/2023</td>
                    <td></td>
                    <td>Bug</td>
                    <td>Bug 35</td>
                    <td>
                      <div class="Actions">
                        <a href="javascript:void(0);">
                          <img src="images/chat_icon.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <span class="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div class="TableThreeDots text-center">
                        <ul class="">
                          <li class="dropdown">
                            <a
                              class=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol class="dropdown-menu dropdown-menuwidth2">
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/view-po.png" />
                                  View Support Request
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/Action-1.png" />
                                  Update Support Request
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/timer.png" />
                                  View History
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>SR3C003</td>
                    <td>Jane Client</td>
                    <td>Builder 3</td>
                    <td>Problem</td>
                    <td>Warranty</td>
                    <td>Warranty Request Status update error</td>
                    <td>08/03/2023 - 09:00 AM UTC -07:00) Mountain Time</td>
                    <td>1- Major Impact</td>
                    <td>Http 500 - Internal Server Error</td>
                    <td>http://Ibuildresidential.com/Warranty/ Company=3C</td>
                    <td>
                      <div class="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#AddNewSupportingDocs2"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0)"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList"
                        >
                          <span class="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>Solved</td>
                    <td>Sysadmin 1</td>
                    <td>08/03/2023</td>
                    <td>08/03/2023</td>
                    <td>Restart of 2 servers</td>
                    <td>Infrastructure</td>
                    <td></td>
                    <td>
                      <div class="Actions">
                        <a href="javascript:void(0);">
                          <img src="images/chat_icon.png" />
                        </a>
                        <a href="javascript:void(0);">
                          <span class="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div class="TableThreeDots text-center">
                        <ul class="">
                          <li class="dropdown">
                            <a
                              class=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol class="dropdown-menu dropdown-menuwidth2">
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/view-po.png" />
                                  View Support Request
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/Action-1.png" />
                                  Update Support Request
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/timer.png" />
                                  View History
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>SR4C015</td>
                    <td>Jane Client</td>
                    <td>Builder 4</td>
                    <td>Problem</td>
                    <td>Construction</td>
                    <td>Gantt Chart is not linking tasks properly</td>
                    <td>08/03/2023 - 09:00 AM UTC -07:00) Mountain Time</td>
                    <td>2 - Significant Impact</td>
                    <td>No error message, function just is not working</td>
                    <td>
                      http://Ibuildresidential.com/ Construction/
                      Company=1C&amp;QR=QR1234
                    </td>
                    <td>
                      <div class="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#AddNewSupportingDocs2"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0)"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList"
                        >
                          <span class="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>Solved</td>
                    <td>Sysadmin 1</td>
                    <td>08/03/2023</td>
                    <td>08/03/2023</td>
                    <td>Bug correction implemented on 05/14/20 23</td>
                    <td>Bug</td>
                    <td>Bug 36</td>
                    <td>
                      <div class="Actions">
                        <a href="javascript:void(0);">
                          <img src="images/chat_icon.png" />
                        </a>
                        <a href="javascript:void(0)">
                          <span class="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div class="TableThreeDots text-center">
                        <ul class="">
                          <li class="dropdown">
                            <a
                              class=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol class="dropdown-menu dropdown-menuwidth2">
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/view-po.png" />
                                  View Support Request
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/Action-1.png" />
                                  Update Support Request
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/timer.png" />
                                  View History
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="PaginationArea">
              <h6>Showing 1 to 10 of 27 entries</h6>
              <h6>
                <img src="images/leftarrow.png" /> <span>01</span>{" "}
                <img src="images/rightarrow.png" /> Out 10
              </h6>
            </div>
          </div>
          <div class="tab-pane fade" id="ChatMessageTab">
            <div class="Tableheader">
              <div class="d-flex align-items-center">
                <h6>Homepage Chat Messages</h6>
                <div class="TodosButtons mt-0 d-flex">
                  <a href="javascript:void(0)" class="">
                    <img src="images/hide_display.png" />
                    Hide Solved/Cancelled
                  </a>
                </div>
              </div>
              <div class="d-flex">
                <div class="TodosButtons mt-0 d-flex">
                  <a href="javascript:void(0)" class="">
                    <img src="images/unread_message.png" />
                    Unread Messages Only
                  </a>
                </div>
                <div class="NewActions">
                  <a href="javascript:void(0);" title="pdf">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="javascript:void(0);" title="download">
                    <img src="images/download.png" />
                  </a>
                  <a href="javascript:void(0);" title="settings">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
            </div>

            <div class="TableList NewTableList TableListHeader TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Chat id</th>
                    <th>Client Name</th>
                    <th>Client Email</th>
                    <th>Status</th>
                    <th>Assigned to</th>
                    <th>Unread Message</th>
                    <th>Supporting Documents</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>CH-50</td>
                    <td>Client 1</td>
                    <td>Client1@email.com</td>
                    <td>Active</td>
                    <td>Sysadmin 1</td>
                    <td>0</td>
                    <td>
                      <div class="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#ViewSupportingDocuments"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a href="javascript:void(0)">
                          <span class="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div class="TableThreeDots text-center">
                        <ul class="">
                          <li class="dropdown">
                            <a
                              class=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol class="dropdown-menu dropdown-menuwidth2">
                              <li>
                                <a
                                  href="javascript:void(0;)"
                                  class="FolderPermissionId"
                                  data-toggle="modal"
                                  data-target="#LiveChat"
                                >
                                  <img src="images/connect.png" />
                                  Connect to Chat
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0;)"
                                  class="FolderPermissionId"
                                  data-toggle="modal"
                                  data-target="#AssignAdmin"
                                >
                                  <img src="images/assign_icon.png" />
                                  Assign an Admin to chat
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0;)"
                                  data-toggle="modal"
                                  data-target="#ViewSupportingDocuments"
                                >
                                  <img src="images/icon-63.png" />
                                  View Supporting Documents
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/timer.png" />
                                  View Chat History
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/end_chat.png" />
                                  End Chat
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>CH-50</td>
                    <td>Client 1</td>
                    <td>Client1@email.com</td>
                    <td>Active</td>
                    <td>
                      <div class="Action">
                        <a href="javascript:void(0);">
                          <img src="images/assign_icon.png" />
                        </a>
                      </div>
                    </td>
                    <td>
                      <div class="TextRed">2</div>
                    </td>
                    <td>
                      <div class="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#ViewSupportingDocuments"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0)"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList"
                        >
                          <span class="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div class="TableThreeDots text-center">
                        <ul class="">
                          <li class="dropdown">
                            <a
                              class=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol class="dropdown-menu dropdown-menuwidth2">
                              <li>
                                <a
                                  href="javascript:void(0;)"
                                  class="FolderPermissionId"
                                  data-toggle="modal"
                                  data-target="#LiveChat"
                                >
                                  <img src="images/connect.png" />
                                  Connect to Chat
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0;)"
                                  class="FolderPermissionId"
                                  data-toggle="modal"
                                  data-target="#AssignAdmin"
                                >
                                  <img src="images/assign_icon.png" />
                                  Assign an Admin to chat
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0;)"
                                  data-toggle="modal"
                                  data-target="#ViewSupportingDocuments"
                                >
                                  <img src="images/icon-63.png" />
                                  View Supporting Documents
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/timer.png" />
                                  View Chat History
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/end_chat.png" />
                                  End Chat
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>CH-50</td>
                    <td>Client 1</td>
                    <td>Client1@email.com</td>
                    <td>Active</td>
                    <td>Sysadmin 1</td>
                    <td>
                      <div class="TextRed">4</div>
                    </td>
                    <td>
                      <div class="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#ViewSupportingDocuments"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0)"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList"
                        >
                          <span class="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div class="TableThreeDots text-center">
                        <ul class="">
                          <li class="dropdown">
                            <a
                              class=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol class="dropdown-menu dropdown-menuwidth2">
                              <li>
                                <a
                                  href="javascript:void(0;)"
                                  class="FolderPermissionId"
                                  data-toggle="modal"
                                  data-target="#LiveChat"
                                >
                                  <img src="images/connect.png" />
                                  Connect to Chat
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0;)"
                                  class="FolderPermissionId"
                                  data-toggle="modal"
                                  data-target="#AssignAdmin"
                                >
                                  <img src="images/assign_icon.png" />
                                  Assign an Admin to chat
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0;)"
                                  data-toggle="modal"
                                  data-target="#ViewSupportingDocuments"
                                >
                                  <img src="images/icon-63.png" />
                                  View Supporting Documents
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/timer.png" />
                                  View Chat History
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/end_chat.png" />
                                  End Chat
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>CH-50</td>
                    <td>Client 1</td>
                    <td>Client1@email.com</td>
                    <td>Active</td>
                    <td>Sysadmin 1</td>
                    <td>0</td>
                    <td>
                      <div class="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#ViewSupportingDocuments"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0)"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList"
                        >
                          <span class="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div class="TableThreeDots text-center">
                        <ul class="">
                          <li class="dropdown">
                            <a
                              class=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol class="dropdown-menu dropdown-menuwidth2">
                              <li>
                                <a
                                  href="javascript:void(0;)"
                                  class="FolderPermissionId"
                                  data-toggle="modal"
                                  data-target="#LiveChat"
                                >
                                  <img src="images/connect.png" />
                                  Connect to Chat
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0;)"
                                  class="FolderPermissionId"
                                  data-toggle="modal"
                                  data-target="#AssignAdmin"
                                >
                                  <img src="images/assign_icon.png" />
                                  Assign an Admin to chat
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0;)"
                                  class="FolderPermissionId"
                                  data-toggle="modal"
                                  data-target="#ViewSupportingDocuments"
                                >
                                  <img src="images/icon-63.png" />
                                  View Supporting Documents
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/timer.png" />
                                  View Chat History
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0;)">
                                  <img src="images/end_chat.png" />
                                  End Chat
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="PaginationArea">
              <h6>Showing 1 to 10 of 27 entries</h6>
              <h6>
                <img src="images/leftarrow.png" /> <span>01</span>{" "}
                <img src="images/rightarrow.png" /> Out 10
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>    )
}

export default IbuildAdminTab