import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUnitStep2 } from "../../../redux/action/proformaAction";

const OptionsInput = ({
  handleUnitOptionChange,
  updateId,
  handleShowComponents,
  handleShowComponent,
}) => {
  const dispatch = useDispatch();
  const [unitOption, setUnitOption] = useState([]);
  useEffect(() => {
    dispatch(getUnitStep2(updateId)).then((res) => {
      setUnitOption(res?.data?.data);
    });
  }, []);
  return (
    <>
      <div className="ModalBigheading pl-4 mb-3">
        <h3 className="mb-0">
          <span>Unit Options Input</span>
        </h3>
      </div>
      <div className="ProformaAccordion">
        <div className="accordion" id="accordionExample">
          {unitOption?.length > 0 &&
            unitOption?.map((datta, ind) => {
              return (
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left"
                        type="button"
                        data-toggle="collapse"
                        data-target="#AttachedGarages"
                        aria-expanded="true"
                        aria-controls="AttachedGarages"
                      >
                        {datta.name}
                      </button>
                    </h2>
                  </div>
                  <div
                    id="AttachedGarages"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body py-0">
                      <div className="EnterProjectTable">
                        <table>
                          <thead>
                            <tr>
                              <th />
                              <th>Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>totalSqFt</td>
                              <td>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control width-250"
                                    placeholder="Insert Sq/Ft"
                                    name="totalSqFt"
                                    value={datta.totalSqFt}
                                    onChange={(e) =>
                                      handleUnitOptionChange(e, ind)
                                    }
                                  />
                                </div>
                              </td>
                            </tr>

                            {datta.name != "Basement Development" && (
                              <tr>
                                <td>totalCubicYards</td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control width-250"
                                      placeholder="Insert cubic yards"
                                      name="totalCubicYards"
                                      value={datta.totalCubicYards}
                                      onChange={(e) =>
                                        handleUnitOptionChange(e, ind)
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            )}
                            {datta.name ===
                              "Detached Garages / Project Common Garages Costs" && (
                              <tr>
                                <td>perimeterLinFt</td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control width-250"
                                      placeholder="Insert cubic yards"
                                      name="perimeterLinFt"
                                      value={datta.perimeterLinFt}
                                      onChange={(e) =>
                                        handleUnitOptionChange(e, ind)
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="Button d-flex  justify-content-between align-items-center px-5">
        <button
          className="Outline border text-success border-success"
          onClick={() => handleShowComponents("unitInput")}
        >
          Cancel
        </button>
        <button
          className="Create bg-success border border-success"
          onClick={() => handleShowComponents("unitQuanties")}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default OptionsInput;
