import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCommonBuildingStep2 } from "../../../redux/action/proformaAction";

const CommonBuildingQuantities = ({
  unitList,
  handleQuantityChange,
  handleShowComponents,
}) => {
  const dispatch = useDispatch([]);
  const [commonBuildingQuantities, setCommonBuildingQuantities] = useState([]);
  useEffect(() => {
    dispatch(getCommonBuildingStep2()).then((res) => {
      console.log({ res });
      setCommonBuildingQuantities(res?.data?.data);
    });
  }, []);
  return (
    <>
      <div>
        <div className="ModalBigheading pl-4 mb-3 d-flex justify-content-between align-items-center">
          <h3 className="mb-0">
            <span>Block Quantities</span>
          </h3>
          <h6>
            <img src={require("../../../assets/images/book-search.png")} />
            Customize Base Costs
          </h6>
        </div>
        <div className="ProformaAccordion">
          <div className="accordion" id="accordionExample">
            {commonBuildingQuantities?.length > 0 &&
              commonBuildingQuantities?.map((item, ind) => {
                return (
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#CommonBuildingCosts"
                          aria-expanded="true"
                          aria-controls="CommonBuildingCosts"
                        >
                          {item.name}
                        </button>
                      </h2>
                      <a href="javascript:void(0);">
                        <img
                          src={require("../../../assets/images/book-search.png")}
                        />
                      </a>
                    </div>
                    <div
                      id="CommonBuildingCosts"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body py-0">
                        <div className="EnterProjectTable GreyTd">
                          <table>
                            <thead>
                              <tr>
                                <th>Code Code #</th>
                                <th>Description</th>
                                <th>Unit of measure</th>
                                <th>Site Office</th>
                                <th>Rec Center</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item?.ProformaBaseCosts?.length > 0 &&
                                item?.ProformaBaseCosts?.map((datas, i) => {
                                  return (
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>{datas.CostCode?.number}</td>
                                      <td>
                                        <div className="CommonSelectBox width-250">
                                          <select
                                          // value={item.foundationType}
                                          // onChange={(e) => handleBuildingFieldChange(e, index, 'foundationType')}
                                          >
                                            <option value="">
                                              Select Foundation Type
                                            </option>
                                            {unitList?.length > 0 &&
                                              unitList?.map((data, i) => {
                                                return (
                                                  <option
                                                    key={i}
                                                    value={data.id}
                                                  >
                                                    {data.name}
                                                  </option>
                                                );
                                              })}
                                          </select>
                                          {/* <span>
                                                        <img src="images/down.png" />
                                                      </span> */}
                                        </div>
                                      </td>
                                      {/* {
                                                        item.data[1].ProformaBaseCosts[0].ProformaBlockQuantities[0].quantity
                                                      } */}
                                      {datas?.ProformaBlockQuantities?.length >
                                        0 &&
                                        datas?.ProformaBlockQuantities?.map(
                                          (block, blocId) => {
                                            return (
                                              <td key={blocId}>
                                                <div className="form-group">
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Insert Quality"
                                                    name="quantity"
                                                    value={block.quantity}
                                                    onChange={(e) =>
                                                      handleQuantityChange(
                                                        e,
                                                        ind,
                                                        i,
                                                        blocId
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </td>
                                            );
                                          }
                                        )}
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="Button d-flex  justify-content-between align-items-center px-5">
          <button
            className="Outline border text-success border-success"
            onClick={() => handleShowComponents("commonBuildingUnit")}
          >
            Cancel
          </button>
          <button
            className="Create bg-success border border-success"
            onClick={() => handleShowComponents("commonBuildingSummeryPreview")}
          >
            Save Draft
          </button>
        </div>
      </div>
    </>
  );
};

export default CommonBuildingQuantities;
