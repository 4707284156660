
import { adminConstructionConstant } from "../actionTypes";

import axios from "axios";
import { Url } from "../../Config/Config";
import { isLoggedIn } from "../../utils/tokenCheck";


export const addProjectProfileInitiate = () => ({
    type: adminConstructionConstant.ADD_PROJECT_PROFILE_INITIATE,
  });
  
  export const addProjectProfileSuccess = (data) => ({
    type: adminConstructionConstant.ADD_PROJECT_PROFILE_SUCCESS,
    payload: data,
  });
  
  export const addProjectProfileFailure = (data) => ({
    type: adminConstructionConstant.ADD_PROJECT_PROFILE_FAILURE,
    payload: data,
  });
  
  export const AddProjectProfileAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(addProjectProfileInitiate());
      const response = await axios.post(
        `${Url}/admin/construction/createProjectType`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
        if (response.status == 200) {
        dispatch(addProjectProfileSuccess(response.data));
      } else {
        dispatch(addProjectProfileFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addProjectProfileFailure(err));
      return err.response;
    }
  };
  









  export const ProjectProfileListInitiate = () => ({
    type: adminConstructionConstant.PROJECT_PROFILE_LIST_INITIATE,
  });
  
  export const ProjectProfileListSuccess = (data) => ({
    type: adminConstructionConstant.PROJECT_PROFILE_LIST_SUCCESS,
    payload: data,
  });
  
  export const ProjectProfileListFailure = (data) => ({
    type: adminConstructionConstant.PROJECT_PROFILE_LIST_FAILURE,
    payload: data,
  });
  
  export const ProjectProfileListAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(ProjectProfileListInitiate());
      const response = await axios.get(`${Url}/admin/construction/projectTypeList`, {
        headers: { Authorization: `Bearer ${token}` },
        params: payload,
      });
      if (response.status == 201) {
        dispatch(ProjectProfileListSuccess(response.data));
      } else {
        dispatch(ProjectProfileListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(ProjectProfileListFailure(err));
      return err.response;
    }
  };
  



  
  export const ProjectProfileDetailsInitiate = () => ({
    type: adminConstructionConstant.PROJECT_PROFILE_DETAILS_INITIATE,
  });
  
  export const ProjectProfileDetailsSuccess = (data) => ({
    type: adminConstructionConstant.PROJECT_PROFILE_DETAILS_SUCCESS,
    payload: data,
  });
  
  export const ProjectProfileDetailsFailure = (data) => ({
    type: adminConstructionConstant.PROJECT_PROFILE_DETAILS_FAILURE,
    payload: data,
  });
  
  export const ProjectProfileDetailsAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(ProjectProfileDetailsInitiate());
      const response = await axios.get(`${Url}/admin/construction/viewProjectType/${payload}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(ProjectProfileDetailsSuccess(response.data));
      } else {
        dispatch(ProjectProfileDetailsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(ProjectProfileDetailsFailure(err));
      return err.response;
    }
  };
  


  export const SearchProjectProfileListInitiate = () => ({
    type: adminConstructionConstant.PROJECT_PROFILE_LIST_INITIATE,
  });
  
  export const SearchProjectProfileListSuccess = (data) => ({
    type: adminConstructionConstant.PROJECT_PROFILE_LIST_SUCCESS,
    payload: data,
  });
  
  export const SearchProjectProfileListFailure = (data) => ({
    type: adminConstructionConstant.PROJECT_PROFILE_LIST_FAILURE,
    payload: data,
  });
  
  export const SearchProjectProfileListAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(SearchProjectProfileListInitiate());
      const response = await axios.get(`${Url}/admin/construction/searchProjectType`, {
        headers: { Authorization: `Bearer ${token}` },
        params: payload,
      });
      if (response.status == 201) {
        dispatch(SearchProjectProfileListSuccess(response.data));
      } else {
        dispatch(SearchProjectProfileListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(SearchProjectProfileListFailure(err));
      return err.response;
    }
  };
  




  export const DeleteProjectProfileInitiate = () => ({
    type: adminConstructionConstant.DELETE_PROJECT_PROFILE_INITIATE,
  });
  
  export const DeleteProjectProfileSuccess = (data) => ({
    type: adminConstructionConstant.DELETE_PROJECT_PROFILE_SUCCESS,
    payload: data,
  });
  
  export const DeleteProjectProfileFailure = (data) => ({
    type: adminConstructionConstant.DELETE_PROJECT_PROFILE_FAILURE,
    payload: data,
  });
  
  export const DeleteProjectProfileAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(DeleteProjectProfileInitiate());
      const response = await axios.delete(`${Url}/admin/construction/deleteProjectType/${payload}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(DeleteProjectProfileSuccess(response.data));
      } else {
        dispatch(DeleteProjectProfileFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(DeleteProjectProfileFailure(err));
      return err.response;
    }
  };
  


  export const UpdateProjectProfileInitiate = () => ({
    type: adminConstructionConstant.UPDATE_PROJECT_PROFILE_INITIATE,
  });
  
  export const UpdateProjectProfileSuccess = (data) => ({
    type: adminConstructionConstant.UPDATE_PROJECT_PROFILE_SUCCESS,
    payload: data,
  });
  
  export const UpdateProjectProfileFailure = (data) => ({
    type: adminConstructionConstant.UPDATE_PROJECT_PROFILE_FAILURE,
    payload: data,
  });
  
  export const UpdateProjectProfileAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(UpdateProjectProfileInitiate());
      const response = await axios.put(
        `${Url}/admin/construction/editProjectType`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
        if (response.status == 200) {
        dispatch(UpdateProjectProfileSuccess(response.data));
      } else {
        dispatch(UpdateProjectProfileFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(UpdateProjectProfileFailure(err));
      return err.response;
    }
  };





  export const addConstructionCategoryInitiate = () => ({
    type: adminConstructionConstant.ADD_CONSTRUCTION_CATEGORY_INITIATE,
  });
  
  export const addConstructionCategorySuccess = (data) => ({
    type: adminConstructionConstant.ADD_CONSTRUCTION_CATEGORY_SUCCESS,
    payload: data,
  });
  
  export const addConstructionCategoryFailure = (data) => ({
    type: adminConstructionConstant.ADD_CONSTRUCTION_CATEGORY_FAILURE,
    payload: data,
  });
  
  export const AddConstructionCategoryAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(addConstructionCategoryInitiate());
      const response = await axios.post(
        `${Url}/admin/construction/createProjectCategoty`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
        if (response.status == 200) {
        dispatch(addConstructionCategorySuccess(response.data));
      } else {
        dispatch(addConstructionCategoryFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addConstructionCategoryFailure(err));
      return err.response;
    }
  };
  









  export const ConstructionCategoryListInitiate = () => ({
    type: adminConstructionConstant.CONSTRUCTION_CATEGORY_LIST_INITIATE,
  });
  
  export const ConstructionCategoryListSuccess = (data) => ({
    type: adminConstructionConstant.CONSTRUCTION_CATEGORY_LIST_SUCCESS,
    payload: data,
  });
  
  export const ConstructionCategoryListFailure = (data) => ({
    type: adminConstructionConstant.CONSTRUCTION_CATEGORY_LIST_FAILURE,
    payload: data,
  });
  
  export const ConstructionCategoryListAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(ConstructionCategoryListInitiate());
      const response = await axios.get(`${Url}/admin/construction/systemPhaseGet`, {
        headers: { Authorization: `Bearer ${token}` },
        params: payload,
      });
      if (response.status == 201) {
        dispatch(ConstructionCategoryListSuccess(response.data));
      } else {
        dispatch(ConstructionCategoryListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(ConstructionCategoryListFailure(err));
      return err.response;
    }
  };
  



  
  export const ConstructionCategoryDetailsInitiate = () => ({
    type: adminConstructionConstant.CONSTRUCTION_CATEGORY_DETAILS_INITIATE,
  });
  
  export const ConstructionCategoryDetailsSuccess = (data) => ({
    type: adminConstructionConstant.CONSTRUCTION_CATEGORY_DETAILS_SUCCESS,
    payload: data,
  });
  
  export const ConstructionCategoryDetailsFailure = (data) => ({
    type: adminConstructionConstant.CONSTRUCTION_CATEGORY_DETAILS_FAILURE,
    payload: data,
  });
  
  export const ConstructionCategoryDetailsAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(ConstructionCategoryDetailsInitiate());
      const response = await axios.get(`${Url}/admin/construction/viewProjectType/${payload}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(ConstructionCategoryDetailsSuccess(response.data));
      } else {
        dispatch(ConstructionCategoryDetailsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(ConstructionCategoryDetailsFailure(err));
      return err.response;
    }
  };
  


  export const SearchConstructionCategoryListInitiate = () => ({
    type: adminConstructionConstant.SEARCH_CONSTRUCTION_CATEGORY_INITIATE,
  });
  
  export const SearchConstructionCategoryListSuccess = (data) => ({
    type: adminConstructionConstant.SEARCH_CONSTRUCTION_CATEGORY_SUCCESS,
    payload: data,
  });
  
  export const SearchConstructionCategoryListFailure = (data) => ({
    type: adminConstructionConstant.SEARCH_CONSTRUCTION_CATEGORY_FAILURE,
    payload: data,
  });
  
  export const SearchConstructionCategoryAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(SearchConstructionCategoryListInitiate());
      const response = await axios.get(`${Url}/admin/construction/searchProjectCategory`, {
        headers: { Authorization: `Bearer ${token}` },
        params: payload,
      });
      if (response.status == 201) {
        dispatch(SearchConstructionCategoryListSuccess(response.data));
      } else {
        dispatch(SearchConstructionCategoryListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(SearchConstructionCategoryListFailure(err));
      return err.response;
    }
  };
  




  export const DeleteConstructionCategoryInitiate = () => ({
    type: adminConstructionConstant.DELETE_CONSTRUCTION_CATEGORY_INITIATE,
  });
  
  export const DeleteConstructionCategorySuccess = (data) => ({
    type: adminConstructionConstant.DELETE_CONSTRUCTION_CATEGORY_SUCCESS,
    payload: data,
  });
  
  export const DeleteConstructionCategoryFailure = (data) => ({
    type: adminConstructionConstant.DELETE_CONSTRUCTION_CATEGORY_FAILURE,
    payload: data,
  });
  
  export const DeleteConstructionCategoryAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(DeleteConstructionCategoryInitiate());
      const response = await axios.delete(`${Url}/admin/construction/deleteProjectCategory/${payload}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(DeleteConstructionCategorySuccess(response.data));
      } else {
        dispatch(DeleteConstructionCategoryFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(DeleteConstructionCategoryFailure(err));
      return err.response;
    }
  };
  


  export const UpdateConstructionCategoryInitiate = () => ({
    type: adminConstructionConstant.UPDATE_CONSTRUCTION_CATEGORY_INITIATE,
  });
  
  export const UpdateConstructionCategorySuccess = (data) => ({
    type: adminConstructionConstant.UPDATE_CONSTRUCTION_CATEGORY_SUCCESS,
    payload: data,
  });
  
  export const UpdateConstructionCategoryFailure = (data) => ({
    type: adminConstructionConstant.UPDATE_CONSTRUCTION_CATEGORY_FAILURE,
    payload: data,
  });
  
  export const UpdateConstructionCategoryAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(UpdateConstructionCategoryInitiate());
      const response = await axios.put(
        `${Url}/admin/construction/editProjectCatgory`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
        if (response.status == 200) {
        dispatch(UpdateConstructionCategorySuccess(response.data));
      } else {
        dispatch(UpdateConstructionCategoryFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(UpdateConstructionCategoryFailure(err));
      return err.response;
    }
  };
  


  







  export const addFromMasterInitiate = () => ({
    type: adminConstructionConstant.ADD_CONSTRUCTION_CATEGORY_INITIATE,
  });
  
  export const addFromMasterSuccess = (data) => ({
    type: adminConstructionConstant.ADD_CONSTRUCTION_CATEGORY_SUCCESS,
    payload: data,
  });
  
  export const addFromMasterFailure = (data) => ({
    type: adminConstructionConstant.ADD_CONSTRUCTION_CATEGORY_FAILURE,
    payload: data,
  });
  
  export const AddFromMasterAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(addFromMasterInitiate());
      const response = await axios.post(
        `${Url}/admin/construction/createMasterProjectCategory`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
        if (response.status == 200) {
        dispatch(addFromMasterSuccess(response.data));
      } else {
        dispatch(addFromMasterFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addFromMasterFailure(err));
      return err.response;
    }
  };
  





  export const addMasterProjectCategoryInitiate = () => ({
    type: adminConstructionConstant.ADD_MASTER_CATEGORY_INITIATE,
  });
  
  export const addMasterProjectCategorySuccess = (data) => ({
    type: adminConstructionConstant.ADD_MASTER_CATEGORY_INITIATE,
    payload: data,
  });
  
  export const addMasterProjectCategoryFailure = (data) => ({
    type: adminConstructionConstant.ADD_MASTER_CATEGORY_FAILURE,
    payload: data,
  });
  
  export const AddMasterProjectCategoryAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(addMasterProjectCategoryInitiate());
      const response = await axios.post(
        `${Url}/admin/construction/createMasterProjectCategory`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
        if (response.status == 200) {
        dispatch(addMasterProjectCategorySuccess(response.data));
      } else {
        dispatch(addMasterProjectCategoryFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addMasterProjectCategoryFailure(err));
      return err.response;
    }
  };
  



  export const updateMasterProjectCategoryInitiate = () => ({
    type: adminConstructionConstant.UPDATE_MASTER_CATEGORY_INITIATE,
  });
  
  export const updateMasterProjectCategorySuccess = (data) => ({
    type: adminConstructionConstant.UPDATE_MASTER_CATEGORY_SUCCESS,
    payload: data,
  });
  
  export const updateMasterProjectCategoryFailure = (data) => ({
    type: adminConstructionConstant.UPDATE_MASTER_CATEGORY_FAILURE,
    payload: data,
  });
  
  export const UpdateMasterProjectCategoryAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(updateMasterProjectCategoryInitiate());
      const response = await axios.put(
        `${Url}/admin/construction/editMasterProjectCatgory`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
        if (response.status == 200) {
        dispatch(updateMasterProjectCategorySuccess(response.data));
      } else {
        dispatch(updateMasterProjectCategoryFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateMasterProjectCategoryFailure(err));
      return err.response;
    }
  };
  


  export const MasterConstructionCategoryListInitiate = () => ({
    type: adminConstructionConstant.MASTER_CATEGORY_LIST_INITIATE,
  });
  
  export const MasterConstructionCategoryListSuccess = (data) => ({
    type: adminConstructionConstant.MASTER_CATEGORY_LIST_SUCCESS,
    payload: data,
  });
  
  export const MasterConstructionCategoryListFailure = (data) => ({
    type: adminConstructionConstant.MASTER_CATEGORY_LIST_FAILURE,
    payload: data,
  });
  
  export const MasterConstructionCategoryListAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(MasterConstructionCategoryListInitiate());
      const response = await axios.get(`${Url}/admin/construction/masterProjectTypeCatgoryList`, {
        headers: { Authorization: `Bearer ${token}` },
        params: payload,
      });
      if (response.status == 201) {
        dispatch(MasterConstructionCategoryListSuccess(response.data));
      } else {
        dispatch(MasterConstructionCategoryListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(MasterConstructionCategoryListFailure(err));
      return err.response;
    }
  };
  



  
  export const MasterConstructionCategoryDetailsInitiate = () => ({
    type: adminConstructionConstant.MASTER_CATEGORY_DETAILS_INITIATE,
  });
  
  export const MasterConstructionCategoryDetailsSuccess = (data) => ({
    type: adminConstructionConstant.MASTER_CATEGORY_DETAILS_SUCCESS,
    payload: data,
  });
  
  export const MasterConstructionCategoryDetailsFailure = (data) => ({
    type: adminConstructionConstant.MASTER_CATEGORY_DETAILS_FAILURE,
    payload: data,
  });
  
  export const MasterConstructionCategoryDetailsAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(MasterConstructionCategoryDetailsInitiate());
      const response = await axios.get(`${Url}/admin/construction/viewMasterProjectCatgory/${payload}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(MasterConstructionCategoryDetailsSuccess(response.data));
      } else {
        dispatch(MasterConstructionCategoryDetailsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(MasterConstructionCategoryDetailsFailure(err));
      return err.response;
    }
  };
  


  export const SearchMasterConstructionCategoryListInitiate = () => ({
    type: adminConstructionConstant.SEARCH_MASTER_CATEGORY_INITIATE,
  });
  
  export const SearchMasterConstructionCategoryListSuccess = (data) => ({
    type: adminConstructionConstant.SEARCH_MASTER_CATEGORY_SUCCESS,
    payload: data,
  });
  
  export const SearchMasterConstructionCategoryListFailure = (data) => ({
    type: adminConstructionConstant.SEARCH_MASTER_CATEGORY_FAILURE,
    payload: data,
  });
  
  export const SearchMasterConstructionCategoryAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(SearchMasterConstructionCategoryListInitiate());
      const response = await axios.get(`${Url}/admin/construction/searchMasterProjectCategory`, {
        headers: { Authorization: `Bearer ${token}` },
        params: payload,
      });
      if (response.status == 201) {
        dispatch(SearchMasterConstructionCategoryListSuccess(response.data));
      } else {
        dispatch(SearchMasterConstructionCategoryListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(SearchMasterConstructionCategoryListFailure(err));
      return err.response;
    }
  };
  




  export const DeleteMasterConstructionCategoryInitiate = () => ({
    type: adminConstructionConstant.DELETE_MASTER_CATEGORY_INITIATE,
  });
  
  export const DeleteMasterConstructionCategorySuccess = (data) => ({
    type: adminConstructionConstant.DELETE_MASTER_CATEGORY_SUCCESS,
    payload: data,
  });
  
  export const DeleteMasterConstructionCategoryFailure = (data) => ({
    type: adminConstructionConstant.DELETE_MASTER_CATEGORY_FAILURE,
    payload: data,
  });
  
  export const DeleteMasterConstructionCategoryAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(DeleteMasterConstructionCategoryInitiate());
      const response = await axios.delete(`${Url}/admin/construction/deleteMasterProjectCategory/${payload}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(DeleteMasterConstructionCategorySuccess(response.data));
      } else {
        dispatch(DeleteMasterConstructionCategoryFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(DeleteMasterConstructionCategoryFailure(err));
      return err.response;
    }
  };
  






  export const addMasterTaskInitiate = () => ({
    type: adminConstructionConstant.ADD_MASTER_CATEGORY_INITIATE,
  });
  
  export const addMasterTaskSuccess = (data) => ({
    type: adminConstructionConstant.ADD_MASTER_CATEGORY_INITIATE,
    payload: data,
  });
  
  export const addMasterTaskFailure = (data) => ({
    type: adminConstructionConstant.ADD_MASTER_CATEGORY_FAILURE,
    payload: data,
  });
  
  export const addMasterTaskAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(addMasterTaskInitiate());
      const response = await axios.post(
        `${Url}/admin/construction/createConstructionMasterTask`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
        if (response.status == 200) {
        dispatch(addMasterTaskSuccess(response.data));
      } else {
        dispatch(addMasterTaskFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addMasterTaskFailure(err));
      return err.response;
    }
  };
  



  export const updateMasterTaskInitiate = () => ({
    type: adminConstructionConstant.UPDATE_MASTER_CATEGORY_INITIATE,
  });
  
  export const updateMasterTaskSuccess = (data) => ({
    type: adminConstructionConstant.UPDATE_MASTER_CATEGORY_SUCCESS,
    payload: data,
  });
  
  export const updateMasterTaskFailure = (data) => ({
    type: adminConstructionConstant.UPDATE_MASTER_CATEGORY_FAILURE,
    payload: data,
  });
  
  export const UpdateMasterTaskAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(updateMasterTaskInitiate());
      const response = await axios.put(
        `${Url}/admin/construction/editConstructionTask`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
        if (response.status == 200) {
        dispatch(updateMasterTaskSuccess(response.data));
      } else {
        dispatch(updateMasterTaskFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateMasterTaskFailure(err));
      return err.response;
    }
  };
  


  export const MasterTaskListInitiate = () => ({
    type: adminConstructionConstant.GET_MASTER_CONSTRUCTION_TASK_INITIATE,
  });
  
  export const MasterTaskListSuccess = (data) => ({
    type: adminConstructionConstant.GET_MASTER_CONSTRUCTION_TASK_SUCCESS,
    payload: data,
  });
  
  export const MasterTaskListFailure = (data) => ({
    type: adminConstructionConstant.GET_MASTER_CONSTRUCTION_TASK_FAILURE,
    payload: data,
  });
  
  export const MasterTaskListAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(MasterTaskListInitiate());
      const response = await axios.get(`${Url}/admin/construction/constructionMasterTaskList`, {
        headers: { Authorization: `Bearer ${token}` },
        params: payload,
      });
      if (response.status == 201) {
        dispatch(MasterTaskListSuccess(response.data));
      } else {
        dispatch(MasterTaskListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(MasterTaskListFailure(err));
      return err.response;
    }
  };
  



  
  export const MasterTaskDetailsInitiate = () => ({
    type: adminConstructionConstant.MASTER_CATEGORY_DETAILS_INITIATE,
  });
  
  export const MasterTaskDetailsSuccess = (data) => ({
    type: adminConstructionConstant.MASTER_CATEGORY_DETAILS_SUCCESS,
    payload: data,
  });
  
  export const MasterTaskDetailsFailure = (data) => ({
    type: adminConstructionConstant.MASTER_CATEGORY_DETAILS_FAILURE,
    payload: data,
  });
  
  export const MasterTaskDetailsAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(MasterTaskDetailsInitiate());
      const response = await axios.get(`${Url}/admin/construction/viewConstructionTask/${payload}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(MasterTaskDetailsSuccess(response.data));
      } else {
        dispatch(MasterTaskDetailsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(MasterTaskDetailsFailure(err));
      return err.response;
    }
  };
  


  export const SearchMasterTaskInitiate = () => ({
    type: adminConstructionConstant.SEARCH_MASTER_CATEGORY_INITIATE,
  });
  
  export const SearchMasterTaskSuccess = (data) => ({
    type: adminConstructionConstant.SEARCH_MASTER_CATEGORY_SUCCESS,
    payload: data,
  });
  
  export const SearchMasterTaskFailure = (data) => ({
    type: adminConstructionConstant.SEARCH_MASTER_CATEGORY_FAILURE,
    payload: data,
  });
  
  export const SearchMasterTaskAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(SearchMasterTaskInitiate());
      const response = await axios.get(`${Url}/admin/construction/searchConstructionMasterTask`, {
        headers: { Authorization: `Bearer ${token}` },
        params: payload,
      });
      if (response.status == 201) {
        dispatch(SearchMasterTaskSuccess(response.data));
      } else {
        dispatch(SearchMasterTaskFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(SearchMasterTaskFailure(err));
      return err.response;
    }
  };
  




  export const DeleteMasterTaskInitiate = () => ({
    type: adminConstructionConstant.DELETE_MASTER_CATEGORY_INITIATE,
  });
  
  export const DeleteMasterTaskSuccess = (data) => ({
    type: adminConstructionConstant.DELETE_MASTER_CATEGORY_SUCCESS,
    payload: data,
  });
  
  export const DeleteMasterTaskFailure = (data) => ({
    type: adminConstructionConstant.DELETE_MASTER_CATEGORY_FAILURE,
    payload: data,
  });
  
  export const DeleteMasterTaskAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(DeleteMasterTaskInitiate());
      const response = await axios.delete(`${Url}/admin/construction/deleteConstructionTask/${payload}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(DeleteMasterTaskSuccess(response.data));
      } else {
        dispatch(DeleteMasterTaskFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(DeleteMasterTaskFailure(err));
      return err.response;
    }
  };
  



  export const addConstructionTaskInitiate = () => ({
    type: adminConstructionConstant.ADD_MASTER_CATEGORY_INITIATE,
  });
  
  export const addConstructionTaskSuccess = (data) => ({
    type: adminConstructionConstant.ADD_MASTER_CATEGORY_INITIATE,
    payload: data,
  });
  
  export const addConstructionTaskFailure = (data) => ({
    type: adminConstructionConstant.ADD_MASTER_CATEGORY_FAILURE,
    payload: data,
  });
  
  export const addConstructionTaskAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(addMasterTaskInitiate());
      const response = await axios.post(
        `${Url}/admin/construction/createConstructionTask`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
        if (response.status == 200) {
        dispatch(addConstructionTaskSuccess(response.data));
      } else {
        dispatch(addConstructionTaskFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addConstructionTaskFailure(err));
      return err.response;
    }
  };
  



  export const updateConstructionTaskInitiate = () => ({
    type: adminConstructionConstant.UPDATE_MASTER_CATEGORY_INITIATE,
  });
  
  export const updateConstructionTaskSuccess = (data) => ({
    type: adminConstructionConstant.UPDATE_MASTER_CATEGORY_SUCCESS,
    payload: data,
  });
  
  export const updateConstructionTaskFailure = (data) => ({
    type: adminConstructionConstant.UPDATE_MASTER_CATEGORY_FAILURE,
    payload: data,
  });
  
  export const UpdateConstructionTaskAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(updateMasterTaskInitiate());
      const response = await axios.put(
        `${Url}/admin/construction/editConstructionTask`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
        if (response.status == 200) {
        dispatch(updateConstructionTaskSuccess(response.data));
      } else {
        dispatch(updateConstructionTaskFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateConstructionTaskFailure(err));
      return err.response;
    }
  };
  


  export const ConstructionTaskListInitiate = () => ({
    type: adminConstructionConstant.GET_CONSTRUCTION_TASK_INITIATE,
  });
  
  export const ConstructionTaskListSuccess = (data) => ({
    type: adminConstructionConstant.GET_CONSTRUCTION_TASK_SUCCESS,
    payload: data,
  });
  
  export const ConstructionTaskListFailure = (data) => ({
    type: adminConstructionConstant.GET_CONSTRUCTION_TASK_FAILURE,
    payload: data,
  });
  
  export const ConstructionTaskListAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(ConstructionTaskListInitiate());
      const response = await axios.get(`${Url}/admin/construction/constructionTaskList`, {
        headers: { Authorization: `Bearer ${token}` },
        params: payload,
      });
      if (response.status == 201) {
        dispatch(ConstructionTaskListSuccess(response.data));
      } else {
        dispatch(ConstructionTaskListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(ConstructionTaskListFailure(err));
      return err.response;
    }
  };
  



  
  export const ConstructionTaskDetailsInitiate = () => ({
    type: adminConstructionConstant.MASTER_CATEGORY_DETAILS_INITIATE,
  });
  
  export const ConstructionTaskDetailsSuccess = (data) => ({
    type: adminConstructionConstant.MASTER_CATEGORY_DETAILS_SUCCESS,
    payload: data,
  });
  
  export const ConstructionTaskDetailsFailure = (data) => ({
    type: adminConstructionConstant.MASTER_CATEGORY_DETAILS_FAILURE,
    payload: data,
  });
  
  export const ConstructionTaskDetailsAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(ConstructionTaskDetailsInitiate());
      const response = await axios.get(`${Url}/admin/construction/viewConstructionTask/${payload}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(ConstructionTaskDetailsSuccess(response.data));
      } else {
        dispatch(ConstructionTaskDetailsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(ConstructionTaskDetailsFailure(err));
      return err.response;
    }
  };
  


  export const SearchConstructionTaskInitiate = () => ({
    type: adminConstructionConstant.SEARCH_MASTER_CATEGORY_INITIATE,
  });
  
  export const SearchConstructionTaskSuccess = (data) => ({
    type: adminConstructionConstant.SEARCH_MASTER_CATEGORY_SUCCESS,
    payload: data,
  });
  
  export const SearchConstructionTaskFailure = (data) => ({
    type: adminConstructionConstant.SEARCH_MASTER_CATEGORY_FAILURE,
    payload: data,
  });
  
  export const SearchConstructionTaskAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(SearchConstructionTaskInitiate());
      const response = await axios.get(`${Url}/admin/construction/searchConstructionTask`, {
        headers: { Authorization: `Bearer ${token}` },
        params: payload,
      });
      if (response.status == 201) {
        dispatch(SearchConstructionTaskSuccess(response.data));
      } else {
        dispatch(SearchConstructionTaskFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(SearchConstructionTaskFailure(err));
      return err.response;
    }
  };
  




  export const DeleteConstructionTaskInitiate = () => ({
    type: adminConstructionConstant.DELETE_MASTER_CATEGORY_INITIATE,
  });
  
  export const DeleteConstructionTaskSuccess = (data) => ({
    type: adminConstructionConstant.DELETE_MASTER_CATEGORY_SUCCESS,
    payload: data,
  });
  
  export const DeleteConstructionTaskFailure = (data) => ({
    type: adminConstructionConstant.DELETE_MASTER_CATEGORY_FAILURE,
    payload: data,
  });
  
  export const DeleteConstructionTaskAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(DeleteMasterTaskInitiate());
      const response = await axios.delete(`${Url}/admin/construction/deleteConstructionTask/${payload}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(DeleteConstructionTaskSuccess(response.data));
      } else {
        dispatch(DeleteConstructionTaskFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(DeleteConstructionTaskFailure(err));
      return err.response;
    }
  };
  





  
  export const addManualTaskInitiate = () => ({
    type: adminConstructionConstant.CREATE_MANUAL_TASK_INITIATE,
  });
  
  export const addManualTaskSuccess = (data) => ({
    type: adminConstructionConstant.CREATE_MANUAL_TASK_SUCCESS,
    payload: data,
  });
  
  export const addManualTaskFailure = (data) => ({
    type: adminConstructionConstant.CREATE_MANUAL_TASK_FAILURE,
    payload: data,
  });
  
  export const addManualTaskAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(addMasterTaskInitiate());
      const response = await axios.post(
        `${Url}/admin/construction/addTaskManually`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
        if (response.status == 200) {
        dispatch(addManualTaskSuccess(response.data));
      } else {
        dispatch(addManualTaskFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addManualTaskFailure(err));
      return err.response;
    }
  };
  








  
  export const addManualPhaseInitiate = () => ({
    type: adminConstructionConstant.CREATE_MANUAL_PHASE_INITIATE,
  });
  
  export const addManualPhaseSuccess = (data) => ({
    type: adminConstructionConstant.CREATE_MANUAL_PHASE_SUCCESS,
    payload: data,
  });
  
  export const addManualPhaseFailure = (data) => ({
    type: adminConstructionConstant.CREATE_MANUAL_PHASE_FAILURE,
    payload: data,
  });
  
  export const addManualPhaseAction = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("admin");
      dispatch(addManualPhaseInitiate());
      const response = await axios.post(
        `${Url}/admin/construction/addPhaseManually`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
        if (response.status == 200) {
        dispatch(addManualPhaseSuccess(response.data));
      } else {
        dispatch(addManualPhaseFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addManualPhaseFailure(err));
      return err.response;
    }
  };
  

