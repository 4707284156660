import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import "../App.css";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import {
  acceptPendingPurchase,
  acceptPo,
  acceptRejectEditPO,
  addDiscussionTradeView,
  addReplyTradeView,
  createUnit,
  editQr,
  getDiscussionListTradeView,
  getEditPOById,
  getEditQouteRequestById,
  getJobByProjectList,
  getProjectByBuilder,
  getPurchaseOrderList,
  getQuoteRequestList,
  getUnitList,
  getUnitQr,
  rejectPo,
  returnQuoteRequest,
  updateEditQouteRequestById,
  updateQuoteRequest,
  uploadAdditionalDocumentsQr,
} from "../redux/action/tradeViewAuthAction";
import Loader from "./Loader";
import TradeViewSidenav from "./trade-view-sidenav";
import TradeHeader from "./TradeHeader";
import moment from "moment";

const initialState = {
  QuoteRequestCostBreakdownModal: false,
  ViewQuoteRequestVendorModal: false,
  ViewQuoteRequestVendorData: [],
  itemDetails: [
    {
      parentTableName: "QUOTEREQUEST",
      color: "",
      quantity: null,
      unitId: null,
      price: "",
      total: "",
      name: "",
    },
  ],
  unitDataName: "",
  search: "",
  quoteId: "",
  jobCodeId: "",
  jobType: "",
  costCodeId: "",
  reasonId: "",
  subtotal: "",
  quoteRequestDetails: [],
  EditQuoteRequestCostBreakdownModal: false,
  createdAt: "",
  ViewAttachmentModal: false,
  ViewAttachmentData: [],

  companyId: "",
  projectId: "",
  jobId: "",
  PurchaseOrderCostBreakdownModal: false,
  PurchaseOrderCostBreakdownData: [],
  ViewPoModal:false,
  viewPoData:[],
  AddNewSupportingDocsModal: false,
  supportingDocFiless: null,
  date: "",
  poId:"",
  ViewAttachDataModal:false,
  ViewAttachData:"",
  qrNumber:"",
};

const PoqrTradeview = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const {
    QuoteRequestCostBreakdownModal,
    ViewQuoteRequestVendorModal,
    ViewQuoteRequestVendorData,
    itemDetails,
    unitDataName,
    search,
    quoteId,
    jobCodeId,
    jobType,
    costCodeId,
    reasonId,
    subtotal,
    quoteRequestDetails,
    EditQuoteRequestCostBreakdownModal,
    createdAt,
    ViewAttachmentModal,
    ViewAttachmentData,
    companyId,
    projectId,
    jobId,

    PurchaseOrderCostBreakdownModal,
    PurchaseOrderCostBreakdownData,
    ViewPoModal,
    viewPoData,
    AddNewSupportingDocsModal,
    supportingDocFiless,
    date,
    poId,
    ViewAttachDataModal,
    ViewAttachData,
    qrNumber
  } = iState;
  const compId = JSON.parse(window.localStorage.getItem("tradeViewLogin"));
  const {
    quoteRequestLoader,
    quoteRequestList,
    purchaseOrderLoader,
    purchaseOrderList,
    unitList,
    projectByBuilderData,
    jobByProjectData,
    discussionList,
    unitListQr,
  } = useSelector((state) => state.tradeViewLogin);
  // console.log("discussionList", discussionList);

  const builderIdd = JSON.parse(window.localStorage.getItem("builderId"));
  useEffect(() => {
    dispatch(getProjectByBuilder(builderIdd));
    dispatch(getUnitList());
  }, []);

  const handleQuoteRequestCostBreakdownModalShow = (item) => {
    updateState({
      ...iState,
      QuoteRequestCostBreakdownModal: true,
      quoteRequestDetails: item,
    });
  };



  const handleQuoteRequestCostBreakdownModalClose = () => {
    updateState({ ...iState, QuoteRequestCostBreakdownModal: false });
  };

  const handleViewQuoteRequestVendorModalShow = (item) => {
    updateState({
      ...iState,
      ViewQuoteRequestVendorModal: true,
      quoteId:item.id
    });
  };

  useEffect(()=>{
    if(ViewQuoteRequestVendorModal)
      {
         dispatch(getEditQouteRequestById(compId?.user?.CompanyId, compId?.user?.token, quoteId)).then(
      (res) => {
        if (res.status === 200) {
          updateState({
            ...iState,
            ViewQuoteRequestVendorData: res?.data?.data,
          });
        }
      }
    );
    dispatch(getUnitQr(compId?.user?.token));
      }
    
  },[ViewQuoteRequestVendorModal])

  const handleViewQuoteRequestVendorModalClose = () => {
    updateState({ ...iState, ViewQuoteRequestVendorModal: false });
  };

 

  const handleEditQuoteRequestCostBreakdownModalShow = (item) => {
    updateState({
      ...iState,
      EditQuoteRequestCostBreakdownModal: true,
      ViewQuoteRequestVendorData: item?.BuilderItems,
      quoteId: item.id,
      jobCodeId: item.jobCodeId,
      jobType: item.jobType,
      costCodeId: item.costCodeId,
      reasonId: item.Reason?.id,
      subtotal: item.subtotal,
    });
  };

  const handleEditQuoteRequestCostBreakdownModalClose = () => {
    updateState({ ...iState, EditQuoteRequestCostBreakdownModal: false });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "projectId") {
      dispatch(getJobByProjectList(builderIdd, value));
     
      updateState({
        ...iState,
        [name]: value,
        jobId: "",
      });
    } else if (name === "jobId") {
      dispatch(getQuoteRequestList(builderIdd));
      dispatch(getPurchaseOrderList(builderIdd));
      updateState({
        ...iState,
        [name]: value,
      });
    } else {
      updateState({
        ...iState,
        [name]: value,
      });
    }
  };

 

  const handleViewAttachmentModalShow = (data) => {
    updateState({
      ...iState,
      ViewAttachmentModal: true,
      ViewAttachmentData: data?.additionalDocuments,
      qrNumber: data?.documentCode
    });
  };

  const handleViewAttachmentModalClose = () => {
    updateState({ ...iState, ViewAttachmentModal: false });
  };

  const handleAcceptRejectPO = (companyId, PoId, type) => {
    if (type === "accept") {
      dispatch(acceptPo(companyId, PoId)).then((res) => {
        if (res.status === 200) {
          dispatch(getPurchaseOrderList(builderIdd));
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    } else {
      dispatch(rejectPo(companyId, PoId)).then((res) => {
        if (res.status === 200) {
          dispatch(getPurchaseOrderList(builderIdd));
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const handlePurchaseOrderCostBreakdownModalShow = (item) => {
    updateState({
      ...iState,
      PurchaseOrderCostBreakdownModal: true,
      PurchaseOrderCostBreakdownData: item,
    });
  };

  const handlePurchaseOrderCostBreakdownModalClose = () => {
    updateState({ ...iState, PurchaseOrderCostBreakdownModal: false });
  };

  const [selectedItem, setSelectedItem] = useState([]);

  const handleCheckboxChange = (event, item) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add the selected item to the array
      setSelectedItem((prevSelectedItems) => [...prevSelectedItems, item]);
    } else {
      // Remove the item from the array
      setSelectedItem((prevSelectedItems) =>
        prevSelectedItems.filter((selectedItem) => selectedItem !== item)
      );
    }
  };

  const handleReturnedPending = () => {
    const datta = [...new Set(selectedItem?.map((data) => data.id))];
    dispatch(returnQuoteRequest(builderIdd, datta)).then((res) => {
      if (res.status === 200) {
        dispatch(getQuoteRequestList(builderIdd));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setSelectedItem([]);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const [selectedItems, setSelectedItems] = useState([]);

  const handleCheckboxsChange = (event, item) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add the selected item to the array
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);
    } else {
      // Remove the item from the array
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((selectedItems) => selectedItems !== item)
      );
    }
  };

  const handleAcceptPendingPurchase = () => {
    const datta = [...new Set(selectedItems?.map((data) => data.id))];
    dispatch(acceptPendingPurchase(builderIdd, datta)).then((res) => {
      if (res.status === 200) {
        dispatch(getPurchaseOrderList(builderIdd));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setSelectedItems([])
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  // console.log("jobId",jobId);

  const [showDiscussion,setShowDiscussion]=useState(false)

  const [discussionState, updateDiscussionState] = useState({
    jobId:"",
    message: "",
    discussionError: "",
  });
  const [replyState, updateReplyState] = useState({
    messageId: "",
    jobId:"",
    message: "",
    replyError: "",
  });
  const [replyField, updateReplyField] = useState({ flag: false, index: "" })

  let handleValidationDiscussion = () => {
    let discussionError = "";
    let formIsValid = true;

    if (discussionState.message == "") {
      discussionError = "* Please enter comment";
      formIsValid = false;
    }

    updateDiscussionState({
      ...discussionState,
      discussionError: discussionError,
    });
    return formIsValid;
  };

  const handleAddDiscussion = () => {
    let formIsValid = handleValidationDiscussion();
    if (formIsValid) {
      dispatch(
        addDiscussionTradeView({ message: discussionState.message, jobId:jobId })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(getDiscussionListTradeView({ jobId:jobId  }));
            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleAddReply = () => {
    let formIsValid = true;
    if (formIsValid) {
      dispatch(
        addReplyTradeView({
          message: replyState.message,
          jobId: replyState.jobId,
          messageId: replyState.messageId,
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(getDiscussionListTradeView({ jobId:jobId  }));
            updateReplyState({
              messageId: "",
              jobId: "",
              message: "",
              replyError: "",
            });

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };


  const addDetailsClick = () => {
    updateState({
      ...iState,
      itemDetails: [
        ...iState.itemDetails,
        {
          parentTableName: "QUOTEREQUEST",
          color: "",
          quantity: null,
          unitId: null,
          price: "",
          total: "",
          name: "",
        },
      ],
    });
  };



  const removeDetailsClick = (i) => {
    let user = [...itemDetails];
    user.splice(i, 1);
    updateState({
      ...iState,
      itemDetails: user,
    });
  };

  const copyBuilderItems = () => {
    const newMappedData =
      ViewQuoteRequestVendorData?.BuilderItems?.map((item) => ({
        parentTableName: "QUOTEREQUEST",
        color: item.color || "",
        quantity: Number(item.quantity) || null,
        unitId: item.unitId || null,
        price: item.price || "",
        total: item.total || "",
        name: item.name || "",
      })) || [];

    updateState({ ...iState, itemDetails: newMappedData });
  };

  const handleClear = () => {
    updateState({ ...iState, itemDetails: [] });
  };

  const handleDetailsChange = (e, i) => {
    const { name, value } = e.target;
    let user = [...itemDetails];
    user[i] = { ...user[i], [name]: value };
    updateState({
      ...iState,
      itemDetails: user,
    });
  };

  const handleAddNewSupportingDocsModalShow = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: true,
      supportingDocFiless: "",
    });
  };
  const handleAddNewSupportingDocsModalClose = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: false,
    });
  };

  const handleRemoveDocsFile = () => {
    // Reset the 'templateFile' state to null
    updateState({
      ...iState,
      supportingDocFiless: null,
    });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#docsInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleUploadAdditionalDocuments = () => {
    const formData = new FormData();
    formData.append("files", supportingDocFiless);
    dispatch(
      uploadAdditionalDocumentsQr(compId?.user?.CompanyId,quoteId,ViewQuoteRequestVendorData?.documentType, compId?.user?.token, formData)
    ).then((res) => {
      if (res.status === 201) {
        dispatch(getEditQouteRequestById(compId?.user?.CompanyId, compId?.user?.token,quoteId,)).then(
          (res) => {
            if (res.status === 200) {
              updateState({
                ...iState,
                ViewQuoteRequestVendorData: res?.data?.data,
                supportingDocFiless: null,
                AddNewSupportingDocsModal: false,
              });
            }
          }
        );
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      updateState({ ...iState, AddNewSupportingDocsModal: true });
    });
  };

  const handleFileChanges = (event, type) => {
    const file = event?.target?.files[0];
    if (type === "docsUpdate") {
      updateState({ ...iState, supportingDocFiless: file });
    }
  };

  const handleUpdateQr = () => {
    const dataa = {
      jobCodeId: ViewQuoteRequestVendorData?.jobCodeId,
      jobType: ViewQuoteRequestVendorData?.jobType,
      costCodeId: ViewQuoteRequestVendorData?.CostCodeId,
      reasonId: ViewQuoteRequestVendorData?.reasonId,
      // subtotal,
      date,
      status:"RETURNED",
      subtotal: totalWithoutTax,
      gst: taxPercentTotal,
      grandTotal: parseInt(Number(totalWithoutTax) + Number(finalTotal)),
      subtotal: Number(
        itemDetails?.reduce(
          (total, data) => total + data.quantity * data.price,
          0
        ) ?? 0
      ),
      itemDetails: itemDetails?.map((data) => ({
        parentTableName: data.parentTableName,
        color: data.color,
        quantity: Number(data.quantity),
        unitId: Number(data.unitId),
        price: data.price,
        total: String(data.quantity * data.price),
        name: data.name,
      })),
    };

    dispatch(
      updateEditQouteRequestById(compId?.user?.CompanyId, compId?.user?.token,quoteId, dataa)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getEditQouteRequestById(compId?.user?.CompanyId, compId?.user?.token,quoteId,)).then(
          (res) => {
            if (res.status === 200) {
              updateState({
                ...iState,
                ViewQuoteRequestVendorData: res?.data?.data,
              });
            }
          }
        );
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const totalWithoutTax =
    itemDetails?.reduce(
      (total, data) => total + data.quantity * data.price,
      0
    ) ?? 0;
    const totalWithoutTaxVendor =
    ViewQuoteRequestVendorData?.VendorItems?.reduce(
      (total, data) => total + Number(data.quantity) * Number(data.price),
      0
    ) ?? 0;


  // const taxPercentTotal =
  //   ViewQuoteRequestVendorData?.TaxProfile?.taxRows?.reduce(
  //     (total, data) => total + data.taxPercent,
  //     0
  //   ) ?? 0;

  const taxPercentTotal =
    ViewQuoteRequestVendorData?.TaxProfile?.taxRows?.[0]?.taxPercent??0;
  const totalWithTax = totalWithoutTax * (1 + taxPercentTotal / 100);
  const finalTotal = (totalWithTax - totalWithoutTax).toFixed(2);

const totalWithTaxVendor = totalWithoutTaxVendor * (1 + taxPercentTotal / 100);
const finalTotalVendor = (totalWithTaxVendor - totalWithoutTaxVendor);


const handleViewPoModalClose=()=>{
  updateState({...iState,ViewPoModal:false,poId:""})
}

const handleViewPoModalShow=(item)=>{
  updateState({...iState,
    ViewPoModal:true,
    poId:item.id,
  })
}

useEffect(() => {
  if(ViewPoModal)
  dispatch(getEditPOById(compId?.user?.CompanyId, compId?.user?.token, poId)).then((res) => {
    if (res.status === 200) {
      updateState({ ...iState, viewPoData: res?.data?.data });
    }
  });
}, [ViewPoModal]);


const handleViewAttachDataModalClose=()=>{
  updateState({...iState,ViewAttachmentModal:true,ViewAttachDataModal:false})
}

const handleViewAttachDataModalShow=(item)=>{
  updateState({...iState,
    ViewAttachmentModal:false,
    ViewAttachDataModal:true,
    ViewAttachData:item?.split("?")[0]
  })
}



  return (
    <>
      {/* <Header /> */}
      <TradeHeader />
      <TradeViewSidenav />
      <div className="WrapperAreaClient">
        <div className="WrapperBoxClient">
          {/* <div class="d-flex flex-wrap">
    <h6 data-toggle="modal" data-target="#EditAttachments">Edit Attachments</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 data-toggle="modal" data-target="#ViewAttachments">View Attachments</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 data-toggle="modal" data-target="#EditAttachmentsDropdown">Edit Attachments Dropwdown</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 data-toggle="modal" data-target="#PurchaseOrderCostBreakdownPreTax">Purchase Order Cost Breakdown Pre-Tax</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 data-toggle="modal" data-target="#PurchaseOrderCostBreakdownPreTaxQuote">Purchase Order Cost Breakdown Pre-Tax Quote</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </div> */}
          <div className="TodosArea">
            <div className="TodosHeader TitleBoxWidth align-items-start" style={{display:"block"}}>
               <div className="row">
                  <div className="col-lg-4 col-sm-6 col-12">
                  <div className="BuilderBox">
                <h4>Project</h4>
                <div className="SelectBuilder">
                  <select
                    className="CommonSelect"
                    name="projectId"
                    value={projectId}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Project</option>
                    {projectByBuilderData?.data?.length > 0 &&
                      projectByBuilderData?.data?.map((item, i) => {
                        return (
                          <option value={item.id} key={i}>
                            {item.projectName}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
                  </div>
                  
                  <div className="col-lg-4 col-sm-6 col-12 ml-auto">
                  <div className="BuilderBox">
                <h4>Job</h4>
                <div className="SelectBuilder">
                  <select
                    className="CommonSelect"
                    name="jobId"
                    value={jobId}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Jobs</option>
                    {jobByProjectData?.data?.length > 0 &&
                      jobByProjectData?.data?.map((item, i) => {
                        return (
                          <option value={item.id} key={i}>
                            {item.jobType}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
                  </div>
               </div>
            </div>
            <div className="CommonTabs mt-3">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#QuoteRequests"
                  >
                    <span>
                      <img src="images/quote_requests.png" />
                    </span>
                    Quote Requests
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#PurchaseOrder"
                  >
                    <span>
                      <img src="images/purcahse_order.png" />
                    </span>
                    Purchase Order
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="tab-content">
            <div className="tab-pane fade show active" id="QuoteRequests">
              <div className="BuilderTableQuote">
                <div className="TableHeadingArea mt-5">
                  <div className="FilesHead">
                    <div className="FilesLeft">
                      <h4>Quote Requests</h4>
                    </div>
                    <div className="d-flex Justify-content-between">
                      <div className="TodosButtons mt-0 d-flex">
                        <a className="" onClick={handleReturnedPending}>
                          Return Pending<span>{selectedItem?.length ?? 0}</span>
                        </a>
                      </div>
                      <div className="NewActions">
                        <a title="filter">
                          <img src="images/icon-64.png" />
                        </a>
                        <a title="pdf">
                          <img src="images/icon-53.png" />
                        </a>
                        <a title="settings">
                          <img src="images/setting.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="TableList TableListMaxHeight  TableListLarge CommonHeading">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Job id</th>
                        <th>Project</th>
                        <th>QR Number</th>
                        <th>Sent By</th>
                        <th>Reason</th>
                        <th>Address</th>
                        <th>Date Received</th>
                        <th>Date Returned</th>
                        <th>Builder Pre-Tax Total</th>
                        <th>Vendor Pre-Tax Total</th>
                        <th>Valid Until</th>
                        <th>Access Expires</th>
                        <th>Attachments</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!quoteRequestLoader ? (
                        quoteRequestList?.data?.length > 0 ? (
                          quoteRequestList?.data?.map((item, i) => {
                            return (
                              <tr
                                className={
                                  item.status === "SEND"
                                    ? "PendingConfirmation"
                                    : "BorderCyan"
                                }
                              >
                                <td>
                                  {item.status === "SEND" && (
                                    <label class="CheckBox">
                                      <input
                                        type="checkbox"
                                        checked={selectedItem.includes(item)}
                                        onChange={(e) =>
                                          handleCheckboxChange(e, item)
                                        }
                                      />
                                      <span class="checkmark"></span>
                                    </label>
                                  )}
                                </td>
                                <td>{item.job?.jobNumber ?? "N/A"}</td>
                                <td>{item.job?.projectName??"N/A"}</td>
                                <td>{item.documentCode}</td>
                                <td>{item.issuedBy?.name ?? "N/A"}</td>
                                <td>{item.Reason?.description ?? "N/A"}</td>
                                <td>
                                  <div>
                                    <img
                                      src="images/location.png"
                                      title="location"
                                    />{" "}
                                    {item.Vendor?.addressLine1 ?? ""}
                                    {item.Vendor?.addressLine2 ?? ""}
                                  </div>
                                </td>
                                <td>
                                  {item.sendAt != null
                                    ? moment(item.sendAt).format("YYYY-MM-DD")
                                    : "N/A"}
                                </td>
                                <td>
                                  {item.acceptedAt != null
                                    ? moment(item.acceptedAt).format(
                                        "YYYY-MM-DD"
                                      )
                                    : "N/A"}
                                </td>
                                <td>
                                  <div
                                    className="Actions"
                                    onClick={() =>
                                      handleEditQuoteRequestCostBreakdownModalShow(
                                        item
                                      )
                                    }
                                  >
                                    ${item.grandTotal ?? 0}
                                    <img
                                      src="images/total_icon.png"
                                      title="Edit QuoteRequest Cost Breakdown Modal"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className="Actions"
                                    onClick={() =>
                                      handleEditQuoteRequestCostBreakdownModalShow(
                                        item
                                      )
                                    }
                                  >
                                    ${item.subtotal ?? 0}
                                    <img
                                      src="images/total_icon.png"
                                      title="Edit QuoteRequest Cost Breakdown Modal"
                                    />
                                  </div>
                                </td>
                                <td>{item.validTill ?? "N/A"}</td>
                                <td>N/A</td>
                                <td>
                                  <div className="text-center">
                                    <a
                                      onClick={() =>
                                        handleViewAttachmentModalShow(item)
                                      }
                                    >
                                      <img
                                        src="images/attachment.png"
                                        title="attachment"
                                      />
                                    </a>
                                    <span className="Count">3</span>
                                  </div>
                                </td>
                                <td>
                                  <div className="TableThreeDots">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol className="dropdown-menu dropdown-menuwidth2">
                                          <li onClick={() =>handleViewQuoteRequestVendorModalShow(item)
                                              }>
                                            <a
                                              
                                            >
                                              <img src="images/view-po.png" />
                                              View QR
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                handleViewAttachmentModalShow(
                                                  item
                                                )
                                              }
                                            >
                                              <img
                                                src="images/attachment.png"
                                                title="attachment"
                                              />
                                              View Attachments
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                handleQuoteRequestCostBreakdownModalShow(
                                                  item
                                                )
                                              }
                                            >
                                              <img src="images/view_cost_breakdown.png" />
                                              View Cost Breakdown
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/download.png" />
                                              Download QR
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/icon-53.png" />
                                              Print QR
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="12">
                              <p>No Data found.</p>
                            </td>
                          </tr>
                        )
                      ) : (
                        <Loader />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="PurchaseOrder">
              <div className="BuilderTablePurchase">
                <div className="TableHeadingArea mt-5">
                  <div className="FilesHead">
                    <div className="FilesLeft">
                      <h4>Purchase Orders</h4>
                    </div>
                    <div className="d-flex Justify-content-between">
                      <div className="TodosButtons mt-0 d-flex">
                        <a onClick={handleAcceptPendingPurchase} className="">
                          Accept Pending
                          <span>{selectedItems?.length ?? 0}</span>
                        </a>
                      </div>
                      <div className="NewActions">
                        <a title="filter">
                          <img src="images/icon-64.png" />
                        </a>
                        <a title="pdf">
                          <img src="images/icon-53.png" />
                        </a>
                        <a title="settings">
                          <img src="images/setting.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="TableList TableListLarge CommonHeading">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Job id</th>
                        <th>PO Number</th>
                        <th>Sent By</th>
                        <th>Reason</th>
                        <th>Address</th>
                        <th>Date Received</th>
                        <th>Date Accepted</th>
                        <th>Pre-Tax Total</th>
                        <th>Access Expires</th>
                        <th>Attachments</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!purchaseOrderLoader ? (
                        purchaseOrderList?.data?.length > 0 ? (
                          purchaseOrderList?.data?.map((data, ind) => {
                            return (
                              <tr
                                className={
                                  data.status === "SEND"
                                    ? "PendingConfirmation"
                                    : "BorderCyan"
                                }
                              >
                                <td>
                                  {data.status === "SEND" && (
                                    <label class="CheckBox">
                                      <input
                                        type="checkbox"
                                        checked={selectedItems.includes(data)}
                                        onChange={(e) =>
                                          handleCheckboxsChange(e, data)
                                        }
                                      />
                                      <span class="checkmark"></span>
                                    </label>
                                  )}
                                </td>
                                <td>{data.job?.jobNumber ?? "N/A"}</td>
                                <td>{data.documentCode}</td>
                                <td>{data.issuedBy?.name??"N/A"}</td>
                                <td>{data.Reason?.description ?? "N/A"}</td>
                                <td>
                                  <div>
                                    <img
                                      src="images/location.png"
                                      title="location"
                                    />{" "}
                                    {data.Vendor?.addressLine1 ?? ""}
                                    {data.Vendor?.addressLine2 ?? ""}
                                  </div>
                                </td>
                                <td>
                                  {data.sendAt != null
                                    ? moment(data.sendAt).format("YYYY-MM-DD")
                                    : "N/A"}
                                </td>
                                <td>
                                  {data.acceptedAt != null
                                    ? moment(data.acceptedAt).format(
                                        "YYYY-MM-DD"
                                      )
                                    : "N/A"}
                                </td>
                                <td>
                                  ${data.grandTotal ?? 0}
                                  <a
                                    onClick={() =>
                                      handlePurchaseOrderCostBreakdownModalShow(
                                        data?.Items
                                      )
                                    }
                                  >
                                    <img
                                      src="images/total_icon.png"
                                      title="Purchase Order Cost Breakdown Pre-Tax"
                                    />
                                  </a>
                                </td>
                                <td> ${data.subtotal ?? 0}</td>
                                <td>
                                  <div class="text-center">
                                    <a>
                                      <img
                                        src="images/attachment.png"
                                        title="attachment"
                                      />
                                    </a>
                                    <span class="Count">3</span>
                                  </div>
                                </td>
                                <td>
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown">
                                        <a
                                          class=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol class="dropdown-menu dropdown-menuwidth2">
                                          <li>
                                            <a onClick={()=>handleViewPoModalShow(data)}>
                                              <img src="images/view-po.png" />
                                              View PO
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                handleAcceptRejectPO(
                                                  data?.job?.CompanyId,
                                                  data.id,
                                                  "accept"
                                                )
                                              }
                                            >
                                              <img src="images/accept_po.png" />
                                              Accept PO
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                handleAcceptRejectPO(
                                                  data?.job?.CompanyId,
                                                  data.id,
                                                  "reject"
                                                )
                                              }
                                            >
                                              <img src="images/view-po.png" />
                                              Reject PO
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                handleViewAttachmentModalShow(
                                                  data
                                                )
                                              }
                                            >
                                              <img
                                                src="images/attachment.png"
                                                title="attachment"
                                              />
                                              View PO's Attachments
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/download.png" />
                                              Download PO
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/icon-53.png" />
                                              Print PO
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="12">
                              <p>No Data found.</p>
                            </td>
                          </tr>
                        )
                      ) : (
                        <Loader />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={QuoteRequestCostBreakdownModal}
          onHide={handleQuoteRequestCostBreakdownModalClose}
          className="ModalBox ExtraLargeModal"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleQuoteRequestCostBreakdownModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Quote Request Cost Breakdown</h4>
              </div>
              <div className="FolderForm">
                <div className="JobTitleArea mb-2">
                  <div className="JobBox">
                    <h6>
                      <span>Quote Request:</span>
                      {quoteRequestDetails?.id}
                    </h6>
                  </div>
                </div>
                <div className="TableSmallHeading">
                  <h6>Builder's Original Input</h6>
                  <h6>*Pre-Tax</h6>
                </div>
                <div className="TableList CommonHeading">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <div className="text-center">
                            Material Description
                            <br />
                            (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                          </div>
                        </th>
                        <th>Color</th>
                        <th>Quantity</th>
                        <th>Units</th>
                        <th>Price/Unit</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {quoteRequestDetails?.BuilderItems?.length > 0 ? (
                        quoteRequestDetails?.BuilderItems?.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{item.id}</td>
                              <td>{item.name}</td>
                              <td>{item.color}</td>
                              <td>{item.quantity}</td>
                              <td>{item.unitId}</td>
                              <td>$ {item.price}</td>
                              <td>$ {item.total}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="12">
                            <p>No Data found.</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="TableSmallHeading mt-5">
                  <h6>Vendor's Cost Breakdown</h6>
                  <h6>*Pre-Tax</h6>
                </div>
                <div className="TableList CommonHeading">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <div className="text-center">
                            Material Description
                            <br />
                            (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                          </div>
                        </th>
                        <th>Color</th>
                        <th>Quantity</th>
                        <th>Units</th>
                        <th>Price/Unit</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {quoteRequestDetails?.VendorItems?.length > 0 ? (
                        quoteRequestDetails?.VendorItems?.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{item.id}</td>
                              <td>{item.name}</td>
                              <td>{item.color}</td>
                              <td>{item.quantity}</td>
                              <td>{item.unitId}</td>
                              <td>$ {item.price}</td>
                              <td>$ {item.total}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="12">
                            <p>No Data found.</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group mt-5">
                      <h6>Valid Unitl</h6>
                      <p className="ml-0">
                        <img src="images/calendar.png" className="mr-2" />
                        {quoteRequestDetails?.validTill ?? "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="Button mt-4 text-center">
                  <button
                    className="Outline"
                    onClick={handleQuoteRequestCostBreakdownModalClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={ViewQuoteRequestVendorModal}
          onHide={handleViewQuoteRequestVendorModalClose}
          className="ModalBox modal fade LargeModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleViewQuoteRequestVendorModalClose}
            >
              ×
            </a>
            <div className="FormArea">
        <div className="ModalTitleBox ModalTitleBoxYellow">
          <h4>Edit Quote Request - Vendor</h4>
        </div>
        <div className="FolderForm">
          <div className="BlueTextArea YellowTextArea">
            <div className="row">
              <div className="col-lg-6">
                <div className="Heading">
                  <h3>
                    Quote Request # {ViewQuoteRequestVendorData?.documentCode}
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="Heading">
                  <h3 className="d-flex justify-content-between">
                    Rivers Edge Unit{" "}
                    {ViewQuoteRequestVendorData?.job?.jobNumber ?? "N/A"}
                  </h3>
                  <h3 className="mb-2">
                    Cost Code:{" "}
                    <span>{`${ViewQuoteRequestVendorData?.CostCode?.number}-${ViewQuoteRequestVendorData?.CostCode?.name}`}</span>
                  </h3>
                  <h6>
                    Status:<span>{ViewQuoteRequestVendorData?.status}</span>
                  </h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group">
                  <h6>Reason</h6>
                  <p className="ml-0">
                    {ViewQuoteRequestVendorData?.Reason?.description ?? "N/A"}
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <h6>Sent By</h6>
                  <p className="ml-0">
                    {ViewQuoteRequestVendorData?.issuedBy?.firstName ||
                      ViewQuoteRequestVendorData?.issuedBy?.lastName ||
                      ViewQuoteRequestVendorData?.issuedBy?.name}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group">
                  <h6>Date Received</h6>
                  <p className="ml-0">
                    {moment(ViewQuoteRequestVendorData?.createdAt).format(
                      "YYYY-MM-DD"
                    )}
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <h6>Date Sent</h6>
                  <p>
                    {
                    ViewQuoteRequestVendorData?.date!==null?
                    moment(ViewQuoteRequestVendorData?.date).format(
                      "YYYY-MM-DD"
                    ):"N/A"}
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <h6>Access Expires</h6>
                  <p className="ml-0">
                    {moment(ViewQuoteRequestVendorData?.updatedAt).format(
                      "YYYY-MM-DD"
                    )}
                  </p>
                </div>
              </div>
              <div className="col-12">
                <div className="ShippingAddressArea">
                  <div className="ShippingAddressAreaBox">
                    <h6>Job Address</h6>
                    <p>
                      {`${
                        ViewQuoteRequestVendorData?.Vendor?.addressLine1 ?? ""
                      }
                                ${
                                  ViewQuoteRequestVendorData?.Vendor
                                    ?.addressLine2 ?? ""
                                }`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="form-group">
                  <h6>Messsage</h6>
                  <p>{ViewQuoteRequestVendorData?.message}</p>
                </div>
              </div>
              <div className="col-12">
                <div className="ItemDetails">
                  <h6>Item Details</h6>
                </div>
              </div>
              <div className="col-12">
                <h6 className="YellowText">Builder</h6>
                <div className="Tableheader mb-3">
                  <h6 className="mb-0">Item</h6>
                  <div className="Actions">
                    <a href="javascript:void(0);">
                      <img src="images/icon-53.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/download.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/setting.png" />
                    </a>
                  </div>
                </div>
                <div className="SchedulePaymentsTable TransactionTableShow mb-0">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>
                          <div className="text-center">
                            Material Description
                            <br />
                            (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                          </div>
                        </th>
                        <th>Color</th>
                        <th>Quantity</th>
                        <th>Units</th>
                        <th>Price/Unit</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ViewQuoteRequestVendorData?.BuilderItems?.length > 0 ? (
                        ViewQuoteRequestVendorData?.BuilderItems?.map(
                          (item, i) => {
                            return (
                              <tr key={i}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.color}</td>
                                <td>{item.quantity}</td>
                                <td>{item.Unit?.name}</td>
                                <td>$ {item.price}</td>
                                <td>$ {item.total}</td>
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <tr>
                          <td colSpan="12">
                            <p>No Data found.</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="TableBottom">
                  <h6>
                    <span>Builder's Prex-Tax Total:</span> $
                    {Number(
                      ViewQuoteRequestVendorData?.BuilderItems?.reduce(
                        (total, data) => total + data.quantity * data.price,
                        0
                      ) ?? 0
                    )}
                  </h6>
                </div>
              </div>
              <div className="col-12 mt-4">
                <h6 className="YellowText">Vendor</h6>
                <div className="Tableheader mb-3">
                  <h6 className="mb-0">Item</h6>
                  <div className="Actions">
                    <a href="javascript:void(0);">
                      <img src="images/icon-53.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/download.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/setting.png" />
                    </a>
                  </div>
                </div>
                <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>
                          <div className="text-center">
                            Material Description
                            <br />
                            (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                          </div>
                        </th>
                        <th>Color</th>
                        <th>Quantity</th>
                        <th>Units</th>
                        <th>Price/Unit</th>
                        <th>Total</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ViewQuoteRequestVendorData?.status === "SEND" ? (
                        itemDetails?.length > 0 ? (
                          itemDetails.map((data, index) => (
                            <tr key={index}>
                              <td>{data.id}</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  value={data.name}
                                  onChange={(e) =>
                                    handleDetailsChange(e, index)
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="color"
                                  value={data.color}
                                  onChange={(e) =>
                                    handleDetailsChange(e, index)
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="quantity"
                                  value={data.quantity}
                                  min={0}
                                  onChange={(e) =>
                                    handleDetailsChange(e, index)
                                  }
                                />
                              </td>
                              <td>
                                <select
                                  className="form-control"
                                  name="unitId"
                                  value={data.unitId}
                                  onChange={(e) =>
                                    handleDetailsChange(e, index)
                                  }
                                >
                                  <option value="">Select</option>
                                  {unitListQr?.map((item, i) => (
                                    <option value={item.id} key={i}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="price"
                                  value={data.price}
                                  min={0}
                                  onChange={(e) =>
                                    handleDetailsChange(e, index)
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={
                                    (data.quantity * data.price).toFixed(2) ?? 0
                                  }
                                  readOnly
                                />
                              </td>
                              <td>
                                <div className="TableThreeDots">
                                  <ul>
                                    <li className="dropdown">
                                      <a
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <img src="images/dots.png" alt="menu" />
                                      </a>
                                      <ol className="dropdown-menu">
                                        <li
                                          onClick={() =>
                                            removeDetailsClick(index)
                                          }
                                        >
                                          <a href="javascript:void(0);">
                                            <img
                                              src="images/dropdown-delete.png"
                                              alt="delete"
                                            />
                                            Remove Line
                                          </a>
                                        </li>
                                      </ol>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="12">
                              <p>No Data found.</p>
                            </td>
                          </tr>
                        )
                      ) : ViewQuoteRequestVendorData?.VendorItems?.length >
                        0 ? (
                        ViewQuoteRequestVendorData?.VendorItems?.map(
                          (item, i) => (
                            <tr key={i}>
                              <td>{item.id}</td>
                              <td>{item.name}</td>
                              <td>{item.color}</td>
                              <td>{item.quantity}</td>
                              <td>{item.Unit?.name}</td>
                              <td>$ {item.price}</td>
                              <td>$ {item.total}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan="12">
                            <p>No Data found.</p>
                          </td>
                        </tr>
                      )}

                      {ViewQuoteRequestVendorData?.status === "SEND" && (
                        <tr>
                          <td colSpan={8}>
                            <div className="TableButtons">
                              <button
                                className="BrandBtn"
                                onClick={addDetailsClick}
                              >
                                Add Line
                              </button>
                              <button
                                className="BrandBtn ml-3"
                                onClick={handleClear}
                              >
                                Clear all lines
                              </button>
                              <button
                                className="BrandBtn ml-3"
                                onClick={copyBuilderItems}
                              >
                                <img src="images/copy_builder_item.png" />
                                Copy Builder´s Items
                              </button>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="TableBottom">
                {
  ViewQuoteRequestVendorData?.status === "SEND" ? (
    <>
      <h6>
        <span>Vendor's Pre-Tax Total: </span> $
        {Number(
          itemDetails?.reduce(
            (total, data) => total + data.quantity * data.price,
            0
          ).toFixed(2) ?? 0
        )}
      </h6>
    </>
  ) : (
    <>
      <h6>
        <span>Vendor's Pre-Tax Total: </span> $
        {Number(
          ViewQuoteRequestVendorData?.VendorItems?.reduce(
            (total, data) => total + data.quantity * data.price,
            0
          ).toFixed(2) ?? 0
        )}
      </h6>
    </>
  )
}

                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="form-group">
                    <div className="CommonModalArea">
                      <h5 className="YellowText">Price Valid Until</h5>
                      <div className="CommonModalBox">
                        {ViewQuoteRequestVendorData?.status === "SEND" ? (
                          <h5>
                            <figure>
                              <img src="images/calendar.png" />
                            </figure>{" "}
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Insert new entry name"
                              name="date"
                              value={date}
                              onChange={handleInputChange}
                            />
                          </h5>
                        ) : (
                          <h5>
                            <figure>
                              <img src="images/calendar.png" />
                            </figure>{" "}
                            <p>
                              {
                              ViewQuoteRequestVendorData?.date!==null?
                              moment(ViewQuoteRequestVendorData?.date).format(
                                "YYYY-MM-DD"
                              ):"N/A"}
                            </p>
                          </h5>
                        )}
                       
                      </div>
                    </div>
                  </div>
                  {
                    ViewQuoteRequestVendorData.status==="SEND"?
<div className="GstTable">
                    <table>
                      <tbody>
                        <tr>
                          <td>Profile</td>
                          <td>
                            {ViewQuoteRequestVendorData?.TaxProfile?.name}
                          </td>
                        </tr>
                        <tr>
                          <td>Subtotal</td>
                          <td>${totalWithoutTax}</td>
                          {/* <td>Modify Tax</td> */}
                        </tr>
                        <tr>
                          <td>
                            TaxPercent(
                            {taxPercentTotal}
                            %)
                          </td>
                          <td>$ {finalTotal}</td>
                        </tr>
                        <tr>
                          <td>Grand Total</td>
                          <td>
                            ${Number(totalWithoutTax) + Number(finalTotal)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>:
                  <div className="GstTable">
                  <table>
                    <tbody>
                      <tr>
                        <td>Profile</td>
                        <td>
                          {ViewQuoteRequestVendorData?.TaxProfile?.name}
                        </td>
                      </tr>
                      <tr>
                        <td>Subtotal</td>
                        <td>${totalWithoutTaxVendor}</td>
                        {/* <td>Modify Tax</td> */}
                      </tr>
                      <tr>
                        <td>
                          TaxPercent(
                          {taxPercentTotal}
                          %)
                        </td>
                        <td>$ {finalTotalVendor}</td>
                      </tr>
                      <tr>
                        <td>Grand Total</td>
                        <td>
                          ${Number(totalWithoutTaxVendor) + Number(finalTotalVendor)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                  }
                  
                </div>
              </div>
              <div className="col-12">
                <div className="mb-4">
                  <div className="Heading">
                    <h6>Attachments</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="CommonModalArea">
                  <div className="form-group">
                    <h5>Download Scope of Work</h5>
                    <div className="CommonModalBox">
                      <h5>
                        <figure>
                          <img src="images/scopeofwork.png" />
                        </figure>{" "}
                        <span className="Count">
                          {ViewQuoteRequestVendorData?.workDocuments?.length ??
                            0}
                        </span>
                        Download Scopes of Work
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="CommonModalArea">
                  <div className="form-group">
                    <h5>Download Construction Documents</h5>
                    <div className="CommonModalBox">
                      <h5>
                        <figure>
                          <img src="images/construction_documents.png" />
                        </figure>{" "}
                        <span className="Count">
                          {ViewQuoteRequestVendorData?.constructionDocuments
                            ?.length ?? 0}
                        </span>{" "}
                        Download Construction Documents
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="CommonModalArea">
                  <div className="form-group">
                    <h5>
                    {ViewQuoteRequestVendorData?.status === "SEND" ?
                      "Upload Your Quote":"Download Your Quote"}</h5>
                    <div className="CommonModalBox">
                      {ViewQuoteRequestVendorData?.status === "SEND" ? (
                        <h5
                          className="CursorPointer"
                          onClick={handleAddNewSupportingDocsModalShow}
                        >
                          <figure>
                            <img src="images/extermal_quote.png" />
                          </figure>{" "}
                          <span className="Count">
                            {ViewQuoteRequestVendorData?.additionalDocuments
                              ?.length ?? 0}
                          </span>{" "}
                          Upload Your Quote
                        </h5>
                      ) : (
                        <h5 className="CursorPointer">
                          <figure>
                            <img src="images/extermal_quote.png" />
                          </figure>{" "}
                          <span className="Count">
                            {ViewQuoteRequestVendorData?.additionalDocuments
                              ?.length ?? 0}
                          </span>{" "}
                          Download Your Quote
                        </h5>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {ViewQuoteRequestVendorData?.status === "SEND" ? (
              <div className="Button d-flex justify-content-center mt-4 ">
               
                <button
                  className="Create"
                  onClick={() => handleUpdateQr()}
                >
                  Save & Send
                </button>
              </div>
            ) : (
              <p>An Action has been already performed</p>
            )}
          </div>
        </div>
      </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={EditQuoteRequestCostBreakdownModal}
          onHide={handleEditQuoteRequestCostBreakdownModalClose}
          className="ModalBox ExtraLargeModal"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleEditQuoteRequestCostBreakdownModalClose}
            >
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Edit Quote Request Cost Breakdown</h4>
              </div>
              <div className="FolderForm">
                <div className="JobTitleArea mb-2">
                  <div className="JobBox">
                    <h6>
                      <span>Purchase Order:</span> 2B-160-13-302
                    </h6>
                  </div>
                </div>
                <div className="TableSmallHeading">
                  <h6>Builder's Original Input</h6>
                  <h6>*Pre-Tax</h6>
                </div>
                <div className="TableList CommonHeading">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <div className="text-center">
                            Material Description
                            <br />
                            (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                          </div>
                        </th>
                        <th>Color</th>
                        <th>Quantity</th>
                        <th>Units</th>
                        <th>Price/Unit</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ViewQuoteRequestVendorData?.length > 0 ? (
                        ViewQuoteRequestVendorData?.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{item.id}</td>
                              <td>{item.name}</td>
                              <td>{item.color}</td>
                              <td>{item.quantity}</td>
                              <td>{item.unitId}</td>
                              <td>$ {item.price}</td>
                              <td>$ {item.total}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="12">
                            <p>No Data found.</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="TableSmallHeading mt-5">
                  <h6>Vendor's Cost Breakdown</h6>
                  <h6>*Pre-Tax</h6>
                </div>
                <div className="TableList CommonHeading">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <div className="text-center">
                            Material Description
                            <br />
                            (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                          </div>
                        </th>
                        <th>Color</th>
                        <th>Quantity</th>
                        <th>Units</th>
                        <th>Price/Unit</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {itemDetails?.length > 0 &&
                        itemDetails?.map((item, ind) => {
                          return (
                            <tr>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  value={item.name}
                                  onChange={(e) => handleDetailsChange(e, ind)}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="color"
                                  value={item.color}
                                  onChange={(e) => handleDetailsChange(e, ind)}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="quantity"
                                  value={item.quantity}
                                  min={0}
                                  onChange={(e) => handleDetailsChange(e, ind)}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="unit"
                                  value={item.unit}
                                  min={0}
                                  onChange={(e) => handleDetailsChange(e, ind)}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="price"
                                  value={item.price}
                                  min={0}
                                  onChange={(e) => handleDetailsChange(e, ind)}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  // name="total"
                                  value={item.quantity * item.price ?? 0}
                                  // onChange={(e) => handleDetailsChange(e, ind)}
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="Button mt-5 d-flex justify-content-between">
                  <button
                    className="Create FolderPermissionId"
                    data-toggle="modal"
                    data-target="#LoadCosts"
                    data-dismiss="modal"
                  >
                    Load values from file
                  </button>
                  <button className="Outline" onClick={addDetailsClick}>
                    Add Line
                  </button>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group mt-5">
                      <h6>Valid Unitl</h6>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Insert new entry name"
                        name="createdAt"
                        value={createdAt}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="Button mt-4 d-flex justify-content-between">
                  <button
                    type="button"
                    className="Create"
                    onClick={handleUpdateQr}
                  >
                    Update QR
                  </button>
                  <button
                    className="Outline"
                    onClick={handleEditQuoteRequestCostBreakdownModalClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={ViewAttachmentModal}
          onHide={handleViewAttachmentModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleViewAttachmentModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>View Attachments</h4>
              </div>
              <div className="FolderForm">
                <div className="ModalBigheading">
                  <h3>
                    <span>Quote Request:</span>{qrNumber??""}
                  </h3>
                </div>
                <div className="form-group">
                  <h4>Files</h4>
                  {ViewAttachmentData?.length > 0 ? (
                    ViewAttachmentData?.map((data) => {
                      return (
                        <div className="FilesAdded">
                          <label>{data.fileName}</label>
                          <span onClick={()=>handleViewAttachDataModalShow(data?.docUrl)} style={{cursor:"pointer"}}>
                            <img src="images/file_name.png" title="files" />
                          </span>
                        </div>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="12">
                        <p>No Data found.</p>
                      </td>
                    </tr>
                  )}
                </div>
                <div className="Button text-center mt-5">
                  <button
                    className="Outline"
                    onClick={handleViewAttachmentModalClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={PurchaseOrderCostBreakdownModal}
          onHide={handlePurchaseOrderCostBreakdownModalClose}
          className="ModalBox modal fade LargeModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handlePurchaseOrderCostBreakdownModalClose}
            >
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Purchase Order Cost Breakdown Pre-Tax</h4>
              </div>
              <div className="FolderForm">
                <div className="JobTitleArea mb-2">
                  <div className="JobBox">
                    <h6>
                      <span>Purchase Order:</span> 2B-160-13-302
                    </h6>
                  </div>
                </div>
                <div className="TableList CommonHeading">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <div className="text-center">
                            Material Description
                            <br />
                            (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                          </div>
                        </th>
                        <th>Color</th>
                        <th>Quantity</th>
                        <th>Units</th>
                        <th>Price/Unit</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {PurchaseOrderCostBreakdownData?.length > 0 ? (
                        PurchaseOrderCostBreakdownData?.map((item) => {
                          return (
                            <tr>
                              <td>{item.name}</td>
                              <td>{item.color}</td>
                              <td>{item.quantity}</td>
                              <td>{item.unitId}</td>
                              <td>$ {item.price}</td>
                              <td>$ {item.total}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <td>
                          <tr>No data found</tr>
                        </td>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
        show={ViewPoModal}
        onHide={ViewPoModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleViewPoModalClose}
          >
            ×
          </a>
          <div className="FormArea">
    <div className="ModalTitleBox ModalTitleBoxBlue">
      <h4>View Purchase Order</h4>
    </div>
    <div className="FolderForm">
      <div className="FolderForm"> 
        <div className="BlueTextArea">
          <div className="row">
            <div className="col-md-6">
              <div className="Heading">
                <h3>Purchase Order # {viewPoData?.documentCode}</h3>
              </div>
            </div>
            <div className="col-md-6">
              <div className="Heading">
                <h3 className="d-flex">
                  {/* Rivers Edge Unit */}
                  {viewPoData?.job?.jobNumber ?? "N/A"}
                  
                </h3>
                <h3>
                  Cost Code:{" "}
                  <span>{`${viewPoData?.CostCode?.number}-${viewPoData?.CostCode?.name}`}</span>
                </h3>
                <h6>
                  Status:<span> {viewPoData?.status}</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="form-group col-md-6">
              <h6>Reason</h6>
              <p className="ml-0 text-black">
                {" "}
                {viewPoData?.Reason?.description ?? "N/A"}
              </p>
            </div>
            {/* <div className="form-group col-md-6">
              <div className="CommonModalArea">
                <h5 className="BlueText">History</h5>
                <div className="CommonModalBox">
                  <h5>
                    <figure>
                      <img src="images/timer.png" />
                    </figure>
                  </h5>
                </div>
              </div>
            </div> */}
          </div>
          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <div className="CommonModalArea BlueText">
                  <h5 className="BlueText">Vendor</h5>
                  <div className="CommonModalBox">
                    <h5>
                    <p  className="ml-0">
                    {viewPoData?.Vendor?.vendorName??"N/A"}
                  </p>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <div className="CommonModalArea BlueText">
                  <h5 className="BlueText">Date Sent</h5>
                  <p  className="ml-0">
                    {viewPoData?.sendAt!==null?moment(viewPoData?.sendAt).format(
                      "YYYY-MM-DD"
                    ):"N/A"}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <div className="CommonModalArea BlueText">
                  <h5 className="BlueText">Date Accepted</h5>
                  <p>
                    {viewPoData?.acceptedAt!==null?
                    moment(viewPoData?.acceptedAt).format(
                      "YYYY-MM-DD"
                    ):"N/A"}
                    
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <div className="CommonModalArea BlueText">
                  <h5 className="BlueText">Reminder</h5>
                  <div className="CommonModalBox">
                  <p>
                    {viewPoData?.reminder!==null?
                    moment(viewPoData?.reminder).format(
                      "YYYY-MM-DD"
                    ):"N/A"}
                    
                  </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <h6>Time since last status update</h6>
                <p className="text-black ml-0">
                    {moment(viewPoData?.updatedAt).format(
                      "YYYY-MM-DD"
                    )}
                </p>
              </div>
            </div>
            <div className="col-12">
              <div className="ShippingAddressArea">
                <div className="ShippingAddressAreaBox">
                  <h6>Mailing Address</h6>
                  <p>{`${viewPoData?.Vendor?.addressLine1 ?? ""}
                                ${viewPoData?.Vendor?.addressLine2 ?? ""}`}</p>
                </div>
                <div className="ShippingAddressAreaBox">
                  <h6>Email</h6>
                  <p>{viewPoData?.Vendor?.email??"N/A"}</p>
                </div>
                <div className="ShippingAddressAreaBox">
                  <h6>Phone</h6>
                  <p>{viewPoData?.Vendor?.phone??"N/A"}</p>
                </div>
                <div className="ShippingAddressAreaBox">
                  <h6>Shipping Address</h6>
                  <p>{`${viewPoData?.Vendor?.addressLine1 ?? ""}
                                ${viewPoData?.Vendor?.addressLine2 ?? ""}`}</p>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="form-group">
                <h6>Messsage</h6>
                <p>
                {viewPoData?.message ?? "N/A"}
                </p>
              </div>
            </div>
            <div className="col-12">
              <div className="ItemDetails">
                <h6>Item Details</h6>
              </div>
            </div>
            <div className="col-12">
              <div className="Tableheader mb-3">
                <h6 className="mb-0">Item</h6>
                <div className="Actions">
                  <a href="javascript:void(0);">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/download.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
              <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        <div className="text-center">
                          Material Description
                          <br />
                          (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                        </div>
                      </th>
                      <th>Color</th>
                      <th>Quantity</th>
                      <th>Units</th>
                      <th>Price/Unit</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {viewPoData?.Items?.length > 0 ? (
                      viewPoData?.Items?.map((item, ind) => {
                        return (
                          <>
                            <tr key={ind}>
                              <td>{item.id}</td>
                              <td>{item.name}</td>
                              <td>{item.color}</td>
                              <td>{item.quantity}</td>
                              <td>{item.unitId}</td>
                              <td>$ {item.price}</td>
                              <td>$ {item.total}</td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="12">
                          <p>No Data found.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div className="col-md-4 ml-auto">
                  <div className="GstTable">
                    <table>
                      <tbody>
                        <tr>
                          <td>Profile</td>
                          <td>{viewPoData?.TaxProfile?.name??"N/A"}</td>
                        </tr>
                        <tr>
                          <td>Subtotal</td>
                          <td>${viewPoData?.subtotal??"N/A"}</td>
                          {/* <td>Modify Tax</td> */}
                        </tr>
                        <tr>
                          <td>GST(5%)</td>
                          <td>${viewPoData?.gst??"N/A"}</td>
                        </tr>
                        <tr>
                          <td>Grand Total</td>
                          <td>${viewPoData?.grandTotal??"N/A"}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
          
        </div>
      </div>
      </div>
      </div>

<div className="DiscussionArea">
                <div className="DiscussionTitle bg_f7" onClick={()=>setShowDiscussion(!showDiscussion)}>
                  <h4>Discussion</h4>
                  <div className="DiscussionChat">
                    <img src="images/down.png" className="DiscussionIcon" />
                    <a href="#">
                      <img src="images/pdf2.png" />
                    </a>
                  </div>
                </div>
                <div class="DiscussionHideArea" style={{ display: showDiscussion ? 'block' : 'none', color: 'red' }}>
              <div class="DiscussionBox">
                <span>FM</span>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    value={discussionState?.message}
                    onChange={(e) =>
                      updateDiscussionState({
                        ...discussionState,
                        message: e.target.value,
                      })
                    }
                    placeholder="Add a Comment ....."
                  />
                  {discussionState.discussionError &&
                    !discussionState.message && (
                      <p style={{ color: "red" }}>
                        {discussionState.discussionError}
                      </p>
                    )}

                  <div class="DiscussionOverlay">
                    <a
                      onClick={() =>
                        updateDiscussionState({
                          ...discussionState,
                          message: "",
                        })
                      }
                      class="CancelChat"
                    >
                      <img src={require("../assets/images/plus-cancel1.png")} />
                    </a>
                    <a onClick={handleAddDiscussion} class="SendChat">
                      <img
                        src={require("../assets/images/forwardbutton.png")}
                      />
                    </a>
                  </div>
                </div>
              </div>
              {discussionList?.details?.map((item, i) => (
                <>
                  <div class="DiscussionBox">
                    <span>CO</span>
                    <div class="DiscussionRight">
                      <h6>{item?.message}</h6>
                      <small>{moment(item.updatedAt).format("lll")}</small>
                    </div>
                  </div>
                  <div class="ReplyArea">
                    <a
                      onClick={(e) => {
                        updateReplyField({ flag: true, index: i });
                        updateReplyState({ ...replyState, message: "" });
                      }}
                    >
                      Reply
                    </a>
                    {item?.ReplyDiscussions?.map((ele) => (
                      <div class="DiscussionBox">
                        <span>RE</span>
                        <div class="DiscussionRight">
                          <h6>{ele?.message}</h6>
                          <small>{moment(ele.updatedAt).format("lll")}</small>
                        </div>
                      </div>
                    ))}

                    {replyField.index == i && (
                      <div class="DiscussionBox w-100">
                        <span>FM{item?.id}</span>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            value={replyState?.message}
                            onChange={(e) =>
                              updateReplyState({
                                ...replyState,
                                message: e.target.value,
                                messageId: item?.id,
                                jobId: item?.jobId,
                              })
                            }
                            placeholder="Add reply ....."
                          />
                          {discussionState.discussionError &&
                            !discussionState.message && (
                              <p style={{ color: "red" }}>
                                {discussionState.discussionError}
                              </p>
                            )}

                          <div class="DiscussionOverlay">
                            <a
                              onClick={() =>
                                updateReplyState({ ...replyState, message: "" })
                              }
                              class="CancelChat"
                            >
                              <img
                                src={require("../assets/images/plus-cancel1.png")}
                              />
                            </a>
                            <a onClick={handleAddReply} class="SendChat">
                              <img
                                src={require("../assets/images/forwardbutton.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ))}
            </div>
              </div>


        </Modal.Body>
      </Modal>
      <Modal
        className="ModalBox fade SmallModal"
        show={AddNewSupportingDocsModal}
        onHide={handleAddNewSupportingDocsModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a
              className="CloseModal"
              onClick={handleAddNewSupportingDocsModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4> New Supporting Docs</h4>
              </div>
              <div className="JobTitleArea">
                <h3>Supporting Documents </h3>
              </div>
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input
                    type="file"
                    id="docsInput"
                    onChange={(e) => handleFileChanges(e, "docsUpdate")}
                  />
                </div>
              </div>
              <div className="FilesAddedTitle">
                <h4>Files</h4>
              </div>
              {supportingDocFiless && (
                <div className="FilesAdded">
                  <label>{supportingDocFiless?.name}</label>
                  <span>
                    <img src="images/fileview.png" />
                  </span>
                  <span onClick={handleRemoveDocsFile}>
                    <img src="images/Cross.png" className="cross" />
                  </span>
                </div>
              )}

              <div className="Button mt-3">
                <button
                  className="Create"
                  onClick={handleUploadAdditionalDocuments}
                >
                  Add Documents
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ViewAttachDataModal}
        onHide={handleViewAttachDataModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleViewAttachDataModalClose}
          >
            ×
          </a>
          <div className="ModalTitleBox">
                <h4> View Attachment</h4>
              </div>
    { ViewAttachData?
      <iframe
      src={`https://docs.google.com/gview?url=${encodeURIComponent(
        ViewAttachData
      )}&embedded=true`}
      title="Document Viewer"
      width="100%"
      height="500"
      frameBorder="0"
      allowFullScreen
    ></iframe>
    :<p>Loading Document...</p>
    }


        </Modal.Body>
      </Modal>
      </div>
    </>
  );
};

export default PoqrTradeview;
