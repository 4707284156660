import { itineraryAction, taskAction } from "../actionTypes";
import { Url } from "../../Config/Config";
import axios from "axios";
import { isLoggedIn } from "../../utils/tokenCheck";
import { multiPartData, multiPartDatas } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

//==================================addInternity=============================================

export const internityAddInitiate = () => ({
  type: itineraryAction.ITINERTY_ADD_INITIATE,
});

export const internityAddSuccess = (data) => ({
  type: itineraryAction.ITINERTY_ADD_SUCCESS,
  payload: data,
});

export const internityAddFailure = (data) => ({
  type: itineraryAction.ITINERTY_ADD_FAILURE,
  payload: data,
});

export const getInternityAdd = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(internityAddInitiate(payload));
    const response = await axios.post(
      `${Url}ibuild-admin/itineraries`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 201) {
      dispatch(internityAddSuccess(response.data));
    } else {
      dispatch(internityAddFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(internityAddFailure(err));
    return err.response;
  }
};

//==================================listInternity=============================================

export const internityListInitiate = () => ({
  type: itineraryAction.ITINERTY_LIST_INITIATE,
});

export const internityListSuccess = (data) => ({
  type: itineraryAction.ITINERTY_LIST_SUCCESS,
  payload: data,
});

export const internityListFailure = (data) => ({
  type: itineraryAction.ITINERTY_LIST_FAILURE,
  payload: data,
});

export const getInternityList = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(internityListInitiate(payload));
    const response = await axios.get(
      `${Url}ibuild-admin/itineraries?startDate=${payload.startDate}&endDate=${payload.endDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(internityListSuccess(response.data));
    } else {
      dispatch(internityListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(internityListFailure(err));
    return err.response;
  }
};
//==================================iternarayDelete========================================

export const deleteItinertyInitiate = () => ({
  type: itineraryAction.ITINERTY_DELETE_INITIATE,
});

export const deleteItinertySuccess = (data) => ({
  type: itineraryAction.ITINERTY_DELETE_SUCCESS,
  payload: data,
});

export const deleteItinertyFailure = (data) => ({
  type: itineraryAction.ITINERTY_DELETE_FAILURE,
  payload: data,
});

export const getItinertyDelete = (TaskId) => async (dispatch) => {
  // debugger
  try {
    const token = isLoggedIn("adminData1");
    const { TaskId: extractedDocId } = TaskId;
    dispatch(deleteItinertyInitiate(extractedDocId));
    const response = await axios.delete(
      `${Url}ibuild-admin/itineraries/${extractedDocId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(deleteItinertySuccess(response.data));
    } else {
      dispatch(deleteItinertyFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(deleteItinertyFailure(err));
    return err.response;
  }
};

//---------------------------------ASSIGNsTAFFF---------------------------------------------------
export const assignStaffInitiate = () => ({
  type: itineraryAction.ASSIGN_STAFF_INITIATE,
});

export const assignStaffSuccess = (data) => ({
  type: itineraryAction.ASSIGN_STAFF_SUCCESS,
  payload: data,
});

export const assignStaffFailure = (data) => ({
  type: itineraryAction.ASSIGN_STAFF_FAILURE,
  payload: data,
});

export const getAssignStaff = (taskId, payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(assignStaffInitiate(payload));
    const response = await axios.put(
      `${Url}ibuild-admin/itineraries/${taskId}/assign-staff`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(assignStaffSuccess(response.data));
    } else {
      dispatch(assignStaffFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(assignStaffFailure(err));
    return err.response;
  }
};

//---------------------------------UPDAteReminder---------------------------------------------------
export const updateReminderInitiate = () => ({
  type: itineraryAction.UPDATE_REMINDER_INITIATE,
});

export const updateReminderSuccess = (data) => ({
  type: itineraryAction.UPDATE_REMINDER_SUCCESS,
  payload: data,
});

export const updateReminderFailure = (data) => ({
  type: itineraryAction.UPDATE_REMINDER_FAILURE,
  payload: data,
});

export const getUpdateReminder = (itId, payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(updateReminderInitiate(payload));
    const response = await axios.put(
      `${Url}ibuild-admin/itineraries/${itId}/set-reminder`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(updateReminderSuccess(response.data));
    } else {
      dispatch(updateReminderFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(updateReminderFailure(err));
    return err.response;
  }
};

//============================ITERnarayUpdate================================

export const iternarayUpdateInitiate = () => ({
  type: itineraryAction.ITINERTY_UPDATE_INITIATE,
});

export const iternarayUpdateSuccess = (data) => ({
  type: itineraryAction.ITINERTY_UPDATE_SUCCESS,
  payload: data,
});

export const iternarayUpdateFailure = (data) => ({
  type: itineraryAction.ITINERTY_UPDATE_FAILURE,
  payload: data,
});

export const getIternarayUpdate = (taskId, payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(iternarayUpdateInitiate(payload));
    const response = await axios.put(
      `${Url}ibuild-admin/itineraries/${taskId}`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(iternarayUpdateSuccess(response.data));
    } else {
      dispatch(iternarayUpdateFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(iternarayUpdateFailure(err));
    return err.response;
  }
};

// ITINERARY MANAGEMENT

export const listAccessInitiate = () => ({
  type: itineraryAction.LIST_REQUEST_ACCESS_INITIATE,
});

export const listAccessSuccess = (data) => ({
  type: itineraryAction.LIST_REQUEST_ACCESS_SUCCESS,
  payload: data,
});

export const listAccessFailure = (data) => ({
  type: itineraryAction.LIST_REQUEST_ACCESS_FAILURE,
  payload: data,
});

export const getlistAccessAction = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(listAccessInitiate(payload));
    const response = await axios.get(
      `${Url}ibuild-admin/itinerary-list-request-access?page=1&itemsPerPage=10`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(listAccessSuccess(response.data));
    } else {
      dispatch(listAccessFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(listAccessFailure(err));
    return err.response;
  }
};

export const manageAccessToItineraryListInitiate = () => ({
  type: itineraryAction.MANAGE_ACCESS_TO_MY_ITINERARY_LIST_INITIATE,
});

export const manageAccessToItineraryListSuccess = (data) => ({
  type: itineraryAction.MANAGE_ACCESS_TO_MY_ITINERARY_LIST_SUCCESS,
  payload: data,
});

export const manageAccessToItineraryListFailure = (data) => ({
  type: itineraryAction.MANAGE_ACCESS_TO_MY_ITINERARY_LIST_FAILURE,
  payload: data,
});

export const manageAccessToItineraryListAccessAction =
  (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(manageAccessToItineraryListInitiate(payload));
      const response = await axios.get(
        `${Url}ibuild-admin/manage-access-to-my-itineraries`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        dispatch(manageAccessToItineraryListSuccess(response.data));
      } else {
        dispatch(manageAccessToItineraryListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(manageAccessToItineraryListFailure(err));
      return err.response;
    }
  };

export const searchGiveAccessToMyItenaryUserInitiate = () => ({
  type: itineraryAction.SEARCH_GIVE_ACCESS_TO_ITINERARY_USER_INITIATE,
});

export const searchGiveAccessToMyItenaryUserSuccess = (data) => ({
  type: itineraryAction.SEARCH_GIVE_ACCESS_TO_ITINERARY_USER_SUCCESS,
  payload: data,
});

export const searchGiveAccessToMyItenaryUserFailure = (data) => ({
  type: itineraryAction.SEARCH_GIVE_ACCESS_TO_ITINERARY_USER_FAILURE,
  payload: data,
});

export const searchGiveAccessToMyItenaryUserAccessAction =
  (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(searchGiveAccessToMyItenaryUserInitiate(payload));
      const response = await axios.get(
        `${Url}ibuild-admin/manage-access-to-users-itinerary-lists?page=1&itemsPerPage=10`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        dispatch(searchGiveAccessToMyItenaryUserSuccess(response.data));
      } else {
        dispatch(searchGiveAccessToMyItenaryUserFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(searchGiveAccessToMyItenaryUserFailure(err));
      return err.response;
    }
  };

export const getAccessToMyItenaryUserInitiate = () => ({
  type: itineraryAction.GIVE_ACCESS_TO_ITINERARY_USER_LIST_INITIATE,
});

export const getAccessToMyItenaryUserSuccess = (data) => ({
  type: itineraryAction.GIVE_ACCESS_TO_ITINERARY_USER_LIST_SUCCESS,
  payload: data,
});

export const getAccessToMyItenaryUserFailure = (data) => ({
  type: itineraryAction.GIVE_ACCESS_TO_ITINERARY_USER_LIST_FAILURE,
  payload: data,
});

export const getAccessToMyItenaryUserAccessAction =
  (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getAccessToMyItenaryUserInitiate(payload));
      const response = await axios.get(
        `${Url}ibuild-admin/itinerary-user-list`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        dispatch(getAccessToMyItenaryUserSuccess(response.data));
      } else {
        dispatch(getAccessToMyItenaryUserFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getAccessToMyItenaryUserFailure(err));
      return err.response;
    }
  };

export const itenaryRequestWithdrawnInitiate = () => ({
  type: itineraryAction.ITINERARY_REQUEST_WITHDRAWN_INITIATE,
});

export const itenaryRequestWithdrawnSuccess = (data) => ({
  type: itineraryAction.ITINERARY_REQUEST_WITHDRAWN_SUCCESS,
  payload: data,
});

export const itenaryRequestWithdrawnFailure = (data) => ({
  type: itineraryAction.ITINERARY_REQUEST_WITHDRAWN_FAILURE,
  payload: data,
});

export const itenaryRequestWithdrawnAction =
  (id, payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(itenaryRequestWithdrawnInitiate(id));
      const response = await axios.put(
        `${Url}ibuild-admin/requests/${id}/itinerary-withdrawn`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        dispatch(itenaryRequestWithdrawnSuccess(response.data));
      } else {
        dispatch(itenaryRequestWithdrawnFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(itenaryRequestWithdrawnFailure(err));
      return err.response;
    }
  };

export const itenaryRequestRevokeInitiate = () => ({
  type: itineraryAction.ITINERARY_REQUEST_REVOKE_INITIATE,
});

export const itenaryRequestRevokeSuccess = (data) => ({
  type: itineraryAction.ITINERARY_REQUEST_REVOKE_SUCCESS,
  payload: data,
});

export const itenaryRequestRevokeFailure = (data) => ({
  type: itineraryAction.ITINERARY_REQUEST_REVOKE_FAILURE,
  payload: data,
});

export const itenaryRequestRevokeAction = (id, payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(itenaryRequestRevokeInitiate(payload));
    const response = await axios.put(
      `${Url}ibuild-admin/requests/${id}/itinerary-withdrawn`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(itenaryRequestRevokeSuccess(response.data));
    } else {
      dispatch(itenaryRequestRevokeFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(itenaryRequestRevokeFailure(err));
    return err.response;
  }
};

export const itenaryRequestDenyInitiate = () => ({
  type: itineraryAction.ITINERARY_REQUEST_DENY_INITIATE,
});

export const itenaryRequestDenySuccess = (data) => ({
  type: itineraryAction.ITINERARY_REQUEST_DENY_SUCCESS,
  payload: data,
});

export const itenaryRequestDenyFailure = (data) => ({
  type: itineraryAction.ITINERARY_REQUEST_DENY_FAILURE,
  payload: data,
});

export const itenaryRequestDenyAction = (id,payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(itenaryRequestDenyInitiate());
    const response = await axios.put(
      `${Url}ibuild-admin/requests/${id}/itinerary-deny`,payload,
      
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(itenaryRequestDenySuccess(response.data));
    } else {
      dispatch(itenaryRequestDenyFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(itenaryRequestDenyFailure(err));
    return err.response;
  }
};

export const itenaryRequestApproveInitiate = () => ({
  type: itineraryAction.ITINERARY_REQUEST_APPROVE_INITIATE,
});

export const itenaryRequestApproveSuccess = (data) => ({
  type: itineraryAction.ITINERARY_REQUEST_APPROVE_SUCCESS,
  payload: data,
});

export const itenaryRequestApproveFailure = (data) => ({
  type: itineraryAction.ITINERARY_REQUEST_APPROVE_FAILURE,
  payload: data,
});

export const itenaryRequestApproveAction =
  (id, payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(itenaryRequestApproveInitiate(payload));
      const response = await axios.put(
        `${Url}ibuild-admin/requests/${id}/itinerary-deny`,
        
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        dispatch(itenaryRequestApproveSuccess(response.data));
      } else {
        dispatch(itenaryRequestApproveFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(itenaryRequestApproveFailure(err));
      return err.response;
    }
  };

export const manageAccessToUsersItineraryListInitiate = () => ({
  type: itineraryAction.MANAGE_ACCESS_TO_USER_ITINERARY_LIST_INITIATE,
});

export const manageAccessToUsersItineraryListSuccess = (data) => ({
  type: itineraryAction.MANAGE_ACCESS_TO_USER_ITINERARY_LIST_SUCCESS,
  payload: data,
});

export const manageAccessToUsersItineraryListFailure = (data) => ({
  type: itineraryAction.MANAGE_ACCESS_TO_USER_ITINERARY_LIST_FAILURE,
  payload: data,
});

export const manageAccessToUsersItineraryListAction =
  (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(manageAccessToUsersItineraryListInitiate(payload));
      const response = await axios.get(
        `${Url}ibuild-admin/manage-access-to-users-itinerary-lists?page=1&itemsPerPage=10`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        dispatch(manageAccessToUsersItineraryListSuccess(response.data));
      } else {
        dispatch(manageAccessToUsersItineraryListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(manageAccessToUsersItineraryListFailure(err));
      return err.response;
    }
  };

//---------------------------------email-calendar-----------------------------------------------
export const emailCalendarInitiate = () => ({
  type: taskAction.EMAIL_CALENDAR_DATA_INITIATE,
});

export const emailCalendarSuccess = (data) => ({
  type: taskAction.EMAIL_CALENDAR_DATA_SUCCESS,
  payload: data,
});

export const emailCalendarFailure = (data) => ({
  type: taskAction.EMAIL_CALENDAR_DATA_FAILURE,
  payload: data,
});

export const getEmailCalendar = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(emailCalendarInitiate(payload));
    const response = await axios.post(
      `${Url}ibuild-admin/create-yahoo-calender`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(emailCalendarSuccess(response.data));
    } else {
      dispatch(emailCalendarFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(emailCalendarFailure(err));
    return err.response;
  }
};

export const googleCalendarInitiate = () => ({
  type: taskAction.GOOGLE_CALENDAR_DATA_INITIATE,
});

export const googleCalendarSuccess = (data) => ({
  type: taskAction.GOOGLE_CALENDAR_DATA_SUCCESS,
  payload: data,
});

export const googleCalendarFailure = (data) => ({
  type: taskAction.GOOGLE_CALENDAR_DATA_FAILURE,
  payload: data,
});

export const getGoogleCalendar = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(googleCalendarInitiate(payload));
    const response = await axios.post(
      `${Url}ibuild-admin/create-google-calender`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(googleCalendarSuccess(response.data));
    } else {
      dispatch(googleCalendarFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(googleCalendarFailure(err));
    return err.response;
  }
};

export const outlookCalendarInitiate = () => ({
  type: taskAction.OUTLOOK_CALENDAR_DATA_INITIATE,
});

export const outlookCalendarSuccess = (data) => ({
  type: taskAction.OUTLOOK_CALENDAR_DATA_SUCCESS,
  payload: data,
});

export const outlookCalendarFailure = (data) => ({
  type: taskAction.OUTLOOK_CALENDAR_DATA_FAILURE,
  payload: data,
});

export const getOutlookCalendar = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(outlookCalendarInitiate(payload));
    const response = await axios.post(
      `${Url}ibuild-admin/create-outlook-calender`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(outlookCalendarSuccess(response.data));
    } else {
      dispatch(outlookCalendarFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(outlookCalendarFailure(err));
    return err.response;
  }
};



export const giveItinarayRequestAccessInitiate = () => ({
  type: itineraryAction.ITINERTY_UPDATE_INITIATE,
});

export const giveItinarayRequestAccessSuccess = (data) => ({
  type: itineraryAction.ITINERTY_UPDATE_SUCCESS,
  payload: data,
});

export const giveItinarayRequestAccessFailure = (data) => ({
  type: itineraryAction.ITINERTY_UPDATE_FAILURE,
  payload: data,
});

export const giveItinarayRequestAccess = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(giveItinarayRequestAccessInitiate(payload));
    const response = await axios.post(
      `${Url}ibuild-admin/itineraries-request-access`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(giveItinarayRequestAccessSuccess(response.data));
    } else {
      dispatch(giveItinarayRequestAccessFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(giveItinarayRequestAccessFailure(err));
    return err.response;
  }
};



export const giveItinarayApproveAccessInitiate = () => ({
  type: itineraryAction.ITINERTY_UPDATE_INITIATE,
});

export const giveItinarayApproveAccessSuccess = (data) => ({
  type: itineraryAction.ITINERTY_UPDATE_SUCCESS,
  payload: data,
});

export const giveItinarayApproveAccessFailure = (data) => ({
  type: itineraryAction.ITINERTY_UPDATE_FAILURE,
  payload: data,
});

export const giveItinarayApproveAccess = (payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(giveItinarayRequestAccessInitiate(payload));
    const response = await axios.post(
      `${Url}ibuild-admin/itineraries-approve-access`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(giveItinarayApproveAccessSuccess(response.data));
    } else {
      dispatch(giveItinarayApproveAccessFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(giveItinarayApproveAccessFailure(err));
    return err.response;
  }
};



export const iternarayAssignStaffMemberInitiate = () => ({
  type: itineraryAction.ITINERTY_UPDATE_INITIATE,
});

export const iternarayAssignStaffMemberSuccess = (data) => ({
  type: itineraryAction.ITINERTY_UPDATE_SUCCESS,
  payload: data,
});

export const iternarayAssignStaffMemberFailure = (data) => ({
  type: itineraryAction.ITINERTY_UPDATE_FAILURE,
  payload: data,
});

export const iternarayAssignStaffMember = (taskId, payload) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(iternarayAssignStaffMemberInitiate(payload));
    const response = await axios.put(
      `${Url}ibuild-admin/itineraries/${taskId}/assign-staff`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(iternarayAssignStaffMemberSuccess(response.data));
    } else {
      dispatch(iternarayAssignStaffMemberFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(iternarayAssignStaffMemberFailure(err));
    return err.response;
  }
};



export const getSharedItineriesInitiate = () => ({
  type: itineraryAction.GET_SHARED_ITINERARY_INITIATE,
});

export const getSharedItineriesSuccess = (data) => ({
  type: itineraryAction.GET_SHARED_ITINERARY_SUCCESS,
  payload: data,
});

export const getSharedItineriesUserFailure = (data) => ({
  type: itineraryAction.GET_SHARED_ITINERARY_FAILURE,
  payload: data,
});

export const getSharedItineriesAction =
  (id) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getSharedItineriesInitiate());
      const response = await axios.get(
        `${Url}ibuild-admin/shared-itineraries?userId=${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        dispatch(getSharedItineriesSuccess(response.data));
      } else {
        dispatch(getAccessToMyItenaryUserFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getAccessToMyItenaryUserFailure(err));
      return err.response;
    }
  };


