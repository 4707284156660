import React, { useEffect, useState } from 'react'
import Modal from "react-bootstrap/Modal";
import CreateBlock from './CreateBlock';
import CreateCommonBuilding from './CreateCommonBuilding';
import { getMultiFamilyDetailsAction } from '../../redux/action/jobAction';
import { useDispatch } from 'react-redux';
import { getMultifamilyJobAction } from '../../redux/action/MultifamilyJobActionDk';
import { useSelector } from 'react-redux';
import CreateUnits from './CreateUnits';

const EditJobModal = ({  editJobModal,
    setEditJobModal,
    selectedId,
    jobId,
    jobName}) => {

      const [createUnitsModal, setCreateUnitsModal] = useState(false)


      const dispatch = useDispatch()
    const handleJobDetialsModalClose = () => {
        setEditJobModal(false);
      };
      const handleJobDetialsModalShow = () => {
        setEditJobModal(true);
      };

      const initialState = {
        ProjectId:selectedId,
        buildingPermit: "",
        subdividision: "",
        specificationDoc: "",
        taxProfile: "",
        baseModel: "",
        homeType: "",
        defaultCurrency: "",
        costScheme: "",
        timeZone: "",
        accountSoftware: false,
        underParkade: false,
        jobAddress: "",
        lot: "",
        block: "",
        phase: "",
        city: "",
        zipCode: "",
        country: "",
        province: "",
        numOfBlocks: 0,
        blocks: [],
        numOfBuildings: 0,
      
      
        
        buildings: [],
        itemId:"",
        errorMsg: {},
      };


      const [iState, UpdateState] = useState(initialState);

      const {
        ProjectId,
        subdividision,
        taxProfile,
        buildingPermit,
        homeType,
        costScheme,
        province,
        specificationDoc,
        baseModel,
        defaultCurrency,
        timeZone,
        proformaId,
    
    
        
        accountSoftware,
        underParkade,
        jobAddress,
        lot,
        block,
        phase,
        city,
        zipCode,
        country,
        numOfBlocks,
        blocks,
        numOfBuildings,
        buildings,
        itemId,
      
        errorMsg,
      } = iState;
    
    
    
      const {
    
        foundationTypeData,
    
      } = useSelector((state) => state.ProformaReducer);
      const [createBlockModal, setCreateBlockModal] =useState(false);
      const [createCommonBuildingModal, setCreateCommonBuildingModal] =useState(false)


      useEffect(()=>{

        if(selectedId){
    dispatch(getMultifamilyJobAction(selectedId)).then((res) => {

      UpdateState({...iState,
        buildingPermit: res?.data?.[0]?.buildingPermit,
        subdividision: res?.data?.[0]?.subdividision,
        specificationDoc: "",
        taxProfile: "",
        baseModel: "",
        homeType: "",
        defaultCurrency: "",
        costScheme: "",
        timeZone: "",
        accountSoftware: false,
        underParkade: false,
        jobAddress: res?.data?.[0]?.jobNumber,
        lot: "",
        block: "",
        phase: "",
        city: "",
        zipCode: "",
        country: "",
        province: "",
        numOfBlocks: 0,
        blocks: res?.data?.[0]?.MultiBlocks,
        numOfBuildings: 0,
      
      
        
        buildings: res?.data?.[0]?.MultiBuildings,
        itemId:res?.data?.[0]?.id,

      });

    })
  }
      },[])


      const handleChange = (e) => {
        const { name, value } = e.target;
    
        UpdateState({
          ...iState,
          [name]: value,
        });
      };
    

  return (

    <>
    <Modal show={editJobModal} className="ModalBox LargeModal">
    <div class="modal-content BgCreate">
      <a
        class="CloseModal"
        onClick={handleJobDetialsModalClose}
        data-dismiss="modal"
      >
        ×
      </a>
      <div class="FormArea">
            <div class="ModalTitleBox GrayModalTitle">
              <h4>Multi-Family Job Details</h4>
            </div>
            <div class="FolderForm">
              <div class="ResidentialJobArea ResidentialJobAreaPara">

                <div class="ResidentialJobHead">
                  <h3>
                    {jobAddress}
                    <span class="dropdown">
                      <a class="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src="images/dots.png" />
                      </a>
                      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" href="javascript:void(0);"><img src="images/archive_job.png" />Archive
                          Job</a>
                      </div>
                    </span>
                  </h3>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <h6>Created By</h6>
                        <p>John Doe</p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <h6>Creation Date</h6>
                        <p>04/15/2023</p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <h6>Last Update</h6>
                        <p>07/10/2023</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="ModalAccordian">
                  <div id="accordion">

                    <div class="card">
                      <div class="card-header" data-toggle="collapse" href="#GeneralDetails" aria-expanded="true">
                        <h4><span><img src="images/general_details.png" /></span>General Details</h4>

                      </div>
                      <div id="GeneralDetails" class="collapse show" data-parent="#accordion" >

                        <div class="card-body">
                          <form>
                            <div class="row">
                              <div class="col-sm-5">
                                <div class="form-group">
                                  <h6>Project</h6>
                                  <input type="text" class="form-control" placeholder="Project 1"
                                  name="projectName"
                                  onChange={handleChange} />
                                </div>
                                <div class="form-group">
                                  <h6>Home Type</h6>
                                  <div class="CommonSelectBox">
                                    <select>
                                      <option selected="">2-Storey</option>
                                      <option>storey - 1</option>
                                    </select>
                                    <span><img src="images/down.png" /></span>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <h6>Shared Unit Cost Scheme</h6>
                                  <div class="CommonSelectBox">
                                    <select>
                                      <option selected="">Square Foot Percentage</option>
                                      <option>storey - 1</option>
                                    </select>
                                    <span><img src="images/down.png" /></span>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <h6>Subdivision</h6>
                                  <div class="CommonSelectBox">
                                    <select>
                                      <option selected="">Delta</option>
                                      <option>Delta 1</option>
                                    </select>
                                    <span><img src="images/down.png" /></span>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <h6>Building Permit #</h6>
                                  <div class="CommonSelectBox">
                                    <select>
                                      <option selected="">BP2023-11766</option>
                                      <option>BP2023-11766</option>
                                    </select>
                                    <span><img src="images/down.png" /></span>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <h6>Accounting Status</h6>
                                  <label class="switch">
                                    <input type="checkbox" /> Integrate with Accounting Software
                                    <span class="slider"></span>
                                  </label>
                                </div>




                              </div>
                              <div class="col-sm-5 ml-auto">
                                <div class="form-group">
                                  <h6>Specification Document</h6>
                                  <input type="text" class="form-control" placeholder="Standard Specifications" />
                                </div>
                                <div class="form-group">
                                  <h6>Base Model</h6>
                                  <p>Multi-Family Job</p>
                                </div>
                                <div class="form-group">
                                  <h6>Tax Profile</h6>
                                  <div class="CommonSelectBox">
                                    <select>
                                      <option selected="">Alberta Profile</option>
                                      <option>profile 1</option>
                                    </select>
                                    <span><img src="images/down.png" /></span>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <h6>Default Currency</h6>
                                  <div class="CommonSelectBox">
                                    <select>
                                      <option selected="">CAD</option>
                                      <option>CAD 1</option>
                                    </select>
                                    <span><img src="images/down.png" /></span>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <h6>Job Site GMT (UTC) Timezone</h6>
                                  <div class="CommonSelectBox">
                                    <select>
                                      <option selected="">Moutain Time GMT - 06</option>
                                      <option>Moutain Time GMT - 06 1</option>
                                    </select>
                                    <span><img src="images/down.png" /></span>
                                  </div>
                                </div>

                              </div>
                              <div class="col-md-7">
                                <div class="form-group">
                                  <h6>Parking</h6>
                                  <div class="ParkingBoxArea">
                                    <div class="ParkingBox">
                                      <h6>Job Id</h6>
                                      <p>HIL-2B-395</p>
                                    </div>
                                    <div class="ParkingBox">
                                      <h6>Type</h6>
                                      <p>Underground Parkade (Covered/ Heated)</p>
                                    </div>
                                    <div class="ParkingBox">
                                      <h6>Action</h6>
                                      <div class="Actions">
                                        <a href="javascript:void(0);"><img src="images/Action-1.png" /></a>
                                        <a href="javascript:void(0);"><img src="images/dropdown-delete.png" /></a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-5">
                                <div class="TodosButtons mt-0 d-flex align-items-center justify-content-center h-100">
                                  <a href="javascript:void(0)" class="FolderPermissionId" data-toggle="modal" data-target="#" data-dismiss="modal"><img src="images/create_parking_white.png" />Create Parking
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class="Button d-flex justify-content-center">
                              <button class="OutlineBlack mr-3">Cancel</button>
                              <button class="Respond ml-3">Save</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" data-toggle="collapse" href="#BlocksUnits">
                        <h4><span><img src="images/blocks_units.png" /></span>Blocks &amp; Units</h4>

                      </div>
                      <div id="BlocksUnits" class="collapse show" data-parent="#accordion">

                        <div class="card-body">
                          <form>
                            <div class="row">
                              <div class="col-md-3">
                                <div class="form-group">
                                  <h6>Total Blocks</h6>
                                  <p>4</p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <h6>Total Units</h6>
                                  <p>12</p>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="TodosButtons mt-0 text-center">
                                  <a onClick={()=> {setCreateUnitsModal(true)
                                  }} class="FolderPermissionId" data-toggle="modal" data-target="#CreateMultiFamilyBlocks2" data-dismiss="modal"><img src="images/create_new_block.png" />Create New Unit
                                  </a>
                                </div>
                              </div>
                            </div>

                          </form>
                          <div class="Tableheader">
                            <h6 class="mb-0">Blocks and Units</h6>
                            <div class="Actions">
                              <a href="javascript:void(0);"><img src="images/icon-53.png" /></a>
                              <a href="javascript:void(0);"><img src="images/download.png" /></a>
                              <a href="javascript:void(0);"><img src="images/setting.png" /></a>
                            </div>
                          </div>
                          <div class="BlockUnitsTable CommonHeading">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>Block Description</th>
                                  <th>Foundation Type</th>
                                  <th># of Units Per Block</th>
                                  <th># Stories Per Block</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              {blocks?.map((item)=>

                              <tbody class="addClasss">
                                   <tr class="BorderBottomTable">
                                   <td class="hidtableshowChangeOrder">
                                     <div class="Action">{item?.blockDescription}<img src="images/icon-34.png" class="ml-2" /></div>
                                   </td>
                                   <td class="hidtableshowChangeOrder">{foundationTypeData?.data?.filter((ele)=>ele?.id ==item?.foundationType)?.[0]?.name}</td>
                                   <td class="hidtableshowChangeOrder">{item?.numOfUnits}</td>
                                   <td class="hidtableshowChangeOrder">{item?.numOfStories}</td>
                                   <td>
                                     <div class="TableThreeDots">
                                       <ul class="">
                                         <li class="dropdown">
                                           <a class="" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                             <img src="images/dots.png" />
                                           </a>
                                           <ol class="dropdown-menu dropdown-menuwidth">
                                             <li><a href="javascript:void(0);"><img src="images/Action-1.png" />Edit Job
                                                 Details</a></li>
                                             <li><a href="javascript:void(0);"><img src="images/archive_job.png" />Archive
                                                 Job</a></li>
                                           </ol>
                                         </li>
                                       </ul>
                                     </div>
                                   </td>
                                 </tr>
                             
                                
                              </tbody>
                                                              )}


                          

                            </table>

                          </div>
                          <div class="PaginationArea">
                            <h6>Showing 3 of 3 entries</h6>
                            <h6><img src="images/leftarrow.png" /> 01 <img src="images/rightarrow.png" /> Out 10</h6>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" data-toggle="collapse" href="#CommonBuilding">
                        <h4><span><img src="images/jobs_collpase.png" /></span>Common Building</h4>

                      </div>
                      <div id="CommonBuilding" class="collapse show" data-parent="#accordion">
                        <div class="card-body">
                          <form>
                            <div class="row">
                              <div class="col-md-5">
                                <div class="form-group">
                                  <h6>Total Commom Buildings</h6>
                                  <p>3</p>
                                </div>
                              </div>
                              <div class="col-md-5 ml-auto">
                                <div class="TodosButtons mt-0">
                                  <a onClick={()=>{setCreateCommonBuildingModal(true)}} class="FolderPermissionId" data-toggle="modal" data-target="#CreateMultiFamilyCommonBuilding2" data-dismiss="modal"><img src="images/cmb_white.png" />Create Common Building
                                  </a>
                                </div>
                              </div>
                            </div>

                            <div class="Tableheader">
                              <h6 class="mb-0">Common Building</h6>
                              <div class="Actions">
                                <a href="javascript:void(0);"><img src="images/icon-53.png" /></a>
                                <a href="javascript:void(0);"><img src="images/download.png" /></a>
                                <a href="javascript:void(0);"><img src="images/setting.png" /></a>
                              </div>
                            </div>


                            <div class="TableList TableListHeader NewTableList TableHeightScroll">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Job Id</th>
                                    <th>Commom Building Name</th>
                                    <th>Foundation Type</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {buildings?.map((item)=>
                                  <tr>
                                  <td>HIL-2B-392</td>
                                  <td>Maintenance Garage</td>
                                  <td>Slab-On-Grade-Foundation - No Piles</td>
                                  <td>
                                    <div class="TableThreeDots">
                                      <ul class="">
                                        <li class="dropdown">
                                          <a class="" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol class="dropdown-menu dropdown-menuwidth">
                                            <li><a href="javascript:void(0);"><img src="images/Action-1.png" />Edit
                                                Create Details</a></li>
                                            <li><a href="javascript:void(0);"><img src="images/archive_job.png" />Archive Job</a></li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                                )}
                                  
                                  
                               
                              
                                </tbody>
                              </table>
                            </div>

                            <div class="PaginationArea">
                              <h6>Showing 1 to 10 of 27 entries</h6>
                              <h6><img src="images/leftarrow.png" /> 01 <img src="images/rightarrow.png" /> Out 10</h6>
                            </div>







                          </form>

                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" data-toggle="collapse" href="#BusinessAddress">
                        <h4><span><img src="images/business_address.png" /></span>Business Address</h4>

                      </div>
                      <div id="BusinessAddress" class="collapse show" data-parent="#accordion">

                        <div class="card-body">
                          <form>
                            <div class="row">
                              <div class="col-sm-5">
                                <div class="form-group">
                                  <h6>Job Address</h6>
                                  <input type="text" class="form-control" placeholder="Insert Full Address" />
                                </div>
                                <div class="form-group">
                                  <h6>Lot</h6>
                                  <input type="text" class="form-control" placeholder="Insert lot number" />
                                </div>
                                <div class="form-group">
                                  <h6>Block</h6>
                                  <input type="text" class="form-control" placeholder="Insert block number" />
                                </div>
                                <div class="form-group">
                                  <h6>Phase</h6>
                                  <input type="text" class="form-control" placeholder="Insert phase" />
                                </div>


                              </div>
                              <div class="col-sm-5 ml-auto">
                                <div class="form-group">
                                  <h6>City</h6>
                                  <input type="text" class="form-control" placeholder="Insert City Name" />
                                </div>
                                <div class="form-group">
                                  <h6>Zip/ Postal Code</h6>
                                  <input type="text" class="form-control" placeholder="Insert zip code" />
                                </div>
                                <div class="form-group">
                                  <h6>Country</h6>
                                  <div class="CommonSelectBox">
                                    <select>
                                      <option selected="">Select Country</option>
                                      <option>USA</option>
                                    </select>
                                    <span><img src="images/down.png" /></span>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <h6>State/Province</h6>
                                  <div class="CommonSelectBox">
                                    <select>
                                      <option selected="">Select State/Province</option>
                                      <option>Goa</option>
                                    </select>
                                    <span><img src="images/down.png" /></span>
                                  </div>
                                </div>


                              </div>
                            </div>

                            <div class="Button d-flex justify-content-center mt-2">
                              <button class="OutlineBlack mr-3">Cancel</button>
                              <button class="Respond ml-3">Save</button>
                            </div>


                          </form>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="CloseButton">
                    <button class="Close" data-toggle="modal" data-target="#MultiFamilyJobDetails5" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
      </div>
      </Modal>

   <CreateBlock createBlockModal={createBlockModal} setCreateBlockModal={setCreateBlockModal}  setEditJobModal={setEditJobModal} iState={iState} UpdateState={UpdateState} /> 

   <CreateUnits createUnitsModal={createUnitsModal} setCreateUnitsModal={setCreateUnitsModal} setCreateBlockModal={setCreateBlockModal}units={blocks} jobId={itemId} selectedId={selectedId} createdFrom={'home'}  />


      <CreateCommonBuilding createCommonBuildingModal={createCommonBuildingModal} setCreateCommonBuildingModal={setCreateCommonBuildingModal}  setEditJobModal={setEditJobModal} jobName={jobName} iState={iState} UpdateState={UpdateState} />     

      
      </>)
}

export default EditJobModal