import { update } from 'lodash';
import React from 'react'
import Modal from "react-bootstrap/Modal";

const NewProjectStep3 = ({ iState, UpdateState,setShow, NewProjectStep3Modal, SetNewProjectStep3Modal,SetNewProjectStep1Modal,SetNewProjectStep2Modal,SetNewProjectStep4Modal,SetNewProjectStep5Modal,SetNewProjectStep6Modal}) => {


  const handleNewProjectStep3ModalClose = () => {SetNewProjectStep3Modal(false)      
     setShow(true)
  };
  const handleNewProjectStep3ModalShow = () => {
      SetNewProjectStep3Modal(true);
  };





  const { projectType, creationType,developer,taxProfile,integrateAccountingSoftware,subdivisions, errorMsg } = iState;

  const handleChange = (e) => {
    const { name, value } = e.target;

    UpdateState({
      ...iState,
      [name]: value,
    });
  };

  const handleValidation = () => {
    let formIsValid = true;
    let errorMsg = {};

    if (!projectType) {
      formIsValid = false;
      errorMsg.projectType = "* Please select project type.";
    }

    if (!creationType) {
      formIsValid = false;
      errorMsg.creationType = "* Please select creation type.";
    }

    // if (!developer) {
    //   formIsValid = false;
    //   errorMsg.developer = "* Please select developer.";
    // }

    if (!taxProfile) {
      formIsValid = false;
      errorMsg.taxProfile = "* Please select tax profile.";
    }



    UpdateState({ ...iState, errorMsg: errorMsg });

    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();

    if (formIsValid) {
      SetNewProjectStep3Modal(false);
      SetNewProjectStep4Modal(true);
    }
  };

  const handleProjectType=(value)=>{
    UpdateState({...iState, projectType:value})
  }

  const handleCreationType=(value)=>{
    UpdateState({...iState, creationType:value})
  }

  const handleCheck =(e)=>{

   UpdateState({...iState, integrateAccountingSoftware:e.target.checked})
  
    }

  return (
    <Modal show={NewProjectStep3Modal} className="ModalBox LargeModal">
<div class="modal-content BgCreate">
            <a href="javascript:void(0);" class="CloseModal" onClick={handleNewProjectStep3ModalClose}>×</a>
            <div class="FormArea">
              <div class="ModalTitleBox">
                <h4>Create New Project</h4>
              </div>
              <div class="StepBox">
            <ul>
                <li class="active">
                    <span class="Icon">
                        <img src={require("../../assets/images/step_1.png")} onClick={()=> {SetNewProjectStep1Modal(true) 
                      SetNewProjectStep3Modal(false)}} />
                    </span>
                    <span class="Text">Details</span>
                </li>
                <li class="active" onClick={()=> {SetNewProjectStep2Modal(true)
                   SetNewProjectStep3Modal(false)}}>
                    <span class="Icon FolderPermissionId " >
                        <img src={require("../../assets/images/step_2.png")}/>
                    </span>
                    <span class="Text">Address</span>
                </li>
                <li class="active">
                  <span class="Icon FolderPermissionId " data-toggle="modal" >
                      <img src={require("../../assets/images/step_8.png")}/>
                  </span>
                  <span class="Text">Type&amp; <br /> Accounting </span>
              </li>
                <li>
                    <span class="Icon FolderPermissionId " data-toggle="modal" onClick={()=> {SetNewProjectStep4Modal(true)
                    SetNewProjectStep3Modal(false)}}>
                        <img src={require("../../assets/images/step_5.png")} />
                    </span>
                    <span class="Text">Job <br /> Numbering</span>
                </li>
                <li>
                    <span class="Icon FolderPermissionId " data-toggle="modal" onClick={()=>{ SetNewProjectStep5Modal(true)
                    SetNewProjectStep3Modal(false)}}>
                        <img src={require("../../assets/images/step_6.png")} />
                    </span>
                    <span class="Text">Cost <br /> Profiles</span>
                </li>
                <li>
                    <span class="Icon FolderPermissionId " data-toggle="modal" onClick={()=> {SetNewProjectStep6Modal(true)
                    SetNewProjectStep3Modal(false)}}>
                        <img src={require("../../assets/images/step_7.png")} />
                    </span>
                    <span class="Text">Confirmation</span>
                </li> 
            </ul>
          </div>
          <div class="FolderForm">
                
                <form>
                  <div class="FolderFormCreateArea">
                    <div class="FieldsMandatory"><h6>Fields with <span class="required">*</span> are mandatory</h6></div>
                    <div class="">
                      <h6>Project Type<span class="required">*</span></h6>
                     <div class="row">
                      <div class="form-group col-md-4">
                        <label class="Radio"> Single Family
                          <input type="radio" onChange={()=>handleProjectType("Single Family")} name="Cuisine" checked={projectType=="Single Family"?true:false} />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="form-group col-md-4">
                        <label class="Radio"> Multi Family
                          <input type="radio" onChange={()=>handleProjectType("Multi Family")} name="Cuisine" checked={projectType=="Multi Family"?true:false} />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="form-group col-md-4">
                        <label class="Radio"> Land Development
                          <input type="radio"onChange={()=>handleProjectType("Land Development")} checked={projectType=="Land Development"?true:false} name="Cuisine"  />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      {errorMsg.projectType && !projectType && (
                        <p style={{ color: "red" }}>{errorMsg.projectType}</p>
                      )}
                     </div>
                    </div>
                    <div class="">
                      <h6>Creation Type<span class="required">*</span></h6>
                      <div class="row">
                        <div class="form-group col-md-4">
                          <label class="Radio">Brand New
                            <input type="radio" name="Cuisine1" value="text"  onChange={()=>handleCreationType("Brand New")} checked={creationType=="Brand New"?true:false} /> 
                            <span class="checkmark"></span>
                          </label>
                        </div>
        
                        <div class="form-group col-md-4">
                          <label class="Radio">From Proforma
                            <input type="radio" name="Cuisine1" value="file" onChange={()=>handleCreationType("From Proforma")} checked={creationType=="From Proforma"?true:false} />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="form-group col-md-4">
                          <label class="Radio">From Old Project
                            <input type="radio" name="Cuisine1" value="OldProject"onChange={()=>handleCreationType("From Old Project")} checked={creationType=="From Old Project"?true:false} />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        {errorMsg.creationType && !creationType && (
                        <p style={{ color: "red" }}>{errorMsg.creationType}</p>
                      )}
                      </div> 
                    </div>

                    
  
                    <div class="text RadioText" style={{display :"block"}}>
                      <div class="col-md-4">
                        <div class="SubdivisionsArea">
                          <h6>Subdivisions</h6>
                          <div class="SubdivisionsBox">
                                 <div class="form-group">
                                  <h6>Subdivision</h6>
                                  <input type="text" class="form-control" value={subdivisions}  onChange={(e)=> UpdateState({...iState, subdivisions:[e.target.value]})} />
                                 </div>
                                 <div class="form-group">
                                  <h6>Action</h6>
                                  <div class="Action">
                                    <a href="javascript:void(0);"><img src="images/Action-1.png" /></a>
                                    <a onClick={()=> UpdateState({...iState, subdivisions:[]})}><img src="images/dropdown-delete.png" /></a>
                                  </div>
                                 </div>
                          </div>
                        </div>
                      </div>
                    </div>
    
                    {/* <div class="file RadioText">
                     <div class="row">
                      <div class="col-md-6">
                        <div class="SubdivisionsArea">
                          <h6>Subdivisions</h6>
                          <div class="SubdivisionsBox">
                                 <div class="form-group">
                                  <h6>Subdivision</h6>
                                  <input type="text" class="form-control" />
                                 </div>
                                 <div class="form-group">
                                  <h6>Action</h6>
                                  <div class="Action">
                                    <a href="javascript:void(0);"><img src="images/Action-1.png" /></a>
                                    <a href="javascript:void(0);"><img src="images/dropdown-delete.png" /></a>
                                  </div>
                                 </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <h6>Country <span class="required">*</span></h6>
                        <div class="CommonSelectBox">
                          <select>
                            <option selected="">Select Country</option>
                            <option>India</option>
                            <option>USA</option>
                            <option>Australia</option>
                          </select>
                          <span><img src="images/down.png"/> </span>
                        </div>
                      </div>
                     </div>
                    </div> */}

                    {/* <div class="OldProject RadioText">
                      <div class="row">
                       <div class="col-md-6">
                         <div class="SubdivisionsArea">
                           <h6>Subdivisions</h6>
                           <div class="SubdivisionsBox">
                                  <div class="form-group">
                                   <h6>Subdivision</h6>
                                   <input type="text" class="form-control" />
                                  </div>
                                  <div class="form-group">
                                   <h6>Action</h6>
                                   <div class="Action">
                                     <a href="javascript:void(0);"><img src="images/Action-1.png" /></a>
                                     <a href="javascript:void(0);"><img src="images/dropdown-delete.png" /></a>
                                   </div>
                                  </div>
                           </div>
                         </div>
                       </div>
                       <div class="form-group col-md-6">
                         <h6>Country <span class="required">*</span></h6>
                         <div class="CommonSelectBox">
                           <select>
                             <option selected="">Select Country</option>
                             <option>India</option>
                             <option>USA</option>
                             <option>Australia</option>
                           </select>
                           <span><img src="images/down.png" /></span>
                         </div>
                       </div>
                      </div>
                     </div> */}


                    <div class="row">
                     
                      <div class="form-group col-md-6">
                        <h6>Developer</h6>
                        <div class="CommonSelectBox">
                          <select onChange={handleChange} value={developer} name="developer">
                            <option value={''} >Select Developer</option>
                            <option value={'Developer 1'}>Developer 1</option>
                            <option value={'Developer 2'}>Developer 2</option>
                          </select>
                          <span><img src="images/down.png" /></span>
                        </div>
                        {errorMsg.developer && !developer && (
                        <p style={{ color: "red" }}>{errorMsg.developer}</p>
                      )}
                      </div>
                      <div class="form-group col-md-6">
                        <h6>Tax Profile</h6>
                        <div class="CommonSelectBox">
                          <select onChange={handleChange} value={taxProfile} name='taxProfile'>
                            <option value={''}>Select Tax Profile</option>
                            <option value={1}>Profile 1</option>
                            <option value={2}>Profile 2</option>
                          </select>
                          <span><img src="images/down.png" /></span>
                        </div>
                        {errorMsg.taxProfile && !taxProfile && (
                        <p style={{ color: "red" }}>{errorMsg.taxProfile}</p>
                      )}
                      </div>
                    <div class="col-12">
                      <label class="switch">
                        <input onChange={handleCheck} type="checkbox" checked={integrateAccountingSoftware} /> Integrate Project with Company's Accounting Software
                        <span class="slider"></span> 
                    </label>
                    </div>
                    </div>
                  </div>
                <div class="Button d-flex justify-content-between align-items-center px-5">
                  <button class="Outline" onClick={()=> {
                    SetNewProjectStep3Modal(false)
                    SetNewProjectStep2Modal(true)
                  }} data-dismiss="modal">Back</button>
                  <button class="Create FolderPermissionId" 
                  onClick={handleSubmit}
                  >Next</button>
                </div>  
                </form>
              
            </div>
            </div>
          </div>
   </Modal>  )
}

export default NewProjectStep3