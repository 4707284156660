import { investorViewAuthAction } from "../actionTypes";
import { Url } from  "../../Config/Config";
import axios from "axios";
import { multiPartData, multiPartDatas } from "../../utils/uploadFile";

//----------------------------------InvestorView==login--------------------------------------------------

export const investorViewLoginInitiate = () => ({
  type: investorViewAuthAction.INVESTOR_VIEW_LOGIN_INITIATE,
});

export const investorViewLoginSuccess = (data) => ({
  type: investorViewAuthAction.INVESTOR_VIEW_LOGIN_SUCCESS,
  payload: data,
});

export const investorViewLoginFailure = (data) => ({
  type: investorViewAuthAction.INVESTOR_VIEW_LOGIN_FAILURE,
  payload: data,
});

export const investorViewAuthLogin = (payload) => async (dispatch) => {
    try {
      dispatch(investorViewLoginInitiate(payload));
      const response = await axios.post(`${Url}investor-views/login`, payload);
      console.log("response",response);
      if (response.status === 200) {
        dispatch(investorViewLoginSuccess(response.data));
      } else {
        dispatch(investorViewLoginFailure(response.data));
      }
      return response
    } catch (err) {
      dispatch(investorViewLoginFailure(err));
      return err.response
    }
  };

//=================================clientSendEmailRequest================================

export const investorViewSendMailInitiate = () => ({
  type: investorViewAuthAction.INVESTOR_VIEW_SEND_MAIL_INITIATE,
});

export const investorViewSendMailSuccess = (data) => ({
  type: investorViewAuthAction.INVESTOR_VIEW_SEND_MAIL_SUCCESS,
  payload: data,
});

export const investorViewSendMailFailure = (data) => ({
  type: investorViewAuthAction.INVESTOR_VIEW_SEND_MAIL_FAILURE,
  payload: data,
});

export const getInvestorViewResetPassword = (payload) => async (dispatch) => {
    try {
      dispatch(investorViewSendMailInitiate(payload));
      const response = await axios.put(`${Url}investor-user/send-reset`, payload);
      if (response.status === 200) {
        dispatch(investorViewSendMailSuccess(response.data));
      } else {
        dispatch(investorViewSendMailFailure(response.data));
      }
      return response
    } catch (err) {
      dispatch(investorViewSendMailFailure(err));
      return err.response
    }
  };
//================================changedPassword================================


export const investorChangePasswordInitiate = () => ({
    type: investorViewAuthAction.INVESTOR_CHANGE_PASSWORD_INITIATE,
  });
  
  export const investorChangePasswordSuccess = (data) => ({
    type: investorViewAuthAction.INVESTOR_CHANGE_PASSWORD_SUCCESS,
    payload: data,
  });
  
  export const investorChangePasswordFailure = (data) => ({
    type: investorViewAuthAction.INVESTOR_CHANGE_PASSWORD_FAILURE,
    payload: data,
  });
  
  export const getInvestorChangePassword = (payload) => async (dispatch) => {
      try {
        dispatch(investorChangePasswordInitiate(payload));
        const response = await axios.put(`${Url}investor-user/send-reset`, payload);
        if (response.status === 200) {
          dispatch(investorChangePasswordSuccess(response.data));
        } else {
          dispatch(investorChangePasswordFailure(response.data));
        }
        return response
      } catch (err) {
        dispatch(investorChangePasswordFailure(err));
        return err.response
      }
    };
//======================================GETVindorByUserId================================


export const getVendorByUserIdInitiate = () => ({
    type: investorViewAuthAction.GET_VENDOR_BY_USER_ID_INITIATE,
  });
  
  export const getVendorByUserIdSuccess = (data) => ({
    type: investorViewAuthAction.GET_VENDOR_BY_USER_ID_SUCCESS,
    payload: data,
  });
  
  export const getVendorByUserIdFailure = (data) => ({
    type: investorViewAuthAction.GET_VENDOR_BY_USER_ID_FAILURE,
    payload: data,
  });
  
  export const getVendorByUserId = (payload) => async (dispatch) => {
      try {
        const investorViewData=JSON.parse(window.localStorage.getItem("investorViewLogin"));
        dispatch(getVendorByUserIdInitiate(payload));
        const response = await axios.get(`${Url}user/${investorViewData?.user?.id}/vendor`,{
            headers: { Authorization: `Bearer ${investorViewData?.user?.token}` },
          });
        if (response.status === 200) {
          dispatch(getVendorByUserIdSuccess(response.data));
        } else {
          dispatch(getVendorByUserIdFailure(response.data));
        }
        return response
      } catch (err) {
        dispatch(getVendorByUserIdFailure(err));
        return err.response
      }
    };

//=======================================UuploadIconImages==============================

export const uploadIconImageInitiate = () => ({
    type: investorViewAuthAction.UPLOAD_ICON_IMAGE_INITIATE,
  });
  
  export const uploadIconImageSuccess = (data) => ({
    type: investorViewAuthAction.UPLOAD_ICON_IMAGE_SUCCESS,
    payload: data,
  });
  
  export const uploadIconImageFailure = (data) => ({
    type: investorViewAuthAction.UPLOAD_ICON_IMAGE_FAILURE,
    payload: data,
  });
  
    export const getUploadIconImage = (vendorId,payload) => async (dispatch) => {
        try {
          const investorViewData=JSON.parse(window.localStorage.getItem("investorViewLogin"));
          const updatedData = multiPartDatas(payload );
          dispatch(uploadIconImageInitiate(payload));
          const response = await axios.put(`${Url}vendors/${vendorId}/investor-icon-image`,updatedData, {
            headers: { Authorization: `Bearer ${investorViewData?.user?.token}` },
          });
          if (response.status == 200) {
            dispatch(uploadIconImageSuccess(response.data));
          } else {
            dispatch(uploadIconImageFailure(response.data));
          }
          return response;
        } catch (err) {
          dispatch(uploadIconImageFailure(err));
          return err.response
        }
      };

//===================================uploadMainImage================================


export const uploadMainImageInitiate = () => ({
    type: investorViewAuthAction.UPLOAD_MAIN_IMAGE_INITIATE,
  });
  
  export const uploadMainImageSuccess = (data) => ({
    type: investorViewAuthAction.UPLOAD_MAIN_IMAGE_SUCCESS,
    payload: data,
  });
  
  export const uploadMainImageFailure = (data) => ({
    type: investorViewAuthAction.UPLOAD_MAIN_IMAGE_FAILURE,
    payload: data,
  });
  
    export const getUploadMainImage = (vendorId,payload) => async (dispatch) => {
        try {
          const investorViewData=JSON.parse(window.localStorage.getItem("investorViewLogin"));
          const updatedData = multiPartDatas(payload );
          dispatch(uploadMainImageInitiate(payload));
          const response = await axios.put(`${Url}vendors/${vendorId}/investor-main-image`,updatedData, {
            headers: { Authorization: `Bearer ${investorViewData?.user?.token}` },
          });
          if (response.status == 200) {
            dispatch(uploadMainImageSuccess(response.data));
          } else {
            dispatch(uploadMainImageFailure(response.data));
          }
          return response;
        } catch (err) {
          dispatch(uploadMainImageFailure(err));
          return err.response
        }
      };
//====================================craeteComment =======================


export const createCommentInitiate = () => ({
    type: investorViewAuthAction.CREATE_COMMENT_INITIATE,
  });
  
  export const createCommentSuccess = (data) => ({
    type: investorViewAuthAction.CREATE_COMMENT_SUCCESS,
    payload: data,
  });
  
  export const createCommentFailure = (data) => ({
    type: investorViewAuthAction.CREATE_COMMENT_FAILURE,
    payload: data,
  });
  
    export const createComment = (investId,compId,payload) => async (dispatch) => {
        try {
          const investorViewData=JSON.parse(window.localStorage.getItem("investorViewLogin"));
          dispatch(createCommentInitiate(payload));
          const response = await axios.post(`${Url}investor-view/${investId}/discussions?commentableType=Company&commentableId=${compId}`,payload, {
            headers: { Authorization: `Bearer ${investorViewData?.user?.token}` },
          });
          if (response.status == 200) {
            dispatch(createCommentSuccess(response.data));
          } else {
            dispatch(createCommentFailure(response.data));
          }
          return response;
        } catch (err) {
          dispatch(createCommentFailure(err));
          return err.response
        }
      };
//================================getComment=====================================


export const getCommentInitiate = () => ({
    type: investorViewAuthAction.GET_COMMENT_INITIATE,
  });
  
  export const getCommentSuccess = (data) => ({
    type: investorViewAuthAction.GET_COMMENT_SUCCESS,
    payload: data,
  });
  
  export const getCommentFailure = (data) => ({
    type: investorViewAuthAction.GET_COMMENT_FAILURE,
    payload: data,
  });
  
    export const getCommentList = (investId,compId) => async (dispatch) => {
        try {
          const investorViewData=JSON.parse(window.localStorage.getItem("investorViewLogin"));
          dispatch(getCommentInitiate());
          const response = await axios.get(`${Url}investor-view/${investId}/discussions?commentableType=Company&commentableId=${compId}`, {
            headers: { Authorization: `Bearer ${investorViewData?.user?.token}` },
          });
          if (response.status == 200) {
            dispatch(getCommentSuccess(response.data));
          } else {
            dispatch(getCommentFailure(response.data));
          }
          return response;
        } catch (err) {
          dispatch(getCommentFailure(err));
          return err.response
        }
      };
//========================================getVindorCompanies================================

export const getVendorCompaniesInitiate = () => ({
    type: investorViewAuthAction.GET_VENDOR_COMPANIES_INITIATE,
  });
  
  export const getVendorCompaniesSuccess = (data) => ({
    type: investorViewAuthAction.GET_VENDOR_COMPANIES_SUCCESS,
    payload: data,
  });
  
  export const getVendorCompaniesFailure = (data) => ({
    type: investorViewAuthAction.GET_VENDOR_COMPANIES_FAILURE,
    payload: data,
  });
  
    export const getVendorCompaniesList = (vendorId) => async (dispatch) => {
        try {
          const investorViewData=JSON.parse(window.localStorage.getItem("investorViewLogin"));
          dispatch(getVendorCompaniesInitiate());
          const response = await axios.get(`${Url}vendors/${vendorId}/vendor-companies`, {
            headers: { Authorization: `Bearer ${investorViewData?.user?.token}` },
          });
          if (response.status == 200) {
            dispatch(getVendorCompaniesSuccess(response.data));
          } else {
            dispatch(getVendorCompaniesFailure(response.data));
          }
          return response;
        } catch (err) {
          dispatch(getVendorCompaniesFailure(err));
          return err.response
        }
      };
//========================================getaLLproject================================

export const getAllProjectInitiate = () => ({
    type: investorViewAuthAction.GET_ALL_PROJECT_INITIATE,
  });
  
  export const getAllProjectSuccess = (data) => ({
    type: investorViewAuthAction.GET_ALL_PROJECT_SUCCESS,
    payload: data,
  });
  
  export const getAllProjectFailure = (data) => ({
    type: investorViewAuthAction.GET_ALL_PROJECT_FAILURE,
    payload: data,
  });
  
    export const getAllProjectList = (compId) => async (dispatch) => {
        try {
          const investorViewData=JSON.parse(window.localStorage.getItem("investorViewLogin"));
          dispatch(getAllProjectInitiate());
          const response = await axios.get(`${Url}companies/${compId}/all-project`, {
            headers: { Authorization: `Bearer ${investorViewData?.user?.token}` },
          });
          if (response.status == 200) {
            dispatch(getAllProjectSuccess(response.data));
          } else {
            dispatch(getAllProjectFailure(response.data));
          }
          return response;
        } catch (err) {
          dispatch(getAllProjectFailure(err));
          return err.response
        }
      };
//=================================LISTProjectJobs================================


export const projectJobListInitiate = () => ({
    type: investorViewAuthAction.PROJECT_JOBS_LIST_INITIATE,
  });
  
  export const projectJobListSuccess = (data) => ({
    type: investorViewAuthAction.PROJECT_JOBS_LIST_SUCCESS,
    payload: data,
  });
  
  export const projectJobListFailure = (data) => ({
    type: investorViewAuthAction.PROJECT_JOBS_LIST_FAILURE,
    payload: data,
  });
  
    export const getProjectJobList = (compId,projId) => async (dispatch) => {
        try {
          const investorViewData=JSON.parse(window.localStorage.getItem("investorViewLogin"));
          dispatch(projectJobListInitiate());
          const response = await axios.get(`${Url}companies/${compId}/projects/${projId}/jobs`, {
            headers: { Authorization: `Bearer ${investorViewData?.user?.token}` },
          });
          if (response.status == 200) {
            dispatch(projectJobListSuccess(response.data));
          } else {
            dispatch(projectJobListFailure(response.data));
          }
          return response;
        } catch (err) {
          dispatch(projectJobListFailure(err));
          return err.response
        }
      };

//==============================investor-view============================


export const investorViewInitiate = () => ({
    type: investorViewAuthAction.INVESTOR_VIEW_INITIATE,
  });
  
  export const investorViewSuccess = (data) => ({
    type: investorViewAuthAction.INVESTOR_VIEW_SUCCESS,
    payload: data,
  });
  
  export const investorViewFailure = (data) => ({
    type: investorViewAuthAction.INVESTOR_VIEW_FAILURE,
    payload: data,
  });
  
    export const getInvestorView = (compId) => async (dispatch) => {
        try {
          const investorViewData=JSON.parse(window.localStorage.getItem("investorViewLogin"));
          dispatch(investorViewInitiate());
          const response = await axios.get(`${Url}companies/${compId}/investor-view`, {
            headers: { Authorization: `Bearer ${investorViewData?.user?.token}` },
          });
          if (response.status == 200) {
            dispatch(investorViewSuccess(response.data));
          } else {
            dispatch(investorViewFailure(response.data));
          }
          return response;
        } catch (err) {
          dispatch(investorViewFailure(err));
          return err.response
        }
      };


//====================================craeteSub-Comment =======================


export const createSubCommentInitiate = () => ({
    type: investorViewAuthAction.CREATE_SUB_COMMENT_INITIATE,
  });
  
  export const createSubCommentSuccess = (data) => ({
    type: investorViewAuthAction.CREATE_SUB_COMMENT_SUCCESS,
    payload: data,
  });
  
  export const createSubCommentFailure = (data) => ({
    type: investorViewAuthAction.CREATE_SUB_COMMENT_FAILURE,
    payload: data,
  });
  
    export const createSubComment = (investId,compId,payload) => async (dispatch) => {
        try {
          const investorViewData=JSON.parse(window.localStorage.getItem("investorViewLogin"));
          dispatch(createSubCommentInitiate(payload));
          const response = await axios.post(`${Url}investor-view/${investId}/sub-discussions?commentableType=Company&commentableId=${compId}`,payload, {
            headers: { Authorization: `Bearer ${investorViewData?.user?.token}` },
          });
          if (response.status == 200) {
            dispatch(createSubCommentSuccess(response.data));
          } else {
            dispatch(createSubCommentFailure(response.data));
          }
          return response;
        } catch (err) {
          dispatch(createSubCommentFailure(err));
          return err.response
        }
      };
//================================getSubComment=====================================


export const getSubCommentInitiate = () => ({
    type: investorViewAuthAction.GET_SUB_COMMENT_INITIATE,
  });
  
  export const getSubCommentSuccess = (data) => ({
    type: investorViewAuthAction.GET_SUB_COMMENT_SUCCESS,
    payload: data,
  });
  
  export const getSubCommentFailure = (data) => ({
    type: investorViewAuthAction.GET_SUB_COMMENT_FAILURE,
    payload: data,
  });
  
    export const getSubCommentList = (investId,disId,compId) => async (dispatch) => {
        try {
          const investorViewData=JSON.parse(window.localStorage.getItem("investorViewLogin"));
          dispatch(getSubCommentInitiate());
          const response = await axios.get(`${Url}investor-view/${investId}/discussions/${disId}/sub-discussions?commentableType=Company&commentableId=${compId}`, {
            headers: { Authorization: `Bearer ${investorViewData?.user?.token}` },
          });
          if (response.status == 200) {
            dispatch(getSubCommentSuccess(response.data));
          } else {
            dispatch(getSubCommentFailure(response.data));
          }
          return response;
        } catch (err) {
          dispatch(getSubCommentFailure(err));
          return err.response
        }
      };
//==========================BUILDER---LIST==============================


export const investorBuilderListInitiate = () => ({
  type: investorViewAuthAction.INVESTOR_BUILDER_LIST_INITIATE,
});

export const investorBuilderListSuccess = (data) => ({
  type: investorViewAuthAction.INVESTOR_BUILDER_LIST_SUCCESS,
  payload: data,
});

export const investorBuilderListFailure = (data) => ({
  type: investorViewAuthAction.INVESTOR_BUILDER_LIST_FAILURE,
  payload: data,
});

  export const getInvestorBuilderList = () => async (dispatch) => {
      try {
        const investorViewData=JSON.parse(window.localStorage.getItem("investorViewLogin"));
        dispatch(investorBuilderListInitiate());
        const response = await axios.get(`${Url}trade/appointments/builderList`, {
          headers: { Authorization: `Bearer ${investorViewData?.user?.token}` },
        });
        if (response.status == 200) {
          dispatch(investorBuilderListSuccess(response.data));
        } else {
          dispatch(investorBuilderListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(investorBuilderListFailure(err));
        return err.response
      }
    };

//=============================builderByproject===================================


export const investorBuilderByProjectInitiate = () => ({
  type: investorViewAuthAction.INVESTOR_BUILDER_BY_PROJECT_INITIATE,
});

export const investorBuilderByProjectSuccess = (data) => ({
  type: investorViewAuthAction.INVESTOR_BUILDER_BY_PROJECT_SUCCESS,
  payload: data,
});

export const investorBuilderByProjectFailure = (data) => ({
  type: investorViewAuthAction.INVESTOR_BUILDER_BY_PROJECT_FAILURE,
  payload: data,
});

  export const getProjectByBuilder = (compId) => async (dispatch) => {
      try {
        const investorViewData=JSON.parse(window.localStorage.getItem("investorViewLogin"));
        dispatch(investorBuilderByProjectInitiate());
        const response = await axios.get(`${Url}trade/appointments/builderList/${compId}/projects`, {
          headers: { Authorization: `Bearer ${investorViewData?.user?.token}` },
        });
        if (response.status == 200) {
          dispatch(investorBuilderByProjectSuccess(response.data));
        } else {
          dispatch(investorBuilderByProjectFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(investorBuilderByProjectFailure(err));
        return err.response
      }
    };

//============================jobByproject=============================

export const investorJobByProjectInitiate = () => ({
  type: investorViewAuthAction.INVESTOR_JOB_BY_PROJECT_INITIATE,
});

export const investorJobByProjectSuccess = (data) => ({
  type: investorViewAuthAction.INVESTOR_JOB_BY_PROJECT_SUCCESS,
  payload: data,
});

export const investorJobByProjectFailure = (data) => ({
  type: investorViewAuthAction.INVESTOR_JOB_BY_PROJECT_FAILURE,
  payload: data,
});

  export const getInvestorJobByProject = (compId,projId) => async (dispatch) => {
      try {
        const investorViewData=JSON.parse(window.localStorage.getItem("investorViewLogin"));
        dispatch(investorJobByProjectInitiate());
        const response = await axios.get(`${Url}trade/appointments/builderList/${compId}/projects/${projId}/jobs`, {
          headers: { Authorization: `Bearer ${investorViewData?.user?.token}` },
        });
        if (response.status == 200) {
          dispatch(investorJobByProjectSuccess(response.data));
        } else {
          dispatch(investorJobByProjectFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(investorJobByProjectFailure(err));
        return err.response
      }
    };

//================================fileNfolder=========================

export const investorFolderNFileInitiate = () => ({
  type: investorViewAuthAction.INVESTOR_FOLDER_N_FILE_LIST_INITIATE,
});

export const investorFolderNFileSuccess = (data) => ({
  type: investorViewAuthAction.INVESTOR_FOLDER_N_FILE_LIST_SUCCESS,
  payload: data,
});

export const investorFolderNFileFailure = (data) => ({
  type: investorViewAuthAction.INVESTOR_FOLDER_N_FILE_LIST_FAILURE,
  payload: data,
});

  export const getInvestorFolderNFile = (compID,tradId) => async (dispatch) => {
      try {
        const investorViewData=JSON.parse(window.localStorage.getItem("investorViewLogin"));
        dispatch(investorFolderNFileInitiate());
        const response = await axios.get(`${Url}companies/${compID}/trade-view/${tradId}/trade-file-manager`, {
          headers: { Authorization: `Bearer ${investorViewData?.user?.token}` },
        });
        if (response.status == 200) {
          dispatch(investorFolderNFileSuccess(response.data));
        } else {
          dispatch(investorFolderNFileFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(investorFolderNFileFailure(err));
        return err.response
      }
    };


//==============================GET-notification==================

export const getInvestorNotificationsInitiate = () => ({
  type: investorViewAuthAction.GET_INVESTOR_NOTIFICATIONS_INITIATE,
});

export const getInvestorNotificationsSuccess = (data) => ({
  type: investorViewAuthAction.GET_INVESTOR_NOTIFICATIONS_SUCCESS,
  payload: data,
});

export const  getInvestorNotificationsFailure = (data) => ({
  type: investorViewAuthAction.GET_INVESTOR_NOTIFICATIONS_FAILURE,
  payload: data,
});

export const getInvestorNotifications = () => async (dispatch) => {
  try {
    const investorViewData=JSON.parse(window.localStorage.getItem("investorViewLogin"));
    dispatch(getInvestorNotificationsInitiate())
    const response = await axios.get(`${Url}companies/${investorViewData?.user?.CompanyId}/notifications?timeFrame=Today`,
      {
        headers: { Authorization: `Bearer ${investorViewData?.user?.token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getInvestorNotificationsSuccess(response.data));
    } else {
      dispatch(getInvestorNotificationsFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getInvestorNotificationsFailure(err));
    return err.response;
  }
};