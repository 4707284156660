

import { accountingAction,  } from "../actionTypes";
const initialState = {
    contractList:[],
    contractLoader:false,
    transactionLoader:false, 
    transactionList: [],
    primaryClientsDetails:[],
    schedulePaymentList:[],
    followUpList:[],
    estimationDocumentList:[],
    soaDetails:[],
    allSupportDocsList:[],
};

export default function accountingReducer(state = initialState, { type, payload }) {
  switch (type) {
    case accountingAction.GET_ALL_CONTRACT_LIST_INITIATE: {
        return {  ...state,contractLoader:true, contractList: []};
      }
      case accountingAction.GET_ALL_CONTRACT_LIST_SUCCESS: {
        return {  ...state,contractLoader:false, contractList:payload};
      }
      case accountingAction.GET_ALL_CONTRACT_LIST_FAILURE: {
        return {  ...state,contractLoader:false, contractList: []};
      }

      case accountingAction.TRANSACTON_LIST_INITIATE: {
        return {  ...state,transactionLoader:true, transactionList: []};
      }
      case accountingAction.TRANSACTON_LIST_SUCCESS: {
        return {  ...state,transactionLoader:false, transactionList:payload};
      }
      case accountingAction.TRANSACTON_LIST_FAILURE: {
        return {  ...state,transactionLoader:false, transactionList: []};
      }

      case accountingAction.GET_ALL_PRIMARY_CLIENTS_INITIATE: {
        return {  ...state, primaryClientsDetails: []};
      }
      case accountingAction.GET_ALL_PRIMARY_CLIENTS_SUCCESS: {
        return {  ...state, primaryClientsDetails:payload};
      }
      case accountingAction.GET_ALL_PRIMARY_CLIENTS_FAILURE: {
        return {  ...state,primaryClientsDetails: []};
      }

      case accountingAction.GET_SCHEDULE_PAYMENT_INITIATE: {
        return {  ...state, schedulePaymentList: []};
      }
      case accountingAction.GET_SCHEDULE_PAYMENT_SUCCESS: {
        return {  ...state, schedulePaymentList:payload};
      }
      case accountingAction.GET_SCHEDULE_PAYMENT_FAILURE: {
        return {  ...state,schedulePaymentList: []};
      }

      case accountingAction.GET_FOLLOW_UPS_LIST_INITIATE: {
        return {  ...state, followUpList: []};
      }
      case accountingAction.GET_FOLLOW_UPS_LIST_SUCCESS: {
        return {  ...state, followUpList:payload};
      }
      case accountingAction.GET_FOLLOW_UPS_LIST_FAILURE: {
        return {  ...state,followUpList: []};
      }

      case accountingAction.GET_ESTIMATION_DOCUMENT_LIST_INITIATE: {
        return {  ...state, estimationDocumentList: []};
      }
      case accountingAction.GET_ESTIMATION_DOCUMENT_LIST_SUCCESS: {
        return {  ...state, estimationDocumentList:payload};
      }
      case accountingAction.GET_ESTIMATION_DOCUMENT_LIST_FAILURE: {
        return {  ...state,estimationDocumentList: []};
      }

      case accountingAction.GET_SOA_DETAILS_INITIATE: {
        return {  ...state, soaDetails: []};
      }
      case accountingAction.GET_SOA_DETAILS_SUCCESS: {
        return {  ...state, soaDetails:payload};
      }
      case accountingAction.GET_SOA_DETAILS_FAILURE: {
        return {  ...state,soaDetails: []};
      }

      case accountingAction.GET_ALL_SUPPORTING_DOCS_TRANSACTION_INITIATE: {
        return {  ...state, allSupportDocsList: []};
      }
      case accountingAction.GET_ALL_SUPPORTING_DOCS_TRANSACTION_SUCCESS: {
        return {  ...state, allSupportDocsList:payload};
      }
      case accountingAction.GET_ALL_SUPPORTING_DOCS_TRANSACTION_FAILURE: {
        return {  ...state,allSupportDocsList: []};
      }

    default:
      return state;
  }
}
