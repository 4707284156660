import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../App.css";
import { toast } from "react-toastify";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  createBackChargeNotifications,
  getBCNotificationById,
} from "../redux/action/estimationAction";
import { Modal } from "react-bootstrap";

const initialState = {
  viewData: [],
  message: "",
  estimatedValue: "",
  supportingDocFiles: [],
  ViewAttachDataModal: false,
  ViewAttachData: "",
  docsType:"",
};

const EditBackChargeNotification = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const {
    viewData,
    message,
    estimatedValue,
    supportingDocFiles,
    ViewAttachDataModal,
    ViewAttachData,
    docsType,
  } = iState;
  const location = useLocation();
  const urlParams = new URLSearchParams(
    location?.search?.split("?")[1]?.split("#")[0]
  );

  const backChargeNotificationId = urlParams?.get("backChargeNotificationId");
  const token = urlParams?.get("token");

  useEffect(() => {
    dispatch(getBCNotificationById(backChargeNotificationId, token)).then(
      (res) => {
        if (res.status === 200) {
          updateState({ ...iState, viewData: res?.data });
        }
      }
    );
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const handleFileChanges = (event, type) => {
    const files = event?.target?.files;
    if (files) {
      if (type === "docsUpdate") {
        updateState({ ...iState, supportingDocFiles: [...files] });
      }
    }
  };

  const handleCreateBackChargeNotificationReply = (types) => {
    const formData = new FormData();
    formData.append("message", message || "");
    formData.append("reply", true);
    formData.append("status", types === "approve" ? "APPROVED" : "RETURNED");
    formData.append("estimatedValue", estimatedValue || "");
    formData.append("backChargevendorId", viewData?.backChargevendorId);
    formData.append("backChargeDocumentId", viewData?.backChargeDocumentId);
    formData.append("originalDocumentId", viewData?.originalDocumentId || "");
    formData.append(
      "originalDocumentType",
      viewData?.originalDocumentType || ""
    );
    Array.from(supportingDocFiles).forEach((image) => {
      formData.append("supportingDocFiles", image);
    });

    dispatch(createBackChargeNotifications(formData, token)).then((res) => {
      if (res.status == 201) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(getBCNotificationById(backChargeNotificationId, token)).then(
          (response) => {
            if (response.status === 200) {
              updateState({ ...iState, viewData: response?.data });
            }
          }
        );

        updateState({
          ...iState,
          message: "",
          estimatedValue: "",
          supportingDocFiles: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleRemoveDocsFile = (i) => {
    let user = [...supportingDocFiles];
    user.splice(i, 1);
    updateState({
      ...iState,
      supportingDocFiles: user,
    });
  };

  const handleViewAttachDataModalClose = () => {
    updateState({ ...iState, ViewAttachDataModal: false });
  };

  const handleViewAttachDataModalShow = (item) => {
    updateState({
      ...iState,
      ViewAttachDataModal:true,
      ViewAttachData: item?.docUrl?.split("?")[0],
      docsType:item.blobName?.split(".")[1]
    });
  };
  console.log(docsType,ViewAttachData);
  return (
    <div
      style={{
        background: "#ffa50066",
        paddingTop: "10px",
      }}
    >
      <div
        className="FormArea"
        style={{
          margin: "10px 15%",
          padding: "50px",
          background: "white",
          borderRadius: "10px",
        }}
      >
        <div className="ModalTitleBox">
          <h4>Vendor Back Charge Message</h4>
        </div>
        <div className="TableHeadingArea">
          <div className="ModalMediumheading">
            <h3>
              <span>Vendor:</span> {viewData?.Vendor?.vendorName ?? "N/A"}
            </h3>
          </div>
          <div className="ModalMediumheading d-flex flex-column">
            <h3>
              <span>From: </span>

              {viewData?.sendByUser?.name ?? "N/A"}
            </h3>{" "}
            {"   "}
            <h3 style={{ marginLeft: "40px!important" }}>
              <span>Date: </span>
              {moment(viewData?.createdAt).format("YYYY-MM-DD & hh:mm a")}
            </h3>
          </div>

          <div className="form-group">
            <h6>Message :</h6>
            <p>{viewData?.message ?? "N/A"}</p>
          </div>
          <div className="form-group">
            <h6>Attachments :</h6>
            <div>
              {viewData?.attachments?.length > 0 ?
                viewData?.attachments?.map((item, i) => {
                  return (
                    <p>
                      {`${i + 1}) ${item.blobName}`} {" "}
                      <span
                        onClick={() =>
                          handleViewAttachDataModalShow(item)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <img src="images/file_name.png" title={item.blobName} className="ml-3" />
                      </span>
                    </p>
                  );
                }):"No Data Available"}
            </div>
          </div>
          <div className="form-group">
            <h6>Estimated Value :</h6>
            <p>$ {viewData?.estimatedValue??0}</p>
          </div>
          <div className="form-group">
            <h6>Message</h6>
            <textarea
              className="form-control"
              rows={4}
              placeholder="Include message (optional)"
              name="message"
              value={message}
              onChange={handleInput}
            />
          </div>
          <div className="form-group">
            <h6>Attachments</h6>
            <div className="Upload">
              <span>-Drag and Drop Files or click to select</span>
              <input
                type="file"
                id="docsInput"
                // multiple
                onChange={(e) => handleFileChanges(e, "docsUpdate")}
              />
            </div>
          </div>
          {supportingDocFiles?.length > 0 &&
            supportingDocFiles?.map((item, i) => {
              return (
                <div className="FilesAdded">
                  <label>{item?.name}</label>
                  <span>
                    <img src="images/fileview.png" />
                  </span>
                  <span onClick={() => handleRemoveDocsFile(item.id)}>
                    <img src="images/Cross.png" className="cross" />
                  </span>
                </div>
              );
            })}
          <div className="form-group">
            <h6>Estimated Value</h6>
            <input
              type="number"
              className="form-control"
              placeholder="Insert value to be charged"
              name="estimatedValue"
              value={estimatedValue}
              onChange={handleInput}
            />
          </div>
        </div>
        {viewData?.BackCharge?.status === "SEND" ? (
          <div className="Button mt-4 d-flex justify-content-between">
            <button
              className="Create FolderPermissionId"
              onClick={() => handleCreateBackChargeNotificationReply("reply")}
            >
              Send Reply
            </button>
            <button
              className="Create FolderPermissionId Green"
              style={{
                backgroundColor: "rgb(84, 130, 20) !important",
              }}
              onClick={() => handleCreateBackChargeNotificationReply("approve")}
            >
              Approved BackCharge
            </button>
          </div>
        ) : (
          <h4>An Action has performed</h4>
        )}
      </div>
      <Modal
        show={ViewAttachDataModal}
        onHide={handleViewAttachDataModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleViewAttachDataModalClose}
          >
            ×
          </a>
          <div className="ModalTitleBox">
            <h4> View Attachment</h4>
            <a href={ViewAttachData} target="_self"
             className="UploadPriceBook DownloadPriceBook"
            style={{
              borderRadius: "10px",
    padding: "10px"
            }}
            >
              Download Docs
            </a>
          </div>
      
          {(ViewAttachData && docsType==="docx") ? (
  <iframe
    src={`https://docs.google.com/gview?url=${encodeURIComponent(ViewAttachData)}&embedded=true`}
    title="Document Viewer"
    width="100%"
    height="500"
    frameBorder="0"
    allowFullScreen
  ></iframe>
) : (
  <iframe
    src={ViewAttachData}
    title="Document Viewer"
    width="100%"
    height="500"
    frameBorder="0"
    allowFullScreen
  ></iframe>
)}

        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditBackChargeNotification;
