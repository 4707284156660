import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetPasswordAction } from '../redux/action/authAction';
import { sucessToast } from '../utils/toast';
const init = {
    password:"",
    confirmPassword:"",
    oldPassword:"",
    errors:{}


}
function ResetPassword() {
    const[iState, updateState] =  useState(init)
    const{password, confirmPassword, oldPassword, errors} = iState;
    const dispatch  = useDispatch();
    const navigate = useNavigate();
 
    const location = useLocation();
    const urlParams = new URLSearchParams(
      location?.search?.split("?")[1].split("#")[0]
    );
    const companyId = urlParams?.get("companyId");
    const userId = urlParams?.get("user");
    const token = urlParams?.get("resetToken");

    const handleInput = (e) => {
        const {name,value} = e.target;
        updateState({...iState, [name]:value})
       }

       const handleValidation = (e) => {
        let formIsValid = true;
        let error = {}
        if(!password){
            error.passwordError = "Password can't be empty";
            formIsValid = false
        }

        // if(!oldPassword){
        //     error.oldPasswordError = "Old password can't be empty";
        //     formIsValid = false
        // }

        if(!confirmPassword){
            error.confirmPasswordError = "Confirm password can't be empty";
            formIsValid = false
        }

        if(password !== confirmPassword){
            error.confirmPasswordError = "Confirm password and password must be the same";
            formIsValid = false
        }
        updateState({...iState, errors:error})
        return formIsValid
       }
   const handleResetPass = (e) => {
    e.preventDefault();
       let formIsValid  = handleValidation();
       if(formIsValid){
        dispatch(resetPasswordAction({"password" : password}, userId, companyId, token)).then((res) => {
            console.log("ressss",res)
            sucessToast("Password changed successfully")
            navigate("/")
        })
       }
   }

  return (
    <>
     <div className="LoginArea">
  <div className="LoginBoxBig">
    <div className="row">
      <div className="col-md-6">
        <div className="LoginBoxLeft">
          <figure>
            <img src="images/login_background.png" />
          </figure>
        </div>
      </div>
      <div className="col-md-6">
        <div className="LoginBox">
          {/* s */}
          <ul className="nav nav-fill">
            <li className="nav-item">
              <a
                href="#SystemAdmin"
                className="nav-link active"
                data-toggle="tab"
              >
                IBuild Admin
              </a>
            </li>
          
          </ul>
          <div className="tab-content">
            <div className="tab-pane fade show active" id="SystemAdmin">
              <h3>Create a new Password</h3>
              <p>
                Your password must be at least six characters and should include
                a combination of special characters, numbers, lower case and
                upper case letters
              </p>
              <form>
                <div className="form-group">
                  <label>Old Password</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Old Password"
                    name='oldPassword'
                    value={oldPassword}
                    onChange={handleInput}
                  />
                  <span style={{color:"red", fontSize:"14px"}}>{errors?.oldPasswordError}</span>
                </div>
                <div className="form-group">
                  <label>New Password</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter New Password"
                    name='password'
                    value={password}
                    onChange={handleInput}
                  />
                    <span style={{color:"red", fontSize:"14px"}}>{errors?.passwordError}</span>
                </div>
                <div className="form-group">
                  <label>Confirm New Password</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Confirm new Password"
                    name='confirmPassword'
                    value={confirmPassword}
                    onChange={handleInput}
                  />
                   <span style={{color:"red", fontSize:"14px"}}>{errors?.confirmPasswordError}</span>
                </div>
                <a className="Button" onClick={handleResetPass}>
                  Reset Password
                </a>
              </form>
             
            </div>
          
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default ResetPassword