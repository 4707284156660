import axios from "axios";
import { JobActionTypes } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config/Config";
import { multiPartData } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));


export const getAllJobListInitate = () => ({
    type: JobActionTypes.JOB_LIST_INITIATE,
  });
  
  export const getAllJobListSuccess = (data) => ({
    type: JobActionTypes.JOB_LIST_SUCCESS,
    payload: data,
  });
  
  export const getAllJobListFailure = (data) => ({
    type: JobActionTypes.JOB_LIST_FAILURE,
    payload: data,
  });
  
  export function getAllJobListAction( pid ) {

    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(getAllJobListInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${pid ? pid :""}/jobs`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            ////console.log("data", data);
            if (data && data.status == 200) {
              dispatch(getAllJobListSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(getAllJobListFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(getAllJobListSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(getAllJobListFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }
  


  
export const getAllProjectListInitate = () => ({
  type: JobActionTypes.PROJECT_LIST_INITIATE,
});

export const getAllProjectListSuccess = (data) => ({
  type: JobActionTypes.PROJECT_LIST_SUCCESS,
  payload: data,
});

export const getAllProjectListFailure = (data) => ({
  type: JobActionTypes.PROJECT_LIST_FAILURE,
  payload: data,
});

export function getAllProjectListAction(pName,jobType ) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
    dispatch(getAllProjectListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/all-project?projectName=${pName ? pName :""}&projectType=${jobType ? jobType :""}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(getAllProjectListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllProjectListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getAllProjectListSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getAllProjectListFailure(err));
            reject(err);
          }
         
        });
    });
  };
}



  
export const getAllProjectDetailsInitate = () => ({
  type: JobActionTypes.PROJECT_DETAILS_INITIATE,
});

export const getAllProjectDetailsSuccess = (data) => ({
  type: JobActionTypes.PROJECT_DETAILS_SUCCESS,
  payload: data,
});

export const getAllProjectDetailsFailure = (data) => ({
  type: JobActionTypes.PROJECT_DETAILS_FAILURE,
  payload: data,
});

export function getAllProjectDetailsAction( pid = 3) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllProjectDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${pid}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllProjectDetailsSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllProjectDetailsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getAllProjectDetailsSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getAllProjectDetailsFailure(err));
            reject(err);
          }
         
        });
    });
  };
}




export const createResidentialInitate = () => ({
  type: JobActionTypes.ADD_RESIDENTIAL_JOB_INITIATE,
});

export const createResidentialSuccess = (data) => ({
  type: JobActionTypes.ADD_RESIDENTIAL_JOB_SUCCESS,
  payload: data,
});

export const createResidentialFailure = (data) => ({
  type: JobActionTypes.ADD_RESIDENTIAL_JOB_FAILURE,
  payload: data,
});

export function createResidentialAction( pid,payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(createResidentialInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${pid}/jobs/residential`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 201) {
            dispatch(createResidentialSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(createResidentialFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(createResidentialSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(createResidentialFailure(err));
            reject(err);
          }
         
        });
    });
  };
}




export const updateResidentialInitate = () => ({
  type: JobActionTypes.UPDATE_RESIDENTIAL_JOB_INITIATE,
});

export const updateResidentialSuccess = (data) => ({
  type: JobActionTypes.UPDATE_RESIDENTIAL_JOB_SUCCESS,
  payload: data,
});

export const updateResidentialFailure = (data) => ({
  type: JobActionTypes.UPDATE_RESIDENTIAL_JOB_FAILURE,
  payload: data,
});

export function updateResidentialAction( pid,JID,payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(updateResidentialInitate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${pid}/jobs/residential/${JID}`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 201) {
            dispatch(updateResidentialSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(updateResidentialFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(updateResidentialSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(updateResidentialFailure(err));
            reject(err);
          }
         
        });
    });
  };
}





  
export const getAllFoundationListInitate = () => ({
  type: JobActionTypes.FOUNDATION_TYPE_INITIATE,
});

export const getAllFoundationListSuccess = (data) => ({
  type: JobActionTypes.FOUNDATION_TYPE_SUCCESS,
  payload: data,
});

export const getAllFoundationListFailure = (data) => ({
  type: JobActionTypes.FOUNDATION_TYPE_FAILURE,
  payload: data,
});

export function getAllFoundationListAction( pid = 3, jobType = "Single Family") {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllFoundationListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}foundationtype`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(getAllFoundationListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllFoundationListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getAllFoundationListSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getAllFoundationListFailure(err));
            reject(err);
          }
         
        });
    });
  };
}




export const getAllHomeListInitate = () => ({
  type: JobActionTypes.HOME_TYPE_INITIATE,
});

export const getAllHomeListSuccess = (data) => ({
  type: JobActionTypes.HOME_TYPE_SUCCESS,
  payload: data,
});

export const getAllHomeListFailure = (data) => ({
  type: JobActionTypes.HOME_TYPE_FAILURE,
  payload: data,
});

export function getAllHomeListAction( ) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllHomeListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}homeTypes`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(getAllHomeListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllHomeListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
        
            dispatch(getAllHomeListFailure(err));
            reject(err);
              
        });
    });
  };
}




  
export const getAllTaxProfileInitate = () => ({
  type: JobActionTypes.TAX_PROFILE_LIST_INITIATE,
});

export const getAllTaxProfileSuccess = (data) => ({
  type: JobActionTypes.TAX_PROFILE_LIST_SUCCESS,
  payload: data,
});

export const getAllTaxProfileFailure = (data) => ({
  type: JobActionTypes.TAX_PROFILE_LIST_FAILURE,
  payload: data,
});

export function getAllTaxProfileAction( pid = 3, jobType = "Single Family") {
  const token = isLoggedIn("adminData1");
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  return (dispatch) => {
    dispatch(getAllTaxProfileInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}/taxprofile?CompanyId=${iBuildLocalData?.user?.CompanyId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(getAllTaxProfileSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllTaxProfileFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getAllTaxProfileSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getAllTaxProfileFailure(err));
            reject(err);
          }
         
        });
    });
  };
}




export const getResidentialDetailsInitate = () => ({
  type: JobActionTypes.RESIDENTIAL_DETAILS_INITIATE,
});

export const getResidentialDetailsSuccess = (data) => ({
  type: JobActionTypes.RESIDENTIAL_DETAILS_SUCCESS,
  payload: data,
});

export const getResidentialDetailsFailure = (data) => ({
  type: JobActionTypes.RESIDENTIAL_DETAILS_FAILURE,
  payload: data,
});

export function getResidentialDetailsAction( pid , ID) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getResidentialDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${pid}/jobs/residential/${ID}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(getResidentialDetailsSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getResidentialDetailsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getResidentialDetailsSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getResidentialDetailsFailure(err));
            reject(err);
          }    
        });
    });
  };
}



export function createResidentailConfrimationAction( pid,payload) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({...payload });
  return (dispatch) => {
    dispatch(createLandDevelopmentInitate());
    return new Promise((resolve, reject) => {
      axios
        .patch(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${pid}/jobs/residential/${payload}/complete`, {}, {
          headers: { Authorization: `Bearer ${token}` },
        })
        
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(createLandDevelopmentSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(createLandDevelopmentFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(createLandDevelopmentSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(createLandDevelopmentFailure(err));
            reject(err);
          }
         
        });
    });
  };
}




export const createLandDevelopmentInitate = () => ({
  type: JobActionTypes.ADD_RESIDENTIAL_JOB_INITIATE,
});

export const createLandDevelopmentSuccess = (data) => ({
  type: JobActionTypes.ADD_RESIDENTIAL_JOB_SUCCESS,
  payload: data,
});

export const createLandDevelopmentFailure = (data) => ({
  type: JobActionTypes.ADD_RESIDENTIAL_JOB_FAILURE,
  payload: data,
});

export function createLandDevelopmentAction( pid,payload) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({...payload });
  return (dispatch) => {
    dispatch(createLandDevelopmentInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${pid}/jobs/land-development`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 201) {
            dispatch(createLandDevelopmentSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(createLandDevelopmentFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(createLandDevelopmentSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(createLandDevelopmentFailure(err));
            reject(err);
          }
         
        });
    });
  };
}




  
export const getAllLandDevelopmentDetailsInitate = () => ({
  type: JobActionTypes.LAND_DEVELOPMENT_DETAILS_INITIATE,
});

export const getAllLandDevelopmentDetailsSuccess = (data) => ({
  type: JobActionTypes.LAND_DEVELOPMENT_DETAILS_SUCCESS,
  payload: data,
});

export const getAllLandDevelopmentDetailsFailure = (data) => ({
  type: JobActionTypes.LAND_DEVELOPMENT_DETAILS_FAILURE,
  payload: data,
});

export function getAllLandDevelopmentDetailsAction( pid,id) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllLandDevelopmentDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${pid ? pid :""}/jobs/land-development?id=${id?id:""}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(getAllLandDevelopmentDetailsSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllLandDevelopmentDetailsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getAllLandDevelopmentDetailsSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getAllLandDevelopmentDetailsFailure(err));
            reject(err);
          }
         
        });
    });
  };
}




export const updateLandDevelopmentInitate = () => ({
  type: JobActionTypes.UPDATE_LAND_DEVELOPMENT_JOB_INITIATE,
});

export const updateLandDevelopmentSuccess = (data) => ({
  type: JobActionTypes.UPDATE_LAND_DEVELOPMENT_JOB_SUCCESS,
  payload: data,
});

export const updateLandDevelopmentFailure = (data) => ({
  type: JobActionTypes.UPDATE_LAND_DEVELOPMENT_JOB_FAILURE,
  payload: data,
});

export function updateLandDevelopmentAction( pid,JID,payload) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(updateLandDevelopmentInitate());
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${pid}/jobs/land-development/${JID}`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if (data && data.status == 201) {
            dispatch(updateLandDevelopmentSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(updateLandDevelopmentFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(updateLandDevelopmentSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(updateLandDevelopmentFailure(err));
            reject(err);
          }
         
        });
    });
  };
}




export function getMultiFamilyDetailsAction( pid , ID) {
  const token = isLoggedIn("adminData1");
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${pid}/jobs/multi-family?id=${ID}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            resolve(err.data)
          }else {
            reject(err);
          }    
        });
    });
  };
}



///////////////////////====craeteTaxProfile==========////////////////

export const createAllTaxProfileInitate = () => ({
  type: JobActionTypes.CREATE_TAX_PROFILE_LIST_INITIATE,
});

export const createAllTaxProfileSuccess = (data) => ({
  type: JobActionTypes.CREATE_TAX_PROFILE_LIST_SUCCESS,
  payload: data,
});

export const createAllTaxProfileFailure = (data) => ({
  type: JobActionTypes.CREATE_TAX_PROFILE_LIST_FAILURE,
  payload: data,
});

export function createAllTaxProfileAction(data) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(createAllTaxProfileInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}taxprofile`, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            dispatch(createAllTaxProfileSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(createAllTaxProfileFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(createAllTaxProfileSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(createAllTaxProfileFailure(err));
            reject(err);
          }
        });
    });
  };
}
