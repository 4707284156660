import React, { useState, useEffect } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import {
  getTradeVendorByUserId,
  getUploadMainTradeImage,
  getUploadTradeIconImage,
  createTradeComment,
  getTradeCommentList,
  getTradeView,
  getTradeSubCommentList,
  createTradeSubComment,
  getTodoBuilderList,
  getTodoAllProjectList,
  getTodoProjectJobList,
  getJobByProjectList,
  getProjectByBuilder,
  getBuilderList,
  getTradeNotifications,
} from "../redux/action/tradeViewAuthAction";
import TradeViewSidenav from "./trade-view-sidenav";
import TradeHeader from "./TradeHeader";

const initialState = {
  file: null,
  mainFile: null,
  content: "",
  projectId: "",
  investorId: "",
  subContent: "",
  index: "",
  parentId: "",
  job: "",

  CompanyId: "",
  companyId: "",
  jobId: "",
  notificationData:[],
};

const TradeViewHome = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const tradeViewLoginLocal = JSON.parse(localStorage.getItem("tradeViewLogin"))
  console.log("tradeViewLoginLocal",tradeViewLoginLocal)
  const {
    file,
    mainFile,
    content,
    projectId,
    investorId,
    subContent,
    index,
    parentId,
    job,

    jobId,
    companyId,
    notificationData,
  } = iState;
  const {
    vendorTradeData,
    tradeCommentList,
    builderList,
    projectList,
    projectJobList,
    tradeSubCommentList,

    projectByBuilderData,
    jobByProjectData,
  } = useSelector((state) => state.tradeViewLogin);
  console.log("notificationData", notificationData);
  const compId = JSON.parse(window.localStorage.getItem("tradeViewLogin"));

  useEffect(() => {
    if (compId?.user?.CompanyId != null && compId?.user?.token != null) {
      dispatch(getTodoAllProjectList(compId?.user?.CompanyId));
      dispatch(getTradeView(compId?.user?.CompanyId)).then((res) => {
        // console.log("newresss",res);
        dispatch(
          getTradeCommentList(
            res?.data?.ViewSetting?.id,
            compId?.user?.CompanyId
          )
        );
        dispatch(
          getTradeVendorByUserId(res?.data?.ViewSetting?.id, compId?.user?.id)
        );
        // dispatch(getSubCommentList(res?.data?.ViewSetting?.id,compId?.user?.CompanyId));
        updateState({ ...iState, investorId: res?.data?.ViewSetting?.id });
      });
    }

    dispatch(getBuilderList());
    dispatch(getTradeNotifications()).then((res)=>{
      if(res.status===200){
       
        updateState({...iState,notificationData:res.data.notifications})
      }
    })
  }, []);

  // useEffect(() => {
  //   dispatch(getTodoProjectJobList(compId?.user?.CompanyId, projectId)).then((res)=>{
  //     window.localStorage.setItem("jobInformation", JSON.stringify(res));
  //   });
  // }, [projectId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "companyId") {
      dispatch(getProjectByBuilder(value));
      updateState({
        ...iState,
        [name]: value,
      });
      window.localStorage.setItem("builderId", JSON.stringify(value));
    } else if (name === "projectId") {
      dispatch(getJobByProjectList(companyId, value));
      updateState({
        ...iState,
        [name]: value,
      });
    } else if (name === "jobId") {
      updateState({
        ...iState,
        [name]: value,
      });
    }
    else {
      updateState({
        ...iState,
        [name]: value,
      });
    }
  };

  const handleFileChanges = (event) => {
    const file = event?.target?.files[0];
    updateState({ ...iState, file: file });
    if (file != null) {
      const formData = new FormData();
      formData.append("file", file);
      dispatch(
        getUploadTradeIconImage(vendorTradeData?.VendorList?.id, formData)
      ).then((res) => {
        if (res.status === 200) {
          dispatch(getTradeVendorByUserId(investorId, compId?.user?.id));
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({ ...iState, file: null });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const handleMainFileChanges = (event) => {
    const files = event?.target?.files[0];
    updateState({ ...iState, mainFile: files });
    if (files != null) {
      const formData = new FormData();
      formData.append("file", files);
      dispatch(
        getUploadMainTradeImage(vendorTradeData?.VendorList?.id, formData)
      ).then((res) => {
        if (res.status === 200) {
          dispatch(getTradeVendorByUserId(investorId, compId?.user?.id));
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({ ...iState, mainFile: null });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const handleCreateComment = () => {
    const dataa = { content };
    dispatch(
      createTradeComment(investorId, compId?.user?.CompanyId, dataa)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getTradeCommentList(investorId, compId?.user?.CompanyId));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          content: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleRemoveComments = (type) => {
    if (type === "comment") {
      updateState({ ...iState, content: "" });
    } else {
      updateState({ ...iState, subContent: "" });
    }
  };
  const handleSubCreateComment = (id) => {
    const dataa = { content: subContent, parentId: id };
    dispatch(
      createTradeSubComment(investorId, compId?.user?.CompanyId, dataa)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(
          getTradeSubCommentList(investorId, id, compId?.user?.CompanyId)
        );
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          subContent: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  return (
    <>
      <>
        {/* <Header /> */}
        <TradeHeader />
        <TradeViewSidenav />
        <div className="WrapperAreaClient">
          <div className="WrapperBoxClient">
            <div className="TitleBoxClient">
              <h4 className="TitleClient">Vendor Overview</h4>
            </div>
            <div className="row">
              <div className="col-md-9">
                <div className="ProjectViewTop">
                  <figure>
                    {/* <img src={require("../assets/images/investor-banner.png")} /> */}
                    <img src={vendorTradeData?.VendorList?.mainUrl} />
                    <div className="CameraOverlay">
                      <input
                        type="file"
                        onChange={(e) => handleMainFileChanges(e)}
                      />
                      <img src="images/camera_icon.png" />
                    </div>
                  </figure>
                </div>
                <div className="row">
                  <div className="col-md-12">
                  
                    <div className="HomeUpdatesAreaTrade ">
                      <div className="row">
                         <div className="col-lg-4">
                         <div
                        className="BuilderBox"
                
                      >
                        <h4>Builder</h4>
        
                        <div className="SelectBuilder">
                          <select
                            className="CommonSelect"
                            name="companyId"
                            value={companyId}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Builder</option>
                            {builderList?.data?.length > 0 &&
                              builderList?.data?.map((item, i) => {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                         </div>
                     
                      <div className="col-lg-4">
                      <div className="BuilderBox">
                        <h4>Project</h4>
                        <div className="SelectBuilder">
                          <select
                            className="CommonSelect"
                            name="projectId"
                            value={projectId}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Project</option>
                            {projectByBuilderData?.data?.length > 0 &&
                              projectByBuilderData?.data?.map((item, i) => {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.projectName}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      </div>
                      
                      <div className="col-lg-4">
                      <div className="BuilderBox">
                        <h4>Job</h4>
                        <div className="SelectBuilder">
                          <select
                            className="CommonSelect"
                            name="jobId"
                            value={jobId}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Jobs</option>
                            {jobByProjectData?.data?.length > 0 &&
                              jobByProjectData?.data?.map((item, i) => {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.jobType}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                <div className="HomeUpdatesArea">
                  <div className="HomeUpdatesAreaTop">
                    <h6>Updates</h6>
                    <h6>Dates</h6>
                  </div>
                  <div className="HomeUpdatesAreaBottom">
                    {
                      notificationData?.length>0&&
                      notificationData?.map((data,i)=>{
                        return(
                          <div className="HomeUpdatesAreaRow">
                          <h6>
                            <img src="images/update_1.png" />
                            {data.notifiMessage}
                          </h6>
                          <p>{moment(data.sendDate).format("MM/DD/YYYY - hh:mm A")}</p>
                        </div>
                        )
                      })
                    }
                   
                    {/* <div className="HomeUpdatesAreaRow">
                      <h6>
                        <img src="images/update_2.png" />1 new document to sign!
                      </h6>
                      <p>05/25/2023 - 02:00 PM</p>
                    </div>
                    <div className="HomeUpdatesAreaRow">
                      <h6>
                        <img src="images/update_3.png" />
                        Warranty Request - Plumbing Fixes was completed! Click
                        here to view and approve!
                      </h6>
                      <p>05/25/2023 - 02:00 PM</p>
                    </div>
                    <div className="HomeUpdatesAreaRow">
                      <h6>
                        <img src="images/update_4.png" />
                        New change order Change order one was created!
                      </h6>
                      <p>05/25/2023 - 02:00 PM</p>
                    </div>
                    <div className="HomeUpdatesAreaRow">
                      <h6>
                        <img src="images/update_5.png" />3 new discussion messages
                        arrived!
                      </h6>
                      <p>05/25/2023 - 02:00 PM</p>
                    </div> */}
                  </div>
                </div>
               
              </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="UserDetailsArea">
                  <div className="UserDetailsBox">
                    <figure>
                      <img src={vendorTradeData?.VendorList?.iconUrl} />
                      <div className="CameraOverlay">
                        <input
                          type="file"
                          onChange={(e) => handleFileChanges(e)}
                        />
                        <img src="images/camera_icon.png" />
                      </div>
                    </figure>
                    <p>Welcome back {tradeViewLoginLocal?.user?.name}!</p>
                    <p>{tradeViewLoginLocal?.user?.email}</p>
                    <p>Trade Associate</p>
                  </div>
                </div>
                <div className="SalesContactBox">
                  <h6>Builder information</h6>
                  <h6 className="Brand">
                  {tradeViewLoginLocal?.builderInfo?.name}<img src="images/location.png" />
                  </h6>
                  <p className="Address">
                  {tradeViewLoginLocal?.builderInfo?.streetAddressLine1}
                    <br />
                    {tradeViewLoginLocal?.builderInfo?.streetAddressLine2} , {" "} {tradeViewLoginLocal?.builderInfo?.country} 
                  </p>
                  <p>
                    <img src="images/send-email.png" />
                    {tradeViewLoginLocal?.builderInfo?.phoneNumber}
                  </p>
                  <p>
                    <img src="images/call.png" />
                    {tradeViewLoginLocal?.builderInfo?.email}
                  </p>
                </div>
              </div>
            </div>
            <div className="DiscussionArea">
              <div className="DiscussionTitle">
                <h4>Discussion</h4>
                <span>{tradeCommentList?.comments?.length}</span>
                <div className="DiscussionChat">
                  <img src="images/down.png" className="DiscussionIcon" />
                  <a href="#">
                    <img src="images/pdf2.png" />
                  </a>
                </div>
              </div>
              <div className="DiscussionHideArea" style={{ display: "block" }}>
                <div className="DiscussionBox">
                  <span>FM</span>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Add a Comment ....."
                      name="content"
                      value={content}
                      onChange={handleInputChange}
                    />
                    <div className="DiscussionOverlay">
                      <a
                        className="CancelChat"
                        onClick={() => handleRemoveComments("comment")}
                      >
                        <img src="images/plus-cancel1.png" />
                      </a>
                      <button
                        className="SendChat"
                        onClick={handleCreateComment}
                        disabled={content == ""}
                      >
                        <img src="images/forwardbutton.png" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="DiscussionBox"></div>
                {tradeCommentList?.comments?.length > 0 &&
                  tradeCommentList?.comments
                    ?.sort((a, b) => b.id - a.id)
                    ?.map((item, ind) => {
                      return (
                        <div className="DiscussionBox">
                          <span>TH</span>
                          <div className="DiscussionRight">
                            <h6>{item.content}</h6>
                            <small>
                              {moment(item.createdAt).format(
                                "MM/DD/YYYY, [at] hh:mm A"
                              )}
                            </small>

                            <div class="ReplyArea">
                              <a
                                onClick={() =>
                                  updateState({
                                    ...iState,
                                    index: ind,
                                    parentId: item.id,
                                  })
                                }
                              >
                                Reply
                              </a>
                              {index === ind &&
                                tradeSubCommentList?.comments?.length > 0 &&
                                tradeSubCommentList?.comments?.map((ele) => (
                                  <div class="DiscussionBox">
                                    <span>RE</span>
                                    <div class="DiscussionRight">
                                      <h6>{ele.content}</h6>
                                      <small>
                                        {moment(ele.createdAt).format(
                                          "MM/DD/YYYY, [at] hh:mm A"
                                        )}
                                      </small>
                                    </div>
                                  </div>
                                ))}
                              {index === ind && (
                                <div class="DiscussionBox w-100">
                                  <span>FM</span>
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Add reply ....."
                                      name="subContent"
                                      value={subContent}
                                      onChange={(e) => handleInputChange(e)}
                                    />

                                    <div class="DiscussionOverlay">
                                      <a
                                        onClick={() =>
                                          handleRemoveComments("subcomment")
                                        }
                                        class="CancelChat"
                                      >
                                        <img
                                          src={require("../assets/images/plus-cancel1.png")}
                                        />
                                      </a>
                                      <a
                                        onClick={() =>
                                          handleSubCreateComment(item.id)
                                        }
                                        class="SendChat"
                                      >
                                        <img
                                          src={require("../assets/images/forwardbutton.png")}
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
        
      </>
    </>
  );
};

export default TradeViewHome;
