import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const init = {
  username:"",
  password:"",
}
const Login = () => {
  const[iState, updateState] = useState(init);
  const{username, password} =iState;
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const handleInput = (e) => {
    const{name,value} = e.target;
    updateState({...iState, [name]:value})
  }

  return (
    <div className="LoginArea">
  <div className="LoginBoxBig">
    <div className="row">
      <div className="col-md-6">
        <div className="LoginBoxLeft">
          <figure>
            <img src="images/login_background.png" />
          </figure>
        </div>
      </div>
      <div className="col-md-6">
        <div className="Logo">
          <img src="images/Logo.png" />
        </div>
        <div className="LoginBox">
          <ul className="nav nav-fill">
            <li className="nav-item">
              <a
                href="#ClientView"
                className="nav-link active"
                data-toggle="tab"
              >
                Client View
              </a>
            </li>
            <li className="nav-item">
              <a href="#TradeView" className="nav-link" data-toggle="tab">
                Trade View
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane fade show active" id="ClientView">
              <h3>Welcome Back</h3>
              <p>Login to continue</p>
              <form>
                <div className="form-group">
                  <label>Username</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter text"
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <div className="InputPasswordView">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter text"
                    />
                    <span>
                      <img src="images/lock_password.png" />
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="form-group">
                    <label className="CheckBox">
                      {" "}
                      Remember me
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-group">
                    <div className="Checkboxs">
                      <span>&nbsp;</span>
                      <a href="login-forgot.html">Forgot password?</a>
                    </div>
                  </div>
                </div>
                <a className="Button" href="client_view_home.html">
                  Login
                </a>
              </form>
            </div>
            <div className="tab-pane fade" id="TradeView">
              <h3>Welcome Back</h3>
              <p>Login to continue</p>
              <form>
                <div className="form-group">
                  <label>Username</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter text"
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <div className="InputPasswordView">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter text"
                    />
                    <span>
                      <img src="images/lock_password.png" />
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="form-group">
                    <label className="CheckBox">
                      {" "}
                      Remember me
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-group">
                    <div className="Checkboxs">
                      <span>&nbsp;</span>
                      <a href="login-forgot.html">Forgot password?</a>
                    </div>
                  </div>
                </div>
                <a className="Button" href="trade_view_home.html">
                  Login
                </a>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Login