import React from 'react'
import Modal from "react-bootstrap/Modal";

const ColumnModal = ({columnModal, setColumnModal}) => {


    const handleAddContactModalClose = () => setColumnModal(false);
    const handleAddContactModalShow = () => {
        setColumnModal(true);
    };
  
  return (
    <Modal show={columnModal} className="ModalBox LargeModal">
    <div class="modal-content">
      <a
        onClick={handleAddContactModalClose}
        class="CloseModal"
        data-dismiss="modal"
      >
        ×
      </a>{" "}

      <div class="FormArea">
              <div class="ModalTitleBox">
                <h4>Column</h4>
              </div>
              <div class="FolderForm">
                <form>
                 <div class="ColumnTable">
                  <table>
                    <tbody>
                      <tr>
                        <td>Column 1</td>
                        <td>
                          <label class="CheckBox">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                        </td>
                        <td>Column 2</td>
                        <td>
                          <label class="CheckBox">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                        </td>
                        <td>Column 3</td>
                        <td>
                          <label class="CheckBox">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                        </td>
                        <td>Column 4</td>
                        <td>
                          <label class="CheckBox">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>Column 5</td>
                        <td>
                          <label class="CheckBox">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                        </td>
                        <td>Column 6</td>
                        <td>
                          <label class="CheckBox">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                        </td>
                        <td>Column 7</td>
                        <td>
                          <label class="CheckBox">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                        </td>
                        <td>Column 8</td>
                        <td>
                          <label class="CheckBox">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>Column 9</td>
                        <td>
                          <label class="CheckBox">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                        </td>
                        <td>Column 10</td>
                        <td>
                          <label class="CheckBox">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                        </td>
                        <td>Column 11</td>
                        <td>
                          <label class="CheckBox">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                        </td>
                        <td>Column 12</td>
                        <td>
                          <label class="CheckBox">
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                 </div>
                 
                      

                  <div class="Button text-center mt-5">
                    <button class="Create">Done</button>
                  </div>
                </form>
              </div>
            </div>
   
    </div>
  </Modal>


  )
}

export default ColumnModal