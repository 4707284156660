import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { checkArray } from "../utils/CheckType";
import { useDispatch, useSelector } from "react-redux";
import {
  VendorSearchAction,
  VendorTypesAction,
  vendorDetailsAction,
} from "../redux/action/vendorAction";
import {
  addBudgetChangeOrderAction,
  addEstimationSupportingDocsAction,
  addFieldPurchaseOrderAction,
  addNewQuoteAction,
  addSupplierCreditAction,
  getAllBlockListAction,
  getAllBuilderSigneeAction,
  getAllEstimationUnitAction,
  getAllProfileListAction,
  getAllQuoteRequestAction,
  getAllReasonListAction,
  getAllUnitListAction,
  updateBillAction,
  updateBudgetChangeOrderAction,
  updateFieldPurchaseOrderAction,
  updatePurchaseDetailsAction,
  updateQuoteRequestAction,
  updateSupplierCreditAction,
} from "../redux/action/estimationAction";

import Loader from "./Loader";
import NoDataFound from "./Nodatafound";

import NewProjectStep1 from "./NewProject/NewProjectStep1";
import MultifamilyJobStep1 from "./MultifamilyJob/MultifamilyJobStep1";
import ProjectAsEntityStep1 from "./ProjectAsEntity/ProjectAsEntityStep1";

import { errorToast, sucessToast } from "../utils/toast";
import { calculateDateDifference, getDate } from "../utils/uploadFile";
import {
  getAllJobListAction,
  getAllProjectListAction,
} from "../redux/action/jobAction";
import {
  getLandPhaseList,
  getMultiBlocksList,
} from "../redux/action/homeAction";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { remove } from "lodash";
import { addSupportingDocsAction } from "../redux/action/saleAction";
import ProformaModal from "./ProformaModal";
import CompanyModal from "./CompanyModal";
import ResidentialJobModal from "./ResidentialJobModal";
import Landdevelopment from "./Land-development";
import { getAllCostCodeAction } from "../redux/action/authAction";
import { getMasterCostCategoryAction } from "../redux/action/costProfileAction";
import { Link } from "react-router-dom";

const initModal = {
  SelectJobModal: false,
  SelectCostModal: false,
  modalState: "",
  NewQuoteRequestModal: false,
  BillModal: false,
  BudgetChangeOrderModal: false,
  FieldPurchaseOrderModal: false,
  SupplyCreditModal: false,
  BackChargeModal: false,
  PurchaseModal: false,
  SystemVendorModal: false,
  reminderModal: false,
  BillQuoteReqModal: false,
  givenIndex: "",
  BudgetChangeOrderModal: false,
  viewBiddingModal: false,
};

const initLeadState = {
  vendorName: "",
  vendorType: "",
  systemErrors: {},
  vendorSearchData: [],
  jobCodeId: "",
  jobType: "",
  costCodeId: "",
  reasonId: "",
  vendorId: "",
  date: "",
  reminder: "",
  message: "",
  profile: "",
  subtotal: "",
  day: "",
  week: "",
  gst: "",
  grandTotal: "",
  scopeWorkId: "",
  constructionDocsId: "",
  externalQuoteId: "",
  calculatedGstAmount: "",
  newQuoteRequestError: {},
  selectJobError: {},
  selectCostError: {},
  itemErrors: "",
  BlockId: "",
  UnitId: "",
  jobType: "",
  billError: {},
  itemDescription: "",
  documentType: "",
  billNewQuoteError: "",
  billDataCategorySubtotal: "",
  estimationDocumentId: "",
  estimationCreateId: "",
  documentDescription: "",
  increaseAmount: "",
  InvesterEmail: "",
  InvesterfullName: "",
  budgetChangeError: {},
  authorisationType: "",
  supportingDocsError: "",
  files: "",
  builderSigneeId: "",
  relatedDocumentId: "",
  projectId: "",

  costCode: "",
  costArea: "",
  projectType: "",
};
const CreateModal = ({ show, setShow }) => {
  const [iModalState, updateModalState] = useState(initModal);
  const [iLeadState, updateLeadState] = useState(initLeadState);
  const quoteRef = useRef();
  const purchaseRef = useRef();
  const [proformaModal, setProformaModal] = useState(false);
  const [companyModal, setCompanyModal] = useState(false);
  const [residentialJobModal, setResidentialJobModal] = useState(false);
  const [landDevelopmentJobModal, setLandDevelopmentJobModal] = useState(false);
  const billRef = useRef();

  const { projectListData } = useSelector((state) => state.jobReducer);

  const { multiblocksList, landPhaseList } = useSelector(
    (state) => state.homeReducer
  );

  const {
    SelectJobModal,
    SelectCostModal,
    modalState,
    NewQuoteRequestModal,
    BillModal,
    BudgetChangeOrderModal,
    FieldPurchaseOrderModal,
    SupplyCreditModal,
    givenIndex,
    BackChargeModal,
    PurchaseModal,
    SystemVendorModal,
    reminderModal,
    BillQuoteReqModal,
    supportingDocsModal,
    viewBiddingModal,
  } = iModalState;

  const { vendorTypesData, loader, vendorListData, vendorDetailsData } =
    useSelector((state) => state.constructionVendor);

  const [NewProjectStep1Modal, SetNewProjectStep1Modal] = useState(false);
  const [MultifamilyJobStep1Modal, SetMultifamilyJobStep1Modal] =
    useState(false);

  const [ProjectAsEntityStep1Modal, SetProjectAsEntityStep1Modal] =
    useState(false);

  const { getAllCostCodeListData } = useSelector((state) => state.authReducer);

  const { masterCostCategoryListData } = useSelector(
    (state) => state.costReducer
  );

  const {
    reasonListData,
    profileListData,
    estimationUnitListData,
    blockListData,
    unitListData,
    builderSigneeListData,
    quoteRequestListData,
  } = useSelector((state) => state.estimationReducer);
  const { jobListData } = useSelector((state) => state.jobReducer);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);

  const {
    vendorName,
    vendorType,
    systemErrors,
    vendorSearchData,
    jobCodeId,
    BlockId,
    costCodeId,
    reasonId,
    vendorId,
    date,
    reminder,
    message,
    profile,
    subtotal,
    gst,
    grandTotal,
    scopeWorkId,
    constructionDocsId,
    externalQuoteId,
    newQuoteRequestError,
    day,
    week,
    selectCostError,
    selectJobError,
    calculatedGstAmount,
    itemErrors,
    UnitId,
    jobType,
    billError,
    itemDescription,
    documentType,
    billNewQuoteError,
    billDataCategorySubtotal,
    estimationDocumentId,
    estimationCreateId,
    documentDescription,
    increaseAmount,
    InvesterEmail,
    InvesterfullName,
    budgetChangeError,
    authorisationType,
    supportingDocsError,
    files,
    builderSigneeId,
    relatedDocumentId,
    costArea,
    costCode,
    projectId,
    projectType,
  } = iLeadState;

  const dispatch = useDispatch();

  const handleInput = (e) => {
    const { name, value } = e.target;
    console.log(value);
    if (value) {
      updateLeadState({ ...iLeadState, [name]: value });
    } else {
      updateLeadState({ ...iLeadState, [name]: value });
    }
  };

  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    updateLeadState({ ...iLeadState, [name]: result });
  };

  const handleCheckbox = (e) => {
    const { name, value, checked } = e.target;
    if (checked == true) {
      updateLeadState({ ...iLeadState, authorisationType: value });
    } else {
      updateLeadState({ ...iLeadState, authorisationType: value });
    }
  };

  const handleInputFile = (e) => {
    const { name, value } = e.target;
    const file = e.target.files[0];
    if (file) {
      updateLeadState({
        ...iLeadState,
        [name]: file,
      });
    }
    e.target.files = null;
  };

  useEffect(() => {
    dispatch(getMultiBlocksList(jobCodeId));
    updateLeadState({
      ...iLeadState,
      jobType: jobCodeId
        ? jobListData?.jobs?.find((x) => x?.id == jobCodeId)?.jobType
        : "",
    });
  }, [jobCodeId]);

  // useEffect(() => {
  //   dispatch(getAllUnitListAction(jobCodeId, BlockId));
  // }, [jobCodeId, BlockId]);

  const hideMainCreateModal = () => {
    setShow(false);
  };

  const showSelectJobModal = (state) => {
    dispatch(getAllProjectListAction());

    if (state === "costModal") {
      updateModalState({
        ...iModalState,
        SelectJobModal: true,
        SelectCostModal: false,
        modalState: state,
      });
    } else {
      updateModalState({ ...iModalState, SelectJobModal: true, modalState });
      setShow(false);
    }
  };

  useEffect(() => {
    // dispatch(getAllProjectListAction(jobCodeId));

    let slectedProject = projectListData?.filter(
      (project) => project?.id == jobCodeId
    );


    updateLeadState((prev) => ({
      ...prev,
      projectType: slectedProject?.[0]?.projectType,
    }));



    console.log(projectListData, slectedProject, "SSSSSSSSSSSSSSSSSSSS==============>")
  }, [jobCodeId]);

  useEffect(() => {
    updateLeadState({
      ...iLeadState,
      gst: profileListData?.find((x) => x?.id == profile)?.taxRows[0]
        ?.taxPercent,
    });
  }, [profile]);

  const hideSelectJobModal = () => {
    if (modalState === "costModal") {
      updateModalState({
        ...iModalState,
        SelectJobModal: false,
        SelectCostModal: true,
        modalState: "",
      });
    } else {
      updateModalState({
        ...iModalState,
        SelectJobModal: false,
        modalState: "",
      });
      setShow(true);
    }
  };

  const showSelectCostModal = () => {
    dispatch(getMasterCostCategoryAction());
    updateModalState({ ...iModalState, SelectCostModal: true });

    setShow(false);
  };
  const hideSelectCostModal = () => {
    updateModalState({ ...iModalState, SelectCostModal: false });
    setShow(true);
  };

  const { isModalOpen, jobCodeId: reducerJobId } = useSelector(
    (state) => state.estimationReducer
  );

  const showFieldPurchaseOrder = (state) => {
    if (jobCodeId) {
      dispatch(VendorTypesAction());
      dispatch(getAllReasonListAction());
      dispatch(getAllProfileListAction(4));
      dispatch(getAllEstimationUnitAction());
      dispatch(
        addFieldPurchaseOrderAction({
          jobCodeId: jobCodeId,
          jobType: jobType,
          costCodeId: 456,
        })
      )
        .then((res) => {
          updateLeadState({
            ...iLeadState,
            estimationDocumentId: res?.data?.documentCode,
            estimationCreateId: res?.data?.id,
          });
        })
        .catch((err) => {
          errorToast(err?.response?.data?.error);
          // console.log("errrrrerererer ,err", err);
        });
      if (state === "supplierCredit") {
        updateModalState({
          ...iModalState,
          SupplyCreditModal: false,
          FieldPurchaseOrderModal: true,
          modalState: state,
        });
      } else {
        updateModalState({ ...iModalState, FieldPurchaseOrderModal: true });
        setShow(false);
      }
    } else {
      errorToast("Please select the job first");
    }
  };
  const hideFieldPurchaseOrder = () => {
    if (jobCodeId) {
      if (modalState === "supplierCredit") {
        updateModalState({
          ...iModalState,
          SupplyCreditModal: true,
          FieldPurchaseOrderModal: false,
          modalState: "",
        });
      } else {
        updateModalState({ ...iModalState, FieldPurchaseOrderModal: false });
        setShow(true);
        setItems([
          {
            quantity: "",
            color: "",
            unitId: "",
            price: "",
            total: "",
            name: "",
            parentTableName: "FIELDPURCHASEORDER",
            parentTableId: 2,
          },
        ]);
        updateLeadState({
          ...iLeadState,
          // jobCodeId: "",
          // jobType: "",
          // costCodeId: "",
          reasonId: "",
          vendorId: "",
          date: "",
          reminder: "",
          message: "",
          profile: "",
          subtotal: "",
          gst: "",
          grandTotal: "",
          scopeWorkId: "",
          constructionDocsId: "",
          externalQuoteId: "",
          vendorType: "",
          type: "",
          newQuoteRequestError: {},
          day: "",
          week: "",
        });
      }
    } else {
      errorToast("Please select the job first");
    }
  };

  const showSupplierCreditModal = (state) => {
    if (jobCodeId) {
      dispatch(VendorTypesAction());
      dispatch(getAllReasonListAction());
      dispatch(getAllProfileListAction(4));
      dispatch(getAllEstimationUnitAction());
      dispatch(
        addSupplierCreditAction({
          jobCodeId: jobCodeId,
          jobType: jobType,
          costCodeId: 456,
        })
      )
        .then((res) => {
          // console.log("resss", res);
          updateLeadState({
            ...iLeadState,
            estimationDocumentId: res?.data?.documentCode,
            estimationCreateId: res?.data?.id,
          });
        })
        .catch((err) => {
          errorToast(err?.response?.data?.error);
          // console.log("errrrrerererer ,err", err);
        });
      if (state === "fieldPurchaseOrder") {
        updateModalState({
          ...iModalState,
          FieldPurchaseOrderModal: false,
          SupplyCreditModal: true,
          modalState: state,
        });
      } else {
        updateModalState({ ...iModalState, SupplyCreditModal: true });
        setShow(false);
      }
    } else {
      errorToast("Please select the job first");
    }
  };
  const hideSupplierCreditModal = () => {
    if (jobCodeId) {
      if (modalState === "fieldPurchaseOrder") {
        updateModalState({
          ...iModalState,
          FieldPurchaseOrderModal: true,
          SupplyCreditModal: false,
          modalState,
        });
      } else {
        updateModalState({ ...iModalState, FieldPurchaseOrderModal: false });
        setShow(true);
        setItems([
          {
            quantity: "",
            color: "",
            unitId: "",
            price: "",
            total: "",
            name: "",
            parentTableName: "FIELDPURCHASEORDER",
            parentTableId: 2,
          },
        ]);
        updateLeadState({
          ...iLeadState,
          // jobCodeId: "",
          // jobType: "",
          // costCodeId: "",
          reasonId: "",
          vendorId: "",
          date: "",
          reminder: "",
          message: "",
          profile: "",
          subtotal: "",
          gst: "",
          grandTotal: "",
          scopeWorkId: "",
          constructionDocsId: "",
          externalQuoteId: "",
          vendorType: "",
          type: "",
          newQuoteRequestError: {},
          day: "",
          week: "",
        });
      }
    } else {
      errorToast("Please select the job first");
    }
  };

  const showNewQuoteRequestModal = (state) => {
    if (jobCodeId || reducerJobId) {
      dispatch(VendorTypesAction());
      dispatch(getAllReasonListAction());
      dispatch(getAllProfileListAction(4));
      dispatch(getAllEstimationUnitAction());
      dispatch(
        addNewQuoteAction({
          jobCodeId: jobCodeId || reducerJobId,
          jobType: "ResidentialJob",
          costCodeId: 456,
        })
      )
        .then((res) => {
          updateLeadState({
            ...iLeadState,
            estimationDocumentId: res?.data?.data?.documentCode,
            estimationCreateId: res?.data?.data?.id,
          });
        })
        .catch((err) => {
          errorToast(err?.response?.data?.error);
          console.log("errrrrerererer ,err", err);
        });
      if (state === "purchaseModal") {
        updateModalState({
          ...iModalState,
          PurchaseModal: false,
          NewQuoteRequestModal: true,
          modalState: state,
        });
      } else {
        updateModalState({ ...iModalState, NewQuoteRequestModal: true });
        setShow(false);
      }
    } else {
      errorToast("Please select the job first");
    }
  };
  const hideNewQuoteRequestModal = () => {
    if (jobCodeId || reducerJobId) {
      if (modalState === "purchaseModal") {
        updateModalState({
          ...iModalState,
          PurchaseModal: true,
          NewQuoteRequestModal: false,
          modalState,
        });
      } else {
        updateModalState({ ...iModalState, NewQuoteRequestModal: false });
        setItems([
          {
            quantity: "",
            color: "",
            unitId: "",
            price: "",
            total: "",
            name: "",
            parentTableName: "FIELDPURCHASEORDER",
            parentTableId: 2,
          },
        ]);
        updateLeadState({
          ...iLeadState,
          // jobCodeId: "",
          // jobType: "",
          // costCodeId: "",
          reasonId: "",
          vendorId: "",
          date: "",
          reminder: "",
          message: "",
          profile: "",
          subtotal: "",
          gst: "",
          grandTotal: "",
          scopeWorkId: "",
          constructionDocsId: "",
          externalQuoteId: "",
          vendorType: "",
          type: "",
          newQuoteRequestError: {},
          day: "",
          week: "",
        });
        setShow(true);
      }
    } else {
      errorToast("Please select the job first");
    }
  };

  const showBillModal = (state) => {
    dispatch(VendorTypesAction());
    dispatch(getAllReasonListAction());
    dispatch(getAllProfileListAction());
    if (jobCodeId) {
      if (state === "costModal") {
        updateModalState({
          ...iModalState,
          BillModal: true,
          SelectCostModal: false,
          modalState: state,
        });
      } else {
        updateModalState({ ...iModalState, BillModal: true, modalState });
        setShow(false);
        setItems([
          {
            quantity: "",
            color: "",
            unitId: "",
            price: "",
            total: "",
            name: "",
            parentTableName: "FIELDPURCHASEORDER",
            parentTableId: 2,
          },
        ]);
        updateLeadState({
          ...iLeadState,
          // jobCodeId: "",
          // jobType: "",
          // costCodeId: "",
          reasonId: "",
          vendorId: "",
          date: "",
          reminder: "",
          message: "",
          profile: "",
          subtotal: "",
          gst: "",
          grandTotal: "",
          scopeWorkId: "",
          constructionDocsId: "",
          externalQuoteId: "",
          vendorType: "",
          type: "",
          newQuoteRequestError: {},
          day: "",
          week: "",
        });
      }
    } else {
      errorToast("Please select the job first");
    }
  };

  const hideBillModal = () => {
    if (modalState === "costModal") {
      updateModalState({
        ...iModalState,
        BillModal: false,
        SelectCostModal: true,
        modalState: "",
      });
    } else {
      updateModalState({
        ...iModalState,
        BillModal: false,
        modalState: "",
      });
      setShow(true);
    }
  };

  const showBudgetChangeOrderModal = (state) => {
    if (jobCodeId) {
      dispatch(getAllReasonListAction());
      dispatch(getAllBuilderSigneeAction());
      dispatch(VendorSearchAction());
      dispatch(getAllQuoteRequestAction());
      dispatch(
        addBudgetChangeOrderAction({
          jobCodeId,
          jobType,
          costCodeId: "456",
        })
      )
        .then((res) => {
          updateLeadState({
            ...iLeadState,
            estimationDocumentId: res?.data?.data?.documentCode,
            estimationCreateId: res?.data?.data?.id,
          });
        })
        .catch((err) => {
          errorToast(err?.response?.data?.error);
          console.log("errrr", err);
        });
      if (state === "costModal") {
        updateModalState({
          ...iModalState,
          BudgetChangeOrderModal: true,
          SelectCostModal: false,
          modalState: state,
        });
      } else {
        updateModalState({
          ...iModalState,
          BudgetChangeOrderModal: true,
          modalState: state,
        });
        setShow(false);
      }
    } else {
      errorToast("Please select the job first");
    }
  };

  const hideBudgetChangeOrderModal = () => {
    if (modalState === "costModal") {
      updateModalState({
        ...iModalState,
        BudgetChangeOrderModal: false,
        SelectCostModal: true,
        modalState: "",
      });
    } else {
      updateModalState({
        ...iModalState,
        BudgetChangeOrderModal: false,
        modalState: "",
      });
      setItems([
        {
          quantity: "",
          color: "",
          unitId: "",
          price: "",
          total: "",
          name: "",
          parentTableName: "FIELDPURCHASEORDER",
          parentTableId: 2,
        },
      ]);
      updateLeadState({
        ...iLeadState,
        // jobCodeId: "",
        // jobType: "",
        // costCodeId: "",
        reasonId: "",
        vendorId: "",
        date: "",
        reminder: "",
        message: "",
        profile: "",
        subtotal: "",
        gst: "",
        grandTotal: "",
        scopeWorkId: "",
        constructionDocsId: "",
        externalQuoteId: "",
        vendorType: "",
        type: "",
        newQuoteRequestError: {},
        day: "",
        week: "",
      });
      setShow(true);
    }
    updateLeadState({
      ...iLeadState,
      documentDescription: "",
      increaseAmount: "",
      InvesterEmail: "",
      InvesterfullName: "",

      authorisationType: "",
      vendorId: "",
      builderSigneeId: "",
      relatedDocumentId: "",
      costCodeId: 534,
      jobCodeId: "",
      jobType: "",
      authorisationType: "",
    });
  };

  const showPurchaseModal = (state) => {
    if (state === "costModal") {
      updateModalState({
        ...iModalState,
        PurchaseModal: true,
        SelectCostModal: false,
        modalState: state,
      });
    } else {
      updateModalState({
        ...iModalState,
        PurchaseModal: true,
        NewQuoteRequestModal: false,
      });
      // setShow(false);
    }
  };

  const hidePurchaseModal = () => {
    if (modalState === "costModal") {
      updateModalState({
        ...iModalState,
        PurchaseModal: false,
        SelectCostModal: true,
        modalState: "",
      });
    } else {
      updateModalState({
        ...iModalState,
        PurchaseModal: false,
        NewQuoteRequestModal: true,
        modalState: "",
      });
      // setShow(true);
    }
  };

  // SYSTEMFUNC

  const hideSystemVendor = () => {
    if (modalState === "quoteModal") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: false,
        NewQuoteRequestModal: true,
        vendorName: "",
        vendorType: "",
        modalState: "",
      });
    } else if (modalState === "purchaseModal") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: false,
        PurchaseModal: true,
        vendorName: "",
        vendorType: "",
        modalState: "",
      });
    } else if (modalState === "billModal") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: false,
        BillModal: true,
        vendorName: "",
        vendorType: "",
        modalState: modalState,
      });
    } else if (modalState === "fieldPurchaseOrder") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: false,
        FieldPurchaseOrderModal: true,
        vendorName: "",
        vendorType: "",
        modalState: "",
      });
    } else if (modalState === "supplierCredit") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: false,
        SupplyCreditModal: true,
        vendorName: "",
        vendorType: "",
        modalState: modalState,
      });
    }
  };

  const showSystemVendor = (data) => {
    if (data === "quoteModal") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: true,
        NewQuoteRequestModal: false,
        vendorName: "",
        vendorType: "",
        modalState: data,
      });
    } else if (data === "purchaseModal") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: true,
        PurchaseModal: false,
        vendorName: "",
        vendorType: "",
        modalState: data,
      });
    } else if (data === "billModal") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: true,
        BillModal: false,
        vendorName: "",
        vendorType: "",
        modalState: data,
      });
    } else if (data === "fieldPurchaseOrder") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: true,
        FieldPurchaseOrderModal: false,
        vendorName: "",
        vendorType: "",
        modalState: data,
      });
    } else if (data === "supplierCredit") {
      updateModalState({
        ...iModalState,
        SystemVendorModal: true,
        SupplyCreditModal: false,
        vendorName: "",
        vendorType: "",
        modalState: data,
      });
    }
  };

  const handleValidationSystem = () => {
    let error = {};
    let formIsValid = true;

    if (!vendorId) {
      error.vendorNameError = "*  Vendor name can't be empty";
      formIsValid = false;
    }

    if (!vendorType) {
      error.vendorTypeError = "* Vendor Type can't be empty";
      formIsValid = false;
    }

    updateLeadState({ ...iLeadState, systemErrors: error });
    return formIsValid;
  };

  const handleSystemVendor = (e) => {
    e.preventDefault();
    let formIsValid = handleValidationSystem();
    // updateState({...iState, name:})
    if (formIsValid) {
      hideSystemVendor();
      // handleAddVendorModal1Show();
    }
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    try {
      if (value == "") {
        setData1(data2);
      } else {
        let temp = data1.filter((item) => {
          return item.toLowerCase().indexOf(value.toLowerCase()) > -1;
        });
        setData1(temp);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputSearchCity = (e) => {
    const { name, value } = e.target;
    dispatch(VendorSearchAction(value)).then((res) => {
      updateModalState({ ...iModalState, vendorSearchData: res.vendors });
    });
  };

  useEffect(() => {
    setData1(vendorTypesData?.vendorType);
    setData2(vendorTypesData?.vendorType);
    dispatch(VendorSearchAction()).then((res) => {
      updateLeadState({ ...iLeadState, vendorSearchData: res.vendors });
    });
  }, [SystemVendorModal]);

  useEffect(() => {
    dispatch(vendorDetailsAction(vendorId));
    getDate();
  }, [vendorId]);

  const [items, setItems] = useState([
    {
      quantity: "",
      color: "",
      unitId: "",
      price: "",
      total: "",
      name: "",
      parentTableName: "QUOTEREQUEST",
      parentTableId: 2,
    },
  ]);

  const handleQuoteRequestValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!reasonId) {
      error.reasonIdError = "Reason id can't be empty";
      formIsValid = false;
    }

    if (!vendorId) {
      error.vendorIdError = "Vendor id can't be empty";
      formIsValid = false;
    }

    // if(!date){
    //   error.dateError = "Date can't be empty";
    //   formIsValid = false
    // }

    if (!day && !week) {
      error.reminderDateError = "Remiander can't be empty";
      formIsValid = false;
    }

    if (!message) {
      error.messageError = "Message can't be empty";
      formIsValid = false;
    }

    if (!profile) {
      error.profileError = "Profile can't be empty";
      formIsValid = false;
    }

    if (!subtotal) {
      error.subtotalError = "subtotal can't be empty";
      formIsValid = false;
    }

    if (!gst) {
      error.gstError = "GST can't be empty";
      formIsValid = false;
    }
    if (!grandTotal) {
      error.grandTotalError = "Grand total can't be empty";
      formIsValid = false;
    }

    // if(!jobCodeId){
    //   error.jobCodeIdError = "Job id can't be empty";
    //   formIsValid = false
    // }
    // if(!jobType){
    //   error.jobTypeError = "Job type can't be empty";
    //   formIsValid = false
    // }
    // if(!costCodeId){
    //   error.costCodeIdError = "COst code can't be empty";
    //   formIsValid = false
    // }

    // if(!scopeWorkId){
    //   error.scopeWorkIdError = "Scope work can't be empty";
    //   formIsValid = false
    // }

    // if(!externalQuoteId){
    //   error.externalQuoteIdError = "External quote can't be empty";
    //   formIsValid = false
    // }

    // if(!constructionDocsId){
    //   error.constructionDocsIdError = "Construction id  can't be empty";
    //   formIsValid = false
    // }

    updateLeadState({ ...iLeadState, newQuoteRequestError: error });
    return formIsValid;
  };

  const handleQuoteRequestFunc = (e, status, requestType) => {
    e.preventDefault();
    let formIsValid = handleQuoteRequestValidation();
    const filled = items.every((item) =>
      Object.values(item).every((value) => value !== "")
    );
    const type = week ? week : day;
    const reminderDates = calculateDateDifference(type);
    let data = {
      jobCodeId: jobCodeId,
      jobType: jobType,
      costCodeId: 342,
      reasonId,
      vendorId,
      date: new Date(),
      reminder: reminderDates,
      message,
      profile,
      subtotal,
      gst,
      grandTotal: Math.round(grandTotal),
      itemDetails: items,
      // status:status

      // scopeWorkId,
      // constructionDocsId,
      // externalQuoteId,
    };

    if (formIsValid) {
      if (filled) {
        if (requestType === "quote") {
          dispatch(updateQuoteRequestAction(estimationCreateId, data))
            .then((res) => {
              // console.log("daataa", res);
              hideNewQuoteRequestModal();
              sucessToast("New quote created successfully");
              setItems([
                {
                  quantity: "",
                  color: "",
                  unitId: "",
                  price: "",
                  total: "",
                  name: "",
                  parentTableName: "QUOTEREQUEST",
                  parentTableId: 2,
                },
              ]);
              updateLeadState({
                ...iLeadState,
                // jobCodeId: "",
                // jobType: "",
                // costCodeId: "",
                reasonId: "",
                vendorId: "",
                date: "",

                message: "",
                profile: "",
                subtotal: "",
                gst: "",
                grandTotal: "",
                scopeWorkId: "",
                constructionDocsId: "",
                externalQuoteId: "",
                vendorType: "",
                type: "",
                newQuoteRequestError: {},
                day: "",
                week: "",
              });
            })
            .catch((err) => {
              errorToast(err?.response?.data?.error);
            });
        } else if (requestType === "fieldPurchaseOrder") {
          dispatch(updateFieldPurchaseOrderAction(data, estimationCreateId))
            .then((res) => {
              hideFieldPurchaseOrder();
              sucessToast("New field purchase order created successfully");
              setItems([
                {
                  quantity: "",
                  color: "",
                  unitId: "",
                  price: "",
                  total: "",
                  name: "",
                  parentTableName: "FIELDPURCHASEORDER",
                  parentTableId: 2,
                },
              ]);
              updateLeadState({
                ...iLeadState,
                // jobCodeId: "",
                // jobType: "",
                // costCodeId: "",
                reasonId: "",
                vendorId: "",
                date: "",
                reminder: "",
                message: "",
                profile: "",
                subtotal: "",
                gst: "",
                grandTotal: "",
                scopeWorkId: "",
                constructionDocsId: "",
                externalQuoteId: "",
                vendorType: "",
                type: "",
                newQuoteRequestError: {},
                day: "",
                week: "",
              });
            })
            .catch((err) => {
              errorToast(err?.response?.data?.error);
            });
        } else if (requestType === "supplierCredit") {
          dispatch(updateSupplierCreditAction(data, estimationCreateId))
            .then((res) => {
              hideSupplierCreditModal();
              sucessToast("New field purchase order created successfully");
              setItems([
                {
                  quantity: "",
                  color: "",
                  unitId: "",
                  price: "",
                  total: "",
                  name: "",
                  parentTableName: "FIELDPURCHASEORDER",
                  parentTableId: 2,
                },
              ]);
              updateLeadState({
                ...iLeadState,
                // jobCodeId: "",
                // jobType: "",
                // costCodeId: "",
                reasonId: "",
                vendorId: "",
                date: "",
                reminder: "",
                message: "",
                profile: "",
                subtotal: "",
                gst: "",
                grandTotal: "",
                scopeWorkId: "",
                constructionDocsId: "",
                externalQuoteId: "",
                vendorType: "",
                type: "",
                newQuoteRequestError: {},
                day: "",
                week: "",
              });
            })
            .catch((err) => {
              errorToast(err?.response?.data?.error);
              console.log("datawasted", err);
            });
        } else {
          dispatch(updatePurchaseDetailsAction(estimationCreateId, data))
            .then((res) => {
              console.log("daataa", res);
              hidePurchaseModal();
              sucessToast("New purchase created successfully");
              setItems([
                {
                  quantity: "",
                  color: "",
                  unitId: "",
                  price: "",
                  total: "",
                  name: "",
                  parentTableName: "QUOTEREQUEST",
                  parentTableId: 2,
                },
              ]);
              updateLeadState({
                ...iLeadState,
                // jobCodeId: "",
                // jobType: "",
                // costCodeId: "",
                reasonId: "",
                vendorId: "",
                date: "",
                reminder: "",
                message: "",
                profile: "",
                subtotal: "",
                gst: "",
                grandTotal: "",
                scopeWorkId: "",
                constructionDocsId: "",
                externalQuoteId: "",
                vendorType: "",
                type: "",
                day: "",
                week: "",
                newQuoteRequestError: {},
              });
            })
            .catch((err) => {
              errorToast(err?.response?.data?.error);
              console.log("datawasted", err);
            });
        }
      } else {
        updateLeadState({
          ...iLeadState,
          itemErrors: "Please fill complete data",
        });
      }
    }
  };

  const hideAddRemianderModal = () => {
    if (modalState === "quoteModal") {
      updateModalState({
        ...iModalState,
        reminderModal: false,
        NewQuoteRequestModal: true,
        // modalState:""
      });
    } else if (modalState === "addTask") {
      updateModalState({
        ...iModalState,
        reminderModal: false,
        AddTaskModal: true,
        // modalState: "",
      });
    } else if (modalState === "editTodo") {
      updateModalState({
        ...iModalState,
        reminderModal: false,
        updateTodoModal: true,
        // modalState: "",
      });
    } else if (modalState === "purchaseModal") {
      updateModalState({
        ...iModalState,
        reminderModal: false,
        PurchaseModal: true,
        // modalId: id,
        modalState: "",
      });
    } else if (modalState === "billModal") {
      updateModalState({
        ...iModalState,
        reminderModal: false,
        BillModal: true,
        // modalId: id,
        modalState: "",
      });
    } else if (modalState === "fieldPurchaseOrder") {
      updateModalState({
        ...iModalState,
        reminderModal: false,
        FieldPurchaseOrderModal: true,
        // modalId: id,
        modalState: "",
      });
    } else if (modalState === "supplierCredit") {
      updateModalState({
        ...iModalState,
        reminderModal: false,
        SupplyCreditModal: true,
        // modalId: id,
        modalState: "",
      });
    } else if (modalState === "budgetChange") {
      updateModalState({
        ...iModalState,
        reminderModal: false,
        BudgetChangeOrderModal: true,
        // modalId: id,
        modalState: "",
      });
    }
  };

  const showAddRemianderModal = (data, id) => {
    if (data === "quoteModal") {
      updateModalState({
        ...iModalState,
        reminderModal: true,
        NewQuoteRequestModal: false,
        // modalId: id,
        modalState: data,
      });
    } else if (data === "addTask") {
      updateModalState({
        ...iModalState,
        reminderModal: true,
        AddTaskModal: false,
        // modalId: id,
        modalState: data,
      });
    } else if (data === "editTodo") {
      updateModalState({
        ...iModalState,
        reminderModal: true,
        updateTodoModal: false,
        modalState: data,
      });
    } else if (data === "purchaseModal") {
      updateModalState({
        ...iModalState,
        reminderModal: true,
        PurchaseModal: false,
        // modalId: id,
        modalState: data,
      });
    } else if (data === "billModal") {
      updateModalState({
        ...iModalState,
        reminderModal: true,
        BillModal: false,
        // modalId: id,
        modalState: data,
      });
    } else if (data === "fieldPurchaseOrder") {
      updateModalState({
        ...iModalState,
        reminderModal: true,
        FieldPurchaseOrderModal: false,
        // modalId: id,
        modalState: data,
      });
    } else if (data === "supplierCredit") {
      updateModalState({
        ...iModalState,
        reminderModal: true,
        SupplyCreditModal: false,
        // modalId: id,
        modalState: data,
      });
    } else if (data === "budgetChange") {
      updateModalState({
        ...iModalState,
        reminderModal: true,
        BudgetChangeOrderModal: false,
        // modalId: id,
        modalState: data,
      });
    }

    updateLeadState({ ...iLeadState, day: day, week: week });
  };

  // const selectJobValidation = () => {
  //    let error = {};
  //    let formIsValid = true;
  // }

  // const selectJob = () => {

  // }

  const [billDataCategories, setBillDataCategories] = useState([
    {
      documentType: "",
      costCodeId: "",
      jobCodeId: "",
      jobType: "",
      reasonId: "",
      itemDescription: "",
      subtotal: "",
      itemDetails: [],
    },
  ]);

  const [billQuoteItems, setBillQuoteItems] = useState([
    {
      quantity: "",
      color: "",
      unitId: "",
      price: "",
      total: "",
      name: "",
      parentTableName: "QUOTEREQUEST",
      parentTableId: 2,
    },
  ]);

  const removeLine = (i, type) => {
    if (type === "BillQuote") {
      let data = [...billDataCategories];
      data.splice(i, 1);
      setBillDataCategories(data);
    } else if (type === "billItemLine") {
      let data = [...billQuoteItems];
      data.splice(i, 1);
      setBillQuoteItems(data);
    } else {
      let data = [...items];
      data.splice(i, 1);
      setItems(data);
    }
  };

  useEffect(() => {
    let sum = 0;
    items?.map((ele, i) => {
      sum = sum + Number(ele?.total ?? 0);
    });

    items.forEach((item) => {
      if (item.quantity && item.price) {
        const quantity = parseInt(item.quantity);
        const price = parseInt(item.price);
        const total = quantity * price;
        item.total = total.toString();
      }
    });

    updateLeadState({ ...iLeadState, subtotal: sum });
  }, [items]);

  useEffect(() => {
    let sum = 0;
    // console.log("billDataCategoires", billDataCategories)
    billDataCategories?.map((ele, i) => {
      // console.log("reerereersererse",ele)
      sum = sum + Number(ele?.subtotal ?? 0);
    });

    updateLeadState({ ...iLeadState, billDataCategorySubtotal: sum });
  }, [billDataCategories, profile]);

  //  console.log(",billDataCategorySubtotal",billDataCategorySubtotal)

  useEffect(() => {
    if (subtotal && gst) {
      updateLeadState({
        ...iLeadState,
        calculatedGstAmount: Math.round(subtotal * (gst / 100)),
        grandTotal: subtotal + subtotal * (gst / 100),
      });
    }
  }, [subtotal, gst]);
  useEffect(() => {
    if (billDataCategorySubtotal) {
      updateLeadState({
        ...iLeadState,
        calculatedGstAmount: Math.round(billDataCategorySubtotal * (gst / 100)),
        grandTotal:
          billDataCategorySubtotal + billDataCategorySubtotal * (gst / 100),
      });
    }
  }, [billDataCategorySubtotal, gst]);

  const showBillQuoteReqModal = (i) => {
    dispatch(getAllEstimationUnitAction());
    updateModalState({
      ...iModalState,
      // reminderModal:true,
      BillQuoteReqModal: true,
      BillModal: false,
      // modalId: id,
      givenIndex: i,
    });
    // updateModalState({...iModalState, BillQuoteReqModal:true, })
  };

  const hideBillQuoteReqModal = () => {
    updateModalState({
      ...iModalState,
      BillQuoteReqModal: false,
      BillModal: true,
    });
  };

  const insertQuoteIntoBill = (e, index) => {
    e.preventDefault();

    const filled = billQuoteItems.every((item) =>
      Object.values(item).every((value) => value !== "")
    );
    if (filled) {
      // debugger;
      billDataCategories[givenIndex].itemDetails = [...billQuoteItems];
      hideBillQuoteReqModal();
      let totalSum = 0;
      billQuoteItems.forEach((item) => {
        if (item.total) {
          // console.log("totoalalla", item)
          const subtotal = parseInt(item.total);
          totalSum += subtotal;
        }
      });
      billDataCategories[givenIndex].subtotal = totalSum;
    } else {
      errorToast("Please enter complete data first");
    }
  };

  const handleChangeArrayItem = (e, index, type) => {
    const { name, value } = e.target;
    if (type === "billModal") {
      const newItems = [...billDataCategories];
      newItems[index] = {
        ...newItems[index],
        [name]: value,
        jobType: jobListData?.jobs?.find((x) => x?.id == jobCodeId)?.jobType,
      };
      setBillDataCategories(newItems);
    } else if (type === "billItemLine") {
      const newItems = [...billQuoteItems];
      newItems[index] = { ...newItems[index], [name]: value };
      setBillQuoteItems(newItems);
    } else {
      const newItems = [...items];
      newItems[index] = { ...newItems[index], [name]: value };
      setItems(newItems);
    }
  };

  const addExtraRowInFunc = (e, type) => {
    e.preventDefault();
    if (type === "billModal") {
      const filled = billDataCategories.every((item) =>
        Object.values(item).every((value) => value !== "")
      );
      if (filled) {
        setBillDataCategories([
          ...billDataCategories,
          {
            billQuoteItems: [
              {
                quantity: "",
                color: "",
                unitId: "",
                price: "",
                total: "",
                name: "",
                parentTableName: "QUOTEREQUEST",
                parentTableId: 2,
              },
            ],

            documentType: "",
            costCodeId: "",
            jobCodeId: "",
            reasonId: "",
            itemDescription: "",
            subtotal: "",
          },
        ]);
      } else {
        updateLeadState({
          ...iLeadState,
          billCategoryError: "Please fill complete data",
        });
      }
    } else if (type === "billItemLine") {
      const filledBillItem = billQuoteItems.every((item) =>
        Object.values(item).every((value) => value !== "")
      );
      if (filledBillItem) {
        setBillQuoteItems([
          ...billQuoteItems,
          {
            quantity: "",
            color: "",
            unitId: "",
            price: "",
            total: "",
            name: "",
            parentTableName: "QUOTEREQUEST",
            parentTableId: 2,
          },
        ]);
      } else {
        updateLeadState({
          ...iLeadState,
          billNewQuoteError: "Please fill complete data",
        });
      }
    } else if (type === "quote") {
      const filledQuote = items.every((item) =>
        Object.values(item).every((value) => value !== "")
      );

      if (filledQuote) {
        setItems([
          ...items,
          {
            quantity: "",
            color: "",
            unitId: "",
            price: "",
            total: "",
            name: "",
            parentTableName: "QUOTEREQUEST",
            parentTableId: 2,
          },
        ]);
      } else {
        updateLeadState({
          ...iLeadState,
          itemErrors: "Please fill complete data",
        });
      }
    }
  };

  const clearAllLines = (e, type) => {
    e.preventDefault();
    if (type === "quote") {
      setItems([
        {
          quantity: "",
          color: "",
          unitId: "",
          price: "",
          total: "",
          name: "",
          parentTableName: "QUOTEREQUEST",
          parentTableId: 2,
        },
      ]);
    } else if (type === "billItemLine") {
      setBillQuoteItems([
        {
          quantity: "",
          color: "",
          unitId: "",
          price: "",
          total: "",
          name: "",
          parentTableName: "QUOTEREQUEST",
          parentTableId: 2,
        },
      ]);
    } else if (type === "billModal") {
      setBillDataCategories([
        {
          documentType: "",
          costCodeId: "",
          jobCodeId: "",
          reasonId: "",
          itemDescription: "",
          subtotal: "",
        },
      ]);
    }
  };
  useEffect(() => {
    billQuoteItems.forEach((item) => {
      if (item.quantity && item.price) {
        const quantity = parseInt(item.quantity);
        const price = parseInt(item.price);
        const total = quantity * price;
        item.total = total.toString();
      }
    });
  }, [billQuoteItems?.price, billQuoteItems?.quantity, billQuoteItems]);

  const handleBillRequestValidation = () => {
    let formIsValid = true;
    let error = {};

    const filled = billDataCategories.every((item) =>
      Object.values(item).every((value) => value !== "")
    );
    if (!filled) {
      error.categoryDataError = "Category data can't be empty";
      formIsValid = false;
    }
    if (!vendorId) {
      error.vendorIdError = "Vendor id can't be empty";
      formIsValid = false;
    }

    // if(!date){
    //   error.dateError = "Date can't be empty";
    //   formIsValid = false
    // }

    if (!day && !week) {
      error.reminderDateError = "Remiander can't be empty";
      formIsValid = false;
    }

    if (!message) {
      error.messageError = "Message can't be empty";
      formIsValid = false;
    }

    if (!profile) {
      error.profileError = "Profile can't be empty";
      formIsValid = false;
    }

    if (!billDataCategorySubtotal) {
      error.subtotalError = "subtotal can't be empty";
      formIsValid = false;
    }

    if (!gst) {
      error.gstError = "GST can't be empty";
      formIsValid = false;
    }
    if (!grandTotal) {
      error.grandTotalError = "Grand total can't be empty";
      formIsValid = false;
    }

    // if(!jobCodeId){
    //   error.jobCodeIdError = "Job id can't be empty";
    //   formIsValid = false
    // }
    // if(!jobType){
    //   error.jobTypeError = "Job type can't be empty";
    //   formIsValid = false
    // }
    // if(!costCodeId){
    //   error.costCodeIdError = "COst code can't be empty";
    //   formIsValid = false
    // }

    // if(!scopeWorkId){
    //   error.scopeWorkIdError = "Scope work can't be empty";
    //   formIsValid = false
    // }

    // if(!externalQuoteId){
    //   error.externalQuoteIdError = "External quote can't be empty";
    //   formIsValid = false
    // }

    // if(!constructionDocsId){
    //   error.constructionDocsIdError = "Construction id  can't be empty";
    //   formIsValid = false
    // }

    updateLeadState({ ...iLeadState, billError: error });
    return formIsValid;
  };

  const updateBillFunc = (e) => {
    e.preventDefault();
    let formIsValid = handleBillRequestValidation();
    const type = week ? week : day;
    const reminderDates = calculateDateDifference(type);
    const filled = billDataCategories.every((item) =>
      Object.values(item).every((value) => value !== "")
    );
    const data = {
      // jobCodeId : jobCodeId,
      // jobType :jobType,
      // costCodeId : 342,
      // reasonId,
      vendorId: +vendorId,
      date: new Date(),
      reminder: reminderDates,
      message,
      profile,
      total: Math.round(grandTotal),
      subTotal: billDataCategorySubtotal,
      Categories: billDataCategories,
      gst,
      projectId: 2,
    };

    if (filled && formIsValid) {
      dispatch(updateBillAction(1, data))
        .then((res) => {
          sucessToast("Bill created successfully");
          hideBillModal();
          updateLeadState({
            ...iLeadState,
            vendorId: "",
            date: "",
            reminder: "",
            message: "",
            profile: "",
            type: "",
            gst: "",
            billDataCategorySubtotal: "",
            grandTotal: "",
          });

          setBillDataCategories([
            {
              documentType: "",
              costCodeId: "",
              jobCodeId: "",
              jobType: "",
              reasonId: "",
              itemDescription: "",
              subtotal: "",
              itemDetails: [],
            },
          ]);
        })
        .catch((err) => {
          errorToast(err?.response?.data?.error);
          console.log("errrrrrrr", err);
        });
    }
  };

  const budgetChangeValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!reasonId) {
      error.reasonIdError = "Reason id can't be empty";
      formIsValid = false;
    }
    // if(!InvesterEmail){
    //   error.InvesterEmailError = "Inverstor email can't be empty";
    //    formIsValid = false
    // }
    if (!documentDescription) {
      error.documentDescriptionError = "Document desc can't be empty";
      formIsValid = false;
    }
    if (!increaseAmount) {
      error.increaseAmountError = "Increase amount can't be empty";
      formIsValid = false;
    }
    if (!day && !week) {
      error.reminderDateError = "Remiander  can't be empty";
      formIsValid = false;
    }
    // if(!authorisationType){
    //   error.authorisationTypeError = "Authorization type can't be empty";
    //    formIsValid = false
    // }

    // if(!vendorId){
    //   error.vendorIdError = "Vendor can't be empty";
    //    formIsValid = false
    // }

    // if (InvesterEmail && InvesterfullName) {
    //   // Resetting the error for vendorId
    //   error.vendorIdError = "";
    //   formIsValid = true
    // } else {
    //   // Validation for vendorId when name or email is empty
    //   if (!vendorId) {
    //     error.vendorIdError = "Vendor can't be empty";
    //     formIsValid = false;
    //   }
    // }

    // if(vendorId){

    // }

    if (!vendorId || (!InvesterEmail && !InvesterfullName)) {
      error.vendorIdError = "Vendor can't be empty";
      return formIsValid;
    }

    if (!builderSigneeId) {
      error.builderSigneeIdError = "Builder signee can't be empty";
      formIsValid = false;
    }
    if (!relatedDocumentId) {
      error.relatedDocumentIdError = "Related docs can't be empty";
      formIsValid = false;
    }
    if (!files) {
      error.filesError = "Files can't be empty";
      formIsValid = false;
    }

    updateLeadState({ ...iLeadState, budgetChangeError: error });
    return formIsValid;
  };

  const budgetChangeFunc = (e, type) => {
    e.preventDefault();
    let formIsValid = budgetChangeValidation();
    if (formIsValid) {
      let data = {
        documentDescription,
        increaseAmount,
        email: InvesterEmail,
        InvesterfullName,
        authorisationType,
        vendorId,
        builderSigneeId,
        relatedDocumentId,
        costCodeId: 534,
        jobCodeId,
        jobType,
        authorisationType,
      };
      dispatch(updateBudgetChangeOrderAction(data, estimationCreateId))
        .then((res) => {
          // console.log('ressssssssssss', res)
          hideBudgetChangeOrderModal();
          sucessToast("Budget created successfully");
          updateLeadState({
            ...iLeadState,
            documentDescription: "",
            increaseAmount: "",
            InvesterEmail: "",
            InvesterfullName: "",

            authorisationType: "",
            vendorId: "",
            builderSigneeId: "",
            relatedDocumentId: "",
            costCodeId: 534,
            jobCodeId: "",
            jobType: "",
            authorisationType: "",
          });
        })
        .catch((err) => {
          errorToast(err?.response?.data?.error);
          console.log("errrrr", err);
        });
    }
  };

  const hideSupportingDocsModal = () => {
    if (modalState === "contractList") {
      updateModalState({
        ...iModalState,
        supportingDocsModal: false,
        modalId: "",
      });
    } else {
      updateModalState({
        ...iModalState,
        supportingDocsModal: false,
        modalId: "",
        BudgetChangeOrderModal: true,
      });
    }
  };

  const showSupportingDocsModal = (data, id) => {
    if (data === "contractList") {
      updateModalState({
        ...iModalState,
        supportingDocsModal: true,
        modalId: id,
        modalState: data,
      });
    } else {
      updateModalState({
        ...iModalState,
        supportingDocsModal: true,
        modalId: id,
        BudgetChangeOrderModal: false,
        modalState: data,
      });
    }
  };

  const addSupportingDocsFunc = () => {
    if (!files) {
      updateLeadState({
        ...iLeadState,
        supportingDocsError: "* Files can't be empty",
      });
    } else {
      dispatch(
        addEstimationSupportingDocsAction(
          { files },
          estimationCreateId,
          "BUDGETCHANGEORDER"
        )
      )
        .then((res) => {
          hideSupportingDocsModal();
          sucessToast("Supporting document added successfully");
        })
        .catch((err) => {
          errorToast(err?.response?.data?.error);
          // console.log("=>", err);
        });
    }
  };

  const proformaShowFunc = () => {
    setProformaModal(true);
    setShow(false);
  };

  const CompanyShowFunc = () => {
    setCompanyModal(true);
    setShow(false);
  };

  const showResidentialJobFunc = () => {
    dispatch(getAllProjectListAction("", "Single Family"));
    setResidentialJobModal(true);
    setShow(false);
  };

  const showLandDevelopmentModal = () => {
    dispatch(getAllProjectListAction("", "Land Development"));
    setLandDevelopmentJobModal(true);
    setShow(false);
  };

  useEffect(() => {
    if(costCode)
    dispatch(getAllCostCodeAction(costCode));
  }, [costCode]);


  

  return (
    <>
      {companyModal && (
        <CompanyModal
          companyModal={companyModal}
          setCompanyModal={setCompanyModal}
          setShow={setShow}
        />
      )}
      {proformaModal && (
        <ProformaModal
          proformaModal={proformaModal}
          setProformaModal={setProformaModal}
          setShow={setShow}
        />
      )}

      {residentialJobModal && (
        <ResidentialJobModal
          residentialJobModal={residentialJobModal}
          setResidentialJobModal={setResidentialJobModal}
          setShow={setShow}
        />
      )}
      <Landdevelopment
        landDevelopmentJobModal={landDevelopmentJobModal}
        setLandDevelopmentJobModal={setLandDevelopmentJobModal}
        setShow={setShow}
      />

      <Modal show={show} onHide={hideMainCreateModal} className="LargeModal">
        <Modal.Body>
          <a className="CloseModal" onClick={hideMainCreateModal} role="button">
            ×
          </a>
          <div className="FormArea">
            <div className="FolderForm FolderFormFull">
              <form>
                <div className="row">
                  <div className="col-md-3">
                    <div className="CreateBox">
                      <h4>Work Item</h4>
                      <ul>
                        <li>
                          <a
                            href="javascript:void(0);"
                            className="FolderPermissionId"
                            onClick={CompanyShowFunc}
                          >
                            Company Setup
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#CreateNewProjectDetails"
                            data-dismiss="modal"
                            onClick={() => {
                              SetNewProjectStep1Modal(true);
                              // setShow(false);
                            }}
                          >
                            New Project
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#CreateNewProjectAsAnEntityDetails"
                            data-dismiss="modal"
                            onClick={() => {
                              SetProjectAsEntityStep1Modal(true);
                              // setShow(false);
                            }}
                          >
                            New Project as an Entity
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            className="FolderPermissionId"
                            onClick={proformaShowFunc}
                          >
                            Proforma
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            className="FolderPermissionId"
                            onClick={showLandDevelopmentModal}
                          >
                            Land Development
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#CreateMultiFamilyJobsDetails"
                            data-dismiss="modal"
                            onClick={() => {
                              SetMultifamilyJobStep1Modal(true);
                            }}
                          >
                            Multi-Family job
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            className="FolderPermissionId"
                            onClick={showResidentialJobFunc}
                          >
                            Residential Job
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-9 HideColumnArea ">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="CreateBox">
                          <h4>Warranty</h4>
                          <ul>
                            <li>
                              <Link to="/Warranty Request">
                                Warranty Request
                              </Link>
                            </li>
                            <li>
                              <Link to="/Warranty Request">Work Order</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="CreateBox">
                          <h4>Construction</h4>
                          <ul>
                            <li>
                              <Link
                                to="/construction"
                                className="FolderPermissionId"
                                data-toggle="modal"
                                data-target="#AddNewTaskTodoLeft"
                                data-dismiss="modal"
                              >
                                To-Do
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/construction"
                                className="FolderPermissionId"
                                data-toggle="modal"
                                data-target="#ConstructionScheduleGenerationLeft"
                                data-dismiss="modal"
                              >
                                Schedule Task
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="CreateBox">
                          <h4>Estimation</h4>
                          <ul>
                            <li>
                              <a
                                className="FolderPermissionId"
                                onClick={() => showNewQuoteRequestModal()}
                              >
                                Quote Request/Purchase Order
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="FolderPermissionId"
                                onClick={() => showBillModal()}
                              >
                                Bill
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="FolderPermissionId"
                                onClick={() => showBudgetChangeOrderModal()}
                              >
                                Budget Change Order
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="FolderPermissionId"
                                onClick={() => showFieldPurchaseOrder()}
                              >
                                {" "}
                                Field Purchase Order Order
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="FolderPermissionId"
                                onClick={() => showSupplierCreditModal()}
                              >
                                {" "}
                                Supply Credit
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="FolderPermissionId"
                                data-toggle="modal"
                                data-target="#ViewQuoteRequestLeftBackChargeLeft"
                                data-dismiss="modal"
                              >
                                {" "}
                                Back-Charge
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-4">
                        <div className="CreateBox">
                          <h4>Price Book</h4>
                          <ul>
                            <li>
                              <Link
                              to="/price-book"
                               
                                className="FolderPermissionId"
                                data-toggle="modal"
                                data-target="#CreateEntryPriceLeft"
                                data-dismiss="modal"
                              >
                                Price Book Item
                              </Link>
                            </li>
                            <li>
                              <Link
                              to="/price-book"
                                className="FolderPermissionId"
                                data-toggle="modal"
                                data-target="#CreateCTCTemplateCtcLeft"
                                data-dismiss="modal"
                              >
                                CTC Template
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="CreateBox">
                          <h4>Sales</h4>
                          <ul>
                            <li>
                              <Link
                                to="/sales"
                                className="FolderPermissionId"
                                data-toggle="modal"
                                data-target="#CreateNewLeadLeft"
                                data-dismiss="modal"
                              >
                                Sales Lead
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/sales"
                                className="FolderPermissionId"
                                data-toggle="modal"
                                data-target="#CreateContractClientLeft"
                                data-dismiss="modal"
                              >
                                Contract Client
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="CreateBox">
                          <h4>Other</h4>
                          <ul>
                            <li>
                              <Link to="/change-order-list">Change Order</Link>
                            </li>
                            <li>
                              <Link to="/itinerary"
                                className="FolderPermissionId"
                                data-toggle="modal"
                                data-target="#AddNewItineraryItemLeft"
                                data-dismiss="modal"
                              >
                                Itinerary
                              </Link>
                            </li>
                            <li>
                              <Link

                              to="/individual-components-part-User-WorkflowTas"
                                className="FolderPermissionId"
                                data-toggle="modal"
                                data-target="#AddTaskLeft"
                                data-dismiss="modal"
                              >
                                Task
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/individual-components-part2-Vendor-List"
                                className="FolderPermissionId"
                                data-toggle="modal"
                                data-target="#CreateVendorMenuVendorLeft"
                                data-dismiss="modal"
                              >
                                Vendor
                              </Link>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="FolderPermissionId"
                                data-toggle="modal"
                                data-target="#CreateNewUserLeft"
                                data-dismiss="modal"
                              >
                                User
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="CreateBoxBottom">
                  <a
                    href="javascript:void(0);"
                    className="FolderPermissionId"
                    data-toggle="modal"
                    data-target="#PendingCreation"
                    data-dismiss="modal"
                  >
                    Pending Creation <span className="Count">3</span>
                  </a>
                  <div className="CreateSideBarCenterArea">
                    <h6 className="mb-2">
                      Job:{" "}
                      {jobCodeId
                        ? jobListData?.jobs?.find((x) => x?.id == jobCodeId)
                            ?.jobNumber
                        : ""}
                      <span
                      // className="fw-400 First"
                      >
                        {"jobCodeId"}
                      </span>{" "}
                    </h6>
                    <h6 className="mb-2">
                      Cost Code:{costCode}
                      <span className="fw-400 Second">
                        30-0102-FRAMING-Labor
                      </span>
                    </h6>
                  </div>
                  <a
                    href="javascript:void(0);"
                    className="TextRedColor fw-500 FolderPermissionId"
                    onClick={() => showSelectJobModal()}
                  >
                    Select Job
                  </a>
                  <a
                    href="javascript:void(0);"
                    className="TextRedColor fw-500 FolderPermissionId"
                    onClick={() => showSelectCostModal()}
                  >
                    Select Job and Cost Code
                  </a>
                  <a
                    href="javascript:void(0);"
                    className="ShowLessModal fw-500"
                  >
                    Show Less
                  </a>
                  <a
                    href="javascript:void(0);"
                    className="ShowMoreModal fw-500"
                  >
                    Show More
                  </a>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* SELECTJOBMODALSHOW */}
      <Modal
        show={SelectJobModal}
        onHide={hideSelectJobModal}
        className="SmallModal"
      >
        <Modal.Body>
          <a className="CloseModal" onClick={hideSelectJobModal} role="button">
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create - Select Job</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>Project</h6>
                <div className="Categories Categories1">
                  <div className="Categories_all Categories_all1">
                    <select
                      name="jobCodeId"
                      value={jobCodeId}
                      onChange={handleInput}
                    >
                      <option value=""> Select Project </option>
                      {projectListData?.map((ele, i) => {
                        return (
                          <option value={ele?.id}>{ele?.projectName}</option>
                        );
                      })}
                    </select>
                    <img src="images/down.png" />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <h6>Job</h6>
                <div className="Categories Categories1">
                  <div className="Categories_all Categories_all1">
                    <select
                    // name="jobCodeId"
                    // value={jobCodeId}
                    // onChange={handleInput}
                    >
                      {checkArray(projectListData) &&
                        projectListData
                          ?.filter((item) => item?.id == jobCodeId)
                          ?.map((ele) => {
                            return (
                              <option value={ele?.id}>{ele?.jobNumber}</option>
                            );
                          })}
                    </select>

                
                    <img src="images/down.png" />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <h6>Block / Phase</h6>
                <div className="Categories Categories2">
                  <div className="Categories_all Categories_all2">
                      <select
                        name="BlockId"
                        value={BlockId}
                        onChange={handleInput}
                      >

                        <option> Select Block </option>
                        {projectType == "Multi Family" && (

                        checkArray(multiblocksList) &&
                          multiblocksList?.map((ele, i) => {
                            return (
                              <option value={ele?.id}>{ele?.jobNumber}</option>
                            );
                          })

                        )}

                      </select>

                    {projectType == "Residential" && (
                      <select
                        name="BlockId"
                        value={BlockId}
                        onChange={handleInput}
                      >
                        <option> Select Block </option>
                        {checkArray(landPhaseList) &&
                          landPhaseList?.map((ele, i) => {
                            return (
                              <option value={ele?.id}>{ele?.jobNumber}</option>
                            );
                          })}
                      </select>
                    )}
                  </div>
                  <ul>
                    <li>
                      Block jobs loaded from the system after job is selected
                    </li>
                  </ul>
                </div>
              </div>
              <div className="form-group">
                <h6>Unit / Lot</h6>
                <div className="Categories Categories3">
                  <div className="Categories_all Categories_all3">
                    <select name="UnitId" value={UnitId} onChange={handleInput}>
                      <option> Select Unit </option>
                      {checkArray(multiblocksList) &&
                        multiblocksList
                          ?.filter((unit) => unit?.id == BlockId)?.[0]
                          ?.MultiUnits?.map((ele, i) => {
                            return (
                              <option value={ele?.id}>{ele?.jobNumber}</option>
                            );
                          })}
                    </select>
                    <img src="images/down.png" />
                  </div>
                  <ul>
                    <li>
                      Unit jobs loaded from the system after block is selected
                    </li>
                  </ul>
                </div>
              </div>
              <div className="Button d-flex justify-content-between">
                <button
                  className="Create ConfirmSelectionBtn"
                  onClick={hideSelectJobModal}
                >
                  Confirm Selection
                </button>
                <button className="Outline" onClick={hideSelectJobModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* SELECTCOSTMODALSHOW */}
      <Modal
        show={SelectCostModal}
        onHide={hideSelectCostModal}
        className="SmallModal"
      >
        <Modal.Body>
          <a className="CloseModal" onClick={hideSelectCostModal} role="button">
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create - Select Job and Cost Code</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <div className="CommonModalArea">
                  <h5>Select Job</h5>
                  <div className="CommonModalBox">
                    <h5
                      className="CursorPointer FolderPermissionId"
                      onClick={() => showSelectJobModal("costModal")}
                    >
                      <figure>
                        <img src="images/select_job_black.png" />
                      </figure>{" "}
                      Select Job
                    </h5>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="Categories Categories2 mb-4">
                  <h6>
                    Cost Codes Categories<span className="required">*</span>
                  </h6>
                  <div className="Categories_all Categories_all2">
                    <select
                      name="costCode"
                      value={costCode}
                      onChange={handleInput}
                    >
                      <option>Select Cost Codes</option>
                      {loader ? (
                        <>
                          {" "}
                          <Loader />
                        </>
                      ) : (
                        <>
                          {checkArray(masterCostCategoryListData?.data) ? (
                            masterCostCategoryListData?.data?.map(
                              (ele, ind) => {
                                return (
                                  <>
                                    <option value={ele?.id}>{ele?.name}</option>
                                  </>
                                );
                              }
                            )
                          ) : (
                            <NoDataFound />
                          )}
                        </>
                      )}
                    </select>
                    <img src="images/down.png" />
                  </div>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                          {errors?.countryError}
                        </span> */}
                </div>
              </div>
              <div className="form-group">
                <div className="Categories Categories2 mb-4">
                  <h6>
                    Cost Codes<span className="required">*</span>
                  </h6>
                  <div className="Categories_all Categories_all2">
                    <select
                      name="costArea"
                      value={costArea}
                      onChange={handleInput}
                    >
                      <option>Select Cost Codes</option>
                      {loader ? (
                        <>
                          {" "}
                          <Loader />
                        </>
                      ) : (
                        <>
                          {checkArray(getAllCostCodeListData?.data) ? (
                            getAllCostCodeListData?.data?.map((ele, ind) => {
                              return (
                                <>
                                  <option value={ele?.name}>{ele?.name}</option>
                                </>
                              );
                            })
                          ) : (
                            <NoDataFound />
                          )}
                        </>
                      )}
                    </select>
                    <img src="images/down.png" />
                  </div>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                          {errors?.countryError}
                        </span> */}
                </div>
              </div>
              <div className="Button d-flex justify-content-between">
                <button className="Outline" onClick={hideSelectCostModal}>
                  Confirm Selection
                </button>
                <button className="Outline" onClick={hideSelectCostModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* NEWQUOTEREQUESTMODALSHOW */}
      <Modal
        show={NewQuoteRequestModal}
        onHide={hideNewQuoteRequestModal}
        className="LargeModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideNewQuoteRequestModal}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox ModalTitleBoxYellow">
              <h4>Create New Quote Request</h4>
            </div>
            <div className="FolderForm">
              <div className="BlueTextArea YellowTextArea">
                <div className="row">
                  <div className="col-md-6">
                    <div className="Heading">
                      <h3>Quote Request # {estimationDocumentId}</h3>
                    </div>
                    <a
                      href="javascript:void(0);"
                      className="BlueOutline mb-4"
                      onClick={() => showPurchaseModal()}
                    >
                      <img src="images/create_quote_blue.png" />
                      Create as Purchase Order
                    </a>
                    <div className="row">
                      <div className="form-group col-md-9">
                        <div className="Categories Categories1">
                          <h6>Reason</h6>
                          <div className="Categories_all Categories_all1">
                            <select
                              name="reasonId"
                              value={reasonId}
                              onChange={handleInput}
                            >
                              <option>Select Reason</option>
                              {checkArray(reasonListData) ? (
                                <>
                                  {reasonListData?.map((ele, i) => {
                                    return (
                                      <option value={ele?.id}>
                                        {ele?.description}
                                      </option>
                                    );
                                  })}
                                </>
                              ) : (
                                <></>
                              )}
                            </select>
                            <img src="images/down.png" />
                          </div>
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {newQuoteRequestError?.reasonIdError}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="Heading">
                      <h3 className="d-flex">
                        Rivers Edge Unit 366(RIV-2B-366)
                        <div className="TableThreeDots ml-5">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#UploadAdditionalDocumentsLeft"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/upload_additional.png" />
                                    Upload Additional Documents
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#RequestLimitIncreaseLeft"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/request_limit.png" />
                                    Request Limit Increase
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#VendorBackChargeNotificationLeft"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/notify_vendor.png" />
                                    Notify Vendor of Back Charge
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </h3>
                      <h3>
                        Cost Code: <span>23-0101 - Appliances</span>
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="ModalFilterAreaBox">
                      <h4 className="mb-4">Vendor</h4>
                      <div className="RadioArea d-flex  flex-column">
                        <div className="form-group mb-3">
                          <label className="Radio">
                            Use Cost Code's preferred vendor
                            <input type="radio" name="Cuisine" />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="form-group">
                          <label className="Radio">
                            Select from list
                            <input type="radio" name="Cuisine" />
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                      {/* <span  style={{color:"red", fontSize:"14px"}}>{newQuoteRequestError?.vendorIdError}</span> */}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <h6>Date</h6>
                      <p className="ml-0">{getDate()}</p>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {newQuoteRequestError?.dateError}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5 className="YellowText">Reminder</h5>
                        <div className="CommonModalBox">
                          <figure
                            className="CursorPointer FolderPermissionId"
                            onClick={() => showAddRemianderModal("quoteModal")}
                          >
                            <img src="images/timer.png" />
                          </figure>
                          <h5>Select</h5>
                        </div>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {newQuoteRequestError?.reminderDateError}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <div className="CommonModalBox">
                          <figure
                            className="CursorPointer FolderPermissionId"
                            onClick={() => showSystemVendor("quoteModal")}
                          >
                            <img src="images/vendor.png" />
                          </figure>
                          <h5 className="text-black">Vendor 1</h5>
                        </div>{" "}
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {newQuoteRequestError?.vendorIdError}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ShippingAddressArea">
                      <div className="ShippingAddressAreaBox">
                        <h6>Mailing Address</h6>
                        <p>
                          {vendorDetailsData?.addressLine1}{" "}
                          {vendorDetailsData?.addressLine2}{" "}
                          {vendorDetailsData?.city}
                        </p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Email</h6>
                        <p> {vendorDetailsData?.email}</p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Phone</h6>
                        <p> {vendorDetailsData?.phone}</p>
                      </div>
                      {/* <div className="ShippingAddressAreaBox">
                <h6>Shipping Address</h6>
                <p>5384 Peach Rd Chilliwack, BC V2R0Z6</p>
              </div> */}
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="form-group">
                      <h6>Messsage</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert message to be sent to vendor"
                        name="message"
                        value={message}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {newQuoteRequestError?.messageError}
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ItemDetails">
                      <h6>Item Details</h6>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="Tableheader mb-3">
                      <h6 className="mb-0">Item</h6>
                      <div className="Actions">
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <DownloadTableExcel
                          filename="Quote item List"
                          sheet="Quote items list"
                          currentTableRef={quoteRef.current}
                        >
                          <button href="jacascript:void(0);">
                            <img src="images/download.png" />
                          </button>
                        </DownloadTableExcel>
                        <a href="javascript:void(0);">
                          <img src="images/setting.png" />
                        </a>
                      </div>
                    </div>
                    <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                      <table ref={quoteRef}>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>
                              <div className="text-center">
                                Material Description
                                <br />
                                (i.e: Modal/Type/Style/manuf part#/Dimensions,
                                etc.)
                              </div>
                            </th>
                            <th>Color</th>
                            <th>Quantity</th>
                            <th>Units</th>
                            <th>Price/Unit</th>
                            <th>Total</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items?.map((item, i) => {
                            return (
                              <tr>
                                <td>{i + 1}</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={item.name}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="color"
                                    value={item.color}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="quantity"
                                    value={item.quantity}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  {/* <div className="SelectAnOption">
                          <div className="dropdown"> */}
                                  {/* <button
                              className=""
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            > */}
                                  {/* Select measure type
                             <img src="images/black-down-arrow.png" /> */}
                                  <select
                                    className="form-control"
                                    name="unitId"
                                    value={item.unitId}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  >
                                    <option value=""> Select Option</option>
                                    {checkArray(estimationUnitListData) &&
                                      estimationUnitListData?.map((ele, i) => {
                                        return (
                                          <option value={ele?.id}>
                                            {" "}
                                            {ele?.name}
                                          </option>
                                        );
                                      })}
                                  </select>

                                  {/* </button> */}
                                  {/* <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                <input type="text" placeholder="-Search" />
                                <span>
                                  <img src="images/search.png" />
                                </span>
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                <img src="images/add-plus.png" />
                                Add New
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                - Full list of units loaded from the system
                              </a>
                            </div> */}
                                  {/* </div>
                        </div> */}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="price"
                                    value={item.price}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="total"
                                    value={+item.price * +item.quantity}
                                    disabled
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <div className="TableThreeDots">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol className="dropdown-menu">
                                          <li>
                                            <a
                                              onClick={(e) =>
                                                addExtraRowInFunc(e, "quote")
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/add_new_column.png" />
                                              Add New Line
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                removeLine(i, "newQuote")
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/dropdown-delete.png" />
                                              Remove Line
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                          <span style={{ color: "red", fontSize: "18px" }}>
                            {itemErrors}
                          </span>
                          {/* <span style={{color:"red", fontSize:"30px"}}>{"itdjkfhdkjghdfgkldfhglfdkghdflemErrors"}</span> */}

                          <tr>
                            <td colSpan={8}>
                              <div className="TableButtons">
                                <button
                                  onClick={(e) => addExtraRowInFunc(e, "quote")}
                                  className="BrandBtn"
                                >
                                  Add Line
                                </button>
                                <button
                                  onClick={(e) => clearAllLines(e, "quote")}
                                  className="BrandBtn"
                                >
                                  Clear all lines
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-md-4 ml-auto">
                        <div className="GstTable">
                          <table>
                            <tbody>
                              <tr>
                                <td>Profile</td>
                                <div class="CommonSelectBox">
                                  <select
                                    name="profile"
                                    value={profile}
                                    onChange={handleInput}
                                  >
                                    <option value="">Select profile</option>
                                    {checkArray(profileListData) &&
                                      profileListData?.map((ele, i) => {
                                        return (
                                          <option value={ele?.id}>
                                            {ele?.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                  <span>
                                    <img src="images/down.png" />
                                  </span>
                                </div>
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {newQuoteRequestError?.profileError}
                                </span>
                              </tr>
                              <tr>
                                <td>Subtotal</td>
                                <td>
                                  {subtotal}
                                  <span
                                    className="estimationModalBelowProfile"
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {newQuoteRequestError?.subtotalError}
                                  </span>
                                </td>
                                {/* <td>Modify Tax</td> */}
                              </tr>
                              <tr>
                                <td>GST({gst}%)</td>
                                <td>{calculatedGstAmount}</td>
                                <span
                                  className="estimationModalBelowProfile"
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {newQuoteRequestError?.gstError}
                                </span>
                                {/* <td>
                          <div className="TableThreeDots">
                            <ul className="">
                              <li className="dropdown">
                                <a
                                  className=""
                                  role="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img src="images/dots.png" />
                                </a>
                                <ol className="dropdown-menu dropdown-menuwidth">
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/remove_tax_line.png" />
                                      Remove Tax line
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddTaxDefinitionLeft"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/add_new_tax_line.png" />
                                      Add New Tax line
                                    </a>
                                  </li>
                                </ol>
                              </li>
                            </ul>
                          </div>
                        </td> */}
                              </tr>
                              <tr>
                                <td>Grand Total</td>
                                <td>{Math.round(grandTotal)}</td>
                                <span
                                  className="estimationModalBelowProfile"
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {newQuoteRequestError?.grandTotalError}
                                </span>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-4">
                      <div className="Heading">
                        <h6>Attachments</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Scope of Work</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#SelectScopesOfWorkLeft"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/scopeofwork.png" />
                            </figure>{" "}
                            Scope of Work &nbsp;<span className="Count">3</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Construction Documents</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#SelectConstructionDocumentsLeft"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/construction_documents.png" />
                            </figure>{" "}
                            Construction Documents &nbsp;
                            <span className="Count">3</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>External Quote</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#ImportReturnedQuoteLeft"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/extermal_quote.png" />
                            </figure>{" "}
                            External Quote &nbsp;
                            <span className="Count">3</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between mt-4 ">
                  <div className="dropdown ml-3">
                    <button
                      className="Create"
                      type="button"
                      onClick={(e) =>
                        handleQuoteRequestFunc(e, "SEND", "quote")
                      }
                    >
                      Save and <img src="images/black-down-arrow.png" />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a className="dropdown-item" href="#">
                        Save and Print
                      </a>
                      <a className="dropdown-item" href="#">
                        Save and Send
                      </a>
                    </div>
                  </div>
                  <button
                    onClick={(e) => handleQuoteRequestFunc(e, "DRAFT", "quote")}
                    className="Create"
                  >
                    Save draft
                  </button>
                  <button className="Create" onClick={hideNewQuoteRequestModal}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* PURCHASEMODALSHOW */}
      <Modal
        show={PurchaseModal}
        onHide={hidePurchaseModal}
        className="LargeModal"
      >
        <Modal.Body>
          <a className="CloseModal" onClick={hidePurchaseModal} role="button">
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox ModalTitleBoxBlue">
              <h4>Create New Purchase Order</h4>
            </div>
            <div className="FolderForm">
              <div className="BlueTextArea">
                <div className="row">
                  <div className="col-md-6">
                    <div className="Heading">
                      <h3>Quote Request # {estimationDocumentId}</h3>
                    </div>
                    <a
                      href="javascript:void(0);"
                      className="BlueOutline mb-4"
                      onClick={() => showPurchaseModal()}
                    >
                      <img src="images/create_quote_blue.png" />
                      Create as Purchase Order
                    </a>
                    <div className="row">
                      <div className="form-group col-md-9">
                        <div className="Categories Categories1">
                          <h6>Reason</h6>
                          <div className="Categories_all Categories_all1">
                            <select
                              name="reasonId"
                              value={reasonId}
                              onChange={handleInput}
                            >
                              <option>Select Reason</option>
                              {checkArray(reasonListData) ? (
                                <>
                                  {reasonListData?.map((ele, i) => {
                                    return (
                                      <option value={ele?.id}>
                                        {ele?.description}
                                      </option>
                                    );
                                  })}
                                </>
                              ) : (
                                <></>
                              )}
                            </select>
                            <img src="images/down.png" />
                          </div>
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {newQuoteRequestError?.reasonIdError}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="Heading">
                      <h3 className="d-flex">
                        Rivers Edge Unit 366(RIV-2B-366)
                        <div className="TableThreeDots ml-5">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#UploadAdditionalDocumentsLeft"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/upload_additional.png" />
                                    Upload Additional Documents
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#RequestLimitIncreaseLeft"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/request_limit.png" />
                                    Request Limit Increase
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#VendorBackChargeNotificationLeft"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/notify_vendor.png" />
                                    Notify Vendor of Back Charge
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </h3>
                      <h3>
                        Cost Code: <span>23-0101 - Appliances</span>
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="ModalFilterAreaBox">
                      <h4 className="mb-4">Vendor</h4>
                      <div className="RadioArea d-flex  flex-column">
                        <div className="form-group mb-3">
                          <label className="Radio">
                            Use Cost Code's preferred vendor
                            <input type="radio" name="Cuisine" />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="form-group">
                          <label className="Radio">
                            Select from list
                            <input type="radio" name="Cuisine" />
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                      {/* <span  style={{color:"red", fontSize:"14px"}}>{newQuoteRequestError?.vendorIdError}</span> */}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <h6>Date</h6>
                      <p className="ml-0">{getDate()}</p>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {newQuoteRequestError?.dateError}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5 className="BlueText">Reminder</h5>
                        <div className="CommonModalBox">
                          <figure
                            className="CursorPointer FolderPermissionId"
                            onClick={() =>
                              showAddRemianderModal("purchaseModal")
                            }
                          >
                            <img src="images/timer.png" />
                          </figure>
                          <h5>Select</h5>
                        </div>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {newQuoteRequestError?.reminderDateError}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <div className="CommonModalBox">
                          <figure
                            className="CursorPointer FolderPermissionId"
                            onClick={() => showSystemVendor("purchaseModal")}
                          >
                            <img src="images/vendor.png" />
                          </figure>
                          <h5 className="text-black">Vendor</h5>
                        </div>{" "}
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {newQuoteRequestError?.vendorIdError}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ShippingAddressArea">
                      <div className="ShippingAddressAreaBox">
                        <h6>Mailing Address</h6>
                        <p>
                          {vendorDetailsData?.addressLine1}{" "}
                          {vendorDetailsData?.addressLine2}{" "}
                          {vendorDetailsData?.city}
                        </p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Email</h6>
                        <p> {vendorDetailsData?.email}</p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Phone</h6>
                        <p> {vendorDetailsData?.phone}</p>
                      </div>
                      {/* <div className="ShippingAddressAreaBox">
                <h6>Shipping Address</h6>
                <p>5384 Peach Rd Chilliwack, BC V2R0Z6</p>
              </div> */}
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="form-group">
                      <h6>Messsage</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert message to be sent to vendor"
                        name="message"
                        value={message}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {newQuoteRequestError?.messageError}
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ItemDetails">
                      <h6>Item Details</h6>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="Tableheader mb-3">
                      <h6 className="mb-0">Item</h6>
                      <div className="Actions">
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <DownloadTableExcel
                          filename="Purchase item List"
                          sheet="Purchase items list"
                          currentTableRef={purchaseRef.current}
                        >
                          <button href="jacascript:void(0);">
                            <img src="images/download.png" />
                          </button>
                        </DownloadTableExcel>
                        <a href="javascript:void(0);">
                          <img src="images/setting.png" />
                        </a>
                      </div>
                    </div>
                    <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                      <table ref={purchaseRef}>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>
                              <div className="text-center">
                                Material Description
                                <br />
                                (i.e: Modal/Type/Style/manuf part#/Dimensions,
                                etc.)
                              </div>
                            </th>
                            <th>Color</th>
                            <th>Quantity</th>
                            <th>Units</th>
                            <th>Price/Unit</th>
                            <th>Total</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* <tr>
                    <td>1</td>
                    <td>NNTK913 S Panasonic Convection Trim Kit</td>
                    <td>Stainless</td>
                    <td>1</td>
                    <td>Each</td>
                    <td>$ 500.00</td>
                    <td>$ 500.00</td>
                    <td>
                      <div className="TableThreeDots">
                        <ul className="">
                          <li className="dropdown">
                            <a
                              className=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol className="dropdown-menu">
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/add_new_column.png" />
                                  Add New Column
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/dropdown-delete.png" />
                                  Remove Line
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr> */}
                          {items?.map((item, i) => {
                            return (
                              <tr>
                                <td>{i + 1}</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={item.name}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="color"
                                    value={item.color}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="quantity"
                                    value={item.quantity}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  {/* <div className="SelectAnOption">
                          <div className="dropdown"> */}
                                  {/* <button
                              className=""
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            > */}
                                  {/* Select measure type
                             <img src="images/black-down-arrow.png" /> */}
                                  <select
                                    className="form-control"
                                    name="unitId"
                                    value={item.unitId}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  >
                                    <option value=""> Select Option</option>
                                    {checkArray(estimationUnitListData) &&
                                      estimationUnitListData?.map((ele, i) => {
                                        return (
                                          <option value={ele?.id}>
                                            {" "}
                                            {ele?.name}
                                          </option>
                                        );
                                      })}
                                  </select>

                                  {/* </button> */}
                                  {/* <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                <input type="text" placeholder="-Search" />
                                <span>
                                  <img src="images/search.png" />
                                </span>
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                <img src="images/add-plus.png" />
                                Add New
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                - Full list of units loaded from the system
                              </a>
                            </div> */}
                                  {/* </div>
                        </div> */}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="price"
                                    value={item.price}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="total"
                                    disabled
                                    value={+item.price * +item.quantity}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <div className="TableThreeDots">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol className="dropdown-menu">
                                          <li>
                                            <a
                                              onClick={(e) =>
                                                addExtraRowInFunc(e, "quote")
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/add_new_column.png" />
                                              Add New Line
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                removeLine(i, "purchase")
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/dropdown-delete.png" />
                                              Remove Line
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                          <span style={{ color: "red", fontSize: "18px" }}>
                            {itemErrors}
                          </span>
                          {/* <span style={{color:"red", fontSize:"30px"}}>{"itdjkfhdkjghdfgkldfhglfdkghdflemErrors"}</span> */}

                          <tr>
                            <td colSpan={8}>
                              <div className="TableButtons">
                                <button
                                  onClick={(e) => addExtraRowInFunc(e, "quote")}
                                  className="BrandBtn"
                                >
                                  Add Line
                                </button>
                                <button
                                  onClick={(e) => clearAllLines(e, "quote")}
                                  className="BrandBtn"
                                >
                                  Clear all lines
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-md-4 ml-auto">
                        <div className="GstTable">
                          <table>
                            <tbody>
                              <tr>
                                <td>Profile</td>
                                <div class="CommonSelectBox">
                                  <select
                                    name="profile"
                                    value={profile}
                                    onChange={handleInput}
                                  >
                                    <option value="">Select profile</option>
                                    {checkArray(profileListData) &&
                                      profileListData?.map((ele, i) => {
                                        return (
                                          <option value={ele?.id}>
                                            {ele?.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                  <span>
                                    <img src="images/down.png" />
                                  </span>
                                </div>
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {newQuoteRequestError?.profileError}
                                </span>
                              </tr>
                              <tr>
                                <td>Subtotal</td>
                                <td>
                                  {subtotal}
                                  <span
                                    className="estimationModalBelowProfile"
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {newQuoteRequestError?.subtotalError}
                                  </span>
                                </td>
                                {/* <td>Modify Tax</td> */}
                              </tr>
                              <tr>
                                <td>GST({gst}%)</td>
                                <td>{calculatedGstAmount}</td>
                                <span
                                  className="estimationModalBelowProfile"
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {newQuoteRequestError?.gstError}
                                </span>
                                {/* <td>
                          <div className="TableThreeDots">
                            <ul className="">
                              <li className="dropdown">
                                <a
                                  className=""
                                  role="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img src="images/dots.png" />
                                </a>
                                <ol className="dropdown-menu dropdown-menuwidth">
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/remove_tax_line.png" />
                                      Remove Tax line
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddTaxDefinitionLeft"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/add_new_tax_line.png" />
                                      Add New Tax line
                                    </a>
                                  </li>
                                </ol>
                              </li>
                            </ul>
                          </div>
                        </td> */}
                              </tr>
                              <tr>
                                <td>Grand Total</td>
                                <td>{Math.round(grandTotal)}</td>
                                <span
                                  className="estimationModalBelowProfile"
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {newQuoteRequestError?.grandTotalError}
                                </span>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-4">
                      <div className="Heading">
                        <h6>Attachments</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Scope of Work</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#SelectScopesOfWorkLeft"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/scopeofwork.png" />
                            </figure>{" "}
                            Scope of Work &nbsp;<span className="Count">3</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Construction Documents</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#SelectConstructionDocumentsLeft"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/construction_documents.png" />
                            </figure>{" "}
                            Construction Documents &nbsp;
                            <span className="Count">3</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>External Quote</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#ImportReturnedQuoteLeft"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/extermal_quote.png" />
                            </figure>{" "}
                            External Quote &nbsp;
                            <span className="Count">3</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="Button d-flex justify-content-between mt-4 ">
                  <div className="dropdown ml-3">
                    <button
                      className="Create"
                      type="button"
                      onClick={(e) =>
                        handleQuoteRequestFunc(e, "SEND", "purchase")
                      }
                    >
                      Save and <img src="images/black-down-arrow.png" />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a className="dropdown-item" href="#">
                        Save and Print
                      </a>
                      <a className="dropdown-item" href="#">
                        Save and Send
                      </a>
                    </div>
                  </div>
                  <button
                    onClick={(e) =>
                      handleQuoteRequestFunc(e, "DRAFT", "purchase")
                    }
                    className="Create"
                  >
                    Save draft
                  </button>
                  <button
                    className="Create"
                    data-toggle="modal"
                    data-target="#CreateNewQuoteRequestLeft"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* VENDORSYSTEMMODALSHOW */}
      <Modal
        show={SystemVendorModal}
        onHide={hideSystemVendor}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                href="javascript:void(0);"
                className="CloseModal"
                onClick={hideSystemVendor}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>System Vendor List</h4>
                </div>
                <div className="FolderForm">
                  {/* <form> */}
                  <div className="form-group">
                    <div className="Categories Categories1 mb-4">
                      <h6>Vendor Type</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="vendorType"
                          value={vendorType}
                          onChange={handleInput}
                        >
                          <option>Select a Vendor Type</option>
                          {checkArray(data1) ? (
                            <>
                              {data1?.map((ele, i) => {
                                return <option value={ele}>{ele}</option>;
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input
                            type="text"
                            placeholder="-Search"
                            onChange={(e) => onChangeHandler(e)}
                          />
                          <span className="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>
                        {/* <li className="CursorPointer">
                        - Full list of vendor types loaded from the system
                      </li> */}
                      </ul>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {systemErrors?.vendorTypeError}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>Vendor Name</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="vendorId"
                          value={vendorId}
                          onChange={handleInput}
                        >
                          <option>Select a Vendor Name</option>
                          {checkArray(vendorSearchData) ? (
                            <>
                              {vendorSearchData?.map((ele, i) => {
                                return (
                                  <option value={ele?.id}>
                                    {ele.vendorName}
                                  </option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input
                            type="text"
                            placeholder="Search"
                            onChange={handleInputSearchCity}
                          />
                          <span className="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>
                        {/* <li className="CursorPointer">
                        - Full list of names loaded from the system
                      </li> */}
                      </ul>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {systemErrors?.vendorNameError}
                      </span>
                    </div>
                  </div>
                  <div className="Button mt-5 d-flex justify-content-between">
                    <button className="Outline" onClick={hideSystemVendor}>
                      Cancel
                    </button>
                    <button className="Create" onClick={handleSystemVendor}>
                      Confirm Selection
                    </button>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* BILLMODALSHOW */}
      <Modal show={BillModal} onHide={hideBillModal} className="LargeModal">
        <Modal.Body>
          <a className="CloseModal" onClick={hideBillModal} role="button">
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create New Bill</h4>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="col-12">
                  <div className="Heading mb-5">
                    <h3 className="d-flex ">
                      {" "}
                      Bill # {estimationDocumentId}
                      <div className="TableThreeDots ml-3">
                        <ul className="">
                          <li className="dropdown">
                            <a
                              className=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol className="dropdown-menu dropdown-menuwidth2">
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/upload_additional.png" />
                                  Upload Additional Documents
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/request_limit.png" />
                                  Request Limit Increase
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/notify_vendor.png" />
                                  Notify Vendor of Back Charge
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </h3>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <div className="CommonModalArea">
                      <h5>Vendor</h5>
                      <div className="CommonModalBox">
                        <h5 className="text-black">
                          <figure onClick={() => showSystemVendor("billModal")}>
                            <img src="images/vendor.png" />
                          </figure>{" "}
                          Vendor 1
                        </h5>
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {billError?.vendorIdError}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <h6>Date</h6>
                    <p className="ml-0">{getDate()}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5>Reminder</h5>
                      <div className="CommonModalBox">
                        <h5>
                          <figure
                            onClick={() => showAddRemianderModal("billModal")}
                          >
                            <img src="images/timer.png" />
                          </figure>{" "}
                          30 Days
                        </h5>
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {billError?.reminderDateError}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="ShippingAddressArea">
                    <div className="ShippingAddressAreaBox">
                      <h6>Mailing Address</h6>
                      <p>
                        {vendorDetailsData?.addressLine1}{" "}
                        {vendorDetailsData?.addressLine2}{" "}
                        {vendorDetailsData?.city}
                      </p>
                    </div>
                    <div className="ShippingAddressAreaBox">
                      <h6>Email</h6>
                      <p> {vendorDetailsData?.email}</p>
                    </div>
                    <div className="ShippingAddressAreaBox">
                      <h6>Phone</h6>
                      <p> {vendorDetailsData?.phone}</p>
                    </div>
                    {/* <div className="ShippingAddressAreaBox">
                <h6>Shipping Address</h6>
                <p>5384 Peach Rd Chilliwack, BC V2R0Z6</p>
              </div> */}
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="form-group">
                    <h6>Messsage</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert message to be sent to vendor"
                      name="message"
                      value={message}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {billError?.messageError}
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="ItemDetails">
                    <h6>Bill Items</h6>
                  </div>
                </div>
                <div className="col-12">
                  <div className="Tableheader mb-3">
                    <h6 className="mb-0">Categories</h6>
                    <div className="Actions">
                      <a href="javascript:void(0);">
                        <img src="images/icon-53.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/download.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/setting.png" />
                      </a>
                    </div>
                  </div>
                  <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                    <table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Job Id</th>
                          <th>Cost Code</th>
                          <th>Cost Code Description</th>
                          <th>Item Description</th>
                          <th>Document Type</th>
                          <th>Document Reason</th>
                          <th>Item Details</th>
                          <th>Subtotal</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {billDataCategories?.map((item, i) => {
                          return (
                            <>
                              <tr>
                                <td>{i + 1}</td>
                                <td>
                                  <div className="Categories_all Categories_all1">
                                    <select
                                      name="jobCodeId"
                                      value={item?.jobCodeId}
                                      onChange={(e) =>
                                        handleChangeArrayItem(e, i, "billModal")
                                      }
                                    >
                                      <option value=""> Select Job </option>
                                      {checkArray(jobListData?.jobs) &&
                                        jobListData?.jobs?.map((ele, i) => {
                                          return (
                                            <option value={ele?.id}>
                                              {ele?.jobNumber}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    <img src="images/down.png" />
                                  </div>
                                </td>
                                <td>
                                  <div className="Categories_all Categories_all1">
                                    <select
                                      name="costCodeId"
                                      value={item?.costCodeId}
                                      onChange={(e) =>
                                        handleChangeArrayItem(e, i, "billModal")
                                      }
                                    >
                                      <option value="">
                                        {" "}
                                        Select Cost Code{" "}
                                      </option>
                                      {checkArray(jobListData?.jobs) &&
                                        jobListData?.jobs?.map((ele, i) => {
                                          return (
                                            <option value={ele?.id}>
                                              {ele?.jobNumber}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    <img src="images/down.png" />
                                  </div>
                                </td>
                                <td>
                                  {" "}
                                  {
                                    jobListData?.jobs?.find(
                                      (x) => x?.id == jobCodeId
                                    )?.jobType
                                  }
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Insert description"
                                    name="itemDescription"
                                    value={item?.itemDescription}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "billModal")
                                    }
                                  />
                                </td>
                                <td>
                                  <div className="Categories_all Categories_all1">
                                    <select
                                      name="documentType"
                                      value={item?.documentType}
                                      onChange={(e) =>
                                        handleChangeArrayItem(e, i, "billModal")
                                      }
                                    >
                                      <option value="">
                                        {" "}
                                        Select Cost Code{" "}
                                      </option>

                                      <option value={"QUOTEEQUEST"}>
                                        {"Quote Request"}
                                      </option>
                                      <option value={"PURCHASEORDER"}>
                                        {"Purchase Order"}
                                      </option>
                                      <option value={"FIELDPURCHASEORDER"}>
                                        {"Field Purchase Order"}
                                      </option>
                                      <option value={"SUPPLIERCREDIT"}>
                                        {"Supplier Credit"}
                                      </option>
                                    </select>
                                    <img src="images/down.png" />
                                  </div>
                                </td>
                                <td>
                                  <div className="Categories_all Categories_all1">
                                    <select
                                      name="reasonId"
                                      value={item?.reasonId}
                                      onChange={(e) =>
                                        handleChangeArrayItem(e, i, "billModal")
                                      }
                                    >
                                      <option>Select Reason</option>
                                      {checkArray(reasonListData) ? (
                                        <>
                                          {reasonListData?.map((ele, i) => {
                                            return (
                                              <option value={ele?.id}>
                                                {ele?.description}
                                              </option>
                                            );
                                          })}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </select>
                                    <img src="images/down.png" />
                                  </div>
                                </td>
                                <td>
                                  <div
                                    onClick={() => showBillQuoteReqModal(i)}
                                    className="Action"
                                  >
                                    <img
                                      src="images/item_details.png"
                                      className="FolderPermissionId CursorPointer"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Insert amount"
                                    name="subtotal"
                                    value={item?.subtotal}
                                    disabled
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "billModal")
                                    }
                                  />
                                </td>
                                <td>
                                  <div className="TableThreeDots">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol className="dropdown-menu dropdown-menuwidth2">
                                          <li>
                                            <a
                                              href="javascript:void(0);"
                                              data-toggle="modal"
                                              data-target="#EditItemDetailsLeft"
                                              data-dismiss="modal"
                                            >
                                              <img src="images/edit_item_details.png" />
                                              Edit item Details
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                removeLine(i, "BillQuote")
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/dropdown-delete.png" />
                                              Remove Line
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/request_limit_increase.png" />
                                              Request Limit Increase
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="5">
                                  <div className="TableList CommonHeadingBlack MaterialDescriptionTable">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>
                                            <div className="text-center">
                                              Material Description
                                              <br />
                                              (i.e: Modal/Type/Style/manuf
                                              part#/Dimensions, etc.)
                                            </div>
                                          </th>
                                          <th>Color</th>
                                          <th>Quantity</th>
                                          <th>Units</th>
                                          <th>Price/Unit</th>
                                          <th>Total</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {checkArray(billQuoteItems) &&
                                          billQuoteItems?.map((ele, i) => {
                                            // console.log("eleeeeee", ele)
                                            return (
                                              <>
                                                <tr>
                                                  <td>1</td>
                                                  <td>
                                                    <div className="text-center">
                                                      {ele?.name}
                                                    </div>
                                                  </td>
                                                  <td> {ele?.color}</td>
                                                  <td> {ele?.quantity}</td>
                                                  <td>{ele?.unitId}</td>
                                                  <td> $ {ele?.price}</td>
                                                  <td>$ {ele?.total}</td>
                                                  <td>
                                                    <div className="TableThreeDots">
                                                      <ul className="">
                                                        <li className="dropdown">
                                                          <a
                                                            className=""
                                                            role="button"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                          >
                                                            <img src="images/dots.png" />
                                                          </a>
                                                          <ol className="dropdown-menu dropdown-menuwidth">
                                                            <li></li>
                                                            <li>
                                                              <a
                                                                onClick={() =>
                                                                  removeLine(
                                                                    i,
                                                                    "billItemLine"
                                                                  )
                                                                }
                                                                href="javascript:void(0);"
                                                              >
                                                                {" "}
                                                                <img src="images/dropdown-delete.png" />
                                                                Remove Line
                                                              </a>
                                                            </li>
                                                          </ol>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          })}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })}

                        <span
                          style={{
                            color: "red",
                            fontSize: "15px",
                            textAlign: "center",
                          }}
                        >
                          {billError?.categoryDataError}
                        </span>

                        <tr>
                          <td colSpan={8}>
                            <div className="TableButtons">
                              <button
                                onClick={(e) =>
                                  addExtraRowInFunc(e, "billModal")
                                }
                                className="BrandBtn"
                              >
                                Add Line
                              </button>
                              <button
                                onClick={(e) => clearAllLines(e, "billModal")}
                                className="BrandBtn"
                              >
                                Clear all lines
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="row">
                    <div className="col-md-4 ml-auto">
                      <div className="GstTable">
                        <table>
                          <tbody>
                            <tr>
                              <td>Profile</td>
                              <div class="CommonSelectBox">
                                <select
                                  name="profile"
                                  value={profile}
                                  onChange={handleInput}
                                >
                                  <option value="">Select profile</option>
                                  {checkArray(profileListData) &&
                                    profileListData?.map((ele, i) => {
                                      return (
                                        <option value={ele?.id}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                </select>
                                <span>
                                  <img src="images/down.png" />
                                </span>
                              </div>
                              <span style={{ color: "red", fontSize: "14px" }}>
                                {billError?.profileError}
                              </span>
                            </tr>
                            <tr>
                              <td>Subtotal</td>
                              <td>
                                {billDataCategorySubtotal}
                                <span
                                  className="estimationModalBelowProfile"
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {billError?.subtotalError}
                                </span>
                              </td>
                              {/* <td>Modify Tax</td> */}
                            </tr>
                            <tr>
                              <td>GST({gst}%)</td>
                              <td>{calculatedGstAmount}</td>
                              <span
                                className="estimationModalBelowProfile"
                                style={{ color: "red", fontSize: "14px" }}
                              >
                                {billError?.gstError}
                              </span>
                              {/* <td>
                          <div className="TableThreeDots">
                            <ul className="">
                              <li className="dropdown">
                                <a
                                  className=""
                                  role="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img src="images/dots.png" />
                                </a>
                                <ol className="dropdown-menu dropdown-menuwidth">
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/remove_tax_line.png" />
                                      Remove Tax line
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddTaxDefinitionLeft"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/add_new_tax_line.png" />
                                      Add New Tax line
                                    </a>
                                  </li>
                                </ol>
                              </li>
                            </ul>
                          </div>
                        </td> */}
                            </tr>
                            <tr>
                              <td>Grand Total</td>
                              <td>{Math.round(grandTotal)}</td>
                              <span
                                className="estimationModalBelowProfile"
                                style={{ color: "red", fontSize: "14px" }}
                              >
                                {billError?.grandTotalError}
                              </span>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-4">
                    <div className="Heading">
                      <h6 className="text-black">Attachments</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5>Scope of Work</h5>
                      <div className="CommonModalBox">
                        <h5 className="CursorPointer">
                          <figure>
                            <img src="images/scopeofwork.png" />
                          </figure>{" "}
                          Scope of Work
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5>Construction Documents</h5>
                      <div className="CommonModalBox">
                        <h5 className="CursorPointer">
                          <figure>
                            <img src="images/construction_documents.png" />
                          </figure>{" "}
                          Construction Documents
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="CommonModalArea">
                    <div className="form-group">
                      <h5>Additional Documents</h5>
                      <div className="CommonModalBox">
                        <h5 className="CursorPointer">
                          <figure>
                            <img src="images/upload_additional.png" />
                          </figure>{" "}
                          Additional Documents
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between mt-4 ">
                <button className="Create" onClick={updateBillFunc}>
                  Save and <img src="images/white_down_arrow.png" />
                </button>
                <button
                  className="Create"
                  data-toggle="modal"
                  data-target="#BillModal"
                  data-dismiss="modal"
                >
                  Save draft
                </button>
                <button className="Outline" onClick={hideBillModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* BUDGETCHANGEORDERMODALSHOW */}
      <Modal
        show={BudgetChangeOrderModal}
        onHide={hideBudgetChangeOrderModal}
        className="LargeModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideBudgetChangeOrderModal}
            role="button"
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create New Budget Change Order</h4>
            </div>
            <div className="FolderForm">
              <div className="ModalBigheading">
                <h3>
                  <span>Budget Change Order # 1</span>
                </h3>
                <h3>
                  <span>Project:</span> Rivers Edge Homes
                </h3>
                <h3>
                  <span>Cost Code:</span> 30-0102 - Framing - Labor
                </h3>
                <h3>
                  <span>Job:</span> Rivers Edge Unit 366(RIV-2B-366)
                </h3>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="form-group col-md-8">
                      <h6>Document Description</h6>
                      <textarea
                        className="form-control"
                        rows={2}
                        placeholder="Insert Description"
                        name="documentDescription"
                        value={documentDescription}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {budgetChangeError?.documentDescriptionError}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-9">
                      <div className="Categories Categories1">
                        <h6>Reason</h6>
                        <div className="Categories_all Categories_all1">
                          <select
                            name="reasonId"
                            value={reasonId}
                            onChange={handleInput}
                          >
                            <option>Select Reason</option>
                            {checkArray(reasonListData) ? (
                              <>
                                {reasonListData?.map((ele, i) => {
                                  return (
                                    <option value={ele?.id}>
                                      {ele?.description}
                                    </option>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </select>
                          <img src="images/down.png" />
                        </div>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {budgetChangeError?.reasonIdError}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-10">
                      <div className="ModalBigheading">
                        <h3>
                          <span>Current Working Budget:</span> $6,000.00
                        </h3>
                      </div>
                      <div className="form-group">
                        <h6>Budget Increase Amount</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert increase amount pre-tax"
                          name="increaseAmount"
                          value={increaseAmount}
                          onChange={handleInput}
                        />
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {budgetChangeError?.increaseAmountError}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ModalBigheading">
                    <h3>
                      <span>Remaining Budget:</span> $0.00
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-10">
                      <div className="form-group">
                        <div className="Categories Categories1">
                          <h6>Related Document</h6>
                          <div className="Categories_all Categories_all1">
                            <select
                              name="relatedDocumentId"
                              value={relatedDocumentId}
                              onChange={handleInput}
                            >
                              <option>Select Related Docs</option>
                              {checkArray(quoteRequestListData) ? (
                                <>
                                  {quoteRequestListData?.map((ele, i) => {
                                    return (
                                      <option value={ele?.id}>
                                        {ele?.documentCode}
                                      </option>
                                    );
                                  })}
                                </>
                              ) : (
                                <></>
                              )}
                            </select>
                          </div>
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {budgetChangeError?.relatedDocumentIdError}
                          </span>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="CommonModalArea">
                          <h5>Add Supporting Document</h5>
                          <div className="CommonModalBox">
                            <h5
                              className="CursorPointer FolderPermissionId"
                              onClick={() => showSupportingDocsModal()}
                            >
                              <figure>
                                <img src="images/icon-63.png" />
                              </figure>
                              Add Supporting Document
                            </h5>
                            <span style={{ color: "red", fontSize: "14px" }}>
                              {budgetChangeError?.supportingDocsError}
                            </span>
                            {/* <span
                                className="Count CursorPointer FolderPermissionId"
                                data-toggle="modal"
                                data-target="#SupportingDocumentListQuoteRequestPurchase"
                                data-dismiss="modal"
                              >
                                3
                              </span> */}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories1">
                          <h6>Builder Signee</h6>
                          <div className="Categories_all Categories_all1">
                            <select
                              name="builderSigneeId"
                              value={builderSigneeId}
                              onChange={handleInput}
                            >
                              <option>Select Builder</option>
                              {checkArray(builderSigneeListData) ? (
                                <>
                                  {builderSigneeListData?.map((ele, i) => {
                                    return (
                                      <option value={ele?.id}>
                                        {ele?.firstName}
                                      </option>
                                    );
                                  })}
                                </>
                              ) : (
                                <></>
                              )}
                            </select>
                          </div>
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {budgetChangeError?.builderSigneeIdError}
                          </span>
                        </div>
                      </div>
                      <div className="ModalBigheading">
                        <h3>
                          <span>Investor Signee</span>
                        </h3>
                        <h3>
                          {/* <span>Company:</span> */}
                          <div className="form-group">
                            <div className="Categories Categories1">
                              <h6>Investor</h6>
                              <div className="Categories_all Categories_all1">
                                <select
                                  name="vendorId"
                                  value={vendorId}
                                  onChange={handleInput}
                                >
                                  <option>Select Investor</option>
                                  {checkArray(vendorListData?.VendorList) ? (
                                    <>
                                      {vendorListData?.VendorList?.map(
                                        (ele, i) => {
                                          return (
                                            <option value={ele?.id}>
                                              {ele?.vendorName}
                                            </option>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </select>
                              </div>
                              <span style={{ color: "red", fontSize: "14px" }}>
                                {budgetChangeError?.vendorIdError}
                              </span>
                            </div>
                          </div>
                        </h3>
                      </div>
                      <div className="form-group">
                        <h6>Full Name</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Jane Investor"
                          name="InvesterfullName"
                          value={InvesterfullName}
                          onChange={handleInput}
                          // disabled= {vendorId}
                        />
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {budgetChangeError?.vendorIdError}
                        </span>
                      </div>
                      <div className="form-group">
                        <h6>Email</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert email"
                          name="InvesterEmail"
                          value={InvesterEmail}
                          onChange={handleInput}
                          // disabled= {vendorId}
                        />
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {budgetChangeError?.vendorIdError}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="Button mb-4">
                    <button className="Create">
                      Generate /update Budget Change Order
                    </button>
                  </div>
                  <div className="form-group">
                    <div className="CommonModalArea">
                      <h5>Budget Change Order Agreement</h5>
                      <div className="CommonModalBox">
                        <figure
                          className="CursorPointer FolderPermissionId"
                          data-toggle="modal"
                          data-target="#ViewBudgetChangeOrderAgreementLeft"
                          data-dismiss="modal"
                        >
                          <img src="images/view_budget.png" />
                        </figure>
                        &nbsp;
                        <h5>
                          <span className="Count">3</span> Budget Change Order
                          Agreement
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <h6>Authorization Type</h6>
                    <label className="Radio">
                      Electronic
                      <input
                        type="radio"
                        name="authorisationType"
                        value="Electronic"
                        onChange={handleCheckbox}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="Radio">
                      Hardcopy
                      <input
                        type="radio"
                        name="authorisationType"
                        value="Hardcopy"
                        onChange={handleCheckbox}
                      />
                      <span className="checkmark" />
                    </label>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {budgetChangeError?.authorisationTypeError}
                    </span>
                  </div>

                  <div className="text RadioText">
                    {/* <div class="form-group">
                <h6>Message (Max 1000 chars limit)</h6>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Write here ....."
                />
                <small class="form-text text-right"
                  >985 characters remaining</small
                >
              </div> */}
                  </div>
                  <div className="file RadioText">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Download Hardcopy Budget Change Order</h5>
                        <div className="CommonModalBox">
                          <h5>
                            <figure>
                              <img src="images/view_budget.png" />
                            </figure>{" "}
                            <span className="Count">3</span>
                            Download Hardcopy Budget Change Order
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Budget Change Order Agreement</h5>
                        <div className="CommonModalBox">
                          <h5>
                            <figure>
                              <img src="images/upload_signed_agreement.png" />
                            </figure>{" "}
                            <span className="Count">3</span> Budget Change Order
                            Agreement
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="CommonModalArea">
                      <h5>Reminder</h5>
                      <div className="CommonModalBox">
                        <h5>
                          <figure
                            onClick={() =>
                              showAddRemianderModal("budgetChange")
                            }
                          >
                            <img src="images/timer.png" />
                          </figure>{" "}
                          5 Days
                        </h5>
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {budgetChangeError?.reminderDateError}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button mt-3 d-flex justify-content-between">
                <button onClick={(e) => budgetChangeFunc(e)} className="Create">
                  Send Budget Change Order
                </button>
                <button
                  onClick={(e) => budgetChangeFunc(e, "DRAFT")}
                  className="Create"
                >
                  Save Draft
                </button>
                <button
                  className="Outline"
                  onClick={hideBudgetChangeOrderModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={reminderModal}
        onHide={hideAddRemianderModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAddRemianderModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Reminder</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>
                  Weeks <span className="required"></span>
                </h6>
                <div className="CommonSelectBox w-100">
                  <select
                    disabled={day}
                    name="week"
                    value={week}
                    onChange={handleInput}
                  >
                    <option value="">
                      Select amount of weeks for reminder
                    </option>
                    <option value="7">1</option>
                    <option value="14">2</option>
                    <option value="21">3</option>
                    <option value="28">4</option>
                  </select>
                  <span>
                    <img src="images/down.png" />
                  </span>
                </div>
                {/* </div> */}
              </div>

              <div className="form-group">
                <h6>
                  Days <span className="required"></span>
                </h6>
                <div className="CommonSelectBox w-100">
                  <select
                    disabled={week}
                    name="day"
                    value={day}
                    onChange={handleInput}
                  >
                    <option value="">Select amount of days for reminder</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </select>
                  <span>
                    <img src="images/down.png" />
                  </span>
                </div>
              </div>
              <div className="Button text-center mt-5">
                <button className="Create" onClick={hideAddRemianderModal}>
                  Set Reminder
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={BillQuoteReqModal}
        onHide={hideBillQuoteReqModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideBillQuoteReqModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Add Item Details</h4>
            </div>
            <div className="FolderForm">
              <div className="ModalBigheading">
                <h3>
                  <span>Bill #</span>147
                </h3>
                <h3>
                  <span>Bill Category #</span>1
                </h3>
              </div>
              <div className="ItemDetails">
                <h6>Item Details</h6>
              </div>
              <div className="Tableheader mb-3">
                <h6 className="mb-0">Item</h6>
                <div className="Actions">
                  <a href="javascript:void(0);">
                    <img src="images/icon-53.png" />
                  </a>
                  <DownloadTableExcel
                    filename="Quote item List"
                    sheet="Quote items list"
                    currentTableRef={quoteRef.current}
                  >
                    <button href="jacascript:void(0);">
                      <img src="images/download.png" />
                    </button>
                  </DownloadTableExcel>
                  <a href="javascript:void(0);">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
              <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                <table ref={quoteRef}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        <div className="text-center">
                          Material Description
                          <br />
                          (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                        </div>
                      </th>
                      <th>Color</th>
                      <th>Quantity</th>
                      <th>Units</th>
                      <th>Price/Unit</th>
                      <th>Total</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {billQuoteItems?.map((item, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              value={item.name}
                              onChange={(e) =>
                                handleChangeArrayItem(e, i, "billItemLine")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              name="color"
                              value={item.color}
                              onChange={(e) =>
                                handleChangeArrayItem(e, i, "billItemLine")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              name="quantity"
                              value={item.quantity}
                              onChange={(e) =>
                                handleChangeArrayItem(e, i, "billItemLine")
                              }
                            />
                          </td>
                          <td>
                            <select
                              className="form-control"
                              name="unitId"
                              value={item.unitId}
                              onChange={(e) =>
                                handleChangeArrayItem(e, i, "billItemLine")
                              }
                            >
                              <option value=""> Select Option</option>
                              {checkArray(estimationUnitListData) &&
                                estimationUnitListData?.map((ele, i) => {
                                  return (
                                    <option value={ele?.id}>
                                      {" "}
                                      {ele?.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              name="price"
                              value={item.price}
                              onChange={(e) =>
                                handleChangeArrayItem(e, i, "billItemLine")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              name="total"
                              disabled
                              value={+item?.price * +item?.quantity}
                              onChange={(e) =>
                                handleChangeArrayItem(e, i, "billItemLine")
                              }
                            />
                          </td>
                          <td>
                            <div className="TableThreeDots">
                              <ul className="">
                                <li className="dropdown">
                                  <a
                                    className=""
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/dots.png" />
                                  </a>
                                  <ol className="dropdown-menu">
                                    <li>
                                      <a
                                        onClick={(e) =>
                                          addExtraRowInFunc(e, "billItemLine")
                                        }
                                        href="javascript:void(0);"
                                      >
                                        <img src="images/add_new_column.png" />
                                        Add New Line
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() =>
                                          removeLine(i, "billItemLine")
                                        }
                                        href="javascript:void(0);"
                                      >
                                        <img src="images/dropdown-delete.png" />
                                        Remove Line
                                      </a>
                                    </li>
                                  </ol>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                    <span style={{ color: "red", fontSize: "18px" }}>
                      {billNewQuoteError}
                    </span>
                    {/* <span style={{color:"red", fontSize:"30px"}}>{"itdjkfhdkjghdfgkldfhglfdkghdflemErrors"}</span> */}
                    <tr>
                      <td colSpan={8}>
                        <div className="TableButtons">
                          <button
                            onClick={(e) =>
                              addExtraRowInFunc(e, "billItemLine")
                            }
                            className="BrandBtn"
                          >
                            Add Line
                          </button>
                          <button
                            onClick={(e) => clearAllLines(e, "billItemLine")}
                            className="BrandBtn"
                          >
                            Clear all lines
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="Button d-flex justify-content-center mt-5">
                <button className="Create mr-5" onClick={insertQuoteIntoBill}>
                  Insert Items
                </button>
                <button
                  className="Outline ml-5"
                  onClick={hideBillQuoteReqModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* NEWQUOTEREQUESTMODALSHOW */}
      <Modal
        show={FieldPurchaseOrderModal}
        onHide={hideFieldPurchaseOrder}
        className="LargeModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideFieldPurchaseOrder}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox ModalTitleBoxGreen">
              <h4>Create New Field Purchase Order</h4>
            </div>
            <div className="FolderForm">
              <div className="BlueTextArea GreenTextArea">
                <div className="row">
                  <div className="col-md-6">
                    <div className="Heading">
                      <h3>Field Quote Request # {estimationDocumentId} </h3>
                    </div>
                    <a
                      href="javascript:void(0);"
                      className="BlueOutline mb-4"
                      onClick={() =>
                        showSupplierCreditModal("fieldPurchaseOrder")
                      }
                    >
                      <img src="images/create_quote_blue.png" />
                      Create as Supplier Credit
                    </a>
                    <div className="row">
                      <div className="form-group col-md-9">
                        <div className="Categories Categories1">
                          <h6>Reason</h6>
                          <div className="Categories_all Categories_all1">
                            <select
                              name="reasonId"
                              value={reasonId}
                              onChange={handleInput}
                            >
                              <option>Select Reason</option>
                              {checkArray(reasonListData) ? (
                                <>
                                  {reasonListData?.map((ele, i) => {
                                    return (
                                      <option value={ele?.id}>
                                        {ele?.description}
                                      </option>
                                    );
                                  })}
                                </>
                              ) : (
                                <></>
                              )}
                            </select>
                            <img src="images/down.png" />
                          </div>
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {newQuoteRequestError?.reasonIdError}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="Heading">
                      <h3 className="d-flex">
                        Rivers Edge Unit 366(RIV-2B-366)
                        <div className="TableThreeDots ml-5">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#UploadAdditionalDocumentsLeft"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/upload_additional.png" />
                                    Upload Additional Documents
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#RequestLimitIncreaseLeft"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/request_limit.png" />
                                    Request Limit Increase
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#VendorBackChargeNotificationLeft"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/notify_vendor.png" />
                                    Notify Vendor of Back Charge
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </h3>
                      <h3>
                        Cost Code: <span>23-0101 - Appliances</span>
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="ModalFilterAreaBox">
                      <h4 className="mb-4">Vendor</h4>
                      <div className="RadioArea d-flex  flex-column">
                        <div className="form-group mb-3">
                          <label className="Radio">
                            Use Cost Code's preferred vendor
                            <input type="radio" name="Cuisine" />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="form-group">
                          <label className="Radio">
                            Select from list
                            <input type="radio" name="Cuisine" />
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                      {/* <span  style={{color:"red", fontSize:"14px"}}>{newQuoteRequestError?.vendorIdError}</span> */}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <h6>Date</h6>
                      <p className="ml-0">{getDate()}</p>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {newQuoteRequestError?.dateError}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5 className="GreenText">Reminder</h5>
                        <div className="CommonModalBox">
                          <figure
                            className="CursorPointer FolderPermissionId"
                            onClick={() =>
                              showAddRemianderModal("fieldPurchaseOrder")
                            }
                          >
                            <img src="images/timer.png" />
                          </figure>
                          <h5>Select</h5>
                        </div>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {newQuoteRequestError?.reminderDateError}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <div className="CommonModalBox">
                          <figure
                            className="CursorPointer FolderPermissionId"
                            onClick={() =>
                              showSystemVendor("fieldPurchaseOrder")
                            }
                          >
                            <img src="images/vendor.png" />
                          </figure>
                          <h5 className="text-black">Vendor 1</h5>
                        </div>{" "}
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {newQuoteRequestError?.vendorIdError}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ShippingAddressArea">
                      <div className="ShippingAddressAreaBox">
                        <h6>Mailing Address</h6>
                        <p>
                          {vendorDetailsData?.addressLine1}{" "}
                          {vendorDetailsData?.addressLine2}{" "}
                          {vendorDetailsData?.city}
                        </p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Email</h6>
                        <p> {vendorDetailsData?.email}</p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Phone</h6>
                        <p> {vendorDetailsData?.phone}</p>
                      </div>
                      {/* <div className="ShippingAddressAreaBox">
                <h6>Shipping Address</h6>
                <p>5384 Peach Rd Chilliwack, BC V2R0Z6</p>
              </div> */}
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="form-group">
                      <h6>Messsage</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert message to be sent to vendor"
                        name="message"
                        value={message}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {newQuoteRequestError?.messageError}
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ItemDetails">
                      <h6>Item Details</h6>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="Tableheader mb-3">
                      <h6 className="mb-0">Item</h6>
                      <div className="Actions">
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <DownloadTableExcel
                          filename="Quote item List"
                          sheet="Quote items list"
                          currentTableRef={quoteRef.current}
                        >
                          <button href="jacascript:void(0);">
                            <img src="images/download.png" />
                          </button>
                        </DownloadTableExcel>
                        <a href="javascript:void(0);">
                          <img src="images/setting.png" />
                        </a>
                      </div>
                    </div>
                    <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                      <table ref={quoteRef}>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>
                              <div className="text-center">
                                Material Description
                                <br />
                                (i.e: Modal/Type/Style/manuf part#/Dimensions,
                                etc.)
                              </div>
                            </th>
                            <th>Color</th>
                            <th>Quantity</th>
                            <th>Units</th>
                            <th>Price/Unit</th>
                            <th>Total</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items?.map((item, i) => {
                            return (
                              <tr>
                                <td>{i + 1}</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={item.name}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="color"
                                    value={item.color}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="quantity"
                                    value={item.quantity}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  {/* <div className="SelectAnOption">
                          <div className="dropdown"> */}
                                  {/* <button
                              className=""
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            > */}
                                  {/* Select measure type
                             <img src="images/black-down-arrow.png" /> */}
                                  <select
                                    className="form-control"
                                    name="unitId"
                                    value={item.unitId}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  >
                                    <option value=""> Select Option</option>
                                    {checkArray(estimationUnitListData) &&
                                      estimationUnitListData?.map((ele, i) => {
                                        return (
                                          <option value={ele?.id}>
                                            {" "}
                                            {ele?.name}
                                          </option>
                                        );
                                      })}
                                  </select>

                                  {/* </button> */}
                                  {/* <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                <input type="text" placeholder="-Search" />
                                <span>
                                  <img src="images/search.png" />
                                </span>
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                <img src="images/add-plus.png" />
                                Add New
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                - Full list of units loaded from the system
                              </a>
                            </div> */}
                                  {/* </div>
                        </div> */}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="price"
                                    value={item.price}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="total"
                                    disabled
                                    value={+item.price * +item.quantity}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <div className="TableThreeDots">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol className="dropdown-menu">
                                          <li>
                                            <a
                                              onClick={(e) =>
                                                addExtraRowInFunc(e, "quote")
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/add_new_column.png" />
                                              Add New Line
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                removeLine(i, "newQuote")
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/dropdown-delete.png" />
                                              Remove Line
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                          <span style={{ color: "red", fontSize: "18px" }}>
                            {itemErrors}
                          </span>
                          {/* <span style={{color:"red", fontSize:"30px"}}>{"itdjkfhdkjghdfgkldfhglfdkghdflemErrors"}</span> */}

                          <tr>
                            <td colSpan={8}>
                              <div className="TableButtons">
                                <button
                                  onClick={(e) => addExtraRowInFunc(e, "quote")}
                                  className="BrandBtn"
                                >
                                  Add Line
                                </button>
                                <button
                                  onClick={(e) => clearAllLines(e, "quote")}
                                  className="BrandBtn"
                                >
                                  Clear all lines
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-md-4 ml-auto">
                        <div className="GstTable">
                          <table>
                            <tbody>
                              <tr>
                                <td>Profile</td>
                                <div class="CommonSelectBox">
                                  <select
                                    name="profile"
                                    value={profile}
                                    onChange={handleInput}
                                  >
                                    <option value="">Select profile</option>
                                    {checkArray(profileListData) &&
                                      profileListData?.map((ele, i) => {
                                        return (
                                          <option value={ele?.id}>
                                            {ele?.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                  <span>
                                    <img src="images/down.png" />
                                  </span>
                                </div>
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {newQuoteRequestError?.profileError}
                                </span>
                              </tr>
                              <tr>
                                <td>Subtotal</td>
                                <td>
                                  {subtotal}
                                  <span
                                    className="estimationModalBelowProfile"
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {newQuoteRequestError?.subtotalError}
                                  </span>
                                </td>
                                {/* <td>Modify Tax</td> */}
                              </tr>
                              <tr>
                                <td>GST({gst}%)</td>
                                <td>{calculatedGstAmount}</td>
                                <span
                                  className="estimationModalBelowProfile"
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {newQuoteRequestError?.gstError}
                                </span>
                                {/* <td>
                          <div className="TableThreeDots">
                            <ul className="">
                              <li className="dropdown">
                                <a
                                  className=""
                                  role="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img src="images/dots.png" />
                                </a>
                                <ol className="dropdown-menu dropdown-menuwidth">
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/remove_tax_line.png" />
                                      Remove Tax line
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddTaxDefinitionLeft"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/add_new_tax_line.png" />
                                      Add New Tax line
                                    </a>
                                  </li>
                                </ol>
                              </li>
                            </ul>
                          </div>
                        </td> */}
                              </tr>
                              <tr>
                                <td>Grand Total</td>
                                <td>{Math.round(grandTotal)}</td>
                                <span
                                  className="estimationModalBelowProfile"
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {newQuoteRequestError?.grandTotalError}
                                </span>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-4">
                      <div className="Heading">
                        <h6>Attachments</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Scope of Work</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#SelectScopesOfWorkLeft"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/scopeofwork.png" />
                            </figure>{" "}
                            Scope of Work &nbsp;<span className="Count">3</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Construction Documents</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#SelectConstructionDocumentsLeft"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/construction_documents.png" />
                            </figure>{" "}
                            Construction Documents &nbsp;
                            <span className="Count">3</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>External Quote</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#ImportReturnedQuoteLeft"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/extermal_quote.png" />
                            </figure>{" "}
                            External Quote &nbsp;
                            <span className="Count">3</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between mt-4 ">
                  <div className="dropdown ml-3">
                    <button
                      className="Create"
                      type="button"
                      onClick={(e) =>
                        handleQuoteRequestFunc(e, "SEND", "fieldPurchaseOrder")
                      }
                    >
                      Save and <img src="images/black-down-arrow.png" />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a className="dropdown-item" href="#">
                        Save and Print
                      </a>
                      <a className="dropdown-item" href="#">
                        Save and Send
                      </a>
                    </div>
                  </div>
                  <button
                    onClick={(e) =>
                      handleQuoteRequestFunc(e, "DRAFT", "fieldPurchaseOrder")
                    }
                    className="Create"
                  >
                    Save draft
                  </button>
                  <button className="Create" onClick={hideFieldPurchaseOrder}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* SupplierCreateModalshow */}
      <Modal
        show={SupplyCreditModal}
        onHide={hideSupplierCreditModal}
        className="LargeModal"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={hideSupplierCreditModal}
            role="button"
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox ModalTitleBoxRed">
              <h4>Create New Supplier Credit Order</h4>
            </div>
            <div className="FolderForm">
              <div className="BlueTextArea RedTextArea">
                <div className="row">
                  <div className="col-md-6">
                    <div className="Heading">
                      <h3>Supplier credit Request # ${estimationDocumentId}</h3>
                    </div>
                    <a
                      href="javascript:void(0);"
                      className="BlueOutline mb-4"
                      onClick={() => showFieldPurchaseOrder("supplierCredit")}
                    >
                      <img src="images/create_quote_blue.png" />
                      Create as Field Purchase Order
                    </a>
                    <div className="row">
                      <div className="form-group col-md-9">
                        <div className="Categories Categories1">
                          <h6>Reason</h6>
                          <div className="Categories_all Categories_all1">
                            <select
                              name="reasonId"
                              value={reasonId}
                              onChange={handleInput}
                            >
                              <option>Select Reason</option>
                              {checkArray(reasonListData) ? (
                                <>
                                  {reasonListData?.map((ele, i) => {
                                    return (
                                      <option value={ele?.id}>
                                        {ele?.description}
                                      </option>
                                    );
                                  })}
                                </>
                              ) : (
                                <></>
                              )}
                            </select>
                            <img src="images/down.png" />
                          </div>
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {newQuoteRequestError?.reasonIdError}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="Heading">
                      <h3 className="d-flex">
                        Rivers Edge Unit 366(RIV-2B-366)
                        <div className="TableThreeDots ml-5">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#UploadAdditionalDocumentsLeft"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/upload_additional.png" />
                                    Upload Additional Documents
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#RequestLimitIncreaseLeft"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/request_limit.png" />
                                    Request Limit Increase
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#VendorBackChargeNotificationLeft"
                                    data-dismiss="modal"
                                  >
                                    <img src="images/notify_vendor.png" />
                                    Notify Vendor of Back Charge
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </h3>
                      <h3>
                        Cost Code: <span>23-0101 - Appliances</span>
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="ModalFilterAreaBox">
                      <h4 className="mb-4">Vendor</h4>
                      <div className="RadioArea d-flex  flex-column">
                        <div className="form-group mb-3">
                          <label className="Radio">
                            Use Cost Code's preferred vendor
                            <input type="radio" name="Cuisine" />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="form-group">
                          <label className="Radio">
                            Select from list
                            <input type="radio" name="Cuisine" />
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                      {/* <span  style={{color:"red", fontSize:"14px"}}>{newQuoteRequestError?.vendorIdError}</span> */}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <h6>Date</h6>
                      <p className="ml-0">{getDate()}</p>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {newQuoteRequestError?.dateError}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5 className="RedText">Reminder</h5>
                        <div className="CommonModalBox">
                          <figure
                            className="CursorPointer FolderPermissionId"
                            onClick={() =>
                              showAddRemianderModal("supplierCredit")
                            }
                          >
                            <img src="images/timer.png" />
                          </figure>
                          <h5>Select</h5>
                        </div>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {newQuoteRequestError?.reminderDateError}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <div className="CommonModalBox">
                          <figure
                            className="CursorPointer FolderPermissionId"
                            onClick={() => showSystemVendor("supplierCredit")}
                          >
                            <img src="images/vendor.png" />
                          </figure>
                          <h5 className="text-black">Vendor 1</h5>
                        </div>{" "}
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {newQuoteRequestError?.vendorIdError}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ShippingAddressArea">
                      <div className="ShippingAddressAreaBox">
                        <h6>Mailing Address</h6>
                        <p>
                          {vendorDetailsData?.addressLine1}{" "}
                          {vendorDetailsData?.addressLine2}{" "}
                          {vendorDetailsData?.city}
                        </p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Email</h6>
                        <p> {vendorDetailsData?.email}</p>
                      </div>
                      <div className="ShippingAddressAreaBox">
                        <h6>Phone</h6>
                        <p> {vendorDetailsData?.phone}</p>
                      </div>
                      {/* <div className="ShippingAddressAreaBox">
                <h6>Shipping Address</h6>
                <p>5384 Peach Rd Chilliwack, BC V2R0Z6</p>
              </div> */}
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="form-group">
                      <h6>Messsage</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert message to be sent to vendor"
                        name="message"
                        value={message}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {newQuoteRequestError?.messageError}
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="ItemDetails">
                      <h6>Item Details</h6>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="Tableheader mb-3">
                      <h6 className="mb-0">Item</h6>
                      <div className="Actions">
                        <a href="javascript:void(0);">
                          <img src="images/icon-53.png" />
                        </a>
                        <DownloadTableExcel
                          filename="Quote item List"
                          sheet="Quote items list"
                          currentTableRef={quoteRef.current}
                        >
                          <button href="jacascript:void(0);">
                            <img src="images/download.png" />
                          </button>
                        </DownloadTableExcel>
                        <a href="javascript:void(0);">
                          <img src="images/setting.png" />
                        </a>
                      </div>
                    </div>
                    <div className="SchedulePaymentsTable TransactionTableShow mb-4">
                      <table ref={quoteRef}>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>
                              <div className="text-center">
                                Material Description
                                <br />
                                (i.e: Modal/Type/Style/manuf part#/Dimensions,
                                etc.)
                              </div>
                            </th>
                            <th>Color</th>
                            <th>Quantity</th>
                            <th>Units</th>
                            <th>Price/Unit</th>
                            <th>Total</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items?.map((item, i) => {
                            return (
                              <tr>
                                <td>{i + 1}</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={item.name}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="color"
                                    value={item.color}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="quantity"
                                    value={item.quantity}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  {/* <div className="SelectAnOption">
                          <div className="dropdown"> */}
                                  {/* <button
                              className=""
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            > */}
                                  {/* Select measure type
                             <img src="images/black-down-arrow.png" /> */}
                                  <select
                                    className="form-control"
                                    name="unitId"
                                    value={item.unitId}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  >
                                    <option value=""> Select Option</option>
                                    {checkArray(estimationUnitListData) &&
                                      estimationUnitListData?.map((ele, i) => {
                                        return (
                                          <option value={ele?.id}>
                                            {" "}
                                            {ele?.name}
                                          </option>
                                        );
                                      })}
                                  </select>

                                  {/* </button> */}
                                  {/* <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                <input type="text" placeholder="-Search" />
                                <span>
                                  <img src="images/search.png" />
                                </span>
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                <img src="images/add-plus.png" />
                                Add New
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                - Full list of units loaded from the system
                              </a>
                            </div> */}
                                  {/* </div>
                        </div> */}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="price"
                                    value={item.price}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="total"
                                    disabled
                                    value={+item.price * +item.quantity}
                                    onChange={(e) =>
                                      handleChangeArrayItem(e, i, "quote")
                                    }
                                  />
                                </td>
                                <td>
                                  <div className="TableThreeDots">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol className="dropdown-menu">
                                          <li>
                                            <a
                                              onClick={(e) =>
                                                addExtraRowInFunc(e, "quote")
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/add_new_column.png" />
                                              Add New Line
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                removeLine(i, "newQuote")
                                              }
                                              href="javascript:void(0);"
                                            >
                                              <img src="images/dropdown-delete.png" />
                                              Remove Line
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                          <span style={{ color: "red", fontSize: "18px" }}>
                            {itemErrors}
                          </span>
                          {/* <span style={{color:"red", fontSize:"30px"}}>{"itdjkfhdkjghdfgkldfhglfdkghdflemErrors"}</span> */}

                          <tr>
                            <td colSpan={8}>
                              <div className="TableButtons">
                                <button
                                  onClick={(e) => addExtraRowInFunc(e, "quote")}
                                  className="BrandBtn"
                                >
                                  Add Line
                                </button>
                                <button
                                  onClick={(e) => clearAllLines(e, "quote")}
                                  className="BrandBtn"
                                >
                                  Clear all lines
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-md-4 ml-auto">
                        <div className="GstTable">
                          <table>
                            <tbody>
                              <tr>
                                <td>Profile</td>
                                <div class="CommonSelectBox">
                                  <select
                                    name="profile"
                                    value={profile}
                                    onChange={handleInput}
                                  >
                                    <option value="">Select profile</option>
                                    {checkArray(profileListData) &&
                                      profileListData?.map((ele, i) => {
                                        return (
                                          <option value={ele?.id}>
                                            {ele?.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                  <span>
                                    <img src="images/down.png" />
                                  </span>
                                </div>
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {newQuoteRequestError?.profileError}
                                </span>
                              </tr>
                              <tr>
                                <td>Subtotal</td>
                                <td>
                                  {subtotal}
                                  <span
                                    className="estimationModalBelowProfile"
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {newQuoteRequestError?.subtotalError}
                                  </span>
                                </td>
                                {/* <td>Modify Tax</td> */}
                              </tr>
                              <tr>
                                <td>GST({gst}%)</td>
                                <td>{calculatedGstAmount}</td>
                                <span
                                  className="estimationModalBelowProfile"
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {newQuoteRequestError?.gstError}
                                </span>
                                {/* <td>
                          <div className="TableThreeDots">
                            <ul className="">
                              <li className="dropdown">
                                <a
                                  className=""
                                  role="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img src="images/dots.png" />
                                </a>
                                <ol className="dropdown-menu dropdown-menuwidth">
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/remove_tax_line.png" />
                                      Remove Tax line
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#AddTaxDefinitionLeft"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/add_new_tax_line.png" />
                                      Add New Tax line
                                    </a>
                                  </li>
                                </ol>
                              </li>
                            </ul>
                          </div>
                        </td> */}
                              </tr>
                              <tr>
                                <td>Grand Total</td>
                                <td>{Math.round(grandTotal)}</td>
                                <span
                                  className="estimationModalBelowProfile"
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {newQuoteRequestError?.grandTotalError}
                                </span>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-4">
                      <div className="Heading">
                        <h6>Attachments</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Scope of Work</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#SelectScopesOfWorkLeft"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/scopeofwork.png" />
                            </figure>{" "}
                            Scope of Work &nbsp;<span className="Count">3</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Construction Documents</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#SelectConstructionDocumentsLeft"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/construction_documents.png" />
                            </figure>{" "}
                            Construction Documents &nbsp;
                            <span className="Count">3</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>External Quote</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#ImportReturnedQuoteLeft"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/extermal_quote.png" />
                            </figure>{" "}
                            External Quote &nbsp;
                            <span className="Count">3</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between mt-4 ">
                  <div className="dropdown ml-3">
                    <button
                      className="Create"
                      type="button"
                      onClick={(e) =>
                        handleQuoteRequestFunc(e, "SEND", "supplierCredit")
                      }
                    >
                      Save and <img src="images/black-down-arrow.png" />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a className="dropdown-item" href="#">
                        Save and Print
                      </a>
                      <a className="dropdown-item" href="#">
                        Save and Send
                      </a>
                    </div>
                  </div>
                  <button
                    onClick={(e) =>
                      handleQuoteRequestFunc(e, "DRAFT", "supplierCredit")
                    }
                    className="Create"
                  >
                    Save draft
                  </button>
                  <button className="Create" onClick={hideSupplierCreditModal}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={supportingDocsModal}
        onHide={hideSupportingDocsModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSupportingDocsModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Add New Supporting Docs</h4>
            </div>
            <div className="JobTitleArea">
              <h3>Supporting Documents #2</h3>
              <div className="JobBox">
                <h6>
                  <span>Job:</span> UUnit3-CHI-2B-315
                </h6>
              </div>
              <div className="JobBox">
                <h6>
                  <span>Origin</span> #185
                </h6>
              </div>
              <div className="JobBox">
                <h6>
                  <span>Item:</span> #143
                </h6>
              </div>
            </div>
            <div className="form-group">
              <h6>Select Files</h6>
              <div className="Upload">
                <span>-Drag and Drop Files or click to select</span>
                <input type="file" name="files" onChange={handleInputFile} />
              </div>
              <span style={{ color: "red", fontSize: "14px" }}>
                {supportingDocsError}
              </span>
            </div>
            {/* <div className="FilesAddedTitle">
              <h4>Files</h4>
            </div> */}
            {/* <div className="FilesAdded">
              <label>File_name.docs</label>
              <span>
                <img src="images/fileview.png" />
              </span>
              <span>
                <img src="images/Cross.png" className="cross" />
              </span>
            </div>
            <div className="FilesAdded">
              <label>File_name.docs</label>
              <span>
                <img src="images/fileview.png" />
              </span>
              <span>
                <img src="images/Cross.png" className="cross" />
              </span>
            </div> */}
            <div className="Button mt-3">
              <button className="Create" onClick={addSupportingDocsFunc}>
                Add Documents
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* NEW PROJECT MODAL */}

      <NewProjectStep1
        SetNewProjectStep1Modal={SetNewProjectStep1Modal}
        NewProjectStep1Modal={NewProjectStep1Modal}
        setShow={setShow}
      />

      {/* MULTI FAMILY JOB MODAL */}

      <MultifamilyJobStep1
        SetMultifamilyJobStep1Modal={SetMultifamilyJobStep1Modal}
        MultifamilyJobStep1Modal={MultifamilyJobStep1Modal}
        setShow={setShow}
      />

      {/* PROJECT AS ENTITY */}

      <ProjectAsEntityStep1
        ProjectAsEntityStep1Modal={ProjectAsEntityStep1Modal}
        SetProjectAsEntityStep1Modal={SetProjectAsEntityStep1Modal}
        setShow={setShow}
      />
    </>
  );
};

export default CreateModal;
