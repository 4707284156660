import React, { useState, useEffect } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  addWarranty,
  getCreateWorkOrder,
  getDraftWarranty,
  getDraftWorkOrder,
  getListOfVendorTypes,
  getVendorList,
  getWarrantyEditableByClient,
  getWarrantySupportDocsUpdate,
  getWorkOrderRequestItemList,
  updateWorkOrder,
} from "../redux/action/warrantyAction";
import { useLocation, useNavigate } from "react-router-dom";
import TradeSuggestedDateModal from "./TradeSuggestedDateModal";
import TradeDaySelectionTable from "./TradeDaySelectionTable";
const initialState = {
  AddNotesModal: false,
  message: "",
  supportingDocFiles: null,
  supportingDocFiless: null,
  AddNewSupportingDocsModal: false,
  VendorModal: false,
  warrantyNotes:"",
  companyName: "",
  vendorType: "",
  vendorName:"",
  phone: "",
  email: "",
  description: "",
  requestDate: "",
  AddItemDocsModal: false,
  itemDocs: "",
  phonecode: "",
  tradesDay:"",
  fromTime:"",
  toTime:"",
  requiredCompletionDate:"",
  phoneCode:"",
  errors: {},
  tradeSuggestedModal:false,
  tradeDaySelectionModal:false,
};

const Warranty_Work_Order = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [iState, updateState] = useState(initialState);
  const {
    AddNotesModal,
    message,
    supportingDocFiles,
    supportingDocFiless,
    AddNewSupportingDocsModal,
    VendorModal,
    companyName,
    vendorType,
    vendorName,
    phone,
    email,
    description,
    requestDate,
    AddItemDocsModal,
    itemDocs,
    phonecode,
    tradesDay,
    fromTime,
    toTime,
    requiredCompletionDate,
    phoneCode,
    errors,
    tradeSuggestedModal,
  tradeDaySelectionModal,
  } = iState;
  const { draftWorkOrder, workOrderReqItemList, vendorTypesList, vendorList } =
    useSelector((state) => state.warrantyReducer);

  useEffect(() => {
    dispatch(getDraftWorkOrder(state?.id, state?.jobType));

    dispatch(getListOfVendorTypes());
    dispatch(getWorkOrderRequestItemList());
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "vendorType") {
      let vendorData = vendorTypesList?.vendorType?.find(
        (item) => item === value
      );
      dispatch(getVendorList(vendorData)).then(data=>{
        console.log({data});
      })
      updateState({
        ...iState,
        [name]: value,
      });
    } else if (name === "vendorName") {
      let vendorListData = vendorList?.vendors?.find(
        (item) => item.vendorName === value
      );
      updateState({
        ...iState,
        [name]: value,
        phone: vendorListData?.phone,
        email: vendorListData?.email,
        companyName: vendorListData?.companyName,
        
        
      });
    } else {
      updateState({
        ...iState,
        [name]: value,
      });
    }
  };

  const handleInputChanges = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };


  const [selectedOption, setSelectedOption] = useState("Text");
  const [textInput, setTextInput] = useState(true);
  const [fileData, setFileData] = useState(false);

  const onValueChange = (event) => {
    updateState({ ...iState, message: "", supportingDocFiles: "" });
    const value = event.target.value;
    setSelectedOption(value);
    setTextInput(value === "Text");
    setFileData(value === "File");
  };

  const handleRemoveClientFile = () => {
    // Reset the 'templateFile' state to null
    updateState({
      ...iState,
      supportingDocFiles: null,
      supportingDocFiless: null,
      itemDocs: null,
    });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#fileInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleAddNotesShow = () => {
    updateState({
      ...iState,
      AddNotesModal: true,
      message: "",
      supportingDocFiles: "",
    });
  };
  const handleAddNotesClose = () => {
    updateState({
      ...iState,
      AddNotesModal: false,
    });
  };

  const handleFileChange = (event, type) => {
    const file = event?.target?.files[0];
    if (type === "support") {
      updateState({ ...iState, supportingDocFiles: file });
    }
    if (type === "itemDoc") {
      updateState({ ...iState, itemDocs: file });
    } else {
      updateState({ ...iState, supportingDocFiless: file });
    }
  };

  const handleAddNotes = () => {
    let dataa;
    if (textInput) {
      dataa = {
        warrantyNotes: [{ noteType: "text", message: message }],
        description: draftWorkOrder?.workOrder?.description
          ? draftWorkOrder?.workOrder?.description
          : "N/A",
        // jobType:"ResidentialJob",
        // jobId:2,
      };
    } else {
      dataa = {
        warrantyNotes: [{ noteType: "files",supportingDocFiles:supportingDocFiles }],
        supportingDocFiles,
        description: draftWorkOrder?.workOrder?.description
          ? draftWorkOrder?.workOrder?.description
          : "N/A",
        // jobType:"ResidentialJob",
        // jobId:2,
      };
    }
    updateState({
      ...iState,
      AddNotesModal: false,

    });
  
  };

  const handleAddNewSupportingDocsModalShow = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: true,
    });
  };
  const handleAddNewSupportingDocsModalClose = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: false,
    });
  };

  const handleSupportDocs = () => {
    let dataa = {
      supportingDocFiles: supportingDocFiless,
    };
    updateState({
      ...iState,
      AddNewSupportingDocsModal: false,
    });

  };

  const handleCheckboxChange = () => {
    const datta =
      draftWorkOrder?.workOrder?.editByClient === true ? false : true;
    dispatch(
      getWarrantyEditableByClient(Number(draftWorkOrder?.workOrder?.id), datta)
    ).then((res) => {
      if (res.status === 200) {
        // dispatch(getDraftWarranty());
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleVendorModalShow = () => {
    updateState({
      ...iState,
      VendorModal: true,
    });
  };
  const handleVendorModalClose = () => {
    updateState({
      ...iState,
      VendorModal: false,
    });
  };

  const [selectedItems, setSelectedItems] = useState([]);

  const handleItemCheckboxChange = (itemId) => {
    if (selectedItems.includes(itemId)) {
      // If the item is already in the selectedItems array, remove it
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    } else {
      // If the item is not in the selectedItems array, add it
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  const handleSaveAndEmail = async(type) => {
    let formIsValid = handleValidation();
    if (formIsValid) {
      try {
        const formData=new FormData()
      formData.append("description",description)
      formData.append("requestDate",requestDate)
      // formData.append("sendEmail",type==="email"?true:false)
      formData.append("clientLegalName",vendorName)
      formData.append("progressStatus","Complete")
      formData.append("vendor[companyName]",companyName)
      formData.append("vendor[vendorType]",vendorType)
      formData.append("vendor[phone]",phone)
      formData.append("vendor[email]",email)
      formData.append("vendor[phonecode]",phoneCode )
      formData.append("phonecode",phonecode )
      formData.append("tradesDay",tradesDay)
      formData.append("fromTime",fromTime)
      formData.append("toTime",toTime)
      formData.append("requiredCompletionDate",requiredCompletionDate)
      formData.append("supportingDocFiles",supportingDocFiless||[])
      if (textInput) {
        formData.append("workOrderNotes[0][noteType]", "text");
        formData.append("workOrderNotes[0][message]", message);
      } else {
        formData.append("workOrderNotes[0][noteType]", "files");
      }

     await dispatch(
        updateWorkOrder(Number(draftWorkOrder?.workOrder?.id), formData)
      ).then((res) => {
        if (res.status === 200) {
          navigate("/warranty-dashboard", { state });
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
      } catch (error) {
        toast.error(error.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    
    }
    } 
  

  const handleAddItemDocsModalShow = () => {
    updateState({
      ...iState,
      AddItemDocsModal: true,
    });
  };
  const handleAddItemDocsModalClose = () => {
    updateState({
      ...iState,
      AddItemDocsModal: false,
    });
  };

  const handleItemDocs = () => {
    let dataa = {
      supportingDocFiles: itemDocs,
    };

    dispatch(
      getWarrantySupportDocsUpdate(draftWorkOrder?.workOrder?.id, dataa)
    ).then((res) => {
      if (res.status === 201) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AddItemDocsModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          AddItemDocsModal: true,
        });
      }
    });
  };

  const handlePhoneInputChange = (value, country) => {
    updateState({
      ...iState,
      phonecode: `+${country.dialCode}`,
    });
  };
  const handlePhoneInputChanges = (value, country) => {
    updateState({
      ...iState,
      phoneCode: `+${country.dialCode}`,
    });
  };
  const[manulEntryShow,setManualEntryShow]=useState(false)
  const handleManualEnteryShow=()=>{
    setManualEntryShow(!manulEntryShow)
  }

  let handleValidation = () => {
    let error = {};
    let formIsValid = true;
    if (!requestDate) {
      error.requestDateError = " * Request Date can't be empty";
      formIsValid = false;
    }

    if (!description || !description.trim()) {
      error.descError = " * Description can't be empty";
      formIsValid = false;
    }
    if (!companyName || !companyName.trim()) {
      error.companyNameError = " * Company Name can't be empty";
      formIsValid = false;
    }
    if (!vendorType || !vendorType.trim()) {
      error.vendorTypeError = " * vendorType can't be empty";
      formIsValid = false;
    }
    if (!email  || !email .trim()) {
      error.emailError = " * email  can't be empty";
      formIsValid = false;
    }
    if (!phoneCode) {
      error.phoneCodeError = " * phoneCode  can't be empty";
      formIsValid = false;
    }
    // if (!fromTime) {
    //   error.fromTimeError = " * fromTime   can't be empty";
    //   formIsValid = false;
    // }
    // if (!toTime) {
    //   error.toTimeError = " * toTime   can't be empty";
    //   formIsValid = false;
    // }
    // if (!tradesDay) {
    //   error.tradesDayError = " * tradesDay    can't be empty";
    //   formIsValid = false;
    // }
    // if (!requiredCompletionDate ) {
    //   error.requiredCompletionDateError = " * requiredCompletionDate    can't be empty";
    //   formIsValid = false;
    // }
    if (!phonecode) {
      error.phonecodeError = " * Country code can't be empty";
      formIsValid = false;
    }
    if (!supportingDocFiless) {
      error.supportingDocFilessError = " * Supporting Docs can't be empty";
      formIsValid = false;
    }

   
    updateState({
      ...iState,
      errors: error,
    });
    return formIsValid;
  };

  
  const handletradeSuggestedModalClose=()=>{
    updateState(prev=>({
      ...prev,
      tradeSuggestedModal:false
    }))
  }
  const handletradeDaySelectionModalClose=()=>{
    updateState(prev=>({
      ...prev,
      tradeDaySelectionModal:false
    }))
  }
  const handletradeDaySelectionModalShow=()=>{
    updateState(prev=>({
      ...prev,
      tradeDaySelectionModal:true
    }))
  }
  return (
    <>
      <Header />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox pb-5">
          <div className="MainTitleBox">
            <div className="MainTitle">
              <h2>Work Order</h2>
            </div>
          </div>
          <div className="TitleBox">
            <h4 className="Title">Work Order #{draftWorkOrder?.workOrder?.id}</h4>
            <div className="TitleBox2 TitleBoxRight">
              <div className="dropdown">
                <h6 data-toggle="dropdown">{state?.jobNumber ?? "N/A"}</h6>
                <span>{state?.jobType ?? "N/A"}</span>
             
              </div>
            
              <a>
                <img src="images/Icon-4.png" />
              </a>
            </div>
          </div>
          <div className="WarrantyDashboardForm mb-4">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>
                        Description<span className="Required">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        rows={3}
                        placeholder="Write here ....."
                        name="description"
                        value={description}
                        onChange={handleInputChanges}
                      />
                       <span style={{ color: "red" }}>{errors?.descError}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <div className="form-group">
                    <label>
                      Vendor<span className="Required">*</span>
                    </label>
                  </div>
                  <div className="TodosButtons mt-3">
                    <a
                      href="javascript:void(0);"
                      className="ml-0"
                      id="ManualEntry"
                      onClick={handleManualEnteryShow}
                    >
                      <img src="images/manual-entry.png" />
                      Manual entry
                    </a>
                    <a onClick={handleVendorModalShow}>
                      <img src="images/select-from-list.png" />
                      Select from the list
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="WarrantyDashboardForm">
            <div className="row">
              <div className="col-lg-6">
                <h4>Client Details</h4>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Possession Date</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Request Date</label>
                      <input
                        type="date"
                        className="form-control"
                        name="requestDate"
                        value={requestDate}
                        onChange={handleInputChanges}
                      />
                       <span style={{ color: "red" }}>{errors?.requestDateError}</span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Client Full Legal Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label>Country Code</label>
                      <PhoneInput
                        inputClass="CountryCodeInput"
                        countryCodeEditable={false}
                        enableSearch
                        value={phonecode}
                        onChange={handlePhoneInputChange}
                      />
                      <span style={{ color: "red" }}>{errors.phonecodeError}</span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Primary Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        Job Address{" "}
                        <img src="images/location.png" className="ml-2" />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Supporting Document</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer"
                            onClick={handleAddNewSupportingDocsModalShow}
                          >
                            <figure>
                              <img src="images/document-support.png" />
                            </figure>
                            Supporting Document
                          </h5>
                          <span
                            className="Count CursorPointer"
                            data-toggle="modal"
                            data-target="#SupportingDocumentList"
                          >
                            3
                          </span>
                        </div>
                        <span style={{ color: "red" }}>{errors?.supportingDocFilessError}</span>
                  
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Add Notes</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer"
                            onClick={handleAddNotesShow}
                          >
                            <figure>
                              <img src="images/document-support.png" />
                            </figure>
                            Add Notes
                          </h5>
                          <span
                            className="Count CursorPointer"
                            data-toggle="modal"
                            data-target="#NotesList"
                          >
                            3
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 ml-auto">
              { manulEntryShow &&
                <div className="">
                  <h3>Vendor</h3>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Company Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Company Name"
                          name="companyName"
                          value={companyName}
                          onChange={handleInputChange}
                        />
                         <span style={{ color: "red" }}>{errors?.companyNameError}</span>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Type</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Non Vendor"
                          name="vendorType"
                          value={vendorType}
                          onChange={handleInputChange}
                        />
                         <span style={{ color: "red" }}>{errors?.vendorTypeError}</span>
                      </div>
                    </div>
                    <div className="col-sm-6">
                    <div className="form-group">
                      <label>Phone Code</label>
                      <PhoneInput
                        inputClass="CountryCodeInput"
                        countryCodeEditable={false}
                        enableSearch
                        value={phoneCode}
                        onChange={handlePhoneInputChanges}
                      />
                       <span style={{ color: "red" }}>{errors?.phoneCodeError}</span>
                    </div>
                  </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Phone Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Phone Number"
                          name="phone"
                          value={phone}
                          onChange={handleInputChange}
                        />
                         <span style={{ color: "red" }}>{errors?.catError}</span>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Warranty Contacts</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Auto-filled by System"
                        />
                        
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Email"
                          name="email"
                          value={email}
                          onChange={handleInputChange}
                        />
                         <span style={{ color: "red" }}>{errors?.emailError}</span>
                      </div>
                    </div>
                  </div>
                </div>

              }
                <div className="col-sm-12">
                  <div className="form-group">
                    <div className="CommonModalArea2">
                      <h5>Schedule Trades Day</h5>
                      <div className="CommonModalBox2 d-flex">
                        <div className="d-flex align-items-center">
                          <h5
                            className="CursorPointer mb-0"
            
                            onClick={()=>{
                              updateState((prev)=>({
                                ...prev,
                                tradeSuggestedModal:true
                              }))
                            }}
                          >
                            <figure>
                              <img src="images/icon-18.png" />
                            </figure>
                            &nbsp; Trade/Client Suggested Dates
                          </h5>
                          <span
                            className="Count CursorPointer"
                            data-toggle="modal"
                            data-target="#TradesDaySelection11"
                          >
                            3
                          </span>
                        </div>
                        <div className="TodosButtons" onClick={handletradeDaySelectionModalShow}>
  <a href="javascript:void(0);">
    <h6 data-toggle="modal" data-target="#TradesDaySelectionTable">
      <img src="images/new-warranty-request.png" /> Trades Day Selection Table{" "}
    </h6>
  </a>
</div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="Filter FullFormGroup">
                    <div className="form-group">
                      <label>Trades- Day</label>
                      <input type="date" className="form-control" 
                      name="tradesDay"
                      value={tradesDay}
                      onChange={handleInputChange}
                      />
                       <span style={{ color: "red" }}>{errors?.tradesDayError}</span>
                  
                    </div>
                    <div className="form-group">
                      <label>From</label>
                      <input type="time" className="form-control"
                      name="fromTime"
                      value={fromTime}
                      onChange={handleInputChange}
                      />
                       <span style={{ color: "red" }}>{errors?.fromTimeError}</span>
                  
                    </div>
                    <div className="form-group">
                      <label>To</label>
                      <input type="time" className="form-control" 
                      name="toTime"
                      value={toTime}
                      onChange={handleInputChange}
                      />
                        <span style={{ color: "red" }}>{errors?.toTimeError}</span>
                  
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="Filter">
                    <div className="form-group">
                      <label>Required Completion Date</label>
                      <input type="date" className="form-control" 
                      name="requiredCompletionDate"
                      value={requiredCompletionDate}
                      onChange={handleInputChange}
                      />
                       <span style={{ color: "red" }}>{errors?.requiredCompletionDateError}</span>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          <div className="Tableheader mt-5">
            <h6>Inspected Items</h6>
            <div className="EditableByClient">
              <div className="form-group mb-0">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={draftWorkOrder?.workOrder?.editByClient == true}
                    onChange={handleCheckboxChange}
                  />
                  <span className="slider" />
                </label>
                <h6>Editable by client</h6>
              </div>
            </div>
          </div>
          <div className="TableList TableListHeader ">
            <table>
              <thead>
                <tr>
                  <th>Select</th>
                  <th>#</th>
                  <th>Description</th>
                  <th>Inspector</th>
                  <th>Date Created</th>
                  <th>Date Inspected</th>
                  <th>Service Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {workOrderReqItemList?.data?.length > 0
                  ? workOrderReqItemList?.data?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <label className="CheckBox">
                              <input
                                type="checkbox"
                                checked={selectedItems.includes(item.id)}
                                onChange={() =>
                                  handleItemCheckboxChange(item.id)
                                }
                              />
                              <span className="checkmark" />
                            </label>
                          </td>
                          <td>{item.id}</td>
                          <td>{item.description}</td>
                          <td>{item.inspectorUser?.name ?? "N/A"}</td>
                          <td>
                            {moment(item?.createdAt).format("YYYY-MM-DD")}
                          </td>
                          <td>
                            {moment(item?.dateInspected).format("YYYY-MM-DD")}
                          </td>
                          <td>{item.serviceTypeOption?.name ?? "N/A"}</td>
                          <td>
                            <div className="Actions">
                              <a onClick={handleAddItemDocsModalShow}>
                                <img src="images/icon-63.png" />
                              </a>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between mt-3 pb-5">
            <button className="BrandBtn">Cancel</button>
            <div className="d-flex ">
              <button
                className="BrandBtnGreen"
                onClick={() => handleSaveAndEmail("save")}
              >
                Save
              </button>
              <div className="dropdown ml-3">
                <button
                  className="BrandBtnGreen"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Save and <img src="images/down-arrow-white.png" />
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a className="dropdown-item" >
                    Save and Print
                  </a>
                  <a
                    className="dropdown-item"
                    onClick={() => handleSaveAndEmail("email")}
                  >
                    Save and E-mail
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="ModalBox fade SmallModal"
        show={AddNotesModal}
        onHide={handleAddNotesClose}
      >
        <Modal.Body>
          <div className="Category">
            <a className="CloseModal" onClick={handleAddNotesClose}>
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Add New Note</h4>
              </div>
              <div className="JobTitleArea">
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> {state?.jobType}
                  </h6>
                </div>
               
                <h6>Type</h6>

                <div className="FolderForm">
                  <div className="form-group mb-4">
                    <label className="Radio">
                      Text
                      <input
                        type="radio"
                        value="Text"
                        checked={selectedOption === "Text"}
                        onChange={onValueChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="Radio">
                      File
                      <input
                        type="radio"
                        value="File"
                        checked={selectedOption === "File"}
                        onChange={onValueChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>

                  {textInput == true && (
                    <div className="form-group">
                      <h6>Message (Max 1000 chars limit)</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Write here ....."
                        name="message"
                        value={message}
                        onChange={handleInputChange}
                      />
                      <small className="form-text text-right">
                        {1000 - Number(message.length)} characters remaining
                      </small>
                    </div>
                  )}

                  {fileData == true && (
                    <>
                      <div className="form-group">
                        <h6>Select Files</h6>
                        <div className="Upload">
                          <span>-Drag and Drop Files or click to select</span>
                          <input
                            type="file"
                            id="fileInput"
                            onChange={(e) => handleFileChange(e, "support")}
                          />
                        </div>
                      </div>
                      {supportingDocFiles && (
                        <div className="FilesAdded">
                          <label>{supportingDocFiles?.name}</label>
                          <span>
                            <img src="images/fileview.png" alt="File view" />
                          </span>
                          <span onClick={handleRemoveClientFile}>
                            <img
                              src="images/Cross.png"
                              className="cross"
                              alt="Cross"
                            />
                          </span>
                        </div>
                      )}
                    </>
                  )}

                  <div className="Button d-flex justify-content-between mt-4">
                    <button className="Create" onClick={handleAddNotes}>
                      Update Note
                    </button>
                    <button className="Outline" onClick={handleAddNotesClose}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="ModalBox fade SmallModal"
        show={AddNewSupportingDocsModal}
        onHide={handleAddNewSupportingDocsModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a
              className="CloseModal"
              onClick={handleAddNewSupportingDocsModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Add New Supporting Docs</h4>
              </div>
              <div className="JobTitleArea">
                <h3>Supporting Documents </h3>
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> {state?.JobType}
                  </h6>
                </div>
                
                          </div>
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input
                    type="file"
                    id="fileInput"
                    onChange={(e) => handleFileChange(e, "supportDocs")}
                  />
                </div>
              </div>
              <div className="FilesAddedTitle">
                <h4>Files</h4>
              </div>
              {supportingDocFiless && (
                <div className="FilesAdded">
                  <label>{supportingDocFiless.name}</label>
                  <span>
                    <img src="images/fileview.png" />
                  </span>
                  <span onClick={handleRemoveClientFile}>
                    <img src="images/Cross.png" className="cross" />
                  </span>
                </div>
              )}

              <div className="Button mt-3">
                <button className="Create" onClick={handleSupportDocs}>
                  Add Documents
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="ModalBox fade SmallModal"
        show={VendorModal}
        onHide={handleVendorModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a className="CloseModal" onClick={handleVendorModalClose}>
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Vendor Selection</h4>
              </div>
              <div className="FolderForm">
                <div className="Categories Categories2 mb-5">
                 
                  <div className="col-md-12">
                    <div className="form-group">
                      <h6>Vendor Type</h6>
                      <div className="CommonSelectBox w-100">
                        <select
                          name="vendorType"
                          value={vendorType}
                          className="w-100"
                          onChange={(e) => handleInputChange(e)}
                        >
                          <option value="">Select Vendor Type</option>
                          {vendorTypesList?.vendorType?.length > 0 &&
                            vendorTypesList?.vendorType?.map((data, ind) => {
                              return (
                                <option key={ind} value={data.id}>
                                  {data}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* <ul style={{}}>
                      <li>-Full List</li>
                      <li>Categories loaded from the system</li>
                    </ul> */}
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <h6>Vendor Name</h6>
                    <div className="CommonSelectBox w-100">
                      <select
                        name="vendorName"
                        value={vendorName}
                        className="w-100"
                        onChange={(e) => handleInputChange(e)}
                      >
                        <option value="">Select Vendor Name</option>
                        {vendorList?.vendors?.length > 0 &&
                          vendorList?.vendors?.map((data, ind) => {
                            if(data.vendorName)
                            return (
                              <option key={ind} value={data.vendorName}>
                                {data.vendorName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="Button text-center mt-5">
                  <button className="Create mr-4" onClick={()=>handleVendorModalClose()}>Confirm Selection</button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="ModalBox fade SmallModal"
        show={AddItemDocsModal}
        onHide={handleAddItemDocsModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a className="CloseModal" onClick={handleAddItemDocsModalClose}>
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Add New Supporting Docs</h4>
              </div>
              <div className="JobTitleArea">
                <h3>Supporting Documents #2</h3>
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Origin</span> #185
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Item:</span> #143
                  </h6>
                </div>
              </div>
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input
                    type="file"
                    id="itemDocumnet"
                    onChange={(e) => handleFileChange(e, "itemDoc")}
                  />
                </div>
              </div>
              <div className="FilesAddedTitle">
                <h4>Files</h4>
              </div>
              {itemDocs && (
                <div className="FilesAdded">
                  <label>{itemDocs.name}</label>
                  <span>
                    <img src="images/fileview.png" />
                  </span>
                  <span onClick={handleRemoveClientFile}>
                    <img src="images/Cross.png" className="cross" />
                  </span>
                </div>
              )}

              <div className="Button mt-3">
                <button className="Create" onClick={handleItemDocs}>
                  Add Documents
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={tradeSuggestedModal}
        onHide={handletradeSuggestedModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handletradeSuggestedModalClose}
          >
            ×
          </a>

   <TradeSuggestedDateModal id={draftWorkOrder?.workOrder?.id}/>


        </Modal.Body>
      </Modal>
      <Modal
        show={tradeDaySelectionModal}
        onHide={handletradeDaySelectionModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handletradeDaySelectionModalClose}
          >
            ×
          </a>

   <TradeDaySelectionTable id={draftWorkOrder?.workOrder?.id}/>


        </Modal.Body>
      </Modal>
    </>
  );
};

export default Warranty_Work_Order;
