import React from "react";
import Modal from "react-bootstrap/Modal";

const FolderAction = ({ FolderActionModal, SetFolderActionModal,SetCreateFolderModal }) => {
  const handleFolderActionModalClose = () => {
    SetFolderActionModal(false);
    SetCreateFolderModal(true)
  };
  return (
    <Modal
      show={FolderActionModal}
      className="ModalBox modal fade SmallModal show"
      style={{ display: "block" }}
    >
      <a
        class="CloseModal"
        data-toggle="modal"
        onClick={handleFolderActionModalClose}
      >
        ×
      </a>

      <div class="FormArea">
                  <div class="ModalTitleBox">
                    <h4>Folder Permissions</h4>
                  </div>
                  <div class="FolderForm">
                    <form>
                      <div class="form-group">
                        <h6>Folder Name</h6>
                        <input type="text" class="form-control" placeholder="Loaded from the system" />
                      </div>
                      <div class="form-group">
                        <h6>Access Group(s)</h6>
                        <div class="Categories Categories1">
                          <div class="Categories_all Categories_all1">
                            <span>Select roles to acess folder's content</span>
                            <img src="images/down.png" />
                          </div>
                          <ul >
                            <li>
                              <label class="CheckBox">All
                                <input type="checkbox" />
                                <span class="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label class="CheckBox">Admin
                                <input type="checkbox" />
                                <span class="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              List of user groups that have access type of file
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="form-group">
                        <h6>Owner Group(s)</h6>
                        <div class="Categories Categories2">
                          <div class="Categories_all Categories_all2">
                            <span>Select roles to be owners of the folder</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>
                              <label class="CheckBox">All
                                <input type="checkbox" />
                                <span class="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              List of user groups that have access type of file
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="Button text-center">
                        <button class="Create" data-toggle="modal" data-target="#folderCreateModal2" data-dismiss="modal">Set Permissions</button>
                      </div>
                    </form>
                  </div>
                </div>
     
    </Modal>
  );
};

export default FolderAction;
