import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import moment from "moment";
import { ThemeContext } from "../ThemeContext";
import Theme from "../components/theme";
import UserProfile from "./headerModals/userProfile";
import ContactList from "./headerModals/contactList";
import Loader from "./Loader";
import AddContact from "./headerModals/addContact";
import ContactDetails from "./headerModals/contactDetails";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllProjectTradeView,
  getRolesForTradeView,
  getTradeView,
  getVendorUser,
  tradeUserResetPasswordEmailSend,
  tradeViewAddVendorUser,
  updateTradeViewVendorUser,
} from "../redux/action/tradeViewAuthAction";
import { Link, useNavigate } from "react-router-dom";
const initialState = {
  CreateUserModal: false,
  fullName: "",
  email: "",
  phoneNumber: "",
  RoleId: "",
  allProjectsAssigned: "",
  roleSearch: "",
  phonecode: "",
  viewId: "",
  ManageUsersModal: false,
  EmailModal: false,
  emails: "",
  UpdateUserModal:false,
  userId:"",
};

const TradeHeader = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const [widthState, setWidth] = useState(false);
  const [NavShow, SetNavShow] = useState(false);

  const [iState, updateState] = useState(initialState);
  const {
    CreateUserModal,
    fullName,
    email,
    phoneNumber,
    RoleId,
    allProjectsAssigned,
    roleSearch,
    phonecode,
    viewId,
    ManageUsersModal,
    EmailModal,
    emails,
    UpdateUserModal,
    userId,
  } = iState;

  const tradeViewData = JSON.parse(
    window.localStorage.getItem("tradeViewLogin")
  );
  const User = JSON.parse(window.localStorage.getItem("user"));
  const [UserProfileModal, setUserProfile] = useState(false);

  const [ContactListModal, setContactList] = useState(false);

  const [ContactDetailsModal, SetContactDetails] = useState(false);

  const [AddContactModal, SetAddContact] = useState(false);
  const { roleList, allProjectList, vendorUserList } = useSelector(
    (state) => state.tradeViewLogin
  );

  // console.log("vendorUserList", vendorUserList);

  useEffect(() => {
    dispatch(getTradeView(tradeViewData?.user?.CompanyId)).then((res) => {
      if (res.status === 200) {
        updateState({ ...iState, viewId: res?.data?.ViewSetting?.id });
      }
    });
    dispatch(
      getRolesForTradeView(
        tradeViewData?.user?.CompanyId,
        tradeViewData?.user?.VendorId
      )
    );
    dispatch(
      getAllProjectTradeView(
        tradeViewData?.user?.CompanyId,
        viewId,
        tradeViewData?.user?.VendorId
      )
    );
    dispatch(
      getVendorUser(
        tradeViewData?.user?.CompanyId,
        viewId,
        tradeViewData?.user?.VendorId
      )
    );
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const [selectedItems, setSelectedItems] = useState([]);
  const handleCreateUserModalShow = () => {
    updateState({
      ...iState,
      CreateUserModal: true,
      fullName: "",
      email: "",
      phoneNumber: "",
      RoleId: "",
      allProjectsAssigned: "",
      ManageUsersModal: false,
    });
    setSelectedItems([]);
  };

  const handleCreateUserModalClose = () => {
    updateState({ ...iState, CreateUserModal: false, ManageUsersModal: true });
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (event, item) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add the selected item to the array
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);
    } else {
      // Remove the item from the array
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((selectedItem) => selectedItem !== item)
      );
    }
  };

  const handleItemClick = (id) => {
    updateState({ ...iState, RoleId: id });
  };

  const handleRoleItemClick = (id) => {
    updateState({ ...iState, modelRole: id });
  };
  // console.log("selectedItems", selectedItems);

  const handleCreateUsers = () => {
    const dataa = {
      fullName,
      email,
      phoneNumber,
      RoleId: [Number(RoleId)],
      // viewId,
      // allProjectsAssigned: false,
      // assignedProjects: [...new Set(selectedItems?.map((data) => data.id))],
      phonecode,
      position: "Owner",
    };
    dispatch(
      tradeViewAddVendorUser(
        tradeViewData?.user?.CompanyId,
        viewId,
        tradeViewData?.user?.VendorId,
        dataa
      )
    ).then((res) => {
      if (res.status === 201) {
        dispatch(
          getVendorUser(
            tradeViewData?.user?.CompanyId,
            viewId,
            tradeViewData?.user?.VendorId
          )
        );
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          CreateUserModal: false,
          fullName: "",
          email: "",
          phoneNumber: "",
          RoleId: "",
          allProjectsAssigned: "",
          ManageUsersModal: true,
        });
        setSelectedItems([]);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          CreateUserModal: true,
        });
      }
    });
  };

  const handlePhoneInputChange = (value, country) => {
    updateState({
      ...iState,
      phonecode: `+${country.dialCode}`,
    });
  };

  const handleManageUsersModalShow = () => {
    updateState({ ...iState, ManageUsersModal: true });
  };

  useEffect(()=>{
    if(ManageUsersModal)
    dispatch(
      getVendorUser(
        tradeViewData?.user?.CompanyId,
        viewId,
        tradeViewData?.user?.VendorId
      )
    );
  },[ManageUsersModal])

  const handleManageUsersModalClose = () => {
    updateState({ ...iState, ManageUsersModal: false });
  };

  const handleEmailModalShow = (email) => {
    updateState({
      ...iState,
      EmailModal: true,
      ManageUsersModal: false,
      email: emails,
    });
  };
  const handleEmailModalClose = () => {
    updateState({ ...iState, EmailModal: false, ManageUsersModal: true });
  };

  const handleUserResetPasswordEmailSend = (e) => {
    e.preventDefault();
    if (!emails) {
      toast.error("Please enter email", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const dataa = { email: emails };
      dispatch(tradeUserResetPasswordEmailSend(dataa)).then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            EmailModal: false,
            ManageUsersModal: true,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            EmailModal: true,
          });
        }
      });
    }
  };

  const handleUpdateUserModalShow = (item) => {
    updateState({ ...iState, 
      UpdateUserModal: true,
    fullName:item.name,
    email:item.email,
    phoneNumber:item.phoneNumber,
    ManageUsersModal:false,
    userId:item.id
    });
  };

  const handleUpdateUsers = () => {
    const dataa = {
      fullName,
      email,
      phoneNumber,
      RoleId: [Number(RoleId)],
      phonecode,
      position: "Owner",
    };
    dispatch(
      updateTradeViewVendorUser(
        tradeViewData?.user?.CompanyId,
        viewId,
        tradeViewData?.user?.VendorId,
        userId,
        dataa
      )
    ).then((res) => {
      if (res.status === 200) {
        dispatch(
          getVendorUser(
            tradeViewData?.user?.CompanyId,
            viewId,
            tradeViewData?.user?.VendorId
          )
        );
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          UpdateUserModal: false,
          fullName: "",
          email: "",
          phoneNumber: "",
          RoleId: "",
          ManageUsersModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          UpdateUserModal: true,
        });
      }
    });
  };

  const handleUpdateUserModalClose = () => {
    updateState({ ...iState, UpdateUserModal: false , ManageUsersModal: true,});
  };

  const handleLogout=()=>{
    window.localStorage.clear();
    navigate("/")
  }

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([
    { path: "/design-selection-tradeview", element: "Design Selections" },
    { path: "/file-manager-trade-view", element: "File Manager" },
    { path: "/appointements-trade-view", element: "Appointments" },
    { path: "/change-order-checklist-tradeview", element: "Change Order Checklist" },
    { path: "/warranty-work-order-tradeview", element: "Warranty Work Orders" },
    { path: "/po-qr-tradeview", element: "Pq Qr" },
    { path: "/construction-schedul-tradeview", element: "Construction Schedule Tasks" },
    { path: "/todo-tradeview", element: "Todo" },
  ]);

  const [isSearchFocused, setIsSearchFocused] = useState(false);

 
  const handleSearchInputChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    if (query === "") {
      setSearchResults([
        { path: "/design-selection-tradeview", element: "Design Selections" },
        { path: "/file-manager-trade-view", element: "File Manager" },
        { path: "/appointements-trade-view", element: "Appointments" },
        { path: "/change-order-checklist-tradeview", element: "Change Order Checklist" },
        { path: "/warranty-work-order-tradeview", element: "Warranty Work Orders" },
        { path: "/po-qr-tradeview", element: "Pq Qr" },
        { path: "/construction-schedul-tradeview", element: "Construction Schedule Tasks" },
        { path: "/todo-tradeview", element: "Todo" },
      ]);
    } else {
      const filteredResults = searchResults.filter(result =>
        result.element.toLowerCase().includes(query)
      );
      setSearchResults(filteredResults);
      
    }
  };
  
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.SearchPosition')) {
        setIsSearchFocused(false);
      }
    };

    if (isSearchFocused) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isSearchFocused]);

  
    const handleClick = (e) => {
      const cssLink = document.getElementById("thememode");
       console.log({cssLink});
      if (!cssLink) {
        console.error("The element with id 'thememode' was not found.");
        return;
      }
  
      if (e.target.checked) {
        console.log("Switching to dark mode");
        cssLink.href = "css/darkmode.css";
      } else {
        console.log("Switching to light mode");
        cssLink.href = "css/lightmode.css";
      }
    };
  
  return (
    <div>
      <header>
        <div className="Logo">
          <img src="images/Logo.png" />
        </div>
        <div className="Navigation">
        <div className="Search SearchPosition">
        <span>
          <img src="images/search.png" />
        </span>
        <input
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={handleSearchInputChange}
          onFocus={() => setIsSearchFocused(true)}
        />
        <div className='SearchOverlay' >
        {
        isSearchFocused&& <ul>
        {searchResults.map((result, index) => (
          <li key={index} >
            <Link to={result.path}>{result.element}</Link>
          </li>
        ))}
      </ul>
       }
        </div>
      </div>
          <div className="NavigationMenu">
            <ul>
              <div
                id="myTask"
                className="sidenav"
                style={{ width: NavShow ? "300px" : "0px" }}
              >
                <a
                  href="javascript:void(0)"
                  className="closebtn"
                  onClick={() => SetNavShow(false)}
                >
                  ×
                </a>
                <div className="SideNavTopBox">
                  <h1>My Task</h1>
                </div>
                <div className="SideNavBottom">
                  <div className="SideTaskBoxTitle">
                    <h6>Today</h6>
                  </div>
                  <div className="SideTaskBox">
                    <h6>Task 1</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                    <div className="TaskButtonArea">
                      <button className="View">View</button>
                      <button className="Start">Start</button>
                    </div>
                  </div>
                  <div className="SideTaskBox">
                    <h6>Task 2</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                    <div className="TaskButtonArea">
                      <button className="View">View</button>
                      <button className="Start">Start</button>
                    </div>
                  </div>
                  <div className="SideTaskBox">
                    <h6>Task 3</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                    <div className="TaskButtonArea">
                      <button className="View">View</button>
                      <button className="Completed">Completed</button>
                    </div>
                  </div>
                  <div className="SideTaskBox">
                    <h6>Task 4</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                    <div className="TaskButtonArea">
                      <button className="View">View</button>
                      <button className="Ongoing">Ongoing</button>
                    </div>
                  </div>
                </div>
              </div>
              <li onclick="openNotification()">
                <a>
                  <img src="images/Icon-2.png" title="notification" />
                </a>
              </li>
              <div id="myNotification" className="sidenav">
                <a
                  href="javascript:void(0)"
                  className="closebtn"
                  onclick="closeNotification()"
                >
                  ×
                </a>
                <div className="SideNavTopBox">
                  <h1>Notification</h1>
                </div>
                <div className="SideNavBottom">
                  <div className="SideTaskBoxTitle">
                    <h6>Today</h6>
                  </div>
                  <div className="SideNotificationBox">
                    <h6>Lorem Ipsum</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                  </div>
                  <div className="text-right">
                    <button className="DeleteBtn">Delete</button>
                  </div>
                  <div className="SideNotificationBox">
                    <h6>Lorem Ipsum</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                  </div>
                  <div className="text-right">
                    <button className="DeleteBtn">Delete</button>
                  </div>
                  <div className="SideNotificationBox">
                    <h6>Lorem Ipsum</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                  </div>
                  <div className="text-right">
                    <button className="DeleteBtn">Delete</button>
                  </div>
                  <div className="SideTaskBoxTitle">
                    <h6>Yesterday</h6>
                  </div>
                  <div className="SideNotificationBox">
                    <h6>Lorem Ipsum</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                  </div>
                  <div className="text-right">
                    <button className="DeleteBtn">Delete</button>
                  </div>
                  <div className="SideNotificationBox">
                    <h6>Lorem Ipsum</h6>
                    <p>
                      There are many variations of passages Lorem Ipsum
                      available
                    </p>
                  </div>
                  <div className="text-right">
                    <button className="DeleteBtn">Delete</button>
                  </div>
                </div>
              </div>
              <li onclick="openHelp()">
                <a href="javascript:void(0);">
                  <img src="images/Icon-3.png" title="help" />
                </a>
              </li>
              <div id="myHelp" className="sidenav">
                <a
                  href="javascript:void(0)"
                  className="closebtn"
                  onclick="closeHelp()"
                >
                  ×
                </a>
                <div className="SideNavTopBox flex-column">
                  <h1>Help</h1>
                  <a
                    href="javascript:void(0);"
                    data-toggle="modal"
                    data-target="#ViewSupportRequestCommon"
                    className="SupportRequests"
                  >
                    Support Requests <span className="Count">3</span>
                  </a>
                  <a
                    href="javascript:void(0);"
                    data-toggle="modal"
                    data-target="#CreateSupportRequestCommonView"
                    className="CreateNewSupportRequest"
                  >
                    <img src="images/icon-90.png" />
                    &nbsp;&nbsp;Create New Support Request
                  </a>
                </div>
                <div className="SideNavBottom">
                  <div className="SideNavSettingBox">
                    <h6>Contrary to popular</h6>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less normal distribution of letters, as
                      opposed to using 'Content here, content here', making it
                      look like readable English.{" "}
                    </p>
                  </div>
                  <div className="SideNavSettingBox">
                    <h6>Contrary to popular</h6>
                    <p>
                      Many desktop publishing packages and web page editors now
                      use Lorem Ipsum as their default model text, and a search
                      for 'lorem ipsum' will uncover many web sites still in
                      their infancy. Various versions have evolved over the
                      years, sometimes by accident, sometimes on purpose
                      (injected humour and the like).
                    </p>
                  </div>
                  <div className="SideNavSettingBox">
                    <h6>Standard chunk</h6>
                    <ol>
                      <li>
                        There are many variations of passages of Lorem Ipsum
                        available, but the majority have suffered alteration in
                        some form, by injected humour, or randomised words which
                        don't look even slightly believable.{" "}
                      </li>
                      <li>
                        If you are going to use a passage of Lorem Ipsum, you
                        need to be sure there isn't anything embarrassing hidden
                        in the middle of text.
                      </li>
                      <li>
                        All the Lorem Ipsum generators on the Internet tend to
                        repeat predefined
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <li onClick={() => setWidth(!widthState)}>
                <a href="javascript:void(0);">
                  <img src="images/Icon-4.png" title="setting" />
                </a>
              </li>
              <div
                id="mySettings"
                className="sidenav"
                style={{ width: widthState ? 300 : 0 }}
              >
                <a className="closebtn" onClick={() => setWidth(false)}>
                  ×
                </a>
                <div className="SideNavTopBox">
                  <h1>Settings</h1>
                </div>
                <div className="SideNavBottom">
                  <div className="SettingThemeArea">
                    <div className="form-group">
                      <label className="Dark-mode d-block">Password</label>
                      <label href="#" className="text-red">
                        Change Password
                      </label>
                    </div>
                    <div className="SideTaskBoxTitle">
                      <h6>Theme</h6>
                    </div>
                    <div className="SideNavHelpBox">
                    <ul>
                   <ThemeContext.Consumer>
                     {({ changeTheme }) => (
                       <Theme
                         chooseTheme={() => {
                           changeTheme("yellow");
                         }}
                       />
                     )}
                   </ThemeContext.Consumer>
                   <ThemeContext.Consumer>
                     {({ changeTheme }) => (
                       <Theme
                         chooseTheme={() => {
                           changeTheme("cyan");
                         }}
                       />
                     )}
                   </ThemeContext.Consumer>
                   <ThemeContext.Consumer>
                     {({ changeTheme }) => (
                       <Theme
                         chooseTheme={() => {
                           changeTheme("lightgreen");
                         }}
                       />
                     )}
                   </ThemeContext.Consumer>
                   <ThemeContext.Consumer>
                     {({ changeTheme }) => (
                       <Theme
                         chooseTheme={() => {
                           changeTheme("pink");
                         }}
                       />
                     )}
                   </ThemeContext.Consumer>

                   <ThemeContext.Consumer>
                     {({ changeTheme }) => (
                       <Theme
                         chooseTheme={() => {
                           changeTheme("brown");
                         }}
                       />
                     )}
                   </ThemeContext.Consumer>
                 </ul>
                    </div>
                    <div className="d-flex justify-content-between mt-4">
                      <div className="form-group">
                        <label className="Radio">
                          English
                          <input type="radio" name="Cuisine" />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="Radio">
                          French
                          <input type="radio" name="Cuisine" />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                    <div>
      <div className="form-group mt-3 d-flex align-items-center justify-content-between">
        <label className="Dark-mode mb-0">Dark Mode</label>
        <label className="switch">
          <input type="checkbox" onChange={handleClick} />
          <span className="slider" />
        </label>
      </div>
      <link id="thememode" rel="stylesheet" href="css/lightmode.css" />
    </div>
                    <div className="form-group">
                      <label className="Dark-mode d-block">Password</label>
                      <label href="#" className="text-red">
                        Change Password
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="Dark-mode d-block">
                        Contact preferences
                      </label>
                      <label href="#" className="text-red">
                        Update contact preferences
                      </label>
                    </div>
                    <div className="form-group">
                      <h5>User Management</h5>
                      <a onClick={() => handleManageUsersModalShow()}>
                        User Management
                      </a>
                    </div>
                  </div>
                  <div className="SettingsEditArea">
                    <div className="SettingsEdit">
                      <h6>Theme</h6>
                      <div className="settingsEditBox">
                        <h6>Add</h6>
                        <p>Remove</p>
                      </div>
                    </div>
                    <div className="SettingsEdit">
                      <h6>Language</h6>
                      <div className="settingsEditBox">
                        <h6>Add</h6>
                        <p>Remove</p>
                      </div>
                    </div>
                    <div className="SettingsEdit">
                      <h6>Dark Mode</h6>
                      <div className="settingsEditBox">
                        <h6>Add</h6>
                        <p>Remove</p>
                      </div>
                    </div>
                    <div className="SettingsEdit">
                      <h6>Password</h6>
                      <div className="settingsEditBox">
                        <h6>Add</h6>
                        <p>Remove</p>
                      </div>
                    </div>
                    <div className="text-center">
                      <button className="Save SaveBtn">Save</button>
                    </div>
                  </div>
                </div>
              </div>
             
              <div id="mySidenav" className="sidenav">
                <a
                  href="javascript:void(0)"
                  className="closebtn"
                  onclick="closeNav()"
                >
                  ×
                </a>
                <div className="sidenavTop">
                  <span>MB</span>
                  <h6>Man Mohan</h6>
                  <h6>IT Consultant</h6>
                </div>
                <div className="d-flex flex-column justify-content-between">
                  <ul className="">
                    <li>
                      <a href="">
                        <img src="images/profile.png" />
                        Profile
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <img src="images/contacts.png" />
                        Contacts
                      </a>
                    </li>
                    <li>
                      <a href="">
                        {" "}
                        <img src="images/my_task.png" />
                        My Tasks
                      </a>
                    </li>
                  </ul>
                  <ul className="bottomUl">
                    <li>
                      <a onClick={handleLogout}>
                        <img src="images/signout.png" />
                        Sign Out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <li onclick="openNav()">
                <a
                  class="dropdown-toggle"
                  id="navbarDropdown"
                  onClick={() => SetNavShow(true)}
                  role="button"
                >
                  <span>
                    {User?.profile ? (
                      <img src={User?.profile} />
                    ) : (
                      tradeViewData?.user?.name.charAt(0) +
                      tradeViewData?.user?.name.charAt(1)
                    )}{" "}
                  </span>
                </a>
              </li>
              <div
                id="mySidenav"
                class="sidenav"
                style={{ width: NavShow ? "300px" : "0px" }}
              >
                <a onClick={() => SetNavShow(false)} className="closebtn">
                  ×
                </a>
                <div class="sidenavTop">
                  <span>
                    {User?.profile ? (
                      <img src={User?.profile} />
                    ) : (
                      tradeViewData?.user?.name.charAt(0) +
                      tradeViewData?.user?.name.charAt(1)
                    )}{" "}
                  </span>
                  <h6>
                    {tradeViewData?.user?.fullName}{" "}
                    {tradeViewData?.user?.lastName}
                  </h6>
                  <h6>{tradeViewData?.user?.name}</h6>
                </div>
                <div class="d-flex flex-column justify-content-between">
                  <ul class="">
                    <li>
                      <a onClick={() => setUserProfile(true)}>
                        <img src="images/profile.png" />
                        Profile
                      </a>
                    </li>
                    <li>
                      <a onClick={() => setContactList(true)}>
                        <img src="images/contacts.png" />
                        Contacts
                      </a>
                    </li>
                  </ul>
                  <ul class="bottomUl">
                    <li>
                      <a href="">
                        <img src="images/signout.png" />
                        Sign Out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </header>
      <Modal
        show={CreateUserModal}
        onHide={handleCreateUserModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleCreateUserModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create New User</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>
                  Name<span className="required">*</span>
                </h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert name"
                  name="fullName"
                  value={fullName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <h6>
                  Email<span className="required">*</span>
                </h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert Email"
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <h6>Country Code</h6>
                <PhoneInput
                  inputClass="CountryCodeInput"
                  countryCodeEditable={false}
                  enableSearch
                  value={phonecode}
                  onChange={handlePhoneInputChange}
                />
              </div>

              <div className="form-group">
                <h6>Phone Number</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert Phone Number"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <h6>
                  Role<span className="required">*</span>
                </h6>
                <div className="Categories Categories1">
                  <div className="Categories_all Categories_all1">
                    <span>Select user role</span>
                    <img src="images/down.png" />
                  </div>
                  <ul>
                    <li>
                      <input
                        type="text"
                        placeholder="Search"
                        name="roleSearch"
                        value={roleSearch}
                        onChange={handleInputChange}
                      />
                      <span className="Search">
                        <img src="images/search.png" />
                      </span>
                    </li>

                    {roleList?.data?.length > 0 &&
                      roleList?.data
                        ?.filter((data) =>
                          roleSearch.toLowerCase() === ""
                            ? true
                            : data.roleName
                                .toLowerCase()
                                .includes(roleSearch.toLowerCase())
                        )
                        .map((item, i) => {
                          return (
                            <li
                              // value={item.id}
                              key={i}
                              onClick={() => handleItemClick(item.id)}
                              style={{
                                backgroundColor:
                                  RoleId === item.id
                                    ? "#e71e09"
                                    : "transparent",
                              }}
                            >
                              {item.roleName}
                            </li>
                          );
                        })}
                  </ul>
                </div>
              </div>
              {/* <div className="form-group">
                <h6>
                  Assign Projects<span className="required">*</span>
                </h6>
                <div className="Categories Categories2">
                  <div className="Categories_all Categories_all2">
                    <span>Select projects</span>
                    <img src="images/down.png" />
                  </div>
                  <ul>
                    {allProjectList?.projects?.length > 0 &&
                        allProjectList?.projects?.map((item, ind) => {
                          return (
                            <li key={ind}>
                              <label className="CheckBox">
                                {item.projectName}
                                <input
                                  type="checkbox"
                                  checked={selectedItems.includes(item)}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, item)
                                  }
                                />
                                <span className="checkmark" />
                              </label>
                            </li>
                          );
                        })}
                  </ul>
                </div>
              </div> */}
              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={handleCreateUsers}>
                  Create User
                </button>
                <button
                  className="Outline"
                  onClick={handleCreateUserModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={UpdateUserModal}
        onHide={handleUpdateUserModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleUpdateUserModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Edit User</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>
                  Name<span className="required">*</span>
                </h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert name"
                  name="fullName"
                  value={fullName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <h6>
                  Email<span className="required">*</span>
                </h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert Email"
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <h6>Country Code</h6>
                <PhoneInput
                  inputClass="CountryCodeInput"
                  countryCodeEditable={false}
                  enableSearch
                  value={phonecode}
                  onChange={handlePhoneInputChange}
                />
              </div>

              <div className="form-group">
                <h6>Phone Number</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert Phone Number"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={handleInputChange}
                />
              </div>
            
                  <div class="form-group">
                    <label>Select Role</label>
                    <select
                      className="form-control"
                      name="RoleId"
                      value={RoleId}
                      onChange={handleInputChange}
                    >
                      <option value="">Select </option>
                      {roleList?.data?.length > 0 &&
                        roleList?.data?.map((item, i) => {
                          return (
                            <option value={item.id} key={i}>
                              {item.roleName}
                            </option>
                          );
                        })}
                    </select>
                  </div>
            
           
              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={handleUpdateUsers}>
                  Update User
                </button>
                <button
                  className="Outline"
                  onClick={handleUpdateUserModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ManageUsersModal}
        onHide={handleManageUsersModalClose}
        className="ModalBox modal fade LargeModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleManageUsersModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Manage Users</h4>
            </div>
            <div className="ModalMediumheading">
              <h3>
                <span>Vendor:</span>{tradeViewData?.user?.name}
              </h3>
            </div>
            <div className="ModalMediumheading">
              <h3>Registered Users</h3>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Role</th>
                    <th>Position</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {vendorUserList?.userList?.length > 0
                    ? vendorUserList?.userList?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{`${item.phonecode ?? ""}${
                              item.phoneNumber
                            }`}</td>
                            <td>{item.position ?? "N/A"}</td>
                            <td>N/A</td>
                            <td>
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu dropdown-menuwidth">
                                      <li>
                                        <a onClick={()=>handleUpdateUserModalShow(item)}>
                                          <img src="images/Action-1.png" />
                                          Edit User Details
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleEmailModalShow(item.email)
                                          }
                                          className="FolderPermissionId"
                                        >
                                          <img src="images/reset_password.png" />
                                          Reset Password
                                        </a>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : "No data found"}
                </tbody>
              </table>
            </div>
            <div className="Button mt-5">
              <button
                className="Create FolderPermissionId"
                onClick={handleCreateUserModalShow}
              >
                Add New User
              </button>
            </div>
            <div className="AssignTable mt-4">
              <h4>Role's Permissions</h4>
              <article>
                <table>
                  <thead>
                    <tr>
                      <th />
                      <th colSpan={10}>Function</th>
                    </tr>
                    <tr>
                      <th>Role</th>
                      <th>Create</th>
                      <th>Design Selections</th>
                      <th>File Manager</th>
                      <th>Appointments</th>
                      <th>Change Order Check-List</th>
                      <th>Warranty Work Order</th>
                      <th>PR/QR</th>
                      <th>Construction Schedule</th>
                      <th>TODO</th>
                      <th>All</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Admin</td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Construction</td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Design</td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Sales</td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Warranty</td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </article>
            </div>
            <div className="AssignTable mt-5">
              <h4>Preferred Contact List</h4>
              <article>
                <table>
                  <thead>
                    <tr>
                      <th />
                      <th colSpan={4}>Builders</th>
                    </tr>
                    <tr>
                      <th>Role</th>
                      <th>Builders 1</th>
                      <th>Builders 2</th>
                      <th>All</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Admin</td>
                      <td>
                        <div className="SelectAnOption">
                          <div className="dropdown">
                            <button
                              className=""
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Select an option
                              <img src="images/black-down-arrow.png" />
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -List of employees for the specific role
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="SelectAnOption">
                          <div className="dropdown">
                            <button
                              className=""
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Select an option
                              <img src="images/black-down-arrow.png" />
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -List of employees for the specific role
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="SelectAnOption">
                          <div className="dropdown">
                            <button
                              className=""
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Select an option
                              <img src="images/black-down-arrow.png" />
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                -List of employees for the specific role
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="TableThreeDots">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth">
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/Action-1.png" />
                                    Edit preferred contacs
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/dropdown-delete.png" />
                                    Clear line
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Construction</td>
                      <td />
                      <td />
                      <td />
                      <td>
                        <div className="TableThreeDots">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth">
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/Action-1.png" />
                                    Edit preferred contacs
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/dropdown-delete.png" />
                                    Clear line
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Design</td>
                      <td />
                      <td />
                      <td />
                      <td>
                        <div className="TableThreeDots">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth">
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/Action-1.png" />
                                    Edit preferred contacs
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/dropdown-delete.png" />
                                    Clear line
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Sales</td>
                      <td />
                      <td />
                      <td />
                      <td>
                        <div className="TableThreeDots">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth">
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/Action-1.png" />
                                    Edit preferred contacs
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/dropdown-delete.png" />
                                    Clear line
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Warranty</td>
                      <td />
                      <td />
                      <td />
                      <td>
                        <div className="TableThreeDots">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth">
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/Action-1.png" />
                                    Edit preferred contacs
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/dropdown-delete.png" />
                                    Clear line
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </article>
            </div>
            <div className="Button mt-5 d-flex  justify-content-center ">
              <button className="Create mr-5">Save</button>
              <button className="Outline ml-5">Cancel</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={EmailModal}
        onHide={handleEmailModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleEmailModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="LoginBox Modal">
              <a href="javascript:void(0);" className="nav-link">
                IBuild Admin
              </a>
              <div className="LoginContentBox">
                <h3>Recover your account</h3>
                <p className="mb-4">
                  Enter email associated with your account and follow the
                  instructions
                </p>
                <form>
                  <div className="form-group">
                    <label>Enter Email</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter text"
                      // name="emails"
                      value={emails}
                      disabled
                      // onChange={handleInputChange}
                    />
                  </div>

                  <a
                    className="Button FolderPermissionId"
                    onClick={handleUserResetPasswordEmailSend}
                  >
                    Send Reset Email
                  </a>
                  <a
                    className="ButtonOutline mt-3"
                    onClick={handleEmailModalClose}
                  >
                    Cancel
                  </a>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ContactList
        ContactListModal={ContactListModal}
        setContactList={setContactList}
        SetAddContact={SetAddContact}
        SetContactDetails={SetContactDetails}
      />

      {AddContactModal && (
        <AddContact
          AddContactModal={AddContactModal}
          SetAddContact={SetAddContact}
        />
      )}

      <ContactDetails
        ContactDetailsModal={ContactDetailsModal}
        SetContactDetails={SetContactDetails}
      />
    </div>

    
  );
};

export default TradeHeader;
