import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import {toast} from "react-toastify"
import { useDispatch } from "react-redux";



const SelectAccountModal = ({ selectAccountModal, setSelectAccountModal,setAssignResouceModal}) => {

  const dispatch = useDispatch()
  const handleSelectAccountModalClose = () => {setSelectAccountModal(false)
    setAssignResouceModal(true)
  }

 





  return (
    <>
      <Modal
        show={selectAccountModal}
        onHide={handleSelectAccountModalClose}
        className="ModalBox ExtraSmallModal"
      >
        <a
          onClick={handleSelectAccountModalClose}
          className="CloseModal"
          data-dismiss="modal"
        >
          ×
        </a>

        <div class="FormArea">
        <div class="ModalTitleBox">
          <h4>Select Account</h4>
        </div>
        <div class="FolderForm">
          <form>
            <div class="form-group">
              <h6>Account Information</h6>
              <div class="Categories Categories1">
                <div class="Categories_all Categories_all1">
                  <span>Select Account</span>
                  <img src="images/down.png" />
                </div>
                <ul>
                  <li>- List of Accounts loaded from the system</li>
                </ul>
              </div>
            </div>
            <div class="Button text-center">
              <button class="Create FolderPermissionId" data-dismiss="modal" data-toggle="modal" data-target="#AssignResourcesHover">Assign</button>
            </div>
          </form>
        </div>
      </div>

   
      </Modal>


    </>
  );
};

export default SelectAccountModal;
