import React, { useEffect, useState } from 'react'
import { UserDropdownAction } from '../../redux/action/dropdownAction'
import { useDispatch, useSelector } from 'react-redux'
import { createAuthorityAction, getAuthorityAction } from '../../redux/action/adminSigningAuthorityAction'
import {toast} from 'react-toastify'

const AdminSigningAuthority = () => {


    const [signDocument, setSignDocument] = useState([ {
      "userId": "",
      "userName":"Sale_Contract",
      "documentType": "sale"
  }])



    const dispatch = useDispatch()
    const {userList} = useSelector((state)=> state.dropdownReducer)


    const [editSigning, setEditSigning] = useState(false)





    const handleSubmit = (e) => {
        e.preventDefault();
        // updateState({ ...iState, stopper: true });
        let formIsValid = true;


        const  signDocument = [
          {
              "userId": 1,
              "userName":"us",
              "documentType": "sale"
          },
           {
              "userId": 1,
                          "userName":"us",
              "documentType": "sale"
          }
      ]
    
        if (formIsValid) {
          dispatch(
            createAuthorityAction({signDocument})
          ).then((res) => {
              if (res.status === 202) {
                toast.success("Users Assigned Successfully", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                });
    
                setEditSigning(false);
    
                // dispatch(getNestedNotificationAction(element?.id)).then((res) => {
                //   setC(res.data);
                // });
              } else {
                toast.error(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            })
            .catch((error) => {
              toast.error(error.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
        }
      };

    useEffect(()=>{
        dispatch(UserDropdownAction())

    },[editSigning])


    useEffect(()=>{
        dispatch(getAuthorityAction())

    },[])






  return (

    <div className="tab-pane fade" id="SingingAuthority">
    <div className="ResidentialJobArea ViewConfigArea">
      <div className="ModalAccordian">
        <div id="accordion">
          <div className="card">
            <div className="card-header">
              <h4
                data-toggle="collapse"
                href="#SigningAuthorityUsersSetupTabs"
              >
                <span>
                  <img src="images/side_menu.png" />
                </span>{" "}
                Signing Authority Users Setup
              </h4>
              <a className="Edit EditViewConfigBodyBtn" onClick={()=> setEditSigning( editSigning? false:true)}>
                <img src="images/Action-1.png" />
              </a>
            </div>
            <div
              id="SigningAuthorityUsersSetupTabs"
              className="collapse show"
              data-parent="#accordion"
            >
              <div className="card-body">
                <div className="ViewConfigBodyMain" style={{display : editSigning?"none":"block"}}>
                  <div className="ViewConfigBody flex-column">
                    <div className="AuthorityBox">
                      <h3>Sales</h3>
                      <h6>Document Contract</h6>
                      <h6>Authorized Users:</h6>
                      <p>Jack Manager</p>
                      <p>John Doe</p>
                    </div>
                    <div className="AuthorityBox">
                      <h6>Document: Sales Letter</h6>
                      <h6>Authorized Users:</h6>
                      <p>Jack Manager</p>
                      <p>John Doe</p>
                    </div>
                    <div className="AuthorityBox">
                      <h3>Construction</h3>
                      <h6>Document: Construction Letter</h6>
                      <h6>Authorized Users:</h6>
                      <p>Jill Manager</p>
                      <p>Jack Manager</p>
                    </div>
                    <div className="AuthorityBox">
                      <h3>Warranty</h3>
                      <h6>Document: Warranty Letter</h6>
                      <h6>Authorized Users:</h6>
                      <p>Josie Manager</p>
                      <p>Jane Williams</p>
                    </div>
                    <div className="AuthorityBox">
                      <h3>Change Order/CTC</h3>
                      <h6>Document: Change Order</h6>
                      <h6>Authorized Users:</h6>
                      <p>Josie Manager</p>
                      <p>Jane Doe</p>
                    </div>
                    <div className="AuthorityBox">
                      <h6>Document: CTC Document</h6>
                      <h6>Authorized Users:</h6>
                      <p>Jack Manager</p>
                      <p>John Doe</p>
                      <p>Josie Manager</p>
                    </div>
                  </div>
                </div>
                <div className="ViewConfigBodyHideMain"  style={{display : editSigning?"block":"none"}}>
                  <div className="ViewConfigBodyHide flex-column">
                    <div className="row">
                      <div className="col-md-10">
                        <div className="AuthoritySetupBox">
                          <h3>Sales</h3>
                          <div className="AuthoritySetupBoxMain">
                            <div className="AuthoritySetupBoxLeft">
                              <h6>Document: Contract</h6>
                              <div className="CommonSelectBox">
                                <select>
                                  <option selected="">
                                    Select User
                                  </option>
                                  {userList?.map((user)=>
                            <option value={user?.id}>
                            {user?.name}
                          </option>
                        )}

                                </select>
                                <span>
                                  <img src="images/down.png" />
                                </span>
                              </div>
                              <button className="SmallRedBtn">
                                Add Signing Authority
                              </button>
                            </div>
                            <div className="AuthoritySetupBoxRight">
                              <h6>Authorized Users:</h6>
                              <div className="AuthorizeName">
                                <p>Jack Manager</p>
                                <img src="images/dropdown-delete.png" />
                              </div>
                              <div className="AuthorizeName">
                                <p>Jack Doe</p>
                                <img src="images/dropdown-delete.png" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="AuthoritySetupBox">
                          <div className="AuthoritySetupBoxMain">
                            <div className="AuthoritySetupBoxLeft">
                              <h6>Document: Sales Letter</h6>
                              <div className="CommonSelectBox">
                                <select>
                                  <option selected="">
                                    Select User
                                  </option>
                                  {userList?.map((user)=>
                            <option value={user?.id}>
                            {user?.name}
                          </option>
                        )}

                                </select>
                                <span>
                                  <img src="images/down.png" />
                                </span>
                              </div>
                              <button className="SmallRedBtn">
                                Add Signing Authority
                              </button>
                            </div>
                            <div className="AuthoritySetupBoxRight">
                              <h6>Authorized Users:</h6>
                              <div className="AuthorizeName">
                                <p>Jack Manager</p>
                                <img src="images/dropdown-delete.png" />
                              </div>
                              <div className="AuthorizeName">
                                <p>Jack Doe</p>
                                <img src="images/dropdown-delete.png" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="AuthoritySetupBox">
                          <h3>Construction</h3>
                          <div className="AuthoritySetupBoxMain">
                            <div className="AuthoritySetupBoxLeft">
                              <h6>
                                Document: Construction Letter
                              </h6>
                              <div className="CommonSelectBox">
                                <select>
                                  <option selected="">
                                    Select User
                                  </option>
                                  {userList?.map((user)=>
                            <option value={user?.id}>
                            {user?.name}
                          </option>
                        )}

                                </select>
                                <span>
                                  <img src="images/down.png" />
                                </span>
                              </div>
                              <button className="SmallRedBtn">
                                Add Signing Authority
                              </button>
                            </div>
                            <div className="AuthoritySetupBoxRight">
                              <h6>Authorized Users:</h6>
                              <div className="AuthorizeName">
                                <p>Jill Manager</p>
                                <img src="images/dropdown-delete.png" />
                              </div>
                              <div className="AuthorizeName">
                                <p>Jack Manager</p>
                                <img src="images/dropdown-delete.png" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="AuthoritySetupBox">
                          <h3>Warranty</h3>
                          <div className="AuthoritySetupBoxMain">
                            <div className="AuthoritySetupBoxLeft">
                              <h6>Document: Warranty Letter</h6>
                              <div className="CommonSelectBox">
                                <select>
                                  <option selected="">
                                    Select User
                                  </option>
                                  {userList?.map((user)=>
                            <option value={user?.id}>
                            {user?.name}
                          </option>
                        )}

                                </select>
                                <span>
                                  <img src="images/down.png" />
                                </span>
                              </div>
                              <button className="SmallRedBtn">
                                Add Signing Authority
                              </button>
                            </div>
                            <div className="AuthoritySetupBoxRight">
                              <h6>Authorized Users:</h6>
                              <div className="AuthorizeName">
                                <p>Josie Manager</p>
                                <img src="images/dropdown-delete.png" />
                              </div>
                              <div className="AuthorizeName">
                                <p>Jane Williams</p>
                                <img src="images/dropdown-delete.png" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="AuthoritySetupBox">
                          <h3>Change Order/CTC</h3>
                          <div className="AuthoritySetupBoxMain">
                            <div className="AuthoritySetupBoxLeft">
                              <h6>Document: Change Order</h6>
                              <div className="CommonSelectBox">
                                <select>
                                  <option selected="">
                                    Select User
                                  </option>
                                </select>
                                <span>
                                  <img src="images/down.png" />
                                </span>
                              </div>
                              <button className="SmallRedBtn">
                                Add Signing Authority
                              </button>
                            </div>
                            <div className="AuthoritySetupBoxRight">
                              <h6>Authorized Users:</h6>
                              <div className="AuthorizeName">
                                <p>Josie Manager</p>
                                <img src="images/dropdown-delete.png" />
                              </div>
                              <div className="AuthorizeName">
                                <p>John Doe</p>
                                <img src="images/dropdown-delete.png" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="AuthoritySetupBox">
                          <h3>Document: CTC Document</h3>
                          <div className="AuthoritySetupBoxMain">
                            <div className="AuthoritySetupBoxLeft">
                              <h6>Document: Change Order</h6>
                              <div className="CommonSelectBox">
                                <select>
                                  <option selected="">
                                    Select User
                                  </option>
                                </select>
                                <span>
                                  <img src="images/down.png" />
                                </span>
                              </div>
                              <button className="SmallRedBtn">
                                Add Signing Authority
                              </button>
                            </div>
                            <div className="AuthoritySetupBoxRight">
                              <h6>Authorized Users:</h6>
                              <div className="AuthorizeName">
                                <p>Jack Manager</p>
                                <img src="images/dropdown-delete.png" />
                              </div>
                              <div className="AuthorizeName">
                                <p>John Doe</p>
                                <img src="images/dropdown-delete.png" />
                              </div>
                              <div className="AuthorizeName">
                                <p>Josie Manager</p>
                                <img src="images/dropdown-delete.png" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Button d-flex justify-content-center mt-4">
                    <button className="OutlineBlack mr-5 SaveViewConfigBodyBtn" onClick={()=> setEditSigning(false)}>
                      Cancel
                    </button>
                    <button className="Respond ml-5 SaveViewConfigBodyBtn" onClick={handleSubmit}>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>    )
}

export default AdminSigningAuthority