import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUnitStep3 } from "../../../redux/action/proformaAction";

const OptionQuantity = ({
  unitStep3List,
  updateId,
  handleShowComponents,
  handleShowComponent,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUnitStep3(updateId)).then((res) => {
      console.log({ res });
    });
  }, []);
  return (
    <>
      <div className="ModalBigheading pl-4 mb-3 d-flex justify-content-between align-items-center">
        <h3 className="mb-0">
          <span>Unit Quantities</span>
        </h3>
        <h6>
          <img src={require("../../../assets/images/book-search.png")} />
          Detailed Costs
        </h6>
      </div>
      <div className="ProformaAccordion">
        <div className="accordion" id="accordionExample">
          {unitStep3List?.data?.length > 0 &&
            unitStep3List?.data?.map((item, i) => {
              return (
                <div className="card">
                  <div className="card-header" id="headingFour">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#ParkingPads"
                        aria-expanded="false"
                        aria-controls="ParkingPads"
                      >
                        {item.name}
                      </button>
                    </h2>
                    <a href="javscript:void(0);">
                      <img
                        src={require("../../../assets/images/book-search.png")}
                      />
                    </a>
                  </div>
                  <div
                    id="ParkingPads"
                    className="collapse"
                    aria-labelledby="headingFour"
                    data-parent="#accordionExample"
                    style={{}}
                  >
                    <div className="card-body py-0">
                      <div className="EnterProjectTable">
                        <table>
                          <thead>
                            <tr>
                              <th>Cost Code #</th>
                              <th>Description</th>
                              <th>Unit of measure</th>
                              <th>Block A</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item?.ProformaBaseCosts?.length > 0 &&
                              item?.ProformaBaseCosts?.map((data, ind) => {
                                return (
                                  <tr key={ind}>
                                    <td>
                                      <p>{data.CostCode?.number}</p>
                                    </td>
                                    <td>
                                      <p>{data.CostCode?.name}</p>
                                    </td>
                                    <td>
                                      <div className="CommonSelectBox width-250">
                                        <select>
                                          <option>{data?.Unit?.name}</option>
                                        </select>
                                        {/* <span>
                                                        <img src="images/down.png" />
                                                      </span> */}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control width-250"
                                          placeholder="Insert Quantity"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="Button d-flex  justify-content-between align-items-center px-5">
        <button
          className="Outline border text-success border-success"
          onClick={() => handleShowComponents("unitOptionInput")}
        >
          Cancel
        </button>
        <button
          className="Create bg-success border border-success"
          onClick={() => handleShowComponents("unitSummeryPreview")}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default OptionQuantity;
