import { AdminNotificationConstant } from "../actionTypes";
const initialState={
    notificationHeadingList:[],
    loader:false,
}


export default function AdminNotificationReducer(state = initialState, {type, payload}) {
    
    switch(type) {
      case AdminNotificationConstant.GET_NOTIFICATION_HEADING_INITIATE: {
        return {
          ...state, loader:true, notificationHeadingList:[]
        }
      }
      case AdminNotificationConstant.GET_NOTIFICATION_HEADING_SUCCESS: {
        return {
          ...state, loader:false, notificationHeadingList:payload?.data
        }
      }
      case AdminNotificationConstant.GET_NOTIFICATION_HEADING_FAILURE: {
        return {
          ...state, loader:false,notificationHeadingList:[]
        }
      }
  
      
      default: 
      return state
  }
}