import axios from "axios";
import { reportsAction } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config/Config";
import { multiPartData } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

export const finaceSalesCashDepositeInitiate = () => ({
  type: reportsAction.FINANCIAL_SALES_CASH_DEPOSITE_INITIATE,
});

export const finaceSalesCashDepositeSuccess = (data) => ({
  type: reportsAction.FINANCIAL_SALES_CASH_DEPOSITE_SUCCESS,
  payload: data,
});

export const finaceSalesCashDepositeFailure = (data) => ({
  type: reportsAction.FINANCIAL_SALES_CASH_DEPOSITE_FAILURE,
  payload: data,
});

export const finaceSalesCashDepositeAction =
  (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(finaceSalesCashDepositeInitiate(payload));
      const response = await axios.post(
        `${Url}companies/${iBuildLocalData?.user?.CompanyId}/financial-sales`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = response;
      if (data && data.status == 200) {
        dispatch(finaceSalesCashDepositeSuccess(response.data));
      } else {
        dispatch(finaceSalesCashDepositeFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(finaceSalesCashDepositeFailure(err));
      return err.response;
    }
  };




  export const blockActualCostsInitiate = () => ({
    type: reportsAction.BLOCK_ACTUAL_COSTS_INITIATE,
  });
  
  export const blockActualCostsSuccess = (data) => ({
    type: reportsAction.BLOCK_ACTUAL_COSTS_SUCCESS,
    payload: data,
  });
  
  export const blockActualCostsFailure = (data) => ({
    type: reportsAction.BLOCK_ACTUAL_COSTS_FAILURE,
    payload: data,
  });
  
  export const blockActualCostsAction =
    (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(blockActualCostsInitiate(payload));
        const response = await axios.post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/reports-blocks`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const data = response;
        if (data && data.status == 200) {
          dispatch(blockActualCostsSuccess(response.data));
        } else {
          dispatch(blockActualCostsFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(blockActualCostsFailure(err));
        return err.response;
      }
    };
  



    export const blockUnitActualCostsInitiate = () => ({
        type: reportsAction.BLOCK_UNIT_ACTUAL_COSTS_INITIATE,
      });
      
      export const blockUnitActualCostsSuccess = (data) => ({
        type: reportsAction.BLOCK_UNIT_ACTUAL_COSTS_SUCCESS,
        payload: data,
      });
      
      export const blockUnitActualCostsFailure = (data) => ({
        type: reportsAction.BLOCK_UNIT_ACTUAL_COSTS_FAILURE,
        payload: data,
      });
      
      export const blockUnitActualCostsAction =
        (payload) => async (dispatch) => {
          try {
            const token = isLoggedIn("adminData1");
            dispatch(blockActualCostsInitiate(payload));
            const response = await axios.post(
              `${Url}companies/${iBuildLocalData?.user?.CompanyId}/reports-proforma-costs`,
              payload,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );

            const data = response;
            if (data && data.status == 200) {

              dispatch(blockUnitActualCostsSuccess(response.data));
            } else {
              dispatch(blockUnitActualCostsFailure(response.data));
            }
            return response;
          } catch (err) {
            dispatch(blockUnitActualCostsFailure(err));
            return err.response;
          }
        };
      
    

        export const estimationActualInitiate = () => ({
          type: reportsAction.ESTIMATION_ACTUAL_INITIATE,
        });
        
        export const estimationActualSuccess = (data) => ({
          type: reportsAction.ESTIMATION_ACTUAL_SUCCESS,
          payload: data,
        });
        
        export const estimationActualFailure = (data) => ({
          type: reportsAction.ESTIMATION_ACTUAL_FAILURE,
          payload: data,
        });
        
        export const estimationActualAction =
          (payload) => async (dispatch) => {
            try {
              const token = isLoggedIn("adminData1");
              dispatch(estimationActualInitiate(payload));
              const response = await axios.post(
                `${Url}companies/${iBuildLocalData?.user?.CompanyId}/reports-estimation`,
                payload,
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              );
              const data = response;
              if (data && data.status == 200) {
                dispatch(estimationActualSuccess(response.data));
              } else {
                dispatch(estimationActualFailure(response.data));
              }
              return response;
            } catch (err) {
              dispatch(estimationActualFailure(err));
              return err.response;
            }
          };
        
      


          export const addCostDistributionInitiate = () => ({
            type: reportsAction.ADD_COST_DISTRIBUTION_INITIATE,
          });
          
          export const addCostDistributionSuccess = (data) => ({
            type: reportsAction.ADD_COST_DISTRIBUTION_SUCCESS,
            payload: data,
          });
          
          export const addCostDistributionFailure = (data) => ({
            type: reportsAction.ADD_COST_DISTRIBUTION_FAILURE,
            payload: data,
          });
          
          export const addCostDistributionAction =
            (payload) => async (dispatch) => {
              try {
                const token = isLoggedIn("adminData1");
                dispatch(addCostDistributionInitiate(payload));
                const response = await axios.post(
                  `${Url}companies/${iBuildLocalData?.user?.CompanyId}/cost-distribution`,
                  payload,
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  }
                );
                const data = response;
                if (data && data.status == 200) {
                  dispatch(addCostDistributionSuccess(response.data));
                } else {
                  dispatch(addCostDistributionFailure(response.data));
                }
                return response;
              } catch (err) {
                dispatch(addCostDistributionFailure(err));
                return err.response;
              }
            };






            export const updateCostDistributionInitiate = () => ({
              type: reportsAction.UPDATE_COST_DISTRIBUTION_INITIATE,
            });
            
            export const updateCostDistributionSuccess = (data) => ({
              type: reportsAction.UPDATE_COST_DISTRIBUTION_SUCCESS,
              payload: data,
            });
            
            export const updateCostDistributionFailure = (data) => ({
              type: reportsAction.UPDATE_COST_DISTRIBUTION_FAILURE,
              payload: data,
            });
            
            export const updateCostDistributionAction =
              (payload) => async (dispatch) => {
                try {
                  const token = isLoggedIn("adminData1");
                  dispatch(updateCostDistributionInitiate(payload));
                  const response = await axios.put(
                    `${Url}companies/${iBuildLocalData?.user?.CompanyId}/cost-distribution`,
                    payload,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  );
                  const data = response;
                  if (data && data.status == 200) {
                    dispatch(updateCostDistributionSuccess(response.data));
                  } else {
                    dispatch(updateCostDistributionFailure(response.data));
                  }
                  return response;
                } catch (err) {
                  dispatch(updateCostDistributionFailure(err));
                  return err.response;
                }
              };
            
  





              export const deleteCostDistributionInitiate = () => ({
                type: reportsAction.DELETE_COST_DISTRIBUTION_INITIATE,
              });
              
              export const deleteCostDistributionSuccess = (data) => ({
                type: reportsAction.DELETE_COST_DISTRIBUTION_SUCCESS,
                payload: data,
              });
              
              export const deleteCostDistributionFailure = (data) => ({
                type: reportsAction.DELETE_COST_DISTRIBUTION_FAILURE,
                payload: data,
              });
              
              export const deleteCostDistributionAction =
                (payload) => async (dispatch) => {
                  try {
                    const token = isLoggedIn("adminData1");
                    dispatch(deleteCostDistributionInitiate(payload));
                    const response = await axios.delete(
                      `${Url}companies/${iBuildLocalData?.user?.CompanyId}/cost-distribution/${payload}`,                      {
                        headers: { Authorization: `Bearer ${token}` },

                      }
                    );
                    const data = response;
                    if (data && data.status == 200) {
                      dispatch(deleteCostDistributionSuccess(response.data));
                    } else {
                      dispatch(deleteCostDistributionFailure(response.data));
                    }
                    return response;
                  } catch (err) {
                    dispatch(updateCostDistributionFailure(err));
                    return err.response;
                  }
                };




                export const getCostDistributionCategoriesInitiate = () => ({
                  type: reportsAction.GET_COST_DISTRIBUTION_CATEGORIES_INITIATE,
                });
                
                export const getCostDistributionCategoriesSuccess = (data) => ({
                  type: reportsAction.GET_COST_DISTRIBUTION_CATEGORIES_SUCCESS,
                  payload: data,
                });
                
                export const getCostDistributionCategoriesFailure = (data) => ({
                  type: reportsAction.GET_COST_DISTRIBUTION_CATEGORIES_FAILURE,
                  payload: data,
                });
                
                export const getCostDistributionCategoriesAction = (jobId, jobType) => async (dispatch) => {
                  
                  try {
                    const token = isLoggedIn("adminData1");
                    dispatch(getCostDistributionCategoriesInitiate());
                    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/cost-distribution?jobId=${jobId}&jobType=${jobType}`,{
                        headers: { Authorization: `Bearer ${token}` },
                      }
                    );
                    if (response.status === 200) {
                      dispatch(getCostDistributionCategoriesSuccess(response.data));
                    } else {
                      dispatch(getCostDistributionCategoriesFailure(response.data));
                    }
                    return response;
                  } catch (err) {
                    dispatch(getCostDistributionCategoriesFailure(err));
                    return err.response;
                  }
                };
              
              


                export const getJobCostCategoriesInitiate = () => ({
                  type: reportsAction.GET_COST_DISTRIBUTION_CATEGORIES_INITIATE,
                });
                
                export const getJobCostCategoriesSuccess = (data) => ({
                  type: reportsAction.GET_COST_DISTRIBUTION_CATEGORIES_SUCCESS,
                  payload: data,
                });
                
                export const getJobCostCategoriesFailure = (data) => ({
                  type: reportsAction.GET_COST_DISTRIBUTION_CATEGORIES_FAILURE,
                  payload: data,
                });
                
                export const getJobCostCategoriesAction = (jobId, jobType) => async (dispatch) => {
                  
                  try {
                    const token = isLoggedIn("adminData1");
                    dispatch(getJobCostCategoriesInitiate());
                    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/cost-distribution?jobId=${jobId}&jobType=${jobType}`,{
                        headers: { Authorization: `Bearer ${token}` },
                      }
                    );
                    if (response.status === 200) {
                      dispatch(getJobCostCategoriesSuccess(response.data));
                    } else {
                      dispatch(getJobCostCategoriesFailure(response.data));
                    }
                    return response;
                  } catch (err) {
                    dispatch(getJobCostCategoriesFailure(err));
                    return err.response;
                  }
                };
              
              
                export const projectCashFlowReportInitiate = () => ({
                  type: reportsAction.PROJECT_CASH_FLOW_INITIATE,
                });
                
                export const projectCashFlowReportSuccess = (data) => ({
                  type: reportsAction.PROJECT_CASH_FLOW_SUCCESS,
                  payload: data,
                });
                
                export const projectCashFlowReportFailure = (data) => ({
                  type: reportsAction.PROJECT_CASH_FLOW_FAILURE,
                  payload: data,
                });
                
                export const projectCashFlowReportAction = (jobId, jobType) => async (dispatch) => {
                  
                  try {
                    const token = isLoggedIn("adminData1");
                    dispatch(projectCashFlowReportInitiate());
                    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/cash-flow-reports?jobId=${jobId}&jobType=${jobType}`,{
                        headers: { Authorization: `Bearer ${token}` },
                      }
                    );
                    if (response.status === 200) {
                      dispatch(projectCashFlowReportSuccess(response.data));
                    } else {
                      dispatch(projectCashFlowReportFailure(response.data));
                    }
                    return response;
                  } catch (err) {
                    dispatch(projectCashFlowReportFailure(err));
                    return err.response;
                  }
                };
              
              
  



  
          
        
  
  
// =================== STATISTICAL REPORT =========================================



          export const statsPurchasingReportInitiate = () => ({
            type: reportsAction.STATS_PURCHASING_FOR_UNIT_INITIATE,
          });
          
          export const statsPurchasingReportSuccess = (data) => ({
            type: reportsAction.STATS_PURCHASING_FOR_UNIT_SUCCESS,
            payload: data,
          });
          
          export const statsPurchasingReportFailure = (data) => ({
            type: reportsAction.STATS_PURCHASING_FOR_UNIT_FAILURE,
            payload: data,
          });
          
          export const statsPurchasingReportAction =
            (payload) => async (dispatch) => {
              try {
                const token = isLoggedIn("adminData1");
                dispatch(statsPurchasingReportInitiate(payload));
                const response = await axios.post(
                  `${Url}companies/${iBuildLocalData?.user?.CompanyId}/reports-purchase-units`,
                  payload,
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  }
                );
                const data = response;
                if (data && data.status == 200) {
                  dispatch(statsPurchasingReportSuccess(response.data));
                } else {
                  dispatch(statsPurchasingReportFailure(response.data));
                }
                return response;
              } catch (err) {
                dispatch(statsPurchasingReportFailure(err));
                return err.response;
              }
            };
          
        
  


            export const statsBudgePoChanageOrderInitiate = () => ({
              type: reportsAction.STATS_BUDGET_PO_CHANGE_ORDER_INITIATE,
            });
            
            export const statsBudgePoChanageOrderSuccess = (data) => ({
              type: reportsAction.STATS_BUDGET_PO_CHANGE_ORDER_SUCCESS,
              payload: data,
            });
            
            export const statsBudgePoChanageOrderFailure = (data) => ({
              type: reportsAction.STATS_BUDGET_PO_CHANGE_ORDER_FAILURE,
              payload: data,
            });
            
            export const statsBudgePoChanageOrderAction =
              (payload) => async (dispatch) => {
                try {
                  const token = isLoggedIn("adminData1");
                  dispatch(statsBudgePoChanageOrderInitiate(payload));
                  const response = await axios.post(
                    `${Url}companies/${iBuildLocalData?.user?.CompanyId}/reports-po-summary`,
                    payload,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  );
                  const data = response;
                  if (data && data.status == 200) {
                    dispatch(statsBudgePoChanageOrderSuccess(response.data));
                  } else {
                    dispatch(statsBudgePoChanageOrderFailure(response.data));
                  }
                  return response;
                } catch (err) {
                  dispatch(statsBudgePoChanageOrderFailure(err));
                  return err.response;
                }
              };
            
          
    

              export const statsFpoAnalysisInitiate = () => ({
                type: reportsAction.STATS_FPO_ANALYSIS_REASON_INITIATE,
              });
              
              export const statsFpoAnalysisSuccess = (data) => ({
                type: reportsAction.STATS_FPO_ANALYSIS_REASON_SUCCESS,
                payload: data,
              });
              
              export const statsFpoAnalysisFailure = (data) => ({
                type: reportsAction.STATS_FPO_ANALYSIS_REASON_FAILURE,
                payload: data,
              });
              
              export const statsFpoAnalysisAction =
                (payload) => async (dispatch) => {
                  try {
                    const token = isLoggedIn("adminData1");
                    dispatch(statsFpoAnalysisInitiate(payload));
                    const response = await axios.post(
                      `${Url}companies/${iBuildLocalData?.user?.CompanyId}/reports-fpo-fpo-by-filter`,
                      payload,
                      {
                        headers: { Authorization: `Bearer ${token}` },
                      }
                    );
                    const data = response;
                    if (data && data.status == 200) {
                      dispatch(statsFpoAnalysisSuccess(response.data));
                    } else {
                      dispatch(statsFpoAnalysisFailure(response.data));
                    }
                    return response;
                  } catch (err) {
                    dispatch(statsFpoAnalysisFailure(err));
                    return err.response;
                  }
                };
              
            
      

                export const statsFpoAnalysisSummaryInitiate = () => ({
                  type: reportsAction.STATS_FPO_ANALYSIS_SUMMARY_INITIATE,
                });
                
                export const statsFpoAnalysisSummarySuccess = (data) => ({
                  type: reportsAction.STATS_FPO_ANALYSIS_SUMMARY_SUCCESS,
                  payload: data,
                });
                
                export const statsFpoAnalysisSummaryFailure = (data) => ({
                  type: reportsAction.STATS_FPO_ANALYSIS_SUMMARY_FAILURE,
                  payload: data,
                });
                
                export const statsFpoAnalysisSummaryAction =
                  (payload) => async (dispatch) => {
                    try {
                      const token = isLoggedIn("adminData1");
                      dispatch(statsFpoAnalysisSummaryInitiate(payload));
                      const response = await axios.post(
                        `${Url}companies/${iBuildLocalData?.user?.CompanyId}/reports-analysis-summary`,
                        payload,
                        {
                          headers: { Authorization: `Bearer ${token}` },
                        }
                      );
                      const data = response;
                      if (data && data.status == 200) {
                        dispatch(statsFpoAnalysisSummarySuccess(response.data));
                      } else {
                        dispatch(statsFpoAnalysisSummaryFailure(response.data));
                      }
                      return response;
                    } catch (err) {
                      dispatch(statsFpoAnalysisSummaryFailure(err));
                      return err.response;
                    }
                  };
                
              



                  export const statsSalesInitiate = () => ({
                    type: reportsAction.STATS_SALE_INITIATE,
                  });
                  
                  export const statsSalesSuccess = (data) => ({
                    type: reportsAction.STATS_SALE_SUCCESS,
                    payload: data,
                  });
                  
                  export const statsSalesFailure = (data) => ({
                    type: reportsAction.STATS_SALE_FAILURE,
                    payload: data,
                  });
                  
                  export const statsSalesAction =
                    (payload) => async (dispatch) => {
                      try {
                        const token = isLoggedIn("adminData1");
                        dispatch(statsSalesInitiate(payload));
                        const response = await axios.post(
                          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/statistical-sales`,
                          payload,
                          {
                            headers: { Authorization: `Bearer ${token}` },
                          }
                        );
                        const data = response;
                        if (data && data.status == 200) {
                          dispatch(statsSalesSuccess(response.data));
                        } else {
                          dispatch(statsSalesFailure(response.data));
                        }
                        return response;
                      } catch (err) {
                        dispatch(statsSalesFailure(err));
                        return err.response;
                      }
                    };
                  
                
    
  


                    export const statsWarrantyInitiate = () => ({
                      type: reportsAction.STATS_WARRANTY_INITIATE,
                    });
                    
                    export const statsWarrantySuccess = (data) => ({
                      type: reportsAction.STATS_WARRANTY_SUCCESS,
                      payload: data,
                    });
                    
                    export const statsWarrantyFailure = (data) => ({
                      type: reportsAction.STATS_WARRANTY_FAILURE,
                      payload: data,
                    });
                    
                    export const statsWarrantyAction =
                      (payload) => async (dispatch) => {
                        try {
                          const token = isLoggedIn("adminData1");
                          dispatch(statsWarrantyInitiate(payload));
                          const response = await axios.post(
                            `${Url}companies/${iBuildLocalData?.user?.CompanyId}/statistical-warranty`,
                            payload,
                            {
                              headers: { Authorization: `Bearer ${token}` },
                            }
                          );
                          const data = response;
                          if (data && data.status == 200) {
                            dispatch(statsWarrantySuccess(response.data));
                          } else {
                            dispatch(statsWarrantyFailure(response.data));
                          }
                          return response;
                        } catch (err) {
                          dispatch(statsWarrantyFailure(err));
                          return err.response;
                        }
                      };
                    



                      export const statsWarrantyChartFirstInitiate = () => ({
                        type: reportsAction.STATS_WARRANTY_REPORT_FIRST_INITIATE,
                      });
                      
                      export const statsWarrantyChartFirstSuccess = (data) => ({
                        type: reportsAction.STATS_WARRANTY_REPORT_FIRST_SUCCESS,
                        payload: data,
                      });
                      
                      export const statsWarrantyChartFirstFailure = (data) => ({
                        type: reportsAction.STATS_WARRANTY_REPORT_FIRST_FAILURE,
                        payload: data,
                      });
                      
                      export const statsWarrantyChartFirstAction =
                        (payload) => async (dispatch) => {
                          try {
                            const token = isLoggedIn("adminData1");
                            dispatch(statsWarrantyChartFirstInitiate(payload));
                            const response = await axios.post(
                              `${Url}companies/${iBuildLocalData?.user?.CompanyId}/statistical-warranty-charts-first`,
                              payload,
                              {
                                headers: { Authorization: `Bearer ${token}` },
                              }
                            );
                            const data = response;
                            if (data && data.status == 200) {
                              dispatch(statsWarrantyChartFirstSuccess(response.data));
                            } else {
                              dispatch(statsWarrantyChartFirstFailure(response.data));
                            }
                            return response;
                          } catch (err) {
                            dispatch(statsWarrantyChartFirstFailure(err));
                            return err.response;
                          }
                        };
                      
                    
    
                  
  





                        export const statsWarrantyChartSecondInitiate = () => ({
                          type: reportsAction.STATS_WARRANTY_REPORT_SECOND_INITIATE,
                        });
                        
                        export const statsWarrantyChartSecondSuccess = (data) => ({
                          type: reportsAction.STATS_WARRANTY_REPORT_SECOND_SUCCESS,
                          payload: data,
                        });
                        
                        export const statsWarrantyChartSecondFailure = (data) => ({
                          type: reportsAction.STATS_WARRANTY_REPORT_SECOND_FAILURE,
                          payload: data,
                        });
                        
                        export const statsWarrantyChartSecondAction =
                          (payload) => async (dispatch) => {
                            try {
                              const token = isLoggedIn("adminData1");
                              dispatch(statsWarrantyChartSecondInitiate(payload));
                              const response = await axios.post(
                                `${Url}companies/${iBuildLocalData?.user?.CompanyId}/statistical-warranty-charts-second`,
                                payload,
                                {
                                  headers: { Authorization: `Bearer ${token}` },
                                }
                              );
                              const data = response;
                              if (data && data.status == 200) {
                                dispatch(statsWarrantyChartSecondSuccess(response.data));
                              } else {
                                dispatch(statsWarrantyChartSecondFailure(response.data));
                              }
                              return response;
                            } catch (err) {
                              dispatch(statsWarrantyChartSecondFailure(err));
                              return err.response;
                            }
                          };







                          export const statsWarrantyChartThirdInitiate = () => ({
                            type: reportsAction.STATS_WARRANTY_REPORT_THIRD_INITIATE,
                          });
                          
                          export const statsWarrantyChartThirdSuccess = (data) => ({
                            type: reportsAction.STATS_WARRANTY_REPORT_THIRD_SUCCESS,
                            payload: data,
                          });
                          
                          export const statsWarrantyChartThirdFailure = (data) => ({
                            type: reportsAction.STATS_WARRANTY_REPORT_THIRD_FAILURE,
                            payload: data,
                          });
                          
                          export const statsWarrantyChartThirdAction =
                            (payload) => async (dispatch) => {
                              try {
                                const token = isLoggedIn("adminData1");
                                dispatch(statsWarrantyChartThirdInitiate(payload));
                                const response = await axios.post(
                                  `${Url}companies/${iBuildLocalData?.user?.CompanyId}/statistical-warranty-charts-third`,
                                  payload,
                                  {
                                    headers: { Authorization: `Bearer ${token}` },
                                  }
                                );
                                const data = response;
                                if (data && data.status == 200) {
                                  dispatch(statsWarrantyChartThirdSuccess(response.data));
                                } else {
                                  dispatch(statsWarrantyChartThirdFailure(response.data));
                                }
                                return response;
                              } catch (err) {
                                dispatch(statsWarrantyChartThirdFailure(err));
                                return err.response;
                              }
                            };
                          
      
                        
                      
    