import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getBlockFoundationStep2 } from '../../../redux/action/proformaAction'

const BlockFoundationQuantities = ({
  blockQuantitiesData,
  unitList,
  handleBlockQuantityChange,
  updateId,
  handleProformaBlockFoundationStep2,
  handleShow
}) => {
  const dispatch=useDispatch()
  const [blockFoundationStep2, setBlockFoundationStep2] = useState([]);
  useEffect(()=>{
dispatch(getBlockFoundationStep2(updateId)).then((data) => {
  setBlockFoundationStep2(data?.data);
})
  },[])
  return (
    <>
    <div className="ProformaAccordion">
    <div className="accordion" id="accordionExample">
      {blockFoundationStep2?.data?.length > 0 &&
        blockFoundationStep2?.data?.map((data, ind) => {
          return (
            <div className="card">
              <div className="card-header" id="headingMain">
                <h2 className="mb-0">
                  <button
                    className={`btn btn-link btn-block text-left `}
                    data-toggle="collapse"
                    data-target="#collapseMain"
                    aria-expanded="false"
                    aria-controls="collapseMain"
                  >
                    {data.name}
                  </button>
                </h2>
                <a href="javascript:void(0);">
                  {/* <img
                    src={require("../assets/images/book-search.png")}
                    alt="Book Search"
                  /> */}
                </a>
              </div>
              <div
                id="collapseMain"
                className="collapse"
                aria-labelledby="headingMain"
                data-parent="#accordionExample"
              >
                <div className="card-body">
                  <div>
                    {data?.ProformaCostHeadings?.length > 0 &&
                      data?.ProformaCostHeadings?.map(
                        (item, i) => {
                          const itemHeadingId = `heading-${i}`;
                          const itemCollapseId = `collapse-${i}`;

                          return (
                            <div className="card" key={i}>
                              <div
                                className="card-header"
                                id={itemHeadingId}
                              >
                                <h2 className="mb-0">
                                  <button
                                    className={`btn btn-link btn-block text-left collapsed `}
                                    type="button"
                                    data-toggle="collapse"
                                    data-target={`#${itemCollapseId}`}
                                    aria-expanded="false"
                                    aria-controls={itemCollapseId}
                                  
                                  >
                                    {item.name}
                                  </button>
                                </h2>
                                <a href="javascript:void(0);">
                                  {/* <img
                                    src={require("../assets/images/book-search.png")}
                                    alt="Book Search"
                                  /> */}
                                </a>
                              </div>
                              <div>
                                <div className="card-body">
                                  <div
                                    className="EnterProjectTable"
                                    style={{
                                      overflowX: "scroll",
                                    }}
                                  >
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Cost Code #</th>
                                          <th>Description</th>
                                          <th>
                                            <div className="ml-5">
                                              Unit of measure
                                            </div>
                                          </th>
                                          {item?.ProformaBaseCosts?.ProformaBlockQuantities?.map(
                                            (blocks, j) => (
                                              <th key={j}>
                                                {
                                                  blocks
                                                    ?.MultiBlock
                                                    ?.blockDescription
                                                }
                                              </th>
                                            )
                                          )}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {item?.ProformaBaseCosts?.map(
                                          (datas, k) => (
                                            <tr key={k}>
                                              <td>
                                                {
                                                  datas.CostCode
                                                    ?.number
                                                }
                                              </td>
                                              <td>
                                                {
                                                  datas.CostCode
                                                    ?.name
                                                }
                                              </td>
                                              <td>
                                                <div className="CommonSelectBox width-250 ml-5">
                                                  <select
                                                    name="unitName"
                                                    onChange={(
                                                      e
                                                    ) =>
                                                      handleProformaBlockFoundationStep2(
                                                        e,
                                                        datas.id,
                                                        ind,
                                                        i,
                                                        k
                                                      )
                                                    }
                                                  >
                                                    <option
                                                      value={
                                                        datas
                                                          ?.Unit
                                                          ?.id
                                                      }
                                                    >
                                                      {
                                                        datas
                                                          ?.Unit
                                                          ?.name
                                                      }
                                                    </option>
                                                  </select>
                                                </div>
                                              </td>
                                              <td>
                                                <div className="form-group">
                                                  <input
                                                    type="text"
                                                    className="form-control width-250"
                                                    placeholder="Insert Quantity"
                                                    name="quantity"
                                                    value={
                                                      datas.quantity ||
                                                      0
                                                    }
                                                    onChange={(
                                                      e
                                                    ) =>
                                                      handleProformaBlockFoundationStep2(
                                                        e,
                                                        datas.id,
                                                        ind,
                                                        i,
                                                        k,
                                                        blockFoundationStep2,
                                                        setBlockFoundationStep2
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </td>
                                              <td>
                                                <div className="form-group">
                                                  <input
                                                    type="text"
                                                    className="form-control width-250"
                                                    placeholder="Insert Quantity"
                                                    name="quantity"
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                    <div className="PaginationArea pl-0">
                                      <h6>
                                        Showing 1 to 10 of 27
                                        entries
                                      </h6>
                                      <h6>
                                        <img
                                          src="images/leftarrow.png"
                                          alt="Left Arrow"
                                        />{" "}
                                        01{" "}
                                        <img
                                          src="images/rightarrow.png"
                                          alt="Right Arrow"
                                        />{" "}
                                        Out 10
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  </div>
  <div className="Button d-flex  justify-content-between align-items-center px-5">
                <button
                  className="Outline border text-success border-success"
                  onClick={() => handleShow("blockFoundationInput")}
                >
                  Cancel
                </button>
                <button
                  className="Create bg-success border border-success"
                  onClick={()=>handleShow("blockFoundationSummeryPreview")}
                   >
                  Save Draft
                </button>
               
              </div>
    </>
  )
}

export default BlockFoundationQuantities