import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "../App.css";
import Modal from "react-bootstrap/Modal";
import Loader from "./Loader";
import SignaturePad from "react-signature-canvas";
import { convertToPNG, dataURLtoBlob } from "../utils/blog";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  addSignInTemplated,
  getConverToString,
  getSalesTemplateByIds,
} from "../redux/action/saleAction";

const initialState = {
  primarySignature: "",
  primarySignatureBlob: "",
  ManageSignature: false,
  signature: "",
};

const ClienTDocsSign = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const signCanvas = useRef({});
  const [clientData, setClientData] = useState([]);
  const [headerData, setHeaderData] = useState("");
  const [iState, updateState] = useState(initialState);

  const { primarySignature, primarySignatureBlob, ManageSignature, signature } =
    iState;
  const urlParams = new URLSearchParams(
    location?.search?.split("?")[1].split("#")[0]
  );
  const companyId = urlParams?.get("companyId");
  const documentId = urlParams?.get("documentId");
  const token = urlParams?.get("token");


  useEffect(() => {
    if (token) {
      dispatch(getSalesTemplateByIds(documentId, token)).then((res) => {
        if (res.status === 200) {
          setClientData(res?.data?.data);
          const filename = res?.data?.data?.htmlBlobName?.split("?")[0];
          dispatch(getConverToString(filename, token)).then((response) => {
            if (response.status === 200) {
              setHeaderData(response?.data?.data);
            }
          });
        }
      });
    }
  }, [documentId, token]);

  const primaryData = clientData?.ClientDocAssignees?.find(
    (item) => item.signeeType === "Primary Signee"
  );
  const secondaryData = clientData?.ClientDocAssignees?.find(
    (item) => item.signeeType === "Secondary Signee"
  );
  const additionalData = clientData?.ClientDocAssignees?.find(
    (item) => item.signeeType === "Additional Signee"
  );
  const builderData = clientData?.ClientDocAssignees?.find(
    (item) => item.signeeType === "Builder Signee"
  );
  

  const handleManageSignatueShow = () => {
    updateState({
      ...iState,
      ManageSignature: true,
      primarySignature: "",
    });
  };
  const handleManageSignatueClose = () => {
    updateState({
      ...iState,
      ManageSignature: false,
      signature: "",
      signTypes: "",
    });
  };

  const addSignature = () => {
    const signatureBlob = dataURLtoBlob(signature);
    const convertToValidFormat = (blob, fileName) => {
      const fileExtension = fileName.split(".").pop().toLowerCase();
      if (
        ![
          "jpg",
          "jpeg",
          "png",
          "gif",
          "pdf",
          "bmp",
          "webp",
          "svg+xml",
        ].includes(fileExtension)
      ) {
        const convertedBlob = convertToPNG(blob);
        return convertedBlob;
      }
      return blob;
    };
    const validSignatureBlob = convertToValidFormat(
      signatureBlob,
      "signature.png"
    );
    const validSignatureBlobURL = URL.createObjectURL(validSignatureBlob);
    updateState({
      ...iState,
      primarySignature: validSignatureBlobURL,
      primarySignatureBlob: validSignatureBlob,
      ManageSignature: false,
    });
  };
  const clearSignature = () => {
    signCanvas.current.clear();
  };

  const handleSave = () => {
    const formData = new FormData();
    formData.append("signeeId", primaryData?.signeeId);
    formData.append("isSigned", true);
    formData.append("file", primarySignatureBlob);
    dispatch(addSignInTemplated(companyId,documentId, formData, token)).then((res) => {
      if (res.status === 200) {
        dispatch(getSalesTemplateByIds(documentId, token)).then((res) => {
          if (res.status === 200) {
            setClientData(res?.data?.data);
            const filename = res?.data?.data?.htmlBlobName?.split("?")[0];
            dispatch(getConverToString(filename, token)).then((response) => {
              if (response.status === 200) {
                setHeaderData(response?.data?.data);
              }
            });
          }
        });
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState });
      }
    });
  };

  return (
    <>
      <div className="FormArea">
        <div className="ModalTitleBox">
          <h4>View Document</h4>
        </div>
        <div className="FolderForm">
          <div className="AgreementViewArea">
            <div className="AgreementViewTop">
              <div className="AgreementViewTopRight">
                {clientData && (
                  <p
                    dangerouslySetInnerHTML={{ __html: headerData }}
                    className="editor"
                  ></p>
                )}
              </div>
            </div>
            <div className="ChangeOrderCreatArea mt-5">
              {primaryData && (
                <div className="row">
                  <div className="col-md-4">
                    <div className="InputBox">
                      <div className="form-group">
                        <input type="date" />
                        <label>Date</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 ml-auto">
                    <div className="InputBox">
                      <div className="form-group">
                        <div
                          className="InsertSignature"
                          onClick={() => handleManageSignatueShow()}
                        >
                          {
                            signature? 
                            <img src={primarySignature} />:
                            <img src={primaryData?.signUrl} />
                          }
                          <a>
                            <img src="images/insert_signature.png" />
                            Insert Signature
                          </a>
                        </div>
                        {/* <label>{signUrl}</label> */}
                        <label>Primary Signee</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {secondaryData?.signUrl && (
                <div className="row">
                  <div className="col-md-4">
                    <div className="InputBox">
                      <div className="form-group">
                        <input type="date" />
                        <label>Date</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 ml-auto">
                    <div className="InputBox">
                      <div className="form-group">
                        <div
                          className="InsertSignature"
                          // onClick={() =>
                          //   handleManageSignatueShow("secondarySignature")
                          // }
                        >
                          {secondaryData?.signUrl && (
                            <img src={secondaryData?.signUrl} />
                          )}
                          {/* <a>
                            <img src="images/insert_signature.png" />
                            Insert Signature
                          </a> */}
                        </div>
                        {/* <label>{secondarySignStatus}</label> */}
                        <label>Secondary Signee</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {additionalData?.signUrl && (
                <div className="row">
                  <div className="col-md-4">
                    <div className="InputBox">
                      <div className="form-group">
                        <input type="date" />
                        <label>Date</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 ml-auto">
                    <div className="InputBox">
                      <div className="form-group">
                        <div
                          className="InsertSignature"
                          // onClick={() =>
                          //   handleManageSignatueShow("additionalSignature")
                          // }
                        >
                          {additionalData?.signUrl && (
                            <img src={additionalData?.signUrl} />
                          )}
                          {/* <a>
                            <img src="images/insert_signature.png" />
                            Insert Signature
                          </a> */}
                        </div>
                        {/* <label>{additionalSignStatus}</label> */}
                        <label>Additional Signee</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {builderData?.signUrl && (
                <div className="row">
                  <div className="col-md-4">
                    <div className="InputBox">
                      <div className="form-group">
                        <input type="date" />
                        <label>Date</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 ml-auto">
                    <div className="InputBox">
                      <div className="form-group">
                        <div
                          className="InsertSignature"
                          // onClick={() =>
                          //   handleManageSignatueShow("builderSignature")
                          // }
                        >
                          {builderData?.signUrl && (
                            <img src={builderData?.signUrl} />
                          )}
                          {/* <a>
                            <img src="images/insert_signature.png" />
                            Insert Signature
                          </a> */}
                        </div>
                        {/* <label>{builderData}</label> */}
                        <label>Builder Signee</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="Button d-flex justify-content-center mt-3 mb-3">
            <button className="Create FolderPermissionId mb-3" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </div>
      <Modal
        show={ManageSignature}
        onHide={handleManageSignatueClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a className="CloseModal" onClick={handleManageSignatueClose}>
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Manage Signatures</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <div className="CommonModalArea">
                  <h5>Add new signature</h5>
                </div>
              </div>
              <div className="form-group">
                <div className="CommonModalArea border p-2">
                  <h5>Signatures</h5>
                  <div className="CommonModalBox">
                    <SignaturePad
                      ref={signCanvas}
                      penColor="green"
                      canvasProps={{
                        width: 400,
                        height: 300,
                        className: "sigCanvas",
                      }}
                      onEnd={() =>
                        updateState({
                          ...iState,
                          signature: signCanvas?.current
                            ?.getTrimmedCanvas()
                            .toDataURL("image/png"),
                        })
                      }
                    />
                    <a onClick={clearSignature}>
                      <img src="images/circle_minus.png" />
                    </a>
                    {/* <a onClick={saveSignature} href="javascript:void(0);">
                        Save
                      </a> */}
                  </div>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                    {errors?.signatureError}
                  </span> */}
                </div>
              </div>

              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={addSignature}>
                  Save
                </button>
                <button className="Outline" onClick={handleManageSignatueClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ClienTDocsSign;
