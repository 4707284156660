import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { Link } from "react-router-dom";
import ClientViewSidenav from "./client-view-sidenav";
import ClientHeader from "./ClientHeader";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  clientAppointmentUpdateReminder,
  getAppointmentPendingList,
  getClientAllAppointmentList,
  getGoogleCalendarClientView,
  getOutlookCalendarClientView,
  getYahooCalendarClientView,
} from "../redux/action/clientViewAuthAction";
import refresh from "../assets/images/refresh.png";
import dropdown_left from "../assets/images/dropdown_left.png";
import dropdown_right from "../assets/images/dropdown_right.png";
import threeDots from "../assets/images/three-dots.png";
import earth from "../assets/images/earth.png";
import taskHistory from "../assets/images/task-history.png";
import exportToCalendar from "../assets/images/export-to-calendar.png";
import rightarrow2 from "../assets/images/rightarrow2.png";
import googleCalendar from "../assets/images/google-calendar.png";
import outlookCalendar from "../assets/images/outlook-calendar.png";
import SetReminder from "../assets/images/set-reminder.png";
import emailCalendar from "../assets/images/email-calendar.png";
import next_calendar from "../assets/images/next_calendar.png";
import Loader from "./Loader";
import { calculateDateDifference } from "../utils/uploadFile";
const initialState = {
  PendingDateSelectionModal: false,
  pendingListData: [],
  selectedDatesOfCalender: [],
  AppointmentsDetailsModal: false,
  appointmentDeatilsData: [],
  UpdateReminderModal: false,
  appointmentId: "",
  week: "",
  day: "",
};

const AppointmentsClientView = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const {
    PendingDateSelectionModal,
    pendingListData,
    selectedDatesOfCalender,
    AppointmentsDetailsModal,
    appointmentDeatilsData,
    UpdateReminderModal,
    appointmentId,
    week,
    day,
  } = iState;

  const clientData = JSON.parse(window.localStorage.getItem("clientViewLogin"));

  const { allAppointmentList, appointmentLoader } = useSelector(
    (state) => state.clientViewLogin
  );
  // console.log("allAppointmentList",clientData?.jobDetails?.JobId);

  useEffect(() => {
    if(clientData){
    dispatch(
      getClientAllAppointmentList(
        clientData?.user?.CompanyId,
        clientData?.jobDetails?.ProjectId,
        clientData?.jobDetails?.id,
        clientData?.jobDetails?.jobType
      )
    ).then((res) => {
      if (res.status === 200 && res?.data?.data?.length > 0) {
        const selectedDatesOfCalender = res?.data?.data
          ?.flatMap((item) => {
            return item?.designAppointmentDates?.map((dateItem) => {
              if (dateItem?.date) {
                // Split date string and construct a new Date object with the correct format
                const [year, month, day] = dateItem.date.split("-");
                return new Date(year, month - 1, day); // Months are 0-based in JavaScript Date constructor
              } else {
                console.error(
                  "dateItem or dateItem.date is null or undefined:",
                  dateItem
                );
                return null; // Handle null or undefined dateItem or dateItem.date
              }
            });
          })
          .filter((date) => date !== null);

        updateState({
          ...iState,
          selectedDatesOfCalender: selectedDatesOfCalender, // Filter out null values
        });
      }
    })}
  }, []);
  const handlePendingDateSelectionModalShow = () => {
    updateState({ ...iState, PendingDateSelectionModal: true });
  };

  useEffect(() => {
    if (PendingDateSelectionModal) {
      dispatch(
        getAppointmentPendingList(
          clientData?.user?.CompanyId,
          clientData?.jobDetails?.ProjectId
        )
      ).then((res) => {
        if (res.status == 200) {
          updateState({ ...iState, pendingListData: res.data?.data });
        }
      });
    }
  }, [PendingDateSelectionModal]);

  const handlePendingDateSelectionModalClose = () => {
    updateState({ ...iState, PendingDateSelectionModal: false });
  };

  const handleGoogleData = (item) => {
    const datta = {
      title: item.title,
      description: "test",
      assignDate: item.reminderDate,
      reminderDate: item.reminderDate,
      location: item.location,
    };
    dispatch(
      getGoogleCalendarClientView(clientData?.user?.CompanyId, datta)
    ).then((res) => {
      if (res.status === 200) {
        window.open(res.data.google, "_blank");
        toast.success("Find data Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleOutLookData = (item) => {
    const datta = {
      title: item.title,
      description: "test",
      assignDate: item.reminderDate,
      reminderDate: item.reminderDate,
      location: item.location,
    };
    dispatch(
      getOutlookCalendarClientView(clientData?.user?.CompanyId, datta)
    ).then((res) => {
      if (res.status === 200) {
        window.open(res.data.outlook, "_blank");
        toast.success("Find data Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  const handleYahooData = (item) => {
    const datta = {
      title: item.title,
      description: "test",
      assignDate: item.reminderDate,
      reminderDate: item.reminderDate,
      location: item.location,
    };
    dispatch(
      getYahooCalendarClientView(clientData?.user?.CompanyId, datta)
    ).then((res) => {
      if (res.status === 200) {
        window.open(res.data.yahoo, "_blank");
        toast.success("Find data Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  const handleAppointmentsDetailsModalShow = (data) => {
    updateState({
      ...iState,
      AppointmentsDetailsModal: true,
      appointmentDeatilsData: data,
    });
  };

  const handleAppointmentsDetailsModalClose = () => {
    updateState({ ...iState, AppointmentsDetailsModal: false });
  };

  const handleUpdateReminderShow = (id) => {
    updateState({
      ...iState,
      UpdateReminderModal: true,
      appointmentId: id,
    });
  };

  const handleUpdateReminderClose = () => {
    updateState({
      ...iState,
      UpdateReminderModal: false,
      appointmentId: "",
    });
  };

  const handleReminderUpdate = () => {
    const types = week ? week : day;
    const reminderDates = calculateDateDifference(types);
    const datta = { reminderDate: reminderDates, appointmentId };
    dispatch(
      clientAppointmentUpdateReminder(
        clientData?.user?.CompanyId,
        clientData?.jobDetails?.ProjectId,
        datta
      )
    ).then((res) => {
      if (res.status === 200) {
        // dispatch(getClientAllAppointmentList(clientData?.user?.CompanyId,clientData?.jobDetails?.ProjectId,
        //   1,clientData?.jobDetails?.JobType))
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          UpdateReminderModal: false,
          day: "",
          week: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, UpdateReminderModal: true });
      }
    });
  };
  return (
    <div>
      <ClientHeader />
      <ClientViewSidenav />
      <div className="WrapperAreaClient">
        <div className="WrapperBoxClient">
          {/* <h6 data-toggle="modal" data-target="#ViewChangeAgreement">
            ViewChangeAgreement
          </h6> */}
          <div className="MainTitleBox">
            <div className="MainTitle">
              <h2>Appointments</h2>
            </div>
          </div>
          <div className="TitleBox d-flex flex-lg-row flex-column align-items-lg-cener align-items-start">
            <div className="TodosButtons mt-0 d-flex">
              <a
                onClick={handlePendingDateSelectionModalShow}
                className="Create mr-2"
              >
                <img src="images/pending_date.png" />
                Pending Date Selections<span>3</span>
              </a>
            </div>
            {/* <p className="MountTime">
              <img src="images/earth.png" />
              Mountain time - US &amp; Canada (08:08 am){" "}
              <img src="images/black-down-arrow.png" />
            </p> */}
            <div className="TitleBoxProjectHeading">
              <h6>
                <span>Project:</span> Chinook Gate
              </h6>
              <h6>
                <span>Unit:</span> 32
              </h6>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="NextAppointmentsArea">
                <h1>Next Appointments</h1>
                {!appointmentLoader ? (
                  allAppointmentList?.data?.length > 0 ? (
                    allAppointmentList?.data?.map((item, i) => {
                      return (
                        <div className="NextAppointBox LightGreenBg">
                          <div className="NextAppointBoxLeft">
                            <h6>{item.title}</h6>
                            <p>
                              {item?.designAppointmentDates?.map((dateItem) => {
                                if (dateItem?.date) {
                                  const [year, month, day] =
                                    dateItem.date.split("-");
                                  const parsedDate = new Date(
                                    year,
                                    month - 1,
                                    day
                                  ); // Months are 0-based in JavaScript Date constructor
                                  const fromTime = dateItem.fromTime;
                                  const toTime = dateItem.toTime;
                                  const formattedDate =
                                    parsedDate.toLocaleDateString("en-US", {
                                      weekday: "long",
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    });
                                  return (
                                    <span key={dateItem.id}>
                                      {`${fromTime} - ${toTime}. ${formattedDate}`}
                                    </span>
                                  );
                                } else {
                                  console.error(
                                    "dateItem or dateItem.date is null or undefined:",
                                    dateItem
                                  );
                                  return null; // Handle null or undefined dateItem or dateItem.date
                                }
                              })}
                            </p>
                          </div>
                          <div className="dropdown">
                            <p data-toggle="dropdown">
                              <span>
                                <img src={threeDots} />
                              </span>
                            </p>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuOffset"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleAppointmentsDetailsModalShow(item)
                                  }
                                >
                                  <img src={taskHistory} />
                                  Appointment Details
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src={exportToCalendar} />
                                  Export to Calender
                                  <img src={rightarrow2} />
                                </a>
                                <ul className="dropdown-menu submenu">
                                  <li>
                                    <a
                                      onClick={() => handleGoogleData(item)}
                                      className="dropdown-item"
                                    >
                                      <img src={googleCalendar} />
                                      Google Calendar
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      onClick={() => handleOutLookData(item)}
                                      className="dropdown-item"
                                    >
                                      <img src={outlookCalendar} />
                                      Outlook Calender
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      onClick={() => handleYahooData(item)}
                                      className="dropdown-item"
                                    >
                                      <img src="images/yahoo-calendar.png" />
                                      Yahoo Calender
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleUpdateReminderShow(item.id)
                                  }
                                >
                                  <img src={SetReminder} />
                                  Set Reminder
                                </a>
                              </li>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    "No Data found"
                  )
                ) : (
                  <Loader />
                )}
              </div>
            </div>
            <div className="col-md-7">
              <div className="AppointmentCalendar">
                {selectedDatesOfCalender?.length > 0 && (
                  <DatePicker
                    // selected={null} // Set to null to avoid selecting a default date
                    // onChange={date => handleDateChange(date)}
                    inline
                    selectsRange
                    dateFormat="dd/mm/yyyy"
                    placeholderText="Select dates"
                    highlightDates={selectedDatesOfCalender}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={PendingDateSelectionModal}
        onHide={handlePendingDateSelectionModalClose}
        className="ModalBox modal fade MediumModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handlePendingDateSelectionModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Pending Date Selection List</h4>
            </div>
            <div className="FolderForm">
              {/* <div className="PendingDateSelectionListArea">
                <table>
                  <thead>
                    <tr>
                      <th>Builder</th>
                      <th>Project</th>
                      <th>Job</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Builder</td>
                      <td>Project</td>
                      <td>Job</td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
              <div className="TableList CommonHeading mt-3">
                <table>
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Type</th>
                      <th>Date Received</th>
                      <th>Activity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pendingListData?.length > 0 ? (
                      pendingListData?.map((data, i) => {
                        return (
                          <tr key={i}>
                            <td>{data.title}</td>
                            <td>{data.jobType}</td>
                            <td>
                              {moment(data.createdAt).format("YYYY-MM-DD")}
                            </td>
                            <td>
                              {/* <a onClick={()=>handleAcceptRejectModalShow(data.id)}>
                              <img src="images/new-icon.png" />
                            </a> */}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="10" className="text-center">
                          <p>No Data found.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={AppointmentsDetailsModal}
        onHide={handleAppointmentsDetailsModalClose}
        className="ModalBox modal fade MediumModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleAppointmentsDetailsModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBoxBrand">
              <h4>Trades Day Selection</h4>
              <a href="">
                <img src="images/logo-white.png" />
              </a>
            </div>
            <div className="FolderForm">
              <h3>Work Order #{appointmentDeatilsData?.id}</h3>
              <div className="row">
                <div className="col-lg-6">
                  <h3 className="fw-700">Trades Day Confirmation</h3>
                  <div className="ClientsDetails">
                    <p>Client Details</p>
                    <h6 className="ClientName"></h6>
                    <p className="ClientEmail"></p>
                    <p className="ClientNumber"></p>
                  </div>
                  <div className="ClientLocation">
                    <p>
                      Location <img src="images/location.png" />
                    </p>
                    <p className="ClientAddress">
                      {appointmentDeatilsData?.location ?? "N/A"}
                    </p>
                  </div>
                  <div className="ClientsDetails">
                    <p>Vendor Details</p>
                    <h6 className="ClientName"></h6>
                    <p className="ClientEmail"></p>
                    <p className="ClientNumber"></p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h3 className="fw-700">Items to be Fixed</h3>
                  <div className="ItemAreaBox ItemAreaBoxUnderline mb-5">
                    <h6>#1 - Leaking Sink</h6>
                    <h6>#2 Faucet Problem</h6>
                  </div>
                  <div className="ClientsDetails">
                    <h6 className="ClientName">Technician</h6>
                    <p className="ClientEmail"></p>
                    <p className="ClientNumber">Contact:</p>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="DatesBoxAreaMain">
                    <h3>Dates</h3>
                    {appointmentDeatilsData?.designAppointmentDates?.length >
                      0 &&
                      appointmentDeatilsData?.designAppointmentDates?.map(
                        (data, index) => {
                          return (
                            <div className="DatesBoxArea" key={index}>
                              <h6>#{index + 1} - Leaking Sink</h6>
                              <p>
                                {data.fromTime} - {data.toTime}. {data.date}
                              </p>
                              <p className="mb-3"></p>
                              <div className="AddToSocialArea">
                                <h6>Add to</h6>
                                <a href="javascript:void(0);">
                                  <img src="images/google-calendar.png" />{" "}
                                  Calendar
                                </a>
                                <a href="javascript:void(0);">
                                  <img src="images/outlook-calendar.png" />
                                  Outlook Calendar
                                </a>
                                <a href="javascript:void(0);">
                                  <img src="images/yahoo-calendar.png" />
                                  Yahoo Calendar
                                </a>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={UpdateReminderModal}
        onHide={handleUpdateReminderClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleUpdateReminderClose}>
                ×
              </a>
              <div className="FolderForm">
                <div className="form-group">
                  <h6>
                    Weeks <span className="required"></span>
                  </h6>
                  <div className="CommonSelectBox w-100">
                    <select
                      disabled={day}
                      name="week"
                      value={week}
                      onChange={handleInputChange}
                    >
                      <option value="">
                        Select amount of weeks for reminder
                      </option>
                      <option value="7">1</option>
                      <option value="14">2</option>
                      <option value="21">3</option>
                      <option value="28">4</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <h6>
                    Days <span className="required"></span>
                  </h6>
                  <div className="CommonSelectBox w-100">
                    <select
                      disabled={week}
                      name="day"
                      value={day}
                      onChange={handleInputChange}
                    >
                      <option value="">
                        Select amount of days for reminder
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </select>
                  </div>
                </div>
                <div className="Button text-center mt-5">
                  <button className="Create" onClick={handleReminderUpdate}>
                    Set Reminder
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AppointmentsClientView;
