import { warrantyAction } from "../actionTypes";
const initialState = {
  loader: false,
  fileLoader:false,
  projectJobList:[],
  warrantyFolderList:[],
  warrantyFolderSearchList:[],
  warrantyFolderHistoryList:[],
  newfileDocsList:[],
  newfileSearchDocsList:[],
  warrantyHistoryList:[],
  warrantyPendingFileEventsList:[],
  pendingLoader:false,
  draftWarranty:[],
  allSystemUrgencyList:[],
  allUrgencyList:[],
  allUserUrgencyList:[],
  allRequestItemList:[],
  itemLoader:false,
  allServiceTypeList:[],
  allItemTypeList:[],
  allSystemServiceTypeList:[],
  allUserServiceTypeList:[],
  allSystemItemTypeList:[],
  allUserItemTypeList:[],
  workOrderReqItemList:[],
  vendorTypesList:[],
  vendorList:[],
  allWarrantyList:[],
  allWorkOrderList:[],
  warrantyLoader:false,
  workLoader:false,
  supportDocsList:[],
  supportDocsLoader:false,
  inspectorList:[],
  draftWorkOrder:[],
  selectedRequestItems:[],
  selectedWarrantyRequestItems:[],
  warrantyJobsList:[],
  primaryClientsDatas:[],
};

export default function warrantyReducer(state = initialState, { type, payload }) {
  switch (type) {

    case warrantyAction.LIST_PROJECT_JOBS_INITIATE: {
      return {...state,projectJobList: []};
    }
    case warrantyAction.LIST_PROJECT_JOBS_SUCCESS: {
      return {...state, projectJobList: payload};
    }
    case warrantyAction.LIST_PROJECT_JOBS_FAILURE: {
      return { ...state,projectJobList:[]};
    }

    case warrantyAction.WARRANTY_FOLDER_LIST_INITIATE: {
        return {...state,warrantyFolderList: []};
      }
      case warrantyAction.WARRANTY_FOLDER_LIST_SUCCESS: {
        return {...state, warrantyFolderList: payload};
      }
      case warrantyAction.WARRANTY_FOLDER_LIST_FAILURE: {
        return { ...state,warrantyFolderList: []};
      }


      case warrantyAction.WARRANTY_FOLDER_SEARCH_LIST_INITIATE: {
        return {...state,warrantyFolderSearchList: []};
      }
      case warrantyAction.WARRANTY_FOLDER_SEARCH_LIST_SUCCESS: {
        return {...state, warrantyFolderSearchList: payload};
      }
      case warrantyAction.WARRANTY_FOLDER_SEARCH_LIST_FAILURE: {
        return { ...state,warrantyFolderSearchList: []};
      }

      case warrantyAction.WARRANTY_FOLDER_HISTORY_LIST_INITIATE: {
        return {...state,warrantyFolderHistoryList: []};
      }
      case warrantyAction.WARRANTY_FOLDER_HISTORY_LIST_SUCCESS: {
        return {...state, warrantyFolderHistoryList: payload};
      }
      case warrantyAction.WARRANTY_FOLDER_HISTORY_LIST_FAILURE: {
        return { ...state,warrantyFolderHistoryList: []};
      }

      case warrantyAction.NEW_FILE_LIST_INITIATE: {
        return {...state,fileLoader:true,newfileDocsList: []};
      }
      case warrantyAction.NEW_FILE_LIST_SUCCESS: {
        return {...state,fileLoader:false, newfileDocsList: payload};
      }
      case warrantyAction.NEW_FILE_LIST_FAILURE: {
        return { ...state,fileLoader:false,newfileDocsList: []};
      }

      case warrantyAction.NEW_FILE_LIST_SEARCH_INITIATE: {
        return {...state,fileLoader:true,newfileSearchDocsList: []};
      }
      case warrantyAction.NEW_FILE_LIST_SEARCH_SUCCESS: {
        return {...state,fileLoader:false, newfileSearchDocsList: payload,};
      }
      case warrantyAction.NEW_FILE_LIST_SEARCH_FAILURE: {
        return { ...state,fileLoader:false,newfileSearchDocsList: []};
      }

      case warrantyAction.WARRANTY_HISTORY_LIST_INITIATE: {
        return {...state,fileLoader:true,warrantyHistoryList: []};
      }
      case warrantyAction.WARRANTY_HISTORY_LIST_SUCCESS: {
        return {...state,fileLoader:false, warrantyHistoryList: payload};
      }
      case warrantyAction.WARRANTY_HISTORY_LIST_FAILURE: {
        return { ...state,fileLoader:false,warrantyHistoryList: []};
      }

      case warrantyAction.WARRANTY_PENDING_FILE_EVENTS_LIST_INITIATE: {
        return {...state,pendingLoader:true,warrantyPendingFileEventsList: []};
      }
      case warrantyAction.WARRANTY_PENDING_FILE_EVENTS_LIST_SUCCESS: {
        return {...state,pendingLoader:false, warrantyPendingFileEventsList: payload,};
      }
      case warrantyAction.WARRANTY_PENDING_FILE_EVENTS_LIST_FAILURE: {
        return { ...state,pendingLoader:false,warrantyPendingFileEventsList: []};
      }

      case warrantyAction.WARRANTY_VIEW_DOCS_INITIATE: {
        return {...state,warrantyViewDocs: []};
      }
      case warrantyAction.WARRANTY_VIEW_DOCS_SUCCESS: {
        return {...state, warrantyViewDocs: payload,};
      }
      case warrantyAction.WARRANTY_VIEW_DOCS_FAILURE: {
        return { ...state,warrantyViewDocs: []};
      }

      case warrantyAction.GET_DRAFT_WARRANTY_INITIATE: {
        return {...state, draftWarranty: []};
      }
      case warrantyAction.GET_DRAFT_WARRANTY_SUCCESS: {
        return {...state, draftWarranty: payload,};
      }
      case warrantyAction.GET_DRAFT_WARRANTY_FAILURE: {
        return { ...state,draftWarranty: []};
      }

      case warrantyAction.ALL_SYSTEM_URGENCY_LIST_INITIATE: {
        return {...state, allSystemUrgencyList: []};
      }
      case warrantyAction.ALL_SYSTEM_URGENCY_LIST_SUCCESS: {
        return {...state, allSystemUrgencyList: payload,};
      }
      case warrantyAction.ALL_SYSTEM_URGENCY_LIST_FAILURE: {
        return { ...state,allSystemUrgencyList: []};
      }

      case warrantyAction.ALL_URGENCY_LIST_INITIATE: {
        return {...state, allUrgencyList: []};
      }
      case warrantyAction.ALL_URGENCY_LIST_SUCCESS: {
        return {...state, allUrgencyList: payload,};
      }
      case warrantyAction.ALL_URGENCY_LIST_FAILURE: {
        return { ...state,allUrgencyList: []};
      }

      case warrantyAction.ALL_USER_URGENCY_LIST_INITIATE: {
        return {...state, allUserUrgencyList: []};
      }
      case warrantyAction.ALL_USER_URGENCY_LIST_SUCCESS: {
        return {...state, allUserUrgencyList: payload,};
      }
      case warrantyAction.ALL_USER_URGENCY_LIST_FAILURE: {
        return { ...state,allUserUrgencyList: []};
      }

      case warrantyAction.ALL_REQUEST_ITEM_LIST_INITIATE: {
        return {...state,itemLoader:true, allRequestItemList: []};
      }
      case warrantyAction.ALL_REQUEST_ITEM_LIST_SUCCESS: {
        return {...state,itemLoader:false, allRequestItemList: payload,};
      }
      case warrantyAction.ALL_REQUEST_ITEM_LIST_FAILURE: {
        return { ...state,itemLoader:false,allRequestItemList: []};
      }

      case warrantyAction.ALL_SERVICE_TYPE_LIST_INITIATE: {
        return {...state, allServiceTypeList: []};
      }
      case warrantyAction.ALL_SERVICE_TYPE_LIST_SUCCESS: {
        return {...state,allServiceTypeList: payload,};
      }
      case warrantyAction.ALL_SERVICE_TYPE_LIST_FAILURE: {
        return { ...state,allServiceTypeList: []};
      }

      case warrantyAction.ALL_ITEM_TYPE_LIST_INITIATE: {
        return {...state, allItemTypeList: []};
      }
      case warrantyAction.ALL_ITEM_TYPE_LIST_SUCCESS: {
        return {...state,allItemTypeList: payload,};
      }
      case warrantyAction.ALL_ITEM_TYPE_LIST_FAILURE: {
        return { ...state,allItemTypeList: []};
      }

      case warrantyAction.ALL_SYSTEM_SERVICE_TYPE_LIST_INITIATE: {
        return {...state, allSystemServiceTypeList: []};
      }
      case warrantyAction.ALL_SYSTEM_SERVICE_TYPE_LIST_SUCCESS: {
        return {...state,allSystemServiceTypeList: payload,};
      }
      case warrantyAction.ALL_SYSTEM_SERVICE_TYPE_LIST_FAILURE: {
        return { ...state,allSystemServiceTypeList: []};
      }

      case warrantyAction.ALL_USER_SERVICE_TYPE_LIST_INITIATE: {
        return {...state, allUserServiceTypeList: []};
      }
      case warrantyAction.ALL_USER_SERVICE_TYPE_LIST_SUCCESS: {
        return {...state,allUserServiceTypeList: payload,};
      }
      case warrantyAction.ALL_USER_SERVICE_TYPE_LIST_FAILURE: {
        return { ...state,allUserServiceTypeList: []};
      }

      case warrantyAction.ALL_SYSTEM_ITEM_TYPE_LIST_INITIATE: {
        return {...state, allSystemItemTypeList: []};
      }
      case warrantyAction.ALL_SYSTEM_ITEM_TYPE_LIST_SUCCESS: {
        return {...state,allSystemItemTypeList: payload,};
      }
      case warrantyAction.ALL_SYSTEM_ITEM_TYPE_LIST_FAILURE: {
        return { ...state,allSystemItemTypeList: []};
      }

      case warrantyAction.ALL_USER_ITEM_TYPE_LIST_INITIATE: {
        return {...state, allUserItemTypeList: []};
      }
      case warrantyAction.ALL_USER_ITEM_TYPE_LIST_SUCCESS: {
        return {...state,allUserItemTypeList: payload,};
      }
      case warrantyAction.ALL_USER_ITEM_TYPE_LIST_FAILURE: {
        return { ...state,allUserItemTypeList: []};
      }

      case warrantyAction.WORK_ORDER_REQUEST_ITEM_LIST_INITIATE: {
        return {...state, workOrderReqItemList: []};
      }
      case warrantyAction.WORK_ORDER_REQUEST_ITEM_LIST_SUCCESS: {
        return {...state,workOrderReqItemList: payload,};
      }
      case warrantyAction.WORK_ORDER_REQUEST_ITEM_LIST_FAILURE: {
        return { ...state,workOrderReqItemList: []};
      }

      case warrantyAction.LIST_OF_VENDOR_TYPES_INITIATE: {
        return {...state, vendorTypesList: []};
      }
      case warrantyAction.LIST_OF_VENDOR_TYPES_SUCCESS: {
        return {...state,vendorTypesList: payload};
      }
      case warrantyAction.LIST_OF_VENDOR_TYPES_FAILURE: {
        return { ...state,vendorTypesList: []};
      }

      case warrantyAction.VENDOR_LIST_INITIATE: {
        return {...state, vendorList: []};
      }
      case warrantyAction.VENDOR_LIST_SUCCESS: {
        return {...state,vendorList: payload};
      }
      case warrantyAction.VENDOR_LIST_FAILURE: {
        return { ...state,vendorList: []};
      }

      case warrantyAction.GET_ALL_WARRANTY_LIST_INITIATE: {
        return {...state,warrantyLoader:true, allWarrantyList: []};
      }
      case warrantyAction.GET_ALL_WARRANTY_LIST_SUCCESS: {
        return {...state,warrantyLoader:false,allWarrantyList: payload};
      }
      case warrantyAction.GET_ALL_WARRANTY_LIST_FAILURE: {
        return { ...state,warrantyLoader:false,allWarrantyList:[]};
      }

      case warrantyAction.GET_ALL_WORK_ORDER_LIST_INITIATE: {
        return {...state, workLoader:true,allWorkOrderList: []};
      }
      case warrantyAction.GET_ALL_WORK_ORDER_LIST_SUCCESS: {
        return {...state,workLoader:false,allWorkOrderList: payload};
      }
      case warrantyAction.GET_ALL_WORK_ORDER_LIST_FAILURE: {
        return { ...state,workLoader:false,allWorkOrderList: []};
      }

      case warrantyAction.SUPPORTING_DOCS_LIST_INITIATE: {
        return {...state, supportDocsLoader:true,supportDocsList: []};
      }
      case warrantyAction.SUPPORTING_DOCS_LIST_SUCCESS: {
        return {...state,supportDocsLoader:false,supportDocsList: payload};
      }
      case warrantyAction.SUPPORTING_DOCS_LIST_FAILURE: {
        return { ...state,supportDocsLoader:false,supportDocsList: []};
      }

      case warrantyAction.INSPECTOR_LIST_INITIATE: {
        return {...state, inspectorList: []};
      }
      case warrantyAction.INSPECTOR_LIST_SUCCESS: {
        return {...state,inspectorList: payload};
      }
      case warrantyAction.INSPECTOR_LIST_FAILURE: {
        return { ...state,inspectorList: []};
      }

      case warrantyAction.GET_DRAFT_WORK_ORDER_INITIATE: {
        return {...state, draftWorkOrder: []};
      }
      case warrantyAction.GET_DRAFT_WORK_ORDER_SUCCESS: {
        return {...state,draftWorkOrder: payload};
      }
      case warrantyAction.GET_DRAFT_WORK_ORDER_FAILURE: {
        return { ...state,draftWorkOrder: []};
      }

      case warrantyAction.GET_SELECTED_REQUEST_ITEMS_INITIATE: {
        return {...state, selectedRequestItems: []};
      }
      case warrantyAction.GET_SELECTED_REQUEST_ITEMS_SUCCESS: {
        return {...state,selectedRequestItems: payload};
      }
      case warrantyAction.GET_SELECTED_REQUEST_ITEMS_FAILURE: {
        return { ...state,selectedRequestItems: []};
      }

      case warrantyAction.GET_SELECTED_WARRANTY_REQUEST_ITEMS_INITIATE: {
        return {...state, selectedWarrantyRequestItems: []};
      }
      case warrantyAction.GET_SELECTED_WARRANTY_REQUEST_ITEMS_SUCCESS: {
        return {...state,selectedWarrantyRequestItems: payload};
      }
      case warrantyAction.GET_SELECTED_WARRANTY_REQUEST_ITEMS_FAILURE: {
        return { ...state,selectedWarrantyRequestItems: []};
      }

      case warrantyAction.GET_WARRANTY_JOBS_INITIATE: {
        return {...state, warrantyJobsList: []};
      }
      case warrantyAction.GET_WARRANTY_JOBS_SUCCESS: {
        return {...state,warrantyJobsList: payload};
      }
      case warrantyAction.GET_WARRANTY_JOBS_FAILURE: {
        return { ...state,warrantyJobsList: []};
      }

      case warrantyAction.GET_PRIMARY_CLIENT_BY_JOB_INITIATE: {
        return {...state, primaryClientsDatas: []};
      }
      case warrantyAction.GET_PRIMARY_CLIENT_BY_JOB_SUCCESS: {
        return {...state,primaryClientsDatas: payload};
      }
      case warrantyAction.GET_PRIMARY_CLIENT_BY_JOB_FAILURE: {
        return { ...state,primaryClientsDatas: []};
      }
    default:
      return state;
  }
}
