import React from 'react'

const BuilderMultiProjectLogin = () => {
  return (
    <div className="LoginArea">
    <div className="LoginBoxBig">
      <div className="row">
        <div className="col-md-5">
          <div className="LoginBoxLeft">
            <figure>
              <img src="images/login_background.png" />
            </figure>
          </div>
        </div>
        <div className="col-md-7">
          <div className="LoginBox mt-5">
            <h6>Multi-Family and Residential Platform</h6>
            <div className="TabContenArea">
              <div className="d-flex justify-content-between">
                <div>
                  <h3>Welcome Back</h3>
                  <p>John Doe</p>
                </div>
                <div className="BuilderLogo">
                  <img src="images/builder_logo.png" />
                </div>
              </div>
              <form>
                <div className="form-group">
                  <h4>Builder Name</h4>
                  <p>Builder Motto</p>
                </div>
                <div className="form-group">
                  <label>Project</label>
                  <div className="LoginSelectArea">
                    <span>
                      <img src="images/select_project.png" />
                    </span>
                    <select>
                      <option selected="">Select Project</option>
                      <option>Project 1</option>
                    </select>
                    <span>
                      <img src="images/down.png" />
                    </span>
                  </div>
                </div>
                <a className="Button mt-5" href="javascript:void(0);">
                  Login
                </a>
              </form>
              <div className="loginSocials">
                <a href="javascript:void(0);">
                  <img src="images/facebook_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/earth_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/mail_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/linkdin_red.png" />
                </a>
              </div>
              <div className="TabContentBottom d-flex justify-content-end">
                <div className="Logo">
                  <img src="images/Logo.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  )
}

export default BuilderMultiProjectLogin