import { reportsAction } from "../actionTypes";
const initialState={
    blocksActualCosts:[],
    blocksUnitsActualCosts:[],

    financialSalesCashDeposite:[],
    estimatiActual:[],

    statsPurchasingReport:[],
    statsBudgePoChanageOrder:[],
    statsFpoAnalysis:[],
    statsFpoAnalysisSummary:[],
    statsSales:[],
    statsWarranty:[],
    statsWarrantyChartFirst:[],
    statsWarrantyChartSecond:[],
    statsWarrantyChartThird:[],


    loader:false,
}


export default function reportsReducer(state = initialState, {type, payload}) {
    
    switch(type) {
      case reportsAction.FINANCIAL_SALES_CASH_DEPOSITE_INITIATE: {
        return {
          ...state, loader:true, financialSalesCashDeposite:[]
        }
      }
      case reportsAction.FINANCIAL_SALES_CASH_DEPOSITE_SUCCESS: {
        return {
          ...state, loader:false, financialSalesCashDeposite:payload
        }
      }
      case reportsAction.FINANCIAL_SALES_CASH_DEPOSITE_FAILURE: {
        return {
          ...state, loader:false,financialSalesCashDeposite:[]
        }
      }


      case reportsAction.BLOCK_ACTUAL_COSTS_INITIATE: {
        return {
          ...state, loader:true, blocksActualCosts:[]
        }
      }
      case reportsAction.BLOCK_ACTUAL_COSTS_SUCCESS: {
        return {
          ...state, loader:false, blocksActualCosts:payload
        }
      }
      case reportsAction.BLOCK_ACTUAL_COSTS_FAILURE: {
        return {
          ...state, loader:false,blocksActualCosts:[]
        }
      }
  


      case reportsAction.BLOCK_UNIT_ACTUAL_COSTS_INITIATE: {
        return {
          ...state, loader:true, blocksUnitsActualCosts:[]
        }
      }
      case reportsAction.BLOCK_UNIT_ACTUAL_COSTS_SUCCESS: {
        return {
          ...state, loader:false, blocksUnitsActualCosts:payload
        }
      }
      case reportsAction.BLOCK_UNIT_ACTUAL_COSTS_FAILURE: {
        return {
          ...state, loader:false,blocksUnitsActualCosts:[]
        }
      }



      case reportsAction.ESTIMATION_ACTUAL_INITIATE: {
        return {
          ...state, loader:true, estimatiActual:[]
        }
      }
      case reportsAction.ESTIMATION_ACTUAL_SUCCESS: {
        return {
          ...state, loader:false, estimatiActual:payload
        }
      }
      case reportsAction.ESTIMATION_ACTUAL_FAILURE: {
        return {
          ...state, loader:false,estimatiActual:[]
        }
      }


      // ==================== STATISTICAL REPORT ==========================



      
      case reportsAction.STATS_PURCHASING_FOR_UNIT_INITIATE: {
        return {
          ...state, loader:true, statsPurchasingReport:[]
        }
      }
      case reportsAction.STATS_PURCHASING_FOR_UNIT_SUCCESS: {
        return {
          ...state, loader:false, statsPurchasingReport:payload
        }
      }
      case reportsAction.STATS_PURCHASING_FOR_UNIT_FAILURE: {
        return {
          ...state, loader:false,statsPurchasingReport:[]
        }
      }



      case reportsAction.STATS_BUDGET_PO_CHANGE_ORDER_INITIATE: {
        return {
          ...state, loader:true, statsBudgePoChanageOrder:[]
        }
      }
      case reportsAction.STATS_BUDGET_PO_CHANGE_ORDER_SUCCESS: {
        return {
          ...state, loader:false, statsBudgePoChanageOrder:payload
        }
      }
      case reportsAction.STATS_BUDGET_PO_CHANGE_ORDER_FAILURE: {
        return {
          ...state, loader:false,statsBudgePoChanageOrder:[]
        }
      }





      case reportsAction.STATS_FPO_ANALYSIS_REASON_INITIATE: {
        return {
          ...state, loader:true, statsFpoAnalysis:[]
        }
      }
      case reportsAction.STATS_FPO_ANALYSIS_REASON_SUCCESS: {
        return {
          ...state, loader:false, statsFpoAnalysis:payload
        }
      }
      case reportsAction.STATS_FPO_ANALYSIS_REASON_FAILURE: {
        return {
          ...state, loader:false,statsFpoAnalysis:[]
        }
      }





      case reportsAction.STATS_FPO_ANALYSIS_SUMMARY_INITIATE: {
        return {
          ...state, loader:true, statsFpoAnalysisSummary:[]
        }
      }
      case reportsAction.STATS_FPO_ANALYSIS_SUMMARY_SUCCESS: {
        return {
          ...state, loader:false, statsFpoAnalysisSummary:payload
        }
      }
      case reportsAction.STATS_FPO_ANALYSIS_SUMMARY_FAILURE: {
        return {
          ...state, loader:false,statsFpoAnalysisSummary:[]
        }
      }




      case reportsAction.STATS_SALE_INITIATE: {
        return {
          ...state, loader:true, statsSales:[]
        }
      }
      case reportsAction.STATS_SALE_SUCCESS: {
        return {
          ...state, loader:false, statsSales:payload
        }
      }
      case reportsAction.STATS_SALE_FAILURE: {
        return {
          ...state, loader:false,statsSales:[]
        }
      }




      case reportsAction.STATS_WARRANTY_INITIATE: {
        return {
          ...state, loader:true, statsWarranty:[]
        }
      }
      case reportsAction.STATS_WARRANTY_SUCCESS: {
        return {
          ...state, loader:false, statsWarranty:payload
        }
      }
      case reportsAction.STATS_WARRANTY_FAILURE: {
        return {
          ...state, loader:false,statsWarranty:[]
        }
      }


      case reportsAction.STATS_WARRANTY_REPORT_FIRST_INITIATE: {
        return {
          ...state, loader:true, statsWarrantyChartFirst:[]
        }
      }
      case reportsAction.STATS_WARRANTY_REPORT_FIRST_SUCCESS: {
        return {
          ...state, loader:false, statsWarrantyChartFirst:payload
        }
      }
      case reportsAction.STATS_WARRANTY_REPORT_FIRST_FAILURE: {
        return {
          ...state, loader:false,statsWarrantyChartFirst:[]
        }
      }




      case reportsAction.STATS_WARRANTY_REPORT_SECOND_INITIATE: {
        return {
          ...state, loader:true, statsWarrantyChartSecond:[]
        }
      }
      case reportsAction.STATS_WARRANTY_REPORT_SECOND_SUCCESS: {
        return {
          ...state, loader:false, statsWarrantyChartSecond:payload
        }
      }
      case reportsAction.STATS_WARRANTY_REPORT_SECOND_FAILURE: {
        return {
          ...state, loader:false,statsWarrantyChartSecond:[]
        }
      }



      case reportsAction.STATS_WARRANTY_REPORT_THIRD_INITIATE: {
        return {
          ...state, loader:true, statsWarrantyChartThird:[]
        }
      }
      case reportsAction.STATS_WARRANTY_REPORT_THIRD_SUCCESS: {
        return {
          ...state, loader:false, statsWarrantyChartThird:payload
        }
      }
      case reportsAction.STATS_WARRANTY_REPORT_THIRD_FAILURE: {
        return {
          ...state, loader:false,statsWarrantyChartThird:[]
        }
      }









      
      default: 
      return state
  }
}