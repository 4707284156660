
export const dataURLtoBlob = (dataURL) => {
  const arr = dataURL?.split(',');
  const mime = arr?.[0]?.match(/:(.*?);/)[1];
  const bstr = atob(arr?.[1]);
  let n = bstr?.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr?.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export function convertToPNG(blob) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const img = new Image();
  return new Promise((resolve, reject) => {
      img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((pngBlob) => {
              resolve(pngBlob);
          }, 'image/png');
      };

      img.onerror = (error) => {
          reject(error);
      };

      img.src = URL.createObjectURL(blob);
  });
}


export  const filterData = (data) => {
  return data?.map((module) => {
    const subPermissions = module?.SubPermissions?.map((sub) => ({
      subModuleName: sub?.subModuleName,
      view: sub?.view??false,
      full: sub?.full??false,
    }));

    return {
      moduleName: module?.moduleName,
      isFunction: module?.isFunction??false,
      view: module?.view??false,
      full: module?.full??false,
      subPermissions: subPermissions?.length ? subPermissions : undefined,
    };
  });
};

