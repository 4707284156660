import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { Modal } from "react-bootstrap";
import { getTimeZoneList } from "../redux/action/authAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { SubscriptionPlanListAction } from "../redux/action/authAction";
import { checkArray } from "../utils/CheckType";
import {
  addCustomerCode,
  addCustomerRequest,
  addCustomerRequestAction,
  addHelpAndSupportAction,
  addHelpTabAction,
  blockCustomerAction,
  changeHelpTabAction,
  customerCodeListAction,
  customerListAction,
  customerRequestListAction,
  deleteCustomerAction,
  getCustomerDemoRequestListAction,
  helpDetailsAction,
  helpListAction,
  helpTabListAction,
  historyListAction,
  removeCustomerDemoAction,
  removeHelpTabAction,
  remvoeCustomerRequestAction,
  schdeuleCustomerDemoAction,
  sendForSignatureAction,
  subscriptionHistoryListAction,
  updateCustomerCodeAction,
  updateHelpSupportAction,
  updateHelpTabAction,
} from "../redux/action/systemAdministrationAction";
import { errorToast, sucessToast } from "../utils/toast";
import { DownloadTableExcel } from "react-export-table-to-excel";
import Loader from "./Loader";
import NoDataFound from "./Nodatafound";
import SignaturePad from "react-signature-canvas";
import {
  addSupportingDocsAction,
  getAllContractsListAction,
} from "../redux/action/saleAction";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import {
  AddDiscussionAction,
  AddReplyAction,
  discussionListAction,
} from "../redux/action/constructionsAction";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { compareNumbers } from "@fullcalendar/core/internal.js";
import { getAllTaxProfileAction } from "../redux/action/jobAction";
import { getMasterCostCategoryAction } from "../redux/action/costProfileAction";
import { companyDetailsAction } from "../redux/action/companyAction";
import IbuildAdminHeader from "./IbuildAdmin/IbuildAdminHeader";
import IbuildAdminSidenav from "./IbuildAdmin/IbuildAdminSidenav";
import IbuldAdminSubscription from "./IbuildAdmin/IbuldAdminSubscription";
const initLeadState = {
  companyId: "",
  clientName: "",
  phoneNumber: "",
  availability: "",
  contactPrefrence: "",
  languagePrefrence: "",
  issueDetailsType: "",
  severity: "",
  module: "",
  description: "",
  problemStartDate: "",
  time: "",
  timeType: "",
  timeZone: "",
  errorMessage: "",
  applicationLink: "",
  helpAndSupportError: {},
  email: "",
  supportingDocFiles: [],
  falseImage: "",
  phonecode: "",
  historyListData: [],
  companyName: "",
  applicationPath: "",
  helpMessage: "",
  messageType: "",
  updateTabHelpModal: false,
  contactId: 1,
  dateOptionOne: "",
  dateOptionTwo: "",
  dateOptionThree: "",
  task: false,
  microsoft: false,
  itinenary: false,
  subDiscount: "",
  fullAccess: false,
  code: "",
  // viewContractCodeModal:true,
  CompanyId: "",
  PlanId: "",
  planName: "",
  contractDuration: "",
  paymentOption: "",
  billingOption: "",
  projectEntityOption: "",
  signeeName: "",
  signeeEmail: "",
  draft: false,
  sendForSignature: false,
  tax_location: "",
  tax_name: "",
  tax_p: "",
  payment: [],
  paymentDate: "",
  paymentAmount: "",
  paymentAfterTax: "",
  paymentStatus: "",
  paymentDates: [],

  hideShowId: "",
  fixedUnitarySubscriptionValue: "",
  unlimitedProjectsForFixedValue: "",
  variableSubscriptionValue: "",
  contractCodeId: "",
  planListAmount: "",
  gstamount: "",
  totalContractAmount: "",
  signature: "",
};
const modalInitState = {
  addSupportModal: false,
  addSupportingDocsModal: false,
  supportingDocsListModal: false,
  supportDetailsModal: false,
  updateSupportModal: false,
  historyModal: false,
  modalId: "",
  addHelpTabModal: false,
  updateTabHelpModal: false,
  customersDemoRequests: false,
  scheduleDemoModal: false,
  addCustomerModal: false,
  updatecontractcodeModal: false,
  viewContractCodeModal: false,
  contractRequestListingModal: false,
  addContractRequestListingModal: false,
  modalId: "",
  addContractRequestModal: false,
  subscriptionModal: false,
  sendSignatureModal: false,
  subscriptionHistoryModal: false,
};

function Systemadministration() {
  const [iLeadState, updateLeadState] = useState(initLeadState);
  const [iModalState, updateModalState] = useState(modalInitState);
  const [showModalCheck, setShowModalCheck] = useState(false);
  const [contractIdSave, setContractIdSave] = useState(false);
  const [activeLinks, setActiveLinks] = useState('');
  const {
    addSupportModal,
    addSupportingDocsModal,
    updateSupportModal,
    historyModal,
    modalId,
    updateTabHelpModal,
    customersDemoRequests,
    addContractRequestModal,
    contractRequestListingModal,
    supportingDocsListModal,
    supportDetailsModal,
    addHelpTabModal,
    scheduleDemoModal,
    addCustomerModal,
    updatecontractcodeModal,
    subscriptionModal,
    sendSignatureModal,
    subscriptionHistoryModal,
  } = iModalState;
  const helpListRef = useRef();
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const supportingDocsRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { discussionList } = useSelector((state) => state.constructionReducer);
  const {
    projectListData,
    foundationListData,
    homeListData,
    taxProfileListData,
    residentialDetailsData,
  } = useSelector((state) => state.jobReducer);
  const [discussionState, updateDiscussionState] = useState({
    jobId: "",
    message: "",
    discussionError: "",
  });
  const [replyState, updateReplyState] = useState({
    messageId: "",
    jobId: "",
    message: "",
    replyError: "",
  });
  const [replyField, updateReplyField] = useState({ flag: false, index: "" });
  const {
    countryData,
    cityData,
    stateData,
    timeZoneData,
    planListData,
    messageListData,
  } = useSelector((state) => {
    return state.authReducer;
  });
  const configObject = {
    // CKEditor 5 configuration options
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "blockQuote",
      "undo",
      "redo",
    ],
    // Add more configuration options as needed
  };

  console.log("messageListData", messageListData);

  const {
    helpListData,
    customerHistoryListData,
    helptabListData,
    helpHistoryListData,
    customerRequestListData,
    loader,
    helpDetailsData,
    customerDemoRequestListData,
    customerListData,
    masterCostCategoryListData,
    customerCodeListData,
  } = useSelector((state) => state.systemAdministrationReducer);

  const [phonecode, setPhoneCode] = useState("");
  const {
    companyId,
    clientName,
    phoneNumber,
    historyListData,
    availability,
    contactPrefrence,
    languagePrefrence,
    issueDetailsType,
    severity,
    module,
    description,
    problemStartDate,
    time,
    timeType,
    timeZone,
    errorMessage,
    applicationLink,
    helpAndSupportError,
    email,
    supportingDocFiles,
    falseImage,
    applicationPath,
    helpMessage,
    messageType,
    contactId,
    dateOptionOne,
    dateOptionTwo,
    dateOptionThree,
    task,
    microsoft,
    itinenary,
    subDiscount,
    fullAccess,
    code,
    viewContractCodeModal,
    CompanyId,
    PlanId,
    planName,
    contractDuration,
    paymentOption,
    billingOption,
    projectEntityOption,
    signeeName,
    signeeEmail,
    draft,
    sendForSignature,
    payment,
    paymentDates,
    tax_location,
    tax_name,
    tax_p,
    paymentDate,
    paymentAmount,
    paymentAfterTax,
    paymentStatus,
    hideShowId,
    fixedUnitarySubscriptionValue,
    unlimitedProjectsForFixedValue,
    variableSubscriptionValue,
    contractCodeId,
    planListAmount,
    gstamount,
    totalContractAmount,
    signature,
  } = iLeadState;

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    updateLeadState({ ...iLeadState, [name]: value });
  };

  const showSupportModal = () => {
    updateModalState({
      ...iModalState,
      addSupportModal: true,
    });
  };

  const hideSupportModal = () => {
    updateModalState({
      ...iModalState,
      addSupportModal: false,
    });
  };

  const showAddCustomerModal = () => {
    updateModalState({
      ...iModalState,
      addCustomerModal: true,
    });
  };

  const hideAddCustomerModal = () => {
    updateModalState({
      ...iModalState,
      addCustomerModal: false,
    });
  };

  const showAddSupportingDocsModal = () => {
    updateModalState({
      ...iModalState,
      addSupportingDocsModal: true,
    });
  };

  const hideAddSupportingDocsModal = () => {
    updateModalState({
      ...iModalState,
      addSupportingDocsModal: false,
    });
  };

  const showSupportingListModal = () => {
    updateModalState({
      ...iModalState,
      supportingDocsListModal: true,
    });
  };

  // console.log("supportingDocsListModal", supportingDocFiles)
  const hideSupportingListModal = () => {
    updateModalState({
      ...iModalState,
      supportingDocsListModal: false,
    });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    updateLeadState({ ...iLeadState, [name]: value });
  };

  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    updateLeadState({ ...iLeadState, [name]: result });
  };

  console.log(activeLinks, "------->");

  const handleCheckbox = (e) => {
    const { name, value, checked } = e.target;
    if (checked == true) {
      updateLeadState({ ...iLeadState, [name]: checked });
    } else {
      updateLeadState({ ...iLeadState, [name]: checked });
    }
  };

  useEffect(() => {
    dispatch(SubscriptionPlanListAction());
    dispatch(getTimeZoneList());
    dispatch(customerListAction());
    dispatch(helpListAction());
    dispatch(helpTabListAction());
    dispatch(getAllTaxProfileAction());
    dispatch(customerCodeListAction());
  }, []);

  const addSupportFunc = (e) => {
    e.preventDefault();
    let data = {
      companyId: iBuildLocalData?.user?.CompanyId,
      companyName: iBuildLocalData?.companies[0]?.name,
      clientName,
      phoneNumber,
      phonecode,
      availability,
      contactPrefrence,
      languagePrefrence,
      issueDetailsType,
      severity,
      module,
      description,
      problemStartDate,
      time,
      timeType,
      timeZone,
      errorMessage,
      applicationLink,
      supportingDocFiles,
    };
    dispatch(addHelpAndSupportAction(data))
      .then((res) => {
        if (res.status === 400) {
          errorToast(res.data.message);
        } else {
          sucessToast("Support added successfully");
          hideSupportModal();
          dispatch(helpListAction());
        }
        dispatch(helpListAction());
      })
      .catch((err) => {
        errorToast("Please enter the proper format file");
      });
  };

  const handlePhoneInputChange = (value, country) => {
    setPhoneCode(`+${country.dialCode}`);
  };

  const handleInputFile = (e) => {
    const { name, value } = e.target;
    const file = e.target.files[0];
    if (file) {
      updateLeadState({
        ...iLeadState,
        [name]: file,
      });
    }
    e.target.files = null;
  };

  const handleFileChange = (event, index) => {
    const { name, value } = event.target;
    const files = event.target.files;
    const updatedFiles = [...supportingDocFiles];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile = { file: file };
      updatedFiles.push(newFile);
    }
    updateLeadState({ ...iLeadState, [name]: updatedFiles });
  };

  const addSupportingDocsFunc = () => {
    dispatch(addSupportingDocsAction(supportingDocFiles)).then((res) => {
      console.log("ressssssssss,", res);
    });
  };

  const hideSupportDetailsModal = (d) => {
    updateModalState({
      ...iModalState,
      supportDetailsModal: false,
    });
  };

  const showSupportDetailsModal = (id) => {
    dispatch(helpDetailsAction(id));
    updateModalState({
      ...iModalState,
      supportDetailsModal: true,
      modalId: id,
    });
  };

  const hideUpdateSupportModal = (d) => {
    updateModalState({
      ...iModalState,
      updateSupportModal: false,
    });
  };

  const showUpdateSupportModal = (id) => {
    dispatch(helpDetailsAction(id));
    updateLeadState({
      ...iLeadState,
      companyId: helpDetailsData[0]?.companyId,
      clientName: helpDetailsData[0]?.clientName,
      phoneNumber: helpDetailsData[0]?.phoneNumber,
      phonecode: helpDetailsData[0]?.phonecode,
      availability: helpDetailsData[0]?.availability,
      contactPrefrence: helpDetailsData[0]?.contactPrefrence,
      languagePrefrence: helpDetailsData[0]?.languagePrefrence,
      issueDetailsType: helpDetailsData[0]?.issueDetailsType,
      severity: helpDetailsData[0]?.severity,
      module: helpDetailsData[0]?.phoneNumber,
      description: helpDetailsData[0]?.description,
      problemStartDate: helpDetailsData[0]?.problemStartDate,
      time: helpDetailsData[0]?.time,
      timeType: helpDetailsData[0]?.timeType,
      timeZone: helpDetailsData[0]?.timeZone,
      errorMessage: helpDetailsData[0]?.errorMessage,
      applicationLink: helpDetailsData[0]?.applicationLink,
      supportingDocFiles: helpDetailsData[0]?.supportingDocFiles,
    });
    updateModalState({
      ...iModalState,
      updateSupportModal: true,
      modalId: id,
    });
  };

  const hideHistoryModal = (d) => {
    updateModalState({
      ...iModalState,
      historyModal: false,
    });
  };

  const showHistoryModal = (id) => {
    dispatch(historyListAction(id)).then((Res) => {
      updateLeadState({ ...iLeadState, historyListData: Res.data });
    });
    updateModalState({
      ...iModalState,
      historyModal: true,
    });
  };

  let handleValidationDiscussion = () => {
    let discussionError = "";
    let formIsValid = true;

    if (discussionState.message == "") {
      discussionError = "* Please enter comment";
      formIsValid = false;
    }

    updateDiscussionState({
      ...discussionState,
      discussionError: discussionError,
    });
    return formIsValid;
  };

  const handleAddDiscussion = () => {
    let formIsValid = handleValidationDiscussion();
    if (formIsValid) {
      dispatch(
        AddDiscussionAction({
          message: discussionState.message,
          helpId: helpDetailsData[0]?.id,
          type: "Help",
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(
              discussionListAction({
                helpId: helpDetailsData[0]?.id,
                type: "Help",
              })
            );

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleAddReply = () => {
    let formIsValid = true;
    if (formIsValid) {
      dispatch(
        AddReplyAction({
          message: replyState.message,
          helpId: helpDetailsData[0]?.id,
          type: "Help",
          messageId: replyState.messageId,
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(
              discussionListAction({
                helpId: helpDetailsData[0]?.id,
                type: "Help",
              })
            );
            updateReplyState({
              messageId: "",
              jobId: "",
              message: "",
              replyError: "",
            });

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const updatehelpSupportFunc = () => {
    let data = {
      id: modalId,
      companyId: iBuildLocalData?.user?.CompanyId,
      companyName: iBuildLocalData?.companies[0]?.name,
      clientName,
      phoneNumber,
      phonecode,
      availability,
      contactPrefrence,
      languagePrefrence,
      issueDetailsType,
      severity,
      module,
      description,
      problemStartDate,
      time,
      timeType,
      timeZone,
      errorMessage,
      applicationLink,
      supportingDocFiles,
      // helpAndSupportError
    };
    dispatch(updateHelpSupportAction(data)).then((res) => {
      sucessToast("Supprt updated successfully");
      hideUpdateSupportModal();
      dispatch(helpListAction());
    });
  };

  const showHelpTabModal = () => {
    updateModalState({ ...iModalState, addHelpTabModal: true });
  };

  const hdeHelpTabModal = () => {
    updateModalState({ ...iModalState, addHelpTabModal: false });
  };

  const showUpdateHelpTabModal = (id, applicationPath, helpMessage) => {
    updateModalState({ ...iModalState, updateTabHelpModal: true, modalId: id });
    updateLeadState({ ...iLeadState, helpMessage, applicationPath });
  };

  const hdeUpdateHelpTabModal = () => {
    updateModalState({ ...iModalState, updateTabHelpModal: false });
  };

  const addHelpTabFunc = (type) => {
    let data = {
      companyId: iBuildLocalData?.user?.CompanyId,
      companyName: iBuildLocalData?.companies[0]?.name,
      messageType: type,
      applicationPath,
      helpMessage,
      pageType:
        applicationPath.split("/")[
          parseInt(applicationPath.split("/").length) - 1
        ],
    };
    dispatch(addHelpTabAction(data)).then((res) => {
      console.log("resssssss", res);
      hdeHelpTabModal();
      dispatch(helpTabListAction());
    });
  };

  const removeTabHelpFunc = (id) => {
    dispatch(removeHelpTabAction(id)).then((res) => {
      console.log(("resssssss", res));
      dispatch(helpTabListAction());
    });
  };

  const changeHelpTabStatus = (id, status) => {
    let data = {
      id,
      status,
    };
    dispatch(changeHelpTabAction(data)).then((res) => {
      console.log(("resssssss", res));
      dispatch(helpTabListAction());
    });
  };

  const updateTabHelpFunc = (type) => {
    let data = {
      id: modalId,
      companyId: iBuildLocalData?.user?.CompanyId,
      companyName: iBuildLocalData?.companies[0]?.name,
      messageType: type,
      applicationPath,
      helpMessage,
    };
    dispatch(updateHelpTabAction(data)).then((res) => {
      console.log(("resssssss", res));
      dispatch(helpTabListAction());
    });
  };
  // console.log("getCustomerDemoRequestListAction" ,customerDemoRequestListData)

  const showCustomerDemoModal = () => {
    dispatch(getCustomerDemoRequestListAction());
    updateModalState({ ...iModalState, customersDemoRequests: true });
  };

  const hideCustomerDemoModal = () => {
    updateModalState({ ...iModalState, customersDemoRequests: false });
  };

  const showScheduleDemoModal = (id) => {
    updateModalState({
      ...iModalState,
      scheduleDemoModal: true,
      customersDemoRequests: false,
      modalId: id,
    });
  };

  const hideScheduleDemoModal = () => {
    updateModalState({
      ...iModalState,
      scheduleDemoModal: false,
      customersDemoRequests: true,
    });
  };

  const removeCustomerDemoFunc = (id) => {
    dispatch(removeCustomerDemoAction(id)).then((res) => {
      console.log(("resssssss", res));
      sucessToast("Customer demo removed successfully");
      dispatch(getCustomerDemoRequestListAction());
    });
  };

  const addScheduleDemoFunc = () => {
    let data = {
      contactId: modalId,
      dateOptionOne,
      dateOptionTwo,
      dateOptionThree,
      task,
      itinenary,
    };
    console.log("datatata", data);
    dispatch(schdeuleCustomerDemoAction(data)).then((res) => {
      console.log("resssssss", res);
      sucessToast("Demo scheduled successfully");
    });
  };

  const addCustomerCodeFunc = (e) => {
    e.preventDefault();
    let data = {
      companyId: 4,
      subDiscount,
      fullAccess,
      code,
    };
    dispatch(addCustomerCode(data))
      .then((res) => {
        console.log("res", res);
        sucessToast("Customer code added successfully");
        hideAddCustomerModal();
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const blockCompanyFunc = (id) => {
    let data = {
      companyId: id,
      blockStatus: false,
    };
    dispatch(blockCustomerAction(data))
      .then((res) => {
        console.log("resss", res);
        sucessToast("Customer blocked sucessfully");
      })
      .catch((err) => {
        errorToast("Internal server error");
        console.log("errr", err);
      });
  };

  const showDeleteCompanyFunc = (id) => {
    dispatch(deleteCustomerAction(id))
      .then((res) => {
        console.log("resss", res);
        dispatch(customerListAction());
        sucessToast("Customer deleted  sucessfully");
      })
      .catch((err) => {
        console.log("errr", err);
        errorToast("Internal server error");
      });
  };

  const showUpdateContractModal = (id) => {
    updateModalState({
      ...iModalState,
      updatecontractcodeModal: true,
      modalId: id,
    });
  };
  const hideUpdateContractModal = (id) => {
    updateModalState({
      ...iModalState,
      updatecontractcodeModal: false,
      modalId: "",
    });
  };

  const updateContractCodeFunc = (e) => {
    e.preventDefault();
    let data = {
      companyId: 4,
      subDiscount,
      fullAccess,
      code,
    };
    dispatch(updateCustomerCodeAction(data))
      .then((res) => {
        console.log("resssssss", res);
        sucessToast("Contract updated successfully");
      })
      .catch((err) => {
        console.log("errrr", err);
        errorToast("Internal server error");
      });
  };

  const showContractCodeDetailsModal = (ele) => {
    console.log("eleleele", ele);
    updateLeadState({
      ...iLeadState,
      subDiscount: ele?.subDiscount,
      code: ele?.code,
      comapnyId: ele?.companyId,
      fullAccess: ele?.fullAccess,
      viewContractCodeModal: true,
    });
  };

  const hideContractCodeDetailsModal = (ele) => {
    updateLeadState({ ...iLeadState, viewContractCodeModal: false });
  };

  const showContactRequestListingModal = () => {
    dispatch(customerRequestListAction());
    updateModalState({ ...iModalState, contractRequestListingModal: true });
  };

  const hideContactRequestListingModal = () => {
    updateModalState({ ...iModalState, contractRequestListingModal: false });
  };

  const showAddContactRequestModal = (id) => {
    dispatch(getAllTaxProfileAction());
    updateModalState({
      ...iModalState,
      addContractRequestModal: true,
      contractRequestListingModal: false,
      modalId: id,
    });
  };

  const hideAddContactRequestModal = () => {
    updateModalState({
      ...iModalState,
      addContractRequestModal: false,
      contractRequestListingModal: true,
    });
  };

  const addTaxFunc = (e) => {
    e.preventDefault();
    let initData = {
      tax_location,
      tax_p,
      tax_name,
    };
    let data = [...payment];

    data.push(initData);
    updateLeadState({ ...iLeadState, payment: data });
  };

  const removeTaxFunc = (i) => {
    let data = [...payment];
    data.splice(i, 1);
    updateLeadState({ ...iLeadState, payment: data });
  };

  const addPaymentFunc = (e) => {
    e.preventDefault();
    let initData = {
      paymentAfterTax,
      paymentDate,
      paymentStatus,
      paymentAmount,
    };
    let data = [...paymentDates];

    data.push(initData);
    updateLeadState({ ...iLeadState, paymentDates: data });
  };

  const removepaymentFunc = (i) => {
    let data = [...paymentDates];
    data.splice(i, 1);
    updateLeadState({ ...iLeadState, paymentDates: data });
  };
  const addCustomerRequestFun = (type) => {
    let data = {
      CompanyId: modalId,
      PlanId,
      planName,
      contractDuration,
      paymentOption,
      billingOption,
      projectEntityOption,
      signeeName,
      signeeEmail,
      draft: type === "DRAFT" ? true : false,
      sendForSignature,
      payment,
      paymentDates,
      variableSubscriptionValue,
      fixedUnitarySubscriptionValue,
      unlimitedProjectsForFixedValue,
      contractCodeId,
      sendForSignature: false,
    };
    console.log("dataagdsgdfgdfsgfd", data);
    dispatch(addCustomerRequestAction(data))
      .then((res) => {
        console.log("resssssss", res?.data?.data?.id);
        sucessToast("Customer request added successfully");
        // hideAddContactRequestModal()
        dispatch(customerRequestListAction());
        setShowModalCheck(true);
        setContractIdSave(res?.data?.data?.id);
      })
      .catch((err) => {
        console.log("errrr ", err);
      });
  };

  console.log("contracttaveeid", contractIdSave);
  const [companydata, setCompanyData] = useState([]);

  const showAddSubscriptionModal = () => {
    dispatch(companyDetailsAction(modalId)).then((res) => {
      console.log("rss", res);
      setCompanyData(res.company);
    });
    updateModalState({ ...iModalState, subscriptionModal: true });
  };

  const hideAddSubscriptionModal = () => {
    updateModalState({ ...iModalState, subscriptionModal: false });
  };

  const sendForSignatureFunc = () => {
    dispatch(sendForSignatureAction(contractIdSave, modalId)).then((res) => {
      console.log("ressssss", res);
      sucessToast("Customer request sent for signature successfully");
      hideAddContactRequestModal();
    });
  };

  const removeRequestFunc = (id) => {
    dispatch(remvoeCustomerRequestAction(id)).then((res) => {
      console.log("ressssss", res);
      sucessToast("Customer request removed signature successfully");
    });
  };

  const showSendSignatureMOdal = () => {
    updateModalState({ ...iModalState, sendSignatureModal: true });
  };

  const hideSendSignatureMOdal = () => {
    updateModalState({ ...iModalState, sendSignatureModal: false });
  };

  const handleJobListByProject = (Id, Index) => {
    updateLeadState((prev) => ({
      ...prev,
      hideShowId: prev.hideShowId == Index ? -1 : Index,
    }));
    // updateState((prev)=>{
    //   ...prev,
    //   resdentalId:Index}
    // })
  };
  useEffect(() => {
    updateLeadState({
      ...iLeadState,
      planName: planListData?.plans?.find((ele) => ele?.id == PlanId)?.planName,
      contractDuration: customerCodeListData?.data?.find(
        (ele) => ele?.id == contractCodeId
      )?.subDiscount,
      planListAmount: planListData?.plans?.find((ele) => ele?.id == PlanId)
        ?.price,
      totalContractAmount:
        planListData?.plans?.find((ele) => ele?.id == PlanId)?.price ??
        0 +
          customerCodeListData?.data?.find((ele) => ele?.id == contractCodeId)
            ?.subDiscount ??
        0,
      gstamount:
        (planListData?.plans?.find((ele) => ele?.id == PlanId)?.price +
          contractDuration) *
          (5 / 100) ?? 0,
    });
  }, [contractCodeId, PlanId]);

  const signCanvas = useRef({});
  const intialCanvas = useRef({});
  const clearSignature = () => {
    signCanvas.current.clear();
  };
  const clearInitials = () => {
    intialCanvas.current.clear();
  };

  const showSubscriptionHistoryModal = (id) => {
    dispatch(subscriptionHistoryListAction(contractIdSave));
    updateModalState({
      ...iModalState,
      subscriptionHistoryModal: true,
      subscriptionModal: false,
    });
  };

  const hideSubscriptionHistoryModal = () => {
    updateModalState({
      ...iModalState,
      subscriptionHistoryModal: false,
      subscriptionModal: true,
    });
  };

  const handleActiveHeaderLinks = (anas) => {
    setActiveLinks(anas)
    console.log(anas, "anas active links------>");
  }

  return (
    <>
      <IbuildAdminHeader />
      <IbuildAdminSidenav activeLinks={activeLinks}/>
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="MainTitleBox">
            <div className="MainTitle">
              <h2>System Administration</h2>
            </div>
          </div>
          <div className="TitleBox justify-content-end">
            <div className="TodosButtons">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    className="nav-link px-2"
                    data-toggle="tab"
                    href="#CustomersInformationTab"
                    onClick={(e) => setActiveLinks('Customers-Information')}
                  >
                    <img src="images/customer_information.png" />
                    Customers Information<span>3</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link px-2"
                    data-toggle="tab"
                    href="#HelpMessagesTab"
                    onClick={(e) => setActiveLinks('Help-Messages')}
                  >
                    <img src="images/help_message.png" />
                    Help Messages<span>3</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link px-2"
                    data-toggle="tab"
                    href="#SubscriptionPlansTab"
                    onClick={(e) => setActiveLinks('Subscription-Plans')}
                  >
                    <img src="images/subscription_plan.png" />
                    Subscription Plans<span>3</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link px-2 active"
                    data-toggle="tab"
                    href="#CustomerSupportTab"
                    onClick={(e) => setActiveLinks('Customer-Support')}
                  >
                    <img src="images/customer_support.png" />
                    Customer Support<span>3</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link px-2"
                    data-toggle="tab"
                    href="#ChatMessageTab"
                    onClick={(e) => setActiveLinks('Chat-Messages')}
                  >
                    <img src="images/customer_support.png" />
                    Chat Messages<span>3</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="tab-content">
            <div className="tab-pane fade" id="CustomersInformationTab">
              <div className="Tableheader">
                <div className="d-flex align-items-center">
                  <h6>Customers</h6>
                  <div className="TodosButtons mt-0 d-flex">
                    <a
                      href="javascript:void(0)"
                      onClick={showContactRequestListingModal}
                    >
                      <img src="images/new_contracts_request.png" />
                      New Contract Requests<span>3</span>
                    </a>
                  </div>
                  <div class="TodosButtons mt-0">
                    <a class="Create" onClick={showCustomerDemoModal}>
                      Customer Demo Request<span>3</span>
                    </a>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="TodosButtons mt-0 d-flex">
                    <a href="javascript:void(0)" onClick={showAddCustomerModal}>
                      <img src="images/create_contract.png" />
                      Create Contract Code
                    </a>
                  </div>
                  <div className="NewActions">
                    <a href="javascript:void(0);" title="pdf">
                      <img src="images/icon-53.png" />
                    </a>
                    <a href="javascript:void(0);" title="download">
                      <img src="images/download.png" />
                    </a>
                    <a href="javascript:void(0);" title="settings">
                      <img src="images/setting.png" />
                    </a>
                  </div>
                </div>
              </div>
              {/* change order table start  */}
              <div
                className="JobTableCommon JobTableCommon"
                style={{ display: "block" }}
              >
                <table className="table">
                  <thead>
                    <tr>
                      <th>Company ID</th>
                      <th>Company Name</th>
                      <th>Subscription Plan</th>
                      <th>Payment Type</th>
                      <th>Billing Option</th>
                      <th>Contract Code</th>
                      <th>Status</th>
                      <th>Payment Status</th>
                      <th>Next Payment</th>
                      <th>Activation Date</th>
                      <th>Active Projects</th>
                      <th>Active Projects as an Entity</th>
                      <th>Active Jobs</th>
                      <th>Active Users</th>
                      <th>Warranty Jobs</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="addClasss">
                    {checkArray(customerListData?.data) ? (
                      customerListData?.data?.map((ele, i) => {
                        return (
                          <>
                            <tr>
                              <td className="hidtableshowChangeOrder">
                                <div
                                  onClick={() =>
                                    handleJobListByProject(ele.id, i)
                                  }
                                  className="Action"
                                >
                                  {ele?.id}{" "}
                                  <img
                                    src={"images/icon-34.png"}
                                    style={{
                                      transform: `rotate(${
                                        hideShowId === i ? "180deg" : "0deg"
                                      })`,
                                    }}
                                  />
                                </div>
                                {/* <div className="Action">
                   {ele?.id} <img src="images/icon-34.png" className="ml-2" />
                 </div> */}
                              </td>
                              <td className="hidtableshowChangeOrder">
                                {ele?.companyName}
                              </td>
                              <td className="hidtableshowChangeOrder">
                                {ele?.subscriptionPlan}
                              </td>
                              <td className="hidtableshowChangeOrder">
                                {ele?.paymentType}
                              </td>
                              <td className="hidtableshowChangeOrder">
                                {ele?.billing_option}
                              </td>
                              <td className="hidtableshowChangeOrder">
                                {"static status"}
                              </td>

                              <td className="hidtableshowChangeOrder">
                                Received
                              </td>
                              <td className="hidtableshowChangeOrder">
                                {ele?.payment_status}
                              </td>
                              <td className="hidtableshowChangeOrder">
                                {ele?.next_pay}
                              </td>
                              <td className="hidtableshowChangeOrder">
                                {ele?.activation_date}
                              </td>
                              <td className="hidtableshowChangeOrder">
                                {ele?.active_p}
                              </td>
                              <td className="hidtableshowChangeOrder">
                                {ele?.active_pa}
                              </td>
                              <td className="hidtableshowChangeOrder">
                                {ele?.active_job}
                              </td>
                              <td className="hidtableshowChangeOrder">
                                {ele?.active_user}
                              </td>
                              <td className="hidtableshowChangeOrder">
                                {ele?.warranty_jobs}
                              </td>
                              <td>
                                <div className="TableThreeDots">
                                  <ul className="">
                                    <li className="dropdown">
                                      <a
                                        className=""
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <img src="images/dots.png" />
                                      </a>
                                      <ol className="dropdown-menu dropdown-menuwidth2">
                                        <li>
                                          <a
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            data-target="#CreateContract"
                                            data-dismiss="modal"
                                          >
                                            <img src="images/Action-1.png" />
                                            Edit Contract
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            onClick={() =>
                                              blockCompanyFunc(ele?.id)
                                            }
                                            href="javascript:void(0);"
                                          >
                                            <img src="images/block.png" />
                                            Block Company
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            onClick={() =>
                                              showUpdateContractModal(ele?.id)
                                            }
                                            href="javascript:void(0);"
                                          >
                                            <img src="images/update_contract.png" />
                                            Update Contract Code
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            onClick={() =>
                                              showContractCodeDetailsModal(ele)
                                            }
                                            href="javascript:void(0);"
                                          >
                                            <img src="images/view_contract.png" />
                                            View Contract
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            onClick={() =>
                                              showDeleteCompanyFunc(ele?.id)
                                            }
                                            href="javascript:void(0);"
                                          >
                                            <img src="images/dropdown-delete.png" />
                                            Delete Company
                                          </a>
                                        </li>
                                      </ol>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={15} className="padding_none">
                                <div
                                  className="JobBoxTable JobBoxTableHeight"
                                  style={{
                                    display:
                                      hideShowId === i ? "block" : "none",
                                  }}
                                >
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Project Id</th>
                                        <th>Project Name</th>
                                        <th>Option</th>
                                        <th>Payment Type</th>
                                        <th>Billing Option</th>
                                        <th>Contract Code</th>
                                        <th>Status</th>
                                        <th>Payment Status</th>
                                        <th>Next Payment</th>
                                        <th>Activation Date</th>
                                        <th>Active Jobs</th>
                                        <th>Active Users</th>
                                        <th />
                                        <th />
                                        <th />
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {checkArray(ele?.projects) &&
                                        ele?.projects?.map((ele, i) => {
                                          return (
                                            <>
                                              <tr>
                                                <td>{ele?.projectId}</td>
                                                <td>{ele?.projectName}</td>
                                                <td>{ele?.option}</td>
                                                <td>{ele?.paymentType}</td>
                                                <td>{ele?.billingOption}</td>
                                                <td>{ele?.contractCode}</td>
                                                <td>{ele?.status}</td>
                                                <td>{ele?.paymentStatus}</td>
                                                <td>{ele?.nextPayment}</td>
                                                <td>{ele?.activationDate}</td>
                                                <td>{ele?.activeJobs}</td>
                                                <td>{ele?.activeUsers}</td>
                                                <td />
                                                <td />
                                                <td />
                                              </tr>
                                            </>
                                          );
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <>
                        <NoDataFound />
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="PaginationArea">
                <h6>Showing 1 to 10 of 27 entries</h6>
                <h6>
                  <img src="images/leftarrow.png" /> <span>01</span>{" "}
                  <img src="images/rightarrow.png" /> Out 10
                </h6>
              </div>
              {/* change order table end   */}
            </div>
            <div className="tab-pane fade" id="HelpMessagesTab">
              <div className="Tableheader">
                <div className="d-flex align-items-center">
                  <h6>System Help Messages</h6>
                  <div className="TodosButtons mt-0 d-flex">
                    <a href="javascript:void(0)" className="">
                      {/* onClick={() => dispatch(helpTabListAction("Hide"))} */}
                      <img src="images/hide_display.png" />
                      Hide disabled help messages
                    </a>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="TodosButtons mt-0 d-flex">
                    <a href="javascript:void(0)" onClick={showHelpTabModal}>
                      <img src="images/create_new_help.png" />
                      Create New Help Message
                    </a>
                  </div>
                  <div className="NewActions">
                    <a href="javascript:void(0);" title="pdf">
                      <img src="images/icon-53.png" />
                    </a>
                    <a href="javascript:void(0);" title="download">
                      <img src="images/download.png" />
                    </a>
                    <a href="javascript:void(0);" title="settings">
                      <img src="images/setting.png" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="TableList NewTableList TableListHeader TableHeightScroll">
                <table>
                  <thead>
                    <tr>
                      <th>Application Path</th>
                      <th>Creation Date</th>
                      <th>Created By</th>
                      <th>Status</th>
                      <th>
                        <div className="text-center">Action</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loader ? (
                      <>
                        <td>
                          {" "}
                          <Loader />
                        </td>
                      </>
                    ) : (
                      <>
                        {checkArray(helptabListData?.notData) ? (
                          helptabListData?.notData?.map((ele, ind) => {
                            return (
                              <>
                                <tr className="PendingConfirmation">
                                  <td>{ele?.applicationPath}</td>
                                  <td>{ele?.createdAt?.split("T")[0]}</td>
                                  <td>{ele?.helpMessage}</td>
                                  <td>{ele?.messageStatus}</td>
                                  <td>
                                    <div className="TableThreeDots text-center">
                                      <ul className="">
                                        <li className="dropdown">
                                          <a
                                            className=""
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol className="dropdown-menu dropdown-menuwidth">
                                            {/* <li>
                            <a href="javascript:void(0;)">
                              <img src="images/work-order-black.png" />
                              View Help Message
                            </a>
                          </li> */}
                                            <li>
                                              <a
                                                href="javascript:void(0)"
                                                onClick={() =>
                                                  showUpdateHelpTabModal(
                                                    ele?.id,
                                                    ele?.applicationPath,
                                                    ele?.helpMessage
                                                  )
                                                }
                                              >
                                                <img src="images/Action-1.png" />
                                                Edit Help Message
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={() =>
                                                  removeTabHelpFunc(ele?.id)
                                                }
                                              >
                                                <img src="images/dropdown-delete.png" />
                                                Delete Help Message
                                              </a>
                                            </li>
                                            <li>
                                              <a href="javascript:void(0);">
                                                <label className="switch">
                                                  <input
                                                    type="checkbox"
                                                    onChange={() =>
                                                      changeHelpTabStatus(
                                                        ele?.id,
                                                        "ACTIVE"
                                                      )
                                                    }
                                                  />
                                                  <span className="slider" />
                                                </label>
                                                Enable Help Message
                                              </a>
                                            </li>
                                            <li>
                                              <a href="javascript:void(0);">
                                                <label className="switch">
                                                  <input
                                                    type="checkbox"
                                                    onChange={() =>
                                                      changeHelpTabStatus(
                                                        ele?.id,
                                                        "INACTIVE"
                                                      )
                                                    }
                                                  />
                                                  <span className="slider" />
                                                </label>
                                                Disable Help Message
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <td>
                            {" "}
                            <NoDataFound />
                          </td>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="PaginationArea">
                <h6>Showing 1 to 10 of 27 entries</h6>
                <h6>
                  <img src="images/leftarrow.png" /> <span>01</span>{" "}
                  <img src="images/rightarrow.png" /> Out 10
                </h6>
              </div>
            </div>
            <div className="tab-pane fade" id="SubscriptionPlansTab">
              {/* <div className="Tableheader">
                <div className="d-flex align-items-center">
                  <h6>Plans</h6>
                </div>
                <div className="d-flex">
                  <div className="TodosButtons mt-0 d-flex">
                    <a
                     
                    >
                      <img src="images/create_new_plan.png" />
                      Create New Plan
                    </a>
                  </div>
                  <div className="NewActions">
                    <a href="javascript:void(0);">
                      <img src="images/icon-53.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/download.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/setting.png" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="TableList NewTableList TableListHeader TableHeightScroll">
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Projects and Jobs</th>
                      <th>Modules</th>
                      <th>Views</th>
                      <th>Last Update</th>
                      <th>Created By</th>
                      <th>Status</th>
                      <th>Monthly price</th>
                      <th>Yearly Discount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="PendingConfirmation">
                      <td>No Land</td>
                      <td>
                        Projects as Entity and Regular <br />
                        Projects, Residential, Multi-Family
                      </td>
                      <td>All</td>
                      <td>
                        <div className="text-black">Client and Trade</div>
                      </td>
                      <td>07/12/20 23</td>
                      <td>Sysadmin 1</td>
                      <td>Draft</td>
                      <td>$tt.tt</td>
                      <td>30%</td>
                      <td>
                        <div className="TableThreeDots text-center">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth">
                                <li>
                                  <a
                                    href="javascript:void(0;)"
                                    data-toggle="modal"
                                    data-target="#EditPlan"
                                  >
                                    <img src="images/work-order-black.png" />
                                    Edit Plan
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0;)">
                                    <img src="images/Action-1.png" />
                                    Delete Plan
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <label className="switch">
                                      <input type="checkbox" />
                                      <span className="slider" />
                                    </label>
                                    Activate Plan
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <label className="switch">
                                      <input type="checkbox" />
                                      <span className="slider" />
                                    </label>
                                    Deactivate Plan
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                 
                   
                  </tbody>
                </table>
              </div>
              <div className="PaginationArea">
                <h6>Showing 1 to 10 of 27 entries</h6>
                <h6>
                  <img src="images/leftarrow.png" /> <span>01</span>{" "}
                  <img src="images/rightarrow.png" /> Out 10
                </h6>
              </div> */}
               {/* {activeLinks === "Subscription-Plans" && 
               } */}
               <IbuldAdminSubscription />
            
              
            </div>
            <div className="tab-pane fade" id="CustomerSupportTab">
              <div className="Tableheader">
                <div className="d-flex align-items-center">
                  <h6>Support Requests</h6>
                  <div className="TodosButtons mt-0 d-flex">
                    <a
                      href="javascript:void(0)"
                      onClick={() => dispatch(helpListAction("Hide"))}
                    >
                      <img src="images/hide_display.png" />
                      Hide Solved/Cancelled
                    </a>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="TodosButtons mt-0 d-flex">
                    <a
                      href="javascript:void(0)"
                      onClick={() => showSupportModal()}
                    >
                      <img src="images/create_new_support.png" />
                      Create New Support Request
                    </a>
                  </div>
                  <div className="NewActions">
                    <a href="javascript:void(0);" title="pdf">
                      <img src="images/icon-53.png" />
                    </a>
                    <DownloadTableExcel
                      filename="Help item List"
                      sheet="Help items list"
                      currentTableRef={helpListRef.current}
                    >
                      <a href="javascript:void(0);" title="download">
                        <img src="images/download.png" />
                      </a>
                    </DownloadTableExcel>

                    <a href="javascript:void(0);" title="settings">
                      <img src="images/setting.png" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="TableList NewTableList TableListHeader TableHeightScroll">
                <table ref={helpListRef}>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Client</th>
                      <th>Company</th>
                      <th>Type</th>
                      <th>Module</th>
                      <th>Description</th>
                      <th>Problem Start Date</th>
                      <th>Severity</th>
                      <th>Error Message</th>
                      <th>Url link of the issue page</th>
                      {/* <th>Supporting Documents</th> */}
                      <th>Status</th>

                      <th>Creation Date</th>
                      <th>Last Update</th>
                      <th>Solution</th>
                      <th>Root Cause</th>
                      <th>Bug ID</th>
                      <th>Messages</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loader ? (
                      <>
                        <td>
                          {" "}
                          <Loader />
                        </td>
                      </>
                    ) : (
                      <>
                        {checkArray(helpListData?.notData) ? (
                          helpListData?.notData?.map((ele, ind) => {
                            return (
                              <>
                                <tr className="PendingConfirmation">
                                  <td>{ele?.id}</td>
                                  <td>{ele?.clientName}</td>
                                  <td>{ele?.companyId}</td>
                                  <td>{ele?.issueDetailsType}</td>
                                  <td>{ele?.module}</td>
                                  <td>{ele?.description}</td>
                                  <td>
                                    {ele?.problemStartDate}, {ele?.time}
                                  </td>
                                  <td>{ele?.severity}</td>
                                  <td>{ele?.errorMessage}</td>
                                  <td>{ele?.applicationLink}</td>
                                  {/* <td>
                  <div className="Actions">
                    <a
                      href="javascript:void(0);"
                      onClick={() => showAddSupportingDocsModal()}
                    >
                      <img src="images/icon-63.png" />
                    </a>
                    <a
                      href="javascript:void(0)"
                      onClick={() => showSupportingListModal()}
                    >
                      <span className="Count">3</span>
                    </a>
                  </div>
                </td> */}
                                  <td>{ele?.requestStatus}</td>

                                  <td>
                                    <div className="text-black">
                                      {ele?.createdAt?.split("T")[0]}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-black">
                                      {ele?.updatedAt?.split("T")[0]}
                                    </div>
                                  </td>
                                  <td />
                                  <td />
                                  <td />
                                  {/* <td>
                  <div className="Actions">
                    <a href="javascript:void(0);">
                      <img src="images/chat_icon.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <span className="Count">3</span>
                    </a>
                  </div>
                </td> */}
                                  <td>
                                    <div className="TableThreeDots text-center">
                                      <ul className="">
                                        <li className="dropdown">
                                          <a
                                            className=""
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol
                                            className="dropdown-menu dropdown-menuwidth2"
                                            style={{}}
                                          >
                                            <li>
                                              <a
                                                onClick={() =>
                                                  showSupportDetailsModal(
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                <img src="images/view-po.png" />
                                                View Support Request
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  showUpdateSupportModal(
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                <img src="images/Action-1.png" />
                                                Update Support Request
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  showHistoryModal(ele?.id)
                                                }
                                              >
                                                <img src="images/timer.png" />
                                                View History
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <td>
                            {" "}
                            <NoDataFound />
                          </td>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="PaginationArea">
                <h6>Showing 1 to 10 of 27 entries</h6>
                <h6>
                  <img src="images/leftarrow.png" /> <span>01</span>{" "}
                  <img src="images/rightarrow.png" /> Out 10
                </h6>
              </div>
            </div>
            <div className="tab-pane fade" id="ChatMessageTab">
              <div className="Tableheader">
                <div className="d-flex align-items-center">
                  <h6>Homepage Chat Messages</h6>
                  <div className="TodosButtons mt-0 d-flex">
                    <a href="javascript:void(0)" className="">
                      <img src="images/hide_display.png" />
                      Hide Solved/Cancelled
                    </a>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="TodosButtons mt-0 d-flex">
                    <a href="javascript:void(0)" className="">
                      <img src="images/unread_message.png" />
                      Unread Messages Only
                    </a>
                  </div>
                  <div className="NewActions">
                    <a href="javascript:void(0);" title="pdf">
                      <img src="images/icon-53.png" />
                    </a>
                    <a href="javascript:void(0);" title="download">
                      <img src="images/download.png" />
                    </a>
                    <a href="javascript:void(0);" title="settings">
                      <img src="images/setting.png" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="TableList NewTableList TableListHeader TableHeightScroll">
                <table>
                  <thead>
                    <tr>
                      <th>Chat id</th>
                      <th>Client Name</th>
                      <th>Client Email</th>
                      <th>Status</th>
                      <th>Assigned to</th>
                      <th>Unread Message</th>
                      <th>Supporting Documents</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>CH-50</td>
                      <td>Client 1</td>
                      <td>Client1@email.com</td>
                      <td>Active</td>
                      <td>Sysadmin 1</td>
                      <td>0</td>
                      <td>
                        <div className="Actions">
                          <a
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#ViewSupportingDocuments"
                          >
                            <img src="images/icon-63.png" />
                          </a>
                          <a href="javascript:void(0)">
                            <span className="Count">3</span>
                          </a>
                        </div>
                      </td>
                      <td>
                        <div className="TableThreeDots text-center">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a
                                    href="javascript:void(0;)"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#LiveChat"
                                  >
                                    <img src="images/connect.png" />
                                    Connect to Chat
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0;)"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#AssignAdmin"
                                  >
                                    <img src="images/assign_icon.png" />
                                    Assign an Admin to chat
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0;)"
                                    data-toggle="modal"
                                    data-target="#ViewSupportingDocuments"
                                  >
                                    <img src="images/icon-63.png" />
                                    View Supporting Documents
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0;)">
                                    <img src="images/timer.png" />
                                    View Chat History
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0;)">
                                    <img src="images/end_chat.png" />
                                    End Chat
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>CH-50</td>
                      <td>Client 1</td>
                      <td>Client1@email.com</td>
                      <td>Active</td>
                      <td>
                        <div className="Action">
                          <a href="javascript:void(0);">
                            <img src="images/assign_icon.png" />
                          </a>
                        </div>
                      </td>
                      <td>
                        <div className="TextRed">2</div>
                      </td>
                      <td>
                        <div className="Actions">
                          <a
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#ViewSupportingDocuments"
                          >
                            <img src="images/icon-63.png" />
                          </a>
                          <a
                            href="javascript:void(0)"
                            data-toggle="modal"
                            data-target="#SupportingDocumentList"
                          >
                            <span className="Count">3</span>
                          </a>
                        </div>
                      </td>
                      <td>
                        <div className="TableThreeDots text-center">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a
                                    href="javascript:void(0;)"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#LiveChat"
                                  >
                                    <img src="images/connect.png" />
                                    Connect to Chat
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0;)"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#AssignAdmin"
                                  >
                                    <img src="images/assign_icon.png" />
                                    Assign an Admin to chat
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0;)"
                                    data-toggle="modal"
                                    data-target="#ViewSupportingDocuments"
                                  >
                                    <img src="images/icon-63.png" />
                                    View Supporting Documents
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0;)">
                                    <img src="images/timer.png" />
                                    View Chat History
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0;)">
                                    <img src="images/end_chat.png" />
                                    End Chat
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>CH-50</td>
                      <td>Client 1</td>
                      <td>Client1@email.com</td>
                      <td>Active</td>
                      <td>Sysadmin 1</td>
                      <td>
                        <div className="TextRed">4</div>
                      </td>
                      <td>
                        <div className="Actions">
                          <a
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#ViewSupportingDocuments"
                          >
                            <img src="images/icon-63.png" />
                          </a>
                          <a
                            href="javascript:void(0)"
                            data-toggle="modal"
                            data-target="#SupportingDocumentList"
                          >
                            <span className="Count">3</span>
                          </a>
                        </div>
                      </td>
                      <td>
                        <div className="TableThreeDots text-center">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a
                                    href="javascript:void(0;)"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#LiveChat"
                                  >
                                    <img src="images/connect.png" />
                                    Connect to Chat
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0;)"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#AssignAdmin"
                                  >
                                    <img src="images/assign_icon.png" />
                                    Assign an Admin to chat
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0;)"
                                    data-toggle="modal"
                                    data-target="#ViewSupportingDocuments"
                                  >
                                    <img src="images/icon-63.png" />
                                    View Supporting Documents
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0;)">
                                    <img src="images/timer.png" />
                                    View Chat History
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0;)">
                                    <img src="images/end_chat.png" />
                                    End Chat
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>CH-50</td>
                      <td>Client 1</td>
                      <td>Client1@email.com</td>
                      <td>Active</td>
                      <td>Sysadmin 1</td>
                      <td>0</td>
                      <td>
                        <div className="Actions">
                          <a
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#ViewSupportingDocuments"
                          >
                            <img src="images/icon-63.png" />
                          </a>
                          <a
                            href="javascript:void(0)"
                            data-toggle="modal"
                            data-target="#SupportingDocumentList"
                          >
                            <span className="Count">3</span>
                          </a>
                        </div>
                      </td>
                      <td>
                        <div className="TableThreeDots text-center">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth2">
                                <li>
                                  <a
                                    href="javascript:void(0;)"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#LiveChat"
                                  >
                                    <img src="images/connect.png" />
                                    Connect to Chat
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0;)"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#AssignAdmin"
                                  >
                                    <img src="images/assign_icon.png" />
                                    Assign an Admin to chat
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0;)"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#ViewSupportingDocuments"
                                  >
                                    <img src="images/icon-63.png" />
                                    View Supporting Documents
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0;)">
                                    <img src="images/timer.png" />
                                    View Chat History
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0;)">
                                    <img src="images/end_chat.png" />
                                    End Chat
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="PaginationArea">
                <h6>Showing 1 to 10 of 27 entries</h6>
                <h6>
                  <img src="images/leftarrow.png" /> <span>01</span>{" "}
                  <img src="images/rightarrow.png" /> Out 10
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={addSupportModal}
        onHide={hideSupportModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSupportModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Support Request</h4>
            </div>
            <div className="FolderForm">
              <div className="ModalBigheading mb-4">
                <h3 className="mb-1">
                  <span>Contact Details</span>
                </h3>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <h6>
                      Company <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="companyId"
                        value={companyId}
                        onChange={handleInput}
                      >
                        <option selected="">Select Company</option>
                        <option value="Company 1">Company 1</option>
                        <option value="Company 2">Company 2</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Client Name <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="clientName"
                        value={clientName}
                        onChange={handleInput}
                      >
                        <option selected="">Select Client</option>
                        <option value="Client 1">Client 1</option>
                        <option value="CLient 2">Client 2</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group">
                    <h6>Country Code</h6>
                    <PhoneInput
                      inputClass="CountryCodeInput"
                      countryCodeEditable={false}
                      enableSearch
                      value={phonecode}
                      onChange={handlePhoneInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <h6>
                      Telephone <span className="required">*</span>
                    </h6>
                    <input
                      type="text"
                      className="form-control"
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={handleInputNumber}
                    />
                  </div>
                </div>
                <div className="col-md-5 ml-auto">
                  <div className="form-group">
                    <h6>
                      Availability <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="availability"
                        value={availability}
                        onChange={handleInput}
                      >
                        <option selected="">Select Availability</option>
                        <option value="Complete">Complete</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Contact Preference <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="contactPrefrence"
                        value={contactPrefrence}
                        onChange={handleInput}
                      >
                        <option selected="">Select Preference</option>
                        <option value="Email">Email </option>
                        <option value="Phone">Phone </option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Email <span className="required">*</span>
                    </h6>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="form-group">
                    <h6>
                      Language Preference <span className="required">*</span>
                    </h6>
                    <div className="d-flex justify-content-between">
                      <label className="Radio text-black ">
                        {" "}
                        English
                        <input
                          type="radio"
                          name="languagePrefrence"
                          value={"English"}
                          onChange={handleRadioChange}
                        />
                        <span className="checkmark" />
                      </label>
                      <label className="Radio text-black ">
                        {" "}
                        French
                        <input
                          type="radio"
                          name="languagePrefrence"
                          value={"French"}
                          onChange={handleRadioChange}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ModalBigheading mb-4">
                <h3 className="mb-1">
                  <span>Issue Details</span>
                </h3>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <h6>
                      Type <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="issueDetailsType"
                        value={issueDetailsType}
                        onChange={handleInput}
                      >
                        <option selected="">Select Type</option>
                        <option value="Type 1">Type 1</option>
                        <option value="Type 2">Type 2</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Module <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="module"
                        value={module}
                        onChange={handleInput}
                      >
                        <option selected="">Select Module</option>
                        <option value="Module 1">Module 1</option>
                        <option value="Module 2">Module 2</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 ml-auto">
                  <div className="form-group">
                    <h6>
                      Severity <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="severity"
                        value={severity}
                        onChange={handleInput}
                      >
                        <option selected="">Select Severity</option>
                        <option value="Severity 1">Severity 1</option>
                        <option value="Severity 2">Severity 2</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <h6>
                      Description <span className="required">*</span>
                    </h6>
                    <textarea
                      className="form-control"
                      placeholder="Insert Description"
                      rows={3}
                      name="description"
                      value={description}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="form-group col-12 mb-0">
                  <h6>
                    Problem Start Date <span className="required">*</span>
                  </h6>
                </div>
                <div className="form-group col-md-4">
                  <input
                    type="date"
                    className="form-control"
                    name="problemStartDate"
                    value={problemStartDate}
                    onChange={handleInput}
                  />
                </div>
                <div className="form-group col-md-4">
                  <input
                    type="time"
                    className="form-control"
                    placeholder="hh:mm"
                    name="time"
                    value={time}
                    onChange={handleInput}
                  />
                </div>
                <div className="form-group col-md-4">
                  <div className="CommonSelectBox CommonSelectBoxWidth">
                    <select
                      name="timeType"
                      value={timeType}
                      onChange={handleInput}
                    >
                      <option selected="">Select</option>
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </div>
                <div className="form-group col-md-5">
                  <div className="CommonSelectBox CommonSelectBoxWidth mb-3">
                    <select
                      name="timeZone"
                      value={timeZone}
                      onChange={handleInput}
                    >
                      <option>Select Timezone</option>
                      {checkArray(timeZoneData?.data) ? (
                        <>
                          {timeZoneData?.data?.map((ele, i) => {
                            return (
                              <option value={ele?.zoneName}>
                                {ele?.zoneName}
                              </option>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </select>
                  </div>
                  <label className="CheckBox">
                    {" "}
                    Not sure, use current time
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="form-group col-md-12">
                  <h6>Error Message</h6>
                  <textarea
                    className="form-control"
                    placeholder="Insert Error Message if applicable, or more details."
                    rows={3}
                    name="errorMessage"
                    value={errorMessage}
                    onChange={handleInput}
                  />
                </div>
                <div className="form-group col-md-6">
                  <h6>Url link of the issue page</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert link where the error is occuring"
                    name="applicationLink"
                    value={applicationLink}
                    onChange={handleInput}
                  />
                </div>
                <div className="ModalBigheading col-md-12">
                  <h3>
                    <span>Supporting Documents</span>
                  </h3>
                </div>
                <div className="form-group col-md-6">
                  <h6>Select Files</h6>
                  <div className="Upload">
                    <span>-Drag and Drop Files or click to select</span>
                    <input
                      type="file"
                      name="supportingDocFiles"
                      onChange={(e) => handleInputFile(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="row"></div>
              {/* <div className="Button mb-3">
        <button className="Create">Add</button>
      </div> */}
              {/* <div className="form-group">
        <h6>Files:</h6>
        <div className="FilesAdded">
          <label>error1_.jpeg</label>
          <span>
            <img src="images/fileview.png" />
          </span>
          <span className="cross">
            <img src="images/Cross.png" />
          </span>
        </div>
        <div className="FilesAdded">
          <label>error1_.jpeg</label>
          <span>
            <img src="images/fileview.png" />
          </span>
          <span className="cross">
            <img src="images/Cross.png" />
          </span>
        </div>
      </div> */}
              {/* <div className="row">
        <div className="form-group col-md-6">
          <h6>
            Assign To <span className="required">*</span>
          </h6>
          <div
            className="CommonSelectBox CommonSelectBoxWidth"
            style={{ width: "100%!important", margin: 0 }}
          >
            <select >
              <option selected="">Select Ibuild Admin</option>
              <option value={"Ibuild Admin"}> Ibuild Admin 1</option>
            </select>
          </div>
        </div>
      </div> */}
              <div className="Button d-flex justify-content-between">
                <button
                  onClick={addSupportFunc}
                  className="Create ClosePermissionId"
                >
                  Create Support Request
                </button>
                <button className="Outline ClosePermissionId">Cancel</button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={addSupportingDocsModal}
        onHide={hideAddSupportingDocsModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAddSupportingDocsModal}
          >
            ×
          </a>

          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Add New Supporting Docs</h4>
            </div>
            <div class="JobTitleArea">
              <h3>Supporting Documents #2</h3>
              <div class="JobBox">
                <h6>
                  <span>Job:</span> UUnit3-CHI-2B-315
                </h6>
              </div>
              <div class="JobBox">
                <h6>
                  <span>Origin</span> #185
                </h6>
              </div>
              <div class="JobBox">
                <h6>
                  <span>Item:</span> #143
                </h6>
              </div>
            </div>
            <div class="form-group">
              <h6>Select Files</h6>
              <div class="Upload">
                <span>-Drag and Drop Files or click to select</span>
                <input
                  type="file"
                  name="supportingDocFile"
                  onChange={(e) => handleFileChange(e)}
                />
              </div>
            </div>
            <div class="FilesAddedTitle">
              <h4>Files</h4>
            </div>
            <div class="FilesAdded">
              <label>File_name.docs</label>
              <span>
                <img src="images/fileview.png" />
              </span>
              <span>
                <img src="images/Cross.png" class="cross" />
              </span>
            </div>
            <div class="FilesAdded">
              <label>File_name.docs</label>
              <span>
                <img src="images/fileview.png" />
              </span>
              <span>
                <img src="images/Cross.png" class="cross" />
              </span>
            </div>
            <div class="Button mt-3">
              <button
                class="Create"
                onClick={addSupportingDocsFunc}
                data-dismiss="modal"
              >
                Add Documents
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={supportingDocsListModal}
        onHide={hideSupportingListModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSupportingListModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Supporting Documents List</h4>
            </div>
            <div className="JobTitleArea">
              <div className="JobBox">
                <h6>
                  <span>Job:</span> UUnit3-CHI-2B-315
                </h6>
                <div>
                  <a
                    href="javascript:void(0);"
                    className="FolderPermissionId"
                    data-toggle="modal"
                    data-target="#AddNewSupportingDocs"
                    data-dismiss="modal"
                  >
                    <img src="images/notebook.png" />
                  </a>
                </div>
              </div>
              <div className="JobBox">
                <h6>
                  <span className="text-red">Origin</span> #185
                </h6>
              </div>
              <div className="JobBox">
                <h6>
                  <span className="text-red">Item:</span> #143
                </h6>
              </div>
            </div>
            <div className="NotesAreaMain">
              <div className="NotesArea mt-4">
                <h6>Supporting Documents List</h6>
                <div>
                  <a href="javascript:void(0);">
                    <img src="images/pdf.png" />
                  </a>
                  <DownloadTableExcel
                    filename="Supporting Docs List"
                    sheet="Supporting Docs list"
                    currentTableRef={supportingDocsRef.current}
                  >
                    <a href="javascript:void(0);" title="download">
                      <img src="images/download.png" />
                    </a>
                  </DownloadTableExcel>
                  <a href="javascript:void(0);">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
              <div className="TableList">
                <table ref={supportingDocsRef}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Sent by</th>
                      <th>Date</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>John Doe</td>
                      <td>05/01/2023</td>
                      <td className="Inspection">floor_manual.pdf</td>
                      <td>
                        <div className="Actions">
                          <a
                            href="javascript:void(0);"
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#SupportingDocsDetails"
                            data-dismiss="modal"
                          >
                            <img src="images/Action-1.png" />
                          </a>
                          <a href="javascript:void(0);">
                            <img src="images/delete.png" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>John Doe</td>
                      <td>05/01/2023</td>
                      <td className="Inspection">photo1.jpg, photo2.jpg</td>
                      <td>
                        <div className="Actions">
                          <a
                            href="javascript:void(0);"
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#SupportingDocsDetails"
                            data-dismiss="modal"
                          >
                            <img src="images/Action-1.png" />
                          </a>
                          <a href="javascript:void(0);">
                            <img src="images/delete.png" />
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={supportDetailsModal}
        onHide={hideSupportDetailsModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSupportDetailsModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Support Request Details</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="row">
                  <div className="form-group col-md-3">
                    <h6>ID</h6>
                    <p className="ml-0">{helpDetailsData[0]?.id}</p>
                  </div>
                  <div className="form-group col-md-3">
                    <h6>Creation Date</h6>
                    <p className="ml-0">
                      {helpDetailsData[0]?.createdAt?.split("T")[0]}
                    </p>
                  </div>
                  <div className="form-group col-md-3">
                    <h6>Last Update</h6>
                    <p className="ml-0">
                      {helpDetailsData[0]?.updatedAt?.split("T")[0]}
                    </p>
                  </div>
                  <div className="form-group col-md-3">
                    <h6>Status</h6>
                    <p className="ml-0">{helpDetailsData[0]?.status}</p>
                  </div>
                </div>
                <div className="ModalBigheading mb-4">
                  <h3 className="mb-1">
                    <span>Contact Details</span>
                  </h3>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <h6>Company</h6>
                      <p className="ml-0">{helpDetailsData[0]?.id}</p>
                    </div>
                    <div className="form-group">
                      <h6>Client Name</h6>
                      <p className="ml-0">{helpDetailsData[0]?.clientName}</p>
                    </div>
                    <div className="form-group">
                      <h6>Telephone</h6>
                      <p className="ml-0">{helpDetailsData[0]?.phoneNumber}</p>
                    </div>
                  </div>
                  <div className="col-md-5 ml-auto">
                    <div className="form-group">
                      <h6>Availability</h6>
                      <p className="ml-0">{helpDetailsData[0]?.availability}</p>
                    </div>
                    <div className="form-group">
                      <h6>Contact Preference</h6>
                      <p className="ml-0">
                        {helpDetailsData[0]?.contactPrefrence}
                      </p>
                    </div>
                    <div className="form-group">
                      <h6>Email</h6>
                      <p className="ml-0">{helpDetailsData[0]?.email}</p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <h6>Language Preference </h6>
                    <p className="ml-0">
                      {helpDetailsData[0]?.languagePrefrence}
                    </p>
                  </div>
                </div>
                <div className="ModalBigheading mt-4 mb-4">
                  <h3 className="mb-1">
                    <span>Issue Details</span>
                  </h3>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>Type</h6>
                      <p className="ml-0">
                        {helpDetailsData[0]?.languagePrefrence}
                      </p>
                    </div>
                    <div className="form-group">
                      <h6>Module</h6>
                      <p className="ml-0">{helpDetailsData[0]?.module}</p>
                    </div>
                    <div className="form-group">
                      <h6>Description</h6>
                      <p className="ml-0">{helpDetailsData[0]?.description}</p>
                    </div>
                    <div className="form-group">
                      <h6>Problem Start Date</h6>
                      <div className="d-flex mb-4">
                        <p className="ml-0">
                          {helpDetailsData[0]?.problemStartDate?.split("T")[0]}
                        </p>
                        {/* <p>09:00 AM</p> */}
                      </div>
                      {/* <p className="ml-0">{helpDetailsData[0]?.problemStartDate?.split("T")[0]}</p> */}
                    </div>
                  </div>
                  <div className="col-md-5 ml-auto">
                    <div className="form-group">
                      <h6>Severity</h6>
                      <p className="ml-0">{helpDetailsData[0]?.severity}</p>
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    <h6>Error Message</h6>
                    <p className="ml-0">{helpDetailsData?.errorMessage}</p>
                  </div>
                  <div className="form-group col-md-12">
                    <h6>Url link of the issue page</h6>
                    <p className="ml-0"> {helpDetailsData?.applicationLink}</p>
                  </div>
                  <div className="col-md-12">
                    <div className="ModalBigheading">
                      <h3>
                        <span>Supporting Documents</span>
                      </h3>
                    </div>
                    <div className="form-group">
                      <h6>Files:</h6>
                      <div className="FilesAdded">
                        <label>error1_.jpeg</label>
                        <span>
                          <img src="images/view_jobs_details.png" />
                        </span>
                      </div>
                      <div className="FilesAdded">
                        <label>error1_.jpeg</label>
                        <span>
                          <img src="images/view_jobs_details.png" />
                        </span>
                      </div>
                    </div>
                    <div className="ModalBigheading">
                      <h3>
                        <span>Support Details</span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-6">
                    <h6>Assign To </h6>
                    <h6 className="fw-400 fw-14">Sysadmin 1</h6>
                  </div>
                  <div className="form-group col-12">
                    <h6>Solution</h6>
                  </div>
                  <div className="form-group col-md-6">
                    <h6>Root Cause</h6>
                    <p className="ml-0">Bug</p>
                  </div>
                  <div className="form-group col-md-6">
                    <h6>Bug Id</h6>
                    <p className="ml-0">Bug 36</p>
                  </div>
                </div>
                <div className="Button text-center">
                  <button className="Outline">Close</button>
                </div>
              </form>
            </div>
            <div class="DiscussionArea">
              <div class="DiscussionTitle">
                <h4>Discussion</h4>
                <span>{discussionList?.length}</span>
                <div class="DiscussionChat">
                  <img
                    src={require("../assets/images/down.png")}
                    class="DiscussionIcon"
                  />
                  <a href="#">
                    <img src={require("../assets/images/pdf2.png")} />
                  </a>
                </div>
              </div>
              <div class="DiscussionHideArea" style={{ display: "block" }}>
                <div class="DiscussionBox">
                  <span>FM</span>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      value={discussionState?.message}
                      onChange={(e) =>
                        updateDiscussionState({
                          ...discussionState,
                          message: e.target.value,
                        })
                      }
                      placeholder="Add a Comment ....."
                    />
                    {discussionState.discussionError &&
                      !discussionState.message && (
                        <p style={{ color: "red" }}>
                          {discussionState.discussionError}
                        </p>
                      )}

                    <div class="DiscussionOverlay">
                      <a
                        onClick={() =>
                          updateDiscussionState({
                            ...discussionState,
                            message: "",
                          })
                        }
                        class="CancelChat"
                      >
                        <img
                          src={require("../assets/images/plus-cancel1.png")}
                        />
                      </a>
                      <a onClick={handleAddDiscussion} class="SendChat">
                        <img
                          src={require("../assets/images/forwardbutton.png")}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                {discussionList?.map((item, i) => (
                  <>
                    <div class="DiscussionBox">
                      <span>CO</span>
                      <div class="DiscussionRight">
                        <h6>{item?.message}</h6>
                        <small>{moment(item.updatedAt).format("lll")}</small>
                      </div>
                    </div>
                    <div class="ReplyArea">
                      <a
                        onClick={(e) => {
                          updateReplyField({ flag: true, index: i });
                          updateReplyState({ ...replyState, message: "" });
                        }}
                      >
                        Reply
                      </a>
                      {item?.ReplyDiscussions?.map((ele) => (
                        <div class="DiscussionBox">
                          <span>RE</span>
                          <div class="DiscussionRight">
                            <h6>{ele?.message}</h6>
                            <small>{moment(ele.updatedAt).format("lll")}</small>
                          </div>
                        </div>
                      ))}

                      {replyField.index == i && (
                        <div class="DiscussionBox w-100">
                          <span>FM{item?.id}</span>
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              value={replyState?.message}
                              onChange={(e) =>
                                updateReplyState({
                                  ...replyState,
                                  message: e.target.value,
                                  messageId: item?.id,
                                  jobId: item?.jobId,
                                })
                              }
                              placeholder="Add reply ....."
                            />
                            {discussionState.discussionError &&
                              !discussionState.message && (
                                <p style={{ color: "red" }}>
                                  {discussionState.discussionError}
                                </p>
                              )}

                            <div class="DiscussionOverlay">
                              <a
                                onClick={() =>
                                  updateReplyState({
                                    ...replyState,
                                    message: "",
                                  })
                                }
                                class="CancelChat"
                              >
                                <img
                                  src={require("../assets/images/plus-cancel1.png")}
                                />
                              </a>
                              <a onClick={handleAddReply} class="SendChat">
                                <img
                                  src={require("../assets/images/forwardbutton.png")}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={updateSupportModal}
        onHide={hideUpdateSupportModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideUpdateSupportModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Support Request Details</h4>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="col-md-2 form-group">
                  <h6>ID</h6>
                  <p className="ml-0">SR1C020</p>
                </div>
                <div className="col-md-3 form-group">
                  <h6>Creation Date</h6>
                  <p className="ml-0">SR1C020</p>
                </div>
                <div className="col-md-3 form-group">
                  <h6>Last Update</h6>
                  <p className="ml-0">SR1C020</p>
                </div>
                <div className="col-md-4 form-group">
                  <h6>Status</h6>
                  <div className="CommonSelectBox CommonSelectBoxWidth">
                    <select>
                      <option selected="">In Progress</option>
                    </select>
                    <span>
                      <img src="images/down.png" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="ModalBigheading mb-4">
                <h3 className="mb-1">
                  <span>Contact Details</span>
                </h3>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <h6>
                      Company <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="companyId"
                        value={companyId}
                        onChange={handleInput}
                      >
                        <option selected="">Select Company</option>
                        <option value="Company 1">Company 1</option>
                        <option value="Company 2">Company 2</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Client Name <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="clientName"
                        value={clientName}
                        onChange={handleInput}
                      >
                        <option selected="">Select Client</option>
                        <option value="Client 1">Client 1</option>
                        <option value="CLient 2">Client 2</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group">
                    <h6>Country Code</h6>
                    <PhoneInput
                      inputClass="CountryCodeInput"
                      countryCodeEditable={false}
                      enableSearch
                      value={phonecode}
                      onChange={handlePhoneInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <h6>
                      Telephone <span className="required">*</span>
                    </h6>
                    <input
                      type="text"
                      className="form-control"
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={handleInputNumber}
                    />
                  </div>
                </div>
                <div className="col-md-5 ml-auto">
                  <div className="form-group">
                    <h6>
                      Availability <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="availability"
                        value={availability}
                        onChange={handleInput}
                      >
                        <option selected="">Select Availability</option>
                        <option value="Complete">Complete</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Contact Preference <span className="required">*</span>
                    </h6>
                    <div className="CommonSelectBox CommonSelectBoxWidth">
                      <select
                        name="contactPrefrence"
                        value={contactPrefrence}
                        onChange={handleInput}
                      >
                        <option selected="">Select Preference</option>
                        <option value="Email">Email </option>
                        <option value="Phone">Phone </option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Email <span className="required">*</span>
                    </h6>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="form-group">
                    <h6>
                      Language Preference <span className="required">*</span>
                    </h6>
                    <div className="d-flex justify-content-between">
                      <label className="Radio text-black ">
                        {" "}
                        English
                        <input
                          type="radio"
                          name="languagePrefrence"
                          value={"English"}
                          onChange={handleRadioChange}
                        />
                        <span className="checkmark" />
                      </label>
                      <label className="Radio text-black ">
                        {" "}
                        French
                        <input
                          type="radio"
                          name="languagePrefrence"
                          value={"French"}
                          onChange={handleRadioChange}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ModalBigheading mb-4">
                <h3 className="mb-1">
                  <span>Issue Details</span>
                </h3>
              </div>
            </div>
            <div className="ModalBigheading mb-4">
              <h3 className="mb-1">
                <span>Issue Details</span>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-5 ml-auto">
                <div className="form-group">
                  <h6>
                    Severity <span className="required">*</span>
                  </h6>
                  <div className="CommonSelectBox CommonSelectBoxWidth">
                    <select
                      name="severity"
                      value={severity}
                      onChange={handleInput}
                    >
                      <option selected="">Select Severity</option>
                      <option value="Severity 1">Severity 1</option>
                      <option value="Severity 2">Severity 2</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <h6>
                    Description <span className="required">*</span>
                  </h6>
                  <textarea
                    className="form-control"
                    placeholder="Insert Description"
                    rows={3}
                    name="description"
                    value={description}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="form-group col-12 mb-0">
                <h6>
                  Problem Start Date <span className="required">*</span>
                </h6>
              </div>
              <div className="form-group col-md-4">
                <input
                  type="date"
                  className="form-control"
                  name="problemStartDate"
                  value={problemStartDate}
                  onChange={handleInput}
                />
              </div>
              <div className="form-group col-md-4">
                <input
                  type="time"
                  className="form-control"
                  placeholder="hh:mm"
                  name="time"
                  value={time}
                  onChange={handleInput}
                />
              </div>
              <div className="form-group col-md-4">
                <div className="CommonSelectBox CommonSelectBoxWidth">
                  <select
                    name="timeType"
                    value={timeType}
                    onChange={handleInput}
                  >
                    <option selected="">Select</option>
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </div>
              <div className="form-group col-md-5">
                <div className="CommonSelectBox CommonSelectBoxWidth mb-3">
                  <select
                    name="timeZone"
                    value={timeZone}
                    onChange={handleInput}
                  >
                    <option>Select Timezone</option>
                    {checkArray(timeZoneData?.data) ? (
                      <>
                        {timeZoneData?.data?.map((ele, i) => {
                          return (
                            <option value={ele?.zoneName}>
                              {ele?.zoneName}
                            </option>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
                <label className="CheckBox">
                  {" "}
                  Not sure, use current time
                  <input type="checkbox" />
                  <span className="checkmark" />
                </label>
              </div>
              <div className="form-group col-md-12">
                <h6>Error Message</h6>
                <textarea
                  className="form-control"
                  placeholder="Insert Error Message if applicable, or more details."
                  rows={3}
                  name="errorMessage"
                  value={errorMessage}
                  onChange={handleInput}
                />
              </div>
              <div className="form-group col-md-6">
                <h6>Url link of the issue page</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert link where the error is occuring"
                  name="applicationLink"
                  value={applicationLink}
                  onChange={handleInput}
                />
              </div>
              <div className="ModalBigheading col-md-12">
                <h3>
                  <span>Supporting Documents</span>
                </h3>
              </div>
              <div className="form-group col-md-6">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input
                    type="file"
                    name="supportingDocFiles"
                    onChange={(e) => handleInputFile(e)}
                  />
                </div>
              </div>
            </div>
            <div className="row"></div>
            <div className="Button mb-3">
              <button className="Create">Add</button>
            </div>
            {/* <div className="form-group">
        <h6>Files:</h6>
        <div className="FilesAdded">
          <label>error1_.jpeg</label>
          <span>
            <img src="images/fileview.png" />
          </span>
          <span className="cross">
            <img src="images/Cross.png" />
          </span>
        </div>
        <div className="FilesAdded">
          <label>error1_.jpeg</label>
          <span>
            <img src="images/fileview.png" />
          </span>
          <span className="cross">
            <img src="images/Cross.png" />
          </span>
        </div>
      </div> */}
            <div className="row">
              <div className="form-group col-md-4">
                <h6>
                  Assign To <span className="required">*</span>
                </h6>
                <div
                  className="CommonSelectBox CommonSelectBoxWidth"
                  style={{ width: "100%!important", margin: 0 }}
                >
                  <select>
                    <option selected="">Sysadmin 1</option>
                    <option> Ibuild Admin 1</option>
                  </select>
                  <span>
                    <img src="images/down.png" />
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4">
                <h6>Root Cause</h6>
                <div
                  className="CommonSelectBox CommonSelectBoxWidth"
                  style={{ width: "100%!important", margin: 0 }}
                >
                  <select>
                    <option selected="">Bug</option>
                  </select>
                  <span>
                    <img src="images/down.png" />
                  </span>
                </div>
              </div>
              <div className="col-md-8">
                <h6>Bug ID</h6>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>Link Name</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Bug 36"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>Address</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="https://dev.azure.com/i...."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Button d-flex justify-content-between">
              <button onClick={updatehelpSupportFunc} className="Create">
                Update Request
              </button>
              <button className="Outline">Cancel</button>
            </div>
          </div>
          <div class="DiscussionArea">
            <div class="DiscussionTitle">
              <h4>Discussion</h4>
              <div class="DiscussionChat">
                <img
                  src={require("../assets/images/down.png")}
                  class="DiscussionIcon"
                />
                <a href="#">
                  <img src={require("../assets/images/pdf2.png")} />
                </a>
              </div>
            </div>
            <div class="DiscussionHideArea" style={{ display: "block" }}>
              <div class="DiscussionBox">
                <span>FM</span>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    value={discussionState?.message}
                    onChange={(e) =>
                      updateDiscussionState({
                        ...discussionState,
                        message: e.target.value,
                      })
                    }
                    placeholder="Add a Comment ....."
                  />
                  {discussionState.discussionError &&
                    !discussionState.message && (
                      <p style={{ color: "red" }}>
                        {discussionState.discussionError}
                      </p>
                    )}

                  <div class="DiscussionOverlay">
                    <a
                      onClick={() =>
                        updateDiscussionState({
                          ...discussionState,
                          message: "",
                        })
                      }
                      class="CancelChat"
                    >
                      <img src={require("../assets/images/plus-cancel1.png")} />
                    </a>
                    <a onClick={handleAddDiscussion} class="SendChat">
                      <img
                        src={require("../assets/images/forwardbutton.png")}
                      />
                    </a>
                  </div>
                </div>
              </div>
              {discussionList?.map((item, i) => (
                <>
                  <div class="DiscussionBox">
                    <span>CO</span>
                    <div class="DiscussionRight">
                      <h6>{item?.message}</h6>
                      <small>{moment(item.updatedAt).format("lll")}</small>
                    </div>
                  </div>
                  <div class="ReplyArea">
                    <a
                      onClick={(e) => {
                        updateReplyField({ flag: true, index: i });
                        updateReplyState({ ...replyState, message: "" });
                      }}
                    >
                      Reply
                    </a>
                    {item?.ReplyDiscussions?.map((ele) => (
                      <div class="DiscussionBox">
                        <span>RE</span>
                        <div class="DiscussionRight">
                          <h6>{ele?.message}</h6>
                          <small>{moment(ele.updatedAt).format("lll")}</small>
                        </div>
                      </div>
                    ))}

                    {replyField.index == i && (
                      <div class="DiscussionBox w-100">
                        <span>FM{item?.id}</span>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            value={replyState?.message}
                            onChange={(e) =>
                              updateReplyState({
                                ...replyState,
                                message: e.target.value,
                                messageId: item?.id,
                                jobId: item?.jobId,
                              })
                            }
                            placeholder="Add reply ....."
                          />
                          {discussionState.discussionError &&
                            !discussionState.message && (
                              <p style={{ color: "red" }}>
                                {discussionState.discussionError}
                              </p>
                            )}

                          <div class="DiscussionOverlay">
                            <a
                              onClick={() =>
                                updateReplyState({ ...replyState, message: "" })
                              }
                              class="CancelChat"
                            >
                              <img
                                src={require("../assets/images/plus-cancel1.png")}
                              />
                            </a>
                            <a onClick={handleAddReply} class="SendChat">
                              <img
                                src={require("../assets/images/forwardbutton.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={historyModal}
        onHide={hideHistoryModal}
        className="ModalBox MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideHistoryModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Support History</h4>
            </div>
            <div className="JobTitleArea mb-2">
              {/* <div className="JobBox">
                <h6>
                  <span>Folder:</span>&nbsp;Custom Layouts
                </h6>
              </div> */}
            </div>
            <div className="TableList TableListAlign CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Modified By</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {checkArray(historyListData) ? (
                    historyListData?.map((ele, i) => {
                      return (
                        <>
                          <tr>
                            <td>{ele?.modifiedBy}</td>
                            <td>{ele?.createdBy}</td>
                            <td>{ele?.createdAt?.split("T")[0]}</td>
                            <td>{ele?.activity}</td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <NoDataFound />
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={addHelpTabModal}
        onHide={hdeHelpTabModal}
        className="ModalBox MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hdeHelpTabModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create New Help Message</h4>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="form-group col-md-4">
                  <h6>Url link of the issue page</h6>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="https://dev.azure.com/i...."
                    name="applicationPath"
                    value={applicationPath}
                    onChange={handleInput}
                  />
                </div>

                <div className="form-group col-md-4">
                  <h6>Url link of the issue page</h6>

                  <div className="FolderForm">
                    <CKEditor
                      editor={ClassicEditor}
                      config={configObject}
                      data={helpMessage}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        updateLeadState({ ...iLeadState, helpMessage: data });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="Button d-flex justify-content-between mt-5">
              {/* <button
        className="Create FolderPermissionId"
        data-toggle="modal"
        data-target="HelpMessage"
        data-dismiss="modal"
      >
        Preview
      </button> */}
              <button
                onClick={() => addHelpTabFunc("Draft")}
                className="Create"
              >
                Save Draft
              </button>
              <button onClick={() => addHelpTabFunc("Send")} className="Create">
                Save&amp;Enable
              </button>
              <button onClick={hdeHelpTabModal} className="Outline">
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={updateTabHelpModal}
        onHide={hdeUpdateHelpTabModal}
        className="ModalBox MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hdeUpdateHelpTabModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Update New Help Message</h4>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="form-group col-md-4">
                  <h6>Url link of the issue page</h6>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="https://dev.azure.com/i...."
                    name="applicationPath"
                    value={applicationPath}
                    onChange={handleInput}
                  />
                </div>

                <div className="form-group col-md-4">
                  <h6>Url link of the issue page</h6>

                  <div className="FolderForm">
                    <CKEditor
                      editor={ClassicEditor}
                      config={configObject}
                      data={helpMessage}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        updateLeadState({ ...iLeadState, helpMessage: data });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="Button d-flex justify-content-between mt-5">
              {/* <button
        className="Create FolderPermissionId"
        data-toggle="modal"
        data-target="HelpMessage"
        data-dismiss="modal"
      >
        Preview
      </button> */}
              <button
                onClick={() => updateTabHelpFunc("Draft")}
                className="Create"
              >
                Update Draft
              </button>
              <button
                onClick={() => updateTabHelpFunc("Send")}
                className="Create"
              >
                Save&amp;Enable
              </button>
              <button onClick={hdeUpdateHelpTabModal} className="Outline">
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={customersDemoRequests}
        onHide={hideCustomerDemoModal}
        className="ModalBox MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideCustomerDemoModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Customers Demo Requests</h4>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Company</th>
                    <th>Solution Type</th>
                    <th>Date Received</th>
                    <th>Status</th>
                    <th>Email Contact</th>
                    <th>Phone Contact</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <>
                      <td>
                        {" "}
                        <Loader />
                      </td>
                    </>
                  ) : (
                    <>
                      {checkArray(customerDemoRequestListData?.data?.data) ? (
                        customerDemoRequestListData?.data?.data?.map(
                          (ele, ind) => {
                            return (
                              <>
                                <tr>
                                  <td>{ele?.firstName}</td>
                                  <td>{ele?.lastName}</td>
                                  <td>{ele?.companyName}</td>
                                  <td>{ele?.solutionType}</td>
                                  <td>{ele?.createdAt?.split("T")[0]}</td>
                                  <td>{ele?.status}</td>
                                  <td>{ele?.email}</td>
                                  <td>{ele?.phone}</td>
                                  <td>
                                    <div className="TableThreeDots">
                                      <ul className="">
                                        <li className="dropdown">
                                          <a
                                            className=""
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol
                                            className="dropdown-menu dropdown-menuwidth"
                                            style={{}}
                                          >
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                className="FolderPermissionId"
                                                onClick={() =>
                                                  showScheduleDemoModal(ele?.id)
                                                }
                                              >
                                                <img src="images/calendar.png" />
                                                Schedule Demo
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  removeCustomerDemoFunc(
                                                    ele?.id
                                                  )
                                                }
                                                href="javascript:void(0);"
                                              >
                                                <img src="images/dropdown-delete.png" />
                                                Delete Request
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          }
                        )
                      ) : (
                        <td>
                          {" "}
                          <NoDataFound />
                        </td>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className="PaginationArea">
              <h6>Showing 1 to 10 of 27 entries</h6>
              <h6>
                <img src="images/leftarrow.png" /> 01{" "}
                <img src="images/rightarrow.png" /> Out 10
              </h6>
            </div>
            <div className="Button text-center mt-4">
              <button
                className="Outline"
                data-toggle="modal"
                data-target="#NewContractRequests"
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={scheduleDemoModal}
        onHide={hideScheduleDemoModal}
        className="ModalBox MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideScheduleDemoModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Schedule Demo</h4>
            </div>
            <div className="FolderForm">
              <div className="ModalMediumheading">
                <h3 style={{ marginBottom: "10px!important" }}>
                  <span>Name :</span> John Doe
                </h3>
                <h3 style={{ marginBottom: "10px!important" }}>
                  <span>Company :</span> Builder 7
                </h3>
                <h3 style={{ marginBottom: "10px!important" }}>
                  <span>Email :</span> john.builder@gmail.com
                </h3>
                <h3>
                  <span>Phone :</span> (587) 555-5555
                </h3>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-groyp">
                    <h6>Date Option</h6>
                    <input
                      type="date"
                      className="form-control"
                      name="dateOptionOne"
                      value={dateOptionOne}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-groyp">
                    <h6>Date Option</h6>
                    <input
                      type="date"
                      className="form-control"
                      name="dateOptionTwo"
                      value={dateOptionTwo}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-groyp">
                    <h6>Date Option</h6>
                    <input
                      type="date"
                      className="form-control"
                      name="dateOptionThree"
                      value={dateOptionThree}
                      onChange={handleInput}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mt-4">
                <label className="CheckBox">
                  {" "}
                  Create Task
                  <input
                    type="checkbox"
                    name="task"
                    value={task}
                    checked={task}
                    onChange={(e) =>
                      updateLeadState({ ...iLeadState, task: e.target.checked })
                    }
                  />
                  <span className="checkmark" />
                </label>
              </div>
              <div className="form-group">
                <label className="CheckBox">
                  {" "}
                  Create Itinerary
                  <input type="checkbox" />
                  <span
                    className="checkmark"
                    name="itinenary"
                    value={itinenary}
                    checked={itinenary}
                    onChange={(e) =>
                      updateLeadState({
                        ...iLeadState,
                        itinenary: e.target.checked,
                      })
                    }
                  />
                </label>
              </div>
              <div className="Button d-flex justify-content-between mt-4">
                <button className="Create" onClick={addScheduleDemoFunc}>
                  Schedule Demo
                </button>
                <button className="Outline" onClick={hideScheduleDemoModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={addCustomerModal}
        onHide={hideAddCustomerModal}
        className="ModalBox MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAddCustomerModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Contract Code</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>Company</h6>
                <div className="CommonSelectBox" style={{ width: "100%" }}>
                  <select>
                    <option selected="">Select a category</option>
                    <option>Category 1</option>
                  </select>
                  <span>
                    <img src="images/down.png" />
                  </span>
                </div>
              </div>
              <div className="FeaturesArea">
                <div className="form-group mb-3">
                  <h6>Features</h6>
                  <label className="CheckBox fw-400 underline-grey">
                    {" "}
                    Subscription Discount
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="ml-3 mt-0">
                  <div className="mb-3">
                    <label className="Radio">
                      {" "}
                      10%
                      <input
                        type="radio"
                        name="subDiscount"
                        value={10}
                        onChange={handleRadioChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="mb-3">
                    <label className="Radio">
                      {" "}
                      15%
                      <input
                        type="radio"
                        name="subDiscount"
                        value={15}
                        onChange={handleRadioChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="mb-3">
                    <label className="Radio">
                      {" "}
                      20%
                      <input
                        type="radio"
                        name="subDiscount"
                        value={20}
                        onChange={handleRadioChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="mb-3">
                    <label className="Radio">
                      {" "}
                      30%
                      <input
                        type="radio"
                        name="subDiscount"
                        value={30}
                        onChange={handleRadioChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
                <div className="form-group mt-4">
                  <label className="CheckBox fw-400 underline-grey">
                    {" "}
                    Full Access
                    <input
                      type="checkbox"
                      name="fullAccess"
                      value={fullAccess}
                      onChange={handleCheckbox}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
              <div className="Button mb-4">
                <button className="Create">Generate Code</button>
              </div>
              <div className="form-group">
                <h6>Code</h6>
                <input
                  type="text"
                  className="form-control"
                  name="code"
                  value={code}
                  onChange={handleInput}
                />
              </div>
              <div className="Button d-flex justify-content-between">
                <button onClick={addCustomerCodeFunc} className="Create">
                  Create Code
                </button>
                <button onClick={hideAddCustomerModal} className="Outline">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={updatecontractcodeModal}
        onHide={hideUpdateContractModal}
        className="ModalBox MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideUpdateContractModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Update Contract Code</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>Company</h6>
                <div className="CommonSelectBox" style={{ width: "100%" }}>
                  <select>
                    <option selected="">Select a category</option>
                    <option>Category 1</option>
                  </select>
                  <span>
                    <img src="images/down.png" />
                  </span>
                </div>
              </div>
              <div className="FeaturesArea">
                <div className="form-group mb-3">
                  <h6>Features</h6>
                  <label className="CheckBox fw-400 underline-grey">
                    {" "}
                    Subscription Discount
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="ml-3 mt-0">
                  <div className="mb-3">
                    <label className="Radio">
                      {" "}
                      10%
                      <input
                        type="radio"
                        name="subDiscount"
                        value={10}
                        onChange={handleRadioChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="mb-3">
                    <label className="Radio">
                      {" "}
                      15%
                      <input
                        type="radio"
                        name="subDiscount"
                        value={15}
                        onChange={handleRadioChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="mb-3">
                    <label className="Radio">
                      {" "}
                      20%
                      <input
                        type="radio"
                        name="subDiscount"
                        value={20}
                        onChange={handleRadioChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="mb-3">
                    <label className="Radio">
                      {" "}
                      30%
                      <input
                        type="radio"
                        name="subDiscount"
                        value={30}
                        onChange={handleRadioChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
                <div className="form-group mt-4">
                  <label className="CheckBox fw-400 underline-grey">
                    {" "}
                    Full Access
                    <input
                      type="checkbox"
                      name="fullAccess"
                      value={fullAccess}
                      onChange={handleCheckbox}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
              <div className="Button mb-4">
                <button className="Create">Generate Code</button>
              </div>
              <div className="form-group">
                <h6>Code</h6>
                <input
                  type="text"
                  className="form-control"
                  name="code"
                  value={code}
                  onChange={handleInput}
                />
              </div>
              <div className="Button d-flex justify-content-between">
                <button onClick={updateContractCodeFunc} className="Create">
                  Update Code
                </button>
                <button onClick={hideUpdateContractModal} className="Outline">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={viewContractCodeModal}
        onHide={hideContractCodeDetailsModal}
        className="ModalBox MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideContractCodeDetailsModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>View Contract Code</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>Company</h6>
                <div className="CommonSelectBox" style={{ width: "100%" }}>
                  <select>
                    <option selected="">Select a category</option>
                    <option>Category 1</option>
                  </select>
                  <span>
                    <img src="images/down.png" />
                  </span>
                </div>
              </div>
              <div className="FeaturesArea">
                <div className="form-group mb-3">
                  <h6>Features</h6>
                  <label className="CheckBox fw-400 underline-grey">
                    {" "}
                    Subscription Discount
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="ml-3 mt-0">
                  <div className="mb-3">
                    <label className="Radio">
                      {" "}
                      10%
                      <input
                        type="radio"
                        name="subDiscount"
                        value={10}
                        onChange={handleRadioChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="mb-3">
                    <label className="Radio">
                      {" "}
                      15%
                      <input
                        type="radio"
                        name="subDiscount"
                        value={15}
                        onChange={handleRadioChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="mb-3">
                    <label className="Radio">
                      {" "}
                      20%
                      <input
                        type="radio"
                        name="subDiscount"
                        value={20}
                        onChange={handleRadioChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="mb-3">
                    <label className="Radio">
                      {" "}
                      30%
                      <input
                        type="radio"
                        name="subDiscount"
                        value={30}
                        onChange={handleRadioChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
                <div className="form-group mt-4">
                  <label className="CheckBox fw-400 underline-grey">
                    {" "}
                    Full Access
                    <input
                      type="checkbox"
                      name="fullAccess"
                      value={fullAccess}
                      onChange={handleCheckbox}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
              <div className="Button mb-4">
                <button className="Create">Generate Code</button>
              </div>
              <div className="form-group">
                <h6>Code</h6>
                <input
                  type="text"
                  className="form-control"
                  name="code"
                  value={code}
                  onChange={handleInput}
                />
              </div>
              <div className="Button d-flex justify-content-between">
                {/* <button onClick={updateContractCodeFunc} className="Create">Update Code</button> */}
                <button
                  onClick={hideContractCodeDetailsModal}
                  className="Outline"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={contractRequestListingModal}
        onHide={hideContactRequestListingModal}
        className="ModalBox MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideContactRequestListingModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>New Contract Requests</h4>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Company Id</th>
                    <th>Company Name</th>
                    <th>Subscription Plan</th>
                    <th>Date Received</th>
                    <th>Status</th>
                    <th>Email Contact</th>
                    {/* <th>Phone Contact</th> */}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {checkArray(customerRequestListData?.data) &&
                    customerRequestListData?.data?.map((ele, i) => {
                      return (
                        <>
                          <tr>
                            <td>{ele?.CompanyId}</td>
                            <td>{ele?.companyName}</td>
                            <td>{ele?.PlanName}</td>
                            <td>{ele?.createdAt?.split("T")[0]}</td>
                            <td>{ele?.signatureStatus}</td>
                            <td>{ele?.signeeEmail}</td>
                            {/* <td>(58 7)555-5555</td> */}
                            <td>
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol
                                      className="dropdown-menu dropdown-menuwidth"
                                      style={{}}
                                    >
                                      <li>
                                        <a
                                          onClick={() =>
                                            showAddContactRequestModal(
                                              ele?.CompanyId
                                            )
                                          }
                                          href="javascript:void(0);"
                                        >
                                          <img src="images/Action-1.png" />
                                          Create Contract
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() =>
                                            removeRequestFunc(ele?.CompanyId)
                                          }
                                          href="javascript:void(0);"
                                        >
                                          <img src="images/dropdown-delete.png" />
                                          Delete Request
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() =>
                                            sendForSignatureFunc(
                                              ele?.id,
                                              ele?.CompanyId
                                            )
                                          }
                                          href="javascript:void(0);"
                                        >
                                          <img src="images/send_signature.png" />
                                          Send for Signature
                                        </a>
                                      </li>
                                      {/* <li>
                      <a href="javascript:void(0);">
                        <img src="images/view_contract.png" />
                        View Contract
                      </a>
                    </li> */}
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="PaginationArea">
              <h6>Showing 1 to 10 of 27 entries</h6>
              <h6>
                <img src="images/leftarrow.png" /> 01{" "}
                <img src="images/rightarrow.png" /> Out 10
              </h6>
            </div>
            <div className="Button text-center mt-4">
              <button
                onClick={hideContactRequestListingModal}
                className="Outline"
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={addContractRequestModal}
        onHide={hideAddContactRequestModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAddContactRequestModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Contract</h4>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <h6>Company</h6>
                    <p className="ml-0">Builder {modalId}</p>
                  </div>
                  <div className="form-group">
                    <h6>Subscription Plan</h6>
                    <div className="CommonSelectBox" style={{ width: "100%" }}>
                      <select
                        name="PlanId"
                        value={PlanId}
                        onChange={handleInput}
                      >
                        <option value="">Select</option>
                        {checkArray(planListData?.plans) &&
                          planListData?.plans?.map((item, ind) => {
                            return (
                              <>
                                <option value={item?.id}>
                                  {item?.planName}
                                </option>
                              </>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="ContractDurationTable">
                    <table>
                      <thead>
                        <tr>
                          <th>Contract Duration</th>
                          <th>Payment Option</th>
                          <th>Billing Option</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            \
                            <div className="form-group">
                              <label className="Radio">
                                {" "}
                                1 Year
                                <input
                                  type="radio"
                                  name="contractDuration"
                                  value={"1 year"}
                                  onChange={handleRadioChange}
                                />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <label className="Radio">
                                {" "}
                                Cheque
                                <input
                                  type="radio"
                                  name="paymentOption"
                                  value={"cheque"}
                                  onChange={handleRadioChange}
                                />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <label className="Radio">
                                {" "}
                                Every 6 months
                                <input
                                  type="radio"
                                  name="billingOption"
                                  value={"6 Month"}
                                  onChange={handleRadioChange}
                                />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-group">
                              <label className="Radio">
                                {" "}
                                3 Year
                                <input
                                  type="radio"
                                  name="contractDuration"
                                  value={"3 year"}
                                  onChange={handleRadioChange}
                                />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <label className="Radio">
                                {" "}
                                Credit Card
                                <input
                                  type="radio"
                                  name="paymentOption"
                                  value={"Credit Card"}
                                  onChange={handleRadioChange}
                                />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <label className="Radio">
                                {" "}
                                Once a Year
                                <input
                                  type="radio"
                                  name="billingOption"
                                  value={"Once a year"}
                                  onChange={handleRadioChange}
                                />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-group">
                              <label className="Radio">
                                {" "}
                                5 Year
                                <input
                                  type="radio"
                                  name="contractDuration"
                                  value={"5 year"}
                                  onChange={handleRadioChange}
                                />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <label className="Radio">
                                {" "}
                                Interact
                                <input
                                  type="radio"
                                  name="paymentOption"
                                  value={"Interact"}
                                  onChange={handleRadioChange}
                                />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-group">
                              <label className="Radio">
                                {" "}
                                10 Year
                                <input
                                  type="radio"
                                  name="contractDuration"
                                  value={"10 year"}
                                  onChange={handleRadioChange}
                                />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <label className="Radio">
                                {" "}
                                Direct Deposit
                                <input
                                  type="radio"
                                  name="paymentOption"
                                  value={"Direct Deposit"}
                                  onChange={handleRadioChange}
                                />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="ModalBigheading mt-3">
                <h3>
                  <span>Payment</span>
                </h3>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <h6>Tax Location</h6>
                    <div className="CommonSelectBox" style={{ width: "100%" }}>
                      <select
                        name="tax_location"
                        value={tax_location}
                        onChange={handleInput}
                      >
                        <option value="">Select Tax profile</option>
                        {checkArray(taxProfileListData) &&
                          taxProfileListData?.map((ele, i) => {
                            return (
                              <>
                                <option value={ele?.id}>{ele?.name}</option>
                              </>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <h6>Tax Name</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="5%"
                      name="tax_name"
                      value={tax_name}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <h6>Tax Percent</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="5%"
                      name="tax_p"
                      value={tax_p}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="Button mb-4">
                    <button onClick={addTaxFunc} className="Create">
                      Add Tax
                    </button>
                  </div>
                </div>
                <div className="col-md-12">
                  <h6>Taxes</h6>
                  {checkArray(payment) &&
                    payment?.map((ele, i) => {
                      return (
                        <>
                          <h6 className="fw-400">
                            {ele?.tax_name} {ele?.tax_p}%{" "}
                            <img
                              onClick={() => removeTaxFunc(i)}
                              src="images/Cross.png"
                              className="ml-5"
                            />
                          </h6>
                        </>
                      );
                    })}
                </div>
              </div>
              <div className="ModalMediumheading mt-3">
                <h3>
                  <span>Set Payment Dates</span>
                </h3>
              </div>
              <div className="EnterProjectTable">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Payment Date</th>
                      <th>Payment Amount</th>
                      <th>Payment After Tax Amount</th>
                      <th>Payment Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkArray(paymentDates) &&
                      paymentDates?.map((ele, i) => {
                        return (
                          <>
                            <tr>
                              <td>1</td>
                              <td>
                                <div>
                                  <p className="text-grey-80 fw-500">
                                    {ele?.paymentDate}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="form-group">
                                  <div>
                                    <p className="text-grey-80 fw-500 ml-0">
                                      ${ele?.paymentAmount}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="form-group">
                                  <div>
                                    <p className="text-grey-80 fw-500 ml-0">
                                      ${ele?.paymentAfterTax}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="form-group">
                                  <div>
                                    <p className="text-grey-80 fw-500 ml-0">
                                      {ele?.paymentStatus}
                                    </p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}

                    <tr>
                      <td>2</td>
                      <td>
                        <div className="form-group mr-5">
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Phase 1"
                            name="paymentDate"
                            value={paymentDate}
                            onChange={handleInput}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="$"
                            name="paymentAmount"
                            value={paymentAmount}
                            onChange={handleInput}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="$"
                            name="paymentAfterTax"
                            value={paymentAfterTax}
                            onChange={handleInput}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="form-group">
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="$"
                              name="paymentStatus"
                              value={paymentStatus}
                              onChange={handleInput}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="col-md-12">
                  <div className="Button mb-4">
                    <button onClick={addPaymentFunc} className="Create">
                      Add Payment Dates{" "}
                    </button>
                  </div>
                </div>
              </div>
              <div className="ModalMediumheading mt-4">
                <h3 style={{ marginBottom: "10px!important" }}>
                  <span>Project as an Entity Options</span>
                </h3>
              </div>
              <div className="ContractDurationTable">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="form-group mb-1">
                          <label className="Radio">
                            {" "}
                            Fixed Unitary Subscription Value
                            <input
                              type="radio"
                              name="fixedUnitarySubscriptionValue"
                              value={fixedUnitarySubscriptionValue}
                              onChange={(e) =>
                                updateLeadState({
                                  ...iLeadState,
                                  fixedUnitarySubscriptionValue: e.target.value,
                                })
                              }
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className="form-group mb-1">
                          <input
                            type="text"
                            className="form-control width-250"
                            placeholder="$"
                            name="fixedUnitarySubscriptionValue"
                            value={fixedUnitarySubscriptionValue}
                            onChange={(e) =>
                              updateLeadState({
                                ...iLeadState,
                                fixedUnitarySubscriptionValue: e.target.value,
                              })
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-group mb-1">
                          <label className="Radio">
                            {" "}
                            Unlimited Projects for Fixed Value
                            <input
                              type="radio"
                              name="unlimitedProjectsForFixedValue"
                              value={unlimitedProjectsForFixedValue}
                              onChange={(e) =>
                                updateLeadState({
                                  ...iLeadState,
                                  unlimitedProjectsForFixedValue:
                                    e.target.value,
                                })
                              }
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                      </td>
                      <td>
                        <div class="form-group mb-1">
                          <input
                            type="text"
                            class="form-control width-250"
                            placeholder="unlimitedProjectsForFixedValue..."
                            name="unlimitedProjectsForFixedValue"
                            value={unlimitedProjectsForFixedValue}
                            onChange={(e) =>
                              updateLeadState({
                                ...iLeadState,
                                unlimitedProjectsForFixedValue: e.target.value,
                              })
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-group mb-1">
                          <label className="Radio">
                            {" "}
                            Variable Subscription Value
                            <input
                              type="radio"
                              class="form-control width-250"
                              name="variableSubscriptionValue"
                              value={variableSubscriptionValue}
                              onChange={(e) =>
                                updateLeadState({
                                  ...iLeadState,
                                  variableSubscriptionValue: e.target.value,
                                })
                              }
                            />
                            <span className="checkmark" name="" />
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className="form-group mb-1">
                          <input
                            type="text"
                            name="variableSubscriptionValue"
                            value={variableSubscriptionValue}
                            onChange={(e) =>
                              updateLeadState({
                                ...iLeadState,
                                variableSubscriptionValue: e.target.value,
                              })
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="CommonModalArea">
                <div className="form-group">
                  <h5>Contract Code</h5>
                  <div className="CommonModalBox">
                    <h5 className="CursorPointer">
                      <figure>
                        <img src="images/update_contract.png" />
                      </figure>{" "}
                      <span className="Count">3</span> Contract Code
                    </h5>
                    <br />
                    <div className="col-md-4">
                      <div className="form-group">
                        <h6>Contract Code Dropdown</h6>
                        <div
                          className="CommonSelectBox"
                          style={{ width: "100%" }}
                        >
                          <select
                            name="contractCodeId"
                            value={contractCodeId}
                            onChange={handleInput}
                          >
                            <option value="">Select Contract Code</option>
                            {checkArray(customerCodeListData?.data) &&
                              customerCodeListData?.data?.map((ele, i) => {
                                return (
                                  <>
                                    <option value={ele?.id}>{ele?.code}</option>
                                  </>
                                );
                              })}
                          </select>
                          <span>
                            <img src="images/down.png" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <p className="text-black d-block ml-0 mt-3">{customerCodeListData?.data?.find((x) => x.id === contractCodeId)?.contrac}</p> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-7">
                  <div className="ContractTotalsTable">
                    <table>
                      <thead>
                        <tr>
                          <th>Contract Totals</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{planName}</td>
                          <td>{planListAmount}</td>
                        </tr>
                        <tr>
                          <td>Unlimited Project for fixed value</td>
                          <td>$ {unlimitedProjectsForFixedValue}</td>
                        </tr>
                        <tr>
                          <td>Contract Code Discount(10%)</td>
                          <td>$ {contractDuration}</td>
                        </tr>
                        <tr>
                          <td>GST(5%)</td>
                          <td>$ {gstamount}</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>Contract Totals</td>
                          <td>$ {totalContractAmount}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <h6>Ibuild Signee</h6>
                    <div className="CommonSelectBox" style={{ width: "100%" }}>
                      <select name="">
                        <option value="Signee 1">Select Signee 1</option>
                        <option value="Signee 2">Select Signee 2</option>
                      </select>
                      <span>
                        <img src="images/down.png" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mx-auto">
                  {/* <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <h6>Signature Status</h6>
                      <p class="ml-0 text-danger-red">Pending with John Sales</p>
                    </div>
                  </div>
                  <div class="col-md-5 ml-auto">
                    <div class="form-group">
                      <h6>Action</h6>
                      <button class="SignDocumentBtn">Sign Document</button>
                    </div>
                  </div>
                </div> */}
                </div>
                <div className="col-md-4">
                  <h6>Builder Signee</h6>
                  <div className="form-group">
                    <h6>Name</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert Signee's Name"
                      name="signeeName"
                      value={signeeName}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                {/* <div class="form-group col-md-6 mx-auto">
                <p class="GreenTextColor fw-12 ml-0">Signee</p>
              </div> */}
                <div className="form-group col-md-6 mx-auto">
                  {/* <p class="GreenTextColor fw-12 ml-0">Signee</p> */}
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <h6>Email</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="builder7@builder7.com"
                      name="signeeEmail"
                      value={signeeEmail}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="mb-4">
                    <a
                      href="javascript:void(0);"
                      className="AnchorBtn"
                      onClick={() => addCustomerRequestFun("SEND")}
                    >
                      Generate/Update Agreement
                    </a>
                  </div>
                </div>
              </div>
              {showModalCheck ? (
                <div className="CommonModalArea">
                  <div className="form-group">
                    <h5>Subscription Agreement</h5>
                    <div className="CommonModalBox">
                      <h5
                        className="CursorPointer"
                        onClick={showAddSubscriptionModal}
                      >
                        <figure>
                          <img src="images/subscription_icon.png" />
                        </figure>{" "}
                        <span className="Count">3</span> Subscription Agreement
                      </h5>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="Button d-flex justify-content-between mt-4">
                <button
                  onClick={() => sendForSignatureFunc()}
                  className="Create"
                >
                  Send Contract for Signature
                </button>
                <button
                  onClick={() => addCustomerRequestFun("DRAFT")}
                  className="Create"
                >
                  Save Draft
                </button>
                <button
                  onClick={hideAddContactRequestModal}
                  className="Outline"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={subscriptionModal}
        onHide={hideAddSubscriptionModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideAddSubscriptionModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Subscription Agreement</h4>
            </div>
            <div className="FolderForm">
              <div className="ModalBigheading">
                <h3>
                  <span className="fw-500">Status: </span>Pending Company
                  Signature
                </h3>
              </div>
              <div className="AgreementViewArea">
                <div className="AgreementViewTop">
                  <a href="javscript:void(0)">
                    <img src="images/modal-big-logo.png" />
                  </a>
                  <div className="AgreementViewTopRight">
                    <h3>iBuild</h3>
                    <p>312 Weddenburn Rd SE</p>
                    <p>Calgary, AB CA, T2J 1J1</p>
                    <p>
                      4034005933 www.ibuildapplications.com
                      linden@ibuildapplications.com
                    </p>
                    <p>
                      “The bitterness of poor quality remains long after the
                      sweetness of meeting
                      <br />
                      the schedule has been gorgotten”
                    </p>
                  </div>
                </div>
                <div className="StatementAdjustmentArea px-4">
                  <h6>Statement of Adjustments</h6>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Client Name</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={companydata?.name}
                        />
                      </div>
                      <div className="form-group">
                        <label>Address</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={companydata?.streetAddressLine1}
                        />
                      </div>
                      <div className="form-group">
                        <label>City</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={companydata?.city}
                        />
                      </div>
                      <div className="form-group">
                        <label>State/Provice</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={companydata?.province}
                        />
                      </div>
                      <div className="form-group">
                        <label>Zip Code</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={companydata?.zipCode}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={companydata?.email}
                        />
                      </div>
                      <div className="form-group">
                        <label>Phone</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={companydata?.primaryNumber}
                        />
                      </div>
                      <div className="form-group">
                        <label>Sales Consultant</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={companydata?.zipCode}
                        />
                      </div>
                      <div className="form-group">
                        <label>Service</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={companydata?.zipCode}
                        />
                      </div>
                      <div className="form-group">
                        <label>Contract Date</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={companydata?.createdAt?.split("T")[0]}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <h6 className="Title">PLAN DETAILS</h6>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={companydata?.planName}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Description</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Total Cost Pre-Tax</label>
                        <p disabled className="LightOrange">
                          {" "}
                          {companydata?.planName}
                        </p>
                      </div>
                    </div>
                    <div className="col-12">
                      <h6 className="Title">Project as Entity Options</h6>
                      <div className="StatementAdjustmentBottom">
                        <label>Unlimited Projects for Fixed Value</label>
                        <p className="BgBlue2">$xx.xx</p>
                      </div>
                      <div>
                        <h6 className="Title">Contract Duration</h6>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>1 Year </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Start Date</label>
                            <input type="text" className="form-control" />
                          </div>
                          <div className="form-group">
                            <label>End Date</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <h6 className="Title">Contract Code</h6>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>CO4E10DISC</label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Benefits</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-12">
                      <h6 className="Title">Payment &amp; Billing</h6>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Payment Option</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Billing Option</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Taxes</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <h6
                        className="Title border-0 mb-0 px-0"
                        style={{
                          marginBottom: "10px!important",
                          padding: "0px!important",
                        }}
                      >
                        Payment Dates
                      </h6>
                      <div className="StatementAdjustmentTable">
                        <table>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Payment Date</th>
                              <th>Payment Amount</th>
                              <th>
                                <div className="text-left ml-5">
                                  Payment After Tax Amount
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {checkArray(paymentDates) &&
                              paymentDates?.map((ele, i) => {
                                console.log("elelelee", ele);
                                return (
                                  <>
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>{ele?.paymentDate}</td>
                                      <td>
                                        <div className="LightGreenToo mr-5">
                                          ${ele?.paymentAmount}
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          className="LightGreenToo mx-5"
                                          style={{ textAlign: "left" }}
                                        >
                                          ${ele?.paymentAfterTax}
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                      <h6 className="Title mt-5">Summary</h6>
                      <div className="StatementAdjustmentBottom">
                        <label>Plan </label>
                        <p className="LightOrange">{planName}</p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Unlimited Projects for Fixed Value</label>
                        <p className="LightBlue2">
                          $ {unlimitedProjectsForFixedValue}
                        </p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Contract Code Discount</label>
                        <p className="LightGreen">$ {contractDuration}</p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Taxes (GST 5%)</label>
                        <p className="LightGrey2"> ${gstamount}</p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Grand Total</label>
                        <p className="LightRed2">${contractDuration}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ChangeOrderCreatArea mt-4">
                  <div className="row">
                    <div className="col-lg-12"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="text" />
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 offset-2">
                      <div className="InputBox">
                        <div className="form-group">
                          <SignaturePad
                            ref={signCanvas}
                            onEnd={() =>
                              updateLeadState({
                                ...iLeadState,
                                signature: signCanvas?.current
                                  ?.getTrimmedCanvas()
                                  .toDataURL("image/png"),
                              })
                            }
                          />
                          {/* <a onClick={clearSignature} href="javascript:void(0);">
                        <img src="images/circle_minus.png" />
                      </a> */}
                          {/* <div class="InsertSignature">
                            </div> */}
                          <label>John Doe</label>
                          <label>Sales Manager</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
            <div className="col-md-3">
              <div className="InputBox">
                <div className="form-group">
                  <input type="text" />
                  <label>Date</label>
                </div>
              </div>
            </div>
            <div className="col-md-3 offset-2">
              <div className="InputBox">
                <div className="form-group">
                  <input type="text" />
                  <label>John Doe</label>
                  <label>Builder 7</label>
                </div>
              </div>
            </div>
          </div> */}
                </div>
              </div>
              <div className="Button d-flex justify-content-between">
                <button className="Create">Print</button>
                <button
                  onClick={() => sendForSignatureFunc()}
                  className="Create"
                >
                  Save
                </button>
                <button
                  className="Create FolderPermissionId"
                  onClick={() => showSubscriptionHistoryModal()}
                >
                  <img src="images/history_icon.png" />
                  History
                </button>
                <button className="Outline" onClick={hideAddSubscriptionModal}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={sendSignatureModal}
        onHide={hideSendSignatureMOdal}
        className="ModalBox MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSendSignatureMOdal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBoxBrand ModalTitleBoxBrandOrange">
              <h4>Multi-Family and Residential Platform</h4>
              <a href="javascript:void(0);">
                <img src="images/logo-orange.png" />
              </a>
            </div>
            <div className="FolderForm">
              <form>
                <div className="SubscriptionArea">
                  <h3>
                    <span>Ibuild Multi-Family and Residential Platform</span>{" "}
                    Contract Signing!
                  </h3>
                  <div className="BuilderArea">
                    <h4>Builder 7,</h4>
                    <h5 className="NewHeading">
                      Please click on the link below to review and sign your
                      contract:
                    </h5>
                    <a href="javascript:void(0);" className="NewAnchor">
                      https://ibuildapplications.com/builder7/contract
                    </a>
                    <h5 className="NewHeading">
                      If you have any questions, please contact your sales
                      representative:
                    </h5>
                  </div>
                  <div className="CompanyDetailsTable">
                    <h6>Company Details</h6>
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Primary phone Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Builder 1</td>
                          <td>admin.builder1@builder1.com</td>
                          <td>(587) 555-5555</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="RegardsArea">
                    <h5>Best Regards,</h5>
                    <p>Ibuild Sales Team</p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={subscriptionHistoryModal}
        onHide={hideSubscriptionHistoryModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideSubscriptionHistoryModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Subscription Agreement History</h4>
            </div>
            <div className="ModalMediumheading">
              <h3 className="mb-1">
                <span>Company:</span> Builder 7
              </h3>
              <h3>
                <span>Company id</span> 4E
              </h3>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Modified By</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {checkArray(customerHistoryListData?.data) &&
                    customerHistoryListData?.data?.map((ele, i) => {
                      return (
                        <>
                          <tr>
                            <td>{ele?.modifiedBy}</td>
                            <td>{ele?.createdBy}</td>
                            <td>{ele?.createdAt?.split("T")[0]}</td>
                            <td>{ele?.activity}</td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Systemadministration;
