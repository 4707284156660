import { update } from "lodash";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createResidentailConfrimationAction,
  createResidentialAction,
  getAllFoundationListAction,
  getAllHomeListAction,
  getAllProjectListAction,
  getAllTaxProfileAction,
  getResidentialDetailsAction,
  updateResidentialAction,
} from "../redux/action/jobAction";
import { checkArray } from "../utils/CheckType";
import { getBlockList, getCitiesList, getSpecificationDocsList, getStateList, getTimeZoneList } from "../redux/action/authAction";
import { sucessToast } from "../utils/toast";
import { getAllBlockListAction } from "../redux/action/estimationAction";
const modalInitState = {
  ProjectFoundationModal: false,
  DetailsModal: false,
  squareFootModal: false,
  jobAddressModal: false,
  confirmationModal: false,
  modalId: "",
  modalState: "",
  JobStaticId: "",
};

const initLeadState = {
  subDivisionArray: [],
  ProjectId: "",
  subdividision: "",
  singleFamilyResid: false,
  singleFamilyFoundationId: "",
  singleFamilyBasementFoundation: false,
  detacGarOrWorkOrMajStorShed: false,
  parkingPad: false,
  secondarySuite: false,
  secoSuiOverFounOptBelow: false,
  secoSuiOverFoundationId: "",
  secondarySuiteBasementFoundation: false,
  buildingPermit: "",
  specificationDocument: "",
  baseModel: "",
  taxProfile: "",
  homeType: "",
  defaultCurrency: "",
  timeZone: "",
  intJobWithAccSof: false,
  sqFtMainFloor: "",
  sqFtSecondFloor: "",
  sqFtThirdFloor: "",
  sqFtGarageFloor: "",
  sqFtBaseOrLowLevel: "",
  jobAddress: "",
  lot: "",
  city: "",
  zipCode: "",
  country: "",
  province: "",
  jobType: "",
  block:'',
  phase:"",
  sqFtTotalFloor:"",
  curStep: "",
};
function ResidentialJobModal({
  residentialJobModal,
  setResidentialJobModal,
  setShow,
}) {
  const [iModalState, updateModalState] = useState(modalInitState);
  const [iLeadState, updateLeadState] = useState(initLeadState);
  const {
    subDivisionArray,
    ProjectId,
    subdividision,
    singleFamilyResid,
    singleFamilyFoundationId,
    singleFamilyBasementFoundation,
    detacGarOrWorkOrMajStorShed,
    parkingPad,
    secondarySuite,
    secoSuiOverFounOptBelow,
    secoSuiOverFoundationId,
    secondarySuiteBasementFoundation,
    buildingPermit,
    specificationDocument,
    baseModel,
    taxProfile,
    homeType,
    defaultCurrency,
    timeZone,
    intJobWithAccSof,
    sqFtMainFloor,
    sqFtSecondFloor,
    sqFtThirdFloor,
    sqFtGarageFloor,
    sqFtBaseOrLowLevel,
    jobAddress,
    lot,
    city,
    zipCode,
    country,
    province,
    jobType,
    block,
    phase,
    sqFtTotalFloor,
    curStep,
  } = iLeadState;
  const {
    projectListData,
    foundationListData,
    homeListData,
    taxProfileListData,
    residentialDetailsData,
  } = useSelector((state) => state.jobReducer);
  // console.log("residentialDetailsData",residentialDetailsData);
  const dispatch = useDispatch();
  const { countryData, cityData, stateData, timeZoneData, blockListData,speicficReleasedDocsListData } = useSelector(
    (state) => state.authReducer
  );
  console.log("speicficReleasedDocsListData",speicficReleasedDocsListData)
  const {
    ProjectFoundationModal,
    DetailsModal,
    squareFootModal,
    jobAddressModal,
    confirmationModal,
    modalId,
    modalState,
    JobStaticId,
  } = iModalState;
  useEffect(() => {
    // dispatch(getAllProjectListAction("Single Family"));
    dispatch(getAllFoundationListAction());
    dispatch(getAllHomeListAction());
     dispatch(getAllTaxProfileAction());
    dispatch(getTimeZoneList());
    dispatch(getResidentialDetailsAction(ProjectId, JobStaticId));
    
  }, []);

  useEffect(() => {
    updateLeadState({
      ...iLeadState,
      subDivisionArray: projectListData?.find((x) => x.id == ProjectId)
        ?.subdivisions,
      // ProjectId:residentialDetailsData.ProjectId,
      subdividision: residentialDetailsData?.subdividision,
      singleFamilyResid: residentialDetailsData?.singleFamilyResid,
      singleFamilyFoundationId:
        residentialDetailsData?.singleFamilyFoundationId,
      singleFamilyBasementFoundation:
        residentialDetailsData?.singleFamilyBasementFoundation,
      detacGarOrWorkOrMajStorShed:
        residentialDetailsData?.detacGarOrWorkOrMajStorShed,
      parkingPad: residentialDetailsData?.parkingPad,
      secondarySuite: residentialDetailsData?.secondarySuite,
      secoSuiOverFounOptBelow: residentialDetailsData?.secoSuiOverFounOptBelow,
      secoSuiOverFoundationId: residentialDetailsData?.secoSuiOverFoundationId,
      secondarySuiteBasementFoundation:
        residentialDetailsData?.secondarySuiteBasementFoundation,
      buildingPermit: residentialDetailsData?.buildingPermit,
      specificationDocument: residentialDetailsData?.specificationDocument,
      baseModel: residentialDetailsData?.baseModel,
      taxProfile: residentialDetailsData?.taxProfile,
      homeType: residentialDetailsData?.homeType,
      defaultCurrency: residentialDetailsData?.defaultCurrency,
      timeZone: residentialDetailsData?.timeZone,
      intJobWithAccSof: residentialDetailsData?.intJobWithAccSof,
      sqFtMainFloor: residentialDetailsData?.sqFtMainFloor,
      sqFtSecondFloor: residentialDetailsData?.sqFtSecondFloor,
      sqFtThirdFloor: residentialDetailsData?.sqFtThirdFloor,
      sqFtGarageFloor: residentialDetailsData?.sqFtGarageFloor,
      sqFtBaseOrLowLevel: residentialDetailsData?.sqFtBaseOrLowLevel,
      jobAddress: residentialDetailsData?.jobAddress,
      lot: residentialDetailsData?.lot,
      city: residentialDetailsData?.city,
      zipCode: residentialDetailsData?.zipCode,
      country: residentialDetailsData?.country,
      province: residentialDetailsData?.province,
      jobType: residentialDetailsData?.jobType,
      // JobStaticId: residentialDetailsData?.JobStaticId,
    });
  }, [residentialDetailsData, residentialJobModal, ProjectId]);

  useEffect(() => {
    if(country)
    dispatch(
      getStateList(
        countryData?.countries?.find((x) => x.name == country)?.isoCode
      )
    );
  }, [country,countryData?.countries,dispatch]);
  useEffect(() => {
    dispatch(
      getCitiesList(
        countryData?.countries?.find((x) => x.name == country)?.isoCode,
        stateData?.states?.find((x) => x.name == province)?.isoCode
      )
    );
  }, [province]);
  const handleRadio = (e) => {
    const { name, value } = e.target;
    updateLeadState({
      ...iLeadState,
      [name]: value,
    });
  };

  const handleCheckbox = (e) => {
    const { name, value, checked } = e.target;
    updateLeadState({
      ...iLeadState,
      [name]: checked,
    });
  };
  const hideProjectFoundationModal = () => {
    setResidentialJobModal(false);
  };

  const showDetailsModal = (id) => {
    // console.log('--------.', id)
    dispatch(getBlockList())
    // ProjectId
    dispatch(getSpecificationDocsList())
    dispatch(getResidentialDetailsAction(ProjectId, id));
    updateModalState({ ...iModalState, DetailsModal: true, JobStaticId: id });
    // setResidentialJobModal(false);
  };

  const hideDetailsModal = () => {
    updateModalState({ ...iModalState, DetailsModal: false });
    setResidentialJobModal(true);
  };

  const showSquareFootModal = () => {
    updateModalState({
      ...iModalState,
      DetailsModal: false,
      squareFootModal: true,
    });
  };

  const hidesquareFootModal = () => {
    updateModalState({
      ...iModalState,
      DetailsModal: true,
      squareFootModal: false,
    });
  };

  const showJobAddressModal = () => {
    updateModalState({
      ...iModalState,
      jobAddressModal: true,
      squareFootModal: false,
    });
  };

  const hideJobAddressModal = () => {
    updateModalState({
      ...iModalState,
      squareFootModal: true,
      jobAddressModal: false,
    });
  };

  const showConfirmationModal = () => {
   
    updateModalState({
      ...iModalState,
      jobAddressModal: false,
      confirmationModal: true,
    });
  };
// console.log("jobStaticId", JobStaticId);
  const hideConfirmationModal = () => {
    dispatch(createResidentailConfrimationAction(ProjectId, JobStaticId)).then((res) =>{
      console.log('ressss', res)
      sucessToast("Residential job created successfully")
      dispatch(getResidentialDetailsAction(ProjectId, JobStaticId));
     })
   
    updateModalState({ ...iModalState, confirmationModal: false });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    updateLeadState({ ...iLeadState, [name]: value });
  };

  const addResidentialJobFunc = (e) => {
    e.preventDefault();
    let data = {
      ProjectId,
      subdividision,
      singleFamilyResid,
      singleFamilyBasementFoundation,
      detacGarOrWorkOrMajStorShed,
      parkingPad,
      secondarySuite,
      singleFamilyFoundationId,
      secoSuiOverFounOptBelow,
      secoSuiOverFoundationId,
      secondarySuiteBasementFoundation,
      
      curStep: 1,
    };
    dispatch(createResidentialAction(ProjectId, data)).then((res) => {
      // console.log("ressssssssss",res?.details?.id);
      showDetailsModal(res?.details?.id);
      updateLeadState({ ...iLeadState, JobStaticId: res?.details?.id });
    });
  };

  const updateResidentialJobFunc = (e, curStep) => {
    e.preventDefault();
    let data = {
      // ProjectId,
      // subdividision,
      // singleFamilyResid,
      // singleFamilyFoundationId,
      // singleFamilyBasementFoundation,
      // detacGarOrWorkOrMajStorShed,
      // parkingPad,
      // secondarySuite,
      // secoSuiOverFounOptBelow,
      // secoSuiOverFoundationId,
      // secondarySuiteBasementFoundation,
      buildingPermit,
      specificationDocument,
      baseModel,
      taxProfile,
      homeType,
      defaultCurrency,
      timeZone : new Date(),
      intJobWithAccSof,
      sqFtMainFloor,
      sqFtSecondFloor,
      sqFtThirdFloor,
      sqFtGarageFloor,
      sqFtBaseOrLowLevel,
      jobAddress,
      lot,
      city,
      zipCode,
      country,  
      province,
      jobType,
      curStep, 
    // sqFtTotalFloor:  (+sqFtMainFloor) + (+sqFtSecondFloor) + (+sqFtThirdFloor) ?? 0,
      block, phase 
    };
    dispatch(updateResidentialAction(ProjectId, JobStaticId, data)).then((res) => {
      // console.log("ressssssssss", res);
      if(curStep === 2){
        showSquareFootModal()
      } else if (curStep ===3){
        showJobAddressModal()
      } else if (curStep ===4){
        showConfirmationModal()
      }
    });
  };

  return (
    <>
      <Modal
        show={residentialJobModal}
        onHide={hideProjectFoundationModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideProjectFoundationModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Residential Job</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span className="Icon">
                    <img src="images/project_foundation.png" />
                  </span>
                  <span className="Text">
                    Project &amp; <br />
                    Foundation{" "}
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateResidentialJobDetails"
                    data-dismiss="modal"
                  >
                    <img src="images/details_step.png" />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateResidentialJobSquare"
                    data-dismiss="modal"
                  >
                    <img src="images/square_foot.png" />
                  </span>
                  <span className="Text">Square Foot</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateResidentialJobAddress"
                    data-dismiss="modal"
                  >
                    <img src="images/step_2.png" />
                  </span>
                  <span className="Text">
                    Job <br /> Address
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateResidentialJobConfirmation"
                    data-dismiss="modal"
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea">
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <h6>
                        Project <span className="required">*</span>
                      </h6>
                      <div className="CommonSelectBox">
                        <select
                          name="ProjectId"
                          value={ProjectId}
                          onChange={handleInput}
                        >
                          <option selected="">Select Project</option>
                          {checkArray(projectListData) &&
                            projectListData?.map((ele, i) => {
                              return (
                                <>
                                  <option value={ele?.id}>
                                    {ele?.projectName}
                                  </option>
                                </>
                              );
                            })}
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>Subdividision</h6>
                      <div className="CommonSelectBox">
                        <select
                          name="subdividision"
                          value={subdividision}
                          onChange={handleInput}
                        >
                          <option value="">Select Subdivision</option>
                          {checkArray(projectListData?.find((x) => x?.id == ProjectId)?.subdivisions) &&
                            projectListData?.find((x) => x?.id == ProjectId)?.subdivisions?.map((ele, i) => {
                              return (
                                <>
                                  <option value={ele}> {ele}</option>
                                </>
                              );
                            })}
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="SingleFamilyResidence">
                      <div className="form-group">
                        <label className="CheckBox">
                          {" "}
                          <strong>Single Family Residence</strong> - With or
                          Without Attached Garage <sup>*</sup>
                          <input
                            type="checkbox"
                            name="singleFamilyResid"
                            value={singleFamilyResid}
                            onChange={handleCheckbox}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div className="row">
                        {singleFamilyResid ? (
                          <div className="col-md-11 ml-auto">
                            <div className="row">
                              <div className="col-md-7">
                                <div className="form-group">
                                  {checkArray(foundationListData) &&
                                    foundationListData?.map((ele, i) => {
                                      return (
                                        <>
                                          <label className="Radio">
                                            {" "}
                                            <strong> {ele?.name}</strong>{" "}
                                            Foundation
                                            <input
                                              type="checkbox"
                                              name="singleFamilyFoundationId"
                                              value={ele?.id}
                                              checked={
                                                ele?.id ==
                                                singleFamilyFoundationId
                                              }
                                              onChange={handleRadio}
                                            />
                                            <span className="checkmark" />
                                          </label>
                                        </>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        <div className="col-md-11">
                          <div className="form-group">
                            <label className="CheckBox">
                              {" "}
                              <strong>
                                Detached Garage or Workshop or Major Storage
                                Shed
                              </strong>
                              <input
                                type="checkbox"
                                name="detacGarOrWorkOrMajStorShed"
                                value={detacGarOrWorkOrMajStorShed}
                                onChange={handleCheckbox}
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="form-group">
                            <label className="CheckBox">
                              {" "}
                              <strong>Parking Pad</strong>
                              <input
                                type="checkbox"
                                name="parkingPad"
                                value={parkingPad}
                                onChange={handleCheckbox}
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="form-group">
                            <label className="CheckBox">
                              {" "}
                              <strong>Secondary Suite</strong> - Over Detached
                              Garage
                              <input
                                type="checkbox"
                                name="secondarySuite"
                                value={secondarySuite}
                                onChange={handleCheckbox}
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="form-group">
                            <label className="CheckBox">
                              {" "}
                              <strong>Secondary Suite</strong> - Over Foundation
                              Option Below
                              <input
                                type="checkbox"
                                name="secondarySuiteBasementFoundation"
                                value={secondarySuiteBasementFoundation}
                                onChange={handleCheckbox}
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                        <div className="col-md-11 ml-auto">
                          <div className="row">
                            {secondarySuiteBasementFoundation ? (
                              <div className="col-md-11 ml-auto">
                                <div className="row">
                                  <div className="col-md-7">
                                    <div className="form-group">
                                      {checkArray(foundationListData) &&
                                        foundationListData?.map((ele, i) => {
                                          return (
                                            <>
                                              <label className="Radio">
                                                {" "}
                                                <strong>
                                                  {" "}
                                                  {ele?.name}
                                                </strong>{" "}
                                                Foundation
                                                <input
                                                  type="checkbox"
                                                  name="secoSuiOverFoundationId"
                                                  value={ele?.id}
                                                  checked={
                                                    ele?.id ==
                                                    secoSuiOverFoundationId
                                                  }
                                                  onChange={handleRadio}
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button
                  className="Outline"
                  data-toggle="modal"
                  data-target="#CreateSideBarModal"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  className="Create FolderPermissionId"
                  onClick={(e) => addResidentialJobFunc(e)}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={DetailsModal}
        onHide={hideDetailsModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideDetailsModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Residential Job</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span className="Icon">
                    <img src="images/project_foundation.png" />
                  </span>
                  <span className="Text">
                    Project &amp; <br />
                    Foundation{" "}
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateResidentialJobProject"
                    data-dismiss="modal"
                  >
                    <img src="images/details_step.png" />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateResidentialJobSquare"
                    data-dismiss="modal"
                  >
                    <img src="images/square_foot.png" />
                  </span>
                  <span className="Text">Square Foot</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateResidentialJobAddress"
                    data-dismiss="modal"
                  >
                    <img src="images/step_2.png" />
                  </span>
                  <span className="Text">
                    Job <br /> Address
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateResidentialJobConfirmation"
                    data-dismiss="modal"
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea">
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>Building Permit #</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Specification Document"
                        name="buildingPermit"
                        value={buildingPermit}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="form-group">
                      <h6>Specification Document</h6>
                      <div className="CommonSelectBox">
                        <select
                          name="specificationDocument"
                          value={specificationDocument}
                          onChange={handleInput}
                        >
                          <option selected="">
                            Select Specification Document
                          </option>
                          {checkArray(speicficReleasedDocsListData?.data) && speicficReleasedDocsListData?.data?.map((ele,i) => {
                             
                                return (
                                  <>
                                   <option value={ele?.id}>{ele?.name}</option>
                                  </>
                                )
                            })} 
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>Base Model</h6>
                      <div className="CommonSelectBox">
                        <select
                          name="baseModel"
                          value={baseModel}
                          onChange={handleInput}
                        >
                          <option selected="">Select Base Model </option>
                         
                            {checkArray(blockListData?.result) && blockListData?.result?.map((ele,i) => {
                              console.log("dfdgfdgdgh", ele)
                                return (
                                  <>
                                   <option value={ele?.id}>{ele?.name}</option>
                                  </>
                                )
                            })} 
                          
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>
                        Tax Profile <span className="required">*</span>
                      </h6>
                      <div className="CommonSelectBox">
                        <select
                          name="taxProfile"
                          value={taxProfile}
                          onChange={handleInput}
                        >
                          <option value="">Select Tax profile</option>
                          {checkArray(taxProfileListData) &&
                            taxProfileListData?.map((ele, i) => {
                              return (
                                <>
                                  <option value={ele?.id}>{ele?.name}</option>
                                </>
                              );
                            })}
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>
                        Home Type <span className="required">*</span>
                      </h6>
                      <div className="CommonSelectBox">
                        <select
                          name="homeType"
                          value={homeType}
                          onChange={handleInput}
                        >
                          <option value="">Select Home Type</option>
                          {checkArray(homeListData) &&
                            homeListData?.map((ele, i) => {
                              return (
                                <>
                                  <option value={ele?.id}>{ele?.name}</option>
                                </>
                              );
                            })}
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>
                        Default Currency <span className="required">*</span>
                      </h6>
                      <div className="CommonSelectBox">
                        <select
                          name="defaultCurrency"
                          value={defaultCurrency}
                          onChange={handleInput}
                        >
                          <option selected="">Select Currency</option>
                          <option value="USD">USD</option>
                          <option value="INR">INR</option>
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>
                        Job Site GMT (UTC) Timezone{" "}
                        <span className="required">*</span>
                      </h6>
                      <div className="CommonSelectBox">
                        <select
                          name="timeZone"
                          value={timeZone}
                          onChange={handleInput}
                        >
                          <option>Select Timezone</option>
                          {checkArray(timeZoneData?.data) ? (
                            <>
                              {timeZoneData?.data?.map((ele, i) => {
                                return (
                                  <option value={ele?.zoneName}>
                                    {ele?.zoneName}
                                  </option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="switch">
                        <input type="checkbox" /> Integrate Job With Accounting
                        Software <span className="required">*</span>
                        <span className="slider" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button
                  className="Outline"
                  data-toggle="modal"
                  data-target="#CreateResidentialJobProject"
                  data-dismiss="modal"
                >
                  Back
                </button>
                <button
                  className="Create FolderPermissionId"
                  onClick={(e) => updateResidentialJobFunc(e, 2)}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={squareFootModal}
        onHide={hidesquareFootModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hidesquareFootModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Residential Job</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateResidentialJobProject"
                    data-dismiss="modal"
                  >
                    <img src="images/project_foundation.png" />
                  </span>
                  <span className="Text">
                    Project &amp; <br />
                    Foundation{" "}
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateResidentialJobDetails"
                    data-dismiss="modal"
                  >
                    <img src="images/details_step.png" />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img src="images/square_foot.png" />
                  </span>
                  <span className="Text">Square Foot</span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateResidentialJobAddress"
                    data-dismiss="modal"
                  >
                    <img src="images/step_2.png" />
                  </span>
                  <span className="Text">
                    Job <br /> Address
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateResidentialJobConfirmation"
                    data-dismiss="modal"
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
           
                <div className="FolderFormCreateArea">
                  <div className="FieldsMandatory">
                    <h6>
                      Fields with <span className="required">*</span> are
                      mandatory
                    </h6>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <h6>SqFt Main Floor</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert sqft"
                          name="sqFtMainFloor"
                          value={sqFtMainFloor}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="form-group">
                        <h6>SqFt Second Floor</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert sqft"
                          name="sqFtSecondFloor"
                          value={sqFtSecondFloor}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="form-group">
                        <h6>SqFt Third Floor</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert sqft"
                          name="sqFtThirdFloor"
                          value={sqFtThirdFloor}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="form-group">
                        <h6>Total House Square Footage</h6>
                        <input
                          type="text"
                          className="form-control InputCyanBg"
                          placeholder="Insert sqft"
                          // name="sqFtTotalFloor"
                          value={(+sqFtMainFloor) + (+sqFtSecondFloor) + (+sqFtThirdFloor) ?? 0}
                          disabled
                          // onChange={handleInput}
                        />
                      </div>
                      <div className="form-group">
                        <h6>SqFt Garage Floor</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert sqft"
                          name="sqFtGarageFloor"
                          value={sqFtGarageFloor}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="form-group">
                        <h6>SqFt Basement/Lower Level</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert sqft"
                          name="sqFtBaseOrLowLevel"
                          value={sqFtBaseOrLowLevel}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between align-items-center px-5">
                  <button
                    className="Outline"
                    data-toggle="modal"
                    data-target="#CreateResidentialJobDetails"
                    data-dismiss="modal"
                  >
                    Back
                  </button>
                  <button
                    className="Create FolderPermissionId"
                    onClick={(e) => updateResidentialJobFunc(e,3)}
                  >
                    Next
                  </button>
                </div>
             
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={jobAddressModal}
        onHide={hideJobAddressModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideJobAddressModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Residential Job</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateResidentialJobProject"
                    data-dismiss="modal"
                  >
                    <img src="images/project_foundation.png" />
                  </span>
                  <span className="Text">
                    Project &amp; <br />
                    Foundation{" "}
                  </span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateResidentialJobDetails"
                    data-dismiss="modal"
                  >
                    <img src="images/details_step.png" />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li className="active">
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateResidentialJobSquare"
                    data-dismiss="modal"
                  >
                    <img src="images/square_foot.png" />
                  </span>
                  <span className="Text">Square Foot</span>
                </li>
                <li className="active">
                  <span className="Icon">
                    <img src="images/step_2.png" />
                  </span>
                  <span className="Text">
                    Job <br /> Address
                  </span>
                </li>
                <li>
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateResidentialJobConfirmation"
                    data-dismiss="modal"
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              <div className="FolderFormCreateArea">
                <div className="FieldsMandatory">
                  <h6>
                    Fields with <span className="required">*</span> are
                    mandatory
                  </h6>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>Job Address</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert full Address"
                        name="jobAddress"
                        value={jobAddress}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="form-group">
                      <h6>Lot</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert lot number"
                        name="lot"
                        value={lot}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="form-group">
                      <h6>Block</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert block number"
                        name="block"
                        value={block}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="form-group">
                      <h6>Phase</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert Phase"
                        name="phase"
                        value={phase}
                        onChange={handleInput}
                      />
                    </div>
                   
                    <div className="form-group">
                      <h6>Zip/ Postal Code</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert zip code"
                        name="zipCode"
                        value={zipCode}
                        onChange={handleInput}
                      />
                    </div>
                    <div className="form-group">
              <h6>Country</h6>
              <div className="CommonSelectBox">
              <select
                                name="country"
                                value={country}
                                onChange={handleInput}
                              >
                                <option>Select Country</option>
                                {checkArray(countryData?.countries) ? (
                                  <>
                                    {countryData?.countries?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                <span>
                  <img src="images/down.png" />
                </span>
              </div>
            </div>
            <div className="form-group">
              <h6>State/Province</h6>
              <div className="CommonSelectBox">
              <select
                                name="province"
                                value={province}
                                onChange={handleInput}
                              >
                                <option>Select Province</option>
                                {checkArray(stateData?.states) ? (
                                  <>
                                    {stateData?.states?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                <span>
                  <img src="images/down.png" />
                </span>
              </div>
            </div>
            <div className="form-group">
              <h6>City</h6>
              <div className="CommonSelectBox">
              <select
                                name="city"
                                value={city}
                                onChange={handleInput}
                              >
                                <option>Select City</option>
                                {checkArray(cityData?.cities) ? (
                                  <>
                                    {cityData?.cities?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                <span>
                  <img src="images/down.png" />
                </span>
              </div>
            </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center px-5">
                <button
                  className="Outline"
                  onClick={() => showConfirmationModal()}
                >
                  Back
                </button>
                <button
                  className="Create FolderPermissionId"
                  onClick={(e) => updateResidentialJobFunc(e,4)}
                >
                  Skip
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={confirmationModal}
        onHide={hideConfirmationModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideConfirmationModal}
          >
            ×
          </a>

          <div className="FormArea">
  <div className="ModalTitleBox">
    <h4>Create Residential Job</h4>
  </div>
  <div className="StepBox">
    <ul>
      <li className="active">
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateResidentialJobProject"
          data-dismiss="modal"
        >
          <img src="images/project_foundation.png" />
        </span>
        <span className="Text">
          Project &amp; <br />
          Foundation{" "}
        </span>
      </li>
      <li className="active">
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateResidentialJobDetails"
          data-dismiss="modal"
        >
          <img src="images/details_step.png" />
        </span>
        <span className="Text">Details</span>
      </li>
      <li className="active">
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateResidentialJobSquare"
          data-dismiss="modal"
        >
          <img src="images/square_foot.png" />
        </span>
        <span className="Text">Square Foot</span>
      </li>
      <li className="active">
        <span
          className="Icon FolderPermissionId"
          data-toggle="modal"
          data-target="#CreateResidentialJobAddress"
          data-dismiss="modal"
        >
          <img src="images/step_2.png" />
        </span>
        <span className="Text">
          Job <br /> Address
        </span>
      </li>
      <li>
        <span className="Icon">
          <img src="images/step_7.png" />
        </span>
        <span className="Text">Confirmation</span>
      </li>
    </ul>
  </div>
  <div className="FolderForm">
   
      <div className="FolderFormCreateArea">
        <div className="FieldsMandatory">
          <h6>
            Fields with <span className="required">*</span> are mandatory
          </h6>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <h6>Project</h6>
              <p>{residentialDetailsData?.details?.Project?.projectAddress}</p>
            </div>
          </div>
         
          <div className="col-md-3">
            <div className="form-group">
              <h6>Building Permit #</h6>
              <p>{residentialDetailsData?.details?.buildingPermit}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <h6>Specification Document</h6>
              <p>{residentialDetailsData?.details?.specificationDocument}</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <h6>HomeType</h6>
              <p>{residentialDetailsData?.details?.HomeType?.name}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <h6>Base Model</h6>
              <p>{residentialDetailsData?.details?.baseModel}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <h6>Subdivision</h6>
              <p>{residentialDetailsData?.details?.subdividision}</p>
            </div>
          </div>
         
          <div className="col-md-6">
            <div className="form-group">
              <h6>Tax Profile</h6>
              <p>{residentialDetailsData?.details?.TaxProfile?.name}</p>
            </div>
            <div className="form-group">
              <h6>Job Site GMT (UTC) Timezone</h6>
              <p>{residentialDetailsData?.details?.timeZone}</p>
            </div>
            <div className="form-group">
              <h6>Integrate Job With Accounting Software</h6>
              <p>No</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <h6>SqFt Main Floor</h6>
              <p>{residentialDetailsData?.details?.sqFtMainFloor}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <h6>SqFt Second Floor</h6>
              <p>{residentialDetailsData?.details?.sqFtSecondFloor}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <h6>SqFt Third Floor</h6>
              <p>{residentialDetailsData?.details?.sqFtThirdFloor}</p>
            </div>
          </div>
        
          <div className="col-md-3">
            <div className="form-group">
              <h6>SqFt Garage Floor</h6>
              <p>{residentialDetailsData?.details?.sqFtGarageFloor}</p>
            </div>
          </div>
          <div className="col-md-9">
            <div className="form-group">
              <h6>SqFt Basement/Lower Level</h6>
              <p>{residentialDetailsData?.details?.sqFtBaseOrLowLevel}</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <h6>Project Address</h6>
              <p>{residentialDetailsData?.details?.Project?.projectAddress}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <h6>City</h6>
              <p>{residentialDetailsData?.details?.city}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <h6>State/Province</h6>
              <p>{residentialDetailsData?.details?.province}</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <h6>Lot</h6>
              <p>{residentialDetailsData?.details?.lot}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <h6>Block</h6>
              <p>{residentialDetailsData?.details?.block}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <h6>Phase</h6>
              <p>{residentialDetailsData?.details?.phase}</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <h6>Zip/ Postal Code</h6>
              <p>{residentialDetailsData?.details?.zipCode}</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <h6>Country</h6>
              <p>{residentialDetailsData?.details?.country}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="Button d-flex justify-content-between align-items-center px-5">
        <button
          className="Outline"
          onClick={hideConfirmationModal}
        >
          Back
        </button>
        <button className="Create ClosePermissionId"  onClick={hideConfirmationModal}>
          Create Job
        </button>
      </div>
   
  </div>
</div>

        </Modal.Body>
      </Modal>
    </>
  );
}

export default ResidentialJobModal;
