import React from 'react'
import Modal from "react-bootstrap/Modal";

const TaskEdit = ({TaskEditModal,SetTaskEditModal,element}) => {

    const handleTaskEditModalClose = () => {
        SetTaskEditModal(false);
    
    
      };
  return (
<Modal show={TaskEditModal} className="ModalBox SmallModal BgClass">
    <a
      onClick={handleTaskEditModalClose}
      class="CloseModal ClosePermissionId"
    >
      ×
    </a>
    <form>
                  <div class="form-group">
                    <h6>Event</h6>
                    <h6 class="fw-400 fw-14">{element?.eventName}</h6>
                    </div>
                  <div class="form-group">
                    <h6>Notification Type</h6>
                    <p class="ml-0">Task</p>
                  </div>
                  <h6>Assigned Users</h6>
                  <div class="form-group">
                    <h6>Assigned User Roles</h6>
                    <p class="ml-0">Estimator</p>
                  </div>
                  <div class="form-group">
                    <h6>Originating User Only</h6>
                    <p class="ml-0">On</p>
                  </div>
                  <div class="row">
                    <div class="col-md-5">
    
                      <div>
                        <h6 class="fw-20 fw-600">Notification Message</h6>
                        <h6>Task Details</h6>
                      </div>
                      <div class="form-group">
                        <h6>Insert keys <span class="ml-3"><img src="images/insert_key_24.png" /></span> </h6>
                        <div class="CommonSelectBox w-100">
                         <select>
                           <option selected="">Select document key</option>
                         </select>
                       </div>
                       <div class="Button my-3">
                        <button class="Create px-3">Add Key</button>
                       </div>
                       <input type="text" class="form-control" placeholder="Insert Notification text" />
                       </div>
                       
                    </div>
                    
                  </div>

                  <div class="row">
                    <div class="col-md-7">
                      <div class="form-group">
                        <h6>Workflow</h6>
                        <p class="ml-0">Check Quote Returned - Task Completed</p>
                       </div>
                       <div class="form-group">
                        <div class="CommonModalArea">
                          <h5>Set Reminder</h5>
                          <div class="CommonModalBox">
                            <h5>
                              <figure><img src="images/timer.png" class="mr-2" /></figure>3 days
                            </h5>
                          </div>
                        </div>
                        </div>
                    </div>
                  </div>


                  
                  <div class="Button d-flex justify-content-between mt-4">
                    <button class="Create" data-dismiss="modal">Save</button>
                    <button class="Outline" data-dismiss="modal">Cancel</button>
                  </div>
                   
                </form>
  </Modal>   )
}

export default TaskEdit