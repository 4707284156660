import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getDocumentationApproval,
  getViewDocumentation,
} from "../redux/action/fileManagerAction";
import { useDispatch } from "react-redux";
import { getConverToString } from "../redux/action/saleAction";

const initialState = {
  viewDocumentEditData: "",
};
const ApproveFileManager = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const { viewDocumentEditData } = iState;

  const location = useLocation();
  const urlParams = new URLSearchParams(
    location?.search?.split("?")[1].split("#")[0]
  );
  const companyId = urlParams?.get("companyId");
  const clientDocId = urlParams?.get("clientDocId");
  const folderId = urlParams?.get("folderId");
  const token = urlParams?.get("token");
  const [actionData,setActionData]=useState({})
  useEffect(() => {
    dispatch(
      getViewDocumentation(companyId, folderId, clientDocId, token)
    ).then((res) => {
      if (res.status === 200) {
        setActionData(res.data.ClientDocDetails)
        let datta = res.data.ClientDocDetails.htmlUrl;
        dispatch(getConverToString(datta, token)).then((response) => {
           if(response.status===200){
          updateState({
            ...iState,
            viewDocumentEditData: response?.data?.data,
          })}
        });
      }
    });
  }, []);


  const handleApprovReject = async (type) => {
    const data = {
      actionStatus: type === "approve" ? "Approval" : "Reject",
    };
  
    try {
      const res = await dispatch(
        getDocumentationApproval(companyId, folderId, clientDocId, data, token)
      );
  
      if (res.status === 200) {
        // Fetch updated documentation details after approval/rejection
        const response = await dispatch(
          getViewDocumentation(companyId, folderId, clientDocId, token)
        );
  
        if (response.status === 200) {
          setActionData(response.data.ClientDocDetails);
        }
  
        // Show success toast notification
        toast.success(
          type === "approve"
            ? "File Approved Successfully"
            : "File Rejected Successfully",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          }
        );
      } else {
        // Show error toast notification
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      // Handle any potential errors
      toast.error("An error occurred. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.error("Error during approval/rejection process:", error);
    }
  };

  console.log("actionData",actionData);
  
  return (
    <>
      <div className="ModalTitleBox p-5">
        <h4>Approval File</h4>
      </div>
      <div className="FormArea p-5">
        {viewDocumentEditData ? (
          <div dangerouslySetInnerHTML={{ __html: viewDocumentEditData }} />
        ) : (
          <p>Loading document...</p>
        )}
        {
            actionData?.actionStatus==null?
            <div className="FolderForm">
            <div className="Button d-flex justify-content-between m-3">
              <button
                className="BrandBtnGreen"
                onClick={() => handleApprovReject("approve")}
              >
                Approve
              </button>
              <button
                className="Create"
                onClick={() => handleApprovReject("reject")}
              >
                Reject
              </button>
            </div>
          </div>:
          <h3>{actionData?.actionStatus==="Approval"?"Approved":"Rejected"}</h3>
        }
       
      </div>
    </>
  );
};

export default ApproveFileManager;
