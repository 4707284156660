import React from 'react'

const BlockSummaryPreview = ({proformaBlockStep3Data,handleShowComponent,handleShow}) => {
  return (
    <>
    <div>
         <div className="ModalMediumheading d-flex align-items-center justify-content-between">
    <h3>
      <span>Block Inputs</span>
    </h3>
    <h6>
      <img src="images/details-cost.png" />
      Detailed Costs
    </h6>
  </div>
  <div className="BlockInputs">
    <table>
      <thead>
        <tr>
          <th>Input</th>
          <th>Block A</th>
          <th>Block B</th>
          <th>Block C</th>
          <th>Block D</th>
          <th>Totals</th>
        </tr>
      </thead>
      <tbody>
      {/* {proformaBlockStep3Data?.data?.table1?.length > 0 &&
          proformaBlockStep3Data?.data?.table1?.map((item, ind) => {
            return (
              <tr key={ind}>
                <td>{item.type}</td>
                <td>{item.UnitOptions?.[0]?.unitValue}</td>
                <td>{item.bedRooms}</td>
                <td>{item.bathRooms}</td>
              </tr>
            );
          })} */}
      </tbody>
    </table>
  </div>
  <div className="BlockCostsPreview">
    <div className="d-flex align-items-center justify-content-between">
      <h4>Block Costs Preview</h4>
      <div className="NewActions">
        <a href="javascript:void(0);" title="pdf">
          <img src="images/pdf.png" />
        </a>
        <a href="javascript:void(0);">
          <img src="images/download.png" />
        </a>
        <a href="javascript:void(0);">
          <img src="images/setting.png" />
        </a>
      </div>
    </div>
    {proformaBlockStep3Data?.data?.table2?.blockQuantities
      ?.length > 0 &&
      proformaBlockStep3Data?.data?.table2?.blockQuantities?.map(
        (data, ind) => {
          return (
            <div className="BlockInputsCollapse">
              <div
                className="BcpHeader"
                data-toggle="collapse"
                href="#collapseExample"
                aria-expanded="true"
                aria-controls="collapseExample"
              >
                <h5>{data.name}</h5>
              </div>
              <div
                className="collapse show"
                id="collapseExample"
                style={{}}
              >
                <div className="card card-body border-0">
                  <div className="BlockInputs">
                    <table>
                      <thead>
                        <tr>
                          <th>Code Code #</th>
                          <th>Description</th>
                          <th>Block A</th>
                          {/* <th>Block B</th>
          <th>Block C</th>
          <th>Block D</th>
          <th>Total Construction Costs</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {data?.ProformaBaseCosts?.length > 0 &&
                          data?.ProformaBaseCosts?.map(
                            (data, ind) => {
                              return (
                                <tr>
                                  <td>{data.CostCode?.number}</td>
                                  <td>{data.CostCode?.name}</td>
                                  <td>
                                    {
                                      data
                                        .ProformaBlockQuantities?.[0]
                                        ?.MultiBlock
                                        ?.blockDescription
                                    }
                                  </td>

                                  <td>
                                    $
                                    {data
                                      .ProformaBlockQuantities?.[0]
                                      ?.totalCost ?? 0}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td />
                          <td>Total Interior Finishing Costs</td>
                          <td>67,480.00</td>
                          <td>67,480.00</td>
                          <td>67,480.00</td>
                          <td>67,480.00</td>
                          <td>2,75,480.00</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      )}

    <div className="BlockInputs Red">
      <table>
        <tfoot>
          <tr>
            <td />
            <td>Grand Total</td>
            <td>$ 1,67,480.00</td>
            <td>$ 1,67,480.00</td>
            <td>$ 1,67,480.00</td>
            <td>$ 1,67,480.00</td>
            <td>$ 32,275,590.00</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
  </div>
  <div className="Button d-flex  justify-content-between align-items-center px-5">
                <button
                  className="Outline border text-success border-success"
                  onClick={()=>handleShow("blockQuanties")}
                  >
                  Cancel
                </button>
                <button
                  className="Create bg-success border border-success"
                  onClick={() => handleShowComponent("blockFoundation")}
                   >
                  Save Drafts
                </button>
               
              </div>
    </>
  )
}

export default BlockSummaryPreview
