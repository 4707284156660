import React from 'react'
import Modal from "react-bootstrap/Modal";

const ItinaryEdit = ({ItinaryEditModal,SetItinaryEditModal,  element}) => {

    const handleItinaryEditModalClose = () => {
        SetItinaryEditModal(false);
    
    
      };
  return (
<Modal show={ItinaryEditModal} className="ModalBox SmallModal BgClass">
    <a
      onClick={handleItinaryEditModalClose}
      class="CloseModal ClosePermissionId"
    >
      ×
    </a>
    <div class="FormArea">
              <div class="ModalTitleBox">
                <h4>Configure Notification Details</h4>
              </div>
              <div class="FolderForm GreenSwitch">
                <form>
                  <div class="form-group">
                    <h6>Event</h6>
                    <h6 class="fw-400 fw-14">{element?.eventName}</h6>
                    </div>
                  <div class="form-group">
                    <h6>Notification Type</h6>
                    <p class="ml-0">Itinerary</p>
                  </div>
                  <h6>Assigned Users</h6>
                  <div class="form-group">
                    <h6>Assigned User Roles</h6>
                    <p class="ml-0">Estimator</p>
                  </div>
                  <div class="form-group">
                    <h6>Originating User Only</h6>
                    <p class="ml-0">On</p>
                  </div>
                  <div class="ModalBigheading">
                    <h3><span>Notification Details</span></h3>
                  </div>
                  
                 
                  <div class="row">
                    
                    <div class="col-md-5">
                      <h6 class="fw-20 fw-600">Subject</h6>
                      <div class="form-group">
                        <h6>Insert keys <span class="ml-3"><img src="images/insert_key_24.png" /></span> </h6>
                        <div class="CommonSelectBox w-100">
                         <select>
                           <option selected="">Select document key</option>
                         </select>
                       </div>
                       <div class="Button my-3">
                        <button class="Create px-3">Add Key</button>
                       </div>
                       </div>
                    </div>
                    
                    <div class="col-md-10">
                      <div class="QuotedArea">
                        <h4><span><img src="images/insert_keys.png" /></span>Quoteld</h4>
                        <h4><span><img src="images/insert_keys.png" /></span>JobId has been returned by</h4>
                        <h4><span><img src="images/insert_keys.png" /></span>Vendor Name</h4>
                      </div>
                    </div>
                    
                  </div>
                  

                  <div class="row">
                    
                    <div class="col-md-5">
                      <h6 class="fw-20 fw-600">Description</h6>
                      <div class="form-group">
                        <h6>Insert keys <span class="ml-3"><img src="images/insert_key_24.png" /></span> </h6>
                        <div class="CommonSelectBox w-100">
                         <select>
                           <option selected="">Select document key</option>
                         </select>
                       </div>
                       <div class="Button my-3">
                        <button class="Create px-3">Add Key</button>
                       </div>
                       <input type="text" class="form-control" placeholder="Insert Notification text" />
                       </div>
                    </div>
                    
                    <div class="col-md-10">
                      <div class="QuotedArea">
                        <h4><span><img src="images/insert_keys.png" /></span>Quoteld</h4>
                        <h4><span><img src="images/insert_keys.png" /></span>JobId has been returned by</h4>
                        <h4><span><img src="images/insert_keys.png" /></span>Vendor Name</h4>
                      </div>
                    </div>
                    
                  </div>

                  

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <h6>Type  </h6>
                        <div class="CommonSelectBox w-100">
                         <select>
                           <option selected="">Meeting</option>
                         </select>
                       </div>
                       </div>
                     <div class="CommonModalArea">
                      <div class="form-group">
                        <h5>Set Reminder</h5>
                        <div class="CommonModalBox">
                          <h5>
                            <figure><img src="images/timer.png" class="mr-2" /></figure>3 days
                            
                          </h5>
                        </div>
                      </div>
                      </div>

                       
                    </div>
                  </div>

                  
                  <div class="Button d-flex justify-content-between mt-4">
                    <button class="Create" data-dismiss="modal">Save</button>
                    <button class="Outline" data-dismiss="modal">Cancel</button>
                  </div>
                   
                </form>
              </div>
            </div>
  </Modal>   )
}

export default ItinaryEdit