import React, { useState, useEffect, useRef } from "react";
import Header from "../../Header";
import SideNav from  "../IbuildAdminSidenav"
import "../../../App.css";
import Modal from "react-bootstrap/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Loader from "../../Loader";
import SignaturePad from "react-signature-canvas";
import {
  getAllDocumentKeyList,
  getAllFolderList,
  getAllTagList,
  getCreateDocumentation,
  getCreateFolder,
  getDocumentationApproval,
  getFileHistoryList,
  getFolderByName,
  getFolderRemove,
  getHtmlFile,
  getListDocumentation,
  getRemoveDocumentation,
  getSearchListDocumentation,
  getShareDocumentationAdd,
  getUploadTemplateMainPic,
  getViewDocumentation,
  getpendingFileEventList,
  lockAction,
  sendForApprovalDocument,
  updateEditDocumentation,
} from "../../../redux/action/IbuildAdminFileManagerAction";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { addSignatureAction } from "../../../redux/action/clientDocumentationAction";
import moment from "moment";
const initialState = {
  folderName: "",
  CreateFolderModal: false,
  ManageSignatueModal: false,
  signature: "",
  initial: "",
  errors: {},
  NewFileModal: false,
  fileFolderName: "",
  clientFile: "",
  DocumentationDeleteModal: false,
  docId: "",
  ShareClientDocumentationModal: false,
  emailBody: "",
  clientEmail: "",
  clientName: "",
  emails: [{ name: "", email: "" }],
  folderId: "",
  id: "",
  SendForApprovalModal: false,
  approverType: "",
  contactSelection: [{ name: "", email: "" }],
  UploadTemplateModal: false,
  templateFile: "",
  ViewDocumentationModal: false,
  viewDocumentData: "",
  ApproveDocumentationModal: false,
  FolderDeleteModal: false,
  EditDocumentationModal: false,
  content: "",
  viewDocumentEditData: "",
  RelaseTemplateModal: false,
  LockTemplateModal: false,
  FilesHistoryModal: false,
  DocumentKeyModal: false,
  documentSearch: "",
  
  JobId: "",
};

const IbuildAdminFileManager = () => {
  const dispatch = useDispatch();
  const signCanvas = useRef({});
  const intialCanvas = useRef({});
  const [iState, updateState] = useState(initialState);
  const {
    folderName,
    CreateFolderModal,
    ManageSignatueModal,
    signature,
    initial,
    errors,
    NewFileModal,
    fileFolderName,
    clientFile,
    DocumentationDeleteModal,
    docId,
    ShareClientDocumentationModal,
    emailBody,
    clientName,
    clientEmail,
    emails,
    folderId,
    id,
    SendForApprovalModal,
    approverType,
    contactSelection,
    UploadTemplateModal,
    templateFile,
    ViewDocumentationModal,
    ApproveDocumentationModal,
    FolderDeleteModal,
    EditDocumentationModal,
    viewDocumentData,
    content,
    viewDocumentEditData,
    RelaseTemplateModal,
    LockTemplateModal,
    FilesHistoryModal,
    DocumentKeyModal,
    documentSearch,
    JobId,
  } = iState;
  const {
    allFolderList,
    searchFolderList,
    documentationList,
    searchDocumentationList,
    viewDocumentation,
    tagList,
    pendingFileList,
    fileHistoryList,
    loader,
    pendingLoader,
    folderLoader,
    allDocumentKey,
  } = useSelector((state) => state.fileManagerData);
  console.log("allDocumentKey", allDocumentKey);

  useEffect(() => {
    dispatch(getAllFolderList());
    dispatch(getAllDocumentKeyList());
  }, []);

  // const handlePendingEventList = (id) => {
  //   dispatch(getpendingFileEventList(id));
  // };

  const handleCreateFolderShow = () => {
    updateState({
      ...iState,
      CreateFolderModal: true,
      folderName: "",
    });
  };

  const handleCreateFolderClose = () => {
    updateState({
      ...iState,
      CreateFolderModal: false,
      folderName: "",
    });
  };

  const configObject = {
    // CKEditor 5 configuration options
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "blockQuote",
      "undo",
      "redo",
    ],
    // Add more configuration options as needed
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  const [searchName, setSearchName] = useState("");
  const [templates, setTemplates] =useState([])
  const [searchFolderName, setSearchFolderName] = useState("");
  useEffect(() => {
    if (searchName) {
      dispatch(getFolderByName({ name: searchName }));
    }
  }, [searchName, dispatch]);

  const handleTagDocument = (id) => {
    dispatch(getListDocumentation(id));
    dispatch(getpendingFileEventList(id));
  };

  useEffect(() => {
    const id = JSON.parse(window.localStorage.getItem("folderID"));

    if (searchFolderName) {
      dispatch(getSearchListDocumentation(id, { name: searchFolderName }));
    }
  }, [searchFolderName, id, dispatch]);

  const handleCreateFolderSubmit = (e) => {
    e.preventDefault();
    // let formIsValid = handleValidation();
    // if (formIsValid) {
    updateState({ ...iState, loading: true });
    const dataa = {
      folderName,
    };
    dispatch(getCreateFolder(dataa))
      .then((res) => {
        dispatch(getAllFolderList());
        toast.success("Folder created successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          folderName: "",
          CreateFolderModal: false,
        });
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, loading: false, CreateFolderModal: true });
      });
  };

  // }



  const handleManageSignatueClose = () => {
    updateState({
      ...iState,
      ManageSignatueModal: false,
      signature: "",
      initial: "",
      errors: {},
    });
  };
  const handleFileChange = (event, type) => {
    const file = event?.target?.files[0];
    if (type === "sign1") {
      updateState({ ...iState, signature: file });
    } else if (type === "sign2") {
      updateState({ ...iState, initial: file });
    } else if (type === "template") {
      updateState({ ...iState, templateFile: file });
    } else {
      updateState({ ...iState, clientFile: file });
    }
  };

  const handleSignatureValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!signature) {
      error.signatureError = "* Signature can't be empty";
      formIsValid = false;
    }

    if (!initial) {
      error.initialError = "* Initial can't be empty";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: error,
    });
    return formIsValid;
  };

  const clearSignature = () => {
    signCanvas.current.clear();
  };
  const clearInitials = () => {
    intialCanvas.current.clear();
  };

  const addSignatureFun = (contractID = 2) => {
    let formIsValid = handleSignatureValidation();
    if (formIsValid) {
      dispatch(addSignatureAction({ signature, initial, imageOwner: "test" }))
        .then((res) => {
          toast.success("Sign Uploded Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            ManageSignatueModal: false,
          });
        })
        .catch((error) => {
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, ManageSignatueModal: true });
        });
    }
  };

  const handleNewFileShow = () => {
    updateState({
      ...iState,
      NewFileModal: true,
      errors: {},
      fileFolderName: "",
      clientFile: "",
      id: "",
    });
  };

  const handleNewFileClose = () => {
    updateState({
      ...iState,
      NewFileModal: false,
      errors: {},
      fileFolderName: "",
      clientFile: "",
      id: "",
    });
  };

  const handleNewFileValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!fileFolderName) {
      error.nameError = "* Name can't be empty";
      formIsValid = false;
    }

    if (!id) {
      error.foldNameError = "* Please select folder name";
      formIsValid = false;
    }

    if (!clientFile) {
      error.cltfileError = "* Please select file";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: error,
    });
    return formIsValid;
  };

  const handleRemoveClientFile = () => {
    // Reset the 'templateFile' state to null
    updateState({ ...iState, clientFile: null });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#createFileInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleNewFileSubmit = () => {
    let formIsValid = handleNewFileValidation();
    if (formIsValid) {
      const dataa = {
        name: fileFolderName,
        files: clientFile,
        clientView: true,
      };
      dispatch(getCreateDocumentation(id, dataa))
        .then((res) => {
          console.log("message", res);
          if (res.status === 201) {
            dispatch(getListDocumentation(id));
            toast.success("Create File Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            updateState({
              ...iState,
              NewFileModal: false,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({ ...iState, NewFileModal: true });
          }
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, NewFileModal: true });
        });
    }
  };

  const handleDocumentationDeleteShow = (id) => {
    updateState({
      ...iState,
      DocumentationDeleteModal: true,
      docId: id,
    });
  };

  const handleDocumentationDeleteClose = () => {
    updateState({
      ...iState,
      DocumentationDeleteModal: false,
      docId: "",
    });
  };

  const handleDocumentationDelete = () => {
    let dataa = { docId };
    const id = JSON.parse(window.localStorage.getItem("folderID"));
    dispatch(getRemoveDocumentation(id, dataa))
      .then((res) => {
        if (res.status === 200) {
          dispatch(getListDocumentation(id));
          // dispatch(getpendingFileEventList(id));
          toast.success("Delete Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
            theme: "colored",
          });
          updateState(initialState);
        }
      })
      .catch((error) => {
        toast.error(error.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
          theme: "colored",
        });
      });
    updateState({ ...iState, DocumentationDeleteModal: true });
  };



  const handleShareClientDocumentClose = () => {
    updateState({
      ...iState,
      ShareClientDocumentationModal: false,
      emailBody: "",
      emails: [],
      clientName: "",
      clientEmail: "",
    });
  };

  const handleAddRecipient = () => {
    const { clientName, clientEmail } = iState;

    // Create a copy of the emails array
    const updatedEmails = [...iState.emails];

    // Push a new recipient object with the clientName and clientEmail values
    updatedEmails.push({ name: clientName, email: clientEmail });

    // Update the state with the modified emails array and reset clientName and clientEmail
    updateState({
      ...iState,
      emails: updatedEmails,
      clientName: "",
      clientEmail: "",
    });
  };

  // Function to remove a recipient at a given index
  const handleRemoveRecipient = (indexToRemove) => {
    // Create a copy of the emails array
    const updatedEmails = [...iState.emails];

    // Use the filter method to create a new array without the item at the specified index
    const updatedEmailsFiltered = updatedEmails.filter(
      (_, index) => index !== indexToRemove
    );

    // Update the state with the modified emails array
    updateState({ ...iState, emails: updatedEmailsFiltered });
  };

  const handleShareDocumentValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!emailBody) {
      error.eBodyError = "* Message can't be empty";
      formIsValid = false;
    }

    // if (!clientName) {
    //   error. = "* Name can't be empty";
    //   formIsValid = false;
    // }

    // if (!clientEmail) {
    //   error.cliEmailError = "*  can't be empty";
    //   formIsValid = false;
    // }
    if (emails?.length == 0) {
      error.cliNameError = "* Name & Email can't be empty";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: error,
    });
    return formIsValid;
  };

  const handleShareDocumentSubmit = () => {
    let formIsValid = handleShareDocumentValidation();
    if (formIsValid) {
      const dataa = {
        emailBody,
        emails,
      };

      dispatch(getShareDocumentationAdd(folderId, id, dataa))
        .then((res) => {
          if (res.status === 200) {
            dispatch(getListDocumentation(folderId));
            toast.success("Share Document Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            updateState({
              ...iState,
              ShareClientDocumentationModal: false,
              folderId: "",
              id: "",
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({
              ...iState,
              loading: false,
              ShareClientDocumentationModal: true,
            });
          }
        })
        .catch((error) => {
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            loading: false,
            ShareClientDocumentationModal: true,
          });
        });
    }
  };

  const handleTypeChange = (type) => {
    updateState({ ...iState, approverType: type });
  };

  const handleNameEmailChange = (e, i) => {
    const { name, value } = e.target;
    let contactData = [...contactSelection];
    contactData[i] = {
      ...contactData[i],
      [name]: value,
    };
    updateState({
      ...iState,
      contactSelection: contactData,
    });
  };



  const handleSendForApprovalClose = () => {
    updateState({
      ...iState,
      SendForApprovalModal: false,
      contactSelection: "",
      folderId: "",
      id: "",
    });
  };

  const handleSendForApprovalSubmit = () => {
    const dataa = {
      approverType: [approverType],
      contactSelection,
    };

    dispatch(sendForApprovalDocument(folderId, id, dataa))
      .then((res) => {
        if (res.status === 200) {
          dispatch(getListDocumentation(folderId));
          toast.success("Send file approval successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            SendForApprovalModal: false,
            folderId: "",
            id: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            loading: false,
            SendForApprovalModal: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          loading: false,
          SendForApprovalModal: true,
        });
      });
  };

  const handleUploadTemplateShow = (foldId, Id) => {
    updateState({
      ...iState,
      UploadTemplateModal: true,
      folderId: foldId,
      id: Id,
      templateFile: "",
    });
  };

  const handleUploadTemplateClose = () => {
    updateState({
      ...iState,
      UploadTemplateModal: false,
      folderId: "",
      id: "",
      templateFile: "",
    });
  };

  const handleRemoveTemplate = () => {
    // Reset the 'templateFile' state to null
    updateState({ ...iState, templateFile: null });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#templateFileInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleUploadTemplateSubmit = () => {
    if (!templateFile) {
      toast.error("Please select the File", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      const dataa = {
        file: templateFile,
      };

      dispatch(getUploadTemplateMainPic(folderId, id, dataa))
        .then((res) => {
          if (res.status === 200) {
            dispatch(getListDocumentation(folderId));
            toast.success("Upload Template Document Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            updateState({
              ...iState,
              UploadTemplateModal: false,
              folderId: "",
              id: "",
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({
              ...iState,
              loading: false,
              UploadTemplateModal: true,
            });
          }
        })
        .catch((error) => {
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            loading: false,
            UploadTemplateModal: true,
          });
        });
    }
  };

  const handleViewDocumentationShow = async (foldId, Id) => {
    updateState({
      ...iState,
      ViewDocumentationModal: true,
      folderId: foldId,
      id: Id,
    });

    const datta = { clientView: true };

    try {
      const response = await dispatch(getViewDocumentation(foldId, Id, datta));
      const documentUrl = response?.data?.ClientDocDetails?.docUrl;

      const urlWithoutQueryString = documentUrl?.split("?")[0];

      const htmlData = await dispatch(
        getHtmlFile({ url: urlWithoutQueryString })
      );
      console.log("Html", htmlData);

      if (documentUrl) {
        updateState({
          ...iState,
          viewDocumentData: htmlData?.data,
          ViewDocumentationModal: true,
        });
      }
    } catch (error) {
      // Handle the error, e.g., log it or show an error message.
      console.error("Error fetching documentation:", error);
    }
  };

  const handleViewDocumentationClose = () => {
    updateState({
      ...iState,
      ViewDocumentationModal: false,
      folderId: "",
      id: "",
      viewDocumentData: "",
    });
  };

  const handleApproveDocumentationShow = async (foldId, Id) => {
    updateState({
      ...iState,
      ApproveDocumentationModal: true,
    });

    const datta = { clientView: true };
    try {
      const response = await dispatch(getViewDocumentation(foldId, Id, datta));
      const documentUrl = response?.data?.ClientDocDetails?.docUrl;

      if (documentUrl) {
        updateState({
          ...iState,
          viewDocumentEditData: documentUrl,
          ApproveDocumentationModal: true,
          folderId: foldId,
          id: Id,
        });
      }
    } catch (error) {
      // Handle the error, e.g., log it or show an error message.
      console.error("Error fetching documentation:", error);
    }
  };

  const handleApproveDocumentationClose = () => {
    updateState({
      ...iState,
      ApproveDocumentationModal: false,
      folderId: "",
      id: "",
    });
  };

  const handleApprovReject = (type) => {
    debugger;
    if (type === "approve") {
      const dataa = {
        file: viewDocumentEditData,
        actionStatus: "Approval",
      };
      dispatch(getDocumentationApproval(folderId, id, dataa)).then((res) => {
        if (res.status === 200) {
          dispatch(getListDocumentation(folderId));
          toast.success("Send file Approval successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            ApproveDocumentationModal: false,
            folderId: "",
            id: "",
            viewDocumentEditData: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            loading: false,
            ApproveDocumentationModal: true,
          });
        }
      });
    } else {
      const dataa = {
        file: viewDocumentEditData,
        actionStatus: "Reject",
      };
      dispatch(getDocumentationApproval(folderId, id, dataa)).then((res) => {
        if (res.status === 200) {
          dispatch(getListDocumentation(folderId));
          toast.success("file Rejected Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            ApproveDocumentationModal: false,
            folderId: "",
            id: "",
            viewDocumentEditData: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            loading: false,
            ApproveDocumentationModal: true,
          });
        }
      });
    }
  };

  const handleFolderDeleteShow = (id, foldName) => {
    updateState({
      ...iState,
      FolderDeleteModal: true,
      docId: id,
      folderName: foldName,
    });
  };

  const handleFolderDeleteClose = () => {
    updateState({
      ...iState,
      FolderDeleteModal: false,
      docId: "",
    });
  };

  const handleFolderDelete = () => {
    let dataa = { folderName };
    dispatch(getFolderRemove(docId, dataa))
      .then((res) => {
        if (res.status === 200) {
          dispatch(getAllFolderList());
          toast.success(" Delete Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            FolderDeleteModal: false,
            docId: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            FolderDeleteModal: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          FolderDeleteModal: true,
        });
      });
  };

  const handleEditDocumentationShow = async (foldId, Id) => {
    updateState({
      ...iState,
      EditDocumentationModal: true,
      folderId: foldId,
      id: Id,
    });

    const datta = { clientView: true };
    try {
      const response = await dispatch(getViewDocumentation(foldId, Id, datta));
      const documentUrl = response?.data?.ClientDocDetails?.docUrl;

      // Split URL to remove query string
      const urlWithoutQueryString = documentUrl?.split("?")[0];

      const htmlData = await dispatch(
        getHtmlFile({ url: urlWithoutQueryString })
      );
      console.log("Html", htmlData);

      if (documentUrl) {
        updateState({
          ...iState,
          content: htmlData?.data,
          EditDocumentationModal: true,
        });
      }
    } catch (error) {
      // Handle the error, e.g., log it or show an error message.
      console.error("Error fetching documentation:", error);
    }
  };

  const handleEditDocumentationClose = () => {
    updateState({
      ...iState,
      EditDocumentationModal: false,
      folderId: "",
      id: "",
    });
  };

  const handleEditDocumentation = () => {
    const dataa = { content };
    dispatch(updateEditDocumentation(folderId, id, dataa)).then((res) => {
      if (res.status === 200) {
        toast.success("Docs Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          EditDocumentationModal: false,
          folderId: "",
          id: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          loading: false,
          EditDocumentationModal: true,
        });
      }
    });
  };

  const handleRelaseTemplateModalShow = () => {
    updateState({
      ...iState,
      RelaseTemplateModal: true,
    });
  };

  const handleRelaseTemplateModalClose = () => {
    updateState({
      ...iState,
      RelaseTemplateModal: false,
    });
  };

  const handleLockTemplateModalShow = (id) => {
    dispatch(getListDocumentation(id));
    
    updateState((prev)=> ({...prev,LockTemplateModal:true }))

  };

  const handleLockTemplateModalClose = () => {
    updateState({
      ...iState,
      LockTemplateModal: false,
    });
  };

  const handleFilesHistoryModalShow = (folderID, id) => {
    updateState({
      ...iState,
      FilesHistoryModal: true,
    });
    dispatch(getFileHistoryList(folderID, id));
  };

  const handleFilesHistoryModalClose = () => {
    updateState({
      ...iState,
      FilesHistoryModal: false,
    });
  };


  const handleDocumentKeyModalClose = () => {
    updateState({
      ...iState,
      DocumentKeyModal: false,
      JobId: "",
    });
  };

  const handleItemClick = (item) => {
    updateState({ ...iState, JobId: item });
  };


  useEffect(()=>{
    if(documentationList){
      let temp  = documentationList?.map((item)=> {return {...item, lock:false}})

      setTemplates(temp)


    }
    // if(searchDocumentationList){
    //   // let temp  = searchDocumentationList?.map((item)=> {return {fileId: item?.id, lock:false}})

    //   setTemplates(searchDocumentationList)


    // }v 


  },documentationList)



  const handleLockFolder = () => {
    const dataa = { content };

    let temp = templates?.map((item)=>  {return {fileId:item.id , lock :item.lock}})
    dispatch(lockAction(folderId,temp)).then((res) => {
      if (res.status === 200) {
        toast.success("Docs Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          LockTemplateModal: false,
          folderId: "",
          id: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          loading: false,
          LockTemplateModal: true,
        });
      }
    });
  };




  return (
    <div>
      <Header />
      <SideNav />
      <>
        <div className="WrapperArea">
          <div className="WrapperBox">
            {/* <div class="d-flex flex-wrap mb-5">
    <h6 class="CursorPointer" data-toggle="modal" data-target="#JobSelectionIsMissing" data-dismiss="modal">Job Selection is Missing</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#JobAndCostCodeSelectionMissing" data-dismiss="modal">Job and Cost Code Selection Missing</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#CreateSelectJob" data-dismiss="modal">Create - Select Job</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#CreateSelectJobandCostCode" data-dismiss="modal">Create - Select Job and Cost Code</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#CreateNewCompanyGeneralInformation">Create Select Job General Information</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#CreateNewProjectDetails">Create New Project Details</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#HeaderPreview">Header Preview</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#CreateResidentialJobProject">Create Residential Job</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityDetails">Create New Project as an Entity</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#CreateMultiFamilyJobsDetails">Create Multi Family Jobs</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#CreateLandDevelopmentDetails">Create Land Development</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#CompanyDetails">Company Details</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#CompanyDetails2">Company Details 2</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#ProjectDetailsResidential">Project Details Residential</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#ProjectDetailsMultiFamily">Project Details Multi-Family</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#ProjectDetailsLandDevelopment">Project Details Land Development</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#ProjectDetailsResidentialEdit">Project Details Residential Edit</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#ProjectDetailsMultiFamilyEdit">Project Details Residential Edit</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#ProjectDetailsLandDevelopmentEdit">Project Details Land Development Edit</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#ProjectDetailsResidentialEntity">Project Details Residential Entity</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#ProjectDetailsMultiFamilyBottom">Project Details Multi-Family Bottom</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#ProjectDetailsResidentialJobsEntityEdit">Project Details Residential Jobs Entity Edit</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#ProjectDetailsMultiFamilyEditBottom">Project Details Multi-Family Edit Bottom</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#ProjectDetailsMultiFamilyEditBottom2">Project Details Multi-Family Edit Bottom 2</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#ProjectDetailsMultiFamilyEditBottom3">Project Details Multi-Family Edit Bottom 3</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#MultiFamilyJobDetails5">Multi Family Job Details 5</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#MultiFamilyJobDetails7">Multi Family Job Details 7</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#MultiFamilyJobDetails9">Multi Family Job Details 9</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#MultiFamilyJobDetails11">Multi Family Job Details 11</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#MultiFamilyJobDetailsUnit101">Multi Family Job Details Unit 101</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#MultiFamilyJobDetailsUnit3">Multi Family Job Details Unit 3</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#LandDevelopmentDetails1">LandDevelopmentDetails 1</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#LandDevelopmentDetailsEdit1">Land Development Details Edit 1</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#LandDevelopmentDetails3">LandDevelopmentDetails 3</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#LandDevelopmentDetailsAdministration">Land Development Details Administration</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#LandDevelopmentDetailsAdministration6">Land Development Details Administration 6</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#LandDevelopmentDetailsLot">Land Development Details Lot</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#LandDevelopmentDetailsLotLast">Land Development Details Lot Last</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#CreateLandDevelopmentLandTractsPhasesLandTrack">Create Land Development Land Tracts Phases</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#CreateLandDevelopmentLots">Create Land Development Lots</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#CreateMultiFamilyBlocks">Create Multi Family Blocks</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#CreateMultiFamilyUnits">Create Multi Family Units</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#CreateMultiFamilyCommonBuilding">Create Multi-Family Common Building</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#CreateMultiFamilyParking">Create Multi-Family Parking</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#CreateResidentialAddOns">Create Residential Add-Ons</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#InsertSignatureTabModal">Insert Signature Tab Modal</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#ResidentialDetailsBasement">ResidentailModal Ankit</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#ResidentialDetailsBasementEdit">ResidentailModal Edit Ankit</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#InsertSignature">Insert Signature</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityType">Create New Project As An Entity Type</h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

    
    
    
    
    
    


    
    
             
 
    
    
    
 </div>  */}
            <div className="TitleBox">
              <h4 className="Title">System File Manager</h4>
              <a
                href="#"
                data-toggle="modal"
                data-target="#folderPermissionsModal"
              >
                <img src="images/Icon-4.png" />
              </a>
            </div>
            <div className="">
              <div className="row">
                <div className="col-sm-4">
                  <div className="DashboardLeft">
                    <div className="ShowList">
                      <h4>Show :</h4>
                      {/* <div className="show-carousel owl-carousel owl-theme">
                        <div className="item">
                          <h6 className="active">System Templates</h6>
                        </div>
                        <div className="item">
                          <h6>Documents</h6>
                        </div>
                        <div className="item">
                          <h6>Pictures</h6>
                        </div>
                        <div className="item">
                          <h6>letters</h6>
                        </div>
                      </div> */}
                      <article>
                        <button onClick={handleNewFileShow}>Upload File</button>
                      </article>
                      <aside>
                        <h5>Folder</h5>
                        <div>
                          <a onClick={handleRelaseTemplateModalShow}>
                            <img
                              src={require("../../../assets/images/release_template.png")}
                            />
                          </a>
                          <a onClick={handleCreateFolderShow}>
                            <img src="images/Plus.png" />
                          </a>
                          <a
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#permissionModal3"
                          >
                            <img src="images/Folder.png" />
                          </a>
                          <a
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#folderActionModal3"
                          >
                            <img src="images/Icon-4.png" />
                          </a>
                        </div>
                      </aside>
                      <div className="Search">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          value={searchName}
                          onChange={(e) => setSearchName(e.target.value)}
                        />
                        <span>
                          <img src="images/search.png" />
                        </span>
                      </div>
                    </div>
                    <div className="FolderList">
                      <ul>
                        {folderLoader ? (
                          <td>
                            <Loader />
                          </td>
                        ) : (
                          <>
                            {searchName ? (
                              searchFolderList?.length > 0 ? (
                                searchFolderList?.map((item) => (
                                  <li>
                                    <span
                                      className="Icon"
                                      onClick={() => handleTagDocument(item.id)}
                                    >
                                      <img src="images/Folder.png" />
                                    </span>
                                    <label>{item.folderName}</label>
                                    <a
                                    onClick={() =>
                                      handleLockTemplateModalShow(item.id)
                                    }
                                    >
                                      <img src="images/Lock.png" />
                                    </a>
                                    <a
                                      onClick={() =>
                                        handleFolderDeleteShow(
                                          item.id,
                                          item.folderName
                                        )
                                      }
                                    >
                                      <img src="images/Cross.png" />
                                    </a>
                                  </li>
                                ))
                              ) : (
                                <div className="text-center w-100">
                                  <h6>No data found</h6>
                                </div>
                              )
                            ) : allFolderList?.length > 0 ? (
                              allFolderList?.map((item) => (
                                <li>
                                  <span
                                    className="Icon"
                                    onClick={() => handleTagDocument(item.id)}
                                  >
                                    <img src="images/Folder.png" />
                                  </span>
                                  <label>{item.folderName}</label>
                                  <a
                                    onClick={() =>
                                      handleLockTemplateModalShow(item.id)
                                    }
                                  >
                                    <img src="images/Lock.png" />
                                  </a>
                                  <a
                                    onClick={() =>
                                      handleFolderDeleteShow(
                                        item.id,
                                        item.folderName
                                      )
                                    }
                                  >
                                    <img src="images/Cross.png" />
                                  </a>
                                </li>
                              ))
                            ) : (
                              <div className="text-center w-100">
                                <h6>No data found</h6>
                              </div>
                            )}
                          </>
                        )}
                      </ul>
                    </div>
          
                  </div>
                </div>
                <div className="col-sm-8">
           
                  <div className="FilesArea">
                    <div className="FilesHead">
                      <div className="FilesLeft">
                        <h4>Files</h4>
                        <aside>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            value={searchFolderName}
                            onChange={(e) =>
                              setSearchFolderName(e.target.value)
                            }
                          />
                        </aside>
                      </div>
                
                      <div className="FilesRight">
                        <a onClick={handleNewFileShow}>
                          {" "}
                          <img src="images/Icon-5.png" />
                        </a>
                        <a onClick={handleNewFileShow}>
                          <img src="images/Icon-6.png" />
                        </a>
                      </div>
                    </div>
                    <div className="FilesBody">
                      <div className="row">
                        {loader ? (
                          <td>
                            <Loader />
                          </td>
                        ) : (
                          <>
                            {searchFolderName ? (
                              searchDocumentationList?.length > 0 ? (
                                searchDocumentationList?.map((data, i) => {
                                  return (
                                    <div className="col-sm-4">
                                      <div className="FilesBox">
                                        <figure>
                                          <img src={data.docUrl} />
                                        </figure>
                                        <figcaption>
                                          <div>
                                            <h4>{data.name}</h4>
                                            <p>
                                              Added:{" "}
                                              {moment(data.createdAt).format(
                                                "YYYY-MM-DD"
                                              )}
                                            </p>
                                          </div>
                                          <ul>
                                            <li className="dropdown">
                                              <a
                                                className="dropdown-toggle"
                                                id="navbarDropdown"
                                                role="button"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                <img src="images/dots.png" />
                                              </a>
                                              {/* <ol class="dropdown-menu dropdown-menuwidth2">
                              <li><a href="javascript:void(0);" data-toggle="modal" data-target="#ViewFile"><img src="images/document-view.png">View</a></li>
                              <li><a href="javascript:void(0);" data-toggle="modal" data-target="#EditFile" ><img src="images/document-edit.png">Edit</a></li>
                              <li><a href="javascript:void(0);" data-toggle="modal" data-target="#EditFile" ><img src="images/document-e-sign.png">Insert Signature</a></li> */}
                                              {/* <li><a href="javascript:void(0);" data-toggle="modal" data-target="#EsignModal" ><img src="images/document-e-sign.png">E-sign</a></li> */}
                                              {/* <li><a href="javascript:void(0);" data-toggle="modal" data-target="#ApproveFile" ><img src="images/approve_icon.png">Approve</a></li> */}
                                              {/* <li><a href="javascript:void(0);"><img src="images/send_icon.png">Send for e-sign</a></li> */}
                                              {/* <li><a href="javascript:void(0);" data-toggle="modal" data-target="#SendForApproval" ><img src="images/send_approval.png">Send for approvel</a></li> */}
                                              {/* <li><a href="javascript:void(0);"><img src="images/dropdown-delete.png">Delete</a></li>
                              <li><a href="javascript:void(0);"><img src="images/download.png">Download documents</a></li>
                              <li><a href="javascript:void(0);"><img src="images/Action-2.png">Share documents</a></li>
                              <li><a href="javascript:void(0);"><img src="images/upload_template.png">Upload Template main picture</a></li>
                            </ol> */}
                                              <ol className="dropdown-menu dropdown-menuwidth">
                                                <li>
                                                  <a
                                                    href="javascript:void(0);"
                                                    data-toggle="modal"
                                                    data-target="#ViewFile"
                                                  >
                                                    <img src="images/document-view.png" />
                                                    View
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    href="javascript:void(0);"
                                                    data-toggle="modal"
                                                    data-target="#EditFileDesktop"
                                                  >
                                                    <img src="images/document-edit.png" />
                                                    Edit
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    href="javascript:void(0);"
                                                    data-toggle="modal"
                                                    data-target="#InsertSignature"
                                                  >
                                                    <img src="images/insert_signature.png" />
                                                    Insert Signature
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    href="javascript:void(0);"
                                                    data-toggle="modal"
                                                    data-target="#ApproveFile"
                                                  >
                                                    <img src="images/approve_icon.png" />
                                                    Approve
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    href="javascript:void(0);"
                                                    data-toggle="modal"
                                                    data-target="#SendForApproval"
                                                  >
                                                    <img src="images/send_approval.png" />
                                                    Send for approvel
                                                  </a>
                                                </li>
                                                <li>
                                                  <a href="javascript:void(0);">
                                                    <img src="images/download.png" />
                                                    Download File
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    href="javascript:void(0);"
                                                    data-toggle="modal"
                                                    data-target="#ShareDocument"
                                                  >
                                                    <img src="images/Action-2.png" />
                                                    Share documents
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    href="javascript:void(0);"
                                                    data-toggle="modal"
                                                    data-target="#DeleteMessage"
                                                  >
                                                    <img src="images/dropdown-delete.png" />
                                                    Delete
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    href="javascript:void(0);"
                                                    data-toggle="modal"
                                                    data-target="#UploadImages"
                                                  >
                                                    <img src="images/upload_template.png" />
                                                    Upload Template main picture
                                                  </a>
                                                </li>
                                              </ol>
                                            </li>
                                            <li>
                                              <a
                                                data-toggle="modal"
                                                data-target="#fileHistory"
                                              >
                                                <img src="images/timer.png" />
                                              </a>
                                            </li>
                                          </ul>
                                        </figcaption>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="text-center w-100">
                                  <h6>No data found</h6>
                                </div>
                              )
                            ) : documentationList?.length > 0 ? (
                              documentationList?.map((data, i) => {
                                return (
                                  <div className="col-sm-4">
                                    <div className="FilesBox">
                                      <figure>
                                        <img src={data.docUrl} />
                                      </figure>
                                      <figcaption>
                                        <div>
                                          <h4>{data.name}</h4>
                                          <p>
                                            Added:{" "}
                                            {moment(data.createdAt).format(
                                              "YYYY-MM-DD"
                                            )}
                                          </p>
                                        </div>
                                        <ul>
                                          <li className="dropdown">
                                            <a
                                              className="dropdown-toggle"
                                              id="navbarDropdown"
                                              role="button"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <img src="images/dots.png" />
                                            </a>
                                            {/* <ol class="dropdown-menu dropdown-menuwidth2">
                              <li><a href="javascript:void(0);" data-toggle="modal" data-target="#ViewFile"><img src="images/document-view.png">View</a></li>
                              <li><a href="javascript:void(0);" data-toggle="modal" data-target="#EditFile" ><img src="images/document-edit.png">Edit</a></li>
                              <li><a href="javascript:void(0);" data-toggle="modal" data-target="#EditFile" ><img src="images/document-e-sign.png">Insert Signature</a></li> */}
                                            {/* <li><a href="javascript:void(0);" data-toggle="modal" data-target="#EsignModal" ><img src="images/document-e-sign.png">E-sign</a></li> */}
                                            {/* <li><a href="javascript:void(0);" data-toggle="modal" data-target="#ApproveFile" ><img src="images/approve_icon.png">Approve</a></li> */}
                                            {/* <li><a href="javascript:void(0);"><img src="images/send_icon.png">Send for e-sign</a></li> */}
                                            {/* <li><a href="javascript:void(0);" data-toggle="modal" data-target="#SendForApproval" ><img src="images/send_approval.png">Send for approvel</a></li> */}
                                            {/* <li><a href="javascript:void(0);"><img src="images/dropdown-delete.png">Delete</a></li>
                              <li><a href="javascript:void(0);"><img src="images/download.png">Download documents</a></li>
                              <li><a href="javascript:void(0);"><img src="images/Action-2.png">Share documents</a></li>
                              <li><a href="javascript:void(0);"><img src="images/upload_template.png">Upload Template main picture</a></li>
                            </ol> */}
                                            <ol className="dropdown-menu dropdown-menuwidth">
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    handleViewDocumentationShow(
                                                      data.folderId,
                                                      data.id
                                                    )
                                                  }
                                                >
                                                  <img src="images/document-view.png" />
                                                  View
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    handleEditDocumentationShow(
                                                      data.folderId,
                                                      data.id
                                                    )
                                                  }
                                                >
                                                  <img src="images/document-edit.png" />
                                                  Edit
                                                </a>
                                              </li>
                                         
                                       
                                              <li>
                                                <a
                                                  href={data.docUrl}
                                                  target="_blank"
                                                  title="ImageName"
                                                >
                                                  <img src="images/download.png" />
                                                  Download File
                                                </a>
                                              </li>
                                             
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    handleDocumentationDeleteShow(
                                                      data.id
                                                    )
                                                  }
                                                >
                                                  <img src="images/dropdown-delete.png" />
                                                  Delete
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    handleUploadTemplateShow(
                                                      data.folderId,
                                                      data.id
                                                    )
                                                  }
                                                >
                                                  <img src="images/upload_template.png" />
                                                  Upload Template main picture
                                                </a>
                                              </li>
                                            </ol>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                handleFilesHistoryModalShow(
                                                  data.folderId,
                                                  data.id
                                                )
                                              }
                                            >
                                              <img src="images/timer.png" />
                                            </a>
                                          </li>
                                        </ul>
                                      </figcaption>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="text-center w-100">
                                <h6>No data found</h6>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={CreateFolderModal}
          onHide={handleCreateFolderClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleCreateFolderClose}>
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Create Folder</h4>
                  </div>
                  <div className="FolderForm">
                    <form onSubmit={handleCreateFolderSubmit}>
                      <div className="form-group">
                        <h6>Folder Name *</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert new folder name"
                          name="folderName"
                          value={folderName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <h6>Folder Permissions</h6>
                        <div className="PermissionsName">
                          <h5
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#permissionModal2"
                            data-dismiss="modal"
                          >
                            <span>
                              <img src="images/Folder.png" />
                            </span>{" "}
                            Folder Permissions
                          </h5>
                        </div>
                      </div>
                      <div className="form-group">
                        <h6>Folder Actions</h6>
                        <div className="PermissionsName">
                          <h5
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#folderActionModal2"
                            data-dismiss="modal"
                          >
                            <span>
                              <img src="images/Icon-4.png" />
                            </span>{" "}
                            Folder Actions
                          </h5>
                        </div>
                      </div>
                      <div className="Button text-center">
                        <button className="Create ClosePermissionId">
                          Create
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={ManageSignatueModal}
          onHide={handleManageSignatueClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <a className="CloseModal" onClick={handleManageSignatueClose}>
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Manage Signatures</h4>
              </div>
              <div className="FolderForm">
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Add new signature</h5>
                    <div className="CommonModalBox">
                      <h5>
                        <figure>
                          <img src="images/add_new_signature.png" />
                        </figure>{" "}
                        Add new signature
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Signatures</h5>
                    <div className="CommonModalBox">
                      {/* <input
                        // type="file"
                        // name="signature"
                        // onChange={handleInputFile}
                      /> */}
                      <SignaturePad
                        ref={signCanvas}
                        onEnd={() =>
                          updateState({
                            ...iState,
                            signature: signCanvas?.current
                              ?.getTrimmedCanvas()
                              .toDataURL("image/png"),
                          })
                        }
                      />
                      <a onClick={clearSignature}>
                        <img src="images/circle_minus.png" />
                      </a>
                      {/* <a onClick={saveSignature} href="javascript:void(0);">
                        Save
                      </a> */}
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.signatureError}
                    </span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Add new Initials</h5>
                    <div className="CommonModalBox">
                      <h5>
                        <figure>
                          <img src="images/add_new_signature.png" />
                        </figure>{" "}
                        Add new Initials
                      </h5>
                      <div></div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Initials</h5>
                    <div className="CommonModalBox">
                      <SignaturePad
                        ref={intialCanvas}
                        onEnd={() =>
                          updateState({
                            ...iState,
                            initial: signCanvas?.current
                              ?.getTrimmedCanvas()
                              .toDataURL("image/png"),
                          })
                        }
                      />
                      <a onClick={clearInitials}>
                        <img src="images/circle_minus.png" />
                      </a>
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.initialError}
                    </span>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={addSignatureFun}>
                    Save
                  </button>
                  <button
                    className="Outline"
                    onClick={handleManageSignatueClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={NewFileModal}
          onHide={handleNewFileClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleNewFileClose}>
                  ×
                </a>

                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Upload New File</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="form-group">
                      <h6>Name</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert new entry name"
                        name="fileFolderName"
                        value={fileFolderName}
                        onChange={handleInputChange}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors?.nameError}
                      </span>
                    </div>
                    <div className="form-group">
                      <h6>Folder</h6>
                      <div className="Categories Categories1">
                        <div className="Categories_all Categories_all1">
                          <span>
                            Select folder where file should be created
                          </span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          {/* <li>
                            <input type="text" placeholder="-Search" />
                            <span className="Search">
                              <img src="images/search.png" />
                            </span>
                          </li> */}
                          {/* <li>
                             
                            </li> */}
                          <div className="form-group">
                            <label>
                              list of folders where user has permission to
                              create file
                            </label>
                            <select
                              className="form-control"
                              name="id"
                              value={id}
                              // onFocus={handleValidation}
                              onChange={handleInputChange}
                            >
                              <option value="">Select</option>
                              {allFolderList?.length > 0 &&
                                allFolderList?.map((item) => {
                                  return (
                                    <option value={item.id}>
                                      {item.folderName}{" "}
                                    </option>
                                  );
                                })}
                            </select>
                            <span style={{ color: "red" }}>
                              {errors?.foldNameError}
                            </span>
                          </div>
                        </ul>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <h6>Editor Option</h6>
                      <div className="mb-4">
                        <label className="Radio">
                          Create file with base template upload
                          <input
                            type="radio"
                            name="Cuisine"
                            // defaultValue="text"
                            // defaultChecked=""
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div>
                        <label className="Radio">
                          Create new file with editor
                          <input
                            type="radio"
                            name="Cuisine"
                            defaultValue="file"
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                    <div className="file RadioText"></div>
                    <div
                      className="text RadioText"
                      style={{ display: "block" }}
                    >
                      <div className="form-group">
                        <h6>Select Template File (Docx)</h6>
                        <div className="Upload">
                          <span>-Drag and Drop Files or click to select</span>
                          <input
                            type="file"
                            id="createFileInput"
                            onChange={(e) => handleFileChange(e, "createFile")}
                          />
                        </div>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errors?.cltfileError}
                        </span>
                      </div>
                      {clientFile && (
                        <div className="FilesAdded">
                          <label>{clientFile?.name}</label>
                          <span>
                            <img src="images/fileview.png" />
                          </span>
                          <span onClick={handleRemoveClientFile}>
                            <img src="images/Cross.png" className="cross" />
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="Button d-flex justify-content-between mt-5">
                      <button
                        className="Create FolderPermissionId"
                        onClick={handleNewFileSubmit}
                      >
                        Create File
                      </button>
                      <button className="Outline" onClick={handleNewFileClose}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={DocumentationDeleteModal}
          onHide={handleDocumentationDeleteClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleDocumentationDeleteClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Delete</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="my-5">
                      <h6>Are you sure you want to delete this ?</h6>
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button
                        className="Create"
                        onClick={handleDocumentationDelete}
                      >
                        Delete
                      </button>
                      <button
                        className="Outline"
                        onClick={handleDocumentationDeleteClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={ShareClientDocumentationModal}
          onHide={handleShareClientDocumentClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleShareClientDocumentClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Share Document</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="ModalBigheading">
                      <h3>
                        <span>Documents:</span>Statement of Adjustments.pdf
                      </h3>
                    </div>
                    <div className="ModalFilterAreaBox mb-3">
                      <h4 className="mb-4">Select Recipient</h4>
                      <div className="RadioArea d-flex  flex-column">
                        <div className="form-group">
                          <label className="Radio">
                            Select Recipient
                            <input
                              type="radio"
                              name="Cuisine"
                              defaultValue="text"
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="form-group mb-4">
                          <label className="Radio">
                            Other Contact
                            <input
                              type="radio"
                              name="Cuisine"
                              defaultValue="file"
                              defaultChecked=""
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="text RadioText">
                      <div className="form-group"></div>
                    </div>
                    <div
                      className="file RadioText mb-5"
                      style={{ display: "block" }}
                    >
                      <div className="form-group">
                        <h6>Name</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert name"
                          name="clientName"
                          value={clientName}
                          onChange={handleInputChange}
                        />
                        {/* <span style={{ color: "red" }}>
                          {errors?.cliNameError}
                        </span> */}
                      </div>
                      <div className="form-group">
                        <h6>Email</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert Email"
                          name="clientEmail"
                          value={clientEmail}
                          onChange={handleInputChange}
                        />
                        {/* <span style={{ color: "red" }}>
                          {errors?.cliEmailError}
                        </span> */}
                      </div>

                      <div className="Button" onClick={handleAddRecipient}>
                        <button
                          className="Create"
                          disabled={clientEmail == "" && clientName == ""}
                        >
                          Add Recipient
                        </button>
                      </div>
                    </div>
                    <h6>Recipients</h6>
                    <div className="FilesAddedTable">
                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          {emails?.length > 0 &&
                            emails?.map((item, ind) => {
                              return (
                                <tr>
                                  <td>
                                    <div className="FilesAddedName">
                                      <p>{item.name}</p>
                                      <span>
                                        <img
                                          src="images/Cross.png"
                                          onClick={() =>
                                            handleRemoveRecipient(ind)
                                          }
                                        />
                                      </span>
                                    </div>
                                  </td>
                                  <td>{item.email}</td>
                                </tr>
                              );
                            })}
                          <span style={{ color: "red" }}>
                            {errors?.cliNameError}
                          </span>
                        </tbody>
                      </table>
                    </div>
                    <div className="form-group">
                      <h6>Message</h6>
                      <textarea
                        type="text"
                        className="form-control"
                        rows={4}
                        placeholder="Include message (optional)"
                        name="emailBody"
                        value={emailBody}
                        onChange={handleInputChange}
                      />
                      <span style={{ color: "red" }}>{errors?.eBodyError}</span>
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button
                        className="Create mr-3"
                        onClick={handleShareDocumentSubmit}
                      >
                        Share Document
                      </button>
                      <button
                        className="Outline ml-3"
                        onClick={handleShareClientDocumentClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={SendForApprovalModal}
          onHide={handleSendForApprovalClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleSendForApprovalClose}>
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Send file for Approval</h4>
                  </div>
                  <div className="form-group mb-4">
                    <h6>Approver Type</h6>
                    {["Client", "Staff Member", "Vendor"]?.map((type) => (
                      <div key={type} className="mb-4">
                        <label className="Radio">
                          {type}
                          <input
                            type="radio"
                            value={type}
                            checked={approverType === type}
                            onChange={() => handleTypeChange(type)}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="FolderForm">
                    <div className="form-group">
                      <h6>Contact Selection</h6>
                      <div className="mb-4">
                        <label className="Radio">
                          {" "}
                          Select from System
                          <input
                            type="radio"
                            name="Cuisine"
                            defaultValue="text"
                            defaultChecked=""
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <label className="Radio">
                        {" "}
                        Input Manually
                        <input
                          type="radio"
                          name="Cuisine"
                          defaultValue="file"
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div
                      className="text RadioText"
                      style={{ display: "block" }}
                    >
                      <div className="form-group">
                        <div className="Categories Categories3">
                          <h6>Select Client</h6>
                          <div className="Categories_all Categories_all3">
                            <span>Select Client</span>
                            <img src="images/down.png" />
                          </div>
                          <ul style={{ display: "none" }}>
                            <li>
                              <input type="text" placeholder="-Search" />
                              <span className="Search">
                                <img src="images/search.png" />
                              </span>
                            </li>
                            <li>- List of Clients loaded from the system</li>
                          </ul>
                        </div>
                      </div>
                      <>
                        {Array.isArray(contactSelection) &&
                          contactSelection.map((contact, index) => (
                            <>
                              <div key={index} className="form-group">
                                <h6>Name</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Insert name"
                                  name="name"
                                  value={contact.name}
                                  onChange={(e) =>
                                    handleNameEmailChange(e, index)
                                  }
                                />
                              </div>
                              <div className="form-group">
                                <h6>Email</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Insert Email"
                                  name="email"
                                  value={contact.email}
                                  onChange={(e) =>
                                    handleNameEmailChange(e, index)
                                  }
                                />
                              </div>
                            </>
                          ))}
                      </>
                    </div>

                    <div className="Button d-flex justify-content-between mt-4">
                      <button
                        className="Create"
                        onClick={handleSendForApprovalSubmit}
                      >
                        Confirm Selection
                      </button>
                      <button
                        className="Outline"
                        onClick={handleSendForApprovalClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={UploadTemplateModal}
          onHide={handleUploadTemplateClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleUploadTemplateClose}>
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Upload Images</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="form-group">
                      <h6>Select Files</h6>
                      <div className="Upload">
                        <span>-Drag and Drop Files or click to select</span>
                        <input
                          type="file"
                          id="templateFileInput"
                          onChange={(e) => handleFileChange(e, "template")}
                        />
                      </div>
                    </div>
                    {/* <div className="Button mb-4">
                        <button className="Create">Add</button>
                      </div> */}
                    {templateFile && (
                      <div className="form-group FilesAddedGroup">
                        <h6>Images</h6>
                        <div className="FilesAdded">
                          <label>{templateFile?.name}</label>
                          <span>
                            <img
                              src={URL.createObjectURL(templateFile)}
                              alt="Image Preview"
                            />
                          </span>
                          <span className="cross">
                            <img
                              src="images/Cross.png"
                              onClick={handleRemoveTemplate}
                            />
                          </span>
                        </div>
                      </div>
                    )}

                    {/* <div className="Button mb-5">
                        <button className="Create">View</button>
                      </div> */}
                    <div className="Button d-flex justify-content-between">
                      <button
                        className="Create"
                        onClick={handleUploadTemplateSubmit}
                      >
                        Save
                      </button>
                      <button
                        className="Outline"
                        onClick={handleUploadTemplateClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={ViewDocumentationModal}
          onHide={handleViewDocumentationClose}
          className="ModalBox LargeModal GreyModalContent"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleViewDocumentationClose}
                >
                  ×
                </a>
                <div>
                  {viewDocumentData && (
                    <CKEditor
                      editor={ClassicEditor}
                      config={configObject}
                      readOnly={true}
                      data={viewDocumentData}
                    />
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={ApproveDocumentationModal}
          onHide={handleApproveDocumentationClose}
          className="ModalBox LargeModal GreyModalContent"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleApproveDocumentationClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  {viewDocumentEditData && (
                    <iframe
                      src={`https://docs.google.com/gview?url=${encodeURIComponent(
                        viewDocumentEditData
                      )}&embedded=true`}
                      title="Document Viewer"
                      width="100%"
                      height="500"
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                    // Additionally, provide a download link for non-supported formats
                    // For example, check the file extension and conditionally render a download link
                    // for formats that are not supported by the viewer.
                  )}
                  <div className="FolderForm">
                    <div className="Button d-flex justify-content-between">
                      <button
                        className="BrandBtnGreen"
                        onClick={() => handleApprovReject("approve")}
                      >
                        Approve
                      </button>
                      <button
                        className="Create"
                        onClick={() => handleApprovReject("reject")}
                      >
                        Reject
                      </button>
                      <button
                        className="Outline"
                        onClick={handleApproveDocumentationClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={FolderDeleteModal}
          onHide={handleFolderDeleteClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleFolderDeleteClose}>
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Delete</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="my-5">
                      <h6>Are you sure you want to delete this ?</h6>
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button className="Create" onClick={handleFolderDelete}>
                        Delete
                      </button>
                      <button
                        className="Outline"
                        onClick={handleFolderDeleteClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={EditDocumentationModal}
          onHide={handleEditDocumentationClose}
          className="ModalBox LargeModal GreyModalContent"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleEditDocumentationClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Edit File</h4>
                  </div>
                  <div className="FolderForm">
                    {content && (
                      <CKEditor
                        editor={ClassicEditor}
                        config={configObject}
                        data={content}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          updateState({ ...iState, content: data });
                        }}
                      />
                    )}
                    <div className="Button d-flex justify-content-between">
                      {/* <button
                        className="Create"
                        data-toggle="modal"
                        data-target="#FilePreviewDesktop"
                        data-dismiss="modal"
                      >
                        Preview
                      </button> */}
                      <button
                        className="Create mt-5"
                        onClick={handleEditDocumentation}
                      >
                        Save File
                      </button>
                      <button
                        className="Create mt-5"
                        onClick={handleEditDocumentationClose}
                      >
                        Cancel &amp; Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={RelaseTemplateModal}
          onHide={handleRelaseTemplateModalClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleRelaseTemplateModalClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Release Templates</h4>
                  </div>
                  <div className="FolderForm">
                    <form>
                      <div className="form-group">
                        <h6>Project</h6>
                        <div className="CommonSelectBox w-100">
                          <select>
                            <option selected="">Select Project</option>
                            <option>
                              -List of active projects in the company
                            </option>
                          </select>
                          <span>
                            <img src="images/down.png" />
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="SubFileManager">
                            <div className="ModalMediumheading">
                              <h3>
                                <span>Sub File Manager</span>
                              </h3>
                            </div>
                            <div className="form-group">
                              <h6>Construction</h6>
                              <label className="Radio">
                                <input type="radio" name="Cuisine" />
                                <span className="checkmark" />
                              </label>
                            </div>
                            <div className="form-group">
                              <h6>Design</h6>
                              <label className="Radio">
                                <input type="radio" name="Cuisine" />
                                <span className="checkmark" />
                              </label>
                            </div>
                            <div className="form-group">
                              <h6>Sales</h6>
                              <label className="Radio">
                                <input type="radio" name="Cuisine" />
                                <span className="checkmark" />
                              </label>
                            </div>
                            <div className="form-group">
                              <h6>Warranty</h6>
                              <label className="Radio">
                                <input type="radio" name="Cuisine" />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mt-4">
                        <h6>System File Manager Folder</h6>
                        <div className="CommonSelectBox w-100">
                          <select>
                            <option selected="">Select Project</option>
                            <option>
                              -List of Folders available in the System File
                              Manager
                            </option>
                          </select>
                          <span>
                            <img src="images/down.png" />
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="ModalMediumheading">
                            <h3>
                              <span>Templates</span>
                            </h3>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="DisplayLoginOnPage">
                            <div className="form-group">
                              <div className="mb-2">
                                <label className="CheckBox">
                                  {" "}
                                  File 1
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </div>
                              <div className="mb-2">
                                <label className="CheckBox">
                                  {" "}
                                  File 2
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </div>
                              <div>
                                <label className="CheckBox">
                                  {" "}
                                  File 3
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </div>
                              <div>
                                <label className="CheckBox">
                                  {" "}
                                  File 4
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="DisplayLoginOnPage">
                            <div className="form-group">
                              <div className="mb-2">
                                <label className="CheckBox">
                                  {" "}
                                  File 5
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </div>
                              <div className="mb-2">
                                <label className="CheckBox">
                                  {" "}
                                  File 6
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </div>
                              <div>
                                <label className="CheckBox">
                                  {" "}
                                  File 7
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </div>
                              <div>
                                <label className="CheckBox">
                                  {" "}
                                  File 8
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="Button d-flex justify-content-between mt-2">
                        <button className="Create" data-dismiss="modal">
                          Release Templates
                        </button>
                        <button className="Outline" data-dismiss="modal">
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={LockTemplateModal}
          onHide={handleLockTemplateModalClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleLockTemplateModalClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Lock Template</h4>
                  </div>
                  <div className="FolderForm">
                    <form>
                      <div className="ModalMediumheading">
                        <h3>
                          <span>Folder:</span>Sales Contract
                        </h3>
                      </div>
                      <div className="AdvancedCostCodes Lock">
                        <table>
                          <thead>
                            <tr>
                              <th>Templates</th>
                              <th>Lock</th>
                            </tr>
                          </thead>
                          <tbody>
                            {templates?.map((item)=>
                                 <tr>
                                 <td>{item?.name}</td>
                                 <td>
                                   <label className="switch">
                                     <input type="checkbox" />
                                     <span className="slider" />
                                   </label>
                                 </td>
                               </tr>
                          )}
                       
                    
                          </tbody>
                        </table>
                      </div>
                      <div className="Button d-flex justify-content-between mt-5">
                        <button onClick={handleLockFolder} className="Create" data-dismiss="modal">
                          Lock templates
                        </button>
                        <button className="Outline" onClick={handleLockTemplateModalClose} data-dismiss="modal">
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={FilesHistoryModal}
          onHide={handleFilesHistoryModalClose}
          className="ModalBox fade SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleFilesHistoryModalClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Files History</h4>
                  </div>
                  <div className="ModalTitleHeading">
                    <h2>
                      <span>Name: </span>Document_2024.doc
                    </h2>
                  </div>
                  <div className="TableList">
                    <table>
                      <thead>
                        <tr>
                          <th>Modified by</th>
                          <th>Created by</th>
                          <th>Date</th>
                          <th>Activity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fileHistoryList?.clientHistories?.length > 0
                          ? fileHistoryList?.clientHistories?.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>{item.modifiedBy ?? "N/A"}</td>
                                  <td>{item.createdBy ?? "N/A"}</td>
                                  <td>
                                    {moment(item.createdAt).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </td>
                                  <td>{item.activity}</td>
                                </tr>
                              );
                            })
                          : "No Data Found"}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={DocumentKeyModal}
          onHide={handleDocumentKeyModalClose}
          className="ModalBox modal fade MediumModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleDocumentKeyModalClose}
            >
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Document Key</h4>
              </div>
              <div className="FolderForm">
                <div className="row">
                  <div className="col-md-7">
                    <div className="form-group">
                      <h6>Keys List</h6>
                      <div className="Categories Categories1">
                        <div className="Categories_all Categories_all1">
                          <span>Block Job</span>
                          <img src="images/down.png" />
                        </div>
                        <ul style={{ display: "block" }}>
                          <li>
                            <input
                              type="text"
                              placeholder="Search"
                              name="documentSearch"
                              value={documentSearch}
                              onChange={handleInputChange}
                            />
                            <span className="Search">
                              <img src="images/search.png" />
                            </span>
                          </li>

                          {allDocumentKey?.length > 0 &&
                            allDocumentKey
                              ?.filter((data) =>
                                documentSearch.toLowerCase() === ""
                                  ? true
                                  : data.label
                                      .toLowerCase()
                                      .includes(documentSearch.toLowerCase())
                              )
                              .map((item, i) => {
                                return (
                                  <li
                                    value={item.label}
                                    key={i}
                                    onClick={() => handleItemClick(item.label)}
                                    style={{
                                      backgroundColor:
                                        JobId === item.label
                                          ? "#e71e09"
                                          : "transparent",
                                    }}
                                  >
                                    {item.label}
                                  </li>
                                );
                              })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 offset-1">
                    <div className="form-group">
                      <h6>Key</h6>
                      <p className="GreenSignedColor ml-0">
                        <img src="images/insert_keys.png" />
                        &nbsp;&nbsp;{JobId ?? "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    </div>
  );
};

export default IbuildAdminFileManager;
