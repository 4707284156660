// const handleChangePhone = (event) => {
//     const result = event.target.value.replace(/\D/g, '');
//     setcontactNumber(result);
//   };

// const handleValidation = () => {
    //     let error = {};
    //     let formIsValid = true;
    //     if (flatsData?.some((x) => x?.name?.toString()?.trim() == "")) {
    //       error.nameErrorr = "* Name can't be empty";
    //       formIsValid = false;
    //     }
    //     // if (flatsData?.some((x) => x?.name?.phoneNumber()?.trim() == "")) {
    //     //   error.ot = "* Name can't be empty";
    //     //   formIsValid = false;
    //     // }
    //     if (flatsData?.some((x) => x?.owner?.toString()?.trim() == "")) {
    //       error.ownerErrorr = "* Owner can't be empty";
    //       formIsValid = false;
    //     }
        // if (flatsData?.some((x) => x?.bathroom?.toString()?.trim() == "")) {
        //   error.bathroomErrorr = "* Bathroom can't be empty";
        //   formIsValid = false;
        // }
        // if (flatsData?.some((x) => x?.sqft?.toString().trim() == "")) {
        //   error.sqftErrorr = "* Sqft can't be empty";
        //   formIsValid = false;
        // }
    //     if (flatsData?.some((x) => x?.bedroom?.toString()?.trim() == "")) {
    //       error.bedroomError = "* Bedroom can't be empty";
    //       formIsValid = false;
    //     }
    //     if (flatsData?.some((x) => x?.document?.toString()?.trim() == "")) {
    //       error.documentError = "* Document can't be empty";
    //       formIsValid = false;
    //     }
    //     updateState({ ...iState, errors: error });
    //     return formIsValid;
    //   };










    // const AddressFun = (type, _id) => {
    //     const data = {postOffice,policeStation,division,district,_id:editServiceCategoryData}
    //     let formIsValid = handleValidationAddress();
    //     if(formIsValid){
    //       if(type === "Add"){
    //         dispatch(addAddressAction(data)).then((res)=> {
    //           sucessToast(res.message)
    //           CloseAddressModal()
    //         })
    //       } else if(type === "Edit") {
    //         dispatch(updateAddressAction(data)).then((res)=> {
    //           sucessToast(res.message)
    //          CloseEditAddressModal()
    //         })
    //       } }
    //       else if(type === "Delete") {
    //         dispatch(removeAddress({_id:deleteId})).then((res)=> {
    //           console.log(res)
    //           sucessToast(res.message)
    //           CloseDeleteAddressModal()
    //         })   
    //     }
    //     dispatch(addressList())
    
    //   }
    
    //   const changeStatusAddress = (status,statusChangeId) => {
    //     dispatch(changeAddressStatus({ _id: statusChangeId, status: status })).then((res) => {
    //       dispatch(addressList())
    //     })
       
    //   };
    
    //   const handleValidationAddress = () => {
    //     let error = {};
    //     let formIsValid = true;
    
    //     if(!division){
    //         error.divisionError = "! Divison can't be empty"
    //         formIsValid = false
    //     }
    //     if(!district){
    //       error.districtError = "! District can't be empty"
    //       formIsValid= false
    //     }
    //     if(!postOffice){
    //       error.postOfficrError = "! Post office can't be empty"
    //       formIsValid = false
    //   }
    //    if(!policeStation){
    //     error.policeStationError = "! Police station can't be empty"
    //     formIsValid= false
    //   }
    //     updateState({...iState, errors:error})
    //     return formIsValid
    
    //   }
    
    

    export function getMaxDate() {
        const date = new Date();
        return date?.toISOString()?.split('T')[0];
       
      }