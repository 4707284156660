import React, { useEffect, useState } from "react";
import ClientViewSidenav from "./client-view-sidenav";
import ClientHeader from "./ClientHeader";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import moment from "moment";
import {
  getExteriorDesignSelections,
  getInteriorDesignSelections,
} from "../redux/action/clientViewAuthAction";

const initialState = {};
const DesignSelectionsClientView = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const {} = iState;
  const { interiorListData, exteriorListData } = useSelector(
    (state) => state.clientViewLogin
  );
  const clientData = JSON.parse(window.localStorage.getItem("clientViewLogin"));
  useEffect(() => {
    dispatch(getInteriorDesignSelections());
    dispatch(getExteriorDesignSelections());
  }, []);


  const [selectedIdx, setSelectedIdx] = useState(null);
  return (
    <div>
      <ClientHeader />
      <ClientViewSidenav />
      <div className="WrapperAreaClient">
        <div className="WrapperBoxClient">
          <div className="MainTitleBox">
            <div className="MainTitle">
              <h2>Design Selections</h2>
            </div>
          </div>
          <div className="TitleBox">
            <div className="TodosButtons">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#InteriorSelectionsTab"
                  >
                    <img src="images/warranty-request.png" />
                    Interior Selections<span>3</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#ExteriorSelectionsTab"
                  >
                    <img src="images/work-order-red.png" />
                    Exterior Selections<span>3</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="tab-content DesignSelectionsContent">
            <div className="tab-pane fade show active " id="InteriorSelectionsTab">
              <div className="InteriorSelections">
                <div
                  className="DesignSelectionHide"
                  style={{ display: "block" }}
                >
                  <div className="row">
                    <div className="col-md-4 offset-2">
                      <div className="form-group">
                        <h6>Last Update</h6>
                        <p>08/10/2023</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <h6>Selection Status</h6>
                        <p className="mb-2">Pending Client Approval</p>
                        <p className="text-black2">
                          Client Selections Completed 0/3
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="NewAccording mb-4">
                    {interiorListData?.agreement?.[0]?.DesignCategoriesJunctions
                      ?.length > 0 &&
                      interiorListData?.agreement?.[0]?.DesignCategoriesJunctions?.map(
                        (item, ind) => {
                          const isOpen = ind === selectedIdx;
                          return (
                            <div className="card">
                              <div
                                className={`card-header HeaderGreen mt-2 mb-2 ${
                                  isOpen ? "open" : ""
                                }`}
                              >
                                <h4
                                  onClick={() =>
                                    setSelectedIdx(isOpen ? null : ind)
                                  }
                                >
                                  <span>
                                    <img src="images/down_arrow_white.png" />
                                  </span>{" "}
                                  Doors Interior{" "}
                                </h4>
                                <a
                                  href="javascript:void(0);"
                                  className="Edit dropdown"
                                >
                                  <div
                                    className=" mt-1"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/white_dots.png" />
                                  </div>
                                  <div
                                    className="dropdown-menu DesignSelectionDropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    {/* <div className="dropdown-item">
                                    <img src="images/Action-1.png" />
                                    Edit Category Name&nbsp;&nbsp;
                                  </div>
                                  <div className="dropdown-item AddOptionDropdown">
                                    <img src="images/add_option.png" />
                                    Add Option
                                  </div> */}
                                    {/* <div className="dropdown-item">
                                    <img src="images/dropdown-delete.png" />
                                    Remove Category
                                  </div> */}
                                  </div>
                                </a>
                              </div>
                              <div
                                className={`collapse ${isOpen ? "show" : ""}`}
                              >
                                <div className="DesignSelectionHeader">
                                  <div className="form-group">
                                    <h6>Deadline </h6>
                                    <p>{item.deadline ?? "N/A"}</p>
                                  </div>
                                  <div className="form-group">
                                    <h6>Allowance </h6>
                                    {item.allowance ?? "N/A"}
                                  </div>
                                  <div className="form-group">
                                    <h6>Change Order</h6>
                                    <p>$0.00</p>
                                  </div>
                                  <div className="form-group">
                                    <h6>Type</h6>
                                    <p>{item.type ?? "N/A"}</p>
                                  </div>
                                  <div className="form-group">
                                    <h6>Status</h6>
                                    <p>N/A</p>
                                  </div>
                                </div>
                                <div className="InteriorSelectionsArea p-0">
                                  <div className="InteriorSelectionsTable d-block">
                                    <table style={{ width: "150%" }}>
                                      <tbody>
                                        {item?.DesingSelectionOptions?.length >
                                          0 &&
                                          item?.DesingSelectionOptions?.map(
                                            (data, index) => {
                                              return (
                                                <tr key={index}>
                                                  <td>
                                                    <div className="Action">
                                                      <img src="images/gate_img.png" />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Name</h6>
                                                      <p className="BlueText">
                                                        Masonite Roman
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Description</h6>
                                                      <p>
                                                        32-inch x 80-inch x 4
                                                        9/16-inch 2-Panel Hollow
                                                        Core Single Prehung
                                                        Interior Door LH
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Vendor</h6>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Cost Code</h6>
                                                      <p>
                                                        08.0201 Windows &amp;
                                                        Doors - Interior
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Measure</h6>
                                                      <p>per unit</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Per Unit</h6>
                                                      <p>$394.00</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Quantity</h6>
                                                      <p>2</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Discount</h6>
                                                      <p>0%</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Client Total</h6>
                                                      <p>$945.60</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Attachments</h6>
                                                      <div className="Actions">
                                                        <a href="javascript:void(0);">
                                                          <img src="images/attachment.png" />
                                                        </a>
                                                        <a href="javascript:void(0)">
                                                          <span className="Count">
                                                            3
                                                          </span>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <button className="Commited mt-4">
                                                      Select
                                                    </button>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="ExteriorSelectionsTab">
              <div className="InteriorSelections">
                <div className="DesignSelectionHide">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <h6>Last Update</h6>
                        <p>08/10/2023</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <h6>Selection Status</h6>
                        <p>New</p>
                      </div>
                    </div>
                  </div>
                  <div className="NewAccording mb-4">
                    {exteriorListData?.agreement?.[0]?.DesignCategoriesJunctions
                      ?.length > 0 &&
                      exteriorListData?.agreement?.[0]?.DesignCategoriesJunctions?.map(
                        (item, ind) => {
                          const isOpen = ind === selectedIdx;
                          return (
                            <div className="card">
                              <div
                                className={`card-header HeaderGreen mt-2 mb-2 ${
                                  isOpen ? "open" : ""
                                }`}
                              >
                                <h4
                                  onClick={() =>
                                    setSelectedIdx(isOpen ? null : ind)
                                  }
                                >
                                  <span>
                                    <img src="images/down_arrow_white.png" />
                                  </span>{" "}
                                  Doors Interior{" "}
                                </h4>
                                <a
                                  href="javascript:void(0);"
                                  className="Edit dropdown"
                                >
                                  <div
                                    className=" mt-1"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/white_dots.png" />
                                  </div>
                                  <div
                                    className="dropdown-menu DesignSelectionDropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    {/* <div className="dropdown-item">
                                    <img src="images/Action-1.png" />
                                    Edit Category Name&nbsp;&nbsp;
                                  </div>
                                  <div className="dropdown-item AddOptionDropdown">
                                    <img src="images/add_option.png" />
                                    Add Option
                                  </div> */}
                                    {/* <div className="dropdown-item">
                                    <img src="images/dropdown-delete.png" />
                                    Remove Category
                                  </div> */}
                                  </div>
                                </a>
                              </div>
                              <div
                                className={`collapse ${isOpen ? "show" : ""}`}
                              >
                                <div className="DesignSelectionHeader">
                                  <div className="form-group">
                                    <h6>Deadline </h6>
                                    <p>{item.deadline ?? "N/A"}</p>
                                  </div>
                                  <div className="form-group">
                                    <h6>Allowance </h6>
                                    {item.allowance ?? "N/A"}
                                  </div>
                                  <div className="form-group">
                                    <h6>Change Order</h6>
                                    <p>$0.00</p>
                                  </div>
                                  <div className="form-group">
                                    <h6>Type</h6>
                                    <p>{item.type ?? "N/A"}</p>
                                  </div>
                                  <div className="form-group">
                                    <h6>Status</h6>
                                    <p>N/A</p>
                                  </div>
                                </div>
                                <div className="InteriorSelectionsArea p-0">
                                  <div className="InteriorSelectionsTable d-block">
                                    <table style={{ width: "150%" }}>
                                      <tbody>
                                        {item?.DesingSelectionOptions?.length >
                                          0 &&
                                          item?.DesingSelectionOptions?.map(
                                            (data, index) => {
                                              return (
                                                <tr key={index}>
                                                  <td>
                                                    <div className="Action">
                                                      <img src="images/gate_img.png" />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Name</h6>
                                                      <p className="BlueText">
                                                        Masonite Roman
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Description</h6>
                                                      <p>
                                                        32-inch x 80-inch x 4
                                                        9/16-inch 2-Panel Hollow
                                                        Core Single Prehung
                                                        Interior Door LH
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Vendor</h6>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Cost Code</h6>
                                                      <p>
                                                        08.0201 Windows &amp;
                                                        Doors - Interior
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Measure</h6>
                                                      <p>per unit</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Per Unit</h6>
                                                      <p>$394.00</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Quantity</h6>
                                                      <p>2</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Discount</h6>
                                                      <p>0%</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Client Total</h6>
                                                      <p>$945.60</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="Action">
                                                      <h6>Attachments</h6>
                                                      <div className="Actions">
                                                        <a href="javascript:void(0);">
                                                          <img src="images/attachment.png" />
                                                        </a>
                                                        <a href="javascript:void(0)">
                                                          <span className="Count">
                                                            3
                                                          </span>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <button className="Commited mt-4">
                                                      Select
                                                    </button>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>

                  <div
                    className="Button text-center"
                    style={{ margin: "100px 0" }}
                  >
                    <button className="Create mr-5">
                      Send for Client Approval
                    </button>
                    <button className="Create SelectionHistoryBtn">
                      Selection History
                    </button>
                    <button className="Outline ml-5">Delete Selection</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Button text-center" style={{ margin: "100px 0" }}>
            <button className="Create mr-5">Save</button>
            <button className="Outline ml-5">Clear Selections</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignSelectionsClientView;
