import { AdminSigningAuthorityConstant } from "../actionTypes";
const initialState={
    authoriyList:{},
    loader:false,
}


export default function adminSigningAuthorityReducer(state = initialState, {type, payload}) {
    
    switch(type) {
      case AdminSigningAuthorityConstant.GET_AUTHORITY_INITIATE: {
        return {
          ...state, loader:true, authoriyList:[]
        }
      }
      case AdminSigningAuthorityConstant.GET_AUTHORITY_SUCCESS: {
        return {
          ...state, loader:false, authoriyList:payload
        }
      }
      case AdminSigningAuthorityConstant.GET_AUTHORITY_FAILURE: {
        return {
          ...state, loader:false,authoriyList:{}
        }
      }
  
      
      default: 
      return state
  }
}