import React, { useState } from "react";
import Input from "./input";
import Quantities from "./quantities";
import Preview from "./preview";

const LandDevelopment = ({
  acres,
  handleAcresChange,
  landDevelopmentStep3List,
  unitList,
  handleShowComponent,
}) => {
  const [show, setShow] = useState({
    Input: true,
    Quanties: false,
    Preview: false,
  });

  const handleShowComponents = (component) => {
    setShow({
      Input: component === "Input",
      Quanties: component === "Quanties",
      Preview: component === "Preview",
    });
  };

  return (
    <div className="FolderFormCreateArea Proforma">
      <div className="StepBox Green">
        <ul>
          <li
            className={show.Input ? "active" : ""}
            onClick={() => handleShowComponents("Input")}
          >
            <span className="Icon">
              <img src={require("../../../assets/images/block_input.png")} />
            </span>
            <span className="Text">
              Land <br />
              Development <br />
              &amp; Admin <br />
              Costs Input
            </span>
          </li>
          <li
            className={show.Quanties ? "active" : ""}
            onClick={() => handleShowComponents("Quanties")}
          >
            <span
              className="Icon FolderPermissionId"
              data-toggle="modal"
              data-target="#CreateProformaLandDevelopmentAdminCostsQuantities"
              data-dismiss="modal"
            >
              <img
                src={require("../../../assets/images/block_quantities.png")}
              />
            </span>
            <span className="Text">
              Land <br />
              Development <br />
              &amp; Admin <br />
              Costs <br />
              Quantities
            </span>
          </li>
          <li
            className={show.Preview ? "active" : ""}
            onClick={() => handleShowComponents("Preview")}
          >
            <span
              className="Icon FolderPermissionId"
              data-toggle="modal"
              data-target="#CreateProformaLandDevelopmentAdminCostsPreview"
              data-dismiss="modal"
            >
              <img src={require("../../../assets/images/block_summary.png")} />
            </span>
            <span className="Text">
              Land <br />
              Development <br />
              &amp; Admin <br />
              Costs Preview
            </span>
          </li>
        </ul>
      </div>
      <div className="FieldsMandatory">
        <h6>
          Fields with <span className="required">*</span> are mandatory
        </h6>
      </div>
      {show.Input && (
        <Input
          acres={acres}
          handleAcresChange={handleAcresChange}
          handleShowComponent={handleShowComponent}
          handleShowComponents={handleShowComponents}
        />
      )}
      {show.Quanties && (
        <Quantities
          unitList={unitList}
          handleShowComponents={handleShowComponents}
        />
      )}
      {show.Preview && (
        <Preview
          landDevelopmentStep3List={landDevelopmentStep3List}
          handleShowComponent={handleShowComponent}
          handleShowComponents={handleShowComponents}
        />
      )}
    </div>
  );
};

export default LandDevelopment;
