import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import "../App.css";
import Modal from "react-bootstrap/Modal";
import Loader from "./Loader";
import { toast } from "react-toastify";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useLocation, Link, useNavigate } from "react-router-dom";
import {
  cancelClientChangeRequest,
  createChangeOrder,
  deleteChangeOrder,
  deleteChangeOrderDocsFiles,
  generateAgreement,
  getChangeOrderList,
  getClientChangeRequestList,
  updateChangeOrderDocsFiles,
} from "../redux/action/changeOrderAction";
import { getHtmlFile } from "../redux/action/fileManagerAction";
import { getAllProjectList } from "../redux/action/vendorViewConfigAction";

const initialState = {
  ClientChangeRequestModal: false,
  SupportingDocumentsListModal: false,
  SupportingDocumentsList: [],
  UpdateNewSupportingDocsModal: false,
  supportingDocFiless: null,
  id: "",
  SupportDocumentDeleteModal: "",
  docId: "",
  ClientChangeRequestDetailsModal: false,
  expandText: false,
  clientChangeDetails: [],
  includedConsultantt: [],
  FilterModal: false,
  agreement: null,
  UploadSignedAgreementModal: false,
  changeOrderid: "",
  viewDocumentData: [],
  ViewAggrementModal: false,
  ClientAgreementEsignModal: false,
  ClientAgreementData:[],
  NavigateModal:false,
};

const ChangeOrderlist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [iState, updateState] = useState(initialState);
  const {
    ClientChangeRequestModal,
    SupportingDocumentsListModal,
    SupportingDocumentsList,
    UpdateNewSupportingDocsModal,
    supportingDocFiless,
    id,
    SupportDocumentDeleteModal,
    docId,
    ClientChangeRequestDetailsModal,
    expandText,
    clientChangeDetails,
    FilterModal,
    includedConsultantt,
    agreement,
    UploadSignedAgreementModal,
    changeOrderid,
    viewDocumentData,
    ViewAggrementModal,
    ClientAgreementEsignModal,
    ClientAgreementData,
    NavigateModal,
  } = iState;
  const { state ,pathname } = useLocation();
  console.log("state ", state );

  const {
    changeOrderLoader,
    changeOrderList,
    clientChangeRequestLoader,
    clientChangeRequestList,
  } = useSelector((state) => state.changeOrderReducer);

  console.log("clientChangeRequestList", clientChangeRequestList);

  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  
  const {allProjectList } = useSelector((state) => state.vendorViewConfigReducer);
  useEffect(() => {
    dispatch(getChangeOrderList(state?.ProjectId, state?.id, state?.jobType));
    dispatch(getAllProjectList(iBuildLocalData?.user?.CompanyId));
  }, []);

  const handleClientChangeRequestModalShow = () => {
    updateState({
      ...iState,
      ClientChangeRequestModal: true,
    });
    dispatch(getClientChangeRequestList(state?.ProjectId));
  };
  const handleClientChangeRequestModalClose = () => {
    updateState({
      ...iState,
      ClientChangeRequestModal: false,
    });
  };

  const handleSupportingDocumentsListModalShow = (data) => {
    updateState({
      ...iState,
      SupportingDocumentsListModal: true,
      SupportingDocumentsList: data,
    });
    // dispatch(getConstructionSupportDocsList(docsId));
  };
  const handleSupportingDocumentsListModalClose = () => {
    updateState({
      ...iState,
      SupportingDocumentsListModal: false,
    });
  };

  const handleFileChanges = (event, type) => {
    const file = event?.target?.files[0];
    if (type === "docsUpdate") {
      updateState({ ...iState, supportingDocFiless: file?.name });
    } else if (type === "aggre") {
      updateState({ ...iState, agreement: file?.name });
    }
  };

  const handleRemoveDocsFile = () => {
    // Reset the 'templateFile' state to null
    updateState({ ...iState, supportingDocFiless: null, agreement: null });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#docsInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleUpdateNewSupportingDocsModalShow = (item) => {
    updateState({
      ...iState,
      UpdateNewSupportingDocsModal: true,
      id: item.id,
      supportingDocFiless: item.file,
      SupportingDocumentsListModal: false,
    });
  };
  const handleUpdateNewSupportingDocsModalClose = () => {
    updateState({
      ...iState,
      UpdateNewSupportingDocsModal: false,
      supportingDocFiless: "",
      SupportingDocumentsListModal: true,
    });
  };

  const handleEditSupportDocs = () => {
    let dataa = {
      file: supportingDocFiless,
      id,
    };
    dispatch(updateChangeOrderDocsFiles(state?.ProjectId, dataa)).then(
      (res) => {
        if (res.status === 200) {
          dispatch(
            getChangeOrderList(state?.ProjectId, state?.id, state?.jobType)
          );
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            UpdateNewSupportingDocsModal: false,
            id: "",
            SupportingDocumentsListModal: true,
            supportingDocFiless: null,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            UpdateNewSupportingDocsModal: true,
          });
        }
      }
    );
  };

  const handleSupportDocumentDeleteModalHideShow = (type, item) => {
    if (type === "show") {
      updateState({
        ...iState,
        SupportDocumentDeleteModal: true,
        id: item.id,
        // docId:item.supportingDocItems?.id,
        SupportingDocumentsListModal: false,
      });
    } else {
      updateState({
        ...iState,
        SupportDocumentDeleteModal: false,
        id: "",
        docId: "",
        SupportingDocumentsListModal: true,
      });
    }
  };

  const handleDeleteSupportDocs = () => {
    dispatch(deleteChangeOrderDocsFiles(state?.ProjectId, id)).then((res) => {
      if (res.status === 200) {
        // dispatch(getConstructionSupportDocsList(docsId));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          SupportDocumentDeleteModal: false,
          id: "",
          SupportingDocumentsListModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          SupportDocumentDeleteModal: true,
        });
      }
    });
  };

  const handleClientChangeRequestDetailsModalShow = (item) => {
    updateState({
      ...iState,
      ClientChangeRequestDetailsModal: true,
      ClientChangeRequestModal: false,
      clientChangeDetails: item,
    });
  };
  const handleClientChangeRequestDetailsModalClose = () => {
    updateState({
      ...iState,
      ClientChangeRequestDetailsModal: false,
      ClientChangeRequestModal: true,
    });
  };

  const handleToggleExpand = (index) => {
    updateState({ ...iState, expandText: expandText === index ? null : index });
  };

  // console.log("clientChangeDetails",clientChangeDetails);

  const handleCancelChangeRequest = () => {
    const dataa = { id: clientChangeDetails?.id, status: "cancel" };
    dispatch(cancelClientChangeRequest(state?.ProjectId, dataa)).then((res) => {
      if (res.status === 202) {
        toast.success("Cancel Change Order Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          ClientChangeRequestDetailsModal: false,
          ClientChangeRequestModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          ClientChangeRequestDetailsModal: true,
          ClientChangeRequestModal: false,
        });
      }
    });
  };

  const handleCreateNew = () => {
    dispatch(createChangeOrder(state?.ProjectId, { draft: true })).then(
      (res) => {
        navigate("/change-order", {
          state: { ...state, changeOrderId: res?.data?.changeOrder?.id },
        });
      }
    );
  };

  const handleFilterModalShow = () => {
    updateState({ ...iState, FilterModal: true, includedConsultantt: [] });
  };

  const handleFilterModalClose = () => {
    updateState({ ...iState, FilterModal: false, includedConsultantt: [] });
  };

  const consultModule = [
    { value: "description", label: "Description" },
    { value: "orderProgress", label: "Change Order Progress" },
    { value: "createdAt", label: "Date Created" },
    { value: "clientSignStatus", label: "Client Signature Status" },
    { value: "builderSignStatus", label: "Builder Signature Status" },
    { value: "poLineTotal", label: "Initial PO Total" },
    { value: "taxProfile", label: "Client Pre-Tax" },
    { value: "requestAdjustment", label: "Agreement/Adjustments" },
    { value: "supportingDocItems", label: "Supporting Documents" },
  ];

  const handleConsChange = (selectedOption) => {
    updateState({ ...iState, includedConsultantt: selectedOption });
  };

  const handleApply = () => {
    updateState({ ...iState, FilterModal: false });
  };

  const handleDeleteChangeOrder = (changeOrderId) => {
    dispatch(deleteChangeOrder(state?.ProjectId, changeOrderId)).then((res) => {
      if (res.status === 202) {
        dispatch(
          getChangeOrderList(state?.ProjectId, state?.id, state?.jobType)
        );
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleUploadSignedAgreementModalShow = (id) => {
    updateState({
      ...iState,
      UploadSignedAgreementModal: true,
      changeOrderid: id,
    });
  };
  const handleUploadSignedAgreementModalClose = () => {
    updateState({
      ...iState,
      UploadSignedAgreementModal: false,
      changeOrderid: "",
      agreement: null,
    });
  };

  const handleUploadSignAggrement = () => {
    const dataa = { changeOrderId: changeOrderid, agreement };
    dispatch(generateAgreement(state?.ProjectId, dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(
          getChangeOrderList(state?.ProjectId, state?.id, state?.jobType)
        );
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          UploadSignedAgreementModal: false,
          agreement: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, UploadSignedAgreementModal: true });
      }
    });
  };
  const handleViewAggrementModalShow = async (documentUrl) => {
    updateState({ ...iState, ViewAggrementModal: true });

    try {
      const urlWithoutQueryString = documentUrl?.split("?")[0];

      const htmlData = await dispatch(
        getHtmlFile({ url: urlWithoutQueryString })
      );

      if (documentUrl) {
        updateState({
          ...iState,
          viewDocumentData: htmlData?.data,
          ViewAggrementModal: true,
        });
      }
    } catch (error) {
      // Handle the error, e.g., log it or show an error message.
      console.error("Error fetching documentation:", error);
    }
  };
  const handleViewAggrementModalClose = () => {
    updateState({ ...iState, ViewAggrementModal: false });
  };
  const configObject = {
    // CKEditor 5 configuration options
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "blockQuote",
      "undo",
      "redo",
    ],
    ckfinder: {
      uploadUrl: "/upload/images", // Specify the upload URL
    },
    // Add more configuration options as needed
  };


  const handleClientAgreementEsignModalShow = (data) => {
    updateState({
      ...iState,
      ClientAgreementEsignModal: true,
      ClientAgreementData: data,
    });
  };
  const handleClientAgreementEsignModalClose = () => {
    updateState({ ...iState, ClientAgreementEsignModal: false });
  };
  // console.log("ClientAgreementData",ClientAgreementData);
  
  const [showTable, setShowTable] = useState(true);
  const [JobMenuModal, setJobMenuModal] = useState(false);
  const handleJobMenuModalClose = () => setJobMenuModal(false);
  const handleJobMenuModalShow = () => setJobMenuModal(true);

  const handlePrints = () => {
    const printContent = document.querySelector('.AgreementViewArea').innerHTML;
    document.body.innerHTML = printContent;
    window.print();
    window.location.reload();
  };
  
  
  const handleDownload = () => {
    const printContent = document.querySelector('.AgreementViewArea').innerHTML;
    const blob = new Blob([printContent], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'agreement_view.html';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };


  const handleNavigateModalShow=()=>{
    updateState({...iState,NavigateModal:true})
  }
  const handleNavigateModalClose=()=>{
    updateState({...iState,NavigateModal:false})
  }
  return (
    <div>
      <>
        <Header />
        <SideNav />
        <div className="WrapperArea">
          <div className="WrapperBox">
            {/* <div class="d-flex">
    <h6 class="CursorPointer" data-toggle="modal" data-target="#ClientChangeRequestList">Client Change Request List</h6>&nbsp;&nbsp;&nbsp;
    <h6 class="CursorPointer" data-toggle="modal" data-target="#ClientChangeRequestDetails">Client Change Request Details</h6>
  </div> */}
            <div className="MainTitleBox">
              <div className="MainTitle">
                <h2>Change orders list</h2>
              </div>
              <div class="TodosButtons">
                <a onClick={handleCreateNew}>
                  <h6 class="">
                    <img src="images/create_new_change_order.png" />
                    Create New Change Order
                  </h6>
                </a>
              </div>
            </div>
            <div className="TitleBox">
              <div className="TodosButtons">
                <a onClick={handleClientChangeRequestModalShow}>
                  <img src="images/client_change_request.png" />
                  Client Change Requests<span>{clientChangeRequestList?.data?.length??0}</span>
                </a>
              </div>
              <div className="TitleBoxAddress">
                <h6>{state?.clientName}</h6>
                <p>{state?.clientEmail} {state?.clientPhone}</p>
                <p>{state?.jobAddress}</p>
                
              </div>
              <div className="TitleBoxNavigate">
                <h5>{state?.jobNumber}</h5>
                <p>{state?.jobType}</p>
                <div className="NavigateButton">
                  <button onClick={handleJobMenuModalShow}>
                    Job Menu
                  </button>
                  <button className="ml-2" onClick={handleNavigateModalShow}>
                    Navigate
                  </button>
                </div>
              </div>
            </div>
            <div className="Tableheader">
              <h6>Change orders</h6>
              <div className="NewActions">
                <a onClick={handleFilterModalShow} title="filter">
                  <img src="images/icon-64.png" />
                </a>
                <a onClick={handlePrints} title="print">
                    <img src="images/icon-53.png" />
                  </a>
                  <a onClick={handleDownload} title="download">
                    <img src="images/download.png" />
                  </a>
                {/* <a href="javascript:void(0);">
                  <img src="images/setting.png" />
                </a> */}
              </div>
            </div>
            <div className="AgreementViewArea" id="agreement-view-area">
            <div className="AgreementViewTop">
                  {/* <a href="javscript:void(0)">
                    <img src="images/modal-big-logo.png" />
                  </a>
                  <div className="AgreementViewTopRight">
                    <h3>iBuild</h3>
                    <p>312 Weddenburn Rd SE</p>
                    <p>Calgary, AB CA, T2J 1J1</p>
                    <p>
                      4034005933 www.ibuildapplications.com
                      linden@ibuildapplications.com
                    </p>
                    <p>
                      “The bitterness of poor quality remains long after the
                      sweetness of meeting
                      <br />
                      the schedule has been gorgotten”
                    </p>
                  </div> */}
                </div>
                {/* <aside>
                  <p>
                    <label>Table :</label>
                    <span>Warranty Request</span>
                  </p>
                  <p>
                    <label>Project :</label>
                    <span>{state?.ProjectId}</span>
                  </p>
                  <p>
                    <label>Job :</label>
                    <span>{state?.id} </span>
                  </p>
                </aside> */}
            <div className="TableList TableListHeader NewTableList TableHeight500">
              <table>
                <thead>
                  {includedConsultantt?.length > 0 ? (
                    <>
                      <tr>
                        <th>#</th>
                        {includedConsultantt.map((data, index) => (
                          <th key={index}>{data.label}</th>
                        ))}
                        <th>Action</th>
                      </tr>
                    </>
                  ) : (
                    <tr>
                      <th>#</th>
                      <th>Description</th>
                      <th>Change Order Progress</th>
                      <th>Date Created</th>
                      <th>Client Signature Status</th>
                      <th>Builder Signature Status</th>
                      <th>Initial PO Total</th>
                      <th>Client Pre-Tax</th>
                      <th>Agreement/Adjustments</th>
                      <th>Supporting Documents</th>
                      <th>Action</th>
                    </tr>
                  )}
                </thead>

                <tbody>
                  {!changeOrderLoader ? (
                    changeOrderList?.data?.length > 0 ? (
                      changeOrderList?.data?.map((item, ind) => (
                        <tr key={ind}>
                          <td>{item.id}</td>
                          {includedConsultantt.length > 0 &&
                          includedConsultantt.some(
                            (module) => module.value === "description"
                          ) ? (
                            <td>
                              <div className="RedTextTd InformationDisplay">
                                {item.description ?? "N/A"}*
                              </div>
                            </td>
                          ) : (
                            includedConsultantt.length == 0 && (
                              <td>
                                <div className="RedTextTd InformationDisplay">
                                  {item.description ?? "N/A"}*
                                </div>
                              </td>
                            )
                          )}

                          {includedConsultantt.length > 0 &&
                          includedConsultantt.some(
                            (module) => module.value === "orderProgress"
                          ) ? (
                            <td>
                              <div className="RedTextTd">
                                {item.orderProgress ?? "N/A"}
                              </div>
                            </td>
                          ) : (
                            includedConsultantt.length == 0 && (
                              <td>
                                <div className="RedTextTd">
                                  {item.orderProgress ?? "N/A"}
                                </div>
                              </td>
                            )
                          )}

                          {includedConsultantt.length > 0 &&
                          includedConsultantt.some(
                            (module) => module.value === "createdAt"
                          ) ? (
                            <td>
                              <div className="RedTextTd">
                                {moment(item.createdAt).format(
                                  "YYYY-MM-DD & HH:MM A"
                                )}
                              </div>
                            </td>
                          ) : (
                            includedConsultantt.length == 0 && (
                              <td>
                                <div className="RedTextTd">
                                  {moment(item.createdAt).format(
                                    "YYYY-MM-DD & HH:MM A"
                                  )}
                                </div>
                              </td>
                            )
                          )}

                          {includedConsultantt.length > 0 &&
                          includedConsultantt.some(
                            (module) => module.value === "clientSignStatus"
                          ) ? (
                            <td>
                              <div className="RedTextTd">
                                {item.clientSignStatus ?? "N/A"}
                              </div>
                            </td>
                          ) : (
                            includedConsultantt.length == 0 && (
                              <td>
                                <div className="RedTextTd">
                                  {item.clientSignStatus ?? "N/A"}
                                </div>
                              </td>
                            )
                          )}

                          {includedConsultantt.length > 0 &&
                          includedConsultantt.some(
                            (module) => module.value === "builderSignStatus"
                          ) ? (
                            <td>
                              <div className="RedTextTd">
                                {item.builderSignStatus ?? "N/A"}
                              </div>
                            </td>
                          ) : (
                            includedConsultantt.length == 0 && (
                              <td>
                                <div className="RedTextTd">
                                  {item.builderSignStatus ?? "N/A"}
                                </div>
                              </td>
                            )
                          )}

                          {includedConsultantt.length > 0 &&
                          includedConsultantt.some(
                            (module) => module.value === "poLineTotal"
                          ) ? (
                            <td>
                              <div className="RedTextTd">
                                ${Number(item.poLineTotal) ?? 0}
                              </div>
                            </td>
                          ) : (
                            includedConsultantt.length == 0 && (
                              <td>
                                <div className="RedTextTd">
                                  ${Number(item.poLineTotal) ?? 0}
                                </div>
                              </td>
                            )
                          )}

                          {includedConsultantt.length > 0 &&
                          includedConsultantt.some(
                            (module) => module.value === "taxProfile"
                          ) ? (
                            <td>${item.taxProfile}</td>
                          ) : (
                            includedConsultantt.length == 0 && (
                              <td>${item.taxProfile}</td>
                            )
                          )}

                          {includedConsultantt.length > 0 &&
                          includedConsultantt.some(
                            (module) => module.value === "requestAdjustment"
                          ) ? (
                            <td>
                              {item.requestAdjustment === true ? "Yes" : "No"}
                            </td>
                          ) : (
                            includedConsultantt.length == 0 && (
                              <td>
                                {item.requestAdjustment === true ? "Yes" : "No"}
                              </td>
                            )
                          )}
                          {includedConsultantt.length > 0 &&
                          includedConsultantt.some(
                            (module) => module.value === "supportingDocItems"
                          ) ? (
                            <td>
                              <div className="Actions">
                                {/* <a href="javascript:void(0);"><img src="images/icon-63.png"></a> */}
                                <a
                                  onClick={() =>
                                    handleSupportingDocumentsListModalShow(item)
                                  }
                                >
                                  <span className="Count">
                                    {item.supportingDocItems?.length ?? 0}
                                  </span>
                                </a>
                              </div>
                            </td>
                          ) : (
                            includedConsultantt.length == 0 && (
                              <td>
                                <div className="Actions">
                                  {/* <a href="javascript:void(0);"><img src="images/icon-63.png"></a> */}
                                  <a
                                    onClick={() =>
                                      handleSupportingDocumentsListModalShow(
                                        item
                                      )
                                    }
                                  >
                                    <span className="Count">
                                      {item.supportingDocItems?.length ?? 0}
                                    </span>
                                  </a>
                                </div>
                              </td>
                            )
                          )}

                          <td>
                            <div className="TableThreeDots">
                              <ul className="">
                                <li className="dropdown">
                                  <a
                                    className="ToggleWidth"
                                    id="navbarDropdown"
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/dots.png" />
                                  </a>
                                  <ol className="dropdown-menu ChangeOrderDropdown">
                                    <li>
                                      <Link
                                        to="/change-order-discussion"
                                        state={item}
                                      >
                                        <img src="images/Action-1.png" />
                                        View/Edit Change Order
                                      </Link>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() =>
                                          handleDeleteChangeOrder(item.id)
                                        }
                                      >
                                        <img src="images/dropdown-delete.png" />
                                        Delete Change Order
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={()=>handleClientAgreementEsignModalShow(item)}
                                      >
                                        <img src="images/start_e_sign_process.png" />
                                        Start E-sign process
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() =>
                                          handleViewAggrementModalShow(
                                            item.changeOrderAgreementfiles?.[0]
                                              ?.agreement
                                          )
                                        }
                                      >
                                        <img src="images/view_Agreement.png" />
                                        View Agreement
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href={
                                          item.changeOrderAgreementfiles?.[0]
                                            ?.agreement
                                        }
                                        target="_blank"
                                      >
                                        <img src="images/download.png" />
                                        Download Hardcopy Agreement
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() =>
                                          handleUploadSignedAgreementModalShow(
                                            item.id
                                          )
                                        }
                                      >
                                        <img src="images/upload_signed_agreement.png" />
                                        Upload Signed Agreement
                                      </a>
                                    </li>
                                  </ol>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div className="text-center w-100">
                        <h6>No data found</h6>
                      </div>
                    )
                  ) : (
                    <Loader />
                  )}
                </tbody>
              </table>
              </div>
            </div>
            <div className="TodosButtons mt-5">
              <a
                href="javascript:void(0)"
                className="ml-0 py-3"
                data-toggle="modal"
                data-target="#ViewConsolidatedChangeOrder"
              >
                <h6>View Consolidated Changes Order POs</h6>
              </a>
              <a href="javascript:void(0)" className="ml-0 py-3">
                <h6>View Change Order Checklist</h6>
              </a>
            </div>
          </div>
        </div>
        <Modal
          className="ModalBox fade LargeModal"
          show={ClientChangeRequestModal}
          onHide={handleClientChangeRequestModalClose}
        >
          <Modal.Body>
            <div className="Category">
              <a
                className="CloseModal"
                onClick={handleClientChangeRequestModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Client Change Request List</h4>
                </div>
                <div className="NotesArea mb-2 d-flex justify-content-end">
                  <h6>{state?.jobNumber} </h6>
                </div>
                <div className="NotesArea mb-2">
                  <h6>Client Change Requests</h6>
                  <h6>{state?.jobType}</h6>
                </div>
                <div className="TableList CommonHeading">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Quantity</th>
                        <th>Date</th>
                        <th>Sent By</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!clientChangeRequestLoader ? (
                        clientChangeRequestList?.data?.length > 0 ? (
                          clientChangeRequestList?.data?.map((item, ind) => {
                            return (
                              <tr key={ind}>
                                <td>{item.id}</td>
                                <td>{item.title}</td>
                                <td>
                                  <div>
                                    {expandText ? (
                                      <>
                                        {item.description.slice(0, 20)}...
                                        <img
                                          src="images/circle.png"
                                          className="ml-2"
                                          alt="Expand"
                                          onClick={() =>
                                            handleToggleExpand(item.id)
                                          }
                                        />
                                      </>
                                    ) : (
                                      <>
                                        {item.description}
                                        {item.description.length > 20 && (
                                          <img
                                            src="images/circle.png"
                                            className="ml-2"
                                            alt="Collapse"
                                            onClick={() =>
                                              handleToggleExpand(item.id)
                                            }
                                          />
                                        )}
                                      </>
                                    )}
                                  </div>
                                </td>
                                <td>{item.Quantity ?? 0}</td>
                                <td>
                                  {moment(item.createdAt).format("YYYY-MM-DD")}
                                </td>
                                <td>{item.sentBy}</td>
                                <td>{item.status}</td>
                                <td>
                                  <div className="TableThreeDots">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          id="navbarDropdown"
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol
                                          className="dropdown-menu ChangeOrderDropdown"
                                          style={{}}
                                        >
                                          <li>
                                            <a
                                              onClick={() =>
                                                handleClientChangeRequestDetailsModalShow(
                                                  item
                                                )
                                              }
                                              className="FolderPermissionId"
                                            >
                                              <img src="images/view-po.png" />
                                              View Client Change Request
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/generate_change_order.png" />
                                              Generate Change Order
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="10">
                              <p>No Data found.</p>
                            </td>
                          </tr>
                        )
                      ) : (
                        <Loader />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          className="ModalBox fade LargeModal show"
          show={SupportingDocumentsListModal}
          onHide={handleSupportingDocumentsListModalClose}
        >
          <Modal.Body>
            <div className="Category">
              <a
                className="CloseModal"
                onClick={handleSupportingDocumentsListModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Supporting Documents List</h4>
                </div>
                <div className="JobTitleArea">
                  <div className="JobBox">
                    <h6>
                      <span>Job:</span> UUnit3-CHI-2B-315
                    </h6>
                    <div>
                      {/* <a
                      onClick={() =>
                        handleAddNewSupportingDocsModalShow(taskId)
                      }
                    >
                      <img src="images/notebook.png" />
                    </a> */}
                    </div>
                  </div>
                  <div className="JobBox">
                    <h6>
                      <span className="text-red">Origin</span> #185
                    </h6>
                  </div>
                  <div className="JobBox">
                    <h6>
                      <span className="text-red">Item:</span> #143
                    </h6>
                  </div>
                </div>
                <div className="NotesAreaMain">
                  <div className="NotesArea mt-4">
                    <h6>Supporting Documents List</h6>
                    <div>
                      <a href="javascript:void(0);">
                        <img src="images/pdf.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/download.png" />
                      </a>
                      {/* <a href="javascript:void(0);">
                        <img src="images/setting.png" />
                      </a> */}
                    </div>
                  </div>
                  <div className="TableList">
                    <table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Sent by</th>
                          <th>Date</th>
                          <th>Notes</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {SupportingDocumentsList?.supportingDocItems?.length >
                        0 ? (
                          SupportingDocumentsList?.supportingDocItems?.map(
                            (data, ind) => (
                              <tr key={ind}>
                                <td>{ind + 1}</td>
                                <td>{data.sendBy ?? "N/A"}</td>
                                <td>
                                  {moment(data.createdAt).format("YYYY-MM-DD")}
                                </td>
                                <td className="Inspection">
                                  {data.file ?? "N/A"}
                                </td>
                                <td>
                                  <div className="Actions">
                                    <a
                                      onClick={() =>
                                        handleUpdateNewSupportingDocsModalShow(
                                          data
                                        )
                                      }
                                    >
                                      <img
                                        src="images/Action-1.png"
                                        alt="Action 1"
                                      />
                                    </a>
                                    <a
                                      onClick={() =>
                                        handleSupportDocumentDeleteModalHideShow(
                                          "show",
                                          data
                                        )
                                      }
                                    >
                                      <img
                                        src="images/delete.png"
                                        alt="Delete"
                                      />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td colSpan="5">
                              <p>No Data found.</p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <div className="Button mt-5">
                <button
                  className="Create FolderPermissionId"
                  onClick={() => handleAddNewSupportingDocsModalShow(taskId)}
                >
                  Add New Supporting Docs
                </button>
              </div> */}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          className="ModalBox fade SmallModal"
          show={UpdateNewSupportingDocsModal}
          onHide={handleUpdateNewSupportingDocsModalClose}
        >
          <Modal.Body>
            <div className="Category">
              <a
                className="CloseModal"
                onClick={handleUpdateNewSupportingDocsModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4> Update New Supporting Docs</h4>
                </div>
                <div className="JobTitleArea">
                  <h3>Supporting Documents #2</h3>
                  <div className="JobBox">
                    <h6>
                      <span>Job:</span> UUnit3-CHI-2B-315
                    </h6>
                  </div>
                  <div className="JobBox">
                    <h6>
                      <span>Origin</span> #185
                    </h6>
                  </div>
                  <div className="JobBox">
                    <h6>
                      <span>Item:</span> #143
                    </h6>
                  </div>
                </div>
                <div className="form-group">
                  <h6>Select Files</h6>
                  <div className="Upload">
                    <span>-Drag and Drop Files or click to select</span>
                    <input
                      type="file"
                      id="docsInput"
                      onChange={(e) => handleFileChanges(e, "docsUpdate")}
                    />
                  </div>
                </div>
                <div className="FilesAddedTitle">
                  <h4>Files</h4>
                </div>
                {supportingDocFiless && (
                  <div className="FilesAdded">
                    <label>{supportingDocFiless?.name}</label>
                    <span>
                      <img src="images/fileview.png" />
                    </span>
                    <span onClick={handleRemoveDocsFile}>
                      <img src="images/Cross.png" className="cross" />
                    </span>
                  </div>
                )}

                <div className="Button mt-3">
                  <button className="Create" onClick={handleEditSupportDocs}>
                    Update Documents
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={SupportDocumentDeleteModal}
          onHide={() => handleSupportDocumentDeleteModalHideShow("hide", "")}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={() =>
                    handleSupportDocumentDeleteModalHideShow("hide", "")
                  }
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Delete</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="my-5">
                      <h6>Are you sure you want to delete this ?</h6>
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button
                        className="Create"
                        onClick={handleDeleteSupportDocs}
                      >
                        Delete
                      </button>
                      <button
                        className="Outline"
                        onClick={() =>
                          handleSupportDocumentDeleteModalHideShow("hide", "")
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          className="ModalBox fade LargeModal show"
          show={ClientChangeRequestDetailsModal}
          onHide={handleClientChangeRequestDetailsModalClose}
        >
          <Modal.Body>
            <div className="Category">
              <a
                className="CloseModal"
                onClick={handleClientChangeRequestDetailsModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Client Change Request Details</h4>
                </div>
                <div className="ModalMainheading">
                  <h3>Client Change Request #2</h3>
                  <h3>{state?.jobNumber}</h3>
                </div>
                <div className="ModalMainheading d-flex justify-content-end">
                  <h3>{state?.jobType}</h3>
                </div>
                <div className="ClientChangeRequestTable">
                  <table>
                    <tbody>
                      <tr>
                        <td>Title</td>
                      </tr>
                      <tr>
                        <td>{clientChangeDetails?.title}</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>Description</td>
                        <td>Quantity</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>{clientChangeDetails?.description}</td>
                        <td valign="top">
                          {clientChangeDetails?.quantity ?? 0}
                        </td>
                      </tr>
                      <tr>
                        <td>Send By</td>
                        <td>Date</td>
                        <td>Status</td>
                      </tr>
                      <tr>
                        <td>{clientChangeDetails?.sentBy}</td>
                        <td>
                          {moment(clientChangeDetails?.createdAt).format(
                            "YYYY-MM-DD"
                          )}
                        </td>
                        <td>{clientChangeDetails?.status}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="Button mt-5 d-flex justify-content-between align-items-center">
                  <button className="Create">
                    Generate Change <br /> Order
                  </button>
                  {clientChangeDetails?.status != "cancel" && (
                    <>
                      <button
                        className="Create ml-3"
                        onClick={handleCancelChangeRequest}
                      >
                        Cancel Client <br /> Change Request
                      </button>
                      <button
                        className="Outline"
                        onClick={handleClientChangeRequestDetailsModalClose}
                      >
                        Close
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={FilterModal}
          onHide={handleFilterModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleFilterModalClose}
            >
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Filter Table</h4>
              </div>
              <div className="FolderForm">
                <div className="ModalMediumheading">
                  <h3>
                    <span>Table : </span>Table 1
                  </h3>
                </div>
                <div className="form-group">
                  <h6>Column</h6>
                  <div className="CommonSelectBox w-100">
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      value={includedConsultantt}
                      onChange={handleConsChange}
                      options={consultModule}
                    />
                  </div>
                </div>

                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={handleApply}>
                    Apply Filter
                  </button>
                  <button className="Outline" onClick={handleFilterModalClose}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={UploadSignedAgreementModal}
          onHide={handleUploadSignedAgreementModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleUploadSignedAgreementModalClose}
            >
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Upload Signed Agreement</h4>
              </div>
              <div className="FolderForm">
                <div className="form-group">
                  <h6>Select Files</h6>
                  <div className="Upload">
                    <span>-Drag and Drop Files or click to select</span>
                    <input
                      type="file"
                      id="docsInput"
                      onChange={(e) => handleFileChanges(e, "aggre")}
                    />
                  </div>
                </div>
                <div className="form-group mb-2">
                  <h4>File</h4>
                </div>
                <div className="FilesAdded">
                  <label>{agreement}</label>
                  <span>
                    <img src="images/fileview.png" />
                  </span>
                  <span className="cross" onClick={handleRemoveDocsFile}>
                    <img src="images/Cross.png" />
                  </span>
                </div>
                <div className="Button d-flex justify-content-between mt-5">
                  <button
                    className="Create"
                    onClick={handleUploadSignAggrement}
                  >
                    Save
                  </button>
                  <button
                    className="Outline"
                    onClick={handleUploadSignedAgreementModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={ViewAggrementModal}
          onHide={handleViewAggrementModalClose}
          className="ModalBox LargeModal GreyModalContent"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleViewAggrementModalClose}
            >
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Agreement View</h4>
              </div>
              <div className="FolderForm">
                {viewDocumentData && (
                  <CKEditor
                    editor={ClassicEditor}
                    config={configObject}
                    readOnly={true}
                    data={viewDocumentData}
                  />
                )}
                <div className="Button d-flex justify-content-between mt-2">
                  <button
                    className="Create FolderPermissionId"
                    // onClick={() => handleViewHistoryModalShow()}
                  >
                    <img src="images/history_icon.png" />
                    History
                  </button>
                  <button
                    className="Outline"
                    onClick={handleViewAggrementModalClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
        show={ClientAgreementEsignModal}
        onHide={handleClientAgreementEsignModalClose}
        className="ModalBox modal fade LargeModal GreyModalContent"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleClientAgreementEsignModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Client Agreement E-sign</h4>
            </div>
            <div className="ModalMainheading">
              <h3>Change Order #{ClientAgreementData?.id}</h3>
              <h3>{ClientAgreementData?.changeOrderId}</h3>
            </div>
            <div className="ModalMainheading d-flex justify-content-end">
              <h3>{ClientAgreementData?.jobType}</h3>
            </div>
            <div className="FolderForm">
              <div className="ModalClientDetailsArea">
                <div className="McdBoxMain">
                  <div className="McdBox">
                    <h6>
                      Description<span className="required">*</span>
                    </h6>
                    <p>{ClientAgreementData?.description}</p>
                  </div>
                </div>
                <h4>Client Details</h4>
                <div className="row">
                  <div className="col-md-6">
                    <div className="McdBoxMain">
                      <div className="McdBox">
                        <h6>Possession Date</h6>
                        <p>{ClientAgreementData?.possessionDate!=null&&moment(ClientAgreementData?.possessionDate).format("YYYY-MM-DD")}</p>
                      </div>
                      <div className="McdBox">
                        <h6>Request Date</h6>
                        <p>{moment(ClientAgreementData?.createdAt).format("YYYY-MM-DD")}</p>
                      </div>
                    </div>
                    <div className="McdBoxMain">
                      <div className="McdBox">
                        <h6>Client Full Legal Name</h6>
                        <p>{ClientAgreementData?.clientName??"N/A"}</p>
                      </div>
                      <div className="McdBox">
                        <h6>Phone Number</h6>
                        <p>{ClientAgreementData?.clientPhone??"N/A"}</p>
                      </div>
                    </div>
                    <div className="McdBoxMain">
                      <div className="McdBox">
                        <h6>
                          Primary Email
                          <img src="images/location.png" />
                        </h6>
                        <p>{ClientAgreementData?.clientEmail??"N/A"}</p>
                      </div>
                    </div>
                    <div className="McdBoxMain">
                      <div className="McdBox">
                        <h6>Job Address</h6>
                        <p>
                          # {ClientAgreementData?.jobAddress??"N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="Profile_ontario_modal">
                      <div className="Profile_ontario_box">
                        <h6>Profile:</h6>
                        <p>{ClientAgreementData?.taxProfile}</p>
                      </div>
                      <div className="Profile_ontario_box">
                        <h6>HST:</h6>
                        <p>{ClientAgreementData?.hst??0}%</p>
                      </div>
                    </div>
                    <div className="ModalAgreementViewArea">
                      <h4>Agreement View</h4>
                      <div className="MavMainBox">
                        <div className="MavBox">
                          <h6>Sign Document</h6>
                          <button
                            type="button"
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#SignAgreement"
                            data-dismiss="modal"
                          >
                            Sign Document
                          </button>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ChangeItemsTable">
                <h2 className="ChangeItemsTableShow" onClick={() => setShowTable(!showTable)}>
                  <img src="images/icon-34.png" />
                  Change Items
                </h2>
                <table style={{ display: showTable ? 'block' : 'none' }}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Description</th>
                      <th>Quantity</th>
                      <th>Measure</th>
                      <th>Per Unit</th>
                      <th>Line Total</th>
                      <th>Pictures</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      ClientAgreementData?.items?.length>0&&
                      ClientAgreementData?.items?.map((item,i)=>{
                        return(
                          <tr key={i}>
                          <td>{item.id}</td>
                          <td>{item.account}</td>
                          <td>{item.quantity??0}</td>
                          <td>{item.measure}</td>
                          <td>${item.perUnit}</td>
                          <td>${item.poLineTotal}</td>
                          <td>
                            <img src={item.picture??""} />
                          </td>
                        </tr>
                        )
                      })
                    }
                   
                  </tbody>
                </table>
              </div>
              <div className="TotalAfterTax">
                <div className="TotalAfterTaxBox">
                  <h6>Total</h6>
                  <p>$ {ClientAgreementData?.clientPreTax}</p>
                </div>
                <div className="TotalAfterTaxBox">
                  <h6>After Tax Total</h6>
                  <p>$ {ClientAgreementData?.poLineTotal}</p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={JobMenuModal}
        onHide={handleJobMenuModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleJobMenuModalClose}
          >
            ×
          </a>

          <div className="FormArea">
  <div className="ModalTitleBox">
    <h4>Job Menu</h4>
  </div>
  <div className="FolderForm">
      <div className="JobMenuListing">
        <ul>
          <li>
            <a href="javascript:void(0);">
              <figure>
                <img src="images/add_build.png" />
              </figure>
              Add to/Edit Build
              <img src="images/rightarrow2.png" className="ListingOverflow" />
            </a>
          </li>
          <li>
            <a href="javascript:void(0);">
              <figure>
                <img src="images/details.png" />
              </figure>
              Details
            </a>
          </li>
          <li className="JobMenuListing_All">
            {
              pathname!=="change-order-list"&&
              <>
              <Link to="/change-order-list" state={state}>
              <figure>
                <img src="images/change_order.png" />
              </figure>
              Change Order
              <img src="images/rightarrow2.png" className="ListingOverflow" />
            </Link>
             <ul className="JobMenuListingUnder">
             <li>
               <Link to="/change-order-list" state={state}>Change orders List</Link>
             </li>
             <li>
               <Link to="/change-order-list" state={state}>
                 Change to Contract (CTC) master
               </Link>
             </li>
           </ul>
           </>
            }
            
           
          </li>
          <li>
            <Link to="/design-selections" state={state}>
              <figure>
                <img src="images/design.png" />
              </figure>
              Design
            </Link>
          </li>
          <li>
            <Link to="/sales" state={state}>
              <figure>
                <img src="images/sales.png" />
              </figure>
              Sales
            </Link>
          </li>
          <li>
            <Link to="/estimation" state={state}>
              <figure>
                <img src="images/estimation.png" />
              </figure>
              Estimation
              <img src="images/rightarrow2.png" className="ListingOverflow" />
            </Link>
          </li>
          <li>
            <Link to="/construction" state={state}>
              <figure>
                <img src="images/construction_icon.png" />
              </figure>
              Construction
              <img src="images/rightarrow2.png" className="ListingOverflow" />
            </Link>
          </li>
          <li>
            <Link to="/warranty-dashboard" state={state}>
              <figure>
                <img src="images/warranty.png" />
              </figure>
              Warranty
            </Link>
          </li>
          <li>
            <Link to="/reports" state={state}>
              <figure>
                <img src="images/reports.png" />
              </figure>
              Reports
              <img src="images/rightarrow2.png" className="ListingOverflow" />
            </Link>
          </li>
          <li>
            <Link to="/client-view-config" state={state}>
              <figure>
                <img src="images/view_config.png" />
              </figure>
              Views Config
              <img src="images/rightarrow2.png" className="ListingOverflow" />
            </Link>
          </li>
        </ul>
      </div>
  </div>
</div>
        </Modal.Body>
      </Modal>
      <Modal
        show={NavigateModal}
        onHide={handleNavigateModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleNavigateModalClose}
          >
            ×
          </a>
          <div className="FormArea">
  <div className="ModalTitleBox">
    <h4>Navigate Project</h4>
  </div>
  <div className="FolderForm">
      <div className="NaviagateProjectArea">
        <ul>
          {
            allProjectList?.projects?.length > 0 &&
            allProjectList?.projects.map((item,index)=>{
              return(
                <>
                 <li className="DarkBlueList AvalonTownsTab">
            <a href="javascript:void(0);">{item.projectName}</a>
          </li>
          <li className="LightBlueList AdministrationTab">
            <a href="javascript:void(0);">
              {item.jobPattern}
            </a>
            <div className="TableThreeDots">
              <ul className="">
                <li className="dropdown">
                  <a
                    className=""
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src="images/dots.png" />
                  </a>
                  <ol className="dropdown-menu dropdown-menuwidth DropDownMenuZindex">
                    <li>
                      <a>
                        <img src="images/add_build.png" />
                        Add to/Edit Build
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src="images/details.png" />
                        Details
                      </a>
                    </li>
                    <li className="border-0">
                      <Link to="/change-order-list" state={state}
                        className="dropdown-item"
                        
                        style={{ border: "1px solid transparent!important" }}
                      >
                        <img src="images/change_order.png" />
                        Change Order &nbsp;&nbsp;
                        <img src="images/rightarrow2.png" />
                      </Link>
                      <ul className="dropdown-menu submenu">
                        <li>
                          <Link to="/change-order-list" state={state}
                            className="dropdown-item"
                          >
                            Change orders List
                          </Link>
                        </li>
                        <li>
                          <Link to="/change-order-list" state={state}
                            className="dropdown-item"
                          >
                            Change to Contract (CTC) master
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/design-selections" state={state}>
                        <img src="images/design.png" />
                        Design
                      </Link>
                    </li>
                    <li>
                      <Link to="/sales" state={state}>
                        <img src="images/sales.png" />
                        Sales
                      </Link>
                    </li>
                    <li>
                      <Link to="/estimation" state={state}>
                        <img src="images/estimation.png" />
                        Estimation
                      </Link>
                    </li>
                    <li>
                      <Link to="/construction" state={state}>
                        <img src="images/construction_icon.png" />
                        Construction
                      </Link>
                    </li>
                    <li>
                      <Link to="/warranty-dashboard" state={state}>
                        <img src="images/warranty.png" />
                        Warranty
                      </Link>
                    </li>
                    <li>
                      <Link to="/reports" state={state}>
                        <img src="images/reports.png" />
                        Reports
                      </Link>
                    </li>
                    <li>
                      <Link to="/client-view-config" state={state}>
                        <img src="images/view_config.png" />
                        Views Config
                      </Link>
                    </li>
                  </ol>
                </li>
              </ul>
            </div>
          </li>
                </>
              )
            })
          }
         
          
        </ul>
      </div>
  </div>
</div>

   


        </Modal.Body>
      </Modal>
      </>
    </div>
  );
};

export default ChangeOrderlist;
