import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { companyDetailsAction, createCompanyAction } from "../../redux/action/companyAction";
import { quicksBookAction } from "../../redux/action/adminNotificationAction";
import { checkArray } from "../../utils/CheckType";
import { Modal } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import {toast} from 'react-toastify'
import { useSelector } from "react-redux";
import { getCitiesList, getCountriesList, getStateList } from "../../redux/action/authAction";
const inititalState = {
  name: "",
  email: "",
  primaryNumber: "",
  website: "",
  logo: "",
  motto: "",
  displayLogo: false,
  displayMotto: false,
  displayName: false,
  streetAddressLine1: "",
  streetAddressLine2: "",
  zipCode: "",
  country: "",
  province: "",
  city: "",
  taxRegistration: "",
  jobNumberPattern: "",
  fiscalYearEnd: "",
  quickBooks: false,
  letterHeader: "",
  sage:false,
  curStep: "",
  costCodeProfileType: false,
  residentialProfile: "",
  multiFamilyProfile: "",
  landDevelopmentProfile: "",
  imagePreview:"",
  companyErrors: {},
};
const AdminCompanyDetails = ({ adminCompanyDetailsStatus }) => {
  const[phonecode, setPhoneCode] = useState("")
  const { companyDetailData } = useSelector((state) => state.companyReducer);




  const [iState, updateState] = useState(inititalState);



  const {
    name,
    email,
    primaryNumber,
    website,
    logo,
    motto,
    displayLogo,
    displayMotto,
    displayName,
    streetAddressLine1,
    streetAddressLine2,
    zipCode,
    country,
    province,
    city,
    taxRegistration,
    jobNumberPattern,
    fiscalYearEnd,
    quickBooks,
    letterHeader,
    curStep,
    costCodeProfileType,
    residentialProfile,
    multiFamilyProfile,
    landDevelopmentProfile,
    companyErrors,
    imagePreview,
    sage,
  } = iState;
  const { countryData, cityData, stateData } = useSelector(
    (state) => state.authReducer
  );



  useEffect(() => {
    updateState({...iState, 
      name:companyDetailData?.name,
      email:companyDetailData?.email, 
      primaryNumber:companyDetailData?.primaryNumber,
      website:companyDetailData?.website,
      displayLogo:companyDetailData?.displayLogo,
      displayMotto:companyDetailData?.displayMotto, 
      displayName:companyDetailData?.displayName,
      streetAddressLine1:companyDetailData?.streetAddressLine1,
      motto:companyDetailData?.motto,
      streetAddressLine2:companyDetailData?.streetAddressLine2,
      city:companyDetailData?.city, 
      zipCode:companyDetailData?.zip,
      country:companyDetailData?.country, 
      province:companyDetailData?.province, 
      logo:companyDetailData?.logo,
      taxRegistration:companyDetailData?.taxRegistration,
      jobNumberPattern:companyDetailData?.jobNumberPattern,
      fiscalYearEnd:companyDetailData?.fiscalYearEnd,
      quickBooks:companyDetailData?.quickBooks,
      letterHeader:companyDetailData?.letterHeader,
      curStep,
      costCodeProfileType:companyDetailData?.costCodeProfileType === null ? false : companyDetailData?.costCodeProfileType,
      residentialProfile:companyDetailData?.residentialProfile,
      multiFamilyProfile:companyDetailData?.multiFamilyProfile,
      landDevelopmentProfile:companyDetailData?.landDevelopmentProfile,
      sage:companyDetailData?.sage,


    })
  },[companyDetailData])
  useEffect(() => {
    if(country)
    dispatch(
      getStateList(
        countryData?.countries?.find((x) => x.name == country)?.isoCode
      )
    );
  }, [country]);
  useEffect(() => {
    dispatch(
      getCitiesList(
        countryData?.countries?.find((x) => x.name == country)?.isoCode,
        stateData?.states?.find((x) => x.name == province)?.isoCode
      )
    );
  }, [province]);



  const [editGeneralInfo, setEditGeneralInfo] = useState(false);
  const [quickbook, setQuickBook] = useState("")


  const dispatch = useDispatch();


  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

  useEffect(() => {

    dispatch(quicksBookAction())
    .then((response) => {
      setQuickBook(response)

      ;})


        dispatch(getCountriesList());

  }, [adminCompanyDetailsStatus]);




  const handleUpload = (e) => {
      const { name, value } = e.target;
      const file = e.target.files[0];
      if (file) {
        if (
          file.type === "image/jpeg" ||
          file.type === "image/jpg" ||
          file.type === "image/png"
        ) {
          

          updateState({
            ...iState,
            logo: e.target.files[0],
            imagePreview:  URL.createObjectURL(file),
          });
        } 
      
    };  };

  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    updateState({ ...iState, [name]: result });
  };

  const handleRadio = (e) => {
    const {name, value } = e.target;
    updateState({
      ...iState, 
      name: value === name,
    });
  }

  const handleCheckbox = (e) => {
    const {name, value,checked } = e.target;
    updateState({
      ...iState, 
      [name] : checked
    });
  }

  const handleInput = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });


  }
  
  const addCompanyFunc = (e) => {
    e.preventDefault();
    dispatch(
      createCompanyAction(iBuildLocalData?.user?.CompanyId, {
        costCodeProfileType: "System Default Cost Codes",
        name,
        email,
        primaryNumber,
        website,
        logo,
        motto,
        displayLogo,
        displayMotto,
        displayName,
        streetAddressLine1,
        streetAddressLine2,
        zipCode,
        country,
        province,
        city,
        taxRegistration,
        jobNumberPattern,
        fiscalYearEnd,
        quickBooks,
        sage ,
        letterHeader,
        curStep:6,
        residentialProfile,
        multiFamilyProfile,
        landDevelopmentProfile,
        phonecode
      })
    ).then((res) => {
   
     
    });
  };

  const handleKeySelection=(e,value)=>{
    e.preventDefault()
    if(!jobNumberPattern){

    updateState({...iState, jobNumberPattern:value})
    }
    else{

      let prev = jobNumberPattern + `-${value}`
      updateState({...iState, jobNumberPattern:prev})

    }} 


    const editorsRef = useRef(null);
    const [content,setContent]=useState("")
    const [HeaderPreviewModal, setHeaderPreviewModal] = useState(false);
    const handlHeaderPreviewModalShow = () => {
      setHeaderPreviewModal(true);
    };
    const handlHeaderPreviewModalClose = () => {
      setHeaderPreviewModal(false);
    };
  
    const handleHeaderApply = () => {
    
       setContent(letterHeader);
      setHeaderPreviewModal(false);
    };

    const handleEditorChange=(newContent)=>{
      setContent(newContent);
    }
  return (
    <>
    <div class="tab-pane fade" id="CompanyDetails">
      <div class="ResidentialJobArea ViewConfigArea">
        <div class="ModalAccordian">
          <div id="accordion">
            <div class="card">
              <div class="card-header">
                <h4 data-toggle="collapse" href="#GeneralInformationTabs">
                  <span>
                    <img src="images/generation_information.png" />
                  </span>{" "}
                  General Information
                </h4>
                <a onClick={()=> setEditGeneralInfo(editGeneralInfo?false:true)} class="Edit EditViewConfigBodyBtn">
                  <img src="images/Action-1.png" />
                </a>
              </div>
              <div
                id="GeneralInformationTabs"
                class="collapse show"
                data-parent="#accordion"
              >
                <div class="card-body">
                  <div class="ViewConfigBodyMain" style={{display : editGeneralInfo ?"none":"block"}}>
                    <div class="ViewConfigBody">
                      <aside>
                        <div class="form-group">
                          <h6>Name</h6>
                          <p>{companyDetailData?.name}</p>
                        </div>
                        <div class="form-group">
                          <h6>Email</h6>
                          <p>{companyDetailData?.email}</p>
                        </div>
                        <div class="form-group">
                          <h6>Primary Phone Number</h6>
                          <p>{companyDetailData?.primaryNumber}</p>
                        </div>
                        <div class="form-group">
                          <h6>Website</h6>
                          <p>{companyDetailData?.website}</p>
                        </div>
                        <div class="form-group">
                          <h6>Login URL Shorthand</h6>
                          <p>{companyDetailData?.shorthand}</p>
                        </div>
                      </aside>
                      <aside>
                        <div class="form-group">
                          <h6>Logo</h6>
                          <span class="FmBuilderLogo">
                            <img src= {companyDetailData?.logo ? companyDetailData?.logo: "images/fm_builder_logo.png"} />
                          </span>
                        </div>
                        <div class="form-group">
                          <h6>Motto</h6>
                          <p>{companyDetailData?.motto}</p>
                        </div>
                        <div class="form-group">
                          <h6>Letter Header</h6>
                          <button class="PreviewBtn" onClick={handlHeaderPreviewModalShow}>Preview</button>
                        </div>
                        <div class="form-group">
                          <h6>Display on Login Page</h6>
                        {companyDetailData?.displayName &&  <p class="mb-2">Name</p>}
                        {companyDetailData?.displayLogo &&<p class="mb-2">Logo</p>}
                        {companyDetailData?.displayMotto &&<p class="mb-2">Motto</p>}
                        </div>
                      </aside>
                    </div>
                  </div>
                  <div class="ViewConfigBodyHideMain" style={{display : editGeneralInfo ?"block":"none"}}>
                    <div class="ViewConfigBodyHide">
                      <aside>
                        <div class="form-group">
                          <h6>Name</h6>
                          <input
                            type="text"

                            class="form-control"
                            name="name"
                            value={name}
                            onChange={handleInput}
                            placeholder="Company 1"
                          />
                        </div>
                        <div class="form-group">
                          <h6>Email</h6>
                          <input
                            type="text"
                            class="form-control"

                            name="email"
                            onChange={handleInput}

                            value={email}
    
                            placeholder="contact@company1.com"
                          />
                        </div>
                        <div class="form-group">
                          <h6>Primary Phone Number</h6>
                          <input
                        type="text"
                        className="form-control"
                        placeholder="Insert Company's primary phone number"
                        name="primaryNumber"
                        value={primaryNumber}
                        onChange={handleInputNumber}
                      />
                        </div>
                        <div class="form-group">
                          <h6>Website</h6>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="www.company1.com"

                            name="website"
                            defaultValue={website}
                            onChange={handleInput}
                          />
                        </div>
                        <div class="form-group">
                          <h6>Login URL Shorthand</h6>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="company1"
                          />
                        </div>
                      </aside>
                      <aside>
                        <div class="form-group">
                          <h6>Logo</h6>
                          <span class="FmBuilderLogo">

                            
                          {logo ? 
                        <>
                         <img src={ imagePreview?imagePreview :logo } />
                         <input
                        type="file"
                        name="logo"
                        onChange={handleUpload}
                      />
                        </>
                       
                        :
                        <>
                         <input
                        type="file"
                        name="logo"
                        onChange={handleUpload}
                      />
                      </ >}
                          </span>
                          <div>
                            <button class="AlterBtn mt-3">Alter</button>
                          </div>
                        </div>
                        <div class="form-group">
                          <h6>Motto</h6>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Building futures"

                            name="motto"
                            defaultValue={motto}
                            onChange={handleInput}
                          />
                        </div>
                        <div class="form-group">
                          <h6>Letter Header</h6>
                          <button class="PreviewBtn mr-2" onClick={handlHeaderPreviewModalShow}>Preview</button>
                          <button class="AlterBtn ml-2">Alter</button>
                        </div>
                        <div class="DisplayLoginOnPage">
                          <div class="form-group">
                            <h6>Display on Login Page</h6>
                            <div class="mb-2">
                              <label class="CheckBox">
                                {" "}
                                Name
                                <input type="checkbox" />
                                <span class="checkmark"></span>
                              </label>
                            </div>
                            <div class="mb-2">
                              <label class="CheckBox">
                                {" "}
                                Logo
                                <input type="checkbox" />
                                <span class="checkmark"></span>
                              </label>
                            </div>
                            <div>
                              <label class="CheckBox">
                                {" "}
                                Motto
                                <input type="checkbox" />
                                <span class="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </aside>
                    </div>
                    <div class="Button d-flex justify-content-center">
                      <button onClick={()=> setEditGeneralInfo(false)}  class="OutlineBlack mr-5 SaveViewConfigBodyBtn">
                        Cancel
                      </button>
                      <button class="Respond ml-5 SaveViewConfigBodyBtn" onClick={addCompanyFunc}>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <h4 data-toggle="collapse" href="#BusinessAddressTabs">
                  <span>
                    <img src="images/business_address.png" />
                  </span>{" "}
                  Business Address
                </h4>
                <a
                  class="Edit EditViewConfigBodyBtn"
                  href="javascript:void(0);"
                >
                  <img src="images/business_address.png" />
                </a>
              </div>
              <div
                id="BusinessAddressTabs"
                class="collapse show"
                data-parent="#accordion"
              >
                <div class="card-body">
                  <div class="ViewConfigBodyMain"  style={{display : editGeneralInfo ?"none":"block"}}>
                    <div class="ViewConfigBody">
                      <aside>
                        <div class="form-group">
                          <h6>Street Address</h6>
                          <p>{companyDetailData?.streetAddressLine1}</p>
                        </div>
                        <div class="form-group">
                          <h6>Street Address Line Two</h6>
                          <p>{companyDetailData?.streetAddressLine2}</p>
                        </div>
                        <div class="form-group">
                          <h6>City</h6>
                          <p>{companyDetailData?.province}</p>
                        </div>
                      </aside>
                      <aside>
                        <div class="form-group">
                          <h6>Zip/ Postal Code</h6>
                          <p>{companyDetailData?.zipCode}</p>
                        </div>
                        <div class="form-group">
                          <h6>Country</h6>
                          <p>{companyDetailData?.country}</p>
                        </div>
                        <div class="form-group">
                          <h6>State/Province</h6>
                          <p>{companyDetailData?.province}</p>
                        </div>
                      </aside>
                    </div>
                  </div>

                  <div class="ViewConfigBodyHideMain" style={{display : editGeneralInfo ?"block":"none"}}>
                    <div class="ViewConfigBodyHide">
                      <aside>
                        <div class="form-group">
                          <h6>Street Address</h6>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Street Address"
                            name="streetAddressLine1"

                            value={streetAddressLine1}
                            onChange={handleInput}
                          />
                        </div>
                        <div class="form-group">
                          <h6>Street Address Line Two</h6>
                          <input
                            type="text"
                            class="form-control"
                            name="streetAddressLine2"

                            value={streetAddressLine2}
                            onChange={handleInput}
    
                            placeholder="Insert full Address"
                          />
                        </div>
                        <div class="form-group">
                          <h6>City</h6>
                          <input
                            type="text"
                            name="zipCode"


                            value={zipCode}
                            onChange={handleInput}
                            class="form-control"
                            placeholder="Calgary"
                          />
                        </div>
                      </aside>
                      <aside>
                        <div class="form-group">
                          <h6>Zip/ Postal Code</h6>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="T3A T3A"
                          />
                        </div>
                        <div class="form-group">
                          <h6>Country</h6>
                          <select
                                name="country"
                                value={country}
                                onChange={handleInput}
                              >
                                <option>Select Country</option>
                                {checkArray(countryData?.countries) ? (
                                  <>
                                    {countryData?.countries?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                        </div>
                        <div class="form-group">
                          <h6>State/Province</h6>
                          <select
                                name="province"
                                value={province}
                                onChange={handleInput}
                              >
                                <option>Select Province</option>
                                {checkArray(stateData?.states) ? (
                                  <>
                                    {stateData?.states?.map((ele, i) => {
                                      return (
                                        <option value={ele?.name}>
                                          {ele?.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </select>
                        </div>
                      </aside>
                    </div>
                    <div class="Button d-flex justify-content-center">
                      <button onClick={()=> setEditGeneralInfo(false)}  class="OutlineBlack mr-5 SaveViewConfigBodyBtn">
                        Cancel
                      </button>
                      <button class="Respond ml-5 SaveViewConfigBodyBtn" onClick={addCompanyFunc}>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <h4 data-toggle="collapse" href="#AccountingTabs">
                  <span>
                    <img src="images/accounting.png" />
                  </span>{" "}
                  Accounting
                </h4>
                <a class="Edit EditViewConfigBodyBtn">
                  <img src="images/tax.png" />
                </a>
              </div>
              <div
                id="AccountingTabs"
                class="collapse show"
                data-parent="#accordion"
              >
                <div class="card-body">
                  <div class="ViewConfigBodyMain" style={{display : editGeneralInfo ?"none":"block"}}>
                    <div class="ViewConfigBody">
                      <aside style={{ width: "100%" }}>
                        <div class="AccounitingCollapseArea AccounitingCollapseArea2">
                          <h6 class="mb-3">
                            Recommended Accounting Configuration Procedures
                            Before Integration
                          </h6>
                          <p>
                            - You must first purchase the{" "}
                            <span>QuickBooks Online Plus</span> version as the{" "}
                            <span>Plus</span> version allows class usage
                          </p>
                          <p>
                            - Second, ensure the{" "}
                            <span>Chart of Accounts (COA)</span> has an{" "}
                            <span>Accounts Payables (A/P)</span> account already
                            set-up, if not, set one up.
                          </p>
                          <p>
                            - In your QuickBooks Online Plus version you must
                            first Turn-On the <span>Purchase Order</span>{" "}
                            Option, the <span>Enable account numbers</span>
                            option, then the the <span>Class</span> Option as
                            follows:
                          </p>
                          <ul>
                            <li>
                              --Click on the{" "}
                              <span>
                                Gear Icon in the Upper Right-Hand Corner of on
                                your Home Dashboard screen.
                              </span>
                            </li>
                            <li>
                              --Click on <span>Account and Settings</span>
                            </li>
                            <li>
                              --Click on the <span>Account and Settings</span>{" "}
                              option.{" "}
                            </li>

                            <li>
                              --Click on the <span>Expenses</span> option.{" "}
                            </li>
                            <li>
                              --Click on the box in front of the{" "}
                              <span>Use Purchase Orders</span> option.
                            </li>
                            <li>
                              --Click the green <span>Save</span> button.
                            </li>
                            <li>
                              --Click on the <span>Advanced</span> option.
                            </li>
                            <li>
                              --Click on the <span>Chart of Accounts</span>{" "}
                              option.
                            </li>
                            <li>
                              --Click on the box in front of{" "}
                              <span>Enable Account Numbers</span>{" "}
                            </li>
                            <li>
                              --Click the green <span>Save</span> button.
                            </li>
                            <li>
                              --Click the box in front of the{" "}
                              <span>Track Classes</span> option.
                            </li>
                            <li>
                              --Click the green <span>Save</span> button
                            </li>
                          </ul>
                          <p>
                            -Return to this page and click on the Green{" "}
                            <span>Connect to QuickBooks </span>button.
                          </p>
                        </div>
                        <div class="row mt-5">
                          <div class="col-lg-4">
                            <div class="form-group">
                              <h6>Software</h6>
                              <a > <img  src="images/quickbooks.png" /></a>
                             
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <h6>Software</h6>
                            <h6 class="fw-500">Not Syncd</h6>
                          </div>
                          <div class="col-lg-4">
                            <div class="form-group">
                              <h6>Fiscal Year End</h6>
                              <p>March</p>
                            </div>
                          </div>
                        </div>
                      </aside>
                    </div>
                  </div>
                  <div class="ViewConfigBodyHideMain" style={{display : editGeneralInfo ?"block":"none"}}>
                    <div class="ViewConfigBodyHide">
                      <aside style={{ width: "100%" }}>
                        <div class="AccounitingCollapseArea">
                          <h6>
                            Recommended Accounting Configuration Procedures
                            Before Integration
                          </h6>
                          <p>
                            - You must first purchase the{" "}
                            <span>QuickBooks Online Plus</span> version as the{" "}
                            <span>Plus</span> version allows class usage
                          </p>
                          <p>
                            - Second, ensure the{" "}
                            <span>Chart of Accounts (COA)</span> has an{" "}
                            <span>Accounts Payables (A/P)</span> account already
                            set-up, if not, set one up.
                          </p>
                          <p>
                            - In your QuickBooks Online Plus version you must
                            first Turn-On the <span>Purchase Order</span>{" "}
                            Option, the <span>Enable account numbers</span>
                            option, then the the <span>Class</span> Option as
                            follows:
                          </p>
                          <ul>
                            <li>
                              --Click on the{" "}
                              <span>
                                Gear Icon in the Upper Right-Hand Corner of on
                                your Home Dashboard screen.
                              </span>
                            </li>
                            <li>
                              --Click on <span>Account and Settings</span>
                            </li>
                            <li>
                              --Click on the <span>Account and Settings</span>{" "}
                              option.{" "}
                            </li>

                            <li>
                              --Click on the <span>Expenses</span> option.{" "}
                            </li>
                            <li>
                              --Click on the box in front of the{" "}
                              <span>Use Purchase Orders</span> option.
                            </li>
                            <li>
                              --Click the green <span>Save</span> button.
                            </li>
                            <li>
                              --Click on the <span>Advanced</span> option.
                            </li>
                            <li>
                              --Click on the <span>Chart of Accounts</span>{" "}
                              option.
                            </li>
                            <li>
                              --Click on the box in front of{" "}
                              <span>Enable Account Numbers</span>{" "}
                            </li>
                            <li>
                              --Click the green <span>Save</span> button.
                            </li>
                            <li>
                              --Click the box in front of the{" "}
                              <span>Track Classes</span> option.
                            </li>
                            <li>
                              --Click the green <span>Save</span> button
                            </li>
                          </ul>
                          <p>
                            -Return to this page and click on the Green{" "}
                            <span>Connect to QuickBooks </span>button.
                          </p>
                        </div>
                        <div class="row mt-5">
                          <div class="col-lg-4">
                            <div class="form-group">
                              <h6>Software</h6>
                              <img src="images/quickbooks.png" />
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="d-flex">
                              <div>
                                <h6>Software</h6>
                                <h6 class="fw-500">Not Syncd</h6>
                              </div>
                              <div class="Button ml-2">
                                <button class="Respond p-1">
                                  Connect to <br /> Quickbooks
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="form-group">
                              <h6>Fiscal Year End</h6>
                              <p>March</p>
                            </div>
                          </div>
                          <div>
                            <button class="AlterBtn">Alter</button>
                          </div>
                        </div>
                      </aside>
                    </div>
                    <div class="Button d-flex justify-content-center">
                      <button onClick={()=> setEditGeneralInfo(false)}  class="OutlineBlack mr-5 SaveViewConfigBodyBtn">
                        Cancel
                      </button>
                      <button class="Respond ml-5 SaveViewConfigBodyBtn" onClick={addCompanyFunc}>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <h4 data-toggle="collapse" href="#TaxTabs">
                  <span>
                    <img src="images/qoqr_1.png" />
                  </span>{" "}
                  Tax
                </h4>
                <a
                  class="Edit EditViewConfigBodyBtn"
                  href="javascript:void(0);"
                >
                  <img src="images/Action-1.png" />
                </a>
              </div>
              <div id="TaxTabs" class="collapse show" data-parent="#accordion">
                <div class="card-body">
                  <div class="ViewConfigBodyMain">
                    <div class="ViewConfigBody">
                      <aside class="w-100">
                        <div class="form-group">
                          <h6>Tax Registration</h6>
                          <p>713158681RT0001</p>
                        </div>
                        <div class="AlbertaTaxMain">
                          <div class="AlbertaTax AlbertaTaxBtn">
                            <h5>Alberta Taxs</h5>
                            <img src="images/down_arrow_fill.png" />
                          </div>
                          <div class="AlbertaTaxHide">
                            <div class="row">
                              <div class="col-lg-4">
                                <div class="form-group">
                                  <h6>Tax Location</h6>
                                  <div class="d-flex justify-content-between">
                                    <p>Canada</p>
                                    <p>Alberta (AB)</p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-6 ml-auto">
                                <div class="form-group">
                                  <h6>Tax Condition</h6>
                                  <div class="d-flex">
                                    <div>
                                      <label class="CheckBox">
                                        {" "}
                                        Default
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                      </label>
                                    </div>
                                    <div class="ml-4">
                                      <label class="CheckBox">
                                        {" "}
                                        Active
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="AlbertaTaxMain">
                          <div class="TaxRow">
                            <h5>Tax Rows</h5>
                          </div>
                          <div class="TaxRowHide">
                            <table>
                              <thead>
                                <tr>
                                  <th>Tax Name</th>
                                  <th>Tax Percent</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <p>GST</p>
                                  </td>
                                  <td>
                                    <p>5%</p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="AlbertaTaxMain">
                          <div class="Rebate">
                            <h5>Rebates</h5>
                          </div>
                          <div class="TaxRowHide">
                            <table>
                              <thead>
                                <tr>
                                  <th>Applicable Row</th>
                                  <th>Sale Price Range</th>
                                  <th>Applied as</th>
                                  <th>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <p>GST(AB 5%)</p>
                                  </td>
                                  <td>
                                    <p>$0.00</p>
                                  </td>
                                  <td>
                                    <p>Sliding Scale</p>
                                  </td>
                                  <td>
                                    <p>$6,300.00</p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </aside>
                    </div>
                  </div>

                  <div class="ViewConfigBodyHideMain">
                    <div class="ViewConfigBodyHide">
                      <aside class="w-100">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="form-group">
                              <h6>Tax Registration</h6>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="713158681RT0001"
                              />
                            </div>
                            <div class="form-group">
                              <h6>Tax Profiles</h6>
                              <div class="SaveDeleteProfile justify-content-start">
                                <button
                                  class="mx-0"
                                  data-toggle="modal"
                                  data-target="#CreateNewTaxProfileModal"
                                >
                                  Add New Tax Profile
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </aside>

                      <div class="row">
                        <div class="col-12">
                          <div class="AlbertaTaxMain">
                            <div class="AlbertaTax AlbertaTaxBtn">
                              <h5>Alberta /GST Tax</h5>
                              <img src="images/down_arrow_fill.png" />
                            </div>
                            <div class="AlbertaTaxHide">
                              <div class="row">
                                <div class="col-lg-7">
                                  <div class="row">
                                    <div class="col-lg-6">
                                      <div class="form-group">
                                        <h6>Tax Location</h6>
                                        <div class="CommonSelectBox">
                                          <select>
                                            <option selected="">
                                              Select Country
                                            </option>
                                            <option>India</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-lg-5">
                                      <div class="form-group">
                                        <h6>&nbsp;</h6>
                                        <div class="CommonSelectBox">
                                          <select>
                                            <option selected="">
                                              Select Province/State
                                            </option>
                                            <option>Delhi</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-4">
                                  <div class="form-group">
                                    <h6>Tax Condition</h6>
                                    <div class="d-flex">
                                      <div>
                                        <label class="CheckBox">
                                          {" "}
                                          Default
                                          <input type="checkbox" />
                                          <span class="checkmark"></span>
                                        </label>
                                      </div>
                                      <div class="ml-4">
                                        <label class="CheckBox">
                                          {" "}
                                          Active
                                          <input type="checkbox" />
                                          <span class="checkmark"></span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="AlbertaTaxMain">
                            <div class="TaxRow">
                              <h5>Tax Rows</h5>
                            </div>
                            <div class="TaxRowHide">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Tax Name</th>
                                    <th>Tax Percent</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div class="form-group">
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="Insert Tax Name"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div class="form-group">
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="Insert Tax Percent"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div class="TableThreeDots text-center">
                                        <ul class="">
                                          <li class="dropdown">
                                            <a
                                              class=""
                                              role="button"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <img src="images/dots.png" />
                                            </a>
                                            <ol class="dropdown-menu dropdown-menuwidth">
                                              <li>
                                                <a href="javascript:void(0);">
                                                  <img src="images/remove_tax_line.png" />
                                                  Remove line
                                                </a>
                                              </li>
                                              <li>
                                                <a href="javascript:void(0);">
                                                  <img src="images/add_new_tax_line.png" />
                                                  Add New Line
                                                </a>
                                              </li>
                                            </ol>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="AlbertaTaxMain">
                            <div class="Rebate">
                              <h5>Rebates</h5>
                            </div>
                            <div
                              class="TaxRowHide"
                              style={{ overflowX: "auto" }}
                            >
                              <table>
                                <thead>
                                  <tr>
                                    <th>Applicable Row</th>
                                    <th>Sale Price Range</th>
                                    <th>Applied as</th>
                                    <th>Amount</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div
                                        class="CommonSelectBox"
                                        style={{ width: "200px!important" }}
                                      >
                                        <select>
                                          <option selected="">GST (5%)</option>
                                        </select>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="d-flex">
                                        <div class="form-group mr-1">
                                          <input
                                            type="text"
                                            class="form-control width-50"
                                            placeholder="$0.00"
                                          />
                                        </div>
                                        <div class="form-group ml-1">
                                          <input
                                            type="text"
                                            class="form-control width-50"
                                            placeholder="$0.00"
                                          />
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="form-group">
                                        <div
                                          class="CommonSelectBox"
                                          style={{ width: "200px!important" }}
                                        >
                                          <select>
                                            <option selected="">
                                              GST (5%)
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="form-group">
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="$6,300.00"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div class="TableThreeDots text-center">
                                        <ul class="">
                                          <li class="dropdown">
                                            <a
                                              class=""
                                              role="button"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <img src="images/dots.png" />
                                            </a>
                                            <ol class="dropdown-menu dropdown-menuwidth">
                                              <li>
                                                <a href="javascript:void(0);">
                                                  <img src="images/remove_tax_line.png" />
                                                  Remove line
                                                </a>
                                              </li>
                                              <li>
                                                <a href="javascript:void(0);">
                                                  <img src="images/add_new_tax_line.png" />
                                                  Add New Line
                                                </a>
                                              </li>
                                            </ol>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="SaveDeleteProfile mt-4">
                              <button>Save Profile</button>
                              <button>Delete Profile</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="Button d-flex justify-content-center">
                      <button onClick={()=> setEditGeneralInfo(false)}  class="OutlineBlack mr-5 SaveViewConfigBodyBtn">
                        Cancel
                      </button>
                      <button class="Respond ml-5 SaveViewConfigBodyBtn" onClick={addCompanyFunc}>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <h4 data-toggle="collapse" href="#JobNumberingTabs">
                  <span>
                    <img src="images/job_numbering.png" />
                  </span>{" "}
                  Job Numbering
                </h4>
                <a class="Edit EditViewConfigBodyBtn">
                  <img src="images/Action-1.png" />
                </a>
              </div>
              <div
                id="JobNumberingTabs"
                class="collapse show"
                data-parent="#accordion"
              >
                <div class="card-body">
               
                  <div class="ViewConfigBodyHideMain">
                    <div class="ViewConfigBodyHide">
                      <aside class="w-100">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="form-group">
                              <h6>Job Number Pattern</h6>
                              <input
                                type="text"

                                onChange={(e)=> {
                                  updateState({...iState, jobNumberPattern:e.target.value})
                                }}

                                value={jobNumberPattern}
                                class="form-control InputCyanBg"
                                placeholder="[UNUM]-[ACD]"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <h6>Sample Job Number</h6>
                              <input
                                type="text"
                                class="form-control InputCyanBg"
                                placeholder="MAH-2B-377"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="RestrictionArea">
                          <h6>Restrictions</h6>
                          <ul>
                            <li>
                              -Max length for generated job number is 40
                              characters.
                            </li>
                            <li>
                              -[CID] and [JN] must both occur at least once.
                            </li>
                            <li>
                              -Only alpha numeric characters and single dashes
                              are allowed.
                            </li>
                            <li>-It cannot end/start in a dash.</li>
                          </ul>
                        </div>
                        <div class="KeySelectionArea">
                      <h6>Key Selection</h6>
                      <div class="KeySelectionBox">
                        <aside>
                          <p><button onClick={(e)=> handleKeySelection(e,'[ACD]')} class="BlueBtn">[ACD]</button><span>Agreement or Creation Date</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[ACDM2]')} class="BlueBtn">[ACDM2]</button><span>Agreement or Creation <br />Date: Two Digit Month</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[SD3]')} class="BlueBtn">[SD3]</button><span>Three Characteres of Subdivision</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[FY2]')} class="BlueBtn">[FY2]</button><span>Two Digit Fiscal Year</span></p>
                        </aside>
                        <aside>
                          <p><button onClick={(e)=> handleKeySelection(e,'[ACDY2]')} class="BlueBtn">[ACDY2]</button><span>Agreement or Creation <br />
                            Date: Two Digit Year</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[CID]')} class="GreenBtn">[CID]</button><span>Company ID</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[SD4]')} class="BlueBtn">[SD4]</button><span>Four Characteres of 
                            Subdivision</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[FY4]')} class="BlueBtn">[FY4]</button><span>Four Digit Fiscal Year</span></p>
                        </aside>
                        <aside>
                          <p><button onClick={(e)=> handleKeySelection(e,'[ACDY4]')} class="BlueBtn">[ACDY4]</button><span>Agreement or Creation <br />
                            Date: Four Digit Year</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[JN]')} class="GreenBtn">[JN]</button><span>Job Number</span></p>
                          <p><button onClick={(e)=> handleKeySelection(e,'[UNUM]')} class="BlueBtn">[UNUM]</button><span>Three Characteres of Subdivision</span></p>
                        </aside>
                      </div>
                    </div>
                      </aside>
                    </div>
                    <div class="Button d-flex justify-content-center">
                      <button onClick={()=> setEditGeneralInfo(false)}  class="OutlineBlack mr-5 SaveViewConfigBodyBtn">
                        Cancel
                      </button>
                      <button class="Respond ml-5 SaveViewConfigBodyBtn" onClick={addCompanyFunc}>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <h4 data-toggle="collapse" href="#SubscriptionTabs">
                  <span>
                    <img src="images/subscription.png" />
                  </span>{" "}
                  Subscription
                </h4>
                <a class="Edit EditViewConfigBodyBtn">
                  <img src="images/Action-1.png" />
                </a>
              </div>
              <div
                id="SubscriptionTabs"
                class="collapse show"
                data-parent="#accordion"
              >
                <div class="card-body">
                  <div class="ViewConfigBodyMain">
                    <div class="ViewConfigBody">
                      <aside class="w-100">
                        <div class="row">
                          <div class="col-lg-3">
                            <div class="form-group">
                              <h6>Company Id</h6>
                              <p>Company Id</p>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="form-group">
                              <h6>Subscription</h6>
                              <p>Plan 1</p>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="form-group">
                              <h6>Billing Option</h6>
                              <p>Monthly</p>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="form-group">
                              <h6>Contract Code</h6>
                              <p>CO001FULL</p>
                            </div>
                          </div>
                        </div>
                      </aside>
                    </div>
                  </div>
                  <div class="ViewConfigBodyHideMain">
                    <div class="ViewConfigBodyHide">
                      <aside class="w-100">
                        <div class="row">
                          <div class="col-lg-3">
                            <div class="form-group">
                              <h6>Company Id</h6>
                              <p>Company Id</p>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="form-group">
                              <h6>Subscription</h6>
                              <p>Plan 1</p>
                              <button class="AlterBtn mt-3">Alter</button>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="form-group">
                              <h6>Billing Option</h6>
                              <p>Monthly</p>
                              <button class="AlterBtn mt-3">Alter</button>
                            </div>
                          </div>
                          <div class="col-lg-3">
                            <div class="form-group">
                              <h6>Contract Code</h6>
                              <p>CO001FULL</p>
                            </div>
                          </div>
                        </div>

                        <div class="Button d-flex justify-content-center">
                          <button class="OutlineBlack mr-5 SaveViewConfigBodyBtn">
                            Cancel
                          </button>
                          <button class="Respond ml-5 SaveViewConfigBodyBtn">
                            Save
                          </button>
                        </div>
                      </aside>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div class="card">
              <div class="card-header">
                <h4 data-toggle="collapse" href="#CostProfilesTabs">
                  <span>
                    <img src="images/cost_profile_collapse.png" />
                  </span>{" "}
                  Cost Profiles
                </h4>
                <a class="Edit EditViewConfigBodyBtn">
                  <img src="images/Action-1.png" />
                </a>
              </div>
              <div
                id="CostProfilesTabs"
                class="collapse show"
                data-parent="#accordion"
              >
                <div class="card-body">
                  <div class="ViewConfigBodyMain">
                    <div class="ViewConfigBody">
                      <aside class="w-100">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="form-group">
                              <h6>Select Cost Code Profiles to be created</h6>
                              <p class="mb-2">Land Development</p>
                              <p class="mb-2">Land Development</p>
                              <p class="mb-2">Residential</p>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <h6>Profiles Type</h6>
                              <p>Advanced Cost Codes</p>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="TableList TableHeightScroll">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Category</th>
                                    <th>Land Development</th>
                                    <th>Multi-Family</th>
                                    <th>Residential</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Land Costs</td>
                                    <td>
                                      <label class="CheckBox">
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                      </label>
                                    </td>
                                    <td>
                                      <label class="CheckBox">
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                      </label>
                                    </td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td>Architects &amp; Consultants Fees</td>
                                    <td>
                                      <label class="CheckBox">
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                      </label>
                                    </td>
                                    <td>
                                      <label class="CheckBox">
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                      </label>
                                    </td>
                                    <td>
                                      <label class="CheckBox">
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                      </label>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Engineers Fees</td>
                                    <td>
                                      <label class="CheckBox">
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                      </label>
                                    </td>
                                    <td>
                                      <label class="CheckBox">
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                      </label>
                                    </td>
                                    <td>
                                      <label class="CheckBox">
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                      </label>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Permit Fees</td>
                                    <td>
                                      <label class="CheckBox">
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                      </label>
                                    </td>
                                    <td>
                                      <label class="CheckBox">
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                      </label>
                                    </td>
                                    <td>
                                      <label class="CheckBox">
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                      </label>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Site Land Development Servicing Costs
                                    </td>
                                    <td>
                                      <label class="CheckBox">
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                      </label>
                                    </td>
                                    <td>
                                      <label class="CheckBox">
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                      </label>
                                    </td>
                                    <td>
                                      <label class="CheckBox">
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                      </label>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </aside>
                    </div>
                  </div>
                  <div class="ViewConfigBodyHideMain">
                    <div class="ViewConfigBodyHide">
                      <aside class="w-100">
                        <div class="row">
                          <div class="col-lg-5">
                            <div class="form-group">
                              <h6>Select Cost Code Profiles to be created</h6>
                              <div class="mb-2">
                                <label class="CheckBox">
                                  {" "}
                                  Land Development
                                  <input type="checkbox" />
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                              <div class="mb-2">
                                <label class="CheckBox">
                                  {" "}
                                  Land Development
                                  <input type="checkbox" />
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                              <div>
                                <label class="CheckBox">
                                  {" "}
                                  Residential
                                  <input type="checkbox" />
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-5 ml-auto">
                            <div class="form-group">
                              <h6>Profiles Type</h6>
                              <div class="CommonSelectBox">
                                <select>
                                  <option selected="">
                                    Advanced Cost Codes
                                  </option>
                                  <option>Advanced Cost Codes 1</option>
                                </select>
                                <span>
                                  <img src="images/down.png" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h6>Advanced Cost Codes</h6>
                        <div class="AdvancedCostCodes AdvancedCostCodes2">
                          <table>
                            <thead>
                              <tr>
                                <th>Categories</th>
                                <th>Land Development</th>
                                <th>Multi-Family</th>
                                <th>Residential</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Land Costs</td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                              </tr>
                              <tr>
                                <td>Architects &amp; Consultants Fees</td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                              </tr>
                              <tr>
                                <td>Engineers Fees</td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                              </tr>
                              <tr>
                                <td>Permit Fees</td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                              </tr>
                              <tr>
                                <td>Site Land Development Servicing Costs</td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                              </tr>
                              <tr>
                                <td>Site Preparation &amp; Tem Office Costs</td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Site Landscaping, Retaining Walls, RPR's
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                              </tr>
                              <tr>
                                <td>New Home Warranty &amp; Insurance Fees</td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                              </tr>
                              <tr>
                                <td>New Home Warranty &amp; Insurance Fees</td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                              </tr>
                              <tr>
                                <td>Marketing &amp; Sales Costs</td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                              </tr>
                              <tr>
                                <td>Management, Legal, Financing Fees</td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                                <td>
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                  </label>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div class="Button d-flex justify-content-center">
                          <button class="OutlineBlack mr-5 SaveViewConfigBodyBtn">
                            Cancel
                          </button>
                          <button class="Respond ml-5 SaveViewConfigBodyBtn">
                            Save
                          </button>
                        </div>
                      </aside>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div class="card">
              <div class="card-header">
                <h4 data-toggle="collapse" href="#ProjectsTabs">
                  <span>
                    <img src="images/project_collapse.png" />
                  </span>{" "}
                  Projects
                </h4>
                <a class="Edit EditViewConfigBodyBtn">
                  <img src="images/Action-1.png" />
                </a>
              </div>
              <div
                id="ProjectsTabs"
                class="collapse show"
                data-parent="#accordion"
              >
                <div class="card-body">
                  <div class="ViewConfigBodyMain">
                    <div class="ViewConfigBody">
                      <aside class="w-100">
                        
                        
                        <div class="Tableheader">
                          <h6 class="mb-0">Regular Project</h6>
                          <div class="d-flex align-items-center">
                            <div class="TodosButtons mt-0 d-flex">
                              <a href="javascript:void(0)" class="">
                                <img src="images/create_new_project.png" />
                                Create New Project
                              </a>
                            </div>
                            <div class="NewActions">
                              <a href="jacascript:void(0);">
                                <img src="images/icon-53.png" />
                              </a>
                              <a href="jacascript:void(0);">
                                <img src="images/download.png" />
                              </a>
                              <a href="jacascript:void(0);">
                                <img src="images/setting.png" />
                              </a>
                            </div>
                          </div>
                        </div>
                      <div className="TableOverflowScroll">
                        <div class="TableList TableListHeader NewTableList TableHeightScroll">
                          <table>
                            <thead>
                              <tr>
                                <th>Id</th>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Address</th>
                                <th>Creation Date</th>
                                <th>Created By</th>
                                <th>Accounting Status</th>
                                <th># Active Jobs</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <a
                                    href="javascript:void(0);"
                                    class="TextBlue"
                                    data-toggle="modal"
                                    data-target="#ProjectDetailsResidential"
                                  >
                                    Proj-4E-RES-1
                                  </a>
                                </td>
                                <td>Chinook Homes</td>
                                <td>Residential</td>
                                <td>
                                  312 Weddenburn Rd SE Calgary, Alberta, T2J
                                  1J1, Canada
                                </td>
                                <td>05/24/2023 </td>
                                <td>John Doe</td>
                                <td>Integrated with Accouting Software</td>
                                <td>20</td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="TextBlue">Proj-4E-RES-1</div>
                                </td>
                                <td>Chinook Homes</td>
                                <td>Residential</td>
                                <td>
                                  312 Weddenburn Rd SE Calgary, Alberta, T2J
                                  1J1, Canada
                                </td>
                                <td>05/24/2023 </td>
                                <td>John Doe</td>
                                <td>Integrated with Accouting Software</td>
                                <td>20</td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="TextBlue">Proj-4E-RES-1</div>
                                </td>
                                <td>Chinook Homes</td>
                                <td>Residential</td>
                                <td>
                                  312 Weddenburn Rd SE Calgary, Alberta, T2J
                                  1J1, Canada
                                </td>
                                <td>05/24/2023 </td>
                                <td>John Doe</td>
                                <td>Integrated with Accouting Software</td>
                                <td>20</td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="TextBlue">Proj-4E-RES-1</div>
                                </td>
                                <td>Chinook Homes</td>
                                <td>Residential</td>
                                <td>
                                  312 Weddenburn Rd SE Calgary, Alberta, T2J
                                  1J1, Canada
                                </td>
                                <td>05/24/2023 </td>
                                <td>John Doe</td>
                                <td>Integrated with Accouting Software</td>
                                <td>20</td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="TextBlue">Proj-4E-RES-1</div>
                                </td>
                                <td>Chinook Homes</td>
                                <td>Residential</td>
                                <td>
                                  312 Weddenburn Rd SE Calgary, Alberta, T2J
                                  1J1, Canada
                                </td>
                                <td>05/24/2023 </td>
                                <td>John Doe</td>
                                <td>Integrated with Accouting Software</td>
                                <td>20</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                        <div class="PaginationArea">
                          <h6>Showing 3 of 3 entries</h6>
                          <h6>
                            <img src="images/leftarrow.png" /> 01{" "}
                            <img src="images/rightarrow.png" /> Out 10
                          </h6>
                        </div>
                        

                         
                         <div class="Tableheader mt-5">
                          <h6 class="mb-0">Regular Project</h6>
                          <div class="d-flex align-items-center">
                            <div class="TodosButtons mt-0 d-flex">
                              <a href="javascript:void(0)" class="">
                                <img src="images/create_new_project.png" />
                                Create New Project
                              </a>
                            </div>
                            <div class="NewActions">
                              <a href="jacascript:void(0);">
                                <img src="images/icon-53.png" />
                              </a>
                              <a href="jacascript:void(0);">
                                <img src="images/download.png" />
                              </a>
                              <a href="jacascript:void(0);">
                                <img src="images/setting.png" />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="TableOverflowScroll">
                        <div class="TableList TableListHeader NewTableList TableHeightScroll">
                          <table>
                            <thead>
                              <tr>
                                <th>Id</th>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Address</th>
                                <th>Creation Date</th>
                                <th>Created By</th>
                                <th>Accounting Status</th>
                                <th>Subscription</th>
                                <th># Active Jobs</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <a
                                    href="javascript:void(0);"
                                    class="TextBlue"
                                    data-toggle="modal"
                                    data-target="#ProjectDetailsResidential"
                                  >
                                    Proj-4E-RES-1
                                  </a>
                                </td>
                                <td>Chinook Homes</td>
                                <td>Residential</td>
                                <td>
                                  312 Weddenburn Rd SE Calgary, Alberta, T2J
                                  1J1, Canada
                                </td>
                                <td>05/24/2023 </td>
                                <td>John Doe</td>
                                <td>Integrated with Accouting Software</td>
                                <td>Plan 1 - Monthly Payments</td>
                                <td>20</td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="TextBlue">Proj-4E-RES-1</div>
                                </td>
                                <td>Chinook Homes</td>
                                <td>Residential</td>
                                <td>
                                  312 Weddenburn Rd SE Calgary, Alberta, T2J
                                  1J1, Canada
                                </td>
                                <td>05/24/2023 </td>
                                <td>John Doe</td>
                                <td>Integrated with Accouting Software</td>
                                <td>Plan 1 - Monthly Payments</td>
                                <td>20</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        </div>
                        <div class="PaginationArea">
                          <h6>Showing 3 of 3 entries</h6>
                          <h6>
                            <img src="images/leftarrow.png" /> 01{" "}
                            <img src="images/rightarrow.png" /> Out 10
                          </h6>
                        </div>
                         
                      </aside>
                    </div>
                  </div>
                  <div class="ViewConfigBodyHideMain">
                    <div class="ViewConfigBodyHide">
                      <aside class="w-100">
                        
                        
                        <div class="Tableheader">
                          <h6 class="mb-0">Regular Project</h6>
                          <div class="d-flex align-items-center">
                            <div class="TodosButtons mt-0 d-flex">
                              <a href="javascript:void(0)" class="">
                                <img src="images/create_new_project.png" />
                                Create New Project
                              </a>
                            </div>
                            <div class="NewActions">
                              <a href="jacascript:void(0);">
                                <img src="images/icon-53.png" />
                              </a>
                              <a href="jacascript:void(0);">
                                <img src="images/download.png" />
                              </a>
                              <a href="jacascript:void(0);">
                                <img src="images/setting.png" />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="TableOverflowScroll">
                        <div class="TableList TableListHeader NewTableList TableHeightScroll">
                          <table>
                            <thead>
                              <tr>
                                <th>Id</th>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Address</th>
                                <th>Creation Date</th>
                                <th>Created By</th>
                                <th>Accounting Status</th>
                                <th># Active Jobs</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div class="TextBlue">Proj-4E-RES-1</div>
                                </td>
                                <td>Chinook Homes</td>
                                <td>Residential</td>
                                <td>
                                  312 Weddenburn Rd SE Calgary, Alberta, T2J
                                  1J1, Canada
                                </td>
                                <td>05/24/2023 </td>
                                <td>John Doe</td>
                                <td>Integrated with Accouting Software</td>
                                <td>20</td>
                                <td>
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown">
                                        <a
                                          class=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol class="dropdown-menu dropdown-menuwidth2">
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/Action-1.png" />
                                              Edit Project Details
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/cancecl_message.png" />
                                              Cancel Project
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/enable_account.png" />
                                              Enable Accouting Integration
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="TextBlue">Proj-4E-RES-1</div>
                                </td>
                                <td>Chinook Homes</td>
                                <td>Residential</td>
                                <td>
                                  312 Weddenburn Rd SE Calgary, Alberta, T2J
                                  1J1, Canada
                                </td>
                                <td>05/24/2023 </td>
                                <td>John Doe</td>
                                <td>Integrated with Accouting Software</td>
                                <td>20</td>
                                <td>
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown">
                                        <a
                                          class=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol class="dropdown-menu dropdown-menuwidth2">
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/Action-1.png" />
                                              Edit Project Details
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/cancecl_message.png" />
                                              Cancel Project
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/enable_account.png" />
                                              Enable Accouting Integration
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="TextBlue">Proj-4E-RES-1</div>
                                </td>
                                <td>Chinook Homes</td>
                                <td>Residential</td>
                                <td>
                                  312 Weddenburn Rd SE Calgary, Alberta, T2J
                                  1J1, Canada
                                </td>
                                <td>05/24/2023 </td>
                                <td>John Doe</td>
                                <td>Integrated with Accouting Software</td>
                                <td>20</td>
                                <td>
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown">
                                        <a
                                          class=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol class="dropdown-menu dropdown-menuwidth2">
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/Action-1.png" />
                                              Edit Project Details
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/cancecl_message.png" />
                                              Cancel Project
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/enable_account.png" />
                                              Enable Accouting Integration
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="TextBlue">Proj-4E-RES-1</div>
                                </td>
                                <td>Chinook Homes</td>
                                <td>Residential</td>
                                <td>
                                  312 Weddenburn Rd SE Calgary, Alberta, T2J
                                  1J1, Canada
                                </td>
                                <td>05/24/2023 </td>
                                <td>John Doe</td>
                                <td>Integrated with Accouting Software</td>
                                <td>20</td>
                                <td>
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown">
                                        <a
                                          class=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol class="dropdown-menu dropdown-menuwidth2">
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/Action-1.png" />
                                              Edit Project Details
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/cancecl_message.png" />
                                              Cancel Project
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/enable_account.png" />
                                              Enable Accouting Integration
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="TextBlue">Proj-4E-RES-1</div>
                                </td>
                                <td>Chinook Homes</td>
                                <td>Residential</td>
                                <td>
                                  312 Weddenburn Rd SE Calgary, Alberta, T2J
                                  1J1, Canada
                                </td>
                                <td>05/24/2023 </td>
                                <td>John Doe</td>
                                <td>Integrated with Accouting Software</td>
                                <td>20</td>
                                <td>
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown">
                                        <a
                                          class=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol class="dropdown-menu dropdown-menuwidth2">
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/Action-1.png" />
                                              Edit Project Details
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/cancecl_message.png" />
                                              Cancel Project
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/enable_account.png" />
                                              Enable Accouting Integration
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        </div>
                        <div class="PaginationArea">
                          <h6>Showing 3 of 3 entries</h6>
                          <h6>
                            <img src="images/leftarrow.png" /> 01{" "}
                            <img src="images/rightarrow.png" /> Out 10
                          </h6>
                        </div>

                
                        
                        <div class="Tableheader mt-5">
                          <h6 class="mb-0">Regular Project</h6>
                          <div class="d-flex align-items-center">
                            <div class="TodosButtons mt-0 d-flex">
                              <a href="javascript:void(0)" class="">
                                <img src="images/create_new_project.png" />
                                Create New Project
                              </a>
                            </div>
                            <div class="NewActions">
                              <a href="jacascript:void(0);">
                                <img src="images/icon-53.png" />
                              </a>
                              <a href="jacascript:void(0);">
                                <img src="images/download.png" />
                              </a>
                              <a href="jacascript:void(0);">
                                <img src="images/setting.png" />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="TableOverflowScroll">
                        <div class="TableList TableListHeader NewTableList TableHeightScroll">
                          <table>
                            <thead>
                              <tr>
                                <th>Id</th>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Address</th>
                                <th>Creation Date</th>
                                <th>Created By</th>
                                <th>Accounting Status</th>
                                <th>Subscription</th>
                                <th># Active Jobs</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div class="TextBlue">Proj-4E-RES-1</div>
                                </td>
                                <td>Chinook Homes</td>
                                <td>Residential</td>
                                <td>
                                  312 Weddenburn Rd SE Calgary, Alberta, T2J
                                  1J1, Canada
                                </td>
                                <td>05/24/2023 </td>
                                <td>John Doe</td>
                                <td>Integrated with Accouting Software</td>
                                <td>Plan 1 - Monthly Payments</td>
                                <td>20</td>
                                <td>
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown">
                                        <a
                                          class=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol class="dropdown-menu dropdown-menuwidth2">
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/Action-1.png" />
                                              Edit Project Details
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/cancecl_message.png" />
                                              Cancel Project
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/switch_project.png" />
                                              Switch to this project
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="TextBlue">Proj-4E-RES-1</div>
                                </td>
                                <td>Chinook Homes</td>
                                <td>Residential</td>
                                <td>
                                  312 Weddenburn Rd SE Calgary, Alberta, T2J
                                  1J1, Canada
                                </td>
                                <td>05/24/2023 </td>
                                <td>John Doe</td>
                                <td>Integrated with Accouting Software</td>
                                <td>Plan 1 - Monthly Payments</td>
                                <td>20</td>
                                <td>
                                  <div class="TableThreeDots">
                                    <ul class="">
                                      <li class="dropdown">
                                        <a
                                          class=""
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol class="dropdown-menu dropdown-menuwidth2">
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/Action-1.png" />
                                              Edit Project Details
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/cancecl_message.png" />
                                              Cancel Project
                                            </a>
                                          </li>
                                          <li>
                                            <a href="javascript:void(0);">
                                              <img src="images/switch_project.png" />
                                              Switch to this project
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        </div>
                        <div class="PaginationArea">
                          <h6>Showing 3 of 3 entries</h6>
                          <h6>
                            <img src="images/leftarrow.png" /> 01{" "}
                            <img src="images/rightarrow.png" /> Out 10
                          </h6>
                        </div>
                        
                      </aside>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="CloseButton">
              <button class="Close" data-dismiss="modal">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal
        show={HeaderPreviewModal}
        onHide={handlHeaderPreviewModalClose}
        className="ModalBox modal fade LargeModal GreyModalContent show"
      >
        <Modal.Body>
          <a
            className="CloseModal"
            onClick={handlHeaderPreviewModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Header Preview</h4>
            </div>
            <div className="FolderForm">
              <div className="AgreementViewArea">
                <div className="AgreementViewTop">
                  <div className="AgreementViewTopRight">
                    <div className="mce-pagebreak">
                      <Editor
                        apiKey="s5lpq1i7ftirx73vaw448qm0lk3phqsvnx3dpqvp542y1fbd"
                        onInit={(evt, editor) => (editorsRef.current = editor)}
                        value={letterHeader}
                        init={{
                          height: 500,
                          menubar: false,

                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                            "lists",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic backcolor | " +
                            "alignleft aligncenter alignright alignjustify | " +
                            "bullist numlist outdent indent | pagebreak | removeformat | help",
                        }}
                        onEditorChange={handleEditorChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button text-center mt-5">
                <button className="Outline" onClick={handleHeaderApply} >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>

  );
};

export default AdminCompanyDetails;
