import React, { useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { mainAdminLoginAction } from "../redux/action/authAction";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Logo from "../assets/images/Logo.png";
import { errorToast } from "../utils/toast";
const init = {
  email: "",
  password: "",
  errors: {},
};
const MainLogin = () => {
  const [iState, updateState] = useState(init);
  const { email, password, errors } = iState;
  const dispatch = useDispatch();
  const navigate = useNavigate();
   const [ibuildShow, setIbuildShow] = useState(false)
  const handleValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!email) {
      error.emailError = "* Email address can't be empty";
      formIsValid = false;
    }

    if (email) {
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        error.emailError = "Please enter a valid email";
        formIsValid = false;
      }
    }

    if (!password?.trim("")) {
      error.passwordError = "! Password can't be empty";
      formIsValid = false;
    }

    if (password) {
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          password
        )
      ) {
        error.passwordError =
          "Please enter atleast one speical character one alphnumeric and one capital letter";
        formIsValid = false;
      }
    }

    updateState({ ...iState, errors: error });
    return formIsValid;
  };

  const handleLogin = (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    if(formIsValid){
      dispatch(mainAdminLoginAction({ email, password })).then((res) => {
        console.log("==============res", res);
        navigate("/dashboard")
      }).catch((err) => {
        console.log("======>", err)
        errorToast("Invalid credentials")
      })
    }
   
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  useEffect(() => {
    window.localStorage.clear();
  }, []);

  return (
    <>
      <div className="LoginArea">
        <div className="LoginBoxBig">
          <div className="row">
            <div className="col-md-5">
              <div className="LoginBoxLeft">
                <figure>
                  <img src="images/login_background.png" />
                </figure>
              </div>
            </div>
            <div className="col-md-7">
            {/* <a href="javascript:void(0);"><img src="images/login_background.png" /></a> */}
            <div className="text-right"><a href="javascript:void(0);"><img src={Logo}/></a></div>
              <div className="LoginBox MultipleLogin">
              
                <div>
                  <h6>Multi-Family and Residential Platform</h6> 
                  <ul>
                    <li>
                      <Link  to="/ibuild-login">
                       Company User
                      </Link>
                    </li>
                    <li>
                      <Link  to="/client-view-login">
                      Client View
                      </Link>
                    </li>
                    <li>
                      <Link  to="/trade-view-login">
                      Trade View
                      </Link>
                    </li>
                    <li>
                      <Link  to="/ibuild-admin-login">
                        Ibuild Admin Login
                      </Link>
                    </li>
                    <li>
                      <Link  to="/investor-view-login">
                        Investor View
                      </Link>
                    </li>
                  </ul>
                  </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLogin;
