import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import SelectAccountModal from "./SelectAccountModal";
import AddNoteModal from "./AddNoteModal";
import AssignStaffModal from "./AssignStaffModal";
import AssignVendorModal from "./AssignVendorModal";

const AssignResouceModal = ({ assignResouceModal, setAssignResouceModal }) => {
  const dispatch = useDispatch();
  const handleassignResouceModalClose = () => setAssignResouceModal(false);

  const [selectAccountModal, setSelectAccountModal] = useState(false);
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [assignStaffModal, setAssignStaffModal] = useState(false)
  const [assignVendorModal,setAssignVendorModal] = useState(false)


  return (
    <>
      <Modal
        show={assignResouceModal}
        onHide={handleassignResouceModalClose}
        className="ModalBox SmallModal"
      >
        <a
          onClick={handleassignResouceModalClose}
          className="CloseModal"
          data-dismiss="modal"
        >
          ×
        </a>

        <div class="FolderForm">
          <form>
            <div class="JobTitleArea mb-2">
              <div class="JobBox">
                <h6>
                  <span>Job:</span>&nbsp;Chinook Moon Homes B-3 Unit 1
                </h6>
              </div>
              <div class="JobBox">
                <h6 class="FolderPermissionId">
                  <span>Task:</span>&nbsp;Sand Walls
                </h6>
              </div>
            </div>
            <div class="StaffMemberArea StaffMemberAreaConst">
              <aside>
                <h6>Select Account</h6>
                <div
                  class="StaffName FolderPermissionId"
                  onClick={() => {setSelectAccountModal(true)
                    setAssignResouceModal(false)
                  }
            
                }
                >
                  <img src="images/icon-40.png" />
                  <h6>Select Account</h6>
                </div>
              </aside>
            </div>

            <div class="StaffMemberArea StaffMemberAreaConst">
              <aside>
                <h6>Field Purchase Order</h6>
                <div class="d-flex">
                  <div class="StaffName">
                    <img src="images/send.png" />
                    <h6>Send</h6>
                  </div>
                  <div class="StaffName ml-5">
                    <img src="images/view.png" />
                    <h6>View</h6>
                  </div>
                </div>
              </aside>
            </div>

            <div class="StaffMemberArea StaffMemberAreaConst">
              <aside>
                <h6>Add Notes</h6>
                <div class="StaffName">
                  <h6
                    class="ml-0 FolderPermissionId"
                    onClick={()=>{setAddNoteModal(true)
                        setAssignResouceModal(false)
                    }}
                  
                  >
                    <img src="images/Action-4.png" />
                    &nbsp;Add Notes
                  </h6>
                  <span
                    class="Count FolderPermissionId"
                    onClick={()=>{setAddNoteModal(true)
                        setAssignResouceModal(false)
                    }}
                  >
                    3
                  </span>
                </div>
              </aside>
            </div>

            <div class="StaffMemberArea StaffMemberAreaConst">
              <aside>
                <h6>Select Resources</h6>
                <div class="d-flex">
                  <div
                    class="StaffName FolderPermissionId"
                    onClick={()=> {
                        setAssignStaffModal(true)
                        setAssignResouceModal(false)
                    }}
                  
                  >
                    <img src="images/member.png" />
                    <h6>Staff Member</h6>
                  </div>
                  <div
                    class="StaffName ml-5 FolderPermissionId"
                    

                    onClick={()=> {
                        setAssignVendorModal(true)
                        setAssignResouceModal(false)
                    }}
                  >
                    <img src="images/assign-trade.png" />
                    <h6>Vendor</h6>
                  </div>
                </div>
              </aside>
            </div>
            <div class="Button d-flex justify-content-between">
              <button class="Create">Add</button>
              <button class="Outline">Clear</button>
            </div>
            <div class="TableList my-4 TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Resources</th>
                    <th>Type</th>
                    <th>Reminder</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>John Doe</td>
                    <td>Staff Member</td>
                    <td>3 Days</td>
                    <td>
                      <a href="">
                        <img src="images/icon-41.png" />
                      </a>
                      <a href="">
                        <img src="images/icon-42.png" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Plumber LTD</td>
                    <td>Vendor</td>
                    <td>1 week</td>
                    <td>
                      <a href="">
                        <img src="images/icon-41.png" />
                      </a>
                      <a href="">
                        <img src="images/icon-42.png" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Plumber LTD</td>
                    <td>Vendor</td>
                    <td>1 week</td>
                    <td>
                      <a href="">
                        <img src="images/icon-41.png" />
                      </a>
                      <a href="">
                        <img src="images/icon-42.png" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Plumber LTD</td>
                    <td>Vendor</td>
                    <td>1 week</td>
                    <td>
                      <a href="">
                        <img src="images/icon-41.png" />
                      </a>
                      <a href="">
                        <img src="images/icon-42.png" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Plumber LTD</td>
                    <td>Vendor</td>
                    <td>1 week</td>
                    <td>
                      <a href="">
                        <img src="images/icon-41.png" />
                      </a>
                      <a href="">
                        <img src="images/icon-42.png" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Plumber LTD</td>
                    <td>Vendor</td>
                    <td>1 week</td>
                    <td>
                      <a href="">
                        <img src="images/icon-41.png" />
                      </a>
                      <a href="">
                        <img src="images/icon-42.png" />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="Button d-flex justify-content-between">
              <button class="Create ClosePermissionId" data-dismiss="modal">
                Assign/Update
              </button>
              <button class="Outline ClosePermissionId" data-dismiss="modal">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <SelectAccountModal
        selectAccountModal={selectAccountModal}
        setSelectAccountModal={setSelectAccountModal}
        setAssignResouceModal={setAssignResouceModal}
      />

<AddNoteModal
        addNoteModal={addNoteModal}
        setAddNoteModal={setAddNoteModal}
        setAssignResouceModal={setAssignResouceModal}
      />


<AssignStaffModal
        assignStaffModal={assignStaffModal}
        setAssignStaffModal={setAssignStaffModal}
        setAssignResouceModal={setAssignResouceModal}
      />


<AssignVendorModal
        assignVendorModal={assignVendorModal}
        setAssignVendorModal={setAssignVendorModal}
        setAssignResouceModal={setAssignResouceModal}
      />
    </>

    
  );
};

export default AssignResouceModal;
