import { todoAction } from "../actionTypes";
import { Url } from "../../Config/Config";
import axios from "axios";
import { isLoggedIn } from "../../utils/tokenCheck";
import { multiPartData, multiPartDatas } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

//---------------------------------taskCreate----------------------------------------------------
export const addTaskInitiate = () => ({
    type: todoAction.ADD_TODO_INITIATE,
  });
  
  export const addTaskSuccess = (data) => ({
    type: todoAction.ADD_TODO_SUCCESS,
    payload: data,
  });
  
  export const addTaskFailure = (data) => ({
    type: todoAction.ADD_TODO_FAILURE,
    payload: data,
  });
  
  export const addTodoAction = (payload) => async (dispatch) => {
    const updatedData = multiPartData({  ...payload });
      try {
        const token = isLoggedIn("adminData1");
        dispatch(addTaskInitiate(payload));
        const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/todos`,updatedData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 201) {
          dispatch(addTaskSuccess(response.data));
        } else {
          dispatch(addTaskFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(addTaskFailure(err));
        return err.response
      }
    };
//---------------------------------tasklISTjOB----------------------------------------------------
export const taskListJobInitiate = () => ({
    type: todoAction.TODO_JOB_LIST_INITIATE,
  });
  
  export const taskListJobSuccess = (data) => ({
    type: todoAction.TODO_JOB_LIST_SUCCESS,
    payload: data,
  });
  
  export const taskListJobFailure = (data) => ({
    type: todoAction.TODO_JOB_LIST_FAILURE,
    payload: data,
  });
  
  export const TodoListAction = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(taskListJobInitiate(payload));
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/todos?toDoStatus=${payload ? payload :""}`, {
          headers: { Authorization: `Bearer ${token}` },
        //   ?jobType=ResidentialJob
        });
        if (response.status == 200 || response.status == 201) {
          dispatch(taskListJobSuccess(response.data));
        } else {
          dispatch(taskListJobFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(taskListJobFailure(err));
        return err.response
      }
    };

//---------------------------------CreatedtaskList----------------------------------------------------
export const createdTaskListInitiate = () => ({
    type: todoAction.CREATED_TODO_LIST_INITIATE,
  });
  
  export const createdTaskListSuccess = (data) => ({
    type: todoAction.CREATED_TODO_LIST_SUCCESS,
    payload: data,
  });
  
  export const createdTaskListFailure = (data) => ({
    type: todoAction.CREATED_TODO_LIST_FAILURE,
    payload: data,
  });
  
  export const getCreatedTaskList = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(createdTaskListInitiate(payload));
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks?taskStatus=Created&showAll=false`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        // console.log("response cre" ,response)
        if (response.status == 200) {
          dispatch(createdTaskListSuccess(response.data));
        } else {
          dispatch(createdTaskListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(createdTaskListFailure(err));
        return err.response
      }
    };
//---------------------------------iNprocesstaskList----------------------------------------------------
export const inProcessTaskListInitiate = () => ({
  type: todoAction.INPROGRESS_TODO_LIST_INITIATE,
});

export const inProcessTaskListSuccess = (data) => ({
  type: todoAction.INPROGRESS_TODO_LIST_SUCCESS,
  payload: data,
});

export const inProcessTaskListFailure = (data) => ({
  type: todoAction.INPROGRESS_TODO_LIST_FAILURE,
  payload: data,
});

export const getInProcessTaskList = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(inProcessTaskListInitiate(payload));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks?taskStatus=In-Progress&showAll=false`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(inProcessTaskListSuccess(response.data));
      } else {
        dispatch(inProcessTaskListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(inProcessTaskListFailure(err));
      return err.response
    }
  };

  //---------------------------------completedtaskList----------------------------------------------------
export const completeTaskListInitiate = () => ({
  type: todoAction.COMPLETED_TODO_LIST_INITIATE,
});

export const completeTaskListSuccess = (data) => ({
  type: todoAction.COMPLETED_TODO_LIST_SUCCESS,
  payload: data,
});

export const completeTaskListFailure = (data) => ({
  type: todoAction.COMPLETED_TODO_LIST_FAILURE,
  payload: data,
});

export const getCompleteTaskList = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(completeTaskListInitiate(payload));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks?taskStatus=Completed&showAll=false`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(completeTaskListSuccess(response.data));
      } else {
        dispatch(completeTaskListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(completeTaskListFailure(err));
      return err.response
    }
  };

//---------------------------------taskMemberList----------------------------------------------------
export const taskMemberListInitiate = () => ({
  type: todoAction.TODO_MEMBER_LIST_INITIATE,
});

export const taskMemberListSuccess = (data) => ({
  type: todoAction.TODO_MEMBER_LIST_SUCCESS,
  payload: data,
});

export const taskMemberListFailure = (data) => ({
  type: todoAction.TODO_MEMBER_LIST_FAILURE,
  payload: data,
});

export const getTaskMemberList = (payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(taskMemberListInitiate(payload));
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/user-list`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(taskMemberListSuccess(response.data));
      } else {
        dispatch(taskMemberListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(taskMemberListFailure(err));
      return err.response
    }
  };

  
//---------------------------------taskhISTROYList----------------------------------------------------
export const taskHistoryListInitiate = () => ({
  type: todoAction.TODO_HISTORY_LIST_INITIATE,
});

export const taskHistoryListSuccess = (data) => ({
  type: todoAction.TODO_HISTORY_LIST_SUCCESS,
  payload: data,
});

export const taskHistoryListFailure = (data) => ({
  type: todoAction.TODO_HISTORY_LIST_FAILURE,
  payload: data,
});

export const getTaskHistoryList = (taskId) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(taskHistoryListInitiate(taskId));
    const response = await axios.get(
      `${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks/${taskId}/history`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(taskHistoryListSuccess(response.data));
    } else {
      dispatch(taskHistoryListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(taskHistoryListFailure(err));
    return err.response;
  }
};

//---------------------------------taskdELETE----------------------------------------------------
export const deleteTodonitiate = () => ({
  type: todoAction.DELETE_TODO_INITIATE,
});

export const deleteTodoSuccess = (data) => ({
  type: todoAction.DELETE_TODO_SUCCESS,
  payload: data,
});

export const deleteTodoFailure = (data) => ({
  type: todoAction.DELETE_TODO_FAILURE,
  payload: data,
});

export const getTodoDelete = (TodoId) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    const { TodoId: extractedDocId } = TodoId;
    dispatch(deleteTodonitiate(TodoId));
    const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/todos/${TodoId}`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(deleteTodoSuccess(response.data));
    } else {
      dispatch(deleteTodoFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(deleteTodoFailure(err));
    return err.response;
  }
};

//---------------------------------taskaSSIGN----------------------------------------------------
export const assignTaskInitiate = () => ({
  type: todoAction.ASSIGN_TODO_INITIATE,
});

export const assignTaskSuccess = (data) => ({
  type: todoAction.ASSIGN_TODO_SUCCESS,
  payload: data,
});

export const assignTaskFailure = (data) => ({
  type: todoAction.ASSIGN_TODO_FAILURE,
  payload: data,
});

export const getTaskAssign = (payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    dispatch(assignTaskInitiate(payload));
    const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/tasks/7/update-assignees`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(assignTaskSuccess(response.data));
    } else {
      dispatch(assignTaskFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(assignTaskFailure(err));
    return err.response;
  }
};


export const addGoogleCalendayInitate = () => ({
  type: todoAction.ADD_GOOGLE_INITIATE,
});

export const addGoogleCalendaySuccess = (data) => ({
  type: todoAction.ADD_GOOGLE_SUCCESS,
  payload: data,
});

export const addGoogleCalendayFailure = (data) => ({
  type: todoAction.ADD_GOOGLE_FAILURE,
  payload: data,
});

export function addGoogleCalendayAction(payload) {
  
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(addGoogleCalendayInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/create-google-calender`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if (data &&  data.status == 200) {
            console.log("data200200", response);
            dispatch(addGoogleCalendaySuccess(response.data));
            resolve(response.data);
          }
        })
        .catch((err) => {
          dispatch(addGoogleCalendayFailure(err));
          reject(err);
        });
    });
  };
}




export const addOutlookCalendayInitate = () => ({
  type: todoAction.ADD_OUTLOOK_INITIATE,
});

export const addOutlookCalendaySuccess = (data) => ({
  type: todoAction.ADD_OUTLOOK_SUCCESS,
  payload: data,
});

export const addOutlookCalendayFailure = (data) => ({
  type: todoAction.ADD_OUTLOOK_FAILURE,
  payload: data,
});

export function addOutlookCalendayAction(payload) {
  
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(addOutlookCalendayInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/create-outlook-calender`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if (data &&  data.status == 200) {
            dispatch(addOutlookCalendaySuccess(response.data));
            resolve(response.data);
          }
        })
        .catch((err) => {
          dispatch(addOutlookCalendayFailure(err));
          reject(err);
        });
    });
  };
}





export const addYahooInitate = () => ({
  type: todoAction.ADD_YAHOO_INITIATE,
});

export const addYahooSuccess = (data) => ({
  type: todoAction.ADD_YAHOO_SUCCESS,
  payload: data,
});

export const addYahooFailure = (data) => ({
  type: todoAction.ADD_YAHOO_FAILURE,
  payload: data,
});

export function addYahooAction(payload) {
  
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(addYahooInitate());
    return new Promise((resolve, reject) => {
      axios
        .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/create-yahoo-calender`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          if (data &&  data.status == 200) {
            console.log("data200200", response);
            dispatch(addYahooSuccess(response.data));
            resolve(response.data);
          }
        })
        .catch((err) => {
          dispatch(addYahooFailure(err));
          reject(err);
        });
    });
  };
}



export const todoDetailsInitate = () => ({
  type: todoAction.TODO_DETAILS_INITIATE,
  });
  
  export const todoDetailsSuccess = (data) => ({
  type: todoAction.TODO_DETAILS_SUCCESS,
  payload: data,
  });
  
  export const todoDetailsFailure = (data) => ({
  type: todoAction.TODO_DETAILS_FAILURE,
  payload: data,
  });
  
  export function todoDetailsAction( id, sid ) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(todoDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/todos/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200 || 201) {
            dispatch(todoDetailsSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(todoDetailsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("==errerr=====>", err)
          if(err.response.status === 404){
            dispatch(todoDetailsSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(todoDetailsFailure(err));
            reject(err);
          }
        });
    });
  };
  }
  
  
  
export const updateRemianderInitate = () => ({
  type: todoAction.UPDATE_REMINDER_INITIATE,
  });
  
  export const updateRemianderSuccess = (data) => ({
  type: todoAction.UPDATE_REMINDER_SUCCESS,
  payload: data,
  });
  
  export const updateRemianderFailure = (data) => ({
  type: todoAction.UPDATE_REMINDER_SUCCESS,
  payload: data,
  });
  
  export function updateRemianderAction(params,payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(updateRemianderInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/todos/${params}/set-reminder`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(updateRemianderSuccess(data));
            resolve(data);
          } else {
            dispatch(updateRemianderFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateRemianderFailure(err));
          reject(err);
        });
    });
  };
  }


  
export const updateTradeInitate = () => ({
  type: todoAction.UPDATE_TRADE_INITIATE,
  });
  
  export const updateTradeSuccess = (data) => ({
  type: todoAction.UPDATE_TRADE_SUCCESS,
  payload: data,
  });
  
  export const updateTradeFailure = (data) => ({
  type: todoAction.UPDATE_TRADE_SUCCESS,
  payload: data,
  });
  
  export function updateTradeAction(params, sid,payload ) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(updateTradeInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/todos/${params}/assign-trade/${sid}`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(updateTradeSuccess(data));
            resolve(data);
          } else {
            dispatch(updateTradeFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateTradeFailure(err));
          reject(err);
        });
    });
  };
  }



  
export const updateStaffInitate = () => ({
  type: todoAction.UPDATE_STAFF_INITIATE,
  });
  
  export const updateStaffSuccess = (data) => ({
  type: todoAction.UPDATE_STAFF_SUCCESS,
  payload: data,
  });
  
  export const updateStaffFailure = (data) => ({
  type: todoAction.UPDATE_STAFF_SUCCESS,
  payload: data,
  });
  
  export function updateStaffAction(payload, params) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(updateStaffInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/ctc-categories/${params}`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(updateStaffSuccess(data));
            resolve(data);
          } else {
            dispatch(updateStaffFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateStaffFailure(err));
          reject(err);
        });
    });
  };
  }




  
  export const updateTodoInitate = () => ({
    type: todoAction.UPDATE_TODO_INITIATE,
    });
    
    export const updateTodoSuccess = (data) => ({
    type: todoAction.UPDATE_TODO_SUCCESS,
    payload: data,
    });
    
    export const updateTodoFailure = (data) => ({
    type: todoAction.UPDATE_TODO_SUCCESS,
    payload: data,
    });
    
    export function updateTodoAction(params,payload ) {
    const token = isLoggedIn("adminData1");
    const updatedData = multiPartData({  ...payload });
    return (dispatch) => {
      dispatch(updateTodoInitate());
      return new Promise((resolve, reject) => {
        // ${payload?payload:""}
        axios
          .put(
            `${Url}companies/${iBuildLocalData?.user?.CompanyId}/todos/${params}`,
            updatedData,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            const data = response.data;
            if ((data && data.status == 201) || 200) {
              dispatch(updateTodoSuccess(data));
              resolve(data);
            } else {
              dispatch(updateTodoFailure(data));
              reject(data);
            }
          })
          .catch((err) => {
            dispatch(updateTodoFailure(err));
            reject(err);
          });
      });
    };
    }
  
  

    
  export const getTodoCalendarInitate = () => ({
    type: todoAction.TODO_CALENDAR_INITIATE,
    });
    
    export const getTodoCalendarSuccess = (data) => ({
    type: todoAction.TODO_CALENDAR_SUCCESS,
    payload: data,
    });
    
    export const getTodoCalendarFailure = (data) => ({
    type: todoAction.TODO_CALENDAR_SUCCESS,
    payload: data,
    });
    
    export function getTodoCalendarAction(data ) {
    const token = isLoggedIn("adminData1");
    return (dispatch) => {
      dispatch(getTodoCalendarInitate());
      return new Promise((resolve, reject) => {
        // ${payload?payload:""}
        axios
          .get(
            `${Url}companies/${iBuildLocalData?.user?.CompanyId}/todos-calendar/?startDate=${data?.startDate}&endDate=${data?.endDate}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            const data = response.data;
            if ((data && data.status == 201) || 200) {
              dispatch(getTodoCalendarSuccess(data));
              resolve(data);
            } else {
              dispatch(getTodoCalendarFailure(data));
              reject(data);
            }
          })
          .catch((err) => {
            dispatch(getTodoCalendarFailure(err));
            reject(err);
          });
      });
    };
    }
  
  


    
  
  export const updateAssignStaffInitate = () => ({
    type: todoAction.UPDATE_ASSIGN_STAFF_INITIATE,
    });
    
    export const updateAssignStaffSuccess = (data) => ({
    type: todoAction.UPDATE_ASSIGN_STAFF_SUCCESS,
    payload: data,
    });
    
    export const updateAssignStaffFailure = (data) => ({
    type: todoAction.UPDATE_ASSIGN_STAFF_SUCCESS,
    payload: data,
    });
    
    export function updateAssignStaffAction(params,payload ) {
    const token = isLoggedIn("adminData1");
    // const updatedData = multiPartData({  ...payload });
    return (dispatch) => {
      dispatch(updateAssignStaffInitate());
      return new Promise((resolve, reject) => {
        // ${payload?payload:""}
        axios
          .put(
            `${Url}companies/${iBuildLocalData?.user?.CompanyId}/todos/${params}/assignStaff`,
            payload,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            const data = response.data;
            if ((data && data.status == 201) || 200) {
              dispatch(updateAssignStaffSuccess(data));
              resolve(data);
            } else {
              dispatch(updateAssignStaffFailure(data));
              reject(data);
            }
          })
          .catch((err) => {
            dispatch(updateAssignStaffFailure(err));
            reject(err);
          });
      });
    };
    }