import React from 'react'
import { Link } from 'react-router-dom'

const IbuildAdminSidenav = (props) => {
  let path = window.location.href.split("/");
  let pathLength = parseInt(path.length) - 1;
  let pathMatch = path[pathLength];

  return (
    
    <div class="SidenavBar">    
      <ul>
          <li className={pathMatch == "ibuild-admin-dashboard" ? "active" : ""}>
            <Link to="/ibuild-admin-dashboard">
              <span> <img src="images/sidenav-1.png" /></span>
              Home
            </Link>
          </li>
          <li className={props?.activeLinks == "Customers-Information" ? "active" : ""}>
            <a>
              <span> <img src="images/customer_information.png" /></span>
              Customers Information
            </a>
          </li>
          <li className={props?.activeLinks == "Help-Messages" ? "active" : ""} >
            <a>
              <span> <img src="images/help_message_side.png" /></span>
              Help Message
            </a>
          </li>
          <li className={props?.activeLinks == "Subscription-Plans" ? "active" : ""}>
          <a>
              <span> <img src="images/sidenav-3.png" /></span>
              Subscription Plans
            </a>
          </li>
          
          <li className={props?.activeLinks == "Customer-Support" ? "active" : ""}>
            <Link to="/system-adminsitration">
              <span>
                {" "}
                <img src="images/subscription_plans_side.png" />
              </span>
              Customer Support
              
            </Link>
          </li>
        
          <li>
            <a>
              <span> <img src="images/sidenav-5.png" /></span>
              Cost Code Master File
            </a>
          </li>
          <li>
            <a>
              <span> <img src="images/ibuild_demo.png" /></span>
              Ibuild Demo
            </a>
          </li>
          <li>
            <Link to="/client-view-login">
              <span> <img src="images/client_login.png" /></span>
              Client Login
            </Link>
          </li>
          <li className={props?.activeLinks == "Chat-Messages" ? "active" : ""}>
            <a>
              <span> <img src="images/chat_message.png" /></span>
              Chat Message
            </a>
          </li>

          <li className={pathMatch == "unit-management" ? "active" : ""}>
            <Link to="/unit-management">
              <span> <img src="images/chat_message.png" /></span>
                 User Management
            </Link>
          </li>
          <li className={pathMatch == "ibuild-admin-file-manager" ? "active" : ""}>
          <Link to="/ibuild-admin-file-manager">
              <span> <img src="images/system_file_manager.png" /></span>
              System File Manager
            </Link>
          </li>
          <li className={pathMatch == "ibuild-admin-pricebook" ? "active" : ""}>
            <Link to="/ibuild-admin-pricebook">
              <span> <img src="images/price_book.png" /></span>
              Price Book
            </Link>
          </li>
          
      </ul>
    </div>
  )
}

export default IbuildAdminSidenav