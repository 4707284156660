import React, { useState } from 'react'
import '../App.css';
import sidenav1 from "../assets/images/sidenav-1.png";
import sidenav2 from "../assets/images/sidenav-2.png";
import sidenav3 from "../assets/images/sidenav-3.png";
import sidenav4 from "../assets/images/sidenav-4.png";
import sidenav5 from "../assets/images/sidenav-5.png";
import sidenav6 from "../assets/images/sidenav-6.png";
import sidenav7 from "../assets/images/sidenav-7.png";
import sidenav8 from "../assets/images/sidenav-8.png";
import sidenav9 from "../assets/images/sidenav-9.png";
import sidenav10 from "../assets/images/sidenav-10.png";
import sidenav11 from "../assets/images/sidenav-11.png";
import sidenav12 from "../assets/images/sidenav-11.png";
import theme8 from "../assets/images/theme-8.png";
import profile from "../assets/images/profile.png";
import contacts from "../assets/images/contacts.png";
import my_task from "../assets/images/my_task.png";
import signout from "../assets/images/signout.png";
import sales from "../assets/images/sales_red.png";
import { Link, useLocation } from 'react-router-dom';
import CreateModal from './CreateModal';
<img src="images/sales_red.png"></img>
const initModal = {

}

const TradeViewSidenav = () => {

    const tradeViewData=JSON.parse(window.localStorage.getItem("tradeViewLogin"));
  
    const location = useLocation();
    const isActive = (path) => {
      return  location.pathname === path ||(location.pathname !== "/" && location.pathname.startsWith(path + "/"))
    };
  
    let path = window.location.href.split("/");
    let pathLength = parseInt(path.length) - 1;
    let pathMatch = path[pathLength];
  
    const [show,setShow]=useState(false)
  return (
    <div>
    {/* <CreateModal show={show} setShow={setShow}/> */}


{
tradeViewData?.user?.role==="Trade View" &&
<div className="SidenavBarClient">
<ul>
  <li>
    <a className="TextRed">
      Trade View
    </a>
  </li>
  {/* <li>
    <a href="javascript:void(0);" className="Black">
      Menu
    </a>
  </li> */}
  <li className={isActive("/trade-view-home")?"active":""}>
    <Link to="/trade-view-home" className="Home">
      <span>
        {" "}
        <img src="images/home_white.png" />
      </span>
      Home
    </Link>
  </li>
  {/* <li>
    <a
      href="javascript:void(0);"
      className="Home"
      data-toggle="modal"
      data-target="javascript:void(0);CreateSideBarModal"
      data-dismiss="modal"
    >
      <span>
        {" "}
        <img src="images/sidenav_grey-2.png" />
      </span>
      Create
    </a>
  </li> */}
  <li className={isActive("/design-selection-tradeview") || isActive("/design-selections-trade-view-details")?"active":""}>
    <Link to="/design-selection-tradeview">
      Design Selections <span className="Number">3</span>
    </Link>
  </li>
  <li  className={isActive("/file-manager-trade-view")?"active":""}>
    <Link to="/file-manager-trade-view">
      File Manager <span className="Number">3</span>
    </Link>
  </li>
  <li className={isActive("/appointements-trade-view")?"active" : ""}>
    <Link to="/appointements-trade-view">
      Appointments <span className="Number">3</span>
    </Link>
  </li>
  <li className={isActive("/change-order-checklist-tradeview") || isActive("/edit-change-order-tradeview")?"active" : ""}>
    <Link to="/change-order-checklist-tradeview">
      Change Order Checklist <span className="Number">3</span>
    </Link>
  </li>
  <li className={isActive("/warranty-work-order-tradeview")|| isActive("/warranty-work-order-tradeview-details")?"active" : ""}>
    <Link to="/warranty-work-order-tradeview">
      Warranty Work Order<span className="Number">3</span>
    </Link>
  </li>
  <li className={isActive("/po-qr-tradeview")?"active" : ""}>
    <Link to="/po-qr-tradeview">
      PQ/QR<span className="Number">3</span>
    </Link>
  </li>
  <li className={isActive("/construction-schedul-tradeview")?"active" : ""}>
    <Link to="/construction-schedul-tradeview">
      Construction Schedule<span className="Number">3</span>
    </Link>
  </li>
  <li className={isActive("/todo-tradeview")?"active" : ""}>
    <Link to="/todo-tradeview">
      TODO<span className="Number">3</span>
    </Link>
  </li>
</ul>
</div>


}




  </div>
  )
}

export default TradeViewSidenav