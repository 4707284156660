import React from 'react'
import { Modal } from 'react-bootstrap'


const ImportSchedule = ({ImportScheduleModal,setImportScheduleModal,setAddSchedule}) => {


    const handleDefaultSystemModalClose = (e) =>{ 
        e.preventDefault()
        setImportScheduleModal(false)
        setAddSchedule(true)

    }


  return (

    <Modal
    show={ImportScheduleModal}
    onHide={handleDefaultSystemModalClose}
    className="ModalBox SmallModal"
  >
    <div class="modal-content">
      <a
        onClick={handleDefaultSystemModalClose}
        className="CloseModal"
        data-dismiss="modal"
      >
        ×
      </a>

      <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Import Schedule</h4>
          </div>
          <div class="FolderForm">
            <form>
              <div class="form-group">
                <h6>Select Files</h6>
                <div class="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input type="file" />
                </div>
              </div>
              <div class="FilesAdded">
                <label>schedule.ibuild</label>
                <span><img src="images/fileview.png" /></span>
                <span><img src="images/Cross.png" class="cross"/></span>
              </div>
              <div class="Button d-flex justify-content-between">
                <button class="Create" data-toggle="modal" data-target="#ConstructionScheduleGeneration" data-dismiss="modal">Add Imported Schedule</button>
                <button class="Outline" data-toggle="modal" onClick={handleDefaultSystemModalClose}>Cancel</button>
              </div>
            </form>
          </div>
        </div>

      </div>
      </Modal>


  )
}

export default ImportSchedule