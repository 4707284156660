import { priceBookActionTypes,  } from "../actionTypes";
const initialState = {
  loader: false,
categoryListData:[],
fileListData:[],
folderHistoryListData:[],
folderSearchListData:[],
pendingFileListData:[],
clientDOcumentationListData:[],
categoryHistoryData :[],
entriesListData:[],
entryHistoryData:[],
measureListData:[],
assemblyListData:[],
assemblyHistoryData:[],
CTCListData:[],
CTCHistoryData:[],
priceBooKListData:[],
templateListData:[],
templateHistoryData:[],
templateDetailsData:[],
ctcCategoryListData:[],
ctcAssemblyListData:[],




};

export default function PriceBookReducer(state = initialState, { type, payload }) {
  switch (type) {
    case priceBookActionTypes.CATEGORY_LIST_INITIATE: {
        return {
          ...state,
          categoryListData: [],
          loader:true
        };
      }
      case priceBookActionTypes.CATEGORY_LIST_SUCCESS: {
        return {
          ...state,
          categoryListData: payload,
          loader:false
        };
      }
      case priceBookActionTypes.CATEGORY_LIST_FAILURE: {
        return {
          ...state,
          categoryListData: payload.data,
          loader:false
        };
      }


      case priceBookActionTypes.CTC_CATEGORY_LIST_INITIATE: {
        console.log(payload,"REDUCER INITIAL =================>")

        return {
          ...state,
          ctcCategoryListData: [],
          loader:true
        };
      }
      case priceBookActionTypes.CTC_CATEGORY_LIST_SUCCESS: {
        console.log(payload,"REDUCER")
        return {
          ...state,
          ctcCategoryListData: payload,
          loader:false
        };
      }
      case priceBookActionTypes.CTC_CATEGORY_LIST_FAILURE: {
        return {
          ...state,
          ctcCategoryListData: payload.data,
          loader:false
        };
      }

      case priceBookActionTypes.CTC_ASSEMBLY_LIST_INITIATE: {
        return {
          ...state,
          ctcAssemblyListData: [],
          loader:true
        };
      }
      case priceBookActionTypes.CTC_ASSEMBLY_LIST_SUCCESS: {
        return {
          ...state,
          ctcAssemblyListData: payload,
          loader:false
        };
      }
      case priceBookActionTypes.CTC_ASSEMBLY_LIST_FAILURE: {
        return {
          ...state,
          ctcAssemblyListData: payload.data,
          loader:false
        };
      }

      case priceBookActionTypes.CATEGORY_HISTORY_INITIATE: {
        return {
          ...state,
          categoryHistoryData: [],
          loader:true
        };
      }
      case priceBookActionTypes.CATEGORY_HISTORY_SUCCESS: {
        return {
          ...state,
          categoryHistoryData: payload,
          loader:false
        };
      }
      case priceBookActionTypes.CATEGORY_HISTORY_FAILURE: {
        return {
          ...state,
          categoryHistoryData: payload.data,
          loader:false
        };
      }

      
      case priceBookActionTypes.ENTRIES_LIST_INITIATE: {
        return {
          ...state,
          entriesListData: [],
          loader:true
        };
      }
      case priceBookActionTypes.ENTRIES_LIST_SUCCESS: {
        return {
          ...state,
          entriesListData: payload,
          loader:false
        };
      }
      case priceBookActionTypes.ENTRIES_LIST_FAILURE: {
        return {
          ...state,
          entriesListData: payload.data,
          loader:false
        };
      }

         
      case priceBookActionTypes.ENTRY_HISTORY_INITIATE: {
        return {
          ...state,
          entryHistoryData: [],
          loader:true
        };
      }
      case priceBookActionTypes.ENTRY_HISTORY_SUCCESS: {
        return {
          ...state,
          entryHistoryData: payload,
          loader:false
        };
      }
      case priceBookActionTypes.ENTRY_HISTORY_FAILURE: {
        return {
          ...state,
          entryHistoryData: payload.data,
          loader:false
        };
      }

           
      case priceBookActionTypes.MEASURE_LIST_INITIATE: {
        return {
          ...state,
          measureListData: [],
          loader:true
        };
      }
      case priceBookActionTypes.MEASURE_LIST_SUCCESS: {
        return {
          ...state,
          measureListData: payload,
          loader:false
        };
      }
      case priceBookActionTypes.MEASURE_LIST_FAILURE: {
        return {
          ...state,
          measureListData: payload.data,
          loader:false
        };
      }


      case priceBookActionTypes.ASSEMBLY_LIST_INITIATE: {
        return {
          ...state,
          assemblyListData: [],
          loader:true
        };
      }
      case priceBookActionTypes.ASSEMBLY_LIST_SUCCESS: {
        return {
          ...state,
          assemblyListData: payload,
          loader:false
        };
      }
      case priceBookActionTypes.ASSEMBLY_LIST_FAILURE: {
        return {
          ...state,
          assemblyListData: payload.data,
          loader:false
        };
      }

      case priceBookActionTypes.ASSEMBLY_HISTORY_INITIATE: {
        return {
          ...state,
          assemblyHistoryData: [],
          loader:true
        };
      }
      case priceBookActionTypes.ASSEMBLY_HISTORY_SUCCESS: {
        return {
          ...state,
          assemblyHistoryData: payload,
          loader:false
        };
      }
      case priceBookActionTypes.ASSEMBLY_HISTORY_FAILURE: {
        return {
          ...state,
          assemblyHistoryData: payload.data,
          loader:false
        };
      }

      
      case priceBookActionTypes.CTC_LIST_INITIATE: {
        return {
          ...state,
          CTCListData: [],
          loader:true
        };
      }
      case priceBookActionTypes.CTC_LIST_SUCCESS: {
        return {
          ...state,
          CTCListData: payload,
          loader:false
        };
      }
      case priceBookActionTypes.CTC_LIST_FAILURE: {
        return {
          ...state,
          CTCListData: payload.data,
          loader:false
        };
      }


      case priceBookActionTypes.TAG_LIST_INITIATE: {
        return {
          ...state,
          tagListData: [],
          loader:true
        };
      }
      case priceBookActionTypes.TAG_LIST_SUCCESS: {
        return {
          ...state,
          tagListData: payload,
          loader:false
        };
      }
      case priceBookActionTypes.TAG_LIST_FAILURE: {
        return {
          ...state,
          tagListData: payload.data,
          loader:false
        };
      }

      case priceBookActionTypes.PRICEBOOK_PERMISSION_LIST_INITIATE: {
        return {
          ...state,
          priceBooKListData: [],
          loader:true
        };
      }
      case priceBookActionTypes.PRICEBOOK_PERMISSION_LIST_SUCCESS: {
        return {
          ...state,
          priceBooKListData: payload,
          loader:false
        };
      }
      case priceBookActionTypes.PRICEBOOK_PERMISSION_LIST_FAILURE: {
        return {
          ...state,
          priceBooKListData: payload.data,
          loader:false
        };
      }


      case priceBookActionTypes.TEMPLATE_LIST_INITIATE: {
        return {
          ...state,
          templateListData: [],
          loader:true
        };
      }
      case priceBookActionTypes.TEMPLATE_LIST_SUCCESS: {
        return {
          ...state,
          templateListData: payload,
          loader:false
        };
      }
      case priceBookActionTypes.TEMPLATE_LIST_FAILURE: {
        return {
          ...state,
          templateListData: payload.data,
          loader:false
        };
      }


      
      case priceBookActionTypes.TEMPLATE_HISTORY_INITIATE: {
        return {
          ...state,
          templateHistoryData: [],
          loader:true
        };
      }
      case priceBookActionTypes.TEMPLATE_HISTORY_SUCCESS: {
        return {
          ...state,
          templateHistoryData: payload,
          loader:false
        };
      }
      case priceBookActionTypes.TEMPLATE_HISTORY_FAILURE: {
        return {
          ...state,
          templateHistoryData: payload.data,
          loader:false
        };
      }


      case priceBookActionTypes.TEMPLATE_DETAILS_INITIATE: {
        return {
          ...state,
          templateDetailsData: [],
          loader:true
        };
      }
      case priceBookActionTypes.TEMPLATE_DETAILS_SUCCESS: {
        return {
          ...state,
          templateDetailsData: payload,
          loader:false
        };
      }
      case priceBookActionTypes.TEMPLATE_DETAILS_FAILURE: {
        return {
          ...state,
          templateDetailsData: payload.data,
          loader:false
        };
      }

  
    

    default:
      return state;
  }
}
