import React from "react";

const SummaryPreview = ({
  unitStep4List,
  handleShowComponent,
  handleShowComponents,
}) => {
  return (
    <>
      <div className="ModalBugheading d-flex align-items-center justify-content-between">
        <h3>
          <span>Unit Inputs</span>
        </h3>
      </div>
      <h6>Unit Types</h6>
      <div className="BlockInputs" style={{ width: "50%" }}>
        <table>
          <thead>
            <tr>
              <th>Types</th>
              <th>Sq/Ft</th>
              <th># of Bedrooms</th>
              <th># of Bathrooms</th>
            </tr>
          </thead>
          <tbody>
            {unitStep4List?.data?.table1?.length > 0 &&
              unitStep4List?.data?.table1?.map((item, ind) => {
                return (
                  <tr key={ind}>
                    <td>{item.type}</td>
                    <td>{item.UnitOptions?.[0]?.unitValue}</td>
                    <td>{item.bedRooms}</td>
                    <td>{item.bathRooms}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="ModalBigheading mt-4">
        <h3>
          <span>Block Unit Inputs</span>
        </h3>
      </div>
      <div className="GreenCheckTable">
        <table>
          <thead>
            <tr className="DarkBlue">
              <th colSpan={7}>Block A</th>
            </tr>
          </thead>
          <tbody>
            <tr className="LightBlue">
              <td colSpan={7}>Story 1</td>
            </tr>
            <tr>
              <td>
                <strong>Units #</strong>
              </td>
              <td>
                <strong>Units Type</strong>
              </td>
              <td>
                <strong>
                  Attached <br /> Garage
                </strong>
              </td>
              <td>
                <strong>
                  Basement <br /> Development
                </strong>
              </td>
              <td>
                <strong>
                  Driveways &amp; <br /> Sidewalks
                </strong>{" "}
              </td>
              <td>
                <strong>
                  Patios / Garden
                  <br /> Suite Concrete Pads
                </strong>{" "}
              </td>
              <td>
                <strong>
                  Detached Garage
                  <br />
                  Project Common Garage
                </strong>{" "}
              </td>
            </tr>

            <tr>
              <td>1-1</td>
              <td>1-1</td>
              <td>
                <div className="Actions">
                  <span className="GreenCheck">
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                </div>
              </td>
              <td>
                <div className="Actions">
                  <span className="GreenCheck">
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                </div>
              </td>
              <td>
                <div className="Actions">
                  <span className="GreenCheck">
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                </div>
              </td>
              <td>
                <div className="Actions">
                  <span className="GreenCheck">
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                </div>
              </td>
              <td>
                <div className="Actions">
                  <span className="GreenCheck">
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="BlockCostsPreview">
        <div className="d-flex align-items-center justify-content-between">
          <h4>Block Unit Costs Preview</h4>
          <div className="NewActions">
            <a href="javascript:void(0);" title="pdf">
              <img src="images/pdf.png" />
            </a>
            <a href="javascript:void(0);" title="download">
              <img src="images/download.png" />
            </a>
          </div>
        </div>
        {unitStep4List?.data?.table2?.blockQuantities?.length > 0 &&
          unitStep4List?.data?.table2?.blockQuantities?.map((data, ind) => {
            return (
              <div className="BlockInputsCollapse">
                <div
                  className="BcpHeader collapsed"
                  data-toggle="collapse"
                  href="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <h5>{data.name}</h5>
                </div>
                <div className="collapse" id="collapseExample" style={{}}>
                  <div className="card card-body border-0">
                    <div className="BlockInputs">
                      <table>
                        <thead>
                          <tr>
                            <th>Code Code #</th>
                            <th>Description</th>
                            <th>BlockA</th>
                            {/* {
                                        data?.ProformaBaseCosts?.[0]?.ProformaBlockQuantities?.length > 0 &&
                                        data?.ProformaBaseCosts?.[0]?.ProformaBlockQuantities?.map((item)=>{
                                          return(
                                         <th>{item.MultiBlock?.blockDescription}</th>
                                          )
                                        })
                                      } */}

                            <th>Total Construction Costs</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.ProformaBaseCosts?.length > 0 &&
                            data?.ProformaBaseCosts?.map((data, ind) => {
                              return (
                                <tr>
                                  <td>{data.CostCode?.number}</td>
                                  <td>{data.CostCode?.name}</td>
                                  <td>
                                    {
                                      data.ProformaBlockQuantities?.[0]
                                        ?.MultiBlock?.blockDescription
                                    }
                                  </td>

                                  <td>
                                    $
                                    {data.ProformaBlockQuantities?.[0]
                                      ?.totalCost ?? 0}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td />
                            <td>Total Interior Finishing Costs</td>
                            <td>67,480.00</td>
                            <td>67,480.00</td>
                            <td>67,480.00</td>
                            <td>67,480.00</td>
                            <td>2,75,480.00</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

        <div className="BlockInputs Red">
          <table>
            <tfoot>
              <tr>
                <td />
                <td>Grand Total</td>
                <td>$ 1,67,480.00</td>
                <td>$ 1,67,480.00</td>
                <td>$ 1,67,480.00</td>
                <td>$ 1,67,480.00</td>
                <td>$ 32,275,590.00</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div className="Button d-flex  justify-content-between align-items-center px-5">
        <button
          className="Outline border text-success border-success"
          onClick={() => handleShowComponents("unitQuanties")}
        >
          Cancel
        </button>
        <button
          className="Create bg-success border border-success"
          onClick={() => handleShowComponent("commonarae")}
        >
          Save Draft
        </button>
      </div>
    </>
  );
};

export default SummaryPreview;
